import { Epic } from 'redux-observable';
import { filter, map, tap } from 'rxjs/operators';
import { t } from 'ttag';
import { REJECT_AUTH } from 'uf-api-rtk/store/emptyApi';
import { getCurrentUserActionTypes } from 'uf-api/api/authentication.service';
import { addAppMessage, MessageAction } from 'uf/appservices/actions';
import { getSignInUrlBase } from 'uf/base/api';
import { combineEpics } from 'uf/base/epics';

const timeout: number = 5000;

export const showSessionCookieExpiredToast: Epic<any, MessageAction> =
  action$ =>
    action$.pipe(
      filter(
        action =>
          (action?.error?.status === 401 &&
            action.type !== getCurrentUserActionTypes.FAILURE) ||
          action.type === REJECT_AUTH,
      ),
      map(() => {
        const messageId = '401_unauthorized_message_id';

        return addAppMessage(
          t`Your session cookie has expired. Signing you out.`,
          {
            id: messageId,
            level: 'danger',
            status: 'running',
            timeout: 0,
            replacesMessage: messageId,
          },
        );
      }),
      tap(() => {
        if (__CLIENT__) {
          setTimeout(
            () => (window.location.href = getSignInUrlBase()),
            timeout,
          );
        }
      }),
    );

export default combineEpics({ showSessionCookieExpiredToast }, 'user');
