import { ThirdPartyAny } from 'uf/base/types';

export function getAmplitude() {
  if (__CLIENT__) {
    // using require here so that we only grab this package on the client side because it references
    // the window object which breaks in SSR
    // eslint-disable-next-line global-require
    const amplitude = require('@amplitude/analytics-browser');
    return amplitude;
  }
  return null;
}

export function getAmplitudeId() {
  if (__CLIENT__) {
    return process.env.UF_AMPLITUDE_ID;
  }
  return null;
}

export function getAmplitudeDomain() {
  if (__CLIENT__) {
    return process.env.UF_AMPLITUDE_DOMAIN;
  }
  return null;
}

export function getAmplitudeApiEndpoint() {
  if (__CLIENT__) {
    return process.env.UF_AMPLITUDE_API_ENDPOINT;
  }
  return null;
}

export function sendAmplitudeEvent(
  eventType: string,
  options: Record<string, ThirdPartyAny>,
) {
  const amplitude = getAmplitude();

  if (amplitude && !__TESTING__) {
    amplitude.track(eventType, options);
  }
}
