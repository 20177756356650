import {
  CuratedColumnSymbology,
  InterpolationTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/public/dataset/utah_agrc_liquefaction': {
    pcode: [
      {
        display_hints: {
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          scale: SymbologyScales.CATEGORICAL,
          theme: 'custom',
        },
        paint: {
          'fill-color': {
            default: '#c49c94',
            property: 'pcode',
            stops: [
              {
                color: '#808900',
                value: '1',
              },
              {
                color: '#d33115',
                value: '10',
              },
              {
                color: '#9f0500',
                value: '11',
              },
              {
                color: '#b0bc00',
                value: '2',
              },
              {
                color: '#dbdf00',
                value: '3',
              },
              {
                color: '#fcdc00',
                value: '4',
              },
              {
                color: '#fcc400',
                value: '5',
              },
              {
                color: '#fb9e00',
                value: '6',
              },
              {
                color: '#fe9200',
                value: '7',
              },
              {
                color: '#e27300',
                value: '8',
              },
              {
                color: '#f44e3b',
                value: '9',
              },
            ],
            type: 'categorical',
          },
          'fill-opacity': 0.5,
        },
        type: SymbologyTypes.FILL,
      },
      {
        display_hints: {
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          scale: SymbologyScales.CATEGORICAL,
          theme: 'custom',
        },
        paint: {
          'line-color': 'transparent',
          'line-opacity': 0.5,
          'line-width': 0.5,
        },
        type: SymbologyTypes.LINE,
      },
    ],
  },
};

export default symbologies;
