import { Divider } from '@blueprintjs/core';
import React, { Fragment, FunctionComponent } from 'react';
import { useSelector } from 'react-redux';

import { getMapMode } from 'uf/explore/selectors/map';
import { LayerId } from 'uf/layers';
import { MapMode } from 'uf/map/mapmode';
import { ProjectId } from 'uf/projects';
import { getHotKeyText } from 'uf/ui/base/hotkeys';
import ExploreMapClearButton from 'uf/ui/explore/ExploreMap/ExploreMapClearButton/ExploreMapClearButton';
import ExploreMapControlButton from 'uf/ui/explore/ExploreMap/ExploreMapControlButton/ExploreMapControlButton';
import { buttonsByKey } from 'uf/ui/explore/ExploreMap/ExploreMapToolbar/buttons';
import { ExploreMapHotKeys } from 'uf/ui/explore/ExploreMap/hotKeys';
import useUserFlag from 'uf/ui/user/useUserFlag';

const drawButton = buttonsByKey[MapMode.DRAW];
const inspectionButton = buttonsByKey[MapMode.INSPECTION];

export const toolbarButtons = [
  buttonsByKey[MapMode.NORMAL],
  buttonsByKey[MapMode.POINT_SELECTION],
  buttonsByKey[MapMode.BOX_SELECTION],
  buttonsByKey[MapMode.POLYGON_SELECTION],
];

interface Props {
  disabled: boolean;
  layerId: LayerId;
  projectId: ProjectId;
  buttonClassName: string;
}
export const SelectionModeButtons: FunctionComponent<Props> = ({
  disabled,
  layerId,
  projectId,
  buttonClassName,
}) => {
  const hasMapEditsFlag = useUserFlag('dev-map-edits');
  const mapMode = useSelector(getMapMode);
  return (
    <Fragment>
      {toolbarButtons.map(
        ({ modeKey, iconPath, title, hotKey, getDisabled }) => (
          <ExploreMapControlButton
            disabled={disabled && getDisabled(!!layerId)}
            hotKey={hotKey}
            key={modeKey}
            modeKey={modeKey}
            title={title}
            iconPath={iconPath}
            className={buttonClassName}
          />
        ),
      )}
      {hasMapEditsFlag && (
        <ExploreMapControlButton
          modeKey={drawButton.modeKey}
          title={drawButton.title}
          iconPath={drawButton.iconPath}
          className={buttonClassName}
        />
      )}

      <ExploreMapClearButton
        projectId={projectId}
        minimal
        className={buttonClassName}
        mapMode={mapMode}
        layerId={layerId}
      />

      <Divider />

      <ExploreMapControlButton
        modeKey={inspectionButton.modeKey}
        disabled={disabled && buttonsByKey.INSPECTION.getDisabled(!!layerId)}
        hotKey={getHotKeyText(ExploreMapHotKeys.MAP_INSPECT)}
        title={inspectionButton.title}
        iconPath={inspectionButton.iconPath}
        className={buttonClassName}
      />
    </Fragment>
  );
};
