import { Epic, ofType } from 'redux-observable';
import { ignoreElements, tap } from 'rxjs/operators';

import { exportModuleParamsActionTypes } from 'uf-api/api/project.service';
import { ClientResponse } from 'uf-api/variables';
import { downloadData } from 'uf/base/download';
import { SuccessAction } from 'uf/data/ActionTypes';

const downloadAfterExportSuccess: Epic<
  SuccessAction<ClientResponse<Blob>>,
  any
> = action$ => {
  return action$.pipe(
    ofType(exportModuleParamsActionTypes.SUCCESS),
    tap(({ result }) => {
      const { data, headers } = result;
      const filename = getExportFilename(headers['content-disposition']);
      downloadData(data, headers['content-type'] as string, filename);
    }),
    ignoreElements(),
  );
};

export default downloadAfterExportSuccess;

function getExportFilename(contentDisposition) {
  // [0] is the whole match, [1] is the first capture group which is the actual file name
  return contentDisposition.match(/filename="(.*)"/)[1];
}
