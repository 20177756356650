/**
 * UrbanFootprint App API
 * API for serving UrbanFootprint Application
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { ClientResponse } from '../variables';

// @ts-ignore: This is used only for params, but some services have param-free APIs
import { AuthenticationServiceInterface } from './authentication.serviceInterface';

// @ts-ignore: These imports are for both params and returnType, but we only use returnType
import { ProblemDetail } from '../model/problemDetail';
// @ts-ignore: These imports are for both params and returnType, but we only use returnType
import { User } from '../model/user';
// @ts-ignore: These imports are for both params and returnType, but we only use returnType
import { UserAction } from '../model/userAction';

/**
 * Package: api
 */

const authenticatedPrefix = `uf-api/authentication/authenticated/`;
/**
 * Action types for authentication.authenticated
 *
 * Handle OIDC authentication response.
 */
export const authenticatedActionTypes = {
  LOAD: `${authenticatedPrefix}LOAD` as 'uf-api/authentication/authenticated/LOAD',
  SUCCESS:
    `${authenticatedPrefix}SUCCESS` as 'uf-api/authentication/authenticated/SUCCESS',
  FAILURE:
    `${authenticatedPrefix}FAILURE` as 'uf-api/authentication/authenticated/FAILURE',
  CLEAR:
    `${authenticatedPrefix}CLEAR` as 'uf-api/authentication/authenticated/CLEAR',
} as const;

/**
 * LOAD action for authentication.authenticated
 * Handle OIDC authentication response.
 */
export interface authenticatedLoadAction {
  readonly type: 'uf-api/authentication/authenticated/LOAD';
  readonly params: AuthenticationServiceInterface.authenticatedParams;
  readonly promise: Promise<ClientResponse<{}>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for authentication.authenticated
 * Handle OIDC authentication response.
 */
export interface authenticatedSuccessAction {
  readonly type: 'uf-api/authentication/authenticated/SUCCESS';
  readonly result: {};
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for authentication.authenticated
 * Handle OIDC authentication response.
 */
export interface authenticatedFailureAction {
  readonly type: 'uf-api/authentication/authenticated/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for authentication.authenticated
 * Handle OIDC authentication response.
 */
export interface authenticatedClearAction {
  readonly type: 'uf-api/authentication/authenticated/CLEAR';
  readonly key: string;
}

const beginAcceptInvitationPrefix = `uf-api/authentication/begin_accept_invitation/`;
/**
 * Action types for authentication.begin_accept_invitation
 *
 * Accept an invitation to join an organization.
 */
export const beginAcceptInvitationActionTypes = {
  LOAD: `${beginAcceptInvitationPrefix}LOAD` as 'uf-api/authentication/begin_accept_invitation/LOAD',
  SUCCESS:
    `${beginAcceptInvitationPrefix}SUCCESS` as 'uf-api/authentication/begin_accept_invitation/SUCCESS',
  FAILURE:
    `${beginAcceptInvitationPrefix}FAILURE` as 'uf-api/authentication/begin_accept_invitation/FAILURE',
  CLEAR:
    `${beginAcceptInvitationPrefix}CLEAR` as 'uf-api/authentication/begin_accept_invitation/CLEAR',
} as const;

/**
 * LOAD action for authentication.begin_accept_invitation
 * Accept an invitation to join an organization.
 */
export interface beginAcceptInvitationLoadAction {
  readonly type: 'uf-api/authentication/begin_accept_invitation/LOAD';
  readonly params: AuthenticationServiceInterface.beginAcceptInvitationParams;
  readonly promise: Promise<ClientResponse<{}>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for authentication.begin_accept_invitation
 * Accept an invitation to join an organization.
 */
export interface beginAcceptInvitationSuccessAction {
  readonly type: 'uf-api/authentication/begin_accept_invitation/SUCCESS';
  readonly result: {};
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for authentication.begin_accept_invitation
 * Accept an invitation to join an organization.
 */
export interface beginAcceptInvitationFailureAction {
  readonly type: 'uf-api/authentication/begin_accept_invitation/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for authentication.begin_accept_invitation
 * Accept an invitation to join an organization.
 */
export interface beginAcceptInvitationClearAction {
  readonly type: 'uf-api/authentication/begin_accept_invitation/CLEAR';
  readonly key: string;
}

const canPerformActionsPrefix = `uf-api/authentication/can_perform_actions/`;
/**
 * Action types for authentication.can_perform_actions
 *
 * Can the current user perform the given verb:resource pairs.
 */
export const canPerformActionsActionTypes = {
  LOAD: `${canPerformActionsPrefix}LOAD` as 'uf-api/authentication/can_perform_actions/LOAD',
  SUCCESS:
    `${canPerformActionsPrefix}SUCCESS` as 'uf-api/authentication/can_perform_actions/SUCCESS',
  FAILURE:
    `${canPerformActionsPrefix}FAILURE` as 'uf-api/authentication/can_perform_actions/FAILURE',
  CLEAR:
    `${canPerformActionsPrefix}CLEAR` as 'uf-api/authentication/can_perform_actions/CLEAR',
} as const;

/**
 * LOAD action for authentication.can_perform_actions
 * Can the current user perform the given verb:resource pairs.
 */
export interface canPerformActionsLoadAction {
  readonly type: 'uf-api/authentication/can_perform_actions/LOAD';
  readonly params: AuthenticationServiceInterface.canPerformActionsParams;
  readonly promise: Promise<ClientResponse<Array<UserAction>>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for authentication.can_perform_actions
 * Can the current user perform the given verb:resource pairs.
 */
export interface canPerformActionsSuccessAction {
  readonly type: 'uf-api/authentication/can_perform_actions/SUCCESS';
  readonly result: Array<UserAction>;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for authentication.can_perform_actions
 * Can the current user perform the given verb:resource pairs.
 */
export interface canPerformActionsFailureAction {
  readonly type: 'uf-api/authentication/can_perform_actions/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for authentication.can_perform_actions
 * Can the current user perform the given verb:resource pairs.
 */
export interface canPerformActionsClearAction {
  readonly type: 'uf-api/authentication/can_perform_actions/CLEAR';
  readonly key: string;
}

const emailVerifiedPrefix = `uf-api/authentication/email_verified/`;
/**
 * Action types for authentication.email_verified
 *
 * Handle email verification response.
 */
export const emailVerifiedActionTypes = {
  LOAD: `${emailVerifiedPrefix}LOAD` as 'uf-api/authentication/email_verified/LOAD',
  SUCCESS:
    `${emailVerifiedPrefix}SUCCESS` as 'uf-api/authentication/email_verified/SUCCESS',
  FAILURE:
    `${emailVerifiedPrefix}FAILURE` as 'uf-api/authentication/email_verified/FAILURE',
  CLEAR:
    `${emailVerifiedPrefix}CLEAR` as 'uf-api/authentication/email_verified/CLEAR',
} as const;

/**
 * LOAD action for authentication.email_verified
 * Handle email verification response.
 */
export interface emailVerifiedLoadAction {
  readonly type: 'uf-api/authentication/email_verified/LOAD';
  readonly params: AuthenticationServiceInterface.emailVerifiedParams;
  readonly promise: Promise<ClientResponse<{}>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for authentication.email_verified
 * Handle email verification response.
 */
export interface emailVerifiedSuccessAction {
  readonly type: 'uf-api/authentication/email_verified/SUCCESS';
  readonly result: {};
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for authentication.email_verified
 * Handle email verification response.
 */
export interface emailVerifiedFailureAction {
  readonly type: 'uf-api/authentication/email_verified/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for authentication.email_verified
 * Handle email verification response.
 */
export interface emailVerifiedClearAction {
  readonly type: 'uf-api/authentication/email_verified/CLEAR';
  readonly key: string;
}

const forgotPasswordPrefix = `uf-api/authentication/forgot_password/`;
/**
 * Action types for authentication.forgot_password
 *
 * Initiate OIDC authentication request with default to forgot password page.
 */
export const forgotPasswordActionTypes = {
  LOAD: `${forgotPasswordPrefix}LOAD` as 'uf-api/authentication/forgot_password/LOAD',
  SUCCESS:
    `${forgotPasswordPrefix}SUCCESS` as 'uf-api/authentication/forgot_password/SUCCESS',
  FAILURE:
    `${forgotPasswordPrefix}FAILURE` as 'uf-api/authentication/forgot_password/FAILURE',
  CLEAR:
    `${forgotPasswordPrefix}CLEAR` as 'uf-api/authentication/forgot_password/CLEAR',
} as const;

/**
 * LOAD action for authentication.forgot_password
 * Initiate OIDC authentication request with default to forgot password page.
 */
export interface forgotPasswordLoadAction {
  readonly type: 'uf-api/authentication/forgot_password/LOAD';
  readonly params: AuthenticationServiceInterface.forgotPasswordParams;
  readonly promise: Promise<ClientResponse<{}>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for authentication.forgot_password
 * Initiate OIDC authentication request with default to forgot password page.
 */
export interface forgotPasswordSuccessAction {
  readonly type: 'uf-api/authentication/forgot_password/SUCCESS';
  readonly result: {};
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for authentication.forgot_password
 * Initiate OIDC authentication request with default to forgot password page.
 */
export interface forgotPasswordFailureAction {
  readonly type: 'uf-api/authentication/forgot_password/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for authentication.forgot_password
 * Initiate OIDC authentication request with default to forgot password page.
 */
export interface forgotPasswordClearAction {
  readonly type: 'uf-api/authentication/forgot_password/CLEAR';
  readonly key: string;
}

const getCurrentUserPrefix = `uf-api/authentication/get_current_user/`;
/**
 * Action types for authentication.get_current_user
 *
 * get the current user
 */
export const getCurrentUserActionTypes = {
  LOAD: `${getCurrentUserPrefix}LOAD` as 'uf-api/authentication/get_current_user/LOAD',
  SUCCESS:
    `${getCurrentUserPrefix}SUCCESS` as 'uf-api/authentication/get_current_user/SUCCESS',
  FAILURE:
    `${getCurrentUserPrefix}FAILURE` as 'uf-api/authentication/get_current_user/FAILURE',
  CLEAR:
    `${getCurrentUserPrefix}CLEAR` as 'uf-api/authentication/get_current_user/CLEAR',
} as const;

/**
 * LOAD action for authentication.get_current_user
 * get the current user
 */
export interface getCurrentUserLoadAction {
  readonly type: 'uf-api/authentication/get_current_user/LOAD';

  readonly promise: Promise<ClientResponse<User>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for authentication.get_current_user
 * get the current user
 */
export interface getCurrentUserSuccessAction {
  readonly type: 'uf-api/authentication/get_current_user/SUCCESS';
  readonly result: User;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for authentication.get_current_user
 * get the current user
 */
export interface getCurrentUserFailureAction {
  readonly type: 'uf-api/authentication/get_current_user/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for authentication.get_current_user
 * get the current user
 */
export interface getCurrentUserClearAction {
  readonly type: 'uf-api/authentication/get_current_user/CLEAR';
  readonly key: string;
}

const loginPrefix = `uf-api/authentication/login/`;
/**
 * Action types for authentication.login
 *
 * user login and authentication
 */
export const loginActionTypes = {
  LOAD: `${loginPrefix}LOAD` as 'uf-api/authentication/login/LOAD',
  SUCCESS: `${loginPrefix}SUCCESS` as 'uf-api/authentication/login/SUCCESS',
  FAILURE: `${loginPrefix}FAILURE` as 'uf-api/authentication/login/FAILURE',
  CLEAR: `${loginPrefix}CLEAR` as 'uf-api/authentication/login/CLEAR',
} as const;

/**
 * LOAD action for authentication.login
 * user login and authentication
 */
export interface loginLoadAction {
  readonly type: 'uf-api/authentication/login/LOAD';
  readonly params: AuthenticationServiceInterface.loginParams;
  readonly promise: Promise<ClientResponse<User>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for authentication.login
 * user login and authentication
 */
export interface loginSuccessAction {
  readonly type: 'uf-api/authentication/login/SUCCESS';
  readonly result: User;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for authentication.login
 * user login and authentication
 */
export interface loginFailureAction {
  readonly type: 'uf-api/authentication/login/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for authentication.login
 * user login and authentication
 */
export interface loginClearAction {
  readonly type: 'uf-api/authentication/login/CLEAR';
  readonly key: string;
}

const loginTokensPrefix = `uf-api/authentication/login_tokens/`;
/**
 * Action types for authentication.login_tokens
 *
 * Login with ID Token and Access Token
 */
export const loginTokensActionTypes = {
  LOAD: `${loginTokensPrefix}LOAD` as 'uf-api/authentication/login_tokens/LOAD',
  SUCCESS:
    `${loginTokensPrefix}SUCCESS` as 'uf-api/authentication/login_tokens/SUCCESS',
  FAILURE:
    `${loginTokensPrefix}FAILURE` as 'uf-api/authentication/login_tokens/FAILURE',
  CLEAR:
    `${loginTokensPrefix}CLEAR` as 'uf-api/authentication/login_tokens/CLEAR',
} as const;

/**
 * LOAD action for authentication.login_tokens
 * Login with ID Token and Access Token
 */
export interface loginTokensLoadAction {
  readonly type: 'uf-api/authentication/login_tokens/LOAD';
  readonly params: AuthenticationServiceInterface.loginTokensParams;
  readonly promise: Promise<ClientResponse<{}>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for authentication.login_tokens
 * Login with ID Token and Access Token
 */
export interface loginTokensSuccessAction {
  readonly type: 'uf-api/authentication/login_tokens/SUCCESS';
  readonly result: {};
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for authentication.login_tokens
 * Login with ID Token and Access Token
 */
export interface loginTokensFailureAction {
  readonly type: 'uf-api/authentication/login_tokens/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for authentication.login_tokens
 * Login with ID Token and Access Token
 */
export interface loginTokensClearAction {
  readonly type: 'uf-api/authentication/login_tokens/CLEAR';
  readonly key: string;
}

const logoutPrefix = `uf-api/authentication/logout/`;
/**
 * Action types for authentication.logout
 *
 * user logout
 */
export const logoutActionTypes = {
  LOAD: `${logoutPrefix}LOAD` as 'uf-api/authentication/logout/LOAD',
  SUCCESS: `${logoutPrefix}SUCCESS` as 'uf-api/authentication/logout/SUCCESS',
  FAILURE: `${logoutPrefix}FAILURE` as 'uf-api/authentication/logout/FAILURE',
  CLEAR: `${logoutPrefix}CLEAR` as 'uf-api/authentication/logout/CLEAR',
} as const;

/**
 * LOAD action for authentication.logout
 * user logout
 */
export interface logoutLoadAction {
  readonly type: 'uf-api/authentication/logout/LOAD';

  readonly promise: Promise<ClientResponse<boolean>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for authentication.logout
 * user logout
 */
export interface logoutSuccessAction {
  readonly type: 'uf-api/authentication/logout/SUCCESS';
  readonly result: boolean;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for authentication.logout
 * user logout
 */
export interface logoutFailureAction {
  readonly type: 'uf-api/authentication/logout/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for authentication.logout
 * user logout
 */
export interface logoutClearAction {
  readonly type: 'uf-api/authentication/logout/CLEAR';
  readonly key: string;
}

const signinPrefix = `uf-api/authentication/signin/`;
/**
 * Action types for authentication.signin
 *
 * Initiate OIDC authentication request.
 */
export const signinActionTypes = {
  LOAD: `${signinPrefix}LOAD` as 'uf-api/authentication/signin/LOAD',
  SUCCESS: `${signinPrefix}SUCCESS` as 'uf-api/authentication/signin/SUCCESS',
  FAILURE: `${signinPrefix}FAILURE` as 'uf-api/authentication/signin/FAILURE',
  CLEAR: `${signinPrefix}CLEAR` as 'uf-api/authentication/signin/CLEAR',
} as const;

/**
 * LOAD action for authentication.signin
 * Initiate OIDC authentication request.
 */
export interface signinLoadAction {
  readonly type: 'uf-api/authentication/signin/LOAD';
  readonly params: AuthenticationServiceInterface.signinParams;
  readonly promise: Promise<ClientResponse<{}>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for authentication.signin
 * Initiate OIDC authentication request.
 */
export interface signinSuccessAction {
  readonly type: 'uf-api/authentication/signin/SUCCESS';
  readonly result: {};
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for authentication.signin
 * Initiate OIDC authentication request.
 */
export interface signinFailureAction {
  readonly type: 'uf-api/authentication/signin/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for authentication.signin
 * Initiate OIDC authentication request.
 */
export interface signinClearAction {
  readonly type: 'uf-api/authentication/signin/CLEAR';
  readonly key: string;
}

const signoutPrefix = `uf-api/authentication/signout/`;
/**
 * Action types for authentication.signout
 *
 * Redirect to Auth0 to signout.
 */
export const signoutActionTypes = {
  LOAD: `${signoutPrefix}LOAD` as 'uf-api/authentication/signout/LOAD',
  SUCCESS: `${signoutPrefix}SUCCESS` as 'uf-api/authentication/signout/SUCCESS',
  FAILURE: `${signoutPrefix}FAILURE` as 'uf-api/authentication/signout/FAILURE',
  CLEAR: `${signoutPrefix}CLEAR` as 'uf-api/authentication/signout/CLEAR',
} as const;

/**
 * LOAD action for authentication.signout
 * Redirect to Auth0 to signout.
 */
export interface signoutLoadAction {
  readonly type: 'uf-api/authentication/signout/LOAD';
  readonly params: AuthenticationServiceInterface.signoutParams;
  readonly promise: Promise<ClientResponse<{}>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for authentication.signout
 * Redirect to Auth0 to signout.
 */
export interface signoutSuccessAction {
  readonly type: 'uf-api/authentication/signout/SUCCESS';
  readonly result: {};
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for authentication.signout
 * Redirect to Auth0 to signout.
 */
export interface signoutFailureAction {
  readonly type: 'uf-api/authentication/signout/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for authentication.signout
 * Redirect to Auth0 to signout.
 */
export interface signoutClearAction {
  readonly type: 'uf-api/authentication/signout/CLEAR';
  readonly key: string;
}

export const authenticationActionTypesMap = {
  /** Action types for authentication.authenticated */
  authenticated: authenticatedActionTypes,
  /** Action types for authentication.begin_accept_invitation */
  begin_accept_invitation: beginAcceptInvitationActionTypes,
  /** Action types for authentication.can_perform_actions */
  can_perform_actions: canPerformActionsActionTypes,
  /** Action types for authentication.email_verified */
  email_verified: emailVerifiedActionTypes,
  /** Action types for authentication.forgot_password */
  forgot_password: forgotPasswordActionTypes,
  /** Action types for authentication.get_current_user */
  get_current_user: getCurrentUserActionTypes,
  /** Action types for authentication.login */
  login: loginActionTypes,
  /** Action types for authentication.login_tokens */
  login_tokens: loginTokensActionTypes,
  /** Action types for authentication.logout */
  logout: logoutActionTypes,
  /** Action types for authentication.signin */
  signin: signinActionTypes,
  /** Action types for authentication.signout */
  signout: signoutActionTypes,
} as const;

export const authenticationDetailsMap = {
  authenticated: {
    /** HTTP method for authentication.authenticated */
    httpMethod: 'get' as const,
  },
  begin_accept_invitation: {
    /** HTTP method for authentication.begin_accept_invitation */
    httpMethod: 'get' as const,
  },
  can_perform_actions: {
    /** HTTP method for authentication.can_perform_actions */
    httpMethod: 'get' as const,
  },
  email_verified: {
    /** HTTP method for authentication.email_verified */
    httpMethod: 'get' as const,
  },
  forgot_password: {
    /** HTTP method for authentication.forgot_password */
    httpMethod: 'get' as const,
  },
  get_current_user: {
    /** HTTP method for authentication.get_current_user */
    httpMethod: 'get' as const,
  },
  login: {
    /** HTTP method for authentication.login */
    httpMethod: 'post' as const,
  },
  login_tokens: {
    /** HTTP method for authentication.login_tokens */
    httpMethod: 'post' as const,
  },
  logout: {
    /** HTTP method for authentication.logout */
    httpMethod: 'get' as const,
  },
  signin: {
    /** HTTP method for authentication.signin */
    httpMethod: 'get' as const,
  },
  signout: {
    /** HTTP method for authentication.signout */
    httpMethod: 'get' as const,
  },
} as const;

/**
 * Local DataState<T> - kept in sync with uf/data/dataState.ts
 */
interface DataState<T> {
  readonly loading: boolean;
  readonly loaded: boolean;
  readonly requestId: string;
  readonly requestTime?: string;
  readonly responseTime?: string;
  readonly requestCount?: number;
  readonly error?: any;
  readonly promise?: Promise<T>;
  readonly data?: T;
  readonly extra?: any;
  readonly key?: string;
}
export interface AuthenticationServiceState {
  /** authenticated state, by key */
  authenticated: {
    readonly [key: string]: DataState<{}>;
  };
  /** beginAcceptInvitation state, by key */
  begin_accept_invitation: {
    readonly [key: string]: DataState<{}>;
  };
  /** canPerformActions state, by key */
  can_perform_actions: {
    readonly [key: string]: DataState<Array<UserAction>>;
  };
  /** emailVerified state, by key */
  email_verified: {
    readonly [key: string]: DataState<{}>;
  };
  /** forgotPassword state, by key */
  forgot_password: {
    readonly [key: string]: DataState<{}>;
  };
  /** getCurrentUser state, by key */
  get_current_user: {
    readonly [key: string]: DataState<User>;
  };
  /** all recent login results (POST/PUT/PATCH) */
  readonly login: DataState<User>[];
  /** all recent loginTokens results (POST/PUT/PATCH) */
  readonly login_tokens: DataState<{}>[];
  /** logout state, by key */
  logout: {
    readonly [key: string]: DataState<boolean>;
  };
  /** signin state, by key */
  signin: {
    readonly [key: string]: DataState<{}>;
  };
  /** signout state, by key */
  signout: {
    readonly [key: string]: DataState<{}>;
  };
}
