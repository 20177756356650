import colors from 'material-colors';
import { t } from 'ttag';

import { ACRES, getUnitName } from 'uf/base/units';
import { LegacyChart, LegacyReport } from 'uf/reporting/index';

import { defaultEmptyChartContent } from './emptyChartMessage';

const IN_SENTENCE = true;

const tncSource = {
  source: 'analysis',
  sourceKey: 'tnc_conservation',
} as const;

const AmphibiansNetAmphibiansAreaDegradedSeries = {
  ...tncSource,
  key: 'tnc_conservation_habitat_amphibians_netamphibians_area_degraded',
  layerKey: 'tnc_conservation_habitat_amphibians_net',
  columnKey: 'amphibians_area_degraded',
  color: colors.red[600],
};

const AmphibiansNetAmphibiansAreaImprovedSeries = {
  ...tncSource,
  key: 'tnc_conservation_habitat_amphibians_netamphibians_area_improved',
  layerKey: 'tnc_conservation_habitat_amphibians_net',
  columnKey: 'amphibians_area_improved',
  color: colors.blue[700],
};

const BirdsNetBirdsAreaDegradedSeries = {
  ...tncSource,
  key: 'tnc_conservation_habitat_birds_netbirds_area_degraded',
  layerKey: 'tnc_conservation_habitat_birds_net',
  columnKey: 'birds_area_degraded',
  color: colors.red[600],
};

const BirdsNetBirdsAreaImprovedSeries = {
  ...tncSource,
  key: 'tnc_conservation_habitat_birds_netbirds_area_improved',
  layerKey: 'tnc_conservation_habitat_birds_net',
  columnKey: 'birds_area_improved',
  color: colors.blue[700],
};

const MammalsNetMammalsAreaDegradedSeries = {
  ...tncSource,
  key: 'tnc_conservation_habitat_mammals_netmammals_area_degraded',
  layerKey: 'tnc_conservation_habitat_mammals_net',
  columnKey: 'mammals_area_degraded',
  color: colors.red[600],
};

const MammalsNetMammalsAreaImprovedSeries = {
  ...tncSource,
  key: 'tnc_conservation_habitat_mammals_netmammals_area_improved',
  layerKey: 'tnc_conservation_habitat_mammals_net',
  columnKey: 'mammals_area_improved',
  color: colors.blue[700],
};

const ReptilesNetReptilesAreaDegradedSeries = {
  ...tncSource,
  key: 'tnc_conservation_habitat_reptiles_netreptiles_area_degraded',
  layerKey: 'tnc_conservation_habitat_reptiles_net',
  columnKey: 'reptiles_area_degraded',
  color: colors.red[600],
};

const ReptilesNetReptilesAreaImprovedSeries = {
  ...tncSource,
  key: 'tnc_conservation_habitat_reptiles_netreptiles_area_improved',
  layerKey: 'tnc_conservation_habitat_reptiles_net',
  columnKey: 'reptiles_area_improved',
  color: colors.blue[700],
};

const TesNetThreatenedEndangeredAreaDegradedSeries = {
  ...tncSource,
  key: 'tnc_conservation_habitat_tes_netthreatened_endangered_area_degraded',
  layerKey: 'tnc_conservation_habitat_tes_net',
  columnKey: 'threatened_endangered_area_degraded',
  color: colors.red[600],
};

const TesNetThreatenedEndangeredAreaImprovedSeries = {
  ...tncSource,
  key: 'tnc_conservation_habitat_tes_netthreatened_endangered_area_improved',
  layerKey: 'tnc_conservation_habitat_tes_net',
  columnKey: 'threatened_endangered_area_improved',
  color: colors.blue[700],
};

const SvccNetClimateChangeExceptBirdsAreaDegradedSeries = {
  ...tncSource,
  key: 'tnc_conservation_habitat_svcc_netclimate_change_except_birds_area_degraded',
  layerKey: 'tnc_conservation_habitat_svcc_net',
  columnKey: 'climate_change_except_birds_area_degraded',
  color: colors.red[600],
};

const SvccNetClimateChangeExceptBirdsAreaImprovedSeries = {
  ...tncSource,
  key: 'tnc_conservation_habitat_svcc_netclimate_change_except_birds_area_improved',
  layerKey: 'tnc_conservation_habitat_svcc_net',
  columnKey: 'climate_change_except_birds_area_improved',
  color: colors.blue[700],
};

const BvccNetClimateChangeBirdsAreaDegradedSeries = {
  ...tncSource,
  key: 'tnc_conservation_habitat_bvcc_netclimate_change_birds_area_degraded',
  layerKey: 'tnc_conservation_habitat_bvcc_net',
  columnKey: 'climate_change_birds_area_degraded',
  color: colors.red[600],
};

const BvccNetClimateChangeBirdsAreaImprovedSeries = {
  ...tncSource,
  key: 'tnc_conservation_habitat_bvcc_netclimate_change_birds_area_improved',
  layerKey: 'tnc_conservation_habitat_bvcc_net',
  columnKey: 'climate_change_birds_area_improved',
  color: colors.blue[700],
};

const EcaNetEcaAgricultureSeries = {
  ...tncSource,
  key: 'tnc_conservation_habitat_eca_neteca_agriculture',
  layerKey: 'tnc_conservation_habitat_eca_net',
  columnKey: 'eca_agriculture',
  color: colors.yellow[600],
};

const EcaNetEcaNaturalSeries = {
  ...tncSource,
  key: 'tnc_conservation_habitat_eca_neteca_natural',
  layerKey: 'tnc_conservation_habitat_eca_net',
  columnKey: 'eca_natural',
  color: colors.green[500],
};

const EcaNetEcaUrbanSeries = {
  ...tncSource,
  key: 'tnc_conservation_habitat_eca_neteca_urban',
  layerKey: 'tnc_conservation_habitat_eca_net',
  columnKey: 'eca_urban',
  color: colors.orange[600],
};

const PcaNetTncPriorityAreasAgricultureSeries = {
  ...tncSource,
  key: 'tnc_conservation_habitat_pca_nettnc_priority_areas_agriculture',
  layerKey: 'tnc_conservation_habitat_pca_net',
  columnKey: 'tnc_priority_areas_agriculture',
  color: colors.yellow[600],
};

const PcaNetTncPriorityAreasNaturalSeries = {
  ...tncSource,
  key: 'tnc_conservation_habitat_pca_nettnc_priority_areas_natural',
  layerKey: 'tnc_conservation_habitat_pca_net',
  columnKey: 'tnc_priority_areas_natural',
  color: colors.green[500],
};

const PcaNetTncPriorityAreasUrbanSeries = {
  ...tncSource,
  key: 'tnc_conservation_habitat_pca_nettnc_priority_areas_urban',
  layerKey: 'tnc_conservation_habitat_pca_net',
  columnKey: 'tnc_priority_areas_urban',
  color: colors.orange[600],
};

const AudubonNetAudubonAgricultureSeries = {
  ...tncSource,
  key: 'tnc_conservation_habitat_audubon_netaudubon_agriculture',
  layerKey: 'tnc_conservation_habitat_audubon_net',
  columnKey: 'audubon_agriculture',
  color: colors.yellow[600],
};

const AudubonNetAudubonNaturalSeries = {
  ...tncSource,
  key: 'tnc_conservation_habitat_audubon_netaudubon_natural',
  layerKey: 'tnc_conservation_habitat_audubon_net',
  columnKey: 'audubon_natural',
  color: colors.green[500],
};

const AudubonNetAudubonUrbanSeries = {
  ...tncSource,
  key: 'tnc_conservation_habitat_audubon_netaudubon_urban',
  layerKey: 'tnc_conservation_habitat_audubon_net',
  columnKey: 'audubon_urban',
  color: colors.orange[600],
};

const SmpNetHighSmSeries = {
  ...tncSource,
  key: 'tnc_conservation_habitat_smp_nethigh_sm',
  layerKey: 'tnc_conservation_habitat_smp_net',
  columnKey: 'high_sm',
  color: colors.green[900],
};

const SmpNetLowSmSeries = {
  ...tncSource,
  key: 'tnc_conservation_habitat_smp_netlow_sm',
  layerKey: 'tnc_conservation_habitat_smp_net',
  columnKey: 'low_sm',
  color: colors.lightGreen[300],
};

const SmpNetMediumSmSeries = {
  ...tncSource,
  key: 'tnc_conservation_habitat_smp_netmedium_sm',
  layerKey: 'tnc_conservation_habitat_smp_net',
  columnKey: 'medium_sm',
  color: colors.green[500],
};

const A001AreaDegradedSeries = {
  ...tncSource,
  key: 'tnc_conservation_a001_area_degraded',
  layerKey: 'tnc_conservation_te_species_area_degraded',
  columnKey: 'a001_area_degraded',
  color: colors.red[600],
};

const A001AreaImprovedSeries = {
  ...tncSource,
  key: 'tnc_conservation_a001_area_improved',
  layerKey: 'tnc_conservation_te_species_area_improved',
  columnKey: 'a001_area_improved',
  color: colors.blue[700],
};

const A003AreaDegradedSeries = {
  ...tncSource,
  key: 'tnc_conservation_a003_area_degraded',
  layerKey: 'tnc_conservation_te_species_area_degraded',
  columnKey: 'a003_area_degraded',
  color: colors.red[600],
};

const A003AreaImprovedSeries = {
  ...tncSource,
  key: 'tnc_conservation_a003_area_improved',
  layerKey: 'tnc_conservation_te_species_area_improved',
  columnKey: 'a003_area_improved',
  color: colors.blue[700],
};

const A011AreaDegradedSeries = {
  ...tncSource,
  key: 'tnc_conservation_a011_area_degraded',
  layerKey: 'tnc_conservation_te_species_area_degraded',
  columnKey: 'a011_area_degraded',
  color: colors.red[600],
};

const A011AreaImprovedSeries = {
  ...tncSource,
  key: 'tnc_conservation_a011_area_improved',
  layerKey: 'tnc_conservation_te_species_area_improved',
  columnKey: 'a011_area_improved',
  color: colors.blue[700],
};

const A013AreaDegradedSeries = {
  ...tncSource,
  key: 'tnc_conservation_a013_area_degraded',
  layerKey: 'tnc_conservation_te_species_area_degraded',
  columnKey: 'a013_area_degraded',
  color: colors.red[600],
};

const A013AreaImprovedSeries = {
  ...tncSource,
  key: 'tnc_conservation_a013_area_improved',
  layerKey: 'tnc_conservation_te_species_area_improved',
  columnKey: 'a013_area_improved',
  color: colors.blue[700],
};

const A017AreaDegradedSeries = {
  ...tncSource,
  key: 'tnc_conservation_a017_area_degraded',
  layerKey: 'tnc_conservation_te_species_area_degraded',
  columnKey: 'a017_area_degraded',
  color: colors.red[600],
};

const A017AreaImprovedSeries = {
  ...tncSource,
  key: 'tnc_conservation_a017_area_improved',
  layerKey: 'tnc_conservation_te_species_area_improved',
  columnKey: 'a017_area_improved',
  color: colors.blue[700],
};

const A018AreaDegradedSeries = {
  ...tncSource,
  key: 'tnc_conservation_a018_area_degraded',
  layerKey: 'tnc_conservation_te_species_area_degraded',
  columnKey: 'a018_area_degraded',
  color: colors.red[600],
};

const A018AreaImprovedSeries = {
  ...tncSource,
  key: 'tnc_conservation_a018_area_improved',
  layerKey: 'tnc_conservation_te_species_area_improved',
  columnKey: 'a018_area_improved',
  color: colors.blue[700],
};

const A024AreaDegradedSeries = {
  ...tncSource,
  key: 'tnc_conservation_a024_area_degraded',
  layerKey: 'tnc_conservation_te_species_area_degraded',
  columnKey: 'a024_area_degraded',
  color: colors.red[600],
};

const A024AreaImprovedSeries = {
  ...tncSource,
  key: 'tnc_conservation_a024_area_improved',
  layerKey: 'tnc_conservation_te_species_area_improved',
  columnKey: 'a024_area_improved',
  color: colors.blue[700],
};

const A025AreaDegradedSeries = {
  ...tncSource,
  key: 'tnc_conservation_a025_area_degraded',
  layerKey: 'tnc_conservation_te_species_area_degraded',
  color: colors.red[600],
  columnKey: 'a025_area_degraded',
};

const A025AreaImprovedSeries = {
  ...tncSource,
  key: 'tnc_conservation_a025_area_improved',
  layerKey: 'tnc_conservation_te_species_area_improved',
  columnKey: 'a025_area_improved',
  color: colors.blue[700],
};

const A031AreaDegradedSeries = {
  ...tncSource,
  key: 'tnc_conservation_a031_area_degraded',
  layerKey: 'tnc_conservation_te_species_area_degraded',
  columnKey: 'a031_area_degraded',
  color: colors.red[600],
};

const A031AreaImprovedSeries = {
  ...tncSource,
  key: 'tnc_conservation_a031_area_improved',
  layerKey: 'tnc_conservation_te_species_area_improved',
  columnKey: 'a031_area_improved',
  color: colors.blue[700],
};

const A035AreaDegradedSeries = {
  ...tncSource,
  key: 'tnc_conservation_a035_area_degraded',
  layerKey: 'tnc_conservation_te_species_area_degraded',
  columnKey: 'a035_area_degraded',
  color: colors.red[600],
};

const A035AreaImprovedSeries = {
  ...tncSource,
  key: 'tnc_conservation_a035_area_improved',
  layerKey: 'tnc_conservation_te_species_area_improved',
  columnKey: 'a035_area_improved',
  color: colors.blue[700],
};

const A044AreaDegradedSeries = {
  ...tncSource,
  key: 'tnc_conservation_a044_area_degraded',
  layerKey: 'tnc_conservation_te_species_area_degraded',
  columnKey: 'a044_area_degraded',
  color: colors.red[600],
};

const A044AreaImprovedSeries = {
  ...tncSource,
  key: 'tnc_conservation_a044_area_improved',
  layerKey: 'tnc_conservation_te_species_area_improved',
  columnKey: 'a044_area_improved',
  color: colors.blue[700],
};

const A067AreaDegradedSeries = {
  ...tncSource,
  key: 'tnc_conservation_a067_area_degraded',
  layerKey: 'tnc_conservation_te_species_area_degraded',
  columnKey: 'a067_area_degraded',
  color: colors.red[600],
};

const A067AreaImprovedSeries = {
  ...tncSource,
  key: 'tnc_conservation_a067_area_improved',
  layerKey: 'tnc_conservation_te_species_area_improved',
  columnKey: 'a067_area_improved',
  color: colors.blue[700],
};

const A070AreaDegradedSeries = {
  ...tncSource,
  key: 'tnc_conservation_a070_area_degraded',
  layerKey: 'tnc_conservation_te_species_area_degraded',
  columnKey: 'a070_area_degraded',
  color: colors.red[600],
};

const A070AreaImprovedSeries = {
  ...tncSource,
  key: 'tnc_conservation_a070_area_improved',
  layerKey: 'tnc_conservation_te_species_area_improved',
  columnKey: 'a070_area_improved',
  color: colors.blue[700],
};

const A071AreaDegradedSeries = {
  ...tncSource,
  key: 'tnc_conservation_a071_area_degraded',
  layerKey: 'tnc_conservation_te_species_area_degraded',
  columnKey: 'a071_area_degraded',
  color: colors.red[600],
};

const A071AreaImprovedSeries = {
  ...tncSource,
  key: 'tnc_conservation_a071_area_improved',
  layerKey: 'tnc_conservation_te_species_area_improved',
  columnKey: 'a071_area_improved',
  color: colors.blue[700],
};

const B109AreaDegradedSeries = {
  ...tncSource,
  key: 'tnc_conservation_b109_area_degraded',
  layerKey: 'tnc_conservation_te_species_area_degraded',
  columnKey: 'b109_area_degraded',
  color: colors.red[600],
};

const B109AreaImprovedSeries = {
  ...tncSource,
  key: 'tnc_conservation_b109_area_improved',
  layerKey: 'tnc_conservation_te_species_area_improved',
  columnKey: 'b109_area_improved',
  color: colors.blue[700],
};

const B113AreaDegradedSeries = {
  ...tncSource,
  key: 'tnc_conservation_b113_area_degraded',
  layerKey: 'tnc_conservation_te_species_area_degraded',
  columnKey: 'b113_area_degraded',
  color: colors.red[600],
};

const B113AreaImprovedSeries = {
  ...tncSource,
  key: 'tnc_conservation_b113_area_improved',
  layerKey: 'tnc_conservation_te_species_area_improved',
  columnKey: 'b113_area_improved',
  color: colors.blue[700],
};

const B121AreaDegradedSeries = {
  ...tncSource,
  key: 'tnc_conservation_b121_area_degraded',
  layerKey: 'tnc_conservation_te_species_area_degraded',
  columnKey: 'b121_area_degraded',
  color: colors.red[600],
};

const B121AreaImprovedSeries = {
  ...tncSource,
  key: 'tnc_conservation_b121_area_improved',
  layerKey: 'tnc_conservation_te_species_area_improved',
  columnKey: 'b121_area_improved',
  color: colors.blue[700],
};

const B143AreaDegradedSeries = {
  ...tncSource,
  key: 'tnc_conservation_b143_area_degraded',
  layerKey: 'tnc_conservation_te_species_area_degraded',
  columnKey: 'b143_area_degraded',
  color: colors.red[600],
};

const B143AreaImprovedSeries = {
  ...tncSource,
  key: 'tnc_conservation_b143_area_improved',
  layerKey: 'tnc_conservation_te_species_area_improved',
  columnKey: 'b143_area_improved',
  color: colors.blue[700],
};

const B144AreaDegradedSeries = {
  ...tncSource,
  key: 'tnc_conservation_b144_area_degraded',
  layerKey: 'tnc_conservation_te_species_area_degraded',
  columnKey: 'b144_area_degraded',
  color: colors.red[600],
};

const B144AreaImprovedSeries = {
  ...tncSource,
  key: 'tnc_conservation_b144_area_improved',
  layerKey: 'tnc_conservation_te_species_area_improved',
  columnKey: 'b144_area_improved',
  color: colors.blue[700],
};

const B150AreaDegradedSeries = {
  ...tncSource,
  key: 'tnc_conservation_b150_area_degraded',
  layerKey: 'tnc_conservation_te_species_area_degraded',
  columnKey: 'b150_area_degraded',
  color: colors.red[600],
};

const B150AreaImprovedSeries = {
  ...tncSource,
  key: 'tnc_conservation_b150_area_improved',
  layerKey: 'tnc_conservation_te_species_area_improved',
  columnKey: 'b150_area_improved',
  color: colors.blue[700],
};

const B154AreaDegradedSeries = {
  ...tncSource,
  key: 'tnc_conservation_b154_area_degraded',
  layerKey: 'tnc_conservation_te_species_area_degraded',
  columnKey: 'b154_area_degraded',
  color: colors.red[600],
};

const B154AreaImprovedSeries = {
  ...tncSource,
  key: 'tnc_conservation_b154_area_improved',
  layerKey: 'tnc_conservation_te_species_area_improved',
  columnKey: 'b154_area_improved',
  color: colors.blue[700],
};

const B234AreaDegradedSeries = {
  ...tncSource,
  key: 'tnc_conservation_b234_area_degraded',
  layerKey: 'tnc_conservation_te_species_area_degraded',
  columnKey: 'b234_area_degraded',
  color: colors.red[600],
};

const B234AreaImprovedSeries = {
  ...tncSource,
  key: 'tnc_conservation_b234_area_improved',
  layerKey: 'tnc_conservation_te_species_area_improved',
  columnKey: 'b234_area_improved',
  color: colors.blue[700],
};

const B240AreaDegradedSeries = {
  ...tncSource,
  key: 'tnc_conservation_b240_area_degraded',
  layerKey: 'tnc_conservation_te_species_area_degraded',
  columnKey: 'b240_area_degraded',
  color: colors.red[600],
};

const B240AreaImprovedSeries = {
  ...tncSource,
  key: 'tnc_conservation_b240_area_improved',
  layerKey: 'tnc_conservation_te_species_area_improved',
  columnKey: 'b240_area_improved',
  color: colors.blue[700],
};

const B241AreaDegradedSeries = {
  ...tncSource,
  key: 'tnc_conservation_b241_area_degraded',
  layerKey: 'tnc_conservation_te_species_area_degraded',
  columnKey: 'b241_area_degraded',
  color: colors.red[600],
};

const B241AreaImprovedSeries = {
  ...tncSource,
  key: 'tnc_conservation_b241_area_improved',
  layerKey: 'tnc_conservation_te_species_area_improved',
  columnKey: 'b241_area_improved',
  color: colors.blue[700],
};

const B259AreaDegradedSeries = {
  ...tncSource,
  key: 'tnc_conservation_b259_area_degraded',
  layerKey: 'tnc_conservation_te_species_area_degraded',
  columnKey: 'b259_area_degraded',
  color: colors.red[600],
};

const B259AreaImprovedSeries = {
  ...tncSource,
  key: 'tnc_conservation_b259_area_improved',
  layerKey: 'tnc_conservation_te_species_area_improved',
  columnKey: 'b259_area_improved',
  color: colors.blue[700],
};

const B268AreaDegradedSeries = {
  ...tncSource,
  key: 'tnc_conservation_b268_area_degraded',
  layerKey: 'tnc_conservation_te_species_area_degraded',
  columnKey: 'b268_area_degraded',
  color: colors.red[600],
};

const B268AreaImprovedSeries = {
  ...tncSource,
  key: 'tnc_conservation_b268_area_improved',
  layerKey: 'tnc_conservation_te_species_area_improved',
  columnKey: 'b268_area_improved',
  color: colors.blue[700],
};

const B270AreaDegradedSeries = {
  ...tncSource,
  key: 'tnc_conservation_b270_area_degraded',
  layerKey: 'tnc_conservation_te_species_area_degraded',
  columnKey: 'b270_area_degraded',
  color: colors.red[600],
};

const B270AreaImprovedSeries = {
  ...tncSource,
  key: 'tnc_conservation_b270_area_improved',
  layerKey: 'tnc_conservation_te_species_area_improved',
  columnKey: 'b270_area_improved',
  color: colors.blue[700],
};

const B271AreaDegradedSeries = {
  ...tncSource,
  key: 'tnc_conservation_b271_area_degraded',
  layerKey: 'tnc_conservation_te_species_area_degraded',
  columnKey: 'b271_area_degraded',
  color: colors.red[600],
};

const B271AreaImprovedSeries = {
  ...tncSource,
  key: 'tnc_conservation_b271_area_improved',
  layerKey: 'tnc_conservation_te_species_area_improved',
  columnKey: 'b271_area_improved',
  color: colors.blue[700],
};

const B297AreaDegradedSeries = {
  ...tncSource,
  key: 'tnc_conservation_b297_area_degraded',
  layerKey: 'tnc_conservation_te_species_area_degraded',
  columnKey: 'b297_area_degraded',
  color: colors.red[600],
};

const B297AreaImprovedSeries = {
  ...tncSource,
  key: 'tnc_conservation_b297_area_improved',
  layerKey: 'tnc_conservation_te_species_area_improved',
  columnKey: 'b297_area_improved',
  color: colors.blue[700],
};

const B315AreaDegradedSeries = {
  ...tncSource,
  key: 'tnc_conservation_b315_area_degraded',
  layerKey: 'tnc_conservation_te_species_area_degraded',
  columnKey: 'b315_area_degraded',
  color: colors.red[600],
};

const B315AreaImprovedSeries = {
  ...tncSource,
  key: 'tnc_conservation_b315_area_improved',
  layerKey: 'tnc_conservation_te_species_area_improved',
  columnKey: 'b315_area_improved',
  color: colors.blue[700],
};

const B342AreaDegradedSeries = {
  ...tncSource,
  key: 'tnc_conservation_b342_area_degraded',
  layerKey: 'tnc_conservation_te_species_area_degraded',
  columnKey: 'b342_area_degraded',
  color: colors.red[600],
};

const B342AreaImprovedSeries = {
  ...tncSource,
  key: 'tnc_conservation_b342_area_improved',
  layerKey: 'tnc_conservation_te_species_area_improved',
  columnKey: 'b342_area_improved',
  color: colors.blue[700],
};

const B410AreaDegradedSeries = {
  ...tncSource,
  key: 'tnc_conservation_b410_area_degraded',
  layerKey: 'tnc_conservation_te_species_area_degraded',
  columnKey: 'b410_area_degraded',
  color: colors.red[600],
};

const B410AreaImprovedSeries = {
  ...tncSource,
  key: 'tnc_conservation_b410_area_improved',
  layerKey: 'tnc_conservation_te_species_area_improved',
  columnKey: 'b410_area_improved',
  color: colors.blue[700],
};

const B413AreaDegradedSeries = {
  ...tncSource,
  key: 'tnc_conservation_b413_area_degraded',
  layerKey: 'tnc_conservation_te_species_area_degraded',
  columnKey: 'b413_area_degraded',
  color: colors.red[600],
};

const B413AreaImprovedSeries = {
  ...tncSource,
  key: 'tnc_conservation_b413_area_improved',
  layerKey: 'tnc_conservation_te_species_area_improved',
  columnKey: 'b413_area_improved',
  color: colors.blue[700],
};

const B484AreaDegradedSeries = {
  ...tncSource,
  key: 'tnc_conservation_b484_area_degraded',
  layerKey: 'tnc_conservation_te_species_area_degraded',
  columnKey: 'b484_area_degraded',
  color: colors.red[600],
};

const B484AreaImprovedSeries = {
  ...tncSource,
  key: 'tnc_conservation_b484_area_improved',
  layerKey: 'tnc_conservation_te_species_area_improved',
  columnKey: 'b484_area_improved',
  color: colors.blue[700],
};

const B497AreaDegradedSeries = {
  ...tncSource,
  key: 'tnc_conservation_b497_area_degraded',
  layerKey: 'tnc_conservation_te_species_area_degraded',
  columnKey: 'b497_area_degraded',
  color: colors.red[600],
};

const B497AreaImprovedSeries = {
  ...tncSource,
  key: 'tnc_conservation_b497_area_improved',
  layerKey: 'tnc_conservation_te_species_area_improved',
  columnKey: 'b497_area_improved',
  color: colors.blue[700],
};

const B499AreaDegradedSeries = {
  ...tncSource,
  key: 'tnc_conservation_b499_area_degraded',
  layerKey: 'tnc_conservation_te_species_area_degraded',
  columnKey: 'b499_area_degraded',
  color: colors.red[600],
};

const B499AreaImprovedSeries = {
  ...tncSource,
  key: 'tnc_conservation_b499_area_improved',
  layerKey: 'tnc_conservation_te_species_area_improved',
  columnKey: 'b499_area_improved',
  color: colors.blue[700],
};

const B549AreaDegradedSeries = {
  ...tncSource,
  key: 'tnc_conservation_b549_area_degraded',
  layerKey: 'tnc_conservation_te_species_area_degraded',
  columnKey: 'b549_area_degraded',
  color: colors.red[600],
};

const B549AreaImprovedSeries = {
  ...tncSource,
  key: 'tnc_conservation_b549_area_improved',
  layerKey: 'tnc_conservation_te_species_area_improved',
  columnKey: 'b549_area_improved',
  color: colors.blue[700],
};

const B553AreaDegradedSeries = {
  ...tncSource,
  key: 'tnc_conservation_b553_area_degraded',
  layerKey: 'tnc_conservation_te_species_area_degraded',
  columnKey: 'b553_area_degraded',
  color: colors.red[600],
};

const B553AreaImprovedSeries = {
  ...tncSource,
  key: 'tnc_conservation_b553_area_improved',
  layerKey: 'tnc_conservation_te_species_area_improved',
  columnKey: 'b553_area_improved',
  color: colors.blue[700],
};

const M006AreaDegradedSeries = {
  ...tncSource,
  key: 'tnc_conservation_m006_area_degraded',
  layerKey: 'tnc_conservation_te_species_area_degraded',
  columnKey: 'm006_area_degraded',
  color: colors.red[600],
};

const M006AreaImprovedSeries = {
  ...tncSource,
  key: 'tnc_conservation_m006_area_improved',
  layerKey: 'tnc_conservation_te_species_area_improved',
  columnKey: 'm006_area_improved',
  color: colors.blue[700],
};

const M045AreaDegradedSeries = {
  ...tncSource,
  key: 'tnc_conservation_m045_area_degraded',
  layerKey: 'tnc_conservation_te_species_area_degraded',
  columnKey: 'm045_area_degraded',
  color: colors.red[600],
};

const M045AreaImprovedSeries = {
  ...tncSource,
  key: 'tnc_conservation_m045_area_improved',
  layerKey: 'tnc_conservation_te_species_area_improved',
  columnKey: 'm045_area_improved',
  color: colors.blue[700],
};

const M052AreaDegradedSeries = {
  ...tncSource,
  key: 'tnc_conservation_m052_area_degraded',
  layerKey: 'tnc_conservation_te_species_area_degraded',
  columnKey: 'm052_area_degraded',
  color: colors.red[600],
};

const M052AreaImprovedSeries = {
  ...tncSource,
  key: 'tnc_conservation_m052_area_improved',
  layerKey: 'tnc_conservation_te_species_area_improved',
  columnKey: 'm052_area_improved',
  color: colors.blue[700],
};

const M068AreaDegradedSeries = {
  ...tncSource,
  key: 'tnc_conservation_m068_area_degraded',
  layerKey: 'tnc_conservation_te_species_area_degraded',
  columnKey: 'm068_area_degraded',
  color: colors.red[600],
};

const M068AreaImprovedSeries = {
  ...tncSource,
  key: 'tnc_conservation_m068_area_improved',
  layerKey: 'tnc_conservation_te_species_area_improved',
  columnKey: 'm068_area_improved',
  color: colors.blue[700],
};

const M073AreaDegradedSeries = {
  ...tncSource,
  key: 'tnc_conservation_m073_area_degraded',
  layerKey: 'tnc_conservation_te_species_area_degraded',
  columnKey: 'm073_area_degraded',
  color: colors.red[600],
};

const M073AreaImprovedSeries = {
  ...tncSource,
  key: 'tnc_conservation_m073_area_improved',
  layerKey: 'tnc_conservation_te_species_area_improved',
  columnKey: 'm073_area_improved',
  color: colors.blue[700],
};

const M086AreaDegradedSeries = {
  ...tncSource,
  key: 'tnc_conservation_m086_area_degraded',
  layerKey: 'tnc_conservation_te_species_area_degraded',
  columnKey: 'm086_area_degraded',
  color: colors.red[600],
};

const M086AreaImprovedSeries = {
  ...tncSource,
  key: 'tnc_conservation_m086_area_improved',
  layerKey: 'tnc_conservation_te_species_area_improved',
  columnKey: 'm086_area_improved',
  color: colors.blue[700],
};

const M104AreaDegradedSeries = {
  ...tncSource,
  key: 'tnc_conservation_m104_area_degraded',
  layerKey: 'tnc_conservation_te_species_area_degraded',
  columnKey: 'm104_area_degraded',
  color: colors.red[600],
};

const M104AreaImprovedSeries = {
  ...tncSource,
  key: 'tnc_conservation_m104_area_improved',
  layerKey: 'tnc_conservation_te_species_area_improved',
  columnKey: 'm104_area_improved',
  color: colors.blue[700],
};

const M106AreaDegradedSeries = {
  ...tncSource,
  key: 'tnc_conservation_m106_area_degraded',
  layerKey: 'tnc_conservation_te_species_area_degraded',
  columnKey: 'm106_area_degraded',
  color: colors.red[600],
};

const M106AreaImprovedSeries = {
  ...tncSource,
  key: 'tnc_conservation_m106_area_improved',
  layerKey: 'tnc_conservation_te_species_area_improved',
  columnKey: 'm106_area_improved',
  color: colors.blue[700],
};

const M108AreaDegradedSeries = {
  ...tncSource,
  key: 'tnc_conservation_m108_area_degraded',
  layerKey: 'tnc_conservation_te_species_area_degraded',
  columnKey: 'm108_area_degraded',
  color: colors.red[600],
};

const M108AreaImprovedSeries = {
  ...tncSource,
  key: 'tnc_conservation_m108_area_improved',
  layerKey: 'tnc_conservation_te_species_area_improved',
  columnKey: 'm108_area_improved',
  color: colors.blue[700],
};

const M110AreaDegradedSeries = {
  ...tncSource,
  key: 'tnc_conservation_m110_area_degraded',
  layerKey: 'tnc_conservation_te_species_area_degraded',
  columnKey: 'm110_area_degraded',
  color: colors.red[600],
};

const M110AreaImprovedSeries = {
  ...tncSource,
  key: 'tnc_conservation_m110_area_improved',
  layerKey: 'tnc_conservation_te_species_area_improved',
  columnKey: 'm110_area_improved',
  color: colors.blue[700],
};

const M111AreaDegradedSeries = {
  ...tncSource,
  key: 'tnc_conservation_m111_area_degraded',
  layerKey: 'tnc_conservation_te_species_area_degraded',
  columnKey: 'm111_area_degraded',
  color: colors.red[600],
};

const M111AreaImprovedSeries = {
  ...tncSource,
  key: 'tnc_conservation_m111_area_improved',
  layerKey: 'tnc_conservation_te_species_area_improved',
  columnKey: 'm111_area_improved',
  color: colors.blue[700],
};

const M114AreaDegradedSeries = {
  ...tncSource,
  key: 'tnc_conservation_m114_area_degraded',
  layerKey: 'tnc_conservation_te_species_area_degraded',
  columnKey: 'm114_area_degraded',
  color: colors.red[600],
};

const M114AreaImprovedSeries = {
  ...tncSource,
  key: 'tnc_conservation_m114_area_improved',
  layerKey: 'tnc_conservation_te_species_area_improved',
  columnKey: 'm114_area_improved',
  color: colors.blue[700],
};

const M127AreaDegradedSeries = {
  ...tncSource,
  key: 'tnc_conservation_m127_area_degraded',
  layerKey: 'tnc_conservation_te_species_area_degraded',
  columnKey: 'm127_area_degraded',
  color: colors.red[600],
};

const M127AreaImprovedSeries = {
  ...tncSource,
  key: 'tnc_conservation_m127_area_improved',
  layerKey: 'tnc_conservation_te_species_area_improved',
  columnKey: 'm127_area_improved',
  color: colors.blue[700],
};

const M134AreaDegradedSeries = {
  ...tncSource,
  key: 'tnc_conservation_m134_area_degraded',
  layerKey: 'tnc_conservation_te_species_area_degraded',
  columnKey: 'm134_area_degraded',
  color: colors.red[600],
};

const M134AreaImprovedSeries = {
  ...tncSource,
  key: 'tnc_conservation_m134_area_improved',
  layerKey: 'tnc_conservation_te_species_area_improved',
  columnKey: 'm134_area_improved',
  color: colors.blue[700],
};

const M147AreaDegradedSeries = {
  ...tncSource,
  key: 'tnc_conservation_m147_area_degraded',
  layerKey: 'tnc_conservation_te_species_area_degraded',
  columnKey: 'm147_area_degraded',
  color: colors.red[600],
};

const M147AreaImprovedSeries = {
  ...tncSource,
  key: 'tnc_conservation_m147_area_improved',
  layerKey: 'tnc_conservation_te_species_area_improved',
  columnKey: 'm147_area_improved',
  color: colors.blue[700],
};

const M148AreaDegradedSeries = {
  ...tncSource,
  key: 'tnc_conservation_m148_area_degraded',
  layerKey: 'tnc_conservation_te_species_area_degraded',
  columnKey: 'm148_area_degraded',
  color: colors.red[600],
};

const M148AreaImprovedSeries = {
  ...tncSource,
  key: 'tnc_conservation_m148_area_improved',
  layerKey: 'tnc_conservation_te_species_area_improved',
  columnKey: 'm148_area_improved',
  color: colors.blue[700],
};

const M150AreaDegradedSeries = {
  ...tncSource,
  key: 'tnc_conservation_m150_area_degraded',
  layerKey: 'tnc_conservation_te_species_area_degraded',
  columnKey: 'm150_area_degraded',
  color: colors.red[600],
};

const M150AreaImprovedSeries = {
  ...tncSource,
  key: 'tnc_conservation_m150_area_improved',
  layerKey: 'tnc_conservation_te_species_area_improved',
  columnKey: 'm150_area_improved',
  color: colors.blue[700],
};

const M159AreaDegradedSeries = {
  ...tncSource,
  key: 'tnc_conservation_m159_area_degraded',
  layerKey: 'tnc_conservation_te_species_area_degraded',
  columnKey: 'm159_area_degraded',
  color: colors.red[600],
};

const M159AreaImprovedSeries = {
  ...tncSource,
  key: 'tnc_conservation_m159_area_improved',
  layerKey: 'tnc_conservation_te_species_area_improved',
  columnKey: 'm159_area_improved',
  color: colors.blue[700],
};

const M164AreaDegradedSeries = {
  ...tncSource,
  key: 'tnc_conservation_m164_area_degraded',
  layerKey: 'tnc_conservation_te_species_area_degraded',
  columnKey: 'm164_area_degraded',
  color: colors.red[600],
};

const M164AreaImprovedSeries = {
  ...tncSource,
  key: 'tnc_conservation_m164_area_improved',
  layerKey: 'tnc_conservation_te_species_area_improved',
  columnKey: 'm164_area_improved',
  color: colors.blue[700],
};

const M168AreaDegradedSeries = {
  ...tncSource,
  key: 'tnc_conservation_m168_area_degraded',
  layerKey: 'tnc_conservation_te_species_area_degraded',
  columnKey: 'm168_area_degraded',
  color: colors.red[600],
};

const M168AreaImprovedSeries = {
  ...tncSource,
  key: 'tnc_conservation_m168_area_improved',
  layerKey: 'tnc_conservation_te_species_area_improved',
  columnKey: 'm168_area_improved',
  color: colors.blue[700],
};

const M183AreaDegradedSeries = {
  ...tncSource,
  key: 'tnc_conservation_m183_area_degraded',
  layerKey: 'tnc_conservation_te_species_area_degraded',
  columnKey: 'm183_area_degraded',
  color: colors.red[600],
};

const M183AreaImprovedSeries = {
  ...tncSource,
  key: 'tnc_conservation_m183_area_improved',
  layerKey: 'tnc_conservation_te_species_area_improved',
  columnKey: 'm183_area_improved',
  color: colors.blue[700],
};

const R005AreaDegradedSeries = {
  ...tncSource,
  key: 'tnc_conservation_r005_area_degraded',
  layerKey: 'tnc_conservation_te_species_area_degraded',
  columnKey: 'r005_area_degraded',
  color: colors.red[600],
};

const R005AreaImprovedSeries = {
  ...tncSource,
  key: 'tnc_conservation_r005_area_improved',
  layerKey: 'tnc_conservation_te_species_area_improved',
  columnKey: 'r005_area_improved',
  color: colors.blue[700],
};
const R007AreaDegradedSeries = {
  ...tncSource,
  key: 'tnc_conservation_r007_area_degraded',
  layerKey: 'tnc_conservation_te_species_area_degraded',
  columnKey: 'r007_area_degraded',
  color: colors.red[600],
};

const R007AreaImprovedSeries = {
  ...tncSource,
  key: 'tnc_conservation_r007_area_improved',
  layerKey: 'tnc_conservation_te_species_area_improved',
  columnKey: 'r007_area_improved',
  color: colors.blue[700],
};

const R014AreaDegradedSeries = {
  ...tncSource,
  key: 'tnc_conservation_r014_area_degraded',
  layerKey: 'tnc_conservation_te_species_area_degraded',
  columnKey: 'r014_area_degraded',
  color: colors.red[600],
};

const R014AreaImprovedSeries = {
  ...tncSource,
  key: 'tnc_conservation_r014_area_improved',
  layerKey: 'tnc_conservation_te_species_area_improved',
  columnKey: 'r014_area_improved',
  color: colors.blue[700],
};

const R019AreaDegradedSeries = {
  ...tncSource,
  key: 'tnc_conservation_r019_area_degraded',
  layerKey: 'tnc_conservation_te_species_area_degraded',
  columnKey: 'r019_area_degraded',
  color: colors.red[600],
};

const R019AreaImprovedSeries = {
  ...tncSource,
  key: 'tnc_conservation_r019_area_improved',
  layerKey: 'tnc_conservation_te_species_area_improved',
  columnKey: 'r019_area_improved',
  color: colors.blue[700],
};

const R035AreaDegradedSeries = {
  ...tncSource,
  key: 'tnc_conservation_r035_area_degraded',
  layerKey: 'tnc_conservation_te_species_area_degraded',
  columnKey: 'r035_area_degraded',
  color: colors.red[600],
};

const R035AreaImprovedSeries = {
  ...tncSource,
  key: 'tnc_conservation_r035_area_improved',
  layerKey: 'tnc_conservation_te_species_area_improved',
  columnKey: 'r035_area_improved',
  color: colors.blue[700],
};

const R046AreaDegradedSeries = {
  ...tncSource,
  key: 'tnc_conservation_r046_area_degraded',
  layerKey: 'tnc_conservation_te_species_area_degraded',
  columnKey: 'r046_area_degraded',
  color: colors.red[600],
};

const R046AreaImprovedSeries = {
  ...tncSource,
  key: 'tnc_conservation_r046_area_improved',
  layerKey: 'tnc_conservation_te_species_area_improved',
  columnKey: 'r046_area_improved',
  color: colors.blue[700],
};

const R053AreaDegradedSeries = {
  ...tncSource,
  key: 'tnc_conservation_r053_area_degraded',
  layerKey: 'tnc_conservation_te_species_area_degraded',
  columnKey: 'r053_area_degraded',
  color: colors.red[600],
};

const R053AreaImprovedSeries = {
  ...tncSource,
  key: 'tnc_conservation_r053_area_improved',
  layerKey: 'tnc_conservation_te_species_area_improved',
  columnKey: 'r053_area_improved',
  color: colors.blue[700],
};

const R061AreaDegradedSeries = {
  ...tncSource,
  key: 'tnc_conservation_r061_area_degraded',
  layerKey: 'tnc_conservation_te_species_area_degraded',
  columnKey: 'r061_area_degraded',
  color: colors.red[600],
};

const R061AreaImprovedSeries = {
  ...tncSource,
  key: 'tnc_conservation_r061_area_improved',
  layerKey: 'tnc_conservation_te_species_area_improved',
  columnKey: 'r061_area_improved',
  color: colors.blue[700],
};

const R079AreaDegradedSeries = {
  ...tncSource,
  key: 'tnc_conservation_r079_area_degraded',
  layerKey: 'tnc_conservation_te_species_area_degraded',
  columnKey: 'r079_area_degraded',
  color: colors.red[600],
};

const R079AreaImprovedSeries = {
  ...tncSource,
  key: 'tnc_conservation_r079_area_improved',
  layerKey: 'tnc_conservation_te_species_area_improved',
  columnKey: 'r079_area_improved',
  color: colors.blue[700],
};

export const tncChartsSharedHabitat: LegacyChart[] = [
  {
    name: t`Net Change in Land Cover: Essential Connectivity Areas`,
    key: 'net_eca',
    description: t`Net Change in Land Use within Essential
  Connectivity Areas (Developed/Natural/Ag)`,
    units: getUnitName(ACRES),
    series: [
      EcaNetEcaUrbanSeries,
      EcaNetEcaAgricultureSeries,
      EcaNetEcaNaturalSeries,
    ],
    emptyChartContent: defaultEmptyChartContent,
    showLegend: true,
    showFullLabel: true,
  },
  {
    name: t`Net Change in Land Cover: TNC Priority Areas`,
    key: 'net_pca',
    description: t`Net Change in Land Use within TNC Priority Areas (Developed/Natural/Ag)`,
    units: getUnitName(ACRES),
    series: [
      PcaNetTncPriorityAreasUrbanSeries,
      PcaNetTncPriorityAreasAgricultureSeries,
      PcaNetTncPriorityAreasNaturalSeries,
    ],
    emptyChartContent: defaultEmptyChartContent,
    showLegend: true,
    showFullLabel: true,
  },
  {
    name: t`Net Change in Land Cover: Audubon Important Bird Areas`,
    key: 'net_audubon_iba',
    description: t`Net Change in Land Use within Audubon IBA (Developed/Natural/Ag)`,
    units: getUnitName(ACRES),
    series: [
      AudubonNetAudubonUrbanSeries,
      AudubonNetAudubonAgricultureSeries,
      AudubonNetAudubonNaturalSeries,
    ],
    emptyChartContent: defaultEmptyChartContent,
    showLegend: true,
    showFullLabel: true,
  },
  {
    name: t`Net Change in Species Movement Potential`,
    key: 'net_species_movement_potential',
    description: t`Net Change in Acres of High, Medium and Low Species Movement Potential`,
    units: getUnitName(ACRES),
    series: [SmpNetLowSmSeries, SmpNetMediumSmSeries, SmpNetHighSmSeries],
    emptyChartContent: defaultEmptyChartContent,
    showLegend: true,
    showFullLabel: true,
  },
  {
    name: t`Habitat Change: Amphibians`,
    key: 'net_amphibians',
    description: t`Habitat Degraded and Improved for Amphibians`,
    subtitle: t`Average area impacted`,
    units: getUnitName(ACRES, IN_SENTENCE),
    series: [
      AmphibiansNetAmphibiansAreaDegradedSeries,
      AmphibiansNetAmphibiansAreaImprovedSeries,
    ],
    emptyChartContent: defaultEmptyChartContent,
    showLegend: true,
    showFullLabel: true,
  },
  {
    name: t`Habitat Change: Birds`,
    key: 'net_birds',
    subtitle: t`Average area impacted`,
    units: getUnitName(ACRES, IN_SENTENCE),
    description: t`Habitat Degraded and Improved for Birds`,
    series: [BirdsNetBirdsAreaDegradedSeries, BirdsNetBirdsAreaImprovedSeries],
    emptyChartContent: defaultEmptyChartContent,
    showLegend: true,
    showFullLabel: true,
  },
  {
    name: t`Habitat Change: Mammals`,
    key: 'net_mammals',
    subtitle: t`Average area impacted`,
    units: getUnitName(ACRES, IN_SENTENCE),
    description: t`Habitat Degraded and Improved for Mammals`,
    series: [
      MammalsNetMammalsAreaDegradedSeries,
      MammalsNetMammalsAreaImprovedSeries,
    ],
    emptyChartContent: defaultEmptyChartContent,
  },
  {
    name: t`Habitat Change: Reptiles`,
    key: 'net_repltiles',
    subtitle: t`Average area impacted`,
    units: getUnitName(ACRES, IN_SENTENCE),
    description: t`Habitat Degraded and Improved for Reptiles`,
    series: [
      ReptilesNetReptilesAreaDegradedSeries,
      ReptilesNetReptilesAreaImprovedSeries,
    ],
    emptyChartContent: defaultEmptyChartContent,
    showLegend: true,
    showFullLabel: true,
  },
  {
    name: t`Habitat Change: Threatened and Endangered Species`,
    subtitle: t`Average area impacted`,
    key: 'net_threatened_endangered_species',
    description: t`Habitat Degraded and Improved for Threatened and Endangered Species`,
    units: getUnitName(ACRES, IN_SENTENCE),
    series: [
      TesNetThreatenedEndangeredAreaDegradedSeries,
      TesNetThreatenedEndangeredAreaImprovedSeries,
    ],
    emptyChartContent: defaultEmptyChartContent,
    showLegend: true,
    showFullLabel: true,
  },
  {
    name: t`Habitat Change: Species Vulnerable to Climate Change (except Birds)`,
    key: 'net_svcc',
    subtitle: t`Average area impacted`,
    description: t`Habitat Degraded and Improved for
  Species Vulnerable to Climate Change (except Birds)`,
    units: getUnitName(ACRES, IN_SENTENCE),
    series: [
      SvccNetClimateChangeExceptBirdsAreaDegradedSeries,
      SvccNetClimateChangeExceptBirdsAreaImprovedSeries,
    ],
    emptyChartContent: defaultEmptyChartContent,
    showLegend: true,
    showFullLabel: true,
  },
  {
    name: t`Habitat Change: Birds Vulnerable to Climate Change`,
    key: 'net_bvcc',
    subtitle: t`Average area impacted`,
    description: t`Habitat Degraded and Improved for Birds Vulnerable to Climate Change`,
    units: getUnitName(ACRES, IN_SENTENCE),
    series: [
      BvccNetClimateChangeBirdsAreaDegradedSeries,
      BvccNetClimateChangeBirdsAreaImprovedSeries,
    ],
    emptyChartContent: defaultEmptyChartContent,
    showLegend: true,
    showFullLabel: true,
  },
];

export const tncChartsHabitatTES: LegacyChart[] = [
  {
    name: t`Habitat Improved for Threatened and Endangered Species`,
    key: 'tes_improved',
    description: t`Habitat Improved for Threatened and Endangered Species`,
    units: getUnitName(ACRES),
    series: [
      A001AreaImprovedSeries,
      A003AreaImprovedSeries,
      A011AreaImprovedSeries,
      A013AreaImprovedSeries,
      A017AreaImprovedSeries,
      A018AreaImprovedSeries,
      A024AreaImprovedSeries,
      A025AreaImprovedSeries,
      A031AreaImprovedSeries,
      A035AreaImprovedSeries,
      A044AreaImprovedSeries,
      A067AreaImprovedSeries,
      A070AreaImprovedSeries,
      A071AreaImprovedSeries,
      B109AreaImprovedSeries,
      B113AreaImprovedSeries,
      B121AreaImprovedSeries,
      B143AreaImprovedSeries,
      B144AreaImprovedSeries,
      B150AreaImprovedSeries,
      B154AreaImprovedSeries,
      B234AreaImprovedSeries,
      B240AreaImprovedSeries,
      B241AreaImprovedSeries,
      B259AreaImprovedSeries,
      B268AreaImprovedSeries,
      B270AreaImprovedSeries,
      B271AreaImprovedSeries,
      B297AreaImprovedSeries,
      B315AreaImprovedSeries,
      B342AreaImprovedSeries,
      B410AreaImprovedSeries,
      B413AreaImprovedSeries,
      B484AreaImprovedSeries,
      B497AreaImprovedSeries,
      B499AreaImprovedSeries,
      B549AreaImprovedSeries,
      B553AreaImprovedSeries,
      M006AreaImprovedSeries,
      M045AreaImprovedSeries,
      M052AreaImprovedSeries,
      M068AreaImprovedSeries,
      M073AreaImprovedSeries,
      M086AreaImprovedSeries,
      M104AreaImprovedSeries,
      M106AreaImprovedSeries,
      M108AreaImprovedSeries,
      M110AreaImprovedSeries,
      M111AreaImprovedSeries,
      M114AreaImprovedSeries,
      M127AreaImprovedSeries,
      M134AreaImprovedSeries,
      M147AreaImprovedSeries,
      M148AreaImprovedSeries,
      M150AreaImprovedSeries,
      M159AreaImprovedSeries,
      M164AreaImprovedSeries,
      M168AreaImprovedSeries,
      M183AreaImprovedSeries,
      R005AreaImprovedSeries,
      R007AreaImprovedSeries,
      R014AreaImprovedSeries,
      R019AreaImprovedSeries,
      R035AreaImprovedSeries,
      R046AreaImprovedSeries,
      R053AreaImprovedSeries,
      R061AreaImprovedSeries,
      R079AreaImprovedSeries,
    ],
    emptyChartContent: defaultEmptyChartContent,
    showLegend: true,
    showFullLabel: true,
  },
  {
    name: t`Habitat Degraded for Threatened and Endangered Species`,
    key: 'tes_degraded',
    units: getUnitName(ACRES),
    description: t`Habitat Degraded for Threatened and Endangered Species`,
    series: [
      A001AreaDegradedSeries,
      A003AreaDegradedSeries,
      A011AreaDegradedSeries,
      A013AreaDegradedSeries,
      A017AreaDegradedSeries,
      A018AreaDegradedSeries,
      A024AreaDegradedSeries,
      A025AreaDegradedSeries,
      A031AreaDegradedSeries,
      A035AreaDegradedSeries,
      A044AreaDegradedSeries,
      A067AreaDegradedSeries,
      A070AreaDegradedSeries,
      A071AreaDegradedSeries,
      B109AreaDegradedSeries,
      B113AreaDegradedSeries,
      B121AreaDegradedSeries,
      B143AreaDegradedSeries,
      B144AreaDegradedSeries,
      B150AreaDegradedSeries,
      B154AreaDegradedSeries,
      B234AreaDegradedSeries,
      B240AreaDegradedSeries,
      B241AreaDegradedSeries,
      B259AreaDegradedSeries,
      B268AreaDegradedSeries,
      B270AreaDegradedSeries,
      B271AreaDegradedSeries,
      B297AreaDegradedSeries,
      B315AreaDegradedSeries,
      B342AreaDegradedSeries,
      B410AreaDegradedSeries,
      B413AreaDegradedSeries,
      B484AreaDegradedSeries,
      B497AreaDegradedSeries,
      B499AreaDegradedSeries,
      B549AreaDegradedSeries,
      B553AreaDegradedSeries,
      M006AreaDegradedSeries,
      M045AreaDegradedSeries,
      M052AreaDegradedSeries,
      M068AreaDegradedSeries,
      M073AreaDegradedSeries,
      M086AreaDegradedSeries,
      M104AreaDegradedSeries,
      M106AreaDegradedSeries,
      M108AreaDegradedSeries,
      M110AreaDegradedSeries,
      M111AreaDegradedSeries,
      M114AreaDegradedSeries,
      M127AreaDegradedSeries,
      M134AreaDegradedSeries,
      M147AreaDegradedSeries,
      M148AreaDegradedSeries,
      M150AreaDegradedSeries,
      M159AreaDegradedSeries,
      M164AreaDegradedSeries,
      M168AreaDegradedSeries,
      M183AreaDegradedSeries,
      R005AreaDegradedSeries,
      R007AreaDegradedSeries,
      R014AreaDegradedSeries,
      R019AreaDegradedSeries,
      R035AreaDegradedSeries,
      R046AreaDegradedSeries,
      R053AreaDegradedSeries,
      R061AreaDegradedSeries,
      R079AreaDegradedSeries,
    ],
    emptyChartContent: defaultEmptyChartContent,
    showLegend: true,
    showFullLabel: true,
  },
];

export const tncChartsHabitat: LegacyReport = Object.freeze({
  key: 'tnc_conservation_habitat',
  name: t`Conservation - Habitat`,
  colorSpec: {
    primaryColor: colors.lime[700],
    interpolateFromColor: 'rgb(255, 214, 79)',
    interpolateToColor: 'rgb(27, 94, 31)',
  },
  charts: [...tncChartsSharedHabitat, ...tncChartsHabitatTES],
});
