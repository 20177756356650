import { AnyAction } from 'redux';
import { Epic, ofType } from 'redux-observable';
import { merge as observableMerge, Observable, of as observableOf } from 'rxjs';
import { filter, mergeMap } from 'rxjs/operators';

import { getShowAppToasts } from 'uf/app/selectors';
import { MessageAction, removeAppMessage } from 'uf/appservices/actions';
import { APP_ADD_MESSAGE } from 'uf/appservices/ActionTypes';
import { combineEpics } from 'uf/base/epics';
import popupStyles from 'uf/styles/popups.module.css';
import { getToastClassName } from 'uf/ui/base/Toast/action';
import { formatToastMessage, getMessageIcon } from 'uf/ui/base/Toast/message';
import NotificationToaster from 'uf/ui/base/Toast/NotificationToaster';

export const addAppMessageEpic: Epic<MessageAction, any> = (
  action$,
  state$,
) => {
  return action$.pipe(
    ofType(APP_ADD_MESSAGE),
    filter(() => getShowAppToasts(state$.value)),
    mergeMap(({ message }) => {
      const { timeout, action } = message;

      let dismissObserver;
      const dismiss$: Observable<AnyAction> = Observable.create(observer => {
        dismissObserver = observer;
      });

      let actionObserver;
      const toastAction$: Observable<AnyAction> = Observable.create(
        observer => {
          actionObserver = observer;
        },
      );

      const level = action ? action.level : 'info';

      const actionProps = {
        ...action,
        className: getToastClassName(level),
      };

      if (action?.onClick) {
        actionProps.onClick = () => {
          actionObserver.next(action.onClick());
          actionObserver.complete();
        };
      }

      NotificationToaster.show(
        {
          className: popupStyles.extraLargeWidth,
          timeout,
          onDismiss: () => {
            dismissObserver.next(message.id);
            dismissObserver.complete();
          },
          icon: message.status && getMessageIcon(message.status),
          message: formatToastMessage(message),
          action: action && {
            ...actionProps,
          },
        },
        message.replacesMessage || message.id,
      );

      return observableMerge(
        dismiss$.pipe(
          mergeMap(messageId =>
            observableOf(removeAppMessage(messageId as unknown as string)),
          ),
        ),
        toastAction$.pipe(
          mergeMap(onClickAction => observableOf(onClickAction)),
        ),
      );
    }),
  );
};

export default combineEpics(
  {
    addAppMessageEpic,
  },
  'appServices',
);
