import colors from 'material-colors';

import {
  CuratedColumnSymbology,
  CUSTOM_THEME,
  DEFAULT_OPACITY,
  InterpolationTypes,
  PaintColorTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/public/dataset/california_oehha_calenviroscreen_3_2018': {
    ces_3_0_percentile_range: [
      {
        type: SymbologyTypes.FILL,
        paint: {
          'fill-color': {
            property: 'ces_3_0_percentile_range',
            type: PaintColorTypes.CATEGORICAL,
            stops: [
              { value: '1-5% (lowest scores)', color: colors.green[500] },
              { value: '5-10%', color: '#5FB54D' },
              { value: '10-15%', color: '#73BC4B' },
              { value: '15-20%', color: '#87C349' },
              { value: '20-25%', color: '#9BC946' },
              { value: '25-30%', color: '#AFD044' },
              { value: '30-35%', color: '#C3D742' },
              { value: '35-40%', color: '#D7DD3F' },
              { value: '40-45%', color: '#EBE43D' },
              { value: '45-50%', color: '#FCD108' },
              { value: '50-55%', color: '#FDDA3A' },
              { value: '55-60%', color: '#FCC93A' },
              { value: '60-65%', color: '#FBB839' },
              { value: '65-70%', color: '#FAA739' },
              { value: '70-75%', color: '#F99738' },
              { value: '75-80%', color: '#F88638' },
              { value: '80-85%', color: '#F77537' },
              { value: '85-90%', color: '#F66437' },
              { value: '90-95%', color: '#F55336' },
              { value: '95-100% (highest scores)', color: colors.red[500] },
            ],
          },
          'fill-opacity': DEFAULT_OPACITY,
        },
        display_hints: {
          scale: SymbologyScales.CATEGORICAL,
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          theme: CUSTOM_THEME,
        },
      },
    ],
  },
};

export default symbologies;
