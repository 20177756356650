import {
  CuratedColumnSymbology,
  CUSTOM_THEME,
  DEFAULT_OPACITY,
  InterpolationTypes,
  PaintColorTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/public/dataset/national_transit_lines_v8': {
    route_type: [
      {
        type: SymbologyTypes.LINE,
        paint: {
          'line-color': {
            property: 'route_type',
            type: PaintColorTypes.CATEGORICAL,
            stops: [
              { value: '3', label: 'Bus', color: '#1f77b4' },
              { value: '5', label: 'Cable Car', color: '#c7ff6f' },
              { value: '4', label: 'Ferry', color: '#2f5bcc' },
              {
                value: '6',
                label: 'Gondola, Suspended Cable Car',
                color: '#7288c0',
              },
              { value: '2', label: 'Rail', color: '#d21245' },
              { value: '1', label: 'Subway, Metro', color: '#faa635' },
              {
                value: '0',
                label: 'Tram, Streetcar, Light Rail',
                color: '#2ca02c',
              },
              { value: '7', label: 'Funicular', color: '#b2877d' },
              { value: '700', label: 'Bus Service', color: '#6497b1' },
              { value: '702', label: 'Express Bus Service', color: '#b3cde0' },
              { value: '800', label: 'Trolley Bus Service', color: '#fb5858' },
              { value: '900', label: 'Tram Service', color: '#fb9f9f' },
              { value: '0.0', label: 'Other Transit Routes', color: '#a6a6a6' },
              {
                value: 'uncategorized',
                label: 'Uncategorized by Operator',
                color: '#a6a6a6',
              },
            ],
          },
          'line-opacity': DEFAULT_OPACITY,
        },
        display_hints: {
          scale: SymbologyScales.CATEGORICAL,
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          theme: CUSTOM_THEME,
        },
      },
    ],
  },
};

export default symbologies;
