import React, { FunctionComponent, useCallback, useRef } from 'react';
import { findDOMNode } from 'react-dom';
import { ScrollView } from 'react-layout-components';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ThunkActionMapDispatch } from 'redux-thunk';

import { ProjectMetadata } from 'uf-api';
import * as appActionCreators from 'uf/app/actions';
import { makeGetActiveScenarioIdForProject } from 'uf/app/selectors';
import { EMPTY_ARRAY } from 'uf/base';
import { DataState, isLoaded, isLoading } from 'uf/data/dataState';
import * as exploreActionCreators from 'uf/explore/actions';
import * as exploreFilterActionCreators from 'uf/explore/actions/filters';
import * as exploreLayerActionCreators from 'uf/explore/actions/layers';
import { TabKey } from 'uf/explore/details';
import { LayerInfoWithExtrusion } from 'uf/explore/layerOrdering';
import {
  getFilterListVisible,
  getShowPaintedOnly,
} from 'uf/explore/selectors/explore';
import { makeGetLayerFiltersGetter } from 'uf/explore/selectors/filters';
import {
  getActiveLayerId,
  getActiveScenarioDefaultLayerIds,
  getAllLayersState,
  getOrderedLayerInfosWithExtrusion,
  getVisibleLayerIdsForScenario,
} from 'uf/explore/selectors/layers';
import { ColumnKey, LayerId } from 'uf/layers';
import { FilterSpec } from 'uf/layers/filters';
import { LayerRole } from 'uf/layers/state';
import { ProjectId } from 'uf/projects';
import { makeGetProjectState } from 'uf/projects/selectors';
import { makeGetLayerRoleForProjectCallback } from 'uf/projects/selectors/layerRole';
import {
  makeGetProjectAllWorkingLayerIds,
  makeGetProjectBaseCanvasLayerId,
} from 'uf/projects/selectors/layers';
import { LegacyVirtualLayerId } from 'uf/projects/virtualLayers';
import { ExtrusionBreaksMethod, NoExtrusionStyleOption } from 'uf/symbology';
import { setMapExtrusion as setMapExtrusionAction } from 'uf/symbology/actions/override';
import LayerList from 'uf/ui/layers/LayerList/LayerList';
import LayerListMessageBox from 'uf/ui/layers/LayerListMessageBox/LayerListMessageBox';
import { ViewId } from 'uf/views';
import * as viewsLayersActionCreators from 'uf/views/actions/layers';

interface OwnProps {
  projectId: ProjectId;
  viewId: ViewId;
}

interface StateProps {
  layerInfos: LayerInfoWithExtrusion[];
  layersState: DataState<null>;
  projectState: DataState<ProjectMetadata>;
  workingLayerIds: LayerId[];
  defaultLayerIds: LayerId[];

  baseCanvasId: LayerId;
  layerFiltersGetter: (
    layerId: LayerId,
    parentLayerId: LayerId,
  ) => Partial<FilterSpec>;
  visibleLayerIds: LayerId[];
  activeLayerId: LayerId;
  filterListVisible: boolean;
  showPaintedOnly: Record<string, boolean>;
  getLayerRole: (layerId: LayerId) => LayerRole;
}

interface DispatchProps {
  setMapExtrusion: typeof setMapExtrusionAction;
  appActions: ThunkActionMapDispatch<typeof appActionCreators>;
  exploreActions: ThunkActionMapDispatch<typeof exploreActionCreators>;
  setLayerOrder: (
    projectId: ProjectId,
    viewId: ViewId,
    layerIds: LegacyVirtualLayerId[],
  ) => void;
  setLayerVisibility: (
    projectId: ProjectId,
    virtualLayerId: LegacyVirtualLayerId,
    viewId: ViewId,
    visible: boolean,
  ) => void;
  layerActions: ThunkActionMapDispatch<typeof exploreLayerActionCreators>;
  filterActions: ThunkActionMapDispatch<typeof exploreFilterActionCreators>;
}

type Props = OwnProps & StateProps & DispatchProps;

export const ExploreLayerList: FunctionComponent<Props> = props => {
  const {
    projectId,
    viewId,
    projectState,
    layerInfos,
    getLayerRole,
    layersState,
    workingLayerIds,
    defaultLayerIds,
    baseCanvasId,
    layerFiltersGetter,
    visibleLayerIds,
    activeLayerId,
    filterListVisible,
    showPaintedOnly,
    setMapExtrusion,
    layerActions,
    filterActions,
    appActions,
    exploreActions,
    setLayerOrder,
    setLayerVisibility,
  } = props;

  const scrollableParent = useRef<HTMLElement>(null);

  const layerListRef = useCallback(node => {
    // eslint-disable-next-line react/no-find-dom-node
    scrollableParent.current = findDOMNode(node) as HTMLElement;
  }, []);

  const onOpenFilterList = useCallback(
    layerId => {
      exploreActions.setDetailsPaneTabKey(projectId, layerId, TabKey.FILTER);
    },
    [exploreActions, projectId],
  );

  const onDeleteLayer = useCallback(
    layerId => {
      appActions.removeWorkingLayerFromActiveProject(layerId);
    },
    [appActions],
  );

  const onTogglePaintedOnly = useCallback(
    (layerId: LayerId, value: boolean) => {
      exploreActions.setShowPaintedOnly(layerId, value);
    },
    [exploreActions],
  );

  const onOrderLayer = useCallback(
    (newOrder: string[]) => {
      setLayerOrder(projectId, viewId, newOrder);
    },
    [projectId, setLayerOrder, viewId],
  );

  const onToggleExtrusion = useCallback(
    (layerId: LayerId, columnKey: ColumnKey) => {
      const options: NoExtrusionStyleOption = {
        breaksMethod: ExtrusionBreaksMethod.NoExtrusion,
      };

      setMapExtrusion(layerId, columnKey, options);
    },
    [setMapExtrusion],
  );

  const { setActiveLayer } = layerActions;

  let layers = layerInfos;
  if (!projectId || !isLoaded(projectState)) {
    layers = EMPTY_ARRAY;
  }

  return (
    <ScrollView ref={layerListRef} flex={1} className={'LayerList-layers'}>
      <LayerList
        droppableId="explore-layer-list"
        baseCanvasId={baseCanvasId}
        layers={layers}
        projectId={projectId}
        viewId={viewId}
        getLayerRole={getLayerRole}
        deletableLayerIds={workingLayerIds}
        layerFiltersGetter={layerFiltersGetter}
        visibleLayerIds={visibleLayerIds}
        activeLayerId={activeLayerId}
        onActivateLayer={setActiveLayer}
        onOpenFilterList={onOpenFilterList}
        flyoutPanelVisible={filterListVisible}
        showPaintedOnly={showPaintedOnly}
        onChangeLayerVisibility={setLayerVisibility}
        onOrderLayer={onOrderLayer}
        onDeleteLayer={onDeleteLayer}
        onEnableFilter={filterActions.enableFilters}
        onShowPaintedOnly={onTogglePaintedOnly}
        onToggleExtrusion={onToggleExtrusion}
      />
      {!isLoading(layersState) && (
        <LayerListMessageBox
          projectId={projectId}
          layers={layers}
          defaultLayerIds={defaultLayerIds}
        />
      )}
    </ScrollView>
  );
};

function makeMapStateToProps() {
  const getProjectAllWorkingLayerIds = makeGetProjectAllWorkingLayerIds();
  const makeGetLayerRole = makeGetLayerRoleForProjectCallback();
  const getProjectState = makeGetProjectState();
  const getLayerFiltersGetter = makeGetLayerFiltersGetter();
  const getScenarioId = makeGetActiveScenarioIdForProject();
  const getProjectBaseCanvasLayerId = makeGetProjectBaseCanvasLayerId();
  return (state, props: OwnProps): StateProps => {
    const { projectId, viewId } = props;
    const scenarioId = getScenarioId(state, { projectId });
    return {
      layerInfos: getOrderedLayerInfosWithExtrusion(state, {
        projectId,
        viewId,
        scenarioId,
      }),
      getLayerRole: makeGetLayerRole(state, { projectId, scenarioId }),
      layersState: getAllLayersState(state),
      projectState: getProjectState(state, { projectId }),
      workingLayerIds: getProjectAllWorkingLayerIds(state, { projectId }),
      defaultLayerIds: getActiveScenarioDefaultLayerIds(state),
      baseCanvasId: getProjectBaseCanvasLayerId(state, { projectId }),
      layerFiltersGetter: getLayerFiltersGetter(state, {
        projectId,
      }),
      visibleLayerIds: getVisibleLayerIdsForScenario(state, {
        viewId,
        projectId,
        scenarioId,
      }),
      activeLayerId: getActiveLayerId(state, { projectId, scenarioId, viewId }),
      filterListVisible: getFilterListVisible(state),
      showPaintedOnly: getShowPaintedOnly(state),
    };
  };
}

export default connect<StateProps, DispatchProps, OwnProps>(
  makeMapStateToProps(),
  (dispatch: Dispatch) => ({
    setMapExtrusion: bindActionCreators(setMapExtrusionAction, dispatch),
    appActions: bindActionCreators(appActionCreators, dispatch),
    exploreActions: bindActionCreators(exploreActionCreators, dispatch),
    setLayerOrder: bindActionCreators(
      viewsLayersActionCreators.setLayerOrder,
      dispatch,
    ),
    setLayerVisibility: bindActionCreators(
      viewsLayersActionCreators.setLayerVisibility,
      dispatch,
    ),
    layerActions: bindActionCreators(exploreLayerActionCreators, dispatch),
    filterActions: bindActionCreators(exploreFilterActionCreators, dispatch),
  }),
)(ExploreLayerList);
