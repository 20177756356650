import { parseServerJSON } from 'uf/base/json';

import { ErrorAny } from './types';

// Turn all the various swagger errors into a consistent format.
//
// Usage:
//
//   swagger.apis.module.some_api_call()
//          .then(....)
//          .catch(normalizeError);
export function normalizeError(error: ErrorAny) {
  // This is a pretty strange case, hopefully never happens!
  if (!error) {
    return Promise.reject(error);
  }

  // This seems to be the new swagger-client 3.x
  if (error.response?.data) {
    // If the server gives us a non-200 level error with an HTTP Problem JSON response.
    // Note that the parseServerJSON accounts for a non-200 level error with an html
    // response like a 502 gateway error from a proxy that we can't control.
    return Promise.reject(parseServerJSON(error.response.data));
  }

  // Support the old format just in case
  // TODO: Remove this.
  if (error.data) {
    return Promise.reject(parseServerJSON(error.data));
  }

  // HTTP-level error, like a network timeout / refused connection, etc.
  if (error.errObj) {
    return Promise.reject(error.errObj);
  }

  // Who knows! Might as well just pass it through
  return Promise.reject(error);
}
