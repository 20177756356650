import colors from 'material-colors';

import {
  CuratedColumnSymbology,
  CUSTOM_THEME,
  DEFAULT_OPACITY,
  InterpolationTypes,
  PaintColorTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/public/dataset/morpc_base_zoning_districts': {
    general_zo: [
      {
        type: SymbologyTypes.FILL,
        paint: {
          'fill-color': {
            property: 'general_zo',
            type: PaintColorTypes.CATEGORICAL,
            stops: [
              { value: 'Commercial', label: 'Commercial', color: '#AF3323' },
              {
                value: 'Downtown District',
                label: 'Downtown District',
                color: '#4C4731',
              },
              {
                value: 'East Franklinton District',
                label: 'East Franklington District',
                color: colors.orange[300],
              },
              {
                value: 'Excavation/Quarrying',
                label: 'Excavation/Quarrying',
                color: '#B7B7B7',
              },
              {
                value: 'Institutional',
                label: 'Institutional',
                color: '#466F7A',
              },
              {
                value: 'Manufactured Home',
                label: 'Manufactured Home',
                color: '#8B8299',
              },
              {
                value: 'Manufacturing',
                label: 'Manufacturing',
                color: '#7E6B99',
              },
              {
                value: 'Multi-family',
                label: 'Multi-family',
                color: '#DDBB75',
              },
              {
                value: 'Neighborhood Center',
                label: 'Neighborhood Center',
                color: '#7C7140',
              },
              {
                value: 'Neighborhood Edge',
                label: 'Neighborhood Edge',
                color: '#8E803E',
              },
              {
                value: 'Neighborhood General',
                label: 'Neighborhood General',
                color: '#A8974A',
              },
              { value: 'Parking', label: 'Parking', color: '#212226' },
              {
                value: 'Research Park',
                label: 'Research Park',
                color: colors.indigo[400],
              },
              { value: 'Residential', label: 'Residential', color: '#D6C26B' },
              { value: 'Town Center', label: 'Town Center', color: '#6D6646' },
            ],
          },
          'fill-opacity': DEFAULT_OPACITY,
        },
        display_hints: {
          scale: SymbologyScales.CATEGORICAL,
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          theme: CUSTOM_THEME,
        },
      },
    ],
  },
};

export default symbologies;
