import React, { FunctionComponent } from 'react';
import { hot } from 'react-hot-loader/root';
import { useSelector } from 'react-redux';
import { getActiveProjectId } from 'uf/app/selectors';
import { DefaultProjectTypes } from 'uf/projects';
import { makeGetProjectById } from 'uf/projects/selectors';
import { UFState } from 'uf/state';

import Header from 'uf/ui/main/Header/Header';
import { allHeaderMenus, HeaderMenuKeys } from 'uf/ui/main/Header/HeaderMenus';
import ScenarioBar from 'uf/ui/scenarios/ScenarioBar/ScenarioBar';

const exploreHeaders: Record<HeaderMenuKeys, boolean> = {
  ...allHeaderMenus,
};

export const ExploreHeader: FunctionComponent = props => {
  const getProjectById = makeGetProjectById();
  const projectId = useSelector(state => getActiveProjectId(state));
  const project = useSelector((state: UFState) =>
    getProjectById(state, { projectId }),
  );
  return (
    <Header menus={exploreHeaders}>
      {project?.project_type === DefaultProjectTypes.LAND_USE && (
        <ScenarioBar />
      )}
    </Header>
  );
};

export default hot(ExploreHeader);
