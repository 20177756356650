import _ from 'lodash';
import uniqid from 'uniqid';

export const BACKSPACE = 8;
export const TAB = 9;
export const ENTER = 13;
export const SHIFT = 16;
export const ESCAPE = 27;
export const SPACE = 32;
export const ARROW_LEFT = 37;
export const ARROW_UP = 38;
export const ARROW_RIGHT = 39;
export const ARROW_DOWN = 40;
export const DELETE = 46;

export const MAX_KEY_LENGTH = 55;

export function postfixKeywithUniqId(key: string) {
  if (!key || key.length === 0) {
    return uniqid();
  }

  const postfix = `_${uniqid()}`;
  const clippedKey = key.slice(0, MAX_KEY_LENGTH - postfix.length);
  return `${clippedKey}${postfix}`;
}

export const NULL_KEY = '__uf_null_key__';
export const UNDEFINED_KEY = '__uf_undefined_key__';

/**
 * parses a string to be used as a key in an object and returns a string value if the key is null or
 * undefined.
 * @param key string, null, or undefined value
 */
export function getSafeKey<K extends string>(key: K | boolean) {
  if (_.isNull(key)) {
    return NULL_KEY;
  }
  if (_.isUndefined(key)) {
    return UNDEFINED_KEY;
  }
  if (_.isBoolean(key)) {
    return key.toString() as K;
  }
  return key;
}
