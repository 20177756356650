import { LOADED, LOADING } from 'uf/base';
import {
  CLEAR_MAP_INSPECTION,
  FIT_MAP_TO_BOUNDS,
  MAP_LOADED,
  MAP_LOADING,
  MAP_RESET,
  SET_BASEMAP_FEATURE_VISIBILITY,
  SET_EXPLORE_MAP_VIEWPORT,
  SET_SELECTION_MODES_DISABLED,
  snapshotActionTypes,
  UPDATE_MAP_BEARING,
  UPDATE_MAP_BOUNDS,
  UPDATE_MAP_CENTER,
  UPDATE_MAP_INSPECTION,
  UPDATE_MAP_MAX_BOUNDS,
  UPDATE_MAP_MODE,
  UPDATE_MAP_PITCH,
  UPDATE_MAP_SCALE,
  UPDATE_MAP_STYLE_URL,
  UPDATE_MAP_VIEWPORT,
  UPDATE_MAP_ZOOM,
  UPDATE_STYLE_LAYERS,
  UPDATE_TILE_SOURCES,
} from 'uf/explore/ActionTypes';
import { initialMapState, MapState } from 'uf/explore/state';
import { BaseMapsById, findBaseMapByStyleUrl } from 'uf/map/baseMaps';

const LEBANON_LNG = -98.58;
const LEBANON_LAT = 39.83;
// https://en.wikipedia.org/wiki/Geographic_center_of_the_contiguous_United_States
export const LEBANON_KS: [number, number] = [LEBANON_LNG, LEBANON_LAT];

export default function map(state: MapState = initialMapState, action) {
  switch (action.type) {
    case MAP_RESET:
      return initialMapState;

    case MAP_LOADING:
      return {
        ...state,
        mapStatus: LOADING,
        mapbox: action.mapbox,
      };

    case MAP_LOADED:
      return {
        ...state,
        mapStatus: LOADED,
        mapbox: action.mapbox,
      };

    case UPDATE_MAP_STYLE_URL:
      return {
        ...state,
        mapStyle: {
          ...state.mapStyle,
          styleUrl: action.styleUrl,
        },
      };

    case SET_BASEMAP_FEATURE_VISIBILITY: {
      const currentBaseMap = findBaseMapByStyleUrl(state.mapStyle.styleUrl);
      const { url: styleUrl } = BaseMapsById[currentBaseMap.id];
      return {
        ...state,
        mapStyle: {
          ...state.mapStyle,
          styleUrl,
          [action.featureType]: action.featureVisible,
        },
      };
    }

    case UPDATE_MAP_VIEWPORT: {
      if (action.save) {
        return {
          ...state,
          ...action.viewport,
        };
      }
      return state;
    }

    case SET_EXPLORE_MAP_VIEWPORT:
      return {
        ...state,
        ...action.viewport,
      };

    case UPDATE_MAP_CENTER:
      return {
        ...state,
        center: action.center,
      };

    case UPDATE_MAP_PITCH:
      return {
        ...state,
        pitch: action.pitch,
      };

    case UPDATE_MAP_BEARING:
      return {
        ...state,
        bearing: action.bearing,
      };

    case UPDATE_MAP_BOUNDS:
      return {
        ...state,
        mapBounds: action.mapBounds,
      };

    case UPDATE_MAP_SCALE:
      return {
        ...state,
        scale: action.scale,
      };

    case FIT_MAP_TO_BOUNDS:
      return {
        ...state,
        requestedMapBounds: action.requestedMapBounds,
      };

    case UPDATE_MAP_MAX_BOUNDS:
      return {
        ...state,
        mapMaxBounds: action.mapMaxBounds,
      };

    case UPDATE_MAP_ZOOM:
      return {
        ...state,
        zoom: action.zoom,
      };

    case UPDATE_MAP_MODE:
      // TODO: switch to makeSetValueReducer
      if (state.mode !== action.mode) {
        return {
          ...state,
          mode: action.mode,
        };
      }
      return state;

    case SET_SELECTION_MODES_DISABLED:
      return {
        ...state,
        selectionModesDisabled: action.value,
      };

    case UPDATE_MAP_INSPECTION:
      return {
        ...state,
        inspection: {
          ...state.inspection,
          [action.layerId]: action.inspection,
        },
      };

    case CLEAR_MAP_INSPECTION: {
      if (!action.layerId) {
        return state;
      }
      return {
        ...state,
        inspection: {
          ...state.inspection,
          [action.layerId]: {},
        },
      };
    }

    case UPDATE_TILE_SOURCES: {
      const tileSources = {};
      action.sources.forEach(({ id, source }) => {
        tileSources[id] = source;
      });
      return {
        ...state,
        debugTileSources: tileSources,
      };
    }

    // TODO: Keep track of layer ordering
    case UPDATE_STYLE_LAYERS: {
      const styleLayers = {};
      action.layers.forEach(l => {
        styleLayers[l.id] = l;
      });
      return {
        ...state,
        debugStyleLayers: styleLayers,
      };
    }

    case snapshotActionTypes.LOAD: {
      return {
        ...state,
        takeSnapshot: true,
      };
    }

    case snapshotActionTypes.SUCCESS: {
      return {
        ...state,
        takeSnapshot: false,
      };
    }

    default:
      return state;
  }
}
