import { getSymbologyActionTypes } from 'uf-api/api/project.service';
import { makeAsyncActionTypes } from 'uf/data/ActionTypes';
import { ColumnKey, LayerId } from 'uf/layers';
import { ProjectId } from 'uf/projects';
import { LegacyVirtualLayerId } from 'uf/projects/virtualLayers';
import { ViewId } from 'uf/views';

import {
  ColumnSymbology,
  DistributionTypes,
  LayerColumnSymbology,
  LayerSymbologyEnvelope,
} from './';
import { DivideByColumnKey } from './divideByColumn';

export const getUserSymbologyActionTypes = getSymbologyActionTypes;

export const saveUserSymbologyActionTypes =
  makeAsyncActionTypes('uf/symbology/save');

/**
 * Every symbology action should include these properties to ensure
 * an epic or other consumers have enough information to act
 */
export interface BaseSymbologyAction {
  projectId: ProjectId;
  viewId: ViewId;
  layerId: LayerId;
  virtualLayerId: LegacyVirtualLayerId;
  columnKey: ColumnKey;
}

export interface SaveUserSymbologyLoadAction extends BaseSymbologyAction {
  type: typeof saveUserSymbologyActionTypes.LOAD;
  divideByColumn: DivideByColumnKey;
  data: LayerSymbologyEnvelope;
  requestId: string;
}

export interface SaveUserSymbologySuccessAction extends BaseSymbologyAction {
  type: typeof saveUserSymbologyActionTypes.SUCCESS;
  divideByColumn: DivideByColumnKey;
  data: LayerSymbologyEnvelope;
  requestId: string;
}

export const clearUserSymbologyActionTypes =
  makeAsyncActionTypes('uf/symbology/clear');
export interface ClearUserSymbologySuccessAction extends BaseSymbologyAction {
  type: typeof clearUserSymbologyActionTypes.SUCCESS;
}

export const SET_DEFAULT_SYMBOLOGY = 'uf/symbology/DEFAULT';

export interface SetDefaultSymbologyAction {
  type: typeof SET_DEFAULT_SYMBOLOGY;
  viewId: ViewId;
  layerId: LayerId;
  virtualLayerId: LegacyVirtualLayerId;
  columnKey: ColumnKey;
  symbology: ColumnSymbology[];
}
export const SET_OVERRIDE_SYMBOLOGY = 'uf/symbology/override/SET';
export interface SetOverrideSymbologyAction extends BaseSymbologyAction {
  type: typeof SET_OVERRIDE_SYMBOLOGY;
  symbologyKey: string;
  symbology: LayerColumnSymbology[];
  divideByColumn: DivideByColumnKey;
}
export const CLEAR_OVERRIDE_SYMBOLOGY = 'uf/symbology/override/CLEAR';

export interface ClearOverrideSymbologyAction extends BaseSymbologyAction {
  type: typeof CLEAR_OVERRIDE_SYMBOLOGY;
  symbologyKey: string;
  divideByColumn: DivideByColumnKey;
}

export const SET_DIVIDE_BY_COLUMN = 'uf/symbology/divideByColumn/SAVE';
export interface SetDivideByColumnAction extends BaseSymbologyAction {
  type: typeof SET_DIVIDE_BY_COLUMN;
  divideByColumn: DivideByColumnKey;
}

export const UPDATE_RAMP_THEME = 'uf/symbology/override/rampTheme/UPDATE';
export const UPDATE_THEME_REVERSED =
  'uf/symbology/override/themeReversed/UPDATE';
export const UPDATE_OPACITY = 'uf/symbology/override/opacity/UPDATE';
export const UPDATE_STROKE_WIDTH = 'uf/symbology/override/strokeWidth/UPDATE';
export const UPDATE_STROKE_COLOR = 'uf/symbology/override/strokeColor/UPDATE';
export const SET_SHOW_ZERO = 'uf/symbology/override/showZero/UPDATE';
export const SET_MAP_EXTRUSION = 'uf/symbology/override/extrusion/UPDATE';
export const UPDATE_STOPS = 'uf/symbology/override/stops/UPDATE';
export interface UpdateStopsAction {
  numStops: number;
  type: string;
  distributionType: DistributionTypes;
}

export const UPDATE_CUSTOM_STOPS = 'uf/symbology/override/customStops/UPDATE';
export const UPDATE_STOP_COLOR = 'uf/symbology/override/stopColor/UPDATE';

export const UPDATE_POINT_SIZE = 'uf/symbology/override/pointSize/UPDATE';
