/**
 * UrbanFootprint App API
 * API for serving UrbanFootprint Application
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { ProjectTypeMetadata } from './projectTypeMetadata';

/**
 * All metadata about a project LAS run.
 */
export interface ProjectLayerAnalysisRun<> {
  /**
   * The buffer radius value.
   */
  buffer?: number;
  /**
   * The buffer unit.
   */
  buffer_unit?: ProjectLayerAnalysisRun.BufferUnitEnum;
  /**
   * The census unit key.
   */
  census_unit_key?: number;
  /**
   * The date this model instance was created.
   */
  created?: Date;
  /**
   * The user who created this model instance.
   */
  creator?: string;
  /**
   * The insight key.
   */
  insight_key: number;
  /**
   * The key of the LAS run.
   */
  key: string;
  /**
   * The layer key.
   */
  layer_key: string;
  /**
   * The layer namespace.
   */
  layer_namespace: string;
  /**
   * The layer type.
   */
  layer_type: string;
  /**
   * The key of the project.
   */
  project_key: string;
  /**
   * The namespace of the project.
   */
  project_namespace: string;
  project_type?: ProjectTypeMetadata;
}
export namespace ProjectLayerAnalysisRun {
  export type BufferUnitEnum = 'miles' | 'meters' | 'kilometers' | 'feet';
  export const BufferUnitEnum = {
    Miles: 'miles',
    Meters: 'meters',
    Kilometers: 'kilometers',
    Feet: 'feet',
  } as const;
}
