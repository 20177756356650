import colors from 'material-colors';

import {
  CuratedColumnSymbology,
  CUSTOM_THEME,
  DEFAULT_OPACITY,
  InterpolationTypes,
  PaintColorTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const seaRiseLayerList = [
  'caladapt_coast_m1274023900',
  'caladapt_coast_m1274679265',
  'caladapt_coast_m1274220509',
  'caladapt_coast_m1540427794',
  'caladapt_delta_s3774549233',
  'caladapt_delta_s3775204598',
  'caladapt_delta_s3774745842',
  'caladapt_delta_s3991997735',
  'caladapt_san_fra2720076251',
  'caladapt_san_fra2720731616',
  'caladapt_san_fra2720272860',
  'caladapt_san_fra3019969041',
  'usgs_cosmos_channel_islands_0_25m',
  'usgs_cosmos_channel_islands_0_5m',
  'usgs_cosmos_channel_islands_0_75m',
  'usgs_cosmos_channel_islands_0m',
  'usgs_cosmos_channel_islands_1_25m',
  'usgs_cosmos_channel_islands_1_5m',
  'usgs_cosmos_channel_islands_1_75m',
  'usgs_cosmos_channel_islands_1m',
  'usgs_cosmos_channel_islands_2m',
  'usgs_cosmos_channel_islands_5m',
  'usgs_cosmos_los_angeles_0_25m',
  'usgs_cosmos_los_angeles_0_5m',
  'usgs_cosmos_los_angeles_0_75m',
  'usgs_cosmos_los_angeles_0m',
  'usgs_cosmos_los_angeles_1_25m',
  'usgs_cosmos_los_angeles_1_5m',
  'usgs_cosmos_los_angeles_1_75m',
  'usgs_cosmos_los_angeles_1m',
  'usgs_cosmos_los_angeles_2m',
  'usgs_cosmos_los_angeles_5m',
  'usgs_cosmos_orange_0_25m',
  'usgs_cosmos_orange_0_5m',
  'usgs_cosmos_orange_0_75m',
  'usgs_cosmos_orange_0m',
  'usgs_cosmos_orange_1_25m',
  'usgs_cosmos_orange_1_5m',
  'usgs_cosmos_orange_1_75m',
  'usgs_cosmos_orange_1m',
  'usgs_cosmos_orange_2m',
  'usgs_cosmos_orange_5m',
  'usgs_cosmos_san_diego_0_25m',
  'usgs_cosmos_san_diego_0_5m',
  'usgs_cosmos_san_diego_0_75m',
  'usgs_cosmos_san_diego_0m',
  'usgs_cosmos_san_diego_1_25m',
  'usgs_cosmos_san_diego_1_5m',
  'usgs_cosmos_san_diego_1_75m',
  'usgs_cosmos_san_diego_1m',
  'usgs_cosmos_san_diego_2m',
  'usgs_cosmos_san_diego_5m',
  'usgs_cosmos_santa_barbara_0_25m',
  'usgs_cosmos_santa_barbara_0_5m',
  'usgs_cosmos_santa_barbara_0_75m',
  'usgs_cosmos_santa_barbara_0m',
  'usgs_cosmos_santa_barbara_1_25m',
  'usgs_cosmos_santa_barbara_1_5m',
  'usgs_cosmos_santa_barbara_1_75m',
  'usgs_cosmos_santa_barbara_1m',
  'usgs_cosmos_santa_barbara_2m',
  'usgs_cosmos_santa_barbara_5m',
  'usgs_cosmos_ventura_0_25m',
  'usgs_cosmos_ventura_0_5m',
  'usgs_cosmos_ventura_0_75m',
  'usgs_cosmos_ventura_0m',
  'usgs_cosmos_ventura_1_25m',
  'usgs_cosmos_ventura_1_5m',
  'usgs_cosmos_ventura_1_75m',
  'usgs_cosmos_ventura_1m',
  'usgs_cosmos_ventura_2m',
  'usgs_cosmos_ventura_5m',
  'usgs_cosmos_0_25m',
  'usgs_cosmos_0_5m',
  'usgs_cosmos_0_75m',
  'usgs_cosmos_0m',
  'usgs_cosmos_1_25m',
  'usgs_cosmos_1_5m',
  'usgs_cosmos_1_75m',
  'usgs_cosmos_1m',
  'usgs_cosmos_2m',
  'usgs_cosmos_5m',
  'sea_level_rise_0ft_v1_proc',
  'sea_level_rise_1ft_v1_proc',
  'sea_level_rise_2ft_v1_proc',
  'sea_level_rise_3ft_v1_proc',
  'sea_level_rise_4ft_v1_proc',
  'sea_level_rise_5ft_v1_proc',
  'sea_level_rise_6ft_v1_proc',
  'sea_level_rise_7ft_v1_proc',
  'sea_level_rise_8ft_v1_proc',
  'sea_level_rise_9ft_v1_proc',
  'sea_level_rise_10ft_v1_proc',
];

const symbologies: Record<
  string,
  Record<string, CuratedColumnSymbology[]>
> = {};
seaRiseLayerList.forEach(layerName => {
  symbologies[`/public/dataset/${layerName}`] = {
    depth: [
      {
        type: SymbologyTypes.FILL,
        paint: {
          'fill-color': {
            property: 'depth',
            type: PaintColorTypes.CATEGORICAL,
            stops: [
              { value: '0 - 0.5m', color: colors.blue[100] },
              { value: '0.5 - 1.0m', color: colors.blue[200] },
              { value: '1.0 - 1.5m', color: colors.blue[300] },
              { value: '1.5 - 2.0m', color: colors.blue[400] },
              { value: '2.0 - 2.5m', color: colors.blue[500] },
              { value: '2.5 - 3.0m', color: colors.blue[600] },
              { value: '3.0 - 3.5m', color: colors.blue[700] },
              { value: '3.5 - 4.0m', color: colors.blue[800] },
              { value: '4.0m +', color: colors.blue[900] },
            ],
          },
          'fill-opacity': DEFAULT_OPACITY,
        },
        display_hints: {
          scale: SymbologyScales.CATEGORICAL,
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          theme: CUSTOM_THEME,
        },
      },
      {
        type: SymbologyTypes.LINE,
        paint: {
          'line-color': {
            property: 'depth',
            type: PaintColorTypes.CATEGORICAL,
            stops: [
              { value: '0 - 0.5m', color: colors.blue[100] },
              { value: '0.5 - 1.0m', color: colors.blue[200] },
              { value: '1.0 - 1.5m', color: colors.blue[300] },
              { value: '1.5 - 2.0m', color: colors.blue[400] },
              { value: '2.0 - 2.5m', color: colors.blue[500] },
              { value: '2.5 - 3.0m', color: colors.blue[600] },
              { value: '3.0 - 3.5m', color: colors.blue[700] },
              { value: '3.5 - 4.0m', color: colors.blue[800] },
              { value: '4.0m +', color: colors.blue[900] },
            ],
          },
          'line-opacity': DEFAULT_OPACITY,
        },
        display_hints: {
          scale: SymbologyScales.CATEGORICAL,
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          theme: CUSTOM_THEME,
        },
      },
    ],
  };
});

export default symbologies;
