import _ from 'lodash';
import colors from 'material-colors';
import { LayerColumnStats } from 'uf-api';
import { cacheableConcat } from 'uf/base/array';
import { BuiltFormTypes } from 'uf/base/builtForms';
import {
  BuiltFormInfo,
  BUILT_FORM_TYPES,
  prefixBuiltFormTypesKeys,
} from 'uf/builtforms';
import { getFillColor, StyledBuiltFormLike } from 'uf/builtforms/color';

interface BuiltFormLike extends StyledBuiltFormLike {
  key?: string;
  name?: string;
  used?: boolean;
  /**
   * If the built form comes from the search apis, the data rows will have this
   * column
   */
  built_form_type?: BuiltFormTypes;
}

/**
 * Turn either a BuiltForm from a library, or a row from the flat builtforms
 * layer, into a stop suitable for rendering in a legend or on the map.
 */
export function makeBuiltFormStops<T extends BuiltFormLike>(
  builtFormItems: T[],
  usedBuiltForms: Record<string, boolean>,
): BuiltFormStop[] {
  return cacheableConcat(
    builtFormItems.map(builtForm => ({
      color: getFillColor(builtForm, colors.grey[400]),
      value: builtForm.key,
      label: builtForm.name,
      used: !!usedBuiltForms[builtForm.key],
    })),
  );
}

// this interface is used by categorical layers for styling
export interface BuiltFormStop {
  color: string;
  value: string;
  label: string;
  used: boolean;
}

/**
 * Convert library-base builtforms into symbology stops
 */
export function makeStopsFromBuiltForms(
  // either of these can be empty while builtforms libraries are loading
  buildingTypes: BuiltFormInfo[] = [],
  placeTypes: BuiltFormInfo[] = [],
  builtFormKeyColumnStats: LayerColumnStats,
): BuiltFormStop[] {
  const buildingTypeItems = prefixBuiltFormTypesKeys(
    _.sortBy(buildingTypes, buildingType => buildingType.name.toLowerCase()),
    BUILT_FORM_TYPES.BUILDING_TYPE,
  );

  const placeTypeItems = prefixBuiltFormTypesKeys(
    _.sortBy(placeTypes, placeType => placeType.name.toLowerCase()),
    BUILT_FORM_TYPES.PLACE_TYPE,
  );

  const usedBuiltForms = _(builtFormKeyColumnStats?.categorical?.values)
    .keyBy('value')
    .mapValues(() => true)
    .value();

  const builtFormItems = [...buildingTypeItems, ...placeTypeItems];
  const builtFormStops = makeBuiltFormStops(builtFormItems, usedBuiltForms);
  return builtFormStops;
}

// The search query for the Built Forms table is a request for all rows. Since the Built Forms
// table is relatively small, we request the entire table to save on network requests. Any component
// that requires the Built Forms table should filter the results to only the data it needs.
// Eg:
// - Symbology filters for all BuildingType and UrbanPlacetype rows.
// - BUILD mode dropdowns filter for BuildingType or UrbanPlacetype rows respectively.
export const BUILT_FORM_QUERY = { limit: 1000 };
