import _ from 'lodash';

import {
  getUserDataActionTypes,
  getUserDataClearAction,
  getUserDataFailureAction,
  getUserDataLoadAction,
  getUserDataSuccessAction,
  listUserDataItemsActionTypes,
  listUserDataItemsClearAction,
  listUserDataItemsFailureAction,
  listUserDataItemsLoadAction,
  listUserDataItemsSuccessAction,
} from 'uf-api/api/userdata.service';
import { UserData } from 'uf-api/model/userData';
import { EMPTY_OBJECT } from 'uf/base';
import {
  BaseAction,
  FailureAction,
  LoadAction,
  LoadStateAction,
  SuccessAction,
} from 'uf/data/ActionTypes';
import {
  DataState,
  getData,
  isLoaded,
  makeClearedState,
  makeErrorState,
  makeLoadedState,
  makeLoadingState,
} from 'uf/data/dataState';
import { makeUserDataKey } from 'uf/user';

type UserDataTruthAction =
  | getUserDataLoadAction
  | getUserDataFailureAction
  | getUserDataSuccessAction
  | getUserDataClearAction
  | listUserDataItemsLoadAction
  | listUserDataItemsSuccessAction
  | listUserDataItemsFailureAction
  | listUserDataItemsClearAction;

export function userData(
  state: Record<string, DataState<UserData>> = EMPTY_OBJECT,
  action: UserDataTruthAction = EMPTY_OBJECT as UserDataTruthAction,
) {
  switch (action.type) {
    case getUserDataActionTypes.LOAD:
      return updateSingleState(
        state,
        action,
        makeLoadDataState(state[action.key], action),
      );
    case getUserDataActionTypes.SUCCESS:
      return updateSingleState(
        state,
        action,
        makeSuccessDataState(state[action.key], action),
      );
    case getUserDataActionTypes.FAILURE:
      return updateSingleState(
        state,
        action,
        makeFailureDataState(state[action.key], action),
      );
    case getUserDataActionTypes.CLEAR:
      return updateSingleState(
        state,
        action,
        makeClearDataState(state[action.key]),
      );
    case listUserDataItemsActionTypes.LOAD:
      return updateBatchState(state, action, makeLoadDataState);
    case listUserDataItemsActionTypes.SUCCESS:
      return makeSuccessBatchState(state, action);
    case listUserDataItemsActionTypes.FAILURE:
      return updateBatchState(state, action, makeFailureDataState);
    case listUserDataItemsActionTypes.CLEAR:
      return updateBatchState(state, action, makeClearDataState);
    default:
      return state;
  }
}

/**
 * Updates the Redux state in response to single user data actions
 * if and only if the state is actually different
 */
function updateSingleState(
  state: Record<string, DataState<UserData>>,
  action: UserDataTruthAction,
  newDataState: DataState<UserData>,
): Record<string, DataState<UserData>> {
  const stateKey = action.key;
  const oldDataState = state[stateKey];
  if (newDataState === oldDataState) {
    return state;
  }
  return {
    ...state,
    [stateKey]: newDataState,
  };
}

function makeLoadDataState(
  oldDataState: DataState<UserData>,
  action: LoadAction,
): DataState<UserData> {
  // We should always replace data states that don't exist
  if (!oldDataState || isNewerRequest(oldDataState, action)) {
    return makeLoadingState(oldDataState, action);
  }

  return oldDataState;
}

function makeSuccessDataState(
  oldDataState: DataState<UserData>,
  action: SuccessAction<UserData>,
): DataState<UserData> {
  if (
    !oldDataState ||
    // If the old state is loaded, we should compare the updated fields in the data, as it is more
    // reliable than request time
    isNewerUserData(oldDataState, action) ||
    isNewerRequest(oldDataState, action)
  ) {
    return makeLoadedState(oldDataState, action);
  }

  return oldDataState;
}

function makeFailureDataState(
  oldDataState: DataState<UserData>,
  action: FailureAction,
): DataState<UserData> {
  if (!oldDataState || isNewerRequest(oldDataState, action)) {
    return makeErrorState(oldDataState, action);
  }

  return oldDataState;
}

function makeClearDataState(
  oldDataState: DataState<UserData>,
): DataState<UserData> {
  const newDataState = makeClearedState(oldDataState);

  if (oldDataState === newDataState) {
    return oldDataState;
  }

  return newDataState;
}

/**
 * Updates the Redux state in response to batched user data actions
 * if and only if the state is actually different
 */
function updateBatchState(
  state: Record<string, DataState<UserData>>,
  action: BaseAction,
  dataStateCreator: (
    oldDataState: DataState<UserData>,
    action: BaseAction,
  ) => DataState<UserData>,
) {
  let shouldChange = false;
  const newEntries = Object.entries(state)
    .filter(([, oldDataState]) => {
      return (
        oldDataState.extra.userKey === action.extra.userKey &&
        oldDataState.extra.dataKey.startsWith(action.extra.prefix)
      );
    })
    .map(([stateKey, oldDataState]) => {
      // We need to preserve the user key and data key of the data state so that
      // we can compare them against future action
      const mockSingleAction: BaseAction = {
        ...action,
        extra: oldDataState.extra,
      };

      const newState = dataStateCreator(oldDataState, mockSingleAction);
      if (newState !== oldDataState) {
        shouldChange = true;
      }
      return [stateKey, newState];
    });
  if (shouldChange) {
    return {
      ...state,
      // TODO: Once we upgrade to ES2019, we should use Object.fromEntries()
      ..._.fromPairs(newEntries),
    };
  }

  return state;
}

function makeSuccessBatchState(
  state: Record<string, DataState<UserData>>,
  action: SuccessAction<UserData[]>,
): Record<string, DataState<UserData>> {
  let shouldUpdate = false;
  const userDataItems = action.result;

  const actionKeys = new Set(
    userDataItems.map(userDataItem =>
      makeUserDataKey(userDataItem.user_key, userDataItem.key),
    ),
  );

  // If a datastate is prefixed by the action, but does not exist in the data,
  // it must have been deleted
  const remainingState = _.pickBy(state, (dataState, stateKey) => {
    // Check for the same user
    if (dataState.extra.userKey !== action.extra.userKey) {
      return true;
    }

    // Keep entries that are not prefixed (and thus not affected) by the change
    if (!dataState.extra.dataKey.startsWith(action.extra.dataKey)) {
      return true;
    }

    if (actionKeys.has(stateKey)) {
      return true;
    }

    const isNewer = isNewerRequest(dataState, action);
    if (isNewer) {
      shouldUpdate = true;
    }
    return !isNewer;
  });

  userDataItems.forEach(userDataItem => {
    const userKey = userDataItem.user_key;
    const dataKey = userDataItem.key;
    const stateKey = makeUserDataKey(userKey, dataKey);
    const mockSingleAction: SuccessAction<UserData> = {
      ...action,
      key: stateKey,
      result: userDataItem,
      extra: { userKey, dataKey },
    };
    const oldDataState = remainingState[stateKey];
    const newDatastate = makeSuccessDataState(oldDataState, mockSingleAction);
    if (oldDataState !== newDatastate) {
      shouldUpdate = true;
      remainingState[stateKey] = newDatastate;
    }
  });

  if (shouldUpdate) {
    return remainingState;
  }
  return state;
}

/**
 * Compares an old data state with an action and determines if we should replace the old data state
 */
function isNewerRequest(
  oldDataState: DataState<UserData>,
  action: LoadStateAction,
): boolean {
  // We should always replace data states that don't have request time
  if (!oldDataState.requestTime && action.requestTime) {
    return true;
  }

  return (
    oldDataState.requestTime &&
    action.requestTime &&
    action.requestTime >= oldDataState.requestTime
  );
}

/**
 * Compares the updated fields of two success actions to determine if we need to replace
 */
function isNewerUserData(
  oldDataState: DataState<UserData>,
  action: SuccessAction<UserData>,
): boolean {
  if (isLoaded(oldDataState)) {
    const oldData = getData(oldDataState);
    const oldUpdated = oldData?.updated;
    const newUpdated = action?.result?.updated;

    // We should prioritize data that has information on updates
    if (!oldUpdated && newUpdated) {
      return true;
    }

    // The internal updated timestamp is the best indicator for how "fresh" data is
    return oldUpdated && newUpdated && oldUpdated < newUpdated;
  }

  return false;
}
