import { AnyAction } from 'redux';
import { Epic, ofType } from 'redux-observable';
import { BehaviorSubject } from 'rxjs';
import { mergeMap, takeUntil } from 'rxjs/operators';

import amplitudeEpics from 'uf/amplitude/epics';
import analysisEpics from 'uf/analysis/epics';
import analysisEditorEpics from 'uf/analysiseditor/epics';
import appEpics from 'uf/app/epics';
import { combineEpics, END_OF_ACTIONS } from 'uf/base/epics';
import builtformSymbologyEpics from 'uf/builtforms-symbology/epics';
import builtFormEpics from 'uf/builtforms/epics';
import collaborationEpics from 'uf/collaboration/epics';
import exploreEpics from 'uf/explore/epics';
import importEpics from 'uf/import/epics';
import intercomEpics from 'uf/intercom/epics';
import layerEpics from 'uf/layers/epics';
import loggingEpics from 'uf/logging/epics';
import mapeditEpics from 'uf/mapedit/epics';
import mapexportEpics from 'uf/mapexport/epics';
import organizationEpics from 'uf/organizations/epics';
import persistenceEpics from 'uf/persistence/epics';
import projectEpics from 'uf/projects/epics';
import reportingEpics from 'uf/reporting/epics';
import scenarioEpics from 'uf/scenarios/epics';
import symbologyEpics from 'uf/symbology/epics';
import taskEpics from 'uf/tasks/epics';
import toastEpics from 'uf/ui/base/Toast/epics';
import userEpics from 'uf/user/epics';
import viewEpics from 'uf/views/epics';
import websocketEpics from 'uf/websockets/epics';

// Default epics. These we can set up when we
// initially create the store with epic middleware.
const allEpics: Record<string, Epic<AnyAction, any>> = {
  analysisEpics,
  analysisEditorEpics,
  appEpics,
  builtFormEpics,
  builtformSymbologyEpics,
  collaborationEpics,
  exploreEpics,
  importEpics,
  layerEpics,
  mapeditEpics,
  mapexportEpics,
  organizationEpics,
  persistenceEpics,
  projectEpics,
  reportingEpics,
  scenarioEpics,
  symbologyEpics,
  taskEpics,
  toastEpics,
  userEpics,
  websocketEpics,
  viewEpics,
};

// Only set up third-party SaaS epics if our environment
// exposes their app ids to us. Also set them up in test,
// so we can be sure they don't break when actions flow through them.

if (__TESTING__ || (!__DEVELOPMENT__ && process.env.UF_AMPLITUDE_ID)) {
  allEpics.loggingEpics = loggingEpics;
}

if (__TESTING__ || (!__DEVELOPMENT__ && process.env.UF_AMPLITUDE_ID)) {
  allEpics.amplitudeEpics = amplitudeEpics;
}

if (__TESTING__ || (!__DEVELOPMENT__ && process.env.UF_INTERCOM_APP_ID)) {
  allEpics.intercomEpics = intercomEpics;
}

const epics = combineEpics(allEpics, 'uf');

// Import this to add new epics in other places.
// Note: this is a stream, so we can use .next on it.
// eg: rootEpic$.next(newEpic1)
export const rootEpic$ = new BehaviorSubject(epics);

// The rootEpic passed to createEpicMiddleware when we
const rootEpic: Epic<AnyAction, any> = (action$, store, dependencies) => {
  return rootEpic$.pipe(
    mergeMap(epic => {
      try {
        return epic(action$, store, dependencies);
      } catch (ex) {
        // This tends to happen when you use a bad/misspelled operator
        // in an epic, like action$.filtez(...)
        console.error('Error setting up root epic: ', ex);
        throw ex;
      }
    }),
    takeUntil(action$.pipe(ofType(END_OF_ACTIONS))),
  );
};

export default rootEpic;
