import { AnyAction } from 'redux';
import { Epic, ofType } from 'redux-observable';
import { merge as observableMerge, Observable, of as observableOf } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';

import { getActiveProjectId } from 'uf/app/selectors';
import { combineEpics } from 'uf/base/epics';
import { SET_UI_PROPERTY, SetUIPropertyAction } from 'uf/explore/ActionTypes';
import { makeGetProjectScenarios } from 'uf/projects/selectors';
import { setActiveModule, setScenariosVisible } from 'uf/reporting/actions';
import { ROUTE_REPORT } from 'uf/reporting/ActionTypes';
import { makeGetReportingContext } from 'uf/reporting/selectors/routing';
import { makeAddRootQueryEpic } from 'uf/routing/query';

export const addRootQueriesEpic = makeAddRootQueryEpic(ROUTE_REPORT);

export const initializeReportView: Epic<SetUIPropertyAction<boolean>, any> = (
  action$,
  state$,
) => {
  // TODO: move these out
  const getReportingContext = makeGetReportingContext();
  const getProjectScenarios = makeGetProjectScenarios();
  return action$.pipe(
    ofType(SET_UI_PROPERTY),
    filter(action => action.property === 'reportViewVisible' && action.value),
    map(() => {
      const projectId = getActiveProjectId(state$.value);
      const reportingContext = getReportingContext(state$.value, { projectId });
      return reportingContext;
    }),
    filter(
      reportingContext =>
        !reportingContext.activeModuleKey && !!reportingContext.projectId,
    ),
    mergeMap(reportingContext => {
      const { projectId } = reportingContext;
      const actions: Observable<AnyAction>[] = [
        observableOf(setActiveModule(projectId, 'paint_tracking')),
      ];
      const scenarioIds =
        getProjectScenarios(state$.value, { projectId }).map(
          ({ full_path: fullPath }) => fullPath,
        ) || [];
      const visible = scenarioIds.map(() => true);
      actions.push(
        observableOf(setScenariosVisible(projectId, scenarioIds, visible)),
      );
      return observableMerge(...actions);
    }),
  );
};

export default combineEpics(
  {
    initializeReportView,
    addRootQueriesEpic,
  },
  'routing',
);
