import { Spinner, SpinnerSize } from '@blueprintjs/core';
import { boundMethod } from 'autobind-decorator';
import classNames from 'classnames';
import React, { Component } from 'react';
import { Box } from 'react-layout-components';

import { LayerReference, ProjectAvailableLayers } from 'uf-api';
import { DataState, isLoading } from 'uf/data/dataState';
import { LayerId, LayerManagerCollapsedState } from 'uf/layers';
import { events } from 'uf/layers/logging';
import { logEvent } from 'uf/logging';
import { ProjectId } from 'uf/projects';
import rootStyles from 'uf/styles/root.module.css';
import LayerMetadataSummary from 'uf/ui/layers/LayerMetadataSummary/LayerMetadataSummary';
import { sources } from 'uf/ui/layers/logging';

import styles from './LayerManager.module.css';
import LayerManagerList from './LayerManagerList/LayerManagerList';
import LayerManagerSearch from './LayerManagerSearch/LayerManagerSearch';

const layerSummaryClassName = classNames(
  rootStyles.thinMarginLeft,
  rootStyles.allowHorizontalTruncation,
);

interface Props {
  projectId: ProjectId;
  availableProjectLayersState: DataState<ProjectAvailableLayers>;
  restrictedLayerIds: string[];
  layerManagerActiveLayerId?: string;
  isCollapsedByCategory: LayerManagerCollapsedState;
  onSetActiveLayerId?: (layerId: LayerId) => void;
  onToggleCategoryCollapsed?: (
    categoryKey: string,
  ) => (collapsed: boolean) => void;
}

interface State {
  searchString: string;
  filterLayerByName: (layerReference: LayerReference) => boolean;
}
export default class LayerManager extends Component<Props, State> {
  state = {
    // The search string the user has typed in
    searchString: '',

    // a predicate function that takes a layer and
    // returns true if the name matches the searchString
    filterLayerByName: undefined,
  };

  @boundMethod
  setActiveLayerManagerLayerId(layerId) {
    const { projectId, onSetActiveLayerId } = this.props;
    onSetActiveLayerId(layerId);
    logEvent(events.LAYER_SELECTED, {
      projectId,
      layerId,
      source: sources.LAYER_MANAGER,
    });
  }

  @boundMethod
  updateSearchString(searchString: string) {
    this.setState({
      searchString,
      filterLayerByName: makeFilterLayerByName(searchString),
    });
  }

  render() {
    const {
      projectId,
      restrictedLayerIds,
      availableProjectLayersState,
      layerManagerActiveLayerId,
      isCollapsedByCategory,
      onToggleCategoryCollapsed,
    } = this.props;
    const { searchString, filterLayerByName } = this.state;

    if (isLoading(availableProjectLayersState)) {
      return (
        <Box className={styles.loadingDialog} center>
          <Spinner size={SpinnerSize.SMALL} />
        </Box>
      );
    }

    return (
      <Box className={styles.activeDialog}>
        <Box
          column
          flex={3}
          flexShrink={0}
          className={rootStyles.overflowHidden}>
          <LayerManagerSearch
            value={searchString}
            onSearchInputChange={this.updateSearchString}
          />
          <LayerManagerList
            projectId={projectId}
            restrictedLayerIds={restrictedLayerIds}
            isSearchResult={!!searchString}
            filterFn={filterLayerByName}
            layerManagerActiveLayerId={layerManagerActiveLayerId}
            onLayerClick={this.setActiveLayerManagerLayerId}
            onToggleCategoryCollapsed={onToggleCategoryCollapsed}
            isCollapsedByCategory={isCollapsedByCategory}
          />
        </Box>

        <Box
          column
          height="100%"
          flex={7}
          flexShrink={0}
          className={layerSummaryClassName}>
          <LayerMetadataSummary
            projectId={projectId}
            charLimit={180}
            layerId={layerManagerActiveLayerId}
            showTitle
            onSearchInputChangeByTag={this.updateSearchString}
          />
        </Box>
      </Box>
    );
  }
}

function makeFilterLayerByName(searchString) {
  return (layerReference: LayerReference) => {
    const { details = {} } = layerReference;
    const layerName = details.name;
    const searchTags = details?.display_hints?.search_tags || [];
    const searchText = `${layerName} ${searchTags.join(' ')}`;
    return searchText.toLowerCase().includes(searchString.toLowerCase().trim());
  };
}
