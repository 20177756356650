import { Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import warning from 'warning';

import { User } from 'uf-api';
import {
  Actions,
  makeWebsocketMessage,
  ScopeTypes,
  WebsocketMessage,
} from 'uf-ws/WebsocketActions';
import { getActiveProjectId } from 'uf/app/selectors';
import { SwaggerThunkExtra } from 'uf/base/xhr';
import { ProjectId } from 'uf/projects';
import { getSessionId, getUser, getUsername } from 'uf/user/selectors/user';

import {
  WebsocketMessageAction,
  WEBSOCKETS_INITIALIZED,
  WEBSOCKETS_READY,
  WEBSOCKETS_RECEIVE_ERROR,
  WEBSOCKETS_RECEIVE_MESSAGE,
  WEBSOCKETS_SENT_MESSAGE,
  WEBSOCKETS_TERMINATED,
  WebsocketsInitializedAction,
  WebsocketsReadyAction,
} from './actionTypes';

export function websocketsInitialized(): WebsocketsInitializedAction {
  return {
    type: WEBSOCKETS_INITIALIZED,
  };
}

export function websocketsReady(): WebsocketsReadyAction {
  return {
    type: WEBSOCKETS_READY,
  };
}

export function websocketsTerminated() {
  return {
    type: WEBSOCKETS_TERMINATED,
  };
}

export function handleWebsocketMessage(
  event: MessageEvent,
): WebsocketMessageAction {
  try {
    const message = JSON.parse(event.data);
    return websocketMessageReceived(message);
  } catch (ex) {
    return websocketErrorReceived(event, ex);
  }
}

export function websocketMessageReceived(message: WebsocketMessage) {
  return {
    type: WEBSOCKETS_RECEIVE_MESSAGE,
    message,
  };
}

export function websocketErrorReceived(event: MessageEvent, error: Error) {
  return {
    type: WEBSOCKETS_RECEIVE_ERROR,
    error: `Unable to handle websocket event ${error}`,
    exception: error,
    event,
    message: event.data,
  };
}

/**
 * A simple thunk action to send a websocket message
 * @param message a message to send over websockets
 */
export function sendWebsocketMessage<T extends WebsocketMessage>(
  message: T,
): ThunkAction<any, any, SwaggerThunkExtra, any> {
  return (dispatch, getState, { client }) => {
    warning(
      client.socket,
      'Cannot send websocket message, no websocket client exists. Websockets may have failed to initialize.',
    );
    if (!client.socket) {
      return;
    }
    client.socket.send(JSON.stringify(message));
  };
}

function sentWebsocketMessage(message) {
  return {
    type: WEBSOCKETS_SENT_MESSAGE,
    message,
  };
}

export function subscribeAll() {
  return (dispatch, getState) => {
    dispatch(subscribeSession());
    dispatch(subscribeUsername());

    const projectId = getActiveProjectId(getState());
    const user = getUser(getState());
    dispatch(subscribeProject(projectId, user));
  };
}

export function subscribeSession(): ThunkAction<
  any,
  any,
  SwaggerThunkExtra,
  any
> {
  return (dispatch: Dispatch, getState, { client }) => {
    const sessionId = getSessionId(getState());

    function subscribe(socket) {
      warning(!!sessionId, 'Missing session id in websocket subscription');
      if (!sessionId) {
        return;
      }
      const message = makeWebsocketMessage(
        Actions.SUBSCRIBE,
        ScopeTypes.SESSION,
        sessionId,
      );
      socket.send(JSON.stringify(message));
      dispatch(sentWebsocketMessage(message));
    }

    client.addEventListener('websocketconnect', ({ detail }) => {
      const { socket } = detail;
      subscribe(socket);
    });

    if (client.socket) {
      subscribe(client.socket);
    }
  };
}

export function subscribeUsername() {
  return (dispatch: Dispatch, getState, { client }) => {
    const username = getUsername(getState());
    const sessionId = getSessionId(getState());
    function subscribe(socket) {
      warning(!!username, 'Missing username in websocket subscription');
      if (!username) {
        return;
      }
      warning(!!sessionId, 'Missing session id in websocket subscription');
      if (!sessionId) {
        return;
      }
      const message = makeWebsocketMessage(
        Actions.SUBSCRIBE,
        ScopeTypes.USER,
        username,
      );

      socket.send(JSON.stringify(message));
      dispatch(sentWebsocketMessage(message));
    }

    client.addEventListener('websocketconnect', ({ detail }) => {
      const { socket } = detail;
      subscribe(socket);
    });
    if (client.socket) {
      subscribe(client.socket);
    }
  };
}

export function subscribeProject(projectId: ProjectId, user: User) {
  return (dispatch: Dispatch, getState, { client }) => {
    const sessionId = getSessionId(getState());
    warning(!!projectId, 'Missing project id in websocket subscription');
    if (!projectId) {
      return;
    }
    warning(!!sessionId, 'Missing session id in websocket subscription');
    if (!sessionId) {
      return;
    }
    function subscribe(socket) {
      const subscriptionMessage = makeWebsocketMessage(
        Actions.SUBSCRIBE,
        ScopeTypes.PROJECT,
        projectId,
      );

      socket.send(JSON.stringify(subscriptionMessage));
      dispatch(sentWebsocketMessage(subscriptionMessage));
    }

    client.addEventListener('websocketconnect', ({ detail }) => {
      const { socket } = detail;
      subscribe(socket);
    });

    if (client.socket) {
      subscribe(client.socket);
    }
  };
}

export function unsubscribeFromProject(projectId: ProjectId) {
  return (dispatch: Dispatch, getState, { client }) => {
    warning(!!projectId, 'Missing project id in websocket subscription');
    if (!projectId) {
      return;
    }
    function subscribe(socket) {
      const subscriptionMessage = makeWebsocketMessage(
        Actions.UNSUBSCRIBE,
        ScopeTypes.PROJECT,
        projectId,
      );

      socket.send(JSON.stringify(subscriptionMessage));
      dispatch(sentWebsocketMessage(subscriptionMessage));
    }

    client.addEventListener('websocketconnect', ({ detail }) => {
      const { socket } = detail;
      subscribe(socket);
    });

    if (client.socket) {
      subscribe(client.socket);
    }
  };
}
