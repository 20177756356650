/**
 * UrbanFootprint App API
 * API for serving UrbanFootprint Application
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { TaskProblem } from './taskProblem';
import { TaskInternalInfo } from './taskInternalInfo';

/**
 * Metadata for a Task.      Note: This object bears a striking resemblance to     notifier.TaskNotification. Ideally they\'d somehow share schema, but because     a TaskNotification has notification fields, it makes it difficult.
 */
export interface Task<Tinfo extends object = any> {
  /**
   * When the task finished.
   */
  end_time?: Date;
  /**
   * When the task was enqueued
   */
  enqueue_time: Date;
  /**
   * Additional information derived from params that are suitable for exposing to users.
   */
  info?: Tinfo;
  internal?: TaskInternalInfo;
  problem?: TaskProblem;
  /**
   * The project path, if this task belongs to a project.
   */
  project_path?: string;
  /**
   * When the task started running
   */
  run_start_time?: Date;
  /**
   * Current state of the task.
   */
  status?: Task.StatusEnum;
  /**
   * The unique ID of a task.
   */
  task_id: string;
  /**
   * The type of task being performed
   */
  task_type: string;
}
export namespace Task {
  export type StatusEnum =
    | 'enqueued'
    | 'running'
    | 'done'
    | 'error'
    | 'cancelled';
  export const StatusEnum = {
    Enqueued: 'enqueued',
    Running: 'running',
    Done: 'done',
    Error: 'error',
    Cancelled: 'cancelled',
  } as const;
}
