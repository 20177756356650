import colors from 'material-colors';

import {
  CuratedColumnSymbology,
  CUSTOM_THEME,
  DEFAULT_OPACITY,
  DistributionTypes,
  InterpolationTypes,
  PaintColorTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/public/dataset/egrid_data_by_subregion_2014': {
    srco2crt: [
      {
        type: SymbologyTypes.FILL,
        paint: {
          'fill-color': {
            property: 'srco2crt',
            type: PaintColorTypes.INTERVAL,
            stops: [
              { value: 0, label: '0 to 300', color: colors.green[300] },
              {
                value: 300,
                label: '300 to 600',
                color: colors.lightGreen[200],
              },
              { value: 600, label: '600 to 800', color: colors.lime[200] },
              { value: 800, label: '800 to 1000', color: colors.yellow[200] },
              { value: 1000, label: '1000 to 1300', color: colors.amber[200] },
              { value: 1300, label: '1300 to 1600', color: colors.orange[300] },
              {
                value: 1600,
                label: '1600 to 1800',
                color: colors.deepOrange[400],
              },
              { value: 1800, label: '1800 or More', color: colors.red[900] },
            ],
          },
          'fill-opacity': DEFAULT_OPACITY,
        },
        display_hints: {
          scale: SymbologyScales.NUMERIC,
          distribution: DistributionTypes.DEFAULT,
          interpolation: InterpolationTypes.INTERVAL,
          theme: CUSTOM_THEME,
        },
      },
    ],
  },
};

export default symbologies;
