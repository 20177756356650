import colors from 'material-colors';
import { t } from 'ttag';

import {
  CuratedColumnSymbology,
  CUSTOM_THEME,
  DistributionTypes,
  InterpolationTypes,
  PaintColorTypes,
  SymbologyDisplayHints,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

function makeBasicColumnStyle(
  propName,
  colorsStopsList,
): CuratedColumnSymbology {
  const displayHints: SymbologyDisplayHints = {
    scale: SymbologyScales.NUMERIC,
    distribution: DistributionTypes.DEFAULT,
    interpolation: InterpolationTypes.INTERVAL,
    theme: CUSTOM_THEME,
    showZero: false,
  };

  return {
    type: SymbologyTypes.FILL,
    paint: {
      'fill-color': {
        property: propName,
        type: PaintColorTypes.INTERVAL,
        stops: colorsStopsList,
      },
      'fill-opacity': 0.7,
    },
    display_hints: displayHints,
  };
}

function makeCategoricalColumnStyle(
  propName,
  colorsStopsList,
): CuratedColumnSymbology {
  return {
    type: SymbologyTypes.FILL,
    paint: {
      'fill-color': {
        property: propName,
        type: PaintColorTypes.CATEGORICAL,
        stops: colorsStopsList,
      },
      'fill-opacity': 0.7,
    },
    display_hints: {
      scale: SymbologyScales.CATEGORICAL,
      distribution: null,
      interpolation: InterpolationTypes.CATEGORICAL,
      theme: CUSTOM_THEME,
    },
  };
}

/**
 * NOTE: The AnalysisSymbologies are all missing the `id` field and can't be used
 * out of the box. The `id` should be added via generateAnalysisModuleSymbology.
 */
export const WaterDemand = {
  per_hh_residential_use: [
    makeBasicColumnStyle('per_hh_residential_use', [
      { value: 5000, color: '#d9ebff' },
      { value: 10000, color: '#B3D8FF' },
      { value: 25000, color: '#9FC6F0' },
      { value: 50000, color: '#8BB5E1' },
      { value: 75000, color: '#77A4D2' },
      { value: 100000, color: '#6392C3' },
      { value: 150000, color: '#5081B5' },
      { value: 200000, color: '#3C70A6' },
      { value: 250000, color: '#285E97' },
      { value: 300000, color: '#144D88' },
      { value: 500000, color: '#013C7A' },
    ]),
  ],
  per_capita_residential_use: [
    makeBasicColumnStyle('per_capita_residential_use', [
      { value: 10000, color: '#d9ebff' },
      { value: 15000, color: '#B3D8FF' },
      { value: 20000, color: '#9FC6F0' },
      { value: 25000, color: '#8BB5E1' },
      { value: 30000, color: '#77A4D2' },
      { value: 50000, color: '#6392C3' },
      { value: 75000, color: '#5081B5' },
      { value: 100000, color: '#3C70A6' },
      { value: 250000, color: '#285E97' },
      { value: 500000, color: '#144D88' },
      { value: 1000000, color: '#013C7A' },
    ]),
  ],
  total_water_use: [
    makeBasicColumnStyle('total_water_use', [
      { value: 1000, color: '#d9ebff' },
      { value: 10000, color: '#B3D8FF' },
      { value: 50000, color: '#9FC6F0' },
      { value: 100000, color: '#8BB5E1' },
      { value: 150000, color: '#77A4D2' },
      { value: 250000, color: '#6392C3' },
      { value: 500000, color: '#5081B5' },
      { value: 1000000, color: '#3C70A6' },
      { value: 2500000, color: '#285E97' },
      { value: 5000000, color: '#144D88' },
      { value: 10000000, color: '#013C7A' },
    ]),
  ],
};

export const AccessibilityModule = {
  park: [
    makeBasicColumnStyle('park', [
      { value: 0, color: '#ffffcc' },
      { value: 5, color: '#c7e9b4' },
      { value: 10, color: '#7fcdbb' },
      { value: 15, color: '#41b6c4' },
      { value: 20, color: '#2c7fb8' },
      { value: 30, color: '#253494' },
    ]),
  ],
  hospital: [
    makeBasicColumnStyle('hospital', [
      { value: 0, color: '#feebe2' },
      { value: 5, color: '#fcc5c0' },
      { value: 10, color: '#fa9fb5' },
      { value: 15, color: '#f768a1' },
      { value: 20, color: '#c51b8a' },
      { value: 30, color: '#7a0177' },
    ]),
  ],
  poi_1: [
    makeBasicColumnStyle('poi_1', [
      { value: 0, color: '#ffffcc' },
      { value: 5, color: '#c7e9b4' },
      { value: 10, color: '#7fcdbb' },
      { value: 15, color: '#41b6c4' },
      { value: 20, color: '#2c7fb8' },
      { value: 30, color: '#253494' },
    ]),
  ],
  poi_2: [
    makeBasicColumnStyle('poi_2', [
      { value: 0, color: '#ffffcc' },
      { value: 5, color: '#c7e9b4' },
      { value: 10, color: '#7fcdbb' },
      { value: 15, color: '#41b6c4' },
      { value: 20, color: '#2c7fb8' },
      { value: 30, color: '#253494' },
    ]),
  ],
  poi_3: [
    makeBasicColumnStyle('poi_3', [
      { value: 0, color: '#ffffcc' },
      { value: 5, color: '#c7e9b4' },
      { value: 10, color: '#7fcdbb' },
      { value: 15, color: '#41b6c4' },
      { value: 20, color: '#2c7fb8' },
      { value: 30, color: '#253494' },
    ]),
  ],
  retail: [
    makeBasicColumnStyle('retail', [
      { value: 0, color: '#bd0026' },
      { value: 5, color: '#f03b20' },
      { value: 10, color: '#fd8d3c' },
      { value: 15, color: '#feb24c' },
      { value: 20, color: '#fed976' },
      { value: 30, color: '#ffffb2' },
    ]),
  ],
  school: [
    makeBasicColumnStyle('school', [
      { value: 0, color: '#edf8fb' },
      { value: 5, color: '#bfd3e6' },
      { value: 10, color: '#9ebcda' },
      { value: 15, color: '#8c96c6' },
      { value: 20, color: '#8856a7' },
      { value: 30, color: '#810f7c' },
    ]),
  ],
  transit: [
    makeBasicColumnStyle('transit', [
      { value: 0, color: '#DB4325' },
      { value: 5, color: '#E68956' },
      { value: 10, color: '#EBD5B0' },
      { value: 15, color: '#c7eae5' },
      { value: 20, color: '#5ab4ac' },
      { value: 30, color: '#01665e' },
    ]),
  ],
  high_quality_transit: [
    makeBasicColumnStyle('high_quality_transit', [
      { value: 0, color: '#DB4325' },
      { value: 5, color: '#E68956' },
      { value: 10, color: '#EBD5B0' },
      { value: 15, color: '#c7eae5' },
      { value: 20, color: '#5ab4ac' },
      { value: 30, color: '#01665e' },
    ]),
  ],
};

export const VMT = {
  pct_walk_trips: [
    makeBasicColumnStyle('pct_walk_trips', [
      { value: 0.02, color: '#fdd2a0' },
      { value: 0.04, color: '#fac38e' },
      { value: 0.06, color: '#f8b57d' },
      { value: 0.08, color: '#f5a76b' },
      { value: 0.1, color: '#f29859' },
      { value: 0.12, color: '#f08a47' },
      { value: 0.15, color: '#ed7c35' },
      { value: 0.2, color: '#eb6e24' },
      { value: 0.25, color: '#e85f12' },
      { value: 0.3, color: colors.orange[900] },
    ]),
  ],
  pct_transit_trips: [
    makeBasicColumnStyle('pct_transit_trips', [
      { value: 0.01, color: '#B4E372' },
      { value: 0.02, color: '#A2CB66' },
      { value: 0.04, color: '#85A754' },
      { value: 0.06, color: '#698343' },
      { value: 0.08, color: '#566B37' },
      { value: 0.1, color: '#43532B' },
      { value: 0.14, color: '#394725' },
      { value: 0.18, color: '#303C20' },
    ]),
  ],
  pct_vehicle_trips: [
    makeBasicColumnStyle('pct_vehicle_trips', [
      { value: 0.5, color: '#8EBBEB' },
      { value: 0.6, color: '#7FAAD8' },
      { value: 0.7, color: '#7099C5' },
      { value: 0.8, color: '#6189B2' },
      { value: 0.85, color: '#5378AD' },
      { value: 0.9, color: '#44688D' },
      { value: 0.95, color: '#35577A' },
      { value: 1.0, color: '#274668' },
    ]),
  ],
};

export const EnergyDemand = {
  per_hh_residential_energy_use: [
    makeBasicColumnStyle('per_hh_residential_energy_use', [
      { value: 5000000, color: '#97D704' },
      { value: 15000000, color: '#B2D103' },
      { value: 30000000, color: '#CBCA03' },
      { value: 50000000, color: '#C5A703' },
      { value: 70000000, color: '#B96602' },
      { value: 80000000, color: '#B34802' },
      { value: 100000000, color: '#AD2C02' },
      { value: 120000000, color: '#A71102' },
      { value: 140000000, color: '#A1020B' },
    ]),
  ],
  per_capita_residential_energy_use: [
    makeBasicColumnStyle('per_capita_residential_energy_use', [
      { value: 5000000, color: '#97D704' },
      { value: 10000000, color: '#B2D103' },
      { value: 15000000, color: '#CBCA03' },
      { value: 20000000, color: '#C5A703' },
      { value: 25000000, color: '#BF8603' },
      { value: 30000000, color: '#B96602' },
      { value: 35000000, color: '#B34802' },
      { value: 40000000, color: '#AD2C02' },
      { value: 50000000, color: '#A71102' },
      { value: 75000000, color: '#A1020B' },
    ]),
  ],
  total_electricity_use: [
    makeBasicColumnStyle('total_electricity_use', [
      { value: 10000, color: '#97D704' },
      { value: 50000, color: '#B2D103' },
      { value: 100000, color: '#CBCA03' },
      { value: 250000, color: '#C5A703' },
      { value: 500000, color: '#BF8603' },
      { value: 750000, color: '#B96602' },
      { value: 1000000, color: '#B34802' },
      { value: 2500000, color: '#AD2C02' },
      { value: 5000000, color: '#A71102' },
      { value: 10000000, color: '#A1020B' },
    ]),
  ],
};

export const PublicHealth = {
  adult_all_walk_minutes: [
    makeBasicColumnStyle('adult_all_walk_minutes', [
      { value: 1, color: '#C3523C' },
      { value: 2.5, color: '#B1782D' },
      { value: 5, color: '#9F9F1E' },
      { value: 7.5, color: '#8DC60F' },
      { value: 10, color: '#7BED00' },
      { value: 15, color: '#5FBC1E' },
      { value: 20, color: '#438C3D' },
      { value: 25, color: '#275C5B' },
      { value: 30, color: '#0B2C7A' },
    ]),
  ],
};

export const GhgEmissions = {
  total_residential_ghg_per_hh: [
    makeBasicColumnStyle('total_residential_ghg_per_hh', [
      { value: 1, color: colors.green[50] },
      { value: 2.5, color: colors.green[200] },
      { value: 5, color: colors.green[400] },
      { value: 7.5, color: colors.green[600] },
      { value: 10, color: colors.green[800] },
      { value: 25, color: colors.green[900] },
    ]),
  ],
};

export const HouseholdCost = {
  residential_energy_cost_per_hh: [
    makeBasicColumnStyle('residential_energy_cost_per_hh', [
      { value: 500, color: colors.deepPurple[100] },
      { value: 1000, color: colors.deepPurple[300] },
      { value: 1500, color: colors.deepPurple[500] },
      { value: 2000, color: colors.deepPurple[700] },
      { value: 3000, color: colors.deepPurple[900] },
    ]),
  ],
};

export const FiscalRevenue = {
  property_tax_revenue_per_acre: [
    makeBasicColumnStyle('property_tax_revenue_per_acre', [
      { value: 5000, color: colors.pink[50] },
      { value: 10000, color: colors.pink[100] },
      { value: 15000, color: colors.pink[200] },
      { value: 20000, color: colors.pink[300] },
      { value: 25000, color: colors.pink[400] },
      { value: 30000, color: colors.pink[500] },
      { value: 50000, color: colors.pink[600] },
      { value: 100000, color: colors.pink[700] },
      { value: 250000, color: colors.pink[800] },
      { value: 500000, color: colors.pink[900] },
    ]),
  ],
  property_tax_revenue: [
    makeBasicColumnStyle('property_tax_revenue', [
      { value: 5000, color: colors.pink[50] },
      { value: 10000, color: colors.pink[100] },
      { value: 15000, color: colors.pink[200] },
      { value: 20000, color: colors.pink[300] },
      { value: 25000, color: colors.pink[400] },
      { value: 30000, color: colors.pink[500] },
      { value: 50000, color: colors.pink[600] },
      { value: 100000, color: colors.pink[700] },
      { value: 250000, color: colors.pink[800] },
      { value: 500000, color: colors.pink[900] },
    ]),
  ],
};

export const ConservationModule = {
  tnc_category: [
    makeCategoricalColumnStyle('tnc_category', [
      { value: 1, label: t`Conifer Forest`, color: '#51b382' },
      { value: 2, label: t`Hardwood Forest`, color: '#367857' },
      { value: 3, label: t`Grassland`, color: '#D8D86E' },
      { value: 4, label: t`Shrub`, color: '#9ec85a' },
      { value: 5, label: t`Barren/Other`, color: '#d9bf89' },
      { value: 6, label: t`Wetland`, color: '#517282' },
      { value: 8, label: t`Conifer Woodland`, color: '#938047' },
      { value: 9, label: t`Water`, color: '#90cce7' },
      { value: 11, label: t`Hardwood Woodland`, color: '#6b5d33' },
      { value: 12, label: t`Desert Shrub`, color: '#d9cf89' },
      { value: 13, label: t`Desert Woodland`, color: '#b9a159' },
      { value: 721, label: t`Developed, Open Space`, color: '#f8bea5' },
      { value: 722, label: t`Developed, Low Density`, color: '#f89f79' },
      { value: 723, label: t`Developed, Medium Density`, color: '#f8713c' },
      { value: 724, label: t`Developed, High Density`, color: '#f84200' },
      { value: 1023, label: t`Cropland (CRP)`, color: '#dddb62' },
      { value: 1024, label: t`Irrigated Hayfield (IRH)`, color: '#c1bf55' },
      { value: 1037, label: t`Dryland Grain Crops (DGR)`, color: '#a29f47' },
      {
        value: 1039,
        label: t`Irrigated Row and Field Crops (IRF)`,
        color: '#828039',
      },
      { value: 1040, label: t`Irrigated Grain Crops (IGR)`, color: '#6a682e' },
      { value: 1042, label: t`Deciduous Orchard (DOR)`, color: '#47772c' },
      { value: 1043, label: t`Vineyard (VIN)`, color: '#9d279d' },
      { value: 1049, label: t`Evergreen Orchard (EOR)`, color: '#579135' },
      { value: 1052, label: t`Rice (RIC)`, color: '#f0f0f0' },
      { value: 1063, label: t`Orchard Vineyard (OVN)`, color: '#89a053' },
    ]),
  ],
};

export const ResiliencyModule = {
  fire_hazard_zone: [
    makeCategoricalColumnStyle('fire_hazard_zone', [
      { value: 'No data available for analysis', color: '#d3d3d3' },
      { value: 'Moderate', color: '#FF7F0E' },
      { value: 'High', color: '#D33115' },
      { value: 'Very High', color: '#9F0500' },
    ]),
  ],
  fld_zone: [
    makeCategoricalColumnStyle('fld_zone', [
      {
        value: 'AH (100YR Shlw)',
        label: 'AH - Areas With A 1% Annual Chance Of Shallow Flooding',
        color: colors.orange[400],
      },
      {
        value: 'AO (100YR River)',
        label: 'AO - River Or Stream Flood Hazard Areas',
        color: colors.orange[500],
      },
      {
        value: 'A (100YR)',
        label: 'A - Areas With A 1% Annual Chance Of Flooding',
        color: colors.deepOrange[500],
      },
      {
        value: 'AE (BFP (AE))',
        label: 'AE - Base Floodplain Elevation',
        color: colors.indigo[500],
      },
      {
        value: 'AR (Temp Risk)',
        label: 'AR - Areas With A Temporarily Increased Flood Risk',
        color: colors.pink[900],
      },
      {
        value: 'A99 (100YR Prot)',
        label:
          'A99 - Areas With A 1% Annual Chance - Protected By Federal Flood Control System',
        color: colors.purple[500],
      },
      {
        value: 'V (Coast(w/BFE))',
        label:
          'V - Coastal Areas With >= 1% Chance Of Flooding And Additional Storm Wave Hazard',
        color: colors.red[500],
      },
      {
        value: 'VE (Coast(No BFE))',
        label:
          'VE - Coastal Areas With >= 1% Chance Of Flooding And Additional Storm Wave Hazard',
        color: colors.red[500],
      },
    ]),
  ],
  least_slr_height: [
    makeBasicColumnStyle('least_slr_height', [
      { value: 1, color: '#084954' },
      { value: 2, color: '#238577' },
      { value: 3, color: '#367857' },
      { value: 4, color: '#8dd45e' },
      { value: 5, color: '#c0fc83' },
      { value: 6, color: '#e6fece' },
    ]),
  ],
  least_slr_height_ft: [
    makeBasicColumnStyle('least_slr_height_ft', [
      { value: 0.5, color: colors.blue[100] },
      { value: 1, color: '#a9d0f3' },
      { value: 2, color: '#98c2eb' },
      { value: 3, color: '#88b4e3' },
      { value: 4, color: '#78a6dc' },
      { value: 5, color: '#6999d4' },
      { value: 6, color: '#5a8bcc' },
      { value: 7, color: '#4c7dc4' },
      { value: 8, color: '#3d6fbb' },
      { value: 9, color: '#2f62b3' },
      { value: 10, color: '#2054aa' },
      { value: 16.4, color: colors.blue[900] },
    ]),
  ],
};

const moduleSymbologies: Record<
  string,
  Record<string, CuratedColumnSymbology[]>
> = {
  tnc_conservation: ConservationModule,
  water_demand: WaterDemand,
  vmt_madison: VMT,
  transportation_anywhere: VMT,
  energy_demand: EnergyDemand,
  public_health: PublicHealth,
  ghg_emission: GhgEmissions,
  household_cost: HouseholdCost,
  fiscal_revenue: FiscalRevenue,
  resiliency: ResiliencyModule,
  walk_accessibility: AccessibilityModule,
  transit_accessibility: AccessibilityModule,
};

export default moduleSymbologies;
