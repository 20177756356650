import { Position } from '@blueprintjs/core';
import { mdiClose } from '@mdi/js';
import { Icon } from '@mdi/react';
import _ from 'lodash';
import React, { useCallback } from 'react';
import { Box } from 'react-layout-components';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { t } from 'ttag';

import {
  clearGeometryFilters as clearGeometryFiltersAction,
  clearPointFilter as clearPointFilterAction,
} from 'uf/explore/actions/filters';
import { clearMapInspection as clearMapInspectionAction } from 'uf/explore/actions/map';
import {
  makeGetLayerGeometryFilters,
  makeGetLayerPointFilter,
} from 'uf/explore/selectors/filters';
import { makeGetLayerInspection } from 'uf/explore/selectors/map';
import { LayerId } from 'uf/layers';
import { events, logEvent } from 'uf/logging';
import { MapMode } from 'uf/map/mapmode';
import { ProjectId } from 'uf/projects';
import { UFState } from 'uf/state';
import rootStyles from 'uf/styles/root.module.css';
import { getHotKeyText } from 'uf/ui/base/hotkeys';
import { IconSizeNew } from 'uf/ui/base/icon';
import Key from 'uf/ui/base/Key/Key';
import { ExploreMapHotKeys } from 'uf/ui/explore/ExploreMap/hotKeys';
import MapButton from 'uf/ui/map/MapButton/MapButton';

interface OwnProps {
  projectId: ProjectId;
  layerId: LayerId;
  mapMode?: MapMode;
  className?: string;
  minimal?: boolean;
}

interface StateProps {
  hasInspection?: boolean;
  hasSelection?: boolean;
}

interface DispatchProps {
  clearMapInspection?: typeof clearMapInspectionAction;
  clearGeometryFilters?: typeof clearGeometryFiltersAction;
  clearPointFilter?: typeof clearPointFilterAction;
}

type Props = OwnProps & StateProps & DispatchProps;

export const ExploreMapClearButton: React.FunctionComponent<Props> = props => {
  const {
    mapMode,
    layerId,
    className,
    clearMapInspection,
    clearGeometryFilters,
    clearPointFilter,
    minimal,
    projectId,
    hasInspection,
    hasSelection,
  } = props;

  const handler = useExploreMapClearButtonHandler(
    clearMapInspection,
    clearGeometryFilters,
    clearPointFilter,
    projectId,
    layerId,
    mapMode,
    hasInspection,
    hasSelection,
  );
  const disabled =
    mapMode === MapMode.INSPECTION ? !hasInspection : !hasSelection;
  const titleText =
    mapMode === MapMode.INSPECTION ? t`Clear inspection` : t`Clear selection`;

  const tooltipText = (
    <Box center>
      {titleText}
      <Key
        className={rootStyles.thinMarginLeft}
        character={getHotKeyText(ExploreMapHotKeys.MAP_CLEAR_SELECTION)}
      />
    </Box>
  );
  return (
    <MapButton
      minimal={minimal}
      className={className}
      id="uf-map-clear-selection"
      tooltipText={tooltipText}
      disabled={disabled}
      onClick={handler}
      tooltipPlacement={Position.BOTTOM}
      icon={<Icon path={mdiClose} size={IconSizeNew.EXTRA_SMALL} />}
    />
  );
};

function makeMapStateToProps() {
  const getInspection = makeGetLayerInspection();
  const getLayerGeometryFilters = makeGetLayerGeometryFilters();
  const getLayerPointFilter = makeGetLayerPointFilter();

  return function mapStateToProps(state: UFState, props: OwnProps): StateProps {
    const { projectId, layerId } = props;
    const layerGeometryFilters = getLayerGeometryFilters(state, {
      projectId,
      layerId,
      parentLayerId: null,
    });
    const layerPointFilter = getLayerPointFilter(state, {
      projectId,
      layerId,
      parentLayerId: null,
    });
    const layerInspection = getInspection(state, {
      layerId,
    });
    const hasInspection = !_.isEmpty(layerInspection);
    const hasSelection =
      !_.isEmpty(layerPointFilter) || !_.isEmpty(layerGeometryFilters);
    return {
      hasInspection,
      hasSelection,
    };
  };
}
function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
  return {
    clearMapInspection: bindActionCreators(clearMapInspectionAction, dispatch),
    clearGeometryFilters: bindActionCreators(
      clearGeometryFiltersAction,
      dispatch,
    ),
    clearPointFilter: bindActionCreators(clearPointFilterAction, dispatch),
  };
}
export default connect(
  makeMapStateToProps,
  mapDispatchToProps,
)(ExploreMapClearButton);

export function useExploreMapClearButtonHandler(
  clearMapInspection: typeof clearMapInspectionAction,
  clearGeometryFilters: typeof clearGeometryFiltersAction,
  clearPointFilter: typeof clearPointFilterAction,
  projectId: ProjectId,
  layerId: LayerId,
  mapMode: MapMode,
  hasInspection: boolean,
  hasSelection: boolean,
) {
  return useCallback(() => {
    if (mapMode === MapMode.INSPECTION && hasInspection) {
      clearMapInspection(layerId);
      return;
    }

    if (!hasSelection) {
      return;
    }
    clearGeometryFilters(projectId, layerId);
    clearPointFilter(projectId, layerId);
    logEvent(events.MAP_CLEAR_SELECTION_CLICKED, { projectId });
  }, [
    clearMapInspection,
    clearGeometryFilters,
    clearPointFilter,
    projectId,
    layerId,
    mapMode,
    hasInspection,
    hasSelection,
  ]);
}
