import { combineReducers } from 'redux';

import { makeSetValueReducer } from 'uf/base/reducerFactories';
import { makeKeyedPromiseReducer } from 'uf/data/makeKeyedPromiseReducer';
import {
  BIND_IMPORT_LAYER_TASK,
  importActionTypes,
  importLayerTaskActionTypes,
} from 'uf/import/ActionTypes';
import {
  BIND_CREATE_BUFFERED_LAYER_TASK,
  createBufferedLayerTaskActionTypes,
  createDynamicLayerActionTypes,
  startCreateBufferedLayerActionTypes,
  UPDATE_PROJECT_STATUS,
} from 'uf/projects/ActionTypes';
import { ProjectsState } from 'uf/projects/state';
import { TaskMessage } from 'uf/tasks/MessageTypes';
import { makeCombinedLegacyTaskReducer } from 'uf/tasks/reducerHelpers';
import projectDialog from './projectDialogSlice';

const updateStatus = makeSetValueReducer<TaskMessage>(UPDATE_PROJECT_STATUS);

const createDynamicLayer = makeKeyedPromiseReducer<string>(
  createDynamicLayerActionTypes,
);

export default combineReducers<ProjectsState>({
  createBufferedLayer: makeCombinedLegacyTaskReducer(
    startCreateBufferedLayerActionTypes,
    BIND_CREATE_BUFFERED_LAYER_TASK,
    createBufferedLayerTaskActionTypes,
  ),
  createDynamicLayer,
  importLayer: makeCombinedLegacyTaskReducer(
    importActionTypes,
    BIND_IMPORT_LAYER_TASK,
    importLayerTaskActionTypes,
  ),
  updateStatus,
  projectDialog,
});
