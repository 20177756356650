/**
 * UrbanFootprint App API
 * API for serving UrbanFootprint Application
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Restrict to data contained with the specified bounding box.
 */
export interface ExpressionWithinBoundingBox<> {
  /**
   * Expression function, set to \"within_bbox\".
   */
  fn: ExpressionWithinBoundingBox.FnEnum;
  /**
   * Easternmost longitude
   */
  max_x: number;
  /**
   * Southernmost latitude
   */
  max_y: number;
  /**
   * Westernmost longitude
   */
  min_x: number;
  /**
   * Northernmost latitude
   */
  min_y: number;
  /**
   * Projection Id. If not specified, defaults to WGS84 (4326)
   */
  srid?: number;
}
export namespace ExpressionWithinBoundingBox {
  export type FnEnum = 'within_bbox';
  export const FnEnum = {
    WithinBbox: 'within_bbox',
  } as const;
}
