import {
  CuratedColumnSymbology,
  CUSTOM_THEME,
  DEFAULT_OPACITY,
  InterpolationTypes,
  PaintColorTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/public/dataset/colorado_transit_lines': {
    route_type_text: [
      {
        type: SymbologyTypes.LINE,
        paint: {
          'line-color': {
            property: 'route_type_text',
            type: PaintColorTypes.CATEGORICAL,
            stops: [
              { value: 'Bus', color: '#1f77b4' },
              { value: 'Cable car', color: '#c7ff6f' },
              { value: 'Ferry', color: '#2f5bcc' },
              { value: 'Gondola, Suspended cable car', color: '#7288c0' },
              { value: 'Rail', color: '#d21245' },
              { value: 'Subway, Metro', color: '#faa635' },
              { value: 'Tram, Streetcar, Light Rail', color: '#2ca02c' },
              { value: 'Funicular', color: '#b2877d' },
            ],
          },
          'line-opacity': DEFAULT_OPACITY,
        },
        display_hints: {
          scale: SymbologyScales.CATEGORICAL,
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          theme: CUSTOM_THEME,
        },
      },
    ],
  },
};

export default symbologies;
