import { Layer } from 'mapbox-gl';
import colors from 'material-colors';

import { LayerMetadata } from 'uf-api';
import { EMPTY_ARRAY } from 'uf/base';
import { assertNever } from 'uf/base/never';
import { ColumnKey, LayerId, LayerVersion } from 'uf/layers';
import { getUfGeometryType } from 'uf/layers/helpers';
import { StyleLayerMetadata } from 'uf/map/stylelayers/stylelayers';
import { SymbologyTypes } from 'uf/symbology';
import { symbologyTypeSupportsColor } from 'uf/symbology/helpers';
import { makeStyleSymbologyId } from 'uf/symbology/spec/id';

export const defaultHoverColor = colors.amber.a700;
export const defaultHoverOpacity = 0.4;

export function makeHoverStyleLayer(
  layerId: LayerId,
  sourceId: string,
  type: SymbologyTypes,
  hoverColor = defaultHoverColor,
  hoverOpacity = defaultHoverOpacity,
) {
  const id = makeStyleSymbologyId(layerId, '_hover_', type);

  // Cannot do special hover style if we can't do special coloring
  if (!symbologyTypeSupportsColor(type)) {
    return null;
  }
  // SUPER HACK for proof of concept - specialized layer based on layer type
  let paint: Layer['paint'];
  switch (type) {
    case SymbologyTypes.LINE:
      paint = {
        'line-color': hoverColor,
        'line-width': 2,
        'line-opacity': [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          1,
          0,
        ],
      };
      break;
    case SymbologyTypes.CIRCLE:
      paint = {
        'circle-radius': 4,
        'circle-opacity': [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          1,
          0,
        ],
        'circle-color': hoverColor,
      };
      break;
    case SymbologyTypes.FILL:
      paint = {
        'fill-color': hoverColor,
        'fill-opacity': [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          hoverOpacity,
          0,
        ],
      };
      break;

    default:
      assertNever(type);
      return null;
  }
  const uf: StyleLayerMetadata = {
    layerId,
  };
  const styleLayer: Layer = {
    id,
    type,
    source: sourceId,
    paint,
    metadata: {
      uf,
    },
  } as Layer;

  // TODO: huge hack. We should somehow look up the *source* layer with id `id`, and
  // Use whatever source/source-layers are set on that.
  styleLayer['source-layer'] = layerId;
  return styleLayer;
}

export function makeHoverStyleLayers(
  layerId: LayerId,
  columnKey: ColumnKey,
  metadata: LayerMetadata,
  layerVersion: LayerVersion,
  sourceId: string,
): Layer[] {
  const ufGeometryType = getUfGeometryType(metadata);
  switch (ufGeometryType) {
    case 'Polygon':
    case 'MultiPolygon':
      return [
        makeHoverStyleLayer(layerId, sourceId, SymbologyTypes.LINE),
        makeHoverStyleLayer(layerId, sourceId, SymbologyTypes.FILL),
      ];
    case 'Point':
    case 'MultiPoint':
      return [makeHoverStyleLayer(layerId, sourceId, SymbologyTypes.CIRCLE)];
    case 'LineString':
    case 'MultiLineString':
      return [makeHoverStyleLayer(layerId, sourceId, SymbologyTypes.LINE)];
    case 'GeometryCollection':
      return EMPTY_ARRAY;
    default:
      assertNever(ufGeometryType);
  }
  return EMPTY_ARRAY;
}
