import { ThunkAction } from 'redux-thunk';

import { isBuiltFormKey } from 'uf/builtforms';
import { ColumnKey, LayerId } from 'uf/layers';
import { ensureLayerBreaks } from 'uf/layers/actions/breaks';
import { ensureLayerMetadata } from 'uf/layers/actions/metadata';
import { ensureLayerStats } from 'uf/layers/actions/stats';
import { getColumnKey } from 'uf/layers/geometryKey';
import { makeGetLayerVersion } from 'uf/layers/selectors/versions';
import { ProjectId } from 'uf/projects';
import { DEFAULT_BREAK_COUNT, DEFAULT_BREAK_TYPE } from 'uf/symbology';
import { ensureBuiltFormStops } from 'uf/symbology/actions/builtforms';
import {
  ensureDivideByColumn,
  loadDivideByColumn,
} from 'uf/symbology/actions/divideByColumn';
import { makeGetDivideByColumn } from 'uf/symbology/selectors/divideByColumn';
import { getStatsParams } from 'uf/symbology/stats';
import { ViewId } from 'uf/views';

/**
 * Ensures the default symbology and its cleaning resources.
 *
 * Note: We can't reuse `loadDefaultSymbology` here, because default symbology
 * is a computed property. This means we need to call the "ensure" versions of
 * each of the sub-actions, and make sure we keep these two functions in sync
 * as we add additional sub-actions.
 */
export function ensureDefaultSymbology(
  projectId: ProjectId,
  viewId: ViewId,
  layerId: LayerId,
  columnKey: ColumnKey,
  // TODO: use appropriate signature - #15846
  // ): ThunkAction<Promise<DefaultSymbologyResources>, any, any, any> {
): ThunkAction<any, any, any, any> {
  const getLayerVersion = makeGetLayerVersion();
  const getDivideByColumn = makeGetDivideByColumn();
  return async (dispatch, getState) => {
    // Block until we get the divideByColumn
    // eslint-disable-next-line @typescript-eslint/await-thenable
    await dispatch(ensureDivideByColumn(projectId, viewId, layerId, columnKey));
    const divideByColumn = getDivideByColumn(getState(), {
      projectId,
      layerId,
      columnKey,
    });

    const version = getLayerVersion(getState(), { layerId });
    const statsParams = getStatsParams(
      getColumnKey(columnKey),
      version,
      divideByColumn,
    );
    const actions = [
      ensureLayerMetadata(layerId),
      ensureLayerStats(layerId, statsParams),
      ensureLayerBreaks(
        layerId,
        version,
        getColumnKey(columnKey),
        DEFAULT_BREAK_COUNT,
        DEFAULT_BREAK_TYPE,
        divideByColumn,
      ),
    ];

    if (isBuiltFormKey(columnKey)) {
      actions.push(ensureBuiltFormStops(projectId, layerId));
    }

    return Promise.all(actions.map(action => dispatch(action)));
  };
}
export function loadDefaultSymbology(
  projectId: ProjectId,
  viewId: ViewId,
  layerId: LayerId,
  columnKey: ColumnKey,
): ThunkAction<any, any, any, any> {
  const getLayerVersion = makeGetLayerVersion();
  const getDivideByColumn = makeGetDivideByColumn();
  return async (dispatch, getState) => {
    // Block until we get the divideByColumn
    // eslint-disable-next-line @typescript-eslint/await-thenable
    await dispatch(loadDivideByColumn(projectId, viewId, layerId, columnKey));
    const divideByColumn = getDivideByColumn(getState(), {
      projectId,
      layerId,
      columnKey,
    });
    const version = getLayerVersion(getState(), { layerId });
    const statsParams = getStatsParams(
      getColumnKey(columnKey),
      version,
      divideByColumn,
    );

    const actions = [
      // The rest of the resources only need to be ensured, because the data
      // would only change from a layer version bump.
      ensureLayerMetadata(layerId),
      ensureLayerStats(layerId, statsParams),
      ensureLayerBreaks(
        layerId,
        version,
        getColumnKey(columnKey),
        DEFAULT_BREAK_COUNT,
        DEFAULT_BREAK_TYPE,
        divideByColumn,
      ),
    ];

    if (isBuiltFormKey(columnKey)) {
      actions.push(ensureBuiltFormStops(projectId, layerId));
    }

    return Promise.all(actions.map(action => dispatch(action)));
  };
}
