import {
  CuratedColumnSymbology,
  CUSTOM_THEME,
  DEFAULT_OPACITY,
  InterpolationTypes,
  PaintColorTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/public/dataset/holc_redlining_areas': {
    holc_grade: [
      {
        type: SymbologyTypes.FILL,
        paint: {
          'fill-color': {
            property: 'holc_grade',
            type: PaintColorTypes.CATEGORICAL,
            stops: [
              { value: 'A', label: 'A - "Best"', color: '#83b379' },
              { value: 'B', label: 'B - "Still Desirable"', color: '#7fbcc9' },
              {
                value: 'C',
                label: 'C - "Definitely Declining"',
                color: '#f5d56c',
              },
              { value: 'D', label: 'D - "Hazardous"', color: '#e38ead' },
              { value: 'E', label: 'E - "Other"', color: '#ebeced' },
            ],
          },
          'fill-opacity': DEFAULT_OPACITY,
        },
        display_hints: {
          scale: SymbologyScales.CATEGORICAL,
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          theme: CUSTOM_THEME,
        },
      },
      {
        type: SymbologyTypes.LINE,
        paint: {
          'line-color': {
            property: 'holc_grade',
            type: PaintColorTypes.CATEGORICAL,
            stops: [
              { value: 'A', color: '#83b379' },
              { value: 'B', color: '#7fbcc9' },
              { value: 'C', color: '#f5d56c' },
              { value: 'D', color: '#e38ead' },
              { value: 'E', color: '#ebeced' },
            ],
          },
          'line-opacity': DEFAULT_OPACITY,
        },
        display_hints: {
          scale: SymbologyScales.CATEGORICAL,
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          theme: CUSTOM_THEME,
        },
      },
    ],
  },
};

export default symbologies;
