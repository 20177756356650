/**
 * Label formatting for column/layer names.
 *
 * There are two dimensions: global vs local, and name vs abbreviation.
 *
 * "Local" means that you have some kind of context:
 * e.g. "Agriculture" and "Military" under an "Employment" heading,
 * instead of "Agricultural Employment" and "Military Employment".
 *
 * "Global" means the label stands on its own, e.g. "Agricultural Employment"
 *
 * Rules:
 * 1. Local vs Global takes precident over name vs abbreviation.
 * 2. Local falls back to Global, but not the other way around.
 *    (Good to add context, bad to lose it unexpectedly)
 * 3. Name falls back to Abbreviation, and Abbreviation falls back to name
 * 4. defaultValue falls back to key.
 *
 */
import _ from 'lodash';
import warning from 'warning';

import slug from 'uf/base/slug';

const formatterCache = {};
function getFormatter(
  minimumFractionDigits: number,
  maximumFractionDigits: number,
): Intl.NumberFormat {
  if (!(minimumFractionDigits in formatterCache)) {
    formatterCache[minimumFractionDigits] = {};
  }
  if (!(maximumFractionDigits in formatterCache[minimumFractionDigits])) {
    const value = new Intl.NumberFormat(undefined, {
      minimumFractionDigits,
      maximumFractionDigits,
    });
    formatterCache[minimumFractionDigits][maximumFractionDigits] = value;
    return value;
  }
  return formatterCache[minimumFractionDigits][maximumFractionDigits];
}

export interface FormatNumericOptions {
  /**
   * The maximum number of digits to show after the decimal place.  Values are rounded if necessary.
   * @default 3
   */
  maximumFractionDigits?: number;

  /**
   * The minimum number of digits to show after the decimal place.  Will pad with zeros if necessary.
   * @default 0
   */
  minimumFractionDigits?: number;

  /**
   * If the absolute value of the number is less than one, this is the number of non-zero digits
   * that will be shown.  If there are fewer non-zero digits than the precision, the number will be
   * padded with zeros.  If there are more non-zero digits than the precision, the number will be rounded.
   * This value will override the min/maxFractionDigit options for values between 1 and -1.  Set
   * this to zero to disable.
   * @default 3
   */
  fractionalPrecision?: number;

  /**
   * if the value is meant to be directly editable by the user through ui
   * @default false
   */
  userEditable?: boolean;
  NaNSymbol?: string;
}

export const NAN_SYMBOL = '-';

const defaultFormatNumericOptions: FormatNumericOptions = {
  maximumFractionDigits: 3,
  minimumFractionDigits: 0,
  fractionalPrecision: 3,
  userEditable: false,
  NaNSymbol: NAN_SYMBOL,
};
/**
 * Formats a value according to the current locale, optionally allowing
 * specification of the minimum and maximum number of digits to display.
 *
 * Can be used to format values suitable for <input type="number"> by
 * passing `false` for `userEditable`. This will leave out any locale
 * formatting like "," or "-"
 *
 * @param value the value to be formatted
 * @param options formatting options (property: default):
     maximumFractionDigits: 3,
     minimumFractionDigits: 0,
     fractionalPrecision: 3,
     userEditable: false,
     NaNSymbol: NAN_SYMBOL,
 */
export function formatNumeric(
  value: string | number,
  options?: Partial<FormatNumericOptions>,
): string {
  const {
    maximumFractionDigits,
    minimumFractionDigits,
    fractionalPrecision,
    userEditable,
    NaNSymbol,
  } = { ...defaultFormatNumericOptions, ...options };

  let numberValue: number;
  if (typeof value === 'string' || typeof value === 'undefined') {
    if (!Number.isFinite(parseFloat(value)) || value === '') {
      return userEditable ? '' : NaNSymbol;
    }
    numberValue = parseFloat(value);
  } else if (value === null || !Number.isFinite(value)) {
    return userEditable ? '' : NaNSymbol;
  } else {
    numberValue = value;
  }

  if (Math.abs(numberValue) < 1 && numberValue !== 0 && fractionalPrecision) {
    const formattedValue = numberValue.toPrecision(fractionalPrecision);
    const numDigitsAfterDecimal = formattedValue.split('.')?.[1]?.length ?? 0;
    warning(
      maximumFractionDigits >= numDigitsAfterDecimal,
      'fractionalPrecision takes precidence over maximumFractionDigits for small values.  Set fractionalPrecision to 0 to disable this.',
    );
    return numberValue.toPrecision(fractionalPrecision);
  }

  const magnitudeAdjust = 10 ** maximumFractionDigits;
  const rounded = Math.round(numberValue * magnitudeAdjust) / magnitudeAdjust;
  if (userEditable) {
    warning(
      minimumFractionDigits === maximumFractionDigits,
      'If using fixed formatting, min and max digits must be equal',
    );
    return toFixed(rounded, minimumFractionDigits);
  }

  return getFormatter(minimumFractionDigits, maximumFractionDigits).format(
    rounded,
  );
}

/**
 *  Rounds a number to the specified decimal place.  Returns a Number.
 */
export function roundTo(value: string | number, precision = 1) {
  if (value === undefined) {
    // avoid returning NaN from the calculations below
    return undefined;
  }

  if (value === null) {
    // avoid returning NaN from the calculations below
    return null;
  }

  const factor = 10 ** precision;
  const numberValue = typeof value === 'string' ? parseFloat(value) : value;
  return Math.round(numberValue * factor) / factor;
}

/**
 *  Formats a value to USD.  If we internationalize we will have to expand this to return
 *  different formats.
 */
export function formatCurrency(num: string | number) {
  warning(_.isNumber(num), 'value cannot be formatted to currency');
  if (!_.isNumber(num)) {
    return NAN_SYMBOL;
  }

  const options = {
    style: 'currency',
    currency: 'USD',
  };

  return num.toLocaleString('en-US', options);
}

/**
 *  Formats a UTC date string to something more human readble.
 */
export function formatDateString(dateString: string | Date) {
  const date = _.isString(dateString) ? new Date(dateString) : dateString;
  if (!date) {
    return '';
  }

  warning(date.getDate(), 'string is not a valid date');
  if (!date.getDate()) {
    return '';
  }

  return date.toLocaleDateString();
}

/**
 *  Formats a UTC date string to a human readable date and time.
 */
export function formatDateTimeString(dateString: string | Date) {
  const date = _.isString(dateString) ? new Date(dateString) : dateString;
  if (!date) {
    return '';
  }

  warning(date.getDate(), 'string is not a valid date');
  if (!date.getDate()) {
    return '';
  }

  return date.toLocaleString();
}

/**
 *  Formats a string to use as a namespace for an organization.
 */
export function formatNamespace(namespace: string) {
  return slug(namespace);
}

/**
 * An object that has a name, for which shorter names may be extracted. This can
 * apply to any object that follows this protocol, hence the generic interface.
 */
export interface AbbreviatableItem {
  name?: string;
  key?: string;
  terse_name?: string;
  abbreviation?: string;
  terse_abbreviation?: string;
}
/**
 * Get the in-context short name of the object, starting with
 * `terse_abbreviation`.
 */
export function getLocalAbbreviation(
  item: AbbreviatableItem,
  defaultValue = '',
) {
  return (
    item?.terse_abbreviation ||
    item?.abbreviation ||
    item?.terse_name ||
    item?.name ||
    defaultValue ||
    item?.key
  );
}

/**
 * Get the abbreviated name to be used in a global object, starting with
 * `abbreviation`.
 */
export function getGlobalAbbreviation(
  item: AbbreviatableItem,
  defaultValue = '',
): string {
  return item.abbreviation || item.name || defaultValue || item.key;
}

/**
 * Get the in-context name of the object, starting with `terse_name`.
 */
export function getLocalName(item: AbbreviatableItem, defaultValue = '') {
  return (
    item?.terse_name ||
    item?.name ||
    item?.terse_abbreviation ||
    item?.abbreviation ||
    defaultValue ||
    item?.key
  );
}

/**
 * Get the global full name of the object, starting with `name`.
 */
export function getGlobalName(item: AbbreviatableItem, defaultValue = '') {
  return item.name || item.abbreviation || defaultValue || item.key;
}

/**
 * Case-insensitive comparison using proper locale-sensitive comparison methods.
 *
 * Usage:
 *
 *   array.sort(compareCaseInsensitive(obj => obj.someStringProperty));
 */
export function compareCaseInsensitive<T>(
  extract: (obj: T) => string,
  reverse: boolean = false,
) {
  const compare = (value1: T, value2: T) => {
    // This is because ...localeCompare(null) !== ...localeCompare('');
    const extracted1 = extract(value1) || '';
    const extracted2 = extract(value2) || '';
    // easy check that also handles most non-string values
    if (extracted1 === extracted2) {
      return 0;
    }
    if (_.isString(extracted1)) {
      return extracted1.localeCompare(extracted2, 'en', {
        sensitivity: 'base',
      });
    }
    // account for odd choices like numbers + strings
    if (!extracted1) {
      if (!extracted2) {
        return 0;
      }
      return -1;
    }
    warning(
      _.isString(extracted1),
      `extracted value ${extracted1} is not a string, cannot compare.`,
    );
    return 1;
  };

  if (reverse) {
    return (value1: T, value2: T) => -compare(value1, value2);
  }
  return compare;
}

/**
 * Format a floating point number as a percent
 * @param value A value from 0 to 1
 * @param precision The number of decimal places to show.
 */
export function formatPercent(value: number, precision = 1) {
  const percentValue = formatNumeric(value * 100, {
    maximumFractionDigits: precision,
    minimumFractionDigits: precision,
    // set this to zero because we aren't dealing with small numbers, expect final values to be from 100 to 0 with fixed
    // decimal digits.
    fractionalPrecision: 0,
  });
  return `${percentValue}%`;
}

/** Max # of digits to clamp to, limited by browser */
const MAX_FIXED_PRECISION = 20;
/**
 * Safely convert to a fixed # of digits. Wrapper for Number.toFixed to not
 * crash on edge/etc.
 *
 * @param value The value
 * @param precision The number of digits
 */
export function toFixed(value: number, precision: number) {
  const realPrecision = Number.isFinite(precision) ? precision : 0;
  const clampedPrecision = Math.max(
    MAX_FIXED_PRECISION,
    Math.min(realPrecision, 0),
  );
  return value.toFixed(clampedPrecision);
}

export function getBooleanValue(value: string | boolean) {
  if (typeof value === 'boolean') {
    return value;
  }
  if (!value) {
    return false;
  }
  switch (value.toLowerCase()) {
    case 'true':
    case '1':
      return true;
    case 'false':
    case '0':
      return false;
    default:
      warning(!!false, `Cannot determine boolean value of ${value}`);
      return !_.isEmpty(value);
  }
}

function titleCase(str: string) {
  // Capitalizes the first letter of each word in a string.
  const strList = str.toLocaleLowerCase().split(' ');
  const final = [];
  strList.forEach(word => {
    final.push(word.charAt(0).toLocaleUpperCase() + word.slice(1));
  });
  return final.join(' ');
}

export function formatTitleCase(str: string) {
  /**
   * Replaces underscores and dashes with spaces,
   * cleans up leading and trailing whitespaces,
   * reduces multiple spaces to be a single whitespace between words,
   * and turns each word into Title Case.

   * NOTE: Cannot use built in lodash (_.startCase(str.toLowerCase())) for this
   * because startCase removes special characters.
   * NOTE: The (?<=\S)-(?=\S) regex replaces all dashes that are NOT surrounded by whitespaces.

   * Examples:
   * "layer_to-upload" => "Layer To Upload"
   * "imporTaNt layer - v1" => "Important Layer - V1"
   * "  drops-leading-whitespace_" => "Drops Leading Whitespace"
   * "too   many   spaces" => "Too Many Spaces"
   */
  const dashesReplaced = str.replace(
    /(\s*)([-_]+)(\s*)/g,
    (match, leadingSpace, p2, trailingSpace, offset) => {
      if (leadingSpace && trailingSpace) {
        return match;
      }
      return ' ';
    },
  );
  const whitespaceTrimmed = dashesReplaced.trim();
  const multipleSpacesAsSingleSpace = whitespaceTrimmed.replace(/\s+/g, ' ');
  const capitalizedFirstLetters = titleCase(multipleSpacesAsSingleSpace);

  return capitalizedFirstLetters;
}

// This is a really loose match from https://stackoverflow.com/a/37563868/204357
const ISO_8601 =
  /^\d{4}(-\d\d(-\d\d(T\d\d:\d\d(:\d\d)?(\.\d+)?(([+-]\d\d:\d\d)|Z)?)?)?)?$/i;

/**
 * Determines if a string is date or date-like, such as an ISO8601-formatted
 * string. If this is true, this string can be passed to `formatDate` or
 * `formatDateString`
 */
export function isDateLike(d: string | Date): d is Date | string {
  if (d instanceof Date) {
    return true;
  }
  return ISO_8601.test(d);
}
