import _ from 'lodash';
import {
  FeatureIdentifier,
  Layer,
  Map,
  MapboxGeoJSONFeature,
  MapLayerMouseEvent,
} from 'mapbox-gl';
import { useCallback, useEffect, useRef, useState } from 'react';
import warning from 'warning';

export type MouseHoverEvent = mapboxgl.MapMouseEvent & {
  features?: mapboxgl.MapboxGeoJSONFeature[];
} & mapboxgl.EventData;

export interface MouseStuff {
  mouseMoveLayerIds: Set<string>;
  mouseLeaveLayerIds: Set<string>;
  hoverFeatures: mapboxgl.FeatureIdentifier[];
}

/**
 * Manages
 * @param map a mapbox map instance
 * @param interactiveStyleLayers All of the layers that should support
 *   mouseover. Typically this is a single layer for points and lines, but a pair
 *   of layers for polygons (line and fill)
 */
export function useMouseHover(map: Map, interactiveStyleLayers: Layer[]) {
  // State managed by this hook. Note that this state is entirely dependent on
  // mapbox events, and NOT react state, which is why we use a ref here.
  const mouseRef = useRef<MouseStuff>({
    mouseMoveLayerIds: new Set(),
    mouseLeaveLayerIds: new Set(),
    hoverFeatures: [],
  });

  const onMouseMove = useCallback(
    (e: MouseHoverEvent) => {
      const newHoverFeatures = updateFeatureState(
        e.features,
        mouseRef.current.hoverFeatures,
        map,
      );
      mouseRef.current.hoverFeatures = newHoverFeatures;
    },
    [map],
  );
  const onMouseLeave = useCallback(
    (e: MouseHoverEvent) => {
      const { hoverFeatures } = mouseRef.current;
      const { features } = e;

      mouseRef.current.hoverFeatures = clearFeatureState(
        features,
        hoverFeatures,
        map,
      );
    },
    [map],
  );
  // reset event listeners whenever the interactiveStyleLayers list changes
  const [currentInteractiveStyleLayers, setCurrentInteractiveStyleLayers] =
    useState<Layer[]>([]);

  const updateEventListeners = useCallback(
    (instance: Map, newStyleLayers: Layer[], oldStyleLayers: Layer[]) => {
      if (!instance) {
        return;
      }
      updateMouseListeners(
        instance,
        mouseRef.current,
        newStyleLayers,
        oldStyleLayers,
        onMouseMove,
        onMouseLeave,
      );
      // it's only safe to update the "current" list once we've actually updated
      // the event listeners.
      setCurrentInteractiveStyleLayers(newStyleLayers);
    },
    [onMouseMove, onMouseLeave, mouseRef],
  );

  useEffect(() => {
    updateEventListeners(
      map,
      interactiveStyleLayers,
      currentInteractiveStyleLayers,
    );
  }, [
    map,
    interactiveStyleLayers,
    currentInteractiveStyleLayers,
    updateEventListeners,
  ]);

  // And finally, an initialization function. Note that this can/should only be
  // called once.
  const initializedRef = useRef(false);
  const initialize = useCallback(
    (instance: Map) => {
      const { current: initialized } = initializedRef;
      warning(!initialized, 'Extra initialization');
      warning(!!instance, 'initialize missing map...');
      if (instance && !initialized) {
        updateEventListeners(instance, interactiveStyleLayers, []);
        initializedRef.current = true;
      }
    },
    [updateEventListeners, interactiveStyleLayers],
  );

  return initialize;
}

function updateMouseListeners(
  map: Map,
  mouseState: MouseStuff,
  newStyleLayers: Layer[],
  oldStyleLayers: Layer[],
  onMouseMove: (e: MouseHoverEvent) => void,
  onMouseLeave: (e: MouseHoverEvent) => void,
) {
  if (!map) {
    // happens during map setup if the map has hover set up
    return;
  }
  const { mouseMoveLayerIds, mouseLeaveLayerIds } = mouseState;
  const newStyleLayerIds = newStyleLayers.map(({ id }) => id);
  const oldStyleLayerIds = oldStyleLayers?.map(({ id }) => id) || [];
  if (_.isEqual(newStyleLayerIds, oldStyleLayerIds)) {
    return;
  }

  const newlyAddedStyleLayerIds = _.difference(
    newStyleLayerIds,
    oldStyleLayerIds,
  );
  const removedStyleLayerIds = _.difference(oldStyleLayerIds, newStyleLayerIds);
  // Note that we are not adding mouseenter events, because those are covered
  // by mousemove. (you get a move with each enter) Also there seems to be an
  // out-of-order issue where sometimes mouseenters fire after mouseleave,
  // causing some features to appear hovered when they are not. (Not sure if
  // this is a mapbox issue or a MapView issue)
  updateMouseHoverEvents(
    map,
    newlyAddedStyleLayerIds,
    removedStyleLayerIds,
    'mousemove',
    mouseMoveLayerIds,
    onMouseMove,
  );
  updateMouseHoverEvents(
    map,
    newlyAddedStyleLayerIds,
    removedStyleLayerIds,
    'mouseleave',
    mouseLeaveLayerIds,
    onMouseLeave,
  );
}

/**
 * Update the features for the given state
 * @param newFeatures The features we now want to show as "hovered"
 * @param currentHoveredFeatures The features that are currently "hovered"
 * @param map
 */
function updateFeatureState(
  newFeatures: MapboxGeoJSONFeature[],
  currentHoveredFeatures: FeatureIdentifier[],
  map: Map,
) {
  // If someone is switching basemaps while hovering over the map, this will
  // error out and lock up the map.
  if (!map?.isStyleLoaded()) {
    warning(
      !!currentHoveredFeatures?.length,
      'Trying to update features while style is loading',
    );
    // the same features are still hovered
    return currentHoveredFeatures;
  }

  const newHoveredFeatures: mapboxgl.FeatureIdentifier[] = newFeatures.map(
    ({ id, source, sourceLayer }) => ({
      id,
      source,
      sourceLayer,
    }),
  );
  // only turn off features that are not already being hovered over
  currentHoveredFeatures
    .filter(
      feature =>
        !newHoveredFeatures.find(
          ({ id, source, sourceLayer }) =>
            feature.id === id &&
            feature.source === source &&
            feature.sourceLayer === sourceLayer,
        ),
    )
    .forEach(feature => {
      map.removeFeatureState(feature, 'hover');
    });
  newHoveredFeatures.forEach(feature => {
    if (feature?.id) {
      map.setFeatureState(feature, { hover: true });
    }
  });
  return newHoveredFeatures;
}

/**
 * Clear the hover state for the given features
 * @param newFeaturesToClear New features that we are leaving
 * @param currentHoveredFeatures Features that might still be marked as hovered
 * @param map
 */
function clearFeatureState(
  newFeaturesToClear: MapboxGeoJSONFeature[],
  currentHoveredFeatures: FeatureIdentifier[],
  map: Map,
) {
  // If someone is switching basemaps while hovering over the map, this will
  // error out and lock up the map.
  if (!map?.isStyleLoaded()) {
    warning(
      !!newFeaturesToClear,
      'Trying to clear features while style is loading',
    );
    return currentHoveredFeatures;
  }
  if (newFeaturesToClear) {
    const localFeatures = newFeaturesToClear.map(
      ({ id, source, sourceLayer }) => ({
        id,
        source,
        sourceLayer,
      }),
    );
    // This is generally redundant but harmless, because we should have already
    // unhovered all the most recently hovered elements.
    localFeatures.forEach(feature => {
      map.removeFeatureState(feature, 'hover');
    });
  }
  currentHoveredFeatures.forEach(feature => {
    map.removeFeatureState(feature, 'hover');
  });
  return [];
}

/**
 * Disconnect and reconnect mouse events for the layers as they are
 * added/removed
 */
function updateMouseHoverEvents(
  map: Map,
  newlyAddedStyleLayerIds: string[],
  removedStyleLayerIds: string[],
  eventType: MapLayerMouseEvent['type'],
  currentLayerIdsWithEvent: Set<string>,
  eventHandler: (e: MouseHoverEvent) => void,
) {
  newlyAddedStyleLayerIds.forEach(layerId => {
    if (!currentLayerIdsWithEvent.has(layerId)) {
      currentLayerIdsWithEvent.add(layerId);
      map.on(eventType, layerId, eventHandler);
    }
  });
  removedStyleLayerIds.forEach(layerId => {
    map.off(eventType, layerId, eventHandler);
    currentLayerIdsWithEvent.delete(layerId);
  });
}
