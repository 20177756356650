import { Tab, Tabs } from '@blueprintjs/core';
import classNames from 'classnames';
import React from 'react';

import { TabKey } from 'uf/explore/details';
import rootStyles from 'uf/styles/root.module.css';
import tabStyles from 'uf/styles/tabs.module.css';

import styles from './ExploreDetailsHeader.module.css';
import { TabInfo, TabInfoProps } from './tabs';

interface ExploreDetailsBodyProps {
  activeKey: TabKey;

  // Using 'any' as a hack until react-bootstrap corrects it's type signature for this handler.
  // Currently claims to be a standard event handler (synthetic event), however
  // this actually gets called with the eventKey of the tab.
  onSelectTab: (tabKkey: any) => void;

  tabInfos: TabInfo[];
  tabInfoProps: TabInfoProps;
}

const tabsClassName = classNames(
  'DetailsPane-Tabs',
  styles.tabs,
  rootStyles.flexBox,
  rootStyles.flexColumn,
  rootStyles.overflowHidden,
  tabStyles.tabsBlueprint,
);
const panelClassName = classNames(
  rootStyles.flex,
  rootStyles.flexBox,
  rootStyles.flexColumn,
  'tw-overflow-auto',
);

const ExploreDetailsBody: React.FunctionComponent<ExploreDetailsBodyProps> =
  props => {
    const { activeKey, onSelectTab, tabInfos, tabInfoProps } = props;

    return (
      <Tabs
        id="explore-details-pane-tabs"
        renderActiveTabPanelOnly
        className={tabsClassName}
        onChange={onSelectTab}
        selectedTabId={activeKey}>
        {tabInfos.map(tabInfo => (
          <Tab
            key={tabInfo.key}
            title={tabInfo.title}
            id={tabInfo.key}
            className={`DetailsPane-${tabInfo.key}`}
            panelClassName={classNames(
              panelClassName,
              `DetailsPane-${tabInfo.key}-body`,
            )}
            panel={tabInfo.getComponent(tabInfoProps)}
          />
        ))}
      </Tabs>
    );
  };

export default ExploreDetailsBody;
