import { createSelector, ParametricSelector, Selector } from 'reselect';

import { EMPTY_ARRAY } from 'uf/base/index';
import { SwaggerAny } from 'uf/base/types';
import { DataState, getData } from 'uf/data/dataState';

type DataSelectorType<T, S, DS extends DataState<T> = DataState<T>> =
  | Selector<S, DS>
  | ParametricSelector<S, any, DS>;
/**
 * Use this in place of reselect's `createSelector` when you want to
 * make a selector deal with selecting data out of dataObjects that may
 * not yet have been loaded.
 *
 * Fundamentally this is a wrapper around createSelector that allows
 * for caching at the dataObject level.
 *
 * See 'uf/base/propTypes' for more information on dataObjects.
 *
 * Usage:
 *
 *   const getSomeData = createDataSelector(state => state.mydataObject)
 */
export function createDataSelector<
  T,
  S,
  DS extends DataState<T> = DataState<T>,
>(
  getDataState: DataSelectorType<SwaggerAny, S, DS>,
  defaultData: T = EMPTY_ARRAY as any,
) {
  return createSelector(getDataState, (dataState): T => {
    const result = getData(dataState, defaultData);
    return result;
  });
}

/**
 * Use this in place of reselect's `createSelector` when you want to
 * make a selector deal with selecting data out of dataObjects that may
 * not yet have been loaded.
 *
 * This identical to `createDataSelector` but preserves typing for
 * ParameterizedSelectors
 *
 * @see createDataSelector
 * @param getDataState Selector which returns a dataState
 * @param defaultData The default value to return
 */
export function createParameterizedDataSelector<
  S,
  T = any,
  DS extends DataState<T> = DataState<T>,
  P = any,
>(
  getDataState: ParametricSelector<S, P, DS>,
  defaultData: T = EMPTY_ARRAY as any,
) {
  return createSelector(getDataState, dataState => {
    const result = getData(dataState, defaultData);
    return result;
  });
}
