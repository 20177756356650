/**
 * UrbanFootprint App API
 * API for serving UrbanFootprint Application
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { ClientResponse } from '../variables';

// @ts-ignore: This is used only for params, but some services have param-free APIs
import { AppServiceInterface } from './app.serviceInterface';

// @ts-ignore: These imports are for both params and returnType, but we only use returnType
import { AppVersion } from '../model/appVersion';
// @ts-ignore: These imports are for both params and returnType, but we only use returnType
import { ProblemDetail } from '../model/problemDetail';

/**
 * Package: api
 */

const getVersionPrefix = `uf-api/app/get_version/`;
/**
 * Action types for app.get_version
 *
 * Returns the current version.
 */
export const getVersionActionTypes = {
  LOAD: `${getVersionPrefix}LOAD` as 'uf-api/app/get_version/LOAD',
  SUCCESS: `${getVersionPrefix}SUCCESS` as 'uf-api/app/get_version/SUCCESS',
  FAILURE: `${getVersionPrefix}FAILURE` as 'uf-api/app/get_version/FAILURE',
  CLEAR: `${getVersionPrefix}CLEAR` as 'uf-api/app/get_version/CLEAR',
} as const;

/**
 * LOAD action for app.get_version
 * Returns the current version.
 */
export interface getVersionLoadAction {
  readonly type: 'uf-api/app/get_version/LOAD';

  readonly promise: Promise<ClientResponse<AppVersion>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for app.get_version
 * Returns the current version.
 */
export interface getVersionSuccessAction {
  readonly type: 'uf-api/app/get_version/SUCCESS';
  readonly result: AppVersion;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for app.get_version
 * Returns the current version.
 */
export interface getVersionFailureAction {
  readonly type: 'uf-api/app/get_version/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for app.get_version
 * Returns the current version.
 */
export interface getVersionClearAction {
  readonly type: 'uf-api/app/get_version/CLEAR';
  readonly key: string;
}

export const appActionTypesMap = {
  /** Action types for app.get_version */
  get_version: getVersionActionTypes,
} as const;

export const appDetailsMap = {
  get_version: {
    /** HTTP method for app.get_version */
    httpMethod: 'get' as const,
  },
} as const;

/**
 * Local DataState<T> - kept in sync with uf/data/dataState.ts
 */
interface DataState<T> {
  readonly loading: boolean;
  readonly loaded: boolean;
  readonly requestId: string;
  readonly requestTime?: string;
  readonly responseTime?: string;
  readonly requestCount?: number;
  readonly error?: any;
  readonly promise?: Promise<T>;
  readonly data?: T;
  readonly extra?: any;
  readonly key?: string;
}
export interface AppServiceState {
  /** getVersion state, by key */
  get_version: {
    readonly [key: string]: DataState<AppVersion>;
  };
}
