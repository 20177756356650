import { mdiDownload } from '@mdi/js';
import { Icon } from '@mdi/react';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { downloadData } from 'uf/base/download';
import { getStyleLayers } from 'uf/explore/selectors/map';
import { IconSizeNew } from 'uf/ui/base/icon';
import MapButton from 'uf/ui/map/MapButton/MapButton';
import useUserFlag from 'uf/ui/user/useUserFlag';

const icon = <Icon path={mdiDownload} size={IconSizeNew.EXTRA_SMALL} />;

const SaveStyleLayersButton = () => {
  const show = useUserFlag('dev-download-style-layers');
  const styleLayers = useSelector(getStyleLayers);
  const onClick = useCallback(() => {
    const styleLayersString = JSON.stringify(styleLayers, null, 2);
    downloadData(styleLayersString, 'application/json', 'stylelayers.json');
  }, [styleLayers]);
  if (!show) {
    return null;
  }
  return (
    <MapButton
      icon={icon}
      tooltipText="Download style layers to JSON"
      tooltipPlacement="bottom"
      onClick={onClick}
    />
  );
};

export default SaveStyleLayersButton;
