import _ from 'lodash';

import { EMPTY_ARRAY } from './';

/**
 * Compute the cartesian product of arrays
 *
 * For example, given [[1,2,3], [4, 5]], produce
 * [
 *  [1, 4],
 *  [1, 5],
 *  [2, 4],
 *  [2, 5],
 *  [3, 4],
 *  [3, 5],
 * ]
 */
export function cartesian<T>(arr: T[][]): T[][] {
  if (arr.length === 1) {
    return arr[0].map(item => [item]);
  }
  const [head, ...tail] = arr;
  const result: T[][] = [];
  if (head.length === 0) {
    return cartesian(tail);
  }
  head.forEach(item => {
    const subcombos = cartesian(tail);
    if (subcombos.length === 0) {
      result.push([item]);
    }
    subcombos.forEach(items => {
      result.push([item, ...items]);
    });
  });
  return result;
}

/**
 * Quick helper function to move an element in an array.  This is temporary until lodash adds a
 * _.move() function, see this issue:
 *   https://github.com/lodash/lodash/issues/1701
 * or if we decide to fill it in ourselves with lodash-move:
 *   https://www.npmjs.com/package/lodash-move
 *
 * @param array the array for which we are moving an item
 * @param fromIndex index of item to move
 * @param toIndex new index for item
 */
export function arrayMoveElement<T>(
  array: T[],
  fromIndex: number,
  toIndex: number,
) {
  const newArray = [...array];

  // TODO: handle negative numbers for splice
  if (
    // handle out of bounds indicies
    fromIndex < 0 ||
    fromIndex > array.length - 1 ||
    toIndex < 0 ||
    toIndex > array.length - 1 ||
    // no need to move if indicies are the same
    toIndex === fromIndex
  ) {
    return newArray;
  }

  const elementToMove = newArray.splice(fromIndex, 1)[0];
  newArray.splice(toIndex, 0, elementToMove);

  return newArray;
}

/**
 * Takes an array of arrays and concatenates them together, but deals with some
 * "special" cases where identity-based caching is important:
 *
 *  * If the result is empty, always returns `EMPTY_ARRAY`
 *  * If all of the arrays are empty except one, returns that array. (this helps
 *    when the other arrays are typically empty, and that array is also
 *    constructor for identity-based caching)
 *
 * For the case of a dynamic list of arrays, it can be a replacement for
 * `_.flatten()`, but with smarter semantics:
 *  * If no arrays are passed, returns `EMPTY_ARRAY`
 *  * If only one array is passed in, returns that array.
 *
 * Typical use case in a selector:
 *
 * ```
 * return cacheableConcat(someLayers, someUsuallyEmptyLayers);
 * ```
 *
 * Typical use case for flatten:
 *
 * ```
 * const nestedLayers = layers.map(({ layer }) => getStyleLayers(state, { layerId }));
 * return cacheableConcat(...nestedLayers)
 * ```
 */
export function cacheableConcat<T>(...arrays: T[][]): T[] {
  const nonEmptyArrays = arrays.filter(array => array && array.length > 0);
  if (nonEmptyArrays.length === 0) {
    return EMPTY_ARRAY;
  }
  if (nonEmptyArrays.length === 1) {
    return nonEmptyArrays[0];
  }
  return _.concat([], ...nonEmptyArrays);
}
interface Decorated<D> {
  sortkeys: (string | number)[];
  value: D;
}
/**
 * Sort an array of objects by a sort key, extracting numbers and sorting them
 * numerically instead of lexically. The signature is similar to python's
 * `sorted` with a `key` parameter, but the key is assumed to be a string and
 * the string will be split up into numbers and strings.
 *
 * Using just strings:
 * ```
 *   const sorted = sortNumerically([
 *     'number is 11 today',
 *     'number is 2 tomorrow',
 *     'number is 3.2 yesterday']);
 *   // sorted is ['...2...', '...3.2...', '...11...']
 * ```
 *
 * With a complex object:
 * ```
 *   const sorted = sortNumerically([
 *     name: 'Scenario #1',
 *     key: 's1',
 *   }, {
 *     name: 'Scenario #11',
 *     key: 's11',
 *   }, {
 *     name: 'Scenario #2',
 *     key: 's2',
 *   ]);
 *   // sorted is [{
 *   //   name: 'Scenario #1',..
 *   // }, {
 *   //    name: 'Scenario #2', ...
 *   // }, {
 *   //    name: 'Scenario #11', ...}]
 * ```
 *
 * @param array
 * @param getKey
 */
export function sortedNumerically<D = string>(
  array: D[],
  getKey: (item: D, index: number) => string = v => v as unknown as string,
) {
  if (array.length === 0) {
    return array;
  }
  // decorate
  const split = array.map(
    (str, index): Decorated<D> => ({
      value: str,
      sortkeys: getKey(str, index)
        .split(/([\d.]+)/)
        .map(value => {
          const n = Number.parseFloat(value);
          if (Number.isFinite(n)) {
            return n;
          }
          return value;
        }),
    }),
  );
  // sort - need to make a series of accessor functions, one for each entry in the arrays
  const max = Math.max(...split.map(a => a.sortkeys.length));
  const accessors = _.range(max).map(n => (a: Decorated<D>) => a.sortkeys[n]);
  const sorted = _.sortBy(split, ...accessors);

  // undecorate
  return sorted.map(n => n.value);
}
