import {
  Button,
  ButtonGroup,
  Classes,
  InputGroup,
  Menu,
  Popover,
  Position,
  Spinner,
  SpinnerSize,
  Tooltip,
} from '@blueprintjs/core';
import { mdiCloudDownload } from '@mdi/js';
import Icon from '@mdi/react';
import classNames from 'classnames';
import React, { FunctionComponent, useCallback } from 'react';
import { Box } from 'react-layout-components';
import { t } from 'ttag';
import { useEnqueueColumnExportTaskMutation } from 'uf-api-rtk/store/Api';
import { parseFullPath } from 'uf/base/dataset';
import { formatNumeric } from 'uf/base/formatting';
import {
  NUMERIC_STAT_MAX,
  NUMERIC_STAT_MEAN,
  NUMERIC_STAT_MIN,
  NUMERIC_STAT_SUM,
} from 'uf/base/stats/AggregateTypes';
import { getExportPermissionText, LayerId } from 'uf/layers';
import rootStyles from 'uf/styles/root.module.css';
import { tw } from 'uf/tailwindcss-classnames';
import CanPerform from 'uf/ui/base/CanPerform/CanPerform';
import ErrorMessage from 'uf/ui/base/ErrorMessage/ErrorMessage';
import { IconSizeNew } from 'uf/ui/base/icon';
import OverflowMenuItem from 'uf/ui/base/OverflowMenuItem/OverflowMenuItem';
import WithTooltipButton from 'uf/ui/base/WithTooltipButton/WithTooltipButton';
import useUserFlag from 'uf/ui/user/useUserFlag';
import ColumnFilterMenu from './ColumnFilterMenu';
import styles from './ExploreColumnSummary.module.css';

const buttons = [
  NUMERIC_STAT_SUM,
  NUMERIC_STAT_MEAN,
  NUMERIC_STAT_MIN,
  NUMERIC_STAT_MAX,
];

interface ExploreColumnSummaryProps {
  layerId: LayerId;
  currentStatType: string;
  setStatType: (key: string) => void;
  filteredRowCount?: number;
  totalRowCount?: number;
  columns?: string[];
  busy?: boolean;
  error?: Error;
  onExport: () => void;
  onSetColumnFilter: (columnFilter: string) => void;
  columnFilter: string;
}

export const ExploreColumnSummary: FunctionComponent<ExploreColumnSummaryProps> =
  ({
    currentStatType,
    setStatType,
    filteredRowCount,
    totalRowCount,
    columns,
    layerId,
    onExport,
    busy,
    error,
    columnFilter,
    onSetColumnFilter,
  }) => {
    const columnCount = columns?.length;
    const [enqueueColumnExport] = useEnqueueColumnExportTaskMutation();
    const handleSetColumnFilter = (
      event: React.ChangeEvent<HTMLInputElement>,
    ) => {
      onSetColumnFilter(event.target.value);
    };
    const handleClearColumnFilter = () => {
      onSetColumnFilter('');
    };
    const handleColumnExport = () => {
      const { namespace, type, key } = parseFullPath(layerId);
      void enqueueColumnExport({
        namespace,
        layerType: type,
        key,
        summaryType: currentStatType,
        body: columns,
      });
    };
    const hasPreSalesOrgFlag = useUserFlag('pre-sales');
    return (
      <div
        className={classNames(
          tw('tw-space-x-10', 'tw-items-center', 'tw-p-3'),
          styles.summary,
        )}>
        <Box alignItems="center">
          <strong>{t`Column Summary:`}</strong>
          <ButtonGroup className={rootStyles.thinPaddingLeft}>
            {buttons.map(button => (
              <StatButton
                key={button.key}
                button={button}
                currentStatType={currentStatType}
                setStatType={setStatType}
              />
            ))}
          </ButtonGroup>
        </Box>
        <Box justifyContent="flex-start">
          <CanPerform verb="layer.export" resources={layerId}>
            {({ disabled, loading, reason }) => (
              <Popover
                position={Position.BOTTOM_RIGHT}
                minimal
                content={
                  <Menu>
                    <OverflowMenuItem
                      label={
                        hasPreSalesOrgFlag
                          ? t`Downloading layers is disabled for pre-sales organizations`
                          : t`Layer data`
                      }
                      onClick={onExport}
                      disabled={hasPreSalesOrgFlag}
                    />
                    <OverflowMenuItem
                      disabled={!columnCount}
                      label={t`Column descriptions`}
                      onClick={handleColumnExport}
                    />
                  </Menu>
                }>
                <Tooltip content={getExportPermissionText(!disabled, reason)}>
                  <Button
                    data-testid="export-csv-menu-button"
                    disabled={disabled || !filteredRowCount}
                    loading={loading}
                    className={Classes.TEXT_OVERFLOW_ELLIPSIS}
                    icon={
                      <Icon
                        path={mdiCloudDownload}
                        size={IconSizeNew.EXTRA_SMALL}
                      />
                    }>
                    {t`Export as CSV`}
                  </Button>
                </Tooltip>
              </Popover>
            )}
          </CanPerform>
        </Box>
        <div className={tw('tw-truncate')}>
          {error && <ErrorMessage error={error} />}
        </div>
        <div className={tw('tw-flex')}>
          <InputGroup
            className={rootStyles.thinMarginHorizontal}
            placeholder={t`Filter columns`}
            // cannot use MDI icon and still show inside the textbox
            leftIcon="search"
            value={columnFilter}
            rightElement={
              <Button
                minimal
                onClick={handleClearColumnFilter}
                icon="small-cross"
                small
              />
            }
            small
            onChange={handleSetColumnFilter}
          />
          <ColumnFilterMenu />
        </div>
        <div>{busy && <Spinner size={SpinnerSize.SMALL} />}</div>
        <div>
          <div>
            <strong>
              {t`Rows:`} {formatNumeric(filteredRowCount)} {'/'}{' '}
              {formatNumeric(totalRowCount)}
            </strong>
          </div>
          <div>
            <strong>
              {t`Columns:`} {formatNumeric(columnCount)}
            </strong>
          </div>
        </div>
      </div>
    );
  };

export default ExploreColumnSummary;

interface StatButtonProps {
  button: {
    key: string;
    label: string;
    description: string;
  };
  currentStatType: string;
  setStatType: (key: string) => void;
}

const StatButton: FunctionComponent<StatButtonProps> = ({
  button,
  currentStatType,
  setStatType,
}) => {
  const onClick = useCallback(
    () => setStatType(button.key),
    [button.key, setStatType],
  );
  return (
    <WithTooltipButton
      id={button.key}
      tooltipText={button.description}
      small
      className={Classes.TEXT_OVERFLOW_ELLIPSIS}
      active={button.key === currentStatType}
      disabled={button.key === currentStatType}
      onClick={onClick}>
      {button.label}
    </WithTooltipButton>
  );
};
