import {
  CuratedColumnSymbology,
  DistributionTypes,
  InterpolationTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const lowPovertyIndexLayers = [
  {
    layerId: '/public/databank/hud_low_poverty_index_2020_v2',
    columnKey: 'pov_idx',
  },
];

const symbologies: Record<
  string,
  Record<string, CuratedColumnSymbology[]>
> = {};
lowPovertyIndexLayers.forEach(({ layerId, columnKey }) => {
  symbologies[layerId] = {
    [columnKey]: [
      {
        display_hints: {
          distribution: DistributionTypes.DEFAULT,
          interpolation: InterpolationTypes.INTERVAL,
          scale: SymbologyScales.NUMERIC,
          showZero: true,
          theme: 'd3:interpolateBuPu',
        },
        paint: {
          'fill-color': {
            property: columnKey,
            stops: [
              {
                color: '#49006A',
                value: 0,
              },
              {
                color: '#AD0A81 ',
                value: 21,
              },
              {
                color: '#F369A3 ',
                value: 41,
              },
              {
                color: '#FCC3C3',
                value: 61,
              },
              {
                color: '#FFF7F3',
                value: 81,
              },
            ],
            type: 'interval',
          },
          'fill-opacity': 0.5,
        },
        type: SymbologyTypes.FILL,
      },
      {
        display_hints: {
          distribution: DistributionTypes.DEFAULT,
          interpolation: InterpolationTypes.INTERVAL,
          scale: SymbologyScales.NUMERIC,
          showZero: true,
          theme: 'custom',
        },
        paint: {
          'line-color': '#333333',
          'line-opacity': 0.5,
          'line-width': 0.5,
        },
        type: SymbologyTypes.LINE,
      },
    ],
  };
});

export default symbologies;
