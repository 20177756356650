import { LineLayout } from 'mapbox-gl';

export const defaultLineLayout: LineLayout = Object.freeze({
  /**
   * We use round tips on our lines
   */
  'line-cap': 'round',

  /**
   * Where lines meet, overlap their rounded ends flush with each other
   */
  'line-join': 'round',
});
