import { mdiMapSearchOutline, mdiSelectionSearch } from '@mdi/js';
import { Icon } from '@mdi/react';
import classNames from 'classnames';
import React, {
  Fragment,
  FunctionComponent,
  useCallback,
  useMemo,
} from 'react';
import { t } from 'ttag';

import { zoomToLayer as zoomToLayerAction } from 'uf/explore/actions/map';
import { makeGetLayerFilters } from 'uf/explore/selectors/filters';
import { LayerId } from 'uf/layers';
import { filtersAreEmpty, FilterSpec } from 'uf/layers/filters';
import { makeGetLayerVersion } from 'uf/layers/selectors/versions';
import { logEvent } from 'uf/logging';
import { ProjectId } from 'uf/projects';
import rootStyles from 'uf/styles/root.module.css';
import { IconSizeNew } from 'uf/ui/base/icon';
import useBindAction from 'uf/ui/base/useBindAction/useBindAction';
import { useMakeSelector } from 'uf/ui/base/useMakeSelector';
import { events } from 'uf/ui/explore/logging';
import MapButton from 'uf/ui/map/MapButton/MapButton';

interface Props {
  projectId: ProjectId;
  layerId: LayerId;
}
export const ZoomButtons: FunctionComponent<Props> = ({
  projectId,
  layerId,
}) => {
  const layerFilters = useMakeSelector(makeGetLayerFilters, {
    projectId,
    layerId,
    parentLayerId: null,
  });
  const hasSelection = useMemo(
    () => !filtersAreEmpty(layerFilters),
    [layerFilters],
  );
  const onZoomToSelection = useOnZoomToSelection(
    projectId,
    layerId,
    layerFilters,
  );
  const onZoomToLayer = useOnZoomToLayer(projectId, layerId);
  return (
    <Fragment>
      <MapButton
        minimal
        className={classNames(
          'ExploreMapTopToolbar-zoomToSelection',
          rootStyles.veryThinMarginRight,
        )}
        disabled={!layerId || !hasSelection}
        tooltipText={t`Zoom to selection`}
        tooltipPlacement="bottom"
        icon={<Icon path={mdiSelectionSearch} size={IconSizeNew.SMALL} />}
        onClick={onZoomToSelection}
      />
      <MapButton
        minimal
        disabled={!layerId}
        tooltipText={t`Zoom to layer`}
        tooltipPlacement="bottom"
        icon={<Icon path={mdiMapSearchOutline} size={IconSizeNew.SMALL} />}
        onClick={onZoomToLayer}
      />
    </Fragment>
  );
};
function useOnZoomToLayer(projectId: ProjectId, layerId: LayerId) {
  const layerVersion = useMakeSelector(makeGetLayerVersion, { layerId });
  const zoomToLayer = useBindAction(zoomToLayerAction);
  return useCallback(() => {
    logEvent(events.ZOOM_TO_LAYER_CLICKED);
    zoomToLayer(projectId, layerId, layerVersion);
  }, [zoomToLayer, projectId, layerId, layerVersion]);
}
function useOnZoomToSelection(
  projectId: ProjectId,
  layerId: LayerId,
  layerFilters: Partial<FilterSpec>,
) {
  const layerVersion = useMakeSelector(makeGetLayerVersion, { layerId });
  const zoomToLayer = useBindAction(zoomToLayerAction);
  return useCallback(() => {
    logEvent(events.ZOOM_TO_SELECTION_CLICKED);
    zoomToLayer(projectId, layerId, layerVersion, layerFilters);
  }, [zoomToLayer, projectId, layerId, layerVersion, layerFilters]);
}
