import { Dispatch } from 'redux';
import uniqId from 'uniqid';

import { clearFlaskCacheActionTypes } from 'uf-api/api/internal.service';
import { InternalServiceInterface } from 'uf-api/api/internal.serviceInterface';
import { setActiveProject } from 'uf/app/actions';
import { getActiveProjectId } from 'uf/app/selectors';
import { addAppMessage } from 'uf/appservices/actions';
import { parseFullPath } from 'uf/base/dataset';
import { makeAsyncApiCaller, SwaggerThunkExtra } from 'uf/base/xhr';
import { dispatchAsyncAction } from 'uf/data/loader';
import { SET_DEBUG_FLAG } from 'uf/debug/ActionTypes';
import { loadUserProjects } from 'uf/projects/actions';
import { looksLikeSymbologyKey } from 'uf/symbology/user';
import { getUserKey, getUserOrganizations } from 'uf/user/selectors/user';

export function setDebugFlag(property: string, value: string | string[]) {
  return {
    type: SET_DEBUG_FLAG,
    property,
    value,
  };
}

const fetchClearFlaskCache = makeAsyncApiCaller(
  api => api.internal.clear_flask_cache,
);

function clearFlaskCacheAction() {
  return dispatchAsyncAction(
    clearFlaskCacheActionTypes,
    fetchClearFlaskCache({}),
  );
}

export function clearFlaskCache() {
  return async (dispatch: Dispatch) => {
    const messageId = uniqId();
    dispatch(addAppMessage('Clearing flask cache...', { id: messageId }));
    try {
      await dispatch(clearFlaskCacheAction());
      dispatch(
        addAppMessage('Flask cache cleared.', { replacesMessage: messageId }),
      );
    } catch (err) {
      dispatch(
        addAppMessage(`Failed to clear flask cache: ${err}`, {
          replacesMessage: messageId,
          level: 'warning',
        }),
      );
      throw err;
    }
  };
}

export function ping(params: InternalServiceInterface.pingParams) {
  return (dispatch: Dispatch, getState, { client }: SwaggerThunkExtra) => {
    const messageId = uniqId();
    dispatch(addAppMessage('Pinging backend...'));
    const pingParams = {
      ...params,
    };
    return client
      .clientReady()
      .then(swagger => swagger.apis.internal.ping(pingParams))
      .then(() => {
        dispatch(
          addAppMessage('Backend pinged.', { replacesMessage: messageId }),
        );
      })
      .catch(err => {
        dispatch(
          addAppMessage(`Failed to ping backend: ${err.errObj.message}`, {
            replacesMessage: messageId,
            level: 'warning',
          }),
        );
        throw err;
      });
  };
}

export function createDemoProject() {
  return (dispatch: Dispatch, getState, { client }: SwaggerThunkExtra) => {
    let organizationKey: string;
    const projectId = getActiveProjectId(getState());
    if (!projectId) {
      // just grab the user's first org:
      const organizations = getUserOrganizations(getState());
      if (!organizations || !organizations.length) {
        return Promise.reject(new Error('No current project or organization'));
      }
      ({ key: organizationKey } = organizations[0]);
    } else {
      ({ namespace: organizationKey } = parseFullPath(projectId));
    }
    return client
      .clientReady()
      .then(swagger =>
        swagger.apis.internal.create_demo_project({
          namespace: organizationKey,
        }),
      )
      .then(({ obj: project }) =>
        dispatch(loadUserProjects()).then(() =>
          dispatch(setActiveProject(project.full_path)),
        ),
      );
  };
}

export function clearAllPersistence() {
  return (dispatch: Dispatch, getState, { client }: SwaggerThunkExtra) => {
    const userKey = getUserKey(getState());
    return client.clientReady().then(swagger => {
      return swagger.apis.userdata
        .list_user_data_keys({ user_key: userKey })
        .then(result => result.obj)
        .then(keys =>
          Promise.all(
            keys.map(key =>
              swagger.apis.userdata.delete_user_data({
                user_key: userKey,
                key,
              }),
            ),
          ),
        )
        .then(results =>
          dispatch(
            addAppMessage(
              `${results.length} user keys cleared. Please reload ASAP.`,
              { level: 'warning' },
            ),
          ),
        );
    });
  };
}

export function clearAllUserSymbology() {
  return (dispatch: Dispatch, getState, { client }: SwaggerThunkExtra) => {
    const userKey = getUserKey(getState());
    return client.clientReady().then(swagger => {
      return swagger.apis.userdata
        .list_user_data_keys({ user_key: userKey })
        .then(result => result.obj)
        .then(keys =>
          Promise.all(
            keys
              // Hack: filter for pref keys containing the '|' character,
              // as only user symbology has these as their delimiter
              .filter(key => looksLikeSymbologyKey(key))
              .map(key =>
                swagger.apis.userdata.delete_user_data({
                  user_key: userKey,
                  key,
                }),
              ),
          ),
        )
        .then(results =>
          dispatch(
            addAppMessage(
              `${results.length} user keys cleared. Please reload ASAP.`,
              { level: 'warning' },
            ),
          ),
        );
    });
  };
}

export function clearPrefs() {
  return (dispatch: Dispatch, getState, { client }: SwaggerThunkExtra) => {
    const userKey = getUserKey(getState());
    return client.clientReady().then(swagger => {
      return swagger.apis.userdata
        .list_user_data_keys({ user_key: userKey })
        .then(result => result.obj)
        .then(keys =>
          Promise.all(
            keys
              .filter(
                key => key.startsWith('pref:') || key.startsWith('persist:'),
              )
              .map(key =>
                swagger.apis.userdata.delete_user_data({
                  user_key: userKey,
                  key,
                }),
              ),
          ),
        )
        .then(results =>
          dispatch(
            addAppMessage(
              `${results.length} user keys cleared. Please reload ASAP.`,
              { level: 'warning' },
            ),
          ),
        );
    });
  };
}
