import _ from 'lodash';
import { ApprovedAny, SwaggerAny } from 'uf/base/types';
import { FailureAction, LoadAction, SuccessAction } from 'uf/data/ActionTypes';

export interface DataStateMap<T, E = any> {
  [key: string]: DataState<T, E>;
}

export const initialState = {
  loaded: false,
  loading: false,
  data: undefined,
  requestId: null,
  error: undefined,
};
export function makeLoadingState<
  T,
  A extends LoadAction,
  S extends DataState<T> = DataState<T>,
>(state: S, action: A): S {
  const actionProps = _.pick(action, [
    'key',
    'promise',
    'extra',
    'requestTime',
    'responseTime',
    'requestId',
  ]);

  return {
    ...initialState,
    ...state,
    requestCount: (state?.requestCount || 0) + 1,
    loading: true,
    ...actionProps,
    // Leave `loaded` and `data` alone: there maybe be existing data
    // that should be used until this is loaded.
  };
}

export function makeLoadedState<T, A extends SuccessAction<T>, S = any>(
  state: S,
  action: A,
): DataState<T> {
  const actionProps = _.pick(action, [
    'extra',
    'requestTime',
    'responseTime',
    'requestId',
  ]);
  return {
    ...initialState,
    loading: false,
    loaded: true,
    data: action.result,
    ...actionProps,
    key: action.key,
    requestId: action.requestId,
  };
}

export function makeErrorState<
  T,
  E,
  A extends FailureAction<E>,
  S extends DataState<T> = DataState<T>,
>(state: S, action: A, props: string[] = []): DataState<T> {
  const actionProps = _.pick(action, [
    'key',
    'error',
    'extra',
    'requestTime',
    'responseTime',
    'requestId',
    ...props,
  ]);
  const requestCount = state?.requestCount || 0;
  return {
    ...initialState,
    requestCount,
    loading: false,
    loaded: false,
    data: undefined,
    key: action.key,
    requestId: action.requestId,
    ...actionProps,
  };
}

export function makeClearedState<T>(state: DataState<T>): DataState<T> {
  // Do not change the state unless we really need to, and do not clear states
  // that are loading.
  if (!state || !isLoaded(state)) {
    return state;
  }
  return {
    ...state,
    // its possible that new data is loading, so only clear loaded
    loaded: false,
    data: undefined,
    error: undefined,
  };
}

/**
 * These are routines to manage promise-based objects, which come in this shape:
 * {
 *   loading: <boolean>, // whether or not there is a promise outstanding
 *   loaded: <boolean>,  // whether or not the promise was resolved
 *   data: <any>,        // The result from the promise, if the promise resolved
 *   error: <any>,       // The result of the promise, if hte promise was rejected
 * }
 *
 * There are some simple implications here:
 *
 * - when `loading` is true, neither `data` nor `error` are valid.
 * - when `loaded` is true, only `data` is valid.
 * - when `loaded` is false and `loading` is false, only `error` is valid.
 * - `loaded` and `loading` should never both be true.
 */

// This is distinct from EMPTY_OBJECT, so that we can test for it in tests.
export const EMPTY_STATE = {};
Object.freeze(EMPTY_STATE);

/**
 * A network envelope wrapping a type `T`. If any additional properties will be
 * set, they should be attached in the envelope in the `extra` property, of type
 * TODO(jasonkraus): what are these additional properties used for?
 * `E`.
 */
export interface DataState<T, E = any> {
  /**
   * The data, available only when `loaded` is true.
   *
   * @note Use `getData()` rather than accessing this directly.
   */
  data?: T;

  /**
   * Is the data loaded? Note that the data can be loading but the previous
   * value still loaded.
   *
   * @note Use `isLoaded()` rather than accessing this directly.
   */
  loaded: boolean;
  /**
   * Is the data still loading from the network?
   *
   * @note Use `isLoading()` rather than accessing this directly.
   */
  loading: boolean;

  /**
   * A unique identifier for this request. Useful in places where caching by
   * request parameters isn't possible, ie: POST requests
   */
  requestId: string;

  /**
   * Time of the initial request, ISO8601 format.
   */
  requestTime?: string;
  /**
   * Time of the most recent response, ISO8601 format.
   */
  responseTime?: string;
  /**
   * Number of retries for this request. (Used to calculate a backoff in the
   * case of automatic retries)
   */
  requestCount?: number;

  /**
   * Error object. Set on a network error.
   *
   * @note Use `hasError()`/`getError()` rather thatn accessing this directly.
   */
  error?: any;

  /**
   * The current outstanding promise for the result. Present ONLY if `loading` is true.
   */
  promise?: Promise<T>;

  /**
   * A callback to (re)request data. Present ONLY if useEnsure was used to retrieve the DataState.
   */
  promiseFactory?: () => Promise<any>;

  /**
   * Extra data provided at load time, to survive the whole load/success/fail lifecycle.
   */
  extra?: E;

  /**
   * keyed parameter, usually indicating that this envelope is stored in redux in a dictionary mapping
   * this key to this envelope.
   */
  key?: string;
}

export interface LoadedDataState<T, E = any> extends DataState<T, E> {
  loaded: true; // TODO (jasonkraus): does this mean loading is false?
}
export interface LoadingDataState<T, E = any> extends DataState<T, E> {
  loading: true; // TODO (jasonkraus): does this mean loaded is false?
  // make this non-optional
  promise: Promise<T>;
}

export interface ErrorDataState<T, E = any> extends DataState<T, E> {
  // make this non-optional
  error: any;
}

export interface ExtraParams<E> {
  extra?: E;
  [key: string]: any;
}

/**
 * Used to extract only loaded data, and provide a default value if it hasn't loaded.
 *
 * IMPORTANT: call appropriate "ensure" hook to request data; or else you get loading forever
 *
 * This is most useful in selectors where you want to provide a default value
 * if the object hasn't loaded yet or if it has an error.
 */
// TODO(jasonkraus): make this type safe
// getData<LayerMetadata>(dsLoading, null) => might be null if loaded but described as LayerMetadata
// accepts undefined dataState! and will return defaultState ... and will never resolve to a loaded state
export function getData<T, E = any>(
  dataState: DataState<T, E>,
  defaultState: any = EMPTY_STATE,
): T {
  if (dataState?.loaded) {
    return dataState.data;
  }
  return defaultState;
}

/**
 * A more type safe data retriever that will cause hooks to suspend while data is loading
 * throws an error if an error occurred,
 * throws a promise if the data is loading - triggering a react suspsense if used in a hook
 * @param dataState
 * @param noRequestCallback call this method if no request (promise) is found
 * @returns
 */
export function useDataSuspense<T, E = any>(
  dataState: DataState<T, E>,
  noRequestCallback: () => any = () => new Error('No request on file'),
): T {
  if (hasError(dataState)) {
    throw getError(dataState);
  }
  if (isLoaded(dataState)) {
    return dataState.data;
  }
  // if there is no promise, make a request (only useEnsure provides this)
  // eslint-disable-next-line @typescript-eslint/no-misused-promises
  throw dataState?.promise || dataState?.promiseFactory
    ? dataState.promiseFactory()
    : noRequestCallback();
}

export function isLoading<T = any, E = any>(
  dataState: DataState<T, E>,
  defaultLoading = false,
): dataState is LoadedDataState<T, E> {
  if (!_.isEmpty(dataState)) {
    // TODO: hack: server-rendered pages that do not have their
    // promises resolve before pageload will show up client-side as
    // { loading: true, promise: {} } - work around this by treating this as "not loading"
    // so that `shouldLoad` returns true.
    if (_.isPlainObject(dataState.promise)) {
      console.warn('Server delivered unfulfilled promise: ', dataState);
    }

    return !!dataState.loading && !_.isPlainObject(dataState.promise);
  }
  return defaultLoading;
}

/**
 * Check if a (web worker) task is loading. This is idential to `isLoading` except
 * that semantics are slightly different for tasks because we can know
 * about a task before it starts, so from the user's perspective, it
 * is "loading" even though it hasn't actually "started" in the
 * backend.
 *
 * TODO: unify isLoading/isTaskLoading - the task semantics are
 * probably mostly correct for async http requests too.
 *
 * TODO(jasonkraus) lets document where these tasks come from
 */
export function isTaskLoading(
  dataState: DataState<SwaggerAny>,
  defaultLoading = true,
) {
  return isLoading(dataState, defaultLoading);
}

export function isLoaded<T = any, E = any>(
  dataState: DataState<T, E>,
  defaultLoaded = false,
): dataState is LoadedDataState<T, E> {
  if (dataState) {
    return !!dataState.loaded;
  }
  return defaultLoaded;
}

export function isLoadedOrLoading<T, E = any>(
  dataState: DataState<T, E>,
): dataState is LoadedDataState<T, E> | LoadingDataState<T, E> {
  return isLoaded<T>(dataState) || isLoading<T>(dataState);
}

export function hasError<T, E = any>(
  dataState: DataState<T, E>,
): dataState is ErrorDataState<T, E> {
  return !!dataState?.error;
}

export function getError<E = any>(dataState: DataState<SwaggerAny, E>): E {
  return hasError(dataState) ? dataState.error : null;
}

export function shouldLoad<T = any>(dataState: DataState<T>) {
  if (_.isEmpty(dataState)) {
    return true;
  }

  if (isLoadedOrLoading(dataState)) {
    return false;
  }

  if (hasError(dataState)) {
    // do not retry 400-level errors
    if (dataState.error.status && dataState.error.status < 500) {
      return false;
    }

    // check timestamp and retries
    if (dataState.responseTime && dataState.requestCount) {
      // Express everything as seconds since epoch
      const nowMs: number = new Date().getTime();
      const msToNextRequest = 1000 * 2 ** dataState.requestCount;
      const nextAllowedResponseMs =
        new Date(dataState.responseTime).getTime() + msToNextRequest;

      return nowMs > nextAllowedResponseMs;
    }
    return false;
  }

  // This accounts for odd edge cases like { loading: false, loaded: false }
  return true;
}

export const EmptyState: DataState<any> = {
  loaded: false,
  loading: false,
  requestId: null,
};

export const EmptyKeyedState: DataState<any> = {
  loaded: false,
  loading: false,
  requestId: null,
  key: null,
};

/**
 * Given an array of states, create a synthetic DataState which more or less
 * represents a single loading state for all the datastates. Follows the same
 * semantics as Promise.all():
 *
 * Specifically:
 *   * If *any* of them are loading, then the combined data state is considered
 *     loading
 *   * If *all* of them loaded, then the combined data state is considered
 *     loaded.
 *   * If *any* of them threw an error, then the combined data state is
 *     considered to have an error.
 *
 * Also note that because this is sometimes used in the context of an
 * `ensure`-style function, which can be called frequently, and because we do
 * not know how big `states` will get, we do not create a Promise unless it is
 * specifically requested.
 */
export function combineDataStates(
  states: DataState<ApprovedAny>[],
  createPromise = false,
): DataState<null> {
  if (states.length === 0) {
    // this is effectively what the logic below would produce, but in a
    // cacheable format
    return EmptyState;
  }
  return {
    loading: states.some(state => isLoading(state)),
    loaded: states.every(state => isLoaded(state)),
    error: getError(states.find(state => hasError(state))) || null,
    data: null,
    requestId: null,
    promise: createPromise
      ? Promise.all(states.map(state => state.promise)).then(() => null)
      : null,
    // TODO: figure out if we should attach synthetic request/response times, requestCount, etc
  };
}
