import { SET_ACTIVE_VIEW, SetActiveViewAction } from 'uf/explore/ActionTypes';
import { registerPersistedAction } from 'uf/persistence';
import { ProjectId } from 'uf/projects';
import { ViewId } from 'uf/views';

export function setActiveView(
  projectId: ProjectId,
  viewId: ViewId,
): SetActiveViewAction {
  return {
    type: SET_ACTIVE_VIEW,
    projectId,
    value: viewId,
  };
}

registerPersistedAction<SetActiveViewAction, [ProjectId], ViewId>(
  'activeView',
  SET_ACTIVE_VIEW,
  ([projectId], viewId) => setActiveView(projectId, viewId),
  {
    getKey({ projectId }) {
      return [projectId];
    },

    getValue({ value: viewId }) {
      return viewId;
    },
  },
);
