/**
 * UrbanFootprint App API
 * API for serving UrbanFootprint Application
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { SplitCanvasPainterReplacement } from './splitCanvasPainterReplacement';

/**
 * Represents an individual row to split, along with replacements.      There must be one of these for every row that the filter to the painter matches.
 */
export interface SplitCanvasPainterSplit<> {
  /**
   * The ID of the row to split - this row will be deleted. NOTE: this must come from the field actually named \"id\".
   */
  id?: string;
  /**
   * What to replace the split item with.
   */
  replace_with?: Array<SplitCanvasPainterReplacement>;
  /**
   * What kind of split to apply.
   */
  split_mode?: SplitCanvasPainterSplit.SplitModeEnum;
}
export namespace SplitCanvasPainterSplit {
  export type SplitModeEnum = 'area_weighted' | 'empty';
  export const SplitModeEnum = {
    AreaWeighted: 'area_weighted',
    Empty: 'empty',
  } as const;
}
