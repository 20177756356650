import _ from 'lodash';
import { FillExtrusionPaint } from 'mapbox-gl';

import { ColumnKey } from 'uf/layers';
import {
  getBinnedFillExtrusionHeightProperty,
  getContinuousFillExtrusionHeightProperty,
  parseNumericPaintColor,
  parsePaintOpacity,
  parsePaintRadius,
  parsePaintStrokeColor,
  parsePaintWidth,
} from 'uf/map/stylelayers/stylelayers';
import {
  ExtrusionBreaksMethod,
  LayerColumnSymbology,
  PaintColorProperty,
} from 'uf/symbology';
import { DivideByColumnKey } from 'uf/symbology/divideByColumn';
import {
  EXTRUSION_STYLE_DEFAULTS,
  isExtrusionSymbology,
} from 'uf/symbology/helpers';

interface ParsePaintOptions {
  nullsOnly: boolean;
}

const DEFAULT_OPTIONS: ParsePaintOptions = {
  nullsOnly: false,
};

export function parseNumericPaintProperty(
  columnKey: ColumnKey,
  symbology: LayerColumnSymbology,
  min: number,
  max: number,
  divideByColumn: DivideByColumnKey,
  { nullsOnly } = DEFAULT_OPTIONS,
): mapboxgl.Layer['paint'] {
  const paintProperty: mapboxgl.Layer['paint'] = _.mapValues(
    symbology.paint,
    (value: any, key: string) => {
      switch (key) {
        // `fill-color`, `line-color`, `circle-color`
        case `${symbology.type}-color`:
          return parseNumericPaintColor(
            symbology,
            value,
            columnKey,
            min,
            max,
            divideByColumn,
            {
              nullsOnly,
            },
          );

        case `${symbology.type}-width`:
          return parsePaintWidth(symbology, value);

        case `${symbology.type}-opacity`:
          return parsePaintOpacity(symbology, value);

        case `${symbology.type}-stroke-color`:
          return parsePaintStrokeColor(symbology, value);

        // this is generally only `circle-radius`, but some day might be
        // `heatmap-radius`
        case `${symbology.type}-radius`:
          return parsePaintRadius(symbology, value, divideByColumn);

        default:
          return value;
      }
    },
  );

  // fill-extrusion is special, as it's captured in the display_hints and
  // not in the symbology's paint property.
  if (isExtrusionSymbology(symbology)) {
    return convertToFillExtrusionPaintProperty(
      columnKey,
      paintProperty,
      symbology,
      max,
      divideByColumn,
    );
  }

  return paintProperty;
}

export function convertToFillExtrusionPaintProperty(
  columnKey: ColumnKey,
  styleLayerPaintProperty: mapboxgl.Layer['paint'],
  fillSymbology: LayerColumnSymbology,
  max: number,
  divideByColumn: DivideByColumnKey,
): mapboxgl.Layer['paint'] {
  const {
    display_hints: {
      extrusion_options: {
        breaksMethod,
        scaleFactor,
        invertLevels,
      } = EXTRUSION_STYLE_DEFAULTS,
    },
  } = fillSymbology;

  const fillExtrusionPaintProperty: FillExtrusionPaint = _.mapKeys(
    styleLayerPaintProperty,
    (unusedValue, key) => key.replace('fill', 'fill-extrusion'),
  );

  if (breaksMethod === ExtrusionBreaksMethod.Binned) {
    // Binned extrusions take their bin values from the color property
    const fillColor: PaintColorProperty = fillSymbology.paint['fill-color'];
    const binnedHeight = getBinnedFillExtrusionHeightProperty(
      fillColor,
      scaleFactor,
    );
    fillExtrusionPaintProperty['fill-extrusion-height'] = binnedHeight;
  } else if (breaksMethod === ExtrusionBreaksMethod.Continuous) {
    const continuousHeight = getContinuousFillExtrusionHeightProperty(
      max,
      scaleFactor,
      invertLevels,
      columnKey,
      divideByColumn,
    );
    fillExtrusionPaintProperty['fill-extrusion-height'] = continuousHeight;
  }

  return fillExtrusionPaintProperty;
}
