import {
  CuratedColumnSymbology,
  CUSTOM_THEME,
  DEFAULT_OPACITY,
  InterpolationTypes,
  PaintColorTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/public/dataset/morpc_recommended_land_use': {
    use_class: [
      {
        type: SymbologyTypes.FILL,
        paint: {
          'fill-color': {
            property: 'use_class',
            type: PaintColorTypes.CATEGORICAL,
            stops: [
              { value: 'Commercial', label: 'Commercial', color: '#AF3323' },
              {
                value: 'Please refer to plan document',
                label: 'Please refer to plan documents',
                color: '#514E4B',
              },
              {
                value: 'Institutional',
                label: 'Institutional',
                color: '#466F7A',
              },
              { value: 'Industrial', label: 'Industrial', color: '#7E6B99' },
              { value: 'Mixed Use', label: 'Mixed Use', color: '#D6B296' },
              {
                value: 'Employment Center',
                label: 'Employment Center',
                color: '#6B5B72',
              },
              { value: 'Agriculture', label: 'Agriculture', color: '#454F2D' },
              { value: 'Office', label: 'Office', color: '#AO88AA' },
              { value: 'Residential', label: 'Residential', color: '#D6C26B' },
              { value: 'Other', label: 'Other', color: '#D6C57A' },
              {
                value: 'Parks/Open Space',
                label: 'Parks/Open Space',
                color: '#65743A',
              },
            ],
          },
          'fill-opacity': DEFAULT_OPACITY,
        },
        display_hints: {
          scale: SymbologyScales.CATEGORICAL,
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          theme: CUSTOM_THEME,
        },
      },
    ],
  },
};

export default symbologies;
