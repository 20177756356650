import { createSelector, ParametricSelector, Selector } from 'reselect';
import { LayerMetadata, LayerStats } from 'uf-api';
import { makeGetFromPropsSelector, makeGetterSelector } from 'uf/base/selector';
import {
  DataState,
  EMPTY_STATE,
  getData,
  isLoaded,
  isLoading,
} from 'uf/data/dataState';
import { ColumnKey, LayerId } from 'uf/layers';
import { getUfGeometryType } from 'uf/layers/helpers';
import { makeGetLayerMetadataStateGetter } from 'uf/layers/selectors/metadata';
import { ProjectId } from 'uf/projects';
import { LegacyVirtualLayerId } from 'uf/projects/virtualLayers';
import { UFState } from 'uf/state';
import { LayerColumnSymbology } from 'uf/symbology';
import { BuiltFormStop } from 'uf/symbology/builtforms';
import { addMissingSymbologies, cleanSymbology } from 'uf/symbology/clean';
import { DivideByColumnKey } from 'uf/symbology/divideByColumn';
import { makeGetBaseOrUserSymbologyState } from 'uf/symbology/selectors/combinedSymbologies';
import { makeGetDivideByColumnGetter } from 'uf/symbology/selectors/divideByColumn';
import { ViewId } from 'uf/views';
import {
  makeGetViewOverrideSymbologyState,
  makeGetViewOverrideSymbologyStateGetter,
} from 'uf/views/selectors/symbology';
import { makeGetBuiltFormsStopsGetter } from './builtforms';
import { makeGetDefaultSymbologyStateGetter } from './defaultSymbology';
import { makeGetSymbologyStatsStateGetter } from './stats';
import { makeGetUserDataSymbologyStateGetter } from './user';

export function makeGetSymbologyState() {
  // until we upgrade reselect, we cast to any
  return (createSelector as any)(
    makeGetBaseOrUserSymbologyState(),
    makeGetViewOverrideSymbologyState(),
    makeGetFromPropsSelector<ProjectId, 'projectId'>('projectId'),
    makeGetFromPropsSelector<LegacyVirtualLayerId, 'virtualLayerId'>(
      'virtualLayerId',
    ),
    makeGetFromPropsSelector<LayerId, 'layerId'>('layerId'),
    makeGetFromPropsSelector<ColumnKey, 'columnKey'>('columnKey'),
    makeGetFromPropsSelector<ViewId, 'viewId'>('viewId'),
    makeGetDivideByColumnGetter(),
    makeGetDefaultSymbologyStateGetter(),
    makeGetLayerMetadataStateGetter(),
    makeGetSymbologyStatsStateGetter(),
    makeGetBuiltFormsStopsGetter(),
    makeGetSymbologyOriginatorGetter(),
    (
      baseOrUserSymbologyState,
      overrideSymbology,
      projectId,
      virtualLayerId,
      layerId,
      columnKey,
      viewId,
      getDivideByColumn,
      getDefaultSymbologyState,
      getLayerMetadataState,
      getLayerStatsState,
      getBuiltFormStops,
      getOriginator,
    ): DataState<LayerColumnSymbology[]> => {
      return getSymbologyStateInternal(
        baseOrUserSymbologyState,
        overrideSymbology,
        columnKey,
        getDivideByColumn(projectId, layerId, columnKey),
        getData(getDefaultSymbologyState(projectId, layerId, columnKey), []),
        getData(getLayerMetadataState(layerId)),
        getData(getLayerStatsState(projectId, layerId, columnKey)),
        getBuiltFormStops(projectId, layerId),
        getOriginator(projectId, viewId, layerId, virtualLayerId, columnKey),
      );
    },
  ) as ParametricSelector<
    UFState,
    GetSymbologyProps,
    DataState<LayerColumnSymbology[]>
  >;
}

/* final source of truth for the UI */
export function makeGetSymbologyStateGetter() {
  return makeGetterSelector(makeGetSymbologyState());
}

export interface GetSymbologyProps {
  projectId: ProjectId;
  viewId: ViewId;
  layerId: LayerId;
  virtualLayerId: LegacyVirtualLayerId | undefined;
  columnKey: ColumnKey;
}

export type GetSymbologyState = (
  props: GetSymbologyProps,
) => DataState<LayerColumnSymbology[]>;

/**
 * Performs necessary sanitization and merge symbology days if needed
 * @param baseOrUserSymbologyState
 * @param overrideSymbology
 * @param columnKey
 * @param divideByColumn
 * @param baseSymbology
 * @param layerMetadata
 * @param layerStats
 * @param builtFormStops
 * @returns
 */
function getSymbologyStateInternal(
  baseOrUserSymbologyState: DataState<LayerColumnSymbology[]>,
  overrideSymbology: LayerColumnSymbology[],
  columnKey: ColumnKey,
  divideByColumn: DivideByColumnKey,
  baseSymbology: LayerColumnSymbology[],
  layerMetadata: LayerMetadata,
  layerStats: LayerStats,
  builtFormStops: BuiltFormStop[],
  originator: string,
): DataState<LayerColumnSymbology[]> {
  let userSymbology = getData(baseOrUserSymbologyState, baseSymbology);
  let loaded = isLoaded(baseOrUserSymbologyState);
  let loading = isLoading(baseOrUserSymbologyState);
  const hasMetadata =
    layerMetadata !== EMPTY_STATE && layerStats !== EMPTY_STATE;

  // symbologies from particular originators need to have missing symbologies added
  if (originator === 'dynamic_layer' || originator === 'analysis') {
    const ufGeometryType = getUfGeometryType(layerMetadata);
    userSymbology = addMissingSymbologies(
      layerMetadata.full_path,
      columnKey,
      userSymbology,
      ufGeometryType,
    );
  }
  if (overrideSymbology.length) {
    loaded = true;
    loading = false;
    // perform a clean operation on any set overrides so that the UX is consistent
    userSymbology = !hasMetadata
      ? overrideSymbology
      : cleanSymbology(
          columnKey,
          divideByColumn,
          overrideSymbology,
          userSymbology,
          layerMetadata,
          layerStats,
          builtFormStops,
        );
  }
  const overrideSymbologyState: DataState<LayerColumnSymbology[]> = {
    loaded,
    loading,
    error: baseOrUserSymbologyState.error,
    requestId: baseOrUserSymbologyState.requestId,
    data: userSymbology,
  };
  return overrideSymbologyState;
}

export function makeGetSymbologyOriginatorGetter(): Selector<
  UFState,
  (
    projectId: ProjectId,
    viewId: ViewId,
    layerId: LayerId,
    virtualLayerId: LegacyVirtualLayerId | undefined,
    columnKey: ColumnKey,
  ) => string
> {
  return createSelector(
    makeGetDivideByColumnGetter(),
    makeGetUserDataSymbologyStateGetter(),
    makeGetViewOverrideSymbologyStateGetter(),
    (getDivideByColumn, getUserSymbologyDataState, getOverrideSymbology) =>
      (
        projectId: ProjectId,
        viewId: ViewId,
        layerId: LayerId,
        virtualLayerId: LegacyVirtualLayerId | undefined,
        columnKey: ColumnKey,
      ): string => {
        const divideByColumn = getDivideByColumn(projectId, layerId, columnKey);
        const userSymbologyDataState = getUserSymbologyDataState({
          viewId,
          projectId,
          layerId,
          columnKey,
        });
        const overrideSymbology = getOverrideSymbology(
          viewId,
          virtualLayerId,
          columnKey,
          divideByColumn,
        );

        if (overrideSymbology.length) {
          return 'override';
        }
        if (getData(userSymbologyDataState)) {
          // forward originator from the API's LayerSymbologyEnvelope
          return getData(userSymbologyDataState).originator;
        }

        return 'default';
      },
  );
}
