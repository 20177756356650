import {
  AnalysisModuleResults,
  LayerReference,
  ScenarioMetadata,
} from 'uf-api';

export function getScenarioAnalysisLayerReferences(
  scenario: ScenarioMetadata,
): LayerReference[] {
  const analysisLayers: LayerReference[] = [];
  if (!scenario) {
    return analysisLayers;
  }

  const { analysis_results: analysisResults = [] } = scenario;
  analysisResults.forEach(analysisResult => {
    const { policies = [] } = analysisResult;
    policies.forEach(policy => {
      const { results: policyResults = {} as AnalysisModuleResults } = policy;
      const { output_layers: outputLayers = [] } = policyResults;

      outputLayers.forEach(outputLayer => {
        analysisLayers.push(outputLayer.layer);
      });
    });
  });
  return analysisLayers;
}
