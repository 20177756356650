import colors from 'material-colors';
import { t } from 'ttag';

import { ACRES, getUnitName } from 'uf/base/units';
import { LegacyReport } from 'uf/reporting/index';

const resiliencySource = {
  source: 'analysis',
  sourceKey: 'resiliency',
};

const fireRisk = Object.freeze({
  key: 'fire_hazard',
  zones: ['moderate', 'high', 'very_high'],
});

const floodRisk = Object.freeze({
  key: 'sfhl',
  zones: [
    'ae',
    'ar_ae',
    'a1_a30',
    'ar_a1_a30',
    'a',
    'ah',
    'ar_ah',
    'ao',
    'ar_ao',
    'a99',
    'ar_a',
    'v1_v30',
    'v',
    've',
    'ar',
  ],
});

const seaRiseRisk = Object.freeze({
  key: 'slr',
  zones: [
    '0',
    '0_8',
    '1',
    '1_6',
    '2',
    '2_5',
    '3',
    '3_3',
    '4',
    '4_1',
    '4_9',
    '5',
    '5_7',
    '6',
    '6_6',
    '7',
    '8',
    '9',
    '10',
    '16_4',
  ],
});

const allRisks = [fireRisk, floodRisk, seaRiseRisk];

function makeSummaryCharts(stat) {
  return allRisks.map(risk => ({
    ...resiliencySource,
    key: `resiliency_summarized_stats_${risk.key}_${stat}`,
    layerKey: `resiliency_summarized_stats_${stat}`,
    columnKey: `${risk.key}_${stat}`,
  }));
}

function makeRiskCharts(risk, stat) {
  return risk.zones.map(zone => ({
    ...resiliencySource,
    key: `resiliency_summarized_stats_${risk.key}_${zone}_${stat}`,
    layerKey: `resiliency_${risk.key}_stats_${stat}`,
    columnKey: `${risk.key}_${zone}_${stat}`,
  }));
}

function makeRiskReportingChartsSLR(stat) {
  return seaRiseRisk.zones.map(zone => ({
    ...resiliencySource,
    key: `resiliency_summarized_stats_slr_${zone}_${stat}`,
    layerKey: 'resiliency_slr_stats_all',
    columnKey: `slr_${zone}_${stat}`,
  }));
}

const resiliencyAnalysisChartsSLR = [
  {
    name: t`Housing in Sea Level Rise Hazard Zones`,
    subtitle: t`Number of dwelling units`,
    description: t`Dwelling Units affected by each sea level rise height scenario (ft)`,
    key: 'resiliency_summarized_stats_slr_du',
    series: makeRiskCharts(seaRiseRisk, 'du'),
  },
  {
    name: t`Population in Sea Level Rise Hazard Zones`,
    subtitle: t`Number of residents`,
    description: t`Population affected by each sea level rise height scenario (ft)`,
    key: 'resiliency_summarized_stats_slr_pop',
    series: makeRiskCharts(seaRiseRisk, 'pop'),
  },
  {
    name: t`Jobs in Sea Level Rise Hazard Zones`,
    subtitle: t`Number of employees`,
    description: t`Jobs affected by each sea level rise height scenario (ft)`,
    key: 'resiliency_summarized_stats_slr_emp',
    series: makeRiskCharts(seaRiseRisk, 'emp'),
  },
  {
    name: t`Land Area in Sea Level Rise Hazard Zones`,
    description: t`Area of canvas features affected by each sea level rise height scenario (ft)`,
    key: 'resiliency_summarized_stats_slr_area_gross',
    series: makeRiskCharts(seaRiseRisk, 'area_gross'),
    units: getUnitName(ACRES),
  },
  {
    name: t`Canvas Features in Sea Level Rise Hazard Zones`,
    subtitle: t`Number of geometries (e.g. parcels)`,
    description: t`Number of canvas features affected by each sea level rise height scenario (ft)`,
    key: 'resiliency_summarized_stats_slr_count',
    series: makeRiskCharts(seaRiseRisk, 'count'),
  },
];

const resiliencyReportingChartsSLR = [
  {
    name: t`Housing in Sea Level Rise Hazard Zones`,
    description: t`Dwelling Units affected by each sea level rise height scenario (ft)`,
    subtitle: t`Number of dwelling units`,
    key: 'resiliency_summarized_stats_slr_du',
    series: makeRiskReportingChartsSLR('du'),
  },
  {
    name: t`Population in Sea Level Rise Hazard Zones`,
    description: t`Population affected by each sea level rise height scenario (ft)`,
    subtitle: t`Number of residents`,
    key: 'resiliency_summarized_stats_slr_pop',
    series: makeRiskReportingChartsSLR('pop'),
  },
  {
    name: t`Jobs in Sea Level Rise Hazard Zones`,
    description: t`Jobs affected by each sea level rise height scenario (ft)`,
    subtitle: t`Number of employees`,
    key: 'resiliency_summarized_stats_slr_emp',
    series: makeRiskReportingChartsSLR('emp'),
  },
  {
    name: t`Land Area in Sea Level Rise Hazard Zones`,
    description: t`Area of canvas features affected by each sea level rise height scenario (ft)`,
    key: 'resiliency_summarized_stats_slr_area_gross',
    series: makeRiskReportingChartsSLR('area_gross'),
    units: getUnitName(ACRES),
  },
  {
    name: t`Canvas Features in Sea Level Rise Hazard Zones`,
    description: t`Number of canvas features affected by each sea level rise height scenario (ft)`,
    subtitle: t`Number of geometries (e.g. parcels)`,
    key: 'resiliency_summarized_stats_slr_count',
    series: makeRiskReportingChartsSLR('count'),
  },
];

const resiliencyChartsShared = [
  {
    name: t`Housing in Hazard Zones`,
    description: t`Dwelling units in risk areas by each natural hazard`,
    subtitle: t`Number of dwelling units`,
    key: 'resiliency_summarized_stats_du',
    series: makeSummaryCharts('du'),
  },
  {
    name: t`Population in Hazard Zones`,
    description: t`Population in risk areas by each natural hazard`,
    subtitle: t`Number of residents`,
    key: 'resiliency_summarized_stats_pop',
    series: makeSummaryCharts('pop'),
  },
  {
    name: t`Jobs in Hazard Zones`,
    description: t`Jobs in risk areas by each natural hazard`,
    subtitle: t`Number of employees`,
    key: 'resiliency_summarized_stats_emp',
    series: makeSummaryCharts('emp'),
  },
  {
    name: t`Land Area in Hazard Zones`,
    description: t`Area of canvas features in natural hazard risk areas by each natural hazard`,
    key: 'resiliency_summarized_stats_area_gross',
    series: makeSummaryCharts('area_gross'),
    units: getUnitName(ACRES),
  },
  {
    name: t`Canvas Features in Hazard Zones`,
    description: t`Number of canvas features in risk areas by each natural hazard`,
    subtitle: t`Number of geometries (e.g. parcels)`,
    key: 'resiliency_summarized_stats_count',
    series: makeSummaryCharts('count'),
  },
  {
    name: t`Housing in Flood Hazard Zones`,
    subtitle: t`Number of dwelling units`,
    description: t`Dwelling Units in flood risk areas by special flood hazard zone`,
    key: 'resiliency_summarized_stats_sfhl_du',
    series: makeRiskCharts(floodRisk, 'du'),
  },
  {
    name: t`Population in Flood Hazard Zones`,
    subtitle: t`Number of residents`,
    description: t`Population in flood risk areas by special flood hazard zone`,
    key: 'resiliency_summarized_stats_sfhl_pop',
    series: makeRiskCharts(floodRisk, 'pop'),
  },
  {
    name: t`Jobs in Flood Hazard Zones`,
    subtitle: t`Number of employees`,
    description: t`Jobs in flood risk areas by special flood hazard zone`,
    key: 'resiliency_summarized_stats_sfhl_emp',
    series: makeRiskCharts(floodRisk, 'emp'),
  },
  {
    name: t`Land Area in Flood Hazard Zones`,
    description: t`Area of canvas features in flood risk areas by special flood hazard zone`,
    key: 'resiliency_summarized_stats_sfhl_area_gross',
    series: makeRiskCharts(floodRisk, 'area_gross'),
    units: getUnitName(ACRES),
  },
  {
    name: t`Canvas Features in Flood Hazard Zones`,
    description: t`Number of canvas features in flood risk areas by special flood hazard zone`,
    subtitle: t`Number of geometries (e.g. parcels)`,
    key: 'resiliency_summarized_stats_sfhl_count',
    series: makeRiskCharts(floodRisk, 'count'),
  },
  {
    name: t`Housing in Fire Hazard Zones`,
    subtitle: t`Number of dwelling units`,
    description: t`Dwelling Units in fire risk areas by severity`,
    key: 'resiliency_summarized_stats_fire_hazard_du',
    series: makeRiskCharts(fireRisk, 'du'),
  },
  {
    name: t`Population in Fire Hazard Zones`,
    description: t`Population in fire risk areas by severity`,
    subtitle: t`Number of residents`,
    key: 'resiliency_summarized_stats_fire_hazard_pop',
    series: makeRiskCharts(fireRisk, 'pop'),
  },
  {
    name: t`Jobs in Fire Hazard Zones`,
    description: t`Jobs in fire risk areas by severity`,
    subtitle: t`Number of employees`,
    key: 'resiliency_summarized_stats_fire_hazard_emp',
    series: makeRiskCharts(fireRisk, 'emp'),
  },
  {
    name: t`Land Area in Fire Hazard Zones`,
    description: t`Area of canvas features in fire risk areas by severity`,
    key: 'resiliency_summarized_stats_fire_hazard_area_gross',
    series: makeRiskCharts(fireRisk, 'area_gross'),
    units: getUnitName(ACRES),
  },
  {
    name: t`Canvas Features in Fire Hazard Zones`,
    description: t`Number of canvas features in fire risk areas by severity`,
    subtitle: t`Number of geometries (e.g. parcels)`,
    key: 'resiliency_summarized_stats_fire_hazard_count',
    series: makeRiskCharts(fireRisk, 'count'),
  },
];

export const resiliencyAnalysisCharts: LegacyReport = Object.freeze({
  key: 'resiliency',
  name: 'Risk and Resilience',
  charts: [...resiliencyChartsShared, ...resiliencyAnalysisChartsSLR],
});

export const resiliencyCharts: LegacyReport = Object.freeze({
  key: 'resiliency',
  name: 'Risk and Resilience',
  colorSpec: {
    primaryColor: 'rgb(255, 153, 0)',
    interpolateFromColor: colors.yellow[300],
    interpolateToColor: colors.deepOrange[900],
  },
  charts: [...resiliencyChartsShared, ...resiliencyReportingChartsSLR],
});
