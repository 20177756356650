import {
  CuratedColumnSymbology,
  CUSTOM_THEME,
  DEFAULT_OPACITY,
  InterpolationTypes,
  PaintColorTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/public/dataset/protected_areas_database_easements': {
    mang_type: [
      {
        type: SymbologyTypes.FILL,
        paint: {
          'fill-color': {
            property: 'mang_type',
            type: PaintColorTypes.CATEGORICAL,
            stops: [
              { value: 'FED', label: 'Federal', color: '#359935' },
              { value: 'STAT', label: 'State', color: '#9eb514' },
              { value: 'LOC', label: 'Local Government', color: '#2e5597' },
              {
                value: 'TRIB',
                label: 'American Indian Lands',
                color: '#c19d6d',
              },
              { value: 'DIST', label: 'Designation', color: '#8f7377' },
              {
                value: 'NGO',
                label: 'Non-Governmental Organization',
                color: '#e66914',
              },
              { value: 'PVT', label: 'Private', color: '#843d0b' },
              { value: 'JNT', label: 'Joint', color: '#e3b0af' },
              { value: 'TERR', label: 'Territorial', color: '#beb49c' },
              { value: 'UNK', label: 'Unknown', color: '#a6a6a6' },
            ],
          },
          'fill-opacity': DEFAULT_OPACITY,
        },
        display_hints: {
          scale: SymbologyScales.CATEGORICAL,
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          theme: CUSTOM_THEME,
        },
      },
      {
        type: SymbologyTypes.LINE,
        paint: {
          'line-color': {
            property: 'mang_type',
            type: PaintColorTypes.CATEGORICAL,
            stops: [
              { value: 'FED', label: 'Federal', color: '#359935' },
              { value: 'STAT', label: 'State', color: '#9eb514' },
              { value: 'LOC', label: 'Local Government', color: '#2e5597' },
              {
                value: 'TRIB',
                label: 'American Indian Lands',
                color: '#c19d6d',
              },
              { value: 'DIST', label: 'Designation', color: '#8f7377' },
              {
                value: 'NGO',
                label: 'Non-Governmental Organization',
                color: '#e66914',
              },
              { value: 'PVT', label: 'Private', color: '#843d0b' },
              { value: 'JNT', label: 'Joint', color: '#e3b0af' },
              { value: 'TERR', label: 'Territorial', color: '#beb49c' },
              { value: 'UNK', label: 'Unknown', color: '#a6a6a6' },
            ],
          },
          'line-opacity': DEFAULT_OPACITY,
        },
        display_hints: {
          scale: SymbologyScales.CATEGORICAL,
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          theme: CUSTOM_THEME,
        },
      },
    ],
  },
};

export default symbologies;
