import { getLayerBreaksActionTypes } from 'uf-api/api/layer.service';
import { LayerServiceInterface } from 'uf-api/api/layer.serviceInterface';
import { LayerColumnBreaks } from 'uf-api/model/models';
import { dispatchAsyncAction, makeEnsureActionCreator } from 'uf/data/loader';
import { ColumnKey, LayerId, LayerVersion } from 'uf/layers';
import { fetchLayerBreaks } from 'uf/layers/apis';
import { makeBreaksKey } from 'uf/layers/breaks';
import { makeGetLayerBreaksByKey } from 'uf/layers/selectors/breaks';
import { DivideByColumnKey } from 'uf/symbology/divideByColumn';

export const ensureLayerBreaks = makeEnsureActionCreator(
  loadLayerBreaks,
  makeGetLayerBreaksByKey(),
  makeBreaksKey,
);

/**
 * Unconditionally load the layer breaks for the given layerId and query. This
 * is used to force the refresh of breaks, in case data has changed on the server.
 * Most of the time, use `ensureLayerBreaks` instead.
 */
export function loadLayerBreaks(
  layerId: LayerId,
  layerVersion: LayerVersion,
  columnKey: ColumnKey,
  breakCount: number,
  breakType: LayerColumnBreaks.BreakTypeEnum,
  divideByColumn?: DivideByColumnKey,
) {
  const key = makeBreaksKey(
    layerId,
    layerVersion,
    columnKey,
    breakCount,
    breakType,
    divideByColumn,
  );

  const breaksParams: Omit<
    LayerServiceInterface.getLayerBreaksParams,
    'filters' | 'namespace' | 'layer_type' | 'key'
  > = {
    columns: [columnKey],
    break_count: breakCount,
    break_type: breakType,
    version: layerVersion,
    normalize_by: divideByColumn || undefined,
  };

  return dispatchAsyncAction(
    getLayerBreaksActionTypes,
    fetchLayerBreaks(layerId, breaksParams),
    { key, params: breaksParams, layerId, columnKey },
  );
}
