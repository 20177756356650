import {
  acceptUserInvitationActionTypes,
  getInvitationsForUserActionTypes,
  rejectUserInvitationActionTypes,
} from 'uf-api/api/user.service';
import {
  dispatchAsyncAction,
  dispatchAsyncActionToRequestId,
} from 'uf/data/loader';

import {
  acceptUserInvitation,
  getInvitationsForUser,
  rejectUserInvitation,
} from './apis';

export function loadInvitationsForUser() {
  return dispatchAsyncAction(
    getInvitationsForUserActionTypes,
    getInvitationsForUser(null),
    { key: 'default' },
  );
}

export function acceptInvitation(token: string) {
  return dispatchAsyncActionToRequestId(
    acceptUserInvitationActionTypes,
    acceptUserInvitation({ token }),
  );
}

export function rejectInvitation(token: string) {
  return dispatchAsyncActionToRequestId(
    rejectUserInvitationActionTypes,
    rejectUserInvitation({ token }),
  );
}
