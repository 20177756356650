import colors from 'material-colors';

import {
  CuratedColumnSymbology,
  CUSTOM_THEME,
  DataDrivenRadiusStop,
  DEFAULT_OPACITY,
  DistributionTypes,
  InterpolationTypes,
  PaintColorTypes,
  SymbologyScales,
  SymbologyTypes,
  SymbolPlacement,
  Symbols,
} from 'uf/symbology';

export function makeHospitalBedSymbology(
  propertyKey: string,
  max: number,
): CuratedColumnSymbology[] {
  const stops: DataDrivenRadiusStop[] = [
    { value: -1, radius: 5 },
    { value: 0, radius: 5 },
    { value: max / 10, radius: 15 },
    { value: max, radius: 30 },
  ];
  return [
    {
      type: SymbologyTypes.CIRCLE,
      paint: {
        'circle-opacity': DEFAULT_OPACITY,
        'circle-stroke-color': colors.blue[400],
        'circle-stroke-width': 2,
        'circle-color': colors.blue[200],
        'circle-radius': {
          property: propertyKey,
          type: PaintColorTypes.INTERVAL,
          stops,
        },
      },
      display_hints: {
        scale: SymbologyScales.NUMERIC,
        distribution: DistributionTypes.CUSTOM,
        interpolation: InterpolationTypes.INTERVAL,
        theme: CUSTOM_THEME,
      },
    },
  ];
}
const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/public/dataset/us_hospitals': {
    beds: makeHospitalBedSymbology('beds', 1000),
    type: [
      {
        type: SymbologyTypes.CIRCLE,
        paint: {
          'circle-radius': 5,
          'circle-color': {
            property: 'type',
            type: PaintColorTypes.CATEGORICAL,
            stops: [
              { value: 'CHILDREN', label: 'Children', color: colors.red[200] },
              {
                value: 'CHRONIC DISEASE HOSPITAL',
                label: 'Chronic Disease Hospital',
                color: colors.purple[200],
              },
              {
                value: 'CRITICAL ACCESS',
                label: 'Critical Access',
                color: colors.indigo[200],
              },
              {
                value: 'GENERAL ACUTE CARE',
                label: 'General Acute Care',
                color: colors.lightBlue[200],
              },
              {
                value: 'LONG TERM CARE',
                label: 'Long Term Care',
                color: colors.teal[200],
              },
              {
                value: 'MILITARY',
                label: 'Military',
                color: colors.lightGreen[200],
              },
              {
                value: 'PSYCHIATRIC',
                label: 'Psychiatric',
                color: colors.yellow[200],
              },
              {
                value: 'REHABILITATION',
                label: 'Rehabilitation',
                color: colors.orange[200],
              },
              {
                value: 'SPECIAL',
                label: 'Special',
                color: colors.deepOrange[200],
              },
              { value: 'WOMEN', label: 'Women', color: colors.pink[200] },
            ],
          },
          'circle-opacity': DEFAULT_OPACITY,
        },
        display_hints: {
          scale: SymbologyScales.CATEGORICAL,
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          theme: CUSTOM_THEME,
        },
      },
      {
        type: SymbologyTypes.SYMBOL,
        paint: {
          'icon-opacity': 0.7,
        },
        layout: {
          symbol: Symbols.HOSPITAL_BUILDING,
          placement: SymbolPlacement.POINT,
        },
        display_hints: {
          theme: 'custom',
          distribution: null,
          scale: null,
          interpolation: null,
        },
      },
    ],
  },
};

export default symbologies;
