import { createEntityAdapter } from '@reduxjs/toolkit';
import { Api, GetLayerDataApiResponse } from './Api';
import { Brand } from 'uf/layers/layerData';

/**
 * This file allows for the customization of endpoints, which we don't
 * have control over (changes would be overwritten) in our code-generated API.
 * Examples of use-cases:
 *  - [Manual cache updates](https://redux-toolkit.js.org/rtk-query/usage/manual-cache-updates)
 *  - [Automated re-fetching](https://redux-toolkit.js.org/rtk-query/usage/automated-refetching#recipes)
 *  - [Customizing queries](https://redux-toolkit.js.org/rtk-query/usage/customizing-queries#implementing-a-queryfn)
 */

const columnsAdapter = createEntityAdapter({
  selectId: (entity: { key: string }) => entity.key,
});
const initialColumnsState = columnsAdapter.getInitialState();

const layerDataAdapter = createEntityAdapter({
  selectId: (entity: { content_hash: string }) => entity.content_hash,
});
const initialLayerDataState = layerDataAdapter.getInitialState();

const enhancedApi = Api.enhanceEndpoints({
  endpoints: {
    updateGlobalSetting(endpoint) {
      // eager update our settings
      endpoint.onQueryStarted = async (
        { userGlobalSetting },
        { dispatch, queryFulfilled },
      ) => {
        const patchResult = dispatch(
          Api.util.updateQueryData('getUserSettings', null, draft => {
            Object.assign(draft, userGlobalSetting);
          }),
        );
        try {
          // response may include the most up-to-date state
          const { data: nextUserGlobalSetting } = await queryFulfilled;
          if (nextUserGlobalSetting) {
            dispatch(
              Api.util.updateQueryData('getUserSettings', null, draft => {
                Object.assign(draft, nextUserGlobalSetting);
              }),
            );
          }
        } catch {
          patchResult.undo();
        }
      };
    },
    updateOrgSetting(endpoint) {
      // eager update our settings
      endpoint.onQueryStarted = async (
        { userOrgSetting },
        { dispatch, queryFulfilled },
      ) => {
        const patchResult = dispatch(
          Api.util.updateQueryData('getUserSettings', null, draft => {
            Object.assign(draft, userOrgSetting);
          }),
        );
        try {
          // response may include the most up-to-date state
          const { data: nextUserOrgSetting } = await queryFulfilled;
          if (nextUserOrgSetting) {
            dispatch(
              Api.util.updateQueryData('getUserSettings', null, draft => {
                Object.assign(draft, nextUserOrgSetting);
              }),
            );
          }
        } catch {
          patchResult.undo();
        }
      };
    },
    setUserData(endpoint) {
      endpoint.onQueryStarted = async (
        { key, userKey, body },
        { dispatch, queryFulfilled },
      ) => {
        const patchResult = dispatch(
          Api.util.updateQueryData('getUserData', { userKey, key }, draft => {
            Object.assign(draft, { value: body });
          }),
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      };
    },
    getUserData(endpoint) {
      endpoint.transformResponse = async (response, meta, args) => {
        if (args.key.includes('columnState')) {
          // @ts-ignore response is unknown by design
          const value = response?.value ?? {};
          return {
            ...value,
            ...columnsAdapter.setAll(initialColumnsState, value?.columns),
          };
        }
        return response;
      };
    },
    getLayerData(endpoint) {
      endpoint.transformResponse = async (response, meta, args) => {
        // Add data normalization for POI layer
        if (args.key.includes('unique_poi')) {
          const { rows = [], column_keys = [] } =
            response as GetLayerDataApiResponse;
          const value = {};
          for (const row of rows) {
            const brand = {} as Brand;
            for (let index = 0; index < column_keys.length; index++) {
              const column = column_keys[index];
              brand[column] = row[index];
            }
            value[brand.content_hash] = brand;
          }
          return layerDataAdapter.setAll(initialLayerDataState, value);
        }
        return response;
      };
    },
  },
});

export { enhancedApi };
