import {
  Button,
  Classes,
  Dialog,
  H4,
  Intent,
  Spinner,
  SpinnerSize,
} from '@blueprintjs/core';
import { boundMethod } from 'autobind-decorator';
import classNames from 'classnames';
import React, { Component, ReactNode } from 'react';
import { Box } from 'react-layout-components';

import rootStyles from 'uf/styles/root.module.css';
import { dialogClasses, Sizes } from 'uf/ui/base/sizes';

interface Props {
  onExited: () => void;
  /**
   * Fired when the user clicks "ok"
   */
  onSubmit: () => void;
  /**
   * Fired when the user clicks "cancel"
   */
  onCancel?: () => void;
  failure?: boolean;
  waitForCompletion?: boolean;
  title?: ReactNode;
  submitMessage?: ReactNode;
  failureMessage?: ReactNode;
  cancelText?: string;
  submitText?: string;
  primaryButtonStyle?: Intent;
  secondaryButtonStyle?: Intent;

  /**
   * Support "legacy" mode where this dialog maintains its own "show" state. Do
   * NOT use this mode on any new code.
   *
   * TODO: get rid of this state by migrating consumers, by converting:
   *
   *   { boolVal && <ConfirmationModal...>}
   *
   * to:
   *
   *   <ConfirmationModal show={boolVal} ...>
   */
  legacyShowModal?: boolean;

  /** Whether to show the modal. Ignored if `legacyShowModal` is true */
  show?: boolean;
}

interface State {
  showModal: boolean;
  showSpinner: boolean;
}
export default class ConfirmationModal extends Component<Props, State> {
  static defaultProps = {
    onExited: () => {},
    onSubmit: () => {},
    failure: false,
    waitForCompletion: false,
    submitMessage: 'Confirm submit?',
    failureMessage: 'Operation failed',
    cancelText: 'Cancel',
    submitText: 'Submit',
    primaryButtonStyle: 'primary',
    secondaryButtonStyle: null,
    show: true,
  };

  state = {
    showModal: true,
    showSpinner: false,
  };

  // This callback fires after animation is complete.
  // parent should use this to stop rendering the modal.
  @boundMethod
  onExited() {
    const { onExited } = this.props;
    if (onExited) {
      onExited();
    }
  }

  // TODO: have this accept a promise-action and we can handle
  // waitForComplettion and closing animations internally
  @boundMethod
  onSubmit(e) {
    // Temporary hack for dialogs that open themselves from buttons.
    e.stopPropagation();
    const { onSubmit, waitForCompletion } = this.props;
    if (waitForCompletion) {
      this.setState({ showSpinner: true }, () => onSubmit());
    } else {
      this.onClose(e);
      onSubmit();
    }
  }

  // This is used to handle the animation of modal.
  @boundMethod
  onClose(e) {
    // Temporary hack for dialogs that open themselves from buttons.
    if (e) {
      e.stopPropagation();
    }
    this.setState({ showModal: false });
    const { onCancel } = this.props;
    if (onCancel) {
      onCancel();
    }
  }

  render() {
    const {
      title,
      failure,
      submitMessage,
      failureMessage,
      cancelText,
      submitText,
      primaryButtonStyle,
      secondaryButtonStyle,
      legacyShowModal,
      show,
    } = this.props;
    const { showModal } = this.state;

    let showSpinner = this.state.showSpinner;
    let message = submitMessage;
    let mainButtonDisabled = false;

    if (failure) {
      showSpinner = false;
      message = failureMessage;
      mainButtonDisabled = true;
    }

    return (
      <Dialog
        isCloseButtonShown={title ? false : undefined}
        transitionDuration={__TESTING__ ? 0 : undefined}
        className={classNames('uf-modal', dialogClasses[Sizes.SMALL])}
        isOpen={legacyShowModal ? showModal : show}
        onClose={this.onClose}
        title={<H4>{title}</H4>}
        onClosed={this.onExited}>
        {message && (
          <Box column center className={Classes.DIALOG_BODY}>
            {message}
          </Box>
        )}
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            {showSpinner && <Spinner size={SpinnerSize.SMALL} />}
            <Button
              intent={secondaryButtonStyle}
              onClick={this.onClose}
              className={classNames(
                rootStyles.veryThinMarginHorizontal,
                'Cancel',
              )}>
              {cancelText}
            </Button>
            <Button
              intent={primaryButtonStyle}
              onClick={this.onSubmit}
              disabled={mainButtonDisabled}
              className={classNames(rootStyles.veryThinMarginHorizontal, 'Ok')}>
              {submitText}
            </Button>
          </div>
        </div>
      </Dialog>
    );
  }
}
