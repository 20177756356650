import { AnalysisModuleParameters } from 'uf-api';
import { parseFullPath } from 'uf/base/dataset';
import { makeAsyncApiCaller, makeAsyncApiCallerRaw } from 'uf/base/xhr';
import { ProjectId } from 'uf/projects';
import { ScenarioId } from 'uf/scenarios';

const getAnalysisModule = makeAsyncApiCaller(
  apis => apis.project.get_analysis_module,
);
export function loadAnalysisSchema(projectId: ProjectId, moduleKey: string) {
  const { namespace, key } = parseFullPath(projectId, 'project');
  return getAnalysisModule({
    namespace,
    key,
    module_key: moduleKey,
  });
}

const getProjectAnalysisParams = makeAsyncApiCaller(
  apis => apis.project.get_project_analysis_params,
);
export function loadAnalysisScenarioParams(
  projectId: ProjectId,
  scenarioId: ScenarioId,
  moduleKey: string,
) {
  const { namespace, key } = parseFullPath(projectId, 'project');
  const { key: scenarioKey } = parseFullPath(scenarioId, 'scenario');
  return getProjectAnalysisParams({
    namespace,
    key,
    scenario_key: scenarioKey,
    module_key: moduleKey,
  });
}

const updateScenarioAnalysisParams = makeAsyncApiCaller(
  apis => apis.project.update_scenario_analysis_params,
);

export function updateScenarioAnalysisParamsAPI(
  projectId: ProjectId,
  scenarioId: ScenarioId,
  params: AnalysisModuleParameters,
  preview: boolean = false,
) {
  const { namespace, key } = parseFullPath(projectId, 'project');
  const { key: scenarioKey } = parseFullPath(scenarioId, 'scenario');

  return updateScenarioAnalysisParams({
    namespace,
    key,
    scenario_key: scenarioKey,
    params,
    preview,
  });
}

const exportModuleParams = makeAsyncApiCallerRaw(
  apis => apis.project.export_module_params,
);

export function exportAnalysisModuleParams(
  projectId: ProjectId,
  moduleKey: string,
) {
  const { namespace, key } = parseFullPath(projectId, 'project');

  return exportModuleParams({
    namespace,
    key,
    module_key: moduleKey,
  });
}
