import { useCallback, useState } from 'react';

import { useDebouncedCallback } from './useDebouncedCallback/useDebouncedCallback';

/**
 * Just like `useState`, this function gives you a state object, except that the
 * state isn't actually updated until after a debounce. This can be helpful for
 * autocomplete inputs, especially if the change of state would cause a
 * rerender.
 *
 * Usage is just like `setState`:
 * ```
 *   const [state, setState] = useDebouncedState('');
 * ```
 *
 * However, sometimes you need the "immediate" value too. To get the current
 * state, there is a 3rd result:
 * ```
 *   const [state, setState, currentState] = useDebouncedState('');
 * ```
 *
 * This allows `state` to be used as the input value, as that needs more
 * immediate response. For instance this will *not* work:
 * ```
 *   <input onChange={e => setState(e.target.value)} value={currentState} />
 * ```
 */
export function useDebouncedState<T>(
  defaultValue: T,
  debounceTime: number,
  leading?: boolean,
  trailing?: boolean,
): [T, (value: T) => void, T] {
  const [state, setState] = useState(defaultValue);
  const [currentState, setCurrentState] = useState(defaultValue);
  const setStateDebounced = useDebouncedCallback(
    setState,
    [setState],
    debounceTime,
    leading,
    trailing,
  );
  const setBoth = useCallback(
    (value: T) => {
      setStateDebounced(value);
      setCurrentState(value);
    },
    [setStateDebounced],
  );
  return [state, setBoth, currentState];
}
