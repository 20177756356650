import _ from 'lodash';
import React, { FunctionComponent, HTMLAttributes, useMemo } from 'react';
import { Box } from 'react-layout-components';

interface PaneProps extends HTMLAttributes<HTMLDivElement> {
  height?: string | number;
  width?: string | number;

  flexShrink?: string | number;
  column?: boolean;
}

const Pane: FunctionComponent<PaneProps> = props => {
  const { children, className, height, width, style, flexShrink, column } =
    props;

  // don't pass undefined to Box attributes expecting a string.
  const boxProps = useMemo(
    () => ({
      ..._.pickBy({ height, width, style, flexShrink, column }),
      // Fixes firefox width issues
      maxWidth: width,
    }),
    [column, flexShrink, height, style, width],
  );

  return (
    <Box className={className} {...boxProps}>
      {children}
    </Box>
  );
};
export default Pane;
