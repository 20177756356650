import { CSSProperties } from 'react';
import { VictoryTheme } from 'victory';
import { VictoryThemeDefinition } from 'victory-core';

const DefaultTheme = VictoryTheme.grayscale;

const defaultLabelStyle: CSSProperties = {
  fontSize: 12,
  fontFamily: 'Roboto',
  color: 'var(--color-blue-grey-800)',
};
const theme: VictoryThemeDefinition = {
  ...DefaultTheme,
  bar: {
    ...DefaultTheme.bar,
    style: {
      ...DefaultTheme.bar.style,
      labels: {
        ...DefaultTheme.bar.style.labels,
        ...defaultLabelStyle,
      },
    },
  },
  axis: {
    ...DefaultTheme.axis,
    style: {
      ...(DefaultTheme.axis as any).style,
      axisLabel: {
        ...(DefaultTheme.axis as any).style.axisLabel,
        ...defaultLabelStyle,
      },
    },
  },
  // TODO: update DefinitelyTyped's type definitions for `VictoryThemeDefinition`.
} as VictoryThemeDefinition;

export default theme;
