import { AnchorButton, Position, Tooltip } from '@blueprintjs/core';
import classNames from 'classnames';
import React, { CSSProperties, MouseEventHandler } from 'react';

import styles from './MapButton.module.css';

const mapButtonStyle: CSSProperties = {
  height: '30px',
  width: '30px',
};

interface MapButtonProps {
  id?: string;
  className?: string;
  active?: boolean;
  icon?: React.ReactElement;
  text?: string;
  tooltipText?: string | JSX.Element;
  tooltipPlacement?: Position;
  // buttonProps
  style?: CSSProperties;
  disabled?: boolean;
  onClick?: MouseEventHandler;
  minimal?: boolean;
}

const MapButton: React.FunctionComponent<MapButtonProps> = props => {
  const {
    id,
    style,
    className,
    icon,
    text,
    children,
    tooltipText,
    tooltipPlacement = Position.LEFT,
    minimal,
    ...buttonProps
  } = props;
  const buttonClass = classNames(className, styles.mapButton);
  const buttonStyle: CSSProperties = {
    ...mapButtonStyle,
    ...style,
  };

  return (
    <Tooltip
      isOpen={tooltipText ? undefined : false}
      openOnTargetFocus={false}
      position={tooltipPlacement}
      content={tooltipText}>
      <AnchorButton
        id={id}
        minimal={minimal}
        small
        icon={icon}
        text={text}
        className={buttonClass}
        style={buttonStyle}
        {...buttonProps}>
        {children}
      </AnchorButton>
    </Tooltip>
  );
};
export default MapButton;
