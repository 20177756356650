import { Position } from '@blueprintjs/core';
import Icon from '@mdi/react';
import classNames from 'classnames';
import React, { FunctionComponent, useCallback } from 'react';
import { Box } from 'react-layout-components';
import { useSelector } from 'react-redux';
import { t } from 'ttag';

import { getActiveProjectId } from 'uf/app/selectors';
import { updateMapMode as updateMapModeAction } from 'uf/explore/actions/map';
import { getMapMode } from 'uf/explore/selectors/map';
import { events, logEvent } from 'uf/logging';
import { MapMode } from 'uf/map/mapmode';
import { ProjectId } from 'uf/projects';
import rootStyles from 'uf/styles/root.module.css';
import { IconSizeNew } from 'uf/ui/base/icon';
import Key from 'uf/ui/base/Key/Key';
import useBindAction from 'uf/ui/base/useBindAction/useBindAction';
import MapButton from 'uf/ui/map/MapButton/MapButton';

interface Props {
  title?: string;
  iconPath?: string;
  className?: string;
  modeKey?: MapMode;
  disabled?: boolean;
  hotKey?: string;
}

const ExploreMapControlButton: FunctionComponent<Props> = ({
  iconPath,
  className,
  modeKey,
  disabled,
  hotKey,
  title,
}) => {
  const activeModeKey = useSelector(getMapMode);
  const projectId = useSelector(getActiveProjectId);
  const onClick = useOnClick(projectId, modeKey);
  const tooltipText = getTooltipText(hotKey, title, disabled);

  return (
    <MapButton
      minimal
      id={`uf-map-${modeKey}`}
      className={classNames(`Map-${modeKey}`, className)}
      icon={<Icon path={iconPath} size={IconSizeNew.SMALL} />}
      active={modeKey === activeModeKey}
      disabled={disabled}
      tooltipText={tooltipText}
      tooltipPlacement={Position.BOTTOM}
      onClick={onClick}
    />
  );
};

export default ExploreMapControlButton;

function useOnClick(projectId: ProjectId, modeKey: MapMode) {
  const updateMapMode = useBindAction(updateMapModeAction);
  return useCallback(() => {
    logEvent(events.MAP_CONTROL_BUTTON_CLICKED, {
      projectId,
      button: modeKey,
    });
    updateMapMode(projectId, modeKey);
  }, [modeKey, projectId, updateMapMode]);
}

function getTooltipText(hotKey: string, title: string, disabled: boolean) {
  if (disabled) {
    return t`Please select a layer from the layer list`;
  }
  if (hotKey) {
    return (
      <Box center>
        {title}
        <Key className={rootStyles.thinMarginLeft} character={hotKey} />
      </Box>
    );
  }
  return title;
}
