import { ProjectId } from 'uf/projects';
import { LegacyVirtualLayerId } from 'uf/projects/virtualLayers';
import { VISIBLE_LAYER_IDS_PREFKEY } from 'uf/user';
import { makePrefValue, makeProjectUserPrefKey } from 'uf/user/helpers';

import { loadUserData, setUserData } from './data';

export function persistVisibleLayerVirtualIdsForProject(
  projectId: ProjectId,
  visibleLayerVirtualIds: LegacyVirtualLayerId[],
  userKey: string,
) {
  const visibleLayersKey = makeProjectUserPrefKey(
    projectId,
    VISIBLE_LAYER_IDS_PREFKEY,
  );
  return setUserData(
    visibleLayersKey,
    makePrefValue(visibleLayerVirtualIds),
    userKey,
  );
}

export function loadPersistedVisibleLayerVirtualIdsForProject(
  projectId: ProjectId,
  userKey: string,
) {
  const visibleLayersKey = makeProjectUserPrefKey(
    projectId,
    VISIBLE_LAYER_IDS_PREFKEY,
  );
  return loadUserData(visibleLayersKey, userKey);
}
