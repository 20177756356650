import { History } from 'history';
import { browserHistory } from 'react-router';
import { AnyAction } from 'redux';
import { ActionsObservable, ofType } from 'redux-observable';
import { merge as observableMerge, of as observableOf } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';

import { OrganizationLogoInfo } from 'uf-api';
import { getCurrentUserActionTypes } from 'uf-api/api/authentication.service';
import {
  addOrgLogoActionTypes,
  cancelInvitationActionTypes,
  changeSubscriptionPlanActionTypes,
  createOrganizationActionTypes,
  deleteOrgLogoActionTypes,
  editMemberInOrganizationActionTypes,
  inviteUserToOrganizationActionTypes,
  removeMemberFromOrganizationActionTypes,
  updateOrganizationNameActionTypes,
} from 'uf-api/api/organization.service';
import {
  createProjectActionTypes,
  createProjectSuccessAction,
} from 'uf-api/api/project.service';
import { parseFullPath } from 'uf/base/dataset';
import { combineEpics } from 'uf/base/epics';
import { SuccessAction } from 'uf/data/ActionTypes';
import { loadCurrentUser } from 'uf/user/actions/authentication';

import {
  ensureOrganizationSubscription,
  loadOrganizationInvitations,
  loadOrganizationMembers,
  loadOrganizationProjects,
  loadOrganizationSubscription,
} from './actions';
import { loadOrganizationLogos } from './actions/logo';

export function reloadOrganizationsAfterCreateSuccess(
  action$: ActionsObservable<AnyAction>,
) {
  return action$
    .ofType(createOrganizationActionTypes.SUCCESS)
    .pipe(map(() => loadCurrentUser()));
}

export function reloadOrganizationsAfterUpdateSuccess(
  action$: ActionsObservable<AnyAction>,
) {
  return action$
    .ofType(updateOrganizationNameActionTypes.SUCCESS)
    .pipe(map(() => loadCurrentUser()));
}

// Use a factory so we can mock the browserHistory in test
export function makeReloadOrganizationProjectsListAfterCreateEpic(
  browserHistoryLib: History,
) {
  return (action$: ActionsObservable<createProjectSuccessAction>) =>
    action$.pipe(
      ofType(createProjectActionTypes.SUCCESS),
      filter(
        () => browserHistoryLib.getCurrentLocation().pathname === '/settings',
      ),
      map(action => {
        const { result: project } = action;
        const { full_path: projectId } = project;
        const { namespace } = parseFullPath(projectId, 'project');
        return loadOrganizationProjects(`/${namespace}`);
      }),
    );
}

export function reloadOrganizationMembersAfterMemberAdd(
  action$: ActionsObservable<AnyAction>,
) {
  return action$
    .ofType(inviteUserToOrganizationActionTypes.SUCCESS)
    .pipe(map(action => loadOrganizationMembers(action.organizationKey)));
}

export function reloadOrganizationMembersAfterMemberEdit(
  action$: ActionsObservable<AnyAction>,
) {
  return action$
    .ofType(editMemberInOrganizationActionTypes.SUCCESS)
    .pipe(map(action => loadOrganizationMembers(action.organizationKey)));
}

export function reloadOrganizationMembersAfterMemberDelete(
  action$: ActionsObservable<AnyAction>,
) {
  return action$
    .ofType(removeMemberFromOrganizationActionTypes.SUCCESS)
    .pipe(map(action => loadOrganizationMembers(action.organizationKey)));
}

export function reloadOrganizationInvitationsAfterInviteSuccess(
  action$: ActionsObservable<AnyAction>,
) {
  return action$
    .ofType(inviteUserToOrganizationActionTypes.SUCCESS)
    .pipe(map(action => loadOrganizationInvitations(action.organizationKey)));
}

export function reloadOrganizationInvitationsAfterCancellation(
  action$: ActionsObservable<AnyAction>,
) {
  return action$
    .ofType(cancelInvitationActionTypes.SUCCESS)
    .pipe(map(action => loadOrganizationInvitations(action.organizationKey)));
}

export function ensureSubscriptionsForOrganizations(
  action$: ActionsObservable<AnyAction>,
) {
  return action$.pipe(
    ofType(getCurrentUserActionTypes.SUCCESS),
    mergeMap(action => {
      const { organizations = [] } = action.result;
      const orgKeys = organizations.map(({ key }) => key);
      const actions = orgKeys.map(orgKey =>
        observableOf(ensureOrganizationSubscription(orgKey)),
      );
      return observableMerge(...actions);
    }),
  );
}

export function reloadSubscriptionOnChangePlan(
  action$: ActionsObservable<AnyAction>,
) {
  return action$
    .ofType(changeSubscriptionPlanActionTypes.SUCCESS)
    .pipe(
      map(({ key: organizationKey }) =>
        loadOrganizationSubscription(organizationKey),
      ),
    );
}

export function reloadLogosListAfterMutation(
  action$: ActionsObservable<SuccessAction<OrganizationLogoInfo>>,
) {
  return action$
    .ofType(addOrgLogoActionTypes.SUCCESS, deleteOrgLogoActionTypes.SUCCESS)
    .pipe(
      map(({ key: organizationKey }) => loadOrganizationLogos(organizationKey)),
    );
}

export default combineEpics(
  {
    reloadOrganizationsAfterCreateSuccess,
    reloadOrganizationsAfterUpdateSuccess,
    reloadOrganizationProjectsListAfterCreate:
      makeReloadOrganizationProjectsListAfterCreateEpic(browserHistory),
    reloadOrganizationMembersAfterMemberAdd,
    reloadOrganizationMembersAfterMemberEdit,
    reloadOrganizationMembersAfterMemberDelete,
    reloadOrganizationInvitationsAfterInviteSuccess,
    reloadOrganizationInvitationsAfterCancellation,
    ensureSubscriptionsForOrganizations,
    reloadSubscriptionOnChangePlan,
    reloadLogosListAfterMutation,
  },
  'organizations',
);
