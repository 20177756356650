import classNames from 'classnames';
import React, { forwardRef, FunctionComponent, memo, useCallback } from 'react';
import { Draggable, DraggableStateSnapshot } from 'react-beautiful-dnd';
import mergeRefs from 'react-merge-refs';

import { LayerReference } from 'uf-api';
import { ColumnKey, LayerId } from 'uf/layers';
import { FilterSpec } from 'uf/layers/filters';
import { ProjectId } from 'uf/projects';
import { LegacyVirtualLayerId } from 'uf/projects/virtualLayers';
import panelStyles from 'uf/styles/panels.module.css';
import { GetComponentProps } from 'uf/ui/base/types';
import LayerListItem from 'uf/ui/layers/LayerListItem/LayerListItem';
import { ViewId } from 'uf/views';

export interface ListItemData {
  itemKey: string;
  active: boolean;
  visible: boolean;
  layer: LayerReference;
  layerId: LayerId;
  virtualLayerId: LegacyVirtualLayerId;
  isPlaceholderLayer: boolean;
  extrudedColumn: ColumnKey;
  isLoading: boolean;
  statusMessage: string;
  layerIconPath: string;
  filters: Partial<FilterSpec>;
  onDelete: (layer: LayerReference) => void;
  isBaseCanvas: boolean;
  showPaintedOnly: boolean;
  onUpdateSelect?: (layer: LayerReference) => void;
}

interface Props extends ListItemData {
  projectId: ProjectId;
  viewId: ViewId;

  flyoutPanelVisible: boolean;
  onActivateLayer: (
    projectId: ProjectId,
    virtualLayerId: LegacyVirtualLayerId,
    active: boolean,
  ) => void;
  onOpenFilterList: (layerId: LayerId) => void;

  onEnableFilter: (
    projectId: ProjectId,
    layerId: LayerId,
    enable: boolean,
  ) => void;
  onChangeLayerVisibility: (
    projectId: ProjectId,
    virtualLayerId: LegacyVirtualLayerId,
    viewId: ViewId,
    visible: boolean,
  ) => void;
  onShowPaintedOnly: (layerId: LayerId, showPaintedOnly: boolean) => void;
  onToggleExtrusion: (layerId: LayerId, columnKey: ColumnKey) => void;

  index: number;
  outerRef: React.MutableRefObject<HTMLElement>;
  showLayerId: boolean;
}

const DraggableLayerListItem: FunctionComponent<Props> = props => {
  const {
    index,
    outerRef,
    active,
    visible,
    isPlaceholderLayer,
    extrudedColumn,
    isLoading,
    statusMessage,
    layer,
    layerId,
    virtualLayerId,
    projectId,
    filters,
    onActivateLayer,
    onOpenFilterList,
    flyoutPanelVisible,
    onEnableFilter,
    onDelete,
    isBaseCanvas,
    showPaintedOnly,
    onShowPaintedOnly,
    onChangeLayerVisibility,
    onToggleExtrusion,
    layerIconPath,
    itemKey,
    viewId,
    showLayerId,
    onUpdateSelect,
  } = props;

  const onChangeVisibility = useCallback(
    () => onChangeLayerVisibility(projectId, virtualLayerId, viewId, !visible),
    [onChangeLayerVisibility, projectId, viewId, virtualLayerId, visible],
  );
  const onEnable = useCallback(
    (enabled: boolean): void => onEnableFilter(projectId, layerId, enabled),
    [layerId, onEnableFilter, projectId],
  );
  const onDeleteLayer = useCallback(() => {
    onDelete(layer);
  }, [layer, onDelete]);

  const onUpdateLayerSelect = useCallback(() => {
    onUpdateSelect(layer);
  }, [layer, onUpdateSelect]);

  const onToggleExtrusionColumn = useCallback(() => {
    onToggleExtrusion(layerId, extrudedColumn);
  }, [extrudedColumn, layerId, onToggleExtrusion]);
  return (
    <Draggable
      disableInteractiveElementBlocking
      draggableId={itemKey}
      index={index}>
      {(provided, snapshot: DraggableStateSnapshot) => (
        <div
          // mergeRefs allows both react-beautiful-dnd and react-flip-move to have
          // access to the inner div
          ref={mergeRefs([provided.innerRef, outerRef])}
          {...provided.draggableProps}
          {...provided.dragHandleProps}>
          <LayerListItem
            className={classNames({
              [panelStyles.card2]:
                snapshot.isDragging && !snapshot.isDropAnimating,
            })}
            layer={layer}
            layerId={layerId}
            virtualLayerId={virtualLayerId}
            projectId={projectId}
            iconPath={layerIconPath}
            isPlaceholderLayer={isPlaceholderLayer}
            isExtruded={!!extrudedColumn}
            isBaseCanvas={isBaseCanvas}
            isLoading={isLoading}
            statusMessage={statusMessage}
            active={active}
            visible={visible}
            filters={filters}
            flyoutPanelVisible={flyoutPanelVisible}
            showPaintedOnly={showPaintedOnly}
            dragging={snapshot.isDragging && !snapshot.isDropAnimating}
            onActivateLayer={onActivateLayer}
            onDelete={onDelete ? onDeleteLayer : null}
            onOpenFilterList={onOpenFilterList}
            onEnableFilter={onEnable}
            onChangeLayerVisibility={onChangeVisibility}
            onShowPaintedOnly={onShowPaintedOnly}
            onToggleExtrusion={onToggleExtrusionColumn}
            showLayerId={showLayerId}
            onUpdateSelect={onUpdateSelect ? onUpdateLayerSelect : null}
          />
        </div>
      )}
    </Draggable>
  );
};

/**
 * Alter the props to allow the `forwardRef` to set the outerRef, without
 * requiring the consumer of `AnimatedListItem` to pass `outerRef`
 */
type AnimatedListItemProps = Omit<
  GetComponentProps<typeof DraggableLayerListItem>,
  'outerRef'
>;
/**
 * An wrapper around `DraggableLayerListItem` to allow a functional component
 * to pass its ref along
 */
const AnimatedListItem = (
  props: AnimatedListItemProps,
  ref: React.MutableRefObject<HTMLElement>,
): JSX.Element => <DraggableLayerListItem {...props} outerRef={ref} />;
const AnimatedListItemRef = forwardRef<
  typeof DraggableLayerListItem,
  AnimatedListItemProps
>(AnimatedListItem as any);

export default memo(AnimatedListItemRef);
