import { t } from 'ttag';
import { LayerColumnBreaks } from 'uf-api';
import { Flavor } from 'uf/base/types';
import { EmptyColumnValueMessage } from 'uf/layers';
import { NullCategory, NULL_CATEGORY } from 'uf/layers/nullStats';

/**
 * These are the types of supported symbology.
 *
 * Note these are coincidentally a subset of the mapbox style layer types, but
 * that is mostly a coincidence as there are transformations which convert
 * symbologies with these types into map style layers and the conversion is not
 * necessarily 1:1 with their corresponding map style layer types.
 */
export enum SymbologyTypes {
  LINE = 'line',
  FILL = 'fill',
  CIRCLE = 'circle',
  SYMBOL = 'symbol',
}

export enum SymbologyType {
  FILL = 'fill',
  LINE = 'line',
  CIRCLE = 'circle',
  SYMBOL = 'symbol',
}

export function isFillSymbology(symbology: ColumnSymbology) {
  return symbology.type === SymbologyTypes.FILL;
}

export function isLineSymbology(symbology: LayerColumnSymbology) {
  return symbology.type === SymbologyTypes.LINE;
}

export function isCircleSymbology(symbology: LayerColumnSymbology) {
  return symbology.type === SymbologyTypes.CIRCLE;
}

/** *******
 * Paint *
 *********/

/*
 * The function type for data-driven paint properties
 * see: https://www.mapbox.com/mapbox-gl-js/style-spec/#function-type
 *
 * This is the subset of mapbox types that we support in our symbology.
 *
 * TODO: This is an example of where the mapbox api is leaking into our model
 * for symbology. Delete this from symbology and get it from the column
 * metatype instead.
 */
export enum PaintColorTypes {
  /**
   * Categorial type maps 1:1 with specific stops. Returns the output value of
   * the stop equal to the function input.
   */
  CATEGORICAL = 'categorical',
  /**
   * Interval type produces "stepwise" stops, meaning no interpolation of values
   * between stops.
   */
  INTERVAL = 'interval',
}

/** *******
 * Scale *
 *********/

/*
 * Describes the scale of the stops.
 *
 * TODO: Delete this from symbology and get it from the column metatype instead.
 */
export enum SymbologyScales {
  CATEGORICAL = 'categorical',
  NUMERIC = 'numeric',
}

export function getScaleType(symbology: ColumnSymbology) {
  return symbology?.display_hints?.scale;
}

export function isNumericSymbology(symbology: ColumnSymbology) {
  return getScaleType(symbology) === SymbologyScales.NUMERIC;
}

/** **************
 * Distribution *
 ****************/

/*
 * Describes the distribution of the stop values.
 */

export enum DistributionTypes {
  DEFAULT = 'DEFAULT',
  CUSTOM = 'CUSTOM',
  NATURAL = 'NATURAL',
  LINEAR = 'LINEAR',
  LOGARITHMIC = 'LOGARITHMIC',
  QUANTILES = 'QUANTILES',
}

export const DistributionLabels = {
  DEFAULT: t`Default`,
  CUSTOM: t`Custom`,
  NATURAL: t`Natural Breaks`,
  LINEAR: t`Equal Interval`,
  LOGARITHMIC: t`Logarithmic`,
  QUANTILES: t`Quantiles`,
};

export enum DistributionQueryStrings {
  LINEAR = 'linear',
  LOGARITHMIC = 'logarithmic',
  QUANTILES = 'quantiles',
  NATURAL = 'natural',
}

// TODO: In uf_api/layer/model/py there is a pattern of
//       lower case on everything. In the FE there is a pattern
//       of all caps on most defaults. If we choose to go with lower case,
//       then we should change all to lower case.
// we need the inverse lookup for the distribution (e.g. break_type will
// be 'quantiles' but we need 'QUANTILES')
export const DistributionQueryStringCrosswalk = {};
Object.keys(DistributionQueryStrings).forEach(key => {
  const keyVal = DistributionQueryStrings[key];
  DistributionQueryStringCrosswalk[keyVal] = key;
});

/** ***************
 * Interpolation *
 *****************/

// Describes how the given colors should be interpolated between stops.
// See: https://www.mapbox.com/mapbox-gl-js/style-spec/#function-type
export enum InterpolationTypes {
  CATEGORICAL = 'categorical',
  INTERVAL = 'interval',
  // TODO: Delete EXPONENTIAL. We don't use this.
  EXPONENTIAL = 'exponential',
}

/** *******
 * Theme *
 *********/

export const FALLBACK_THEME = 'd3:interpolateGnBu';
export const CUSTOM_THEME = 'custom';

export const SequentialSingleHueThemes = [
  'material:red:range',
  'material:blue:range',
  'material:green:range',
  'material:orange:range',
];

export const SequentialMultiHueThemes = [
  'd3:interpolateYlOrRd',
  'd3:interpolateGnBu',
  'd3:interpolateBuPu',
  'd3:interpolateRdPu',
];

export const DivergingThemes = [
  'd3:interpolateRdBu',
  'd3:interpolateRdYlGn',
  'uf:#8C5109,#D9B365,#F6E8C3,#F5F5F5,#C7EAE5,#59B4AC,#01665E',
  'uf:#DB4325,#E57540,#ECC997,#EBEED9,#A3D8C3,#5DC3B0,#086265',
];

export const CategoricalThemes = [
  'd3:schemeCategory10',
  'd3:schemeCategory20',
  'd3:schemeCategory20b',
  'd3:schemeCategory20c',
];

export const MaterialThemes = [
  ...SequentialSingleHueThemes.filter(theme => theme.startsWith('material')),
];

export const D3Themes = {
  NUMERIC: [
    ...SequentialMultiHueThemes.filter(theme => theme.startsWith('d3')),
    ...DivergingThemes.filter(theme => theme.startsWith('d3')),
  ],

  CATEGORICAL: [...CategoricalThemes.filter(theme => theme.startsWith('d3'))],
};

export const UFThemes = {
  NUMERIC: [...DivergingThemes.filter(theme => theme.startsWith('uf'))],
};

export function getTheme(symbology: ColumnSymbology): string {
  return symbology?.display_hints?.theme;
}

/** **********
 * Defaults *
 ************/
export const SYMBOLOGY_FORMAT = '1.0.0';

// TODO: Unify this with the frontend constants in api/uf_api/layer/tasks.py
export const DEFAULT_BREAK_COUNT = 12;
export const DEFAULT_BREAK_TYPE: LayerColumnBreaks.BreakTypeEnum =
  LayerColumnBreaks.BreakTypeEnum.Natural;
export const DEFAULT_NUMERIC_DISTRIBUTION = DistributionTypes.NATURAL;

export const DEFAULT_OPACITY = 0.8;
// Default opacity for environmental layers
export const DEFAULT_OPACITY_ENVIRONMENTAL = 0.6;
export const DEFAULT_LINE_WIDTH = 1;
export const DEFAULT_CIRCLE_RADIUS = 5;
// These defaults are constant across all numeric symbologies
export const DEFAULT_NUMERIC_DISPLAY_HINTS = {
  scale: SymbologyScales.NUMERIC,
  showZero: false,
};

export const DEFAULT_CATEGORICAL_DISPLAY_HINTS = {
  distribution: null,
  interpolation: InterpolationTypes.CATEGORICAL,
  scale: SymbologyScales.CATEGORICAL,
  theme: CUSTOM_THEME,
};

/**
 * A single paint style for a property.
 */
export interface PaintProperty<S, D = number | string> {
  // If there is no styling specified for a given stop, use the default styling provided.
  // For example, colors are strings (hex colors) while opacities are numbers (0 to 1)
  default?: D;

  type: 'categorical' | 'interval';
  property: string;
  stops: S[];
}

/*
 * Data-Driven Properties
 */
export interface DataDrivenPaintProperty<
  S extends DataDrivenStop,
  D = number | string,
> {
  // If there is no styling specified for a given stop, use the default styling provided.
  // For example, colors are strings (hex colors) while opacities are numbers (0 to 1)
  default?: D;

  type: 'categorical' | 'interval';
  property: string;
  stops: S[];
}

export type DataDrivenColorProperty = DataDrivenPaintProperty<
  DataDrivenColorStop,
  string
>;

export type DataDrivenWidthProperty = DataDrivenPaintProperty<
  DataDrivenWidthStop,
  number
>;
export type DataDrivenOpacityProperty = DataDrivenPaintProperty<
  DataDrivenOpacityStop,
  number
>;
export type DataDrivenHeightProperty = DataDrivenPaintProperty<
  DataDrivenStop,
  number
>;
export type DataDrivenStrokeColorProperty = DataDrivenPaintProperty<
  DataDrivenStrokeColor,
  string
>;

export type DataDrivenRadiusProperty = DataDrivenPaintProperty<
  DataDrivenRadiusStop,
  number
>;

export type PaintWidthProperty = DataDrivenWidthProperty | number;
export type PaintColorProperty = DataDrivenColorProperty | string;
export type PaintOpacityProperty = DataDrivenOpacityProperty | number;
export type PaintHeightProperty = DataDrivenHeightProperty | number;
export type PaintStrokeColorProperty = DataDrivenStrokeColorProperty | string;

export type PaintRadiusProperty = DataDrivenRadiusProperty | number;

/**
 * Base data-driven stop
 */
export type DataDrivenStopValue = number | string | boolean | NullCategory;
export interface DataDrivenStop<P = DataDrivenStopValue> {
  // values can be strings, eg: built form keys
  // or numbers, eg: pop, du, area
  value: P;

  // Optional because we default to the `value` if not provided
  label?: string;

  // Let's us omit a built form from the legend if it is not used
  used?: boolean;
}

/**
 * A stop for generating color.
 */
export interface DataDrivenColorStop extends DataDrivenStop {
  color?: string;
}

export interface DataDrivenWidthStop extends DataDrivenStop {
  width?: number;
}

export interface DataDrivenOpacityStop extends DataDrivenStop {
  opacity?: number;
}

export interface DataDrivenHeightStop extends DataDrivenStop {
  height?: number;
}

export interface DataDrivenStrokeColor extends DataDrivenStop {
  'stroke-color'?: string;
}
export interface DataDrivenRadiusStop extends DataDrivenStop {
  radius: number;
}

/*
 * Add more Stop interfaces here when we support more data-driven properties
 * like opacity, stroke-width, etc...
 */
type StopValue = number;
type Color = string;
type Opacity = number; // value bebween 0 - 1
type Height = number; // value in meters
export type ColorStop = [StopValue, Color];
export type OpacityStop = [StopValue, Opacity];
export type HeightStop = [StopValue, Height];

export interface FillExtrusionSymbologyPaintProperty {
  'fill-extrusion-color'?: PaintColorProperty;
  'fill-extrusion-height'?: PaintHeightProperty;
  'fill-extrusion-opacity'?: number;
}

export interface FillSymbologyPaintProperty {
  'fill-color': string | DataDrivenColorProperty;
  'fill-opacity'?: number;
}

export interface SymbolSymbologyPaintProperty {
  /**
   * Opacity of icon
   */
  'icon-opacity': number;
}

export interface LineSymbologyPaintProperty {
  'line-color': string | DataDrivenColorProperty;
  'line-width'?: number | DataDrivenWidthProperty;
  'line-opacity'?: number;
}

export interface CircleSymbologyPaintProperty {
  'circle-color': string | DataDrivenColorProperty;
  'circle-opacity'?: number;
  'circle-radius'?: number | DataDrivenRadiusProperty;
  'circle-stroke-color'?: string | DataDrivenStrokeColorProperty;
  'circle-stroke-width'?: number;
}

export interface SymbolSymbologyLayoutProperty {
  symbol: Symbols;
  placement: SymbolPlacement;
}

export type PaintPropertyKey = keyof (FillExtrusionSymbologyPaintProperty &
  FillSymbologyPaintProperty &
  LineSymbologyPaintProperty &
  CircleSymbologyPaintProperty &
  SymbolSymbologyPaintProperty);

export interface PaintColor {
  paintColor: DataDrivenPaintProperty<DataDrivenColorStop, string> | string;
}

export type CustomTheme = 'custom';
export type DefaultTheme = 'material:blue:range';
export type MaterialThemes =
  | 'material:red:range'
  | DefaultTheme
  | 'material:green:range'
  | 'material:orange:range';

export type D3ThemesNumeric =
  // sequential multi-hue
  | 'd3:interpolateYlOrRd'
  | 'd3:interpolateGnBu'
  // diverging scales
  | 'd3:interpolateRdBu'
  | 'd3:interpolateRdYlGn';

export type D3ThemesCategorical =
  | 'd3:schemeCategory10'
  | 'd3:schemeCategory20'
  | 'd3:schemeCategory20b'
  | 'd3:schemeCategory20c';

// TODO: create theme strings for 'material:grey:300', et al instead of using 'string' type
export type Theme =
  | CustomTheme
  | MaterialThemes
  | D3ThemesNumeric
  | D3ThemesCategorical
  | string;

export enum ExtrusionBreaksMethod {
  Continuous = 'continuous',
  Binned = 'binned',
  NoExtrusion = 'noExtrusion',
}

export interface ExtrusionStyleOptions {
  /**
   * Indicates the pattern to use for determine extrude heights
   */
  breaksMethod: ExtrusionBreaksMethod;

  /**
   * If you want to extrude the lower values more instead of higher values
   */
  invertLevels: boolean;

  /**
   * Degree to scale the attribute data when calculating extrude height
   */
  scaleFactor: number;
}

// use this when you only need to update a few of the properties
export interface NoExtrusionStyleOption {
  breaksMethod: ExtrusionBreaksMethod.NoExtrusion;
}

/*
 * Display Hints
 */
export interface SymbologyDisplayHints {
  scale: SymbologyScales;
  distribution: DistributionTypes;
  interpolation: InterpolationTypes;
  theme: Theme;

  /**
   * If the theme is reversed.
   */
  is_reverse_theme?: boolean;
  /**
   * if scale is categorical, this field is optional
   */
  is_percent?: boolean;

  /**
   * apply color stop to zero valued geos (transparent otherwise)
   */
  showZero?: boolean;

  /** 
   *  exponential interpolations require an exponential_base
   */
  exponential_base?: number;

  /**
   * whether or not to have the extrusion heights binned or continuous
   */
  extrusion_options?: ExtrusionStyleOptions;
}

/** The combination of all well defined paint property types */
export type PaintPropertyType =
  | FillSymbologyPaintProperty
  | FillExtrusionSymbologyPaintProperty
  | LineSymbologyPaintProperty
  | CircleSymbologyPaintProperty
  | SymbolSymbologyPaintProperty;

export interface LayerColumnSymbologyBase {
  type: SymbologyTypes;
  paint: PaintPropertyType;
  layout?: SymbolSymbologyLayoutProperty;
}

/*
 * Complete Symbology
 * Does not contain min/max stops for numeric symbologies
 */
export interface LayerColumnSymbology extends LayerColumnSymbologyBase {
  id: string;
  display_hints: SymbologyDisplayHints;
}

/**
 * A layer column's complete symbology, including FILL, STROKE, CIRCLE
 */
export interface LayerSymbologyEnvelope {
  format: '1.0.0';
  type: 'symbology';
  symbology: LayerColumnSymbology[];
  originator: 'user' | 'dynamic_layer' | 'analysis' | 'override';
}

/**
 * A hand-edited symbolgoy
 * A curated symbology does not need to have display_hints, but should be
 * transformed into LayerColumnSymbology by adding missing display hints, if
 * any.
 */
export interface CuratedColumnSymbology extends LayerColumnSymbologyBase {
  display_hints?: SymbologyDisplayHints;
}

export type ColumnSymbology = LayerColumnSymbology | CuratedColumnSymbology;

/** The symbols that we support on the map. */
export const enum Symbols {
  CHEVRON_RIGHT = 'chevron_right',
  HOSPITAL_BUILDING = 'hospital_building',
  SCHOOL = 'school',
}

/** A list of possible placements */
export const enum SymbolPlacement {
  /**
   * On the line, oriented in the direction of the geometry. Useful for
   * directional arrows */
  FOLLOW_LINE = 'follow_line',
  /**
   * On the line, always oriented so the user can see it. Useful for labelling a
   * route, e.g. a bike route
   * */
  ON_LINE = 'on_line',
  /**
   * In the center of the geometry. Useful for labelling points or the center of
   * polygons.
   */
  CENTER = 'center',

  /** On the point for a point layer */
  POINT = 'point',
}

export const DEFAULT_NULL_COLOR_STOP: DataDrivenColorStop = {
  value: NULL_CATEGORY,
  label: EmptyColumnValueMessage,
  color: 'transparent',
};
export type ViewId = Flavor<string, 'ViewId'>;
