import colors from 'material-colors';

import {
  CuratedColumnSymbology,
  CUSTOM_THEME,
  DEFAULT_OPACITY,
  InterpolationTypes,
  PaintColorTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/public/dataset/morpc_commercial_zoning_overlays': {
    type: [
      {
        type: SymbologyTypes.FILL,
        paint: {
          'fill-color': {
            property: 'type',
            type: PaintColorTypes.CATEGORICAL,
            stops: [
              {
                value: 'COMMUNITY COMMERCIAL OVERLAY',
                label: 'Community Commercial Zone',
                color: colors.red[900],
              },
              {
                value: 'REGIONAL COMMERCIAL OVERLAY',
                label: 'Regional Commercial Zone',
                color: colors.deepOrange[400],
              },
              {
                value: 'URBAN COMMERCIAL OVERLAY',
                label: 'Urban Commercial Zone',
                color: colors.orange[300],
              },
            ],
          },
          'fill-opacity': DEFAULT_OPACITY,
        },
        display_hints: {
          scale: SymbologyScales.CATEGORICAL,
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          theme: CUSTOM_THEME,
        },
      },
    ],
  },
};

export default symbologies;
