import { bindMapActionCreators } from '@mapbox/mapbox-gl-redux';
import { Layer, LngLatLike, Map } from 'mapbox-gl';
import { Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { LOADED } from 'uf/base';
import { downloadUrl } from 'uf/base/download';
import {
  getCenterFromBounds,
  getZoomFromBounds,
  getZoomFromScale,
  UFLngLatBounds,
} from 'uf/base/map';
import * as ActionTypes from 'uf/explore/ActionTypes';
import { getExploreMapViewport } from 'uf/explore/selectors/map';
import { LayerId, LayerVersion } from 'uf/layers';
import { FilterSpec } from 'uf/layers/filters';
import { LayerSource, MapPosition, MapViewport } from 'uf/map';
import { MapMode } from 'uf/map/mapmode';
import { registerPersistedAction } from 'uf/persistence';
import { ProjectId } from 'uf/projects';
import { makeGetProjectBounds } from 'uf/projects/selectors';

const exploreMapActionCreators = bindMapActionCreators('explore-map');
export const { fitBounds } = exploreMapActionCreators;
export function resetMap() {
  return {
    type: ActionTypes.MAP_RESET,
  };
}

export function mapLoaded(mapbox?: Map) {
  return {
    type: ActionTypes.MAP_LOADED,
    mapbox,
  };
}
export function mapLoading(mapbox?: Map) {
  return {
    type: ActionTypes.MAP_LOADING,
    mapbox,
  };
}

export function mapError(error: Error) {
  return {
    type: ActionTypes.MAP_ERROR,
    error,
  };
}

export function updateMapStatus(mapStatus: string, mapbox?: Map) {
  return mapStatus === LOADED ? mapLoaded(mapbox) : mapLoading(mapbox);
}

export function updateMapStyleUrl(
  projectId: ProjectId,
  styleUrl: string,
): ActionTypes.UpdateMapStyleUrlAction {
  return {
    type: ActionTypes.UPDATE_MAP_STYLE_URL,
    projectId,
    styleUrl,
  };
}

registerPersistedAction<ActionTypes.UpdateMapStyleUrlAction, string, string>(
  'styleUrl',
  ActionTypes.UPDATE_MAP_STYLE_URL,
  ([projectId], value) => updateMapStyleUrl(projectId, value),
  {
    getKey({ projectId }) {
      return projectId;
    },
    getValue({ styleUrl }) {
      return styleUrl;
    },
  },
);

export function setBasemapFeatureVisibility(
  projectId: ProjectId,
  featureType: string,
  featureVisible: boolean,
): ActionTypes.SetBasemapFeatureVisibility {
  return {
    type: ActionTypes.SET_BASEMAP_FEATURE_VISIBILITY,
    projectId,
    featureType,
    featureVisible,
  };
}

registerPersistedAction<
  ActionTypes.SetBasemapFeatureVisibility,
  string[],
  boolean
>(
  'basemapFeatureVisible',
  ActionTypes.SET_BASEMAP_FEATURE_VISIBILITY,
  ([projectId, featureType], value) =>
    setBasemapFeatureVisibility(projectId, featureType, value),
  {
    getKey({ projectId, featureType }) {
      return [projectId, featureType];
    },
    getValue({ featureVisible }) {
      return featureVisible;
    },
  },
);

export function updateMapViewport(
  projectId: ProjectId,
  viewport: Partial<MapViewport>,
  save: boolean = false,
): ActionTypes.UpdateMapViewportAction {
  return {
    type: ActionTypes.UPDATE_MAP_VIEWPORT,
    projectId,
    viewport,
    save,
  };
}

export function setExploreMapViewport(
  projectId: ProjectId,
  viewport: MapViewport,
): ActionTypes.SetExploreMapViewportAction {
  return {
    type: ActionTypes.SET_EXPLORE_MAP_VIEWPORT,
    projectId,
    viewport,
  };
}

registerPersistedAction<
  ActionTypes.UpdateMapViewportAction,
  string[],
  Partial<MapViewport>
>(
  'exploreMapViewport',
  ActionTypes.SET_EXPLORE_MAP_VIEWPORT,
  ([projectId], value) => updateMapViewport(projectId, value, false),
  {
    getKey({ projectId }) {
      return [projectId];
    },
    getValue({ viewport }) {
      const { center, zoom, pitch, bearing } = viewport ?? {};
      return { center, zoom, pitch, bearing };
    },
  },
);

/**
 * This is just for keeping the map and redux in sync,
 * and is generally only fired by the map itself.
 */
export function updateMapBounds(
  projectId: ProjectId,
  mapBounds: UFLngLatBounds,
) {
  return {
    type: ActionTypes.UPDATE_MAP_BOUNDS,
    projectId,
    mapBounds,
  };
}

export function updateMapScale(projectId: ProjectId, scale: number) {
  return {
    projectId,
    type: ActionTypes.UPDATE_MAP_SCALE,
    scale,
  };
}

/**
 * This is an explicit call to move the map to the given bounds.
 * Calling this will move the map and the map will call
 * updateMapBounds to keep everything in sync.
 */
export function fitMapToBounds(
  projectId: ProjectId,
  requestedMapBounds: UFLngLatBounds,
): ActionTypes.FitMapToBoundsAction {
  return {
    type: ActionTypes.FIT_MAP_TO_BOUNDS,
    requestedMapBounds,
    projectId,
  };
}

/**
 * This sets the hard limit of the map. If the given bounds do not fit
 * the aspect ratio of the viewport, the map may call this again.
 */
export function updateMapMaxBounds(
  projectId: ProjectId,
  mapMaxBounds: UFLngLatBounds,
) {
  return {
    type: ActionTypes.UPDATE_MAP_MAX_BOUNDS,
    projectId,
    mapMaxBounds,
  };
}

export function zoomToLayer(
  projectId: ProjectId,
  layerId: LayerId,
  layerVersion: LayerVersion,
  filters?: Partial<FilterSpec>,
): ActionTypes.ZoomToLayerAction {
  return {
    type: ActionTypes.ZOOM_TO_LAYER,
    projectId,
    layerId,
    layerVersion,
    layerFilters: filters,
  };
}

export function updateMapMode(
  projectId: ProjectId,
  mode: MapMode,
): ActionTypes.UpdateMapModeAction {
  return {
    type: ActionTypes.UPDATE_MAP_MODE,
    projectId,
    mode,
  };
}

export function setSelectionModesDisabled(
  projectId: ProjectId,
  value: boolean,
): ActionTypes.SetSelectionModesDisabledAction {
  return {
    type: ActionTypes.SET_SELECTION_MODES_DISABLED,
    projectId,
    value,
  };
}

export function updateMapInspection(
  layerId: LayerId,
  point: { x: number; y: number },
  lngLat: LngLatLike,
  properties = {},
) {
  return {
    type: ActionTypes.UPDATE_MAP_INSPECTION,
    layerId,
    inspection: {
      point,
      lngLat,
      properties,
    },
  };
}

export function clearMapInspection(layerId: LayerId) {
  return {
    type: ActionTypes.CLEAR_MAP_INSPECTION,
    layerId,
  };
}

export function updateTileSources(sources: LayerSource[]) {
  return {
    type: ActionTypes.UPDATE_TILE_SOURCES,
    sources,
  };
}

export function updateStyleLayers(layers: Layer[]) {
  return {
    type: ActionTypes.UPDATE_STYLE_LAYERS,
    layers,
  };
}

export function setTakeSnapshot() {
  return {
    type: ActionTypes.snapshotActionTypes.LOAD,
  };
}

export function snapshotReady(url: string) {
  return (dispatch: Dispatch) => {
    // Dispatch this first to guarantee that the outstanding snapshot request removed.
    dispatch({
      type: ActionTypes.snapshotActionTypes.SUCCESS,
      url,
    });

    downloadUrl(url, 'urbanfootprint-map.png');
  };
}

// TODO: get rid of this thunk and use an epic instead
export function setExtentsPosition(
  projectId: ProjectId,
  position: MapPosition,
): ThunkAction<any, any, any, any> {
  const getProjectBounds = makeGetProjectBounds();
  return (dispatch: Dispatch, getState) => {
    const state = getState();
    const projectBounds = getProjectBounds(state, { projectId });
    const { width, height } = getExploreMapViewport(state, { projectId });
    const { pitch, bearing, scale } = position;

    // The map might not exist yet when this fires, such as when
    // restoring a specific bookmark from user persistence.
    if (scale) {
      const zoom = getZoomFromScale(scale, position.lnglat.lat);
      const center: [number, number] = [
        position.lnglat.lng,
        position.lnglat.lat,
      ];
      dispatch(updateMapViewport(projectId, { zoom, center, pitch, bearing }));
    } else if (projectBounds) {
      // if there is no scale, then we zoom to the project bounds.

      const zoom = getZoomFromBounds(projectBounds, width, height);
      const center = getCenterFromBounds(projectBounds);
      const viewport = { pitch, bearing, zoom, center };
      dispatch(updateMapViewport(projectId, viewport));
    }
  };
}

export function setExtentsBounds(
  projectId: ProjectId,
  projectBounds: UFLngLatBounds,
): ThunkAction<any, any, any, any> {
  return (dispatch: Dispatch, getState) => {
    const state = getState();
    const { width, height, pitch, bearing } = getExploreMapViewport(state, {
      projectId,
    });

    const zoom = getZoomFromBounds(projectBounds, width, height);
    const center = getCenterFromBounds(projectBounds);
    const viewport = { pitch, bearing, zoom, center };
    dispatch(updateMapViewport(projectId, viewport));
  };
}
