import {
  CuratedColumnSymbology,
  CUSTOM_THEME,
  DEFAULT_OPACITY,
  InterpolationTypes,
  PaintColorTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/public/dataset/us_parks_dataset': {
    feattype: [
      {
        type: SymbologyTypes.FILL,
        paint: {
          'fill-color': {
            property: 'feattype',
            type: PaintColorTypes.CATEGORICAL,
            stops: [
              {
                value: 'National park or forest',
                label: 'National Park or Forest',
                color: '#183128',
              },
              {
                value: 'State park or forest',
                label: 'State Park or Forest',
                color: '#375d3b',
              },
              { value: 'County park', label: 'County Park', color: '#abc8a4' },
              {
                value: 'Regional park',
                label: 'Regional Park',
                color: '#c4d7a4',
              },
              { value: 'Local park', label: 'Local Park', color: '#e1e6b9' },
            ],
          },
          'fill-opacity': DEFAULT_OPACITY,
        },
        display_hints: {
          scale: SymbologyScales.CATEGORICAL,
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          theme: CUSTOM_THEME,
        },
      },
    ],
  },
};

export default symbologies;
