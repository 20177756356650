import { Epic, ofType } from 'redux-observable';
import { merge as observableMerge, of as observableOf } from 'rxjs';
import { filter, mergeMap } from 'rxjs/operators';

import { getReportClearAction } from 'uf-api/api/report.service';
import { ModuleRunStatus } from 'uf/analysis';
import {
  SET_ANALYSIS_STATUS,
  SetAnalysisStatusAction,
} from 'uf/analysis/ActionTypes';
import { convertLegacyReport, LegacyReport } from 'uf/reporting';
import { clearScenarioReport } from 'uf/reporting/actions';
import { getReportingChartsSpecsForProject } from 'uf/reporting/selectors';

/**
 * When an analysis module finishes running, clear any reports
 * that use that module.
 */
export const clearScenarioOnAnalysisDone: Epic<
  SetAnalysisStatusAction,
  any,
  any,
  getReportClearAction
> = (action$, state$) => {
  return action$.pipe(
    ofType(SET_ANALYSIS_STATUS),
    filter(action => action.status === ModuleRunStatus.DONE),
    mergeMap(action => {
      const { projectId, moduleKey, scenarioId } = action;
      const specs = getReportingChartsSpecsForProject(state$.value, {
        projectId,
      });
      const affectedSpecs = specs.filter(spec =>
        reportIncludesModuleKey(spec, moduleKey),
      );
      const clearActions = affectedSpecs.map(spec => {
        const reportSpec = convertLegacyReport(spec);

        return observableOf(
          clearScenarioReport(projectId, scenarioId, reportSpec),
        );
      });
      return observableMerge(...clearActions);
    }),
  );
};

/**
 * Check if a report contains outputs for a particular analysis module.
 *
 * Technically reports can contain results from multiple analysis modules, so we
 * have to check for a match.
 */
function reportIncludesModuleKey(spec: LegacyReport, moduleKey: string) {
  return spec.charts.some(chart =>
    chart.series.some(
      series => series.source === 'analysis' && moduleKey === series.sourceKey,
    ),
  );
}

export default clearScenarioOnAnalysisDone;
