import {
  CuratedColumnSymbology,
  CUSTOM_THEME,
  DEFAULT_OPACITY,
  InterpolationTypes,
  PaintColorTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/calthorpeanalytics/dataset/corelogic_county_coverage_summary': {
    coverage: [
      {
        type: SymbologyTypes.FILL,
        paint: {
          'fill-color': {
            property: 'coverage',
            type: PaintColorTypes.CATEGORICAL,
            stops: [
              { value: 'Full', color: '#83b379' },
              { value: 'Partial', color: '#7fbcc9' },
              { value: 'Selection', color: '#f5d56c' },
            ],
          },
          'fill-opacity': DEFAULT_OPACITY,
        },
        display_hints: {
          scale: SymbologyScales.CATEGORICAL,
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          theme: CUSTOM_THEME,
        },
      },
      {
        type: SymbologyTypes.LINE,
        paint: {
          'line-color': {
            property: 'coverage',
            type: PaintColorTypes.CATEGORICAL,
            stops: [
              { value: 'Full', color: '#83b379' },
              { value: 'Partial', color: '#7fbcc9' },
              { value: 'Selection', color: '#f5d56c' },
            ],
          },
          'line-opacity': DEFAULT_OPACITY,
        },
        display_hints: {
          scale: SymbologyScales.CATEGORICAL,
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          theme: CUSTOM_THEME,
        },
      },
    ],
  },
};

export default symbologies;
