import { shallowEqual } from 'recompose';
import { encode } from 'rison';

// re-export for compatibility
export { decode as risonDecode } from 'rison';

/**
 * Recursively "drop" undefined from objects:
 *
 *  * In objects: keys whose values are `undefined` are dropped.
 *    e.g., `{ foo: undefined, bar: 1 }` becomes `{ bar: 1}`
 *  * In every other case, undefined becomes null, such as
 *    `[1, undefined, 2]` becomes `[1, null, 2]`
 *
 * @param obj
 */
function dropUndefined<T extends object | any[] | string | number>(obj: T): T {
  if (typeof obj === 'object') {
    return dropUndefinedObject(obj as object) as T;
  }
  if (obj === undefined) {
    return null;
  }
  return obj;
}

function dropUndefinedObject<T extends object>(obj: T): T {
  if (obj === null) {
    return obj;
  }
  if (Array.isArray(obj)) {
    return dropUndefinedArray(obj as any[]) as T;
  }
  const newObject = Object.fromEntries(
    Object.entries(obj)
      .filter(([key, value]) => value !== undefined)
      .map(([key, value]) => {
        return [key, dropUndefined(value)];
      }),
  );
  if (shallowEqual(obj, newObject)) {
    return obj;
  }
  return newObject as T;
}

function dropUndefinedArray<T>(obj: T[]): T[] {
  const newArray = obj.map(value => dropUndefined(value as any));
  if (shallowEqual(obj, newArray)) {
    return obj;
  }
  return newArray;
}

/**
 * Safely rison-encode an object.
 *
 * Since rison does not support `undefined`, this follows the same semantics as JSON.stringify:
 *
 *  * In objects: keys whose values are `undefined` are dropped.
 *    e.g., `{ foo: undefined, bar: 1 }` becomes `{ bar: 1}`
 *  * In every other case, undefined becomes null, such as
 *    `[1, undefined, 2]` becomes `[1, null, 2]`
 * @param obj
 */
export function risonEncode<T extends object | any[]>(obj: T) {
  return encode(dropUndefined(obj));
}
