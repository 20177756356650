import { Store } from 'redux';
import watch from 'redux-watch';

import { getActiveProjectId } from 'uf/app/selectors';
import { setReportRoute } from 'uf/reporting/actions/routing';
import {
  makeGetReportingContext,
  ReportingContext,
} from 'uf/reporting/selectors/routing';
import { SyncStateWithUrl } from 'uf/routing/state';

export const syncReportingStateWithUrl: SyncStateWithUrl = (store: Store) => {
  const getReportingContext = makeGetReportingContext();
  const watchBuiltFormCurrentBuiltFormState = watch(() => {
    const projectId = getActiveProjectId(store.getState());
    return getReportingContext(store.getState(), { projectId });
  });

  const unsubscribe = store.subscribe(
    watchBuiltFormCurrentBuiltFormState((newState: ReportingContext) => {
      const { projectId, scenarioIds, activeModuleKey, reportViewVisible } =
        newState;
      if (!reportViewVisible || !activeModuleKey) {
        // Note that for a missing activeModuleKey, we are relying on an epic to
        // resolve that for us.
        return;
      }
      store.dispatch(setReportRoute(activeModuleKey, projectId, scenarioIds));
    }),
  );
  return unsubscribe;
};
