import { Position, Spinner } from '@blueprintjs/core';
import { boundMethod } from 'autobind-decorator';
import * as d3Array from 'd3-array';
import _ from 'lodash';
import colors from 'material-colors';
import React, { PureComponent, ReactNode } from 'react';
import { Box } from 'react-layout-components';
import shallowEqual from 'recompose/shallowEqual';
import { t } from 'ttag';
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryContainer,
  VictoryStack,
  VictoryTooltip,
} from 'victory';
import { NumberOrCallback } from 'victory-core';

import {
  formatNumeric,
  FormatNumericOptions,
  formatPercent,
} from 'uf/base/formatting';
import { abbreviateContext, Formatter } from 'uf/base/numbers';
import { getUnitName, UnitLabelKeys } from 'uf/base/units';
import rootStyles from 'uf/styles/root.module.css';
import textStyles from 'uf/styles/text.module.css';
import { ChartLegend } from 'uf/ui/charts/ChartLegend/ChartLegend';
import { SeriesLabel } from 'uf/ui/charts/SeriesLabel/SeriesLabel';

import { BarChartBackgroundBar } from './BarChartBackgroundBar';
import { BarChartSeriesLabels } from './BarChartSeriesLabels';
import { BarChartTooltip } from './BarChartTooltip';
import { BarValueLabels } from './BarValueLabels';
import {
  AxisAdjustment,
  BAR_TOOLTIP_DISTANCE,
  DatumSummary,
  DEFAULT_BAR_WIDTH,
  DEFAULT_TOTALS_FONT_SIZE,
  SumFormat,
} from './chart';
import theme from './theme';

// TODO: support getColor
const blues = [
  colors.cyan[600],
  colors.cyan[700],
  colors.cyan[800],
  colors.cyan[900],
];

/**
 * Default line around bars.
 * TODO: get this from a theme.
 */
const DEFAULT_BAR_STROKE_COLOR = 'white';
const DEFAULT_BAR_STROKE_WIDTH = 1;
/**
 * Space above the chart.
 *
 * TODO: move to a prop, or part of a `padding` prop.
 */
const TOP_PADDING = 20;

/**
 * Space between each bar region.
 *
 * TODO: move this to a prop once we fully switch to the new bar chart format
 */
const SPACE_BETWEEN_BARS = 12;

/**
 * Space between the bar, and the label for that bar
 */
const SPACE_BETWEEN_BAR_AND_LABEL = 2;

// The only way to trigger zero labels
const NO_TICK_VALUES = [null];

const getFormatOptions = (base: number): Partial<FormatNumericOptions> => {
  if (base === 1) {
    return {
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
      fractionalPrecision: 0,
    };
  }

  // use default options
  return undefined;
};

export interface Props<T> {
  emptyChartContent?: ReactNode;
  forceShowLegend?: boolean;
  forceShowFullLabel?: boolean;
  isPercent?: boolean;
  data: T[];

  title?: ReactNode;
  subtitle?: ReactNode;

  width?: number;
  height?: number;
  transitionTime?: number;
  padding?: {
    left: number;
    right: number;
    top: number;
    bottom: number;
  };
  loading?: boolean;
  orientation: 'vertical' | 'horizontal';
  /**
   * A datum key distinguishes one datum from another.
   *
   * Thi function returns the unique key for the whole datum (i.e.
   * getDatumKey(datum) is the x value). For example if showing a bar for each
   * scenario, this is the scenario key.
   */
  getDatumKey: (datum: T, index?: number) => string;

  /**
   * A series key defines the different metrics along the y axis. In a regular
   * bar chart, there is only one series key. In a stack bar chart, there are
   * multiple series keys.
   *
   * This function returns an array of keys to use,
   * passed to d3Shape.stack().keys().
   */
  getSeriesKeys: (data: T[]) => string[];
  /**
   * Get the english label for the series key.
   */
  getSeriesLabel: (key: string) => string;

  /**
   * a function that takes (datum, key) and returns a value, passed to
   * d3Shape.stack.value()
   */
  getDatumValue: (datum: T, key: string) => number;
  /**
   * The user-visible label for the datum, for the x axis.
   * TODO: fix types so that `datum` can be a `T`
   */
  getLabel: (datum: any) => string;
  /**
   * The user-visible label for data in a data series, called with (datum, key)
   */
  getDatumLabel?: (datum: T, key: string) => any;
  /** Function to get a color based on the datum */
  getColor?: (datum: T, key: string) => any;
  /** To force a given bar width. `width` will be ignored */
  barWidth: number;

  /**
   * Used to format arbitrary numbers used in chart summaries.
   */
  formatSum?: (datum: T, sum: number) => string | SumFormat[];
  /**
   * Get the unit key for a datum. It is reasonable to just always return the
   * same string here if the chart is consistent,
   *
   * e.g. `getUnitKey={() => 'people'}`
   * */
  getUnitKey?: (datum: T, key: UnitLabelKeys) => UnitLabelKeys;
}

interface State<D> {
  domain: { y: [number, number] };
  keys: string[];
  formatter: Formatter;

  barExtents: DatumSummary<D>[];
  needsCentralAxis: boolean;
}
const HORIZONTAL_AXIS_HEIGHT_ESTIMATE = 20;
const nullLabel:
  | string[]
  | number[]
  | ((data: any) => string | number | string[] | number[]) = datum => null;
export class BarChart<D extends object> extends PureComponent<
  Props<D>,
  State<D>
> {
  static defaultProps: Partial<Props<any>> = {
    getDatumKey: d => d.key,
    getLabel: d => d.label || d.key,
    getColor: () => colors.blue[500],
    width: 450, // where does this come from, victory?
    padding: {
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
    },
    data: [],
    orientation: 'vertical',
    // We default to a single series, using just 'value'
    getSeriesKeys: () => ['value'],
    getSeriesLabel: key => key,
    getUnitKey: (datum, key) => null,
    getDatumValue: _.get,
    transitionTime: 150,
    barWidth: DEFAULT_BAR_WIDTH,
  };

  constructor(props: Props<D>) {
    super(props);
    const keys = this.getValidKeys();
    const { domain, needsCentralAxis } = this.getDomainSummary(keys);
    const [min, max] = domain.y;
    const barExtents = this.getBarExtents(min, max);
    const formatter = abbreviateContext(domain.y);

    this.state = {
      domain,
      formatter,
      keys,
      barExtents,
      needsCentralAxis,
    };
  }

  componentDidUpdate(prevProps: Props<D>) {
    if (!shallowEqual(this.props, prevProps)) {
      const keys = this.getValidKeys();
      const { domain, needsCentralAxis } = this.getDomainSummary(keys);
      const [min, max] = domain.y;
      const barExtents = this.getBarExtents(min, max);
      const formatter = abbreviateContext(domain.y);
      this.setState({
        domain,
        formatter,
        keys,
        barExtents,
        needsCentralAxis,
      });
    }
  }

  getBarExtents(min: number, max: number) {
    const { data, getDatumKey } = this.props;
    return data.map(
      (d): DatumSummary<D> => ({
        y: max,
        y0: min,
        x: getDatumKey(d),
        originalDatum: d,
      }),
    );
  }

  @boundMethod
  getDomainSummary(keys: string[]): {
    domain: { y: [number, number] };
    needsCentralAxis: boolean;
  } {
    const { isPercent, getDatumValue, data } = this.props;

    const seriesValues: number[][] = getSeriesValues(getDatumValue, keys, data);
    const needsCentralAxis =
      anySeriesHasPositiveAndNegativeNumbers(seriesValues);
    const sums = getSeriesDomainMaxes(seriesValues);
    let [min, max] = sums.length ? d3Array.extent(sums) : [0, 1];
    if (needsCentralAxis) {
      // pick the largest value sent by getSeriesDomainMaxes and set it as the min and max extents
      const maxAbsoluteExtentValue = Math.max.apply(null, sums);
      [min, max] = [-maxAbsoluteExtentValue, maxAbsoluteExtentValue];
    }
    let lowerBound = Math.min(0, min);
    let upperBound = Math.max(max, 0);
    if (isPercent) {
      lowerBound = Math.min(0, min);
      upperBound = Math.max(1, max);
    }
    if (lowerBound === upperBound) {
      // we have no sense of scale at this point, add something arbitrary:
      upperBound = lowerBound + 1;
    }
    return { domain: { y: [lowerBound, upperBound] }, needsCentralAxis };
  }

  @boundMethod
  formatDatum(datum: D, key: UnitLabelKeys) {
    const { getDatumValue, getDatumLabel, getUnitKey } = this.props;
    if (getDatumLabel) {
      return getDatumLabel(datum, key);
    }
    const unitKey = getUnitKey(datum, key);
    const value = getDatumValue(datum, key);
    return this.formatValue(value, key, unitKey);
  }

  @boundMethod
  formatDatumSummary(summary: DatumSummary<D>) {
    const { getColor, getDatumValue } = this.props;
    const { originalDatum: datum } = summary;
    const { keys } = this.state;

    // Even if the keys are valid for the whole chart, they may not be valid for this exact datum.
    const keysWithData = keys.filter(key =>
      Number.isFinite(getDatumValue(datum, key)),
    ) as UnitLabelKeys[];
    const sum = d3Array.sum(keysWithData.map(key => getDatumValue(datum, key)));
    const sumValue = this.formatValue(sum, null, null);
    return (
      <div className={textStyles.ellipsis}>
        {keysWithData.map(key => (
          <SeriesLabel
            key={key}
            color={getColor(datum, key)}
            label={this.formatDatum(datum, key)}
          />
        ))}
        <SeriesLabel label={t`Total: ${sumValue}`} />
      </div>
    );
  }

  @boundMethod
  formatValue(value: number, key: string, unitKey: UnitLabelKeys) {
    const { isPercent, forceShowFullLabel, getSeriesLabel } = this.props;
    if (isPercent) {
      return formatPercent(value);
    }

    const { formatter, keys } = this.state;

    const { value: newValue, abbreviation } = formatter(value);
    const formattedValue = formatNumeric(
      newValue,
      getFormatOptions(abbreviation.base),
    );
    const showFullLabel = (keys.length > 1 || forceShowFullLabel) && key;
    const seriesLabel = showFullLabel ? getSeriesLabel(key) : null;
    const prefix = seriesLabel ? `${seriesLabel}: ` : '';
    const suffix = abbreviation.shortAbbreviation
      ? ` ${abbreviation.shortAbbreviation}`
      : '';
    const units = unitKey ? ` ${getUnitName(unitKey)}` : '';
    return `${prefix}${formattedValue}${suffix}${units}`;
  }

  @boundMethod
  formatSum(datum: D, sum: number) {
    const { formatSum } = this.props;
    if (formatSum) {
      return formatSum(datum, sum);
    }
    return this.formatValue(sum, null, null);
  }

  @boundMethod
  getValidKeys() {
    const { getSeriesKeys, getDatumValue, data } = this.props;
    const allKeys = getSeriesKeys(data);

    return allKeys.filter(key =>
      data.some(datum => Number.isFinite(getDatumValue(datum, key))),
    );
  }

  /**
   * Get a function that produces a centerpoint along the y (dependent) axis, of
   * the current bar. All bars come with a `scale` that is relative to the
   * current position in the VictoryStack. Note that this value is always
   * negative because it's the number of pixels back from the end of the bar.
   *
   * @param key the series key
   */
  centerY(key: string): NumberOrCallback {
    const { getDatumValue } = this.props;
    return ({ datum, scale }) => {
      return (scale.y(0) - scale.y(getDatumValue(datum, key))) / 2;
    };
  }

  render() {
    const {
      data,
      title,
      subtitle,
      loading,
      orientation,
      getColor,
      getDatumKey,
      getLabel,
      getSeriesLabel,
      getDatumValue,
      height,
      width,
      barWidth,
      forceShowLegend,
      emptyChartContent,
    } = this.props;
    const { keys, domain, barExtents, formatter, needsCentralAxis } =
      this.state;

    // TODO: make `responsive` a prop so that this isn't automatic
    const horizontal = orientation === 'horizontal';
    const domainPadding = barWidth / 2;
    const barRegionWidth =
      barWidth +
      SPACE_BETWEEN_BARS +
      DEFAULT_TOTALS_FONT_SIZE +
      SPACE_BETWEEN_BAR_AND_LABEL;

    const padding = {
      left: 0,
      top: TOP_PADDING,
      bottom: 0,
      right: 0,
    };

    const { realWidth, realHeight, chartWidth } = getChartDimensions(
      horizontal,
      data.length,
      barRegionWidth,
      padding,
      height,
      width,
    );

    const colorScale = data.length
      ? keys.map(key => getColor(data[0], key))
      : blues;

    const axisAdjustment: AxisAdjustment = horizontal
      ? { dy: -(HORIZONTAL_AXIS_HEIGHT_ESTIMATE + DEFAULT_TOTALS_FONT_SIZE) }
      : { dx: 100 };

    const showTotalsTooltip = keys.length > 1;
    const hasValidChartData = !!data.length && !!keys.length;
    const showNoDataMessage = emptyChartContent && !hasValidChartData;
    const showLegend = keys.length > 1 || forceShowLegend;
    const { shortLabel: shortAbbreviation } = formatter.abbreviation;
    const titleContent: ReactNode = (
      <div className="h3">
        <strong>{title}</strong>
      </div>
    );
    /* In order to remove *all* axes, we have to add one and hide it with style */
    const ghostVictoryAxis: ReactNode = (
      <VictoryAxis
        dependentAxis
        tickValues={NO_TICK_VALUES}
        style={{
          axis: { stroke: 'none' },
        }}
      />
    );

    const VerticalAxis: ReactNode = (
      <VictoryAxis
        crossAxis
        domain={domain}
        style={{
          tickLabels: { fill: 'none' },
          axis: { stroke: 'grey' },
        }}
      />
    );

    const AxisComponent: ReactNode = needsCentralAxis
      ? VerticalAxis
      : ghostVictoryAxis;

    const backgroundBar: ReactNode = (
      <BarChartBackgroundBar
        invisible={showNoDataMessage}
        showTotalsTooltip={showTotalsTooltip}
        horizontal={horizontal}
        formatDatumSummary={this.formatDatumSummary}
        barExtents={barExtents}
        barWidth={barWidth}
      />
    );
    if (showNoDataMessage && !loading) {
      return (
        <Box column maxWidth={realWidth}>
          {title && titleContent}
          <Box className={rootStyles.mediumMarginVertical}>
            {emptyChartContent}
          </Box>
          {/* Introduced primarily with the aim to use the backgroundBar to maintain consistent width of cards */}
          <VictoryChart
            theme={theme}
            style={{ parent: { position: 'relative' } }}
            domain={domain}
            width={realWidth}
            height={realHeight}
            padding={padding}
            domainPadding={{ x: domainPadding, y: 0 }}
            containerComponent={<VictoryContainer responsive={false} />}
            horizontal={horizontal}>
            {ghostVictoryAxis}
            {backgroundBar}
          </VictoryChart>
        </Box>
      );
    }
    return (
      <Box column maxWidth={realWidth}>
        {title && titleContent}
        {(subtitle || shortAbbreviation) && (
          <h4 className={textStyles.mutedText}>
            {subtitle}{' '}
            {formatter.abbreviation.base !== 1
              ? `(${formatter.abbreviation.shortLabel})`
              : null}
          </h4>
        )}
        {showLegend && (
          <ChartLegend
            data={data}
            getSeriesLabel={getSeriesLabel}
            seriesKeys={keys}
            getColor={getColor}
          />
        )}
        <VictoryChart
          theme={theme}
          style={{ parent: { position: 'relative' } }}
          domain={domain}
          width={realWidth}
          height={realHeight}
          padding={padding}
          domainPadding={{ x: domainPadding, y: 0 }}
          containerComponent={<VictoryContainer responsive={false} />}
          horizontal={horizontal}>
          {!loading && (
            <BarChartSeriesLabels
              data={data}
              getDatumKey={getDatumKey}
              getLabel={getLabel}
              fontSize={DEFAULT_TOTALS_FONT_SIZE}
              rightPadding={72}
              axisAdjustment={axisAdjustment}
            />
          )}
          {/* Represents the value seen at the right end of the bar chart */}
          {hasValidChartData && !needsCentralAxis && (
            <BarValueLabels
              data={data}
              getDatumKey={getDatumKey}
              keys={keys}
              getDatumValue={getDatumValue}
              horizontal={horizontal}
              chartWidth={chartWidth}
              axisAdjustment={axisAdjustment}
              formatSum={this.formatSum}
              fontSize={DEFAULT_TOTALS_FONT_SIZE}
              fontFamily={'Roboto Mono'}
            />
          )}
          {!loading && backgroundBar}
          {/* The axis needs to sit behind the VictoryStack so that the tooltips for
          the bars aren't blocked by the label text for the axis */}
          {AxisComponent}
          {/* Actual bars for data */}
          {hasValidChartData && (
            <VictoryStack
              colorScale={colorScale}
              style={{ data: { width: barWidth } }}>
              {keys.map(key => (
                <VictoryBar
                  labelComponent={
                    <VictoryTooltip
                      renderInPortal={false}
                      dx={horizontal ? this.centerY(key) : 0}
                      dy={horizontal ? 0 : BAR_TOOLTIP_DISTANCE}
                      flyoutComponent={
                        <BarChartTooltip
                          position={Position.TOP}
                          getDatumLabel={this.formatDatum}
                          seriesKey={key}
                        />
                      }
                    />
                  }
                  labels={nullLabel}
                  key={key}
                  // TODO: Figure out why order is being reversed inside the stack?
                  data={data.slice().reverse()}
                  style={{
                    data: {
                      fill: d => getColor(d.datum, key),
                      stroke: DEFAULT_BAR_STROKE_COLOR,
                      strokeWidth: DEFAULT_BAR_STROKE_WIDTH,
                    },
                    labels: {
                      fill: 'none',
                    },
                  }}
                  x={d => getDatumKey(d)}
                  y={d => getDatumValue(d, key)}
                />
              ))}
            </VictoryStack>
          )}
          {loading && (
            <g>
              <foreignObject width={realWidth} height={realHeight}>
                <Spinner className={rootStyles.fitHeight} />
              </foreignObject>
            </g>
          )}
        </VictoryChart>
      </Box>
    );
  }
}

function getSeriesDomainMaxes(seriesValues: number[][]) {
  return seriesValues
    .map(seriesValue => {
      /* since some series might be a combinatinons of positive and negative
      numbers while others in the data object might have only positive or negative numbers */
      return hasPositiveAndNegativeValues(seriesValue)
        ? d3Array.max(seriesValue.map(value => Math.abs(value)))
        : d3Array.sum(seriesValue);
    })
    .reverse();
}

function anySeriesHasPositiveAndNegativeNumbers(seriesValues: number[][]) {
  return seriesValues.some(seriesValue =>
    hasPositiveAndNegativeValues(seriesValue),
  );
}

function hasPositiveAndNegativeValues(seriesValue: number[]) {
  const hasNegativeValues = seriesValue.some(value => value < 0);
  const hasPositiveValues = seriesValue.some(value => value > 0);
  return hasNegativeValues && hasPositiveValues;
}

function getSeriesValues<D extends object>(
  getDatumValue: (datum: D, key: string) => number,
  keys: string[],
  data: D[],
) {
  return data.map(datum => keys.map(key => getDatumValue(datum, key)));
}

function getChartDimensions(
  horizontal: boolean,
  count: number,
  barRegionWidth: number,
  padding: { left: number; top: number; bottom: number; right: number },
  height: number,
  width: number,
) {
  const realHeight = horizontal ? count * barRegionWidth : height;
  const realWidth = !horizontal
    ? count * barRegionWidth + padding.left + padding.right
    : width;
  const chartWidth = realWidth - padding.left - padding.right;
  return { realWidth, realHeight, chartWidth };
}
