import {
  CuratedColumnSymbology,
  InterpolationTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/public/dataset/hifld_us_private_schools': {
    status: [
      {
        display_hints: {
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          scale: SymbologyScales.CATEGORICAL,
          theme: 'custom',
        },
        paint: {
          'circle-color': {
            default: '#2ca02c',
            property: 'status',
            stops: [
              {
                color: '#68bc00',
                value: '1',
              },
              {
                color: '#fcdc00',
                value: '2',
              },
            ],
            type: 'categorical',
          },
          'circle-opacity': 0.6,
          'circle-stroke-color': 'transparent',
        },
        type: SymbologyTypes.CIRCLE,
      },
    ],
  },
};

export default symbologies;
