import { createSelector } from 'reselect';

import { makeGetFromPropsSelector } from 'uf/base/selector';
import { MapState } from 'uf/map/state';

function getMapState(state): Record<string, MapState> {
  return state.map;
}

function makeGetMapState() {
  return createSelector(
    getMapState,
    makeGetFromPropsSelector<string, 'mapKey'>('mapKey'),
    (mapState, mapKey): MapState => mapState[mapKey],
  );
}

export const makeGetMapStyle = makeGetMapProperty('style');
export const makeGetRequestedMapBounds = makeGetMapProperty('requestedBounds');
export const makeGetMapMaxBounds = makeGetMapProperty('maxBounds');
export const makeGetMapBounds = makeGetMapProperty('bounds');
export const makeGetMapPitch = makeGetMapProperty('pitch');
export const makeGetMapBearing = makeGetMapProperty('bearing');
export const makeGetMapCenter = makeGetMapProperty('center');
export const makeGetMapScale = makeGetMapProperty('scale');
export const makeGetMapZoom = makeGetMapProperty('zoom');
export const makeGetMapStatus = makeGetMapProperty('status');
export const makeGetMapbox = makeGetMapProperty('mapbox');

export function makeSimpleGetMapbox(mapKey: string) {
  return state => {
    return (state[mapKey].map as MapState).mapbox;
  };
}

function makeGetMapProperty<K extends keyof MapState>(propertyKey: K) {
  return () =>
    createSelector(
      makeGetMapState(),
      (mapState): MapState[K] => mapState[propertyKey],
    );
}
