import React, { FunctionComponent, FormEvent, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { t } from 'ttag';
import classNames from 'classnames';
import { Button, InputGroup } from '@blueprintjs/core';

import rootStyles from 'uf/styles/root.module.css';
import textStyles from 'uf/styles/text.module.css';
import { getSearchInfo } from 'uf/mapi/selectors';
import { beginSearch, clearSearch, gotoFeature } from 'uf/mapi/actions';
import useBindAction from 'uf/ui/base/useBindAction/useBindAction';
import { logEvent } from 'uf/logging';
import { events } from 'uf/ui/explore/logging';
import { getExploreMapCenter } from 'uf/explore/selectors/map';
import { getActiveProjectId } from 'uf/app/selectors';

const clearButtonClass = classNames(
  rootStyles.noPadding,
  rootStyles.thinMarginLeft,
  textStyles.activeColor,
);

const searchButtonClass = classNames(
  rootStyles.noPadding,
  rootStyles.thinMarginLeft,
  textStyles.activeColor,
);

function doSearchReceiver(event: FormEvent<HTMLInputElement>) {
  const query = event.currentTarget.value;
  return (dispatch, getState) => {
    logEvent(events.INTENT_TO_SEARCH);
    const state = getState();
    const projectId = getActiveProjectId(state);
    const proximity = getExploreMapCenter(state, {
      projectId,
    });
    return beginSearch(query, proximity)(dispatch, getState, {});
  };
}

interface GeoSearchboxInputProps {
  className?: string;
}

const GeoSearchboxInput: FunctionComponent<GeoSearchboxInputProps> = props => {
  const { className } = props;
  const { query, results } = useSelector(getSearchInfo);
  const firstFeature = results?.features[0] || null;
  const doSearch = useBindAction(doSearchReceiver);
  const bindGoto = useBindAction(gotoFeature);
  const gotoFirstFeature = useCallback(
    e =>
      firstFeature
        ? [logEvent(events.SEARCH_MATCH_SELECTED), bindGoto(firstFeature)]
        : null,
    [bindGoto, firstFeature],
  );
  const doClear = useBindAction(e => clearSearch());
  const clearButton = query ? (
    <Button
      name="clearSearch"
      minimal
      small
      className={clearButtonClass}
      onClick={doClear}
      icon="cross"
    />
  ) : null;
  const searchButton = (
    <Button
      name="search"
      minimal
      small
      className={searchButtonClass}
      onClick={gotoFirstFeature}
      icon="search"
    />
  );
  return (
    <InputGroup
      name="geosearch"
      className={className}
      leftElement={searchButton}
      placeholder={t`Search`}
      autoComplete="off"
      value={query}
      onChange={doSearch}
      rightElement={clearButton}
    />
  );
};

export default GeoSearchboxInput;
