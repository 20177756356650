/** Leading-case style enum for builtform types, like `'BuildingType'`, etc */
export enum BuiltFormTypes {
  BuildingType = 'BuildingType',
  UrbanPlacetype = 'UrbanPlacetype',

  /**
   * @deprecated
   */
  DeprecatedBuildingType = 'DeprecatedBuildingType',
  /**
   * @deprecated
   */
  DeprecatedUrbanPlacetype = 'DeprecatedUrbanPlacetype',
}

export function isPlaceType(builtFormType: string) {
  return builtFormType === 'UrbanPlacetype';
}
