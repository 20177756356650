import MapiClient from '@mapbox/mapbox-sdk/lib/classes/mapi-client';
import _ from 'lodash';
import { AnyAction } from 'redux';
import {
  ActionsObservable,
  combineEpics as defaultCombineEpics,
  Epic,
  StateObservable,
} from 'redux-observable';
import {
  EMPTY,
  GroupedObservable,
  Observable,
  of,
  OperatorFunction,
} from 'rxjs';
import {
  catchError,
  delay,
  filter,
  groupBy,
  ignoreElements,
  map,
  repeat,
  timeoutWith,
} from 'rxjs/operators';
import { animationFrame } from 'rxjs/scheduler/animationFrame';

import { SwaggerClient } from 'uf/base/xhr';

type AnyEpic = Epic<AnyAction, any>;

export interface EpicDependecies {
  client: SwaggerClient;
  mapi: MapiClient;
}

/**
 * Wrapper for epics, to use RxJS's catch/finally operators to report
 * when the epics explode, or the entire observable pipeline shuts
 * down.
 */
function debugEpic<E extends Epic<AnyAction, any> = Epic<AnyAction, any>>(
  debugName: string,
) {
  return (epic: E) =>
    (
      action$: ActionsObservable<any>,
      state$: StateObservable<any>,
      dependencies: any,
    ): Observable<any> =>
      epic(action$, state$, dependencies).pipe(
        filter(returnAction => {
          if (returnAction === null || returnAction === undefined) {
            Promise.reject(
              new Error(`The Epic ${debugName} emitted null action`),
            );
            return false;
          }
          return true;
        }),
        catchError((error, source) => {
          console.error(
            `The epic ${debugName} caught an error.`,
            error,
            source,
          );
          Promise.reject(error);
          return source;
        }),
      );
}

/**
 * This is a functional replacement for combineEpics,
 * but with error reporting
 */
export function combineEpics<E extends AnyEpic = AnyEpic>(
  epicObj: Record<string, E> | E,
  debugName: string,
): AnyEpic {
  if (typeof epicObj === 'function') {
    return debugEpic(debugName)(epicObj);
  }

  return defaultCombineEpics(
    ...Object.entries(epicObj)
      .map(([name, epics]) => combineEpics(epics, `${debugName}/${name}`))
      .map(debugEpic(debugName)),
  );
}

/**
 * Compare two actions for matching properties. Useful for things like
 * `switchMap()`, which require you to match up actions when you switch
 * streams.
 *
 * Example:
 *
 * (action$) => action$
 *   .ofType(FOO_ACTION)
 *   .switchMap(fooAction =>
 *     action$
 *       .ofType(BAR_ACTION)
 *       .filter(barAction => {
 *         const matcher = makeMatchActions(['layerId', 'columnKey'], ['layerId', 'columnKey']);
 *         return matcher(fooAction, barAction);
 *       });
 */
export function makeMatchActions<T1 extends AnyAction, T2 extends AnyAction>(
  keys1: (keyof T1)[],
  keys2: (keyof T2)[],
) {
  return (action1: T1, action2: T2) =>
    _.zip(keys1, keys2).every(([key1, key2]) =>
      _.isEqual(action1?.[key1], action2?.[key2]),
    );
}

/**
 * A universal action type to signal that epics are done, and it is time to
 * close the stream
 */
export const END_OF_ACTIONS = 'uf/END_OF_ACTIONS';
/**
 * This is a special action that completes the epic, allowing it to free up
 * memory.
 * */
export const CloseEpicStream = {
  type: END_OF_ACTIONS,
} as const;

const defaultGroupTimeout = 15000;

/**
 * An operator like rxjs's `groupBy` but with a timeout so that groups can be
 * cleaned up after a time.
 *
 * Reimplemented from https://youtu.be/hsr4ArAsOL4
 *
 * @param makeKey A function that maps an item (action) to a string key
 * @param timeout The number of milliseconds until the group auto-closes.
 */
export function groupByWithTimeout<T, K extends string | number>(
  makeKey: (item: T) => K,
  timeout: number = defaultGroupTimeout,
): OperatorFunction<T, GroupedObservable<K, T>> {
  return groupBy(
    makeKey,
    action => action,
    actionsByKey$ =>
      actionsByKey$.pipe(timeoutWith(timeout, EMPTY), ignoreElements()),
  );
}

/**
 * Creates an observable that emits every `interval` seconds, but only when the
 * browser is visible.
 *
 * @param interval Time between output, in milliseconds
 */
export function makeIntervalTimer(interval: number) {
  return of(animationFrame.now(), animationFrame).pipe(
    map(start => animationFrame.now() - start),
    delay(interval),
    repeat(),
  );
}
