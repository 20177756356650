import { createSelector } from 'reselect';
import { EMPTY_ARRAY, EMPTY_OBJECT } from 'uf/base';
import { makeGetFromPropsSelector } from 'uf/base/selector';
import { hasError, isLoaded, isLoading } from 'uf/data/dataState';
import { UFState } from 'uf/state';
import {
  RawRowData,
  RawSearchResult,
  SearchState,
  UnresolvedSearchResult,
} from './state';

// Don't use this directly, this is exported only so that helpers can use it.
// TODO: stop exporting this once the explore/layer module figures out a better way to resolve
// a search key from a selector.
export function getSearchState(state: UFState): SearchState {
  return state.search || (EMPTY_OBJECT as SearchState);
}

// TODO: stop exporting this once the explore/layer module figures out a better way to resolve
// a search key from a selector.
export function resolveSearchKey(
  searchState: SearchState,
  searchKey: string,
): Record<string, string>[] {
  const queryState = getQueryState(searchState, searchKey);
  const ids = resultOrEmpty(queryState);
  if (
    !queryState ||
    !(queryState.datasetId in searchState.datasets) ||
    !ids.length
  ) {
    return EMPTY_ARRAY;
  }
  const data = resolveIds(searchState.datasets[queryState.datasetId], ids);
  return data;
}

/*
 * This generates a helper selector to resolve the common case where a React
 * component uses the specific prop 'searchKey' to describe its results.
 *
 * This is useful when the searchKey is available in props.searchKey:
 *
 * @connect(
 *   (state, props) => ({ searchResult: getSearchResults(state, props) })
 * )
 * ...
 * // in your component:
 *    {isLoaded(searchResult) ? <DataList items={searchResult.items} /> : <Spinner/>}
 *
 * Returns a full "query state" object, which is an object with these properties:
 * `loading` - if the query is currently loading.
 * `loaded` - if the query is already loaded.
 * `items` - the resolved items, depending if the search is loaded or not.
 */
export function makeGetSearchResultsWithState() {
  return createSelector(
    getSearchState,
    makeGetFromPropsSelector<string, 'searchKey'>('searchKey'),
    (searchState, searchKey): RawSearchResult => {
      const queryState = getQueryState(searchState, searchKey);
      const resolvedItems = resolveSearchKey(searchState, searchKey);
      if (isLoading(queryState)) {
        return {
          ...queryState,
          data: resolvedItems,
          items: resolvedItems,
          promise: queryState.promise.then(
            // Fallback to response if resolver doesn't yet return values
            (response: any) => {
              const resolvedSearch = resolveSearchKey(searchState, searchKey);
              return resolvedSearch?.length ? resolvedSearch : response.items;
            },
          ),
        };
      }
      if (hasError(queryState)) {
        return {
          ...queryState,
          data: resolvedItems,
          items: resolvedItems,
          promise: Promise.reject(queryState.error),
        };
      }
      return {
        ...queryState,
        data: resolvedItems,
        items: resolvedItems,
        // already resolved
        promise: Promise.resolve(resolvedItems),
      };
    },
  );
}

function resultOrEmpty(queryState: UnresolvedSearchResult): string[] {
  if (isLoaded(queryState)) {
    return queryState.data || EMPTY_ARRAY;
  }
  return EMPTY_ARRAY;
}

function getQueryState(searchState: SearchState, searchKey: string) {
  return searchState?.searches?.[searchKey];
}

/**
 * A helper function to simply resolve ids out of a dataset.
 */
function resolveIds(
  dataset: Record<string, RawRowData>,
  ids: string[],
): RawRowData[] {
  return ids.map(id => dataset[id]);
}
