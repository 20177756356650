import { Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { ClientResponse } from 'uf-api';
import {
  getReportActionTypes,
  getReportClearAction,
  getReportXlsActionTypes,
} from 'uf-api/api/report.service';
import { ReportResults, ReportSpec } from 'uf-api/model/models';
import { getData, hasError, isLoading, shouldLoad } from 'uf/data/dataState';
import { dispatchAsyncAction } from 'uf/data/loader';
import { ProjectId } from 'uf/projects';
import { makeScenarioReportKey } from 'uf/reporting';
import {
  SET_ACTIVE_MODULE,
  SET_SCENARIOS_VISIBLE,
  SetActiveModuleAction,
  SetScenariosVisibleAction,
} from 'uf/reporting/ActionTypes';
import {
  exportReport as exportReportAPI,
  loadReport as loadReportAPI,
} from 'uf/reporting/apis';
import { makeGetScenarioReportState } from 'uf/reporting/selectors';
import { ScenarioId } from 'uf/scenarios';

export function setActiveModule(
  projectId: ProjectId,
  activeModuleKey: string,
): SetActiveModuleAction {
  return {
    type: SET_ACTIVE_MODULE,
    projectId,
    value: activeModuleKey,
  };
}

export function setScenariosVisible(
  projectId: ProjectId,
  scenarioIds: string[],
  visible: boolean[],
): SetScenariosVisibleAction {
  return {
    type: SET_SCENARIOS_VISIBLE,
    projectId,
    scenarioIds,
    visible,
  };
}

/**
 * Load the reporting chart data for a single scenario.
 *
 * NOTE: If you want to render a chart that display multiple scenario bars, then you will need to
 * call this once for each scenario.
 *
 */
function loadScenarioReport(
  projectId: ProjectId,
  scenarioId: ScenarioId,
  spec: ReportSpec,
): ThunkAction<Promise<ReportResults>, any, any, any> {
  return dispatchAsyncAction(
    getReportActionTypes,
    loadReportAPI(projectId, scenarioId, spec),
    {
      key: makeScenarioReportKey(projectId, scenarioId, spec),
      extra: {
        projectId,
        scenarioId,
        spec,
      },
    },
  );
}

export function clearScenarioReport(
  projectId: ProjectId,
  scenarioId: ScenarioId,
  reportSpec: ReportSpec,
): getReportClearAction {
  return {
    type: getReportActionTypes.CLEAR,
    key: makeScenarioReportKey(projectId, scenarioId, reportSpec),
  };
}

/**
 * Ensure the reporting chart data for a single scenario.
 *
 * NOTE: If you want to render a chart that display multiple scenario bars, then you will need to
 * call this once for each scenario.
 *
 * @param projectId
 * @param scenarioId
 * @param spec
 */
export function ensureScenarioReport(
  projectId: ProjectId,
  scenarioId: ScenarioId,
  spec: ReportSpec,
): ThunkAction<Promise<ReportResults>, any, any, any> {
  const getReportState = makeGetScenarioReportState();
  const params = {
    projectId,
    scenarioId,
    spec,
    analysisModuleKey: spec.key,
  };

  return (dispatch: Dispatch, getState) => {
    const reportState = getReportState(getState(), params);
    if (shouldLoad(reportState)) {
      return dispatch(loadScenarioReport(projectId, scenarioId, spec));
    }

    if (isLoading(reportState)) {
      return reportState.promise;
    }

    if (hasError(reportState)) {
      return Promise.reject<ReportResults>(reportState.error);
    }

    // fully loaded, no errors.
    return Promise.resolve(getData(reportState));
  };
}

export function exportReport(
  projectId: ProjectId,
  scenarioIds: string[],
  spec: ReportSpec,
): ThunkAction<Promise<ClientResponse<Blob>>, any, any, any> {
  return dispatchAsyncAction(
    getReportXlsActionTypes,
    exportReportAPI(projectId, scenarioIds, spec),
    {
      projectId,
      scenarioIds,
      key: makeScenarioReportKey(projectId, scenarioIds.join(','), spec),
    },
  );
}
