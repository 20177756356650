import { RefObject } from 'react';

// return true if the child is 100% contained by the container
export function containsNode(
  containerNode: Element,
  childNode: Element,
): boolean {
  const containerRect = containerNode.getBoundingClientRect();
  const childRect = childNode.getBoundingClientRect();

  // floor the values to prevent javascript rounding errors
  const topOut = Math.floor(childRect.top) < Math.floor(containerRect.top);
  const leftOut = Math.floor(childRect.left) < Math.floor(containerRect.left);
  const bottomOut =
    Math.floor(childRect.bottom) > Math.floor(containerRect.bottom);
  const rightOut =
    Math.floor(childRect.right) > Math.floor(containerRect.right);

  if (topOut || leftOut || bottomOut || rightOut) {
    return false;
  }

  return true;
}

export function getRect<T extends HTMLElement>(ref: RefObject<T>): ClientRect {
  if (!ref) {
    return null;
  }

  const current = ref.current;

  if (!current) {
    return null;
  }

  return current.getBoundingClientRect();
}

/**
 * Determines if an element is currently overflowing.
 *
 * It is often necessary to descend a few levels in the DOM, depending on which
 * element you have access to, so use `depth`. This will short-circuit as soon
 * as it finds an element that overflows. However, use this cautiously as it can
 * be expensive to do a breadth-first search for overflow, especially if you
 * rarely find the overflow.
 *
 * @param element The element to check for overflow.
 * @param depth the number of child levels to descend (defaults to 0)
 */
export function elementOverflows(element: Element, depth = 0) {
  const { clientHeight, clientWidth, scrollHeight, scrollWidth } = element;
  if (clientWidth < scrollWidth || clientHeight < scrollHeight) {
    return true;
  }
  if (depth > 0) {
    return elementsOverflow(
      Array.from(element.childNodes) as Element[],
      depth - 1,
    );
  }
  return false;
}

/**
 * Search an array of elements to see if something overflows. this is most
 * useful with a `ResizeObserver`:
 *
 * ```
 *   n = new ResizeObserver(entries => {
 *      const overflows = elementsOverflow(entries.map(e => e.target));
 *   })
 * ```
 *
 * @see elementOverflows
 *
 * @param elements The elements to check for overflow.
 * @param depth the number of child levels to descend (defaults to 0)
 */
export function elementsOverflow(elements: Element[], depth = 0) {
  return elements.some(element => elementOverflows(element, depth));
}
