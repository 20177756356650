import { createSelector } from 'reselect';

import { UFState } from 'uf/state';

import { Persistence } from './state';

const EmptyState: Persistence = {
  saveEnabled: true,
  loadEnabled: true,
};

export const getShouldLoadPersistence = createSelector(
  (state: UFState): Persistence => state.persistence || EmptyState,
  persistence => persistence.loadEnabled,
);
export const getShouldSavePersistence = createSelector(
  (state: UFState): Persistence => state.persistence || EmptyState,
  persistence => persistence.saveEnabled,
);
