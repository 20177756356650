import React from 'react';
import defaultIcon from 'uf/assets/default-project-icon.svg';

const DEFAULT_ICON_SIZE = 12;

interface OwnProps {
  label?: string;
  source?: string;
  size?: number;
}

const ProjectTypeIcon = ({
  label = 'default',
  source = defaultIcon,
  size = DEFAULT_ICON_SIZE,
}: OwnProps) => {
  return (
    <img
      src={source}
      alt={`${label}-icon`}
      height={size}
      width={size}
      // Min-width prevents unintended resizing when paired with text elements
      style={{ minWidth: size }}
    />
  );
};

export default ProjectTypeIcon;
