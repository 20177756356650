import { createSelector } from 'reselect';
import { t } from 'ttag';

import { LayerStats } from 'uf-api';
import { getCanonicalParameters } from 'uf/base/api';
import { makeGetFromPropsSelector } from 'uf/base/selector';
import { DataState, getData, hasError, isLoading } from 'uf/data/dataState';
import { makeReduxDataKey } from 'uf/data/helpers';
import { ColumnKey, LayerId, LayerVersion } from 'uf/layers';
import { getColumnKey } from 'uf/layers/geometryKey';
import { getLayersStatsStates } from 'uf/layers/selectors/stats';
import {
  makeGetLayerVersion,
  makeGetLayerVersionGetter,
} from 'uf/layers/selectors/versions';
import { ProjectId } from 'uf/projects';
import { DivideByColumnKey } from 'uf/symbology/divideByColumn';
import {
  makeGetDivideByColumn,
  makeGetDivideByColumnGetter,
} from 'uf/symbology/selectors/divideByColumn';
import {
  getCategories,
  getStatsParams,
  hasCategoricalStats,
} from 'uf/symbology/stats';

export function makeGetSymbologyStatsState() {
  return createSelector(
    makeGetFromPropsSelector<LayerId, 'layerId'>('layerId'),
    makeGetFromPropsSelector<ColumnKey, 'columnKey'>('columnKey'),
    makeGetDivideByColumn(),
    makeGetLayerVersion(),
    getLayersStatsStates,
    (layerId, columnKey, divideByColumn, layerVersion, layerStatsStates) =>
      getSymbologyStatsState(
        layerId,
        columnKey,
        divideByColumn,
        layerVersion,
        layerStatsStates,
      ),
  );
}

export function makeGetSymbologyStatsStateGetter() {
  return createSelector(
    makeGetLayerVersionGetter(),
    makeGetDivideByColumnGetter(),
    getLayersStatsStates,
    (getLayerVersion, getDivideByColumn, layerStatsStates) =>
      (projectId: ProjectId, layerId: LayerId, columnKey: ColumnKey) => {
        const divideByColumn = getDivideByColumn(projectId, layerId, columnKey);
        const layerVersion = getLayerVersion(layerId);
        return getSymbologyStatsState(
          layerId,
          columnKey,
          divideByColumn,
          layerVersion,
          layerStatsStates,
        );
      },
  );
}

function getSymbologyStatsState(
  layerId: LayerId,
  columnKey: ColumnKey,
  divideByColumn: DivideByColumnKey,
  layerVersion: LayerVersion,
  layerStatsStates: Record<string, DataState<LayerStats>>,
): DataState<LayerStats> {
  // We may not have a columnKey yet, as many consumers pass the
  // activeColumnKey which is set asyncronously
  if (!columnKey) {
    return null;
  }

  const statsParams = getStatsParams(
    getColumnKey(columnKey),
    layerVersion,
    divideByColumn,
  );

  const key = makeReduxDataKey(layerId, getCanonicalParameters(statsParams));

  const statsState = layerStatsStates[key];
  const layerStats = getData(statsState, null);

  // Fresh load or actual errors should be returned
  if ((isLoading(statsState) && !layerStats) || hasError(statsState)) {
    return statsState;
  }

  // TODO: Share this error logic with other stats selectors that deal in
  // single column lookups
  let error: string;
  if (hasCategoricalStats(layerStats, columnKey)) {
    const categories = getCategories(layerStats, columnKey);
    if (!categories) {
      // If values is missing entirely, then it was left out because there were
      // too many.
      error = t`This categorical column includes too many unique values to display.`;
    } else if (!categories.length) {
      // Empty values is not an api error, but we should treat it as one
      error = t`No values to display.`;
    }
  }

  return error ? { ...statsState, error } : statsState;
}
