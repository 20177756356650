import _ from 'lodash';
import { createSelector } from 'reselect';

import {
  OrganizationProjectsListItem,
  ProjectMetadata,
  ScenarioMetadata,
  Task,
} from 'uf-api/model/models';
import {
  makeGetApiArrayResults,
  makeGetApiObjectGetter,
  makeGetApiObjectResults,
  makeGetApiObjectState,
} from 'uf/api/selectors';
import { EMPTY_ARRAY, EMPTY_OBJECT } from 'uf/base';
import { compareCaseInsensitive } from 'uf/base/formatting';
import { UFLngLatBounds } from 'uf/base/map';
import { makeGetFromPropsSelector } from 'uf/base/selector';
import { DataState, EmptyState, getData, isLoaded } from 'uf/data/dataState';
import {
  getScenarios,
  makeProjectKey,
  PROJECT_LIST_REDUX_KEY,
  ProjectId,
} from 'uf/projects';
import { ProjectsState } from 'uf/projects/state';
import UpdateStatusType from 'uf/projects/StatusTypes';
import { ScenarioId } from 'uf/scenarios';
import { UFState } from 'uf/state';
import { EmptyTaskMessage, TaskMessage } from 'uf/tasks/MessageTypes';

/*
 * Top-level properties
 */
export function getProjectsState(state: UFState): ProjectsState {
  return state?.projects || (EMPTY_OBJECT as ProjectsState);
}

export const getCreateScenarioState = createSelector(
  makeGetApiArrayResults('project', 'create_scenario'),
  (scenarioStates): DataState<unknown> => _.last(scenarioStates) || EmptyState,
);

export const getProjectsListState = createSelector(
  makeGetApiObjectResults('project', 'get_projects_v2'),
  projects => projects[PROJECT_LIST_REDUX_KEY],
);

export function makeGetProjectBaseScenario() {
  return createSelector(
    makeGetProjectById(),
    (project: ProjectMetadata): ScenarioMetadata =>
      project.base_scenario || EMPTY_OBJECT,
  );
}

/*
 * Computed properties
 */
export const getProjectList = createSelector(
  getProjectsListState,
  projectList => {
    const organizations: OrganizationProjectsListItem[] =
      getData(projectList).items ?? EMPTY_ARRAY;
    return organizations.map(org => org.projects).flat();
  },
);

export const getProjectListByOrganization = createSelector(
  getProjectsListState,
  projectList => getData(projectList).items || EMPTY_ARRAY,
);

/**
 * Get a loading state for a project
 */
export function makeGetProjectState() {
  return makeGetApiObjectState('project', 'get_project', makeProjectKey);
}

export function makeGetProjectGetter() {
  return makeGetApiObjectGetter(
    'project',
    'get_project',
    (projectId: ProjectId) => projectId,
  );
}

// TODO: remove this, we should not need it
export const getAvailableProjectStates = makeGetApiObjectResults(
  'project',
  'get_project',
);
/**
 * Get project metadata for a project based on a prop (defaults to projectId)
 */
export function makeGetProjectById() {
  return createSelector(
    makeGetProjectState(),
    (projectState): ProjectMetadata => getData(projectState, EMPTY_OBJECT),
  );
}

export function makeGetScenarioIdFromProps() {
  return makeGetFromPropsSelector<ScenarioId, 'scenarioId'>('scenarioId');
}
/**
 * Get an list of scenarios for a project, sorted by name.
 */

// TODO: deprecate this in favor of makeGetProjectScenarios
export const getProjectScenarios = createSelector(
  makeGetProjectState(),
  (projectState): ScenarioMetadata[] => {
    if (!isLoaded(projectState)) {
      return EMPTY_ARRAY;
    }
    const project = getData(projectState);
    return getScenarios(project);
  },
);

const compareByName = compareCaseInsensitive<ScenarioMetadata>(
  scenario => scenario.name,
);

export function makeGetProjectScenarios() {
  const getProjectState = makeGetProjectState();
  return createSelector(
    getProjectState,
    (projectState: DataState<ProjectMetadata>): ScenarioMetadata[] => {
      if (!isLoaded(projectState)) {
        return EMPTY_ARRAY;
      }
      const project = getData(projectState);
      const scenarios = getScenarios(project);
      const sortedScenarios = [...scenarios].sort((s1, s2) => {
        if (s1.base_scenario) {
          return -1;
        }
        if (s2.base_scenario) {
          return 1;
        }
        return compareByName(s1, s2);
      });
      return sortedScenarios;
    },
  );
}

export function makeGetProjectScenarioIds() {
  return createSelector(makeGetProjectScenarios(), scenarios =>
    scenarios.map(({ full_path: fullPath }) => fullPath),
  );
}

export function makeGetProjectScenario() {
  const getScenarioId = makeGetScenarioIdFromProps();
  const selectScenarios = makeGetProjectScenarios();
  return createSelector(
    getScenarioId,
    selectScenarios,
    (scenarioId, scenarios) =>
      scenarios.find(scenario => scenario.full_path === scenarioId),
  );
}

export function makeGetScenarioById() {
  return createSelector(
    makeGetProjectScenarios(),
    makeGetFromPropsSelector<ScenarioId, 'scenarioId'>('scenarioId'),
    (scenarios, scenarioId): ScenarioMetadata => {
      if (!scenarioId) {
        return scenarios[0];
      }
      return _.find(scenarios, { full_path: scenarioId });
    },
  );
}

export function makeGetProjectBuiltFormsLibraryId() {
  return createSelector(makeGetProjectState(), projectState => {
    return getProjectBuiltFormsLibraryId(projectState);
  });
}

export function makeGetProjectBuiltFormsLibraryIdGetter() {
  return createSelector(
    makeGetProjectGetter(),
    getProjectState => (projectId: ProjectId) => {
      return getProjectBuiltFormsLibraryId(getProjectState(projectId));
    },
  );
}

function getProjectBuiltFormsLibraryId(
  projectState: DataState<ProjectMetadata>,
) {
  const project = getData(projectState, EMPTY_OBJECT);
  const { built_forms_library: libraryId } = project;
  return libraryId;
}

const getProjectUpdateStatus = createSelector(
  getProjectsState,
  (projectState): Record<string, TaskMessage> =>
    projectState.updateStatus || EMPTY_OBJECT,
);

export function makeGetProjectUpdateStatusType() {
  return createSelector(
    getProjectUpdateStatus,
    makeGetFromPropsSelector<ProjectId, 'projectId'>('projectId'),
    (updateStatus, projectId): string => {
      const projectStatus = updateStatus[projectId] || EmptyTaskMessage;
      // TODO: reconcile TaskStatuses with UpdateStatusType
      if ((projectStatus.status as string) !== UpdateStatusType.IN_PROGRESS) {
        // we only care about actively running jobs.
        return null;
      }
      const { info } = projectStatus;
      return info?.update_type;
    },
  );
}

export function makeGetAnalysisModuleInfoForProject() {
  return createSelector(
    makeGetProjectState(),
    makeGetFromPropsSelector<string, 'moduleKey'>('moduleKey'),
    (projectState, moduleKey) => {
      const project = getData(projectState);
      const { analysis_modules: analysisModules = [] } = project;
      return analysisModules.find(module => module.module_key === moduleKey);
    },
  );
}

export function makeGetScenarioInfoForProject() {
  return createSelector(
    makeGetProjectState(),
    makeGetFromPropsSelector<ScenarioId, 'scenarioId'>('scenarioId'),
    (
      projectState: DataState<ProjectMetadata>,
      scenarioId: ScenarioId,
    ): ScenarioMetadata => {
      const project = getData(projectState);
      const { base_scenario: baseScenario = {}, scenarios = [] } = project;
      return [baseScenario]
        .concat(scenarios)
        .find(scenario => scenario.full_path === scenarioId);
    },
  );
}

export function makeGetProjectBounds() {
  return createSelector(makeGetProjectById(), project => {
    if (_.isEmpty(project)) {
      return null;
    }
    const { project_map_bounds: projectMapBounds } = project;
    const bounds: UFLngLatBounds = [
      [projectMapBounds[0], projectMapBounds[1]],
      [projectMapBounds[2], projectMapBounds[3]],
    ];

    return bounds;
  });
}

export function makeGetRunningTasksStateForProject() {
  return createSelector(
    makeGetApiObjectState('project', 'get_project_tasks', makeProjectKey),
    (tasksState): DataState<Task[]> => tasksState,
  );
}
