import colors from 'material-colors';
import { t } from 'ttag';

import { ACRES, getUnitName } from 'uf/base/units';
import { LegacyChartSeries, LegacyReport } from 'uf/reporting/index';

const IN_SENTENCE = true;

const totalLandSeries: LegacyChartSeries = {
  key: 'total_land_consumed',
  source: 'analysis',
  sourceKey: 'land_consumption',
  layerKey: 'land_consumption_total_results',
  columnKey: 'total_land_consumed',
};

const infillLandSeries: LegacyChartSeries = {
  key: 'infill_consumed',
  source: 'analysis',
  sourceKey: 'land_consumption',
  layerKey: 'land_consumption_map_output',
  columnKey: 'infill_consumed',
  color: colors.blueGrey[300],
  stat: 'sum',
};

const redevLandSeries: LegacyChartSeries = {
  key: 'redevelopment_consumed',
  source: 'analysis',
  sourceKey: 'land_consumption',
  layerKey: 'land_consumption_map_output',
  columnKey: 'redevelopment_consumed',
  color: colors.blueGrey[700],
  stat: 'sum',
};

const agLandSeries: LegacyChartSeries = {
  key: 'agriculture_consumed',
  source: 'analysis',
  sourceKey: 'land_consumption',
  layerKey: 'land_consumption_map_output',
  columnKey: 'agriculture_consumed',
  color: colors.yellow[700],
  stat: 'sum',
};

const woodlandLandSeries: LegacyChartSeries = {
  key: 'woodland_consumed',
  source: 'analysis',
  sourceKey: 'land_consumption',
  layerKey: 'land_consumption_map_output',
  columnKey: 'woodland_consumed',
  color: colors.lime[700],
  stat: 'sum',
};

const otherGreenfieldLandSeries: LegacyChartSeries = {
  key: 'greenfield_consumed',
  source: 'analysis',
  sourceKey: 'land_consumption',
  layerKey: 'land_consumption_map_output',
  columnKey: 'greenfield_consumed',
  color: colors.lightGreen[800],
  stat: 'sum',
};

export const landConsumptionCharts: LegacyReport = Object.freeze({
  key: 'land_consumption',
  name: 'Land Consumption',
  colorSpec: {
    primaryColor: 'rgb(128, 178, 110)',
    interpolateFromColor: colors.lime[400],
    interpolateToColor: colors.teal[600],
  },
  charts: [
    {
      name: t`Total Land Consumed`,
      description: t`Total land consumed broken down by type`,
      subtitle: t`Land cover change`,
      key: 'total_land_consumed',
      units: getUnitName(ACRES, IN_SENTENCE),
      series: [
        infillLandSeries,
        redevLandSeries,
        agLandSeries,
        woodlandLandSeries,
        otherGreenfieldLandSeries,
      ],
    },
    {
      name: t`Urban Land Consumed`,
      description: t`Total urban land consumed broken down by type`,
      subtitle: t`Urbanized land with new development`,
      key: 'total_urban_land_consumed',
      series: [infillLandSeries, redevLandSeries],
      units: getUnitName(ACRES, IN_SENTENCE),
    },
    {
      name: t`Greenfield Land Consumed`,
      description: t`Total greenfield land consumed broken down by type`,
      subtitle: t`Undeveloped land urbanized`,
      key: 'total_greenfield_land_consumed',
      series: [agLandSeries, woodlandLandSeries, otherGreenfieldLandSeries],
      units: getUnitName(ACRES, IN_SENTENCE),
    },
  ],
});

const totalLandChart = {
  name: t`Total Land Consumed`,
  description: t`Total land consumed`,
  subtitle: t`Land cover change`,
  key: 'total_land_consumed',
  series: [totalLandSeries],
  units: getUnitName(ACRES, IN_SENTENCE),
};

export const landConsumptionAnalysisCharts: LegacyReport = Object.freeze({
  key: 'land_consumption',
  name: t`Land Consumption`,
  charts: [
    totalLandChart,
    {
      name: t`Land Consumed by Type`,
      description: t`Greenfield, Agricultural, Woodland, Vacant and Urban land consumed `,
      subtitle: t`Land cover change`,
      key: 'land_consumed_by_type',
      units: getUnitName(ACRES, IN_SENTENCE),
      series: [
        infillLandSeries,
        redevLandSeries,
        agLandSeries,
        woodlandLandSeries,
        otherGreenfieldLandSeries,
      ],
    },
  ],
});
