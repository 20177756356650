import { AnyAction } from 'redux';
import { ActionsObservable, StateObservable } from 'redux-observable';

import { map } from 'rxjs/operators';

import {
  enqueuePaintBaseScenarioCanvasActionTypes,
  enqueuePaintScenarioCanvasActionTypes,
} from 'uf-api/api/paint.service';
import {
  addToLayerListActionTypes,
  createProjectActionTypes,
  createScenarioActionTypes,
  deleteScenarioActionTypes,
  removeFromLayerListActionTypes,
  runAnalysisModuleForProjectActionTypes,
  updateScenarioAnalysisParamsActionTypes,
} from 'uf-api/api/project.service';

import { SET_ACTIVE_SCENARIO } from 'uf/app/ActionTypes';
import { getActiveProjectId } from 'uf/app/selectors';
import { combineEpics } from 'uf/base/epics';
import {
  ADD_JOINED_LAYER_FILTER,
  CLEAR_COLUMN_FILTERS,
  SET_CURRENT_BUILT_FORM,
  SET_HISTOGRAM_FILTER_MODE,
  SET_SUMMARY_STATS_TYPE,
  snapshotActionTypes,
  UPDATE_BUFFER_FILTER,
  UPDATE_COLUMN_FILTER,
  ZOOM_TO_LAYER,
} from 'uf/explore/ActionTypes';
import { importActionTypes } from 'uf/import/ActionTypes';

import { logAction } from 'uf/logging/actions';
import { SET_EXPORT_FORMAT } from 'uf/mapexport/ActionTypes';
import {
  createDynamicLayerActionTypes,
  startCreateBufferedLayerActionTypes,
} from 'uf/projects/ActionTypes';
import { UFState } from 'uf/state';
import {
  clearUserSymbologyActionTypes,
  saveUserSymbologyActionTypes,
  UPDATE_CUSTOM_STOPS,
} from 'uf/symbology/ActionTypes';

export function logEvents(
  action$: ActionsObservable<AnyAction>,
  state$: StateObservable<UFState>,
) {
  return action$
    .ofType(
      createScenarioActionTypes.SUCCESS,
      createProjectActionTypes.SUCCESS,
      importActionTypes.SUCCESS,
      addToLayerListActionTypes.SUCCESS,
      removeFromLayerListActionTypes.SUCCESS,
      enqueuePaintScenarioCanvasActionTypes.SUCCESS,
      enqueuePaintBaseScenarioCanvasActionTypes.SUCCESS,
      enqueuePaintScenarioCanvasActionTypes.SUCCESS,
      enqueuePaintBaseScenarioCanvasActionTypes.SUCCESS,
      UPDATE_COLUMN_FILTER,
      CLEAR_COLUMN_FILTERS,
      ADD_JOINED_LAYER_FILTER,
      UPDATE_BUFFER_FILTER,
      runAnalysisModuleForProjectActionTypes.SUCCESS,
      SET_CURRENT_BUILT_FORM,
      createDynamicLayerActionTypes.SUCCESS,
      startCreateBufferedLayerActionTypes.SUCCESS,
      saveUserSymbologyActionTypes.SUCCESS,
      clearUserSymbologyActionTypes.SUCCESS,
      snapshotActionTypes.SUCCESS,
      SET_SUMMARY_STATS_TYPE,
      SET_HISTOGRAM_FILTER_MODE,
      UPDATE_CUSTOM_STOPS,
      ZOOM_TO_LAYER,
      SET_ACTIVE_SCENARIO,
      SET_EXPORT_FORMAT,
      updateScenarioAnalysisParamsActionTypes.SUCCESS,
      deleteScenarioActionTypes.SUCCESS,
    )
    .pipe(
      map(action => {
        const projectId = getActiveProjectId(state$.value);
        if (action.type === UPDATE_COLUMN_FILTER) {
          const updateAction = {
            ...action,
            projectId,
          };

          return logAction(getActionType(action.type), updateAction);
        }

        // filter should always be serialized to prevent excess event properties in Amplitude.
        if (
          action.type === enqueuePaintScenarioCanvasActionTypes.SUCCESS ||
          action.type === enqueuePaintBaseScenarioCanvasActionTypes.SUCCESS
        ) {
          const updateAction = {
            ...action,
            projectId,
            filters: action.extra.filters,
          };
          return logAction(getActionType(action.type), updateAction);
        }
        const updateActions = {
          ...action,
          projectId,
        };
        return logAction(getActionType(action.type), updateActions);
      }),
    );
}

/**
 * When action types change their string value, we need to still log with the
 * old string value.
 *
 * The most common case of this is as we switch to `uf-api` generated action
 * types - we still need to log the old action type.
 */
const legacyActionMap: Record<string, string> = {
  [enqueuePaintBaseScenarioCanvasActionTypes.SUCCESS]:
    'uf-api/paint/paint_base_scenario_canvas/SUCCESS',
  [enqueuePaintScenarioCanvasActionTypes.SUCCESS]:
    'uf-api/paint/paint_scenario_canvas/SUCCESS',
};

function getActionType(actionType: string) {
  if (actionType in legacyActionMap) {
    return legacyActionMap[actionType];
  }
  return actionType;
}

export default combineEpics(
  {
    logEvents,
  },
  'logging',
);
