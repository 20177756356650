import { Epic, ofType } from 'redux-observable';
import { merge as observableMerge, of as observableOf } from 'rxjs';
import { buffer, debounceTime, map, mergeMap } from 'rxjs/operators';
import { t } from 'ttag';

import { updateScenarioAnalysisParamsActionTypes } from 'uf-api/api/project.service';
import { AnalysisParameterValidationError } from 'uf-api/model/analysisParameterValidationError';
import { loadAnalysisScenarioParams } from 'uf/analysiseditor/actions';
import {
  clearPreview,
  setDirtyScenarioAnalysisParams,
} from 'uf/analysiseditor/actions/editor';
import { CLEAR_SCENARIO_PARAMS } from 'uf/analysiseditor/ActionTypes';
import { makeGetAnalysisEditorPreviews } from 'uf/analysiseditor/selectors/editor';
import { addAppMessage } from 'uf/appservices/actions';
import { combineEpics } from 'uf/base/epics';
import { FailureAction, SuccessAction } from 'uf/data/ActionTypes';
import { makeGetScenarioById } from 'uf/projects/selectors';

export const reloadAfterSave: Epic<
  SuccessAction<AnalysisParameterValidationError[]>,
  any
> = action$ => {
  return action$.pipe(
    ofType(updateScenarioAnalysisParamsActionTypes.SUCCESS),
    map(action => {
      const { projectId, scenarioId, moduleKey } = action.extra;

      return loadAnalysisScenarioParams(projectId, scenarioId, moduleKey);
    }),
  );
};

export const clearDirtyAfterSave: Epic<
  SuccessAction<AnalysisParameterValidationError[]>,
  any
> = action$ => {
  return action$.pipe(
    ofType(updateScenarioAnalysisParamsActionTypes.SUCCESS),
    map(action => {
      const { projectId, scenarioId, moduleKey } = action.extra;
      return setDirtyScenarioAnalysisParams(
        projectId,
        scenarioId,
        moduleKey,
        false,
      );
    }),
  );
};

export const alertOnSaveError: Epic<FailureAction, any> = (action$, state$) => {
  const getScenarioById = makeGetScenarioById();
  const failure$ = action$.pipe(
    ofType(updateScenarioAnalysisParamsActionTypes.FAILURE),
  );
  const bufferedFailures$ = failure$.pipe(
    buffer(failure$.pipe(debounceTime(500))),
  );

  // TODO: figure out how to group by project + moduleKey
  return bufferedFailures$.pipe(
    map(actions => {
      const scenarioNames = actions
        .map(({ extra }) => {
          const { projectId, scenarioId } = extra;
          const scenario = getScenarioById(state$.value, {
            projectId,
            scenarioId,
          });
          return scenario;
        })
        .filter(scenario => !!scenario)
        .map(scenario => scenario.name);

      if (!scenarioNames.length) {
        return addAppMessage(
          t`There was an error saving parameters: the scenario may have been deleted.`,
          { level: 'danger' },
        );
      }
      if (actions.length === 1) {
        return addAppMessage(
          t`There was an error saving parameters for the scenario: ${scenarioNames[0]}.`,
          { level: 'danger' },
        );
      }
      const names = scenarioNames.join(', ');
      return addAppMessage(
        t`There was an error saving parameters for the scenarios: ${names}.`,
        { level: 'danger' },
      );
    }),
  );
};

export const clearPreviewsAfterDiscard: Epic<any, any> = (action$, state$) => {
  const getAnalysisEditorPreviews = makeGetAnalysisEditorPreviews();
  return action$.pipe(
    ofType(CLEAR_SCENARIO_PARAMS),
    mergeMap(({ projectId, moduleKey }) => {
      const previewStates = getAnalysisEditorPreviews(state$.value, {
        projectId,
        moduleKey,
      });

      const actions = Object.values(previewStates).map(previewState => {
        const {
          extra: { scenarioId },
        } = previewState;
        return observableOf(clearPreview(projectId, moduleKey, scenarioId));
      });

      return observableMerge(...actions);
    }),
  );
};

export default combineEpics(
  {
    reloadAfterSave,
    clearDirtyAfterSave,
    alertOnSaveError,
    clearPreviewsAfterDiscard,
  },
  'editor',
);
