import classNames from 'classnames';
import React from 'react';

import cursorStyles from 'uf/styles/cursor.module.css';
import rootStyles from 'uf/styles/root.module.css';

import styles from './Clickable.module.css';

interface OwnProps {
  dataAttributes?: Record<string, string>;
  /** for compatibility with HTML buttons */
  type?: 'reset' | 'button' | 'submit';
}

type Props = OwnProps & React.HTMLProps<HTMLButtonElement>;

/**
 * @deprecated since 1/30/2019.
 */
const Clickable: React.FunctionComponent<Props> = props => {
  const { children, className, dataAttributes, ...buttonProps } = props;

  const buttonClassName = classNames(
    cursorStyles.pointer,
    rootStyles.shrink,
    rootStyles.noPadding,
    styles.unstyledButton,
    className,
  );

  return (
    <button className={buttonClassName} {...dataAttributes} {...buttonProps}>
      {children}
    </button>
  );
};

/**
 * @deprecated since 1/30/2019
 */
export default Clickable;
