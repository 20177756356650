import {
  ActionsObservable,
  Epic,
  ofType,
  StateObservable,
} from 'redux-observable';
import { ThunkAction } from 'redux-thunk';
import { Observable } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';

import { combineEpics } from 'uf/base/epics';
import { BUILT_FORM_TYPES, isCanvasBuiltFormKey } from 'uf/builtforms';
import { makeGetBuiltFormInfosWithColorEditsGetter } from 'uf/builtforms-symbology/selectors/colorStops';
import {
  updateLibraryBuildingTypeInfos,
  updateLibraryPlaceTypeInfos,
} from 'uf/builtforms/actions/data';
import { getData } from 'uf/data/dataState';
import { matchRequestId } from 'uf/data/epics';
import { ColumnKey, LayerId } from 'uf/layers';
import { makeGetLayerMetadataStateGetter } from 'uf/layers/selectors/metadata';
import { ProjectId } from 'uf/projects';
import { makeGetProjectBuiltFormsLibraryIdGetter } from 'uf/projects/selectors';
import { makeGetProjectLayerIdMap } from 'uf/projects/selectors/virtualLayers';
import { UFState } from 'uf/state';
import {
  saveUserSymbologyActionTypes,
  SaveUserSymbologyLoadAction,
  SaveUserSymbologySuccessAction,
} from 'uf/symbology/ActionTypes';
import { ViewId } from 'uf/views';

/**
 * If a user edits the building type colors for L4, then we need to also update the
 * builtforms infos when they click SAVE.
 */
export const updateBuildingTypeInfosOnL4SymbologySave: Epic<
  SaveUserSymbologyLoadAction | SaveUserSymbologySuccessAction,
  any,
  UFState
> = (action$, state$) => {
  return action$.pipe(
    ofType<SaveUserSymbologyLoadAction>(saveUserSymbologyActionTypes.LOAD),
    filterIsCanvas(state$),
    filterHasBuiltFormColorEdits(BUILT_FORM_TYPES.BUILDING_TYPE, state$),
    switchMapUpdateBuiltFormInfosOnL4SymbologySave(
      action$,
      state$,
      BUILT_FORM_TYPES.BUILDING_TYPE,
    ),
  );
};

/**
 * If a user edits the place type colors for L4, then we need to also update the
 * builtforms infos when they click SAVE.
 */
export const updatePlaceTypeInfosOnL4SymbologySave: Epic<
  SaveUserSymbologyLoadAction | SaveUserSymbologySuccessAction,
  any,
  UFState
> = (action$, state$) => {
  return action$.pipe(
    ofType<SaveUserSymbologyLoadAction>(saveUserSymbologyActionTypes.LOAD),
    filterIsCanvas(state$),
    filterHasBuiltFormColorEdits(BUILT_FORM_TYPES.PLACE_TYPE, state$),
    switchMapUpdateBuiltFormInfosOnL4SymbologySave(
      action$,
      state$,
      BUILT_FORM_TYPES.PLACE_TYPE,
    ),
  );
};

function switchMapUpdateBuiltFormInfosOnL4SymbologySave(
  action$: ActionsObservable<
    SaveUserSymbologyLoadAction | SaveUserSymbologySuccessAction
  >,
  state$: StateObservable<UFState>,
  builtFormType: BUILT_FORM_TYPES,
) {
  const getBuiltFormInfosWithColorEdits =
    makeGetBuiltFormInfosWithColorEditsGetter();
  const getBuiltFormLibraryId = makeGetProjectBuiltFormsLibraryIdGetter();
  const getLayerMap = makeGetProjectLayerIdMap();

  // We actually only care about responding to the successAction stream, but
  // we use switchMap() on the loadAction first because we need to hold onto
  // the symbology edits before they get cleared away by the successAction.
  return switchMap<
    SaveUserSymbologyLoadAction,
    Observable<ThunkAction<Promise<{}>, any, any, any>>
  >(loadAction => {
    const { projectId, viewId, layerId, columnKey } = loadAction;
    const layerMap = getLayerMap(state$.value, { projectId });
    // TODO: get this from 'extra' in loadAction
    const virtualLayerId = layerMap[layerId];
    const updatedBuiltFormInfos = getBuiltFormInfosWithColorEdits(state$.value)(
      projectId,
      viewId,
      layerId,
      virtualLayerId,
      columnKey,
      builtFormType,
    );

    const libraryId = getBuiltFormLibraryId(state$.value)(projectId);

    return action$.pipe(
      ofType<SaveUserSymbologySuccessAction>(
        saveUserSymbologyActionTypes.SUCCESS,
      ),
      filter(successAction => matchRequestId(loadAction, successAction)),
      // this take(1) should be a no-op because of the requestId matching, but just in
      // case, we close the stream after the first match.
      take(1),
      map(() => {
        if (builtFormType === BUILT_FORM_TYPES.BUILDING_TYPE) {
          return updateLibraryBuildingTypeInfos(
            libraryId,
            updatedBuiltFormInfos,
          );
        }
        if (builtFormType === BUILT_FORM_TYPES.PLACE_TYPE) {
          return updateLibraryPlaceTypeInfos(libraryId, updatedBuiltFormInfos);
        }
      }),
    );
  });
}

// TODO: Move somewhere shared
function filterIsCanvas<T extends { layerId: LayerId; columnKey: ColumnKey }>(
  state$: StateObservable<UFState>,
) {
  const getLayerMetadataState = makeGetLayerMetadataStateGetter();
  return filter<T>(({ layerId, columnKey }) => {
    const metadataState = getLayerMetadataState(state$.value)(layerId);
    const metadata = getData(metadataState);
    return isCanvasBuiltFormKey(metadata, columnKey);
  });
}

// TODO: Move somewhere shared
function filterHasBuiltFormColorEdits<
  T extends {
    projectId: ProjectId;
    viewId: ViewId;
    layerId: LayerId;
    columnKey: ColumnKey;
  },
>(builtFormType: BUILT_FORM_TYPES, state$: StateObservable<UFState>) {
  const getBuiltFormInfosWithColorEdits =
    makeGetBuiltFormInfosWithColorEditsGetter();
  const getLayerMap = makeGetProjectLayerIdMap();
  return filter<T>(({ projectId, viewId, layerId, columnKey }) => {
    const layerMap = getLayerMap(state$.value, { projectId });
    const virtualLayerId = layerMap[layerId];
    const colorEdits = getBuiltFormInfosWithColorEdits(state$.value)(
      projectId,
      viewId,
      layerId,
      virtualLayerId,
      columnKey,
      builtFormType,
    );

    return !!colorEdits.length;
  });
}

export default combineEpics(
  {
    updateBuildingTypeInfosOnL4SymbologySave,
    updatePlaceTypeInfosOnL4SymbologySave,
  },
  'builtforms-symbology',
);
