import { Position, Tooltip } from '@blueprintjs/core';
import classNames from 'classnames';
import React, { FunctionComponent } from 'react';
import { Box } from 'react-layout-components';

import textStyles from 'uf/styles/text.module.css';

import styles from './SharingCircles.module.css';

export interface CircleInfo {
  key: string;
  icon: string | JSX.Element;
  className?: string;
  color?: string;
  textColor?: string;
  borderColor?: string;
  tooltipText?: string | JSX.Element;
  tooltipPosition?: Position;
}

type SharingCircleProps = CircleInfo;

export const SharingCircle: FunctionComponent<SharingCircleProps> = props => {
  const {
    className,
    color,
    textColor,
    borderColor,
    icon,
    tooltipText,
    tooltipPosition = Position.BOTTOM,
  } = props;

  const circleStyle: React.CSSProperties = {
    backgroundColor: color,
    color: textColor,
    borderColor,
  };

  return (
    <Tooltip
      portalClassName={styles.tooltip}
      position={tooltipPosition}
      content={tooltipText}>
      <Box
        center
        className={classNames(
          className,
          styles.sharingCircle,
          textStyles.centerAlign,
          'h4',
        )}
        style={circleStyle}>
        <span>{icon}</span>
      </Box>
    </Tooltip>
  );
};

export default SharingCircle;
