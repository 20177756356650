import {
  CuratedColumnSymbology,
  InterpolationTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/public/dataset/zoning_salt_lake_city': {
    zoning: [
      {
        display_hints: {
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          scale: SymbologyScales.CATEGORICAL,
          theme: 'custom',
        },
        paint: {
          'fill-color': {
            default: '#ff7f0e',
            property: 'zoning',
            stops: [
              {
                color: '#b0bc00',
                value: 'AG',
              },
              {
                color: '#b0bc00',
                value: 'AG-2',
              },
              {
                color: '#b0bc00',
                value: 'AG-5',
              },
              {
                color: '#999999',
                value: 'AIRPORT',
              },
              {
                color: '#7b64ff',
                value: 'BP',
              },
              {
                color: '#98df8a',
                value: 'CB',
              },
              {
                color: '#fda1ff',
                value: 'CC',
              },
              {
                color: '#fda1ff',
                value: 'CG',
              },
              {
                color: '#fda1ff',
                value: 'CN',
              },
              {
                color: '#fda1ff',
                value: 'CS',
              },
              {
                color: '#8c564b',
                value: 'CSHBD1',
              },
              {
                color: '#c49c94',
                value: 'CSHBD2',
              },
              {
                color: '#9f0500',
                value: 'D-1',
              },
              {
                color: '#9f0500',
                value: 'D-2',
              },
              {
                color: '#9f0500',
                value: 'D-3',
              },
              {
                color: '#9f0500',
                value: 'D-4',
              },
              {
                color: '#aea1ff',
                value: 'EI',
              },
              {
                color: '#dbdb8d',
                value: 'FB-SC',
              },
              {
                color: '#17becf',
                value: 'FB-SE',
              },
              {
                color: '#9edae5',
                value: 'FB-UN1',
              },
              {
                color: '#1f77b4',
                value: 'FB-UN2',
              },
              {
                color: '#aec7e8',
                value: 'FP',
              },
              {
                color: '#fcdc00',
                value: 'FR-1',
              },
              {
                color: '#fcdc00',
                value: 'FR-2',
              },
              {
                color: '#fcdc00',
                value: 'FR-3',
              },
              {
                color: '#98df8a',
                value: 'GMU',
              },
              {
                color: '#009ce0',
                value: 'I',
              },
              {
                color: '#aea1ff',
                value: 'M-1',
              },
              {
                color: '#aea1ff',
                value: 'M-2',
              },
              {
                color: '#fb9e00',
                value: 'MH',
              },
              {
                color: '#f44e3b',
                value: 'MU',
              },
              {
                color: '#c49c94',
                value: 'NOS',
              },
              {
                color: '#68bc00',
                value: 'OS',
              },
              {
                color: '#f7b6d2',
                value: 'PL',
              },
              {
                color: '#7f7f7f',
                value: 'PL2',
              },
              {
                color: '#fcdc00',
                value: 'R-1-12000',
              },
              {
                color: '#fcdc00',
                value: 'R-1-5000',
              },
              {
                color: '#fcdc00',
                value: 'R-1-7000',
              },
              {
                color: '#fe9200',
                value: 'R-2',
              },
              {
                color: '#9edae5',
                value: 'RB',
              },
              {
                color: '#e27300',
                value: 'RMF-30',
              },
              {
                color: '#e27300',
                value: 'RMF-35',
              },
              {
                color: '#e27300',
                value: 'RMF-45',
              },
              {
                color: '#e27300',
                value: 'RMF-75',
              },
              {
                color: '#f44e3b',
                value: 'R-MU',
              },
              {
                color: '#f44e3b',
                value: 'R-MU-35',
              },
              {
                color: '#f44e3b',
                value: 'R-MU-45',
              },
              {
                color: '#f44e3b',
                value: 'RO',
              },
              {
                color: '#9467bd',
                value: 'RP',
              },
              {
                color: '#fda1ff',
                value: 'SNB',
              },
              {
                color: '#fe9200',
                value: 'SR-1',
              },
              {
                color: '#fe9200',
                value: 'SR-1A',
              },
              {
                color: '#fe9200',
                value: 'SR-3',
              },
              {
                color: '#9f0500',
                value: 'TSA-MUEC-C',
              },
              {
                color: '#9f0500',
                value: 'TSA-MUEC-T',
              },
              {
                color: '#9f0500',
                value: 'TSA-SP-C',
              },
              {
                color: '#9f0500',
                value: 'TSA-SP-T',
              },
              {
                color: '#9f0500',
                value: 'TSA-UC-C',
              },
              {
                color: '#9f0500',
                value: 'TSA-UC-T',
              },
              {
                color: '#9f0500',
                value: 'TSA-UN-C',
              },
              {
                color: '#9f0500',
                value: 'TSA-UN-T',
              },
              {
                color: '#009ce0',
                value: 'UI',
              },
            ],
            type: 'categorical',
          },
          'fill-opacity': 0.5,
        },
        type: SymbologyTypes.FILL,
      },
      {
        display_hints: {
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          scale: SymbologyScales.CATEGORICAL,
          theme: 'custom',
        },
        paint: {
          'line-color': 'transparent',
          'line-opacity': 0.5,
          'line-width': 0.5,
        },
        type: SymbologyTypes.LINE,
      },
    ],
  },
};

export default symbologies;
