import { FunctionComponent, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { ProjectAvailableLayers } from 'uf-api/model/projectAvailableLayers';
import { DataState } from 'uf/data/dataState';
import { ProjectId } from 'uf/projects';
import { loadAvailableProjectLayers } from 'uf/projects/actions';
import { makeGetAvailableProjectLayers } from 'uf/projects/selectors/availableLayers';

interface StateProps {
  availableProjectLayersState: DataState<ProjectAvailableLayers>;
}

interface DispatchProps {
  fetchAvailableProjectLayers: (projectId: ProjectId) => void;
}

interface OwnProps {
  projectId: ProjectId;
  children: (
    availableProjectLayersState: DataState<ProjectAvailableLayers>,
  ) => JSX.Element;
}

type Props = OwnProps & StateProps & DispatchProps;

const WithAvailableProjectLayers: FunctionComponent<Props> = (props: Props) => {
  const {
    availableProjectLayersState,
    fetchAvailableProjectLayers,
    projectId,
    children,
  } = props;

  useEffect(() => {
    if (projectId) {
      fetchAvailableProjectLayers(projectId);
    }
  }, [projectId, fetchAvailableProjectLayers]);

  return children(availableProjectLayersState);
};

export default connect<StateProps, DispatchProps, OwnProps>(
  makeMapStateToProps,
  mapDispatchToProps,
)(WithAvailableProjectLayers);

function makeMapStateToProps() {
  const getAvailableProjectLayersState = makeGetAvailableProjectLayers();
  return (state, { projectId }): StateProps => ({
    availableProjectLayersState: getAvailableProjectLayersState(state, {
      projectId,
    }),
  });
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
  return {
    fetchAvailableProjectLayers: bindActionCreators(
      loadAvailableProjectLayers,
      dispatch,
    ),
  };
}
