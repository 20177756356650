/**
 * UrbanFootprint App API
 * API for serving UrbanFootprint Application
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { ClientResponse } from '../variables';

// @ts-ignore: This is used only for params, but some services have param-free APIs
import { MapexportServiceInterface } from './mapexport.serviceInterface';

// @ts-ignore: These imports are for both params and returnType, but we only use returnType
import { MapExportRequest } from '../model/mapExportRequest';
// @ts-ignore: These imports are for both params and returnType, but we only use returnType
import { ProblemDetail } from '../model/problemDetail';

/**
 * Package: api
 */

const downloadMapExportPrefix = `uf-api/mapexport/download_map_export/`;
/**
 * Action types for mapexport.download_map_export
 *
 * Download a map export that was previously enqueued and completed.
 */
export const downloadMapExportActionTypes = {
  LOAD: `${downloadMapExportPrefix}LOAD` as 'uf-api/mapexport/download_map_export/LOAD',
  SUCCESS:
    `${downloadMapExportPrefix}SUCCESS` as 'uf-api/mapexport/download_map_export/SUCCESS',
  FAILURE:
    `${downloadMapExportPrefix}FAILURE` as 'uf-api/mapexport/download_map_export/FAILURE',
  CLEAR:
    `${downloadMapExportPrefix}CLEAR` as 'uf-api/mapexport/download_map_export/CLEAR',
} as const;

/**
 * LOAD action for mapexport.download_map_export
 * Download a map export that was previously enqueued and completed.
 */
export interface downloadMapExportLoadAction {
  readonly type: 'uf-api/mapexport/download_map_export/LOAD';
  readonly params: MapexportServiceInterface.downloadMapExportParams;
  readonly promise: Promise<ClientResponse<Blob>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for mapexport.download_map_export
 * Download a map export that was previously enqueued and completed.
 */
export interface downloadMapExportSuccessAction {
  readonly type: 'uf-api/mapexport/download_map_export/SUCCESS';
  readonly result: Blob;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for mapexport.download_map_export
 * Download a map export that was previously enqueued and completed.
 */
export interface downloadMapExportFailureAction {
  readonly type: 'uf-api/mapexport/download_map_export/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for mapexport.download_map_export
 * Download a map export that was previously enqueued and completed.
 */
export interface downloadMapExportClearAction {
  readonly type: 'uf-api/mapexport/download_map_export/CLEAR';
  readonly key: string;
}

const enqueueMapExportPrefix = `uf-api/mapexport/enqueue_map_export/`;
/**
 * Action types for mapexport.enqueue_map_export
 *
 * Starts creating an export of a map.
 */
export const enqueueMapExportActionTypes = {
  LOAD: `${enqueueMapExportPrefix}LOAD` as 'uf-api/mapexport/enqueue_map_export/LOAD',
  SUCCESS:
    `${enqueueMapExportPrefix}SUCCESS` as 'uf-api/mapexport/enqueue_map_export/SUCCESS',
  FAILURE:
    `${enqueueMapExportPrefix}FAILURE` as 'uf-api/mapexport/enqueue_map_export/FAILURE',
  CLEAR:
    `${enqueueMapExportPrefix}CLEAR` as 'uf-api/mapexport/enqueue_map_export/CLEAR',
} as const;

/**
 * LOAD action for mapexport.enqueue_map_export
 * Starts creating an export of a map.
 */
export interface enqueueMapExportLoadAction {
  readonly type: 'uf-api/mapexport/enqueue_map_export/LOAD';
  readonly params: MapexportServiceInterface.enqueueMapExportParams;
  readonly promise: Promise<ClientResponse<string>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for mapexport.enqueue_map_export
 * Starts creating an export of a map.
 */
export interface enqueueMapExportSuccessAction {
  readonly type: 'uf-api/mapexport/enqueue_map_export/SUCCESS';
  readonly result: string;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for mapexport.enqueue_map_export
 * Starts creating an export of a map.
 */
export interface enqueueMapExportFailureAction {
  readonly type: 'uf-api/mapexport/enqueue_map_export/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for mapexport.enqueue_map_export
 * Starts creating an export of a map.
 */
export interface enqueueMapExportClearAction {
  readonly type: 'uf-api/mapexport/enqueue_map_export/CLEAR';
  readonly key: string;
}

export const mapexportActionTypesMap = {
  /** Action types for mapexport.download_map_export */
  download_map_export: downloadMapExportActionTypes,
  /** Action types for mapexport.enqueue_map_export */
  enqueue_map_export: enqueueMapExportActionTypes,
} as const;

export const mapexportDetailsMap = {
  download_map_export: {
    /** HTTP method for mapexport.download_map_export */
    httpMethod: 'get' as const,
  },
  enqueue_map_export: {
    /** HTTP method for mapexport.enqueue_map_export */
    httpMethod: 'post' as const,
  },
} as const;

/**
 * Local DataState<T> - kept in sync with uf/data/dataState.ts
 */
interface DataState<T> {
  readonly loading: boolean;
  readonly loaded: boolean;
  readonly requestId: string;
  readonly requestTime?: string;
  readonly responseTime?: string;
  readonly requestCount?: number;
  readonly error?: any;
  readonly promise?: Promise<T>;
  readonly data?: T;
  readonly extra?: any;
  readonly key?: string;
}
export interface MapexportServiceState {
  /** downloadMapExport state, by key */
  download_map_export: {
    readonly [key: string]: DataState<Blob>;
  };
  /** all recent enqueueMapExport results (POST/PUT/PATCH) */
  readonly enqueue_map_export: DataState<string>[];
}
