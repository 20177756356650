import {
  InputGroup,
  Intent,
  NumericInput,
  Switch,
  Tag,
} from '@blueprintjs/core';
import classNames from 'classnames';
import React, { ChangeEvent, FunctionComponent, useCallback } from 'react';
import { Box } from 'react-layout-components';

import { LayerColumn } from 'uf-api';
import { getLocalAbbreviation, getLocalName } from 'uf/base/formatting';
import { assertNever } from 'uf/base/never';
import { getUnitInfo } from 'uf/base/units';
import rootStyles from 'uf/styles/root.module.css';

export interface FeatureValueEditorProps {
  column: LayerColumn;
  value: string | number | boolean;

  onChange: (key: string, value: number | string | boolean) => void;
  intent?: Intent;
  disabled?: boolean;
}

export const FeatureValueEditor: FunctionComponent<FeatureValueEditorProps> =
  props => {
    const { column, value, onChange, disabled, intent = Intent.NONE } = props;
    const unitName =
      column.units && getLocalAbbreviation(getUnitInfo(column.units));
    const columnName = (
      <div className={classNames(rootStyles.nowrap, rootStyles.flex)}>
        {getLocalName(column)}
      </div>
    );

    const onInputChange = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        onChange(column.key, e.target.value);
      },
      [column, onChange],
    );
    const onNumberChange = useCallback(
      (num: number) => {
        onChange(column.key, num);
      },
      [column, onChange],
    );
    const onSwitchChange = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        onChange(column.key, e.target.checked);
      },
      [column, onChange],
    );

    switch (column.type) {
      case 'bigint':
      case 'float':
      case 'int':
        return (
          <Box justifyContent="space-between" alignItems="center">
            {columnName}
            <NumericInput
              fill
              intent={intent}
              disabled={disabled}
              className={rootStyles.flex}
              value={(value ?? undefined) as number}
              onValueChange={onNumberChange}
              rightElement={unitName && <Tag minimal>{unitName}</Tag>}
            />
          </Box>
        );
      case 'bytes':
      case 'string':
        return (
          <Box justifyContent="space-between" alignItems="center">
            {columnName}
            <InputGroup
              intent={intent}
              disabled={disabled}
              className={rootStyles.flex}
              value={value as string}
              onChange={onInputChange}
            />
          </Box>
        );
      case 'boolean':
        return (
          <Box justifyContent="space-between" alignItems="center">
            {columnName}
            <Switch
              disabled={disabled}
              className={rootStyles.flex}
              checked={!!value}
              onChange={onSwitchChange}
            />
          </Box>
        );
      default:
        assertNever(column.type);
    }
  };
