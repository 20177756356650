import { Position, Tooltip } from '@blueprintjs/core';
import { mdiAlert } from '@mdi/js';
import Icon from '@mdi/react';
import classNames from 'classnames';
import React, { Component } from 'react';
import { Box } from 'react-layout-components';
import { t } from 'ttag';

import { ModuleRunStatus } from 'uf/analysis';
import { getModuleIsRunning } from 'uf/analysis/helpers';
import colorStyles from 'uf/styles/colors.module.css';
import rootStyles from 'uf/styles/root.module.css';
import textStyles from 'uf/styles/text.module.css';
import { IconSizeNew } from 'uf/ui/base/icon';

interface AnalysisStatusMessageProps {
  // overrides message derived from runStatus
  statusMessage?: string;
  invalidSubscription?: boolean;
  isBaseScenario?: boolean;
  runStatus?: ModuleRunStatus;
  analysisDone?: boolean;
  analysisStale?: boolean;
}

export default class AnalysisStatusMessage extends Component<AnalysisStatusMessageProps> {
  render() {
    const {
      statusMessage,
      analysisDone,
      analysisStale,
      invalidSubscription,
      isBaseScenario,
      runStatus,
    } = this.props;

    // only conside the module out of date if it is stale and we know the module has run at least
    // once.  otherwise we just show the module as ready to run.
    const analysisIsRunning = getModuleIsRunning(runStatus);
    const analysisOutOfDate = analysisStale && analysisDone;
    const analysisError = runStatus === ModuleRunStatus.ERROR;
    // we don't want to show the user information about the module being stale if they can't run
    // the analysis module
    const disabled = invalidSubscription && !isBaseScenario;
    const showStatusMessage = !disabled;
    const showStatusIcon =
      !statusMessage &&
      !analysisIsRunning &&
      !disabled &&
      (analysisError || analysisOutOfDate);

    let message = t`Ready to run`;
    let tooltipMessage = '';
    let statusColorClass;
    // TODO: find a way to distinguish between running for a scenario and for the whole project.
    if (typeof statusMessage === 'string') {
      message = statusMessage;
    } else if (analysisIsRunning) {
      message = t`Analysis is running...`;
    } else if (analysisError) {
      message = t`An error occured, please re-run`;
      statusColorClass = colorStyles.fail;
    } else if (analysisOutOfDate) {
      message = t`Analysis out of date`;
      tooltipMessage = t`Analysis out-of-date due to changes to scenarios. Please rerun to update.`;
      statusColorClass = colorStyles.danger;
    } else if (analysisDone) {
      message = t`Analysis complete`;
    }

    const statusIconClassName = classNames(
      'AnalysisStatusMessage-alert',
      statusColorClass,
      rootStyles.veryThinPaddingRight,
    );

    const messageComponent = (
      <Box
        className={classNames(
          'h6',
          'AnalysisStatusMessage',
          textStyles.disabledText,
        )}
        alignItems="center">
        {showStatusIcon && (
          <Icon
            path={mdiAlert}
            className={statusIconClassName}
            size={IconSizeNew.SMALL}
          />
        )}
        {showStatusMessage && (
          <div className={classNames({ [statusColorClass]: statusColorClass })}>
            {message}
          </div>
        )}
      </Box>
    );

    if (showStatusIcon && analysisStale && !analysisError) {
      return (
        <Tooltip position={Position.BOTTOM} content={tooltipMessage}>
          {messageComponent}
        </Tooltip>
      );
    }
    return messageComponent;
  }
}
