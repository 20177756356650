import { Menu, MenuItem, Spinner } from '@blueprintjs/core';
import {
  Column,
  MenuContext,
  RowHeaderCell2 as RowHeaderCell,
  Table2 as Table,
} from '@blueprintjs/table';
import { IIndexedResizeCallback } from '@blueprintjs/table/lib/esm/interactions/resizable';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { LayerNumericStats } from 'uf-api';
import ZoomToLocation from 'uf/assets/zoom-to-location.svg';
import { LayerTableData } from 'uf/layers/layerData';
import { columnClassName } from 'uf/ui/charts/Table/helpers';
// Override some css classes
import styles from 'uf/ui/charts/Table/Table.module.css';
import {
  useColumnState,
  useTableData,
  useTableSelection,
} from 'uf/ui/explore/ExploreDataViewer/hooks';
import { DataCell, formatRowItem } from './DataCell';

import { useSelector } from 'react-redux';
import { selectActiveLayerId } from 'uf/app/selectors';
import { COLUMN_RESIZE } from 'uf/explore/logging';
import { logEvent } from 'uf/logging';
import {
  fitColumnWidthsToTable,
  OnFreezeColumn,
  OnReorderColumns,
} from 'uf/ui/explore/ExploreDataViewer/hooks/useFormattedTableData';
import ExploreDataTableHeaderCell from './ExploreDataTableHeaderCell';

const LOADING_TIMEOUT = 750;

interface ExploreDataTableProps {
  currentSummaryStatType: string;
  cellWidths?: number[];
  width?: number;
  isStatsLoading: boolean;
  data: LayerTableData;
  columns: string[];
  statCellData: LayerNumericStats[];
  onReorderColumns: OnReorderColumns;
  onFreezeColumn: OnFreezeColumn;
}

const ExploreDataTable: React.FunctionComponent<ExploreDataTableProps> =
  props => {
    const {
      currentSummaryStatType,
      isStatsLoading,
      data,
      columns,
      statCellData,
      onReorderColumns,
      onFreezeColumn,
      width,
    } = props;
    const { setColumnState, columnsById } = useColumnState();
    const [isLayerChanging, setIsLayerChanging] = useState(false);
    const layerId = useSelector(selectActiveLayerId);
    const tableRef = useRef();
    /**
     * This implementation prevents misalignment between columns
     * and rows when switching between previously fetched layer data
     * by toggling a loading state to make the table component remount
     */
    useEffect(() => {
      setTimeout(() => {
        if (!!data || !isStatsLoading) {
          setIsLayerChanging(false);
        }
      }, LOADING_TIMEOUT);
      return () => {
        setIsLayerChanging(true);
      };
    }, [data, isStatsLoading]);

    const defaultWidths = useMemo(
      () => fitColumnWidthsToTable(width, columns),
      [columns, width],
    );
    const widths = useMemo(() => {
      return columns?.map(
        (columnKey, index) =>
          columnsById[columnKey]?.width || defaultWidths[index],
      );
    }, [columns, columnsById, defaultWidths]);

    const getCellClipboardData = useCallback(
      (rowIndex: number, columnIndex: number) => {
        const columnKey = columns[columnIndex];
        const columnDataIndex = data.cols.findIndex(
          ({ key }) => key === columnKey,
        );
        return formatRowItem(data.rows[rowIndex][columnDataIndex]);
      },
      [data, columns],
    );
    const renderHeader = useCallback(
      columnIndex => {
        const columnKey = columns[columnIndex];
        const column = data.cols.find(({ key }) => key === columnKey);
        return (
          <ExploreDataTableHeaderCell
            column={column}
            onFreeze={onFreezeColumn}
            currentSummaryStatType={currentSummaryStatType}
            statCellValue={statCellData[columnIndex]}
          />
        );
      },
      [
        columns,
        data.cols,
        onFreezeColumn,
        currentSummaryStatType,
        statCellData,
      ],
    );
    // Toggle loading state on layer change to prevent column misalignment
    const { isFetching: isFetchingTableData, isLoading: isLoadingTableData } =
      useTableData();
    const { flyToSelection } = useTableSelection();
    const isLoading =
      isStatsLoading ||
      isFetchingTableData ||
      isLoadingTableData ||
      isLayerChanging;
    const cellRenderer = useCallback(
      (rowIndex: number, columnIndex: number) => {
        const columnKey = columns[columnIndex];
        const column = data.cols.find(({ key }) => key === columnKey);
        const columnType = column?.type ?? 'string';
        const row = data.rows[rowIndex];
        const datum = row ? row.find(r => r.key === columnKey) : null;
        return (
          <DataCell
            className={columnClassName(columnType)}
            datum={datum}
            onDoubleClick={() => flyToSelection(rowIndex)}
            column={column}
          />
        );
      },
      [data, columns, flyToSelection],
    );
    const renderZoomToMenu = useCallback(
      (selection: MenuContext | number) => {
        return (
          <Menu data-testid="zoom-to-menu" className={styles.menu}>
            <MenuItem
              data-testid="zoom-to-menu-item"
              icon={
                <img
                  alt="Zoom to location"
                  style={{ height: 16 }}
                  src={ZoomToLocation}></img>
              }
              onClick={() => flyToSelection(selection)}
              text="Zoom to location"
            />
          </Menu>
        );
      },
      [flyToSelection],
    );
    const rowHeaderCellRenderer = useCallback(
      rowIndex => {
        return (
          <RowHeaderCell
            nameRenderer={() => (
              <div onDoubleClick={() => flyToSelection(rowIndex)}>
                {`${Number(rowIndex) + 1}`}
              </div>
            )}
            menuRenderer={renderZoomToMenu}
          />
        );
      },
      [flyToSelection, renderZoomToMenu],
    );
    const handleColumnWidthChange: IIndexedResizeCallback = useCallback(
      (index, value) => {
        const columnKey = columns[index];
        setColumnState(columnKey, { width: value });
        logEvent(COLUMN_RESIZE, {
          layerId,
          columnKey,
          width: value,
        });
      },
      [columns, layerId, setColumnState],
    );

    const frozenColumnCount = columns.filter(
      colKey => columnsById[colKey]?.isFrozen,
    ).length;

    return isLoading ? (
      <div style={{ display: 'flex', placeContent: 'center', width: '100%' }}>
        <Spinner />
      </div>
    ) : (
      <Table
        ref={tableRef}
        enableFocusedCell
        className={styles.table}
        enableColumnReordering
        columnWidths={widths}
        onColumnsReordered={onReorderColumns}
        onColumnWidthChanged={handleColumnWidthChange}
        numRows={data.rows.length}
        numFrozenColumns={frozenColumnCount}
        getCellClipboardData={getCellClipboardData}
        rowHeaderCellRenderer={rowHeaderCellRenderer}
        bodyContextMenuRenderer={renderZoomToMenu}
        cellRendererDependencies={[columnsById, columns, widths]}>
        {columns?.map(key => (
          <Column
            key={key}
            id={key}
            columnHeaderCellRenderer={renderHeader}
            cellRenderer={cellRenderer}
          />
        ))}
      </Table>
    );
  };

export default ExploreDataTable;
