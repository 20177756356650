export const events = {
  LAYER_EXPORTED: 'uf.layers.exported',
  LAYER_IMPORT_STARTED: 'uf.layers.import.started',
  LAYER_SELECTED: 'uf.layers.selected',
  LAYER_LEGEND_SET_COLUMN: 'uf.layers.legend.set_column',
  LAYER_SET_ACTIVE: 'uf.layers.set_active',
  LAYER_SET_VISIBILITY: 'uf.layers.set_visible',
  LAYER_IMPORT_ERROR: 'uf.layers.import.error',
  LAYER_IMPORT_DONE: 'uf.layers.import.done',
};

export const COLUMN_EXPORT = 'uf.layer.export.metadata';
