import _ from 'lodash';
import { createSelector } from 'reselect';

import {
  LayerReference,
  ProjectMetadata,
  ScenarioMetadata,
} from 'uf-api/model/models';
import { makeGetApiObjectResults } from 'uf/api/selectors';
import { EMPTY_ARRAY, EMPTY_OBJECT } from 'uf/base';
import { makeGetFromPropsSelector } from 'uf/base/selector';
import { DataState, getData, isLoaded } from 'uf/data/dataState';
import { convertLayerListItemToLayerReference } from 'uf/layers/compatibility';
import { LayerDataParams } from 'uf/layers/filters';
import { ProjectId } from 'uf/projects';
import { getReferenceLayersFromLayerList } from 'uf/projects/layerList';
import {
  getNonBaseScenarioCanvasLayerReference,
  getProjectBaseCanvasLayerReference,
} from 'uf/projects/projectLayers';
import {
  LegacyVirtualLayerId,
  makeVirtualAnalysisLayerId,
} from 'uf/projects/virtualLayers';
import { BUILT_FORM_QUERY } from 'uf/symbology/builtforms';

import {
  makeGetProjectById,
  makeGetProjectGetter,
  makeGetProjectState,
  makeGetScenarioById,
} from './index';

export const getAvailableProjectLayersState = makeGetApiObjectResults(
  'project',
  'get_project_available_layers',
);

/**
 * Gets the scenario's canvas layerId, only if the scenario is *not* the base
 * scenario
 */
export function makeGetNonBaseScenarioCanvasLayerId() {
  return createSelector(
    makeGetNonBaseScenarioCanvasLayerReference(),
    layer => layer?.full_path,
  );
}

/**
 * Gets the scenario's canvas, only if the scenario is *not* the base scenario
 */
export function makeGetNonBaseScenarioCanvasLayerReference() {
  return createSelector(makeGetScenarioById(), scenario =>
    getNonBaseScenarioCanvasLayerReference(scenario),
  );
}

/**
 * Gets the painted canvas for the scenario, accounting for whether the scenario
 * is a base scenario or not.
 */
export function makeGetScenarioCanvasLayerReference() {
  return createSelector(makeGetScenarioById(), scenario =>
    scenario?.base_scenario
      ? scenario.base_edits_painted_canvas
      : scenario?.painted_uf_canvas,
  );
}

/**
 * Gets the painted canvas layerId for the scenario, accounting for whether the
 * scenario is a base scenario or not.
 */
export function makeGetScenarioCanvasLayerId() {
  return createSelector(
    makeGetScenarioCanvasLayerReference(),
    layer => layer?.full_path,
  );
}

export function makeGetProjectBuiltFormsLayerReference() {
  return createSelector(makeGetProjectState(), projectState => {
    return getProjectBuiltFormsLayerReference(projectState);
  });
}
export function makeGetProjectBuiltFormsLayerReferenceGetter() {
  return createSelector(
    makeGetProjectGetter(),
    getProjectState => (projectId: ProjectId) => {
      const projectState = getProjectState(projectId);
      return getProjectBuiltFormsLayerReference(projectState);
    },
  );
}
function getProjectBuiltFormsLayerReference(
  projectStates: DataState<ProjectMetadata>,
): LayerReference {
  const project = getData(projectStates, EMPTY_OBJECT);
  const { built_forms_layer: layer } = project;
  return convertLayerListItemToLayerReference(layer);
}
export function makeGetProjectBuiltFormsLayerId() {
  return createSelector(
    makeGetProjectBuiltFormsLayerReference(),
    builtFormsLayer => {
      return getProjectBuiltFormsLayerId(builtFormsLayer);
    },
  );
}

function getProjectBuiltFormsLayerId(builtFormsLayer: LayerReference) {
  if (!builtFormsLayer) {
    return null;
  }
  return builtFormsLayer.full_path;
}
export function makeGetProjectBuiltFormsQuery() {
  return createSelector(
    makeGetProjectBuiltFormsLayerReference(),
    (builtFormsLayer): LayerDataParams => {
      return getProjectBuiltFormsQuery(builtFormsLayer);
    },
  );
}
export function makeGetProjectBuiltFormsQueryGetter() {
  return createSelector(
    makeGetProjectBuiltFormsLayerReferenceGetter(),
    getBuiltFormsLayer =>
      (projectId: ProjectId): LayerDataParams => {
        const builtFormsLayer = getBuiltFormsLayer(projectId);
        return getProjectBuiltFormsQuery(builtFormsLayer);
      },
  );
}
function getProjectBuiltFormsQuery(
  builtFormsLayer: LayerReference,
): LayerDataParams {
  if (!builtFormsLayer) {
    return BUILT_FORM_QUERY;
  }
  return {
    ...BUILT_FORM_QUERY,
    version: builtFormsLayer.version,
  };
}
export function makeGetProjectAllWorkingLayerReferences() {
  return createSelector(makeGetProjectById(), (project): LayerReference[] => {
    if (_.isEmpty(project)) {
      return EMPTY_ARRAY as LayerReference[];
    }
    return project.working_layers || (EMPTY_ARRAY as LayerReference[]);
  });
}
export function makeGetProjectAllWorkingLayerIds() {
  return createSelector(
    makeGetProjectAllWorkingLayerReferences(),
    (workingLayers: LayerReference[]): string[] =>
      workingLayers.map(layer => layer.full_path),
  );
}

function getProjectReferenceLayers(project: ProjectMetadata): LayerReference[] {
  if (_.isEmpty(project)) {
    return EMPTY_ARRAY;
  }
  return getReferenceLayersFromLayerList(project);
}

export function makeGetProjectReferenceLayers() {
  return createSelector(makeGetProjectById(), getProjectReferenceLayers);
}

export function makeGetProjectAllReferenceLayerIds() {
  return createSelector(
    makeGetProjectReferenceLayers(),
    (referenceLayers: LayerReference[]): string[] =>
      referenceLayers.map(layer => layer.full_path),
  );
}

export function makeGetProjectBaseCanvasLayerReference() {
  return createSelector(
    makeGetProjectById(),
    getProjectBaseCanvasLayerReference,
  );
}
export function makeGetProjectBaseCanvasLayerId() {
  return createSelector(
    makeGetProjectBaseCanvasLayerReference(),
    (layerInfo): string => layerInfo?.full_path,
  );
}
function makeGetProjectCanvasLayers() {
  return createSelector(
    makeGetProjectState(),
    (projectState): LayerReference[] => {
      const project = getData(projectState, null);
      if (!project) {
        return EMPTY_ARRAY;
      }
      const { base_scenario: baseScenario, scenarios = [] } = project;
      if (!baseScenario) {
        return EMPTY_ARRAY;
      }
      const canvasLayers: LayerReference[] = [];
      // Add the base canvas
      const { base_edits_painted_canvas: baseCanvas } = baseScenario;
      if (!baseCanvas) {
        return EMPTY_ARRAY;
      }
      canvasLayers.push(baseCanvas);
      // Add the context area canvas, if it exists
      // TODO: This is an total hack until context canvas is not listed with the reference layers
      const { reference_layers: referenceLayers = [] } = project;
      const contextAreaCanvas = referenceLayers.find(({ details: { name } }) =>
        ['Context Area Canvas', 'Context Area Land Use'].includes(name),
      );
      if (contextAreaCanvas) {
        canvasLayers.push(contextAreaCanvas);
      }
      // Add the scenario canvai
      const scenarioCanvases = scenarios
        // these should only be missing in tests
        .map(
          scenario =>
            scenario.painted_uf_canvas || (EMPTY_OBJECT as LayerReference),
        );
      canvasLayers.push(...scenarioCanvases);
      return canvasLayers;
    },
  );
}
export function makeGetProjectCanvasLayerIds() {
  return createSelector(
    makeGetProjectCanvasLayers(),
    (canvasLayers: LayerReference[]): string[] =>
      canvasLayers.map(layer => layer.full_path),
  );
}
export function makeGetDevelopmentLayerForProject() {
  return createSelector(
    makeGetProjectState(),
    (projectState: DataState<ProjectMetadata>): string => {
      const project = getData(projectState, null);
      if (!project) {
        return null;
      }
      const { base_scenario: baseScenario = {} } = project;
      const {
        base_edits_painted_canvas: baseDevelopmentLayer = {} as LayerReference,
      } = baseScenario;
      return baseDevelopmentLayer.full_path;
    },
  );
}
export function makeGetProjectAreaLayerForProject() {
  return createSelector(
    makeGetProjectState(),
    (projectState: DataState<ProjectMetadata>): string => {
      const project = getData(projectState, null);
      if (!project) {
        return null;
      }
      const { project_area_layer: projectAreaLayer = {} as LayerReference } =
        project;
      return projectAreaLayer.full_path;
    },
  );
}
export function makeGetContextAreaLayerForProject() {
  return createSelector(
    makeGetProjectState(),
    (projectState): LayerReference => {
      if (!isLoaded(projectState)) {
        return EMPTY_OBJECT as LayerReference;
      }
      const project = getData(projectState);
      const { context_area_layer: contextAreaLayer = {} as LayerReference } =
        project;
      return contextAreaLayer;
    },
  );
}
export function makeGetContextAreaLayerIdForProject() {
  return createSelector(
    makeGetContextAreaLayerForProject(),
    (contextLayer): string => contextLayer.full_path,
  );
}
export function makeGetProjectAnalysisOutputLayerIds() {
  return createSelector(
    makeGetScenarioById(),
    (scenario: ScenarioMetadata = EMPTY_OBJECT): string[] => {
      const { analysis_results: analysisResults = [] } = scenario;
      const analysisOutputLayerIds = analysisResults.flatMap(
        ({ policies = [] }) =>
          policies.flatMap(({ results }) =>
            results.output_layers.map(({ layer }) => layer.full_path),
          ),
      );
      return analysisOutputLayerIds;
    },
  );
}

/**
 * Get Virtual layer ids for the specified analysis module in the specified
 * scenario */
export function makeGetAnalysisVirtualLayerIds() {
  return createSelector(
    makeGetScenarioById(),
    makeGetFromPropsSelector<string, 'moduleKey'>('moduleKey'),
    (
      scenario: ScenarioMetadata = EMPTY_OBJECT,
      moduleKey,
    ): LegacyVirtualLayerId[] => {
      const { analysis_results: analysisResults = [] } = scenario;
      const analysisOutputLayerIds = analysisResults.flatMap(
        ({ policies = [] }) =>
          policies.flatMap(({ results }) =>
            results.output_layers.map(({ layer }) => layer),
          ),
      );
      return analysisOutputLayerIds
        .filter(
          layerRef => layerRef.details.display_hints.module_key === moduleKey,
        )
        .map(layerReference =>
          makeVirtualAnalysisLayerId(layerReference?.details?.name),
        );
    },
  );
}

export function getProjectBoundaryLayerReferences(
  project: ProjectMetadata,
): LayerReference[] {
  if (_.isEmpty(project)) {
    return EMPTY_ARRAY;
  }
  const boundaryLayers: LayerReference[] = [];
  const { project_area_layer: projectArea, context_area_layer: contextArea } =
    project;
  if (projectArea) {
    boundaryLayers.push(projectArea);
  }
  // double check to make sure the context area is actually different than the project area
  // since we send down the project area as the context area if one wasn't created during
  // project creation
  if (contextArea) {
    if (projectArea.full_path !== contextArea.full_path) {
      boundaryLayers.push(contextArea);
    }
  }
  if (_.isEmpty(boundaryLayers)) {
    return EMPTY_ARRAY;
  }
  return boundaryLayers;
}
