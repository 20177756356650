import {
  CuratedColumnSymbology,
  CUSTOM_THEME,
  DEFAULT_OPACITY,
  InterpolationTypes,
  PaintColorTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/public/dataset/us_fws_nwi_2019_12_04': {
    wetland_type: [
      {
        type: SymbologyTypes.FILL,
        paint: {
          'fill-color': {
            property: 'wetland_type',
            type: PaintColorTypes.CATEGORICAL,
            stops: [
              { value: 'Estuarine and Marine Deepwater', color: '#5eaaac' },
              { value: 'Estuarine and Marine Wetland', color: '#9cd4bd' },
              { value: 'Freshwater Emergent Wetland', color: '#acd66d' },
              { value: 'Freshwater Forested/Shrub Wetland', color: '#5fb17b' },
              { value: 'Freshwater Pond', color: '#9db4ce' },
              { value: 'Lake', color: '#6a60a5' },
              { value: 'Other', color: '#c9af8c' },
              { value: 'Riverine', color: '#5fb6cd' },
            ],
          },
          'fill-opacity': DEFAULT_OPACITY,
        },
        display_hints: {
          scale: SymbologyScales.CATEGORICAL,
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          theme: CUSTOM_THEME,
        },
      },
    ],
  },
};

export default symbologies;
