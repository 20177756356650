import { ActionsObservable, Epic, ofType } from 'redux-observable';
import { merge } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { ScopeTypes } from 'uf-ws/WebsocketActions';
import { combineEpics } from 'uf/base/epics';
import { BUILT_FORM_KEY } from 'uf/builtforms';
import { makeGetActiveViewId } from 'uf/explore/selectors/views';
import {
  BaseScenarioPaintTaskMessageBase,
  ScenarioPaintTaskMessageBase,
} from 'uf/painting/tasks';
import { loadSymbology } from 'uf/symbology/actions/load';
import { loadUserSymbology } from 'uf/symbology/actions/user';
import {
  clearUserSymbologyActionTypes,
  ClearUserSymbologySuccessAction,
  saveUserSymbologyActionTypes,
  SaveUserSymbologySuccessAction,
} from 'uf/symbology/ActionTypes';
import { NotificationAction } from 'uf/tasks/ActionTypes';
import { ofNotificationType } from 'uf/tasks/observables';
import { TaskStatuses } from 'uf/tasks/TaskStatuses';
import { TaskTypes } from 'uf/tasks/TaskTypes';

export const reloadUserSymbologyAfterSaveOrClear: Epic<any, any> = (
  action$: ActionsObservable<
    SaveUserSymbologySuccessAction & ClearUserSymbologySuccessAction
  >,
  state$,
) => {
  return action$.pipe(
    ofType(
      saveUserSymbologyActionTypes.SUCCESS,
      clearUserSymbologyActionTypes.SUCCESS,
    ),
    map(action => {
      const { projectId, layerId, columnKey, viewId } = action;
      return loadUserSymbology(projectId, viewId, layerId, columnKey);
    }),
  );
};

export const reloadSymbologyAfterAsyncPaint: Epic<NotificationAction, any> = (
  action$,
  state$,
) => {
  const getActiveViewId = makeGetActiveViewId();
  return merge(
    action$.pipe(
      ofNotificationType<ScenarioPaintTaskMessageBase>(
        TaskTypes.TASK_TYPE_SCENARIO_PAINT,
        TaskStatuses.DONE,
      ),
    ),
    action$.pipe(
      ofNotificationType<BaseScenarioPaintTaskMessageBase>(
        TaskTypes.TASK_TYPE_BASE_SCENARIO_PAINT,
        TaskStatuses.DONE,
      ),
    ),
  ).pipe(
    map(action => {
      const {
        result: {
          canvas: { full_path: layerId },
        },
      } = action.result;
      const projectId =
        action.result.scope_type === ScopeTypes.PROJECT
          ? action.result.scope
          : null;
      return { projectId, layerId };
    }),
    filter(({ projectId, layerId }) => !!projectId && !!layerId),
    map(({ projectId, layerId }) => {
      // HACK: We should update symbologies for all views.  When we do this, make sure to update the
      // dependecy exception under CAN_DEPEND_ON_EXPLORE.
      const viewId = getActiveViewId(state$.value, { projectId });
      return loadSymbology(projectId, viewId, layerId, BUILT_FORM_KEY);
    }),
  );
};

export default combineEpics(
  {
    reloadUserSymbologyAfterSaveOrClear,
    reloadSymbologyAfterAsyncPaint,
  },
  'symbology',
);
