import {
  CuratedColumnSymbology,
  DistributionTypes,
  InterpolationTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/public/dataset/cdc_chronic_disease_health_prom': {
    access2_crudeprev: [
      {
        display_hints: {
          distribution: DistributionTypes.QUANTILES,
          interpolation: InterpolationTypes.INTERVAL,
          is_percent: false,
          scale: SymbologyScales.NUMERIC,
          showZero: false,
          theme: 'd3:interpolateRdPu',
        },
        paint: {
          'fill-color': {
            default: 'transparent',
            property: 'access2_crudeprev',
            stops: [
              {
                color: 'rgb(255, 247, 243)',
                value: 7,
              },
              {
                color: 'rgb(253, 220, 216)',
                value: 8.9,
              },
              {
                color: 'rgb(251, 185, 190)',
                value: 10.8,
              },
              {
                color: 'rgb(248, 135, 172)',
                value: 12.9,
              },
              {
                color: 'rgb(231, 74, 155)',
                value: 15.4,
              },
              {
                color: 'rgb(187, 19, 134)',
                value: 18.3,
              },
              {
                color: 'rgb(130, 1, 119)',
                value: 22,
              },
              {
                color: 'rgb(73, 0, 106)',
                value: 27.9,
              },
            ],
            type: 'interval',
          },
          'fill-opacity': 0.7,
        },
        type: SymbologyTypes.FILL,
      },
      {
        display_hints: {
          distribution: DistributionTypes.QUANTILES,
          interpolation: InterpolationTypes.INTERVAL,
          is_percent: false,
          scale: SymbologyScales.NUMERIC,
          showZero: false,
          theme: 'material:blue:range',
        },
        paint: {
          'line-color': 'transparent',
          'line-opacity': 0.7,
          'line-width': 0.5,
        },
        type: SymbologyTypes.LINE,
      },
    ],
    arthritis_crudeprev: [
      {
        display_hints: {
          distribution: DistributionTypes.QUANTILES,
          interpolation: InterpolationTypes.INTERVAL,
          is_percent: false,
          scale: SymbologyScales.NUMERIC,
          showZero: false,
          theme: 'd3:interpolateRdPu',
        },
        paint: {
          'fill-color': {
            default: 'transparent',
            property: 'arthritis_crudeprev',
            stops: [
              {
                color: 'rgb(255, 247, 243)',
                value: 15.2,
              },
              {
                color: 'rgb(253, 220, 216)',
                value: 17.5,
              },
              {
                color: 'rgb(251, 185, 190)',
                value: 19.4,
              },
              {
                color: 'rgb(248, 135, 172)',
                value: 21.1,
              },
              {
                color: 'rgb(231, 74, 155)',
                value: 22.8,
              },
              {
                color: 'rgb(187, 19, 134)',
                value: 24.6,
              },
              {
                color: 'rgb(130, 1, 119)',
                value: 26.9,
              },
              {
                color: 'rgb(73, 0, 106)',
                value: 30.3,
              },
            ],
            type: 'interval',
          },
          'fill-opacity': 0.7,
        },
        type: SymbologyTypes.FILL,
      },
      {
        display_hints: {
          distribution: DistributionTypes.QUANTILES,
          interpolation: InterpolationTypes.INTERVAL,
          is_percent: false,
          scale: SymbologyScales.NUMERIC,
          showZero: false,
          theme: 'material:blue:range',
        },
        paint: {
          'line-color': 'transparent',
          'line-opacity': 0.7,
          'line-width': 0.5,
        },
        type: SymbologyTypes.LINE,
      },
    ],
    binge_crudeprev: [
      {
        display_hints: {
          distribution: DistributionTypes.QUANTILES,
          interpolation: InterpolationTypes.INTERVAL,
          is_percent: false,
          scale: SymbologyScales.NUMERIC,
          showZero: false,
          theme: 'd3:interpolateRdPu',
        },
        paint: {
          'fill-color': {
            default: 'transparent',
            property: 'binge_crudeprev',
            stops: [
              {
                color: 'rgb(255, 247, 243)',
                value: 13,
              },
              {
                color: 'rgb(253, 220, 216)',
                value: 14.6,
              },
              {
                color: 'rgb(251, 185, 190)',
                value: 15.8,
              },
              {
                color: 'rgb(248, 135, 172)',
                value: 16.8,
              },
              {
                color: 'rgb(231, 74, 155)',
                value: 17.8,
              },
              {
                color: 'rgb(187, 19, 134)',
                value: 19,
              },
              {
                color: 'rgb(130, 1, 119)',
                value: 20.3,
              },
              {
                color: 'rgb(73, 0, 106)',
                value: 22.5,
              },
            ],
            type: 'interval',
          },
          'fill-opacity': 0.7,
        },
        type: SymbologyTypes.FILL,
      },
      {
        display_hints: {
          distribution: DistributionTypes.QUANTILES,
          interpolation: InterpolationTypes.INTERVAL,
          is_percent: false,
          scale: SymbologyScales.NUMERIC,
          showZero: false,
          theme: 'material:blue:range',
        },
        paint: {
          'line-color': 'transparent',
          'line-opacity': 0.7,
          'line-width': 0.5,
        },
        type: SymbologyTypes.LINE,
      },
    ],
    bphigh_crudeprev: [
      {
        display_hints: {
          distribution: DistributionTypes.QUANTILES,
          interpolation: InterpolationTypes.INTERVAL,
          is_percent: false,
          scale: SymbologyScales.NUMERIC,
          showZero: false,
          theme: 'd3:interpolateRdPu',
        },
        paint: {
          'fill-color': {
            default: 'transparent',
            property: 'bphigh_crudeprev',
            stops: [
              {
                color: 'rgb(255, 247, 243)',
                value: 21.9,
              },
              {
                color: 'rgb(253, 220, 216)',
                value: 24.8,
              },
              {
                color: 'rgb(251, 185, 190)',
                value: 26.8,
              },
              {
                color: 'rgb(248, 135, 172)',
                value: 28.5,
              },
              {
                color: 'rgb(231, 74, 155)',
                value: 30.3,
              },
              {
                color: 'rgb(187, 19, 134)',
                value: 32.5,
              },
              {
                color: 'rgb(130, 1, 119)',
                value: 35.5,
              },
              {
                color: 'rgb(73, 0, 106)',
                value: 41.1,
              },
            ],
            type: 'interval',
          },
          'fill-opacity': 0.7,
        },
        type: SymbologyTypes.FILL,
      },
      {
        display_hints: {
          distribution: DistributionTypes.QUANTILES,
          interpolation: InterpolationTypes.INTERVAL,
          is_percent: false,
          scale: SymbologyScales.NUMERIC,
          showZero: false,
          theme: 'material:blue:range',
        },
        paint: {
          'line-color': 'transparent',
          'line-opacity': 0.7,
          'line-width': 0.5,
        },
        type: SymbologyTypes.LINE,
      },
    ],
    bpmed_crudeprev: [
      {
        display_hints: {
          distribution: DistributionTypes.QUANTILES,
          interpolation: InterpolationTypes.INTERVAL,
          is_percent: false,
          scale: SymbologyScales.NUMERIC,
          showZero: false,
          theme: 'd3:interpolateRdPu',
        },
        paint: {
          'fill-color': {
            default: 'transparent',
            property: 'bpmed_crudeprev',
            stops: [
              {
                color: 'rgb(255, 247, 243)',
                value: 64.1,
              },
              {
                color: 'rgb(253, 220, 216)',
                value: 67.6,
              },
              {
                color: 'rgb(251, 185, 190)',
                value: 70.1,
              },
              {
                color: 'rgb(248, 135, 172)',
                value: 72.2,
              },
              {
                color: 'rgb(231, 74, 155)',
                value: 74.1,
              },
              {
                color: 'rgb(187, 19, 134)',
                value: 75.9,
              },
              {
                color: 'rgb(130, 1, 119)',
                value: 77.8,
              },
              {
                color: 'rgb(73, 0, 106)',
                value: 80.2,
              },
            ],
            type: 'interval',
          },
          'fill-opacity': 0.7,
        },
        type: SymbologyTypes.FILL,
      },
      {
        display_hints: {
          distribution: DistributionTypes.QUANTILES,
          interpolation: InterpolationTypes.INTERVAL,
          is_percent: false,
          scale: SymbologyScales.NUMERIC,
          showZero: false,
          theme: 'material:blue:range',
        },
        paint: {
          'line-color': 'transparent',
          'line-opacity': 0.7,
          'line-width': 0.5,
        },
        type: SymbologyTypes.LINE,
      },
    ],
    cancer_crudeprev: [
      {
        display_hints: {
          distribution: DistributionTypes.QUANTILES,
          interpolation: InterpolationTypes.INTERVAL,
          is_percent: false,
          scale: SymbologyScales.NUMERIC,
          showZero: false,
          theme: 'd3:interpolateRdPu',
        },
        paint: {
          'fill-color': {
            default: 'transparent',
            property: 'cancer_crudeprev',
            stops: [
              {
                color: 'rgb(255, 247, 243)',
                value: 3.7,
              },
              {
                color: 'rgb(253, 220, 216)',
                value: 4.2,
              },
              {
                color: 'rgb(251, 185, 190)',
                value: 4.7,
              },
              {
                color: 'rgb(248, 135, 172)',
                value: 5.1,
              },
              {
                color: 'rgb(231, 74, 155)',
                value: 5.5,
              },
              {
                color: 'rgb(187, 19, 134)',
                value: 6,
              },
              {
                color: 'rgb(130, 1, 119)',
                value: 6.6,
              },
              {
                color: 'rgb(73, 0, 106)',
                value: 7.5,
              },
            ],
            type: 'interval',
          },
          'fill-opacity': 0.7,
        },
        type: SymbologyTypes.FILL,
      },
      {
        display_hints: {
          distribution: DistributionTypes.QUANTILES,
          interpolation: InterpolationTypes.INTERVAL,
          is_percent: false,
          scale: SymbologyScales.NUMERIC,
          showZero: false,
          theme: 'material:blue:range',
        },
        paint: {
          'line-color': 'transparent',
          'line-opacity': 0.7,
          'line-width': 0.5,
        },
        type: SymbologyTypes.LINE,
      },
    ],
    casthma_crudeprev: [
      {
        display_hints: {
          distribution: DistributionTypes.QUANTILES,
          interpolation: InterpolationTypes.INTERVAL,
          is_percent: false,
          scale: SymbologyScales.NUMERIC,
          showZero: false,
          theme: 'd3:interpolateRdPu',
        },
        paint: {
          'fill-color': {
            default: 'transparent',
            property: 'casthma_crudeprev',
            stops: [
              {
                color: 'rgb(255, 247, 243)',
                value: 7.7,
              },
              {
                color: 'rgb(253, 220, 216)',
                value: 8.2,
              },
              {
                color: 'rgb(251, 185, 190)',
                value: 8.7,
              },
              {
                color: 'rgb(248, 135, 172)',
                value: 9.1,
              },
              {
                color: 'rgb(231, 74, 155)',
                value: 9.6,
              },
              {
                color: 'rgb(187, 19, 134)',
                value: 10.2,
              },
              {
                color: 'rgb(130, 1, 119)',
                value: 11,
              },
              {
                color: 'rgb(73, 0, 106)',
                value: 12.2,
              },
            ],
            type: 'interval',
          },
          'fill-opacity': 0.7,
        },
        type: SymbologyTypes.FILL,
      },
      {
        display_hints: {
          distribution: DistributionTypes.QUANTILES,
          interpolation: InterpolationTypes.INTERVAL,
          is_percent: false,
          scale: SymbologyScales.NUMERIC,
          showZero: false,
          theme: 'material:blue:range',
        },
        paint: {
          'line-color': 'transparent',
          'line-opacity': 0.7,
          'line-width': 0.5,
        },
        type: SymbologyTypes.LINE,
      },
    ],
    chd_crudeprev: [
      {
        display_hints: {
          distribution: DistributionTypes.QUANTILES,
          interpolation: InterpolationTypes.INTERVAL,
          is_percent: false,
          scale: SymbologyScales.NUMERIC,
          showZero: false,
          theme: 'd3:interpolateRdPu',
        },
        paint: {
          'fill-color': {
            default: 'transparent',
            property: 'chd_crudeprev',
            stops: [
              {
                color: 'rgb(255, 247, 243)',
                value: 3.5,
              },
              {
                color: 'rgb(253, 220, 216)',
                value: 4.3,
              },
              {
                color: 'rgb(251, 185, 190)',
                value: 4.9,
              },
              {
                color: 'rgb(248, 135, 172)',
                value: 5.4,
              },
              {
                color: 'rgb(231, 74, 155)',
                value: 5.9,
              },
              {
                color: 'rgb(187, 19, 134)',
                value: 6.4,
              },
              {
                color: 'rgb(130, 1, 119)',
                value: 7.1,
              },
              {
                color: 'rgb(73, 0, 106)',
                value: 8.3,
              },
            ],
            type: 'interval',
          },
          'fill-opacity': 0.7,
        },
        type: SymbologyTypes.FILL,
      },
      {
        display_hints: {
          distribution: DistributionTypes.QUANTILES,
          interpolation: InterpolationTypes.INTERVAL,
          is_percent: false,
          scale: SymbologyScales.NUMERIC,
          showZero: false,
          theme: 'material:blue:range',
        },
        paint: {
          'line-color': 'transparent',
          'line-opacity': 0.7,
          'line-width': 0.5,
        },
        type: SymbologyTypes.LINE,
      },
    ],
    checkup_crudeprev: [
      {
        display_hints: {
          distribution: DistributionTypes.QUANTILES,
          interpolation: InterpolationTypes.INTERVAL,
          is_percent: false,
          scale: SymbologyScales.NUMERIC,
          showZero: false,
          theme: 'd3:interpolateRdPu',
        },
        paint: {
          'fill-color': {
            default: 'transparent',
            property: 'checkup_crudeprev',
            stops: [
              {
                color: 'rgb(255, 247, 243)',
                value: 61.6,
              },
              {
                color: 'rgb(253, 220, 216)',
                value: 64.2,
              },
              {
                color: 'rgb(251, 185, 190)',
                value: 66.2,
              },
              {
                color: 'rgb(248, 135, 172)',
                value: 68.2,
              },
              {
                color: 'rgb(231, 74, 155)',
                value: 70.2,
              },
              {
                color: 'rgb(187, 19, 134)',
                value: 72.4,
              },
              {
                color: 'rgb(130, 1, 119)',
                value: 74.8,
              },
              {
                color: 'rgb(73, 0, 106)',
                value: 77.8,
              },
            ],
            type: 'interval',
          },
          'fill-opacity': 0.7,
        },
        type: SymbologyTypes.FILL,
      },
      {
        display_hints: {
          distribution: DistributionTypes.QUANTILES,
          interpolation: InterpolationTypes.INTERVAL,
          is_percent: false,
          scale: SymbologyScales.NUMERIC,
          showZero: false,
          theme: 'material:blue:range',
        },
        paint: {
          'line-color': 'transparent',
          'line-opacity': 0.7,
          'line-width': 0.5,
        },
        type: SymbologyTypes.LINE,
      },
    ],
    cholscreen_crudeprev: [
      {
        display_hints: {
          distribution: DistributionTypes.QUANTILES,
          interpolation: InterpolationTypes.INTERVAL,
          is_percent: false,
          scale: SymbologyScales.NUMERIC,
          showZero: false,
          theme: 'd3:interpolateRdPu',
        },
        paint: {
          'fill-color': {
            default: 'transparent',
            property: 'cholscreen_crudeprev',
            stops: [
              {
                color: 'rgb(255, 247, 243)',
                value: 62.4,
              },
              {
                color: 'rgb(253, 220, 216)',
                value: 66.8,
              },
              {
                color: 'rgb(251, 185, 190)',
                value: 69.8,
              },
              {
                color: 'rgb(248, 135, 172)',
                value: 72.2,
              },
              {
                color: 'rgb(231, 74, 155)',
                value: 74.5,
              },
              {
                color: 'rgb(187, 19, 134)',
                value: 76.8,
              },
              {
                color: 'rgb(130, 1, 119)',
                value: 79.2,
              },
              {
                color: 'rgb(73, 0, 106)',
                value: 82.3,
              },
            ],
            type: 'interval',
          },
          'fill-opacity': 0.7,
        },
        type: SymbologyTypes.FILL,
      },
      {
        display_hints: {
          distribution: DistributionTypes.QUANTILES,
          interpolation: InterpolationTypes.INTERVAL,
          is_percent: false,
          scale: SymbologyScales.NUMERIC,
          showZero: false,
          theme: 'material:blue:range',
        },
        paint: {
          'line-color': 'transparent',
          'line-opacity': 0.7,
          'line-width': 0.5,
        },
        type: SymbologyTypes.LINE,
      },
    ],
    colon_screen_crudeprev: [
      {
        display_hints: {
          distribution: DistributionTypes.QUANTILES,
          interpolation: InterpolationTypes.INTERVAL,
          is_percent: false,
          scale: SymbologyScales.NUMERIC,
          showZero: false,
          theme: 'd3:interpolateRdPu',
        },
        paint: {
          'fill-color': {
            default: 'transparent',
            property: 'colon_screen_crudeprev',
            stops: [
              {
                color: 'rgb(255, 247, 243)',
                value: 50,
              },
              {
                color: 'rgb(253, 220, 216)',
                value: 54.6,
              },
              {
                color: 'rgb(251, 185, 190)',
                value: 58.3,
              },
              {
                color: 'rgb(248, 135, 172)',
                value: 61.4,
              },
              {
                color: 'rgb(231, 74, 155)',
                value: 64.1,
              },
              {
                color: 'rgb(187, 19, 134)',
                value: 66.7,
              },
              {
                color: 'rgb(130, 1, 119)',
                value: 69.2,
              },
              {
                color: 'rgb(73, 0, 106)',
                value: 72.2,
              },
            ],
            type: 'interval',
          },
          'fill-opacity': 0.7,
        },
        type: SymbologyTypes.FILL,
      },
      {
        display_hints: {
          distribution: DistributionTypes.QUANTILES,
          interpolation: InterpolationTypes.INTERVAL,
          is_percent: false,
          scale: SymbologyScales.NUMERIC,
          showZero: false,
          theme: 'material:blue:range',
        },
        paint: {
          'line-color': 'transparent',
          'line-opacity': 0.7,
          'line-width': 0.5,
        },
        type: SymbologyTypes.LINE,
      },
    ],
    copd_crudeprev: [
      {
        display_hints: {
          distribution: DistributionTypes.QUANTILES,
          interpolation: InterpolationTypes.INTERVAL,
          is_percent: false,
          scale: SymbologyScales.NUMERIC,
          showZero: false,
          theme: 'd3:interpolateRdPu',
        },
        paint: {
          'fill-color': {
            default: 'transparent',
            property: 'copd_crudeprev',
            stops: [
              {
                color: 'rgb(255, 247, 243)',
                value: 3.7,
              },
              {
                color: 'rgb(253, 220, 216)',
                value: 4.4,
              },
              {
                color: 'rgb(251, 185, 190)',
                value: 5,
              },
              {
                color: 'rgb(248, 135, 172)',
                value: 5.6,
              },
              {
                color: 'rgb(231, 74, 155)',
                value: 6.2,
              },
              {
                color: 'rgb(187, 19, 134)',
                value: 6.9,
              },
              {
                color: 'rgb(130, 1, 119)',
                value: 7.9,
              },
              {
                color: 'rgb(73, 0, 106)',
                value: 9.5,
              },
            ],
            type: 'interval',
          },
          'fill-opacity': 0.7,
        },
        type: SymbologyTypes.FILL,
      },
      {
        display_hints: {
          distribution: DistributionTypes.QUANTILES,
          interpolation: InterpolationTypes.INTERVAL,
          is_percent: false,
          scale: SymbologyScales.NUMERIC,
          showZero: false,
          theme: 'material:blue:range',
        },
        paint: {
          'line-color': 'transparent',
          'line-opacity': 0.7,
          'line-width': 0.5,
        },
        type: SymbologyTypes.LINE,
      },
    ],
    corem_crudeprev: [
      {
        display_hints: {
          distribution: DistributionTypes.QUANTILES,
          interpolation: InterpolationTypes.INTERVAL,
          is_percent: false,
          scale: SymbologyScales.NUMERIC,
          showZero: false,
          theme: 'd3:interpolateRdPu',
        },
        paint: {
          'fill-color': {
            default: 'transparent',
            property: 'corem_crudeprev',
            stops: [
              {
                color: 'rgb(255, 247, 243)',
                value: 21.1,
              },
              {
                color: 'rgb(253, 220, 216)',
                value: 24.4,
              },
              {
                color: 'rgb(251, 185, 190)',
                value: 27.5,
              },
              {
                color: 'rgb(248, 135, 172)',
                value: 30.6,
              },
              {
                color: 'rgb(231, 74, 155)',
                value: 33.2,
              },
              {
                color: 'rgb(187, 19, 134)',
                value: 35.6,
              },
              {
                color: 'rgb(130, 1, 119)',
                value: 38.2,
              },
              {
                color: 'rgb(73, 0, 106)',
                value: 41.6,
              },
            ],
            type: 'interval',
          },
          'fill-opacity': 0.7,
        },
        type: SymbologyTypes.FILL,
      },
      {
        display_hints: {
          distribution: DistributionTypes.QUANTILES,
          interpolation: InterpolationTypes.INTERVAL,
          is_percent: false,
          scale: SymbologyScales.NUMERIC,
          showZero: false,
          theme: 'material:blue:range',
        },
        paint: {
          'line-color': 'transparent',
          'line-opacity': 0.7,
          'line-width': 0.5,
        },
        type: SymbologyTypes.LINE,
      },
    ],
    corew_crudeprev: [
      {
        display_hints: {
          distribution: DistributionTypes.QUANTILES,
          interpolation: InterpolationTypes.INTERVAL,
          is_percent: false,
          scale: SymbologyScales.NUMERIC,
          showZero: false,
          theme: 'd3:interpolateRdPu',
        },
        paint: {
          'fill-color': {
            default: 'transparent',
            property: 'corew_crudeprev',
            stops: [
              {
                color: 'rgb(255, 247, 243)',
                value: 20.5,
              },
              {
                color: 'rgb(253, 220, 216)',
                value: 23.5,
              },
              {
                color: 'rgb(251, 185, 190)',
                value: 26.1,
              },
              {
                color: 'rgb(248, 135, 172)',
                value: 28.7,
              },
              {
                color: 'rgb(231, 74, 155)',
                value: 31,
              },
              {
                color: 'rgb(187, 19, 134)',
                value: 33.3,
              },
              {
                color: 'rgb(130, 1, 119)',
                value: 35.8,
              },
              {
                color: 'rgb(73, 0, 106)',
                value: 38.9,
              },
            ],
            type: 'interval',
          },
          'fill-opacity': 0.7,
        },
        type: SymbologyTypes.FILL,
      },
      {
        display_hints: {
          distribution: DistributionTypes.QUANTILES,
          interpolation: InterpolationTypes.INTERVAL,
          is_percent: false,
          scale: SymbologyScales.NUMERIC,
          showZero: false,
          theme: 'material:blue:range',
        },
        paint: {
          'line-color': 'transparent',
          'line-opacity': 0.7,
          'line-width': 0.5,
        },
        type: SymbologyTypes.LINE,
      },
    ],
    csmoking_crudeprev: [
      {
        display_hints: {
          distribution: DistributionTypes.QUANTILES,
          interpolation: InterpolationTypes.INTERVAL,
          is_percent: false,
          scale: SymbologyScales.NUMERIC,
          showZero: false,
          theme: 'd3:interpolateRdPu',
        },
        paint: {
          'fill-color': {
            default: 'transparent',
            property: 'csmoking_crudeprev',
            stops: [
              {
                color: 'rgb(255, 247, 243)',
                value: 11.1,
              },
              {
                color: 'rgb(253, 220, 216)',
                value: 13.2,
              },
              {
                color: 'rgb(251, 185, 190)',
                value: 14.9,
              },
              {
                color: 'rgb(248, 135, 172)',
                value: 16.5,
              },
              {
                color: 'rgb(231, 74, 155)',
                value: 18.2,
              },
              {
                color: 'rgb(187, 19, 134)',
                value: 20.1,
              },
              {
                color: 'rgb(130, 1, 119)',
                value: 22.5,
              },
              {
                color: 'rgb(73, 0, 106)',
                value: 26.2,
              },
            ],
            type: 'interval',
          },
          'fill-opacity': 0.7,
        },
        type: SymbologyTypes.FILL,
      },
      {
        display_hints: {
          distribution: DistributionTypes.QUANTILES,
          interpolation: InterpolationTypes.INTERVAL,
          is_percent: false,
          scale: SymbologyScales.NUMERIC,
          showZero: false,
          theme: 'material:blue:range',
        },
        paint: {
          'line-color': 'transparent',
          'line-opacity': 0.7,
          'line-width': 0.5,
        },
        type: SymbologyTypes.LINE,
      },
    ],
  },
};

export default symbologies;
