import { User } from 'uf-api';
import { Actions, ScopeTypes } from 'uf-ws/WebsocketActions';
import { makeKeyedListActionCreators } from 'uf/base/keyedListActionHelpers';
import {
  presenceActionTypes,
  RECEIVE_COLLABORATION_MESSAGE,
  ReceiveCollaborationMessage,
  SEND_COLLABORATION_MESSAGE,
  SendCollaborationMessage,
} from 'uf/collaboration/ActionTypes';
import { Collaborator } from 'uf/collaboration/Collaborator';
import {
  CollaborationMessage,
  CollaborationMessageTypes,
  PresenceInfo,
} from 'uf/collaboration/MessageTypes';
import { ProjectId } from 'uf/projects';

export const presenceActions =
  makeKeyedListActionCreators<Collaborator>(presenceActionTypes);

/**
 * An action creator to notify to a specific user that we are subscribed to the project.  The action
 * should be picked up by an epic to send the message via websockets.
 * @param subscriber the user we are notifying
 * @param projectId the project id we are subscribed to
 * @param responder our user information
 */
export function notifyUsersOfPresence(
  subscriber: User,
  projectId: ProjectId,
  responder: User,
): SendCollaborationMessage<PresenceInfo> {
  const message = userPresentOnProjectMessage(
    Actions.NOTIFY,
    ScopeTypes.SESSION,
    subscriber.session_id,
    projectId,
    responder,
  );

  return sendCollaborationMessage(message);
}

/**
 * An action creator to notify all users subscribed to a project that we are also subscribed.  The
 * action should be picked up by an epic to send the message via websockets.
 * @param subscriber user information to broadcast
 * @param projectId the project the user is subscribed to
 */
export function notifyAllUsersOfPresence(
  subscriber: User,
  projectId: ProjectId,
): SendCollaborationMessage<PresenceInfo> {
  const message = userPresentOnProjectMessage(
    Actions.NOTIFY,
    ScopeTypes.PROJECT,
    projectId,
    projectId,
    subscriber,
  );

  return sendCollaborationMessage(message);
}

/**
 * An action creator to notify all users subscribed to a project that we are also subscribed.  The
 * action should be picked up by an epic to send the message via websockets.
 * @param projectId the project the user is subscribed to
 */
export function notifyAllUsersWhenLeavingProject(
  projectId: ProjectId,
  user: User,
): SendCollaborationMessage<PresenceInfo> {
  const leavingMessage = userLeavingProjectMessage(
    Actions.NOTIFY,
    ScopeTypes.PROJECT,
    projectId,
    projectId,
    user,
  );

  return sendCollaborationMessage(leavingMessage);
}

/**
 * helper function to create a project subscript collaboration message.  exported for testing.
 * @param action a websocket action
 * @param scopeType the scope of the message i.e. PROJECT, USER, SESSION
 * @param scope the specific channel for the scope
 * @param projectId project subscribed to
 * @param user the user that is subscribing to the project
 */
export function userPresentOnProjectMessage(
  action: Actions,
  scopeType: ScopeTypes,
  scope: string,
  projectId: ProjectId,
  user: User,
): CollaborationMessage<PresenceInfo> {
  return {
    action,
    scope_type: scopeType,
    scope,
    type: CollaborationMessageTypes.PRESENT,
    info: {
      user,
      projectId,
    },
  };
}

/**
 * helper function to create a project subscript collaboration message.  exported for testing.
 *
 * @param action a websocket action
 * @param scopeType the scope of the message i.e. PROJECT, USER, SESSION
 * @param scope the specific channel for the scope
 * @param projectId project subscribed to
 * @param user the user that is subscribing to the project
 */
export function userLeavingProjectMessage(
  action: Actions,
  scopeType: ScopeTypes,
  scope: string,
  projectId: ProjectId,
  user: User,
): CollaborationMessage<PresenceInfo> {
  return {
    action,
    scope_type: scopeType,
    scope,
    type: CollaborationMessageTypes.LEAVING,
    info: {
      user,
      projectId,
    },
  };
}

/**
 * A simple action creator to send a websocket message.  This action should be picked up by an epic
 * to perform the actual websocket stuff.
 * @param message the websocket message meant to be serialized and sent over websockets
 */
export function sendCollaborationMessage<T>(
  message: CollaborationMessage<T>,
): SendCollaborationMessage<T> {
  return {
    type: SEND_COLLABORATION_MESSAGE,
    message,
  };
}

/**
 * A simple action creator to notify the system that a collaboration message was received via
 * websockets.
 * @param message the deserialized websocket message
 */
export function receivedCollaborationMessage<T = any>(
  message: CollaborationMessage<T>,
): ReceiveCollaborationMessage<T> {
  return {
    type: RECEIVE_COLLABORATION_MESSAGE,
    message,
  };
}
