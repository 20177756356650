/**
 * UrbanFootprint App API
 * API for serving UrbanFootprint Application
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * How much a subcategory contributes to total employment of a category.
 */
export interface BuiltFormPainterEmploymentSubCategoryWeight<> {
  /**
   * Key for the subcategory.
   */
  subcategory_key: BuiltFormPainterEmploymentSubCategoryWeight.SubcategoryKeyEnum;
  /**
   * Portion of the subcategory; must be a number between 0 and 1.
   */
  subcategory_weight: number;
}
export namespace BuiltFormPainterEmploymentSubCategoryWeight {
  export type SubcategoryKeyEnum =
    | 'retail_services'
    | 'restaurant'
    | 'arts_entertainment'
    | 'accommodation'
    | 'other_services'
    | 'office_services'
    | 'medical_services'
    | 'public_admin'
    | 'education'
    | 'manufacturing'
    | 'wholesale'
    | 'transport_warehousing'
    | 'utilities'
    | 'construction'
    | 'agriculture'
    | 'extraction';
  export const SubcategoryKeyEnum = {
    RetailServices: 'retail_services',
    Restaurant: 'restaurant',
    ArtsEntertainment: 'arts_entertainment',
    Accommodation: 'accommodation',
    OtherServices: 'other_services',
    OfficeServices: 'office_services',
    MedicalServices: 'medical_services',
    PublicAdmin: 'public_admin',
    Education: 'education',
    Manufacturing: 'manufacturing',
    Wholesale: 'wholesale',
    TransportWarehousing: 'transport_warehousing',
    Utilities: 'utilities',
    Construction: 'construction',
    Agriculture: 'agriculture',
    Extraction: 'extraction',
  } as const;
}
