import _ from 'lodash';

import { risonEncode } from 'uf/base/rison';

/**
 * Creates a non-deterministic unique id for use in our async actions. This
 * helps us match up LOAD/SUCCESS actions in our epics regardless of the redux data key.
 */
const REQUEST_ID_PREFIX = 'r-';
export function makeRequestId() {
  return _.uniqueId(REQUEST_ID_PREFIX);
}

/**
 * An extremely generic way to construct a key for storing data states in redux.
 *
 * @param id An identifier of some kind. This could be a layer id, a user key,
 * or anything that identifies the resource.
 * @param query Usually the parameters to an api, but could be anything you
 * want to cache on.
 */
export function makeReduxDataKey<T extends object>(id: string, query?: T) {
  // Using rison for readability
  let queryString: string;

  try {
    queryString = _.isEmpty(query) ? '*' : risonEncode(query);
  } catch (error) {
    // hijack this error message so we can get more info out of it.
    if (error.message === "rison can't encode the undefined value") {
      const undefinedKeys = getUndefinedKeys(query);
      throw new Error(
        `rison can't encode the undefined values: ${undefinedKeys.join(', ')}`,
      );
    }

    throw error;
  }

  return `${id}:${queryString}`;
}

/**
 * Get a list of keys or values
 */
export function getUndefinedKeys<T extends object>(
  query: T | T[],
  path = '',
): string[] {
  if (query === undefined) {
    return [path];
  }
  if (_.isArray(query)) {
    return _.flatten(
      query
        .map((item, index) => getUndefinedKeys(item, `${path}[${index}]`))
        .filter(key => key),
    );
  }
  if (_.isObject(query)) {
    return _.flatten(
      _.map(query, (item, key) =>
        getUndefinedKeys(
          item as unknown as object,
          path ? `${path}.${key}` : key,
        ),
      ).filter(key => key),
    );
  }
  return [];
}

export const convertRequestBodyToFormData = body =>
  Object.keys(body).reduce((formData, key) => {
    // Don't transcribe null or undefined values
    if (!_.isUndefined(body[key])) {
      formData.append(key, body[key]);
    }
    return formData;
  }, new FormData());
