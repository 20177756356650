import {
  ActionableEvent,
  CombineEvent,
  CreateEvent,
  DeleteEvent,
  EventType,
  Mode,
  ModeChangeEvent,
  RenderEvent,
  SelectionChangeEvent,
  UncombineEvent,
  UpdateEvent,
} from '@mapbox/mapbox-gl-draw';
import MapboxDraw from '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw-unminified';
import { Layer, Map } from 'mapbox-gl';
import warning from 'warning';

import { invert } from 'uf/base/objects';
import DrawModeBoxSelectVertices from 'uf/ui/map/DrawModeBoxSelectVertices';

export interface DrawModeListeners {
  /** response to 'draw.create' */
  onCreate?(e: CreateEvent): void;

  /** response to 'draw.delete' */
  onDelete?(e: DeleteEvent): void;

  /** response to 'draw.combine' */
  onCombine?(e: CombineEvent): void;

  /** response to 'draw.uncombine' */
  onUncombine?(e: UncombineEvent): void;

  /** response to 'draw.update' */
  onUpdate?(e: UpdateEvent): void;

  /** response to 'draw.selectionchange' */
  onSelectionChange?(e: SelectionChangeEvent): void;

  /** response to 'draw.modechange' */
  onModeChange?(e: ModeChangeEvent): void;

  /** response to 'draw.render' */
  onRender?(e: RenderEvent): void;

  /** response to 'draw.actionable' */
  onActionable?(e: ActionableEvent): void;
}

const eventMap: Record<EventType, keyof DrawModeListeners> = {
  'draw.combine': 'onCombine',
  'draw.create': 'onCreate',
  'draw.delete': 'onDelete',
  'draw.modechange': 'onModeChange',
  'draw.render': 'onRender',
  'draw.selectionchange': 'onSelectionChange',
  'draw.uncombine': 'onUncombine',
  'draw.update': 'onUpdate',
};

const eventReverseMap = invert(eventMap);

export interface DrawMode {
  mapboxDraw: MapboxDraw<'box_select_vertices'>;
  map: Map;
  on<K extends keyof DrawModeListeners>(
    eventName: K,
    callback: DrawModeListeners[K],
  ): void;
  off<K extends keyof DrawModeListeners>(
    eventName: K,
    callback: DrawModeListeners[K],
  ): void;
}

/**
 * Exported only for testing
 */
export class DrawModeImpl implements DrawMode {
  mapboxDraw: MapboxDraw<'box_select_vertices'>;
  map: Map;
  callbacks: DrawModeListeners = {};
  constructor(mapboxDraw: MapboxDraw<'box_select_vertices'>, map: Map) {
    this.mapboxDraw = mapboxDraw;
    this.map = map;
  }

  on<K extends keyof DrawModeListeners>(
    eventName: K,
    callback: DrawModeListeners[K],
  ) {
    if (!this.map) {
      return;
    }
    const eventType = eventReverseMap[eventName];
    if (this.callbacks[eventName] !== callback) {
      if (this.callbacks[eventName]) {
        this.map.off(eventType, this.callbacks[eventName]);
      }
      this.callbacks[eventName] = callback;
      this.map.on(eventType, callback);
    }
  }

  off<K extends keyof DrawModeListeners>(
    eventName: K,
    callback: DrawModeListeners[K],
  ) {
    const eventType = eventReverseMap[eventName];
    if (this.callbacks[eventName] === callback) {
      this.map.off(eventType, callback);
    }
  }

  cleanup() {
    if (!this.callbacks) {
      return;
    }
    Object.entries(eventMap).forEach(([eventKey, callbackKey]) => {
      if (this.callbacks[callbackKey]) {
        this.map.off(eventKey, this.callbacks[callbackKey]);
      }
    });
    this.map = null;
    this.mapboxDraw = null;
  }
}

const modes: Record<Mode<'box_select_vertices'>, any> = {
  ...MapboxDraw.modes,
  box_select_vertices: DrawModeBoxSelectVertices,
};

export function setDrawMode(map: Map, styles: Layer[]) {
  if (!map) {
    warning(!!map, 'Cannot enter draw mode without a map');
    return { drawMode: null, exitMode: () => {} };
  }
  const canvas = map.getCanvasContainer();
  canvas.style.cursor = 'pointer';

  const draw = new MapboxDraw<'box_select_vertices'>({
    displayControlsDefault: false,
    styles,
    modes,
  });
  map.addControl(draw, 'top-right');
  const drawModeImpl = new DrawModeImpl(draw, map);

  function exitMode() {
    drawModeImpl.cleanup();
    map.removeControl(draw);
  }
  return { drawMode: drawModeImpl, exitMode };
}
