import { combineDataStates, DataState, getData } from 'uf/data/dataState';
import { LayerColumnSymbology } from 'uf/symbology';

export function getMergedSymbologyState(
  ...symbologyStates: DataState<LayerColumnSymbology[]>[]
): DataState<LayerColumnSymbology[]> {
  return getMergedSymbologyOrLoadingState(
    combineDataStates(symbologyStates),
    ...symbologyStates,
  );
}

export function getMergedSymbologyOrLoadingState(
  loadingState: DataState<null>,
  ...symbologyStates: DataState<LayerColumnSymbology[]>[]
) {
  const symbologyState = symbologyStates.find(dataState =>
    getData(dataState, null),
  );

  if (symbologyState) {
    return symbologyState;
  }

  return loadingState;
}
