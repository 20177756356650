import { Dispatch, Store } from 'redux';
import { RouteThunk } from 'redux-first-router';
import watch from 'redux-watch';

import { setActiveAnalysisEditorModuleKey } from 'uf/analysiseditor/actions';
import {
  setAnalysisEditorRoute,
  setAnalysisEditorWithKeyRoute,
} from 'uf/analysiseditor/actions/routing';
import {
  AnalysisEditorContext,
  makeGetActiveAnalysisEditorModuleByProjectId,
} from 'uf/analysiseditor/selectors';
import { makeGetAnalysisEditorContext } from 'uf/analysiseditor/selectors/routing';
import { setActiveProject } from 'uf/app/actions';
import { getActiveProjectId } from 'uf/app/selectors';
import { parseFullPath } from 'uf/base/dataset';
import { setUIProperty } from 'uf/explore/actions';
import { getLocationState } from 'uf/routing/selectors';
import { SyncStateWithUrl } from 'uf/routing/state';

/**
 * Watch a redux store, updating the URL as it changes
 *
 * @param store Redux store
 */
export const syncAnalysisEditorStateWithUrl: SyncStateWithUrl = (
  store: Store,
) => {
  const getAnalysisEditorContext = makeGetAnalysisEditorContext();
  const watchBuiltFormCurrentBuiltFormState = watch(() => {
    const projectId = getActiveProjectId(store.getState());
    return getAnalysisEditorContext(store.getState(), { projectId });
  });

  const unsubscribe = store.subscribe(
    watchBuiltFormCurrentBuiltFormState((newState: AnalysisEditorContext) => {
      const {
        flags,
        projectId,
        moduleKey,
        manageViewEditor,
        manageViewVisible,
      } = newState;
      if (!manageViewVisible || manageViewEditor !== 'analysiseditor') {
        return;
      }

      // TODO: remove this parsing
      const { namespace, key } = parseFullPath(projectId, 'project');

      if (moduleKey) {
        store.dispatch(
          setAnalysisEditorWithKeyRoute(namespace, key, moduleKey, flags),
        );
      } else {
        store.dispatch(setAnalysisEditorRoute(namespace, key, flags));
      }
    }),
  );
  return unsubscribe;
};

export function makeUpdateAnalysisEditorStateFromUrl(): RouteThunk {
  const getActiveAnalysisEditorModuleByProjectId =
    makeGetActiveAnalysisEditorModuleByProjectId();
  return (dispatch: Dispatch, getState) => {
    const state = getState();
    const location = getLocationState(state);
    const { namespace, projectKey, moduleKey } = location.payload as any;
    const projectId = `/${namespace}/project/${projectKey}`;

    const activeProjectId = getActiveProjectId(state);
    if (projectId && activeProjectId !== projectId) {
      dispatch(setActiveProject(projectId));
    }

    dispatch(setUIProperty('manageViewVisible', true));
    dispatch(setUIProperty('manageViewEditor', 'analysiseditor'));

    const activeModuleKey = getActiveAnalysisEditorModuleByProjectId(state, {
      projectId,
    });
    if (moduleKey !== activeModuleKey) {
      dispatch(setActiveAnalysisEditorModuleKey(moduleKey, projectId));
    }
  };
}
