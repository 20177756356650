import { extent, mean } from 'd3-array';
import _ from 'lodash';
import warning from 'warning';

import {
  LayerColumn,
  LayerColumnStats,
  LayerMetadata,
  LayerStats,
  ValueBucket,
} from 'uf-api';
import { LayerServiceInterface } from 'uf-api/api/layer.serviceInterface';
import { EMPTY_OBJECT } from 'uf/base';
import { Omit } from 'uf/base/types';
import { ColumnKey } from 'uf/layers';
import { FilterSpec } from 'uf/layers/filters';
import {
  isNullValueBucket,
  NULL_CATEGORY,
  NullCategory,
} from 'uf/layers/nullStats';

import { NativeValue } from './formatting';
import { Metatype } from './metadata';

export function findColumnKeyStats(
  layerStats: LayerStats,
  columnKey: ColumnKey,
): LayerColumnStats {
  const columnStats = layerStats?.column_stats;
  if (columnStats) {
    return columnStats.find(stat => stat.column_key === columnKey);
  }
}

export function getCategoricalStatsValues(
  layerColumnStats: LayerColumnStats,
): (string | NullCategory)[] {
  const values = layerColumnStats?.categorical?.values ?? [];
  return values.map(entry =>
    !isNullValueBucket(entry) ? entry.value : NULL_CATEGORY,
  );
}

/**
 * All the search parameters needed to make a layer stats api call. The api
 * endpoint expects a filter string, but the front-end passes this around as a
 * FilterSpec. LayerId info is omitted as well as that is added at the api
 * level. Use this interface everywhere execept at the api level.
 */
export interface LayerStatsParams
  extends Omit<
    LayerServiceInterface.getLayerStatsParams,
    'filters' | 'namespace' | 'layer_type' | 'key'
  > {
  version: string;
  filters?: Partial<FilterSpec>;
}

interface CurrentStats {
  readonly rowCount: number;
  readonly columnStats: Readonly<Record<string, LayerColumnStats>>;
}

export function combineStats(
  layerMetadata: LayerMetadata,
  ...stats: LayerStats[]
): LayerStats {
  warning(!_.isEmpty(layerMetadata), 'Missing metadata while combining stats');
  if (stats.length < 1) {
    return EMPTY_OBJECT;
  }

  const columnsByKey = _.keyBy(layerMetadata.columns, 'key');
  const accumulator: CurrentStats = {
    rowCount: stats[0].row_count ?? 0,
    columnStats: _.keyBy(stats[0].column_stats, 'column_key'),
  };

  const result = stats
    .slice(1)
    .reduce((currentStats, layerStats): CurrentStats => {
      if (_.isEmpty(layerStats)) {
        return currentStats;
      }
      const { row_count: rowCount = 0, column_stats: columnStats = [] } =
        layerStats;
      return combineColumnStats(
        columnsByKey,
        rowCount,
        columnStats,
        currentStats,
      );
    }, accumulator);

  const { columns = [] } = layerMetadata;
  // now convert back to layerstats
  return {
    row_count: result.rowCount,
    column_stats: columns
      .map(column => {
        return result.columnStats[column.key];
      })
      .filter(Boolean),
  };
}

export function combineColumnStats(
  columnMetadata: Record<string, LayerColumn>,
  rowCount: number,
  stats: LayerColumnStats[],
  currentStats: CurrentStats,
): CurrentStats {
  const { rowCount: currentRowCount } = currentStats;

  // only combine stats for columns that appear in one or the other stats
  const columnKeys = _.uniq([
    ...stats.map(({ column_key: columnKey }) => columnKey),
    ...Object.keys(currentStats.columnStats),
  ]);
  const newStats = columnKeys.map((columnKey): LayerColumnStats => {
    const column = columnMetadata[columnKey];
    const columnStats = stats.find(
      ({ column_key: statsColumnKey }) => statsColumnKey === columnKey,
    );
    const currentColumnStats = currentStats.columnStats[columnKey];
    if (!columnStats) {
      // Might be null, but that's ok
      return currentColumnStats;
    }
    if (!currentColumnStats) {
      // can't be null
      return columnStats;
    }
    switch (column.metatype) {
      case Metatype.CATEGORICAL: {
        return combineCategoricalStats(columnStats, currentColumnStats);
      }

      case Metatype.NUMERIC: {
        return combineNumericStats(
          rowCount,
          columnStats,
          currentRowCount,
          currentColumnStats,
        );
      }
      default:
        // This should never happen, but if it does, it isn't fatal: we're
        // only ever looking at stats for columns that have already been
        // identified as categorical or numeric
        warning(
          false,
          `Generating stats for non-computable column ${columnKey}`,
        );
    }
  });

  return {
    rowCount: _.sum([rowCount, currentStats.rowCount]),
    columnStats: _.keyBy(newStats, 'column_key'),
  };
}

function combineCategoricalStats(
  columnStats: LayerColumnStats,
  currentColumnStats: LayerColumnStats,
): LayerColumnStats {
  const values = columnStats?.categorical?.values ?? [];
  const currentValues = currentColumnStats?.categorical?.values ?? [];
  const allValues = _.uniq([
    ...values.map(({ value }) => value),
    ...currentValues.map(({ value }) => value),
  ]);
  const newBuckets = allValues
    .map(bucketValue => {
      const currentBucket = currentValues.find(
        ({ value }) => value === bucketValue,
      );
      const bucket = values.find(({ value }) => value === bucketValue);
      if (!currentBucket) {
        return bucket;
      }
      if (!bucket) {
        return currentBucket;
      }

      // NOTE: we 're ignoring min/max for categorical values
      const { value, count } = bucket;

      const newBucket: ValueBucket = {
        value,
        count: _.sum([count + currentBucket.count]),
      };
      return newBucket;
    })
    .filter(bucket => !!bucket);
  return {
    ...columnStats,
    categorical: {
      ...columnStats.categorical,
      // TODO: technically the server could give us a values_count higher than
      // the buckets shown, in which case we'd track "extra" buckets in each merge, but the
      // math isn't really worth the complication
      values_count: newBuckets.length,
      values: newBuckets,
    },
  };
}

function combineNumericStats(
  rowCount: number,
  columnStats: LayerColumnStats,
  currentRowCount: number,
  currentColumnStats: LayerColumnStats,
): LayerColumnStats {
  const { numeric } = columnStats;
  const { numeric: currentNumeric } = currentColumnStats;
  const { min, max, avg, sum } = numeric;
  return {
    ...currentColumnStats,
    numeric: {
      ...currentColumnStats.numeric,
      min: _.min([min, currentNumeric.min]),
      max: _.max([max, currentNumeric.max]),
      avg:
        (avg * rowCount + currentNumeric.avg * currentRowCount) /
        (rowCount + currentRowCount),
      sum: sum + currentNumeric.sum,
    },
  };
}

export function generateStats(
  layerMetadata: LayerMetadata,
  data: Record<string, NativeValue>[],
): LayerStats {
  const { columns = [] } = layerMetadata;
  const columnStats = columns.map((column): LayerColumnStats => {
    const columnData = data
      .map(row => row[column.key])
      .filter(datum => datum !== undefined);
    switch (column.metatype) {
      case Metatype.CATEGORICAL:
        return generateCategoricalStats(column, columnData as string[]);

      case Metatype.NUMERIC:
        return generateNumericStats(
          column,
          (columnData as number[]).filter(Number.isFinite),
        );

      default:
        // This is a perfectly normal case for non-indexed columns, and will
        // be filtered out later.
        return null;
    }
  });
  return {
    row_count: data.length,
    column_stats: columnStats.filter(Boolean),
  };
}

function generateCategoricalStats(
  column: LayerColumn,
  data: string[],
): LayerColumnStats {
  const counts = _.countBy(data);
  return {
    column_key: column.key,
    categorical: {
      values: data.map((datum): ValueBucket => {
        return {
          value: datum,
          count: counts[datum],
          // TODO: other values?
        };
      }),
    },
  };
}

function generateNumericStats(
  column: LayerColumn,
  data: number[],
): LayerColumnStats {
  const [min, max] = extent(data);
  const avg = mean(data);
  return {
    column_key: column.key,
    numeric: {
      min,
      max,
      avg,
    },
  };
}
