/**
 * UrbanFootprint App API
 * API for serving UrbanFootprint Application
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * True if left is greater than or equal to right.
 */
export interface ExpressionGreaterThanOrEquals<
  Tleft extends object = any,
  Tright extends object = any,
> {
  /**
   * Expression function, set to \">=\".
   */
  fn: ExpressionGreaterThanOrEquals.FnEnum;
  /**
   * left hand side
   */
  left: Tleft;
  /**
   * right hand side
   */
  right: Tright;
}
export namespace ExpressionGreaterThanOrEquals {
  export type FnEnum = '>=';
  export const FnEnum = {
    GreaterThanOrEqualTo: '>=',
  } as const;
}
