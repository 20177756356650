import { ColumnKey } from 'uf/layers';

export const DEFAULT_GEO_COLUMN_KEY = '*';
export const GEOMETRY_KEY = 'geometry_key';

export function isDefaultGeoColumnKey(columnKey: ColumnKey) {
  return columnKey === DEFAULT_GEO_COLUMN_KEY;
}

/**
 * Translate '*' to 'geometry_key' if necessary
 */
export function getColumnKey(columnKey: ColumnKey) {
  // The * column is actually called "geometry_key" in metadata
  return isDefaultGeoColumnKey(columnKey) ? GEOMETRY_KEY : columnKey;
}

export function isGeometryKey(columnKey: ColumnKey) {
  return columnKey === GEOMETRY_KEY;
}
