import { combineEpics } from 'uf/base/epics';
import invitationEpics from './invitations';
import userNotifications from './notifications';
import routingEpics from './routing';
import userEpics from './user';

export default combineEpics(
  {
    routingEpics,
    userEpics,
    invitationEpics,
    userNotifications,
  },
  'reportingEpics',
);
