import { AnyAction } from 'redux';

import { searchActionTypes } from './ActionTypes';
import { LayerDataResult } from './index';
import { SearchState } from './state';

const initialState: SearchState = {
  datasets: {},
  searches: {},
};

export default function search(
  state = initialState,
  action: AnyAction = { type: null },
): SearchState {
  switch (action.type) {
    case searchActionTypes.LOAD: {
      return {
        ...state,
        searches: {
          ...state.searches,
          [action.searchKey]: {
            ...state.searches[action.searchKey],
            loading: true,
            loaded: false,
            promise: action.promise,
            datasetId: action.datasetId,
            searchKey: action.searchKey,
          },
        },
      };
    }

    case searchActionTypes.SUCCESS: {
      return {
        ...state,
        datasets: {
          ...state.datasets,
          [action.datasetId]: updateDataset(
            state.datasets[action.datasetId],
            action.result,
          ),
        },
        searches: {
          ...state.searches,
          [action.searchKey]: {
            loading: false,
            loaded: true,
            datasetId: action.datasetId,
            searchKey: action.searchKey,
            data: extractIds(action.result.items),
          },
        },
      };
    }

    case searchActionTypes.FAILURE:
      return {
        ...state,
        searches: {
          ...state.searches,
          [action.searchKey]: {
            loading: false,
            loaded: false,
            datasetId: action.datasetId,
            searchKey: action.searchKey,
            error: action.error,
          },
        },
      };

    default:
      return state;
  }
}

function updateDataset(dataset, result: LayerDataResult) {
  const { items } = result;
  // ..super naive: just put all items in the dataset
  const updatedDataset = {
    ...dataset,
  };

  items.forEach(item => {
    updatedDataset[item._internalSearchKey] = item;
  });

  return updatedDataset;
}

function extractIds(items: Record<string, string>[]) {
  return items.map(item => item._internalSearchKey);
}
