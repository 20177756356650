import { Action } from 'redux';

const WEBSOCKETS_PREFIX = 'uf/websockets';

export const WEBSOCKETS_INITIALIZED = 'uf/websockets/INITIALIZED';
export interface WebsocketsInitializedAction {
  type: typeof WEBSOCKETS_INITIALIZED;
}
export const WEBSOCKETS_READY = 'uf/websockets/READY';

export interface WebsocketsReadyAction {
  type: typeof WEBSOCKETS_READY;
}
export const WEBSOCKETS_TERMINATED = 'uf/websockets/TERMINATED';

export interface WebsocketsTerminatedAction {
  type: typeof WEBSOCKETS_TERMINATED;
}
export const WEBSOCKETS_RECEIVE_MESSAGE = `${WEBSOCKETS_PREFIX}/RECEIVE_MESSAGE`;

export const WEBSOCKETS_RECEIVE_ERROR = `${WEBSOCKETS_PREFIX}/RECEIVE_ERROR`;

export const WEBSOCKETS_SENT_MESSAGE = `${WEBSOCKETS_PREFIX}/SENT_MESSAGE`;

export interface WebsocketMessageAction<T = any> extends Action {
  type:
    | typeof WEBSOCKETS_RECEIVE_ERROR
    | typeof WEBSOCKETS_RECEIVE_MESSAGE
    | typeof WEBSOCKETS_SENT_MESSAGE;
  message: T;

  event?: MessageEvent;

  error?: any;
  exception?: any;
}
