import { Position, Tooltip } from '@blueprintjs/core';
import React, { PureComponent, ReactNode } from 'react';

interface TooltipProps<D> {
  /**
   * The x position for the tooltip, set by VictoryTooltip
   */
  x?: number;
  /**
   * The y position for the tooltip, set by VictoryTooltip
   */
  y?: number;

  dx?: number;
  dy?: number;

  position: Position;
  /**
   * The height of the element we're hovering over, set by VictoryTooltip
   */
  height?: number;
  /**
   * The width of the element we're hovering over, set by VictoryTooltip
   */
  width?: number;
  datum?: D;
  seriesKey: string;
  getDatumLabel: (datum: D, key: string) => ReactNode;
  center?: { x: number; y: number };
}

export class BarChartTooltip<D> extends PureComponent<TooltipProps<D>> {
  render() {
    const { x, y, dx, dy, getDatumLabel, seriesKey, datum, position } =
      this.props;

    // x,y come from Victory, dx, dy come from our own adjustments
    const tooltipX = x + dx;
    const tooltipY = y + dy;

    const datumLabel = getDatumLabel(datum, seriesKey);

    return (
      <Tooltip
        isOpen
        boundary="window"
        position={position}
        wrapperTagName="g"
        targetTagName="g"
        content={<div>{datumLabel}</div>}>
        <foreignObject x={tooltipX} y={tooltipY} width={1} height={1}>
          <div />
        </foreignObject>
      </Tooltip>
    );
  }
}
