import { mdiArrowLeft, mdiChartBar } from '@mdi/js';
import classNames from 'classnames';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { t } from 'ttag';

import { getActiveAnalysisModuleKey } from 'uf/explore/selectors/analysis';
import rootStyles from 'uf/styles/root.module.css';
import AnalysisModuleDetails from 'uf/ui/analysis/AnalysisModuleDetails/AnalysisModuleDetails';
import AnalysisModules from 'uf/ui/analysis/AnalysisModules/AnalysisModules';
import ErrorBoundary from 'uf/ui/base/ErrorBoundary/ErrorBoundary';
import Pane from 'uf/ui/base/Pane/Pane';
import PaneHeader from 'uf/ui/base/Pane/PaneHeader';
import styles from './AnalysisPane.module.css';
import { setUIProperty as setUIPropertyAction } from 'uf/explore/actions';
import CloseButton from 'uf/ui/base/CloseButton/CloseButton';
import { Tooltip2 } from '@blueprintjs/popover2';
import { setActiveAnalysisModuleKey } from 'uf/explore/actions/analysis';

interface OwnProps {
  width?: number;
}

const paneClassName = classNames(
  rootStyles.scrollY,
  styles.analysisPane,
  'AnalysisPane',
);

const AnalysisPane: React.FC<OwnProps> = ({ width = 0 }) => {
  const dispatch = useDispatch();
  const moduleKey = useSelector(getActiveAnalysisModuleKey);
  const showDetails = !!moduleKey;

  const onCloseAnalysisDetails = () => {
    dispatch(setActiveAnalysisModuleKey(null));
  };

  const onSetActiveModule = (activeModuleKey: string) => {
    dispatch(
      setActiveAnalysisModuleKey(activeModuleKey, {
        activateLayers: true,
      }),
    );
  };

  const onCloseAnalysisPane = () => {
    dispatch(setUIPropertyAction('analysisUIVisible', false));
  };

  const headerTitle = showDetails
    ? t`Back to all modules`
    : t`Analysis Modules`;
  const headerIconPath = showDetails ? mdiArrowLeft : mdiChartBar;
  const headerIconOnClick = showDetails ? onCloseAnalysisDetails : null;

  return (
    <Pane column height="100%" style={{ width }} className={paneClassName}>
      <div className="tw-absolute tw-top-3 tw-right-3 tw-z-50">
        <Tooltip2
          position="bottom-right"
          className="tw-inline-flex tw-inline-flex"
          content={t`Close pane`}>
          <CloseButton onClose={onCloseAnalysisPane} />
        </Tooltip2>
      </div>
      <ErrorBoundary
        title={t`Error loading analysis pane`}
        componentName="AnalysisPane">
        <PaneHeader
          sticky
          className="h4 AnalysisPane-header tw-z-10"
          iconPath={headerIconPath}
          title={headerTitle}
          onClick={headerIconOnClick}
        />
        {!showDetails && (
          <AnalysisModules
            disableActiveStyling
            activeModuleKey={moduleKey}
            onSetActiveModule={onSetActiveModule}
          />
        )}
        {showDetails && <AnalysisModuleDetails moduleKey={moduleKey} />}
      </ErrorBoundary>
    </Pane>
  );
};

export default AnalysisPane;
