import colors from 'material-colors';

import {
  CuratedColumnSymbology,
  CUSTOM_THEME,
  DEFAULT_OPACITY,
  DistributionTypes,
  InterpolationTypes,
  PaintColorTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

interface CPUCLayer {
  layerPath: string;
  color: string;
  label: string;
  value: number;
}

const styleCPUCLayerList: CPUCLayer[] = [
  {
    layerPath:
      '/public/dataset/cpuc_fire_threat_map_tier_2_elevat33679654_2020_04_23_22_34',
    color: colors.orange[700],
    label: 'CPUC Tier-2',
    value: 2,
  },
  {
    layerPath:
      '/public/dataset/cpuc_fire_threat_map_tier_3_extrem30766578_2020_04_23_22_34',
    color: colors.red[700],
    label: 'CPUC Tier-3',
    value: 3,
  },
  {
    layerPath:
      '/public/dataset/cpuc_high_hazard_zone_map_zone_1_c21515513_2020_04_23_22_34',
    color: colors.yellow[700],
    label: 'HHZ Zone-1',
    value: 1,
  },
];

const symbologies: Record<
  string,
  Record<string, CuratedColumnSymbology[]>
> = {};

styleCPUCLayerList.forEach(cpucLayer => {
  symbologies[cpucLayer.layerPath] = {
    hazzone: [
      {
        type: SymbologyTypes.FILL,
        paint: {
          'fill-color': {
            property: 'hazzone',
            type: PaintColorTypes.INTERVAL,
            stops: [
              {
                value: cpucLayer.value,
                label: cpucLayer.label,
                color: cpucLayer.color,
              },
            ],
          },
          'fill-opacity': DEFAULT_OPACITY,
        },
        display_hints: {
          scale: SymbologyScales.NUMERIC,
          distribution: DistributionTypes.DEFAULT,
          interpolation: InterpolationTypes.INTERVAL,
          theme: CUSTOM_THEME,
        },
      },
    ],
  };
});

export default symbologies;
