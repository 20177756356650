import {
  CuratedColumnSymbology,
  InterpolationTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/public/dataset/zoning_san_jose': {
    zoning: [
      {
        display_hints: {
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          scale: SymbologyScales.CATEGORICAL,
          theme: 'custom',
        },
        paint: {
          'fill-color': {
            default: '#98df8a',
            property: 'zoning',
            stops: [
              {
                color: '#e3fed5',
                value: 'A',
              },
              {
                color: '#e3fed5',
                value: 'A(PD)',
              },
              {
                color: '#f67dc9',
                value: 'CG',
              },
              {
                color: '#f67dc9',
                value: 'CG(PD)',
              },
              {
                color: '#decfde',
                value: 'CIC',
              },
              {
                color: '#decfde',
                value: 'CIC(PD)',
              },
              {
                color: '#fd92bd',
                value: 'CN',
              },
              {
                color: '#fd92bd',
                value: 'CN(PD)',
              },
              {
                color: '#fed5f4',
                value: 'CO',
              },
              {
                color: '#fed5f4',
                value: 'CO(PD)',
              },
              {
                color: '#f9d0d9',
                value: 'CP',
              },
              {
                color: '#f9d0d9',
                value: 'CP(PD)',
              },
              {
                color: '#d86ea0',
                value: 'DC',
              },
              {
                color: '#b2686b',
                value: 'DC-NT1',
              },
              {
                color: '#b9baba',
                value: 'HI',
              },
              {
                color: '#b9baba',
                value: 'HI(PD)',
              },
              {
                color: '#b4c5dc',
                value: 'IP',
              },
              {
                color: '#b4c5dc',
                value: 'IP(PD)',
              },
              {
                color: '#e0e2e2',
                value: 'LI',
              },
              {
                color: '#e0e2e2',
                value: 'LI(PD)',
              },
              {
                color: '#fc8e90',
                value: 'MS-C',
              },
              {
                color: '#fd8e90',
                value: 'MS-G',
              },
              {
                color: '#e3fed5',
                value: 'OS',
              },
              {
                color: '#e3fed5',
                value: 'OS(PD)',
              },
              {
                color: '#bcf7fe',
                value: 'PQP',
              },
              {
                color: '#fffeac',
                value: 'R-1-1',
              },
              {
                color: '#fffeac',
                value: 'R-1-1(PD)',
              },
              {
                color: '#fffeac',
                value: 'R-1-2',
              },
              {
                color: '#fffeac',
                value: 'R-1-2(PD)',
              },
              {
                color: '#fffeac',
                value: 'R-1-5',
              },
              {
                color: '#fffeac',
                value: 'R-1-5(CL)',
              },
              {
                color: '#fffeac',
                value: 'R-1-5(PD)',
              },
              {
                color: '#fffeac',
                value: 'R-1-8',
              },
              {
                color: '#fffeac',
                value: 'R-1-8(CL)',
              },
              {
                color: '#fffeac',
                value: 'R-1-8(PD)',
              },
              {
                color: '#fffeac',
                value: 'R-1-RR',
              },
              {
                color: '#fede82',
                value: 'R-2',
              },
              {
                color: '#fede82',
                value: 'R-2(PD)',
              },
              {
                color: '#d3b19c',
                value: 'R-M',
              },
              {
                color: '#d3b19c',
                value: 'R-M(CL)',
              },
              {
                color: '#fffeac',
                value: 'R-MH',
              },
              {
                color: '#fffeac',
                value: 'R-M (PD)',
              },
              {
                color: '#d3b19c',
                value: 'R-M(PD)',
              },
              {
                color: '#6f99f6',
                value: 'TEC',
              },
              {
                color: '#abc7fd',
                value: 'WATER',
              },
            ],
            type: 'categorical',
          },
          'fill-opacity': 0.9,
        },
        type: SymbologyTypes.FILL,
      },
      {
        display_hints: {
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          scale: SymbologyScales.CATEGORICAL,
          theme: 'custom',
        },
        paint: {
          'line-color': 'transparent',
          'line-opacity': 0.9,
          'line-width': 1,
        },
        type: SymbologyTypes.LINE,
      },
    ],
  },
};

export default symbologies;
