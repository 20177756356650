import partial from 'lodash/partial';
import { AnyAction, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import warning from 'warning';

import { setActiveLayer } from 'uf/explore/actions/layers';
import { makeGetLayerModuleKey } from 'uf/explore/selectors/analysis';
import {
  getActiveLayerId,
  makeGetOrderedLayerInfos,
} from 'uf/explore/selectors/layers';
import { ProjectId } from 'uf/projects';
import { makeGetAnalysisVirtualLayerIds } from 'uf/projects/selectors/layers';
import { ScenarioId } from 'uf/scenarios';
import { UFState } from 'uf/state';
import { ViewId } from 'uf/views';
import { setLayerVisibility } from 'uf/views/actions/layers';
import { setUIProperty } from '.';

/**
 * Set the explore-wide current analysis module.
 * Note that you can pass { activateLayers: true } to also make the explore mode turn
 * on any relevant layers for the current scenario.
 */
export interface SetActiveAnalysisModuleKeyOptions {
  activateLayers: boolean;
}

export const setActiveAnalysisModuleKey = partial(
  setUIProperty,
  'activeAnalysisModuleKey',
);

export function setActiveLayerByModuleKey(
  projectId: ProjectId,
  viewId: ViewId,
  scenarioId: ScenarioId,
  moduleKey: string,
): ThunkAction<any, UFState, any, AnyAction> {
  warning(
    !!moduleKey,
    'Missing module key while trying to set an active layer',
  );
  const getLayerModuleKey = makeGetLayerModuleKey();
  const getAnalysisVirtualLayerIdsByKey = makeGetAnalysisVirtualLayerIds();
  return (dispatch: Dispatch, getState) => {
    const state = getState();

    // TODO: projectId and activeLayerId should be parameters to this function
    const activeLayerId = getActiveLayerId(state, {
      projectId,
      scenarioId,
      viewId,
    });
    const activeLayerModuleKey = getLayerModuleKey(state, {
      layerId: activeLayerId,
    });

    if (moduleKey === activeLayerModuleKey) {
      return;
    }

    dispatch(
      updateAnalysisLayerVisibility(projectId, viewId, scenarioId, moduleKey),
    );

    const virtualLayerIds = getAnalysisVirtualLayerIdsByKey(state, {
      projectId,
      scenarioId,
      moduleKey,
    });

    if (virtualLayerIds.length) {
      dispatch(setActiveLayer(projectId, virtualLayerIds[0], true));
    }
  };
}

function updateAnalysisLayerVisibility(
  projectId: ProjectId,
  viewId: ViewId,
  scenarioId: ScenarioId,
  moduleKey: string,
) {
  const getLayerModuleKey = makeGetLayerModuleKey();
  const getOrderedLayerInfos = makeGetOrderedLayerInfos();
  return (dispatch: Dispatch, getState) => {
    const layers = getOrderedLayerInfos(getState(), {
      projectId,
      scenarioId,
      viewId,
    });
    const analysisLayers = layers.filter(
      layer => layer.layerInfo.layer_type === 'analysis',
    );

    // Activate the *first* layer for a the analysis module
    analysisLayers.some(layer => {
      const layerModuleKey = getLayerModuleKey(getState(), {
        layerId: layer.layerInfo.full_path,
      });
      if (layerModuleKey === moduleKey) {
        dispatch(
          setLayerVisibility(projectId, layer.layerOrderKey, viewId, true),
        );
        return true;
      }
      // keep going
      return false;
    });

    // Deactivate all analysis modules for other layers
    analysisLayers.forEach(layer => {
      const layerModuleKey = getLayerModuleKey(getState(), {
        layerId: layer.layerInfo.full_path,
      });
      // turn off all other analysis layers
      if (layerModuleKey !== moduleKey) {
        dispatch(
          setLayerVisibility(projectId, layer.layerOrderKey, viewId, false),
        );
      }
    });
  };
}
