import { boundMethod } from 'autobind-decorator';
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import wrapDisplayName from 'recompose/wrapDisplayName';
import { bindActionCreators, Dispatch } from 'redux';
import { ThunkActionDispatch } from 'redux-thunk';

import { omitHocProps } from 'uf/base/hocs';
import { ensureLayerData } from 'uf/explore/actions/layers';
import { LayerId } from 'uf/layers';
import { getLayerDataSearchKey } from 'uf/layers/filters';
import { makeGetSearchResultsWithState } from 'uf/search/selectors';
import { SearchResult } from 'uf/search/state';

interface OwnProps {
  // Required from parent
  layerId: LayerId;
  query: any;
}

export interface ProvidedProps {
  // Passed to wrapped component
  layerSearchData: SearchResult;
}

interface DispatchProps {
  // Not passed to wrapped component
  __hoc__ensureLayerData: ThunkActionDispatch<typeof ensureLayerData>;
}

/**
 * Params:
 *   layerIdPropName: The prop where the layer id comes from
 * To use:
 */
export function withLayerSearchData<O = OwnProps, P = ProvidedProps>(
  layerIdPropName = 'layerId',
  queryPropName = 'query',
  layerSearchDataPropName = 'layerSearchData',
) {
  return WrappedComponent => {
    class WithLayerSearchDataHOC extends Component<O & P & DispatchProps> {
      componentDidMount() {
        this.ensureLayerSearchData();
      }

      componentDidUpdate(prevProps) {
        if (this.propsChanged(prevProps)) {
          this.ensureLayerSearchData();
        }
      }

      @boundMethod
      ensureLayerSearchData() {
        const layerId = this.props[layerIdPropName];
        const query = this.props[queryPropName];
        if (layerId) {
          this.props.__hoc__ensureLayerData(layerId, query);
        }
      }

      @boundMethod
      propsChanged(prevProps) {
        const layerId = this.props[layerIdPropName];
        const query = this.props[queryPropName];
        const changed =
          !_.isEqual(layerId, prevProps[layerIdPropName]) ||
          !_.isEqual(query, prevProps[queryPropName]);
        return changed;
      }
      render() {
        const props = omitHocProps(this.props);
        return <WrappedComponent {...props} />;
      }
    }

    const wrapped = connect(
      makeMapStateToProps,
      mapDispatchToProps,
    )(WithLayerSearchDataHOC as React.ComponentClass | React.SFC);
    wrapped.displayName = wrapDisplayName(
      WrappedComponent,
      'WithLayerSearchDataHOC',
    );
    return wrapped;
  };
  function makeMapStateToProps() {
    const getLayerSearchData = makeGetSearchResultsWithState();
    return (state, props) => {
      const layerId = props[layerIdPropName];
      const query = props[queryPropName];
      const searchKey = `search:${getLayerDataSearchKey(layerId, query)}`;
      return {
        [layerSearchDataPropName]: getLayerSearchData(state, { searchKey }),
      };
    };
  }
  function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
    return bindActionCreators(
      {
        __hoc__ensureLayerData: ensureLayerData,
      },
      dispatch,
    );
  }
}
