/**
 * UrbanFootprint App API
 * API for serving UrbanFootprint Application
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Restrict to data contained with the specified polygon.
 */
export interface ExpressionWithinPolygon<> {
  /**
   * polygon coordinates
   */
  coordinates: Array<object>;
  /**
   * Expression function, set to \"within_polygon\".
   */
  fn: ExpressionWithinPolygon.FnEnum;
}
export namespace ExpressionWithinPolygon {
  export type FnEnum = 'within_polygon';
  export const FnEnum = {
    WithinPolygon: 'within_polygon',
  } as const;
}
