import { Feature, Polygon } from 'geojson';
import warning from 'warning';

import {
  BuiltFormPainterEmploymentAllocations,
  BuiltFormPainterParams,
  BuiltFormPainterResidentialAllocation,
  GeoJSONGeometry,
  LayerReference,
  PaintingActionInfo,
  SplitCanvasPainterParams,
  SplitCanvasPainterReplacement,
  SplitCanvasPainterSplit,
} from 'uf-api';
import {
  enqueuePaintBaseScenarioCanvasActionTypes,
  enqueuePaintScenarioCanvasActionTypes,
} from 'uf-api/api/paint.service';
import { BuiltFormTypes } from 'uf/base/builtForms';
import { dispatchAsyncAction } from 'uf/data/loader';
import {
  ADD_TO_RECENT_PAINT_HISTORY,
  AddToRecentPaintHistoryAction,
  CLEAR_EMPLOYMENT_DIRECT_PAINTING,
  CLEAR_RESIDENTIAL_DIRECT_PAINTING,
  ClearEmploymentDirectPaintingAction,
  ClearResidentialDirectPaintingAction,
  SET_CURRENT_BUILT_FORM,
  SET_HOVER_BUILT_FORM,
  SetCurrentBuiltFormAction,
  SetHoverBuiltFormAction,
  UPDATE_EMPLOYMENT_DIRECT_PAINTING,
  UPDATE_RESIDENTIAL_DIRECT_PAINTING,
  UpdateEmploymentDirectPaintingAction,
  UpdateResidentialDirectPaintingAction,
} from 'uf/explore/ActionTypes';
import {
  convertFiltersToExpressions,
  Expression,
  FilterSpec,
} from 'uf/layers/filters';
import { EmploymentDirectPaintingState } from 'uf/painting/employment';
import { makePaintScenarioKey, PaintScenarioExtra } from 'uf/painting/painting';
import { ResidentialDirectPainting } from 'uf/painting/residential';
import { ProjectId } from 'uf/projects';

import {
  enqueuePaintBaseScenarioCanvasAPI,
  enqueuePaintScenarioAPI,
} from './apis';

export function setCurrentBuiltForm(
  projectId: ProjectId,
  builtFormType: BuiltFormTypes,
  builtFormKey: string,
): SetCurrentBuiltFormAction {
  return {
    type: SET_CURRENT_BUILT_FORM,
    projectId,
    builtFormType,
    builtFormKey,
  };
}

export function setHoverBuiltForm(
  projectId: ProjectId,
  builtFormType: BuiltFormTypes,
  builtFormKey: string,
): SetHoverBuiltFormAction {
  return {
    type: SET_HOVER_BUILT_FORM,
    projectId,
    builtFormType,
    builtFormKey,
  };
}

interface PaintScenarioOptions {
  applyNetDensity?: boolean;
  keyOnly?: boolean;
  builtFormsLayer?: LayerReference;
  residential?: BuiltFormPainterResidentialAllocation;
  employment?: BuiltFormPainterEmploymentAllocations;
}

export function enqueuePaintScenario(
  projectId: ProjectId,
  scenarioKey: string,
  filters: Partial<FilterSpec>,
  newBuiltFormKey: string,
  options?: PaintScenarioOptions,
) {
  const { extra, expressions, params } = makePaintParams(
    projectId,
    scenarioKey,
    filters,
    newBuiltFormKey,
    options,
  );
  const paintOperation = enqueuePaintScenarioAPI(
    projectId,
    scenarioKey,
    expressions,
    'built_form',
    params,
    false,
  );

  return dispatchAsyncAction(
    enqueuePaintScenarioCanvasActionTypes,
    paintOperation,
    {
      key: extra.key,
      extra,
    },
  );
}

function makePaintParams(
  projectId: ProjectId,
  scenarioKey: string,
  filters: Partial<FilterSpec>,
  newBuiltFormKey: string,
  options: PaintScenarioOptions,
): {
  expressions: Expression[];
  params: BuiltFormPainterParams;
  extra: PaintScenarioExtra;
} {
  const expressions = convertFiltersToExpressions(filters);

  const { applyNetDensity, keyOnly, builtFormsLayer, residential, employment } =
    options;

  const { version, details } = builtFormsLayer;
  const latestVersion = details?.latest_version;

  if (latestVersion) {
    warning(
      version === latestVersion,
      'User cannot paint, version and latest_version are mismatched',
    );
  }

  const params: BuiltFormPainterParams = {
    built_form_key: newBuiltFormKey,
    apply_net_density: applyNetDensity,
    built_forms_layer: {
      path: builtFormsLayer.full_path,
      version,
    },
    residential,
    employment,
    key_only: keyOnly,
  };

  const extra: PaintScenarioExtra = {
    projectId,
    scenarioKey,
    filters,
    painter: 'built_form',
    params,
    key: makePaintScenarioKey(projectId, scenarioKey),
  };
  return { extra, expressions, params };
}

export function enqueueRevertToBase(
  projectId: ProjectId,
  scenarioKey: string,
  filters: Partial<FilterSpec>,
  isBaseScenario: boolean,
) {
  const expressions = convertFiltersToExpressions(filters);

  const paintOperation = enqueuePaintScenarioAPI(
    projectId,
    scenarioKey,
    expressions,
    'revert_to_base',
    {},
    isBaseScenario,
  );
  const actionTypes = isBaseScenario
    ? enqueuePaintBaseScenarioCanvasActionTypes
    : enqueuePaintScenarioCanvasActionTypes;
  const key = makePaintScenarioKey(projectId, scenarioKey);
  return dispatchAsyncAction<string, PaintScenarioExtra>(
    actionTypes,
    paintOperation,
    {
      projectId,
      scenarioKey,
      filters,
      painter: 'revert_to_base',
      key,
      extra: {
        projectId,
        filters,
        key,
        painter: 'revert_to_base' as PaintingActionInfo.PainterEnum,
        params: {},
        scenarioKey,
      },
    },
  );
}

export function enqueueDeleteFromBaseScenarioCanvas(
  projectId: ProjectId,
  filters: Partial<FilterSpec>,
) {
  const expressions = convertFiltersToExpressions(filters);
  const operation = enqueuePaintScenarioAPI(
    projectId,
    null,
    expressions,
    'delete',
    {},
    true,
  );
  return dispatchAsyncAction<string, PaintScenarioExtra>(
    enqueuePaintBaseScenarioCanvasActionTypes,
    operation,
    // Should only have one ongoing paint per projectId
    {
      key: projectId,
      projectId,
      extra: {
        projectId,
        scenarioKey: null,
        filters,
        key: projectId,
        painter: 'delete',
        params: {},
      },
    },
  );
}

export function enqueuePaintBaseScenarioCanvas(
  projectId: ProjectId,
  filters: Partial<FilterSpec>,
  newBuiltFormKey: string,
  options: PaintScenarioOptions,
) {
  const {
    builtFormsLayer,
    applyNetDensity = false,
    keyOnly,
    residential,
    employment,
  } = options;
  const { expressions, params, extra } = makeBasePaintParams(
    projectId,
    filters,
    builtFormsLayer,
    newBuiltFormKey,
    applyNetDensity,
    keyOnly,
    residential,
    employment,
  );
  const operation = enqueuePaintBaseScenarioCanvasAPI(
    projectId,
    expressions,
    params,
  );
  return dispatchAsyncAction(
    enqueuePaintBaseScenarioCanvasActionTypes,
    operation,
    {
      key: projectId,
      extra,
    },
  );
}

// TODO: unify this with makePaintParams, after we switch to enqueue-based painting
function makeBasePaintParams(
  projectId: ProjectId,
  filters: Partial<FilterSpec>,
  builtFormsLayer: LayerReference,
  newBuiltFormKey: string,
  applyNetDensity: boolean,
  keyOnly: boolean,
  residential: BuiltFormPainterResidentialAllocation,
  employment: BuiltFormPainterEmploymentAllocations,
): {
  expressions: Expression[];
  params: BuiltFormPainterParams;
  extra: PaintScenarioExtra;
} {
  const expressions = convertFiltersToExpressions(filters);
  const { version } = builtFormsLayer;

  const params: BuiltFormPainterParams = {
    built_form_key: newBuiltFormKey,
    apply_net_density: applyNetDensity,
    built_forms_layer: {
      path: builtFormsLayer.full_path,
      version,
    },
    key_only: keyOnly,
    residential,
    employment,
  };
  const extra: PaintScenarioExtra = {
    // Should only have one ongoing paint per projectId
    key: projectId,
    projectId,
    filters,
    painter: 'built_form',
    params,
    scenarioKey: null,
  };
  return { expressions, params, extra };
}

export function enqueueUndoPaintingAction(
  projectId: ProjectId,
  scenarioKey: string,
  actionKey: string,
  isBaseScenario: boolean,
) {
  const params = {
    action: actionKey,
  };
  const paintOperation = enqueuePaintScenarioAPI(
    projectId,
    scenarioKey,
    null,
    'undo',
    params,
    isBaseScenario,
  );
  const key = makePaintScenarioKey(projectId, scenarioKey);

  const extra: PaintScenarioExtra = {
    key,
    projectId,
    painter: 'built_form',
    params,
    scenarioKey,
    filters: null,
  };

  const actionTypes = isBaseScenario
    ? enqueuePaintBaseScenarioCanvasActionTypes
    : enqueuePaintScenarioCanvasActionTypes;
  return dispatchAsyncAction(actionTypes, paintOperation, {
    projectId,
    scenarioKey,
    painter: 'undo',
    extra,
    key,
  });
}

export function enqueueRedoPaintingAction(
  projectId: ProjectId,
  scenarioKey: string,
  actionKey: string,
  isBaseScenario: boolean,
) {
  const params = {
    action: actionKey,
  };
  const paintOperation = enqueuePaintScenarioAPI(
    projectId,
    scenarioKey,
    null,
    'redo',
    params,
    isBaseScenario,
  );
  const key = makePaintScenarioKey(projectId, scenarioKey);
  const extra: PaintScenarioExtra = {
    key,
    projectId,
    painter: 'built_form',
    params,
    scenarioKey,
    filters: null,
  };
  const actionTypes = isBaseScenario
    ? enqueuePaintBaseScenarioCanvasActionTypes
    : enqueuePaintScenarioCanvasActionTypes;

  return dispatchAsyncAction(actionTypes, paintOperation, {
    projectId,
    scenarioKey,
    painter: 'redo',
    extra,
    key,
  });
}

export function addToRecentPaintHistory(
  projectId: ProjectId,
  newBuiltFormType: BuiltFormTypes,
  newBuiltFormKey: string,
): AddToRecentPaintHistoryAction {
  return {
    type: ADD_TO_RECENT_PAINT_HISTORY,
    projectId,
    builtFormType: newBuiltFormType,
    builtFormKey: newBuiltFormKey,
  };
}

export function updateResidentialDirectPainting(
  projectId: ProjectId,
  residentialDirectPainting: ResidentialDirectPainting,
): UpdateResidentialDirectPaintingAction {
  return {
    type: UPDATE_RESIDENTIAL_DIRECT_PAINTING,
    projectId,
    value: residentialDirectPainting,
  };
}

export function clearResidentialDirectPainting(
  projectId: ProjectId,
): ClearResidentialDirectPaintingAction {
  return {
    type: CLEAR_RESIDENTIAL_DIRECT_PAINTING,
    projectId,
  };
}

export function updateEmploymentDirectPainting(
  projectId: ProjectId,
  employmentDirectPainting: EmploymentDirectPaintingState,
): UpdateEmploymentDirectPaintingAction {
  return {
    type: UPDATE_EMPLOYMENT_DIRECT_PAINTING,
    projectId,
    value: employmentDirectPainting,
  };
}

export function clearEmploymentDirectPainting(
  projectId: ProjectId,
): ClearEmploymentDirectPaintingAction {
  return {
    type: CLEAR_EMPLOYMENT_DIRECT_PAINTING,
    projectId,
  };
}

export function enqueueSplitCanvasFeatures(
  projectId: ProjectId,
  filters: Partial<FilterSpec>,
  featureIds: string[],
  newFeaturesById: Record<string, Feature<Polygon>[]>,
) {
  const expressions = convertFiltersToExpressions(filters);
  const params: SplitCanvasPainterParams = {
    splits: featureIds.map((featureId): SplitCanvasPainterSplit => {
      const replaceWith = newFeaturesById[featureId].map(
        (newFeature): SplitCanvasPainterReplacement => ({
          geometry: newFeature.geometry as GeoJSONGeometry,
        }),
      );
      return {
        id: featureId,
        replace_with: replaceWith,
      };
    }),
  };

  const paintOperation = enqueuePaintScenarioAPI(
    projectId,
    null, // do not need scenario Id right now
    expressions,
    'split_canvas',
    params,
    true,
  );

  const extra: PaintScenarioExtra = {
    projectId,
    filters,
    scenarioKey: null,
    painter: 'split_canvas',
    params,
    key: projectId,
  };

  return dispatchAsyncAction(
    enqueuePaintBaseScenarioCanvasActionTypes,
    paintOperation,
    {
      key: projectId,
      extra,
    },
  );
}
