import {
  CuratedColumnSymbology,
  DistributionTypes,
  InterpolationTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/public/dataset/florida_dot_aadt_w_truck_fl_2018': {
    aadt: [
      {
        display_hints: {
          distribution: DistributionTypes.CUSTOM,
          interpolation: InterpolationTypes.INTERVAL,
          is_percent: false,
          scale: SymbologyScales.NUMERIC,
          showZero: false,
          theme: 'custom',
        },
        paint: {
          'line-color': {
            default: 'transparent',
            property: 'aadt',
            stops: [
              {
                color: 'rgb(255, 247, 243)',
                label: '40 - 1,500',
                value: 0,
              },
              {
                color: 'rgb(253, 226, 223)',
                label: '1,500 - 3,000',
                value: 1500,
              },
              {
                color: 'rgb(252, 202, 200)',
                label: '3,000 - 4,500',
                value: 3000,
              },
              {
                color: 'rgb(251, 171, 184)',
                label: '4,500 - 6,000',
                value: 4500,
              },
              {
                color: 'rgb(248, 128, 170)',
                label: '6,000 - 7,500',
                value: 6000,
              },
              {
                color: 'rgb(234, 81, 157)',
                label: '7,500 - 10,000',
                value: 7500,
              },
              {
                color: 'rgb(204, 35, 142)',
                label: '10,000 - 15,000',
                value: 10000,
              },
              {
                color: 'rgb(162, 5, 126)',
                label: '15,000 - 30,000',
                value: 15000,
              },
              {
                color: 'rgb(117, 1, 117)',
                label: '30,000 - 50,000',
                value: 30000,
              },
              {
                color: 'rgb(73, 0, 106)',
                label: '50,000 - 235,000',
                value: 50000,
              },
            ],
            type: 'interval',
          },
          'line-opacity': 0.8,
          'line-width': 1,
        },
        type: SymbologyTypes.LINE,
      },
    ],
    truckaadt: [
      {
        display_hints: {
          distribution: DistributionTypes.CUSTOM,
          interpolation: InterpolationTypes.INTERVAL,
          is_percent: false,
          scale: SymbologyScales.NUMERIC,
          showZero: false,
          theme: 'custom',
        },
        paint: {
          'line-color': {
            default: 'transparent',
            property: 'truckaadt',
            stops: [
              {
                color: 'rgb(255, 255, 204)',
                label: '2 - 50',
                value: 0,
              },
              {
                color: 'rgb(255, 240, 169)',
                label: '50 - 100',
                value: 50,
              },
              {
                color: 'rgb(254, 224, 135)',
                label: '100 - 200',
                value: 100,
              },
              {
                color: 'rgb(254, 201, 101)',
                label: '200 - 300',
                value: 200,
              },
              {
                color: 'rgb(254, 171, 75)',
                label: '300 - 400',
                value: 300,
              },
              {
                color: 'rgb(253, 137, 60)',
                label: '400 - 550',
                value: 400,
              },
              {
                color: 'rgb(250, 92, 46)',
                label: '550 - 700',
                value: 550,
              },
              {
                color: 'rgb(236, 48, 35)',
                label: '700 - 1,000',
                value: 700,
              },
              {
                color: 'rgb(211, 17, 33)',
                label: '1,000 - 1,500',
                value: 1000,
              },
              {
                color: 'rgb(175, 2, 37)',
                label: '1,500 - 3,000',
                value: 1500,
              },
              {
                color: 'rgb(128, 0, 38)',
                label: '3,000 - 26,872',
                value: 3000,
              },
            ],
            type: 'interval',
          },
          'line-opacity': 0.9,
          'line-width': 1,
        },
        type: SymbologyTypes.LINE,
      },
    ],
  },
};

export default symbologies;
