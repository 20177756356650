import colors from 'material-colors';

import { DEFAULT_GEO_COLUMN_KEY } from 'uf/layers/geometryKey';
import {
  CuratedColumnSymbology,
  DEFAULT_OPACITY,
  InterpolationTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/public/dataset/morpc_mtp16_gridtazforecast_lu_gridtaz_official2016mtp_forecasts':
    {
      [DEFAULT_GEO_COLUMN_KEY]: [
        {
          type: SymbologyTypes.LINE,
          paint: {
            'line-color': colors.blue[900],
            'line-width': 2,
            'line-opacity': DEFAULT_OPACITY,
          },
          display_hints: {
            scale: SymbologyScales.CATEGORICAL,
            distribution: null,
            interpolation: InterpolationTypes.CATEGORICAL,
            theme: 'material:blue:900',
          },
        },
      ],
    },
};

export default symbologies;
