import _ from 'lodash';
import { createSelector } from 'reselect';

import { ScenarioMetadata } from 'uf-api';
import { EMPTY_ARRAY } from 'uf/base';
import { makeGetFromPropsSelector } from 'uf/base/selector';
import { getLegacyExploreState } from 'uf/explore/selectors/explore';
import { ProjectId } from 'uf/projects';
import { makeGetProjectScenarios } from 'uf/projects/selectors';
import {
  getPendingAndProjectScenarioMetadatas,
  getPendingScenarioMetadatasForProject,
} from 'uf/scenarios/selectors';

export const getExploreScenariosState = createSelector(
  getLegacyExploreState,
  explore => explore.scenarios,
);

export function makeGetOrderedScenarioIdsForProject() {
  return createSelector(
    getExploreScenariosState,
    makeGetFromPropsSelector<ProjectId, 'projectId'>('projectId'),
    (scenarios, projectId): string[] =>
      scenarios.order[projectId] || EMPTY_ARRAY,
  );
}

/**
 * returns a list of scenario metadatas for the given project as ordered by the user.  if no order
 * is found, we default to an alphabetically sorted list.
 */
export function makeGetOrderedScenariosForProject() {
  const getOrderedScenarioIds = makeGetOrderedScenarioIdsForProject();
  const getScenariosForProject = makeGetProjectScenarios();
  return createSelector(
    getPendingAndProjectScenarioMetadatas,
    getOrderedScenarioIds,
    getScenariosForProject,
    getPendingScenarioMetadatasForProject,
    (
      sortedAndPendingScenarios, // sorted alphabetically
      orderedScenarioIds: string[], // ordered by the user
      scenarioMetadatas,
      pendingScenarioMetadatas,
    ): ScenarioMetadata[] => {
      if (!_.isEmpty(orderedScenarioIds)) {
        const metadatas: ScenarioMetadata[] = [
          ...scenarioMetadatas,
          ...pendingScenarioMetadatas,
        ];
        return _.sortBy(metadatas, metadata =>
          orderedScenarioIds.indexOf(metadata.full_path),
        );
      }

      return sortedAndPendingScenarios;
    },
  );
}
