import { ActionsObservable, Epic, ofType } from 'redux-observable';
import { zip as observableZip } from 'rxjs';
import { filter, map, switchMap, withLatestFrom } from 'rxjs/operators';

import { Actions, ScopeTypes, WebsocketMessage } from 'uf-ws/WebsocketActions';
import { SET_ACTIVE_PROJECT, SetActiveProjectAction } from 'uf/app/ActionTypes';
import { getActiveProjectId } from 'uf/app/selectors';
import { combineEpics } from 'uf/base/epics';
import {
  notifyAllUsersOfPresence,
  notifyAllUsersWhenLeavingProject,
  notifyUsersOfPresence,
  presenceActions,
  receivedCollaborationMessage as receivedCollaborationMessageAction,
} from 'uf/collaboration/actions';
import {
  RECEIVE_COLLABORATION_MESSAGE,
  ReceiveCollaborationMessage,
  SEND_COLLABORATION_MESSAGE,
  SendCollaborationMessage,
} from 'uf/collaboration/ActionTypes';
import { Collaborator } from 'uf/collaboration/Collaborator';
import {
  CollaborationMessage,
  CollaborationMessageTypes,
  PresenceInfo,
} from 'uf/collaboration/MessageTypes';
import { UFState } from 'uf/state';
import { getUser } from 'uf/user/selectors/user';
import { sendWebsocketMessage } from 'uf/websockets/actions';
import {
  WebsocketMessageAction,
  WEBSOCKETS_READY,
  WEBSOCKETS_RECEIVE_MESSAGE,
  WEBSOCKETS_TERMINATED,
  WebsocketsTerminatedAction,
} from 'uf/websockets/actionTypes';

type MessageInfoAny = any;
/**
 *  An epic to identify websocket messages that contain a collaboration message, and emit that
 *  message in an action.  A basic websocket message looks like:
 *  WebsocketMessage {
 *    action?: Actions;
 *    scope_type: ScopeTypes;
 *    scope: string;
 *    // identifies the sender of the message
 *    connection_id?: string;
 * }
 *
 *  A CollaborationMessage extends WebsocketMessage with:
 *  CollaborationMessage extends WebsocketMessage {
 *    type: CollaborationMessageTypes;
 *    info?: T;
 *  }
 *
 * the incoming action looks like:
 * {
 *   type: WEBSOCKETS_RECEIVE_MESSAGE
 *   message: WebsocketsMessage
 * }
 *
 * if we determine the websockets message to be a collaboration message we emit
 * {
 *   type: RECEIVE_COLLABORATION_MESSAGE,
 *   message: CollaborationMessage}
 * }
 */
export function convertWebsocketMessageToCollaborationMessage(
  action$: ActionsObservable<
    WebsocketMessageAction<CollaborationMessage<MessageInfoAny>>
  >,
) {
  return action$.pipe(
    ofType(WEBSOCKETS_RECEIVE_MESSAGE),
    filter(action => action.message.action === Actions.NOTIFY),
    filter(action => isCollaborationMessage(action.message)),
    map(action => receivedCollaborationMessageAction(action.message)),
  );
}

function isCollaborationMessage(
  message: any,
): message is CollaborationMessageTypes {
  if (!message) {
    return false;
  }

  if (!message.connection_id) {
    return false;
  }

  return message.type in CollaborationMessageTypes;
}

/**
 *  Epic to handle removing another user's presence from a project.  There is no way for the other
 *  user to send a collaboration message in this case since we are dealing with the websocket
 *  connection itself getting interrupted.  When this happens, the websocket server will send an
 *  UNSUBSCRIBE message to everyone who is present on the project and we handle that here by
 *  removing their presence on the project.  Unfortunately this crosses the boundary between
 *  websocket and collaboration concerns.
 */
export function removePresenceFromProjectWhenUserDisconnects(
  action$: ActionsObservable<WebsocketMessageAction<WebsocketMessage>>,
) {
  return action$.pipe(
    ofType(WEBSOCKETS_RECEIVE_MESSAGE),
    filter(action => action.message.action === Actions.UNSUBSCRIBE),
    filter(({ message }) => message.scope_type === ScopeTypes.PROJECT),
    map(({ message }) => {
      // since the user disconnected, all we know is the connection id.  stub out the collaborator
      // so we can remove them from the list
      const collaborator: Collaborator = {
        connectionId: message.connection_id,
      };
      return presenceActions.removeItem(message.scope, collaborator);
    }),
  );
}

/**
 *  An epic to add another user's presence to the active project we are working on.
 */
export const addPresenceToProject: Epic<
  ReceiveCollaborationMessage<PresenceInfo>,
  any,
  UFState
> = action$ => {
  return action$.pipe(
    ofType(RECEIVE_COLLABORATION_MESSAGE),
    filter(action => isPresentOnProjectMessage(action.message)),
    map(({ message }) => {
      const collaborator: Collaborator = {
        ...message.info.user,
        connectionId: message.connection_id,
      };
      return presenceActions.upsertItem(message.info.projectId, collaborator);
    }),
  );
};

function isPresentOnProjectMessage(
  message: CollaborationMessage<PresenceInfo>,
): boolean {
  return message.type === CollaborationMessageTypes.PRESENT;
}

/**
 *  An epic to remove another user's presence to the active project we are working on.
 */
export const removePresenceFromProject: Epic<
  ReceiveCollaborationMessage<PresenceInfo>,
  any,
  UFState
> = action$ => {
  return action$.pipe(
    ofType(RECEIVE_COLLABORATION_MESSAGE),
    filter(action => action.message.type === CollaborationMessageTypes.LEAVING),
    map(({ message }) => {
      const collaborator: Collaborator = {
        ...message.info.user,
        connectionId: message.connection_id,
      };
      return presenceActions.removeItem(message.info.projectId, collaborator);
    }),
  );
};

/**
 *  An epic to respond to a project subscription notification.  When a project subscription is sent
 *  at the project scope level, we respond directly to the user who sent the messag.  We only
 *  respond if we are subscribed to the project as well.  We ignore the message if we were the
 *  sender.
 */
export const sendPresenceToUser: Epic<
  ReceiveCollaborationMessage<PresenceInfo>,
  any,
  UFState
> = (action$, state$) => {
  return action$.pipe(
    ofType(RECEIVE_COLLABORATION_MESSAGE),
    filter(({ message }) => message.type === CollaborationMessageTypes.PRESENT),
    withLatestFrom(state$),
    // don't respond to self!
    filter(([{ message }, state]) => {
      const { user } = message.info;
      return user.session_id !== getUser(state).session_id;
    }),
    // only respond if message was broadcast to project.  don't respond if sent directly to user.
    filter(([{ message }, state]) => {
      const { scope_type: scopeType } = message;
      return scopeType === ScopeTypes.PROJECT;
    }),
    filter(
      ([{ message }, state]) =>
        message.info.projectId === getActiveProjectId(state),
    ),
    map(([action, state]) => {
      const { user, projectId } = action.message.info;
      return notifyUsersOfPresence(user, projectId, getUser(state));
    }),
  );
};

/**
 * An epic to notify all users subscribed to a project that you are now subscribed as well.
 */
export const sendPresenceOnProjectSwitch: Epic<SetActiveProjectAction, any> = (
  action$,
  state$,
) => {
  // first value comes down from SSR, action is not fired client side so we record it here
  let previousProjectId = getActiveProjectId(state$.value);
  return observableZip(action$.pipe(ofType(WEBSOCKETS_READY))).pipe(
    switchMap(() =>
      action$.pipe(
        ofType(SET_ACTIVE_PROJECT),
        filter(({ value: projectId }) => {
          if (!projectId) {
            return false;
          }
          if (projectId === previousProjectId) {
            return false;
          }
          previousProjectId = projectId;
          return true;
        }),
        map(({ value: projectId }) =>
          notifyAllUsersOfPresence(getUser(state$.value), projectId),
        ),
      ),
    ),
  );
};

/**
 * An epic to notify other users when leaving a project. wait for the app/websockets to initialize
 * before trying to use websockets.
 */
export const notifyUsersWhenLeavingProject: Epic<
  SetActiveProjectAction,
  any,
  UFState
> = (action$, state$) => {
  // first value comes down from SSR, action is not fired client side so we record it here
  let previousProjectId = getActiveProjectId(state$.value);
  return observableZip(action$.pipe(ofType(WEBSOCKETS_READY))).pipe(
    switchMap(() => {
      return action$.pipe(
        ofType(SET_ACTIVE_PROJECT),
        filter(({ value: projectId }) => {
          if (projectId === previousProjectId) {
            return false;
          }
          // sometimes the projectId can get set to 'null'.  i.e. the active project gets deleted.
          if (!previousProjectId) {
            previousProjectId = projectId;
            return false;
          }
          return true;
        }),
        map(({ value: projectId }) => {
          const user = getUser(state$.value);
          const action = notifyAllUsersWhenLeavingProject(
            previousProjectId,
            user,
          );
          previousProjectId = projectId;
          return action;
        }),
      );
    }),
  );
};

export const sendPresenceWhenWebsocketsReady: Epic<
  SetActiveProjectAction,
  any
> = (action$, state$) => {
  return observableZip(action$.pipe(ofType(WEBSOCKETS_READY))).pipe(
    map(() =>
      notifyAllUsersOfPresence(
        getUser(state$.value),
        getActiveProjectId(state$.value),
      ),
    ),
  );
};

export const sendCollaborationWebsocketMessage: Epic<
  SendCollaborationMessage<CollaborationMessage<PresenceInfo>>,
  any
> = action$ => {
  return action$.pipe(
    ofType(SEND_COLLABORATION_MESSAGE),
    // don't try to send websocket nofitications during SSR
    filter(() => !__SERVER__ || __TESTING__),
    map(({ message }) => sendWebsocketMessage(message)),
  );
};

export const clearCollaborationStateWhenWebsocketsCloses: Epic<
  WebsocketsTerminatedAction,
  any,
  UFState
> = action$ => {
  return action$.pipe(
    ofType(WEBSOCKETS_TERMINATED),
    map(() => {
      return presenceActions.clearAllLists();
    }),
  );
};

export default combineEpics(
  {
    convertWebsocketMessageToCollaborationMessage,
    removePresenceFromProjectWhenUserDisconnects,
    addPresenceToProject,
    removePresenceFromProject,
    sendPresenceToUser,
    sendPresenceOnProjectSwitch,
    notifyUsersWhenLeavingProject,
    sendPresenceWhenWebsocketsReady,
    sendCollaborationWebsocketMessage,
    clearCollaborationStateWhenWebsocketsCloses,
  },
  'collaboration',
);
