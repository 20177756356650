import _ from 'lodash';
import { createSelector } from 'reselect';

import { Library } from 'uf-api';
import { getActiveProjectId } from 'uf/app/selectors';
import { EMPTY_OBJECT } from 'uf/base';
import { makeGetFromPropsSelector } from 'uf/base/selector';
import {
  BUILT_FORM_TYPES,
  BuiltForm,
  BuiltFormInfo,
  BuiltFormListState,
  BuiltFormTypeKeys,
  makeBuiltFormKey,
  makeLibraryKey,
} from 'uf/builtforms';
import {
  BuiltFormReference,
  BuiltFormsState,
  EditorState,
} from 'uf/builtforms/state';
import { DataState, EmptyKeyedState, getData } from 'uf/data/dataState';
import { createDataSelector } from 'uf/data/selectors';
import { makeGetExploreUIProperty } from 'uf/explore/selectors/explore';
import { ProjectId } from 'uf/projects';
import { UFState } from 'uf/state';

function getBuiltFormsState(state): BuiltFormsState {
  return state.builtforms || EMPTY_OBJECT;
}

const getBuiltFormsLibrariesState = createSelector(
  getBuiltFormsState,
  (builtforms): Record<string, DataState<Library>> =>
    builtforms.libraries || EMPTY_OBJECT,
);

export function makeGetLibraryState() {
  return createSelector(
    getBuiltFormsLibrariesState,
    makeGetFromPropsSelector<string, 'libraryId'>('libraryId'),
    (librariesState, libraryId): DataState<Library> =>
      librariesState[libraryId] || EmptyKeyedState,
  );
}

export function makeGetLibrary() {
  return createDataSelector(makeGetLibraryState(), EMPTY_OBJECT as Library);
}

/**
 * This state holds lists of built forms,
 * uniquely identified by (builtFormType, libraryId) such as
 * ('building', '/calthorpeanalytics/builtforms/abcd')
 */
export const getBuiltFormsListsState = createSelector(
  getBuiltFormsState,
  (builtforms): BuiltFormsState['builtform_lists'] =>
    builtforms.builtform_lists || (EMPTY_OBJECT as BuiltFormListState),
);

export const getBuildingListsState = createSelector(
  getBuiltFormsListsState,
  builtFormsLists => builtFormsLists.building,
);
export const getBuildingTypeListsState = createSelector(
  getBuiltFormsListsState,
  builtFormsLists => builtFormsLists.building_type,
);
export const getPlaceTypeListsState = createSelector(
  getBuiltFormsListsState,
  builtFormsLists => builtFormsLists.place_type,
);

export function makeGetBuiltFormsListStateGetter() {
  return createSelector(
    getBuildingListsState,
    getBuildingTypeListsState,
    getPlaceTypeListsState,
    (buildingListsState, buildingTypeListsState, placeTypeListsState) =>
      (
        libraryId: string,
        builtFormType: BUILT_FORM_TYPES,
      ): DataState<BuiltFormInfo[]> => {
        const key = makeLibraryKey(libraryId, builtFormType);
        if (builtFormType === BUILT_FORM_TYPES.BUILDING) {
          return buildingListsState[key];
        }
        if (builtFormType === BUILT_FORM_TYPES.BUILDING_TYPE) {
          return buildingTypeListsState[key];
        }
        if (builtFormType === BUILT_FORM_TYPES.PLACE_TYPE) {
          return placeTypeListsState[key];
        }
        return EmptyKeyedState;
      },
  );
}

export function makeGetBuiltFormsListState() {
  return createSelector(
    makeGetBuiltFormsListStateGetter(),
    makeGetFromPropsSelector<string, 'libraryId'>('libraryId'),
    makeGetFromPropsSelector<BUILT_FORM_TYPES, 'builtFormType'>(
      'builtFormType',
    ),
    (
      getBuiltFormsList,
      libraryId,
      builtFormType,
    ): DataState<BuiltFormInfo[]> => {
      return getBuiltFormsList(libraryId, builtFormType);
    },
  );
}

export function makeGetBuiltFormsList() {
  return createDataSelector<BuiltFormInfo[], UFState>(
    makeGetBuiltFormsListState(),
  );
}

/**
 * This state holds individual built forms.
 * Built forms are objects with built form properties, uniquely
 * identified by (builtFormType, libraryId, builtFormKey) such as
 * ('building', '/calthorpe/builtforms/abcd', 'xyzoire')
 */
const getBuiltFormItemsState = createSelector(
  getBuiltFormsState,
  (
    builtforms,
  ): Record<BuiltFormTypeKeys, Record<string, DataState<BuiltForm>>> =>
    builtforms.builtform_items ||
    (EMPTY_OBJECT as Record<
      BuiltFormTypeKeys,
      Record<string, DataState<BuiltForm>>
    >),
);

export function makeGetBuiltFormState() {
  return createSelector(
    getBuiltFormItemsState,
    makeGetFromPropsSelector<string, 'libraryId'>('libraryId'),
    makeGetFromPropsSelector<BUILT_FORM_TYPES, 'builtFormType'>(
      'builtFormType',
    ),
    makeGetFromPropsSelector<string, 'builtFormKey'>('builtFormKey'),
    (
      builtFormsState,
      libraryId,
      builtFormType,
      builtFormKey,
    ): DataState<BuiltForm> => {
      const builtForms: Record<string, DataState<BuiltForm>> = builtFormsState[
        builtFormType
      ] || EMPTY_OBJECT;
      const key = makeBuiltFormKey(libraryId, builtFormType, builtFormKey);
      return builtForms[key] || EmptyKeyedState;
    },
  );
}

export function makeGetBuiltForm() {
  return createDataSelector<BuiltForm, UFState>(makeGetBuiltFormState());
}

const getEditorState = createSelector(
  getBuiltFormsState,
  (builtFormsState: any): EditorState => builtFormsState.editor || EMPTY_OBJECT,
);

const getEditorDataState = createSelector(
  getEditorState,
  editorState => editorState.data || EMPTY_OBJECT,
);

export const getEditorCurrentBuiltFormState = createSelector(
  getEditorState,
  editorState => editorState.currentBuiltForm ?? EMPTY_OBJECT,
);

export interface BuiltFormsEditorContext {
  currentBuiltForm: BuiltFormReference;
  manageViewEditor: string;
  manageViewVisible: boolean;
}

export const getEditorContext = createSelector(
  getEditorCurrentBuiltFormState,
  getActiveProjectId,
  makeGetExploreUIProperty<string>('manageViewEditor'),
  makeGetExploreUIProperty<boolean>('manageViewVisible'),
  (
    currentBuiltFormByProject,
    projectId,
    manageViewEditor,
    manageViewVisible,
  ): BuiltFormsEditorContext => ({
    currentBuiltForm: currentBuiltFormByProject[projectId],
    manageViewEditor,
    manageViewVisible,
  }),
);

export function makeGetEditorCurrentBuiltFormForProject() {
  return createSelector(
    getEditorCurrentBuiltFormState,
    makeGetFromPropsSelector<ProjectId, 'projectId'>('projectId'),
    (currentBuiltFormState, projectId): BuiltFormReference =>
      currentBuiltFormState[projectId],
  );
}

/**
 * Get whatever built form is currently in the editor.
 */
export function makeGetEditorBuiltForm() {
  return createSelector(
    getEditorDataState,
    makeGetFromPropsSelector<string, 'libraryId'>('libraryId'),
    makeGetFromPropsSelector<BUILT_FORM_TYPES, 'builtFormType'>(
      'builtFormType',
    ),
    makeGetFromPropsSelector<string, 'builtFormKey'>('builtFormKey'),
    (editorState, libraryId, builtFormType, builtFormKey): BuiltForm => {
      const key = makeBuiltFormKey(libraryId, builtFormType, builtFormKey);
      return editorState[key] || EMPTY_OBJECT;
    },
  );
}

export function makeIsEditingBuiltForm() {
  return createSelector(
    getEditorDataState,
    makeGetFromPropsSelector<string, 'libraryId'>('libraryId'),
    makeGetFromPropsSelector<BUILT_FORM_TYPES, 'builtFormType'>(
      'builtFormType',
    ),
    makeGetFromPropsSelector<string, 'builtFormKey'>('builtFormKey'),
    (editorState, libraryId, builtFormType, builtFormKey) => {
      const key = makeBuiltFormKey(libraryId, builtFormType, builtFormKey);
      return !_.isEmpty(editorState[key]);
    },
  );
}

/**
 * Get the built form that is being edited
 */
export function makeGetEditorEditableBuiltForm() {
  return createSelector(
    makeGetEditorBuiltForm(),
    makeGetBuiltFormState(),
    (editorBuiltForm, serverBuiltFormState) => {
      // If the editor hasn't started editing yet, we just fall back
      // to the last thing we got from the server.
      if (_.isEmpty(editorBuiltForm)) {
        return getData(serverBuiltFormState, EMPTY_OBJECT);
      }

      return editorBuiltForm;
    },
  );
}

export function makeGetBuiltFormName() {
  return createSelector(
    makeGetEditorEditableBuiltForm(),
    (builtForm): string => builtForm.name,
  );
}
