import colors from 'material-colors';
import { LayerId } from 'uf/layers';
import { DEFAULT_GEO_COLUMN_KEY } from 'uf/layers/geometryKey';
import {
  CuratedColumnSymbology,
  CUSTOM_THEME,
  DEFAULT_CIRCLE_RADIUS,
  DEFAULT_OPACITY,
  InterpolationTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const DEFAULT_POINT_COLOR = colors.grey[50];
const DEFAULT_POINT_STROKE = colors.grey[900];
const DEFAULT_STROKE_WIDTH = 2;

interface PointLayerProps {
  layerId: LayerId;
  radius?: number;
  circleColor?: string;
  strokeColor?: string;
  strokeWidth?: number;
  opacity?: number;
}

// add a new element to this object, consisting of layer path, radii, and color for point datasets
// default values:
// radius: 5
// stroke width: 2
// circel color: DEFAULT_POINT_COLOR
// stroke color: DEFAULT_POINT_STROKE
// opacity: 0.8
const pointLayerProps: PointLayerProps[] = [
  {
    layerId: '/public/databank/usda_ams_national_farmers_markets_directory',
    circleColor: colors.green[50],
    strokeColor: colors.green[900],
  },
  {
    layerId: '/public/dataset/california_cleanup_sites',
    circleColor: colors.blue[50],
    strokeColor: colors.blue[900],
  },
  {
    layerId: '/public/dataset/california_envirostor_cleanup_sites',
    circleColor: colors.green[50],
    strokeColor: colors.green[900],
  },
  {
    layerId: '/public/dataset/california_hazardous_waste_site',
    circleColor: colors.amber[50],
    strokeColor: colors.amber[900],
  },
  {
    layerId: '/public/dataset/california_ice_site',
    circleColor: colors.pink[50],
    strokeColor: colors.pink[900],
  },
  {
    layerId: '/public/dataset/california_permitted_ust',
    circleColor: colors.indigo[50],
    strokeColor: colors.indigo[900],
  },
  {
    layerId: '/public/dataset/colorado_transit_stops',
  },
  {
    layerId: '/cityofmadison/dataset/madison_brt_stops_s2',
  },
  {
    layerId: '/cityofmadison/dataset/madison_brt_stops_s3',
  },
  {
    layerId: '/cityofmadison/dataset/madison_commuter_rail_stops',
  },
  {
    layerId: '/public/dataset/morpc_points_of_interest',
    radius: 3,
    circleColor: colors.green[50],
    strokeColor: colors.blue[900],
    strokeWidth: 1,
  },
  {
    layerId: '/public/dataset/national_transit_stops_v8',
    radius: 2,
  },
  {
    layerId: '/public/dataset/national_transit_stops',
    radius: 2,
  },
  {
    layerId: '/public/dataset/sac_cnty_light_rail_stops',
  },
  {
    layerId: '/public/dataset/sonoma_smart_rail_stations',
  },
  {
    layerId: '/public/dataset/united_states_common_core_data_k12_schools',
    circleColor: colors.blue[50],
    strokeColor: colors.blue[900],
  },
  {
    layerId: '/public/dataset/usdot_airports',
    circleColor: colors.orange[50],
    strokeColor: colors.orange[900],
  },
  {
    layerId: '/public/dataset/usdot_crashessmallscale',
    circleColor: colors.red[50],
    strokeColor: colors.red[900],
  },
  {
    layerId: '/public/dataset/usdot_ports',
    radius: 6,
    circleColor: colors.teal[300],
    strokeColor: colors.teal[300],
  },
  {
    layerId: '/public/dataset/ventura_major_transit_stops',
  },
  {
    layerId: '/public/databank/hifld_mobile_home_parks_esri_api',
  },
  {
    layerId: '/public/databank/hifld_religious_sites',
  },
  {
    layerId: '/public/databank/hud_public_housing_buildings_2022',
  },
];

const symbologies: Record<
  string,
  Record<string, CuratedColumnSymbology[]>
> = {};

pointLayerProps.forEach(layerProp => {
  const radius = layerProp?.radius ?? DEFAULT_CIRCLE_RADIUS;
  const circleColor = layerProp?.circleColor ?? DEFAULT_POINT_COLOR;
  const strokeColor = layerProp?.strokeColor ?? DEFAULT_POINT_STROKE;
  const strokeWidth = layerProp?.strokeWidth ?? DEFAULT_STROKE_WIDTH;
  const circleOpacity = layerProp?.opacity ?? DEFAULT_OPACITY;
  symbologies[layerProp.layerId] = {
    [DEFAULT_GEO_COLUMN_KEY]: [
      {
        type: SymbologyTypes.CIRCLE,
        paint: {
          'circle-radius': radius,
          'circle-color': circleColor,
          'circle-opacity': circleOpacity,
          'circle-stroke-width': strokeWidth,
          'circle-stroke-color': strokeColor,
        },
        display_hints: {
          scale: SymbologyScales.CATEGORICAL,
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          theme: CUSTOM_THEME,
        },
      },
    ],
  };
});

export default symbologies;
