import { Feature, Polygon } from 'geojson';
import { Epic, ofType } from 'redux-observable';
import { concat, defer, merge } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { LayerColumn, LayerStats } from 'uf-api';
import { SwaggerThunkExtra } from 'uf/base/xhr';
import { getData, isLoaded } from 'uf/data/dataState';
import {
  setFailedIntersections,
  setSplitFeatureIds,
  setTooManyFeatures,
} from 'uf/explore/actions/features';
import { clearFilters } from 'uf/explore/actions/filters';
import { enqueueSplitCanvasFeatures } from 'uf/explore/actions/paint';
import {
  SAVE_SPLIT_FEATURES,
  SaveSplitFeaturesAction,
} from 'uf/explore/ActionTypes';
import {
  getExploreGridOptions,
  makeGetSplitParcelFeatureIds,
  makeGetSplittingEnabled,
} from 'uf/explore/selectors/features';
import { makeGetLayerFilters } from 'uf/explore/selectors/filters';
import {
  SPLIT_FEATURE,
  SplitFeatureAction,
  UNSPLIT_FEATURE,
  UnSplitFeatureAction,
} from 'uf/featuresplit/ActionTypes';
import {
  extractNewFeatures,
  partitionFeaturesBySplit,
} from 'uf/featuresplit/edits';
import {
  getGridFeatures,
  GRID_SIZE_TOO_SMALL,
  MAX_FEATURES_TO_SPLIT,
  MAX_NEW_FEATURES_FROM_SPLIT,
  SPLIT_PREVIEW_FEATURE,
  TOO_MANY_NEW_FEATURES_FROM_SPLIT,
} from 'uf/featuresplit/split';
import { FilterSpec, getStatsSearchKey } from 'uf/layers/filters';
import { Metatype } from 'uf/layers/metadata';
import { makeGetLayerGeojson } from 'uf/layers/selectors/geojson';
import { makeGetLayerStatsByKey } from 'uf/layers/selectors/stats';
import { makeGetLayerVersion } from 'uf/layers/selectors/versions';
import { ensureGeoJsonStream } from 'uf/layers/streams/geojson';
import { featureEditListActions } from 'uf/mapedit/actions/features';
import { makeGetLayerEditFeatures } from 'uf/mapedit/selectors/features';
import { UFState } from 'uf/state';

export const saveSplitFeaturesEpic: Epic<SaveSplitFeaturesAction, any> = (
  action$,
  state$,
) => {
  const getLayerEditFeatures = makeGetLayerEditFeatures<Polygon>();
  return action$.pipe(
    ofType(SAVE_SPLIT_FEATURES),
    mergeMap(({ projectId, layerId }) => {
      const featureEdits = getLayerEditFeatures(state$.value, {
        layerId,
        projectId,
      });
      const { splitFeatures, nonSplitFeatures } =
        partitionFeaturesBySplit(featureEdits);

      const newFeatureMap = extractNewFeatures(splitFeatures);

      const oldFeatureIds = Object.keys(newFeatureMap);

      // TODO: get the current filters for this layer
      const filters: Partial<FilterSpec> = {
        columns: {
          id: {
            columnMetatype: Metatype.CATEGORICAL,
            type: LayerColumn.TypeEnum.String,
            filterValue: oldFeatureIds,
          },
        },
      };

      const clearingActions = [
        setSplitFeatureIds(projectId, layerId, []),
        clearFilters(projectId, layerId),
        featureEditListActions.setList(nonSplitFeatures, projectId, layerId),
      ];

      const saveSplitAction = enqueueSplitCanvasFeatures(
        projectId,
        filters,
        oldFeatureIds,
        newFeatureMap,
      );

      if (oldFeatureIds.length) {
        return merge([saveSplitAction, ...clearingActions]);
      }

      return merge(clearingActions);
    }),
  );
};

export const splitFeatureEpic: Epic<
  SplitFeatureAction,
  any,
  UFState,
  SwaggerThunkExtra
> = (action$, state$, { client }) => {
  const getLayerVersion = makeGetLayerVersion();
  const getLayerFilters = makeGetLayerFilters();
  const getLayerGeojson = makeGetLayerGeojson();
  const getLayerEditFeatures = makeGetLayerEditFeatures();
  const getSplitParcelFeatureIds = makeGetSplitParcelFeatureIds();
  const getLayerStats = makeGetLayerStatsByKey();
  const getSplittingEnabled = makeGetSplittingEnabled();
  return action$.pipe(
    ofType(SPLIT_FEATURE),
    mergeMap(action => {
      const { projectId, layerId } = action;
      const version = getLayerVersion(state$.value, { layerId });
      const filters = getLayerFilters(state$.value, {
        projectId,
        layerId,
        parentLayerId: null,
      });
      return concat(
        ensureGeoJsonStream(action$, state$, client, {
          layerId,
          filters,
          version,
        }),
        defer(() => {
          // because the ui may close by the time we get here, check to see if we still want to show
          // splits on the map
          const splittingEnabled = getSplittingEnabled(state$.value, {
            projectId,
          });

          const searchKey = getStatsSearchKey(layerId, {
            version,
            filters,
            columns: null,
          });

          const stats: LayerStats = getData(
            getLayerStats(state$.value, {
              key: searchKey,
            }),
          );

          const tooManyFeaturesToSplit =
            stats?.row_count > MAX_FEATURES_TO_SPLIT;

          const featureState = getLayerGeojson(state$.value, {
            filters,
            layerId,
            version,
          });

          // might have thrown an error
          if (!isLoaded(featureState)) {
            return;
          }

          const gridOptions = getExploreGridOptions(state$.value, {
            projectId,
          });
          const selectedFeatures = getData(featureState)?.features;

          const previousFeatureIds = getSplitParcelFeatureIds(state$.value, {
            layerId,
            projectId,
          });

          const previousSplitPreviewFeatures = getLayerEditFeatures(
            state$.value,
            {
              layerId,
              projectId,
            },
          ).filter(feature => feature?.properties?.[SPLIT_PREVIEW_FEATURE]);

          const nonSplitPreviewFeatures = getLayerEditFeatures(state$.value, {
            layerId,
            projectId,
          }).filter(feature => !feature?.properties?.[SPLIT_PREVIEW_FEATURE]);

          let splitFeatures =
            previousSplitPreviewFeatures as Feature<Polygon>[];

          let tooManyNewFeatures: boolean = false;
          let gridSizeTooSmall: boolean = false;
          let failedIntersections: number = 0;

          try {
            splitFeatures = selectedFeatures.flatMap(feature => {
              const { features, numFailedIntersections } = getGridFeatures(
                feature as Feature<Polygon>,
                gridOptions,
              );
              failedIntersections += numFailedIntersections;
              return features;
            });
          } catch (e) {
            if (e.message === TOO_MANY_NEW_FEATURES_FROM_SPLIT) {
              tooManyNewFeatures = true;
            } else if (e.message === GRID_SIZE_TOO_SMALL) {
              gridSizeTooSmall = true;
            } else {
              throw e;
            }
          }

          // getGridFeatures uses quick math to determine if there are too many features.  The value
          // it calculates undershoots the actual number of features that will be created. So, we do
          // an extra check here to see if the actual number of grid cells goes over the maximum
          // number of new features allowed.
          tooManyNewFeatures =
            tooManyNewFeatures ||
            splitFeatures.length > MAX_NEW_FEATURES_FROM_SPLIT;

          const shouldUseNewFeatureIds =
            !tooManyNewFeatures && !gridSizeTooSmall;

          let featureIds = shouldUseNewFeatureIds
            ? splitFeatures.map(({ id }) => String(id)) || []
            : previousFeatureIds;

          if (!splittingEnabled || tooManyFeaturesToSplit || gridSizeTooSmall) {
            splitFeatures = [];
            featureIds = [];
          }

          const setFeatureListAction = featureEditListActions.setList(
            [...nonSplitPreviewFeatures, ...splitFeatures],
            projectId,
            layerId,
          );

          return merge([
            // these inform the splitting UI
            setTooManyFeatures(projectId, layerId, tooManyNewFeatures),
            setFailedIntersections(projectId, layerId, failedIntersections),
            setSplitFeatureIds(projectId, layerId, featureIds),
            // this informs the map of which features to display
            setFeatureListAction,
          ]);
        }),
      );
    }),
  );
};

export const unSplitFeatureEpic: Epic<
  UnSplitFeatureAction,
  any,
  UFState,
  SwaggerThunkExtra
> = (action$, state$, { client }) => {
  const getLayerVersion = makeGetLayerVersion();
  const getLayerFilters = makeGetLayerFilters();
  const getLayerGeojson = makeGetLayerGeojson();
  const getLayerEditFeatures = makeGetLayerEditFeatures();
  return action$.pipe(
    ofType(UNSPLIT_FEATURE),
    mergeMap(action => {
      const { projectId, layerId } = action;
      const version = getLayerVersion(state$.value, { layerId });
      const filters = getLayerFilters(state$.value, {
        projectId,
        layerId,
        parentLayerId: null,
      });
      return concat(
        ensureGeoJsonStream(action$, state$, client, {
          layerId,
          filters,
          version,
        }),
        defer(() => {
          const featureState = getLayerGeojson(state$.value, {
            filters,
            layerId,
            version,
          });
          // might have thrown an error
          if (!isLoaded(featureState)) {
            return;
          }

          const nonParcelSplitEdits = getLayerEditFeatures(state$.value, {
            layerId,
            projectId,
          }).filter(feature => !feature?.properties?.[SPLIT_PREVIEW_FEATURE]);

          return merge([
            featureEditListActions.setList(
              nonParcelSplitEdits,
              projectId,
              layerId,
            ),
          ]);
        }),
      );
    }),
  );
};
