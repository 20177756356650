import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { t } from 'ttag';
import { getActiveOrganizationKey } from 'uf/app/selectors';
import {
  dialogClosed,
  selectProjectDialog,
} from 'uf/projects/projectDialogSlice';
import { makeGetProjectById, makeGetScenarioById } from 'uf/projects/selectors';
import Flex from 'uf/ui/base/Flex';
import FormDialog from 'uf/ui/base/Modals/FormDialog';

import { Sizes } from 'uf/ui/base/sizes';
import { useMakeSelector } from 'uf/ui/base/useMakeSelector';
import CreateProjectForm, {
  CreateProjectFormState,
} from 'uf/ui/projects/CreateProjectForm/CreateProjectForm';
import CreateProjectOptions from './CreateProjectOptions/CreateProjectOptions';
import ProjectTypeInformation from './ProjectTypeInformation/ProjectTypeInformation';
import { useCreateProject } from './hooks';
import { useGetProjectTypesQuery } from 'uf-api-rtk/store/Api';
import { usePreferredOrgKey } from 'uf/ui/user/hooks';

interface OwnProps {
  onClose?: () => void;
  show?: boolean;
  /** Source project to create canvas from */
  sourceProjectId?: string;
  // Source scenario to create canvas from
  sourceScenarioId?: string;
}

const CreateProjectDialog: FunctionComponent<OwnProps> = ({
  show,
  sourceProjectId,
  sourceScenarioId,
  onClose,
}) => {
  const [error, setError] = useState(null);
  const [formError, setFormError] = useState(null);
  const [formData, setFormData] = useState<CreateProjectFormState>({});
  const [projectType, setProjectType] = useState<string | null>(null);
  const dispatch = useDispatch();
  const projectDialog = useSelector(selectProjectDialog);
  const organizationKey = useSelector(getActiveOrganizationKey);
  const sourceProject = useMakeSelector(makeGetProjectById, {
    projectId: sourceProjectId,
  });
  const sourceScenario = useMakeSelector(makeGetScenarioById, {
    projectId: sourceProjectId,
    scenarioId: sourceScenarioId,
  });

  const handleClose = useCallback(() => {
    setError(null);
    setFormData({});
    if (onClose) {
      onClose();
    }
    dispatch(dialogClosed());
  }, [dispatch, onClose]);

  const { handleCreateProject, isLoading } = useCreateProject(
    formData,
    sourceProject,
    sourceScenario,
    setError,
    setFormData,
    handleClose,
    projectType,
  );

  const [orgKey] = usePreferredOrgKey();
  const { data } = useGetProjectTypesQuery({ orgKey }, { skip: !orgKey });

  useEffect(() => {
    if (data?.items?.length) {
      setProjectType(data.items[0].key);
    }
  }, [data]);

  const toggleProjectType = useCallback(event => {
    setProjectType(event.currentTarget.value);
  }, []);

  /**
   * Non-Source Tab dialog is hooked into Redux to facilitate a single dialog
   * with multiple entry points (ProjectMenu, HeaderMenus, ProjectSettings)
   */
  const isOpen = projectDialog.isOpen || show;
  const valid = formData.valid && !isLoading;
  const projectName = sourceProject?.name;
  const scenarioName = sourceScenario?.name;
  const title = sourceProjectId
    ? t`Create new project from ${projectName}: ${scenarioName}`
    : t`Create new project`;
  return (
    <FormDialog
      controlsClassNamePrefix={'CreateProjectForm'}
      show={isOpen}
      title={title}
      busy={isLoading}
      busyText={t`Creating project...`}
      error={error || formError}
      okDisabled={!valid || !!formError}
      okText={t`Create`}
      onOk={handleCreateProject}
      onCancel={handleClose}
      size={Sizes.LARGE}>
      <div data-testid="create-project-dialog-body" style={{ display: 'flex' }}>
        <Flex className="tw-w-1/3" flexDirection="col">
          <CreateProjectOptions
            handleSelect={toggleProjectType}
            selectedProjectType={projectType}
          />
        </Flex>
        <Flex className="tw-w-2/3" flexDirection="col">
          <ProjectTypeInformation projectTypeKey={projectType} />
          <CreateProjectForm
            onSetError={setFormError}
            sourceProject={sourceProjectId ? sourceProject : null}
            sourceScenario={sourceScenarioId ? sourceScenario : null}
            organizationKey={organizationKey}
            onFormUpdate={setFormData}
            projectType={projectType}
          />
        </Flex>
      </div>
    </FormDialog>
  );
};

export default CreateProjectDialog;
