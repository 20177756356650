import { t } from 'ttag';

import { UserAction } from 'uf-api';
import { assertNever } from 'uf/base/never';
import { Flavor } from 'uf/base/types';

export type ScenarioId = Flavor<string, 'ScenarioId'>;

const CLONE_ENABLED = t`Create a new scenario`;

const CLONE_DISABLED = t`Creating scenarios is currently disabled`;

export function getCreateScenarioPermissionText(
  canCreateScenario: boolean,
  reason: UserAction.ReasonEnum,
  enabledText = CLONE_ENABLED,
  disabledText = CLONE_DISABLED,
) {
  if (canCreateScenario) {
    return enabledText;
  }
  if (!reason) {
    return disabledText;
  }

  switch (reason) {
    case 'subscription':
      return t`Cannot create more scenarios for this project. Please update your subscription or contact support.`;

    case 'restriction':
      return t`This scenario cannot be cloned. Contact support for additional information.`;

    case 'permission':
      return t`Your account does not have permission to create scenarios.`;

    default:
      return assertNever(reason, t`This scenario cannot be cloned.`);
  }
}
