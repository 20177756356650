/**
 * UrbanFootprint App API
 * API for serving UrbanFootprint Application
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { ReportAnalysisSourceSpec } from './reportAnalysisSourceSpec';
import { ReportCanvasSourceSpec } from './reportCanvasSourceSpec';

/**
 * Spec for a single data series. (or single datum, if aggregate_by is specified)
 */
export interface ReportSeriesSpec<> {
  /**
   * The type of aggregation, if any.
   */
  aggregate_by?: ReportSeriesSpec.AggregateByEnum;
  analysis_source?: ReportAnalysisSourceSpec;
  canvas_source?: ReportCanvasSourceSpec;
  /**
   * A unique user-supplied key to be included in the output.
   */
  key?: string;
  /**
   * Name to override series name. If missing, will be supplied by metadata.
   */
  name?: string;
}
export namespace ReportSeriesSpec {
  export type AggregateByEnum = 'sum' | 'avg' | 'median';
  export const AggregateByEnum = {
    Sum: 'sum',
    Avg: 'avg',
    Median: 'median',
  } as const;
}
