import {
  addOrgLogoActionTypes,
  deleteOrgLogoActionTypes,
  getOrgLogoInfosActionTypes,
} from 'uf-api/api/organization.service';
import { makeAsyncApiCaller } from 'uf/base/xhr';
import { dispatchAsyncAction, makeEnsureActionCreator } from 'uf/data/loader';
import { makeGetOrganzationLogoInfosState } from 'uf/organizations/selectors/logo';

const getOrgLogoInfos = makeAsyncApiCaller(
  apis => apis.organization.get_org_logo_infos,
);

export function loadOrganizationLogos(organizationKey: string) {
  return dispatchAsyncAction(
    getOrgLogoInfosActionTypes,
    getOrgLogoInfos({ org_key: organizationKey }),
    { key: organizationKey },
  );
}

export const ensureOrganizationLogos = makeEnsureActionCreator(
  loadOrganizationLogos,
  makeGetOrganzationLogoInfosState(),
  organizationKey => ({ organizationKey }),
);

const addOrgLogoAPI = makeAsyncApiCaller(
  apis => apis.organization.add_org_logo,
);

export function addOrgLogo(organizationKey: string, file: File, name: string) {
  return dispatchAsyncAction(
    addOrgLogoActionTypes,
    addOrgLogoAPI({
      org_key: organizationKey,
      formData: file,
      name,
    }),
    { key: organizationKey },
  );
}

const deleteOrgLogoAPI = makeAsyncApiCaller(
  apis => apis.organization.delete_org_logo,
);

export function deleteOrgLogo(organizationKey: string, key: string) {
  return dispatchAsyncAction(
    deleteOrgLogoActionTypes,
    deleteOrgLogoAPI({ org_key: organizationKey, logo_key: key }),
    { key: organizationKey },
  );
}
