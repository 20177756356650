import _ from 'lodash';
import { t } from 'ttag';

import { LayerColumn, LayerColumnStats } from 'uf-api/model/models';
import { EMPTY_OBJECT } from 'uf/base';
import {
  formatNumeric,
  formatPercent,
  getBooleanValue,
} from 'uf/base/formatting';
import { getUnitName, UnitLabelKeys } from 'uf/base/units';
import { getIsPercent } from 'uf/layers/metadata';

/**
 * Formats a value according to the column metadata
 * for that field.
 *
 * `nullString` is the string to return if the value is 'null' (like
 * an empty string for string-based columns)
 */
export function formatValue(
  value: NativeValue,
  columnMetadata: LayerColumn,
  nullString = '',
  units: boolean = false,
) {
  const { number_format: numberFormat } = columnMetadata;
  /**
   * If the metadata includes a number_format property, use those fields to format
   * the supplied value (if it is in-fact a number). Otherwise, fallback to the custom
   * formatting logic. This is optimistic and does not validate the provided formatting
   * fields.
   */

  if (
    (isColumnNumberType(columnMetadata) ||
      columnMetadata?.metatype === LayerColumn.MetatypeEnum.Numeric) &&
    !_.isEmpty(numberFormat) &&
    !isNaN(Number(value))
  ) {
    return Intl.NumberFormat('en-US', numberFormat).format(Number(value));
  }
  if (getIsPercent(columnMetadata)) {
    return formatPercent(value as number);
  }
  if (columnMetadata.metatype === LayerColumn.MetatypeEnum.Numeric) {
    return formatNumericValue(value as number, units, columnMetadata);
  }
  if (columnMetadata.type === 'boolean') {
    const boolValue = convertBooleanValue(value);
    return boolValue ? t`Yes` : t`No`;
  }
  if (columnMetadata.type === 'string') {
    // even non-strings should be coerced to strings
    return value ? `${value}` : nullString;
  }
  return `${value}`;
}

function formatNumericValue(
  value: number,
  units: boolean,
  columnMetadata: LayerColumn,
) {
  const strValue = formatNumeric(value);
  return units && columnMetadata.units
    ? `${strValue} ${getUnitName(columnMetadata.units as UnitLabelKeys)}`
    : strValue;
}

export function isColumnNumberType(column: LayerColumn) {
  if (!column) {
    return false;
  }
  return (
    column.type === LayerColumn.TypeEnum.Float ||
    column.type === LayerColumn.TypeEnum.Int ||
    column.type === LayerColumn.TypeEnum.Bigint
  );
}

export function isColumnDataChartable(
  column: LayerColumn,
  stats: LayerColumnStats,
) {
  if (!column || !stats) {
    return false;
  }

  switch (column.metatype) {
    case 'categorical':
      return !_.isEmpty(stats.categorical.values);

    case 'numeric':
      return 'min' in stats.numeric && 'max' in stats.numeric;

    default:
      return false;
  }
}

export type NativeValue = string | number | boolean;
/**
 * Coerce a value to it's "native" javscript type, as per the type specified in the column.
 *
 * For instance:
 *  * boolean columns coerce "true" and "1" to `true`, most other things to false
 *  * Int columns coercce "1.0" and "1.2" to `1` (by rounding)
 *  * Float columns coerce "1.0" and "1.2" to `1.0` and `1.2` respectively
 *
 * etc..
 *
 * @param value Any primitive
 * @param columnMetadata The column including the type information
 */
export function getNativeValue(
  value: NativeValue,
  columnMetadata: LayerColumn = EMPTY_OBJECT as LayerColumn,
): NativeValue {
  if (!columnMetadata) {
    console.warn('XXX MISSING metadata for ', value);
    return null;
  }
  switch (columnMetadata.type) {
    case LayerColumn.TypeEnum.Float:
      return convertFloatValue(value);

    case LayerColumn.TypeEnum.Int:
      return convertIntValue(value);

    case LayerColumn.TypeEnum.String:
    case LayerColumn.TypeEnum.Bytes:
      if (!value) {
        return '';
      }
      // TODO: handle raw bytes, perhaps with ArrayBuffer?
      return `${value}`;

    case LayerColumn.TypeEnum.Boolean:
      return convertBooleanValue(value);

    default:
      return value;
  }
}
function convertFloatValue(value: NativeValue): number {
  if (value === '' || typeof value === 'boolean') {
    return null;
  }
  if (typeof value === 'number') {
    return value;
  }
  return parseFloat(value);
}

function convertIntValue(value: NativeValue): number {
  if (value === '' || typeof value === 'boolean') {
    return null;
  }
  if (typeof value === 'number') {
    return Math.round(value);
  }
  return parseInt(value, 10);
}

function convertBooleanValue(value: NativeValue): boolean {
  if (typeof value === 'boolean') {
    return value;
  }
  if (typeof value === 'string') {
    return getBooleanValue(value);
  }
  if (typeof value === 'number') {
    return !!value;
  }
  return !_.isEmpty(value);
}

/**
 * Get the name of a column.
 * @deprecated see `getGlobalName` or `getLocalName`
 */
export function getColumnName(column: LayerColumn, local = false) {
  return column?.name || column?.key;
}

// String formatter, just for title/tooltips in this component.
export function getColumnTitleText(column: LayerColumn) {
  const text = [getColumnName(column)];
  if (column.description) {
    text.push(column.description);
  }

  return text.join('\n');
}

enum FileTypesByFormat {
  csv = 'csv',
  shp = 'zip',
  svg = 'svg',
}

export function formatFileName(layerName: string, fileFormat: string): string {
  const fileType = FileTypesByFormat[fileFormat];

  if (!fileType) {
    return layerName;
  }

  return `${layerName}.${fileType}`;
}
