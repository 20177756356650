import { AnyAction } from 'redux';

import { MessageAction, RemoveMessageAction } from 'uf/appservices/actions';
import {
  APP_ADD_MESSAGE,
  APP_REMOVE_MESSAGE,
} from 'uf/appservices/ActionTypes';
import { Message } from 'uf/appservices/messages';

export default function messages(
  state: Message[] = [],
  action: AnyAction = { type: undefined },
): Message[] {
  switch (action.type) {
    case APP_ADD_MESSAGE: {
      const { message } = action as MessageAction;
      if (message.replacesMessage) {
        const newState: Message[] = [...state];
        const messagePosition = newState.findIndex(
          m => m.id === m.replacesMessage,
        );
        if (messagePosition !== -1) {
          newState.splice(messagePosition, 1, message);
          return newState;
        }
      }
      return [...state, message];
    }
    case APP_REMOVE_MESSAGE: {
      const removeAction = action as RemoveMessageAction;
      const newState: Message[] = [...state];
      const messagePosition = newState.findIndex(m => m.id === removeAction.id);
      if (messagePosition !== -1) {
        newState.splice(messagePosition, 1);
      }
      return newState;
    }

    default:
      return state;
  }
}
