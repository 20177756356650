import { LayerId } from 'uf/layers';
import {
  SET_LAYER_VERSIONS,
  UPDATE_LAYER_VERSION,
} from 'uf/layers/ActionTypes';

export function setLayerVersions(versions: Record<string, string>) {
  return {
    type: SET_LAYER_VERSIONS,
    versions,
  };
}

export function updateLayerVersion(layerId: LayerId, version: string) {
  return {
    type: UPDATE_LAYER_VERSION,
    layerId,
    version,
  };
}
