import {
  mdiAlphabetical,
  mdiBorderOutside,
  mdiCircleMedium,
  mdiLayersOutline,
  mdiNumeric,
  mdiPoll,
  mdiViewDashboard,
  mdiViewDashboardOutline,
} from '@mdi/js';

import { LayerColumn } from 'uf-api';
import { LayerRole } from 'uf/layers/state';

export const LayerIconPathByRole = {
  [LayerRole.CONTEXT_AREA]: mdiBorderOutside,
  [LayerRole.PROJECT_AREA]: mdiBorderOutside,
  [LayerRole.BASE_CANVAS]: mdiViewDashboardOutline,
  [LayerRole.SCENARIO_CANVAS]: mdiViewDashboard,
  [LayerRole.REFERENCE]: mdiLayersOutline,
  [LayerRole.ANALYSIS]: mdiPoll,
  [LayerRole.WORKING]: mdiLayersOutline,
  [LayerRole.UNKNOWN]: mdiLayersOutline,
};

export function getColumnIconPath(column: LayerColumn): string {
  switch (column.metatype) {
    case LayerColumn.MetatypeEnum.Categorical:
      return mdiAlphabetical;
    case LayerColumn.MetatypeEnum.Numeric:
      return mdiNumeric;
    default:
      return mdiCircleMedium;
  }
}
