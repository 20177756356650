/**
 * UrbanFootprint App API
 * API for serving UrbanFootprint Application
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * True `value` is one of `one_of`.
 */
export interface ExpressionIn<Tvalue extends object = any> {
  /**
   * Expression function, set to \"in\".
   */
  fn: ExpressionIn.FnEnum;
  /**
   *
   */
  one_of: Array<object>;
  /**
   *
   */
  value: Tvalue;
}
export namespace ExpressionIn {
  export type FnEnum = 'in';
  export const FnEnum = {
    In: 'in',
  } as const;
}
