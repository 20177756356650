/**
 * UrbanFootprint App API
 * API for serving UrbanFootprint Application
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { BuiltFormValidationError } from './builtFormValidationError';
import { BuiltFormItemWeight } from './builtFormItemWeight';

/**
 * A Blend of BuildingTypes.
 */
export interface PlaceType<Tstyle extends object = any> {
  /**
   * The total parcel area, in coarse units. For BuildingTypes and PlaceTypes this is always 1.
   */
  area_parcel?: number;
  /**
   * Weighted average of area_parcel_agriculture from subcomponents.
   */
  area_parcel_agriculture?: number;
  /**
   * Weighted average of area_parcel_allocation_error from subcomponents.
   */
  area_parcel_allocation_error?: number;
  /**
   * Weighted average of area_parcel_allocation_error_pct from subcomponents.
   */
  area_parcel_allocation_error_pct?: number;
  /**
   * Weighted average of area_parcel_civic_institutional from subcomponents.
   */
  area_parcel_civic_institutional?: number;
  /**
   * Weighted average of area_parcel_commercial from subcomponents.
   */
  area_parcel_commercial?: number;
  /**
   * Weighted average of area_parcel_fine from subcomponents.
   */
  area_parcel_fine?: number;
  /**
   * Weighted average of area_parcel_industrial from subcomponents.
   */
  area_parcel_industrial?: number;
  /**
   * Weighted average of area_parcel_mixed_use from subcomponents.
   */
  area_parcel_mixed_use?: number;
  /**
   * Weighted average of area_parcel_natural_conservation from subcomponents.
   */
  area_parcel_natural_conservation?: number;
  /**
   * Weighted average of area_parcel_natural_resources from subcomponents.
   */
  area_parcel_natural_resources?: number;
  /**
   * Weighted average of area_parcel_open_space from subcomponents.
   */
  area_parcel_open_space?: number;
  /**
   * Weighted average of area_parcel_other from subcomponents.
   */
  area_parcel_other?: number;
  /**
   * Weighted average of area_parcel_res from subcomponents.
   */
  area_parcel_res?: number;
  /**
   * Weighted average of area_parcel_res_acc from subcomponents.
   */
  area_parcel_res_acc?: number;
  /**
   * Weighted average of area_parcel_res_attsf from subcomponents.
   */
  area_parcel_res_attsf?: number;
  /**
   * Weighted average of area_parcel_res_detsf_ll from subcomponents.
   */
  area_parcel_res_detsf_ll?: number;
  /**
   * Weighted average of area_parcel_res_detsf_sl from subcomponents.
   */
  area_parcel_res_detsf_sl?: number;
  /**
   * Weighted average of area_parcel_res_mf from subcomponents.
   */
  area_parcel_res_mf?: number;
  /**
   * Weighted average of area_parcel_residential from subcomponents.
   */
  area_parcel_residential?: number;
  /**
   * Weighted average of area_parcel_transportation_utilities from subcomponents.
   */
  area_parcel_transportation_utilities?: number;
  /**
   * Weighted average of area_parcel_water from subcomponents.
   */
  area_parcel_water?: number;
  /**
   * Weighted average of area_parcel_with_residential from subcomponents.
   */
  area_parcel_with_residential?: number;
  /**
   * Weighted average of area_park from subcomponents.
   */
  area_park?: number;
  /**
   * Weighted average of area_per_emp from subcomponents.
   */
  area_per_emp?: number;
  /**
   * Weighted average of area_per_emp_ag from subcomponents.
   */
  area_per_emp_ag?: number;
  /**
   * Weighted average of area_per_emp_ag_agriculture from subcomponents.
   */
  area_per_emp_ag_agriculture?: number;
  /**
   * Weighted average of area_per_emp_ag_extraction from subcomponents.
   */
  area_per_emp_ag_extraction?: number;
  /**
   * Weighted average of area_per_emp_ind from subcomponents.
   */
  area_per_emp_ind?: number;
  /**
   * Weighted average of area_per_emp_ind_construction from subcomponents.
   */
  area_per_emp_ind_construction?: number;
  /**
   * Weighted average of area_per_emp_ind_manufacturing from subcomponents.
   */
  area_per_emp_ind_manufacturing?: number;
  /**
   * Weighted average of area_per_emp_ind_transport_warehousing from subcomponents.
   */
  area_per_emp_ind_transport_warehousing?: number;
  /**
   * Weighted average of area_per_emp_ind_utilities from subcomponents.
   */
  area_per_emp_ind_utilities?: number;
  /**
   * Weighted average of area_per_emp_ind_wholesale from subcomponents.
   */
  area_per_emp_ind_wholesale?: number;
  /**
   * Weighted average of area_per_emp_military from subcomponents.
   */
  area_per_emp_military?: number;
  /**
   * Weighted average of area_per_emp_off from subcomponents.
   */
  area_per_emp_off?: number;
  /**
   * Weighted average of area_per_emp_off_medical_services from subcomponents.
   */
  area_per_emp_off_medical_services?: number;
  /**
   * Weighted average of area_per_emp_off_office_services from subcomponents.
   */
  area_per_emp_off_office_services?: number;
  /**
   * Weighted average of area_per_emp_pub from subcomponents.
   */
  area_per_emp_pub?: number;
  /**
   * Weighted average of area_per_emp_pub_education from subcomponents.
   */
  area_per_emp_pub_education?: number;
  /**
   * Weighted average of area_per_emp_pub_public_admin from subcomponents.
   */
  area_per_emp_pub_public_admin?: number;
  /**
   * Weighted average of area_per_emp_ret from subcomponents.
   */
  area_per_emp_ret?: number;
  /**
   * Weighted average of area_per_emp_ret_accommodation from subcomponents.
   */
  area_per_emp_ret_accommodation?: number;
  /**
   * Weighted average of area_per_emp_ret_arts_entertainment from subcomponents.
   */
  area_per_emp_ret_arts_entertainment?: number;
  /**
   * Weighted average of area_per_emp_ret_other_services from subcomponents.
   */
  area_per_emp_ret_other_services?: number;
  /**
   * Weighted average of area_per_emp_ret_restaurant from subcomponents.
   */
  area_per_emp_ret_restaurant?: number;
  /**
   * Weighted average of area_per_emp_ret_retail_services from subcomponents.
   */
  area_per_emp_ret_retail_services?: number;
  /**
   * Unit Size for Accessory unit
   */
  avg_unit_area_acc?: number;
  /**
   * Unit Size for Single-family attached
   */
  avg_unit_area_attsf?: number;
  /**
   * Unit Size for Single-family detached large lot
   */
  avg_unit_area_detsf_ll?: number;
  /**
   * Unit Size for Single-family detached small lot
   */
  avg_unit_area_detsf_sl?: number;
  /**
   * Unit Size for Multifamily
   */
  avg_unit_area_mf?: number;
  /**
   * Weighted average of bldg_area_acc from subcomponents.
   */
  bldg_area_acc?: number;
  /**
   * Weighted average of bldg_area_accommodation from subcomponents.
   */
  bldg_area_accommodation?: number;
  /**
   * Portion of building area that is accommodation
   */
  bldg_area_accommodation_pct?: number;
  /**
   * Weighted average of bldg_area_ag from subcomponents.
   */
  bldg_area_ag?: number;
  /**
   * Portion of building area that is ag
   */
  bldg_area_ag_pct?: number;
  /**
   * Weighted average of bldg_area_agriculture from subcomponents.
   */
  bldg_area_agriculture?: number;
  /**
   * Portion of building area that is agriculture
   */
  bldg_area_agriculture_pct?: number;
  /**
   * Weighted average of bldg_area_allocation_error from subcomponents.
   */
  bldg_area_allocation_error?: number;
  /**
   * Weighted average of bldg_area_allocation_error_pct from subcomponents.
   */
  bldg_area_allocation_error_pct?: number;
  /**
   * Weighted average of bldg_area_arts_entertainment from subcomponents.
   */
  bldg_area_arts_entertainment?: number;
  /**
   * Portion of building area that is arts_entertainment
   */
  bldg_area_arts_entertainment_pct?: number;
  /**
   * Weighted average of bldg_area_attsf from subcomponents.
   */
  bldg_area_attsf?: number;
  /**
   * Weighted average of bldg_area_belowground from subcomponents.
   */
  bldg_area_belowground?: number;
  /**
   * Weighted average of bldg_area_construction from subcomponents.
   */
  bldg_area_construction?: number;
  /**
   * Portion of building area that is construction
   */
  bldg_area_construction_pct?: number;
  /**
   * Weighted average of bldg_area_detsf_ll from subcomponents.
   */
  bldg_area_detsf_ll?: number;
  /**
   * Weighted average of bldg_area_detsf_sl from subcomponents.
   */
  bldg_area_detsf_sl?: number;
  /**
   * Weighted average of bldg_area_education from subcomponents.
   */
  bldg_area_education?: number;
  /**
   * Portion of building area that is education
   */
  bldg_area_education_pct?: number;
  /**
   * Weighted average of bldg_area_emp_allocation_error from subcomponents.
   */
  bldg_area_emp_allocation_error?: number;
  /**
   * Weighted average of bldg_area_emp_allocation_error_pct from subcomponents.
   */
  bldg_area_emp_allocation_error_pct?: number;
  /**
   * Weighted average of bldg_area_extraction from subcomponents.
   */
  bldg_area_extraction?: number;
  /**
   * Portion of building area that is extraction
   */
  bldg_area_extraction_pct?: number;
  /**
   * Weighted average of bldg_area_footprint from subcomponents.
   */
  bldg_area_footprint?: number;
  /**
   * Weighted average of bldg_area_footprint_area_parcel_pct from subcomponents.
   */
  bldg_area_footprint_area_parcel_pct?: number;
  /**
   * Weighted average of bldg_area_ind from subcomponents.
   */
  bldg_area_ind?: number;
  /**
   * Portion of building area that is ind
   */
  bldg_area_ind_pct?: number;
  /**
   * Weighted average of bldg_area_manufacturing from subcomponents.
   */
  bldg_area_manufacturing?: number;
  /**
   * Portion of building area that is manufacturing
   */
  bldg_area_manufacturing_pct?: number;
  /**
   * Weighted average of bldg_area_medical_services from subcomponents.
   */
  bldg_area_medical_services?: number;
  /**
   * Portion of building area that is medical_services
   */
  bldg_area_medical_services_pct?: number;
  /**
   * Weighted average of bldg_area_mf from subcomponents.
   */
  bldg_area_mf?: number;
  /**
   * Weighted average of bldg_area_military from subcomponents.
   */
  bldg_area_military?: number;
  /**
   * Portion of building area that is military
   */
  bldg_area_military_pct?: number;
  /**
   * Weighted average of bldg_area_nonres from subcomponents.
   */
  bldg_area_nonres?: number;
  /**
   * Weighted average of bldg_area_nonres_pct from subcomponents.
   */
  bldg_area_nonres_pct?: number;
  /**
   * Weighted average of bldg_area_nonresidential_net from subcomponents.
   */
  bldg_area_nonresidential_net?: number;
  /**
   * Weighted average of bldg_area_off from subcomponents.
   */
  bldg_area_off?: number;
  /**
   * Portion of building area that is off
   */
  bldg_area_off_pct?: number;
  /**
   * Weighted average of bldg_area_office_services from subcomponents.
   */
  bldg_area_office_services?: number;
  /**
   * Portion of building area that is office_services
   */
  bldg_area_office_services_pct?: number;
  /**
   * Weighted average of bldg_area_other_services from subcomponents.
   */
  bldg_area_other_services?: number;
  /**
   * Portion of building area that is other_services
   */
  bldg_area_other_services_pct?: number;
  /**
   * Weighted average of bldg_area_pub from subcomponents.
   */
  bldg_area_pub?: number;
  /**
   * Portion of building area that is pub
   */
  bldg_area_pub_pct?: number;
  /**
   * Weighted average of bldg_area_public_admin from subcomponents.
   */
  bldg_area_public_admin?: number;
  /**
   * Portion of building area that is public_admin
   */
  bldg_area_public_admin_pct?: number;
  /**
   * Weighted average of bldg_area_res from subcomponents.
   */
  bldg_area_res?: number;
  /**
   * Weighted average of bldg_area_res_pct from subcomponents.
   */
  bldg_area_res_pct?: number;
  /**
   * Weighted average of bldg_area_res_type_allocation_error from subcomponents.
   */
  bldg_area_res_type_allocation_error?: number;
  /**
   * Weighted average of bldg_area_res_type_allocation_error_pct from subcomponents.
   */
  bldg_area_res_type_allocation_error_pct?: number;
  /**
   * Weighted average of bldg_area_residential_net from subcomponents.
   */
  bldg_area_residential_net?: number;
  /**
   * Weighted average of bldg_area_restaurant from subcomponents.
   */
  bldg_area_restaurant?: number;
  /**
   * Portion of building area that is restaurant
   */
  bldg_area_restaurant_pct?: number;
  /**
   * Weighted average of bldg_area_ret from subcomponents.
   */
  bldg_area_ret?: number;
  /**
   * Portion of building area that is ret
   */
  bldg_area_ret_pct?: number;
  /**
   * Weighted average of bldg_area_retail_services from subcomponents.
   */
  bldg_area_retail_services?: number;
  /**
   * Portion of building area that is retail_services
   */
  bldg_area_retail_services_pct?: number;
  /**
   * Weighted average of bldg_area_total from subcomponents.
   */
  bldg_area_total?: number;
  /**
   * Weighted average of bldg_area_total_net from subcomponents.
   */
  bldg_area_total_net?: number;
  /**
   * Weighted average of bldg_area_transport_warehousing from subcomponents.
   */
  bldg_area_transport_warehousing?: number;
  /**
   * Portion of building area that is transport_warehousing
   */
  bldg_area_transport_warehousing_pct?: number;
  /**
   * Weighted average of bldg_area_utilities from subcomponents.
   */
  bldg_area_utilities?: number;
  /**
   * Portion of building area that is utilities
   */
  bldg_area_utilities_pct?: number;
  /**
   * Weighted average of bldg_area_wholesale from subcomponents.
   */
  bldg_area_wholesale?: number;
  /**
   * Portion of building area that is wholesale
   */
  bldg_area_wholesale_pct?: number;
  /**
   * Weighted average of bldg_efficiency_ratio_nonresidential from subcomponents.
   */
  bldg_efficiency_ratio_nonresidential?: number;
  /**
   * Weighted average of bldg_efficiency_ratio_residential from subcomponents.
   */
  bldg_efficiency_ratio_residential?: number;
  /**
   * Weighted average of bldg_height from subcomponents.
   */
  bldg_height?: number;
  /**
   * The amount over/under allocated.
   */
  blend_allocation_error?: number;
  /**
   * The amount that each building type contributes to the Place Type.
   */
  building_types?: Array<BuiltFormItemWeight>;
  /**
   * The date this model instance was created.
   */
  created?: Date;
  /**
   * The user who created this model instance.
   */
  creator?: string;
  /**
   * Description of the place type.
   */
  description?: string;
  /**
   * Weighted average of du from subcomponents.
   */
  du?: number;
  /**
   * Weighted average of du_acc from subcomponents.
   */
  du_acc?: number;
  /**
   * Weighted average of du_attsf from subcomponents.
   */
  du_attsf?: number;
  /**
   * Weighted average of du_detsf_ll from subcomponents.
   */
  du_detsf_ll?: number;
  /**
   * Weighted average of du_detsf_sl from subcomponents.
   */
  du_detsf_sl?: number;
  /**
   * Weighted average of du_mf from subcomponents.
   */
  du_mf?: number;
  /**
   * Weighted average of emp from subcomponents.
   */
  emp?: number;
  /**
   * Weighted average of emp_accommodation from subcomponents.
   */
  emp_accommodation?: number;
  /**
   * Weighted average of emp_ag from subcomponents.
   */
  emp_ag?: number;
  /**
   * Portion of total employment that is AGRICULTURE.
   */
  emp_ag_agriculture_pct?: number;
  /**
   * Portion of total employment that is EXTRACTION.
   */
  emp_ag_extraction_pct?: number;
  /**
   * Portion of total employment that is Agricultural.
   */
  emp_ag_pct?: number;
  /**
   * Weighted average of emp_ag_pct_allocation_error from subcomponents.
   */
  emp_ag_pct_allocation_error?: number;
  /**
   * Weighted average of emp_agriculture from subcomponents.
   */
  emp_agriculture?: number;
  /**
   * Weighted average of emp_arts_entertainment from subcomponents.
   */
  emp_arts_entertainment?: number;
  /**
   * Weighted average of emp_construction from subcomponents.
   */
  emp_construction?: number;
  /**
   * Weighted average of emp_education from subcomponents.
   */
  emp_education?: number;
  /**
   * Weighted average of emp_extraction from subcomponents.
   */
  emp_extraction?: number;
  /**
   * Weighted average of emp_ind from subcomponents.
   */
  emp_ind?: number;
  /**
   * Portion of total employment that is CONSTRUCTION.
   */
  emp_ind_construction_pct?: number;
  /**
   * Portion of total employment that is MANUFACTURING.
   */
  emp_ind_manufacturing_pct?: number;
  /**
   * Portion of total employment that is Industrial.
   */
  emp_ind_pct?: number;
  /**
   * Weighted average of emp_ind_pct_allocation_error from subcomponents.
   */
  emp_ind_pct_allocation_error?: number;
  /**
   * Portion of total employment that is TRANSPORT_WARESHOUSING.
   */
  emp_ind_transport_warehousing_pct?: number;
  /**
   * Portion of total employment that is UTILITIES.
   */
  emp_ind_utilities_pct?: number;
  /**
   * Portion of total employment that is WHOLESALE.
   */
  emp_ind_wholesale_pct?: number;
  /**
   * Weighted average of emp_manufacturing from subcomponents.
   */
  emp_manufacturing?: number;
  /**
   * Weighted average of emp_medical_services from subcomponents.
   */
  emp_medical_services?: number;
  /**
   * Weighted average of emp_military from subcomponents.
   */
  emp_military?: number;
  /**
   * Portion of total employment that is Military.
   */
  emp_military_pct?: number;
  /**
   * Weighted average of emp_off from subcomponents.
   */
  emp_off?: number;
  /**
   * Portion of total employment that is MEDICAL_SERVICES.
   */
  emp_off_medical_services_pct?: number;
  /**
   * Portion of total employment that is OFFICE_SERVICES.
   */
  emp_off_office_services_pct?: number;
  /**
   * Portion of total employment that is Office.
   */
  emp_off_pct?: number;
  /**
   * Weighted average of emp_off_pct_allocation_error from subcomponents.
   */
  emp_off_pct_allocation_error?: number;
  /**
   * Weighted average of emp_office_services from subcomponents.
   */
  emp_office_services?: number;
  /**
   * Weighted average of emp_other_services from subcomponents.
   */
  emp_other_services?: number;
  /**
   * Weighted average of emp_pub from subcomponents.
   */
  emp_pub?: number;
  /**
   * Portion of total employment that is EDUCATION.
   */
  emp_pub_education_pct?: number;
  /**
   * Portion of total employment that is Public.
   */
  emp_pub_pct?: number;
  /**
   * Weighted average of emp_pub_pct_allocation_error from subcomponents.
   */
  emp_pub_pct_allocation_error?: number;
  /**
   * Portion of total employment that is PUBLIC_ADMIN.
   */
  emp_pub_public_admin_pct?: number;
  /**
   * Weighted average of emp_public_admin from subcomponents.
   */
  emp_public_admin?: number;
  /**
   * Weighted average of emp_restaurant from subcomponents.
   */
  emp_restaurant?: number;
  /**
   * Weighted average of emp_ret from subcomponents.
   */
  emp_ret?: number;
  /**
   * Portion of total employment that is ACCOMMODATION.
   */
  emp_ret_accommodation_pct?: number;
  /**
   * Portion of total employment that is ARTS_ENTERTAINMENT.
   */
  emp_ret_arts_entertainment_pct?: number;
  /**
   * Portion of total employment that is OTHER_SERVICES.
   */
  emp_ret_other_services_pct?: number;
  /**
   * Portion of total employment that is Retail.
   */
  emp_ret_pct?: number;
  /**
   * Weighted average of emp_ret_pct_allocation_error from subcomponents.
   */
  emp_ret_pct_allocation_error?: number;
  /**
   * Portion of total employment that is RESTAURANT.
   */
  emp_ret_restaurant_pct?: number;
  /**
   * Portion of total employment that is RETAIL_SERVICES.
   */
  emp_ret_retail_services_pct?: number;
  /**
   * Weighted average of emp_retail_services from subcomponents.
   */
  emp_retail_services?: number;
  /**
   * Weighted average of emp_transport_warehousing from subcomponents.
   */
  emp_transport_warehousing?: number;
  /**
   * Weighted average of emp_utilities from subcomponents.
   */
  emp_utilities?: number;
  /**
   * Weighted average of emp_wholesale from subcomponents.
   */
  emp_wholesale?: number;
  /**
   * Weighted average of far_building from subcomponents.
   */
  far_building?: number;
  /**
   * Weighted average of far_building_nonresidential from subcomponents.
   */
  far_building_nonresidential?: number;
  /**
   * Weighted average of far_building_residential from subcomponents.
   */
  far_building_residential?: number;
  /**
   * Weighted average of far_parking from subcomponents.
   */
  far_parking?: number;
  /**
   * Weighted average of far_total from subcomponents.
   */
  far_total?: number;
  /**
   * Weighted average of floor_count from subcomponents.
   */
  floor_count?: number;
  /**
   * Weighted average of floor_height from subcomponents.
   */
  floor_height?: number;
  /**
   * Weighted average of hardscape_area_other from subcomponents.
   */
  hardscape_area_other?: number;
  /**
   * Weighted average of hardscape_area_other_area_parcel_pct from subcomponents.
   */
  hardscape_area_other_area_parcel_pct?: number;
  /**
   * Weighted average of hardscape_area_total from subcomponents.
   */
  hardscape_area_total?: number;
  /**
   * Weighted average of hh from subcomponents.
   */
  hh?: number;
  /**
   * Weighted average of internal_parking_stall_size from subcomponents.
   */
  internal_parking_stall_size?: number;
  /**
   * Intersection Density
   */
  intersection_density?: number;
  /**
   * Weighted average of irrigated_area from subcomponents.
   */
  irrigated_area?: number;
  /**
   * Weighted average of irrigated_area_nonresidential from subcomponents.
   */
  irrigated_area_nonresidential?: number;
  /**
   * Non-Residential percentage of irrigated area.
   */
  irrigated_area_nonresidential_pct?: number;
  /**
   * Weighted average of irrigated_area_residential from subcomponents.
   */
  irrigated_area_residential?: number;
  /**
   * Residential percentage of irrigated area.
   */
  irrigated_area_residential_pct?: number;
  /**
   * Weighted average of is_agriculture from subcomponents.
   */
  is_agriculture?: number;
  /**
   * Weighted average of is_civic_institutional from subcomponents.
   */
  is_civic_institutional?: number;
  /**
   * Weighted average of is_commercial from subcomponents.
   */
  is_commercial?: number;
  /**
   * Weighted average of is_employment_density_high from subcomponents.
   */
  is_employment_density_high?: number;
  /**
   * Weighted average of is_employment_density_low from subcomponents.
   */
  is_employment_density_low?: number;
  /**
   * Weighted average of is_employment_density_mid from subcomponents.
   */
  is_employment_density_mid?: number;
  /**
   * Weighted average of is_industrial from subcomponents.
   */
  is_industrial?: number;
  /**
   * Weighted average of is_mixed_use from subcomponents.
   */
  is_mixed_use?: number;
  /**
   * Weighted average of is_natural_conservation from subcomponents.
   */
  is_natural_conservation?: number;
  /**
   * Weighted average of is_natural_resources from subcomponents.
   */
  is_natural_resources?: number;
  /**
   * Weighted average of is_open_space from subcomponents.
   */
  is_open_space?: number;
  /**
   * Weighted average of is_other from subcomponents.
   */
  is_other?: number;
  /**
   * Weighted average of is_residential from subcomponents.
   */
  is_residential?: number;
  /**
   * Weighted average of is_transportation_utilities from subcomponents.
   */
  is_transportation_utilities?: number;
  /**
   * Weighted average of is_water from subcomponents.
   */
  is_water?: number;
  /**
   * The key of this Place Type.
   */
  key?: string;
  /**
   * UF Meta Land-Use-Category
   */
  land_development_category?: PlaceType.LandDevelopmentCategoryEnum;
  /**
   * High Level Land use Category
   */
  land_use_category_level_1?: PlaceType.LandUseCategoryLevel1Enum;
  /**
   * Medium-grained land use category
   */
  land_use_category_level_2?: string;
  /**
   * Fine-grained land use category
   */
  land_use_category_level_3?: string;
  /**
   * The path to the library this Place Type belongs to.
   */
  library_path?: string;
  /**
   * The Place Type name.
   */
  name?: string;
  /**
   * The namespace of this Place Type.
   */
  namespace?: string;
  /**
   * Weighted average of nonhardscape_area from subcomponents.
   */
  nonhardscape_area?: number;
  /**
   * Weighted average of nonhardscape_area_area_parcel_pct from subcomponents.
   */
  nonhardscape_area_area_parcel_pct?: number;
  /**
   * Portion of non-hardscape area that is irrigated.
   */
  nonhardscape_area_irrigated_pct?: number;
  /**
   * Weighted average of parking_area_internal from subcomponents.
   */
  parking_area_internal?: number;
  /**
   * Weighted average of parking_area_structured from subcomponents.
   */
  parking_area_structured?: number;
  /**
   * Weighted average of parking_area_structured_footprint from subcomponents.
   */
  parking_area_structured_footprint?: number;
  /**
   * Weighted average of parking_area_structured_footprint_area_parcel_pct from subcomponents.
   */
  parking_area_structured_footprint_area_parcel_pct?: number;
  /**
   * Weighted average of parking_area_surface from subcomponents.
   */
  parking_area_surface?: number;
  /**
   * Weighted average of parking_area_surface_area_parcel_pct from subcomponents.
   */
  parking_area_surface_area_parcel_pct?: number;
  /**
   * Weighted average of parking_spaces_internal from subcomponents.
   */
  parking_spaces_internal?: number;
  /**
   * Weighted average of parking_spaces_structured from subcomponents.
   */
  parking_spaces_structured?: number;
  /**
   * Weighted average of parking_spaces_surface from subcomponents.
   */
  parking_spaces_surface?: number;
  /**
   * Weighted average of parking_spaces_total from subcomponents.
   */
  parking_spaces_total?: number;
  /**
   * Weighted average of pop from subcomponents.
   */
  pop?: number;
  /**
   * Weighted average of pop_groupquarter from subcomponents.
   */
  pop_groupquarter?: number;
  /**
   * The amount of ROW under/over allocated.
   */
  row_allocation_error_pct?: number;
  /**
   * Portion of Place Type taken up by Right of Way.
   */
  row_area_pct?: number;
  /**
   * Portion of ROW area taken up by arterial streets.
   */
  row_arterial_pct?: number;
  /**
   * Portion of ROW area taken up by collector streets.
   */
  row_collector_pct?: number;
  /**
   * Portion of ROW area taken up by local streets.
   */
  row_local_pct?: number;
  /**
   * Weighted average of structured_parking_stall_size from subcomponents.
   */
  structured_parking_stall_size?: number;
  /**
   * Full style description (fill, stroke, etc)
   */
  style?: Tstyle;
  /**
   * Weighted average of surface_parking_stall_size from subcomponents.
   */
  surface_parking_stall_size?: number;
  /**
   * High Level Land use Category
   */
  uf_land_use_category?: PlaceType.UfLandUseCategoryEnum;
  /**
   * Land use codes for this place type.
   */
  uf_land_use_codes?: Array<string>;
  /**
   * The date this model instance was last updated.
   */
  updated?: Date;
  /**
   * The user who last updated this model instance.
   */
  updated_by?: string;
  /**
   * If the Place Type is used in any canvas in the project.
   */
  used?: boolean;
  /**
   * If the PlaceType is valid and ready to be painted with.
   */
  valid?: boolean;
  /**
   * List of errors making this place type invalid.
   */
  validation_errors?: Array<BuiltFormValidationError>;
}
export namespace PlaceType {
  export type LandDevelopmentCategoryEnum =
    | 'urban'
    | 'compact'
    | 'standard'
    | 'rural'
    | 'undeveloped';
  export const LandDevelopmentCategoryEnum = {
    Urban: 'urban',
    Compact: 'compact',
    Standard: 'standard',
    Rural: 'rural',
    Undeveloped: 'undeveloped',
  } as const;
  export type LandUseCategoryLevel1Enum =
    | 'mixed_use'
    | 'residential'
    | 'commercial'
    | 'industrial'
    | 'civic_institutional'
    | 'transportation_utilities'
    | 'open_space'
    | 'other'
    | 'agriculture'
    | 'natural_resources'
    | 'natural_conservation'
    | 'water';
  export const LandUseCategoryLevel1Enum = {
    MixedUse: 'mixed_use',
    Residential: 'residential',
    Commercial: 'commercial',
    Industrial: 'industrial',
    CivicInstitutional: 'civic_institutional',
    TransportationUtilities: 'transportation_utilities',
    OpenSpace: 'open_space',
    Other: 'other',
    Agriculture: 'agriculture',
    NaturalResources: 'natural_resources',
    NaturalConservation: 'natural_conservation',
    Water: 'water',
  } as const;
  export type UfLandUseCategoryEnum =
    | 'mixed_use'
    | 'residential'
    | 'commercial'
    | 'industrial'
    | 'civic_institutional'
    | 'transportation_utilities'
    | 'open_space'
    | 'other'
    | 'natural_resources'
    | 'natural'
    | 'conservation_module_landscape_types';
  export const UfLandUseCategoryEnum = {
    MixedUse: 'mixed_use',
    Residential: 'residential',
    Commercial: 'commercial',
    Industrial: 'industrial',
    CivicInstitutional: 'civic_institutional',
    TransportationUtilities: 'transportation_utilities',
    OpenSpace: 'open_space',
    Other: 'other',
    NaturalResources: 'natural_resources',
    Natural: 'natural',
    ConservationModuleLandscapeTypes: 'conservation_module_landscape_types',
  } as const;
}
