import { OrganizationInfo } from 'uf-api';

export function getOrganizationIds(
  organizationInfo: OrganizationInfo | OrganizationInfo[],
): string | string[] {
  if (Array.isArray(organizationInfo)) {
    return organizationInfo.map(({ full_path: fullPath }) => fullPath);
  }

  return organizationInfo.full_path;
}
