import area from '@turf/area';
import bbox from '@turf/bbox';
import circle from '@turf/circle';
import distance from '@turf/distance';
import { point } from '@turf/helpers';
import intersect from '@turf/intersect';
import { featureEach, flattenEach } from '@turf/meta';
import squareGrid from '@turf/square-grid';
import transformRotate from '@turf/transform-rotate';
import transformTranslate from '@turf/transform-translate';
import {
  BBox,
  Feature,
  FeatureCollection,
  MultiPolygon,
  Polygon,
} from 'geojson';
import _ from 'lodash';
import uniqId from 'uniqid';
import warning from 'warning';

import { GridOptions } from 'uf/base/geo';
import { convertToKilometers } from 'uf/base/turfutils';
import { METERS_PER_KM, UnitLabelKeys } from 'uf/base/units';
import { rollbarError } from 'uf/rollbar';

export const MAX_NEW_FEATURES_FROM_SPLIT = 500;
export const MIN_GRID_SIZE_IN_ACRES = 0.001;
const MIN_GRID_SIZE_IN_KM = convertToKilometers(
  MIN_GRID_SIZE_IN_ACRES,
  'acres',
);

export const MAX_FEATURES_TO_SPLIT = 500;
export const TOO_MANY_NEW_FEATURES_FROM_SPLIT =
  'too_many_new_features_from_split';
export const GRID_SIZE_TOO_SMALL = 'grid_size_too_small';

const MOVE_EAST = 90;
const MOVE_WEST = 270;
const MOVE_NORTH = 0;
const MOVE_SOUTH = 180;

// this property indicates that a feature is a 'preview' feature, or one that we haven't saved to
// the backend yet.
export const SPLIT_PREVIEW_FEATURE = '_split_preview_feature';

const defaultGridOptions: GridOptions = {
  size: 1.0,
  angle: 0,
  offsetX: 0,
  offsetY: 0,
  unitKey: 'acres',
};

interface FeatureResults {
  numFailedIntersections: number;
  features: Feature<Polygon>[];
}

export function getGridFeatures(
  featureToSplit: Feature<Polygon>,
  gridOptions: GridOptions = defaultGridOptions,
): FeatureResults {
  const { size, angle: inputAngle, offsetX, offsetY, unitKey } = gridOptions;

  const lengthInKm = convertToKilometers(size, unitKey);

  const paddedBoundingBox = getPaddedBBoxForFeature(
    featureToSplit,
    lengthInKm,
    lengthInKm,
  );

  if (lengthInKm < MIN_GRID_SIZE_IN_KM) {
    throw new Error(GRID_SIZE_TOO_SMALL);
  }

  if (getTooManyFeatures(featureToSplit, gridOptions)) {
    throw new Error(TOO_MANY_NEW_FEATURES_FROM_SPLIT);
  }

  const gridFeatures = squareGrid(paddedBoundingBox, lengthInKm);

  // offset the grid
  const offsetXPercent = _.clamp(offsetX / 100, -1, 1);
  const offsetYPercent = _.clamp(offsetY / 100, -1, 1);
  const offsetXInKm = lengthInKm * offsetXPercent;
  const offsetYInKm = lengthInKm * offsetYPercent;
  const translatedXGridFeatures = transformTranslate(
    gridFeatures,
    Math.abs(offsetXInKm),
    offsetX >= 0 ? MOVE_WEST : MOVE_EAST,
  );

  const translatedXYGridFeatures = transformTranslate(
    translatedXGridFeatures,
    Math.abs(offsetYInKm),
    offsetY >= 0 ? MOVE_NORTH : MOVE_SOUTH,
  );

  // rotate the grid
  const angle = _.clamp(inputAngle, -180, 180);
  const rotatedGridFeatures = transformRotate(translatedXYGridFeatures, angle);

  // clip the grid to the feature
  const result = clipFeaturesToPolygon(rotatedGridFeatures, featureToSplit);

  return result;
}

function clipFeaturesToPolygon(
  features: FeatureCollection<Polygon>,
  feature,
): FeatureResults {
  const result: Feature<Polygon>[] = [];
  let numFailedIntersections = 0;
  featureEach<Polygon | MultiPolygon>(features, feat => {
    let clippedFeatures: Feature<Polygon | MultiPolygon>;
    try {
      clippedFeatures = intersect(feat, feature);
    } catch (e) {
      numFailedIntersections += 1;
      rollbarError(e);
      console.error('Could not clip feature to polygon. ', e);
    }

    if (clippedFeatures) {
      // Make sure to pull apart MultiPolygons
      flattenEach(
        clippedFeatures,
        (clippedFeature: Feature<Polygon>, index) => {
          warning(
            clippedFeature.geometry.type === 'Polygon',
            `Split created a ${clippedFeature.geometry.type} instead of a Polygon`,
          );

          if (clippedFeature.geometry.type !== 'Polygon') {
            return;
          }

          const id = `${feature.id}:${uniqId()}`;
          const newFeature: Feature<Polygon> = {
            ...clippedFeature,
            id,
            properties: {
              ...clippedFeature.properties,
              id,
              geometry_key: id,
              // the ID of the feature being split
              split_source_feature_id: feature?.properties?.id,
              [SPLIT_PREVIEW_FEATURE]: true,
            },
          };
          // Sometimes `intersect` creates a single point!
          result.push(newFeature);
        },
      );
    }
  });

  return { features: result, numFailedIntersections };
}

/**
 * running transformations on too many features can cause stack overflows.  this provides a rough
 * guess of how many features would be created from a grid We can handle more than
 * the limit set here, and the UI should handle precise max limit so we don't need to be that
 * accurate here.
 * @param feature
 * @param gridOptions
 */
export function getTooManyFeatures(
  feature: Feature<Polygon>,
  gridOptions: GridOptions = defaultGridOptions,
): boolean {
  const { size, unitKey } = gridOptions;

  const lengthInKm = convertToKilometers(size, unitKey);

  // If the grid would create more than 1000 features, return an empty list.  We need to do this
  // before all the turf js transfomations in this function to prevent overflow errors.
  // TODO: return an error here instead to inform the ui that too many features would be created.
  const areaFeature = area(feature); // in m^2
  const areaGrid = (lengthInKm * METERS_PER_KM) ** 2; // length in km, convert to m
  if (areaFeature > areaGrid * MAX_NEW_FEATURES_FROM_SPLIT) {
    return true;
  }
  return false;
}

/**
 * Function to get the minimum bounding box needed to alwyas contain a feature if it were rotated or offset.
 *
 * To do this we need to figure out the max radius of the rotating/offset feature.  first, we'd
 * create bounding box around the feature:
 *
 *                   ------------------(bbox)-------------------------
 *                   |xxxxxxxxxxxx                                   |
 *                   |  xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx      |
 *                   |         xxxxxxx(feature)xxxxxxx               |
 *                   |                 xxxxxxxxxxxxxxxxxxxxxxxxxxxxxx|
 *                   |-------------------------------------------------
 *
 * Then we find the distance from the center of the bbox to the corner.  Then we add the distance
 * of the hypotenus of the offsets and add these two values together to get a rough value of the radius.
 *
 * Now that we have the radius and a center point, we can create a buffer around that point and
 * finally make a bbox around that buffer.
 *
 * @param feature - the feature to make a bounding box for
 * @param maxOffsetX - value in kilometers. the amount the feature may be offset horizontally
 * @param maxOffsetY - value in kilometers. the amount the feature may be offset vertically
 */
function getPaddedBBoxForFeature(
  feature: Feature<Polygon>,
  maxOffsetX: number,
  maxOffsetY: number,
): BBox {
  const boundingBox = bbox(feature);
  const [swLng, swLat, neLng, neLat] = boundingBox;

  const latCenter = (neLat + swLat) / 2;
  const lngCenter = (neLng + swLng) / 2;

  const centerCoord: [number, number] = [lngCenter, latCenter];
  const neCoord: [number, number] = [neLng, neLat];

  const radiusBBox = distance(point(centerCoord), point(neCoord));
  const offset = Math.hypot(maxOffsetX, maxOffsetY);
  const radius = radiusBBox + offset;

  return bbox(circle(point(centerCoord), radius));
}

export function getGridSizeTooSmall(size: number, unitKey: UnitLabelKeys) {
  if (size < 0) {
    return true;
  }

  const currentGridSizeInKm = convertToKilometers(size, unitKey);
  return currentGridSizeInKm < MIN_GRID_SIZE_IN_KM;
}
