import React, { FunctionComponent } from 'react';
import { Box, ScrollView } from 'react-layout-components';
import { connect } from 'react-redux';

import {
  getActiveProjectId,
  getActiveScenarioId,
  getBaseScenarioId,
} from 'uf/app/selectors';
import { ProjectId } from 'uf/projects';
import { ScenarioId } from 'uf/scenarios';
import AnalysisCharts from 'uf/ui/analysis/AnalysisCharts/AnalysisCharts';
import AnalysisModuleListItem from 'uf/ui/analysis/AnalysisModuleListItem/AnalysisModuleListItem';
import { DEFAULT_CHART_WIDTH } from 'uf/ui/charts/dimensions';

const PADDING_LEFT = 24;
const PADDING_RIGHT = 24;
const CARD_PADDING = 32;
const CHART_WIDTH =
  DEFAULT_CHART_WIDTH - PADDING_LEFT - PADDING_RIGHT - CARD_PADDING;

interface OwnProps {
  moduleKey: string;
}

interface StateProps {
  // string status for each module
  projectId: ProjectId;
  scenarioId: ScenarioId;
  baseScenarioId: string;
}

type AnalysisModuleDetailsProps = OwnProps & StateProps;

export const AnalysisModuleDetails: FunctionComponent<AnalysisModuleDetailsProps> =
  props => {
    const { moduleKey, projectId, scenarioId, baseScenarioId } = props;
    const isBaseScenario = baseScenarioId === scenarioId;
    return (
      <Box column flex={1}>
        <AnalysisModuleListItem
          moduleKey={moduleKey}
          projectId={projectId}
          scenarioId={scenarioId}
          isBaseScenario={isBaseScenario}
        />

        <ScrollView alignItems="center" flex={1}>
          <AnalysisCharts width={CHART_WIDTH} />
        </ScrollView>
      </Box>
    );
  };

function mapStateToProps(state): StateProps {
  return {
    projectId: getActiveProjectId(state),
    scenarioId: getActiveScenarioId(state),
    baseScenarioId: getBaseScenarioId(state),
  };
}

export default connect<StateProps, null, OwnProps>(mapStateToProps)(
  AnalysisModuleDetails,
);
