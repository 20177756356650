import _ from 'lodash';
import { createSelector, ParametricSelector } from 'reselect';
import { t } from 'ttag';
import warning from 'warning';

import {
  AnalysisModuleInfo,
  ReportResults,
  ReportSpec,
} from 'uf-api/model/models';
import {
  makeGetProjectAnalysisModules,
  makeGetProjectAnalysisResultsByModuleKey,
} from 'uf/analysis/selectors/analysis';
import { EMPTY_ARRAY, EMPTY_OBJECT } from 'uf/base';
import { cacheableConcat } from 'uf/base/array';
import { makeGetFromPropsSelector } from 'uf/base/selector';
import { DataState, EmptyState, getData, isLoading } from 'uf/data/dataState';
import { ProjectId } from 'uf/projects';
import {
  getAnalysisSpecification,
  getReportSpecification,
} from 'uf/reporting/defaultReports';
import { LegacyReport, makeScenarioReportKey } from 'uf/reporting/index';
import { ReportingState } from 'uf/reporting/state';
import { ScenarioId } from 'uf/scenarios';
import { UFState } from 'uf/state';

export interface ChartDatum {
  key?: string;
  name?: string;
  units?: string;
  is_percent?: boolean;
  value?: number;
  abbreviation?: string;
  terse_abbreviation?: string;
  terse_name?: string;
}

interface WithProjectId {
  projectId: ProjectId;
}

/**
 * Get spec(s) for reporting view
 */
export const getReportingChartsSpecsForProject: ParametricSelector<
  any,
  WithProjectId,
  LegacyReport[]
> = createSelector(
  makeGetProjectAnalysisModules(),
  (moduleInfos: AnalysisModuleInfo[]) => {
    const moduleKeys = moduleInfos.map(module => module.module_key);
    const chartsSpec = getReportSpecification(moduleKeys);
    return chartsSpec;
  },
);

/**
 * Get spec(s) for analysis view
 */
export const getAnalysisChartsSpec: ParametricSelector<
  any,
  WithProjectId,
  LegacyReport[]
> = createSelector(
  makeGetProjectAnalysisModules(),
  (moduleInfos: AnalysisModuleInfo[]) => {
    const moduleKeys = moduleInfos.map(module => module.module_key);
    const chartsSpec = getAnalysisSpecification(moduleKeys);
    return chartsSpec;
  },
);

export function getReportState(state: UFState): ReportingState {
  return state.reporting ?? (EMPTY_OBJECT as ReportingState);
}

const getReportLoadState = createSelector(
  getReportState,
  reportState => reportState.reports,
);

/**
 * Selector for finding the report data for a specific scenario
 */
export function makeGetScenarioReportState() {
  return createSelector(
    makeGetFromPropsSelector<ProjectId, 'projectId'>('projectId'),
    makeGetFromPropsSelector<ScenarioId, 'scenarioId'>('scenarioId'),
    makeGetFromPropsSelector<ReportSpec, 'spec'>('spec'),
    getReportLoadState,
    (projectId, scenarioId, spec, reportLoadStates) => {
      return getScenarioReportStateInner(
        projectId,
        scenarioId,
        spec,
        reportLoadStates,
      );
    },
  );
}

export function makeGetScenarioReportStateGetter() {
  return createSelector(
    getReportLoadState,
    reportLoadStates =>
      (projectId: ProjectId, scenarioId: ScenarioId, spec: ReportSpec) => {
        return getScenarioReportStateInner(
          projectId,
          scenarioId,
          spec,
          reportLoadStates,
        );
      },
  );
}

function getScenarioReportStateInner(
  projectId: ProjectId,
  scenarioId: ScenarioId,
  spec: ReportSpec,
  reportLoadStates: Record<string, DataState<ReportResults>>,
): DataState<ReportResults> {
  if (!spec) {
    return EmptyState;
  }
  const key = makeScenarioReportKey(projectId, scenarioId, spec);
  return reportLoadStates[key] || EmptyState;
}

/**
 * Private selector for finding the report data for a list of scenarios. This collects all the
 * DataStates for a single report chart. To get the actual chart data see the public selector
 * `makeGetChartData` below.
 */
function makeGetReportStates() {
  return createSelector(
    makeGetFromPropsSelector<ProjectId, 'projectId'>('projectId'),
    makeGetFromPropsSelector<string[], 'scenarioIds'>('scenarioIds'),
    makeGetFromPropsSelector<ReportSpec, 'spec'>('spec'),
    makeGetScenarioReportStateGetter(),
    (
      projectId: ProjectId,
      scenarioIds: string[],
      spec: ReportSpec,
      getScenarioReportState,
    ): DataState<ReportResults>[] => {
      if (!spec) {
        return EMPTY_ARRAY;
      }
      return cacheableConcat(
        scenarioIds.map(scenarioId =>
          getScenarioReportState(projectId, scenarioId, spec),
        ),
      );
    },
  );
}

export function makeGetChartLoading() {
  return createSelector(
    makeGetFromPropsSelector<string, 'reportKey'>('reportKey'), // i.e. 'pop', etc
    makeGetReportStates(),
    (reportKey, reportStates) =>
      reportStates.some(dataState => isLoading(dataState)),
  );
}

export function makeGetChartSubtitle() {
  return createSelector(
    makeGetFromPropsSelector<string, 'reportKey'>('reportKey'),
    makeGetFromPropsSelector<string, 'analysisModuleKey'>('analysisModuleKey'),
    getAnalysisChartsSpec,
    (
      analysisChartKey: string,
      analysisModuleKey: string,
      legacyReports: LegacyReport[],
    ) => {
      const oldSpecByModule = legacyReports.find(
        section => section.key === analysisModuleKey,
      );
      const chartSpec = oldSpecByModule.charts.find(
        chart => chart.key === analysisChartKey,
      );

      const chartSubtitleText = chartSpec.subtitle || null;
      const chartSubtitleUnit = chartSpec.units || null;

      let chartSubtitle: string = '';
      if (chartSubtitleText && chartSubtitleUnit) {
        chartSubtitle = t`${chartSubtitleText} in ${chartSubtitleUnit}`;
      } else if (chartSubtitleText && !chartSubtitleUnit) {
        chartSubtitle = chartSubtitleText;
      } else if (!chartSubtitleText && chartSubtitleUnit) {
        chartSubtitle = chartSubtitleUnit;
      }
      return chartSubtitle;
    },
  );
}

export function makeGetChartData() {
  return createSelector(
    makeGetFromPropsSelector<string[], 'scenarioIds'>('scenarioIds'),
    makeGetFromPropsSelector<string, 'reportKey'>('reportKey'), // i.e. 'pop', etc
    makeGetReportStates(),
    getAnalysisChartsSpec,
    (
      scenarioIds: string[],
      reportKey: string,
      reportStates: DataState<ReportResults>[],
      legacyReports: LegacyReport[],
    ): ChartDatum[][] => {
      if (scenarioIds.length === 0) {
        return EMPTY_ARRAY;
      }
      return _.zip(reportStates, scenarioIds).map(
        ([reportState, scenarioId]) => {
          const scenarioResult = getData(reportState, {});
          if (!scenarioResult) {
            return EMPTY_ARRAY as ChartDatum[];
          }
          const { results = [] } = scenarioResult;
          const outputGroup = results.find(og => og.key === reportKey);
          if (!outputGroup) {
            return EMPTY_ARRAY as ChartDatum[];
          }
          const moduleReport = legacyReports.find(legacyReport =>
            legacyReport.charts.find(chart => chart.key === outputGroup.key),
          );
          const moduleChart = moduleReport
            ? moduleReport.charts.find(chart => chart.key === outputGroup.key)
            : null;

          const data = outputGroup.series.map((outputSeries): ChartDatum => {
            const { scenarios = [], ...series } = outputSeries;
            const scenarioData = scenarios.find(
              scenario => scenario.scenario_path === scenarioId,
            );
            // may not have begun loading yet
            // or if scenario data recieved has no values,
            // example: for modules that don't return charts for base scenario runs
            if (!scenarioData || !scenarioData.values) {
              warning(
                !!scenarioData,
                `Missing data for ${scenarioId} in output series ${outputSeries.key}`,
              );
              return series;
            }
            // search if the chart has an additional param hideZeroValue defined,
            // and return an empty object if true.
            if (
              moduleChart?.hideZeroValues &&
              scenarioData.values.every(v => v === 0)
            ) {
              return EMPTY_OBJECT;
            }
            const { values = [] } = scenarioData;
            return {
              ...series,
              // may be undefined if the server didn't give us anything.
              value: values[0],
            };
          });
          return data;
        },
      );
    },
  );
}

export function makeGetScenarioIdsWithResults() {
  return createSelector(
    makeGetProjectAnalysisResultsByModuleKey(),
    getReportingChartsSpecsForProject,
    makeGetFromPropsSelector<string, 'reportSpecKey'>('reportSpecKey'),
    (analysisResults, specs, reportSpecKey): string[] => {
      const reportSpec = specs.find(({ key }) => key === reportSpecKey);
      if (!reportSpec) {
        return EMPTY_ARRAY;
      }
      const analysisModuleKeys = findAnalysisModuleKeys(reportSpec);
      const moduleScenarioKeys = _.flatten(
        Object.entries(analysisResults)
          .filter(([moduleKey]) => analysisModuleKeys.includes(moduleKey))
          .map(([moduleKey, result]) => {
            return Object.keys(result).filter(
              ([scenarioId, results]) => results.length,
            );
          }),
      );
      return moduleScenarioKeys;
    },
  );
}

function findAnalysisModuleKeys(report: LegacyReport): string[] {
  // TODO: switch to flatMap
  return _.uniq(
    _.flatten(
      report.charts
        .map(chart => {
          return chart.series
            .map(series => {
              if (series.source === 'analysis') {
                return series.sourceKey;
              }
              if (series.source === 'canvas') {
                return 'paint_tracking';
              }
              return null;
            })
            .filter(key => key);
        })
        .filter(key => key),
    ),
  );
}
