// A generalized data view which shows a filter/layer toggle, a map, and a data table.
import { boundMethod } from 'autobind-decorator';
import classNames from 'classnames';
import React, { Component, CSSProperties } from 'react';
import ContainerDimensions from 'react-container-dimensions';
import { hot } from 'react-hot-loader/root';
import { Box } from 'react-layout-components';
import { connect } from 'react-redux';
import SplitPane from 'react-split-pane';
import compose from 'recompose/compose';
import { t } from 'ttag';

import {
  getActiveProjectId,
  makeGetActiveScenarioIdForProject,
} from 'uf/app/selectors';
import {
  getAnalysisUIVisible,
  getBuildUIVisible,
  getExploreViewVisible,
} from 'uf/explore/selectors/explore';
import { makeGetActiveViewId } from 'uf/explore/selectors/views';
import { ProjectId } from 'uf/projects';
import { ScenarioId } from 'uf/scenarios';
import { UFState } from 'uf/state';
import popoverStyles from 'uf/styles/popups.module.css';
import rootStyles from 'uf/styles/root.module.css';
import { tw } from 'uf/tailwindcss-classnames';
import CollapseTab from 'uf/ui/base/CollapseTab/CollapseTab';
import ErrorBoundary from 'uf/ui/base/ErrorBoundary/ErrorBoundary';
import { GetComponentProps } from 'uf/ui/base/types';
import { DEFAULT_CHART_WIDTH } from 'uf/ui/charts/dimensions';
import { DEFAULT_DETAIL_PANEL_WIDTH, DEFAULT_PANEL_WIDTH } from 'uf/ui/explore';
import ExploreDataViewer from 'uf/ui/explore/ExploreDataViewer/ExploreDataViewer';
import ExploreMapPane from 'uf/ui/explore/ExploreMapPane/ExploreMapPane';
import { ExplorePanel } from 'uf/ui/explore/ExplorePanel/ExplorePanel';
import ExploreSecondaryPanel from 'uf/ui/explore/ExploreSecondaryPanel/ExploreSecondaryPanel';
import { ViewId } from 'uf/views';

import styles from './ExploreView.module.css';
import { getActiveLayerId } from 'uf/explore/selectors/layers';

const PADDING_LEFT = 4;
export const PANEL_WIDTH = DEFAULT_CHART_WIDTH + PADDING_LEFT + PADDING_LEFT;

type SplitPaneProps = GetComponentProps<SplitPane>;

const exploreViewStyle: CSSProperties = {
  overflow: 'hidden',
  position: 'relative',
};

// add a couple styles to make render more readable
const resizerStyle: CSSProperties = {
  zIndex: 1,
  boxSizing: 'border-box',
  backgroundColor: 'var(--color-blue-grey-50)',
  border: '1px solid var(--color-blue-grey-300)',
  margin: '-1px',
};

const horizontalResizerStyle: CSSProperties = {
  ...resizerStyle,
  height: '8px',
  width: '100%',
  cursor: 'row-resize',
};

interface StateProps {
  projectId: ProjectId;
  scenarioId: ScenarioId;
  viewId: ViewId;
  activeLayerId?: string;
  // for showing the charts; toggling explore view
  exploreViewVisible?: boolean;
  paintUIVisible?: boolean;
  analysisUIVisible?: boolean;
}

type Props = StateProps;

interface State {
  detailsPaneCollapsed: boolean;
  dataViewerCollapsed: boolean;
}

const dataTabClassName = classNames(
  'CollapseTab-ExploreDataViewer',
  tw('tw-p-0'),
  tw('tw-bg-blueGray-50'),
  tw('tw-rounded-tr-md'),
  tw('tw-rounded-tl-md'),
  tw('tw-rounded-b-none'),
);

const panelTabClassName = classNames(
  'CollapseTab-ExploreDetailsPane',
  tw('tw-p-0'),
  tw('tw-bg-blueGray-50'),
  tw('tw-rounded-tr-md'),
  tw('tw-rounded-br-md'),
  tw('tw-rounded-l-none'),
);

export class ExploreView extends Component<Props, State> {
  state = {
    detailsPaneCollapsed: false,
    dataViewerCollapsed: true,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.activeLayerId !== this.props.activeLayerId) {
      this.setState({ detailsPaneCollapsed: !this.props.activeLayerId });
    }
  }

  @boundMethod
  onClickDetailsPaneTab() {
    this.setState(({ detailsPaneCollapsed }) => ({
      detailsPaneCollapsed: !detailsPaneCollapsed,
    }));
  }

  @boundMethod
  onClickDataViewerTab() {
    this.setState(({ dataViewerCollapsed }) => ({
      dataViewerCollapsed: !dataViewerCollapsed,
    }));
  }

  render() {
    const {
      projectId,
      scenarioId,
      viewId,
      activeLayerId,
      exploreViewVisible,
      analysisUIVisible,
    } = this.props;
    const { detailsPaneCollapsed, dataViewerCollapsed } = this.state;

    // With the detailspane, the secondary panel is *only* used for analysis.
    const secondaryPanelVisible = analysisUIVisible;

    const layerPanelWidth = DEFAULT_PANEL_WIDTH;

    const dataTableVisible = exploreViewVisible && activeLayerId;
    const dataTableSize =
      dataTableVisible && !dataViewerCollapsed ? '75%' : '100%';
    const mapChartsAndDataTableProps: SplitPaneProps = {
      split: 'horizontal',
      maxSize: 0,
      defaultSize: '100%',
      pane2Style: { height: '0' },
      size: dataTableSize,
      resizerStyle:
        dataTableVisible && !dataViewerCollapsed && horizontalResizerStyle,
    };

    const tabTextClassName = classNames(tw('tw-text-blueGray-500'), {
      [tw('tw-text-blueGray-700')]: activeLayerId,
    });

    const secondaryPanelWidth = DEFAULT_CHART_WIDTH;
    const detailsPaneWidth = DEFAULT_DETAIL_PANEL_WIDTH;
    const detailsTabLabel = t`Layer Details`;
    // offset by 6px to account for the slider handle
    const dataViewerTabOffset =
      dataTableVisible && !dataViewerCollapsed ? '0px, 6px' : '0px';
    const disabledTabTooltipText = t`Select a layer to view this pane.`;

    return (
      <Box
        height="100%"
        className={'ExploreView'}
        flexGrow={1}
        style={exploreViewStyle}>
        {/* Explore View */}
        {/* [LayerPanel], [Map, SecondaryPane, DataTable] */}
        {/* LayerPanel */}
        {exploreViewVisible && (
          // CollapseTab here is only responsible for showing the tab since we don't want to
          // collapse the entire ExplorePanel, but we want to show the tab hanging off the entire
          // panel.
          <CollapseTab
            open
            buttonClassName={classNames(tabTextClassName, panelTabClassName)}
            popoverClassName={styles.explorePaneTab}
            position="right-top"
            offset="79px"
            label={detailsTabLabel}
            detailsPaneCollapsed={detailsPaneCollapsed}
            disabled={!activeLayerId}
            disabledTooltipText={disabledTabTooltipText}
            onClick={this.onClickDetailsPaneTab}>
            <ExplorePanel
              projectId={projectId}
              scenarioId={scenarioId}
              viewId={viewId}
              layerPanelWidth={layerPanelWidth}
              detailsPaneWidth={detailsPaneWidth}
              detailsPaneCollapsed={detailsPaneCollapsed}
            />
          </CollapseTab>
        )}

        {/* [ Map, SecondaryPane, DataTable] */}
        <Box flexGrow={1} style={{ position: 'relative' }}>
          {/* [Maps, SecondaryPane], [DataTable] */}
          <SplitPane {...mapChartsAndDataTableProps}>
            {/* Map, SecondaryPane */}
            <Box flex={1} className={rootStyles.overflowHidden}>
              {/* Map and Toolbar */}
              <ExploreMapPane
                projectId={projectId}
                activeLayerId={activeLayerId}
              />
              {secondaryPanelVisible && (
                <ExploreSecondaryPanel width={secondaryPanelWidth} />
              )}
            </Box>

            {/* DataTable */}
            <ContainerDimensions>
              {({ width }) => (
                <CollapseTab
                  buttonClassName={classNames(
                    tabTextClassName,
                    dataTabClassName,
                  )}
                  popoverClassName={styles.explorePaneTab}
                  targetClassName={popoverStyles.fit}
                  offset={dataViewerTabOffset}
                  open={dataTableVisible && !dataViewerCollapsed}
                  position="top"
                  label={t`Data Table`}
                  disabled={!activeLayerId}
                  disabledTooltipText={disabledTabTooltipText}
                  targetLength={`${width}px`}
                  onClick={this.onClickDataViewerTab}>
                  <ExploreDataViewer width={width} />
                </CollapseTab>
              )}
            </ContainerDimensions>
          </SplitPane>
        </Box>
      </Box>
    );
  }
}
const ConnectedExploreView = compose<Props, {}>(
  connect<StateProps, never, never, UFState>(() => {
    const getActiveScenarioId = makeGetActiveScenarioIdForProject();
    const getActiveViewId = makeGetActiveViewId();
    return (state): StateProps => {
      const projectId = getActiveProjectId(state);
      const scenarioId = getActiveScenarioId(state, {
        projectId,
      });
      const viewId = getActiveViewId(state, { projectId });
      return {
        activeLayerId: getActiveLayerId(state, {
          projectId,
          scenarioId,
          viewId,
        }),
        projectId,
        scenarioId,
        viewId,
        exploreViewVisible: getExploreViewVisible(state),
        paintUIVisible: getBuildUIVisible(state),
        analysisUIVisible: getAnalysisUIVisible(state),
      };
    };
  }),
)(ExploreView);

function ExploreViewWithErrorBoundary(
  props: GetComponentProps<typeof ConnectedExploreView>,
) {
  return (
    <ErrorBoundary title={t`Explore mode error`} componentName="ExploreView">
      <ConnectedExploreView {...props} />
    </ErrorBoundary>
  );
}

export default hot(ExploreViewWithErrorBoundary);
