import {
  CuratedColumnSymbology,
  InterpolationTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/public/dataset/city_of_somerville_parcels': {
    polytype: [
      {
        display_hints: {
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          scale: SymbologyScales.CATEGORICAL,
          theme: 'custom',
        },
        paint: {
          'fill-color': {
            default: '#d62728',
            property: 'polytype',
            stops: [
              {
                color: 'transparent',
                value: 'PARCEL',
              },
              {
                color: '#333333',
                value: 'ROW',
              },
              {
                color: '#cccccc',
                value: 'RR ROW',
              },
            ],
            type: 'categorical',
          },
          'fill-opacity': 0.9,
        },
        type: SymbologyTypes.FILL,
      },
      {
        display_hints: {
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          scale: SymbologyScales.CATEGORICAL,
          theme: 'custom',
        },
        paint: {
          'line-color': '#808080',
          'line-opacity': 0.9,
          'line-width': 0.5,
        },
        type: SymbologyTypes.LINE,
      },
    ],
  },
};

export default symbologies;
