import React, { CSSProperties, Fragment, PureComponent } from 'react';
import { VictoryAxis, VictoryLabel } from 'victory';
import { VictoryCommonProps } from 'victory-core';

import { GetComponentProps } from 'uf/ui/base/types';

import { AxisAdjustment } from './chart';
import { Text } from './Text';

interface BarChartSeriesLabelsProps<D> extends VictoryCommonProps {
  data: D[];
  getDatumKey: (datum: D, index?: number) => string;
  getLabel: (datum: any) => string;
  axisAdjustment: AxisAdjustment;
  style?: GetComponentProps<typeof VictoryAxis>['style'];

  /**
   * value in pixels.  describes the gap on the right side of the chart that the label should avoid.
   */
  rightPadding?: number;
  fontSize?: number;
}

// The only way to trigger zero labels
const NO_TICK_VALUES = [null];
/**
 * Labels each bar inline with the name/label of the series.
 */
export class BarChartSeriesLabels<D extends object> extends PureComponent<
  BarChartSeriesLabelsProps<D>
> {
  static defaultProps = {
    // NOTE: for now the default is 72px, which is roughly the width of the largest ValueLabel:
    // 999,999.99
    rightPadding: 72,
    fontSize: 18,
  };

  render() {
    const {
      data,
      getDatumKey: getKey,
      getLabel,
      axisAdjustment,
      style,
      fontSize,
      rightPadding,
      ...axisProps
    } = this.props;
    const { dy } = axisAdjustment as { dy: number };
    const baseStyle: CSSProperties = {
      fontSize,
    };

    return (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <Fragment>
        {data.map((datum, i) => {
          const otherProps = {
            textComponent: (
              <Text
                chartWidth={axisProps.width}
                rightPadding={rightPadding}
                fontSize={fontSize}
                yAdjustment={dy}
                label={getLabel(datum)}
              />
            ),
          };
          const axisSeriesLabelComponent = (
            <VictoryLabel
              {...(otherProps as any)}
              x={0}
              dx={0}
              style={baseStyle}
              {...axisAdjustment}
            />
          );

          return (
            <VictoryAxis
              dependentAxis
              tickValues={NO_TICK_VALUES}
              // TODO: figure out why orientation is always bottom, even on vertical charts?
              orientation="bottom"
              {...axisProps}
              animate={null}
              key={getKey(datum, i)}
              style={{
                ...style,
                tickLabels: { fill: 'none' },
                axisLabel: { textAnchor: 'start' },
                axis: { stroke: 'none' },
              }}
              label={key => getLabel(datum)}
              axisLabelComponent={axisSeriesLabelComponent}
              axisValue={getKey(datum)}
            />
          );
        })}
      </Fragment>
    );
  }
}
