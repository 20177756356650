import { createSelector, ParametricSelector, Selector } from 'reselect';

import { makeGetFromPropsSelector } from 'uf/base/selector';
import { combineDataStates, DataState } from 'uf/data/dataState';

import { CombinedTaskState } from './state';

/**
 * Create a selector that gets the unified data state for a task.
 *
 * Usage:
 *
 * ```
 * // extract ownprops, convert to string key
 * function makeGetFooParamKey() {
 *   return createSelector(
 *     makeGetFromPropsSelector<string, 'fooId'>('fooId'),
 *     makeGetFromPropsSelector<string, 'barId'>('barId'),
 *     (fooId, barId) => makeMasterFooKey(fooId, barId),
 *   )
 * }
 *
 * // selector to get combined data state
 * function makeFooState() {
 *   return makeTaskDataStateSelector(
 *     makeGetFooTaskState(), // selector that gets root of CombinedTaskState
 *     makeGetFooParamKey(),
 *   );
 * }
 * ```
 * @param parentSelector
 */
export function makeTaskDataStateSelector<
  S,
  D,
  M,
  K extends Record<string, string>,
>(
  parentSelector: Selector<S, CombinedTaskState<D, M>>,
  keySelector: ParametricSelector<S, K, string> = makeGetFromPropsSelector<
    string,
    'key'
  >('key'),
) {
  return createSelector(
    parentSelector,
    keySelector,
    (taskRoot, key): DataState<null> => {
      const enqueuedLoadingState = taskRoot.enqueued[key];
      const taskIds = taskRoot.taskIds[key];
      const taskLoadingStates =
        taskIds?.map(taskId => taskRoot.tasks[taskId]) ?? [];
      return combineDataStates([enqueuedLoadingState, ...taskLoadingStates]);
    },
  );
}
