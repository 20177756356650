import { t } from 'ttag';

import { ColumnKey, LayerId } from 'uf/layers';
import { Expression } from 'uf/layers/filters';

/**
 * Utility functions for creating projects
 */

function makeFeatureFilter(feature, columnKey: ColumnKey): Expression {
  return {
    fn: '==',
    left: { fn: 'column', key: columnKey },
    right: feature[columnKey],
  };
}

export interface CensusLocation {
  layerId: LayerId;
  feature: any;
  label?: string;
}
/**
 * `censusLocation` is an object returned by LocationPicker,
 * which includes `feature`
 *
 */
export function makeFeatureBoundary(censusLocation: CensusLocation) {
  const { layerId, feature } = censusLocation;
  const filter = makeFeatureFilter(feature, 'geoid');
  return {
    layerId,
    name: feature.name,
    // Creates something like "Canvas - Denver" which will also become "Painted Canvas - Denver"
    canvasName: makeCanvasName(feature.name),
    filter,
  };
}

export function makeCanvasName(name: string) {
  return t`Base Canvas`;
}
