import { Feature, Geometry } from 'geojson';
import { createSelector, defaultMemoize } from 'reselect';

import { EMPTY_ARRAY, EMPTY_OBJECT } from 'uf/base';
import { cacheableConcat } from 'uf/base/array';
import { makeGetFromPropsSelector } from 'uf/base/selector';
import { LayerId } from 'uf/layers';
import { makeExpressionsExecutor } from 'uf/layers/expressions';
import {
  convertFiltersToExpressions,
  filtersAreEmpty,
  FilterSpec,
} from 'uf/layers/filters';
import { makeGetLayerMetadata } from 'uf/layers/selectors/metadata';
import { generateStats } from 'uf/layers/stats';
import { filterEntitiesFromEdits } from 'uf/mapedit';
import { LayerEdits, LayerEditsByProject } from 'uf/mapedit/state';
import { ProjectId } from 'uf/projects';

import { getMapEditState } from './';

const getMapEdits = createSelector(
  getMapEditState,
  (mapEditState): LayerEditsByProject => mapEditState?.edits || EMPTY_OBJECT,
);

const EmptyEdits: LayerEdits = Object.freeze({
  deletedFeatureIds: EMPTY_ARRAY,
  featureOverrides: EMPTY_ARRAY,
  selectedFeatureIds: EMPTY_ARRAY,
  selectedCoordPaths: EMPTY_OBJECT,
  defaultProperties: EMPTY_OBJECT,
});

export function makeGetLayerEditState() {
  return createSelector(
    makeGetFromPropsSelector<LayerId, 'layerId'>('layerId'),
    makeGetFromPropsSelector<ProjectId, 'projectId'>('projectId'),
    getMapEdits,
    (layerId, projectId, mapEdits): LayerEdits =>
      mapEdits?.[projectId]?.[layerId] ?? EmptyEdits,
  );
}

export function makeGetLayerEditStateGetter<
  G extends Geometry,
  T = Record<string, any>,
>() {
  return createSelector(getMapEdits, mapEdits =>
    defaultMemoize(
      (projectId: ProjectId, layerId: LayerId): LayerEdits<G, T> =>
        (mapEdits?.[projectId]?.[layerId] ?? EmptyEdits) as LayerEdits<G, T>,
    ),
  );
}

export function makeGetLayerEditFeatures<
  G extends Geometry,
  P extends Record<string, any> = Record<string, any>,
>() {
  return createSelector(
    makeGetLayerEditState(),
    (layerEdits): Feature<G, P>[] => layerEdits.featureOverrides ?? EMPTY_ARRAY,
  );
}

export function makeGetLayerEditDeletedFeatureIds() {
  return createSelector(
    makeGetLayerEditState(),
    (layerEdits): (string | number)[] => layerEdits.deletedFeatureIds,
  );
}

export function makeGetLayerEditDeletedFeatureIdsGetter<G extends Geometry>() {
  return createSelector(
    makeGetLayerEditStateGetter<G>(),
    getLayerEdits =>
      (projectId: ProjectId, layerId: LayerId): (string | number)[] =>
        getLayerEdits(projectId, layerId).deletedFeatureIds ?? EMPTY_ARRAY,
  );
}

export function makeGetLayerEditDefaultProperties() {
  return createSelector(
    makeGetLayerEditState(),
    (layerEdits): Record<string, any> =>
      layerEdits.defaultProperties ?? EMPTY_OBJECT,
  );
}

export function makeGetLayerEditFeaturesGetter<
  G extends Geometry,
  T = Record<string, any>,
>() {
  return createSelector(
    makeGetLayerEditStateGetter<G, T>(),
    getLayerEditState =>
      (projectId: ProjectId, layerId: LayerId): Feature<G, T>[] =>
        getLayerEditState(projectId, layerId).featureOverrides ?? EMPTY_ARRAY,
  );
}

export function makeGetEditFeaturesFilteredGetter<
  G extends Geometry,
  T = Record<string, any>,
>() {
  return createSelector(
    makeGetLayerEditFeaturesGetter<G, T>(),
    getLayerEditFeatures =>
      (
        projectId: ProjectId,
        layerId: LayerId,
        filters: Partial<FilterSpec>,
      ) => {
        const features = getLayerEditFeatures(projectId, layerId);
        if (!filtersAreEmpty(filters)) {
          const executor = makeExpressionsExecutor(
            convertFiltersToExpressions(filters),
          );
          return features.filter(feature => executor(feature));
        }
        return features;
      },
  );
}

/**
 * A generalized selector to apply changes from mapedit to an existing list of features.
 *
 * Sample usage:
 *
 * ```
 * const makeGetEditedHouses(
 *   makeGetHouses(),
 *   makeGetEditedFeaturesFilter(),
 *   (houses, filterHouses) => {
 *       return filterHouses(projectId, houseLayerId, houses, house => house.house_id);
 *   });
 * ```
 */
export function makeGetEditedFeaturesGetter<T>() {
  return createSelector(
    makeGetLayerEditFeaturesGetter<Geometry, T>(),
    makeGetLayerEditDeletedFeatureIdsGetter(),
    (getEditedFeatures, getDeletedFeatureIds) =>
      (
        projectId: ProjectId,
        layerId: LayerId,
        entities: T[],
        getFeatureId: (entity: T) => string | number,
      ) => {
        const editedFeatures = getEditedFeatures(projectId, layerId);
        const deletedFeatureIds = getDeletedFeatureIds(projectId, layerId);

        return filterEntitiesFromEdits<T>(
          entities,
          getFeatureId,
          editedFeatures,
          deletedFeatureIds,
        );
      },
  );
}

/**
 * A selector which simply caches the conversion from filterSpec => Expression[]
 */
function makeGetExpressionFromFilterSpec() {
  return createSelector(
    makeGetFromPropsSelector<Partial<FilterSpec>, 'filters'>('filters'),
    filters => convertFiltersToExpressions(filters) ?? EMPTY_ARRAY,
  );
}

/**
 * A selector which caches the conversion from Expression[] => executor
 */
function makeGetExpressionExecutor<G extends Geometry>() {
  return createSelector(makeGetExpressionFromFilterSpec(), expressions =>
    makeExpressionsExecutor<G>(expressions),
  );
}

/**
 * A selector which applies the expressions to the edits on the specified layer,
 * returning the GeoJSON features.
 */
export function makeGetEditsFilteredFeatures<
  G extends Geometry,
  P extends Record<string, any> = Record<string, any>,
>() {
  return createSelector(
    makeGetLayerEditFeatures<G, P>(),
    makeGetExpressionExecutor(),
    (features, expressionExecutor): Feature<G, P>[] => {
      return cacheableConcat<Feature<G, P>>(
        features.filter(feature => expressionExecutor(feature)),
      );
    },
  );
}

/**
 * A selector which applies the expressions to the edits on the specified layer,
 * returning an array of objects, one for each feature, in the form `{ property: value, ... }`.
 */
export function makeGetEditsLayerData<T = Record<string, any>>() {
  return createSelector(
    makeGetEditsFilteredFeatures<Geometry, T>(),
    (features): T[] =>
      cacheableConcat(
        features.map((feature: Feature<Geometry, T>) => feature.properties),
      ),
  );
}

export function makeGetEditsLayerStats() {
  return createSelector(
    makeGetLayerMetadata(),
    makeGetEditsLayerData(),
    // needed to satisfy createSelector
    makeGetFromPropsSelector<ProjectId, 'projectId'>('projectId'),
    (layerMetadata, data) => {
      return generateStats(layerMetadata, data);
    },
  );
}
