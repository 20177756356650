import {
  CuratedColumnSymbology,
  InterpolationTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/public/dataset/washington_dc_zoning_2016_washington_dc': {
    zone_distr: [
      {
        display_hints: {
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          scale: SymbologyScales.CATEGORICAL,
          theme: 'custom',
        },
        paint: {
          'fill-color': {
            default: '#17becf',
            property: 'zone_distr',
            stops: [
              {
                color: '#d33115',
                value: 'Downtown Zone',
              },
              {
                color: '#f44e3b',
                value: 'Mixed-Use Zone',
              },
              {
                color: '#f44e3b',
                value: 'Neighborhood Mixed-Use Zone',
              },
              {
                color: '#aea1ff',
                value: 'Production, Distribution, and Repair Zone',
              },
              {
                color: '#e27300',
                value: 'Residential Apartment Zone',
              },
              {
                color: '#fe9200',
                value: 'Residential Flat Zone',
              },
              {
                color: '#fcdc00',
                value: 'Residential Zone',
              },
              {
                color: '#7f7f7f',
                value: 'Special Purpose Zone',
              },
              {
                color: '#cccccc',
                value: 'Unzoned',
              },
            ],
            type: 'categorical',
          },
          'fill-opacity': 0.9,
        },
        type: SymbologyTypes.FILL,
      },
      {
        display_hints: {
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          scale: SymbologyScales.CATEGORICAL,
          theme: 'custom',
        },
        paint: {
          'line-color': '#999999',
          'line-opacity': 0.9,
          'line-width': 0.5,
        },
        type: SymbologyTypes.LINE,
      },
    ],
  },
};

export default symbologies;
