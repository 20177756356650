import classNames from 'classnames';
import React, { FunctionComponent } from 'react';
import { hot } from 'react-hot-loader/root';
import { Box } from 'react-layout-components';

import rootStyles from 'uf/styles/root.module.css';
import Footer from 'uf/ui/main/Footer/Footer';
import ModeBar from 'uf/ui/main/ModeBar/ModeBar';
import ExpiredSubscriptionDialog from 'uf/ui/subscriptions/ExpiredSubscription/ExpiredSubscriptionDialog';
import useUserFlag from 'uf/ui/user/useUserFlag';

interface Props {
  header: React.Component;
  body: React.Component;
}

// Children of the router cannot be functions or hot reloading breaks.
export const MainPage: FunctionComponent<Props> = props => {
  const debugFlexbox = useUserFlag('dev-show-flexbox');
  const debugClassName = classNames({
    'flexbox-debug-root': debugFlexbox,
  });
  return (
    <div id="main" className={debugClassName}>
      <Box column id="body">
        <Box>{props.header}</Box>
        <Box flex={1} className={rootStyles.overflowHidden}>
          <ModeBar />
          <Box
            flex={1}
            className={rootStyles.overflowHidden}
            style={{
              minHeight: '0px',
            }}>
            {props.body}
          </Box>
        </Box>
      </Box>
      <ExpiredSubscriptionDialog />
      <Footer id="footer" />
    </div>
  );
};

export default hot(MainPage);
