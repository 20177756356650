import { combineReducers } from 'redux';

import { enqueueLayerExportTaskActionTypes } from 'uf-api/api/layer.service';
import {
  BIND_EXPORT_LAYER_TASK,
  exportLayerTaskActionTypes,
} from 'uf/layers/ActionTypes';
import { LayersState } from 'uf/layers/state';
import { makeCombinedLegacyTaskReducer } from 'uf/tasks/reducerHelpers';

import versions from './versions';
import zoomExtentSlice from './zoomExtentSlice';

export default combineReducers<LayersState>({
  export: makeCombinedLegacyTaskReducer(
    enqueueLayerExportTaskActionTypes,
    BIND_EXPORT_LAYER_TASK,
    exportLayerTaskActionTypes,
  ),
  versions,
  zoomExtent: zoomExtentSlice,
});
