import colors from 'material-colors';
import { t } from 'ttag';

import { ACRES, ACRES_FEET, getUnitName } from 'uf/base/units';
import { LegacyChart, LegacyReport } from 'uf/reporting/index';

import { defaultEmptyChartContent } from './emptyChartMessage';

const IN_SENTENCE = true;

const tncSource = {
  source: 'analysis',
  sourceKey: 'tnc_conservation',
} as const;

const NetAgUrbanWaterDemand = {
  ...tncSource,
  key: 'net_agurban_water_demand',
  layerKey: 'tnc_conservation_total_water_demand_net',
  columnKey: 'net_water_demand',
  color: colors.blue[600],
};

const TotalDegradedWaterCatchment = {
  ...tncSource,
  key: 'total_degraded_water_catchment',
  layerKey: 'tnc_conservation_water_catchment_total',
  columnKey: 'degraded_catchments_area',
  color: colors.lightGreen[300],
};

const TotalRiparianWaterCatchment = {
  ...tncSource,
  key: 'total_riparian_water_catchment',
  layerKey: 'tnc_conservation_water_catchment_total',
  columnKey: 'important_riparian_buffer_area',
  color: colors.green[500],
};

const TotalNaturalWaterCatchment = {
  ...tncSource,
  key: 'total_natural_water_catchment',
  layerKey: 'tnc_conservation_water_catchment_total',
  columnKey: 'natural_catchments_area',
  color: colors.green[900],
};

const GainsDegradedWaterCatchment = {
  ...tncSource,
  key: 'gains_degraded_water_catchment',
  layerKey: 'tnc_conservation_water_catchment_gains_total',
  columnKey: 'degraded_catchments_gains',
  color: colors.lightGreen[300],
};

const GainsRiparianWaterCatchment = {
  ...tncSource,
  key: 'gains_riparian_water_catchment',
  layerKey: 'tnc_conservation_water_catchment_gains_total',
  columnKey: 'important_riparian_buffer_gains',
  color: colors.green[500],
};

const GainsNaturalWaterCatchment = {
  ...tncSource,
  key: 'gains_natural_water_catchment',
  layerKey: 'tnc_conservation_water_catchment_gains_total',
  columnKey: 'natural_catchments_gains',
  color: colors.green[900],
};

const LossesDegradedWaterCatchment = {
  ...tncSource,
  key: 'losses_degraded_water_catchment',
  layerKey: 'tnc_conservation_water_catchment_loss_total',
  columnKey: 'degraded_catchments_losses',
  color: colors.lightGreen[300],
};

const LossesRiparianWaterCatchment = {
  ...tncSource,
  key: 'losses_riparian_water_catchment',
  layerKey: 'tnc_conservation_water_catchment_loss_total',
  columnKey: 'important_riparian_buffer_losses',
  color: colors.green[500],
};

const LossesNaturalWaterCatchment = {
  ...tncSource,
  key: 'losses_natural_water_catchment',
  layerKey: 'tnc_conservation_water_catchment_loss_total',
  columnKey: 'natural_catchments_losses',
  color: colors.green[900],
};

const NetFloodPlainLayerAgricultureSeries = {
  ...tncSource,
  key: 'tnc_conservation_water_flood_plain_net_layer_agriculture',
  layerKey: 'tnc_conservation_water_flood_plain_net',
  columnKey: 'flood_plain_layer_agriculture',
  color: colors.yellow[600],
};

const NetFloodPlainLayerNaturalSeries = {
  ...tncSource,
  key: 'tnc_conservation_water_flood_plain_net_layer_natural',
  layerKey: 'tnc_conservation_water_flood_plain_net',
  columnKey: 'flood_plain_layer_natural',
  color: colors.green[500],
};

const NetFloodPlainLayerUrbanSeries = {
  ...tncSource,
  key: 'tnc_conservation_water_flood_plain_net_layer_urban',
  layerKey: 'tnc_conservation_water_flood_plain_net',
  columnKey: 'flood_plain_layer_urban',
  color: colors.orange[600],
};

const NetWetlandsAgricultureSeries = {
  ...tncSource,
  key: 'tnc_conservation_water_wetlands_net_agriculture',
  layerKey: 'tnc_conservation_water_wetlands_net',
  columnKey: 'wetlands_agriculture',
  color: colors.yellow[600],
};

const NetWetlandsNaturalSeries = {
  ...tncSource,
  key: 'tnc_conservation_water_wetlands_net_natural',
  layerKey: 'tnc_conservation_water_wetlands_net',
  columnKey: 'wetlands_natural',
  color: colors.green[500],
};

const NetWetlandsUrbanSeries = {
  ...tncSource,
  key: 'tnc_conservation_water_wetlands_net_urban',
  layerKey: 'tnc_conservation_water_wetlands_net',
  columnKey: 'wetlands_urban',
  color: colors.orange[600],
};

const NetDwswAgricultureSeries = {
  ...tncSource,
  key: 'tnc_conservation_water_dwsw_net_agriculture',
  layerKey: 'tnc_conservation_water_dwsw_net',
  columnKey: 'dwsw_agriculture',
  color: colors.yellow[600],
};

const NetDwswNaturalSeries = {
  ...tncSource,
  key: 'tnc_conservation_water_dwsw_net_natural',
  layerKey: 'tnc_conservation_water_dwsw_net',
  columnKey: 'dwsw_natural',
  color: colors.green[500],
};

const NetDwswUrbanSeries = {
  ...tncSource,
  key: 'tnc_conservation_water_dwsw_net_urban',
  layerKey: 'tnc_conservation_water_dwsw_net',
  columnKey: 'dwsw_urban',
  color: colors.orange[600],
};

const NetAraAgricultureSeries = {
  ...tncSource,
  key: 'tnc_conservation_water_ara_net_agriculture',
  layerKey: 'tnc_conservation_water_ara_net',
  columnKey: 'ara_agriculture',
  color: colors.yellow[600],
};

const NetAraNaturalSeries = {
  ...tncSource,
  key: 'tnc_conservation_water_ara_net_natural',
  layerKey: 'tnc_conservation_water_ara_net',
  columnKey: 'ara_natural',
  color: colors.green[500],
};

const NetAraUrbanSeries = {
  ...tncSource,
  key: 'tnc_conservation_water_ara_net_urban',
  layerKey: 'tnc_conservation_water_ara_net',
  columnKey: 'ara_urban',
  color: colors.orange[600],
};

const NetDegradedCatchmentsAreaSeries = {
  ...tncSource,
  key: 'tnc_conservation_water_catchment_net_degraded_catchments_area',
  layerKey: 'tnc_conservation_water_catchment_net',
  columnKey: 'degraded_catchments_area',
  color: colors.lightGreen[300],
};

const NetImportantRiparianBufferAreaSeries = {
  ...tncSource,
  key: 'tnc_conservation_water_catchment_net_important_riparian_buffer_area',
  layerKey: 'tnc_conservation_water_catchment_net',
  columnKey: 'important_riparian_buffer_area',
  color: colors.green[500],
};

const NetNaturalCatchmentsAreaSeries = {
  ...tncSource,
  key: 'tnc_conservation_water_catchment_net_natural_catchments_area',
  layerKey: 'tnc_conservation_water_catchment_net',
  columnKey: 'natural_catchments_area',
  color: colors.green[900],
};

const NetUrbanWaterDemandSeries = {
  ...tncSource,
  key: 'tnc_conservation_water_urban_demand_net_demand',
  layerKey: 'tnc_conservation_water_urban_demand_net',
  columnKey: 'urban_water_demand',
  color: colors.blue[500],
};

const NetGwRechargeImpactedSeries = {
  ...tncSource,
  key: 'tnc_conservation_water_grpi_netgw_recharge_impacted',
  layerKey: 'tnc_conservation_water_grpi_net',
  columnKey: 'gw_recharge_impacted',
  color: colors.cyan[700],
};

const WaterAgricultureTotalDemandWaterCroplandSeries = {
  ...tncSource,
  key: 'tnc_conservation_water_agro_demand_totalwater_cropland',
  layerKey: 'tnc_conservation_water_agro_demand_total',
  columnKey: 'water_cropland',
  color: colors.lime[200],
};

const WaterAgricultureTotalDemandWaterDeciduousOrchardSeries = {
  ...tncSource,
  key: 'tnc_conservation_water_agro_demand_totalwater_deciduous_orchard',
  layerKey: 'tnc_conservation_water_agro_demand_total',
  columnKey: 'water_deciduous_orchard',
  color: colors.green[900],
};

const WaterAgricultureTotalDemandWaterDrylandGrainCropsSeries = {
  ...tncSource,
  key: 'tnc_conservation_water_agro_demand_totalwater_dryland_grain_crops',
  layerKey: 'tnc_conservation_water_agro_demand_total',
  columnKey: 'water_dryland_grain_crops',
  color: colors.lime[600],
};

const WaterAgricultureTotalDemandWaterEvergreenOrchardSeries = {
  ...tncSource,
  key: 'tnc_conservation_water_agro_demand_totalwater_evergreen_orchard',
  layerKey: 'tnc_conservation_water_agro_demand_total',
  columnKey: 'water_evergreen_orchard',
  color: colors.green[600],
};

const WaterAgricultureTotalDemandWaterIrrigatedGrainCropsSeries = {
  ...tncSource,
  key: 'tnc_conservation_water_agro_demand_totalwater_irrigated_grain_crops',
  layerKey: 'tnc_conservation_water_agro_demand_total',
  columnKey: 'water_irrigated_grain_crops',
  color: colors.lime[900],
};

const WaterAgricultureTotalDemandWaterIrrigatedHayfieldSeries = {
  ...tncSource,
  key: 'tnc_conservation_water_agro_demand_totalwater_irrigated_hayfield',
  layerKey: 'tnc_conservation_water_agro_demand_total',
  columnKey: 'water_irrigated_hayfield',
  color: colors.lime[400],
};

const WaterAgricultureTotalDemandWaterIrrigatedRowAndFieldCropsSeries = {
  ...tncSource,
  key: 'tnc_conservation_water_agro_demand_totalwater_irrigated_row_and_field_crops',
  layerKey: 'tnc_conservation_water_agro_demand_total',
  columnKey: 'water_irrigated_row_and_field_crops',
  color: colors.lime[800],
};

const WaterAgricultureTotalDemandWaterOrchardVineyardSeries = {
  ...tncSource,
  key: 'tnc_conservation_water_agro_demand_totalwater_orchard_vineyard',
  layerKey: 'tnc_conservation_water_agro_demand_total',
  columnKey: 'water_orchard_vineyard',
  color: colors.lightGreen[800],
};

const WaterAgricultureTotalDemandWaterRiceSeries = {
  ...tncSource,
  key: 'tnc_conservation_water_agro_demand_totalwater_rice',
  layerKey: 'tnc_conservation_water_agro_demand_total',
  columnKey: 'water_rice',
  color: colors.grey[300],
};

const WaterAgricultureTotalDemandWaterVineyardSeries = {
  ...tncSource,
  key: 'tnc_conservation_water_agro_demand_totalwater_vineyard',
  layerKey: 'tnc_conservation_water_agro_demand_total',
  columnKey: 'water_vineyard',
  color: colors.purple[700],
};

const WaterAgricultureNetDemandWaterCroplandSeries = {
  ...tncSource,
  key: 'tnc_conservation_water_agro_demand_netwater_cropland',
  layerKey: 'tnc_conservation_water_agro_demand_net',
  columnKey: 'water_cropland',
  color: colors.lime[200],
};

const WaterAgricultureNetDemandWaterDeciduousOrchardSeries = {
  ...tncSource,
  key: 'tnc_conservation_water_agro_demand_netwater_deciduous_orchard',
  layerKey: 'tnc_conservation_water_agro_demand_net',
  columnKey: 'water_deciduous_orchard',
  color: colors.green[900],
};

const WaterAgricultureNetDemandWaterDrylandGrainCropsSeries = {
  ...tncSource,
  key: 'tnc_conservation_water_agro_demand_netwater_dryland_grain_crops',
  layerKey: 'tnc_conservation_water_agro_demand_net',
  columnKey: 'water_dryland_grain_crops',
  color: colors.lime[600],
};

const WaterAgricultureNetDemandWaterEvergreenOrchardSeries = {
  ...tncSource,
  key: 'tnc_conservation_water_agro_demand_netwater_evergreen_orchard',
  layerKey: 'tnc_conservation_water_agro_demand_net',
  columnKey: 'water_evergreen_orchard',
  color: colors.green[600],
};

const WaterAgricultureNetDemandWaterIrrigatedGrainCropsSeries = {
  ...tncSource,
  key: 'tnc_conservation_water_agro_demand_netwater_irrigated_grain_crops',
  layerKey: 'tnc_conservation_water_agro_demand_net',
  columnKey: 'water_irrigated_grain_crops',
  color: colors.lime[900],
};

const WaterAgricultureNetDemandWaterIrrigatedHayfieldSeries = {
  ...tncSource,
  key: 'tnc_conservation_water_agro_demand_netwater_irrigated_hayfield',
  layerKey: 'tnc_conservation_water_agro_demand_net',
  columnKey: 'water_irrigated_hayfield',
  color: colors.lime[400],
};

const WaterAgricultureNetDemandWaterIrrigatedRowAndFieldCropsSeries = {
  ...tncSource,
  key: 'tnc_conservation_water_agro_demand_netwater_irrigated_row_and_field_crops',
  layerKey: 'tnc_conservation_water_agro_demand_net',
  columnKey: 'water_irrigated_row_and_field_crops',
  color: colors.lime[800],
};

const WaterAgricultureNetDemandWaterOrchardVineyardSeries = {
  ...tncSource,
  key: 'tnc_conservation_water_agro_demand_netwater_orchard_vineyard',
  layerKey: 'tnc_conservation_water_agro_demand_net',
  columnKey: 'water_orchard_vineyard',
  color: colors.lightGreen[800],
};

const WaterAgricultureNetDemandWaterRiceSeries = {
  ...tncSource,
  key: 'tnc_conservation_water_agro_demand_netwater_rice',
  layerKey: 'tnc_conservation_water_agro_demand_net',
  columnKey: 'water_rice',
  color: colors.grey[300],
};

const WaterAgricultureNetDemandWaterVineyardSeries = {
  ...tncSource,
  key: 'tnc_conservation_water_agro_demand_netwater_vineyard',
  layerKey: 'tnc_conservation_water_agro_demand_net',
  columnKey: 'water_vineyard',
  color: colors.purple[700],
};

const WaterAgricultureTotalDemandWaterCroplandGainsSeries = {
  ...tncSource,
  key: 'tnc_conservation_agro_water_gains_totalwater_cropland',
  layerKey: 'tnc_conservation_agro_water_gains_total',
  columnKey: 'water_cropland_gains',
  color: colors.lime[200],
};

const WaterAgricultureTotalDemandWaterDeciduousOrchardGainsSeries = {
  ...tncSource,
  key: 'tnc_conservation_agro_water_gains_totalwater_deciduous_orchard',
  layerKey: 'tnc_conservation_agro_water_gains_total',
  columnKey: 'water_deciduous_orchard_gains',
  color: colors.green[900],
};

const WaterAgricultureTotalDemandWaterDrylandGrainCropsGainsSeries = {
  ...tncSource,
  key: 'tnc_conservation_agro_water_gains_totalwater_dryland_grain_crops',
  layerKey: 'tnc_conservation_agro_water_gains_total',
  columnKey: 'water_dryland_grain_crops_gains',
  color: colors.lime[600],
};

const WaterAgricultureTotalDemandWaterEvergreenOrchardGainsSeries = {
  ...tncSource,
  key: 'tnc_conservation_agro_water_gains_totalwater_evergreen_orchard',
  layerKey: 'tnc_conservation_agro_water_gains_total',
  columnKey: 'water_evergreen_orchard_gains',
  color: colors.green[600],
};

const WaterAgricultureTotalDemandWaterIrrigatedGrainCropsGainsSeries = {
  ...tncSource,
  key: 'tnc_conservation_agro_water_gains_totalwater_irrigated_grain_crops',
  layerKey: 'tnc_conservation_agro_water_gains_total',
  columnKey: 'water_irrigated_grain_crops_gains',
  color: colors.lime[900],
};

const WaterAgricultureTotalDemandWaterIrrigatedHayfieldGainsSeries = {
  ...tncSource,
  key: 'tnc_conservation_agro_water_gains_totalwater_irrigated_hayfield',
  layerKey: 'tnc_conservation_agro_water_gains_total',
  columnKey: 'water_irrigated_hayfield_gains',
  color: colors.lime[400],
};

const WaterAgricultureTotalDemandWaterIrrigatedRowAndFieldCropsGainsSeries = {
  ...tncSource,
  key: 'tnc_conservation_agro_water_gains_totalwater_irrigated_row_and_field_crops',
  layerKey: 'tnc_conservation_agro_water_gains_total',
  columnKey: 'water_irrigated_row_and_field_crops_gains',
  color: colors.lime[800],
};

const WaterAgricultureTotalDemandWaterOrchardVineyardGainsSeries = {
  ...tncSource,
  key: 'tnc_conservation_agro_water_gains_totalwater_orchard_vineyard',
  layerKey: 'tnc_conservation_agro_water_gains_total',
  columnKey: 'water_orchard_vineyard_gains',
  color: colors.lightGreen[800],
};

const WaterAgricultureTotalDemandWaterRiceGainsSeries = {
  ...tncSource,
  key: 'tnc_conservation_agro_water_gains_totalwater_rice',
  layerKey: 'tnc_conservation_agro_water_gains_total',
  columnKey: 'water_rice_gains',
  color: colors.grey[300],
};

const WaterAgricultureTotalDemandWaterVineyardGainsSeries = {
  ...tncSource,
  key: 'tnc_conservation_agro_water_gains_totalwater_vineyard',
  layerKey: 'tnc_conservation_agro_water_gains_total',
  columnKey: 'water_vineyard_gains',
  color: colors.purple[700],
};

const WaterAgricultureTotalDemandWaterCroplandLossesSeries = {
  ...tncSource,
  key: 'tnc_conservation_agro_water_loss_totalwater_cropland',
  layerKey: 'tnc_conservation_agro_water_loss_total',
  columnKey: 'water_cropland_losses',
  color: colors.lime[200],
};

const WaterAgricultureTotalDemandWaterDeciduousOrchardLossesSeries = {
  ...tncSource,
  key: 'tnc_conservation_agro_water_loss_totalwater_deciduous_orchard',
  layerKey: 'tnc_conservation_agro_water_loss_total',
  columnKey: 'water_deciduous_orchard_losses',
  color: colors.green[900],
};

const WaterAgricultureTotalDemandWaterDrylandGrainCropsLossesSeries = {
  ...tncSource,
  key: 'tnc_conservation_agro_water_loss_totalwater_dryland_grain_crops',
  layerKey: 'tnc_conservation_agro_water_loss_total',
  columnKey: 'water_dryland_grain_crops_losses',
  color: colors.lime[600],
};

const WaterAgricultureTotalDemandWaterEvergreenOrchardLossesSeries = {
  ...tncSource,
  key: 'tnc_conservation_agro_water_loss_totalwater_evergreen_orchard',
  layerKey: 'tnc_conservation_agro_water_loss_total',
  columnKey: 'water_evergreen_orchard_losses',
  color: colors.green[600],
};

const WaterAgricultureTotalDemandWaterIrrigatedGrainCropsLossesSeries = {
  ...tncSource,
  key: 'tnc_conservation_agro_water_loss_totalwater_irrigated_grain_crops',
  layerKey: 'tnc_conservation_agro_water_loss_total',
  columnKey: 'water_irrigated_grain_crops_losses',
  color: colors.lime[900],
};

const WaterAgricultureTotalDemandWaterIrrigatedHayfieldLossesSeries = {
  ...tncSource,
  key: 'tnc_conservation_agro_water_loss_totalwater_irrigated_hayfield',
  layerKey: 'tnc_conservation_agro_water_loss_total',
  columnKey: 'water_irrigated_hayfield_losses',
  color: colors.lime[400],
};

const WaterAgricultureTotalDemandWaterIrrigatedRowAndFieldCropsLossesSeries = {
  ...tncSource,
  key: 'tnc_conservation_agro_water_loss_totalwater_irrigated_row_and_field_crops',
  layerKey: 'tnc_conservation_agro_water_loss_total',
  columnKey: 'water_irrigated_row_and_field_crops_losses',
  color: colors.lime[800],
};

const WaterAgricultureTotalDemandWaterOrchardVineyardLossesSeries = {
  ...tncSource,
  key: 'tnc_conservation_agro_water_loss_totalwater_orchard_vineyard',
  layerKey: 'tnc_conservation_agro_water_loss_total',
  columnKey: 'water_orchard_vineyard_losses',
  color: colors.lightGreen[800],
};

const WaterAgricultureTotalDemandWaterRiceLossesSeries = {
  ...tncSource,
  key: 'tnc_conservation_agro_water_loss_totalwater_rice',
  layerKey: 'tnc_conservation_agro_water_loss_total',
  columnKey: 'water_rice_losses',
  color: colors.grey[300],
};

const WaterAgricultureTotalDemandWaterVineyardLossesSeries = {
  ...tncSource,
  key: 'tnc_conservation_agro_water_loss_totalwater_vineyard',
  layerKey: 'tnc_conservation_agro_water_loss_total',
  columnKey: 'water_vineyard_losses',
  color: colors.purple[700],
};

export const tncChartsSharedWater: LegacyChart[] = [
  {
    name: t`Net Change in Land Cover: Floodplains`,
    key: 'net_flood_plain',
    description: t`Net Change in Land Use within Floodplains (Developed/ Natural/ Ag)`,
    series: [
      NetFloodPlainLayerUrbanSeries,
      NetFloodPlainLayerAgricultureSeries,
      NetFloodPlainLayerNaturalSeries,
    ],
    units: getUnitName(ACRES),
    emptyChartContent: defaultEmptyChartContent,
    showLegend: true,
    showFullLabel: true,
  },
  {
    name: t`Net Change in Land Cover: Wetlands`,
    key: 'net_wetlands',
    description: t`Net Change in Land Use within Wetlands (Developed/ Natural/ Ag)`,
    series: [
      NetWetlandsUrbanSeries,
      NetWetlandsAgricultureSeries,
      NetWetlandsNaturalSeries,
    ],
    units: getUnitName(ACRES),
    emptyChartContent: defaultEmptyChartContent,
    showLegend: true,
    showFullLabel: true,
  },
  {
    name: t`Net Change in Land Cover: Drinking Water Source Watersheds`,
    key: 'net_dwsw',
    description: t`Net Change in Land Use within Drinking
  Water Source Watersheds (Developed/ Natural/ Ag)`,
    series: [
      NetDwswUrbanSeries,
      NetDwswAgricultureSeries,
      NetDwswNaturalSeries,
    ],
    units: getUnitName(ACRES),
    emptyChartContent: defaultEmptyChartContent,
    showLegend: true,
    showFullLabel: true,
  },
  {
    name: t`Net Change in Land Cover: Active River Area`,
    key: 'net_ara',
    description: t`Net Change in Land Use within Active River Area (Developed/ Natural/ Ag)`,
    series: [NetAraUrbanSeries, NetAraAgricultureSeries, NetAraNaturalSeries],
    units: getUnitName(ACRES),
    emptyChartContent: defaultEmptyChartContent,
    showLegend: true,
    showFullLabel: true,
  },
  {
    name: t`Net Change in Groundwater Recharge Potential`,
    key: 'net_groundwater_recharge_potential',
    description: t`Net Change in Groundwater Recharge Potential`,
    series: [NetGwRechargeImpactedSeries],
    units: getUnitName(ACRES_FEET),
    emptyChartContent: defaultEmptyChartContent,
    showLegend: true,
    showFullLabel: true,
  },
  {
    name: t`Net Change in Urban Water Demand`,
    key: 'net_urban_water_demand',
    description: t`Net Change in Urban Water Demand`,
    series: [NetUrbanWaterDemandSeries],
    units: getUnitName(ACRES_FEET),
    emptyChartContent: defaultEmptyChartContent,
    showLegend: true,
    showFullLabel: true,
  },
  {
    name: t`Net Change in Total Water Demand`,
    key: 'total_water_demand',
    description: t`Net Change in Total Water Demand (Urban + Agricultural)`,
    series: [NetAgUrbanWaterDemand],
    units: getUnitName(ACRES_FEET),
    emptyChartContent: defaultEmptyChartContent,
    showLegend: true,
    showFullLabel: true,
  },
  {
    name: t`Net Change in Watershed Integrity Class`,
    key: 'net_acreage_water_catchment',
    description: t`Change in Acreage by Watershed Integrity Class (Natural / Riparian/ Degraded)`,
    series: [
      NetNaturalCatchmentsAreaSeries,
      NetImportantRiparianBufferAreaSeries,
      NetDegradedCatchmentsAreaSeries,
    ],
    units: getUnitName(ACRES),
    emptyChartContent: defaultEmptyChartContent,
    showLegend: true,
    showFullLabel: true,
  },
  {
    name: t`Total Watershed Integrity Class Area by Type`,
    key: 'total_acreage_water_catchment',
    description: t`Total acreage of Watershed Integrity Class Types`,
    series: [
      TotalNaturalWaterCatchment,
      TotalRiparianWaterCatchment,
      TotalDegradedWaterCatchment,
    ],
    units: getUnitName(ACRES),
    emptyChartContent: defaultEmptyChartContent,
    showLegend: true,
    showFullLabel: true,
  },
  {
    name: t`Increase in Watershed Integrity Class Area by Type`,
    key: 'gains_acreage_water_catchment',
    description: t`Increase in Watershed Integrity Class Types`,
    subtitle: t`Change`,
    units: getUnitName(ACRES, IN_SENTENCE),
    series: [
      GainsNaturalWaterCatchment,
      GainsRiparianWaterCatchment,
      GainsDegradedWaterCatchment,
    ],
    emptyChartContent: defaultEmptyChartContent,
    showLegend: true,
    showFullLabel: true,
  },
  {
    name: t`Decrease in Watershed Integrity Class Area by Type`,
    key: 'losses_acreage_water_catchment',
    description: t`Decrease in Watershed Integrity Class Types`,
    subtitle: t`Change`,
    series: [
      LossesNaturalWaterCatchment,
      LossesRiparianWaterCatchment,
      LossesDegradedWaterCatchment,
    ],
    units: getUnitName(ACRES, IN_SENTENCE),
    emptyChartContent: defaultEmptyChartContent,
    showLegend: true,
    showFullLabel: true,
  },
  {
    name: t`Total Agricultural Water Demand`,
    key: 'total_agricultural_water_demand',
    description: t`Total Agricultural Water Demand`,
    units: getUnitName(ACRES_FEET),
    series: [
      WaterAgricultureTotalDemandWaterCroplandSeries,
      WaterAgricultureTotalDemandWaterDrylandGrainCropsSeries,
      WaterAgricultureTotalDemandWaterIrrigatedGrainCropsSeries,
      WaterAgricultureTotalDemandWaterIrrigatedHayfieldSeries,
      WaterAgricultureTotalDemandWaterIrrigatedRowAndFieldCropsSeries,
      WaterAgricultureTotalDemandWaterRiceSeries,
      WaterAgricultureTotalDemandWaterDeciduousOrchardSeries,
      WaterAgricultureTotalDemandWaterEvergreenOrchardSeries,
      WaterAgricultureTotalDemandWaterOrchardVineyardSeries,
      WaterAgricultureTotalDemandWaterVineyardSeries,
    ],
    emptyChartContent: defaultEmptyChartContent,
    showLegend: true,
    showFullLabel: true,
  },
  {
    name: t`Net Change in Agricultural Water Demand`,
    key: 'net_agricultural_water_demand',
    units: getUnitName(ACRES_FEET),
    description: t`Net Change in Agricultural Water Demand`,
    series: [
      WaterAgricultureNetDemandWaterCroplandSeries,
      WaterAgricultureNetDemandWaterDrylandGrainCropsSeries,
      WaterAgricultureNetDemandWaterIrrigatedGrainCropsSeries,
      WaterAgricultureNetDemandWaterIrrigatedHayfieldSeries,
      WaterAgricultureNetDemandWaterIrrigatedRowAndFieldCropsSeries,
      WaterAgricultureNetDemandWaterRiceSeries,
      WaterAgricultureNetDemandWaterDeciduousOrchardSeries,
      WaterAgricultureNetDemandWaterEvergreenOrchardSeries,
      WaterAgricultureNetDemandWaterOrchardVineyardSeries,
      WaterAgricultureNetDemandWaterVineyardSeries,
    ],
    emptyChartContent: defaultEmptyChartContent,
    showLegend: true,
    showFullLabel: true,
  },
  {
    name: t`Increase in Agricultural Water Demand`,
    key: 'gains_agricultural_water_demand',
    subtitle: t`Change`,
    description: t`Increase in Agricultural Water Demand`,
    units: getUnitName(ACRES_FEET, IN_SENTENCE),
    series: [
      WaterAgricultureTotalDemandWaterCroplandGainsSeries,
      WaterAgricultureTotalDemandWaterDrylandGrainCropsGainsSeries,
      WaterAgricultureTotalDemandWaterIrrigatedGrainCropsGainsSeries,
      WaterAgricultureTotalDemandWaterIrrigatedHayfieldGainsSeries,
      WaterAgricultureTotalDemandWaterIrrigatedRowAndFieldCropsGainsSeries,
      WaterAgricultureTotalDemandWaterRiceGainsSeries,
      WaterAgricultureTotalDemandWaterDeciduousOrchardGainsSeries,
      WaterAgricultureTotalDemandWaterEvergreenOrchardGainsSeries,
      WaterAgricultureTotalDemandWaterOrchardVineyardGainsSeries,
      WaterAgricultureTotalDemandWaterVineyardGainsSeries,
    ],
    emptyChartContent: defaultEmptyChartContent,
    showLegend: true,
    showFullLabel: true,
  },
  {
    name: t`Decrease in Agricultural Water Demand`,
    key: 'losses_agricultural_water_demand',
    subtitle: t`Change`,
    description: t`Decrease in Agricultural Water Demand`,
    units: getUnitName(ACRES_FEET, IN_SENTENCE),
    series: [
      WaterAgricultureTotalDemandWaterCroplandLossesSeries,
      WaterAgricultureTotalDemandWaterDrylandGrainCropsLossesSeries,
      WaterAgricultureTotalDemandWaterIrrigatedGrainCropsLossesSeries,
      WaterAgricultureTotalDemandWaterIrrigatedHayfieldLossesSeries,
      WaterAgricultureTotalDemandWaterIrrigatedRowAndFieldCropsLossesSeries,
      WaterAgricultureTotalDemandWaterRiceLossesSeries,
      WaterAgricultureTotalDemandWaterDeciduousOrchardLossesSeries,
      WaterAgricultureTotalDemandWaterEvergreenOrchardLossesSeries,
      WaterAgricultureTotalDemandWaterOrchardVineyardLossesSeries,
      WaterAgricultureTotalDemandWaterVineyardLossesSeries,
    ],
    emptyChartContent: defaultEmptyChartContent,
    showLegend: true,
    showFullLabel: true,
  },
];

export const tncChartsWater: LegacyReport = Object.freeze({
  key: 'tnc_conservation_water',
  name: t`Conservation - Water`,
  colorSpec: {
    primaryColor: colors.lime[700],
    interpolateFromColor: 'rgb(255, 214, 79)',
    interpolateToColor: 'rgb(27, 94, 31)',
  },
  charts: [...tncChartsSharedWater],
});
