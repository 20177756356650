import { combineReducers } from 'redux';

import {
  createBuildingActionTypes,
  createBuildingTypeActionTypes,
  createPlaceTypeActionTypes,
  getBuildingActionTypes,
  getBuildingPreviewMultiActionTypes,
  getBuildingTypeActionTypes,
  getBuildingTypePreviewActionTypes,
  getLibraryActionTypes,
  getLibraryBuildingsActionTypes,
  getLibraryBuildingTypesActionTypes,
  getLibraryPlaceTypesActionTypes,
  getPlaceTypeActionTypes,
  getPlaceTypePreviewActionTypes,
  updateBuildingActionTypes,
  updateBuildingTypeActionTypes,
  updatePlaceTypeActionTypes,
} from 'uf-api/api/builtforms.service';
import { withClearReducer } from 'uf/base/reducerFactories';
import { BUILT_FORM_TYPES } from 'uf/builtforms';
import {
  clearBuiltFormItemsActionType,
  clearBuiltFormListsActionType,
} from 'uf/builtforms/ActionTypes';
import { BuiltFormsState } from 'uf/builtforms/state';
import { makeKeyedPromiseReducer } from 'uf/data/makeKeyedPromiseReducer';

import editor from './editor';

const makeBuiltFormListsReducer = actionType =>
  withClearReducer(
    clearBuiltFormListsActionType,
    makeKeyedPromiseReducer(actionType),
  );

const makeBuiltFormItemsReducer = actionType =>
  withClearReducer(
    clearBuiltFormItemsActionType,
    makeKeyedPromiseReducer(actionType),
  );

export default combineReducers<BuiltFormsState>({
  libraries: makeKeyedPromiseReducer(getLibraryActionTypes),
  builtform_lists: combineReducers({
    [BUILT_FORM_TYPES.BUILDING]: makeBuiltFormListsReducer(
      getLibraryBuildingsActionTypes,
    ),
    [BUILT_FORM_TYPES.BUILDING_TYPE]: makeBuiltFormListsReducer(
      getLibraryBuildingTypesActionTypes,
    ),
    [BUILT_FORM_TYPES.PLACE_TYPE]: makeBuiltFormListsReducer(
      getLibraryPlaceTypesActionTypes,
    ),
  }),
  builtform_items: combineReducers({
    [BUILT_FORM_TYPES.BUILDING]: makeBuiltFormItemsReducer(
      getBuildingActionTypes,
    ),
    [BUILT_FORM_TYPES.BUILDING_TYPE]: makeBuiltFormItemsReducer(
      getBuildingTypeActionTypes,
    ),
    [BUILT_FORM_TYPES.PLACE_TYPE]: makeBuiltFormItemsReducer(
      getPlaceTypeActionTypes,
    ),
  }),
  create_building: makeKeyedPromiseReducer(createBuildingActionTypes),
  update_building: makeKeyedPromiseReducer(updateBuildingActionTypes),
  preview_building: makeKeyedPromiseReducer(getBuildingPreviewMultiActionTypes),

  create_building_type: makeKeyedPromiseReducer(createBuildingTypeActionTypes),
  update_building_type: makeKeyedPromiseReducer(updateBuildingTypeActionTypes),
  preview_building_type: makeKeyedPromiseReducer(
    getBuildingTypePreviewActionTypes,
  ),

  create_place_type: makeKeyedPromiseReducer(createPlaceTypeActionTypes),
  update_place_type: makeKeyedPromiseReducer(updatePlaceTypeActionTypes),
  preview_place_type: makeKeyedPromiseReducer(getPlaceTypePreviewActionTypes),

  editor,
});
