import { Classes, Dialog } from '@blueprintjs/core';
import { boundMethod } from 'autobind-decorator';
import classNames from 'classnames';
import React, { Component, FunctionComponent } from 'react';
import { jt, t } from 'ttag';

import { Subscription } from 'uf-api';
import { getAPIUrl } from 'uf/base/api';
import { tw } from 'uf/tailwindcss-classnames';
import { DataState } from 'uf/data/dataState';
import rootStyles from 'uf/styles/root.module.css';
import { link } from 'uf/styles/text';
import { dialogClasses, Sizes } from 'uf/ui/base/sizes';
import { ConnectedWithAllSubscriptions } from 'uf/ui/organization/hocs/withSubscription';

interface Props {
  subscriptionStates: DataState<Subscription>[];
  orgKey: string;
}

interface State {
  show?: boolean;
  dismissable?: boolean;
}

export class InnerDialog extends Component<Props, State> {
  state: State = {
    show: false,
    dismissable: false,
  };

  componentDidMount() {
    this.updateState();
  }

  componentDidUpdate(prevProps) {
    const { subscriptionStates, orgKey } = this.props;
    if (
      prevProps.subscriptionStates !== subscriptionStates ||
      prevProps.orgKey !== orgKey
    ) {
      this.updateState();
    }
  }

  updateState() {
    const { subscriptionStates, orgKey } = this.props;

    const expiredSubscriptions = subscriptionStates
      // For now, assume no subscription means unlimited.
      .filter(
        subscription =>
          subscription.data?.state === Subscription.StateEnum.Expired,
      );

    // If the current org has an expired subscription, then we show the dialog.
    const show = expiredSubscriptions.some(expired => expired.key === orgKey);

    // And if all upgradable subscriptions are expired, then the user isn't allowed to do anything.
    const dismissable =
      subscriptionStates.length !== expiredSubscriptions.length;

    this.setState({ show, dismissable });
  }

  @boundMethod
  onHide() {
    const { dismissable } = this.state;
    if (dismissable) {
      this.setState({ show: false });
    }
  }

  render() {
    const { show, dismissable } = this.state;
    const contactLink = (
      <a
        className={link}
        href="https://urbanfootprint.com/about-us/contact-us/"
        target="_"
        key="linkUrbanFootprintContactUs">
        {t`contact us`}
      </a>
    );
    const signoutLink = (
      <a
        href={getAPIUrl('signout')}
        key="linkUrbanFootprintContactUs"
        className={link}>
        {t`Click here`}
      </a>
    );

    return (
      <Dialog
        isCloseButtonShown={dismissable}
        canEscapeKeyClose={dismissable}
        canOutsideClickClose={dismissable}
        transitionDuration={__TESTING__ ? 0 : undefined}
        className={dialogClasses[Sizes.MEDIUM]}
        portalClassName={tw('tw-z-30')}
        isOpen={show}
        onClose={this.onHide}
        title={t`We hope you enjoyed your UrbanFootprint trial!`}>
        <div className={Classes.DIALOG_BODY}>
          <p>{jt`Unlock the full power of UrbanFootprint for your planning
              needs. Upgrade to an affordable monthly or annual
              subscription. Please ${contactLink} to find the best
              UrbanFootprint subscription for you.`}</p>
          <p>{jt`Do you have another account? ${signoutLink} to switch accounts.`}</p>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <a
              className={classNames(
                rootStyles.thinMarginRight,
                rootStyles.defaultBorder,
                Classes.BUTTON,
              )}
              target="_blank"
              rel="noopener noreferrer"
              href="https://urbanfootprint.com/request-a-demo"
              type="submit">
              {t`Request a demo`}
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://urbanfootprint.com/pricing"
              className={classNames(Classes.INTENT_PRIMARY, Classes.BUTTON)}>
              {t`Upgrade now`}
            </a>
          </div>
        </div>
      </Dialog>
    );
  }
}

const ExpiredSubscriptionDialog: FunctionComponent = () => {
  return (
    <ConnectedWithAllSubscriptions>
      {(subscriptionStates, orgKey) => (
        <InnerDialog subscriptionStates={subscriptionStates} orgKey={orgKey} />
      )}
    </ConnectedWithAllSubscriptions>
  );
};
export default ExpiredSubscriptionDialog;
