import { Units } from '@turf/helpers';
import { t } from 'ttag';
import warning from 'warning';

import { AbbreviatableItem } from 'uf/base/formatting';

export const FEET_PER_METER = 3.2808;
export const FEET_PER_MILE = 5280;
export const YARDS_PER_FOOT = 1 / 3;
export const SQUARE_YARDS_PER_ACRE = 4840;
export const SQUARE_FEET_PER_ACRE = 43560;
export const SQUARE_FEET_PER_HECTARE = 107639;
export const MILES_PER_METER = FEET_PER_METER / FEET_PER_MILE;
export const METERS_PER_FOOT = 1 / FEET_PER_METER;
export const METERS_PER_MILE = 1 / MILES_PER_METER;
export const METERS_PER_KILOMETER = 1000;
export const KILOMETERS_PER_METER = 1 / METERS_PER_KILOMETER;
export const KILOMETERS_PER_FOOT = KILOMETERS_PER_METER * METERS_PER_FOOT;
export const KILOMETERS_PER_MILE = KILOMETERS_PER_METER * METERS_PER_MILE;

export const METERS_PER_KM = 1000;

export const metersPerUnit = {
  feet: METERS_PER_FOOT,
  miles: METERS_PER_MILE,
};

export const INCHES_PER_FOOT = 12;

export const ACRES = 'acres';
export const ACRES_FEET = 'acres_feet';
export const BTU = 'british_thermal_unit';
export const DOLLARS = 'dollars';
export const GALLONS = 'gallons';
export const KWH = 'kilowatt_hour';
export const MILES_TRAVELED = 'miles_traveled';
export const MT = 'metric_ton';
export const MTCO2E = 'metric_ton_equivalent_co2';
export const PASSENGER_VEHICLES = 'passenger_vehicles';
export const PASSENGER_VEHICLES_PER_YEAR = 'passenger vehicles / yr';
export const SQ_FT = 'square_feet';
export const THM = 'therm';

/**
 * Created as a separate type as we may expand it later
 */
export interface UnitInfo extends AbbreviatableItem {
  /**
   * Use this when writing a sentence case string, eg: "Developable area (acres)"
   */
  inSentenceName?: string;

  /**
   * Use this when writing a sentence case string but want the singular unit
   * label, eg: "People per acre"
   */
  inSentenceSingular?: string;
}
export type UnitLabelKeys =
  | '$'
  | '(dwelling units + jobs) / acre'
  | 'British thermal units / household / year'
  | 'British thermal units / person / year'
  | 'British thermal units / year'
  | 'Dollars / household / year'
  | 'Dollars / year'
  | 'Kilowatt hour / year'
  | 'Kilowatt hours / year'
  | 'MW'
  | 'Therm / year'
  | 'Therms / year'
  | 'ac - ft'
  | 'acre'
  | 'acre-ft / year'
  | 'acres'
  | 'acres_feet'
  | 'bmi'
  | 'british_thermal_unit'
  | 'canvas features'
  | 'centimeters'
  | 'cubic_feet'
  | 'day / year'
  | 'day'
  | 'degrees'
  | 'dollar'
  | 'dollar/square_meter'
  | 'dollars / 1000 square feet'
  | 'dollars / acre'
  | 'dollars / acre-foot'
  | 'dollars / dwelling unit'
  | 'dollars / foot'
  | 'dollars / gallon'
  | 'dollars / kilowatt-hour'
  | 'dollars / kilowatt_hour'
  | 'dollars / lane * mile'
  | 'dollars / mile'
  | 'dollars / square foot'
  | 'dollars / therm'
  | 'dollars / year'
  | 'dollars'
  | 'dollars/kilowatt*hour'
  | 'dwelling units / acre'
  | 'dwelling units'
  | 'dwelling_unit / acre'
  | 'dwelling_unit'
  | 'dwelling_units'
  | 'dwelling_units_occupied'
  | 'employees'
  | 'feet'
  | 'foot'
  | 'ft'
  | 'gC / m^2'
  | 'gallons / day'
  | 'gallons / employee / year'
  | 'gallons / household / year'
  | 'gallons / person / day'
  | 'gallons / person / year'
  | 'gallons / year'
  | 'gallons'
  | 'gallons/dayay'
  | 'gigawatt * hour / year'
  | 'gigawatt-hours'
  | 'grams / mile'
  | 'hectare'
  | 'hectares'
  | 'hh'
  | 'hour'
  | 'household'
  | 'households'
  | 'inch/year'
  | 'inches / year'
  | 'inches'
  | 'intersection/square_mile'
  | 'intersections / square_mile'
  | 'jobs / acre'
  | 'jobs'
  | 'kilometer / square_kilometer'
  | 'kilometer'
  | 'kilometers'
  | 'kilowatt * hour / year / household'
  | 'kilowatt * hour / year / square_foot'
  | 'kilowatt-hour / million gallons'
  | 'kilowatt-hours / household / year'
  | 'kilowatt-hours / square foot / year'
  | 'kilowatt_hour / million gallons'
  | 'kilowatt_hour'
  | 'lb / billion british thermal units'
  | 'lb/gigawatt*hour'
  | 'lb/megawatt*hour'
  | 'lb/megawatt*hour/year'
  | 'lb/million british thermal units'
  | 'lbs / year'
  | 'megawatt * hour / year'
  | 'megawatt * hour'
  | 'megawatt'
  | 'meter'
  | 'meters'
  | 'metric tons / gallon'
  | 'metric tons / household / year'
  | 'metric tons / kilowatt-hour'
  | 'metric tons / person / year'
  | 'metric tons / therm'
  | 'metric tons / year'
  | 'metric tons'
  | 'metric_ton / gallon'
  | 'metric_ton / household / year'
  | 'metric_ton / kilowatt_hour'
  | 'metric_ton / person / year'
  | 'metric_ton / therm'
  | 'metric_ton / year'
  | 'metric_ton'
  | 'metric_ton_equivalent_co2'
  | 'mets'
  | 'mgal / day'
  | 'mile'
  | 'miles / day / household'
  | 'miles / day / person'
  | 'miles / day'
  | 'miles / gallon'
  | 'miles / hour'
  | 'miles / year / household'
  | 'miles / year / person'
  | 'miles / year'
  | 'miles per hour'
  | 'miles'
  | 'miles_traveled'
  | 'millimeters'
  | 'million british thermal units / year'
  | 'million british thermal units'
  | 'million metric tons / year'
  | 'minute / day'
  | 'minute/day'
  | 'minutes'
  | 'multiplier'
  | 'nautical_miles'
  | 'none'
  | 'passenger vehicles / yr'
  | 'passenger_vehicles'
  | 'people'
  | 'per_thousand_res'
  | 'percent'
  | 'person / acre'
  | 'person'
  | 'persons / vehicle'
  | 'persons'
  | 'pound / gallon'
  | 'pound / megawatt_hour'
  | 'pound / milliwatt_hour'
  | 'pound / therm'
  | 'radians'
  | 'square foot'
  | 'square kilometers'
  | 'square meter'
  | 'square_acre'
  | 'square_feet'
  | 'square feet'
  | 'square_feet_per_employee'
  | 'square_foot'
  | 'square_kilometer'
  | 'square_kilometers'
  | 'square_meter'
  | 'square_meters'
  | 'square_mile'
  | 'square_miles'
  | 'therm'
  | 'therm/year/household'
  | 'therm/year/square_foot'
  | 'therms / household / year'
  | 'therms / square foot / year'
  | 'tons / year'
  | 'tons'
  | 'transit_stop'
  | 'trips / day / household'
  | 'trips / day / person'
  | 'trips / day'
  | 'trips / person / day'
  | 'trips / year / household'
  | 'trips / year / person'
  | 'trips / year'
  | 'trips'
  | 'us_dollar / acre_foot'
  | 'us_dollar / kgallon'
  | 'us_dollar'
  | 'us_dollars'
  | 'vehicles / households'
  | 'vehicles'
  | 'yards'
  | 'zones';
/**
 * These are the unit labels for all known datasets in Prod as of 12-27-2018.
 *
 * select json_array_elements(x.columns)->>'units' as units, count(*)
 * from (
 *     select columns from dataset.dataset
 *     union all select columns from dataset.databank
 *     union all select columns from module_run_output_layer_version
 * ) as x
 * group by units
 * order by count desc;
 */

export const UNIT_LABELS: Record<UnitLabelKeys, UnitInfo> = {
  $: {
    inSentenceName: t`US Dollars`,
    name: t`US Dollars`,
  },
  '(dwelling units + jobs) / acre': {
    abbreviation: t`(DU+jobs)/ac`,
    inSentenceName: t`dwelling units and jobs per acre`,
    name: t`Dwelling units and jobs per acre`,
  },
  'ac - ft': {
    inSentenceName: t`acre-feet`,
    name: t`Acre-feet`,
  },
  acre: {
    inSentenceName: t`acres`,
    name: t`Acres`,
  },
  'acre-ft / year': {
    inSentenceName: t`acre-feet / year`,
    name: t`Acre-feet / year`,
  },
  [ACRES]: {
    abbreviation: t`ac`,
    inSentenceName: t`acres`,
    inSentenceSingular: t`acre`,
    name: t`Acres`,
  },
  [ACRES_FEET]: {
    abbreviation: t`ac-ft`,
    inSentenceName: t`acre-feet`,
    name: t`Acre-feet`,
  },
  bmi: {
    inSentenceName: t`BMI`,
    name: t`BMI`,
  },
  'British thermal units / household / year': {
    inSentenceName: t`british thermal units / household / year`,
    name: t`British thermal units / household / year`,
  },
  'British thermal units / person / year': {
    inSentenceName: t`british thermal units / person / year`,
    name: t`British thermal units / person / year`,
  },
  'British thermal units / year': {
    inSentenceName: t`british thermal units / year`,
    name: t`British thermal units / year`,
  },
  [BTU]: {
    abbreviation: t`BTU`,
    inSentenceName: t`BTUs`,
    name: t`British thermal units`,
  },
  'canvas features': {
    inSentenceName: t`canvas features`,
    name: t`Canvas Features`,
  },
  centimeters: {
    abbreviation: t`cm`,
    inSentenceName: t`centimeters`,
    name: t`Centimeters`,
  },
  cubic_feet: {
    inSentenceName: t`cubic feet`,
    name: t`Cubic feet`,
  },
  day: {
    inSentenceName: t`days`,
    name: t`Days`,
  },
  'day / year': {
    abbreviation: t`day/year`,
    inSentenceName: t`days per year`,
    name: t`Days per year`,
  },
  degrees: {
    abbreviation: '\u00B0',
    inSentenceName: t`degrees`,
    name: t`Degrees`,
  },
  dollar: {
    abbreviation: '$',
    inSentenceName: t`dollars`,
    name: t`Dollars`,
  },
  'dollar/square_meter': {
    inSentenceName: t`dollars / square meter`,
    name: t`Dollars / square meter`,
  },
  [DOLLARS]: {
    abbreviation: '$',
    inSentenceName: t`dollars`,
    name: t`Dollars`,
  },
  'dollars / 1000 square feet': {
    abbreviation: t`$/1,000 ft\u00B2`,
    inSentenceName: t`dollars / 1,000 square feet`,
    name: t`dollars / 1,000 square feet`,
  },
  'dollars / acre': {
    inSentenceName: t`dollars / acre`,
    name: t`Dollars / acre`,
  },
  'dollars / acre-foot': {
    abbreviation: t`$/ac-ft`,
    inSentenceName: t`dollars / acre-foot`,
    name: t`Dollars / acre-foot`,
  },
  'dollars / dwelling unit': {
    abbreviation: t`$/DU`,
    inSentenceName: t`dollars / dwelling unit`,
    name: t`Dollars / dwelling unit`,
  },
  'dollars / foot': {
    abbreviation: t`$/ft`,
    inSentenceName: t`dollars / foot`,
    name: t`Dollars / foot`,
  },
  'dollars / gallon': {
    abbreviation: t`$/gal`,
    inSentenceName: t`dollars / gallon`,
    name: t`Dollars / gallon`,
  },
  'Dollars / household / year': {
    abbreviation: t`$/hh/yr`,
    inSentenceName: t`dollars / household / year`,
    name: t`Dollars / household / year`,
  },
  'dollars / kilowatt_hour': {
    abbreviation: t`$/kWh`,
    inSentenceName: t`dollars / kilowatt-hour`,
    name: t`Dollars / kilowatt-hour`,
  },
  'dollars / kilowatt-hour': {
    abbreviation: t`$/kWh`,
    inSentenceName: t`dollars / kilowatt-hour`,
    name: t`Dollars / kilowatt-hour`,
  },
  'dollars / lane * mile': {
    abbreviation: t`$/lane mile`,
    inSentenceName: t`dollars / lane mile`,
    name: t`Dollars / lane mile`,
  },
  'dollars / mile': {
    abbreviation: t`$/mile`,
    inSentenceName: t`dollars / mile`,
    name: t`Dollars / mile`,
  },
  'dollars / square foot': {
    abbreviation: t`$/ft\u00B2`,
    inSentenceName: t`dollars / square foot`,
    name: t`Dollars / square foot`,
  },
  'dollars / therm': {
    abbreviation: t`$/therm`,
    inSentenceName: t`dollars / therm`,
    name: t`Dollars / therm`,
  },
  'dollars / year': {
    inSentenceName: t`dollars / year`,
    name: t`Dollars / year`,
  },
  'Dollars / year': {
    inSentenceName: t`dollars / year`,
    name: t`Dollars / year`,
  },
  'dollars/kilowatt*hour': {
    abbreviation: t`$/kWh`,
    inSentenceName: t`dollars / kilowatt-hour`,
    name: t`Dollars / Kilowatt-hour`,
  },
  'dwelling units': {
    abbreviation: t`DU`,
    inSentenceName: t`dwelling units`,
    name: t`Dwelling units`,
  },
  'dwelling units / acre': {
    abbreviation: t`DU/ac`,
    inSentenceName: t`dwelling units / acre`,
    name: t`Dwelling units / acre`,
  },
  dwelling_unit: {
    inSentenceName: t`dwelling units`,
    name: t`Dwelling units`,
  },
  'dwelling_unit / acre': {
    inSentenceName: t`dwelling units / acre`,
    name: t`Dwelling units / acre`,
  },
  dwelling_units: {
    inSentenceName: t`dwelling units`,
    name: t`Dwelling units`,
  },
  dwelling_units_occupied: {
    inSentenceName: t`dwelling units occupied`,
    name: t`Dwelling units occupied`,
  },
  employees: {
    inSentenceName: t`employees`,
    name: t`Employees`,
  },
  feet: {
    inSentenceName: t`feet`,
    name: t`Feet`,
  },
  foot: {
    inSentenceName: t`feet`,
    name: t`Feet`,
  },
  ft: {
    inSentenceName: t`feet`,
    name: t`Feet`,
  },
  [GALLONS]: {
    abbreviation: t`gal`,
    inSentenceName: t`gallons`,
    name: t`Gallons`,
  },
  // matches typo in usgs_usco_2010.metadata.yml
  'gallons / day': {
    inSentenceName: t`gallons / day`,
    name: t`Gallons / day`,
  },
  'gallons / employee / year': {
    inSentenceName: t`gallons / employee / year`,
    name: t`Gallons / employee / year`,
  },
  'gallons / household / year': {
    inSentenceName: t`gallons / household / year`,
    name: t`Gallons / household / year`,
  },
  'gallons / person / day': {
    abbreviation: t`GPCD`,
    inSentenceName: t`gallons / person / day`,
    name: t`Gallons / person / day`,
  },
  'gallons / person / year': {
    inSentenceName: t`gallons / person / year`,
    name: t`Gallons / person / year`,
  },
  'gallons / year': {
    inSentenceName: t`gallons / year`,
    name: t`Gallons / year`,
  },
  'gallons/dayay': {
    inSentenceName: t`gallons / day`,
    name: t`Gallons / day`,
  },
  'gC / m^2': {
    inSentenceName: t`gC / m\u00B2`,
    name: t`gC / m\u00B2`,
  },
  'gigawatt * hour / year': {
    inSentenceName: t`GWh / year`,
    name: t`GWh / year`,
  },
  'gigawatt-hours': {
    inSentenceName: t`GWh`,
    name: t`GWh`,
  },
  'grams / mile': {
    abbreviation: t`g/mi`,
    inSentenceName: t`grams / mile`,
    name: t`Grams / mile`,
  },
  hectare: {
    inSentenceName: t`hectares`,
    name: t`Hectares`,
  },
  // TODO fix whatever dataset called this hectare, if it's a uf layer
  hectares: {
    inSentenceName: t`hectares`,
    name: t`Hectares`,
  },
  hh: {
    inSentenceName: t`households`,
    name: t`Households`,
  },
  hour: {
    abbreviation: 'hour',
    inSentenceName: t`hour of the day`,
    name: t`Hour of the day`,
  },
  household: {
    inSentenceName: t`households`,
    name: t`Households`,
  },
  households: {
    inSentenceName: t`households`,
    name: t`Households`,
  },
  'inch/year': {
    inSentenceName: t`inches / year`,
    name: t`Inches / year`,
  },
  inches: {
    abbreviation: t`in`,
    inSentenceName: t`inches`,
    name: t`Inches`,
  },
  'inches / year': {
    abbreviation: t`in/yr`,
    inSentenceName: t`inches / year`,
    name: t`Inches / year`,
  },
  'intersection/square_mile': {
    inSentenceName: t`intersections / square mile`,
    name: t`Intersections / square mile`,
  },
  'intersections / square_mile': {
    inSentenceName: t`intersections / square mile`,
    name: t`Intersections / square mile`,
  },
  jobs: {
    inSentenceName: t`jobs`,
    name: t`Jobs`,
  },
  'jobs / acre': {
    abbreviation: t`jobs/ac`,
    inSentenceName: t`jobs / acre`,
    name: t`Jobs / acre`,
  },
  kilometer: {
    inSentenceName: t`kilometers`,
    name: t`Kilometers`,
  },
  kilometers: {
    inSentenceName: t`kilometers`,
    name: t`Kilometers`,
  },
  'kilometer / square_kilometer': {
    inSentenceName: t`kilometers / square kilometer`,
    name: t`Kilometers / square kilometer`,
  },
  'kilowatt * hour / year / household': {
    inSentenceName: t`kwh / year / household`,
    name: t`Kwh / year / household`,
  },
  'kilowatt * hour / year / square_foot': {
    inSentenceName: t`kwh / year / square foot`,
    name: t`Kwh / year / square foot`,
  },
  'Kilowatt hour / year': {
    inSentenceName: t`kwh / year`,
    name: t`Kwh / year`,
  },
  'Kilowatt hours / year': {
    inSentenceName: t`kwh / year`,
    name: t`Kwh / year`,
  },
  'kilowatt_hour / million gallons': {
    abbreviation: t`kWh/Mgal`,
    inSentenceName: t`kilowatt-hours / million gallons`,
    name: t`Kilowatt-hours / million gallons`,
  },
  'kilowatt-hour / million gallons': {
    abbreviation: t`kWh/Mgal`,
    inSentenceName: t`kilowatt-hours / million gallons`,
    name: t`Kilowatt-hours / million gallons`,
  },
  'kilowatt-hours / household / year': {
    abbreviation: t`kWh/HH/yr`,
    inSentenceName: t`kilowatt-hours / household / year`,
    name: t`Kilowatt-hours / household / year`,
  },
  'kilowatt-hours / square foot / year': {
    abbreviation: t`kWh/ft\u00B2/yr`,
    inSentenceName: t`kilowatt-hours / square-foot / year`,
    name: t`Kilowatt-hours / square-foot / year`,
  },
  [KWH]: {
    abbreviation: t`KWh`,
    inSentenceName: t`kilowatt-hours`,
    name: t`Kilowatt-hours`,
  },
  'lb / billion british thermal units': {
    inSentenceName: t`lbs / billion british thermal units`,
    name: t`lbs / billion british thermal units`,
  },
  'lb/gigawatt*hour': {
    inSentenceName: t`lbs / GWh`,
    name: t`lbs / GWh`,
  },
  'lb/megawatt*hour': {
    inSentenceName: t`lbs / MWh`,
    name: t`lbs / MWh`,
  },
  'lb/megawatt*hour/year': {
    inSentenceName: t`lbs / MWh / year`,
    name: t`lbs / MWh / year`,
  },
  'lb/million british thermal units': {
    inSentenceName: t`lb / million british thermal units`,
    name: t`lb / million british thermal units`,
  },
  'lbs / year': {
    inSentenceName: t`lbs / year`,
    name: t`lbs / year`,
  },
  megawatt: {
    inSentenceName: t`megawatts`,
    name: t`Megawatts`,
  },
  'megawatt * hour': {
    inSentenceName: t`MWh`,
    name: t`MWh`,
  },
  'megawatt * hour / year': {
    inSentenceName: t`MWh / year`,
    name: t`MWh / year`,
  },
  meter: {
    inSentenceName: t`meters`,
    name: t`Meters`,
  },
  meters: {
    inSentenceName: t`meters`,
    name: t`Meters`,
  },
  'metric tons': {
    inSentenceName: t`metric tons`,
    name: t`Metric tons`,
  },
  'metric tons / gallon': {
    abbreviation: t`MT/gal`,
    inSentenceName: t`metric tons / gallon`,
    name: t`Metric tons / gallon`,
  },
  'metric tons / household / year': {
    inSentenceName: t`metric tons / household / year`,
    name: t`Metric tons / household / year`,
  },
  'metric tons / kilowatt-hour': {
    abbreviation: t`MT/kWh`,
    inSentenceName: t`metric tons / kilowatt-hour`,
    name: t`Metric tons / kilowatt-hour`,
  },
  'metric tons / person / year': {
    inSentenceName: t`metric tons / person / year`,
    name: t`Metric tons / person / year`,
  },
  'metric tons / therm': {
    abbreviation: t`MT/therm`,
    inSentenceName: t`metric tons / therm`,
    name: t`Metric tons / therm`,
  },
  'metric tons / year': {
    inSentenceName: t`metric tons / year`,
    name: t`Metric tons / year`,
  },
  'metric_ton / gallon': {
    abbreviation: t`MT/gal`,
    inSentenceName: t`metric tons / kilowatt-hour`,
    name: t`Metric tons / gallon`,
  },
  'metric_ton / household / year': {
    inSentenceName: t`metric tons / household / year`,
    name: t`Metric tons / household / year`,
  },
  'metric_ton / kilowatt_hour': {
    abbreviation: t`MT/kWh`,
    inSentenceName: t`metric tons / kilowatt-hour`,
    name: t`Metric tons / kilowatt-hour`,
  },
  'metric_ton / person / year': {
    inSentenceName: t`metric tons / person / year`,
    name: t`Metric tons / person / year`,
  },
  'metric_ton / therm': {
    abbreviation: t`MT/therm`,
    inSentenceName: t`metric tons / therm`,
    name: t`Metric tons / therm`,
  },
  'metric_ton / year': {
    inSentenceName: t`metric tons / year`,
    name: t`Metric tons / year`,
  },
  mets: {
    abbreviation: t`mets`,
    inSentenceName: t`metabolic equivalent of task`,
    name: t`Metabolic equivalent of task`,
  },
  'mgal / day': {
    inSentenceName: t`million gallons / day`,
    name: t`Million gallons / day`,
  },
  mile: {
    inSentenceName: t`miles`,
    name: t`Miles`,
  },
  miles: {
    inSentenceName: t`miles`,
    name: t`Miles`,
  },
  'miles / day': {
    inSentenceName: t`miles / day`,
    name: t`Miles / day`,
  },
  'miles / day / household': {
    inSentenceName: t`miles / day / household`,
    name: t`Miles / day / household`,
  },
  'miles / day / person': {
    inSentenceName: t`miles / day / person`,
    name: t`Miles / day / person`,
  },
  'miles / gallon': {
    abbreviation: t`mpg`,
    inSentenceName: t`miles / gallon`,
    name: t`Miles / gallon`,
  },
  'miles / hour': {
    abbreviation: t`mph`,
    inSentenceName: t`miles / hour`,
    name: t`Miles / hour`,
  },
  'miles / year': {
    inSentenceName: t`miles / year`,
    name: t`Miles / year`,
  },
  'miles / year / household': {
    inSentenceName: t`miles / year / household`,
    name: t`Miles / year / household`,
  },
  'miles / year / person': {
    inSentenceName: t`miles / year / person`,
    name: t`Miles / year / person`,
  },
  'miles per hour': {
    abbreviation: t`mph`,
    inSentenceName: t`miles / hour`,
    name: t`Miles / hour`,
  },
  [MILES_TRAVELED]: {
    inSentenceName: t`miles traveled`,
    name: t`Miles traveled`,
  },
  millimeters: {
    abbreviation: t`mm`,
    inSentenceName: t`millimeters`,
    name: t`Millimeters`,
  },
  'million british thermal units': {
    inSentenceName: t`million british thermal units`,
    name: t`Million british thermal units`,
  },
  'million british thermal units / year': {
    inSentenceName: t`million british thermal units / year`,
    name: t`Million british thermal units / year`,
  },
  'million metric tons / year': {
    inSentenceName: t`million metric tons / year`,
    name: t`Million metric tons / year`,
  },
  'minute / day': {
    inSentenceName: t`minutes / day`,
    name: t`Minutes / day`,
  },
  'minute/day': {
    inSentenceName: t`minutes / day`,
    name: t`Minutes / day`,
  },
  minutes: {
    inSentenceName: t`minutes`,
    name: t`Minutes`,
  },
  [MT]: {
    abbreviation: t`MT`,
    inSentenceName: t`metric tons`,
    name: t`Metric tons`,
  },
  [MTCO2E]: {
    abbreviation: t`MT CO\u2082e`,
    inSentenceName: t`metric tons of CO\u2082 equivalent`,
    name: t`Metric tons of CO\u2082 equivalent`,
  },
  multiplier: {
    inSentenceName: t`multiplier factor where 0.8 means 80% of the original value`,
    name: t`Multiplier factor where 0.8 means 80% of the original value`,
    terse_abbreviation: t`multiplier`,
  },
  MW: {
    inSentenceName: t`MW`,
    name: t`MW`,
  },
  nautical_miles: {
    inSentenceName: t`nautical miles`,
    name: t`Nautical Miles`,
  },
  none: {
    inSentenceName: '',
    name: '',
  },
  [PASSENGER_VEHICLES]: {
    inSentenceName: t`passenger vehicles`,
    name: t`Passenger vehicles`,
  },
  [PASSENGER_VEHICLES_PER_YEAR]: {
    inSentenceName: t`passenger vehicles / year`,
    name: t`Passenger vehicles / year`,
  },
  people: {
    inSentenceName: t`people`,
    name: t`People`,
  },
  per_thousand_res: {
    abbreviation: t`per 1,000 res`,
    inSentenceName: t`per 1,000 residents`,
    name: t`per 1,000 residents`,
  },
  percent: {
    inSentenceName: t`percent`,
    name: t`Percent`,
  },
  person: {
    inSentenceName: t`persons`,
    name: t`Persons`,
  },
  'person / acre': {
    inSentenceName: t`persons / acre`,
    name: t`Persons / acre`,
  },
  persons: {
    inSentenceName: t`persons`,
    name: t`Persons`,
  },
  'persons / vehicle': {
    abbreviation: t`Persons/vehicle`,
    inSentenceName: t`persons / vehicle`,
    name: t`Persons / vehicle`,
  },
  'pound / gallon': {
    abbreviation: t`lb/gal`,
    inSentenceName: t`pounds / gallon`,
    name: t`Pounds / gallon`,
  },
  'pound / megawatt_hour': {
    abbreviation: t`lb/MWh`,
    inSentenceName: t`pounds / megawatt-hour`,
    name: t`Pounds / megawatt-hour`,
  },
  'pound / milliwatt_hour': {
    abbreviation: t`lb/mWh`,
    inSentenceName: t`pounds / milliwatt-hour`,
    name: t`Pounds / milliwatt-hour`,
  },
  'pound / therm': {
    abbreviation: t`lb/therm`,
    inSentenceName: t`pounds / therm`,
    name: t`Pounds / therm`,
  },
  radians: {
    abbreviation: t`rad`,
    inSentenceName: t`radians`,
    name: t`Radians`,
  },
  [SQ_FT]: {
    inSentenceName: t`square feet`,
    abbreviation: 'ft\u00B2',
    name: t`Square feet`,
  },
  'square feet': {
    inSentenceName: t`square feet`,
    abbreviation: 'ft\u00B2',
    name: t`Square feet`,
  },
  'square foot': {
    abbreviation: t`ft\u00B2`,
    inSentenceName: t`square feet`,
    name: t`Square feet`,
  },
  'square kilometers': {
    inSentenceName: t`square kilometers`,
    abbreviation: 'km\u00B2',
    name: t`Square kilometers`,
  },
  'square meter': {
    inSentenceName: t`square meters`,
    abbreviation: 'm\u00B2',
    name: t`Square meters`,
  },
  square_acre: {
    inSentenceName: t`square acres`,
    name: t`Square acres`,
  },
  square_feet_per_employee: {
    abbreviation: t`ft\u00B2/emp`,
    inSentenceName: t`square feet / employee`,
    name: t`Square feet / employee`,
  },
  square_foot: {
    inSentenceName: t`square foot`,
    abbreviation: 'ft\u00B2',
    name: t`Square foot`,
  },
  square_kilometer: {
    inSentenceName: t`square kilometers`,
    abbreviation: 'km\u00B2',
    name: t`Square kilometers`,
  },
  square_kilometers: {
    inSentenceName: t`square kilometers`,
    abbreviation: 'km\u00B2',
    name: t`Square kilometers`,
  },
  square_meter: {
    inSentenceName: t`square meters`,
    abbreviation: 'm\u00B2',
    name: t`Square meters`,
  },
  square_meters: {
    inSentenceName: t`square meters`,
    abbreviation: 'm\u00B2',
    name: t`Square meters`,
  },
  square_mile: {
    inSentenceName: t`square miles`,
    abbreviation: 'mi\u00B2',
    name: t`Square miles`,
  },
  square_miles: {
    inSentenceName: t`square miles`,
    abbreviation: 'mi\u00B2',
    name: t`Square miles`,
  },
  'Therm / year': {
    inSentenceName: t`therm / year`,
    name: t`Therm / year`,
  },
  'therm/year/household': {
    inSentenceName: t`therm / year / household`,
    name: t`Therm / year / household`,
  },
  'therm/year/square_foot': {
    inSentenceName: t`therm / year / square foot`,
    name: t`Therm / year / square foot`,
  },
  'therms / household / year': {
    abbreviation: t`therm/HH/yr`,
    inSentenceName: t`therms / household / year`,
    name: t`Therms / household / year`,
  },
  'therms / square foot / year': {
    abbreviation: t`therm/ft\u00B2/yr`,
    inSentenceName: t`therms / square-foot / year`,
    name: t`Therms / square-foot / year`,
  },
  'Therms / year': {
    inSentenceName: t`therms / year`,
    name: t`Therms / year`,
  },
  [THM]: {
    abbreviation: t`thm`,
    inSentenceName: t`therms`,
    name: t`Therms`,
  },
  tons: {
    inSentenceName: t`tons`,
    name: t`Tons`,
  },
  'tons / year': {
    inSentenceName: t`tons / year`,
    name: t`Tons / year`,
  },
  transit_stop: {
    inSentenceName: t`transit stops`,
    name: t`Transit stops`,
  },
  trips: {
    inSentenceName: t`trips`,
    name: t`Trips`,
  },
  'trips / day': {
    inSentenceName: t`trips / day`,
    name: t`Trips / day`,
  },
  'trips / day / household': {
    inSentenceName: t`trips / day / household`,
    name: t`Trips / day / household`,
  },
  'trips / day / person': {
    inSentenceName: t`trips / day / person`,
    name: t`Trips / day / person`,
  },
  'trips / person / day': {
    inSentenceName: t`trips / person / day`,
    name: t`Trips / person / day`,
  },
  'trips / year': {
    inSentenceName: t`trips / year`,
    name: t`Trips / year`,
  },
  'trips / year / household': {
    inSentenceName: t`trips / year / household`,
    name: t`Trips / year / household`,
  },
  'trips / year / person': {
    inSentenceName: t`trips / year / person`,
    name: t`Trips / year / person`,
  },
  us_dollar: {
    inSentenceName: t`US dollars`,
    name: t`US Dollars`,
  },
  'us_dollar / acre_foot': {
    abbreviation: t`$/ac-ft`,
    inSentenceName: t`dollars / acre-foot`,
    name: t`Dollars / acre-foot`,
  },
  'us_dollar / kgallon': {
    abbreviation: t`$/1000 gal`,
    inSentenceName: t`dollars / 1000 gallons`,
    name: t`Dollars / 1000 gallons`,
  },
  us_dollars: {
    inSentenceName: t`US dollars`,
    name: t`US Dollars`,
  },
  vehicles: {
    inSentenceName: t`vehicles`,
    name: t`Vehicles`,
  },
  'vehicles / households': {
    inSentenceName: t`vehicles / household`,
    name: t`Vehicles / household`,
  },
  yards: {
    abbreviation: t`yd`,
    inSentenceName: t`yards`,
    name: t`Yards`,
  },
  zones: {
    inSentenceName: t`zones`,
    name: t`Zones`,
  },
};
/**
 * Get information about a unit, including its name(s) and abbreviations.
 *
 * @param unitKey Key of unit type
 */
export function getUnitInfo(unitKey: string): UnitInfo {
  if (unitKey in UNIT_LABELS) {
    return UNIT_LABELS[unitKey];
  }
  warning(
    !!(unitKey in UNIT_LABELS || unitKey === null),
    `Unknown unit key: ${unitKey}, returning key as is.`,
  );
  return {
    key: unitKey,
    name: unitKey,
    inSentenceName: unitKey,
  };
}

/**
 * Get proper name or a name suitable to use in a sentence of a unit.
 *
 * @param unitKey Key of unit type
 */
export function getUnitName(
  unitKey: UnitLabelKeys,
  inSentence?: boolean,
): string {
  const unitInfo = getUnitInfo(unitKey);
  if (inSentence) {
    if (unitInfo.inSentenceName) {
      return unitInfo.inSentenceName;
    }
    return unitInfo.name.toLocaleLowerCase();
  }
  return unitInfo.name;
}

/**
 * Get the abbreviation for a unit.
 *
 * @param unitKey Key of unit type
 */
export function getUnitAbbreviation(unitKey: UnitLabelKeys): string {
  const unitInfo = getUnitInfo(unitKey);
  return unitInfo.abbreviation;
}

export function getUnitNameSingular(unitKey: string) {
  const unitInfo = getUnitInfo(unitKey);
  if (unitInfo.inSentenceSingular) {
    return unitInfo.inSentenceSingular;
  }
  return unitInfo.name;
}

// maps all Turf.js units to our UnitInfos
export const turfToUnitInfoMap: Record<Units, UnitInfo> = {
  miles: getUnitInfo('miles'),
  nauticalmiles: getUnitInfo('nautical_miles'),
  degrees: getUnitInfo('degrees'),
  radians: getUnitInfo('radians'),
  inches: getUnitInfo('inches'),
  yards: getUnitInfo('yards'),
  meters: getUnitInfo('meters'),
  kilometers: getUnitInfo('kilometer'),
  acres: getUnitInfo('acres'),
  centimeters: getUnitInfo('centimeters'),
  feet: getUnitInfo('feet'),
  millimeters: getUnitInfo('millimeters'),
  hectares: getUnitInfo('hectare'),
};
