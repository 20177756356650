import { mdiLink } from '@mdi/js';
import Icon from '@mdi/react';
import * as React from 'react';

import { LayerSource } from 'uf-api';
import { IconSizeNew } from 'uf/ui/base/icon';

interface SourceProps {
  source: LayerSource;
}

const Source: React.FunctionComponent<SourceProps> = props => {
  const {
    source: { url, name },
  } = props;
  if (url) {
    return (
      <a target="_blank" rel="noreferrer noopener" href={url}>
        {name || url} <Icon path={mdiLink} size={IconSizeNew.SMALL} />
      </a>
    );
  }
  return <span>{name}</span>;
};

export default Source;
