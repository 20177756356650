import React from 'react';

type FlexDirection = 'row' | 'row-reverse' | 'col' | 'col-reverse';
type JustifyContent = 'start' | 'end' | 'center' | 'between' | 'around';
type AlignItems = 'start' | 'end' | 'center' | 'stretch';

interface Props {
  flexDirection?: FlexDirection;
  justifyContent?: JustifyContent;
  alignItems?: AlignItems;
  className?: string;
  children?: React.ReactNode;
}

const Flex = ({
  flexDirection = 'row',
  justifyContent = 'start',
  alignItems = 'start',
  className = '',
  children,
}: Props) => {
  if (!children) {
    return null;
  }
  return (
    <div
      data-testid="flex"
      className={`tw-flex tw-flex-${flexDirection} tw-justify-${justifyContent} tw-items-${alignItems} ${className}`}>
      {children}
    </div>
  );
};

export default Flex;
