import { isNullCategory } from 'uf/layers/nullStats';
import {
  DataDrivenColorProperty,
  DataDrivenColorStop,
  FALLBACK_THEME,
  LayerColumnSymbology,
  SymbologyScales,
  Theme,
} from 'uf/symbology';
import { symbologyTypeSupportsColor } from 'uf/symbology/helpers';
import {
  getPaintProperty,
  isDataDriven,
  makePaintPropertyKey,
} from 'uf/symbology/spec/paint';
import {
  generateThemeColorBins,
  generateThemeColorScale,
} from 'uf/symbology/theme';

/**
 * Returns a new copy of the given symbology,
 * with new colors calculated from the given theme and scale type.
 *
 * @param symbology - a single symbology from a LayerColumnSymbology
 * @param newProperties
 * @param newProperties.theme - 'custom' or a theme from MaterialThemes or D3Themes
 * @param newProperties.scaleType = one of 'categorical' or 'numeric', see SymbologyScales
 * @return updatedSymbology - a copy of `symbology` with the new theme applied
 */
export function updateTheme(
  symbology: LayerColumnSymbology,
  { theme, scaleType },
): LayerColumnSymbology {
  if (!symbologyTypeSupportsColor(symbology.type)) {
    return symbology;
  }
  const colorPaintProperty = getPaintProperty(symbology, 'color');

  if (isDataDriven(colorPaintProperty)) {
    return updateDataDrivenTheme(symbology, theme, scaleType);
  }

  return updateBasicTheme(symbology, theme, scaleType);
}

export function updateThemeReversed(
  symbology: LayerColumnSymbology,
  newThemeReversed: boolean,
): LayerColumnSymbology {
  const newSymbology: LayerColumnSymbology = {
    ...symbology,
    display_hints: {
      ...symbology.display_hints,
      is_reverse_theme: newThemeReversed,
    },
  };
  const {
    theme,
    scale: scaleType,
    is_reverse_theme: isThemeReversed,
  } = symbology.display_hints;
  // on reversing theme, flip stop colors for categorical or custom bins
  // or other situations not handled by d3 scale (yet)
  if (theme === 'custom') {
    // isThemeRevered may be null, negate to get proper boolean
    if (newThemeReversed !== !!isThemeReversed) {
      return flipStopColors(newSymbology);
    }
    // if no toggle is detected, no operation is done
    return symbology;
  }
  return updateTheme(newSymbology, { theme, scaleType });
}

// TODO: Make this work for categorical themes as well
export function setDefaultTheme(
  symbology: LayerColumnSymbology,
): LayerColumnSymbology {
  const { scale: scaleType } = symbology.display_hints;
  const update = { theme: FALLBACK_THEME, scaleType };
  return updateTheme(symbology, update);
}

function updateBasicTheme(
  symbology: LayerColumnSymbology,
  newTheme: Theme,
  scaleType: SymbologyScales,
): LayerColumnSymbology {
  const colorPaintPropertyKey = makePaintPropertyKey(symbology, 'color');

  const scale = generateThemeColorScale(1, newTheme, scaleType);

  const newSymbology = {
    ...symbology,

    paint: {
      ...symbology.paint,
      [colorPaintPropertyKey]: scale(1),
    },

    display_hints: {
      ...symbology.display_hints,
      theme: newTheme,
    },
  };

  return newSymbology;
}

function updateDataDrivenTheme(
  symbology: LayerColumnSymbology,
  theme: Theme,
  scaleType: SymbologyScales,
): LayerColumnSymbology {
  const colorPaintPropertyKey = makePaintPropertyKey(symbology, 'color');
  const colorPaintProperty = getPaintProperty<
    DataDrivenColorStop,
    DataDrivenColorProperty
  >(symbology, 'color');

  if (!isDataDriven<DataDrivenColorProperty>(colorPaintProperty)) {
    return symbology;
  }

  const { stops } = colorPaintProperty;

  const {
    display_hints: { is_reverse_theme: isThemeReversed },
  } = symbology;

  let nonNullStops: DataDrivenColorStop[] = stops.filter(
    stop => !isNullCategory(stop.value),
  );
  const nullStops: DataDrivenColorStop[] = stops.filter(stop =>
    isNullCategory(stop.value),
  );

  const colors = generateThemeColorBins(
    nonNullStops.length,
    theme,
    scaleType,
    isThemeReversed,
  );
  nonNullStops = nonNullStops.map((stop, i) => ({
    ...stop,
    color: colors[i],
  }));
  const newStops = nullStops.concat(nonNullStops);

  const newSymbology = {
    ...symbology,

    paint: {
      ...symbology.paint,
      [colorPaintPropertyKey]: {
        ...colorPaintProperty,
        stops: newStops,
      },
    },

    display_hints: {
      ...symbology.display_hints,
      theme,
    },
  };

  return newSymbology;
}

function flipStopColors(symbology: LayerColumnSymbology): LayerColumnSymbology {
  const colorPaintPropertyKey = makePaintPropertyKey(symbology, 'color');
  const colorPaintProperty = getPaintProperty<
    DataDrivenColorStop,
    DataDrivenColorProperty
  >(symbology, 'color');

  if (!isDataDriven<DataDrivenColorProperty>(colorPaintProperty)) {
    return symbology;
  }

  const { stops } = colorPaintProperty;
  const nonNullStops: DataDrivenColorStop[] = stops.filter(
    stop => !isNullCategory(stop.value),
  );
  const nullStops: DataDrivenColorStop[] = stops.filter(stop =>
    isNullCategory(stop.value),
  );

  const reversedColorNonNullStops = nonNullStops.map((nns, i) => ({
    ...nns,
    color: nonNullStops[nonNullStops.length - i - 1].color,
  }));

  const newStops = nullStops.concat(reversedColorNonNullStops);

  return {
    ...symbology,
    paint: {
      ...symbology.paint,
      [colorPaintPropertyKey]: {
        ...colorPaintProperty,
        stops: newStops,
      },
    },
  };
}
