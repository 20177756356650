import { LayerData } from 'uf-api';

/**
 * The data from get_layer_data comes back in the form:
 * { column_keys: ['a', 'b', 'c'],
 *   rows: [[1, 2, 3], [4, 5, 6], [7, 8, 9]],
 *   <pagination state>,
 * }
 *
 * But the in-memory representation is in the form:
 * {
 *   items: [
 *     { id: 0, a: 1, b: 2, c: 3 },
 *     { id: 1, a: 4, b: 5, c: 6 },
 *     { id: 2, a: 7: b: 8, c: 9 },
 *   ],
 *   <pagination state>,
 * }
 */
export function translateDataToItems(
  layerData: LayerData,
  uniqueKeys: string[] = [],
) {
  const { column_keys: columnKeys, rows } = layerData;

  if (!rows) {
    return [];
  }

  return rows.map(row => {
    // Provide a default 'id' if there isn't one in the dataset.
    // it might get overwritten, but that's ok.
    // TODO: Use the metadata to generate a unique row id
    const rowObject: Record<string, string> = {};
    columnKeys.forEach((columnKey, i) => {
      rowObject[columnKey] = row[i];
    });

    // now compose a key
    const uniqueKeysForRow = uniqueKeys.map(key => rowObject[key]);
    rowObject._internalSearchKey = uniqueKeysForRow.join('\n');

    return rowObject;
  });
}
