import { Intent, Menu, MenuDivider, Tag, Tooltip } from '@blueprintjs/core';
import { mdiCallMade, mdiContentCopy, mdiDelete, mdiPencil } from '@mdi/js';
import { Icon } from '@mdi/react';
import React, { Fragment } from 'react';
import { t } from 'ttag';

import { UserAction } from 'uf-api';
import { parseFullPath } from 'uf/base/dataset';
import { ProjectId } from 'uf/projects';
import { getCreateScenarioPermissionText, ScenarioId } from 'uf/scenarios';
import CanPerform from 'uf/ui/base/CanPerform/CanPerform';
import { IconSizeNew } from 'uf/ui/base/icon';
import OverflowMenuItem from 'uf/ui/base/OverflowMenuItem/OverflowMenuItem';

import styles from './ScenarioOptionsMenu.module.css';

interface Props {
  closeMenu: () => void;
  scenarioId: ScenarioId;
  projectId: ProjectId;
  onCloneScenario?: () => void;
  onEditScenario?: () => void;
  onDeleteScenario?: () => void;
  onCreateProject?: () => void;
  showCreateProject?: boolean;
  scenariosLoading?: boolean;
}

const ScenarioOptionsMenu: React.FunctionComponent<Props> = props => {
  const {
    closeMenu,
    scenarioId,
    projectId,
    onCloneScenario,
    onEditScenario,
    onDeleteScenario,
    onCreateProject,
    showCreateProject,
    scenariosLoading,
    // when rendered via Kebab, we must allow props to pass
    // through to the Popover for correct positioning
    ...restProps
  } = props;

  const { key: scenarioKey } = parseFullPath(scenarioId, 'scenario');

  return (
    <Menu className={styles.menu} {...restProps}>
      <CanPerform cache={false} verb="scenario.create" resources={projectId}>
        {({ disabled, reason }) => {
          let label = null;
          if (disabled) {
            if (reason === UserAction.ReasonEnum.Permission) {
              return null;
            }
            const tooltipText = getCreateScenarioPermissionText(
              !disabled,
              reason,
            );
            label = (
              <Tooltip content={tooltipText} hoverOpenDelay={500}>
                {t`Duplicate`}
              </Tooltip>
            );
          } else {
            label = t`Duplicate`;
          }
          return (
            <OverflowMenuItem
              disabled={disabled}
              closeAfterLoading
              closeMenu={closeMenu}
              className={'Scenario-duplicate'}
              loading={scenariosLoading}
              label={label}
              icon={
                <Icon path={mdiContentCopy} size={IconSizeNew.EXTRA_SMALL} />
              }
              onSelect={onCloneScenario}
            />
          );
        }}
      </CanPerform>
      <OverflowMenuItem
        className={'Scenario-editDetails'}
        label={t`Edit details`}
        icon={<Icon path={mdiPencil} size={IconSizeNew.EXTRA_SMALL} />}
        onSelect={onEditScenario}
      />
      <OverflowMenuItem
        className={'Scenario-delete'}
        disabled={scenarioKey.startsWith('_no_delete')}
        label={t`Delete`}
        icon={<Icon path={mdiDelete} size={IconSizeNew.EXTRA_SMALL} />}
        onSelect={onDeleteScenario}
      />
      {showCreateProject && (
        <Fragment>
          <MenuDivider />
          <OverflowMenuItem
            label={t`Create new project from this scenario`}
            labelElement={<Tag intent={Intent.WARNING}>{t`BETA`}</Tag>}
            icon={<Icon path={mdiCallMade} size={IconSizeNew.EXTRA_SMALL} />}
            onClick={onCreateProject}
          />
        </Fragment>
      )}
    </Menu>
  );
};

export default ScenarioOptionsMenu;
