import _ from 'lodash';
import { useCallback, useEffect } from 'react';

/**
 * Use just like useCallback, but to debounce all outgoing changes.
 *
 * **Note that you MUST include deps just like `useCallback` or you won't get a
 * new callback when the callback's scope changes**
 *
 * **Note that this will not preserve event data in react v16 or older, see:
 * https://reactjs.org/docs/legacy-event-pooling.html **
 *
 * ```
 * const onChange = useDebouncedCallback(e => {
 *   onInputChange(someKey, e.target.value);
 * }, 100, [someKey, onInputChange]);
 *
 *    <input onChange={onChange} />
 * ```
 *
 * Adapted from
 * https://stackoverflow.com/a/57335271/204357
 *
 * @param callback the callback you want to call
 * @param deps The deps for the callback, just like useCallback
 * @param wait The amount of time to wait
 */
export function useDebouncedCallback<A extends any[]>(
  callback: (...args: A) => void,
  deps: unknown[],
  wait: number,
  leading: boolean = false,
  trailing: boolean = true,
) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedCall = useCallback(
    _.debounce(callback, wait, { leading, trailing }),
    // if there are no parameters, then we can depend on the callback itself to change
    deps.length ? [wait, ...deps] : [wait, leading, trailing, callback],
  );
  function cleanup() {
    debouncedCall.cancel();
  }
  // Used only for cleanup, to make sure the debounce doesn't fire after the
  // component is unmounted.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => cleanup, []);
  return debouncedCall;
}
