import { dispatchAsyncAction } from 'uf/data/loader';
import { importActionTypes } from 'uf/import/ActionTypes';
import { importLayerAPI } from 'uf/import/apis';
import { ProjectId } from 'uf/projects';

export function importLayer(projectId: ProjectId, file: File, name: string) {
  return dispatchAsyncAction(
    importActionTypes,
    importLayerAPI(projectId, file, name),
    {
      key: `${projectId} ${file.name}-${Math.random()}`,
      projectId,
    },
  );
}
