export const sources = {
  EXPLORE_DATA_VIEWER: 'uf.ui.explore.data_viewer',
};

export const events = {
  ZOOM_TO_LAYER_CLICKED: 'uf.uf.explore.map.toolbar.zoom_to_layer',
  ZOOM_TO_SELECTION_CLICKED: 'uf.uf.explore.map.toolbar.zoom_to_selection',
  INTENT_TO_SEARCH: 'uf.uf.explore.map.toolbar.intent_to_search',
  SEARCH_MATCH_SELECTED: 'uf.uf.explore.map.toolbar.search_match_selected',
};
