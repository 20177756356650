import { boundMethod } from 'autobind-decorator';
import React, { Component } from 'react';

import Kebab from 'uf/ui/base/Kebab/Kebab';

import AnalysisModuleMenu from './AnalysisModuleMenu';

interface AnalysisKebabProps {
  moduleKey?: string;
  className?: string;
  onOpen?: () => void;
  onClose?: () => void;
}
// use class to store ref
export default class AnalysisKebab extends Component<AnalysisKebabProps> {
  // make sure this doesn't trigger click events upstream since this component
  // can be used within other clickable components (i.e. buttons).
  @boundMethod
  onClickKebab(e) {
    e.stopPropagation();
  }

  render() {
    const { moduleKey, className, onOpen, onClose } = this.props;

    return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events
      <span role="button" tabIndex={0} onClick={this.onClickKebab}>
        <Kebab
          onOpen={onOpen}
          onClose={onClose}
          horizontal
          className={className}
          small
          overlayPlacement="bottom"
          menuPlacement="bottom">
          {() => <AnalysisModuleMenu moduleKey={moduleKey} />}
        </Kebab>
      </span>
    );
  }
}
