import { Spinner, SpinnerSize, Tooltip } from '@blueprintjs/core';
import { mdiInformation } from '@mdi/js';
import Icon from '@mdi/react';
import classNames from 'classnames';
import _ from 'lodash';
import React, { Component } from 'react';
import { Box, ScrollView } from 'react-layout-components';
import { connect } from 'react-redux';
import { jt, t } from 'ttag';

import { LayerReference } from 'uf-api';
import { LayerId, LayerManagerCollapsedState } from 'uf/layers';
import { ProjectId } from 'uf/projects';
import {
  AnnotatedLayerReference,
  LayerListCategory,
  LoadingDataState,
  makeGetProjectLayersCategoryList,
  makeGetProjectLayersCategoryListLoadingState,
} from 'uf/projects/selectors/availableLayers';
import { UFState } from 'uf/state';
import { tw } from 'uf/tailwindcss-classnames';
import { IconSizeNew } from 'uf/ui/base/icon';
import makeDataAttributes from 'uf/ui/base/makeDataAttributes';
import { useFavoriteLayers } from 'uf/ui/layers/hooks';

import CategoryList from './CategoryList';

export const DEFAULT_OPEN_CATEGORY = 'education';
// convert array of strings to JSX element
const NEW_LINE = <br />;
const FAVORITE_LAYERS_TOOLTIP = (
  // eslint-disable-next-line react/jsx-no-useless-fragment
  <>{jt`
  Favorite layers are ${NEW_LINE}
  automatically added to
  ${NEW_LINE} all new projects
`}</>
);
const FAVORITE_CATEGORY_KEY = 'favorites';
const FAVORITE_CATEGORY_LABEL = t`Favorite Layers`;

interface OwnProps {
  projectId: ProjectId;
  restrictedLayerIds?: string[];
  layerManagerActiveLayerId?: string;
  onLayerClick?: (layerId: LayerId) => void;
  filterFn?: (layer: LayerReference) => boolean;
  isSearchResult?: boolean;
  defaultOpenCategory?: string;
  favorites?: LayerId[];
  isCollapsedByCategory: LayerManagerCollapsedState;
  onToggleCategoryCollapsed?: (
    categoryKey: string,
  ) => (collapsed: boolean) => void;
}

interface StateProps {
  // TODO: make a type for this crazy thing.
  projectLayersCategoryList: LayerListCategory[];
  categoryListLoadingState: LoadingDataState;
}

type LayerManagerListProps = OwnProps & StateProps;

export class LayerManagerList extends Component<LayerManagerListProps> {
  static defaultProps = {
    // Only render layers that pass the filterFn
    filterFn: _.stubTrue,
    isSearchResult: false,
    restrictedLayerIds: [],
    onLayerClick: (layerId: LayerId) => {},
    // Set the category that is open by default for users
    defaultOpenCategory: DEFAULT_OPEN_CATEGORY,
  };

  componentDidMount() {
    const { layerManagerActiveLayerId } = this.props;
    const categories = this.getCategories();
    this.setFirstLayerActive(categories, layerManagerActiveLayerId);
  }

  componentDidUpdate(prevProps: Readonly<OwnProps & StateProps>): void {
    const categories = this.getCategories();
    this.setFirstLayerActive(categories, this.props.layerManagerActiveLayerId);
  }

  getCategories(passedProps?: LayerManagerListProps) {
    const props = passedProps || this.props;
    const {
      layerManagerActiveLayerId,
      restrictedLayerIds,
      projectLayersCategoryList,
      filterFn,
      isSearchResult,
      defaultOpenCategory,
      favorites,
      isCollapsedByCategory,
    } = props;

    return annotateCategories(
      projectLayersCategoryList,
      filterFn,
      layerManagerActiveLayerId,
      restrictedLayerIds,
      isSearchResult,
      isCollapsedByCategory,
      defaultOpenCategory,
      favorites,
    );
  }

  setFirstLayerActive(categories, layerManagerActiveLayerId) {
    const { onLayerClick, defaultOpenCategory } = this.props;
    /**
     * Layer metadata responses (requested whenever a user clicks on a list item) are
     * kept in the Redux store for the duration of a session. This conditional prevents
     * layer metadata that may no longer be valid (e.g. switching to a different project
     * area where the currently selected layer doesn't exist) from being populated
     */
    const isInvalidLayer =
      !_.isEmpty(categories) &&
      layerManagerActiveLayerId &&
      !categories.find(category =>
        category.layerReferences.find(
          ref => ref.layer.full_path === layerManagerActiveLayerId,
        ),
      );
    if (
      (!_.isEmpty(categories) && !layerManagerActiveLayerId) ||
      isInvalidLayer
    ) {
      // this is sort of a hack to set the first layer.  A better approach might be to move all of
      // the code responsible for creating the categories to a hoc that could wrap the LayerManager
      // and pass down the result.  That way the LayerManager could just set the
      // layerManagerActiveLayerId itself.
      const defaultCategory = categories.find(category => {
        return category.categoryKey === defaultOpenCategory;
      });
      onLayerClick(defaultCategory?.layerReferences?.[0]?.layer?.full_path);
    }
  }

  render() {
    const {
      projectId,
      categoryListLoadingState: { isLoadingData, hasData },
      onLayerClick,
      onToggleCategoryCollapsed,
    } = this.props;

    const categories = this.getCategories();

    if (isLoadingData && !hasData) {
      return (
        <Box flex={1} alignItems="center" justifyContent="center">
          <Spinner size={SpinnerSize.SMALL} />
        </Box>
      );
    }

    if (_.isEmpty(categories)) {
      return null;
    }

    return (
      <ScrollView
        className={classNames(tw('tw-divide-y'), 'LayerManagerList')}
        flex={1}>
        {categories.map(
          ({ categoryKey, categoryLabel, collapsed, layerReferences }) => (
            <CategoryList
              className={'LayerManager-category'}
              dataAttributes={makeDataAttributes({ categoryKey })}
              key={categoryKey}
              projectId={projectId}
              onCollapse={onToggleCategoryCollapsed(categoryKey)}
              collapsed={collapsed}
              headerClassName={'LayerManager-categoryHeader'}
              headerDataAttributes={makeDataAttributes({ categoryKey })}
              header={makeHeader(
                categoryLabel,
                layerReferences.length,
                categoryKey === FAVORITE_CATEGORY_KEY
                  ? FAVORITE_LAYERS_TOOLTIP
                  : null,
              )}
              layerReferences={layerReferences}
              onLayerClick={onLayerClick}
            />
          ),
        )}
      </ScrollView>
    );
  }
}

export const LayerManagerListConnected = connect<StateProps, {}, OwnProps>(
  () => {
    const getProjectLayersCategoryList = makeGetProjectLayersCategoryList();
    const getCategoryListLoadingState =
      makeGetProjectLayersCategoryListLoadingState();

    return (state: UFState, ownProps: OwnProps): StateProps => {
      const { projectId } = ownProps;

      return {
        projectLayersCategoryList: getProjectLayersCategoryList(state, {
          projectId,
        }),
        categoryListLoadingState: getCategoryListLoadingState(state, {
          projectId,
        }),
      };
    };
  },
)(LayerManagerList);

export default function DefaultLayerManageList(props: OwnProps) {
  const { favoriteLayers } = useFavoriteLayers();
  return (
    <LayerManagerListConnected
      {...props}
      favorites={favoriteLayers.map(lr => lr.full_path)}
    />
  );
}

export function annotateCategories(
  categoryList: LayerListCategory[],
  filterFn: (layer: LayerReference) => boolean,
  activeLayerId: string,
  restrictedLayerIds: string[],
  isSearchResult: boolean,
  isCollapsedByCategory: LayerManagerCollapsedState,
  defaultOpenCategory?: string,
  favorites?: LayerId[],
) {
  // We only use the layers that pass the search filter, and we remove empty categories
  const filteredCategoryList = filterLayersByCategory(categoryList, filterFn);

  // Annotate the active layer
  let annotatedCategoriesList = getCategoriesWithActiveAnnotation(
    filteredCategoryList,
    activeLayerId,
  );

  // Annotate restricted layers
  if (!_.isEmpty(restrictedLayerIds)) {
    annotatedCategoriesList = getCategoriesWithRestrictedAnnotation(
      annotatedCategoriesList,
    );
  }

  // Sort the categories alphabetically by category label
  let sortedCategoriesList = _.sortBy(annotatedCategoriesList, 'categoryLabel');

  if (favorites) {
    const favoriteLayersSeen = sortedCategoriesList.reduce(
      (favoriteRefs, category) => {
        return category.layerReferences.reduce((acc, layerRef) => {
          const { layer } = layerRef;
          if (favorites.indexOf(layer.full_path) !== -1) {
            acc.push(layerRef);
          }
          return acc;
        }, favoriteRefs);
      },
      [] as AnnotatedLayerReference[],
    );
    if (favoriteLayersSeen.length) {
      const favoriteCategory: LayerListCategory = {
        categoryKey: FAVORITE_CATEGORY_KEY,
        categoryLabel: FAVORITE_CATEGORY_LABEL,
        layerReferences: favoriteLayersSeen,
      };
      sortedCategoriesList = [favoriteCategory, ...sortedCategoriesList];
    }
  }

  // Annotate the categories with their collapsed states
  const sortedCategoriesWithCollapsedAnnotation =
    getCategoriesWithCollapsedAnnotation(
      sortedCategoriesList,
      isCollapsedByCategory,
      isSearchResult,
      defaultOpenCategory,
      activeLayerId,
    );

  return sortedCategoriesWithCollapsedAnnotation;
}

function filterLayersByCategory(
  categoryList: LayerListCategory[],
  filterFn: (layer: LayerReference) => boolean,
): LayerListCategory[] {
  const filteredCategoryLayers = categoryList.map(category => ({
    ...category,
    layerReferences: category.layerReferences.filter(({ layer }) =>
      filterFn(layer),
    ),
  }));

  return filteredCategoryLayers.filter(
    category => !_.isEmpty(category.layerReferences),
  );
}

// A layer can be a combination of selected/unselected, disabled, or active in the Layer Manager
// TODO: Mark disabled layers as well (ie: analysis outputs that haven't been run)
function getCategoriesWithActiveAnnotation(
  categoryList: LayerListCategory[],
  activeLayerId: string,
): LayerListCategory[] {
  return categoryList.map(
    (category: LayerListCategory): LayerListCategory => ({
      ...category,
      layerReferences: category.layerReferences.map(layerReference => ({
        ...layerReference,
        active: layerReference.layer.full_path === activeLayerId,
      })),
    }),
  );
}

function getCategoriesWithRestrictedAnnotation(
  categoryList: LayerListCategory[],
): LayerListCategory[] {
  return categoryList.map(
    (category: LayerListCategory): LayerListCategory => ({
      ...category,
      layerReferences: category.layerReferences.map(layerReference => ({
        ...layerReference,
      })),
    }),
  );
}

function getCategoriesWithCollapsedAnnotation(
  categoryList: LayerListCategory[],
  isCollapsedByCategory: LayerManagerCollapsedState,
  isSearchResult: boolean,
  defaultOpenCategory?: string,
  activeLayerId?: string,
): LayerListCategory[] {
  return categoryList.map((category): LayerListCategory => {
    // collapse by default
    let collapsed = true;
    if (isSearchResult) {
      // expand all categories if the user is viewing search results
      collapsed = false;
    } else if (
      (category.categoryKey === defaultOpenCategory ||
        // expand if a category's layer was set as active during search
        category.layerReferences.find(
          ref => ref.layer.full_path === activeLayerId,
        )) &&
      isCollapsedByCategory[category.categoryKey] === undefined
    ) {
      // expand the first category if the user is NOT searching,
      // and the user has not set a collapsed state themselves
      collapsed = false;
    } else if (isCollapsedByCategory[category.categoryKey] !== undefined) {
      // otherwise use whatever the user's defined collapsed state
      collapsed = isCollapsedByCategory[category.categoryKey];
    }

    return {
      ...category,
      collapsed,
    };
  });
}

export function makeHeader(
  categoryLabel: string,
  numLayers: number,
  tooltip?: string | JSX.Element,
) {
  const label = `${categoryLabel} (${numLayers})`;
  if (tooltip) {
    return (
      <div
        style={{
          justifyContent: 'space-between',
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
        }}>
        <div>{label}</div>
        <Tooltip content={tooltip} placement="top" className={tw('tw-mr-5')}>
          <Icon
            size={IconSizeNew.EXTRA_SMALL}
            className={tw('tw-inline', 'tw-m-1', 'tw-text-blueGray-600')}
            path={mdiInformation}
          />
        </Tooltip>
      </div>
    );
  }
  return label;
}
