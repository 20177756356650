import _ from 'lodash';
import { createSelector } from 'reselect';

import { makeGetFromPropsSelector } from 'uf/base/selector';
import { DataState, getData, isLoaded } from 'uf/data/dataState';
import { MapPositionEnvelope } from 'uf/explore/bookmarks';
import { MapPosition } from 'uf/map';
import { makeGetProjectById } from 'uf/projects/selectors';
import { makeUserDataKey } from 'uf/user';
import {
  getUserDataItemsInternalState,
  makeGetUserDataStateByPrefix,
} from 'uf/user/selectors/user';
import { TypedUserData } from 'uf/user/state';

export function makeGetProjectPosition() {
  return createSelector(makeGetProjectById(), (project): MapPosition => {
    if (_.isEmpty(project)) {
      return null;
    }
    return {
      key: project.full_path,
      lnglat: {
        lng: project.center_lng,
        lat: project.center_lat,
      },
      scale: null,
      pitch: 0,
      bearing: 0,
      name: null,
    };
  });
}
/**
 * Supply the user data prefix as { userDataPrefix: makeBookmarkPrefix(projectId) }
 */
export function makeGetUserBookmarks() {
  return createSelector(
    makeGetUserDataStateByPrefix('prefix', 'userKey'),
    (userDataStates): MapPosition[] => {
      return Object.values(userDataStates)
        .filter(userDataState => isLoaded(userDataState))
        .map(
          userDataState =>
            getData<TypedUserData<MapPositionEnvelope>>(userDataState).value,
        )
        .map(envelope => envelope.position);
    },
  );
}

/**
 * Get a DataState that represents the currently loaded bookmarks.
 */
export function makeGetUserBookmarksState() {
  return createSelector(
    makeGetFromPropsSelector<string, 'userKey'>('userKey'),
    makeGetFromPropsSelector<string, 'prefix'>('prefix'),
    getUserDataItemsInternalState,
    (userKey, prefix, userDataItemInternalState): DataState<null> => {
      const stateKey = makeUserDataKey(userKey, prefix);
      const userDataItemsState = userDataItemInternalState[stateKey];
      // Modify the data and promise field to ensure that consumers do not check
      // the data. They should only use this selector to check for loading and
      // loaded states.
      return {
        ...userDataItemsState,
        data: null,
        promise: null,
      };
    },
  );
}
