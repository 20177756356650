import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { t } from 'ttag';

import { postfixKeywithUniqId } from 'uf/base/keys';
import { typeAssertNever } from 'uf/base/never';
import { ProjectId } from 'uf/projects';
import useBindAction from 'uf/ui/base/useBindAction/useBindAction';
import { EditViewDialog } from 'uf/ui/views/EditViewDialog/EditViewDialog';
import { View } from 'uf/views';
import {
  duplicateView as duplicateViewAction,
  viewListActions,
} from 'uf/views/actions/index';

export enum ViewEditorMode {
  EDIT = 'edit',
  DUPLICATE = 'duplicate',
}
interface Props {
  projectId: ProjectId;
  /** Set this prop to open the dialog */
  show?: boolean;
  mode: ViewEditorMode;

  /** The view to edit or duplicate */
  view?: View;
  /** Fired *after* the view has been duplicated. Use this to clear the `cloneFromView` prop */
  onSaveView: (view: View, previousView: View) => void;
  /** If the user hits cancel, clear the `cloneFromView` prop */
  onCancel: () => void;
}
export const ViewEditor: FunctionComponent<Props> = props => {
  const {
    projectId,
    view: sourceView,
    onSaveView,
    onCancel,
    mode,
    show,
  } = props;

  // Create a copy of the cloned view for edit
  const candidateView = useLocalView(sourceView, mode);

  // The "final" view will be passed in here
  const duplicateView = useBindAction(duplicateViewAction);
  const onDuplicate = useCallback(
    (newView: View) => {
      duplicateView(projectId, sourceView?.key, newView);
      onSaveView?.(newView, sourceView);
    },
    [sourceView, duplicateView, projectId, onSaveView],
  );

  const saveView = useBindAction(viewListActions.updateItem);
  const onEdit = useCallback(
    (newView: View) => {
      saveView(sourceView, newView, projectId);
      onSaveView?.(newView, sourceView);
    },
    [onSaveView, projectId, saveView, sourceView],
  );

  const onChange = useMemo(() => {
    switch (mode) {
      case ViewEditorMode.DUPLICATE:
        return onDuplicate;
      case ViewEditorMode.EDIT:
        return onEdit;
      default:
        typeAssertNever(mode);
        return null;
    }
  }, [mode, onDuplicate, onEdit]);

  return (
    <EditViewDialog
      show={show}
      view={candidateView}
      onChange={onChange}
      onCancel={onCancel}
    />
  );
};

/** Make a local copy of the view, depending on what mode you're in */
function useLocalView(view: View, mode: ViewEditorMode) {
  const [localView, setLocalView] = useState(view);
  // Every time the source view is updated, we create a new candidate
  useEffect(() => {
    if (view) {
      switch (mode) {
        case ViewEditorMode.DUPLICATE: {
          const viewName = view.name;
          const defaultName = viewName ? t`${viewName} (copy)` : t`New view`;
          setLocalView({
            ...view,
            name: defaultName,
            key: postfixKeywithUniqId(view?.key),
          });
          break;
        }
        case ViewEditorMode.EDIT: {
          setLocalView(view);
          break;
        }
        default:
          typeAssertNever(mode);
      }
    } else {
      setLocalView(null);
    }
  }, [view, mode]);
  return localView;
}
