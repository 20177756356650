import { ProjectId } from 'uf/projects';
import { USER_FLOW_PREFIX, USER_PREF_PREFIX } from 'uf/user';

// return a key to store user preferences
export function makeUserPrefKey(prefKey: string) {
  return `${USER_PREF_PREFIX}:${prefKey}`;
}

// returns a key that is project specific for user preferences can be stored per project
export function makeProjectUserPrefKey(projectId: ProjectId, prefKey: string) {
  return `${USER_PREF_PREFIX}:${projectId}:${prefKey}`;
}

/**
 * Parse a userdata key.
 *
 * this is DEPRECATED. We should not be parsing our own keys.
 *
 * @param userDataKey composite key
 */
export function parseUserDataKey(userDataKey: string) {
  return userDataKey.split(':');
}

/**
 * Parse the key to get user prefs
 *
 * this is DEPRECATED. We should not be parsing our own keys.
 *
 * @param projectPrefKey
 */
export function parseProjectUserPrefKey(projectPrefKey: string) {
  const [userKey, prefix, projectId, prefKey] =
    parseUserDataKey(projectPrefKey);
  if (prefix !== USER_PREF_PREFIX) {
    // don't throw a console warning here since we expect to parse a lot of strings that might
    // not be pref keys
    return null;
  }
  return { prefix, projectId, prefKey, userKey };
}

/**
 * Generate a predicate that parses a user_data.
 *
 * thisis DEPRECATED. we should not be parsing our own keys.
 * @param keyToMatch
 */
export function makeMatchActionsWithPrefKey(keyToMatch: string) {
  return action => {
    const { prefKey } = parseProjectUserPrefKey(action.key) || ({} as any);
    return prefKey === keyToMatch;
  };
}

export function makeUserFlowKey(flowKey: string) {
  return `${USER_FLOW_PREFIX}:${flowKey}`;
}

export function getUserFlowName(dataKey: string): string {
  const [prefix, flowName] = parseUserDataKey(dataKey);
  if (prefix !== USER_FLOW_PREFIX) {
    // don't throw a console warning here since we expect to parse a lot of strings that might
    // not be flow keys
    return null;
  }
  return flowName;
}

// returns an object with a key/value pair to store in uesr data
export function makePrefValue<T>(value: T) {
  return { value };
}
