import { t } from 'ttag';

const categoryLabels = {
  land_use: t`Land Use`,
  transport: t`Transport & Circulation`,
  infrastructure: t`Infrastructure`,
  health: t`Health`,
  crime: t`Crime`,
  education: t`Education`,
  socio_demographic: t`Socio-Demographic`,
  conservation: t`Conservation`,
  parks_and_open_spaces: t`Parks & Open Space`,
  premium_data: t`Premium Data`,
  risk_and_resilience: t`Risk & Resilience`,
  environment_and_land_cover: t`Environment & Land Cover`,
  administrative_boundaries: t`Administrative Boundaries`,
  equity_and_env_justice: t`Equity & Environmental Justice`,
  undefined: t`Uncategorized`,
  airspace: t`Urban Air Mobility / Airspace`,
  resilience_insights: t`Resilience Insights`,
  // Base Canvas Inputs -> (put these instead in whichever relevant category above)
  // Analysis Inputs - (put these instead in whichever relevant category above)
};

const categoryDescriptions = {
  land_use: t`Layers describing locations and attributes for land use data including parcel reference data, points of interest, zoning, local land use codes etc`,
  transport: t`Datasets including nationwide transit lines, transit stops, roads and railways`,
  infrastructure: t`Supporting infrastructure including powerlines, dams, airports`,
  health: t`Datasets related to health, hospital and pharmacy locations, diseases and CalEnviroScreen`,
  crime: t`Crime stats`,
  education: t`Datasets related to schools and educational institutions`,
  socio_demographic: t`Datasets including census information etc`,
  conservation: t`Layers for habitat preservation, water demand, agricultural factors`,
  parks_and_open_spaces: t`Layers describing locations and attributes for green spaces, regional and local parks as well as national parks`,
  premium_data: t`Exclusive datasets that are not available through the standard UrbanFootprint subscription. Includes datasets like StreetLight transportation data.`,
  risk_and_resilience: t`National and regional datasets describing sea-rise, flood risk, fire hazard etc`,
  environment_and_land_cover: t`Datasets including wet lands, habitat, agriculture and carbon sequestration data`,
  administrative_boundaries: t`Layers describing congressional districts, census tracts and jurisdiction boundaries`,
  equity_and_env_justice: t`Layers including environmental justice, opportunity zones, fair market rents, etc`,
  undefined: t`No category defined`,
  airspace: t`Datasets related to air travel and airspace management`,
  resilience_insights: t`UrbanFootprint's curated, multi-dimensional data insights to inform decision making`,
};

export default function getCategoryLabel(categoryKey?: string) {
  return categoryLabels[categoryKey] || categoryKey;
}

export function getCategoryDescription(categoryKey?: string) {
  return (
    categoryDescriptions[categoryKey] ||
    categoryLabels[categoryKey] ||
    categoryKey
  );
}
export function getLayerCategoryKeys() {
  return Object.keys(categoryLabels).filter(
    category => category !== 'undefined',
  );
}
