import { Feature, GeoJsonGeometryTypes } from 'geojson';
import _ from 'lodash';
import { Layer, LngLat, Map, MapboxOptions, Point } from 'mapbox-gl';
import React, { FunctionComponent, useCallback, useMemo, useRef } from 'react';

import { EMPTY_ARRAY } from 'uf/base';
import { PolygonGeometry } from 'uf/base/geometry';
import { MapMode } from 'uf/map/mapmode';
import { MapView, Props as MapViewProps } from 'uf/ui/map/MapView/MapView';
import { DrawMode } from 'uf/ui/map/modes/draw';

import { makeDrawStyleLayers } from './drawingLayers';
import { useMode } from './useMode';
import { useMouseHover } from './useMouseHover';

interface Props extends MapViewProps {
  /** The "active" layer - the one the user is interacting with */
  activeLayerId?: string;
  activeLayerFeatureType: GeoJsonGeometryTypes;

  /** The style layers used for any user interaction - hovering, clicking, etc. */
  interactiveStyleLayers: Layer[];
  /** Style layers for the active layer */
  activeStyleLayers: Layer[];
  /** Style layers for selection on the active layer */
  selectionStyleLayers: Layer[];

  /** The interactive mode - i.e. pan, move, point select, etc */
  mapMode?: MapMode;

  /**
   * Fires when the map mode changes.
   * onMapStatusChanged(mode, prevMode, map)
   * @param mode - the current mode the map is in
   * @param prevMode - the previous mode
   * @param map - the mapboxgl map instance
   */
  onMapModeChanged?: (mode: MapMode, prevMode: MapMode, map: Map) => void;

  /** In polygon selection mode, when the user has drawn a polygon */
  onPolygonSelection?: (shape: PolygonGeometry, add: boolean) => void;

  /** In point selection mode, when the user has clicked on a feature */
  onPointSelection?: (features: Feature[], add: boolean) => void;

  /** In inspection mode, when the user has clicked on a feature */
  onInspection?: (point: Point, lngLat: LngLat, map: Map) => void;

  /** In draw mode, when the user has drawn a new feature */
  onDrawMode?: (draw: DrawMode) => void;
}

const InteractiveMapView: FunctionComponent<Props> = props => {
  const {
    styleLayers = EMPTY_ARRAY as Layer[],
    activeStyleLayers = EMPTY_ARRAY as Layer[],
    selectionStyleLayers = EMPTY_ARRAY as Layer[],
    onMapInitialized,
    mapMode,
    interactiveStyleLayers = EMPTY_ARRAY,
    onDrawMode,
    onPolygonSelection,
    onPointSelection,
    onInspection,
    onMapModeChanged,
    activeLayerId,
    activeLayerFeatureType,
  } = props;

  const mapRef = useRef<Map>(null);

  const drawStyleLayers = useDrawStyleLayers(
    activeLayerFeatureType,
    activeStyleLayers,
    selectionStyleLayers,
  );

  const setMapMode = useMode(
    mapRef.current,
    interactiveStyleLayers,
    drawStyleLayers,
    onPolygonSelection,
    onPointSelection,
    onInspection,
    onDrawMode,
    onMapModeChanged,
    mapMode,
    activeLayerId,
  );

  const initializeMouseEvents = useMouseHover(
    mapRef.current,
    interactiveStyleLayers,
  );

  const initializeMap = useInitialize(
    mapRef,
    onMapInitialized,
    initializeMouseEvents,
    mapMode,
    setMapMode,
  );

  const mapStyleLayers = useMemo(
    () => [...styleLayers, ...interactiveStyleLayers],
    [styleLayers, interactiveStyleLayers],
  );

  const mapProps = _.omit(props, ['styleLayers', 'interactiveStyleLayers']);

  return (
    <MapView
      {...mapProps}
      styleLayers={mapStyleLayers}
      onMapInitialized={initializeMap}
    />
  );
};

InteractiveMapView.defaultProps = {
  ...MapView.defaultProps,
  activeLayerId: null,
  interactiveStyleLayers: [],
  mapMode: MapMode.NORMAL,
  styleLayers: [],
};

export default InteractiveMapView;

function useInitialize(
  mapRef: React.MutableRefObject<Map>,
  onMapInitialized: (options: MapboxOptions, map: Map) => void,
  localInitialize: (map: Map) => void,
  mapMode: MapMode,
  setMapMode: (map: Map, mode: MapMode) => void,
) {
  const initializeMap = useCallback(
    (options: MapboxOptions, map: Map) => {
      mapRef.current = map;
      if (onMapInitialized) {
        onMapInitialized(options, map);
      }
      localInitialize(map);
      if (mapMode) {
        mapRef.current.once('load', () => {
          setMapMode(mapRef.current, mapMode);
        });
      }
    },
    [onMapInitialized, mapRef, mapMode, localInitialize, setMapMode],
  );
  return initializeMap;
}

function useDrawStyleLayers(
  activeLayerFeatureType: GeoJsonGeometryTypes,
  activeStyleLayers: Layer[],
  selectionStyleLayers: Layer[],
) {
  return useMemo(
    () =>
      makeDrawStyleLayers(
        activeLayerFeatureType,
        activeStyleLayers,
        selectionStyleLayers,
      ),
    [activeLayerFeatureType, activeStyleLayers, selectionStyleLayers],
  );
}
