import { makeListActionCreatorsWithExtra } from 'uf/base/listActionHelpers';
import { registerListPersistence } from 'uf/persistence/lists';
import { ProjectId } from 'uf/projects';
import { View, ViewId } from 'uf/views';
import {
  DUPLICATE_VIEW,
  DuplicateViewAction,
  ExtraViewParams,
  viewListActionTypes,
} from 'uf/views/ActionTypes';
import { makeGetProjectViewsList } from 'uf/views/selectors/views';

function makeExtraParams(projectId: ProjectId): ExtraViewParams {
  return {
    projectId,
  };
}

export const viewListActions = makeListActionCreatorsWithExtra<
  View,
  Parameters<typeof makeExtraParams>,
  ExtraViewParams
>(viewListActionTypes, makeExtraParams);

registerListPersistence('views', viewListActions, viewListActionTypes, {
  getKey({ projectId }) {
    return [projectId];
  },
  getValue({ projectId }, state) {
    const getProjectViewsList = makeGetProjectViewsList();
    const views = getProjectViewsList(state, { projectId });
    return views;
  },
});

export function duplicateView(
  projectId: ProjectId,
  sourceViewId: ViewId,
  newView: View,
): DuplicateViewAction {
  return { type: DUPLICATE_VIEW, projectId, sourceViewId, newView };
}
