import classNames from 'classnames';
import React from 'react';

import panelStyles from 'uf/styles/panels.module.css';

const defaultStyle: React.CSSProperties = {
  position: 'absolute',
};
interface MapPanelProps {
  style?: React.CSSProperties;
  heading?: React.ReactNode;
  headingClassName?: string;
  bodyClassName?: string;
}
/**
 * The default react-bootstrap <Panel> does not provide an interface for changing the
 * styles of the heading or body. <MapPanel> gives us this flexibility.
 */
const MapPanel: React.FunctionComponent<MapPanelProps> = ({
  style,
  heading,
  headingClassName,
  bodyClassName,
  children,
}) => {
  const panelStyle = { ...defaultStyle, ...style };
  return (
    <div
      className={classNames('panel', 'panel-default', panelStyles.card1)}
      style={panelStyle}>
      {heading && (
        <div className={classNames('panel-heading', headingClassName)}>
          {heading}
        </div>
      )}
      <div className={classNames('panel-body', bodyClassName)}>{children}</div>
    </div>
  );
};
export default MapPanel;
