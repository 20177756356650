import colors from 'material-colors';

import {
  CuratedColumnSymbology,
  CUSTOM_THEME,
  DEFAULT_OPACITY,
  InterpolationTypes,
  PaintColorTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/public/dataset/fema_national_flood_hazard_layer_areas': {
    fld_zone: [
      {
        type: SymbologyTypes.FILL,
        paint: {
          'fill-color': {
            property: 'fld_zone',
            type: PaintColorTypes.CATEGORICAL,
            stops: [
              {
                value: 'C',
                label: 'C - Area Of Minimal Flood Hazard',
                color: colors.lightGreen[300],
              },
              {
                value: 'X',
                label: 'X - Area Of Minimal Flood Hazard',
                color: colors.lightGreen[300],
              },
              {
                value: 'B',
                label: 'B - Area Of Moderate Flood Hazard',
                color: colors.amber[500],
              },
              {
                value: 'AH',
                label: 'AH - Areas With A 1% Annual Chance Of Shallow Flooding',
                color: colors.orange[400],
              },
              {
                value: 'AO',
                label: 'AO - River Or Stream Flood Hazard Areas',
                color: colors.orange[500],
              },
              {
                value: 'A',
                label: 'A - Areas With A 1% Annual Chance Of Flooding',
                color: colors.deepOrange[500],
              },
              {
                value: 'AE',
                label: 'AE - Base Floodplain Elevation',
                color: colors.indigo[500],
              },
              {
                value: 'AR',
                label: 'AR - Areas With A Temporarily Increased Flood Risk',
                color: colors.pink[900],
              },
              {
                value: 'A99',
                label:
                  'A99 - Areas With A 1% Annual Chance - Protected By Federal Flood Control System',
                color: colors.purple[500],
              },
              {
                value: 'V',
                label:
                  'V - Coastal Areas With >= 1% Chance Of Flooding And Additional Storm Wave Hazard',
                color: colors.red[500],
              },
              {
                value: 'VE',
                label:
                  'VE - Coastal Areas With >= 1% Chance Of Flooding And Additional Storm Wave Hazard',
                color: colors.red[500],
              },
              {
                value: 'D',
                label: 'D - Undetermined Risk Area',
                color: colors.grey[400],
              },
              {
                value: 'AREA NOT INCLUDED',
                label: 'Area not included',
                color: colors.grey[400],
              },
            ],
          },
          'fill-opacity': DEFAULT_OPACITY,
        },
        display_hints: {
          scale: SymbologyScales.CATEGORICAL,
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          theme: CUSTOM_THEME,
        },
      },
      {
        type: SymbologyTypes.LINE,
        paint: {
          'line-color': {
            property: 'fld_zone',
            type: PaintColorTypes.CATEGORICAL,
            stops: [
              { value: 'C', color: colors.lightGreen[300] },
              { value: 'X', color: colors.lightGreen[300] },
              { value: 'B', color: colors.amber[500] },
              { value: 'AH', color: colors.orange[400] },
              { value: 'AO', color: colors.orange[500] },
              { value: 'A', color: colors.deepOrange[500] },
              { value: 'AE', color: colors.indigo[500] },
              { value: 'AR', color: colors.pink[900] },
              { value: 'A99', color: colors.purple[500] },
              { value: 'V', color: colors.red[500] },
              { value: 'VE', color: colors.red[500] },
              { value: 'D', color: colors.grey[400] },
              { value: 'AREA NOT INCLUDED', color: colors.grey[400] },
            ],
          },
          'line-opacity': DEFAULT_OPACITY,
        },
        display_hints: {
          scale: SymbologyScales.CATEGORICAL,
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          theme: CUSTOM_THEME,
        },
      },
    ],
  },
};

export default symbologies;
