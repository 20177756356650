import { GeoJsonGeometryTypes } from 'geojson';
import _ from 'lodash';

import { LayerColumn, LayerMetadata, LayerReference } from 'uf-api';
import { parseFullPath } from 'uf/base/dataset';
import { isCategoricalColumn, isNumericColumn } from 'uf/layers/metadata';

import { LayerId } from './';

/**
 *  returns the parent layerId for the scoped layer.
 */
export function getParentLayerId(layer: LayerReference) {
  const parentLayerId = layer?.details?.display_hints?.parent?.full_path;
  return parentLayerId;
}

export function getParentLayerVersion(layer: LayerReference) {
  return layer?.details?.display_hints?.parent?.parent_version;
}

export function isPaintedLayer(layerMetadata: LayerMetadata | LayerReference) {
  if (!layerMetadata) {
    return false;
  }

  return isPaintedLayerType(layerMetadata.layer_type);
}

export function isPaintedLayerId(layerId: LayerId) {
  const { type: layerType } = parseFullPath(layerId);
  return isPaintedLayerType(layerType);
}

export function isPaintedLayerType(layerType: string) {
  return ['painted', 'canvas'].includes(layerType);
}

export function isCanvasLayerId(layerId: LayerId) {
  const { type: layerType } = parseFullPath(layerId);
  return isCanvasLayerType(layerType);
}

export function isCanvasLayerType(layerType: string) {
  return layerType === 'canvas';
}

export function isAnalysisLayer(layerMetadata: LayerMetadata | LayerReference) {
  if (!layerMetadata) {
    return false;
  }

  return layerMetadata.layer_type === 'analysis';
}

export function isLayerMappable(layerMetadata: LayerMetadata) {
  return layerMetadata.geometry_type_key;
}

export function isMappable(layer: LayerReference): boolean {
  return layer?.details?.tile_url || layer?.details?.display_hints?.has_geojson;
}

export function isNotMappable(layer: LayerReference) {
  return !isMappable(layer);
}

export function isColumnMappable(column: LayerColumn) {
  return isNumericColumn(column) || isCategoricalColumn(column);
}
export const LineGeometryTypes: GeoJsonGeometryTypes[] = [
  'MultiLineString',
  'LineString',
];
export const PolygonGeometryTypes: GeoJsonGeometryTypes[] = [
  'MultiPolygon',
  'Polygon',
];
export const PointGeometryTypes: GeoJsonGeometryTypes[] = [
  'MultiPoint',
  'Point',
];
export const isLineLayer = makeGeometryTypePredicate(LineGeometryTypes);
export const isPolygonLayer = makeGeometryTypePredicate(PolygonGeometryTypes, [
  'analysis',
  'painted',
]);
export const isPointLayer = makeGeometryTypePredicate(PointGeometryTypes);

export function getUfGeometryType(
  layerMetadata: LayerMetadata,
): GeoJsonGeometryTypes {
  const ufGeometryType: GeoJsonGeometryTypes =
    layerMetadata?.display_hints?.uf_geometry_type;
  if (isLineLayer(layerMetadata) || isPointLayer(layerMetadata)) {
    return ufGeometryType;
  }

  // Analysis layers don't currently have a uf_geometry_type, but
  // we know they are canvas-based, so we can assume they're polygons.
  // TODO: Remove the isAnalysisLayer check after #12430 is fixed.
  if (isPolygonLayer(layerMetadata) || isAnalysisLayer(layerMetadata)) {
    return ufGeometryType || 'MultiPolygon';
  }

  // Unhinted layers should be treated as polygons.
  // This constrains weird symbology issues to just Point layers,
  // which don't have a `line` or `fill` symbology.
  return 'MultiPolygon';
}

// Exported for testing only
export function makeGeometryTypePredicate(
  geometryTypes: GeoJsonGeometryTypes[],
  layerTypes: string[] = [],
) {
  return (layerMetadata: LayerMetadata): boolean => {
    if (_.isEmpty(layerMetadata)) {
      return false;
    }

    // Either the display hint matches
    const matchesDisplayHint = geometryTypes.includes(
      layerMetadata?.display_hints?.uf_geometry_type,
    );

    // Or the layer type matches, this is hack until canvases and analysis have uf_geometry_type
    const matchesLayerType = layerTypes.includes(
      parseFullPath(layerMetadata.full_path).type,
    );

    return matchesDisplayHint || matchesLayerType;
  };
}
