import { createSelector } from 'reselect';

import { LayerMetadata, LayerStats } from 'uf-api';
import { makeGetFromPropsSelector } from 'uf/base/selector';
import { DataState, getData, isLoaded } from 'uf/data/dataState';
import { ColumnKey, LayerId } from 'uf/layers';
import {
  makeGetLayerMetadataState,
  makeGetLayerMetadataStateGetter,
} from 'uf/layers/selectors/metadata';
import { ProjectId } from 'uf/projects';
import { LayerColumnSymbology } from 'uf/symbology';
import { getCleanedSymbologyDataState } from 'uf/symbology/selectors/curated/clean';
import {
  makeGetCuratedSymbologyLoadingState,
  makeGetCuratedSymbologyLoadingStateGetter,
} from 'uf/symbology/selectors/curated/loadingState';
import {
  makeGetSymbologyStatsState,
  makeGetSymbologyStatsStateGetter,
} from 'uf/symbology/selectors/stats';
import AnalysisSymbologies from 'uf/symbology/styles/AnalysisSymbologies';
import { makeGetUserFlag } from 'uf/user/selectors/flags';

/**
 * Lookup a curated symbology by exact match on module_key display hint.
 */
export function makeGetCuratedSymbologyFromAnalysisSymbologiesFile() {
  return createSelector(
    makeGetCuratedSymbologyLoadingState(),
    makeGetFromPropsSelector<LayerId, 'layerId'>('layerId'),
    makeGetFromPropsSelector<ColumnKey, 'columnKey'>('columnKey'),
    makeGetLayerMetadataState(),
    makeGetSymbologyStatsState(),
    makeGetUserFlag('symbology-symbols'),
    (
      loadingState,
      layerId,
      columnKey,
      layerMetadataState,
      layerStatsState,
      hasSymbolSupportFlag,
    ) => {
      return getCuratedSymbologyFromAnalysisSymbologiesFile(
        loadingState,
        layerMetadataState,
        layerStatsState,
        layerId,
        columnKey,
        hasSymbolSupportFlag,
      );
    },
  );
}

export function makeGetCuratedSymbologyFromAnalysisSymbologiesFileGetter() {
  return createSelector(
    makeGetCuratedSymbologyLoadingStateGetter(),
    makeGetLayerMetadataStateGetter(),
    makeGetSymbologyStatsStateGetter(),
    makeGetUserFlag('symbology-symbols'),
    (
        getLoadingState,
        getLayerMetadataState,
        getLayerStatsState,
        hasSymbolSupportFlag,
      ) =>
      (projectId: ProjectId, layerId: LayerId, columnKey: ColumnKey) => {
        const loadingState = getLoadingState(projectId, layerId, columnKey);
        const layerMetadataState = getLayerMetadataState(layerId);
        const statsState = getLayerStatsState(projectId, layerId, columnKey);
        return getCuratedSymbologyFromAnalysisSymbologiesFile(
          loadingState,
          layerMetadataState,
          statsState,
          layerId,
          columnKey,
          hasSymbolSupportFlag,
        );
      },
  );
}

function getCuratedSymbologyFromAnalysisSymbologiesFile(
  loadingState: DataState<null>,
  layerMetadataState: DataState<LayerMetadata>,
  layerStatsState: DataState<LayerStats>,
  layerId: LayerId,
  columnKey: ColumnKey,
  hasSymbolSupportFlag: boolean,
): DataState<LayerColumnSymbology[]> {
  if (!isLoaded(loadingState)) {
    return loadingState;
  }

  const layerMetadata = getData(layerMetadataState);
  const moduleKey = layerMetadata?.display_hints?.module_key;
  if (!moduleKey) {
    return loadingState;
  }

  /*
   * NOTE: Unlike the other curated symbology selectors, analysis layers should not check for
   * root layer curated symbology because:
   *
   * a) layers of type `analysis` don't have a root layer display hint, and
   * b) dynamic layers made from analysis layers have the wrong root layer,
   *    ie: `block_canvas_development_layer_v2`, and
   * c) dynamic layers preserve the module_key display hint so we can just use that
   */
  const hardcodedSymbologies = findAnalysisSymbologies(moduleKey, columnKey);

  if (hardcodedSymbologies) {
    const layerStats = getData(layerStatsState);
    return getCleanedSymbologyDataState(
      layerId,
      columnKey,
      layerMetadata,
      layerStats,
      hardcodedSymbologies,
      loadingState,
      hasSymbolSupportFlag,
    );
  }

  return loadingState;
}

function findAnalysisSymbologies(moduleKey: string, columnKey: ColumnKey) {
  return AnalysisSymbologies?.[moduleKey]?.[columnKey];
}
