import _ from 'lodash';

/**
 * @param current - the complete set of items that currently exist
 * @param next - the complete set of items after an update
 * @param onAdd - add(Object nextItem, Object currentItem)
 * @param onRemove - remove(Object currentItem)
 * @param onMove - move(Object currentItem, Object beforeItem)
 */
export default function updateListOrder<T>(
  current: T[],
  next: T[],
  onAdd: (item: T, zoomExtentsByLayer, before?: T) => void,
  onRemove: (item: T) => void,
  onMove: (item: T, before: T) => void,
  isEqual: (a: T, b: T) => boolean = _.isEqual,
  zoomExtentsByLayer?: Record<string, { minZoom: number; maxZoom: number }>,
) {
  let c = 0;
  let n = 0;

  // we make a copy of the current layers array so that
  // we can mutate it freely as we update the layers on the map.
  const currentCopy = [...current];

  while (n < next.length && c < currentCopy.length) {
    // skip this item if the two items are identical
    if (isEqual(next[n], currentCopy[c])) {
      c += 1;
      n += 1;
      continue;
    }

    const nextItem = next[n];
    // check if the next layers contain the current one
    const indexOfNextItemInCurrent = _.findIndex(currentCopy, o =>
      isEqual(o, nextItem),
    );
    const currentListContainsNextItem = indexOfNextItemInCurrent > -1;

    const currentCopyItem = currentCopy[n];
    // check if the current layers contain the next one
    const indexOfCurrentItemInNext = _.findIndex(next, o =>
      isEqual(o, currentCopyItem),
    );
    const nextListContainsCurrentItem = indexOfCurrentItemInNext > -1;

    // Remove the item if it doesn't exist in next
    if (!nextListContainsCurrentItem) {
      const currentItem = currentCopy[c];
      _.remove(currentCopy, o => isEqual(o, currentItem));
      if (onRemove) {
        onRemove(currentItem);
      }
      continue;
    }

    // Move the item to the correct spot if it exists in next
    if (currentListContainsNextItem) {
      const currentItem = currentCopy[indexOfNextItemInCurrent];
      const beforeItem = currentCopy[c];
      const currentItemIndex = _.findIndex(currentCopy, o =>
        isEqual(o, currentItem),
      );
      const beforeItemIndex = _.findIndex(currentCopy, o =>
        isEqual(o, beforeItem),
      );
      move(currentCopy, currentItemIndex, beforeItemIndex);
      if (onMove) {
        onMove(currentItem, beforeItem);
      }
      continue;
    }

    // Add the item to the correct spot if it doesn't exist in current
    if (!currentListContainsNextItem) {
      const beforeItem = currentCopy[c];
      const index = _.findIndex(currentCopy, o => isEqual(o, beforeItem));
      currentCopy.splice(index, 0, next[n]);
      if (onAdd) {
        onAdd(next[n], zoomExtentsByLayer, beforeItem);
      }
      continue;
    }
  }

  if (n < next.length) {
    // add any remaining layers from next
    while (n < next.length) {
      currentCopy.push(next[n]);
      if (onAdd) {
        onAdd(next[n], zoomExtentsByLayer);
      }
      n += 1;
    }
  } else if (currentCopy.length > next.length) {
    // remove any unnecessary layers from current
    while (currentCopy.length > next.length) {
      const currentItem = currentCopy[c];
      _.remove(currentCopy, o => isEqual(o, currentItem));
      if (onRemove) {
        onRemove(currentItem);
      }
    }
  }

  // Return the modified copy. It should look identical to `next`.
  return currentCopy;
}

// EXPORTED FOR TESTING ONLY
export function move<T>(array: T[], oldIndex: number, newIndex: number) {
  const n = newIndex >= array.length ? array.length - 1 : newIndex;
  array.splice(n, 0, array.splice(oldIndex, 1)[0]);
  return array;
}
