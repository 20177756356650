import { AnyLayout, Expression, SymbolLayout } from 'mapbox-gl';
import { createSelector } from 'reselect';
import { LayerMetadata, LayerStats } from 'uf-api';
import { assertNever } from 'uf/base/never';
import { DataState, getData } from 'uf/data/dataState';
import { ColumnKey, LayerId, LayerVersion } from 'uf/layers';
import { isDefaultGeoColumnKey } from 'uf/layers/geometryKey';
import {
  getColumnMetadata,
  isCategoricalColumn,
  isNumericColumn,
} from 'uf/layers/metadata';
import { containsStatsNulls } from 'uf/layers/nullStats';
import { makeGetLayerMetadataStateGetter } from 'uf/layers/selectors/metadata';
import { findColumnKeyStats } from 'uf/layers/stats';
import { StyleLayerInfo, UFMapLayer } from 'uf/map';
import { defaultLineLayout } from 'uf/map/lines';
import { parseCategoricalPaintProperty } from 'uf/map/stylelayers/categorical';
import { parseNumericPaintProperty } from 'uf/map/stylelayers/numeric';
import {
  getPaintFilter,
  getPaintNullFilter,
  makeZeroStyleLayers,
  StyleLayerMetadata,
} from 'uf/map/stylelayers/stylelayers';
import { makeSourceId } from 'uf/map/tileSources';
import { ProjectId } from 'uf/projects';
import { LegacyVirtualLayerId } from 'uf/projects/virtualLayers';
import {
  isLineSymbology,
  LayerColumnSymbology,
  SymbologyScales,
  SymbologyTypes,
  SymbolPlacement,
  Symbols,
  SymbolSymbologyLayoutProperty,
} from 'uf/symbology';
import { DivideByColumnKey } from 'uf/symbology/divideByColumn';
import {
  isExtrusionSymbology,
  symbologyTypeSupportsColor,
  symbologyTypeSupportsOpacity,
} from 'uf/symbology/helpers';
import { makeGetSymbologyStateGetter } from 'uf/symbology/selectors';
import { makeGetDivideByColumnGetter } from 'uf/symbology/selectors/divideByColumn';
import { makeGetSymbologyStatsStateGetter } from 'uf/symbology/selectors/stats';
import { makeStyleSymbologyId } from 'uf/symbology/spec/id';
import { ViewId } from 'uf/views';
import warning from 'warning';

/**
 * Gets the style layers for the given layer. This is a bare-bones selector,
 * interpreting only the fields in symbology.
 *
 * Other selectors may use this to create additional style layers, eg: Adding
 * "selection outlines" or "painted only" styling when consumed by uf/explore.
 */

export function makeGetStyleLayerInfoGetter() {
  return createSelector(
    makeGetLayerMetadataStateGetter(),
    makeGetSymbologyStatsStateGetter(),
    makeGetSymbologyStateGetter(),
    makeGetDivideByColumnGetter(),
    (getMetadataState, getStatsState, getSymbologyState, getDivideByColumn) =>
      (
        projectId: ProjectId,
        viewId: ViewId,
        layerId: LayerId,
        virtualLayerId: LegacyVirtualLayerId,
        columnKey: ColumnKey,
        layerVersion: LayerVersion,
      ): StyleLayerInfo => {
        const metadataState = getMetadataState(layerId);
        const divideByColumn = getDivideByColumn(projectId, layerId, columnKey);
        const statsState = getStatsState(projectId, layerId, columnKey);
        const symbologyState = getSymbologyState({
          projectId,
          viewId,
          layerId,
          virtualLayerId,
          columnKey,
        });
        return getStyleLayerInfo(
          layerId,
          columnKey,
          layerVersion,
          metadataState,
          statsState,
          symbologyState,
          divideByColumn,
        );
      },
  );
}

function getStyleLayerInfo(
  layerId: LayerId,
  columnKey: ColumnKey,
  layerVersion: LayerVersion,
  metadataState: DataState<LayerMetadata>,
  statsState: DataState<LayerStats>,
  symbologyState: DataState<LayerColumnSymbology[]>,
  divideByColumn: DivideByColumnKey,
): StyleLayerInfo {
  const symbologies = getData(symbologyState, null);
  if (!symbologies) {
    return null;
  }

  const metadata = getData(metadataState);
  const stats = getData(statsState);
  const styleLayers = getStyleLayers(
    layerId,
    columnKey,
    layerVersion,
    metadata,
    symbologies,
    stats,
    divideByColumn,
  );

  const columnMetadata = getColumnMetadata(metadata, columnKey);

  return {
    layerId,
    columnKey,
    // The NONE column is categorical, but doesn't have a metatype so we
    // treat it specially here
    metatype: isDefaultGeoColumnKey(columnKey)
      ? 'categorical'
      : columnMetadata.metatype,
    version: layerVersion,
    styleLayers,
  };
}

function getStyleLayers(
  layerId: LayerId,
  columnKey: ColumnKey,
  layerVersion: LayerVersion,
  metadata: LayerMetadata,
  symbologies: LayerColumnSymbology[],
  layerStats: LayerStats,
  divideByColumn: DivideByColumnKey,
): UFMapLayer[] {
  const sourceId = makeSourceId(
    layerId,
    layerVersion,
    columnKey,
    divideByColumn,
  );

  const columnMetadata = getColumnMetadata(metadata, columnKey);
  const columnStats = findColumnKeyStats(layerStats, columnKey);
  const containsNulls = containsStatsNulls(columnStats);

  // The NONE column is categorical, but doesn't have a metatype so we treat it specially here
  if (isDefaultGeoColumnKey(columnKey) || isCategoricalColumn(columnMetadata)) {
    return makeCategoricalStyleLayers(
      layerId,
      sourceId,
      symbologies,
      columnKey,
      containsNulls,
    );
  }

  // sometimes column stats can be missing, possibly a bad response from the server
  if (isNumericColumn(columnMetadata) && columnStats) {
    const styleLayers = makeNumericStyleLayers(
      layerId,
      columnKey,
      sourceId,
      symbologies,
      columnStats.numeric.min,
      columnStats.numeric.max,
      divideByColumn,
      containsNulls,
    );
    return styleLayers;
  }

  warning(
    !!columnMetadata.metatype,
    `Unable to generate style layers for layer: ${layerId}, column: ${columnKey}.
     Is the metatype: ${columnMetadata.metatype} correct?`,
  );
}

function makeCategoricalStyleLayers(
  layerId: LayerId,
  sourceId: string,
  symbologies: LayerColumnSymbology[],
  columnKey: ColumnKey,
  containsNulls: boolean,
): UFMapLayer[] {
  const dataStyleLayers: UFMapLayer[] = symbologies.map(
    (symbology): UFMapLayer => {
      const paint = parseCategoricalPaintProperty(symbology, columnKey);

      const uf: StyleLayerMetadata = {
        layerId,
        columnKey,
      };
      const styleLayer: UFMapLayer = {
        id: symbology.id,
        type: symbology.type,
        source: sourceId,
        'source-layer': layerId,
        paint,
        metadata: { uf },
      };

      const filters: Expression[] = [];
      const basicGeometryFilter = getPaintFilter(symbology.type);
      if (basicGeometryFilter) {
        filters.push(basicGeometryFilter);
      }

      const nullFilter = getPaintNullFilter(columnKey);
      if (!isDefaultGeoColumnKey(columnKey) && containsNulls) {
        filters.push(nullFilter);
      }

      if (filters.length) {
        styleLayer.filter = ['all', ...filters];
      }

      const layout: AnyLayout = makeLayoutProperty(symbology.layout);
      if (layout) {
        styleLayer.layout = layout;
      }

      if (isLineSymbology(symbology)) {
        styleLayer.layout = defaultLineLayout;
      }

      return styleLayer;
    },
  );

  // The symbology can contain null categories, but mapbox don't support styling
  // multiple data types in a single style layer, we have to do that in a
  // separate style layer.
  if (!containsNulls) {
    return dataStyleLayers;
  }

  const nullStyleLayers = makeNullStyleLayers(
    symbologies,
    layerId,
    columnKey,
    sourceId,
  );

  return [...dataStyleLayers, ...nullStyleLayers];
}

export function makeNullStyleLayerId(symbologyId: string) {
  return `${symbologyId}|null-layer`;
}

function makeNullStyleLayers(
  symbologies: LayerColumnSymbology[],
  layerId: LayerId,
  columnKey: ColumnKey,
  sourceId: string,
): UFMapLayer[] {
  return symbologies.map(symbology => {
    const paint = parseCategoricalPaintProperty(symbology, columnKey, {
      nullsOnly: true,
    });

    const uf: StyleLayerMetadata = {
      layerId,
      columnKey,
    };
    const styleLayer: UFMapLayer = {
      id: makeNullStyleLayerId(symbology.id),
      type: symbology.type,
      source: sourceId,
      'source-layer': layerId,
      paint,
      metadata: { uf },
    };

    const basicGeometryFilter = getPaintFilter(symbology.type);
    const nullFilter = getPaintNullFilter(columnKey, true);
    const filter: Expression = basicGeometryFilter
      ? ['all', basicGeometryFilter, nullFilter]
      : undefined;
    if (filter) {
      styleLayer.filter = filter;
    }

    const layout: AnyLayout = makeLayoutProperty(symbology.layout);
    if (layout) {
      styleLayer.layout = layout;
    }

    if (isLineSymbology(symbology)) {
      styleLayer.layout = defaultLineLayout;
    }

    return styleLayer;
  });
}

function makeNumericStyleLayers(
  layerId: LayerId,
  columnKey: ColumnKey,
  sourceId: string,
  symbologies: LayerColumnSymbology[],
  min: number,
  max: number,
  divideByColumn: DivideByColumnKey,
  containsNulls: boolean,
): UFMapLayer[] {
  const dataStyleLayers: UFMapLayer[] = [];
  symbologies.forEach(symbology => {
    const styleLayerType = isExtrusionSymbology(symbology)
      ? 'fill-extrusion'
      : symbology.type;

    const paint = parseNumericPaintProperty(
      columnKey,
      symbology,
      min,
      max,
      divideByColumn,
    );

    const styleLayerId = makeStyleSymbologyId(
      layerId,
      columnKey,
      // HACK: currently fill-extrusion is a mapbox-only style, not part of
      // symbology, but we need a unique symbology key here.
      isExtrusionSymbology(symbology)
        ? ('fill-extrusion' as SymbologyTypes)
        : symbology.type,
      divideByColumn,
    );

    const uf: StyleLayerMetadata = {
      layerId,
      columnKey,
    };

    const styleLayer: UFMapLayer = {
      id: styleLayerId,
      type: styleLayerType,
      source: sourceId,
      'source-layer': layerId,
      paint,
      metadata: { uf },
    };

    // Filters
    const filters: Expression[] = [];
    const basicGeometryFilter = getPaintFilter(symbology.type);
    if (basicGeometryFilter) {
      filters.push(basicGeometryFilter);
    }
    const nullFilter = getPaintNullFilter(columnKey);
    if (containsNulls) {
      filters.push(nullFilter);
    }
    if (filters.length) {
      styleLayer.filter = ['all', ...filters];
    }

    // Layout
    const layout: AnyLayout = makeLayoutProperty(symbology.layout);
    if (layout) {
      styleLayer.layout = layout;
    } else if (isLineSymbology(symbology)) {
      styleLayer.layout = defaultLineLayout;
    }

    if (
      !symbology.display_hints.showZero &&
      symbology.display_hints.scale === SymbologyScales.NUMERIC
    ) {
      const [filteredStyleLayer, zeroStyleLayer] = makeZeroStyleLayers(
        styleLayer,
        columnKey,
        symbologyTypeSupportsOpacity(symbology.type),
        symbologyTypeSupportsColor(symbology.type),
      );
      dataStyleLayers.push(filteredStyleLayer, zeroStyleLayer);
    } else {
      dataStyleLayers.push(styleLayer);
    }
  });

  // The symbology can contain null categories, but mapbox don't support styling
  // multiple data types in a single style layer, we have to do that in a
  // separate style layer.
  if (!containsNulls) {
    return dataStyleLayers;
  }

  const nullStyleLayers = makeNullStyleLayers(
    symbologies,
    layerId,
    columnKey,
    sourceId,
  );

  return [...dataStyleLayers, ...nullStyleLayers];
}

const lineOrientedSymbolLayout: SymbolLayout = {
  'icon-rotation-alignment': 'map',
  'symbol-placement': 'line',
  'symbol-spacing': 50,
  'icon-offset': [0, 10],
  'icon-allow-overlap': true,
  // 'icon-ignore-placement': true,
};

const onLineSymbolLayout: SymbolLayout = {
  'icon-rotation-alignment': 'viewport',
  'symbol-placement': 'line',

  // Note sure about these
  'icon-allow-overlap': true,
  'icon-ignore-placement': true,
};

// TODO: deal with points vs polygons/lines?
const centerSymbolLayout: SymbolLayout = {
  'icon-rotation-alignment': 'viewport',
  'symbol-placement': 'line-center',

  // Note sure about these
  'icon-allow-overlap': true,
  'icon-ignore-placement': true,
};

const pointSymbolLayout: SymbolLayout = {
  'symbol-placement': 'point',
  'icon-allow-overlap': true,
};

function makeLayoutProperty(layout: SymbolSymbologyLayoutProperty): AnyLayout {
  if (!layout) {
    return null;
  }

  let result: SymbolLayout;
  switch (layout.placement) {
    case SymbolPlacement.FOLLOW_LINE:
      result = { ...lineOrientedSymbolLayout };
      break;
    case SymbolPlacement.ON_LINE:
      result = { ...onLineSymbolLayout };
      break;
    case SymbolPlacement.CENTER:
      result = { ...centerSymbolLayout };
      break;
    case SymbolPlacement.POINT:
      result = { ...pointSymbolLayout };
      break;
    default:
      assertNever(layout.placement);
  }
  switch (layout.symbol) {
    case Symbols.CHEVRON_RIGHT:
      result['icon-image'] = 'chevron-right';
      break;
    case Symbols.HOSPITAL_BUILDING:
      result['icon-image'] = 'hospital-building';
      break;
    case Symbols.SCHOOL:
      result['icon-image'] = 'school';
      break;
    default:
      assertNever(layout.symbol);
  }

  return result;
}
