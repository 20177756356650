import { Button } from '@blueprintjs/core';
import { mdiChevronDown, mdiChevronRight } from '@mdi/js';
import Icon from '@mdi/react';
import classNames from 'classnames';
import React, { MouseEventHandler } from 'react';
import { Box } from 'react-layout-components';
import { t } from 'ttag';

import rootStyles from 'uf/styles/root.module.css';
import textStyles from 'uf/styles/text.module.css';
import { IconSizeNew } from 'uf/ui/base/icon';

import styles from './CollapsibleList.module.css';

interface CollapsibleListHeaderProps {
  collapsed: boolean;
  headerClassName: string;
  headerDataAttributes: Record<string, string>;
  onCollapse: MouseEventHandler;
}

const CollapsibleListHeader: React.FunctionComponent<CollapsibleListHeaderProps> =
  ({
    onCollapse,
    collapsed,
    headerClassName,
    headerDataAttributes,
    children,
  }) => {
    const collapseButtonClass = classNames(
      rootStyles.veryThinPadding,
      rootStyles.veryThinMarginRight,
      textStyles.activeColor,
      styles.collapseButton,
      collapsed ? 'CollapseButton-expand' : 'CollapseButton-collapse',
    );

    const collapseIconPath = collapsed ? mdiChevronRight : mdiChevronDown;

    return (
      <Box
        alignItems="center"
        className={classNames(
          styles.header,
          rootStyles.veryThinPaddingVertical,
          headerClassName,
        )}
        {...headerDataAttributes}>
        <Button
          minimal
          small
          className={collapseButtonClass}
          icon={<Icon path={collapseIconPath} size={IconSizeNew.SMALL} />}
          onClick={onCollapse}>
          <i title={t`Minimize`} className={collapseIconPath} />
        </Button>
        {children}
      </Box>
    );
  };

export default CollapsibleListHeader;
