/**
 * UrbanFootprint App API
 * API for serving UrbanFootprint Application
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * A restriction that applies to a layer.
 */
export interface LayerRestriction<> {
  /**
   * Any additional information about the restriction.
   */
  details?: string;
  /**
   * The type of restriction.
   */
  restriction_type: LayerRestriction.RestrictionTypeEnum;
}
export namespace LayerRestriction {
  export type RestrictionTypeEnum = 'layer.export' | 'layer.combined_export';
  export const RestrictionTypeEnum = {
    Export: 'layer.export',
    CombinedExport: 'layer.combined_export',
  } as const;
}
