import colors from 'material-colors';

import {
  CuratedColumnSymbology,
  CUSTOM_THEME,
  DistributionTypes,
  InterpolationTypes,
  PaintColorTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

interface TransiLineLayer {
  layerPath: string;
}

const styleTransitLineLayerList: TransiLineLayer[] = [
  {
    layerPath: '/public/databank/transit_lines_proc_v2',
  },
  {
    layerPath: '/public/databank/transit_lines_proc_covid',
  },
];

const symbologies: Record<
  string,
  Record<string, CuratedColumnSymbology[]>
> = {};

styleTransitLineLayerList.forEach(transitLineLayer => {
  symbologies[transitLineLayer.layerPath] = {
    mode: [
      {
        type: SymbologyTypes.LINE,
        paint: {
          'line-color': {
            default: 'transparent',
            property: 'mode',
            stops: [
              {
                color: '#C7231D',
                value: 'Subway/Metro',
              },
              {
                color: '#FFB300',
                value: 'Tram/Streetcar/Light rail',
              },
              {
                color: '#AB47BC',
                value: 'Rail',
              },
              {
                color: '#808080',
                value: 'Bus',
              },
              {
                color: '#2196F3',
                value: 'Ferry',
              },
              {
                color: '#66BB6A',
                value: 'Cable car',
              },
            ],
            type: PaintColorTypes.CATEGORICAL,
          },
          'line-opacity': 0.5,
          'line-width': 2.5,
        },
        display_hints: {
          scale: SymbologyScales.CATEGORICAL,
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          theme: CUSTOM_THEME,
        },
      },
    ],
    total_arrivals_weekday_all_day: [
      {
        type: SymbologyTypes.LINE,
        paint: {
          'line-color': {
            default: 'transparent',
            property: 'total_arrivals_weekday_all_day',
            stops: [
              {
                color: colors.pink[100],
                value: 0,
              },
              {
                color: colors.pink[200],
                value: 20,
              },
              {
                color: colors.pink[300],
                value: 40,
              },
              {
                color: colors.pink[500],
                value: 60,
              },
              {
                color: colors.pink[700],
                value: 80,
              },
              {
                color: colors.pink[800],
                value: 100,
              },
              {
                color: colors.pink[900],
                value: 120,
              },
            ],
            type: PaintColorTypes.INTERVAL,
          },
          'line-opacity': 0.5,
          'line-width': 2.5,
        },
        display_hints: {
          distribution: DistributionTypes.CUSTOM,
          interpolation: InterpolationTypes.INTERVAL,
          scale: SymbologyScales.NUMERIC,
          theme: CUSTOM_THEME,
        },
      },
    ],
    total_arrivals_weekend_all_day: [
      {
        type: SymbologyTypes.LINE,
        paint: {
          'line-color': {
            default: 'transparent',
            property: 'total_arrivals_weekend_all_day',
            stops: [
              {
                color: colors.cyan[100],
                value: 0,
              },
              {
                color: colors.cyan[200],
                value: 20,
              },
              {
                color: colors.cyan[300],
                value: 40,
              },
              {
                color: colors.cyan[500],
                value: 60,
              },
              {
                color: colors.cyan[700],
                value: 80,
              },
              {
                color: colors.cyan[800],
                value: 100,
              },
              {
                color: colors.cyan[900],
                value: 120,
              },
            ],
            type: PaintColorTypes.INTERVAL,
          },
          'line-opacity': 0.5,
          'line-width': 2.5,
        },
        display_hints: {
          distribution: DistributionTypes.CUSTOM,
          interpolation: InterpolationTypes.INTERVAL,
          scale: SymbologyScales.NUMERIC,
          theme: CUSTOM_THEME,
        },
      },
    ],
    headway_avg_weekday_daytime: [
      {
        type: SymbologyTypes.LINE,
        paint: {
          'line-color': {
            default: 'transparent',
            property: 'headway_avg_weekday_daytime',
            stops: [
              {
                color: colors.deepOrange[900],
                value: 0,
              },
              {
                color: colors.deepOrange[800],
                value: 5,
              },
              {
                color: colors.deepOrange[500],
                value: 10,
              },
              {
                color: colors.deepOrange[300],
                value: 15,
              },
              {
                color: colors.deepOrange[200],
                value: 30,
              },
              {
                color: colors.deepOrange[100],
                value: 60,
              },
            ],
            type: PaintColorTypes.INTERVAL,
          },
          'line-opacity': 0.5,
          'line-width': 2.5,
        },
        display_hints: {
          distribution: DistributionTypes.CUSTOM,
          interpolation: InterpolationTypes.INTERVAL,
          scale: SymbologyScales.NUMERIC,
          theme: CUSTOM_THEME,
        },
      },
    ],
    headway_avg_weekend_daytime: [
      {
        type: SymbologyTypes.LINE,
        paint: {
          'line-color': {
            default: 'transparent',
            property: 'headway_avg_weekend_daytime',
            stops: [
              {
                color: colors.blue[900],
                value: 0,
              },
              {
                color: colors.blue[800],
                value: 5,
              },
              {
                color: colors.blue[500],
                value: 10,
              },
              {
                color: colors.blue[300],
                value: 15,
              },
              {
                color: colors.blue[200],
                value: 30,
              },
              {
                color: colors.blue[100],
                value: 60,
              },
            ],
            type: PaintColorTypes.INTERVAL,
          },
          'line-opacity': 0.5,
          'line-width': 2.5,
        },
        display_hints: {
          distribution: DistributionTypes.CUSTOM,
          interpolation: InterpolationTypes.INTERVAL,
          scale: SymbologyScales.NUMERIC,
          theme: CUSTOM_THEME,
        },
      },
    ],
  };
});

export default symbologies;
