import {
  CuratedColumnSymbology,
  InterpolationTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/pzfpngxt/dataset/city_of_somerville_open_space': {
    use: [
      {
        display_hints: {
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          scale: SymbologyScales.CATEGORICAL,
          theme: 'custom',
        },
        paint: {
          'fill-color': {
            default: '#1f77b4',
            property: 'use',
            stops: [
              {
                color: '#9f0500',
                value: 'Baseball',
              },
              {
                color: '#c45100',
                value: 'Basketball',
              },
              {
                color: '#fcc400',
                value: 'Cafe Seating',
              },
              {
                color: '#68bc00',
                value: 'City Park',
              },
              {
                color: '#2ca02c',
                value: 'Community Garden',
              },
              {
                color: '#fb9e00',
                value: 'Community Path',
              },
              {
                color: '#d62728',
                value: 'Dog Park',
              },
              {
                color: '#16a5a5',
                value: 'Farm',
              },
              {
                color: '#68bc00',
                value: 'Field',
              },
              {
                color: '#c5b0d5',
                value: 'Landmark',
              },
              {
                color: '#8c564b',
                value: 'LL Baseball',
              },
              {
                color: '#808080',
                value: 'Parking',
              },
              {
                color: '#cccccc',
                value: 'Passive',
              },
              {
                color: '#fda1ff',
                value: 'Performance Space',
              },
              {
                color: '#dbdf00',
                value: 'Playground',
              },
              {
                color: '#c7c7c7',
                value: 'Plaza',
              },
              {
                color: '#73d8ff',
                value: 'Pool',
              },
              {
                color: '#68bc00',
                value: 'Recreational Field',
              },
              {
                color: '#68bc00',
                value: 'Regional Park',
              },
              {
                color: '#b0bc00',
                value: 'Tennis',
              },
            ],
            type: 'categorical',
          },
          'fill-opacity': 0.9,
        },
        type: SymbologyTypes.FILL,
      },
      {
        display_hints: {
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          scale: SymbologyScales.CATEGORICAL,
          theme: 'custom',
        },
        paint: {
          'line-color': 'transparent',
          'line-opacity': 0.9,
          'line-width': 0.5,
        },
        type: SymbologyTypes.LINE,
      },
    ],
  },
};

export default symbologies;
