import { Epic, ofType } from 'redux-observable';
import { filter, map, withLatestFrom } from 'rxjs/operators';
import { t } from 'ttag';

import {
  enqueueLayerExportTaskActionTypes,
  enqueueLayerExportTaskSuccessAction,
} from 'uf-api/api/layer.service';
import { addAppMessage } from 'uf/appservices/actions';
import { downloadUrl } from 'uf/base/download';
import { combineEpics } from 'uf/base/epics';
import { LayerId } from 'uf/layers';
import {
  BIND_EXPORT_LAYER_TASK,
  LAYER_DOWNLOAD_STARTED,
} from 'uf/layers/ActionTypes';
import { formatFileName } from 'uf/layers/formatting';
import { COLUMN_EXPORT } from 'uf/layers/logging';
import { logEvent } from 'uf/logging';
import { ExportLayerTaskMessage } from 'uf/projects/tasks';
import { UFState } from 'uf/state';
import { bindTaskToResource } from 'uf/tasks/actions';
import { NotificationAction } from 'uf/tasks/ActionTypes';
import { NotificationTypes } from 'uf/tasks/NotificationTypes';
import { extractErrorDetails } from 'uf/ui/base/errors';
import { makeWebsocketActionType } from 'uf/websockets/actionHelpers';

const bindExportLayerTaskToLayer: Epic<
  enqueueLayerExportTaskSuccessAction & { layerId: LayerId },
  any
> = action$ => {
  return action$
    .ofType(enqueueLayerExportTaskActionTypes.SUCCESS)
    .pipe(
      map(({ result: { task_id: taskId }, layerId }) =>
        bindTaskToResource(BIND_EXPORT_LAYER_TASK, taskId, layerId),
      ),
    );
};

export const showExportLayerStartedToast: Epic<
  NotificationAction<ExportLayerTaskMessage>,
  any
> = (action$, state$) =>
  action$.pipe(
    ofType(makeWebsocketActionType(NotificationTypes.LAYER_EXPORT_STARTED)),
    withLatestFrom(state$),
    filter(([action, state]) => {
      /*
       * The BE controls when the task started message is sent.
       * Due to this we need to be sure an error wasn't messaged before the task started message.
       * Otherwise the loading toast will override the error message and never disappear.
       */
      return !state.appservices.messages?.some(
        task => task.replacesMessage === action.result.task_id,
      );
    }),
    map(([action, state]) => {
      return addAppMessage(
        `Exporting layer "${action.result.info.layer_name}"...`,
        {
          status: 'running',
          replacesMessage: action.result.task_id,
          timeout: 0,
        },
      );
    }),
  );

export const showExportLayerDoneToast: Epic<
  NotificationAction<ExportLayerTaskMessage>,
  any,
  UFState
> = action$ =>
  action$
    .ofType(makeWebsocketActionType(NotificationTypes.LAYER_EXPORT_DONE))
    .pipe(
      map(({ result }) => {
        const { layer_name: layerName, file_format: fileFormat } = result.info;
        const fileName = formatFileName(layerName, fileFormat);

        return addAppMessage(`Successfully exported layer "${fileName}"`, {
          level: 'success',
          status: 'success',
          action: {
            text: t`Download`,
            onClick: () => {
              downloadUrl(result.result.download_url);
              return {
                type: LAYER_DOWNLOAD_STARTED,
              };
            },
          },
          replacesMessage: result.task_id,
          timeout: 0,
        });
      }),
    );

export const showExportLayerErrorToast: Epic<
  NotificationAction<ExportLayerTaskMessage>,
  any,
  UFState
> = action$ =>
  action$
    .ofType(makeWebsocketActionType(NotificationTypes.LAYER_EXPORT_ERROR))
    .pipe(
      map(({ result }) => {
        const { type_code: typeCode } = result.problem;
        const { layer_name: layerName } = result.info;

        let errorMessage = t`There was an error exporting "${result.info.layer_name}"`;
        if (typeCode === 'layer_data_empty') {
          errorMessage = t`There is no data to export for "${layerName}".`;
        } else if (typeCode === 'layer_export_maximum_features') {
          errorMessage = extractErrorDetails(result.problem).errorDetailRaw;
        }

        return addAppMessage(errorMessage, {
          level: 'danger',
          status: 'failure',
          replacesMessage: result.task_id,
          timeout: 0,
        });
      }),
    );

export const showExportColumnsStartedToast = action$ =>
  action$
    .ofType(makeWebsocketActionType(NotificationTypes.COLUMN_EXPORT_STARTED))
    .pipe(
      map(({ result }) => {
        return addAppMessage(
          `Exporting column metadata for "${result.info.layer_name}"...`,
          {
            status: 'running',
            replacesMessage: result.task_id,
            timeout: 0,
          },
        );
      }),
    );

export const showExportColumnsErrorToast = action$ =>
  action$
    .ofType(makeWebsocketActionType(NotificationTypes.COLUMN_EXPORT_ERROR))
    .pipe(
      map(({ result }) => {
        const { layer_name: layerName } = result.info;

        const errorMessage = t`There was an error exporting column metadata for "${layerName}"`;

        return addAppMessage(errorMessage, {
          level: 'danger',
          status: 'failure',
          replacesMessage: result.task_id,
          timeout: 0,
        });
      }),
    );

export const showExportColumnsDoneToast = action$ =>
  action$
    .ofType(makeWebsocketActionType(NotificationTypes.COLUMN_EXPORT_DONE))
    .pipe(
      map(({ result }) => {
        const { layer_name: layerName, full_path: layerId } = result.info;
        // Log Amplitude event on success
        logEvent(COLUMN_EXPORT, { layerId });
        return addAppMessage(
          `Successfully exported layer "${layerName} Metadata.csv"`,
          {
            level: 'success',
            status: 'success',
            action: {
              text: t`Download`,
              onClick: () => {
                downloadUrl(result.result.download_url);
                return {
                  type: LAYER_DOWNLOAD_STARTED,
                };
              },
            },
            replacesMessage: result.task_id,
            timeout: 0,
          },
        );
      }),
    );

export default combineEpics(
  {
    bindExportLayerTaskToLayer,
    showExportLayerStartedToast,
    showExportLayerDoneToast,
    showExportLayerErrorToast,
    showExportColumnsStartedToast,
    showExportColumnsDoneToast,
    showExportColumnsErrorToast,
  },
  'layers',
);
