import _ from 'lodash';

import { EMPTY_OBJECT } from 'uf/base';
import {
  ADD_USER_FLAGS,
  FlagAction,
  REMOVE_USER_FLAGS,
  SET_USER_FLAGS,
} from 'uf/user/ActionTypes';

/**
 * This reducer keeps track of the user's different flags
 *
 */

const initialState: Record<string, boolean> = EMPTY_OBJECT;

export default function flags(
  state = initialState,
  action: FlagAction = { type: undefined, flags: [] },
): Record<string, boolean> {
  switch (action.type) {
    case SET_USER_FLAGS:
      return _.mapValues(_.keyBy(action.flags), () => true);

    case ADD_USER_FLAGS:
      return {
        ...state,
        ..._.mapValues(_.keyBy(action.flags), () => true),
      };

    case REMOVE_USER_FLAGS:
      return {
        ...state,
        ..._.mapValues(_.keyBy(action.flags), () => false),
      };

    default:
      return state;
  }
}
