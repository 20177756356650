import {
  CuratedColumnSymbology,
  DistributionTypes,
  InterpolationTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/public/dataset/usgs_earthquake_mmi_haywired_scenario': {
    paramvalue: [
      {
        display_hints: {
          distribution: DistributionTypes.CUSTOM,
          interpolation: InterpolationTypes.INTERVAL,
          is_percent: false,
          is_reverse_theme: false,
          scale: SymbologyScales.NUMERIC,
          showZero: false,
          theme: 'd3:interpolateYlOrRd',
        },
        paint: {
          'fill-color': {
            default: 'transparent',
            property: 'paramvalue',
            stops: [
              {
                color: 'rgb(255, 255, 204)',
                value: 0,
              },
              {
                color: 'rgb(255, 230, 146)',
                value: 4,
              },
              {
                color: 'rgb(254, 191, 91)',
                value: 5,
              },
              {
                color: 'rgb(253, 137, 60)',
                value: 6,
              },
              {
                color: 'rgb(242, 61, 38)',
                value: 7,
              },
              {
                color: 'rgb(200, 10, 34)',
                value: 8,
              },
              {
                color: 'rgb(128, 0, 38)',
                value: 9,
              },
            ],
            type: 'interval',
          },
          'fill-opacity': 0.6,
        },
        type: SymbologyTypes.FILL,
      },
      {
        display_hints: {
          distribution: DistributionTypes.QUANTILES,
          interpolation: InterpolationTypes.INTERVAL,
          is_percent: false,
          scale: SymbologyScales.NUMERIC,
          showZero: false,
          theme: 'material:blue:range',
        },
        paint: {
          'line-color': 'transparent',
          'line-opacity': 0.6,
          'line-width': 1,
        },
        type: SymbologyTypes.LINE,
      },
    ],
  },
};

export default symbologies;
