import {
  CuratedColumnSymbology,
  CUSTOM_THEME,
  DEFAULT_OPACITY,
  InterpolationTypes,
  PaintColorTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/public/dataset/california_hydrologic_regions': {
    first_hrna: [
      {
        type: SymbologyTypes.FILL,
        paint: {
          'fill-color': {
            property: 'first_hrna',
            type: PaintColorTypes.CATEGORICAL,
            stops: [
              { value: 'North Coast', color: '#ac2adf' },
              { value: 'San Francisco Bay', color: '#8a7ad1' },
              { value: 'Central Coast', color: '#41c3cf' },
              { value: 'South Coast', color: '#cbc475' },
              { value: 'Sacramento River', color: '#ce7544' },
              { value: 'San Joaquin', color: '#55d155' },
              { value: 'Tulare Lake', color: '#ec6e85' },
              { value: 'North Lahontan', color: '#3e75d4' },
              { value: 'South Lahontan', color: '#4bcb91' },
              { value: 'Colarado River', color: '#dbf01e' },
            ],
          },
          'fill-opacity': DEFAULT_OPACITY,
        },
        display_hints: {
          scale: SymbologyScales.CATEGORICAL,
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          theme: CUSTOM_THEME,
        },
      },
    ],
  },
};

export default symbologies;
