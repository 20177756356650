import { createSelector } from 'reselect';

import { UserData } from 'uf-api';
import { DataState, EmptyKeyedState } from 'uf/data/dataState';
import { LAST_ACTIVE_PROJECT_ID, makeUserDataKey } from 'uf/user';
import { makeUserPrefKey } from 'uf/user/helpers';

import { getUserDataState, getUserKey } from './user';

export const getLastActiveProjectState = createSelector(
  getUserDataState,
  getUserKey,
  (userDataState, userKey): DataState<UserData> => {
    const lastActiveProjectIdState =
      userDataState[
        makeUserDataKey(userKey, makeUserPrefKey(LAST_ACTIVE_PROJECT_ID))
      ];
    return lastActiveProjectIdState || EmptyKeyedState;
  },
);
