import { createSelector } from 'reselect';

import { makeGetFromPropsSelector } from 'uf/base/selector';
import { makeGetExploreUIProperty } from 'uf/explore/selectors/explore';
import { ProjectId } from 'uf/projects';
import { getUserSetFlags } from 'uf/user/selectors/flags';

import {
  AnalysisEditorContext,
  makeGetActiveAnalysisEditorModuleByProjectId,
} from './index';

/**
 * This is the toplevel "context" for the analysis editor. The results of this
 * selector are what determine how the analysis editor url should update or not.
 *
 * In the future the projectId + moduleKey should be stored in redux separately,
 * updated by the url, rather than using getActive*
 */
export function makeGetAnalysisEditorContext() {
  return createSelector(
    makeGetFromPropsSelector<ProjectId, 'projectId'>('projectId'),
    // can't use parameterized selectors here :(
    makeGetActiveAnalysisEditorModuleByProjectId(),
    getUserSetFlags,
    makeGetExploreUIProperty<string>('manageViewVisible'),
    makeGetExploreUIProperty<string>('manageViewEditor'),
    (
      projectId,
      activeModuleKey,
      flags,
      manageViewVisible,
      manageViewEditor,
    ): AnalysisEditorContext => ({
      flags,
      projectId,
      moduleKey: activeModuleKey,
      manageViewVisible,
      manageViewEditor,
    }),
  );
}
