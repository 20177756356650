import * as React from 'react';
import { Box } from 'react-layout-components';

import { makeGetValidActiveLayerId } from 'uf/explore/selectors/layers';
import { ProjectId } from 'uf/projects';
import { ScenarioId } from 'uf/scenarios';
import CollapseTab from 'uf/ui/base/CollapseTab/CollapseTab';
import { useMakeSelector } from 'uf/ui/base/useMakeSelector';
import ExploreDetailsPane from 'uf/ui/explore/ExploreDetailsPane/ExploreDetailsPane';
import { ExploreLayerPanel } from 'uf/ui/explore/ExploreLayerPanel/ExploreLayerPanel';
import { ViewId } from 'uf/views';

import styles from './ExplorePanel.module.css';

interface Props {
  projectId: ProjectId;
  scenarioId: ScenarioId;
  viewId: ViewId;
  layerPanelWidth: number;
  detailsPaneWidth: number;
  detailsPaneCollapsed?: boolean;
}

export const ExplorePanel: React.FunctionComponent<Props> = props => {
  const {
    projectId,
    scenarioId,
    viewId,
    layerPanelWidth,
    detailsPaneWidth,
    detailsPaneCollapsed,
  } = props;
  const activeLayerId = useMakeSelector(makeGetValidActiveLayerId, {
    projectId,
    scenarioId,
    viewId,
  });

  // the CollapseTab here is only responsible for the collapsing function, not displaying the tab.
  // If we tried to display the tab here it would push the outer Box and wouldn't hang over the map.
  return (
    <Box height="100%" className={styles.explorePanel}>
      <ExploreLayerPanel width={layerPanelWidth} projectId={projectId} />
      {activeLayerId && (
        <CollapseTab hideTabbyBit open={!detailsPaneCollapsed}>
          <ExploreDetailsPane width={detailsPaneWidth} />
        </CollapseTab>
      )}
    </Box>
  );
};
