import { makeKeyedReducer, makeListReducer } from 'uf/base/reducerFactories';
import { View, ViewId } from 'uf/views';
import { viewListActionTypes } from 'uf/views/ActionTypes';

const viewListReducer = makeKeyedReducer(
  makeListReducer<View, ViewId>(viewListActionTypes, view => view?.key),
  'projectId',
);

export default viewListReducer;
