import _ from 'lodash';
import { Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import uniqid from 'uniqid';
import warning from 'warning';

import {
  createBuildingActionTypes,
  createBuildingTypeActionTypes,
  createPlaceTypeActionTypes,
  deleteBuildingActionTypes,
  deleteBuildingTypeActionTypes,
  deletePlaceTypeActionTypes,
  getBuildingActionTypes,
  getBuildingPreviewMultiActionTypes,
  getBuildingTypeActionTypes,
  getBuildingTypePreviewActionTypes,
  getLibraryActionTypes,
  getLibraryBuildingsActionTypes,
  getLibraryBuildingTypesActionTypes,
  getLibraryPlaceTypesActionTypes,
  getPlaceTypeActionTypes,
  getPlaceTypePreviewActionTypes,
  updateBuildingActionTypes,
  updateBuildingTypeActionTypes,
  updateBuildingTypeInfosActionTypes,
  updateLibraryDefaultsActionTypes,
  updateLibraryVariablesActionTypes,
  updatePlaceTypeActionTypes,
  updatePlaceTypeInfosActionTypes,
} from 'uf-api/api/builtforms.service';
import { bakeLibraryActionTypes } from 'uf-api/api/internal.service';
import {
  Building,
  BuildingType,
  BuildingTypeInfo,
  LibraryDefaults,
  LibraryVariables,
  PlaceType,
  PlaceTypeInfo,
} from 'uf-api/model/models';
import { parseFullPath } from 'uf/base/dataset';
import { MAX_KEY_LENGTH, postfixKeywithUniqId } from 'uf/base/keys';
import { rejectNever } from 'uf/base/never';
import slug from 'uf/base/slug';
import {
  BUILT_FORM_TYPES,
  BuiltForm,
  BuiltFormInfo,
  makeBuiltFormKey,
  makeLibraryKey,
} from 'uf/builtforms';
import {
  bakeLibrary as bakeLibraryAPI,
  createBuilding as createBuildingAPI,
  createBuildingType as createBuildingTypeAPI,
  createPlaceType as createPlaceTypeAPI,
  deleteBuilding as deleteBuildingAPI,
  deleteBuildingType as deleteBuildingTypeAPI,
  deletePlaceType as deletePlaceTypeAPI,
  getBuilding,
  getBuildingPreview,
  getBuildingType,
  getBuildingTypePreview,
  getLibrary,
  getLibraryBuildings,
  getLibraryBuildingTypes,
  getLibraryPlaceTypes,
  getPlaceType,
  getPlaceTypePreview,
  updateBuilding as updateBuildingAPI,
  updateBuildingType as updateBuildingTypeAPI,
  updateLibraryBuildingTypeInfos as updateBuildingTypeInfosAPI,
  updateLibraryDefaults as updateLibraryDefaultsAPI,
  updateLibraryPlaceTypeInfos as updatePlaceTypeInfosAPI,
  updateLibraryVariables as updateLibraryVariablesAPI,
  updatePlaceType as updatePlaceTypeAPI,
} from 'uf/builtforms/apis';
import {
  makeGetBuiltFormsListState,
  makeGetBuiltFormState,
  makeGetLibraryState,
} from 'uf/builtforms/selectors';
import { getData, isLoaded, shouldLoad } from 'uf/data/dataState';
import { dispatchAsyncAction } from 'uf/data/loader';
import { ensureCheckFeature } from 'uf/user/actions/permissions';

export function loadLibrary(libraryId: string) {
  const { namespace, key } = parseFullPath(libraryId, 'builtforms');
  return dispatchAsyncAction(
    getLibraryActionTypes,
    getLibrary({ namespace, key }),
    {
      key: libraryId,
    },
  );
}

export function ensureLibrary(libraryId: string) {
  const getLibraryState = makeGetLibraryState();
  return (dispatch: Dispatch, getState: () => any) => {
    const libraryState = getLibraryState(getState(), { libraryId });

    if (isLoaded(libraryState)) {
      return Promise.resolve(getData(libraryState));
    }

    if (shouldLoad(libraryState)) {
      return dispatch(loadLibrary(libraryId));
    }

    return libraryState.promise;
  };
}

export function ensureCanEditProjectBuiltForms(libraryId: string) {
  return (dispatch: Dispatch) =>
    dispatch(ensureCheckFeature('builtforms.update', libraryId));
}

export function updateLibraryDefaults(
  libraryId: string,
  defaults: LibraryDefaults,
) {
  const { namespace, key } = parseFullPath(libraryId, 'builtforms');
  return dispatchAsyncAction(
    updateLibraryDefaultsActionTypes,
    updateLibraryDefaultsAPI({ namespace, key, defaults }),
    { key: libraryId },
  );
}

export function updateLibraryVariables(
  libraryId: string,
  variables: LibraryVariables,
) {
  const { namespace, key } = parseFullPath(libraryId, 'builtforms');
  return dispatchAsyncAction(
    updateLibraryVariablesActionTypes,
    updateLibraryVariablesAPI({ namespace, key, variables }),
    { key: libraryId },
  );
}

export function loadLibraryBuildings(libraryId: string) {
  const { namespace, key } = parseFullPath(libraryId, 'builtforms');
  return dispatchAsyncAction(
    getLibraryBuildingsActionTypes,
    getLibraryBuildings({ namespace, key }),
    { key: makeLibraryKey(libraryId, BUILT_FORM_TYPES.BUILDING) },
  );
}

export function clearLibraryBuildings(libraryId: string) {
  return {
    type: getLibraryBuildingsActionTypes.CLEAR,
    key: makeLibraryKey(libraryId, BUILT_FORM_TYPES.BUILDING),
  };
}

export function updateLibraryBuildingTypeInfos(
  libraryId: string,
  buildingTypeInfos: BuildingTypeInfo[],
): ThunkAction<Promise<{}>, any, any, any> {
  const { namespace, key } = parseFullPath(libraryId, 'builtforms');
  return dispatchAsyncAction(
    updateBuildingTypeInfosActionTypes,
    updateBuildingTypeInfosAPI({
      library_key: key,
      library_namespace: namespace,
      building_type_infos: buildingTypeInfos,
    }),
    {
      actionParams: { libraryId, buildingTypeInfos },
      key: makeLibraryKey(libraryId, BUILT_FORM_TYPES.BUILDING_TYPE),
    },
  );
}

export function loadLibraryBuildingTypes(libraryId: string) {
  const { namespace, key } = parseFullPath(libraryId, 'builtforms');
  return dispatchAsyncAction(
    getLibraryBuildingTypesActionTypes,
    getLibraryBuildingTypes({ namespace, key }),
    { key: makeLibraryKey(libraryId, BUILT_FORM_TYPES.BUILDING_TYPE) },
  );
}

export function clearLibraryBuildingTypes(libraryId: string) {
  return {
    type: getLibraryBuildingTypesActionTypes.CLEAR,
    key: makeLibraryKey(libraryId, BUILT_FORM_TYPES.BUILDING_TYPE),
  };
}

export function loadLibraryPlaceTypes(libraryId: string) {
  const { namespace, key } = parseFullPath(libraryId, 'builtforms');
  return dispatchAsyncAction(
    getLibraryPlaceTypesActionTypes,
    getLibraryPlaceTypes({ namespace, key }),
    { key: makeLibraryKey(libraryId, BUILT_FORM_TYPES.PLACE_TYPE) },
  );
}
export function updateLibraryPlaceTypeInfos(
  libraryId: string,
  placeTypeInfos: PlaceTypeInfo[],
): ThunkAction<Promise<{}>, any, any, any> {
  const { namespace, key } = parseFullPath(libraryId, 'builtforms');
  return dispatchAsyncAction(
    updatePlaceTypeInfosActionTypes,
    updatePlaceTypeInfosAPI({
      library_key: key,
      library_namespace: namespace,
      place_type_infos: placeTypeInfos,
    }),
    {
      actionParams: { libraryId, placeTypeInfos },
      key: makeLibraryKey(libraryId, BUILT_FORM_TYPES.PLACE_TYPE),
    },
  );
}

export function clearLibraryPlaceTypes(libraryId: string) {
  return {
    type: getLibraryPlaceTypesActionTypes.CLEAR,
    key: makeLibraryKey(libraryId, BUILT_FORM_TYPES.PLACE_TYPE),
  };
}

export function ensureLibraryBuiltForms(
  libraryId: string,
  builtFormType: BUILT_FORM_TYPES,
) {
  switch (builtFormType) {
    case BUILT_FORM_TYPES.BUILDING:
      return ensureLibraryBuildings(libraryId);

    case BUILT_FORM_TYPES.BUILDING_TYPE:
      return ensureLibraryBuildingTypes(libraryId);

    case BUILT_FORM_TYPES.PLACE_TYPE:
      return ensureLibraryPlaceTypes(libraryId);

    default:
      return () =>
        rejectNever<BuiltFormInfo[]>(
          builtFormType,
          `Unknown land use type ${builtFormType}`,
        );
  }
}

function ensureLibraryBuildings(libraryId: string) {
  const getBuiltFormsListState = makeGetBuiltFormsListState();
  return (dispatch: Dispatch, getState: () => any) => {
    const builtFormsState = getBuiltFormsListState(getState(), {
      libraryId,
      builtFormType: BUILT_FORM_TYPES.BUILDING,
    });

    if (isLoaded(builtFormsState)) {
      return Promise.resolve(getData(builtFormsState));
    }

    if (shouldLoad(builtFormsState)) {
      return dispatch(loadLibraryBuildings(libraryId));
    }

    return builtFormsState.promise;
  };
}

function ensureLibraryBuildingTypes(libraryId: string) {
  const getBuiltFormsListState = makeGetBuiltFormsListState();
  return (dispatch: Dispatch, getState: () => any) => {
    const builtFormsState = getBuiltFormsListState(getState(), {
      libraryId,
      builtFormType: BUILT_FORM_TYPES.BUILDING_TYPE,
    });

    if (isLoaded(builtFormsState)) {
      return Promise.resolve(getData(builtFormsState));
    }

    if (shouldLoad(builtFormsState)) {
      return dispatch(loadLibraryBuildingTypes(libraryId));
    }

    return builtFormsState.promise;
  };
}

function ensureLibraryPlaceTypes(libraryId: string) {
  const getBuiltFormsListState = makeGetBuiltFormsListState();
  return (dispatch: Dispatch, getState: () => any) => {
    const builtFormsState = getBuiltFormsListState(getState(), {
      libraryId,
      builtFormType: BUILT_FORM_TYPES.PLACE_TYPE,
    });

    if (isLoaded(builtFormsState)) {
      return Promise.resolve(getData(builtFormsState));
    }

    if (shouldLoad(builtFormsState)) {
      return dispatch(loadLibraryPlaceTypes(libraryId));
    }

    return builtFormsState.promise;
  };
}

export function loadBuiltForm(
  libraryId: string,
  builtFormType: BUILT_FORM_TYPES,
  builtFormKey: string,
) {
  switch (builtFormType) {
    case BUILT_FORM_TYPES.BUILDING:
      return loadBuilding(libraryId, builtFormKey);

    case BUILT_FORM_TYPES.BUILDING_TYPE:
      return loadBuildingType(libraryId, builtFormKey);

    case BUILT_FORM_TYPES.PLACE_TYPE:
      return loadPlaceType(libraryId, builtFormKey);

    default:
      return () =>
        rejectNever(builtFormType, `Unknown land use type ${builtFormType}`);
  }
}

export function loadBuilding(libraryId: string, buildingKey: string) {
  const { namespace, key } = parseFullPath(libraryId, 'builtforms');
  const params = { libraryId, buildingKey };
  return dispatchAsyncAction(
    getBuildingActionTypes,
    getBuilding({
      library_namespace: namespace,
      library_key: key,
      key: buildingKey,
    }),
    {
      params,
      key: makeBuiltFormKey(libraryId, BUILT_FORM_TYPES.BUILDING, buildingKey),
    },
  );
}

export function loadBuildingType(libraryId: string, buildingTypeKey: string) {
  const params = { libraryId, buildingTypeKey };
  const { namespace, key } = parseFullPath(libraryId, 'builtforms');
  return dispatchAsyncAction(
    getBuildingTypeActionTypes,
    getBuildingType({
      library_namespace: namespace,
      library_key: key,
      key: buildingTypeKey,
    }),
    {
      params,
      key: makeBuiltFormKey(
        libraryId,
        BUILT_FORM_TYPES.BUILDING_TYPE,
        buildingTypeKey,
      ),
    },
  );
}

export function loadPlaceType(libraryId: string, placeTypeKey: string) {
  const params = { libraryId, placeTypeKey };
  const { namespace, key } = parseFullPath(libraryId, 'builtforms');

  return dispatchAsyncAction(
    getPlaceTypeActionTypes,
    getPlaceType({
      library_namespace: namespace,
      library_key: key,
      key: placeTypeKey,
    }),
    {
      params,
      key: makeBuiltFormKey(
        libraryId,
        BUILT_FORM_TYPES.PLACE_TYPE,
        placeTypeKey,
      ),
    },
  );
}

export function clearBuilding(libraryId: string, buildingKey: string) {
  return {
    type: getBuildingActionTypes.CLEAR,
    key: makeBuiltFormKey(libraryId, BUILT_FORM_TYPES.BUILDING, buildingKey),
  };
}

export function clearBuildingType(libraryId: string, buildingTypeKey: string) {
  return {
    type: getBuildingTypeActionTypes.CLEAR,
    key: makeBuiltFormKey(
      libraryId,
      BUILT_FORM_TYPES.BUILDING_TYPE,
      buildingTypeKey,
    ),
  };
}

export function clearPlaceType(libraryId: string, placeTypeKey: string) {
  return {
    type: getPlaceTypeActionTypes.CLEAR,
    key: makeBuiltFormKey(libraryId, BUILT_FORM_TYPES.PLACE_TYPE, placeTypeKey),
  };
}

export function ensureBuiltForm(
  libraryId: string,
  builtFormType: BUILT_FORM_TYPES,
  builtFormKey: string,
) {
  switch (builtFormType) {
    case BUILT_FORM_TYPES.BUILDING:
      return ensureBuilding(libraryId, builtFormKey);

    case BUILT_FORM_TYPES.BUILDING_TYPE:
      return ensureBuildingType(libraryId, builtFormKey);

    case BUILT_FORM_TYPES.PLACE_TYPE:
      return ensurePlaceType(libraryId, builtFormKey);

    default:
      break;
  }
  return () =>
    rejectNever(builtFormType, `Unknown land use type ${builtFormType}`);
}

function ensureBuilding(libraryId: string, builtFormKey: string) {
  const getBuiltFormState = makeGetBuiltFormState();
  return (dispatch: Dispatch, getState: () => any) => {
    const builtFormState = getBuiltFormState(getState(), {
      libraryId,
      builtFormType: BUILT_FORM_TYPES.BUILDING,
      builtFormKey,
    });

    if (isLoaded(builtFormState)) {
      return Promise.resolve(getData(builtFormState));
    }

    if (shouldLoad(builtFormState)) {
      return dispatch(loadBuilding(libraryId, builtFormKey));
    }

    return builtFormState.promise;
  };
}

function ensureBuildingType(libraryId: string, builtFormKey: string) {
  const getBuiltFormState = makeGetBuiltFormState();
  return (dispatch: Dispatch, getState: () => any) => {
    const builtFormState = getBuiltFormState(getState(), {
      libraryId,
      builtFormType: BUILT_FORM_TYPES.BUILDING_TYPE,
      builtFormKey,
    });

    if (isLoaded(builtFormState)) {
      return Promise.resolve(getData(builtFormState));
    }

    if (shouldLoad(builtFormState)) {
      return dispatch(loadBuildingType(libraryId, builtFormKey));
    }

    return builtFormState.promise;
  };
}

function ensurePlaceType(libraryId: string, builtFormKey: string) {
  const getBuiltFormState = makeGetBuiltFormState();
  return (dispatch: Dispatch, getState: () => any) => {
    const builtFormState = getBuiltFormState(getState(), {
      libraryId,
      builtFormType: BUILT_FORM_TYPES.PLACE_TYPE,
      builtFormKey,
    });

    if (isLoaded(builtFormState)) {
      return Promise.resolve(getData(builtFormState));
    }

    if (shouldLoad(builtFormState)) {
      return dispatch(loadPlaceType(libraryId, builtFormKey));
    }

    return builtFormState.promise;
  };
}

export function createBuiltForm(
  libraryId: string,
  builtFormType: BUILT_FORM_TYPES,
  name: string,
  key?: string,
): ThunkAction<Promise<BuiltForm>, any, any, any> {
  // TODO: these keys REALLY should be created on the backed to avoid collisions
  let builtFormKey: string;
  if (key) {
    builtFormKey = key.slice(0, MAX_KEY_LENGTH);
  } else if (name && name.length > 0) {
    builtFormKey = postfixKeywithUniqId(slug(name));
  } else {
    builtFormKey = `${uniqid()}`;
  }

  switch (builtFormType) {
    case BUILT_FORM_TYPES.BUILDING:
      return createBuilding(libraryId, name, builtFormKey);

    case BUILT_FORM_TYPES.BUILDING_TYPE:
      return createBuildingType(libraryId, name, builtFormKey);

    case BUILT_FORM_TYPES.PLACE_TYPE:
      return createPlaceType(libraryId, name, builtFormKey);

    default:
      break;
  }
  return () =>
    rejectNever(builtFormType, `Unknown land use type ${builtFormType}`);
}

export function createBuilding(
  libraryId: string,
  name: string,
  buildingKey: string,
) {
  const params = { libraryId, name, key: buildingKey };
  const { namespace, key } = parseFullPath(libraryId);
  return dispatchAsyncAction(
    createBuildingActionTypes,
    createBuildingAPI({
      library_namespace: namespace,
      library_key: key,
      key: buildingKey,
      name,
    }),
    {
      params,
      // TODO: Name is not unique enough for a key
      key: `${libraryId}:${name}:${key}`,
    },
  );
}

export function createBuildingType(
  libraryId: string,
  name: string,
  buildingTypeKey: string,
) {
  const params = { libraryId, name, key: buildingTypeKey };
  const { namespace, key } = parseFullPath(libraryId);
  return dispatchAsyncAction(
    createBuildingTypeActionTypes,
    createBuildingTypeAPI({
      library_namespace: namespace,
      library_key: key,
      key: buildingTypeKey,
      name,
    }),
    {
      params,
      // TODO: Name is not unique enough for a key
      key: `${libraryId}:${name}:${buildingTypeKey}`,
    },
  );
}

export function createPlaceType(
  libraryId: string,
  name: string,
  placeTypeKey: string,
) {
  const params = { libraryId, name, key: placeTypeKey };
  const { namespace, key } = parseFullPath(libraryId);
  return dispatchAsyncAction(
    createPlaceTypeActionTypes,
    createPlaceTypeAPI({
      library_namespace: namespace,
      library_key: key,
      key: placeTypeKey,
      name,
    }),
    {
      params,
      // TODO: Name is not unique enough for a key
      key: `${libraryId}:${name}:${placeTypeKey}`,
    },
  );
}

export function updateBuilding(
  libraryId: string,
  buildingKey: string,
  building: Building,
) {
  const params = { libraryId, buildingKey, building };
  const { namespace, key } = parseFullPath(libraryId);
  return dispatchAsyncAction(
    updateBuildingActionTypes,
    updateBuildingAPI({
      library_namespace: namespace,
      library_key: key,
      key: buildingKey,
      building,
    }),
    {
      params,
      key: `${libraryId}/${buildingKey}`,
    },
  );
}

export function updateBuildingType(
  libraryId: string,
  buildingTypeKey: string,
  buildingType: BuildingType,
) {
  const params = { libraryId, buildingTypeKey, buildingType };
  const { namespace, key } = parseFullPath(libraryId);
  return dispatchAsyncAction(
    updateBuildingTypeActionTypes,
    updateBuildingTypeAPI({
      library_namespace: namespace,
      library_key: key,
      key: buildingTypeKey,
      building_type: buildingType,
    }),
    {
      params,
      key: `${libraryId}/${buildingTypeKey}`,
    },
  );
}

function updatePlaceType(
  libraryId: string,
  placeTypeKey: string,
  placeType: PlaceType,
) {
  const params = { libraryId, placeTypeKey, placeType };
  const { namespace, key } = parseFullPath(libraryId);
  return dispatchAsyncAction(
    updatePlaceTypeActionTypes,
    updatePlaceTypeAPI({
      library_namespace: namespace,
      library_key: key,
      key: placeTypeKey,
      place_type: placeType,
    }),
    {
      params,
      key: `${libraryId}/${placeTypeKey}`,
    },
  );
}

export function updateBuiltForm(
  libraryId: string,
  builtFormType: BUILT_FORM_TYPES,
  builtFormKey: string,
  builtForm: BuiltForm,
): ThunkAction<Promise<BuiltForm>, any, any, any> {
  switch (builtFormType) {
    case BUILT_FORM_TYPES.BUILDING:
      return updateBuilding(libraryId, builtFormKey, builtForm as Building);
    case BUILT_FORM_TYPES.BUILDING_TYPE:
      return updateBuildingType(
        libraryId,
        builtFormKey,
        builtForm as BuildingType,
      );
    case BUILT_FORM_TYPES.PLACE_TYPE:
      return updatePlaceType(libraryId, builtFormKey, builtForm as PlaceType);
    default:
      return () =>
        rejectNever(
          builtFormType,
          `Unknown builtFormType '${builtFormType}' while updating ${builtFormKey}`,
        );
  }
}

export function deleteBuiltForm(
  libraryId: string,
  builtFormType: BUILT_FORM_TYPES,
  key: string,
): ThunkAction<Promise<{}>, any, any, any> {
  switch (builtFormType) {
    case BUILT_FORM_TYPES.BUILDING:
      return deleteBuilding(libraryId, key);
    case BUILT_FORM_TYPES.BUILDING_TYPE:
      return deleteBuildingType(libraryId, key);
    case BUILT_FORM_TYPES.PLACE_TYPE:
      return deletePlaceType(libraryId, key);
    default:
      return () =>
        rejectNever(builtFormType, `Unknown land use type ${builtFormType}`);
  }
}

export function deleteBuilding(libraryId: string, buildingKey: string) {
  const params = { libraryId, key: buildingKey };
  const { namespace, key } = parseFullPath(libraryId);
  return dispatchAsyncAction(
    deleteBuildingActionTypes,
    deleteBuildingAPI({
      library_namespace: namespace,
      library_key: key,
      key: buildingKey,
    }),
    { params },
  );
}

export function deleteBuildingType(libraryId: string, buildingKey: string) {
  const params = { libraryId, key: buildingKey };
  const { namespace, key } = parseFullPath(libraryId);
  return dispatchAsyncAction(
    deleteBuildingTypeActionTypes,
    deleteBuildingTypeAPI({
      library_namespace: namespace,
      library_key: key,
      key: buildingKey,
    }),
    { params },
  );
}

export function deletePlaceType(libraryId: string, placeTypeKey: string) {
  const params = { libraryId, key: placeTypeKey };
  const { namespace, key } = parseFullPath(libraryId);
  return dispatchAsyncAction(
    deletePlaceTypeActionTypes,
    deletePlaceTypeAPI({
      library_namespace: namespace,
      library_key: key,
      key: placeTypeKey,
    }),
    { params },
  );
}

export function loadBuiltFormPreview(
  libraryId: string,
  builtFormType: BUILT_FORM_TYPES,
  propName: string,
  value: string,
  builtForm: BuiltForm,
  withDefaults: boolean = false,
): ThunkAction<Promise<BuiltForm>, any, any, any> {
  switch (builtFormType) {
    case BUILT_FORM_TYPES.BUILDING: {
      return loadBuildingPreview(
        libraryId,
        propName,
        value,
        builtForm as Building,
        withDefaults,
      );
    }
    case BUILT_FORM_TYPES.BUILDING_TYPE: {
      warning(
        !value && !propName,
        'Building Types should not have preview property',
      );
      return loadBuildingTypePreview(libraryId, builtForm as BuildingType);
    }
    case BUILT_FORM_TYPES.PLACE_TYPE:
      return loadPlaceTypePreview(
        libraryId,
        propName,
        value,
        builtForm as PlaceType,
      );
    default:
      return () =>
        rejectNever(
          builtFormType,
          `Unknown builtFormType '${builtFormType}' while loading preview`,
        );
  }
}

export function loadBuildingPreview(
  libraryId: string,
  propName: string,
  value: string | number,
  building: Building,
  withDefaults: boolean,
) {
  let valueProp: 'number_value' | 'string_value' = 'number_value';
  if (_.isString(value)) {
    valueProp = 'string_value';
  }
  const { namespace, key } = parseFullPath(libraryId);

  return dispatchAsyncAction(
    getBuildingPreviewMultiActionTypes,
    getBuildingPreview({
      library_namespace: namespace,
      library_key: key,

      update_data: {
        building,
        updates: [{ prop_name: propName, [valueProp]: value }],
      },
      with_defaults: withDefaults,
    }),
    { key: `${libraryId}` },
  );
}

export function loadBuildingTypePreview(
  libraryId: string,
  buildingType: BuildingType,
) {
  const { namespace, key } = parseFullPath(libraryId);
  return dispatchAsyncAction(
    getBuildingTypePreviewActionTypes,
    getBuildingTypePreview({
      library_namespace: namespace,
      library_key: key,
      building_type: buildingType,
    }),
    { key: `${libraryId}` },
  );
}

export function loadPlaceTypePreview(
  libraryId: string,
  unusedPropName: string,
  unusedValue: string,
  placeType: PlaceType,
) {
  const { namespace, key } = parseFullPath(libraryId);
  return dispatchAsyncAction(
    getPlaceTypePreviewActionTypes,
    getPlaceTypePreview({
      library_namespace: namespace,
      library_key: key,
      place_type: placeType,
    }),
    { key: `${libraryId}` },
  );
}

export function bakeLibrary(libraryId: string) {
  const { namespace, key } = parseFullPath(libraryId);
  return dispatchAsyncAction(
    bakeLibraryActionTypes,
    bakeLibraryAPI({ namespace, key }),
    { key: libraryId },
  );
}
