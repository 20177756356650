import colors from 'material-colors';

import {
  CuratedColumnSymbology,
  CUSTOM_THEME,
  DEFAULT_OPACITY,
  DistributionTypes,
  InterpolationTypes,
  PaintColorTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/calthorpeanalytics/dataset/land_value_riverside_city': {
    imp_land_ratio: [
      {
        type: SymbologyTypes.FILL,
        paint: {
          'fill-color': {
            property: 'imp_land_ratio',
            type: PaintColorTypes.INTERVAL,
            stops: [
              { value: 0.0, label: '0.0 - 0.25', color: colors.red[900] },
              {
                value: 0.25,
                label: '0.25 - 0.50',
                color: colors.deepOrange[400],
              },
              { value: 0.5, label: '0.50 - 0.75', color: colors.orange[300] },
              { value: 0.75, label: '0.75 - 1.0', color: colors.amber[200] },
              { value: 1.0, label: '1.0 - 1.25', color: colors.yellow[200] },
              { value: 1.25, label: '1.25 - 1.50', color: colors.lime[200] },
              {
                value: 1.5,
                label: '1.50 - 1.75',
                color: colors.lightGreen[200],
              },
              { value: 1.75, label: '1.75 - 2.0', color: colors.green[300] },
              { value: 2.0, label: '2.0 - 5.0', color: colors.lightBlue[200] },
              { value: 5.0, label: '5.0 - 10.0', color: colors.blue[200] },
              { value: 10.0, label: '10.0 +', color: colors.indigo[400] },
            ],
          },
          'fill-opacity': DEFAULT_OPACITY,
        },
        display_hints: {
          scale: SymbologyScales.NUMERIC,
          distribution: DistributionTypes.DEFAULT,
          interpolation: InterpolationTypes.INTERVAL,
          theme: CUSTOM_THEME,
        },
      },
    ],
  },
};

export default symbologies;
