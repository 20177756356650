import { History } from 'history';
import React from 'react';
import { hydrate } from 'react-dom';
import ErrorBoundary from 'react-error-boundary';
import { Provider } from 'react-redux';
import { Router } from 'react-router';
import { RedBoxError } from 'redbox-react';
import { Store } from 'redux';

import { ThirdPartyAny } from 'uf/base/types';
import { logEvent } from 'uf/logging';
import { rollbarError } from 'uf/rollbar';
import { FutureHistory } from 'uf/routing/history';
import PageErrorHandler from 'uf/ui/base/PageErrorHandler/PageErrorHandler';

export function renderBody(
  renderProps: ThirdPartyAny, // from react-router
  root: Element,
  store: Store,
  history: History & FutureHistory,
) {
  if (!renderProps) {
    // this happens when the router is lazy-loading routes. The router is
    // saying "not found" but it will call this function again with the
    // loaded route.
    return;
  }

  const reduxBody = (
    <Provider store={store} key="provider">
      <Router history={history} {...renderProps} />
    </Provider>
  );
  hydrate(
    <ErrorBoundary
      FallbackComponent={PageErrorHandler}
      onError={handleReactError}>
      {reduxBody}
    </ErrorBoundary>,
    root,
  );
}

export function renderError(
  error: ThirdPartyAny, // from react-router
  root: Element,
) {
  console.error(error);
  rollbarError(error, {
    env: 'browser',
    source: 'route_match',
  });
  if (__DEVELOPMENT__) {
    let realError = error;
    if (!(error instanceof Error)) {
      if (error.title) {
        realError = new Error(error.title);
      } else {
        realError = new Error(error);
      }
    }
    hydrate(<RedBoxError error={realError} />, root);
  } else {
    hydrate(<h1 className="alert alert-danger">Server error.</h1>, root);
  }
}

export function handleReactError(
  error: Error & { reason?: string },
  stack: string,
) {
  rollbarError(error, {
    env: 'browser',
    source: 'react_error',
    from_reason: !!error.reason,
    stack,
  });

  // Tell amplitude too, so we can correlate actions with crashes
  logEvent('uf.app.bsod', {
    error,
    stack,
  });
}
