import { Units } from '@turf/helpers';
import nearestPointOnLine from '@turf/nearest-point-on-line';
import { minIndex } from 'd3-array';
import { Feature, LineString, Point } from 'geojson';

import { UnitLabelKeys } from 'uf/base/units';

import { LngLat } from './map';

export interface GridOptions {
  /**
   * length of one side of grid square in miles.  we'll expand this soon to include different unit types
   **/
  size: number;

  /**
   * units to use for the grid cell length or area
   */
  unitKey: UnitLabelKeys;

  /**
   * angle in degrees.  should go from 0-90
   **/
  angle: number;

  /**
   * offset in percent of the X length of the grid.  should go from -100 to 100
   */
  offsetX: number;

  /**
   * offset in percent of the Y length of the grid.  should go from -100 to 100
   */
  offsetY: number;
}

export function formatLatLng({ lat, lng }: LngLat, space = '\u00a0') {
  const latSuffix = lat < 0 ? 'S' : 'N';
  const lngSuffix = lng < 0 ? 'W' : 'E';

  const roundedLat = Math.round(Math.abs(lat) * 1000) / 1000;
  const roundedLng = Math.round(Math.abs(lng) * 1000) / 1000;
  return `${roundedLat}\u00b0${latSuffix}${space}${roundedLng}\u00b0${lngSuffix}`;
}

/** The `properties` part of a feature (copied from turfjs) */
export interface NearestPointProperties {
  /** The closest point was found on this nth line part */
  index?: number;
  /** distance between point and the closest point */
  dist?: number;
  /** The distance along the line between start and the closest point */
  location?: number;
}

/** A point feature result from `nearestPointOnLine` */
export type NearestPointOnLine = Feature<Point, NearestPointProperties>;
/**
 * Given a set of lines and a set of points, project each point to the closest
 * line
 *
 * Returns a set of GeoJson Point Features for each input point (from turfjs's
 * nearestPointOnLine) with three values in `feature.properties`:
 *  * `index`: closest point was found on nth line part
 *  * `dist`: distance between point and the closest point
 *  * `location`: distance along the line between start and the closest point
 */
export function projectPointsToLines(
  points: Feature<Point>[],
  lines: Feature<LineString>[],
  units: Units = 'miles',
): NearestPointOnLine[] {
  // when no lines are provided, we just return a dummy list of points
  if (!lines.length) {
    return points.map((point, index) => ({
      ...point,
      properties: {
        index,
        dist: 0,
        location: 0,
      },
    }));
  }
  return points.map(point => {
    const nearestPoints = lines.map(line =>
      nearestPointOnLine(line, point, { units }),
    );
    const nearestPointIndex = minIndex(nearestPoints, p => p.properties.dist);
    const pointOnLine: NearestPointOnLine = nearestPoints[nearestPointIndex];
    return pointOnLine;
  });
}
