import {
  Button,
  Classes,
  Divider,
  InputGroup,
  Menu,
  MenuDivider,
  MenuItem,
  Popover,
  PopoverInteractionKind,
  ResizeEntry,
  ResizeSensor,
  Text,
  Tooltip,
} from '@blueprintjs/core';
import { mdiCheck, mdiMenuDown } from '@mdi/js';
import { Icon } from '@mdi/react';
import classNames from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import { t } from 'ttag';
import { ProjectListItem, useGetProjectsV2Query } from 'uf-api-rtk/store/Api';
import popupStyles from 'uf/styles/popups.module.css';
import textStyles from 'uf/styles/text.module.css';
import { events } from 'uf/projects/logging';
import { CopyableText } from 'uf/ui/base/CopyableText/CopyableText';
import { elementsOverflow } from 'uf/ui/base/domHelpers';
import { IconSizeNew } from 'uf/ui/base/icon';
import { useProjectTypes } from 'uf/ui/projects/hooks';
import useUserFlag from 'uf/ui/user/useUserFlag';
import CreateProjectMenuButton from './CreateProjectMenuButton/CreateProjectMenuButton';
import menuStyles from './ProjectMenu.module.css';
import ProjectTypeIcon from 'uf/ui/projects/ProjectTypeIcon/ProjectTypeIcon';
import { logEvent } from 'uf/logging';
import { usePreferredOrgKey, usePreferredProjectId } from 'uf/ui/user/hooks';

const debugHoverCloseDelay = 1000;

interface Props {
  className?: string;
}

const selectClassName = 'projectsMenu';

const popoverClassName = classNames(
  popupStyles.dropdown,
  popupStyles.dropdownLarge,
  popupStyles.extraLargeWidth,
  menuStyles.popupWrapper,
);

export default function ProjectMenu({ className }: Props): JSX.Element {
  const [query, setQuery] = useState<string>('');
  const [activeOrgKey] = usePreferredOrgKey();
  const [activeProjectId, setDefaultProjectId] =
    usePreferredProjectId(activeOrgKey);
  const projectsByOrg = useGetProjectsV2Query().currentData?.items || [];

  const activeItem = projectsByOrg.find(
    ({ organization }) => organization.key === activeOrgKey,
  );
  const activeOrg = activeItem?.organization;
  const unfilteredProjects = activeItem?.projects || [];
  const activeProject = activeProjectId
    ? projectsByOrg
        .flatMap(({ projects }) => projects)
        .find(({ full_path: fullPath }) => fullPath === activeProjectId)
    : null;
  const availableProjects = query
    ? unfilteredProjects.filter(({ name }) => {
        return name
          .toLocaleLowerCase()
          .includes(query.trim().toLocaleLowerCase());
      })
    : unfilteredProjects;
  const selectProject = useCallback(
    (projectFullPath: string) => {
      setDefaultProjectId(projectFullPath);
      logEvent(events.PROJECT_SELECTED, {
        projectId: projectFullPath,
        previous: activeProject?.full_path,
        selected: projectFullPath,
      });
    },
    [setDefaultProjectId, activeProject?.full_path],
  );
  const handleSearchClick = () => {
    if (availableProjects) {
      selectProject(availableProjects[0].full_path);
    }
  };
  const { projectTypes: allProjectTypes } = useProjectTypes(activeOrgKey);
  // goto first project if none is selected
  useEffect(() => {
    if (!activeProjectId && activeItem?.projects?.length) {
      selectProject(activeItem.projects[0].full_path);
    }
  }, [selectProject, activeProjectId, activeItem]);

  const projectMenuItems = unfilteredProjects.length ? (
    availableProjects.map(project => {
      const active = project.key === activeProject?.key;
      const icon = active ? (
        <Icon path={mdiCheck} size={IconSizeNew.EXTRA_SMALL} />
      ) : (
        <ProjectTypeIcon
          label={project.project_type}
          source={allProjectTypes[project.project_type]?.icon_url}
        />
      );
      return (
        <MenuItem
          key={project.full_path}
          onClick={() => selectProject(project.full_path)}
          active={active}
          icon={icon}
          text={<EmphasizeMatchedText query={query} text={project.name} />}
          className="tw-items-center"
        />
      );
    })
  ) : (
    <MenuItem disabled text={t`(No projects available)`} />
  );

  const clearButton = query ? (
    <Button
      name="clearSearch"
      minimal
      small
      className="tw-no-padding tw-ml-1 tw-text-blue-500"
      onClick={() => setQuery('')}
      icon="cross"
    />
  ) : null;
  const searchButton = (
    <Button
      name="search"
      minimal
      small
      className="tw-no-padding tw-ml-1 tw-text-blue-500"
      onClick={handleSearchClick}
      icon="search"
    />
  );

  const noMatchText =
    query.length && !availableProjects.length ? ' (no matching projects)' : '';

  const menu = activeOrg ? (
    <div>
      <InputGroup
        className={classNames(menuStyles.projectQueryField, 'tw-bg-white')}
        name="projectsearch"
        leftElement={searchButton}
        placeholder={t`Search...`}
        autoComplete="off"
        value={query}
        onChange={e => setQuery(e.target.value)}
        rightElement={clearButton}
      />
      <Menu className="tw-p-1">
        <MenuDivider
          key={activeOrg.full_path}
          title={activeOrg.name + noMatchText}
          className={classNames(
            menuStyles.menuHeaderDivider,
            'tw-sticky tw-bg-white',
          )}
        />
        {projectMenuItems}
      </Menu>
      <div className="tw-sticky tw-pt-0 tw-px-2 tw-pb-2 tw-bg-white tw-bottom-0">
        <Divider className="tw--mx-5 tw-mt-0" />
        <CreateProjectMenuButton />
      </div>
    </div>
  ) : (
    t`Please select an org first`
  );

  // Resize handlers for project titles
  const [titleOverflows, setTitleOverflows] = useState(false);
  const onResize = useCallback((entries: ResizeEntry[]) => {
    setTitleOverflows(
      elementsOverflow(
        entries.map(entry => entry.target),
        1,
      ),
    );
  }, []);
  const showProjectId = useUserFlag('dev-show-debug-ids');
  const showTooltip = !!activeProject && (showProjectId || titleOverflows);
  const { projectTypes } = useProjectTypes(activeOrgKey);
  const activeProjectType = activeProject
    ? projectTypes[activeProject.project_type]
    : null;

  return (
    <Popover
      popoverClassName={popoverClassName}
      content={menu}
      minimal
      className={selectClassName}
      placement="bottom-start">
      <Tooltip
        content={
          <ProjectTooltip
            project={activeProject}
            showProjectId={showProjectId}
          />
        }
        interactionKind={PopoverInteractionKind.HOVER}
        hoverCloseDelay={showProjectId ? debugHoverCloseDelay : undefined}
        disabled={!showTooltip}>
        <ResizeSensor onResize={onResize}>
          <Button
            data-testid="project-menu-dropdown-button"
            text={
              activeProject ? (
                <>
                  {activeProjectType ? (
                    <Text className={menuStyles.projectTypeText}>
                      <span
                        style={{
                          color: `${activeProjectType.style.alt_color}`,
                        }}>
                        {activeProjectType.name}
                      </span>
                    </Text>
                  ) : null}
                  <Text>{activeProject.name}</Text>
                </>
              ) : (
                t`Select Project`
              )
            }
            minimal
            className={classNames(
              className,
              textStyles.noWrap,
              popupStyles.maxLargeWidth,
              Classes.TEXT_OVERFLOW_ELLIPSIS,
            )}
            rightIcon={
              <Icon path={mdiMenuDown} size={IconSizeNew.EXTRA_SMALL} />
            }
          />
        </ResizeSensor>
      </Tooltip>
    </Popover>
  );
}

function ProjectTooltip({
  project,
  showProjectId,
}: {
  project: ProjectListItem;
  showProjectId: boolean;
}): JSX.Element {
  if (!project) {
    return null;
  }
  if (showProjectId) {
    return (
      <div>
        <div>{project.name}</div>
        <CopyableText text={project.full_path} />
      </div>
    );
  }
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{project.name}</>;
}

function EmphasizeMatchedText({
  query: rawQuery,
  text: rawText,
}: {
  query: string;
  text: string;
}): JSX.Element {
  const query = rawQuery.trim().toLocaleLowerCase();
  const text = rawText.toLocaleLowerCase();
  if (query && text.includes(query)) {
    const splitPoint = text.indexOf(query);
    const before = rawText.slice(0, splitPoint);
    const inside = rawText.slice(splitPoint, splitPoint + query.length);
    const after = rawText.slice(splitPoint + query.length);
    return (
      <>
        <span className={Classes.TEXT_MUTED}>{before}</span>
        <strong>{inside}</strong>
        <span className={Classes.TEXT_MUTED}>{after}</span>
      </>
    );
  }
  return <>{rawText}</>;
}
