import { mdiInformationOutline } from '@mdi/js';
import Icon from '@mdi/react';
import classNames from 'classnames';
import React from 'react';
import rootStyles from 'uf/styles/root.module.css';
import textStyles from 'uf/styles/text.module.css';
import Flex from 'uf/ui/base/Flex';
import { IconSizeNew } from 'uf/ui/base/icon';
import WithTooltip from 'uf/ui/base/WithTooltip/WithTooltip';

const titleSectionClassName = classNames(
  'page-header',
  rootStyles.mediumMarginBottom,
);

interface FormSectionProps {
  className?: string;
  title?: string;
  titleClass?: string;
  infoText?: string;
}

const FormSection: React.FunctionComponent<FormSectionProps> = props => {
  const { title, titleClass, infoText, className, children } = props;

  const titleTextClassName = classNames(textStyles.bold, titleClass);

  return (
    <Flex flexDirection="col" className={`${className} tw-w-full`}>
      <Flex
        justifyContent="between"
        alignItems="center"
        className={`${titleSectionClassName} tw-w-full`}>
        <h4 className={titleTextClassName}>{title}</h4>
        {infoText && <FormSectionTooltip infoText={infoText} />}
      </Flex>
      {children}
    </Flex>
  );
};

export default FormSection;

function FormSectionTooltip(props) {
  const { infoText } = props;

  if (!infoText) {
    return null;
  }

  return (
    <WithTooltip text={infoText}>
      <Icon path={mdiInformationOutline} size={IconSizeNew.SMALL} />
    </WithTooltip>
  );
}
