import { combineReducers } from 'redux';

import { createScenarioActionTypes } from 'uf-api/api/project.service';
import {
  makeSetKeyedValueReducer,
  makeSetValueReducer,
} from 'uf/base/reducerFactories';
import { CreateScenarioDataStateExtraProp } from 'uf/projects/actions';
import { ScenarioCloneTaskMessage } from 'uf/projects/tasks';
import {
  BIND_CREATE_SCENARIO_TASK,
  createScenarioTaskActionTypes,
  SET_SCENARIO_UPDATE_STATUS,
} from 'uf/scenarios/ActionTypes';
import { ScenariosState } from 'uf/scenarios/state';
import { ScenarioDeleteMessage } from 'uf/scenarios/tasks';
import { ScenarioUpdateStatus } from 'uf/scenarios/update';
import { NotificationTypes } from 'uf/tasks/NotificationTypes';
import { makeCombinedLegacyTaskReducer } from 'uf/tasks/reducerHelpers';
import { makeWebsocketActionType } from 'uf/websockets/actionHelpers';

const updateStatus = makeSetValueReducer<ScenarioUpdateStatus>(
  SET_SCENARIO_UPDATE_STATUS,
);

const deleteStatus = makeSetKeyedValueReducer<ScenarioDeleteMessage, any>(
  makeWebsocketActionType(NotificationTypes.SCENARIO_DELETED),
  ['result', 'scenario_key'],
  ['result'],
);

export default combineReducers<ScenariosState>({
  cloneStatus: makeCombinedLegacyTaskReducer<
    string,
    ScenarioCloneTaskMessage,
    CreateScenarioDataStateExtraProp
  >(
    createScenarioActionTypes,
    BIND_CREATE_SCENARIO_TASK,
    createScenarioTaskActionTypes,
  ),
  deleteStatus,
  updateStatus,
});
