import { Store } from 'redux';
import { Payload, redirect, RouteThunk } from 'redux-first-router';
import watch from 'redux-watch';

import { EMPTY_OBJECT } from 'uf/base';
import { setInternalPageKey, setInternalRoute } from 'uf/internal/actions';
import {
  getActiveInternalPageKey,
  getInternalPageState,
  InternalPageState,
} from 'uf/internal/selectors';
import { getLocationState } from 'uf/routing/selectors';
import { SyncStateWithUrl } from 'uf/routing/state';

export function makeUpdateInternalStateFromUrl(): RouteThunk {
  return (dispatch, getState) => {
    const state = getState();
    const location = getLocationState(state);
    const { pageKey = null } = location.payload as any;
    const activePageKey = getActiveInternalPageKey(state);
    if (activePageKey !== pageKey) {
      dispatch(setInternalPageKey(pageKey));
    }
  };
}

export const syncInternalStateWithUrl: SyncStateWithUrl = (store: Store) => {
  const watchInternalState = watch(() =>
    getInternalPageState(store.getState()),
  );
  const unsubscribe = store.subscribe(
    watchInternalState((internalPageState: InternalPageState) => {
      if (internalPageState) {
        const location = getLocationState(store.getState());
        const { pageKey: urlPageKey } =
          location?.payload ?? (EMPTY_OBJECT as Payload);
        const { pageKey, params } = internalPageState;
        if (urlPageKey !== pageKey) {
          store.dispatch(setInternalRoute(pageKey, params));
        } else {
          store.dispatch(redirect(setInternalRoute(pageKey, params)));
        }
      }
    }),
  );
  return unsubscribe;
};
