import { Epic } from 'redux-observable';
import { map } from 'rxjs/operators';
import { t } from 'ttag';

import { addAppMessage } from 'uf/appservices/actions';
import { combineEpics } from 'uf/base/epics';
import { ScenarioCloneTaskMessage } from 'uf/projects/tasks';
import { NotificationAction } from 'uf/tasks/ActionTypes';
import { ofNotificationType } from 'uf/tasks/observables';
import { TaskStatuses } from 'uf/tasks/TaskStatuses';
import { TaskTypes } from 'uf/tasks/TaskTypes';

export const showScenarioCloneStartedToast: Epic<
  NotificationAction<ScenarioCloneTaskMessage>,
  any
> = action$ => {
  return action$.pipe(
    ofNotificationType<ScenarioCloneTaskMessage>(
      TaskTypes.TASK_TYPE_PROJECT_SCENARIO_CLONE,
      TaskStatuses.ENQUEUED,
    ),
    map(({ result }) => {
      return addAppMessage(
        t`Creating scenario "${result.info.scenario_name}"...`,
        {
          status: 'running',
          timeout: 0,
          id: result.task_id,
        },
      );
    }),
  );
};

export const showScenarioCloneDoneToast: Epic<
  NotificationAction<ScenarioCloneTaskMessage>,
  any
> = action$ => {
  return action$.pipe(
    ofNotificationType<ScenarioCloneTaskMessage>(
      TaskTypes.TASK_TYPE_PROJECT_SCENARIO_CLONE,
      TaskStatuses.DONE,
    ),
    map(({ result }) => {
      return addAppMessage(
        t`Created scenario "${result.info.scenario_name}".`,
        {
          status: 'success',
          id: result.task_id,
        },
      );
    }),
  );
};

export const showScenarioCloneErrorToast: Epic<
  NotificationAction<ScenarioCloneTaskMessage>,
  any
> = action$ => {
  return action$.pipe(
    ofNotificationType<ScenarioCloneTaskMessage>(
      TaskTypes.TASK_TYPE_PROJECT_SCENARIO_CLONE,
      TaskStatuses.ERROR,
    ),
    map(({ result }) => {
      return addAppMessage(
        t`There was an error creating scenario "${result.info.scenario_name}".`,
        {
          status: 'failure',
          timeout: 0,
          id: result.task_id,
        },
      );
    }),
  );
};

export default combineEpics(
  {
    showScenarioCloneStartedToast,
    showScenarioCloneDoneToast,
    showScenarioCloneErrorToast,
  },
  'notifications',
);
