import { DataState, getData, isLoaded } from 'uf/data/dataState';
import { DivideByColumn, DivideByColumnKey } from 'uf/symbology/divideByColumn';
import { TypedUserData } from 'uf/user/state';

export function getDivideByColumnFromUserDataState(
  divideByColumnState: DataState<TypedUserData<DivideByColumn>>,
): DivideByColumnKey {
  // TODO this might not be "loaded" even though it was requested and received,
  // user data list operations might return an empty array and create an ambigious loading state.
  // Ideally we return undefined while loading
  if (!isLoaded(divideByColumnState)) {
    return false;
  }
  const divideByColumn = getData(divideByColumnState);
  return divideByColumn?.value?.divideByColumn || false;
}
