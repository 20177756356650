import { createSelector } from 'reselect';

import { LayerStats } from 'uf-api/model/models';
import { EMPTY_OBJECT } from 'uf/base';
import {
  makeGetFromPropsOptionalSelector,
  makeGetFromPropsSelector,
} from 'uf/base/selector';
import { NUMERIC_STAT_SUM } from 'uf/base/stats/AggregateTypes';
import { DataState, getData } from 'uf/data/dataState';
import { createDataSelector } from 'uf/data/selectors';
import { LayerId } from 'uf/layers';
import { getStatsSearchKey } from 'uf/layers/filters';
import { makeGetLayerMetadata } from 'uf/layers/selectors/metadata';
import {
  getAvailableLayerStats,
  makeGetFilteredLayerStatsState,
} from 'uf/layers/selectors/stats';
import { makeGetLayerVersion } from 'uf/layers/selectors/versions';
import { combineStats } from 'uf/layers/stats';
import { makeGetEditsLayerStats } from 'uf/mapedit/selectors/features';
import { ProjectId } from 'uf/projects';
import { UFState } from 'uf/state';

import { getExploreStatsState } from './explore';
import { makeGetLayerFiltersGetter } from './filters';

export const getSummaryStatsType = createSelector(
  getExploreStatsState,
  ({ aggregateType }) => aggregateType || NUMERIC_STAT_SUM.key,
);

/**
 * gets the stats state for a layer with its currently selected filters.  If the
 * layer in question is a joined layer, then we parse out its filters from its
 * parent's filter state.
 */
export function makeGetLayerExploreFilteredStatsState() {
  return createSelector(
    getAvailableLayerStats,
    makeGetLayerFiltersGetter(),
    makeGetLayerVersion(),
    makeGetFromPropsSelector<LayerId, 'layerId'>('layerId'),
    makeGetFromPropsOptionalSelector<LayerId, 'parentLayerId'>('parentLayerId'),
    makeGetFromPropsSelector<string[], 'columns'>('columns'),
    (
      availableLayerStats,
      getAllFilters,
      version,
      layerId,
      parentLayerId,
      columns,
    ) => {
      let statsKey;
      if (parentLayerId) {
        const filters = getAllFilters(layerId, parentLayerId);
        statsKey = getStatsSearchKey(layerId, {
          filters,
          // when using parentId, we assume the parent version has been passed in
          version: filters.version,
        });
      } else {
        const filters = getAllFilters(layerId, parentLayerId);
        statsKey = getStatsSearchKey(layerId, {
          filters,
          version,
          columns,
        });
      }

      const layerStats = availableLayerStats.find(
        stats => stats.key === statsKey,
      );

      return layerStats || (EMPTY_OBJECT as DataState<LayerStats>);
    },
  );
}

export const getLayerFilteredStats = createDataSelector<LayerStats, UFState>(
  makeGetLayerExploreFilteredStatsState(),
  EMPTY_OBJECT as LayerStats,
);

/**
 *  Gets layer data, combining with any edits made by the mapedit module
 */
export function makeGetLayerStatsStateWithEdits() {
  return createSelector(
    makeGetLayerMetadata(),
    makeGetFilteredLayerStatsState(),
    makeGetEditsLayerStats(),
    makeGetFromPropsSelector<ProjectId, 'projectId'>('projectId'),
    makeGetFromPropsOptionalSelector<number, 'maxCategoricalValues'>(
      'maxCategoricalValues',
    ),
    (layerMetadata, layerStatsState, editsStats): DataState<LayerStats> => {
      return {
        ...layerStatsState,
        data: combineStats(
          layerMetadata,
          getData(layerStatsState, EMPTY_OBJECT),
          editsStats,
        ),
      };
    },
  );
}
