import { Feature, Geometry, Position } from 'geojson';
import _ from 'lodash';
import { LngLatBounds } from 'mapbox-gl';

import { LayerBounds, MapExportControlSettings } from 'uf-api';
import { roundTo, toFixed } from 'uf/base/formatting';

import { typeAssertNever } from './never';
import { FEET_PER_METER, INCHES_PER_FOOT, MILES_PER_METER } from './units';

/* eslint-disable @typescript-eslint/no-magic-numbers */
export const DEFAULT_COORDINATE_DECIMAL_ACCURACY = 6;
export function roundLngLat(
  lngLat: number,
  precision = DEFAULT_COORDINATE_DECIMAL_ACCURACY,
) {
  return roundTo(lngLat, precision);
}

/**
 * Find the lower bound LngLat with a precision. Note this is like `roundLngLat`
 * but used to find lower bound value. Often used in conjunction with
 * `ceilLngLat` to found the best enclosing bounding box.
 */
export function floorLngLat(
  lngLat: number,
  precision = DEFAULT_COORDINATE_DECIMAL_ACCURACY,
) {
  return _.floor(lngLat, precision);
}

/**
 * Find the upper bound LngLat with a precision. Note this is like `roundLngLat`
 * but used to find upper bound value. Often used in conjunction with
 * `floorLngLat` to found the best enclosing bounding box.
 */
export function ceilLngLat(
  lngLat: number,
  precision = DEFAULT_COORDINATE_DECIMAL_ACCURACY,
) {
  return _.ceil(lngLat, precision);
}

// TOOD: combine DEFAULT_SCREEN_DPI and CSS_PPI if we can.
export const DEFAULT_SCREEN_DPI = 96;

// a CSS pixel is defined as 72 PPI
export const CSS_PPI = 72;

export interface Dimensions {
  width: number;
  height: number;
}

export enum DimensionType {
  Print = 'Print',
  Image = 'Image',
}

export type Longitude = number;
export type Latitude = number;
export type Coordinate = [Longitude, Latitude];
/**
 * The one true LngLatBounds type for UrbanFootprint. It is compatible with all of the MapboxGL apis
 * while remaining easy to manipulate for things like padding, equality checks, etc. Formatted as:
 * [swLngLat, neLngLat]
 */
export type UFLngLatBounds = [
  /**
   * swLng, swLat
   */
  Coordinate,
  /**
   * neLng, neLat
   */
  Coordinate,
];

/** Poor man's type defintion for mapbox expressions */
export type MapboxExpression = (string | number | boolean | MapboxExpression)[];

/**
 * LngLat replacement for mapbox, so it can be used both server and client side.
 */
export interface LngLat {
  lng: number;
  lat: number;
}

// 10% default padding
const MAP_BOUNDS_PADDING_PERCENT = 0.1;

// Pads the project bounds by a constant amount.
// bounds = [sw.lng, sw.lat, ne.lng, ne.lat]
// TODO: This will have to account for the meridian
// once we allow projects outside the US
export function padMapBounds(
  bounds: UFLngLatBounds,
  targetWidth: number,
  targetHeight: number,
  boundsPercent: number = MAP_BOUNDS_PADDING_PERCENT,
): UFLngLatBounds {
  if (bounds) {
    const width = Math.abs(bounds[1][0] - bounds[0][0]);
    const height = Math.abs(bounds[1][1] - bounds[0][1]);
    const centerLat = bounds[0][1] + height / 2;
    // This is the ratio of lng degrees to lat degrees, since width/height come in as pixels.
    const aspectScaleFactor = Math.cos(centerLat * (Math.PI / 180));

    const targetAspectRatio =
      (targetWidth || width) / (targetHeight || height) / aspectScaleFactor;
    const aspectRatio = width / height;

    const scale = aspectRatio / targetAspectRatio;

    let newWidth = width;
    let newHeight = height;
    if (targetAspectRatio < aspectRatio) {
      // target is too wide - grow the height
      newHeight *= scale;
    } else if (targetAspectRatio > aspectRatio) {
      // target is too tall - grow the width
      newWidth /= scale;
    }

    // max out all padding at ~1 degree, otherwise the map gets really wonky when
    // width or height gets really close to zero (and thus the aspect ratio
    // ratio approaches 0 or Infinity)
    const heightDelta = Math.min(newHeight - height, 1);
    const widthDelta = Math.min(newWidth - width, 1);
    const lngPadding = Math.min(newWidth * (boundsPercent / 2), 1);
    const latPadding = Math.min(newHeight * (boundsPercent / 2), 1);

    // expand the box to account for both the target aspect ratio, as
    // well as the requested padding
    return [
      [
        bounds[0][0] - widthDelta / 2 - lngPadding,
        bounds[0][1] - heightDelta / 2 - latPadding,
      ],
      [
        bounds[1][0] + widthDelta / 2 + lngPadding,
        bounds[1][1] + heightDelta / 2 + latPadding,
      ],
    ];
  }
  return null;
}

export function isValidLng(lng: number) {
  return lng >= -180 && lng <= 180;
}

export function isValidLat(lat: number) {
  return lat >= -90 && lat <= 90;
}

export function isValidLngLat(lnglat: LngLat) {
  return lnglat && isValidLng(lnglat.lng) && isValidLat(lnglat.lat);
}

export function isEqualBounds(
  bounds1: UFLngLatBounds,
  bounds2: UFLngLatBounds,
): boolean {
  // Sometimes the map has no initial bounds, or the project comes in
  // with no bounds. This keeps `_.zip` below from trying to compare an
  // array vs undefined values.
  if (!_.isEmpty(bounds1) !== !_.isEmpty(bounds2)) {
    return false;
  }

  return _.zip(bounds1, bounds2).every(([a, b]) =>
    _.zip(a, b).every(([av, bv]) => av.toFixed(5) === bv.toFixed(5)),
  );
}

/**
 * Compare two lnglats to see if they are equal, or close enough to equal. This
 * is meant to deal with various rounding error.
 *
 * Some rough guidelines from
 * https://gis.stackexchange.com/questions/8650/measuring-accuracy-of-latitude-and-longitude
 *
 * * 1 is ~11.1km - distinguish one city vs another
 * * 2 is ~1.1km - distinguish one village from another
 * * 3 is ~110m - distinguish large agricultural field or institutional campus
 * * 4 is ~11m - distinguish one parcel from another
 * * 5 is ~1.1m - distinguish one tree from another
 *
 * @param lnglat1 first position
 * @param lnglat2 second position
 * @param magnitude number of decimal places of accuracy
 */
export function isEqualLngLat(
  lnglat1: LngLat,
  lnglat2: LngLat,
  magnitude: number = DEFAULT_COORDINATE_DECIMAL_ACCURACY,
): boolean {
  if (!lnglat1 || !lnglat2) {
    return false;
  }
  const { lng: lng1, lat: lat1 } = lnglat1;
  const { lng: lng2, lat: lat2 } = lnglat2;
  if (lng1 === lng2 && lat1 === lat2) {
    return true;
  }
  // Make Edge happy: it blows up on toFixed()
  if (
    !Number.isFinite(lng1) ||
    !Number.isFinite(lat1) ||
    !Number.isFinite(lng2) ||
    !Number.isFinite(lat2)
  ) {
    return false;
  }
  return (
    toFixed(lng1, magnitude) === toFixed(lng2, magnitude) &&
    toFixed(lat1, magnitude) === toFixed(lat2, magnitude)
  );
}

export function metersPerDot(scale = 1) {
  return scale / (DEFAULT_SCREEN_DPI * INCHES_PER_FOOT * FEET_PER_METER);
}

const LNGLAT_ADJUST = 4;

/** Cap `value` at max */
function saturate(value: number, max: number) {
  return Math.min(value, max);
}

/**
 * Get the closest reasonable magnitude for calculating lnglat equality. We
 * calculate this by figuring out the width of a pixel and then rounding up to
 * the nearest decimal place which includes this value.
 *
 * For example, at scale = 10000 and DPI of 132, one meter is 1.76 meters. This
 * means we want to be +/- 10m which we'll find at the 4th decimal place. (@see
 * isEqualLngLat)
 *
 * @example
 * isEqualLngLat(lnglat1, lnglat2, magnitudeForScale(10000, 132))
 */
export function magnitudeForScale(scale = 1, dpi = DEFAULT_SCREEN_DPI) {
  const magnitude = Math.log10(metersPerDot(scale));
  const lnglatMagnitude = LNGLAT_ADJUST - saturate(magnitude, LNGLAT_ADJUST);
  return saturate(Math.round(lnglatMagnitude), 100);
}

export function isEqualCenter(center1: number[], center2: number[]): boolean {
  return _.zip(center1, center2).every(
    ([a, b]) => a.toFixed(5) === b.toFixed(5),
  );
}

export function setMapOnWindow(mapName: string, map: mapboxgl.Map): void {
  if (__CLIENT__ || __TESTING__) {
    window[mapName] = map;
  }
}

/**
 * This is a way to transfer a map's viewport from one map to another. Pitch,
 * bearing, and center can all transfer directly, but zoom has to changed based
 * on the differences between the two viewport sizes.
 *
 * @param sourceWidth Width (in pixels) of source viewport.
 * @param sourceHeight Height (in pixels) of the source viewport.
 * @param sourceZoom Zoom of the source viewport.
 * @param width Width (in pixels) of the target viewport.
 * @param height Height (in pixels) of the target viewport.
 */
export function getScaledZoom(
  sourceWidth: number,
  sourceHeight: number,
  sourceZoom: number,
  width: number,
  height: number,
  reverse?: boolean,
) {
  const scale = Math.min(width / sourceWidth, height / sourceHeight);
  // zoom is on a base-2 logarithmic scale
  if (reverse) {
    return sourceZoom - Math.log2(scale);
  }

  return sourceZoom + Math.log2(scale);
}

export const EARTH_RADIUS_METERS = 6371000;
export const EARTH_RADIUS_FEET = 3.2808 * EARTH_RADIUS_METERS;

/**
 * Get a point along a path.
 *
 * This allows you to start at a point, travel a distance, and find the point at
 * that distance. For instance, to find a point 50km due east of another point,
 * call `getPointAtDistance(lnglat, 90, 50000)`
 *
 * Algorithm + code inspired by:
 * https://www.movable-type.co.uk/scripts/latlong.html#destPoint
 *
 * @param start Starting point
 * @param bearingDegrees Bearing, in degrees (i.e. 0, 90, etc)
 * @param distance Distance, in desired units (defaults to meters if radius is
 *                 not specified)
 * @param radius Radius of the earth in target units (defaults to meters)
 * @returns resulting point.
 */
export function getPointAtDistance(
  start: LngLat,
  bearingDegrees: number,
  distance: number,
  radius = EARTH_RADIUS_METERS,
) {
  const bearing = bearingDegrees * RADIANS;
  const lng1 = start.lng * RADIANS;
  const lat1 = start.lat * RADIANS;
  const angularDistance = distance / radius;
  const lat2 = Math.asin(
    Math.sin(lat1) * Math.cos(angularDistance) +
      Math.cos(lat1) * Math.sin(angularDistance) * Math.cos(bearing),
  );

  const y = Math.sin(bearing) * Math.sin(angularDistance) * Math.cos(lat1);
  const x = Math.cos(angularDistance) - Math.sin(lat1) * Math.sin(lat2);
  const lng2 = lng1 + Math.atan2(y, x);
  const lng2Degrees = ((lng2 / RADIANS + 540) % 360) - 180;
  return { lng: lng2Degrees, lat: lat2 / RADIANS };
}

const RADIANS = Math.PI / 180;

/**
 * Get distance between two points.
 *
 * Uses spherical law of cosines approximation.
 *
 * @param point1 First point
 * @param point2 Second point
 * @param radius Radius of the earth in target units (defaults to meters)
 */
export function getDistance(
  point1: LngLat,
  point2: LngLat,
  radius = EARTH_RADIUS_METERS,
) {
  const lat1 = point1.lat * RADIANS;
  const lat2 = point2.lat * RADIANS;
  const lngDelta = (point2.lng - point1.lng) * RADIANS;

  const a =
    Math.sin(lat1) * Math.sin(lat2) +
    Math.cos(lat1) * Math.cos(lat2) * Math.cos(lngDelta);

  return radius * Math.acos(Math.min(a, 1));
}

/**
 * Always use this to round a calculated scale into one that is used in the UI,
 * to ensure that scale granularity is consistent across the app.
 */
export function roundScale(scale: number) {
  return roundTo(scale, -1);
}

/**
 * returns the scale ratio of the exported map to real world.  the equation was found at:
 * https://wiki.openstreetmap.org/wiki/Zoom_levels
 *
 * note that mapbox considers tiles to be 512x512 px so we use a 9 in the exponent instead of 8.
 * source: https://blog.mapbox.com/512-map-tiles-cb5bfd6e72ba
 *
 * @param zoom - zoom level of mapbox
 * @param latitude - center latitude of the map
 */
export function getScaleFromZoom(zoom: number, latitude: number) {
  const inchesPerPixel =
    (2 *
      Math.PI *
      EARTH_RADIUS_FEET *
      INCHES_PER_FOOT *
      Math.cos((Math.PI / 180) * latitude)) /
    2 ** (zoom + 9);

  const scale = CSS_PPI * inchesPerPixel;
  return scale;
}

export function getZoomFromScale(scale: number, latitude: number) {
  const EARTH_RADIUS_INCHES = EARTH_RADIUS_FEET * INCHES_PER_FOOT;
  const latitudeRadians = (Math.PI * latitude) / 180;

  const zoom =
    Math.log2(
      (2 * Math.PI * EARTH_RADIUS_INCHES * Math.cos(latitudeRadians)) /
        (scale / CSS_PPI),
    ) - 9;

  return zoom;
}
// gets the zoom level to show a bounding box within a map window.  note that we use the longer side
// of the bounding box to calculate zoom so we don't end up cutting off part of the box in the
// window. for example:
// ____________________________________________________________________
// |                                                                   |
// |            - - - - -  .(ne point)                                 |
// |            |          |                                           |
// |            |          |                                           |<-- map window
// |            |          |                                           |
// |            |          |                                           |
// |            |          |<-- bounding box                           |
// |            |          |                                           |
// |            |          |                                           |
// |            .(sw point)|                                           |
// |___________________________________________________________________|
//
// since the bounding box is much taller than it is wide and the map window is much wider than it is
// tall, if we used the width of the bounding box to calculate zoom we'd end up zooming in way too
// much and cutting off the top/bottom of the bounding box
// source for equation: https://wiki.openstreetmap.org/wiki/Zoom_levels
export function getZoomFromBounds(
  bounds: UFLngLatBounds,
  mapWidthInPixels: number,
  mapHeightInPixels: number,
): number {
  const [[swLng, swLat], [neLng, neLat]] = bounds;
  const [centerLng, centerLat] = getCenterFromBounds(bounds);

  let metersPerPixel =
    getDistance(
      { lng: swLng, lat: centerLat },
      { lng: neLng, lat: centerLat },
    ) / mapWidthInPixels;

  const lngDelta = neLng - swLng;
  const latDelta = neLat - swLat;
  if (latDelta > lngDelta) {
    metersPerPixel =
      getDistance(
        { lng: centerLng, lat: swLat },
        { lng: centerLng, lat: neLat },
      ) / mapHeightInPixels;
  }

  // NOTE: the 7 in this equation *should* be a 9 since Mapbox tiles are considered to be 512px and
  // log2(512) is 9.  7 gets better results though, not sure why.  We may have to add the
  // devicePixelRatio in here to correct for different screens.
  const zoomLevel =
    Math.log2(
      (EARTH_RADIUS_METERS * Math.cos(centerLat * RADIANS)) / metersPerPixel,
    ) - 7;

  // default to 10, protect against null height/width
  return Number.isFinite(zoomLevel) ? zoomLevel : 10;
}

export function getCenterFromBounds(bounds: UFLngLatBounds): Coordinate {
  const [[swLng, swLat], [neLng, neLat]] = bounds;

  return [roundLngLat((swLng + neLng) / 2), roundLngLat((swLat + neLat) / 2)];
}

export function flattenLngLatBounds(
  lngLatBounds: LngLatBounds,
): UFLngLatBounds {
  // Cast this because this returns an array of tuples, but is typed as number[][]
  return lngLatBounds.toArray() as UFLngLatBounds;
}

const feetBreaks = [20, 40, 200, 400, 600, 1000, 2000];
const lastFootBreak = feetBreaks[feetBreaks.length - 1];
const mileBreaks = [1, 2, 4, 10, 20, 40, 200, 400, 800, 1000];

const metricBreaks = [1, 2, 4, 10, 20, 40, 200, 400, 800, 1000];

/**
 *  Gets the max value for a scale bar that goes from 0 to [max].  Given a distance in meters, and
 *  the unit to convert to, this function will return the closest value in the pre-determined
 *  breaks.  Break values were chosen such that they can be nicely divided in half and by 4.  For
 *  example, given a distance of 100m and a unit of 'feet', getMaxValueForScaleBar will return 400:
 *
 *  feet breaks = [20, 40, 200,   400, 600, 1000, 2000];
 *                              ^
 *                              \-- 100m (328ft) is closest to 400 so this is returned.
 *
 *  We can now make a nice scale bar:
 *
 *  0      100     200             400
 *  |***|   |***|   |***************|
 *
 *  @param distanceInMeters - an approximate distance to show on the scale bar
 *  @param unit - the unit of return value.  not that the
 *  breaks are not the same for different units.
 */

export interface ScaleBarInfo {
  maxValue: number;
  unit: string;
}

export function getScaleBarMaxSize(
  distanceInMeters: number,
  scaleUnits: MapExportControlSettings.ScaleUnitsEnum,
): ScaleBarInfo {
  if (scaleUnits === 'us') {
    const distanceInFeet = distanceInMeters * FEET_PER_METER;
    const useMiles = distanceInFeet > lastFootBreak;
    const breaks = useMiles ? mileBreaks : feetBreaks;
    const distance = useMiles
      ? distanceInMeters * MILES_PER_METER
      : distanceInFeet;
    const maxValue = getClosestValue(distance, breaks);

    return {
      maxValue,
      unit: useMiles ? 'miles' : 'feet',
    };
  }

  // TODO:  when we actually start showing metic in the scale bar, add autoswitching to km.
  if (scaleUnits === 'metric') {
    const maxValue = getClosestValue(distanceInMeters, metricBreaks);

    return {
      maxValue,
      unit: 'meters',
    };
  }
}

function getClosestValue(value: number, array: number[]): number {
  const index = array.findIndex(val => value < val);

  if (index === -1) {
    return array[array.length - 1];
  }

  if (index === 0) {
    return array[0];
  }

  const lowerValue = array[index - 1];
  const upperValue = array[index];
  const deltaToLowerValue = value - lowerValue;
  const deltaToUpperValue = upperValue - value;
  return deltaToLowerValue < deltaToUpperValue ? lowerValue : upperValue;
}

/**
 * Converts a LayerBounds object to a UFLngLatBounds object
 * @param bounds - the layer bounds
 */
export function getLngLatBoundsFromLayerBounds(
  bounds: LayerBounds,
): UFLngLatBounds {
  const { x_min: xMin, x_max: xMax, y_min: yMin, y_max: yMax } = bounds;
  return [
    [xMin, yMin],
    [xMax, yMax],
  ];
}

/**
 * Gets the LngLat coordinates for all vertices in a feature.
 */
export function extractFeatureCoordinates(feature: Feature): Position[] {
  switch (feature?.geometry?.type) {
    // coordinates is a single coordinate, add the outter array for consistency
    case 'Point':
      return [feature?.geometry?.coordinates];

    // coordinates are in a 2-dimensional array
    case 'LineString':
    case 'MultiPoint':
      return feature?.geometry?.coordinates;

    // coordinates are a 3-dimensional array, flatten for consistency
    case 'MultiLineString':
    case 'Polygon':
      return _.flatten(feature?.geometry?.coordinates);

    // coordinates are in a 4-dimensional array
    case 'MultiPolygon':
      // flattenDepth does a bad job of resolving the flattened type, so we cast
      // to 'unknown' first and lock this down in test
      return _.flattenDepth(
        feature?.geometry?.coordinates,
        2,
      ) as unknown as Position[];

    case 'GeometryCollection':
    default:
      // Can't assertNever() here because not all features have the "geometry" property
      return [];
  }
}

/**
 * Generate a feature with the specified vertices removed.
 *
 * If all vertices match, then this function returns null, which means the
 * feature itself should probably be deleted.
 */
export function removeVerticesFromFeature<G extends Geometry>(
  feature: Feature<G>,
  coordPaths: string[],
): Feature<G> {
  const { geometry } = feature;
  const updatedGeometry = removeVerticesFromGeometry(geometry, coordPaths) as G;
  if (!updatedGeometry) {
    return null;
  }
  return {
    ...feature,
    geometry: updatedGeometry,
  };
}

/**
 * Helper function to remove vertices from an arbitrary geometry.
 */
function removeVerticesFromGeometry(
  geometry: Geometry,
  coordPaths: string[],
): Geometry {
  switch (geometry?.type) {
    case 'MultiPoint':
    case 'LineString': {
      const { coordinates } = geometry;

      const updatedCoordinates = filterByCoordPaths(coordinates, coordPaths);
      if (!updatedCoordinates.length) {
        return null;
      }
      return {
        ...geometry,
        coordinates: updatedCoordinates,
      };
    }

    case 'Point': {
      const { coordinates } = geometry;
      // There can only be a single coordinate in a Point feature
      if (coordinates && coordPaths[0] === '0') {
        return null;
      }
      return geometry;
    }

    case 'MultiLineString':
    case 'Polygon': {
      const { coordinates } = geometry;
      const updatedCoordinates = filterByCoordPaths(coordinates, coordPaths);
      if (!updatedCoordinates.length) {
        return null;
      }
      return {
        ...geometry,
        coordinates: updatedCoordinates,
      };
    }
    case 'MultiPolygon': {
      const { coordinates } = geometry;
      const updatedCoordinates = filterByCoordPaths(coordinates, coordPaths);
      if (!updatedCoordinates.length) {
        return null;
      }
      return {
        ...geometry,
        coordinates: updatedCoordinates,
      };
    }
    case 'GeometryCollection': {
      const { geometries } = geometry;
      const updatedGeometries = geometries
        .map(subGeometry => removeVerticesFromGeometry(subGeometry, coordPaths))
        .filter(subGeometry => !!subGeometry);

      if (!updatedGeometries.length) {
        return null;
      }

      return {
        ...geometry,
        geometries: updatedGeometries,
      };
    }
    default:
      typeAssertNever(geometry);
      return geometry;
  }
}

/**
 * Helper function to remove a list of vertices from a list of vertices.
 */
function filterByCoordPaths<T>(coordinates: T[], coordPaths: string[]): T[] {
  return coordinates.filter((coord, index) => {
    return !coordPaths.includes(index.toString());
  });
}
