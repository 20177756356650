import { Store } from 'redux';

import { syncAnalysisEditorStateWithUrl } from 'uf/analysiseditor/routing/sync';
import { syncAppUIStateWithBrowserUrl } from 'uf/app/routing/sync';
import { syncBuiltFormsStateWithUrl } from 'uf/builtforms/routing/sync';
import { syncExploreStateWithUrl } from 'uf/explore/routing/sync';
import { syncInternalStateWithUrl } from 'uf/internal/routing/sync';
import { syncReportingStateWithUrl } from 'uf/reporting/routing/sync';

/** syncBrowserUrlWithReduxState()
 *  This is the main function that subscribes to the store and updates the url
 *  when state changes.  This is done using 'redux-watch' to attach listeners to slices of state.
 *  As this grows in complexity we should look to use something that can handle a large number of
 *  subscribers well.  redux-subscribe seems to offer this.
 */
export default function syncBrowserUrlWithReduxState(store: Store): () => void {
  const unsubscribes = [
    syncAppUIStateWithBrowserUrl(store),
    syncExploreStateWithUrl(store),
    syncBuiltFormsStateWithUrl(store),
    syncAnalysisEditorStateWithUrl(store),
    syncReportingStateWithUrl(store),
    syncInternalStateWithUrl(store),
  ];

  return () => {
    unsubscribes.forEach(unsubscribe => unsubscribe());
  };
}
