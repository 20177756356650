import React from 'react';

interface Props {
  className?: string;
  style?: React.CSSProperties;
}

export default function IconDashboard(props: Props) {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g clipPath="url(#clip0_5121_12)">
        <path
          fill="currentColor"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.24996 3.74991V2.24996H17.7495V3.74991H2.24996ZM1.99997 3.50475e-05C0.895439 3.50475e-05 3.50475e-05 0.895439 3.50475e-05 1.99997V4.87488V5.99984H1.125H18.8744H19.9994V4.87488V1.99997C19.9994 0.895533 19.1041 3.50475e-05 17.9995 3.50475e-05H1.99997ZM8.99973 8H0V10.9999H8.99973V8ZM11 8H20V18C20 19.1046 19.1046 20 18 20H11V8ZM8.99973 13H0V17.9998C0 19.1043 0.895405 19.9997 1.99994 19.9997H8.99973V13Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_5121_12">
          <path fill="#fff" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
