import { Callout, HTMLTable } from '@blueprintjs/core';
import classNames from 'classnames';
import _ from 'lodash';
import React, { Fragment, FunctionComponent } from 'react';
import { t } from 'ttag';

import { LayerMetadata } from 'uf-api';
import { shouldHideColumn } from 'uf/layers/metadata';
import gridStyles from 'uf/styles/grid.module.css';
import rootStyles from 'uf/styles/root.module.css';
import textStyles from 'uf/styles/text.module.css';

import ColumnMetadataSummary from './ColumnMetadataSummary';

interface Props {
  layerMetadata: LayerMetadata;
  displayPanelView: boolean;
  charLimit?: number;
}
export const LayerColumns: FunctionComponent<Props> = props => {
  const { layerMetadata, displayPanelView, charLimit } = props;
  const { columns = [], unique_keys: uniqueKeys } = layerMetadata;
  // Hiding internal UF columns
  const visibleColumns = columns.filter(column => !shouldHideColumn(column));
  const tableClassName = classNames(
    gridStyles.table,
    gridStyles.tableLayerManager,
    {
      [gridStyles.simpleTableLayerManager]: displayPanelView,
    },
  );
  const tableHeaderClassName = classNames(
    gridStyles.table,
    gridStyles.tableLayerManager,
    {
      [gridStyles.simpleTableLayerManager]: displayPanelView,
    },
    rootStyles.shrink0,
  );
  return (
    <Fragment>
      {!_.isEmpty(visibleColumns) && (
        <Fragment>
          <HTMLTable className={tableHeaderClassName}>
            <thead>
              <tr className="h4">
                <th>{t`Column`}</th>
                <th>{t`Description`}</th>
                {!displayPanelView && <th>{t`Type`}</th>}
              </tr>
            </thead>
          </HTMLTable>
          <HTMLTable className={tableClassName} striped>
            <tbody>
              {visibleColumns.map(column => (
                <ColumnMetadataSummary
                  key={column.key}
                  charLimit={charLimit}
                  columnMetadata={column}
                  uniqueKeys={uniqueKeys}
                  hideTypeColumn={displayPanelView}
                />
              ))}
            </tbody>
          </HTMLTable>
        </Fragment>
      )}
      {_.isEmpty(visibleColumns) && (
        <Callout icon={null} className={textStyles.centerAlign}>
          <small>
            {t`No additional attributes available for this dataset`}
          </small>
        </Callout>
      )}
    </Fragment>
  );
};
