import { AnyAction } from 'redux';
import { ActionsObservable, Epic, ofType } from 'redux-observable';
import {
  empty as observableEmpty,
  merge as observableMerge,
  of as observableOf,
} from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';

import { ProjectMetadata } from 'uf-api';
import { getProjectActionTypes } from 'uf-api/api/project.service';
import { getActiveProject } from 'uf/app/selectors';
import { combineEpics } from 'uf/base/epics';
import { SuccessAction } from 'uf/data/ActionTypes';
import { ScenarioCloneTaskMessage } from 'uf/projects/tasks';
import { getScenarioUpdateStatus } from 'uf/scenarios/actions';
import { createScenarioMessageTypes } from 'uf/scenarios/ActionTypes';
import { getScenariosUpdateStatus } from 'uf/scenarios/selectors';
import { UFState } from 'uf/state';
import { makeTaskActionType } from 'uf/tasks/actionHelpers';
import { notificationAction } from 'uf/tasks/actions';
import { NotificationAction } from 'uf/tasks/ActionTypes';
import { ofNotificationType } from 'uf/tasks/observables';
import { TaskTypes } from 'uf/tasks/TaskTypes';

export const ensureAllScenariosStatus: Epic<
  SuccessAction<ProjectMetadata>,
  any,
  UFState
> = (action$: ActionsObservable<AnyAction>, state$) => {
  return action$.pipe(
    ofType(getProjectActionTypes.SUCCESS),
    mergeMap(() => {
      const project = getActiveProject(state$.value);
      if (!project || !project.scenarios) {
        return observableEmpty();
      }

      const projectId = project.full_path;
      const scenarioKeys = project.scenarios.map(scenario => scenario.key);
      const updateStatuses = getScenariosUpdateStatus(state$.value);
      const actions = [];

      scenarioKeys.forEach(scenarioKey => {
        const updateStatus = updateStatuses[scenarioKey];
        if (!updateStatus) {
          actions.push(
            observableOf(getScenarioUpdateStatus(scenarioKey, projectId)),
          );
        }
      });
      return observableMerge(...actions);
    }),
  );
};

export const dispatchCloneScenarioActions: Epic<
  NotificationAction<ScenarioCloneTaskMessage>
> = action$ => {
  return action$.pipe(
    ofNotificationType<ScenarioCloneTaskMessage>(
      TaskTypes.TASK_TYPE_PROJECT_SCENARIO_CLONE,
    ),
    // TODO: remove this filter when we handle all of the websocket statuses with a better
    // reducer.  currently we have a bad mapping of websockets statuses to data loading statuses
    // so we can use makeKeyedPromiseReducer.
    filter(({ result }) => createScenarioMessageTypes[result.status]),
    map(({ result, key }) => {
      const actionType = makeTaskActionType(
        createScenarioMessageTypes[result.status],
      );
      const extra = {
        result,
      };

      return notificationAction(actionType, result, key, extra);
    }),
  );
};

export default combineEpics(
  {
    ensureAllScenariosStatus,
    dispatchCloneScenarioActions,
  },
  'scenarios',
);
