import { AnyAction } from 'redux';

export const routingPrefix = 'uf/routing/';

/**
 * converts uf route action types to route action types to be consumed by redux-first-router
 * @param actionType the action type to be converted
 */
export function makeRoutingActionType(actionType: string) {
  return `${routingPrefix}${actionType}`;
}

/**
 * converts uf route actions to route actions to be consumed by redux-first-router
 * @param action to be converted
 */
export function convertRoutingAction(action: AnyAction) {
  return {
    ...action,
    type: makeRoutingActionType(action.type),
  };
}
