import { Callout, Menu, MenuDivider, Position } from '@blueprintjs/core';
import React, { FunctionComponent, useEffect } from 'react';
import { connect } from 'react-redux';
import { t } from 'ttag';
import { tw } from 'uf/tailwindcss-classnames';

import {
  useEnqueueColumnExportTaskMutation,
  UserAction,
} from 'uf-api-rtk/store/Api';
import { LayerServiceInterface } from 'uf-api/api/layer.serviceInterface';
import { getActiveProjectId } from 'uf/app/selectors';
import { parseFullPath } from 'uf/base/dataset';
import { DataState, getData, isLoaded } from 'uf/data/dataState';
import { ColumnKey, getExportPermissionText, LayerId } from 'uf/layers';
import { beginExport, BeginExportAction } from 'uf/layers/actions/export';
import { events } from 'uf/layers/logging';
import { shouldHideColumn } from 'uf/layers/metadata';
import { makeGetLayerMetadata } from 'uf/layers/selectors/metadata';
import { logEvent } from 'uf/logging';
import { ProjectId } from 'uf/projects';
import OverflowMenuItem from 'uf/ui/base/OverflowMenuItem/OverflowMenuItem';
import { sources } from 'uf/ui/layers/logging';
import { ensureCheckFeature } from 'uf/user/actions/permissions';
import { makeGetCheckFeatureDetails } from 'uf/user/selectors/permissions';

// Types of file formats that we can export
type ExportLayerFileFormat =
  LayerServiceInterface.enqueueLayerExportTaskParams['file_format'];

interface StateProps {
  canExport: DataState<UserAction>;
  projectId: ProjectId;
  canCombinedExport: DataState<UserAction>;
  columns?: string[];
}
interface OwnProps {
  layerId: LayerId;
  columnKey: ColumnKey;
  onDismiss?: () => void;
  ensureCheckFeature?: (verb: string, resource: string) => Promise<boolean>;
  beginExport?: BeginExportAction;
}

interface OwnProps {
  layerId: LayerId;
  columnKey: ColumnKey;
  onDismiss?: () => void;
  ensureCheckFeature?: (verb: string, resource: string) => Promise<boolean>;
  beginExport?: BeginExportAction;
}

interface OnExportParameters {
  includeGeometries?: boolean;
  includeColumnKey?: boolean;
}

type Props = OwnProps & StateProps;

export const LayerExportMenuItems: FunctionComponent<Props> = ({
  canExport,
  canCombinedExport,
  columnKey,
  columns,
  layerId,
  projectId,
  onDismiss,
  ...actions
}) => {
  const [enqueueColumnExport] = useEnqueueColumnExportTaskMutation();

  useEffect(() => {
    void actions.ensureCheckFeature('layer.export', layerId);
    void actions.ensureCheckFeature('layer.combined_export', layerId);
  }, [actions, layerId]);

  // We have the file format enum stored in LayerServiceInterface
  const onExport = (
    type: ExportLayerFileFormat,
    { includeGeometries, includeColumnKey }: OnExportParameters = {
      includeGeometries: true,
      includeColumnKey: false,
    },
    exportColumns?: string[],
  ) => {
    // Determines whether we should include the columnKey parameter
    const columnKeyParam = includeColumnKey ? columnKey : null;

    void actions.beginExport(
      projectId,
      layerId,
      null,
      type,
      includeGeometries,
      null,
      columnKeyParam,
      exportColumns,
    );

    // CSV with Geometries gets a custom name in the logs
    const logType: string =
      type === 'csv' && includeGeometries ? 'csv with geometries' : type;

    logEvent(events.LAYER_EXPORTED, {
      projectId,
      layerId,
      type: logType,
      source: sources.LAYER_LIST,
    });
    onDismiss();
  };

  const handleExport = e => {
    const { name } = e.currentTarget;
    switch (name) {
      case 'csv_with_geo':
        return onExport('csv', { includeGeometries: true }, columns);
      case 'csv':
        return onExport('csv', { includeGeometries: false }, columns);
      case 'shp':
        if (combinedExportDisabled) {
          return onExport('shp_split');
        }
        return onExport('shp');
      default:
        return onExport(name);
    }
  };

  const handleExportColumnCSV = () => {
    const { namespace, type, key } = parseFullPath(layerId);
    void enqueueColumnExport({
      namespace,
      layerType: type,
      key,
      body: columns,
    });
  };

  const exportDisabled = !isLoaded(canExport) || !getData(canExport)?.can_do;
  const exportDisabledReason =
    exportDisabled && isLoaded(canExport) ? getData(canExport)?.reason : null;

  const combinedExportDisabled =
    !isLoaded(canCombinedExport) || !getData(canCombinedExport)?.can_do;
  const combinedExportDisabledReason =
    combinedExportDisabled && isLoaded(canCombinedExport)
      ? getData(canCombinedExport)?.reason
      : null;

  let exportRestricted = false;
  let exportRestrictedHelpText;
  let exportRestrictedHelpURL;

  if (exportDisabled && exportDisabledReason === 'restriction') {
    exportRestricted = true;
    const reasonDetail = getData(canExport)?.reason_detail;
    if (reasonDetail?.reason_detail_type === 'feature_count') {
      const maxExportFeatureCount = reasonDetail?.max_export_feature_count;
      exportRestrictedHelpText = t`Download of layers with more than ${maxExportFeatureCount} rows not supported. Reduce the number of rows to download. `;
      exportRestrictedHelpURL =
        'https://docs.urbanfootprint.com/private/analyst/en/index-en.html?contextId=layersizelimit';
    } else {
      exportRestrictedHelpText = t`Data download restricted for this location. `;
      exportRestrictedHelpURL =
        'https://docs.urbanfootprint.com/private/analyst/en/index-en.html?contextId=parceldatarestrictions';
    }
  } else if (
    combinedExportDisabled &&
    combinedExportDisabledReason === 'restriction'
  ) {
    exportRestricted = true;
    exportRestrictedHelpText = t`Features and data export as separate files. `;
    exportRestrictedHelpURL =
      'https://docs.urbanfootprint.com/private/analyst/en/index-en.html?contextId=parceldatarestrictions';
  }
  return (
    <Menu>
      {exportRestricted && (
        <Callout className={tw('tw-max-w-sm')}>
          <h4 className={tw('tw-font-bold')}>{t`Layer has restrictions`}</h4>
          <span>{exportRestrictedHelpText}</span>
          <a
            className={tw('tw-text-blue-600')}
            href={exportRestrictedHelpURL}
            target="_blank"
            rel="noopener noreferrer">
            Learn more.
          </a>
        </Callout>
      )}
      <MenuDivider title={t`Layer data`} />
      <OverflowMenuItem
        name="csv_with_geo"
        label={t`CSV with geometries`}
        className={'OverflowMenuItem-export-csv-geo'}
        onClick={handleExport}
        disabled={exportDisabled || combinedExportDisabled}
        tooltip={
          !exportRestricted &&
          getExportPermissionText(
            !exportDisabled && !combinedExportDisabled,
            exportDisabledReason || combinedExportDisabledReason,
            t`Export this layer to CSV with feature geometries in a column in WKT format`,
          )
        }
        tooltipPosition={Position.LEFT}
      />
      <OverflowMenuItem
        name="csv"
        label={t`CSV w/o geometries`}
        className={'OverflowMenuItem-export-csv-no-geo'}
        onClick={handleExport}
        disabled={exportDisabled}
        tooltip={
          !exportRestricted &&
          getExportPermissionText(
            !exportDisabled,
            exportDisabledReason,
            t`Export only data columns in this layer to CSV`,
          )
        }
        tooltipPosition={Position.LEFT}
      />
      <OverflowMenuItem
        name="geojson"
        label={t`GeoJSON`}
        className={'OverflowMenuItem-export-geojson'}
        onClick={handleExport}
        disabled={exportDisabled || combinedExportDisabled}
        tooltip={
          !exportRestricted &&
          getExportPermissionText(
            !exportDisabled && !combinedExportDisabled,
            exportDisabledReason || combinedExportDisabledReason,
            t`Export this layer to a GeoJSON`,
          )
        }
        tooltipPosition={Position.LEFT}
      />
      <OverflowMenuItem
        name="gpkg"
        label={t`GeoPackage`}
        className={'OverflowMenuItem-export-gpkg'}
        onClick={handleExport}
        disabled={exportDisabled || combinedExportDisabled}
        tooltip={
          !exportRestricted &&
          getExportPermissionText(
            !exportDisabled && !combinedExportDisabled,
            exportDisabledReason || combinedExportDisabledReason,
            t`Export this layer to a GeoPackage`,
          )
        }
        tooltipPosition={Position.LEFT}
      />
      <OverflowMenuItem
        name="shp"
        label={t`Shapefile`}
        className={'OverflowMenuItem-export-shp'}
        onClick={handleExport}
        disabled={exportDisabled}
        tooltip={
          !exportRestricted &&
          getExportPermissionText(
            !exportDisabled,
            exportDisabledReason,
            t`Export this layer to a zipped shapefile`,
          )
        }
        tooltipPosition={Position.LEFT}
      />
      <OverflowMenuItem
        name="svg"
        label={t`SVG`}
        className={'OverflowMenuItem-export-svg'}
        onClick={handleExport}
        disabled={exportDisabled || combinedExportDisabled}
        tooltip={
          !exportRestricted &&
          getExportPermissionText(
            !exportDisabled && !combinedExportDisabled,
            exportDisabledReason || combinedExportDisabledReason,
            t`Export this layer to an SVG file with data for the current column.`,
          )
        }
        tooltipPosition={Position.LEFT}
      />
      <MenuDivider title={t`Column descriptions`} />
      <OverflowMenuItem
        label="CSV"
        onClick={handleExportColumnCSV}
        disabled={exportDisabled || combinedExportDisabled || !columns?.length}
      />
    </Menu>
  );
};

export const mapStateToProps = () => {
  const getCheckFeature = makeGetCheckFeatureDetails();
  const getLayerMetadata = makeGetLayerMetadata();
  return (state, ownProps: OwnProps): StateProps => {
    const layerMetadata = getLayerMetadata(state, {
      layerId: ownProps.layerId,
    });
    const columns = layerMetadata.columns
      ?.filter(column => !shouldHideColumn(column))
      .map(column => column.key);
    return {
      canExport: getCheckFeature(state, {
        verb: 'layer.export',
        resource: ownProps.layerId,
      }),
      projectId: getActiveProjectId(state),
      canCombinedExport: getCheckFeature(state, {
        verb: 'layer.combined_export',
        resource: ownProps.layerId,
      }),
      columns,
    };
  };
};

export default connect(mapStateToProps, {
  ensureCheckFeature,
  beginExport,
})(LayerExportMenuItems);
