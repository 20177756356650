import { Collapse } from '@blueprintjs/core';
import { boundMethod } from 'autobind-decorator';
import classNames from 'classnames';
import React, { Component, ReactNode } from 'react';
import { Box } from 'react-layout-components';

import rootStyles from 'uf/styles/root.module.css';
import { tw } from 'uf/tailwindcss-classnames';
import CollapsibleListHeader from 'uf/ui/base/CollapsibleList/CollapsibleListHeader';

export interface CollapsibleListProps {
  // For attaching classes to the entire collapsible list
  className?: string;
  dataAttributes?: Record<string, string>;

  // CollapsibleList provides the collapse button and a consistent
  // grey header. Most often you'll just pass the header as a string,
  // but you can also pass a JSX element as the header if you want to
  // add kebabs or other options.
  header: ReactNode;

  // For attaching classes to the collapse button in the header
  headerClassName?: string;
  headerDataAttributes?: Record<string, string>;

  // For attaching classes to the ListGroup container
  bodyClassName?: string;
  bodyDataAttributes?: Record<string, string>;

  // A callback that accepts the current collapsed state.
  // Useful if this needs to be a controlled component
  onCollapse?: (collapsed: boolean) => void;

  collapsed?: boolean;
  keepChildrenMounted?: boolean;
}

interface CollapsibleListState {
  collapsed: boolean;
}

const listClassName = classNames(
  rootStyles.defaultBorderColor,
  rootStyles.defaultBorderLeft,
  rootStyles.defaultBorderRight,
);
export default class CollapsibleList extends Component<
  CollapsibleListProps,
  CollapsibleListState
> {
  static defaultProps = {
    header: '',
    collapsed: false,
    onCollapse: () => {},
    keepChildrenMounted: true,
  };

  state = {
    collapsed: this.props.collapsed,
  };

  componentDidUpdate(prevProps: CollapsibleListProps) {
    if (
      prevProps.collapsed !== this.props.collapsed &&
      this.props.collapsed !== this.state.collapsed
    ) {
      this.setState({ collapsed: this.props.collapsed });
    }
  }

  @boundMethod
  toggleCollapse() {
    this.setState(
      prevState => ({ collapsed: !prevState.collapsed }),
      () => this.props.onCollapse(this.state.collapsed),
    );
  }

  render() {
    const {
      className,
      dataAttributes,
      header,
      headerClassName,
      headerDataAttributes,
      bodyClassName,
      bodyDataAttributes,
      keepChildrenMounted,
      children,
    } = this.props;

    // Remove default margins so that CollapsibleList elements
    // will stack nicely on top of each other
    const listGroupClass = classNames(
      rootStyles.shrink,
      bodyClassName,
      tw('tw-divide-y'),
    );

    return (
      <div className={classNames(className, listClassName)} {...dataAttributes}>
        <CollapsibleListHeader
          headerClassName={headerClassName}
          headerDataAttributes={headerDataAttributes}
          collapsed={this.state.collapsed}
          onCollapse={this.toggleCollapse}>
          {header}
        </CollapsibleListHeader>

        <Collapse
          isOpen={!this.state.collapsed}
          keepChildrenMounted={keepChildrenMounted}>
          <Box column className={listGroupClass} {...bodyDataAttributes}>
            {children}
          </Box>
        </Collapse>
      </div>
    );
  }
}
