import { Spinner } from '@blueprintjs/core';
import {
  mdiChartBar,
  mdiDomain,
  mdiFolderEdit,
  mdiMap,
  mdiNewspaper,
} from '@mdi/js';
import classNames from 'classnames';
import React, { FunctionComponent, useCallback } from 'react';
import { Box } from 'react-layout-components';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { t } from 'ttag';

import { UserAction } from 'uf-api';
import { AppMode } from 'uf/app/modes';
import {
  getActiveProjectId,
  haveActiveProjectScenarios,
  makeGetActiveScenarioIdForProject,
} from 'uf/app/selectors';
import { assertNever } from 'uf/base/never';
import { getDashboardBase } from 'uf/base/api';
import { setDetailsPaneTabKey, setUIProperty } from 'uf/explore/actions';
import { setDetailsBuildTabVisible } from 'uf/explore/actions/details';
import { TabKey } from 'uf/explore/details';
import {
  getAnalysisUIVisible,
  getBuildUIVisible,
  getExploreViewVisible,
  getManageViewVisible,
  getReportViewVisible,
  makeGetLayerActiveDetailTabKey,
} from 'uf/explore/selectors/explore';
import { getActiveLayerId } from 'uf/explore/selectors/layers';
import { makeGetActiveViewId } from 'uf/explore/selectors/views';
import { LayerId } from 'uf/layers';
import { events, logEvent } from 'uf/logging';
import { ProjectId } from 'uf/projects';
import { getExploreMode } from 'uf/routing/selectors';
import { ScenarioId } from 'uf/scenarios';
import tw from 'uf/tailwindcss-classnames';
import CanPerform from 'uf/ui/base/CanPerform/CanPerform';
import ModeBarItem from 'uf/ui/main/ModeBar/ModeBarItem';
import { useProject, useProjectType } from 'uf/ui/projects/hooks';
import ProjectTypeIcon from 'uf/ui/projects/ProjectTypeIcon/ProjectTypeIcon';
import IconDashboard from './IconDashboard';
import styles from './ModeBar.module.css';
import useUserFlag from 'uf/ui/user/useUserFlag';

function logModeSwitch(mode, projectId) {
  logEvent(events.MODE_SWITCHED, { mode, projectId });
}

interface StateProps {
  projectId: ProjectId;
  scenarioId: ScenarioId;
  layerId: LayerId;
  activeDetailsTab: TabKey;
  haveScenarios: boolean;
  exploreMode: boolean;
  exploreViewVisible: boolean;
  reportViewVisible: boolean;
  manageViewVisible: boolean;
  paintUIVisible: boolean;
  analysisUIVisible: boolean;
}

interface DispatchProps {
  setExploreViewVisible: (visible: boolean) => void;
  setReportViewVisible: () => void;
  setManageViewVisible: () => void;
  setAnalysisUIVisible: (visible: boolean) => void;
  setBuildUIVisible: (visible: boolean) => void;
  setBuildTabVisible: typeof setDetailsBuildTabVisible;
  setDetailsPaneTab: typeof setDetailsPaneTabKey;
}

type Props = StateProps & DispatchProps;

const iconStyles = {
  height: '26px',
  width: '26px',
  padding: '3px',
};

export const ModeBar: FunctionComponent<Props> = props => {
  const {
    exploreViewVisible,
    analysisUIVisible,
    paintUIVisible,
    setExploreViewVisible,
    setReportViewVisible,
    setManageViewVisible,
    setAnalysisUIVisible,
    setBuildUIVisible,

    projectId,
    scenarioId,
    layerId,
    setBuildTabVisible,
    setDetailsPaneTab,
    activeDetailsTab,
    haveScenarios,
    exploreMode,
    reportViewVisible,
    manageViewVisible,
  } = props;

  const project = useProject(projectId);
  const showLocationAnalysis = useUserFlag('location-analysis');
  const { projectType } = useProjectType(project.data?.project_type);

  const onClickModeItem = useCallback(
    eventKey => {
      logModeSwitch(eventKey, projectId);
      const dashboardURL = `${getDashboardBase()}/organization${projectId}`;
      const mode = eventKey as AppMode;
      switch (mode) {
        case AppMode.Explore:
          setExploreViewVisible(!exploreViewVisible);
          break;

        case AppMode.Report:
          setReportViewVisible();
          break;

        case AppMode.Paint:
          setBuildUIVisible(!paintUIVisible);
          if (activeDetailsTab === TabKey.BUILD && exploreViewVisible) {
            setDetailsPaneTab(projectId, layerId, TabKey.STYLE);
          } else {
            setBuildTabVisible(projectId, scenarioId);
          }
          break;

        case AppMode.Analyze:
          setAnalysisUIVisible(!analysisUIVisible);
          break;

        case AppMode.Manage:
          setManageViewVisible();
          break;

        case AppMode.Dashboard:
          window.location.href = dashboardURL;
          break;

        default:
          // Unreachable if this switch is exhaustive
          assertNever(mode);
      }
    },
    [
      activeDetailsTab,
      analysisUIVisible,
      exploreViewVisible,
      layerId,
      paintUIVisible,
      projectId,
      scenarioId,
      setAnalysisUIVisible,
      setBuildTabVisible,
      setBuildUIVisible,
      setDetailsPaneTab,
      setExploreViewVisible,
      setManageViewVisible,
      setReportViewVisible,
    ],
  );

  const buildActive = activeDetailsTab === TabKey.BUILD && exploreViewVisible;
  const hasNoProjects = !(projectType || project.key);
  return (
    <CanPerform verb="scenario.update" resources={projectId}>
      {({ disabled, reason }) => {
        const isHidden =
          disabled && reason === UserAction.ReasonEnum.Permission;
        return (
          <Box
            column
            alignItems="center"
            style={{
              backgroundColor:
                projectType?.style?.background_color ?? '#f4f7fb',
            }}
            className={classNames(styles.modeBar, 'ModeBar')}>
            {projectType || hasNoProjects ? (
              <>
                <div
                  className={styles.modeBarTypeIcon}
                  // 40 suffix on hex code sets opacity to 25%
                  style={{
                    backgroundColor: `${
                      projectType?.style?.color ?? '#6E88A6'
                    }40`,
                  }}>
                  <ProjectTypeIcon
                    source={projectType?.icon_url}
                    label={projectType?.key}
                    size={20}
                  />
                </div>
                <ModeBarItem
                  active={exploreViewVisible && exploreMode}
                  className={'Explore'}
                  eventKey={AppMode.Explore}
                  iconPath={mdiMap}
                  onClick={onClickModeItem}
                  tooltipContent={t`Map`}
                />
              </>
            ) : (
              // Prevents disparity in styling when switching to a project of a new type for the first time
              <div className={tw('tw-p-3')}>
                <Spinner size={36} />
              </div>
            )}
            {/* TODO: This will need to be updated once project types other than land use can include a base canvas */}
            {projectType?.key === 'land_use' && !isHidden && (
              <>
                <ModeBarItem
                  active={buildActive}
                  className={'Build'}
                  disabled={!haveScenarios || disabled}
                  disabledTooltipContent={
                    disabled
                      ? t`Build mode is disabled.`
                      : t`Select a Project First`
                  }
                  eventKey={AppMode.Paint}
                  iconPath={mdiDomain}
                  onClick={onClickModeItem}
                  tooltipContent={t`Build`}
                />
                <ModeBarItem
                  disabled={disabled}
                  active={analysisUIVisible}
                  className={'Analyze'}
                  eventKey={AppMode.Analyze}
                  iconPath={mdiChartBar}
                  onClick={onClickModeItem}
                  tooltipContent={t`Analyze`}
                  disabledTooltipContent={t`Analysis is disabled.`}
                />
                <ModeBarItem
                  disabled={disabled}
                  active={reportViewVisible}
                  className={'Report'}
                  eventKey={AppMode.Report}
                  iconPath={mdiNewspaper}
                  onClick={onClickModeItem}
                  tooltipContent={t`Report`}
                  disabledTooltipContent={t`Reporting mode is disabled.`}
                />
                <ModeBarItem
                  disabled={disabled}
                  active={manageViewVisible}
                  className={'Manage'}
                  eventKey={AppMode.Manage}
                  iconPath={mdiFolderEdit}
                  onClick={onClickModeItem}
                  tooltipContent={t`Manage`}
                  disabledTooltipContent={t`Manage mode is disabled.`}
                />
              </>
            )}
            {showLocationAnalysis && (
              <ModeBarItem
                active={false}
                className={'Dashboard'}
                eventKey={AppMode.Dashboard}
                icon={<IconDashboard style={iconStyles} />}
                onClick={onClickModeItem}
                tooltipContent={t`Dashboards`}
              />
            )}
          </Box>
        );
      }}
    </CanPerform>
  );
};

function makeMapStateToProps() {
  const getDetailsTabKey = makeGetLayerActiveDetailTabKey();

  const getActiveViewId = makeGetActiveViewId();
  const getActiveScenarioId = makeGetActiveScenarioIdForProject();
  return (state): StateProps => {
    const projectId = getActiveProjectId(state);
    const scenarioId = getActiveScenarioId(state, { projectId });
    const viewId = getActiveViewId(state, { projectId });
    const layerId = getActiveLayerId(state, { projectId, scenarioId, viewId });

    return {
      projectId,
      scenarioId,
      layerId,
      activeDetailsTab: getDetailsTabKey(state, {
        projectId,
        layerId,
      }),
      haveScenarios: haveActiveProjectScenarios(state),
      exploreMode: getExploreMode(state),
      exploreViewVisible: getExploreViewVisible(state),
      reportViewVisible: getReportViewVisible(state),
      analysisUIVisible: getAnalysisUIVisible(state),
      paintUIVisible: getBuildUIVisible(state),
      manageViewVisible: getManageViewVisible(state),
    };
  };
}

function makeMapDispatchToProps() {
  return (dispatch: Dispatch): DispatchProps => {
    return {
      setExploreViewVisible: value =>
        dispatch(setUIProperty('exploreViewVisible', value)),
      setReportViewVisible: () =>
        dispatch(setUIProperty('reportViewVisible', true)),
      setManageViewVisible: () =>
        dispatch(setUIProperty('manageViewVisible', true)),
      setAnalysisUIVisible: value =>
        dispatch(setUIProperty('analysisUIVisible', value)),
      setBuildUIVisible: value =>
        dispatch(setUIProperty('paintUIVisible', value)),
      setBuildTabVisible: bindActionCreators(
        setDetailsBuildTabVisible,
        dispatch,
      ),
      setDetailsPaneTab: bindActionCreators(setDetailsPaneTabKey, dispatch),
    };
  };
}

export default connect<StateProps, DispatchProps>(
  makeMapStateToProps,
  makeMapDispatchToProps,
)(ModeBar);
