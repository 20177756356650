import { createSelector } from 'reselect';

import { makeGetFromPropsSelector } from 'uf/base/selector';
import { ColumnKey, LayerId } from 'uf/layers';

import { makeGetExploreByProjectState } from './explore';

/**
 * Get all column filters for all layers in a project
 */
export function makeGetVisibilityFilters() {
  return createSelector(
    makeGetExploreByProjectState(),
    state => state?.visibilityFilters,
  );
}

/**
 * Get all column filters for a given layer
 */
export function makeGetVisibilityFiltersForLayer() {
  return createSelector(
    makeGetVisibilityFilters(),
    makeGetFromPropsSelector<LayerId, 'layerId'>('layerId'),
    (state, layerId) => state?.[layerId] ?? null,
  );
}

/**
 * Get the single filtered value for a specific layer+column combination
 */
export function makeGetVisibilityFilterValue() {
  return createSelector(
    makeGetVisibilityFiltersForLayer(),
    makeGetFromPropsSelector<ColumnKey, 'columnKey'>('columnKey'),
    (state, columnKey) => state?.[columnKey] ?? null,
  );
}
