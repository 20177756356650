import { AnalysisModuleParameters } from 'uf-api';
import { updateScenarioAnalysisParamsActionTypes } from 'uf-api/api/project.service';
import { ModuleKey } from 'uf/analysis';
import { makeAnalysisParameterKey } from 'uf/analysiseditor';
import {
  CLEAR_SCENARIO_PARAMS,
  ClearScenarioParamAction,
  previewScenarioParamActionTypes,
  SET_DIRTY_SCENARIO_PARAMS,
  SetDirtyScenarioParamAction,
  UPDATE_SCENARIO_PARAMS,
  UpdateScenarioParamAction,
} from 'uf/analysiseditor/ActionTypes';
import { updateScenarioAnalysisParamsAPI } from 'uf/analysiseditor/apis';
import { dispatchAsyncAction } from 'uf/data/loader';
import { ProjectId } from 'uf/projects';
import { ScenarioId } from 'uf/scenarios';

/**
 * Update one or more scenario parameters in redux.
 */
export function updateScenarioParams(
  scenarioId: ScenarioId,
  moduleKey: ModuleKey,
  parameters: AnalysisModuleParameters,
): UpdateScenarioParamAction {
  return {
    type: UPDATE_SCENARIO_PARAMS,
    scenarioId,
    moduleKey,
    parameters,
  };
}

/**
 * Save parameters back to the database.
 */
export function updateScenarioAnalysisParams(
  projectId: ProjectId,
  scenarioId: ScenarioId,
  parameters: AnalysisModuleParameters,
) {
  return dispatchAsyncAction(
    updateScenarioAnalysisParamsActionTypes,
    updateScenarioAnalysisParamsAPI(projectId, scenarioId, parameters),
    {
      key: makeAnalysisParameterKey(
        projectId,
        scenarioId,
        parameters.module_key,
      ),
      extra: {
        projectId,
        scenarioId,
        moduleKey: parameters.module_key,
      },
      params: parameters.parameters,
    },
  );
}

/**
 * Preview parameters changes.
 */
export function previewScenarioAnalysisParams(
  projectId: ProjectId,
  scenarioId: ScenarioId,
  parameters: AnalysisModuleParameters,
) {
  return dispatchAsyncAction(
    // We use our own action types here instead of the swagger ones so that we don't confuse our
    // reducer that handles saves with the reducer that handles previews.
    previewScenarioParamActionTypes,
    updateScenarioAnalysisParamsAPI(projectId, scenarioId, parameters, true),
    {
      key: makeAnalysisParameterKey(
        projectId,
        scenarioId,
        parameters.module_key,
      ),
      extra: {
        projectId,
        scenarioId,
        moduleKey: parameters.module_key,
      },
    },
  );
}

/**
 * Clear all user edits. Usually done after data has been written to the db and refreshed
 * @param projectId
 * @param scenarioId
 * @param moduleKey
 */
export function clearScenarioAnalysisParams(
  projectId: ProjectId,
  scenarioId: ScenarioId,
  moduleKey: ModuleKey,
): ClearScenarioParamAction {
  return {
    type: CLEAR_SCENARIO_PARAMS,
    projectId,
    scenarioId,
    moduleKey,
  };
}

export function clearPreview(
  projectId: ProjectId,
  moduleKey: ModuleKey,
  scenarioId: ScenarioId,
) {
  return {
    type: previewScenarioParamActionTypes.CLEAR,
    key: makeAnalysisParameterKey(projectId, scenarioId, moduleKey),
  };
}

/**
 * Mark that the current user edits have been saved, or at least begun to be saved.
 * @param projectId
 * @param scenarioId
 * @param moduleKey
 */
export function setDirtyScenarioAnalysisParams(
  projectId: ProjectId,
  scenarioId: ScenarioId,
  moduleKey: ModuleKey,
  dirty: boolean,
): SetDirtyScenarioParamAction {
  return {
    type: SET_DIRTY_SCENARIO_PARAMS,
    projectId,
    scenarioId,
    moduleKey,
    dirty,
  };
}
