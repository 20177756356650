import {
  Button,
  InputGroup,
  Menu,
  MenuDivider,
  MenuItem,
  Popover,
  Position,
  Tooltip,
} from '@blueprintjs/core';
import {
  mdiAccountCircle,
  mdiCheck,
  mdiCog,
  mdiAccountBoxOutline,
  mdiLogout,
} from '@mdi/js';
import Icon from '@mdi/react';
import classNames from 'classnames';
import _ from 'lodash';
import React, { Fragment, useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { t } from 'ttag';
import { useListUserPortfoliosQuery } from 'uf-api-rtk/store/Api';

import { getAPIUrl } from 'uf/base/api';
import popupStyles from 'uf/styles/popups.module.css';
import { IconSizeNew } from 'uf/ui/base/icon';
import { getUser } from 'uf/user/selectors/user';
import { usePreferredOrgKey } from 'uf/ui/user/hooks';
import { events } from 'uf/ui/user/logging';
import { logEvent } from 'uf/logging';
import { setUIProperty } from 'uf/explore/actions';

interface Props {
  className?: string;
  targetClassName?: string;
}

const popoverClassName = classNames(
  popupStyles.dropdownLarge,
  popupStyles.dropdown,
);

function UserMenu({ className, targetClassName }: Props): JSX.Element {
  const dispatch = useDispatch();
  const { key: userKey } = useSelector(getUser);
  const { currentData: portfolios } = useListUserPortfoliosQuery(
    {
      userKey,
    },
    { skip: !userKey },
  );
  const [query, setQuery] = useState('');
  const [activeOrgKey, setOrg] = usePreferredOrgKey();
  const organizations = portfolios
    ? portfolios.reduce((acc, cur) => {
        acc.push(...cur.organizations);
        return acc;
      }, [])
    : [];
  const activeOrg = activeOrgKey
    ? organizations.find(({ key }) => key === activeOrgKey)
    : null;
  const activePortfolio = portfolios
    ? portfolios.find(item =>
        item.organizations.some(({ key }) => key === activeOrgKey),
      )
    : null;
  // filter portfolios by the presence of filtered organizations
  const filteredPortfolios = useMemo(() => {
    const processedQuery = query.trim().toLocaleLowerCase();
    if (!processedQuery.length) {
      return portfolios;
    }
    return portfolios.reduce((acc, portfolio) => {
      const filteredOrgs = portfolio.organizations.filter(orgInfo => {
        return orgInfo.name.toLowerCase().includes(processedQuery);
      });
      if (filteredOrgs.length) {
        acc.push({
          ...portfolio,
          organizations: filteredOrgs,
        });
      }
      return acc;
    }, []);
  }, [query, portfolios]);
  const handleSearchClick = useCallback(() => {
    if (portfolios.length) {
      const nextOrg = portfolios[0].organizations[0].key;
      logEvent(events.GO_TO_FIRST_PROJECT, {
        org: nextOrg,
      });
      setOrg(nextOrg);
    }
  }, [portfolios, setOrg]);

  const clearButton = query ? (
    <Button
      name="clearSearch"
      minimal
      small
      className="tw-no-padding tw-ml-1 tw-text-blue-500"
      onClick={() => setQuery('')}
      icon="cross"
    />
  ) : null;
  const searchButton = (
    <Button
      name="search"
      minimal
      small
      className="tw-no-padding tw-ml-1 tw-text-blue-500"
      onClick={handleSearchClick}
      icon="search"
    />
  );

  const menu = (
    <Menu className="tw-p-0">
      <Fragment>
        <InputGroup
          className="tw-m-2 bg-white"
          name="orgsearch"
          leftElement={searchButton}
          placeholder={t`Search`}
          autoComplete="off"
          value={query}
          onChange={e => setQuery(e.target.value)}
          rightElement={clearButton}
        />
        {!_.isEmpty(filteredPortfolios) && (
          <div className="tw-overflow-y-auto h-64 tw-border-solid tw-border-b tw-p-2">
            {_(filteredPortfolios)
              .sortBy('name')
              .map(portfolio => (
                <Fragment key={portfolio.key}>
                  <MenuDivider title={portfolio.name} className="tw-border-0" />
                  {portfolio.organizations.map(organization => (
                    <MenuItem
                      icon={
                        <Icon
                          path={
                            organization.key === activeOrgKey
                              ? mdiCheck
                              : // eslint-disable-next-line react/no-unescaped-entities
                                '' // render an empty icon
                          }
                          size={IconSizeNew.SMALL}
                        />
                      }
                      active={organization.key === activeOrgKey}
                      key={organization.key}
                      text={organization.name}
                      onClick={() => setOrg(organization.key)}
                    />
                  ))}
                </Fragment>
              ))
              .value()}
          </div>
        )}
      </Fragment>
      <div className="tw-p-2">
        {activeOrgKey && (
          <MenuItem
            icon={<Icon path={mdiCog} size={IconSizeNew.SMALL} />}
            text={t`Organization settings`}
            onClick={() => {
              // navigates to `/settings/${activeOrgKey}`
              dispatch(setUIProperty('settingsViewVisible', activeOrgKey));
            }}
          />
        )}
        <MenuItem
          icon={<Icon path={mdiAccountBoxOutline} size={IconSizeNew.SMALL} />}
          text={t`My profile`}
          onClick={() => {
            // navigates to /profile
            dispatch(setUIProperty('profileViewVisible', true));
          }}
        />
        <MenuItem
          icon={<Icon path={mdiLogout} size={IconSizeNew.SMALL} />}
          text={t`Log out`}
          href={getAPIUrl('signout')}
        />
      </div>
    </Menu>
  );
  const tooltipContent =
    activeOrg && activePortfolio ? (
      <div className="tw-text-center tw-text-lg">
        <strong className="tw-text-xl tw-block">{activeOrg.name}</strong>
        In &ldquo;{activePortfolio.name}&rdquo;
      </div>
    ) : null;

  return (
    <Popover
      minimal
      popoverClassName={popoverClassName}
      targetClassName={targetClassName}
      content={menu}>
      <Tooltip position={Position.BOTTOM} content={tooltipContent}>
        <Button
          minimal
          className={classNames(className, 'UserMenu')}
          icon={<Icon path={mdiAccountCircle} size={IconSizeNew.SMALL} />}
        />
      </Tooltip>
    </Popover>
  );
}

export default UserMenu;
