/**
 * UrbanFootprint App API
 * API for serving UrbanFootprint Application
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { ClientResponse } from '../variables';

// @ts-ignore: This is used only for params, but some services have param-free APIs
import { ProjectServiceInterface } from './project.serviceInterface';

// @ts-ignore: These imports are for both params and returnType, but we only use returnType
import { AnalysisModuleInfo } from '../model/analysisModuleInfo';
// @ts-ignore: These imports are for both params and returnType, but we only use returnType
import { AnalysisModuleParameters } from '../model/analysisModuleParameters';
// @ts-ignore: These imports are for both params and returnType, but we only use returnType
import { AnalysisParameterValidationError } from '../model/analysisParameterValidationError';
// @ts-ignore: These imports are for both params and returnType, but we only use returnType
import { CanvasTypeInfo } from '../model/canvasTypeInfo';
// @ts-ignore: These imports are for both params and returnType, but we only use returnType
import { Dashboard } from '../model/dashboard';
// @ts-ignore: These imports are for both params and returnType, but we only use returnType
import { LayerReference } from '../model/layerReference';
// @ts-ignore: These imports are for both params and returnType, but we only use returnType
import { OrganizationProjectsList } from '../model/organizationProjectsList';
// @ts-ignore: These imports are for both params and returnType, but we only use returnType
import { ProblemDetail } from '../model/problemDetail';
// @ts-ignore: These imports are for both params and returnType, but we only use returnType
import { ProjectAvailableLayers } from '../model/projectAvailableLayers';
// @ts-ignore: These imports are for both params and returnType, but we only use returnType
import { ProjectLayerAnalysisRunParameter } from '../model/projectLayerAnalysisRunParameter';
// @ts-ignore: These imports are for both params and returnType, but we only use returnType
import { ProjectLayerAnalysisRunStatus } from '../model/projectLayerAnalysisRunStatus';
// @ts-ignore: These imports are for both params and returnType, but we only use returnType
import { ProjectList } from '../model/projectList';
// @ts-ignore: These imports are for both params and returnType, but we only use returnType
import { ProjectMetadata } from '../model/projectMetadata';
// @ts-ignore: These imports are for both params and returnType, but we only use returnType
import { ProjectModuleParams } from '../model/projectModuleParams';
// @ts-ignore: These imports are for both params and returnType, but we only use returnType
import { ProjectUpdate } from '../model/projectUpdate';
// @ts-ignore: These imports are for both params and returnType, but we only use returnType
import { ScenarioUpdate } from '../model/scenarioUpdate';
// @ts-ignore: These imports are for both params and returnType, but we only use returnType
import { Task } from '../model/task';

/**
 * Package: api
 */

const addProjectWorkingLayerPrefix = `uf-api/project/add_project_working_layer/`;
/**
 * Action types for project.add_project_working_layer
 *
 * Add an existing working layer to project.
 */
export const addProjectWorkingLayerActionTypes = {
  LOAD: `${addProjectWorkingLayerPrefix}LOAD` as 'uf-api/project/add_project_working_layer/LOAD',
  SUCCESS:
    `${addProjectWorkingLayerPrefix}SUCCESS` as 'uf-api/project/add_project_working_layer/SUCCESS',
  FAILURE:
    `${addProjectWorkingLayerPrefix}FAILURE` as 'uf-api/project/add_project_working_layer/FAILURE',
  CLEAR:
    `${addProjectWorkingLayerPrefix}CLEAR` as 'uf-api/project/add_project_working_layer/CLEAR',
} as const;

/**
 * LOAD action for project.add_project_working_layer
 * Add an existing working layer to project.
 */
export interface addProjectWorkingLayerLoadAction {
  readonly type: 'uf-api/project/add_project_working_layer/LOAD';
  readonly params: ProjectServiceInterface.addProjectWorkingLayerParams;
  readonly promise: Promise<ClientResponse<LayerReference>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for project.add_project_working_layer
 * Add an existing working layer to project.
 */
export interface addProjectWorkingLayerSuccessAction {
  readonly type: 'uf-api/project/add_project_working_layer/SUCCESS';
  readonly result: LayerReference;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for project.add_project_working_layer
 * Add an existing working layer to project.
 */
export interface addProjectWorkingLayerFailureAction {
  readonly type: 'uf-api/project/add_project_working_layer/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for project.add_project_working_layer
 * Add an existing working layer to project.
 */
export interface addProjectWorkingLayerClearAction {
  readonly type: 'uf-api/project/add_project_working_layer/CLEAR';
  readonly key: string;
}

const addSymbologyPrefix = `uf-api/project/add_symbology/`;
/**
 * Action types for project.add_symbology
 *
 * Add user specific symbology for layer in project
 */
export const addSymbologyActionTypes = {
  LOAD: `${addSymbologyPrefix}LOAD` as 'uf-api/project/add_symbology/LOAD',
  SUCCESS:
    `${addSymbologyPrefix}SUCCESS` as 'uf-api/project/add_symbology/SUCCESS',
  FAILURE:
    `${addSymbologyPrefix}FAILURE` as 'uf-api/project/add_symbology/FAILURE',
  CLEAR: `${addSymbologyPrefix}CLEAR` as 'uf-api/project/add_symbology/CLEAR',
} as const;

/**
 * LOAD action for project.add_symbology
 * Add user specific symbology for layer in project
 */
export interface addSymbologyLoadAction {
  readonly type: 'uf-api/project/add_symbology/LOAD';
  readonly params: ProjectServiceInterface.addSymbologyParams;
  readonly promise: Promise<ClientResponse<{}>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for project.add_symbology
 * Add user specific symbology for layer in project
 */
export interface addSymbologySuccessAction {
  readonly type: 'uf-api/project/add_symbology/SUCCESS';
  readonly result: {};
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for project.add_symbology
 * Add user specific symbology for layer in project
 */
export interface addSymbologyFailureAction {
  readonly type: 'uf-api/project/add_symbology/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for project.add_symbology
 * Add user specific symbology for layer in project
 */
export interface addSymbologyClearAction {
  readonly type: 'uf-api/project/add_symbology/CLEAR';
  readonly key: string;
}

const addToLayerListPrefix = `uf-api/project/add_to_layer_list/`;
/**
 * Action types for project.add_to_layer_list
 *
 * Add a layer to the project layer list.
 */
export const addToLayerListActionTypes = {
  LOAD: `${addToLayerListPrefix}LOAD` as 'uf-api/project/add_to_layer_list/LOAD',
  SUCCESS:
    `${addToLayerListPrefix}SUCCESS` as 'uf-api/project/add_to_layer_list/SUCCESS',
  FAILURE:
    `${addToLayerListPrefix}FAILURE` as 'uf-api/project/add_to_layer_list/FAILURE',
  CLEAR:
    `${addToLayerListPrefix}CLEAR` as 'uf-api/project/add_to_layer_list/CLEAR',
} as const;

/**
 * LOAD action for project.add_to_layer_list
 * Add a layer to the project layer list.
 */
export interface addToLayerListLoadAction {
  readonly type: 'uf-api/project/add_to_layer_list/LOAD';
  readonly params: ProjectServiceInterface.addToLayerListParams;
  readonly promise: Promise<ClientResponse<{}>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for project.add_to_layer_list
 * Add a layer to the project layer list.
 */
export interface addToLayerListSuccessAction {
  readonly type: 'uf-api/project/add_to_layer_list/SUCCESS';
  readonly result: {};
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for project.add_to_layer_list
 * Add a layer to the project layer list.
 */
export interface addToLayerListFailureAction {
  readonly type: 'uf-api/project/add_to_layer_list/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for project.add_to_layer_list
 * Add a layer to the project layer list.
 */
export interface addToLayerListClearAction {
  readonly type: 'uf-api/project/add_to_layer_list/CLEAR';
  readonly key: string;
}

const createProjectPrefix = `uf-api/project/create_project/`;
/**
 * Action types for project.create_project
 *
 */
export const createProjectActionTypes = {
  LOAD: `${createProjectPrefix}LOAD` as 'uf-api/project/create_project/LOAD',
  SUCCESS:
    `${createProjectPrefix}SUCCESS` as 'uf-api/project/create_project/SUCCESS',
  FAILURE:
    `${createProjectPrefix}FAILURE` as 'uf-api/project/create_project/FAILURE',
  CLEAR: `${createProjectPrefix}CLEAR` as 'uf-api/project/create_project/CLEAR',
} as const;

/**
 * LOAD action for project.create_project
 */
export interface createProjectLoadAction {
  readonly type: 'uf-api/project/create_project/LOAD';
  readonly params: ProjectServiceInterface.createProjectParams;
  readonly promise: Promise<ClientResponse<ProjectMetadata>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for project.create_project
 */
export interface createProjectSuccessAction {
  readonly type: 'uf-api/project/create_project/SUCCESS';
  readonly result: ProjectMetadata;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for project.create_project
 */
export interface createProjectFailureAction {
  readonly type: 'uf-api/project/create_project/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for project.create_project
 */
export interface createProjectClearAction {
  readonly type: 'uf-api/project/create_project/CLEAR';
  readonly key: string;
}

const createProjectDynamicLayerPrefix = `uf-api/project/create_project_dynamic_layer/`;
/**
 * Action types for project.create_project_dynamic_layer
 *
 * Create a new dynamic layer in a project.
 */
export const createProjectDynamicLayerActionTypes = {
  LOAD: `${createProjectDynamicLayerPrefix}LOAD` as 'uf-api/project/create_project_dynamic_layer/LOAD',
  SUCCESS:
    `${createProjectDynamicLayerPrefix}SUCCESS` as 'uf-api/project/create_project_dynamic_layer/SUCCESS',
  FAILURE:
    `${createProjectDynamicLayerPrefix}FAILURE` as 'uf-api/project/create_project_dynamic_layer/FAILURE',
  CLEAR:
    `${createProjectDynamicLayerPrefix}CLEAR` as 'uf-api/project/create_project_dynamic_layer/CLEAR',
} as const;

/**
 * LOAD action for project.create_project_dynamic_layer
 * Create a new dynamic layer in a project.
 */
export interface createProjectDynamicLayerLoadAction {
  readonly type: 'uf-api/project/create_project_dynamic_layer/LOAD';
  readonly params: ProjectServiceInterface.createProjectDynamicLayerParams;
  readonly promise: Promise<ClientResponse<LayerReference>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for project.create_project_dynamic_layer
 * Create a new dynamic layer in a project.
 */
export interface createProjectDynamicLayerSuccessAction {
  readonly type: 'uf-api/project/create_project_dynamic_layer/SUCCESS';
  readonly result: LayerReference;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for project.create_project_dynamic_layer
 * Create a new dynamic layer in a project.
 */
export interface createProjectDynamicLayerFailureAction {
  readonly type: 'uf-api/project/create_project_dynamic_layer/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for project.create_project_dynamic_layer
 * Create a new dynamic layer in a project.
 */
export interface createProjectDynamicLayerClearAction {
  readonly type: 'uf-api/project/create_project_dynamic_layer/CLEAR';
  readonly key: string;
}

const createScenarioPrefix = `uf-api/project/create_scenario/`;
/**
 * Action types for project.create_scenario
 *
 * Create a scenario
 */
export const createScenarioActionTypes = {
  LOAD: `${createScenarioPrefix}LOAD` as 'uf-api/project/create_scenario/LOAD',
  SUCCESS:
    `${createScenarioPrefix}SUCCESS` as 'uf-api/project/create_scenario/SUCCESS',
  FAILURE:
    `${createScenarioPrefix}FAILURE` as 'uf-api/project/create_scenario/FAILURE',
  CLEAR:
    `${createScenarioPrefix}CLEAR` as 'uf-api/project/create_scenario/CLEAR',
} as const;

/**
 * LOAD action for project.create_scenario
 * Create a scenario
 */
export interface createScenarioLoadAction {
  readonly type: 'uf-api/project/create_scenario/LOAD';
  readonly params: ProjectServiceInterface.createScenarioParams;
  readonly promise: Promise<ClientResponse<{}>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for project.create_scenario
 * Create a scenario
 */
export interface createScenarioSuccessAction {
  readonly type: 'uf-api/project/create_scenario/SUCCESS';
  readonly result: {};
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for project.create_scenario
 * Create a scenario
 */
export interface createScenarioFailureAction {
  readonly type: 'uf-api/project/create_scenario/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for project.create_scenario
 * Create a scenario
 */
export interface createScenarioClearAction {
  readonly type: 'uf-api/project/create_scenario/CLEAR';
  readonly key: string;
}

const deleteProjectPrefix = `uf-api/project/delete_project/`;
/**
 * Action types for project.delete_project
 *
 * Delete project
 */
export const deleteProjectActionTypes = {
  LOAD: `${deleteProjectPrefix}LOAD` as 'uf-api/project/delete_project/LOAD',
  SUCCESS:
    `${deleteProjectPrefix}SUCCESS` as 'uf-api/project/delete_project/SUCCESS',
  FAILURE:
    `${deleteProjectPrefix}FAILURE` as 'uf-api/project/delete_project/FAILURE',
  CLEAR: `${deleteProjectPrefix}CLEAR` as 'uf-api/project/delete_project/CLEAR',
} as const;

/**
 * LOAD action for project.delete_project
 * Delete project
 */
export interface deleteProjectLoadAction {
  readonly type: 'uf-api/project/delete_project/LOAD';
  readonly params: ProjectServiceInterface.deleteProjectParams;
  readonly promise: Promise<ClientResponse<{}>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for project.delete_project
 * Delete project
 */
export interface deleteProjectSuccessAction {
  readonly type: 'uf-api/project/delete_project/SUCCESS';
  readonly result: {};
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for project.delete_project
 * Delete project
 */
export interface deleteProjectFailureAction {
  readonly type: 'uf-api/project/delete_project/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for project.delete_project
 * Delete project
 */
export interface deleteProjectClearAction {
  readonly type: 'uf-api/project/delete_project/CLEAR';
  readonly key: string;
}

const deleteProjectLayerAnalysisRunDashboardPrefix = `uf-api/project/delete_project_layer_analysis_run_dashboard/`;
/**
 * Action types for project.delete_project_layer_analysis_run_dashboard
 *
 * Soft delete an analysis layer task dashboard.
 */
export const deleteProjectLayerAnalysisRunDashboardActionTypes = {
  LOAD: `${deleteProjectLayerAnalysisRunDashboardPrefix}LOAD` as 'uf-api/project/delete_project_layer_analysis_run_dashboard/LOAD',
  SUCCESS:
    `${deleteProjectLayerAnalysisRunDashboardPrefix}SUCCESS` as 'uf-api/project/delete_project_layer_analysis_run_dashboard/SUCCESS',
  FAILURE:
    `${deleteProjectLayerAnalysisRunDashboardPrefix}FAILURE` as 'uf-api/project/delete_project_layer_analysis_run_dashboard/FAILURE',
  CLEAR:
    `${deleteProjectLayerAnalysisRunDashboardPrefix}CLEAR` as 'uf-api/project/delete_project_layer_analysis_run_dashboard/CLEAR',
} as const;

/**
 * LOAD action for project.delete_project_layer_analysis_run_dashboard
 * Soft delete an analysis layer task dashboard.
 */
export interface deleteProjectLayerAnalysisRunDashboardLoadAction {
  readonly type: 'uf-api/project/delete_project_layer_analysis_run_dashboard/LOAD';
  readonly params: ProjectServiceInterface.deleteProjectLayerAnalysisRunDashboardParams;
  readonly promise: Promise<ClientResponse<{}>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for project.delete_project_layer_analysis_run_dashboard
 * Soft delete an analysis layer task dashboard.
 */
export interface deleteProjectLayerAnalysisRunDashboardSuccessAction {
  readonly type: 'uf-api/project/delete_project_layer_analysis_run_dashboard/SUCCESS';
  readonly result: {};
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for project.delete_project_layer_analysis_run_dashboard
 * Soft delete an analysis layer task dashboard.
 */
export interface deleteProjectLayerAnalysisRunDashboardFailureAction {
  readonly type: 'uf-api/project/delete_project_layer_analysis_run_dashboard/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for project.delete_project_layer_analysis_run_dashboard
 * Soft delete an analysis layer task dashboard.
 */
export interface deleteProjectLayerAnalysisRunDashboardClearAction {
  readonly type: 'uf-api/project/delete_project_layer_analysis_run_dashboard/CLEAR';
  readonly key: string;
}

const deleteScenarioPrefix = `uf-api/project/delete_scenario/`;
/**
 * Action types for project.delete_scenario
 *
 * Delete a scenario
 */
export const deleteScenarioActionTypes = {
  LOAD: `${deleteScenarioPrefix}LOAD` as 'uf-api/project/delete_scenario/LOAD',
  SUCCESS:
    `${deleteScenarioPrefix}SUCCESS` as 'uf-api/project/delete_scenario/SUCCESS',
  FAILURE:
    `${deleteScenarioPrefix}FAILURE` as 'uf-api/project/delete_scenario/FAILURE',
  CLEAR:
    `${deleteScenarioPrefix}CLEAR` as 'uf-api/project/delete_scenario/CLEAR',
} as const;

/**
 * LOAD action for project.delete_scenario
 * Delete a scenario
 */
export interface deleteScenarioLoadAction {
  readonly type: 'uf-api/project/delete_scenario/LOAD';
  readonly params: ProjectServiceInterface.deleteScenarioParams;
  readonly promise: Promise<ClientResponse<{}>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for project.delete_scenario
 * Delete a scenario
 */
export interface deleteScenarioSuccessAction {
  readonly type: 'uf-api/project/delete_scenario/SUCCESS';
  readonly result: {};
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for project.delete_scenario
 * Delete a scenario
 */
export interface deleteScenarioFailureAction {
  readonly type: 'uf-api/project/delete_scenario/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for project.delete_scenario
 * Delete a scenario
 */
export interface deleteScenarioClearAction {
  readonly type: 'uf-api/project/delete_scenario/CLEAR';
  readonly key: string;
}

const deleteSymbologyPrefix = `uf-api/project/delete_symbology/`;
/**
 * Action types for project.delete_symbology
 *
 * Remove user specific symbology for layer in project
 */
export const deleteSymbologyActionTypes = {
  LOAD: `${deleteSymbologyPrefix}LOAD` as 'uf-api/project/delete_symbology/LOAD',
  SUCCESS:
    `${deleteSymbologyPrefix}SUCCESS` as 'uf-api/project/delete_symbology/SUCCESS',
  FAILURE:
    `${deleteSymbologyPrefix}FAILURE` as 'uf-api/project/delete_symbology/FAILURE',
  CLEAR:
    `${deleteSymbologyPrefix}CLEAR` as 'uf-api/project/delete_symbology/CLEAR',
} as const;

/**
 * LOAD action for project.delete_symbology
 * Remove user specific symbology for layer in project
 */
export interface deleteSymbologyLoadAction {
  readonly type: 'uf-api/project/delete_symbology/LOAD';
  readonly params: ProjectServiceInterface.deleteSymbologyParams;
  readonly promise: Promise<ClientResponse<{}>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for project.delete_symbology
 * Remove user specific symbology for layer in project
 */
export interface deleteSymbologySuccessAction {
  readonly type: 'uf-api/project/delete_symbology/SUCCESS';
  readonly result: {};
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for project.delete_symbology
 * Remove user specific symbology for layer in project
 */
export interface deleteSymbologyFailureAction {
  readonly type: 'uf-api/project/delete_symbology/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for project.delete_symbology
 * Remove user specific symbology for layer in project
 */
export interface deleteSymbologyClearAction {
  readonly type: 'uf-api/project/delete_symbology/CLEAR';
  readonly key: string;
}

const downloadExportedPrefix = `uf-api/project/download_exported/`;
/**
 * Action types for project.download_exported
 *
 * Download a layer that was previously enqueued.
 */
export const downloadExportedActionTypes = {
  LOAD: `${downloadExportedPrefix}LOAD` as 'uf-api/project/download_exported/LOAD',
  SUCCESS:
    `${downloadExportedPrefix}SUCCESS` as 'uf-api/project/download_exported/SUCCESS',
  FAILURE:
    `${downloadExportedPrefix}FAILURE` as 'uf-api/project/download_exported/FAILURE',
  CLEAR:
    `${downloadExportedPrefix}CLEAR` as 'uf-api/project/download_exported/CLEAR',
} as const;

/**
 * LOAD action for project.download_exported
 * Download a layer that was previously enqueued.
 */
export interface downloadExportedLoadAction {
  readonly type: 'uf-api/project/download_exported/LOAD';
  readonly params: ProjectServiceInterface.downloadExportedParams;
  readonly promise: Promise<ClientResponse<{}>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for project.download_exported
 * Download a layer that was previously enqueued.
 */
export interface downloadExportedSuccessAction {
  readonly type: 'uf-api/project/download_exported/SUCCESS';
  readonly result: {};
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for project.download_exported
 * Download a layer that was previously enqueued.
 */
export interface downloadExportedFailureAction {
  readonly type: 'uf-api/project/download_exported/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for project.download_exported
 * Download a layer that was previously enqueued.
 */
export interface downloadExportedClearAction {
  readonly type: 'uf-api/project/download_exported/CLEAR';
  readonly key: string;
}

const editProjectPrefix = `uf-api/project/edit_project/`;
/**
 * Action types for project.edit_project
 *
 * Edit details of a project.
 */
export const editProjectActionTypes = {
  LOAD: `${editProjectPrefix}LOAD` as 'uf-api/project/edit_project/LOAD',
  SUCCESS:
    `${editProjectPrefix}SUCCESS` as 'uf-api/project/edit_project/SUCCESS',
  FAILURE:
    `${editProjectPrefix}FAILURE` as 'uf-api/project/edit_project/FAILURE',
  CLEAR: `${editProjectPrefix}CLEAR` as 'uf-api/project/edit_project/CLEAR',
} as const;

/**
 * LOAD action for project.edit_project
 * Edit details of a project.
 */
export interface editProjectLoadAction {
  readonly type: 'uf-api/project/edit_project/LOAD';
  readonly params: ProjectServiceInterface.editProjectParams;
  readonly promise: Promise<ClientResponse<{}>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for project.edit_project
 * Edit details of a project.
 */
export interface editProjectSuccessAction {
  readonly type: 'uf-api/project/edit_project/SUCCESS';
  readonly result: {};
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for project.edit_project
 * Edit details of a project.
 */
export interface editProjectFailureAction {
  readonly type: 'uf-api/project/edit_project/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for project.edit_project
 * Edit details of a project.
 */
export interface editProjectClearAction {
  readonly type: 'uf-api/project/edit_project/CLEAR';
  readonly key: string;
}

const editScenarioPrefix = `uf-api/project/edit_scenario/`;
/**
 * Action types for project.edit_scenario
 *
 * Edit a scenario
 */
export const editScenarioActionTypes = {
  LOAD: `${editScenarioPrefix}LOAD` as 'uf-api/project/edit_scenario/LOAD',
  SUCCESS:
    `${editScenarioPrefix}SUCCESS` as 'uf-api/project/edit_scenario/SUCCESS',
  FAILURE:
    `${editScenarioPrefix}FAILURE` as 'uf-api/project/edit_scenario/FAILURE',
  CLEAR: `${editScenarioPrefix}CLEAR` as 'uf-api/project/edit_scenario/CLEAR',
} as const;

/**
 * LOAD action for project.edit_scenario
 * Edit a scenario
 */
export interface editScenarioLoadAction {
  readonly type: 'uf-api/project/edit_scenario/LOAD';
  readonly params: ProjectServiceInterface.editScenarioParams;
  readonly promise: Promise<ClientResponse<{}>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for project.edit_scenario
 * Edit a scenario
 */
export interface editScenarioSuccessAction {
  readonly type: 'uf-api/project/edit_scenario/SUCCESS';
  readonly result: {};
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for project.edit_scenario
 * Edit a scenario
 */
export interface editScenarioFailureAction {
  readonly type: 'uf-api/project/edit_scenario/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for project.edit_scenario
 * Edit a scenario
 */
export interface editScenarioClearAction {
  readonly type: 'uf-api/project/edit_scenario/CLEAR';
  readonly key: string;
}

const enqueueCreateBaseCanvasTaskPrefix = `uf-api/project/enqueue_create_base_canvas_task/`;
/**
 * Action types for project.enqueue_create_base_canvas_task
 *
 * Create a base canvas for project.
 */
export const enqueueCreateBaseCanvasTaskActionTypes = {
  LOAD: `${enqueueCreateBaseCanvasTaskPrefix}LOAD` as 'uf-api/project/enqueue_create_base_canvas_task/LOAD',
  SUCCESS:
    `${enqueueCreateBaseCanvasTaskPrefix}SUCCESS` as 'uf-api/project/enqueue_create_base_canvas_task/SUCCESS',
  FAILURE:
    `${enqueueCreateBaseCanvasTaskPrefix}FAILURE` as 'uf-api/project/enqueue_create_base_canvas_task/FAILURE',
  CLEAR:
    `${enqueueCreateBaseCanvasTaskPrefix}CLEAR` as 'uf-api/project/enqueue_create_base_canvas_task/CLEAR',
} as const;

/**
 * LOAD action for project.enqueue_create_base_canvas_task
 * Create a base canvas for project.
 */
export interface enqueueCreateBaseCanvasTaskLoadAction {
  readonly type: 'uf-api/project/enqueue_create_base_canvas_task/LOAD';
  readonly params: ProjectServiceInterface.enqueueCreateBaseCanvasTaskParams;
  readonly promise: Promise<ClientResponse<string>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for project.enqueue_create_base_canvas_task
 * Create a base canvas for project.
 */
export interface enqueueCreateBaseCanvasTaskSuccessAction {
  readonly type: 'uf-api/project/enqueue_create_base_canvas_task/SUCCESS';
  readonly result: string;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for project.enqueue_create_base_canvas_task
 * Create a base canvas for project.
 */
export interface enqueueCreateBaseCanvasTaskFailureAction {
  readonly type: 'uf-api/project/enqueue_create_base_canvas_task/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for project.enqueue_create_base_canvas_task
 * Create a base canvas for project.
 */
export interface enqueueCreateBaseCanvasTaskClearAction {
  readonly type: 'uf-api/project/enqueue_create_base_canvas_task/CLEAR';
  readonly key: string;
}

const enqueueCreateContextCanvasTaskPrefix = `uf-api/project/enqueue_create_context_canvas_task/`;
/**
 * Action types for project.enqueue_create_context_canvas_task
 *
 * Create a context canvas for project.
 */
export const enqueueCreateContextCanvasTaskActionTypes = {
  LOAD: `${enqueueCreateContextCanvasTaskPrefix}LOAD` as 'uf-api/project/enqueue_create_context_canvas_task/LOAD',
  SUCCESS:
    `${enqueueCreateContextCanvasTaskPrefix}SUCCESS` as 'uf-api/project/enqueue_create_context_canvas_task/SUCCESS',
  FAILURE:
    `${enqueueCreateContextCanvasTaskPrefix}FAILURE` as 'uf-api/project/enqueue_create_context_canvas_task/FAILURE',
  CLEAR:
    `${enqueueCreateContextCanvasTaskPrefix}CLEAR` as 'uf-api/project/enqueue_create_context_canvas_task/CLEAR',
} as const;

/**
 * LOAD action for project.enqueue_create_context_canvas_task
 * Create a context canvas for project.
 */
export interface enqueueCreateContextCanvasTaskLoadAction {
  readonly type: 'uf-api/project/enqueue_create_context_canvas_task/LOAD';
  readonly params: ProjectServiceInterface.enqueueCreateContextCanvasTaskParams;
  readonly promise: Promise<ClientResponse<string>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for project.enqueue_create_context_canvas_task
 * Create a context canvas for project.
 */
export interface enqueueCreateContextCanvasTaskSuccessAction {
  readonly type: 'uf-api/project/enqueue_create_context_canvas_task/SUCCESS';
  readonly result: string;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for project.enqueue_create_context_canvas_task
 * Create a context canvas for project.
 */
export interface enqueueCreateContextCanvasTaskFailureAction {
  readonly type: 'uf-api/project/enqueue_create_context_canvas_task/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for project.enqueue_create_context_canvas_task
 * Create a context canvas for project.
 */
export interface enqueueCreateContextCanvasTaskClearAction {
  readonly type: 'uf-api/project/enqueue_create_context_canvas_task/CLEAR';
  readonly key: string;
}

const enqueueImportLayerPrefix = `uf-api/project/enqueue_import_layer/`;
/**
 * Action types for project.enqueue_import_layer
 *
 * Persist a raw dataset file and enqueue a task to import it.
 */
export const enqueueImportLayerActionTypes = {
  LOAD: `${enqueueImportLayerPrefix}LOAD` as 'uf-api/project/enqueue_import_layer/LOAD',
  SUCCESS:
    `${enqueueImportLayerPrefix}SUCCESS` as 'uf-api/project/enqueue_import_layer/SUCCESS',
  FAILURE:
    `${enqueueImportLayerPrefix}FAILURE` as 'uf-api/project/enqueue_import_layer/FAILURE',
  CLEAR:
    `${enqueueImportLayerPrefix}CLEAR` as 'uf-api/project/enqueue_import_layer/CLEAR',
} as const;

/**
 * LOAD action for project.enqueue_import_layer
 * Persist a raw dataset file and enqueue a task to import it.
 */
export interface enqueueImportLayerLoadAction {
  readonly type: 'uf-api/project/enqueue_import_layer/LOAD';
  readonly params: ProjectServiceInterface.enqueueImportLayerParams;
  readonly promise: Promise<ClientResponse<string>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for project.enqueue_import_layer
 * Persist a raw dataset file and enqueue a task to import it.
 */
export interface enqueueImportLayerSuccessAction {
  readonly type: 'uf-api/project/enqueue_import_layer/SUCCESS';
  readonly result: string;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for project.enqueue_import_layer
 * Persist a raw dataset file and enqueue a task to import it.
 */
export interface enqueueImportLayerFailureAction {
  readonly type: 'uf-api/project/enqueue_import_layer/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for project.enqueue_import_layer
 * Persist a raw dataset file and enqueue a task to import it.
 */
export interface enqueueImportLayerClearAction {
  readonly type: 'uf-api/project/enqueue_import_layer/CLEAR';
  readonly key: string;
}

const enqueueProjectBufferedLayerTaskPrefix = `uf-api/project/enqueue_project_buffered_layer_task/`;
/**
 * Action types for project.enqueue_project_buffered_layer_task
 *
 * Create a new project buffered layer creation task.
 */
export const enqueueProjectBufferedLayerTaskActionTypes = {
  LOAD: `${enqueueProjectBufferedLayerTaskPrefix}LOAD` as 'uf-api/project/enqueue_project_buffered_layer_task/LOAD',
  SUCCESS:
    `${enqueueProjectBufferedLayerTaskPrefix}SUCCESS` as 'uf-api/project/enqueue_project_buffered_layer_task/SUCCESS',
  FAILURE:
    `${enqueueProjectBufferedLayerTaskPrefix}FAILURE` as 'uf-api/project/enqueue_project_buffered_layer_task/FAILURE',
  CLEAR:
    `${enqueueProjectBufferedLayerTaskPrefix}CLEAR` as 'uf-api/project/enqueue_project_buffered_layer_task/CLEAR',
} as const;

/**
 * LOAD action for project.enqueue_project_buffered_layer_task
 * Create a new project buffered layer creation task.
 */
export interface enqueueProjectBufferedLayerTaskLoadAction {
  readonly type: 'uf-api/project/enqueue_project_buffered_layer_task/LOAD';
  readonly params: ProjectServiceInterface.enqueueProjectBufferedLayerTaskParams;
  readonly promise: Promise<ClientResponse<string>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for project.enqueue_project_buffered_layer_task
 * Create a new project buffered layer creation task.
 */
export interface enqueueProjectBufferedLayerTaskSuccessAction {
  readonly type: 'uf-api/project/enqueue_project_buffered_layer_task/SUCCESS';
  readonly result: string;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for project.enqueue_project_buffered_layer_task
 * Create a new project buffered layer creation task.
 */
export interface enqueueProjectBufferedLayerTaskFailureAction {
  readonly type: 'uf-api/project/enqueue_project_buffered_layer_task/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for project.enqueue_project_buffered_layer_task
 * Create a new project buffered layer creation task.
 */
export interface enqueueProjectBufferedLayerTaskClearAction {
  readonly type: 'uf-api/project/enqueue_project_buffered_layer_task/CLEAR';
  readonly key: string;
}

const enqueueProjectConvertLayerTaskPrefix = `uf-api/project/enqueue_project_convert_layer_task/`;
/**
 * Action types for project.enqueue_project_convert_layer_task
 *
 * Create a new project converted layer creation task.
 */
export const enqueueProjectConvertLayerTaskActionTypes = {
  LOAD: `${enqueueProjectConvertLayerTaskPrefix}LOAD` as 'uf-api/project/enqueue_project_convert_layer_task/LOAD',
  SUCCESS:
    `${enqueueProjectConvertLayerTaskPrefix}SUCCESS` as 'uf-api/project/enqueue_project_convert_layer_task/SUCCESS',
  FAILURE:
    `${enqueueProjectConvertLayerTaskPrefix}FAILURE` as 'uf-api/project/enqueue_project_convert_layer_task/FAILURE',
  CLEAR:
    `${enqueueProjectConvertLayerTaskPrefix}CLEAR` as 'uf-api/project/enqueue_project_convert_layer_task/CLEAR',
} as const;

/**
 * LOAD action for project.enqueue_project_convert_layer_task
 * Create a new project converted layer creation task.
 */
export interface enqueueProjectConvertLayerTaskLoadAction {
  readonly type: 'uf-api/project/enqueue_project_convert_layer_task/LOAD';
  readonly params: ProjectServiceInterface.enqueueProjectConvertLayerTaskParams;
  readonly promise: Promise<ClientResponse<string>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for project.enqueue_project_convert_layer_task
 * Create a new project converted layer creation task.
 */
export interface enqueueProjectConvertLayerTaskSuccessAction {
  readonly type: 'uf-api/project/enqueue_project_convert_layer_task/SUCCESS';
  readonly result: string;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for project.enqueue_project_convert_layer_task
 * Create a new project converted layer creation task.
 */
export interface enqueueProjectConvertLayerTaskFailureAction {
  readonly type: 'uf-api/project/enqueue_project_convert_layer_task/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for project.enqueue_project_convert_layer_task
 * Create a new project converted layer creation task.
 */
export interface enqueueProjectConvertLayerTaskClearAction {
  readonly type: 'uf-api/project/enqueue_project_convert_layer_task/CLEAR';
  readonly key: string;
}

const exportModuleParamsPrefix = `uf-api/project/export_module_params/`;
/**
 * Action types for project.export_module_params
 *
 * Export analysis parameter values for the specified project and module.
 */
export const exportModuleParamsActionTypes = {
  LOAD: `${exportModuleParamsPrefix}LOAD` as 'uf-api/project/export_module_params/LOAD',
  SUCCESS:
    `${exportModuleParamsPrefix}SUCCESS` as 'uf-api/project/export_module_params/SUCCESS',
  FAILURE:
    `${exportModuleParamsPrefix}FAILURE` as 'uf-api/project/export_module_params/FAILURE',
  CLEAR:
    `${exportModuleParamsPrefix}CLEAR` as 'uf-api/project/export_module_params/CLEAR',
} as const;

/**
 * LOAD action for project.export_module_params
 * Export analysis parameter values for the specified project and module.
 */
export interface exportModuleParamsLoadAction {
  readonly type: 'uf-api/project/export_module_params/LOAD';
  readonly params: ProjectServiceInterface.exportModuleParamsParams;
  readonly promise: Promise<ClientResponse<Blob>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for project.export_module_params
 * Export analysis parameter values for the specified project and module.
 */
export interface exportModuleParamsSuccessAction {
  readonly type: 'uf-api/project/export_module_params/SUCCESS';
  readonly result: Blob;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for project.export_module_params
 * Export analysis parameter values for the specified project and module.
 */
export interface exportModuleParamsFailureAction {
  readonly type: 'uf-api/project/export_module_params/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for project.export_module_params
 * Export analysis parameter values for the specified project and module.
 */
export interface exportModuleParamsClearAction {
  readonly type: 'uf-api/project/export_module_params/CLEAR';
  readonly key: string;
}

const getAnalysisModulePrefix = `uf-api/project/get_analysis_module/`;
/**
 * Action types for project.get_analysis_module
 *
 * Get metadata about an analysis module.
 */
export const getAnalysisModuleActionTypes = {
  LOAD: `${getAnalysisModulePrefix}LOAD` as 'uf-api/project/get_analysis_module/LOAD',
  SUCCESS:
    `${getAnalysisModulePrefix}SUCCESS` as 'uf-api/project/get_analysis_module/SUCCESS',
  FAILURE:
    `${getAnalysisModulePrefix}FAILURE` as 'uf-api/project/get_analysis_module/FAILURE',
  CLEAR:
    `${getAnalysisModulePrefix}CLEAR` as 'uf-api/project/get_analysis_module/CLEAR',
} as const;

/**
 * LOAD action for project.get_analysis_module
 * Get metadata about an analysis module.
 */
export interface getAnalysisModuleLoadAction {
  readonly type: 'uf-api/project/get_analysis_module/LOAD';
  readonly params: ProjectServiceInterface.getAnalysisModuleParams;
  readonly promise: Promise<ClientResponse<AnalysisModuleInfo>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for project.get_analysis_module
 * Get metadata about an analysis module.
 */
export interface getAnalysisModuleSuccessAction {
  readonly type: 'uf-api/project/get_analysis_module/SUCCESS';
  readonly result: AnalysisModuleInfo;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for project.get_analysis_module
 * Get metadata about an analysis module.
 */
export interface getAnalysisModuleFailureAction {
  readonly type: 'uf-api/project/get_analysis_module/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for project.get_analysis_module
 * Get metadata about an analysis module.
 */
export interface getAnalysisModuleClearAction {
  readonly type: 'uf-api/project/get_analysis_module/CLEAR';
  readonly key: string;
}

const getAnalysisModulesPrefix = `uf-api/project/get_analysis_modules/`;
/**
 * Action types for project.get_analysis_modules
 *
 * Get a list of available analysis modules.
 */
export const getAnalysisModulesActionTypes = {
  LOAD: `${getAnalysisModulesPrefix}LOAD` as 'uf-api/project/get_analysis_modules/LOAD',
  SUCCESS:
    `${getAnalysisModulesPrefix}SUCCESS` as 'uf-api/project/get_analysis_modules/SUCCESS',
  FAILURE:
    `${getAnalysisModulesPrefix}FAILURE` as 'uf-api/project/get_analysis_modules/FAILURE',
  CLEAR:
    `${getAnalysisModulesPrefix}CLEAR` as 'uf-api/project/get_analysis_modules/CLEAR',
} as const;

/**
 * LOAD action for project.get_analysis_modules
 * Get a list of available analysis modules.
 */
export interface getAnalysisModulesLoadAction {
  readonly type: 'uf-api/project/get_analysis_modules/LOAD';
  readonly params: ProjectServiceInterface.getAnalysisModulesParams;
  readonly promise: Promise<ClientResponse<Array<string>>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for project.get_analysis_modules
 * Get a list of available analysis modules.
 */
export interface getAnalysisModulesSuccessAction {
  readonly type: 'uf-api/project/get_analysis_modules/SUCCESS';
  readonly result: Array<string>;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for project.get_analysis_modules
 * Get a list of available analysis modules.
 */
export interface getAnalysisModulesFailureAction {
  readonly type: 'uf-api/project/get_analysis_modules/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for project.get_analysis_modules
 * Get a list of available analysis modules.
 */
export interface getAnalysisModulesClearAction {
  readonly type: 'uf-api/project/get_analysis_modules/CLEAR';
  readonly key: string;
}

const getCanvasTypesPrefix = `uf-api/project/get_canvas_types/`;
/**
 * Action types for project.get_canvas_types
 *
 * Get the project canvas types for an organization.
 */
export const getCanvasTypesActionTypes = {
  LOAD: `${getCanvasTypesPrefix}LOAD` as 'uf-api/project/get_canvas_types/LOAD',
  SUCCESS:
    `${getCanvasTypesPrefix}SUCCESS` as 'uf-api/project/get_canvas_types/SUCCESS',
  FAILURE:
    `${getCanvasTypesPrefix}FAILURE` as 'uf-api/project/get_canvas_types/FAILURE',
  CLEAR:
    `${getCanvasTypesPrefix}CLEAR` as 'uf-api/project/get_canvas_types/CLEAR',
} as const;

/**
 * LOAD action for project.get_canvas_types
 * Get the project canvas types for an organization.
 */
export interface getCanvasTypesLoadAction {
  readonly type: 'uf-api/project/get_canvas_types/LOAD';
  readonly params: ProjectServiceInterface.getCanvasTypesParams;
  readonly promise: Promise<ClientResponse<Array<CanvasTypeInfo>>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for project.get_canvas_types
 * Get the project canvas types for an organization.
 */
export interface getCanvasTypesSuccessAction {
  readonly type: 'uf-api/project/get_canvas_types/SUCCESS';
  readonly result: Array<CanvasTypeInfo>;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for project.get_canvas_types
 * Get the project canvas types for an organization.
 */
export interface getCanvasTypesFailureAction {
  readonly type: 'uf-api/project/get_canvas_types/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for project.get_canvas_types
 * Get the project canvas types for an organization.
 */
export interface getCanvasTypesClearAction {
  readonly type: 'uf-api/project/get_canvas_types/CLEAR';
  readonly key: string;
}

const getProjectPrefix = `uf-api/project/get_project/`;
/**
 * Action types for project.get_project
 *
 * Return metadata about a project.
 */
export const getProjectActionTypes = {
  LOAD: `${getProjectPrefix}LOAD` as 'uf-api/project/get_project/LOAD',
  SUCCESS: `${getProjectPrefix}SUCCESS` as 'uf-api/project/get_project/SUCCESS',
  FAILURE: `${getProjectPrefix}FAILURE` as 'uf-api/project/get_project/FAILURE',
  CLEAR: `${getProjectPrefix}CLEAR` as 'uf-api/project/get_project/CLEAR',
} as const;

/**
 * LOAD action for project.get_project
 * Return metadata about a project.
 */
export interface getProjectLoadAction {
  readonly type: 'uf-api/project/get_project/LOAD';
  readonly params: ProjectServiceInterface.getProjectParams;
  readonly promise: Promise<ClientResponse<ProjectMetadata>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for project.get_project
 * Return metadata about a project.
 */
export interface getProjectSuccessAction {
  readonly type: 'uf-api/project/get_project/SUCCESS';
  readonly result: ProjectMetadata;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for project.get_project
 * Return metadata about a project.
 */
export interface getProjectFailureAction {
  readonly type: 'uf-api/project/get_project/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for project.get_project
 * Return metadata about a project.
 */
export interface getProjectClearAction {
  readonly type: 'uf-api/project/get_project/CLEAR';
  readonly key: string;
}

const getProjectAnalysisParamsPrefix = `uf-api/project/get_project_analysis_params/`;
/**
 * Action types for project.get_project_analysis_params
 *
 * Get analysis params for all scenarios of a project, for a single module.
 */
export const getProjectAnalysisParamsActionTypes = {
  LOAD: `${getProjectAnalysisParamsPrefix}LOAD` as 'uf-api/project/get_project_analysis_params/LOAD',
  SUCCESS:
    `${getProjectAnalysisParamsPrefix}SUCCESS` as 'uf-api/project/get_project_analysis_params/SUCCESS',
  FAILURE:
    `${getProjectAnalysisParamsPrefix}FAILURE` as 'uf-api/project/get_project_analysis_params/FAILURE',
  CLEAR:
    `${getProjectAnalysisParamsPrefix}CLEAR` as 'uf-api/project/get_project_analysis_params/CLEAR',
} as const;

/**
 * LOAD action for project.get_project_analysis_params
 * Get analysis params for all scenarios of a project, for a single module.
 */
export interface getProjectAnalysisParamsLoadAction {
  readonly type: 'uf-api/project/get_project_analysis_params/LOAD';
  readonly params: ProjectServiceInterface.getProjectAnalysisParamsParams;
  readonly promise: Promise<ClientResponse<ProjectModuleParams>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for project.get_project_analysis_params
 * Get analysis params for all scenarios of a project, for a single module.
 */
export interface getProjectAnalysisParamsSuccessAction {
  readonly type: 'uf-api/project/get_project_analysis_params/SUCCESS';
  readonly result: ProjectModuleParams;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for project.get_project_analysis_params
 * Get analysis params for all scenarios of a project, for a single module.
 */
export interface getProjectAnalysisParamsFailureAction {
  readonly type: 'uf-api/project/get_project_analysis_params/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for project.get_project_analysis_params
 * Get analysis params for all scenarios of a project, for a single module.
 */
export interface getProjectAnalysisParamsClearAction {
  readonly type: 'uf-api/project/get_project_analysis_params/CLEAR';
  readonly key: string;
}

const getProjectAvailableLayersPrefix = `uf-api/project/get_project_available_layers/`;
/**
 * Action types for project.get_project_available_layers
 *
 * Return the layers that are available for a project to use.
 */
export const getProjectAvailableLayersActionTypes = {
  LOAD: `${getProjectAvailableLayersPrefix}LOAD` as 'uf-api/project/get_project_available_layers/LOAD',
  SUCCESS:
    `${getProjectAvailableLayersPrefix}SUCCESS` as 'uf-api/project/get_project_available_layers/SUCCESS',
  FAILURE:
    `${getProjectAvailableLayersPrefix}FAILURE` as 'uf-api/project/get_project_available_layers/FAILURE',
  CLEAR:
    `${getProjectAvailableLayersPrefix}CLEAR` as 'uf-api/project/get_project_available_layers/CLEAR',
} as const;

/**
 * LOAD action for project.get_project_available_layers
 * Return the layers that are available for a project to use.
 */
export interface getProjectAvailableLayersLoadAction {
  readonly type: 'uf-api/project/get_project_available_layers/LOAD';
  readonly params: ProjectServiceInterface.getProjectAvailableLayersParams;
  readonly promise: Promise<ClientResponse<ProjectAvailableLayers>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for project.get_project_available_layers
 * Return the layers that are available for a project to use.
 */
export interface getProjectAvailableLayersSuccessAction {
  readonly type: 'uf-api/project/get_project_available_layers/SUCCESS';
  readonly result: ProjectAvailableLayers;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for project.get_project_available_layers
 * Return the layers that are available for a project to use.
 */
export interface getProjectAvailableLayersFailureAction {
  readonly type: 'uf-api/project/get_project_available_layers/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for project.get_project_available_layers
 * Return the layers that are available for a project to use.
 */
export interface getProjectAvailableLayersClearAction {
  readonly type: 'uf-api/project/get_project_available_layers/CLEAR';
  readonly key: string;
}

const getProjectTasksPrefix = `uf-api/project/get_project_tasks/`;
/**
 * Action types for project.get_project_tasks
 *
 * Get the running tasks for a project.
 */
export const getProjectTasksActionTypes = {
  LOAD: `${getProjectTasksPrefix}LOAD` as 'uf-api/project/get_project_tasks/LOAD',
  SUCCESS:
    `${getProjectTasksPrefix}SUCCESS` as 'uf-api/project/get_project_tasks/SUCCESS',
  FAILURE:
    `${getProjectTasksPrefix}FAILURE` as 'uf-api/project/get_project_tasks/FAILURE',
  CLEAR:
    `${getProjectTasksPrefix}CLEAR` as 'uf-api/project/get_project_tasks/CLEAR',
} as const;

/**
 * LOAD action for project.get_project_tasks
 * Get the running tasks for a project.
 */
export interface getProjectTasksLoadAction {
  readonly type: 'uf-api/project/get_project_tasks/LOAD';
  readonly params: ProjectServiceInterface.getProjectTasksParams;
  readonly promise: Promise<ClientResponse<Array<Task>>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for project.get_project_tasks
 * Get the running tasks for a project.
 */
export interface getProjectTasksSuccessAction {
  readonly type: 'uf-api/project/get_project_tasks/SUCCESS';
  readonly result: Array<Task>;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for project.get_project_tasks
 * Get the running tasks for a project.
 */
export interface getProjectTasksFailureAction {
  readonly type: 'uf-api/project/get_project_tasks/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for project.get_project_tasks
 * Get the running tasks for a project.
 */
export interface getProjectTasksClearAction {
  readonly type: 'uf-api/project/get_project_tasks/CLEAR';
  readonly key: string;
}

const getProjectUpdateInProgressPrefix = `uf-api/project/get_project_update_in_progress/`;
/**
 * Action types for project.get_project_update_in_progress
 *
 * Get the update in progress for a project.
 */
export const getProjectUpdateInProgressActionTypes = {
  LOAD: `${getProjectUpdateInProgressPrefix}LOAD` as 'uf-api/project/get_project_update_in_progress/LOAD',
  SUCCESS:
    `${getProjectUpdateInProgressPrefix}SUCCESS` as 'uf-api/project/get_project_update_in_progress/SUCCESS',
  FAILURE:
    `${getProjectUpdateInProgressPrefix}FAILURE` as 'uf-api/project/get_project_update_in_progress/FAILURE',
  CLEAR:
    `${getProjectUpdateInProgressPrefix}CLEAR` as 'uf-api/project/get_project_update_in_progress/CLEAR',
} as const;

/**
 * LOAD action for project.get_project_update_in_progress
 * Get the update in progress for a project.
 */
export interface getProjectUpdateInProgressLoadAction {
  readonly type: 'uf-api/project/get_project_update_in_progress/LOAD';
  readonly params: ProjectServiceInterface.getProjectUpdateInProgressParams;
  readonly promise: Promise<ClientResponse<ProjectUpdate>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for project.get_project_update_in_progress
 * Get the update in progress for a project.
 */
export interface getProjectUpdateInProgressSuccessAction {
  readonly type: 'uf-api/project/get_project_update_in_progress/SUCCESS';
  readonly result: ProjectUpdate;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for project.get_project_update_in_progress
 * Get the update in progress for a project.
 */
export interface getProjectUpdateInProgressFailureAction {
  readonly type: 'uf-api/project/get_project_update_in_progress/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for project.get_project_update_in_progress
 * Get the update in progress for a project.
 */
export interface getProjectUpdateInProgressClearAction {
  readonly type: 'uf-api/project/get_project_update_in_progress/CLEAR';
  readonly key: string;
}

const getProjectWorkingLayersPrefix = `uf-api/project/get_project_working_layers/`;
/**
 * Action types for project.get_project_working_layers
 *
 * Get list of working layers in a project.
 */
export const getProjectWorkingLayersActionTypes = {
  LOAD: `${getProjectWorkingLayersPrefix}LOAD` as 'uf-api/project/get_project_working_layers/LOAD',
  SUCCESS:
    `${getProjectWorkingLayersPrefix}SUCCESS` as 'uf-api/project/get_project_working_layers/SUCCESS',
  FAILURE:
    `${getProjectWorkingLayersPrefix}FAILURE` as 'uf-api/project/get_project_working_layers/FAILURE',
  CLEAR:
    `${getProjectWorkingLayersPrefix}CLEAR` as 'uf-api/project/get_project_working_layers/CLEAR',
} as const;

/**
 * LOAD action for project.get_project_working_layers
 * Get list of working layers in a project.
 */
export interface getProjectWorkingLayersLoadAction {
  readonly type: 'uf-api/project/get_project_working_layers/LOAD';
  readonly params: ProjectServiceInterface.getProjectWorkingLayersParams;
  readonly promise: Promise<ClientResponse<Array<LayerReference>>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for project.get_project_working_layers
 * Get list of working layers in a project.
 */
export interface getProjectWorkingLayersSuccessAction {
  readonly type: 'uf-api/project/get_project_working_layers/SUCCESS';
  readonly result: Array<LayerReference>;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for project.get_project_working_layers
 * Get list of working layers in a project.
 */
export interface getProjectWorkingLayersFailureAction {
  readonly type: 'uf-api/project/get_project_working_layers/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for project.get_project_working_layers
 * Get list of working layers in a project.
 */
export interface getProjectWorkingLayersClearAction {
  readonly type: 'uf-api/project/get_project_working_layers/CLEAR';
  readonly key: string;
}

const getProjectsPrefix = `uf-api/project/get_projects/`;
/**
 * Action types for project.get_projects
 *
 * Return a list of projects for the current user.
 */
export const getProjectsActionTypes = {
  LOAD: `${getProjectsPrefix}LOAD` as 'uf-api/project/get_projects/LOAD',
  SUCCESS:
    `${getProjectsPrefix}SUCCESS` as 'uf-api/project/get_projects/SUCCESS',
  FAILURE:
    `${getProjectsPrefix}FAILURE` as 'uf-api/project/get_projects/FAILURE',
  CLEAR: `${getProjectsPrefix}CLEAR` as 'uf-api/project/get_projects/CLEAR',
} as const;

/**
 * LOAD action for project.get_projects
 * Return a list of projects for the current user.
 */
export interface getProjectsLoadAction {
  readonly type: 'uf-api/project/get_projects/LOAD';

  readonly promise: Promise<ClientResponse<ProjectList>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for project.get_projects
 * Return a list of projects for the current user.
 */
export interface getProjectsSuccessAction {
  readonly type: 'uf-api/project/get_projects/SUCCESS';
  readonly result: ProjectList;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for project.get_projects
 * Return a list of projects for the current user.
 */
export interface getProjectsFailureAction {
  readonly type: 'uf-api/project/get_projects/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for project.get_projects
 * Return a list of projects for the current user.
 */
export interface getProjectsClearAction {
  readonly type: 'uf-api/project/get_projects/CLEAR';
  readonly key: string;
}

const getProjectsV2Prefix = `uf-api/project/get_projects_v2/`;
/**
 * Action types for project.get_projects_v2
 *
 * Return a list of projects for the current user, grouped by organization.
 */
export const getProjectsV2ActionTypes = {
  LOAD: `${getProjectsV2Prefix}LOAD` as 'uf-api/project/get_projects_v2/LOAD',
  SUCCESS:
    `${getProjectsV2Prefix}SUCCESS` as 'uf-api/project/get_projects_v2/SUCCESS',
  FAILURE:
    `${getProjectsV2Prefix}FAILURE` as 'uf-api/project/get_projects_v2/FAILURE',
  CLEAR:
    `${getProjectsV2Prefix}CLEAR` as 'uf-api/project/get_projects_v2/CLEAR',
} as const;

/**
 * LOAD action for project.get_projects_v2
 * Return a list of projects for the current user, grouped by organization.
 */
export interface getProjectsV2LoadAction {
  readonly type: 'uf-api/project/get_projects_v2/LOAD';

  readonly promise: Promise<ClientResponse<OrganizationProjectsList>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for project.get_projects_v2
 * Return a list of projects for the current user, grouped by organization.
 */
export interface getProjectsV2SuccessAction {
  readonly type: 'uf-api/project/get_projects_v2/SUCCESS';
  readonly result: OrganizationProjectsList;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for project.get_projects_v2
 * Return a list of projects for the current user, grouped by organization.
 */
export interface getProjectsV2FailureAction {
  readonly type: 'uf-api/project/get_projects_v2/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for project.get_projects_v2
 * Return a list of projects for the current user, grouped by organization.
 */
export interface getProjectsV2ClearAction {
  readonly type: 'uf-api/project/get_projects_v2/CLEAR';
  readonly key: string;
}

const getRunningProjectLayerAnalysisRunsPrefix = `uf-api/project/get_running_project_layer_analysis_runs/`;
/**
 * Action types for project.get_running_project_layer_analysis_runs
 *
 * Gets a list of currently running project analysis layer task ids.
 */
export const getRunningProjectLayerAnalysisRunsActionTypes = {
  LOAD: `${getRunningProjectLayerAnalysisRunsPrefix}LOAD` as 'uf-api/project/get_running_project_layer_analysis_runs/LOAD',
  SUCCESS:
    `${getRunningProjectLayerAnalysisRunsPrefix}SUCCESS` as 'uf-api/project/get_running_project_layer_analysis_runs/SUCCESS',
  FAILURE:
    `${getRunningProjectLayerAnalysisRunsPrefix}FAILURE` as 'uf-api/project/get_running_project_layer_analysis_runs/FAILURE',
  CLEAR:
    `${getRunningProjectLayerAnalysisRunsPrefix}CLEAR` as 'uf-api/project/get_running_project_layer_analysis_runs/CLEAR',
} as const;

/**
 * LOAD action for project.get_running_project_layer_analysis_runs
 * Gets a list of currently running project analysis layer task ids.
 */
export interface getRunningProjectLayerAnalysisRunsLoadAction {
  readonly type: 'uf-api/project/get_running_project_layer_analysis_runs/LOAD';
  readonly params: ProjectServiceInterface.getRunningProjectLayerAnalysisRunsParams;
  readonly promise: Promise<ClientResponse<Array<string>>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for project.get_running_project_layer_analysis_runs
 * Gets a list of currently running project analysis layer task ids.
 */
export interface getRunningProjectLayerAnalysisRunsSuccessAction {
  readonly type: 'uf-api/project/get_running_project_layer_analysis_runs/SUCCESS';
  readonly result: Array<string>;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for project.get_running_project_layer_analysis_runs
 * Gets a list of currently running project analysis layer task ids.
 */
export interface getRunningProjectLayerAnalysisRunsFailureAction {
  readonly type: 'uf-api/project/get_running_project_layer_analysis_runs/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for project.get_running_project_layer_analysis_runs
 * Gets a list of currently running project analysis layer task ids.
 */
export interface getRunningProjectLayerAnalysisRunsClearAction {
  readonly type: 'uf-api/project/get_running_project_layer_analysis_runs/CLEAR';
  readonly key: string;
}

const getScenarioUpdateInProgressPrefix = `uf-api/project/get_scenario_update_in_progress/`;
/**
 * Action types for project.get_scenario_update_in_progress
 *
 * Get the update in progress for a scenario.
 */
export const getScenarioUpdateInProgressActionTypes = {
  LOAD: `${getScenarioUpdateInProgressPrefix}LOAD` as 'uf-api/project/get_scenario_update_in_progress/LOAD',
  SUCCESS:
    `${getScenarioUpdateInProgressPrefix}SUCCESS` as 'uf-api/project/get_scenario_update_in_progress/SUCCESS',
  FAILURE:
    `${getScenarioUpdateInProgressPrefix}FAILURE` as 'uf-api/project/get_scenario_update_in_progress/FAILURE',
  CLEAR:
    `${getScenarioUpdateInProgressPrefix}CLEAR` as 'uf-api/project/get_scenario_update_in_progress/CLEAR',
} as const;

/**
 * LOAD action for project.get_scenario_update_in_progress
 * Get the update in progress for a scenario.
 */
export interface getScenarioUpdateInProgressLoadAction {
  readonly type: 'uf-api/project/get_scenario_update_in_progress/LOAD';
  readonly params: ProjectServiceInterface.getScenarioUpdateInProgressParams;
  readonly promise: Promise<ClientResponse<ScenarioUpdate>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for project.get_scenario_update_in_progress
 * Get the update in progress for a scenario.
 */
export interface getScenarioUpdateInProgressSuccessAction {
  readonly type: 'uf-api/project/get_scenario_update_in_progress/SUCCESS';
  readonly result: ScenarioUpdate;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for project.get_scenario_update_in_progress
 * Get the update in progress for a scenario.
 */
export interface getScenarioUpdateInProgressFailureAction {
  readonly type: 'uf-api/project/get_scenario_update_in_progress/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for project.get_scenario_update_in_progress
 * Get the update in progress for a scenario.
 */
export interface getScenarioUpdateInProgressClearAction {
  readonly type: 'uf-api/project/get_scenario_update_in_progress/CLEAR';
  readonly key: string;
}

const getSymbologyPrefix = `uf-api/project/get_symbology/`;
/**
 * Action types for project.get_symbology
 *
 */
export const getSymbologyActionTypes = {
  LOAD: `${getSymbologyPrefix}LOAD` as 'uf-api/project/get_symbology/LOAD',
  SUCCESS:
    `${getSymbologyPrefix}SUCCESS` as 'uf-api/project/get_symbology/SUCCESS',
  FAILURE:
    `${getSymbologyPrefix}FAILURE` as 'uf-api/project/get_symbology/FAILURE',
  CLEAR: `${getSymbologyPrefix}CLEAR` as 'uf-api/project/get_symbology/CLEAR',
} as const;

/**
 * LOAD action for project.get_symbology
 */
export interface getSymbologyLoadAction {
  readonly type: 'uf-api/project/get_symbology/LOAD';
  readonly params: ProjectServiceInterface.getSymbologyParams;
  readonly promise: Promise<ClientResponse<object>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for project.get_symbology
 */
export interface getSymbologySuccessAction {
  readonly type: 'uf-api/project/get_symbology/SUCCESS';
  readonly result: object;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for project.get_symbology
 */
export interface getSymbologyFailureAction {
  readonly type: 'uf-api/project/get_symbology/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for project.get_symbology
 */
export interface getSymbologyClearAction {
  readonly type: 'uf-api/project/get_symbology/CLEAR';
  readonly key: string;
}

const removeFromLayerListPrefix = `uf-api/project/remove_from_layer_list/`;
/**
 * Action types for project.remove_from_layer_list
 *
 * Remove a layer from the project layer list.
 */
export const removeFromLayerListActionTypes = {
  LOAD: `${removeFromLayerListPrefix}LOAD` as 'uf-api/project/remove_from_layer_list/LOAD',
  SUCCESS:
    `${removeFromLayerListPrefix}SUCCESS` as 'uf-api/project/remove_from_layer_list/SUCCESS',
  FAILURE:
    `${removeFromLayerListPrefix}FAILURE` as 'uf-api/project/remove_from_layer_list/FAILURE',
  CLEAR:
    `${removeFromLayerListPrefix}CLEAR` as 'uf-api/project/remove_from_layer_list/CLEAR',
} as const;

/**
 * LOAD action for project.remove_from_layer_list
 * Remove a layer from the project layer list.
 */
export interface removeFromLayerListLoadAction {
  readonly type: 'uf-api/project/remove_from_layer_list/LOAD';
  readonly params: ProjectServiceInterface.removeFromLayerListParams;
  readonly promise: Promise<ClientResponse<{}>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for project.remove_from_layer_list
 * Remove a layer from the project layer list.
 */
export interface removeFromLayerListSuccessAction {
  readonly type: 'uf-api/project/remove_from_layer_list/SUCCESS';
  readonly result: {};
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for project.remove_from_layer_list
 * Remove a layer from the project layer list.
 */
export interface removeFromLayerListFailureAction {
  readonly type: 'uf-api/project/remove_from_layer_list/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for project.remove_from_layer_list
 * Remove a layer from the project layer list.
 */
export interface removeFromLayerListClearAction {
  readonly type: 'uf-api/project/remove_from_layer_list/CLEAR';
  readonly key: string;
}

const removeProjectWorkingLayerPrefix = `uf-api/project/remove_project_working_layer/`;
/**
 * Action types for project.remove_project_working_layer
 *
 * Remove working layer from project.
 */
export const removeProjectWorkingLayerActionTypes = {
  LOAD: `${removeProjectWorkingLayerPrefix}LOAD` as 'uf-api/project/remove_project_working_layer/LOAD',
  SUCCESS:
    `${removeProjectWorkingLayerPrefix}SUCCESS` as 'uf-api/project/remove_project_working_layer/SUCCESS',
  FAILURE:
    `${removeProjectWorkingLayerPrefix}FAILURE` as 'uf-api/project/remove_project_working_layer/FAILURE',
  CLEAR:
    `${removeProjectWorkingLayerPrefix}CLEAR` as 'uf-api/project/remove_project_working_layer/CLEAR',
} as const;

/**
 * LOAD action for project.remove_project_working_layer
 * Remove working layer from project.
 */
export interface removeProjectWorkingLayerLoadAction {
  readonly type: 'uf-api/project/remove_project_working_layer/LOAD';
  readonly params: ProjectServiceInterface.removeProjectWorkingLayerParams;
  readonly promise: Promise<ClientResponse<{}>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for project.remove_project_working_layer
 * Remove working layer from project.
 */
export interface removeProjectWorkingLayerSuccessAction {
  readonly type: 'uf-api/project/remove_project_working_layer/SUCCESS';
  readonly result: {};
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for project.remove_project_working_layer
 * Remove working layer from project.
 */
export interface removeProjectWorkingLayerFailureAction {
  readonly type: 'uf-api/project/remove_project_working_layer/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for project.remove_project_working_layer
 * Remove working layer from project.
 */
export interface removeProjectWorkingLayerClearAction {
  readonly type: 'uf-api/project/remove_project_working_layer/CLEAR';
  readonly key: string;
}

const runAnalysisModuleForProjectPrefix = `uf-api/project/run_analysis_module_for_project/`;
/**
 * Action types for project.run_analysis_module_for_project
 *
 * analysis module execution
 */
export const runAnalysisModuleForProjectActionTypes = {
  LOAD: `${runAnalysisModuleForProjectPrefix}LOAD` as 'uf-api/project/run_analysis_module_for_project/LOAD',
  SUCCESS:
    `${runAnalysisModuleForProjectPrefix}SUCCESS` as 'uf-api/project/run_analysis_module_for_project/SUCCESS',
  FAILURE:
    `${runAnalysisModuleForProjectPrefix}FAILURE` as 'uf-api/project/run_analysis_module_for_project/FAILURE',
  CLEAR:
    `${runAnalysisModuleForProjectPrefix}CLEAR` as 'uf-api/project/run_analysis_module_for_project/CLEAR',
} as const;

/**
 * LOAD action for project.run_analysis_module_for_project
 * analysis module execution
 */
export interface runAnalysisModuleForProjectLoadAction {
  readonly type: 'uf-api/project/run_analysis_module_for_project/LOAD';
  readonly params: ProjectServiceInterface.runAnalysisModuleForProjectParams;
  readonly promise: Promise<ClientResponse<string>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for project.run_analysis_module_for_project
 * analysis module execution
 */
export interface runAnalysisModuleForProjectSuccessAction {
  readonly type: 'uf-api/project/run_analysis_module_for_project/SUCCESS';
  readonly result: string;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for project.run_analysis_module_for_project
 * analysis module execution
 */
export interface runAnalysisModuleForProjectFailureAction {
  readonly type: 'uf-api/project/run_analysis_module_for_project/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for project.run_analysis_module_for_project
 * analysis module execution
 */
export interface runAnalysisModuleForProjectClearAction {
  readonly type: 'uf-api/project/run_analysis_module_for_project/CLEAR';
  readonly key: string;
}

const runLayerServiceForProjectPrefix = `uf-api/project/run_layer_service_for_project/`;
/**
 * Action types for project.run_layer_service_for_project
 *
 * Create a new project analysis layer task.
 */
export const runLayerServiceForProjectActionTypes = {
  LOAD: `${runLayerServiceForProjectPrefix}LOAD` as 'uf-api/project/run_layer_service_for_project/LOAD',
  SUCCESS:
    `${runLayerServiceForProjectPrefix}SUCCESS` as 'uf-api/project/run_layer_service_for_project/SUCCESS',
  FAILURE:
    `${runLayerServiceForProjectPrefix}FAILURE` as 'uf-api/project/run_layer_service_for_project/FAILURE',
  CLEAR:
    `${runLayerServiceForProjectPrefix}CLEAR` as 'uf-api/project/run_layer_service_for_project/CLEAR',
} as const;

/**
 * LOAD action for project.run_layer_service_for_project
 * Create a new project analysis layer task.
 */
export interface runLayerServiceForProjectLoadAction {
  readonly type: 'uf-api/project/run_layer_service_for_project/LOAD';
  readonly params: ProjectServiceInterface.runLayerServiceForProjectParams;
  readonly promise: Promise<ClientResponse<string>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for project.run_layer_service_for_project
 * Create a new project analysis layer task.
 */
export interface runLayerServiceForProjectSuccessAction {
  readonly type: 'uf-api/project/run_layer_service_for_project/SUCCESS';
  readonly result: string;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for project.run_layer_service_for_project
 * Create a new project analysis layer task.
 */
export interface runLayerServiceForProjectFailureAction {
  readonly type: 'uf-api/project/run_layer_service_for_project/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for project.run_layer_service_for_project
 * Create a new project analysis layer task.
 */
export interface runLayerServiceForProjectClearAction {
  readonly type: 'uf-api/project/run_layer_service_for_project/CLEAR';
  readonly key: string;
}

const setProjectLayerAnalysisRunDashboardPrefix = `uf-api/project/set_project_layer_analysis_run_dashboard/`;
/**
 * Action types for project.set_project_layer_analysis_run_dashboard
 *
 * Set a dashboard id for an analysis layer task.
 */
export const setProjectLayerAnalysisRunDashboardActionTypes = {
  LOAD: `${setProjectLayerAnalysisRunDashboardPrefix}LOAD` as 'uf-api/project/set_project_layer_analysis_run_dashboard/LOAD',
  SUCCESS:
    `${setProjectLayerAnalysisRunDashboardPrefix}SUCCESS` as 'uf-api/project/set_project_layer_analysis_run_dashboard/SUCCESS',
  FAILURE:
    `${setProjectLayerAnalysisRunDashboardPrefix}FAILURE` as 'uf-api/project/set_project_layer_analysis_run_dashboard/FAILURE',
  CLEAR:
    `${setProjectLayerAnalysisRunDashboardPrefix}CLEAR` as 'uf-api/project/set_project_layer_analysis_run_dashboard/CLEAR',
} as const;

/**
 * LOAD action for project.set_project_layer_analysis_run_dashboard
 * Set a dashboard id for an analysis layer task.
 */
export interface setProjectLayerAnalysisRunDashboardLoadAction {
  readonly type: 'uf-api/project/set_project_layer_analysis_run_dashboard/LOAD';
  readonly params: ProjectServiceInterface.setProjectLayerAnalysisRunDashboardParams;
  readonly promise: Promise<ClientResponse<{}>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for project.set_project_layer_analysis_run_dashboard
 * Set a dashboard id for an analysis layer task.
 */
export interface setProjectLayerAnalysisRunDashboardSuccessAction {
  readonly type: 'uf-api/project/set_project_layer_analysis_run_dashboard/SUCCESS';
  readonly result: {};
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for project.set_project_layer_analysis_run_dashboard
 * Set a dashboard id for an analysis layer task.
 */
export interface setProjectLayerAnalysisRunDashboardFailureAction {
  readonly type: 'uf-api/project/set_project_layer_analysis_run_dashboard/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for project.set_project_layer_analysis_run_dashboard
 * Set a dashboard id for an analysis layer task.
 */
export interface setProjectLayerAnalysisRunDashboardClearAction {
  readonly type: 'uf-api/project/set_project_layer_analysis_run_dashboard/CLEAR';
  readonly key: string;
}

const setProjectLayerAnalysisRunDashboardModelPrefix = `uf-api/project/set_project_layer_analysis_run_dashboard_model/`;
/**
 * Action types for project.set_project_layer_analysis_run_dashboard_model
 *
 * Set the status and an optional detailed message for an analysis layer task.
 */
export const setProjectLayerAnalysisRunDashboardModelActionTypes = {
  LOAD: `${setProjectLayerAnalysisRunDashboardModelPrefix}LOAD` as 'uf-api/project/set_project_layer_analysis_run_dashboard_model/LOAD',
  SUCCESS:
    `${setProjectLayerAnalysisRunDashboardModelPrefix}SUCCESS` as 'uf-api/project/set_project_layer_analysis_run_dashboard_model/SUCCESS',
  FAILURE:
    `${setProjectLayerAnalysisRunDashboardModelPrefix}FAILURE` as 'uf-api/project/set_project_layer_analysis_run_dashboard_model/FAILURE',
  CLEAR:
    `${setProjectLayerAnalysisRunDashboardModelPrefix}CLEAR` as 'uf-api/project/set_project_layer_analysis_run_dashboard_model/CLEAR',
} as const;

/**
 * LOAD action for project.set_project_layer_analysis_run_dashboard_model
 * Set the status and an optional detailed message for an analysis layer task.
 */
export interface setProjectLayerAnalysisRunDashboardModelLoadAction {
  readonly type: 'uf-api/project/set_project_layer_analysis_run_dashboard_model/LOAD';
  readonly params: ProjectServiceInterface.setProjectLayerAnalysisRunDashboardModelParams;
  readonly promise: Promise<ClientResponse<{}>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for project.set_project_layer_analysis_run_dashboard_model
 * Set the status and an optional detailed message for an analysis layer task.
 */
export interface setProjectLayerAnalysisRunDashboardModelSuccessAction {
  readonly type: 'uf-api/project/set_project_layer_analysis_run_dashboard_model/SUCCESS';
  readonly result: {};
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for project.set_project_layer_analysis_run_dashboard_model
 * Set the status and an optional detailed message for an analysis layer task.
 */
export interface setProjectLayerAnalysisRunDashboardModelFailureAction {
  readonly type: 'uf-api/project/set_project_layer_analysis_run_dashboard_model/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for project.set_project_layer_analysis_run_dashboard_model
 * Set the status and an optional detailed message for an analysis layer task.
 */
export interface setProjectLayerAnalysisRunDashboardModelClearAction {
  readonly type: 'uf-api/project/set_project_layer_analysis_run_dashboard_model/CLEAR';
  readonly key: string;
}

const setProjectLayerAnalysisRunOutputTablePrefix = `uf-api/project/set_project_layer_analysis_run_output_table/`;
/**
 * Action types for project.set_project_layer_analysis_run_output_table
 *
 * Set a result table path for an analysis layer task run.
 */
export const setProjectLayerAnalysisRunOutputTableActionTypes = {
  LOAD: `${setProjectLayerAnalysisRunOutputTablePrefix}LOAD` as 'uf-api/project/set_project_layer_analysis_run_output_table/LOAD',
  SUCCESS:
    `${setProjectLayerAnalysisRunOutputTablePrefix}SUCCESS` as 'uf-api/project/set_project_layer_analysis_run_output_table/SUCCESS',
  FAILURE:
    `${setProjectLayerAnalysisRunOutputTablePrefix}FAILURE` as 'uf-api/project/set_project_layer_analysis_run_output_table/FAILURE',
  CLEAR:
    `${setProjectLayerAnalysisRunOutputTablePrefix}CLEAR` as 'uf-api/project/set_project_layer_analysis_run_output_table/CLEAR',
} as const;

/**
 * LOAD action for project.set_project_layer_analysis_run_output_table
 * Set a result table path for an analysis layer task run.
 */
export interface setProjectLayerAnalysisRunOutputTableLoadAction {
  readonly type: 'uf-api/project/set_project_layer_analysis_run_output_table/LOAD';
  readonly params: ProjectServiceInterface.setProjectLayerAnalysisRunOutputTableParams;
  readonly promise: Promise<ClientResponse<{}>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for project.set_project_layer_analysis_run_output_table
 * Set a result table path for an analysis layer task run.
 */
export interface setProjectLayerAnalysisRunOutputTableSuccessAction {
  readonly type: 'uf-api/project/set_project_layer_analysis_run_output_table/SUCCESS';
  readonly result: {};
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for project.set_project_layer_analysis_run_output_table
 * Set a result table path for an analysis layer task run.
 */
export interface setProjectLayerAnalysisRunOutputTableFailureAction {
  readonly type: 'uf-api/project/set_project_layer_analysis_run_output_table/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for project.set_project_layer_analysis_run_output_table
 * Set a result table path for an analysis layer task run.
 */
export interface setProjectLayerAnalysisRunOutputTableClearAction {
  readonly type: 'uf-api/project/set_project_layer_analysis_run_output_table/CLEAR';
  readonly key: string;
}

const setProjectLayerAnalysisRunStatusPrefix = `uf-api/project/set_project_layer_analysis_run_status/`;
/**
 * Action types for project.set_project_layer_analysis_run_status
 *
 * Set the status of an analysis layer task.
 */
export const setProjectLayerAnalysisRunStatusActionTypes = {
  LOAD: `${setProjectLayerAnalysisRunStatusPrefix}LOAD` as 'uf-api/project/set_project_layer_analysis_run_status/LOAD',
  SUCCESS:
    `${setProjectLayerAnalysisRunStatusPrefix}SUCCESS` as 'uf-api/project/set_project_layer_analysis_run_status/SUCCESS',
  FAILURE:
    `${setProjectLayerAnalysisRunStatusPrefix}FAILURE` as 'uf-api/project/set_project_layer_analysis_run_status/FAILURE',
  CLEAR:
    `${setProjectLayerAnalysisRunStatusPrefix}CLEAR` as 'uf-api/project/set_project_layer_analysis_run_status/CLEAR',
} as const;

/**
 * LOAD action for project.set_project_layer_analysis_run_status
 * Set the status of an analysis layer task.
 */
export interface setProjectLayerAnalysisRunStatusLoadAction {
  readonly type: 'uf-api/project/set_project_layer_analysis_run_status/LOAD';
  readonly params: ProjectServiceInterface.setProjectLayerAnalysisRunStatusParams;
  readonly promise: Promise<ClientResponse<{}>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for project.set_project_layer_analysis_run_status
 * Set the status of an analysis layer task.
 */
export interface setProjectLayerAnalysisRunStatusSuccessAction {
  readonly type: 'uf-api/project/set_project_layer_analysis_run_status/SUCCESS';
  readonly result: {};
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for project.set_project_layer_analysis_run_status
 * Set the status of an analysis layer task.
 */
export interface setProjectLayerAnalysisRunStatusFailureAction {
  readonly type: 'uf-api/project/set_project_layer_analysis_run_status/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for project.set_project_layer_analysis_run_status
 * Set the status of an analysis layer task.
 */
export interface setProjectLayerAnalysisRunStatusClearAction {
  readonly type: 'uf-api/project/set_project_layer_analysis_run_status/CLEAR';
  readonly key: string;
}

const updateLayerNamePrefix = `uf-api/project/update_layer_name/`;
/**
 * Action types for project.update_layer_name
 *
 * Change the name of a layer.
 */
export const updateLayerNameActionTypes = {
  LOAD: `${updateLayerNamePrefix}LOAD` as 'uf-api/project/update_layer_name/LOAD',
  SUCCESS:
    `${updateLayerNamePrefix}SUCCESS` as 'uf-api/project/update_layer_name/SUCCESS',
  FAILURE:
    `${updateLayerNamePrefix}FAILURE` as 'uf-api/project/update_layer_name/FAILURE',
  CLEAR:
    `${updateLayerNamePrefix}CLEAR` as 'uf-api/project/update_layer_name/CLEAR',
} as const;

/**
 * LOAD action for project.update_layer_name
 * Change the name of a layer.
 */
export interface updateLayerNameLoadAction {
  readonly type: 'uf-api/project/update_layer_name/LOAD';
  readonly params: ProjectServiceInterface.updateLayerNameParams;
  readonly promise: Promise<ClientResponse<{}>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for project.update_layer_name
 * Change the name of a layer.
 */
export interface updateLayerNameSuccessAction {
  readonly type: 'uf-api/project/update_layer_name/SUCCESS';
  readonly result: {};
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for project.update_layer_name
 * Change the name of a layer.
 */
export interface updateLayerNameFailureAction {
  readonly type: 'uf-api/project/update_layer_name/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for project.update_layer_name
 * Change the name of a layer.
 */
export interface updateLayerNameClearAction {
  readonly type: 'uf-api/project/update_layer_name/CLEAR';
  readonly key: string;
}

const updateProjectLayerAnalysisRunDashboardPrefix = `uf-api/project/update_project_layer_analysis_run_dashboard/`;
/**
 * Action types for project.update_project_layer_analysis_run_dashboard
 *
 * Update the dashboard name for an analysis layer task dashboard.
 */
export const updateProjectLayerAnalysisRunDashboardActionTypes = {
  LOAD: `${updateProjectLayerAnalysisRunDashboardPrefix}LOAD` as 'uf-api/project/update_project_layer_analysis_run_dashboard/LOAD',
  SUCCESS:
    `${updateProjectLayerAnalysisRunDashboardPrefix}SUCCESS` as 'uf-api/project/update_project_layer_analysis_run_dashboard/SUCCESS',
  FAILURE:
    `${updateProjectLayerAnalysisRunDashboardPrefix}FAILURE` as 'uf-api/project/update_project_layer_analysis_run_dashboard/FAILURE',
  CLEAR:
    `${updateProjectLayerAnalysisRunDashboardPrefix}CLEAR` as 'uf-api/project/update_project_layer_analysis_run_dashboard/CLEAR',
} as const;

/**
 * LOAD action for project.update_project_layer_analysis_run_dashboard
 * Update the dashboard name for an analysis layer task dashboard.
 */
export interface updateProjectLayerAnalysisRunDashboardLoadAction {
  readonly type: 'uf-api/project/update_project_layer_analysis_run_dashboard/LOAD';
  readonly params: ProjectServiceInterface.updateProjectLayerAnalysisRunDashboardParams;
  readonly promise: Promise<ClientResponse<{}>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for project.update_project_layer_analysis_run_dashboard
 * Update the dashboard name for an analysis layer task dashboard.
 */
export interface updateProjectLayerAnalysisRunDashboardSuccessAction {
  readonly type: 'uf-api/project/update_project_layer_analysis_run_dashboard/SUCCESS';
  readonly result: {};
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for project.update_project_layer_analysis_run_dashboard
 * Update the dashboard name for an analysis layer task dashboard.
 */
export interface updateProjectLayerAnalysisRunDashboardFailureAction {
  readonly type: 'uf-api/project/update_project_layer_analysis_run_dashboard/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for project.update_project_layer_analysis_run_dashboard
 * Update the dashboard name for an analysis layer task dashboard.
 */
export interface updateProjectLayerAnalysisRunDashboardClearAction {
  readonly type: 'uf-api/project/update_project_layer_analysis_run_dashboard/CLEAR';
  readonly key: string;
}

const updateScenarioAnalysisParamsPrefix = `uf-api/project/update_scenario_analysis_params/`;
/**
 * Action types for project.update_scenario_analysis_params
 *
 * Update analysis parameters for a scenario.
 */
export const updateScenarioAnalysisParamsActionTypes = {
  LOAD: `${updateScenarioAnalysisParamsPrefix}LOAD` as 'uf-api/project/update_scenario_analysis_params/LOAD',
  SUCCESS:
    `${updateScenarioAnalysisParamsPrefix}SUCCESS` as 'uf-api/project/update_scenario_analysis_params/SUCCESS',
  FAILURE:
    `${updateScenarioAnalysisParamsPrefix}FAILURE` as 'uf-api/project/update_scenario_analysis_params/FAILURE',
  CLEAR:
    `${updateScenarioAnalysisParamsPrefix}CLEAR` as 'uf-api/project/update_scenario_analysis_params/CLEAR',
} as const;

/**
 * LOAD action for project.update_scenario_analysis_params
 * Update analysis parameters for a scenario.
 */
export interface updateScenarioAnalysisParamsLoadAction {
  readonly type: 'uf-api/project/update_scenario_analysis_params/LOAD';
  readonly params: ProjectServiceInterface.updateScenarioAnalysisParamsParams;
  readonly promise: Promise<
    ClientResponse<Array<AnalysisParameterValidationError>>
  >;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for project.update_scenario_analysis_params
 * Update analysis parameters for a scenario.
 */
export interface updateScenarioAnalysisParamsSuccessAction {
  readonly type: 'uf-api/project/update_scenario_analysis_params/SUCCESS';
  readonly result: Array<AnalysisParameterValidationError>;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for project.update_scenario_analysis_params
 * Update analysis parameters for a scenario.
 */
export interface updateScenarioAnalysisParamsFailureAction {
  readonly type: 'uf-api/project/update_scenario_analysis_params/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for project.update_scenario_analysis_params
 * Update analysis parameters for a scenario.
 */
export interface updateScenarioAnalysisParamsClearAction {
  readonly type: 'uf-api/project/update_scenario_analysis_params/CLEAR';
  readonly key: string;
}

export const projectActionTypesMap = {
  /** Action types for project.add_project_working_layer */
  add_project_working_layer: addProjectWorkingLayerActionTypes,
  /** Action types for project.add_symbology */
  add_symbology: addSymbologyActionTypes,
  /** Action types for project.add_to_layer_list */
  add_to_layer_list: addToLayerListActionTypes,
  /** Action types for project.create_project */
  create_project: createProjectActionTypes,
  /** Action types for project.create_project_dynamic_layer */
  create_project_dynamic_layer: createProjectDynamicLayerActionTypes,
  /** Action types for project.create_scenario */
  create_scenario: createScenarioActionTypes,
  /** Action types for project.delete_project */
  delete_project: deleteProjectActionTypes,
  /** Action types for project.delete_project_layer_analysis_run_dashboard */
  delete_project_layer_analysis_run_dashboard:
    deleteProjectLayerAnalysisRunDashboardActionTypes,
  /** Action types for project.delete_scenario */
  delete_scenario: deleteScenarioActionTypes,
  /** Action types for project.delete_symbology */
  delete_symbology: deleteSymbologyActionTypes,
  /** Action types for project.download_exported */
  download_exported: downloadExportedActionTypes,
  /** Action types for project.edit_project */
  edit_project: editProjectActionTypes,
  /** Action types for project.edit_scenario */
  edit_scenario: editScenarioActionTypes,
  /** Action types for project.enqueue_create_base_canvas_task */
  enqueue_create_base_canvas_task: enqueueCreateBaseCanvasTaskActionTypes,
  /** Action types for project.enqueue_create_context_canvas_task */
  enqueue_create_context_canvas_task: enqueueCreateContextCanvasTaskActionTypes,
  /** Action types for project.enqueue_import_layer */
  enqueue_import_layer: enqueueImportLayerActionTypes,
  /** Action types for project.enqueue_project_buffered_layer_task */
  enqueue_project_buffered_layer_task:
    enqueueProjectBufferedLayerTaskActionTypes,
  /** Action types for project.enqueue_project_convert_layer_task */
  enqueue_project_convert_layer_task: enqueueProjectConvertLayerTaskActionTypes,
  /** Action types for project.export_module_params */
  export_module_params: exportModuleParamsActionTypes,
  /** Action types for project.get_analysis_module */
  get_analysis_module: getAnalysisModuleActionTypes,
  /** Action types for project.get_analysis_modules */
  get_analysis_modules: getAnalysisModulesActionTypes,
  /** Action types for project.get_canvas_types */
  get_canvas_types: getCanvasTypesActionTypes,
  /** Action types for project.get_project */
  get_project: getProjectActionTypes,
  /** Action types for project.get_project_analysis_params */
  get_project_analysis_params: getProjectAnalysisParamsActionTypes,
  /** Action types for project.get_project_available_layers */
  get_project_available_layers: getProjectAvailableLayersActionTypes,
  /** Action types for project.get_project_tasks */
  get_project_tasks: getProjectTasksActionTypes,
  /** Action types for project.get_project_update_in_progress */
  get_project_update_in_progress: getProjectUpdateInProgressActionTypes,
  /** Action types for project.get_project_working_layers */
  get_project_working_layers: getProjectWorkingLayersActionTypes,
  /** Action types for project.get_projects */
  get_projects: getProjectsActionTypes,
  /** Action types for project.get_projects_v2 */
  get_projects_v2: getProjectsV2ActionTypes,
  /** Action types for project.get_running_project_layer_analysis_runs */
  get_running_project_layer_analysis_runs:
    getRunningProjectLayerAnalysisRunsActionTypes,
  /** Action types for project.get_scenario_update_in_progress */
  get_scenario_update_in_progress: getScenarioUpdateInProgressActionTypes,
  /** Action types for project.get_symbology */
  get_symbology: getSymbologyActionTypes,
  /** Action types for project.remove_from_layer_list */
  remove_from_layer_list: removeFromLayerListActionTypes,
  /** Action types for project.remove_project_working_layer */
  remove_project_working_layer: removeProjectWorkingLayerActionTypes,
  /** Action types for project.run_analysis_module_for_project */
  run_analysis_module_for_project: runAnalysisModuleForProjectActionTypes,
  /** Action types for project.run_layer_service_for_project */
  run_layer_service_for_project: runLayerServiceForProjectActionTypes,
  /** Action types for project.set_project_layer_analysis_run_dashboard */
  set_project_layer_analysis_run_dashboard:
    setProjectLayerAnalysisRunDashboardActionTypes,
  /** Action types for project.set_project_layer_analysis_run_dashboard_model */
  set_project_layer_analysis_run_dashboard_model:
    setProjectLayerAnalysisRunDashboardModelActionTypes,
  /** Action types for project.set_project_layer_analysis_run_output_table */
  set_project_layer_analysis_run_output_table:
    setProjectLayerAnalysisRunOutputTableActionTypes,
  /** Action types for project.set_project_layer_analysis_run_status */
  set_project_layer_analysis_run_status:
    setProjectLayerAnalysisRunStatusActionTypes,
  /** Action types for project.update_layer_name */
  update_layer_name: updateLayerNameActionTypes,
  /** Action types for project.update_project_layer_analysis_run_dashboard */
  update_project_layer_analysis_run_dashboard:
    updateProjectLayerAnalysisRunDashboardActionTypes,
  /** Action types for project.update_scenario_analysis_params */
  update_scenario_analysis_params: updateScenarioAnalysisParamsActionTypes,
} as const;

export const projectDetailsMap = {
  add_project_working_layer: {
    /** HTTP method for project.add_project_working_layer */
    httpMethod: 'put' as const,
  },
  add_symbology: {
    /** HTTP method for project.add_symbology */
    httpMethod: 'post' as const,
  },
  add_to_layer_list: {
    /** HTTP method for project.add_to_layer_list */
    httpMethod: 'put' as const,
  },
  create_project: {
    /** HTTP method for project.create_project */
    httpMethod: 'post' as const,
  },
  create_project_dynamic_layer: {
    /** HTTP method for project.create_project_dynamic_layer */
    httpMethod: 'post' as const,
  },
  create_scenario: {
    /** HTTP method for project.create_scenario */
    httpMethod: 'post' as const,
  },
  delete_project: {
    /** HTTP method for project.delete_project */
    httpMethod: 'delete' as const,
  },
  delete_project_layer_analysis_run_dashboard: {
    /** HTTP method for project.delete_project_layer_analysis_run_dashboard */
    httpMethod: 'delete' as const,
  },
  delete_scenario: {
    /** HTTP method for project.delete_scenario */
    httpMethod: 'delete' as const,
  },
  delete_symbology: {
    /** HTTP method for project.delete_symbology */
    httpMethod: 'delete' as const,
  },
  download_exported: {
    /** HTTP method for project.download_exported */
    httpMethod: 'get' as const,
  },
  edit_project: {
    /** HTTP method for project.edit_project */
    httpMethod: 'put' as const,
  },
  edit_scenario: {
    /** HTTP method for project.edit_scenario */
    httpMethod: 'put' as const,
  },
  enqueue_create_base_canvas_task: {
    /** HTTP method for project.enqueue_create_base_canvas_task */
    httpMethod: 'post' as const,
  },
  enqueue_create_context_canvas_task: {
    /** HTTP method for project.enqueue_create_context_canvas_task */
    httpMethod: 'post' as const,
  },
  enqueue_import_layer: {
    /** HTTP method for project.enqueue_import_layer */
    httpMethod: 'post' as const,
  },
  enqueue_project_buffered_layer_task: {
    /** HTTP method for project.enqueue_project_buffered_layer_task */
    httpMethod: 'post' as const,
  },
  enqueue_project_convert_layer_task: {
    /** HTTP method for project.enqueue_project_convert_layer_task */
    httpMethod: 'post' as const,
  },
  export_module_params: {
    /** HTTP method for project.export_module_params */
    httpMethod: 'get' as const,
  },
  get_analysis_module: {
    /** HTTP method for project.get_analysis_module */
    httpMethod: 'get' as const,
  },
  get_analysis_modules: {
    /** HTTP method for project.get_analysis_modules */
    httpMethod: 'get' as const,
  },
  get_canvas_types: {
    /** HTTP method for project.get_canvas_types */
    httpMethod: 'get' as const,
  },
  get_project: {
    /** HTTP method for project.get_project */
    httpMethod: 'get' as const,
  },
  get_project_analysis_params: {
    /** HTTP method for project.get_project_analysis_params */
    httpMethod: 'get' as const,
  },
  get_project_available_layers: {
    /** HTTP method for project.get_project_available_layers */
    httpMethod: 'get' as const,
  },
  get_project_tasks: {
    /** HTTP method for project.get_project_tasks */
    httpMethod: 'get' as const,
  },
  get_project_update_in_progress: {
    /** HTTP method for project.get_project_update_in_progress */
    httpMethod: 'get' as const,
  },
  get_project_working_layers: {
    /** HTTP method for project.get_project_working_layers */
    httpMethod: 'get' as const,
  },
  get_projects: {
    /** HTTP method for project.get_projects */
    httpMethod: 'get' as const,
  },
  get_projects_v2: {
    /** HTTP method for project.get_projects_v2 */
    httpMethod: 'get' as const,
  },
  get_running_project_layer_analysis_runs: {
    /** HTTP method for project.get_running_project_layer_analysis_runs */
    httpMethod: 'get' as const,
  },
  get_scenario_update_in_progress: {
    /** HTTP method for project.get_scenario_update_in_progress */
    httpMethod: 'get' as const,
  },
  get_symbology: {
    /** HTTP method for project.get_symbology */
    httpMethod: 'get' as const,
  },
  remove_from_layer_list: {
    /** HTTP method for project.remove_from_layer_list */
    httpMethod: 'delete' as const,
  },
  remove_project_working_layer: {
    /** HTTP method for project.remove_project_working_layer */
    httpMethod: 'delete' as const,
  },
  run_analysis_module_for_project: {
    /** HTTP method for project.run_analysis_module_for_project */
    httpMethod: 'post' as const,
  },
  run_layer_service_for_project: {
    /** HTTP method for project.run_layer_service_for_project */
    httpMethod: 'post' as const,
  },
  set_project_layer_analysis_run_dashboard: {
    /** HTTP method for project.set_project_layer_analysis_run_dashboard */
    httpMethod: 'post' as const,
  },
  set_project_layer_analysis_run_dashboard_model: {
    /** HTTP method for project.set_project_layer_analysis_run_dashboard_model */
    httpMethod: 'post' as const,
  },
  set_project_layer_analysis_run_output_table: {
    /** HTTP method for project.set_project_layer_analysis_run_output_table */
    httpMethod: 'post' as const,
  },
  set_project_layer_analysis_run_status: {
    /** HTTP method for project.set_project_layer_analysis_run_status */
    httpMethod: 'post' as const,
  },
  update_layer_name: {
    /** HTTP method for project.update_layer_name */
    httpMethod: 'put' as const,
  },
  update_project_layer_analysis_run_dashboard: {
    /** HTTP method for project.update_project_layer_analysis_run_dashboard */
    httpMethod: 'put' as const,
  },
  update_scenario_analysis_params: {
    /** HTTP method for project.update_scenario_analysis_params */
    httpMethod: 'put' as const,
  },
} as const;

/**
 * Local DataState<T> - kept in sync with uf/data/dataState.ts
 */
interface DataState<T> {
  readonly loading: boolean;
  readonly loaded: boolean;
  readonly requestId: string;
  readonly requestTime?: string;
  readonly responseTime?: string;
  readonly requestCount?: number;
  readonly error?: any;
  readonly promise?: Promise<T>;
  readonly data?: T;
  readonly extra?: any;
  readonly key?: string;
}
export interface ProjectServiceState {
  /** all recent addProjectWorkingLayer results (POST/PUT/PATCH) */
  readonly add_project_working_layer: DataState<LayerReference>[];
  /** all recent addSymbology results (POST/PUT/PATCH) */
  readonly add_symbology: DataState<{}>[];
  /** all recent addToLayerList results (POST/PUT/PATCH) */
  readonly add_to_layer_list: DataState<{}>[];
  /** all recent createProject results (POST/PUT/PATCH) */
  readonly create_project: DataState<ProjectMetadata>[];
  /** all recent createProjectDynamicLayer results (POST/PUT/PATCH) */
  readonly create_project_dynamic_layer: DataState<LayerReference>[];
  /** all recent createScenario results (POST/PUT/PATCH) */
  readonly create_scenario: DataState<{}>[];
  /** deleteProject state, by key */
  delete_project: {
    readonly [key: string]: DataState<{}>;
  };
  /** deleteProjectLayerAnalysisRunDashboard state, by key */
  delete_project_layer_analysis_run_dashboard: {
    readonly [key: string]: DataState<{}>;
  };
  /** deleteScenario state, by key */
  delete_scenario: {
    readonly [key: string]: DataState<{}>;
  };
  /** deleteSymbology state, by key */
  delete_symbology: {
    readonly [key: string]: DataState<{}>;
  };
  /** downloadExported state, by key */
  download_exported: {
    readonly [key: string]: DataState<{}>;
  };
  /** all recent editProject results (POST/PUT/PATCH) */
  readonly edit_project: DataState<{}>[];
  /** all recent editScenario results (POST/PUT/PATCH) */
  readonly edit_scenario: DataState<{}>[];
  /** all recent enqueueCreateBaseCanvasTask results (POST/PUT/PATCH) */
  readonly enqueue_create_base_canvas_task: DataState<string>[];
  /** all recent enqueueCreateContextCanvasTask results (POST/PUT/PATCH) */
  readonly enqueue_create_context_canvas_task: DataState<string>[];
  /** all recent enqueueImportLayer results (POST/PUT/PATCH) */
  readonly enqueue_import_layer: DataState<string>[];
  /** all recent enqueueProjectBufferedLayerTask results (POST/PUT/PATCH) */
  readonly enqueue_project_buffered_layer_task: DataState<string>[];
  /** all recent enqueueProjectConvertLayerTask results (POST/PUT/PATCH) */
  readonly enqueue_project_convert_layer_task: DataState<string>[];
  /** exportModuleParams state, by key */
  export_module_params: {
    readonly [key: string]: DataState<Blob>;
  };
  /** getAnalysisModule state, by key */
  get_analysis_module: {
    readonly [key: string]: DataState<AnalysisModuleInfo>;
  };
  /** getAnalysisModules state, by key */
  get_analysis_modules: {
    readonly [key: string]: DataState<Array<string>>;
  };
  /** getCanvasTypes state, by key */
  get_canvas_types: {
    readonly [key: string]: DataState<Array<CanvasTypeInfo>>;
  };
  /** getProject state, by key */
  get_project: {
    readonly [key: string]: DataState<ProjectMetadata>;
  };
  /** getProjectAnalysisParams state, by key */
  get_project_analysis_params: {
    readonly [key: string]: DataState<ProjectModuleParams>;
  };
  /** getProjectAvailableLayers state, by key */
  get_project_available_layers: {
    readonly [key: string]: DataState<ProjectAvailableLayers>;
  };
  /** getProjectTasks state, by key */
  get_project_tasks: {
    readonly [key: string]: DataState<Array<Task>>;
  };
  /** getProjectUpdateInProgress state, by key */
  get_project_update_in_progress: {
    readonly [key: string]: DataState<ProjectUpdate>;
  };
  /** getProjectWorkingLayers state, by key */
  get_project_working_layers: {
    readonly [key: string]: DataState<Array<LayerReference>>;
  };
  /** getProjects state, by key */
  get_projects: {
    readonly [key: string]: DataState<ProjectList>;
  };
  /** getProjectsV2 state, by key */
  get_projects_v2: {
    readonly [key: string]: DataState<OrganizationProjectsList>;
  };
  /** getRunningProjectLayerAnalysisRuns state, by key */
  get_running_project_layer_analysis_runs: {
    readonly [key: string]: DataState<Array<string>>;
  };
  /** getScenarioUpdateInProgress state, by key */
  get_scenario_update_in_progress: {
    readonly [key: string]: DataState<ScenarioUpdate>;
  };
  /** getSymbology state, by key */
  get_symbology: {
    readonly [key: string]: DataState<object>;
  };
  /** removeFromLayerList state, by key */
  remove_from_layer_list: {
    readonly [key: string]: DataState<{}>;
  };
  /** removeProjectWorkingLayer state, by key */
  remove_project_working_layer: {
    readonly [key: string]: DataState<{}>;
  };
  /** all recent runAnalysisModuleForProject results (POST/PUT/PATCH) */
  readonly run_analysis_module_for_project: DataState<string>[];
  /** all recent runLayerServiceForProject results (POST/PUT/PATCH) */
  readonly run_layer_service_for_project: DataState<string>[];
  /** all recent setProjectLayerAnalysisRunDashboard results (POST/PUT/PATCH) */
  readonly set_project_layer_analysis_run_dashboard: DataState<{}>[];
  /** all recent setProjectLayerAnalysisRunDashboardModel results (POST/PUT/PATCH) */
  readonly set_project_layer_analysis_run_dashboard_model: DataState<{}>[];
  /** all recent setProjectLayerAnalysisRunOutputTable results (POST/PUT/PATCH) */
  readonly set_project_layer_analysis_run_output_table: DataState<{}>[];
  /** all recent setProjectLayerAnalysisRunStatus results (POST/PUT/PATCH) */
  readonly set_project_layer_analysis_run_status: DataState<{}>[];
  /** all recent updateLayerName results (POST/PUT/PATCH) */
  readonly update_layer_name: DataState<{}>[];
  /** all recent updateProjectLayerAnalysisRunDashboard results (POST/PUT/PATCH) */
  readonly update_project_layer_analysis_run_dashboard: DataState<{}>[];
  /** all recent updateScenarioAnalysisParams results (POST/PUT/PATCH) */
  readonly update_scenario_analysis_params: DataState<
    Array<AnalysisParameterValidationError>
  >[];
}
