import React, { FunctionComponent } from 'react';
import { FallbackProps } from 'react-error-boundary';
import { t } from 'ttag';

import ErrorView from 'uf/ui/error/ErrorView';

type PageErrorHandlerProps = FallbackProps;

const PageErrorHandler: FunctionComponent<PageErrorHandlerProps> = props => {
  const { error, componentStack } = props;
  const visibleError = __DEVELOPMENT__
    ? error
    : new Error(t`Please reload this page to return to UrbanFootprint`);
  return <ErrorView error={visibleError} stack={componentStack} />;
};

export default PageErrorHandler;
