import { Dispatch } from 'redux';

import { makeLibraryId } from 'uf/builtforms';
import { loadLibrary } from 'uf/builtforms/actions/data';
import { initializeBuiltformEditor } from 'uf/builtforms/actions/editor';
import { setUIProperty } from 'uf/explore/actions';
import { SetInitialRoute } from 'uf/routing/state';

export const setInitialBuiltFormRoute: SetInitialRoute = (
  location,
  query,
  dispatch: Dispatch,
) => {
  dispatch(setUIProperty('manageViewVisible', true));
  dispatch(setUIProperty('manageViewEditor', 'builtforms'));

  const { type, namespace, libraryKey, key } = location.payload as any;
  const libraryId = makeLibraryId(namespace, libraryKey);
  dispatch(initializeBuiltformEditor(libraryId, type, key));

  // TODO: move to an epic
  dispatch(loadLibrary(libraryId));
};
