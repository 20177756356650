import _ from 'lodash';
import { createSelector } from 'reselect';

import {
  BuiltFormPainterEmploymentAllocation,
  BuiltFormPainterEmploymentAllocations,
  BuiltFormPainterResidentialAllocation,
  Library,
  PaintingActionInfo,
} from 'uf-api';
import { makeGetApiArrayResults } from 'uf/api/selectors';
import { EMPTY_ARRAY, EMPTY_OBJECT } from 'uf/base';
import { parseFullPath } from 'uf/base/dataset';
import { makeGetFromPropsSelector } from 'uf/base/selector';
import { CanvasColumnKey } from 'uf/builtforms';
import { PaintAllocationKey } from 'uf/builtforms/residential';
import { makeGetLibraryState } from 'uf/builtforms/selectors';
import {
  DataState,
  EmptyKeyedState,
  getData,
  isLoaded,
} from 'uf/data/dataState';
import { PaintState } from 'uf/explore/state';
import {
  EmploymentAllocation,
  EmploymentDirectPaintingState,
  EmploymentEditMode,
  getSubsectorAllocations,
} from 'uf/painting/employment';
import { makePaintScenarioKey } from 'uf/painting/painting';
import {
  ResidentialAllocation,
  ResidentialDirectPainting,
} from 'uf/painting/residential';
import { ProjectId } from 'uf/projects';
import { ScenarioId } from 'uf/scenarios';
import { makeTaskDataStateSelector } from 'uf/tasks/selectors';

import { getLegacyExploreState, makeGetExploreByProjectState } from './explore';

export const getPaintState = createSelector(
  makeGetExploreByProjectState(),
  ({ paint = EMPTY_OBJECT as PaintState }) => paint,
);

export function makeGetRecentBuiltForms() {
  return createSelector(getPaintState, ({ recent }) => recent || EMPTY_ARRAY);
}

export const getRecentBuiltForms = makeGetRecentBuiltForms();

export function makeGetCurrentPaintingBuiltForm() {
  return createSelector(getPaintState, ({ current: currentBuiltForm }) => {
    if (!currentBuiltForm?.builtFormKey || !currentBuiltForm?.builtFormType) {
      return null;
    }
    return currentBuiltForm;
  });
}
// Always paint by the current BuiltForm
export const getCurrentPaintingBuiltForm = makeGetCurrentPaintingBuiltForm();

const getHoverPaintingBuiltForm = createSelector(
  getPaintState,
  ({ hover: hoverBuiltForm }) => {
    if (!hoverBuiltForm?.builtFormKey || !hoverBuiltForm?.builtFormType) {
      return null;
    }
    return hoverBuiltForm;
  },
);

// Decides which Built Form to display in the preview
// TODO: Create a type for this
export const getActiveBuiltForm = createSelector(
  getCurrentPaintingBuiltForm,
  getHoverPaintingBuiltForm,
  (currentBuiltForm, hoverBuiltForm) =>
    hoverBuiltForm || currentBuiltForm || (EMPTY_OBJECT as any),
);

export function makeGetLatestSplitBaseCanvas() {
  return createSelector(
    makeGetApiArrayResults('paint', 'enqueue_paint_base_scenario_canvas'),
    makeGetFromPropsSelector<ProjectId, 'projectId'>('projectId'),
    (results, projectId): DataState<PaintingActionInfo> => {
      // TODO: we really need a better key than just the projectid - this could
      // be any base canvas edit!
      const matchingResults = results.filter(
        result => result.key === projectId,
      );
      const resultsByTime = _.sortBy(
        matchingResults,
        result => result.requestTime,
      );
      return resultsByTime.slice(-1)[0] ?? (EmptyKeyedState as any);
    },
  );
}
const EmptyResidential: ResidentialDirectPainting = {
  allocations: [],
  isZero: false,
  mode: BuiltFormPainterResidentialAllocation.AllocationModeEnum.Feature,
};
export function makeGetResidentialAllocationDetails() {
  return createSelector(
    getPaintState,
    state => state.residential ?? EmptyResidential,
  );
}

const EmptyEmployment: EmploymentDirectPaintingState = {
  allocationMode:
    BuiltFormPainterEmploymentAllocations.AllocationModeEnum.Feature,
  allocations: [],
  editMode: EmploymentEditMode.Employees,
  employeeTotal: null,
  isZero: false,
};
export function makeGetEmploymentAllocationDetails() {
  return createSelector(
    getPaintState,
    (state): EmploymentDirectPaintingState =>
      state.employment ?? EmptyEmployment,
  );
}

export function makeGetResidentialAllocationPaintInfo() {
  return createSelector(
    makeGetResidentialAllocationDetails(),
    (allocationDetails): BuiltFormPainterResidentialAllocation => {
      const { mode, allocations, isZero } = allocationDetails;

      if (!allocations.length && !isZero) {
        return null;
      }
      return {
        allocation_mode: mode,
        bldg_area_attsf: getAreaAllocation(allocations, 'bldg_area_attsf'),
        bldg_area_detsf_ll: getAreaAllocation(
          allocations,
          'bldg_area_detsf_ll',
        ),
        bldg_area_detsf_sl: getAreaAllocation(
          allocations,
          'bldg_area_detsf_sl',
        ),
        bldg_area_mf: getAreaAllocation(allocations, 'bldg_area_mf'),
        du_attsf: getUnitAllocation(allocations, 'du_attsf'),
        du_detsf_ll: getUnitAllocation(allocations, 'du_detsf_ll'),
        du_detsf_sl: getUnitAllocation(allocations, 'du_detsf_sl'),
        du_mf: getUnitAllocation(allocations, 'du_mf'),
      };
    },
  );
}

export function makeGetEmploymentAllocationPaintInfo() {
  return createSelector(
    makeGetEmploymentAllocationDetails(),
    makeGetLibraryState(),
    (
      allocationDetails,
      libraryState,
    ): BuiltFormPainterEmploymentAllocations => {
      const { allocationMode, allocations, employeeTotal, isZero } =
        allocationDetails;
      if (!allocations.length && !isZero) {
        return null;
      }

      if (!Number.isFinite(employeeTotal) || !isLoaded(libraryState)) {
        return null;
      }
      const library = getData(libraryState);
      const industrialAllocation = getEmploymentAllocations(
        allocations,
        'emp_ind',
        library,
      );
      const agricultureAllocation = getEmploymentAllocations(
        allocations,
        'emp_ag',
        library,
      );
      const officeAllocation = getEmploymentAllocations(
        allocations,
        'emp_off',
        library,
      );
      const publicAllocation = getEmploymentAllocations(
        allocations,
        'emp_pub',
        library,
      );
      const retailAllocation = getEmploymentAllocations(
        allocations,
        'emp_ret',
        library,
      );
      return {
        allocation_mode: allocationMode,
        agriculture_extraction_allocation: agricultureAllocation,
        industrial_allocation: industrialAllocation,
        office_allocation: officeAllocation,
        public_allocation: publicAllocation,
        retail_allocation: retailAllocation,
      };
    },
  );
}

function getEmploymentAllocations(
  allocations: EmploymentAllocation[],
  empKey: CanvasColumnKey,
  library: Library,
): BuiltFormPainterEmploymentAllocation[] {
  return allocations
    .filter(({ employmentType }) => employmentType.empKey === empKey)
    .map(
      (allocation): BuiltFormPainterEmploymentAllocation => ({
        area: allocation.buildingArea,
        density: allocation.density,
        emp: allocation.employees,
        subcategories: getSubsectorAllocations(
          allocation,
          // this can be either mode since both 'employees' and 'buildingArea' should be up to date.
          EmploymentEditMode.BuildingFloorArea,
          library,
        ).map(a => ({
          subcategory_key: a.id,
          subcategory_weight: a.percentEmployment,
        })),
      }),
    );
}

/**
 * Get the total area dedicated to the given type. If there are no allocations,
 * this returns null. This takes into account the # of units allocated in each
 * allocation.
 */
function getAreaAllocation(
  allocations: ResidentialAllocation[],
  key: PaintAllocationKey,
): number {
  const matchingAllocations = allocations.filter(
    ({ residentialType }) => residentialType.areaControlKey === key,
  );
  if (!matchingAllocations?.length) {
    return null;
  }
  return _.sum(
    matchingAllocations.map(
      allocation => allocation.areaPerUnit * allocation.units,
    ),
  );
}

/**
 * Get # of units dedicated to the given type. If there are no allocations, this
 * returns null.
 */
function getUnitAllocation(
  allocations: ResidentialAllocation[],
  key: PaintAllocationKey,
): number {
  const matchingAllocations = allocations.filter(
    ({ residentialType }) => residentialType.paintUnitControlKey === key,
  );
  if (!matchingAllocations?.length) {
    return null;
  }
  return _.sum(matchingAllocations.map(allocation => allocation.units));
}

function makeGetScenarioPaintKey() {
  return createSelector(
    makeGetFromPropsSelector<ProjectId, 'projectId'>('projectId'),
    makeGetFromPropsSelector<ScenarioId, 'scenarioId'>('scenarioId'),
    (projectId, scenarioId) => {
      if (!scenarioId || !projectId) {
        return null;
      }
      const { key: scenarioKey } = parseFullPath(scenarioId);
      return makePaintScenarioKey(projectId, scenarioKey);
    },
  );
}

function makeGetScenarioPaintTaskState() {
  return createSelector(getLegacyExploreState, state => state?.paintTasks);
}

export function makeGetScenarioPaintState() {
  return makeTaskDataStateSelector(
    makeGetScenarioPaintTaskState(),
    makeGetScenarioPaintKey(),
  );
}

function makeGetBaseScenarioPaintTaskState() {
  return createSelector(getLegacyExploreState, state => state?.paintBaseTasks);
}
export function makeGetBaseScenarioPaintState() {
  return makeTaskDataStateSelector(
    makeGetBaseScenarioPaintTaskState(),
    makeGetFromPropsSelector<ProjectId, 'projectId'>('projectId'),
  );
}
