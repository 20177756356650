import { useMemo } from 'react';
import {
  GeometryType,
  useGetLayerQuery,
  useGetOrganizationInsightsQuery,
} from 'uf-api-rtk/store/Api';

const useAvailableInsights = (
  layerKey: string,
  layerNamespace: string,
  layerType: string,
  layerGeometry: GeometryType,
) => {
  const { data: layerMetadata } = useGetLayerQuery({
    key: layerKey,
    namespace: layerNamespace,
    layerType,
  });

  const {
    currentData: insightTypeCategories,
    isFetching,
    isLoading,
    isError,
    isSuccess,
    error,
  } = useGetOrganizationInsightsQuery(
    { key: layerNamespace },
    { skip: !layerNamespace },
  );

  const insightTypes = insightTypeCategories?.[0]?.insights;

  // Create an array of available insights based on the layer's columns
  const allowedInsightKeys = useMemo(() => {
    const allowedKeys = new Set<number>();
    const layerColumns = new Set(layerMetadata?.columns?.map(({ key }) => key));
    if (layerGeometry) {
      insightTypeCategories?.forEach(category => {
        category.insights.forEach(insight => {
          if (
            insight.input_geo_types?.includes(
              layerGeometry.simple_geometry_type,
            ) &&
            !insight.required_columns?.some(
              column => !layerColumns.has(column.key),
            )
          ) {
            allowedKeys.add(insight.key);
          }
        });
      });
    }
    return allowedKeys;
  }, [layerMetadata, insightTypeCategories, layerGeometry]);
  return {
    allowedInsightKeys,
    insightTypes,
    insightTypeCategories,
    isFetching,
    isLoading,
    isError,
    isSuccess,
    error,
  };
};

export default useAvailableInsights;
