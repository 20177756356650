import {
  CuratedColumnSymbology,
  DistributionTypes,
  InterpolationTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const FSILayers = [
  {
    layerId: '/calthorpeanalytics/databank/fsi_alabama_v2',
    columnKey: 'hh_food_insecure_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fsi_alabama_v2',
    columnKey: 'hh_food_insufficient_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fsi_alaska_v2',
    columnKey: 'hh_food_insecure_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fsi_alaska_v2',
    columnKey: 'hh_food_insufficient_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fsi_arizona_v2',
    columnKey: 'hh_food_insecure_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fsi_arizona_v2',
    columnKey: 'hh_food_insufficient_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fsi_arkansas_v2',
    columnKey: 'hh_food_insecure_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fsi_arkansas_v2',
    columnKey: 'hh_food_insufficient_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fsi_california_v2',
    columnKey: 'hh_food_insecure_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fsi_california_v2',
    columnKey: 'hh_food_insufficient_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fsi_colorado_v2',
    columnKey: 'hh_food_insecure_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fsi_colorado_v2',
    columnKey: 'hh_food_insufficient_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fsi_connecticut_v2',
    columnKey: 'hh_food_insecure_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fsi_connecticut_v2',
    columnKey: 'hh_food_insufficient_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fsi_delaware_v2',
    columnKey: 'hh_food_insecure_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fsi_delaware_v2',
    columnKey: 'hh_food_insufficient_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fsi_florida_v2',
    columnKey: 'hh_food_insecure_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fsi_florida_v2',
    columnKey: 'hh_food_insufficient_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fsi_georgia_v2',
    columnKey: 'hh_food_insecure_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fsi_georgia_v2',
    columnKey: 'hh_food_insufficient_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fsi_hawaii_v2',
    columnKey: 'hh_food_insecure_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fsi_hawaii_v2',
    columnKey: 'hh_food_insufficient_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fsi_idaho_v2',
    columnKey: 'hh_food_insecure_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fsi_idaho_v2',
    columnKey: 'hh_food_insufficient_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fsi_illinois_v2',
    columnKey: 'hh_food_insecure_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fsi_illinois_v2',
    columnKey: 'hh_food_insufficient_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fsi_indiana_v2',
    columnKey: 'hh_food_insecure_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fsi_indiana_v2',
    columnKey: 'hh_food_insufficient_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fsi_iowa_v2',
    columnKey: 'hh_food_insecure_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fsi_iowa_v2',
    columnKey: 'hh_food_insufficient_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fsi_kansas_v2',
    columnKey: 'hh_food_insecure_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fsi_kansas_v2',
    columnKey: 'hh_food_insufficient_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fsi_kentucky_v2',
    columnKey: 'hh_food_insecure_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fsi_kentucky_v2',
    columnKey: 'hh_food_insufficient_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fsi_louisiana_v2',
    columnKey: 'hh_food_insecure_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fsi_louisiana_v2',
    columnKey: 'hh_food_insufficient_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fsi_maine_v2',
    columnKey: 'hh_food_insecure_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fsi_maine_v2',
    columnKey: 'hh_food_insufficient_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fsi_maryland_v2',
    columnKey: 'hh_food_insecure_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fsi_maryland_v2',
    columnKey: 'hh_food_insufficient_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fsi_massachusetts_v2',
    columnKey: 'hh_food_insecure_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fsi_massachusetts_v2',
    columnKey: 'hh_food_insufficient_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fsi_michigan_v2',
    columnKey: 'hh_food_insecure_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fsi_michigan_v2',
    columnKey: 'hh_food_insufficient_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fsi_minnesota_v2',
    columnKey: 'hh_food_insecure_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fsi_minnesota_v2',
    columnKey: 'hh_food_insufficient_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fsi_mississippi_v2',
    columnKey: 'hh_food_insecure_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fsi_mississippi_v2',
    columnKey: 'hh_food_insufficient_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fsi_missouri_v2',
    columnKey: 'hh_food_insecure_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fsi_missouri_v2',
    columnKey: 'hh_food_insufficient_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fsi_montana_v2',
    columnKey: 'hh_food_insecure_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fsi_montana_v2',
    columnKey: 'hh_food_insufficient_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fsi_nebraska_v2',
    columnKey: 'hh_food_insecure_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fsi_nebraska_v2',
    columnKey: 'hh_food_insufficient_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fsi_nevada_v2',
    columnKey: 'hh_food_insecure_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fsi_nevada_v2',
    columnKey: 'hh_food_insufficient_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fsi_new_hampshire_v2',
    columnKey: 'hh_food_insecure_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fsi_new_hampshire_v2',
    columnKey: 'hh_food_insufficient_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fsi_new_jersey_v2',
    columnKey: 'hh_food_insecure_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fsi_new_jersey_v2',
    columnKey: 'hh_food_insufficient_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fsi_new_mexico_v2',
    columnKey: 'hh_food_insecure_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fsi_new_mexico_v2',
    columnKey: 'hh_food_insufficient_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fsi_new_york_v2',
    columnKey: 'hh_food_insecure_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fsi_new_york_v2',
    columnKey: 'hh_food_insufficient_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fsi_north_carolina_v2',
    columnKey: 'hh_food_insecure_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fsi_north_carolina_v2',
    columnKey: 'hh_food_insufficient_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fsi_north_dakota_v2',
    columnKey: 'hh_food_insecure_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fsi_north_dakota_v2',
    columnKey: 'hh_food_insufficient_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fsi_ohio_v2',
    columnKey: 'hh_food_insecure_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fsi_ohio_v2',
    columnKey: 'hh_food_insufficient_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fsi_oklahoma_v2',
    columnKey: 'hh_food_insecure_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fsi_oklahoma_v2',
    columnKey: 'hh_food_insufficient_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fsi_oregon_v2',
    columnKey: 'hh_food_insecure_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fsi_oregon_v2',
    columnKey: 'hh_food_insufficient_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fsi_pennsylvania_v2',
    columnKey: 'hh_food_insecure_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fsi_pennsylvania_v2',
    columnKey: 'hh_food_insufficient_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fsi_rhode_island_v2',
    columnKey: 'hh_food_insecure_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fsi_rhode_island_v2',
    columnKey: 'hh_food_insufficient_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fsi_south_carolina_v2',
    columnKey: 'hh_food_insecure_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fsi_south_carolina_v2',
    columnKey: 'hh_food_insufficient_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fsi_south_dakota_v2',
    columnKey: 'hh_food_insecure_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fsi_south_dakota_v2',
    columnKey: 'hh_food_insufficient_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fsi_tennessee_v2',
    columnKey: 'hh_food_insecure_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fsi_tennessee_v2',
    columnKey: 'hh_food_insufficient_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fsi_texas_v2',
    columnKey: 'hh_food_insecure_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fsi_texas_v2',
    columnKey: 'hh_food_insufficient_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fsi_utah_v2',
    columnKey: 'hh_food_insecure_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fsi_utah_v2',
    columnKey: 'hh_food_insufficient_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fsi_vermont_v2',
    columnKey: 'hh_food_insecure_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fsi_vermont_v2',
    columnKey: 'hh_food_insufficient_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fsi_virginia_v2',
    columnKey: 'hh_food_insecure_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fsi_virginia_v2',
    columnKey: 'hh_food_insufficient_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fsi_washington_v2',
    columnKey: 'hh_food_insecure_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fsi_washington_v2',
    columnKey: 'hh_food_insufficient_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fsi_west_virginia_v2',
    columnKey: 'hh_food_insecure_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fsi_west_virginia_v2',
    columnKey: 'hh_food_insufficient_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fsi_wisconsin_v2',
    columnKey: 'hh_food_insecure_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fsi_wisconsin_v2',
    columnKey: 'hh_food_insufficient_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fsi_wyoming_v2',
    columnKey: 'hh_food_insecure_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fsi_wyoming_v2',
    columnKey: 'hh_food_insufficient_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fsi_district_of_columbia_v2',
    columnKey: 'hh_food_insecure_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fsi_district_of_columbia_v2',
    columnKey: 'hh_food_insufficient_pct',
  },
  {
    layerId: '/t3e441wl/databank/fsi_oklahoma_v2',
    columnKey: 'hh_food_insecure_pct',
  },
  {
    layerId: '/t3e441wl/databank/fsi_oklahoma_v2',
    columnKey: 'hh_food_insufficient_pct',
  },
  {
    layerId: '/robur9aw/databank/fsi_louisiana_v2',
    columnKey: 'hh_food_insecure_pct',
  },
  {
    layerId: '/robur9aw/databank/fsi_louisiana_v2',
    columnKey: 'hh_food_insufficient_pct',
  },
];

const symbologies: Record<
  string,
  Record<string, CuratedColumnSymbology[]>
> = {};
FSILayers.forEach(({ layerId, columnKey }) => {
  symbologies[layerId] = {
    [columnKey]: [
      {
        display_hints: {
          distribution: DistributionTypes.DEFAULT,
          interpolation: InterpolationTypes.INTERVAL,
          scale: SymbologyScales.NUMERIC,
          showZero: true,
          theme: 'd3:interpolateGnBu',
        },
        paint: {
          'fill-color': {
            property: columnKey,
            stops: [
              {
                color: '#29635D',
                value: 0,
              },
              {
                color: '#4DAD9E',
                value: 6,
              },
              {
                color: '#A4D7C3',
                value: 9,
              },
              {
                color: '#E0E4C9',
                value: 12,
              },
              {
                color: '#EBD5B0',
                value: 15,
              },
              {
                color: '#EAAD7C',
                value: 18,
              },
              {
                color: '#E58F5A',
                value: 21,
              },
              {
                color: '#E16C3B',
                value: 24,
              },
              {
                color: '#DB4325',
                value: 27,
              },
              {
                color: '#B0351D',
                value: 30,
              },
            ],
            type: 'interval',
          },
          'fill-opacity': 0.5,
        },
        type: SymbologyTypes.FILL,
      },
      {
        display_hints: {
          distribution: DistributionTypes.DEFAULT,
          interpolation: InterpolationTypes.INTERVAL,
          scale: SymbologyScales.NUMERIC,
          showZero: true,
          theme: 'custom',
        },
        paint: {
          'line-color': '#333333',
          'line-opacity': 0.5,
          'line-width': 0.5,
        },
        type: SymbologyTypes.LINE,
      },
    ],
  };
});

export default symbologies;
