import { PlainRoute } from 'react-router';

import { setActiveProject } from 'uf/app/actions';
import { makeProjectId } from 'uf/projects/ids';
import ExploreHeader from 'uf/ui/explore/ExploreHeader/ExploreHeader';
import ExploreView from 'uf/ui/explore/ExploreView/ExploreView';

export default function getExploreRoutes(store, waitForIdle): PlainRoute[] {
  return [
    {
      path: ':namespace/project/:projectKey',
      onEnter: makeEnsureActiveProjectId(store, waitForIdle),
      components: {
        header: ExploreHeader,
        body: ExploreView,
      },
    },
  ];
}

function makeEnsureActiveProjectId(store, waitForIdle) {
  return ({ params }, replace, callback) => {
    const { namespace, projectKey } = params;

    const projectId = makeProjectId(namespace, projectKey);
    store.dispatch(
      setActiveProject(projectId, {
        redirectToExplore: false,
        refreshMap: true,
      }),
    );
    if (waitForIdle) {
      waitForIdle().then(() => callback());
    } else {
      callback();
    }
  };
}
