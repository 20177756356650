import { GeoJsonGeometryTypes } from 'geojson';
import _ from 'lodash';

import { ColumnKey } from 'uf/layers';
import { isDefaultGeoColumnKey } from 'uf/layers/geometryKey';
import { isNullCategory } from 'uf/layers/nullStats';
import {
  CUSTOM_THEME,
  DataDrivenColorProperty,
  DataDrivenColorStop,
  DataDrivenStopValue,
  isCircleSymbology,
  isFillSymbology,
  isLineSymbology,
  LayerColumnSymbology,
  PaintProperty,
  SymbologyDisplayHints,
} from 'uf/symbology';
import { getCanonicalSymbology } from 'uf/symbology/helpers';
import {
  getPaintProperty,
  isDataDriven,
  makePaintPropertyKey,
} from 'uf/symbology/spec/paint';

const COLOR_SUFFIX = 'color';

interface StrokeColorOptions {
  color: string;
}
export function updateStrokeColor(
  symbology: LayerColumnSymbology,
  options: StrokeColorOptions,
) {
  const { color } = options;

  // Ignore Fill symbologies because Polygons (aka fills) use a Line symbology for stroke
  if (isFillSymbology(symbology)) {
    return symbology;
  }

  // The name of the paint property that holds the stroke color
  // ie, circle-stroke-color or color
  const stopPropertyKey = isCircleSymbology(symbology)
    ? `stroke-${COLOR_SUFFIX}`
    : COLOR_SUFFIX;
  const strokeColorKey = makePaintPropertyKey(symbology, stopPropertyKey);

  // Updated display hints in the case this is a Line symbology
  const displayHints = {
    ...symbology.display_hints,
    theme: isLineSymbology(symbology)
      ? CUSTOM_THEME
      : symbology.display_hints.theme,
  };

  return updateBasicPaintProperty(
    symbology,
    strokeColorKey,
    color,
    displayHints,
  );
}

/**
 * Updates the stop color for a specific legend stop.
 * This happens when the user opens the swatch editor and changes the color.
 * @param symbologies
 * @param columnKey  important when converting between basic/data-driven symbologies
 * @param geometryType how we know what should and shouldn't be converted
 * @param stopValue the value to update the color for
 * @param color
 */
export function updateStopColor(
  symbologies: LayerColumnSymbology[],
  columnKey: ColumnKey,
  geometryType: GeoJsonGeometryTypes,
  stopValue: DataDrivenStopValue,
  color: string,
): LayerColumnSymbology[] {
  /*
   * Easy case, we cannot have a data-driven None column, so just update these as basic styles
   */
  if (isDefaultGeoColumnKey(columnKey)) {
    const symbology = getCanonicalSymbology(symbologies, geometryType);
    const updatedSymbology = updateBasicPaintProperty(
      symbology,
      makePaintPropertyKey(symbology, COLOR_SUFFIX),
      color,
      makeDisplayHintsWithCustomTheme(symbology),
    );

    return symbologies.map(s =>
      s.id === updatedSymbology.id ? updatedSymbology : s,
    );
  }

  if (geometryType === 'LineString' || geometryType === 'MultiLineString') {
    return updateLineStopColor(symbologies, color, stopValue);
  }

  // Polygon layers can have a basic stroke color applied to all classes
  // via the Stroke color picker. But we need to convert the stroke to be data-driven
  // when the user changes a specific stop color.
  if (geometryType === 'MultiPolygon' || geometryType === 'Polygon') {
    return updatePolygonStopColor(symbologies, color, stopValue);
  }

  // Point layers can have a basic stroke color applied to all classes
  // via the Stroke color picker. But we need to convert the stroke to be data-driven
  // when the user changes a specific stop color.
  if (geometryType === 'MultiPoint' || geometryType === 'Point') {
    return updatePointStopColor(symbologies, color, stopValue);
  }

  return symbologies;
}

function updatePointStopColor(
  symbologies: LayerColumnSymbology[],
  color: string,
  stopValue: DataDrivenStopValue,
): LayerColumnSymbology[] {
  return symbologies.map(circleSymbology => {
    if (!isCircleSymbology(circleSymbology)) {
      return circleSymbology;
    }
    const colorKey = makePaintPropertyKey(circleSymbology, COLOR_SUFFIX);
    const displayHints = {
      ...circleSymbology.display_hints,
      theme: CUSTOM_THEME,
    };

    const updatedSymbology = updateDataDrivenPaintProperty(
      circleSymbology,
      colorKey,
      'color',
      color,
      [stopValue],
      displayHints,
    );
    return updatedSymbology;
  });
}

function updatePolygonStopColor(
  symbologies: LayerColumnSymbology[],
  color: string,
  stopValue: DataDrivenStopValue,
): LayerColumnSymbology[] {
  return symbologies.map(fillSymbology => {
    if (!isFillSymbology(fillSymbology)) {
      return fillSymbology;
    }
    const colorKey = makePaintPropertyKey(fillSymbology, COLOR_SUFFIX);
    const displayHints = {
      ...fillSymbology.display_hints,
      theme: CUSTOM_THEME,
    };

    const updatedFillSymbology = updateDataDrivenPaintProperty(
      fillSymbology,
      colorKey,
      'color',
      color,
      [stopValue],
      displayHints,
    );
    return updatedFillSymbology;
  });
}

function updateLineStopColor(
  symbologies: LayerColumnSymbology[],
  color: string,
  stopValue: DataDrivenStopValue,
): LayerColumnSymbology[];
function updateLineStopColor(
  symbologies: LayerColumnSymbology[],
  color: string,
  stopValue,
): LayerColumnSymbology[] {
  return symbologies.map(lineSymbology => {
    if (!isLineSymbology(lineSymbology)) {
      return lineSymbology;
    }
    const colorKey = makePaintPropertyKey(lineSymbology, COLOR_SUFFIX);
    const displayHints = {
      ...lineSymbology.display_hints,
      theme: CUSTOM_THEME,
    };
    return updateDataDrivenPaintProperty(
      lineSymbology,
      colorKey,
      COLOR_SUFFIX,
      color,
      [stopValue],
      displayHints,
    );
  });
}

function updateBasicPaintProperty(
  symbology: LayerColumnSymbology,
  paintPropertyKey: string,
  value: string,
  displayHints?: SymbologyDisplayHints,
) {
  return {
    ...symbology,
    paint: {
      ...symbology.paint,
      [paintPropertyKey]: value,
    },
    display_hints: displayHints || symbology.display_hints,
  };
}

/**
 * Updates the stops of a symbology for a given paint property.
 *
 * If the gven symbology did not previously contain this paint property,
 * it will clone the symbology's data-driven color property by default.
 *
 * @param symbology
 * @param paintPropertyKey eg: circle-stroke-color or line-color
 * @param stopPropertyKey
 * @param value  the actual color, stroke-color, etc..
 * @param stopValues The stop values to apply the color to, if not provided all stops get set
 * @param displayHints  Maybe you need to update the theme or something?
 */
function updateDataDrivenPaintProperty(
  symbology: LayerColumnSymbology,
  paintPropertyKey: string, // TODO: stronger type
  stopPropertyKey: string, // TODO: stronger type
  value: DataDrivenStopValue,
  stopValues: DataDrivenStopValue[] = [],
  displayHints?: SymbologyDisplayHints,
): LayerColumnSymbology {
  const paintProperty: PaintProperty<DataDrivenColorStop> =
    symbology?.paint?.[paintPropertyKey];
  const stops = paintProperty?.stops;

  // If we don't have stops for the given paint property, then clone the color stops.
  let updatedPaintProperty;
  if (!stops) {
    const paintColor = getPaintProperty<
      DataDrivenColorStop,
      DataDrivenColorProperty
    >(symbology, COLOR_SUFFIX);

    if (!isDataDriven<DataDrivenColorProperty>(paintColor)) {
      return symbology;
    }

    updatedPaintProperty = {
      ...paintColor,
      stops: paintColor.stops.map(stop => {
        if (stopValues.length && !matchesStop(stopValues, stop)) {
          return stop;
        }
        // Remove the "color" property, since that was just a starting point
        const stripped = _.omit(stop, COLOR_SUFFIX);
        return { ...stripped, [stopPropertyKey]: value };
      }),
    };
  } else {
    // Otherwise just do a normal map
    updatedPaintProperty = {
      ...paintProperty,
      stops: stops.map(stop => {
        if (stopValues.length && !matchesStop(stopValues, stop)) {
          return stop;
        }
        return { ...stop, [stopPropertyKey]: value };
      }),
    };
  }

  return {
    ...symbology,
    paint: {
      ...symbology.paint,
      [paintPropertyKey]: updatedPaintProperty,
    },
    display_hints: displayHints || symbology.display_hints,
  };
}

function matchesStop(
  stopValues: DataDrivenStopValue[],
  stop: DataDrivenColorStop,
) {
  if (stopValues.includes(stop.value)) {
    return true;
  }
  if (isNullCategory(stop.value)) {
    return stopValues.some(stopValue => isNullCategory(stopValue));
  }
  return false;
}

function makeDisplayHintsWithCustomTheme(symbology) {
  return {
    ...symbology.display_hints,
    // changing any stop should set the theme to 'custom' if it wasn't already
    theme: CUSTOM_THEME,
  };
}
