import React, { useMemo } from 'react';
import { OrganizationInfo, UserAction } from 'uf-api';
import CanPerform from 'uf/ui/base/CanPerform/CanPerform';
import { useProjectTypes } from 'uf/ui/projects/hooks';

interface OwnProps {
  organization: OrganizationInfo;
  selectedOrg: string | null;
  projectType: string;
  onHavePermission: (
    canPerform: boolean,
    disabledReason?: UserAction.ReasonEnum,
    resource?: string,
  ) => void;
}

const OrganizationOption = ({
  organization,
  selectedOrg,
  projectType,
  onHavePermission,
}: OwnProps) => {
  const { projectTypes } = useProjectTypes(organization.key);
  const isDisabled = useMemo(() => {
    // Grab available project type keys
    const availableProjectTypeKeys = Object.keys(projectTypes);
    // Confirm org has access to currently active project type
    return !availableProjectTypeKeys.includes(projectType);
  }, [projectTypes, projectType]);
  return (
    <CanPerform
      onHavePermission={onHavePermission}
      key={organization.key}
      hideWithNoPermission
      verb="project.create"
      resources={organization.full_path}>
      {() => (
        <option
          data-testid="organization-menu-option"
          value={organization.key}
          selected={organization.key === selectedOrg}
          disabled={isDisabled}>
          {organization.name}
        </option>
      )}
    </CanPerform>
  );
};

export default OrganizationOption;
