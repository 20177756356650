import { MapExportRequest } from 'uf-api';
import { parseFullPath } from 'uf/base/dataset';
import { makeAsyncApiCaller } from 'uf/base/xhr';
import { ProjectId } from 'uf/projects';

const enqueueMapExport = makeAsyncApiCaller(
  apis => apis.mapexport.enqueue_map_export,
);

export function startMapExportAPI(
  projectId: ProjectId,
  request: MapExportRequest,
) {
  const { namespace, key } = parseFullPath(projectId, 'project');
  return enqueueMapExport({
    namespace,
    key,
    request,
  });
}
