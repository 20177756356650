import _ from 'lodash';

export interface StyledBuiltFormLike {
  color?: string;
  style?: string | { fill: { color: string } };
}
/**
 * Given a builtform, extract the fill color. This may come from
 * old-style built forms (which have a `color` attribute) or from
 * new-style builtforms (which have a `style` attribute in the shape
 * { fill: { color: '#abcd' } }
 */
export function getFillColor(
  builtForm: StyledBuiltFormLike,
  defaultColor = '#abc',
): string {
  if (_.isEmpty(builtForm)) {
    return defaultColor;
  }

  if ('color' in builtForm && builtForm.color) {
    return builtForm.color;
  }

  if (builtForm.style) {
    // TODO: Find a way to cache this and/or preparse this
    let style = builtForm.style;

    // when the style comes from the 'baked' layer from the backend, it is a string and needs to
    // be parsed.  remove this once we are no longer using baked layers.
    if (typeof style === 'string') {
      style = JSON.parse(style);
    }

    if (!style) {
      return defaultColor;
    }
    const { fill } = (style as { fill: { color: string } }) || { fill: null };
    const { color = defaultColor } = fill;
    return color;
  }

  return defaultColor;
}
