import slug from 'slugify';

const SLUG_DELIMITER = '_';

const slugOptions = {
  replacement: SLUG_DELIMITER,
  lower: true,
  symbols: false,
};

/**
 * Slug wrapper that uses a UF-safe case and delimiter
 * It is slugged for url use and
 *  pruned so that it meets postgres requirements:
 *  https://www.postgresql.org/docs/current/static/sql-syntax-lexical.html#SQL-SYNTAX-IDENTIFIERS
 */
export default function (str = '') {
  let result = str;
  result = result.replace(/[\W]/g, ''); // get rid of all non-word characters
  result = result.replace(/-/g, ''); // get rid of all dashses
  result = slug(result, slugOptions);
  return replaceLeadingDigitWithWord(result);
}

const digitWordMap: Record<string, string> = {
  0: 'zero',
  1: 'one',
  2: 'two',
  3: 'three',
  4: 'four',
  5: 'five',
  6: 'six',
  7: 'seven',
  8: 'eight',
  9: 'nine',
};

function replaceLeadingDigitWithWord(str: string) {
  const word = digitWordMap[str[0]];
  if (word) {
    return word + str.slice(1);
  }
  return str;
}
