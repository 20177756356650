import { getLayerBoundsActionTypes } from 'uf-api/api/layer.service';
import { dispatchAsyncAction, makeEnsureActionCreator } from 'uf/data/loader';
import { fetchLayerBounds } from 'uf/layers/apis';
import { LayerBoundsParams, makeLayerBoundsKey } from 'uf/layers/bounds';
import { getLayerDataApiParams } from 'uf/layers/filters';
import { makeGetLayerBounds } from 'uf/layers/selectors/bounds';

export const ensureLayerBounds = makeEnsureActionCreator(
  loadLayerBounds,
  makeGetLayerBounds(),
  makeLayerBoundsKey,
);

export function loadLayerBounds(params: LayerBoundsParams) {
  const { layerId, filters, version } = params;
  const { key, apiParams } = getLayerDataApiParams(layerId, {
    filters,
    version,
  });

  return dispatchAsyncAction(
    getLayerBoundsActionTypes,
    fetchLayerBounds(layerId, version, apiParams.filters),
    { key, layerId, filters },
  );
}
