import { LegacyChartSeries, LegacyReport } from 'uf/reporting/index';

const ScaledPopSeries: LegacyChartSeries = {
  key: 'scaled_pop',
  source: 'analysis',
  sourceKey: 'example',
  layerKey: 'analysis_example_sum',
  columnKey: 'total_scaled_pop',
};

const TotalDevSeries: LegacyChartSeries = {
  key: 'total_dev',
  source: 'analysis',
  sourceKey: 'example',
  layerKey: 'analysis_example_sum',
  columnKey: 'total_developable',
};

export const exampleCharts: LegacyReport = Object.freeze({
  key: 'example',
  name: 'Dummy Charts',
  charts: [
    {
      name: 'Sum of pop and developable',
      key: 'total',
      series: [ScaledPopSeries, TotalDevSeries],
    },
  ],
});
