import { Action as RFRAction } from 'redux-first-router';

import { ROUTE_PROFILE } from 'uf/user/ActionTypes';

export function setProfileRoute(): RFRAction {
  return {
    type: ROUTE_PROFILE,
    payload: {},
  };
}
