import { ActionsObservable, ofType } from 'redux-observable';
import { map } from 'rxjs/operators';
import { t } from 'ttag';

import { FRONTEND_VERSION_MISMATCH } from 'uf/app/ActionTypes';
import { NEW_VERSION_MESSAGE_ID } from 'uf/app/epics/version';
import { addAppMessage } from 'uf/appservices/actions';
import { Message } from 'uf/appservices/messages';
import { BuildInfo } from 'uf/base/buildinfo';
import { SuccessAction } from 'uf/data/ActionTypes';

export function alertUserOnVersionMismatch(
  action$: ActionsObservable<SuccessAction<BuildInfo>>,
) {
  return action$.pipe(
    ofType(FRONTEND_VERSION_MISMATCH),
    map(() => {
      const message: Message = {
        replacesMessage: NEW_VERSION_MESSAGE_ID,
        id: NEW_VERSION_MESSAGE_ID,
        // Message is not dismissable.
        timeout: 0,
        action: {
          text: t`RELOAD`,
          onClick: () => {
            window.location.reload(true);
          },
        },
      };
      return addAppMessage(
        t`UrbanFootprint Analyst has been updated. Reload your browser to get the new version.`,
        message,
      );
    }),
  );
}
