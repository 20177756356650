/**
 * UrbanFootprint App API
 * API for serving UrbanFootprint Application
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Membership.
 */
export interface ResourcePolicyMembership<> {
  /**
   * User key of member.
   */
  member?: string;
  /**
   * Role of the member in resource.
   */
  role?: ResourcePolicyMembership.RoleEnum;
}
export namespace ResourcePolicyMembership {
  export type RoleEnum =
    | 'collaborator'
    | 'viewer'
    | 'editor'
    | 'admin'
    | 'owner'
    | 'public';
  export const RoleEnum = {
    Collaborator: 'collaborator',
    Viewer: 'viewer',
    Editor: 'editor',
    Admin: 'admin',
    Owner: 'owner',
    Public: 'public',
  } as const;
}
