import { createSelector } from 'reselect';

import { EMPTY_OBJECT } from 'uf/base';

import { InternalState } from './state';

function getInternalState(state): InternalState {
  return state.internal || EMPTY_OBJECT;
}

export const getActiveInternalPageKey = createSelector(
  getInternalState,
  internalState => internalState.pageKey,
);

const getPageParams = createSelector(
  getInternalState,
  internalState => internalState.urlParams ?? EMPTY_OBJECT,
);

export function makeGetInternalPageParams<
  T extends object = Record<string, any>,
>(pageKey: string) {
  return createSelector(
    getPageParams,
    (urlParams): T => (urlParams?.[pageKey] ?? EMPTY_OBJECT) as T,
  );
}

export interface InternalPageState {
  pageKey: string;
  params: { [key: string]: any };
}

export const getInternalPageState = createSelector(
  getPageParams,
  getActiveInternalPageKey,
  (params, pageKey): InternalPageState => ({
    pageKey,
    params: params[pageKey] || EMPTY_OBJECT,
  }),
);
