import _ from 'lodash';

import { getLayerStatsActionTypes } from 'uf-api/api/layer.service';
import { getCanonicalParameters } from 'uf/base/api';
import { dispatchAsyncAction, makeEnsureActionCreator } from 'uf/data/loader';
import { LayerId } from 'uf/layers';
import { fetchLayerStats } from 'uf/layers/apis';
import { getStatsApiParams, getStatsSearchKey } from 'uf/layers/filters';
import { makeGetLayerStatsByKey } from 'uf/layers/selectors/stats';
import { LayerStatsParams } from 'uf/layers/stats';
import warning from 'warning';

/**
 * Maximum number of columns that we can request stats for.
 */
const MAX_STATS_COLUMNS = 100;
/**
 * Action that only loads the layer if it hasn't already been loaded,
 * but returns a promise that resolves to the final stats.
 */
export const ensureLayerStats = makeEnsureActionCreator(
  loadLayerStats,
  makeGetLayerStatsByKey(),
  getStatsSearchKey,
);

/**
 * Unconditionally load the layer stats for the given layerId and query. This
 * is used to force the refresh of stats, in case data has changed on the server.
 * Most of the time, use `ensureLayerStats` instead.
 */
const EMPTY_PARAMS: LayerStatsParams = {} as LayerStatsParams;

export function loadLayerStats(
  layerId: LayerId,
  params: LayerStatsParams = EMPTY_PARAMS,
) {
  const { columns } = params;
  const { apiParams, key } = getStatsApiParams(layerId, params);
  warning(
    !_.isEmpty(columns) || params.row_count,
    `Missing columns while loading stats for ${layerId}`,
  );

  // TODO: Remove this big hack..somehow. Requesting too many columns means we
  // exceed url limits. So we are restricted to `MAX_STATS_COLUMNS` but only for
  // the actual request. To keep everything sane, the actual key needs to be
  // based on the original list of columns
  let finalColumns = columns;
  if (columns && columns.length > MAX_STATS_COLUMNS) {
    finalColumns = columns.slice(0, MAX_STATS_COLUMNS);
  }
  // Sort for consistent caching
  finalColumns = _.sortBy(finalColumns);

  const searchParamsRestrictedColumns = getCanonicalParameters({
    ...apiParams,
    columns: finalColumns,
  });

  return dispatchAsyncAction(
    getLayerStatsActionTypes,
    fetchLayerStats(layerId, searchParamsRestrictedColumns),
    {
      key,
      params: apiParams,
      // pass another level of `extra` so it actually gets stored in redux
      // when a keyedPromiseReducer sees it
      // TODO: Stop spreading extra in dispatchAsyncAction
      extra: {
        actionParams: { layerId, params },
      },
    },
  );
}
