import _ from 'lodash';
import { createSelector } from 'reselect';

import { LayerColumnStats, LayerReference, LayerStats } from 'uf-api';
import { EMPTY_ARRAY } from 'uf/base';
import { BuiltFormTypes } from 'uf/base/builtForms';
import { makeGetFromPropsSelector } from 'uf/base/selector';
import { BUILT_FORM_KEY, BUILT_FORM_TYPES, BuiltFormInfo } from 'uf/builtforms';
import { makeGetBuiltFormsListStateGetter } from 'uf/builtforms/selectors';
import { combineDataStates, DataState, getData } from 'uf/data/dataState';
import { makeReduxDataKey } from 'uf/data/helpers';
import { LayerId } from 'uf/layers';
import { LayerDataParams } from 'uf/layers/filters';
import { findColumnKeyStats } from 'uf/layers/stats';
import { ProjectId } from 'uf/projects';
import {
  makeGetProjectBuiltFormsLibraryId,
  makeGetProjectBuiltFormsLibraryIdGetter,
} from 'uf/projects/selectors';
import {
  makeGetProjectBuiltFormsLayerReference,
  makeGetProjectBuiltFormsLayerReferenceGetter,
  makeGetProjectBuiltFormsQuery,
  makeGetProjectBuiltFormsQueryGetter,
} from 'uf/projects/selectors/layers';
import { getSearchState, resolveSearchKey } from 'uf/search/selectors';
import { SearchState } from 'uf/search/state';
import {
  BuiltFormStop,
  makeBuiltFormStops,
  makeStopsFromBuiltForms,
} from 'uf/symbology/builtforms';
import { makeGetSymbologyStatsStateGetter } from 'uf/symbology/selectors/stats';

export function makeGetBuiltFormsStopsLoadingState() {
  return createSelector(
    makeGetProjectBuiltFormsLayerReference(),
    makeGetProjectBuiltFormsLibraryId(),
    makeGetBuiltFormsListStateGetter(),
    getSearchState,
    makeGetProjectBuiltFormsQuery(),
    makeGetSymbologyStatsStateGetter(),
    makeGetFromPropsSelector<ProjectId, 'projectId'>('projectId'),
    makeGetFromPropsSelector<LayerId, 'layerId'>('layerId'),
    (
      builtFormsLayerReference,
      builtFormsLibraryId,
      getBuiltFormListState,
      searchState,
      builtFormsQuery,
      getStatsState,
      projectId,
      layerId,
    ) => {
      const buildingTypesList = getBuiltFormListState(
        builtFormsLibraryId,
        BUILT_FORM_TYPES.BUILDING_TYPE,
      );
      const placeTypesList = getBuiltFormListState(
        builtFormsLibraryId,
        BUILT_FORM_TYPES.PLACE_TYPE,
      );
      const statsState = getStatsState(projectId, layerId, BUILT_FORM_KEY);
      return getBuiltFormsStopsLoadingState(
        builtFormsLayerReference,
        builtFormsLibraryId,
        buildingTypesList,
        placeTypesList,
        statsState,
        searchState,
        builtFormsQuery,
      );
    },
  );
}

export function makeGetBuiltFormsStopsLoadingStateGetter() {
  return createSelector(
    makeGetProjectBuiltFormsLayerReferenceGetter(),
    makeGetProjectBuiltFormsLibraryIdGetter(),
    makeGetBuiltFormsListStateGetter(),
    getSearchState,
    makeGetProjectBuiltFormsQueryGetter(),
    makeGetSymbologyStatsStateGetter(),
    (
        getBuiltFormsLayerReference,
        getBuiltFormsLibraryId,
        getBuiltFormListState,
        searchState,
        getBuiltFormsQuery,
        getStatsState,
      ) =>
      (projectId: ProjectId, layerId: LayerId) => {
        const builtFormsLayerReference = getBuiltFormsLayerReference(projectId);
        const builtFormsLibraryId = getBuiltFormsLibraryId(projectId);
        const builtFormsQuery = getBuiltFormsQuery(projectId);
        const buildingTypesList = getBuiltFormListState(
          builtFormsLibraryId,
          BUILT_FORM_TYPES.BUILDING_TYPE,
        );
        const placeTypesList = getBuiltFormListState(
          builtFormsLibraryId,
          BUILT_FORM_TYPES.PLACE_TYPE,
        );
        const statsState = getStatsState(projectId, layerId, BUILT_FORM_KEY);
        return getBuiltFormsStopsLoadingState(
          builtFormsLayerReference,
          builtFormsLibraryId,
          buildingTypesList,
          placeTypesList,
          statsState,
          searchState,
          builtFormsQuery,
        );
      },
  );
}

function getBuiltFormsStopsLoadingState(
  builtFormsLayerReference: LayerReference,
  builtFormsLibraryId: string,
  buildingTypesList: DataState<BuiltFormInfo[]>,
  placeTypesList: DataState<BuiltFormInfo[]>,
  statsState: DataState<LayerStats>,
  searchState: SearchState,
  builtFormsQuery: LayerDataParams,
) {
  const loadingStates: DataState<any>[] = [];
  if (statsState) {
    // Don't add undefined to the list, because it will cause "isLoaded()" to never be true
    loadingStates.push(statsState);
  }
  if (builtFormsLibraryId) {
    // Don't add undefined to the lists, because it will cause "isLoaded()" to
    // never be true
    if (buildingTypesList) {
      loadingStates.push(buildingTypesList);
    }
    if (placeTypesList) {
      loadingStates.push(placeTypesList);
    }
  }
  if (builtFormsLayerReference) {
    const searchKey = makeReduxDataKey(
      builtFormsLayerReference.full_path,
      builtFormsQuery,
    );

    // Don't add undefined to the list, because it will cause "isLoaded()" to never be true
    if (searchState[searchKey]) {
      loadingStates.push(searchState[searchKey]);
    }
  }

  return combineDataStates(loadingStates);
}

export function makeGetBuiltFormsStops() {
  return createSelector(
    makeGetProjectBuiltFormsLayerReference(),
    makeGetProjectBuiltFormsLibraryId(),
    makeGetBuiltFormsListStateGetter(),
    getSearchState,
    makeGetProjectBuiltFormsQuery(),
    makeGetSymbologyStatsStateGetter(),
    makeGetFromPropsSelector<ProjectId, 'projectId'>('projectId'),
    makeGetFromPropsSelector<LayerId, 'layerId'>('layerId'),
    (
      builtFormsLayerReference,
      builtFormsLibraryId,
      getBuiltFormsListState,
      searchState,
      builtFormsQuery,
      getStatsState,
      projectId,
      layerId,
    ): BuiltFormStop[] => {
      const buildingTypes = getBuiltFormsListState(
        builtFormsLibraryId,
        BUILT_FORM_TYPES.BUILDING_TYPE,
      );
      const placeTypes = getBuiltFormsListState(
        builtFormsLibraryId,
        BUILT_FORM_TYPES.PLACE_TYPE,
      );
      const statsState = getStatsState(projectId, layerId, BUILT_FORM_KEY);
      return getBuiltFormsStops(
        builtFormsLayerReference,
        builtFormsLibraryId,
        buildingTypes,
        placeTypes,
        statsState,
        searchState,
        builtFormsQuery,
      );
    },
  );
}

export function makeGetBuiltFormsStopsGetter() {
  return createSelector(
    makeGetProjectBuiltFormsLayerReferenceGetter(),
    makeGetProjectBuiltFormsLibraryIdGetter(),
    makeGetBuiltFormsListStateGetter(),
    getSearchState,
    makeGetProjectBuiltFormsQueryGetter(),
    makeGetSymbologyStatsStateGetter(),
    (
        getBuiltFormsLayerReference,
        getBuiltFormsLibraryId,
        getBuiltFormsListState,
        searchState,
        getBuiltFormsQuery,
        getStatsState,
      ) =>
      (projectId: ProjectId, layerId: LayerId): BuiltFormStop[] => {
        const builtFormsLayerReference = getBuiltFormsLayerReference(projectId);
        const builtFormsLibraryId = getBuiltFormsLibraryId(projectId);
        const builtFormsQuery = getBuiltFormsQuery(projectId);
        const buildingTypes = getBuiltFormsListState(
          builtFormsLibraryId,
          BUILT_FORM_TYPES.BUILDING_TYPE,
        );
        const placeTypes = getBuiltFormsListState(
          builtFormsLibraryId,
          BUILT_FORM_TYPES.PLACE_TYPE,
        );
        const statsState = getStatsState(projectId, layerId, BUILT_FORM_KEY);
        return getBuiltFormsStops(
          builtFormsLayerReference,
          builtFormsLibraryId,
          buildingTypes,
          placeTypes,
          statsState,
          searchState,
          builtFormsQuery,
        );
      },
  );
}

function getBuiltFormsStops(
  builtFormsLayerReference: LayerReference,
  builtFormsLibraryId: string,
  buildingTypesListState: DataState<BuiltFormInfo[]>,
  placeTypesListState: DataState<BuiltFormInfo[]>,
  statsState: DataState<LayerStats>,
  searchState: SearchState,
  builtFormsQuery: LayerDataParams,
): BuiltFormStop[] {
  const stats = findColumnKeyStats(getData(statsState), BUILT_FORM_KEY);

  if (builtFormsLibraryId) {
    const buildingTypes = getData(buildingTypesListState, EMPTY_ARRAY);
    const placeTypes = getData(placeTypesListState, EMPTY_ARRAY);
    return makeStopsFromBuiltForms(buildingTypes, placeTypes, stats);
  }

  if (builtFormsLayerReference) {
    return getStopsFromBuiltFormsLayer(
      builtFormsLayerReference,
      searchState,
      builtFormsQuery,
      stats,
    );
  }
  return EMPTY_ARRAY;
}

function getStopsFromBuiltFormsLayer(
  builtFormsLayerReference: LayerReference,
  searchState: SearchState,
  query: LayerDataParams,
  builtFormKeyColumnStats: LayerColumnStats,
) {
  const searchKey = makeReduxDataKey(builtFormsLayerReference.full_path, query);
  const data = resolveSearchKey(searchState, searchKey);

  const buildingTypes = data.filter(
    row => row.built_form_type === BuiltFormTypes.BuildingType,
  );
  const sortedBuildingTypes = _.sortBy(buildingTypes, buildingType =>
    buildingType.name.toLowerCase(),
  );

  const placeTypes = data.filter(
    row => row.built_form_type === BuiltFormTypes.UrbanPlacetype,
  );
  const sortedPlaceTypes = _.sortBy(placeTypes, placeType =>
    placeType.name.toLowerCase(),
  );

  const usedBuiltForms = _(builtFormKeyColumnStats?.categorical?.values)
    .keyBy('value')
    .mapValues(() => true)
    .value();

  return makeBuiltFormStops(
    [...sortedPlaceTypes, ...sortedBuildingTypes],
    usedBuiltForms,
  );
}
