import {
  CuratedColumnSymbology,
  DistributionTypes,
  InterpolationTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/public/dataset/cdc_usaleep_life_expectancy_estimates_usa_v2': {
    abridged_life_table_flag: [
      {
        display_hints: {
          distribution: DistributionTypes.QUANTILES,
          interpolation: InterpolationTypes.INTERVAL,
          is_percent: false,
          scale: SymbologyScales.NUMERIC,
          showZero: false,
          theme: 'custom',
        },
        paint: {
          'fill-color': {
            default: 'transparent',
            property: 'abridged_life_table_flag',
            stops: [
              {
                color: '#68ccca',
                value: 1,
              },
              {
                color: '#e27300',
                value: 2,
              },
              {
                color: '#b0bc00',
                value: 3,
              },
            ],
            type: 'interval',
          },
          'fill-opacity': 0.6,
        },
        type: SymbologyTypes.FILL,
      },
      {
        display_hints: {
          distribution: DistributionTypes.QUANTILES,
          interpolation: InterpolationTypes.INTERVAL,
          is_percent: false,
          scale: SymbologyScales.NUMERIC,
          showZero: false,
          theme: 'material:blue:range',
        },
        paint: {
          'line-color': 'transparent',
          'line-opacity': 0.6,
          'line-width': 1,
        },
        type: SymbologyTypes.LINE,
      },
    ],
    life_expectancy: [
      {
        display_hints: {
          distribution: DistributionTypes.CUSTOM,
          interpolation: InterpolationTypes.INTERVAL,
          is_percent: false,
          scale: SymbologyScales.NUMERIC,
          showZero: false,
          theme: 'uf:#DB4325,#E57540,#ECC997,#EBEED9,#A3D8C3,#5DC3B0,#086265',
        },
        paint: {
          'fill-color': {
            default: 'transparent',
            property: 'life_expectancy',
            stops: [
              {
                color: 'rgb(219, 67, 37)',
                value: 0,
              },
              {
                color: 'rgb(226, 107, 61)',
                value: 65,
              },
              {
                color: 'rgb(232, 159, 108)',
                value: 70,
              },
              {
                color: 'rgb(235, 207, 166)',
                value: 75,
              },
              {
                color: 'rgb(223, 228, 202)',
                value: 77.5,
              },
              {
                color: 'rgb(181, 221, 200)',
                value: 80,
              },
              {
                color: 'rgb(128, 204, 184)',
                value: 82.5,
              },
              {
                color: 'rgb(71, 165, 153)',
                value: 85,
              },
              {
                color: 'rgb(8, 98, 101)',
                value: 90,
              },
            ],
            type: 'interval',
          },
          'fill-opacity': 0.9,
        },
        type: SymbologyTypes.FILL,
      },
      {
        display_hints: {
          distribution: DistributionTypes.QUANTILES,
          interpolation: InterpolationTypes.INTERVAL,
          is_percent: false,
          scale: SymbologyScales.NUMERIC,
          showZero: false,
          theme: 'material:blue:range',
        },
        paint: {
          'line-color': 'transparent',
          'line-opacity': 0.9,
          'line-width': 1,
        },
        type: SymbologyTypes.LINE,
      },
    ],
    std_err_life_expectancy: [
      {
        display_hints: {
          distribution: DistributionTypes.CUSTOM,
          interpolation: InterpolationTypes.INTERVAL,
          is_percent: false,
          scale: SymbologyScales.NUMERIC,
          showZero: false,
          theme: 'd3:interpolateGnBu',
        },
        paint: {
          'fill-color': {
            default: 'transparent',
            property: 'std_err_life_expectancy',
            stops: [
              {
                color: 'rgb(247, 252, 240)',
                value: 0,
              },
              {
                color: 'rgb(221, 242, 216)',
                value: 0.5,
              },
              {
                color: 'rgb(193, 231, 193)',
                value: 1,
              },
              {
                color: 'rgb(148, 214, 188)',
                value: 1.5,
              },
              {
                color: 'rgb(98, 189, 203)',
                value: 2,
              },
              {
                color: 'rgb(53, 151, 196)',
                value: 2.5,
              },
              {
                color: 'rgb(17, 109, 172)',
                value: 3,
              },
              {
                color: 'rgb(8, 64, 129)',
                value: 3.5,
              },
            ],
            type: 'interval',
          },
          'fill-opacity': 0.6,
        },
        type: SymbologyTypes.FILL,
      },
      {
        display_hints: {
          distribution: DistributionTypes.QUANTILES,
          interpolation: InterpolationTypes.INTERVAL,
          is_percent: false,
          scale: SymbologyScales.NUMERIC,
          showZero: false,
          theme: 'material:blue:range',
        },
        paint: {
          'line-color': 'transparent',
          'line-opacity': 0.6,
          'line-width': 1,
        },
        type: SymbologyTypes.LINE,
      },
    ],
  },
};

export default symbologies;
