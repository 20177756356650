import { ProjectId } from 'uf/projects';
import { LAST_ACTIVE_PROJECT_ID } from 'uf/user';
import { makePrefValue, makeUserPrefKey } from 'uf/user/helpers';

import { loadUserData, setUserData } from './data';

export function setLastActiveProject(projectId: ProjectId, userKey: string) {
  const userPrefKey = makeUserPrefKey(LAST_ACTIVE_PROJECT_ID);
  return setUserData(userPrefKey, makePrefValue(projectId), userKey);
}

export function getLastActiveProject(userKey: string) {
  const userPrefKey = makeUserPrefKey(LAST_ACTIVE_PROJECT_ID);
  return loadUserData(userPrefKey, userKey);
}
