import { Action, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { makeUnresolvedPromise, PromiseWrapper } from 'uf/base/promise';
import { risonEncode } from 'uf/base/rison';
import { SwaggerClient } from 'uf/base/xhr';
import {
  FailureAction,
  LoadAction,
  LoadActionTypes,
  SuccessAction,
} from 'uf/data/ActionTypes';
import { makeRequestId } from 'uf/data/helpers';
import { EpicDependecies } from 'uf/base/epics';

import {
  DataState,
  ExtraParams,
  getData,
  hasError,
  isLoading,
  shouldLoad,
} from './dataState';

const MIDDLEWARE_KEY = 'client';

/**
 * A dynamic loader for a datasource. provides:
 *   A tri-state action which loads a url.
 *   A reducer which integrates the results of that url
 *
 * Requires redux-thunk installed as middleware.
 */

export type ClientOperation<T = any, M = SwaggerClient> = (
  client: M,
) => Promise<T>;

interface ExtraStateParams {
  [key: string]: any;
}

/**
 * Dispatch an action that results in a network request, translating the
 * resulting Promise into a set of LOAD/SUCCESS/FAILURE actions.
 *
 * @param actionTypes A standard dictionary of LOAD/SUCCESS/FAILURE action
 *   types.
 * @param asyncOperation A function that returns a thunk action, which itself
 *   returns a promise.
 * @param extra A dictionary, usually containg its own `extra` key.  Add values
 *   to the `extra` value to put them in the network envelope for the lifetime of
 *   the request.
 */
export function dispatchAsyncAction<T, E = ExtraStateParams, M = SwaggerClient>(
  actionTypes: LoadActionTypes,
  asyncOperation: ClientOperation<T, M>,
  extra: ExtraParams<E> = {},
  errorCallback?: (error) => T,
  middlewareKey: keyof EpicDependecies = MIDDLEWARE_KEY,
): ThunkAction<Promise<T>, any, any, any> {
  const asyncThunk: ThunkAction<Promise<T>, any, any, any> = (
    dispatch: Dispatch,
    getState,
    middlewareState,
  ) => asyncOperation(middlewareState[middlewareKey]);
  const requestId = makeRequestId();

  return (dispatch: Dispatch) =>
    dispatchThunk(
      dispatch,
      asyncThunk,
      actionTypes,
      extra,
      errorCallback,
      requestId,
    );
}

/**
 * Dispatch an async action, but return the requestId immediately, so that
 * results can be retrieved out of redux, without waiting for a promise to be
 * returned.
 */
export function dispatchAsyncActionToRequestId<
  T,
  E = ExtraStateParams,
  M = SwaggerClient,
>(
  actionTypes: LoadActionTypes,
  asyncOperation: ClientOperation<T, M>,
  extra: ExtraParams<E> = {},
  errorCallback?: (error) => T,
  middlewareKey: string = 'client',
): ThunkAction<string, any, any, any> {
  const asyncThunk: ThunkAction<Promise<T>, any, any, any> = (
    dispatch: Dispatch,
    getState,
    middlewareState,
  ) => asyncOperation(middlewareState[middlewareKey]);
  const requestId = makeRequestId();

  return (dispatch: Dispatch) => {
    dispatchThunk(
      dispatch,
      asyncThunk,
      actionTypes,
      extra,
      errorCallback,
      requestId,
    );
    return requestId;
  };
}

async function dispatchThunk<T, E>(
  dispatch: Dispatch,
  asyncThunk: ThunkAction<Promise<T>, any, any, any>,
  actionTypes: LoadActionTypes,
  extra: ExtraParams<E> = {},
  errorCallback: (error) => T,
  requestId: string,
): Promise<T> {
  // This promise will resolve at the end of the LOAD/SUCCESS/FAILURE chain,
  // but we need access to it to store it in the LOAD action.
  const requestTime = new Date().toISOString();
  const promiseWrapper = makeUnresolvedPromise<T>();

  // Dispatch this synchronously so that the promise is available in the state tree ASAP.
  try {
    dispatch<LoadAction>({
      type: actionTypes.LOAD,
      promise: promiseWrapper.promise,
      requestTime,
      requestId,
      ...extra,
    });
  } catch (error) {
    promiseWrapper.reject(error);
    return promiseWrapper.promise;
  }

  try {
    const result = await dispatch(asyncThunk);
    return dispatchSuccessAction<T, E>(
      dispatch,
      actionTypes,
      result,
      requestTime,
      requestId,
      extra,
      promiseWrapper,
    );
  } catch (error) {
    let realError = error;
    if (errorCallback) {
      try {
        const result = errorCallback(error);
        return dispatchSuccessAction<T, E>(
          dispatch,
          actionTypes,
          result,
          requestTime,
          requestId,
          extra,
          promiseWrapper,
        );
      } catch (errorCallbackError) {
        realError = errorCallbackError;
      }
    }
    // This "catch" happens when there is a promise-based error from the
    // original XHR/fetch call.
    const responseTime = new Date().toISOString();
    dispatch<FailureAction>({
      type: actionTypes.FAILURE,
      error: realError,
      requestId,
      requestTime,
      responseTime,
      ...extra,
    });
    // This "catch" happens when any of the above calls to
    // `dispatch` synchronously throws an exception,
    // which most often happens in a redux-observable epic.
    if (!promiseWrapper.resolved) {
      promiseWrapper.reject(realError);
    }
    return promiseWrapper.promise;
  }
}

function dispatchSuccessAction<T, E = ExtraStateParams>(
  dispatch: Dispatch,
  actionTypes: LoadActionTypes,
  result: T,
  requestTime: string,
  requestId: string,
  extra: ExtraParams<E>,
  promiseWrapper: PromiseWrapper<T>,
) {
  const responseTime = new Date().toISOString();
  dispatch<SuccessAction<T>>({
    type: actionTypes.SUCCESS,
    result,
    requestId,
    requestTime,
    responseTime,
    ...extra,
  });
  promiseWrapper.resolve(result);
  return promiseWrapper.promise;
}

/**
 * create a fetch action that fetches a url, and dispatches action types.
 *
 */
export function makeAsyncAction<T, S, E, A extends Action>(
  actionTypes: LoadActionTypes,
  defaultAction: ThunkAction<Promise<T>, S, E, A>,
): () => ThunkAction<Promise<T>, S, any, any> {
  // TODO: unify this with dispatchAsyncAction above
  return function fetchUrl() {
    const dispatcher = (dispatch: Dispatch): Promise<T> => {
      // This promise will resolve at the end of the LOAD/SUCCESS/FAILURE chain,
      // but we need access to it to sotre it in the LOAD action.
      const promiseWrapper = makeUnresolvedPromise<T>();

      // Dispatch this synchronously so that the promise is available in the state tree ASAP.
      try {
        dispatch({
          type: actionTypes.LOAD,
          promise: promiseWrapper.promise,
        });
      } catch (error) {
        promiseWrapper.reject(error);
        return promiseWrapper.promise;
      }

      dispatch(defaultAction)
        .then(
          result => {
            dispatch({ type: actionTypes.SUCCESS, result });
            promiseWrapper.resolve(result);
            return result;
          },
          error => {
            dispatch({ type: actionTypes.FAILURE, error });
            promiseWrapper.reject(error);
            throw error;
          },
        )
        .catch(err => {
          if (!promiseWrapper.resolved) {
            // This happens when any of the above calls to
            // `dispatch` synchronously throws an exception,
            // which most often happens in a redux-observable epic.
            promiseWrapper.reject(err);
          }
          // Ignore the error, it just gets passed on to the wrapper
        });

      return promiseWrapper.promise;
    };
    return dispatcher;
  };
}

export function encodeArguments(...args: any[]): string {
  if (args.length === 1) {
    return risonEncode(args[0]);
  }
  return risonEncode(args);
}

/**
 * Create an ensure-style action creator.
 *
 * By default just takes the parameters passed and encodes them into a string.
 *
 * Usage:
 *
 *   export const ensureFoo = makeEnsureActionCreator(loadFoo, getFoo);
 *
 * Typically, `getFoo` is a selector that takes a single prop key:
 *
 *   export function makeGetFoo() {
 *     return createSelector(
 *         getAllFoos,
 *         makeGetFromPropsSelector<string, 'key'>('key'),
 *         (allFoos, key) => allFoos[key]);
 *   }
 *
 * Now this can be used as an action creator:
 *
 *   dispatch(ensureFoo('SomeFooId'));
 *
 * @param loadActionCreator Action creator function that returns a promise. This
 *     is typically a thunk.
 * @param keySelector A selector that takes the current state, as well as parameters
 *     from makeKey, and returns a DataState describing if this request has loaded
 * @param makeOwnProps A function that takes the parameters to the action creator,
 *     and turns them into the `ownProps` for keySelector. Note that if
 *     makeKey returns a string, then `ownProps` will be set to `{ key: <key> }`.
 */
export function makeEnsureActionCreator<T, S, E, O, A extends any[] = any[]>(
  loadActionCreator: (...args: A) => ThunkAction<Promise<T>, S, E, Action>,
  keySelector: (state: S, ownProps: O) => DataState<T>,
  makeOwnProps: OwnPropsMaker<A> = encodeArguments,
): (...args: A) => ThunkAction<Promise<T>, any, any, any> {
  return (...args: A) =>
    (dispatch: Dispatch, getState) => {
      let ownProps = makeOwnProps(...args);
      if (typeof ownProps === 'string') {
        ownProps = { key: ownProps };
      }

      const resultState = keySelector(getState(), ownProps);

      if (shouldLoad(resultState)) {
        try {
          return dispatch(loadActionCreator(...args));
        } catch (ex) {
          return Promise.reject(ex);
        }
      }
      return evaluateResultState(resultState);
    };
}

function evaluateResultState<T = any>(resultState: DataState<T>) {
  if (isLoading(resultState)) {
    return resultState.promise;
  }

  if (hasError(resultState)) {
    return Promise.reject(resultState.error);
  }

  return Promise.resolve(getData(resultState));
}

export type OwnPropsMaker<A extends any[], R = any> = (
  ...args: A
) => R | string;
