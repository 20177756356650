import warning from 'warning';

import { ColumnKey, LayerId } from 'uf/layers';
import { DivideByColumnKey } from 'uf/symbology/divideByColumn';

export function makeSourceId(
  layerId: LayerId,
  version: string,
  columnKey: ColumnKey,
  divideByColumn?: DivideByColumnKey,
) {
  const parts = [layerId, version, columnKey];
  warning(!!columnKey, `missing column key for ${layerId}|${version}`);
  if (divideByColumn) {
    parts.push(divideByColumn);
  }
  return parts.join('|');
}

export function parseTileSourceId(tileSourceId: string) {
  const [layerId, version, columnKey, divideByColumn] = tileSourceId.split('|');
  return {
    layerId,
    version,
    columnKey,
    divideByColumn,
  };
}
