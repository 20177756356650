export interface PermissionAction {
  verb: string;
  resource: string;
}

/**
 *  Builds a query array to be used by loadCheckFeatures. For each resource
 *  provided, each verb will be checked.
 */
export function buildCheckFeaturesQuery(
  verbs: string[],
  resources: string[],
): PermissionAction[] {
  const query: PermissionAction[] = [];
  resources.forEach(resource => {
    verbs.forEach(verb => {
      query.push({ verb, resource });
    });
  });

  return query;
}

export interface Resource<T> {
  [verb: string]: T;
}
