import {
  AnchorButton,
  Position,
  Tooltip,
  MaybeElement,
} from '@blueprintjs/core';
import Icon from '@mdi/react';
import classNames from 'classnames';
import React, { useCallback } from 'react';

import buttonStyles from 'uf/styles/buttons.module.css';
import iconStyles from 'uf/styles/icons.module.css';
import { IconSizeNew } from 'uf/ui/base/icon';
import { useProjectType } from 'uf/ui/projects/hooks';

import styles from './ModeBar.module.css';

interface ModeBarItemProps {
  onClick?: (eventKey: string) => void;
  active?: boolean;
  className?: string;
  disabled?: boolean;
  disabledTooltipContent?: string;
  eventKey: string;
  icon?: MaybeElement;
  iconPath?: string;
  tooltipContent?: string;
}

const ModeBarItem = ({
  onClick,
  eventKey,
  disabled,
  active,
  icon,
  iconPath,
  className,
  disabledTooltipContent,
  tooltipContent,
}: ModeBarItemProps) => {
  const { projectType } = useProjectType();
  // Project type color is used to style icons on active
  const projectTypeColor = projectType?.style?.color ?? '#6E88A6';
  const handleClick = useCallback(() => {
    if (onClick && !disabled) {
      onClick(eventKey);
    }
  }, [onClick, disabled, eventKey]);
  return (
    <Tooltip
      position={Position.RIGHT}
      targetClassName={styles.modeBarItemMargin}
      content={disabled ? disabledTooltipContent : tooltipContent}>
      <AnchorButton
        data-testid="mode-bar-item"
        disabled={disabled}
        active={active}
        onClick={handleClick}
        minimal
        className={classNames(
          className,
          buttonStyles.button,
          styles.modeBarItem,
        )}
        // BF suffix on hex code sets opacity to 75%
        style={{ color: active ? `${projectTypeColor}BF` : '#92A6BE' }}
        icon={
          iconPath ? (
            <Icon
              path={iconPath}
              size={IconSizeNew.MEDIUM}
              className={iconStyles.size24px}
            />
          ) : (
            icon
          )
        }
      />
    </Tooltip>
  );
};

export default ModeBarItem;
