import { mdiEye, mdiEyeOff } from '@mdi/js';
import Icon from '@mdi/react';
import { boundMethod } from 'autobind-decorator';
import React, { Component } from 'react';
import { t } from 'ttag';

import Clickable from 'uf/ui/base/Clickable/Clickable';
import { IconSizeNew } from 'uf/ui/base/icon';
import WithTooltip from 'uf/ui/base/WithTooltip/WithTooltip';

interface Props {
  layerVisible: boolean;
  onClick: (e) => void;
  className?: string;
}

interface State {
  hovering: boolean;
}

export default class VisibilityToggle extends Component<Props, State> {
  state = {
    hovering: false,
  };

  @boundMethod
  onMouseEnter() {
    this.setState({ hovering: true });
  }

  @boundMethod
  onMouseLeave() {
    this.setState({ hovering: false });
  }

  render() {
    const { layerVisible, onClick, className } = this.props;

    // By default, icon reflects the current visibility state of the layer
    const defaultIconPath = layerVisible ? mdiEye : mdiEyeOff;

    // When hovering, icon reflects the state that clicking the layer will set
    const hoveredIconPath = layerVisible ? mdiEyeOff : mdiEye;

    const iconPath = this.state.hovering ? hoveredIconPath : defaultIconPath;

    // By default, the tooltip reflects the state that clicking the layer will set
    const tooltipText = layerVisible ? t`Hide` : t`Show`;

    return (
      <Clickable
        onClick={onClick}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        className={className}>
        <WithTooltip text={tooltipText}>
          <Icon path={iconPath} size={IconSizeNew.SMALL} />
        </WithTooltip>
      </Clickable>
    );
  }
}
