import { createSelector } from 'reselect';

import { LayerBreaks } from 'uf-api/model/models';
import { makeGetApiObjectResults } from 'uf/api/selectors';
import { makeGetFromPropsSelector } from 'uf/base/selector';
import { DataState } from 'uf/data/dataState';

export const getBreaksStates = makeGetApiObjectResults(
  'layer',
  'get_layer_breaks',
);
export function makeGetLayerBreaksByKey() {
  return createSelector(
    getBreaksStates,
    makeGetFromPropsSelector<string, 'key'>('key'),
    (allBreaks, key): DataState<LayerBreaks> => allBreaks[key],
  );
}
