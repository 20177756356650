import { combineReducers } from 'redux';

import {
  makeKeyedReducer,
  makeSetKeyedValueReducer,
} from 'uf/base/reducerFactories';
import { LegacyVirtualLayerId } from 'uf/projects/virtualLayers';
import { LayerSymbologyEnvelope } from 'uf/symbology';
import {
  ClearOverrideSymbologyAction,
  SET_DIVIDE_BY_COLUMN,
  SET_OVERRIDE_SYMBOLOGY,
  SaveUserSymbologyLoadAction,
  SetOverrideSymbologyAction,
} from 'uf/symbology/ActionTypes';
import { ViewId, ViewSymbologyState } from 'uf/views';

export type OverrideActions =
  | ClearOverrideSymbologyAction
  | SetOverrideSymbologyAction;

/**
 * Deeply keyed override symbology reducer [viewId][virtualLayerId][columnKey][divideByColumn]
 */
const overrideByDivideByColumn = makeSetKeyedValueReducer<
  LayerSymbologyEnvelope,
  SetOverrideSymbologyAction
>(SET_OVERRIDE_SYMBOLOGY, ['divideByColumn'], ['symbology']);
const overrideByColumnKey = makeKeyedReducer<
  any,
  string,
  SetOverrideSymbologyAction
>(overrideByDivideByColumn, 'columnKey', undefined);
const overrideByVirtualLayerId = makeKeyedReducer<
  any,
  LegacyVirtualLayerId,
  SetOverrideSymbologyAction
>(overrideByColumnKey, 'virtualLayerId', undefined);
const overrideByViewId = makeKeyedReducer<
  any,
  ViewId,
  SetOverrideSymbologyAction
>(overrideByVirtualLayerId, 'viewId', undefined);

/**
 * Deeply keyed divideByColumn reducer [projectId][viewId][virtualLayerId][columnKey]
 */
const divideByColumnByColumnKey = makeSetKeyedValueReducer<
  string,
  SaveUserSymbologyLoadAction
>(SET_DIVIDE_BY_COLUMN, ['divideByColumn'], ['symbologyEnvelope']);
const divideByColumnByVirtualLayerId = makeKeyedReducer(
  divideByColumnByColumnKey,
  'virtualLayerId',
);
const divideByColumnByViewId = makeKeyedReducer(
  divideByColumnByVirtualLayerId,
  'viewId',
);
const divideByColumnByProjectId = makeKeyedReducer(
  divideByColumnByViewId,
  'projectId',
);

export default combineReducers<ViewSymbologyState>({
  // The source of truth for symbology when being actively configured in the symbology editor.
  // This takes precedence over the `base` symbology.
  override: overrideByViewId,
  divideByColumn: divideByColumnByProjectId,
});
