import { createSelector } from 'reselect';

import { LayerBreaks } from 'uf-api';
import { makeGetFromPropsSelector } from 'uf/base/selector';
import { DataState } from 'uf/data/dataState';
import { ColumnKey, LayerId, LayerVersion } from 'uf/layers';
import { makeBreaksKey } from 'uf/layers/breaks';
import { getBreaksStates } from 'uf/layers/selectors/breaks';
import {
  makeGetLayerVersion,
  makeGetLayerVersionGetter,
} from 'uf/layers/selectors/versions';
import { ProjectId } from 'uf/projects';
import { DEFAULT_BREAK_COUNT, DEFAULT_BREAK_TYPE } from 'uf/symbology';
import { DivideByColumnKey } from 'uf/symbology/divideByColumn';
import {
  makeGetDivideByColumn,
  makeGetDivideByColumnGetter,
} from 'uf/symbology/selectors/divideByColumn';

export function makeGetSymbologyDefaultBreaksState() {
  return createSelector(
    getBreaksStates,
    makeGetFromPropsSelector<LayerId, 'layerId'>('layerId'),
    makeGetFromPropsSelector<ColumnKey, 'columnKey'>('columnKey'),
    makeGetLayerVersion(),
    makeGetDivideByColumn(),
    (
      allBreaks,
      layerId,
      columnKey,
      layerVersion,
      divideByColumn,
    ): DataState<LayerBreaks> =>
      getSymbologyDefaultBreaksState(
        allBreaks,
        layerId,
        columnKey,
        layerVersion,
        divideByColumn,
      ),
  );
}

export function makeGetSymbologyDefaultBreaksStateGetter() {
  return createSelector(
    makeGetLayerVersionGetter(),
    makeGetDivideByColumnGetter(),
    getBreaksStates,
    (getLayerVersion, getDivideByColumn, breaksState) =>
      (projectId: ProjectId, layerId: LayerId, columnKey: ColumnKey) =>
        getSymbologyDefaultBreaksState(
          breaksState,
          layerId,
          columnKey,
          getLayerVersion(layerId),
          getDivideByColumn(projectId, layerId, columnKey),
        ),
  );
}

function getSymbologyDefaultBreaksState(
  breaksState: Record<string, DataState<LayerBreaks>>,
  layerId: LayerId,
  columnKey: ColumnKey,
  layerVersion: LayerVersion,
  divideByColumn: DivideByColumnKey,
) {
  // We may not have a columnKey yet, as many consumers pass the
  // activeColumnKey which is set asyncronously
  if (!columnKey) {
    return null;
  }
  const key = makeBreaksKey(
    layerId,
    layerVersion,
    columnKey,
    DEFAULT_BREAK_COUNT,
    DEFAULT_BREAK_TYPE,
    divideByColumn,
  );

  return breaksState[key];
}
