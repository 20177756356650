import _ from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';

import { LayerMetadata } from 'uf-api';
import { ensureLayerMetadata as ensureLayerMetadataActionCreator } from 'uf/layers/actions/metadata';
import { LayerDataParams } from 'uf/layers/filters';
import { beginSearch as beginSearchAction } from 'uf/search/actions';
import { RawSearchResult } from 'uf/search/state';
import useBindAction from 'uf/ui/base/useBindAction/useBindAction';
import { useDebouncedCallback } from 'uf/ui/base/useDebouncedCallback/useDebouncedCallback';

import {
  LocationPickerSection,
  LocationPickerSourceLayer,
  LocationRow,
} from './types';

export function useDisambiguations(disambiguationData: RawSearchResult) {
  const [disambiguationsById, setDisambiguationsById] =
    useState<Record<string, LocationRow>>(null);

  useEffect(() => {
    // NOTE: we assume the items from the server match the LocationRow interface
    const rows = disambiguationData.items as unknown[] as LocationRow[];
    setDisambiguationsById(
      disambiguationData ? _.keyBy(rows, '_internalSearchKey') : {},
    );
  }, [disambiguationData]);

  return disambiguationsById;
}
export function useBeginSearch(
  requestLimit: number,
  sourceLayers: LocationPickerSourceLayer[],
  metadataById: Record<string, LayerMetadata>,
  setSections: (sections: LocationPickerSection[]) => void,
  debounceTime: number,
) {
  const latestPromises = useRef<Promise<any>[]>();
  const [busy, setBusy] = useState(false);
  // the single representative error being emitted by this hook
  const [error, setError] = useState(null);

  const beginSearch = useBindAction(beginSearchAction);
  const beginSearchRaw = useCallback(
    async (query: string) => {
      if (!query) {
        latestPromises.current = null;
        setSections([]);
        setBusy(false);
        return;
      }

      const searchQuery: LayerDataParams = {
        filters: {
          columns: {
            name: {
              columnMetatype: 'manual',
              fn: 'startswith',
              filterValue: {
                prefix: query,
                value: {
                  fn: 'column',
                  key: 'name',
                },
              },
            },
          },
        },
        limit: requestLimit,
      };
      // keep a private array of results specific to this request
      const nextSections = sourceLayers.map(
        (sourceLayer): LocationPickerSection => ({
          title: sourceLayer.name,
          suggestions: [],
          source: sourceLayer,
        }),
      );
      const currentErrors = [];
      const promises = sourceLayers.map(async (sourceLayer, index) => {
        const { layerId, name, columnFilter } = sourceLayer;
        const layerMetadata = metadataById[layerId];

        const layerQuery: LayerDataParams = {
          ...searchQuery,
          filters: {
            ...searchQuery.filters,
            columns: {
              ...searchQuery.filters.columns,
              ...columnFilter,
            },
          },
        };
        setBusy(true);
        try {
          const result = await beginSearch(layerId, layerQuery, layerMetadata);
          nextSections[index] = {
            title: name,
            suggestions: result.items as unknown[] as LocationRow[],
            source: sourceLayer,
          };
          return result;
        } catch (err) {
          if (__TESTING__) {
            console.error(`Error loading locations for ${layerId}`);
          } else {
            console.error(`Error loading locations for ${layerId}: `, err);
          }
          currentErrors.push(err);
        }
      });
      // Synchronously set this every time we kick off a search.
      // TODO: figure out how to cancel outstanding searches.
      latestPromises.current = promises;
      await Promise.all(promises);

      // new promises might get fired while we're waiting for the above to complete.
      // discard the result when this happens.
      if (latestPromises.current !== promises) {
        return;
      }

      if (currentErrors?.length && currentErrors.length === promises?.length) {
        setError(currentErrors[0]);
      } else {
        // Make sure to clear the error anyway, in case there was some stray
        // error that came in late
        setError(null);
      }
      setBusy(false);
      setSections(nextSections);
      // Don't leak!
      latestPromises.current = null;
    },
    [beginSearch, metadataById, requestLimit, setSections, sourceLayers],
  );
  const onSearch = useDebouncedCallback(
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    beginSearchRaw,
    [beginSearchRaw],
    debounceTime,
  );
  return { busy, error, onSearch };
}
export function useEnsureMetadata(sourceLayers: LocationPickerSourceLayer[]) {
  const [metadataById, setMetadataById] = useState<
    Record<string, LayerMetadata>
  >({});
  const ensureLayerMetadata = useBindAction(ensureLayerMetadataActionCreator);

  useEffect(() => {
    let isSubscribed = true;
    const promises = sourceLayers.map(({ layerId }) =>
      ensureLayerMetadata(layerId).then(metadata => ({ layerId, metadata })),
    );
    Promise.all(promises).then(metadatas => {
      if (isSubscribed) {
        const newMetadataById = Object.fromEntries(
          metadatas.map(({ layerId, metadata }) => [layerId, metadata]),
        );

        setMetadataById(newMetadataById);
      }
      return () => {
        isSubscribed = false;
      };
    });
  }, [ensureLayerMetadata, sourceLayers]);
  return metadataById;
}
