import React from 'react';

import { ProjectRoles } from 'uf/projects';
import useUserFlag from 'uf/ui/user/useUserFlag';

import { ProjectRoleChoices } from './ProjectRoleChoices/ProjectRoleChoices';

interface Props {
  projectRole: ProjectRoles;
  onProjectRoleChange: (projectRole: ProjectRoles) => void;
}

export const CreateProjectFlags: React.FunctionComponent<Props> = props => {
  const { projectRole, onProjectRoleChange } = props;

  const hasProjectRoleFlag = useUserFlag('project-roles');

  if (hasProjectRoleFlag) {
    return (
      <ProjectRoleChoices
        projectRole={projectRole}
        onProjectRoleChange={onProjectRoleChange}
      />
    );
  }
  return null;
};
