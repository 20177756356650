import { getAvailableFeatureFlagsActionTypes } from 'uf-api/api/internal.service';
import { getOrganizationFeatureFlagsActionTypes } from 'uf-api/api/organization.service';
import { dispatchAsyncAction, makeEnsureActionCreator } from 'uf/data/loader';
import {
  ADD_USER_FLAGS,
  REMOVE_USER_FLAGS,
  SET_USER_FLAGS,
} from 'uf/user/ActionTypes';
import {
  getAvailableApiFlagsState,
  makeGetUserOrganizationFlagsState,
} from 'uf/user/selectors/flags';

import { getAvailableFeatureFlags, getOrganizationFeatureFlags } from './apis';

export function setUserFlags(flags: string[]) {
  return {
    type: SET_USER_FLAGS,
    flags,
  };
}

export function addUserFlags(flags: string[]) {
  return {
    type: ADD_USER_FLAGS,
    flags,
  };
}

export function removeUserFlags(flags: string[]) {
  return {
    type: REMOVE_USER_FLAGS,
    flags,
  };
}

/**
 * helper action creator to allow a boolean to mass add/remove flags.
 */
export function updateUserFlags(flags: string[], value: boolean) {
  if (value) {
    return addUserFlags(flags);
  }
  return removeUserFlags(flags);
}

export function loadAvailableApiFlags() {
  return dispatchAsyncAction(
    getAvailableFeatureFlagsActionTypes,
    getAvailableFeatureFlags({}),
  );
}

export const ensureAvailableApiFlags = makeEnsureActionCreator(
  loadAvailableApiFlags,
  getAvailableApiFlagsState,
);

export function loadUserOrganizationFlags(organizationKey: string) {
  return dispatchAsyncAction(
    getOrganizationFeatureFlagsActionTypes,
    getOrganizationFeatureFlags({ key: organizationKey }),
    { key: organizationKey },
  );
}

export const ensureUserOrganizationFlags = makeEnsureActionCreator(
  loadUserOrganizationFlags,
  makeGetUserOrganizationFlagsState(),
  organizationKey => ({ organizationKey }),
);
