import { AnyAction } from 'redux';
import { Action as RFRAction } from 'redux-first-router';
import { Epic, StateObservable } from 'redux-observable';
import { map } from 'rxjs/operators';

import { convertRoutingAction } from 'uf/routing/actions';
import { getRootQueries } from 'uf/routing/selectors';
import { UFState } from 'uf/state';

/**
 * Creates an epic that adds the root query to the action and converts the action to be
 * consumed by redux-first-router.  This should be used when you don't need any custom modifications
 * to the query or other parts of the action.  If you do, then use the helper functions.
 *
 * Example:
 *
 * ```
 *   const addRootQueryToHomeRouteEpic = makeAddRootQueryEpic(HOME_ROUTE_1, HOME_ROUTE_2);
 * ```
 *
 * will create an epic that listens for actions like:
 * {
 *   type: 'HOME_ROUTE_1',
 *   query: {
 *     home-query: 'value',
 *   }
 * }
 *
 * and emits actions like:
 *
 * {
 *   type: 'uf/routing/HOME_ROUTE_1',
 *   query: {
 *     home-query: 'value',
 *     root-query: 'root-value',
 *   }
 * }
 */
export function makeAddRootQueryEpic(
  ...actionTypes: string[]
): Epic<RFRAction, any, UFState> {
  return (action$, state$) =>
    action$
      .ofType(...actionTypes)
      .pipe(map(makeAddRootQuery(state$)), map(convertRoutingAction));
}

/**
 * function to be used in an epic.  adds root queries to the mapped action
 *
 * Example:
 *
 * export const addRootQueriesEpic: Epic = (action$, _, _, state$}) => {
 *   return action$
 *     .ofType(SOME_ROUTING_ACTION_TYPE)
 *     .map(makeMapRootQueries(state$.value))
 */
export function makeAddRootQuery(state$: StateObservable<UFState>) {
  return action => {
    const rootQueries = getRootQueries(state$.value);
    return addRootQueryToAction(action, rootQueries);
  };
}

export function addRootQueryToAction(action: AnyAction, rootQuery: object) {
  const actionQuery = {
    ...action.query,
    ...action.meta?.query,
  };

  const routingAction = {
    ...action,
    query: {
      ...actionQuery,
      ...rootQuery,
    },
  };
  return routingAction;
}
