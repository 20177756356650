import {
  CuratedColumnSymbology,
  CUSTOM_THEME,
  DEFAULT_OPACITY,
  InterpolationTypes,
  PaintColorTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

import stateList from './us_states';

const symbologies: Record<
  string,
  Record<string, CuratedColumnSymbology[]>
> = {};

stateList.forEach(stateName => {
  symbologies[`/public/dataset/nhd_area_${stateName}`] = {
    ftype: [
      {
        type: SymbologyTypes.FILL,
        paint: {
          'fill-color': {
            property: 'ftype',
            type: PaintColorTypes.CATEGORICAL,
            stops: [
              { value: 307, label: 'Area to be Submerged', color: '#b8b2a6' },
              { value: 312, label: 'Bay Inlet', color: '#3f7276' },
              { value: 318, label: 'Bridge', color: '#ee7058' },
              { value: 336, label: 'Canal Ditch', color: '#92a7a7' },
              { value: 343, label: 'Dam Weir', color: '#7b9798' },
              { value: 362, label: 'Flume', color: '#77cbcb' },
              { value: 364, label: 'Foreshore', color: '#f4e2ba' },
              { value: 398, label: 'Lock Chamber', color: '#86be69' },
              { value: 403, label: 'Inudation Area', color: '#fa9b85' },
              { value: 431, label: 'Rapids', color: '#0e7e62' },
              { value: 445, label: 'Sea Ocean', color: '#15475e' },
              { value: 455, label: 'Spillway', color: '#a95545' },
              { value: 460, label: 'Stream/River', color: '#9cc4e1' },
              { value: 461, label: 'Submerged Stream', color: '#64b7fe' },
              { value: 484, label: 'Wash', color: '#f2e20d' },
              { value: 485, label: 'Water Intake/Outflow', color: '#2b3b8e' },
              {
                value: 537,
                label: 'Area of Complex Channels',
                color: '#9b8959',
              },
              { value: 568, label: 'Levee', color: '#89673a' },
            ],
          },
          'fill-opacity': DEFAULT_OPACITY,
        },
        display_hints: {
          scale: SymbologyScales.CATEGORICAL,
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          theme: CUSTOM_THEME,
        },
      },
    ],
  };
});

export default symbologies;
