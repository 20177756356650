import {
  CuratedColumnSymbology,
  DistributionTypes,
  InterpolationTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const censusPlanningdatabaseHouseholdsLayers = [
  {
    layerId:
      '/public/dataset/us_census_census_planning_database_2018_household',
    columnKey: 'avg_tot_prns_in_hhd_acs_12_16',
  },
  {
    layerId:
      '/public/dataset/us_census_census_planning_database_2019_household',
    columnKey: 'avg_tot_prns_in_hhd_acs_13_17',
  },
  {
    layerId: '/public/databank/cpd_2020_household',
    columnKey: 'avg_tot_prns_in_hhd_acs_14_18',
  },
  {
    layerId: '/public/databank/cpd_2021_household',
    columnKey: 'avg_tot_prns_in_hhd_acs_15_19',
  },
];

const symbologies: Record<
  string,
  Record<string, CuratedColumnSymbology[]>
> = {};
censusPlanningdatabaseHouseholdsLayers.forEach(({ layerId, columnKey }) => {
  symbologies[layerId] = {
    [columnKey]: [
      {
        display_hints: {
          distribution: DistributionTypes.DEFAULT,
          interpolation: InterpolationTypes.INTERVAL,
          scale: SymbologyScales.NUMERIC,
          showZero: true,
          theme: 'd3:interpolateRdPu',
        },
        paint: {
          'fill-color': {
            property: columnKey,
            stops: [
              {
                color: 'rgb(255, 247, 243)',
                value: 1,
              },
              {
                color: 'rgb(253, 228, 225)',
                value: 1.25,
              },
              {
                color: 'rgb(252, 207, 204)',
                value: 1.5,
              },
              {
                color: 'rgb(251, 181, 188)',
                value: 1.75,
              },
              {
                color: 'rgb(249, 147, 176)',
                value: 2,
              },
              {
                color: 'rgb(243, 105, 163)',
                value: 2.25,
              },
              {
                color: 'rgb(224, 62, 152)',
                value: 2.5,
              },
              {
                color: 'rgb(192, 23, 136)',
                value: 2.75,
              },
              {
                color: 'rgb(153, 3, 124)',
                value: 3,
              },
              {
                color: 'rgb(112, 1, 116)',
                value: 3.25,
              },
              {
                color: 'rgb(73, 0, 106)',
                value: 3.5,
              },
            ],
            type: 'interval',
          },
          'fill-opacity': 0.5,
        },
        type: SymbologyTypes.FILL,
      },
      {
        display_hints: {
          distribution: DistributionTypes.DEFAULT,
          interpolation: InterpolationTypes.INTERVAL,
          scale: SymbologyScales.NUMERIC,
          showZero: true,
          theme: 'custom',
        },
        paint: {
          'line-color': '#333333',
          'line-opacity': 0.5,
          'line-width': 0.5,
        },
        type: SymbologyTypes.LINE,
      },
    ],
  };
});

export default symbologies;
