import { combineReducers } from 'redux';

import { getCurrentUserActionTypes } from 'uf-api/api/authentication.service';
import { getAvailableFeatureFlagsActionTypes } from 'uf-api/api/internal.service';
import {
  getBillingInfoActionTypes,
  getOrganizationFeatureFlagsActionTypes,
} from 'uf-api/api/organization.service';
import {
  getUserDataActionTypes,
  listUserDataItemsActionTypes,
} from 'uf-api/api/userdata.service';
import { makeKeyedPromiseReducer } from 'uf/data/makeKeyedPromiseReducer';
import { makePromiseReducer } from 'uf/data/makePromiseReducer';
import { authenticationActionTypes } from 'uf/user/ActionTypes';
import { userData } from 'uf/user/reducer/userDataTruth';
import { UserState } from 'uf/user/state';

import flags from './flags';

const user = combineReducers<UserState>({
  // TODO: deprecated.  remove when auth0 goes into production.
  authentication: makePromiseReducer(authenticationActionTypes),
  info: makePromiseReducer(getCurrentUserActionTypes),
  billingInfo: makeKeyedPromiseReducer(getBillingInfoActionTypes),
  userData,
  userDataInternal: makeKeyedPromiseReducer(getUserDataActionTypes),
  userDataItemsInternal: makeKeyedPromiseReducer(listUserDataItemsActionTypes),
  organizationFlags: makeKeyedPromiseReducer(
    getOrganizationFeatureFlagsActionTypes,
  ),
  flags,
  apiFlags: makePromiseReducer(getAvailableFeatureFlagsActionTypes),
});

export default user;
