import _ from 'lodash';
import { Epic } from 'redux-observable';
import { filter, map } from 'rxjs/operators';
import { t } from 'ttag';
import warning from 'warning';

import { ScopeTypes } from 'uf-ws/WebsocketActions';
import { TaskStatusToModuleStatus } from 'uf/analysis';
import { runAnalysis, setAnalysisStatus } from 'uf/analysis/actions/analysis';
import { events as analysisEvents } from 'uf/analysis/logging';
import { AnalysisTaskMessage } from 'uf/analysis/tasks';
import { getActiveProjectId } from 'uf/app/selectors';
import { addAppMessage } from 'uf/appservices/actions';
import { Message } from 'uf/appservices/messages';
import { combineEpics } from 'uf/base/epics';
import { logEvent } from 'uf/logging';
import { loadProject } from 'uf/projects/actions';
import {
  makeGetAnalysisModuleInfoForProject,
  makeGetScenarioInfoForProject,
} from 'uf/projects/selectors';
import { ScenarioId } from 'uf/scenarios';
import { NotificationAction } from 'uf/tasks/ActionTypes';
import { ofNotificationType } from 'uf/tasks/observables';
import { TaskStatuses } from 'uf/tasks/TaskStatuses';
import { TaskTypes } from 'uf/tasks/TaskTypes';

export const setAnalysisStatusEpic: Epic<
  NotificationAction<AnalysisTaskMessage>,
  any
> = action$ =>
  action$.pipe(
    ofNotificationType<AnalysisTaskMessage>(
      TaskTypes.TASK_TYPE_SCENARIO_MODULE_ANALYSIS,
    ),
    map(({ result }) => {
      const projectId = result.scope;
      const moduleKey: string = result.info.analysis_module_key;
      const scenarioId: ScenarioId = result.info.scenario_path;
      const moduleStatus = TaskStatusToModuleStatus[result.status];

      return setAnalysisStatus(projectId, moduleKey, scenarioId, moduleStatus);
    }),
  );

export const loadProjectAfterAnalysisFinishes: Epic<
  NotificationAction<AnalysisTaskMessage>,
  any
> = action$ =>
  action$.pipe(
    ofNotificationType<AnalysisTaskMessage>(
      TaskTypes.TASK_TYPE_SCENARIO_MODULE_ANALYSIS,
      TaskStatuses.DONE,
    ),
    map(({ result }) => {
      warning(
        result.scope_type === ScopeTypes.PROJECT,
        `Analysis status updated with unexpected scope_type ${result.scope_type}`,
      );
      logEvent(analysisEvents.ANALYSIS_FINISHED, { result });
      const projectId = result.scope;
      return loadProject(projectId);
    }),
  );

export const showToastForAnalysisError: Epic<
  NotificationAction<AnalysisTaskMessage>,
  any
> = (action$, state$) => {
  const getAnalysisModuleInfoForProject = makeGetAnalysisModuleInfoForProject();
  const getScenarioInfoForProject = makeGetScenarioInfoForProject();

  return action$.pipe(
    ofNotificationType<AnalysisTaskMessage>(
      TaskTypes.TASK_TYPE_SCENARIO_MODULE_ANALYSIS,
      TaskStatuses.ERROR,
    ),
    filter(action => {
      const projectId = action.result.scope;
      const isForActiveProject = getActiveProjectId(state$.value) === projectId;

      return isForActiveProject;
    }),
    map(({ result }) => {
      const projectId = result.scope;
      const moduleKey: string = result.info.analysis_module_key;
      const scenarioId: ScenarioId = result.info.scenario_path;
      const message: string = result?.problem?.title;

      logEvent(analysisEvents.ANALYSIS_ERROR, { result });
      warning(
        result.scope_type === ScopeTypes.PROJECT,
        `Analysis status updated with unexpected scope_type ${result.scope_type}`,
      );

      const module = getAnalysisModuleInfoForProject(state$.value, {
        projectId,
        moduleKey,
      });
      const scenario = getScenarioInfoForProject(state$.value, {
        projectId,
        scenarioId,
      });

      let text: string = message;
      if (module?.name && scenario && scenario.name) {
        const moduleName = module.name;
        const scenarioName = scenario.name;
        text = t`Error running ${moduleName} analysis in ${scenarioName}`;
      } else if (_.isEmpty(text)) {
        text = t`Error running analysis module`;
      }

      const onClickAction = runAnalysis(moduleKey, projectId, scenarioId);

      const toastMessage: Message = {
        level: 'danger',
        status: 'failure',
        action: {
          text: t`Retry`,
          level: 'danger',
          onClick: () => onClickAction,
        },
      };

      return addAppMessage(text, toastMessage);
    }),
  );
};

export default combineEpics(
  {
    setAnalysisStatusEpic,
    loadProjectAfterAnalysisFinishes,
    showToastForAnalysisError,
  },
  'analysisNotifications',
);
