import colors from 'material-colors';

import {
  CuratedColumnSymbology,
  CUSTOM_THEME,
  DEFAULT_OPACITY,
  InterpolationTypes,
  PaintColorTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/public/dataset/hud_recaps': {
    rcap_10: [
      {
        type: SymbologyTypes.FILL,
        paint: {
          'fill-color': {
            property: 'rcap_10',
            type: PaintColorTypes.CATEGORICAL,
            stops: [
              {
                value: 0,
                label: 'Not a Racially/Ethnically Concentrated Area of Poverty',
                color: colors.lightBlue[100],
              },
              {
                value: 1,
                label: 'Racially/Ethnically Concentrated Areas of Poverty',
                color: colors.red[300],
              },
            ],
          },
          'fill-opacity': DEFAULT_OPACITY,
        },
        display_hints: {
          scale: SymbologyScales.CATEGORICAL,
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          theme: CUSTOM_THEME,
        },
      },
    ],
  },
};

export default symbologies;
