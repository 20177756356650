import { AnyAction } from 'redux';

enum ReduxLoggerFilterOptions {
  /**
   * For hiding the LOAD, SUCCESS, ERROR actions in redux-logger
   */
  HIDE_ASYNC_ACTIONS = 'no-async-actions',

  /**
   * For hiding actions that we don't control, like our router actions.
   * Only actions that begin with `uf/` will be logged
   */
  HIDE_NON_UF_ACTIONS = 'uf-actions-only',

  /**
   * For hiding any action that is dispatched as a result of touching the explore map.
   * This is useful if you've got a lot of LOADING/LOADED actions from interacting with the map.
   */
  HIDE_EXPLORE_MAP_ACTIONS = 'no-explore-map-actions',

  /**
   * For hiding the mapbox-gl-redux actions that are dispatched with every mapbox viewport event.
   */
  HIDE_MAPBOX_ACTIONS = 'no-mapbox-actions',
}

/**
 * The list of redux logger filter options.
 * Purposefully not exported from this file.
 * If you want to push to it, use the exported methods.
 */
let reduxLoggerFilters: ReduxLoggerFilterOptions[] = [
  // Hide the mapbox actions by default because there a lot of them.
  ReduxLoggerFilterOptions.HIDE_MAPBOX_ACTIONS,
];

export const hasHiddenAsyncActions = makeHasHiddenPredicate(
  ReduxLoggerFilterOptions.HIDE_ASYNC_ACTIONS,
);

export const hasHiddenNonUfActions = makeHasHiddenPredicate(
  ReduxLoggerFilterOptions.HIDE_NON_UF_ACTIONS,
);

export const hasHiddenExploreMapActions = makeHasHiddenPredicate(
  ReduxLoggerFilterOptions.HIDE_EXPLORE_MAP_ACTIONS,
);
export const hasHiddenMapboxActions = makeHasHiddenPredicate(
  ReduxLoggerFilterOptions.HIDE_MAPBOX_ACTIONS,
);

/**
 * For hiding the LOAD, SUCCESS, ERROR actions in redux-logger.
 */
export const hideAsyncActions = makeHideFunction(
  ReduxLoggerFilterOptions.HIDE_ASYNC_ACTIONS,
  hasHiddenAsyncActions,
);

/**
 *
 * For hiding actions that we don't control, like our router actions.
 * Only actions that begin with `uf/` will be logged.
 */
export const hideNonUfActions = makeHideFunction(
  ReduxLoggerFilterOptions.HIDE_NON_UF_ACTIONS,
  hasHiddenNonUfActions,
);

/**
 * For hiding any action that is dispatched as a result of touching the explore map.
 * This is useful if you've got a lot of LOADING/LOADED actions from interacting with the map.
 */
export const hideExploreMapActions = makeHideFunction(
  ReduxLoggerFilterOptions.HIDE_EXPLORE_MAP_ACTIONS,
  hasHiddenExploreMapActions,
);

export const hideMapboxActions = makeHideFunction(
  ReduxLoggerFilterOptions.HIDE_MAPBOX_ACTIONS,
  hasHiddenMapboxActions,
);

export function shouldShowAction(action: AnyAction) {
  // Don't show non-uf actions when specified
  if (hasHiddenNonUfActions() && isUfAction(action)) {
    return false;
  }

  // Don't show uf async actions when specified
  if (hasHiddenAsyncActions() && isAsyncAction(action)) {
    return false;
  }

  // Don't show explore map actions when specified
  if (hasHiddenExploreMapActions() && isExploreMapAction(action)) {
    return false;
  }
  // Don't show mapbox actions when specified
  if (hasHiddenMapboxActions() && isMapboxAction(action)) {
    return false;
  }

  return true;
}

export function isUfAction(action: AnyAction): boolean {
  const ufActionPrefixes = ['uf/', 'uf-api'];
  return ufActionPrefixes.some(prefix => action.type.startsWith(prefix));
}

export function isAsyncAction(action: AnyAction): boolean {
  let isAsync = false;
  ['/LOAD', '/SUCCESS', '/FAILURE'].forEach(asyncSuffix => {
    isAsync = isAsync || action.type.endsWith(asyncSuffix);
  });

  return isUfAction(action) && isAsync;
}

export function isExploreMapAction(action: AnyAction): boolean {
  return isUfAction(action) && action.type.startsWith('uf/explore/map');
}
export function isMapboxAction(action: AnyAction): boolean {
  return action.type.startsWith('mapbox-');
}

function makeHasHiddenPredicate(
  reduxLoggerFilterOption: ReduxLoggerFilterOptions,
) {
  return () => reduxLoggerFilters.includes(reduxLoggerFilterOption);
}

function makeHideFunction(
  reduxLoggerFilterOption: ReduxLoggerFilterOptions,
  isHiddenPredicate,
) {
  return (hide: boolean) => {
    if (hide && !isHiddenPredicate()) {
      reduxLoggerFilters.push(reduxLoggerFilterOption);
    } else {
      reduxLoggerFilters = reduxLoggerFilters.filter(
        filter => filter !== reduxLoggerFilterOption,
      );
    }
  };
}
