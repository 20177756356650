import { boundMethod } from 'autobind-decorator';
import classNames from 'classnames';
import React, { Component } from 'react';
import SockJS from 'sockjs-client';

import panelStyles from 'uf/styles/panels.module.css';
import rootStyles from 'uf/styles/root.module.css';

import styles from './HmrStatus.module.css';

interface HmrStatusProps {
  onMessage?: (...args: any[]) => any;
  className?: string;
  style?: React.CSSProperties;
}

interface HmrStatusState {
  status: string;
}
/**
 * This is a simple component for watching the status of the
 * hot-module-reloading provided by webpack-dev-server.
 *
 * It uses `module.hot` to decide whether to activate.
 *
 * There are two different mechanisms to listen for hot-reload messages:
 *
 * * module.hot.addStatusHandler - provides status about a hot-reload
 *   as it is happening.
 * * websocket notifications - provides status about the server-side
 *   process, like when the re-compilation begins, when the connection
 *   to the server is lost, etc.
 *
 * The `onMessage` prop will be called with (message, level) if any messages
 * need to bubble out of the component.
 */
class HmrStatus extends Component<HmrStatusProps, HmrStatusState> {
  mounted: boolean;
  sock: WebSocket;
  state = {
    status: 'starting',
  };

  componentDidMount() {
    this.mounted = true;
    // Don't call setState until after the component has rendered, so
    // that server-side and client-side rendering are the same.
    if (module.hot) {
      module.hot.addStatusHandler(this.onHmrUpdate);
      if (this.sock) {
        // already hooked up, component has just been re-mounted.
        return;
      }
      this.sock = new SockJS('/sockjs-node');
      this.sock.onopen = () => {
        if (this.mounted && this.state.status) {
          this.setState({ status: 'idle' });
        }
      };
      this.sock.onclose = () => {
        if (this.mounted && this.state.status) {
          this.setState({ status: 'disconnected' });
        }
        this.sock = null;
      };
      this.sock.onmessage = this.onSocketMessage;
      this.onHmrUpdate(module.hot.status());
    }
  }
  componentWillUnmount() {
    this.mounted = false;
    if (module.hot) {
      module.hot.removeStatusHandler(this.onHmrUpdate);
    }
  }
  @boundMethod
  onHmrUpdate(status) {
    if (this.mounted && this.state.status) {
      this.setState({ status });
    }
  }
  @boundMethod
  onSocketMessage(event) {
    if (!this.mounted) {
      return;
    }
    const message = JSON.parse(event.data);
    switch (message.type) {
      case 'invalid':
        this.setState({ status: 'recompiling' });
        break;
      case 'still-ok':
      case 'ok':
        this.setState({ status: 'ready' });
        break;
      case 'hash':
        break;
      case 'warnings':
        this.setState({ status: 'warnings' });
        message.data.forEach(warning => {
          this.props.onMessage(warning, 'warning');
        });
        break;
      case 'errors':
        this.setState({ status: 'errors' });
        message.data.forEach(error => {
          this.props.onMessage(error, 'danger');
        });
        break;
      // These are known messages, but can generally be ignored. However we will
      // still output them in case they sometimes contain useful information
      // that we want to use:
      case 'hot':
      case 'log-level':
      case 'liveReload':
        /* eslint-disable no-console */
        console.log('Generic hot-reload message: ', message);
        /* eslint-enable no-console */
        break;
      default:
        console.warn(`HMR: Unknown message type ${message.type}`, message);
        break;
    }
  }
  render() {
    const { style, className } = this.props;
    const statusClass = `status-${this.state.status}`;
    return (
      <div style={style} className={classNames(className, panelStyles.card5)}>
        <span
          className={classNames(
            styles.status,
            styles[statusClass],
            rootStyles.veryThinPadding,
          )}>
          {this.state.status}
        </span>
      </div>
    );
  }
}
export default HmrStatus;
