import _ from 'lodash';
import { createSelector } from 'reselect';

import {
  LayerReference,
  MapExportControlSettings,
  MapExportDisplaySettings,
  MapExportLayerStyle,
  MapExportOutputSettings,
  MapExportRequest,
} from 'uf-api/model/models';
import { EMPTY_ARRAY, EMPTY_OBJECT } from 'uf/base';
import { DEFAULT_SCREEN_DPI, Dimensions, DimensionType } from 'uf/base/map';
import { makeGetFromPropsSelector } from 'uf/base/selector';
import { getVisibleFeatureIds } from 'uf/explore/baseMapLayers';
import {
  makeGetProjectLayersMapStyles,
  makeGetVisibleLayerReferences,
  makeGetVisibleVirtualIdsByLayerIdMap,
} from 'uf/explore/selectors/layers';
import {
  getHasBuildings,
  getHasCitiesLabels,
  getHasContours,
  getHasPlaceLabels,
  getHasPoiLabels,
  getHasRoadRailLabels,
  getHasWaterLabels,
  getHasWaterMask,
  makeGetExploreMapStyleUrl,
} from 'uf/explore/selectors/map';
import { makeGetActiveViewId } from 'uf/explore/selectors/views';
import { ColumnKey } from 'uf/layers';
import { getAllLayerVersions } from 'uf/layers/selectors/versions';
import { EMPTY_BASE_MAP } from 'uf/map/baseMaps';
import { MapExportState, MapExportUIState } from 'uf/mapexport/state';
import { getLayerStyles } from 'uf/mapexport/styles';
import { ProjectId } from 'uf/projects';
import { makeGetProjectLayerIdMap } from 'uf/projects/selectors/virtualLayers';
import { ScenarioId } from 'uf/scenarios';
import { makeGetSymbologyStateGetter } from 'uf/symbology/selectors';
import { makeGetDivideByColumnGetter } from 'uf/symbology/selectors/divideByColumn';
import { TaskIdsMap, TaskMessage, TaskState } from 'uf/tasks/MessageTypes';
import { ViewId } from 'uf/views';

function getMapExport(state): MapExportState {
  return state.mapexport || EMPTY_OBJECT;
}

const getMapExportUIState = createSelector(
  getMapExport,
  mapexport => mapexport.uiState || (EMPTY_OBJECT as MapExportUIState),
);

export const getIncludeBaseMap =
  makeGetMapExportUIStatePropertySelector('includeBaseMap');
export const getMapExportFormat =
  makeGetMapExportUIStatePropertySelector('exportFormat');
export const getLegendPlacement =
  makeGetMapExportUIStatePropertySelector('legendPlacement');
export const getLegendClassesToInclude =
  makeGetMapExportUIStatePropertySelector('legendClassesToInclude');
const getExcludedLegendLayers = makeGetMapExportUIStatePropertySelector(
  'excludedLegendLayers',
);
export const getPageOrientation =
  makeGetMapExportUIStatePropertySelector('pageOrientation');
export const getMapExportBearing =
  makeGetMapExportUIStatePropertySelector('bearing');
export const getMapExportZoom = makeGetMapExportUIStatePropertySelector('zoom');
export const getMapExportCenter =
  makeGetMapExportUIStatePropertySelector('center');
const getMapExportWidth = makeGetMapExportUIStatePropertySelector('width');
const getMapExportHeight = makeGetMapExportUIStatePropertySelector('height');
export const getMapExportPitch =
  makeGetMapExportUIStatePropertySelector('pitch');

export const getMapExportScale =
  makeGetMapExportUIStatePropertySelector('scale');

export const getMapExportScaleRatio =
  makeGetMapExportUIStatePropertySelector('scaleRatio');

export const getMapExportRequestedScale =
  makeGetMapExportUIStatePropertySelector('requestedScale');

export const getMapExportResolution =
  makeGetMapExportUIStatePropertySelector('resolution');

export const getMapExportDimensionType =
  makeGetMapExportUIStatePropertySelector('dimensionType');

export const getMapExportDimensions =
  makeGetMapExportUIStatePropertySelector('dimensions');
export const getMapExportPhysicalDimensions =
  makeGetMapExportUIStatePropertySelector('physicalDimensions');

export const getMapExportScaleBarVisible =
  makeGetMapExportUIStatePropertySelector('scaleBarVisible');

export const getMapExportCompassVisible =
  makeGetMapExportUIStatePropertySelector('compassVisible');

export const getMapExportTitleVisible =
  makeGetMapExportUIStatePropertySelector('titleVisible');

export const getMapExportTitle =
  makeGetMapExportUIStatePropertySelector('title');

export const getMapExportBounds =
  makeGetMapExportUIStatePropertySelector('bounds');

export const getMapExportRequestedBounds =
  makeGetMapExportUIStatePropertySelector('requestedBounds');

export const getMapExportLogoKey =
  makeGetMapExportUIStatePropertySelector('logoKey');

export const getMapExportLogoVisible =
  makeGetMapExportUIStatePropertySelector('logoVisible');

export const getMapExportLogoType =
  makeGetMapExportUIStatePropertySelector('logoType');

export const getMapExportCompassType =
  makeGetMapExportUIStatePropertySelector('compassType');

export const getMapExportScaleType =
  makeGetMapExportUIStatePropertySelector('scaleType');

export const getMapExportScaleUnits =
  makeGetMapExportUIStatePropertySelector('scaleUnits');

export const getMapExportNumGeometriesVisible =
  makeGetMapExportUIStatePropertySelector('numGeometries');

export const getScreenToPageRatio = createSelector(
  getPageOrientation,
  getMapExportDimensionType,
  getMapExportDimensions,
  getMapExportPhysicalDimensions,
  getMapExportWidth,
  (
    orientation,
    dimensionType,
    dimensions,
    physicalDimensions,
    screenWidthPx,
  ) => {
    const exportWidthInches = getOrientedDimensions(
      physicalDimensions,
      orientation,
    ).width;
    const exportWidthPx = getOrientedDimensions(dimensions, orientation).width;

    if (dimensionType === DimensionType.Print) {
      const printWidthPx = exportWidthInches * DEFAULT_SCREEN_DPI;
      const ratio = screenWidthPx / printWidthPx;
      return ratio;
    }

    if (dimensionType === DimensionType.Image) {
      return screenWidthPx / exportWidthPx;
    }
  },
);

// TODO: move this to a base directory and make every instance of this calculation use this
// function.
export function getOrientedDimensions(
  dimensions: Dimensions,
  orientation: MapExportDisplaySettings.OrientationEnum,
): Dimensions {
  if (orientation === MapExportDisplaySettings.OrientationEnum.Landscape) {
    return {
      width: dimensions.height,
      height: dimensions.width,
    };
  }

  return {
    ...dimensions,
  };
}

/**
 * returns that last selected bookmark from the export dialog
 */
export function makeGetMapExportBookmarkKey() {
  return createSelector(
    getMapExportUIState,
    makeGetFromPropsSelector<ProjectId, 'projectId'>('projectId'),
    (mapExportState, projectId): string => {
      const bookmarkKeysState = mapExportState.bookmarkKey;
      return bookmarkKeysState[projectId];
    },
  );
}

export function makeGetMapExportBaseMapStyleUrl() {
  return createSelector(
    getIncludeBaseMap,
    makeGetExploreMapStyleUrl(),
    (includeBaseMap, exploreMapStyleUrl) => {
      if (includeBaseMap) {
        return exploreMapStyleUrl;
      }
      return EMPTY_BASE_MAP;
    },
  );
}

export const getMapExportBaseMapFeatures = createSelector(
  getHasBuildings,
  getHasContours,
  getHasCitiesLabels,
  getHasPlaceLabels,
  getHasPoiLabels,
  getHasRoadRailLabels,
  getHasWaterLabels,
  getHasWaterMask,
  (
    buildingsVisible,
    contoursVisible,
    citiesLabelVisible,
    placesLabelVisible,
    poiLabelVisible,
    roadRailLabelVisible,
    waterLabelVisible,
    waterMaskVisible,
  ) => {
    const params = {
      buildingsVisible,
      contoursVisible,
      citiesLabelVisible,
      placesLabelVisible,
      poiLabelVisible,
      roadRailLabelVisible,
      waterLabelVisible,
      waterMaskVisible,
    };
    return getVisibleFeatureIds(params);
  },
);

export interface ExportLayerSpec {
  excludeFromLegend: boolean;
  layer: LayerReference;
  columnKey: ColumnKey;
  version: string;
}

export const getMapExportLayers = createSelector(
  makeGetVisibleLayerReferences(),
  getExcludedLegendLayers,
  makeGetProjectLayersMapStyles(), // for the active columns
  getAllLayerVersions,
  makeGetProjectLayerIdMap(),
  makeGetFromPropsSelector<ScenarioId, 'scenarioId'>('scenarioId'),
  (
    allLayers,
    excludedLayers,
    mapStyles,
    allLayerVersions,
    layerMap,
  ): ExportLayerSpec[] => {
    if (!allLayers.length) {
      return EMPTY_ARRAY;
    }

    const exportLayers = allLayers.map(layer => {
      const { full_path: layerId } = layer;
      const virtualLayerId = layerMap[layerId];
      const spec: ExportLayerSpec = {
        excludeFromLegend: excludedLayers.includes(layerId),
        layer,
        columnKey: mapStyles?.[virtualLayerId]?.activeColumnKey,
        version: allLayerVersions[layerId],
      };
      return spec;
    });

    return exportLayers;
  },
);

export const getMapExportLayerIds = createSelector(
  getMapExportLayers,
  allLayers => {
    if (_.isEmpty(allLayers)) {
      return EMPTY_ARRAY;
    }
    return allLayers.map(({ layer }) => layer.full_path);
  },
);

export const getMapExportLayerColumnKeys = createSelector(
  getMapExportLayers,
  allLayers => allLayers.map(({ columnKey }) => columnKey),
);

export const getMapExportLegendLayerIds = createSelector(
  getMapExportLayers,
  mapExportLayers =>
    mapExportLayers
      .filter(({ excludeFromLegend }) => !excludeFromLegend)
      .map(({ layer }) => layer.full_path),
);

export const getMapExportLegendLayerColumnKeys = createSelector(
  getMapExportLayers,
  mapExportLayers =>
    mapExportLayers
      .filter(({ excludeFromLegend }) => !excludeFromLegend)
      .map(({ columnKey }) => columnKey),
);

export const getMapExportLegendLayerVersions = createSelector(
  getMapExportLayers,
  mapExportLayers =>
    mapExportLayers
      .filter(({ excludeFromLegend }) => !excludeFromLegend)
      .map(({ version }) => version),
);

export const getMapExportDisplayParams = createSelector(
  getPageOrientation,
  getMapExportZoom,
  getMapExportCenter,
  getMapExportBounds,
  getMapExportWidth,
  getMapExportHeight,
  getMapExportPitch,
  getMapExportBearing,
  (pageOrientation, zoom, center, bounds, width, height, pitch, bearing) => {
    const displayParams: MapExportDisplaySettings = {
      source_zoom: zoom,
      source_height: height,
      source_width: width,
      center,
      bounds,
      // always send portrait
      // TODO: remove orientation alltogether.  At one point we were going to actually rotate the
      // exportd image for landscape but we decided that was dumb and it was better to just output a
      // wider image.
      orientation: MapExportDisplaySettings.OrientationEnum.Portrait,
      pitch,
      bearing,
    };
    return displayParams;
  },
);

const getMapExportOutputSettings = createSelector(
  getMapExportDimensions,
  getPageOrientation,
  getMapExportResolution,
  getMapExportFormat,
  (dimensions, orientation, dpi, format): MapExportOutputSettings => {
    if (orientation === MapExportDisplaySettings.OrientationEnum.Landscape) {
      return {
        width: dimensions.height,
        height: dimensions.width,
        dpi,
        format,
      };
    }

    return {
      ...dimensions,
      dpi,
      format,
    };
  },
);

const getMapExportControlSettings = createSelector(
  getMapExportLayers,
  getMapExportCompassType,
  getMapExportCompassVisible,
  getLegendPlacement,
  getLegendClassesToInclude,
  getMapExportLogoKey,
  getMapExportLogoVisible,
  getMapExportScaleType,
  getMapExportScaleUnits,
  getMapExportScaleBarVisible,
  getMapExportTitleVisible,
  getMapExportTitle,
  (
    mapExportLayers,
    compassType,
    compassVisible,
    legendPlacement,
    legendClassesToInclude,
    logoKey,
    logoVisible,
    scaleType,
    scaleUnits,
    scaleBarVisible,
    titleVisible,
    title,
  ): MapExportControlSettings => {
    const legendLayers = mapExportLayers.filter(
      ({ excludeFromLegend }) => !excludeFromLegend,
    );
    const legendLayerIds = legendLayers.map(({ layer }) => layer.full_path);
    const legendLayerColumnKeys = legendLayers.map(
      ({ columnKey }) => columnKey,
    );

    const request: MapExportControlSettings = {
      compass_type: compassVisible
        ? compassType
        : MapExportControlSettings.CompassTypeEnum.None,
      legend_layer_column_keys: legendLayerColumnKeys,
      legend_layers: legendLayerIds,
      legend_type: legendPlacement,
      legend_classes_to_include: legendClassesToInclude,
      scale_type: scaleBarVisible
        ? scaleType
        : MapExportControlSettings.ScaleTypeEnum.None,
      scale_units: scaleUnits,
      title: titleVisible ? title.text : '',
    };

    if (logoVisible && logoKey) {
      request.logo_key = logoKey;
    }

    return request;
  },
);

export const getMapExportLayerStyles = createSelector(
  makeGetSymbologyStateGetter(),
  makeGetDivideByColumnGetter(),
  getMapExportLayerIds,
  makeGetVisibleVirtualIdsByLayerIdMap(),
  getMapExportLayerColumnKeys,
  // extra
  makeGetFromPropsSelector<ProjectId, 'projectId'>('projectId'),
  makeGetFromPropsSelector<ViewId, 'viewId'>('viewId'),
  makeGetFromPropsSelector<ScenarioId, 'scenarioId'>('scenarioId'),
  (
    getSymbologyState,
    getDivideByColumn,
    layerIds,
    virtualLayerIdMap,
    columnKeys,
    projectId,
    viewId,
  ): MapExportLayerStyle[] => {
    return getLayerStyles(
      projectId,
      viewId,
      layerIds,
      virtualLayerIdMap,
      columnKeys,
      getDivideByColumn,
      getSymbologyState,
    );
  },
);

export function makeGetMapExportRequestParams() {
  return createSelector(
    makeGetMapExportBaseMapStyleUrl(),
    getMapExportLayers,
    getMapExportLayerStyles,
    getMapExportOutputSettings,
    getMapExportControlSettings,
    getMapExportDisplayParams,
    getMapExportBaseMapFeatures,
    makeGetActiveViewId(),
    // extra
    makeGetFromPropsSelector<ScenarioId, 'scenarioId'>('scenarioId'),
    (
      baseMapUrl,
      mapExportLayers,
      mapExportLayerStyles,
      outputSettings,
      controlSettings,
      displaySettings,
      showBasemapFeatures,
      viewId,
    ) => {
      const mapLayerIds = mapExportLayers.map(({ layer }) => layer.full_path);
      const mapLayerColumnKeys = mapExportLayers.map(
        ({ columnKey }) => columnKey,
      );

      const request: MapExportRequest = {
        content_settings: {
          basemap: baseMapUrl,
          layers: mapLayerIds,
          layer_column_keys: mapLayerColumnKeys,
          show_feature_ids: showBasemapFeatures,
          layer_styles: mapExportLayerStyles,
          view_id: viewId,
        },
        control_settings: controlSettings,
        display_settings: displaySettings,
        output_settings: outputSettings,
      };
      return request;
    },
  );
}

function makeGetMapExportUIStatePropertySelector<
  K extends keyof MapExportUIState,
>(propertyKey: K) {
  return createSelector(
    getMapExportUIState,
    (mapExportState): MapExportUIState[K] => mapExportState[propertyKey],
  );
}

type ExportState = TaskState<TaskMessage, string>;

const getMapExportRequests = createSelector<any, any, ExportState>(
  getMapExport,
  mapexport => mapexport.requests as ExportState,
);

const getMapExportTaskIds = createSelector(
  getMapExportRequests,
  requests => requests.taskIds || (EMPTY_OBJECT as TaskIdsMap),
);

const getMapExportTasks = createSelector(
  getMapExportRequests,
  requests => requests.tasks,
);

function makeGetMapExportProjectTaskIds() {
  return createSelector(
    getMapExportTaskIds,
    makeGetFromPropsSelector<ProjectId, 'projectId'>('projectId'),
    (taskIds, projectId) => taskIds[projectId] || (EMPTY_ARRAY as string[]),
  );
}

export function makeGetMapExportProjectTasks() {
  return createSelector(
    makeGetMapExportProjectTaskIds(),
    getMapExportTasks,
    (taskIds, tasks) => taskIds.map(taskId => tasks[taskId]),
  );
}
