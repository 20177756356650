/**
 * UrbanFootprint App API
 * API for serving UrbanFootprint Application
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { BuiltFormPainterEmploymentSubCategoryWeight } from './builtFormPainterEmploymentSubCategoryWeight';

/**
 * Allocation values for type of employment.      Must have two of the three fields \"area\", \"emp\" and \"density\".      If area + density are present, emp will be calculated.     If emp + density are present area will be calculated.     If area + emp are present, density is not needed.
 */
export interface BuiltFormPainterEmploymentAllocation<> {
  /**
   * Amount of area to allocate.
   */
  area?: number;
  /**
   * Used to look up density to calculate area based on emp, or vice versa.
   */
  density?: BuiltFormPainterEmploymentAllocation.DensityEnum;
  /**
   * Amount of employees to allocate.
   */
  emp?: number;
  /**
   * Per subcategory weights; subcategory_weights must add up to 1.
   */
  subcategories?: Array<BuiltFormPainterEmploymentSubCategoryWeight>;
}
export namespace BuiltFormPainterEmploymentAllocation {
  export type DensityEnum = 'high' | 'mid' | 'low';
  export const DensityEnum = {
    High: 'high',
    Mid: 'mid',
    Low: 'low',
  } as const;
}
