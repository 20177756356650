import {
  CuratedColumnSymbology,
  CUSTOM_THEME,
  DEFAULT_OPACITY,
  DistributionTypes,
  InterpolationTypes,
  PaintColorTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/cityofmadison/dataset/madison_water_use_base': {
    residential_outdoor_use: [
      {
        type: SymbologyTypes.FILL,
        paint: {
          'fill-color': {
            property: 'residential_outdoor_use',
            type: PaintColorTypes.INTERVAL,
            stops: [
              { value: 0, color: '#909090' },
              { value: 1, color: '#d9ebff' },
              { value: 40, color: '#B3D8FF' },
              { value: 60, color: '#9FC6F0' },
              { value: 80, color: '#8BB5E1' },
              { value: 120, color: '#77A4D2' },
              { value: 160, color: '#6392C3' },
              { value: 200, color: '#5081B5' },
              { value: 250, color: '#3C70A6' },
              { value: 300, color: '#285E97' },
              { value: 350, color: '#144D88' },
              { value: 400, color: '#013C7A' },
            ],
          },
          'fill-opacity': DEFAULT_OPACITY,
        },
        display_hints: {
          scale: SymbologyScales.NUMERIC,
          distribution: DistributionTypes.DEFAULT,
          interpolation: InterpolationTypes.INTERVAL,
          theme: CUSTOM_THEME,
        },
      },
    ],
  },
};

export default symbologies;
