import { SET_DETAILS_BUILD_TAB } from 'uf/explore/ActionTypes';
import { ProjectId } from 'uf/projects';
import { ScenarioId } from 'uf/scenarios';

export function setDetailsBuildTabVisible(
  projectId: ProjectId,
  scenarioId: ScenarioId,
) {
  return {
    type: SET_DETAILS_BUILD_TAB,
    projectId,
    scenarioId,
  };
}
