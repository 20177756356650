import { EMPTY_OBJECT } from 'uf/base';
import { GridOptions } from 'uf/base/geo';
import {
  makeSetKeyedValueReducer,
  makeSetValueOnlyReducer,
} from 'uf/base/reducerFactories';
import {
  SET_FEATURE_IDS_FROM_SPLIT,
  SET_GRID_OPTIONS,
  SetFeatureIdsFromSplit,
} from 'uf/explore/ActionTypes';

export const featureIds = makeSetKeyedValueReducer<
  string[],
  SetFeatureIdsFromSplit
>(SET_FEATURE_IDS_FROM_SPLIT, ['layerId'], ['featureIds'], EMPTY_OBJECT);

const defaultGridOptions: GridOptions = {
  size: 1.0,
  unitKey: 'acres',
  angle: 0,
  offsetX: 0,
  offsetY: 0,
};

export const gridOptions = makeSetValueOnlyReducer(
  SET_GRID_OPTIONS,
  defaultGridOptions,
);
