import {
  FormGroup,
  Intent,
  ITooltipProps,
  NumericInput,
  Tooltip,
} from '@blueprintjs/core';
import { Icon } from '@mdi/react';
import classNames from 'classnames';
import _ from 'lodash';
import React, {
  CSSProperties,
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import warning from 'warning';

import { roundTo as baseRoundTo } from 'uf/base/formatting';
import colorStyles from 'uf/styles/colors.module.css';
import rootStyles from 'uf/styles/root.module.css';
import { tw } from 'uf/tailwindcss-classnames';
import { IconSizeNew } from 'uf/ui/base/icon';
import formStyles from 'uf/ui/forms/formStyles.module.css';

import styles from './NumericControl.module.css';

type ValidStates = 'success' | 'warning' | 'error';

const validationToIntent: Record<ValidStates, Intent> = {
  success: 'success',
  warning: 'warning',
  error: 'danger',
};

interface UncontrolledNumericControlProps {
  title?: string;
  /**
   * The value to display - required because leaving the control blank is too
   * complicated to track */
  inlineTitle?: boolean;
  value: number;
  /** clamps the value after the user clicks away.  must provide both min and max for this to work */
  clampValueOnBlur?: boolean;
  allowEmptyState?: boolean;
  validationState?: ValidStates;
  placeholder?: string;
  /** Error message to display in a tooltip */
  tooltipText?: string | JSX.Element;
  tooltipProps?: ITooltipProps;

  /** Whether or not the UI should "flash" when a new value appears. */
  flashOnUpdate?: boolean;

  /**
   * Callback to fire when the user presses any arbitrary key inside the NumericControl.
   * Useful for implementing Submit on Enter Key functionality.
   */
  onKeyUp?: (
    controlKey: string,
    event: React.KeyboardEvent<HTMLInputElement>,
  ) => void;
  onKeyDown?: (
    controlKey: string,
    event: React.KeyboardEvent<HTMLInputElement>,
  ) => void;

  /** editing is currently disabled, dims the input */
  disabled?: boolean;

  /** User is not allowed to edit it */
  readOnly?: boolean;

  help?: string;
  /** The tag to show to the right side of the input.  If values are supplied for tags, this should
   * be the currently selected tag.  TODO: if this value is not supplied when there are tags, treat this component as
   * uncontrolled. */
  rightElement?: JSX.Element;
  /** A path to an mdi svg icon */
  leftIconPath?: string;
  style?: CSSProperties;
  /**
   * if the NumericControl should fill the width of its container
   */
  fill?: boolean;
  className?: string;

  /** Numer of decimal places. Defaults to 1 decimal place. Set to null to disable rounding. */
  precision?: number | null;
  min?: number;
  max?: number;
  step?: 'any' | number;
}

interface ControlledNumericControlProps
  extends UncontrolledNumericControlProps {
  // TODO: rename this to 'name' to match blueprint's prop
  controlKey: string;
  /**
   * Callback to fire when the numeric value changes.
   * Useful for validation or looking up stuff as the user types.
   */
  onChange: (controlKey: string, value: number) => void;
}

type Props = UncontrolledNumericControlProps | ControlledNumericControlProps;

/**
 * A helper class to standardize use of validation, help, etc across
 * react-bootstrap form controls.
 */
const NumericControl: FunctionComponent<Props> = props => {
  const {
    title,
    inlineTitle,
    tooltipText,
    tooltipProps,
    validationState,
    placeholder,
    help,
    rightElement,
    leftIconPath,
    style,
    className,
    disabled,
    readOnly,
    min,
    max,
    step = 'any',
    value,
    clampValueOnBlur,
    precision = 1,
    allowEmptyState,
    flashOnUpdate,
    onKeyDown: onKeyDownFromProps,
    onKeyUp: onKeyUpFromProps,
  } = props;

  const { controlKey, onChange } = props as ControlledNumericControlProps;

  const [lastValidValue, setLastValidValue] = useState(
    roundTo(props.value, props.precision),
  );
  const [editing, setEditing] = useState(false);
  const [currentStringValue, setCurrentStringValue] = useState(
    Number.isFinite(lastValidValue) ? `${lastValidValue}` : undefined,
  );
  const [flashing, setFlashing] = useState(false);

  useEffect(() => {
    const currentRoundedValue = roundTo(Number(currentStringValue), precision);

    const nextRoundedValue = roundTo(value, precision);

    if (flashOnUpdate && !isEquivalent(currentRoundedValue, nextRoundedValue)) {
      setFlashing(true);
    }
  }, [currentStringValue, flashOnUpdate, precision, value]);

  const shouldClamp = useCallback(() => {
    return (
      _.isNumber(min) &&
      !Number.isNaN(min) &&
      _.isNumber(max) &&
      !Number.isNaN(max)
    );
  }, [max, min]);

  const onKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (!controlKey) {
        return;
      }
      if (onKeyDownFromProps) {
        onKeyDownFromProps(controlKey, event);
      }
    },
    [controlKey, onKeyDownFromProps],
  );

  const onKeyUp = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (!controlKey) {
        return;
      }

      if (onKeyUpFromProps) {
        onKeyUpFromProps(controlKey, event);
      }
    },
    [controlKey, onKeyUpFromProps],
  );

  const onValueChange = useCallback(
    (
      newValue: number,
      newStringValue: string,
      unusedInputElement: HTMLInputElement,
    ) => {
      if (!('controlKey' in props)) {
        return;
      }

      let validValue = Number.isFinite(newValue) ? newValue : lastValidValue;

      if (shouldClamp() && clampValueOnBlur) {
        validValue = validValue > max ? max : validValue;
        validValue = validValue < min ? min : validValue;
      }

      setLastValidValue(validValue);
      setCurrentStringValue(newStringValue);

      if (onChange) {
        if (allowEmptyState && newStringValue === '') {
          onChange(controlKey, null);
          return;
        }
        // don't call onChange handler while user is in bad state
        if (!Number.isFinite(newValue)) {
          return;
        }

        validValue = newValue;
        if (
          shouldClamp() &&
          clampValueOnBlur &&
          (newValue > max || newValue < min)
        ) {
          validValue = validValue > max ? max : validValue;
          validValue = validValue < min ? min : validValue;
        }

        onChange(controlKey, validValue);
      }
    },
    [
      props,
      lastValidValue,
      controlKey,
      onChange,
      allowEmptyState,
      min,
      max,
      clampValueOnBlur,
      shouldClamp,
    ],
  );

  const onFlashEnd = useCallback(event => {
    if (event.animationName === formStyles.flashAnimation) {
      setFlashing(false);
    }
  }, []);

  const onBlur = useCallback(() => {
    setEditing(false);
  }, []);

  const onFocus = useCallback(() => {
    const last = roundTo(value, precision);
    setEditing(true);
    setLastValidValue(last);
    setCurrentStringValue(Number.isFinite(last) ? `${last}` : '');
  }, [precision, value]);

  let invalidFeedback;
  if (__DEVELOPMENT__) {
    // help debug UI that is effectively read-only
    const hasInvalidHandlers =
      !disabled &&
      !readOnly &&
      ((onChange && !controlKey) || (!onChange && controlKey));

    invalidFeedback = hasInvalidHandlers && (
      <div className="text-danger">Invalid change handler</div>
    );
  }

  let effectiveValidationState = validationState;
  if (!effectiveValidationState) {
    effectiveValidationState = tooltipText ? 'error' : null;
  }

  let displayValue = editing
    ? currentStringValue
    : `${roundTo(value, precision)}`;
  if (!Number.isFinite(value) && !editing) {
    displayValue = allowEmptyState ? '' : '0';
  }

  let intent: Intent;
  if (validationState) {
    intent = validationToIntent[validationState];
  }

  const controlClassName = classNames(formStyles.ellipsis, rootStyles.shrink0, {
    [colorStyles.fail]: !!effectiveValidationState,
    [formStyles.flashing]: flashing,
  });

  let leftIcon: JSX.Element;
  if (leftIconPath) {
    leftIcon = (
      <span className={classNames('bp4-icon', styles.leftIcon)}>
        <Icon size={IconSizeNew.SMALL} path={leftIconPath} />
      </span>
    );
  }

  // clampValueOnBlur true and shouldClamp false should make a warning.  have to negate the whole
  // statement in order for this logic to work. i.e. can't just have: !clampValueOnBlur &&
  // shouldClamp instead since a true value of clampValueOnBlur will allways trigger a warning.
  warning(
    !(clampValueOnBlur && !shouldClamp()),
    'clampValueOnBlur requires values for both min and max',
  );

  return (
    <FormGroup
      style={style}
      className={classNames(
        className,
        styles.numericControl,
        rootStyles.fitWidth,
        rootStyles.shrink,
      )}
      label={title}
      inline={inlineTitle}>
      <Tooltip
        disabled={!tooltipText}
        targetClassName={rootStyles.fitWidth}
        popoverClassName={tw('tw-max-w-sm')}
        content={tooltipText}
        {...tooltipProps}>
        <NumericInput
          data-lpignore="true"
          autoFocus={false}
          intent={intent}
          onBlur={onBlur}
          onFocus={onFocus}
          name={controlKey}
          value={displayValue}
          disabled={disabled}
          clampValueOnBlur={shouldClamp() && clampValueOnBlur}
          readOnly={readOnly}
          onAnimationEnd={onFlashEnd}
          className={controlClassName}
          placeholder={placeholder}
          min={min}
          max={max}
          step={step}
          onKeyUp={onKeyUp}
          onKeyDown={onKeyDown}
          onValueChange={onValueChange}
          buttonPosition="none"
          leftIcon={leftIcon}
          rightElement={rightElement}
        />
      </Tooltip>
      {invalidFeedback}
      {help && help}
    </FormGroup>
  );
};

export default NumericControl;

function isEquivalent(a, b) {
  if (a === b) {
    return true;
  }

  // No two NaNs are equal to each other, but for our purposes they
  // should behave as equal.
  return Number.isNaN(a) && Number.isNaN(b);
}

function roundTo(value: number, precision: number | null) {
  if (precision === null) {
    return value;
  }
  return baseRoundTo(value, precision);
}
