import { makeSetPropValueAction } from 'uf/base/actionHelpers';
import {
  UPDATE_PROJECT_PAINTING_STATUS,
  UPDATE_SCENARIO_PAINTING_STATUS,
} from 'uf/painting/ActionTypes';

export const updateScenarioPaintingStatus = makeSetPropValueAction<{
  type: string;
}>(UPDATE_SCENARIO_PAINTING_STATUS);
export const updateProjectPaintingStatus = makeSetPropValueAction<{
  type: string;
}>(UPDATE_PROJECT_PAINTING_STATUS);
