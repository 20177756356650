import { InterpolatorFactory } from 'd3-scale';
import _ from 'lodash';
import warning from 'warning';

import {
  ProjectModuleParams,
  ReportCanvasSourceSpec,
  ReportOutputGroupSpec,
  ReportSeriesSpec,
  ReportSpec,
} from 'uf-api/model/models';
import { assertNever } from 'uf/base/never';
import { ColumnKey } from 'uf/layers';
import { ProjectId } from 'uf/projects';
import { ScenarioId } from 'uf/scenarios';

export function makeScenarioReportKey(
  projectId: ProjectId,
  scenarioId: ScenarioId,
  reportSpec: ReportSpec,
) {
  const seriesKey = reportSpec.output_groups.map(({ key }) => key).join('|');
  return `${projectId}:${scenarioId}:${reportSpec.key}:${seriesKey}`;
}

interface LegacyChartSeriesBase {
  key: string;
  layerKey?: string;
  columnKey: ColumnKey;
  stat?: ReportSeriesSpec.AggregateByEnum;
  color?: string;
}
// LEGACY types
export interface LegacyAnalysisChartSeries extends LegacyChartSeriesBase {
  source: 'analysis';
  /**
   * The analysis module key, e.g. 'water_demand'
   */
  sourceKey: string;
}

interface LegacyCanvasChartSeries extends LegacyChartSeriesBase {
  key: string;
  source: 'canvas';
  /** Which canvas within the scenario */
  sourceKey: ReportCanvasSourceSpec.LayerKeyEnum;
}

export type LegacyChartSeries =
  | LegacyCanvasChartSeries
  | LegacyAnalysisChartSeries;

export interface LegacyChart {
  key: string;
  name: string;
  /**
   * Descriptions give additional detail about
   * what's been seen in a chart, tend to be longer
   * and mor everbose that subtitle.
   */
  description?: string;
  /**
   * Subtitles are structured to be shorter single
   * line elucidations of chart titles in the reporting mode.
   */
  subtitle?: string;
  hideZeroValues?: boolean;
  readonly series: LegacyChartSeries[];
  units?: string;
  showLegend?: boolean;
  showFullLabel?: boolean;
  emptyChartContent?: string;
}

export interface LegacyReport {
  key: string;
  name: string;
  colorSpec?: ChartColorSpec;
  readonly charts: LegacyChart[];
}

export interface ChartColorSpec {
  primaryColor: string;
  interpolateFromColor: string;
  interpolateToColor: string;
  interpolationMethod?: InterpolatorFactory<string, string>;
}

/**
 * This is to support the migration from the "old" chart spec format to
 * the "new" report spec format. Once we've completely switched over,
 * we'll hand-convert the existing specs and get rid of this function.
 */
export function convertOldSpec(key: string, specs: LegacyReport[]): ReportSpec {
  if (!key) {
    return null;
  }
  const oldSpec = specs.find(s => s.key === key);
  if (!oldSpec) {
    return null;
  }
  return convertLegacyReport(oldSpec);
}

export function convertLegacyReport(oldSpec: LegacyReport) {
  const oldSeries = _.flatten(oldSpec.charts.map(chart => chart.series));
  const series = oldSeries.map(convertLegacyChartSeries);
  const outputGroups = oldSpec.charts.map(convertLegacyChart);
  return {
    series,
    key: oldSpec.key,
    name: oldSpec.name,
    output_groups: outputGroups,
  };
}
/**
 * Hack to insert the point of interest labels from the analysis parameters
 * into the chart titles. This is to support "walk access to xyz", where users
 * can configure the layer id and name for a custom point of interest to run
 * acceessibility against. Initially done to support "walk access to grocery
 * stores" for quick analysis during Covid-19.
 */
export function insertAccessibilityPOILabelsLegacyReport(
  accessibilityModuleParams: ProjectModuleParams,
  spec: LegacyReport,
): LegacyReport {
  const moduleKey =
    accessibilityModuleParams?.scenario_module_params?.[0]?.module_params
      .module_key;
  if (
    !accessibilityModuleParams ||
    (moduleKey !== 'walk_accessibility' &&
      moduleKey !== 'transit_accessibility')
  ) {
    return spec;
  }

  const {
    poi_1_name: poi1Label,
    poi_2_name: poi2Label,
    poi_3_name: poi3Label,
  } = accessibilityModuleParams.scenario_module_params[0].module_params
    .parameters;

  let updatedCharts = spec?.charts;
  if (spec?.charts) {
    updatedCharts = updatedCharts.map(outputChart => {
      if (outputChart.key === 'pct_access_poi_1') {
        return {
          ...outputChart,
          name: `Custom POI #1: ${poi1Label} Access`,
        };
      }
      if (outputChart.key === 'pct_access_poi_2') {
        return {
          ...outputChart,
          name: `Custom POI #2: ${poi2Label} Access`,
        };
      }
      if (outputChart.key === 'pct_access_poi_3') {
        return {
          ...outputChart,
          name: `Custom POI #3: ${poi3Label} Access`,
        };
      }

      return outputChart;
    });
  }

  const updatedSpec: LegacyReport = {
    ...spec,
    charts: updatedCharts,
  };
  return updatedSpec;
}

/**
 * Hack to insert the point of interest labels from the analysis parameters
 * into the chart titles. This is to support "walk access to xyz", where users
 * can configure the layer id and name for a custom point of interest to run
 * acceessibility against. Initially done to support "walk access to grocery
 * stores" for quick analysis during Covid-19.
 */
export function insertAccessibilityPOILabels(
  accessibilityModuleParams: ProjectModuleParams,
  spec: ReportSpec,
): ReportSpec {
  const moduleParams =
    accessibilityModuleParams?.scenario_module_params[0]?.module_params;
  const moduleKey = moduleParams?.module_key;
  const parameters = moduleParams?.parameters;
  if (
    !accessibilityModuleParams ||
    !parameters ||
    (moduleKey !== 'walk_accessibility' &&
      moduleKey !== 'transit_accessibility')
  ) {
    return spec;
  }

  const {
    poi_1_name: poi1Label,
    poi_2_name: poi2Label,
    poi_3_name: poi3Label,
  } = parameters;

  let updatedOutputGroups = spec?.output_groups;
  if (spec?.output_groups) {
    updatedOutputGroups = updatedOutputGroups.map(outputGroup => {
      if (outputGroup.key === 'pct_access_poi_1') {
        return {
          ...outputGroup,
          name: `Custom POI #1: ${poi1Label} Access`,
        };
      }
      if (outputGroup.key === 'pct_access_poi_2') {
        return {
          ...outputGroup,
          name: `Custom POI #2: ${poi2Label} Access`,
        };
      }
      if (outputGroup.key === 'pct_access_poi_3') {
        return {
          ...outputGroup,
          name: `Custom POI #3: ${poi3Label} Access`,
        };
      }

      return outputGroup;
    });
  }

  const updatedSpec: ReportSpec = {
    ...spec,
    output_groups: updatedOutputGroups,
  };
  return updatedSpec;
}

function convertLegacyChartSeries(
  oldSeries: LegacyChartSeries,
): ReportSeriesSpec {
  let seriesResult: ReportSeriesSpec;
  switch (oldSeries.source) {
    case 'canvas': {
      seriesResult = {
        key: oldSeries.key,
        canvas_source: {
          layer_key: oldSeries.sourceKey,
          column_key: oldSeries.columnKey,
        },
      };
      break;
    }
    case 'analysis': {
      seriesResult = {
        key: oldSeries.key,
        analysis_source: {
          analysis_module: oldSeries.sourceKey,
          layer_key: oldSeries.layerKey,
          column_key: oldSeries.columnKey,
        },
      };
      break;
    }
    default:
      assertNever(oldSeries);
  }

  if (oldSeries.stat) {
    seriesResult.aggregate_by = oldSeries.stat;
  }

  return seriesResult;
}

function convertLegacyChart(oldChart: LegacyChart): ReportOutputGroupSpec {
  const { key, name, description, series } = oldChart;
  warning(name !== description, `duplicate name for ${key}: ${name}`);
  const result: ReportOutputGroupSpec = {
    key,
    name,
    series_keys: series.map(s => s.key),
  };
  if (description) {
    result.description = description;
  }
  return result;
}
