/**
 * This reducer keeps track of the current layers and their states within the app.
 *
 * This includes:
 * - which layer is "active"
 * - which layers are "visible"
 * - Any current filters/queries used in each layer
 */
import { combineReducers } from 'redux';

import { makeSetValueReducer } from 'uf/base/reducerFactories';
import {
  SET_SCENARIO_ORDER,
  SetScenarioOrderAction,
} from 'uf/explore/ActionTypes';

const scenarioOrdersByProject = makeSetValueReducer<
  string[],
  SetScenarioOrderAction
>(SET_SCENARIO_ORDER);

export default combineReducers({
  order: scenarioOrdersByProject,
});
