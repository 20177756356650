import { t } from 'ttag';

import {
  MapExportControlSettings,
  MapExportDisplaySettings,
  MapExportOutputSettings,
} from 'uf-api/model/models';
import { Dimensions } from 'uf/base/map';

/**
 * Special key just for the UF logo.
 */
export const UF_LOGO_KEY = 'uf:logo';

// Map our notion of units to mapbox's notion of units
export enum SCALE_TYPE_MAP {
  us = 'imperial',
  metric = 'metric',
}

export function getExportFormatLabel(
  exportFormat: MapExportOutputSettings.FormatEnum,
): string {
  switch (exportFormat) {
    case MapExportOutputSettings.FormatEnum.Png:
      return t`PNG`;
    case MapExportOutputSettings.FormatEnum.Svg:
      return t`SVG`;
    default:
      console.warn(`Unexpected export format: ${exportFormat}`);
      return exportFormat;
  }
}

const hiddenLegendTypes: MapExportControlSettings.LegendTypeEnum[] = [
  MapExportControlSettings.LegendTypeEnum.None,
  MapExportControlSettings.LegendTypeEnum.OverlayUpperRight,
];

/* Legend Placements */
export function isMapVisible(
  legendPlacement: MapExportControlSettings.LegendTypeEnum,
): boolean {
  return hiddenLegendTypes.includes(legendPlacement);
}

export function isLegendSeparate(
  legendPlacement: MapExportControlSettings.LegendTypeEnum,
): boolean {
  return MapExportControlSettings.LegendTypeEnum.Separate === legendPlacement;
}

const LegendTypeNames = {
  [MapExportControlSettings.LegendTypeEnum.None]: t`No legend`,
  [MapExportControlSettings.LegendTypeEnum.Separate]: t`Separate file`,
  [MapExportControlSettings.LegendTypeEnum.OverlayUpperRight]: t`On page`,
};

export function getLegendPlacementLabel(
  legendPlacement: MapExportControlSettings.LegendTypeEnum,
): string {
  return LegendTypeNames[legendPlacement];
}

/* Page Orientation */
const OrientationNames = {
  [MapExportDisplaySettings.OrientationEnum.Landscape]: t`Landscape`,
  [MapExportDisplaySettings.OrientationEnum.Portrait]: t`Portrait`,
};

export function getPageOrientationLabel(
  pageOrientation: MapExportDisplaySettings.OrientationEnum,
): string {
  return OrientationNames[pageOrientation];
}

/**
 * Predefined scales (though any actual numeric scale is allowed internally)
 */
export enum Scale {
  OneTo50000 = 50000,
  OneTo25000 = 25000,
  OneTo10000 = 10000,
  OneTo5000 = 5000,
  OneTo2500 = 2500,
  OneTo1000 = 1000,
  OneTo500 = 500,
}

/**
 * Get dimensions, in pixels, given physical dimensions and resolution.
 */
export function getDimensions(
  physicalDimensions: Dimensions,
  resolution: number,
) {
  return {
    width: physicalDimensions.width * resolution,
    height: physicalDimensions.height * resolution,
  };
}

/**
 * Get physical dimensions, in inches, given pixel dimensions and resolution.
 */
export function getPhysicalDimensions(
  dimensions: Dimensions,
  resolution: number,
): Dimensions {
  return {
    width: dimensions.width / resolution,
    height: dimensions.height / resolution,
  };
}
