import { t } from 'ttag';

import { LegacyReport } from 'uf/reporting/index';

import { tncChartsSharedAgriculture } from './tnc_conservation_agriculture';
import { tncChartsSharedCarbon } from './tnc_conservation_carbon';
import { tncChartsSharedGen } from './tnc_conservation_general';
import {
  tncChartsHabitatTES,
  tncChartsSharedHabitat,
} from './tnc_conservation_habitat';
import { tncChartsSharedWater } from './tnc_conservation_water';

export const tncAnalysisCharts: LegacyReport = Object.freeze({
  key: 'tnc_conservation',
  name: t`Conservation - All Results`,
  charts: [
    ...tncChartsSharedGen,
    ...tncChartsSharedCarbon,
    ...tncChartsSharedWater,
    ...tncChartsSharedHabitat,
    ...tncChartsHabitatTES,
    ...tncChartsSharedAgriculture,
  ],
});
