import { AnyAction } from 'redux';
import { ActionsObservable, Epic, ofType } from 'redux-observable';
import {
  empty as observableEmpty,
  merge as observableMerge,
  of as observableOf,
  zip as observableZip,
} from 'rxjs';
import { filter, map, mergeMap, take } from 'rxjs/operators';
import warning from 'warning';

import {
  createBuildingActionTypes,
  createBuildingTypeActionTypes,
  createPlaceTypeActionTypes,
  deleteBuildingActionTypes,
  deleteBuildingTypeActionTypes,
  deletePlaceTypeActionTypes,
  updateBuildingActionTypes,
  updateBuildingTypeActionTypes,
  updateBuildingTypeInfosActionTypes,
  updateLibraryDefaultsActionTypes,
  updateLibraryVariablesActionTypes,
  updatePlaceTypeActionTypes,
  updatePlaceTypeInfosActionTypes,
} from 'uf-api/api/builtforms.service';
import { ScopeTypes } from 'uf-ws/WebsocketActions';
import { APP_INITIALIZED } from 'uf/app/ActionTypes';
import { getActiveProjectId } from 'uf/app/selectors';
import { combineEpics } from 'uf/base/epics';
import {
  clearBuildingType,
  clearLibraryBuildingTypes,
  clearLibraryPlaceTypes,
  clearPlaceType,
  ensureLibraryBuiltForms,
  loadLibrary,
  loadLibraryBuildings,
  loadLibraryBuildingTypes,
  loadLibraryPlaceTypes,
} from 'uf/builtforms/actions/data';
import {
  UpdateBuildingTypeInfosSuccessAction,
  UpdatePlaceTypeInfosSuccessAction,
} from 'uf/builtforms/ActionTypes';
import { BUILT_FORM_TYPES } from 'uf/builtforms/index';
import {
  BaseScenarioPaintTaskMessageBase,
  ScenarioPaintTaskMessageBase,
} from 'uf/painting/tasks';
import { makeGetProjectBuiltFormsLibraryId } from 'uf/projects/selectors';
import { UFState } from 'uf/state';
import { NotificationAction } from 'uf/tasks/ActionTypes';
import { ofNotificationType } from 'uf/tasks/observables';
import { TaskStatuses } from 'uf/tasks/TaskStatuses';
import { TaskTypes } from 'uf/tasks/TaskTypes';

function reloadBuildingListAfterWrite(action$: ActionsObservable<AnyAction>) {
  return action$
    .ofType(
      createBuildingActionTypes.SUCCESS,
      updateBuildingActionTypes.SUCCESS,
      deleteBuildingActionTypes.SUCCESS,
    )
    .pipe(
      map(({ params }) => {
        const { libraryId } = params;
        return loadLibraryBuildings(libraryId);
      }),
    );
}

function reloadBuildingTypeListAfterWrite(
  action$: ActionsObservable<AnyAction>,
) {
  return action$
    .ofType(
      createBuildingTypeActionTypes.SUCCESS,
      updateBuildingTypeActionTypes.SUCCESS,
      deleteBuildingTypeActionTypes.SUCCESS,
    )
    .pipe(
      map(({ params }) => {
        const { libraryId } = params;
        return loadLibraryBuildingTypes(libraryId);
      }),
    );
}

function reloadPlaceTypeListAfterWrite(action$: ActionsObservable<AnyAction>) {
  return action$
    .ofType(
      createPlaceTypeActionTypes.SUCCESS,
      updatePlaceTypeActionTypes.SUCCESS,
      deletePlaceTypeActionTypes.SUCCESS,
    )
    .pipe(
      map(({ params }) => {
        const { libraryId } = params;
        return loadLibraryPlaceTypes(libraryId);
      }),
    );
}

export function reloadLibraryAfterSave(action$: ActionsObservable<AnyAction>) {
  return observableZip(
    action$.pipe(ofType(updateLibraryDefaultsActionTypes.SUCCESS), take(1)),
    action$.pipe(ofType(updateLibraryVariablesActionTypes.SUCCESS), take(1)),
  ).pipe(
    map(([defaultsAction, variablesAction]) => {
      warning(
        defaultsAction.key === variablesAction.key,
        'library keys do not match for defaults and variables',
      );
      const { key: libraryId } = defaultsAction;
      return loadLibrary(libraryId);
    }),
  );
}

/**
 * We need to clear the builtform state after every paint to force reloads of the used and valid
 * flags to enable/disable the appropriate ui in the builtform editor.
 */
export const clearBuiltFormsAfterAsyncPaint: Epic<
  NotificationAction,
  any,
  UFState
> = (action$, state$) => {
  const getProjectBuiltFormsLibraryId = makeGetProjectBuiltFormsLibraryId();
  return observableMerge(
    action$.pipe(
      ofNotificationType<ScenarioPaintTaskMessageBase>(
        TaskTypes.TASK_TYPE_SCENARIO_PAINT,
        TaskStatuses.DONE,
      ),
    ),
    action$.pipe(
      ofNotificationType<BaseScenarioPaintTaskMessageBase>(
        TaskTypes.TASK_TYPE_BASE_SCENARIO_PAINT,
        TaskStatuses.DONE,
      ),
    ),
  ).pipe(
    map(action => {
      const projectId =
        action.result.scope_type === ScopeTypes.PROJECT
          ? action.result.scope
          : null;
      return projectId;
    }),
    filter(projectId => !!projectId),
    map(projectId => {
      return getProjectBuiltFormsLibraryId(state$.value, { projectId });
    }),
    filter(libraryId => !!libraryId),
    mergeMap(libraryId => {
      return observableOf(
        loadLibraryBuildingTypes(libraryId),
        loadLibraryPlaceTypes(libraryId),
      );
    }),
  );
};

/**
 * Clear out any state that's holding on to a building type that was just
 * updated. This includes not just the building type infos list, but the
 * individual building types as well.
 *
 * This helps keep the built form editor and symbology in sync as they both
 * make changes to the built form library.
 */
export const clearBuildingTypesOnBuildingTypeInfosChange: Epic<
  UpdateBuildingTypeInfosSuccessAction,
  any
> = (action$, state$) => {
  return action$.pipe(
    ofType(updateBuildingTypeInfosActionTypes.SUCCESS),
    mergeMap(successAction => {
      const {
        actionParams: { libraryId, buildingTypeInfos },
      } = successAction;

      const clearActions = [
        observableOf(clearLibraryBuildingTypes(libraryId)),
        ...buildingTypeInfos.map(buildingTypeInfo =>
          observableOf(clearBuildingType(libraryId, buildingTypeInfo.key)),
        ),
      ];

      return observableMerge(...clearActions);
    }),
  );
};

/**
 * Clear out any state that's holding on to a place type that was just
 * updated. This includes not just the place type infos list, but the
 * individual building types as well.
 *
 * This helps keep the built form editor and symbology in sync as they both
 * make changes to the built form library.
 */
export const clearPlaceTypesOnPlaceTypeInfosChange: Epic<
  UpdatePlaceTypeInfosSuccessAction,
  any
> = action$ => {
  return action$.ofType(updatePlaceTypeInfosActionTypes.SUCCESS).pipe(
    mergeMap(successAction => {
      const {
        actionParams: { libraryId, placeTypeInfos },
      } = successAction;

      const clearActions = [
        observableOf(clearLibraryPlaceTypes(libraryId)),
        ...placeTypeInfos.map(placeTypeInfo =>
          observableOf(clearPlaceType(libraryId, placeTypeInfo.key)),
        ),
      ];

      return observableMerge(...clearActions);
    }),
  );
};

/**
 * Pre-load the builtforms list so the builtforms UI is responsive.
 *
 * TODO: Remove when loading builtform lists is faster.
 */
const precacheBuiltformLists: Epic<any, any> = (action$, state$) => {
  const getProjectBuiltformsLibraryId = makeGetProjectBuiltFormsLibraryId();
  return action$.pipe(
    ofType(APP_INITIALIZED),
    filter(() => __CLIENT__ || __TESTING__),
    mergeMap(() => {
      const projectId = getActiveProjectId(state$.value);
      const libraryId = getProjectBuiltformsLibraryId(state$.value, {
        projectId,
      });
      if (!libraryId) {
        return observableEmpty();
      }

      return observableOf(
        ensureLibraryBuiltForms(libraryId, BUILT_FORM_TYPES.BUILDING),
        ensureLibraryBuiltForms(libraryId, BUILT_FORM_TYPES.BUILDING_TYPE),
        ensureLibraryBuiltForms(libraryId, BUILT_FORM_TYPES.PLACE_TYPE),
      );
    }),
  );
};

export default combineEpics(
  {
    clearBuiltFormsAfterAsyncPaint,
    clearBuildingTypesOnBuildingTypeInfosChange,
    clearPlaceTypesOnPlaceTypeInfosChange,
    reloadBuildingListAfterWrite,
    reloadBuildingTypeListAfterWrite,
    reloadPlaceTypeListAfterWrite,
    reloadLibraryAfterSave,
    precacheBuiltformLists,
  },
  'builtforms',
);
