import {
  CuratedColumnSymbology,
  InterpolationTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/public/dataset/city_of_san_jose_general_plan_san_jose': {
    gpdesignat: [
      {
        display_hints: {
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          scale: SymbologyScales.CATEGORICAL,
          theme: 'custom',
        },
        paint: {
          'fill-color': {
            default: '#9edae5',
            property: 'gpdesignat',
            stops: [
              {
                color: '#808900',
                value: 'Agriculture',
              },
              {
                color: '#808080',
                value: 'CD',
              },
              {
                color: '#808080',
                value: 'CIC',
              },
              {
                color: '#fda1ff',
                value: 'Combined Industrial/Commercial',
              },
              {
                color: '#f44e3b',
                value: 'Commercial Downtown',
              },
              {
                color: '#f44e3b',
                value: 'Downtown',
              },
              {
                color: '#d62728',
                value: 'DT',
              },
              {
                color: '#aea1ff',
                value: 'Heavy Industrial',
              },
              {
                color: '#9467bd',
                value: 'Industrial Park',
              },
              {
                color: '#aea1ff',
                value: 'LI',
              },
              {
                color: '#aea1ff',
                value: 'Light Industrial',
              },
              {
                color: '#a4dd00',
                value: 'Lower Hillside',
              },
              {
                color: '#ab149e',
                value: 'Mixed Use Commercial',
              },
              {
                color: '#fcc400',
                value: 'Mixed Use Neighborhood',
              },
              {
                color: '#fda1ff',
                value: 'MUC',
              },
              {
                color: '#999999',
                value: 'MUN',
              },
              {
                color: '#808080',
                value: 'NCC',
              },
              {
                color: '#fda1ff',
                value: 'Neighborhood/Community Commercial',
              },
              {
                color: '#68bc00',
                value: 'Open Hillside',
              },
              {
                color: '#194d33',
                value: 'Open Space, Parklands and Habitat',
              },
              {
                color: '#808080',
                value: 'OSPH',
              },
              {
                color: '#808080',
                value: 'PQP',
              },
              {
                color: '#ff7f0e',
                value: 'Private Recreation and Open Space',
              },
              {
                color: '#ffbb78',
                value: 'PROS',
              },
              {
                color: '#009ce0',
                value: 'Public/Quasi-Public',
              },
              {
                color: '#fda1ff',
                value: 'Regional Commercial',
              },
              {
                color: '#fcdc00',
                value: 'Residential Neighborhood',
              },
              {
                color: '#fcdc00',
                value: 'RN',
              },
              {
                color: '#fcdc00',
                value: 'Rural Residential',
              },
              {
                color: '#e27300',
                value: 'TR',
              },
              {
                color: '#fda1ff',
                value: 'Transit Employment Center',
              },
              {
                color: '#e27300',
                value: 'Transit Residential',
              },
              {
                color: '#808080',
                value: 'Transportation and Utilities',
              },
              {
                color: '#e27300',
                value: 'UR',
              },
              {
                color: '#c45100',
                value: 'Urban Residential',
              },
              {
                color: '#e27300',
                value: 'Urban Village',
              },
              {
                color: '#e27300',
                value: 'UV',
              },
              {
                color: '#808080',
                value: 'UVC',
              },
              {
                color: '#808080',
                value: 'x',
              },
            ],
            type: 'categorical',
          },
          'fill-opacity': 0.9,
        },
        type: SymbologyTypes.FILL,
      },
      {
        display_hints: {
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          scale: SymbologyScales.CATEGORICAL,
          theme: 'custom',
        },
        paint: {
          'line-color': '#999999',
          'line-opacity': 0.9,
          'line-width': 0.5,
        },
        type: SymbologyTypes.LINE,
      },
    ],
  },
};

export default symbologies;
