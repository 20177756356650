import { combineEpics } from 'uf/base/epics';
import { ROUTE_EXPLORE, ROUTE_EXPLORE_NEW } from 'uf/explore/ActionTypes';
import { makeAddRootQueryEpic } from 'uf/routing/query';

export const addRootQueriesEpic = makeAddRootQueryEpic(
  ROUTE_EXPLORE,
  ROUTE_EXPLORE_NEW,
);

export default combineEpics(
  {
    addRootQueriesEpic,
  },
  'exploreRoutingEpics',
);
