import { Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { LayerMetadata } from 'uf-api/model/models';
import { parseFullPath } from 'uf/base/dataset';
import { normalizeError } from 'uf/base/swagger';
import { SwaggerClient } from 'uf/base/xhr';
import { ClientOperation, dispatchAsyncAction } from 'uf/data/loader';
import { LayerId } from 'uf/layers';
import {
  Expression,
  getFilterClause,
  getLayerDataApiParams,
  LayerDataParams,
} from 'uf/layers/filters';
import { searchActionTypes } from 'uf/search/ActionTypes';
import { translateDataToItems } from 'uf/search/adapters';

import { LayerDataResult } from './index';

// remove this when we have more actions.
export function beginSearch(
  layerId: LayerId,
  params: LayerDataParams = {},
  layerMetadata: LayerMetadata,
): ThunkAction<Promise<LayerDataResult>, any, any, any> {
  const { key, apiParams } = getLayerDataApiParams(layerId, params);
  const searchKey = `search:${key}`;
  const uniqueKeys = layerMetadata ? layerMetadata.unique_keys : null;
  const columnKeys = layerMetadata?.columns?.map(column => column.key);
  let sortColumn, sortDirection;
  // Make sure request isn't sent with invalid sort values
  if (
    params?.sortParams &&
    Array.isArray(columnKeys) &&
    columnKeys.includes(params.sortParams.sortColumn)
  ) {
    sortColumn = params?.sortParams?.sortColumn;
    sortDirection = params?.sortParams?.sortDirection;
  }
  const limit = params.limit || 100;
  const { filters: query, version } = apiParams;
  return async (dispatch: Dispatch) => {
    const searchResult = await dispatch(
      dispatchAsyncAction(
        searchActionTypes,
        fetch(
          layerId,
          query,
          limit,
          uniqueKeys,
          version,
          sortColumn,
          sortDirection,
        ),
        { datasetId: layerId, query, searchKey },
      ),
    );
    return {
      searchKey,
      ...searchResult,
    };
  };
}

// TODO: Deal with pagination here.
function fetch(
  datasetId: string,
  query: Expression[],
  limit: number,
  uniqueKeys: string[],
  version: string,
  sortColumn?: string,
  sortDirection?: 'asc' | 'desc',
): ClientOperation<LayerDataResult> {
  return (client: SwaggerClient) => {
    const params = makeLayerDataParameters(
      datasetId,
      query,
      limit,
      version,
      sortColumn,
      sortDirection,
    );
    const fetchResult = client
      .clientReady()
      .then(swagger => swagger.apis.layer.get_layer_data(params))
      .then(({ obj }) => ({
        items: translateDataToItems(obj, uniqueKeys),
        column_keys: obj.column_keys,
      }))
      .catch(normalizeError);

    return fetchResult;
  };
}

function makeLayerDataParameters(
  datasetId: string,
  query: Expression[],
  limit: number,
  version: string,
  sortColumn?: string,
  sortDirection?: 'asc' | 'desc',
) {
  const { namespace, type: layerType, key } = parseFullPath(datasetId);
  const filterClause = getFilterClause(query);
  return {
    namespace,
    layer_type: layerType,
    key,
    ...filterClause,
    limit,
    version,
    ...(sortColumn && {
      sort_column: sortColumn,
      sort_direction: sortDirection,
    }),
  };
}
