import {
  CuratedColumnSymbology,
  InterpolationTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/public/dataset/hifld_us_sup_colleges': {
    naics_desc: [
      {
        display_hints: {
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          scale: SymbologyScales.CATEGORICAL,
          theme: 'custom',
        },
        paint: {
          'circle-color': {
            default: '#8c564b',
            property: 'naics_desc',
            stops: [
              {
                color: '#653294',
                value: 'COLLEGES, UNIVERSITIES, AND PROFESSIONAL SCHOOLS',
              },
              {
                color: '#9f0500',
                value: 'COSMETOLOGY AND BARBER SCHOOLS',
              },
              {
                color: '#e27300',
                value: 'FINE ARTS SCHOOLS',
              },
              {
                color: '#b0bc00',
                value: 'JUNIOR COLLEGES',
              },
              {
                color: '#fcc400',
                value: 'OTHER TECHNICAL AND TRADE SCHOOLS',
              },
            ],
            type: 'categorical',
          },
          'circle-opacity': 0.7,
          'circle-stroke-color': 'transparent',
        },
        type: SymbologyTypes.CIRCLE,
      },
    ],
  },
};

export default symbologies;
