import _ from 'lodash';

import { LayerReference, ProjectMetadata } from 'uf-api';
import { assertNever } from 'uf/base/never';
import { Flavor } from 'uf/base/types';
import { LayerId } from 'uf/layers';
import {
  getNonBaseScenarioCanvasLayerReference,
  getProjectBaseCanvasLayerReference,
} from 'uf/projects/projectLayers';
import { ScenarioId } from 'uf/scenarios';

import { getScenarios } from './';

/**
 * "Layers" in UF can have properties that make it difficult to manage them by
 * their actual layer ids. In fact, many of the items in the layer list that
 * the user thinks are layers are not technically layers at all!
 *
 * To deal with this, each Project maintains a set of Virtual Layer Ids that
 * than can be mapped to their correct resource, ie: layer, canvas, transit
 * canvas, analysis output, etc...
 */
export type LegacyVirtualLayerId = Flavor<string, 'LegacyVirtualLayerId'>;

enum VirtualLayerType {
  CANVAS = 'canvas',
  BASE_CANVAS = 'base_canvas',

  ANALYSIS_OUTPUT = 'analysis_output',
}

export enum LayerType {
  BOUNDARY = 'boundary',
  PAINTED = 'painted',
  BASE = 'base',
  WORKING = 'working',
  ANALYSIS = 'analysis',
  REFERENCE = 'reference',
}

interface VirtualLayerScenarioCanvasReference {
  type: VirtualLayerType.CANVAS | VirtualLayerType.BASE_CANVAS;
}

interface VirtualLayerAnalysisOutputReference {
  type: VirtualLayerType.ANALYSIS_OUTPUT;
  analysis_module: string;
  analysis_output_key: string;
}

export type VirtualLayerReference =
  | VirtualLayerScenarioCanvasReference
  | VirtualLayerAnalysisOutputReference;

function findScenario(project: ProjectMetadata, scenarioId: ScenarioId) {
  const scenarios = getScenarios(project);
  return scenarios.find(scenario => scenario.full_path === scenarioId);
}

export function resolveVirtualLayerReference(
  ref: VirtualLayerReference,
  project: ProjectMetadata,
  scenarioId: ScenarioId,
): LayerReference {
  switch (ref.type) {
    case VirtualLayerType.ANALYSIS_OUTPUT: {
      const scenario = findScenario(project, scenarioId);
      if (!scenario) {
        return null;
      }
      const moduleResults = scenario.analysis_results.find(
        results => results.module_key === ref.analysis_module,
      );
      if (!moduleResults) {
        return null;
      }
      const policy = moduleResults.policies.find(
        p => p.results?.output_layers && !!p.results.output_layers.length,
      );
      if (!policy) {
        return null;
      }

      const analysisLayerInfo = policy.results.output_layers.find(
        layerInfo => layerInfo.module_output_key === ref.analysis_output_key,
      );
      return analysisLayerInfo.layer;
    }

    case VirtualLayerType.BASE_CANVAS: {
      const scenario = findScenario(project, scenarioId);
      if (!scenario) {
        return null;
      }
      return scenario.canvas_set.development_layer;
    }

    case VirtualLayerType.CANVAS: {
      const scenario = findScenario(project, scenarioId);
      if (!scenario) {
        return null;
      }
      if (scenario === project.base_scenario) {
        return scenario.base_edits_painted_canvas;
      }
      return scenario.painted_uf_canvas;
    }
    default:
      return assertNever(
        ref,
        `Invalid virtual layer type ${(ref as any).type}`,
      );
  }
}

export interface AnalysisLayerInfo {
  layerReference: LayerReference;
  moduleKey: string;
  moduleName: string;
  moduleOutputKey: string;
  policyKey: string;
  resultsKey: string;
}
export type LayerToVirtualLayerMap = Record<LayerId, LegacyVirtualLayerId>;

/**
 * Create an object that maps absolute layer ids like `/foo/dataset/bar` to the
 * respective virtual layer id in the given project. Note that building this map
 * involves walking the entire project looking for all layers, so the results
 * should be cached when possible.
 *
 * Usage:
 * ```
 * const layerMap = makeVirtualIdMapForProject(project);
 * const virtualLayerId = layerMap[layerId];
 * ```
 *
 * This map may be optionally filtered by scenarioId. If scenarioId is not
 * specified, then the entire project comes back
 */

export function makeVirtualIdMapForProject(
  project: ProjectMetadata,
  scenarioId?: ScenarioId,
): LayerToVirtualLayerMap {
  const baseCanvasLayer = getProjectBaseCanvasLayerReference(project);

  // check that full_path exists, as there can be projects where the canvas has not
  // loaded yet, causing this property not to exist yet
  const baseCanvasMap: LayerToVirtualLayerMap = baseCanvasLayer?.full_path
    ? {
        [baseCanvasLayer.full_path]: makeVirtualLayerId(
          LayerType.BASE,
          baseCanvasLayer.full_path,
        ),
      }
    : {};

  const scenarios = getScenarios(project).filter(
    scenario => !scenarioId || scenario.full_path === scenarioId,
  );
  const nonBaseCanvasLayers = scenarios.map(scenario =>
    getNonBaseScenarioCanvasLayerReference(scenario),
  );

  const scenarioCanvasMap: LayerToVirtualLayerMap = makeVirtualMapByLayerId(
    LayerType.PAINTED,
    nonBaseCanvasLayers,
  );

  const workingLayers = project?.working_layers ?? [];
  const workingLayersMap: LayerToVirtualLayerMap = makeVirtualMapByLayerId(
    LayerType.WORKING,
    workingLayers,
  );
  const boundaryLayers = _.uniq(
    [project?.project_area_layer, project?.context_area_layer].filter(
      layer => !!layer,
    ),
  );
  const boundaryLayersMap: LayerToVirtualLayerMap = makeVirtualMapByLayerId(
    LayerType.BOUNDARY,
    boundaryLayers,
  );

  const analysisLayers: [LayerId, AnalysisLayerInfo][] = scenarios.flatMap(
    scenario => {
      return (
        scenario.analysis_results?.flatMap(analysisResults => {
          const moduleKey = analysisResults.module_key;
          const moduleName = project.analysis_modules?.find(
            module => module.module_key === moduleKey,
          )?.name;
          return analysisResults.policies.flatMap(policy => {
            const policyKey = policy.policy_key;
            const resultsKey = policy.results.key;
            return policy.results.output_layers.map(
              (outputLayer): [LayerId, AnalysisLayerInfo] => {
                const moduleOutputKey = outputLayer.module_output_key;
                return [
                  outputLayer.layer.full_path,
                  {
                    layerReference: outputLayer.layer,
                    moduleName,
                    moduleKey,
                    moduleOutputKey,
                    policyKey,
                    resultsKey,
                  },
                ];
              },
            );
          });
        }) ?? []
      );
    },
  );
  const analysisLayersMap: LayerToVirtualLayerMap = Object.fromEntries(
    analysisLayers?.map(([layerId, info]) => {
      return [
        layerId,
        makeVirtualAnalysisLayerId(info.layerReference?.details?.name),
      ];
    }) ?? [],
  );
  const referenceLayerMap = makeVirtualMapByLayerId(
    LayerType.REFERENCE,
    project?.reference_layers,
  );
  const layerListMap = makeVirtualMapByLayerId(
    LayerType.REFERENCE,
    project.layer_list?.map(item => item.layer),
  );

  return {
    // layerListMap must be first because many of the layers are overwritten by
    // the object spread
    ...layerListMap,
    ...baseCanvasMap,
    ...scenarioCanvasMap,
    ...boundaryLayersMap,
    ...analysisLayersMap,
    ...workingLayersMap,
    ...referenceLayerMap,
  };
}

/**
 * Helper function to turn an array of ids into an array of virtual ids, with a specific prefix
 *
 * ```
 * makeVirtualMapByLayerId('reference',
 *   [reference('/foo/dataset/bar'), reference('/foo/dataset/baz')])
 * ```
 * returns
 * ```
 * ['reference:/foo/dataset/bar', '/foo/dataset/baz']
 * ```
 *
 * @param layerType The layer type which will become the prefix
 * @param layers An array of layer references
 */
function makeVirtualMapByLayerId(
  layerType: LayerType,
  layers: LayerReference[],
): Record<LayerId, LegacyVirtualLayerId> {
  if (!layers) {
    return {};
  }
  return Object.fromEntries(
    layers
      .filter(layer => !!layer)
      .map(layer => [
        layer.full_path,
        makeVirtualLayerId(layerType, layer.full_path),
      ]),
  );
}

export function makeVirtualLayerId(layerType: LayerType, layerId: LayerId) {
  switch (layerType) {
    case LayerType.BASE:
      return `${LayerType.BASE}:${LayerType.BASE}`;
    case LayerType.PAINTED:
      // special since layerIds differ across scenarios and we want to keep symbology constant
      return `${layerType}:${layerType}`;
    case LayerType.ANALYSIS:
      if (__TESTING__) {
        throw Error('use makeVirtualAnalysisLayerId here instead');
      }
      console.error(
        'Cannot make virtualId for analysis layers, use makeVirtualAnalysisLayerId',
      );
      return;
    case LayerType.BOUNDARY:
    case LayerType.REFERENCE:
    case LayerType.WORKING:
      return `${layerType}:${layerId}`;

    default:
      assertNever(layerType);
  }
}

export function makeVirtualAnalysisLayerId(analysisOutputModuleName: string) {
  return `${LayerType.ANALYSIS}:${analysisOutputModuleName}`;
}
