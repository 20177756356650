import { Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { LayerGeoData } from 'uf-api';
import { enhancedApi as Api } from 'uf-api-rtk/store';
import { addAppMessage } from 'uf/appservices/actions';
import { Message } from 'uf/appservices/messages';
import { EMPTY_ARRAY } from 'uf/base';
import { parseFullPath } from 'uf/base/dataset';
import { LayerId } from 'uf/layers';
import {
  filtersAreEmpty,
  FilterSpec,
  getFilterClause,
  getLayerDataApiParams,
} from 'uf/layers/filters';

export function ensureLayerSelection(
  layerId: LayerId,
  version: string,
  filters: Partial<FilterSpec>,
): ThunkAction<Promise<LayerGeoData>, any, any, any> {
  return (dispatch: Dispatch, getState): Promise<LayerGeoData> => {
    if (filtersAreEmpty(filters) || filters.disabled) {
      return Promise.resolve({ geo_keys: EMPTY_ARRAY });
    }
    return loadLayerSelection(layerId, version, filters)(
      dispatch,
      getState,
      {},
    ).then(async r => {
      const { data, error: strictError, originalArgs } = r;
      const error = strictError as any;
      // prevent refetching if the query was too complex
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
      if (error?.status === 503) {
        await dispatch(
          Api.util.upsertQueryData('getLayerGeoKeys', originalArgs, {
            geo_keys: [],
          }),
        );
      }
      // notify the user of any error messages sent by the server
      if (error?.data?.title) {
        const { title: text } = error.data;
        const toastMessage: Message = {
          level: 'danger',
          status: 'failure',
        };
        dispatch(addAppMessage(text, toastMessage));
      }
      return data;
    });
  };
}

function loadLayerSelection(
  layerId: LayerId,
  version: string,
  filterSpec: Partial<FilterSpec>,
) {
  const { apiParams } = getLayerDataApiParams(layerId, {
    version,
    filters: filterSpec,
  });
  const { key, namespace, type: layerType } = parseFullPath(layerId);
  const { filters } = apiParams;
  return Api.endpoints.getLayerGeoKeys.initiate(
    {
      namespace,
      layerType,
      key,
      version,
      ...getFilterClause(filters),
      // limit,
      // uncached,
    },
    { forceRefetch: false },
  );
}
