import { combineReducers } from 'redux';

import {
  makeKeyedReducer,
  makeSetValueOnlyReducer,
  makeSetValueReducer,
} from 'uf/base/reducerFactories';

import { SET_INTERNAL_PAGE_KEY, SET_INTERNAL_PAGE_PARAM } from './ActionTypes';

const urlParams = makeKeyedReducer(
  makeSetValueReducer(SET_INTERNAL_PAGE_PARAM),
  'pageKey',
);

export default combineReducers({
  urlParams,
  pageKey: makeSetValueOnlyReducer(SET_INTERNAL_PAGE_KEY),
});
