import { Mode, ModeOptions } from '@mapbox/mapbox-gl-draw';
import { Marker } from 'mapbox-gl';
import { Action } from 'redux';

import { BuiltFormTypes } from 'uf/base/builtForms';
import { GridOptions } from 'uf/base/geo';
import { makeKeyedListActionTypes } from 'uf/base/keyedListActionHelpers';
import { UFLngLatBounds } from 'uf/base/map';
import { makeAsyncActionTypes } from 'uf/data/ActionTypes';
import { TabKey } from 'uf/explore/details';
import { ColumnKey, LayerId, LayerVersion } from 'uf/layers';
import * as LayerActions from 'uf/layers/ActionTypes';
import { FilterSpec } from 'uf/layers/filters';
import { MapViewport } from 'uf/map';
import { MapMode } from 'uf/map/mapmode';
import { CoordPathsByFeature } from 'uf/map/state';
import { EmploymentDirectPaintingState } from 'uf/painting/employment';
import { ResidentialDirectPainting } from 'uf/painting/residential';
import { ProjectId } from 'uf/projects';
import { LegacyVirtualLayerId } from 'uf/projects/virtualLayers';
import { ViewId } from 'uf/views';

export const SELECT_FEATURE = 'uf/explore/drawingMode/SELECT_FEATURE';
export interface SelectFeaturesAction {
  type: typeof SELECT_FEATURE;
  projectId: ProjectId;
  layerId: LayerId;
  /**
   * The features the user selected.
   */
  featureIds: string[];

  /**
   * The specific vertices that were selected in the selected features. Useful
   * for allowing users to edit segments of a transit line by clicking a point
   * on the line and deleting it.
   */
  coordPaths: CoordPathsByFeature;
}

export const DELETE_SELECTED_VERTICES =
  'uf/explore/drawingMode/DELETE_SELECTED_VERTICES';
export interface DeleteVerticesAction {
  type: typeof DELETE_SELECTED_VERTICES;
  projectId: ProjectId;
  layerId: LayerId;
  featureId: string;
  coordPaths: string[];
}

export const SET_ACTIVE_LAYER = 'uf/explore/SET_ACTIVE_LAYER';
export interface SetActiveLayerAction extends Action {
  type: typeof SET_ACTIVE_LAYER;
  projectId: ProjectId;
  virtualLayerId: LegacyVirtualLayerId;
  active: boolean;
}
export const CLEAR_ACTIVE_LAYER = 'uf/explore/CLEAR_ACTIVE_LAYER';
export interface ClearActiveLayerAction extends Action {
  type: typeof CLEAR_ACTIVE_LAYER;
  projectId: ProjectId;
}

export const UPDATE_LAYER_QUERY = 'uf/explore/UPDATE_LAYER_QUERY';
export const SET_LAYER_SEARCH_KEY = 'uf/explore/SET_LAYER_SEARCH_KEY';
export interface SetLayerSearchKeyAction extends Action {
  type: typeof SET_LAYER_SEARCH_KEY;
  projectId: ProjectId;
  layerId: LayerId;
  searchKey: string;
}
export const SET_ACTIVE_VIEW = 'uf/explore/SET_ACTIVE_VIEW';
export interface SetActiveViewAction {
  type: typeof SET_ACTIVE_VIEW;
  projectId: ProjectId;
  value: ViewId;
}
export const SET_SEARCH_MARKER = 'uf/explore/map/SET_SEARCH_MARKER';
export interface SetSearchMarkerAction extends Action {
  type: typeof SET_SEARCH_MARKER;
  projectId: ProjectId;
  marker: Marker;
}
export const CLEAR_SEARCH_MARKER = 'uf/explore/map/CLEAR_SEARCH_MARKER';

export const SET_SCENARIO_ORDER = 'uf/explore/SET_SCENARIO_ORDER';
export interface SetScenarioOrderAction extends Action {
  type: typeof SET_SCENARIO_ORDER;
  property: string;
  value: string[];
}

export const ADD_CHART = 'uf/explore/layers/ADD_CHART';
export const REMOVE_CHART = 'uf/explore/layers/REMOVE_CHART';
export const SET_HISTOGRAM_WEIGH_BY =
  'uf/explore/charts/SET_HISTOGRAM_WEIGH_BY';
export const SET_HISTOGRAM_FILTER_MODE =
  'uf/explore/charts/SET_HISTOGRAM_FILTER_MODE';
export const SET_ACTIVE_CHART_TYPE_MODE =
  'uf/explore/charts/SET_ACTIVE_CHART_TYPE_MODE';

export const MAP_RESET = 'uf/explore/map/RESET';
export const MAP_LOADING = 'uf/explore/map/LOADING';
export const MAP_LOADED = 'uf/explore/map/LOADED';
export const MAP_ERROR = 'uf/explore/map/ERROR';

export const UPDATE_MAP_STYLE_URL = 'uf/explore/map/style_url/UPDATE';
export interface UpdateMapStyleUrlAction extends Action {
  type: typeof UPDATE_MAP_STYLE_URL;
  projectId: ProjectId;
  styleUrl: string;
}
export const SET_BASEMAP_FEATURE_VISIBILITY =
  'uf/explore/map/basemap/features/UPDATE';
export interface SetBasemapFeatureVisibility extends Action {
  type: typeof SET_BASEMAP_FEATURE_VISIBILITY;
  projectId: ProjectId;
  featureType: string;
  featureVisible: boolean;
}

export const UPDATE_MAP_CENTER = 'uf/explore/map/center/UPDATE';
export const UPDATE_MAP_PITCH = 'uf/explore/map/pitch/UPDATE';
export const UPDATE_MAP_BEARING = 'uf/explore/map/bearing/UPDATE';
export const UPDATE_MAP_BOUNDS = 'uf/explore/map/mapBounds/UPDATE';
export const UPDATE_MAP_ZOOM = 'uf/explore/map/zoom/UPDATE';
export const UPDATE_MAP_SCALE = 'uf/explore/map/scale/UPDATE';
export const UPDATE_MAP_VIEWPORT = 'uf/explore/map/viewport/UPDATE';
export interface UpdateMapViewportAction extends Action {
  type: typeof UPDATE_MAP_VIEWPORT;
  projectId: ProjectId;
  viewport: Partial<MapViewport>;

  /**
   * if this action should store its result in redux
   */
  save: boolean;
}

export const SET_EXPLORE_MAP_VIEWPORT = 'uf/explore/map/viewport/SET';
export interface SetExploreMapViewportAction extends Action {
  type: typeof SET_EXPLORE_MAP_VIEWPORT;
  projectId: ProjectId;
  viewport: MapViewport;
}
export const FIT_MAP_TO_BOUNDS = 'uf/explore/map/FIT_MAP_TO_BOUNDS';
export interface FitMapToBoundsAction extends Action {
  type: typeof FIT_MAP_TO_BOUNDS;
  projectId: ProjectId;
  requestedMapBounds: UFLngLatBounds;
}
export const UPDATE_MAP_MAX_BOUNDS = 'uf/explore/map/mapMaxBounds/UPDATE';
export const ZOOM_TO_LAYER = 'uf/explore/map/ZOOM_TO_LAYER';
export interface ZoomToLayerAction extends Action {
  type: typeof ZOOM_TO_LAYER;
  projectId: ProjectId;
  layerId: LayerId;
  layerVersion: LayerVersion;
  layerFilters?: Partial<FilterSpec>;
}
export const UPDATE_TILE_SOURCES = 'uf/explore/map/tile_sources/UPDATE';
export const UPDATE_STYLE_LAYERS = 'uf/explore/map/style_layers/UPDATE';
export const UPDATE_MAP_MODE = 'uf/explore/map/mode/UPDATE';
export interface UpdateMapModeAction extends Action {
  type: typeof UPDATE_MAP_MODE;
  projectId: ProjectId;
  mode: MapMode;
}
export const SET_SELECTION_MODES_DISABLED =
  'uf/explore/map/SELECTION_MODES_DISABLED';

export interface SetSelectionModesDisabledAction extends Action {
  type: typeof SET_SELECTION_MODES_DISABLED;
  projectId: ProjectId;
  value: boolean;
}
export const UPDATE_MAP_INSPECTION = 'uf/explore/map/inspection/UPDATE';
export const CLEAR_MAP_INSPECTION = 'uf/explore/map/inspection/CLEAR';

/* filter actions */
export const UPDATE_COLUMN_FILTER = 'uf/explore/filters/column/UPDATE';
export type UpdateColumnFilterAction = LayerActions.UpdateColumnFilterAction<
  typeof UPDATE_COLUMN_FILTER
>;
export const CLEAR_COLUMN_FILTERS = 'uf/explore/filters/column/CLEAR';
export type ClearColumnFilterAction = LayerActions.ClearColumnFilterAction<
  typeof CLEAR_COLUMN_FILTERS
>;
export const ADD_JOINED_LAYER_FILTER = 'uf/explore/filters/layer/ADD';
export type AddJoinedLayerFilterAction =
  LayerActions.AddJoinedLayerFilterAction<typeof ADD_JOINED_LAYER_FILTER>;
export const CLEAR_JOINED_LAYER_FILTER = 'uf/explore/filters/layer/CLEAR';
export type ClearJoinedLayerFilterAction =
  LayerActions.ClearJoinedLayerFilterAction<typeof CLEAR_JOINED_LAYER_FILTER>;
export const UPDATE_BUFFER_FILTER = 'uf/explore/filters/buffer/UPDATE';
export type UpdateBufferFilterAction = LayerActions.UpdateBufferFilterAction<
  typeof UPDATE_BUFFER_FILTER
>;
export const CLEAR_BUFFER_FILTER = 'uf/explore/filters/buffer/CLEAR';
export type ClearBufferFilterAction = LayerActions.ClearBufferFilterAction<
  typeof CLEAR_BUFFER_FILTER
>;
export const UPDATE_POINT_FILTER = 'uf/explore/filters/point/UPDATE';
export type UpdatePointFilterAction = LayerActions.UpdatePointFilterAction<
  typeof UPDATE_POINT_FILTER
>;
export const CLEAR_POINT_FILTER = 'uf/explore/filters/point/CLEAR';
export type ClearPointFilterAction = LayerActions.ClearPointFilterAction<
  typeof CLEAR_POINT_FILTER
>;
export const UPDATE_GEOMETRY_FILTERS = 'uf/explore/filters/geometry/UPDATE';
export type UpdateGeometryFilterAction =
  LayerActions.UpdateGeometryFilterAction<typeof UPDATE_GEOMETRY_FILTERS>;
export const CLEAR_GEOMETRY_FILTERS = 'uf/explore/filters/geometry/CLEAR';
export type ClearGeometryFilters = LayerActions.ClearGeometryFilters<
  typeof CLEAR_GEOMETRY_FILTERS
>;
export const CLEAR_SELECTION_FILTERS = 'uf/explore/filters/selection/CLEAR';
export type ClearSelectionFiltersAction =
  LayerActions.ClearSelectionFiltersAction<typeof CLEAR_SELECTION_FILTERS>;
export const CLEAR_FILTERS = 'uf/explore/filters/CLEAR';
export type ClearFiltersAction = LayerActions.ClearFiltersAction<
  typeof CLEAR_FILTERS
>;
export const ENABLE_FILTERS = 'uf/explore/filters/ENABLE';
export type EnableFiltersAction = LayerActions.EnableFiltersAction<
  typeof ENABLE_FILTERS
>;
export const UPDATE_JOIN_LAYER_METHOD =
  'uf/explore/filters/joinLayerMethod/UPDATE';
export type UpdateJoinLayerMethodAction =
  LayerActions.UpdateJoinLayerMethodAction<typeof UPDATE_JOIN_LAYER_METHOD>;

// Used for setting simple, arbitrary flags like
// whether the chartPanel or Build Bar are visible/invisible
const uiActionTypesPrefix = 'uf/explore/ui';
export const SET_UI_PROPERTY = 'uf/explore/ui/properties/SET_UI_PROPERTY';
export interface SetUIPropertyAction<T, E = never> {
  type: typeof SET_UI_PROPERTY;
  property: string;
  value: T;
  extra?: E;
  projectId: ProjectId;
}

export const SET_DETAILS_TAB = 'uf/explore/ui/SET_DETAILS_TAB';
export interface SetDetailsTabKeyAction {
  type: typeof SET_DETAILS_TAB;
  projectId: ProjectId;
  layerId: LayerId;
  value: TabKey;
}

export const SET_DETAILS_BUILD_TAB = `${uiActionTypesPrefix}/SET_DETAILS_BUILD_TAB`;

export const SET_DRAWING_MODE = 'uf/explore/ui/SET_DRAWING_MODE';
export interface SetDrawingModeAction {
  type: typeof SET_DRAWING_MODE;
  projectId: ProjectId;
  layerId: LayerId;
  mode: Mode<'box_select_vertices'>;
  modeOptions: ModeOptions;
}
export const SET_SHOW_PAINT_ONLY = 'uf/explore/ui/SET_SHOW_PAINT_ONLY';

export const SET_SUMMARY_STATS_TYPE = 'uf/explore/stats/SET_SUMMARY_STATS_TYPE';

export const SET_CURRENT_BUILT_FORM =
  'uf/explore/painting/SET_CURRENT_BUILT_FORM';
export interface SetCurrentBuiltFormAction {
  type: typeof SET_CURRENT_BUILT_FORM;
  projectId: ProjectId;
  builtFormType: BuiltFormTypes;
  builtFormKey: string;
}
export const SET_HOVER_BUILT_FORM = 'uf/explore/painting/SET_HOVER_BUILT_FORM';
export interface SetHoverBuiltFormAction {
  type: typeof SET_HOVER_BUILT_FORM;
  projectId: ProjectId;
  builtFormType: BuiltFormTypes;
  builtFormKey: string;
}

export const ADD_TO_RECENT_PAINT_HISTORY =
  'uf/explore/painting/ADD_TO_RECENT_PAINT_HISTORY';
export interface AddToRecentPaintHistoryAction {
  type: typeof ADD_TO_RECENT_PAINT_HISTORY;
  projectId: ProjectId;
  builtFormType: BuiltFormTypes;
  builtFormKey: string;
}

const exploreFilterListActionTypesPrefix = `${uiActionTypesPrefix}/exploreFilterList`;
export const exploreFilterListActionTypes = makeKeyedListActionTypes(
  exploreFilterListActionTypesPrefix,
);
const exploreGeoJoinListActionTypesPrefix = `${uiActionTypesPrefix}/exploreGeoJoinList`;
export const exploreGeoJoinListActionTypes = makeKeyedListActionTypes(
  exploreGeoJoinListActionTypesPrefix,
);

const snapshotActionTypesPrefix = 'uf/explore/snapshot';
export const snapshotActionTypes = makeAsyncActionTypes(
  snapshotActionTypesPrefix,
);

// TODO: move explore out of the default route
export const ROUTE_EXPLORE = 'HOME';
export const ROUTE_EXPLORE_NEW = 'EXPLORE';

export const SET_FEATURE_IDS_FROM_SPLIT =
  'uf/explore/SET_FEATURE_IDS_FROM_SPLIT';

export interface SetFeatureIdsFromSplit {
  type: typeof SET_FEATURE_IDS_FROM_SPLIT;
  projectId: ProjectId;
  layerId: LayerId;
  featureIds: string[];
}
export const SET_TOO_MANY_FEATURES = 'uf/explore/SET_TOO_MANY_FEATURES';

export interface SetTooManyFeaturesAction {
  type: typeof SET_TOO_MANY_FEATURES;
  projectId: ProjectId;
  layerId: LayerId;
  value: boolean;
}

export const SET_FAILED_INTERSECTIONS = 'uf/explore/SET_FAILED_INTERSECTIONS';
export interface SetFailedIntersections {
  type: typeof SET_FAILED_INTERSECTIONS;
  projectId: ProjectId;
  layerId: LayerId;
  value: number;
}

export const SAVE_SPLIT_FEATURES = 'uf/explore/SAVE_SPLIT_FEATURES';

export interface SaveSplitFeaturesAction {
  type: typeof SAVE_SPLIT_FEATURES;
  projectId: ProjectId;
  layerId: LayerId;
}

export const SET_GRID_OPTIONS = 'uf/explore/SET_GRID_OPTIONS';
export interface SetGridOptions {
  type: typeof SET_GRID_OPTIONS;
  value: GridOptions;
  projectId: ProjectId;
}

export const UPDATE_VISIBILITY_FILTER = 'uf/explore/UPDATE_VISIBILITY_FILTER';
export interface UpdateVisibilityFilter {
  type: typeof UPDATE_VISIBILITY_FILTER;
  projectId: ProjectId;
  layerId: LayerId;
  columnKey: ColumnKey;
  value: string;
}

export const UPDATE_EMPLOYMENT_DIRECT_PAINTING =
  'uf/explore/painting/UPDATE_EMPLOYMENT_DIRECT_PAINTING';
export interface UpdateEmploymentDirectPaintingAction {
  type: typeof UPDATE_EMPLOYMENT_DIRECT_PAINTING;
  projectId: ProjectId;
  value: EmploymentDirectPaintingState;
}

export const CLEAR_EMPLOYMENT_DIRECT_PAINTING =
  'uf/explore/painting/CLEAR_EMPLOYMENT_DIRECT_PAINTING';
export interface ClearEmploymentDirectPaintingAction {
  type: typeof CLEAR_EMPLOYMENT_DIRECT_PAINTING;
  projectId: ProjectId;
}

export const UPDATE_RESIDENTIAL_DIRECT_PAINTING =
  'uf/explore/painting/UPDATE_RESIDENTIAL_DIRECT_PAINTING';
export interface UpdateResidentialDirectPaintingAction {
  type: typeof UPDATE_RESIDENTIAL_DIRECT_PAINTING;
  projectId: ProjectId;
  value: ResidentialDirectPainting;
}

export const CLEAR_RESIDENTIAL_DIRECT_PAINTING =
  'uf/explore/painting/CLEAR_RESIDENTIAL_DIRECT_PAINTING';
export interface ClearResidentialDirectPaintingAction {
  type: typeof CLEAR_RESIDENTIAL_DIRECT_PAINTING;
  projectId: ProjectId;
}

export const BIND_PAINT_BASE_SCENARIO_TASK =
  'uf/explore/painting/BIND_PAINT_BASE_SCENARIO_TASK';
export const BIND_PAINT_SCENARIO_TASK =
  'uf/explore/painting/BIND_PAINT_SCENARIO_TASK';
