import { createSelector } from 'reselect';

import { EMPTY_OBJECT } from 'uf/base';
import { makeGetFromPropsSelector } from 'uf/base/selector';
import { ProjectId } from 'uf/projects';
import { UFState } from 'uf/state';
import { ViewId } from 'uf/views';
import { ViewsLayersState, ViewState } from 'uf/views/state';

export function getViewsState(state: UFState): ViewState {
  return state.views;
}

export function makeGetViewsLayersState() {
  return createSelector(
    getViewsState,
    makeGetFromPropsSelector<ProjectId, 'projectId'>('projectId'),
    makeGetFromPropsSelector<ViewId, 'viewId'>('viewId'),
    (viewsState, projectId, viewId): ViewsLayersState => {
      return viewsState?.layers[projectId]?.[viewId] || EMPTY_OBJECT;
    },
  );
}
