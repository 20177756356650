import { Feature } from 'geojson';
import { ThunkAction } from 'redux-thunk';

import {
  DELETE_SELECTED_VERTICES,
  DeleteVerticesAction,
  SELECT_FEATURE,
  SelectFeaturesAction,
} from 'uf/explore/ActionTypes';
import { makeGetLayerEditsSelectedVerticesForFeature } from 'uf/explore/selectors/drawingMode';
import { LayerId } from 'uf/layers';
import { CoordPathsByFeature } from 'uf/map/state';
import { ProjectId } from 'uf/projects';
import { UFState } from 'uf/state';

export function selectFeatures(
  projectId: ProjectId,
  layerId: LayerId,
  features: Feature[],
  coordPaths: CoordPathsByFeature,
): SelectFeaturesAction {
  return {
    type: SELECT_FEATURE,
    projectId,
    layerId,
    featureIds: features.map(({ id }) => id as string),
    coordPaths,
  };
}

export function deleteSelectedVerticesFromFeature(
  projectId: ProjectId,
  layerId: LayerId,
  featureId: string,
): ThunkAction<void, UFState, any, DeleteVerticesAction> {
  const getSelectedVerticesForFeature =
    makeGetLayerEditsSelectedVerticesForFeature();
  return (dispatch, getState) => {
    const coordPaths = getSelectedVerticesForFeature(getState(), {
      layerId,
      projectId,
      featureId,
    });

    dispatch({
      type: DELETE_SELECTED_VERTICES,
      projectId,
      layerId,
      featureId,
      coordPaths,
    });
  };
}
