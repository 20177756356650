import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ParametricSelector } from 'reselect';

/**
 * Run a selector from its factory, passing in OwnProps as appropriate
 *
 * Example:
 *
 * ```
 * const layerMetadataState = useMakeSelector(makeGetLayerMetadata, { layerId });
 * ```
 *
 * @param makeSelector A selector factory. Do not pass an inline function here.
 * @param props Props that would be passed as `OwnProps` to the selector.
 */
export function useMakeSelector<S, P, R, PP extends P = P, A extends [] = []>(
  makeSelector: (...args: A) => ParametricSelector<S, P, R>,
  props: PP,
) {
  const selector = useMemo(makeSelector, [makeSelector]);
  const result = useSelector((state: S) => selector(state, props));
  return result;
}
