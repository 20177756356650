import { LayerMetadata, LayerStats } from 'uf-api';
import { Writeable } from 'uf/base/types';
import { ColumnKey } from 'uf/layers';
import { GEOMETRY_KEY, getColumnKey } from 'uf/layers/geometryKey';
import { getColumnMetadata } from 'uf/layers/metadata';
import {
  isNullValueBucket,
  NullCategory,
  NULL_CATEGORY,
} from 'uf/layers/nullStats';
import { findColumnKeyStats, LayerStatsParams } from 'uf/layers/stats';
import { DivideByColumnKey } from './divideByColumn';

export type SymbologyCategoryValue = string | NullCategory;

export function getStatsParams(
  columnKey: ColumnKey,
  version: string,
  divideByColumn?: DivideByColumnKey,
): LayerStatsParams {
  const params: Writeable<LayerStatsParams> = {
    columns: [getColumnKey(columnKey)],
    version,
    // Make this call faster by omitting the row counts
    row_count: false,
  };

  if (divideByColumn) {
    params.normalize_by = divideByColumn;
  }

  return params;
}

export function hasCategoricalStats(
  layerStats: LayerStats,
  columnKey: ColumnKey,
) {
  const stats = findColumnKeyStats(layerStats, columnKey);
  return !!stats?.categorical;
}

export function getCategories(
  layerStats: LayerStats,
  columnKey: ColumnKey,
): SymbologyCategoryValue[] {
  const stats = findColumnKeyStats(layerStats, columnKey);
  const values = stats?.categorical?.values;
  // if values is missing entirely, then it was left out because
  // there were too many.
  if (!values) {
    return null;
  }

  const categories = values
    // don't make symbology for empty string categories
    .filter(entry => entry.value !== '')
    .map(entry => {
      if (isNullValueBucket(entry)) {
        return NULL_CATEGORY;
      }

      return entry.value;
    });

  return categories;
}

/**
 * Checks if a given column requires stats to be loaded, to be displayed.
 *
 * @param layerMetadataState
 * @param symbologyColumnKey
 */
export function columnUsesStats(
  layerMetadataState: LayerMetadata,
  symbologyColumnKey: string,
) {
  const columnKey = getColumnKey(symbologyColumnKey);

  // Geometry_key never needs stats as there is no data. This allows us to
  // answer this before metadata even loads.
  if (columnKey === GEOMETRY_KEY) {
    return false;
  }

  // Note that if metadata is not loaded, this will return `true`, that we need
  // stats. This means when metadata loads, stats may go from being required to
  // being optional,
  const column = getColumnMetadata(layerMetadataState, columnKey);
  const skipCuratedCleaning = column?.display_hints?.skip_curated_cleaning;

  return !skipCuratedCleaning;
}
