import assert from 'assert';
import { ColumnKey, LayerId } from 'uf/layers';
import { SymbologyTypes } from 'uf/symbology';
import { DivideByColumnKey } from 'uf/symbology/divideByColumn';

export function makeStyleSymbologyId(
  layerId: LayerId,
  columnKey: ColumnKey,
  symbologyType: SymbologyTypes,
  divideByColumn?: DivideByColumnKey,
) {
  // TODO(jasonkraus): replace with strict null checks
  assert(layerId?.length, `Empty layerId not allowed: ${layerId}`);
  const parts = [layerId, columnKey, symbologyType];
  if (divideByColumn) {
    parts.push(divideByColumn);
  }
  return parts.join('|');
}
