import * as _ from 'lodash';
import { createSelector } from 'reselect';

import { AnalysisModuleInfo, ProjectModuleParams } from 'uf-api';
import {
  AnalysisParameterActionInfo,
  makeAnalysisParameterKey,
  makeAnalysisSchemaKey,
} from 'uf/analysiseditor';
import { AnalysisEditorState } from 'uf/analysiseditor/state';
import { makeGetApiObjectResults } from 'uf/api/selectors';
import { EMPTY_OBJECT } from 'uf/base';
import { makeGetFromPropsSelector } from 'uf/base/selector';
import { DataState, EmptyKeyedState } from 'uf/data/dataState';
import { ProjectId } from 'uf/projects';
import { ScenarioId } from 'uf/scenarios';
import { UFState } from 'uf/state';

export function getAnalysisEditorState(state: UFState): AnalysisEditorState {
  return state.analysiseditor || (EMPTY_OBJECT as AnalysisEditorState);
}

const getActiveAnalysisEditorModuleKeyState = createSelector(
  getAnalysisEditorState,
  analysiseditor => analysiseditor.activeModuleKey ?? EMPTY_OBJECT,
);

export const getProjectParamsState = createSelector(
  getAnalysisEditorState,
  (
    state,
  ): Record<
    string,
    DataState<ProjectModuleParams, AnalysisParameterActionInfo>
  > => state.projectModuleParams || EMPTY_OBJECT,
);

export const getProjectParamsStateNew = makeGetApiObjectResults(
  'project',
  'get_project_analysis_params',
);

export function makeGetActiveAnalysisEditorModuleByProjectId() {
  return createSelector(
    getActiveAnalysisEditorModuleKeyState,
    makeGetFromPropsSelector<ProjectId, 'projectId'>('projectId'),
    (activeModuleKeyByProjectId, projectId): string =>
      activeModuleKeyByProjectId[projectId],
  );
}

export function makeGetAnalysisSchema() {
  return createSelector(
    makeGetApiObjectResults('project', 'get_analysis_module'),
    makeGetFromPropsSelector<ProjectId, 'projectId'>('projectId'),
    makeGetFromPropsSelector<string, 'moduleKey'>('moduleKey'),
    (schemaState, projectId, moduleKey): DataState<AnalysisModuleInfo> => {
      if (!projectId || !moduleKey) {
        return EmptyKeyedState;
      }

      const key = makeAnalysisSchemaKey(projectId, moduleKey);
      return schemaState[key];
    },
  );
}

export function makeGetAnalysisModuleParamsByScenarioId() {
  return createSelector(
    getProjectParamsState,
    makeGetFromPropsSelector<ProjectId, 'projectId'>('projectId'),
    makeGetFromPropsSelector<string, 'moduleKey'>('moduleKey'),
    (projectParams, projectId, moduleKey) => {
      const matchingResults = Object.values(projectParams).filter(
        projectParamState => {
          const { extra } = projectParamState;
          return extra.projectId === projectId && extra.moduleKey === moduleKey;
        },
      );
      const c = _.keyBy(matchingResults, r => r.extra.scenarioId);

      return c;
    },
  );
}

/**
 * get the parameters that are set for a given (projectId, scenarioId, moduleKey)
 */
export function makeGetAnalysisScenarioParams() {
  return createSelector(
    getProjectParamsState,
    makeGetFromPropsSelector<ProjectId, 'projectId'>('projectId'),
    makeGetFromPropsSelector<ScenarioId, 'scenarioId'>('scenarioId'),
    makeGetFromPropsSelector<string, 'moduleKey'>('moduleKey'),
    (
      paramState,
      projectId,
      scenarioId,
      moduleKey,
    ): DataState<ProjectModuleParams> => {
      const key = makeAnalysisParameterKey(projectId, scenarioId, moduleKey);
      return paramState[key];
    },
  );
}
export interface AnalysisEditorContext {
  /**
   * Flags set by the user
   */
  flags: string[];
  projectId: ProjectId;
  moduleKey: string;
  manageViewVisible: string;
  manageViewEditor: string;
}
