/**
 * UrbanFootprint App API
 * API for serving UrbanFootprint Application
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { LayerColumnValidValues } from './layerColumnValidValues';

/**
 * Describes a parameter to be passed to an analysis module.
 */
export interface AnalysisModuleParameterMetadata<
  Tconverted_default_value extends object = any,
  Tdisplay_hints extends object = any,
> {
  /**
   * A very short abbreviation with categorical context. (e.g. \"Ag. Emp.\")
   */
  abbreviation?: string;
  /**
   * the most adjacent param category that the param belongs to
   */
  category?: string;
  /**
   * An object containing the default value parsed as its defined type.
   */
  converted_default_value?: Tconverted_default_value;
  /**
   * The string representation of the default param value.
   */
  default_value?: string;
  /**
   * A longer description of the param.
   */
  description?: string;
  /**
   * Free-form object for hints to the front-end. It currently includes 1. is_internal, a flag to indicate if the param is internal view and edit only; 2. is_visible, a flag to indicate if the param is shown to users by default without any additional action, like expand, in UI.
   */
  display_hints?: Tdisplay_hints;
  /**
   * The human-friendly units of measurement for the param.
   */
  display_units?: string;
  display_valid_values?: LayerColumnValidValues;
  /**
   * Any constraints to apply to dynamically generated valid values. Only applies if dynamic_valid_values is True.
   */
  dynamic_valid_value_constraint?: AnalysisModuleParameterMetadata.DynamicValidValueConstraintEnum;
  /**
   * Whether or not the valid values of this param are dynamically generated from the attributes of another object, like a project, etc.
   */
  dynamic_valid_values?: boolean;
  /**
   * An identifier that is unique across all params for a single analysis module.
   */
  key: string;
  /**
   * Higher level type of param value.
   */
  metatype?: AnalysisModuleParameterMetadata.MetatypeEnum;
  /**
   * Name of the param, in English.
   */
  name: string;
  /**
   * If parameter is required to have value to run model (otherwise default can be None).
   */
  optional?: boolean;
  /**
   * Whether the param contains any sensitive information that poses potential security concerns.
   */
  sensitive?: boolean;
  /**
   * The full path of the layer that can be used to derive a value for this param.
   */
  source_layer?: string;
  /**
   * An very short abbreviation without context. (e.g. \"Ag.\")
   */
  terse_abbreviation?: string;
  /**
   * A shortened version of the name, without context. (e.g. \"Agricultural\")
   */
  terse_name?: string;
  /**
   * The type of param value.
   */
  type: AnalysisModuleParameterMetadata.TypeEnum;
  /**
   * The units of measurement for the param.
   */
  units?: string;
  valid_values?: LayerColumnValidValues;
}
export namespace AnalysisModuleParameterMetadata {
  export type DynamicValidValueConstraintEnum = 'layer_geometry_type_point';
  export const DynamicValidValueConstraintEnum = {
    LayerGeometryTypePoint: 'layer_geometry_type_point',
  } as const;
  export type MetatypeEnum = 'categorical' | 'numeric';
  export const MetatypeEnum = {
    Categorical: 'categorical',
    Numeric: 'numeric',
  } as const;
  export type TypeEnum = 'string' | 'boolean' | 'int' | 'float' | 'layer_path';
  export const TypeEnum = {
    String: 'string',
    Boolean: 'boolean',
    Int: 'int',
    Float: 'float',
    LayerPath: 'layer_path',
  } as const;
}
