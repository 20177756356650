import { setInternalPageKey, setInternalPageParam } from 'uf/internal/actions';
import { SetInitialRoute } from 'uf/routing/state';

export const setInitialInternalRoute: SetInitialRoute = (
  location,
  query,
  dispatch,
) => {
  const { pageKey = null } = location.payload as any;
  dispatch(setInternalPageKey(pageKey));
  Object.keys(query).forEach(key => {
    dispatch(setInternalPageParam<any, string>(pageKey, key, query[key]));
  });
};
