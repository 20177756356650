import { LngLatLike, Map, MapMouseEvent, Point } from 'mapbox-gl';

/**
 * Inspection Mode:
 * Click on the map to select the features under the mouse.
 * @param map - the mapboxgl map instance
 * @param onInspectPoint - called with arguments: point, lngLat, map
 * @return unsetInspectionMode - cleans up any Inspection Mode artifacts
 */
export function setInspectionMode(
  map: Map,
  onInspectPoint: (point: Point, lnglat: LngLatLike, map: Map) => void,
): () => void {
  const canvas = map.getCanvasContainer();
  canvas.style.cursor = 'pointer';
  map.boxZoom.enable();
  map.dragPan.enable();
  map.doubleClickZoom.enable();

  map.on('mousedown', mousedown);

  return () => map.off('mousedown', mousedown);

  function mousedown(mouseDownEvent: MapMouseEvent) {
    const point = mouseDownEvent.point;
    const lngLat = mouseDownEvent.lngLat;
    onInspectPoint(point, lngLat, map);
  }
}
