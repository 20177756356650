import { INonIdealStateProps, NonIdealState } from '@blueprintjs/core';
import { mdiAlertCircle } from '@mdi/js';
import Icon from '@mdi/react';
import classNames from 'classnames';
import React, { FunctionComponent } from 'react';
import { t } from 'ttag';

import rootStyles from 'uf/styles/root.module.css';
import ErrorBoundaryReloadButton from 'uf/ui/base/ErrorBoundary/ErrorReloadButton';
import { IconSizeNew } from 'uf/ui/base/icon';

import styles from './ErrorFallback.module.css';

export interface ErrorFallbackProps extends INonIdealStateProps {
  onClick: () => void;
  showAction?: boolean;
  icon?: JSX.Element;
}

const ErrorFallback: FunctionComponent<ErrorFallbackProps> = props => {
  const {
    icon = <Icon path={mdiAlertCircle} size={IconSizeNew.LARGE} />,
    title = t`An Error Occured`,
    description = t`If the problem persists, please contact support`,
    showAction = true,
    onClick,
  } = props;

  const action = showAction && <ErrorBoundaryReloadButton onClick={onClick} />;

  return (
    <NonIdealState
      className={classNames(styles.errorFallback, rootStyles.thinPadding)}
      icon={icon}
      title={title}
      description={description}
      action={action}
    />
  );
};

export default ErrorFallback;
