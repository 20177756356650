import { Callout, Classes, FormGroup, InputGroup } from '@blueprintjs/core';
import { mdiInformationVariant } from '@mdi/js';
import Icon from '@mdi/react';
import { boundMethod } from 'autobind-decorator';
import React, { Component } from 'react';
import { Box } from 'react-layout-components';
import { t } from 'ttag';

import { IMPORT_EXTENSIONS_AND_MIMETYPES_PLUS_CSV } from 'uf/import/mimetypes';
import FileUpload from 'uf/ui/base/FileUpload/FileUpload';
import { IconSizeNew } from 'uf/ui/base/icon';
import { supportedLayersHint, supportedTypesPrompt } from 'uf/ui/importer/text';

const initialState = Object.freeze({
  file: null,
  name: null,
});

// TODO: Converted with script per issue #10495. Improve typing on next edit.
interface LayerImportFormProps {
  disabled?: boolean;
  onFormUpdate?: (...args: any[]) => any;
}
interface LayerImportFormState {
  file: File;
  name: string;
}
export default class LayerImportForm extends Component<
  LayerImportFormProps,
  LayerImportFormState
> {
  constructor(props) {
    super(props);
    this.state = initialState;
  }
  @boundMethod
  onDrop(file: File, nameSuggestion: string) {
    const { onFormUpdate } = this.props;
    this.setState({ file, name: nameSuggestion });
    // file is null if the file is rejected
    if (file && onFormUpdate) {
      onFormUpdate({ file, name: nameSuggestion });
    }
  }
  @boundMethod
  onLayerNameChanged(event) {
    const { value: name } = event.target;
    this.setState({ name });
    const { onFormUpdate } = this.props;
    const { file } = this.state;
    onFormUpdate({ file, name });
  }
  render() {
    const { file, name } = this.state;
    const { disabled } = this.props;
    const uploadStyle = {
      paddingLeft: '33%',
      paddingRight: '33%',
    };
    return (
      <Box column alignItems="stretch">
        <FileUpload
          accept={IMPORT_EXTENSIONS_AND_MIMETYPES_PLUS_CSV}
          disabled={disabled}
          style={uploadStyle}
          emptyMessage={<h3 className="text-center">{supportedTypesPrompt}</h3>}
          onDrop={this.onDrop}
        />
        <Callout
          icon={
            <Icon
              className={Classes.ICON}
              path={mdiInformationVariant}
              size={IconSizeNew.MEDIUM}
            />
          }>
          {supportedLayersHint}
        </Callout>
        {file && (
          <FormGroup
            inline
            label={t`Layer display name`}
            contentClassName={Classes.FLEX_EXPANDER}>
            <InputGroup
              type="text"
              value={name}
              onChange={this.onLayerNameChanged}
            />
          </FormGroup>
        )}
      </Box>
    );
  }
}
