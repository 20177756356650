import { FeatureCollection } from 'geojson';

import { ClientResponse } from 'uf-api';
import { getLayerGeojsonActionTypes } from 'uf-api/api/layer.service';
import { LayerServiceInterface } from 'uf-api/api/layer.serviceInterface';
import { parseFullPath } from 'uf/base/dataset';
import { makeAsyncApiCaller } from 'uf/base/xhr';
import { dispatchAsyncAction, makeEnsureActionCreator } from 'uf/data/loader';
import { LayerId } from 'uf/layers';
import { LAYER_DATA_LIMIT } from 'uf/layers/apis';
import {
  getFilterClause,
  getStatsSearchKey,
  getLayerDataApiParams,
  LayerDataParams,
} from 'uf/layers/filters';
import { makeGetGeojsonByKey } from 'uf/layers/selectors/geojson';

export const fetchLayerGeojson = makeAsyncApiCaller<
  LayerServiceInterface.getLayerGeojsonParams,
  FeatureCollection
>(api => {
  const caller = api.layer.get_layer_geojson;

  return caller as (
    params: LayerServiceInterface.getLayerGeojsonParams,
    extraHttpRequestParams?: any,
  ) => Promise<ClientResponse<FeatureCollection>>;
});

export function loadLayerGeojson(layerId: LayerId, params: LayerDataParams) {
  const { key: layerKey, namespace, type } = parseFullPath(layerId);
  const { key, apiParams } = getLayerDataApiParams(layerId, params);
  const realParams = {
    ...apiParams,
    namespace,
    key: layerKey,
    layer_type: type,
    limit: LAYER_DATA_LIMIT,
    ...getFilterClause(apiParams.filters),
  };
  return dispatchAsyncAction(
    getLayerGeojsonActionTypes,
    fetchLayerGeojson(realParams),
    { key },
  );
}

export const ensureLayerGeojson = makeEnsureActionCreator(
  loadLayerGeojson,
  makeGetGeojsonByKey(),
  getStatsSearchKey,
);
