import { makeListActionCreatorsWithExtra } from 'uf/base/listActionHelpers';
import { registerPersistedAction } from 'uf/persistence';
import { registerListPersistence } from 'uf/persistence/lists';
import { ProjectId } from 'uf/projects';
import { LegacyVirtualLayerId } from 'uf/projects/virtualLayers';
import { DEFAULT_VIEW_ID, ViewId } from 'uf/views';
import {
  CLEAR_VISIBLE_LAYERS,
  ClearVisibleLayersAction,
  SET_LAYER_ORDER,
  SET_LAYER_VISIBILITY,
  SetLayerOrderAction,
  SetLayerVisibilityAction,
  visibleLayerListActionTypes,
} from 'uf/views/ActionTypes';
import { makeGetViewVisibleVirtualLayers } from 'uf/views/selectors/layers';

/**
 * Set an individual layer visibility
 *
 * @deprecated
 * @see visibleLayersListActions
 * @param projectId
 * @param virtualLayerId the virtual id (not used yet)
 */
export function setLayerVisibility(
  projectId: ProjectId,
  virtualLayerId: LegacyVirtualLayerId,
  viewId: ViewId,
  visible: boolean = true,
): SetLayerVisibilityAction {
  if (!projectId) {
    throw Error('missing projectId');
  }

  return {
    type: SET_LAYER_VISIBILITY,
    projectId,
    virtualLayerId,
    viewId,
    visible,
  };
}

export function clearVisibleLayers(
  projectId: ProjectId,
  viewId: ViewId,
): ClearVisibleLayersAction {
  return {
    type: CLEAR_VISIBLE_LAYERS,
    projectId,
    viewId,
  };
}
export function setLayerOrder(
  projectId: ProjectId,
  viewId: ViewId,
  layerOrder: LegacyVirtualLayerId[],
): SetLayerOrderAction {
  return {
    type: SET_LAYER_ORDER,
    projectId,
    viewId,
    layerOrder,
  };
}

// TODO: remove this when we've completely migrated to views
registerPersistedAction<SetLayerOrderAction, ProjectId, LegacyVirtualLayerId[]>(
  'layerOrder',
  SET_LAYER_ORDER,
  (projectId: ProjectId, layerOrder: LegacyVirtualLayerId[]) =>
    // Note: The view-specific layer order will also call this
    setLayerOrder(projectId, DEFAULT_VIEW_ID, layerOrder),
  {
    getKey({ projectId }) {
      return projectId;
    },

    getValue({ layerOrder }) {
      return layerOrder;
    },
  },
);

registerPersistedAction<
  SetLayerOrderAction,
  [ProjectId, ViewId],
  LegacyVirtualLayerId[]
>(
  'viewLayerOrder',
  SET_LAYER_ORDER,
  ([projectId, viewId], layerOrder: LegacyVirtualLayerId[]) =>
    // Note: The view-specific layer order will also call this
    setLayerOrder(projectId, viewId, layerOrder),
  {
    getKey({ projectId, viewId }) {
      return [projectId, viewId];
    },

    getValue({ layerOrder }) {
      return layerOrder;
    },
  },
);

interface ExtraParams {
  projectId: ProjectId;
  viewId: ViewId;
}

function makeExtraParams(projectId: ProjectId, viewId: ViewId): ExtraParams {
  return { projectId, viewId };
}
export const visibleLayersListActions = makeListActionCreatorsWithExtra<
  LegacyVirtualLayerId,
  Parameters<typeof makeExtraParams>,
  ExtraParams
>(visibleLayerListActionTypes, makeExtraParams);

registerListPersistence(
  'viewLayersVisible',
  visibleLayersListActions,
  visibleLayerListActionTypes,
  {
    getKey({ projectId, viewId }) {
      return [projectId, viewId];
    },
    getValue({ projectId, viewId }, state) {
      const getVisibleLayersList = makeGetViewVisibleVirtualLayers();
      const layerIds = getVisibleLayersList(state, { projectId, viewId });
      return layerIds;
    },
  },
);
