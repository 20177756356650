import { Tag } from '@blueprintjs/core';
import { mdiFilter, mdiLinkVariant, mdiMapOutline, mdiUpdate } from '@mdi/js';
import Icon from '@mdi/react';
import classNames from 'classnames';
import React, { Fragment, FunctionComponent, ReactNode } from 'react';
import { Box, ScrollView } from 'react-layout-components';
import { t } from 'ttag';

import { LayerMetadata } from 'uf-api';
import { hasVisibleParent } from 'uf/layers/metadata';
import rootStyles from 'uf/styles/root.module.css';
import textStyles from 'uf/styles/text.module.css';
import tw from 'uf/tailwindcss-classnames';
import { CopyableText } from 'uf/ui/base/CopyableText/CopyableText';
import { IconSizeNew } from 'uf/ui/base/icon';
import WithLayerMetadata from 'uf/ui/layers/hocs/withLayerMetadata';
import Source from 'uf/ui/layers/LayerMetadataSummary/Source';
import useUserFlag from 'uf/ui/user/useUserFlag';
import FavoriteLayerStar from './FavoriteLayerStar';

import localStyles from './LayerMetadataSummary.module.css';

const DESCRIPTION_BOX_HEIGHT = 130;

const updatedLayerDescriptionClassName = classNames(
  rootStyles.wrapLongWords,
  rootStyles.veryThinMarginTop,
  rootStyles.smallMarginRight,
  'h4',
);

const devLayerMetadataClassName = classNames(
  'h5',
  localStyles.layerDescriptionLineHeight,
  rootStyles.breakWord,
  textStyles.mutedText,
);

const layerMetadataInfoClassName = rootStyles.smallMarginBottom;

const dataAttributesWrapperClassName = rootStyles.smallMarginBottom;

const sourceLinkClassName = classNames('h4', textStyles.activeColor);

interface Props {
  isLayerFavoritable: boolean;
  layerMetadata: LayerMetadata;
  displayPanelView: boolean;
  showTitle: boolean;
  onSearchInputChangeByTag?: (value: string) => void;
}

export const LayerHeader: FunctionComponent<Props> = props => {
  const {
    isLayerFavoritable,
    layerMetadata,
    displayPanelView,
    showTitle,
    onSearchInputChangeByTag,
  } = props;
  const descriptionTagsWrapperClassName = classNames(
    localStyles.descriptionTagsWrapper,
    {
      [localStyles.simplifiedDescriptionTagsWrapper]: displayPanelView,
    },
  );
  const hasDevLayerMetadataExtrasFlag = useUserFlag(
    'dev-layer-metadata-extras',
  );
  const { source, description, display_hints: displayHints } = layerMetadata;
  const hasSource = source && (source.url || source.name);
  const sourceLink: ReactNode = hasSource ? (
    <span className={sourceLinkClassName}>
      <Source source={source} />
    </span>
  ) : null;
  const hasParent = hasVisibleParent(layerMetadata);
  return (
    <Fragment>
      {showTitle && (
        <Box
          className={classNames(
            rootStyles.shrink,
            rootStyles.smallPaddingBottom,
            rootStyles.shrink0,
          )}>
          {isLayerFavoritable && (
            <FavoriteLayerStar
              className={classNames(
                rootStyles.shrink,
                rootStyles.shrink0,
                tw('tw-ml-0'),
              )}
              layerKey={layerMetadata.key}
              layerNamespace={layerMetadata.namespace}
              layerType={layerMetadata.layer_type}
            />
          )}
          <h2 className={tw('tw-leading-8')}>{layerMetadata.name}</h2>
        </Box>
      )}

      <Box flexShrink={0} wrap="reverse" className={layerMetadataInfoClassName}>
        <Box column className={descriptionTagsWrapperClassName}>
          {!displayPanelView && displayHints.search_tags && (
            <Box wrap className={rootStyles.thinMarginBottom}>
              {displayHints.search_tags.map((tag: string) => (
                <Tag
                  key={tag}
                  interactive
                  minimal
                  intent={'success'}
                  className={rootStyles.veryThinMargin}
                  // eslint-disable-next-line no-restricted-syntax
                  onClick={event => onSearchInputChangeByTag(tag)}>
                  {tag}
                </Tag>
              ))}
            </Box>
          )}
          <Box
            flex={4}
            height={DESCRIPTION_BOX_HEIGHT}
            className={updatedLayerDescriptionClassName}>
            <ScrollView height="100%">
              {hasDevLayerMetadataExtrasFlag && (
                <Box column wrap className={rootStyles.thinMarginBottom}>
                  {layerMetadata.supply_pattern && (
                    <span className={devLayerMetadataClassName}>
                      Supply pattern:{' '}
                      <CopyableText text={layerMetadata.supply_pattern} />
                    </span>
                  )}
                  <span className={devLayerMetadataClassName}>
                    Full path: <CopyableText text={layerMetadata.full_path} />
                  </span>
                  <span className={devLayerMetadataClassName}>
                    Geometry type key: {layerMetadata.geometry_type_key}
                  </span>
                  <span className={devLayerMetadataClassName}>
                    Vintage: {layerMetadata.display_hints.vintage}
                  </span>
                  <span className={devLayerMetadataClassName}>
                    Layer type: {layerMetadata.layer_type}
                  </span>
                </Box>
              )}
              {description || (
                <small>{t`No description provided by source`}</small>
              )}
            </ScrollView>
          </Box>
        </Box>
        <Box flex="1 0 0" column className={dataAttributesWrapperClassName}>
          <LayerProp
            label={t`Source`}
            value={sourceLink}
            iconPath={mdiLinkVariant}
          />
          <LayerProp
            label={t`Scale`}
            value={displayHints.jurisdictional_scale}
            iconPath={mdiMapOutline}
          />
          <LayerProp
            label={t`Last updated from source`}
            unavailableLabel={t`Unavailable from source`}
            value={displayHints.as_of_date}
            iconPath={mdiUpdate}
          />
          {hasParent && (
            <LayerProp
              label={t`Filtered from layer`}
              value={
                <WithLayerMetadata layerId={displayHints.parent.full_path}>
                  {({ layerMetadata: parentLayerMetadata }) =>
                    parentLayerMetadata ? parentLayerMetadata.name : null
                  }
                </WithLayerMetadata>
              }
              iconPath={mdiFilter}
            />
          )}
        </Box>
      </Box>
    </Fragment>
  );
};

const attributeTypeWrapperClassName = rootStyles.smallMarginBottom;

const attributeTypeTextClassName = classNames(
  rootStyles.thinMarginLeft,
  rootStyles.veryThinMarginTop,
);

const attributeTypeTitleClassName = classNames(
  textStyles.mutedText,
  textStyles.fontSizeH5,
);

interface LayerPropProps {
  label: string;
  unavailableLabel?: string;
  value: ReactNode;
  iconPath: string;
}

const layerPropIconClassName = classNames(
  rootStyles.veryThinMarginTop,
  textStyles.mutedText,
);
const LayerProp: FunctionComponent<LayerPropProps> = ({
  label,
  unavailableLabel = t`Unavailable`,
  value,
  iconPath,
}) => {
  return (
    <Box className={attributeTypeWrapperClassName}>
      <Icon
        className={layerPropIconClassName}
        path={iconPath}
        size={IconSizeNew.SMALL}
      />
      <Box flex={1} column className={attributeTypeTextClassName}>
        <span className={attributeTypeTitleClassName}>{label}</span>
        <span className="h4">
          {value}
          {!value && <small>{unavailableLabel}</small>}
        </span>
      </Box>
    </Box>
  );
};
