import {
  CuratedColumnSymbology,
  CUSTOM_THEME,
  DEFAULT_OPACITY,
  InterpolationTypes,
  PaintColorTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

import stateList from './us_states';

const symbologies: Record<
  string,
  Record<string, CuratedColumnSymbology[]>
> = {};

stateList.forEach(stateName => {
  symbologies[`/public/dataset/nhd_waterbody_${stateName}`] = {
    ftype: [
      {
        type: SymbologyTypes.FILL,
        paint: {
          'fill-color': {
            property: 'ftype',
            type: PaintColorTypes.CATEGORICAL,
            stops: [
              { value: 361, label: 'Playa', color: '#8fe2e6' },
              { value: 378, label: 'Ice Mass', color: '#def9fb' },
              { value: 390, label: 'Lake/Pond', color: '#31b5ff' },
              { value: 436, label: 'Resevoir', color: '#8795b8' },
              { value: 466, label: 'Swamp Marsh', color: '#86a255' },
              { value: 493, label: 'Estuary', color: '#02697a' },
            ],
          },
          'fill-opacity': DEFAULT_OPACITY,
        },
        display_hints: {
          scale: SymbologyScales.CATEGORICAL,
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          theme: CUSTOM_THEME,
        },
      },
    ],
  };
});

export default symbologies;
