import { Spinner } from '@blueprintjs/core';
import classNames from 'classnames';
import { Feature } from 'geojson';
import React, { FunctionComponent, useCallback } from 'react';
import { Box } from 'react-layout-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ThunkActionMapDispatch } from 'redux-thunk';
import { t } from 'ttag';

import { LayerMetadata } from 'uf-api';
import { DataState, getData, isLoading } from 'uf/data/dataState';
import { makeGetLayerEditsSelectedFeatures } from 'uf/explore/selectors/drawingMode';
import { LayerId } from 'uf/layers';
import { getNativeValue } from 'uf/layers/formatting';
import { getColumnMetadata } from 'uf/layers/metadata';
import { makeGetLayerMetadataState } from 'uf/layers/selectors/metadata';
import { featureEditListActions } from 'uf/mapedit/actions/features';
import { ProjectId } from 'uf/projects';
import rootStyles from 'uf/styles/root.module.css';
import { FeatureValueEditor } from 'uf/ui/mapedit/FeatureValueEditor/FeatureValueEditor';

interface OwnProps {
  projectId: ProjectId;
  layerId: LayerId;
}

interface StateProps {
  layerMetadataState: DataState<LayerMetadata>;

  features: Feature[];
}
interface DispatchProps {
  mapeditFeatureActions: ThunkActionMapDispatch<
    Partial<typeof featureEditListActions>
  >;
}
type Props = OwnProps & StateProps & DispatchProps;

export const FeatureEditor: FunctionComponent<Props> = props => {
  const {
    layerMetadataState,
    features,
    mapeditFeatureActions,
    projectId,
    layerId,
  } = props;
  const layerMetadata = getData(layerMetadataState);

  const onChange = useCallback(
    (key: string, value: string | number | boolean) => {
      const nativeValue = getNativeValue(
        value,
        getColumnMetadata(layerMetadata, key),
      );
      const feature = {
        ...features[0],
        properties: {
          ...features[0].properties,
          [key]: nativeValue,
        },
      };
      mapeditFeatureActions.updateItem(feature, null, projectId, layerId);
    },
    [features, layerId, projectId, layerMetadata, mapeditFeatureActions],
  );

  if (isLoading(layerMetadataState)) {
    return <Spinner />;
  }

  if (features.length === 0) {
    return <div>{t`No feature`}</div>;
  }
  if (features.length > 1) {
    const featureCount = features.length;
    return <div>{t`Too many (${featureCount}) features selected`}</div>;
  }

  const metadata = getData(layerMetadataState);
  const { columns = [] } = metadata;
  const feature = features[0];
  return (
    <Box
      column
      className={classNames(rootStyles.scrollY, rootStyles.thinPadding)}>
      {columns.map(column => (
        <FeatureValueEditor
          onChange={onChange}
          key={column.key}
          column={column}
          value={feature.properties[column.key]}
        />
      ))}
    </Box>
  );
};

export default connect<StateProps, DispatchProps, OwnProps>(
  () => {
    const getLayerMetadataState = makeGetLayerMetadataState();
    const getLayerEditsSelectedFeatures = makeGetLayerEditsSelectedFeatures();
    return (state, props: OwnProps): StateProps => ({
      layerMetadataState: getLayerMetadataState(state, {
        layerId: props.layerId,
      }),

      features: getLayerEditsSelectedFeatures(state, {
        layerId: props.layerId,
        projectId: props.projectId,
      }),
    });
  },
  dispatch => ({
    mapeditFeatureActions: bindActionCreators(
      { ...featureEditListActions },
      dispatch,
    ),
  }),
)(FeatureEditor);
