import { Feature, GeoJsonGeometryTypes } from 'geojson';
import _ from 'lodash';
import { Expression, Layer, LinePaint } from 'mapbox-gl';
import colors from 'material-colors';

import { LayerReference } from 'uf-api';
import { EMPTY_ARRAY } from 'uf/base';
import { MapboxExpression } from 'uf/base/map';
import { SPLIT_PREVIEW_FEATURE } from 'uf/featuresplit/split';
import { LayerId } from 'uf/layers';
import { GEOMETRY_KEY } from 'uf/layers/geometryKey';
import { LayerSource, StyleLayerInfo, UFMapLayer } from 'uf/map';
import {
  getStyleLayerUFMetadata,
  StyleLayerMetadata,
} from 'uf/map/stylelayers/stylelayers';
import { makeSourceId } from 'uf/map/tileSources';
import { SymbologyTypes } from 'uf/symbology';
import { makeStyleSymbologyId } from 'uf/symbology/spec/id';

import { makeSelectionStyleLayers } from './selection';

const EDITS_SOURCE_SENTINEL = '__ALL_FEATURES__';
const PREVIEW_EDITS_SOURCE_SENTINEL = '__PREVIEW_FEATURES__';
const EDITS_COLUMN_SENTINEL = '__EDITS__';

export function makeEditsTileSourceId(layerId: LayerId) {
  return makeSourceId(layerId, EDITS_SOURCE_SENTINEL, EDITS_COLUMN_SENTINEL);
}

const editsPreviewLinePaintStyle: LinePaint = {
  'line-color': colors.orange['500'],
  'line-opacity': 1,
  'line-width': 2,
};
export function makeEditsStyleLayerId(
  layerId: LayerId,
  type: SymbologyTypes,
): LayerId {
  // hack, calling this a line
  return makeStyleSymbologyId(layerId, EDITS_SOURCE_SENTINEL, type);
}

const previewFeaturesFilter: Expression = [
  '==',
  ['get', SPLIT_PREVIEW_FEATURE],
  true,
];

const nonPreviewFeaturesFilter: Expression = [
  '!=',
  ['get', SPLIT_PREVIEW_FEATURE],
  true,
];

export function makePreviewEditsStyleLayerId(
  layerId: LayerId,
  type: SymbologyTypes,
) {
  // hack, calling this a line
  return makeStyleSymbologyId(layerId, PREVIEW_EDITS_SOURCE_SENTINEL, type);
}

/**
 * Generate tile sources
 */
export function makeEditsStyleLayers(
  layerId: LayerId,
  styleLayerInfo: StyleLayerInfo,
  features: Feature[],
): Layer[] {
  if (_.isEmpty(features)) {
    return EMPTY_ARRAY;
  }
  const hasPreviewFeatures = features.some(
    feature => feature.properties[SPLIT_PREVIEW_FEATURE],
  );

  return styleLayerInfo.styleLayers.flatMap(styleLayer => {
    // transform the style layer to source its data from the corresponding geojson edits layer
    let filter = nonPreviewFeaturesFilter;
    if (!_.isEmpty(styleLayer.filter)) {
      filter = ['all', styleLayer.filter, nonPreviewFeaturesFilter];
    }

    let uf: StyleLayerMetadata = {
      ...getStyleLayerUFMetadata(styleLayer),
      editsLayer: true,
      layerId,
    };
    const layer: Layer = {
      ...styleLayer,
      source: makeEditsTileSourceId(layerId),
      // HACK: this conversion shouldn't be allowed
      id: makeEditsStyleLayerId(layerId, styleLayer.type as SymbologyTypes),
      metadata: {
        ...styleLayer.metadata,
        uf,
      },
      filter,
    };

    delete layer['source-layer'];

    if (hasPreviewFeatures && styleLayer.type === 'line') {
      uf = {
        ...getStyleLayerUFMetadata(styleLayer),
        previewEditsLayer: true,
      };
      const previewLayer: UFMapLayer = {
        ...styleLayer,
        source: makeEditsTileSourceId(layerId),
        metadata: {
          ...styleLayer.metadata,
          uf,
        },
        id: makePreviewEditsStyleLayerId(
          layerId,
          styleLayer.type as SymbologyTypes,
        ),
        paint: { ...styleLayer.paint, ...editsPreviewLinePaintStyle },
        filter: previewFeaturesFilter,
      };
      delete previewLayer['source-layer'];

      // note that the preview layer needs to draw on top of the edits layer
      return [layer, previewLayer];
    }
    return [layer];
  });
}

export function makeEditsSelectionStyleLayers(
  layerId: LayerId,
  sourceId: string,
  type: GeoJsonGeometryTypes,
  selectedFeatures: Feature[],
): Layer[] {
  if (!selectedFeatures.length) {
    return EMPTY_ARRAY;
  }
  const styleLayers = makeSelectionStyleLayers(
    layerId,
    'EDITS_SELECTION_VERSION',
    'EDITS_SELECTION_COLUMN',
    null,
    type,
    [],
    true,
  );
  const includeExpression = makeIncludeExpressionByFeatureIds(selectedFeatures);
  const newLayers: Layer[] = styleLayers.map(styleLayer => {
    const extraPaintStyle = ['line'].includes(styleLayer.type)
      ? { 'line-width': 4 }
      : {};

    const newLayer: Layer = {
      ...styleLayer,
      id: `${styleLayer.id}|${EDITS_COLUMN_SENTINEL}`,
      source: sourceId,
      paint: {
        ...styleLayer.paint,
        ...extraPaintStyle,
      },
    } as Layer;
    if (includeExpression) {
      newLayer.filter = ['all', styleLayer.filter, includeExpression];
    }
    delete newLayer['source-layer'];
    return newLayer;
  });
  return newLayers;
}

function makeIncludeExpressionByFeatureIds(
  features: Feature[],
): MapboxExpression {
  const geometryKeys = features
    .map(feature => feature.properties[GEOMETRY_KEY])
    .filter(geometryKey => geometryKey);
  const uniqueGeometryKeys = Array.from(new Set(geometryKeys));
  return ['match', ['get', GEOMETRY_KEY], uniqueGeometryKeys, true, false];
}

/**
 * Generate a tile source with embedded geojson for the features passed in
 */
export function makeEditsTileSource(
  layerId: LayerId,
  features: Feature[] = EMPTY_ARRAY,
): LayerSource {
  const sourceId = makeEditsTileSourceId(layerId);

  const editsSource: LayerSource = {
    id: sourceId,
    source: {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features,
      },
    },
  };

  return editsSource;
}

export function mapLayerReferencesToEditsTileSources(
  layers: LayerReference[],
  featuresByLayerId: Record<string, Feature[]>,
): LayerSource[] {
  return layers
    .map(({ full_path: layerId }) => {
      const featureList = featuresByLayerId[layerId];
      if (_.isEmpty(featureList)) {
        return null;
      }
      const sources = makeEditsTileSource(layerId, featureList);
      return sources;
    })
    .filter(layer => !!layer);
}
