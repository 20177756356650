import { EMPTY_OBJECT } from 'uf/base';
import { LayerId } from 'uf/layers';
import { FilterSpec } from 'uf/layers/filters';

export function getLayerFilters(
  allFilters: Record<string, Partial<FilterSpec>>,
  layerId: LayerId,
  parentLayerId: string,
): Partial<FilterSpec> {
  if (!parentLayerId) {
    return allFilters[layerId] || EMPTY_OBJECT;
  }
  return (
    allFilters?.[parentLayerId]?.layers?.[layerId] ||
    (EMPTY_OBJECT as FilterSpec)
  );
}
