import { FeatureCollection } from '@turf/helpers';
import _ from 'lodash';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  useGetLayerGeojsonQuery,
  useGetLayerQuery,
} from 'uf-api-rtk/store/Api';
import {
  getActiveProjectId,
  makeGetActiveScenarioIdForProject,
} from 'uf/app/selectors';
import { EMPTY_ARRAY } from 'uf/base';
import { parseFullPath } from 'uf/base/dataset';
import { convertLayerDataToDataTable } from 'uf/explore/selectors/data';
import { makeGetLayerFilters } from 'uf/explore/selectors/filters';
import { getActiveLayerId } from 'uf/explore/selectors/layers';
import { makeGetActiveViewId } from 'uf/explore/selectors/views';
import {
  convertFiltersToExpressions,
  getFilterClause,
} from 'uf/layers/filters';
import { LayerTableData } from 'uf/layers/layerData';
import { makeGetLayerVersion } from 'uf/layers/selectors/versions';
import useColumnState from './useColumnState';

// Initialize selector creators
const selectLayerFilters = makeGetLayerFilters();
const selectLayerVersion = makeGetLayerVersion();
const selectActiveViewId = makeGetActiveViewId();
const selectActiveScenarioId = makeGetActiveScenarioIdForProject();

const useTableData = () => {
  // The current sorting state of the table
  const { sortColumn, sortDirection } = useColumnState();
  // Get arguments for layer requests
  const projectId = useSelector(getActiveProjectId);
  const viewId = useSelector(state => selectActiveViewId(state, { projectId }));
  const scenarioId = useSelector(state =>
    selectActiveScenarioId(state, { projectId }),
  );
  const layerId = useSelector(state =>
    getActiveLayerId(state, {
      projectId,
      viewId,
      scenarioId,
    }),
  );
  // Filters applied via the 'Filter' tab in Layer Details
  const filters = useSelector(state =>
    selectLayerFilters(state, { projectId, layerId, parentLayerId: null }),
  );
  // Get current layer version
  const version = useSelector(state => selectLayerVersion(state, { layerId }));
  // Destructured layer ID elements
  const { namespace, type: layerType, key } = parseFullPath(layerId);
  // String formatted filter clause
  const layerFilters = getFilterClause(
    convertFiltersToExpressions(filters),
  )?.filters;
  // Compose arguments
  const layerArgs = { namespace, layerType, key };
  const optionalLayerArgs = {
    filters: layerFilters,
    version,
    ...(sortColumn && { sortColumn, sortDirection }),
  };
  const skipConditions = !projectId || !layerId;
  // Get layer data for table display
  const { data, ...status } = useGetLayerGeojsonQuery(
    {
      ...layerArgs,
      ...optionalLayerArgs,
    },
    { skip: skipConditions },
  );
  // Type coercion for responds data
  const featureData = data as FeatureCollection;
  // Get layer metadata for optional column mapping
  const { data: metadata } = useGetLayerQuery(layerArgs, {
    skip: skipConditions,
  });
  const formattedData = useMemo(() => {
    const dataTable = convertLayerDataToDataTable(
      _.map(featureData?.features, 'properties'),
      metadata,
      {},
    );
    const cleanedDataTable: LayerTableData = {
      cols: dataTable.cols ?? EMPTY_ARRAY,
      rows: dataTable.rows ?? EMPTY_ARRAY,
    };
    return cleanedDataTable;
  }, [featureData, metadata]);
  return {
    data: formattedData,
    features: featureData?.features,
    ...status,
  };
};

export default useTableData;
