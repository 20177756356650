import {
  mdiAlertOutline,
  mdiCheckBoxOutline,
  mdiCloseCircleOutline,
  mdiHelpCircleOutline,
  mdiInformation,
} from '@mdi/js';
import Icon from '@mdi/react';
import classNames from 'classnames';
import React from 'react';
import { Box } from 'react-layout-components';
import { IconSizeNew } from 'uf/ui/base/icon';
import styles from './MessageArea.module.css';

interface MessageAreaProps {
  level?: string;
  title?: string;
  className?: string;
  style?: React.CSSProperties;
}

const MessageArea: React.FunctionComponent<MessageAreaProps> = ({
  className,
  style,
  children,
  title,
  level = 'info',
}) => {
  return (
    <Box className={classNames(styles.messageArea, className)} style={style}>
      <Box column>
        <Icon
          path={getPathForLevel(level)}
          className={classNames(styles.messageIcon, styles[level])}
          size={IconSizeNew.SMALL}
        />
      </Box>
      <Box column justifyContent="center">
        {title && <div className="h3">{title}</div>}
        <div className={styles.message}>{children}</div>
      </Box>
    </Box>
  );
};

export default MessageArea;

function getPathForLevel(level) {
  switch (level) {
    case 'info':
      return mdiInformation;
    case 'warning':
      return mdiAlertOutline;
    case 'success':
      return mdiCheckBoxOutline;
    case 'error':
      return mdiCloseCircleOutline;
    default:
      return mdiHelpCircleOutline;
  }
}
