import classNames from 'classnames';

import popupStyles from 'uf/styles/popups.module.css';

export enum Sizes {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
  XLARGE = 'x-large',
  CUSTOM = 'custom',
}

export const dialogClasses: Record<Sizes, string> = {
  [Sizes.SMALL]: classNames(popupStyles.dialog, popupStyles.dialogSmall),
  [Sizes.MEDIUM]: classNames(popupStyles.dialog, popupStyles.dialogMedium),
  [Sizes.LARGE]: classNames(popupStyles.dialog, popupStyles.dialogLarge),
  [Sizes.XLARGE]: classNames(popupStyles.dialog, popupStyles.dialogXLarge),
  [Sizes.CUSTOM]: popupStyles.dialog,
};
