import _ from 'lodash';
import { createSelector } from 'reselect';
import warning from 'warning';

import {
  LayerReference,
  ProjectMetadata,
  ScenarioMetadata,
} from 'uf-api/model/models';
import { EMPTY_ARRAY, EMPTY_OBJECT } from 'uf/base';
import { cacheableConcat } from 'uf/base/array';
import { UFLngLatBounds } from 'uf/base/map';
import { makeGetFromPropsSelector } from 'uf/base/selector';
import { DataState, EMPTY_STATE, getData } from 'uf/data/dataState';
import { getScenarios, ProjectId } from 'uf/projects';
import { getReferenceLayersFromLayerList } from 'uf/projects/layerList';
import { getProjectBaseCanvasLayerReference } from 'uf/projects/projectLayers';
import {
  getAvailableProjectStates,
  makeGetProjectById,
  makeGetProjectScenarioIds,
  makeGetProjectScenarios,
  makeGetScenarioById,
} from 'uf/projects/selectors';
import {
  getProjectBoundaryLayerReferences,
  makeGetProjectAllWorkingLayerReferences,
  makeGetProjectBaseCanvasLayerReference,
  makeGetProjectReferenceLayers,
  makeGetScenarioCanvasLayerReference,
} from 'uf/projects/selectors/layers';
import { ScenarioId } from 'uf/scenarios';
import { getScenarioAnalysisLayerReferences } from 'uf/scenarios/analysisLayers';

import { UFState } from 'uf/state';
import { AppScenarioInfo } from './state';
import { selectApp } from './slice';

export const selectActiveViewId = (state: UFState) =>
  selectApp(state).activeViewId;
export const selectActiveLayerId = (state: UFState) =>
  selectApp(state).activeLayerId;

export const getFrontendVersion = (state: UFState) =>
  selectApp(state).frontendVersion;

const getActiveScenarioIdByProjectId = createSelector(
  selectApp,
  appState => appState.activeScenario,
);

export function makeGetActiveScenarioIdForProject() {
  return createSelector(
    makeGetFromPropsSelector<ProjectId, 'projectId'>('projectId'),
    getActiveScenarioIdByProjectId,
    makeGetProjectScenarioIds(),
    (projectId, activeScenarioByProjectId, scenarioIds): ScenarioId => {
      const scenarioId = activeScenarioByProjectId[projectId];
      if (!scenarioId || !scenarioIds.includes(scenarioId)) {
        return scenarioIds[0] ?? null;
      }
      return scenarioId;
    },
  );
}

export const getActiveProjectId = createSelector(
  selectApp,
  (appState): ProjectId => appState.activeProjectId,
);

export const getActiveProjectState = createSelector(
  getAvailableProjectStates,
  getActiveProjectId,
  (projects, projectId) =>
    projects[projectId] || (EMPTY_STATE as DataState<ProjectMetadata>),
);

export const getActiveProject = createSelector(
  getActiveProjectState,
  (projectState): ProjectMetadata => getData(projectState, EMPTY_OBJECT),
);

/**
 * This is the bounds of the project area - i.e. what we used to carve out the
 * canvas.
 */
export const getActiveProjectProjectBounds = createSelector(
  getActiveProject,
  (project): UFLngLatBounds => {
    if (_.isEmpty(project)) {
      return null;
    }
    const { project_map_bounds: projectMapBounds } = project;
    const bounds: UFLngLatBounds = [
      [projectMapBounds[0], projectMapBounds[1]],
      [projectMapBounds[2], projectMapBounds[3]],
    ];

    return bounds;
  },
);

/**
 * This is the bounds of the project *including* the context area
 */
export const getActiveProjectContextBounds = createSelector(
  getActiveProject,
  (project): UFLngLatBounds => {
    if (_.isEmpty(project)) {
      return null;
    }
    const { map_bounds: mapBounds } = project;
    const bounds: UFLngLatBounds = [
      [mapBounds[0], mapBounds[1]],
      [mapBounds[2], mapBounds[3]],
    ];

    return bounds;
  },
);

// currently the 'base scenario' only has analysis layers associated with it.
export const getBaseScenario = createSelector(getActiveProject, project => {
  if (!project) {
    return EMPTY_OBJECT as ScenarioMetadata;
  }
  return project.base_scenario || (EMPTY_OBJECT as ScenarioMetadata);
});

export const getBaseScenarioId = createSelector(
  getBaseScenario,
  baseScenario => baseScenario.full_path,
);

export const getActiveProjectScenarios = createSelector(
  getActiveProject,
  project => {
    if (_.isEmpty(project)) {
      return EMPTY_ARRAY as ScenarioMetadata[];
    }
    return getScenarios(project);
  },
);

export const haveActiveProjectScenarios = createSelector(
  getActiveProjectScenarios,
  scenarios => !_.isEmpty(scenarios),
);

// TODO: This is used to key the scenario. We should rely on the
// backend instead, or use uniqid.
export const getNextScenarioOrdinal = createSelector(
  getActiveProject,
  project => {
    if (_.isEmpty(project)) {
      return 0;
    }
    return project.next_scenario_ordinal;
  },
);

const getScenarioData = createSelector(
  selectApp,
  (app): AppScenarioInfo => app.activeScenario || EMPTY_OBJECT,
);

/**
 * This gets the actual active scenario as set in redux. This should never be
 * exported, and if anything you almost always want `getActiveScenarioId`
 * below, anyway.
 */
const getActiveScenarioIdInternal = createSelector(
  getActiveProjectId,
  getScenarioData,
  (projectId, activeScenario) => activeScenario[projectId],
);

/**
 * Returns the base scenario when no scenario is selected. This will
 * return null when the current project hasn't yet loaded.
 */
export const getActiveScenario = createSelector(
  getActiveProjectScenarios,
  getActiveScenarioIdInternal,
  (scenarios, activeScenarioId) => {
    if (_.isEmpty(scenarios)) {
      return null;
    }
    if (!activeScenarioId) {
      return scenarios[0];
    }
    return _.find(scenarios, { full_path: activeScenarioId });
  },
);

/**
 * New version of getActiveScenarioId that returns the base scenario
 * id when no scenario is selected.
 *
 * @deprecated use `makeGetActiveScenarioForProject`
 */
export const getActiveScenarioId = createSelector(
  getActiveScenario,
  (scenario): ScenarioId => scenario?.full_path,
);

/**
 * When you select a project this is the layer information associated with that project.
 * Infos includes  name, info, key, tile-url etc.
 * */
export function makeGetProjectLayerReferences() {
  return createSelector(makeGetProjectById(), (project): LayerReference[] => {
    return getProjectLayerReferences(project);
  });
}

function getProjectLayerReferences(project: ProjectMetadata) {
  if (_.isEmpty(project)) {
    return EMPTY_ARRAY as LayerReference[];
  }
  const referenceLayers = getReferenceLayersFromLayerList(project);
  const workingLayers: LayerReference[] = project.working_layers || EMPTY_ARRAY;
  const boundaryLayers: LayerReference[] = [];
  if (project.project_area_layer) {
    boundaryLayers.push(project.project_area_layer);
  }
  if (project.context_area_layer) {
    boundaryLayers.push(project.context_area_layer);
  }
  const result: LayerReference[] = [
    ...workingLayers,
    ...boundaryLayers,
    ...referenceLayers,
  ];
  return result;
}

/**
 * Get the currently active organization's key.
 */
export const getActiveOrganizationKey = (state: UFState) => {
  return selectApp(state).activeOrgId;
};

export function makeGetProjectBoundaryLayerReferences() {
  return createSelector(
    makeGetProjectById(),
    getProjectBoundaryLayerReferences,
  );
}

export function makeGetProjectAllLayerReferences() {
  return createSelector(
    makeGetProjectById(),
    makeGetProjectScenarios(),
    (project, scenarios): LayerReference[] => {
      if (_.isEmpty(project)) {
        return EMPTY_ARRAY;
      }
      // TODO: Refactor to use existing selectors for these layer attributes.
      const hasBaseEditsCanvas =
        project.base_scenario?.base_edits_painted_canvas;
      const paintableLayers = hasBaseEditsCanvas
        ? [project.base_scenario.base_edits_painted_canvas]
        : [];

      const referenceLayers = getReferenceLayersFromLayerList(project);

      const workingLayerReferences = project.working_layers || EMPTY_ARRAY;
      const boundaryLayerReferences: LayerReference[] = [];
      if (project.project_area_layer) {
        boundaryLayerReferences.push(project.project_area_layer);
      }
      if (project.context_area_layer) {
        boundaryLayerReferences.push(project.context_area_layer);
      }
      const scenarioLayers = _.flatten(
        scenarios.map(scenario => {
          const scenarioPaintableLayers: LayerReference[] =
            scenario.painted_uf_canvas ? [scenario.painted_uf_canvas] : [];

          const scenarioAnalysisLayers =
            getScenarioAnalysisLayerReferences(scenario);
          return cacheableConcat(
            scenarioPaintableLayers,
            scenarioAnalysisLayers,
          );
        }),
      );
      // analysis layers for the base scenario are a prop on the project object
      const baseScenarioAnalysisLayers = getScenarioAnalysisLayerReferences(
        project.base_scenario,
      );
      return [
        ...paintableLayers,
        ...workingLayerReferences,
        ...boundaryLayerReferences,
        ...referenceLayers,
        ...scenarioLayers,
        ...baseScenarioAnalysisLayers,
      ];
    },
  );
}

/**
 * @deprecated in favor of makeGetProjectBaseCanvasLayerReference
 */
export const getActiveProjectBaseCanvasLayerReference = createSelector(
  getActiveProject,
  getProjectBaseCanvasLayerReference,
);

/**
 * @deprecated in favor of makeGetProjectBaseCanvasLayerId
 */
export const getActiveProjectBaseCanvasLayerId = createSelector(
  getActiveProjectBaseCanvasLayerReference,
  layerInfo => layerInfo.full_path,
);

export function makeGetProjectAllJoinableLayerReferences() {
  return createSelector(
    makeGetProjectReferenceLayers(),
    makeGetProjectAllWorkingLayerReferences(),
    makeGetProjectBoundaryLayerReferences(),
    makeGetProjectBaseCanvasLayerReference(),
    makeGetScenarioCanvasLayerReference(),
    makeGetFromPropsSelector<ScenarioId, 'scenarioId'>('scenarioId'),
    (
      referenceLayers,
      workingLayers,
      projectBoundaryLayers,
      baseCanvasLayers,
      scenarioCanvasLayers,
    ): LayerReference[] => {
      // List of layer types which a user is allow to join with
      const joinLayerTypes = ['dataset', 'dynamic'];
      const bufferedLayers: LayerReference[] = workingLayers.filter(layer =>
        joinLayerTypes.includes(layer.layer_type),
      );

      const joinableLayers: LayerReference[] = [];

      // If the base canvas doesn't exist yet, let's protect against that
      if (baseCanvasLayers) {
        joinableLayers.push(baseCanvasLayers);
      }

      // Also, the base scenario doesn't have a scenario (painted) canvas,
      // so let's protect against that.
      if (scenarioCanvasLayers) {
        joinableLayers.push(scenarioCanvasLayers);
      }

      joinableLayers.push(
        ...projectBoundaryLayers,
        ...referenceLayers,
        ...bufferedLayers,
      );

      // The above can overlap a bit
      return _.uniqBy(joinableLayers, 'full_path');
    },
  );
}

export function makeGetScenarioLayerReferences() {
  return createSelector(
    makeGetScenarioById(),
    makeGetProjectById(),
    makeGetFromPropsSelector<ScenarioId, 'scenarioId'>('scenarioId'),
    (activeScenario, activeProject): LayerReference[] => {
      return getScenarioLayerReferences(activeScenario, activeProject);
    },
  );
}

function getScenarioLayerReferences(
  activeScenario: ScenarioMetadata,
  activeProject: ProjectMetadata,
) {
  if (!activeScenario) {
    return EMPTY_ARRAY;
  }
  if (!activeScenario.base_scenario && !activeScenario.painted_uf_canvas) {
    console.warn('missing dev layer in ', activeScenario);
  }
  const baseLayerReference = activeScenario.base_scenario
    ? activeScenario.base_edits_painted_canvas
    : activeScenario.painted_uf_canvas;
  const inActiveScenario = !_.isEmpty(activeScenario);
  let analysisLayers: LayerReference[];
  if (inActiveScenario) {
    analysisLayers = getScenarioAnalysisLayerReferences(activeScenario);
  } else {
    // analysis layers for the base scenario are a prop on the project object
    analysisLayers = getScenarioAnalysisLayerReferences(
      activeProject.base_scenario,
    );
  }
  warning(
    !!baseLayerReference,
    `Missing base layer in project ${activeProject.full_path}, ` +
      `scenario ${activeScenario.full_path}`,
  );

  return cacheableConcat(
    baseLayerReference ? [baseLayerReference] : [],
    analysisLayers,
  );
}
export const getActiveScenarioCanvasLayerReference = createSelector(
  getActiveScenario,
  scenario => scenario?.painted_uf_canvas,
);

export const getActiveScenarioCanvasLayerId = createSelector(
  getActiveScenarioCanvasLayerReference,
  layer => layer?.full_path,
);

/**
 * @deprecated in favor of `makeGetScenarioAnalysisLayerReferences`
 */
export const getActiveScenarioAnalysisLayerReferences = createSelector(
  getActiveProject,
  getActiveScenario,
  (activeProject, activeScenario): LayerReference[] => {
    if (_.isEmpty(activeProject)) {
      return EMPTY_ARRAY;
    }
    if (_.isEmpty(activeScenario)) {
      return getScenarioAnalysisLayerReferences(activeProject.base_scenario);
    }
    return getScenarioAnalysisLayerReferences(activeScenario);
  },
);

export function makeGetScenarioAnalysisLayerReferences() {
  return createSelector(
    makeGetProjectById(),
    makeGetScenarioById(),
    makeGetFromPropsSelector<ScenarioId, 'scenarioId'>('scenarioId'),
    (project, scenario): LayerReference[] => {
      if (_.isEmpty(project)) {
        return EMPTY_ARRAY;
      }
      if (_.isEmpty(scenario)) {
        return getScenarioAnalysisLayerReferences(project.base_scenario);
      }
      return getScenarioAnalysisLayerReferences(scenario);
    },
  );
}

/**
 * @deprecated in favor of `makeGetProjectMapMaxBounds`
 */
export const getActiveProjectMapMaxBounds = createSelector(
  getActiveProject,
  ({ max_map_bounds: maxMapBounds }): UFLngLatBounds => {
    return getProjectMapMaxBounds(maxMapBounds);
  },
);

function getProjectMapMaxBounds(maxMapBounds: number[]) {
  if (_.isEmpty(maxMapBounds)) {
    return null;
  }

  const bounds: UFLngLatBounds = [
    [maxMapBounds[0], maxMapBounds[1]],
    [maxMapBounds[2], maxMapBounds[3]],
  ];
  return bounds;
}

export function makeGetProjectMapMaxBounds() {
  return createSelector(
    makeGetProjectById(),
    ({ max_map_bounds: maxMapBounds }): UFLngLatBounds => {
      return getProjectMapMaxBounds(maxMapBounds);
    },
  );
}

export const getShowAppToasts = createSelector(
  selectApp,
  appState => appState.toasts.show ?? true,
);
