import { Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { LayerMetadata, LayerStats } from 'uf-api';
import { ColumnKey, LayerId } from 'uf/layers';
import { ensureLayerMetadata } from 'uf/layers/actions/metadata';
import { ensureLayerStats } from 'uf/layers/actions/stats';
import { getColumnKey } from 'uf/layers/geometryKey';
import { makeGetLayerVersion } from 'uf/layers/selectors/versions';
import { getStatsParams } from 'uf/symbology/stats';

export type CuratedSymbologyResources = [
  /*
   * We use metadata to look up symbology keys
   */
  LayerMetadata,
  /*
   * We use stats to "clamp" numeric domains to the layer's min/max
   * TODO: https://github.com/CalthorpeAnalytics/uf/issues/11965
   */
  LayerStats,
];

export function ensureCuratedSymbology(
  layerId: LayerId,
  columnKey: ColumnKey,
): ThunkAction<Promise<CuratedSymbologyResources>, any, any, any> {
  const getLayerVersion = makeGetLayerVersion();
  return (dispatch: Dispatch, getState) => {
    const version = getLayerVersion(getState(), { layerId });
    const statsParams = getStatsParams(getColumnKey(columnKey), version);
    return Promise.all([
      dispatch(ensureLayerMetadata(layerId)),
      dispatch(ensureLayerStats(layerId, statsParams)),
    ]);
  };
}
