import { Button } from '@blueprintjs/core';
import React, { useCallback, useMemo, useState } from 'react';
import tw from 'uf/tailwindcss-classnames';
import ProjectTypeIcon from 'uf/ui/projects/ProjectTypeIcon/ProjectTypeIcon';

interface ProjectTypeButtonStyle {
  backgroundColor: string;
  boxShadow?: string;
  border?: string;
}

const ProjectTypeButton = ({
  projectType,
  selectedProjectType,
  handleSelect,
}) => {
  const [hovered, setHovered] = useState(false);
  const {
    icon_url: icon,
    key,
    name,
    short_description: shortDescription,
    medium_description: mediumDescription,
    style: { color, background_color: backgroundColor },
  } = projectType;
  const handleHover = useCallback(() => {
    setHovered(prevState => !prevState);
  }, []);
  const active = selectedProjectType === key;
  // Provide button styles based on user interaction
  const buttonStyle = useMemo(() => {
    let style = { backgroundColor: '#FFF' } as ProjectTypeButtonStyle;
    if (hovered) {
      style = { backgroundColor };
    }
    if (active) {
      style = {
        backgroundColor,
        boxShadow: 'none',
        border: `1px solid ${color}`,
      };
    }
    return style;
  }, [active, backgroundColor, color, hovered]);
  return (
    <Button
      data-testid="project-type-button"
      active={active}
      onMouseEnter={handleHover}
      onMouseLeave={handleHover}
      className={tw(
        'tw-px-3',
        'tw-items-start',
        'tw-py-5',
        'tw-px-6',
        'tw-mb-8',
        'tw-rounded-md',
      )}
      style={buttonStyle}
      fill
      alignText="left"
      value={key}
      icon={<ProjectTypeIcon label={key} source={icon} size={14} />}
      onClick={handleSelect}>
      <div className={tw('tw--top-1', 'tw-relative')}>
        <h4 className={tw('tw-font-bold')}>{name}</h4>
        <h6 style={{ color }} className={tw('tw-font-bold')}>
          {shortDescription.toUpperCase()}
        </h6>
        <p className={tw('tw-text-gray-700')}>{mediumDescription}</p>
      </div>
    </Button>
  );
};

export default ProjectTypeButton;
