import {
  CuratedColumnSymbology,
  InterpolationTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/public/dataset/city_of_somerville_current_zoning': {
    zonecode: [
      {
        display_hints: {
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          scale: SymbologyScales.CATEGORICAL,
          theme: 'custom',
        },
        paint: {
          'fill-color': {
            default: '#ff7f0e',
            property: 'zonecode',
            stops: [
              {
                color: '#7b93f3',
                value: 'ASMD',
              },
              {
                color: '#22c7fc',
                value: 'BA',
              },
              {
                color: '#1474fb',
                value: 'BB',
              },
              {
                color: '#fb0d1c',
                value: 'CBD',
              },
              {
                color: '#febdbf',
                value: 'CCD45',
              },
              {
                color: '#febdbf',
                value: 'CCD45-C',
              },
              {
                color: '#fd7e81',
                value: 'CCD55',
              },
              {
                color: '#fd7e81',
                value: 'CCD55-C',
              },
              {
                color: '#dc78fc',
                value: 'IA',
              },
              {
                color: '#c326fb',
                value: 'IB',
              },
              {
                color: '#4b0a6f',
                value: 'IP',
              },
              {
                color: '#c0d2fd',
                value: 'NB',
              },
              {
                color: '#70074d',
                value: 'NPSD',
              },
              {
                color: '#3ea61c',
                value: 'OS',
              },
              {
                color: '#fffec2',
                value: 'RA',
              },
              {
                color: '#fefd7d',
                value: 'RB',
              },
              {
                color: '#a7a821',
                value: 'RC',
              },
              {
                color: '#fd7722',
                value: 'TOD100',
              },
              {
                color: '#c4691a',
                value: 'TOD135',
              },
              {
                color: '#ceb026',
                value: 'TOD55',
              },
              {
                color: '#fda829',
                value: 'TOD70',
              },
              {
                color: '#d6fec2',
                value: 'UN',
              },
            ],
            type: 'categorical',
          },
          'fill-opacity': 0.9,
        },
        type: SymbologyTypes.FILL,
      },
      {
        display_hints: {
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          scale: SymbologyScales.CATEGORICAL,
          theme: 'custom',
        },
        paint: {
          'line-color': '#666666',
          'line-opacity': 0.9,
          'line-width': 0.5,
        },
        type: SymbologyTypes.LINE,
      },
    ],
  },
};

export default symbologies;
