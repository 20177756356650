import { Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { MapiResponse } from '@mapbox/mapbox-sdk/lib/classes/mapi-response';
import MapiClient from '@mapbox/mapbox-sdk/lib/classes/mapi-client';
import Geocoding, {
  GeocodeFeature,
  GeocodeRequest,
} from '@mapbox/mapbox-sdk/services/geocoding';

import { ClientOperation, dispatchAsyncAction } from 'uf/data/loader';
import { forwardGecodeActionTypes, SELECT_FEATURE } from 'uf/mapi/ActionTypes';

const DEFAULT_COUNTRY_CODE = 'US';

export function beginSearch(
  query: string,
  proximity: [number, number] = null,
  country: string = null,
): ThunkAction<Promise<MapiResponse>, any, any, any> {
  return async (dispatch: Dispatch, getState) => {
    if (!query.trim().length) {
      return dispatch({
        type: forwardGecodeActionTypes.LOAD,
        query,
      });
    }

    const searchResult = await dispatch(
      dispatchAsyncAction(
        forwardGecodeActionTypes,
        fetch({
          query,
          proximity,
          mode: 'mapbox.places',
          countries: [DEFAULT_COUNTRY_CODE],
        }),
        { query },
        null,
        'mapi',
      ),
    );
    return {
      query,
      ...searchResult.body,
    };
  };
}

function fetch(
  options: GeocodeRequest,
): ClientOperation<MapiResponse, MapiClient> {
  return (client: MapiClient) => {
    const geocoder = Geocoding(client);
    return geocoder.forwardGeocode(options).send();
  };
}

export function clearSearch() {
  return { type: forwardGecodeActionTypes.CLEAR };
}

export function gotoFeature(feature: GeocodeFeature) {
  return {
    type: SELECT_FEATURE,
    query: feature.text,
    feature,
  };
}
