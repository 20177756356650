import _ from 'lodash';

import { LayerReference, ProjectMetadata, ScenarioMetadata } from 'uf-api';
import { EMPTY_OBJECT } from 'uf/base';
import { DefaultProjectTypes } from '.';

export function getProjectBaseCanvasLayerReference(
  project: ProjectMetadata,
): LayerReference {
  if (_.isEmpty(project)) {
    return EMPTY_OBJECT as LayerReference;
  }
  const {
    base_scenario: baseScenario,
    project_type: projectType,
    include_base_canvas: includeBaseCanvas,
  } = project;
  if (projectType !== DefaultProjectTypes.LAND_USE && !includeBaseCanvas) {
    return null;
  }
  if (_.isEmpty(baseScenario)) {
    return EMPTY_OBJECT as LayerReference;
  }
  const { base_edits_painted_canvas: baseCanvasLayer } = baseScenario;
  if (!baseCanvasLayer) {
    return EMPTY_OBJECT as LayerReference;
  }
  return baseCanvasLayer;
}

export function getNonBaseScenarioCanvasLayerReference(
  scenario: ScenarioMetadata,
): LayerReference {
  return scenario?.base_scenario ? null : scenario?.painted_uf_canvas;
}
