import { combineEpics } from 'uf/base/epics';

import overrideSymbologyEpics from './override';
import reloadSymbologyEpics from './reload';

export default combineEpics(
  {
    overrideSymbologyEpics,
    reloadSymbologyEpics,
  },
  'symbology',
);
