import colors from 'material-colors';
import warning from 'warning';

import {
  LAND_USE_CATEGORIES_LEVEL_1,
  LAND_USE_CATEGORIES_LEVEL_2,
  LAND_USE_CATEGORIES_LEVEL_3,
} from 'uf/builtforms';
import {
  CuratedColumnSymbology,
  CUSTOM_THEME,
  DEFAULT_OPACITY,
  DEFAULT_OPACITY_ENVIRONMENTAL,
  DistributionTypes,
  InterpolationTypes,
  PaintColorTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

import { PROJECT_CANVAS_OPACITY } from './dynamic/createBuiltFormSymbology';
import { makeHospitalBedSymbology } from './LayerSymbologies/us_hospitals';

const KEY_SYMBOLOGY_COLUMN_PLACEHOLDER = 'key_symbology_column_placeholder';
/**
 * NOTE: The KeySymbologies do not have a static id. Id is determined at run time in helpers.js
 */

const projectAreaBoundarySymbology: CuratedColumnSymbology[] = [
  {
    // Specify the fill first because we want the legend to show a transparent stop
    type: SymbologyTypes.FILL,
    paint: {
      'fill-color': 'transparent',
      'fill-opacity': DEFAULT_OPACITY,
    },
    display_hints: {
      scale: SymbologyScales.CATEGORICAL,
      distribution: null,
      interpolation: InterpolationTypes.CATEGORICAL,
      theme: CUSTOM_THEME,
    },
  },
  {
    type: SymbologyTypes.LINE,
    paint: {
      'line-color': colors.black,
      'line-width': 2,
      'line-opacity': DEFAULT_OPACITY,
    },
    display_hints: {
      scale: SymbologyScales.CATEGORICAL,
      distribution: null,
      interpolation: InterpolationTypes.CATEGORICAL,
      theme: CUSTOM_THEME,
    },
  },
];
const contextAreaBoundarySymbology: CuratedColumnSymbology[] = [
  {
    // Specify the fill first because we want the legend to show a transparent stop
    type: SymbologyTypes.FILL,
    paint: {
      'fill-color': 'transparent',
      'fill-opacity': DEFAULT_OPACITY,
    },
    display_hints: {
      scale: SymbologyScales.CATEGORICAL,
      distribution: null,
      interpolation: InterpolationTypes.CATEGORICAL,
      theme: CUSTOM_THEME,
    },
  },
  {
    type: SymbologyTypes.LINE,
    paint: {
      'line-color': colors.black,
      'line-width': 4,
      'line-opacity': DEFAULT_OPACITY,
    },
    display_hints: {
      scale: SymbologyScales.CATEGORICAL,
      distribution: null,
      interpolation: InterpolationTypes.CATEGORICAL,
      theme: CUSTOM_THEME,
    },
  },
];

const osmRoadsBridgesSymbology: CuratedColumnSymbology[] = [
  {
    display_hints: {
      distribution: null,
      interpolation: InterpolationTypes.CATEGORICAL,
      scale: SymbologyScales.CATEGORICAL,
      theme: 'custom',
    },
    paint: {
      'line-color': {
        default: '#2ca02c',
        property: 'bridge',
        stops: [
          {
            color: '#808080',
            value: 'No',
          },
          {
            color: colors.black,
            value: 'Yes',
          },
        ],
        type: 'categorical',
      },
      'line-opacity': 0.9,
      'line-width': {
        default: 1,
        property: 'bridge',
        stops: [
          {
            width: 1,
            value: 'No',
          },
          {
            width: 2,
            value: 'Yes',
          },
        ],
        type: 'categorical',
      },
    },
    type: SymbologyTypes.LINE,
  },
];

const osmRoadsDetailedFunctionalClassificationSymbology: CuratedColumnSymbology[] =
  [
    {
      display_hints: {
        distribution: null,
        interpolation: InterpolationTypes.CATEGORICAL,
        scale: SymbologyScales.CATEGORICAL,
        theme: 'custom',
      },
      paint: {
        'line-color': {
          default: '#bcbd22',
          property: 'detailed_functional_classification',
          stops: [
            {
              color: '#d1d1d1',
              value: 'Alley',
            },
            {
              color: '#ff7b00',
              value: 'Arterial',
            },
            {
              color: '#efd065',
              value: 'Collector',
            },
            {
              color: '#c7231d',
              value: 'Freeway',
            },
            {
              color: '#fc351e',
              value: 'Highway',
            },
            {
              color: '#808080',
              value: 'Local',
            },
            {
              color: '#fc351e',
              value: 'On/Off ramp',
            },
            {
              color: '#d1d1d1',
              value: 'Unclassified',
            },
          ],
          type: 'categorical',
        },
        'line-opacity': 0.9,
        'line-width': {
          default: 1,
          property: 'detailed_functional_classification',
          stops: [
            {
              width: 1,
              value: 'Alley',
            },
            {
              width: 1.5,
              value: 'Arterial',
            },
            {
              width: 1.5,
              value: 'Collector',
            },
            {
              width: 2,
              value: 'Freeway',
            },
            {
              width: 2,
              value: 'Highway',
            },
            {
              width: 1,
              value: 'Local',
            },
            {
              width: 1.5,
              value: 'On/Off ramp',
            },
            {
              width: 1,
              value: 'Unclassified',
            },
          ],
          type: 'categorical',
        },
      },
      type: SymbologyTypes.LINE,
    },
  ];

const osmRoadsFunctionalClassificationSymbology: CuratedColumnSymbology[] = [
  {
    display_hints: {
      distribution: null,
      interpolation: InterpolationTypes.CATEGORICAL,
      scale: SymbologyScales.CATEGORICAL,
      theme: 'custom',
    },
    paint: {
      'line-color': {
        default: '#8c564b',
        property: 'functional_classification',
        stops: [
          {
            color: '#ff7b00',
            value: 'Arterial',
          },
          {
            color: '#efd065',
            value: 'Collector',
          },
          {
            color: '#c7231d',
            value: 'Freeway/Highway',
          },
          {
            color: '#808080',
            value: 'Local',
          },
          {
            color: '#d1d1d1',
            value: 'Other',
          },
        ],
        type: 'categorical',
      },
      'line-opacity': 0.9,
      'line-width': {
        default: 1,
        property: 'functional_classification',
        stops: [
          {
            width: 1.5,
            value: 'Arterial',
          },
          {
            width: 1.5,
            value: 'Collector',
          },
          {
            width: 2,
            value: 'Freeway/Highway',
          },
          {
            width: 1,
            value: 'Local',
          },
          {
            width: 1,
            value: 'Other',
          },
        ],
        type: 'categorical',
      },
    },
    type: SymbologyTypes.LINE,
  },
];

const osmRoadsLengthSymbology: CuratedColumnSymbology[] = [
  {
    display_hints: {
      distribution: DistributionTypes.QUANTILES,
      interpolation: InterpolationTypes.INTERVAL,
      is_percent: false,
      scale: SymbologyScales.NUMERIC,
      showZero: false,
      theme: 'd3:interpolateRdPu',
    },
    paint: {
      'line-color': {
        default: 'transparent',
        property: 'length_in_feet',
        stops: [
          {
            color: 'rgb(255, 247, 243)',
            value: 38.739,
          },
          {
            color: 'rgb(253, 226, 223)',
            value: 73.114,
          },
          {
            color: 'rgb(252, 202, 200)',
            value: 119.271,
          },
          {
            color: 'rgb(251, 171, 184)',
            value: 162.961,
          },
          {
            color: 'rgb(248, 128, 170)',
            value: 216.156,
          },
          {
            color: 'rgb(234, 81, 157)',
            value: 263.714,
          },
          {
            color: 'rgb(204, 35, 142)',
            value: 323.677,
          },
          {
            color: 'rgb(162, 5, 126)',
            value: 432.442,
          },
          {
            color: 'rgb(117, 1, 117)',
            value: 614.18,
          },
          {
            color: 'rgb(73, 0, 106)',
            value: 963.728,
          },
        ],
        type: 'interval',
      },
      'line-opacity': 0.9,
      'line-width': 1,
    },
    type: SymbologyTypes.LINE,
  },
];

const osmRoadsStreetDirectionSymbology: CuratedColumnSymbology[] = [
  {
    display_hints: {
      distribution: null,
      interpolation: InterpolationTypes.CATEGORICAL,
      scale: SymbologyScales.CATEGORICAL,
      theme: 'custom',
    },
    paint: {
      'line-color': {
        default: '#d62728',
        property: 'street_direction',
        stops: [
          {
            color: colors.black,
            value: 'One-way',
          },
          {
            color: '#808080',
            value: 'Two-way',
          },
          {
            color: '#d1d1d1',
            value: 'Unclassified',
          },
        ],
        type: 'categorical',
      },
      'line-opacity': 0.9,
      'line-width': {
        default: 1,
        property: 'street_direction',
        stops: [
          {
            width: 2,
            value: 'One-way',
          },
          {
            width: 1,
            value: 'Two-way',
          },
          {
            width: 1,
            value: 'Unclassified',
          },
        ],
        type: 'categorical',
      },
    },
    type: SymbologyTypes.LINE,
  },
];

const osmRoadsTunnelSymbology: CuratedColumnSymbology[] = [
  {
    display_hints: {
      distribution: null,
      interpolation: InterpolationTypes.CATEGORICAL,
      scale: SymbologyScales.CATEGORICAL,
      theme: 'custom',
    },
    paint: {
      'line-color': {
        default: '#2ca02c',
        property: 'tunnel',
        stops: [
          {
            color: '#808080',
            value: 'No',
          },
          {
            color: colors.black,
            value: 'Yes',
          },
        ],
        type: 'categorical',
      },
      'line-opacity': 0.9,
      'line-width': {
        default: 1,
        property: 'tunnel',
        stops: [
          {
            width: 1,
            value: 'No',
          },
          {
            width: 2,
            value: 'Yes',
          },
        ],
        type: 'categorical',
      },
    },
    type: SymbologyTypes.LINE,
  },
];

const osmInternalRoadsTypeSymbology: CuratedColumnSymbology[] = [
  {
    display_hints: {
      distribution: null,
      interpolation: InterpolationTypes.CATEGORICAL,
      scale: SymbologyScales.CATEGORICAL,
      theme: 'custom',
    },
    paint: {
      'line-color': {
        default: '#2ca02c',
        property: 'type',
        stops: [
          {
            color: '#aea1ff',
            value: 'Parking',
          },
          {
            color: '#7b64ff',
            value: 'Private / Internal road',
          },
        ],
        type: 'categorical',
      },
      'line-opacity': 0.9,
      'line-width': 1,
    },
    type: SymbologyTypes.LINE,
  },
];

const osmInternalRoadsLengthSymbology: CuratedColumnSymbology[] = [
  {
    display_hints: {
      distribution: DistributionTypes.QUANTILES,
      interpolation: InterpolationTypes.INTERVAL,
      is_percent: false,
      scale: SymbologyScales.NUMERIC,
      showZero: false,
      theme: 'd3:interpolateRdPu',
    },
    paint: {
      'line-color': {
        default: 'transparent',
        property: 'length_in_feet',
        stops: [
          {
            color: 'rgb(255, 247, 243)',
            value: 37.341,
          },
          {
            color: 'rgb(253, 226, 223)',
            value: 52.575,
          },
          {
            color: 'rgb(252, 202, 200)',
            value: 61.46,
          },
          {
            color: 'rgb(251, 171, 184)',
            value: 71.536,
          },
          {
            color: 'rgb(248, 128, 170)',
            value: 94.529,
          },
          {
            color: 'rgb(234, 81, 157)',
            value: 127.857,
          },
          {
            color: 'rgb(204, 35, 142)',
            value: 164.007,
          },
          {
            color: 'rgb(162, 5, 126)',
            value: 206.25,
          },
          {
            color: 'rgb(117, 1, 117)',
            value: 267.897,
          },
          {
            color: 'rgb(73, 0, 106)',
            value: 387.273,
          },
        ],
        type: 'interval',
      },
      'line-opacity': 0.9,
      'line-width': 1,
    },
    type: SymbologyTypes.LINE,
  },
];

const osmBikePedTypeSymbology: CuratedColumnSymbology[] = [
  {
    display_hints: {
      distribution: null,
      interpolation: InterpolationTypes.CATEGORICAL,
      scale: SymbologyScales.CATEGORICAL,
      theme: 'custom',
    },
    paint: {
      'line-color': {
        default: '#2ca02c',
        property: 'type',
        stops: [
          {
            color: '#68bc00',
            value: 'Bicycle',
          },
          {
            color: '#a4dd00',
            value: 'Pedestrian',
          },
        ],
        type: 'categorical',
      },
      'line-opacity': 0.9,
      'line-width': 1,
    },
    type: SymbologyTypes.LINE,
  },
];

const osmBikePedLengthSymbology: CuratedColumnSymbology[] = [
  {
    display_hints: {
      distribution: DistributionTypes.QUANTILES,
      interpolation: InterpolationTypes.INTERVAL,
      is_percent: false,
      scale: SymbologyScales.NUMERIC,
      showZero: false,
      theme: 'd3:interpolateRdPu',
    },
    paint: {
      'line-color': {
        default: 'transparent',
        property: 'length_in_feet',
        stops: [
          {
            color: 'rgb(255, 247, 243)',
            value: 21.714,
          },
          {
            color: 'rgb(253, 226, 223)',
            value: 30.323,
          },
          {
            color: 'rgb(252, 202, 200)',
            value: 45.019,
          },
          {
            color: 'rgb(251, 171, 184)',
            value: 68.806,
          },
          {
            color: 'rgb(248, 128, 170)',
            value: 105.586,
          },
          {
            color: 'rgb(234, 81, 157)',
            value: 153.774,
          },
          {
            color: 'rgb(204, 35, 142)',
            value: 228.486,
          },
          {
            color: 'rgb(162, 5, 126)',
            value: 342.802,
          },
          {
            color: 'rgb(117, 1, 117)',
            value: 589.156,
          },
          {
            color: 'rgb(73, 0, 106)',
            value: 1256.495,
          },
        ],
        type: 'interval',
      },
      'line-opacity': 0.9,
      'line-width': 1,
    },
    type: SymbologyTypes.LINE,
  },
];

const endangeredSpeciesLayersPolySymbology: CuratedColumnSymbology[] = [
  {
    type: SymbologyTypes.FILL,
    paint: {
      'fill-color': {
        property: 'listing_st',
        type: PaintColorTypes.CATEGORICAL,
        stops: [
          { value: 'Endangered', color: '#f8514f' },
          { value: 'Proposed endangered', color: '#fb9a99' },
          { value: 'Resolved Taxon', color: '#92D050' },
          { value: 'Threatened', color: '#fbcb99' },
        ],
      },
      'fill-opacity': DEFAULT_OPACITY,
    },
    display_hints: {
      scale: SymbologyScales.CATEGORICAL,
      distribution: null,
      interpolation: InterpolationTypes.CATEGORICAL,
      theme: CUSTOM_THEME,
    },
  },
  {
    type: SymbologyTypes.LINE,
    paint: {
      'line-color': {
        property: 'listing_st',
        type: PaintColorTypes.CATEGORICAL,
        stops: [
          { value: 'Endangered', color: '#f8514f' },
          { value: 'Proposed endangered', color: '#fb9a99' },
          { value: 'Resolved Taxon', color: '#92D050' },
          { value: 'Threatened', color: '#fbcb99' },
        ],
      },
      'line-opacity': DEFAULT_OPACITY,
    },
    display_hints: {
      scale: SymbologyScales.CATEGORICAL,
      distribution: null,
      interpolation: InterpolationTypes.CATEGORICAL,
      theme: CUSTOM_THEME,
    },
  },
];

const endangeredSpeciesLayersLineSymbology: CuratedColumnSymbology[] = [
  {
    type: SymbologyTypes.LINE,
    paint: {
      'line-color': {
        property: 'listing_st',
        type: PaintColorTypes.CATEGORICAL,
        stops: [
          { value: 'Endangered', color: '#f8514f' },
          { value: 'Proposed endangered', color: '#fb9a99' },
          { value: 'Resolved Taxon', color: '#92D050' },
          { value: 'Threatened', color: '#fbcb99' },
        ],
      },
      'line-opacity': DEFAULT_OPACITY,
    },
    display_hints: {
      scale: SymbologyScales.CATEGORICAL,
      distribution: null,
      interpolation: InterpolationTypes.CATEGORICAL,
      theme: CUSTOM_THEME,
    },
  },
];

const nwiWetlandTypePolySymbology: CuratedColumnSymbology[] = [
  {
    type: SymbologyTypes.FILL,
    paint: {
      'fill-color': {
        property: 'wetland_type',
        type: PaintColorTypes.CATEGORICAL,
        stops: [
          { value: 'Estuarine and Marine Deepwater', color: '#5eaaac' },
          { value: 'Estuarine and Marine Wetland', color: '#9cd4bd' },
          { value: 'Freshwater Emergent Wetland', color: '#acd66d' },
          { value: 'Freshwater Forested/Shrub Wetland', color: '#5fb17b' },
          { value: 'Freshwater Pond', color: '#9db4ce' },
          { value: 'Lake', color: '#6a60a5' },
          { value: 'Other', color: '#c9af8c' },
          { value: 'Riverine', color: '#5fb6cd' },
        ],
      },
      'fill-opacity': DEFAULT_OPACITY,
    },
    display_hints: {
      scale: SymbologyScales.CATEGORICAL,
      distribution: null,
      interpolation: InterpolationTypes.CATEGORICAL,
      theme: CUSTOM_THEME,
    },
  },
  {
    type: SymbologyTypes.LINE,
    paint: {
      'line-color': {
        property: 'wetland_type',
        type: PaintColorTypes.CATEGORICAL,
        stops: [
          { value: 'Estuarine and Marine Deepwater', color: '#5eaaac' },
          { value: 'Estuarine and Marine Wetland', color: '#9cd4bd' },
          { value: 'Freshwater Emergent Wetland', color: '#acd66d' },
          { value: 'Freshwater Forested/Shrub Wetland', color: '#5fb17b' },
          { value: 'Freshwater Pond', color: '#9db4ce' },
          { value: 'Lake', color: '#6a60a5' },
          { value: 'Other', color: '#c9af8c' },
          { value: 'Riverine', color: '#5fb6cd' },
        ],
      },
      'line-opacity': DEFAULT_OPACITY,
    },
    display_hints: {
      scale: SymbologyScales.CATEGORICAL,
      distribution: null,
      interpolation: InterpolationTypes.CATEGORICAL,
      theme: CUSTOM_THEME,
    },
  },
];

const covidCasesSymbology: CuratedColumnSymbology[] = [
  {
    type: SymbologyTypes.FILL,
    paint: {
      'fill-color': {
        property: KEY_SYMBOLOGY_COLUMN_PLACEHOLDER,
        default: 'transparent',
        type: PaintColorTypes.INTERVAL,
        stops: [
          {
            value: 1,
            color: '#FFF5F0',
          },
          {
            value: 250,
            color: '#FEE0D2',
          },
          {
            value: 500,
            color: '#FCBBA1',
          },
          {
            value: 1000,
            color: '#FC9272',
          },
          {
            value: 2500,
            color: '#FB6D4A',
          },
          {
            value: 5000,
            color: '#EF3B2C',
          },
          {
            value: 7500,
            color: '#CB181D',
          },
          {
            value: 10000,
            color: '#A50F15',
          },
          {
            value: 15000,
            color: '#67000D',
          },
          {
            value: 20000,
            color: '#4D000A',
          },
          {
            value: 35000,
            color: '#340007',
          },
        ],
      },
      'fill-opacity': 1,
    },
    display_hints: {
      scale: SymbologyScales.NUMERIC,
      distribution: DistributionTypes.CUSTOM,
      interpolation: InterpolationTypes.INTERVAL,
      theme: CUSTOM_THEME,
    },
  },
  {
    type: SymbologyTypes.LINE,
    paint: {
      'line-color': '#CCCCCC',
      'line-width': 0.5,
      'line-opacity': 1,
    },
    display_hints: {
      scale: SymbologyScales.NUMERIC,
      distribution: DistributionTypes.CUSTOM,
      interpolation: InterpolationTypes.INTERVAL,
      theme: CUSTOM_THEME,
    },
  },
];

const covidDeathSymbology: CuratedColumnSymbology[] = [
  {
    type: SymbologyTypes.FILL,
    paint: {
      'fill-color': {
        property: KEY_SYMBOLOGY_COLUMN_PLACEHOLDER,
        default: 'transparent',
        type: PaintColorTypes.INTERVAL,
        stops: [
          {
            value: 1,
            color: '#FFF5F0',
          },
          {
            value: 10,
            color: '#FEE0D2',
          },
          {
            value: 25,
            color: '#FCBBA1',
          },
          {
            value: 50,
            color: '#FC9272',
          },
          {
            value: 75,
            color: '#FB6D4A',
          },
          {
            value: 100,
            color: '#EF3B2C',
          },
          {
            value: 150,
            color: '#CB181D',
          },
          {
            value: 300,
            color: '#A50F15',
          },
          {
            value: 500,
            color: '#67000D',
          },
          {
            value: 750,
            color: '#4D000A',
          },
          {
            value: 1000,
            color: '#340007',
          },
        ],
      },
      'fill-opacity': 1,
    },
    display_hints: {
      scale: SymbologyScales.NUMERIC,
      distribution: DistributionTypes.CUSTOM,
      interpolation: InterpolationTypes.INTERVAL,
      theme: CUSTOM_THEME,
    },
  },
  {
    type: SymbologyTypes.LINE,
    paint: {
      'line-color': '#CCCCCC',
      'line-width': 0.5,
      'line-opacity': 1,
    },
    display_hints: {
      scale: SymbologyScales.NUMERIC,
      distribution: DistributionTypes.CUSTOM,
      interpolation: InterpolationTypes.INTERVAL,
      theme: CUSTOM_THEME,
    },
  },
];

const HOSPITAL_BED_SYMBOLOGY_HOSPITALS_NUM_LICENS_MAX = 2100;
const HOSPITAL_BED_SYMBOLOGY_HOSPITALS_NUM_STAFFE_MAX = 3000;
const HOSPITAL_BED_SYMBOLOGY_HOSPITALS_NUM_ICU_BE_MAX = 250;
const HOSPITAL_BED_SYMBOLOGY_HOSPITALS_ADULT_ICU_MAX = 350;
const HOSPITAL_BED_SYMBOLOGY_HOSPITALS_PEDI_ICU_B_MAX = 300;
const HOSPITAL_BED_SYMBOLOGY_HOSPITALS_AVG_VENTIL_MAX = 100;
const HOSPITAL_BED_SYMBOLOGY_HOSPITALS_POTENTIAL_MAX = 1500;
const HOSPITAL_BED_SYMBOLOGY_HOSPITALS_BED_UTILIZ_MAX = 1;
const HOSPITAL_BED_SYMBOLOGY_COVID_CONFIRMED_CASES_MAX = 200;

const KeySymbology: Record<string, CuratedColumnSymbology[]> = {
  hospitals_num_licens: makeHospitalBedSymbology(
    'num_licens',
    HOSPITAL_BED_SYMBOLOGY_HOSPITALS_NUM_LICENS_MAX,
  ),
  hospitals_num_staffe: makeHospitalBedSymbology(
    'num_staffe',
    HOSPITAL_BED_SYMBOLOGY_HOSPITALS_NUM_STAFFE_MAX,
  ),
  hospitals_num_icu_be: makeHospitalBedSymbology(
    'num_icu_be',
    HOSPITAL_BED_SYMBOLOGY_HOSPITALS_NUM_ICU_BE_MAX,
  ),
  hospitals_adult_icu: makeHospitalBedSymbology(
    'adult_icu',
    HOSPITAL_BED_SYMBOLOGY_HOSPITALS_ADULT_ICU_MAX,
  ),
  hospitals_pedi_icu_b: makeHospitalBedSymbology(
    'pedi_icu_b',
    HOSPITAL_BED_SYMBOLOGY_HOSPITALS_PEDI_ICU_B_MAX,
  ),
  hospitals_avg_ventil: makeHospitalBedSymbology(
    'avg_ventil',
    HOSPITAL_BED_SYMBOLOGY_HOSPITALS_AVG_VENTIL_MAX,
  ),
  hospitals_potential: makeHospitalBedSymbology(
    'potential',
    HOSPITAL_BED_SYMBOLOGY_HOSPITALS_POTENTIAL_MAX,
  ),
  hospitals_bed_utiliz: makeHospitalBedSymbology(
    'bed_utiliz',
    HOSPITAL_BED_SYMBOLOGY_HOSPITALS_BED_UTILIZ_MAX,
  ),
  covid_confirmed_cases: makeHospitalBedSymbology(
    'confirmed',
    HOSPITAL_BED_SYMBOLOGY_COVID_CONFIRMED_CASES_MAX,
  ),
  covid_cases: covidCasesSymbology,
  covid_deaths: covidDeathSymbology,
  project_area_boundary: projectAreaBoundarySymbology,
  context_area_boundary: contextAreaBoundarySymbology,
  osm_roads_bridges: osmRoadsBridgesSymbology,
  osm_roads_detailed_functional_classification:
    osmRoadsDetailedFunctionalClassificationSymbology,
  osm_roads_functional_classification:
    osmRoadsFunctionalClassificationSymbology,
  osm_roads_length: osmRoadsLengthSymbology,
  osm_roads_street_direction: osmRoadsStreetDirectionSymbology,
  osm_roads_tunnel: osmRoadsTunnelSymbology,
  osm_internal_roads_type: osmInternalRoadsTypeSymbology,
  osm_internal_roads_length: osmInternalRoadsLengthSymbology,
  osm_bike_ped_type: osmBikePedTypeSymbology,
  osm_bike_ped_length: osmBikePedLengthSymbology,
  fws_endangered_species_critical_habitat_line:
    endangeredSpeciesLayersLineSymbology,
  fws_endangered_species_critical_habitat_poly:
    endangeredSpeciesLayersPolySymbology,
  nwi_wetland_type: nwiWetlandTypePolySymbology,
  fema_fld_zone: [
    {
      type: SymbologyTypes.FILL,
      paint: {
        'fill-color': {
          property: 'fld_zone',
          type: PaintColorTypes.CATEGORICAL,
          stops: [
            {
              value: 'C',
              label: 'C - Area Of Minimal Flood Hazard',
              color: colors.lightGreen[300],
            },
            {
              value: 'X',
              label: 'X - Area Of Minimal Flood Hazard',
              color: colors.lightGreen[300],
            },
            {
              value: 'B',
              label: 'B - Area Of Moderate Flood Hazard',
              color: colors.amber[500],
            },
            {
              value: 'AH',
              label: 'AH - Areas With A 1% Annual Chance Of Shallow Flooding',
              color: colors.orange[400],
            },
            {
              value: 'AO',
              label: 'AO - River Or Stream Flood Hazard Areas',
              color: colors.orange[500],
            },
            {
              value: 'A',
              label: 'A - Areas With A 1% Annual Chance Of Flooding',
              color: colors.deepOrange[500],
            },
            {
              value: 'AE',
              label: 'AE - Base Floodplain Elevation',
              color: colors.indigo[500],
            },
            {
              value: 'AR',
              label: 'AR - Areas With A Temporarily Increased Flood Risk',
              color: colors.pink[900],
            },
            {
              value: 'A99',
              label:
                'A99 - Areas With A 1% Annual Chance - Protected By Federal Flood Control System',
              color: colors.purple[500],
            },
            {
              value: 'V',
              label:
                'V - Coastal Areas With >= 1% Chance Of Flooding And Additional Storm Wave Hazard',
              color: colors.red[500],
            },
            {
              value: 'VE',
              label:
                'VE - Coastal Areas With >= 1% Chance Of Flooding And Additional Storm Wave Hazard',
              color: colors.red[500],
            },
            {
              value: 'D',
              label: 'D - Undetermined Risk Area',
              color: colors.grey[400],
            },
            {
              value: 'AREA NOT INCLUDED',
              label: 'Area not included',
              color: colors.grey[400],
            },
          ],
        },
        'fill-opacity': DEFAULT_OPACITY_ENVIRONMENTAL,
      },
      display_hints: {
        scale: SymbologyScales.CATEGORICAL,
        distribution: null,
        interpolation: InterpolationTypes.CATEGORICAL,
        theme: CUSTOM_THEME,
      },
    },
    {
      type: SymbologyTypes.LINE,
      paint: {
        'line-color': {
          property: 'fld_zone',
          type: PaintColorTypes.CATEGORICAL,
          stops: [
            { value: 'C', color: colors.lightGreen[300] },
            { value: 'X', color: colors.lightGreen[300] },
            { value: 'B', color: colors.amber[500] },
            { value: 'AH', color: colors.orange[400] },
            { value: 'AO', color: colors.orange[500] },
            { value: 'A', color: colors.deepOrange[500] },
            { value: 'AE', color: colors.indigo[500] },
            { value: 'AR', color: colors.pink[900] },
            { value: 'A99', color: colors.purple[500] },
            { value: 'V', color: colors.red[500] },
            { value: 'VE', color: colors.red[500] },
            { value: 'D', color: colors.grey[400] },
            { value: 'AREA NOT INCLUDED', color: colors.grey[400] },
          ],
        },
        'line-opacity': DEFAULT_OPACITY,
      },
      display_hints: {
        scale: SymbologyScales.CATEGORICAL,
        distribution: null,
        interpolation: InterpolationTypes.CATEGORICAL,
        theme: CUSTOM_THEME,
      },
    },
  ],
  fema_fld_zone_mapped: [
    {
      display_hints: {
        distribution: null,
        interpolation: InterpolationTypes.CATEGORICAL,
        scale: SymbologyScales.CATEGORICAL,
        theme: 'custom',
      },
      paint: {
        'fill-color': {
          property: 'fld_zone',
          stops: [
            {
              color: '#e9dcbf',
              label: 'C - Area Of Minimal Flood Hazard',
              value: 'C',
            },
            {
              color: '#ede7d9',
              label: 'X - Area Of Minimal Flood Hazard',
              value: 'X',
            },
            {
              color: '#eec4c4',
              label: 'B - Area Of Moderate Flood Hazard',
              value: 'B',
            },
            {
              color: '#ebb6b7',
              label: 'AH - Areas With A 1% Annual Chance Of Shallow Flooding',
              value: 'AH',
            },
            {
              color: '#e39c9d',
              label: 'AO - River Or Stream Flood Hazard Areas',
              value: 'AO',
            },
            {
              color: '#dc8183',
              label: 'A - Areas With A 1% Annual Chance Of Flooding',
              value: 'A',
            },
            {
              color: '#cd4c4f',
              label: 'AE - Base Floodplain Elevation',
              value: 'AE',
            },
            {
              color: '#bcb15f',
              label: 'AR - Areas With A Temporarily Increased Flood Risk',
              value: 'AR',
            },
            {
              color: '#eade84',
              label:
                'A99 - Areas With A 1% Annual Chance - Protected By Federal Flood Control System',
              value: 'A99',
            },
            {
              color: '#7f2426',
              label:
                'V - Coastal Areas With >= 1% Chance Of Flooding And Additional Storm Wave Hazard',
              value: 'V',
            },
            {
              color: '#b43235',
              label:
                'VE - Coastal Areas With >= 1% Chance Of Flooding And Additional Storm Wave Hazard',
              value: 'VE',
            },
            {
              color: '#8c8a8a',
              label: 'D - Undetermined Risk Area',
              value: 'D',
            },
          ],
          type: 'categorical',
        },
        'fill-opacity': 0.7,
      },
      type: SymbologyTypes.FILL,
    },
    {
      display_hints: {
        distribution: null,
        interpolation: InterpolationTypes.CATEGORICAL,
        scale: SymbologyScales.CATEGORICAL,
        theme: 'custom',
      },
      paint: {
        'line-color': 'transparent',
        'line-opacity': 0.7,
      },
      type: SymbologyTypes.LINE,
    },
  ],
  fema_fld_zone_unmapped: [
    {
      type: SymbologyTypes.FILL,
      paint: {
        'fill-color': {
          property: 'fld_zone',
          type: PaintColorTypes.CATEGORICAL,
          stops: [
            {
              value: 'AREA NOT INCLUDED',
              label: 'Area not included',
              color: colors.grey[400],
            },
          ],
        },
        'fill-opacity': DEFAULT_OPACITY_ENVIRONMENTAL,
      },
      display_hints: {
        scale: SymbologyScales.CATEGORICAL,
        distribution: null,
        interpolation: InterpolationTypes.CATEGORICAL,
        theme: CUSTOM_THEME,
      },
    },
    {
      type: SymbologyTypes.LINE,
      paint: {
        'line-color': {
          property: 'fld_zone',
          type: PaintColorTypes.CATEGORICAL,
          stops: [{ value: 'AREA NOT INCLUDED', color: colors.grey[400] }],
        },
        'line-opacity': DEFAULT_OPACITY,
      },
      display_hints: {
        scale: SymbologyScales.CATEGORICAL,
        distribution: null,
        interpolation: InterpolationTypes.CATEGORICAL,
        theme: CUSTOM_THEME,
      },
    },
  ],
  parcel_imp_land_ratio: [
    {
      type: SymbologyTypes.FILL,
      paint: {
        'fill-color': {
          property: 'imp_land_ratio',
          type: PaintColorTypes.INTERVAL,
          stops: [
            {
              color: 'rgb(140, 81, 9)',
              value: 0,
            },
            {
              color: 'rgb(184, 138, 64)',
              value: 0.25,
            },
            {
              color: 'rgb(219, 186, 120)',
              value: 0.5,
            },
            {
              color: 'rgb(238, 218, 172)',
              value: 0.75,
            },
            {
              color: 'rgb(244, 236, 213)',
              value: 1,
            },
            {
              color: 'rgb(238, 241, 234)',
              value: 1.25,
            },
            {
              color: 'rgb(215, 237, 233)',
              value: 1.5,
            },
            {
              color: 'rgb(172, 219, 214)',
              value: 1.75,
            },
            {
              color: 'rgb(113, 189, 182)',
              value: 2,
            },
            {
              color: 'rgb(55, 148, 140)',
              value: 5,
            },
            {
              color: 'rgb(1, 102, 94)',
              value: 10,
            },
          ],
        },
        'fill-opacity': DEFAULT_OPACITY,
      },
      display_hints: {
        scale: SymbologyScales.NUMERIC,
        distribution: DistributionTypes.DEFAULT,
        interpolation: InterpolationTypes.INTERVAL,
        theme: CUSTOM_THEME,
      },
    },
  ],
  land_use_category_level_1: [
    {
      type: SymbologyTypes.FILL,
      paint: {
        'fill-color': {
          property: 'land_use_category_level_1',
          type: PaintColorTypes.CATEGORICAL,
          stops: LAND_USE_CATEGORIES_LEVEL_1.map(i => ({
            value: i.key,
            label: i.name,
            color: i.color,
          })),
        },
        'fill-opacity': PROJECT_CANVAS_OPACITY,
      },
      display_hints: {
        scale: SymbologyScales.CATEGORICAL,
        distribution: null,
        interpolation: InterpolationTypes.CATEGORICAL,
        theme: CUSTOM_THEME,
      },
    },
    {
      type: SymbologyTypes.LINE,
      paint: {
        'line-color': colors.grey[500],
        'line-width': 0.5,
        'line-opacity': PROJECT_CANVAS_OPACITY,
      },
      display_hints: {
        scale: SymbologyScales.CATEGORICAL,
        distribution: null,
        interpolation: InterpolationTypes.CATEGORICAL,
        theme: 'material:grey:500',
      },
    },
  ],
  land_use_category_level_2: [
    {
      type: SymbologyTypes.FILL,
      paint: {
        'fill-color': {
          property: 'land_use_category_level_2',
          type: PaintColorTypes.CATEGORICAL,
          stops: LAND_USE_CATEGORIES_LEVEL_2.map(i => ({
            value: i.key,
            label: i.name,
            color: i.color,
          })),
        },
        'fill-opacity': PROJECT_CANVAS_OPACITY,
      },
      display_hints: {
        scale: SymbologyScales.CATEGORICAL,
        distribution: null,
        interpolation: InterpolationTypes.CATEGORICAL,
        theme: CUSTOM_THEME,
      },
    },
    {
      type: SymbologyTypes.LINE,
      paint: {
        'line-color': colors.grey[500],
        'line-width': 0.5,
        'line-opacity': PROJECT_CANVAS_OPACITY,
      },
      display_hints: {
        scale: SymbologyScales.CATEGORICAL,
        distribution: null,
        interpolation: InterpolationTypes.CATEGORICAL,
        theme: 'material:grey:500',
      },
    },
  ],
  land_use_category_level_3: [
    {
      type: SymbologyTypes.FILL,
      paint: {
        'fill-color': {
          property: 'land_use_category_level_3',
          type: PaintColorTypes.CATEGORICAL,
          stops: LAND_USE_CATEGORIES_LEVEL_3.filter(i => 'color' in i).map(
            i => ({
              value: i.key,
              label: i.name,
              color: i.color,
            }),
          ),
        },
        'fill-opacity': PROJECT_CANVAS_OPACITY,
      },
      display_hints: {
        scale: SymbologyScales.CATEGORICAL,
        distribution: null,
        interpolation: InterpolationTypes.CATEGORICAL,
        theme: CUSTOM_THEME,
      },
    },
    {
      type: SymbologyTypes.LINE,
      paint: {
        'line-color': colors.grey[500],
        'line-width': 0.5,
        'line-opacity': PROJECT_CANVAS_OPACITY,
      },
      display_hints: {
        scale: SymbologyScales.CATEGORICAL,
        distribution: null,
        interpolation: InterpolationTypes.CATEGORICAL,
        theme: 'material:grey:500',
      },
    },
  ],
};

export function registerKeySymbology(
  symbologyKey: string,
  symbologies: CuratedColumnSymbology[],
) {
  warning(
    !KeySymbology[symbologyKey],
    `Symbology key: "${symbologyKey}" already exists.`,
  );

  KeySymbology[symbologyKey] = symbologies;

  return () => {
    // It's possible that the key symbology was overwritten, so check before unregistering
    if (KeySymbology[symbologyKey] === symbologies) {
      delete KeySymbology[symbologyKey];
    }
  };
}

export default KeySymbology;
