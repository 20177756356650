import { LegacyChartSeries, LegacyReport } from 'uf/reporting/index';

const suitabilityScoreAvg: LegacyChartSeries = {
  key: 'suitability_score_avg',
  source: 'analysis',
  sourceKey: 'suitability',
  layerKey: 'suitability_spatial_output',
  columnKey: 'suitability_score',
  stat: 'avg',
};

export const suitabilityCharts: LegacyReport = Object.freeze({
  key: 'suitability',
  name: 'Suitability',
  charts: [
    {
      name: 'Suitability Score Average',
      key: 'suitability_score_avg',
      series: [suitabilityScoreAvg],
    },
  ],
});
