import _ from 'lodash';
import { Map, Point } from 'mapbox-gl';
import { useCallback } from 'react';

import { LngLat } from 'uf/base/map';
import { LayerId } from 'uf/layers';
import {
  makeLngLatPrimitive,
  makePointPrimitive,
} from 'uf/ui/map/MapView/helpers';

export function useInspection(
  activeLayerId: string,
  activeStyleLayerIds: string[],
  updateMapInspection: (
    layerId: LayerId,
    point: { x: number; y: number },
    lngLat: mapboxgl.LngLatLike,
    properties?: {},
  ) => {
    type: string;
    layerId: LayerId;
    inspection: {
      point: { x: number; y: number };
      lngLat: mapboxgl.LngLatLike;
      properties: {};
    };
  },
  clearMapInspection: (layerId: LayerId) => {
    type: string;
    layerId: LayerId;
  },
) {
  const onInspection = useCallback(
    (point: Point, lngLat: LngLat, map: Map) => {
      handleInspection(
        map,
        activeLayerId,
        point,
        lngLat,
        activeStyleLayerIds,
        updateMapInspection,
      );
    },
    [activeLayerId, activeStyleLayerIds, updateMapInspection],
  );
  const onClearInspection = useCallback(() => {
    clearMapInspection(activeLayerId);
  }, [clearMapInspection, activeLayerId]);
  return { onInspection, onClearInspection };
}
function handleInspection(
  map: Map,
  activeLayerId: string,
  point: Point,
  lngLat: LngLat,
  activeStyleLayerIds: string[],
  updateMapInspection: (
    layerId: LayerId,
    point: any,
    lngLat: any,
    properties?: {},
  ) => {
    type: string;
    layerId: LayerId;
    inspection: {
      point: any;
      lngLat: any;
      properties: {};
    };
  },
) {
  if (activeStyleLayerIds?.length) {
    const pointPrimitive = makePointPrimitive(point);
    const lngLatPrimitive = makeLngLatPrimitive(lngLat);
    const features = map.queryRenderedFeatures(point, {
      layers: activeStyleLayerIds,
    });
    const propertiesPrimitive = !_.isEmpty(features)
      ? features[0].properties
      : {};
    updateMapInspection(
      activeLayerId,
      pointPrimitive,
      lngLatPrimitive,
      propertiesPrimitive,
    );
  }
}
