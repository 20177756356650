import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';

import { getAnalysisUIVisible } from 'uf/explore/selectors/explore';
import AnalysisPane from 'uf/ui/analysis/AnalysisPane/AnalysisPane';

interface OwnProps {
  width?: number;
}

// add a couple styles to make render more readable
interface StateProps {
  analysisUIVisible: boolean;
}

type Props = StateProps & OwnProps;

export const ExploreSecondaryPanel: FunctionComponent<Props> = props => {
  const { analysisUIVisible, width } = props;

  if (!analysisUIVisible) {
    return null;
  }

  return <AnalysisPane width={width} />;
};

function makeMapStateToProps() {
  return (state): StateProps => ({
    analysisUIVisible: getAnalysisUIVisible(state),
  });
}

export default connect<StateProps, never, OwnProps>(makeMapStateToProps)(
  ExploreSecondaryPanel,
);
