import { Mode } from '@mapbox/mapbox-gl-draw';
import { combineReducers } from 'redux';

import { SET_DRAWING_MODE, SetDrawingModeAction } from 'uf/explore/ActionTypes';
import selectedCoordPaths from 'uf/explore/reducer/selectedCoordPaths';
import selectedFeatureIds from 'uf/explore/reducer/selectedFeatureIds';
import { DrawingMode } from 'uf/explore/state';

export default combineReducers<DrawingMode>({
  mode: reducer,
  selectedCoordPaths,
  selectedFeatureIds,
});

function reducer(
  state: Mode<'box_select_vertices'> = 'simple_select',
  action: SetDrawingModeAction,
): Mode<'box_select_vertices'> {
  switch (action.type) {
    case SET_DRAWING_MODE: {
      const { mode } = action;
      if (state !== mode) {
        return mode;
      }
      return state;
    }
    default:
      return state;
  }
}
