import { createSelector } from 'reselect';

import { EMPTY_OBJECT } from 'uf/base';
import { makeGetFromPropsSelector } from 'uf/base/selector';
import { makeGetExploreUIProperty } from 'uf/explore/selectors/explore';
import { ProjectId } from 'uf/projects';
import { makeGetProjectScenarios } from 'uf/projects/selectors';

import { getReportState } from './';

export interface ReportingContext {
  projectId: ProjectId;
  activeModuleKey: string;

  scenarioIds: string[];
  reportViewVisible: boolean;
}

const getScenariosVisibleState = createSelector(
  getReportState,
  (reportState): Record<string, Record<string, boolean>> =>
    reportState.scenariosVisible || EMPTY_OBJECT,
);

export function makeGetReportVisibleScenarioIds() {
  return createSelector(
    getScenariosVisibleState,
    makeGetProjectScenarios(),
    makeGetFromPropsSelector<ProjectId, 'projectId'>('projectId'),
    (scenariosVisible, scenarios, projectId): string[] => {
      const scenarioMap: Record<string, boolean> =
        scenariosVisible[projectId] || EMPTY_OBJECT;
      // always return in project order
      return scenarios
        .filter(scenario => scenarioMap[scenario.full_path])
        .map(scenario => scenario.full_path);
    },
  );
}

export function makeGetReportActiveModule() {
  return createSelector(
    getReportState,
    makeGetFromPropsSelector<ProjectId, 'projectId'>('projectId'),
    (state, projectId): string => state?.activeModule?.[projectId],
  );
}

export function makeGetReportingContext() {
  return createSelector(
    makeGetFromPropsSelector<ProjectId, 'projectId'>('projectId'),
    makeGetReportVisibleScenarioIds(),
    makeGetReportActiveModule(),
    makeGetExploreUIProperty<boolean>('reportViewVisible'),

    (
      projectId,
      scenarioIds,
      activeModuleKey,
      reportViewVisible,
    ): ReportingContext => {
      return {
        projectId,
        activeModuleKey,
        scenarioIds,
        reportViewVisible,
      };
    },
  );
}
