import { mdiCheckboxBlank } from '@mdi/js';
import Icon from '@mdi/react';
import React, { PureComponent } from 'react';
import { Box } from 'react-layout-components';

import rootStyles from 'uf/styles/root.module.css';
import { IconSizeNew } from 'uf/ui/base/icon';

export interface Props {
  color: string;
  label: string;
  className?: string;
}

export class SeriesLabel extends PureComponent<Props> {
  static defaultProps = {
    color: 'rgba(0, 0, 0, 0)',
  };
  render() {
    const { color, label, className } = this.props;
    if (!label) {
      return null;
    }
    return (
      <Box className={className}>
        <Icon
          color={color}
          path={mdiCheckboxBlank}
          className={rootStyles.veryThinPaddingRight}
          size={IconSizeNew.SMALL}
        />
        <span>{label}</span>
      </Box>
    );
  }
}
