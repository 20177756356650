import colors from 'material-colors';
import { t } from 'ttag';

import { DOLLARS, getUnitName } from 'uf/base/units';
import { LegacyChartSeries, LegacyReport } from 'uf/reporting/index';

const endstateResidentialPropertyTaxRevenueSeries: LegacyChartSeries = {
  key: 'property_tax_revenue_residential',
  source: 'analysis',
  sourceKey: 'fiscal_revenue',
  layerKey: 'fiscal_revenue_results_endstate',
  columnKey: 'property_tax_revenue_residential',
  stat: 'sum',
  color: colors.yellow[600],
};

const endstateCommercialPropertyTaxRevenueSeries: LegacyChartSeries = {
  key: 'property_tax_revenue_commercial',
  source: 'analysis',
  sourceKey: 'fiscal_revenue',
  layerKey: 'fiscal_revenue_results_endstate',
  columnKey: 'property_tax_revenue_commercial',
  stat: 'sum',
  color: colors.red[300],
};

const endstateResidentialOtherTaxRevenueSeries: LegacyChartSeries = {
  key: 'other_tax_revenue_residential',
  source: 'analysis',
  sourceKey: 'fiscal_revenue',
  layerKey: 'fiscal_revenue_results_endstate',
  columnKey: 'other_tax_revenue_residential',
  stat: 'sum',
  color: colors.yellow[700],
};

const endstateCommercialOtherTaxRevenueSeries: LegacyChartSeries = {
  key: 'other_tax_revenue_commercial',
  source: 'analysis',
  sourceKey: 'fiscal_revenue',
  layerKey: 'fiscal_revenue_results_endstate',
  columnKey: 'other_tax_revenue_commercial',
  stat: 'sum',
  color: colors.red[400],
};

const netResidentialPropertyTaxRevenueSeries: LegacyChartSeries = {
  key: 'net_property_tax_revenue_residential',
  source: 'analysis',
  sourceKey: 'fiscal_revenue',
  layerKey: 'fiscal_revenue_results_net',
  columnKey: 'property_tax_revenue_residential',
  stat: 'sum',
  color: colors.yellow[600],
};

const netCommercialPropertyTaxRevenueSeries: LegacyChartSeries = {
  key: 'net_property_tax_revenue_commercial',
  source: 'analysis',
  sourceKey: 'fiscal_revenue',
  layerKey: 'fiscal_revenue_results_net',
  columnKey: 'property_tax_revenue_commercial',
  stat: 'sum',
  color: colors.red[300],
};

const netResidentialOtherTaxRevenueSeries: LegacyChartSeries = {
  key: 'net_other_tax_revenue_residential',
  source: 'analysis',
  sourceKey: 'fiscal_revenue',
  layerKey: 'fiscal_revenue_results_net',
  columnKey: 'other_tax_revenue_residential',
  stat: 'sum',
  color: colors.yellow[700],
};

const netCommercialOtherTaxRevenueSeries: LegacyChartSeries = {
  key: 'net_other_tax_revenue_commercial',
  source: 'analysis',
  sourceKey: 'fiscal_revenue',
  layerKey: 'fiscal_revenue_results_net',
  columnKey: 'other_tax_revenue_commercial',
  stat: 'sum',
  color: colors.red[400],
};

const newResidentialPropertyTaxRevenueSeries: LegacyChartSeries = {
  key: 'new_development_property_tax_revenue_residential',
  source: 'analysis',
  sourceKey: 'fiscal_revenue',
  layerKey: 'fiscal_revenue_results_new_development',
  columnKey: 'property_tax_revenue_residential',
  stat: 'sum',
  color: colors.yellow[600],
};

const newCommercialPropertyTaxRevenueSeries: LegacyChartSeries = {
  key: 'new_development_property_tax_revenue_commercial',
  source: 'analysis',
  sourceKey: 'fiscal_revenue',
  layerKey: 'fiscal_revenue_results_new_development',
  columnKey: 'property_tax_revenue_commercial',
  stat: 'sum',
  color: colors.red[300],
};

const newResidentialOtherTaxRevenueSeries: LegacyChartSeries = {
  key: 'new_development_other_tax_revenue_residential',
  source: 'analysis',
  sourceKey: 'fiscal_revenue',
  layerKey: 'fiscal_revenue_results_new_development',
  columnKey: 'other_tax_revenue_residential',
  stat: 'sum',
  color: colors.yellow[700],
};

const newCommercialOtherTaxRevenueSeries: LegacyChartSeries = {
  key: 'new_development_other_tax_revenue_commercial',
  source: 'analysis',
  sourceKey: 'fiscal_revenue',
  layerKey: 'fiscal_revenue_results_new_development',
  columnKey: 'other_tax_revenue_commercial',
  stat: 'sum',
  color: colors.red[400],
};

const cumulativeNewResidentialPropertyTaxRevenueSeries: LegacyChartSeries = {
  key: 'cumulative_new_development_property_tax_revenue_residential',
  source: 'analysis',
  sourceKey: 'fiscal_revenue',
  layerKey: 'cumulative_new_development_revenue_summary_totals',
  columnKey: 'property_tax_revenue_residential',
  color: colors.yellow[600],
};

const cumulativeNewCommercialPropertyTaxRevenueSeries: LegacyChartSeries = {
  key: 'cumulative_new_development_property_tax_revenue_commercial',
  source: 'analysis',
  sourceKey: 'fiscal_revenue',
  layerKey: 'cumulative_new_development_revenue_summary_totals',
  columnKey: 'property_tax_revenue_commercial',
  color: colors.red[300],
};

const cumulativeNewResidentialOtherTaxRevenueSeries: LegacyChartSeries = {
  key: 'cumulative_new_development_other_tax_revenue_residential',
  source: 'analysis',
  sourceKey: 'fiscal_revenue',
  layerKey: 'cumulative_new_development_revenue_summary_totals',
  columnKey: 'other_tax_revenue_residential',
  color: colors.yellow[700],
};

const cumulativeNewCommercialOtherTaxRevenueSeries: LegacyChartSeries = {
  key: 'cumulative_new_development_other_tax_revenue_commercial',
  source: 'analysis',
  sourceKey: 'fiscal_revenue',
  layerKey: 'cumulative_new_development_revenue_summary_totals',
  columnKey: 'other_tax_revenue_commercial',
  color: colors.red[400],
};

const newDevelopmentImpactFeesResidentialSeries: LegacyChartSeries = {
  key: 'fiscal_revenue_impact_fees_new_development_residential',
  source: 'analysis',
  sourceKey: 'fiscal_revenue',
  layerKey: 'fiscal_revenue_impact_fees_new_development',
  columnKey: 'impact_fees_residential',
  stat: 'sum',
};

const newDevelopmentImpactFeesCommercialSeries: LegacyChartSeries = {
  key: 'fiscal_revenue_impact_fees_new_development_commercial',
  source: 'analysis',
  sourceKey: 'fiscal_revenue',
  layerKey: 'fiscal_revenue_impact_fees_new_development',
  columnKey: 'impact_fees_commercial',
  stat: 'sum',
};

export const fiscalRevenueCharts: LegacyReport = Object.freeze({
  key: 'fiscal_revenue',
  name: 'Fiscal Revenue',
  colorSpec: {
    primaryColor: 'rgb(83, 151, 134)',
    interpolateFromColor: colors.lightBlue[200],
    interpolateToColor: 'rgb(27, 94, 31)',
  },
  charts: [
    {
      name: t`Annual Tax Revenue from New Development (End Year)`,
      key: 'new_tax_revenue',
      units: getUnitName(DOLLARS),
      series: [
        newResidentialPropertyTaxRevenueSeries,
        newResidentialOtherTaxRevenueSeries,
        newCommercialPropertyTaxRevenueSeries,
        newCommercialOtherTaxRevenueSeries,
      ],
      hideZeroValues: true,
    },
    {
      name: t`Cumulative Tax Revenue from New Development`,
      key: 'cumulative_new_tax_revenue',
      units: getUnitName(DOLLARS),
      series: [
        cumulativeNewResidentialPropertyTaxRevenueSeries,
        cumulativeNewResidentialOtherTaxRevenueSeries,
        cumulativeNewCommercialPropertyTaxRevenueSeries,
        cumulativeNewCommercialOtherTaxRevenueSeries,
      ],
      hideZeroValues: true,
    },
    {
      name: t`Change in Annual Tax Revenue from Base Year (End Year)`,
      key: 'net_tax_revenue',
      units: getUnitName(DOLLARS),
      series: [
        netResidentialPropertyTaxRevenueSeries,
        netResidentialOtherTaxRevenueSeries,
        netCommercialPropertyTaxRevenueSeries,
        netCommercialOtherTaxRevenueSeries,
      ],
      hideZeroValues: true,
    },
    {
      name: t`Total Annual Tax Revenue (End Year)`,
      key: 'endstate_tax_revenue',
      units: getUnitName(DOLLARS),
      series: [
        endstateResidentialPropertyTaxRevenueSeries,
        endstateResidentialOtherTaxRevenueSeries,
        endstateCommercialPropertyTaxRevenueSeries,
        endstateCommercialOtherTaxRevenueSeries,
      ],
      hideZeroValues: true,
    },
    {
      name: t`Revenue from Development Impact Fees`,
      key: 'impact_fee_revenue',
      units: getUnitName(DOLLARS),
      series: [
        newDevelopmentImpactFeesResidentialSeries,
        newDevelopmentImpactFeesCommercialSeries,
      ],
      hideZeroValues: true,
    },
  ],
});
