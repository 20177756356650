import _ from 'lodash';

import { LayerColumnStats, ValueBucket } from 'uf-api';

/**
 * Null values can exist in categorical stats. This is communicated by the api
 * when the optional value property is missing, ie:
 *
 * { values_count: 13 }, // <-- this represents the `null` value!
 * { value: 'good', values_count: 52 },
 * { value: 'bad', values_count: 2 },
 */

export interface NullCategory {
  isNullCategory: true;
}

export const NULL_CATEGORY: NullCategory = Object.freeze({
  isNullCategory: true,
});

export function isNullCategory(
  category: number | string | boolean | NullCategory,
): category is NullCategory {
  // We should opt to use NULL_CATEGORY in our code so this is referentially
  // equal, but since the null category will be serialized and read back into
  // symbology we need to do a deep-equals check to cover all cases.
  return _.isEqual(category, NULL_CATEGORY);
}

export function containsStatsNulls(columnStats: LayerColumnStats): boolean {
  const hasCategoricalNulls = !!columnStats?.categorical?.values?.find(
    valueBucket => isNullValueBucket(valueBucket),
  );

  const hasNumericNulls = !!columnStats?.numeric?.null_count;

  return hasCategoricalNulls || hasNumericNulls;
}

export function isNullValueBucket(entry: ValueBucket) {
  return !('value' in entry);
}
