/**
 * UrbanFootprint App API
 * API for serving UrbanFootprint Application
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { LayerColumnValidValues } from './layerColumnValidValues';

/**
 * Describes one column in a layer.
 */
export interface LayerColumn<
  Tdisplay_hints extends object = any,
  Tnumber_format extends object = any,
> {
  /**
   * A very short abbreviation with categorical context. (e.g. \"Ag. Emp.\")
   */
  abbreviation?: string;
  /**
   * Column category, an arbitrary string in column description table.
   */
  category?: string;
  /**
   * A longer description of the property.
   */
  description?: string;
  /**
   * Free-form object for hints to the front-end. Backend might populate it, but backend should never rely on anything in here.
   */
  display_hints?: Tdisplay_hints;
  /**
   * If true we want to hide the column.
   */
  hidden?: boolean;
  /**
   * The canonical, human and machine readable name of the column.
   */
  key: string;
  /**
   * Higher level type of data
   */
  metatype?: LayerColumn.MetatypeEnum;
  /**
   * Name of the property, in English.
   */
  name?: string;
  /**
   * If true we do not want to symbolize the column.
   */
  not_mappable?: boolean;
  /**
   * An object holding all the number formatting information for the column, possible values defined here https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat#parameters
   */
  number_format?: Tnumber_format;
  /**
   * The column name of the source (for data taken directly from tables).
   */
  source?: string;
  /**
   * An very short abbreviation without context. (e.g. \"Ag.\")
   */
  terse_abbreviation?: string;
  /**
   * A shortened version of the name, without context. (e.g. \"Agricultural\")
   */
  terse_name?: string;
  /**
   * The type of data in the column
   */
  type?: LayerColumn.TypeEnum;
  /**
   * What are the units of data in this column.
   */
  units?: string;
  valid_values?: LayerColumnValidValues;
}
export namespace LayerColumn {
  export type MetatypeEnum = 'categorical' | 'numeric';
  export const MetatypeEnum = {
    Categorical: 'categorical',
    Numeric: 'numeric',
  } as const;
  export type TypeEnum =
    | 'string'
    | 'boolean'
    | 'int'
    | 'bigint'
    | 'float'
    | 'bytes';
  export const TypeEnum = {
    String: 'string',
    Boolean: 'boolean',
    Int: 'int',
    Bigint: 'bigint',
    Float: 'float',
    Bytes: 'bytes',
  } as const;
}
