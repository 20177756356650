import { combineReducers } from 'redux';

import { EMPTY_ARRAY, EMPTY_OBJECT } from 'uf/base';
import {
  makeKeyedReducer,
  makeListReducer,
  makeSetKeyedValueReducer,
} from 'uf/base/reducerFactories';
import { ProjectId } from 'uf/projects';
import { LegacyVirtualLayerId } from 'uf/projects/virtualLayers';
import { ViewId } from 'uf/views';
import { visibleLayersListActions } from 'uf/views/actions/layers';
import {
  SET_LAYER_MAP_COLUMN_KEY,
  SET_LAYER_ORDER,
  SetLayerOrderAction,
  visibleLayerListActionTypes,
} from 'uf/views/ActionTypes';
import { LayersByView, ViewsLayersState } from 'uf/views/state';

export const DEFAULT_LAYERS_STATE: ViewsLayersState = {
  visibleLayerIds: [],
  activeColumns: {},
  layerOrder: [],
};
/** All possible actions for list visiblity */
// TODO: surely there's a  better way to extract this type?
type VisibleListActions = ReturnType<
  typeof visibleLayersListActions[keyof typeof visibleLayersListActions]
>;
type LayersReducerActionTypes = VisibleListActions | SetLayerOrderAction;

function layerOrder(
  state: LegacyVirtualLayerId[] = EMPTY_ARRAY,
  action: SetLayerOrderAction = undefined,
) {
  if (action.type === SET_LAYER_ORDER) {
    return action.layerOrder;
  }
  return state;
}
const layersReducer = combineReducers<ViewsLayersState>({
  visibleLayerIds: makeListReducer(visibleLayerListActionTypes),
  layerOrder,
  activeColumns: makeSetKeyedValueReducer(
    SET_LAYER_MAP_COLUMN_KEY,
    ['virtualLayerId'],
    ['columnKey'],
    EMPTY_OBJECT,
  ),
});

export default makeKeyedReducer<
  LayersByView,
  ProjectId,
  LayersReducerActionTypes
>(
  makeKeyedReducer<ViewsLayersState, ViewId, LayersReducerActionTypes>(
    layersReducer,
    'viewId',
  ),
  'projectId',
);
