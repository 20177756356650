import { Position, Tooltip } from '@blueprintjs/core';
import * as React from 'react';

import { Omit } from 'uf/base/types';
import popupStyles from 'uf/styles/popups.module.css';
import { GetComponentProps } from 'uf/ui/base/types';

export interface TooltipTestOptions {
  animation?: boolean;
  delayHide?: number;
  container?: React.ReactNode;
}

interface Props {
  text?: any;
  hide?: boolean;
  placement?: Position;
  /** Deprecated */
  testOptions?: TooltipTestOptions;
  // for the tooltip
  // TODO: Rename these to tooltipClassName and tooltipStyle
  className?: string;
  /** Deprecated */
  style?: object;

  // for the container
  /** Deprecated */
  containerClassName?: string;
}
type TooltipProps = Omit<GetComponentProps<Tooltip>, 'content'>;

export default class WithTooltip extends React.Component<Props & TooltipProps> {
  static defaultProps = {
    placement: Position.TOP,
    text: null,
    popoverClassName: popupStyles.maxLargeWidth,
  };

  render() {
    const { text, hide, placement, children, className, ...tooltipProps } =
      this.props;

    // still render the containing <span> so style doesn't change when the tooltip is on/off
    if (hide || !text) {
      return children;
    }

    return (
      <Tooltip
        content={text}
        disabled={hide || !text}
        position={placement}
        className={className}
        {...tooltipProps}>
        {children}
      </Tooltip>
    );
  }
}
