import colors from 'material-colors';
import { t } from 'ttag';

import { getUnitName, MTCO2E } from 'uf/base/units';
import { LegacyChartSeries, LegacyReport } from 'uf/reporting/index';

const transportGHGSeries: LegacyChartSeries = {
  key: 'total_transportation_ghg',
  source: 'analysis',
  sourceKey: 'ghg_emission',
  layerKey: 'ghg_summary_totals',
  columnKey: 'total_transportation_ghg',
};

const energyGHGSeries: LegacyChartSeries = {
  key: 'total_building_ghg',
  source: 'analysis',
  sourceKey: 'ghg_emission',
  layerKey: 'ghg_emission_results',
  columnKey: 'total_building_ghg',
  stat: 'sum',
};

const waterGHGSeries: LegacyChartSeries = {
  key: 'total_water_ghg',
  source: 'analysis',
  sourceKey: 'ghg_emission',
  layerKey: 'ghg_emission_results',
  columnKey: 'total_water_ghg',
  stat: 'sum',
};

const waterGHGperHouseholdSeries: LegacyChartSeries = {
  key: 'residential_water_ghg_per_hh',
  source: 'analysis',
  sourceKey: 'ghg_emission',
  layerKey: 'ghg_summary_per_hh',
  columnKey: 'residential_water_ghg_per_hh',
};

const energyGHGperHouseholdSeries: LegacyChartSeries = {
  key: 'residential_building_ghg_per_hh',
  source: 'analysis',
  sourceKey: 'ghg_emission',
  layerKey: 'ghg_summary_per_hh',
  columnKey: 'residential_building_ghg_per_hh',
};

const transportGHGperHouseholdSeries: LegacyChartSeries = {
  key: 'transportation_ghg_per_hh',
  source: 'analysis',
  sourceKey: 'ghg_emission',
  layerKey: 'ghg_summary_per_hh',
  columnKey: 'transportation_ghg_per_hh',
};

const noxPollutionSeries: LegacyChartSeries = {
  key: 'total_nox',
  source: 'analysis',
  sourceKey: 'ghg_emission',
  layerKey: 'pollutant_summary_totals',
  columnKey: 'total_nox',
};

const pm10PollutionSeries: LegacyChartSeries = {
  key: 'total_pm10',
  source: 'analysis',
  sourceKey: 'ghg_emission',
  layerKey: 'pollutant_summary_totals',
  columnKey: 'total_pm10',
};

const pm25PollutionSeries: LegacyChartSeries = {
  key: 'total_pm2_5',
  source: 'analysis',
  sourceKey: 'ghg_emission',
  layerKey: 'pollutant_summary_totals',
  columnKey: 'total_pm2_5',
};

const soxPollutionSeries: LegacyChartSeries = {
  key: 'total_sox',
  source: 'analysis',
  sourceKey: 'ghg_emission',
  layerKey: 'pollutant_summary_totals',
  columnKey: 'total_sox',
};

const coPollutionSeries: LegacyChartSeries = {
  key: 'total_co',
  source: 'analysis',
  sourceKey: 'ghg_emission',
  layerKey: 'pollutant_summary_totals',
  columnKey: 'total_co',
};

const rogvocPollutionSeries: LegacyChartSeries = {
  key: 'total_rog_voc',
  source: 'analysis',
  sourceKey: 'ghg_emission',
  layerKey: 'pollutant_summary_totals',
  columnKey: 'total_rog_voc',
};

const totalPollutantsByTypeChart = {
  name: t`Annual GHG Emissions by Source`,
  description: t`Annual greenhouse gas (GHG) emissions from
  transport and household utilities`,
  key: 'total_ghg',
  series: [transportGHGSeries, energyGHGSeries, waterGHGSeries],
  units: getUnitName(MTCO2E),
};

const ghgPerHouseholdByTypeChart = {
  name: t`Per Household Annual GHG Emissions by Source`,
  description: t`Average annual greenhouse gas (GHG) emissions from
  transport and household utilities, per household`,
  key: 'ghg_per_hh',
  units: getUnitName(MTCO2E),
  series: [
    transportGHGperHouseholdSeries,
    energyGHGperHouseholdSeries,
    waterGHGperHouseholdSeries,
  ],
};

export const pollutantTypesChart = {
  name: t`Annual Passenger Vehicle Pollutant Emissions by Type`,
  description: t`Total pollutant emissions from transport`,
  key: 'total_pollutants',
  units: getUnitName(MTCO2E),
  series: [
    noxPollutionSeries,
    pm10PollutionSeries,
    pm25PollutionSeries,
    soxPollutionSeries,
    coPollutionSeries,
    rogvocPollutionSeries,
  ],
};

export const transportEmissionChart = {
  name: 'Annual Passenger Vehicle GHG Emissions',
  description: t`Annual greenhouse gas (GHG) emissions from
  transport`,
  key: 'total_transport_ghg',
  series: [transportGHGSeries],
  units: getUnitName(MTCO2E),
};

export const buildingEnergyEmissionChart = {
  name: t`Annual Building Energy GHG Emissions`,
  description: t`Annual greenhouse gas (GHG) emissions from
  building energy use`,
  key: 'total_building_ghg',
  series: [energyGHGSeries],
  units: getUnitName(MTCO2E),
};

export const emissionsCharts: LegacyReport = Object.freeze({
  key: 'ghg_emission',
  name: 'Emissions',
  colorSpec: {
    primaryColor: 'rgb(119, 123, 74)',
    interpolateFromColor: colors.lime[300],
    interpolateToColor: colors.grey[900],
  },
  charts: [
    totalPollutantsByTypeChart,
    transportEmissionChart,
    buildingEnergyEmissionChart,
    {
      name: t`Annual Water Energy GHG Emissions`,
      description: t`Annual greenhouse gas (GHG) emissions from
    building water use`,
      key: 'total_water_ghg',
      series: [waterGHGSeries],
      units: getUnitName(MTCO2E),
    },
    ghgPerHouseholdByTypeChart,
    pollutantTypesChart,
  ],
});

export const emissionsAnalysisCharts: LegacyReport = Object.freeze({
  key: 'ghg_emission',
  name: 'Emissions',
  charts: [
    totalPollutantsByTypeChart,
    ghgPerHouseholdByTypeChart,
    pollutantTypesChart,
  ],
});
