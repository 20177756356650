import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ProjectMetadata, ScenarioMetadata } from 'uf-api';
import {
  CreateProjectApiArg,
  useCreateProjectMutation,
  useEnqueueCreateBaseCanvasTaskMutation,
  useEnqueueCreateContextCanvasTaskMutation,
} from 'uf-api-rtk/store/Api';
import { ProjectServiceInterface } from 'uf-api/api/project.serviceInterface';
import { setActiveProject } from 'uf/app/actions';
import { Writeable } from 'uf/base/types';
import { logEvent } from 'uf/logging';
import { DefaultProjectTypes } from 'uf/projects';
import { CreateProjectRequest } from 'uf/projects/actions';
import { generateProjectKey, makeLayerQuery } from 'uf/projects/ids';
import { events } from 'uf/projects/logging';
import { extractErrorDetails } from 'uf/ui/base/errors';
import { CreateProjectFormState } from 'uf/ui/projects/CreateProjectForm/CreateProjectForm';
import { useProjectType } from 'uf/ui/projects/hooks';
import warning from 'warning';
import { makeProjectRequest } from './helpers';
import { convertRequestBodyToFormData } from 'uf/data/helpers';

const useCreateProject = (
  formData: CreateProjectFormState,
  sourceProject: ProjectMetadata,
  sourceScenario: ScenarioMetadata,
  setError: React.Dispatch<any>,
  setFormData: React.Dispatch<React.SetStateAction<CreateProjectFormState>>,
  onSuccess: () => void,
  projectTypeKey: string,
) => {
  const dispatch = useDispatch();
  const [createProject, { isLoading, error }] = useCreateProjectMutation();
  const { projectType, status } = useProjectType(projectTypeKey);
  const [
    createBaseCanvas,
    { isLoading: isBaseCanvasRequestLoading, error: createBaseCanvasError },
  ] = useEnqueueCreateBaseCanvasTaskMutation();
  const [
    createContextCanvas,
    {
      isLoading: isContextCanvasRequestLoading,
      error: createContextCanvasError,
    },
  ] = useEnqueueCreateContextCanvasTaskMutation();
  useEffect(() => {
    const requestError =
      error || createBaseCanvasError || createContextCanvasError;
    if (requestError) {
      const formattedError = extractErrorDetails(
        (requestError as FetchBaseQueryError)?.data || requestError,
      );
      setError(formattedError?.errorMessage || formattedError?.errorDetail);
    }
    return () => {
      setError(null);
    };
  }, [createBaseCanvasError, createContextCanvasError, error, setError]);

  const handleCreateProject = useCallback(async () => {
    /**
     * It may be possible projectType not being loaded will cause a project to
     * be created without a base canvas when it indeed needs one, this would
     * cause jarring behavior for a customer, this check just ensures it won't happen
     * even if it is incredibly unlikely.
     * */
    if (!projectType) {
      throw new Error(
        'Project type was not loaded in create project request, it is necessary for the include_base_canvas to be populated before creating a project',
      );
    }
    const { name, description, organizationKey, intersectionMethod } = formData;
    const createProjectParams: CreateProjectRequest = makeProjectRequest(
      name,
      description,
      organizationKey,
      formData,
      sourceProject,
      sourceScenario,
      intersectionMethod,
      projectTypeKey,
    );
    logEvent(events.PROJECT_CANVAS_INFORMATION, { ...createProjectParams });
    const {
      projectAreaLayerId,
      projectAreaLayerFilter,
      projectAreaUploadKey,
      projectCanvasTypeKey,
      contextAreaLayerId,
      contextAreaLayerFilter,
      contextCanvasTypeKey,
      projectRole,
      builtFormsLibraryId,
      projectCanvasLayer,
    } = createProjectParams;
    const namespace = organizationKey;
    const key = generateProjectKey(name);
    // Initialize request body
    const requestBody: Writeable<ProjectServiceInterface.createProjectParams> =
      {
        namespace,
        key,
        name,
        description,
        canvas_type: projectCanvasTypeKey,
        role: projectRole,
        project_type: projectTypeKey,
      };
    // Validate required parameters
    const projectAreaIsValid =
      (projectAreaLayerId && !projectAreaUploadKey) ||
      (!projectAreaLayerId && projectAreaUploadKey);
    warning(
      projectAreaIsValid,
      'Project creation was called with too many project boundary parameters',
    );
    // Update request with project area references
    if (projectAreaLayerId) {
      const projectArea = makeLayerQuery(
        projectAreaLayerId,
        null,
        projectAreaLayerFilter,
      );
      requestBody.project_area_query = JSON.stringify(projectArea);
    } else if (projectAreaUploadKey) {
      requestBody.project_area_shapes_ref = projectAreaUploadKey;
    }
    // Update request with context area references
    if (contextAreaLayerId) {
      const contextArea = makeLayerQuery(
        contextAreaLayerId,
        null,
        contextAreaLayerFilter,
      );
      requestBody.context_area_query = JSON.stringify(contextArea);
      requestBody.context_canvas_type = contextCanvasTypeKey;
    }
    // TODO: Check on parsing post requests as form data via RTK config to avoid TS errors
    const request: CreateProjectApiArg = {
      // @ts-ignore
      body: convertRequestBodyToFormData(requestBody),
    };
    const defaultRequestParams = {
      canvas_type: projectCanvasTypeKey,
      intersection_method: intersectionMethod,
    };
    // Initiate main project creation request
    const res = await createProject(request);
    if (!(res as { error: FetchBaseQueryError | SerializedError })?.error) {
      logEvent(events.CREATE_PROJECT_SUCCESS, { ...createProjectParams });
      // Make base canvas creation request if conditions are met
      if (
        projectTypeKey === DefaultProjectTypes.LAND_USE ||
        projectType.include_base_canvas
      ) {
        const canvasRequestBody = {
          ...defaultRequestParams,
          built_forms_library_path: builtFormsLibraryId,
          project_base_canvas_ref: JSON.stringify(projectCanvasLayer),
        };
        createBaseCanvas({
          namespace,
          key,
          // @ts-ignore
          body: convertRequestBodyToFormData(canvasRequestBody),
        });
      }
      // Make context canvas creation request if conditions are met
      if (contextAreaLayerId) {
        createContextCanvas({
          namespace,
          key,
          // @ts-ignore
          body: convertRequestBodyToFormData(defaultRequestParams),
        });
      }
      setFormData({});
      dispatch(
        // @ts-ignore
        setActiveProject(res?.data?.full_path),
      );
      if (onSuccess) {
        onSuccess();
      }
    } else {
      logEvent(events.CREATE_PROJECT_FAILURE, { ...createProjectParams });
    }
  }, [
    formData,
    sourceProject,
    sourceScenario,
    projectTypeKey,
    projectType,
    createProject,
    setFormData,
    dispatch,
    onSuccess,
    createBaseCanvas,
    createContextCanvas,
  ]);
  return {
    handleCreateProject,
    isLoading:
      isLoading ||
      isBaseCanvasRequestLoading ||
      isContextCanvasRequestLoading ||
      status.isLoading,
  };
};

export { useCreateProject };
