import { Card, Checkbox, Classes, InputGroup } from '@blueprintjs/core';
import classNames from 'classnames';
import React, { useState } from 'react';
import { Control, Controller } from 'react-hook-form';
import { Brand } from 'uf/layers/layerData';
import { VList as List } from 'virtua';

interface ListItemProps {
  item: Brand;
  onClick: (string) => void;
}

const ListItem = ({ item, onClick }: ListItemProps) => {
  const textClassName = item.selected ? 'tw-text-black' : Classes.TEXT_MUTED;
  const label = `${item.name} ${item.category ? `[${item.category}]` : ''}`;
  return (
    <div className="tw-flex tw-items-center tw-pr-5 tw-pl-2">
      <Checkbox
        id={item.content_hash}
        onChange={() => onClick(item.content_hash)}
        checked={item.selected}
        className={classNames('tw-m-0', 'tw-py-3', textClassName)}
        label={label}
      />
      <span
        className={classNames(
          'tw-ml-auto',
          textClassName,
        )}>{`(${item.national_count})`}</span>
    </div>
  );
};

interface Props {
  onClick: (string) => void;
  items: Brand[];
  control: Control<any>;
}

export default function PointsOfInterestSelection({
  onClick,
  items,
  control,
}: Props) {
  const [filter, setFilter] = useState('');
  return (
    <Card className="tw-p-1 tw-mx-8 tw-my-6 tw-flex tw-flex-col tw-flex-grow">
      <InputGroup
        name="filter"
        type="search"
        className="tw-m-1"
        inputClassName="tw-rounded-none "
        leftIcon="search"
        placeholder="Search..."
        onChange={e => setFilter(e.target.value)}
        value={filter}
      />
      <Controller
        name="selectedIds"
        control={control}
        render={({ field }) => (
          <List className="tw-flex-grow">
            {items
              .filter(item =>
                item.name.toLowerCase().includes(filter.toLowerCase()),
              )
              .map(item => (
                <ListItem
                  {...field}
                  key={item.name}
                  item={item}
                  onClick={onClick}
                />
              ))}
          </List>
        )}
      />
    </Card>
  );
}
