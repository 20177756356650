// TODO: Converted with script per issue #10495. Improve typing on next edit.

import { FormGroup, Radio, Spinner, SpinnerSize } from '@blueprintjs/core';
import classNames from 'classnames';
import React, { useCallback, useState } from 'react';
import { jt, t } from 'ttag';

import { OrganizationInfo } from 'uf-api';
import { CanvasTypes } from 'uf/projects';
import MailToLink from 'uf/ui/base/MailToLink/MailToLink';

import styles from './CanvasTypeChoices.module.css';

interface OwnProps {
  id: string;
  organization: OrganizationInfo;
  canvasType: CanvasTypes;
  defaultCanvasType: CanvasTypes;
  canvasArea: object;
  choicesLabel: string;
  canvasTypeLoading: boolean;
  canvasTypeLoaded: boolean;
  canvasTypeError: object;
  parcelCanvasAvailable: boolean;
  parcelCanvasDisabledReason: any;
  blockCanvasAvailable: boolean;
  onCanvasTypeChange: (value: CanvasTypes) => void;
}

const CanvasTypeChoices: React.FunctionComponent<OwnProps> = props => {
  const {
    id,
    organization: { name: organizationName },
    canvasArea,
    canvasType,
    defaultCanvasType,
    choicesLabel,
    parcelCanvasAvailable,
    blockCanvasAvailable,
    parcelCanvasDisabledReason,
    canvasTypeLoading,
    canvasTypeLoaded,
    canvasTypeError,
    onCanvasTypeChange: onCanvasTypeChangeHandler,
  } = props;

  const [lastUserSelection, setUserSelection] = useState(null);

  const onCanvasTypeChange = useCallback(
    e => {
      const value = e?.target?.value as CanvasTypes;
      setUserSelection(value);
      onCanvasTypeChangeHandler(value);
    },
    [onCanvasTypeChangeHandler],
  );

  const noParcelDataAvailable =
    parcelCanvasDisabledReason &&
    (parcelCanvasDisabledReason.code === 'canvas_no_geos' ||
      parcelCanvasDisabledReason.code === 'canvas_failed_quality') &&
    canvasTypeLoaded;
  const parcelProjectSizeTooBig =
    parcelCanvasDisabledReason &&
    parcelCanvasDisabledReason.code === 'canvas_too_large' &&
    canvasTypeLoaded;
  let helpBlock = null;
  if (canvasTypeLoading) {
    helpBlock = t`Getting canvas types...`;
  } else if (canvasTypeError) {
    helpBlock = t`There was an error fetching available canvas types.`;
  } else if (noParcelDataAvailable) {
    const submitRequest = (
      <ParcelCanvasRequestLink
        key="submitRequest"
        organizationName={organizationName}
        canvasArea={canvasArea}
      />
    );
    helpBlock = jt`UrbanFootprint currently does not have parcel data for the project area you selected. ${submitRequest} to the UrbanFootprint team if you need it for your project.`;
  } else if (parcelProjectSizeTooBig) {
    helpBlock = t`The project area you selected exceeds the current maximum parcel canvas size allowed in UrbanFootprint.`;
  }

  const parcelChecked = canvasType === CanvasTypes.PARCEL;
  const blockChecked = canvasType === CanvasTypes.BLOCK;

  // if the user hasn't changed the value, try to default to parcels.
  // if parcels are not available, we have to update to block type canvas.
  if (!parcelCanvasAvailable) {
    if (canvasType === CanvasTypes.PARCEL) {
      onCanvasTypeChangeHandler(CanvasTypes.BLOCK);
    }
  }

  // if the user has changed the value, let's use that value when possible.
  else if (
    parcelCanvasAvailable &&
    lastUserSelection &&
    canvasType !== lastUserSelection
  ) {
    onCanvasTypeChangeHandler(lastUserSelection);
  } else if (
    parcelCanvasAvailable &&
    !lastUserSelection &&
    canvasType !== defaultCanvasType
  ) {
    onCanvasTypeChangeHandler(defaultCanvasType);
  }

  return (
    <FormGroup label={choicesLabel}>
      <Radio
        // TODO: set this to 'CreateProjectForm-{Project,Context}-canvasType'
        // once this is used for both Project and Context areas
        className={classNames(styles.radio, 'CreateProjectForm-canvasType')}
        name={id}
        value={CanvasTypes.PARCEL}
        disabled={!parcelCanvasAvailable}
        checked={parcelChecked}
        onChange={onCanvasTypeChange}>
        {t`Parcel`}
      </Radio>
      <Radio
        // TODO: set this to 'CreateProjectForm-{Project,Context}-canvasType'
        // once this is used for both Project and Context areas
        className={classNames(styles.radio, 'CreateProjectForm-canvasType')}
        name={id}
        value={CanvasTypes.BLOCK}
        disabled={!blockCanvasAvailable}
        checked={blockChecked}
        onChange={onCanvasTypeChange}>
        {t`Census Block`}
      </Radio>
      <span>{helpBlock}</span>
      {canvasTypeLoading && <Spinner size={SpinnerSize.SMALL} />}
    </FormGroup>
  );
};

CanvasTypeChoices.defaultProps = {
  canvasType: CanvasTypes.BLOCK,
  choicesLabel: t`Canvas type`,
  organization: {} as OrganizationInfo,
};

export default CanvasTypeChoices;

interface ParcelCanvasRequestLinkProps {
  organizationName: string;
  canvasArea: any;
}

const ParcelCanvasRequestLink: React.FunctionComponent<ParcelCanvasRequestLinkProps> =
  ({ organizationName, canvasArea }) => {
    let subject = `Parcel Canvas Request from ${organizationName}`;
    if (canvasArea) {
      subject = `${subject} in ${canvasArea.label}`;
    }

    return (
      <MailToLink mailTo="support@urbanfootprint.com" subject={subject}>
        {t`Submit a request`}
      </MailToLink>
    );
  };
