import {
  CuratedColumnSymbology,
  CUSTOM_THEME,
  DEFAULT_OPACITY,
  InterpolationTypes,
  PaintColorTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/public/dataset/california_combined_resistance': {
    raster_val: [
      {
        type: SymbologyTypes.FILL,
        paint: {
          'fill-color': {
            property: 'raster_val',
            type: PaintColorTypes.CATEGORICAL,
            stops: [
              {
                value: 1,
                label: 'High Species Movement Potential',
                color: '#034500',
              },
              {
                value: 2,
                label: 'Medium Species Movement Potential',
                color: '#328a2e',
              },
              {
                value: 3,
                label: 'Low Species Movement Potential',
                color: '#8dcf8a',
              },
            ],
          },
          'fill-opacity': DEFAULT_OPACITY,
        },
        display_hints: {
          scale: SymbologyScales.CATEGORICAL,
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          theme: CUSTOM_THEME,
        },
      },
    ],
  },
};

export default symbologies;
