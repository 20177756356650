import {
  CuratedColumnSymbology,
  InterpolationTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/public/dataset/city_of_portland_zoning_portland_or': {
    zone: [
      {
        display_hints: {
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          scale: SymbologyScales.CATEGORICAL,
          theme: 'custom',
        },
        paint: {
          'fill-color': {
            default: '#c49c94',
            property: 'zone',
            stops: [
              {
                color: '#fda1ff',
                value: 'CE',
              },
              {
                color: '#808080',
                value: 'CI1',
              },
              {
                color: '#808080',
                value: 'CI2',
              },
              {
                color: '#aea1ff',
                value: 'CL',
              },
              {
                color: '#d33115',
                value: 'CM1',
              },
              {
                color: '#d33115',
                value: 'CM2',
              },
              {
                color: '#d62728',
                value: 'CM3',
              },
              {
                color: '#ff9896',
                value: 'CR',
              },
              {
                color: '#9467bd',
                value: 'CX',
              },
              {
                color: '#808080',
                value: 'EG1',
              },
              {
                color: '#808080',
                value: 'EG2',
              },
              {
                color: '#7b64ff',
                value: 'EX',
              },
              {
                color: '#aea1ff',
                value: 'IG1',
              },
              {
                color: '#aea1ff',
                value: 'IG2',
              },
              {
                color: '#aea1ff',
                value: 'IH',
              },
              {
                color: '#c45100',
                value: 'IR',
              },
              {
                color: '#bcbd22',
                value: 'MC',
              },
              {
                color: '#68bc00',
                value: 'OS',
              },
              {
                color: '#e27300',
                value: 'R1',
              },
              {
                color: '#fcdc00',
                value: 'R10',
              },
              {
                color: '#e27300',
                value: 'R2',
              },
              {
                color: '#fcdc00',
                value: 'R20',
              },
              {
                color: '#fe9200',
                value: 'R2.5',
              },
              {
                color: '#e27300',
                value: 'R3',
              },
              {
                color: '#fcdc00',
                value: 'R5',
              },
              {
                color: '#fcdc00',
                value: 'R7',
              },
              {
                color: '#fcdc00',
                value: 'RF',
              },
              {
                color: '#c45100',
                value: 'RH',
              },
              {
                color: '#fe9200',
                value: 'RMP',
              },
              {
                color: '#e27300',
                value: 'RX',
              },
              {
                color: '#999999',
                value: 'WC',
              },
            ],
            type: 'categorical',
          },
          'fill-opacity': 0.9,
        },
        type: SymbologyTypes.FILL,
      },
      {
        display_hints: {
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          scale: SymbologyScales.CATEGORICAL,
          theme: 'custom',
        },
        paint: {
          'line-color': '#999999',
          'line-opacity': 0.9,
          'line-width': 0.5,
        },
        type: SymbologyTypes.LINE,
      },
    ],
  },
};

export default symbologies;
