import loadable from '@loadable/component';
import * as React from 'react';
import { t } from 'ttag';

import { LayerMetadata } from 'uf-api';
import { TabKey } from 'uf/explore/details';
import { ColumnKey, LayerId } from 'uf/layers';
import { DENSITY_COLUMN } from 'uf/layers/canvas';
import { LayerRole } from 'uf/layers/state';
import { ProjectId } from 'uf/projects';
import { LegacyVirtualLayerId } from 'uf/projects/virtualLayers';
import { ScenarioId } from 'uf/scenarios';
import FeatureEditor from 'uf/ui/mapedit/FeatureEditor/FeatureEditor';
import { ViewId } from 'uf/views';

const LayerMetadataSummary = loadable(
  () =>
    import(
      'uf/ui/layers/LayerMetadataSummary/LayerMetadataSummary' /* webpackChunkName: 'layers' */
    ),
);

const ExploreLayerFilters = loadable(
  () =>
    import(
      'uf/ui/explore/ExploreLayerFilters/ExploreLayerFilters' /* webpackChunkName: 'layers' */
    ),
);

/** The new and improved filters */
const FilterPane = loadable(
  () =>
    import(
      'uf/ui/filters/FilterPane/FilterPane' /* webpackChunkName: 'layers' */
    ),
);

const StylePane = loadable(
  () =>
    import(
      'uf/ui/symbology/StylePane/StylePane' /* webpackChunkName: "symbology" */
    ),
);

const SymbologyEditorPane = loadable(
  () =>
    import(
      'uf/ui/symbology/SymbologyEditorPane/SymbologyEditorPane' /* webpackChunkName: "symbology" */
    ),
);
SymbologyEditorPane.displayName = 'SymbologyEditorPane';
const BuildDetailsPane = loadable(
  () =>
    import(
      'uf/ui/painting/BuildDetailsPane/BuildDetailsPane' /* webpackChunkName: "painting" */
    ),
);
const EditPane = loadable(
  () =>
    import(
      'uf/ui/painting/EditPane/EditPane' /* webpackChunkName: 'painting' */
    ),
);
const ExploreBufferPane = loadable(
  () =>
    import(
      'uf/ui/explore/ExploreBufferPane/ExploreBufferPane' /* webpackChunkName: 'layers' */
    ),
);

const LocationAnalysisPane = loadable(
  () => import('uf/ui/location-analysis/LocationAnalysisPane'),
);

type ShouldShowTab = (
  layerRole: LayerRole,
  layer?: LayerMetadata,
  canScenarioUpdate?: boolean,
  isBaseScenario?: boolean,
  featureFlags?: string[],
  geometryType?: string,
  allowedInsightKeys?: Set<number>,
) => boolean;

// should show for all known layer roles
const showStyleTab: ShouldShowTab = layerRole =>
  [
    LayerRole.PROJECT_AREA,
    LayerRole.CONTEXT_AREA,
    LayerRole.SCENARIO_CANVAS,
    LayerRole.BASE_CANVAS,
    LayerRole.REFERENCE,
    LayerRole.WORKING,
    LayerRole.ANALYSIS,
  ].includes(layerRole);

const showBuildTab: ShouldShowTab = (
  layerRole,
  layer,
  canScenarioUpdate,
  isBaseScenario,
) => {
  if (!canScenarioUpdate) {
    return false;
  }
  if (isBaseScenario) {
    return layerRole === LayerRole.BASE_CANVAS;
  }
  return layerRole === LayerRole.SCENARIO_CANVAS;
};

const showEditTab: ShouldShowTab = (
  layerRole: LayerRole,
  layer?: LayerMetadata,
  canScenarioUpdate?: boolean,
  isBaseScenario?: boolean,
  featureFlags?: string[],
): boolean => {
  if (!canScenarioUpdate) {
    return false;
  }

  if (!isBaseScenario) {
    return false;
  }

  if (layerRole !== LayerRole.BASE_CANVAS) {
    return false;
  }

  return true;
};

export interface TabInfoProps {
  projectId?: ProjectId;
  scenarioId: ScenarioId;
  viewId: ViewId;
  layerId: LayerId;
  virtualLayerId: LegacyVirtualLayerId;
  columnKey: ColumnKey;
  layer: LayerMetadata;
  layerRole: LayerRole;
  isBaseScenario: boolean;
  onShowSubComponent: () => void;
  onHideSubComponent: () => void;
  width?: number;
}

type GetComponent = (props?: TabInfoProps) => JSX.Element;

export interface TabInfo {
  key: TabKey;
  title: string;
  /**
   * This component is rendered beneath the tabs
   */
  getComponent: GetComponent;

  /**
   * This component is rendered as a full DetailsPane takeover
   */
  getSubComponent?: GetComponent;
  /**
   * Callback to decide if a panel should be visible or not
   */
  shouldShow: ShouldShowTab;
}

const StyleTabInfo: TabInfo = {
  key: TabKey.STYLE,
  title: t`Style`,
  getComponent({
    projectId,
    viewId,
    layerId,
    virtualLayerId,
    onShowSubComponent,
  }) {
    return (
      <StylePane
        key={virtualLayerId}
        projectId={projectId}
        viewId={viewId}
        layerId={layerId}
        virtualLayerId={virtualLayerId}
        densityColumn={DENSITY_COLUMN}
        onOpenEditor={onShowSubComponent}
      />
    );
  },

  getSubComponent({
    projectId,
    viewId,
    layerId,
    virtualLayerId,
    columnKey,
    onHideSubComponent,
  }) {
    return (
      <SymbologyEditorPane
        projectId={projectId}
        viewId={viewId}
        layerId={layerId}
        virtualLayerId={virtualLayerId}
        columnKey={columnKey}
        densityColumn={DENSITY_COLUMN}
        onClose={onHideSubComponent}
      />
    );
  },

  shouldShow: showStyleTab,
};

const BuildTabInfo: TabInfo = {
  key: TabKey.BUILD,
  title: t`Build`,
  getComponent({ projectId, scenarioId, width, isBaseScenario }) {
    return (
      <BuildDetailsPane
        key={projectId}
        projectId={projectId}
        scenarioId={scenarioId}
        isBaseScenario={isBaseScenario}
        width={width}
      />
    );
  },
  shouldShow: showBuildTab,
};

const FilterTabInfo: TabInfo = {
  key: TabKey.FILTER,
  title: t`Filter`,
  getComponent({ projectId, scenarioId, layerId }) {
    return (
      <ExploreLayerFilters
        key={layerId}
        projectId={projectId}
        scenarioId={scenarioId}
        layerId={layerId}
      />
    );
  },
  shouldShow: (layerRole, layer: LayerMetadata) => {
    return true;
  },
};

const NewFilterTabInfo: TabInfo = {
  key: TabKey.NEW_FILTER,
  title: t`Filter (new)`,
  getComponent({ projectId, scenarioId, layerId, layer }) {
    return (
      <FilterPane
        key={layerId}
        projectId={projectId}
        scenarioId={scenarioId}
        layerId={layerId}
        layerMetadata={layer}
      />
    );
  },
  shouldShow: (layerRole, projectId, layerMetadata, isBaseScenario, flags) =>
    flags.includes('new-filters'),
};

const EditTabInfo: TabInfo = {
  key: TabKey.EDIT,
  title: t`Edit`,
  getComponent({ projectId, viewId, scenarioId }) {
    return (
      <EditPane
        key={projectId}
        projectId={projectId}
        viewId={viewId}
        scenarioId={scenarioId}
      />
    );
  },
  shouldShow: showEditTab,
};

const ExploreBufferTabInfo: TabInfo = {
  key: TabKey.BUFFER,
  title: t`Buffer`,
  getComponent({ projectId, layerId, viewId, scenarioId }) {
    return (
      <ExploreBufferPane
        key={layerId}
        projectId={projectId}
        layerId={layerId}
      />
    );
  },
  shouldShow: () => true,
};

const SourceTabInfo: TabInfo = {
  key: TabKey.SOURCE,
  title: t`Source`,
  getComponent({ layerId, projectId }) {
    return (
      <LayerMetadataSummary
        key={layerId}
        charLimit={60}
        projectId={projectId}
        layerId={layerId}
        displayPanelView
      />
    );
  },
  shouldShow: layerRole => !!layerRole,
};

const FeatureEditsTabInfo: TabInfo = {
  key: TabKey.FEATURE_EDIT,
  title: t`Edit Feature`,
  getComponent({ layerId, projectId }) {
    return (
      <FeatureEditor key={layerId} projectId={projectId} layerId={layerId} />
    );
  },
  shouldShow: (layerRole, projectId, layerMetadata, isBaseScenario, flags) =>
    flags.includes('dev-map-edits'),
};

const InsightsTabInfo: TabInfo = {
  key: TabKey.INSIGHTS,
  title: t`Insights`,
  getComponent({ layerId }) {
    return <LocationAnalysisPane layerId={layerId} />;
  },
  shouldShow: (
    layerRole,
    layerMetadata,
    canScenarioUpdate,
    isBaseScenario,
    flags,
    geometryType,
    allowedInsightKeys,
  ) => {
    return (
      !!layerMetadata &&
      flags.includes('location-analysis') &&
      !!allowedInsightKeys.size
    );
  },
};

export const DetailsPaneTabs: TabInfo[] = [
  StyleTabInfo,
  BuildTabInfo,
  FilterTabInfo,
  NewFilterTabInfo,
  EditTabInfo,
  ExploreBufferTabInfo,
  SourceTabInfo,
  FeatureEditsTabInfo,
  InsightsTabInfo,
];
