import _ from 'lodash';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { LayerColumn, LayerNumericStats, LayerStats } from 'uf-api';
import { selectTableState } from 'uf/explore/reducer/tableSlice';
import { ColumnKey } from 'uf/layers';

import { shouldHideColumn } from 'uf/layers/metadata';

import useColumnState, { Column } from './useColumnState';

const PIXELS_PER_CHARACTER = 20; // hack: 20 pixels per character. This could be better calculated

const DEFAULT_CELL_CHARACTER_WIDTH = 8;
const MIN_CELL_WIDTH = 70;

export type OnReorderColumns = (
  oldIndex: number,
  newIndex: number,
  length: number,
) => void;

export type OnFreezeColumn = (frozenColumnKey: ColumnKey | string) => void;

interface FilteredData {
  columns: string[];
  filteredStats: LayerNumericStats[];
  onReorderColumns: OnReorderColumns;
  onFreezeColumn: OnFreezeColumn;
}

// Calculates the default column widths, scaling to the size of the table.
export function fitColumnWidthsToTable(
  tableWidth: number,
  cols: string[],
): number[] {
  // Get list of default column widths, based off content inside each
  const widths = cols.map(() =>
    Math.max(
      DEFAULT_CELL_CHARACTER_WIDTH * PIXELS_PER_CHARACTER,
      MIN_CELL_WIDTH,
    ),
  );

  // If we're not filling available space, scale all the cells up to take up the entire space.
  const totalCellsWidth = _.sum(widths);
  if (totalCellsWidth < tableWidth) {
    const ratio = tableWidth / totalCellsWidth;
    return widths.map(w => w * ratio);
  }
  return widths;
}

function shouldShowColumnByName(
  column: LayerColumn,
  columnFilterLowerCase: string,
) {
  if (!columnFilterLowerCase) {
    return !shouldHideColumn(column);
  }
  const { name = '', key } = column;
  return (
    (name.toLocaleLowerCase().includes(columnFilterLowerCase) ||
      key.includes(columnFilterLowerCase)) &&
    !shouldHideColumn(column)
  );
}

const useFormattedTableData = (
  columns: LayerColumn[] = [],
  stats: LayerStats,
  columnFilter: string,
): FilteredData => {
  const {
    setColumnOrder,
    setFrozenColumns,
    getMappedColumnsByKeys,
    columnsById,
    ids: columnIds,
  } = useColumnState();
  const columnMetadataByKey = _.keyBy(columns, 'key');
  const { showOnlyFavorites } = useSelector(selectTableState);
  const searchFilter = columnFilter.toLocaleLowerCase();
  /**
   * Once a table's columns have been reordered, the response from the UserData API
   * will have the same length as the columns from metadata and we will use the former
   * to enforce persistent column order between sessions. Otherwise we want to select
   * the columns directly from metadata, as we may only have a few columns with persisted
   * state (favorited, for example) which would render an incomplete table.
   */

  // Filter out hidden columns
  const visibleColumns = columns?.filter(column => !shouldHideColumn(column));
  const visibleColumnIds = columnIds.filter(id => {
    // Check that the column hasn't been filtered out (ex. "Show only favorites" has been toggled)
    const column = visibleColumns.find(col => col.key === id);
    return column && !shouldHideColumn(column);
  });
  const columnKeys =
    visibleColumnIds.length === visibleColumns?.length
      ? visibleColumnIds
      : visibleColumns?.map(column => column.key);

  // dictionary of stats in columns
  const statsByKey = useMemo(() => {
    return _.keyBy(stats.column_stats, 'column_key');
  }, [stats.column_stats]);

  const columnsWithState = _.keyBy(
    columnKeys.map(key => {
      return {
        ...columnMetadataByKey[key],
        ...columnsById[key],
      };
    }),
    'key',
  );

  const filteredColumns = Object.values(columnsWithState)
    .filter(column => shouldShowColumnByName(column, searchFilter))
    .filter(column => !showOnlyFavorites || column.isFavorite);

  const filteredKeys = filteredColumns.map(({ key }) => key);

  // This does last mile filtering logic on ordered data.
  const filteredStats = useMemo(() => {
    return filteredKeys.map(key => {
      const stat = statsByKey[key];
      if (stat?.numeric) {
        return stat.numeric;
      }
      return null;
    });
  }, [statsByKey, filteredKeys]);

  /**
   * Defines a Blueprint callback called when columns are reordered.
   * Everything is downstream of orderedColumns changes, so this
   * just sorts that using some basic custom logic
   */
  const onReorderColumns: OnReorderColumns = useCallback(
    (oldIndex: number, newIndex: number, length: number) => {
      if (oldIndex === newIndex) {
        return;
      }

      const direction = newIndex > oldIndex ? 1 : -1;

      // figure out how many items are frozen _before_ we move anything
      // @ts-expect-error this is a totally valid array method
      const prevFrozenIndex = columnKeys.findLastIndex(
        id => columnsWithState[id]?.isFrozen,
      );

      // grab the keys of the selected, visible columns
      const selectedColumns = filteredKeys.slice(oldIndex, oldIndex + length);

      // the list of ALL keys with the moving keys removed
      const restColumns = columnKeys.filter(
        key => !selectedColumns.includes(key),
      );

      /*
       * since restColumns excludes the moving keys, we can create a hole at the new index
       * and directly insert the moved keys there, resulting in a correctly ordered
       * array with the selected keys moved to the right spot
       */
      const newColumnIds = [
        ...restColumns.slice(0, newIndex),
        ...selectedColumns,
        ...restColumns.slice(newIndex),
      ];

      /*
       * to compute the new frozen set, we need to determine if the selected keys moved
       * into or out of the frozen set, adjusting the new index by the length (number of columns moving)
       * and direction of the move
       *   - if they are moving _out_ (newIndex + length > prevIndex): count frozen _without_ the selected keys
       *   - if they are moving in (newIndex - length < prevFrozenIndex): count frozen _with_ the selected keys
       *
       */
      const sourceColumns =
        newIndex + length * direction > prevFrozenIndex
          ? restColumns
          : newColumnIds;

      // @ts-expect-error this is a totally valid array method
      const lastFrozenIndex = sourceColumns.findLastIndex(
        id => columnsWithState[id]?.isFrozen,
      );

      // add isFrozen property to every column, freezing all below the frozen index
      const newColumnObjects = getMappedColumnsByKeys(newColumnIds).map(
        (col, i) => ({ ...col, isFrozen: i <= lastFrozenIndex }),
      );
      setColumnOrder(newColumnObjects);
    },
    [
      columnKeys,
      columnsWithState,
      filteredKeys,
      getMappedColumnsByKeys,
      setColumnOrder,
    ],
  );

  /*
   * logic duplicated from above
   */
  const onFreezeColumn = useCallback(
    (colKey: ColumnKey | string) => {
      const restColumnIds = columnKeys.filter(key => key !== colKey);

      const shouldFreeze = !columnsWithState[colKey]?.isFrozen;

      /*
       * because Array::slice(begin, end) _excludes_ end, we need to nudge it up by 1
       * the case where we are freezing so that we don't call e.g. slice(0, 0)
       * we have 1 frozen item
       */
      const offset = 1;

      const lastFrozenIndex: number =
        // @ts-expect-error
        (restColumnIds.findLastIndex(
          key => columnsWithState[key]?.isFrozen,
        ) as number) + offset;

      const newColumnIds = [
        ...restColumnIds.slice(0, lastFrozenIndex),
        colKey,
        ...restColumnIds.slice(lastFrozenIndex),
      ];

      const newColumns = getMappedColumnsByKeys(newColumnIds).map(
        (col: Column) => {
          if (col.key === colKey) {
            return { ...col, isFrozen: shouldFreeze };
          }
          return col;
        },
      );

      setFrozenColumns(colKey, newColumns, shouldFreeze);
    },
    [columnsWithState, setFrozenColumns, columnKeys, getMappedColumnsByKeys],
  );

  return {
    columns: filteredKeys,
    filteredStats,
    onReorderColumns,
    onFreezeColumn,
  };
};

export { useFormattedTableData };
