import colors from 'material-colors';

import {
  CuratedColumnSymbology,
  CUSTOM_THEME,
  DEFAULT_OPACITY,
  InterpolationTypes,
  PaintColorTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

import stateList from './us_states';

const symbologies: Record<
  string,
  Record<string, CuratedColumnSymbology[]>
> = {};

stateList.forEach(stateName => {
  symbologies[`/public/dataset/tiger_roads_${stateName}`] = {
    mtfcc: [
      {
        type: SymbologyTypes.LINE,
        paint: {
          'line-color': {
            property: 'mtfcc',
            type: PaintColorTypes.CATEGORICAL,
            stops: [
              { value: 'S1100', label: 'Primary Road', color: '#e27300' },
              { value: 'S1200', label: 'Secondary Road', color: '#fcc400' },
              {
                value: 'S1400',
                label: 'Local Neighborhood Road, Rural Road, City Street',
                color: '#6f6f6f',
              },
              {
                value: 'S1500',
                label: 'Vehicular Trail (4WD)',
                color: '#b7b392',
              },
              { value: 'S1630', label: 'Ramp', color: '#fde7c4' },
              { value: 'S1640', label: 'Service Drive', color: '#ffebaf' },
              {
                value: 'S1710',
                label: 'Walkway/Pedestrian Trail',
                color: '#b9b794',
              },
              { value: 'S1720', label: 'Stairway', color: '#d4d4d4' },
              { value: 'S1730', label: 'Alley', color: colors.yellow[100] },
              {
                value: 'S1740',
                label: 'Private Road for service vehicles',
                color: '#ff0052',
              },
              {
                value: 'S1750',
                label: 'Internal U.S. Census Bureau use',
                color: '#081a37',
              },
              { value: 'S1780', label: 'Parking Lot Road', color: '#d7d6d4' },
              { value: 'S1820', label: 'Bike Path or Trail', color: '#cfa76e' },
              { value: 'S1830', label: 'Bridle Path', color: '#673e0f' },
              { value: 'S2000', label: 'Road Median', color: '#cbe6a3' },
            ],
          },
          'line-opacity': DEFAULT_OPACITY,
        },
        display_hints: {
          scale: SymbologyScales.CATEGORICAL,
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          theme: CUSTOM_THEME,
        },
      },
    ],
  };
});

export default symbologies;
