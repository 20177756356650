import {
  getAnalysisModuleActionTypes,
  getAnalysisModuleClearAction,
  getProjectAnalysisParamsActionTypes,
} from 'uf-api/api/project.service';
import {
  AnalysisParameterActionInfo,
  makeAnalysisParameterKey,
  makeAnalysisSchemaKey,
} from 'uf/analysiseditor';
import { SET_ACTIVE_ANALYSIS_EDITOR_MODULE_KEY } from 'uf/analysiseditor/ActionTypes';
import {
  loadAnalysisScenarioParams as loadAnalysisScenarioParamsAPI,
  loadAnalysisSchema as loadAnalysisSchemaAPI,
} from 'uf/analysiseditor/apis';
import {
  makeGetAnalysisScenarioParams,
  makeGetAnalysisSchema,
} from 'uf/analysiseditor/selectors';
import { dispatchAsyncAction, makeEnsureActionCreator } from 'uf/data/loader';
import { ProjectId } from 'uf/projects';
import { ScenarioId } from 'uf/scenarios';

export function setActiveAnalysisEditorModuleKey(
  moduleKey: string,
  projectId: ProjectId,
) {
  return {
    type: SET_ACTIVE_ANALYSIS_EDITOR_MODULE_KEY,
    value: moduleKey,
    projectId,
  };
}

export function loadAnalysisSchema(projectId: ProjectId, moduleKey: string) {
  return dispatchAsyncAction(
    getAnalysisModuleActionTypes,
    loadAnalysisSchemaAPI(projectId, moduleKey),
    { key: makeAnalysisSchemaKey(projectId, moduleKey) },
  );
}

export function clearAnalysisSchema(
  projectId: ProjectId,
  moduleKey: string,
): getAnalysisModuleClearAction {
  return {
    type: getAnalysisModuleActionTypes.CLEAR,
    key: makeAnalysisSchemaKey(projectId, moduleKey),
  };
}

export const ensureAnalysisSchema = makeEnsureActionCreator(
  loadAnalysisSchema,
  makeGetAnalysisSchema(),
  (projectId: ProjectId, moduleKey: string) => ({ projectId, moduleKey }),
);

export function loadAnalysisScenarioParams(
  projectId: ProjectId,
  scenarioId: ScenarioId,
  moduleKey: string,
) {
  const extra: AnalysisParameterActionInfo = {
    projectId,
    scenarioId,
    moduleKey,
  };
  return dispatchAsyncAction(
    getProjectAnalysisParamsActionTypes,
    loadAnalysisScenarioParamsAPI(projectId, scenarioId, moduleKey),
    {
      key: makeAnalysisParameterKey(projectId, scenarioId, moduleKey),
      extra,
    },
  );
}

export const ensureScenarioAnalysisParams = makeEnsureActionCreator(
  loadAnalysisScenarioParams,
  makeGetAnalysisScenarioParams(),
  (projectId: ProjectId, scenarioId: ScenarioId, moduleKey: string) => ({
    projectId,
    scenarioId,
    moduleKey,
  }),
);
