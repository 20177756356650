import { combineReducers } from 'redux';

import {
  enqueuePaintBaseScenarioCanvasActionTypes,
  enqueuePaintScenarioCanvasActionTypes,
} from 'uf-api/api/paint.service';
import {
  makeKeyedReducer,
  makeSetValueOnlyReducer,
  makeSetValueReducer,
} from 'uf/base/reducerFactories';
import { NUMERIC_STAT_SUM } from 'uf/base/stats/AggregateTypes';
import {
  BIND_PAINT_BASE_SCENARIO_TASK,
  BIND_PAINT_SCENARIO_TASK,
  SET_ACTIVE_VIEW,
  SET_DETAILS_TAB,
  SET_FAILED_INTERSECTIONS,
  SET_SUMMARY_STATS_TYPE,
  SET_TOO_MANY_FEATURES,
} from 'uf/explore/ActionTypes';
import drawingMode from 'uf/explore/reducer/drawingMode';
import { ExploreState, LegacyExploreState } from 'uf/explore/state';
import { SPLITTING_ENABLED } from 'uf/featuresplit/ActionTypes';
import { makeCombinedTaskReducer } from 'uf/tasks/reducerHelpers';
import { TaskTypes } from 'uf/tasks/TaskTypes';

import charts from './charts';
import { featureIds, gridOptions } from './features';
import filters from './filters';
import layers from './layers';
import map from './map';
import markers from './markers';
import paint from './paint';
import scenarios from './scenarios';
import ui from './ui';
import visibilityFilters from './visibility';

// Manages state for the ExploreColumnSummary
const stats = makeSetValueReducer<string>(SET_SUMMARY_STATS_TYPE, {
  aggregateType: NUMERIC_STAT_SUM.key,
});

const explore = combineReducers<ExploreState>({
  charts,
  detailsTabKey: makeKeyedReducer(
    makeSetValueOnlyReducer(SET_DETAILS_TAB),
    'layerId',
  ),
  drawingMode: makeKeyedReducer(drawingMode, 'layerId'),
  tooManyFeaturesFromSplit: makeKeyedReducer(
    makeSetValueOnlyReducer(SET_TOO_MANY_FEATURES),
    'layerId',
  ),
  failedIntersections: makeKeyedReducer(
    makeSetValueOnlyReducer(SET_FAILED_INTERSECTIONS),
    'layerId',
  ),
  featureIdsFromSplit: featureIds,
  splittingEnabled: makeSetValueOnlyReducer(SPLITTING_ENABLED, false),
  gridOptionsForFeatureSplitting: gridOptions,
  filters,
  layers,
  map,
  markers,
  paint,
  scenarios,
  stats,
  ui,
  visibilityFilters,
  activeViewId: makeSetValueOnlyReducer(SET_ACTIVE_VIEW),
});

export const legacyExplore = combineReducers<LegacyExploreState>({
  map,
  scenarios,
  ui,
  paintBaseTasks: makeCombinedTaskReducer(
    enqueuePaintBaseScenarioCanvasActionTypes,
    BIND_PAINT_BASE_SCENARIO_TASK,
    TaskTypes.TASK_TYPE_BASE_SCENARIO_PAINT,
  ),
  paintTasks: makeCombinedTaskReducer(
    enqueuePaintScenarioCanvasActionTypes,
    BIND_PAINT_SCENARIO_TASK,
    TaskTypes.TASK_TYPE_SCENARIO_PAINT,
  ),
});

export default explore;
