import _ from 'lodash';
import { createSelector } from 'reselect';

import { LayerStats } from 'uf-api/model/models';
import { makeGetApiObjectResults } from 'uf/api/selectors';
import { EMPTY_OBJECT } from 'uf/base';
import {
  makeGetFromPropsOptionalSelector,
  makeGetFromPropsSelector,
} from 'uf/base/selector';
import { DataState, getData, isLoaded } from 'uf/data/dataState';
import { createParameterizedDataSelector } from 'uf/data/selectors';
import { FilterSpec, getStatsSearchKey, StatsParams } from 'uf/layers/filters';

import { LayerId } from '../';
import { makeGetLayerVersion } from './versions';

export const getLayersStatsStates = makeGetApiObjectResults(
  'layer',
  'get_layer_stats',
);

export const getAvailableLayerStats = createSelector(
  getLayersStatsStates,
  layersStatsStates => _.values(layersStatsStates),
);

export const getLayersStats = createSelector(
  getLayersStatsStates,
  layersStatsStates =>
    _.chain(layersStatsStates)
      .pickBy(state => isLoaded(state))
      .mapValues(state => getData(state))
      .value(),
);

export function makeGetLayerStatsByKey() {
  return createSelector(
    getLayersStatsStates,
    makeGetFromPropsSelector<string, 'key'>('key'),
    (layersStatsStates, statsKey) => layersStatsStates[statsKey],
  );
}

/**
 * Selector for getting the loading state for a given (layerId, filter)
 * combination. This is similar to {@link getLayerFilteredStatsState} , but
 * you must pass in the filter directly.
 *
 * Generally do not include the version in the filter, just let the selector do the work there.
 */
function makeGetLayerStatsStateForFilters() {
  return createSelector(
    getAvailableLayerStats,
    makeGetFromPropsSelector<string, 'searchKey'>('searchKey'),
    (availableLayerStats, searchKey) => {
      const layerStats = availableLayerStats.find(
        stats => stats.key === searchKey,
      );
      return layerStats || (EMPTY_OBJECT as DataState<LayerStats>);
    },
  );
}

const getLayerStatsForFilters = createParameterizedDataSelector(
  makeGetLayerStatsStateForFilters(),
  EMPTY_OBJECT as LayerStats,
);

export const getLayerStatsForFiltersByColumnKey = createSelector(
  getLayerStatsForFilters,
  stats => _.keyBy(stats.column_stats, 'column_key'),
);

export function makeGetLayerStatsState() {
  return createSelector(
    getAvailableLayerStats,
    makeGetLayerVersion(),
    makeGetFromPropsSelector<LayerId, 'layerId'>('layerId'),
    makeGetFromPropsSelector<string[], 'columns'>('columns'),
    makeGetFromPropsOptionalSelector<LayerId, 'parentLayerId'>('parentLayerId'),
    (availableLayerStats, version, layerId, columns) => {
      const statsKey = getStatsSearchKey(layerId, { version, columns });
      const activeLayerStats = availableLayerStats.filter(
        layerStats => layerStats.key === statsKey,
      );
      return activeLayerStats[0] || (EMPTY_OBJECT as DataState<LayerStats>);
    },
  );
}
export function makeGetFilteredLayerStatsState() {
  return createSelector(
    getAvailableLayerStats,
    makeGetLayerVersion(),
    makeGetFromPropsSelector<LayerId, 'layerId'>('layerId'),
    makeGetFromPropsSelector<string[], 'columns'>('columns'),
    makeGetFromPropsSelector<Partial<FilterSpec>, 'filters'>('filters'),
    makeGetFromPropsOptionalSelector<number, 'maxCategoricalValues'>(
      'maxCategoricalValues',
    ),
    // only specify to override the current version
    makeGetFromPropsOptionalSelector<string, 'version'>('version'),
    (
      availableLayerStats,
      version,
      layerId,
      columns,
      filters,
      maxCategoricalValues,
      versionOverride,
    ) => {
      const statsKey = getStatsSearchKey(layerId, {
        version: versionOverride ?? version,
        columns,
        filters,
        max_categorical_values: maxCategoricalValues,
      } as StatsParams);
      const activeLayerStats = availableLayerStats.filter(
        layerStats => layerStats.key === statsKey,
      );
      return activeLayerStats[0] || (EMPTY_OBJECT as DataState<LayerStats>);
    },
  );
}
export const getLayerStats = createParameterizedDataSelector(
  makeGetLayerStatsState(),
  EMPTY_OBJECT as LayerStats,
);
