import { MenuItem, Position, Spinner, Tooltip } from '@blueprintjs/core';
import { boundMethod } from 'autobind-decorator';
import React, { Component, ReactNode } from 'react';

import popupStyles from 'uf/styles/popups.module.css';
import { IconSize } from 'uf/ui/base/icon';

interface Props {
  name?: string;
  label: ReactNode;
  labelElement?: ReactNode;
  icon?: JSX.Element;
  className?: string;
  href?: string;
  onSelect?: () => void;
  /**
   * note this is @deprecated in favor of onSelect
   */
  onClick?: (e?: any) => void;

  loading?: boolean;
  disabled?: boolean;
  closeAfterLoading?: boolean;
  closeMenu?: () => void;

  tooltip?: string | JSX.Element;

  tooltipPosition?: Position;
}

interface State {
  disabled: boolean;

  loaded: boolean;
}

class OverflowMenuItem extends Component<Props, State> {
  static defaultProps = {
    // delays closing the menu in case of async operations
    closeAfterLoading: false,
  };

  state = {
    disabled: false,
    loaded: false,
  };
  componentDidUpdate(prevProps: Props) {
    const { loading } = this.props;

    const doneLoading = !loading && prevProps.loading;
    if (!this.state.loaded && doneLoading) {
      const { closeMenu } = this.props;
      if (closeMenu) {
        closeMenu();
      }
      this.setState({ loaded: doneLoading });
    }
  }

  @boundMethod
  onSelect() {
    const { onSelect, closeAfterLoading } = this.props;
    if (onSelect) {
      onSelect();

      // if selecting the item kicks off an async process and we want to wait for it
      // to complete (closeAfterLoading), we will wait until then to close the menu.  This is
      // handled in componentDidUpdate.
      if (closeAfterLoading) {
        this.setState({ disabled: true });
      }
    }
  }

  render() {
    const {
      label: rawLabel,
      labelElement,
      icon,
      loading,
      disabled,
      className,
      // eslint-disable-next-line no-unused-vars
      onSelect,
      closeMenu,
      closeAfterLoading,
      tooltip,
      tooltipPosition,
      ...passthroughProps
    } = this.props;
    const { loaded } = this.state;

    const itemDisabled = this.state.disabled || loading || disabled;
    const label = tooltip ? (
      <Tooltip
        content={tooltip}
        position={tooltipPosition}
        popoverClassName={popupStyles.largeWidth}>
        {rawLabel}
      </Tooltip>
    ) : (
      rawLabel
    );
    return (
      <MenuItem
        data-testid="overflow-menu-item"
        disabled={itemDisabled}
        className={className}
        onClick={this.onSelect}
        icon={icon}
        labelElement={
          loading ? <Spinner size={IconSize.EXTRA_SMALL} /> : labelElement
        }
        text={label}
        shouldDismissPopover={loaded || !closeAfterLoading}
        {...passthroughProps}
      />
    );
  }
}

export default OverflowMenuItem;
