import _ from 'lodash';
import { AnyAction } from 'redux';
import { Epic, ofType } from 'redux-observable';
import { merge as observableMerge, of as observableOf } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';

import { ScenarioMetadata } from 'uf-api';
import { getReportClearAction } from 'uf-api/api/report.service';
import { LayerId } from 'uf/layers';
import { makeGetProjectScenarios } from 'uf/projects/selectors';
import { convertLegacyReport, LegacyReport } from 'uf/reporting';
import { clearScenarioReport } from 'uf/reporting/actions';
import { getReportingChartsSpecsForProject } from 'uf/reporting/selectors';
import { NotificationTypes } from 'uf/tasks/NotificationTypes';
import { makeWebsocketActionType } from 'uf/websockets/actionHelpers';

/**
 * When an analysis module finishes running, clear any reports
 * that use that module.
 */
export const clearScenarioOnPaint: Epic<
  AnyAction,
  any,
  any,
  getReportClearAction
> = (action$, state$) => {
  const getProjectScenarios = makeGetProjectScenarios();
  return action$.pipe(
    ofType(makeWebsocketActionType(NotificationTypes.PROJECT_LAYER_UPDATED)),
    map(({ result }) => {
      const { layer: layerId, scope: projectId } = result;
      const scenarios = getProjectScenarios(state$.value, { projectId });
      return { projectId, scenarios, layerId };
    }),
    filter(({ scenarios }) => !_.isEmpty(scenarios)),
    mergeMap(({ projectId, scenarios, layerId }) => {
      const affectedScenarios = scenarios.filter(scenario =>
        isCanvasLayer(scenario, layerId),
      );
      const specs = getReportingChartsSpecsForProject(state$.value, {
        projectId,
      });
      const affectedSpecs = specs.filter(spec => reportIsPainting(spec));

      const clearActions = affectedSpecs.flatMap(spec => {
        const reportSpec = convertLegacyReport(spec);
        return affectedScenarios.map(scenario =>
          observableOf(
            clearScenarioReport(projectId, scenario.full_path, reportSpec),
          ),
        );
      });

      return observableMerge(...clearActions);
    }),
  );
};

/** Returns true if this layer is the scenario's canvas layer */
function isCanvasLayer(scenario: ScenarioMetadata, layerId: LayerId) {
  if (scenario.base_scenario) {
    if (scenario.base_edits_painted_canvas.full_path === layerId) {
      return true;
    }
    return false;
  }
  // uninitialized scenarios are missing this (mostly just in tests)
  if (!scenario.painted_uf_canvas) {
    return false;
  }
  if (scenario.painted_uf_canvas.full_path === layerId) {
    return true;
  }
  return false;
}

function reportIsPainting(spec: LegacyReport) {
  return spec.charts.some(chart =>
    chart.series.some(series => series.source === 'canvas'),
  );
}
export default clearScenarioOnPaint;
