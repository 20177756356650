import { Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { BUILT_FORM_KEY, BUILT_FORM_TYPES, BuiltFormInfo } from 'uf/builtforms';
import { ensureLibraryBuiltForms } from 'uf/builtforms/actions/data';
import { ensureLayerData } from 'uf/explore/actions/layers';
import { LayerId } from 'uf/layers';
import { ensureLayerStats } from 'uf/layers/actions/stats';
import { makeGetLayerVersion } from 'uf/layers/selectors/versions';
import { ProjectId } from 'uf/projects';
import { makeGetProjectBuiltFormsLibraryId } from 'uf/projects/selectors';
import {
  makeGetProjectBuiltFormsLayerId,
  makeGetProjectBuiltFormsQuery,
} from 'uf/projects/selectors/layers';
import { RawRowData } from 'uf/search/state';
import { getStatsParams } from 'uf/symbology/stats';

export function ensureBuiltFormStops(
  projectId: ProjectId,
  layerId: LayerId,
): ThunkAction<any, any, any, any> {
  const getProjectBuiltFormsLibraryId = makeGetProjectBuiltFormsLibraryId();
  const getProjectBuiltFormsLayerId = makeGetProjectBuiltFormsLayerId();
  const getLayerVersion = makeGetLayerVersion();

  return (dispatch: Dispatch, getState) => {
    const actions = [];

    // Use stats to know which built forms to mark `used`
    const version = getLayerVersion(getState(), { layerId });
    const statsParams = getStatsParams(BUILT_FORM_KEY, version);
    actions.push(ensureLayerStats(layerId, statsParams));

    // Use the correct source for the builtforms depending on what we have at
    // the time
    const builtFormLibraryId = getProjectBuiltFormsLibraryId(getState(), {
      projectId,
    });
    const builtFormLayerId = getProjectBuiltFormsLayerId(getState(), {
      projectId,
    });
    if (builtFormLibraryId) {
      actions.push(ensureBuiltFormsFromLibrary(builtFormLibraryId));
    } else if (builtFormLayerId) {
      // the built forms might not be baked yet
      actions.push(ensureBuiltFormLayerStops(projectId, builtFormLayerId));
    }

    return Promise.all(actions.map(action => dispatch(action)));
  };
}

/**
 * Load builtform symbology from the project's layer
 */
function ensureBuiltFormLayerStops(
  projectId: ProjectId,
  builtFormLayerId: string,
): ThunkAction<Promise<RawRowData[]>, any, any, any> {
  const getProjectBuiltFormsQuery = makeGetProjectBuiltFormsQuery();

  return (dispatch: Dispatch, getState) => {
    const builtFormQuery = getProjectBuiltFormsQuery(getState(), {
      projectId,
    });
    return dispatch(ensureLayerData(builtFormLayerId, builtFormQuery));
  };
}

/**
 * Load up all data needed to show builtforms symbology from a library
 * @param builtFormLibraryId
 */
function ensureBuiltFormsFromLibrary(
  builtFormLibraryId: string,
): ThunkAction<Promise<[BuiltFormInfo[], BuiltFormInfo[]]>, any, any, any> {
  return (dispatch: Dispatch) =>
    Promise.all([
      dispatch(
        ensureLibraryBuiltForms(
          builtFormLibraryId,
          BUILT_FORM_TYPES.BUILDING_TYPE,
        ),
      ),
      dispatch(
        ensureLibraryBuiltForms(
          builtFormLibraryId,
          BUILT_FORM_TYPES.PLACE_TYPE,
        ),
      ),
    ]);
}
