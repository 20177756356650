import { Action } from 'redux';

import { AnalysisModuleParameters } from 'uf-api';
import { ModuleKey } from 'uf/analysis';
import { makeAsyncActionTypes } from 'uf/data/ActionTypes';
import { ProjectId } from 'uf/projects';
import { ScenarioId } from 'uf/scenarios';

const actionPrefix = 'uf/analysiseditor';

export const SET_ACTIVE_ANALYSIS_EDITOR_MODULE_KEY = `${actionPrefix}/SET_ACTIVE_MODULE_KEY`;
export const ROUTE_ANALYSIS_EDITOR = 'ANALYSIS_EDITOR';
export const ROUTE_ANALYSIS_EDITOR_WITH_KEY = 'ANALYSIS_EDITOR_WITH_KEY';

export const UPDATE_SCENARIO_PARAMS =
  'uf/analysiseditor/UPDATE_SCENARIO_PARAMS';
export interface UpdateScenarioParamAction extends Action {
  type: typeof UPDATE_SCENARIO_PARAMS;
  scenarioId: ScenarioId;
  moduleKey: ModuleKey;
  parameters: AnalysisModuleParameters;
}
export const CLEAR_SCENARIO_PARAMS = 'uf/analysiseditor/CLEAR_SCENARIO_PARAMS';

export interface ClearScenarioParamAction extends Action {
  type: typeof CLEAR_SCENARIO_PARAMS;
  projectId: ProjectId;
  scenarioId: ScenarioId;
  moduleKey: ModuleKey;
}
export const SET_DIRTY_SCENARIO_PARAMS =
  'uf/analysiseditor/SET_DIRTY_SCENARIO_PARAMS';

export interface SetDirtyScenarioParamAction extends Action {
  type: typeof SET_DIRTY_SCENARIO_PARAMS;
  projectId: ProjectId;
  scenarioId: ScenarioId;
  moduleKey: ModuleKey;
  dirty: boolean;
}

// We use our own action types here instead of the swagger ones so that we don't confuse our
// reducer that handles saves with the reducer that handles previews.
const previewScenarioParamPrefix = `${actionPrefix}/previewparams`;
export const previewScenarioParamActionTypes = makeAsyncActionTypes(
  previewScenarioParamPrefix,
);
