import { mdiPlay } from '@mdi/js';
import { boundMethod } from 'autobind-decorator';
import classNames from 'classnames';
import React, { Component } from 'react';
import { t } from 'ttag';

import makeDataAttributes from 'uf/ui/base/makeDataAttributes';
import NestedIconButton from 'uf/ui/base/NestedIconButton/NestedIconButton';
import WithTooltip from 'uf/ui/base/WithTooltip/WithTooltip';

interface AnalysisRunIconProps {
  // from CanPerform
  invalidSubscription?: boolean;

  className?: string;
  moduleKey: string;
  isBaseScenario: boolean;
  analysisUpToDate: boolean;
  onRun: (modueKey: string) => void;
}
// use class to store ref
export default class AnalysisRunIcon extends Component<AnalysisRunIconProps> {
  @boundMethod
  onClick(e) {
    const { moduleKey } = this.props;
    this.props.onRun(moduleKey);
  }

  // returns if the button should be disabled due to the subscription.
  getDisabled() {
    const { invalidSubscription, isBaseScenario } = this.props;
    return invalidSubscription && !isBaseScenario;
  }

  render() {
    const { className, analysisUpToDate, moduleKey } = this.props;
    let tooltipText = t`Run analysis for this scenario.`;

    if (analysisUpToDate) {
      tooltipText = t`Analysis is up to date.`;
    }

    if (this.getDisabled()) {
      tooltipText = t`Upgrade your subscription to run analysis on your scenarios.`;
    }

    const analysisRunIconClassName = classNames('AnalysisRunIcon', className);

    return (
      <WithTooltip text={tooltipText}>
        <NestedIconButton
          iconPath={mdiPlay}
          disabled={this.getDisabled() || analysisUpToDate}
          className={analysisRunIconClassName}
          onClick={this.onClick}
          {...makeDataAttributes({ moduleKey })}
        />
      </WithTooltip>
    );
  }
}
