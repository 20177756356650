import {
  CuratedColumnSymbology,
  CUSTOM_THEME,
  DEFAULT_OPACITY,
  InterpolationTypes,
  PaintColorTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

import MPORegionList from './mpo_regions';

const symbologies: Record<
  string,
  Record<string, CuratedColumnSymbology[]>
> = {};

MPORegionList.forEach(regionList => {
  symbologies[`/public/dataset/california_base_conservation_${regionList}`] = {
    raster_val: [
      {
        type: SymbologyTypes.FILL,
        paint: {
          'fill-color': {
            property: 'raster_val',
            type: PaintColorTypes.CATEGORICAL,
            stops: [
              { value: 1, label: 'Conifer Forest', color: '#51b382' },
              { value: 2, label: 'Hardwood Forest', color: '#367857' },
              { value: 3, label: 'Grassland', color: '#D8D86E' },
              { value: 4, label: 'Shrub', color: '#9ec85a' },
              { value: 5, label: 'Barren/Other', color: '#d9bf89' },
              { value: 6, label: 'Wetland', color: '#517282' },
              { value: 8, label: 'Conifer Woodland', color: '#938047' },
              { value: 9, label: 'Water', color: '#90cce7' },
              { value: 11, label: 'Hardwood Woodland', color: '#6b5d33' },
              { value: 12, label: 'Desert Shrub', color: '#d9cf89' },
              { value: 13, label: 'Desert Woodland', color: '#b9a159' },
              { value: 721, label: 'Developed, Open Space', color: '#f8bea5' },
              { value: 722, label: 'Developed, Low Density', color: '#f89f79' },
              {
                value: 723,
                label: 'Developed, Medium Density',
                color: '#f8713c',
              },
              {
                value: 724,
                label: 'Developed, High Density',
                color: '#f84200',
              },
              { value: 1023, label: 'Cropland (CRP)', color: '#dddb62' },
              {
                value: 1034,
                label: 'Irrigated Hayfield (IRH)',
                color: '#c1bf55',
              },
              {
                value: 1037,
                label: 'Dryland Grain Crops (DGR)',
                color: '#a29f47',
              },
              {
                value: 1039,
                label: 'Irrigated Row and Field Crops (IRF)',
                color: '#828039',
              },
              {
                value: 1040,
                label: 'Irrigated Grain Crops (IGR)',
                color: '#6a682e',
              },
              {
                value: 1042,
                label: 'Deciduous Orchard (DOR)',
                color: '#47772c',
              },
              { value: 1043, label: 'Vineyard (VIN)', color: '#9d279d' },
              {
                value: 1049,
                label: 'Evergreen Orchard (EOR)',
                color: '#579135',
              },
              { value: 1052, label: 'Rice (RIC)', color: '#f0f0f0' },
              {
                value: 1063,
                label: 'Orchard Vineyard (OVN)',
                color: '#89a053',
              },
            ],
          },
          'fill-opacity': DEFAULT_OPACITY,
        },
        display_hints: {
          scale: SymbologyScales.CATEGORICAL,
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          theme: CUSTOM_THEME,
        },
      },
    ],
  };
});

export default symbologies;
