import { Feature, Geometry } from 'geojson';
import { createSelector } from 'reselect';

import { EMPTY_ARRAY, EMPTY_OBJECT } from 'uf/base';
import { cacheableConcat } from 'uf/base/array';
import { makeGetFromPropsSelector } from 'uf/base/selector';
import { makeGetExploreByProjectState } from 'uf/explore/selectors/explore';
import { DrawingMode } from 'uf/explore/state';
import { LayerId } from 'uf/layers';
import { CoordPathsByFeature } from 'uf/map/state';
import { makeGetLayerEditFeatures } from 'uf/mapedit/selectors/features';

function makeGetDrawingModeState() {
  return createSelector(
    makeGetExploreByProjectState(),
    makeGetFromPropsSelector<LayerId, 'layerId'>('layerId'),
    (exploreState, layerId): DrawingMode =>
      exploreState?.drawingMode?.[layerId] ?? (EMPTY_OBJECT as DrawingMode),
  );
}

export function makeGetLayerEditsSelectedFeatureIds() {
  return createSelector(
    makeGetDrawingModeState(),
    (drawingModeState): string[] =>
      drawingModeState.selectedFeatureIds || EMPTY_ARRAY,
  );
}
export function makeGetLayerEditsSelectedVertices() {
  return createSelector(
    makeGetDrawingModeState(),
    (drawingModeState): CoordPathsByFeature =>
      drawingModeState.selectedCoordPaths || EMPTY_OBJECT,
  );
}

export function makeGetLayerEditsSelectedVerticesForFeature() {
  return createSelector(
    makeGetLayerEditsSelectedVertices(),
    makeGetFromPropsSelector<string, 'featureId'>('featureId'),
    (verticesByFeature, featureId) => {
      return cacheableConcat(verticesByFeature[featureId]);
    },
  );
}

export function makeGetLayerEditsSelectedFeatures<G extends Geometry>() {
  return createSelector(
    makeGetLayerEditFeatures<G>(),
    makeGetLayerEditsSelectedFeatureIds(),
    (features, featureIds): Feature<G>[] =>
      cacheableConcat(
        features.filter(feature => featureIds.includes(feature.id as string)),
      ),
  );
}
