import { combineReducers } from 'redux';

import { MemberCount, Subscription } from 'uf-api';
import {
  getFilteredMemberCountInOrganizationActionTypes,
  getInvitationsForOrganizationActionTypes,
  getMembersInfoInOrganizationActionTypes,
  getOrgFilesActionTypes,
  getOrgLogoInfosActionTypes,
  getProjectsInOrganizationActionTypes,
  getSubscriptionActionTypes,
} from 'uf-api/api/organization.service';
import { makeKeyedPromiseReducer } from 'uf/data/makeKeyedPromiseReducer';
import {
  InvitationListResponse,
  MemberListResponse,
  OrganizationFileListResponse,
  OrganizationLogoListResponse,
  OrganizationState,
} from 'uf/organizations/state';
import { ProjectListResponse } from 'uf/projects/state';

// The list of projects in the organization
const projectListReducer = makeKeyedPromiseReducer<ProjectListResponse>(
  getProjectsInOrganizationActionTypes,
);
const subscriptionReducer = makeKeyedPromiseReducer<Subscription>(
  getSubscriptionActionTypes,
);
const membersReducer = makeKeyedPromiseReducer<MemberListResponse>(
  getMembersInfoInOrganizationActionTypes,
);
const invitationsReducer = makeKeyedPromiseReducer<InvitationListResponse>(
  getInvitationsForOrganizationActionTypes,
);

const logosReducer = makeKeyedPromiseReducer<OrganizationLogoListResponse>(
  getOrgLogoInfosActionTypes,
);

const fileReducer = makeKeyedPromiseReducer<OrganizationFileListResponse>(
  getOrgFilesActionTypes,
);

const memberCountReducer = makeKeyedPromiseReducer<MemberCount>(
  getFilteredMemberCountInOrganizationActionTypes,
);

export default combineReducers<OrganizationState>({
  projectList: projectListReducer,
  subscription: subscriptionReducer,
  members: membersReducer,
  invitations: invitationsReducer,
  logos: logosReducer,
  files: fileReducer,
  memberCount: memberCountReducer,
});
