import { Epic, ofType } from 'redux-observable';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { t } from 'ttag';

import { LayerReference } from 'uf-api';
import { addAppMessage } from 'uf/appservices/actions';
import { SuccessAction } from 'uf/data/ActionTypes';
import { setActiveLayer } from 'uf/explore/actions/layers';
import { makeGetActiveViewId } from 'uf/explore/selectors/views';
import { createDynamicLayerActionTypes } from 'uf/projects/ActionTypes';
import { makeGetProjectLayerIdMap } from 'uf/projects/selectors/virtualLayers';
import { copyUserSymbology } from 'uf/symbology/actions/user';

export const copyUserSymbologyOnDynamicLayerCreation: Epic<
  SuccessAction<LayerReference>,
  any
> = (action$, state$) => {
  const getActiveViewId = makeGetActiveViewId();
  return action$.pipe(
    ofType(createDynamicLayerActionTypes.SUCCESS),
    map(action => {
      const {
        // Use projectId from action because the user may have switched projects
        // before the success action dispatched
        projectId,

        // Use the parentLayerId from the action because we don't have metadata
        // for the dynamic layer yet on which to lookup the parentLayerId
        parentLayerId,
        result: { full_path: dynamicLayerId },
      } = action;

      // TODO: get this from the action.  We need to stick viewId in the extra of the async action
      // so that we can pick it up in epics.
      const viewId = getActiveViewId(state$.value, { projectId });
      return copyUserSymbology(
        projectId,
        viewId,
        parentLayerId,
        dynamicLayerId,
      );
    }),
  );
};

export const showToastForFilteredLayerCreation: Epic<
  SuccessAction<LayerReference>,
  any
> = (action$, state$) => {
  const getProjectLayerIdMap = makeGetProjectLayerIdMap();
  return action$.pipe(
    ofType(createDynamicLayerActionTypes.SUCCESS),
    switchMap(action =>
      state$.pipe(
        map(state => {
          const { projectId, result } = action;
          const { full_path: layerId } = result;
          const layerMap = getProjectLayerIdMap(state, { projectId });
          return layerMap[layerId];
        }),
        filter(virtualLayerId => !!virtualLayerId),
        take(1),
        map(virtualLayerId => {
          const { projectId, params } = action;
          const layerName = params.name;
          const onClickAction = setActiveLayer(projectId, virtualLayerId);
          return addAppMessage(
            t`Successfully created filtered layer ${layerName}`,
            {
              level: 'success',
              status: 'success',
              action: {
                text: t`VIEW`,
                onClick: () => onClickAction,
              },
              timeout: 0,
            },
          );
        }),
      ),
    ),
  );
};
