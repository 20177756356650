import * as React from 'react';
import { connect } from 'react-redux';
import wrapDisplayName from 'recompose/wrapDisplayName';

import { Omit } from 'uf/base/types';
import { makeGetUserFlag } from 'uf/user/selectors/flags';

export default function withFeatureFlag<
  K extends string = 'featureFlag',
  P extends Record<K, boolean> = Record<K, boolean>,
>(featureFlagName: string, propKey: K = 'featureFlag' as K) {
  type StateProps = Record<K, boolean>;
  function makeMapStateToProps() {
    const getUserFlag = makeGetUserFlag(featureFlagName);
    return (state): StateProps =>
      ({
        [propKey]: getUserFlag(state),
      } as P);
  }
  type OwnProps = Omit<P, keyof StateProps>;

  return (WrappedComponent: React.ComponentType<P>) => {
    function WithFeatureFlagHOC(props) {
      return <WrappedComponent {...props} />;
    }

    const wrapped = connect<StateProps, never, OwnProps>(makeMapStateToProps)(
      WithFeatureFlagHOC,
    );
    wrapped.displayName = wrapDisplayName(
      WrappedComponent,
      'WithFeatureFlagHOC',
    );

    return wrapped;
  };
}
