import React, { FunctionComponent, useState } from 'react';
import tw from 'uf/tailwindcss-classnames';
import textStyles from 'uf/styles/text.module.css';
import { t } from 'ttag';

interface ReadMoreProps {
  text: string;
  charLimit?: number;
}

const DEFAULT_CHAR_LIMIT = 80;
const CHAR_LENGTH_BUFFER = 5; // buffer for minimum number of characters to have hidden in ReadMore

const ReadMore: FunctionComponent<ReadMoreProps> = ({
  text,
  charLimit = DEFAULT_CHAR_LIMIT,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const shouldShowButton = text.length - CHAR_LENGTH_BUFFER > charLimit;
  const buttonText = isExpanded ? 'less' : 'more';

  const displayText =
    !isExpanded && shouldShowButton
      ? `${text.substring(0, charLimit)}...`
      : text;

  const handleClick = () => {
    setIsExpanded(prevReadMore => !prevReadMore);
  };

  return (
    <>
      <span className={tw('tw-mr-2')}>{displayText}</span>
      {shouldShowButton && (
        <button className={textStyles.mutedText} onClick={handleClick}>
          {t`Read ${buttonText}`}
        </button>
      )}
    </>
  );
};

export default ReadMore;
