import { User } from 'uf-api';
import { WebsocketMessage } from 'uf-ws/WebsocketActions';
import { ProjectId } from 'uf/projects';

export interface CollaborationMessage<T> extends WebsocketMessage {
  type: CollaborationMessageTypes;

  info?: T;
}

export enum CollaborationMessageTypes {
  PRESENT = 'PRESENT',
  LEAVING = 'LEAVING',
}

export interface PresenceInfo {
  user: User;
  projectId: ProjectId;
}
