import { boundMethod } from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import uniqid from 'uniqid';

import { getActiveProjectId } from 'uf/app/selectors';
import { LngLat } from 'uf/base/map';
import { DataState, isLoading } from 'uf/data/dataState';
import * as bookmarkActionCreators from 'uf/explore/actions/bookmarks';
import { makeBookmarkKeyPrefix } from 'uf/explore/bookmarks';
import {
  makeGetProjectPosition,
  makeGetUserBookmarks,
  makeGetUserBookmarksState,
} from 'uf/explore/selectors/bookmarks';
import { MapPosition } from 'uf/map';
import { makeGetMapExportBookmarkKey } from 'uf/mapexport/selectors';
import { ProjectId } from 'uf/projects';
import MapExtentsPicker from 'uf/ui/map/MapExtentsPicker/MapExtentsPicker';
import { MapPositionDialog } from 'uf/ui/map/MapPositionDialog/MapPositionDialog';
import { getUserKey } from 'uf/user/selectors/user';

interface StateProps {
  projectId: ProjectId;
  bookmarks: MapPosition[];
  bookmarkKey: string;
  bookmarksState: DataState<any>;
  userKey: string;
  projectPosition: MapPosition;
}

interface DispatchProps {
  ensureBookmarks: (projectId: ProjectId, userKey: string) => void;
  saveBookmark: (
    projectId: ProjectId,
    position: MapPosition,
    userKey: string,
  ) => void;
  deleteBookmark: (
    projectId: ProjectId,
    position: MapPosition,
    userKey: string,
  ) => void;
}

interface OwnProps {
  center?: LngLat;

  scale?: number;
  pitch: number;
  bearing: number;
  onSetPosition?: (position: MapPosition) => void;
  small?: boolean;
  minimal?: boolean;

  className?: string;
}

interface State {
  showSaveDialog: boolean;

  // TODO: move this to redux
  currentPositionKey?: string;
}

type Props = StateProps & DispatchProps & OwnProps;

export class ExploreBookmarks extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showSaveDialog: false,
      currentPositionKey: props.bookmarkKey || props?.projectPosition?.key,
    };
  }

  componentDidMount() {
    const { ensureBookmarks, projectId, userKey } = this.props;
    if (projectId) {
      ensureBookmarks(projectId, userKey);
    }
  }

  componentDidUpdate(prevProps) {
    const { ensureBookmarks, projectId, userKey } = this.props;
    if (prevProps.projectId !== projectId) {
      this.setState({ currentPositionKey: projectId });
      if (projectId) {
        ensureBookmarks(projectId, userKey);
      }
    }
  }

  @boundMethod
  onSavePosition(position: MapPosition) {
    const { projectId, saveBookmark, userKey, bookmarks } = this.props;
    // hack
    let { key } = position;

    // TODO: this should somehow be part of the saveBookmark action
    const existingBookmark = bookmarks.find(pos => pos.key === key);
    if (existingBookmark) {
      key = `${key}-${uniqid()}`;
    }

    const newPosition = {
      ...position,
      key,
    };

    saveBookmark(projectId, newPosition, userKey);
    this.setState({
      showSaveDialog: false,
      currentPositionKey: key,
    });
  }

  @boundMethod
  onDeletePosition(position: MapPosition) {
    const { deleteBookmark, projectId, userKey } = this.props;
    deleteBookmark(projectId, position, userKey);
  }

  @boundMethod
  onBeginSave(position: MapPosition) {
    this.setState({
      showSaveDialog: true,
    });
  }
  @boundMethod
  onSelectPosition(position: MapPosition) {
    this.setState({ currentPositionKey: position.key });
    const { onSetPosition } = this.props;
    if (onSetPosition) {
      onSetPosition(position);
    }
  }

  @boundMethod
  onDialogChangePosition(position: MapPosition) {
    const { onSetPosition } = this.props;
    if (onSetPosition) {
      onSetPosition(position);
    }
  }

  @boundMethod
  onCancelSave() {
    this.setState({
      showSaveDialog: false,
    });
  }

  render() {
    const {
      bookmarks,
      bookmarkKey,
      bookmarksState,
      center,
      scale,
      pitch,
      bearing,
      projectPosition,
      small,
      minimal,
      className,
    } = this.props;
    const { showSaveDialog, currentPositionKey } = this.state;

    return (
      <React.Fragment>
        <MapExtentsPicker
          className={className}
          small={small}
          minimal={minimal}
          onSavePosition={this.onBeginSave}
          onDeletePosition={this.onDeletePosition}
          onSelectPosition={this.onSelectPosition}
          currentPositionKey={bookmarkKey || currentPositionKey}
          lnglat={center}
          scale={scale}
          bearing={bearing}
          pitch={pitch}
          loading={isLoading(bookmarksState)}
          positions={bookmarks}
          projectPosition={projectPosition}
        />
        <MapPositionDialog
          show={showSaveDialog}
          lnglat={center}
          scale={scale}
          pitch={pitch}
          bearing={bearing}
          onChange={this.onDialogChangePosition}
          onCancel={this.onCancelSave}
          onOk={this.onSavePosition}
        />
      </React.Fragment>
    );
  }
}

export default connect<StateProps, DispatchProps, OwnProps>(
  () => {
    const getUserBookmarks = makeGetUserBookmarks();
    const getProjectPosition = makeGetProjectPosition();
    const getUserBookmarksState = makeGetUserBookmarksState();
    const getMapExportBookmarkKey = makeGetMapExportBookmarkKey();
    return (state): StateProps => {
      const projectId = getActiveProjectId(state);
      const userKey = getUserKey(state);
      const prefix = makeBookmarkKeyPrefix(projectId);
      return {
        userKey,
        projectId,
        bookmarks: getUserBookmarks(state, { prefix, userKey }),
        bookmarkKey: getMapExportBookmarkKey(state, { projectId }),
        bookmarksState: getUserBookmarksState(state, { prefix, userKey }),
        projectPosition: getProjectPosition(state, { projectId }),
      };
    };
  },
  (dispatch: Dispatch): DispatchProps => ({
    ensureBookmarks: bindActionCreators(
      bookmarkActionCreators.ensureBookmarks,
      dispatch,
    ),
    saveBookmark: bindActionCreators(
      bookmarkActionCreators.saveBookmark,
      dispatch,
    ),
    deleteBookmark: bindActionCreators(
      bookmarkActionCreators.deleteBookmark,
      dispatch,
    ),
  }),
)(ExploreBookmarks);
