import { createSelector } from 'reselect';

import { UserData } from 'uf-api/model/models';
import { EMPTY_ARRAY } from 'uf/base';
import { makeGetFromPropsSelector } from 'uf/base/selector';
import {
  DataState,
  EmptyKeyedState,
  getData,
  isLoaded,
} from 'uf/data/dataState';
import { ProjectId } from 'uf/projects';
import { makeUserDataKey, VISIBLE_LAYER_IDS_PREFKEY } from 'uf/user';
import { makeProjectUserPrefKey } from 'uf/user/helpers';

import { getUserDataState, getUserKey } from './user';

export const getPersistedVisibleLayerVirtualIdsState = createSelector(
  getUserDataState,
  getUserKey,
  makeGetFromPropsSelector<ProjectId, 'projectId'>('projectId'),
  (userDataState, userKey, projectId: ProjectId): DataState<UserData> => {
    const visibleLayerIdsKey = makeProjectUserPrefKey(
      projectId,
      VISIBLE_LAYER_IDS_PREFKEY,
    );
    return (
      userDataState[makeUserDataKey(userKey, visibleLayerIdsKey)] ||
      EmptyKeyedState
    );
  },
);

export const getPersistedVisibleLayerVirtualIds = createSelector(
  getPersistedVisibleLayerVirtualIdsState,
  (visibleIdsState): string[] => {
    if (isLoaded(visibleIdsState)) {
      const data = getData<UserData>(visibleIdsState);
      if (data?.value) {
        return data.value.value;
      }
    }
    return EMPTY_ARRAY;
  },
);
