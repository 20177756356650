import assert from 'assert';
import shallowEqual from 'recompose/shallowEqual';
import { AnyAction } from 'redux';
import {
  ActionsObservable,
  Epic,
  ofType,
  StateObservable,
} from 'redux-observable';
import { EMPTY, of as observableOf } from 'rxjs';
import { filter, first, map, mergeMap, switchMap } from 'rxjs/operators';

import { LayerColumnBreaks, LayerMetadata, LayerStats } from 'uf-api';
import { getLayerBreaksActionTypes } from 'uf-api/api/layer.service';
import { getActiveProjectId } from 'uf/app/selectors';
import { EMPTY_ARRAY } from 'uf/base';
import { combineEpics, makeMatchActions } from 'uf/base/epics';
import { isLandUseColumnKey } from 'uf/builtforms';
import { getData, isLoading } from 'uf/data/dataState';
import { makeReduxDataKey } from 'uf/data/helpers';
import { makeGetActiveViewId } from 'uf/explore/selectors/views';
import { ColumnKey, LayerId } from 'uf/layers';
import { loadLayerBreaks } from 'uf/layers/actions/breaks';
import { getUfGeometryType, isLineLayer } from 'uf/layers/helpers';
import { makeGetLayerMetadata } from 'uf/layers/selectors/metadata';
import { makeGetLayerStatsByKey } from 'uf/layers/selectors/stats';
import { makeGetLayerVersion } from 'uf/layers/selectors/versions';
import { makeGetProjectLayerIdMap } from 'uf/projects/selectors/virtualLayers';
import { LegacyVirtualLayerId } from 'uf/projects/virtualLayers';
import { UFState } from 'uf/state';
import {
  DistributionQueryStrings,
  getScaleType,
  isCircleSymbology,
  isLineSymbology,
  LayerColumnSymbology,
  SymbologyTypes,
} from 'uf/symbology';
import {
  clearOverrideSymbology,
  setOverrideSymbology,
} from 'uf/symbology/actions/override';
import { saveUserSymbology } from 'uf/symbology/actions/user';
import {
  saveUserSymbologyActionTypes,
  SaveUserSymbologySuccessAction,
  SET_DEFAULT_SYMBOLOGY,
  SET_MAP_EXTRUSION,
  SET_SHOW_ZERO,
  UPDATE_CUSTOM_STOPS,
  UPDATE_OPACITY,
  UPDATE_POINT_SIZE,
  UPDATE_RAMP_THEME,
  UPDATE_STOP_COLOR,
  UPDATE_STOPS,
  UPDATE_STROKE_COLOR,
  UPDATE_STROKE_WIDTH,
  UPDATE_THEME_REVERSED,
  UpdateStopsAction,
} from 'uf/symbology/ActionTypes';
import { makeGetSymbologyState } from 'uf/symbology/selectors';
import { makeGetDivideByColumn } from 'uf/symbology/selectors/divideByColumn';
import { getStatsParams } from 'uf/symbology/stats';
import { updateStopColor, updateStrokeColor } from 'uf/symbology/updates/color';
import { updateCustomStops } from 'uf/symbology/updates/customStops';
import updateDistributionAndStops from 'uf/symbology/updates/distributionAndStops';
import updateExtrusion from 'uf/symbology/updates/extrusion';
import updateOpacity from 'uf/symbology/updates/opacity';
import { updatePointSize } from 'uf/symbology/updates/pointSize';
import updateShowZero from 'uf/symbology/updates/showZero';
import updateStrokeWidth from 'uf/symbology/updates/strokeWidth';
import { updateTheme, updateThemeReversed } from 'uf/symbology/updates/theme';

interface Action extends AnyAction {
  layerId: LayerId;
  columnKey: ColumnKey;
  autosave?: boolean;
}

type UpdateSymbologyFn = (
  action: Action,
  symbologies: LayerColumnSymbology[],
  layerMetadata: LayerMetadata,
  layerStats: LayerStats,
) => LayerColumnSymbology[];

export const updateOpacityEpic = makeOverrideSymbologyEpic(
  UPDATE_OPACITY,
  ({ opacity }, symbologies, layerMetadata) =>
    symbologies.map(symbology => updateOpacity(symbology, { opacity })),
);

export const updateRampThemeEpic = makeOverrideSymbologyEpic(
  UPDATE_RAMP_THEME,
  ({ columnKey, theme }, symbologies, layerMetadata) =>
    symbologies.map(symbology => {
      if (
        // We don't allow ramp editing for any land use keys
        isLandUseColumnKey(layerMetadata, columnKey) ||
        // nor do we allow ramp editing on the stroke of a polygon/point
        (isLineSymbology(symbology) && !isLineLayer(layerMetadata))
      ) {
        return symbology;
      }

      return updateTheme(symbology, {
        theme,
        scaleType: getScaleType(symbology),
      });
    }),
);

export const updateThemeReversedEpic = makeOverrideSymbologyEpic(
  UPDATE_THEME_REVERSED,
  ({ themeReversed }, symbologies) =>
    symbologies.map(symbology => updateThemeReversed(symbology, themeReversed)),
);

function makeEmptyOutlineSymbology(layerMetadata: LayerMetadata) {
  const geometryType = getUfGeometryType(layerMetadata);
  const type = geometryType.endsWith('Point')
    ? SymbologyTypes.CIRCLE
    : SymbologyTypes.LINE;
  return {
    id: null,
    display_hints: {} as any,
    paint: {},
    type,
  } as LayerColumnSymbology;
}

export const updateStrokeWidthEpic = makeEditSymbologyEpic(
  UPDATE_STROKE_WIDTH,
  ({ strokeWidth }, symbology) => {
    return updateStrokeWidth(symbology, { lineWidth: strokeWidth });
  },
  symbology => isLineSymbology(symbology) || isCircleSymbology(symbology),
  makeEmptyOutlineSymbology,
);

export const updateStrokeColorEpic = makeEditSymbologyEpic(
  UPDATE_STROKE_COLOR,
  ({ strokeColor }, symbology) => {
    return updateStrokeColor(symbology, { color: strokeColor });
  },
  symbology => isLineSymbology(symbology) || isCircleSymbology(symbology),
  makeEmptyOutlineSymbology,
);

export function setDefaultSymbologyEpic(
  action$: ActionsObservable<AnyAction>,
  state$: StateObservable<UFState>,
) {
  const getDivideByColumn = makeGetDivideByColumn();
  return action$.pipe(
    ofType(SET_DEFAULT_SYMBOLOGY),
    map(({ viewId, layerId, virtualLayerId, columnKey, symbology }) => {
      const projectId = getActiveProjectId(state$.value);
      const divideByColumn = getDivideByColumn(state$.value, {
        layerId,
        columnKey,
        projectId,
      });

      return setOverrideSymbology(
        projectId,
        viewId,
        layerId,
        virtualLayerId,
        columnKey,
        symbology,
        divideByColumn,
      );
    }),
  );
}

export const setShowZeroEpic = makeOverrideSymbologyEpic(
  SET_SHOW_ZERO,
  ({ showZero }, symbologies) =>
    symbologies.map(symbology => updateShowZero(symbology, { showZero })),
);

export const setExtrusionEpic = makeOverrideSymbologyEpic(
  SET_MAP_EXTRUSION,
  ({ extrusion }, symbologies) => {
    const newSymbologies = symbologies.map(symbology =>
      updateExtrusion(symbology, { extrusion }),
    );
    if (shallowEqual(newSymbologies, symbologies)) {
      return symbologies;
    }
    return newSymbologies;
  },
);

export const updateCustomStopsEpic = makeOverrideSymbologyEpic(
  UPDATE_CUSTOM_STOPS,
  ({ columnKey, bins }, symbologies, layerMetadata, layerStats) => {
    const geometryType = getUfGeometryType(layerMetadata);
    const columnStats = layerStats.column_stats.find(
      ({ column_key: statsColumnKey }) => statsColumnKey === columnKey,
    );
    const {
      numeric: { min, max },
    } = columnStats;
    return updateCustomStops(symbologies, geometryType, bins, min, max);
  },
);

export const updateStopColorEpic = makeOverrideSymbologyEpic(
  UPDATE_STOP_COLOR,
  ({ columnKey, color, value }, symbologies, layerMetadata) => {
    const geometryType = getUfGeometryType(layerMetadata);
    return updateStopColor(symbologies, columnKey, geometryType, value, color);
  },
);

export const updatePointSizeEpic = makeEditSymbologyEpic(
  UPDATE_POINT_SIZE,
  ({ size }, symbology) => {
    return updatePointSize(symbology, size);
  },
  isCircleSymbology,
);

const loadUpdatedStops: Epic<any, any> = (
  action$: ActionsObservable<AnyAction>,
  state$,
) => {
  const getLayerVersion = makeGetLayerVersion();
  const getDivideByColumn = makeGetDivideByColumn();
  return action$.pipe(
    ofType(UPDATE_STOPS),
    map(action => {
      const { layerId, columnKey, numStops, distributionType } = action;

      const projectId = getActiveProjectId(state$.value);
      const divideByColum = getDivideByColumn(state$.value, {
        layerId,
        columnKey,
        projectId,
      });
      const layerVersion = getLayerVersion(state$.value, { layerId });

      // TODO: Refactor all consumers of DistributionQueryStrings over to BreakTypeEnum
      const breaksType = DistributionQueryStrings[
        distributionType
      ] as LayerColumnBreaks.BreakTypeEnum;

      return loadLayerBreaks(
        layerId,
        layerVersion,
        columnKey,
        numStops,
        breaksType,
        divideByColum,
      );
    }),
  );
};

function applyUpdatedStops(action$: ActionsObservable<AnyAction>, state$) {
  const getSymbologyState = makeGetSymbologyState();
  const getLayerMetadata = makeGetLayerMetadata();
  const getDivideByColumn = makeGetDivideByColumn();
  const getActiveViewId = makeGetActiveViewId();
  const getLayerMap = makeGetProjectLayerIdMap();

  return action$.pipe(
    ofType(UPDATE_STOPS),
    switchMap((updateNumStopsNewAction: UpdateStopsAction) =>
      action$.pipe(
        ofType(getLayerBreaksActionTypes.SUCCESS),
        filter(layerBreaksSuccessAction => {
          const matcher = makeMatchActions(
            ['layerId', 'columnKey'],
            ['layerId', 'columnKey'],
          );
          return matcher(updateNumStopsNewAction, layerBreaksSuccessAction);
        }),
        // Close the stream after this so that other calls for this symbology's
        // breaks, eg: hard-reloads, don't end up setting override symbology after
        // the first edit was performed.
        first(),
        map(layerBreaksSuccessAction => {
          const { layerId, columnKey } = layerBreaksSuccessAction;
          const { numStops, distributionType } = updateNumStopsNewAction;

          const projectId = getActiveProjectId(state$.value);
          const viewId = getActiveViewId(state$.value, { projectId });
          const divideByColumn = getDivideByColumn(state$.value, {
            layerId,
            projectId,
            columnKey,
          });
          const layerMap = getLayerMap(state$.value, { projectId });
          const virtualLayerId = layerMap[layerId];
          const symbologyState = getSymbologyState(state$.value, {
            projectId,
            viewId,
            layerId,
            virtualLayerId,
            columnKey,
          });
          const symbologies = getData(symbologyState, []);

          const breaksCount = numStops + 2;
          const breaksType = DistributionQueryStrings[
            distributionType
          ] as LayerColumnBreaks.BreakTypeEnum;

          const layerMetadata = getLayerMetadata(state$.value, { layerId });
          const ufGeometryType = getUfGeometryType(layerMetadata);
          const updatedSymbologies = updateDistributionAndStops(
            symbologies,
            {
              ...layerBreaksSuccessAction.result,
              params: {
                break_count: breaksCount,
                break_type: breaksType,
              },
            },
            ufGeometryType,
          );

          return setOverrideSymbology(
            projectId,
            viewId,
            layerId,
            virtualLayerId,
            columnKey,
            updatedSymbologies,
            divideByColumn,
          );
        }),
      ),
    ),
  );
}

/** Create an epic that updates symbology every time the given symbology type is updated */
function makeOverrideSymbologyEpic(
  actionType: string,
  updateSymbologies: UpdateSymbologyFn,
) {
  const epic: Epic<
    { type: any; layerId: LayerId; columnKey: ColumnKey; autosave?: boolean },
    any
  > = (action$, state$) => {
    const getSymbologyState = makeGetSymbologyState();
    const getLayerMetadata = makeGetLayerMetadata();
    const getLayerVersion = makeGetLayerVersion();
    const getLayerStatsByKey = makeGetLayerStatsByKey();
    const getDivideByColumn = makeGetDivideByColumn();
    const getActiveViewId = makeGetActiveViewId();
    const getLayerMap = makeGetProjectLayerIdMap();
    return action$.pipe(
      ofType(actionType),
      mergeMap(action => {
        const { layerId, columnKey, autosave } = action;
        // TODO: should add projectId and viewId all symbology actions that update symbology,
        // basically all the actions listed in uf/symbology/ActionTypes ( SET_DEFAULT_SYMBOLOGY etc}
        // so that they can be picked up in epics.  We need this to ensure that we are updating the
        // correct symbology.
        const projectId = getActiveProjectId(state$.value);
        const viewId = getActiveViewId(state$.value, { projectId });
        const layerMap = getLayerMap(state$.value, { projectId });
        const virtualLayerId: LegacyVirtualLayerId = layerMap[layerId];
        const layerMetadata = getLayerMetadata(state$.value, { layerId });
        const layerVersion = getLayerVersion(state$.value, { layerId });
        const divideByColumn = getDivideByColumn(state$.value, {
          layerId,
          columnKey,
          projectId,
        });
        const statsParams = getStatsParams(
          columnKey,
          layerVersion,
          divideByColumn,
        );
        const key = makeReduxDataKey(layerId, statsParams);
        const layerStats = getData(getLayerStatsByKey(state$.value, { key }));
        const symbologyState = getSymbologyState(state$.value, {
          projectId,
          viewId,
          layerId,
          virtualLayerId,
          columnKey,
        });
        // bail early (we should be waiting here)
        if (isLoading(symbologyState)) {
          return EMPTY;
        }

        const symbologies = getData(symbologyState, EMPTY_ARRAY);
        const updatedSymbologies = updateSymbologies(
          action,
          symbologies,
          layerMetadata,
          layerStats,
        );
        if (updatedSymbologies === symbologies) {
          // No-op if if symbologies have not changed
          return EMPTY;
        }

        if (autosave) {
          return observableOf(
            saveUserSymbology(
              projectId,
              viewId,
              layerId,
              columnKey,
              divideByColumn,
              updatedSymbologies,
            ),
          );
        }

        return observableOf(
          setOverrideSymbology(
            projectId,
            viewId,
            layerId,
            virtualLayerId,
            columnKey,
            updatedSymbologies,
            divideByColumn,
          ),
        );
      }),
    );
  };
  return epic;
}

type EditSymbologyFn = (
  action: Action,
  symbology: LayerColumnSymbology,
  layerMetadata: LayerMetadata,
  layerStats: LayerStats,
) => LayerColumnSymbology;

/**
 * makeEditSymbologyEpic is a special case of makeOverrideSymbologyEpic that includes extra safeguards
 * * explicitly selects a single symbology to edit
 * * will only succeed if a symbology is acted upon
 * * allows for a default symbology to be provided
 * @param actionType
 * @param editSymbology
 * @param selector
 * @param provideDefault
 * @returns
 */
export function makeEditSymbologyEpic(
  actionType: string,
  editSymbology: EditSymbologyFn,
  selector: (symbology: LayerColumnSymbology) => boolean,
  provideDefault?: (layerMetadata: LayerMetadata) => LayerColumnSymbology,
) {
  return makeOverrideSymbologyEpic(
    actionType,
    (action, symbologies, layerMetadata, layerStats) => {
      let hasMatch = false;
      const nextSymbologies = symbologies.map(symbology => {
        if (selector(symbology)) {
          assert(
            !hasMatch,
            `${actionType} attempted to edit more than one symbology`,
          );
          hasMatch = true;
          return editSymbology(action, symbology, layerMetadata, layerStats);
        }
        return symbology;
      });
      if (!hasMatch) {
        assert(
          provideDefault,
          `${actionType} found no matching symbologies to act on`,
        );
        const newSymbology = provideDefault(layerMetadata);
        nextSymbologies.push(
          editSymbology(action, newSymbology, layerMetadata, layerStats),
        );
      }
      return nextSymbologies;
    },
  );
}

export const updateStopsEpic = combineEpics(
  {
    loadUpdatedStops,
    applyUpdatedStops,
  },
  'updateStops',
);

const clearOverrideOnSaveEpic: Epic<
  SaveUserSymbologySuccessAction,
  any,
  UFState
> = (action$, state$) => {
  return action$.pipe(
    ofType(saveUserSymbologyActionTypes.SUCCESS),
    map(action => {
      const {
        viewId,
        layerId,
        virtualLayerId,
        columnKey,
        divideByColumn,
        projectId,
      } = action;

      return clearOverrideSymbology(
        projectId,
        viewId,
        layerId,
        virtualLayerId,
        columnKey,
        divideByColumn,
      );
    }),
  );
};

export default combineEpics(
  {
    updateCustomStopsEpic,
    updateOpacityEpic,
    updateRampThemeEpic,
    updateThemeReversedEpic,
    updateStrokeWidthEpic,
    updateStrokeColorEpic,
    updatePointSizeEpic,
    updateStopColorEpic,
    setShowZeroEpic,
    setExtrusionEpic,
    setDefaultSymbologyEpic,
    updateStopsEpic,
    clearOverrideOnSaveEpic,
  },
  'override',
);
