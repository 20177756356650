import _ from 'lodash';
import { Store } from 'redux';
import watch from 'redux-watch';

import { AppMode } from 'uf/app/modes';
import { getActiveProjectId } from 'uf/app/selectors';
import { parseFullPath } from 'uf/base/dataset';
import { BUILT_FORM_TYPES } from 'uf/builtforms';
import {
  setBuiltFormEditorRoute,
  setBuiltFormEditorWithKeyRoute,
} from 'uf/builtforms/actions';
import { setEditorCurrentBuiltForm } from 'uf/builtforms/actions/editor';
import { makeGetEditorCurrentBuiltFormForProject } from 'uf/builtforms/selectors';
import {
  setExploreNewRoute,
  setExploreRoute,
} from 'uf/explore/actions/routing';
import { getExploreUIPropertiesState } from 'uf/explore/selectors/explore';
import { setSettingsRoute } from 'uf/organizations/actions/routing';
import { makeGetProjectBuiltFormsLibraryId } from 'uf/projects/selectors';
import { setReportRoute } from 'uf/reporting/actions/routing';
import { SyncStateWithUrl } from 'uf/routing/state';
import { setProfileRoute } from 'uf/user/actions/routing';
import { makeGetUserFlag } from 'uf/user/selectors/flags';

const getLibraryId = makeGetProjectBuiltFormsLibraryId();
export const syncAppUIStateWithBrowserUrl: SyncStateWithUrl = (
  store: Store,
) => {
  const watchAppUIState = watch(() =>
    getExploreUIPropertiesState(store.getState()),
  );
  const getEditorCurrentBuiltFormForProject =
    makeGetEditorCurrentBuiltFormForProject();
  const unsubscribe = store.subscribe(
    watchAppUIState((newState, oldState) => {
      const {
        exploreViewVisible,
        paintUIVisible,
        analysisUIVisible,
        reportViewVisible,
        settingsViewVisible,
        profileViewVisible,
        manageViewVisible,
      } = newState;

      const {
        exploreViewVisible: oldExploreViewVisible,
        analysisUIVisible: oldAnalysisUIVisible,
        paintUIVisible: oldBuildUIVisible,
        reportViewVisible: oldReportViewVisible,
        settingsViewVisible: oldSettingsViewVisible,
        profileViewVisible: oldProfileViewVisible,
        manageViewVisible: oldManageViewVisible,
      } = oldState;

      let mode: AppMode;
      if (analysisUIVisible) {
        mode = AppMode.Analyze;
      }

      if (paintUIVisible) {
        mode = AppMode.Paint;
      }

      const { dispatch } = store;

      const state = store.getState();
      const activeProjectId = getActiveProjectId(state);

      // Make sure we are changing modebar route in case this
      // slice of state was changed for some other reason.
      // This might be a sign we need better redux architecture for these states.
      if (
        exploreViewVisible === oldExploreViewVisible &&
        reportViewVisible === oldReportViewVisible &&
        analysisUIVisible === oldAnalysisUIVisible &&
        paintUIVisible === oldBuildUIVisible &&
        settingsViewVisible === oldSettingsViewVisible &&
        profileViewVisible === oldProfileViewVisible &&
        manageViewVisible === oldManageViewVisible
      ) {
        return;
      }

      if (exploreViewVisible) {
        const getExploreModeProjectUrlsFlagEnabled = makeGetUserFlag(
          'explore-mode-project-urls',
        );
        if (getExploreModeProjectUrlsFlagEnabled(state)) {
          const { namespace, key } = parseFullPath(activeProjectId, 'project');
          dispatch(setExploreNewRoute(namespace, key, false, mode));
        } else {
          dispatch(setExploreRoute(false, mode));
        }
      } else if (reportViewVisible) {
        // TODO: get the key from redux so we remember reporting state!
        const reportSpecKey = 'paint_tracking';
        dispatch(setReportRoute(reportSpecKey, activeProjectId, []));
      } else if (manageViewVisible) {
        const currentBuiltForm = getEditorCurrentBuiltFormForProject(state, {
          projectId: activeProjectId,
        });
        if (!_.isEmpty(currentBuiltForm)) {
          // set current built form if it exists
          const { builtFormKey, builtFormType, libraryId } = currentBuiltForm;
          const { namespace, key } = parseFullPath(libraryId, 'builtforms');
          if (builtFormKey) {
            store.dispatch(
              setBuiltFormEditorWithKeyRoute(
                builtFormType,
                namespace,
                key,
                builtFormKey,
              ),
            );
          } else {
            store.dispatch(
              setBuiltFormEditorRoute(builtFormType, namespace, key),
            );
          }
        } else {
          // otherwise set what we know and let the component figure out the
          // builtform key from the list
          const { namespace, key } = parseFullPath(activeProjectId);
          const libraryId = getLibraryId(state, { projectId: activeProjectId });
          dispatch(
            setEditorCurrentBuiltForm(
              activeProjectId,
              libraryId,
              BUILT_FORM_TYPES.BUILDING,
            ),
          );
          dispatch(
            setBuiltFormEditorRoute(BUILT_FORM_TYPES.BUILDING, namespace, key),
          );
        }
      } else if (settingsViewVisible) {
        dispatch(setSettingsRoute(settingsViewVisible));
      } else if (profileViewVisible) {
        dispatch(setProfileRoute());
      } else if (!exploreViewVisible && !reportViewVisible) {
        dispatch(setExploreRoute(true, mode));
      }
    }),
  );
  return unsubscribe;
};
