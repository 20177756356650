import {
  CuratedColumnSymbology,
  CUSTOM_THEME,
  DEFAULT_OPACITY,
  DistributionTypes,
  InterpolationTypes,
  PaintColorTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/public/dataset/average_gasoline_price_by_state_2016': {
    retail_gasoline_price: [
      {
        type: SymbologyTypes.FILL,
        paint: {
          'fill-color': {
            property: 'retail_gasoline_price',
            type: PaintColorTypes.INTERVAL,
            stops: [
              { value: 0, label: '0.00 to 2.00', color: '#fef2c0' },
              { value: 2.0, label: '2.00 to 2.10', color: '#fdd2a0' },
              { value: 2.1, label: '2.10 to 2.20', color: '#fac38e' },
              { value: 2.2, label: '2.20 to 2.30', color: '#f8b57d' },
              { value: 2.3, label: '2.30 to 2.40', color: '#f5a76b' },
              { value: 2.4, label: '2.40 to 2.50', color: '#f29859' },
              { value: 2.5, label: '2.50 to 2.60', color: '#f08a47' },
              { value: 2.6, label: '2.60 or more', color: '#ed7c35' },
            ],
          },
          'fill-opacity': DEFAULT_OPACITY,
        },
        display_hints: {
          scale: SymbologyScales.NUMERIC,
          distribution: DistributionTypes.DEFAULT,
          interpolation: InterpolationTypes.INTERVAL,
          theme: CUSTOM_THEME,
        },
      },
    ],
  },
};

export default symbologies;
