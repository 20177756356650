import { createSelector } from 'reselect';

import { ProjectListItem } from 'uf-api/model/projectListItem';
import { getActiveProjectId } from 'uf/app/selectors';
import { makeGetExploreUIProperty } from 'uf/explore/selectors/explore';
import { ProjectId } from 'uf/projects';
import { getProjectList } from 'uf/projects/selectors';
import { makeGetUserFlag } from 'uf/user/selectors/flags';

export interface ExploreContext {
  exploreViewVisible: boolean;
  projectId: ProjectId;
  projectsList: ProjectListItem[];
  exploreModeProjectUrlsFlagEnabled: boolean;
}

export function makeGetExploreContext() {
  return createSelector(
    makeGetExploreUIProperty<boolean>('exploreViewVisible'),
    getActiveProjectId,
    getProjectList,
    makeGetUserFlag('explore-mode-project-urls'),
    (
      exploreViewVisible,
      projectId,
      projectsList,
      exploreModeProjectUrlsFlagEnabled,
    ): ExploreContext => ({
      exploreViewVisible,
      projectId,
      projectsList,
      exploreModeProjectUrlsFlagEnabled,
    }),
  );
}
