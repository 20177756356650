/**
 * UrbanFootprint App API
 * API for serving UrbanFootprint Application
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { UserActionReasonDetail } from './userActionReasonDetail';

/**
 * An Action that a user can perform on a resource.
 */
export interface UserAction<> {
  /**
   * Can it be done.
   */
  can_do?: boolean;
  /**
   * If it could not be done, why.
   */
  reason?: UserAction.ReasonEnum;
  reason_detail?: UserActionReasonDetail;
  /**
   * The thing that is done to - this should be a path to a resource.
   */
  resource: string;
  /**
   * The thing to be done.
   */
  verb: string;
}
export namespace UserAction {
  export type ReasonEnum = 'permission' | 'subscription' | 'restriction';
  export const ReasonEnum = {
    Permission: 'permission',
    Subscription: 'subscription',
    Restriction: 'restriction',
  } as const;
}
