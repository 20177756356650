import _ from 'lodash';
import { createSelector } from 'reselect';
import { LayerReference } from 'uf-api';
import {
  getActiveProject,
  getActiveProjectBaseCanvasLayerReference,
  getActiveProjectState,
  getActiveScenarioCanvasLayerReference,
  makeGetProjectBoundaryLayerReferences,
  makeGetProjectLayerReferences,
  makeGetScenarioAnalysisLayerReferences,
  makeGetScenarioLayerReferences,
} from 'uf/app/selectors';
import { EMPTY_ARRAY, EMPTY_OBJECT } from 'uf/base';
import { invert } from 'uf/base/objects';
import { makeGetFromPropsSelector } from 'uf/base/selector';
import { DataState, getData } from 'uf/data/dataState';
import {
  addExtrudedColumnToLayerInfo,
  LayerInfoWithExtrusion,
  makeLayerOrderInfos,
} from 'uf/explore/layerOrdering';
import { makeGetExploreByProjectState } from 'uf/explore/selectors/explore';
import { ExploreLayersState, ProjectStyleInfo } from 'uf/explore/state';
import { LayerId } from 'uf/layers';
import { ActiveColumnInfo } from 'uf/map/ActiveColumnInfo';
import { ProjectId } from 'uf/projects';
import {
  getProjectBoundaryLayerReferences,
  makeGetNonBaseScenarioCanvasLayerReference,
  makeGetProjectAllWorkingLayerReferences,
  makeGetProjectBaseCanvasLayerReference,
  makeGetProjectReferenceLayers,
} from 'uf/projects/selectors/layers';
import {
  makeGetProjectLayerIdMap,
  makeGetScenarioLayerIdMap,
} from 'uf/projects/selectors/virtualLayers';
import { LegacyVirtualLayerId } from 'uf/projects/virtualLayers';
import { ScenarioId } from 'uf/scenarios';
import { makeGetSymbologyStateGetter } from 'uf/symbology/selectors';
import { makeGetUserFlag } from 'uf/user/selectors/flags';
import { ViewId } from 'uf/views';
import {
  makeGetViewLayerOrder,
  makeGetViewMapColumnKeys,
  makeGetViewVisibleVirtualLayers,
} from 'uf/views/selectors/layers';

// This is a hack to make layer panel progress look right.
export const getAllLayersState = createSelector(
  getActiveProjectState,
  projectState =>
    ({
      loading: projectState.loading,
      loaded: projectState.loaded,
    } as DataState<any>),
);

/**
 * @deprecated remove when we switch to using uf/views state
 */
const getOrderState = createSelector(
  makeGetExploreLayersState(),
  ({ order }): LegacyVirtualLayerId[] => order || EMPTY_ARRAY,
);

/**
 * returns true if there is any entry for the active project id.
 * @deprecated remove when we switch to using uf/views state
 */
export const getProjectHasLayerOrder = createSelector(
  getOrderState,
  order => !!order?.length,
);

/**
 * temporary selector while we migrate to layer ordering to uf/views
 */
function makeGetProjectLayerOrder() {
  return createSelector(
    getOrderState,
    makeGetViewLayerOrder(),
    makeGetUserFlag('saved-views'),
    makeGetFromPropsSelector<ViewId, 'viewId'>('viewId'),
    (layerOrderOld, layerOrderNew, useSavedViews): LegacyVirtualLayerId[] => {
      return useSavedViews ? layerOrderNew : layerOrderOld;
    },
  );
}

// Get all layers in the active project, including layers for the active scenario.
function makeGetScenarioLayers() {
  return createSelector(
    makeGetScenarioLayerReferences(),
    makeGetProjectBaseCanvasLayerReference(),
    makeGetProjectLayerReferences(),
    makeGetFromPropsSelector<ScenarioId, 'scenarioId'>('scenarioId'),
    (scenarioLayers, baseCanvasLayer, projectLayers): LayerReference[] => [
      ...scenarioLayers,
      baseCanvasLayer,
      ...projectLayers,
    ],
  );
}

function makeGetScenarioLayersById() {
  return createSelector(makeGetScenarioLayers(), layers =>
    _.keyBy(layers, 'full_path'),
  );
}

const EmptyExploreLayersState: ExploreLayersState = {
  activeLayerVirtualId: null,
  mapStyles: EMPTY_OBJECT,
  order: EMPTY_ARRAY,
  queryStates: EMPTY_OBJECT,
  visibleLayerVirtualIds: EMPTY_ARRAY,
};

function makeGetExploreLayersState() {
  return createSelector(
    makeGetExploreByProjectState(),
    (exploreState): ExploreLayersState =>
      exploreState.layers ?? EmptyExploreLayersState,
  );
}

// TODO: Write this as a mapping over the virtual layer ids and delete this property from redux.
function makeGetVisibleLayerIdsOld() {
  return createSelector(
    makeGetExploreLayersState(),
    makeGetScenarioLayerIdMap(),
    (layerState, virtualIdsByLayerId): LayerId[] => {
      const visibleLayerVirtualIds: LegacyVirtualLayerId[] =
        layerState.visibleLayerVirtualIds || EMPTY_ARRAY;
      const layerIdsByVirtualId = invert(virtualIdsByLayerId);
      const visibleLayerIds = visibleLayerVirtualIds
        .map(
          visibleLayerVirtualId => layerIdsByVirtualId[visibleLayerVirtualId],
        )
        .filter(layerId => !!layerId);
      return visibleLayerIds;
    },
  );
}

export function makeGetVisibleVirtualLayerIds() {
  return createSelector(
    makeGetExploreLayersState(),
    makeGetViewVisibleVirtualLayers(),
    makeGetUserFlag('saved-views'),
    makeGetFromPropsSelector<ViewId, 'viewId'>('viewId'),
    (layerState, viewVirtualLayerIds, useSavedViews) => {
      if (useSavedViews) {
        return viewVirtualLayerIds;
      }
      return layerState.visibleLayerVirtualIds;
    },
  );
}

/**
 * Get the list of visibile layer ids from the current view
 */
export function makeGetVisibleLayerIds() {
  return createSelector(
    makeGetVisibleLayerIdsOld(),
    makeGetVisibleVirtualIdsByLayerIdMap(),
    makeGetViewVisibleVirtualLayers(),
    makeGetUserFlag('saved-views'),
    makeGetFromPropsSelector<string, 'viewId'>('viewId'),
    (
      visibleLayerIds,
      virtualIdsByLayerId,
      viewVirtualLayerIds,
      useSavedViews,
    ): LayerId[] => {
      if (useSavedViews) {
        const layerIdsByVirtualId = invert(virtualIdsByLayerId);
        return viewVirtualLayerIds.map(
          virtualLayerId => layerIdsByVirtualId[virtualLayerId],
        );
      }
      return visibleLayerIds.filter(layerId => !!layerId);
    },
  );
}

export const getActiveLayerId = createSelector(
  makeGetVisibleVirtualLayerIds(),
  makeGetExploreLayersState(),
  makeGetScenarioLayerIdMap(),
  // TODO: get rid of this disambiguator once we have virtual layer visibility
  makeGetFromPropsSelector<ScenarioId, 'scenarioId'>('scenarioId'),
  (visibleVirtualLayerIds, layerState, layerMap, scenarioId): LayerId => {
    const virtualToLayerIdMap = invert(layerMap);
    if (visibleVirtualLayerIds.includes(layerState.activeLayerVirtualId)) {
      // Note that this scenario might not have this particular virtual layer id
      return virtualToLayerIdMap[layerState.activeLayerVirtualId] ?? null;
    }
    return null;
  },
);

/**
 * Use this when you need to make sure that the active layer id actually
 * corresponds to a current layer. TODO: migrate most consumers of
 * getActiveLayerId to this.
 */
export function makeGetValidActiveLayerId() {
  return createSelector(
    getActiveLayerId,
    makeGetScenarioLayersById(),
    makeGetFromPropsSelector<ViewId, 'viewId'>('viewId'),

    (layerId, scenarioLayersById) => {
      /* This is because sometimes layers change*/
      const isValidLayer = layerId in scenarioLayersById;
      return isValidLayer ? layerId : null;
    },
  );
}

/**
 * Get map of layerId => columnKey that should be mapped.
 */
function makeGetProjectLayersMapStylesOld() {
  return createSelector(
    makeGetExploreLayersState(),
    (layersState): ProjectStyleInfo => {
      const { mapStyles } = layersState;
      return mapStyles ?? EMPTY_OBJECT;
    },
  );
}

export function makeGetProjectLayersMapStyles() {
  return createSelector(
    makeGetProjectLayersMapStylesOld(),
    makeGetViewMapColumnKeys(),
    makeGetUserFlag('saved-views'),
    makeGetFromPropsSelector<ViewId, 'viewId'>('viewId'),
    (exploreMapStyles, viewMapColumnKeys, useSavedViews): ProjectStyleInfo => {
      if (useSavedViews) {
        const activeColumnInfos = Object.entries<string>(viewMapColumnKeys).map(
          ([layerId, activeColumnKey]): [
            LegacyVirtualLayerId,
            ActiveColumnInfo,
          ] => [
            layerId,
            {
              activeColumnKey,
            },
          ],
        );
        return Object.fromEntries(activeColumnInfos);
      }
      return exploreMapStyles;
    },
  );
}

export function makeGetLayerActiveColumnKey() {
  return createSelector(
    makeGetProjectLayersMapStyles(),
    makeGetFromPropsSelector<LegacyVirtualLayerId, 'virtualLayerId'>(
      'virtualLayerId',
    ),
    (layerStyles, virtualLayerId): string =>
      layerStyles?.[virtualLayerId]?.activeColumnKey,
  );
}

const getLayerQueryStates = createSelector(
  makeGetExploreLayersState(),
  layerState => layerState.queryStates,
);

export function makeGetLayerSearchKey() {
  return createSelector(
    getLayerQueryStates,
    makeGetFromPropsSelector<LayerId, 'layerId'>('layerId'),
    (queryStates, layerId): string => queryStates?.[layerId]?.searchKey,
  );
}

/**
 * @deprecated in favor of makeGetProjectBoundaryLayerReferences
 */
const getActiveProjectBoundaryLayerReferences = createSelector(
  getActiveProject,
  getProjectBoundaryLayerReferences,
);

export const getActiveScenarioDefaultLayerIds = createSelector(
  getActiveProjectBaseCanvasLayerReference,
  getActiveProjectBoundaryLayerReferences,
  getActiveScenarioCanvasLayerReference,
  (baseLayer, boundaryLayers, canvasLayers) => {
    const infos = [...boundaryLayers];
    if (baseLayer) {
      infos.unshift(baseLayer);
    }
    if (canvasLayers) {
      infos.push(canvasLayers);
    }

    return infos.map(info => info.full_path);
  },
);

export function makeGetOrderedLayerInfos() {
  return createSelector(
    makeGetProjectBaseCanvasLayerReference(),
    makeGetProjectReferenceLayers(),
    makeGetProjectAllWorkingLayerReferences(),
    makeGetProjectBoundaryLayerReferences(),
    makeGetScenarioAnalysisLayerReferences(),
    makeGetNonBaseScenarioCanvasLayerReference(),
    makeGetProjectLayerOrder(),
    makeGetFromPropsSelector<ScenarioId, 'scenarioId'>('scenarioId'),
    (
      baseCanvasLayer,
      referenceLayers,
      workingLayers,
      boundaryLayers,
      analysisLayers,
      nonBaseCanvasLayer,
      existingOrder,
    ) => {
      const orderedLayerInfos = makeLayerOrderInfos(
        baseCanvasLayer,
        nonBaseCanvasLayer,
        workingLayers,
        boundaryLayers,
        analysisLayers,
        referenceLayers,
        existingOrder,
      );

      return orderedLayerInfos;
    },
  );
}

/**
 * Gets the order of the layerInfos for the active project.
 * If a scenario specific layer is not found, a placeholder layerInfo is left in the list.
 * This allows the user to switch between scenarios while maintaining ordering.
 */
export const getOrderedLayerInfosWithExtrusion = createSelector(
  makeGetOrderedLayerInfos(),
  makeGetProjectLayersMapStyles(),
  makeGetProjectLayerIdMap(),
  makeGetSymbologyStateGetter(),
  makeGetFromPropsSelector<ProjectId, 'projectId'>('projectId'),
  makeGetFromPropsSelector<ViewId, 'viewId'>('viewId'),
  (
    orderedLayerInfos,
    layerStyles,
    layerMap,
    getSymbologyDataState,
    projectId,
    viewId,
  ) => {
    return orderedLayerInfos.map((layerInfo): LayerInfoWithExtrusion => {
      const layerId = layerInfo?.layerInfo?.full_path;
      const virtualLayerId = layerMap[layerId];
      const columnKey = layerStyles?.[virtualLayerId]?.activeColumnKey;
      if (layerId && layerInfo.isPlaceholderLayer) {
        // HACK to avoid console noise because fake canvas doesn't do extrusion
        return {
          ...layerInfo,
          extrudedColumn: null,
        };
      }
      const symbologies = getData(
        getSymbologyDataState({
          projectId,
          viewId,
          layerId,
          virtualLayerId,
          columnKey,
        }),
        EMPTY_ARRAY,
      );
      // Fill symbology contains the extrusion hints
      const fillSymbology = symbologies.find(({ type }) => type === 'fill');
      return addExtrudedColumnToLayerInfo(layerInfo, fillSymbology, columnKey);
    });
  },
);

/**
 * Get the layer details by layerId from the aggregated list of project layers
 */
export const getLayerDetails = createSelector(
  makeGetOrderedLayerInfos(),
  makeGetFromPropsSelector<LayerId, 'layerId'>('layerId'),
  (orderedLayerInfos, layerId) => {
    const layer = orderedLayerInfos.find(
      (orderedLayer: LayerInfoWithExtrusion) => {
        const layerInfoId = orderedLayer?.layerInfo?.full_path;
        return layerInfoId === layerId;
      },
    );
    return layer?.layerInfo?.details;
  },
);

export const getOrderedLayerIds = createSelector(
  makeGetOrderedLayerInfos(),
  layerInfos => layerInfos.map(({ layerInfo }) => layerInfo.full_path),
);

export const getLayerOrderKeys = createSelector(
  makeGetOrderedLayerInfos(),
  layerInfos => layerInfos.map(layerInfo => layerInfo.layerOrderKey),
);

/**
 * Turns a list of OrdredLayerInfos to a map of virtual ids by layer id.
 * i.e.
 * layerOrderedInfos = [
 *   {
 *     layerInfo: { full_path: '/public/dataset/xyz' },
 *     layerOrderKey: 'base:base',
 *   }
 * ];
 *
 *  transforms to
 *
 *  virtualIdsByLayerId = {
 *    '/public/dataset/xyz': 'base:base',
 *  };
 */
export function makeGetVisibleVirtualIdsByLayerIdMap() {
  return createSelector(
    makeGetOrderedLayerInfos(),
    (layerInfos): Record<LayerId, LegacyVirtualLayerId> => {
      const layerInfosByLayerId = _.keyBy(layerInfos, 'layerInfo.full_path');
      const virtualIdsByLayerId = _.mapValues(
        layerInfosByLayerId,
        'layerOrderKey',
      );
      return virtualIdsByLayerId;
    },
  );
}

// uses the virtual ids to return the corresponding layer ids for visible
// layers in the active scenario
const getVisibleLayerIdsForScenarioOld = createSelector(
  makeGetVisibleVirtualLayerIds(),
  makeGetVisibleVirtualIdsByLayerIdMap(),
  makeGetFromPropsSelector<ScenarioId, 'scenarioId'>('scenarioId'),
  (visibleLayerVirtualIds, virtualIdsByLayerId) => {
    const layerIdsByVirtualId = invert(virtualIdsByLayerId);
    const visibleLayerIds = visibleLayerVirtualIds.map(
      (visibleLayerVirtualId): LayerId =>
        layerIdsByVirtualId[visibleLayerVirtualId],
    );
    return visibleLayerIds.filter(Boolean);
  },
);

export const getVisibleLayerIdsForScenario = createSelector(
  getVisibleLayerIdsForScenarioOld,
  makeGetViewVisibleVirtualLayers(),
  makeGetVisibleVirtualIdsByLayerIdMap(),
  makeGetUserFlag('saved-views'),
  makeGetFromPropsSelector<ScenarioId, 'scenarioId'>('scenarioId'),
  (
    exploreVisibleLayerIds,
    viewVisibleVirtualLayerIds,
    virtualIdsByLayerId,
    useSavedViews,
  ): LayerId[] => {
    const layerIdsByVirtualId = invert(virtualIdsByLayerId);

    if (useSavedViews) {
      return viewVisibleVirtualLayerIds.map(
        (visibleLayerVirtualId): LayerId =>
          layerIdsByVirtualId[visibleLayerVirtualId],
      );
    }

    return exploreVisibleLayerIds;
  },
);

function makeGetAllLayerIds() {
  return createSelector(makeGetOrderedLayerInfos(), (layers): string[] =>
    layers.map(layerInfo => layerInfo.layerInfo.full_path),
  );
}

/**
 * returns a list a LayerReference's that are marked as visible in the active
 * project and scenario.
 */
export function makeGetVisibleLayerReferences() {
  return createSelector(
    makeGetProjectLayerReferences(),
    makeGetScenarioLayerReferences(),
    makeGetProjectBaseCanvasLayerReference(),
    makeGetAllLayerIds(),
    getVisibleLayerIdsForScenario,
    makeGetFromPropsSelector<ScenarioId, 'scenarioId'>('scenarioId'),
    (
      projectLayers,
      scenarioLayers,
      baseCanvasLayer,
      allLayerIds,
      visibleLayerIds,
    ): LayerReference[] => {
      return extractVisibleLayerReferences(
        projectLayers,
        scenarioLayers,
        baseCanvasLayer,
        allLayerIds,
        visibleLayerIds,
      );
    },
  );
}

function extractVisibleLayerReferences(
  projectLayers: LayerReference[],
  scenarioLayers: LayerReference[],
  baseCanvasLayer: LayerReference,
  allLayerIds: string[],
  visibleLayerIds: string[],
): LayerReference[] {
  const layerReferences = [
    ...projectLayers,
    ...scenarioLayers,
    baseCanvasLayer,
  ];
  const layersById = _.keyBy<LayerReference>(layerReferences, 'full_path');

  // filter out non visible, non active scenario layers
  const orderedVisibleLayerIds = allLayerIds.filter(layerId => {
    const visible = visibleLayerIds.includes(layerId);
    if (visible && !layersById[layerId]) {
      return false;
    }
    return visible && layersById[layerId];
  });
  // memoization friendly
  if (!orderedVisibleLayerIds.length) {
    return EMPTY_ARRAY;
  }
  return orderedVisibleLayerIds.map(layerId => layersById[layerId]);
}
