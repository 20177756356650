import { FeatureCollection, Geometry } from 'geojson';
import _ from 'lodash';

import { useListFavoriteLayersQuery } from 'uf-api-rtk/store/Api';
import { FunctionParams } from 'uf/base/types';
import {
  DataState,
  getData,
  isLoaded,
  useDataSuspense,
} from 'uf/data/dataState';
import { makeReduxDataKey } from 'uf/data/helpers';
import { loadLayerData } from 'uf/explore/actions/layers';
import { LayerId } from 'uf/layers';
import { loadLayerBounds } from 'uf/layers/actions/bounds';
import { loadLayerBreaks } from 'uf/layers/actions/breaks';
import { loadLayerGeojson } from 'uf/layers/actions/geojson';
import { loadLayerMetadata } from 'uf/layers/actions/metadata';
import { loadLayerStats } from 'uf/layers/actions/stats';
import { makeLayerBoundsKey } from 'uf/layers/bounds';
import { makeBreaksKey } from 'uf/layers/breaks';
import {
  StatsParams,
  getLayerDataSearchKey,
  getStatsSearchKey,
} from 'uf/layers/filters';
import { getUfGeometryType } from 'uf/layers/helpers';
import { makeGetLayerBounds } from 'uf/layers/selectors/bounds';
import { makeGetLayerBreaksByKey } from 'uf/layers/selectors/breaks';
import { makeGetLayerDataState } from 'uf/layers/selectors/data';
import { makeGetGeojsonByKey } from 'uf/layers/selectors/geojson';
import { makeGetLayerMetadataState } from 'uf/layers/selectors/metadata';
import { makeGetLayerStatsByKey } from 'uf/layers/selectors/stats';
import { makeUseEnsure } from 'uf/ui/data/useEnsure';
import { useCurrentUser } from 'uf/ui/user/hooks';

export const useLayerStats = makeUseEnsure(
  loadLayerStats,
  makeGetLayerStatsByKey,
  getStatsSearchKey,
  layerId => !!layerId,
);

type StatsRowParams = Omit<StatsParams, 'columns'>;

export function useLayerRowStats(
  layerId: LayerId,
  queryParams: StatsRowParams,
) {
  const newQueryParams: StatsParams = {
    ...queryParams,
    columns: [],
    row_count: true,
  };
  return useLayerStats(layerId, newQueryParams);
}

type StatsColumnParams = Omit<StatsParams, 'row_count'>;

export function useLayerColumnStats(
  layerId: LayerId,
  queryParams: StatsColumnParams,
) {
  if (queryParams.columns.length === 0) {
    throw new Error(
      'useLayerColumnsStats must contain at least one valid column value',
    );
  }
  const newQueryParams: StatsParams = { ...queryParams, row_count: false };
  return useLayerStats(layerId, newQueryParams);
}

/**
 * Generic geojson call, used for casting later
 */
const useLayerGeojsonGeneric = makeUseEnsure(
  loadLayerGeojson,
  makeGetGeojsonByKey,
  getLayerDataSearchKey,
  layerId => !!layerId,
);

/**
 * Request layer geojson
 */
export function useLayerGeojson<
  G extends Geometry = Geometry,
  P extends Record<string, any> = Record<string, any>,
>(...args: FunctionParams<typeof useLayerGeojsonGeneric>) {
  return useLayerGeojsonGeneric(...args) as DataState<FeatureCollection<G, P>>;
}

// CONSIDER: the name implies you get a value, instead you get a "state" object
export const useLayerMetadata = makeUseEnsure(
  loadLayerMetadata,
  makeGetLayerMetadataState,
  layerId => ({ layerId }),
  layerId => !!layerId,
);

/**
 * useLayerMetadataValue will trigger a suspense until the data is loaded
 * and will raise an exception if a fetch error occurrs
 * @param layerId
 * @returns LayerMetadata
 */
export function useLayerMetadataValue(layerId: LayerId) {
  return useDataSuspense(useLayerMetadata(layerId));
}

export function useLayerGeometryType(layerId: LayerId) {
  const layerMetadataState = useLayerMetadata(layerId);
  const geometryType = isLoaded(layerMetadataState)
    ? getUfGeometryType(getData(layerMetadataState))
    : null;
  return geometryType;
}

export const useLayerBounds = makeUseEnsure(
  loadLayerBounds,
  makeGetLayerBounds,
  makeLayerBoundsKey,
  params => !!params?.layerId,
);

export const useLayerBreaks = makeUseEnsure(
  loadLayerBreaks,
  makeGetLayerBreaksByKey,
  makeBreaksKey,
  (layerId, layerVersion, columnKey, breakCount, breakType) =>
    !!(layerId && columnKey && breakCount && breakType !== undefined),
);

/** Get raw data from the `get_layer` call */
export const useLayerData = makeUseEnsure(
  loadLayerData,
  makeGetLayerDataState,
  (layerId, query) => {
    return { searchKey: `search:${makeReduxDataKey(layerId, query)}`, layerId };
  },
  (layerId, query, layerMetadata) => layerId && !_.isEmpty(layerMetadata),
);

export function useFavoriteLayers() {
  const user = useCurrentUser();
  const userKey = user?.key;
  const { data: entries, refetch } = useListFavoriteLayersQuery({ userKey });
  return {
    favoriteLayers: entries || [],
    refetch,
  };
}
