import { AnyAction } from 'redux';
import { ActionsObservable, Epic, ofType } from 'redux-observable';
import { debounceTime, filter, map, withLatestFrom } from 'rxjs/operators';

import { User } from 'uf-api';
import { getCurrentUserActionTypes } from 'uf-api/api/authentication.service';
import {
  deleteUserDataActionTypes,
  getUserDataActionTypes,
  setUserDataActionTypes,
} from 'uf-api/api/userdata.service';
import { SetActiveProjectAction, SET_ACTIVE_PROJECT } from 'uf/app/ActionTypes';
import { parseFullPath } from 'uf/base/dataset';
import { combineEpics } from 'uf/base/epics';
import { SuccessAction } from 'uf/data/ActionTypes';
import { makeGetVisibleVirtualLayerIds } from 'uf/explore/selectors/layers';
import {
  getShouldLoadPersistence,
  getShouldSavePersistence,
} from 'uf/persistence/selectors';
import { loadUserProjects } from 'uf/projects/actions';
import { UFState } from 'uf/state';
import {
  ensureAvailableApiFlags,
  ensureUserOrganizationFlags,
} from 'uf/user/actions/flags';
import {
  loadPersistedVisibleLayerVirtualIdsForProject,
  persistVisibleLayerVirtualIdsForProject,
} from 'uf/user/actions/layers';
import { setLastActiveProject } from 'uf/user/actions/projects';
import { getUserIsAuthenticated, getUserKey } from 'uf/user/selectors/user';
import { visibleLayersListActions } from 'uf/views/actions/layers';
import { visibleLayerListActionTypes } from 'uf/views/ActionTypes';

/**
 * An epic to respond to successful user info API calls.
 *
 * Every time redux receives an action of type uf/user/info/SUCCESS:
 * we load all projects associated with that user.
 */
const DEBOUNCE_INTERVAL = __TESTING__ ? 0 : 100;

export const loadProjectsForCurrentUser: Epic<AnyAction, any> = (
  action$: ActionsObservable<AnyAction>,
  state$,
) => {
  return action$.pipe(
    ofType(getCurrentUserActionTypes.SUCCESS),
    filter(() => getUserIsAuthenticated(state$.value)),
    // This condition indicates that either the user successfully
    // logged in, or that a call to /api/current_user found
    // an existing user in the current session and returned it.
    // In this case we load the projects the user is associated with.
    map(() => loadUserProjects()),
  );
};

export function loadApiFlagForSuperuser(
  action$: ActionsObservable<SuccessAction<User>>,
) {
  return action$.pipe(
    ofType(getCurrentUserActionTypes.SUCCESS),
    map(action => action.result),
    filter(user => user.is_superuser),
    map(() => ensureAvailableApiFlags()),
  );
}

/*
 * Epics for setting user preferences
 */
export const setLastActiveProjectEpic: Epic<AnyAction, any, UFState> = (
  action$: ActionsObservable<AnyAction>,
  state$,
) => {
  return action$.pipe(
    ofType(SET_ACTIVE_PROJECT),
    withLatestFrom(state$),
    filter(([a, state]) => getShouldSavePersistence(state)),
    map(([{ value: projectId }]) =>
      setLastActiveProject(projectId, getUserKey(state$.value)),
    ),
  );
};

export const persistVisibleLayerVirtualIdsForProjectEpic: Epic<
  ReturnType<typeof visibleLayersListActions.setList>,
  any
> = (action$, state$) => {
  const getVisibleVirtualLayerIds = makeGetVisibleVirtualLayerIds();
  return action$.pipe(
    ofType(visibleLayerListActionTypes.SET),
    withLatestFrom(state$),
    filter(([a, state]) => getShouldSavePersistence(state)),
    debounceTime(DEBOUNCE_INTERVAL),
    map(([action]) => {
      const { projectId, viewId } = action;
      const visibleLayers = getVisibleVirtualLayerIds(state$.value, {
        projectId,
        viewId,
      });
      return persistVisibleLayerVirtualIdsForProject(
        projectId,
        visibleLayers,
        getUserKey(state$.value),
      );
    }),
  );
};

/*
 * Epics for retrieving user preferences
 */
export const loadPersistedVisibleLayerVirtualIdsForProjectEpic: Epic<
  AnyAction,
  any
> = (action$, state$) => {
  return action$.pipe(
    ofType(SET_ACTIVE_PROJECT),
    withLatestFrom(state$),
    filter(([a, state]) => getShouldLoadPersistence(state)),
    map(([{ value: projectId }]) => {
      const userKey = getUserKey(state$.value);
      return loadPersistedVisibleLayerVirtualIdsForProject(projectId, userKey);
    }),
  );
};

export const ensureUserOrganizationFlagsOnProjectSwitchEpic: Epic<
  SetActiveProjectAction,
  any
> = action$ => {
  return action$.pipe(
    ofType(SET_ACTIVE_PROJECT),
    map(action => {
      const { namespace: organizationKey } = parseFullPath(
        action.value,
        'project',
      );
      return ensureUserOrganizationFlags(organizationKey);
    }),
  );
};

/**
 * When user data is deleted or set on the server, clear it out in redux so
 * components will ensure them again.
 */
export const clearUserDataOnChange: Epic<any, any> = (
  action$: ActionsObservable<AnyAction>,
) => {
  return action$
    .ofType(deleteUserDataActionTypes.SUCCESS, setUserDataActionTypes.SUCCESS)
    .pipe(
      map(action => {
        return { type: getUserDataActionTypes.CLEAR, key: action.key };
      }),
    );
};

export default combineEpics(
  {
    clearUserDataOnChange,
    loadProjectsForCurrentUser,
    loadApiFlagForSuperuser,

    setLastActiveProjectEpic,

    persistVisibleLayerVirtualIdsForProjectEpic,
    loadPersistedVisibleLayerVirtualIdsForProjectEpic,

    ensureUserOrganizationFlagsOnProjectSwitchEpic,
  },
  'userEpics',
);
