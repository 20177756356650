import { Menu, MenuItem } from '@blueprintjs/core';
import * as React from 'react';
import { t } from 'ttag';

import { moduleMethodologyLinks } from 'uf/analysis/helpers';

interface OwnProps {
  moduleKey?: string;
  className?: string;
}

type AnalysisModuleMenuProps = OwnProps;

const AnalysisModuleMenu: React.FunctionComponent<AnalysisModuleMenuProps> =
  props => {
    const { moduleKey, className, ...passthroughProps } = props;
    // NOTE: The extra ...props are passed in from the OverlayTrigger to say where the menu goes.
    // NOTE: analysis.run is a core-level feature, but only applies to non base scenarios.  There is
    // logic at the run analysis endpoint that allows and project to run analysis on the base
    // scenario so we don't do a CanPerform check.

    // TODO: make this a component that accepts MenuItems.  Could possibly be a subcomponent of
    // DropdownIcon; DropdownIconMenu.  Styling was copied to mimic how react-bootstrap's
    // DropdownButton works
    return (
      <Menu className={className} {...passthroughProps}>
        <MenuItem
          href={moduleMethodologyLinks[moduleKey]}
          target="_blank"
          rel="noopener noreferrer"
          text={t`Review Methodology`}
        />
      </Menu>
    );
  };

export default AnalysisModuleMenu;
