import { mdiMinus, mdiPlus } from '@mdi/js';
import { Icon } from '@mdi/react';
import classNames from 'classnames';
import _ from 'lodash';
import React, { CSSProperties, useCallback } from 'react';
import { Box } from 'react-layout-components';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ThunkActionMapDispatch } from 'redux-thunk';
import { t } from 'ttag';

import { LayerId } from 'uf/layers';
import { ProjectId } from 'uf/projects';
import * as projectActionCreators from 'uf/projects/actions';
import rootStyles from 'uf/styles/root.module.css';
import textStyles from 'uf/styles/text.module.css';
import Clickable from 'uf/ui/base/Clickable/Clickable';
import {
  CollapsibleListItem,
  CollapsibleListItemProps,
} from 'uf/ui/base/CollapsibleList/CollapsibleListItem';
import { IconSizeNew } from 'uf/ui/base/icon';
import IconToggleButton from 'uf/ui/base/IconToggleButton/IconToggleButton';
import makeDataAttributes from 'uf/ui/base/makeDataAttributes';

interface OwnProps extends CollapsibleListItemProps {
  // for updating layer list when add/remove button is clicked
  projectId?: ProjectId;
  layerId?: LayerId;

  // for updating metadata panel when item is clicked
  onSelectItem?: () => void;
  title?: string;
  iconPath?: string;
  addRemoveTooltipText?: { addText: string; removeText: string };
  active?: boolean;
  disabled?: boolean;
  selected?: boolean;
  constrained?: boolean;
}

interface DispatchProps {
  projectActions: ThunkActionMapDispatch<typeof projectActionCreators>;
}

type CategoryListItemProps = OwnProps & DispatchProps;

const categoryListItemPropNames = [
  'layerId',
  'projectId',
  'projectActions',
  'onSelectItem',
  'addRemoveTooltipText',
];

function getCollapsibleListItemProps(
  props: CategoryListItemProps,
): CollapsibleListItemProps {
  const collapsibleListItemProps = _.omit(props, categoryListItemPropNames);
  return collapsibleListItemProps;
}
const contentStyle: CSSProperties = { minWidth: 0 };

export const CategoryListItem: React.FunctionComponent<CategoryListItemProps> =
  props => {
    const {
      active,
      disabled,
      selected,
      title,
      iconPath,
      layerId,
      onSelectItem,
      constrained,
      projectActions,
      projectId,
    } = props;

    const handleUpdateLayerList = useCallback(
      (value: boolean) => {
        const isRemove = !value;
        if (constrained && isRemove) {
          return;
        }

        void projectActions.updateLayerList(projectId, layerId, isRemove);
      },
      [projectId, layerId, constrained, projectActions],
    );

    const layerGeoIconClassName = classNames(rootStyles.thinMarginRight, {
      [textStyles.mutedText]: !active,
    });

    return (
      <CollapsibleListItem
        className={'LayerManager-layer'}
        {...getCollapsibleListItemProps(props)}>
        <Clickable
          onClick={onSelectItem}
          className={classNames(rootStyles.fit, { active })}>
          <Box
            fit
            justifyContent="space-between"
            className={textStyles.ellipsisParent}>
            <Box
              justifyContent="space-between"
              alignItems="center"
              style={contentStyle}>
              <Icon
                path={iconPath}
                className={layerGeoIconClassName}
                size={IconSizeNew.SMALL}
              />
              <div className={textStyles.ellipsis}>{title}</div>
            </Box>
            <IconToggleButton
              className={classNames(
                rootStyles.thinMarginLeft,
                { active },
                'LayerManager-addRemove',
              )}
              iconPath={mdiPlus}
              iconToggledPath={mdiMinus}
              disabled={disabled}
              selected={selected}
              debounce={400}
              tooltipText={t`Add to layer list`}
              toggledTooltipText={t`Remove from layer list`}
              onClick={handleUpdateLayerList}
              {...makeDataAttributes({ layerId })}
            />
          </Box>
        </Clickable>
      </CollapsibleListItem>
    );
  };

export default connect(null, (dispatch: Dispatch) => ({
  projectActions: bindActionCreators(projectActionCreators, dispatch),
}))(CategoryListItem);
