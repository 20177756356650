import { Feature } from 'geojson';

import {
  AppendItemAction,
  ListActionTypes,
  RemoveItemAction,
} from 'uf/base/listActionHelpers';
import { SetValueAction } from 'uf/base/reducerFactories';
import { LayerId } from 'uf/layers';
import { FilterSpec } from 'uf/layers/filters';
import { ProjectId } from 'uf/projects';

interface MapEditAction {
  projectId: ProjectId;
  layerId: LayerId;
  fromUser?: boolean;
}

export const ADD_FEATURE = 'uf/mapedit/ADD_FEATURE';

export const DELETE_FEATURE = 'uf/mapedit/DELETE_FEATURE';
export const SET_FEATURE_LIST = 'uf/mapedit/SET_FEATURE_LIST';
export const UPDATE_FEATURE = 'uf/mapedit/UPDATE_FEATURE';
export const UPSERT_FEATURE = 'uf/mapedit/UPSERT_FEATURE';
export const UPSERT_FEATURES = 'uf/mapedit/UPSERT_FEATURES';
export const CLEAR_ALL_FEATURE_LISTS = 'uf/mapedit/CLEAR_ALL_FEATURE_LISTS';

export const featureEditListActionTypes: ListActionTypes<
  typeof SET_FEATURE_LIST,
  typeof UPDATE_FEATURE,
  typeof UPSERT_FEATURE,
  typeof UPSERT_FEATURES,
  typeof ADD_FEATURE,
  typeof DELETE_FEATURE
> = {
  SET: SET_FEATURE_LIST,
  APPEND: ADD_FEATURE,
  UPDATE: UPDATE_FEATURE,
  UPSERT: UPSERT_FEATURE,
  UPSERT_ITEMS: UPSERT_FEATURES,
  REMOVE: DELETE_FEATURE,
};

export type AddFeatureAction = MapEditAction &
  AppendItemAction<Feature, typeof featureEditListActionTypes.APPEND>;

export type DeleteFeatureAction = MapEditAction &
  RemoveItemAction<Feature, typeof featureEditListActionTypes.REMOVE>;

export const LOAD_FEATURES_INTO_EDITS = 'uf/mapedit/LOAD_FEATURES_INTO_EDITS';
export interface LoadSelectionIntoEditsAction {
  type: typeof LOAD_FEATURES_INTO_EDITS;
  projectId: ProjectId;
  layerId: LayerId;
  version: string;
  filters: Partial<FilterSpec>;
}

export const SET_EDITS_DEFAULT_PROPERTIES =
  'uf/mapedit/SET_EDITS_DEFAULT_PROPERTIES';
export interface SetEditsDefaultPropertiesAction<P> extends SetValueAction<P> {
  type: typeof SET_EDITS_DEFAULT_PROPERTIES;
  layerId: LayerId;
  projectId: ProjectId;
}
