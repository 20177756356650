import {
  CuratedColumnSymbology,
  CUSTOM_THEME,
  DEFAULT_OPACITY,
  DistributionTypes,
  InterpolationTypes,
  PaintColorTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/public/dataset/usgs_usco_2010': {
    do_per_capita: [
      {
        type: SymbologyTypes.FILL,
        paint: {
          'fill-color': {
            property: 'do_per_capita',
            type: PaintColorTypes.INTERVAL,
            stops: [
              { value: 0, color: '#e6f6e1' },
              { value: 15, color: '#aadeba' },
              { value: 75, color: '#8bd2bf' },
              { value: 125, color: '#6bc2c9' },
              { value: 175, color: '#4caecd' },
              { value: 225, color: '#3193c2' },
              { value: 300, color: '#1978b4' },
              { value: 375, color: '#0a5d9f' },
              { value: 450, color: '#084081' },
              { value: 550, color: '#00305c' },
              { value: 650, color: '#001830' },
            ],
          },
          'fill-opacity': DEFAULT_OPACITY,
        },
        display_hints: {
          scale: SymbologyScales.NUMERIC,
          distribution: DistributionTypes.DEFAULT,
          interpolation: InterpolationTypes.INTERVAL,
          theme: CUSTOM_THEME,
        },
      },
    ],
  },
};

export default symbologies;
