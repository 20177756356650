import { createSelector } from 'reselect';

import { Subscription } from 'uf-api';
import { EMPTY_ARRAY, EMPTY_OBJECT } from 'uf/base';
import { makeGetFromPropsSelector } from 'uf/base/selector';
import { DataState, EmptyKeyedState, getData } from 'uf/data/dataState';
import { MemberListResponse, OrganizationState } from 'uf/organizations/state';
import { UFState } from 'uf/state';

export function getOrganizationsState(state: UFState): OrganizationState {
  return state?.organizations || (EMPTY_OBJECT as OrganizationState);
}

const getOrganizationProjectListState = createSelector(
  getOrganizationsState,
  organizationsState =>
    organizationsState.projectList ||
    (EMPTY_OBJECT as typeof organizationsState.projectList),
);

export const getOrganizationSubscriptionState = createSelector(
  getOrganizationsState,
  organizationsState => organizationsState.subscription || EMPTY_OBJECT,
);

export function makeGetOrganizationSubscriptionStateByKey() {
  return createSelector(
    getOrganizationSubscriptionState,
    makeGetFromPropsSelector<string, 'key'>('key'),
    (subscriptions, key) => subscriptions[key],
  );
}

type OrgKey = string;
export const getOrganizationMembersState = createSelector(
  getOrganizationsState,
  (organizationsState): Record<OrgKey, DataState<MemberListResponse>> =>
    organizationsState.members ||
    (EMPTY_OBJECT as typeof organizationsState.members),
);

export const getOrganizationInvitationsState = createSelector(
  getOrganizationsState,
  organizationsState =>
    organizationsState.invitations ||
    (EMPTY_OBJECT as typeof organizationsState.invitations),
);

const getOrganizationFileState = createSelector(
  getOrganizationsState,
  organizationsState =>
    organizationsState.files ||
    (EMPTY_OBJECT as typeof organizationsState.files),
);

export function makeGetOrganizationProjectList() {
  return createSelector(
    makeGetFromPropsSelector<string, 'organizationKey'>('organizationKey'),
    getOrganizationProjectListState,
    (organizationKey, projectsState) => {
      if (!organizationKey) {
        return EMPTY_ARRAY;
      }
      return getData(projectsState[organizationKey]).items || EMPTY_ARRAY;
    },
  );
}

export function makeGetOrganizationSubscriptionState() {
  return createSelector(
    makeGetFromPropsSelector<string, 'organizationKey'>('organizationKey'),
    getOrganizationSubscriptionState,
    (organizationKey, subscriptionState): DataState<Subscription> => {
      if (!organizationKey) {
        return EmptyKeyedState;
      }
      return subscriptionState[organizationKey] || EmptyKeyedState;
    },
  );
}

export function makeGetOrganizationSubscription() {
  return createSelector(
    makeGetOrganizationSubscriptionState(),
    subscriptionState => getData(subscriptionState, EMPTY_OBJECT),
  );
}

export function makeGetOrganizationsSubscriptionStates() {
  return createSelector(
    getOrganizationSubscriptionState,
    makeGetFromPropsSelector<string[], 'organizationKeys'>('organizationKeys'),
    (orgState, organizationKeys) =>
      organizationKeys.map(orgKey => orgState[orgKey]),
  );
}

export function makeGetOrganizationMembersState() {
  return createSelector(
    makeGetFromPropsSelector<string, 'organizationKey'>('organizationKey'),
    getOrganizationMembersState,
    (organizationKey, membersState): DataState<MemberListResponse> => {
      if (!organizationKey) {
        return EMPTY_OBJECT as DataState<MemberListResponse>;
      }

      return (
        membersState[organizationKey] ||
        (EMPTY_OBJECT as DataState<MemberListResponse>)
      );
    },
  );
}

export function makeGetOrganizationMembers() {
  return createSelector(
    makeGetFromPropsSelector<string, 'organizationKey'>('organizationKey'),
    getOrganizationMembersState,
    (organizationKey, membersState) => {
      if (!organizationKey) {
        return EMPTY_ARRAY;
      }
      return getData(membersState[organizationKey]).items || EMPTY_ARRAY;
    },
  );
}

export function makeGetOrganizationInvitations() {
  return createSelector(
    makeGetFromPropsSelector<string, 'organizationKey'>('organizationKey'),
    getOrganizationInvitationsState,
    (organizationKey, invitationsState) => {
      if (!organizationKey) {
        return EMPTY_ARRAY;
      }
      return getData(invitationsState[organizationKey]).items || EMPTY_ARRAY;
    },
  );
}

export function makeGetOrganizationUsedSeats() {
  const getOrganizationInvitations = makeGetOrganizationInvitations();
  const getOrganizationMembers = makeGetOrganizationMembers();
  return createSelector(
    getOrganizationInvitations,
    getOrganizationMembers,
    (invitations, members) => invitations.length + members.length,
  );
}

export function makeGetOrganizationFiles() {
  return createSelector(
    makeGetFromPropsSelector<string, 'organizationKey'>('organizationKey'),
    getOrganizationFileState,
    (organizationKey, fileState) => {
      if (!organizationKey) {
        return EMPTY_ARRAY;
      }
      return getData(fileState[organizationKey]).items || EMPTY_ARRAY;
    },
  );
}

export const getFilteredOrganizationMemberCountState = createSelector(
  getOrganizationsState,
  organizationsState =>
    organizationsState.memberCount ||
    (EMPTY_OBJECT as typeof organizationsState.memberCount),
);

export function makeGetFilteredOrganizationMemberCount() {
  return createSelector(
    makeGetFromPropsSelector<string, 'organizationKey'>('organizationKey'),
    getFilteredOrganizationMemberCountState,
    (organizationKey, memberCountState) => {
      if (!organizationKey) {
        return EMPTY_ARRAY;
      }
      return getData(memberCountState[organizationKey]).count;
    },
  );
}
