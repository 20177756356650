import { t } from 'ttag';

import { LegacyChart, LegacyReport } from 'uf/reporting/index';

const dailyTotalChart: LegacyChart = {
  name: t`Transit Trip Counts Daily`,
  description: t`Estimated number of total transit trips daily by the DRM model`,
  key: 'drm_daily_total_transit_trip',
  series: [
    {
      key: 'daily_total_transit_trip',
      source: 'analysis',
      sourceKey: 'direct_ridership',
      layerKey: 'daily_transit_trip_total',
      columnKey: 'daily_total_transit_trip',
    },
  ],
};

const annualTotalChart: LegacyChart = {
  name: t`Transit Trip Counts Annually`,
  description: t`Estimated number of total transit trips annually by the DRM model`,
  key: 'drm_annual_total_transit_trip',
  series: [
    {
      key: 'annual_total_transit_trip',
      source: 'analysis',
      sourceKey: 'direct_ridership',
      layerKey: 'annual_transit_trip_total',
      columnKey: 'annual_total_transit_trip',
    },
  ],
};

export const drmCharts: LegacyReport = Object.freeze({
  key: 'direct_ridership',
  name: t`Direct Ridership`,
  charts: [dailyTotalChart, annualTotalChart],
});
