import {
  CuratedColumnSymbology,
  DistributionTypes,
  InterpolationTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/public/dataset/usda_ers_food_access_research_atlas_2015': {
    lalowihalfshare: [
      {
        display_hints: {
          distribution: DistributionTypes.QUANTILES,
          interpolation: InterpolationTypes.INTERVAL,
          is_percent: false,
          is_reverse_theme: true,
          scale: SymbologyScales.NUMERIC,
          showZero: true,
          theme: 'uf:#DB4325,#E57540,#ECC997,#EBEED9,#A3D8C3,#5DC3B0,#086265',
        },
        paint: {
          'fill-color': {
            default: 'transparent',
            property: 'lalowihalfshare',
            stops: [
              {
                color: 'rgb(8, 98, 101)',
                value: 0.007,
              },
              {
                color: 'rgb(64, 159, 148)',
                value: 0.06,
              },
              {
                color: 'rgb(116, 198, 180)',
                value: 0.1,
              },
              {
                color: 'rgb(163, 216, 196)',
                value: 0.139,
              },
              {
                color: 'rgb(208, 228, 205)',
                value: 0.18,
              },
              {
                color: 'rgb(232, 222, 191)',
                value: 0.224,
              },
              {
                color: 'rgb(235, 193, 148)',
                value: 0.271,
              },
              {
                color: 'rgb(231, 146, 96)',
                value: 0.326,
              },
              {
                color: 'rgb(226, 102, 58)',
                value: 0.391,
              },
              {
                color: 'rgb(219, 67, 37)',
                value: 0.487,
              },
            ],
            type: 'interval',
          },
          'fill-opacity': 0.7000000000000001,
        },
        type: SymbologyTypes.FILL,
      },
      {
        display_hints: {
          distribution: DistributionTypes.QUANTILES,
          interpolation: InterpolationTypes.INTERVAL,
          is_percent: false,
          scale: SymbologyScales.NUMERIC,
          showZero: true,
          theme: 'material:blue:range',
        },
        paint: {
          'line-color': 'transparent',
          'line-opacity': 0.7000000000000001,
          'line-width': 0.5,
        },
        type: SymbologyTypes.LINE,
      },
    ],
    lapop1_20: [
      {
        display_hints: {
          distribution: DistributionTypes.QUANTILES,
          interpolation: InterpolationTypes.INTERVAL,
          is_percent: false,
          is_reverse_theme: true,
          scale: SymbologyScales.NUMERIC,
          showZero: true,
          theme: 'd3:interpolateRdBu',
        },
        paint: {
          'fill-color': {
            default: 'transparent',
            property: 'lapop1_20',
            stops: [
              {
                color: 'rgb(5, 48, 97)',
                value: 0,
              },
              {
                color: 'rgb(75, 148, 196)',
                value: 18.528,
              },
              {
                color: 'rgb(205, 227, 238)',
                value: 343.04,
              },
              {
                color: 'rgb(251, 215, 196)',
                value: 991.597,
              },
              {
                color: 'rgb(213, 96, 80)',
                value: 1926.639,
              },
              {
                color: 'rgb(103, 0, 31)',
                value: 3342.397,
              },
            ],
            type: 'interval',
          },
          'fill-opacity': 0.6,
        },
        type: SymbologyTypes.FILL,
      },
      {
        display_hints: {
          distribution: DistributionTypes.QUANTILES,
          interpolation: InterpolationTypes.INTERVAL,
          is_percent: false,
          scale: SymbologyScales.NUMERIC,
          showZero: true,
          theme: 'material:blue:range',
        },
        paint: {
          'line-color': 'transparent',
          'line-opacity': 0.6,
          'line-width': 0.5,
        },
        type: SymbologyTypes.LINE,
      },
    ],
  },
};

export default symbologies;
