/**
 * UrbanFootprint App API
 * API for serving UrbanFootprint Application
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { BillingPeriod } from './billingPeriod';

/**
 * An UrbanFootprint plan that can be purchased.
 */
export interface UFPlan<> {
  /**
   * The base cost, in cents, per billing period.
   */
  base_price?: number;
  billing_period: BillingPeriod;
  /**
   * Code of the plan.
   */
  code: string;
  /**
   * The description of the plan.
   */
  description: string;
  /**
   * The features that this plan is entitled to.
   */
  features?: Array<UFPlan.FeaturesEnum>;
  /**
   * The number of projects that the base price includes with no additional charge. -1 means unlimited.
   */
  num_projects?: number;
  /**
   * The number of scenarios per project that the base price includes with no additional charge. -1 means unlimited
   */
  num_scenarios?: number;
  /**
   * The number of users that the base price includes with no additional charge. -1 means unlimited.
   */
  num_seats?: number;
  /**
   * The cost per seat, in cents, per billing period.
   */
  price_per_seat?: number;
  /**
   * Can users purchase this plan through the app/API (as opposed to through sales, etc.)? Note that users can still purchase add-ons (seats, projects, scenarios) for non self-service plans if the price for the add-on is > 0.
   */
  self_service?: boolean;
  /**
   * How long should the trial last, if there is one, in days.
   */
  trial_length?: number;
}
export namespace UFPlan {
  export type FeaturesEnum =
    | 'layer.export'
    | 'layer.import'
    | 'project.read'
    | 'project.create'
    | 'scenario.create'
    | 'analysis.run'
    | 'builtforms.update';
  export const FeaturesEnum = {
    LayerExport: 'layer.export',
    LayerImport: 'layer.import',
    ProjectRead: 'project.read',
    ProjectCreate: 'project.create',
    ScenarioCreate: 'scenario.create',
    AnalysisRun: 'analysis.run',
    BuiltformsUpdate: 'builtforms.update',
  } as const;
}
