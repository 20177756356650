import { getLayerActionTypes } from 'uf-api/api/layer.service';
import { dispatchAsyncAction, makeEnsureActionCreator } from 'uf/data/loader';
import { LayerId } from 'uf/layers';
import { fetchLayerMetadata } from 'uf/layers/apis';
import { makeGetLayerMetadataByKey } from 'uf/layers/selectors/metadata';

/**
 * Action that only loads the layer metadata if it hasn't already been
 * loaded, but returns a promise that resolves to the final metadata
 * no matter what the loaded state is.
 */
export const ensureLayerMetadata = makeEnsureActionCreator(
  loadLayerMetadata,
  makeGetLayerMetadataByKey(),
);

/**
 * Unconditionally load the layer metadata for the given layerId. This
 * is used to force the refresh of metadata, in case data has changed
 * on the server.  Most of the time, use `ensureLayerMetadata`
 * instead.
 */
export function loadLayerMetadata(layerId: LayerId) {
  return dispatchAsyncAction(getLayerActionTypes, fetchLayerMetadata(layerId), {
    key: layerId,
    layerId,
  });
}
