import { parseFullPath } from 'uf/base/dataset';
import { makeAsyncApiCaller } from 'uf/base/xhr';
import { ProjectId } from 'uf/projects';

const enqueueImportLayer = makeAsyncApiCaller(
  apis => apis.project.enqueue_import_layer,
);
export function importLayerAPI(projectId: ProjectId, file: Blob, name: string) {
  const { namespace, key } = parseFullPath(projectId);
  return enqueueImportLayer({
    project_namespace: namespace,
    project_key: key,
    formData: file,
    name,
  });
}
