import { combineReducers } from 'redux';

import { ProjectModuleParams } from 'uf-api';
import { getProjectAnalysisParamsActionTypes } from 'uf-api/api/project.service';
import {
  makeKeyedReducer,
  makeSetValueOnlyReducer,
} from 'uf/base/reducerFactories';
import { makeKeyedPromiseReducer } from 'uf/data/makeKeyedPromiseReducer';

import {
  CLEAR_SCENARIO_PARAMS,
  ClearScenarioParamAction,
  previewScenarioParamActionTypes,
  SET_ACTIVE_ANALYSIS_EDITOR_MODULE_KEY,
  SET_DIRTY_SCENARIO_PARAMS,
  SetDirtyScenarioParamAction,
  UPDATE_SCENARIO_PARAMS,
  UpdateScenarioParamAction,
} from './ActionTypes';
import { AnalysisEditorState, EditorState } from './state';

const reducer = combineReducers<AnalysisEditorState>({
  activeModuleKey: makeKeyedReducer(
    makeSetValueOnlyReducer(SET_ACTIVE_ANALYSIS_EDITOR_MODULE_KEY),
    'projectId',
  ),

  projectModuleParams: makeKeyedPromiseReducer<ProjectModuleParams>(
    getProjectAnalysisParamsActionTypes,
  ),

  editors: makeKeyedReducer(
    makeKeyedReducer(editReducer, 'scenarioId'),
    'moduleKey',
  ),

  preview: makeKeyedPromiseReducer(previewScenarioParamActionTypes),
});

function editReducer(
  state: EditorState,
  action:
    | UpdateScenarioParamAction
    | SetDirtyScenarioParamAction
    | ClearScenarioParamAction,
): EditorState {
  switch (action.type) {
    case UPDATE_SCENARIO_PARAMS:
      return {
        ...state,
        data: action.parameters,
        // automatically set dirty
        dirty: true,
      };

    case SET_DIRTY_SCENARIO_PARAMS:
      return {
        ...state,
        dirty: action.dirty,
      };

    case CLEAR_SCENARIO_PARAMS:
      return {
        ...state,
        dirty: false,
        data: null,
      };
    default:
      return state;
  }
}

export default reducer;
