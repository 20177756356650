import { ReportSpec } from 'uf-api';
import { parseFullPath } from 'uf/base/dataset';
import { makeAsyncApiCaller, makeAsyncApiCallerRaw } from 'uf/base/xhr';
import { ProjectId } from 'uf/projects';
import { ScenarioId } from 'uf/scenarios';

const getReport = makeAsyncApiCaller(apis => apis.report.get_report);

export function loadReport(
  projectId: ProjectId,
  scenarioId: ScenarioId,
  spec: ReportSpec,
) {
  const { namespace, key } = parseFullPath(projectId);
  return getReport({
    namespace,
    key,
    /**
     * While the api accepts multiple scenarios, we don't have a good way of working with it
     * from the viewpoint of our ensure* pattern. Since the same scenario could exist in many
     * requests, we would need a really smart selector that can look at all the DataStates and
     * resolve what's been loaded. This problem is better solved with an abstraction like
     * GraphQL.
     */
    scenario_paths: [scenarioId],
    spec,
  });
}

const getReportXls = makeAsyncApiCallerRaw(apis => apis.report.get_report_xls);

export function exportReport(
  projectId: ProjectId,
  scenarioIds: string[],
  spec: ReportSpec,
) {
  const { namespace, key } = parseFullPath(projectId);
  return getReportXls({
    namespace,
    key,
    scenario_paths: scenarioIds,
    spec,
  });
}
