// eslint-enable no-shadow
import { mdiChevronRight, mdiHospitalBuilding, mdiSchool } from '@mdi/js';
import _ from 'lodash';
import {
  CameraOptions,
  Layer,
  LngLatLike,
  Map,
  MapboxOptions,
  Marker,
  RequestParameters,
  Style,
  TransformRequestFunction,
} from 'mapbox-gl';
import React, {
  Fragment,
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Box } from 'react-layout-components';
import { jt, t } from 'ttag';
import { EMPTY_ARRAY, LOADED, LOADING } from 'uf/base';
import { getAPIOrigin } from 'uf/base/api';
import { padMapBounds, setMapOnWindow, UFLngLatBounds } from 'uf/base/map';
import { LayerSource, MapViewport } from 'uf/map';
import ErrorMessage from 'uf/ui/base/ErrorMessage/ErrorMessage';
import { GetComponentProps } from 'uf/ui/base/types';
import { useDebouncedCallback } from 'uf/ui/base/useDebouncedCallback/useDebouncedCallback';
import usePrevious from 'uf/ui/base/usePrevious/usePrevious';
import mapboxgl from 'uf/ui/map/mapboxgl';
import { useSnapshot } from 'uf/ui/map/MapView/useSnapshot';
import warning from 'warning';
import {
  addSvgPathAsImage,
  addTileSources,
  getMapViewport,
  removeTileSources,
  updateMapLayers,
  updateTileSourcesData,
} from './helpers';
import { useHandleErrors, useMapboxEventListener } from './hooks';
import useUserFlag from 'uf/ui/user/useUserFlag';
import { useSelector } from 'react-redux';
import { selectZoomExtentsByLayerId } from 'uf/layers/reducer/zoomExtentSlice';

/**
 * This is the padding used by MapBox when they render elements on the map.  Use this
 * value to align with their elements.
 */
export const MAPBOX_PADDING = 10;

const BOUNDS_PADDING = 0.2;
const MAX_BOUNDS_PADDING = 2.0;
const DEBOUNCE_TIME = 300;

export interface Props {
  height: number;
  width: number;

  className?: string;

  style: string | Style;
  onRefreshBasemap?: (map: Map) => void;

  showTileBoundaries?: boolean;

  /**
   * An array of mapbox tile sources.
   * https://www.mapbox.com/mapbox-gl-style-spec/#sources
   */
  tileSources: LayerSource[];
  styleLayers: Layer[];

  markers?: Record<string, Marker>;

  /**
   * Fires after the map has been instantiated and default event handlers are set.
   * This is useful for adding additional Controls, setting additional event handlers,
   * and configuring the map instance.
   * onMapInitialized(map)
   * @param options - the parameters used to initialize the map
   * @param map - the mapboxgl map instance
   */
  onMapInitialized: (options: MapboxOptions, map: Map) => void;
  /**
   * Fires when the map is loading, then again when it finishes.
   * onMapStatusChanged(status, map)
   * @param status - 'LOADING' or 'LOADED'
   * @param map - the mapboxgl map instance
   */
  onMapStatusChanged: (status: string, map: Map) => void;

  /**
   * Fires when the map is idle.
   * This is preferable to onMapStatusChanged.
   * @param map - the mapboxgl map instance
   */
  onMapIdle?: (map: Map) => void;

  onMapMaxBoundsChanged?: (bounds: UFLngLatBounds, map: Map) => void;

  mapMaxBounds?: UFLngLatBounds;
  requestedMapBounds?: UFLngLatBounds;

  requestedMapBoundsPadding?: number;
  onFitToRequestedBounds?: () => void;

  zoom: number;

  center: LngLatLike;

  pitch: number;

  bearing: number;
  /**
   * Fires when style layers are re-applied to the map.
   * onStyleLayersChanged(styleLayers, map)
   * @param styleLayers - contains all style layers on the map.
   * @param map - the mapboxgl map instance
   */
  onStyleLayersChanged?: (styleLayers: Layer[], map: Map) => void;
  /**
   * Fires when tile sources are added to the map, including 'composite'.
   * onTileSourcesChanged(tileSources, map)
   * @param tileSources - contains all tile sources on the map.
   * @param map - the mapboxgl map instance
   */
  onTileSourcesChanged?: (tileSources: LayerSource[], map: Map) => void;

  /**
   * Fires when the map is loading, then again when it finishes.
   * onMapViewportChanged(zoom, center, map)
   * @param zoom - the new zoom level
   * @param center - the new center point of the map
   * @param map - the mapboxgl map instance
   */
  onViewportChanged: (
    viewport: Partial<MapViewport>,
    map: Map,
    fromUser: boolean,
  ) => void;

  /**
   * Set this to `true` to take a snapshot on the next render. `onSnapshot` will
   * be called with the url of the snapshot PNG. Make sure to clear this once
   * the snapshot has been taken.
   */
  takeSnapshot?: boolean;

  onError?: (error, map: Map) => void;

  /**
   * Callback when a snapshot is run. The implementation of this function should
   * clear the `takeSnapshot` property
   */
  onSnapshot?: (url: string) => void;

  attributionStyle: null | 'text' | 'compact';

  mapboxLogoPosition?:
    | 'top-left'
    | 'top-right'
    | 'bottom-left'
    | 'bottom-right';

  headers?: Record<string, string>;
  /**
   * This is property that will be set on the global `window` object with the
   * mapbox instance. e.g. if you set this to `'__map'` then `window.__map` will
   * point to the map.
   */
  globalMapProperty?: string;
}
export const MapView: FunctionComponent<Partial<Props>> = props => {
  const [map, setMap] = useState<Map>(null);
  const {
    tileSources = EMPTY_ARRAY as LayerSource[],
    styleLayers = EMPTY_ARRAY as Layer[],
    attributionStyle = 'compact',
    requestedMapBoundsPadding = BOUNDS_PADDING,
    height,
    width,
    takeSnapshot,
    mapMaxBounds,
    pitch,
    bearing,
    zoom,
    center,
    onRefreshBasemap,
    style,
    showTileBoundaries,
    mapboxLogoPosition,
    requestedMapBounds,
    headers,
    onMapInitialized,
    onViewportChanged,
    onSnapshot,
    onMapStatusChanged,
    onStyleLayersChanged,
    onFitToRequestedBounds,
    onMapMaxBoundsChanged,
    onError,
    onMapIdle,
    onTileSourcesChanged,
    globalMapProperty,
    markers,
  } = props;
  const transformRequest: mapboxgl.TransformRequestFunction =
    useTransformRequest(headers);

  const [styleUrl, setStyleUrl] = useState<string | Style>('');
  const [safeToMutateMap, setSafeToMutateMap] = useState(false);
  // Hides shadow box around map bounds
  useUserFlag('dev-hide-max-bounds');
  useResizeMap(map, width, height);

  const fitBounds = useFitBounds(
    map,
    requestedMapBounds,
    width,
    height,
    onFitToRequestedBounds,
    requestedMapBoundsPadding,
    pitch,
    bearing,
  );

  // TODO: this should just be a regular function that takes bearing/center/map/pitch/zoom as parameters
  const easeTo = useCallback(() => {
    if (!map) {
      return;
    }
    // The map's pitch/bearing can change due to animations like easeTo.
    // We don't want to update the pitch/bearing in this case because
    // this is likely a transition and not the final state.
    const ease: CameraOptions = {};
    if (_.isNumber(pitch)) {
      ease.pitch = pitch;
    }
    if (_.isNumber(bearing)) {
      ease.bearing = bearing;
    }
    if (_.isNumber(zoom)) {
      ease.zoom = zoom;
    }
    if (!_.isEmpty(center)) {
      ease.center = center;
    }

    if (!_.isEmpty(ease)) {
      // missing in mock
      if (map.easeTo) {
        map.easeTo(ease);
      }
    }
  }, [bearing, center, map, pitch, zoom]);

  useUpdateMapMaxBounds(
    map,
    mapMaxBounds,
    width,
    height,
    onMapMaxBoundsChanged,
  );

  useUpdateTileSourceData(map, tileSources);

  useTakeSnapshot(map, onSnapshot, takeSnapshot);

  useEffect(() => {
    if (map) {
      map.showTileBoundaries = showTileBoundaries;
    }
  }, [map, showTileBoundaries]);

  const onMapStatusChangedInternal = useMapStatusChanged(
    map,
    onMapStatusChanged,
  );
  const zoomExtentsByLayer = useSelector(selectZoomExtentsByLayerId);
  const forceUpdateMap = useUpdateMapLayers(
    map,
    styleLayers,
    styleUrl,
    style,
    tileSources,
    safeToMutateMap,
    setSafeToMutateMap,
    onStyleLayersChanged,
    onTileSourcesChanged,
    setStyleUrl,
    onMapStatusChangedInternal,
    onRefreshBasemap,
    zoomExtentsByLayer,
  );

  useHandleErrors(map, forceUpdateMap, onError, safeToMutateMap);

  useTrackMapPosition(
    map,
    requestedMapBounds,
    fitBounds,
    easeTo,
    pitch,
    bearing,
    zoom,
    center,
  );

  useFinishBasemapLoad(map, setSafeToMutateMap);

  // Just because the map is LOADED, does not mean the map is done rendering.
  // The 'idle' event is best for knowing when the map is fully rendered and settled.
  useMapIdle(map, onMapIdle);

  useMarkers(map, markers);

  useHandleMapMove(map, onViewportChanged, width, height);
  const mapDivRef = useInitializeMap(
    map,
    style,
    attributionStyle,
    mapMaxBounds,
    width,
    height,
    mapboxLogoPosition,
    pitch,
    bearing,
    center,
    zoom,
    setMap,
    setStyleUrl,
    showTileBoundaries,
    requestedMapBounds,
    fitBounds,
    onMapInitialized,
    transformRequest,
    globalMapProperty,
  );

  // surprisingly expensive to calculate!
  const webglSupported = useMemo(() => mapboxgl.supported(), []);

  if (!webglSupported) {
    return renderUnsupportedWebGLMessage();
  }

  const divStyle = {
    height,
    width,
  };

  return <div id="map" style={divStyle} ref={mapDivRef} data-testid="map" />;
};

// testing component that lets us test with height and width set
type TestingProps = Pick<
  GetComponentProps<typeof MapView>,
  Exclude<keyof GetComponentProps<typeof MapView>, 'width' | 'height'>
>;
export const TestingMapView: FunctionComponent<TestingProps> = props => {
  const testingProps = {
    ...props,
    // override height/width because hocs will set these to zero
    height: 500,
    width: 500,
  };
  return <MapView {...testingProps} />;
};

export default MapView;

function useMarkers(map: Map, markers?: Record<string, Marker>) {
  const prevMarkers = usePrevious(markers);
  useEffect(() => {
    _.map(markers, (m, key) => {
      if (prevMarkers?.[key] === m) {
        // no change; pass
      } else {
        if (prevMarkers?.[key] !== undefined) {
          prevMarkers[key].remove();
        }
        m.addTo(map);
      }
    });
    _.map(prevMarkers, (m, key) => {
      if (markers?.[key] === undefined) {
        m.remove();
      }
    });
  }, [map, prevMarkers, markers]);
}

/**
 * Generate a `transformRequest` callback that can be used to alter the request
 * while the map is up.
 *
 * @param headers Current value of headers.
 */
function useTransformRequest(headers: Record<string, string>) {
  // When headers change store them in a ref so that the callback does not need
  // to be regenerated.
  const headersForTransform = useRef<Record<string, string>>(headers);
  useEffect(() => {
    headersForTransform.current = headers;
  }, [headers]);

  // This should aways return the same callback
  const transformRequest: mapboxgl.TransformRequestFunction = useCallback(
    (url, resourceType) => {
      // UF Tile requests are cross origin, so we must make sure to pass the
      // credentials (cookies).
      if (resourceType === 'Tile' && url.startsWith(getAPIOrigin())) {
        const request: RequestParameters = {
          url,
          credentials: 'include',
        };

        if (!_.isEmpty(headersForTransform.current)) {
          request.headers = headersForTransform.current;
        }

        return request;
      }
    },
    // This must remain empty so that we have a consistent callback instance
    // every time
    [],
  );
  return transformRequest;
}

function useUpdateTileSourceData(map: Map | null, tileSources: LayerSource[]) {
  const prevTileSources = usePrevious(tileSources);
  useEffect(() => {
    if (!map) {
      return;
    }
    updateTileSourcesData(map, prevTileSources, tileSources);
  }, [map, prevTileSources, tileSources]);
}

function useTakeSnapshot(
  map: Map | null,
  onSnapshot: (url: string) => void,
  takeSnapshot: boolean,
) {
  const doSnapshot = useSnapshot(map, onSnapshot);
  const lastValue = useRef(takeSnapshot);

  useEffect(() => {
    if (takeSnapshot && takeSnapshot !== lastValue.current) {
      doSnapshot();
    }
    lastValue.current = takeSnapshot;
  }, [doSnapshot, takeSnapshot, lastValue]);
}

function useFinishBasemapLoad(
  map: Map,
  setSafeToMutateMap: (safe: boolean) => void,
) {
  const onSafeToMutateMap = useCallback(() => {
    setSafeToMutateMap(true);
  }, [setSafeToMutateMap]);

  useMapboxEventListener(map, 'style.load', onSafeToMutateMap);
}

/** Fire onMapStatusChanged when the map rerenders */
function useMapStatusChanged(
  map: Map | null,
  onMapStatusChanged: (status: string, map: Map) => void,
) {
  const onMapStatusChangedInternal = useCallback(() => {
    let mapStatus = LOADING;
    if (map.loaded()) {
      mapStatus = LOADED;
    }

    if (onMapStatusChanged) {
      onMapStatusChanged(mapStatus, map);
    }
  }, [map, onMapStatusChanged]);

  useMapboxEventListener(map, 'render', onMapStatusChangedInternal);
  return onMapStatusChangedInternal;
}

function useMapIdle(map: Map, onMapIdle: (map: Map) => void) {
  const onMapIdleInternal = useCallback(() => {
    if (onMapIdle) {
      onMapIdle(map);
    }
  }, [map, onMapIdle]);
  useMapboxEventListener(map, 'idle', onMapIdleInternal);
}

/** Deal with the map moving on its own, such as if a user dragged it around, zoomed, etc */
function useHandleMapMove(
  map: Map | null,
  onViewportChanged: (
    viewport: Partial<MapViewport>,
    map: Map,
    fromUser: boolean,
  ) => void,
  width: number,
  height: number,
) {
  const handleViewportChanged = useCallback(
    (
      event: mapboxgl.MapboxEvent<MouseEvent | TouchEvent | WheelEvent> &
        mapboxgl.EventData,
    ) => {
      // due to https://github.com/mapbox/mapbox-gl-js/issues/6512 we might get
      // `moveend` events during a resize, which can happen when the map is being
      // initialized and react is still flushing out the DOM. In fact, the map
      // might be moving for other reasons, including a zero-duration `fitBounds`
      // The below guards against this case, and any other cases where mapbox is
      // erroneously firing `moveend` events while the map is in transition.
      if (map.isMoving()) {
        return;
      }
      const { originalEvent } = event;
      if (onViewportChanged) {
        const viewport: Partial<MapViewport> = {
          ...getMapViewport(map),
          width,
          height,
        };
        onViewportChanged(viewport, map, !!originalEvent);
      }
    },
    [height, map, onViewportChanged, width],
  );
  useMapboxEventListener(map, 'moveend', handleViewportChanged);
}

function useUpdateMapMaxBounds(
  map: Map | null,
  mapMaxBounds: UFLngLatBounds,
  width: number,
  height: number,
  onMapMaxBoundsChanged: (bounds: UFLngLatBounds, map: Map) => void,
) {
  const updateMapMaxBounds = useCallback(() => {
    if (!map) {
      return;
    }

    // If mapMaxBounds was removed from props, use these values to clear it from the map
    let newMaxBounds = null;

    // But if there is a maxBounds prop, use it instead
    if (mapMaxBounds) {
      const newPaddedMaxBounds: UFLngLatBounds = padMapBounds(
        mapMaxBounds,
        width,
        height,
        MAX_BOUNDS_PADDING,
      );
      newMaxBounds = newPaddedMaxBounds;
    }

    if (onMapMaxBoundsChanged) {
      onMapMaxBoundsChanged(newMaxBounds, map);
    }
  }, [height, map, mapMaxBounds, onMapMaxBoundsChanged, width]);

  useEffect(() => {
    updateMapMaxBounds();
  }, [updateMapMaxBounds]);
}

/**
 * Update all the style information
 */
function useUpdateMapLayers(
  map: Map | null,
  styleLayers: Layer[],
  styleUrl: string | Style,
  style: string | Style,
  tileSources: LayerSource[],
  safeToMutateMap: boolean,
  setSafeToMutateMap: (safe: boolean) => void,
  onStyleLayersChanged: (styleLayers: Layer[], map: Map) => void,
  onTileSourcesChanged: (tileSources: LayerSource[], map: Map) => void,
  setStyleUrl: (styleUrl: string | Style) => void,
  onMapStatusChangedLocal: () => void,
  onRefreshBasemap: (map: Map) => void,
  zoomExtentsByLayer: Record<string, { minZoom: number; maxZoom: number }>,
) {
  const [currentStyleLayers, setCurrentStyleLayers] =
    useState<Layer[]>(EMPTY_ARRAY);
  const [internalTileSources, setInternalTileSources] =
    useState<LayerSource[]>(EMPTY_ARRAY);

  const updateStyleLayers = useCallback(
    (isSafe: boolean) => {
      if (!isSafe) {
        return;
      }
      updateMapLayers(map, styleLayers, currentStyleLayers, zoomExtentsByLayer);
      setCurrentStyleLayers(styleLayers);
    },
    [currentStyleLayers, map, styleLayers, zoomExtentsByLayer],
  );

  const styleUrlDirty = useMemo(() => {
    return !_.isEqual(styleUrl, style);
  }, [style, styleUrl]);

  const styleLayersDirty = useMemo(() => {
    // When the styleUrl is dirty, we have to call map#setStyle.
    // This will _drop_ all the tile sources and style layers on our map,
    // so we mark those dirty too to make sure they get reapplied.
    return !_.isEqual(currentStyleLayers, styleLayers);
  }, [currentStyleLayers, styleLayers]);

  const tileSourcesDirty = useMemo(() => {
    return !_.isEqual(internalTileSources, tileSources);
  }, [internalTileSources, tileSources]);
  const onTileSourcesOrStyleLayersChanged = useCallback(
    (isSafe: boolean) => {
      if (!isSafe) {
        return;
      }

      // Add any new tile sources first
      if (tileSourcesDirty) {
        addTileSources(map, tileSources);
      }

      // Then update style layers
      if (styleLayersDirty) {
        updateStyleLayers(isSafe);
        if (onStyleLayersChanged) {
          onStyleLayersChanged(styleLayers, map);
        }
      }

      // Finally, clean up any unused tile sources
      if (tileSourcesDirty) {
        removeTileSources(map, internalTileSources, tileSources);
        setInternalTileSources(tileSources);
        if (onTileSourcesChanged) {
          onTileSourcesChanged(tileSources, map);
        }
      }
    },
    [
      internalTileSources,
      map,
      onStyleLayersChanged,
      onTileSourcesChanged,
      styleLayers,
      styleLayersDirty,
      tileSources,
      tileSourcesDirty,
      updateStyleLayers,
    ],
  );

  const updateBasemap = useCallback(
    (isSafe: boolean) => {
      if (!isSafe || !styleUrlDirty) {
        return;
      }

      map.setStyle(style);
      setStyleUrl(style);

      // Calling map#setStyle makes it unsafe to mutate the map
      // until the next `style.load` event is fired
      setSafeToMutateMap(false);

      // changing the base map clears the style layers and tile sources
      // on the map, so we clear them here to stay in sync
      setCurrentStyleLayers(EMPTY_ARRAY);
      setInternalTileSources(EMPTY_ARRAY);
    },
    [map, setSafeToMutateMap, setStyleUrl, style, styleUrlDirty],
  );

  const forceUpdateMap = useCallback(
    (isSafe: boolean, refreshLayers = true) => {
      onMapStatusChangedLocal();
      updateBasemap(isSafe);
      if (refreshLayers) {
        onTileSourcesOrStyleLayersChanged(isSafe);
      }
    },
    [onMapStatusChangedLocal, onTileSourcesOrStyleLayersChanged, updateBasemap],
  );

  useEffect(() => {
    // Handles when basemap, tile sources or style layers change in props

    if (safeToMutateMap) {
      if (onRefreshBasemap) {
        onRefreshBasemap(map);
      }
      const styleLayersChanged = !_.isEqual(styleLayers, currentStyleLayers);

      if (styleLayersChanged || !_.isEqual(styleUrl, style)) {
        forceUpdateMap(safeToMutateMap);
      }
    }
  }, [
    forceUpdateMap,
    currentStyleLayers,
    map,
    onRefreshBasemap,
    safeToMutateMap,
    style,
    styleLayers,
    styleUrl,
  ]);

  // TODO: stop anyone from calling this outside of this hook
  return forceUpdateMap;
}

/**
 * Move map to the current position according to props. If the map is moving,
 * then remember that we need to move and try again.
 *
 * When the move finally happens, it uses whatever zoom/center etc is current
 * at the time of the move, not the zoom/center at the time the original move
 * was requested.
 *
 * Also note that `shouldEaseTo` is a sort of dirty flag that gets carried
 * along, so that even when props change and `componentDidUpdate` recognizes
 * the props changing, the app can keep moving forward with this dirty flag.
 */
function useTrackMapPosition(
  map: Map | null,
  requestedMapBounds: UFLngLatBounds,
  fitBounds: () => void,
  easeTo: () => void,
  pitch: number,
  bearing: number,
  zoom: number,
  center: mapboxgl.LngLatLike,
) {
  const [moveIsPending, setMoveIsPending] = useState(false);

  const moveMap = useCallback(
    (shouldEaseTo: boolean) => {
      if (!map) {
        // Map is shutting down and unmounting while moving
        return;
      }

      const shouldMove = shouldEaseTo || requestedMapBounds;
      if (!shouldMove) {
        return;
      }

      // if we can't move now, defer moving until later
      if (isMapMoving(map) && !moveIsPending) {
        setMoveIsPending(true);
        requestAnimationFrame(() => moveMap(shouldEaseTo));
        return;
      }

      // we're definitely about to execute the move, so it is safe to clear the
      // flag now.
      setMoveIsPending(false);

      if (requestedMapBounds) {
        // We use the fitBounds pattern if it's given since it effects bearing, zoom, and center
        fitBounds();
      } else {
        easeTo();
      }
    },
    [easeTo, fitBounds, map, moveIsPending, requestedMapBounds],
  );

  const prevPitch = usePrevious(pitch);
  const prevBearing = usePrevious(bearing);
  const prevZoom = usePrevious(zoom);
  const prevCenter = usePrevious(center);
  useEffect(() => {
    const hasNewPitch = prevPitch !== pitch;
    const hasNewBearing = prevBearing !== bearing;
    const hasNewZoom = prevZoom !== zoom;
    const hasNewCenter = !_.isEqual(prevCenter, center);

    const shouldEaseTo =
      hasNewPitch || hasNewBearing || hasNewZoom || hasNewCenter;

    // TODO: only move if one of the 4 props change
    moveMap(shouldEaseTo);
  }, [
    pitch,
    bearing,
    zoom,
    center,
    prevPitch,
    prevBearing,
    prevZoom,
    prevCenter,
    moveMap,
  ]);
}

/** Resize the map when the width/height changes. */
function useResizeMap(map: Map | null, width: number, height: number) {
  const resizeMap = useDebouncedCallback(
    (unusedWidth?: number, unusedHeight?: number) => {
      if (map) {
        // Note that we don't actually need the height/width, the map will figure
        // that one out for us, but having these as parameters satisfies hook
        // dependencies appropriately.
        map.resize();
      }
    },
    [map],
    DEBOUNCE_TIME,
  );
  useEffect(() => {
    resizeMap(width, height);
  }, [width, height, resizeMap]);
}

/** returns a function that fits the matching bounds */
function useFitBounds(
  map: Map | null,
  requestedMapBounds: UFLngLatBounds,
  width: number,
  height: number,
  onFitToRequestedBounds: () => void,
  requestedMapBoundsPadding: number,
  pitch: number,
  bearing: number,
) {
  return useCallback(() => {
    if (!map || _.isEmpty(requestedMapBounds)) {
      return;
    }

    if (!width || !height) {
      warning(
        !!__TESTING__,
        'Bad width/height: should only happen during tests',
      );
      if (onFitToRequestedBounds) {
        onFitToRequestedBounds();
      }
      return;
    }

    if (requestedMapBounds) {
      warning(
        onFitToRequestedBounds,
        `requestedMapBounds prop was set without a corresponding onFitToRequestedBounds prop.
         This will result in infinite map.fitBounds calls unless you clear the requestedMapBounds elsewhere.`,
      );
    }

    const bounds = requestedMapBoundsPadding
      ? padMapBounds(
          requestedMapBounds,
          width,
          height,
          requestedMapBoundsPadding,
        )
      : requestedMapBounds;

    map.fitBounds(bounds, {
      animate: false,
      duration: 0,
    });

    const jump: CameraOptions = {};
    if (_.isNumber(pitch)) {
      jump.pitch = pitch;
    }
    if (_.isNumber(bearing)) {
      jump.bearing = bearing;
    }
    if (!_.isEmpty(jump)) {
      map.jumpTo(jump);
    }

    if (onFitToRequestedBounds) {
      onFitToRequestedBounds();
    }
  }, [
    bearing,
    height,
    map,
    onFitToRequestedBounds,
    pitch,
    requestedMapBounds,
    requestedMapBoundsPadding,
    width,
  ]);
}

function useInitializeMap(
  map: Map | null,
  style: string | Style,
  attributionStyle: string,
  mapMaxBounds: UFLngLatBounds,
  width: number,
  height: number,
  mapboxLogoPosition: Props['mapboxLogoPosition'],
  pitch: number,
  bearing: number,
  center: mapboxgl.LngLatLike,
  zoom: number,
  setMap: (map: Map) => void,
  setStyleUrl: (styleUrl: string | Style) => void,
  showTileBoundaries: boolean,
  requestedMapBounds: UFLngLatBounds,
  fitBounds: () => void,
  onMapInitialized: (options: MapboxOptions, map: Map) => void,
  transformRequest: mapboxgl.TransformRequestFunction,
  globalMapProperty: string,
) {
  const mapDivRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    warning(
      mapDivRef.current instanceof HTMLElement,
      `Initialize effect before mount is complete: ${mapDivRef.current}`,
    );
    warning(!map, 'Should not already have a map');
    if (map) {
      return;
    }

    // Instantiate the map and set the styleUrl
    const options: MapboxOptions = getMapboxOptions(
      mapDivRef.current,
      style,
      attributionStyle,
      mapMaxBounds,
      width,
      height,
      mapboxLogoPosition,
      pitch,
      bearing,
      center,
      zoom,
      transformRequest,
    );

    const thisMap = new mapboxgl.Map(options);
    if (globalMapProperty) {
      setMapOnWindow(globalMapProperty, thisMap);
    }
    setupMap(
      thisMap,
      attributionStyle,
      setStyleUrl,
      style,
      showTileBoundaries,
      requestedMapBounds,
      fitBounds,
      onMapInitialized,
      options,
    );
    setMap(thisMap);

    return () => {
      thisMap.remove();
      if (globalMapProperty) {
        setMapOnWindow(globalMapProperty, null);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return mapDivRef;
}

function setupMap(
  map: Map,
  attributionStyle: string,
  setStyleUrl: (styleUrl: string | Style) => void,
  style: string | Style,
  showTileBoundaries: boolean,
  requestedMapBounds: UFLngLatBounds,
  fitBounds: () => void,
  onMapInitialized: (options: MapboxOptions, map: Map) => void,
  options: MapboxOptions,
) {
  warning(!!map, 'Missing map in initial setup');
  setupMapControls(map, attributionStyle);

  setStyleUrl(style);

  // Show tile boundaries and coordinates overlayed on the map.
  map.showTileBoundaries = !!showTileBoundaries;

  // We can't just initialize the map with the correct map bounds,
  // so we do it on the load event instead.
  // TODO: Refactor this to an initialization parameter once this is fixed:
  // https://github.com/mapbox/mapbox-gl-js/issues/1970
  if (requestedMapBounds) {
    map.once('load', () => {
      fitBounds();
    });
  }

  // Tracks when it is safe to mutate the map
  // Give the map instance back to the user after initialization
  if (onMapInitialized) {
    onMapInitialized(options, map);
  }
}

function setupMapControls(map: Map, attributionStyle: string) {
  addSvgPathAsImage(map, 'chevron-right', mdiChevronRight);
  addSvgPathAsImage(map, 'hospital-building', mdiHospitalBuilding);
  addSvgPathAsImage(map, 'school', mdiSchool);
  if (attributionStyle === 'compact') {
    map.addControl(new mapboxgl.AttributionControl({ compact: true }));
  }
}

function getMapboxOptions(
  container: HTMLElement,
  style: string | Style,
  attributionStyle: string,
  mapMaxBounds: UFLngLatBounds,
  width: number,
  height: number,
  mapboxLogoPosition: MapboxOptions['logoPosition'],
  pitch: number,
  bearing: number,
  center: mapboxgl.LngLatLike,
  zoom: number,
  transformRequest: TransformRequestFunction,
) {
  const options: MapboxOptions = {
    container,
    style,
    // by default, if the user rotates the map within 7 degrees of north,
    // the map will snap back to north. We probably don't want this, so
    // we set it to zero instead.
    bearingSnap: 0,

    trackResize: false,

    antialias: true,
  };

  if (!attributionStyle || attributionStyle === 'compact') {
    options.attributionControl = false;
  }

  if (mapboxLogoPosition) {
    options.logoPosition = mapboxLogoPosition;
  }

  if (_.isNumber(pitch)) {
    options.pitch = pitch;
  }

  if (_.isNumber(bearing)) {
    options.bearing = bearing;
  }

  if (!_.isEmpty(center)) {
    options.center = center;
  }

  if (_.isNumber(zoom) && !Number.isNaN(zoom)) {
    options.zoom = zoom;
  }

  options.transformRequest = transformRequest;
  return options;
}

function renderUnsupportedWebGLMessage() {
  const supportUrl =
    'http://help.urbanfootprint.io/frequently-asked-questions/browser-compatibility';
  const supportLink = (
    <a
      target="_blank"
      rel="noopener noreferrer"
      href={supportUrl}>{t`browser support page`}</a>
  );
  return (
    <Box fit center>
      <ErrorMessage
        error={
          <Fragment>
            <div>{t`Your browser does not support WebGL.`}</div>{' '}
            <div>{jt`Please visit the ${supportLink} for more information.`}</div>
          </Fragment>
        }
      />
    </Box>
  );
}

function isMapMoving(map: Map) {
  if (!map) {
    return false;
  }
  const isMoving = map.isMoving();
  const isRotating = map.isRotating();
  const isZooming = map.isZooming();
  return isMoving || isRotating || isZooming;
  // TODO: need to cache better based on mapbox state, might need to update these in another way?
}
