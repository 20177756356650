import { Button, FormGroup, Icon } from '@blueprintjs/core';
import classNames from 'classnames';
import React, { useCallback, useState } from 'react';
import { t } from 'ttag';
import { assertNever } from 'uf/base/never';
import { BoundarySources, DefaultProjectTypes } from 'uf/projects';
import { CensusLocation } from 'uf/projects/create';
import Flex from 'uf/ui/base/Flex';
import LocationPicker from 'uf/ui/base/LocationPicker/LocationPicker';
import SearchableFilterSelect from 'uf/ui/base/SearchableFilterSelect/SearchableFilterSelect';
import UploadBoundary from 'uf/ui/projects/CreateProjectForm/UploadBoundary/UploadBoundary';
import { useBoundaryTypes, useCensusStates } from 'uf/ui/projects/hooks';

interface OwnProps {
  boundarySource: BoundarySources;
  wellKnownLabel?: string;
  wellKnownLabelInfo?: string;
  wellKnownPlaceholder?: string;
  wellKnownClassName?: string;
  onWellKnownChange?: (location: CensusLocation | null) => void;
  projectType?: string;
  userUploadLabel?: string;
  onUserUploadChange?: (fileUploadKey: string) => void;
  onNameChange?: (name: string) => void;
}

const BoundaryPicker = ({
  onNameChange,
  onUserUploadChange,
  boundarySource,
  projectType,
  wellKnownLabel,
  wellKnownLabelInfo,
  wellKnownPlaceholder,
  wellKnownClassName,
  onWellKnownChange,
  userUploadLabel,
}: OwnProps) => {
  const [selectedFilterMenu, setSelectedFilterMenu] = useState(null);
  const [selectedStateFilters, setSelectedStateFilters] = useState([]);
  const [selectedBoundaryTypeFilters, setSelectedBoundaryTypeFilters] =
    useState([]);
  const states = useCensusStates();
  const boundaryTypes = useBoundaryTypes();
  const handleUpload = useCallback(
    (layerName: string, fileUploadKey: string) => {
      if (onNameChange) {
        onNameChange(layerName);
      }
      if (onUserUploadChange) {
        onUserUploadChange(fileUploadKey);
      }
    },
    [onNameChange, onUserUploadChange],
  );

  const handleNameChange = useCallback(
    (newLayerName: string) => {
      if (onNameChange) {
        onNameChange(newLayerName);
      }
    },
    [onNameChange],
  );

  const handleFilterClick = useCallback(value => {
    setSelectedFilterMenu(value);
  }, []);

  const handleClearFilters = useCallback(() => {
    setSelectedBoundaryTypeFilters([]);
    setSelectedStateFilters([]);
  }, []);
  switch (boundarySource) {
    case BoundarySources.WELL_KNOWN:
      return (
        <FormGroup label={wellKnownLabel} labelInfo={wellKnownLabelInfo}>
          <Flex alignItems="center" className="tw-mb-2">
            <SearchableFilterSelect
              isOpen={selectedFilterMenu === 'states'}
              name="states"
              onClick={handleFilterClick}
              onSelect={setSelectedStateFilters}
              defaultFilter="all states"
              selectedFilters={selectedStateFilters}
              filterableItems={states}
            />
            <div style={{ marginLeft: 6 }} />
            <SearchableFilterSelect
              isOpen={selectedFilterMenu === 'boundary-type'}
              name="boundary-type"
              onClick={handleFilterClick}
              onSelect={setSelectedBoundaryTypeFilters}
              defaultFilter="all boundary types"
              selectedFilters={selectedBoundaryTypeFilters}
              filterableItems={boundaryTypes}
            />
            <div style={{ marginLeft: 6 }} />
            {!!(
              selectedStateFilters.length || selectedBoundaryTypeFilters.length
            ) && (
              <Button
                onClick={handleClearFilters}
                minimal
                icon={<Icon icon="cross" iconSize={12} />}>
                Clear filters
              </Button>
            )}
          </Flex>
          <LocationPicker
            placeholder={wellKnownPlaceholder}
            onChange={onWellKnownChange}
            selectDefaultArea={projectType === DefaultProjectTypes.GENERAL}
            projectType={projectType}
            className={classNames(
              wellKnownClassName,
              'CreateProjectForm-locationPicker',
            )}
            selectedCensusStates={selectedStateFilters}
            {...(selectedBoundaryTypeFilters.length && {
              selectedBoundaryTypes: selectedBoundaryTypeFilters,
            })}
          />
        </FormGroup>
      );
    case BoundarySources.USER_UPLOAD:
      return (
        <UploadBoundary
          filenameControlLabel={userUploadLabel}
          onUpload={handleUpload}
          onNameChange={handleNameChange}
        />
      );
    case BoundarySources.SOURCE_PROJECT:
      return (
        <div>{t`The new project area boundary will be a duplicate of the project area boundary from the current project.`}</div>
      );
    default:
      assertNever(boundarySource, `unknown boundary source ${boundarySource}`);
  }
};
export default BoundaryPicker;
