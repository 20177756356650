/**
 * UrbanFootprint App API
 * API for serving UrbanFootprint Application
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { LayerReference } from './layerReference';

/**
 * Info on painting action.
 */
export interface PaintingActionInfo<
  Tdisplay_hints extends object = any,
  Tfilter extends object = any,
  Tparams extends object = any,
> {
  /**
   * If painting action is currently applied to canvas.
   */
  active?: boolean;
  canvas: LayerReference;
  /**
   * Time painting action was created in RFC3339.
   */
  created?: Date;
  /**
   * Key of user who performed the action.
   */
  creator?: string;
  /**
   * Hints for the frontend.
   */
  display_hints?: Tdisplay_hints;
  /**
   * Filter used by painter to determine which rows get painted.
   */
  filter?: Tfilter;
  /**
   * Key of painting action.
   */
  key: string;
  /**
   * Key of painter type.
   */
  painter: PaintingActionInfo.PainterEnum;
  /**
   * Parameters used by painter to perform action.
   */
  params?: Tparams;
}
export namespace PaintingActionInfo {
  export type PainterEnum =
    | 'column'
    | 'row'
    | 'built_form'
    | 'undo'
    | 'redo'
    | 'delete'
    | 'split_canvas';
  export const PainterEnum = {
    Column: 'column',
    Row: 'row',
    BuiltForm: 'built_form',
    Undo: 'undo',
    Redo: 'redo',
    Delete: 'delete',
    SplitCanvas: 'split_canvas',
  } as const;
}
