import { Position } from '@blueprintjs/core';
import { mdiCamera } from '@mdi/js';
import Icon from '@mdi/react';
import React, { FunctionComponent, useCallback } from 'react';
import { Box } from 'react-layout-components';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { t } from 'ttag';

import { getActiveProjectId } from 'uf/app/selectors';
import { setTakeSnapshot as setTakeSnapshotAction } from 'uf/explore/actions/map';
import { logEvent } from 'uf/logging';
import { events as mapEvents } from 'uf/map/logging';
import { ProjectId } from 'uf/projects';
import rootStyles from 'uf/styles/root.module.css';
import { getHotKeyText } from 'uf/ui/base/hotkeys';
import { IconSizeNew } from 'uf/ui/base/icon';
import Key from 'uf/ui/base/Key/Key';
import { ExploreMapHotKeys } from 'uf/ui/explore/ExploreMap/hotKeys';
import MapButton from 'uf/ui/map/MapButton/MapButton';

const iconClassName = 'Map-export';

interface OwnProps {
  tooltipPlacement?: Position;
  minimal?: boolean;
  className?: string;
}

interface DispatchProps {
  setTakeSnapshot: typeof setTakeSnapshotAction;
}

interface StateProps {
  projectId: ProjectId;
}

type ExploreSnapshotButtonProps = OwnProps & DispatchProps & StateProps;

export const ExploreSnapshotButton: FunctionComponent<ExploreSnapshotButtonProps> =
  props => {
    const { setTakeSnapshot, projectId, tooltipPlacement, minimal, className } =
      props;

    const handleClick = useSnapshotHander(setTakeSnapshot, projectId);

    const tooltipText = (
      <Box center>
        {t`Export a quick map snapshot`}
        <Key
          className={rootStyles.thinMarginLeft}
          character={getHotKeyText(ExploreMapHotKeys.MAP_SNAPSHOT)}
        />
      </Box>
    );
    return (
      <MapButton
        minimal={minimal}
        className={className}
        id="map-snapshot"
        tooltipText={tooltipText}
        tooltipPlacement={tooltipPlacement}
        onClick={handleClick}
        icon={
          <Icon
            path={mdiCamera}
            className={iconClassName}
            size={IconSizeNew.SMALL}
          />
        }
      />
    );
  };

export default connect(
  state => ({
    projectId: getActiveProjectId(state),
  }),
  (dispatch: Dispatch) => ({
    setTakeSnapshot: bindActionCreators(setTakeSnapshotAction, dispatch),
  }),
)(ExploreSnapshotButton);

export function useSnapshotHander(
  setTakeSnapshot: typeof setTakeSnapshotAction,
  projectId: ProjectId,
) {
  return useCallback(() => {
    setTakeSnapshot();
    logEvent(mapEvents.MAP_EXPORT_SNAPSHOT, { projectId });
  }, [setTakeSnapshot, projectId]);
}
