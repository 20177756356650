import { t } from 'ttag';

import { Subscription } from 'uf-api';
import { normalizeError } from 'uf/base/swagger';
import { makeAsyncApiCaller } from 'uf/base/xhr';

export const login = makeAsyncApiCaller(apis => apis.authentication.login);
export const logout = makeAsyncApiCaller(apis => apis.authentication.logout);

export const getCurrentUser = makeAsyncApiCaller(
  apis => apis.authentication.get_current_user,
);

export const getOrganizationFeatureFlags = makeAsyncApiCaller(
  apis => apis.organization.get_organization_feature_flags,
);

export const canPerformActions = makeAsyncApiCaller(
  apis => apis.authentication.can_perform_actions,
);

export const setUserData = makeAsyncApiCaller(
  apis => apis.userdata.set_user_data,
);
export const deleteUserData = makeAsyncApiCaller(
  apis => apis.userdata.delete_user_data,
);
export const getUserData = makeAsyncApiCaller(
  apis => apis.userdata.get_user_data,
);
export const listUserDataItems = makeAsyncApiCaller(
  apis => apis.userdata.list_user_data_items,
);

const changeSubscriptionPlan = makeAsyncApiCaller(
  apis => apis.organization.change_subscription_plan,
  {
    500: () =>
      normalizeError(
        new Error(
          t`Your transaction could not be processed.
    Please click the submit button again. If this problem persists,
    email support@urbanfootprint.com with a description of your problem.`,
        ),
        // TODO: figure out why we need a custom error here, resulting in this
        // hacky cast.
      ) as Subscription,
  },
);

export function changeSubscriptionPlanAPI(
  organizationKey: string,
  planCode: string,
  seats: number,
) {
  return changeSubscriptionPlan({
    key: organizationKey,
    plan_code: planCode,
    num_seats: seats,
  });
}

export const getAvailableFeatureFlags = makeAsyncApiCaller(
  apis => apis.internal.get_available_feature_flags,
);

export const getInvitationsForUser = makeAsyncApiCaller(
  apis => apis.user.get_invitations_for_user,
);

export const acceptUserInvitation = makeAsyncApiCaller(
  apis => apis.user.accept_user_invitation,
);
export const rejectUserInvitation = makeAsyncApiCaller(
  apis => apis.user.reject_user_invitation,
);
