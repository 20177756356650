import colors from 'material-colors';

import {
  CuratedColumnSymbology,
  CUSTOM_THEME,
  DEFAULT_OPACITY,
  DistributionTypes,
  InterpolationTypes,
  PaintColorTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/public/dataset/irrigation_factor_2010': {
    residential_irrigation_factor: [
      {
        type: SymbologyTypes.FILL,
        paint: {
          'fill-color': {
            property: 'residential_irrigation_factor',
            type: PaintColorTypes.INTERVAL,
            stops: [
              { value: 0, color: '#150162' },
              { value: 0.1, color: colors.blue[900] },
              { value: 0.3, color: '#4c7ec2' },
              { value: 0.5, color: '#7ca7da' },
              { value: 0.7, color: '#abd0f3' },
              { value: 0.9, color: '#dcf9fd' },
              { value: 1, color: colors.white },
              { value: 1.1, color: '#fffdd0' },
              { value: 1.5, color: '#ffe48d' },
              { value: 2, color: '#fd9942' },
              { value: 2.5, color: '#f74b29' },
              { value: 5, color: '#ab0225' },
              { value: 10, label: '10-46', color: '#800026' },
            ],
          },
          'fill-opacity': DEFAULT_OPACITY,
        },
        display_hints: {
          scale: SymbologyScales.NUMERIC,
          distribution: DistributionTypes.DEFAULT,
          interpolation: InterpolationTypes.INTERVAL,
          theme: CUSTOM_THEME,
        },
      },
    ],
  },
};

export default symbologies;
