import { Action as RFRAction } from 'redux-first-router';

import { SetValueAction } from 'uf/base/reducerFactories';
import { makeRoutingActionType } from 'uf/routing/actions';

import {
  ROUTE_INTERNAL,
  SET_INTERNAL_PAGE_KEY,
  SET_INTERNAL_PAGE_PARAM,
} from './ActionTypes';

export function setInternalPageKey(pageKey: string): SetValueAction<string> {
  return { type: SET_INTERNAL_PAGE_KEY, value: pageKey };
}

export function setInternalPageParam<T, K extends keyof T>(
  pageKey: string,
  paramKey: K,
  paramValue: T[K],
) {
  return {
    type: SET_INTERNAL_PAGE_PARAM,
    pageKey,
    property: paramKey,
    value: paramValue,
  };
}

export function setInternalRoute(
  pageKey: string,
  params: { [key: string]: string },
): RFRAction {
  return {
    type: makeRoutingActionType(ROUTE_INTERNAL),
    query: params,
    payload: {
      pageKey,
    },
  };
}
