/**
 * Data structures for mapping numbers to strings with friendly locale-aware names.
 * For instance,
 *
 * // numbers are in the millions range
 * const getFormat = abbreviateContext([0, 31231934]);
 *
 * const formatted = getFormat(9343666);
 * returns:
 *   { value: 9.34366,
 *     abbreviation: { labeledPostfix: 'million', shortLabel: 'millions', ...} }
 *
 * This allows a range of numbers ot all use the same abbreviateContext. You can also
 * get to the abbreviation object directly from the getFormat result:
 *   getFormat.labeledPostfix === 'million'
 *
 * The keys of `abbreviation` are as follows:
 *   labeledPostfix: What do put after the number (i.e. '9.34 million')
 *   abbreviation: shorter version of labeledPostfix ('mil')
 *   shortAbbreviation: even shorter version ('M')
 *   shortLabel: What to put in a label describing these numbers (i.e. 'millions')
 *   base: The base numeric value of the abbreviation (i.e. 1000000)
 */

export interface Abbreviation {
  readonly labeledPostfix: string;
  readonly shortLabel: string;
  readonly abbreviation: string;
  readonly shortAbbreviation: string;
  readonly base: number;
  readonly decimalPrecision: number;
}

export interface Formatter {
  (value: number): { value: number; abbreviation: Abbreviation };
  abbreviation?: Abbreviation;
}

const partialEmptyAbbreviation = Object.freeze({
  labeledPostfix: '',
  shortLabel: '',
  abbreviation: '',
  shortAbbreviation: '',
});

const identityAbbreviation: Abbreviation = Object.freeze({
  ...partialEmptyAbbreviation,
  base: 1,
  decimalPrecision: 2,
});

const QUADRILLIONS_ZEROS = 15;
const TRILLIONS_ZEROS = 12;
const BILLIONS_ZEROS = 9;
const MILLIONS_ZEROS = 6;
const THOUSANDS_ZEROS = 3;
const abbreviations: readonly Abbreviation[] = Object.freeze([
  {
    labeledPostfix: 'quadrillion',
    shortLabel: 'quadrillions',
    abbreviation: 'quad.',
    shortAbbreviation: 'Qd',
    base: 10 ** QUADRILLIONS_ZEROS,
    decimalPrecision: 0,
  },
  {
    labeledPostfix: 'trillion',
    shortLabel: 'trillions',
    abbreviation: 'tril.',
    shortAbbreviation: 'T',
    base: 10 ** TRILLIONS_ZEROS,
    decimalPrecision: 0,
  },
  {
    labeledPostfix: 'billion',
    shortLabel: 'billions',
    abbreviation: 'bil.',
    shortAbbreviation: 'B',
    base: 10 ** BILLIONS_ZEROS,
    decimalPrecision: 0,
  },
  {
    labeledPostfix: 'million',
    shortLabel: 'millions',
    abbreviation: 'mil.',
    shortAbbreviation: 'M',
    base: 10 ** MILLIONS_ZEROS,
    decimalPrecision: 0,
  },
  {
    ...partialEmptyAbbreviation,
    base: 1,
    decimalPrecision: 1,
  },
  {
    ...partialEmptyAbbreviation,
    abbreviation: 'e-3',
    base: 10 ** -THOUSANDS_ZEROS,
    decimalPrecision: 3,
  },
  {
    ...partialEmptyAbbreviation,
    abbreviation: 'e-6',
    base: 10 ** -MILLIONS_ZEROS,
    decimalPrecision: 6,
  },
  {
    ...partialEmptyAbbreviation,
    abbreviation: 'e-9',
    base: 10 ** -BILLIONS_ZEROS,
    decimalPrecision: 9,
  },
]);

export function abbreviateContext(values: number[]): Formatter {
  const biggestNumber = Math.max(...values.map(Math.abs));

  const abbreviation = abbreviations.find(abbr => biggestNumber >= abbr.base);

  if (!abbreviation) {
    const formatter: Formatter = value => ({
      value,
      abbreviation: identityAbbreviation,
    });
    formatter.abbreviation = identityAbbreviation;
    return formatter;
  }

  const format: Formatter = value => {
    const newValue = value / abbreviation.base;
    return {
      abbreviation,
      value: newValue,
    };
  };
  format.abbreviation = abbreviation;
  return format;
}

/**
 * Convert a number to three decimal places inclusively based on type of bound
 * @param value - Any input value
 * @param rounding - A value of 'min' or 'max' to determine which way to round a number
 * @param options - Optional parameters for determining output format
 * @param options.maximumFractionDigits - The number of decimal places to round to
 * @returns A number with up to three decimal places or the original value (if arg is not a number)
 */
export function setInclusiveFixedValue(
  // eslint-disable-next-line no-restricted-syntax
  value: any,
  rounding: 'min' | 'max',
  options: {
    maximumFractionDigits: number;
  } = { maximumFractionDigits: 3 },
): any {
  // If the value passed is not a number return the original to allow return values
  if (typeof value !== 'number' || Number.isNaN(value)) {
    return value;
  }
  // Determine decimal precision by number of fraction digits
  const precision = 10 ** options.maximumFractionDigits;
  // Determine rounding function
  const roundingMethod = rounding === 'max' ? Math.ceil : Math.floor;
  // Return value to specified precision if number is not an integer
  return precision > 0 && !!(value % 1)
    ? roundingMethod(value * precision) / precision
    : roundingMethod(value);
}
