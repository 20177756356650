import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  CLEAR_ACTIVE_PROJECT,
  loadFrontendVersionActionTypes,
  SET_ACTIVE_PROJECT,
  SET_ACTIVE_SCENARIO,
  SHOW_APP_TOASTS,
} from 'uf/app/ActionTypes';
import { EMPTY_OBJECT } from 'uf/base';
import { parseFullPath } from 'uf/base/dataset';
import { SET_ACTIVE_LAYER, SET_ACTIVE_VIEW } from 'uf/explore/ActionTypes';

const appStateSlice = createSlice({
  name: 'app',
  initialState: {
    activeLayerId: null,
    activeProjectId: null,
    activeViewId: null,
    activeOrgId: null,
    activeScenario: {},
    toasts: {
      show: true,
    },
    frontendVersion: {},
  },
  reducers: {
    setActiveOrg(state, action: PayloadAction<string | null>) {
      state.activeOrgId = action.payload;
      state.activeProjectId = null;
      state.activeLayerId = null;
      state.activeViewId = null;
    },
  },
  // support action shapes that were manually defined / not standardized
  extraReducers: {
    [SET_ACTIVE_PROJECT]: (state, action) => {
      state.activeProjectId = action.value;
      state.activeOrgId = action.value
        ? parseFullPath(action.value).namespace
        : null;
      state.activeLayerId = null;
      state.activeViewId = null;
    },
    [CLEAR_ACTIVE_PROJECT]: state => {
      state.activeProjectId = null;
      state.activeLayerId = null;
      state.activeViewId = null;
    },
    [SET_ACTIVE_SCENARIO]: (state, action) => {
      state.activeScenario[action.projectId || state.activeProjectId] =
        action.value;
    },
    [SET_ACTIVE_LAYER]: (state, action) => {
      state.activeLayerId = action.virtualLayerId;
    },
    [SET_ACTIVE_VIEW]: (state, action) => {
      state.activeViewId = action.value;
    },
    [SHOW_APP_TOASTS]: (state, action) => {
      state.toasts.show = action.value;
    },
    [loadFrontendVersionActionTypes.SUCCESS]: (state, action) => {
      state.frontendVersion = action.result;
    },
  },
});

export const selectApp = state => state.app || EMPTY_OBJECT;

export const { reducer } = appStateSlice;
export const { setActiveOrg } = appStateSlice.actions;
