import _ from 'lodash';
import { FunctionComponent, useEffect, useMemo, useState } from 'react';

import { UserAction } from 'uf-api';
import {
  combineDataStates,
  DataState,
  getData,
  isLoaded,
  isLoading,
} from 'uf/data/dataState';
import useBindAction from 'uf/ui/base/useBindAction/useBindAction';
import { useMakeSelector } from 'uf/ui/base/useMakeSelector';
import {
  ensureCheckFeatures as ensureCheckFeatureActionCreator,
  loadCheckFeatures as loadCheckFeatureActionCreator,
} from 'uf/user/actions/permissions';
import { buildCheckFeaturesQuery } from 'uf/user/permissions';
import {
  makeGetCheckFeatures,
  makeGetCheckFeaturesDetails,
} from 'uf/user/selectors/permissions';

type ResourceType = string | string[];

interface ProvidedProps {
  loading?: boolean;
  loaded?: boolean;
  disabled?: boolean;
  reason: UserAction.ReasonEnum;

  permissions: boolean[];
  permissionsDetails: DataState<UserAction>[];
}

interface Props {
  cache?: boolean;
  verb?: string;
  resources?: ResourceType;
  any?: boolean;
  performRecheck?: boolean;
  /**
   * Do not render any children if the user does not have permission, or while
   * the permission is loading.
   */
  hideWithNoPermission?: boolean;
  /**
   * Render this instead of children if the user does not have permission.
   * @deprecated in favor of just deciding this in `children`
   */
  fallbackComponent?: JSX.Element;
  onHavePermission?: (
    canPerform: boolean,
    disabledReason?: UserAction.ReasonEnum,
    resources?: ResourceType,
    verb?: string,
  ) => void;
  children: (params: ProvidedProps) => JSX.Element;
}

/**
 * Conditionally render some UI based on whether or not the user has permission.
 *
 * Examples:
 *
 * - Won't render any visible UI if permissions are denied
 *   <CanPerform verb="layer.export" resources={layerId}>
 *     <Button>Click here</Button>
 *   </CanPerform>
 *
 * - Renders the child and passes it the named disabledProp as a boolean
 *   (The child must then implement this prop, eg: <Button> has a `disabled` prop)
 *   <CanPerform verb="layer.export" resources={layerId} disabledProp="disabled">
 *     <Button>Click here</Button>
 *   </CanPerform>
 *
 * - Renders the fallbackComponent if permissions are denied
 *   This can be used when a different UI should be shown
 *   <CanPerform verb="layer.export" resources={layerId}
 *     fallbackComponent={<Button disabled>Sorry, Dave</Button>}>
 *     <Button>Click here</Button>
 *   </CanPerform>
 */
const CanPerform: FunctionComponent<Props> = ({
  verb,
  resources: rawResources,
  cache = true,
  any,

  fallbackComponent,
  hideWithNoPermission,

  onHavePermission,
  children,
}) => {
  const resources = useMemo(
    () => (rawResources ? _.flatten([rawResources]) : []),
    [rawResources],
  );

  const { permissions, permissionsDetails } = usePermissions(resources, verb);
  const combinedStates = combineDataStates(permissionsDetails);
  const loading = isLoading(combinedStates);
  const loaded = isLoaded(combinedStates);

  // optimization to avoid rerenders because of singular vs plural resource lists
  const { canPerform, disabledReason } = useCheckCanPerform(
    resources,
    any,
    permissions,
    permissionsDetails,
  );

  // Fire requests if necessary
  useEnsurePermissions(verb, resources, cache);

  // Call any callbacks if something changed
  useEffect(() => {
    if (onHavePermission && disabledReason) {
      // need to pass along the original `resources` that were passed in to the component
      onHavePermission(canPerform, disabledReason, rawResources, verb);
    }
  }, [verb, rawResources, canPerform, disabledReason, onHavePermission]);

  // allows us to send permissions, resources, verb etc to child.
  const propsToSend: ProvidedProps = {
    loading,
    loaded,
    reason: disabledReason,
    permissions,
    permissionsDetails,
  };

  // if we can't perform the action...
  if (!canPerform) {
    if (hideWithNoPermission) {
      return null;
    }

    // then we send a fallback component if available
    if (fallbackComponent) {
      return fallbackComponent;
    }
    // otherwise we render children anyway, but assume the child knows what to
    // do with the `disabled` prop.
    propsToSend.disabled = true;
  }
  const result: JSX.Element = children(propsToSend);
  return result;
};

export function useEnsurePermissions(
  verb: string,
  resources: string[],
  cache: boolean,
): boolean {
  const [loading, setLoading] = useState(false);
  const ensureCheckFeatures = useBindAction(ensureCheckFeatureActionCreator);
  const loadCheckFeatures = useBindAction(loadCheckFeatureActionCreator);

  useEffect(() => {
    if (!verb || _.isEmpty(resources)) {
      return;
    }
    setLoading(true);
    if (cache) {
      ensureCheckFeatures([verb], resources);
    } else {
      const query = buildCheckFeaturesQuery([verb], resources);
      loadCheckFeatures(query);
    }
  }, [
    cache,
    ensureCheckFeatures,
    loadCheckFeatures,
    resources,
    setLoading,
    verb,
  ]);
  return loading;
}

/** A hook to wrap and cache `checkCanPerform` */
function useCheckCanPerform(
  resources: string[],
  any: boolean,
  permissions: boolean[],
  permissionsDetails: DataState<UserAction>[],
) {
  return useMemo(
    () => checkCanPerform(resources, any, permissions, permissionsDetails),
    [resources, any, permissions, permissionsDetails],
  );
}

function checkCanPerform(
  resources: string[],
  any: boolean,
  permissions: boolean[],
  permissionDetails: DataState<UserAction>[],
): { canPerform: boolean; disabledReason: UserAction.ReasonEnum } {
  if (_.isEmpty(permissions)) {
    return { canPerform: false, disabledReason: null };
  }
  let canPerform: boolean;
  if (any) {
    canPerform = resources.some((resource, i) => permissions[i]);
  } else {
    canPerform = resources.every((resource, i) => permissions[i]);
  }
  const disabledReason = getDisabledReason(permissionDetails);
  return { canPerform, disabledReason };
}

export function usePermissions(resources: string[], verb: string) {
  const featuresParams = { resources, verb };
  const checkedFeatures = useMakeSelector(makeGetCheckFeatures, featuresParams);
  const checkedFeaturesDetails = useMakeSelector(
    makeGetCheckFeaturesDetails,
    featuresParams,
  );

  return {
    permissions: checkedFeatures,
    permissionsDetails: checkedFeaturesDetails,
  };
}

export default CanPerform;

function getDisabledReason(
  permissionsDetails: DataState<UserAction>[],
): UserAction.ReasonEnum {
  let disabledAction: UserAction;

  // find the first action that is explictly disabled
  permissionsDetails.some(actionState => {
    if (isLoaded(actionState) && !getData(actionState)?.can_do) {
      disabledAction = getData(actionState);
    }
    return !!disabledAction;
  });
  if (disabledAction) {
    return disabledAction.reason;
  }
  return null;
}
