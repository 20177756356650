import {
  Button,
  ButtonGroup,
  Classes,
  H6,
  Menu,
  MenuDivider,
  MenuItem,
  Popover,
  Position,
  Tooltip,
} from '@blueprintjs/core';
import {
  mdiCheck,
  mdiContentSave,
  mdiCrosshairs,
  mdiCrosshairsGps,
  mdiMenuDown,
  mdiTrashCan,
} from '@mdi/js';
import Icon from '@mdi/react';
import classNames from 'classnames';
import * as _ from 'lodash';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { t } from 'ttag';

import { formatLatLng } from 'uf/base/geo';
import { isEqualLngLat, LngLat, magnitudeForScale } from 'uf/base/map';
import { MapPosition } from 'uf/map';
import colorStyles from 'uf/styles/colors.module.css';
import rootStyles from 'uf/styles/root.module.css';
import { IconSizeNew } from 'uf/ui/base/icon';

import styles from './MapExtentsPicker.module.css';

export interface Props {
  positions: MapPosition[];

  /**
   * Project position is special in that it is always visible
   */
  projectPosition?: MapPosition;

  /**
   * Current position on a map - will use this to decide if the picker has
   * selected a position or not.
   */
  lnglat: LngLat;

  /**
   * Current scale for a map - will use this to decide if the picker has
   * selected a position or not.
   */
  scale?: number;

  pitch: number;
  bearing: number;

  currentPositionKey: string;

  loading?: boolean;
  small?: boolean;
  minimal?: boolean;
  className?: string;
  pullRight?: boolean;

  onSelectPosition?: (position: MapPosition) => void;

  onSavePosition?: (position: MapPosition) => void;

  onDeletePosition?: (position: MapPosition) => void;
}

export interface State {
  id: string;

  lastPositionKey: string;
}

const menuItemClassName = classNames(
  rootStyles.flexBox,
  rootStyles.flexRow,
  styles.menuItem,
);
const checked = <Icon path={mdiCheck} size={IconSizeNew.EXTRA_SMALL} />;
const notChecked = <Icon path={''} size={IconSizeNew.EXTRA_SMALL} />;
const MAX_SCALE_ERROR_PERCENT = 0.05;
const MapExtentsPicker: FunctionComponent<Props> = props => {
  const {
    positions,
    projectPosition,
    lnglat,
    scale,
    pitch,
    bearing,
    loading,
    small,
    minimal,
    className,
    currentPositionKey,
    onDeletePosition,
    onSavePosition,
    onSelectPosition,
  } = props;

  const [lastPositionKey, setLastPositionKey] =
    useState<string>(currentPositionKey);

  useEffect(() => {
    setLastPositionKey(currentPositionKey);
  }, [currentPositionKey]);

  const getPosition = useCallback(
    (key: string) => {
      if (!key) {
        return null;
      }
      if (projectPosition && projectPosition.key === key) {
        return projectPosition;
      }
      return positions.find(pos => pos.key === key);
    },
    [positions, projectPosition],
  );

  /**
   * Does the mostly recently set position match the current lng/lat?
   * Determines if the user has panned/zoomed the map outside of this
   * component.
   *
   * If any of the position properties are null or undefined, then they
   * do not need to match the current lng/lat/scale/bearing/etc.
   */
  const isCurrent = useMemo(() => {
    const position = getPosition(lastPositionKey);
    if (!position) {
      return false;
    }

    const errorThreshold = scale * MAX_SCALE_ERROR_PERCENT;
    const scaleMatches =
      _.isNil(position.scale) ||
      Math.abs(scale - position.scale) < errorThreshold;

    return (
      scaleMatches &&
      (_.isNil(position.pitch) || pitch === position.pitch) &&
      (_.isNil(position.bearing) || bearing === position.bearing) &&
      isEqualLngLat(lnglat, position.lnglat, magnitudeForScale(scale))
    );
  }, [bearing, getPosition, lastPositionKey, lnglat, pitch, scale]);
  const onSelect = useCallback(
    eventKey => {
      setLastPositionKey(eventKey);
      const position = getPosition(eventKey);

      // TODO: warn user if `position` is missing and/or wait until it has loaded?
      if (position && onSelect) {
        onSelectPosition(position);
      }
    },
    [getPosition, onSelectPosition],
  );

  const onSelectCurrent = useCallback(() => {
    const position = getPosition(lastPositionKey);
    // TODO: warn user if `position` is missing and/or wait until it has loaded?
    if (position && onSelectPosition) {
      onSelectPosition(position);
    }
  }, [getPosition, lastPositionKey, onSelectPosition]);

  const onDelete = useCallback(
    eventKey => {
      if (onDeletePosition) {
        const position = getPosition(eventKey);
        onDeletePosition(position);
      }
    },
    [getPosition, onDeletePosition],
  );

  const onSave = useCallback(() => {
    const position: MapPosition = {
      lnglat,
      scale,
      pitch,
      bearing,
      name: null,
      key: null,
    };
    onSavePosition(position);
  }, [bearing, lnglat, onSavePosition, pitch, scale]);

  const title = useMemo(() => {
    const position = getPosition(lastPositionKey);
    let text: React.ReactNode = t`(nothing selected)`;
    if (position) {
      if (position === projectPosition) {
        text = t`Project Area`;
      } else {
        text = position.name;
      }
    }

    return (
      <span className={isCurrent ? colorStyles.primary : null}>{text}</span>
    );
  }, [getPosition, isCurrent, lastPositionKey, projectPosition]);

  const menu = (
    <PositionMenu
      lastPositionKey={lastPositionKey}
      projectPosition={projectPosition}
      positions={positions}
      onSelectPosition={onSelect}
      onSavePosition={onSave}
      onDeletePosition={onDelete}
      isCurrent={isCurrent}
      lnglat={lnglat}
      scale={scale}
      pitch={pitch}
      bearing={bearing}
    />
  );

  const iconColorClass = isCurrent
    ? colorStyles.primary
    : colorStyles.disabledText;

  const iconClassName = iconColorClass;
  const icon = isCurrent ? (
    <Icon
      path={mdiCrosshairsGps}
      className={iconClassName}
      size={IconSizeNew.SMALL}
    />
  ) : (
    <Icon
      path={mdiCrosshairs}
      className={iconClassName}
      size={IconSizeNew.SMALL}
    />
  );
  return (
    <ButtonGroup className={Classes.TEXT_OVERFLOW_ELLIPSIS}>
      <Button
        onClick={onSelectCurrent}
        small={small}
        minimal={minimal}
        className={classNames(className, 'MapExtentsPicker-zoom-to-area')}
        loading={loading}
        icon={icon}
        text={title}
      />
      <Popover content={menu} position={Position.BOTTOM_RIGHT}>
        <Tooltip content={t`Saved map positions`}>
          <Button
            small={small}
            minimal={minimal}
            className={className}
            icon={<Icon path={mdiMenuDown} size={IconSizeNew.SMALL} />}
          />
        </Tooltip>
      </Popover>
    </ButtonGroup>
  );
};

MapExtentsPicker.defaultProps = {
  positions: [],
  lnglat: null,
  currentPositionKey: null,
};
export default MapExtentsPicker;

interface MenuProps {
  projectPosition: MapPosition;
  positions: MapPosition[];
  lastPositionKey: string;
  onSelectPosition: (eventKey: string) => void;
  onSavePosition: () => void;
  onDeletePosition: (eventKey: string) => void;

  isCurrent: boolean;
  lnglat: LngLat;
  scale?: number;

  pitch: number;
  bearing: number;
}

const PositionMenu: React.FunctionComponent<MenuProps> = props => {
  const {
    projectPosition,
    lastPositionKey,
    positions,
    isCurrent,
    lnglat,
    scale,
    bearing,
    pitch,
    onSelectPosition,
    onSavePosition,
    onDeletePosition,
  } = props;
  const onSelect = useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      let node = event.target as HTMLElement;
      while (node !== event.currentTarget.parentElement) {
        const { dataset } = node;

        const { action, key } = dataset;
        if (action === 'delete') {
          onDeletePosition(key);
          return;
        }

        if (action === 'select') {
          onSelectPosition(key);
          return;
        }
        node = node.parentElement;
      }
    },
    [onDeletePosition, onSelectPosition],
  );

  const havePositions = positions && !!positions.length;
  const projectCheckedIcon =
    isCurrent && projectPosition.key === lastPositionKey ? checked : notChecked;
  const debugPosition =
    __DEVELOPMENT__ && !__TESTING__ && lnglat ? (
      <React.Fragment>
        <li className={Classes.MENU_HEADER}>
          {formatLatLng(lnglat)} @ 1:{scale}
        </li>
        <li className={Classes.MENU_HEADER}>
          Pitch: {pitch}
          {'\u00b0'} Bearing: {bearing}
          {'\u00b0'}
        </li>
      </React.Fragment>
    ) : null;

  const onSelectProjectArea = useCallback(
    () => onSelectPosition(projectPosition.key),
    [onSelectPosition, projectPosition.key],
  );
  return (
    <Menu>
      {projectPosition && (
        <MenuItem
          shouldDismissPopover={false}
          icon={projectCheckedIcon}
          onClick={onSelectProjectArea}
          text={t`Project Area`}
        />
      )}

      {havePositions && (
        <React.Fragment>
          <li className={Classes.MENU_HEADER}>
            <H6>{t`Saved map positions`}</H6>
          </li>
          {positions.map(position => (
            <MenuItem
              key={position.key}
              shouldDismissPopover={false}
              icon={
                isCurrent && position.key === lastPositionKey
                  ? checked
                  : notChecked
              }
              className={menuItemClassName}
              labelElement={
                <Button
                  small
                  minimal
                  data-action="delete"
                  data-key={position.key}
                  icon={
                    <Icon
                      className={styles.trash}
                      path={mdiTrashCan}
                      size={IconSizeNew.SMALL}
                    />
                  }
                />
              }
              data-action="select"
              data-key={position.key}
              onClick={onSelect}
              text={position.name}
            />
          ))}
          <MenuDivider />
        </React.Fragment>
      )}
      <MenuItem
        icon={<Icon path={mdiContentSave} size={IconSizeNew.EXTRA_SMALL} />}
        onClick={onSavePosition}
        text={t`Save current position`}>
        {debugPosition}
      </MenuItem>
    </Menu>
  );
};
