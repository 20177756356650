import {
  CuratedColumnSymbology,
  CUSTOM_THEME,
  DEFAULT_OPACITY,
  InterpolationTypes,
  PaintColorTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/public/dataset/sonoma_bus_routes': {
    routename: [
      {
        type: SymbologyTypes.LINE,
        paint: {
          'line-color': {
            property: 'routename',
            type: PaintColorTypes.CATEGORICAL,
            stops: [
              { value: 'ROUTE 10', color: '#1f77b4' },
              { value: 'ROUTE 12', color: '#aec7e8' },
              { value: 'ROUTE 14', color: '#ff7f0e' },
              { value: 'ROUTE 20', color: '#ffbb78' },
              { value: 'ROUTE 22', color: '#2ca02c' },
              { value: 'ROUTE 24', color: '#98df8a' },
              { value: 'ROUTE 26', color: '#d62728' },
              { value: 'ROUTE 28', color: '#ff9896' },
              { value: 'ROUTE 30', color: '#9467bd' },
              { value: 'ROUTE 32', color: '#c5b0d5' },
              { value: 'ROUTE 34', color: '#8c564b' },
              { value: 'ROUTE 38', color: '#c49c94' },
              { value: 'ROUTE 40', color: '#e377c2' },
              { value: 'ROUTE 42', color: '#f7b6d2' },
              { value: 'ROUTE 44', color: '#7f7f7f' },
              { value: 'ROUTE 46', color: '#c7c7c7' },
              { value: 'ROUTE 48', color: '#bcbd22' },
              { value: 'ROUTE 50', color: '#dbdb8d' },
              { value: 'ROUTE 60', color: '#17becf' },
              { value: 'ROUTE 62', color: '#9edae5' },
              { value: 'ROUTE 66', color: '#1f77b4' },
              { value: 'ROUTE 68', color: '#aec7e8' },
            ],
          },
          'line-opacity': DEFAULT_OPACITY,
        },
        display_hints: {
          scale: SymbologyScales.CATEGORICAL,
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          theme: CUSTOM_THEME,
        },
      },
    ],
  },
};

export default symbologies;
