import {
  CuratedColumnSymbology,
  InterpolationTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/public/dataset/city_of_arlington_zoning_districts_arlington_tx': {
    district: [
      {
        display_hints: {
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          scale: SymbologyScales.CATEGORICAL,
          theme: 'custom',
        },
        paint: {
          'fill-color': {
            default: '#1f77b4',
            property: 'district',
            stops: [
              {
                color: '#1f77b4',
                value: 'CC',
              },
              {
                color: '#aec7e8',
                value: 'DB',
              },
              {
                color: '#808080',
                value: 'ETJ',
              },
              {
                color: '#ffbb78',
                value: 'GC',
              },
              {
                color: '#2ca02c',
                value: 'IM',
              },
              {
                color: '#98df8a',
                value: 'LI',
              },
              {
                color: '#d62728',
                value: 'LO',
              },
              {
                color: '#fe9200',
                value: 'MH',
              },
              {
                color: '#9467bd',
                value: 'NC',
              },
              {
                color: '#c5b0d5',
                value: 'OC',
              },
              {
                color: '#808080',
                value: 'PD',
              },
              {
                color: '#fcdc00',
                value: 'RE',
              },
              {
                color: '#fb9e00',
                value: 'RM-12',
              },
              {
                color: '#e27300',
                value: 'RMF-22',
              },
              {
                color: '#fcdc00',
                value: 'RS-15',
              },
              {
                color: '#fcdc00',
                value: 'RS-20',
              },
              {
                color: '#fcdc00',
                value: 'RS-5',
              },
              {
                color: '#fcdc00',
                value: 'RS-7.2',
              },
              {
                color: '#17becf',
                value: 'UTA',
              },
              {
                color: '#9edae5',
                value: 'VG',
              },
            ],
            type: 'categorical',
          },
          'fill-opacity': 0.9,
        },
        type: SymbologyTypes.FILL,
      },
      {
        display_hints: {
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          scale: SymbologyScales.CATEGORICAL,
          theme: 'custom',
        },
        paint: {
          'line-color': '#999999',
          'line-opacity': 0.9,
          'line-width': 0.5,
        },
        type: SymbologyTypes.LINE,
      },
    ],
    zoningdeta: [
      {
        display_hints: {
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          scale: SymbologyScales.CATEGORICAL,
          theme: 'custom',
        },
        paint: {
          'fill-color': {
            default: '#aec7e8',
            property: 'zoningdeta',
            stops: [
              {
                color: '#1f77b4',
                value: 'APO-CC',
              },
              {
                color: '#aec7e8',
                value: 'APO-GC',
              },
              {
                color: '#ff7f0e',
                value: 'APO-IM',
              },
              {
                color: '#ffbb78',
                value: 'APO-LI',
              },
              {
                color: '#2ca02c',
                value: 'APO-NC',
              },
              {
                color: '#98df8a',
                value: 'APO-OC',
              },
              {
                color: '#d62728',
                value: 'APO-PD',
              },
              {
                color: '#ff9896',
                value: 'APO-RE',
              },
              {
                color: '#9467bd',
                value: 'APO-RM-12',
              },
              {
                color: '#c5b0d5',
                value: 'APO-RMF-22',
              },
              {
                color: '#8c564b',
                value: 'APO-RS-5',
              },
              {
                color: '#c49c94',
                value: 'APO-RS-7.2',
              },
              {
                color: '#e377c2',
                value: 'BP-PD',
              },
              {
                color: '#f7b6d2',
                value: 'CC',
              },
              {
                color: '#7f7f7f',
                value: 'CDO-RS-7.2',
              },
              {
                color: '#c7c7c7',
                value: 'DB',
              },
              {
                color: '#bcbd22',
                value: 'DNO-CC',
              },
              {
                color: '#dbdb8d',
                value: 'DNO-GC',
              },
              {
                color: '#17becf',
                value: 'DNO-LI',
              },
              {
                color: '#9edae5',
                value: 'DNO-LO',
              },
              {
                color: '#1f77b4',
                value: 'DNO-LPO-NC',
              },
              {
                color: '#aec7e8',
                value: 'DNO-LPO-RM-12',
              },
              {
                color: '#ff7f0e',
                value: 'DNO-NC',
              },
              {
                color: '#ffbb78',
                value: 'DNO-OC',
              },
              {
                color: '#2ca02c',
                value: 'DNO-PD',
              },
              {
                color: '#98df8a',
                value: 'DNO-PD-DB',
              },
              {
                color: '#d62728',
                value: 'DNO-PD-RMF-22',
              },
              {
                color: '#ff9896',
                value: 'DNO-RM-12',
              },
              {
                color: '#9467bd',
                value: 'DNO-RMF-22',
              },
              {
                color: '#c5b0d5',
                value: 'DNO-RS-7.2',
              },
              {
                color: '#8c564b',
                value: 'EDO-CC',
              },
              {
                color: '#c49c94',
                value: 'EDO-GC',
              },
              {
                color: '#e377c2',
                value: 'EDO-IM',
              },
              {
                color: '#f7b6d2',
                value: 'EDO-LI',
              },
              {
                color: '#7f7f7f',
                value: 'EDO-NC',
              },
              {
                color: '#c7c7c7',
                value: 'EDO-OC',
              },
              {
                color: '#bcbd22',
                value: 'EDO-PD',
              },
              {
                color: '#dbdb8d',
                value: 'EDO-RM-12',
              },
              {
                color: '#17becf',
                value: 'EDO-RMF-22',
              },
              {
                color: '#9edae5',
                value: 'EDO-RS-7.2',
              },
              {
                color: '#1f77b4',
                value: 'ETJ',
              },
              {
                color: '#aec7e8',
                value: 'GC',
              },
              {
                color: '#ff7f0e',
                value: 'IM',
              },
              {
                color: '#ffbb78',
                value: 'LCMUO-CC',
              },
              {
                color: '#2ca02c',
                value: 'LCMUO-GC',
              },
              {
                color: '#98df8a',
                value: 'LCMUO-PD',
              },
              {
                color: '#d62728',
                value: 'LCMUO-RMF-22',
              },
              {
                color: '#ff9896',
                value: 'LI',
              },
              {
                color: '#9467bd',
                value: 'LO',
              },
              {
                color: '#c5b0d5',
                value: 'LPO-CC',
              },
              {
                color: '#8c564b',
                value: 'LPO-DB',
              },
              {
                color: '#c49c94',
                value: 'LPO-NC',
              },
              {
                color: '#e377c2',
                value: 'LPO-PD',
              },
              {
                color: '#f7b6d2',
                value: 'LPO-RM-12',
              },
              {
                color: '#7f7f7f',
                value: 'LPO-RS-7.2',
              },
              {
                color: '#c7c7c7',
                value: 'MH',
              },
              {
                color: '#bcbd22',
                value: 'NC',
              },
              {
                color: '#dbdb8d',
                value: 'OC',
              },
              {
                color: '#d33115',
                value: 'PD',
              },
              {
                color: '#9edae5',
                value: 'PD-CA',
              },
              {
                color: '#1f77b4',
                value: 'PD-CC',
              },
              {
                color: '#aec7e8',
                value: 'PD-DB',
              },
              {
                color: '#ff7f0e',
                value: 'PD-DNO-RMF-22',
              },
              {
                color: '#ffbb78',
                value: 'PD-EDO-CC',
              },
              {
                color: '#2ca02c',
                value: 'PD-LI',
              },
              {
                color: '#98df8a',
                value: 'PD-RM-12',
              },
              {
                color: '#d62728',
                value: 'PD-RM-12-NC',
              },
              {
                color: '#ff9896',
                value: 'PD-RMF-22',
              },
              {
                color: '#9467bd',
                value: 'PD-RS-15',
              },
              {
                color: '#c5b0d5',
                value: 'PD-RS-5',
              },
              {
                color: '#8c564b',
                value: 'PD-RS-5-CC',
              },
              {
                color: '#c49c94',
                value: 'PD-RS-7.2',
              },
              {
                color: '#e377c2',
                value: 'RE',
              },
              {
                color: '#f7b6d2',
                value: 'RM-12',
              },
              {
                color: '#7f7f7f',
                value: 'RMF-22',
              },
              {
                color: '#fcdc00',
                value: 'RS-15',
              },
              {
                color: '#fcdc00',
                value: 'RS-20',
              },
              {
                color: '#fcdc00',
                value: 'RS-5',
              },
              {
                color: '#fcdc00',
                value: 'RS-7.2',
              },
              {
                color: '#9edae5',
                value: 'UTA',
              },
              {
                color: '#1f77b4',
                value: 'VG',
              },
            ],
            type: 'categorical',
          },
          'fill-opacity': 0.9,
        },
        type: SymbologyTypes.FILL,
      },
      {
        display_hints: {
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          scale: SymbologyScales.CATEGORICAL,
          theme: 'custom',
        },
        paint: {
          'line-color': '#999999',
          'line-opacity': 0.9,
          'line-width': 0.5,
        },
        type: SymbologyTypes.LINE,
      },
    ],
  },
};

export default symbologies;
