import colors from 'material-colors';

import {
  CuratedColumnSymbology,
  CUSTOM_THEME,
  DEFAULT_OPACITY,
  InterpolationTypes,
  PaintColorTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/public/dataset/us_colleges_and_universities': {
    naics_desc: [
      {
        type: SymbologyTypes.CIRCLE,
        paint: {
          'circle-radius': 3,
          'circle-color': {
            property: 'naics_desc',
            type: PaintColorTypes.CATEGORICAL,
            stops: [
              {
                value: 'BUSINESS AND SECRETARIAL SCHOOLS',
                label: 'Business and Secretarial Schools',
                color: colors.purple[200],
              },
              {
                value: 'COLLEGES, UNIVERSITIES, AND PROFESSIONAL SCHOOLS',
                label: 'Colleges, Universities, and Professional Schools',
                color: colors.indigo[200],
              },
              {
                value: 'COMPUTER TRAINING',
                label: 'Computer Training',
                color: colors.lightBlue[200],
              },
              {
                value: 'COSMETOLOGY AND BARBER SCHOOLS',
                label: 'Cosmetology and Barber Schools',
                color: colors.teal[200],
              },
              {
                value: 'EDUCATIONAL SUPPORT SERVICES',
                label: 'Educational Support Services',
                color: colors.lightGreen[200],
              },
              {
                value: 'FINE ARTS SCHOOLS',
                label: 'Fine Arts Schools',
                color: colors.yellow[200],
              },
              {
                value: 'FLIGHT TRAINING',
                label: 'Flight Training',
                color: colors.orange[200],
              },
              {
                value: 'JUNIOR COLLEGE',
                label: 'Junior College',
                color: colors.deepOrange[200],
              },
              {
                value: 'OTHER TECHNICAL AND TRADE SCHOOLS',
                label: 'Other Technical and Trade Schools',
                color: colors.pink[200],
              },
            ],
          },
          'circle-opacity': DEFAULT_OPACITY,
        },
        display_hints: {
          scale: SymbologyScales.CATEGORICAL,
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          theme: CUSTOM_THEME,
        },
      },
    ],
  },
};

export default symbologies;
