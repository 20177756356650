/**
 * UrbanFootprint App API
 * API for serving UrbanFootprint Application
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { ClientResponse } from '../variables';

// @ts-ignore: This is used only for params, but some services have param-free APIs
import { DashboardServiceInterface } from './dashboard.serviceInterface';

// @ts-ignore: These imports are for both params and returnType, but we only use returnType
import { Dashboard } from '../model/dashboard';
// @ts-ignore: These imports are for both params and returnType, but we only use returnType
import { EmbeddedDashboardResult } from '../model/embeddedDashboardResult';
// @ts-ignore: These imports are for both params and returnType, but we only use returnType
import { EmbeddedTopicResult } from '../model/embeddedTopicResult';
// @ts-ignore: These imports are for both params and returnType, but we only use returnType
import { ProblemDetail } from '../model/problemDetail';
// @ts-ignore: These imports are for both params and returnType, but we only use returnType
import { ProjectDashboard } from '../model/projectDashboard';

/**
 * Package: api
 */

const deleteOrgDashboardPrefix = `uf-api/dashboard/delete_org_dashboard/`;
/**
 * Action types for dashboard.delete_org_dashboard
 *
 * Soft delete a dashboard id for an org.
 */
export const deleteOrgDashboardActionTypes = {
  LOAD: `${deleteOrgDashboardPrefix}LOAD` as 'uf-api/dashboard/delete_org_dashboard/LOAD',
  SUCCESS:
    `${deleteOrgDashboardPrefix}SUCCESS` as 'uf-api/dashboard/delete_org_dashboard/SUCCESS',
  FAILURE:
    `${deleteOrgDashboardPrefix}FAILURE` as 'uf-api/dashboard/delete_org_dashboard/FAILURE',
  CLEAR:
    `${deleteOrgDashboardPrefix}CLEAR` as 'uf-api/dashboard/delete_org_dashboard/CLEAR',
} as const;

/**
 * LOAD action for dashboard.delete_org_dashboard
 * Soft delete a dashboard id for an org.
 */
export interface deleteOrgDashboardLoadAction {
  readonly type: 'uf-api/dashboard/delete_org_dashboard/LOAD';
  readonly params: DashboardServiceInterface.deleteOrgDashboardParams;
  readonly promise: Promise<ClientResponse<{}>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for dashboard.delete_org_dashboard
 * Soft delete a dashboard id for an org.
 */
export interface deleteOrgDashboardSuccessAction {
  readonly type: 'uf-api/dashboard/delete_org_dashboard/SUCCESS';
  readonly result: {};
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for dashboard.delete_org_dashboard
 * Soft delete a dashboard id for an org.
 */
export interface deleteOrgDashboardFailureAction {
  readonly type: 'uf-api/dashboard/delete_org_dashboard/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for dashboard.delete_org_dashboard
 * Soft delete a dashboard id for an org.
 */
export interface deleteOrgDashboardClearAction {
  readonly type: 'uf-api/dashboard/delete_org_dashboard/CLEAR';
  readonly key: string;
}

const getDashboardEmbedPrefix = `uf-api/dashboard/get_dashboard_embed/`;
/**
 * Action types for dashboard.get_dashboard_embed
 *
 * Return a dashboard embed result
 */
export const getDashboardEmbedActionTypes = {
  LOAD: `${getDashboardEmbedPrefix}LOAD` as 'uf-api/dashboard/get_dashboard_embed/LOAD',
  SUCCESS:
    `${getDashboardEmbedPrefix}SUCCESS` as 'uf-api/dashboard/get_dashboard_embed/SUCCESS',
  FAILURE:
    `${getDashboardEmbedPrefix}FAILURE` as 'uf-api/dashboard/get_dashboard_embed/FAILURE',
  CLEAR:
    `${getDashboardEmbedPrefix}CLEAR` as 'uf-api/dashboard/get_dashboard_embed/CLEAR',
} as const;

/**
 * LOAD action for dashboard.get_dashboard_embed
 * Return a dashboard embed result
 */
export interface getDashboardEmbedLoadAction {
  readonly type: 'uf-api/dashboard/get_dashboard_embed/LOAD';
  readonly params: DashboardServiceInterface.getDashboardEmbedParams;
  readonly promise: Promise<ClientResponse<EmbeddedDashboardResult>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for dashboard.get_dashboard_embed
 * Return a dashboard embed result
 */
export interface getDashboardEmbedSuccessAction {
  readonly type: 'uf-api/dashboard/get_dashboard_embed/SUCCESS';
  readonly result: EmbeddedDashboardResult;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for dashboard.get_dashboard_embed
 * Return a dashboard embed result
 */
export interface getDashboardEmbedFailureAction {
  readonly type: 'uf-api/dashboard/get_dashboard_embed/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for dashboard.get_dashboard_embed
 * Return a dashboard embed result
 */
export interface getDashboardEmbedClearAction {
  readonly type: 'uf-api/dashboard/get_dashboard_embed/CLEAR';
  readonly key: string;
}

const getOrgDashboardsPrefix = `uf-api/dashboard/get_org_dashboards/`;
/**
 * Action types for dashboard.get_org_dashboards
 *
 * Return a list of dashboards associated with projects in org
 */
export const getOrgDashboardsActionTypes = {
  LOAD: `${getOrgDashboardsPrefix}LOAD` as 'uf-api/dashboard/get_org_dashboards/LOAD',
  SUCCESS:
    `${getOrgDashboardsPrefix}SUCCESS` as 'uf-api/dashboard/get_org_dashboards/SUCCESS',
  FAILURE:
    `${getOrgDashboardsPrefix}FAILURE` as 'uf-api/dashboard/get_org_dashboards/FAILURE',
  CLEAR:
    `${getOrgDashboardsPrefix}CLEAR` as 'uf-api/dashboard/get_org_dashboards/CLEAR',
} as const;

/**
 * LOAD action for dashboard.get_org_dashboards
 * Return a list of dashboards associated with projects in org
 */
export interface getOrgDashboardsLoadAction {
  readonly type: 'uf-api/dashboard/get_org_dashboards/LOAD';
  readonly params: DashboardServiceInterface.getOrgDashboardsParams;
  readonly promise: Promise<ClientResponse<Array<ProjectDashboard>>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for dashboard.get_org_dashboards
 * Return a list of dashboards associated with projects in org
 */
export interface getOrgDashboardsSuccessAction {
  readonly type: 'uf-api/dashboard/get_org_dashboards/SUCCESS';
  readonly result: Array<ProjectDashboard>;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for dashboard.get_org_dashboards
 * Return a list of dashboards associated with projects in org
 */
export interface getOrgDashboardsFailureAction {
  readonly type: 'uf-api/dashboard/get_org_dashboards/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for dashboard.get_org_dashboards
 * Return a list of dashboards associated with projects in org
 */
export interface getOrgDashboardsClearAction {
  readonly type: 'uf-api/dashboard/get_org_dashboards/CLEAR';
  readonly key: string;
}

const getTopicEmbedPrefix = `uf-api/dashboard/get_topic_embed/`;
/**
 * Action types for dashboard.get_topic_embed
 *
 * Return a topic embed result
 */
export const getTopicEmbedActionTypes = {
  LOAD: `${getTopicEmbedPrefix}LOAD` as 'uf-api/dashboard/get_topic_embed/LOAD',
  SUCCESS:
    `${getTopicEmbedPrefix}SUCCESS` as 'uf-api/dashboard/get_topic_embed/SUCCESS',
  FAILURE:
    `${getTopicEmbedPrefix}FAILURE` as 'uf-api/dashboard/get_topic_embed/FAILURE',
  CLEAR:
    `${getTopicEmbedPrefix}CLEAR` as 'uf-api/dashboard/get_topic_embed/CLEAR',
} as const;

/**
 * LOAD action for dashboard.get_topic_embed
 * Return a topic embed result
 */
export interface getTopicEmbedLoadAction {
  readonly type: 'uf-api/dashboard/get_topic_embed/LOAD';
  readonly params: DashboardServiceInterface.getTopicEmbedParams;
  readonly promise: Promise<ClientResponse<EmbeddedTopicResult>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for dashboard.get_topic_embed
 * Return a topic embed result
 */
export interface getTopicEmbedSuccessAction {
  readonly type: 'uf-api/dashboard/get_topic_embed/SUCCESS';
  readonly result: EmbeddedTopicResult;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for dashboard.get_topic_embed
 * Return a topic embed result
 */
export interface getTopicEmbedFailureAction {
  readonly type: 'uf-api/dashboard/get_topic_embed/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for dashboard.get_topic_embed
 * Return a topic embed result
 */
export interface getTopicEmbedClearAction {
  readonly type: 'uf-api/dashboard/get_topic_embed/CLEAR';
  readonly key: string;
}

const updateOrgDashboardPrefix = `uf-api/dashboard/update_org_dashboard/`;
/**
 * Action types for dashboard.update_org_dashboard
 *
 * Update the dashboard name for a dashboard id and org.
 */
export const updateOrgDashboardActionTypes = {
  LOAD: `${updateOrgDashboardPrefix}LOAD` as 'uf-api/dashboard/update_org_dashboard/LOAD',
  SUCCESS:
    `${updateOrgDashboardPrefix}SUCCESS` as 'uf-api/dashboard/update_org_dashboard/SUCCESS',
  FAILURE:
    `${updateOrgDashboardPrefix}FAILURE` as 'uf-api/dashboard/update_org_dashboard/FAILURE',
  CLEAR:
    `${updateOrgDashboardPrefix}CLEAR` as 'uf-api/dashboard/update_org_dashboard/CLEAR',
} as const;

/**
 * LOAD action for dashboard.update_org_dashboard
 * Update the dashboard name for a dashboard id and org.
 */
export interface updateOrgDashboardLoadAction {
  readonly type: 'uf-api/dashboard/update_org_dashboard/LOAD';
  readonly params: DashboardServiceInterface.updateOrgDashboardParams;
  readonly promise: Promise<ClientResponse<{}>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for dashboard.update_org_dashboard
 * Update the dashboard name for a dashboard id and org.
 */
export interface updateOrgDashboardSuccessAction {
  readonly type: 'uf-api/dashboard/update_org_dashboard/SUCCESS';
  readonly result: {};
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for dashboard.update_org_dashboard
 * Update the dashboard name for a dashboard id and org.
 */
export interface updateOrgDashboardFailureAction {
  readonly type: 'uf-api/dashboard/update_org_dashboard/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for dashboard.update_org_dashboard
 * Update the dashboard name for a dashboard id and org.
 */
export interface updateOrgDashboardClearAction {
  readonly type: 'uf-api/dashboard/update_org_dashboard/CLEAR';
  readonly key: string;
}

export const dashboardActionTypesMap = {
  /** Action types for dashboard.delete_org_dashboard */
  delete_org_dashboard: deleteOrgDashboardActionTypes,
  /** Action types for dashboard.get_dashboard_embed */
  get_dashboard_embed: getDashboardEmbedActionTypes,
  /** Action types for dashboard.get_org_dashboards */
  get_org_dashboards: getOrgDashboardsActionTypes,
  /** Action types for dashboard.get_topic_embed */
  get_topic_embed: getTopicEmbedActionTypes,
  /** Action types for dashboard.update_org_dashboard */
  update_org_dashboard: updateOrgDashboardActionTypes,
} as const;

export const dashboardDetailsMap = {
  delete_org_dashboard: {
    /** HTTP method for dashboard.delete_org_dashboard */
    httpMethod: 'delete' as const,
  },
  get_dashboard_embed: {
    /** HTTP method for dashboard.get_dashboard_embed */
    httpMethod: 'get' as const,
  },
  get_org_dashboards: {
    /** HTTP method for dashboard.get_org_dashboards */
    httpMethod: 'get' as const,
  },
  get_topic_embed: {
    /** HTTP method for dashboard.get_topic_embed */
    httpMethod: 'get' as const,
  },
  update_org_dashboard: {
    /** HTTP method for dashboard.update_org_dashboard */
    httpMethod: 'put' as const,
  },
} as const;

/**
 * Local DataState<T> - kept in sync with uf/data/dataState.ts
 */
interface DataState<T> {
  readonly loading: boolean;
  readonly loaded: boolean;
  readonly requestId: string;
  readonly requestTime?: string;
  readonly responseTime?: string;
  readonly requestCount?: number;
  readonly error?: any;
  readonly promise?: Promise<T>;
  readonly data?: T;
  readonly extra?: any;
  readonly key?: string;
}
export interface DashboardServiceState {
  /** deleteOrgDashboard state, by key */
  delete_org_dashboard: {
    readonly [key: string]: DataState<{}>;
  };
  /** getDashboardEmbed state, by key */
  get_dashboard_embed: {
    readonly [key: string]: DataState<EmbeddedDashboardResult>;
  };
  /** getOrgDashboards state, by key */
  get_org_dashboards: {
    readonly [key: string]: DataState<Array<ProjectDashboard>>;
  };
  /** getTopicEmbed state, by key */
  get_topic_embed: {
    readonly [key: string]: DataState<EmbeddedTopicResult>;
  };
  /** all recent updateOrgDashboard results (POST/PUT/PATCH) */
  readonly update_org_dashboard: DataState<{}>[];
}
