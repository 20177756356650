import { createSelector } from 'reselect';

import { EMPTY_ARRAY } from 'uf/base';
import { cacheableConcat } from 'uf/base/array';
import { BUILT_FORM_TYPES, BuiltFormInfo } from 'uf/builtforms';
import {
  getBuiltFormInfosFromColorStops,
  updateBuiltFormInfoColors,
} from 'uf/builtforms-symbology/builtforms';
import { makeGetBuiltFormsListStateGetter } from 'uf/builtforms/selectors';
import { getData } from 'uf/data/dataState';
import { ColumnKey, LayerId } from 'uf/layers';
import { ProjectId } from 'uf/projects';
import { makeGetProjectBuiltFormsLibraryIdGetter } from 'uf/projects/selectors';
import { LegacyVirtualLayerId } from 'uf/projects/virtualLayers';
import { ViewId } from 'uf/views';
import { makeGetColorStopEditsGetter } from 'uf/views/selectors/symbology/colorStopEdits';

/**
 * Get the library built form infos that have colors in their symbology that
 * differ from what's in the library. This allows us update the built form
 * library colors when the user edits symbology.
 */
export type GetBuiltFormInfosWithColorEdits = (
  projectId: ProjectId,
  viewId: ViewId,
  layerId: LayerId,
  virtualLayerId: LegacyVirtualLayerId,
  columnKey: ColumnKey,
  builtFormType: BUILT_FORM_TYPES,
) => BuiltFormInfo[];

export function makeGetBuiltFormInfosWithColorEditsGetter() {
  return createSelector(
    makeGetColorStopEditsGetter(),
    makeGetProjectBuiltFormsLibraryIdGetter(),
    makeGetBuiltFormsListStateGetter(),
    (
        getColorStopEdits,
        getProjectBuiltFormsLibraryId,
        getBuiltFormsListState,
      ): GetBuiltFormInfosWithColorEdits =>
      (
        projectId,
        viewId,
        layerId,
        virtualLayerId,
        columnKey,
        builtFormType,
      ) => {
        const colorStopEdits = getColorStopEdits(
          projectId,
          viewId,
          layerId,
          virtualLayerId,
          columnKey,
        );
        const libraryId = getProjectBuiltFormsLibraryId(projectId);
        const builtFormInfos = getBuiltFormsListState(libraryId, builtFormType);
        const matchingBuiltFormInfos = getBuiltFormInfosFromColorStops(
          getData(builtFormInfos, EMPTY_ARRAY),
          colorStopEdits,
          builtFormType,
        );
        const matchingBuiltFormInfosWithColorUpdate = updateBuiltFormInfoColors(
          matchingBuiltFormInfos,
          colorStopEdits,
          builtFormType,
        );

        return cacheableConcat(matchingBuiltFormInfosWithColorUpdate);
      },
  );
}
