import { ColumnKey, LayerId } from 'uf/layers';
import { CuratedColumnSymbology } from 'uf/symbology';

import alternative_fuel_stations_us from './alternative_fuel_stations_us';
import ambag_boundaries from './ambag_boundaries';
import ambag_land_use from './ambag_land_use';
import ambag_taz from './ambag_taz';
import average_electricity_price_by_state_2015 from './average_electricity_price_by_state_2015';
import average_gasoline_price_by_state_2016 from './average_gasoline_price_by_state_2016';
import average_natural_gas_price_by_state_2015 from './average_natural_gas_price_by_state_2015';
import bay_area_conservation_lands_network from './bay_area_conservation_lands_network';
import bay_area_critical_linkages from './bay_area_critical_linkages';
import bay_area_priority_conservation_areas from './bay_area_priority_conservation_areas';
import beaumont_general_plan_land_use from './beaumont_general_plan_land_use';
import boundary_agreements_cities_villages_2008 from './boundary_agreements_cities_villages_2008';
import building_characteristics_riverside_city from './building_characteristics_riverside_city';
import ca_powerplants from './ca_powerplants';
import cal_fire_cal_fire_administrative_boundaries from './cal_fire_cal_fire_administrative_boundaries';
import cal_fire_cal_fire_direct_protection_areas from './cal_fire_cal_fire_direct_protection_areas';
import cal_fire_cal_fire_facilities from './cal_fire_cal_fire_facilities';
import cal_fire_cal_fire_fire_hazard_reduction_projects from './cal_fire_cal_fire_fire_hazard_reduction_projects';
import cal_fire_cal_fire_priority_projects_n_05_19 from './cal_fire_cal_fire_priority_projects_n_05_19';
import cal_fire_fire_hazard_severity_zones___california_aggregated from './cal_fire_fire_hazard_severity_zones___california_aggregated';
import calfire_fire_hazard_severity_zones from './calfire_fire_hazard_severity_zones';
import calenviroscreen_3 from './calenviroscreen_3';
import calenviroscreen_3_draft_results from './calenviroscreen_3_draft_results';
import calenviroscreen_4 from './calenviroscreen_4';
import california_above_carbon_density from './california_above_carbon_density';
import california_active_river_areas from './california_active_river_areas';
import california_ag_production from './california_ag_production';
import california_ag_water_demand from './california_ag_water_demand';
import california_areas_of_critical_concern from './california_areas_of_critical_concern';
import california_audubon_important_bird_areas from './california_audubon_important_bird_areas';
import california_base_conservation from './california_base_conservation';
import california_carbon_density from './california_carbon_density';
import california_census_rates from './california_census_rates';
import california_combined_resistance from './california_combined_resistance';
import california_conservation_module_flood_plain_layer from './california_conservation_module_flood_plain_layer';
import california_cscd_current_schools_2016 from './california_cscd_current_schools_2016';
import california_drinking_water_source_watersheds from './california_drinking_water_source_watersheds';
import california_ecoregions_13 from './california_ecoregions_13';
import california_endangered_species from './california_endangered_species';
import california_essential_connectivity_areas from './california_essential_connectivity_areas';
import california_farm_mapping_monitoring_program from './california_farm_mapping_monitoring_program';
import california_fault_zone from './california_fault_zone';
import california_flood_plain_layer from './california_flood_plain_layer';
import california_gw_recharge from './california_gw_recharge';
import california_health_disadvantage_index from './california_health_disadvantage_index';
import california_hydrologic_regions from './california_hydrologic_regions';
import california_landslide_zone from './california_landslide_zone';
import california_liquefaction_zone from './california_liquefaction_zone';
import california_lqls_uneval_area from './california_lqls_uneval_area';
import california_nhdplus_catchments from './california_nhdplus_catchments';
import california_nhdplus_flowline_150m_buffer from './california_nhdplus_flowline_150m_buffer';
import california_priority_boundaries from './california_priority_boundaries';
import california_soc0_30 from './california_soc0_30';
import california_tnc_priority_areas from './california_tnc_priority_areas';
import california_usfs_subsections from './california_usfs_subsections';
import california_wetlands from './california_wetlands';
import caltrans_caltrans_district_boundaries from './caltrans_caltrans_district_boundaries';
import cdc_chronic_disease_health_prom from './cdc_chronic_disease_health_prom';
import cdc_usaleep_life_expectancy_estimates_usa_v2 from './cdc_usaleep_life_expectancy_estimates_usa_v2';
import census_blocks from './census_blocks';
import census_planning_database_cbg_us_2016 from './census_planning_database_cbg_us_2016';
import census_tiger_arealm from './census_tiger_arealm';
import census_tiger_military_installations from './census_tiger_military_installations';
import city_of_appleton_city_of_appleton_zoning from './city_of_appleton_city_of_appleton_zoning';
import city_of_arlington_zoning_districts_arlington_tx from './city_of_arlington_zoning_districts_arlington_tx';
import city_of_berkeley_zoning_districts_berkeley_ca from './city_of_berkeley_zoning_districts_berkeley_ca';
import city_of_fresno_plu from './city_of_fresno_plu';
import city_of_fresno_zoning from './city_of_fresno_zoning';
import city_of_madison_city_of_madison_zoning from './city_of_madison_city_of_madison_zoning';
import city_of_minneapolis_zoning_primary_minneapolis from './city_of_minneapolis_zoning_primary_minneapolis';
import city_of_philadelphia_zoning_base_districts_philadelphia from './city_of_philadelphia_zoning_base_districts_philadelphia';
import city_of_portland_zoning_portland_or from './city_of_portland_zoning_portland_or';
import city_of_san_jose_general_plan_san_jose from './city_of_san_jose_general_plan_san_jose';
import city_of_seattle_zoning_seattle from './city_of_seattle_zoning_seattle';
import city_of_somerville_building_footprints from './city_of_somerville_building_footprints';
import city_of_somerville_current_zoning from './city_of_somerville_current_zoning';
import city_of_somerville_green_line_ext from './city_of_somerville_green_line_ext';
import city_of_somerville_green_line_ext_stops from './city_of_somerville_green_line_ext_stops';
import city_of_somerville_open_space from './city_of_somerville_open_space';
import city_of_somerville_parcels from './city_of_somerville_parcels';
import city_of_somerville_proposed_zoning from './city_of_somerville_proposed_zoning';
import colorado_clear_creek_parcels from './colorado_clear_creek_parcels';
import colorado_evapotranspiration_canvas from './colorado_evapotranspiration_canvas';
import colorado_transit_lines from './colorado_transit_lines';
import corelogic_county_coverage_summary from './corelogic_county_coverage_summary';
import covina_metrolinksbline_ln_scag_2004_1 from './covina_metrolinksbline_ln_scag_2004_1';
import covina_parcels_2015 from './covina_parcels_2015';
import covina_parcels_tc from './covina_parcels_tc';
import cpad_holdings from './cpad_holdings';
import cpuc_hftd from './cpuc_hftd';
import dane_county_distance_references_modified_blocks from './dane_county_distance_references_modified_blocks';
import dane_county_subparcels_land_use from './dane_county_subparcels_land_use';
import dane_county_tax_parcels from './dane_county_tax_parcels';
import DefaultGeoColumnKeyPointSymbologies from './DefaultGeoColumnKeyPointSymbologies';
import denver_e_contour_lines from './denver_e_contour_lines';
import denver_w_contour_lines from './denver_w_contour_lines';
import egrid_data_by_power_plant_2016 from './egrid_data_by_power_plant_2016';
import egrid_data_by_state_2016 from './egrid_data_by_state_2016';
import egrid_data_by_subregion_2014 from './egrid_data_by_subregion_2014';
import elk_grove_land_use from './elk_grove_land_use';
import elk_grove_ph_outcomes_base from './elk_grove_ph_outcomes_base';
import elk_grove_vmt_outcomes_base from './elk_grove_vmt_outcomes_base';
import epa_facility_registry_service from './epa_facility_registry_service';
import epa_smart_location_blockgroup_2010 from './epa_smart_location_blockgroup_2010';
import eri_v1 from './eri';
import esri_us_parks_2019 from './esri_us_parks_2019';
import fema_national_flood_hazard_layer_areas from './fema_national_flood_hazard_layer_areas';
import florida_dot_aadt_w_truck_fl_2018 from './florida_dot_aadt_w_truck_fl_2018';
import florida_dot_bicycle_lanes_fl from './florida_dot_bicycle_lanes_fl';
import fsi from './fsi';
import fri from './fri';
import greeninfo_cced_2018 from './greeninfo_cced_2018';
import greeninfo_cpad_2018a from './greeninfo_cpad_2018a';
import greeninfo_california_protected_areas_database_2021a from './greeninfo_california_protected_areas_database_2021a';
import habitatcores20170305 from './habitatcores20170305';
import headstart_national_202210 from './headstart_national_202210';
import hifld_nursing_home from './hifld_nursing_home';
import hifld_us_colleges from './hifld_us_colleges';
import hifld_us_daycare_centers from './hifld_us_daycare_centers';
import hifld_us_private_schools from './hifld_us_private_schools';
import hifld_us_sup_colleges from './hifld_us_sup_colleges';
import holc_redlining_areas from './holc_redlining_areas';
import honolulu_land_use from './honolulu_land_use';
import hud_concentrated_areas_of_poverty_hud from './hud_concentrated_areas_of_poverty_hud';
import hud_env_health_hazard_idx from './hud_env_health_hazard_idx';
import hud_fair_market_rents from './hud_fair_market_rents';
import hud_fair_market_rents_2019 from './hud_fair_market_rents_2019';
import hud_low_poverty_idx from './hud_low_poverty_idx';
import hud_low_trans_cost_idx from './hud_low_trans_cost_idx';
import hud_public_housing_buildings from './hud_public_housing_buildings';
import hud_recaps from './hud_recaps';
import invasiveplantcurrent from './invasiveplantcurrent';
import irrigation_factor_2010 from './irrigation_factor_2010';
import land_use from './land_use';
import land_value_riverside_city from './land_value_riverside_city';
import latch_survey_by_census_tract from './latch_survey_by_census_tract';
import lcpconnectors20170305 from './lcpconnectors20170305';
import low_povert_index from './low_poverty_index';
import madison_activity_centers from './madison_activity_centers';
import madison_boundary_area from './madison_boundary_area';
import madison_brt_routes_s2 from './madison_brt_routes_s2';
import madison_brt_routes_s3 from './madison_brt_routes_s3';
import madison_census_rates from './madison_census_rates';
import madison_city_town_village_boundaries from './madison_city_town_village_boundaries';
import madison_commuter_rail from './madison_commuter_rail';
import madison_core_habitat_buffer_540ft_town from './madison_core_habitat_buffer_540ft_town';
import madison_county_boundary from './madison_county_boundary';
import madison_county_conservation_easements from './madison_county_conservation_easements';
import madison_county_natural_resource_areas from './madison_county_natural_resource_areas';
import madison_county_parks from './madison_county_parks';
import madison_county_wildlife_areas from './madison_county_wildlife_areas';
import madison_energy_use_base from './madison_energy_use_base';
import madison_future_land_use_update_2018_comp_plan from './madison_future_land_use_update_2018_comp_plan';
import madison_historic_districts from './madison_historic_districts';
import madison_local_landmarks from './madison_local_landmarks';
import madison_ph_outcomes_base from './madison_ph_outcomes_base';
import madison_urban_service_areas from './madison_urban_service_areas';
import madison_vmt_outcomes_base from './madison_vmt_outcomes_base';
import madison_water_use_base from './madison_water_use_base';
import mbta_transit_lines_greater_boston from './mbta_transit_lines_greater_boston';
import mbta_transit_stops_greater_boston from './mbta_transit_stops_greater_boston';
import morpc_base_zoning_districts from './morpc_base_zoning_districts';
import morpc_commercial_zoning_overlays from './morpc_commercial_zoning_overlays';
import morpc_mtp16_gridtazforecast_lu_gridtaz_official2016mtp_forecasts from './morpc_mtp16_gridtazforecast_lu_gridtaz_official2016mtp_forecasts';
import morpc_recommended_land_use from './morpc_recommended_land_use';
import national_commercial_building_energy_use_rate from './national_commercial_building_energy_use_rate';
import national_electric_power_sales_by_state from './national_electric_power_sales_by_state';
import national_energy_consumption_calibration_factors_by_state from './national_energy_consumption_calibration_factors_by_state';
import national_evapotranspiration_canvas_2014 from './national_evapotranspiration_canvas_2014';
import national_registry_of_historic_places from './national_registry_of_historic_places';
import national_residential_energy_use_rate from './national_residential_energy_use_rate';
import national_transit_lines from './national_transit_lines';
import national_transit_lines_2018_q4 from './national_transit_lines_2018_q4';
import nhd_area from './nhd_area';
import nhd_waterbody from './nhd_waterbody';
import noaa_marine_protected_areas from './noaa_marine_protected_areas';
import nwi_wetlands from './nwi_wetlands';
import odlcd_urban_growth_boundaries from './odlcd_urban_growth_boundaries';
import osm_building_footprints from './osm_building_footprints';
import parcels from './parcels';
import phasocal_california_healthy_places_index from './phasocal_california_healthy_places_index';
import protected_areas_database_combined from './protected_areas_database_combined';
import protected_areas_database_easements from './protected_areas_database_easements';
import public_school_characteristics from './public_school_characteristics';
import sac_cnty_light_rail from './sac_cnty_light_rail';
import sac_cnty_sasd_boundary from './sac_cnty_sasd_boundary';
import sac_cnty_school_districts from './sac_cnty_school_districts';
import sac_cnty_streams from './sac_cnty_streams';
import sac_cnty_urban_service_boundary from './sac_cnty_urban_service_boundary';
import sac_cnty_vernal_pools from './sac_cnty_vernal_pools';
import sac_cnty_water_purveyors from './sac_cnty_water_purveyors';
import sea_level_rise_cosmos from './sea_level_rise_cosmos';
import sea_level_rise from './sea_level_rise';
import sea_level_rise_old from './sea_level_rise_old';
import sonoma_bus_routes from './sonoma_bus_routes';
import sonoma_jurisdiction_limits from './sonoma_jurisdiction_limits';
import sonoma_ph_outcomes_base from './sonoma_ph_outcomes_base';
import sonoma_priority_development_areas from './sonoma_priority_development_areas';
import sonoma_reference_data_parcel from './sonoma_reference_data_parcel';
import sonoma_smart_rail_route from './sonoma_smart_rail_route';
import sonoma_spheres_of_influence from './sonoma_spheres_of_influence';
import sonoma_urban_growth_boundaries from './sonoma_urban_growth_boundaries';
import sonoma_vmt_outcomes_base from './sonoma_vmt_outcomes_base';
import sonoma_water_use_base from './sonoma_water_use_base';
import style_lines_only from './style_lines_only';
import tiger_rails from './tiger_rails';
import tiger_roads from './tiger_roads';
import tiger_zcta from './tiger_zcta';
import tnc_global_ecoregional_portfolio from './tnc_global_ecoregional_portfolio';
import tnc_terrestrial_ecoregions from './tnc_terrestrial_ecoregions';
import toxic_release_inventory_2016 from './toxic_release_inventory_2016';
import transit_lines from './transit_lines';
import us_census_census_planning_database_2018 from './us_census_census_planning_database_2018';
import us_census_census_planning_database_age_distribution from './us_census_census_planning_database_age_distribution';
import us_census_census_planning_database_education from './us_census_census_planning_database_education';
import us_census_census_planning_database_household from './us_census_census_planning_database_household';
import us_census_census_planning_database_housing_units from './us_census_census_planning_database_housing_units';
import us_census_census_planning_database_income from './us_census_census_planning_database_income';
import us_census_census_planning_database_race from './us_census_census_planning_database_race';
import us_census_census_planning_database_sex from './us_census_census_planning_database_sex';
import us_census_rates_table_2012 from './us_census_rates_table_2012';
import us_colleges_and_universities from './us_colleges_and_universities';
import us_hospitals from './us_hospitals';
import us_parks_dataset from './us_parks_dataset';
import usda_ers_food_access_research_atlas_2015 from './usda_ers_food_access_research_atlas_2015';
import usfs_pacific_northwest_wildfire_probability_2018 from './usfs_pacific_northwest_wildfire_probability_2018';
import usfs_wildfire_hazard_potential_2018 from './usfs_wildfire_hazard_potential_2018';
import usgs_earthquake_mmi_haywired_scenario from './usgs_earthquake_mmi_haywired_scenario';
import usgs_usco_2010 from './usgs_usco_2010';
import utah_agrc_liquefaction from './utah_agrc_liquefaction';
import ventura_bike_lane from './ventura_bike_lane';
import ventura_city_boundary from './ventura_city_boundary';
import ventura_county_boundary from './ventura_county_boundary';
import ventura_general_plan_parcels from './ventura_general_plan_parcels';
import ventura_high_quality_transit_areas from './ventura_high_quality_transit_areas';
import ventura_sea_level_rise from './ventura_sea_level_rise';
import ventura_sphere_of_influence from './ventura_sphere_of_influence';
import ventura_tier2_taz from './ventura_tier2_taz';
import washington_dc_zoning_2016_washington_dc from './washington_dc_zoning_2016_washington_dc';
import watershed_boundary_dataset_basins from './watershed_boundary_dataset_basins';
import watershed_boundary_dataset_regions from './watershed_boundary_dataset_regions';
import watershed_boundary_dataset_subbasins from './watershed_boundary_dataset_subbasins';
import watershed_boundary_dataset_subregions from './watershed_boundary_dataset_subregions';
import watershed_boundary_dataset_subwatersheds from './watershed_boundary_dataset_subwatersheds';
import watershed_boundary_dataset_watersheds from './watershed_boundary_dataset_watersheds';
import western_us_powerlines from './western_us_powerlines';
import zoning_salt_lake_city from './zoning_salt_lake_city';
import zoning_san_francisco from './zoning_san_francisco';
import zoning_san_jose from './zoning_san_jose';
import zoning_standard_uf from './zoning_standard_uf';

const LayerSymbologies: Record<
  string,
  Record<string, CuratedColumnSymbology[]>
> = {
  ...alternative_fuel_stations_us,
  ...ambag_boundaries,
  ...ambag_land_use,
  ...ambag_taz,
  ...average_electricity_price_by_state_2015,
  ...average_gasoline_price_by_state_2016,
  ...average_natural_gas_price_by_state_2015,
  ...bay_area_conservation_lands_network,
  ...bay_area_critical_linkages,
  ...bay_area_priority_conservation_areas,
  ...beaumont_general_plan_land_use,
  ...boundary_agreements_cities_villages_2008,
  ...building_characteristics_riverside_city,
  ...ca_powerplants,
  ...cal_fire_cal_fire_administrative_boundaries,
  ...cal_fire_cal_fire_direct_protection_areas,
  ...cal_fire_cal_fire_facilities,
  ...cal_fire_cal_fire_fire_hazard_reduction_projects,
  ...cal_fire_cal_fire_priority_projects_n_05_19,
  ...calfire_fire_hazard_severity_zones,
  ...cal_fire_fire_hazard_severity_zones___california_aggregated,
  ...calenviroscreen_3_draft_results,
  ...calenviroscreen_3,
  ...calenviroscreen_4,
  ...california_above_carbon_density,
  ...california_active_river_areas,
  ...california_ag_production,
  ...california_ag_water_demand,
  ...california_areas_of_critical_concern,
  ...california_audubon_important_bird_areas,
  ...california_base_conservation,
  ...california_carbon_density,
  ...california_census_rates,
  ...california_combined_resistance,
  ...california_conservation_module_flood_plain_layer,
  ...california_cscd_current_schools_2016,
  ...california_drinking_water_source_watersheds,
  ...california_ecoregions_13,
  ...california_endangered_species,
  ...california_essential_connectivity_areas,
  ...california_farm_mapping_monitoring_program,
  ...california_fault_zone,
  ...california_flood_plain_layer,
  ...california_gw_recharge,
  ...california_health_disadvantage_index,
  ...california_hydrologic_regions,
  ...california_landslide_zone,
  ...california_liquefaction_zone,
  ...california_lqls_uneval_area,
  ...california_nhdplus_catchments,
  ...california_nhdplus_flowline_150m_buffer,
  ...california_priority_boundaries,
  ...california_soc0_30,
  ...california_tnc_priority_areas,
  ...california_usfs_subsections,
  ...california_wetlands,
  ...caltrans_caltrans_district_boundaries,
  ...cdc_chronic_disease_health_prom,
  ...cdc_usaleep_life_expectancy_estimates_usa_v2,
  ...census_blocks,
  ...census_planning_database_cbg_us_2016,
  ...census_tiger_arealm,
  ...census_tiger_military_installations,
  ...city_of_appleton_city_of_appleton_zoning,
  ...city_of_arlington_zoning_districts_arlington_tx,
  ...city_of_berkeley_zoning_districts_berkeley_ca,
  ...city_of_fresno_plu,
  ...city_of_fresno_zoning,
  ...city_of_madison_city_of_madison_zoning,
  ...city_of_minneapolis_zoning_primary_minneapolis,
  ...city_of_philadelphia_zoning_base_districts_philadelphia,
  ...city_of_portland_zoning_portland_or,
  ...city_of_san_jose_general_plan_san_jose,
  ...city_of_seattle_zoning_seattle,
  ...city_of_somerville_building_footprints,
  ...city_of_somerville_current_zoning,
  ...city_of_somerville_green_line_ext,
  ...city_of_somerville_green_line_ext_stops,
  ...city_of_somerville_open_space,
  ...city_of_somerville_parcels,
  ...city_of_somerville_proposed_zoning,
  ...colorado_clear_creek_parcels,
  ...colorado_evapotranspiration_canvas,
  ...colorado_transit_lines,
  ...corelogic_county_coverage_summary,
  ...covina_metrolinksbline_ln_scag_2004_1,
  ...covina_parcels_2015,
  ...covina_parcels_tc,
  ...cpad_holdings,
  ...cpuc_hftd,
  ...dane_county_distance_references_modified_blocks,
  ...dane_county_subparcels_land_use,
  ...dane_county_tax_parcels,
  ...denver_e_contour_lines,
  ...denver_w_contour_lines,
  ...egrid_data_by_power_plant_2016,
  ...egrid_data_by_state_2016,
  ...egrid_data_by_subregion_2014,
  ...elk_grove_land_use,
  ...elk_grove_ph_outcomes_base,
  ...elk_grove_vmt_outcomes_base,
  ...epa_facility_registry_service,
  ...epa_smart_location_blockgroup_2010,
  ...eri_v1,
  ...esri_us_parks_2019,
  ...fema_national_flood_hazard_layer_areas,
  ...florida_dot_aadt_w_truck_fl_2018,
  ...florida_dot_bicycle_lanes_fl,
  ...fri,
  ...DefaultGeoColumnKeyPointSymbologies,
  ...fsi,
  ...greeninfo_cced_2018,
  ...greeninfo_cpad_2018a,
  ...greeninfo_california_protected_areas_database_2021a,
  ...habitatcores20170305,
  ...headstart_national_202210,
  ...hifld_us_colleges,
  ...hifld_us_daycare_centers,
  ...hifld_nursing_home,
  ...hifld_us_private_schools,
  ...hifld_us_sup_colleges,
  ...holc_redlining_areas,
  ...honolulu_land_use,
  ...hud_concentrated_areas_of_poverty_hud,
  ...hud_env_health_hazard_idx,
  ...hud_fair_market_rents,
  ...hud_fair_market_rents_2019,
  ...hud_low_poverty_idx,
  ...hud_low_trans_cost_idx,
  ...hud_public_housing_buildings,
  ...hud_recaps,
  ...invasiveplantcurrent,
  ...irrigation_factor_2010,
  ...land_use,
  ...land_value_riverside_city,
  ...latch_survey_by_census_tract,
  ...lcpconnectors20170305,
  ...low_povert_index,
  ...madison_activity_centers,
  ...madison_boundary_area,
  ...madison_brt_routes_s2,
  ...madison_brt_routes_s3,
  ...madison_census_rates,
  ...madison_city_town_village_boundaries,
  ...madison_commuter_rail,
  ...madison_core_habitat_buffer_540ft_town,
  ...madison_county_boundary,
  ...madison_county_conservation_easements,
  ...madison_county_natural_resource_areas,
  ...madison_county_parks,
  ...madison_county_wildlife_areas,
  ...madison_energy_use_base,
  ...madison_future_land_use_update_2018_comp_plan,
  ...madison_historic_districts,
  ...madison_local_landmarks,
  ...madison_ph_outcomes_base,
  ...madison_urban_service_areas,
  ...madison_vmt_outcomes_base,
  ...madison_water_use_base,
  ...mbta_transit_lines_greater_boston,
  ...mbta_transit_stops_greater_boston,
  ...morpc_base_zoning_districts,
  ...morpc_commercial_zoning_overlays,
  ...morpc_mtp16_gridtazforecast_lu_gridtaz_official2016mtp_forecasts,
  ...morpc_recommended_land_use,
  ...national_commercial_building_energy_use_rate,
  ...national_electric_power_sales_by_state,
  ...national_energy_consumption_calibration_factors_by_state,
  ...national_evapotranspiration_canvas_2014,
  ...national_registry_of_historic_places,
  ...national_residential_energy_use_rate,
  ...national_transit_lines,
  ...national_transit_lines_2018_q4,
  ...nhd_area,
  ...nhd_waterbody,
  ...noaa_marine_protected_areas,
  ...nwi_wetlands,
  ...odlcd_urban_growth_boundaries,
  ...osm_building_footprints,
  ...parcels,
  ...phasocal_california_healthy_places_index,
  ...protected_areas_database_combined,
  ...protected_areas_database_easements,
  ...public_school_characteristics,
  ...sac_cnty_light_rail,
  ...sac_cnty_sasd_boundary,
  ...sac_cnty_school_districts,
  ...sac_cnty_streams,
  ...sac_cnty_urban_service_boundary,
  ...sac_cnty_vernal_pools,
  ...sac_cnty_water_purveyors,
  ...sea_level_rise,
  ...sea_level_rise_cosmos,
  ...sea_level_rise_old,
  ...sonoma_bus_routes,
  ...sonoma_jurisdiction_limits,
  ...sonoma_ph_outcomes_base,
  ...sonoma_priority_development_areas,
  ...sonoma_reference_data_parcel,
  ...sonoma_smart_rail_route,
  ...sonoma_spheres_of_influence,
  ...sonoma_urban_growth_boundaries,
  ...sonoma_vmt_outcomes_base,
  ...sonoma_water_use_base,
  ...style_lines_only,
  ...tiger_rails,
  ...tiger_roads,
  ...tiger_zcta,
  ...tnc_global_ecoregional_portfolio,
  ...tnc_terrestrial_ecoregions,
  ...toxic_release_inventory_2016,
  ...transit_lines,
  ...us_census_census_planning_database_2018,
  ...us_census_census_planning_database_age_distribution,
  ...us_census_census_planning_database_education,
  ...us_census_census_planning_database_household,
  ...us_census_census_planning_database_housing_units,
  ...us_census_census_planning_database_income,
  ...us_census_census_planning_database_race,
  ...us_census_census_planning_database_sex,
  ...us_census_rates_table_2012,
  ...us_colleges_and_universities,
  ...us_hospitals,
  ...us_parks_dataset,
  ...usda_ers_food_access_research_atlas_2015,
  ...usfs_pacific_northwest_wildfire_probability_2018,
  ...usfs_wildfire_hazard_potential_2018,
  ...utah_agrc_liquefaction,
  ...usgs_earthquake_mmi_haywired_scenario,
  ...usgs_usco_2010,
  ...ventura_bike_lane,
  ...ventura_city_boundary,
  ...ventura_county_boundary,
  ...ventura_general_plan_parcels,
  ...ventura_high_quality_transit_areas,
  ...ventura_sea_level_rise,
  ...ventura_sphere_of_influence,
  ...ventura_tier2_taz,
  ...washington_dc_zoning_2016_washington_dc,
  ...watershed_boundary_dataset_basins,
  ...watershed_boundary_dataset_regions,
  ...watershed_boundary_dataset_subbasins,
  ...watershed_boundary_dataset_subregions,
  ...watershed_boundary_dataset_subwatersheds,
  ...watershed_boundary_dataset_watersheds,
  ...western_us_powerlines,
  ...zoning_salt_lake_city,
  ...zoning_san_francisco,
  ...zoning_san_jose,
  ...zoning_standard_uf,
};

export function registerLayerSymbology(
  layerId: LayerId,
  columnKey: ColumnKey,
  symbology: CuratedColumnSymbology[],
) {
  LayerSymbologies[layerId] = {
    ...LayerSymbologies[layerId],
    [columnKey]: symbology,
  };

  return () => {
    delete LayerSymbologies[layerId][columnKey];
  };
}

export default LayerSymbologies;
