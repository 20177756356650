import {
  CuratedColumnSymbology,
  InterpolationTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/public/dataset/city_of_berkeley_zoning_districts_berkeley_ca': {
    zoning: [
      {
        display_hints: {
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          scale: SymbologyScales.CATEGORICAL,
          theme: 'custom',
        },
        paint: {
          'fill-color': {
            default: '#17becf',
            property: 'zoning',
            stops: [
              {
                color: '#d33115',
                value: 'C-1',
              },
              {
                color: '#e036b8',
                value: 'C-DMU Buffer',
              },
              {
                color: '#630947',
                value: 'C-DMU Core',
              },
              {
                color: '#9b1646',
                value: 'C-DMU Corridor',
              },
              {
                color: '#a80aa4',
                value: 'C-DMU Outer Core',
              },
              {
                color: '#f44e3b',
                value: 'C-E',
              },
              {
                color: '#e27300',
                value: 'C-N',
              },
              {
                color: '#cf7702',
                value: 'C-N(H)',
              },
              {
                color: '#e27300',
                value: 'C-NS',
              },
              {
                color: '#e27300',
                value: 'C-NS(H)',
              },
              {
                color: '#9f0500',
                value: 'C-SA',
              },
              {
                color: '#d33115',
                value: 'C-SO',
              },
              {
                color: '#f44e3b',
                value: 'C-T',
              },
              {
                color: '#c45100',
                value: 'C-W',
              },
              {
                color: '#b9a69b',
                value: 'ES-R',
              },
              {
                color: '#daf0fc',
                value: 'M',
              },
              {
                color: '#c1d8fd',
                value: 'MM',
              },
              {
                color: '#8ad4fc',
                value: 'MULI',
              },
              {
                color: '#69b4fe',
                value: 'MUR',
              },
              {
                color: '#fbfac0',
                value: 'R-1',
              },
              {
                color: '#fbf98f',
                value: 'R-1A',
              },
              {
                color: '#eae885',
                value: 'R-1H',
              },
              {
                color: '#fbd75e',
                value: 'R-2',
              },
              {
                color: '#f9a900',
                value: 'R-2A',
              },
              {
                color: '#e39a00',
                value: 'R-2AH',
              },
              {
                color: '#eecc59',
                value: 'R-2H',
              },
              {
                color: '#fb9300',
                value: 'R-3',
              },
              {
                color: '#ed8b00',
                value: 'R-3H',
              },
              {
                color: '#d86f00',
                value: 'R-4',
              },
              {
                color: '#c96700',
                value: 'R-4H',
              },
              {
                color: '#ba7028',
                value: 'R-5',
              },
              {
                color: '#9e5f22',
                value: 'R-5H',
              },
              {
                color: '#7f693b',
                value: 'R-S',
              },
              {
                color: '#5d4d2b',
                value: 'R-SH',
              },
              {
                color: '#6800da',
                value: 'R-SMU',
              },
              {
                color: '#dbdf00',
                value: 'SP',
              },
              {
                color: '#cccccc',
                value: 'U',
              },
              {
                color: '#666666',
                value: 'X',
              },
            ],
            type: 'categorical',
          },
          'fill-opacity': 0.9,
        },
        type: SymbologyTypes.FILL,
      },
      {
        display_hints: {
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          scale: SymbologyScales.CATEGORICAL,
          theme: 'custom',
        },
        paint: {
          'line-color': '#b3b3b3',
          'line-opacity': 0.9,
          'line-width': 0.5,
        },
        type: SymbologyTypes.LINE,
      },
    ],
  },
};

export default symbologies;
