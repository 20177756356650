import classNames from 'classnames';
import _ from 'lodash';
import React, {
  CSSProperties,
  FunctionComponent,
  ReactNode,
  useCallback,
  useState,
} from 'react';
import Dropzone, { DropzoneState } from 'react-dropzone';
import { Box } from 'react-layout-components';
import { t } from 'ttag';

import { formatTitleCase } from 'uf/base/formatting';
import useUserFlag from 'uf/ui/user/useUserFlag';

import styles from './FileUpload.module.css';

export const DEFAULT_MAXIMUM_FILE_SIZE = 100e6;

const UPLOAD_LARGE_FILES_MULTIPLIER = 3;

interface FileUploadProps {
  disabled?: boolean;
  className?: string;
  fileClassName?: string;
  style?: React.CSSProperties;
  maxSizeMessage?: ReactNode;
  rejectedTypeMessage?: ReactNode;
  emptyMessage?: ReactNode;
  haveFileMessage?: ReactNode;
  errorMessage?: ReactNode;
  accept?: string | string[];
  onDrop: (file: File, name: string) => void;
  hasError?: boolean;
  small?: boolean;
  maxFileSize?: number;
}

const FileUpload: FunctionComponent<FileUploadProps> = ({
  disabled,
  className,
  fileClassName,
  style,
  small,
  rejectedTypeMessage,
  emptyMessage,
  haveFileMessage,
  errorMessage,
  hasError,
  accept,
  onDrop,
  maxFileSize,
}) => {
  const [state, setState] = useState(null);
  const canUploadLargeFiles = useUserFlag('dev-import-large');
  let maxSize = maxFileSize || DEFAULT_MAXIMUM_FILE_SIZE;
  if (canUploadLargeFiles) {
    maxSize *= UPLOAD_LARGE_FILES_MULTIPLIER;
  }
  const handleDrop = useCallback(
    (files: File[]) => {
      let file = null;
      let nameSuggestion = null;
      if (!_.isEmpty(files)) {
        [file] = files;
        nameSuggestion = makeNameSuggestion(file.name);
      }
      setState(file);
      onDrop(file, nameSuggestion);
    },
    [onDrop],
  );

  return (
    <Dropzone
      disabled={disabled}
      accept={accept}
      maxSize={maxSize}
      multiple={false}
      onDrop={handleDrop}>
      {dropzoneState => (
        <Body
          dropzoneState={dropzoneState}
          disabled={disabled}
          file={state}
          className={className}
          style={style}
          small={small}
          fileClassName={fileClassName}
          emptyMessage={emptyMessage}
          haveFileMessage={haveFileMessage}
          rejectedTypeMessage={rejectedTypeMessage}
          errorMessage={errorMessage}
          hasError={hasError}
          maxFileSize={maxSize}
        />
      )}
    </Dropzone>
  );
};

export default FileUpload;

interface BodyProps {
  dropzoneState: DropzoneState;
  disabled: boolean;
  className: string;
  fileClassName: string;
  style: CSSProperties;
  small: boolean;
  rejectedTypeMessage: ReactNode;
  emptyMessage: ReactNode;
  haveFileMessage: ReactNode;
  errorMessage: ReactNode;
  hasError: boolean;
  file: File;
  maxSizeMessage?: ReactNode;
  maxFileSize: number;
}
const ONE_MEGABYTE = 1e6;
const Body: FunctionComponent<BodyProps> = props => {
  const {
    dropzoneState,
    disabled,
    className,
    fileClassName,
    style,
    small,
    rejectedTypeMessage,
    emptyMessage,
    haveFileMessage,
    errorMessage,
    hasError,
    file,
    maxSizeMessage,
    maxFileSize,
  } = props;

  const dropzoneClassName = classNames(styles.dropzone, className, {
    [styles.dropzoneWithFile]: !!file,
    [styles.dropzoneWithError]: hasError,
    [styles.dropzoneNormal]: !small,
    [styles.dropzoneSmall]: small,
    [fileClassName]: !!file,
  });

  const textClassName = small ? 'h5' : 'h3';

  const rootProps = dropzoneState.getRootProps({
    className: dropzoneClassName,
    style,
  });
  let body: ReactNode;

  //  isDragReject currently doesn't trigger on size constraints
  //  See: https://github.com/react-dropzone/react-dropzone/issues/861
  if (dropzoneState.fileRejections.length > 0) {
    const rejectedFile = dropzoneState.fileRejections[0];

    // Check if it was rejected on size
    if (rejectedFile?.file.size > maxFileSize) {
      const megabyteSize = maxFileSize / ONE_MEGABYTE;
      body = maxSizeMessage ? (
        maxSizeMessage
      ) : (
        <Box>{t`The file exceeds the maximum size limit of ${megabyteSize} MB`}</Box>
      );

      //  If it wasn't rejected on size, it was rejected on type
    } else {
      body = rejectedTypeMessage ? (
        rejectedTypeMessage
      ) : (
        <Box>{t`This file type is not supported.`}</Box>
      );
    }
  } else if (hasError) {
    body =
      errorMessage !== undefined ? (
        errorMessage
      ) : (
        <Box
          fit
          center
          className={
            textClassName
          }>{t`There was a problem with the file you uploaded.`}</Box>
      );
  } else if (file) {
    body =
      haveFileMessage !== undefined ? (
        haveFileMessage
      ) : (
        <Box column alignItems="center">
          <h3 className="text-center">{file.name}</h3>
          <div>({file.size.toLocaleString()} bytes)</div>
        </Box>
      );
  } else {
    body =
      emptyMessage !== undefined ? (
        emptyMessage
      ) : (
        <Box fit center className={textClassName}>
          {t`Drop your file here, or click to upload.`}
        </Box>
      );
  }

  const inputProps = dropzoneState.getInputProps({ disabled });
  return (
    <div {...rootProps}>
      <input type="file" value="" {...inputProps} alt="abc" />
      {body}
    </div>
  );
};

// Drop the trailing extension - turns filename.foo.zip into filename.foo.
function baseName(filename: string) {
  const extensionPosition = filename.lastIndexOf('.');
  if (extensionPosition !== -1) {
    return filename.substring(0, extensionPosition);
  }
  return filename;
}

// Create a user-friendly layer name suggestion
function makeNameSuggestion(filename: string) {
  // converts text to Title Case and replaces dashes and underscores with spaces.
  return formatTitleCase(baseName(filename));
}
