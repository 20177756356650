import { Classes } from '@blueprintjs/core';
import classNames from 'classnames';
import React from 'react';
import { Box } from 'react-layout-components';
import { connect } from 'react-redux';

import { getFrontendVersion } from 'uf/app/selectors';
import { CURRENT_YEAR } from 'uf/base';
import { BuildInfo } from 'uf/base/buildinfo';
import { UFState } from 'uf/state';
import rootStyles from 'uf/styles/root.module.css';
import textStyles from 'uf/styles/text.module.css';
import { makeGetUserFlag } from 'uf/user/selectors/flags';

interface StateProps {
  version?: {
    version?: string;
    git_branch?: string;
    git_commit?: string;
    git_hash?: string;
  };
  isDeveloper?: boolean;
}

interface OwnProps {
  id?: string;
  clientVersion?: BuildInfo;
}

type Props = OwnProps & StateProps;
const footerClassName = classNames(
  rootStyles.veryThinPaddingHorizontal,
  textStyles.ellipsis,
  rootStyles.defaultBorderTop,
);
const developerInfoClassName = classNames(
  rootStyles.veryThinMarginLeft,
  textStyles.ellipsis,
  textStyles.invisibleInTests,
);
export const Footer: React.FunctionComponent<Props> = props => {
  const { version, id, isDeveloper, clientVersion } = props;
  const buildDate = new Date(__BUILD_INFO__.build_date).toUTCString();
  const { git_branch: gitBranch, git_commit: gitCommit } =
    clientVersion || __BUILD_INFO__;
  const match = gitCommit === version.git_commit;
  const matchStyle = classNames({ [Classes.INTENT_WARNING]: !match });
  return (
    <Box id={id} className={footerClassName}>
      &copy; {CURRENT_YEAR} UrbanFootprint
      {isDeveloper && (
        <span className={developerInfoClassName}>
          {' '}
          Client Build:{' '}
          <a
            href={`https://github.com/CalthorpeAnalytics/uf/commits/${gitBranch}`}
            rel="noopener noreferrer"
            target="_blank">
            <code>{gitBranch}</code>
          </a>
          @
          <a
            href={`https://github.com/CalthorpeAnalytics/uf/commits/${gitCommit}`}
            rel="noopener noreferrer"
            target="_blank">
            <code>{gitCommit}</code>
          </a>{' '}
          (
          <a
            href={`https://github.com/CalthorpeAnalytics/uf/compare/${gitCommit}...master`}
            rel="noopener noreferrer"
            target="_blank">
            unreleased
          </a>
          ) <span title={__BUILD_INFO__.build_date}>{buildDate}</span>{' '}
          <span className={matchStyle}>
            Frontend Server Build:{' '}
            <code className={matchStyle}>
              {version.git_branch} @ {version.git_commit}
            </code>
          </span>
        </span>
      )}
    </Box>
  );
};
export default connect<StateProps, never, OwnProps>(() => {
  const getIsDeveloper = makeGetUserFlag('developer');
  return (state: UFState): StateProps => ({
    isDeveloper: getIsDeveloper(state),
    version: getFrontendVersion(state),
  });
})(Footer);
