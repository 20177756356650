import { RoutesMap } from 'redux-first-router';

import {
  ROUTE_ANALYSIS_EDITOR,
  ROUTE_ANALYSIS_EDITOR_WITH_KEY,
} from 'uf/analysiseditor/ActionTypes';
import { makeUpdateAnalysisEditorStateFromUrl } from 'uf/analysiseditor/routing/sync';
import {
  ROUTE_BUILT_FORM_EDITOR,
  ROUTE_BUILT_FORM_EDITOR_WITH_KEY,
} from 'uf/builtforms/ActionTypes';
import { makeUpdateEditorStateFromUrl } from 'uf/builtforms/routing/sync';
import { ROUTE_EXPLORE, ROUTE_EXPLORE_NEW } from 'uf/explore/ActionTypes';
import { makeUpdateExploreStateFromUrl } from 'uf/explore/routing/sync';
import { ROUTE_INTERNAL } from 'uf/internal/ActionTypes';
import { makeUpdateInternalStateFromUrl } from 'uf/internal/routing/sync';
import { ROUTE_SETTINGS } from 'uf/organizations/ActionTypes';
import { ROUTE_REPORT } from 'uf/reporting/ActionTypes';
import { makeRoutingActionType } from 'uf/routing/actions';
import { ROUTE_PROFILE } from 'uf/user/ActionTypes';

// This is the main routesMap for redux-first-router
const routesMap: RoutesMap = {
  [makeRoutingActionType(ROUTE_EXPLORE)]: {
    path: '/',
    thunk: makeUpdateExploreStateFromUrl(),
  },
  [makeRoutingActionType(ROUTE_EXPLORE_NEW)]: {
    path: '/explore/:namespace/project/:projectKey',
  },
  [makeRoutingActionType(ROUTE_REPORT)]: {
    path: '/report/:type/:namespace/project/:projectKey',
  },
  [makeRoutingActionType(ROUTE_SETTINGS)]: {
    path: '/settings/:namespace',
  },
  [makeRoutingActionType(ROUTE_PROFILE)]: { path: '/profile' },
  [makeRoutingActionType(ROUTE_BUILT_FORM_EDITOR)]: {
    path: '/bf/:type/:namespace/builtforms/:libraryKey',
  },
  [makeRoutingActionType(ROUTE_BUILT_FORM_EDITOR_WITH_KEY)]: {
    path: '/bf/:type/:namespace/builtforms/:libraryKey/:key',
    thunk: makeUpdateEditorStateFromUrl(),
  },
  [makeRoutingActionType(ROUTE_ANALYSIS_EDITOR)]: {
    path: '/ae/analysis/:namespace/project/:projectKey',
  },
  [makeRoutingActionType(ROUTE_ANALYSIS_EDITOR_WITH_KEY)]: {
    path: '/ae/analysis/:namespace/project/:projectKey/:moduleKey',
    thunk: makeUpdateAnalysisEditorStateFromUrl(),
  },
  [makeRoutingActionType(ROUTE_INTERNAL)]: {
    path: '/internal/:pageKey?',
    thunk: makeUpdateInternalStateFromUrl(),
  },
};

export default routesMap;

if (module.hot) {
  module.hot.accept();
}
