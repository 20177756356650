/**
 * UrbanFootprint App API
 * API for serving UrbanFootprint Application
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * True if the two geos intersect.
 */
export interface ExpressionIntersects<
  Tleft_geo extends object = any,
  Tright_geo extends object = any,
> {
  /**
   * Expression function, set to \"intersects\".
   */
  fn: ExpressionIntersects.FnEnum;
  /**
   * The first Geometry.
   */
  left_geo: Tleft_geo;
  /**
   * The second Geometry or column
   */
  right_geo?: Tright_geo;
}
export namespace ExpressionIntersects {
  export type FnEnum = 'intersects';
  export const FnEnum = {
    Intersects: 'intersects',
  } as const;
}
