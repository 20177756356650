import { Action } from 'redux';

import { makeAsyncActionTypes } from 'uf/data/ActionTypes';

const userPrefix = 'uf/user';
const flagsPrefix = `${userPrefix}/flags`;

// TODO: deprecated.  remove when auth0 goes into production.
const authenticationPrefix = `${userPrefix}/authentication`;

export const authenticationActionTypes =
  makeAsyncActionTypes(authenticationPrefix);

export const SET_USER_FLAGS = `${flagsPrefix}/SET_USER_FLAGS`;
export const ADD_USER_FLAGS = `${flagsPrefix}/ADD_USER_FLAGS`;
export const REMOVE_USER_FLAGS = `${flagsPrefix}/REMOVE_USER_FLAGS`;
export interface FlagAction extends Action {
  type:
    | typeof SET_USER_FLAGS
    | typeof ADD_USER_FLAGS
    | typeof REMOVE_USER_FLAGS;
  flags: string[];
}

export const ROUTE_PROFILE = 'PROFILE';
