import {
  CuratedColumnSymbology,
  InterpolationTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/public/dataset/hifld_nursing_home': {
    naics_desc: [
      {
        display_hints: {
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          scale: SymbologyScales.CATEGORICAL,
          theme: 'custom',
        },
        paint: {
          'circle-color': {
            default: '#2ca02c',
            property: 'naics_desc',
            stops: [
              {
                color: '#009ce0',
                value: 'ASSISTED LIVING FACILITIES FOR THE ELDERLY',
              },
              {
                color: '#653294',
                value: 'CONTINUING CARE RETIREMENT COMMUNITIES',
              },
            ],
            type: 'categorical',
          },
          'circle-opacity': 0.9,
          'circle-stroke-color': 'transparent',
        },
        type: SymbologyTypes.CIRCLE,
      },
    ],
    type: [
      {
        display_hints: {
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          scale: SymbologyScales.CATEGORICAL,
          theme: 'd3:schemeCategory10',
        },
        paint: {
          'circle-color': {
            default: '#2ca02c',
            property: 'type',
            stops: [
              {
                color: '#1f77b4',
                value: 'ASSISTED LIVING',
              },
              {
                color: '#ff7f0e',
                value: 'CONTINUING CARE RETIREMENT COMMUNITY',
              },
            ],
            type: 'categorical',
          },
          'circle-opacity': 0.6,
          'circle-stroke-color': 'transparent',
        },
        type: SymbologyTypes.CIRCLE,
      },
    ],
  },
};

export default symbologies;
