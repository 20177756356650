import { createSelector } from 'reselect';

import { EMPTY_OBJECT } from 'uf/base';
import { ROUTE_EXPLORE } from 'uf/explore/ActionTypes';
import { Query, RootQuery } from 'uf/routing';
import { makeRoutingActionType } from 'uf/routing/actions';
import { LocationState } from 'uf/routing/state';
import { UFState } from 'uf/state';
import { getDefaultFeatureFlags, getUserFlags } from 'uf/user/selectors/flags';
import { getUserIsSuperuser } from 'uf/user/selectors/user';

export function getLocationState(state: UFState): LocationState {
  const { location } = state;
  return location;
}

export const getQueryState = createSelector(
  getLocationState,
  (location): Query => (location.query || EMPTY_OBJECT) as Query,
);

/**
 * selector that returns queries that we want to persist across routes.
 */
export const getRootQueries = createSelector(
  getUserFlags,
  getUserIsSuperuser,
  (flags, isSuperUser): RootQuery => {
    const rootQuery: RootQuery = {};

    if (isSuperUser || __DEVELOPMENT__ || __TESTING__) {
      const featureFlags = flags.filter(isUrlSafeFlag);

      if (featureFlags.length) {
        rootQuery.feature_flags = featureFlags.join(',');
      }
    }

    return rootQuery;
  },
);

export const getExploreMode = createSelector(
  getLocationState,
  location => location.type === makeRoutingActionType(ROUTE_EXPLORE),
);

const flagsToOmitFromUrl = [
  ...Object.keys(getDefaultFeatureFlags()),
  'developer',
];

/**
 *  filter function that returns true if the flag is safe to include in the URL
 */
function isUrlSafeFlag(flag: string): boolean {
  return !flagsToOmitFromUrl.includes(flag);
}
