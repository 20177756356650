import { Button, IButtonProps } from '@blueprintjs/core';
import Icon from '@mdi/react';
import { boundMethod } from 'autobind-decorator';
import React, { Component, CSSProperties, MouseEventHandler } from 'react';

interface Props extends IButtonProps {
  // prop to remove the all-cap text transform applied by react-bootstrap.
  iconPath: string;
  className?: string;
  style?: CSSProperties;
  onClick?: MouseEventHandler;
  disabled?: boolean;
  iconSize?: number;
}

/**
 * PLEASE READ:  This is an icon that works as a button.  This component breaks
 * some accessibility standards and should only be used for cases where a
 * clickable item is needed inside a button container.  If this is not the case
 * then a <button> should be used instead!
 * @param className - class names to be applied to the icon.
 * @param style - inline styles to be applied to the icon.
 * @param onClick - click handler for the icon.
 * @param disabled - if true, prevents the click actions.
 */
export default class NestedIconButton extends Component<Props> {
  static defaultProps = {
    className: '',
  };

  @boundMethod
  onClick(e) {
    const { disabled, onClick } = this.props;

    // WARNING: this is the default behavior because this component is designed
    // to be nested in a button.  If you need different behavior then you
    // should use a different component.
    e.preventDefault();
    e.stopPropagation();

    if (!disabled && !!onClick) {
      onClick(e);
    }
  }

  render() {
    const {
      className,
      style,
      children,
      disabled, // not used, but excluded from extraProps
      onClick, // not used, but excluded from extraProps
      iconPath,
      iconSize = 1,
      ...extraProps
    } = this.props;

    return (
      <Button
        minimal
        small
        className={className}
        style={style}
        onClick={this.onClick}
        icon={<Icon path={iconPath} size={iconSize} />}
        {...extraProps}>
        {children}
      </Button>
    );
  }
}
