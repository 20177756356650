import { ProjectId } from 'uf/projects';
import { ScenarioId } from 'uf/scenarios';

import { ModuleRunStatus } from './index';

const actionPrefix = 'uf/analysis';

export const SET_ANALYSIS_STATUS = `${actionPrefix}/SET_ANALYSIS_STATUS`;
export interface SetAnalysisStatusAction {
  type: string;
  projectId: ProjectId;
  scenarioId: ScenarioId;
  moduleKey: string;
  status: ModuleRunStatus;
}
