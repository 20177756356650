import {
  CuratedColumnSymbology,
  CUSTOM_THEME,
  DEFAULT_OPACITY,
  InterpolationTypes,
  PaintColorTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/public/dataset/california_tnc_priority_areas': {
    habitat_integration: [
      {
        type: SymbologyTypes.FILL,
        paint: {
          'fill-color': {
            property: 'habitat_integration',
            type: PaintColorTypes.CATEGORICAL,
            stops: [
              { value: 'Freshwater', color: '#a6cee3' },
              { value: 'Terrestrial', color: '#b2df8a' },
              { value: 'Terrestrial/Freshwater', color: '#73b6a4' },
              { value: 'Terrestrial/Subterranean', color: '#33a02c' },
            ],
          },
          'fill-opacity': DEFAULT_OPACITY,
        },
        display_hints: {
          scale: SymbologyScales.CATEGORICAL,
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          theme: CUSTOM_THEME,
        },
      },
    ],
  },
};

export default symbologies;
