import { ColumnKey } from 'uf/layers';
import { DEFAULT_GEO_COLUMN_KEY, GEOMETRY_KEY } from 'uf/layers/geometryKey';
import { registerPersistedAction } from 'uf/persistence';
import { ProjectId } from 'uf/projects';
import { LegacyVirtualLayerId } from 'uf/projects/virtualLayers';
import { UFState } from 'uf/state';
import { ViewId } from 'uf/views';
import {
  SET_LAYER_MAP_COLUMN_KEY,
  SetActiveColumnAction,
} from 'uf/views/ActionTypes';

registerPersistedAction<
  SetActiveColumnAction,
  [ProjectId, LegacyVirtualLayerId, ViewId],
  string,
  UFState
>(
  'activeVLayerColumn',
  SET_LAYER_MAP_COLUMN_KEY,
  ([projectId, virtualLayerId, viewId], columnKey) =>
    setLayerMapColumnKey(virtualLayerId, columnKey, projectId, viewId),
  {
    getKey({ projectId, virtualLayerId, viewId }) {
      return [projectId, virtualLayerId, viewId];
    },

    getValue({ columnKey }) {
      return columnKey;
    },
  },
);

registerPersistedAction<
  SetActiveColumnAction,
  [ProjectId, ViewId, LegacyVirtualLayerId],
  string,
  UFState
>(
  'viewActiveVLayerColumn',
  SET_LAYER_MAP_COLUMN_KEY,
  ([projectId, viewId, virtualLayerId], columnKey) =>
    setLayerMapColumnKey(virtualLayerId, columnKey, projectId, viewId),
  {
    getKey({ projectId, viewId, virtualLayerId }) {
      return [projectId, viewId, virtualLayerId];
    },

    getValue({ columnKey }) {
      return columnKey;
    },
  },
);

export function setLayerMapColumnKey(
  virtualLayerId: LegacyVirtualLayerId,
  columnKey: ColumnKey,
  projectId: ProjectId,
  viewId: ViewId,
): SetActiveColumnAction {
  const finalColumnKey =
    columnKey === GEOMETRY_KEY ? DEFAULT_GEO_COLUMN_KEY : columnKey;
  return {
    type: SET_LAYER_MAP_COLUMN_KEY,
    projectId,
    viewId,
    virtualLayerId,
    columnKey: finalColumnKey,
  };
}
