import { ColumnKey } from 'uf/layers';
import { ProjectId } from 'uf/projects';
import { LegacyVirtualLayerId } from 'uf/projects/virtualLayers';
import {
  LayerColumnSymbology,
  LayerSymbologyEnvelope,
  ViewId,
} from 'uf/symbology';

export type { ViewId };

export const DEFAULT_VIEW_ID: ViewId = 'default_view';

export interface View {
  key: ViewId;
  name: string;
}

export type SymbologyByDivideByColumnKey = Record<
  ColumnKey,
  LayerSymbologyEnvelope
>;

export type SymbologyByColumnKey = Record<
  ColumnKey,
  SymbologyByDivideByColumnKey
>;

export type SymbologyByVirtualLayerId = Record<
  LegacyVirtualLayerId,
  SymbologyByColumnKey
>;

export type SymbologyByView = Record<ViewId, SymbologyByVirtualLayerId>;

export type SymbologyByProject = Record<ProjectId, SymbologyByView>;

export interface ViewSymbologyState {
  /**
   *  The source of truth for symbology when being actively configured in the symbology editor. This
   *  takes precedence over the `base` symbology.
   **/
  override: Record<
    ViewId,
    Record<
      LegacyVirtualLayerId,
      Record<ColumnKey, Record<string, LayerColumnSymbology[]>>
    >
  >;
  divideByColumn: Record<
    ProjectId,
    Record<ViewId, Record<LegacyVirtualLayerId, Record<ColumnKey, string>>>
  >;
}
