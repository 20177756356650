import {
  CuratedColumnSymbology,
  CUSTOM_THEME,
  DEFAULT_OPACITY,
  InterpolationTypes,
  PaintColorTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/public/dataset/california_nhdplus_catchments': {
    catchment_type: [
      {
        type: SymbologyTypes.FILL,
        paint: {
          'fill-color': {
            property: 'catchment_type',
            type: PaintColorTypes.CATEGORICAL,
            stops: [
              { value: 'nc_na', label: 'Natural Catchments', color: '#097218' },
              {
                value: 'uc_ua',
                label: 'Degraded Catchments',
                color: '#89c191',
              },
              {
                value: 'uc_na',
                label: 'Important Riparian Buffers',
                color: '#41a34f',
              },
            ],
          },
          'fill-opacity': DEFAULT_OPACITY,
        },
        display_hints: {
          scale: SymbologyScales.CATEGORICAL,
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          theme: CUSTOM_THEME,
        },
      },
    ],
  },
};

export default symbologies;
