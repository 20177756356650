import { LayerMetadata } from 'uf-api';
import { getSafeKey } from 'uf/base/keys';
import { ColumnKey } from 'uf/layers';
import { getColumnKey } from 'uf/layers/geometryKey';
import { getColumnMetadata } from 'uf/layers/metadata';
import { ProjectId } from 'uf/projects';
import { LegacyVirtualLayerId } from 'uf/projects/virtualLayers';
import { ViewId } from 'uf/views';

export function getSymbologyKey(
  layerMetadata: LayerMetadata,
  columnKey: ColumnKey,
): string {
  const finalColumnKey = getColumnKey(columnKey);
  const columnMetadata = getColumnMetadata(layerMetadata, finalColumnKey);
  return columnMetadata?.display_hints?.symbology_key;
}

/**
 * Returns the user_data key for symbology.  This is the key found in set_user_data objects in
 * redux.
 * @deprecated Use the backend API
 * @param userKey
 * @param projectId
 * @param viewId
 * @param virtualLayerId
 * @param columnKey
 * @param divideByColumn
 */
export function makeSymbologyUserDataKeyForUser(
  userKey: string,
  projectId: ProjectId,
  viewId: ViewId,
  // TODO: use virtual layer id here!
  virtualLayerId: LegacyVirtualLayerId,
  columnKey: ColumnKey,
  divideByColumn: string,
) {
  return `${userKey}:${makeSymbologyUserDataKeyNew(
    projectId,
    viewId,
    virtualLayerId,
    columnKey,
    divideByColumn,
  )}`;
}

/**
 * Returns the persistence data key for symbology.  This is the 'key' column found in the user_data table.
 * @deprecated
 * @param projectId
 * @param viewId
 * @param virtualLayerId
 * @param columnKey
 * @param divideByColumn
 */
export function makeSymbologyUserDataKeyNew(
  projectId: ProjectId,
  viewId: ViewId,
  virtualLayerId: LegacyVirtualLayerId,
  columnKey: ColumnKey,
  divideByColumn: string,
) {
  const divideByColumnKey = getSafeKey(divideByColumn);
  return `persist:userSymbology:${projectId}:${viewId}:${escape(
    virtualLayerId,
  )}:${columnKey}:${divideByColumnKey}`;
}
