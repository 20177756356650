import { DrawMode } from '@mapbox/mapbox-gl-draw';
import bboxPolygon from '@turf/bbox-polygon';
import { Geometries } from '@turf/helpers';
import { Feature, Polygon, Position } from 'geojson';

import { getIntersectingVerticesCoordPaths } from 'uf/map/draw';
import { setBoxSelectionMode } from 'uf/ui/map/modes/boxSelection';

export interface BoxSelectVerticesOptions {
  features: Feature<Geometries>[];
}

interface State {
  unsetMode: () => void;
}
const BoxSelectVertices: DrawMode<Polygon, BoxSelectVerticesOptions, State> = {
  onSetup({ features }: BoxSelectVerticesOptions) {
    const unsetMode = setBoxSelectionMode(
      this.map,
      makeOnBoxSelectVertices(features, this),
    );
    return {
      unsetMode,
    };
  },

  onStop(state: State) {
    state.unsetMode();
  },

  toDisplayFeatures() {
    // we let setBoxSelectionMode handle rendering, so this should just be a
    // noop
    return null;
  },
};

export default BoxSelectVertices;

function makeOnBoxSelectVertices(
  features: Feature<Geometries>[],
  drawMode: DrawMode<Polygon, BoxSelectVerticesOptions, State>,
) {
  // TODO: Make this work with `add`. This requires threading the existing
  // selected coordPaths into this mode.
  return (polygon: Position[], add: boolean) => {
    const [ne, , sw] = polygon;
    const minX = sw[0];
    const minY = sw[1];
    const maxX = ne[0];
    const maxY = ne[1];

    const rectangleFeature = bboxPolygon([minX, minY, maxX, maxY]);

    const vertices = getIntersectingVerticesCoordPaths(
      rectangleFeature,
      features,
    );
    drawMode.changeMode('direct_select', {
      featureId: features[0].id,
      coordPath: vertices[features[0].id],
    });
  };
}
