import {
  CuratedColumnSymbology,
  CUSTOM_THEME,
  DEFAULT_OPACITY,
  InterpolationTypes,
  PaintColorTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/cityofmadison/dataset/madison_future_land_use_update_2018_comp_plan': {
    gflu18_newcat: [
      {
        type: SymbologyTypes.FILL,
        paint: {
          'fill-color': {
            property: 'gflu18_newcat',
            type: PaintColorTypes.CATEGORICAL,
            stops: [
              { value: 'A', color: 'rgb(196, 180, 157)' },
              { value: 'CMU', color: 'rgb(138, 91, 156)' },
              { value: 'DC', color: 'rgb(0, 41, 94)' },
              { value: 'DMU', color: 'rgb(26, 100, 176)' },
              { value: 'E', color: 'rgb(246, 159, 161)' },
              { value: 'GC', color: 'rgb(228, 70, 70)' },
              { value: 'HR', color: 'rgb(154, 94, 50)' },
              { value: 'I', color: 'rgb(156, 156, 156)' },
              { value: 'LMR', color: 'rgb(251, 209, 89)' },
              { value: 'LR', color: 'rgb(255, 245, 168)' },
              { value: 'MR', color: 'rgb(211, 118, 53)' },
              { value: 'NMU', color: 'rgb(191, 158, 202)' },
              { value: 'NPA', color: 'rgb(114, 78, 31)' },
              { value: 'P', color: 'rgb(148, 203, 140)' },
              { value: 'RMU', color: 'rgb(118, 51, 147)' },
              { value: 'SI', color: 'rgb(194, 207, 234)' },
              { value: 'Water', color: 'rgb(151, 219, 242)' },
            ],
          },
          'fill-opacity': DEFAULT_OPACITY,
        },
        display_hints: {
          scale: SymbologyScales.CATEGORICAL,
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          theme: CUSTOM_THEME,
        },
      },
    ],
  },
};

export default symbologies;
