import colors from 'material-colors';

import {
  CuratedColumnSymbology,
  CUSTOM_THEME,
  DEFAULT_OPACITY,
  DistributionTypes,
  InterpolationTypes,
  PaintColorTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/public/dataset/hud_public_housing_buildings': {
    acc_units: [
      {
        type: SymbologyTypes.CIRCLE,
        paint: {
          'circle-radius': 3,
          'circle-color': {
            property: 'acc_units',
            type: PaintColorTypes.INTERVAL,
            stops: [
              { value: 0, label: 'Less than 100', color: '#aacff2' },
              { value: 100, label: '100 to 200', color: '#75a2d7' },
              { value: 200, label: '200 to 300', color: '#5383c5' },
              { value: 300, label: '300 to 400', color: '#3065b3' },
              { value: 400, label: '400 to 500', color: colors.blue[900] },
              { value: 500, label: 'More than 500', color: '#0d1b48' },
            ],
          },
          'circle-opacity': DEFAULT_OPACITY,
        },
        display_hints: {
          scale: SymbologyScales.NUMERIC,
          distribution: DistributionTypes.DEFAULT,
          interpolation: InterpolationTypes.INTERVAL,
          theme: CUSTOM_THEME,
        },
      },
    ],
  },
};

export default symbologies;
