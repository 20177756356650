import { Position } from 'geojson';
import { Map, MapMouseEvent, Point } from 'mapbox-gl';

import { DEFAULT_COORDINATE_DECIMAL_ACCURACY } from 'uf/base/map';

import styles from './boxSelection.module.css';

/**
 * Box Selection Mode:
 * Click-and-drag on the map to select a bounding box.
 * @param map - the mapboxgl map instance
 * @param onBoxSelect - called with arguments: features, LngLatBounds, map
 * @return unsetBoxSelectionMode - cleans up any Box Selection Mode artifacts
 */
export function setBoxSelectionMode(
  map: Map,
  onBoxSelect: (polygon: Position[], add: boolean) => void,
): () => void {
  const canvas = map.getCanvasContainer();
  canvas.style.cursor = 'crosshair';
  map.boxZoom.disable();
  map.dragPan.disable();
  map.doubleClickZoom.disable();

  map.on('mousedown', mousedown);

  let box = null;

  // returns a function that detaches the "mousedown" event handler
  return () => map.off('mousedown', mousedown);

  function mousedown(mousedownEvent: MapMouseEvent) {
    const startPoint = mousedownEvent.point;
    let currentPoint: Point;

    // Extents of the shape are tracked and used by moth mouse
    // related methods
    let minX = toFixedNumber(startPoint.x, DEFAULT_COORDINATE_DECIMAL_ACCURACY);
    let maxX = toFixedNumber(startPoint.x, DEFAULT_COORDINATE_DECIMAL_ACCURACY);
    let minY = toFixedNumber(startPoint.y, DEFAULT_COORDINATE_DECIMAL_ACCURACY);
    let maxY = toFixedNumber(startPoint.y, DEFAULT_COORDINATE_DECIMAL_ACCURACY);

    // Paint the draw box as the mouse moves
    map.on('mousemove', mousemove);

    // Retrieve the geo bounding box and underlying features
    map.on('mouseup', mouseup);

    function mousemove(mousemoveEvent) {
      currentPoint = mousemoveEvent.point;

      if (!box) {
        box = document.createElement('div');
        box.classList.add(styles.boxdraw);
        canvas.appendChild(box);
      }

      // Update the tracked extents points
      minX = toFixedNumber(
        Math.min(startPoint.x, currentPoint.x),
        DEFAULT_COORDINATE_DECIMAL_ACCURACY,
      );
      maxX = toFixedNumber(
        Math.max(startPoint.x, currentPoint.x),
        DEFAULT_COORDINATE_DECIMAL_ACCURACY,
      );
      minY = toFixedNumber(
        Math.min(startPoint.y, currentPoint.y),
        DEFAULT_COORDINATE_DECIMAL_ACCURACY,
      );
      maxY = toFixedNumber(
        Math.max(startPoint.y, currentPoint.y),
        DEFAULT_COORDINATE_DECIMAL_ACCURACY,
      );

      // Adjust width and xy position of the draw box element ongoing
      const pos = `translate(${minX}px, ${minY}px)`;
      box.style.transform = pos;
      box.style.WebkitTransform = pos;
      box.style.width = `${maxX - minX}px`;
      box.style.height = `${maxY - minY}px`;
    }

    function mouseup(mouseUpEvent: MapMouseEvent) {
      map.off('mousemove', mousemove);
      map.off('mouseup', mouseup);

      const add = mouseUpEvent.originalEvent.shiftKey;

      if (box) {
        box.parentNode.removeChild(box);
        box = null;
      }

      // ignore clicks where the user doesn't drag the mouse
      if (minX === maxX && minY === maxY) {
        return;
      }

      const ne = map.unproject([maxX, maxY]).toArray();
      const se = map.unproject([maxX, minY]).toArray();
      const sw = map.unproject([minX, minY]).toArray();
      const nw = map.unproject([minX, maxY]).toArray();
      const coordinates = [ne, se, sw, nw, ne];

      onBoxSelect(coordinates, add);
    }
  }
}

function toFixedNumber(num: number, numberOfDecimals: number) {
  const pow = 10 ** numberOfDecimals;
  return Math.round(num * pow) / pow;
}
