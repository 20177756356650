import { makeAsyncApiCaller } from 'uf/base/xhr';

export const getLibrary = makeAsyncApiCaller(
  apis => apis.builtforms.get_library,
);

export const updateLibraryDefaults = makeAsyncApiCaller(
  apis => apis.builtforms.update_library_defaults,
);
export const updateLibraryVariables = makeAsyncApiCaller(
  apis => apis.builtforms.update_library_variables,
);

export const getLibraryBuildings = makeAsyncApiCaller(
  apis => apis.builtforms.get_library_buildings,
);

export const getBuilding = makeAsyncApiCaller(
  apis => apis.builtforms.get_building,
);
export const createBuilding = makeAsyncApiCaller(
  apis => apis.builtforms.create_building,
);

export const deleteBuilding = makeAsyncApiCaller(
  apis => apis.builtforms.delete_building,
);
export const updateBuilding = makeAsyncApiCaller(
  apis => apis.builtforms.update_building,
);

export const getBuildingPreview = makeAsyncApiCaller(
  apis => apis.builtforms.get_building_preview_multi,
);

export const getLibraryBuildingTypes = makeAsyncApiCaller(
  apis => apis.builtforms.get_library_building_types,
);
export const updateLibraryBuildingTypeInfos = makeAsyncApiCaller(
  apis => apis.builtforms.update_building_type_infos,
);

export const updateLibraryPlaceTypeInfos = makeAsyncApiCaller(
  apis => apis.builtforms.update_place_type_infos,
);
export const getBuildingType = makeAsyncApiCaller(
  apis => apis.builtforms.get_building_type,
);

export const createBuildingType = makeAsyncApiCaller(
  apis => apis.builtforms.create_building_type,
);

export const deleteBuildingType = makeAsyncApiCaller(
  apis => apis.builtforms.delete_building_type,
);
export const updateBuildingType = makeAsyncApiCaller(
  apis => apis.builtforms.update_building_type,
);

export const getBuildingTypePreview = makeAsyncApiCaller(
  apis => apis.builtforms.get_building_type_preview,
);

export const getLibraryPlaceTypes = makeAsyncApiCaller(
  apis => apis.builtforms.get_library_place_types,
);

export const getPlaceType = makeAsyncApiCaller(
  apis => apis.builtforms.get_place_type,
);

export const createPlaceType = makeAsyncApiCaller(
  apis => apis.builtforms.create_place_type,
);
export const deletePlaceType = makeAsyncApiCaller(
  apis => apis.builtforms.delete_place_type,
);

export const updatePlaceType = makeAsyncApiCaller(
  apis => apis.builtforms.update_place_type,
);

export const getPlaceTypePreview = makeAsyncApiCaller(
  apis => apis.builtforms.get_place_type_preview,
);

export const bakeLibrary = makeAsyncApiCaller(
  apis => apis.internal.bake_library,
);
