import {
  Button,
  Classes,
  HTMLTable,
  IResizeEntry,
  Position,
  ResizeSensor,
  Spinner,
  SpinnerSize,
  Tooltip,
} from '@blueprintjs/core';
import { mdiClose } from '@mdi/js';
import { Icon } from '@mdi/react';
import classNames from 'classnames';
import _ from 'lodash';
import React, {
  CSSProperties,
  FunctionComponent,
  useCallback,
  useState,
} from 'react';
import { Box } from 'react-layout-components';
import { connect } from 'react-redux';
import compose from 'recompose/compose';

import { LayerMetadata } from 'uf-api';
import {
  getActiveProjectId,
  makeGetActiveScenarioIdForProject,
} from 'uf/app/selectors';
import { formatNumeric } from 'uf/base/formatting';
import { isLoading } from 'uf/data/dataState';
import { getActiveLayerId } from 'uf/explore/selectors/layers';
import { makeGetLayerInspectionQuery } from 'uf/explore/selectors/map';
import { makeGetActiveViewId } from 'uf/explore/selectors/views';
import { LayerId } from 'uf/layers';
import { shouldHideColumn } from 'uf/layers/metadata';
import popupStyles from 'uf/styles/popups.module.css';
import rootStyles from 'uf/styles/root.module.css';
import tableStyles from 'uf/styles/table.module.css';
import { elementsOverflow } from 'uf/ui/base/domHelpers';
import { IconSizeNew } from 'uf/ui/base/icon';
import {
  THICK_SPACING,
  THIN_SPACING,
  VERY_THICK_SPACING,
} from 'uf/ui/base/spacing';
import { DEFAULT_PANEL_WIDTH } from 'uf/ui/explore';
import { MAPBOX_PADDING } from 'uf/ui/map/layout';
import MapPanel from 'uf/ui/map/MapPanel';
import { withLayerSearchData } from 'uf/ui/search/hocs/withLayerSearchData';

const BASE_MAP_SWITCHER_BOTTOM_PLACEMENT = THICK_SPACING;
const BASE_MAP_SWITCHER_HEIGHT = VERY_THICK_SPACING;
const panelStyle: CSSProperties = {
  width: DEFAULT_PANEL_WIDTH,
  right: MAPBOX_PADDING,
  bottom:
    BASE_MAP_SWITCHER_BOTTOM_PLACEMENT +
    BASE_MAP_SWITCHER_HEIGHT +
    THIN_SPACING,
};

const bodyClassName = classNames(
  popupStyles.mediumPopup,
  rootStyles.veryThinPadding,
  rootStyles.scrollY,
);
const tableClassName = classNames(rootStyles.fit, tableStyles.fixed);

// TODO: Converted with script per issue #10495. Improve typing on next edit.
interface OwnProps {
  activeLayerMetadata?: LayerMetadata;
  inspection?: {
    point?: object;
    lngLat?: object;
    properties?: object;
  };
  onClearInspection?: (...args: any[]) => any;
  layerSearchData?: object;
}

interface StateProps {
  layerId: LayerId;
  query: any;
}

type Props = StateProps & OwnProps;

export const ExploreMapInspectionPanel: React.FunctionComponent<Props> =
  props => {
    const {
      activeLayerMetadata,
      inspection,
      onClearInspection,
      layerSearchData,
    } = props;
    if (!inspection || _.isEmpty(inspection.properties)) {
      return null;
    }
    const PanelHeading = (
      <Box
        className={classNames(
          rootStyles.flexBox,
          rootStyles.alignItemsCenter,
          rootStyles.veryThinPaddingVertical,
        )}
        fit>
        <Button minimal onClick={onClearInspection}>
          <Icon
            path={mdiClose}
            size={IconSizeNew.MEDIUM}
            title="Close window"
          />
        </Button>
        <h4>Feature Information</h4>
      </Box>
    );
    return (
      <MapPanel
        style={panelStyle}
        heading={PanelHeading}
        headingClassName={rootStyles.noPadding}
        bodyClassName={bodyClassName}>
        {renderSearchData(activeLayerMetadata.columns, layerSearchData)}
      </MapPanel>
    );
  };
function makeMapStateToProps() {
  const getLayerInspectionQuery = makeGetLayerInspectionQuery();
  const getActiveScenarioId = makeGetActiveScenarioIdForProject();
  const getActiveViewId = makeGetActiveViewId();
  return (state): StateProps => {
    const projectId = getActiveProjectId(state);
    const scenarioId = getActiveScenarioId(state, { projectId });
    const viewId = getActiveViewId(state, { projectId });
    const activeLayerId = getActiveLayerId(state, {
      projectId,
      scenarioId,
      viewId,
    });
    return {
      // passed to withLayerSearchData
      layerId: activeLayerId,
      query: getLayerInspectionQuery(state, { layerId: activeLayerId }),
    };
  };
}

const withInspectionDataFromSearch = compose<StateProps, OwnProps>(
  connect(makeMapStateToProps),
  withLayerSearchData(),
);

export default withInspectionDataFromSearch(ExploreMapInspectionPanel);
const labelStyle: CSSProperties = {
  width: '60%',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};
const valueStyle = {
  ...labelStyle,
  width: '40%',
};
function renderSearchData(columns, searchDataState) {
  if (isLoading(searchDataState)) {
    return <Spinner size={SpinnerSize.SMALL} />;
  }
  if (!columns || _.isEmpty(searchDataState.items)) {
    return null;
  }
  const [properties] = searchDataState.items;
  return renderFeatureInformation(columns, properties);
}
function renderFeatureInformation(columns, properties) {
  if (!columns || _.isEmpty(properties)) {
    return null;
  }
  const foundProperties = columns
    .filter(
      column =>
        properties[column.key] !== undefined && !shouldHideColumn(column),
    ) // 0 is okay
    .map(({ key, name, metatype }) => {
      const label =
        metatype === 'numeric'
          ? formatNumeric(properties[key])
          : properties[key];
      return (
        <tr key={key}>
          <td style={labelStyle}>
            <OverflowingTooltip label={name} />
          </td>
          <td style={valueStyle}>
            <OverflowingTooltip label={label} />
          </td>
        </tr>
      );
    });
  const columnKeys = _.keyBy(columns, column => column.key);
  // filter to only include properties with no corresponding column metadata
  const extraProperties = [];
  _.forEach(properties, (value, key) => {
    if (columnKeys[key] || key === '_internalSearchKey') {
      return;
    }
    extraProperties.push(
      <tr key={key}>
        <td style={labelStyle} title={key}>
          <OverflowingTooltip label={key} />
        </td>
        <td style={valueStyle} title={value}>
          <OverflowingTooltip label={value} />
        </td>
      </tr>,
    );
  });
  // add extra properties at the end of the table
  return (
    <HTMLTable striped condensed className={tableClassName}>
      <tbody>{[...foundProperties, ...extraProperties]}</tbody>
    </HTMLTable>
  );
}

const OverflowingTooltip: FunctionComponent<{
  label: string | JSX.Element;
}> = ({ label }) => {
  const [overflowed, setOverflowed] = useState(true);
  const onResize = useCallback(
    (entries: IResizeEntry[]) => {
      setOverflowed(elementsOverflow(entries.map(e => e.target)));
    },
    [setOverflowed],
  );

  return (
    <Tooltip
      wrapperTagName="div"
      targetTagName="div"
      disabled={!overflowed}
      position={Position.TOP}
      content={label}>
      <ResizeSensor onResize={onResize}>
        <div className={Classes.TEXT_OVERFLOW_ELLIPSIS}>{label}</div>
      </ResizeSensor>
    </Tooltip>
  );
};
