import classNames from 'classnames';
import _ from 'lodash';
import React, { FunctionComponent, useMemo } from 'react';
import ContainerDimensions from 'react-container-dimensions';
import { GlobalHotKeys } from 'react-hotkeys';
import { Box } from 'react-layout-components';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import {
  clearGeometryFilters as clearGeometryFiltersAction,
  clearPointFilter as clearPointFilterAction,
} from 'uf/explore/actions/filters';
import {
  clearMapInspection as clearMapInspectionAction,
  setTakeSnapshot as setTakeSnapshotAction,
  updateMapMode as updateMapModeAction,
} from 'uf/explore/actions/map';
import { UpdateMapModeAction } from 'uf/explore/ActionTypes';
import {
  makeGetLayerGeometryFilters,
  makeGetLayerPointFilter,
} from 'uf/explore/selectors/filters';
import {
  getMapMode,
  getSelectionModesDisabled,
  makeGetLayerInspection,
} from 'uf/explore/selectors/map';
import { MapMode } from 'uf/map/mapmode';
import { ProjectId } from 'uf/projects';
import { UFState } from 'uf/state';
import rootStyles from 'uf/styles/root.module.css';
import ExploreMap from 'uf/ui/explore/ExploreMap/ExploreMap';
import { useExploreMapClearButtonHandler } from 'uf/ui/explore/ExploreMap/ExploreMapClearButton/ExploreMapClearButton';
import { buttonsByKey } from 'uf/ui/explore/ExploreMap/ExploreMapToolbar/buttons';
import {
  ExploreMapHotKeyHandlers,
  ExploreMapHotKeys,
} from 'uf/ui/explore/ExploreMap/hotKeys';
import ExploreMapTopToolbar from 'uf/ui/explore/ExploreMapTopToolbar/ExploreMapTopToolbar';
import { useSnapshotHander } from 'uf/ui/explore/ExploreSnapshotButton/ExploreSnapshotButton';

const mapAndToolbarClass = classNames(
  rootStyles.flexBox,
  rootStyles.flex,
  rootStyles.flexColumn,
  rootStyles.overflowHidden,
);

interface Props {
  activeLayerId: string;
  projectId: ProjectId;
}

interface StateProps {
  hasInspection: boolean;
  hasSelection: boolean;
  mapMode?: MapMode;
  selectionModesDisabled?: boolean;
}

interface DispatchProps {
  updateMapMode: typeof updateMapModeAction;
  clearMapInspection: typeof clearMapInspectionAction;
  clearGeometryFilters: typeof clearGeometryFiltersAction;
  clearPointFilter: typeof clearPointFilterAction;
  setTakeSnapshot: typeof setTakeSnapshotAction;
}

type ExploreMapPaneProps = Props & StateProps & DispatchProps;

export const ExploreMapPane: FunctionComponent<ExploreMapPaneProps> = props => {
  const {
    activeLayerId,
    updateMapMode,
    projectId,
    setTakeSnapshot,
    clearMapInspection,
    clearGeometryFilters,
    clearPointFilter,
    mapMode,
    hasInspection,
    hasSelection,
    selectionModesDisabled,
  } = props;

  const modeHandlers = useHotKeyHandlers(
    updateMapMode,
    projectId,
    activeLayerId,
    selectionModesDisabled,
  );

  const clearHandler = useExploreMapClearButtonHandler(
    clearMapInspection,
    clearGeometryFilters,
    clearPointFilter,
    projectId,
    activeLayerId,
    mapMode,
    hasInspection,
    hasSelection,
  );

  const snapshotHandler = useSnapshotHander(setTakeSnapshot, projectId);

  const handlers: ExploreMapHotKeyHandlers = {
    ...modeHandlers,
    MAP_CLEAR_SELECTION: clearHandler,
    MAP_SNAPSHOT: snapshotHandler,
    DO_NOTHING: () => {},
  };

  return (
    <div className={mapAndToolbarClass}>
      <GlobalHotKeys
        allowChanges
        keyMap={ExploreMapHotKeys}
        handlers={handlers}
      />

      <ExploreMapTopToolbar projectId={projectId} layerId={activeLayerId} />
      <Box flex={1} className={rootStyles.overflowHidden}>
        <ContainerDimensions>
          {({ width, height }) => {
            if (__TESTING__) {
              return null;
            }
            return <ExploreMap height={height} width={width} />;
          }}
        </ContainerDimensions>
      </Box>
    </div>
  );
};

function makeMapStateToProps() {
  const getInspection = makeGetLayerInspection();
  const getLayerGeometryFilters = makeGetLayerGeometryFilters();
  const getLayerPointFilter = makeGetLayerPointFilter();

  return function mapStateToProps(state: UFState, props: Props): StateProps {
    const { projectId, activeLayerId: layerId } = props;
    const layerGeometryFilters = getLayerGeometryFilters(state, {
      projectId,
      layerId,
      parentLayerId: null,
    });
    const layerPointFilter = getLayerPointFilter(state, {
      projectId,
      layerId,
      parentLayerId: null,
    });
    const layerInspection = getInspection(state, {
      layerId,
    });
    const hasInspection = !_.isEmpty(layerInspection);
    const hasSelection =
      !_.isEmpty(layerPointFilter) || !_.isEmpty(layerGeometryFilters);
    return {
      hasInspection,
      hasSelection,
      mapMode: getMapMode(state),
      selectionModesDisabled: getSelectionModesDisabled(state),
    };
  };
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
  return {
    updateMapMode: bindActionCreators(updateMapModeAction, dispatch),
    clearMapInspection: bindActionCreators(clearMapInspectionAction, dispatch),
    clearGeometryFilters: bindActionCreators(
      clearGeometryFiltersAction,
      dispatch,
    ),
    clearPointFilter: bindActionCreators(clearPointFilterAction, dispatch),
    setTakeSnapshot: bindActionCreators(setTakeSnapshotAction, dispatch),
  };
}

export default connect<StateProps, DispatchProps, Props>(
  makeMapStateToProps,
  mapDispatchToProps,
)(ExploreMapPane);

function useHotKeyHandlers(
  updateMode: typeof updateMapModeAction,
  projectId: ProjectId,
  activeLayerId: string,
  selectionModesDisabled: boolean,
) {
  return useMemo(
    () =>
      getHotKeyHandlers(
        updateMode,
        projectId,
        activeLayerId,
        selectionModesDisabled,
      ),
    [updateMode, projectId, activeLayerId, selectionModesDisabled],
  );
}

const mapModesByHotKey = {
  MAP_PAN: MapMode.NORMAL,
  MAP_INSPECT: MapMode.INSPECTION,
  MAP_POINT_SELECT: MapMode.POINT_SELECTION,
  MAP_BOX_SELECT: MapMode.BOX_SELECTION,
  MAP_POLYGON_SELECT: MapMode.POLYGON_SELECTION,
};

// TODO: refactor code elsewhere so we can just fire an action without needing to worry if active
// layers set.  could have an epic the decides if we should actually update redux or make the
// toolbar smarter.
function getHotKeyHandlers(
  updateMode: typeof updateMapModeAction,
  projectId: ProjectId,
  activeLayerId: string,
  selectionModesDisabled: boolean,
): Record<string, () => UpdateMapModeAction> {
  return _.mapValues(mapModesByHotKey, mapMode => {
    const disabled =
      selectionModesDisabled ||
      buttonsByKey[mapMode].getDisabled(!!activeLayerId);

    if (disabled) {
      return null;
    }

    return () => updateMode(projectId, mapMode);
  });
}
