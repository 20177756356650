import {
  CuratedColumnSymbology,
  CUSTOM_THEME,
  InterpolationTypes,
  PaintColorTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/public/dataset/usfs_wildfire_hazard_potential_2018': {
    fire_risk: [
      {
        type: SymbologyTypes.FILL,
        paint: {
          'fill-color': {
            property: 'fire_risk',
            type: PaintColorTypes.CATEGORICAL,
            stops: [
              { value: 'Very Low', color: '#D3D3D3' },
              { value: 'Low', color: '#D3D3D3' },
              { value: 'Moderate', color: '#FF7F0E' },
              { value: 'High', color: '#D33115' },
              { value: 'Very High', color: '#9F0500' },
            ],
          },
          'fill-opacity': 0.5,
        },
        display_hints: {
          scale: SymbologyScales.CATEGORICAL,
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          theme: CUSTOM_THEME,
        },
      },
      {
        type: SymbologyTypes.LINE,
        paint: {
          'line-color': {
            property: 'fire_risk',
            type: PaintColorTypes.CATEGORICAL,
            stops: [
              { value: 'Very Low', color: '#D3D3D3' },
              { value: 'Low', color: '#D3D3D3' },
              { value: 'Moderate', color: '#FF7F0E' },
              { value: 'High', color: '#D33115' },
              { value: 'Very High', color: '#9F0500' },
            ],
          },
          'line-opacity': 0.5,
        },
        display_hints: {
          scale: SymbologyScales.CATEGORICAL,
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          theme: CUSTOM_THEME,
        },
      },
    ],
  },
};

export default symbologies;
