import { TaskStatuses } from 'uf/tasks/TaskStatuses';

export type ModuleKey = string;

// 'PENDING' - queue reqeusted
// 'QUEUED' - queued, not running
// 'STARTED' - actually running
// 'ERROR' - error
export enum ModuleRunStatus {
  // TODO: change this to 'ANALYSIS_PENDING' when analysis bar goes away
  PENDING = 'PENDING',
  QUEUED = 'ANALYSIS_QUEUED',
  STARTED = 'ANALYSIS_STARTED',
  DONE = 'ANALYSIS_DONE',
  ERROR = 'ANALYSIS_ERROR',
}

export const TaskStatusToModuleStatus = {
  [TaskStatuses.ENQUEUED]: ModuleRunStatus.QUEUED,
  [TaskStatuses.RUNNING]: ModuleRunStatus.STARTED,
  [TaskStatuses.DONE]: ModuleRunStatus.DONE,
  [TaskStatuses.ERROR]: ModuleRunStatus.ERROR,
  // this doesn't really apply since analysis can't be cancelled, mapping to DONE just in case
  [TaskStatuses.CANCELLED]: ModuleRunStatus.DONE,
};
