import { mdiDotsHorizontal } from '@mdi/js';
import { Icon } from '@mdi/react';
import classNames from 'classnames';
import * as d3Scale from 'd3-scale';
import _ from 'lodash';
import React, { FunctionComponent, useMemo } from 'react';
import { Box } from 'react-layout-components';
import { connect } from 'react-redux';

import { User } from 'uf-api';
import { getActiveProjectId } from 'uf/app/selectors';
import { Collaborator } from 'uf/collaboration/Collaborator';
import { makeGetCollaboratorsForProject } from 'uf/collaboration/selectors';
import rootStyles from 'uf/styles/root.module.css';
import { IconSizeNew } from 'uf/ui/base/icon';
import {
  CircleInfo,
  SharingCircle,
} from 'uf/ui/collaboration/SharingCircles/SharingCircle';
import { getUser } from 'uf/user/selectors/user';

interface StateProps {
  collaborators: Collaborator[];
  user: User;
}

interface OwnProps {
  className?: string;
  circleClassName?: string;
  maxCircles?: number;
  overflowCircleClassName?: string;
}

type Props = StateProps & OwnProps;

const defaultColorPalette = [
  'var(--color-deep-orange)',
  'var(--color-amber)',
  'var(--color-cyan)',
  'var(--color-blue)',
  'var(--color-green)',
];

export const SharingCircles: FunctionComponent<Props> = props => {
  const { className, circleClassName, maxCircles, user, collaborators } = props;
  const getCircleColor = useMemo(
    () => d3Scale.scaleOrdinal<string, string>().range(defaultColorPalette),
    [],
  );

  const circles = useMemo(() => {
    const userCircle: CircleInfo = user && {
      key: user.key,
      icon: user?.first_name?.[0].toLocaleUpperCase(),
      tooltipText: `${user.first_name} ${user.last_name}`,
    };

    const allCircles: CircleInfo[] = collaborators.map(collaborator => ({
      key: collaborator.key,
      icon: collaborator?.first_name?.[0].toLocaleUpperCase(),
      tooltipText: `${collaborator.first_name} ${collaborator.last_name}`,
    }));

    if (userCircle) {
      return [...allCircles, userCircle];
    }
    return allCircles;
  }, [collaborators, user]);

  if (_.isEmpty(circles)) {
    return null;
  }

  let visibleCircles = circles;
  let overflowCircles: CircleInfo[];
  if (circles.length > maxCircles) {
    visibleCircles = circles.slice(0, maxCircles);
    overflowCircles = circles.slice(maxCircles);
  }

  return (
    <Box
      alignItems="center"
      className={classNames(className, rootStyles.thinPaddingRight)}>
      {overflowCircles && (
        <SharingCircle
          key="overflow-circle"
          className={circleClassName}
          color="var(--color-blue-grey-400)"
          icon={
            <Icon
              path={mdiDotsHorizontal}
              className={'SharingCircles-overflow'}
              size={IconSizeNew.EXTRA_SMALL}
            />
          }
          tooltipText={getOverflowText(overflowCircles)}
        />
      )}
      {visibleCircles.map(circle => (
        <SharingCircle
          className={circleClassName}
          color={getCircleColor(circle.key)}
          key={circle.key}
          {...circle}
        />
      ))}
    </Box>
  );
};

function makeMapStateToProps() {
  const getCollaborators = makeGetCollaboratorsForProject();
  // don't pass a new list every time
  return (state): StateProps => {
    const projectId = getActiveProjectId(state);
    const collaborators = getCollaborators(state, { projectId });
    const user = getUser(state);
    return {
      collaborators,
      user,
    };
  };
}

export default connect<StateProps, never, OwnProps>(makeMapStateToProps)(
  SharingCircles,
);

function getOverflowText(circles: CircleInfo[]) {
  return (
    <div>
      {circles.map(({ key, tooltipText }) => (
        <div key={key}>{tooltipText}</div>
      ))}
    </div>
  );
}
