import { createSelector } from 'reselect';

import { LayerMetadata, LayerStats } from 'uf-api';
import { makeGetFromPropsSelector } from 'uf/base/selector';
import {
  combineDataStates,
  DataState,
  EmptyState,
  getData,
} from 'uf/data/dataState';
import { ColumnKey, LayerId } from 'uf/layers';
import {
  makeGetLayerMetadataState,
  makeGetLayerMetadataStateGetter,
} from 'uf/layers/selectors/metadata';
import { ProjectId } from 'uf/projects';
import {
  makeGetSymbologyStatsState,
  makeGetSymbologyStatsStateGetter,
} from 'uf/symbology/selectors/stats';
import { columnUsesStats } from 'uf/symbology/stats';

export function makeGetCuratedSymbologyLoadingState() {
  return createSelector(
    makeGetLayerMetadataState(),
    makeGetSymbologyStatsState(),
    makeGetFromPropsSelector<ColumnKey, 'columnKey'>('columnKey'),
    (layerMetadataState, layerStatsState, columnKey): DataState<null> =>
      getCuratedSymbologyLoadingState(
        layerMetadataState,
        layerStatsState,
        columnKey,
      ),
  );
}

export function makeGetCuratedSymbologyLoadingStateGetter() {
  return createSelector(
    makeGetLayerMetadataStateGetter(),
    makeGetSymbologyStatsStateGetter(),
    (getLayerMetadataState, getSymbologyStatsState) =>
      (projectId: ProjectId, layerId: LayerId, columnKey: ColumnKey) => {
        const layerMetadataState = getLayerMetadataState(layerId);
        const layerStatsState = getSymbologyStatsState(
          projectId,
          layerId,
          columnKey,
        );

        return getCuratedSymbologyLoadingState(
          layerMetadataState,
          layerStatsState,
          columnKey,
        );
      },
  );
}

function getCuratedSymbologyLoadingState(
  layerMetadataState: DataState<LayerMetadata>,
  layerStatsState: DataState<LayerStats>,
  columnKey: ColumnKey,
): DataState<null> {
  const needStats = columnUsesStats(getData(layerMetadataState), columnKey);
  // If metadata or stats haven't been kicked off, we're totally idle
  if (!layerMetadataState || (needStats && !layerStatsState)) {
    return EmptyState;
  }
  const loadingStates: DataState<LayerMetadata | LayerStats>[] = [
    layerMetadataState,
  ];

  if (needStats) {
    loadingStates.push(layerStatsState);
  }
  return combineDataStates(loadingStates);
}
