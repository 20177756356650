import {
  CuratedColumnSymbology,
  InterpolationTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/public/dataset/hifld_us_daycare_centers': {
    naics_desc: [
      {
        display_hints: {
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          scale: SymbologyScales.CATEGORICAL,
          theme: 'custom',
        },
        paint: {
          'circle-color': {
            default: '#d62728',
            property: 'naics_desc',
            stops: [
              {
                color: '#ab149e',
                value:
                  'CHILD DAY CARE, BEFORE OR AFTER SCHOOL, SEPARATE FROM SCHOOLS',
              },
              {
                color: '#7b64ff',
                value: 'CHILD DAY CARE CENTERS',
              },
              {
                color: '#73d8ff',
                value: 'HEAD START PROGRAMS, SEPARATE FROM SCHOOLS',
              },
            ],
            type: 'categorical',
          },
          'circle-opacity': 0.6,
          'circle-stroke-color': 'transparent',
        },
        type: SymbologyTypes.CIRCLE,
      },
    ],
  },
};

export default symbologies;
