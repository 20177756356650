import { GeoJsonGeometryTypes } from 'geojson';
import MaterialColors from 'material-colors';

import { ColumnKey, LayerId } from 'uf/layers';
import {
  CUSTOM_THEME,
  DEFAULT_LINE_WIDTH,
  DEFAULT_OPACITY,
  InterpolationTypes,
  LayerColumnSymbology,
  SymbologyDisplayHints,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';
import { makeStyleSymbologyId } from 'uf/symbology/spec/id';

export function createDefaultGeometrySymbology(
  layerId: LayerId,
  columnKey: ColumnKey,
  ufGeometryType: GeoJsonGeometryTypes,
): LayerColumnSymbology[] {
  switch (ufGeometryType) {
    case 'MultiPolygon':
    case 'Polygon':
      return [
        generateDefaultGeometryFillSymbology(layerId, columnKey),
        generateDefaultGeometryLineSymbology(layerId, columnKey),
      ];

    case 'MultiLineString':
    case 'LineString':
      return [generateDefaultGeometryLineSymbology(layerId, columnKey)];

    case 'MultiPoint':
    case 'Point':
      return [generateDefaultGeometryCircleSymbology(layerId, columnKey)];

    default:
      return [];
  }
}

export function generateDefaultGeometryFillSymbology(
  layerId: LayerId,
  columnKey: ColumnKey,
): LayerColumnSymbology {
  return {
    id: makeStyleSymbologyId(layerId, columnKey, SymbologyTypes.FILL),
    type: SymbologyTypes.FILL,
    paint: {
      'fill-color': 'transparent',
      'fill-opacity': DEFAULT_OPACITY,
    },
    display_hints: makeDisplayHints(),
  };
}

export function generateDefaultGeometryLineSymbology(
  layerId: LayerId,
  columnKey: ColumnKey,
  isTransparent?: boolean,
): LayerColumnSymbology {
  return {
    id: makeStyleSymbologyId(layerId, columnKey, SymbologyTypes.LINE),
    type: SymbologyTypes.LINE,
    paint: {
      'line-color': isTransparent ? 'transparent' : MaterialColors.grey[700],
      'line-width': DEFAULT_LINE_WIDTH,
      'line-opacity': DEFAULT_OPACITY,
    },
    display_hints: makeDisplayHints(),
  };
}

export function generateDefaultGeometryCircleSymbology(
  layerId: LayerId,
  columnKey: ColumnKey,
  isTransparent?: boolean,
): LayerColumnSymbology {
  return {
    id: makeStyleSymbologyId(layerId, columnKey, SymbologyTypes.CIRCLE),
    type: SymbologyTypes.CIRCLE,
    paint: {
      'circle-color': isTransparent ? 'transparent' : MaterialColors.grey[800],
      'circle-radius': 2,
      'circle-stroke-color': 'transparent',
      'circle-opacity': DEFAULT_OPACITY,
    },
    display_hints: makeDisplayHints(),
  };
}

export function makeDisplayHints(): SymbologyDisplayHints {
  return {
    scale: SymbologyScales.CATEGORICAL,
    distribution: null,
    interpolation: InterpolationTypes.CATEGORICAL,
    theme: CUSTOM_THEME,
  };
}
