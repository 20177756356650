import _ from 'lodash';
import uniqid from 'uniqid';
import warning from 'warning';

import { LayerMetadata } from 'uf-api';
import { assertNever } from 'uf/base/never';
import { NativeValue } from 'uf/layers/formatting';
import { GEOMETRY_KEY } from 'uf/layers/geometryKey';
import { getColumnMetadata } from 'uf/layers/metadata';

const BOOLEAN_PERCENT_THRESHOLD = 0.5;
export function generateUniqueFeatureProperties(
  layerMetadata: LayerMetadata,
): Record<string, NativeValue> {
  const { unique_keys: uniqueKeys = [] } = layerMetadata;
  warning(
    !!uniqueKeys.length,
    `Cannot generate feature for ${layerMetadata.full_path} if unique_keys is empty`,
  );
  const uniqueProperties = uniqueKeys.map((key): [string, string] => {
    const column = getColumnMetadata(layerMetadata, key);
    switch (column.type) {
      case 'bigint':
      case 'int':
      case 'float':
        return [key, `${Math.floor(Math.random() * 10 * 1000 * 1000)}`];
      case 'boolean':
        return [key, `${Math.random() > BOOLEAN_PERCENT_THRESHOLD}`];
      case 'bytes':
      case 'string':
        return [key, uniqid()];
      default:
        assertNever(
          column.type,
          `No type found for column ${key} in ${layerMetadata.full_path}`,
        );
    }
  });
  if (!_.isEmpty(getColumnMetadata(layerMetadata, GEOMETRY_KEY))) {
    uniqueProperties.push([GEOMETRY_KEY, uniqid()]);
  }
  return _.fromPairs(uniqueProperties);
}

export function extractUniqueKeyValues(
  layerMetadata: LayerMetadata,
  featureProperties: Record<string, NativeValue>,
) {
  const { unique_keys: uniqueKeys } = layerMetadata;
  return _.fromPairs(uniqueKeys.map(key => [key, featureProperties[key]]));
}

/**
 * Generate a feature id from uniqueKeyys. Note that this currently matches the
 * server's `get_layer_geojson` implementation for creating feature ids.
 */
export function makeFeatureId(
  layerMetadata: LayerMetadata,
  featureProperties: Record<string, NativeValue>,
): string {
  const { unique_keys: uniqueKeys } = layerMetadata;
  return uniqueKeys.map(key => featureProperties[key]).join(':');
}
