/**
 * UrbanFootprint App API
 * API for serving UrbanFootprint Application
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { ClientResponse } from '../variables';

// @ts-ignore: This is used only for params, but some services have param-free APIs
import { GeoServiceInterface } from './geo.serviceInterface';

// @ts-ignore: These imports are for both params and returnType, but we only use returnType
import { ProblemDetail } from '../model/problemDetail';

/**
 * Package: api
 */

const loadTemporaryShapesPrefix = `uf-api/geo/load_temporary_shapes/`;
/**
 * Action types for geo.load_temporary_shapes
 *
 * Upload a set of referencable geometries that will live for a brief period of time.
 */
export const loadTemporaryShapesActionTypes = {
  LOAD: `${loadTemporaryShapesPrefix}LOAD` as 'uf-api/geo/load_temporary_shapes/LOAD',
  SUCCESS:
    `${loadTemporaryShapesPrefix}SUCCESS` as 'uf-api/geo/load_temporary_shapes/SUCCESS',
  FAILURE:
    `${loadTemporaryShapesPrefix}FAILURE` as 'uf-api/geo/load_temporary_shapes/FAILURE',
  CLEAR:
    `${loadTemporaryShapesPrefix}CLEAR` as 'uf-api/geo/load_temporary_shapes/CLEAR',
} as const;

/**
 * LOAD action for geo.load_temporary_shapes
 * Upload a set of referencable geometries that will live for a brief period of time.
 */
export interface loadTemporaryShapesLoadAction {
  readonly type: 'uf-api/geo/load_temporary_shapes/LOAD';
  readonly params: GeoServiceInterface.loadTemporaryShapesParams;
  readonly promise: Promise<ClientResponse<string>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for geo.load_temporary_shapes
 * Upload a set of referencable geometries that will live for a brief period of time.
 */
export interface loadTemporaryShapesSuccessAction {
  readonly type: 'uf-api/geo/load_temporary_shapes/SUCCESS';
  readonly result: string;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for geo.load_temporary_shapes
 * Upload a set of referencable geometries that will live for a brief period of time.
 */
export interface loadTemporaryShapesFailureAction {
  readonly type: 'uf-api/geo/load_temporary_shapes/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for geo.load_temporary_shapes
 * Upload a set of referencable geometries that will live for a brief period of time.
 */
export interface loadTemporaryShapesClearAction {
  readonly type: 'uf-api/geo/load_temporary_shapes/CLEAR';
  readonly key: string;
}

export const geoActionTypesMap = {
  /** Action types for geo.load_temporary_shapes */
  load_temporary_shapes: loadTemporaryShapesActionTypes,
} as const;

export const geoDetailsMap = {
  load_temporary_shapes: {
    /** HTTP method for geo.load_temporary_shapes */
    httpMethod: 'post' as const,
  },
} as const;

/**
 * Local DataState<T> - kept in sync with uf/data/dataState.ts
 */
interface DataState<T> {
  readonly loading: boolean;
  readonly loaded: boolean;
  readonly requestId: string;
  readonly requestTime?: string;
  readonly responseTime?: string;
  readonly requestCount?: number;
  readonly error?: any;
  readonly promise?: Promise<T>;
  readonly data?: T;
  readonly extra?: any;
  readonly key?: string;
}
export interface GeoServiceState {
  /** all recent loadTemporaryShapes results (POST/PUT/PATCH) */
  readonly load_temporary_shapes: DataState<string>[];
}
