import {
  Button,
  Classes,
  Menu,
  MenuDivider,
  MenuItem,
  Popover,
  Spinner,
  SpinnerSize,
  Tag,
} from '@blueprintjs/core';
import {
  mdiAccessPoint,
  mdiCheck,
  mdiContentDuplicate,
  mdiContentSave,
  mdiDownload,
  mdiFileRestore,
  mdiFlag,
  mdiFlagCheckered,
  mdiMemory,
  mdiOpenInNew,
  mdiProgressWrench,
  mdiReact,
  mdiWrench,
} from '@mdi/js';
import { Icon } from '@mdi/react';
import AnsiUp from 'ansi_up';
import classNames from 'classnames';
import { format } from 'd3-format';
import _ from 'lodash';
import sizeof from 'object-sizeof';
import React, {
  CSSProperties,
  Fragment,
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Box } from 'react-layout-components';
import { connect, useStore } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import Link from 'redux-first-router-link';
import { ThunkActionMapDispatch } from 'redux-thunk';

import { ProjectMetadata } from 'uf-api';
import { getActiveProjectId } from 'uf/app/selectors';
import * as appActionCreators from 'uf/appservices/actions';
import { formatPercent } from 'uf/base/formatting';
import * as debugActionCreators from 'uf/debug/actions';
import { beginExport } from 'uf/layers/actions/export';
import {
  hasHiddenAsyncActions,
  hasHiddenExploreMapActions,
  hasHiddenMapboxActions,
  hasHiddenNonUfActions,
  hideAsyncActions,
  hideExploreMapActions,
  hideMapboxActions,
  hideNonUfActions,
} from 'uf/logger';

import { ProjectId } from 'uf/projects';
import { makeGetProjectById } from 'uf/projects/selectors';
import rootStyles from 'uf/styles/root.module.css';

import { IconSizeNew } from 'uf/ui/base/icon';
import HmrStatus from 'uf/ui/debug/HmrStatus/HmrStatus';
import * as flagActionCreators from 'uf/user/actions/flags';
import {
  getAvailableFlags,
  getUserFlags,
  makeGetUserFlag,
} from 'uf/user/selectors/flags';
import { getUserIsSuperuser } from 'uf/user/selectors/user';

interface OwnProps {
  className?: string;
}

interface StateProps {
  availableFlags: string[];
  userFlags: string[];
  isDeveloper: boolean;
  isSuperuser: boolean;
  projectId: ProjectId;
  project: ProjectMetadata;
}

interface DispatchProps {
  debugActions: ThunkActionMapDispatch<typeof debugActionCreators>;
  appActions: ThunkActionMapDispatch<typeof appActionCreators>;
  flagActions: ThunkActionMapDispatch<typeof flagActionCreators>;

  onExport: typeof beginExport;
}

type Props = OwnProps & StateProps & DispatchProps;

/** The menu transition time in ms - this is overridden here so that integration
 * tests have faster access to menus. Note this is set to `1` so that transition
 * end events still fire */
const MENU_TRANSITION_DURATION = 1;
const HMR_STYLE: CSSProperties = {
  position: 'absolute',
  top: 0,
  left: 0,
  zIndex: 10000,
};
const MENU_MODIFIERS = { preventOverflow: { enabled: true } };
export const DeveloperTools: FunctionComponent<Props> = props => {
  const {
    project,
    onExport,
    debugActions,
    appActions,
    flagActions,
    availableFlags,
    userFlags,
    isDeveloper,
    isSuperuser,
    className,
    projectId,
  } = props;
  const handleExport = useCallback(() => {
    const { built_forms_layer: layer } = project;
    const version = layer.version || layer?.details?.latest_version;
    onExport(
      projectId,
      layer.full_path,
      version,
      'csv',
      false,
      null,
      null,
      null,
    );
  }, [onExport, project, projectId]);
  const ansiUp = useMemo(() => new AnsiUp(), []);

  const addAppMessage = useCallback(
    (message, level) =>
      appActions.addAppMessage(ansiUp.ansi_to_html(message), {
        level,
        isTrustedHtml: true,
      }),
    [ansiUp, appActions],
  );

  const featureFlags = availableFlags.filter(flag => !isDeveloperFlag(flag));
  const developerFlags = availableFlags.filter(flag => isDeveloperFlag(flag));

  if (!isDeveloper) {
    return null;
  }

  const buttonClassName = classNames('DeveloperTools', {
    [Classes.INTENT_WARNING]: !isSuperuser,
  });

  return (
    <Box justifyContent="center" alignItems="center">
      {__DEVELOPMENT__ && (
        <HmrStatus key="hmr" style={HMR_STYLE} onMessage={addAppMessage} />
      )}
      <Popover
        autoFocus={false}
        transitionDuration={MENU_TRANSITION_DURATION}
        content={
          <DeveloperToolsMenu
            isSuperuser={isSuperuser}
            debugActions={debugActions}
            projectId={projectId}
            featureFlags={featureFlags}
            userFlags={userFlags}
            flagActions={flagActions}
            developerFlags={developerFlags}
            onExport={handleExport}
          />
        }
        modifiers={MENU_MODIFIERS}>
        <Button
          icon={<Icon path={mdiWrench} size={IconSizeNew.SMALL} />}
          minimal
          small
          className={classNames(buttonClassName, className)}
          id="uf-header-internal"
        />
      </Popover>
    </Box>
  );
};

interface FlagMenuProps {
  featureFlags: string[];
  flagsActive: string[];
  updateUserFlags: (flags: string[], value: boolean) => void;
}

const FlagMenu: FunctionComponent<FlagMenuProps> = ({
  featureFlags,
  flagsActive,
  updateUserFlags,
}: FlagMenuProps) => {
  return (
    <Fragment>
      {_.sortBy(featureFlags).map(flag => (
        <FeatureFlagMenuItem
          key={flag}
          flag={flag}
          updateUserFlags={updateUserFlags}
          active={flagsActive.includes(flag)}
        />
      ))}
    </Fragment>
  );
};

interface MenuProps {
  isSuperuser: boolean;
  debugActions: ThunkActionMapDispatch<typeof debugActionCreators>;
  projectId: ProjectId;
  featureFlags: string[];
  userFlags: string[];
  flagActions: ThunkActionMapDispatch<typeof flagActionCreators>;
  developerFlags: string[];
  onExport: () => void;
}

const MENUITEM_POPOVER_PROPS = { transitionDuration: MENU_TRANSITION_DURATION };
const DeveloperToolsMenu: FunctionComponent<MenuProps> = ({
  isSuperuser,
  debugActions,

  projectId,
  featureFlags,
  userFlags,
  flagActions,
  developerFlags,
  onExport,
}) => {
  const onThrowError = useCallback(() => {
    throw new Error(
      `Developer menu dummy error: ${/* nonce */ Math.random()} `,
    );
  }, []);

  const onPingBySession = useCallback(
    () => debugActions.ping({ notify_session: true }),
    [debugActions],
  );
  const onPingByUsername = useCallback(
    () => debugActions.ping({ notify_user: true }),
    [debugActions],
  );
  const onPingByProject = useCallback(
    () => debugActions.ping({ notify_project_id: projectId }),
    [debugActions, projectId],
  );
  const onHideAsyncActions = useCallback(
    () => hideAsyncActions(!hasHiddenAsyncActions()),
    [],
  );
  const onHideNonUfActions = useCallback(
    () => hideNonUfActions(!hasHiddenNonUfActions()),
    [],
  );

  const onHideExploreMapActions = useCallback(
    () => hideExploreMapActions(!hasHiddenExploreMapActions()),
    [],
  );
  const onHideMapboxActions = useCallback(
    () => hideMapboxActions(!hasHiddenMapboxActions()),
    [],
  );

  const [memoryDetail, memoryError] = useMeasureMemory();
  return (
    <Menu>
      {!isSuperuser && (
        <MenuDivider
          title={<strong>WARNING: Not superuser!</strong>}
          className={Classes.INTENT_WARNING}
        />
      )}
      <MenuDivider title="Debugging" />
      <Link to="/internal">
        <MenuItem
          text="Internal tools"
          icon={<Icon path={mdiOpenInNew} size={IconSizeNew.EXTRA_SMALL} />}
        />
      </Link>

      <MenuItem
        popoverProps={MENUITEM_POPOVER_PROPS}
        text="Developer Tools"
        icon={<Icon path={mdiProgressWrench} size={IconSizeNew.EXTRA_SMALL} />}
        className={'DeveloperTools-developerTools'}>
        <MenuItem text="Throw an error" icon="error" onClick={onThrowError} />
        <MenuItem
          text="Clone Demo Project"
          icon={
            <Icon path={mdiContentDuplicate} size={IconSizeNew.EXTRA_SMALL} />
          }
          className={'DeveloperTools-createProjectFromDemo'}
          onClick={debugActions.createDemoProject}
        />
        {__DEVELOPMENT__ && (
          <MenuItem
            icon={<Icon path={mdiFileRestore} size={IconSizeNew.EXTRA_SMALL} />}
            text="Caching">
            <MenuItem
              onClick={debugActions.clearFlaskCache}
              text="Clear Flask Cache"
            />
          </MenuItem>
        )}
        <MenuItem
          popoverProps={MENUITEM_POPOVER_PROPS}
          text="Persistence"
          icon={<Icon path={mdiContentSave} size={IconSizeNew.EXTRA_SMALL} />}
          className={'DeveloperTools-persistence'}
          id="uf-dev-persistence">
          <MenuItem
            text="Clear user prefs"
            className={'DeveloperTools-clearPrefs'}
            onClick={debugActions.clearPrefs}
          />
          <MenuItem
            text="Clear all user symbology"
            className={'DeveloperTools-clearAllUserSymbology'}
            onClick={debugActions.clearAllUserSymbology}
          />
          <MenuItem
            text="Clear all user persistence"
            className={'DeveloperTools-clearAllPersistence'}
            onClick={debugActions.clearAllPersistence}
          />
        </MenuItem>
        <MenuItem
          popoverProps={MENUITEM_POPOVER_PROPS}
          text="Ping"
          icon={<Icon path={mdiAccessPoint} size={IconSizeNew.EXTRA_SMALL} />}>
          <MenuItem onClick={onPingBySession} text="...by Session" />
          <MenuItem onClick={onPingByUsername} text="...by Username" />
          <MenuItem onClick={onPingByProject} text="...by Project" />
        </MenuItem>
        <MenuItem
          popoverProps={MENUITEM_POPOVER_PROPS}
          text="Download"
          icon={<Icon path={mdiDownload} size={IconSizeNew.EXTRA_SMALL} />}>
          <MenuItem onClick={onExport} text="Flat BuiltForms" />
        </MenuItem>
      </MenuItem>

      <MenuItem
        text="Action Logger"
        popoverProps={MENUITEM_POPOVER_PROPS}
        icon={<Icon path={mdiReact} size={IconSizeNew.EXTRA_SMALL} />}>
        <MenuItem
          icon={
            <Icon
              path={mdiCheck}
              className={hasHiddenAsyncActions() ? rootStyles.invisible : null}
              size={IconSizeNew.SMALL}
            />
          }
          onClick={onHideAsyncActions}
          text="Hide UF async actions"
        />
        <MenuItem
          icon={
            <Icon
              path={mdiCheck}
              className={hasHiddenNonUfActions() ? rootStyles.invisible : null}
              size={IconSizeNew.SMALL}
            />
          }
          onClick={onHideNonUfActions}
          text="Hide non-UF actions"
        />
        <MenuItem
          icon={
            <Icon
              path={mdiCheck}
              className={
                hasHiddenExploreMapActions() ? rootStyles.invisible : null
              }
              size={IconSizeNew.SMALL}
            />
          }
          onClick={onHideExploreMapActions}
          text="Hide explore map actions"
        />
        <MenuItem
          icon={
            <Icon
              path={mdiCheck}
              className={hasHiddenMapboxActions() ? rootStyles.invisible : null}
              size={IconSizeNew.SMALL}
            />
          }
          onClick={onHideMapboxActions}
          text="Hide mapbox actions"
        />
      </MenuItem>

      <MenuItem
        popoverProps={MENUITEM_POPOVER_PROPS}
        text="Feature Flags"
        icon={<Icon path={mdiFlag} size={IconSizeNew.EXTRA_SMALL} />}>
        <FlagMenu
          featureFlags={featureFlags}
          flagsActive={userFlags}
          updateUserFlags={flagActions.updateUserFlags}
        />
      </MenuItem>

      <MenuItem
        popoverProps={MENUITEM_POPOVER_PROPS}
        text="Developer Flags"
        icon={<Icon path={mdiFlagCheckered} size={IconSizeNew.EXTRA_SMALL} />}>
        <FlagMenu
          featureFlags={developerFlags}
          flagsActive={userFlags}
          updateUserFlags={flagActions.updateUserFlags}
        />
      </MenuItem>
      {(performance as any).measureMemory && (
        <MenuItem
          text={getMemorySummary(memoryDetail, memoryError)}
          icon={
            memoryDetail || memoryError ? (
              <Icon path={mdiMemory} size={IconSizeNew.EXTRA_SMALL} />
            ) : (
              <Spinner size={SpinnerSize.SMALL} />
            )
          }>
          {memoryDetail ? (
            <MemoryDetail memoryDetail={memoryDetail} />
          ) : undefined}
        </MenuItem>
      )}
    </Menu>
  );
};

const MemoryDetail: FunctionComponent<{
  memoryDetail: MemoryMeasurement;
}> = props => {
  const { memoryDetail } = props;

  const groupsByUrl = Object.entries(
    _.groupBy(memoryDetail.breakdown, b => b.attribution[0]),
  );
  _.sortBy(groupsByUrl, ([url]) => url);
  const store = useStore();

  const [reduxSize, setReduxSize] = useState<number>(null);
  const state = store.getState();
  useEffect(() => {
    setReduxSize(sizeof(state));
  }, [state]);

  return (
    <div className={rootStyles.maxWidthLarge}>
      {groupsByUrl.map(([url, items]) => (
        <Fragment key={url}>
          <MenuDivider title={getMemoryGroupTitle(url)} />
          {items.map((item, index) => (
            <MenuItem
              key={index}
              text={getMemoryBreakdownSummary(item, memoryDetail.bytes)}
            />
          ))}
        </Fragment>
      ))}
      <MenuDivider title={`Redux: ${formatMemory(reduxSize)}`} />
    </div>
  );
};

function isDeveloperFlag(flag: string) {
  return flag.startsWith('dev-') || flag === 'developer';
}

function mapStateToProps() {
  const getIsDeveloper = makeGetUserFlag('developer');

  const getProject = makeGetProjectById();

  return (state): StateProps => {
    const projectId = getActiveProjectId(state);
    return {
      projectId,
      availableFlags: getAvailableFlags(state),
      userFlags: getUserFlags(state),
      isDeveloper: getIsDeveloper(state),
      isSuperuser: getUserIsSuperuser(state),
      project: getProject(state, { projectId }),
    };
  };
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
  return {
    debugActions: bindActionCreators(debugActionCreators, dispatch),
    appActions: bindActionCreators(appActionCreators, dispatch),
    flagActions: bindActionCreators(flagActionCreators, dispatch),
    onExport: bindActionCreators(beginExport, dispatch),
  };
}
export default connect<StateProps, DispatchProps, OwnProps>(
  mapStateToProps,
  mapDispatchToProps,
)(DeveloperTools);

interface MemoryMeasurement {
  breakdown: {
    bytes: number;
    attribution: string[];
    userAgentSpecificTypes: string[];
  }[];
  bytes: number;
}

function useMeasureMemory(): [MemoryMeasurement, Error] {
  const isMounted = useRef(true);
  const [memoryDetail, setMemoryDetail] = useState(null);
  const [memoryError, setMemoryError] = useState(null);
  const onMemoryReady = useCallback(md => {
    if (isMounted.current) {
      setMemoryDetail(md);
    }
  }, []);
  const onMemoryError = useCallback(err => {
    if (isMounted.current) {
      setMemoryError(err);
    }
  }, []);
  useEffect(() => {
    if (!(performance as any).measureMemory) {
      return;
    }
    (performance as any)
      .measureMemory()
      .then(onMemoryReady)
      .catch(onMemoryError);
    return () => {
      isMounted.current = false;
    };
    // only execute once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [memoryDetail, memoryError];
}

const formatMemory = format('.4~s');
function getMemorySummary(memoryDetail: MemoryMeasurement, memoryError: Error) {
  if (!memoryDetail) {
    return 'memory scan...';
  }
  if (memoryError) {
    return `Error: ${memoryError}`;
  }
  return `Memory: ${formatMemory(memoryDetail.bytes)}`;
}

function getMemoryBreakdownSummary(
  item: MemoryMeasurement['breakdown'][0],
  total: number,
) {
  const width = formatPercent(item.bytes / total);
  const style: CSSProperties = {
    width,
    backgroundColor: 'var(--color-blue-100)',
  };
  return (
    <Box column className={rootStyles.maxWidthMedium}>
      <div style={style}>
        {formatMemory(item.bytes)} ({width})
      </div>
      <Box justifyContent="flex-end">
        {item.userAgentSpecificTypes?.map(type => (
          <Tag key={type} className={rootStyles.veryThinMarginHorizontal}>
            {type}
          </Tag>
        ))}
      </Box>
    </Box>
  );
}

function getMemoryGroupTitle(url: string) {
  if (url === 'about:blank') {
    return 'In-page frames';
  }
  if (url === window.location.toString()) {
    return 'This page';
  }
  return 'Workers';
}

const FeatureFlagMenuItem: FunctionComponent<{
  flag: string;
  updateUserFlags: (flags: string[], active: boolean) => void;
  active: boolean;
}> = ({ flag, updateUserFlags, active }) => {
  const onClick = useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      // Inserting this to prevent submenu from auto-closing when a
      // flag has been selected so that you can ensure a setting has
      // been flipped, and make additional selections as well
      e.stopPropagation();
      updateUserFlags([flag], !active);
    },
    [active, flag, updateUserFlags],
  );
  return (
    <MenuItem
      key={flag}
      text={flag}
      icon={
        <Icon
          path={mdiCheck}
          className={classNames(
            `DeveloperTools-featureflag-${flag}`,
            `DeveloperTools-featureflag-${flag}-${active ? 'on' : 'off'}`,
            {
              [rootStyles.invisible]: !active,
            },
          )}
          size={IconSizeNew.SMALL}
        />
      } // eslint-disable-next-line no-restricted-syntax
      onClick={onClick}
    />
  );
};
