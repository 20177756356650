import { Feature } from 'geojson';
import { EventData, Map, MapMouseEvent } from 'mapbox-gl';

/**
 * Point Selection Mode: Click on the map to select a feature.
 * @param map - the mapboxgl map instance
 * @param styleLayerIds the mapbox layer ids of layers that are already loaded,
 *   that should support selection
 * @param onPointSelect - called with arguments: point, map, add
 * @return unsetPointSelection - a function cleans up any Point Selection Mode artifacts
 */
export function setPointSelectionMode(
  map: Map,
  styleLayerIds: string[],
  onPointSelect: (features: Feature[], add: boolean) => void,
): () => void {
  const canvas = map.getCanvasContainer();
  canvas.style.cursor = 'default';
  map.boxZoom.disable();
  map.doubleClickZoom.disable();

  styleLayerIds.forEach(layerId => {
    map.on('mouseup', layerId, mouseup);
  });

  return () => {
    styleLayerIds.forEach(layerId => {
      map.off('mouseup', layerId, mouseup);
    });
  };

  function mouseup(event: MapMouseEvent & EventData) {
    if (map.isMoving()) {
      return;
    }

    const updateOnly = event.originalEvent.shiftKey;
    onPointSelect(event.features, updateOnly);
  }
}
