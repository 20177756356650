import materialColors from 'material-colors';

import { LegacyChartSeries, LegacyReport } from 'uf/reporting/index';

const censusPctUnderFiveSeries: LegacyChartSeries = {
  key: 'pct_pop_under_5',
  source: 'analysis',
  sourceKey: 'census_demographics',
  layerKey: 'census_pop_summary',
  columnKey: 'pct_pop_under_5',
  color: materialColors.orange[100],
};
const censusPctFiveSeventeenSeries: LegacyChartSeries = {
  key: 'pct_pop_5_17',
  source: 'analysis',
  sourceKey: 'census_demographics',
  layerKey: 'census_pop_summary',
  columnKey: 'pct_pop_5_17',
  color: materialColors.orange[200],
};
const censusPctEighteenTwentyfourSeries: LegacyChartSeries = {
  key: 'pct_pop_18_24',
  source: 'analysis',
  sourceKey: 'census_demographics',
  layerKey: 'census_pop_summary',
  columnKey: 'pct_pop_18_24',
  color: materialColors.orange[300],
};
const censusPctTwentyfiveFourtyfourSeries: LegacyChartSeries = {
  key: 'pct_pop_25_44',
  source: 'analysis',
  sourceKey: 'census_demographics',
  layerKey: 'census_pop_summary',
  columnKey: 'pct_pop_25_44',
  color: materialColors.orange[400],
};
const censusPctFourtyfiveSixtyfourSeries: LegacyChartSeries = {
  key: 'pct_pop_45_64',
  source: 'analysis',
  sourceKey: 'census_demographics',
  layerKey: 'census_pop_summary',
  columnKey: 'pct_pop_45_64',
  color: materialColors.orange[500],
};
const censusPctSixtyfivePlusSeries: LegacyChartSeries = {
  key: 'pct_pop_65plus',
  source: 'analysis',
  sourceKey: 'census_demographics',
  layerKey: 'census_pop_summary',
  columnKey: 'pct_pop_65plus',
  color: materialColors.orange[600],
};

const censusPctHispanicSeries: LegacyChartSeries = {
  key: 'pct_hispanic',
  source: 'analysis',
  sourceKey: 'census_demographics',
  layerKey: 'census_race_summary',
  columnKey: 'pct_hispanic',
  color: materialColors.orange[300],
};
const censusPctWhiteSeries: LegacyChartSeries = {
  key: 'pct_nh_white_alone',
  source: 'analysis',
  sourceKey: 'census_demographics',
  layerKey: 'census_race_summary',
  columnKey: 'pct_nh_white_alone',
  color: materialColors.purple[300],
};
const censusPctBlackSeries: LegacyChartSeries = {
  key: 'pct_nh_blk_alone',
  source: 'analysis',
  sourceKey: 'census_demographics',
  layerKey: 'census_race_summary',
  columnKey: 'pct_nh_blk_alone',
  color: materialColors.blue[300],
};
const censusPctNativeAmericanSeries: LegacyChartSeries = {
  key: 'pct_nh_aian_alone',
  source: 'analysis',
  sourceKey: 'census_demographics',
  layerKey: 'census_race_summary',
  columnKey: 'pct_nh_aian_alone',
  color: materialColors.pink[300],
};
const censusPctAsianSeries: LegacyChartSeries = {
  key: 'pct_nh_asian_alone',
  source: 'analysis',
  sourceKey: 'census_demographics',
  layerKey: 'census_race_summary',
  columnKey: 'pct_nh_asian_alone',
  color: materialColors.green[300],
};
const censusPctHawaiianSeries: LegacyChartSeries = {
  key: 'pct_nh_nhopi_alone',
  source: 'analysis',
  sourceKey: 'census_demographics',
  layerKey: 'census_race_summary',
  columnKey: 'pct_nh_nhopi_alone',
  color: materialColors.yellow[300],
};
const censusPctMultipleRacesSeries: LegacyChartSeries = {
  key: 'pct_nh_sor_alone',
  source: 'analysis',
  sourceKey: 'census_demographics',
  layerKey: 'census_race_summary',
  columnKey: 'pct_nh_sor_alone',
  color: materialColors.grey[300],
};

const censusPctNoHighSchoolSeries: LegacyChartSeries = {
  key: 'pct_not_hs_grad',
  source: 'analysis',
  sourceKey: 'census_demographics',
  layerKey: 'census_education_summary',
  columnKey: 'pct_not_hs_grad',
  color: materialColors.red[300],
};
const censusPctCollegeSeries: LegacyChartSeries = {
  key: 'pct_college',
  source: 'analysis',
  sourceKey: 'census_demographics',
  layerKey: 'census_education_summary',
  columnKey: 'pct_college',
  color: materialColors.yellow[300],
};

const censusPctMaleSeries: LegacyChartSeries = {
  key: 'pct_males',
  source: 'analysis',
  sourceKey: 'census_demographics',
  layerKey: 'census_sex_summary',
  columnKey: 'pct_males',
  color: materialColors.blue[300],
};
const censusPctFemalesSeries: LegacyChartSeries = {
  key: 'pct_females',
  source: 'analysis',
  sourceKey: 'census_demographics',
  layerKey: 'census_sex_summary',
  columnKey: 'pct_females',
  color: materialColors.pink[300],
};

const censusPctPovertySeries: LegacyChartSeries = {
  key: 'pct_prs_blw_pov_lev',
  source: 'analysis',
  sourceKey: 'census_demographics',
  layerKey: 'census_poverty_indicator_summary',
  columnKey: 'pct_prs_blw_pov_lev',
  color: materialColors.red[300],
};
const censusPctPubAssistSeries: LegacyChartSeries = {
  key: 'pct_pub_asst_inc',
  source: 'analysis',
  sourceKey: 'census_demographics',
  layerKey: 'census_poverty_indicator_summary',
  columnKey: 'pct_pub_asst_inc',
  color: materialColors.purple[300],
};

const censusPctRenterSeries: LegacyChartSeries = {
  key: 'pct_renter_occp_hu',
  source: 'analysis',
  sourceKey: 'census_demographics',
  layerKey: 'own_rent_summary',
  columnKey: 'pct_renter_occp_hu',
  color: materialColors.orange[300],
};
const censusPctOwnerSeries: LegacyChartSeries = {
  key: 'pct_owner_occp_hu',
  source: 'analysis',
  sourceKey: 'census_demographics',
  layerKey: 'own_rent_summary',
  columnKey: 'pct_owner_occp_hu',
  color: materialColors.blue[300],
};

const censusPctRelFamilySeries: LegacyChartSeries = {
  key: 'pct_rel_family_hhd',
  source: 'analysis',
  sourceKey: 'census_demographics',
  layerKey: 'family_structure_summary',
  columnKey: 'pct_rel_family_hhd',
  color: materialColors.blue[300],
};
const censusPctMarriedSeries: LegacyChartSeries = {
  key: 'pct_mrdcple_fmly_hhd',
  source: 'analysis',
  sourceKey: 'census_demographics',
  layerKey: 'family_structure_summary',
  columnKey: 'pct_mrdcple_fmly_hhd',
  color: materialColors.blue[300],
};
const censusPctNotMarriedSeries: LegacyChartSeries = {
  key: 'pct_not_mrdcple_hhd',
  source: 'analysis',
  sourceKey: 'census_demographics',
  layerKey: 'family_structure_summary',
  columnKey: 'pct_not_mrdcple_hhd',
  color: materialColors.orange[300],
};
const censusPctNonfamilySeries: LegacyChartSeries = {
  key: 'pct_nonfamily_hhd',
  source: 'analysis',
  sourceKey: 'census_demographics',
  layerKey: 'family_structure_summary',
  columnKey: 'pct_nonfamily_hhd',
  color: materialColors.orange[300],
};
const censusPctFamilyWUnder18Series: LegacyChartSeries = {
  key: 'pct_hhd_ppl_und_18',
  source: 'analysis',
  sourceKey: 'census_demographics',
  layerKey: 'family_structure_summary',
  columnKey: 'pct_hhd_ppl_und_18',
  color: materialColors.blue[300],
};

export const censusDemographicsCharts: LegacyReport = Object.freeze({
  key: 'census_demographics',
  name: 'Census Demographics',
  charts: [
    {
      name: 'Race / Ethnicity',
      key: 'census_race_ethnicity',
      series: [
        censusPctHispanicSeries,
        censusPctWhiteSeries,
        censusPctBlackSeries,
        censusPctAsianSeries,
        censusPctNativeAmericanSeries,
        censusPctHawaiianSeries,
        censusPctMultipleRacesSeries,
      ],
    },
    {
      name: 'Age',
      key: 'census_age',
      series: [
        censusPctUnderFiveSeries,
        censusPctFiveSeventeenSeries,
        censusPctEighteenTwentyfourSeries,
        censusPctTwentyfiveFourtyfourSeries,
        censusPctFourtyfiveSixtyfourSeries,
        censusPctSixtyfivePlusSeries,
      ],
    },
    {
      name: 'Sex',
      key: 'census_sex',
      series: [censusPctFemalesSeries, censusPctMaleSeries],
    },
    {
      name: 'Education',
      key: 'census_education',
      series: [censusPctNoHighSchoolSeries, censusPctCollegeSeries],
    },
    {
      name: 'Ownership Status',
      key: 'census_ownership',
      series: [censusPctOwnerSeries, censusPctRenterSeries],
    },
    {
      name: 'Poverty',
      key: 'census_poverty',
      series: [censusPctPovertySeries],
    },
    {
      name: 'Public Assistance',
      key: 'census_public_assistance',
      series: [censusPctPubAssistSeries],
    },
    {
      name: 'Marriage Status',
      key: 'census_married',
      series: [censusPctMarriedSeries, censusPctNotMarriedSeries],
    },
    {
      name: 'Households With Children',
      key: 'census_hh_w_children',
      series: [censusPctFamilyWUnder18Series],
    },
    {
      name: 'Household Structure',
      key: 'census_hh_structure',
      series: [censusPctRelFamilySeries, censusPctNonfamilySeries],
    },
  ],
});
