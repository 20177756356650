import colors from 'material-colors';

import { DEFAULT_GEO_COLUMN_KEY } from 'uf/layers/geometryKey';
import {
  CuratedColumnSymbology,
  DEFAULT_OPACITY,
  InterpolationTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/cityofmadison/dataset/madison_local_landmarks': {
    [DEFAULT_GEO_COLUMN_KEY]: [
      {
        type: SymbologyTypes.FILL,
        paint: {
          'fill-color': colors.red[300],
          'fill-opacity': DEFAULT_OPACITY,
        },
        display_hints: {
          scale: SymbologyScales.CATEGORICAL,
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          theme: 'material:red:300',
        },
      },
    ],
  },
};

export default symbologies;
