import { LayerColumnSymbology, SymbologyTypes } from 'uf/symbology';

/**
 * Returns a new copy of the given symbology with the new line width applied
 *
 * @param symbology - a single symbology from a LayerColumnSymbology
 * @param properties  additional properties
 * @param properties.lineWidth - the new line width in pixels
 * @return updatedSymbology - a copy of `symbology` with the new line width applied
 */
export default function updateStrokeWidth(
  symbology: LayerColumnSymbology,
  { lineWidth },
): LayerColumnSymbology {
  const { type: symbologyType } = symbology;

  switch (symbologyType) {
    // Minor defensive coding here, the caller shouldn't pass a fill symbology to this function
    case SymbologyTypes.FILL:
      return symbology;

    case SymbologyTypes.LINE:
      return {
        ...symbology,
        paint: {
          ...symbology.paint,
          'line-width': lineWidth,
        },
      };

    case SymbologyTypes.CIRCLE:
      return {
        ...symbology,
        paint: {
          ...symbology.paint,
          'circle-stroke-width': lineWidth,
        },
      };
  }
  return symbology;
}
