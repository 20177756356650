// This is the entrypoint for browser-side rendering. It renders the root object into
// a div on the page that looks like <div id="content"></div>. If the server has already
// rendered content there, then it will do a react-style rerender of the DOM that is there.

// public path must be loaded before all other imports
import './publicPath';

import { History } from 'history';
import { PlainRoute } from 'react-router';
import { Store } from 'redux';

import { FutureHistory } from 'uf/routing/history';

import { renderBody, renderError } from './client/render';
import { createClientStore } from './client/setup';
import { asyncMatch } from './ui/base/asyncMatch';

const API_SERVER_ORIGIN = process.env.UF_API_SERVER_ADDR;

declare global {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Window {
    __redux_store: Store;
    __redux_history: History & FutureHistory;
    __routes: PlainRoute[];
    __data: any;
  }
}

if (window.__redux_store) {
  // If you see this message, it means the hot-reloader is trying to reload this file,
  // which generally fails because redux doesn't support hot-reloading of the store.
  // One symptom of this problem is when the components that Routes refer to are
  // stateless functions rather than subclasses of React.Component.
  console.warn('Hot-reloading root file does not work, page may hard-reload.');
}

createClientStore(API_SERVER_ORIGIN).then(
  async ({ store, routes, history }) => {
    window.__redux_store = store;
    window.__redux_history = history;
    window.__routes = routes;
    const root = document.querySelector('#content');
    try {
      const { renderProps } = await asyncMatch({
        routes,
        history,
      });
      renderBody(renderProps, root, store, history);
    } catch (ex) {
      renderError(ex, root);
    }
  },
);
