import { Classes, Position, Tooltip } from '@blueprintjs/core';
import {
  mdiAlert,
  mdiCheckBoxOutline,
  mdiCloseCircle,
  mdiInformationOutline,
} from '@mdi/js';
import Icon from '@mdi/react';
import classNames from 'classnames';
import React, { FunctionComponent, ReactNode } from 'react';
import { Box } from 'react-layout-components';
import warning from 'warning';

import colorStyles from 'uf/styles/colors.module.css';
import panelStyles from 'uf/styles/panels.module.css';
import rootStyles from 'uf/styles/root.module.css';
import { ErrorObj, extractErrorDetails } from 'uf/ui/base/errors';
import { IconSizeNew } from 'uf/ui/base/icon';

import styles from './ErrorMessage.module.css';

type Level = 'success' | 'danger' | 'fail' | 'info';
interface Props {
  // Maybe raw error string, or error object with `title` and `detail`
  // properties.
  error: ReactNode | ErrorObj;

  /**
   * A user-readable string. If specified, then the raw error details
   * will be less promenant.
   */
  title?: string;

  // any additional classes for the error message span
  tooltipPosition?: Position;

  // TODO: Figure out what choices should be available.
  level?: Level;

  // requests that the detail string be shown with the title if available
  includeDetailTextInline?: boolean;
}

const messagePanelStyle = classNames(
  panelStyles.dark,
  panelStyles.noCard,
  rootStyles.thinMarginRight,
);

const messageBodyStyle = classNames(
  styles.errorBody,
  rootStyles.veryThinPadding,
);

// Puts an error message on the screen based on either an error string or an error object.
const ErrorMessage: FunctionComponent<Props> = ({
  error,
  tooltipPosition,
  title,
  level,
  includeDetailTextInline,
}) => {
  // We could do stronger checking here to distinguish between native `Error`
  const { errorDetail, errorDetailRaw, errorMessage } =
    extractErrorDetails(error);

  if (!errorMessage) {
    warning(!errorDetail, `Have error detail but not error: ${errorDetail}`);
    return null;
  }

  // If there is a user-supplied title, put that up.
  const errorClass = title ? Classes.TEXT_MUTED : Classes.INTENT_DANGER;

  const errorIcon = getErrorIconClass(level, true);
  const errorComponent = (
    <div className={messagePanelStyle}>
      <div className={messageBodyStyle}>
        <Box alignItems="center">
          <Box center>
            <Icon
              path={errorIcon.path}
              className={errorIcon.className}
              size={IconSizeNew.MEDIUM}
            />
          </Box>
          <Box column>
            {title && (
              <span
                className={classNames(
                  'h4 alert-text',
                  rootStyles.veryThinPadding,
                )}>
                {title}
              </span>
            )}
            <span
              className={classNames(
                'h4',
                errorClass,
                rootStyles.veryThinPadding,
              )}>
              {errorMessage}
              {includeDetailTextInline &&
                errorDetailRaw &&
                ` ${errorDetailRaw}`}
            </span>
          </Box>
        </Box>
      </div>
    </div>
  );

  if (errorDetail) {
    return (
      <Tooltip
        boundary="window"
        position={tooltipPosition}
        hoverCloseDelay={5000}
        content={errorDetail}>
        {errorComponent}
      </Tooltip>
    );
  }

  return errorComponent;
};

ErrorMessage.defaultProps = {
  tooltipPosition: Position.BOTTOM,
  level: 'danger',
};

export default ErrorMessage;

export function getErrorIconClass(level: Level, inline: boolean) {
  const alertIconType = {
    success: { className: colorStyles.success, path: mdiCheckBoxOutline },
    danger: { className: colorStyles.danger, path: mdiAlert },
    fail: { className: colorStyles.fail, path: mdiCloseCircle },
    info: { className: colorStyles.info, path: mdiInformationOutline },
  };

  const iconClass: string =
    alertIconType[level].className || alertIconType.danger.className;

  return {
    // add padding to top of icons to align with title text
    className: classNames(
      iconClass,
      inline ? rootStyles.veryThinPaddingTop : null,
    ),
    path: alertIconType[level].path,
  };
}
