/**
 * UrbanFootprint App API
 * API for serving UrbanFootprint Application
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * How frequently is an account billed.
 */
export interface BillingPeriod<> {
  /**
   * The number of interval_unit\'s that this billing period has.
   */
  interval_length?: number;
  /**
   * The amount of time that interval_length refers to.
   */
  interval_unit?: BillingPeriod.IntervalUnitEnum;
}
export namespace BillingPeriod {
  export type IntervalUnitEnum = 'daily' | 'monthly' | 'yearly';
  export const IntervalUnitEnum = {
    Daily: 'daily',
    Monthly: 'monthly',
    Yearly: 'yearly',
  } as const;
}
