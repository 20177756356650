import { combineEpics } from 'uf/base/epics';

import bookmarksEpics from './bookmarks';
import drawingModeEpics from './drawingMode';
import exploreEpics from './explore';
import {
  saveSplitFeaturesEpic,
  splitFeatureEpic,
  unSplitFeatureEpic,
} from './features';
import mapEpics from './map';
import persistenceEpics from './persistence';
import routingEpics from './routing';
import searchEpics from './search';
import tasksEpics from './tasks';
import versionEpics from './versions';
import viewEpics from './views';

export default combineEpics(
  {
    bookmarksEpics,
    exploreEpics,
    persistenceEpics,
    routingEpics,
    versionEpics,
    mapEpics,
    splitFeatureEpic,
    unSplitFeatureEpic,
    saveSplitFeaturesEpic,
    tasksEpics,
    drawingModeEpics,
    viewEpics,
    searchEpics,
  },
  'analysisEditorEpics',
);
