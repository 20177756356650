/**
 * UrbanFootprint App API
 * API for serving UrbanFootprint Application
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Settings for controlling the rastered output.
 */
export interface MapExportOutputSettings<> {
  /**
   * Output quality expressed as dots-per-inch.
   */
  dpi?: number;
  /**
   * The image format.
   */
  format?: MapExportOutputSettings.FormatEnum;
  /**
   * The desired height in pixels, overrides quality.
   */
  height?: number;
  /**
   * The amount to scale components on the map by
   */
  scale_factor?: number;
  /**
   * The desired width in pixels, overrides quality.
   */
  width?: number;
}
export namespace MapExportOutputSettings {
  export type FormatEnum = 'png' | 'svg';
  export const FormatEnum = {
    Png: 'png',
    Svg: 'svg',
  } as const;
}
