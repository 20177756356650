import { Map } from 'mapbox-gl';
import { useCallback, useEffect, useRef } from 'react';

import { ThirdPartyAny } from 'uf/base/types';

export function useMapboxEventListener(
  map: Map | null,
  eventName: string,
  handler: (ev: ThirdPartyAny) => void,
) {
  // Create a ref that stores handler
  const savedHandler = useRef(handler);

  // Update ref.current value if handler changes.
  // This allows our effect below to always get latest handler ...
  // ... without us needing to pass it in effect deps array ...
  // ... and potentially cause effect to re-run every render.
  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(
    () => {
      if (!map) {
        return;
      }

      // Create event listener that calls handler function stored in ref
      const eventListener = event => {
        // it's legal to pass `null` as an event handler
        if (savedHandler.current) {
          savedHandler.current(event);
        }
      };

      // Add event listener
      map.on(eventName, eventListener);

      // Remove event listener on cleanup
      return () => {
        map.off(eventName, eventListener);
      };
    },
    [eventName, map], // Re-run if eventName or element changes
  );
}

export function useHandleErrors(
  map: Map | null,
  forceUpdateMap: (safeToUpdateMap: boolean, refreshLayers?: boolean) => void,
  onError: (error: Error, map: Map) => void,
  safeToUpdateMap: boolean,
) {
  const handleError = useCallback(
    (error: Error) => {
      console.warn('Mapbox error: ', error);
      // This is normally handled by the map's event handlers,
      // however if the map is completely loaded and idle when this error
      // occurs, we need to call this function synthetically.
      if (map.loaded()) {
        forceUpdateMap(safeToUpdateMap, false);
      }
      if (onError) {
        onError(error, map);
      }
    },
    [forceUpdateMap, map, onError, safeToUpdateMap],
  );
  // Updates map status in cases where an error occured instead of a render.
  // Also, runs the error prop callback if provided.
  useMapboxEventListener(map, 'error', handleError);
}
