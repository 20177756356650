import {
  CuratedColumnSymbology,
  CUSTOM_THEME,
  InterpolationTypes,
  PaintColorTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/public/dataset/greeninfo_cpad_2018a': {
    access_typ: [
      {
        type: SymbologyTypes.FILL,
        paint: {
          'fill-color': {
            property: 'access_typ',
            type: PaintColorTypes.CATEGORICAL,
            stops: [
              {
                value: 'No Public Access',
                color: '#9f0500',
              },
              {
                value: 'Open Access',
                color: '#68bc00',
              },
              {
                value: 'Restricted Access',
                color: '#fcc400',
              },
              {
                value: 'Unknown Access',
                color: '#999999',
              },
            ],
            default: '#9467bd',
          },
          'fill-opacity': 0.9,
        },
        display_hints: {
          scale: SymbologyScales.CATEGORICAL,
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          theme: CUSTOM_THEME,
        },
      },
      {
        type: SymbologyTypes.LINE,
        paint: {
          'line-color': {
            property: 'access_typ',
            type: PaintColorTypes.CATEGORICAL,
            stops: [
              {
                value: 'No Public Access',
                color: '#1f77b4',
              },
              {
                value: 'Open Access',
                color: '#ff7f0e',
              },
              {
                value: 'Restricted Access',
                color: '#2ca02c',
              },
              {
                value: 'Unknown Access',
                color: '#d62728',
              },
            ],
            default: '#9467bd',
          },
          'line-width': 0.5,
          'line-opacity': 0.9,
        },
        display_hints: {
          scale: SymbologyScales.CATEGORICAL,
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          theme: 'd3:schemeCategory10',
        },
      },
    ],
    land_water: [
      {
        type: SymbologyTypes.FILL,
        paint: {
          'fill-color': {
            property: 'land_water',
            type: 'categorical',
            stops: [
              {
                value: 'Land',
                color: '#3182bd',
              },
              {
                value: 'Water',
                color: '#6baed6',
              },
            ],
            default: '#2ca02c',
          },
          'fill-opacity': 0.4,
        },
        display_hints: {
          scale: SymbologyScales.CATEGORICAL,
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          theme: 'd3:schemeCategory20c',
        },
      },
      {
        type: SymbologyTypes.LINE,
        paint: {
          'line-color': {
            property: 'land_water',
            type: 'categorical',
            stops: [
              {
                value: 'Land',
                color: '#1f77b4',
              },
              {
                value: 'Water',
                color: '#ff7f0e',
              },
            ],
            default: '#2ca02c',
          },
          'line-width': 0.5,
          'line-opacity': 0.4,
        },
        display_hints: {
          scale: SymbologyScales.CATEGORICAL,
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          theme: 'd3:schemeCategory10',
        },
      },
    ],
  },
};

export default symbologies;
