import { Feature, LineString } from 'geojson';
import { AnyAction } from 'redux';
import { Epic, ofType, StateObservable } from 'redux-observable';
import { filter, map } from 'rxjs/operators';

import { combineEpics } from 'uf/base/epics';
import { removeVerticesFromFeature } from 'uf/base/map';
import { SwaggerThunkExtra } from 'uf/base/xhr';
import {
  DELETE_SELECTED_VERTICES,
  DeleteVerticesAction,
} from 'uf/explore/ActionTypes';
import { makeGetLayerEditsSelectedFeatures } from 'uf/explore/selectors/drawingMode';
import { LayerId } from 'uf/layers';
import { featureEditListActions } from 'uf/mapedit/actions/features';
import { ProjectId } from 'uf/projects';
import { UFState } from 'uf/state';

// TODO: Move to uf/explore
export const deleteSelectedVertices: Epic<
  DeleteVerticesAction,
  any,
  UFState,
  SwaggerThunkExtra
> = (action$, state$) => {
  const getLayerEditsSelectedFeatures = makeGetLayerEditsSelectedFeatures();
  return action$.pipe(
    ofType(DELETE_SELECTED_VERTICES),
    // Too many unknowns around deleting vertices in non-LineString features.
    // This is a big hammer!
    filterLineStringOnlyHACK(state$),
    map(deleteSelectedVerticesAction => {
      const { featureId, coordPaths, projectId, layerId } =
        deleteSelectedVerticesAction;

      const featureOverrides: Feature[] = getLayerEditsSelectedFeatures(
        state$.value,
        {
          layerId,
          projectId,
        },
      );
      const feature = featureOverrides.find(({ id, geometry }) => {
        return id === featureId && geometry.type === 'LineString';
      });

      const updatedFeature = removeVerticesFromFeature(feature, coordPaths);

      return featureEditListActions.updateItem(
        updatedFeature,
        null,
        projectId,
        layerId,
        true,
      );
    }),
  );
};

// TODO: Figure out what it means to delete vertices from non-LineString features
function filterLineStringOnlyHACK<
  T extends AnyAction & {
    projectId: ProjectId;
    layerId: LayerId;
    featureId: string;
  },
>(state$: StateObservable<UFState>) {
  const getLayerEditsSelectedFeatures = makeGetLayerEditsSelectedFeatures();
  return filter((action: T) => {
    const { layerId, projectId, featureId } = action;
    const featureOverrides = getLayerEditsSelectedFeatures(state$.value, {
      layerId,
      projectId,
    });
    return !!featureOverrides.find(
      (feature): feature is Feature<LineString> => {
        const { id, geometry } = feature;
        return id === featureId && geometry.type === 'LineString';
      },
    );
  });
}

export default combineEpics({ deleteSelectedVertices }, 'explore.drawingMode');
