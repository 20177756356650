import { useSelector } from 'react-redux';
import {
  LayerReference,
  useEnqueueProjectConvertLayerTaskMutation,
  useGetAppLayersByContextQuery,
  useGetLayerDataQuery,
} from 'uf-api-rtk/store/Api';
import { getActiveProject } from 'uf/app/selectors';
import { selectApp } from 'uf/app/slice';
import { parseFullPath } from 'uf/base/dataset';
import { convertRequestBodyToFormData } from 'uf/data/helpers';
import { Brand } from 'uf/layers/layerData';
import { logEvent } from 'uf/logging';

/**
 * A helper function to turn a string of places into a delimited array of place objects
 * @param places An arbitrary string value
 * @returns An array of place objects
 */
export const getPlaces = (places: string) => {
  return places
    .split(/\n|;/gm)
    .filter(Boolean)
    .map(place => ({ name: place, type: 'location_name' }));
};

export const getFilterExpression = (
  selectedItems: Brand[],
  projectArea: LayerReference,
  context: LayerReference,
) => {
  const itemTypes = Array.from(new Set(selectedItems.map(item => item.type)));
  return {
    fn: 'and',
    expressions: [
      {
        fn: 'layer_intersects',
        layer: projectArea.full_path,
        version: projectArea.version,
      },
      {
        fn: 'layer_intersects',
        layer: context.full_path,
        version: context.version,
        filter: {
          fn: 'or',
          expressions: itemTypes.map(type => {
            return {
              fn: 'in',
              value: {
                fn: 'column',
                key: type === 'brand' ? 'brand_name' : type,
              },
              one_of: selectedItems
                .filter(item => item.type === type)
                .map(item => item.name),
            };
          }),
        },
      },
    ],
  };
};

export function usePointsOfInterest(): {
  brandsById: Record<string, Brand>;
  brandIds: string[];
  onSubmit: (data: any) => void;
} {
  const projectId = useSelector(selectApp).activeProjectId;
  const { key: projectKey = '' } = projectId ? parseFullPath(projectId) : {};
  const project = useSelector(getActiveProject);
  const orgId = useSelector(selectApp).activeOrgId;
  const { data: contexts } = useGetAppLayersByContextQuery({
    contexts: ['poi_brands', 'pois', 'poi_parcels'],
  });
  const [convertLayer] = useEnqueueProjectConvertLayerTaskMutation();
  const brandContext = contexts?.find(
    context => context.context === 'poi_brands',
  );
  const pointsOfInterestContext = contexts?.find(
    context => context.context === 'pois',
  );
  const parcelContext = contexts?.find(
    context => context.context === 'poi_parcels',
  );
  const { key, layer_type: layerType, namespace } = brandContext?.layer || {};
  const {
    // @ts-ignore POI layer data is normalized
    data: { entities = {}, ids = [] } = {},
  } = useGetLayerDataQuery(
    {
      namespace,
      key,
      layerType,
      sortColumn: 'name',
      sortDirection: 'asc',
      limit: 10000,
    },
    { skip: !contexts || !brandContext },
  );
  const handleSubmit = async data => {
    const filter = getFilterExpression(
      data,
      project.project_area_layer,
      pointsOfInterestContext.layer,
    );
    const { full_path: parcelPath, version: parcelVersion } =
      parcelContext.layer;
    const selection = data.map(item => item.name).join(', ');
    const currentDate = new Date().toISOString().split('T')[0];
    try {
      await convertLayer({
        namespace: orgId,
        key: projectKey,
        // @ts-ignore this is the correct form data
        body: convertRequestBodyToFormData({
          parent: parcelPath,
          conversion_method: 'point_on_surface',
          parent_version: parcelVersion,
          filter: JSON.stringify(filter),
          name: selection,
          description: `Known business locations found by searching UrbanFootprint’s business data for ${selection} on ${currentDate}.`,
        }),
      });
      logEvent('Brands and Businesses Added', {
        projectId,
        selection,
      });
    } catch (error) {
      logEvent('Brands and Businesses Add Failed', {
        projectId,
        selection,
        error,
      });
    }
  };
  return {
    brandsById: entities,
    brandIds: ids,
    onSubmit: handleSubmit,
  };
}
