import { Reducer } from 'redux';

import { EMPTY_OBJECT } from 'uf/base';
import {
  FailureAction,
  LoadAction,
  LoadActionTypes,
  SuccessAction,
  UpdateStateAction,
} from 'uf/data/ActionTypes';
import {
  DataStateMap,
  makeClearedState,
  makeErrorState,
  makeLoadedState,
  makeLoadingState,
} from 'uf/data/dataState';

// This is the object in redux that contains all the results from a keyedPromiseReducer

/**
 * Creates a reducer that stores multiple promise results in the
 * state, keyed on some value in the actions.
 *
 * This assumes the LOAD/SUCCESS/FAIL actions always have the same key.
 *
 * For example:
 *
 *   const reducer = makeKeyedPromiseReducer({
 *     LOAD: 'my-action/LOAD',
 *     SUCCESS: 'my-action/SUCCESS',
 *     ERROR: 'my-action/ERROR,
 *     CLEAR: 'my-action/CLEAR',
 *   });
 *
 *   state = reducer(state, { type: 'my-action/LOAD', key: 'item1' });
 *   state = reducer(state, { type: 'my-action/LOAD', key: 'item2' });
 *
 * Note that the actionTypes dictionary can be generated using `makeAsyncActionTypes`:
 *
 *   const reducer = makeKeyedPromiseReducer(makeAsyncActionTypes('my-action'));
 *
 * This results in two entries in the state, 'item1' and 'item2':
 *
 *   { item1: { loading: true, loaded: false },
 *     item2: { loading: true, loaded: false } }
 *
 * When one of these resolves, it won't affect the others:
 *
 *   state = reducer(state, { type: 'my-action/SUCCESS', key: 'item1', result: 'Item 1' }
 *
 * Results in a state:
 *   { item1: { loading: false, loaded: true, data: 'Item 1' },
 *     item2: { loading: true, loaded: false } }
 *
 * This is especially useful with dispatchAsyncAction, where you can
 * pass along extra action properties. With this reducer:
 *
 *   const reducer = makeKeyedPromiseReducer(makeAsyncActionTypes('my-action'));
 *
 * You can dispatch with an object with a serverKey:
 *
 *   dispatchAsyncAction(makeAsyncActionTypes('my-action'),
 *                       () => fetchServerItem('item1'), { key: 'item1' })
 *
 */
export function makeKeyedPromiseReducer<T>(
  actionTypes: LoadActionTypes,
): Reducer<DataStateMap<T>> {
  return function reducer(
    state: DataStateMap<T> = EMPTY_OBJECT,
    action: UpdateStateAction<T>,
  ): DataStateMap<T> {
    switch (action.type) {
      case actionTypes.LOAD: {
        const dataKey = action.key;
        return {
          ...state,
          [dataKey]: makeLoadingState(state[dataKey], action as LoadAction),
        };
      }
      case actionTypes.SUCCESS: {
        const dataKey = action.key;
        return {
          ...state,
          [dataKey]: makeLoadedState(
            state[dataKey],
            action as SuccessAction<T>,
          ),
        };
      }
      case actionTypes.FAILURE: {
        const dataKey = action.key;
        return {
          ...state,
          [dataKey]: makeErrorState(state[dataKey], action as FailureAction),
        };
      }
      case actionTypes.CLEAR: {
        const dataKey = action.key;
        const newState = makeClearedState(state[dataKey]);
        // avoid unnecessary state changes
        if (newState === state[dataKey]) {
          return state;
        }
        return {
          ...state,
          [dataKey]: newState,
        };
      }
      default:
        return state;
    }
  };
}
