import _ from 'lodash';
import {
  MapExportLayerStyle,
  MapExportLayerStyleBreak,
} from 'uf-api/model/models';
import { EMPTY_ARRAY } from 'uf/base';
import { getData } from 'uf/data/dataState';
import { ColumnKey, LayerId } from 'uf/layers';
import { isNullCategory } from 'uf/layers/nullStats';
import { ProjectId } from 'uf/projects';
import { LegacyVirtualLayerId } from 'uf/projects/virtualLayers';
import {
  DataDrivenColorProperty,
  DataDrivenColorStop,
  DataDrivenOpacityProperty,
  DataDrivenOpacityStop,
  DataDrivenRadiusProperty,
  DataDrivenRadiusStop,
  DataDrivenStrokeColor,
  DataDrivenStrokeColorProperty,
  DataDrivenWidthProperty,
  DataDrivenWidthStop,
  isCircleSymbology,
  isFillSymbology,
  isLineSymbology,
  isNumericSymbology,
  LayerColumnSymbology,
} from 'uf/symbology';
import { GetSymbologyState } from 'uf/symbology/selectors';
import { GetDivideByColumn } from 'uf/symbology/selectors/divideByColumn';
import { getPaintProperty, isDataDriven } from 'uf/symbology/spec/paint';
import { ViewId } from 'uf/views';

const OPACITY_SUFFIX = 'opacity';
const WIDTH_SUFFIX = 'width';
const COLOR_SUFFIX = 'color';
const STROKE_COLOR_SUFFIX = 'stroke-color';
const STROKE_WIDTH_SUFFIX = 'stroke-width';
const RADIUS_SUFFIX = 'radius';

const STROKE_PREFIX = 'stroke';
const FILL_PREFIX = 'fill';
const RADIUS_PREFIX = 'radius';

export interface Style {
  fill_color: string;
  fill_opacity: number;
  stroke_color: string;
  stroke_width: number;
  stroke_opacity: number;
  radius: number;
}
export type PartialStyle = Partial<Style>;

export interface PartialDataDrivenStyle extends PartialStyle {
  value: string | number;
}

export function getValuesForColor(
  colorProperty: DataDrivenColorProperty,
  key: string,
): PartialDataDrivenStyle[] {
  return colorProperty.stops.map(
    (stop): PartialDataDrivenStyle => ({
      [key]: stop[COLOR_SUFFIX],
      value: makeValueFromStop(stop),
    }),
  );
}

export function getValuesForWidth(
  widthProperty: DataDrivenWidthProperty,
  key: string,
): PartialDataDrivenStyle[] {
  return widthProperty.stops.map(
    (stop): PartialDataDrivenStyle => ({
      [key]: stop[WIDTH_SUFFIX],
      value: makeValueFromStop(stop),
    }),
  );
}

export function getValuesForOpacity(
  opacityProperty: DataDrivenOpacityProperty,
  key: string,
): PartialDataDrivenStyle[] {
  return opacityProperty.stops.map(
    (stop): PartialDataDrivenStyle => ({
      [key]: stop[OPACITY_SUFFIX],
      value: makeValueFromStop(stop),
    }),
  );
}

export function getValuesForRadius(
  radiusProperty: DataDrivenRadiusProperty,
  key: string,
): PartialDataDrivenStyle[] {
  return radiusProperty.stops.map(
    (stop): PartialDataDrivenStyle => ({
      [key]: stop[RADIUS_SUFFIX],
      value: makeValueFromStop(stop),
    }),
  );
}

export function getValuesForStrokeColor(
  strokeProperty: DataDrivenStrokeColorProperty,
  key: string,
): PartialDataDrivenStyle[] {
  return strokeProperty.stops.map(
    (stop): PartialDataDrivenStyle => ({
      [key]: stop[STROKE_COLOR_SUFFIX],
      value: makeValueFromStop(stop),
    }),
  );
}

function makeValueFromStop(stop: DataDrivenStrokeColor): string | number {
  return isNullCategory(stop.value) ? null : (stop.value as string | number);
}

export function getDataDrivenColorProperty(
  style: LayerColumnSymbology,
  key: string,
): PartialDataDrivenStyle[] {
  const colorProperty = getPaintProperty<
    DataDrivenColorStop,
    DataDrivenColorProperty
  >(style, COLOR_SUFFIX);
  return isDataDriven(colorProperty)
    ? getValuesForColor(colorProperty, key)
    : [];
}

export function getNonDataDrivenColorProperty(
  style: LayerColumnSymbology,
  key: string,
): PartialStyle {
  const property = getPaintProperty<
    DataDrivenColorStop,
    DataDrivenColorProperty
  >(style, COLOR_SUFFIX);
  return isDataDriven(property) ? {} : { [key]: property };
}

export function getDataDrivenWidthProperty(
  style: LayerColumnSymbology,
  key: string,
): PartialDataDrivenStyle[] {
  const widthProperty = getPaintProperty<
    DataDrivenWidthStop,
    DataDrivenWidthProperty
  >(style, WIDTH_SUFFIX);
  return isDataDriven(widthProperty)
    ? getValuesForWidth(widthProperty, key)
    : [];
}

export function getNonDataDrivenWidthProperty(
  style: LayerColumnSymbology,
  key: string,
): PartialStyle {
  const property = getPaintProperty<
    DataDrivenWidthStop,
    DataDrivenWidthProperty
  >(style, WIDTH_SUFFIX);
  return isDataDriven(property) ? {} : { [key]: property };
}

export function getDataDrivenOpacityProperty(
  style: LayerColumnSymbology,
  key: string,
): PartialDataDrivenStyle[] {
  const opacityProperty = getPaintProperty<
    DataDrivenOpacityStop,
    DataDrivenOpacityProperty
  >(style, OPACITY_SUFFIX);
  return isDataDriven(opacityProperty)
    ? getValuesForOpacity(opacityProperty, key)
    : [];
}

export function getNonDataDrivenOpacityProperty(
  style: LayerColumnSymbology,
  key: string,
): PartialStyle {
  const property = getPaintProperty<
    DataDrivenOpacityStop,
    DataDrivenOpacityProperty
  >(style, OPACITY_SUFFIX);
  return isDataDriven(property) ? {} : { [key]: property };
}

export function getDataDrivenStrokeColorProperty(
  style: LayerColumnSymbology,
  key: string,
): PartialDataDrivenStyle[] {
  const strokeColorProperty = getPaintProperty<
    DataDrivenColorStop,
    DataDrivenStrokeColorProperty
  >(style, STROKE_COLOR_SUFFIX);
  return isDataDriven(strokeColorProperty)
    ? getValuesForStrokeColor(strokeColorProperty, key)
    : [];
}

export function getNonDataDrivenStrokeColorProperty(
  style: LayerColumnSymbology,
  key: string,
): PartialStyle {
  const property = getPaintProperty<
    DataDrivenColorStop,
    DataDrivenStrokeColorProperty
  >(style, STROKE_COLOR_SUFFIX);
  return isDataDriven(property) ? {} : { [key]: property };
}

export function getNonDataDrivenStrokeWidthProperty(
  style: LayerColumnSymbology,
  key: string,
): PartialStyle {
  const property = getPaintProperty(style, STROKE_WIDTH_SUFFIX);
  return isDataDriven(property) ? {} : { [key]: property };
}

export function getNonDataDrivenStrokeOpacityProperty(
  style: LayerColumnSymbology,
  key: string,
): PartialStyle {
  const property = getPaintProperty(style, OPACITY_SUFFIX);
  return isDataDriven(property) ? {} : { [key]: property };
}

export function getDataDrivenRadiusProperty(
  style: LayerColumnSymbology,
  key: string,
): PartialDataDrivenStyle[] {
  const radiusProperty = getPaintProperty<
    DataDrivenRadiusStop,
    DataDrivenRadiusProperty
  >(style, RADIUS_SUFFIX);
  return isDataDriven(radiusProperty)
    ? getValuesForRadius(radiusProperty, key)
    : [];
}

export function getNonDataDrivenRadiusProperty(
  style: LayerColumnSymbology,
  key: string,
): PartialStyle {
  const property = getPaintProperty(style, RADIUS_SUFFIX);
  return isDataDriven(property) ? {} : { [key]: property };
}

export function mergeStyles(
  dataDrivenStyles: PartialDataDrivenStyle[],
  nonDataDrivenStyles: PartialStyle,
): PartialDataDrivenStyle[] {
  let mergedStyles = [];
  if (dataDrivenStyles.length > 0) {
    mergedStyles = dataDrivenStyles.map(
      (style): MapExportLayerStyleBreak => ({
        ...style,
        ...nonDataDrivenStyles,
      }),
    );
    return mergedStyles;
  }
  const nonDataDrivenStylesWithValue = Object.assign({}, nonDataDrivenStyles, {
    value: undefined,
  });
  return [nonDataDrivenStylesWithValue];
}

export function mergeObjects(styles: PartialStyle[]): PartialStyle {
  return _.omitBy(_.assign({}, ...styles), _.isNil);
}

export function mergeDataDrivenObjects(
  styles: PartialDataDrivenStyle[],
): PartialDataDrivenStyle {
  return styles.reduce((mergedStyle, style) => ({ ...mergedStyle, ...style }), {
    value: undefined,
  });
}

export function removeUndefinedProperties(
  styles: MapExportLayerStyleBreak[],
): MapExportLayerStyleBreak[] {
  return styles.map(style => _.omitBy(_.assign({}, style), _.isNil));
}

function mergeDataDrivenStyles(
  styles: PartialDataDrivenStyle[][],
): PartialDataDrivenStyle[] {
  if (styles.length === 0) {
    return [{ value: undefined }];
  }
  return styles[0].map((styleCount, i) => {
    const objectsPerIndex = styles.map(
      (style): PartialDataDrivenStyle => style[i],
    );
    return mergeDataDrivenObjects(objectsPerIndex);
  });
}

function mergeDrivenAndNonDrivenStyles(
  dataDrivenStyles: PartialDataDrivenStyle[][],
  nonDataDrivenStyles: PartialStyle[],
): PartialDataDrivenStyle[] {
  const filteredDataDrivenStyles = dataDrivenStyles.filter(
    style => Object.keys(style).length !== 0,
  );
  const mergedDataDrivenStyles = mergeDataDrivenStyles(
    filteredDataDrivenStyles,
  );
  const mergedNonDataDrivenStyles: PartialStyle =
    mergeObjects(nonDataDrivenStyles);
  const mergedStyles = mergeStyles(
    mergedDataDrivenStyles,
    mergedNonDataDrivenStyles,
  );
  return mergedStyles;
}

export function mergeAllStyles(
  dataDrivenStyles: PartialDataDrivenStyle[][],
  nonDataDrivenStyles: PartialStyle[],
  isNumeric: boolean,
): MapExportLayerStyleBreak[] {
  const mergedStyles = mergeDrivenAndNonDrivenStyles(
    dataDrivenStyles,
    nonDataDrivenStyles,
  );
  const typedStyles = updateValueForType(mergedStyles, isNumeric);
  const cleanedStyles = removeUndefinedProperties(typedStyles);

  return cleanedStyles;
}

export function updateValueForInterval(
  styles: PartialDataDrivenStyle[],
): MapExportLayerStyleBreak[] {
  return styles.map((style, i): MapExportLayerStyleBreak => {
    const styleClone: MapExportLayerStyleBreak = { ...style };
    if (style.value !== undefined) {
      styleClone.min_value = style.value as number;
      styleClone.max_value =
        i === styles.length - 1
          ? Number.MAX_SAFE_INTEGER
          : (styles[i + 1].value as number);
    }
    delete styleClone['value'];
    return styleClone;
  });
}

export function updateValueForCategorical(
  styles: PartialDataDrivenStyle[],
): MapExportLayerStyleBreak[] {
  return styles.map((style): MapExportLayerStyleBreak => {
    const styleClone: MapExportLayerStyleBreak = { ...style };
    styleClone.category =
      style.value !== undefined ? `${style.value}` : undefined;
    delete styleClone['value'];
    return styleClone;
  });
}

export function updateValueForType(
  styles: PartialDataDrivenStyle[],
  isNumeric: boolean,
): MapExportLayerStyleBreak[] {
  if (isNumeric) {
    return updateValueForInterval(styles);
  }
  return updateValueForCategorical(styles);
}

export function getLineStyles(
  style: LayerColumnSymbology,
): MapExportLayerStyleBreak[] {
  const isNumeric = isNumericSymbology(style);
  const nonDataDrivenStyles: PartialStyle[] = [];
  const dataDrivenStyles: PartialDataDrivenStyle[][] = [];

  const lineColorKey = `${STROKE_PREFIX}_${COLOR_SUFFIX}`;
  dataDrivenStyles.push(getDataDrivenColorProperty(style, lineColorKey));
  nonDataDrivenStyles.push(getNonDataDrivenColorProperty(style, lineColorKey));

  const lineWidthKey = `${STROKE_PREFIX}_${WIDTH_SUFFIX}`;
  dataDrivenStyles.push(getDataDrivenWidthProperty(style, lineWidthKey));
  nonDataDrivenStyles.push(getNonDataDrivenWidthProperty(style, lineWidthKey));

  const lineOpacityKey = `${STROKE_PREFIX}_${OPACITY_SUFFIX}`;
  dataDrivenStyles.push(getDataDrivenOpacityProperty(style, lineOpacityKey));
  nonDataDrivenStyles.push(
    getNonDataDrivenOpacityProperty(style, lineOpacityKey),
  );

  const mergedStyles = mergeAllStyles(
    dataDrivenStyles,
    nonDataDrivenStyles,
    isNumeric,
  );
  return mergedStyles;
}

export function getFillStyles(
  style: LayerColumnSymbology,
): MapExportLayerStyleBreak[] {
  const isNumeric = isNumericSymbology(style);
  const nonDataDrivenStyles: PartialStyle[] = [];
  const dataDrivenStyles: PartialDataDrivenStyle[][] = [];

  const fillColorKey = `${FILL_PREFIX}_${COLOR_SUFFIX}`;
  dataDrivenStyles.push(getDataDrivenColorProperty(style, fillColorKey));
  nonDataDrivenStyles.push(getNonDataDrivenColorProperty(style, fillColorKey));

  const fillOpacityKey = `${FILL_PREFIX}_${OPACITY_SUFFIX}`;
  dataDrivenStyles.push(getDataDrivenOpacityProperty(style, fillOpacityKey));
  nonDataDrivenStyles.push(
    getNonDataDrivenOpacityProperty(style, fillOpacityKey),
  );

  const mergedStyles = mergeAllStyles(
    dataDrivenStyles,
    nonDataDrivenStyles,
    isNumeric,
  );
  return mergedStyles;
}

export function getCircleStyles(
  style: LayerColumnSymbology,
): MapExportLayerStyleBreak[] {
  const isNumeric = isNumericSymbology(style);
  const nonDataDrivenStyles: PartialStyle[] = [];
  const dataDrivenStyles: PartialDataDrivenStyle[][] = [];

  const fillColorKey = `${FILL_PREFIX}_${COLOR_SUFFIX}`;
  dataDrivenStyles.push(getDataDrivenColorProperty(style, fillColorKey));
  nonDataDrivenStyles.push(getNonDataDrivenColorProperty(style, fillColorKey));

  const fillOpacityKey = `${FILL_PREFIX}_${OPACITY_SUFFIX}`;
  dataDrivenStyles.push(getDataDrivenOpacityProperty(style, fillOpacityKey));
  nonDataDrivenStyles.push(
    getNonDataDrivenOpacityProperty(style, fillOpacityKey),
  );

  const radiusKey = `${RADIUS_PREFIX}`;
  dataDrivenStyles.push(getDataDrivenRadiusProperty(style, radiusKey));
  nonDataDrivenStyles.push(getNonDataDrivenRadiusProperty(style, radiusKey));

  const lineColorKey = `${STROKE_PREFIX}_${COLOR_SUFFIX}`;
  dataDrivenStyles.push(getDataDrivenStrokeColorProperty(style, lineColorKey));
  nonDataDrivenStyles.push(
    getNonDataDrivenStrokeColorProperty(style, lineColorKey),
  );

  const lineWidthKey = `${STROKE_PREFIX}_${WIDTH_SUFFIX}`;
  // TODO: Add support for DataDrivenStrokeWidthProperties
  nonDataDrivenStyles.push(
    getNonDataDrivenStrokeWidthProperty(style, lineWidthKey),
  );

  const lineOpacityKey = `${STROKE_PREFIX}_${OPACITY_SUFFIX}`;
  dataDrivenStyles.push(getDataDrivenOpacityProperty(style, lineOpacityKey));
  nonDataDrivenStyles.push(
    getNonDataDrivenOpacityProperty(style, lineOpacityKey),
  );

  const mergedStyles = mergeAllStyles(
    dataDrivenStyles,
    nonDataDrivenStyles,
    isNumeric,
  );
  return mergedStyles;
}

function mergeSymbologies(
  allStyles: MapExportLayerStyle[][],
): MapExportLayerStyleBreak[] {
  const nonDataDrivenStyles = [];
  const dataDrivenStyles = [];
  allStyles.forEach(style => {
    if (style.length === 1) {
      nonDataDrivenStyles.push(...style);
    } else {
      dataDrivenStyles.push(style);
    }
  });

  const mergedNonDataDrivenStyle = mergeObjects(nonDataDrivenStyles);
  const mergedDataDrivenStyles = mergeDataDrivenStyles(dataDrivenStyles);

  const mergedStyles = mergeStyles(
    mergedDataDrivenStyles,
    mergedNonDataDrivenStyle,
  );

  return mergedStyles;
}

export function getShowZeroValues(styles: LayerColumnSymbology[]): boolean {
  return styles[0]?.display_hints?.showZero ?? false;
}

export function getLayerStyles(
  projectId: ProjectId,
  viewId: ViewId,
  layerIds: LayerId[],
  virtualLayerIdMap: Record<LayerId, LegacyVirtualLayerId>,
  columnKeys: ColumnKey[],
  getDivideByColumn: GetDivideByColumn,
  getSymbologyState: GetSymbologyState,
): MapExportLayerStyle[] {
  const layerRefs = _.zip(layerIds, columnKeys);
  const layerStyleBreaks = layerRefs.map((layerRef): MapExportLayerStyle => {
    const [layerId, columnKey] = layerRef;
    const virtualLayerId = virtualLayerIdMap[layerId];

    const styles = getData(
      getSymbologyState({
        projectId,
        viewId,
        layerId,
        virtualLayerId,
        columnKey,
      }),
      EMPTY_ARRAY,
    );

    if (!styles) {
      return;
    }

    const allStyles = [];
    styles.forEach(style => {
      if (isFillSymbology(style)) {
        allStyles.push(getFillStyles(style));
      } else if (isLineSymbology(style)) {
        allStyles.push(getLineStyles(style));
      } else if (isCircleSymbology(style)) {
        allStyles.push(getCircleStyles(style));
      }
    });

    const mergedStyles = mergeSymbologies(allStyles);

    const showZeroValues = getShowZeroValues(styles);

    // TODO: revisit this redundant call, try to remove instances further up stack
    const cleanedStyles = removeUndefinedProperties(mergedStyles);

    const divideByColumn = getDivideByColumn(projectId, layerId, columnKey);
    return {
      layer_style: cleanedStyles,
      divide_by_column: divideByColumn || undefined,
      show_zero_values: showZeroValues,
    };
  });
  return layerStyleBreaks;
}
