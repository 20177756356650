import colors from 'material-colors';

import {
  CuratedColumnSymbology,
  CUSTOM_THEME,
  DEFAULT_OPACITY,
  InterpolationTypes,
  PaintColorTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/public/dataset/bay_area_conservation_lands_network': {
    final_cln: [
      {
        type: SymbologyTypes.FILL,
        paint: {
          'fill-color': {
            property: 'final_cln',
            type: PaintColorTypes.CATEGORICAL,
            stops: [
              { value: 'Essential', color: colors.brown[800] },
              { value: 'Fragmented', color: colors.brown[300] },
              { value: 'Further Consideration', color: colors.brown[100] },
              { value: 'Important', color: '#5f4842' },
            ],
          },
          'fill-opacity': DEFAULT_OPACITY,
        },
        display_hints: {
          scale: SymbologyScales.CATEGORICAL,
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          theme: CUSTOM_THEME,
        },
      },
    ],
  },
};

export default symbologies;
