import {
  Action,
  createHistory as createBrowserHistory,
  createMemoryHistory,
  History,
  HistoryOptions,
  Location,
  MemoryHistoryOptions,
} from 'history';
import { parse } from 'query-string';

/**
 * Polyfill for history v4
 */
export interface FutureHistory {
  location: Location<{ [key: string]: string }>;
}
/**
 * createHistory()
 * The purpose of this function is to bridge the gap between the npm `history` package's
 * v3 and v4.  react-router-3 uses history v3 and redux-first-router uses
 * history v4.  The main differences in the versions are that history v3 has a getCurrentLocation()
 * method while v4 has a location property.  Also, v4 expects a query object property on the
 * location object.  This createHistory() function returns a history object that can be used by
 * consumers that expect either history version.
 */

// TODO: fix the type signature for path when we upgrade to history v4.  @types/history@3.0
// expects an MemoryHistoryOptions parameter for createMemoryHistory but this is not in line with
// the implementation which expects a string path.
export function createHistory(path?: HistoryOptions & MemoryHistoryOptions) {
  // if we are running tests or doing SSR then we need to use memory history
  const history: History & FutureHistory = __CLIENT__
    ? createBrowserHistory(path)
    : createMemoryHistory(path);

  // polyfill query in location returned from getCurrentLocation
  const getCurrentLocation = history.getCurrentLocation;
  history.getCurrentLocation = () => {
    const location = getCurrentLocation();
    return locationWithQuery(location);
  };

  // polyfill history v4 location property
  Object.defineProperty(history, 'location', {
    get() {
      const location = locationWithQuery(history.getCurrentLocation());
      return location;
    },
    enumerable: true,
  });

  // polyfill second argument to history listeners
  const originalListen = history.listen;
  history.listen = (listener: LocationActionListener) => {
    const listenerWithSecondArgument = (location?: Location) =>
      listener(locationWithQuery(location), location.action);
    return originalListen(listenerWithSecondArgument);
  };

  return history;
}

function locationWithQuery(location: Location): Location {
  return {
    // ensure query is present
    query: parse(location.search || ''),
    // ensure pathname is present, allow location to overwrite
    pathname: '/',
    ...location,
  };
}

type LocationActionListener = (location?: Location, action?: Action) => void;
