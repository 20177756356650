import { Epic, ofType } from 'redux-observable';
import { filter, map } from 'rxjs/operators';
import warning from 'warning';

import {
  deleteUserDataActionTypes,
  setUserDataActionTypes,
} from 'uf-api/api/userdata.service';
import { getActiveProjectId } from 'uf/app/selectors';
import { combineEpics } from 'uf/base/epics';
import { SuccessAction } from 'uf/data/ActionTypes';
import { loadBookmarks } from 'uf/explore/actions/bookmarks';
import {
  makeBookmarkKeyPrefix,
  MapPositionEnvelope,
} from 'uf/explore/bookmarks';
import { loadUserData } from 'uf/user/actions/data';
import { getUserKey } from 'uf/user/selectors/user';
import { TypedUserData } from 'uf/user/state';

type SavedMapPosition = TypedUserData<MapPositionEnvelope>;
export const reloadBookmarkOnSave: Epic<
  SuccessAction<SavedMapPosition>,
  any
> = (action$, state$) => {
  return action$.pipe(
    ofType(setUserDataActionTypes.SUCCESS),
    filter(action => {
      const projectId = getActiveProjectId(state$.value);
      warning(
        !!projectId,
        'Missing active project while removing bookmark',
        action.projectId,
      );
      return (
        projectId &&
        action.extra.dataKey.startsWith(makeBookmarkKeyPrefix(projectId))
      );
    }),
    map(action => {
      const userKey = getUserKey(state$.value);
      return loadUserData(action.extra.dataKey, userKey);
    }),
  );
};
export const reloadBookmarkKeysOnChange: Epic<SuccessAction<{}>, any> = (
  action$,
  state$,
) => {
  return action$
    .ofType(setUserDataActionTypes.SUCCESS, deleteUserDataActionTypes.SUCCESS)
    .pipe(
      filter(action => {
        const projectId = getActiveProjectId(state$.value);
        warning(
          !!projectId,
          'Missing active project while removing bookmark',
          action.projectId,
        );
        return (
          projectId &&
          action.extra.dataKey.startsWith(makeBookmarkKeyPrefix(projectId))
        );
      }),
      map(() => {
        const projectId = getActiveProjectId(state$.value);
        const userKey = getUserKey(state$.value);
        return loadBookmarks(projectId, userKey);
      }),
    );
};

export default combineEpics(
  {
    reloadBookmarkKeysOnChange,
    reloadBookmarkOnSave,
  },
  'bookmarks',
);
