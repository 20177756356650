import { Button, Navbar } from '@blueprintjs/core';
import { mdiAlertCircle } from '@mdi/js';
import Icon from '@mdi/react';
import classNames from 'classnames';
import _ from 'lodash';
import React, { ReactNode } from 'react';
import { Box } from 'react-layout-components';
import { connect } from 'react-redux';

import logo from 'uf/assets/logo.svg';
import { setExploreRoute as setExploreRouteAction } from 'uf/explore/actions/routing';
import buttonStyles from 'uf/styles/buttons.module.css';
import rootStyles from 'uf/styles/root.module.css';
import textStyles from 'uf/styles/text.module.css';
import ErrorBoundary from 'uf/ui/base/ErrorBoundary/ErrorBoundary';
import ErrorBoundaryReloadButton from 'uf/ui/base/ErrorBoundary/ErrorReloadButton';
import { ErrorFallbackProps } from 'uf/ui/base/ErrorFallback/ErrorFallback';
import errorStyles from 'uf/ui/base/ErrorFallback/ErrorFallback.module.css';
import { IconSizeNew } from 'uf/ui/base/icon';

import styles from './Header.module.css';
import HeaderMenus, { HeaderMenuKeys } from './HeaderMenus';
import CreateProjectDialog from 'uf/ui/projects/CreateProjectDialog/CreateProjectDialog';
import LocationAnalysisDialog from 'uf/ui/location-analysis/LocationAnalysisDialog/LocationAnalysisDialog';

const navBarClassName = classNames(
  rootStyles.flexBox,
  rootStyles.noPadding,
  buttonStyles.flat,
  styles.header,
);

const errorIcon = (
  <Icon
    path={mdiAlertCircle}
    className={errorStyles.lightIcon}
    size={IconSizeNew.MEDIUM}
  />
);

const leftMenuOptions: HeaderMenuKeys[] = ['projectMenu'];
const rightMenuOptions: HeaderMenuKeys[] = [
  'subscriptionStatusLink',
  'developerTools',
  'helpLink',
  'sharingCircles',
  'userMenu',
  'createProjectButton',
];
interface DispatchProps {
  setExploreRoute: () => void;
}

interface OwnProps {
  menus: Record<HeaderMenuKeys, boolean>;

  children?: ReactNode;
}

type Props = DispatchProps & OwnProps;

export const Header: React.FunctionComponent<Props> = ({
  menus,
  setExploreRoute,
  children,
}) => {
  const leftMenus = getHeaderMenus(menus, leftMenuOptions);
  const rightMenus = getHeaderMenus(menus, rightMenuOptions);

  return (
    <Navbar className={navBarClassName}>
      <ErrorBoundary
        icon={errorIcon}
        errorFallbackComponent={HeaderErrorFallback}>
        <Button
          className={styles.logoButton}
          minimal
          onClick={setExploreRoute}
          icon={<img src={logo} width={24} alt="UrbanFootprint" />}
        />

        <HeaderMenus menus={leftMenus} />

        <Box minWidth={0} flex={1}>
          {children}
        </Box>

        <HeaderMenus className={styles.rightHeaderMenus} menus={rightMenus} />
        <CreateProjectDialog />
        <LocationAnalysisDialog />
      </ErrorBoundary>
    </Navbar>
  );
};

export default connect<never, DispatchProps, OwnProps>(null, {
  setExploreRoute: setExploreRouteAction,
})(Header);

const titleClass = classNames(
  rootStyles.thinPaddingLeft,
  textStyles.bold,
  errorStyles.lightText,
);
const descriptionClass = classNames(
  rootStyles.thinPaddingHorizontal,
  errorStyles.lightText,
  styles.errorDescription,
);

function HeaderErrorFallback(props: ErrorFallbackProps) {
  const { icon, title, description, onClick } = props;
  return (
    <Box fit center>
      {icon}
      <div className={titleClass}>{title}</div>
      <div className={descriptionClass}>{description}</div>
      <ErrorBoundaryReloadButton onClick={onClick} />
    </Box>
  );
}

function getHeaderMenus(
  menus: Record<HeaderMenuKeys, boolean>,
  list: HeaderMenuKeys[],
) {
  return _.pickBy(
    menus,
    (value, key: HeaderMenuKeys) => list.includes(key) && value,
  ) as Record<HeaderMenuKeys, boolean>;
}
