import { combineReducers } from 'redux';

import { ViewState } from 'uf/views/state';

import layers from './layers';
import symbology from './symbology';
import views from './views';

const reducer = combineReducers<ViewState>({
  views,
  layers,
  symbology,
});

export default reducer;
