import colors from 'material-colors';

import {
  CuratedColumnSymbology,
  CUSTOM_THEME,
  DEFAULT_OPACITY,
  InterpolationTypes,
  PaintColorTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/public/dataset/national_registry_of_historic_places': {
    type: [
      {
        type: SymbologyTypes.CIRCLE,
        paint: {
          'circle-radius': 3,
          'circle-color': {
            property: 'type',
            type: PaintColorTypes.CATEGORICAL,
            stops: [
              { value: 'Building', color: colors.teal[500] },
              { value: 'District', color: '#3a7dff' },
              { value: 'Object', color: '#6b7098' },
              { value: 'Site', color: '#ff8300' },
              { value: 'Structures', color: colors.deepPurple[500] },
            ],
          },
          'circle-opacity': DEFAULT_OPACITY,
        },
        display_hints: {
          scale: SymbologyScales.CATEGORICAL,
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          theme: CUSTOM_THEME,
        },
      },
    ],
  },
};

export default symbologies;
