import { Map, MapMouseEvent } from 'mapbox-gl';

import { PolygonGeometry } from 'uf/base/geometry';
import { ENTER, ESCAPE, SHIFT } from 'uf/base/keys';

import Polygon from './Polygon';

/**
 * Polygon Selection Mode:
 * Click on the map to create a polygon.
 * @param map - the mapboxgl map instance
 * @param onPolygonSelect - called with arguments: features, LngLatBounds, map
 * @return unsetPolygonSelectionMode - cleans up any Polygon Selection Mode artifacts
 */
export function setPolygonSelectionMode(
  map: Map,
  onPolygonSelect: (polygon: PolygonGeometry, add: boolean) => void,
): () => void {
  const canvas = map.getCanvasContainer();
  const polygon = new Polygon(map);
  let isCursorOverPoint = false;
  let add: boolean = false;

  map.boxZoom.disable();
  map.doubleClickZoom.disable();
  map.on('click', click);
  map.on('mousemove', mousemove);
  map.on('dblclick', dblclick);
  document.body.addEventListener('keydown', keydown, true);
  document.body.addEventListener('keyup', keyup, true);

  canvas.style.cursor = 'crosshair';

  // MapView unSet function
  return () => {
    polygon.remove();
    map.off('click', click);
    map.off('mousemove', mousemove);
    map.off('dblclick', dblclick);
    document.body.removeEventListener('keydown', keydown);
    document.body.removeEventListener('keyup', keyup);
  };

  function click(mouseEvent: MapMouseEvent) {
    const {
      lngLat: { lng, lat },
    } = mouseEvent;
    updatePolygonFromClick(
      polygon,
      lng,
      lat,
      isCursorOverPoint,
      add,
      onPolygonSelect,
    );
  }

  function mousemove(mouseEvent: MapMouseEvent) {
    isCursorOverPoint = getIsCursorOverPoint(mouseEvent, polygon, canvas);
  }

  function dblclick() {
    // do nothing if there is only one point so far
    // (need 2 prev points and the dblclick point to make a polygon)
    if (polygon.getNumCoordinates() >= 2) {
      if (polygon.closePolygon()) {
        // send the coordinates to redux store
        onPolygonSelect(polygon.getCoordinates(), add);
        polygon.reset();
      }
    }
  }

  function keyup(e: KeyboardEvent) {
    // TODO: use e.keyCode or e.shiftKey ?
    if (e.which === SHIFT) {
      add = false;
    }
  }

  function keydown(e: KeyboardEvent) {
    // TODO: use e.keyCode or e.shiftKey ?
    if (e.which === ESCAPE) {
      polygon.reset();
      e.preventDefault();
    }
    // TODO: use e.keyCode or e.shiftKey ?
    if (e.which === SHIFT) {
      add = true;
    }
    // TODO: use e.keyCode or e.shiftKey ?
    if (e.which === ENTER) {
      // if there are enough points to close the polygon, close it
      if (polygon.closePolygon()) {
        // send the coordinates to redux store
        onPolygonSelect(polygon.getCoordinates(), add);
        polygon.reset();
      }
    }
  }
}
function getIsCursorOverPoint(
  mouseEvent: MapMouseEvent,
  polygon: Polygon,
  canvas: HTMLElement,
) {
  const {
    point,
    lngLat: { lng, lat },
  } = mouseEvent;
  // set a flag to enable other mouse events.
  polygon.setMousePosition([lng, lat]);
  polygon.draw();
  const isCursorOverPoint = polygon.isCursorOverStartingPoint(point);
  if (isCursorOverPoint) {
    canvas.style.cursor = 'pointer';
  } else {
    canvas.style.cursor = 'crosshair';
  }
  return isCursorOverPoint;
}

function updatePolygonFromClick(
  polygon: Polygon,
  lng: number,
  lat: number,
  isCursorOverPoint: boolean,
  add: boolean,
  onPolygonReady: (polygon: number[][], add: boolean) => void,
) {
  if (isCursorOverPoint) {
    // if there are enough points to close the polygon, close it
    if (polygon.closePolygon()) {
      // send the coordinates to redux store
      onPolygonReady(polygon.getCoordinates(), add);
      polygon.reset();
    }
    return;
  }

  if (polygon.addCoordinates([lng, lat])) {
    polygon.draw();
  }
}
