import { Card } from '@blueprintjs/core';
import _ from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { connect } from 'react-redux';

import { ReportOutputGroupSpec, ReportSpec } from 'uf-api/model/models';
import { getLocalName } from 'uf/base/formatting';
import { ProjectId } from 'uf/projects';
import {
  ChartDatum,
  makeGetChartData,
  makeGetChartLoading,
  makeGetChartSubtitle,
} from 'uf/reporting/selectors';
import { ScenarioId } from 'uf/scenarios';
import { BarChart } from 'uf/ui/charts/BarChart/BarChart';

interface OwnProps {
  width: number;
  height: number;

  projectId: ProjectId;
  scenarioId: ScenarioId;

  outputGroup: ReportOutputGroupSpec;
  spec: ReportSpec;
  moduleKey: string;
  className?: string;
}

interface StateProps {
  loading?: boolean;
  data: ChartDatum[][];
  chartSubtitle: string;
}

export const AnalysisChart: React.FunctionComponent<StateProps & OwnProps> =
  props => {
    const {
      width,
      loading,
      outputGroup,
      data: unfilteredData,
      className,
      chartSubtitle,
    } = props;

    const isPercent = useMemo(() => {
      return (
        unfilteredData.length &&
        unfilteredData.every(
          dataDatum =>
            dataDatum.length && dataDatum.every(datum => datum.is_percent),
        )
      );
    }, [unfilteredData]);

    const { name } = outputGroup;

    const data = useMemo(
      () =>
        unfilteredData.map(chartData =>
          chartData.filter(datum => Number.isFinite(datum.value)),
        ),
      [unfilteredData],
    );
    const getUnitKey = useCallback(() => {
      // filter out items with null (non-existent) data
      // If units are not the same across the data, don't show the value axis label.
      // If the units are undefined and we know this is a percent, use Percent.
      const allUnits = _.compact(
        _.uniq(data.map(datum => _.uniq(datum.map(d => d?.units)))),
      );
      let units = null;
      if (allUnits.length === 1) {
        units = allUnits[0];
      } else if (!allUnits.length && isPercent) {
        units = 'percent';
      }

      return units;
    }, [data, isPercent]);

    // We'll show a spinner, but if the chart has no data, then we'll hide the whole chart.
    const hasData = data?.some(datum => datum.some(d => d.value !== undefined));

    if (!hasData) {
      return null;
    }

    return (
      <Card className={className}>
        <BarChart
          loading={loading}
          title={name}
          subtitle={chartSubtitle}
          isPercent={isPercent}
          data={data[0]}
          orientation="horizontal"
          width={width}
          getLabel={getLocalName}
          getUnitKey={getUnitKey}
        />
      </Card>
    );
  };

export default connect<StateProps, never, OwnProps>(() => {
  const getChartData = makeGetChartData();
  const getChartLoading = makeGetChartLoading();
  const getChartSubtitle = makeGetChartSubtitle();
  return (state, props: OwnProps): StateProps => {
    const { outputGroup, spec, projectId, scenarioId, moduleKey } = props;
    const resultParams = {
      projectId,
      spec,
      reportKey: outputGroup.key,
      analysisModuleKey: moduleKey,
      scenarioIds: [scenarioId],
    };
    return {
      data: getChartData(state, resultParams),
      loading: getChartLoading(state, resultParams),
      chartSubtitle: getChartSubtitle(state, resultParams),
    };
  };
})(AnalysisChart);
