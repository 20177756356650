/**
 * UrbanFootprint App API
 * API for serving UrbanFootprint Application
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { ClientResponse } from '../variables';

// @ts-ignore: This is used only for params, but some services have param-free APIs
import { UserServiceInterface } from './user.serviceInterface';

// @ts-ignore: These imports are for both params and returnType, but we only use returnType
import { InvitationList } from '../model/invitationList';
// @ts-ignore: These imports are for both params and returnType, but we only use returnType
import { InvitationListItem } from '../model/invitationListItem';
// @ts-ignore: These imports are for both params and returnType, but we only use returnType
import { ProblemDetail } from '../model/problemDetail';

/**
 * Package: api
 */

const acceptUserInvitationPrefix = `uf-api/user/accept_user_invitation/`;
/**
 * Action types for user.accept_user_invitation
 *
 * Accept invitation to join organization
 */
export const acceptUserInvitationActionTypes = {
  LOAD: `${acceptUserInvitationPrefix}LOAD` as 'uf-api/user/accept_user_invitation/LOAD',
  SUCCESS:
    `${acceptUserInvitationPrefix}SUCCESS` as 'uf-api/user/accept_user_invitation/SUCCESS',
  FAILURE:
    `${acceptUserInvitationPrefix}FAILURE` as 'uf-api/user/accept_user_invitation/FAILURE',
  CLEAR:
    `${acceptUserInvitationPrefix}CLEAR` as 'uf-api/user/accept_user_invitation/CLEAR',
} as const;

/**
 * LOAD action for user.accept_user_invitation
 * Accept invitation to join organization
 */
export interface acceptUserInvitationLoadAction {
  readonly type: 'uf-api/user/accept_user_invitation/LOAD';
  readonly params: UserServiceInterface.acceptUserInvitationParams;
  readonly promise: Promise<ClientResponse<{}>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for user.accept_user_invitation
 * Accept invitation to join organization
 */
export interface acceptUserInvitationSuccessAction {
  readonly type: 'uf-api/user/accept_user_invitation/SUCCESS';
  readonly result: {};
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for user.accept_user_invitation
 * Accept invitation to join organization
 */
export interface acceptUserInvitationFailureAction {
  readonly type: 'uf-api/user/accept_user_invitation/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for user.accept_user_invitation
 * Accept invitation to join organization
 */
export interface acceptUserInvitationClearAction {
  readonly type: 'uf-api/user/accept_user_invitation/CLEAR';
  readonly key: string;
}

const cancelTaskPrefix = `uf-api/user/cancel_task/`;
/**
 * Action types for user.cancel_task
 *
 * Cancels a running task.
 */
export const cancelTaskActionTypes = {
  LOAD: `${cancelTaskPrefix}LOAD` as 'uf-api/user/cancel_task/LOAD',
  SUCCESS: `${cancelTaskPrefix}SUCCESS` as 'uf-api/user/cancel_task/SUCCESS',
  FAILURE: `${cancelTaskPrefix}FAILURE` as 'uf-api/user/cancel_task/FAILURE',
  CLEAR: `${cancelTaskPrefix}CLEAR` as 'uf-api/user/cancel_task/CLEAR',
} as const;

/**
 * LOAD action for user.cancel_task
 * Cancels a running task.
 */
export interface cancelTaskLoadAction {
  readonly type: 'uf-api/user/cancel_task/LOAD';
  readonly params: UserServiceInterface.cancelTaskParams;
  readonly promise: Promise<ClientResponse<{}>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for user.cancel_task
 * Cancels a running task.
 */
export interface cancelTaskSuccessAction {
  readonly type: 'uf-api/user/cancel_task/SUCCESS';
  readonly result: {};
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for user.cancel_task
 * Cancels a running task.
 */
export interface cancelTaskFailureAction {
  readonly type: 'uf-api/user/cancel_task/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for user.cancel_task
 * Cancels a running task.
 */
export interface cancelTaskClearAction {
  readonly type: 'uf-api/user/cancel_task/CLEAR';
  readonly key: string;
}

const getInvitationsForUserPrefix = `uf-api/user/get_invitations_for_user/`;
/**
 * Action types for user.get_invitations_for_user
 *
 * Get list of pending invitations for current user
 */
export const getInvitationsForUserActionTypes = {
  LOAD: `${getInvitationsForUserPrefix}LOAD` as 'uf-api/user/get_invitations_for_user/LOAD',
  SUCCESS:
    `${getInvitationsForUserPrefix}SUCCESS` as 'uf-api/user/get_invitations_for_user/SUCCESS',
  FAILURE:
    `${getInvitationsForUserPrefix}FAILURE` as 'uf-api/user/get_invitations_for_user/FAILURE',
  CLEAR:
    `${getInvitationsForUserPrefix}CLEAR` as 'uf-api/user/get_invitations_for_user/CLEAR',
} as const;

/**
 * LOAD action for user.get_invitations_for_user
 * Get list of pending invitations for current user
 */
export interface getInvitationsForUserLoadAction {
  readonly type: 'uf-api/user/get_invitations_for_user/LOAD';

  readonly promise: Promise<ClientResponse<InvitationList>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for user.get_invitations_for_user
 * Get list of pending invitations for current user
 */
export interface getInvitationsForUserSuccessAction {
  readonly type: 'uf-api/user/get_invitations_for_user/SUCCESS';
  readonly result: InvitationList;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for user.get_invitations_for_user
 * Get list of pending invitations for current user
 */
export interface getInvitationsForUserFailureAction {
  readonly type: 'uf-api/user/get_invitations_for_user/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for user.get_invitations_for_user
 * Get list of pending invitations for current user
 */
export interface getInvitationsForUserClearAction {
  readonly type: 'uf-api/user/get_invitations_for_user/CLEAR';
  readonly key: string;
}

const getUserInvitationPrefix = `uf-api/user/get_user_invitation/`;
/**
 * Action types for user.get_user_invitation
 *
 * Get invitation info
 */
export const getUserInvitationActionTypes = {
  LOAD: `${getUserInvitationPrefix}LOAD` as 'uf-api/user/get_user_invitation/LOAD',
  SUCCESS:
    `${getUserInvitationPrefix}SUCCESS` as 'uf-api/user/get_user_invitation/SUCCESS',
  FAILURE:
    `${getUserInvitationPrefix}FAILURE` as 'uf-api/user/get_user_invitation/FAILURE',
  CLEAR:
    `${getUserInvitationPrefix}CLEAR` as 'uf-api/user/get_user_invitation/CLEAR',
} as const;

/**
 * LOAD action for user.get_user_invitation
 * Get invitation info
 */
export interface getUserInvitationLoadAction {
  readonly type: 'uf-api/user/get_user_invitation/LOAD';
  readonly params: UserServiceInterface.getUserInvitationParams;
  readonly promise: Promise<ClientResponse<InvitationListItem>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for user.get_user_invitation
 * Get invitation info
 */
export interface getUserInvitationSuccessAction {
  readonly type: 'uf-api/user/get_user_invitation/SUCCESS';
  readonly result: InvitationListItem;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for user.get_user_invitation
 * Get invitation info
 */
export interface getUserInvitationFailureAction {
  readonly type: 'uf-api/user/get_user_invitation/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for user.get_user_invitation
 * Get invitation info
 */
export interface getUserInvitationClearAction {
  readonly type: 'uf-api/user/get_user_invitation/CLEAR';
  readonly key: string;
}

const rejectUserInvitationPrefix = `uf-api/user/reject_user_invitation/`;
/**
 * Action types for user.reject_user_invitation
 *
 * Reject invitation to join organization.
 */
export const rejectUserInvitationActionTypes = {
  LOAD: `${rejectUserInvitationPrefix}LOAD` as 'uf-api/user/reject_user_invitation/LOAD',
  SUCCESS:
    `${rejectUserInvitationPrefix}SUCCESS` as 'uf-api/user/reject_user_invitation/SUCCESS',
  FAILURE:
    `${rejectUserInvitationPrefix}FAILURE` as 'uf-api/user/reject_user_invitation/FAILURE',
  CLEAR:
    `${rejectUserInvitationPrefix}CLEAR` as 'uf-api/user/reject_user_invitation/CLEAR',
} as const;

/**
 * LOAD action for user.reject_user_invitation
 * Reject invitation to join organization.
 */
export interface rejectUserInvitationLoadAction {
  readonly type: 'uf-api/user/reject_user_invitation/LOAD';
  readonly params: UserServiceInterface.rejectUserInvitationParams;
  readonly promise: Promise<ClientResponse<{}>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for user.reject_user_invitation
 * Reject invitation to join organization.
 */
export interface rejectUserInvitationSuccessAction {
  readonly type: 'uf-api/user/reject_user_invitation/SUCCESS';
  readonly result: {};
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for user.reject_user_invitation
 * Reject invitation to join organization.
 */
export interface rejectUserInvitationFailureAction {
  readonly type: 'uf-api/user/reject_user_invitation/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for user.reject_user_invitation
 * Reject invitation to join organization.
 */
export interface rejectUserInvitationClearAction {
  readonly type: 'uf-api/user/reject_user_invitation/CLEAR';
  readonly key: string;
}

export const userActionTypesMap = {
  /** Action types for user.accept_user_invitation */
  accept_user_invitation: acceptUserInvitationActionTypes,
  /** Action types for user.cancel_task */
  cancel_task: cancelTaskActionTypes,
  /** Action types for user.get_invitations_for_user */
  get_invitations_for_user: getInvitationsForUserActionTypes,
  /** Action types for user.get_user_invitation */
  get_user_invitation: getUserInvitationActionTypes,
  /** Action types for user.reject_user_invitation */
  reject_user_invitation: rejectUserInvitationActionTypes,
} as const;

export const userDetailsMap = {
  accept_user_invitation: {
    /** HTTP method for user.accept_user_invitation */
    httpMethod: 'post' as const,
  },
  cancel_task: {
    /** HTTP method for user.cancel_task */
    httpMethod: 'delete' as const,
  },
  get_invitations_for_user: {
    /** HTTP method for user.get_invitations_for_user */
    httpMethod: 'get' as const,
  },
  get_user_invitation: {
    /** HTTP method for user.get_user_invitation */
    httpMethod: 'get' as const,
  },
  reject_user_invitation: {
    /** HTTP method for user.reject_user_invitation */
    httpMethod: 'delete' as const,
  },
} as const;

/**
 * Local DataState<T> - kept in sync with uf/data/dataState.ts
 */
interface DataState<T> {
  readonly loading: boolean;
  readonly loaded: boolean;
  readonly requestId: string;
  readonly requestTime?: string;
  readonly responseTime?: string;
  readonly requestCount?: number;
  readonly error?: any;
  readonly promise?: Promise<T>;
  readonly data?: T;
  readonly extra?: any;
  readonly key?: string;
}
export interface UserServiceState {
  /** all recent acceptUserInvitation results (POST/PUT/PATCH) */
  readonly accept_user_invitation: DataState<{}>[];
  /** cancelTask state, by key */
  cancel_task: {
    readonly [key: string]: DataState<{}>;
  };
  /** getInvitationsForUser state, by key */
  get_invitations_for_user: {
    readonly [key: string]: DataState<InvitationList>;
  };
  /** getUserInvitation state, by key */
  get_user_invitation: {
    readonly [key: string]: DataState<InvitationListItem>;
  };
  /** rejectUserInvitation state, by key */
  reject_user_invitation: {
    readonly [key: string]: DataState<{}>;
  };
}
