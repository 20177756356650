import { mdiLayers } from '@mdi/js';
import classNames from 'classnames';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { msgid, ngettext, t } from 'ttag';
import { useGetRunningProjectLayerAnalysisRunsQuery } from 'uf-api-rtk/store/Api';
import { getActiveOrganizationKey } from 'uf/app/selectors';
import { parseFullPath } from 'uf/base/dataset';
import { events } from 'uf/layers/logging';
import { logEvent } from 'uf/logging';
import { ProjectId } from 'uf/projects';
import Flex from 'uf/ui/base/Flex';
import PaneHeader from 'uf/ui/base/Pane/PaneHeader';
import LayerImportDialog from 'uf/ui/layers/LayerImportDialog/LayerImportDialog';
import LayerManagerDialog from 'uf/ui/layers/LayerManager/LayerManagerDialog/LayerManagerDialog';
import PointsOfInterestDialog from 'uf/ui/points-of-interest/PointsOfInterestDialog';
import useUserFlag from 'uf/ui/user/useUserFlag';
import { ViewPicker } from 'uf/ui/views/ViewPicker/ViewPicker';
import { ViewId } from 'uf/views';
import AddLayerMenu from './AddLayerMenu';
import styles from './ExploreLayerList.module.css';

interface Props {
  projectId: ProjectId;
  loading: boolean;
  layerCount: number;
  viewId: ViewId;
  onSelectView: (viewId: ViewId) => void;
}

const ExploreLayerListHeader = ({
  layerCount,
  loading,
  projectId,
  viewId,
  onSelectView,
}: Props) => {
  const [isLayerManagerOpen, setIsLayerManagerOpen] = useState(false);
  const [isLayerImportOpen, setIsLayerImportOpen] = useState(false);
  const [isFindPointsOfInterestOpen, setIsFindPointsOfInterestOpen] =
    useState(false);
  const projectKey = projectId ? parseFullPath(projectId).key : '';
  const namespace = useSelector(getActiveOrganizationKey);
  const { currentData: runningJobs } =
    useGetRunningProjectLayerAnalysisRunsQuery(
      {
        namespace,
        key: projectKey,
      },
      { skip: !projectKey || !namespace },
    );
  const numberOfLayers = ngettext(
    msgid`${layerCount} Layer`,
    `${layerCount} Layers`,
    layerCount,
  );
  // Number of active layer analysis service jobs
  const runningJobCount = runningJobs?.length || 0;
  const tooltip = ngettext(
    msgid`${runningJobCount} layer being processed`,
    `${runningJobCount} layers being processed`,
    runningJobCount,
  );
  const hasSavedViewsFlag = useUserFlag('saved-views');
  const handleAddLayerClick = () => {
    logEvent('Layer Manager Opened', { projectId });
    setIsLayerManagerOpen(true);
  };
  const handleImportLayerClick = () => {
    logEvent(events.LAYER_IMPORT_STARTED, { projectId });
    setIsLayerImportOpen(true);
  };
  const handleFindPointsOfInterestClick = () => {
    logEvent('Find Points of Interest Opened', { projectId });
    setIsFindPointsOfInterestOpen(true);
  };
  return (
    <>
      <PaneHeader
        showSpinner={loading || runningJobCount > 0}
        className={classNames(styles.header, 'tw-items-center')}
        iconPath={mdiLayers}
        title={t`Layers`}
        titleClassName="tw-min-w-0 tw-pt-0"
        tooltip={tooltip}>
        {!!projectId && (
          <AddLayerMenu
            onAddLayerClick={handleAddLayerClick}
            onImportLayerClick={handleImportLayerClick}
            onFindPointsOfInterestClick={handleFindPointsOfInterestClick}
          />
        )}
      </PaneHeader>
      {hasSavedViewsFlag && (
        <ViewPicker
          className="tw-ml-5 tw-space-x-2"
          projectId={projectId}
          viewId={viewId}
          onSelectViewId={onSelectView}
        />
      )}
      <Flex alignItems="center" className={styles.layerCount}>
        {numberOfLayers}
      </Flex>
      <LayerManagerDialog
        projectId={projectId}
        show={isLayerManagerOpen}
        onOk={() => setIsLayerManagerOpen(false)}
      />
      <LayerImportDialog
        projectId={projectId}
        show={isLayerImportOpen}
        onOk={() => setIsLayerImportOpen(false)}
        onCancel={() => setIsLayerImportOpen(false)}
      />
      <PointsOfInterestDialog
        isOpen={isFindPointsOfInterestOpen}
        onClose={() => setIsFindPointsOfInterestOpen(false)}
      />
    </>
  );
};

export default ExploreLayerListHeader;
