import { combineEpics } from 'uf/base/epics';

import { copyGeoJsonToEdits } from './features';

export default combineEpics(
  {
    copyGeoJsonToEdits,
  },
  'index',
);
