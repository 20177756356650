import { createSelector } from 'reselect';

import { LayerBreaks, LayerMetadata, LayerStats } from 'uf-api';
import { makeGetFromPropsSelector } from 'uf/base/selector';
import { isBuiltFormKey } from 'uf/builtforms';
import {
  combineDataStates,
  DataState,
  getData,
  isLoaded,
} from 'uf/data/dataState';
import { ColumnKey, LayerId } from 'uf/layers';
import { findColumnKeyBreaks } from 'uf/layers/breaks';
import { isDefaultGeoColumnKey } from 'uf/layers/geometryKey';
import { getUfGeometryType } from 'uf/layers/helpers';
import {
  getColumnMetadata,
  isCategoricalColumn,
  isNumericColumnKey,
  getIsPercent,
} from 'uf/layers/metadata';
import {
  makeGetLayerMetadataState,
  makeGetLayerMetadataStateGetter,
} from 'uf/layers/selectors/metadata';
import { findColumnKeyStats } from 'uf/layers/stats';
import { ProjectId } from 'uf/projects';
import { LayerColumnSymbology } from 'uf/symbology';
import { BuiltFormStop } from 'uf/symbology/builtforms';
import { setBuiltFormStops } from 'uf/symbology/clean';
import { DivideByColumn } from 'uf/symbology/divideByColumn';
import {
  makeGetSymbologyDefaultBreaksState,
  makeGetSymbologyDefaultBreaksStateGetter,
} from 'uf/symbology/selectors/breaks';
import {
  makeGetBuiltFormsStops,
  makeGetBuiltFormsStopsGetter,
  makeGetBuiltFormsStopsLoadingState,
  makeGetBuiltFormsStopsLoadingStateGetter,
} from 'uf/symbology/selectors/builtforms';
import {
  makeGetDivideByColumnState,
  makeGetDivideByColumnStateGetter,
} from 'uf/symbology/selectors/divideByColumn';
import {
  makeGetSymbologyStatsState,
  makeGetSymbologyStatsStateGetter,
} from 'uf/symbology/selectors/stats';
import { createBuiltFormSymbology } from 'uf/symbology/styles/dynamic/createBuiltFormSymbology';
import { createCategoricalSymbology } from 'uf/symbology/styles/dynamic/createCategoricalSymbology';
import { createDefaultGeometrySymbology } from 'uf/symbology/styles/dynamic/createDefaultGeometrySymbology';
import { createNumericSymbology } from 'uf/symbology/styles/dynamic/createNumericSymbology';
import { getSymbologyKey } from 'uf/symbology/symbologyKey';
import { TypedUserData } from 'uf/user/state';
import { getMergedSymbologyOrLoadingState } from './merge';

export function makeGetDefaultSymbologyState() {
  return createSelector(
    makeGetDefaultSymbologyLoadingState(),
    makeGetBuiltFormSymbologyState(),
    makeGetCategoricalSymbologyState(),
    makeGetNumericSymbologyState(),
    makeGetNoneColumnSymbologyState(),
    makeGetFromPropsSelector<ProjectId, 'projectId'>('projectId'),
    makeGetFromPropsSelector<LayerId, 'layerId'>('layerId'),
    makeGetFromPropsSelector<ColumnKey, 'columnKey'>('columnKey'),
    (
      loadingState,
      builtFormSymbologyState,
      categoricalSymbologyState,
      numericSymbologyState,
      noneColumnSymbologyState,
    ) => {
      return getMergedSymbologyOrLoadingState(
        loadingState,
        builtFormSymbologyState,
        categoricalSymbologyState,
        numericSymbologyState,
        noneColumnSymbologyState,
      );
    },
  );
}

export function makeGetDefaultSymbologyStateGetter() {
  return createSelector(
    makeGetDefaultSymbologyLoadingStateGetter(),
    makeGetBuiltFormSymbologyStateGetter(),
    makeGetCategoricalSymbologyStateGetter(),
    makeGetNumericSymbologyStateGetter(),
    makeGetNoneColumnSymbologyStateGetter(),
    (getLoadingState, getBuiltForm, getCategorical, getNumeric, getNone) =>
      (projectId: ProjectId, layerId: LayerId, columnKey: ColumnKey) => {
        const loadingState = getLoadingState(projectId, layerId, columnKey);
        const builtFormSymbologyState = getBuiltForm(
          projectId,
          layerId,
          columnKey,
        );
        const categoricalSymbologyState = getCategorical(
          projectId,
          layerId,
          columnKey,
        );
        const numericSymbologyState = getNumeric(projectId, layerId, columnKey);
        const noneColumnSymbologyState = getNone(projectId, layerId, columnKey);
        return getMergedSymbologyOrLoadingState(
          loadingState,
          builtFormSymbologyState,
          categoricalSymbologyState,
          numericSymbologyState,
          noneColumnSymbologyState,
        );
      },
  );
}

// TODO: Delete this and use the getter selector locally everywhere
function makeGetDefaultSymbologyLoadingState() {
  return createSelector(
    makeGetLayerMetadataState(),
    makeGetSymbologyStatsState(),
    makeGetBuiltFormsStopsLoadingState(),
    makeGetSymbologyDefaultBreaksState(),
    makeGetDivideByColumnState(),
    makeGetFromPropsSelector<ColumnKey, 'columnKey'>('columnKey'),
    (
      metadataState,
      statsState,
      builtFormsLoadingState,
      layerBreaksState,
      divideByColumnState,
      columnKey,
    ): DataState<null> => {
      return getDefaultSymbologyLoadingState(
        metadataState,
        statsState,
        builtFormsLoadingState,
        layerBreaksState,
        divideByColumnState,
        columnKey,
      );
    },
  );
}

function makeGetDefaultSymbologyLoadingStateGetter() {
  return createSelector(
    makeGetLayerMetadataStateGetter(),
    makeGetSymbologyStatsStateGetter(),
    makeGetBuiltFormsStopsLoadingStateGetter(),
    makeGetSymbologyDefaultBreaksStateGetter(),
    makeGetDivideByColumnStateGetter(),
    (
        getMetadataState,
        getStatsState,
        getBuiltFormsLoadingState,
        getLayerBreaksState,
        getDivideByColumnState,
      ) =>
      (
        projectId: ProjectId,
        layerId: LayerId,
        columnKey: ColumnKey,
      ): DataState<null> => {
        const metadataState = getMetadataState(layerId);
        const statsState = getStatsState(projectId, layerId, columnKey);
        const builtFormsLoadingState = getBuiltFormsLoadingState(
          projectId,
          layerId,
        );
        const layerBreaksState = getLayerBreaksState(
          projectId,
          layerId,
          columnKey,
        );
        const divideByColumnState = getDivideByColumnState(
          projectId,
          layerId,
          columnKey,
        );
        return getDefaultSymbologyLoadingState(
          metadataState,
          statsState,
          builtFormsLoadingState,
          layerBreaksState,
          divideByColumnState,
          columnKey,
        );
      },
  );
}
function getDefaultSymbologyLoadingState(
  metadataState: DataState<LayerMetadata>,
  statsState: DataState<LayerStats>,
  builtFormsLoadingState: DataState<null>,
  layerBreaksState: DataState<LayerBreaks>,
  divideByColumnState: DataState<TypedUserData<DivideByColumn>>,
  columnKey: ColumnKey,
): DataState<null> {
  const dataStates: DataState<any>[] = [metadataState, divideByColumnState];

  if (!isDefaultGeoColumnKey(columnKey)) {
    dataStates.push(statsState, layerBreaksState);
  }

  if (isBuiltFormKey(columnKey)) {
    dataStates.push(builtFormsLoadingState);
  }

  return combineDataStates(dataStates);
}

/**
 * Returns an "outlines" symbology for the given layer + columnKey. Most commonly used for the *
 * column, but can be useful when wanting to show something on the map while the rest of symbology
 * is loading.
 */
export function makeGetNoneColumnSymbologyState() {
  return createSelector(
    makeGetDefaultSymbologyLoadingState(),
    makeGetLayerMetadataState(),
    makeGetFromPropsSelector<LayerId, 'layerId'>('layerId'),
    makeGetFromPropsSelector<ColumnKey, 'columnKey'>('columnKey'),
    (
      loadingState,
      metadataState,
      layerId,
      columnKey,
    ): DataState<LayerColumnSymbology[]> => {
      return getNoneColumnSymbologyState(
        loadingState,
        metadataState,
        layerId,
        columnKey,
      );
    },
  );
}

export function makeGetNoneColumnSymbologyStateGetter() {
  return createSelector(
    makeGetDefaultSymbologyLoadingStateGetter(),
    makeGetLayerMetadataStateGetter(),
    (getLoadingState, getMetadataState) =>
      (
        projectId: ProjectId,
        layerId: LayerId,
        columnKey: ColumnKey,
      ): DataState<LayerColumnSymbology[]> => {
        const loadingState = getLoadingState(projectId, layerId, columnKey);
        const metadataState = getMetadataState(layerId);
        return getNoneColumnSymbologyState(
          loadingState,
          metadataState,
          layerId,
          columnKey,
        );
      },
  );
}

function getNoneColumnSymbologyState(
  loadingState: DataState<null>,
  metadataState: DataState<LayerMetadata>,
  layerId: LayerId,
  columnKey: ColumnKey,
): DataState<LayerColumnSymbology[]> {
  if (!isLoaded(loadingState)) {
    return loadingState;
  }

  const layerMetadata = getData(metadataState);
  const ufGeometryType = getUfGeometryType(layerMetadata);
  return {
    ...loadingState,
    data: createDefaultGeometrySymbology(layerId, columnKey, ufGeometryType),
  };
}

export function makeGetBuiltFormSymbologyState() {
  return createSelector(
    makeGetDefaultSymbologyLoadingState(),
    makeGetLayerMetadataState(),
    makeGetBuiltFormsStops(),
    makeGetFromPropsSelector<LayerId, 'layerId'>('layerId'),
    makeGetFromPropsSelector<ColumnKey, 'columnKey'>('columnKey'),
    (loadingState, metadataState, builtFormStops, layerId, columnKey) => {
      return getBuiltFormSymbologyState(
        loadingState,
        metadataState,
        builtFormStops,
        layerId,
        columnKey,
      );
    },
  );
}
export function makeGetBuiltFormSymbologyStateGetter() {
  return createSelector(
    makeGetDefaultSymbologyLoadingStateGetter(),
    makeGetLayerMetadataStateGetter(),
    makeGetBuiltFormsStopsGetter(),
    (getLoadingState, getMetadataState, getBuiltFormStops) =>
      (projectId: ProjectId, layerId: LayerId, columnKey: ColumnKey) => {
        const loadingState = getLoadingState(projectId, layerId, columnKey);
        const metadataState = getMetadataState(layerId);
        const builtFormStops = getBuiltFormStops(projectId, layerId);
        return getBuiltFormSymbologyState(
          loadingState,
          metadataState,
          builtFormStops,
          layerId,
          columnKey,
        );
      },
  );
}

function getBuiltFormSymbologyState(
  loadingState: DataState<null>,
  metadataState: DataState<LayerMetadata>,
  builtFormStops: BuiltFormStop[],
  layerId: LayerId,
  columnKey: ColumnKey,
): DataState<LayerColumnSymbology[]> {
  if (!isLoaded(loadingState) || !isBuiltFormKey(columnKey)) {
    return loadingState;
  }

  const layerMetadata = getData(metadataState);
  const symbologyKey = getSymbologyKey(layerMetadata, columnKey);

  const builtFormSymbology = cleanBuiltFormSymbology(
    createBuiltFormSymbology(layerId, columnKey, builtFormStops, symbologyKey),
    builtFormStops,
  );

  return {
    ...loadingState,
    data: builtFormSymbology,
  };
}
export function makeGetCategoricalSymbologyState() {
  return createSelector(
    makeGetDefaultSymbologyLoadingState(),
    makeGetLayerMetadataState(),
    makeGetSymbologyStatsState(),
    makeGetFromPropsSelector<LayerId, 'layerId'>('layerId'),
    makeGetFromPropsSelector<ColumnKey, 'columnKey'>('columnKey'),
    (loadingState, metadataState, statsState, layerId, columnKey) => {
      return getCategoricalSymbologyState(
        loadingState,
        metadataState,
        statsState,
        layerId,
        columnKey,
      );
    },
  );
}

export function makeGetCategoricalSymbologyStateGetter() {
  return createSelector(
    makeGetDefaultSymbologyLoadingStateGetter(),
    makeGetLayerMetadataStateGetter(),
    makeGetSymbologyStatsStateGetter(),
    (getLoadingState, getMetadataState, getStatsState) =>
      (projectId: ProjectId, layerId: LayerId, columnKey: ColumnKey) => {
        const loadingState = getLoadingState(projectId, layerId, columnKey);
        const metadataState = getMetadataState(layerId);
        const statsState = getStatsState(projectId, layerId, columnKey);
        return getCategoricalSymbologyState(
          loadingState,
          metadataState,
          statsState,
          layerId,
          columnKey,
        );
      },
  );
}

function getCategoricalSymbologyState(
  loadingState: DataState<null>,
  metadataState: DataState<LayerMetadata>,
  statsState: DataState<LayerStats>,
  layerId: LayerId,
  columnKey: ColumnKey,
): DataState<LayerColumnSymbology[]> {
  if (!isLoaded(loadingState)) {
    return loadingState;
  }

  const layerMetadata = getData(metadataState);
  const columnMetadata = getColumnMetadata(layerMetadata, columnKey);
  if (!isCategoricalColumn(columnMetadata)) {
    return loadingState;
  }

  const ufGeometryType = getUfGeometryType(layerMetadata);
  const layerStats = getData(statsState);

  return {
    ...loadingState,
    data: createCategoricalSymbology(
      layerId,
      columnKey,
      layerStats,
      ufGeometryType,
    ),
  };
}

export function makeGetNumericSymbologyState() {
  return createSelector(
    makeGetDefaultSymbologyLoadingState(),
    makeGetLayerMetadataState(),
    makeGetSymbologyStatsState(),
    makeGetSymbologyDefaultBreaksState(),
    makeGetFromPropsSelector<LayerId, 'layerId'>('layerId'),
    makeGetFromPropsSelector<ColumnKey, 'columnKey'>('columnKey'),
    (
      loadingState,
      metadataState,
      statsState,
      breaksState,
      layerId,
      columnKey,
    ) => {
      return getNumericSymbologyState(
        loadingState,
        metadataState,
        statsState,
        breaksState,
        layerId,
        columnKey,
      );
    },
  );
}
export function makeGetNumericSymbologyStateGetter() {
  return createSelector(
    makeGetDefaultSymbologyLoadingStateGetter(),
    makeGetLayerMetadataStateGetter(),
    makeGetSymbologyStatsStateGetter(),
    makeGetSymbologyDefaultBreaksStateGetter(),
    (getLoadingState, getMetadataState, getStatsState, getBreaksState) =>
      (projectId: ProjectId, layerId: LayerId, columnKey: ColumnKey) => {
        const loadingState = getLoadingState(projectId, layerId, columnKey);
        const metadataState = getMetadataState(layerId);
        const statsState = getStatsState(projectId, layerId, columnKey);
        const breaksState = getBreaksState(projectId, layerId, columnKey);
        return getNumericSymbologyState(
          loadingState,
          metadataState,
          statsState,
          breaksState,
          layerId,
          columnKey,
        );
      },
  );
}

function getNumericSymbologyState(
  loadingState: DataState<null>,
  metadataState: DataState<LayerMetadata>,
  statsState: DataState<LayerStats>,
  breaksState: DataState<LayerBreaks>,
  layerId: LayerId,
  columnKey: ColumnKey,
): DataState<LayerColumnSymbology[]> {
  if (!isLoaded(loadingState)) {
    return loadingState;
  }

  const layerMetadata = getData(metadataState);
  if (!isNumericColumnKey(columnKey, layerMetadata)) {
    return loadingState;
  }

  const layerStats = getData(statsState);
  const columnStats = findColumnKeyStats(layerStats, columnKey);

  const layerBreaks = getData(breaksState);
  const columnBreaks = findColumnKeyBreaks(layerBreaks, columnKey);

  const columnMetadata = getColumnMetadata(layerMetadata, columnKey);
  const ufGeometryType = getUfGeometryType(layerMetadata);

  return {
    ...loadingState,
    data: createNumericSymbology(
      layerId,
      columnKey,
      columnBreaks,
      columnStats.numeric.min,
      columnStats.numeric.max,
      ufGeometryType,
      getIsPercent(columnMetadata),
    ),
  };
}

export function cleanBuiltFormSymbology(
  symbologies: LayerColumnSymbology[],
  builtFormStops: BuiltFormStop[],
) {
  return setBuiltFormStops(symbologies, builtFormStops);
}
