import { combineReducers } from 'redux';

import { makeSetValueOnlyReducer } from 'uf/base/reducerFactories';

import {
  ENABLE_LOAD_PERSISTENCE,
  ENABLE_SAVE_PERSISTENCE,
} from './ActionTypes';
import { Persistence } from './state';

export default combineReducers<Persistence>({
  saveEnabled: makeSetValueOnlyReducer(ENABLE_SAVE_PERSISTENCE, true),
  loadEnabled: makeSetValueOnlyReducer(ENABLE_LOAD_PERSISTENCE, true),
});
