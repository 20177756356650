import {
  CuratedColumnSymbology,
  InterpolationTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/pzfpngxt/dataset/city_of_somerville_proposed_zoning': {
    zonecode: [
      {
        display_hints: {
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          scale: SymbologyScales.CATEGORICAL,
          theme: 'custom',
        },
        paint: {
          'fill-color': {
            default: '#17becf',
            property: 'zonecode',
            stops: [
              {
                color: '#51619f',
                value: 'ASQ-SD',
              },
              {
                color: '#eb4749',
                value: 'CB',
              },
              {
                color: '#f7bec0',
                value: 'CC4',
              },
              {
                color: '#f07e81',
                value: 'CC5',
              },
              {
                color: '#f07e81',
                value: 'CI',
              },
              {
                color: '#8a8a74',
                value: 'CIV',
              },
              {
                color: '#d8bfdb',
                value: 'FAB',
              },
              {
                color: '#842e83',
                value: 'HR',
              },
              {
                color: '#d88fd8',
                value: 'MR3',
              },
              {
                color: '#b988ba',
                value: 'MR4',
              },
              {
                color: '#a95da3',
                value: 'MR5',
              },
              {
                color: '#a32784',
                value: 'MR6',
              },
              {
                color: 'transparent',
                value: 'na',
              },
              {
                color: '#9093aa',
                value: 'NP-SD',
              },
              {
                color: '#fcf1a4',
                value: 'NR',
              },
              {
                color: '#9094c9',
                value: 'PH-SD',
              },
              {
                color: '#c2f2f9',
                value: 'TU-SD',
              },
              {
                color: '#f3bf61',
                value: 'UR',
              },
            ],
            type: 'categorical',
          },
          'fill-opacity': 0.9,
        },
        type: SymbologyTypes.FILL,
      },
      {
        display_hints: {
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          scale: SymbologyScales.CATEGORICAL,
          theme: 'custom',
        },
        paint: {
          'line-color': '#666666',
          'line-opacity': 0.9,
          'line-width': 0.5,
        },
        type: SymbologyTypes.LINE,
      },
    ],
  },
};

export default symbologies;
