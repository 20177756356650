import {
  CuratedColumnSymbology,
  CUSTOM_THEME,
  DEFAULT_OPACITY,
  InterpolationTypes,
  PaintColorTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/public/databank/city_of_fresno_planned_land_use_city_of_fresno_ca': {
    plu_text: [
      {
        type: SymbologyTypes.FILL,
        paint: {
          'fill-color': {
            property: 'plu_text',
            type: PaintColorTypes.CATEGORICAL,
            stops: [
              { value: 'Canal', color: '#A4CDFD' },
              { value: 'Commercial - Community', color: '#FF6209' },
              { value: 'Commercial - General', color: '#FF1304' },
              { value: 'Commercial - Highway', color: '#CC0066' },
              { value: 'Commercial - Main Street', color: '#AD80BD' },
              { value: 'Commercial - Recreation', color: '#E64B00' },
              { value: 'Commercial - Regional', color: '#BF3996' },
              { value: 'Corridor - Center Mixed Use', color: '#C448FE' },
              { value: 'Downtown Core', color: '#740C45' },
              { value: 'Downtown General', color: '#A83845' },
              { value: 'Downtown Neighborhood', color: '#B8778B' },
              { value: 'Employment - Business Park', color: '#E7BEFF' },
              { value: 'Employment - Heavy Industrial', color: '#686868' },
              { value: 'Employment - Light Industrial', color: '#9C9C9C' },
              { value: 'Employment - Office', color: '#FF96F5' },
              {
                value: 'Employment - Regional Business Park',
                color: '#E7BEFF',
              },
              { value: 'Neighborhood Mixed-Use', color: '#BD96FE' },
              { value: 'Open Space', color: '#98CB71' },
              { value: 'Open Space - Clear Zone', color: '#98CB71' },
              {
                value: 'Open Space - Commercial-Recreational',
                color: '#7DBD4C',
              },
              { value: 'Open Space - Community Park', color: '#7DBD4C' },
              { value: 'Open Space - Golf Course', color: '#98CB71' },
              { value: 'Open Space - Multi-use', color: '#98CB71' },
              { value: 'Open Space - Neighborhood Park', color: '#7DBD4C' },
              { value: 'Open Space - Park', color: '#7DBD4C' },
              { value: 'Open Space - Ponding Basin', color: '#C0D9AF' },
              {
                value: 'Open Space - Ponding Basin (Park Use)',
                color: '#98CB71',
              },
              { value: 'Open Space - Regional Park', color: '#98CB71' },
              { value: 'Open Space (Trail)', color: '#98CB71' },
              { value: 'Open Space Water', color: '#A4CDFD' },
              { value: 'Planned Road', color: '#000000' },
              { value: 'Planned Trail', color: '#98CB71' },
              { value: 'Public Facilities - Waste Water', color: '#7A7A7A' },
              { value: 'Public Facility', color: '#05C4FE' },
              { value: 'Public Facility - Airport', color: '#05C4FE' },
              {
                value: 'Public Facility - Cal State University',
                color: '#05C4FE',
              },
              { value: 'Public Facility - Cemetery', color: '#05C4FE' },
              { value: 'Public Facility - Church', color: '#05C4FE' },
              { value: 'Public Facility - College', color: '#05C4FE' },
              {
                value: 'Public Facility - Community Activity Center',
                color: '#05C4FE',
              },
              {
                value: 'Public Facility - Convalescent Hospital',
                color: '#05C4FE',
              },
              {
                value: 'Public Facility - Elementary - Middle School',
                color: '#05C4FE',
              },
              {
                value: 'Public Facility - Elementary School',
                color: '#05C4FE',
              },
              { value: 'Public Facility - Fairgrounds', color: '#05C4FE' },
              { value: 'Public Facility - Fire Station', color: '#05C4FE' },
              { value: 'Public Facility - High School', color: '#05C4FE' },
              { value: 'Public Facility - Hospital', color: '#05C4FE' },
              {
                value: 'Public Facility - Middle & High School',
                color: '#05C4FE',
              },
              { value: 'Public Facility - Middle School', color: '#05C4FE' },
              {
                value: 'Public Facility - Neighborhood Center',
                color: '#05C4FE',
              },
              { value: 'Public Facility - PG&E Substation', color: '#05C4FE' },
              {
                value: 'Public Facility - Police Department',
                color: '#05C4FE',
              },
              { value: 'Public Facility - Special School', color: '#05C4FE' },
              { value: 'Public Facility - Water Recharge', color: '#05C4FE' },
              {
                value: 'Public Facility-Elementary-Middle School-High Sch*',
                color: '#05C4FE',
              },
              { value: 'Railroad', color: '#000000' },
              { value: 'Regional Mixed-Use', color: '#8400A8' },
              { value: 'Residential - High Density', color: '#730401' },
              { value: 'Residential - Low Density', color: '#FEFFBE' },
              { value: 'Residential - Medium Density', color: '#FFD37F' },
              { value: 'Residential - Medium High Density', color: '#FEAC03' },
              { value: 'Residential - Medium Low Density', color: '#FDFF00' },
              { value: 'Residential - Urban Neighborhood', color: '#A86F02' },
              { value: 'SEGA Buffer', color: '#3AA33A' },
              { value: 'SEGA School with Park', color: '#05C4FE' },
            ],
          },
          'fill-opacity': DEFAULT_OPACITY,
        },
        display_hints: {
          scale: SymbologyScales.CATEGORICAL,
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          theme: CUSTOM_THEME,
        },
      },
    ],
  },
};

export default symbologies;
