import _ from 'lodash';
import { createSelector } from 'reselect';

import { DataState, getData, isLoaded } from 'uf/data/dataState';
import { getNativeValue, NativeValue } from 'uf/layers/formatting';
import { makeGetSearchResultsWithState } from 'uf/search/selectors';

import { makeGetLayerMetadata } from './metadata';

export function makeGetLayerDataState() {
  return createSelector(
    makeGetSearchResultsWithState(),
    makeGetLayerMetadata(),
    (
      rawSearchResults,
      layerMetadata,
    ): DataState<Record<string, NativeValue>[]> => {
      if (!isLoaded(rawSearchResults)) {
        return rawSearchResults;
      }
      const rawData = getData(rawSearchResults);
      const columns = _.keyBy(layerMetadata.columns, 'key');
      const data = rawData.map(row =>
        _.fromPairs(
          Object.entries(row).map(([key, value]) => {
            return [key, getNativeValue(value, columns[key])];
          }),
        ),
      );
      return {
        ...rawSearchResults,
        data,
      };
    },
  );
}
