import _ from 'lodash';
import { createSelector } from 'reselect';

import { makeGetApiObjectResults } from 'uf/api/selectors';
import { getActiveProjectId } from 'uf/app/selectors';
import { EMPTY_ARRAY, EMPTY_OBJECT } from 'uf/base';
import { parseFullPath } from 'uf/base/dataset';
import { makeGetFromPropsSelector } from 'uf/base/selector';
import { DataState, getData } from 'uf/data/dataState';
import { UserState } from 'uf/user/state';

import { getUserState } from './index';
import { getUserIsSuperuser } from './user';

const UF_FEATURE_FLAG_HEADER = 'UF-Feature-Flags';
export const ENABLE_BLOCK_CANVAS_FEATURE_FLAG = 'enable-block-canvas';
export const DEV_LAYER_METADATA_EXTRAS = 'dev-layer-metadata-extras';

// This should be used as a transitionary structure only, for the window during
// which a flagged feature is released, and legacy code is removed. This allows
// us to flip the default without altering any other code.
// TODO: come up with a nice way to make this work with __TESTING__
const DEFAULT_FEATURE_FLAGS: Record<string, boolean> = __TESTING__
  ? EMPTY_OBJECT
  : {
      // put 'flag-name': true, here to ship a feature-flagged feature
    };

const getUserOrganizationFeatureFlags = makeGetApiObjectResults(
  'organization',
  'get_organization_feature_flags',
);

Object.freeze(DEFAULT_FEATURE_FLAGS);

export function getDefaultFeatureFlags(): Record<string, boolean> {
  if (!process.env.UF_DEFAULT_FEATURE_FLAGS) {
    return DEFAULT_FEATURE_FLAGS;
  }

  const envFeatureFlags: Record<string, boolean> = _.fromPairs(
    process.env.UF_DEFAULT_FEATURE_FLAGS.split(',')
      .map(flag => flag.trim())
      .filter(flag => _.isString(flag) && flag.length > 0)
      .map(flag => [flag, true]),
  );

  return {
    ...DEFAULT_FEATURE_FLAGS,
    ...envFeatureFlags,
  };
}

/**
 * Get flags that the user has explicitly turned on, but special casing the 'developer' flag
 */
export const getUserSetFlags = createSelector(getUserState, (user): string[] =>
  _.keys(_.pickBy(user.flags)),
);

export const getUserFlags = createSelector(getUserState, (user): string[] =>
  _.keys(
    _.pickBy({
      ...getDefaultFeatureFlags(),
      ...user.flags,
    }),
  ),
);

export const getAvailableApiFlagsState = createSelector(
  getUserState,
  (user): DataState<string[]> => user.apiFlags,
);

export const getAvailableApiFlags = createSelector(
  getAvailableApiFlagsState,
  (apiFlagsState): string[] => {
    return getData(apiFlagsState, EMPTY_ARRAY);
  },
);

export const getUserBackendFlags = createSelector(
  getAvailableApiFlags,
  getUserFlags,
  (apiFlags, userFlags): string[] => {
    return _.intersection(apiFlags, userFlags);
  },
);

// These are registered every time a new getUserFlag selector is
// created. This means they are stored outside of redux, but there is
// kind of a chicken-and-egg problem that we can't put them into redux
// while the selectors are being created. So we just keep them here.
// Note that we should always update by replacing, not appending.
let globalFlags: string[] = [];
Object.freeze(globalFlags);

export function getGlobalFlags(): string[] {
  return globalFlags;
}

export function ensureGlobalFlag(flag: string): void {
  if (!globalFlags.includes(flag)) {
    globalFlags = [...globalFlags, flag];
    globalFlags.sort();
    Object.freeze(globalFlags);
  }
}

/**
 * This is a way to force global flags to exist at startup
 */
ensureGlobalFlag('new-filters');
ensureGlobalFlag(DEV_LAYER_METADATA_EXTRAS);

/**
 * Create a selector for a given flag.
 * The most common use of this is to put this inside connect()
 *
 * ```
 * connect(() => {
 *    const getUseMagic = makeGetUserFlag('use-magic');
 *    return state => ({
 *        useMagic: getUseMagic(state),
 *        ...,
 *    });
 * });
 * ```
 */
export function makeGetUserFlag(flagName: string) {
  ensureGlobalFlag(flagName);
  return createSelector(
    getUserFlags,
    // TODO: get orgKey from selector props!  This is a hack to get this to work for now.  Assumes
    // that anyone that has access to this project is in the org.
    getActiveProjectId,
    getUserOrganizationFeatureFlags,
    getUserIsSuperuser,
    (flags, projectId, orgFlagsState, isSuperuser): boolean => {
      let orgFlags: string[] = [];
      if (projectId) {
        const { namespace } = parseFullPath(projectId) || {};
        orgFlags = getData(orgFlagsState?.[namespace])?.items || [];
      }

      // If set by default, then user doesn't have to be a superuser or developer.
      if (
        getDefaultFeatureFlags()[flagName] ||
        __DEVELOPMENT__ ||
        __TESTING__ ||
        isSuperuser
      ) {
        return [...flags, ...orgFlags].includes(flagName);
      }

      if (orgFlags) {
        return orgFlags.includes(flagName);
      }

      return false;
    },
  );
}

// Treat this like a selector, we may use `state` at some point
export const getAvailableFlags = createSelector(
  getUserFlags,
  getGlobalFlags, // depend on this, but it will possibly change
  getAvailableApiFlags,
  (userFlags, unusedGlobalFlags, apiFlags) => {
    userFlags.forEach(flag => {
      ensureGlobalFlag(flag);
    });
    return _.union(globalFlags, apiFlags);
  },
);

export const getUserOrganizationFlagsState = createSelector(
  getUserState,
  (user: UserState) =>
    user.organizationFlags || (EMPTY_OBJECT as UserState['organizationFlags']),
);

export const getUserActiveOrganizationFlags = createSelector(
  getUserOrganizationFlagsState,
  getActiveProjectId,
  (
    organizationFlags: UserState['organizationFlags'],
    activeProjectId,
  ): string[] => {
    if (!activeProjectId) {
      return [];
    }
    const { namespace: organizationKey } = parseFullPath(activeProjectId);
    const data = getData(organizationFlags[organizationKey]);
    return data?.items || [];
  },
);

export function makeGetUserOrganizationFlagsState() {
  return createSelector(
    getUserOrganizationFlagsState,
    makeGetFromPropsSelector<string, 'organizationKey'>('organizationKey'),
    (orgFlags, orgKey) => {
      return orgFlags[orgKey];
    },
  );
}

export const getFlagHeaders = createSelector(
  getUserBackendFlags,
  getUserActiveOrganizationFlags,
  (userFlags, orgFlags) => {
    const headers: Record<string, string> = {};

    const flags = userFlags.concat(orgFlags);
    if (flags.length) {
      // eslint-disable-next-line no-param-reassign
      headers[UF_FEATURE_FLAG_HEADER] = flags.join(',');
    }

    return headers;
  },
);
