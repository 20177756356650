import { Action } from 'redux';

import { LayerColumn } from 'uf-api';
import { getLayerSuccessAction } from 'uf-api/api/layer.service';
import { BoundingBox, PolygonGeometry } from 'uf/base/geometry';
import { UFLngLatBounds } from 'uf/base/map';
import { ColumnKey, LayerId } from 'uf/layers';
import { ColumnFilterValue, FilterBuffer } from 'uf/layers/filters';
import { ProjectId } from 'uf/projects';
import { makeWebsocketActionTypes } from 'uf/websockets/actionHelpers';

import { exportLayer } from './websocketMessages';

const layerActionPrefix = 'uf/layers';

export interface GetLayerMetadataSuccessAction extends getLayerSuccessAction {
  layerId: LayerId;
}

export const SET_LAYER_VERSIONS = `${layerActionPrefix}/versions/SET_LAYER_VERSIONS`;
export const UPDATE_LAYER_VERSION = `${layerActionPrefix}/versions/UPDATE_LAYER_VERSION`;

export const exportLayerTaskActionTypes = makeWebsocketActionTypes(
  exportLayer.LAYER_EXPORT_STARTED,
  exportLayer.LAYER_EXPORT_DONE,
  exportLayer.LAYER_EXPORT_ERROR,
);

export const LAYER_DOWNLOAD_STARTED = `${layerActionPrefix}/DOWNLOAD_STARTED`;
export const BIND_EXPORT_LAYER_TASK = `${layerActionPrefix}/export/BIND_EXPORT_LAYER_TASK`;

export const LAYER_GEOMETRY_TYPE = `${layerActionPrefix}/GEOMETRY_TYPE`;
export interface UpdateColumnFilterAction<T> extends Action {
  type: T;
  projectId: ProjectId;
  layerId: LayerId;
  parentLayerId: string;
  version: string;
  columnKey: ColumnKey;
  filterValue: ColumnFilterValue;
  columnMetatype: LayerColumn.MetatypeEnum;
  columnType: LayerColumn.TypeEnum;
}
export interface ClearColumnFilterAction<T> extends Action {
  type: T;
  projectId: ProjectId;
  layerId: LayerId;
  parentLayerId: string;
  columnKeys: ColumnKey | ColumnKey[];
}
export interface AddJoinedLayerFilterAction<T> extends Action {
  type: T;
  projectId: ProjectId;
  layerId: LayerId;
  version: string;
  parentLayerId: string;
  joinMethod: string;
}
export interface UpdateBufferFilterAction<T> extends Action {
  type: T;
  projectId: ProjectId;
  layerId: LayerId;
  version: string;
  buffer: FilterBuffer;
  parentLayerId: string;
}
export interface ClearBufferFilterAction<T> extends Action {
  type: T;
  projectId: ProjectId;
  layerId: LayerId;
  parentLayerId: string;
}
export interface UpdatePointFilterAction<T> extends Action {
  type: T;
  projectId: ProjectId;
  layerId: LayerId;
  /** The geometry key of the feature being added/updated */
  pointIds: string[];
  /** geokeys that are currently selected, by other filter rules */
  currentGeoKeys: string[];
  /**
   * If true, we should update point filters rather than replacing them. This
   * means if the point is already in currentGeoKeys, it should be added as an
   * exclusion
   */
  updateOnly: boolean;
}
export interface ClearPointFilterAction<T> extends Action {
  type: T;
  projectId: ProjectId;
  layerId: LayerId;
}
export interface UpdateGeometryFilterAction<T> extends Action {
  type: T;
  projectId: ProjectId;
  layerId: LayerId;
  geometryType: 'bbox' | 'polygon';
  geometry: UFLngLatBounds | BoundingBox | PolygonGeometry;
}

export interface ClearGeometryFilters<T> extends Action {
  type: T;
  projectId: ProjectId;
  layerId: LayerId;
}
export interface ClearSelectionFiltersAction<T> extends Action {
  type: T;
  projectId: ProjectId;
  layerId: LayerId;
}

export interface ClearFiltersAction<T> extends Action {
  type: T;
  projectId: ProjectId;
  layerId: LayerId;
}

export interface EnableFiltersAction<T> extends Action {
  type: T;
  projectId: ProjectId;
  layerId: LayerId;
  enabled: boolean;
}

export interface UpdateJoinLayerMethodAction<T> extends Action {
  type: T;
  layerId: LayerId;
  projectId: ProjectId;
  version: string;
  parentLayerId: string;
  joinMethod: string;
}

export interface ClearJoinedLayerFilterAction<T> extends Action {
  type: T;
  projectId: ProjectId;
  layerId: LayerId;
  parentLayerId: string;
}
