import {
  Button,
  Checkbox,
  Classes,
  Icon,
  Menu,
  MenuItem,
  Popover,
} from '@blueprintjs/core';
import classNames from 'classnames';
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from 'react';

interface FilterableItem {
  name: string;
  value: string;
}

interface Props {
  filterableItems: FilterableItem[];
  selectedFilters: FilterableItem[];
  /**
   * Default display string for the dropdown button
   */
  defaultFilter: string;
  isOpen: boolean;
  name: string;
  onClick: (name: string) => void;
  onSelect: Dispatch<SetStateAction<FilterableItem[]>>;
}

const SearchableFilterSelect = ({
  filterableItems,
  selectedFilters,
  defaultFilter,
  isOpen,
  onClick,
  onSelect,
  name,
}: Props) => {
  const [filterSearchValue, setFilterSearchValue] = useState('');
  // Filter available items by search input
  const filterList = useMemo(
    () =>
      filterableItems.filter(item =>
        item.name.toLowerCase().includes(filterSearchValue.toLowerCase()),
      ),
    [filterSearchValue, filterableItems],
  );
  const hasSelections = useMemo(
    () => !!selectedFilters.length,
    [selectedFilters],
  );
  // Generate the text to display on the dropdown button
  const displayFilter = useMemo(() => {
    if (selectedFilters.length === 1) {
      return selectedFilters[0].name;
    }
    if (selectedFilters.length > 1) {
      return `${selectedFilters[0].name}, +${selectedFilters.length - 1}`;
    }
    return defaultFilter;
  }, [selectedFilters, defaultFilter]);
  const handleSelect = useCallback(
    e => {
      const { value } = e.target;
      const selectedItem = filterableItems.find(item => item.value === value);
      if (selectedItem) {
        onSelect(filters =>
          !filters.map(filter => filter.value).includes(value)
            ? [...filters, selectedItem]
            : filters.filter(filter => filter.value !== selectedItem.value),
        );
      }
    },
    [filterableItems, onSelect],
  );
  // Fire callback to handle opening dropdown
  const handleClick = useCallback(() => {
    onClick(name);
  }, [name, onClick]);
  const handleClose = useCallback(
    (nextOpenState, e) => {
      // Prevent menu from closing if still selecting filters
      if (
        nextOpenState === false &&
        !e?.currentTarget?.classList?.contains('bp4-popover')
      ) {
        onClick(null);
        setFilterSearchValue('');
      }
    },
    [onClick],
  );
  const handleSearch = useCallback(e => {
    const { value } = e.target;
    setFilterSearchValue(value);
  }, []);
  return (
    <Popover
      isOpen={isOpen}
      minimal
      placement="bottom-start"
      onInteraction={handleClose}
      content={
        <Menu>
          <div className={Classes.INPUT_GROUP}>
            <Icon iconSize={11} icon="search" />
            <input
              type="text"
              className={classNames(Classes.INPUT, Classes.SMALL, Classes.FILL)}
              value={filterSearchValue}
              placeholder="Filter..."
              onChange={handleSearch}
            />
          </div>
          <div
            style={{
              maxHeight: 200,
              overflow: 'scroll',
            }}>
            {filterList.map(item => {
              const { name: itemName, value } = item;
              const isActive = selectedFilters.some(
                filter => filter.value === value,
              );
              return (
                <MenuItem
                  style={{
                    color: isActive ? '' : 'var(--color-disabled-text)',
                  }}
                  key={value}
                  icon={
                    <Checkbox
                      style={{ margin: 0 }}
                      onChange={handleSelect}
                      value={value}
                      inline
                      large={false}
                      label={itemName}
                      checked={isActive}
                    />
                  }
                />
              );
            })}
          </div>
        </Menu>
      }>
      <Button
        onClick={handleClick}
        minimal
        style={{
          backgroundColor: hasSelections ? '#235CB7' : '#F5F8FA',
          color: hasSelections ? '#FFF' : '',
        }}
        rightIcon={
          <Icon
            icon="caret-down"
            style={{ color: hasSelections ? '#FFF' : '' }}
          />
        }>
        Search within <strong>{displayFilter}</strong>
      </Button>
    </Popover>
  );
};

export default SearchableFilterSelect;
