import { parseFullPath } from 'uf/base/dataset';
import { ColumnKey, LayerId } from 'uf/layers';
import { LayerColumnSymbology } from 'uf/symbology';
import { DEFAULT_VIEW_ID, ViewId } from 'uf/views';
import { DivideByColumnKey } from './divideByColumn';

/**
 * A layer column's complete symbology, including FILL, STROKE, CIRCLE
 * @deprecated import from uf/symbology instead
 */
export interface LayerSymbologyEnvelope {
  format: '1.0.0';
  type: 'symbology';
  symbology: LayerColumnSymbology[];
}

export function makeSymbologyUserDataPrefixForLayer(layerId: LayerId) {
  return layerId;
}

export function makeSymbologyUserDataKey({
  layerId,
  columnKey,
  divideByColumn = false,
  viewId = DEFAULT_VIEW_ID,
}: {
  layerId: LayerId;
  columnKey: ColumnKey;
  divideByColumn?: DivideByColumnKey;
  viewId?: ViewId;
}) {
  const parts: string[] = [
    layerId,
    columnKey,
    viewId,
    divideByColumn as string,
  ].filter(x => !!x);
  return parts.join('|');
}

/**
 * Helper for clearing all user symbologies via the wrench menu. This is for
 * developers only.
 *
 * DO NOT USE THIS IN FEATURE CODE.
 * DO NOT USE THIS IN FEATURE CODE.
 * DO NOT USE THIS IN FEATURE CODE.
 */
export function looksLikeSymbologyKey(key: string) {
  const keyParts = key.split('|');
  return !!(
    keyParts.length >= 2 &&
    keyParts.length <= 4 &&
    parseFullPath(keyParts[0])
  );
}
