/**
 * UrbanFootprint App API
 * API for serving UrbanFootprint Application
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * How to allocate residential values to canvas rows.      Numerical values represent either absolute units or per acre densities     depending on allocation_mode.
 */
export interface BuiltFormPainterResidentialAllocation<> {
  /**
   * How to allocate numerical values.
   */
  allocation_mode?: BuiltFormPainterResidentialAllocation.AllocationModeEnum;
  /**
   * Amount of Attached Single-Family Area.
   */
  bldg_area_attsf?: number;
  /**
   * Amount of Detached Single Family Large-Lot Area.
   */
  bldg_area_detsf_ll?: number;
  /**
   * Amount of Detached Single Family Small-Lot Area.
   */
  bldg_area_detsf_sl?: number;
  /**
   * Amount of Multifamily Area.
   */
  bldg_area_mf?: number;
  /**
   * Amount of Attached Single-Family Dwelling Units.
   */
  du_attsf?: number;
  /**
   * Amount of Detached Single Family Large-Lot Dwelling Units.
   */
  du_detsf_ll?: number;
  /**
   * Amount of Detached Single Family Small-Lot Dwelling Units.
   */
  du_detsf_sl?: number;
  /**
   * Amount of Multifamily Dwelling Units.
   */
  du_mf?: number;
}
export namespace BuiltFormPainterResidentialAllocation {
  export type AllocationModeEnum = 'per_feature' | 'per_acre';
  export const AllocationModeEnum = {
    Feature: 'per_feature',
    Acre: 'per_acre',
  } as const;
}
