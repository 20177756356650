import {
  CuratedColumnSymbology,
  CUSTOM_THEME,
  DEFAULT_OPACITY,
  DistributionTypes,
  InterpolationTypes,
  PaintColorTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/public/dataset/average_electricity_price_by_state_2015': {
    average_electricity_price_residential: [
      {
        type: SymbologyTypes.FILL,
        paint: {
          'fill-color': {
            property: 'average_electricity_price_residential',
            type: PaintColorTypes.INTERVAL,
            stops: [
              { value: 0, label: '0.00 to 0.04', color: '#fef2c0' },
              { value: 0.04, label: '0.04 to 0.08', color: '#fdd2a0' },
              { value: 0.08, label: '0.08 to 0.12', color: '#fac38e' },
              { value: 0.12, label: '0.12 to 0.16', color: '#f8b57d' },
              { value: 0.16, label: '0.16 to 0.20', color: '#f5a76b' },
              { value: 0.2, label: '0.20 to 0.24', color: '#f29859' },
              { value: 0.24, label: '0.24 to 0.28', color: '#f08a47' },
              { value: 0.28, label: '0.28 or more', color: '#ed7c35' },
            ],
          },
          'fill-opacity': DEFAULT_OPACITY,
        },
        display_hints: {
          scale: SymbologyScales.NUMERIC,
          distribution: DistributionTypes.DEFAULT,
          interpolation: InterpolationTypes.INTERVAL,
          theme: CUSTOM_THEME,
        },
      },
    ],
  },
};

export default symbologies;
