/**
 * UrbanFootprint App API
 * API for serving UrbanFootprint Application
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Basic info about member of an organization.
 */
export interface MemberListItem<> {
  /**
   * The email of the member
   */
  email?: string;
  /**
   * The first name of the member.
   */
  first_name?: string;
  /**
   * Opaque string representing member, which will never change, even if email changes.
   */
  key: string;
  /**
   * The last name of the member.
   */
  last_name?: string;
  /**
   * The role of the member.
   */
  role?: MemberListItem.RoleEnum;
}
export namespace MemberListItem {
  export type RoleEnum =
    | 'collaborator'
    | 'viewer'
    | 'editor'
    | 'admin'
    | 'owner'
    | 'public';
  export const RoleEnum = {
    Collaborator: 'collaborator',
    Viewer: 'viewer',
    Editor: 'editor',
    Admin: 'admin',
    Owner: 'owner',
    Public: 'public',
  } as const;
}
