import { createSelector } from 'reselect';

import { makeGetFromPropsSelector } from 'uf/base/selector';
import { DataState, getData, isLoaded } from 'uf/data/dataState';
import { ColumnKey, LayerId } from 'uf/layers';
import { ProjectId } from 'uf/projects';
import { LayerColumnSymbology } from 'uf/symbology';
import {
  makeGetCuratedSymbologyFromAnalysisSymbologiesFile,
  makeGetCuratedSymbologyFromAnalysisSymbologiesFileGetter,
} from 'uf/symbology/selectors/curated/analysisSymbologies';
import {
  makeGetCuratedSymbologyFromKeySymbologiesFile,
  makeGetCuratedSymbologyFromKeySymbologiesFileGetter,
} from 'uf/symbology/selectors/curated/keySymbologies';
import {
  makeGetCuratedSymbologyFromLayerSymbologiesFile,
  makeGetCuratedSymbologyFromLayerSymbologiesFileGetter,
} from 'uf/symbology/selectors/curated/layerSymbologies';
import {
  makeGetCuratedSymbologyLoadingState,
  makeGetCuratedSymbologyLoadingStateGetter,
} from 'uf/symbology/selectors/curated/loadingState';

export function makeGetCuratedSymbologyState() {
  return createSelector(
    makeGetCuratedSymbologyLoadingState(),
    makeGetCuratedSymbologyFromLayerSymbologiesFile(),
    makeGetCuratedSymbologyFromKeySymbologiesFile(),
    makeGetCuratedSymbologyFromAnalysisSymbologiesFile(),
    makeGetFromPropsSelector<LayerId, 'layerId'>('layerId'),
    makeGetFromPropsSelector<ColumnKey, 'columnKey'>('columnKey'),
    (loadingState, layerSymbologies, keySymbologies, analysisSymbologies) => {
      return getCuratedSymbologyState(
        loadingState,
        layerSymbologies,
        keySymbologies,
        analysisSymbologies,
      );
    },
  );
}

export function makeGetCuratedSymbologyStateGetter() {
  return createSelector(
    makeGetCuratedSymbologyLoadingStateGetter(),
    makeGetCuratedSymbologyFromLayerSymbologiesFileGetter(),
    makeGetCuratedSymbologyFromKeySymbologiesFileGetter(),
    makeGetCuratedSymbologyFromAnalysisSymbologiesFileGetter(),
    (
        getLoadingState,
        getLayerSymbologies,
        getKeySymbologies,
        getAnalysisSymbologies,
      ) =>
      (projectId: ProjectId, layerId: LayerId, columnKey: ColumnKey) => {
        const loadingState = getLoadingState(projectId, layerId, columnKey);
        const layerSymbologies = getLayerSymbologies(
          projectId,
          layerId,
          columnKey,
        );
        const keySymbologies = getKeySymbologies(projectId, layerId, columnKey);
        const analysisSymbologies = getAnalysisSymbologies(
          projectId,
          layerId,
          columnKey,
        );
        return getCuratedSymbologyState(
          loadingState,
          layerSymbologies,
          keySymbologies,
          analysisSymbologies,
        );
      },
  );
}

function getCuratedSymbologyState(
  loadingState: DataState<null>,
  layerSymbologies: DataState<LayerColumnSymbology[]>,
  keySymbologies: DataState<LayerColumnSymbology[]>,
  analysisSymbologies: DataState<LayerColumnSymbology[]>,
): DataState<LayerColumnSymbology[]> {
  if (!isLoaded(loadingState)) {
    return loadingState;
  }

  // Look through the symbologies in order and take the first one that's loaded w/ data.
  const curatedSymbology = [
    layerSymbologies,
    keySymbologies,
    analysisSymbologies,
  ].find(symbologyState => getData(symbologyState, null));

  if (curatedSymbology) {
    return curatedSymbology;
  }
  return loadingState;
}
