/* eslint-disable @typescript-eslint/naming-convention */
import { LayerColumn } from 'uf-api';
import { BoundingBox, PolygonGeometry } from 'uf/base/geometry';
import { UFLngLatBounds } from 'uf/base/map';
import { ColumnKey, LayerId } from 'uf/layers';
import * as ActionTypes from 'uf/layers/ActionTypes';
import { ColumnFilterValue, FilterBuffer } from 'uf/layers/filters';
import { ProjectId } from 'uf/projects';

export function makeFilterActions<
  ADD_JOINED_LAYER_FILTER_TYPE,
  CLEAR_BUFFER_FILTER_TYPE,
  CLEAR_COLUMN_FILTERS_TYPE,
  CLEAR_FILTERS_TYPE,
  CLEAR_GEOMETRY_FILTERS_TYPE,
  CLEAR_POINT_FILTER_TYPE,
  CLEAR_SELECTION_FILTERS_TYPE,
  ENABLE_FILTERS_TYPE,
  UPDATE_BUFFER_FILTER_TYPE,
  UPDATE_COLUMN_FILTER_TYPE,
  UPDATE_GEOMETRY_FILTERS_TYPE,
  UPDATE_POINT_FILTER_TYPE,
  UPDATE_JOIN_LAYER_METHOD_TYPE,
  CLEAR_JOINED_LAYER_FILTER_TYPE,
>(
  ADD_JOINED_LAYER_FILTER: ADD_JOINED_LAYER_FILTER_TYPE,
  CLEAR_BUFFER_FILTER: CLEAR_BUFFER_FILTER_TYPE,
  CLEAR_COLUMN_FILTERS: CLEAR_COLUMN_FILTERS_TYPE,
  CLEAR_FILTERS: CLEAR_FILTERS_TYPE,
  CLEAR_GEOMETRY_FILTERS: CLEAR_GEOMETRY_FILTERS_TYPE,
  CLEAR_POINT_FILTER: CLEAR_POINT_FILTER_TYPE,
  CLEAR_SELECTION_FILTERS: CLEAR_SELECTION_FILTERS_TYPE,
  ENABLE_FILTERS: ENABLE_FILTERS_TYPE,
  UPDATE_BUFFER_FILTER: UPDATE_BUFFER_FILTER_TYPE,
  UPDATE_COLUMN_FILTER: UPDATE_COLUMN_FILTER_TYPE,
  UPDATE_GEOMETRY_FILTERS: UPDATE_GEOMETRY_FILTERS_TYPE,
  UPDATE_POINT_FILTER: UPDATE_POINT_FILTER_TYPE,
  UPDATE_JOIN_LAYER_METHOD: UPDATE_JOIN_LAYER_METHOD_TYPE,
  CLEAR_JOINED_LAYER_FILTER: CLEAR_JOINED_LAYER_FILTER_TYPE,
) {
  return {
    addJoinedLayerFilter: makeAddJoinedLayerFilter(ADD_JOINED_LAYER_FILTER),
    clearBufferFilter: makeClearBufferFilter(CLEAR_BUFFER_FILTER),
    clearColumnFilters: makeClearColumnFilters(CLEAR_COLUMN_FILTERS),
    clearFilters: makeClearFilters(CLEAR_FILTERS),
    clearGeometryFilters: makeClearGeometryFilters(CLEAR_GEOMETRY_FILTERS),
    clearPointFilter: makeClearPointFilter(CLEAR_POINT_FILTER),
    clearSelectionFilters: makeClearSelectionFilters(CLEAR_SELECTION_FILTERS),
    enableFilters: makeEnableFilters(ENABLE_FILTERS),
    updateBufferFilter: makeUpdateBufferFilter(UPDATE_BUFFER_FILTER),
    updateColumnFilter: makeUpdateColumnFilter(UPDATE_COLUMN_FILTER),
    updateGeometryFilter: makeUpdateGeometryFilters(UPDATE_GEOMETRY_FILTERS),
    updatePointFilter: makeUpdatePointFilter(UPDATE_POINT_FILTER),
    updateJoinLayerMethod: makeUpdateJoinLayerMethod(UPDATE_JOIN_LAYER_METHOD),
    clearJoinedLayerFilter: makeClearJoinedLayerFilter(
      CLEAR_JOINED_LAYER_FILTER,
    ),
  };
}
/**
 * Create an update action creator
 */
export function makeUpdateColumnFilter<T>(type: T) {
  /**
   * @param projectId A full project path like /public/project/xyz
   * @param layerId A full layer path like /public/dataset/census_blocks
   * @param columnKey The column for which the filter is being applied.
   * @param columnMetatype The column's data type. e.g. numeric or categorical
   * @param filterValue An object that depends on propertySchema. e.g. for numeric
   *   filters, includes min/max values.
   * @param parentLayerId  Used to indicate that this column filter is a child (geo-join) of a
   *   primary layer.
   * @param version The latest version for that layer.
   * @param columnType The column's data type
   */
  return (
    projectId: ProjectId,
    layerId: LayerId,
    columnKey: ColumnKey,
    columnMetatype: LayerColumn.MetatypeEnum,
    filterValue: ColumnFilterValue,
    parentLayerId?: string,
    version?: string,
    columnType: LayerColumn.TypeEnum = LayerColumn.TypeEnum.String,
  ): ActionTypes.UpdateColumnFilterAction<T> => {
    return {
      type,
      projectId,
      layerId,
      columnKey,
      columnMetatype,
      filterValue,
      parentLayerId,
      version,
      columnType,
    };
  };
}

/**
 * Create a clear action creator
 *
 * The action clears the filters for the given columns for a given layer
 */
export function makeClearColumnFilters<T>(type: T) {
  /**
   * @param projectId A full project path like /public/project/xyz
   * @param layerId String. A full layer path like /public/dataset/census_blocks
   * @param columnKeys Array or string. The column(s) for which the filter is being applied.
   *   If no value is provided then all of the column filters will be cleared.
   * @param parentLayerId  Used to indicate that this column filter is a child (geo-join) of a
   *   primary layer.
   */
  return (
    projectId: ProjectId,
    layerId: LayerId,
    columnKeys?: ColumnKey | ColumnKey[],
    parentLayerId?: string,
  ): ActionTypes.ClearColumnFilterAction<T> => {
    return {
      type,
      projectId,
      layerId,
      columnKeys,
      parentLayerId,
    };
  };
}

export function makeAddJoinedLayerFilter<T>(type: T) {
  return (
    projectId: ProjectId,
    layerId: LayerId,
    version: string,
    parentLayerId: string,
    joinMethod: string,
  ): ActionTypes.AddJoinedLayerFilterAction<T> => {
    return {
      type,
      projectId,
      layerId,
      version,
      parentLayerId,
      joinMethod,
    };
  };
}

export function makeUpdateJoinLayerMethod<T>(type: T) {
  return (
    projectId: ProjectId,
    layerId: LayerId,
    parentLayerId: string,
    version: string,
    joinMethod: string,
  ): ActionTypes.UpdateJoinLayerMethodAction<T> => {
    return {
      type,
      projectId,
      layerId,
      version,
      parentLayerId,
      joinMethod,
    };
  };
}

export function makeClearJoinedLayerFilter<T>(type: T) {
  return (
    projectId: ProjectId,
    layerId: LayerId,
    parentLayerId: string,
  ): ActionTypes.ClearJoinedLayerFilterAction<T> => {
    return {
      type,
      projectId,
      layerId,
      parentLayerId,
    };
  };
}

export function makeUpdateBufferFilter<T>(type: T) {
  return (
    projectId: ProjectId,
    layerId: LayerId,
    version: string,
    buffer: FilterBuffer,
    parentLayerId: string,
  ): ActionTypes.UpdateBufferFilterAction<T> => {
    return {
      type,
      projectId,
      layerId,
      version,
      buffer,
      parentLayerId,
    };
  };
}

export function makeClearBufferFilter<T>(type: T) {
  return (
    projectId: ProjectId,
    layerId: LayerId,
    parentLayerId?: string,
  ): ActionTypes.ClearBufferFilterAction<T> => {
    return {
      type,
      projectId,
      layerId,
      parentLayerId,
    };
  };
}

/**
 * Update the point filter
 *
 */
export function makeUpdatePointFilter<T>(type: T) {
  /**
   * @param projectId A full project path like /public/project/xyz
   * @param layerId A full layer path like /public/dataset/census_blocks
   * @param pointIds list of geometry_keys to add/update
   * @param currentGeoKeys The currently selected poitns
   * @param updateOnly flag to indicate if we should be adding or removing points
   *   from the current selection. if `true`, pointFilter will add the point to the
   *   filter if it is not selected, and removed if it is. If `false` all the points
   *   will be cleared and the filter will contain only the new point
   */
  return (
    projectId: ProjectId,
    layerId: LayerId,
    pointIds: string[],
    currentGeoKeys: string[],
    updateOnly?: boolean,
  ): ActionTypes.UpdatePointFilterAction<T> => {
    return {
      type,
      projectId,
      layerId,
      pointIds,
      currentGeoKeys,
      updateOnly,
    };
  };
}

/**
 * Make a clear point action creator
 *
 * Action clears a specific point from the filters
 */
export function makeClearPointFilter<T>(type: T) {
  /**
   * @param projectId A full project path like /public/project/xyz
   * @param layerId A full layer path like /public/dataset/census_blocks
   */
  return (
    projectId: ProjectId,
    layerId: LayerId,
  ): ActionTypes.ClearPointFilterAction<T> => {
    return {
      type,
      projectId,
      layerId,
    };
  };
}

interface UpdateGeometryFilterActionCreator<T> {
  (
    projectId: ProjectId,
    layerId: LayerId,
    geometryType: 'polygon',
    geometry: PolygonGeometry,
  ): ActionTypes.UpdateGeometryFilterAction<T>;
  (
    projectId: ProjectId,
    layerId: LayerId,
    geometryType: 'bbox',
    geometry: UFLngLatBounds | BoundingBox,
  ): ActionTypes.UpdateGeometryFilterAction<T>;
}

/**
 * Make an geometry update action creator
 *
 * Action updates the given geometry
 */
export function makeUpdateGeometryFilters<T>(
  type: T,
): UpdateGeometryFilterActionCreator<T> {
  /**
   * @param projectId A full project path like /public/project/xyz
   * @param layerId A full layer path like /public/dataset/census_blocks
   * @param geometryType Currently only 'bbox' types exist
   * @param geometry an object containing coordinates
   */
  return (
    projectId: ProjectId,
    layerId: LayerId,
    geometryType: 'bbox' | 'polygon',
    geometry: ActionTypes.UpdateGeometryFilterAction<T>['geometry'],
  ): ActionTypes.UpdateGeometryFilterAction<T> => {
    return {
      type,
      projectId,
      layerId,
      geometryType,
      geometry,
    };
  };
}

/**
 * Create a clear geometry action
 *
 * Action clears all geometries
 */
export function makeClearGeometryFilters<T>(type: T) {
  /**
   * @param projectId A full project path like /public/project/xyz
   * @param layerId A full layer path like /public/dataset/census_blocks
   */
  return (
    projectId: ProjectId,
    layerId: LayerId,
  ): ActionTypes.ClearGeometryFilters<T> => {
    return {
      type,
      projectId,
      layerId,
    };
  };
}

/**
 * This function will clear all the selection style filters i.e.
 * geometries, points (non facet type)
 */
export function makeClearSelectionFilters<T>(type: T) {
  /**
   * @param projectId A full project path like /public/project/xyz
   * @param layerId A full layer path like /public/dataset/census_blocks
   */
  return (
    projectId: ProjectId,
    layerId: LayerId,
  ): ActionTypes.ClearSelectionFiltersAction<T> => {
    return {
      type,
      projectId,
      layerId,
    };
  };
}

/**
 * Clear all active filters for a layer
 *
 */
export function makeClearFilters<T>(type: T) {
  /**
   * @param projectId A full project path like /public/project/xyz
   * @param layerId A full layer path like /public/dataset/census_blocks
   */
  return (
    projectId: ProjectId,
    layerId: LayerId,
  ): ActionTypes.ClearFiltersAction<T> => {
    return {
      type,
      projectId,
      layerId,
    };
  };
}

/**
 * Enable/disable filters for a layer, without clearing them
 */
export function makeEnableFilters<T>(type: T) {
  return (
    projectId: ProjectId,
    layerId: LayerId,
    enabled: boolean,
  ): ActionTypes.EnableFiltersAction<T> => {
    return {
      type,
      projectId,
      layerId,
      enabled,
    };
  };
}
