import _ from 'lodash';
import { Action, AnyAction, Reducer } from 'redux';
import warning from 'warning';

import { EMPTY_ARRAY, EMPTY_OBJECT } from 'uf/base';
import { KeyedListActionTypes } from 'uf/base/keyedListActionHelpers';
import { getSafeKey } from 'uf/base/keys';
import {
  AppendItemAction,
  ListAction,
  ListActionTypes,
  RemoveItemAction,
  SetListAction,
  UpdateItemAction,
  UpsertItemAction,
  UpsertItemsAction,
} from 'uf/base/listActionHelpers';

import { cacheableConcat } from './array';

export function withClearReducer<A extends Action>(
  clearActionType: string,
  reducer: Reducer,
) {
  return function clearReducer(state = EMPTY_OBJECT, action: A) {
    // avoid unnecessary state changes
    if (state && action.type === clearActionType) {
      return EMPTY_OBJECT;
    }

    return reducer(state, action);
  };
}

type KeyedAction<K extends string> = AnyAction & Record<K, any>;

/**
 * Takes a standard reducer and returns a reducer that can direct actions to keyed sub reducers.
 *
 * @param reducer The reducer to change into a keyed reducer.
 * @param key The property on the action to key the sub reducers by.
 * @param clearAllActionType The action type that will set the state as an EMPTY_OBJECT
 * @return The keyed reducer.
 */
export function makeKeyedReducer<S, K extends string, A extends KeyedAction<K>>(
  reducer: Reducer<S>,
  key: K,
  clearAllActionType?: string,
): Reducer<Record<K, S>> {
  return (state = EMPTY_OBJECT as Record<string, S>, action: A) => {
    if (action.type === clearAllActionType) {
      return EMPTY_OBJECT;
    }
    if (key in action) {
      const stateKey = getSafeKey(action[key]) as K;
      const newValue = reducer(state[stateKey], action);
      // avoid unnecessary state changes
      if (state[stateKey] !== newValue) {
        return {
          ...(state as any),
          [getSafeKey(action[key])]: newValue,
        };
      }
    }

    return state;
  };
}

export function makeFunctionKeyedReducer<
  S,
  K extends string,
  A extends KeyedAction<K>,
>(
  reducer: Reducer<S>,
  getKey: (action: A) => string,
  clearAllActionType?: string,
): Reducer<Record<string, S>> {
  return (state = EMPTY_OBJECT as Record<string, S>, action: A) => {
    if (action.type === clearAllActionType) {
      return EMPTY_OBJECT;
    }
    const stateKey = getKey(action);
    if (stateKey !== undefined) {
      const newValue = reducer(state[stateKey], action);
      // avoid unnecessary state changes
      if (state[stateKey] !== newValue) {
        return {
          ...(state as any),
          [stateKey]: newValue,
        };
      }
    }

    return state;
  };
}

export function makeFlagReducer<T = boolean>(
  onValue: T = true as any,
  offValue: T = false as any,
  onActionTypes: T[] = [],
  offActionTypes: T[] = [],
  defaultValue: T = onValue,
) {
  const common = _.intersection(onActionTypes, offActionTypes);
  if (common.length > 0) {
    throw new Error(
      `Invalid FlagReducer: Duplicate actions found: ${JSON.stringify(common)}`,
    );
  }

  const defaultState = defaultValue || onValue;

  return (state = defaultState, action: Action = { type: undefined }) => {
    if (!action.type) {
      return state;
    }

    if (onActionTypes.includes(action.type)) {
      return onValue;
    }

    if (action.type && offActionTypes.includes(action.type)) {
      return offValue;
    }

    return state;
  };
}

export interface SetValueAction<V, E = never> extends Action {
  value: V;
  extra?: E;
}

export interface PropertyAction<V, E = never> extends SetValueAction<V, E> {
  property: string;
}

/**
 * Creates a reducer that sets the state to exactly `action.value`
 *
 * @param actionType The action type for the reducer
 * @param defaultState The default value if unset
 */
export function makeSetValueOnlyReducer<
  V,
  A extends SetValueAction<V> = SetValueAction<V>,
>(actionType: A['type'], defaultState: V = null): Reducer<V> {
  return (state = defaultState, action: A) => {
    if (actionType === action.type) {
      return action.value;
    }
    return state;
  };
}
/**
 * Creates a reducer that just sets name/values on a JavaScript object. This is
 * very similar to makeSetValueOnlyReducer, except that the state is an object
 * with properties, and the property itself is specified in `action.property`.
 *
 * This is most useful when the properties you are keeping track of are dynamic.
 *
 * Hook up with `combineReducers`:
 * ```
 *   combineReducers({
 *     debug: makeSetValueReducer(SET_DEBUG_VALUE),
 *     ...
 *   });
 * ```
 * And later:
 * ```
 *   function setUrlValue(property, value) {
 *     return {
 *       type: SET_DEBUG_VALUE,
 *       property,
 *       value,
 *     };
 *   }
 * ```
 *
 * Dispatch:
 * ```
 *   Object.keys(url.query).forEach(key => {
 *      dispatch(setUrlValue(key, url.query[key]));
 *   });
 * ```
 *
 * If url.query was { showMapLoader: true, visitCount: 3 }
 *
 * ```
 *   {
 *     showMapLoader: true,
 *     visitCount: 3,
 *   }
 * ```
 */
export function makeSetValueReducer<
  V,
  A extends PropertyAction<V> = PropertyAction<V>,
>(
  actionType: string,
  defaultState: Record<string, V> = EMPTY_OBJECT,
): Reducer<Record<string, V>> {
  return makeSetKeyedValueReducer<V, A>(
    actionType,
    ['property'],
    ['value'],
    defaultState,
  );
}

/**
 * Creates a keyed reducer that lets you specify properties in the action to
 * use for both the key and the value. For example, if you have an action:
 *
 * {
 *   type: 'UPDATE_DOG',
 *   name: 'Fido',
 *   tagId: 'xyz',
 * }
 *
 * you could create a keyed reducer that would create state like:
 *
 *   dogsById: {
 *     'xyz': 'Fido',
 *   }
 *
 * if you chose ['tagId'] for the keyProp and ['name'] for the valueProp.
 *
 * @param actionType the action type that the reducer responds to.
 * @param keyPropPath location of property to use as the key.  i.e. ['some',
 * 'nested', 'prop']
 * @param valuePropPath location of property to use as the value
 * @param defaultState default state to pass to the reducer
 */
export function makeSetKeyedValueReducer<
  V,
  A extends AnyAction,
  KT extends string = string,
>(
  actionType: string,
  keyPropPath: string[],
  valuePropPath: string[],
  defaultState: Record<string, V> = EMPTY_OBJECT,
) {
  return (state = defaultState, action: A) => {
    const key: KT = getSafeKey(_.get(action, keyPropPath));
    const value = _.get(action, valuePropPath);
    const oldValue = state[key];
    if (actionType === action.type && oldValue !== value) {
      return {
        ...state,
        [key]: value,
      };
    }
    return state;
  };
}

/**
 * Creates a keyed reducer that can update multiple keyed values from an action.  The length of the
 * key array and the value array must be equal.
 *
 * @param actionType the action type that the reducer responds to.
 * @param keysPropPath  location of the property to use as the keys.  i.e. ['some', 'nested', 'prop']
 * @param valuesPropPath location of the property to use as the values
 * @param defaultState default state to initialize the reducer
 */
export function makeSetManyKeyedValuesReducer<
  V,
  A extends AnyAction = AnyAction,
>(
  actionType: string,
  keysPropPath: string[],
  valuesPropPath: string[],
  defaultState: Record<string, V> = EMPTY_OBJECT,
) {
  return (state = defaultState, action: A) => {
    if (actionType !== action.type) {
      return state;
    }

    const keys: string[] = _.get(action, keysPropPath);
    const values: V[] = _.get(action, valuesPropPath);

    if (keys.length !== values.length) {
      warning(
        false,
        'Error in makeSetManyKeyedValuesReducer: keys and values have different lengths',
      );
      return state;
    }

    const newValues = {};
    _.zip(keys, values).forEach(([key, value]) => {
      const oldValue = state[key];
      if (oldValue !== value) {
        newValues[key] = value;
      }
    });

    if (_.isEmpty(newValues)) {
      return state;
    }

    return {
      ...state,
      ...newValues,
    };
  };
}

/**
 * makes a list reducer.
 *
 * @param actionTypes a set of ListAction's that the reducer listens for
 * @param getItemKey a function to key items in the list.  defaults to returning the item itself.
 *
 * Actions:
 *   SET: sets the keyed value to the list provided in the action
 *   UPDATE: updates an item in the list.  If no item is found, it is appended at the end.
 *   APPEND: append an item to the end of the list.
 *   REMOVE: remove an item from the list if it exists.
 */
export function makeListReducer<T, K = T>(
  actionTypes: ListActionTypes,
  getItemKey: (v: T) => K = (v: T) => v as unknown as K,
): Reducer<T[]> {
  return function reducer(state: T[] = EMPTY_ARRAY, action: ListAction<T>) {
    switch (action.type) {
      case actionTypes.SET: {
        return (action as SetListAction<T>).list || EMPTY_ARRAY;
      }

      case actionTypes.UPDATE: {
        const previousList = state;
        const itemKey = getItemKey((action as UpdateItemAction<T>).item);
        const itemIndex = previousList.findIndex(
          item => itemKey === getItemKey(item),
        );

        // if we find the item in the list, update it
        if (itemIndex > -1) {
          // Just in case, we'll update all instances of that item.
          const newList = previousList.map(previousItem => {
            const newItem =
              'newItem' in action
                ? action.newItem
                : (action as UpdateItemAction<T>).item;
            if (getItemKey(previousItem) === itemKey) {
              return newItem;
            }
            return previousItem;
          });
          return newList;
        }

        return state;
      }

      case actionTypes.UPSERT: {
        const upsertAction = action as UpsertItemAction<T>;
        const { newItem, item } = upsertAction;
        const previousList = state;
        const itemKey = getItemKey(upsertAction.item);
        const itemIndex = previousList.findIndex(
          x => itemKey === getItemKey(x),
        );
        const upsertItem = 'newItem' in upsertAction ? newItem : item;

        // if we find the item in the list, update it
        if (itemIndex > -1) {
          const newList = [...previousList];
          newList[itemIndex] = upsertItem;
          return newList;
        }

        // if we didn't find the item in the list, we append it to the list.
        if (itemIndex === -1) {
          return [...previousList, upsertItem];
        }
        return state;
      }

      case actionTypes.UPSERT_ITEMS: {
        const upsertItemsAction = action as UpsertItemsAction<T>;
        const { items } = upsertItemsAction;
        // New items that were used as replacements in the existing array
        const replacedItems: Set<T> = new Set<T>();

        // calculate all the keys once, a kind of Decorate-Find-Undecorate
        // pattern. If keys were guaranteed to be strings, this could be a map
        // instead.
        const newItemKeyPairs: [K, T][] = items.map(item => [
          getItemKey(item),
          item,
        ]);

        // First, replace matching items in place
        const newState = state.map(currentItem => {
          const currentItemKey = getItemKey(currentItem);

          const match = newItemKeyPairs.find(([key]) => key === currentItemKey);
          if (!match) {
            return currentItem;
          }
          const [, newItem] = match;
          replacedItems.add(newItem);
          return newItem;
        });

        // Now take any that were not added
        const toAdd = items.filter(item => !replacedItems.has(item));
        return cacheableConcat(newState, toAdd);
      }

      case actionTypes.APPEND: {
        const previousList = state;
        // items can either be a list of items or a string, make sure it's a list so we can spread. i.e.:
        // list: _.flatten([[item1, item2, item3]]) => [item1, item2, item3]
        // string: _.flatten(['newValue']) =>  ['newValue']
        // both of these results can get spread into the new list
        const items = _.flatten([(action as AppendItemAction<T>).items]);
        const newList = [...previousList, ...items];
        return newList;
      }

      case actionTypes.REMOVE: {
        const previousList = state;
        const items = _.flattenDeep([(action as RemoveItemAction<T>).items]);
        const itemKeys = items.map(item => getItemKey(item));
        const newList = previousList.filter(
          item => !itemKeys.includes(getItemKey(item)),
        );
        if (newList.length === previousList.length) {
          return state;
        }
        return newList;
      }

      default:
        return state;
    }
  };
}

/**
 * makes a keyed reducer where every entry has a list.
 *
 * @param actionTypes a set of ListAction's that the reducer listens for
 * @param listKey the property in the action to key the lists by
 * @param getItemKey a function to key items in the list.  defaults to returning the item itself.
 *
 * Actions:
 *   SET: sets the keyed value to the list provided in the action
 *   UPDATE: updates an item in the list.  If no item is found, it is appended at the end.
 *   APPEND: append an item to the end of the list.
 *   REMOVE: remove an item from the list if it exists.
 *   CLEAR_ALL: clears all the lists and returns the default state.
 */
export function makeKeyedListReducer<T, KL extends string, V = T>(
  actionTypes: KeyedListActionTypes,
  listKey: KL = 'key' as KL,
  getItemKey: (v: T) => V = (v: T) => v as unknown as V,
): Reducer<Record<string, T[]>, any> {
  return makeKeyedReducer<T[], KL, KeyedAction<KL>>(
    makeListReducer<T, V>(actionTypes, getItemKey),
    listKey,
    actionTypes.CLEAR_ALL,
  );
}
