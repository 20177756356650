/**
 * Generate a new text id use for generating semi-unique ids, based on
 * the latest id. The implementation Happens to use monotonically
 * increasing integers, but this is an implementation detail, and the
 * resulting id should not be parsed.
 *
 * Used generally as:
 *
 *   const nextId = getNextId('chart:', currentId);
 *
 * If `currentId` is null, then the above code returns `'chart:0'`.
 *
 * If `currentId` is `'chart:4'` then the above code returns `'chart:5'`.
 *
 */
export function getNextId(tag: string, currentId?: string): string {
  if (!currentId) {
    return `${tag}0`;
  }
  const numericId = parseInt(currentId.slice(tag.length), 10) || 0;
  return `${tag}${numericId + 1}`;
}

/**
 * @description
 * Join any number of ids with a delimiter.
 *
 * @param ids Array of string ids
 * @param delimiter String to join the array of ids with
 *
 * @example
 * var parentLayerId = '/foo/layer/id';
 * var childId = '/bar/layer/id';
 *
 * makeIdListKey([parentLayerId, childId]);
 * // => '/foo/layer/id:/bar/layer/id';
 *
 */
export function makeIdListKey(ids: string[], delimiter = ':'): string {
  return ids.join(delimiter);
}
