import {
  CuratedColumnSymbology,
  CUSTOM_THEME,
  DEFAULT_OPACITY,
  DistributionTypes,
  InterpolationTypes,
  PaintColorTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/public/dataset/dane_county_tax_parcels': {
    impr_land_ratio: [
      {
        type: SymbologyTypes.FILL,
        paint: {
          'fill-color': {
            property: 'impr_land_ratio',
            type: PaintColorTypes.INTERVAL,
            stops: [
              { value: 0.5, color: 'rgb(255, 113, 113)' },
              { value: 0.75, color: 'rgb(241, 103, 102)' },
              { value: 1, color: 'rgb(228, 93, 91)' },
              { value: 1.25, color: 'rgb(214, 84, 81)' },
              { value: 1.5, color: 'rgb(201, 74, 70)' },
              { value: 1.75, color: 'rgb(187, 64, 60)' },
              { value: 2, color: 'rgb(174, 55, 50)' },
              { value: 3, color: 'rgb(161, 45, 41)' },
              { value: 4, color: 'rgb(148, 35, 31)' },
              { value: 5, color: 'rgb(135, 25, 22)' },
            ],
          },
          'fill-opacity': DEFAULT_OPACITY,
        },
        display_hints: {
          scale: SymbologyScales.NUMERIC,
          distribution: DistributionTypes.DEFAULT,
          interpolation: InterpolationTypes.INTERVAL,
          theme: CUSTOM_THEME,
        },
      },
    ],
  },
};

export default symbologies;
