import { Dispatch } from 'redux';
import SockJS from 'sockjs-client';

import { SwaggerClient } from 'uf/base/xhr';

import {
  handleWebsocketMessage,
  websocketsInitialized,
  websocketsTerminated,
} from './actions';

let reconnectInterval = null;

/**
 * Establishes the websocket connection, using a Promise to delay the
 * websocket until it is ready (open).
 *
 * General usage in an action, using redux-thunk:
 *
 * function myAction(someParam) {
 *   return (dispatch: Dispatch, getState, { client }) =>
 *     client.socket.then(socket => {
 *       socket.send({...someParam });
 *     });
 * }
 */

// TODO: abstract away the client object.
export function websocketConnect(client: SwaggerClient, dispatch: Dispatch) {
  // eslint-disable-next-line no-param-reassign
  client.socketPromise = new Promise(resolve => {
    // reconnect logic from http://stackoverflow.com/a/28497938/204357
    const socket: WebSocket = new SockJS('/websockets', {
      transports: ['websocket'],
    });

    // if we got this far, a connection is pending, so don't keep trying
    // to reconnect.
    clearInterval(reconnectInterval);

    socket.onopen = () => {
      // eslint-disable-next-line no-param-reassign
      client.socket = socket;

      resolve(socket);
      const connectevent = new CustomEvent('websocketconnect', {
        detail: { client, socket },
      });
      client.dispatchEvent(connectevent);
      dispatch(websocketsInitialized());
    };

    // TODO: instead of setInterval, should do some kind of exponential
    // backoff, probably using
    // https://github.com/MathieuTurcotte/node-backoff or something.
    // eslint-disable-next-line no-param-reassign
    socket.onclose = () => {
      dispatch(websocketsTerminated());
      // eslint-disable-next-line no-param-reassign
      client.socket = null;
      // eslint-disable-next-line no-param-reassign
      client.socketPromise = null;
      reconnectInterval = setInterval(
        () => websocketConnect(client, dispatch),
        2 * 1000,
      );
    };

    socket.onmessage = (event: MessageEvent) => {
      dispatch(handleWebsocketMessage(event));
    };
  });
}
