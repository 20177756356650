import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { LayerId, LayerVersion } from 'uf/layers';
import { ProjectId } from 'uf/projects';
import * as projectActionCreators from 'uf/projects/actions';
import {
  makeGetAvailableProjectLayers,
  makeGetProjectLayerListReferenceLayers,
} from 'uf/projects/selectors/availableLayers';
import { useMakeSelector } from 'uf/ui/base/useMakeSelector';
// TODO move hooks up to there
import { useCallback } from 'react';
import { getUfGeometryType } from 'uf/layers/helpers';
import { createConvertedLayer } from 'uf/projects/apis';
import { useLayerMetadataValue } from 'uf/ui/layers/hooks';
import { FormProps } from 'uf/ui/projects/SaveWorkingLayerButton/SaveWorkingLayerPrompt';

export function useIsLayerRemovable(projectId: ProjectId, layerId: LayerId) {
  const referenceLayerIds = useMakeSelector(
    makeGetProjectLayerListReferenceLayers,
    { projectId },
  ).map(l => l.full_path);
  return referenceLayerIds.includes(layerId);
}

export function useLayerGeometryType(layerId: LayerId) {
  const layerMetadata = useLayerMetadataValue(layerId);
  return getUfGeometryType(layerMetadata);
}

export function useProjectActions() {
  const dispatch = useDispatch();
  return bindActionCreators(projectActionCreators, dispatch);
}

export function useCreateConvertedLayerCallback(
  projectId: ProjectId,
  layerId: LayerId,
  layerVersion: LayerVersion,
) {
  const dispatch = useDispatch();
  return useCallback(
    ({ name, description }: FormProps) => {
      return dispatch(
        createConvertedLayer(
          projectId,
          layerId,
          layerVersion,
          name,
          description,
          'point_on_surface',
        ),
      );
    },
    [dispatch, layerId, layerVersion, projectId],
  );
}

export function useIsLayerFavoritable(projectId: ProjectId, layerId: LayerId) {
  const getAvailableProjectLayersState = makeGetAvailableProjectLayers();
  const availableLayers = useSelector(state =>
    getAvailableProjectLayersState(state, {
      projectId,
    }),
  );
  const layer = availableLayers?.data?.reference.find(
    ref => ref.layer.full_path === layerId,
  );
  return !!layer?.allow_in_favorites;
}
