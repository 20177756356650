import classNames from 'classnames';

import { Message } from 'uf/appservices/messages';
import textStyles from 'uf/styles/text.module.css';

export function getToastClassName(level: Message.MessageLevel): string {
  return classNames(textStyles.uppercaseText, getIntentClassName(level));
}

type IntentClasses = { [key in Message.MessageLevel]: string };

export const intentClasses: IntentClasses = {
  danger: 'bp4-intent-danger',
  info: 'bp4-intent-primary',
  success: 'bp4-intent-success',
  warning: 'bp4-intent-warning',
};

function getIntentClassName(level: Message.MessageLevel) {
  return intentClasses[level] || intentClasses.info;
}
