import _ from 'lodash';

export const HOC_PREFIX = '__hoc__';

export function withHocPrefix<T extends string = string>(str: string) {
  return `${HOC_PREFIX}${str}` as T;
}

export function omitHocProps<T extends object, K extends keyof T>(
  props: T,
  omitProps?: string[],
): Pick<T, K> {
  const omitSet = new Set(omitProps);
  const result = {} as Pick<T, keyof T>;

  Object.keys(props)
    .filter(key =>
      omitProps ? !omitSet.has(key) : !_.startsWith(key, HOC_PREFIX),
    )
    .forEach(key => {
      result[key] = props[key];
    });

  return result;
}
