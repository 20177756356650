import { INonIdealStateProps } from '@blueprintjs/core';
import { mdiAlertCircle } from '@mdi/js';
import Icon from '@mdi/react';
import { boundMethod } from 'autobind-decorator';
import React, { Component, ErrorInfo, ReactNode } from 'react';
import { connect } from 'react-redux';
import { jt, t } from 'ttag';

import { showIntercom as showIntercomAction } from 'uf/intercom/actions';
import { logEvent } from 'uf/logging';
import { rollbarError } from 'uf/rollbar';
import ErrorFallback from 'uf/ui/base/ErrorFallback/ErrorFallback';
import { IconSizeNew } from 'uf/ui/base/icon';

interface OwnProps extends INonIdealStateProps {
  showAction?: boolean;
  children?: ReactNode;
  onError?: (error: Error | PromiseRejectionEvent, info: ErrorInfo) => void;

  /**
   * The component to show in place of ErrorFallback.  This should only be used in places where
   * ErrorFallback does not fit in the ui.
   * @param icon the icon to show
   * @param title the error title
   * @param description the error description
   * @param showAction show an action button
   * @param onClick click andler for the action button
   */
  errorFallbackComponent?: React.ComponentType<any>;
  componentName?: string;
  icon?: JSX.Element;
}

interface DispatchProps {
  showIntercom: () => void;
}

type ErrorBoundaryProps = OwnProps & DispatchProps;

interface ErrorBoundaryState {
  error: Error | PromiseRejectionEvent;
  info: ErrorInfo;
}

export class ErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  static defaultProps = {
    icon: <Icon path={mdiAlertCircle} size={IconSizeNew.LARGE} />,
    onError: () => {},
  };

  state = {
    error: null,
    info: null,
  };

  componentDidCatch(error: Error | PromiseRejectionEvent, info: ErrorInfo) {
    const { onError, componentName: component } = this.props;
    const { componentStack } = info;

    let stack;
    let reason;
    if (isPromiseRejection(error)) {
      reason = error.reason;
    } else {
      stack = error.stack;
    }

    onError(error, info);
    this.setState({ error, info });

    rollbarError(reason || error, {
      env: 'browser',
      source: 'react_error_boundary',
      component,
      from_reason: !!reason,
      stack,
      component_stack: componentStack,
    });

    // Tell amplitude too, so we can correlate actions with crashes
    logEvent('uf.app.errorboundary', {
      error,
      component,
      stack,
    });
  }

  @boundMethod
  onClick() {
    this.setState({ error: null, info: null });
  }

  @boundMethod
  getDescription() {
    const { description, showIntercom } = this.props;
    if (description) {
      return description;
    }

    const contactSupport = (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/click-events-have-key-events
      <a
        key="contact-support"
        role="button"
        tabIndex={0}
        onClick={showIntercom}>{t`contact support`}</a>
    );

    return (
      <span>{jt`Please reload or ${contactSupport} if the issue persists.`}</span>
    );
  }
  render() {
    const {
      icon,
      title,
      showAction,
      errorFallbackComponent: ErrorFallbackComponent,
      children,
    } = this.props;
    const { error } = this.state;

    if (error) {
      if (ErrorFallbackComponent) {
        return (
          <ErrorFallbackComponent
            icon={icon}
            title={title}
            description={this.getDescription()}
            showAction={showAction}
            onClick={this.onClick}
          />
        );
      }
      return (
        <ErrorFallback
          icon={icon}
          title={title}
          description={this.getDescription()}
          showAction={showAction}
          onClick={this.onClick}
        />
      );
    }

    return children;
  }
}

export default connect<never, DispatchProps, OwnProps>(
  null,
  (dispatch): DispatchProps => ({
    showIntercom: () => dispatch(showIntercomAction()),
  }),
)(ErrorBoundary);

function isPromiseRejection(error): error is PromiseRejectionEvent {
  return !!error.reason;
}
