import _ from 'lodash';

import { ApprovedAny } from './types';

// These are constants meant to be used in places where ===
// matters. Specifically this is useful for default uses of the
// 'reselect' library where we want to return a consistent value for an
// empty result, without dirtying caches or breaking memoization.
export const EMPTY_ARRAY = [];
export const EMPTY_OBJECT = {};

Object.freeze(EMPTY_ARRAY);
Object.freeze(EMPTY_OBJECT);

export const CURRENT_YEAR = new Date().getFullYear();
export const LOADED = 'LOADED';
export const LOADING = 'LOADING';

/**
 * Determins if something is "falsey" per more general rules than JS or lodash usually says, closer
 * to what Python considers falsey.
 *
 * Falsey values include:
 *
 *  * false
 *  * []
 *  * {}
 *  * 0
 *  * ''
 *
 */
export function isFalsey(value: ApprovedAny) {
  if (_.isArrayLike(value) || _.isObjectLike(value)) {
    return _.isEmpty(value);
  }

  return !value;
}

export function isTruthy(value: ApprovedAny) {
  return !isFalsey(value);
}
