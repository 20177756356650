import _ from 'lodash';
import { createSelector } from 'reselect';
import warning from 'warning';

import { UserAction } from 'uf-api';
import { makeGetApiObjectResults } from 'uf/api/selectors';
import { EMPTY_ARRAY } from 'uf/base';
import { makeGetFromPropsSelector } from 'uf/base/selector';
import { DataState, getData, isLoaded, isLoading } from 'uf/data/dataState';
import { PermissionAction } from 'uf/user/permissions';

export const getCanPerformState = makeGetApiObjectResults(
  'authentication',
  'can_perform_actions',
);
// Create a predicate that returns true if the given
// canPerform object matches the (verb, resource) combination
function makeCanPerformMatches(verb: string, resource: string) {
  return ({ verb: queryVerb, resource: queryResource }) =>
    verb === queryVerb && resource === queryResource;
}

/**
 * Get loading states for all permissions call that have to do with the given
 * verb + resource.
 *
 * Generally not for wide consumption becuase it includes states in various
 * loading states.
 */
export function makeGetMatchingCanPerformStates() {
  // Gets an array of states that match the given (verb, resource) combination
  return createSelector(
    getCanPerformState,
    makeGetFromPropsSelector<string, 'verb'>('verb'),
    makeGetFromPropsSelector<string, 'resource'>('resource'),
    (canPerformStates, verb, resource): DataState<UserAction[]>[] =>
      getMatchingStatesForResource(canPerformStates, verb, resource),
  );
}

/**
 * same as `makeGetMatchingCanPerformStates` except for multiple resources
 * instead of one
 */
function makeGetMatchingCanPerformStatesManyResources() {
  // Gets an array of states that match the given (verb, resource) combination
  return createSelector(
    getCanPerformState,
    makeGetFromPropsSelector<string, 'verb'>('verb'),
    makeGetFromPropsSelector<string[], 'resources'>('resources'),
    (canPerformStates, verb, resources): DataState<UserAction[]>[][] => {
      if (!resources) {
        return EMPTY_ARRAY;
      }
      // A 'can perform' state is a loading state,
      // with the 'query' key set to the initial query
      return resources.map(resource =>
        getMatchingStatesForResource(canPerformStates, verb, resource),
      );
    },
  );
}

function getMatchingStatesForResource(
  canPerformStates: Record<string, DataState<UserAction[]>>,
  verb: string,
  resource: string,
) {
  const canPerformMatch = makeCanPerformMatches(verb, resource);
  const matchingCanPerforms = Object.values(canPerformStates).filter(
    ({ key }) => {
      if (!key) {
        return false;
      }
      const query = JSON.parse(key);
      const m = query.some(canPerformMatch);
      return m;
    },
  );
  return matchingCanPerforms;
}

/**
 * Gets a specific state of true, false, or undefined for each resource
 * true means user has access
 * false means user does not have access
 * undefined means we don't yet know.
 *
 * undefined is conveniently falsey so you can default to "no access"
 */
export function makeGetCheckFeature() {
  return createSelector(
    makeGetMatchingCanPerformStates(),
    makeGetFromPropsSelector<string, 'verb'>('verb'),
    makeGetFromPropsSelector<string, 'resource'>('resource'),
    (states, verb, resource): boolean =>
      getNewestMatchingCanDo(states, verb, resource),
  );
}

export function makeGetCheckFeatures() {
  return createSelector(
    makeGetMatchingCanPerformStatesManyResources(),
    makeGetFromPropsSelector<string, 'verb'>('verb'),
    makeGetFromPropsSelector<string[], 'resources'>('resources'),
    (statesPerResource, verb, resources): boolean[] => {
      if (!resources) {
        return EMPTY_ARRAY;
      }
      return _.zip(statesPerResource, resources).map(([states, resource]) =>
        getNewestMatchingCanDo(states, verb, resource),
      );
    },
  );
}

function getNewestMatchingCanDo(
  states: DataState<UserAction[]>[],
  verb: string,
  resource: string,
) {
  const canPerformMatch = makeCanPerformMatches(verb, resource);
  const canDos: boolean[] = _(states)
    // filter - loaded values only
    .filter(d => isLoaded(d))
    // sort by time ascending order
    .sortBy('extra.timestamp')
    .map((d: DataState<UserAction[]>): UserAction[] => getData<UserAction[]>(d))
    // First find *all* matching items for this (verb, resource) combo
    .map((items: UserAction[]): UserAction[] => items.filter(canPerformMatch))
    // Map (and flatten) to just the boolean results
    .map((items: UserAction[]): boolean =>
      items.some(({ can_do: canDo }) => canDo),
    )
    .value();
  if (!canDos.length) {
    return undefined;
  }

  // finally take the last element, which should be the most recent in time
  return canDos.pop();
}

export function makeGetCheckFeatureDetails() {
  return createSelector(
    makeGetMatchingCanPerformStates(),
    makeGetFromPropsSelector<string, 'verb'>('verb'),
    makeGetFromPropsSelector<string, 'resource'>('resource'),
    (states, verb, resource): DataState<UserAction> =>
      getNewestMatchingUserActionState(states, verb, resource),
  );
}

export function makeGetCheckFeaturesDetails() {
  return createSelector(
    makeGetMatchingCanPerformStatesManyResources(),
    makeGetFromPropsSelector<string, 'verb'>('verb'),
    makeGetFromPropsSelector<string[], 'resources'>('resources'),
    (statesPerResource, verb, resources): DataState<UserAction>[] =>
      _.zip(statesPerResource, resources).map(([states, resource]) =>
        getNewestMatchingUserActionState(states, verb, resource),
      ),
  );
}

function getNewestMatchingUserActionState(
  states: DataState<UserAction[]>[],
  verb: string,
  resource: string,
): DataState<UserAction> {
  const canPerformMatch = makeCanPerformMatches(verb, resource);
  const actionsStates: DataState<UserAction[]>[] = _.sortBy(
    states,
    state => state.requestTime,
  );

  // Find all possible DataStates that could be used for matching
  const matchingActions = actionsStates
    .map((actionsState): [DataState<UserAction[]>, UserAction] => {
      // First find *all* matching items for this (verb, resource) combo
      const actionQueries: PermissionAction[] =
        actionsState?.extra?.query ?? [];
      if (actionQueries.some(query => canPerformMatch(query))) {
        const actions = getData(actionsState, EMPTY_ARRAY);
        if (!actions.length) {
          // this is a bit of a hack,
          warning(
            isLoading(actionsState),
            'Permissions envelope matches, but is not loaded',
          );
          return [actionsState, { resource, verb, can_do: false }];
        }
        const matchingAction = actions.find(item => canPerformMatch(item));
        return [actionsState, matchingAction];
      }
      // make a fake item for this verb, so that actionStates that are loading
      // for the first time can still load
      return [actionsState, { resource, verb, can_do: false }];
    })
    .filter(([actionsState, matchingAction]) => !!matchingAction);

  // now pick out the most recent one
  const [actionsState, matchingAction] = _.last(matchingActions) ?? EMPTY_ARRAY;
  const newAction: DataState<UserAction> = {
    ...actionsState,
    data: matchingAction,
    promise: null,
  };
  return newAction;
}
