import { createSelector } from 'reselect';

import { EMPTY_OBJECT } from 'uf/base';
import { makeGetFromPropsSelector } from 'uf/base/selector';
import { EmptyKeyedState } from 'uf/data/dataState';

import { getOrganizationsState } from './';

const getOrganizationLogoState = createSelector(
  getOrganizationsState,
  organizationState => organizationState.logos || EMPTY_OBJECT,
);

export function makeGetOrganzationLogoInfosState() {
  return createSelector(
    getOrganizationLogoState,
    makeGetFromPropsSelector<string, 'organizationKey'>('organizationKey'),
    (organizationState, key) => organizationState[key] || EmptyKeyedState,
  );
}
