import { AnyAction } from 'redux';
import { ActionsObservable, Epic, ofType } from 'redux-observable';
import { merge as observableMerge, of as observableOf } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  first,
  map,
  mergeMap,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import { t } from 'ttag';

import {
  createProjectActionTypes,
  createProjectSuccessAction,
  createScenarioActionTypes,
  createScenarioSuccessAction,
  deleteProjectActionTypes,
  deleteScenarioActionTypes,
  editProjectActionTypes,
  editScenarioActionTypes,
  getProjectActionTypes,
  getProjectTasksActionTypes,
  getProjectTasksSuccessAction,
  removeFromLayerListActionTypes,
  removeFromLayerListSuccessAction,
} from 'uf-api/api/project.service';
import { ScopeTypes } from 'uf-ws/WebsocketActions';
import { clearActiveProject, setActiveScenario } from 'uf/app/actions';
import { APP_INITIALIZED, SET_ACTIVE_PROJECT } from 'uf/app/ActionTypes';
import {
  getActiveProjectId,
  getActiveScenarioId,
  makeGetActiveScenarioIdForProject,
} from 'uf/app/selectors';
import { addAppMessage } from 'uf/appservices/actions';
import { combineEpics } from 'uf/base/epics';
import {
  clearLibraryBuildings,
  clearLibraryBuildingTypes,
  clearLibraryPlaceTypes,
} from 'uf/builtforms/actions/data';
import { FailureAction } from 'uf/data/ActionTypes';
import { clearActiveLayer } from 'uf/explore/actions/layers';
import { resetMap } from 'uf/explore/actions/map';
import { getActiveLayerId } from 'uf/explore/selectors/layers';
import { makeGetActiveViewId } from 'uf/explore/selectors/views';
import {
  BIND_IMPORT_LAYER_TASK,
  importActionTypes,
} from 'uf/import/ActionTypes';
import { ensureLayerBounds } from 'uf/layers/actions/bounds';
import { loadOrganizationProjects } from 'uf/organizations/actions';
import {
  loadProject,
  loadTasksForProject,
  loadUserProjects,
  RemoveLayerExtra,
} from 'uf/projects/actions';
import {
  BIND_CREATE_BUFFERED_LAYER_TASK,
  startCreateBufferedLayerActionTypes,
} from 'uf/projects/ActionTypes';
import { clearAnalysisParamsOnProjectUpdate } from 'uf/projects/epics/analysis';
import { makeProjectId } from 'uf/projects/ids';
import {
  getProjectScenarios,
  makeGetProjectBuiltFormsLibraryId,
} from 'uf/projects/selectors';
import { makeGetContextAreaLayerForProject } from 'uf/projects/selectors/layers';
import { makeGetProjectLayerIdMap } from 'uf/projects/selectors/virtualLayers';
import {
  BIND_CREATE_SCENARIO_TASK,
  createScenarioMessageTypes,
} from 'uf/scenarios/ActionTypes';
import { UFState } from 'uf/state';
import { makeTaskActionType } from 'uf/tasks/actionHelpers';
import { bindTaskToResource } from 'uf/tasks/actions';
import { NotificationAction } from 'uf/tasks/ActionTypes';
import { NotificationTypes } from 'uf/tasks/NotificationTypes';
import { ofNotificationType } from 'uf/tasks/observables';
import { TaskStatuses } from 'uf/tasks/TaskStatuses';
import { TaskTypes } from 'uf/tasks/TaskTypes';
import { setLayerVisibility } from 'uf/views/actions/layers';
import { makeWebsocketActionType } from 'uf/websockets/actionHelpers';

import {
  copyUserSymbologyOnDynamicLayerCreation,
  showToastForFilteredLayerCreation,
} from './dynamicLayers';
import notificationEpics from './notifications';

/**
 * Epics to handle scenario creation/modification.
 */
function reportCreateScenarioFailure(
  action$: ActionsObservable<FailureAction>,
) {
  return action$.pipe(
    ofType(createScenarioActionTypes.FAILURE),
    map(() =>
      addAppMessage('There was an error while creating the scenario', {
        level: 'danger',
      }),
    ),
  );
}

const reloadProjectAfterScenarioEdit: Epic<AnyAction, any> = (
  action$: ActionsObservable<AnyAction>,
  state$,
) => {
  return action$
    .ofType(editScenarioActionTypes.SUCCESS)
    .pipe(map(() => loadProject(getActiveProjectId(state$.value))));
};

function reportEditScenarioFailure(action$: ActionsObservable<AnyAction>) {
  return action$.pipe(
    ofType(editScenarioActionTypes.FAILURE),
    map(() =>
      addAppMessage('There was an error while editing the scenario', {
        level: 'danger',
      }),
    ),
  );
}

export const reloadProjectAfterDeleteScenario: Epic<AnyAction, any> = (
  action$: ActionsObservable<AnyAction>,
  state$,
) => {
  return action$
    .ofType(makeWebsocketActionType(NotificationTypes.SCENARIO_DELETED))
    .pipe(
      mergeMap(() =>
        observableMerge(
          observableOf(loadProject(getActiveProjectId(state$.value))),
          observableOf(
            addAppMessage(t`The scenario was deleted.`, {
              level: 'success',
              status: 'success',
            }),
          ),
        ),
      ),
    );
};

export const reloadProjectAfterCreateScenario: Epic<any, any> = (
  action$: ActionsObservable<AnyAction>,
  state$,
) =>
  action$
    .ofType(makeTaskActionType(createScenarioMessageTypes[TaskStatuses.DONE]))
    .pipe(map(() => loadProject(getActiveProjectId(state$.value))));

export const ensureContextAreaBoundsOnProjectLoad: Epic<AnyAction, any> = (
  action$,
  state$,
) => {
  const getContextLayer = makeGetContextAreaLayerForProject();
  return action$.pipe(
    ofType(getProjectActionTypes.SUCCESS),
    filter(action => {
      const { projectId } = action;
      return !!getContextLayer(state$.value, { projectId });
    }),
    map(action => {
      const { projectId } = action;

      const { full_path: contextLayerId, version } = getContextLayer(
        state$.value,
        { projectId },
      );

      return ensureLayerBounds({ layerId: contextLayerId, version });
    }),
  );
};

export const selectNextScenarioAfterDeleteScenario: Epic<AnyAction, any> = (
  action$,
  state$,
) => {
  let index;
  return action$.pipe(
    ofType(deleteScenarioActionTypes.SUCCESS),
    // we only need to select another scenario if the active scenario is deleted
    filter(({ scenarioId }) => {
      const activeScenarioId = getActiveScenarioId(state$.value);

      return scenarioId === activeScenarioId;
    }),
    // grab the index of the deleted scenario and wait for the project to reload
    switchMap(deleteScenarioAction => {
      const { projectId, scenarioId } = deleteScenarioAction;
      const scenarios = getProjectScenarios(state$.value, { projectId });
      index = scenarios.findIndex(
        scenario => scenario.full_path === scenarioId,
      );
      return action$
        .ofType(getProjectActionTypes.SUCCESS)
        .pipe(
          first(
            loadProjectAction =>
              loadProjectAction.result.full_path === projectId,
          ),
        );
    }),
    // now, activate the scenario at that index and
    // decrement if we deleted the scenario at the end of the list
    map(getProjectAction => {
      const { key: projectId } = getProjectAction;
      const scenarios = getProjectScenarios(state$.value, { projectId });
      if (index === scenarios.length) {
        index -= 1;
      }
      return setActiveScenario(projectId, scenarios[index].full_path);
    }),
  );
};

// When the active layer is removed via the Layer Manager, we need to clear it
// TODO: remove this when the selectors do the right thing
const unsetActiveLayerAfterRemoveFromLayerList: Epic<
  removeFromLayerListSuccessAction & RemoveLayerExtra,
  any
> = (action$, state$) => {
  const getActiveViewId = makeGetActiveViewId();
  const getProjectLayerIdMap = makeGetProjectLayerIdMap();
  return action$.pipe(
    ofType(removeFromLayerListActionTypes.SUCCESS),
    filter(({ projectId, layerId }) => {
      const scenarioId = makeGetActiveScenarioIdForProject()(state$.value, {
        projectId,
      });
      const viewId = getActiveViewId(state$.value, { projectId });
      return (
        layerId ===
        getActiveLayerId(state$.value, {
          projectId,
          scenarioId,
          viewId,
        })
      );
    }),
    withLatestFrom(state$),
    map(([{ projectId, layerId }, state]) => {
      const viewId = getActiveViewId(state, { projectId });
      const layerIdMap = getProjectLayerIdMap(state, { projectId });
      return setLayerVisibility(projectId, layerIdMap[layerId], viewId, false);
    }),
  );
};

function reportDeleteScenarioFailure(action$: ActionsObservable<AnyAction>) {
  // TODO: Handle this as a general swagger error.
  return action$.pipe(
    ofType(deleteScenarioActionTypes.FAILURE),
    map(() =>
      addAppMessage('There was an error while deleting the scenario', {
        level: 'danger',
      }),
    ),
  );
}

export function loadProjectListAfterCreateProject(
  action$: ActionsObservable<createProjectSuccessAction>,
) {
  return action$
    .ofType(createProjectActionTypes.SUCCESS)
    .pipe(map(() => loadUserProjects()));
}

export const reloadProjectListsAfterDeleteProject: Epic<AnyAction, any> = (
  action$: ActionsObservable<AnyAction>,
  state$,
) => {
  return action$.pipe(
    ofType(deleteProjectActionTypes.SUCCESS),
    mergeMap(({ namespace, projectKey, options }) => {
      const allActions = [];

      // Clear all the active things if the user deleted their active project.
      const activeProjectId = getActiveProjectId(state$.value);
      const deletedProjectId = makeProjectId(namespace, projectKey);

      if (deletedProjectId === activeProjectId) {
        // TODO: Make separate epics to manage the active state relationships between
        // Project/Scenario/Layer, eg: clearActiveProject should signal
        // clearing the active scenario, clearing the active project should reset the map, etc..
        allActions.push(observableOf(clearActiveProject()));
        allActions.push(observableOf(clearActiveLayer(activeProjectId)));
        allActions.push(observableOf(resetMap()));
      }

      // Always reload the user projects list when a project is deleted.
      // This will kick off another epic to set a new active project.
      allActions.push(observableOf(loadUserProjects()));

      // if the action specifies a reload of the organization projects list, do that too
      if (options.refreshOrganizationProjectList) {
        allActions.push(observableOf(loadOrganizationProjects(namespace)));
      }

      return observableMerge(...allActions);
    }),
  );
};

export function reloadProjectListsAfterEditProject(
  action$: ActionsObservable<AnyAction>,
) {
  return action$.pipe(
    ofType(editProjectActionTypes.SUCCESS),
    mergeMap(({ namespace, options }) => {
      const allActions = [];
      const organizationKey = namespace;

      allActions.push(observableOf(loadUserProjects()));

      if (options.refreshOrganizationProjectList) {
        allActions.push(
          observableOf(loadOrganizationProjects(organizationKey)),
        );
      }

      return observableMerge(...allActions);
    }),
  );
}

export function bindBufferedLayerTaskToProject(
  action$: ActionsObservable<AnyAction>,
) {
  return action$
    .ofType(startCreateBufferedLayerActionTypes.SUCCESS)
    .pipe(
      map(({ result: taskId, projectId }) =>
        bindTaskToResource(BIND_CREATE_BUFFERED_LAYER_TASK, taskId, projectId),
      ),
    );
}

export const bindCreateScenarioTaskToProject: Epic<
  createScenarioSuccessAction,
  any
> = action$ => {
  return action$.ofType(createScenarioActionTypes.SUCCESS).pipe(
    map(({ result: taskId, key: scenarioKey }) =>
      bindTaskToResource(
        BIND_CREATE_SCENARIO_TASK,
        // TODO: fix the create_scenario endpoint so that the taskid is in the response
        taskId as string,
        scenarioKey,
      ),
    ),
  );
};

export function bindImportLayerTaskToProject(
  action$: ActionsObservable<AnyAction>,
) {
  return action$
    .ofType(importActionTypes.SUCCESS)
    .pipe(
      map(({ result: taskId, projectId }) =>
        bindTaskToResource(BIND_IMPORT_LAYER_TASK, taskId, projectId),
      ),
    );
}

/**
 * An epic to get the running tasks associated with a project.  We only need to call this the first
 * time we switch to a project, further tasks will be sent down via websockets.  Make sure to only
 * start loading once the app has loaded client side so we don't miss any tasks.
 */
function loadTasksWhenProjectSet(action$: ActionsObservable<AnyAction>) {
  return action$.pipe(
    ofType(APP_INITIALIZED),
    switchMap(() => {
      return action$.pipe(
        ofType(SET_ACTIVE_PROJECT),
        distinctUntilChanged(
          (action1, action2) => action1.value === action2.value,
        ),
        filter(({ value: activeProjectId }) => !!activeProjectId),
        map(({ value: projectId }) => {
          return loadTasksForProject(projectId);
        }),
      );
    }),
  );
}

/**
 * Load tasks for the active project (if it exists) after the app loads.  We need this epic because
 * SET_ACTIVE_PROJECT is called during SSR, so loadTasksWhenProjectSet won't catch the action for
 * the initial project.
 */
const loadTasksAfterAppInitialized: Epic<AnyAction, any> = (
  action$: ActionsObservable<AnyAction>,
  state$,
) => {
  return action$.pipe(
    ofType(APP_INITIALIZED),
    filter(() => !!getActiveProjectId(state$.value)),
    map(() => loadTasksForProject(getActiveProjectId(state$.value))),
  );
};

export const loadRunningTasksForProject = combineEpics(
  {
    loadTasksAfterAppInitialized,
    loadTasksWhenProjectSet,
  },
  'loadRunningTasksForProject',
);

export const emitTaskNotifications: Epic<getProjectTasksSuccessAction, any> =
  action$ =>
    action$.pipe(
      ofType(getProjectTasksActionTypes.SUCCESS),
      mergeMap(action => {
        const notificationActions = action.result.map<NotificationAction>(
          task => ({
            type: makeWebsocketActionType(NotificationTypes.TASK_NOTIFICATION),
            key: task.task_id,
            result: {
              type: NotificationTypes.TASK_NOTIFICATION,
              scope_type: ScopeTypes.PROJECT,
              scope: action.key,
              task_id: task.task_id,
              task_type: task.task_type as TaskTypes,
              info: task.info,
              // TODO: need to combine Task.StatusEnum with TaskStatuses
              status: task.status as TaskStatuses,
            },
          }),
        );

        return observableMerge(
          ...notificationActions.map(notificationAction =>
            observableOf(notificationAction),
          ),
        );
      }),
    );

/**
 * There may have been built forms requested prior to the completion of base
 * canvas creation. Those will have all the built forms marked as "unused".
 *
 * Since there is no notion of built form library versioning, we need to allow
 * the ensures to fire again when the base canvas creation is complete.
 */
export const clearLibraryBuiltFormsOnCanvasCreation: Epic<
  NotificationAction,
  any,
  UFState
> = (action$, state$) => {
  const getProjectBuiltFormsLibraryId = makeGetProjectBuiltFormsLibraryId();
  return action$.pipe(
    ofNotificationType(
      TaskTypes.TASK_TYPE_PROJECT_CREATE_BASE_SCENARIO,
      TaskStatuses.DONE,
    ),
    mergeMap(action => {
      const projectId = action.result.scope;
      const libraryId = getProjectBuiltFormsLibraryId(state$.value, {
        projectId,
      });
      return observableMerge(
        observableOf(clearLibraryBuildings(libraryId)),
        observableOf(clearLibraryBuildingTypes(libraryId)),
        observableOf(clearLibraryPlaceTypes(libraryId)),
      );
    }),
  );
};

export default combineEpics(
  {
    clearLibraryBuiltFormsOnCanvasCreation,
    reportCreateScenarioFailure,
    reloadProjectAfterScenarioEdit,
    reportEditScenarioFailure,
    reloadProjectAfterDeleteScenario,
    reloadProjectAfterCreateScenario,
    selectNextScenarioAfterDeleteScenario,
    reportDeleteScenarioFailure,
    loadProjectListAfterCreateProject,
    reloadProjectListsAfterDeleteProject,
    reloadProjectListsAfterEditProject,
    unsetActiveLayerAfterRemoveFromLayerList,
    bindBufferedLayerTaskToProject,
    bindCreateScenarioTaskToProject,
    bindImportLayerTaskToProject,
    loadRunningTasksForProject,
    emitTaskNotifications,
    ensureContextAreaBoundsOnProjectLoad,
    notificationEpics,
    copyUserSymbologyOnDynamicLayerCreation,
    clearAnalysisParamsOnProjectUpdate,
    showToastForFilteredLayerCreation,
  },
  'project epics',
);
