import _ from 'lodash';
import { t } from 'ttag';

import { LayerReference } from 'uf-api';
import { ColumnKey } from 'uf/layers';
import { LayerType, LegacyVirtualLayerId } from 'uf/projects/virtualLayers';
import { ExtrusionBreaksMethod, LayerColumnSymbology } from 'uf/symbology';

export const PLACEHOLDER_PREFIX = 'placeholder:';
export const PAINTED_CANVAS_VIRTUAL_ID: LegacyVirtualLayerId =
  'painted:painted';
export const BASE_CANVAS_VIRTUAL_ID: LegacyVirtualLayerId = 'base:base';

/**
 * When we have never had an order in the project, generate an initial order.
 * This generally means
 * 1. base
 * 2. painted
 * 3. boundary
 * 4. working
 * 5. analysis
 * 6. reference
 */
const DEFAULT_ORDER = [PAINTED_CANVAS_VIRTUAL_ID, BASE_CANVAS_VIRTUAL_ID];

export interface LayerInfo {
  layerOrderKey: LegacyVirtualLayerId;
  isPlaceholderLayer?: boolean;
  status?: LayerStatus;
  layerInfo: LayerReference;
}

export interface LayerInfoWithExtrusion extends LayerInfo {
  extrudedColumn: string;
}

export interface LayerStatus {
  loading: boolean;
  message: string;
}

/*
 * Default layer indexes
 * When a new layer shows up, it should be inserted at the correct index. These are the defaults.
 * If the existing order is different, we reassign these indexes so groups of layers are together.
 * This should be in sync with DEFAULT_ORDER.
 */
const LAST_LAYER_INDEX_DEFAULTS = {
  // Note that new layers get inserted *after* the indexes here, so
  // -1 means the first position in the list.
  boundary: -1,
  base: DEFAULT_ORDER.indexOf(BASE_CANVAS_VIRTUAL_ID),
  painted: DEFAULT_ORDER.indexOf(PAINTED_CANVAS_VIRTUAL_ID),
};

// And finally, this is the order that new layers are added, if they've never been seen before.
const LAYER_TYPE_ORDER: LayerType[] = [
  LayerType.BOUNDARY,
  LayerType.PAINTED,
  LayerType.BASE,
  LayerType.WORKING,
  LayerType.ANALYSIS,
  LayerType.REFERENCE,
];

function parseOrderKey(layerOrderKey: string): {
  layerType: LayerType;
  layerKey: string;
} {
  // Note that layerKey is *sometimes* the layerId, but it could be
  // something else like the analysis module_key or some other fake
  // layer id. For singular, well-known layers, layerKey will be
  // layerType (i.e. a layerOrderKey of 'base' will parse to
  // { layerType: 'base', layerKey: 'base' }
  const [layerType, layerKey] = layerOrderKey.split(':') as [LayerType, string];
  return { layerType, layerKey };
}

export function makeLayerOrderKey(
  layerType: LayerType,
  layerKey: string,
): LegacyVirtualLayerId {
  return `${layerType}:${layerKey}`;
}

export function makePlaceHolderForKey(key: LegacyVirtualLayerId) {
  return `${PLACEHOLDER_PREFIX}${key}`;
}

export function isPlaceHolder(key: string) {
  return key.startsWith(PLACEHOLDER_PREFIX);
}

function makePlaceHolderLayerReference(
  layerOrderKey: LegacyVirtualLayerId,
): LayerReference {
  return {
    namespace: null,
    key: null,
    layer_type: null,
    full_path: makePlaceHolderForKey(layerOrderKey),
  };
}

interface LayerReferenceByKey {
  [layerInfoType: string]: LayerReference;
}

type LayerReferenceByType = Record<LayerType, LayerReferenceByKey>;

type LayerTypeIndex = Record<LayerType, number>;
/**
 * Resolve a layer order key like 'reference:/foo/bar' to a 'layerOrderInfo' object, in the shape:
 * {
 *   layerOrderKey: ...
 *   layerInfo,
 * }
 */
function resolveLayerOrderKey(
  layerOrderKey: string,
  layerInfosByType: LayerReferenceByType,
  layerStatusByOrderKey: Record<string, LayerStatus>,
): LayerInfo {
  const { layerType, layerKey } = parseOrderKey(layerOrderKey);

  if (layerInfosByType[layerType][layerKey]) {
    const newLayerInfo: LayerReference = layerInfosByType[layerType][layerKey];
    const status: LayerStatus = layerStatusByOrderKey[layerOrderKey];
    const layerOrderInfo: LayerInfo = {
      layerOrderKey,
      layerInfo: newLayerInfo,
    };

    if (status) {
      layerOrderInfo.status = status;
    }

    return layerOrderInfo;
  }
  // If the layer wasn't found, it means the new scenario no
  // longer has it, but we'll leave a placeholder in place.
  return {
    layerOrderKey,
    isPlaceholderLayer: true,
    layerInfo: makePlaceHolderLayerReference(layerOrderKey),
  };
}

export function makeLayerOrderInfos(
  baseLayer: LayerReference,
  canvasLayer: LayerReference,
  workingLayers: LayerReference[],
  boundaryLayers: LayerReference[],
  analysisLayers: LayerReference[],
  referenceLayers: LayerReference[],
  existingOrder: string[],
): LayerInfo[] {
  let order = existingOrder;
  if (_.isEmpty(existingOrder)) {
    order = DEFAULT_ORDER;
  }

  const layerInfosByType = groupLayersByType(
    baseLayer,
    canvasLayer,
    boundaryLayers,
    workingLayers,
    analysisLayers,
    referenceLayers,
  );

  const lastLayerTypeIndex: LayerTypeIndex = {
    ...LAST_LAYER_INDEX_DEFAULTS,
    // Analysis, Boundary, and Reference layers are inserted at the end
    working: order.length - 1,
    analysis: order.length - 1,
    reference: order.length - 1,
  };

  // TODO: get these from a selector.  Spiking this in here for now until the backend can tell us
  // information about the status of a layer. For now we assume that if there is no base layer that
  // it must be loading.
  const layerStatusByKey: Record<string, LayerStatus> = {};
  if (baseLayer && _.isEmpty(baseLayer)) {
    layerStatusByKey[BASE_CANVAS_VIRTUAL_ID] = {
      loading: true,
      message: t`Creating base canvas...`,
    };
  }

  // This is a giant join between existingOrder and all the layers in the project.
  // * Where they line up, we just add them to an array.
  // * If a new layer shows up that isn't in existingOrder, then we insert it after
  //   the last entry for that layerType.
  // * If an entry is in existingOrder that we don't have a matching layer, insert
  //   a "placeholder" layer that may be ignored by consumers.

  // first create an ordered list from layers we know about.
  const orderedLayerInfos = createOrderFromExistingLayers(
    order,
    layerInfosByType,
    lastLayerTypeIndex,
    layerStatusByKey,
  );

  insertMissingLayers(orderedLayerInfos, layerInfosByType, lastLayerTypeIndex);
  return orderedLayerInfos;
}

/**
 * Increases indexes
 */
function increaseIndexesAfter(indexObject: LayerTypeIndex, afterIndex: number) {
  Object.keys(indexObject).forEach(key => {
    if (indexObject[key] >= afterIndex) {
      // eslint-disable-next-line no-param-reassign
      indexObject[key] += 1;
    }
  });
}

function groupLayersByType(
  baseLayer: LayerReference,
  canvasLayer: LayerReference,
  boundaryLayers: LayerReference[],
  workingLayers: LayerReference[],
  analysisLayers: LayerReference[],
  referenceLayers: LayerReference[],
): LayerReferenceByType {
  const mappableAnalysisLayers = sortedMappableLayers(analysisLayers);
  const mappableReferenceLayers = sortedMappableLayers(referenceLayers);
  const mappableWorkingLayers = sortedMappableLayers(workingLayers);

  const referenceLayersById = _.keyBy(mappableReferenceLayers, 'full_path');
  const workingLayersById = _.keyBy(mappableWorkingLayers, 'full_path');
  const boundaryLayersById = _.keyBy(boundaryLayers, 'full_path');
  const analysisLayersByName = _.keyBy(mappableAnalysisLayers, 'details.name');

  const layersByType: LayerReferenceByType = {
    analysis: analysisLayersByName,
    reference: referenceLayersById,
    working: workingLayersById,
    boundary: boundaryLayersById,
    painted: {},
    base: {},
  };

  if (baseLayer) {
    layersByType.base.base = baseLayer;
  }

  if (canvasLayer) {
    layersByType.painted.painted = canvasLayer;
  }
  return layersByType;
}

function createOrderFromExistingLayers(
  existingOrder: string[],
  layersByType: LayerReferenceByType,
  lastLayerTypeIndex: LayerTypeIndex,
  layerStatusByKey: Record<string, LayerStatus>,
): LayerInfo[] {
  const orderedLayers: LayerInfo[] = [];

  existingOrder.forEach((layerOrderKey, currentIndex) => {
    const { layerType, layerKey } = parseOrderKey(layerOrderKey);

    const layerInfo = resolveLayerOrderKey(
      layerOrderKey,
      layersByType,
      layerStatusByKey,
    );
    if (layerInfo) {
      increaseIndexesAfter(lastLayerTypeIndex, currentIndex);
      orderedLayers.push(layerInfo);

      // eslint-disable-next-line no-param-reassign
      lastLayerTypeIndex[layerType] = currentIndex;
      if (layerKey in layersByType[layerType]) {
        // eslint-disable-next-line no-param-reassign
        delete layersByType[layerType][layerKey];
      }
    }
  });

  return orderedLayers;
}

function insertMissingLayers(
  layerInfos: LayerInfo[],
  layerInfosByType: LayerReferenceByType,
  lastLayerTypeIndex: LayerTypeIndex,
) {
  // next insert any layers that haven't yet been inserted
  LAYER_TYPE_ORDER.forEach(targetLayerType => {
    // TODO: do these in the order specified by each of the respective layer
    const targetLayerTypeInfos = layerInfosByType[targetLayerType];
    Object.keys(targetLayerTypeInfos).forEach(newLayerKey => {
      // insert in the new position
      const newLayerInfo = targetLayerTypeInfos[newLayerKey];
      const layerTypeLastIndex = lastLayerTypeIndex[targetLayerType];
      layerInfos.splice(layerTypeLastIndex + 1, 0, {
        layerOrderKey: makeLayerOrderKey(targetLayerType, newLayerKey),
        layerInfo: newLayerInfo,
      });

      // now update any positions right after
      increaseIndexesAfter(lastLayerTypeIndex, layerTypeLastIndex);
    });
  });
}

function sortedMappableLayers(layers: LayerReference[]) {
  return _.sortBy(
    layers.filter(layer => layer?.details?.tile_url),
    layer => layer?.details?.name,
  );
}

/**
 * helper function to add the 'extrudedColumn' property to a LayerInfo if the symbology indicates
 * there is an extruded column.
 *
 * @param layerInfo the layer info object to add the property to
 * @param symbology the symbology for the column to check for extrusion
 * @param columnKey the column key of the symbology, this string is set as the value for
 *                  extrudedColumn
 */
export function addExtrudedColumnToLayerInfo(
  layerInfo: LayerInfo,
  symbology: LayerColumnSymbology,
  columnKey: ColumnKey,
): LayerInfoWithExtrusion {
  const extrusionOptions = symbology?.display_hints?.extrusion_options;

  const hasExtrudedColumn =
    extrusionOptions &&
    extrusionOptions.breaksMethod !== ExtrusionBreaksMethod.NoExtrusion;

  if (hasExtrudedColumn) {
    return {
      ...layerInfo,
      extrudedColumn: columnKey,
    };
  }

  return {
    ...layerInfo,
    extrudedColumn: null,
  };
}
