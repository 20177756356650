import { Button, Menu, Position } from '@blueprintjs/core';
import { Popover2, Tooltip2 } from '@blueprintjs/popover2';
import { mdiMenuDown, mdiPlusThick, mdiUpload } from '@mdi/js';
import Icon from '@mdi/react';
import classNames from 'classnames';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { t } from 'ttag';
import { UserAction } from 'uf-api';
import { getActiveProjectId } from 'uf/app/selectors';
import { DataState, getData, isLoaded } from 'uf/data/dataState';
import styles from 'uf/styles/buttons.module.css';
import CanPerform from 'uf/ui/base/CanPerform/CanPerform';
import { storeMarkerPath } from 'uf/ui/base/StoreMarker';
import { IconSizeNew } from 'uf/ui/base/icon';
import useUserFlag from 'uf/ui/user/useUserFlag';
const buttonClassName = 'tw-w-full tw-shadow-none tw-inline';
const labelClassName = 'tw-flex tw-justify-start tw-items-center tw-gap-x-3';

interface Props {
  onAddLayerClick: () => void;
  onImportLayerClick: () => void;
  onFindPointsOfInterestClick: () => void;
}

export default function AddLayerMenu({
  onAddLayerClick,
  onImportLayerClick,
  onFindPointsOfInterestClick,
}: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const projectId = useSelector(getActiveProjectId);
  const showFindPointsOfInterestOption = useUserFlag('find-businesses');
  return (
    <Popover2
      isOpen={isOpen}
      enforceFocus={false}
      onClose={() => setIsOpen(false)}
      position={Position.BOTTOM_RIGHT}
      minimal
      content={
        <Menu onClick={() => setIsOpen(false)} className="tw-p-0 tw-max-w-sm">
          <Button
            className={classNames(buttonClassName, 'PreDefinedLayerOption')}
            onClick={onAddLayerClick}>
            <span className={labelClassName}>
              <Icon
                className={styles.darkGrayIcon}
                path={mdiPlusThick}
                size={1}
              />
              <span>{t`Add pre-defined layers`}</span>
            </span>
          </Button>
          {showFindPointsOfInterestOption && (
            <Button
              className={classNames(buttonClassName, styles.featuredButton)}
              onClick={onFindPointsOfInterestClick}>
              <span className={labelClassName}>
                <Icon path={storeMarkerPath} size={1} color="#0EA8CA" />
                <span>{t`Find brands & businesses`}</span>
              </span>
            </Button>
          )}
          <CanPerform verb="layer.import" resources={projectId}>
            {({ disabled, permissionsDetails }) => (
              <Tooltip2
                fill
                content={getLayerImportTooltipText(permissionsDetails)}>
                <Button
                  disabled={disabled}
                  className={classNames(buttonClassName, 'UploadLayerOption')}
                  onClick={onImportLayerClick}>
                  <span className={labelClassName}>
                    <Icon
                      className={styles.darkGrayIcon}
                      path={mdiUpload}
                      size={1}
                    />
                    <span>{t`Upload layer`}</span>
                  </span>
                </Button>
              </Tooltip2>
            )}
          </CanPerform>
        </Menu>
      }>
      <Tooltip2 content={t`Add a layer`}>
        <Button
          onClick={() => setIsOpen(true)}
          className="AddLayerManagerButton"
          intent="primary"
          rightIcon={
            <Icon path={mdiMenuDown} size={IconSizeNew.EXTRA_SMALL} />
          }>
          {t`Add`}
        </Button>
      </Tooltip2>
    </Popover2>
  );
}

function getLayerImportTooltipText(
  permissions: DataState<UserAction>[] | null,
) {
  const details =
    permissions.length && isLoaded(permissions[0])
      ? getData(permissions[0])
      : null;
  if (details && !details.can_do) {
    if (details.reason === 'subscription') {
      return t`Upgrade your subscription to enable this feature.`;
    } else if (details.reason === 'permission') {
      return t`This feature requires permission to make changes to the project.`;
    }
  }
}
