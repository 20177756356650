import * as d3Interpolate from 'd3-interpolate';
import * as d3Scale from 'd3-scale';
import * as d3Chroma from 'd3-scale-chromatic';
import _ from 'lodash';
import MaterialColors from 'material-colors';

import { SymbologyScales } from 'uf/symbology';

/**
 * Generates a d3 color scale function for numeric and categorical symbologies.
 * @param numberOfBuckets how many bins to generate
 * @param theme  theme string, eg: 'material:blue:range'
 * @param scaleType  one of 'categorical' or 'numeric', see SymbologyScales
 * @return scaleFn, a function that takes a value and returns a color
 */
export function generateThemeColorScale(
  numberOfBuckets: number,
  theme: string,
  scaleType: SymbologyScales,
): (s: any) => string {
  const { palette, color, intensity } = parseTheme(theme);

  const domain: [number, number] = [1, numberOfBuckets];
  // find either an interpolator or range that defines the colors of the theme
  let range: string[];
  let interpolator: (t: number) => string;
  switch (palette) {
    case 'material': {
      if (scaleType === SymbologyScales.NUMERIC) {
        if (isRangeTheme(theme)) {
          // Range themes with numeric scale type just need a min and a max
          range = [MaterialColors[color][100], MaterialColors[color][900]];
        } else {
          // single-value, ie: material blue 500
          range = [MaterialColors[color][intensity]];
        }
      }
      if (scaleType === SymbologyScales.CATEGORICAL) {
        if (isRangeTheme(theme)) {
          // Range themes with categorical scale type need to specify each discrete color
          range = _.range(100, 1000, 100).map(
            value => MaterialColors[color][value],
          );
        } else {
          // single-value, ie: material blue 500
          range = [MaterialColors[color][intensity]];
        }
      }
      break;
    }
    case 'd3': {
      const d3Algo = d3Chroma[color] || d3Scale[color];
      if (typeof d3Algo === 'function') {
        interpolator = d3Algo;
      } else {
        range = [...d3Algo];
      }
      break;
    }
    case 'uf': {
      // TODO add logic separate NUMERIC and CATEGORICAL once we have some category ramps.
      interpolator = d3Interpolate.interpolateRgbBasis(color.split(','));
      break;
    }
  }

  if (interpolator) {
    return d3Scale.scaleSequential(interpolator).domain(domain);
  }
  if (scaleType === SymbologyScales.NUMERIC) {
    return d3Scale
      .scaleLinear<string, string>()
      .domain(domain)
      .range(range)
      .clamp(true);
  }
  if (scaleType === SymbologyScales.CATEGORICAL) {
    return d3Scale.scaleOrdinal(range);
  }
}

export function parseTheme(theme: string) {
  const [palette, color, intensity] = theme.split(':');
  return { palette, color, intensity };
}

function isRangeTheme(theme: string) {
  return parseTheme(theme).intensity === 'range';
}

export function generateThemeColorBins(
  numberOfBuckets: number,
  theme: string,
  scaleType: SymbologyScales,
  reverse: boolean = false,
): string[] {
  const scale = generateThemeColorScale(numberOfBuckets, theme, scaleType);
  const colors = _.range(1, numberOfBuckets + 1).map(scale);
  if (reverse) {
    colors.reverse();
  }
  return colors;
}
