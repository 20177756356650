import { GeoJsonGeometryTypes } from 'geojson';
import _ from 'lodash';
import warning from 'warning';

import { LayerColumn, LayerMetadata } from 'uf-api/model/models';
import { parseFullPath } from 'uf/base/dataset';
import { assertNever } from 'uf/base/never';
import { BUILT_FORM_KEY, LAND_USE_CATEGORY_LEVEL_3 } from 'uf/builtforms';
import { ColumnKey, LayerId } from 'uf/layers';
import { DEFAULT_GEO_COLUMN_KEY, GEOMETRY_KEY } from 'uf/layers/geometryKey';
import { isPaintedLayer } from 'uf/layers/helpers';
import { getColumnMetadata, getMappableColumns } from 'uf/layers/metadata';
import {
  ColumnSymbology,
  DataDrivenColorProperty,
  DataDrivenColorStop,
  ExtrusionBreaksMethod,
  ExtrusionStyleOptions,
  isCircleSymbology,
  isFillSymbology,
  isLineSymbology,
  LayerColumnSymbology,
  SymbologyDisplayHints,
  SymbologyTypes,
} from 'uf/symbology';
import { getPaintProperty, isDataDriven } from 'uf/symbology/spec/paint';

import { isNullCategory } from 'uf/layers/nullStats';
import KeySymbologies from './styles/KeySymbologies';
import LayerSymbologies from './styles/LayerSymbologies';

export const DEFAULT_SCALE_FACTOR = 1;

export const EXTRUSION_STYLE_DEFAULTS = {
  breaksMethod: ExtrusionBreaksMethod.NoExtrusion,
  scaleFactor: DEFAULT_SCALE_FACTOR,
} as ExtrusionStyleOptions;
Object.freeze(EXTRUSION_STYLE_DEFAULTS);

/**
 * Helper function to take a layerId and optional visible layer style and return
 * what columnKey to *actually* use for styles.
 *
 * @param layerId - The primary layer id to use. This should
 *   be a well-known layerId, such as the base layer id for a painted
 *   layer.
 * @param layerMetadata for the *actual* layer, such as the painted layer.
 */
export function generateSymbologyLayerColumnKey(
  layerId: LayerId,
  layerMetadata: LayerMetadata,
  columnKey: ColumnKey,
): string {
  // User selection takes precedence.
  if (columnKey) {
    return columnKey;
  }

  // configuration in DB takes precedence over configuration in code
  if (layerMetadata?.columns) {
    // get subset of just columns with 'mappable' (categorical or numeric) metatypes
    const mappableColumns = getMappableColumns(layerMetadata);
    if (mappableColumns) {
      // do we have a default_mapping_column? If so, use it.
      const configuredMappingColumnKey =
        layerMetadata?.display_hints?.default_mapping_column;
      // default_mapping_column may not always be valid as it is defined
      // by humans and therefore prone to data entry errors. This checks it against
      // the columns in the dataset to be sure it in fact exists, and that it has
      // a valid `metatype` for symbology.
      // TODO: Remove this validation check once `default_mapping_column`
      // is validated on the backend.
      if (
        isValidDefaultMappingColumnKey(
          mappableColumns,
          configuredMappingColumnKey,
        )
      ) {
        return configuredMappingColumnKey;
      }
    }
  }

  const { type: layerType } = parseFullPath(layerId);

  if (
    isPaintedLayer(layerMetadata) ||
    layerMetadata?.display_hints?.is_urban_canvas
  ) {
    if (
      !_.isEmpty(getColumnMetadata(layerMetadata, LAND_USE_CATEGORY_LEVEL_3))
    ) {
      return LAND_USE_CATEGORY_LEVEL_3;
    }
    return BUILT_FORM_KEY;
  }

  if (layerType === 'analysis') {
    const analysisModuleKey = layerMetadata.display_hints.module_key;
    const analysisLayerKey = layerMetadata.display_hints.layer_key;
    return findColumnByKey(analysisModuleKey, analysisLayerKey);
  }

  // If no column is selected, grab a column from the preconfigured
  // layers (right now all preconfigured layers have only one column to
  // style)
  if (layerId in LayerSymbologies) {
    const columnKeys = Object.keys(LayerSymbologies[layerId]);
    warning(
      columnKeys.length === 1,
      `Got ${columnKeys.length} columns in ${layerId}, expected 1`,
    );
    return columnKeys[0];
  } else if (layerMetadata?.columns) {
    // do we have a KeySymbology for any of the columns? If so, grab the first one!
    const mappableColumn = layerMetadata.columns.find(
      ({ display_hints: { symbology_key: symbologyKey = null } = {} }) =>
        symbologyKey && !!KeySymbologies[symbologyKey],
    );
    if (mappableColumn) {
      // if the column is geometry_key, then draw without using any specific column values
      if (mappableColumn.key === GEOMETRY_KEY) {
        return DEFAULT_GEO_COLUMN_KEY;
      }
      return mappableColumn.key;
    }
  }
  return DEFAULT_GEO_COLUMN_KEY;
}

export const analysisModuleLayerDefaults: Record<
  string,
  Record<string, string>
> = {
  vmt_madison: {
    vmt_model_results: 'vmt_daily_per_hh',
    vehicle_trips_by_type: 'pct_walk_trips',
  },
  transportation_anywhere: {
    vmt_model_results: 'vmt_daily_per_hh',
    vehicle_trips_by_type: 'pct_walk_trips',
  },
  water_demand: {
    water_demand_results: 'per_hh_residential_use',
  },
  energy_demand: {
    energy_demand_results: 'per_hh_residential_energy_use',
  },
  ghg_emission: {
    ghg_emission_results: 'total_residential_ghg_per_hh',
  },
  household_cost: {
    household_cost_results: 'residential_energy_cost_per_hh',
  },
  fiscal_cost: {
    emergency_service_cost_results: 'sum_emergency_services_costs',
    network_cumulative_cost_by_type_and_by_category: 'sum_infrastructure_costs',
  },
  fiscal_cost_madison: {
    emergency_service_cost_results: 'total_emergency_service_cost',
    network_cumulative_cost_by_type_and_by_category: 'capital',
  },
  fiscal_revenue: {
    fiscal_revenue_results_endstate: 'property_tax_revenue_per_acre',
    fiscal_revenue_results_net: 'property_tax_revenue_per_acre',
    fiscal_revenue_results_new_development: 'property_tax_revenue_per_acre',
  },
  land_consumption: {
    land_consumption_map_output: 'pct_greenfield_consumed',
  },
  public_health: {
    analysis_public_health_blockgroup: 'adult_all_walk_minutes',
  },
  resiliency: {
    resiliency_affected_areas: 'fld_zone',
    resiliency_sfhl_affected_areas: 'fld_zone',
    resiliency_slr_affected_areas: 'least_slr_height_ft',
    resiliency_fire_hazard_affected_areas: 'fire_hazard_zone',
  },
  tnc_conservation: {
    tnc_conservation_categories: 'tnc_category',
  },
  transit_accessibility: {
    park_area_access_by_transit: 'park_area_access_30_min',
    population_access_by_transit: 'population_access_30_min',
    household_access_by_transit: 'household_access_30_min',
    employment_access_by_transit: 'employment_access_30_min',
    nearest_by_transit: 'park',
  },
  walk_accessibility: {
    park_area_access_by_walk: 'park_area_access_15_min',
    employment_access_by_walk: 'employment_access_15_min',
    population_access_by_walk: 'population_access_15_min',
    household_access_by_walk: 'household_access_15_min',
    nearest_by_walk: 'park',
  },
  direct_ridership: {
    transit_trip_results: 'drm_total_transit_trip',
  },
};

function findColumnByKey(analysisModuleKey: string, analysisLayerKey: string) {
  let columnKey = DEFAULT_GEO_COLUMN_KEY;

  if (analysisModuleKey in analysisModuleLayerDefaults) {
    const layerDefaults = analysisModuleLayerDefaults[analysisModuleKey];

    // See if that output layer key is specified
    if (analysisLayerKey in layerDefaults) {
      columnKey = layerDefaults[analysisLayerKey];

      // If it isn't, use the default string if it is available
    } else if (layerDefaults.default) {
      columnKey = layerDefaults.default;
    } else {
      console.warn(
        `Unkown analysis module ${analysisModuleKey}, could not resolve column key`,
      );
    }
  }
  // Otherwise default to generic results
  return columnKey;
}

function isValidDefaultMappingColumnKey(
  mappableColumns: LayerColumn[],
  defaultMappingColumnKey: string,
) {
  if (defaultMappingColumnKey === DEFAULT_GEO_COLUMN_KEY) {
    return true;
  }
  const mappableColumn = mappableColumns.find(
    column => column.key === defaultMappingColumnKey,
  );
  if (mappableColumn) {
    return true;
  }
  return false;
}

/**
 * Takes a paint property and prunes stops that are out of the bounds defined by min and max.  This
 * is necessary to ensure that curated symbologies and user created symbologies makes sense and we
 * don't create strange ui behavior.
 *
 * It is worth noting that we leave one stop below the minimum due to the Mapbox implementation of
 * stops.  All stops except the first apply their color from their value up to the next value.  The
 * first stop applies it's color to all values below the second stop. i.e.:
 *
 * [
 *   {value: 0, color: darkblue},
 *   {value: 10, color: blue},
 *   {value: 20, color: lightblue},
 *   {value: 30, color: white},
 * ]
 *
 * applies colors like this:
 * <--------0--------10--------20--------30-------->
 * <----darkblue----|--blue---|--l-blue-|--white->
 *
 * So, if we provide a min value of '1' we still want to keep the first stop so that we color all
 * values below 10 darkblue.  If the minimum is 11, we'd prune the 0 stop and color all values below
 * 20 as blue as it would not make sense to have 2 stops below the minimum value in the dataset.
 *
 * @param paintProperty the property to prune stops on
 * @param min the minimum value by which to prune
 * @param max the maximum value by which to prune
 */
export function prunePaintProperty(
  /**
   * A data-driven paint property like `fill-color`.
   */
  paintProperty: DataDrivenColorProperty,
  /**
   * The min value for the column
   */
  min: number,
  /**
   * The max value for the column
   */
  max: number,
): DataDrivenColorProperty {
  const { stops } = paintProperty;

  const newStops = Number.isFinite(max)
    ? stops.filter(({ value }) => value <= max)
    : [...stops];

  let numStopsBelowMin = Number.isFinite(min)
    ? newStops.filter(({ value }) => value < min).length
    : 0;

  while (numStopsBelowMin > 1) {
    newStops.shift();
    numStopsBelowMin -= 1;
  }

  // Sometimes the paint stops will be out of range of the min/max, ie:
  // min: 0, max: 0, stops: [100, 500, 1000, 1500]
  // In this case, return the first stop.
  if (!newStops.length) {
    newStops.push(stops[0]);
  } else if (isNullCategory(stops[0].value)) {
    newStops.splice(0, 0, stops[0]);
  }

  return {
    ...paintProperty,
    stops: newStops,
  };
}

export function getColumnOpacity(
  columnSymbology: ColumnSymbology[] = [],
): number {
  if (_.isEmpty(columnSymbology)) {
    return null;
  }

  const firstSymbology = columnSymbology.find(({ type }) =>
    symbologyTypeSupportsOpacity(type),
  );
  const type = firstSymbology?.type;
  if (!type) {
    return null;
  }

  const opacity: number = firstSymbology.paint[`${type}-opacity`];
  return opacity;
}

// MapboxGL's Style spec says that circle-radius default is 5.
// Since we don't require it be specified in symbology we should use this as the default too.
export function getPointSize(columnSymbology: LayerColumnSymbology[]): number {
  if (_.isEmpty(columnSymbology)) {
    return 5;
  }

  const firstSymbology = columnSymbology[0];
  const pointSize: number = firstSymbology.paint['circle-radius'];

  return typeof pointSize === 'number' ? pointSize : 5;
}

export function getColumnSymbologyScale(
  columnSymbology: ColumnSymbology[] = [],
  type?: SymbologyTypes,
): SymbologyDisplayHints['scale'] {
  if (_.isEmpty(columnSymbology)) {
    warning(!!columnSymbology, 'symbology is missing!');
    return null;
  }

  let symbology = columnSymbology[0];

  if (type === SymbologyTypes.FILL) {
    symbology = columnSymbology.find(isFillSymbology);
  }

  if (type === SymbologyTypes.LINE) {
    symbology = columnSymbology.find(isLineSymbology);
  }

  if (type === SymbologyTypes.CIRCLE) {
    symbology = columnSymbology.find(isCircleSymbology);
  }

  const symbologyScale = symbology?.display_hints?.scale;

  return symbologyScale;
}

export function getSymbologyRampIsReversed(
  symbologies: LayerColumnSymbology[] = [],
  ufGeometryType: GeoJsonGeometryTypes,
): boolean {
  const symbology = getCanonicalSymbology(symbologies, ufGeometryType);
  if (!symbology) {
    return false;
  }

  return !!symbology.display_hints.is_reverse_theme;
}

/**
 * Gets the correct symbology for looking up data-driven properties.
 * We need this because Polygons have two symbologies, Fill and Line.
 *
 * @param symbologies
 * @param ufGeometryType
 */
export function getCanonicalSymbology(
  symbologies: LayerColumnSymbology[],
  ufGeometryType: GeoJsonGeometryTypes,
): LayerColumnSymbology {
  const fillSymbology = symbologies.find(({ type }) => type === 'fill');
  const lineSymbology = symbologies.find(
    ({ type }) => type === 'line' || type === 'symbol',
  );
  const circleSymbology = symbologies.find(({ type }) => type === 'circle');

  switch (ufGeometryType) {
    case 'MultiPolygon':
    case 'Polygon':
      return fillSymbology;

    case 'MultiLineString':
    case 'LineString':
      return lineSymbology;

    case 'MultiPoint':
    case 'Point':
      return circleSymbology;

    case 'GeometryCollection':
      warning(
        false,
        `Unknown uf_geometry_type ${ufGeometryType}, falling back to fill`,
      );
      return fillSymbology;

    default:
      return assertNever(ufGeometryType);
  }
}

export function getColumnSymbologyScaleForLayerType(
  columnSymbology: LayerColumnSymbology[] = [],
  ufGeometryType: GeoJsonGeometryTypes,
): SymbologyDisplayHints['scale'] {
  const symbology = getCanonicalSymbology(columnSymbology, ufGeometryType);
  const symbologyScale = symbology?.display_hints?.scale;

  return symbologyScale;
}

export function getColumnSymbologyDistribution(
  columnSymbology: ColumnSymbology[] = [],
): SymbologyDisplayHints['distribution'] {
  const firstSymbology = columnSymbology[0];
  const distribution = firstSymbology?.display_hints?.distribution;

  return distribution;
}

export function getColumnSymbologyNumStops(
  columnSymbology: LayerColumnSymbology[] = [],
): number {
  const firstSymbology = columnSymbology[0];
  if (!firstSymbology) {
    return 0;
  }

  const paintProperty = getPaintProperty<
    DataDrivenColorStop,
    DataDrivenColorProperty
  >(firstSymbology, 'color');

  if (isDataDriven<DataDrivenColorProperty>(paintProperty)) {
    return paintProperty?.stops?.length ?? 0;
  }

  return 0;
}

export function getColumnSymbologyTheme(
  columnSymbology: LayerColumnSymbology[] = [],
): SymbologyDisplayHints['theme'] {
  const firstSymbology = columnSymbology[0];
  const symbologyTheme = firstSymbology?.display_hints?.theme;

  return symbologyTheme;
}

export function getShouldStyleZeroAsTransparent(
  columnSymbology: ColumnSymbology[] = [],
): boolean {
  const firstSymbology = columnSymbology[0];
  if (!firstSymbology) {
    // show zero by default
    return false;
  }

  return !firstSymbology?.display_hints?.showZero;
}

export function getExtrusionStyle(
  columnSymbology: ColumnSymbology[] = [],
): ExtrusionStyleOptions {
  const firstSymbology = columnSymbology[0];
  return firstSymbology?.display_hints?.extrusion_options;
}

export function symbologiesContainExtrusionStyles(
  symbologies: LayerColumnSymbology[],
) {
  return _.some(symbologies, symbology => isExtrusionSymbology(symbology));
}

export function isExtrusionSymbology(symbology: LayerColumnSymbology) {
  const {
    display_hints: {
      extrusion_options: { breaksMethod } = EXTRUSION_STYLE_DEFAULTS,
    },
  } = symbology;
  return (
    isFillSymbology(symbology) &&
    breaksMethod !== ExtrusionBreaksMethod.NoExtrusion
  );
}

type OPACITY_TYPES = Exclude<SymbologyTypes, SymbologyTypes.SYMBOL>;
const OPACITY_TYPES: readonly OPACITY_TYPES[] = Object.freeze([
  SymbologyTypes.CIRCLE,
  SymbologyTypes.LINE,
  SymbologyTypes.FILL,
]);
type COLOR_TYPES = Exclude<SymbologyTypes, SymbologyTypes.SYMBOL>;
const COLOR_TYPES: readonly COLOR_TYPES[] = Object.freeze([
  SymbologyTypes.CIRCLE,
  SymbologyTypes.LINE,
  SymbologyTypes.FILL,
]);
export function symbologyTypeSupportsOpacity(
  layerType: SymbologyTypes,
): layerType is OPACITY_TYPES {
  return OPACITY_TYPES.includes(layerType as OPACITY_TYPES);
}
export function symbologyTypeSupportsColor(
  layerType: SymbologyTypes,
): layerType is COLOR_TYPES {
  return COLOR_TYPES.includes(layerType as COLOR_TYPES);
}
