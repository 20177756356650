import _, { Dictionary } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { t } from 'ttag';
import {
  useGetProjectTypeLayersQuery,
  useGetProjectTypesQuery,
} from 'uf-api-rtk/store/Api';
import { getActiveOrganizationKey, getActiveProject } from 'uf/app/selectors';
import { ensureLayerData } from 'uf/explore/actions/layers';
import { loadProject } from 'uf/projects/actions';
import getCategoryLabel from 'uf/projects/layerCategories';
import { makeGetProjectState } from 'uf/projects/selectors';
import { makeUseEnsure } from 'uf/ui/data/useEnsure';
import { usePreferredOrgKey } from 'uf/ui/user/hooks';

const useProject = makeUseEnsure(
  loadProject,
  makeGetProjectState,
  projectId => ({ projectId }),
  projectId => !!projectId,
);

const useCensusStates = () => {
  const dispatch = useDispatch();
  const [censusStates, setCensusStates] = useState([]);
  const getCensusStateData = useCallback(async () => {
    const response = await dispatch(
      ensureLayerData('/public/dataset/census_tiger_state', {}),
    );
    setCensusStates(
      response
        .map(({ statefp, name }) => ({
          value: statefp,
          name,
        }))
        .sort((a, b) => {
          if (a.name > b.name) {
            return 1;
          } else if (a.name < b.name) {
            return -1;
          }
          return 0;
        }),
    );
  }, [dispatch]);
  useEffect(() => {
    getCensusStateData();
  }, [dispatch, getCensusStateData]);
  return censusStates;
};

const useBoundaryTypes = () => {
  // Ideally we will be able to programmatically grab the boundary types in the future
  const defaultBoundaryTypes = useMemo(
    () => [
      { name: t`CBSAs`, value: 'CBSAs' },
      {
        name: t`Congressional Districts (116th)`,
        value: 'Congressional Districts (116th)',
      },
      { name: t`Counties`, value: 'Counties' },

      { name: t`MPOs`, value: 'MPOs' },
      { name: t`Places`, value: 'Places' },
      {
        name: t`School Districts (Elementary)`,
        value: 'School Districts (Elementary)',
      },
      {
        name: t`School Districts (Secondary)`,
        value: 'School Districts (Secondary)',
      },
      {
        name: t`School Districts (Unified)`,
        value: 'School Districts (Unified)',
      },
      { name: t`States`, value: 'States' },
      { name: t`Regions`, value: 'Regions' },
    ],
    [],
  );
  return defaultBoundaryTypes;
};

const useProjectTypes = (orgKey?: string) => {
  const [currentOrgKey] = usePreferredOrgKey();
  const { data, ...status } = useGetProjectTypesQuery(
    { orgKey: orgKey || currentOrgKey },
    { skip: !(orgKey || currentOrgKey) },
  );
  const projectTypes = useMemo(
    () => _.keyBy(data?.items, 'key'),
    [data?.items],
  );
  return {
    projectTypes,
    status,
  };
};

const useProjectType = (projectTypeKey?: string) => {
  const activeProject = useSelector(getActiveProject);
  const [orgKey] = usePreferredOrgKey();
  const activeProjectType = activeProject.project_type;
  // We want all the project types a user has access to, here
  const { data, ...status } = useGetProjectTypesQuery({ orgKey });
  const projectTypes = useMemo(
    () => _.keyBy(data?.items, 'key'),
    [data?.items],
  );
  // Default to active project type if no key is provided
  return {
    projectType: projectTypes[projectTypeKey || activeProjectType],
    status,
  };
};

const useProjectTypeLayers = (
  projectTypeKey: string,
): {
  layersByCategory: Dictionary<{ category: string; layers: string[] }>;
  status: any;
} => {
  const orgKey = useSelector(getActiveOrganizationKey);
  const { data, ...status } = useGetProjectTypeLayersQuery(
    { projectTypeKey, orgKey },
    { skip: !projectTypeKey || !orgKey },
  );
  const layersByCategory = _.reduce(
    data?.items,
    (acc, layer) => {
      const { category, name } = layer;
      // Create a formatted key based on the category
      const categoryKey = _.camelCase(category);
      const currentCategory = acc[categoryKey];
      let categoryData = {};
      // If category already exists with a layer, update the layer list
      if (currentCategory) {
        categoryData = {
          ...currentCategory,
          layers: [...currentCategory.layers, name],
        };
        // If that category doesn't exist add the category with the layer
      } else {
        categoryData = {
          category: getCategoryLabel(category),
          layers: [name],
        };
      }
      return {
        ...acc,
        [categoryKey]: categoryData,
      };
    },
    {},
  );
  return {
    layersByCategory,
    status,
  };
};

export {
  useBoundaryTypes,
  useCensusStates,
  useProject,
  useProjectType,
  useProjectTypeLayers,
  useProjectTypes,
};
