// export function makeEnsureActionCreator<T = any, A extends any[] = any[]>(
//   loadActionCreator: (...args: A) => ThunkAction<Promise<T>, any, any, any>,
//   keySelector: (state: any, ownProps: any) => DataState<T>,
//   makeOwnProps: OwnPropsMaker<A, any> = encodeArguments,
// ): (...args: A) => ThunkAction<Promise<T>, any, any, any> {
// }

import { useCallback, useEffect, useMemo } from 'react';
import { useSelector, useStore } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { ParametricSelector } from 'reselect';
import { risonEncode } from 'uf/base/rison';
import { SwaggerThunkExtra } from 'uf/base/xhr';
import { DataState, EmptyKeyedState, shouldLoad } from 'uf/data/dataState';
import { encodeArguments, OwnPropsMaker } from 'uf/data/loader';
import { UFState } from 'uf/state';
import useBindAction from 'uf/ui/base/useBindAction/useBindAction';

/**
 * Create a lazy-loading hook.
 * Returns a hook that returns a DataState.
 *
 * use:
 *
 * ```
 * export const useLayerStats = makeUseEnsure(
 *   loadLayerStats,
 *   makeGetLayerStatsByKey,
 *   getFilterSearchKey,
 * );
 * ```
 *
 * Then later in your component:
 *
 * ```
 * const statsState = useLayerStats(layerId, { filters, row_count: true });
 * ```
 *
 * @param loadActionCreator the function which
 */
export function makeUseEnsure<T, A extends any[], TAR, OP extends {} = any>(
  loadActionCreator: (
    ...args: A
  ) => ThunkAction<TAR, UFState, SwaggerThunkExtra, AnyAction>,
  keySelectorFactory: () => ParametricSelector<UFState, OP, DataState<T>>,
  makeOwnProps: OwnPropsMaker<A, OP> = encodeArguments,
  isValid: (...args: A) => boolean = alwaysTrue,
) {
  function useEnsureLoadHook(...args: A) {
    const isValidCall = isValid(...args);
    const ownProps: string | OP = useMemo(() => {
      const props = makeOwnProps(...args);
      if (typeof props === 'string') {
        // this casting is a hack, but there's no clean way around it!
        return { key: props } as unknown as OP;
      }
      return props;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...args]);

    // we need a shallow-compare of args here in order to account for
    // array or object arguments. However, useEffect does not support shallow
    // comparisons in the dependency, so this guarantees we have a stable string.
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const encodedArgs = useMemo(() => risonEncode(args), [...args]);
    const load = useBindAction(loadActionCreator);
    const { currentValue, getLatestValue } = useMakeSelectorGetter<
      any,
      OP,
      DataState<T>
    >(keySelectorFactory, ownProps, encodedArgs, {
      promiseFactory: () => load(...args) as unknown as Promise<T>,
    });
    // const dataState = getDataState();
    useEffect(() => {
      const shouldCallLoad = isValidCall && shouldLoad(getLatestValue());
      if (shouldCallLoad) {
        try {
          load(...args);
        } catch (ex) {
          // Report the error but do not explode
          Promise.reject(ex);
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isValidCall, load, encodedArgs, getLatestValue]);
    if (!isValidCall) {
      return EmptyKeyedState as DataState<T>;
    }
    return { ...currentValue };
  }
  return useEnsureLoadHook;
}

function alwaysTrue() {
  return true;
}

/**
 * A super-cached version of useSelector that returns a function with a
 * new value every time
 *
 * Usage:
 * ```
 * const getData = useMakeSelectorGetter(makeGetFooGetter, {props});
 * ```
 */
function useMakeSelectorGetter<S, P, R>(
  makeSelector: () => ParametricSelector<S, P, R>,
  props: P,
  serializedProps: string,
  extra: Partial<R>,
) {
  const selector = useMemo(makeSelector, [makeSelector]);
  const store = useStore();
  const currentValue = useSelector<S>(state => selector(state, props)) as R;
  // Need to do two levels of caching because simply `() => () => selector(..)`
  // will still result in a new inner function on every invocation, which will
  // cause useSelector to think that state has changed every time, so that
  // `getValue` will change on ever render.
  const innerGetter = useCallback(
    () => selector(store.getState(), props),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [serializedProps, selector, store],
  );
  const cachedSelectorGetter = useCallback(() => innerGetter, [innerGetter]);
  const getLatestValue: () => R = useSelector(cachedSelectorGetter);
  return useMemo(
    () => ({
      currentValue: { ...currentValue, ...extra },
      getLatestValue,
    }),
    [currentValue, getLatestValue, extra],
  );
}
