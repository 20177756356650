import {
  CuratedColumnSymbology,
  DistributionTypes,
  InterpolationTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const censusPlanningdatabaseRaceLayers = [
  {
    layerId: '/public/dataset/us_census_census_planning_database_2018_race',
    columnKey: 'pct_hispanic_acs_12_16',
  },
  {
    layerId: '/public/dataset/us_census_census_planning_database_2019_race',
    columnKey: 'pct_hispanic_acs_13_17',
  },
  {
    layerId: '/public/databank/cpd_2020_race_ethnicity',
    columnKey: 'pct_hispanic_acs_14_18',
  },
  {
    layerId: '/public/databank/cpd_2021_race_ethnicity',
    columnKey: 'pct_hispanic_acs_15_19',
  },
];

const symbologies: Record<
  string,
  Record<string, CuratedColumnSymbology[]>
> = {};
censusPlanningdatabaseRaceLayers.forEach(({ layerId, columnKey }) => {
  symbologies[layerId] = {
    [columnKey]: [
      {
        display_hints: {
          distribution: DistributionTypes.DEFAULT,
          interpolation: InterpolationTypes.INTERVAL,
          scale: SymbologyScales.NUMERIC,
          showZero: true,
          theme: 'd3:interpolateRdPu',
        },
        paint: {
          'fill-color': {
            property: columnKey,
            stops: [
              {
                color: 'rgb(255, 247, 243)',
                value: 0,
              },
              {
                color: 'rgb(253, 226, 223)',
                value: 10,
              },
              {
                color: 'rgb(252, 202, 200)',
                value: 20,
              },
              {
                color: 'rgb(251, 171, 184)',
                value: 30,
              },
              {
                color: 'rgb(248, 128, 170)',
                value: 40,
              },
              {
                color: 'rgb(234, 81, 157)',
                value: 50,
              },
              {
                color: 'rgb(204, 35, 142)',
                value: 60,
              },
              {
                color: 'rgb(162, 5, 126)',
                value: 70,
              },
              {
                color: 'rgb(117, 1, 117)',
                value: 80,
              },
              {
                color: 'rgb(73, 0, 106)',
                value: 90,
              },
            ],
            type: 'interval',
          },
          'fill-opacity': 0.5,
        },
        type: SymbologyTypes.FILL,
      },
      {
        display_hints: {
          distribution: DistributionTypes.DEFAULT,
          interpolation: InterpolationTypes.INTERVAL,
          scale: SymbologyScales.NUMERIC,
          showZero: true,
          theme: 'custom',
        },
        paint: {
          'line-color': '#333333',
          'line-opacity': 0.5,
          'line-width': 0.5,
        },
        type: SymbologyTypes.LINE,
      },
    ],
  };
});

export default symbologies;
