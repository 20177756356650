import {
  CuratedColumnSymbology,
  InterpolationTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/public/dataset/florida_dot_bicycle_lanes_fl': {
    descr: [
      {
        display_hints: {
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          is_reverse_theme: true,
          scale: SymbologyScales.CATEGORICAL,
          theme: 'd3:schemeCategory20',
        },
        paint: {
          'line-color': {
            default: '#e377c2',
            property: 'descr',
            stops: [
              {
                color: '#98df8a',
                value: 'BOTH 2 AND 3',
              },
              {
                color: '#2ca02c',
                value: 'BUFFERED',
              },
              {
                color: '#ffbb78',
                value: 'COLORED',
              },
              {
                color: '#ff7f0e',
                value: 'DESIGNATED',
              },
              {
                color: '#aec7e8',
                value: 'SHARROW',
              },
              {
                color: '#1f77b4',
                value: 'UNDESIGNATED (OBSOLETE)',
              },
            ],
            type: 'categorical',
          },
          'line-opacity': 0.9,
          'line-width': 2,
        },
        type: SymbologyTypes.LINE,
      },
    ],
    road_side: [
      {
        display_hints: {
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          scale: SymbologyScales.CATEGORICAL,
          theme: 'd3:schemeCategory20',
        },
        paint: {
          'line-color': {
            default: '#d62728',
            property: 'road_side',
            stops: [
              {
                color: '#1f77b4',
                value: 'C',
              },
              {
                color: '#aec7e8',
                value: 'L',
              },
              {
                color: '#ff7f0e',
                value: 'R',
              },
            ],
            type: 'categorical',
          },
          'line-opacity': 0.9,
          'line-width': 2,
        },
        type: SymbologyTypes.LINE,
      },
    ],
  },
};

export default symbologies;
