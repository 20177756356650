import classNames from 'classnames';
import React, { CSSProperties, ReactNode } from 'react';
import { t } from 'ttag';

import { ErrorAny } from 'uf/base/types';
import rootStyles from 'uf/styles/root.module.css';

/**
 * Cover all the various shapes of error objects.
 * TODO: Define this on the swagger side
 */
export interface ErrorObj extends Error {
  title?: string;
  detail?: string;
  traceback?: string;
}

const tooltipDetailClassName = classNames(
  rootStyles.maxHeightLarge,
  rootStyles.maxWidthExtraLarge,
  rootStyles.scrollY,
);
/**
 * Extract details from an error object, that may come in many forms. Results
 * are in an object that contains:
 *
 *  - `errorMessage` - The basic message to show to the user
 *  - `errorDetail` - Further detail to show the user
 *  - `errorDetailRaw` - Raw error string to show in a developer-only context.
 *
 * TODO: futher refctor this to return only strings, letting someone else format
 * them better.
 */
export function extractErrorDetails(error: ErrorAny) {
  let errorMessage: ReactNode = null;
  let errorDetail: JSX.Element | string;
  let errorDetailRaw: string = null;

  if (error) {
    if (isError(error)) {
      // May be null
      errorDetail = errorDetailRaw = error.detail;
      if (error.traceback) {
        errorDetail = (
          <div>
            <div className={tooltipDetailClassName}>{errorDetail}</div>
            <pre className={tooltipDetailClassName} style={tracebackStyle}>
              {error.traceback}
            </pre>
          </div>
        );
      }
      // For browser-level timeouts, we get no useful message
      if (
        (error.name === 'TypeError' && error.message === 'Failed to fetch') ||
        error.name === 'NetworkError'
      ) {
        errorMessage = t`Network Error`;
        if (!errorDetail) {
          // Provide some better error detail
          errorDetail = (
            <div>
              {t`There was an error getting data from the server. Please try again.`}
            </div>
          );
        }
        // If error is an object, extract it's message and detail, if any
      } else if (error.message || error.title) {
        errorMessage = error.message || error.title;
      } else {
        // coerce to string if it isn't already
        errorMessage = `${error}`;
      }
    } else {
      errorDetailRaw = errorMessage = error;
    }
  }
  if (!errorDetailRaw) {
    errorDetailRaw = `${errorMessage}`;
  }

  return { errorDetail, errorDetailRaw, errorMessage };
}

function isError(error: any): error is ErrorObj {
  return (
    error instanceof Error ||
    (typeof error === 'object' &&
      ('title' in error ||
        'detail' in error ||
        'traceback' in error ||
        'stack' in error ||
        'message' in error))
  );
}

const tracebackStyle: CSSProperties = {
  width: '80em',
  textAlign: 'left',
};
