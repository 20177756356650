import { createSlice } from '@reduxjs/toolkit';
import { logEvent } from 'uf/logging';
import { events } from './logging';

export interface ProjectDialogState {
  isOpen: boolean;
}

const initialState: ProjectDialogState = {
  isOpen: false,
};

export const projectDialogSlice = createSlice({
  name: 'projectDialog',
  initialState,
  reducers: {
    dialogClosed: state => {
      state.isOpen = false;
    },
    dialogOpened: state => {
      state.isOpen = true;
    },
  },
});

export const { dialogClosed, dialogOpened } = projectDialogSlice.actions;

export const openProjectDialog = source => dispatch => {
  logEvent(events.CREATE_PROJECT_STARTED, {
    source,
  });
  dispatch(dialogOpened());
};

export const selectProjectDialog = state => state.projects.projectDialog;

export default projectDialogSlice.reducer;
