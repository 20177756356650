/**
 * UrbanFootprint App API
 * API for serving UrbanFootprint Application
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Return True when `value` starts with `prefix`.
 */
export interface ExpressionStartsWith<Tvalue extends object = any> {
  /**
   * If the value is case sensitive. (default is case insensitive)
   */
  case_sensitive?: boolean;
  /**
   * Expression function, set to \"startswith\".
   */
  fn: ExpressionStartsWith.FnEnum;
  /**
   * the prefix to use
   */
  prefix: string;
  /**
   * the value to test
   */
  value: Tvalue;
}
export namespace ExpressionStartsWith {
  export type FnEnum = 'startswith';
  export const FnEnum = {
    Startswith: 'startswith',
  } as const;
}
