import { Dispatch } from 'redux';

import { AppMode } from 'uf/app/modes';
import { setUIProperty } from 'uf/explore/actions';
import { SetInitialRoute } from 'uf/routing/state';

export const setInitialExploreRoute: SetInitialRoute = (
  location,
  query,
  dispatch: Dispatch,
) => {
  dispatch(setUIProperty('exploreViewVisible', true));

  if (query.mode === AppMode.Paint) {
    dispatch(setUIProperty('paintUIVisible', true));
    dispatch(setUIProperty('chartPanelVisible', true));
  } else if (query.mode === AppMode.Analyze) {
    dispatch(setUIProperty('analysisUIVisible', true));
    dispatch(setUIProperty('chartPanelVisible', true));
  } else if (query.maponly === '1') {
    dispatch(setUIProperty('exploreViewVisible', false));
  }
};
