import {
  ADD_JOINED_LAYER_FILTER,
  CLEAR_BUFFER_FILTER,
  CLEAR_COLUMN_FILTERS,
  CLEAR_FILTERS,
  CLEAR_GEOMETRY_FILTERS,
  CLEAR_JOINED_LAYER_FILTER,
  CLEAR_POINT_FILTER,
  CLEAR_SELECTION_FILTERS,
  ENABLE_FILTERS,
  UPDATE_BUFFER_FILTER,
  UPDATE_COLUMN_FILTER,
  UPDATE_GEOMETRY_FILTERS,
  UPDATE_JOIN_LAYER_METHOD,
  UPDATE_POINT_FILTER,
} from 'uf/explore/ActionTypes';
import { FilterSpec } from 'uf/layers/filters';
import { makeFilterReducer } from 'uf/layers/reducer/makeFilterReducer';

export const initialState: Record<string, Partial<FilterSpec>> = {};

const filters = makeFilterReducer(
  ADD_JOINED_LAYER_FILTER,
  CLEAR_BUFFER_FILTER,
  CLEAR_COLUMN_FILTERS,
  CLEAR_FILTERS,
  CLEAR_GEOMETRY_FILTERS,
  CLEAR_POINT_FILTER,
  CLEAR_SELECTION_FILTERS,
  ENABLE_FILTERS,
  UPDATE_BUFFER_FILTER,
  UPDATE_COLUMN_FILTER,
  UPDATE_GEOMETRY_FILTERS,
  UPDATE_POINT_FILTER,
  UPDATE_JOIN_LAYER_METHOD,
  CLEAR_JOINED_LAYER_FILTER,
);
export default filters;
