import _ from 'lodash';

import {
  BUILT_FORM_TYPES,
  BuiltFormInfo,
  prefixBuiltFormTypeKey,
} from 'uf/builtforms';
import { DataDrivenColorStop } from 'uf/symbology';

/**
 * Grabs the built form infos that exist in colorStops.
 */
export function getBuiltFormInfosFromColorStops<T extends BuiltFormInfo>(
  builtFormInfos: T[],
  colorStops: DataDrivenColorStop[],
  builtFormType: BUILT_FORM_TYPES,
): T[] {
  const matchingBuiltFormInfos = _.intersectionWith(
    builtFormInfos,
    colorStops,
    (builtFormInfo, colorStop) =>
      prefixBuiltFormTypeKey(builtFormInfo, builtFormType).key ===
      colorStop.value,
  );
  return matchingBuiltFormInfos;
}

/**
 * Updates the given built form infos with the new color values.
 */
export function updateBuiltFormInfoColors<T extends BuiltFormInfo>(
  builtFormInfos: T[],
  colorStops: DataDrivenColorStop[],
  builtFormType: BUILT_FORM_TYPES,
): T[] {
  return builtFormInfos.map(builtFormInfo => {
    const { color } = colorStops.find(
      stop =>
        stop.value === prefixBuiltFormTypeKey(builtFormInfo, builtFormType).key,
    );

    return { ...builtFormInfo, style: { fill: { color } } };
  });
}
