import _ from 'lodash';

import { ExtrusionStyleOptions, LayerColumnSymbology } from 'uf/symbology';

/**
 * Returns a new copy of the given symbology with the new extrusion setting applied
 *
 * @param symbology - a single symbology from a LayerColumnSymbology
 * @param newProperties - additional hints
 * @param newProperties.extrusion - Whether to extrude data and how
 * @return updatedSymbology - a copy of `symbology` with the new extrusion setting applied
 */
export default function updateExtrusion(
  symbology: LayerColumnSymbology,
  { extrusion }: { extrusion: ExtrusionStyleOptions },
): LayerColumnSymbology {
  if (_.isEqual(extrusion, symbology?.display_hints?.extrusion_options)) {
    return symbology;
  }
  return {
    ...symbology,
    display_hints: {
      ...symbology.display_hints,
      extrusion_options: {
        ...symbology.display_hints.extrusion_options,
        ...extrusion,
      },
    },
  };
}
