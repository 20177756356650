import { Button, InputGroup } from '@blueprintjs/core';
import { boundMethod } from 'autobind-decorator';
import classNames from 'classnames';
import React, { Component } from 'react';
import { Box } from 'react-layout-components';
import { t } from 'ttag';

import rootStyles from 'uf/styles/root.module.css';
import textStyles from 'uf/styles/text.module.css';

const clearButtonClass = classNames(
  rootStyles.noPadding,
  rootStyles.thinMarginLeft,
  textStyles.activeColor,
);

const layerManagerClassName = classNames(
  rootStyles.mediumMarginVertical,
  rootStyles.veryThinPaddingHorizontal,
  'LayerManager-search',
);

interface OwnProps {
  value: string;
  onSearchInputChange: (value: string) => void;
}

type LayerManagerSearchProps = OwnProps;

export default class LayerManagerSearch extends Component<LayerManagerSearchProps> {
  // this should be event: FormEvent<FormControl> except react's
  // EventTarget interface is empty so we get:
  // 'Property 'value' does not exist on target' error
  @boundMethod
  onInputChange(event: any) {
    this.props.onSearchInputChange(event.target.value);
  }

  @boundMethod
  onButtonClick() {
    this.props.onSearchInputChange('');
  }

  render() {
    const { value } = this.props;

    const clearButton = (
      <Button
        name="clearSearch"
        minimal
        small
        className={clearButtonClass}
        onClick={this.onButtonClick}
        icon="cross"
      />
    );

    return (
      <Box column className={layerManagerClassName}>
        <InputGroup
          name="layerName"
          large
          leftIcon="search"
          placeholder={t`Search for layers`}
          value={value}
          onChange={this.onInputChange}
          rightElement={clearButton}
          autoFocus
        />
      </Box>
    );
  }
}
