import { Spinner, SpinnerSize } from '@blueprintjs/core';
import classNames from 'classnames';
import React, { FunctionComponent } from 'react';
import { Box } from 'react-layout-components';

import { getData, hasError, isLoading } from 'uf/data/dataState';
import { LayerId } from 'uf/layers';
import { ProjectId } from 'uf/projects';
import rootStyles from 'uf/styles/root.module.css';
import ErrorMessage from 'uf/ui/base/ErrorMessage/ErrorMessage';
import { useLayerMetadata } from 'uf/ui/layers/hooks';
import { useIsLayerFavoritable } from 'uf/ui/layers/LayerListItem/hooks';

import { LayerColumns } from './LayerColumns';
import { LayerHeader } from './LayerHeader';

interface OwnProps {
  projectId: ProjectId;
  layerId: LayerId;
  lintMode?: boolean;
  showTitle?: boolean;
  displayPanelView?: boolean;
  charLimit?: number;
  onSearchInputChangeByTag?: (value: string) => void;
}

export type LayerMetadataSummaryProps = OwnProps;

const summaryClassName = classNames(
  rootStyles.thickPadding,
  'tw-overflow-y-scroll',
);
const LayerMetadataSummary: FunctionComponent<LayerMetadataSummaryProps> =
  props => {
    const {
      showTitle,
      displayPanelView,
      layerId,
      projectId,
      charLimit,
      onSearchInputChangeByTag,
    } = props;

    const layerMetadataState = useLayerMetadata(layerId);
    const layerMetadata = getData(layerMetadataState, null);
    const isLayerFavoritable = useIsLayerFavoritable(projectId, layerId);
    if (isLoading(layerMetadataState)) {
      return (
        <Box fit center>
          <Spinner size={SpinnerSize.SMALL} />
        </Box>
      );
    }

    if (!layerMetadata || !layerMetadataState) {
      return null;
    }

    if (hasError(layerMetadataState)) {
      return <ErrorMessage error={layerMetadataState.error} />;
    }

    return (
      <Box flex={1} column className={summaryClassName}>
        <LayerHeader
          isLayerFavoritable={isLayerFavoritable}
          layerMetadata={layerMetadata}
          showTitle={showTitle}
          displayPanelView={displayPanelView}
          onSearchInputChangeByTag={onSearchInputChangeByTag}
        />
        <LayerColumns
          charLimit={charLimit}
          layerMetadata={layerMetadata}
          displayPanelView={displayPanelView}
        />
      </Box>
    );
  };

export default LayerMetadataSummary;
