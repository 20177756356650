import assert from 'assert';
import { t } from 'ttag';
import { ProjectMetadata, ScenarioMetadata } from 'uf-api';
import { assertNever } from 'uf/base/never';
import { Expression } from 'uf/layers/filters';
import { BoundarySources, CanvasTypes } from 'uf/projects';
import { CreateProjectRequest } from 'uf/projects/actions';
import { makeCanvasName, makeFeatureBoundary } from 'uf/projects/create';
import { CreateProjectFormState } from 'uf/ui/projects/CreateProjectForm/CreateProjectForm';

interface WellKnownArea {
  projectAreaLayerId: string;
  projectAreaLayerFilter: Expression;
  projectCanvasName: string;
  projectCanvasTypeKey: CanvasTypes;
}

interface UploadedArea {
  projectAreaUploadKey: string;
  projectCanvasName: string;
  projectCanvasTypeKey: CanvasTypes;
}

interface SourceProjectArea {
  projectAreaLayerId: string;
}

type ProjectAreaParams = WellKnownArea | UploadedArea | SourceProjectArea;

const getProjectAreaParams = (
  formData: CreateProjectFormState,
  project: ProjectMetadata,
): ProjectAreaParams => {
  const { projectBoundarySource } = formData;
  switch (projectBoundarySource) {
    case BoundarySources.WELL_KNOWN: {
      const { projectArea } = formData;
      if (projectArea) {
        const { projectCanvasTypeKey } = formData;
        const featureBoundary = makeFeatureBoundary(projectArea);
        return {
          projectAreaLayerId: featureBoundary.layerId,
          projectAreaLayerFilter: featureBoundary.filter,
          projectCanvasName: featureBoundary.canvasName,
          projectCanvasTypeKey,
        };
      }
      break;
    }
    case BoundarySources.USER_UPLOAD: {
      const { projectAreaUploadKey } = formData;
      if (projectAreaUploadKey) {
        const { projectCanvasTypeKey, projectCanvasName } = formData;
        return {
          projectAreaUploadKey,
          projectCanvasName: makeCanvasName(projectCanvasName),
          projectCanvasTypeKey,
        };
      }
      break;
    }
    case BoundarySources.SOURCE_PROJECT: {
      return {
        projectAreaLayerId: project.project_area_layer.full_path,
        projectCanvasName: t`Base Canvas`,
      };
    }
    default:
      assertNever(
        projectBoundarySource,
        `Unknown project boundary source detected: ${projectBoundarySource}`,
      );
      break;
  }

  return null;
};

const getContextAreaParams = (
  formData: CreateProjectFormState,
  project: ProjectMetadata,
): {
  contextAreaLayerId: string;
  contextAreaLayerFilter?: Expression;
  contextCanvasTypeKey: 'parcel' | 'block';
} => {
  const { contextBoundarySource, contextArea, contextCanvasTypeKey } = formData;

  switch (contextBoundarySource) {
    case BoundarySources.WELL_KNOWN: {
      if (!contextArea) {
        return null;
      }
      const featureBoundary = makeFeatureBoundary(contextArea);
      return {
        contextAreaLayerId: featureBoundary.layerId,
        contextAreaLayerFilter: featureBoundary.filter,
        contextCanvasTypeKey,
      };
    }
    case BoundarySources.SOURCE_PROJECT:
      return {
        contextAreaLayerId: project.context_area_layer.full_path,
        contextCanvasTypeKey,
      };
    case BoundarySources.USER_UPLOAD:
      return null;
    default:
      assertNever(
        contextBoundarySource,
        `Unknown boundary source ${contextBoundarySource}`,
      );
  }
};

const makeProjectRequest = (
  name: string,
  description: string,
  organizationKey: string,
  formData: CreateProjectFormState,
  project: ProjectMetadata,
  scenario: ScenarioMetadata,
  intersectionMethod: 'centroid' | 'boundary',
  projectType: string,
): CreateProjectRequest => {
  const request: CreateProjectRequest = {
    name,
    description,
    organizationKey,
    ...getProjectAreaParams(formData, project),
    ...getContextAreaParams(formData, project),
    projectType,
    projectRole: formData.projectRole,
    intersectionMethod: intersectionMethod as any,
  };
  assert(intersectionMethod);
  if (project && scenario) {
    const canvas = scenario.base_scenario
      ? scenario.base_edits_painted_canvas
      : scenario.painted_uf_canvas;
    request.projectCanvasLayer = canvas;
    request.builtFormsLibraryId = project.built_forms_library;
  }
  return request;
};

export { makeProjectRequest, getProjectAreaParams, getContextAreaParams };
