import { useCallback, useRef } from 'react';
import shallowEqual from 'recompose/shallowEqual';

/**
 * Create a callback that never calls with the same arguments twice in a row:
 *
 * ```
 * const onStatusChange = useUniqCallback(status => {
 *   console.log('new status for', foo, ' is ', status)
 * }, [foo]);
 * ```
 *
 * The above makes sure you'll never see a repeated value for `status`, unless
 * `foo` also changes.
 *
 * @param callback The function to call
 * @param deps Deps, allowing the ability to pass a new callback in every time
 */
export function useUniqCallback<A extends any[]>(
  callback: (...args: A) => void,
  deps: unknown[],
) {
  const ref = useRef<A>();
  const previousDeps = useRef<any[]>();
  const newDeps = deps.length ? deps : [callback];
  return useCallback(
    (...args: A) => {
      // fire if args *or* deps have changed
      if (
        !ref.current ||
        !shallowEqual(args, ref.current) ||
        !previousDeps.current ||
        !shallowEqual(previousDeps.current, newDeps)
      ) {
        callback(...args);
      }
      ref.current = args;
      previousDeps.current = newDeps;
    },

    // ignore `callback` as we assume it will always change, instead require the
    // caller to pass `deps`
    //  eslint-disable-next-line react-hooks/exhaustive-deps
    newDeps,
  );
}
