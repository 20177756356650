import {
  CuratedColumnSymbology,
  DistributionTypes,
  InterpolationTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const censusPlanningdatabaseAgeLayers = [
  {
    layerId:
      '/public/dataset/us_census_census_planning_database_2018_age_distribution',
    columnKey: 'pct_pop_25_44_acs_12_16',
  },
  {
    layerId:
      '/public/dataset/us_census_census_planning_database_2019_age_distribution',
    columnKey: 'pct_pop_25_44_acs_13_17',
  },
  {
    layerId: '/public/databank/cpd_2020_age_distribution',
    columnKey: 'pct_pop_25_44_acs_14_18',
  },
  {
    layerId: '/public/databank/cpd_2021_age_distribution',
    columnKey: 'pct_pop_25_44_acs_15_19',
  },
];

const symbologies: Record<
  string,
  Record<string, CuratedColumnSymbology[]>
> = {};
censusPlanningdatabaseAgeLayers.forEach(({ layerId, columnKey }) => {
  symbologies[layerId] = {
    [columnKey]: [
      {
        display_hints: {
          distribution: DistributionTypes.DEFAULT,
          interpolation: InterpolationTypes.INTERVAL,
          scale: SymbologyScales.NUMERIC,
          showZero: true,
          theme: 'd3:interpolateBuPu',
        },
        paint: {
          'fill-color': {
            property: columnKey,
            stops: [
              {
                color: 'rgb(247, 252, 253)',
                value: 0,
              },
              {
                color: 'rgb(225, 237, 244)',
                value: 10,
              },
              {
                color: 'rgb(198, 217, 233)',
                value: 20,
              },
              {
                color: 'rgb(170, 195, 222)',
                value: 30,
              },
              {
                color: 'rgb(149, 167, 207)',
                value: 40,
              },
              {
                color: 'rgb(140, 131, 189)',
                value: 50,
              },
              {
                color: 'rgb(138, 93, 170)',
                value: 60,
              },
              {
                color: 'rgb(134, 53, 149)',
                value: 70,
              },
              {
                color: 'rgb(118, 17, 117)',
                value: 80,
              },
              {
                color: 'rgb(77, 0, 75)',
                value: 90,
              },
            ],
            type: 'interval',
          },
          'fill-opacity': 0.5,
        },
        type: SymbologyTypes.FILL,
      },
      {
        display_hints: {
          distribution: DistributionTypes.DEFAULT,
          interpolation: InterpolationTypes.INTERVAL,
          scale: SymbologyScales.NUMERIC,
          showZero: true,
          theme: 'custom',
        },
        paint: {
          'line-color': '#333333',
          'line-opacity': 0.5,
          'line-width': 0.5,
        },
        type: SymbologyTypes.LINE,
      },
    ],
  };
});

export default symbologies;
