import { Mode, ModeOptions } from '@mapbox/mapbox-gl-draw';
import { LayerNumericStats } from 'uf-api';
import { AppThunk } from 'uf/app/AppThunk';
import { getActiveProjectId } from 'uf/app/selectors';

import { makeSetPropValueAction } from 'uf/base/actionHelpers';
import {
  SET_DETAILS_TAB,
  SET_DRAWING_MODE,
  SET_SHOW_PAINT_ONLY,
  SET_SUMMARY_STATS_TYPE,
  SET_UI_PROPERTY,
  SetDetailsTabKeyAction,
  SetDrawingModeAction,
  SetUIPropertyAction,
} from 'uf/explore/ActionTypes';
import { TabKey } from 'uf/explore/details';
import { LayerId } from 'uf/layers';
import { registerPersistedAction } from 'uf/persistence';
import { ProjectId } from 'uf/projects';

export function setUIProperty<T, E = never>(
  property: string,
  value: T,
  extra?: E,
): AppThunk<SetUIPropertyAction<T, E>> {
  return (dispatch, getState) => {
    const projectId = getActiveProjectId(getState());
    return dispatch({
      type: SET_UI_PROPERTY,
      property,
      value,
      projectId,
      ...(extra ? { extra } : {}),
    });
  };
}

export const setShowPaintedOnly = makeSetPropValueAction(SET_SHOW_PAINT_ONLY);

export function setSummaryStatsType(
  projectId: ProjectId,
  statsKey: keyof LayerNumericStats,
) {
  return {
    type: SET_SUMMARY_STATS_TYPE,
    property: 'aggregateType',
    value: statsKey,
    projectId,
  };
}

export function setDetailsPaneTabKey(
  projectId: ProjectId,
  layerId: LayerId,
  tabKey: TabKey,
): SetDetailsTabKeyAction {
  return {
    type: SET_DETAILS_TAB,
    // For makeKeyedReducer
    projectId,
    layerId,
    value: tabKey,
  };
}

registerPersistedAction<SetDetailsTabKeyAction, string[], TabKey>(
  'detailsTab',
  SET_DETAILS_TAB,
  ([projectId, layerId]: string[], tabKey: TabKey) =>
    setDetailsPaneTabKey(projectId, layerId, tabKey),
  {
    getKey({ projectId, layerId }) {
      return [projectId, layerId];
    },
    getValue({ value }) {
      return value;
    },
  },
);

export function setDrawingMode(
  projectId: ProjectId,
  layerId: LayerId,
  mode: Mode<'box_select_vertices'>,
  modeOptions: ModeOptions,
): SetDrawingModeAction {
  return {
    type: SET_DRAWING_MODE,
    projectId,
    layerId,
    mode,
    modeOptions,
  };
}
