import { FormGroup, InputGroup } from '@blueprintjs/core';
import React, { FC, ReactNode, useCallback, useEffect, useState } from 'react';
import { t } from 'ttag';

import FormDialog from 'uf/ui/base/Modals/FormDialog';

export interface FormProps {
  name: string;
  description?: string;
}

export interface Props {
  className?: string;
  title: ReactNode;
  show: boolean;
  defaults?: Partial<FormProps>;
  busy?: boolean;
  okDisabled?: boolean;
  error?: any;
  onOk: (form: FormProps) => void;
  onCancel: () => void;
}

const SaveWorkingLayerPrompt: FC<Props> = ({
  className,
  show,
  defaults,
  title,
  busy,
  error,
  okDisabled,
  onOk,
  onCancel,
}) => {
  const [form, setForm] = useState<Partial<FormProps>>(defaults || {});

  useEffect(() => {
    if (show) {
      setForm(defaults);
    }
    // only trigger when show changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const handleModalChange = useCallback(
    event => {
      const { target } = event;
      const { name: fieldName, value: fieldValue } = target;
      setForm({ ...form, [fieldName]: fieldValue });
    },
    [form, setForm],
  );

  const onSubmit = useCallback(() => {
    onOk(form as FormProps);
  }, [form, onOk]);

  return (
    <FormDialog
      show={show}
      className={className}
      title={<div className="h5">{title}</div>}
      okDisabled={okDisabled}
      error={error && `${error}`}
      busy={busy}
      onOk={onSubmit}
      onCancel={onCancel}>
      <FormGroup label={t`Name`} labelFor="SaveWorkingLayerPrompt-name">
        <InputGroup
          name="name"
          id="SaveWorkingLayerPrompt-name"
          onChange={handleModalChange}
          type="text"
          value={form.name}
        />
      </FormGroup>
      {Object.prototype.hasOwnProperty.call(defaults, 'description') && (
        <FormGroup
          label={t`Description`}
          labelFor="SaveWorkingLayerPrompt-description">
          <InputGroup
            name="description"
            id="SaveWorkingLayerPrompt-description"
            onChange={handleModalChange}
            type="text"
            value={form.description}
          />
        </FormGroup>
      )}
    </FormDialog>
  );
};

export default SaveWorkingLayerPrompt;

export function useLayerPromptProps<T>(
  onSave: (form: FormProps) => Promise<T>,
) {
  const [busy, setBusy] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  return {
    onOk: useCallback(
      (form: FormProps) => {
        setBusy(true);
        onSave(form)
          .catch(setError)
          .finally(() => {
            setBusy(false);
          });
      },
      [onSave, setBusy, setError],
    ),
    error,
    busy,
  };
}
