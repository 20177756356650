import { Boundary, OverflowList } from '@blueprintjs/core';
import { boundMethod } from 'autobind-decorator';
import classNames from 'classnames';
import _ from 'lodash';
import React, { Component } from 'react';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';
import { Box } from 'react-layout-components';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ThunkActionMapDispatch } from 'redux-thunk';
import { t } from 'ttag';
import warning from 'warning';

import { ProjectMetadata, ScenarioMetadata, UserAction } from 'uf-api';
import * as appActionCreators from 'uf/app/actions';
import {
  getActiveProjectId,
  getActiveScenario,
  getBaseScenario,
  getNextScenarioOrdinal,
} from 'uf/app/selectors';
import { arrayMoveElement } from 'uf/base/array';
import { parseFullPath } from 'uf/base/dataset';
import { DataState, isLoading } from 'uf/data/dataState';
import { setScenarioOrder as setScenarioOrderActionCreator } from 'uf/explore/actions/scenarios';
import { makeGetOrderedScenariosForProject } from 'uf/explore/selectors/scenarios';
import { logEvent } from 'uf/logging';
import { ProjectId } from 'uf/projects';
import * as projectActionCreators from 'uf/projects/actions';
import {
  getCreateScenarioState,
  makeGetProjectById,
} from 'uf/projects/selectors';
import StatusTypes from 'uf/projects/StatusTypes';
import { getCreateScenarioPermissionText, ScenarioId } from 'uf/scenarios';
import { events } from 'uf/scenarios/logging';
import {
  getPendingScenarioIdsForProject,
  getScenariosUpdateStatus,
} from 'uf/scenarios/selectors';
import { ScenarioUpdateStatus } from 'uf/scenarios/update';
import rootStyles from 'uf/styles/root.module.css';
import CanPerform from 'uf/ui/base/CanPerform/CanPerform';
import makeDataAttributes from 'uf/ui/base/makeDataAttributes';
import { CreateScenarioMenu } from 'uf/ui/scenarios/ScenarioBar/CreateScenarioMenu';
import ScenarioOverflowMenu from 'uf/ui/scenarios/ScenarioBar/ScenarioOverflowMenu';
import ScenarioTab from 'uf/ui/scenarios/ScenarioTab/ScenarioTab';

import styles from './ScenarioBar.module.css';

const renderNull = () => null;

const stubbedBaseScenario: ScenarioMetadata = {
  full_path: '/stubbed/scenario/base_scenario',
  key: 'base_scenario',
  name: t`Base Scenario`,
  base_scenario: true,
};

interface OwnProps {
  style?: React.CSSProperties;
  barHeight?: number;

  // minimum number of items to show on the screen.  this is very useful for testing since the
  // overflow list will collapse 100% without this.
  minVisibleItems?: number;
}

interface StateProps {
  hasScenarios: boolean;
  scenariosLoading: boolean;
  /**
   * alphanumeric sorted scenarios
   */
  sortedScenarios: ScenarioMetadata[];
  pendingScenarioIds: string[];
  baseScenario: ScenarioMetadata;
  baseScenarioId: string;
  activeScenario: ScenarioMetadata;
  activeProject: ProjectMetadata;
  activeProjectId: string;
  nextScenarioOrdinal?: number;
  createScenarioState?: DataState<any>;
  scenariosUpdateStatus?: Record<string, ScenarioUpdateStatus>;
}

interface DispatchProps {
  projectActions?: ThunkActionMapDispatch<typeof projectActionCreators>;
  setActiveScenario?: (projectId: ProjectId, scenarioId: ScenarioId) => void;
  setScenarioOrder?: (projectId: ProjectId, order: string[]) => void;
}

type ScenarioBarProps = OwnProps & StateProps & DispatchProps;

interface ScenarioBarState {
  overflowScenarios?: ScenarioMetadata[];
}

export class ScenarioBar extends Component<ScenarioBarProps, ScenarioBarState> {
  // included so tests don't break when component is mounted
  static displayName = 'ScenarioBar';
  static defaultProps: Partial<ScenarioBarProps> = {
    activeProject: {},
    baseScenario: {},
    minVisibleItems: 1,
  };

  state: Partial<ScenarioBarState> = {
    overflowScenarios: [],
  };

  componentDidUpdate(prevProps) {
    const projectChanged =
      prevProps.activeProjectId !== this.props.activeProjectId;

    const scenariosChanged =
      prevProps.sortedScenarios !== this.props.sortedScenarios;

    // Clear out the overflow scenarios when the project changes or the
    // scenarios list changes, eg: due to delete or add scenario
    if (projectChanged || scenariosChanged) {
      this.setState({ overflowScenarios: [] });
    }
  }

  @boundMethod
  onDragEnd(result: DropResult) {
    if (!result.destination) {
      return null;
    }

    this.moveScenario(result.source.index, result.destination.index);
  }

  @boundMethod
  onOverflowScenarioClick(scenario: ScenarioMetadata) {
    const { activeProjectId, sortedScenarios, setActiveScenario } = this.props;
    const { overflowScenarios } = this.state;

    const scenarioIndex = sortedScenarios.findIndex(
      s => s.full_path === scenario.full_path,
    );

    const lastVisibleTabIndex =
      sortedScenarios.length - overflowScenarios.length - 1;

    this.moveScenario(scenarioIndex, lastVisibleTabIndex);
    setActiveScenario(activeProjectId, scenario.full_path);
  }

  @boundMethod
  moveScenario(sourceIndex: number, destinationIndex: number) {
    const { activeProjectId, sortedScenarios, setScenarioOrder } = this.props;
    const order = sortedScenarios.map(metadata => metadata.full_path);
    const newOrder = arrayMoveElement(order, sourceIndex, destinationIndex);

    setScenarioOrder(activeProjectId, newOrder);

    logEvent(events.SCENARIO_ORDER, {
      projectId: activeProjectId,
      oldScenarioOrder: order,
      newScenarioOrder: newOrder,
    });
  }

  // OverflowList can rerender a lot as tab sizes change. Allow it some time to
  // settle before we update the number of overflow items and caursing another
  // render.

  @boundMethod
  onCreateScenario(scenario: ScenarioMetadata) {
    const {
      activeProject: { full_path: projectId },
      nextScenarioOrdinal,
      projectActions: { cloneScenario },
    } = this.props;

    cloneScenario(
      projectId,
      scenario.full_path,
      scenario.name,
      nextScenarioOrdinal,
    );
  }

  @boundMethod
  onOverflow(newOverflowScenarios: ScenarioMetadata[]) {
    this.setState(({ overflowScenarios }) => {
      if (!_.isEqual(newOverflowScenarios, overflowScenarios)) {
        return { overflowScenarios: newOverflowScenarios };
      }
      return null;
    });
  }

  @boundMethod
  getScenarios() {
    const { activeProject, activeScenario, baseScenario, sortedScenarios } =
      this.props;
    const { overflowScenarios } = this.state;

    if (
      /*
       * Blueprint's OverflowList cannot properly render during SSR as it has no
       * notion of how much space there is in the Header and therefore how many
       * items to put in the overflow list. Instead, it will render all as
       * visible tabs which will run over the other items in the header. So,
       * during SSR we only render the first tab and a spinner next to it until
       * we render client side after the project loads.
       */
      !__CLIENT__ &&
      /*
       * Testing environment is technically an SSR, but here we actually want
       * the default OverflowList behavior (see previous comment) so we can
       * test our handmade overflow logic.
       */
      !__TESTING__
    ) {
      // slice handles the case when sortedScenarios is empty too
      return sortedScenarios.slice(0, 1);
    }

    if (!_.isEmpty(activeProject) && _.isEmpty(baseScenario)) {
      /*
       * This is a bit of a hack: we don't know for certain that we're creating
       * the initial scenario, so we assume the backend is working on it. TODO:
       * Use some indication from the backend that the scenario creation process
       * is actually running.
       */
      warning(
        _.isEmpty(sortedScenarios),
        'Missing base scenario, but have other scenarios',
      );
      return [stubbedBaseScenario];
    }

    const scenarios = getOrderedScenarios(
      activeScenario,
      sortedScenarios,
      overflowScenarios.length,
    );

    return scenarios;
  }

  @boundMethod
  renderVisibleScenarios(scenario: ScenarioMetadata, index: number) {
    const {
      activeProjectId,
      activeProject,
      activeScenario,
      baseScenarioId,
      scenariosLoading,
      scenariosUpdateStatus,
      pendingScenarioIds,
    } = this.props;

    return (
      <Draggable
        disableInteractiveElementBlocking
        key={scenario.full_path}
        index={index}
        draggableId={scenario.full_path}>
        {provided => (
          <ScenarioTab
            innerRef={provided.innerRef}
            dragAndDropProps={{
              ...provided.draggableProps,
              ...provided.dragHandleProps,
            }}
            dataAttributes={makeDataAttributes({
              isBaseScenario: scenario.full_path === baseScenarioId,
              scenarioIndex: index,
            })}
            key={scenario.full_path}
            scenario={scenario}
            projectId={activeProjectId}
            project={activeProject}
            active={isScenarioActive(activeScenario, scenario)}
            scenariosLoading={scenariosLoading}
            loading={pendingScenarioIds.includes(scenario.full_path)}
            isUpdating={getIsUpdating(
              scenariosUpdateStatus,
              scenario.full_path,
            )}
          />
        )}
      </Draggable>
    );
  }

  render() {
    const { minVisibleItems, activeProjectId, pendingScenarioIds } = this.props;

    const { overflowScenarios } = this.state;

    const sortedScenarios = this.getScenarios();

    const scenarioBarClass = classNames(
      rootStyles.flexBox,
      // We must use a container with a scrolling overflow for Droppable
      rootStyles.scroll,
      styles.scenarioBar,
    );

    return (
      <Box className={classNames('ScenarioBar', rootStyles.overflowHidden)}>
        <DragDropContext onDragEnd={this.onDragEnd}>
          <Droppable droppableId="visibleTabs" direction="horizontal">
            {provided => (
              <div
                /*
                 * Use vanilla div so we can pass the innerRef to an actual DOM
                 * node. TODO: Switch to <Box> once ref support lands:
                 * https://github.com/robinweser/react-layout-components/pull/53
                 */
                ref={provided.innerRef}
                className={scenarioBarClass}
                {...provided.droppableProps}>
                <OverflowList
                  className={rootStyles.alignItemsEnd}
                  minVisibleItems={minVisibleItems}
                  observeParents
                  collapseFrom={Boundary.END}
                  items={sortedScenarios}
                  onOverflow={this.onOverflow}
                  overflowRenderer={renderNull}
                  visibleItemRenderer={this.renderVisibleScenarios}
                />
                {provided.placeholder && (
                  <Box
                    alignItems="flex-end"
                    className={styles.dragAndDropPlaceholder}>
                    {provided.placeholder}
                  </Box>
                )}
              </div>
            )}
          </Droppable>
          <Box flex={1} alignItems="flex-end" className={styles.scenarioBar}>
            {!!overflowScenarios.length && (
              <ScenarioOverflowMenu
                onItemClick={this.onOverflowScenarioClick}
                scenarios={overflowScenarios}
                pendingScenarioIds={pendingScenarioIds}
              />
            )}
            <CanPerform
              cache={false}
              verb="scenario.create"
              resources={activeProjectId}>
              {({ disabled, reason }) => {
                if (reason === UserAction.ReasonEnum.Permission) {
                  return null;
                }
                return (
                  <CreateScenarioMenu
                    disabled={disabled}
                    tooltipText={getCreateScenarioPermissionText(
                      !disabled,
                      reason,
                    )}
                    onClickItem={this.onCreateScenario}
                    scenarios={sortedScenarios}
                  />
                );
              }}
            </CanPerform>
          </Box>
        </DragDropContext>
      </Box>
    );
  }
}

function makeMapStateToProps() {
  const getOrderedScenarioMetadatas = makeGetOrderedScenariosForProject();
  const getProjectById = makeGetProjectById();
  return (state): StateProps => {
    const createScenarioState = getCreateScenarioState(state);
    const scenariosLoading = isLoading(createScenarioState);

    const projectId = getActiveProjectId(state);
    const project = getProjectById(state, { projectId });
    const baseScenario = getBaseScenario(state);
    const baseScenarioId = baseScenario?.full_path;
    const sortedScenarios = getOrderedScenarioMetadatas(state, { projectId });
    const pendingScenarioIds = getPendingScenarioIdsForProject(state, {
      projectId,
    });
    const hasScenarios = !_.isEmpty(sortedScenarios);

    return {
      activeProject: project,
      activeProjectId: projectId,
      baseScenario,
      baseScenarioId,
      createScenarioState,
      hasScenarios,
      scenariosLoading,
      sortedScenarios,
      pendingScenarioIds,
      activeScenario: getActiveScenario(state),
      nextScenarioOrdinal: getNextScenarioOrdinal(state),
      scenariosUpdateStatus: getScenariosUpdateStatus(state),
    };
  };
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
  return {
    projectActions: bindActionCreators(projectActionCreators, dispatch),
    setActiveScenario: bindActionCreators(
      appActionCreators.setActiveScenario,
      dispatch,
    ),
    setScenarioOrder: bindActionCreators(
      setScenarioOrderActionCreator,
      dispatch,
    ),
  };
}

export default connect<StateProps, DispatchProps, OwnProps>(
  makeMapStateToProps,
  mapDispatchToProps,
)(ScenarioBar);

function isScenarioActive(
  activeScenario: ScenarioMetadata,
  scenario: ScenarioMetadata,
) {
  return activeScenario && activeScenario.full_path === scenario.full_path;
}

function getIsUpdating(
  scenariosUpdateStatus: Record<string, ScenarioUpdateStatus>,
  scenarioId: ScenarioId,
): boolean {
  const { key: scenarioKey } = parseFullPath(scenarioId);
  const updateStatus = scenariosUpdateStatus[scenarioKey];
  return updateStatus?.status === StatusTypes.IN_PROGRESS;
}

function getOrderedScenarios(
  activeScenario: ScenarioMetadata,
  scenarios: ScenarioMetadata[],
  overflowCount: number,
): ScenarioMetadata[] {
  const activeScenarioId = activeScenario?.full_path;
  const activeScenarioIndex = scenarios.findIndex(
    s => s.full_path === activeScenarioId,
  );

  const lastVisibleScenarioIndex = scenarios.length - overflowCount - 1;
  let orderedScenarios = [...scenarios];
  if (activeScenarioIndex > lastVisibleScenarioIndex) {
    orderedScenarios = arrayMoveElement(
      scenarios,
      activeScenarioIndex,
      lastVisibleScenarioIndex,
    );
  }

  return orderedScenarios;
}
