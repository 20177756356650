import colors from 'material-colors';

import {
  CuratedColumnSymbology,
  InterpolationTypes,
  PaintColorTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/public/dataset/egrid_data_by_power_plant_2016': {
    plfuelct: [
      {
        display_hints: {
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          scale: SymbologyScales.CATEGORICAL,
          theme: 'custom',
        },
        paint: {
          'circle-color': {
            default: '#c49c94',
            property: 'plfuelct',
            stops: [
              {
                color: '#dbdf00',
                value: 'BIOMASS',
              },
              {
                color: '#8b4513',
                value: 'COAL',
              },
              {
                color: '#ff7f0e',
                value: 'GAS',
              },
              {
                color: '#808900',
                value: 'GEOTHERMAL',
              },
              {
                color: '#009ce0',
                value: 'HYDRO',
              },
              {
                color: '#aea1ff',
                value: 'NUCLEAR',
              },
              {
                color: '#16a5a5',
                value: 'OFSL',
              },
              {
                color: colors.black,
                value: 'OIL',
              },
              {
                color: '#999999',
                value: 'OTHF',
              },
              {
                color: '#fcdc00',
                value: 'SOLAR',
              },
              {
                color: '#68bc00',
                value: 'WIND',
              },
            ],
            type: PaintColorTypes.CATEGORICAL,
          },
          'circle-opacity': 0.9,
          'circle-radius': 10,
          'circle-stroke-color': 'transparent',
        },
        type: SymbologyTypes.CIRCLE,
      },
    ],
  },
};

export default symbologies;
