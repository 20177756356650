import { Action } from 'redux';

import { makeAsyncActionTypes } from 'uf/data/ActionTypes';
import { ProjectId } from 'uf/projects';

const PREFIX = 'uf/app';

export const PAGE_INITIALIZED = `${PREFIX}/PAGE_INITIALIZED`;
export const APP_INITIALIZED = 'uf/app/INITIALIZED';
export interface AppInitializedAction {
  type: typeof APP_INITIALIZED;
}
// app.info actions and prefixes
const APP_INFO_PREFIX = `${PREFIX}/info`;
const VERSION_PREFIX = `${APP_INFO_PREFIX}/version`;

// There is no swagger api for this, it's actually just a raw fetch call.
export const loadFrontendVersionActionTypes = makeAsyncActionTypes(
  `${VERSION_PREFIX}/frontend`,
);

export const FRONTEND_VERSION_MISMATCH = 'uf/app/FRONTEND_VERSION_MISMATCH';
export interface FrontendVersionMismatchAction {
  type: typeof FRONTEND_VERSION_MISMATCH;
}
export const CLEAR_ACTIVE_PROJECT = `${PREFIX}/CLEAR_ACTIVE_PROJECT`;
export interface ClearActiveProjectAction extends Action {
  type: typeof CLEAR_ACTIVE_PROJECT;
  refreshMap?: boolean;
  redirectToExplore?: boolean;
  forceProjectReload?: boolean;
}

// active project actions and prefix
export const SET_ACTIVE_PROJECT = `${PREFIX}/SET_ACTIVE_PROJECT`;
export interface SetActiveProjectAction extends Action {
  type: typeof SET_ACTIVE_PROJECT;
  property: 'activeProjectId';
  value: string;

  // TODO: can we get rid of these?
  refreshMap?: boolean;
  redirectToExplore?: boolean;
  forceProjectReload?: boolean;
}
// Fired when the active scenario is loaded, or when the user
// activates a loaded project. Attach epics to this when you want to
// do something when the active project changes, but you require infor
// from that project
export const ACTIVE_PROJECT_READY = 'uf/app/ACTIVE_PROJECT_READY';
export interface ActiveProjectReadyAction {
  type: typeof ACTIVE_PROJECT_READY;
  projectId: ProjectId;
}
// active scenario actions and prefix
export const SET_ACTIVE_SCENARIO = 'uf/app/SET_ACTIVE_SCENARIO';
export interface SetActiveScenarioAction extends Action {
  type: typeof SET_ACTIVE_SCENARIO;
  projectId: ProjectId;
  property: string;
  value: string;
}

export const SHOW_APP_TOASTS = 'uf/app/SHOW_APP_TOASTS';

export interface ShowAppToastsAction extends Action {
  type: typeof SHOW_APP_TOASTS;
  value: boolean;
}
