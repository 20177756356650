import { AnyAction, combineReducers } from 'redux';

import {
  makeKeyedListReducer,
  makeKeyedReducer,
  makeSetValueReducer,
} from 'uf/base/reducerFactories';
import {
  SET_SHOW_PAINT_ONLY,
  SET_UI_PROPERTY,
  SetUIPropertyAction,
  exploreFilterListActionTypes,
  exploreGeoJoinListActionTypes,
} from 'uf/explore/ActionTypes';
import { JoinItem, UiState } from 'uf/explore/state';

// TODO: this really looks like it should get elevated to the app/ directory along with reducer
const appViews = new Set([
  'exploreViewVisible',
  'reportViewVisible',
  'settingsViewVisible',
  'profileViewVisible',
  'manageViewVisible',
]);

const exploreModes = new Set(['paintUIVisible', 'analysisUIVisible']);

// returns a dictionary whose property values are false
function initFalseFlags(props: Set<string>): Record<string, boolean> {
  return Array(...props).reduce((prev, cur: string) => {
    prev[cur] = false;
    return prev;
  }, {} as Record<string, boolean>);
}

// used to reset view state
const initialAppViewsState = initFalseFlags(appViews);
const initialExplorerModesState = initFalseFlags(exploreModes);

const initialState = {
  // views
  ...initialAppViewsState,
  ...initialExplorerModesState,

  // TODO:  this is deprecated. remove as part of #9015
  chartPanelVisible: false,
  // Manages weather the constraints panel or flyout panel is visible
  visibleSecondaryPanel: null,
  // The current analysis module
  activeAnalysisModuleKey: null,
};

function properties(state = initialState, action: AnyAction) {
  switch (action.type) {
    case SET_UI_PROPERTY: {
      const { property, value } = action as SetUIPropertyAction<any>;

      if (exploreModes.has(property) && !!value) {
        return {
          ...state,
          ...initialAppViewsState,
          exploreViewVisible: true,
          [property]: value,
        };
      }
      if (appViews.has(property) && !!value) {
        return {
          ...state,
          ...initialAppViewsState,
          [property]: value,
        };
      }
      return {
        ...state,
        [property]: value,
      };
    }
  }
  return state;
}

// contains lists of filter ids for items selected from the Add Filter button
const filterListItems = makeKeyedReducer<
  Record<string, JoinItem[]>,
  'projectId',
  any
>(
  makeKeyedListReducer<JoinItem, 'key', string>(
    exploreFilterListActionTypes,
    'key',
    item => item.key,
  ),
  'projectId',
);

// contains lists of layer ids for items selected from Join Layer button
const geojoinListItems = makeKeyedReducer<
  Record<string, JoinItem[]>,
  'projectId',
  any
>(
  makeKeyedListReducer<JoinItem, 'key', string>(
    exploreGeoJoinListActionTypes,
    'key',
    item => item.key,
  ),
  'projectId',
);

const ui = combineReducers<UiState>({
  properties,
  filterListItems,
  geojoinListItems,
  showPaintedOnly: makeSetValueReducer<boolean>(SET_SHOW_PAINT_ONLY),
});

export default ui;
