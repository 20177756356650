import { createSelector } from 'reselect';

import { makeGetExploreByProjectState } from './explore';

/**
 * helper to get the markers state
 */
export function makeGetAllMarkers() {
  return createSelector(makeGetExploreByProjectState(), state => state.markers);
}
