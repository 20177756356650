import _ from 'lodash';
import { AnyLayer, Layer, Map } from 'mapbox-gl';
import { shallowEqual } from 'recompose';
import warning from 'warning';

import { flattenLngLatBounds } from 'uf/base/map';
import { assertNever } from 'uf/base/never';
import { makeSvgDataUrlFromPath } from 'uf/base/svg';
import updateListOrder from 'uf/base/updateListOrder';
import { LayerId } from 'uf/layers';
import { LayerSource, MapViewport } from 'uf/map';

export function styleLayersEqual(v1: Layer, v2: Layer) {
  return v1.id === v2.id && v1.source === v2.source;
}

/* tile sources */

export function addTileSources(map: Map, sources: LayerSource[]) {
  sources.forEach(({ id, source }) => {
    if (map.getSource(id)) {
      return;
    }
    map.addSource(id, source);
  });
}

// removes all tile sources found in current but not in next.
export function removeTileSources(
  map: Map,
  currentSources: LayerSource[],
  nextSources: LayerSource[],
) {
  const nextSourcesById = _.keyBy(nextSources, 'id');
  currentSources.forEach(({ id }) => {
    if (!nextSourcesById[id] && map.getSource(id)) {
      map.removeSource(id);
    }
  });
}

/**
 * Update the 'data' for any geojson tile sources if they've changed.
 */
export function updateTileSourcesData(
  map: Map,
  currentSources: LayerSource[],
  nextSources: LayerSource[],
) {
  if (shallowEqual(currentSources, nextSources)) {
    return;
  }
  const currentSourcesById = _.keyBy(currentSources, 'id');
  nextSources.forEach(({ id, source }) => {
    const currentSource = currentSourcesById[id];
    if (
      currentSource &&
      source.type === 'geojson' &&
      currentSource.source.type === 'geojson' &&
      !shallowEqual(source.data, currentSource.source.data)
    ) {
      // need to get a source class instance so we can call setData on it.
      const mapSource = map.getSource(id);
      warning(
        !!mapSource,
        `Map source ${id} is not on the map yet, but data has already been updated`,
      );
      if (!mapSource) {
        return;
      }
      warning(
        mapSource.type === 'geojson',
        `Cannot change tile source type from geojson to ${source.type}`,
      );

      if (mapSource.type !== 'geojson') {
        return;
      }
      mapSource.setData(source.data);
    }
  });
}

/* style layers */
export function makeAddStyleLayer(map: Map) {
  return (
    layer: AnyLayer,
    zoomExtentsByLayer: Record<string, { minZoom: number; maxZoom: number }>,
    beforeLayer?: AnyLayer,
  ) => {
    if (!map.getLayer(layer.id)) {
      if (beforeLayer && map.getLayer(beforeLayer.id)) {
        map.addLayer(layer, beforeLayer.id);
      } else if (!beforeLayer && map.getLayer('water-label')) {
        map.addLayer(layer, 'water-label');
      } else {
        map.addLayer(layer);
      }
    }
    if (zoomExtentsByLayer[layer['source-layer']]) {
      const { minZoom, maxZoom } = zoomExtentsByLayer[layer['source-layer']];
      map.setLayerZoomRange(layer.id, minZoom, maxZoom);
    }
  };
}

export function makeRemoveStyleLayer(map: Map) {
  return (layer: Layer) => {
    if (map.getLayer(layer.id)) {
      map.removeLayer(layer.id);
    }
  };
}

export function makeMoveStyleLayer(map: Map) {
  return (layer: Layer, beforeLayer: Layer) => {
    if (map.getLayer(layer.id) && map.getLayer(beforeLayer.id)) {
      map.moveLayer(layer.id, beforeLayer.id);
    }
  };
}

export function makePointPrimitive({ x, y }: { x: number; y: number }) {
  return { x, y };
}

export function makeLngLatPrimitive({
  lng,
  lat,
}: {
  lng: number;
  lat: number;
}) {
  return { lng, lat };
}

export function getMapViewport(map: mapboxgl.Map): Partial<MapViewport> {
  const bounds = map.getBounds();
  return {
    center: map.getCenter().toArray() as [number, number],
    zoom: map.getZoom(),
    bearing: map.getBearing(),
    pitch: map.getPitch(),
    bounds: bounds ? flattenLngLatBounds(bounds) : null,
  };
}

/** Default height/width when rasterizing map icons */
const RASTER_ICON_SIZE = 20;

/**
 * Adds an icon image to the map by rasterizing it through `Image`.
 *
 * Normally the map only supports raw PNG/JPG urls, but this allows the browser
 * to rasterize the image first.
 *
 * Technique from
 * https://github.com/mapbox/mapbox-gl-js/issues/5529#issuecomment-340011876
 */
export function addSvgPathAsImage(
  map: Map,
  imageKey: string,
  path: string,
  width = RASTER_ICON_SIZE,
  height = RASTER_ICON_SIZE,
) {
  const imageData = makeSvgDataUrlFromPath(path);
  const img = new Image(width, height);
  img.onload = () => map.addImage(imageKey, img);
  img.src = imageData;
}

/**
 * Update a set of properties in the map. Works for both `'paint'` and
 * `'layout'`.
 * @param map
 * @param propertyType
 * @param oldLayerStyle
 * @param newLayerStyle
 * @param layerId
 */
export function updateStyleProperties(
  map: Map,
  propertyType: 'paint' | 'layout',
  oldLayerStyle: Layer,
  newLayerStyle: Layer,
  layerId: LayerId,
) {
  const allProperties = _.union(
    Object.keys(oldLayerStyle[propertyType] || {}),
    Object.keys(newLayerStyle[propertyType] || {}),
  );
  allProperties.forEach(property => {
    if (
      !_.isEqual(
        newLayerStyle?.[propertyType]?.[property],
        oldLayerStyle?.[propertyType]?.[property],
      )
    ) {
      if (propertyType === 'paint') {
        map.setPaintProperty(
          layerId,
          property,
          newLayerStyle?.paint?.[property],
        );
        return;
      } else if (propertyType === 'layout') {
        map.setLayoutProperty(
          layerId,
          property,
          newLayerStyle?.layout?.[property],
        );
        return;
      }
      assertNever(propertyType);
    }
  });
}

export function updateMapLayers(
  map: Map,
  styleLayers: Layer[],
  currentStyleLayers: Layer[],
  zoomExtentsByLayer?: Record<string, { minZoom: number; maxZoom: number }>,
) {
  const add = makeAddStyleLayer(map);
  const remove = makeRemoveStyleLayer(map);
  const move = makeMoveStyleLayer(map);

  // move extrusion layers on top to avoid weird shadowing;
  const newStyleLayers = [
    ...styleLayers.filter(layer => layer.type !== 'fill-extrusion'),
    ...styleLayers.filter(layer => layer.type === 'fill-extrusion'),
  ];
  updateListOrder(
    currentStyleLayers,
    newStyleLayers,
    add,
    remove,
    move,
    styleLayersEqual,
    zoomExtentsByLayer,
  );

  // now find layers that have changed, and call mapbox APIs to update them
  const oldLayersById = _.keyBy(currentStyleLayers, 'id');
  const newLayersById = _.keyBy(newStyleLayers, 'id');
  const keptLayerIds = _.intersectionBy(
    currentStyleLayers,
    newStyleLayers,
    'id',
  ).map(layer => layer.id);

  _.forEach(keptLayerIds, layerId => {
    const newLayerStyle = newLayersById[layerId];
    const oldLayerStyle = oldLayersById[layerId];

    if (!_.isEqual(oldLayerStyle, newLayerStyle)) {
      if (!_.isEqual(newLayerStyle.filter, oldLayerStyle.filter)) {
        map.setFilter(layerId, newLayersById[layerId].filter);
      }

      updateStyleProperties(
        map,
        'paint',
        oldLayerStyle,
        newLayerStyle,
        layerId,
      );
      updateStyleProperties(
        map,
        'layout',
        oldLayerStyle,
        newLayerStyle,
        layerId,
      );

      const layoutProperties = _.union(
        Object.keys(oldLayerStyle.layout || {}),
        Object.keys(newLayerStyle.layout || {}),
      );
      layoutProperties.forEach(layoutProperty => {
        if (
          !_.isEqual(
            newLayerStyle.layout[layoutProperty],
            oldLayerStyle.layout[layoutProperty],
          )
        ) {
          map.setLayoutProperty(
            layerId,
            layoutProperty,
            newLayerStyle.layout[layoutProperty],
          );
        }
      });
    }
  });
}
