import {
  cancelInvitationActionTypes,
  createOrganizationActionTypes,
  editMemberInOrganizationActionTypes,
  getFilteredMemberCountInOrganizationActionTypes,
  getInvitationsForOrganizationActionTypes,
  getMembersInfoInOrganizationActionTypes,
  getOrgFilesActionTypes,
  getOrgFileUrlActionTypes,
  getProjectsInOrganizationActionTypes,
  getSubscriptionActionTypes,
  inviteUserToOrganizationActionTypes,
  removeMemberFromOrganizationActionTypes,
  requestSalesContactActionTypes,
  updateOrganizationNameActionTypes,
  updateSubscriptionSeatsActionTypes,
} from 'uf-api/api/organization.service';
import { makeAsyncApiCaller } from 'uf/base/xhr';
import { dispatchAsyncAction, makeEnsureActionCreator } from 'uf/data/loader';
import {
  makeGetOrganizationMembersState,
  makeGetOrganizationSubscriptionStateByKey,
} from 'uf/organizations/selectors';
import { OrganizationRoles } from 'uf/organizations/spec';

const createOrganizationAPI = makeAsyncApiCaller(
  apis => apis.organization.create_organization,
);
export function createOrganization(
  organizationKey: string,
  organizationName: string,
) {
  return dispatchAsyncAction(
    createOrganizationActionTypes,
    createOrganizationAPI({ key: organizationKey, name: organizationName }),
    { organizationKey, organizationName },
  );
}

const updateOrganizationNameAPI = makeAsyncApiCaller(
  api => api.organization.update_organization_name,
);

export function updateOrganizationName(
  organizationKey: string,
  organizationName: string,
) {
  return dispatchAsyncAction(
    updateOrganizationNameActionTypes,
    updateOrganizationNameAPI({ key: organizationKey, name: organizationName }),
    { organizationKey, organizationName },
  );
}

const getProjectsInOrganization = makeAsyncApiCaller(
  api => api.organization.get_projects_in_organization,
);

export function loadOrganizationProjects(organizationKey: string) {
  return dispatchAsyncAction(
    getProjectsInOrganizationActionTypes,
    getProjectsInOrganization({ key: organizationKey }),
    { key: organizationKey },
  );
}

export const ensureOrganizationSubscription = makeEnsureActionCreator(
  loadOrganizationSubscription,
  makeGetOrganizationSubscriptionStateByKey(),
);

const getSubscription = makeAsyncApiCaller(
  apis => apis.organization.get_subscription,
);
export function loadOrganizationSubscription(organizationKey: string) {
  return dispatchAsyncAction(
    getSubscriptionActionTypes,
    getSubscription({ key: organizationKey }),
    { key: organizationKey },
  );
}

const getOrganizationMembers = makeAsyncApiCaller(
  apis => apis.organization.get_members_info_in_organization,
);
export function loadOrganizationMembers(organizationKey: string) {
  return dispatchAsyncAction(
    getMembersInfoInOrganizationActionTypes,
    getOrganizationMembers({ key: organizationKey }),
    { key: organizationKey },
  );
}

export const ensureOrganizationMembers = makeEnsureActionCreator(
  loadOrganizationMembers,
  makeGetOrganizationMembersState(),
  organizationKey => ({ organizationKey }),
);

const fetchOrganizationInvitations = makeAsyncApiCaller(
  apis => apis.organization.get_invitations_for_organization,
);
export function loadOrganizationInvitations(organizationKey: string) {
  return dispatchAsyncAction(
    getInvitationsForOrganizationActionTypes,
    fetchOrganizationInvitations({ org_key: organizationKey }),
    { key: organizationKey },
  );
}

const cancelInvitation = makeAsyncApiCaller(
  apis => apis.organization.cancel_invitation,
);
export function cancelOrganizationInvitation(
  organizationKey: string,
  token: string,
) {
  return dispatchAsyncAction(
    cancelInvitationActionTypes,
    cancelInvitation({ org_key: organizationKey, token }),
    { organizationKey, token },
  );
}

const inviteUserToOrganization = makeAsyncApiCaller(
  apis => apis.organization.invite_user_to_organization,
);
export function inviteOrganizationMember(
  organizationKey: string,
  email: string,
  role: OrganizationRoles,
  firstName: string,
  lastName: string,
  resend: boolean,
) {
  return dispatchAsyncAction(
    inviteUserToOrganizationActionTypes,
    inviteUserToOrganization({
      org_key: organizationKey,
      user_email: email,
      role,
      first_name: firstName,
      last_name: lastName,
      resend,
    }),
    {
      organizationKey,
      email,
      role,
      firstName,
      lastName,
      resend,
    },
  );
}

const editOrganizationMemberAPI = makeAsyncApiCaller(
  apis => apis.organization.edit_member_in_organization,
);
export function editOrganizationMember(
  organizationKey: string,
  userKey: string,
  role: OrganizationRoles,
) {
  return dispatchAsyncAction(
    editMemberInOrganizationActionTypes,
    editOrganizationMemberAPI({
      org_key: organizationKey,
      user_key: userKey,
      role,
    }),
    { organizationKey, userKey },
  );
}

const removeOrganizationMemberAPI = makeAsyncApiCaller(
  apis => apis.organization.remove_member_from_organization,
);
export function removeOrganizationMember(
  organizationKey: string,
  userKey: string,
) {
  return dispatchAsyncAction(
    removeMemberFromOrganizationActionTypes,
    removeOrganizationMemberAPI({
      org_key: organizationKey,
      user_key: userKey,
    }),
    { organizationKey, userKey },
  );
}

const updateSubscriptionSeatsAPI = makeAsyncApiCaller(
  apis => apis.organization.update_subscription_seats,
);
export function updateSubscriptionSeats(
  organizationKey: string,
  seats: number,
  preview: boolean,
) {
  return dispatchAsyncAction(
    updateSubscriptionSeatsActionTypes,
    updateSubscriptionSeatsAPI({ key: organizationKey, seats, preview }),
    { organizationKey, seats },
  );
}

const requestSalesContactAPI = makeAsyncApiCaller(
  apis => apis.organization.request_sales_contact,
);
export function requestSalesContact(organizationKey: string) {
  return dispatchAsyncAction(
    requestSalesContactActionTypes,
    requestSalesContactAPI({ key: organizationKey }),
    { organizationKey },
  );
}

const getOrgFileUrlAPI = makeAsyncApiCaller(
  apis => apis.organization.get_org_file_url,
);
export function getOrgFileUrl(
  organizationKey: string,
  filename: string,
  method: 'GET' | 'PUT',
) {
  return dispatchAsyncAction(
    getOrgFileUrlActionTypes,
    getOrgFileUrlAPI({ key: organizationKey, file: filename, method }),
  );
}

const getOrgFilesAPI = makeAsyncApiCaller(
  apis => apis.organization.get_org_files,
);

export function getOrgFiles(organizationKey: string) {
  return dispatchAsyncAction(
    getOrgFilesActionTypes,
    getOrgFilesAPI({ key: organizationKey }),
    { key: organizationKey },
  );
}

const getFilteredOrgMemberCountAPI = makeAsyncApiCaller(
  apis => apis.organization.get_filtered_member_count_in_organization,
);

export function getFilteredOrgMemberCount(organizationKey: string) {
  return dispatchAsyncAction(
    getFilteredMemberCountInOrganizationActionTypes,
    getFilteredOrgMemberCountAPI({ key: organizationKey }),
    { key: organizationKey },
  );
}
