import { Epic, ofType } from 'redux-observable';
import { map, withLatestFrom } from 'rxjs/operators';

import { combineEpics } from 'uf/base/epics';
import { RemoveItemAction } from 'uf/base/listActionHelpers';
import { setActiveView } from 'uf/explore/actions/views';
import { SET_ACTIVE_LAYER, SetActiveLayerAction } from 'uf/explore/ActionTypes';
import { makeGetActiveViewId } from 'uf/explore/selectors/views';
import { DEFAULT_VIEW_ID, View } from 'uf/views';
import { setLayerVisibility } from 'uf/views/actions/layers';
import { ExtraViewParams, viewListActionTypes } from 'uf/views/ActionTypes';

export const setActiveLayerVisible: Epic<SetActiveLayerAction, any> = (
  action$,
  state$,
) => {
  const getActiveViewId = makeGetActiveViewId();
  return action$.pipe(
    ofType(SET_ACTIVE_LAYER),
    withLatestFrom(state$),
    map(([{ virtualLayerId, projectId, active }, state]) => {
      // the active layer should always be visible
      const isVisible = active;
      const viewId = getActiveViewId(state, { projectId });
      return setLayerVisibility(projectId, virtualLayerId, viewId, isVisible);
    }),
  );
};

export const resetActiveViewAfterDelete: Epic<
  RemoveItemAction<View> & ExtraViewParams,
  any
> = action$ => {
  return action$.pipe(
    ofType(viewListActionTypes.REMOVE),
    map(action => {
      const { projectId } = action;

      return setActiveView(projectId, DEFAULT_VIEW_ID);
    }),
  );
};

export default combineEpics(
  { setActiveLayerVisible, resetActiveViewAfterDelete },
  'views',
);
