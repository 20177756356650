import { Position } from '@blueprintjs/core';
import React from 'react';
import { VictoryBar, VictoryTooltip } from 'victory';

import { BarChartTooltip } from './BarChartTooltip';
import {
  BAR_TOOLTIP_DISTANCE,
  DatumSummary,
  DEFAULT_TOTALS_FONT_SIZE,
} from './chart';

interface Props<D> {
  /** Use to take up space of bars, but not show anything on screen */
  invisible?: boolean;
  showTotalsTooltip: boolean;
  horizontal: boolean;
  formatDatumSummary: (summary: DatumSummary<D>, k: string) => JSX.Element;
  barExtents: DatumSummary<D>[];
  barWidth: number;
}

const nullLabel = (datum: unknown) => null;
export function BarChartBackgroundBar<D extends object>(props: Props<D>) {
  const {
    invisible,
    showTotalsTooltip,
    horizontal,
    formatDatumSummary,
    barExtents,
    barWidth,
    ...victoryProps
  } = props;

  const opacity = invisible ? 0 : 1;
  return (
    <VictoryBar
      {...victoryProps}
      animate={null}
      horizontal={horizontal}
      labelComponent={
        showTotalsTooltip ? (
          <VictoryTooltip
            renderInPortal={false}
            dx={horizontal ? -BAR_TOOLTIP_DISTANCE : 0}
            dy={
              horizontal
                ? // Hack to show tooltip above totals label.
                  // TODO: move this tooltip *into* the totals label rather
                  // than making it show up here.
                  -(barWidth / 2 + DEFAULT_TOTALS_FONT_SIZE)
                : BAR_TOOLTIP_DISTANCE
            }
            flyoutComponent={
              <BarChartTooltip<DatumSummary<D>>
                position={Position.TOP}
                getDatumLabel={formatDatumSummary}
                seriesKey={null}
              />
            }
          />
        ) : undefined
      }
      labels={nullLabel}
      data={barExtents}
      style={{
        data: {
          fill: 'var(--color-blue-grey-50)',
          fillOpacity: opacity,
          width: barWidth,
        },
        labels: {
          fill: 'none',
        },
      }}
    />
  );
}
