/**
 * UrbanFootprint App API
 * API for serving UrbanFootprint Application
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Spec for getting data from the scenario canvas.
 */
export interface ReportCanvasSourceSpec<> {
  /**
   * The column within the canvas layer to get data for.
   */
  column_key?: string;
  /**
   * The layer key within the canvas, such as development_layer
   */
  layer_key?: ReportCanvasSourceSpec.LayerKeyEnum;
}
export namespace ReportCanvasSourceSpec {
  export type LayerKeyEnum = 'development_layer' | 'painted_layer';
  export const LayerKeyEnum = {
    DevelopmentLayer: 'development_layer',
    PaintedLayer: 'painted_layer',
  } as const;
}
