import { Epic, ofType } from 'redux-observable';
import { map } from 'rxjs/operators';

import { SuccessAction } from 'uf/data/ActionTypes';
import { bindTaskToResource } from 'uf/tasks/actions';

export function makeBindEpic<SA extends SuccessAction<string>>(
  successActionType: string,
  bindActionType: string,
): Epic<SA, any> {
  return action$ => {
    return action$.pipe(
      ofType(successActionType),
      map(action =>
        bindTaskToResource(bindActionType, action.result, action.key),
      ),
    );
  };
}
