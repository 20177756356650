/**
 * UrbanFootprint App API
 * API for serving UrbanFootprint Application
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Return True when `left` == `right`.
 */
export interface ExpressionEquals<
  Tleft extends object = any,
  Tright extends object = any,
> {
  /**
   * Expression function, set to \"==\".
   */
  fn: ExpressionEquals.FnEnum;
  /**
   * left hand side
   */
  left: Tleft;
  /**
   * right hand side
   */
  right: Tright;
}
export namespace ExpressionEquals {
  export type FnEnum = '==';
  export const FnEnum = {
    '': '==',
  } as const;
}
