import {
  CuratedColumnSymbology,
  CUSTOM_THEME,
  DEFAULT_OPACITY,
  DistributionTypes,
  InterpolationTypes,
  PaintColorTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/public/dataset/hud_low_trans_cost_idx': {
    tcost_idx: [
      {
        type: SymbologyTypes.FILL,
        paint: {
          'fill-color': {
            property: 'tcost_idx',
            type: PaintColorTypes.INTERVAL,
            stops: [
              { value: 0, label: '0 to 19', color: '#ffbfe9' },
              { value: 20, label: '20 to 39', color: '#cf8aca' },
              { value: 40, label: '40 to 59', color: '#9f5bab' },
              { value: 60, label: '60 to 79', color: '#74318f' },
              { value: 80, label: '80 to 100', color: '#4d0073' },
            ],
          },
          'fill-opacity': DEFAULT_OPACITY,
        },
        display_hints: {
          scale: SymbologyScales.NUMERIC,
          distribution: DistributionTypes.DEFAULT,
          interpolation: InterpolationTypes.INTERVAL,
          theme: CUSTOM_THEME,
        },
      },
    ],
  },
};

export default symbologies;
