import { LayerColumn, LayerColumnStats } from 'uf-api';
import { ColumnKey, LayerId } from 'uf/layers';
import { FilterSpec } from 'uf/layers/filters';

export enum ChartMode {
  // Use whatever filters the explore view shows
  LIVE = 'LIVE',

  // Use the filters that are baked into the histogram
  // (if there are no filters, then this is irrelevant)
  FILTERED = 'FILTERED',

  // Don't apply filters: show data for the whole dataset
  UNFILTERED = 'UNFILTERED',
}

// TODO: deprecated.  We no longer have a button group that toggles between
// these so we don't need 'modes'.  remove this with #9027
export enum ChartTypeMode {
  // The custom charts / histograms that the user creates dynamicall
  CUSTOM = 'CUSTOM',

  // The Program Level Summary charts for painted layers
  PROGRAM_LEVEL_SUMMARY = 'PROGRAM_LEVEL_SUMMARY',

  // The charts that correspond with analysis outputs
  ANALYSIS = 'ANALYSIS',
}

export interface LayerChart {
  id: string;
  layerId: LayerId;
  columnKey: ColumnKey;
  filters?: Partial<FilterSpec>;
  filterMode?: ChartMode;
  weighByColumnKey?: string;
}

export interface ChartInfo {
  chart: LayerChart;
  columnMetadata: LayerColumn;
  columnStats: LayerColumnStats;
}
