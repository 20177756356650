import { AnchorButton } from '@blueprintjs/core';
import { mdiClose } from '@mdi/js';
import Icon from '@mdi/react';
import classNames from 'classnames';
import * as React from 'react';

import buttonStyles from 'uf/styles/buttons.module.css';
import iconStyles from 'uf/styles/icons.module.css';
import { IconSizeNew } from 'uf/ui/base/icon';

interface Props {
  className?: string;
  onClose?: React.MouseEventHandler;
  /**
   * icon to use for the close button.  defaults to mdiClose.
   */
  icon?: JSX.Element;
  small?: boolean;

  /**
   * Controls the components visibility
   */
  hidden?: boolean;
}

const closeIcon = <Icon path={mdiClose} size={IconSizeNew.SMALL} />;
const CloseButton: React.FunctionComponent<Props> = props => {
  const { onClose, className, small, icon = closeIcon, hidden = false } = props;

  const buttonClassName = classNames(
    className,
    buttonStyles.button,
    iconStyles.colorDefault,
    buttonStyles.light,
  );

  return (
    !hidden && (
      <AnchorButton
        minimal
        small={small}
        onClick={onClose}
        className={buttonClassName}
        icon={icon}
      />
    )
  );
};

export default CloseButton;
