import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { ThunkAction } from 'redux-thunk';

import { AnyFunction, FunctionParams } from 'uf/base/types';

/**
 * A hook to auto-bind an action creactor to the current store's dispatcher.
 * @param f
 */
export default function useBindAction<F extends AnyFunction>(
  f: F,
): ReturnType<F> extends ThunkAction<infer R, any, any, any>
  ? (...args: FunctionParams<F>) => R
  : (...args: FunctionParams<F>) => void {
  const dispatch = useDispatch();
  const callback = useCallback(
    (...args: FunctionParams<F>) => {
      return dispatch(f(...args));
    },
    [f, dispatch],
  );
  return callback as any;
}
