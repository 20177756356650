import { Feature, Polygon } from 'geojson';

import { SPLIT_PREVIEW_FEATURE } from 'uf/featuresplit/split';

/**
 * Build up a map of of oldFeatureId => [newFeature1, newFeature2, etc]
 */
export function extractNewFeatures(
  splitFeatures: [string, Feature<Polygon>][],
): Record<string, Feature<Polygon>[]> {
  let newFeatureMap: Record<string, Feature<Polygon>[]> = {};
  splitFeatures.forEach(([sourceFeatureId, newFeature]) => {
    const features = newFeatureMap[sourceFeatureId] ?? [];
    newFeatureMap[sourceFeatureId] = [...features, newFeature];
  });

  // one new feature from and old feature isn't a split, ignore those
  newFeatureMap = Object.fromEntries(
    Object.entries(newFeatureMap).filter(
      ([oldFeatureId, newFeatures]) => newFeatures.length > 1,
    ),
  );
  return newFeatureMap;
}
/** Take all feature edits and figure out which ones are splits or not */
export function partitionFeaturesBySplit(featureEdits: Feature<Polygon>[]) {
  const splitFeatures = featureEdits
    .filter(feature => feature.properties[SPLIT_PREVIEW_FEATURE])
    .map((feature): [string, Feature<Polygon>] => {
      const newFeature: Feature<Polygon> = {
        ...feature,
        properties: {
          ...feature.properties,
        },
      };
      const sourceFeatureIdProperty: string =
        newFeature.properties.split_source_feature_id;
      delete newFeature.properties[SPLIT_PREVIEW_FEATURE];
      delete newFeature.properties.split_source_feature_id;
      return [sourceFeatureIdProperty, newFeature];
    });
  const nonSplitFeatures = featureEdits.filter(
    feature => !feature.properties[SPLIT_PREVIEW_FEATURE],
  );
  return { splitFeatures, nonSplitFeatures };
}
