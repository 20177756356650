/**
 * UrbanFootprint App API
 * API for serving UrbanFootprint Application
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * The status and detailed status message for a dashboard run.
 */
export interface ProjectLayerAnalysisRunStatus<> {
  /**
   * The optional detailed status message
   */
  message?: string;
  /**
   * The status
   */
  status: ProjectLayerAnalysisRunStatus.StatusEnum;
}
export namespace ProjectLayerAnalysisRunStatus {
  export type StatusEnum =
    | 'created'
    | 'running'
    | 'processed'
    | 'loading'
    | 'complete'
    | 'error'
    | 'cancelled';
  export const StatusEnum = {
    Created: 'created',
    Running: 'running',
    Processed: 'processed',
    Loading: 'loading',
    Complete: 'complete',
    Error: 'error',
    Cancelled: 'cancelled',
  } as const;
}
