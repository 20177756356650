import { createSlice } from '@reduxjs/toolkit';
import { SET_ACTIVE_LAYER } from 'uf/explore/ActionTypes';
interface TableState {
  showOnlyFavorites: boolean;
}

const initialState: TableState = {
  showOnlyFavorites: false,
};

export const tableSlice = createSlice({
  name: 'table',
  initialState,
  reducers: {
    favoritesFilterEnabled: state => {
      state.showOnlyFavorites = true;
    },
    favoritesFilterDisabled: state => {
      state.showOnlyFavorites = false;
    },
  },
  extraReducers: builder => {
    // Reset table state on layer change
    builder.addCase(SET_ACTIVE_LAYER, () => {
      return initialState;
    });
  },
});

export const { favoritesFilterDisabled, favoritesFilterEnabled } =
  tableSlice.actions;

export const selectTableState = state => state.table;

export default tableSlice.reducer;
