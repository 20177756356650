import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import reduce from 'lodash/reduce';
import { sendAmplitudeEvent } from 'uf/amplitude/utils';
import { ThirdPartyAny } from 'uf/base/types';

export const events = {
  // Mode Bar Events
  MODE_SWITCHED: 'uf.main.mode.switched',

  // Map Events
  MAP_ZOOM_IN_CLICKED: 'uf.map.zoom_in.clicked',
  MAP_ZOOM_OUT_CLICKED: 'uf.map.zoom_out.clicked',
  MAP_CONTROL_BUTTON_CLICKED: 'uf.map.control_button.clicked',
  MAP_CLEAR_SELECTION_CLICKED: 'uf.map.clear_selection.clicked',
  MAP_CHARTS_CLICKED: 'uf.map.charts.clicked',
  MAP_BASE_LAYER_SELECTED: 'uf.map.base_layer.selected',
  MAP_CHANGE_SWATCH_COLOR: 'uf.map.symbology.swatch_color.saved',
  MAP_FEATURE_CLICKED: 'uf.maps.feature_clicked',
};

/**
 * Use this function to log events throughout the app that don't have redux actions
 * associated with them or if we need to capture information that isn't in an action.
 * (i.e. internal state, button clicks etc)
 * @param eventType Any string to denote what event this is to the logging system.
 * i.e. uf.explore.subsystem.action
 * @param extra Any extra information to pass along
 */
export function logEvent(eventType: string, extra?: ThirdPartyAny) {
  sendAmplitudeEvent(eventType, serialize(extra));
}

const SERIALIZE_PROPERTIES = new Set(['pointIds', 'layers', 'columns']);
/**
 * This will return a deep copy that serializes array values and objects with dynamic keys (ie pointIds).
 *
 * Amplitude flattens objects and arrays which causes things like
 * 'filterValue.3' to become a property.  Since amplitude limits the number
 * of event properties per project, we don't want this. After applying this
 * transformation, amplitude will show a property of 'filterValue' with a
 * JSON payload.
 *
 * Additionally, this will scan for certain properties and serialize those:
 *  * pointIds
 *  * layers
 *  * columns
 */
export function serialize(extra: ThirdPartyAny) {
  if (isArray(extra)) {
    return JSON.stringify(extra);
  }

  if (!isObject(extra)) {
    return extra;
  }

  return reduce(
    extra,
    (collection, value, property) => {
      if (SERIALIZE_PROPERTIES.has(property)) {
        collection[property] = JSON.stringify(value);
      } else {
        collection[property] = serialize(value);
      }
      return collection;
    },
    {},
  );
}
