import {
  DirectSelectOptions,
  DrawLineStringOptions,
  SimpleSelectOptions,
} from '@mapbox/mapbox-gl-draw';

import { EMPTY_ARRAY } from 'uf/base';
import {
  SELECT_FEATURE,
  SelectFeaturesAction,
  SET_DRAWING_MODE,
  SetDrawingModeAction,
} from 'uf/explore/ActionTypes';

export default function selectedFeatureIds(
  state: string[] = EMPTY_ARRAY,
  action: SelectFeaturesAction | SetDrawingModeAction,
): string[] {
  switch (action.type) {
    case SELECT_FEATURE: {
      return action.featureIds;
    }

    case SET_DRAWING_MODE: {
      if (action.mode === 'direct_select') {
        const directSelectOptions: DirectSelectOptions =
          action.modeOptions || {};
        // you must specify a featureId in direct_select mode, so this is safe
        return [directSelectOptions.featureId];
      }
      if (action.mode === 'simple_select') {
        const simpleSelectOptions: SimpleSelectOptions =
          action.modeOptions || {};

        if (simpleSelectOptions.featureIds) {
          return simpleSelectOptions.featureIds;
        }
        return EMPTY_ARRAY;
      }

      if (action.mode === 'draw_line_string') {
        const drawLineStringOptions: DrawLineStringOptions =
          action.modeOptions || {};
        if (drawLineStringOptions?.featureId) {
          return [drawLineStringOptions?.featureId];
        }
      }
      return EMPTY_ARRAY;
    }

    default:
      return state;
  }
}
