import {
  CuratedColumnSymbology,
  DistributionTypes,
  InterpolationTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/public/dataset/egrid_data_by_state_2016': {
    stc2erta: [
      {
        display_hints: {
          distribution: DistributionTypes.QUANTILES,
          interpolation: InterpolationTypes.INTERVAL,
          is_percent: false,
          is_reverse_theme: true,
          scale: SymbologyScales.NUMERIC,
          showZero: false,
          theme: 'uf:#8C5109,#D9B365,#F6E8C3,#F5F5F5,#C7EAE5,#59B4AC,#01665E',
        },
        paint: {
          'fill-color': {
            default: 'transparent',
            property: 'stc2erta',
            stops: [
              {
                color: 'rgb(1, 102, 94)',
                value: 316.792,
              },
              {
                color: 'rgb(61, 153, 145)',
                value: 502.135,
              },
              {
                color: 'rgb(126, 197, 190)',
                value: 771.539,
              },
              {
                color: 'rgb(188, 227, 222)',
                value: 871.69,
              },
              {
                color: 'rgb(227, 240, 236)',
                value: 930.013,
              },
              {
                color: 'rgb(243, 239, 225)',
                value: 1003.87,
              },
              {
                color: 'rgb(241, 225, 188)',
                value: 1048.311,
              },
              {
                color: 'rgb(224, 194, 132)',
                value: 1260.078,
              },
              {
                color: 'rgb(189, 144, 70)',
                value: 1477.945,
              },
              {
                color: 'rgb(140, 81, 9)',
                value: 1698.955,
              },
            ],
            type: 'interval',
          },
          'fill-opacity': 0.9,
        },
        type: SymbologyTypes.FILL,
      },
      {
        display_hints: {
          distribution: DistributionTypes.QUANTILES,
          interpolation: InterpolationTypes.INTERVAL,
          is_percent: false,
          scale: SymbologyScales.NUMERIC,
          showZero: false,
          theme: 'material:blue:range',
        },
        paint: {
          'line-color': 'transparent',
          'line-opacity': 0.9,
          'line-width': 0.5,
        },
        type: SymbologyTypes.LINE,
      },
    ],
  },
};

export default symbologies;
