import {
  CuratedColumnSymbology,
  DistributionTypes,
  InterpolationTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/public/dataset/us_census_rates_table_2012': {
    hh_agg_inc_rate: [
      {
        display_hints: {
          distribution: DistributionTypes.QUANTILES,
          interpolation: InterpolationTypes.INTERVAL,
          is_percent: false,
          scale: SymbologyScales.NUMERIC,
          showZero: true,
          theme: 'd3:interpolateRdYlGn',
        },
        paint: {
          'fill-color': {
            default: 'transparent',
            property: 'hh_agg_inc_rate',
            stops: [
              {
                color: 'rgb(165, 0, 38)',
                value: 34112.172,
              },
              {
                color: 'rgb(216, 56, 45)',
                value: 41458.16,
              },
              {
                color: 'rgb(244, 124, 74)',
                value: 47298.94,
              },
              {
                color: 'rgb(253, 190, 112)',
                value: 52687.387,
              },
              {
                color: 'rgb(254, 237, 161)',
                value: 58206,
              },
              {
                color: 'rgb(233, 245, 161)',
                value: 64374.751,
              },
              {
                color: 'rgb(182, 224, 118)',
                value: 71700.766,
              },
              {
                color: 'rgb(115, 195, 100)',
                value: 81275.828,
              },
              {
                color: 'rgb(40, 155, 81)',
                value: 95175.097,
              },
              {
                color: 'rgb(0, 104, 55)',
                value: 120096.774,
              },
            ],
            type: 'interval',
          },
          'fill-opacity': 0.5,
        },
        type: SymbologyTypes.FILL,
      },
      {
        display_hints: {
          distribution: DistributionTypes.QUANTILES,
          interpolation: InterpolationTypes.INTERVAL,
          is_percent: false,
          scale: SymbologyScales.NUMERIC,
          showZero: true,
          theme: 'custom',
        },
        paint: {
          'line-color': '#333333',
          'line-opacity': 0.5,
          'line-width': 0.5,
        },
        type: SymbologyTypes.LINE,
      },
    ],
  },
};

export default symbologies;
