import {
  CuratedColumnSymbology,
  DistributionTypes,
  InterpolationTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/public/dataset/phasocal_california_healthy_places_index': {
    hpi2_pctile_neg: [
      {
        display_hints: {
          distribution: DistributionTypes.CUSTOM,
          interpolation: InterpolationTypes.INTERVAL,
          is_percent: false,
          is_reverse_theme: true,
          scale: SymbologyScales.NUMERIC,
          showZero: false,
          theme: 'custom',
        },
        paint: {
          'fill-color': {
            default: 'transparent',
            property: 'hpi2_pctile_neg',
            stops: [
              {
                color: 'rgb(5, 48, 97)',
                value: 0,
              },
              {
                color: 'rgb(107, 172, 208)',
                value: 20,
              },
              {
                color: 'rgb(242, 239, 238)',
                value: 40,
              },
              {
                color: 'rgb(228, 130, 104)',
                value: 60,
              },
              {
                color: 'rgb(103, 0, 31)',
                value: 80,
              },
            ],
            type: 'interval',
          },
          'fill-opacity': 0.6,
        },
        type: SymbologyTypes.FILL,
      },
      {
        display_hints: {
          distribution: DistributionTypes.QUANTILES,
          interpolation: InterpolationTypes.INTERVAL,
          is_percent: false,
          scale: SymbologyScales.NUMERIC,
          showZero: false,
          theme: 'material:blue:range',
        },
        paint: {
          'line-color': 'transparent',
          'line-opacity': 0.6,
          'line-width': 1,
        },
        type: SymbologyTypes.LINE,
      },
    ],
    hpi2_pctile_pos: [
      {
        display_hints: {
          distribution: DistributionTypes.CUSTOM,
          interpolation: InterpolationTypes.INTERVAL,
          is_percent: false,
          scale: SymbologyScales.NUMERIC,
          showZero: true,
          theme: 'd3:interpolateRdBu',
        },
        paint: {
          'fill-color': {
            default: 'transparent',
            property: 'hpi2_pctile_pos',
            stops: [
              {
                color: 'rgb(103, 0, 31)',
                value: 0,
              },
              {
                color: 'rgb(228, 130, 104)',
                value: 20,
              },
              {
                color: 'rgb(242, 239, 238)',
                value: 40,
              },
              {
                color: 'rgb(107, 172, 208)',
                value: 60,
              },
              {
                color: 'rgb(5, 48, 97)',
                value: 80,
              },
            ],
            type: 'interval',
          },
          'fill-opacity': 0.6,
        },
        type: SymbologyTypes.FILL,
      },
      {
        display_hints: {
          distribution: DistributionTypes.QUANTILES,
          interpolation: InterpolationTypes.INTERVAL,
          is_percent: false,
          scale: SymbologyScales.NUMERIC,
          showZero: true,
          theme: 'material:blue:range',
        },
        paint: {
          'line-color': 'transparent',
          'line-opacity': 0.6,
          'line-width': 1,
        },
        type: SymbologyTypes.LINE,
      },
    ],
  },
};

export default symbologies;
