import { FeatureCollection, Geometries, points } from '@turf/helpers';
import { getCoords } from '@turf/invariant';
import { coordAll } from '@turf/meta';
import pointsWithinPolygon from '@turf/points-within-polygon';
import { Feature, Polygon } from 'geojson';
import _ from 'lodash';

/**
 * Gets the coordinate paths from a list of features that intersect with the
 * given rectangle. Useful for setting initial selection state in direct_select
 * mode.
 */
export function getIntersectingVerticesCoordPaths(
  rectangle: Feature<Polygon>,
  features: Feature<Geometries>[],
): Record<string /* feature id*/, string[] /* coord paths */> {
  const intersectingVerticesByFeature = {};
  features.forEach(feature => {
    const featureCoords = getCoords(feature);
    const intersectingPoints = pointsWithinPolygon(
      points(featureCoords),
      rectangle,
    );
    const intersectingCoords = coordAll(
      intersectingPoints as FeatureCollection,
    );

    // map the intersecting coordinates to their coord_paths
    intersectingVerticesByFeature[feature.id] = intersectingCoords.map(
      coord => {
        const indexOfIntersectingCoordInFeature = _.findIndex(
          featureCoords,
          featureCoord => _.isEqual(coord, featureCoord),
        );
        return `${indexOfIntersectingCoordInFeature}`;
      },
    );
  });

  return intersectingVerticesByFeature;
}
