import { AnyAction } from 'redux';
import { ActionsObservable, ofType } from 'redux-observable';
import { filter, map, mergeMap, tap } from 'rxjs/operators';

import { APP_INITIALIZED, PAGE_INITIALIZED } from 'uf/app/ActionTypes';
import { combineEpics, makeIntervalTimer } from 'uf/base/epics';
import { initializeIntercom, updateIntercom } from 'uf/intercom/actions';
import { SHOW_INTERCOM } from 'uf/intercom/ActionTypes';

function hasIntercom() {
  if (!(__CLIENT__ || __TESTING__)) {
    return false;
  }

  return !!window.Intercom;
}
export function initializeIntercomEpic(action$: ActionsObservable<AnyAction>) {
  return action$.pipe(
    ofType(PAGE_INITIALIZED),
    map(() => initializeIntercom()),
  );
}

export function showIntercomEpic(action$: ActionsObservable<AnyAction>) {
  return action$.pipe(
    ofType(SHOW_INTERCOM),
    tap(() => {
      if (hasIntercom()) {
        window.Intercom('show');
      }
    }),
    filter(() => false),
  );
}

const INTERCOM_CHECK_FREQUENCY_SECONDS = 60 * 60;
/**
 * An observable that fires every hour, but only when the window is
 * visible.
 */
const intercomCheckTick$ = makeIntervalTimer(
  INTERCOM_CHECK_FREQUENCY_SECONDS * 1000,
);

/**
 * This begins an infinite process to periodically ping the API server
 * to see if the client and the server are out of sync.
 */
export function updateIntercomEpic(action$: ActionsObservable<AnyAction>) {
  return action$.pipe(
    ofType(APP_INITIALIZED),
    filter(() => __CLIENT__),
    mergeMap(() =>
      intercomCheckTick$.pipe(
        map(() => {
          updateIntercom();
        }),
      ),
    ),
  );
}

export default combineEpics(
  {
    initializeIntercomEpic,
    showIntercomEpic,
    updateIntercomEpic,
  },
  'intercom',
);
