/**
 * UrbanFootprint App API
 * API for serving UrbanFootprint Application
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { ClientResponse } from '../variables';

// @ts-ignore: This is used only for params, but some services have param-free APIs
import { AnalysisServiceInterface } from './analysis.serviceInterface';

// @ts-ignore: These imports are for both params and returnType, but we only use returnType
import { AnalysisOutputLayerMetadata } from '../model/analysisOutputLayerMetadata';
// @ts-ignore: These imports are for both params and returnType, but we only use returnType
import { ProblemDetail } from '../model/problemDetail';

/**
 * Package: api
 */

const getAnalysisOutputModuleMetadataPrefix = `uf-api/analysis/get_analysis_output_module_metadata/`;
/**
 * Action types for analysis.get_analysis_output_module_metadata
 *
 * Get metadata for an analysis output module.
 */
export const getAnalysisOutputModuleMetadataActionTypes = {
  LOAD: `${getAnalysisOutputModuleMetadataPrefix}LOAD` as 'uf-api/analysis/get_analysis_output_module_metadata/LOAD',
  SUCCESS:
    `${getAnalysisOutputModuleMetadataPrefix}SUCCESS` as 'uf-api/analysis/get_analysis_output_module_metadata/SUCCESS',
  FAILURE:
    `${getAnalysisOutputModuleMetadataPrefix}FAILURE` as 'uf-api/analysis/get_analysis_output_module_metadata/FAILURE',
  CLEAR:
    `${getAnalysisOutputModuleMetadataPrefix}CLEAR` as 'uf-api/analysis/get_analysis_output_module_metadata/CLEAR',
} as const;

/**
 * LOAD action for analysis.get_analysis_output_module_metadata
 * Get metadata for an analysis output module.
 */
export interface getAnalysisOutputModuleMetadataLoadAction {
  readonly type: 'uf-api/analysis/get_analysis_output_module_metadata/LOAD';
  readonly params: AnalysisServiceInterface.getAnalysisOutputModuleMetadataParams;
  readonly promise: Promise<ClientResponse<AnalysisOutputLayerMetadata>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for analysis.get_analysis_output_module_metadata
 * Get metadata for an analysis output module.
 */
export interface getAnalysisOutputModuleMetadataSuccessAction {
  readonly type: 'uf-api/analysis/get_analysis_output_module_metadata/SUCCESS';
  readonly result: AnalysisOutputLayerMetadata;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for analysis.get_analysis_output_module_metadata
 * Get metadata for an analysis output module.
 */
export interface getAnalysisOutputModuleMetadataFailureAction {
  readonly type: 'uf-api/analysis/get_analysis_output_module_metadata/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for analysis.get_analysis_output_module_metadata
 * Get metadata for an analysis output module.
 */
export interface getAnalysisOutputModuleMetadataClearAction {
  readonly type: 'uf-api/analysis/get_analysis_output_module_metadata/CLEAR';
  readonly key: string;
}

export const analysisActionTypesMap = {
  /** Action types for analysis.get_analysis_output_module_metadata */
  get_analysis_output_module_metadata:
    getAnalysisOutputModuleMetadataActionTypes,
} as const;

export const analysisDetailsMap = {
  get_analysis_output_module_metadata: {
    /** HTTP method for analysis.get_analysis_output_module_metadata */
    httpMethod: 'get' as const,
  },
} as const;

/**
 * Local DataState<T> - kept in sync with uf/data/dataState.ts
 */
interface DataState<T> {
  readonly loading: boolean;
  readonly loaded: boolean;
  readonly requestId: string;
  readonly requestTime?: string;
  readonly responseTime?: string;
  readonly requestCount?: number;
  readonly error?: any;
  readonly promise?: Promise<T>;
  readonly data?: T;
  readonly extra?: any;
  readonly key?: string;
}
export interface AnalysisServiceState {
  /** getAnalysisOutputModuleMetadata state, by key */
  get_analysis_output_module_metadata: {
    readonly [key: string]: DataState<AnalysisOutputLayerMetadata>;
  };
}
