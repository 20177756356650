import {
  mdiAccountGroup,
  mdiArrowDecision,
  mdiCar,
  mdiCashMultiple,
  mdiCashUsd,
  mdiCityVariantOutline,
  mdiEarth,
  mdiFlash,
  mdiHandLeft,
  mdiHeartPulse,
  mdiHome,
  mdiInvertColors,
  mdiLeaf,
  mdiPineTree,
  mdiSubwayVariant,
  mdiWalk,
  mdiWeatherFog,
} from '@mdi/js';
import _ from 'lodash';
import materialColors from 'material-colors';

export interface AnalysisStyle {
  readonly key: string;
  readonly color: string;
  readonly iconPath: string;
  readonly shape?: string;
}
// This style data gets joined up with the actual analysis modules
// based on the 'key' property.
const waterDemand: AnalysisStyle = {
  key: 'water_demand',
  color: materialColors.lightBlue[500],
  iconPath: mdiInvertColors,
  shape: 'circle',
};

const ghgEmission: AnalysisStyle = {
  key: 'ghg_emission',
  color: materialColors.lightGreen[500],
  iconPath: mdiWeatherFog,
  shape: 'circle',
};

const fiscalRevenue: AnalysisStyle = {
  key: 'fiscal_revenue',
  color: materialColors.purple[500],
  iconPath: mdiCashMultiple,
  shape: 'circle',
};

const fiscalRevenueMadison: AnalysisStyle = {
  key: 'fiscal_revenue_madison',
  color: materialColors.purple[500],
  iconPath: mdiCashMultiple,
  shape: 'circle',
};

const vehicleMilesTraveledMadison: AnalysisStyle = {
  key: 'vmt_madison',
  color: materialColors.deepPurple[500],
  iconPath: mdiCar,
  shape: 'circle',
};

const vehicleMilesTraveledAnywhere: AnalysisStyle = {
  key: 'transportation_anywhere',
  color: materialColors.deepPurple[500],
  iconPath: mdiCar,
  shape: 'circle',
};

const householdCost: AnalysisStyle = {
  key: 'household_cost',
  color: materialColors.blue[500],
  iconPath: mdiHome,
  shape: 'circle',
};

const energyDemand: AnalysisStyle = {
  key: 'energy_demand',
  color: materialColors.orange[500],
  iconPath: mdiFlash,
  shape: 'circle',
};

const publicHealth: AnalysisStyle = {
  key: 'public_health',
  color: materialColors.red[500],
  iconPath: mdiHeartPulse,
  shape: 'circle',
};

const tripDistribution: AnalysisStyle = {
  key: 'trip_distribution',
  color: materialColors.black,
  iconPath: mdiArrowDecision,
  shape: 'circle',
};

const landConsumption: AnalysisStyle = {
  key: 'land_consumption',
  color: materialColors.green[500],
  iconPath: mdiPineTree,
  shape: 'circle',
};

const transitAccessibility: AnalysisStyle = {
  key: 'transit_accessibility',
  color: materialColors.deepOrange[500],
  iconPath: mdiSubwayVariant,
  shape: 'circle',
};

const walkAccessibility: AnalysisStyle = {
  key: 'walk_accessibility',
  color: materialColors.deepOrange[300],
  iconPath: mdiWalk,
  shape: 'circle',
};

const fiscalCost: AnalysisStyle = {
  key: 'fiscal_cost',
  color: materialColors.cyan[500],
  iconPath: mdiCashUsd,
  shape: 'circle',
};

const fiscalCostMadison: AnalysisStyle = {
  key: 'fiscal_cost_madison',
  color: materialColors.cyan[500],
  iconPath: mdiCashUsd,
  shape: 'circle',
};

const exampleModule: AnalysisStyle = {
  key: 'example',
  color: materialColors.pink[500],
  iconPath: mdiHandLeft,
};

const paintTracking: AnalysisStyle = {
  key: 'paint_tracking',
  color: materialColors.brown[500],
  iconPath: mdiCityVariantOutline,
};

// For the analysis module
const tncConservation: AnalysisStyle = {
  key: 'tnc_conservation',
  color: materialColors.green[500],
  iconPath: mdiLeaf,
};

// for the many different reporting tabs
const tncConservationCarbon: AnalysisStyle = {
  key: 'tnc_conservation_carbon',
  color: materialColors.green[500],
  iconPath: mdiLeaf,
};

const tncConservationWater: AnalysisStyle = {
  key: 'tnc_conservation_water',
  color: materialColors.green[500],
  iconPath: mdiLeaf,
};

const tncConservationHabitat: AnalysisStyle = {
  key: 'tnc_conservation_habitat',
  color: materialColors.green[500],
  iconPath: mdiLeaf,
};

const tncConservationAgriculture: AnalysisStyle = {
  key: 'tnc_conservation_agriculture',
  color: materialColors.green[500],
  iconPath: mdiLeaf,
};

const censusDemographics: AnalysisStyle = {
  key: 'census_demographics',
  color: materialColors.yellow[500],
  iconPath: mdiAccountGroup,
};

const resiliency: AnalysisStyle = {
  key: 'resiliency',
  color: materialColors.lightBlue[500],
  iconPath: mdiEarth,
};

const directRidership: AnalysisStyle = {
  key: 'direct_ridership',
  color: materialColors.yellow[500],
  iconPath: mdiSubwayVariant,
};

const aduFeasibility: AnalysisStyle = {
  key: 'accessory_dwelling_unit_feasibility',
  color: materialColors.yellow[500],
  iconPath: mdiHome,
};

const suitability: AnalysisStyle = {
  key: 'suitability',
  color: materialColors.yellow[500],
  iconPath: mdiEarth,
};

const analysisModules: AnalysisStyle[] = [
  waterDemand,
  ghgEmission,
  fiscalRevenue,
  fiscalRevenueMadison,
  fiscalCost,
  fiscalCostMadison,
  vehicleMilesTraveledMadison,
  vehicleMilesTraveledAnywhere,
  householdCost,
  energyDemand,
  publicHealth,
  landConsumption,
  tripDistribution,
  transitAccessibility,
  walkAccessibility,
  exampleModule,
  tncConservation,
  tncConservationCarbon,
  tncConservationWater,
  tncConservationHabitat,
  tncConservationAgriculture,
  resiliency,
  directRidership,
  aduFeasibility,
  suitability,

  // not really an analysis module
  // but this makes it show up in
  // the reporting mode
  paintTracking,
  censusDemographics,
];

export default _.keyBy(analysisModules, module => module.key);
