import { Feature } from 'geojson';

import { makeListActionCreatorsWithExtra } from 'uf/base/listActionHelpers';
import { LayerId } from 'uf/layers';
import { FilterSpec } from 'uf/layers/filters';
import {
  featureEditListActionTypes,
  LOAD_FEATURES_INTO_EDITS,
  LoadSelectionIntoEditsAction,
  SET_EDITS_DEFAULT_PROPERTIES,
  SetEditsDefaultPropertiesAction,
} from 'uf/mapedit/ActionTypes';
import { ProjectId } from 'uf/projects';

interface ExtraFeatureEditParams {
  projectId: ProjectId;
  layerId: LayerId;
  fromUser?: boolean;
}

/**
 *
 * @param projectId The project the edit is scoped to
 * @param layerId The layer being updated/overridden
 * @param fromUser Was this a programmatic edit or a user edit?
 */
function makeExtraParams(
  projectId: ProjectId,
  layerId: LayerId,
  fromUser?: boolean,
): ExtraFeatureEditParams {
  return {
    projectId,
    layerId,
    fromUser,
  };
}
export const featureEditListActions = makeListActionCreatorsWithExtra<
  Feature,
  Parameters<typeof makeExtraParams>,
  ExtraFeatureEditParams,
  typeof featureEditListActionTypes.SET,
  typeof featureEditListActionTypes.UPDATE,
  typeof featureEditListActionTypes.UPSERT,
  typeof featureEditListActionTypes.UPSERT_ITEMS,
  typeof featureEditListActionTypes.APPEND,
  typeof featureEditListActionTypes.REMOVE
>(featureEditListActionTypes, makeExtraParams);

export function loadFeaturesIntoEdits(
  projectId: ProjectId,
  layerId: LayerId,
  version: string,
  filters: Partial<FilterSpec>,
): LoadSelectionIntoEditsAction {
  return {
    type: LOAD_FEATURES_INTO_EDITS,
    projectId,
    layerId,
    version,
    filters,
  };
}

export function setEditsDefaultProperties<P = Feature['properties']>(
  projectId: ProjectId,
  layerId: LayerId,
  properties: P,
): SetEditsDefaultPropertiesAction<P> {
  return {
    type: SET_EDITS_DEFAULT_PROPERTIES,
    projectId,
    layerId,
    value: properties,
  };
}
