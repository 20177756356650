import { mdiInformation } from '@mdi/js';
import { Icon } from '@mdi/react';
import classNames from 'classnames';
import React from 'react';
import { Box } from 'react-layout-components';
import { t } from 'ttag';

import { LayerInfo } from 'uf/explore/layerOrdering';
import { ProjectId } from 'uf/projects';
import rootStyles from 'uf/styles/root.module.css';
import textStyles from 'uf/styles/text.module.css';

const messageBoxClass = classNames(
  rootStyles.lightBackground,
  rootStyles.thinMargin,
  rootStyles.thinPadding,
  rootStyles.mediumPaddingTop,
  rootStyles.mediumMaddingBottom,
);
interface LayerListMessageBoxProps {
  projectId: ProjectId;
  layers: LayerInfo[];
  defaultLayerIds: string[];
}

const LayerListMessageBox: React.FunctionComponent<LayerListMessageBoxProps> =
  ({ projectId, layers = [], defaultLayerIds = [] }) => {
    if (!projectId) {
      return (
        <Box
          column
          className={classNames(
            messageBoxClass,
            rootStyles.mediumPaddingBottom,
          )}>
          <Icon path={mdiInformation} size={2} />
          {t`Select or create a project from the project menu in the upper right.`}
        </Box>
      );
    }

    const onlyDefaultLayers = layers
      .filter(layerOrderInfo => !layerOrderInfo.isPlaceholderLayer)
      .every(layerOrderInfo =>
        defaultLayerIds.includes(layerOrderInfo.layerInfo.full_path),
      );
    if (!onlyDefaultLayers) {
      return null;
    }
    return (
      <Box column className={messageBoxClass} alignItems="center">
        <Icon path={mdiInformation} size={2} />
        <div
          className={
            textStyles.centerAlign
          }>{t`Search, add, or upload data layers by clicking the Add button.`}</div>
      </Box>
    );
  };

export default LayerListMessageBox;
