import { GridOptions } from 'uf/base/geo';
import * as ActionTypes from 'uf/explore/ActionTypes';
import { LayerId } from 'uf/layers';
import { ProjectId } from 'uf/projects';

/**
 * Action that emits the feature ids created from splitting a map feature. When the user changes how
 * to split a feature, we need to remove the old changes and update with the new ones.
 * @param projectId
 * @param layerId
 * @param featureIds
 */
export function setSplitFeatureIds(
  projectId: ProjectId,
  layerId: LayerId,
  featureIds: string[],
): ActionTypes.SetFeatureIdsFromSplit {
  return {
    type: ActionTypes.SET_FEATURE_IDS_FROM_SPLIT,
    projectId,
    layerId,
    featureIds,
  };
}

export function setTooManyFeatures(
  projectId: ProjectId,
  layerId: LayerId,
  value: boolean,
): ActionTypes.SetTooManyFeaturesAction {
  return {
    type: ActionTypes.SET_TOO_MANY_FEATURES,
    projectId,
    layerId,
    value,
  };
}

export function setFailedIntersections(
  projectId: ProjectId,
  layerId: LayerId,
  value: number,
): ActionTypes.SetFailedIntersections {
  return {
    type: ActionTypes.SET_FAILED_INTERSECTIONS,
    projectId,
    layerId,
    value,
  };
}

export function saveSplitFeatures(
  projectId: ProjectId,
  layerId: LayerId,
): ActionTypes.SaveSplitFeaturesAction {
  return {
    type: ActionTypes.SAVE_SPLIT_FEATURES,
    projectId,
    layerId,
  };
}

export function setGridOptions(
  gridOptions: GridOptions,
  projectId: ProjectId,
): ActionTypes.SetGridOptions {
  return {
    type: ActionTypes.SET_GRID_OPTIONS,
    value: gridOptions,
    projectId,
  };
}
