import { FormGroup, InputGroup } from '@blueprintjs/core';
import { boundMethod } from 'autobind-decorator';
import classNames from 'classnames';
import React, { Component } from 'react';
import { Box } from 'react-layout-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { t } from 'ttag';

import { normalizeError } from 'uf/base/swagger';
import { IMPORT_EXTENSIONS_AND_MIMETYPES } from 'uf/import/mimetypes';
import { uploadTemporaryShapes as uploadTemporaryShapesAction } from 'uf/projects/actions';
import FileUpload from 'uf/ui/base/FileUpload/FileUpload';
import MessageArea from 'uf/ui/base/MessageArea/MessageArea';
import { supportedBoundariesHint } from 'uf/ui/importer/text';

import styles from './UploadBoundary.module.css';

interface OwnProps {
  disabled?: boolean;
  filenameControlLabel?: string;
  onUpload?: (nameSuggestion: string, fileUploadKey: string) => void;
  onNameChange?: (newName: string) => void;
}

interface DispatchProps {
  uploadTemporaryShapes: (formData: Blob) => Promise<string>;
}
interface State {
  file: File;
  filename: string;
  error: any;
}

type Props = OwnProps & DispatchProps;

const uploadClassName = 'CreateProjectForm-projectArea-dropzone';

export class UploadBoundary extends Component<Props, State> {
  /**
   * The current promise of the uploaded file
   */
  currentPromise: Promise<string>;

  state = {
    file: null,
    filename: null,
    error: null,
  };

  @boundMethod
  onDrop(file: File, nameSuggestion: string) {
    if (!file) {
      // null file means drag was rejected
      return;
    }
    const { uploadTemporaryShapes, onUpload } = this.props;

    const fileUploadPromise = uploadTemporaryShapes(file);

    this.currentPromise = fileUploadPromise;
    fileUploadPromise
      .then(fileUploadKey => {
        if (this.currentPromise !== fileUploadPromise) {
          return;
        }
        this.currentPromise = null;
        this.setState({
          file,
          filename: nameSuggestion,
          // clear any errors from previous file upload
          error: null,
        });
        if (onUpload) {
          onUpload(nameSuggestion, fileUploadKey);
        }
      })
      .catch(normalizeError)
      .catch(error => {
        this.setState({
          file: null,
          filename: null,
          error,
        });
        this.currentPromise = null;
      });
  }
  @boundMethod
  onLayerNameChange(event) {
    const { onNameChange } = this.props;
    const {
      target: { value: newName },
    } = event;
    this.setState({ filename: newName });
    if (onNameChange) {
      onNameChange(newName);
    }
  }
  render() {
    const { file, filename, error } = this.state;
    const { disabled, filenameControlLabel } = this.props;
    const fileClassName = classNames({
      [styles.dropzoneWithFile]: !!(file && !error),
      [styles.dropzoneWithError]: !!error,
    });

    return (
      <Box column>
        <Box alignItems="center">
          <FileUpload
            accept={IMPORT_EXTENSIONS_AND_MIMETYPES}
            disabled={disabled}
            small
            className={uploadClassName}
            emptyMessage={
              <Box fit center className="h6">
                {t`Drop your GeoJSON, GeoPackage, or zipped shapefile here, or click to upload.`}
              </Box>
            }
            errorMessage={
              <Box fit center className="h6">
                {getErrorMessage(error)}
              </Box>
            }
            fileClassName={fileClassName}
            onDrop={this.onDrop}
            hasError={!!error}
          />
          {file && !error && (
            <FormGroup
              label={filenameControlLabel}
              className={styles.filenameField}>
              <InputGroup
                type="text"
                value={filename}
                onChange={this.onLayerNameChange}
              />
            </FormGroup>
          )}
        </Box>
        <MessageArea className={styles.messageArea}>
          {supportedBoundariesHint}
        </MessageArea>
      </Box>
    );
  }
}
export default connect<never, DispatchProps, OwnProps>(null, dispatch => ({
  ...bindActionCreators(
    { uploadTemporaryShapes: uploadTemporaryShapesAction },
    dispatch,
  ),
}))(UploadBoundary);

function getErrorMessage(error): string {
  if (!error) {
    return null;
  }
  switch (error.type) {
    case 'too_many_temp_geos':
      return t`Upload failed. Please try a file with fewer geometries.`;
    default:
      if (error.detail) {
        return error.detail;
      }
      return t`Something unexpected occurred. Please try again.`;
  }
}
