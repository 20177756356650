import {
  CuratedColumnSymbology,
  CUSTOM_THEME,
  DEFAULT_OPACITY,
  InterpolationTypes,
  PaintColorTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/public/dataset/dane_county_subparcels_land_use': {
    generalized_landuse: [
      {
        type: SymbologyTypes.FILL,
        paint: {
          'fill-color': {
            property: 'generalized_landuse',
            type: PaintColorTypes.CATEGORICAL,
            stops: [
              { value: 'AGRICULTURE', color: '#948A54' },
              { value: 'COMMERCIAL', color: '#FF0000' },
              { value: 'INDUSTRIAL', color: '#9900CC' },
              { value: 'INSTITUTIONAL/GOVERNMENTAL', color: '#595959' },
              { value: 'MANUFACTURING', color: '#FF99CC' },
              { value: 'MINERAL EXTRACTION', color: '#800000' },
              { value: 'OPEN LAND', color: '#92D050' },
              { value: 'RECREATION', color: '#92D050' },
              { value: 'RESIDENTIAL', color: '#FFFFCC' },
              {
                value: 'TRANSPORTATION, COMMUNICATIONS AND UTILITIES',
                color: '#222',
              },
              { value: 'UNDER CONSTRUCTION', color: '#0000FF' },
              { value: 'VACANT SUBDIVIDED LAND', color: '#333' },
              { value: 'WATER', color: '#b4b4f4' },
              { value: 'WHOLESALE AND RETAIL TRADE', color: '#494529' },
              { value: 'WOODLANDS', color: '#10924d' },
            ],
          },
          'fill-opacity': DEFAULT_OPACITY,
        },
        display_hints: {
          scale: SymbologyScales.CATEGORICAL,
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          theme: CUSTOM_THEME,
        },
      },
    ],
  },
};

export default symbologies;
