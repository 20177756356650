import { createSelector } from 'reselect';
import warning from 'warning';

import { makeGetFromPropsSelector } from 'uf/base/selector';
import { isPlaceHolder } from 'uf/explore/layerOrdering';
import { LayerId } from 'uf/layers';
import { LayerRole } from 'uf/layers/state';
import { ScenarioId } from 'uf/scenarios';

import {
  makeGetContextAreaLayerIdForProject,
  makeGetProjectAllReferenceLayerIds,
  makeGetProjectAllWorkingLayerIds,
  makeGetProjectAnalysisOutputLayerIds,
  makeGetProjectAreaLayerForProject,
  makeGetProjectBaseCanvasLayerId,
  makeGetProjectCanvasLayerIds,
} from './layers';

export type LayerRoleCallback = (layerId: LayerId) => LayerRole;

export function makeGetLayerRoleForProject() {
  return createSelector(
    makeGetProjectAreaLayerForProject(),
    makeGetContextAreaLayerIdForProject(),
    makeGetProjectBaseCanvasLayerId(),
    makeGetProjectCanvasLayerIds(),
    makeGetProjectAllReferenceLayerIds(),
    makeGetProjectAllWorkingLayerIds(),
    makeGetProjectAnalysisOutputLayerIds(),
    makeGetFromPropsSelector<LayerId, 'layerId'>('layerId'),
    getLayerRoleForProject,
  );
}
export function makeGetLayerRoleForProjectCallback() {
  return createSelector(
    makeGetProjectAreaLayerForProject(),
    makeGetContextAreaLayerIdForProject(),
    makeGetProjectBaseCanvasLayerId(),
    makeGetProjectCanvasLayerIds(),
    makeGetProjectAllReferenceLayerIds(),
    makeGetProjectAllWorkingLayerIds(),
    makeGetProjectAnalysisOutputLayerIds(),
    makeGetFromPropsSelector<ScenarioId, 'scenarioId'>('scenarioId'),
    (
      projectAreaLayerId,
      contextAreaLayerId,
      baseCanvasLayerId,
      canvasLayerIds,
      referenceLayerIds,
      workingLayerIds,
      analysisLayerIds,
    ): LayerRoleCallback => {
      return (layerId: LayerId) =>
        getLayerRoleForProject(
          projectAreaLayerId,
          contextAreaLayerId,
          baseCanvasLayerId,
          canvasLayerIds,
          referenceLayerIds,
          workingLayerIds,
          analysisLayerIds,
          layerId,
        );
    },
  );
}
function getLayerRoleForProject(
  projectAreaLayerId: string,
  contextAreaLayerId: string,
  baseCanvasLayerId: string,
  canvasLayerIds: string[],
  referenceLayerIds: string[],
  workingLayerIds: string[],
  analysisLayerIds: string[],
  layerId: LayerId,
): LayerRole {
  if (!layerId) {
    return LayerRole.UNKNOWN;
  }
  if (projectAreaLayerId === layerId) {
    return LayerRole.PROJECT_AREA;
  }
  if (contextAreaLayerId === layerId) {
    return LayerRole.CONTEXT_AREA;
  }
  if (layerId === baseCanvasLayerId) {
    return LayerRole.BASE_CANVAS;
  }
  if (canvasLayerIds.includes(layerId)) {
    return LayerRole.SCENARIO_CANVAS;
  }
  if (referenceLayerIds.includes(layerId)) {
    return LayerRole.REFERENCE;
  }
  if (analysisLayerIds.includes(layerId)) {
    return LayerRole.ANALYSIS;
  }
  if (workingLayerIds.includes(layerId)) {
    return LayerRole.WORKING;
  }
  if (layerId.startsWith('placeholder:')) {
    return LayerRole.UNKNOWN;
  }
  // should never happen
  warning(
    layerId && isPlaceHolder(layerId),
    `unknown layer type for layer ${layerId}`,
  );
  return LayerRole.UNKNOWN;
}
