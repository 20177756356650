import { Intent, Spinner } from '@blueprintjs/core';
import { mdiAlert, mdiCheckCircle, mdiTimerSand } from '@mdi/js';
import Icon from '@mdi/react';
import classNames from 'classnames';
import React, { ReactElement } from 'react';
import { Box } from 'react-layout-components';

import { Message } from 'uf/appservices/messages';
import { assertNever } from 'uf/base/never';
import iconStyles from 'uf/styles/icons.module.css';
import { tw } from 'uf/tailwindcss-classnames';
import { IconSize, IconSizeNew } from 'uf/ui/base/icon';

import styles from './Toast.module.css';

const successClassName = classNames(styles.icon, styles.successIcon);

const failureClassName = classNames(styles.icon, styles.failureIcon);

const stackStyle: React.CSSProperties = {
  maxHeight: '10em', // roughly 5 vertical lines
  wordWrap: 'break-word',
};

export function formatToastMessage(message: Message) {
  return (
    <Box column justifyContent="center">
      <span className={styles.message}>
        {message.isTrustedHtml ? (
          <pre
            className={tw('tw-bg-black')}
            dangerouslySetInnerHTML={{ __html: message.text as string }}
          />
        ) : (
          message.text
        )}{' '}
        {message.detail}
      </span>
      {__DEVELOPMENT__ && message.stack && (
        <pre style={stackStyle}>{message.stack}</pre>
      )}
    </Box>
  );
}

export function getMessageIcon(status: Message.MessageStatus): ReactElement {
  switch (status) {
    case 'success':
      return (
        <Icon
          path={mdiCheckCircle}
          size={IconSizeNew.MEDIUM}
          className={successClassName}
        />
      );
    case 'enqueued':
    case 'running':
      return (
        <Spinner
          className={styles.icon}
          intent={Intent.PRIMARY}
          size={IconSize.MEDIUM}
        />
      );

    case 'failure':
      return (
        <Icon
          path={mdiAlert}
          className={failureClassName}
          size={IconSizeNew.MEDIUM}
        />
      );

    case 'runningUntracked':
      return (
        <Icon
          path={mdiTimerSand}
          size={IconSizeNew.MEDIUM}
          className={classNames(iconStyles.colorDefault, styles.icon)}
        />
      );

    default:
      return assertNever(status);
  }
}
