import { Feature } from 'geojson';
import _ from 'lodash';
import { useCallback } from 'react';

import { PolygonGeometry } from 'uf/base/geometry';
import * as filterActionCreators from 'uf/explore/actions/filters';
import { ProjectId } from 'uf/projects';
import { rollbarError } from 'uf/rollbar';

export function useMapSelection(
  projectId: ProjectId,
  activeLayerId: string,
  selectedGeoKeys: string[],
  clearSelectionFilters: typeof filterActionCreators.clearSelectionFilters,
  updateGeometryFilter: typeof filterActionCreators.updateGeometryFilter,
  updatePointFilter: typeof filterActionCreators.updatePointFilter,
) {
  const onPolygonSelection = useCallback(
    (polygon: PolygonGeometry, add: boolean) => {
      handlePolygonSelection(
        projectId,
        activeLayerId,
        add,
        clearSelectionFilters,
        updateGeometryFilter,
        polygon,
      );
    },
    [projectId, activeLayerId, clearSelectionFilters, updateGeometryFilter],
  );
  const onPointSelection = useCallback(
    (features: Feature[], add: boolean) =>
      handlePointSelection(
        projectId,
        activeLayerId,
        selectedGeoKeys,
        features,
        add,
        clearSelectionFilters,
        updatePointFilter,
      ),
    [
      projectId,
      activeLayerId,
      selectedGeoKeys,
      clearSelectionFilters,
      updatePointFilter,
    ],
  );
  return { onPolygonSelection, onPointSelection };
}

function handlePolygonSelection(
  projectId: ProjectId,
  activeLayerId: string,
  add: boolean,
  clearSelectionFilters: typeof filterActionCreators.clearSelectionFilters,
  updateGeometryFilter: typeof filterActionCreators.updateGeometryFilter,
  polygon: PolygonGeometry,
) {
  if (!add) {
    clearSelectionFilters(projectId, activeLayerId);
  }
  updateGeometryFilter(projectId, activeLayerId, 'polygon', polygon);
}

function handlePointSelection(
  projectId: ProjectId,
  activeLayerId: string,
  currentGeoKeys: string[],
  features: Feature[],
  add: boolean,
  clearSelectionFilters: typeof filterActionCreators.clearSelectionFilters,
  updatePointFilter: typeof filterActionCreators.updatePointFilter,
) {
  try {
    const pointIds = features.map(({ properties }) => properties.geometry_key);
    if (_.isEmpty(pointIds)) {
      return;
    }
    if (!add) {
      clearSelectionFilters(projectId, activeLayerId);
    }
    updatePointFilter(projectId, activeLayerId, pointIds, currentGeoKeys, add);
  } catch (e) {
    rollbarError('Error in point selection', e, {
      featureCount: features?.length,
    });
  }
}
