import { Action as RFRAction } from 'redux-first-router';

import { AppMode } from 'uf/app/modes';
import { ROUTE_EXPLORE, ROUTE_EXPLORE_NEW } from 'uf/explore/ActionTypes';
import { Query } from 'uf/routing';
import { makeRoutingActionType } from 'uf/routing/actions';

export function setExploreRoute(mapOnly: boolean = false, mode?: AppMode) {
  const query: Query = {};

  if (mapOnly) {
    query.maponly = '1';
  } else if (mode) {
    query.mode = mode;
  }

  return {
    type: makeRoutingActionType(ROUTE_EXPLORE),
    meta: {
      query,
    },
  } as RFRAction;
}

export function setExploreNewRoute(
  namespace: string,
  projectKey: string,
  mapOnly: boolean = false,
  mode?: AppMode,
  flags?: string[],
): RFRAction {
  const query: Query = {};

  if (mapOnly) {
    query.maponly = '1';
  } else if (mode) {
    query.mode = mode;
  }

  return {
    type: makeRoutingActionType(ROUTE_EXPLORE_NEW),
    query,
    payload: {
      namespace,
      projectKey,
    },
  };
}
