import { AnyAction } from 'redux';
import { ActionsObservable, ofType } from 'redux-observable';
import { filter, map, mergeMap, scan } from 'rxjs/operators';

import { frontendVersionMismatch, loadFrontendVersion } from 'uf/app/actions';
import {
  APP_INITIALIZED,
  loadFrontendVersionActionTypes,
} from 'uf/app/ActionTypes';
import { BuildInfo } from 'uf/base/buildinfo';
import { makeIntervalTimer } from 'uf/base/epics';
import { SuccessAction } from 'uf/data/ActionTypes';

interface VersionAccumulator {
  version: BuildInfo;
  count: number;
}

const MAINTENANCE_MODE = 'maintenance_mode';

// The number of seconds to wait before notifying the user that a new version of
// the UF frontend has been deployed. Setting this to a high number allows us to
// deploy hotfixes without spamming our users, but setting it too low makes it
// less likely that users will pick up a critical update. Note though, that
// these are active seconds, meaning we do not count time while the browser is
// minimized or not in the foreground
const VERSION_MISMATCH_NOTIFICATION_DELAY_SECONDS = process.env
  .UF_NEW_VERSION_NOTIFICATION_DELAY_SECONDS
  ? parseInt(process.env.UF_NEW_VERSION_NOTIFICATION_DELAY_SECONDS, 10)
  : 10 * 60 * 60; // every 10 hours

// Ping once a minute.  Reduce this to once per 10 minutes in development.
const VERSION_PING_FREQUENCY_MINUTES = __DEVELOPMENT__ ? 10 : 1;

// This is effectively how many occurances of a version mismatch.
const VERSION_CHECK_FREQUENCY_SECONDS = VERSION_PING_FREQUENCY_MINUTES * 60;

const VERSION_MISMATCH_CHECK_COUNT = Math.floor(
  VERSION_MISMATCH_NOTIFICATION_DELAY_SECONDS / VERSION_CHECK_FREQUENCY_SECONDS,
);

/**
 * An observable that fires every minute, but only when the window is
 * visible.
 */
const versionCheckTick$ = makeIntervalTimer(
  VERSION_CHECK_FREQUENCY_SECONDS * 1000,
);

/**
 * This begins an infinite process to periodically ping the API server
 * to see if the client and the server are out of sync.
 */
export function beginVersionPing(action$: ActionsObservable<AnyAction>) {
  return action$.pipe(
    ofType(APP_INITIALIZED),
    filter(() => __CLIENT__),
    mergeMap(() =>
      versionCheckTick$.pipe(map(ellapsedMsec => loadFrontendVersion())),
    ),
  );
}

export function matchesMaintenanceMode(gitCommit: string): boolean {
  return gitCommit === MAINTENANCE_MODE;
}

export const NEW_VERSION_MESSAGE_ID = 'uf-new-version';

export function matchesEnvVersion(gitCommit: string) {
  return gitCommit === __BUILD_INFO__.git_commit;
}

/**
 * Reducer which accumulates the current running count of repeated versions.
 * When used with RxJS's scan() this emits a running count of the current version.
 */
export function distinctVersionWithCount(
  currentState: VersionAccumulator,
  {
    result,
  }: {
    result: BuildInfo;
  },
): VersionAccumulator {
  if (!currentState || currentState.version.git_commit !== result.git_commit) {
    return {
      version: result,
      count: 1,
    };
  }

  return {
    version: result,
    count: currentState.count + 1,
  };
}

/**
 * Watch for N versions in a row that do not match the current build, before
 * declaring a version mismatch
 */
export function alertVersionMismatch(
  action$: ActionsObservable<SuccessAction<BuildInfo>>,
) {
  return action$.pipe(
    ofType(loadFrontendVersionActionTypes.SUCCESS),
    filter(({ result }) => {
      const { git_commit: gitCommit } = result;
      return (
        gitCommit &&
        !matchesEnvVersion(gitCommit) &&
        !matchesMaintenanceMode(gitCommit)
      );
    }),
    scan(distinctVersionWithCount, null),
    filter(state => state.count === VERSION_MISMATCH_CHECK_COUNT),
    map(() => {
      return frontendVersionMismatch();
    }),
  );
}
