import _ from 'lodash';
import { createSelector } from 'reselect';
import { LayerReference } from 'uf-api';
import { makeGetApiObjectResults } from 'uf/api/selectors';
import { makeGetProjectLayerReferences } from 'uf/app/selectors';

import { EMPTY_ARRAY } from 'uf/base';
import { getSafeKey } from 'uf/base/keys';
import { makeGetFromPropsSelector } from 'uf/base/selector';
import { DataState } from 'uf/data/dataState';
import { ColumnKey } from 'uf/layers';
import { ProjectId } from 'uf/projects';
import { LegacyVirtualLayerId } from 'uf/projects/virtualLayers';
import { LayerColumnSymbology, LayerSymbologyEnvelope } from 'uf/symbology';
import { DivideByColumnKey } from 'uf/symbology/divideByColumn';
import { makeGetDivideByColumn } from 'uf/symbology/selectors/divideByColumn';
import { ViewId } from 'uf/views';
import { getViewsState } from 'uf/views/selectors';

/** Get all symbology for a project */
export function makeGetViewSymbologyList() {
  return createSelector(
    makeGetProjectLayerReferences(),
    makeGetApiObjectResults('project', 'get_symbology'),
    makeGetFromPropsSelector<ViewId, 'viewId'>('viewId'),
    (projectLayers: LayerReference[], symbologyStates, viewId) => {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const layerIds = new Set(projectLayers.map(({ full_path }) => full_path));
      return _.reduce(
        symbologyStates as Record<string, DataState<LayerSymbologyEnvelope>>,
        (result, dataState, key) => {
          // hacky exploit of makeSymbologyUserDataKey
          if (
            layerIds.has(key.split('|')[0]) &&
            key.endsWith(`|${viewId}`) &&
            dataState.data
          ) {
            result[key] = dataState.data;
          }
          return result;
        },
        {} as Record<string, LayerSymbologyEnvelope>,
      );
    },
  );
}

export function makeGetViewOverrideSymbologyState() {
  return createSelector(
    getViewsState,
    makeGetFromPropsSelector<ViewId, 'viewId'>('viewId'),
    makeGetFromPropsSelector<LegacyVirtualLayerId, 'virtualLayerId'>(
      'virtualLayerId',
    ),
    makeGetFromPropsSelector<ColumnKey, 'columnKey'>('columnKey'),
    makeGetDivideByColumn(),
    (
      viewsState,
      viewId,
      virtualLayerId,
      columnKey,
      divideByColumn,
    ): LayerColumnSymbology[] => {
      return (
        viewsState?.symbology?.override?.[viewId]?.[virtualLayerId]?.[
          columnKey
        ]?.[getSafeKey(divideByColumn)] || EMPTY_ARRAY
      );
    },
  );
}

export function makeGetViewOverrideSymbologyStateGetter() {
  return createSelector(
    getViewsState,
    viewsState =>
      (
        viewId: ViewId,
        virtualLayerId: LegacyVirtualLayerId,
        columnKey: ColumnKey,
        divideByColumn: DivideByColumnKey,
      ): LayerColumnSymbology[] => {
        return (
          viewsState?.symbology?.override?.[viewId]?.[virtualLayerId]?.[
            columnKey
          ]?.[getSafeKey(divideByColumn)] || EMPTY_ARRAY
        );
      },
  );
}

export function makeGetViewDivideByColumn() {
  return createSelector(
    getViewsState,
    makeGetFromPropsSelector<ProjectId, 'projectId'>('projectId'),
    makeGetFromPropsSelector<ViewId, 'viewId'>('viewId'),
    makeGetFromPropsSelector<LegacyVirtualLayerId, 'virtualLayerId'>(
      'virtualLayerId',
    ),
    makeGetFromPropsSelector<ColumnKey, 'columnKey'>('columnKey'),
    (
      viewsState,
      projectId,
      viewId,
      virtualLayerId,
      columnKey,
    ): DivideByColumnKey => {
      return viewsState?.symbology?.divideByColumn?.[projectId]?.[viewId]?.[
        virtualLayerId
      ]?.[columnKey];
    },
  );
}
