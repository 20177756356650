import { FormGroup, HTMLSelect } from '@blueprintjs/core';
import React, { useEffect } from 'react';
import { t } from 'ttag';
import { OrganizationInfo, UserAction } from 'uf-api';
import { useProjectTypes } from 'uf/ui/projects/hooks';
import OrganizationOption from './OrganizationOption';

interface OwnProps {
  organizations: OrganizationInfo[];
  currentOrganizationKey?: string;
  projectType: string;
  onChange: (e: any) => void;
  onHavePermission: (
    canPerform: boolean,
    disabledReason?: UserAction.ReasonEnum,
    resource?: string,
  ) => void;
}

const OrganizationSelect = ({
  organizations,
  currentOrganizationKey,
  projectType,
  onChange,
  onHavePermission,
}: OwnProps) => {
  const { projectTypes } = useProjectTypes(currentOrganizationKey);
  useEffect(() => {
    // Grab available project type keys
    const availableProjectTypeKeys = Object.keys(projectTypes);
    // Confirm org has access to currently active project type
    const isValidOrg = availableProjectTypeKeys.includes(projectType);
    if (!!currentOrganizationKey && !isValidOrg) {
      // Invalidate form on mismatched org when switching project types
      onChange({ target: { value: null } });
    }
  }, [currentOrganizationKey, projectType, projectTypes, onChange]);

  return (
    <FormGroup label={t`Organization`} labelInfo={t`(required)`}>
      <HTMLSelect
        name="organization"
        fill
        placeholder={t`Organization`}
        className={'CreateProjectForm-organization'}
        onChange={onChange}>
        {organizations.length === 0 && (
          <option disabled value={null}>{t`Not in any organizations`}</option>
        )}
        {!currentOrganizationKey && organizations.length > 0 && (
          <option value={null}>{t`Select one...`}</option>
        )}
        {organizations.length > 0 && (
          <>
            {organizations.map(organization => (
              <OrganizationOption
                key={organization.key}
                organization={organization}
                selectedOrg={currentOrganizationKey}
                projectType={projectType}
                onHavePermission={onHavePermission}
              />
            ))}
          </>
        )}
      </HTMLSelect>
    </FormGroup>
  );
};

export default OrganizationSelect;
