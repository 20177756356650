import {
  ADD_JOINED_LAYER_FILTER,
  CLEAR_BUFFER_FILTER,
  CLEAR_COLUMN_FILTERS,
  CLEAR_FILTERS,
  CLEAR_GEOMETRY_FILTERS,
  CLEAR_JOINED_LAYER_FILTER,
  CLEAR_POINT_FILTER,
  CLEAR_SELECTION_FILTERS,
  ENABLE_FILTERS,
  UPDATE_BUFFER_FILTER,
  UPDATE_COLUMN_FILTER,
  UPDATE_GEOMETRY_FILTERS,
  UPDATE_JOIN_LAYER_METHOD,
  UPDATE_POINT_FILTER,
} from 'uf/explore/ActionTypes';
import { makeFilterActions } from 'uf/layers/actions/filters';

export const {
  addJoinedLayerFilter,
  clearBufferFilter,
  clearColumnFilters,
  clearFilters,
  clearGeometryFilters,
  clearPointFilter,
  clearSelectionFilters,
  enableFilters,
  updateBufferFilter,
  updateColumnFilter,
  updateGeometryFilter,
  updatePointFilter,
  updateJoinLayerMethod,
  clearJoinedLayerFilter,
} = makeFilterActions<
  typeof ADD_JOINED_LAYER_FILTER,
  typeof CLEAR_BUFFER_FILTER,
  typeof CLEAR_COLUMN_FILTERS,
  typeof CLEAR_FILTERS,
  typeof CLEAR_GEOMETRY_FILTERS,
  typeof CLEAR_POINT_FILTER,
  typeof CLEAR_SELECTION_FILTERS,
  typeof ENABLE_FILTERS,
  typeof UPDATE_BUFFER_FILTER,
  typeof UPDATE_COLUMN_FILTER,
  typeof UPDATE_GEOMETRY_FILTERS,
  typeof UPDATE_POINT_FILTER,
  typeof UPDATE_JOIN_LAYER_METHOD,
  typeof CLEAR_JOINED_LAYER_FILTER
>(
  ADD_JOINED_LAYER_FILTER,
  CLEAR_BUFFER_FILTER,
  CLEAR_COLUMN_FILTERS,
  CLEAR_FILTERS,
  CLEAR_GEOMETRY_FILTERS,
  CLEAR_POINT_FILTER,
  CLEAR_SELECTION_FILTERS,
  ENABLE_FILTERS,
  UPDATE_BUFFER_FILTER,
  UPDATE_COLUMN_FILTER,
  UPDATE_GEOMETRY_FILTERS,
  UPDATE_POINT_FILTER,
  UPDATE_JOIN_LAYER_METHOD,
  CLEAR_JOINED_LAYER_FILTER,
);
