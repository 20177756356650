import _ from 'lodash';
import { Layer } from 'mapbox-gl';

import {
  getPaintOpacityKey,
  getPaintWidthKey,
} from 'uf/map/stylelayers/stylelayers';

/**
 * Dim all layers not in selectionStyleLayers
 */
export function getSalientSelectionLayers(
  styleLayers: Layer[],
  selectionStyleLayerIds: string[],
): Layer[] {
  if (_.isEmpty(selectionStyleLayerIds)) {
    return styleLayers;
  }

  return styleLayers.map(layer => {
    // we want these layers to stand out, don't reduce their opacity
    if (selectionStyleLayerIds.includes(layer.id)) {
      return layer;
    }

    const { type, paint } = layer;
    const opacityKey = getPaintOpacityKey(type);
    const widthKey = getPaintWidthKey(type);

    const newStyle: Layer = {
      ...layer,
      paint: {
        ...paint,
        [opacityKey]: 0.1,
      },
    } as Layer;

    if (widthKey) {
      newStyle.paint[widthKey] = 0.2;
    }

    return newStyle;
  });
}
