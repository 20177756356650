import { Spinner } from '@blueprintjs/core';
import React, { CSSProperties } from 'react';

import rootStyles from 'uf/styles/root.module.css';
import MapButton from 'uf/ui/map/MapButton/MapButton';

interface ExploreMapSpinnerProps {
  style?: CSSProperties;
  mapLoading?: boolean;
}

const ExploreMapSpinner: React.FunctionComponent<ExploreMapSpinnerProps> = ({
  mapLoading,
  style,
}) => {
  const buttonStyle: CSSProperties = {
    ...style,
    visibility: mapLoading ? 'visible' : 'hidden',
  };

  // don't render spinner when idle, integration tests wait for spinner's to not exist to proceed.
  const icon = mapLoading ? <Spinner size={18} /> : <i />;

  return (
    <MapButton
      style={buttonStyle}
      className={rootStyles.disablePointerEvents}
      icon={icon}
    />
  );
};

export default ExploreMapSpinner;
