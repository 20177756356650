import {
  CuratedColumnSymbology,
  InterpolationTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/public/dataset/caltrans_caltrans_district_boundaries': {
    region: [
      {
        display_hints: {
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          scale: SymbologyScales.CATEGORICAL,
          theme: 'custom',
        },
        paint: {
          'fill-color': {
            default: '#d62728',
            property: 'region',
            stops: [
              {
                color: '#16a5a5',
                value: 'Central',
              },
              {
                color: '#68bc00',
                value: 'North',
              },
              {
                color: '#cccccc',
                value: 'No Value',
              },
            ],
            type: 'categorical',
          },
          'fill-opacity': 0.4,
        },
        type: SymbologyTypes.FILL,
      },
      {
        display_hints: {
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          scale: SymbologyScales.CATEGORICAL,
          theme: 'd3:schemeCategory10',
        },
        paint: {
          'line-color': {
            default: '#d62728',
            property: 'region',
            stops: [
              {
                color: '#1f77b4',
                value: 'Central',
              },
              {
                color: '#ff7f0e',
                value: 'North',
              },
              {
                color: '#2ca02c',
                value: 'No Value',
              },
            ],
            type: 'categorical',
          },
          'line-opacity': 0.4,
          'line-width': 0.5,
        },
        type: SymbologyTypes.LINE,
      },
    ],
  },
};

export default symbologies;
