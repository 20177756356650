import { Dispatch } from 'redux';

import { makeEnsureActionCreator } from 'uf/data/loader';
import {
  makeBookmarkKey,
  makeBookmarkKeyPrefix,
  MapPositionEnvelope,
  wrapMapPosition,
} from 'uf/explore/bookmarks';
import { makeGetUserBookmarksState } from 'uf/explore/selectors/bookmarks';
import { MapPosition } from 'uf/map';
import { ProjectId } from 'uf/projects';
import {
  deleteUserData,
  loadUserData,
  loadUserDataItems,
  setUserData,
} from 'uf/user/actions/data';

/**
 * Load *all* bookmarks for a given (project, user) combination
 */
export function loadBookmarks(projectId: ProjectId, userKey: string) {
  // TODO: This is quick and dirty, and should be done with epics
  const prefix = makeBookmarkKeyPrefix(projectId);
  return (dispatch: Dispatch) =>
    dispatch(loadUserDataItems(userKey, prefix)).then(userDataItems => {
      return userDataItems.map(userDataItem => {
        const envelope: MapPositionEnvelope = userDataItem.value;
        return envelope.position;
      });
    });
}

/**
 * Load a single bookmark for a (project, user)
 */
export function loadBookmark(
  projectId: ProjectId,
  userKey: string,
  key: string,
) {
  return loadUserData(makeBookmarkKey(projectId, key), userKey);
}

/**
 * Save a single bookmark for a (project, user)
 */
export function saveBookmark(
  projectId: ProjectId,
  position: MapPosition,
  userKey: string,
) {
  const userDataKey = makeBookmarkKey(projectId, position.key);
  return setUserData(userDataKey, wrapMapPosition(position), userKey);
}

export function deleteBookmark(
  projectId: ProjectId,
  position: MapPosition,
  userKey: string,
) {
  const userDataKey = makeBookmarkKey(projectId, position.key);
  return deleteUserData(userDataKey, userKey);
}

export const ensureBookmarks = makeEnsureActionCreator(
  loadBookmarks,
  makeGetUserBookmarksState(),
  (projectId: ProjectId, userKey: string) => ({
    prefix: makeBookmarkKeyPrefix(projectId),
    userKey,
  }),
);
