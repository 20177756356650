import { useDebouncedCallback } from 'uf/ui/base/useDebouncedCallback/useDebouncedCallback';
import { useUniqCallback } from 'uf/ui/base/useUniqCallback/useUniqCallback';

/**
 * Create a debouncing callback that also only fires when the arguments change.
 *
 * This is a convenience: it is just a composition of `useDebouncedCallback` and
 * `useUniqCallback`, but the composition order is tricky.
 *
 * Make sure to use `useCallback` so that the function is not regenerated over and over:
 *
 * const onClick = useUniqDebounced(
 *   useCallback(() => doSomething(), [doSomething]),
 * );
 *
 */
export function useUniqDebounced<A extends any[]>(
  callback: (...args: A) => void,
  debounceTime: number,
) {
  // since this function is entirely dependent on just the callback itself,
  // which should be generated using `useCallback`, we do not need to pass along
  // any explicit deps.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useDebouncedCallback(
    useUniqCallback(callback, [callback]),
    [],
    debounceTime,
  );
}
