import { Action as RFRAction } from 'redux-first-router';

import { BUILT_FORM_TYPES } from 'uf/builtforms';
import {
  ROUTE_BUILT_FORM_EDITOR,
  ROUTE_BUILT_FORM_EDITOR_WITH_KEY,
} from 'uf/builtforms/ActionTypes';
import { makeRoutingActionType } from 'uf/routing/actions';

export function setBuiltFormEditorRoute(
  builtFormType: BUILT_FORM_TYPES,
  namespace: string,
  libraryKey: string,
): RFRAction {
  return {
    type: makeRoutingActionType(ROUTE_BUILT_FORM_EDITOR),
    payload: {
      type: builtFormType,
      namespace,
      libraryKey,
    },
  };
}

export function setBuiltFormEditorWithKeyRoute(
  builtFormType: BUILT_FORM_TYPES,
  namespace: string,
  libraryKey: string,
  builtFormKey?: string,
): RFRAction {
  return {
    type: makeRoutingActionType(ROUTE_BUILT_FORM_EDITOR_WITH_KEY),
    payload: {
      type: builtFormType,
      namespace,
      libraryKey,
      key: builtFormKey,
    },
  };
}
