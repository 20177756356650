import { t } from 'ttag';

import { LegacyChartSeries, LegacyReport } from 'uf/reporting/index';

const totalADUSeries: LegacyChartSeries = {
  key: 'total_adu_parcels',
  source: 'analysis',
  sourceKey: 'accessory_dwelling_unit_feasibility',
  layerKey: 'adu_feasibility_spatial_output',
  columnKey: 'du',
  stat: 'sum',
};

export const aduCharts: LegacyReport = Object.freeze({
  key: 'accessory_dwelling_unit_feasibility',
  name: 'Accessory Dwelling Units',
  charts: [
    {
      name: 'Total ADU Feasible Parcels',
      description: t`Count of parcels that could
    feasibly accommodate an ADU based on specified criteria`,
      key: 'count_adu_parcels',
      series: [totalADUSeries],
    },
  ],
});
