import colors from 'material-colors';

import {
  CuratedColumnSymbology,
  CUSTOM_THEME,
  DEFAULT_OPACITY,
  InterpolationTypes,
  PaintColorTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/public/dataset/toxic_release_inventory_2016': {
    industry_sector: [
      {
        type: SymbologyTypes.CIRCLE,
        paint: {
          'circle-radius': 4,
          'circle-color': {
            property: 'industry_sector',
            type: PaintColorTypes.CATEGORICAL,
            stops: [
              { value: 'Apparel', color: colors.orange[700] },
              { value: 'Beverages', color: colors.pink[500] },
              { value: 'Chemical Wholesalers', color: colors.red[700] },
              { value: 'Chemicals', color: colors.red[400] },
              { value: 'Coal Mining', color: colors.grey[900] },
              {
                value: 'Computers and Electronic Products',
                color: colors.teal[500],
              },
              { value: 'Electric Utilities', color: colors.deepPurple[500] },
              { value: 'Electrical Equipment', color: colors.yellow[500] },
              { value: 'Fabricated Metals', color: colors.blueGrey[800] },
              { value: 'Food', color: colors.lime[500] },
              { value: 'Furniture', color: colors.brown[500] },
              { value: 'Hazardous Waste', color: colors.red[500] },
              { value: 'Leather', color: colors.brown[500] },
              { value: 'Machinery', color: colors.blueGrey[500] },
              { value: 'Metal Mining', color: colors.grey[700] },
              {
                value: 'Miscellaneous Manufacturing',
                color: colors.blueGrey[300],
              },
              { value: 'Nonmetallic Mineral Product', color: colors.grey[500] },
              { value: 'Other', color: colors.blueGrey[200] },
              { value: 'Paper', color: colors.grey[300] },
              { value: 'Petroleum', color: colors.blueGrey[600] },
              {
                value: 'Petroleum Bulk Terminals',
                color: colors.blueGrey[600],
              },
              { value: 'Plastics and Rubber', color: colors.grey[700] },
              { value: 'Primary Metals', color: colors.blueGrey[500] },
              { value: 'Printing', color: colors.grey[300] },
              { value: 'Publishing', color: colors.grey[300] },
              { value: 'Textile Product', color: colors.amber[500] },
              { value: 'Textiles', color: colors.red[500] },
              { value: 'Tobacco', color: colors.brown[300] },
              { value: 'Transportation Equipment', color: colors.grey[700] },
              { value: 'Wood Products', color: colors.brown[700] },
            ],
          },
          'circle-opacity': DEFAULT_OPACITY,
        },
        display_hints: {
          scale: SymbologyScales.CATEGORICAL,
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          theme: CUSTOM_THEME,
        },
      },
    ],
  },
};

export default symbologies;
