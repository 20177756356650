import colors from 'material-colors';

import {
  CuratedColumnSymbology,
  CUSTOM_THEME,
  DEFAULT_OPACITY,
  DistributionTypes,
  InterpolationTypes,
  PaintColorTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/public/dataset/average_natural_gas_price_by_state_2015': {
    residential_average_natural_gas_price: [
      {
        type: SymbologyTypes.FILL,
        paint: {
          'fill-color': {
            property: 'residential_average_natural_gas_price',
            type: PaintColorTypes.INTERVAL,
            stops: [
              { value: 0, label: '0.00 to 0.20', color: '#fef2c0' },
              { value: 0.2, label: '0.20 to 0.40', color: '#fdd2a0' },
              { value: 0.4, label: '0.40 to 0.60', color: '#fac38e' },
              { value: 0.6, label: '0.60 to 0.80', color: '#f8b57d' },
              { value: 0.8, label: '0.80 to 1.00', color: '#f5a76b' },
              { value: 1.0, label: '1.00 to 1.20', color: '#f29859' },
              { value: 1.2, label: '1.20 to 1.40', color: '#f08a47' },
              { value: 1.4, label: '1.40 to 1.60', color: '#ed7c35' },
              { value: 1.6, label: '1.60 to 1.80', color: '#eb6e24' },
              { value: 1.8, label: '1.80 to 2.00', color: '#e85f12' },
              { value: 2, label: '2.00 or more', color: colors.orange[900] },
            ],
          },
          'fill-opacity': DEFAULT_OPACITY,
        },
        display_hints: {
          scale: SymbologyScales.NUMERIC,
          distribution: DistributionTypes.DEFAULT,
          interpolation: InterpolationTypes.INTERVAL,
          theme: CUSTOM_THEME,
        },
      },
    ],
  },
};

export default symbologies;
