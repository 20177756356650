import _ from 'lodash';

import { ColumnKey } from 'uf/layers';
import {
  DataDrivenStop,
  LayerColumnSymbology,
  PaintColorTypes,
  PaintProperty,
  PaintPropertyKey,
} from 'uf/symbology';

/**
 * Gets a property from the `Symbology#paint` field in a single symbology.
 * This takes into account the `Symbology#type` when locating the property.
 * @param symbology - a single symbology from a LayerColumnSymbology
 * @param propertySuffix - 'color', 'opacity', etc..
 * @return paintProperty - Typically an object for data-driven
 *                                         paint properties or a string for basic ones
 */
export function getPaintProperty<
  S extends DataDrivenStop = DataDrivenStop,
  P extends PaintProperty<S> = PaintProperty<S>,
>(
  symbology: LayerColumnSymbology,
  propertySuffix: string,
): P | string | number {
  const paintPropertyKey = makePaintPropertyKey(symbology, propertySuffix);
  const paintProperty = symbology.paint[paintPropertyKey];

  if (isDataDriven<P>(paintProperty)) {
    return paintProperty;
  }

  if (typeof paintProperty === 'string') {
    return paintProperty;
  }

  if (typeof paintProperty === 'number') {
    return paintProperty;
  }
}

export function makePaintPropertyKey(
  symbology: LayerColumnSymbology,
  propertySuffix: string,
): PaintPropertyKey {
  return `${symbology.type}-${propertySuffix}` as PaintPropertyKey;
}

export function isDataDriven<P>(
  paintProperty: P | number | string,
): paintProperty is P {
  if (
    !paintProperty ||
    typeof paintProperty === 'string' ||
    typeof paintProperty === 'number'
  ) {
    return false;
  }
  return true;
}

export function hasDataDrivenValues(
  symbologies: LayerColumnSymbology[],
): boolean {
  return symbologies.some(symbology =>
    _.some(symbology.paint, paintProperty => isDataDriven(paintProperty)),
  );
}

export function makePaintProperty<S, D>(
  stops: S[],
  defaultValue: D,
  columnKey: ColumnKey,
  type: PaintColorTypes,
): PaintProperty<S, D> | D {
  if (!stops || !stops.length) {
    return defaultValue;
  }

  return {
    property: columnKey,
    type,
    stops,
    default: defaultValue,
  };
}

export function makeTransparentPaintColor(
  paintColor: PaintProperty<DataDrivenStop, string>,
) {
  if (!isDataDriven(paintColor)) {
    return 'transparent';
  }
  return {
    ...paintColor,
    stops: paintColor.stops.map(stop => ({ ...stop, color: 'transparent' })),
  };
}
