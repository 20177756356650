import { setAnalysisEditorWithKeyRoute } from 'uf/analysiseditor/actions/routing';
import { parseFullPath } from 'uf/base/dataset';
import { ProjectId } from 'uf/projects';

export function linkToAnalysisEditorModule(
  projectId: ProjectId,
  moduleKey: string,
) {
  const { namespace, key } = parseFullPath(projectId, 'project');
  return setAnalysisEditorWithKeyRoute(namespace, key, moduleKey);
}
