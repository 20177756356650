import { FeatureCollection, Geometry } from 'geojson';
import { createSelector } from 'reselect';

import { makeGetApiObjectResults } from 'uf/api/selectors';
import { makeGetFromPropsSelector } from 'uf/base/selector';
import { DataState, DataStateMap, EmptyState } from 'uf/data/dataState';
import { LayerId } from 'uf/layers';
import { FilterSpec, getLayerDataApiParams } from 'uf/layers/filters';

export const getLayersGeojsonStates = makeGetApiObjectResults(
  'layer',
  'get_layer_geojson',
);

const EmptyFeatureCollectionState: DataState<FeatureCollection> = {
  ...EmptyState,
  key: null,
};

export function makeGetGeojsonByKey() {
  return createSelector(
    getLayersGeojsonStates,
    makeGetFromPropsSelector<string, 'key'>('key'),
    (states, key): DataState<FeatureCollection> => {
      const geojsonStates: DataStateMap<FeatureCollection> =
        states as DataStateMap<FeatureCollection>;
      return geojsonStates?.[key] ?? EmptyFeatureCollectionState;
    },
  );
}

export function makeGetLayerGeojson<
  G extends Geometry,
  P extends Record<string, any> = Record<string, any>,
>() {
  return createSelector(
    getLayersGeojsonStates,
    makeGetFromPropsSelector<LayerId, 'layerId'>('layerId'),
    makeGetFromPropsSelector<Partial<FilterSpec>, 'filters'>('filters'),
    makeGetFromPropsSelector<string, 'version'>('version'),
    (states, layerId, filters, version): DataState<FeatureCollection<G, P>> => {
      const { key } = getLayerDataApiParams(layerId, {
        filters,
        version,
      });
      return (states?.[key] ?? EmptyFeatureCollectionState) as DataState<
        FeatureCollection<G, P>
      >;
    },
  );
}
