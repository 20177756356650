import colors from 'material-colors';

import {
  CuratedColumnSymbology,
  CUSTOM_THEME,
  DEFAULT_OPACITY_ENVIRONMENTAL,
  InterpolationTypes,
  PaintColorTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

import stateList from './us_states';

const seaLevelRiseLevels = ['0', '1', '2', '3', '4', '5', '6'];

const seaLevelRiseVersions = ['', '_v2'];

const symbologies: Record<
  string,
  Record<string, CuratedColumnSymbology[]>
> = {};

stateList.forEach(stateName => {
  seaLevelRiseVersions.forEach(version => {
    seaLevelRiseLevels.forEach(footLevel => {
      symbologies[
        `/public/dataset/sea_level_rise_${footLevel}ft_${stateName}${version}`
      ] = {
        hydrologically_connected: [
          {
            type: SymbologyTypes.FILL,
            paint: {
              'fill-color': {
                property: 'hydrologically_connected',
                type: PaintColorTypes.CATEGORICAL,
                stops: [
                  { value: 'Yes', color: colors.teal[700] },
                  { value: 'No', color: colors.lime[700] },
                ],
              },
              'fill-opacity': DEFAULT_OPACITY_ENVIRONMENTAL,
            },
            display_hints: {
              scale: SymbologyScales.CATEGORICAL,
              distribution: null,
              interpolation: InterpolationTypes.CATEGORICAL,
              theme: CUSTOM_THEME,
            },
          },
        ],
      };
    });
  });
});

export default symbologies;
