import {
  CuratedColumnSymbology,
  InterpolationTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/public/dataset/city_of_madison_city_of_madison_zoning': {
    zoning_cod: [
      {
        display_hints: {
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          scale: SymbologyScales.CATEGORICAL,
          theme: 'custom',
        },
        paint: {
          'fill-color': {
            default: '#9edae5',
            property: 'zoning_cod',
            stops: [
              {
                color: '#dbdf00',
                value: 'A',
              },
              {
                color: '#b3b3b3',
                value: 'AP',
              },
              {
                color: '#ff7f0e',
                value: 'CC',
              },
              {
                color: '#fa28ff',
                value: 'CC-T',
              },
              {
                color: '#009ce0',
                value: 'CI',
              },
              {
                color: '#b0bc00',
                value: 'CN',
              },
              {
                color: '#d62728',
                value: 'DC',
              },
              {
                color: '#e27300',
                value: 'DR1',
              },
              {
                color: '#e27300',
                value: 'DR2',
              },
              {
                color: '#c5b0d5',
                value: 'EC',
              },
              {
                color: '#aea1ff',
                value: 'IG',
              },
              {
                color: '#aea1ff',
                value: 'IL',
              },
              {
                color: '#f44e3b',
                value: 'LMX',
              },
              {
                color: '#f44e3b',
                value: 'MXC',
              },
              {
                color: '#f44e3b',
                value: 'NMX',
              },
              {
                color: '#c7c7c7',
                value: 'PD',
              },
              {
                color: '#e27300',
                value: 'PMHP',
              },
              {
                color: '#68bc00',
                value: 'PR',
              },
              {
                color: '#fda1ff',
                value: 'SE',
              },
              {
                color: '#fda1ff',
                value: 'SEC',
              },
              {
                color: '#fcdc00',
                value: 'SR-C1',
              },
              {
                color: '#fcdc00',
                value: 'SR-C2',
              },
              {
                color: '#fcdc00',
                value: 'SR-C3',
              },
              {
                color: '#fb9e00',
                value: 'SR-V1',
              },
              {
                color: '#fb9e00',
                value: 'SR-V2',
              },
              {
                color: '#fda1ff',
                value: 'TE',
              },
              {
                color: '#fcdc00',
                value: 'TR-C1',
              },
              {
                color: '#fcdc00',
                value: 'TR-C2',
              },
              {
                color: '#fcdc00',
                value: 'TR-C3',
              },
              {
                color: '#fcdc00',
                value: 'TR-C4',
              },
              {
                color: '#fcdc00',
                value: 'TR-P',
              },
              {
                color: '#fcdc00',
                value: 'TR-R',
              },
              {
                color: '#fe9200',
                value: 'TR-U1',
              },
              {
                color: '#fe9200',
                value: 'TR-U2',
              },
              {
                color: '#fe9200',
                value: 'TR-V1',
              },
              {
                color: '#fe9200',
                value: 'TR-V2',
              },
              {
                color: '#fda1ff',
                value: 'TSS',
              },
              {
                color: '#d33115',
                value: 'UMX',
              },
              {
                color: '#d33115',
                value: 'UOR',
              },
            ],
            type: 'categorical',
          },
          'fill-opacity': 0.6,
        },
        type: SymbologyTypes.FILL,
      },
      {
        display_hints: {
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          scale: SymbologyScales.CATEGORICAL,
          theme: 'custom',
        },
        paint: {
          'line-color': '#999999',
          'line-opacity': 0.6,
          'line-width': 0.5,
        },
        type: SymbologyTypes.LINE,
      },
    ],
  },
};

export default symbologies;
