import { History } from 'history';
import { browserHistory } from 'react-router';
import { AnyAction } from 'redux';
import { ActionsObservable, Epic, ofType } from 'redux-observable';
import { NEVER, never as observableNever } from 'rxjs';
import {
  buffer,
  debounceTime,
  distinct,
  filter,
  map,
  mergeMap,
  scan,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { msgid, ngettext, t } from 'ttag';

import {
  getCurrentUserActionTypes,
  getCurrentUserSuccessAction,
} from 'uf-api/api/authentication.service';
import {
  createProjectActionTypes,
  createProjectSuccessAction,
  getProjectActionTypes,
  getProjectSuccessAction,
} from 'uf-api/api/project.service';
import { setActiveProject } from 'uf/app/actions';
import {
  APP_INITIALIZED,
  AppInitializedAction,
  FRONTEND_VERSION_MISMATCH,
  loadFrontendVersionActionTypes,
  SET_ACTIVE_PROJECT,
  SetActiveProjectAction,
} from 'uf/app/ActionTypes';
import { alertUserOnVersionMismatch } from 'uf/app/epics/notifications';
import {
  alertVersionMismatch,
  beginVersionPing,
  distinctVersionWithCount,
  matchesMaintenanceMode,
  NEW_VERSION_MESSAGE_ID,
} from 'uf/app/epics/version';
import {
  getActiveProject,
  getActiveProjectId,
  getActiveScenarioId,
} from 'uf/app/selectors';
import { addAppMessage } from 'uf/appservices/actions';
import { BuildInfo } from 'uf/base/buildinfo';
import { combineEpics } from 'uf/base/epics';
import { SuccessAction } from 'uf/data/ActionTypes';
import { getData, isLoaded } from 'uf/data/dataState';
import {
  addRumGlobalContext,
  configureRumProject,
  configureRumUser,
} from 'uf/datadog';
import {
  getLayerOrderKeys,
  getProjectHasLayerOrder,
} from 'uf/explore/selectors/layers';
import { makeGetActiveViewId } from 'uf/explore/selectors/views';
import { ProjectId } from 'uf/projects';
import { loadProject } from 'uf/projects/actions';
import { makeGetProjectLayerIdMap } from 'uf/projects/selectors/virtualLayers';
import { configureRollbarUser, setRollbarContextProperty } from 'uf/rollbar';
import { UFState } from 'uf/state';
import { NotificationTypes } from 'uf/tasks/NotificationTypes';
import { getPersistedVisibleLayerVirtualIdsState } from 'uf/user/selectors/layers';
import { getUser } from 'uf/user/selectors/user';
import { setLayerOrder, setLayerVisibility } from 'uf/views/actions/layers';
import { makeWebsocketActionType } from 'uf/websockets/actionHelpers';

import projectEpics from './project';

/**
 * Whenever a project is loaded reset the layer order.
 */
export const resetLayerOrderOnProjectLoad: Epic<getProjectSuccessAction, any> =
  (action$, state$) => {
    return action$.pipe(
      ofType(getProjectActionTypes.SUCCESS),
      withLatestFrom(state$),
      filter(([action, state]) => {
        const activeProject = getActiveProject(state);
        const { result: loadedProject } = action;
        return (
          activeProject && activeProject.full_path === loadedProject.full_path
        );
      }),
      // if there is no existing layer order
      filter(([action, state]) => {
        const projectId: ProjectId = action.result.full_path;
        return !getProjectHasLayerOrder(state, { projectId });
      }),
      // then assign the layer order to the default order
      map(([action, state]) => {
        const projectId = action.result.full_path;
        const scenarioId = getActiveScenarioId(state);
        const viewId = makeGetActiveViewId()(state, { projectId });
        const defaultLayerOrder = getLayerOrderKeys(state, {
          projectId,
          scenarioId,
          viewId,
        });
        return setLayerOrder(projectId, viewId, defaultLayerOrder);
      }),
    );
  };

/**
 * After a user creates a new project, switch to it immediately.
 */
export function makeActivateNewProjectEpic(browserHistoryLib: History) {
  return (action$: ActionsObservable<createProjectSuccessAction>) =>
    action$.pipe(
      ofType(createProjectActionTypes.SUCCESS),
      filter(
        () => browserHistoryLib.getCurrentLocation().pathname !== '/settings',
      ),
      map(action => {
        const { result: project } = action;
        const { full_path: projectId } = project;
        return setActiveProject(projectId);
      }),
    );
}

/**
 * Watch for 2 'site maintenance' messages before alerting the user.
 */
const RELOAD_TIMEOUT_MS = 15000;

export function alertUserOnMaintenanceMode(
  action$: ActionsObservable<SuccessAction<BuildInfo>>,
) {
  return action$.pipe(
    ofType(loadFrontendVersionActionTypes.SUCCESS),
    filter(({ result }) => {
      const { git_commit: gitCommit } = result;
      return matchesMaintenanceMode(gitCommit);
    }),
    scan(distinctVersionWithCount, null),
    filter(state => state.count === 2),
    map(() =>
      addAppMessage(
        t`UrbanFootprint is undergoing site maintenance. Normal site functionality will not be available during this period.`,
        {
          // Make sure we're only alerting the user once.
          replacesMessage: NEW_VERSION_MESSAGE_ID,
          id: NEW_VERSION_MESSAGE_ID,
          timeout: 0,
          status: 'failure',
        },
      ),
    ),
    // Reload the page after 15 seconds. This will redirect to the 'uf-maintenance'
    // ECS service which itself will periodically refresh, eventually loading the
    // UF app back when the maintenance period if complete.
    tap(() => {
      if (__CLIENT__) {
        setTimeout(() => {
          window.location.reload(true);
        }, RELOAD_TIMEOUT_MS);
      }
    }),
  );
}

// the project's canvas is created asynchronously when a project is created.  we need to wait
// until the canvas is available and then set it visible
export const setCanvasVisibleAfterProjectCreation: Epic<
  getProjectSuccessAction,
  any,
  any,
  UFState
> = (action$, state$) => {
  const getActiveProjectViewId = makeGetActiveViewId();
  const getProjectLayerIdMap = makeGetProjectLayerIdMap();
  return action$.pipe(
    // wait for project load success
    ofType(getProjectActionTypes.SUCCESS),
    // make sure there is a canvas layer
    filter(({ result: project }) => !!project?.base_scenario?.canvas_set),
    // only do this the first time we see the project has a base canvas set
    distinct(({ result: project }) => project.full_path),
    // only do this if we don't have persisted visible layers
    withLatestFrom(state$),
    filter(([action, state]) => {
      const activeProjectId = getActiveProjectId(state);
      const persistedVisibleLayerIdsState =
        getPersistedVisibleLayerVirtualIdsState(state, {
          projectId: activeProjectId,
        });

      const prefLoaded = isLoaded(persistedVisibleLayerIdsState);
      const prefValue = getData(persistedVisibleLayerIdsState, null);
      // If the value can't be loaded, or simply isn't set, then it is fine to
      // turn it on.
      const shouldSetVisible = !prefLoaded || !prefValue || !prefValue.value;
      return shouldSetVisible;
    }),
    // set canvas visible
    map(([{ result: project }, state]) => {
      const projectId = project.full_path;
      const viewId = getActiveProjectViewId(state, {
        projectId,
      });
      const layerMap = getProjectLayerIdMap(state, { projectId });
      const virtualLayerId =
        layerMap[project.base_scenario.base_edits_painted_canvas.full_path];
      return setLayerVisibility(projectId, virtualLayerId, viewId, true);
    }),
  );
};

const activateNewProject = makeActivateNewProjectEpic(browserHistory);

interface BufferTime {
  bufferTime?: number;
}

export function makeBufferedScenarioUpdateMessages(bufferTime = 500) {
  const bufferedScenarioUpdateMessages: Epic<any, any> & BufferTime =
    action$ => {
      return action$
        .ofType(
          makeWebsocketActionType(NotificationTypes.SCENARIO_UPDATE_BEGIN),
        )
        .pipe(
          // TODO: scope this to the "current" project, or scope app messages by project
          buffer(action$.pipe(debounceTime(bufferTime))),
          filter(actions => !!actions.length),
          map(actions => {
            const message = ngettext(
              msgid`Updating 1 scenario...`,
              `Updating ${actions.length} scenarios...`,
              actions.length,
            );
            return addAppMessage(message, {
              level: 'warning',
              status: 'runningUntracked',
            });
          }),
        );
    };
  return bufferedScenarioUpdateMessages;
}

export const setTracingContextProject: Epic<SetActiveProjectAction, any> =
  action$ => {
    return action$.pipe(
      ofType(SET_ACTIVE_PROJECT),
      tap(({ value: projectId }) => {
        setRollbarContextProperty('project_id', projectId);
        configureRumProject(projectId);
      }),
      mergeMap(() => observableNever()),
    );
  };

export const setTracingContextUser: Epic<getCurrentUserSuccessAction, any> =
  action$ => {
    return action$.pipe(
      ofType(getCurrentUserActionTypes.SUCCESS),
      tap(({ result: user }) => {
        configureRollbarUser(user.key, user.email, user.email);
        configureRumUser(user.key, user.email);
      }),
      mergeMap(() => observableNever()),
    );
  };

export const initializeTracingContext: Epic<AppInitializedAction, any> = (
  action$,
  state$,
) => {
  return action$.pipe(
    ofType(APP_INITIALIZED),
    withLatestFrom(state$),
    tap(([action, state]) => {
      const projectId = getActiveProjectId(state);
      if (projectId) {
        setRollbarContextProperty('project_id', projectId);
        configureRumProject(projectId);
      }
      const user = getUser(state);
      if (user) {
        configureRollbarUser(user.key, user.email, user.email);
        configureRumUser(user.key, user.email);
        setRollbarContextProperty('session_id', user.session_id);
        addRumGlobalContext('session_id', user.session_id);
      }
    }),
    mergeMap(() => NEVER),
  );
};
export const reloadProjectOnVersionChange: Epic<AnyAction, any> = (
  action$,
  state$,
) => {
  return action$.pipe(
    ofType(FRONTEND_VERSION_MISMATCH),
    withLatestFrom(state$),
    map(([action, state]) => {
      const activeProjectId = getActiveProjectId(state);
      return loadProject(activeProjectId);
    }),
  );
};

export default combineEpics(
  {
    beginVersionPing,
    alertVersionMismatch,
    alertUserOnVersionMismatch,
    alertUserOnMaintenanceMode,

    reloadProjectOnVersionChange,

    resetLayerOrderOnProjectLoad,

    // new project creation
    activateNewProject,
    setCanvasVisibleAfterProjectCreation,

    bufferedScenarioUpdateMessages: makeBufferedScenarioUpdateMessages(),

    setTracingContextProject,
    setTracingContextUser,
    initializeTracingContext,
    project: projectEpics,
  },
  'app',
);
