import { GeoJsonGeometryTypes } from 'geojson';
import { Expression, Layer } from 'mapbox-gl';
import colors from 'material-colors';

import { EMPTY_ARRAY } from 'uf/base';
import { cacheableConcat } from 'uf/base/array';
import { makeSelectionStyleLayers } from 'uf/explore/map/selection';

/**
 * Interface for styling drawing. Note that:
 *
 *  * A Vertex is an actual coordinate in a LineString or Polygon
 *  * A Midpoint is an artificial coordinate added by mapbox draw, which becomes
 *    a vertex when the user starts to draw it.
 *  * Styles of points in a point layer is different than styling of vertices or
 *    midpoints in a line or polygon layer
 */

interface DrawStyles {
  /** Point features not being edited:  */
  pointPointInactiveColor: string;
  /** Point features not being edited: circle stroke */
  pointFeatureOutlineColor: string;

  polyLineMidpointOutlineColor: string;
  polyLineMidpointActiveColor: string;
  polyLineMidpointColor: string;

  polyLineVertexInactiveColor: string;
  polyLineVertexStrokeInactiveColor: string;
}

// This is the hover color, but perhaps it should be the selection color?
const defaultPointColor = colors.amber.a700;
const defaultLineColor = colors.cyan[500];

export const defaultDrawStyles: DrawStyles = {
  pointPointInactiveColor: defaultPointColor,
  pointFeatureOutlineColor: colors.white,

  polyLineMidpointActiveColor: defaultPointColor,
  polyLineMidpointColor: defaultLineColor,
  polyLineMidpointOutlineColor: colors.white,

  polyLineVertexInactiveColor: defaultPointColor,
  polyLineVertexStrokeInactiveColor: colors.white,
};

export function makeDrawStyleLayers(
  featureType: GeoJsonGeometryTypes,
  styleLayers: Layer[],
  selectionStyleLayers: Layer[],
  drawStyles?: DrawStyles,
): Layer[] {
  if (!featureType) {
    return EMPTY_ARRAY;
  }
  // this is kind of a hack: we need to exclude the existing selection styles, if any
  // TODO: figure out how to get a selector *without* selection for a layer
  const selectionStyleLayerIds = selectionStyleLayers.map(({ id }) => id);
  const drawStyleLayers = styleLayers
    .filter(layer => !selectionStyleLayerIds.includes(layer.id))
    .map(layer => makeDrawLayer(layer, false));

  // Note that the first 4 parameters are just used to construct the source id, which we are deleting anyway
  const featureStyleLayers = makeSelectionStyleLayers(
    'draw-layer',
    null,
    'DRAW_COLUMN', // not used, will be replaced by `makeDrawLayer`, but necessary to generate anything
    null,
    featureType,
    [],
    true,
  );
  const drawFeatureStyleLayers = featureStyleLayers.map(layer => {
    const newLayer: Layer = makeDrawLayer(layer, true);
    return newLayer;
  });
  return cacheableConcat(
    drawStyleLayers,
    drawFeatureStyleLayers,
    makeDrawingLayers(drawStyles),
  );
}
/** Transforms a normal draw layer into one that can be used by mapbox-draw */
function makeDrawLayer(layer: Layer, active: boolean) {
  const newLayer: Layer = {
    ...layer,
    id: `${layer.id}-DRAW`,
    filter: [
      'all',
      IS_CURRENT_FEATURE,
      ['==', 'active', active ? 'true' : 'false'],
    ],
  };
  // These need to be removed, not just set to null/undefined
  delete newLayer['source-layer'];
  delete newLayer.source;
  return newLayer;
}

// Modes: static vs other modes (simple_select, etc)

/**
 * A feature is being edited right now
 */
const ANY_DRAW_MODE: Expression = ['!=', 'mode', 'static'];

// Things we are styling
/**
 * Style a point
 */
const IS_POINT: Expression = ['==', '$type', 'Point'];

//
// What part of a larger feature?
//

/**
 * This is a vertex of a polygon or line.
 */
const IS_VERTEX: Expression = ['==', 'meta', 'vertex'];
/**
 * This (point) is the entire feature, (i.e. editing a point on a point layer) as
 * opposed to just a part of it.
 */
const IS_CURRENT_FEATURE: Expression = ['==', 'meta', 'feature'];
/**
 * This isn't a real point (vertex) in the original line/polygon feature - it is
 * a midpoint that can be upgraded to a vertex.
 */
const IS_MIDPOINT: Expression = ['==', 'meta', 'midpoint'];

//
// Activeness
//

/** This item is not selected. */
const IS_NOT_ACTIVE: Expression = ['==', 'active', 'false'];

/** This item is selected. (applies to vertices or entire polygon/line) */
const IS_ACTIVE: Expression = ['==', 'active', 'true'];
/**
 * Creates hand-curated drawing layers, inspired by
 * https://github.com/mapbox/mapbox-gl-draw/blob/master/src/lib/theme.js
 *
 * In particular, these are the styles for 'meta' not set to 'feature', such as
 * 'meta': 'midpoint', etc.
 */
function makeDrawingLayers(styles: DrawStyles = defaultDrawStyles): Layer[] {
  const {
    pointPointInactiveColor,
    pointFeatureOutlineColor: pointPointInactiveStrokeColor,
    polyLineMidpointOutlineColor: polyLineMidpointStrokeColor,
    polyLineMidpointActiveColor,
    polyLineMidpointColor,
    polyLineVertexInactiveColor,
    polyLineVertexStrokeInactiveColor,
  } = {
    ...defaultDrawStyles,
    ...styles,
  };
  return [
    {
      id: 'gl-draw-polygon-midpoint',
      type: 'circle',
      filter: ['all', IS_POINT, IS_MIDPOINT],
      paint: {
        'circle-radius': 3,
        // Matches the cyan selection line in order to differentiate the vertices
        // in the actual feature from "synthetic" midpoints provided by gl-draw
        'circle-color': polyLineMidpointColor,
        'circle-stroke-color': polyLineMidpointStrokeColor,
        'circle-stroke-width': 1,
      },
    },
    {
      id: 'gl-draw-polygon-and-line-vertex-stroke-inactive',
      type: 'circle',
      filter: ['all', IS_POINT, IS_VERTEX, ANY_DRAW_MODE],
      paint: {
        'circle-radius': 5,
        'circle-color': polyLineVertexStrokeInactiveColor,
      },
    },
    {
      id: 'gl-draw-polygon-and-line-vertex-inactive',
      type: 'circle',
      filter: ['all', IS_POINT, IS_VERTEX, ANY_DRAW_MODE],
      paint: {
        'circle-radius': 3,
        'circle-color': polyLineVertexInactiveColor,
      },
    },
    {
      id: 'gl-draw-point-point-inactive',
      type: 'circle',
      filter: [
        'all',
        IS_POINT,
        IS_NOT_ACTIVE,
        IS_CURRENT_FEATURE,
        ANY_DRAW_MODE,
      ],
      paint: {
        'circle-radius': 3,
        'circle-opacity': 1,
        'circle-color': pointPointInactiveColor,
        'circle-stroke-color': pointPointInactiveStrokeColor,
        'circle-stroke-width': 1,
      },
    },
    {
      id: 'gl-draw-point-active',
      type: 'circle',
      filter: ['all', IS_POINT, IS_ACTIVE, IS_VERTEX],
      paint: {
        'circle-radius': 5,
        'circle-color': polyLineMidpointActiveColor,
        'circle-stroke-color': pointPointInactiveStrokeColor,
        'circle-stroke-width': 1,
      },
    },
    {
      id: 'gl-draw-vertex-active',
      type: 'circle',
      filter: ['all', IS_POINT, IS_ACTIVE, IS_CURRENT_FEATURE],
      paint: {
        'circle-radius': 5,
        'circle-color': polyLineMidpointActiveColor,
        'circle-stroke-color': pointPointInactiveStrokeColor,
        'circle-stroke-width': 1,
      },
    },
  ];
}
