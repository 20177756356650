/**
 * UrbanFootprint App API
 * API for serving UrbanFootprint Application
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { BillingPeriod } from './billingPeriod';

/**
 * An organization\'s subscription to a particular UF plan.
 */
export interface Subscription<> {
  billing_period?: BillingPeriod;
  /**
   * Cost, in cents, per billing period.
   */
  cost_per_billing_period?: number;
  /**
   * When the subscription expired, if it has expired.
   */
  expired_at?: Date;
  /**
   * Additional features that this plan is entitled to.
   */
  features?: Array<Subscription.FeaturesEnum>;
  /**
   * Number of projects that the org is entitled to; -1 means unlimited.
   */
  num_projects?: number;
  /**
   * Number of scenarios the org is entitled to; -1 means unlimited.
   */
  num_scenarios?: number;
  /**
   * Number of seats that the org is entitled to; -1 means unlimited.
   */
  num_seats?: number;
  /**
   * Code of the plan that the organization is subscribed to.
   */
  plan_code?: string;
  /**
   * The state of the subscription
   */
  state?: Subscription.StateEnum;
  /**
   * If in trial-period, When the trial period will end.
   */
  trial_end_date?: Date;
}
export namespace Subscription {
  export type FeaturesEnum =
    | 'layer.export'
    | 'layer.import'
    | 'project.read'
    | 'project.create'
    | 'scenario.create'
    | 'analysis.run'
    | 'builtforms.update';
  export const FeaturesEnum = {
    LayerExport: 'layer.export',
    LayerImport: 'layer.import',
    ProjectRead: 'project.read',
    ProjectCreate: 'project.create',
    ScenarioCreate: 'scenario.create',
    AnalysisRun: 'analysis.run',
    BuiltformsUpdate: 'builtforms.update',
  } as const;
  export type StateEnum = 'trial' | 'active' | 'expired';
  export const StateEnum = {
    Trial: 'trial',
    Active: 'active',
    Expired: 'expired',
  } as const;
}
