import classNames from 'classnames';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { ThunkActionMapDispatch } from 'redux-thunk';
import { t } from 'ttag';

import { getActiveProjectId, getActiveScenarioId } from 'uf/app/selectors';
import * as exploreActionCreators from 'uf/explore/actions';
import { setActiveView as setActiveViewAction } from 'uf/explore/actions/views';
import {
  getAllLayersState,
  makeGetOrderedLayerInfos,
} from 'uf/explore/selectors/layers';
import { makeGetActiveViewId } from 'uf/explore/selectors/views';
import { ProjectId } from 'uf/projects';
import ErrorBoundary from 'uf/ui/base/ErrorBoundary/ErrorBoundary';
import Pane from 'uf/ui/base/Pane/Pane';
import useBindAction from 'uf/ui/base/useBindAction/useBindAction';
import { useMakeSelector } from 'uf/ui/base/useMakeSelector';
import ExploreLayerList from 'uf/ui/explore/ExploreLayerList/ExploreLayerList';
import ExploreLayerListHeader from 'uf/ui/explore/ExploreLayerList/ExploreLayerListHeader';
import { ViewId } from 'uf/views';

interface OwnProps {
  width?: number;
}

interface StateProps {
  projectId: ProjectId;
}

interface DispatchProps {
  exploreActions?: ThunkActionMapDispatch<typeof exploreActionCreators>;
}

type Props = StateProps & DispatchProps & OwnProps;

export const ExploreLayerPanel: React.FunctionComponent<Props> = props => {
  const { width } = props;

  const containerClass = classNames(
    'explore-layer-panel-container',
    'LayerList',
  );
  const projectId = useSelector(getActiveProjectId);
  const scenarioId = useSelector(getActiveScenarioId);
  const viewId = useMakeSelector(makeGetActiveViewId, { projectId });

  const allLayers = useMakeSelector(makeGetOrderedLayerInfos, {
    projectId,
    scenarioId,
    viewId,
  });

  const layerCount = allLayers.filter(
    ({ isPlaceholderLayer }) => !isPlaceholderLayer,
  ).length;
  const layersState = useSelector(getAllLayersState);
  const setActiveView = useBindAction(setActiveViewAction);

  const onSelectView = useCallback(
    (id: ViewId) => {
      setActiveView(projectId, id);
    },
    [projectId, setActiveView],
  );
  return (
    <Pane column width={width} className={containerClass}>
      <ErrorBoundary
        title={t`Error loading project layers`}
        componentName="ExploreLayerPanel">
        <ExploreLayerListHeader
          projectId={projectId}
          layerCount={layerCount}
          loading={layersState.loading}
          viewId={viewId}
          onSelectView={onSelectView}
        />
        <ExploreLayerList projectId={projectId} viewId={viewId} />
      </ErrorBoundary>
    </Pane>
  );
};
