import { NonIdealState, Spinner, SpinnerSize } from '@blueprintjs/core';
import Icon from '@mdi/react';
import classNames from 'classnames';
import _ from 'lodash';
import React, { Component } from 'react';
import { Box } from 'react-layout-components';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ThunkActionDispatch } from 'redux-thunk';
import { t } from 'ttag';

import {
  AnalysisModuleInfo,
  ReportResults,
  ReportSpec,
} from 'uf-api/model/models';
import { ModuleRunStatus } from 'uf/analysis';
import { getModuleRunStatus } from 'uf/analysis/helpers';
import {
  getActiveProjectAnalysisModulesByKey,
  getAnalysisStatus,
} from 'uf/analysis/selectors/analysis';
import * as analysisEditorActionCreators from 'uf/analysiseditor/actions';
import { makeGetAnalysisModuleParamsByScenarioId } from 'uf/analysiseditor/selectors';
import { getActiveProjectId, getActiveScenarioId } from 'uf/app/selectors';
import { DataState, getData, isLoaded, isLoading } from 'uf/data/dataState';
import { getActiveAnalysisModuleKey } from 'uf/explore/selectors/analysis';
import { ProjectId } from 'uf/projects';
import { convertOldSpec, insertAccessibilityPOILabels } from 'uf/reporting';
import * as reportActionCreators from 'uf/reporting/actions';
import {
  getAnalysisChartsSpec,
  makeGetScenarioReportState,
} from 'uf/reporting/selectors';
import { ScenarioId } from 'uf/scenarios';
import rootStyles from 'uf/styles/root.module.css';
import AnalysisChart from 'uf/ui/analysis/AnalysisChart/AnalysisChart';
import AnalysisToolSwatch from 'uf/ui/analysis/AnalysisToolSwatch/AnalysisToolSwatch';
import moduleStyles from 'uf/ui/analysis/moduleStyles';
import { IconSizeNew } from 'uf/ui/base/icon';
import { DEFAULT_CHART_WIDTH } from 'uf/ui/charts/dimensions';

// TODO: This should be a selector once we have backend support
const moduleStylesByKey = _.keyBy(moduleStyles, 'key');

interface OwnProps {
  width?: number;
}

interface StateProps {
  projectId: ProjectId;
  scenarioId: ScenarioId;
  moduleKey: string;
  moduleStatus: ModuleRunStatus;
  modulesByKey: { [moduleKey: string]: AnalysisModuleInfo };
  spec: ReportSpec;
  reportState: DataState<ReportResults>;
}

interface DispatchProps {
  ensureScenarioAnalysisParams: ThunkActionDispatch<
    typeof analysisEditorActionCreators.ensureScenarioAnalysisParams
  >;
  ensureScenarioReport: ThunkActionDispatch<
    typeof reportActionCreators.ensureScenarioReport
  >;
}

type AnalysisChartsProps = OwnProps & StateProps & DispatchProps;

const containerBaseClassName = classNames('panel-body', 'AnalysisCharts');
export class AnalysisCharts extends Component<AnalysisChartsProps> {
  static defaultProps = {
    modulesByKey: {},
    width: DEFAULT_CHART_WIDTH,
  };

  componentDidMount() {
    this.ensureScenarioReport();
  }

  componentDidUpdate(prevProps: AnalysisChartsProps) {
    const { projectId, scenarioId, moduleKey, moduleStatus, reportState } =
      this.props;

    if (
      prevProps.projectId !== projectId ||
      prevProps.scenarioId !== scenarioId ||
      prevProps.moduleKey !== moduleKey ||
      prevProps.moduleStatus !== moduleStatus ||
      prevProps.reportState !== reportState
    ) {
      this.ensureScenarioReport();
    }
  }

  ensureScenarioReport() {
    const {
      projectId,
      scenarioId,
      ensureScenarioReport,
      ensureScenarioAnalysisParams,
      spec,
      moduleKey,
    } = this.props;
    if (projectId && scenarioId && spec) {
      ensureScenarioReport(projectId, scenarioId, spec);
      ensureScenarioAnalysisParams(projectId, scenarioId, moduleKey);
    }
  }

  render() {
    const {
      width,
      moduleKey,
      projectId,
      scenarioId,
      modulesByKey,
      spec,
      reportState,
    } = this.props;

    const containerClassName = classNames(
      rootStyles.lightBackground,
      rootStyles.mediumPaddingHorizontal,
      containerBaseClassName,
    );
    // Generally this only happens during project switching, while
    // the new project is loading.
    if (!moduleKey || !modulesByKey[moduleKey]) {
      return (
        <Box fit column center className={containerClassName}>
          <h4>{t`Please choose an analysis module.`}</h4>
        </Box>
      );
    }

    const module = modulesByKey[moduleKey];
    const moduleStyle = moduleStylesByKey[moduleKey];

    if (!spec) {
      return (
        <Box fit column center className={containerClassName}>
          <AnalysisToolSwatch iconPath={moduleStyle.iconPath} />
          <h4>{t`There are no charts associated with the ${module.name} analysis module.`}</h4>
        </Box>
      );
    }

    if (isLoading(reportState) && !isLoaded(reportState)) {
      return (
        <NonIdealState
          title={t`Loading charts for ${module.name}`}
          icon={<Spinner size={SpinnerSize.LARGE} />}
        />
      );
    }

    const report = getData(reportState);
    const { results = [] } = report;

    // this is kind of a hack until we can unify report results report states
    const haveReportData = results.some(result =>
      result.series.some(series =>
        series.scenarios.some(
          scenario => !!scenario.values && !!scenario.values.length,
        ),
      ),
    );

    if (!haveReportData) {
      return (
        <NonIdealState
          title={t`No charts available.`}
          description={t`Run ${module.name} analysis to view summarized results here.`}
          icon={<Icon path={moduleStyle.iconPath} size={IconSizeNew.LARGE} />}
        />
      );
    }

    return (
      <Box column className={containerClassName}>
        {spec.output_groups.map(outputGroup => (
          <AnalysisChart
            className={classNames(
              `AnalysisChart-${outputGroup.key}`,
              rootStyles.thinMarginVertical,
            )}
            key={outputGroup.key}
            projectId={projectId}
            scenarioId={scenarioId}
            spec={spec}
            outputGroup={outputGroup}
            width={width}
            height={300}
            moduleKey={moduleKey}
          />
        ))}
      </Box>
    );
  }
}

function makeMapStateToProps() {
  const getScenarioReportState = makeGetScenarioReportState();
  const getAnalysisModuleParamsByScenarioId =
    makeGetAnalysisModuleParamsByScenarioId();

  return (state): StateProps => {
    const scenarioId: ScenarioId = getActiveScenarioId(state);
    const projectId: ProjectId = getActiveProjectId(state);
    const specs = getAnalysisChartsSpec(state, { projectId });
    const moduleKey = getActiveAnalysisModuleKey(state) as string;

    let spec = convertOldSpec(moduleKey, specs);
    const analysisModuleParams = getAnalysisModuleParamsByScenarioId(state, {
      projectId,
      moduleKey,
    })[scenarioId];

    spec = insertAccessibilityPOILabels(
      getData(analysisModuleParams, null),
      spec,
    );

    const params = {
      projectId,
      scenarioId,
      spec,
      analysisModuleKey: moduleKey,
    };
    const moduleStatuses = getAnalysisStatus(state);
    const moduleStatus = getModuleRunStatus(
      moduleStatuses,
      moduleKey,
      projectId,
      scenarioId,
    );

    return {
      projectId,
      scenarioId,
      spec,
      moduleKey,
      moduleStatus,
      modulesByKey: getActiveProjectAnalysisModulesByKey(state),
      reportState: getScenarioReportState(state, params),
    };
  };
}
export default connect<StateProps, DispatchProps, OwnProps>(
  makeMapStateToProps,
  (dispatch: Dispatch) =>
    bindActionCreators(
      {
        ensureScenarioReport: reportActionCreators.ensureScenarioReport,
        ensureScenarioAnalysisParams:
          analysisEditorActionCreators.ensureScenarioAnalysisParams,
      },
      dispatch,
    ),
)(AnalysisCharts);
