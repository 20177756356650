/**
 * This reducer keeps track of the current layers and their states within the app.
 *
 * This includes:
 * - which layer is "active"
 * - which layers are "visible"
 * - Any current filters/queries used in each layer
 */
import _ from 'lodash';

import { EMPTY_ARRAY } from 'uf/base';
import { makeListReducer } from 'uf/base/reducerFactories';
import {
  CLEAR_ACTIVE_LAYER,
  ClearActiveLayerAction,
  SET_ACTIVE_LAYER,
  SET_LAYER_SEARCH_KEY,
  SetActiveLayerAction,
  SetLayerSearchKeyAction,
} from 'uf/explore/ActionTypes';
import { ExploreLayersState } from 'uf/explore/state';
import { LegacyVirtualLayerId } from 'uf/projects/virtualLayers';
import {
  CLEAR_VISIBLE_LAYERS,
  ClearVisibleLayersAction,
  SET_LAYER_MAP_COLUMN_KEY,
  SET_LAYER_ORDER,
  SET_LAYER_VISIBILITY,
  SetActiveColumnAction,
  SetLayerOrderAction,
  SetLayerVisibilityAction,
  visibleLayerListActionTypes,
} from 'uf/views/ActionTypes';

const initialState: ExploreLayersState = {
  activeLayerVirtualId: null,
  order: EMPTY_ARRAY,

  /*
   * mapping layerIds to their "current" queries, looks like:
   * {
   *   [layerId]: {
   *     query: { active query object, not yet defined },
   *     searchKey: <searchKey>,
   *   },
   *   ...
   * }
   */
  queryStates: {},

  /**
   * This is where we'll store information about mapping for this
   * layer. Each entry is keyed first by project id, then by layer id:
   * {
   *   [projectId]: {
   *     [layerId]: {
   *       // The currently mapped style key
   *       activeColumnKey: <columnKey>,
   *     },
   *   },
   * }
   */
  mapStyles: {},

  visibleLayerVirtualIds: [],
};

type ExploreLayersReducerActionTypes =
  | SetLayerVisibilityAction
  | SetActiveLayerAction
  | ClearActiveLayerAction
  | ClearVisibleLayersAction
  | SetLayerOrderAction
  | SetLayerSearchKeyAction
  | SetActiveColumnAction;

const visbilityReducer = makeListReducer<LegacyVirtualLayerId>(
  visibleLayerListActionTypes,
);
function layer(
  state: ExploreLayersState = initialState,
  action: ExploreLayersReducerActionTypes,
): ExploreLayersState {
  switch (action.type) {
    case SET_LAYER_VISIBILITY: {
      // The active layer is always forced to be visible. However, we do not
      // force a layer to be active if there isn't one already. This should be
      // ok, but if we ever *require* an active layer, this is probably where we
      // would set it.
      if (action.visible) {
        return {
          ...state,
          // use compact to protect against null layerId values
          visibleLayerVirtualIds: _.compact(
            _.union(state.visibleLayerVirtualIds, [action.virtualLayerId]),
          ),
        };
      }

      if (action.virtualLayerId === state.activeLayerVirtualId) {
        return {
          ...state,
          // hiding the active layer, so deactivate it
          activeLayerVirtualId: null,
          visibleLayerVirtualIds: _.without(
            state.visibleLayerVirtualIds,
            action.virtualLayerId,
          ),
        };
      }
      return {
        ...state,
        visibleLayerVirtualIds: _.without(
          state.visibleLayerVirtualIds,
          action.virtualLayerId,
        ),
      };
    }

    case SET_ACTIVE_LAYER: {
      // If a layer is set active, then it must be visible too.
      if (action.active) {
        return {
          ...state,
          activeLayerVirtualId: action.virtualLayerId,
          // use compact to protect against null layerId values, specifically  when unsetting the
          // active layer, the value becomes null, we don't want that in this list.
          visibleLayerVirtualIds: _.compact(
            _.union(state.visibleLayerVirtualIds, [action.virtualLayerId]),
          ),
        };
      }

      // explicitly deactivating the active layer, doesn't affect visbility
      if (action.virtualLayerId === state.activeLayerVirtualId) {
        return {
          ...state,
          activeLayerVirtualId: null,
        };
      }
      return state;
    }

    case CLEAR_ACTIVE_LAYER:
      return {
        ...state,
        activeLayerVirtualId: null,
      };

    case CLEAR_VISIBLE_LAYERS:
      return {
        ...state,
        activeLayerVirtualId: null,
        visibleLayerVirtualIds: [],
      };

    // TODO: remove this once selectors are updated to use uf.views slice of state
    case SET_LAYER_ORDER:
      if (_.isEqual(state.order, action.layerOrder)) {
        return state;
      }
      return {
        ...state,
        order: action.layerOrder,
      };

    case SET_LAYER_SEARCH_KEY:
      // Fired once the search has kicked off and the frontend has a
      // handle (i.e. the searchKey) on the search itself.
      return {
        ...state,
        queryStates: {
          ...state.queryStates,
          [action.layerId]: {
            ...state.queryStates[action.layerId],
            searchKey: action.searchKey,
          },
        },
      };

    case SET_LAYER_MAP_COLUMN_KEY: {
      const { virtualLayerId, columnKey } = action;

      if (
        virtualLayerId === state?.mapStyles?.[virtualLayerId]?.activeColumnKey
      ) {
        return state;
      }
      return {
        ...state,
        mapStyles: {
          ...state.mapStyles,
          [virtualLayerId]: {
            ...state?.mapStyles?.[virtualLayerId],
            activeColumnKey: columnKey,
          },
        },
      };
    }

    default: {
      // hack until we can break this reducer up a a bit
      const visibleLayerVirtualIds = visbilityReducer(
        state.visibleLayerVirtualIds,
        action,
      );
      if (visibleLayerVirtualIds !== state.visibleLayerVirtualIds) {
        return {
          ...state,
          visibleLayerVirtualIds,
        };
      }
      return state;
    }
  }
}

export default layer;
