import React from 'react';
import { jt, t } from 'ttag';
import InlineLink from 'uf/ui/base/InlineLink/InlineLink';

const geojsonio = (
  <InlineLink href="http://geojson.io" text="geojson.io" showIcon />
);

export const supportedBoundariesHint = jt`We currently support GeoJSON, GeoPackage, or zipped shapefiles.
Hint: You can easily create these file formats for any area at ${geojsonio} and then upload it here.
Note: UrbanFootprint does not support boundary layers that include two or more different types of geometries (e.g. polygons combined with lines or points). Please upload a boundary layer with a single geometry type (i.e. polygon or multipolygon only).`;

export const supportedLayersHint = jt`You can create these file formats for any area at ${geojsonio} and then upload them here. Also, all boundary layers must only include a single geometry (for example, polygon or multi-polygon).
All uploaded layers only appear in the current project’s layer list and are available to only other users within your team’s Analyst organization.`;

export const supportedTypesPrompt = (
  <>
    {t`Drop your GeoJSON, GeoPackage, zipped shapefile, or CSV (with WKT geometry column) here, or click to `}
    <span>
      <b>Upload</b>.
    </span>
  </>
);

export const generalFileUploadPrompt = t`Drag or select a file to upload...`;
