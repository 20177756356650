import {
  BuiltFormPainterEmploymentAllocations,
  BuiltFormPainterResidentialAllocation,
} from 'uf-api';
import { typeAssertNever } from 'uf/base/never';
import {
  ADD_TO_RECENT_PAINT_HISTORY,
  AddToRecentPaintHistoryAction,
  CLEAR_EMPLOYMENT_DIRECT_PAINTING,
  CLEAR_RESIDENTIAL_DIRECT_PAINTING,
  ClearEmploymentDirectPaintingAction,
  ClearResidentialDirectPaintingAction,
  SET_CURRENT_BUILT_FORM,
  SET_HOVER_BUILT_FORM,
  SetCurrentBuiltFormAction,
  SetHoverBuiltFormAction,
  UPDATE_EMPLOYMENT_DIRECT_PAINTING,
  UPDATE_RESIDENTIAL_DIRECT_PAINTING,
  UpdateEmploymentDirectPaintingAction,
  UpdateResidentialDirectPaintingAction,
} from 'uf/explore/ActionTypes';
import { PaintState } from 'uf/explore/state';
import { EmploymentEditMode } from 'uf/painting/employment';

export const initialState: PaintState = {
  // The built form used for painting
  current: {
    builtFormType: null,
    builtFormKey: null,
  },

  // The built form when hovering over a painting dropdown
  hover: {
    builtFormType: null,
    builtFormKey: null,
  },
  recent: [],
  residential: {
    mode: BuiltFormPainterResidentialAllocation.AllocationModeEnum.Feature,
    allocations: [],
    isZero: false,
  },
  employment: {
    allocationMode:
      BuiltFormPainterEmploymentAllocations.AllocationModeEnum.Feature,
    editMode: EmploymentEditMode.BuildingFloorArea,
    allocations: [],
    employeeTotal: null,
    isZero: false,
  },
};

type PaintActions =
  | SetHoverBuiltFormAction
  | SetCurrentBuiltFormAction
  | AddToRecentPaintHistoryAction
  | UpdateEmploymentDirectPaintingAction
  | ClearEmploymentDirectPaintingAction
  | UpdateResidentialDirectPaintingAction
  | ClearResidentialDirectPaintingAction;

export default function paint(
  state = initialState,
  action: PaintActions,
): PaintState {
  switch (action.type) {
    case SET_CURRENT_BUILT_FORM:
      return {
        ...state,
        current: {
          ...state.current,
          builtFormType: action.builtFormType,
          builtFormKey: action.builtFormKey,
        },
      };

    case SET_HOVER_BUILT_FORM:
      return {
        ...state,
        hover: {
          ...state.hover,
          builtFormType: action.builtFormType,
          builtFormKey: action.builtFormKey,
        },
      };

    case ADD_TO_RECENT_PAINT_HISTORY: {
      const withoutBuiltForm = state.recent.filter(
        ({ builtFormKey }) => builtFormKey !== action.builtFormKey,
      );

      const builtForm = {
        builtFormType: action.builtFormType,
        builtFormKey: action.builtFormKey,
      };

      return {
        ...state,
        // TODO: We should probably cap this to some fixed length.
        recent: [builtForm, ...withoutBuiltForm],
      };
    }

    case UPDATE_EMPLOYMENT_DIRECT_PAINTING: {
      return {
        ...state,
        employment: {
          ...state.employment,
          ...action.value,
        },
      };
    }

    case CLEAR_EMPLOYMENT_DIRECT_PAINTING: {
      return {
        ...state,
        employment: initialState.employment,
      };
    }

    case UPDATE_RESIDENTIAL_DIRECT_PAINTING: {
      return {
        ...state,
        residential: {
          ...state.residential,
          ...action.value,
        },
      };
    }

    case CLEAR_RESIDENTIAL_DIRECT_PAINTING: {
      return {
        ...state,
        residential: initialState.residential,
      };
    }

    default:
      typeAssertNever(action);
      return state;
  }
}
