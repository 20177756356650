import {
  CuratedColumnSymbology,
  CUSTOM_THEME,
  DEFAULT_OPACITY,
  DistributionTypes,
  InterpolationTypes,
  PaintColorTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/cityofmadison/dataset/madison_census_rates': {
    hh_agg_inc_rate: [
      {
        type: SymbologyTypes.FILL,
        paint: {
          'fill-color': {
            property: 'hh_agg_inc_rate',
            type: PaintColorTypes.INTERVAL,
            stops: [
              { value: 10000, color: 'rgb(122, 13, 13)' },
              { value: 20000, color: 'rgb(134, 24, 21)' },
              { value: 30000, color: 'rgb(145, 33, 30)' },
              { value: 40000, color: 'rgb(157, 43, 38)' },
              { value: 50000, color: 'rgb(169, 51, 47)' },
              { value: 60000, color: 'rgb(181, 60, 56)' },
              { value: 75000, color: 'rgb(193, 69, 65)' },
              { value: 100000, color: 'rgb(206, 78, 74)' },
              { value: 150000, color: 'rgb(218, 86, 83)' },
              { value: 200000, color: 'rgb(230, 95, 93)' },
              { value: 250000, color: 'rgb(243, 104, 103)' },
            ],
          },
          'fill-opacity': DEFAULT_OPACITY,
        },
        display_hints: {
          scale: SymbologyScales.NUMERIC,
          distribution: DistributionTypes.DEFAULT,
          interpolation: InterpolationTypes.INTERVAL,
          theme: CUSTOM_THEME,
        },
      },
    ],
  },
};

export default symbologies;
