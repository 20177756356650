import {
  CuratedColumnSymbology,
  CUSTOM_THEME,
  DEFAULT_OPACITY,
  InterpolationTypes,
  PaintColorTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/public/dataset/california_gw_recharge': {
    raster_val: [
      {
        type: SymbologyTypes.FILL,
        paint: {
          'fill-color': {
            property: 'raster_val',
            type: PaintColorTypes.CATEGORICAL,
            stops: [
              {
                value: 1,
                label: 'Far Below Hydrologic Region Average',
                color: '#f0f8ff',
              },
              {
                value: 2,
                label: 'Below Hydrologic Region Average',
                color: '#c0d6e4',
              },
              {
                value: 3,
                label: 'Average for the Hydrologic Region',
                color: '#6497b1',
              },
              {
                value: 4,
                label: 'Above Hydrologic Region Average',
                color: '#003366',
              },
              {
                value: 5,
                label: 'Far Above Hydrologic Region Average',
                color: '#0b1a4d',
              },
            ],
          },
          'fill-opacity': DEFAULT_OPACITY,
        },
        display_hints: {
          scale: SymbologyScales.CATEGORICAL,
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          theme: CUSTOM_THEME,
        },
      },
    ],
  },
};

export default symbologies;
