import {
  CuratedColumnSymbology,
  DistributionTypes,
  InterpolationTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const FRILayers = [
  {
    layerId: '/calthorpeanalytics/databank/fri_alabama_v1',
    columnKey: 'hh_foreclosure_risk_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fri_alaska_v1',
    columnKey: 'hh_foreclosure_risk_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fri_arizona_v1',
    columnKey: 'hh_foreclosure_risk_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fri_arkansas_v1',
    columnKey: 'hh_foreclosure_risk_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fri_california_v1',
    columnKey: 'hh_foreclosure_risk_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fri_colorado_v1',
    columnKey: 'hh_foreclosure_risk_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fri_connecticut_v1',
    columnKey: 'hh_foreclosure_risk_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fri_delaware_v1',
    columnKey: 'hh_foreclosure_risk_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fri_florida_v1',
    columnKey: 'hh_foreclosure_risk_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fri_georgia_v1',
    columnKey: 'hh_foreclosure_risk_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fri_hawaii_v1',
    columnKey: 'hh_foreclosure_risk_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fri_idaho_v1',
    columnKey: 'hh_foreclosure_risk_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fri_illinois_v1',
    columnKey: 'hh_foreclosure_risk_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fri_indiana_v1',
    columnKey: 'hh_foreclosure_risk_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fri_iowa_v1',
    columnKey: 'hh_foreclosure_risk_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fri_kansas_v1',
    columnKey: 'hh_foreclosure_risk_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fri_kentucky_v1',
    columnKey: 'hh_foreclosure_risk_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fri_louisiana_v1',
    columnKey: 'hh_foreclosure_risk_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fri_maine_v1',
    columnKey: 'hh_foreclosure_risk_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fri_maryland_v1',
    columnKey: 'hh_foreclosure_risk_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fri_massachusetts_v1',
    columnKey: 'hh_foreclosure_risk_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fri_michigan_v1',
    columnKey: 'hh_foreclosure_risk_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fri_minnesota_v1',
    columnKey: 'hh_foreclosure_risk_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fri_mississippi_v1',
    columnKey: 'hh_foreclosure_risk_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fri_missouri_v1',
    columnKey: 'hh_foreclosure_risk_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fri_montana_v1',
    columnKey: 'hh_foreclosure_risk_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fri_nebraska_v1',
    columnKey: 'hh_foreclosure_risk_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fri_nevada_v1',
    columnKey: 'hh_foreclosure_risk_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fri_new_hampshire_v1',
    columnKey: 'hh_foreclosure_risk_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fri_new_jersey_v1',
    columnKey: 'hh_foreclosure_risk_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fri_new_mexico_v1',
    columnKey: 'hh_foreclosure_risk_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fri_new_york_v1',
    columnKey: 'hh_foreclosure_risk_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fri_north_carolina_v1',
    columnKey: 'hh_foreclosure_risk_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fri_north_dakota_v1',
    columnKey: 'hh_foreclosure_risk_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fri_ohio_v1',
    columnKey: 'hh_foreclosure_risk_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fri_oklahoma_v1',
    columnKey: 'hh_foreclosure_risk_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fri_oregon_v1',
    columnKey: 'hh_foreclosure_risk_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fri_pennsylvania_v1',
    columnKey: 'hh_foreclosure_risk_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fri_rhode_island_v1',
    columnKey: 'hh_foreclosure_risk_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fri_south_carolina_v1',
    columnKey: 'hh_foreclosure_risk_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fri_south_dakota_v1',
    columnKey: 'hh_foreclosure_risk_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fri_tennessee_v1',
    columnKey: 'hh_foreclosure_risk_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fri_texas_v1',
    columnKey: 'hh_foreclosure_risk_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fri_utah_v1',
    columnKey: 'hh_foreclosure_risk_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fri_vermont_v1',
    columnKey: 'hh_foreclosure_risk_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fri_virginia_v1',
    columnKey: 'hh_foreclosure_risk_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fri_washington_v1',
    columnKey: 'hh_foreclosure_risk_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fri_west_virginia_v1',
    columnKey: 'hh_foreclosure_risk_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fri_wisconsin_v1',
    columnKey: 'hh_foreclosure_risk_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fri_wyoming_v1',
    columnKey: 'hh_foreclosure_risk_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/fri_district_of_columbia_v1',
    columnKey: 'hh_foreclosure_risk_pct',
  },
];

const symbologies: Record<
  string,
  Record<string, CuratedColumnSymbology[]>
> = {};
FRILayers.forEach(({ layerId, columnKey }) => {
  symbologies[layerId] = {
    [columnKey]: [
      {
        display_hints: {
          distribution: DistributionTypes.DEFAULT,
          interpolation: InterpolationTypes.INTERVAL,
          scale: SymbologyScales.NUMERIC,
          showZero: true,
          theme: 'd3:interpolateGnBu',
        },
        paint: {
          'fill-color': {
            property: columnKey,
            stops: [
              {
                color: '#FFFFE5',
                value: 0,
              },
              {
                color: '#FFF7BC',
                value: 6,
              },
              {
                color: '#FEE391',
                value: 9,
              },
              {
                color: '#FEC44F',
                value: 12,
              },
              {
                color: '#FE9929',
                value: 15,
              },
              {
                color: '#EC7014',
                value: 18,
              },
              {
                color: '#CC4C02',
                value: 21,
              },
              {
                color: '#8C2D04',
                value: 24,
              },
            ],
            type: 'interval',
          },
          'fill-opacity': 0.5,
        },
        type: SymbologyTypes.FILL,
      },
      {
        display_hints: {
          distribution: DistributionTypes.DEFAULT,
          interpolation: InterpolationTypes.INTERVAL,
          scale: SymbologyScales.NUMERIC,
          showZero: true,
          theme: 'custom',
        },
        paint: {
          'line-color': '#333333',
          'line-opacity': 0.5,
          'line-width': 0.5,
        },
        type: SymbologyTypes.LINE,
      },
    ],
  };
});

export default symbologies;
