/**
 * UrbanFootprint App API
 * API for serving UrbanFootprint Application
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Return a PostGIS Bounding Box object
 */
export interface ExpressionBoundingBox<> {
  /**
   * bounding box bounds in the order of [west, south, east, north]
   */
  bounds: Array<number>;
  /**
   * Expression function, set to \"bbox\".
   */
  fn: ExpressionBoundingBox.FnEnum;
}
export namespace ExpressionBoundingBox {
  export type FnEnum = 'bbox';
  export const FnEnum = {
    Bbox: 'bbox',
  } as const;
}
