/**
 * UrbanFootprint App API
 * API for serving UrbanFootprint Application
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Represents an in-flight update to a Project.
 */
export interface ProjectUpdate<Tparams extends object = any> {
  /**
   * Parameters for the update; schema is dependent on update_type.
   */
  params?: Tparams;
  /**
   * Time update was initiated, in RFC3339.
   */
  start_time: Date;
  /**
   * Time execution began, in RFC3339.
   */
  task_start_time?: Date;
  /**
   * Uniquely identifies this update.
   */
  uf_task_id?: string;
  /**
   * What kind of update is in-flight.
   */
  update_type: ProjectUpdate.UpdateTypeEnum;
  /**
   * Key of the user who initiated this update.
   */
  user_key: string;
}
export namespace ProjectUpdate {
  export type UpdateTypeEnum =
    | 'BAKE_BUILT_FORMS'
    | 'BASE_EDIT_BASE_SCENARIO_UPDATE'
    | 'SCHEMA_MIGRATE_PROJECT_UPDATE';
  export const UpdateTypeEnum = {
    BAKEBUILTFORMS: 'BAKE_BUILT_FORMS',
    BASEEDITBASESCENARIOUPDATE: 'BASE_EDIT_BASE_SCENARIO_UPDATE',
    SCHEMAMIGRATEPROJECTUPDATE: 'SCHEMA_MIGRATE_PROJECT_UPDATE',
  } as const;
}
