import {
  CuratedColumnSymbology,
  CUSTOM_THEME,
  DEFAULT_OPACITY,
  InterpolationTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const publicSchoolCharLayers = [
  {
    layerId: '/public/databank/nces_public_school_characteristics_2019_20',
    columnKey: 'gshi',
  },
];

const symbologies: Record<
  string,
  Record<string, CuratedColumnSymbology[]>
> = {};
publicSchoolCharLayers.forEach(({ layerId, columnKey }) => {
  symbologies[layerId] = {
    [columnKey]: [
      {
        display_hints: {
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          scale: SymbologyScales.CATEGORICAL,
          theme: CUSTOM_THEME,
        },
        paint: {
          'circle-radius': 3,
          'circle-color': {
            property: columnKey,
            stops: [
              {
                color: '#A4DD00',
                value: '01',
              },
              {
                color: '#A4DD00',
                value: '02',
              },
              {
                color: '#A4DD00',
                value: '03',
              },
              {
                color: '#A4DD00',
                value: '04',
              },
              {
                color: '#A4DD00',
                value: '05',
              },
              {
                color: '#007EE6',
                value: '06',
              },
              {
                color: '#007EE6',
                value: '07',
              },
              {
                color: '#007EE6',
                value: '08',
              },
              {
                color: '#DD0036',
                value: '09',
              },
              {
                color: '#DD0036',
                value: '10',
              },
              {
                color: '#DD0036',
                value: '11',
              },
              {
                color: '#DD0036',
                value: '12',
              },
              {
                color: '#EEAAC8',
                value: 'KG',
              },
              {
                color: '#EEAAC8',
                value: 'PK',
              },
              {
                color: '#AEAAEE',
                value: 'M',
              },
            ],
            type: 'categorical',
          },
          'circle-opacity': DEFAULT_OPACITY,
        },
        type: SymbologyTypes.CIRCLE,
      },
    ],
  };
});

export default symbologies;
