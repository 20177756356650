import colors from 'material-colors';
import { t } from 'ttag';

import { getUnitName, MT, PASSENGER_VEHICLES } from 'uf/base/units';
import { LegacyChart, LegacyReport } from 'uf/reporting/index';

import { defaultEmptyChartContent } from './emptyChartMessage';

const tncSource = {
  source: 'analysis',
  sourceKey: 'tnc_conservation',
} as const;
const NetAboveGroundSeries = {
  ...tncSource,
  key: 'net_above_ground_carbon',
  layerKey: 'tnc_conservation_carbon_content_net',
  columnKey: 'above_ground',
  color: colors.lime[800],
};

const NetBelowGroundSeries = {
  ...tncSource,
  key: 'net_below_ground_carbon',
  layerKey: 'tnc_conservation_carbon_content_net',
  columnKey: 'below_ground',
  color: colors.brown[800],
};

const TotalAboveGroundSeries = {
  ...tncSource,
  key: 'total_above_ground_carbon',
  layerKey: 'tnc_conservation_carbon_content_total',
  columnKey: 'above_ground',
  color: colors.lime[800],
};

const TotalBelowGroundSeries = {
  ...tncSource,
  key: 'total_below_ground_carbon',
  layerKey: 'tnc_conservation_carbon_content_total',
  columnKey: 'below_ground',
  color: colors.brown[800],
};

const NetAboveGroundEquivalentSeries = {
  ...tncSource,
  key: 'net_above_ground_carbon_equivalent',
  layerKey: 'tnc_conservation_carbon_content_equivalent_net',
  columnKey: 'above_ground_equivalent',
  color: colors.lime[800],
};

const NetBelowGroundEquivalentSeries = {
  ...tncSource,
  key: 'net_below_ground_carbon_equivalent',
  layerKey: 'tnc_conservation_carbon_content_equivalent_net',
  columnKey: 'below_ground_equivalent',
  color: colors.brown[800],
};

export const tncChartsSharedCarbon: LegacyChart[] = [
  {
    name: t`Net Change in Carbon Stock`,
    key: 'net_carbon_stock',
    description: t`Net Change in Carbon Stock`,
    series: [NetBelowGroundSeries, NetAboveGroundSeries],
    units: getUnitName(MT),
    emptyChartContent: defaultEmptyChartContent,
    showLegend: true,
    showFullLabel: true,
  },
  {
    name: t`Net Change in Above Ground Carbon Stock`,
    key: 'net_above_ground_carbon_stock',
    description: t`Net Change in Above Ground Carbon Stock`,
    units: getUnitName(MT),
    series: [NetAboveGroundSeries],
    emptyChartContent: defaultEmptyChartContent,
    showLegend: true,
    showFullLabel: true,
  },
  {
    name: t`Net Change in Below Ground Carbon Stock`,
    key: 'net_below_ground_carbon_stock',
    description: t`Net Change in Soil Carbon Stock`,
    units: getUnitName(MT),
    series: [NetBelowGroundSeries],
    emptyChartContent: defaultEmptyChartContent,
    showLegend: true,
    showFullLabel: true,
  },
  {
    name: t`Net Change in Carbon Stock as Equivalent Passenger Vehicles Driven Annually`,
    key: 'net_carbon_equivalent',
    description: t`Net Change in Carbon Stock measured
  as equivalent passenger vehicles driven per year`,
    series: [NetBelowGroundEquivalentSeries, NetAboveGroundEquivalentSeries],
    units: getUnitName(PASSENGER_VEHICLES),
    emptyChartContent: defaultEmptyChartContent,
    showLegend: true,
    showFullLabel: true,
  },
  {
    name: t`Net Change in Above Ground Carbon Stock measured
  as equivalent passenger vehicles driven per year`,
    key: 'net_above_ground_carbon_equivalent',
    description: t`Net Change in Above Ground Carbon Stock measured
  as equivalent passenger vehicles driven per year`,
    series: [NetAboveGroundEquivalentSeries],
    units: getUnitName(PASSENGER_VEHICLES),
    emptyChartContent: defaultEmptyChartContent,
    showLegend: true,
    showFullLabel: true,
  },
  {
    name: t`Net Change in Below Ground Carbon Stock measured
  as equivalent passenger vehicles driven per year`,
    key: 'net_below_ground_carbon_equivalent',
    description: t`Net Change in Below Ground Carbon Stock measured
  as equivalent passenger vehicles driven per year`,
    units: getUnitName(PASSENGER_VEHICLES),
    series: [NetBelowGroundEquivalentSeries],
    emptyChartContent: defaultEmptyChartContent,
    showLegend: true,
    showFullLabel: true,
  },
  {
    name: t`Total Carbon Stock`,
    key: 'total_carbon_stock',
    description: t`Total Carbon Stock`,
    series: [TotalBelowGroundSeries, TotalAboveGroundSeries],
    units: getUnitName(MT),
    emptyChartContent: defaultEmptyChartContent,
    showLegend: true,
    showFullLabel: true,
  },
  {
    name: t`Total Above Ground Carbon Stock`,
    key: 'total_above_ground_carbon_stock',
    description: t`Total Above Ground Carbon Stock`,
    series: [TotalAboveGroundSeries],
    units: getUnitName(MT),
    emptyChartContent: defaultEmptyChartContent,
    showLegend: true,
    showFullLabel: true,
  },
  {
    name: t`Total Below Ground Carbon Stock`,
    key: 'total_below_ground_carbon_stock',
    description: t`Total Soil Carbon Stock`,
    series: [TotalBelowGroundSeries],
    units: getUnitName(MT),
    emptyChartContent: defaultEmptyChartContent,
    showLegend: true,
    showFullLabel: true,
  },
];

export const tncChartsCarbon: LegacyReport = Object.freeze({
  key: 'tnc_conservation_carbon',
  name: t`Conservation - Carbon`,
  colorSpec: {
    primaryColor: colors.lime[700],
    interpolateFromColor: 'rgb(255, 214, 79)',
    interpolateToColor: 'rgb(27, 94, 31)',
  },
  charts: [...tncChartsSharedCarbon],
});
