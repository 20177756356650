import colors from 'material-colors';
import { t } from 'ttag';

import { GALLONS, getUnitName } from 'uf/base/units';
import { LegacyChartSeries, LegacyReport } from 'uf/reporting/index';

const IN_SENTENCE = true;

const totalResidentialSeries: LegacyChartSeries = {
  key: 'total_res',
  source: 'analysis',
  sourceKey: 'water_demand',
  layerKey: 'water_demand_results',
  columnKey: 'total_residential_use',
  stat: 'sum',
};

const totalCommercialSeries: LegacyChartSeries = {
  key: 'total_com',
  source: 'analysis',
  sourceKey: 'water_demand',
  layerKey: 'water_demand_results',
  columnKey: 'total_commercial_use',
  stat: 'sum',
};

const residentialIndoorSeries: LegacyChartSeries = {
  key: 'total_res_indoor',
  source: 'analysis',
  sourceKey: 'water_demand',
  layerKey: 'water_demand_results',
  columnKey: 'residential_indoor_use',
  stat: 'sum',
};

const residentialOutdoorSeries: LegacyChartSeries = {
  key: 'total_res_outdoor',
  source: 'analysis',
  sourceKey: 'water_demand',
  layerKey: 'water_demand_results',
  columnKey: 'residential_outdoor_use',
  stat: 'sum',
};

const commercialIndoorSeries: LegacyChartSeries = {
  key: 'total_comm_indoor',
  source: 'analysis',
  sourceKey: 'water_demand',
  layerKey: 'water_demand_results',
  columnKey: 'commercial_indoor_use',
  stat: 'sum',
};

const commercialOutdoorSeries: LegacyChartSeries = {
  key: 'total_comm_outdoor',
  source: 'analysis',
  sourceKey: 'water_demand',
  layerKey: 'water_demand_results',
  columnKey: 'commercial_outdoor_use',
  stat: 'sum',
};

const perCapitaResidentialSeries: LegacyChartSeries = {
  key: 'per_capita_res',
  source: 'analysis',
  sourceKey: 'water_demand',
  layerKey: 'water_summary_per_capita',
  columnKey: 'residential_per_capita_use',
  stat: 'sum',
};

const perCapitaCommercialSeries: LegacyChartSeries = {
  key: 'per_capita_com',
  source: 'analysis',
  sourceKey: 'water_demand',
  layerKey: 'water_summary_per_capita',
  columnKey: 'commercial_per_employee_use',
  stat: 'sum',
};

const perHouseholdIndoorResidentialSeries: LegacyChartSeries = {
  key: 'per_household_indoor_res',
  source: 'analysis',
  sourceKey: 'water_demand',
  layerKey: 'water_summary_per_household',
  columnKey: 'residential_indoor_per_household_use',
  stat: 'sum',
};

const perHouseholdOutdoorResidentialSeries: LegacyChartSeries = {
  key: 'per_household_outdoor_res',
  source: 'analysis',
  sourceKey: 'water_demand',
  layerKey: 'water_summary_per_household',
  columnKey: 'residential_outdoor_per_household_use',
  stat: 'sum',
};

const perHouseholdAnnualChart = {
  key: 'per_household_annual',
  description: t`Annual average water use per household by indoor and outdoor usage`,
  name: t`Per Household Annual Residential Water Use`,
  subtitle: t`Average indoor and outdoor water consumption`,
  units: getUnitName(GALLONS, IN_SENTENCE),
  series: [
    perHouseholdIndoorResidentialSeries,
    perHouseholdOutdoorResidentialSeries,
  ],
};

const totalWaterUseChart = {
  name: t`Total Annual Water Use`,
  description: t`Annual residential and commercial indoor and outdoor water use`,
  subtitle: t`Indoor and outdoor water consumed`,
  key: 'total',
  series: [totalResidentialSeries, totalCommercialSeries],
  units: getUnitName(GALLONS, IN_SENTENCE),
};

export const waterCharts: LegacyReport = Object.freeze({
  key: 'water_demand',
  name: 'Water Use',
  colorSpec: {
    primaryColor: colors.lightBlue[500],
    interpolateFromColor: colors.lightBlue[100],
    interpolateToColor: 'rgb(1, 88, 155)',
  },
  charts: [
    totalWaterUseChart,
    {
      key: 'total_indoor',
      description: t`Annual residential and commercial indoor water use`,
      name: t`Annual Indoor Water Use`,
      subtitle: t`Water consumed`,
      series: [residentialIndoorSeries, commercialIndoorSeries],
      units: getUnitName(GALLONS, IN_SENTENCE),
    },
    {
      key: 'total_outdoor',
      description: t`Annual residential and commercial outdoor water use`,
      name: t`Annual Outdoor Water Use`,
      subtitle: t`Water consumed`,
      series: [residentialOutdoorSeries, commercialOutdoorSeries],
      units: getUnitName(GALLONS, IN_SENTENCE),
    },
    {
      key: 'total_residential',
      description: t`Annual residential indoor and outdoor water use`,
      name: t`Annual Residential Water Use`,
      subtitle: t`Water consumed`,
      series: [residentialIndoorSeries, residentialOutdoorSeries],
      units: getUnitName(GALLONS, IN_SENTENCE),
    },
    {
      key: 'total_commercial',
      description: t`Annual commercial indoor and outdoor water use`,
      name: t`Annual Commercial Water Use`,
      subtitle: t`Water consumed`,
      series: [commercialIndoorSeries, commercialOutdoorSeries],
      units: getUnitName(GALLONS, IN_SENTENCE),
    },
    {
      key: 'per_capita_residential',
      description: t`Annual average water use per person`,
      name: t`Per Capita Annual Residential Water Use`,
      subtitle: t`Average indoor and outdoor water consumption`,
      series: [perCapitaResidentialSeries],
      units: getUnitName(GALLONS, IN_SENTENCE),
    },
    perHouseholdAnnualChart,
  ],
});

const perCapitalAnnualChart = {
  key: 'per_capita_annual',
  description: t`Annual average water use per resident and per employee`,
  subtitle: t`Average indoor and outdoor water consumption per resident and per employee`,
  name: t`Per Capita Annual Water Use`,
  series: [perCapitaResidentialSeries, perCapitaCommercialSeries],
  units: getUnitName(GALLONS, IN_SENTENCE),
};

const perHouseholdAnnualAnalysisChart = {
  key: 'per_household_annual',
  description: t`Annual average water use per household by indoor and outdoor usage`,
  subtitle: t`Average indoor and outdoor water consumption`,
  name: t`Per Household Annual Water Use`,
  units: getUnitName(GALLONS, IN_SENTENCE),
  series: [
    perHouseholdIndoorResidentialSeries,
    perHouseholdOutdoorResidentialSeries,
  ],
};

const ldcSeries: LegacyChartSeries[] = [
  'urban',
  'compact',
  'standard',
  'rural',
].map(key => ({
  key: `per_capita_ldc_${key}`,
  source: 'analysis',
  sourceKey: 'water_demand',
  layerKey: 'water_summary_res_use_by_ldc',
  columnKey: key,
  stat: 'sum',
}));

export const waterAnalysisCharts: LegacyReport = Object.freeze({
  key: 'water_demand',
  name: 'Water Use',
  units: getUnitName(GALLONS, IN_SENTENCE),
  charts: [
    totalWaterUseChart,
    perCapitalAnnualChart,
    perHouseholdAnnualAnalysisChart,
    {
      name: t`Per Capita Residential Water Use by Land Development Category`,
      description: t`Per capita annual average water use by land development category`,
      subtitle: t`Average indoor and outdoor water consumption`,
      key: 'per_capita_by_ldc',
      units: getUnitName(GALLONS, IN_SENTENCE),
      series: ldcSeries,
      hideZeroValues: true,
    },
  ],
});
