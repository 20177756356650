import { GeoJsonGeometryTypes } from 'geojson';
import { Layer } from 'mapbox-gl';

import { EMPTY_ARRAY } from 'uf/base';
import { assertNever } from 'uf/base/never';
import { ColumnKey, LayerId, LayerVersion } from 'uf/layers';
import { GEOMETRY_KEY } from 'uf/layers/geometryKey';
import { defaultLineLayout } from 'uf/map/lines';
import {
  getPaintFilter,
  parsePaintPropertyOld,
  StyleLayerMetadata,
} from 'uf/map/stylelayers/stylelayers';
import { makeSourceId } from 'uf/map/tileSources';
import { SymbologyTypes } from 'uf/symbology';
import { DivideByColumnKey } from 'uf/symbology/divideByColumn';
import { makeSelectionSymbology } from 'uf/symbology/styles/SelectionSymbologies';

export function makeSelectionStyleLayer(
  layerId: LayerId,
  columnKey: ColumnKey,
  sourceId: string,
  type: SymbologyTypes,
  selectedGeoKeys: string[],
): Layer {
  const selectionSymbology = makeSelectionSymbology(layerId, type);
  const { id } = selectionSymbology;
  const filter = selectedGeoKeys?.length
    ? ['all', getPaintFilter(type), makeSelectionFilter(selectedGeoKeys)]
    : getPaintFilter(type);
  const uf: StyleLayerMetadata = {
    layerId,
    selectionColumnKey: true,
  };
  const styleLayer: Layer = {
    id,
    type,
    source: sourceId,
    'source-layer': layerId,
    filter,
    metadata: {
      uf,
    },
    paint: parsePaintPropertyOld(selectionSymbology, columnKey),
  } as Layer;
  if (type === SymbologyTypes.LINE) {
    styleLayer.layout = defaultLineLayout;
  }
  return styleLayer;
}

/**
 * Create selection layers for the selected keys.
 *
 * Note that if there are no selected keys, then this will create no style
 * layers. To override this behavior, pass true for `createForEmptyKeys`
 *
 * @param layerId
 * @param layerVersion
 * @param columnKey
 * @param divideByColumn
 * @param ufGeometryType
 * @param selectedKeys The list of geometry keys
 * @param createForEmptyKeys Force creation of layers even if `selectedKeys` is
 *   empty
 */
export function makeSelectionStyleLayers(
  layerId: LayerId,
  layerVersion: LayerVersion,
  columnKey: ColumnKey,
  divideByColumn: DivideByColumnKey,
  ufGeometryType: GeoJsonGeometryTypes,
  selectedKeys: string[],
  createForEmptyKeys: boolean = false,
): Layer[] {
  if (!columnKey || (!selectedKeys.length && !createForEmptyKeys)) {
    return EMPTY_ARRAY;
  }
  const sourceId = makeSourceId(
    layerId,
    layerVersion,
    columnKey,
    divideByColumn,
  );

  const styleLayers: Layer[] = [];
  switch (ufGeometryType) {
    case 'Polygon':
    case 'MultiPolygon':
      styleLayers.push(
        makeSelectionStyleLayer(
          layerId,
          columnKey,
          sourceId,
          SymbologyTypes.FILL,
          selectedKeys,
        ),
        makeSelectionStyleLayer(
          layerId,
          columnKey,
          sourceId,
          SymbologyTypes.LINE,
          selectedKeys,
        ),
      );
      break;
    case 'LineString':
    case 'MultiLineString':
      styleLayers.push(
        makeSelectionStyleLayer(
          layerId,
          columnKey,
          sourceId,
          SymbologyTypes.LINE,
          selectedKeys,
        ),
      );
      break;
    case 'Point':
    case 'MultiPoint':
      styleLayers.push(
        makeSelectionStyleLayer(
          layerId,
          columnKey,
          sourceId,
          SymbologyTypes.CIRCLE,
          selectedKeys,
        ),
      );
      break;
    case 'GeometryCollection':
      return null;
    default:
      assertNever(ufGeometryType);
  }
  return styleLayers;
}

// Used for selection style layers, this is how we
// identify and style only the selected features (blue outlines)
// TODO: Move this to uf/explore
function makeSelectionFilter(selectedGeoKeys: string[]): mapboxgl.Expression {
  return ['match', ['get', GEOMETRY_KEY], selectedGeoKeys, true, false];
}
