import { LayerBreaks, LayerColumnBreaks } from 'uf-api';
import { getCanonicalParameters } from 'uf/base/api';
import { makeReduxDataKey } from 'uf/data/helpers';
import { ColumnKey, LayerId, LayerVersion } from 'uf/layers';
import { DivideByColumnKey } from 'uf/symbology/divideByColumn';

export function findColumnKeyBreaks(
  columnBreaks: LayerBreaks,
  columnKey: ColumnKey,
) {
  return columnBreaks.column_breaks?.find(
    breaks => breaks.column_key === columnKey,
  );
}

export function makeBreaksKey(
  layerId: LayerId,
  layerVersion: LayerVersion,
  columnKey: ColumnKey,
  breakCount: number,
  breakType: LayerColumnBreaks.BreakTypeEnum,
  divideByColumn: DivideByColumnKey,
) {
  const params = {
    columns: [columnKey],
    break_count: breakCount,
    break_type: breakType,
    version: layerVersion,
    normalize_by: divideByColumn || undefined,
  };
  const breaksParams = getCanonicalParameters(params);
  return makeReduxDataKey(layerId, breaksParams);
}
