import assert from 'assert';
import _ from 'lodash';
import { Epic, ofType } from 'redux-observable';
import { mergeMap, withLatestFrom } from 'rxjs/operators';

import { combineEpics } from 'uf/base/epics';
import { LegacyVirtualLayerId } from 'uf/projects/virtualLayers';
import { saveUserSymbology } from 'uf/symbology/actions/user';

import { viewListActions } from './actions';
import { setLayerMapColumnKey } from './actions/activeColumnKey';
import { setLayerOrder, visibleLayersListActions } from './actions/layers';
import { DUPLICATE_VIEW, DuplicateViewAction } from './ActionTypes';
import {
  makeGetViewLayerOrder,
  makeGetViewMapColumnKeys,
  makeGetViewVisibleVirtualLayers,
} from './selectors/layers';
import { makeGetViewSymbologyList } from './selectors/symbology';

export const duplicateView: Epic<DuplicateViewAction, any> = (
  action$,
  state$,
) => {
  const getLayerViewOrder = makeGetViewLayerOrder();
  const getViewVisibleVirtualLayers = makeGetViewVisibleVirtualLayers();
  const getViewMapColumnKeys = makeGetViewMapColumnKeys();
  const getViewSymbologyList = makeGetViewSymbologyList();

  return action$.pipe(
    ofType(DUPLICATE_VIEW),
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
      const { projectId, newView, sourceViewId } = action;
      const targetViewId = newView.key;
      const duplicateViewAction = viewListActions.appendItem(
        newView,
        projectId,
      );
      if (!sourceViewId) {
        return [duplicateViewAction];
      }

      const layerOrder = getLayerViewOrder(state, {
        projectId,
        viewId: sourceViewId,
      });

      const visibleLayerIds = getViewVisibleVirtualLayers(state, {
        projectId,
        viewId: sourceViewId,
      });
      const setColumnKeys = Object.entries<string>(
        getViewMapColumnKeys(state, {
          projectId,
          viewId: sourceViewId,
        }),
      ).map(([virtualLayerId, columnKey]: [LegacyVirtualLayerId, string]) => {
        return setLayerMapColumnKey(
          virtualLayerId,
          columnKey,
          projectId,
          newView.key,
        );
      });

      const symbologies = getViewSymbologyList(state, {
        projectId,
        viewId: sourceViewId,
      });
      const copySymbologyActions = _.map(symbologies, (symbology, fullPath) => {
        // order defined by makeSymbologyUserDataKey
        const [layerId, columnKey, , divideByColumnKeyString] =
          fullPath.split('|');
        assert(layerId);
        // TODO: this is duplicated elsewhere
        const divideByColumnKey =
          divideByColumnKeyString === 'undefined'
            ? false
            : divideByColumnKeyString;

        return saveUserSymbology(
          projectId,
          targetViewId,
          layerId,
          columnKey,
          divideByColumnKey,
          symbology.symbology,
        );
      });
      return [
        duplicateViewAction,
        setLayerOrder(projectId, newView.key, layerOrder),
        visibleLayersListActions.setList(
          visibleLayerIds,
          projectId,
          newView.key,
        ),
        ...copySymbologyActions,
        ...setColumnKeys,
      ];
    }),
  );
};
export default combineEpics({ duplicateView }, 'views');
