import { LayerColumnSymbology } from '../';

/**
 * Returns a new copy of the given symbology with the new showZero setting applied
 *
 * @param symbology - a single symbology from a LayerColumnSymbology
 * @param newProperties additional hints
 * @param newProperties.showZero - Whether to render zeros on the map or not
 * @return updatedSymbology - a copy of `symbology` with the new zero setting applied
 */
export default function updateShowZero(
  symbology: LayerColumnSymbology,
  { showZero },
): LayerColumnSymbology {
  return {
    ...symbology,
    display_hints: {
      ...symbology.display_hints,
      showZero,
    },
  };
}
