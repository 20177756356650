import _ from 'lodash';
import { createSelector } from 'reselect';

import { LayerMetadata } from 'uf-api/model/models';
import { makeGetApiObjectResults } from 'uf/api/selectors';
import { EMPTY_OBJECT } from 'uf/base';
import { makeGetFromPropsSelector } from 'uf/base/selector';
import {
  DataState,
  getData,
  hasError,
  isLoaded,
  isLoading,
} from 'uf/data/dataState';
import { LayerId } from 'uf/layers';

export const getLayersMetadataStates = makeGetApiObjectResults(
  'layer',
  'get_layer',
);
export const getAvailableLayerMetadata = createSelector(
  getLayersMetadataStates,
  layersMetadata =>
    _.values(layersMetadata).filter(
      layerMetadata =>
        isLoading(layerMetadata) ||
        isLoaded(layerMetadata) ||
        !hasError(layerMetadata),
    ),
);

export function makeGetLayerMetadataState() {
  return createSelector(
    getLayersMetadataStates,
    makeGetFromPropsSelector<LayerId, 'layerId'>('layerId'),
    (layersMetadata, layerId) => layersMetadata[layerId],
  );
}

/**
 * A selector which returns a callback that can be used to get many metadata
 * states. This is useful when you have a list of layerIds coming into your
 * selector and you want metadata for each one.
 *
 * If you just need a single layer's metadata, use: makeGetLayerMetadataState.
 */
export type GetLayerMetadataState = (
  layerId: LayerId,
) => DataState<LayerMetadata>;
export function makeGetLayerMetadataStateGetter() {
  return createSelector(
    getLayersMetadataStates,
    (layerMetadataStates): GetLayerMetadataState =>
      layerId =>
        layerMetadataStates[layerId] ||
        (EMPTY_OBJECT as DataState<LayerMetadata>),
  );
}

export function makeGetLayerMetadata() {
  const getLayerMetadataState = makeGetLayerMetadataState();
  return createSelector(getLayerMetadataState, metadataState =>
    getData(metadataState),
  );
}

export function makeGetLayerMetadataByKey() {
  return createSelector(
    getLayersMetadataStates,
    makeGetFromPropsSelector<string, 'key'>('key'),
    (layersMetadataStates, layerId) => layersMetadataStates[layerId],
  );
}

// Get metadata for multiple layerIds in one go
export const getMetadataForLayerIds = createSelector(
  getLayersMetadataStates,
  makeGetFromPropsSelector<LayerId[], 'layerIds'>('layerIds'),
  (metadataStates, layerIds = []) =>
    layerIds.map(layerId => getData(metadataStates[layerId])),
);
