import { ThunkAction } from 'redux-thunk';

import { LayerMetadata, LayerReference } from 'uf-api';
import { updateLayerNameActionTypes } from 'uf-api/api/project.service';
import { risonEncode } from 'uf/base/rison';
import { dispatchAsyncAction } from 'uf/data/loader';
import { LayerId } from 'uf/layers';
import {
  convertFiltersToExpressions,
  Expression,
  FilterSpec,
} from 'uf/layers/filters';
import { ProjectId } from 'uf/projects';
import { createDynamicLayerActionTypes } from 'uf/projects/ActionTypes';
import { createDynamicLayerAPI, updateLayerNameAPI } from 'uf/projects/apis';

// TODO: Refactor to makeReduxDataKey
function makeDynamicLayerCreateKey(
  projectId: ProjectId,
  layerId: LayerId,
  query: Expression[],
) {
  const filterString = query ? risonEncode(query) : '*';
  return `${layerId}:in=${projectId}:filters=${filterString}`;
}

export function createDynamicLayer(
  projectId: ProjectId,
  parentLayerMetadata: LayerMetadata,
  filters: Partial<FilterSpec>,
  name: string,
  description?: string,
): ThunkAction<Promise<LayerReference>, any, any, any> {
  const expressions = convertFiltersToExpressions(filters) || [];

  const { full_path: parentLayerId } = parentLayerMetadata;
  const key = makeDynamicLayerCreateKey(projectId, parentLayerId, expressions);
  return dispatchAsyncAction(
    createDynamicLayerActionTypes,
    createDynamicLayerAPI(
      projectId,
      parentLayerId,
      expressions,
      name,
      description,
    ),
    { key, projectId, parentLayerId, params: { name } },
  );
}

export function updateLayerName(
  layerId: LayerId,
  projectId: ProjectId,
  name: string,
): ThunkAction<Promise<{}>, any, any, any> {
  return dispatchAsyncAction(
    updateLayerNameActionTypes,
    updateLayerNameAPI(layerId, projectId, name),
    {
      key: `${projectId}:${layerId}:${risonEncode({
        layerId,
        projectId,
        name,
      })}`,
      layerId,
      projectId,
      params: { name },
    },
  );
}
