import colors from 'material-colors';
import { t } from 'ttag';

import { LegacyChartSeries, LegacyReport } from 'uf/reporting/index';

const SOURCE = 'analysis';
const SOURCEKEY = 'walk_accessibility';

const schoolAccess: LegacyChartSeries = {
  key: 'percent_of_population_in_15_min_walk_range_schools',
  source: SOURCE,
  sourceKey: SOURCEKEY,
  layerKey: 'percent_access_poi_school',
  columnKey: 'school_15_minutes',
};

const hospitalAccess: LegacyChartSeries = {
  key: 'percent_of_population_in_20_min_walk_range_hospitals',
  source: SOURCE,
  sourceKey: SOURCEKEY,
  layerKey: 'percent_access_poi_hospital',
  columnKey: 'hospital_20_minutes',
};

const poi1Access: LegacyChartSeries = {
  key: 'percent_of_population_in_20_min_walk_range_poi_1',
  source: SOURCE,
  sourceKey: SOURCEKEY,
  layerKey: 'percent_access_poi_poi_1',
  columnKey: 'poi_1_20_minutes',
};

const poi2Access: LegacyChartSeries = {
  key: 'percent_of_population_in_20_min_walk_range_poi_2',
  source: SOURCE,
  sourceKey: SOURCEKEY,
  layerKey: 'percent_access_poi_poi_2',
  columnKey: 'poi_2_20_minutes',
};

const poi3Access: LegacyChartSeries = {
  key: 'percent_of_population_in_20_min_walk_range_poi_3',
  source: SOURCE,
  sourceKey: SOURCEKEY,
  layerKey: 'percent_access_poi_poi_3',
  columnKey: 'poi_3_20_minutes',
};

const retailAccess: LegacyChartSeries = {
  key: 'percent_of_population_in_10_min_walk_range_retail',
  source: SOURCE,
  sourceKey: SOURCEKEY,
  layerKey: 'percent_access_poi_retail',
  columnKey: 'retail_10_minutes',
};

const parkAccess: LegacyChartSeries = {
  key: 'percent_of_population_in_10_min_walk_range_park',
  source: SOURCE,
  sourceKey: SOURCEKEY,
  layerKey: 'percent_access_poi_park',
  columnKey: 'park_10_minutes',
};

const transitStopAccess: LegacyChartSeries = {
  key: 'percent_of_population_in_5_min_walk_range_transit_stop',
  source: SOURCE,
  sourceKey: SOURCEKEY,
  layerKey: 'percent_access_poi_transit',
  columnKey: 'transit_5_minutes',
};

const highQualityTransitStopAccess: LegacyChartSeries = {
  key: 'percent_of_population_in_5_min_walk_range_high_quality_transit_stop',
  source: SOURCE,
  sourceKey: SOURCEKEY,
  layerKey: 'percent_access_poi_high_quality_transit',
  columnKey: 'high_quality_transit_5_minutes',
};

const schoolSeries: LegacyChartSeries[] = ['5', '10', '15', '20'].map(key => ({
  key: `school_${key}_minutes`,
  source: SOURCE,
  sourceKey: SOURCEKEY,
  layerKey: 'percent_access_poi_school',
  columnKey: `school_${key}_minutes`,
}));

const hospitalSeries: LegacyChartSeries[] = ['5', '10', '15', '20'].map(
  key => ({
    key: `hospital_${key}_minutes`,
    source: SOURCE,
    sourceKey: SOURCEKEY,
    layerKey: 'percent_access_poi_hospital',
    columnKey: `hospital_${key}_minutes`,
  }),
);

const poi1Series: LegacyChartSeries[] = ['5', '10', '15', '20'].map(key => ({
  key: `poi_1_${key}_minutes`,
  source: SOURCE,
  sourceKey: SOURCEKEY,
  layerKey: 'percent_access_poi_poi_1',
  columnKey: `poi_1_${key}_minutes`,
}));

const poi2Series: LegacyChartSeries[] = ['5', '10', '15', '20'].map(key => ({
  key: `poi_2_${key}_minutes`,
  source: SOURCE,
  sourceKey: SOURCEKEY,
  layerKey: 'percent_access_poi_poi_2',
  columnKey: `poi_2_${key}_minutes`,
}));

const poi3Series: LegacyChartSeries[] = ['5', '10', '15', '20'].map(key => ({
  key: `poi_3_${key}_minutes`,
  source: SOURCE,
  sourceKey: SOURCEKEY,
  layerKey: 'percent_access_poi_poi_3',
  columnKey: `poi_3_${key}_minutes`,
}));

const restaurantSeries: LegacyChartSeries[] = ['5', '10', '15', '20'].map(
  key => ({
    key: `restaurant_${key}_minutes`,
    source: SOURCE,
    sourceKey: SOURCEKEY,
    layerKey: 'percent_access_poi_restaurant',
    columnKey: `restaurant_${key}_minutes`,
  }),
);

const retailSeries: LegacyChartSeries[] = ['5', '10', '15', '20'].map(key => ({
  key: `retail_${key}_minutes`,
  source: SOURCE,
  sourceKey: SOURCEKEY,
  layerKey: 'percent_access_poi_retail',
  columnKey: `retail_${key}_minutes`,
}));

const parkSeries: LegacyChartSeries[] = ['5', '10', '15', '20'].map(key => ({
  key: `park_${key}_minutes`,
  source: SOURCE,
  sourceKey: SOURCEKEY,
  layerKey: 'percent_access_poi_park',
  columnKey: `park_${key}_minutes`,
}));

const transitStopSeries: LegacyChartSeries[] = ['5', '10', '15', '20'].map(
  key => ({
    key: `transit_${key}_minutes`,
    source: SOURCE,
    sourceKey: SOURCEKEY,
    layerKey: 'percent_access_poi_transit',
    columnKey: `transit_${key}_minutes`,
  }),
);

const highQualityTransitStopSeries: LegacyChartSeries[] = [
  '5',
  '10',
  '15',
  '20',
].map(key => ({
  key: `high_quality_transit_${key}_minutes`,
  source: SOURCE,
  sourceKey: SOURCEKEY,
  layerKey: 'percent_access_poi_high_quality_transit',
  columnKey: `high_quality_transit_${key}_minutes`,
}));

export const accessibilityWalkCharts: LegacyReport = Object.freeze({
  key: SOURCEKEY,
  name: 'Walk Accessibility',
  colorSpec: {
    primaryColor: colors.teal[200],
    interpolateFromColor: colors.lightGreen[50],
    interpolateToColor: 'rgb(1, 88, 155)',
  },
  charts: [
    {
      key: 'pct_access_park',
      description: t`Percent of residents that can access a park within 10 minutes.`,
      subtitle: t`Percent of residents within 10 minutes`,
      name: t`Parks Access `,
      series: [parkAccess],
    },
    {
      key: 'pct_access_school',
      description: t`Percent of residents that can access a school within 15 minutes.`,
      subtitle: t`Percent of residents within 15 minutes`,
      name: t`Schools Access`,
      series: [schoolAccess],
    },
    {
      key: 'pct_access_hospital',
      description: t`Percent of residents that can access a hospital within 20 minutes.`,
      subtitle: t`Percent of residents within 20 minutes`,
      name: t`Hospitals Access`,
      series: [hospitalAccess],
    },
    {
      key: 'pct_access_poi_1',
      description: t`Percent of residents that can access POI 1 within 20 minutes.`,
      subtitle: t`Percent of residents within 20 minutes`,
      name: t`POI #1 Access`,
      series: [poi1Access],
      hideZeroValues: true,
    },
    {
      key: 'pct_access_poi_2',
      description: t`Percent of residents that can access POI 2 within 20 minutes.`,
      subtitle: t`Percent of residents within 20 minutes`,
      name: t`POI #2 Access`,
      series: [poi2Access],
      hideZeroValues: true,
    },
    {
      key: 'pct_access_poi_3',
      description: t`Percent of residents that can access POI 3 within 20 minutes.`,
      subtitle: t`Percent of residents within 20 minutes`,
      name: t`POI #3 Access`,
      series: [poi3Access],
      hideZeroValues: true,
    },
    {
      key: 'pct_access_transit',
      description: t`Percent of residents that can access a transit stop within 5 minutes.`,
      subtitle: t`Percent of residents within 5 minutes`,
      name: t`Transit Access: All Stops`,
      series: [transitStopAccess],
    },
    {
      key: 'pct_access_high_quality_transit',
      description: t`Percent of residents that can access a high quality transit stop within 5 minutes.`,
      subtitle: t`Percent of residents within 5 minutes`,
      name: t`Transit Access: High-Quality Stops`,
      series: [highQualityTransitStopAccess],
    },
    {
      key: 'pct_access_retail',
      description: t`Percent of residents that can access nth nearest retail within 10 minutes.`,
      subtitle: t`Percent of residents within 10 minutes`,
      name: t`Retail Access`,
      series: [retailAccess],
    },
  ],
});

export const accessibilityWalkAnalysisCharts: LegacyReport = Object.freeze({
  key: 'walk_accessibility',
  name: 'Accessibility - Walk',
  charts: [
    {
      key: 'pct_access_park',
      description: t`Percent of residents that can access a
    park within a specific time frame.`,
      subtitle: t`Percent of residents within X minutes walk`,
      name: t`Parks Access`,
      series: parkSeries,
    },
    {
      key: 'pct_access_school',
      description: t`Percent of residents that can access a
    school within a specific time frame.`,
      subtitle: t`Percent of residents within X minutes walk`,
      name: t`Schools Access`,
      series: schoolSeries,
    },
    {
      key: 'pct_access_hospital',
      description: t`Percent of residents that can access a
    hospital within a specific time frame.`,
      subtitle: t`Percent of residents within X minutes walk`,
      name: t`Hospitals Access`,
      series: hospitalSeries,
    },
    {
      key: 'pct_access_poi_1',
      description: t`Percent of residents that can access a
    POI 1 within a specific time frame.`,
      subtitle: t`Percent of residents within X minutes walk`,
      name: t`POI #1 Access`,
      series: poi1Series,
      hideZeroValues: true,
    },
    {
      key: 'pct_access_poi_2',
      description: t`Percent of residents that can access a
    POI 2 within a specific time frame.`,
      subtitle: t`Percent of residents within X minutes walk`,
      name: t`POI #2 Access`,
      series: poi2Series,
      hideZeroValues: true,
    },
    {
      key: 'pct_access_poi_3',
      description: t`Percent of residents that can access a
    POI 3 within a specific time frame.`,
      subtitle: t`Percent of residents within X minutes walk`,
      name: t`POI #3 Access`,
      series: poi3Series,
      hideZeroValues: true,
    },
    {
      key: 'pct_access_transit',
      description: t`Percent of residents that can access a
    transit stop within a specific time frame.`,
      subtitle: t`Percent of residents within X minutes walk`,
      name: t`Transit Access: All Stops`,
      series: transitStopSeries,
    },
    {
      key: 'pct_access_high_quality_transit',
      description: t`Percent of residents that can access a
    high quality transit stop within a specific time frame.`,
      subtitle: t`Percent of residents within X minutes walk`,
      name: t`Transit Access: High-Quality Stops`,
      series: highQualityTransitStopSeries,
    },
    {
      key: 'pct_access_restaurant',
      description: t`Percent of residents that can access a
    restaurant within a specific time frame.`,
      subtitle: t`Percent of residents within X minutes walk`,
      name: t`Restaurants Access`,
      series: restaurantSeries,
    },
    {
      key: 'pct_access_retail',
      description: t`Percent of residents that can access nth nearest
    retail within a specific time frame.`,
      subtitle: t`Percent of residents within X minutes walk`,
      name: t`Retail Access`,
      series: retailSeries,
    },
  ],
});
