import colors from 'material-colors';

import { DOLLARS, getUnitName } from 'uf/base/units';
import { LegacyChartSeries, LegacyReport } from 'uf/reporting/index';

const endstateResidentialPropertyTaxRevenueSeries: LegacyChartSeries = {
  key: 'property_tax_revenue_residential',
  source: 'analysis',
  sourceKey: 'fiscal_revenue_madison',
  layerKey: 'endstate_revenue_summary_totals',
  columnKey: 'property_tax_revenue_residential',
  color: colors.yellow[600],
};

const endstateCommercialPropertyTaxRevenueSeries: LegacyChartSeries = {
  key: 'property_tax_revenue_commercial',
  source: 'analysis',
  sourceKey: 'fiscal_revenue_madison',
  layerKey: 'endstate_revenue_summary_totals',
  columnKey: 'property_tax_revenue_commercial',
  color: colors.red[300],
};

const endstateResidentialUtilityRevenueSeries: LegacyChartSeries = {
  key: 'utility_revenue_residential',
  source: 'analysis',
  sourceKey: 'fiscal_revenue_madison',
  layerKey: 'endstate_revenue_summary_totals',
  columnKey: 'utility_revenue_residential',
  color: colors.yellow[600],
};

const endstateCommercialUtilityRevenueSeries: LegacyChartSeries = {
  key: 'utility_revenue_commercial',
  source: 'analysis',
  sourceKey: 'fiscal_revenue_madison',
  layerKey: 'endstate_revenue_summary_totals',
  columnKey: 'utility_revenue_commercial',
  color: colors.red[300],
};

const netResidentialPropertyTaxRevenueSeries: LegacyChartSeries = {
  key: 'net_property_tax_revenue_residential',
  source: 'analysis',
  sourceKey: 'fiscal_revenue_madison',
  layerKey: 'net_revenue_summary_totals',
  columnKey: 'net_property_tax_revenue_residential',
  color: colors.yellow[600],
};

const netCommercialPropertyTaxRevenueSeries: LegacyChartSeries = {
  key: 'net_property_tax_revenue_commercial',
  source: 'analysis',
  sourceKey: 'fiscal_revenue_madison',
  layerKey: 'net_revenue_summary_totals',
  columnKey: 'net_property_tax_revenue_commercial',
  color: colors.red[300],
};

const netResidentialUtilityRevenueSeries: LegacyChartSeries = {
  key: 'net_utility_revenue_residential',
  source: 'analysis',
  sourceKey: 'fiscal_revenue_madison',
  layerKey: 'net_revenue_summary_totals',
  columnKey: 'net_utility_revenue_residential',
  color: colors.yellow[600],
};

const netCommercialUtilityRevenueSeries: LegacyChartSeries = {
  key: 'net_utility_revenue_commercial',
  source: 'analysis',
  sourceKey: 'fiscal_revenue_madison',
  layerKey: 'net_revenue_summary_totals',
  columnKey: 'net_utility_revenue_commercial',
  color: colors.red[300],
};

const newResidentialPropertyTaxRevenueSeries: LegacyChartSeries = {
  key: 'new_development_property_tax_revenue_residential',
  source: 'analysis',
  sourceKey: 'fiscal_revenue_madison',
  layerKey: 'new_development_revenue_summary_totals',
  columnKey: 'new_development_property_tax_revenue_residential',
  color: colors.yellow[600],
};

const newCommercialPropertyTaxRevenueSeries: LegacyChartSeries = {
  key: 'new_development_property_tax_revenue_commercial',
  source: 'analysis',
  sourceKey: 'fiscal_revenue_madison',
  layerKey: 'new_development_revenue_summary_totals',
  columnKey: 'new_development_property_tax_revenue_commercial',
  color: colors.red[300],
};

const newResidentialUtilityRevenueSeries: LegacyChartSeries = {
  key: 'new_development_utility_revenue_residential',
  source: 'analysis',
  sourceKey: 'fiscal_revenue_madison',
  layerKey: 'new_development_revenue_summary_totals',
  columnKey: 'new_development_utility_revenue_residential',
  color: colors.yellow[600],
};

const newCommercialUtilityRevenueSeries: LegacyChartSeries = {
  key: 'new_development_utility_revenue_commercial',
  source: 'analysis',
  sourceKey: 'fiscal_revenue_madison',
  layerKey: 'new_development_revenue_summary_totals',
  columnKey: 'new_development_utility_revenue_commercial',
  color: colors.red[300],
};

export const fiscalRevenueMadisonCharts: LegacyReport = Object.freeze({
  key: 'fiscal_revenue_madison',
  name: 'Fiscal Revenue - Madison',
  colorSpec: {
    primaryColor: 'rgb(83, 151, 134)',
    interpolateFromColor: colors.lightBlue[200],
    interpolateToColor: 'rgb(27, 94, 31)',
  },
  charts: [
    {
      name: 'End Year Total Property Tax Revenue',
      key: 'endstate_property_tax_revenue',
      units: getUnitName(DOLLARS),
      series: [
        endstateResidentialPropertyTaxRevenueSeries,
        endstateCommercialPropertyTaxRevenueSeries,
      ],
    },
    {
      name: 'End Year Total Utility Revenue',
      key: 'endstate_utility_revenue',
      units: getUnitName(DOLLARS),
      series: [
        endstateResidentialUtilityRevenueSeries,
        endstateCommercialUtilityRevenueSeries,
      ],
    },
    {
      name: 'End Year Net Property Tax Revenue',
      key: 'net_property_tax_revenue',
      units: getUnitName(DOLLARS),
      series: [
        netResidentialPropertyTaxRevenueSeries,
        netCommercialPropertyTaxRevenueSeries,
      ],
    },
    {
      name: 'End Year Net Utility Revenue',
      key: 'net_utility_revenue',
      units: getUnitName(DOLLARS),
      series: [
        netResidentialUtilityRevenueSeries,
        netCommercialUtilityRevenueSeries,
      ],
    },
    {
      name: 'End Year New Development Property Tax Revenue',
      key: 'new_property_tax_revenue',
      units: getUnitName(DOLLARS),
      series: [
        newResidentialPropertyTaxRevenueSeries,
        newCommercialPropertyTaxRevenueSeries,
      ],
    },
    {
      name: 'End Year New Development Utility Revenue',
      key: 'new_utility_revenue',
      units: getUnitName(DOLLARS),
      series: [
        newResidentialUtilityRevenueSeries,
        newCommercialUtilityRevenueSeries,
      ],
    },
  ],
});
