import colors from 'material-colors';
import { t } from 'ttag';

import { getUnitName, MILES_TRAVELED } from 'uf/base/units';
import { LegacyChartSeries, LegacyReport } from 'uf/reporting/index';

import { pollutantTypesChart, transportEmissionChart } from './emissions';
import {
  perHouseholdTransportOandMCostChart,
  totalTravelCostChart,
} from './household';

const IN_SENTENCE = true;

function makeTransitModeShareSeries(vmtSourceKey: string): LegacyChartSeries {
  return {
    key: 'transit_mode_share',
    source: 'analysis',
    sourceKey: vmtSourceKey,
    layerKey: 'mode_share',
    columnKey: 'pct_transit_trips',
  };
}

function makeWalkModeShareSeries(vmtSourceKey: string): LegacyChartSeries {
  return {
    key: 'walk_mode_share',
    source: 'analysis',
    sourceKey: vmtSourceKey,
    layerKey: 'mode_share',
    columnKey: 'pct_walk_trips',
  };
}

function makeVehicleModeShareSeries(vmtSourceKey: string): LegacyChartSeries {
  return {
    key: 'vehicle_mode_share',
    source: 'analysis',
    sourceKey: vmtSourceKey,
    layerKey: 'mode_share',
    columnKey: 'pct_vehicle_trips',
  };
}

const vmtAllThreeCharts: LegacyReport[] = [
  'vmt_madison',
  'transportation_anywhere',
].map(
  (sourceKey): LegacyReport => ({
    key: `${sourceKey}`,
    name: t`Transportation`,
    colorSpec: {
      primaryColor: colors.pink[200],
      interpolateFromColor: colors.pink[50],
      interpolateToColor: colors.deepPurple[800],
    },
    charts: [
      {
        name: t`Annual VMT`,
        description: t`Annual passenger vehicle miles traveled (VMT)`,
        subtitle: t`Passenger vehicles,`,
        units: getUnitName(MILES_TRAVELED, IN_SENTENCE),
        key: 'total_vmt',
        series: [
          {
            key: `total_vmt_${sourceKey}`,
            source: 'analysis',
            sourceKey: `${sourceKey}`,
            layerKey: 'total_vmt',
            columnKey: 'total_vmt',
          },
        ],
      },
      {
        name: t`Per Capita Annual Residential VMT`,
        description: t`Average annual per capita passenger vehicle miles traveled (VMT)`,
        subtitle: t`Passenger vehicles,`,
        units: getUnitName(MILES_TRAVELED, IN_SENTENCE),
        key: 'vmt_per_capita',
        series: [
          {
            key: `vmt_per_capita_${sourceKey}`,
            source: 'analysis',
            sourceKey: `${sourceKey}`,
            layerKey: 'vmt_ratios',
            columnKey: 'vmt_per_capita',
          },
        ],
      },
      {
        name: t`Per Household Annual Residential VMT`,
        description: t`Average annual per household passenger vehicle miles traveled (VMT)`,
        subtitle: t`Passenger vehicles,`,
        units: getUnitName(MILES_TRAVELED, IN_SENTENCE),
        key: 'vmt_per_household',
        series: [
          {
            key: `vmt_per_household_${sourceKey}`,
            source: 'analysis',
            sourceKey: `${sourceKey}`,
            layerKey: 'vmt_ratios',
            columnKey: 'vmt_per_hh',
          },
        ],
      },
      {
        name: t`Travel Mode Share`,
        description: t`Travel mode share (%) by walk/bike, drive, transit`,
        subtitle: t`Percent of trips by type`,
        key: 'mode_shares',
        series: [
          makeVehicleModeShareSeries(`${sourceKey}`),
          makeTransitModeShareSeries(`${sourceKey}`),
          makeWalkModeShareSeries(`${sourceKey}`),
        ],
      },
      {
        name: t`MXD Total Vehicle Trips Daily`,
        description: t`Total daily vehicle trips estimated by the MXD model`,
        key: 'vehicle_trips_daily',
        series: [
          {
            key: `mxd_total_veh_trips_${sourceKey}`,
            source: 'analysis',
            sourceKey: `${sourceKey}`,
            layerKey: 'daily_vehicle_trips_total',
            columnKey: 'mxd_total_veh_trips',
          },
        ],
      },
      {
        name: t`MXD Total Vehicle Trips Annual`,
        description: t`Total annual vehicle trips estimated by the MXD model`,
        key: 'vehicle_trips_annual',
        series: [
          {
            key: `annual_mxd_total_veh_trips_${sourceKey}`,
            source: 'analysis',
            sourceKey: `${sourceKey}`,
            layerKey: 'annual_vehicle_trips_total',
            columnKey: 'annual_mxd_total_veh_trips',
          },
        ],
      },
      {
        name: t`MXD Vehicle Trips Daily Per Capita`,
        description: t`Daily vehicle trips per capita estimated by the MXD model`,
        key: 'vehicle_trips_daily_per_capita',
        series: [
          {
            key: `mxd_veh_trips_daily_per_capita_${sourceKey}`,
            source: 'analysis',
            sourceKey: `${sourceKey}`,
            layerKey: 'daily_vehicle_trips_ratio',
            columnKey: 'mxd_veh_trips_daily_per_capita',
          },
        ],
      },
      {
        name: t`MXD Vehicle Trips Daily Per Household`,
        description: t`Daily vehicle trips per household estimated by the MXD model`,
        key: 'mxd_veh_trips_daily_per_hh',
        series: [
          {
            key: `mxd_veh_trips_daily_per_hh_${sourceKey}`,
            source: 'analysis',
            sourceKey: `${sourceKey}`,
            layerKey: 'daily_vehicle_trips_ratio',
            columnKey: 'mxd_veh_trips_daily_per_hh',
          },
        ],
      },
      {
        name: t`MXD Vehicle Trips Annual Per Capita`,
        description: t`Annual vehicle trips per capita estimated by the MXD model`,
        key: 'vehicle_trips_annual_per_capita',
        series: [
          {
            key: `mxd_veh_trips_annual_per_capita_${sourceKey}`,
            source: 'analysis',
            sourceKey: `${sourceKey}`,
            layerKey: 'annual_vehicle_trips_ratio',
            columnKey: 'mxd_veh_trips_annual_per_capita',
          },
        ],
      },
      {
        name: t`MXD Vehicle Trips Annual Per Household`,
        description: t`Annual vehicle trips per household estimated by the MXD model`,
        key: 'mxd_veh_trips_annual_per_hh',
        series: [
          {
            key: `mxd_veh_trips_annual_per_hh_${sourceKey}`,
            source: 'analysis',
            sourceKey: `${sourceKey}`,
            layerKey: 'annual_vehicle_trips_ratio',
            columnKey: 'mxd_veh_trips_annual_per_hh',
          },
        ],
      },
      pollutantTypesChart,
      transportEmissionChart,
      totalTravelCostChart,
      perHouseholdTransportOandMCostChart,
    ],
  }),
);

function makePerCapitaLDCSeries(vmtSourceKey: string): LegacyChartSeries[] {
  return ['urban', 'compact', 'standard', 'rural'].map(
    (key): LegacyChartSeries => ({
      key: `per_capita_ldc_${key}`,
      source: 'analysis',
      sourceKey: vmtSourceKey,
      layerKey: 'vmt_per_cap_per_ldc',
      columnKey: key,
    }),
  );
}

function makePerHouseholdLDCSeries(vmtSourceKey: string): LegacyChartSeries[] {
  return ['urban', 'compact', 'standard', 'rural'].map(key => ({
    key: `per_hh_ldc_${key}`,
    source: 'analysis',
    sourceKey: vmtSourceKey,
    layerKey: 'vmt_per_hh_per_ldc',
    columnKey: key,
  }));
}

const vmtAllThreeAnalysisCharts: LegacyReport[] = [
  'vmt_madison',
  'transportation_anywhere',
].map(
  (sourceKey): LegacyReport => ({
    key: `${sourceKey}`,
    name: t`Transportation`,
    charts: [
      {
        name: t`Travel Mode Share`,
        description: t`Travel mode share (%) by walk/bike, drive, transit`,
        subtitle: t`Percentage of trips by type`,
        key: 'mode_shares',
        series: [
          makeTransitModeShareSeries(`${sourceKey}`),
          makeWalkModeShareSeries(`${sourceKey}`),
          makeVehicleModeShareSeries(`${sourceKey}`),
        ],
      },
      {
        name: t`Daily Vehicle Trips`,
        description: t`Daily vehicle trips by Mixed-Use Trip Generation Model
      and Institute of Transportation Engineers trip rates`,
        subtitle: t`By Mixed Use Trip Generation Model (MXD) and Institute of Transportation Engineers (ITE) trip rates`,
        key: 'mxd_vs_ite_total_veh_trips_daily',
        series: [
          {
            key: `mxd_veh_trips_total_${sourceKey}`,
            source: 'analysis',
            sourceKey: `${sourceKey}`,
            layerKey: 'daily_vehicle_trips_total',
            columnKey: 'mxd_total_veh_trips',
          },
          {
            key: `ite_total_veh_trips_${sourceKey}`,
            source: 'analysis',
            sourceKey: `${sourceKey}`,
            layerKey: 'daily_vehicle_trips_total',
            columnKey: 'ite_total_veh_trips',
          },
        ],
      },
      {
        name: t`Annual Vehicle Trips`,
        description: t`Annual vehicle trips by Mixed-Use Trip Generation Model
      and Institute of Transportation Engineers trip rates`,
        key: 'mxd_vs_ite_total_veh_trips_annual',
        subtitle: t`By Mixed Use Trip Generation Model (MXD) and Institute of Transportation Engineers (ITE) trip rates`,
        series: [
          {
            key: `annual_mxd_total_veh_trips_${sourceKey}`,
            source: 'analysis',
            sourceKey: `${sourceKey}`,
            layerKey: 'annual_vehicle_trips_total',
            columnKey: 'annual_mxd_total_veh_trips',
          },
          {
            key: `annual_ite_total_veh_trips_${sourceKey}`,
            source: 'analysis',
            sourceKey: `${sourceKey}`,
            layerKey: 'annual_vehicle_trips_total',
            columnKey: 'annual_ite_total_veh_trips',
          },
        ],
      },
      {
        name: t`Per Capita and Per Household Daily MXD Vehicle Trips`,
        description: t`Average daily per capita and per
      household vehicle trips estimated by the MXD model`,
        key: 'mxd_per_cap_per_hh_veh_trips_daily',
        series: [
          {
            key: `mxd_veh_trips_daily_per_capita_${sourceKey}`,
            source: 'analysis',
            sourceKey: `${sourceKey}`,
            layerKey: 'daily_vehicle_trips_ratio',
            columnKey: 'mxd_veh_trips_daily_per_capita',
          },
          {
            key: `mxd_veh_trips_daily_per_hh_${sourceKey}`,
            source: 'analysis',
            sourceKey: `${sourceKey}`,
            layerKey: 'daily_vehicle_trips_ratio',
            columnKey: 'mxd_veh_trips_daily_per_hh',
          },
        ],
      },
      {
        name: t`Per Capita and Per Household Annual MXD Vehicle Trips`,
        description: t`Average annual per capita and per
      household vehicle trips estimated by the MXD model`,
        key: 'mxd_per_cap_per_hh_veh_trips_annually',
        series: [
          {
            key: `mxd_veh_trips_annual_per_capita_${sourceKey}`,
            source: 'analysis',
            sourceKey: `${sourceKey}`,
            layerKey: 'annual_vehicle_trips_ratio',
            columnKey: 'mxd_veh_trips_annual_per_capita',
          },
          {
            key: `mxd_veh_trips_annual_per_hh_${sourceKey}`,
            source: 'analysis',
            sourceKey: `${sourceKey}`,
            layerKey: 'annual_vehicle_trips_ratio',
            columnKey: 'mxd_veh_trips_annual_per_hh',
          },
        ],
      },
      {
        name: t`Annual VMT`,
        description: t`Annual passenger vehicle miles traveled (VMT)`,
        subtitle: t`Passenger vehicle`,
        units: getUnitName(MILES_TRAVELED, IN_SENTENCE),
        key: 'total_vmt',
        series: [
          {
            key: `total_vmt_${sourceKey}`,
            source: 'analysis',
            sourceKey: `${sourceKey}`,
            layerKey: 'total_vmt',
            columnKey: 'total_vmt',
          },
        ],
      },
      {
        name: t`Per Capita and Per Household Annual Residential VMT`,
        description: t`Average annual per capita and per
      household passenger vehicle miles traveled (VMT)`,
        subtitle: t`Average passenger vehicle`,
        units: getUnitName(MILES_TRAVELED, IN_SENTENCE),
        key: 'vmt_per_capita',
        series: [
          {
            key: `vmt_per_capita_${sourceKey}`,
            source: 'analysis',
            sourceKey: `${sourceKey}`,
            layerKey: 'vmt_ratios',
            columnKey: 'vmt_per_capita',
          },
          {
            key: `vmt_per_household_${sourceKey}`,
            source: 'analysis',
            sourceKey: `${sourceKey}`,
            layerKey: 'vmt_ratios',
            columnKey: 'vmt_per_hh',
          },
        ],
      },
      {
        name: t`Per Capita Residential VMT by Land Development Category`,
        description: t`Average annual per capita home-based passenger
      vehicle miles traveled by land development category (LDC)`,
        subtitle: t`Average passenger vehicle`,
        units: getUnitName(MILES_TRAVELED, IN_SENTENCE),
        key: 'per_capita_vmt_by_ldc',
        series: makePerCapitaLDCSeries(`${sourceKey}`),
      },
      {
        name: t`Per Household Residential VMT by Land Development Category`,
        description: t`Average annual per household home-based passenger
      vehicle miles traveled by land development category (LDC)`,
        subtitle: t`Average passenger vehicle`,
        units: getUnitName(MILES_TRAVELED, IN_SENTENCE),
        key: 'per_hh_vmt_by_ldc',
        series: makePerHouseholdLDCSeries(`${sourceKey}`),
      },
    ],
  }),
);

export const vmtCharts: readonly LegacyReport[] =
  Object.freeze(vmtAllThreeCharts);

export const vmtAnalysisCharts: readonly LegacyReport[] = Object.freeze(
  vmtAllThreeAnalysisCharts,
);
