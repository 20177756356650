export enum NotificationTypes {
  ALL_PAINTINGS_LOCKED = 'ALL_PAINTINGS_LOCKED',
  ALL_PAINTINGS_UNLOCKED = 'ALL_PAINTINGS_UNLOCKED',
  ANALYSIS_DONE = 'ANALYSIS_DONE',
  ANALYSIS_ERROR = 'ANALYSIS_ERROR',
  ANALYSIS_QUEUED = 'ANALYSIS_QUEUED',
  ANALYSIS_STARTED = 'ANALYSIS_STARTED',
  BUILTFORMS_UPDATED = 'BUILTFORMS_UPDATED',
  LAYER_BUFFER_STARTED = 'LAYER_BUFFER_STARTED',
  LAYER_BUFFER_DONE = 'LAYER_BUFFER_DONE',
  LAYER_BUFFER_ERROR = 'LAYER_BUFFER_ERROR',
  LAYER_EXPORT_DONE = 'LAYER_EXPORT_DONE',
  LAYER_EXPORT_ERROR = 'LAYER_EXPORT_ERROR',
  LAYER_EXPORT_STARTED = 'LAYER_EXPORT_STARTED',
  LAYER_IMPORT_DONE = 'LAYER_IMPORT_DONE',
  LAYER_IMPORT_ERROR = 'LAYER_IMPORT_ERROR',
  LAYER_IMPORT_STARTED = 'LAYER_IMPORT_STARTED',
  MAPEXPORT_DONE = 'MAPEXPORT_DONE',
  MAPEXPORT_ERROR = 'MAPEXPORT_ERROR',
  MAPEXPORT_STARTED = 'MAPEXPORT_STARTED',
  PAINTING_LOCKED = 'PAINTING_LOCKED',
  PAINTING_UNLOCKED = 'PAINTING_UNLOCKED',
  PONG = 'pong',
  PROJECT_LAYER_UPDATED = 'PROJECT_LAYER_UPDATED',
  PROJECT_UPDATED = 'PROJECT_UPDATED',
  PROJECT_UPDATE_BEGIN = 'PROJECT_UPDATE_BEGIN',
  PROJECT_UPDATE_CANCELLED = 'PROJECT_UPDATE_CANCELLED',
  PROJECT_UPDATE_SUCCEEDED = 'PROJECT_UPDATE_SUCCEEDED',
  SCENARIO_DELETED = 'SCENARIO_DELETED',
  SCENARIO_UPDATE_BEGIN = 'SCENARIO_UPDATE_BEGIN',
  SCENARIO_UPDATE_CANCELLED = 'SCENARIO_UPDATE_CANCELLED',
  SCENARIO_UPDATE_FAILED = 'SCENARIO_UPDATE_FAILED',
  SCENARIO_UPDATE_SUCCEEDED = 'SCENARIO_UPDATE_SUCCEEDED',
  TASK_NOTIFICATION = 'TASK_NOTIFICATION',
  COLUMN_EXPORT_STARTED = 'COLUMN_EXPORT_STARTED',
  COLUMN_EXPORT_DONE = 'COLUMN_EXPORT_DONE',
  COLUMN_EXPORT_ERROR = 'COLUMN_EXPORT_ERROR',
  LAYER_POINT_ON_SURFACE_STARTED = 'LAYER_POINT_ON_SURFACE_STARTED',
  LAYER_POINT_ON_SURFACE_DONE = 'LAYER_POINT_ON_SURFACE_DONE',
  LAYER_POINT_ON_SURFACE_ERROR = 'LAYER_POINT_ON_SURFACE_ERROR',
}
