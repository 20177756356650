import _ from 'lodash';
import { createSelector, ParametricSelector } from 'reselect';

import {
  MemberListItem,
  OrganizationInfo,
  Subscription,
  User,
  UserData,
} from 'uf-api/model/models';
import { EMPTY_ARRAY, EMPTY_OBJECT } from 'uf/base';
import { makeGetFromPropsSelector } from 'uf/base/selector';
import { DataState, getData, isLoaded } from 'uf/data/dataState';
import {
  getOrganizationMembersState,
  getOrganizationSubscriptionState,
  makeGetOrganizationMembers,
} from 'uf/organizations/selectors';
import { MemberListResponse } from 'uf/organizations/state';
import { UserOrganizationInfo } from 'uf/user/user';

import { getUserState } from './index';

const getAuthState = createSelector(
  getUserState,
  userState => userState.authentication || (EMPTY_OBJECT as DataState<User>),
);

export const getUserInfoState = createSelector(
  getUserState,
  userState => userState.info || (EMPTY_OBJECT as DataState<User>),
);

export const getUser = createSelector(
  getUserInfoState,
  getAuthState,
  (infoState, authState): User => {
    const userInfo = getData(infoState, EMPTY_OBJECT);
    if (!_.isEmpty(userInfo)) {
      return userInfo;
    }

    // TODO: deprecated.  remove when auth0 goes into production.
    // this needs to be in right now to support the old method of checking if a user is
    // authenticated on app enter
    return getData(authState, EMPTY_OBJECT);
  },
);

export const getUserDataState = createSelector(
  getUserState,
  (userState): Record<string, DataState<UserData>> =>
    userState.userData || EMPTY_OBJECT,
);

// Should be used to check API request state only. To get the data, use getUserDataState
export const getUserDataItemsInternalState = createSelector(
  getUserState,
  (userState): Record<string, DataState<UserData[]>> =>
    userState.userDataItemsInternal || EMPTY_OBJECT,
);

export function makeGetUserDataStateByPrefix<
  PP extends string,
  UKP extends string,
>(prefixParameter: PP, userKeyParameter: UKP) {
  return createSelector(
    getUserDataState,
    makeGetFromPropsSelector<string, UKP>(userKeyParameter),
    makeGetFromPropsSelector<string, PP>(prefixParameter),
    (userDataStates, userKey, prefix) =>
      _.pickBy(
        userDataStates,
        userDataState =>
          userDataState.extra.userKey === userKey &&
          userDataState.extra.dataKey.startsWith(prefix),
      ),
  );
}

export const getUserIsAuthenticated = createSelector(
  getUser,
  user => !_.isEmpty(user),
);

/**
 * Determine if the currently logged in user (if any)
 * is a superuser. The is_superuser user
 * comes from the server, and could be fooled by rogue javascript. We
 * ultimately only rely on the APIs being protected server-side. This
 * selector should not be considered as any form of security except
 * when it is used in server-side rendered code.
 */
export const getUserIsSuperuser = createSelector(
  getUser,
  // This relies on the user object being somewhat flushed out,
  // as kind of a forcing function to make sure we define our tests well,
  // and that a more simplistic object can't fool the selector.
  user => user.username && user.email && user.is_superuser,
);

export const getUserDisplayName = createSelector(getUser, user => {
  if (user.first_name && user.last_name) {
    return `${user.first_name} ${user.last_name}`;
  }
  if (user.first_name) {
    return user.first_name;
  }
  return user.email || 'Unknown User';
});

export const getUsername = createSelector(getUser, user => user.username || '');

export const getSessionId = createSelector(
  getUser,
  user => user.session_id || null,
);

export const getUserKey = createSelector(getUser, user => user.key || '');

export const getUserOrganizations = createSelector(
  getUser,
  (user): OrganizationInfo[] => {
    const orgs =
      user.organizations || (EMPTY_ARRAY as typeof user.organizations);
    return orgs;
  },
);

export const getUserOrganizationKeys = createSelector(
  getUserOrganizations,
  organizations => organizations.map(({ key }) => key),
);

export const getUserOrganizationPaths = createSelector(
  getUserOrganizations,
  organizations => organizations.map(({ full_path: fullPath }) => fullPath),
);

// Combines information for each of the user's organizations.  It includes the all the original
// information in getUserOrganizations, plus the user's role for each org and the subscription
// info of each org (if available and the user has permission)
export const getUserOrganizationsInfos = createSelector(
  getUser,
  getUserOrganizations,
  getOrganizationSubscriptionState,
  getOrganizationMembersState,
  (
    user,
    organizations,
    subscriptionsByOrg,
    membersByOrg,
  ): UserOrganizationInfo[] => {
    return organizations.map(org => {
      const membersResponse = getData<MemberListResponse>(
        membersByOrg[org.key],
        EMPTY_OBJECT as MemberListItem,
      );

      const members: MemberListItem[] = membersResponse.items || EMPTY_ARRAY;

      const memberInfo =
        members.find(member => member.key === user.key) ||
        (EMPTY_OBJECT as MemberListItem);

      const role = memberInfo.role;

      const subscription = getData<Subscription>(
        subscriptionsByOrg[org.key],
        EMPTY_OBJECT,
      );

      return {
        ...org,
        role,
        subscription,
      };
    });
  },
);

export const getUserOrganization = createSelector(
  getUserOrganizations,
  makeGetFromPropsSelector<string, 'orgKey'>('orgKey'),
  (organizations, orgKey): OrganizationInfo =>
    organizations.find(org => org.key === orgKey) ||
    (EMPTY_OBJECT as OrganizationInfo),
);

interface WithOrganizationId {
  organizationKey: string;
}
export function makeGetUserOrganizationRole(): ParametricSelector<
  any,
  WithOrganizationId,
  MemberListItem.RoleEnum
> {
  return createSelector(
    getUserKey,
    makeGetOrganizationMembers(),
    (userKey, members: MemberListItem[]) => {
      const userInfo = members.find(member => member.key === userKey);

      if (userInfo) {
        return userInfo.role;
      }

      return null;
    },
  );
}

function isTrialAndExpiring(subscription) {
  // TODO: This is a subscription that we do not have access to
  // that comes in as null, should be filtered separately.
  if (!subscription) {
    return false;
  }
  // only subscriptions that will expire
  if (subscription.state !== Subscription.StateEnum.Trial) {
    return false;
  }
  // only current subscriptions
  const expires = new Date(subscription.trial_end_date).getTime();
  return expires > Date.now();
}

function isExpired(subscription) {
  if (!subscription) {
    return false;
  }
  return subscription.state === Subscription.StateEnum.Expired;
}

/**
 * Get the next org that will expire, so we can tell the user how many days they have left.
 */
export const getNextExpiringOrganizationKey = createSelector(
  getOrganizationSubscriptionState,
  getUserOrganizations,
  (
    subscriptionStates: Record<string, DataState<Subscription>>,
    organizations,
  ): string => {
    // We want the most recent unexpired subscription, but ultimately
    // nwe need to return the organization key, not the subscription
    // itself.
    const trialSubscriptions: any = organizations
      .filter(({ key }) => key in subscriptionStates)
      .map(({ key }) => [subscriptionStates[key], key]);

    const loadedTrialSubscriptions = trialSubscriptions
      .filter(([state]) => isLoaded(state))
      .map(([state, organizationKey]) => [getData(state), organizationKey])
      .filter(
        ([subscription]) =>
          isTrialAndExpiring(subscription) || isExpired(subscription),
      );

    const [result] = _.sortBy(
      loadedTrialSubscriptions,
      ([subscription]) => subscription.trial_end_date,
    );

    if (!result) {
      return null;
    }

    return result[1];
  },
);
