import _ from 'lodash';
import warning from 'warning';

import { enqueueProjectConvertLayerTaskActionTypes } from 'uf-api/api/project.service';
import { ProjectServiceInterface } from 'uf-api/api/project.serviceInterface';
import { parseFullPath } from 'uf/base/dataset';
import { assertNever } from 'uf/base/never';
import { Writeable } from 'uf/base/types';
import { makeAsyncApiCaller } from 'uf/base/xhr';
import { dispatchAsyncAction } from 'uf/data/loader';
import { LayerId } from 'uf/layers';
import { Expression, FilterBuffer } from 'uf/layers/filters';
import { BoundarySources, ProjectId } from 'uf/projects';
import { ScenarioId } from 'uf/scenarios';

// TODO: Include anolysis module params
// Update the layer list items in the project. Adds by default
const removeFromLayerList = makeAsyncApiCaller(
  apis => apis.project.remove_from_layer_list,
);
const addToLayerList = makeAsyncApiCaller(
  apis => apis.project.add_to_layer_list,
);

export function updateLayerListAPI(
  projectId: ProjectId,
  layerId: LayerId,
  isRemove = false,
) {
  const { namespace, key } = parseFullPath(projectId);
  const params = {
    namespace,
    key,
    layer_path: layerId,
  };

  if (isRemove) {
    return removeFromLayerList(params);
  }
  return addToLayerList(params);
}

const getProjectAvailableLayers = makeAsyncApiCaller(
  apis => apis.project.get_project_available_layers,
);

export function fetchAvailableProjectLayersAPI(projectId: ProjectId) {
  const { namespace, key } = parseFullPath(projectId, 'project');
  return getProjectAvailableLayers({ namespace, key });
}

export const fetchUserProjectsAPI = makeAsyncApiCaller(
  apis => apis.project.get_projects_v2,
);

const getProject = makeAsyncApiCaller(apis => apis.project.get_project);
export function fetchProject(projectId: ProjectId) {
  const { namespace, key } = parseFullPath(projectId, 'project');
  return getProject({ namespace, key });
}

const getProjectTasks = makeAsyncApiCaller(
  apis => apis.project.get_project_tasks,
);
export function fetchTasksForProject(projectId: ProjectId) {
  const { namespace, key } = parseFullPath(projectId, 'project');
  return getProjectTasks({ namespace, key });
}

const editScenario = makeAsyncApiCaller(apis => apis.project.edit_scenario);
export function editScenarioOperation(
  scenarioId: ScenarioId,
  projectId: ProjectId,
  name: string,
  description: string,
) {
  const { key } = parseFullPath(projectId, 'project');
  const { namespace, key: scenarioKey } = parseFullPath(scenarioId, 'scenario');
  const params = {
    namespace,
    key,
    scenario_key: scenarioKey,
    name,
    description,
  };

  return editScenario(params);
}

const removeProjectWorkingLayer = makeAsyncApiCaller(
  apis => apis.project.remove_project_working_layer,
);

export function removeWorkingLayerAPI(projectId: ProjectId, layerId: LayerId) {
  const { namespace: projectNamespace, key: projectKey } =
    parseFullPath(projectId);
  const {
    namespace: layerNamespace,
    type: layerType,
    key: layerKey,
  } = parseFullPath(layerId);

  const params = {
    project_namespace: projectNamespace,
    project_key: projectKey,
    layer_namespace: layerNamespace,
    layer_type: layerType,
    layer_key: layerKey,
  };
  return removeProjectWorkingLayer(params);
}

const deleteProject = makeAsyncApiCaller(apis => apis.project.delete_project);
export function deleteProjectAPI(namespace: string, projectKey: string) {
  const params = {
    namespace,
    key: projectKey,
  };
  return deleteProject(params);
}

const editProject = makeAsyncApiCaller(apis => apis.project.edit_project);

export function editProjectAPI(
  namespace: string,
  projectKey: string,
  name: string,
  description: string,
) {
  const params = {
    namespace,
    key: projectKey,
    name,
    description,
  };

  return editProject(params);
}

const getCanvasTypes = makeAsyncApiCaller(
  apis => apis.project.get_canvas_types,
);

export function getCanvasTypesAPI(
  organizationKey: string,
  boundarySourceType: BoundarySources,
  { canvasArea, areaUploadKey },
) {
  const params: Writeable<ProjectServiceInterface.getCanvasTypesParams> = {
    key: organizationKey,
  };

  switch (boundarySourceType) {
    case BoundarySources.WELL_KNOWN:
      params.project_area_query = canvasArea
        ? JSON.stringify(canvasArea)
        : null;
      break;
    case BoundarySources.USER_UPLOAD:
      params.project_area_shapes_ref = areaUploadKey || null;
      break;

    case BoundarySources.SOURCE_PROJECT:
      // Nothing to check here, projct will just pick its own types
      warning(
        false,
        'Should not be getting canvas types for project-based canvas',
      );
      break;

    default:
      assertNever(
        boundarySourceType,
        `Unknown boundary source detected: ${boundarySourceType}`,
      );
      break;
  }

  return getCanvasTypes(params);
}

const getProjectUpdateInProgress = makeAsyncApiCaller(
  apis => apis.project.get_project_update_in_progress,
);

export function fetchProjectUpdateStatus(projectId: ProjectId) {
  const { namespace, key } = parseFullPath(projectId);
  return getProjectUpdateInProgress({ namespace, key });
}

const enqueueProjectBufferedLayerTask = makeAsyncApiCaller(
  apis => apis.project.enqueue_project_buffered_layer_task,
);
export function createBufferedLayerAPI(
  projectId: ProjectId,
  parentLayerId: string,
  parentLayerVersion: string,
  filter: Expression,
  buffer: FilterBuffer,
  name: string,
  description: string,
) {
  const { namespace, key } = parseFullPath(projectId, 'project');
  const filterString = _.isEmpty(filter) ? null : JSON.stringify(filter);
  return enqueueProjectBufferedLayerTask({
    namespace,
    key,
    parent: parentLayerId,
    parent_version: parentLayerVersion,
    filter: filterString,
    buffer_distance: buffer.distance,
    buffer_units: buffer.unit,
    name,
    description,
  });
}

const enqueueProjectConvertLayerTask = makeAsyncApiCaller(
  apis => apis.project.enqueue_project_convert_layer_task,
);
export function createConvertedLayer(
  projectId: ProjectId,
  parentLayerId: string,
  parentLayerVersion: string,
  name: string,
  description: string,
  conversionMethod: 'point_on_surface',
  filter?: Expression,
) {
  const { namespace, key } = parseFullPath(projectId, 'project');
  const filterString = _.isEmpty(filter) ? null : JSON.stringify(filter);
  return dispatchAsyncAction(
    enqueueProjectConvertLayerTaskActionTypes,
    enqueueProjectConvertLayerTask({
      namespace,
      key,
      parent: parentLayerId,
      parent_version: parentLayerVersion,
      filter: filterString,
      conversion_method: conversionMethod,
      name,
      description,
    }),
  );
}

const createProjectDynamicLayer = makeAsyncApiCaller(
  apis => apis.project.create_project_dynamic_layer,
);
export function createDynamicLayerAPI(
  projectId: ProjectId,
  parentLayerId: string,
  filters: Expression[],
  name: string,
  description: string,
) {
  const { namespace, key } = parseFullPath(projectId, 'project');
  const filter = _.isEmpty(filters)
    ? undefined
    : JSON.stringify({
        fn: 'and',
        expressions: filters,
      });
  return createProjectDynamicLayer({
    namespace,
    key,
    parent: parentLayerId,
    filter,
    name,
    description,
  });
}

const updateLayerName = makeAsyncApiCaller(
  apis => apis.project.update_layer_name,
);
export function updateLayerNameAPI(
  layerId: LayerId,
  projectId: ProjectId,
  name: string,
) {
  const layer = parseFullPath(layerId);
  const project = parseFullPath(projectId);
  return updateLayerName({
    project_namespace: project.namespace,
    project_key: project.key,
    layer_namespace: layer.namespace,
    layer_type: layer.type,
    layer_key: layer.key,
    name,
  });
}
