import {
  AnchorButton,
  Button,
  Classes,
  Dialog,
  Intent,
  Position,
  Spinner,
  SpinnerSize,
  Tooltip,
} from '@blueprintjs/core';
import classNames from 'classnames';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Box } from 'react-layout-components';
import { t } from 'ttag';
import popupStyles from 'uf/styles/popups.module.css';
import rootStyles from 'uf/styles/root.module.css';
import ErrorMessage from 'uf/ui/base/ErrorMessage/ErrorMessage';
import { ErrorObj } from 'uf/ui/base/errors';
import { dialogClasses, Sizes } from 'uf/ui/base/sizes';

interface Props {
  show?: boolean;
  busy?: boolean;
  busyText?: string;
  title?: JSX.Element | string;
  error?: string | ErrorObj;
  onOk?: () => void;
  onCancel?: () => void;
  onHide?: () => void;
  okText?: string;
  okTooltip?: JSX.Element | string;
  cancelText?: string;
  okDisabled?: boolean;
  // pass through to overlay
  container?: any;
  size?: Sizes;
  dialogClassName?: string;
  // If specified, this will add classnames to
  // the Title, Ok, and Cancel buttons for use
  // with css selectors
  controlsClassNamePrefix?: string;

  /**
   *  Focuses the first input element.  Defaults to true
   */
  autoFocus?: boolean;

  className?: string;

  onClosed?: (node: HTMLElement) => void;

  /** Forwarded to the Dialog element */
  transitionDuration?: number;
  /** Forwarded to the Dialog element */
  transitionName?: string;
}

const FormDialog: FunctionComponent<Props> = props => {
  const {
    title,
    show,
    onOk,
    onCancel,
    onHide,
    okDisabled,
    okText,
    okTooltip,
    onClosed,
    cancelText,
    error,
    busy,
    busyText,
    size,
    dialogClassName,
    controlsClassNamePrefix,
    children,
    autoFocus,
    className,
    transitionDuration,
    transitionName,
  } = props;

  const onHideDialog = useCallback(
    e => {
      // this logic is a hack to add onHide without affecting the previous onCancel behavior (which is
      // hooked up to Dialog's onClose)
      if (onHide) {
        onHide();
      } else if (onCancel) {
        onCancel();
      }
    },
    [onCancel, onHide],
  );

  const onCancelDialog = useCallback(
    e => {
      if (onCancel) {
        onCancel();
      }
    },
    [onCancel],
  );

  const { onOk: onDialogOk, disabled: locallyBusy } = useSafeOk(onOk);

  const titleClassName = classNames({
    [`${controlsClassNamePrefix}-title`]: !!controlsClassNamePrefix,
  });

  const cancelClassName = classNames('test-dialog-cancel', 'Cancel', {
    [`${controlsClassNamePrefix}-cancel`]: !!controlsClassNamePrefix,
  });

  const okClassName = classNames('test-dialog-ok', 'Ok', {
    [`${controlsClassNamePrefix}-ok`]: !!controlsClassNamePrefix,
  });

  // Whether to put spinner directly in the ok button
  const busyInSaveButton = busy && okDisabled;
  const buttonsDisabled =
    !show || // if the dialog is supposed to be hidden
    okDisabled || // the ok button is explicitly disabled by the consumer
    busy || // the consumer says it's busy processing
    locallyBusy; // we are in a local debounce

  return (
    <Dialog
      transitionName={transitionName}
      transitionDuration={__TESTING__ ? 0 : transitionDuration}
      className={classNames(dialogClasses[size], className, dialogClassName)}
      isCloseButtonShown={title ? false : undefined}
      isOpen={show}
      onClose={onHideDialog}
      onClosed={onClosed}
      onOpening={autoFocus ? focusFirstInput : null}
      title={title ? <div className={titleClassName}>{title}</div> : null}>
      <Box column className={Classes.DIALOG_BODY}>
        {children}
      </Box>

      <div className={Classes.DIALOG_FOOTER}>
        <Box alignItems="center" className={Classes.DIALOG_FOOTER_ACTIONS}>
          <ErrorMessage error={error} />
          {/* only show spinner if we are */}
          {busy && !busyInSaveButton && <Spinner size={SpinnerSize.SMALL} />}
          {busy && busyText && (
            <span className={rootStyles.thinPaddingRight}>{busyText}</span>
          )}
          {onCancel && (
            <Button
              className={classNames(
                rootStyles.veryThinMarginHorizontal,
                cancelClassName,
              )}
              onClick={onCancelDialog}>
              {cancelText}
            </Button>
          )}
          {onOk && (
            <Tooltip
              popoverClassName={popupStyles.largeWidth}
              boundary={'viewport'}
              position={Position.TOP}
              disabled={!okTooltip}
              content={okTooltip}>
              <AnchorButton
                intent={Intent.PRIMARY}
                className={okClassName}
                loading={busyInSaveButton}
                disabled={buttonsDisabled}
                onClick={onDialogOk}>
                {okText}
              </AnchorButton>
            </Tooltip>
          )}
        </Box>
      </div>
    </Dialog>
  );
};

FormDialog.defaultProps = {
  okText: t`Ok`,
  cancelText: t`Cancel`,
  autoFocus: true,
  size: Sizes.MEDIUM,
};

/**
 * Try to inteligently focus the first form input
 */
function focusFirstInput(rootElement: HTMLElement) {
  if (!rootElement) {
    return;
  }
  const selectors = ['input', 'select', 'button.btn-primary'];
  selectors.some(selector => {
    const input = rootElement.querySelector(selector);
    if (input) {
      (input as HTMLElement).focus();
      return true;
    }
    return false;
  });
}
export default FormDialog;

/**
 * A hook to safely call a callback only once per react render cycle. This gives
 * time to render the "disabled" state of a button before the callback is
 * actually called.
 *
 * This is useful if `onOk` is synchronously slow, leaving the button on the
 * screen able to queue up outstanding click events. Now, as soon as the first
 * click event comes in, we disabled the button and let react render, and only
 * then is it safe to call.
 *
 * @param onOk a callback to be called one call at a time.
 */
function useSafeOk(onOk: () => void) {
  const [callOk, setCallOk] = useState(false);
  const onCallOk = () => {
    setCallOk(true);
  };
  useEffect(() => {
    if (callOk) {
      onOk();
      setCallOk(false);
    }
  }, [callOk, onOk]);
  return { disabled: callOk, onOk: onCallOk };
}
