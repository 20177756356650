import { Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { makeEnsureActionCreator } from 'uf/data/loader';
import { ColumnKey, LayerId } from 'uf/layers';
import { registerPersistedAction } from 'uf/persistence';
import { ProjectId } from 'uf/projects';
import { makeGetProjectVirtualLayerIdMap } from 'uf/projects/selectors/virtualLayers';
import { LegacyVirtualLayerId } from 'uf/projects/virtualLayers';
import {
  SetDivideByColumnAction,
  SET_DIVIDE_BY_COLUMN,
} from 'uf/symbology/ActionTypes';
import {
  DivideByColumnKey,
  makeDivideByColumnUserDataKey,
  makeDivideByColumnUserDataPrefixForLayer,
} from 'uf/symbology/divideByColumn';
import { makeGetDivideByColumnState } from 'uf/symbology/selectors/divideByColumn';
import {
  deleteUserData,
  loadUserData,
  loadUserDataItems,
  setUserData,
} from 'uf/user/actions/data';
import { getUserKey } from 'uf/user/selectors/user';
import { ViewId } from 'uf/views';

export const ensureDivideByColumn = makeEnsureActionCreator(
  loadDivideByColumn,
  makeGetDivideByColumnState(),
  (projectId, viewId, layerId, columnKey) => ({
    projectId,
    viewId,
    layerId,
    columnKey,
  }),
);

/**
 * Action for loading the divideByColumn pref for a single layer + column
 */
export function loadDivideByColumn(
  projectId: ProjectId,
  viewId: ViewId,
  layerId: LayerId,
  columnKey: ColumnKey,
) {
  const key = makeDivideByColumnUserDataKey(projectId, layerId, columnKey);

  return (dispatch: Dispatch, getState) => {
    const userKey = getUserKey(getState());
    return dispatch(
      loadUserData(key, userKey, {
        layerId,
        columnKey,
        projectId,
      }),
    );
  };
}

export function setDivideByColumn(
  projectId: ProjectId,
  viewId: ViewId,
  layerId: LayerId,
  columnKey: ColumnKey,
  divideByColumn: DivideByColumnKey,
) {
  const key = makeDivideByColumnUserDataKey(projectId, layerId, columnKey);

  return (dispatch: Dispatch, getState) => {
    const userKey = getUserKey(getState());
    return dispatch(
      setUserData(key, { divideByColumn }, userKey, {
        projectId,
        layerId,
        columnKey,
        divideByColumn,
      }),
    );
  };
}

export function setDivideByColumnNew(
  projectId: ProjectId,
  viewId: ViewId,
  virtualLayerId: LegacyVirtualLayerId,
  columnKey: ColumnKey,
  divideByColumn: DivideByColumnKey,
): ThunkAction<SetDivideByColumnAction, any, any, any> {
  const getProjectVirtualLayerIdMap = makeGetProjectVirtualLayerIdMap();
  return (dispatch: Dispatch, getState) => {
    const layerId = getProjectVirtualLayerIdMap(getState(), { projectId })[
      virtualLayerId
    ];
    return dispatch({
      type: SET_DIVIDE_BY_COLUMN,
      projectId,
      viewId,
      layerId,
      virtualLayerId,
      columnKey,
      divideByColumn,
    });
  };
}

export function clearDivideByColumnNew(
  projectId: ProjectId,
  viewId: ViewId,
  virtualLayerId: LegacyVirtualLayerId,
  columnKey: ColumnKey,
): ThunkAction<SetDivideByColumnAction, any, any, any> {
  const getProjectVirtualLayerIdMap = makeGetProjectVirtualLayerIdMap();
  return (dispatch: Dispatch, getState) => {
    const layerId = getProjectVirtualLayerIdMap(getState(), { projectId })[
      virtualLayerId
    ];
    return dispatch({
      type: SET_DIVIDE_BY_COLUMN,
      projectId,
      viewId,
      layerId,
      virtualLayerId,
      columnKey,
      // Don't set to null since that'l break looking up symbology in redux.
      divideByColumn: undefined,
    });
  };
}

registerPersistedAction<SetDivideByColumnAction, string[], DivideByColumnKey>(
  'divideByColumn',
  SET_DIVIDE_BY_COLUMN,
  ([projectId, viewId, virtualLayerId, columnKey], divideByColumn) => {
    return setDivideByColumnNew(
      projectId,
      viewId,
      virtualLayerId,
      columnKey,
      divideByColumn,
    );
  },
  {
    getKey({ projectId, viewId, virtualLayerId, columnKey }) {
      return [projectId, viewId, virtualLayerId, columnKey];
    },
    getValue({ divideByColumn }) {
      return divideByColumn;
    },
  },
);

export function clearDivideByColumn(
  projectId: ProjectId,
  viewId: ViewId,
  layerId: LayerId,
  columnKey: ColumnKey,
) {
  const key = makeDivideByColumnUserDataKey(projectId, layerId, columnKey);

  return (dispatch: Dispatch, getState) => {
    const userKey = getUserKey(getState());
    return dispatch(
      deleteUserData(key, userKey, { projectId, layerId, columnKey }),
    );
  };
}

/**
 * Action for loading the divideByColumn pref for the whole layer
 */
export function loadDivideByColumns(projectId: ProjectId, layerId: LayerId) {
  return (dispatch: Dispatch, getState) => {
    const userKey = getUserKey(getState());
    return dispatch(
      loadUserDataItems(
        userKey,
        makeDivideByColumnUserDataPrefixForLayer(projectId, layerId),
      ),
    );
  };
}
