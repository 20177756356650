import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { Popover, Position, PopoverInteractionKind } from '@blueprintjs/core';

import { getSearchInfo } from 'uf/mapi/selectors';
import GeoSearchboxInput from './GeoSearchboxInput';
import GeoSearchboxMenu from './GeoSearchboxMenu';

interface GeoSearchboxProps {
  defaultIsOpen?: boolean;
  className?: string;
  inputClassName?: string;
  resultsClassName?: string;
}

const GeoSearchbox: FunctionComponent<GeoSearchboxProps> = props => {
  const { defaultIsOpen, className, resultsClassName, inputClassName } = props;
  const { query, results } = useSelector(getSearchInfo);

  const searchResults =
    results && query.trim().length ? (
      <GeoSearchboxMenu className={resultsClassName} />
    ) : null;

  return (
    <Popover
      className={className}
      content={searchResults}
      position={Position.BOTTOM}
      interactionKind={PopoverInteractionKind.HOVER}
      defaultIsOpen={defaultIsOpen}
      fill={true}
      minimal={true}>
      <GeoSearchboxInput className={inputClassName} />
    </Popover>
  );
};

export default GeoSearchbox;
