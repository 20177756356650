import {
  Button,
  Checkbox,
  Popover,
  PopperModifiers,
  Position,
} from '@blueprintjs/core';
import { boundMethod } from 'autobind-decorator';
import classNames from 'classnames';
import _ from 'lodash';
import colors from 'material-colors';
import React, {
  Component,
  CSSProperties,
  FunctionComponent,
  useCallback,
} from 'react';
import { Box } from 'react-layout-components';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { bindActionCreators, Dispatch } from 'redux';
import { t } from 'ttag';

import { getActiveProjectId } from 'uf/app/selectors';
import * as MapActionCreators from 'uf/explore/actions/map';
import { baseMapLayers } from 'uf/explore/baseMapLayers';
import {
  getHasBuildings,
  getHasCitiesLabels,
  getHasContours,
  getHasPlaceLabels,
  getHasPoiLabels,
  getHasRoadRailLabels,
  getHasWaterLabels,
  getHasWaterMask,
  makeGetExploreBaseMap,
  makeGetExploreMapStyleUrl,
} from 'uf/explore/selectors/map';
import { events, logEvent } from 'uf/logging';
import { BaseMap, BaseMapsList } from 'uf/map/baseMaps';
import { ProjectId } from 'uf/projects';
import rootStyles from 'uf/styles/root.module.css';
import { tw } from 'uf/tailwindcss-classnames';
import makeDataAttributes from 'uf/ui/base/makeDataAttributes';
import { MAPBOX_PADDING } from 'uf/ui/map/layout';
import withFeatureFlag from 'uf/ui/user/hocs/withFeatureFlag';

import basic from './assets/basic.png';
import blank from './assets/blank.png';
import bright from './assets/bright.png';
import dark from './assets/dark.png';
import light from './assets/light.png';
import minimalLand from './assets/minimalLand.png';
import minimalLandDark from './assets/minimalLandDark.png';
import satellite from './assets/satellite.png';
import satelliteFaded from './assets/satelliteFaded.png';
import schematic from './assets/schematic.png';
import streets from './assets/streets.png';
import terrain from './assets/terrain.png';
import styles from './BaseMapSwitcher.module.css';

const positionStyle: CSSProperties = {
  position: 'absolute',
  bottom: 'var(--thick-spacing)',
  right: MAPBOX_PADDING,
};

// Override bootstrap's obnoxiously large button padding (6px top and bottom, 16px left and right!)
// since we want our buttons images to be closer to the button's edge.
const buttonStyle: CSSProperties = {
  padding: '2px',
  paddingLeft: '3px',
};

const modifiers: PopperModifiers = { arrow: { enabled: false } };
const popoverButtonThumbnailStyle: CSSProperties = {
  height: '28px',
  width: '28px',
  border: `1px solid ${colors.grey[500]}`,
  borderRadius: '2px',
};
const mapFeatureListStyle: CSSProperties = { width: '200px', height: '100%' };

const featureList = baseMapLayers.filter(
  baseMapLayer => !isMaskLayer(baseMapLayer),
);
const maskList = baseMapLayers.filter(baseMapLayer =>
  isMaskLayer(baseMapLayer),
);

const BaseMapIconsById = {
  basic,
  dark,
  light,
  satellite,
  streets,
  bright,
  blank,
  minimalLand,
  minimalLandDark,
  satelliteFaded,
  schematic,
  terrain,
};
interface BaseMapSwitcherProps {
  baseMap?: BaseMap;
  styleUrl?: string;
  updateMapStyleUrl?: typeof MapActionCreators.updateMapStyleUrl;
  contoursVisible?: boolean;
  buildingsVisible?: boolean;
  citiesLabelVisible?: boolean;
  placesLabelVisible?: boolean;
  poiLabelVisible?: boolean;
  roadRailLabelVisible?: boolean;
  waterLabelVisible?: boolean;
  waterMaskVisible?: boolean;
  setBasemapFeatureVisibility?: (...args: any[]) => any;
  projectId?: ProjectId;
  hasShowWaterMaskFlag?: boolean;
}

export class BaseMapSwitcher extends Component<BaseMapSwitcherProps> {
  baseMapsList = BaseMapsList;

  @boundMethod
  handleBaseMapSelected(styleUrl: string, id: string) {
    const { updateMapStyleUrl, projectId } = this.props;
    updateMapStyleUrl(projectId, styleUrl);
    logEvent(events.MAP_BASE_LAYER_SELECTED, {
      projectId,
      mapId: id,
    });
  }
  @boundMethod
  handleFeatureVisibilityClick(event: React.ChangeEvent<HTMLInputElement>) {
    const { setBasemapFeatureVisibility, projectId } = this.props;
    setBasemapFeatureVisibility(
      projectId,
      event.target.name,
      event.target.checked,
    );
    logEvent(events.MAP_FEATURE_CLICKED, {
      projectID: projectId,
      feature: event.target.name,
      visibility: event.target.checked,
    });
  }

  renderButton() {
    const { baseMap } = this.props;
    return (
      <Button
        className={rootStyles.veryThinPadding}
        icon={
          baseMap && (
            <img
              alt={baseMap.label}
              src={BaseMapIconsById[baseMap.id]}
              style={popoverButtonThumbnailStyle}
            />
          )
        }>
        {t`Base Map`}
      </Button>
    );
  }

  renderRows() {
    const { styleUrl } = this.props;
    return _.chunk(this.baseMapsList, 3).map((row: any) => (
      <Box key={row[0].url} fit alignItems="stretch">
        {row.map(({ label, url, labelColor, id }) => {
          return (
            <BaseMapButton
              key={id}
              styleUrl={styleUrl}
              url={url}
              labelColor={labelColor}
              label={label}
              id={id}
              handleBaseMapSelected={this.handleBaseMapSelected}
            />
          );
        })}
      </Box>
    ));
  }

  render() {
    const { hasShowWaterMaskFlag } = this.props;

    return (
      <div
        className={classNames(tw('tw-z-20'), 'Basemap')}
        style={positionStyle}>
        <Popover
          position={Position.TOP}
          modifiers={modifiers}
          content={
            <Box row className={rootStyles.veryThinPadding}>
              <Box
                column
                className={rootStyles.thinPaddingLeft}
                style={mapFeatureListStyle}>
                <h4>{t`Basemap features`}</h4>
                {featureList.map(({ featureProp, featureLabel }) => (
                  <Checkbox
                    key={featureProp}
                    name={featureProp}
                    checked={this.props[featureProp]}
                    onChange={this.handleFeatureVisibilityClick}>
                    {featureLabel}
                  </Checkbox>
                ))}
                {hasShowWaterMaskFlag && (
                  <div>
                    <h4>{t`Masking features`}</h4>
                    {maskList.map(({ featureProp, featureLabel }) => (
                      <Checkbox
                        key={featureProp}
                        name={featureProp}
                        checked={this.props[featureProp]}
                        onChange={this.handleFeatureVisibilityClick}>
                        {featureLabel}
                      </Checkbox>
                    ))}
                  </div>
                )}
              </Box>
              <Box column>{this.renderRows()}</Box>
            </Box>
          }>
          {this.renderButton()}
        </Popover>
      </div>
    );
  }
}
interface BaseMapButtonProps {
  styleUrl: string;
  url: string;
  labelColor: string;
  label: string;
  id: string;
  handleBaseMapSelected: (styleUrl: string, id: string) => void;
}

const BaseMapButton: FunctionComponent<BaseMapButtonProps> = props => {
  const { styleUrl, url, labelColor, label, id, handleBaseMapSelected } = props;
  const baseMapButtonStyle: CSSProperties = {
    ...buttonStyle,
    margin: '2px',
    border: `2px solid ${
      styleUrl === url ? 'var(--color-light-blue)' : 'transparent'
    }`,
  };
  const labelStyle: CSSProperties = {
    color: labelColor,
  };
  const onClick = useCallback(() => {
    handleBaseMapSelected(url, id);
  }, [handleBaseMapSelected, id, url]);

  return (
    <Button
      key={label}
      minimal
      title={label}
      disabled={styleUrl === url}
      style={baseMapButtonStyle}
      onClick={onClick}
      {...makeDataAttributes({ basemapName: label })}>
      <div className={styles.thumbnailStyle}>
        <img alt={label} src={BaseMapIconsById[id]} />
        <span className={classNames(styles.swatchLabel, id)} style={labelStyle}>
          {label}
        </span>
      </div>
    </Button>
  );
};

function makeMapStateToProps() {
  const getExploreBaseMap = makeGetExploreBaseMap();
  const getExploreMapStyleUrl = makeGetExploreMapStyleUrl();
  return state => {
    const projectId = getActiveProjectId(state);

    return {
      projectId,
      baseMap: getExploreBaseMap(state, { projectId }),
      styleUrl: getExploreMapStyleUrl(state, {
        projectId: getActiveProjectId(state),
      }),
      contoursVisible: getHasContours(state, { projectId }),
      buildingsVisible: getHasBuildings(state, { projectId }),
      citiesLabelVisible: getHasCitiesLabels(state, { projectId }),
      placesLabelVisible: getHasPlaceLabels(state, { projectId }),
      poiLabelVisible: getHasPoiLabels(state, { projectId }),
      roadRailLabelVisible: getHasRoadRailLabels(state, { projectId }),
      waterLabelVisible: getHasWaterLabels(state, { projectId }),
      waterMaskVisible: getHasWaterMask(state, { projectId }),
    };
  };
}
function mapDispatchToProps(dispatch: Dispatch) {
  return {
    updateMapStyleUrl: bindActionCreators(
      MapActionCreators.updateMapStyleUrl,
      dispatch,
    ),
    setBasemapFeatureVisibility: bindActionCreators(
      MapActionCreators.setBasemapFeatureVisibility,
      dispatch,
    ),
  };
}

function isMaskLayer(baseMapLayer) {
  return baseMapLayer.featureProp === 'waterMaskVisible';
}
export default compose<BaseMapSwitcherProps, BaseMapSwitcherProps>(
  connect(makeMapStateToProps, mapDispatchToProps),
  withFeatureFlag('basemap-water-mask', 'hasShowWaterMaskFlag'),
)(BaseMapSwitcher);
