import _ from 'lodash';

import { isFalsey } from 'uf/base';

const DEFAULT_API_SERVER_ORIGIN = 'http://localhost:8080';
const UF_APP_ORIGIN = process.env.UF_APP_ORIGIN;
const API_SERVER_ORIGIN =
  process.env.UF_API_SERVER_ADDR || DEFAULT_API_SERVER_ORIGIN;
const UF_DASHBOARD_ORIGIN = process.env.UF_DASHBOARD_ORIGIN;

/**
 * Turns a swagger parameter dictionary into a "canonical" one,
 * omitting any values that are inconsequential to the API call.
 *
 * This allows us, for instance, to compare two API calls and see if
 * they're the "same" call, even if their parameters are slightly
 * different.
 *
 * Consider:
 *
 *   { columns: ['pop'], histograms: false }
 *   { columns: ['pop'] }
 *
 * These are the same because 'histograms' defaults to false. So
 * passing either through getCanonicalParameters results in:
 *
 *   { columns: ['pop'] }
 */
export function getCanonicalParameters<T extends object>(params: T) {
  // TODO: introspect the API to figure out which parameters should be left out, so that
  // empty strings/etc have specific meaning.
  return _.omitBy(
    params,
    param => typeof param !== 'boolean' && isFalsey(param),
  ) as T;
}

/**
 * Returns the externally routable domain of the API server.
 */
export function getAPIOrigin(): string {
  return API_SERVER_ORIGIN;
}

/**
 * Returns the externally routable base (domain + leading path) of the API server.
 */
export function getAPIBase(): string {
  return `${getAPIOrigin()}/api`;
}

/**
 * Returns the externally routable path to a particular API endpoint, specified
 * by path.
 */
export function getAPIUrl(path: string): string {
  return `${getAPIBase()}/${path}`;
}

/**
 * Returns a signin URL for the API server, with the given error message.
 * Also, with the url to redirect to.
 */
export function getSignInUrlBase({
  reason = '',
  pathname = '',
}: { reason?: string; pathname?: string } = {}) {
  const baseUrl = new URL(getAPIUrl('signin'));

  if (reason) {
    baseUrl.searchParams.set('reason', reason);
  }

  if (__CLIENT__) {
    baseUrl.searchParams.set('redirect_to', window.location.href);
  } else if (UF_APP_ORIGIN) {
    baseUrl.searchParams.set('redirect_to', `${UF_APP_ORIGIN}${pathname}`);
  }
  return baseUrl.href;
}

/**
 * Returns the externally routable base (domain + leading path) of the management API server.
 */
export function getManagementAPIBase(): string {
  return `${getAPIOrigin()}/api-management`;
}

/**
 * Returns the externally routable path to a particular API management endpoint, specified
 * by path.
 */
export function getManagementAPIUrl(path: string): string {
  return `${getManagementAPIBase()}/${path}`;
}

/**
 * Returns the externally routable of the dashboard app.
 */
export function getDashboardBase(): string {
  return UF_DASHBOARD_ORIGIN;
}
