import booleanContains from '@turf/boolean-contains';
import booleanCrosses from '@turf/boolean-crosses';
import booleanOverlap from '@turf/boolean-overlap';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import { Geometries, LineString } from '@turf/helpers';
import { Feature, Geometry, Point, Polygon } from 'geojson';
import warning from 'warning';

import {
  ExpressionEquals,
  ExpressionGreaterThan,
  ExpressionGreaterThanOrEquals,
  ExpressionLessThan,
  ExpressionLessThanOrEquals,
  ExpressionPoint,
  ExpressionWithinBoundingBox,
  ExpressionWithinPolygon,
} from 'uf-api';
import { typeAssertNever } from 'uf/base/never';

import { Expression } from './filters';

type Executor<R, G extends Geometry> = (feature: Feature<G>) => R;

/**
 * Create an function that you can call on a GeoJSON feature to determine if it should match the given expression.
 *
 * ```
 * // expression evaluator for pop > 2
 * const hasSomePop = makeExpressionsExecutor([{
 *    fn: '>',
 *    left: { fn: 'column', key: 'pop' },
 *    right: 2,
 * }]);
 *
 * const features: Feature[] = getAllFeatures();
 * const featuresWithPop = hasSomePop(features);
 * ```
 */
export function makeExpressionsExecutor<G extends Geometry>(
  expressions: Expression[],
): Executor<boolean, G> {
  const executors = expressions.map(expression =>
    makeExpressionExecutor(expression),
  );

  // default to an "add" executor
  const executor = properties => executors.every(ex => ex(properties));
  executor.expressions = expressions;
  Object.defineProperty(executor, 'name', { value: 'expression:and' });
  return executor;
}

interface LeftRightExpression {
  left: any;
  right: any;
  fn: Expression['fn'];
}

/**
 * Create a function that takes an expression, with
 * properties `left` and `right`, and returns an executor
 *
 */
function makeLeftRightComparison<
  E extends LeftRightExpression,
  G extends Geometry,
>(
  fn: (left: E['left'], right: E['right']) => boolean,
): (expression: E) => Executor<boolean, G> {
  return (expression: E) => {
    const { left, right } = expression;
    const leftExecutor = makeExpressionExecutor(left);
    const rightExecutor = makeExpressionExecutor(right);
    // Now return
    return feature => {
      const leftValue = leftExecutor(feature);
      const rightValue = rightExecutor(feature);
      return fn(leftValue, rightValue);
    };
  };
}
type ExecutorFnMap<G extends Geometry> = Partial<
  {
    [fn in Expression['fn']]: (e: Expression) => Executor<boolean, G>;
  }
>;

const numericMap: ExecutorFnMap<Geometry> = {
  '<': makeLeftRightComparison<ExpressionLessThan, Geometry>((a, b) => a < b),
  '>': makeLeftRightComparison<ExpressionGreaterThan, Geometry>(
    (a, b) => a > b,
  ),
  '>=': makeLeftRightComparison<ExpressionGreaterThanOrEquals, Geometry>(
    (a, b) => a >= b,
  ),
  '<=': makeLeftRightComparison<ExpressionLessThanOrEquals, Geometry>(
    (a, b) => a <= b,
  ),
  '==': makeLeftRightComparison<ExpressionEquals, Geometry>((a, b) => a === b),
};

export function makeExpressionExecutor<G extends Geometry>(
  expression: Expression | number | string | boolean,
): Executor<number | string | boolean | Geometries, G> {
  const executor = makeExpressionExecutorRaw(expression);
  if (typeof expression === 'object' && expression.fn) {
    Object.defineProperty(executor, 'name', {
      value: `expression:${expression.fn}`,
    });
  }
  return executor;
}

function makeExpressionExecutorRaw<G extends Geometry>(
  expression: Expression | number | string | boolean,
): Executor<number | string | boolean | Geometries, G> {
  if (typeof expression !== 'object') {
    return feature => expression;
  }
  switch (expression.fn) {
    case '<':
    case '>':
    case '>=':
    case '<=':
    case '==': {
      const executorFactory = numericMap[expression.fn];
      return executorFactory(expression);
    }
    case 'column': {
      const { key } = expression;
      return feature => feature.properties[key];
    }
    case 'in': {
      const { one_of: oneOf, value } = expression;
      const valueExecutor = makeExpressionExecutor(value);
      return feature => {
        const featureValue = valueExecutor(feature);
        return (oneOf as any[]).includes(featureValue);
      };
    }
    case 'and': {
      const { expressions } = expression;
      const executors = expressions.map((exp: Expression) =>
        makeExpressionExecutor(exp),
      );
      return feature => executors.every(executor => executor(feature));
    }

    case 'or': {
      const { expressions } = expression;
      const executors = expressions.map((exp: Expression) =>
        makeExpressionExecutor(exp),
      );
      return feature => executors.some(executor => executor(feature));
    }

    case 'not': {
      const { expression: subExpression } = expression;
      const subExpressionExecutor = makeExpressionExecutor(subExpression);
      return feature => !subExpressionExecutor(feature);
    }

    case 'startswith': {
      const { prefix, value, case_sensitive: caseSensitive } = expression;
      const valueExecutor = makeExpressionExecutor(value);
      if (caseSensitive) {
        return feature =>
          (valueExecutor(feature) as string)?.startsWith(prefix);
      }
      return feature =>
        (valueExecutor(feature) as string)
          ?.toLocaleLowerCase()
          .startsWith(prefix.toLocaleLowerCase());
    }
    case 'within_polygon': {
      return makeWithinPolygon(expression);
    }
    case 'within_bbox': {
      return makeWithinBBox(expression);
    }
    case 'point': {
      const { lng, lat } = expression;
      const point: Point = { type: 'Point', coordinates: [lng, lat] };
      return feature => point;
    }
    case 'bbox': {
      const { bounds } = expression;
      const [w, s, e, n] = bounds;
      const bbox: Polygon = {
        type: 'Polygon',
        coordinates: [
          [
            [w, s],
            [w, n],
            [e, n],
            [e, s],
          ],
        ],
      };
      return feature => bbox;
    }

    case 'geometry': {
      const { geometry } = expression;
      return feature => geometry;
    }

    case 'intersects': {
      const { left_geo: leftGeo, right_geo: rightGeo } = expression;
      const leftExecutor = makeExpressionExecutor(leftGeo);
      const rightExecutor = rightGeo
        ? makeExpressionExecutor(rightGeo)
        : (feature: Feature) => feature.geometry;
      return feature => {
        const left: Geometry = leftExecutor(feature) as Geometry;
        const right: Geometry = rightExecutor(feature) as Geometry;
        if (left.type === 'Polygon') {
          return intersectsPolygon(right, left);
        } else if (right.type === 'Polygon') {
          return intersectsPolygon(left, right);
        }
        warning(
          !!false,
          `Cannot 'intersect' ${left.type} with ${right.type}: at least one must be a polygon`,
        );
        return true;
      };
    }

    case 'layer_intersects':
    case 'within_tile': {
      warning(false, `'${expression.fn}' is not yet supported`);
      return feature => false;
    }
    case 'is_null': {
      const valueExecutor = makeExpressionExecutor(expression.expression);
      return feature => {
        const value = valueExecutor(feature);

        return value === null || value === undefined;
      };
    }

    default:
      typeAssertNever(expression);
      warning(
        false,
        `Unable to execute expression of type "${
          (expression as Expression).fn
        }"`,
      );
      return feature => false;
  }
}

function makeWithinPolygon(expression: ExpressionWithinPolygon) {
  const { coordinates } = expression;
  const polygon: Polygon = {
    type: 'Polygon',
    coordinates: [
      coordinates.map(obj => {
        const { lng, lat } = obj as { lng: number; lat: number };
        return [lng, lat];
      }),
    ],
  };
  return makeIntersectsExecutor(polygon);
}

function makeIntersectsExecutor(polygon: Polygon) {
  const match = (feature: Feature) => {
    const { geometry } = feature;
    return intersectsPolygon(geometry, polygon);
  };
  return match;
}

function intersectsPolygon(geometry: Geometry, polygon: Polygon): boolean {
  switch (geometry.type) {
    case 'Polygon':
    case 'MultiPolygon': {
      // Overlap means the lines of one polygon cross the lines of another,
      // contains means one is within the other
      return (
        booleanOverlap(geometry, polygon) || booleanContains(polygon, geometry)
      );
    }
    case 'Point': {
      return booleanPointInPolygon(geometry, polygon);
    }
    case 'MultiPoint': {
      return geometry.coordinates.some(point =>
        booleanPointInPolygon(point, polygon),
      );
    }
    case 'LineString': {
      return (
        booleanCrosses(geometry, polygon) || booleanContains(polygon, geometry)
      );
    }
    case 'MultiLineString': {
      return geometry.coordinates.some(coordinates => {
        const line: LineString = {
          type: 'LineString',
          coordinates,
        };
        return booleanCrosses(line, polygon);
      });
    }
    case 'GeometryCollection': {
      const { geometries } = geometry;
      return geometries.some(subgeo => {
        return intersectsPolygon(subgeo, polygon);
      });
    }
    default:
      typeAssertNever(geometry);
      return false;
  }
}

function makeWithinBBox(expression: ExpressionWithinBoundingBox) {
  const { max_x: maxX, max_y: maxY, min_x: minX, min_y: minY } = expression;
  const coordinates: ExpressionPoint[] = [
    { fn: 'point', lng: minX, lat: minY },
    { fn: 'point', lng: maxX, lat: minY },
    { fn: 'point', lng: maxX, lat: maxY },
    { fn: 'point', lng: minX, lat: maxY },
    { fn: 'point', lng: minX, lat: minY },
  ];
  const polygonExpression: ExpressionWithinPolygon = {
    fn: 'within_polygon',
    coordinates,
  };

  return makeWithinPolygon(polygonExpression);
}
