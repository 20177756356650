import {
  CuratedColumnSymbology,
  DistributionTypes,
  InterpolationTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/public/dataset/latch_survey_by_census_tract': {
    est_pmiles: [
      {
        display_hints: {
          distribution: DistributionTypes.CUSTOM,
          interpolation: InterpolationTypes.INTERVAL,
          is_percent: false,
          is_reverse_theme: false,
          scale: SymbologyScales.NUMERIC,
          showZero: false,
          theme: 'custom',
        },
        paint: {
          'fill-color': {
            default: 'transparent',
            property: 'est_pmiles',
            stops: [
              {
                color: 'rgb(247, 252, 240)',
                label: '21.17 - 25',
                value: 0,
              },
              {
                color: 'rgb(229, 245, 223)',
                label: '25 - 30',
                value: 25,
              },
              {
                color: 'rgb(211, 238, 206)',
                label: '30 - 45',
                value: 30,
              },
              {
                color: 'rgb(189, 229, 191)',
                label: '45 - 50',
                value: 45,
              },
              {
                color: 'rgb(158, 217, 187)',
                label: '50 - 55',
                value: 50,
              },
              {
                color: 'rgb(123, 203, 196)',
                label: '55 - 60',
                value: 55,
              },
              {
                color: 'rgb(88, 183, 205)',
                label: '60 - 65',
                value: 60,
              },
              {
                color: 'rgb(57, 156, 198)',
                label: '65 - 70',
                value: 65,
              },
              {
                color: 'rgb(29, 126, 183)',
                label: '70 - 75',
                value: 70,
              },
              {
                color: 'rgb(11, 96, 161)',
                label: '75 - 80',
                value: 75,
              },
              {
                color: 'rgb(8, 64, 129)',
                label: '80 - 102.45',
                value: 80,
              },
            ],
            type: 'interval',
          },
          'fill-opacity': 0.9,
        },
        type: SymbologyTypes.FILL,
      },
      {
        display_hints: {
          distribution: DistributionTypes.QUANTILES,
          interpolation: InterpolationTypes.INTERVAL,
          is_percent: false,
          scale: SymbologyScales.NUMERIC,
          showZero: false,
          theme: 'material:blue:range',
        },
        paint: {
          'line-color': 'transparent',
          'line-opacity': 0.9,
          'line-width': 0.5,
        },
        type: SymbologyTypes.LINE,
      },
    ],
  },
};

export default symbologies;
