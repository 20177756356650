import {
  CuratedColumnSymbology,
  CUSTOM_THEME,
  DEFAULT_OPACITY,
  InterpolationTypes,
  PaintColorTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

import MPORegionList from './mpo_regions';

const symbologies: Record<
  string,
  Record<string, CuratedColumnSymbology[]>
> = {};

MPORegionList.forEach(regionList => {
  symbologies[`/public/dataset/california_carbon_density_${regionList}`] = {
    raster_val: [
      {
        type: SymbologyTypes.FILL,
        paint: {
          'fill-color': {
            property: 'raster_val',
            type: PaintColorTypes.CATEGORICAL,
            stops: [
              {
                value: 1,
                label: 'Low Above Ground Carbon Density',
                color: '#eec870',
              },
              {
                value: 2,
                label: 'Medium Above Ground Carbon Density',
                color: '#d69d3a',
              },
              {
                value: 3,
                label: 'High Above Ground Carbon Density',
                color: '#a6732b',
              },
            ],
          },
          'fill-opacity': DEFAULT_OPACITY,
        },
        display_hints: {
          scale: SymbologyScales.CATEGORICAL,
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          theme: CUSTOM_THEME,
        },
      },
    ],
  };
});

export default symbologies;
