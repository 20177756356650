import { Dispatch } from 'redux';

import { setActiveAnalysisEditorModuleKey } from 'uf/analysiseditor/actions';
import { setActiveProject } from 'uf/app/actions';
import { setUIProperty } from 'uf/explore/actions';
import { makeProjectId } from 'uf/projects/ids';
import { SetInitialRoute } from 'uf/routing/state';

/**
 * Update redux from the url
 *
 * @param location
 * @param query
 * @param dispatch
 */
export const setInitialAnalysisEditorRoute: SetInitialRoute = (
  location,
  query,
  dispatch: Dispatch,
) => {
  dispatch(setUIProperty('manageViewVisible', true));
  dispatch(setUIProperty('manageViewEditor', 'analysiseditor'));
  const { namespace, projectKey, moduleKey } = location.payload as any;
  const projectId = makeProjectId(namespace, projectKey);

  // Handles when SSR takes us directly to the analysis editor
  dispatch(setActiveProject(projectId, { redirectToExplore: false }));

  // TODO: set the first module if moduleKey not specified
  const finalModuleKey = moduleKey || null;
  dispatch(setActiveAnalysisEditorModuleKey(finalModuleKey, projectId));
};
