import { combineReducers } from 'redux';
import { UFApiActionTypesMap, UFApiDetailsMap } from 'uf-api';
import { enhancedApi as Api } from 'uf-api-rtk/store';
import analysis from 'uf/analysis/reducer';
import analysiseditor from 'uf/analysiseditor/reducer';
import { reducer as app } from 'uf/app/slice';
import appservices from 'uf/appservices/reducer';
import { makeKeyedReducer } from 'uf/base/reducerFactories';
import builtforms from 'uf/builtforms/reducer';
import collaboration from 'uf/collaboration/reducer';
import { makeAPIReducer } from 'uf/data/apiReducer';
import debug from 'uf/debug/reducer';
import explore, { legacyExplore } from 'uf/explore/reducer';
import tableReducer from 'uf/explore/reducer/tableSlice';
import internal from 'uf/internal/reducer';
import layers from 'uf/layers/reducer';
import mapedit from 'uf/mapedit/reducer';
import mapexport from 'uf/mapexport/reducer';
import mapi from 'uf/mapi/reducer';
import organizations from 'uf/organizations/reducer';
import painting from 'uf/painting/reducer';
import persistence from 'uf/persistence/reducer';
import projects from 'uf/projects/reducer';
import reporting from 'uf/reporting/reducer';
import scenarios from 'uf/scenarios/reducer';
import search from 'uf/search/reducer';
import symbology from 'uf/symbology/reducer';
import user from 'uf/user/reducer';
import views from 'uf/views/reducer';
import locationAnalysisDialog from './location-analysis/locationAnalysisDialogSlice';

const baseReducers = {
  analysis,
  analysiseditor,
  api: makeAPIReducer(UFApiActionTypesMap, UFApiDetailsMap),
  app,
  appservices,
  builtforms,
  collaboration,
  debug,
  // TODO:  migrate our selectors over to work with exploreByProjectId that
  // are project specific and create a new explore reducer for items that
  // are not project specific.
  explore: legacyExplore,
  exploreByProjectId: makeKeyedReducer(explore, 'projectId'),
  internal,
  layers,
  location: {} as any,
  mapedit,
  mapexport,
  mapi,
  organizations,
  painting,
  persistence,
  projects,
  reporting,
  scenarios,
  search,
  symbology,
  user,
  views,
  [Api.reducerPath]: Api.reducer,
  table: tableReducer,
  locationAnalysisDialog,
};

export function createRootReducer(additionalReducers = {}) {
  return combineReducers({
    ...baseReducers,
    ...additionalReducers,
  });
}

// TODO: Remove this in favor of createRootReducer.
export default combineReducers(baseReducers);
