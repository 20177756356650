import colors from 'material-colors';

import {
  CuratedColumnSymbology,
  CUSTOM_THEME,
  DEFAULT_OPACITY,
  InterpolationTypes,
  PaintColorTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/public/dataset/calenviroscreen_3_draft_results': {
    draft_ces_30_percentile_range: [
      {
        type: SymbologyTypes.FILL,
        paint: {
          'fill-color': {
            property: 'draft_ces_30_percentile_range',
            type: PaintColorTypes.CATEGORICAL,
            stops: [
              { value: '1-5% (lowest scores)', color: colors.green[500] },
              { value: '6-10%', color: '#5FB54D' },
              { value: '11-15%', color: '#73BC4B' },
              { value: '16-20%', color: '#87C349' },
              { value: '21-25%', color: '#9BC946' },
              { value: '26-30%', color: '#AFD044' },
              { value: '31-35%', color: '#C3D742' },
              { value: '36-40%', color: '#D7DD3F' },
              { value: '41-45%', color: '#EBE43D' },
              { value: '46-50%', color: '#FCD108' },
              { value: '51-55%', color: '#FDDA3A' },
              { value: '56-60%', color: '#FCC93A' },
              { value: '61-65%', color: '#FBB839' },
              { value: '66-70%', color: '#FAA739' },
              { value: '71-75%', color: '#F99738' },
              { value: '76-80%', color: '#F88638' },
              { value: '81-85%', color: '#F77537' },
              { value: '86-90%', color: '#F66437' },
              { value: '91-95%', color: '#F55336' },
              { value: '96-100% (highest scores)', color: colors.red[500] },
            ],
          },
          'fill-opacity': DEFAULT_OPACITY,
        },
        display_hints: {
          scale: SymbologyScales.CATEGORICAL,
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          theme: CUSTOM_THEME,
        },
      },
    ],
  },
};

export default symbologies;
