import { boundMethod } from 'autobind-decorator';
import classNames from 'classnames';
import React, { Component } from 'react';
import { Box } from 'react-layout-components';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ThunkActionMapDispatch } from 'redux-thunk';
import { t } from 'ttag';

import { getActiveProjectId } from 'uf/app/selectors';
import * as mapActionCreators from 'uf/explore/actions/map';
import { getExploreMapPitch } from 'uf/explore/selectors/map';
import { ProjectId } from 'uf/projects';
import { UFState } from 'uf/state';
import MapButton from 'uf/ui/map/MapButton/MapButton';

interface OwnProps {
  className?: string;
  style?: React.CSSProperties;
  projectId: ProjectId;
}
interface StateProps {
  pitch: number;
}

interface DispatchProps {
  mapActions: ThunkActionMapDispatch<typeof mapActionCreators>;
}

type ExploreMapTogglePitchButtonProps = OwnProps & StateProps & DispatchProps;

export class ExploreMapTogglePitchButton extends Component<ExploreMapTogglePitchButtonProps> {
  @boundMethod
  onTogglePitch() {
    const { pitch, mapActions, projectId } = this.props;
    // don't persist these yet, ExploreMap with set the map viewport
    if (pitch) {
      mapActions.updateMapViewport(projectId, { pitch: 0 });
    } else {
      mapActions.updateMapViewport(projectId, { pitch: 60 });
    }
  }
  render() {
    const { pitch, className, style } = this.props;
    return (
      <MapButton
        id="uf-map-toggle-pitch"
        tooltipText={t`3D mode`}
        active={!!pitch}
        style={style}
        className={classNames(className, 'TogglePitchButton')}
        onClick={this.onTogglePitch}>
        <Box center>
          <strong>{t`3D`}</strong>
        </Box>
      </MapButton>
    );
  }
}
export default connect<StateProps, DispatchProps, {}, UFState>(
  state => ({
    pitch: getExploreMapPitch(state, { projectId: getActiveProjectId(state) }),
  }),
  (dispatch: Dispatch) => ({
    mapActions: bindActionCreators(mapActionCreators, dispatch),
  }),
)(ExploreMapTogglePitchButton);
