import colors from 'material-colors';

import {
  CuratedColumnSymbology,
  CUSTOM_THEME,
  DEFAULT_OPACITY,
  InterpolationTypes,
  PaintColorTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/public/dataset/honolulu_zoning_381554405': {
    zone_class: [
      {
        type: SymbologyTypes.FILL,
        paint: {
          'fill-color': {
            property: 'zone_class',
            type: PaintColorTypes.CATEGORICAL,
            stops: [
              { color: '#ffb318', value: 'A-1' },
              { color: '#eac79b', value: 'A-2' },
              { color: '#d7c1a6', value: 'A-3' },
              { color: '#ccff46', value: 'AG-1' },
              { color: '#acfc3c', value: 'AG-2' },
              { color: '#b0edee', value: 'Aloha' },
              { color: '#fdca67', value: 'AMX-1' },
              { color: '#e0bb87', value: 'AMX-2' },
              { color: '#cbb79c', value: 'AMX-3' },
              { color: '#fdd9c0', value: 'Apart' },
              { color: '#ffe3d6', value: 'ApartMix' },
              { color: '#fea49e', value: 'B-1' },
              { color: '#fe8080', value: 'B-2' },
              { color: '#f2937c', value: 'BMX-3' },
              { color: '#f17165', value: 'BMX-4' },
              { color: '#ffd900', value: 'C' },
              { color: '#d4d4d4', value: 'F-1' },
              { color: '#f8d4fe', value: 'I-1' },
              { color: '#f4bbfd', value: 'I-2' },
              { color: '#f198fd', value: 'I-3' },
              { color: '#e5aaca', value: 'IMX-1' },
              { color: '#d0feed', value: 'Kak' },
              { color: '#efd8f1', value: 'MU' },
              { color: '#e7ffe6', value: 'P-1' },
              { color: '#9aff00', value: 'P-2' },
              { color: '#80ffff', value: 'PU' },
              { color: '#b9efff', value: 'Pub' },
              { color: '#ffff99', value: 'R-10' },
              { color: '#ffffb3', value: 'R-20' },
              { color: colors.yellow.a200, value: 'R-3.5' },
              { color: '#ffff3f', value: 'R-5' },
              { color: '#fffe78', value: 'R-7.5' },
              { color: '#fac6b3', value: 'ResMix' },
              { color: '#fdbfd9', value: 'Resort' },
              { color: '#eee9ff', value: 'WI' },
            ],
          },
          'fill-opacity': DEFAULT_OPACITY,
        },
        display_hints: {
          scale: SymbologyScales.CATEGORICAL,
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          theme: CUSTOM_THEME,
        },
      },
    ],
  },
};

export default symbologies;
