import {
  CuratedColumnSymbology,
  DistributionTypes,
  InterpolationTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const censusPlanningdatabaseSexLayers = [
  {
    layerId: '/public/dataset/us_census_census_planning_database_2018_sex',
    columnKey: 'pct_females_acs_12_16',
  },
  {
    layerId: '/public/dataset/us_census_census_planning_database_2019_sex',
    columnKey: 'pct_females_acs_13_17',
  },
  {
    layerId: '/public/databank/cpd_2020_sex_distribution',
    columnKey: 'pct_females_acs_14_18',
  },
  {
    layerId: '/public/databank/cpd_2021_sex_distribution',
    columnKey: 'pct_females_acs_15_19',
  },
];

const symbologies: Record<
  string,
  Record<string, CuratedColumnSymbology[]>
> = {};
censusPlanningdatabaseSexLayers.forEach(({ layerId, columnKey }) => {
  symbologies[layerId] = {
    [columnKey]: [
      {
        display_hints: {
          distribution: DistributionTypes.DEFAULT,
          interpolation: InterpolationTypes.INTERVAL,
          scale: SymbologyScales.NUMERIC,
          showZero: true,
          theme: 'd3:interpolateRdBu',
        },
        paint: {
          'fill-color': {
            property: columnKey,
            stops: [
              {
                color: 'rgb(103, 0, 31)',
                value: 0,
              },
              {
                color: 'rgb(177, 38, 49)',
                value: 10,
              },
              {
                color: 'rgb(220, 111, 90)',
                value: 20,
              },
              {
                color: 'rgb(246, 182, 154)',
                value: 30,
              },
              {
                color: 'rgb(249, 230, 219)',
                value: 40,
              },
              {
                color: 'rgb(225, 236, 242)',
                value: 50,
              },
              {
                color: 'rgb(166, 207, 227)',
                value: 60,
              },
              {
                color: 'rgb(88, 158, 201)',
                value: 70,
              },
              {
                color: 'rgb(37, 106, 168)',
                value: 80,
              },
              {
                color: 'rgb(5, 48, 97)',
                value: 90,
              },
            ],
            type: 'interval',
          },
          'fill-opacity': 0.5,
        },
        type: SymbologyTypes.FILL,
      },
      {
        display_hints: {
          distribution: DistributionTypes.DEFAULT,
          interpolation: InterpolationTypes.INTERVAL,
          scale: SymbologyScales.NUMERIC,
          showZero: true,
          theme: 'custom',
        },
        paint: {
          'line-color': '#333333',
          'line-opacity': 0.5,
          'line-width': 0.5,
        },
        type: SymbologyTypes.LINE,
      },
    ],
  };
});

export default symbologies;
