import { MapPosition } from 'uf/map';
import { ProjectId } from 'uf/projects';

export function makeBookmarkKeyPrefix(projectId: ProjectId) {
  return `bookmark:project=${projectId}:`;
}

export function makeBookmarkKey(projectId: ProjectId, bookmarkKey: string) {
  return `${makeBookmarkKeyPrefix(projectId)}${bookmarkKey}`;
}

export interface MapPositionEnvelope {
  version: '1.0.0';
  position: MapPosition;
}

export function wrapMapPosition(position: MapPosition): MapPositionEnvelope {
  return {
    version: '1.0.0',
    position,
  };
}
