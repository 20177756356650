import { Feature } from 'geojson';
import { combineReducers } from 'redux';

import { EMPTY_ARRAY } from 'uf/base';
import {
  makeKeyedReducer,
  makeListReducer,
  makeSetValueOnlyReducer,
} from 'uf/base/reducerFactories';
import {
  DELETE_FEATURE,
  DeleteFeatureAction,
  featureEditListActionTypes,
  SET_EDITS_DEFAULT_PROPERTIES,
} from 'uf/mapedit/ActionTypes';
import { LayerEdits, MapEditState } from 'uf/mapedit/state';

/** We will keep track of explicit deletes so they can be blacklisted
 *
 * TODO: `feature.id` is somewhat meaningless, as it is hard to tie it back to
 * the original feature unless it happens to be a consistent hash value of the
 * actual feature's id, whatever that might be. (varies by type) Figure out a
 * way to define a consistent key.
 */
function deleteReducer(
  state: string[] = EMPTY_ARRAY,
  action: DeleteFeatureAction,
): string[] {
  if (action.type === DELETE_FEATURE) {
    if (Array.isArray(action.items)) {
      const newItems = action.items
        .filter(item => !state.includes(item.id as string))
        .map(item => item.id as string);
      if (newItems.length) {
        return [...state, ...newItems];
      }
    } else if (!state.includes(action.items.id as string)) {
      return [...state, action.items.id as string];
    }
  }
  return state;
}

const defaultProperties = makeSetValueOnlyReducer<Record<string, any>>(
  SET_EDITS_DEFAULT_PROPERTIES,
);

/** Reducer for a specific layer */
const layerEditsReducer = combineReducers<LayerEdits>({
  featureOverrides: makeListReducer(
    featureEditListActionTypes,
    (feature: Feature) => feature.id,
  ),
  deletedFeatureIds: deleteReducer,
  defaultProperties,
});

/** Reducer keyed by layer id */
const perLayerEdits = makeKeyedReducer(layerEditsReducer, 'layerId');

/**
 *  Reducer keyed by project id
 *
 * This effecitively is a two-layer reducer:
 *
 * [projectId] => [layerId] => edits
 */
// TODO: fix typing here
const edits = makeKeyedReducer<any, any, any>(perLayerEdits, 'projectId');

export default combineReducers<MapEditState>({ edits });
