import {
  CuratedColumnSymbology,
  CUSTOM_THEME,
  DEFAULT_OPACITY,
  InterpolationTypes,
  PaintColorTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/public/dataset/california_ecoregions_13': {
    us_l3name: [
      {
        type: SymbologyTypes.FILL,
        paint: {
          'fill-color': {
            property: 'us_l3name',
            type: PaintColorTypes.CATEGORICAL,
            stops: [
              { value: 'Central California Valley', color: '#f7e7b4' },
              { value: 'Central Basin and Range', color: '#d7c6cf' },
              {
                value: 'Central California Foothills and Coastal Mountains',
                color: '#8caba8',
              },
              { value: 'Coast Range', color: '#68c4af' },
              {
                value: 'Eastern Cascades Slopes and Foothills',
                color: '#b8dbd3',
              },
              {
                value: 'Klamath Mountains/California High North Coast Range',
                color: '#1b85b8',
              },
              { value: 'Mojave Basin and Range', color: '#c3cb71' },
              { value: 'Nothern Basin and Range', color: '#5a5255' },
              { value: 'Sierra Nevada', color: '#ae5a41' },
              { value: 'Sonoran Basin and Range', color: '#ebdada' },
              { value: 'Southern California Mountains', color: '#a2798f' },
              { value: 'Cascades', color: '#dfdfde' },
              {
                value: 'Southern California/Northern Baja Coast',
                color: '#c3cb71',
              },
            ],
          },
          'fill-opacity': DEFAULT_OPACITY,
        },
        display_hints: {
          scale: SymbologyScales.CATEGORICAL,
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          theme: CUSTOM_THEME,
        },
      },
    ],
  },
};

export default symbologies;
