/**
 * UrbanFootprint App API
 * API for serving UrbanFootprint Application
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * True if the value of any of the expressions are true.
 */
export interface ExpressionOr<> {
  /**
   *
   */
  expressions: Array<object>;
  /**
   * Expression function, set to \"or\".
   */
  fn: ExpressionOr.FnEnum;
}
export namespace ExpressionOr {
  export type FnEnum = 'or';
  export const FnEnum = {
    Or: 'or',
  } as const;
}
