import uniqId from 'uniqid';
import { APP_ADD_MESSAGE, APP_REMOVE_MESSAGE } from './ActionTypes';
import { Message } from './messages';

const TOAST_DURATION_SECONDS = 20;
const TOAST_MESSAGE_DURATION = TOAST_DURATION_SECONDS * 1000;

export interface MessageAction {
  type: string;
  replaceMessage?: boolean;
  message: Message;
}

export function addAppMessage(
  textOrError: string | Error,
  message: Message = {},
) {
  const {
    level = 'info',
    status,
    replacesMessage = null,
    timeout = TOAST_MESSAGE_DURATION,
    action,
    id: messageId = null,
    isTrustedHtml,
  } = message;
  let text = textOrError;
  let detail = message.detail;
  let stack = null;

  if (typeof text !== 'string') {
    // error objects and exceptions have a 'message' property
    const { message: errMessage, stack: errStack } = text;
    if (errMessage) {
      text = errMessage;
      detail = message.detail;
    } else {
      // Try to just convert to a string.
      text = `${text}`;
    }

    if (errStack) {
      stack = errStack;
    }
  }

  const id = messageId || uniqId();
  const messageAction: MessageAction = {
    type: APP_ADD_MESSAGE,
    message: {
      text,
      detail,
      level,
      status,
      stack,
      action,
      replacesMessage,
      timeout,
      id,
      isTrustedHtml,
    },
  };

  return messageAction;
}
export interface RemoveMessageAction {
  type: string;
  id: string;
}
/**
 * Removes a message previously added with `addAppMessage`. See
 * `addAppMessage` for details and an example.
 */
export function removeAppMessage(id: string): RemoveMessageAction {
  return {
    type: APP_REMOVE_MESSAGE,
    id,
  };
}
