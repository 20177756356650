import { Intent, Menu, MenuDivider, Spinner } from '@blueprintjs/core';
import {
  mdiCheck,
  mdiCloseCircle,
  mdiMagnify,
  mdiMapMarkerCircle,
  mdiPencil,
  mdiTrashCanOutline,
} from '@mdi/js';
import Icon from '@mdi/react';
import React, { CSSProperties, FC, Suspense, useCallback } from 'react';

import { t } from 'ttag';

import { LayerId } from 'uf/layers';
import { ProjectId } from 'uf/projects';
import { IconSize, IconSizeNew } from 'uf/ui/base/icon';
import OverflowMenuItem from 'uf/ui/base/OverflowMenuItem/OverflowMenuItem';

import {
  useIsLayerRemovable,
  useLayerGeometryType,
  useProjectActions,
} from './hooks';
import useUserFlag from 'uf/ui/user/useUserFlag';
import { dialogOpened } from 'uf/layers/reducer/zoomExtentSlice';
import { useDispatch } from 'react-redux';

// clear out positioning of the menu so it can be rendered directly in the popover
const menuStyle: CSSProperties = {
  position: 'static',
  display: 'block',
  clear: 'left',
};

interface OwnProps {
  layerId: LayerId;
  className?: string;
  paintedOnly?: boolean;
  projectId: ProjectId;
  onDismiss?: () => void;
  onDelete?: (layerId: LayerId) => void;
  onShowPaintedOnly?: () => void;
  onConvertToPoints?: () => void;
  onUpdateSelect?: () => void;
}

export const LayerListItemMenu: FC<OwnProps> = ({
  layerId,
  projectId,
  onDelete,
  onShowPaintedOnly,
  onConvertToPoints,
  paintedOnly,
  onDismiss,
  onUpdateSelect,
}) => {
  const dispatch = useDispatch();
  const onDeleteLayer = useCallback(() => {
    onDelete(layerId);
    if (onDismiss) {
      onDismiss();
    }
  }, [onDelete, layerId, onDismiss]);

  const projectActions = useProjectActions();
  const { updateLayerList } = projectActions;

  const onRemoveLayer = useCallback(() => {
    updateLayerList(projectId, layerId, true);
  }, [projectId, layerId, updateLayerList]);

  const isPolygon = useLayerGeometryType(layerId).endsWith('Polygon');

  const isRemovable = useIsLayerRemovable(projectId, layerId);

  const showPaintedOnlyStyle: CSSProperties = {
    visibility: paintedOnly ? 'initial' : 'hidden',
  };
  const showZoomExtentOption = useUserFlag('layer-zoom-extent');
  const handleEditZoomClick = useCallback(() => {
    dispatch(dialogOpened(layerId));
  }, [layerId, dispatch]);
  return (
    <Menu role="menu" style={menuStyle}>
      <MenuDivider title={t`Layer options`} />
      {onUpdateSelect && (
        <OverflowMenuItem
          label={t`Change layer name`}
          icon={
            <Icon
              path={mdiPencil}
              size={IconSizeNew.SMALL}
              title={t`Change layer name`}
            />
          }
          onClick={onUpdateSelect}
        />
      )}
      {isPolygon && onConvertToPoints && (
        <OverflowMenuItem
          label={t`Convert to point layer`}
          icon={
            <Icon
              path={mdiMapMarkerCircle}
              size={IconSizeNew.SMALL}
              title={t`Convert to point layer`}
            />
          }
          onClick={onConvertToPoints}
        />
      )}
      {isRemovable && (
        <OverflowMenuItem
          label={t`Remove layer`}
          icon={
            <Icon
              path={mdiCloseCircle}
              size={IconSizeNew.SMALL}
              title={t`Remove layer`}
            />
          }
          onClick={onRemoveLayer}
        />
      )}
      {onShowPaintedOnly && (
        <OverflowMenuItem
          icon={
            <Icon
              path={mdiCheck}
              size={IconSizeNew.SMALL}
              style={showPaintedOnlyStyle}
              title={t`Show only painted geometries`}
            />
          }
          label={t`Show only painted geometries`}
          className={'OverflowMenuItem-show-painted-only'}
          onClick={onShowPaintedOnly}
        />
      )}
      {onDelete && (
        <OverflowMenuItem
          label={t`Delete layer`}
          icon={
            <Icon
              path={mdiTrashCanOutline}
              size={IconSizeNew.SMALL}
              title={t`Delete layer`}
            />
          }
          onClick={onDeleteLayer}
        />
      )}
      {showZoomExtentOption && (
        <OverflowMenuItem
          label={t`Edit zoom extent`}
          icon={
            <Icon
              path={mdiMagnify}
              size={IconSizeNew.SMALL}
              title={t`Edit zoom extent`}
            />
          }
          onClick={handleEditZoomClick}
        />
      )}
    </Menu>
  );
};

export default function DefaultLayerListItemMenu(props: OwnProps) {
  return (
    <Suspense
      fallback={<Spinner intent={Intent.PRIMARY} size={IconSize.SMALL} />}>
      <LayerListItemMenu {...props} />
    </Suspense>
  );
}
