import { PaintingActionInfo } from 'uf-api/model/models';
import { FilterSpec } from 'uf/layers/filters';
import { ProjectId } from 'uf/projects';

export interface PaintScenarioExtra {
  projectId: ProjectId;
  scenarioKey: string;
  filters: Partial<FilterSpec>;
  painter: PaintingActionInfo.PainterEnum;

  params: any;
  key: string;
}

export function makePaintScenarioKey(
  projectId: ProjectId,
  scenarioKey: string,
): string {
  return `${projectId}:${scenarioKey}`;
}
