import { IMenuContext, Region } from '@blueprintjs/table';
import bbox from '@turf/bbox';
import { Feature, Geometries, geometryCollection } from '@turf/helpers';
import _ from 'lodash';
import { LngLatBoundsLike } from 'mapbox-gl';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { ZOOM_LOCATION } from 'uf/explore/logging';
import { logEvent } from 'uf/logging';
import { makeSimpleGetMapbox } from 'uf/map/selectors/map';
import useTableData from './useTableData';

const MAX_ZOOM = 13;

const selectMapInstance = makeSimpleGetMapbox('explore');

// Get the row indices of the passed table selection
const getRangeFromSelection = (
  selection: Region[],
  maxRange: number,
): number[] => {
  const range = _.reduce(
    selection,
    (acc, item) => {
      // Get the row range of each selection
      let rowIndices = [];
      if (Array.isArray(item.rows)) {
        rowIndices = _.range(item.rows[0], Number(item.rows[1]) + 1);
      } else {
        // If the selection is an empty object, all rows are selected
        rowIndices = _.range(0, maxRange);
      }
      acc.push(...rowIndices);
      return acc;
    },
    [],
  );
  return _.uniq(range);
};

const useTableSelection = () => {
  const [selection, setSelection] = useState<Region[]>([]);
  // Get rendered GeoJSON features
  const { features } = useTableData();
  // Grab Mapbox instance
  const map = useSelector(selectMapInstance);
  // Callback for 'Zoom to location' interactions
  const flyToSelection = useCallback(
    /**
     * Argument newSelection returns an array of selections that
     * contains an object with cols and rows properties
     * ex: [ { cols: [0, 7], rows: [2, 4] } ]
     * The above example signifies the selection of the 1st through 8th
     * columns and the 3rd through 5th rows
     * ex: [ { cols: [0, 0], rows: [1, 1] }, { cols: [0, 0], rows: [4, 4] } ]
     * The above example signifies the selection first column of the 2nd and 5th rows
     */
    (newSelection: IMenuContext | number) => {
      let selectedRows: number[] = [];
      const maxRange = features?.length || 0;
      /**
       * Clicking the row header in Blueprint provides only a single index.
       * To allow the ability to zoom to a selection on row header click,
       * we'll store the current selection in state and check if the
       * selected row index is within the range of the current selection.
       * Otherwise, we proceed with the single index.
       */
      if (_.isNumber(newSelection)) {
        selectedRows = getRangeFromSelection(selection, maxRange);
        // Check if the clicked row header is within the current selection
        if (!_.includes(selectedRows, newSelection)) {
          selectedRows = [newSelection];
        }
      } else {
        // Grab rows from the callback provided table selection
        selectedRows = getRangeFromSelection(
          newSelection.getSelectedRegions(),
          maxRange,
        );
      }
      if (features?.length) {
        // Grab features by indices
        const selectedFeatures = _.pullAt<Feature>(
          Array.from(features),
          selectedRows,
        );
        // Flat map features' geometries
        const selectedGeometry = _.map(
          selectedFeatures,
          feature => feature?.geometry,
        ) as Geometries[];
        // Get total valid feature count
        const featureCount = selectedGeometry?.length;
        // If selection exists generate a bounding box for new viewport to fit to
        if (featureCount > 0) {
          // Get the geometry type of the feature(s) (this is always unique)
          const geometryType = selectedGeometry[0].type;
          // Generate a turf geometry collection based off of feature geometries
          const collection = geometryCollection(selectedGeometry);
          // Check if we're moving to a single point feature
          const isSinglePointFeature =
            featureCount === 1 && geometryType === 'Point';
          const boundingBox = bbox(collection);
          // Move map to new bounding box
          map.fitBounds(boundingBox as LngLatBoundsLike, {
            linear: true,
            padding: 50,
            /**
             * Max zoom only defined for a single Point feature
             * Use the current zoom level if the user is zoomed in closer than default max
             */
            ...(isSinglePointFeature && {
              maxZoom: map.getZoom() < MAX_ZOOM ? MAX_ZOOM : map.getZoom(),
            }),
          });
          // Send selection information to Amplitude
          logEvent(ZOOM_LOCATION, {
            featureCount,
            geometryType,
            boundingBox,
          });
        }
      }
    },
    [features, map, selection],
  );
  return { flyToSelection, setSelection };
};

export default useTableSelection;
