import {
  CuratedColumnSymbology,
  CUSTOM_THEME,
  DEFAULT_OPACITY,
  DistributionTypes,
  InterpolationTypes,
  PaintColorTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/public/dataset/hud_env_health_hazard_idx': {
    haz_idx: [
      {
        type: SymbologyTypes.FILL,
        paint: {
          'fill-color': {
            property: 'haz_idx',
            type: PaintColorTypes.INTERVAL,
            stops: [
              { value: 0, label: '0 to 19', color: '#b00b26' },
              { value: 20, label: '20 to 39', color: '#fa9958' },
              { value: 40, label: '40 to 59', color: '#fdf1a7' },
              { value: 60, label: '60 to 79', color: '#a8da70' },
              { value: 80, label: '80 to 100', color: '#006837' },
            ],
          },
          'fill-opacity': DEFAULT_OPACITY,
        },
        display_hints: {
          scale: SymbologyScales.NUMERIC,
          distribution: DistributionTypes.DEFAULT,
          interpolation: InterpolationTypes.INTERVAL,
          theme: CUSTOM_THEME,
        },
      },
    ],
  },
};

export default symbologies;
