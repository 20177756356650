/**
 * UrbanFootprint App API
 * API for serving UrbanFootprint Application
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { ClientResponse } from '../variables';

// @ts-ignore: This is used only for params, but some services have param-free APIs
import { InternalServiceInterface } from './internal.serviceInterface';

// @ts-ignore: These imports are for both params and returnType, but we only use returnType
import { DynamicLayerMetadata } from '../model/dynamicLayerMetadata';
// @ts-ignore: These imports are for both params and returnType, but we only use returnType
import { EntityExplorerEntity } from '../model/entityExplorerEntity';
// @ts-ignore: These imports are for both params and returnType, but we only use returnType
import { InternalUserInfo } from '../model/internalUserInfo';
// @ts-ignore: These imports are for both params and returnType, but we only use returnType
import { LayerBounds } from '../model/layerBounds';
// @ts-ignore: These imports are for both params and returnType, but we only use returnType
import { LayerGeoData } from '../model/layerGeoData';
// @ts-ignore: These imports are for both params and returnType, but we only use returnType
import { LayerMetadata } from '../model/layerMetadata';
// @ts-ignore: These imports are for both params and returnType, but we only use returnType
import { LayerReferenceList } from '../model/layerReferenceList';
// @ts-ignore: These imports are for both params and returnType, but we only use returnType
import { LayerRegistryItem } from '../model/layerRegistryItem';
// @ts-ignore: These imports are for both params and returnType, but we only use returnType
import { LayerStats } from '../model/layerStats';
// @ts-ignore: These imports are for both params and returnType, but we only use returnType
import { LayerSummaryItem } from '../model/layerSummaryItem';
// @ts-ignore: These imports are for both params and returnType, but we only use returnType
import { PaintingActionInfo } from '../model/paintingActionInfo';
// @ts-ignore: These imports are for both params and returnType, but we only use returnType
import { ProblemDetail } from '../model/problemDetail';
// @ts-ignore: These imports are for both params and returnType, but we only use returnType
import { ProjectReference } from '../model/projectReference';
// @ts-ignore: These imports are for both params and returnType, but we only use returnType
import { Task } from '../model/task';
// @ts-ignore: These imports are for both params and returnType, but we only use returnType
import { UserDevice } from '../model/userDevice';

/**
 * Package: api
 */

const bakeLibraryPrefix = `uf-api/internal/bake_library/`;
/**
 * Action types for internal.bake_library
 *
 * Begin baking out builtforms.
 */
export const bakeLibraryActionTypes = {
  LOAD: `${bakeLibraryPrefix}LOAD` as 'uf-api/internal/bake_library/LOAD',
  SUCCESS:
    `${bakeLibraryPrefix}SUCCESS` as 'uf-api/internal/bake_library/SUCCESS',
  FAILURE:
    `${bakeLibraryPrefix}FAILURE` as 'uf-api/internal/bake_library/FAILURE',
  CLEAR: `${bakeLibraryPrefix}CLEAR` as 'uf-api/internal/bake_library/CLEAR',
} as const;

/**
 * LOAD action for internal.bake_library
 * Begin baking out builtforms.
 */
export interface bakeLibraryLoadAction {
  readonly type: 'uf-api/internal/bake_library/LOAD';
  readonly params: InternalServiceInterface.bakeLibraryParams;
  readonly promise: Promise<ClientResponse<{}>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for internal.bake_library
 * Begin baking out builtforms.
 */
export interface bakeLibrarySuccessAction {
  readonly type: 'uf-api/internal/bake_library/SUCCESS';
  readonly result: {};
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for internal.bake_library
 * Begin baking out builtforms.
 */
export interface bakeLibraryFailureAction {
  readonly type: 'uf-api/internal/bake_library/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for internal.bake_library
 * Begin baking out builtforms.
 */
export interface bakeLibraryClearAction {
  readonly type: 'uf-api/internal/bake_library/CLEAR';
  readonly key: string;
}

const clearFlaskCachePrefix = `uf-api/internal/clear_flask_cache/`;
/**
 * Action types for internal.clear_flask_cache
 *
 */
export const clearFlaskCacheActionTypes = {
  LOAD: `${clearFlaskCachePrefix}LOAD` as 'uf-api/internal/clear_flask_cache/LOAD',
  SUCCESS:
    `${clearFlaskCachePrefix}SUCCESS` as 'uf-api/internal/clear_flask_cache/SUCCESS',
  FAILURE:
    `${clearFlaskCachePrefix}FAILURE` as 'uf-api/internal/clear_flask_cache/FAILURE',
  CLEAR:
    `${clearFlaskCachePrefix}CLEAR` as 'uf-api/internal/clear_flask_cache/CLEAR',
} as const;

/**
 * LOAD action for internal.clear_flask_cache
 */
export interface clearFlaskCacheLoadAction {
  readonly type: 'uf-api/internal/clear_flask_cache/LOAD';

  readonly promise: Promise<ClientResponse<boolean>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for internal.clear_flask_cache
 */
export interface clearFlaskCacheSuccessAction {
  readonly type: 'uf-api/internal/clear_flask_cache/SUCCESS';
  readonly result: boolean;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for internal.clear_flask_cache
 */
export interface clearFlaskCacheFailureAction {
  readonly type: 'uf-api/internal/clear_flask_cache/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for internal.clear_flask_cache
 */
export interface clearFlaskCacheClearAction {
  readonly type: 'uf-api/internal/clear_flask_cache/CLEAR';
  readonly key: string;
}

const clearSubscriptionCachePrefix = `uf-api/internal/clear_subscription_cache/`;
/**
 * Action types for internal.clear_subscription_cache
 *
 */
export const clearSubscriptionCacheActionTypes = {
  LOAD: `${clearSubscriptionCachePrefix}LOAD` as 'uf-api/internal/clear_subscription_cache/LOAD',
  SUCCESS:
    `${clearSubscriptionCachePrefix}SUCCESS` as 'uf-api/internal/clear_subscription_cache/SUCCESS',
  FAILURE:
    `${clearSubscriptionCachePrefix}FAILURE` as 'uf-api/internal/clear_subscription_cache/FAILURE',
  CLEAR:
    `${clearSubscriptionCachePrefix}CLEAR` as 'uf-api/internal/clear_subscription_cache/CLEAR',
} as const;

/**
 * LOAD action for internal.clear_subscription_cache
 */
export interface clearSubscriptionCacheLoadAction {
  readonly type: 'uf-api/internal/clear_subscription_cache/LOAD';
  readonly params: InternalServiceInterface.clearSubscriptionCacheParams;
  readonly promise: Promise<ClientResponse<{}>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for internal.clear_subscription_cache
 */
export interface clearSubscriptionCacheSuccessAction {
  readonly type: 'uf-api/internal/clear_subscription_cache/SUCCESS';
  readonly result: {};
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for internal.clear_subscription_cache
 */
export interface clearSubscriptionCacheFailureAction {
  readonly type: 'uf-api/internal/clear_subscription_cache/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for internal.clear_subscription_cache
 */
export interface clearSubscriptionCacheClearAction {
  readonly type: 'uf-api/internal/clear_subscription_cache/CLEAR';
  readonly key: string;
}

const createDemoProjectPrefix = `uf-api/internal/create_demo_project/`;
/**
 * Action types for internal.create_demo_project
 *
 * Creates a demo project for the current user.
 */
export const createDemoProjectActionTypes = {
  LOAD: `${createDemoProjectPrefix}LOAD` as 'uf-api/internal/create_demo_project/LOAD',
  SUCCESS:
    `${createDemoProjectPrefix}SUCCESS` as 'uf-api/internal/create_demo_project/SUCCESS',
  FAILURE:
    `${createDemoProjectPrefix}FAILURE` as 'uf-api/internal/create_demo_project/FAILURE',
  CLEAR:
    `${createDemoProjectPrefix}CLEAR` as 'uf-api/internal/create_demo_project/CLEAR',
} as const;

/**
 * LOAD action for internal.create_demo_project
 * Creates a demo project for the current user.
 */
export interface createDemoProjectLoadAction {
  readonly type: 'uf-api/internal/create_demo_project/LOAD';
  readonly params: InternalServiceInterface.createDemoProjectParams;
  readonly promise: Promise<ClientResponse<ProjectReference>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for internal.create_demo_project
 * Creates a demo project for the current user.
 */
export interface createDemoProjectSuccessAction {
  readonly type: 'uf-api/internal/create_demo_project/SUCCESS';
  readonly result: ProjectReference;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for internal.create_demo_project
 * Creates a demo project for the current user.
 */
export interface createDemoProjectFailureAction {
  readonly type: 'uf-api/internal/create_demo_project/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for internal.create_demo_project
 * Creates a demo project for the current user.
 */
export interface createDemoProjectClearAction {
  readonly type: 'uf-api/internal/create_demo_project/CLEAR';
  readonly key: string;
}

const exportUserDevicesPrefix = `uf-api/internal/export_user_devices/`;
/**
 * Action types for internal.export_user_devices
 *
 * Get user devices as CSV.
 */
export const exportUserDevicesActionTypes = {
  LOAD: `${exportUserDevicesPrefix}LOAD` as 'uf-api/internal/export_user_devices/LOAD',
  SUCCESS:
    `${exportUserDevicesPrefix}SUCCESS` as 'uf-api/internal/export_user_devices/SUCCESS',
  FAILURE:
    `${exportUserDevicesPrefix}FAILURE` as 'uf-api/internal/export_user_devices/FAILURE',
  CLEAR:
    `${exportUserDevicesPrefix}CLEAR` as 'uf-api/internal/export_user_devices/CLEAR',
} as const;

/**
 * LOAD action for internal.export_user_devices
 * Get user devices as CSV.
 */
export interface exportUserDevicesLoadAction {
  readonly type: 'uf-api/internal/export_user_devices/LOAD';

  readonly promise: Promise<ClientResponse<Blob>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for internal.export_user_devices
 * Get user devices as CSV.
 */
export interface exportUserDevicesSuccessAction {
  readonly type: 'uf-api/internal/export_user_devices/SUCCESS';
  readonly result: Blob;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for internal.export_user_devices
 * Get user devices as CSV.
 */
export interface exportUserDevicesFailureAction {
  readonly type: 'uf-api/internal/export_user_devices/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for internal.export_user_devices
 * Get user devices as CSV.
 */
export interface exportUserDevicesClearAction {
  readonly type: 'uf-api/internal/export_user_devices/CLEAR';
  readonly key: string;
}

const getAvailableFeatureFlagsPrefix = `uf-api/internal/get_available_feature_flags/`;
/**
 * Action types for internal.get_available_feature_flags
 *
 */
export const getAvailableFeatureFlagsActionTypes = {
  LOAD: `${getAvailableFeatureFlagsPrefix}LOAD` as 'uf-api/internal/get_available_feature_flags/LOAD',
  SUCCESS:
    `${getAvailableFeatureFlagsPrefix}SUCCESS` as 'uf-api/internal/get_available_feature_flags/SUCCESS',
  FAILURE:
    `${getAvailableFeatureFlagsPrefix}FAILURE` as 'uf-api/internal/get_available_feature_flags/FAILURE',
  CLEAR:
    `${getAvailableFeatureFlagsPrefix}CLEAR` as 'uf-api/internal/get_available_feature_flags/CLEAR',
} as const;

/**
 * LOAD action for internal.get_available_feature_flags
 */
export interface getAvailableFeatureFlagsLoadAction {
  readonly type: 'uf-api/internal/get_available_feature_flags/LOAD';

  readonly promise: Promise<ClientResponse<Array<string>>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for internal.get_available_feature_flags
 */
export interface getAvailableFeatureFlagsSuccessAction {
  readonly type: 'uf-api/internal/get_available_feature_flags/SUCCESS';
  readonly result: Array<string>;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for internal.get_available_feature_flags
 */
export interface getAvailableFeatureFlagsFailureAction {
  readonly type: 'uf-api/internal/get_available_feature_flags/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for internal.get_available_feature_flags
 */
export interface getAvailableFeatureFlagsClearAction {
  readonly type: 'uf-api/internal/get_available_feature_flags/CLEAR';
  readonly key: string;
}

const getDynamicLayerInternalPrefix = `uf-api/internal/get_dynamic_layer_internal/`;
/**
 * Action types for internal.get_dynamic_layer_internal
 *
 * Get dynamic layer metadata
 */
export const getDynamicLayerInternalActionTypes = {
  LOAD: `${getDynamicLayerInternalPrefix}LOAD` as 'uf-api/internal/get_dynamic_layer_internal/LOAD',
  SUCCESS:
    `${getDynamicLayerInternalPrefix}SUCCESS` as 'uf-api/internal/get_dynamic_layer_internal/SUCCESS',
  FAILURE:
    `${getDynamicLayerInternalPrefix}FAILURE` as 'uf-api/internal/get_dynamic_layer_internal/FAILURE',
  CLEAR:
    `${getDynamicLayerInternalPrefix}CLEAR` as 'uf-api/internal/get_dynamic_layer_internal/CLEAR',
} as const;

/**
 * LOAD action for internal.get_dynamic_layer_internal
 * Get dynamic layer metadata
 */
export interface getDynamicLayerInternalLoadAction {
  readonly type: 'uf-api/internal/get_dynamic_layer_internal/LOAD';
  readonly params: InternalServiceInterface.getDynamicLayerInternalParams;
  readonly promise: Promise<ClientResponse<DynamicLayerMetadata>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for internal.get_dynamic_layer_internal
 * Get dynamic layer metadata
 */
export interface getDynamicLayerInternalSuccessAction {
  readonly type: 'uf-api/internal/get_dynamic_layer_internal/SUCCESS';
  readonly result: DynamicLayerMetadata;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for internal.get_dynamic_layer_internal
 * Get dynamic layer metadata
 */
export interface getDynamicLayerInternalFailureAction {
  readonly type: 'uf-api/internal/get_dynamic_layer_internal/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for internal.get_dynamic_layer_internal
 * Get dynamic layer metadata
 */
export interface getDynamicLayerInternalClearAction {
  readonly type: 'uf-api/internal/get_dynamic_layer_internal/CLEAR';
  readonly key: string;
}

const getEntityInfoPrefix = `uf-api/internal/get_entity_info/`;
/**
 * Action types for internal.get_entity_info
 *
 */
export const getEntityInfoActionTypes = {
  LOAD: `${getEntityInfoPrefix}LOAD` as 'uf-api/internal/get_entity_info/LOAD',
  SUCCESS:
    `${getEntityInfoPrefix}SUCCESS` as 'uf-api/internal/get_entity_info/SUCCESS',
  FAILURE:
    `${getEntityInfoPrefix}FAILURE` as 'uf-api/internal/get_entity_info/FAILURE',
  CLEAR:
    `${getEntityInfoPrefix}CLEAR` as 'uf-api/internal/get_entity_info/CLEAR',
} as const;

/**
 * LOAD action for internal.get_entity_info
 */
export interface getEntityInfoLoadAction {
  readonly type: 'uf-api/internal/get_entity_info/LOAD';
  readonly params: InternalServiceInterface.getEntityInfoParams;
  readonly promise: Promise<ClientResponse<EntityExplorerEntity>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for internal.get_entity_info
 */
export interface getEntityInfoSuccessAction {
  readonly type: 'uf-api/internal/get_entity_info/SUCCESS';
  readonly result: EntityExplorerEntity;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for internal.get_entity_info
 */
export interface getEntityInfoFailureAction {
  readonly type: 'uf-api/internal/get_entity_info/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for internal.get_entity_info
 */
export interface getEntityInfoClearAction {
  readonly type: 'uf-api/internal/get_entity_info/CLEAR';
  readonly key: string;
}

const getInternalLayerBoundsPrefix = `uf-api/internal/get_internal_layer_bounds/`;
/**
 * Action types for internal.get_internal_layer_bounds
 *
 * Get geographic bounds for a layer
 */
export const getInternalLayerBoundsActionTypes = {
  LOAD: `${getInternalLayerBoundsPrefix}LOAD` as 'uf-api/internal/get_internal_layer_bounds/LOAD',
  SUCCESS:
    `${getInternalLayerBoundsPrefix}SUCCESS` as 'uf-api/internal/get_internal_layer_bounds/SUCCESS',
  FAILURE:
    `${getInternalLayerBoundsPrefix}FAILURE` as 'uf-api/internal/get_internal_layer_bounds/FAILURE',
  CLEAR:
    `${getInternalLayerBoundsPrefix}CLEAR` as 'uf-api/internal/get_internal_layer_bounds/CLEAR',
} as const;

/**
 * LOAD action for internal.get_internal_layer_bounds
 * Get geographic bounds for a layer
 */
export interface getInternalLayerBoundsLoadAction {
  readonly type: 'uf-api/internal/get_internal_layer_bounds/LOAD';
  readonly params: InternalServiceInterface.getInternalLayerBoundsParams;
  readonly promise: Promise<ClientResponse<LayerBounds>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for internal.get_internal_layer_bounds
 * Get geographic bounds for a layer
 */
export interface getInternalLayerBoundsSuccessAction {
  readonly type: 'uf-api/internal/get_internal_layer_bounds/SUCCESS';
  readonly result: LayerBounds;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for internal.get_internal_layer_bounds
 * Get geographic bounds for a layer
 */
export interface getInternalLayerBoundsFailureAction {
  readonly type: 'uf-api/internal/get_internal_layer_bounds/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for internal.get_internal_layer_bounds
 * Get geographic bounds for a layer
 */
export interface getInternalLayerBoundsClearAction {
  readonly type: 'uf-api/internal/get_internal_layer_bounds/CLEAR';
  readonly key: string;
}

const getInternalLayerGeoKeysPrefix = `uf-api/internal/get_internal_layer_geo_keys/`;
/**
 * Action types for internal.get_internal_layer_geo_keys
 *
 * Get geo keys for a layer by filter
 */
export const getInternalLayerGeoKeysActionTypes = {
  LOAD: `${getInternalLayerGeoKeysPrefix}LOAD` as 'uf-api/internal/get_internal_layer_geo_keys/LOAD',
  SUCCESS:
    `${getInternalLayerGeoKeysPrefix}SUCCESS` as 'uf-api/internal/get_internal_layer_geo_keys/SUCCESS',
  FAILURE:
    `${getInternalLayerGeoKeysPrefix}FAILURE` as 'uf-api/internal/get_internal_layer_geo_keys/FAILURE',
  CLEAR:
    `${getInternalLayerGeoKeysPrefix}CLEAR` as 'uf-api/internal/get_internal_layer_geo_keys/CLEAR',
} as const;

/**
 * LOAD action for internal.get_internal_layer_geo_keys
 * Get geo keys for a layer by filter
 */
export interface getInternalLayerGeoKeysLoadAction {
  readonly type: 'uf-api/internal/get_internal_layer_geo_keys/LOAD';
  readonly params: InternalServiceInterface.getInternalLayerGeoKeysParams;
  readonly promise: Promise<ClientResponse<LayerGeoData>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for internal.get_internal_layer_geo_keys
 * Get geo keys for a layer by filter
 */
export interface getInternalLayerGeoKeysSuccessAction {
  readonly type: 'uf-api/internal/get_internal_layer_geo_keys/SUCCESS';
  readonly result: LayerGeoData;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for internal.get_internal_layer_geo_keys
 * Get geo keys for a layer by filter
 */
export interface getInternalLayerGeoKeysFailureAction {
  readonly type: 'uf-api/internal/get_internal_layer_geo_keys/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for internal.get_internal_layer_geo_keys
 * Get geo keys for a layer by filter
 */
export interface getInternalLayerGeoKeysClearAction {
  readonly type: 'uf-api/internal/get_internal_layer_geo_keys/CLEAR';
  readonly key: string;
}

const getInternalLayerStatsPrefix = `uf-api/internal/get_internal_layer_stats/`;
/**
 * Action types for internal.get_internal_layer_stats
 *
 * Get stats for a layer
 */
export const getInternalLayerStatsActionTypes = {
  LOAD: `${getInternalLayerStatsPrefix}LOAD` as 'uf-api/internal/get_internal_layer_stats/LOAD',
  SUCCESS:
    `${getInternalLayerStatsPrefix}SUCCESS` as 'uf-api/internal/get_internal_layer_stats/SUCCESS',
  FAILURE:
    `${getInternalLayerStatsPrefix}FAILURE` as 'uf-api/internal/get_internal_layer_stats/FAILURE',
  CLEAR:
    `${getInternalLayerStatsPrefix}CLEAR` as 'uf-api/internal/get_internal_layer_stats/CLEAR',
} as const;

/**
 * LOAD action for internal.get_internal_layer_stats
 * Get stats for a layer
 */
export interface getInternalLayerStatsLoadAction {
  readonly type: 'uf-api/internal/get_internal_layer_stats/LOAD';
  readonly params: InternalServiceInterface.getInternalLayerStatsParams;
  readonly promise: Promise<ClientResponse<LayerStats>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for internal.get_internal_layer_stats
 * Get stats for a layer
 */
export interface getInternalLayerStatsSuccessAction {
  readonly type: 'uf-api/internal/get_internal_layer_stats/SUCCESS';
  readonly result: LayerStats;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for internal.get_internal_layer_stats
 * Get stats for a layer
 */
export interface getInternalLayerStatsFailureAction {
  readonly type: 'uf-api/internal/get_internal_layer_stats/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for internal.get_internal_layer_stats
 * Get stats for a layer
 */
export interface getInternalLayerStatsClearAction {
  readonly type: 'uf-api/internal/get_internal_layer_stats/CLEAR';
  readonly key: string;
}

const getLayerMetadataPrefix = `uf-api/internal/get_layer_metadata/`;
/**
 * Action types for internal.get_layer_metadata
 *
 * Get metadata for a layer
 */
export const getLayerMetadataActionTypes = {
  LOAD: `${getLayerMetadataPrefix}LOAD` as 'uf-api/internal/get_layer_metadata/LOAD',
  SUCCESS:
    `${getLayerMetadataPrefix}SUCCESS` as 'uf-api/internal/get_layer_metadata/SUCCESS',
  FAILURE:
    `${getLayerMetadataPrefix}FAILURE` as 'uf-api/internal/get_layer_metadata/FAILURE',
  CLEAR:
    `${getLayerMetadataPrefix}CLEAR` as 'uf-api/internal/get_layer_metadata/CLEAR',
} as const;

/**
 * LOAD action for internal.get_layer_metadata
 * Get metadata for a layer
 */
export interface getLayerMetadataLoadAction {
  readonly type: 'uf-api/internal/get_layer_metadata/LOAD';
  readonly params: InternalServiceInterface.getLayerMetadataParams;
  readonly promise: Promise<ClientResponse<LayerMetadata>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for internal.get_layer_metadata
 * Get metadata for a layer
 */
export interface getLayerMetadataSuccessAction {
  readonly type: 'uf-api/internal/get_layer_metadata/SUCCESS';
  readonly result: LayerMetadata;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for internal.get_layer_metadata
 * Get metadata for a layer
 */
export interface getLayerMetadataFailureAction {
  readonly type: 'uf-api/internal/get_layer_metadata/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for internal.get_layer_metadata
 * Get metadata for a layer
 */
export interface getLayerMetadataClearAction {
  readonly type: 'uf-api/internal/get_layer_metadata/CLEAR';
  readonly key: string;
}

const getLayerRegistryPrefix = `uf-api/internal/get_layer_registry/`;
/**
 * Action types for internal.get_layer_registry
 *
 * Return the layer registry.
 */
export const getLayerRegistryActionTypes = {
  LOAD: `${getLayerRegistryPrefix}LOAD` as 'uf-api/internal/get_layer_registry/LOAD',
  SUCCESS:
    `${getLayerRegistryPrefix}SUCCESS` as 'uf-api/internal/get_layer_registry/SUCCESS',
  FAILURE:
    `${getLayerRegistryPrefix}FAILURE` as 'uf-api/internal/get_layer_registry/FAILURE',
  CLEAR:
    `${getLayerRegistryPrefix}CLEAR` as 'uf-api/internal/get_layer_registry/CLEAR',
} as const;

/**
 * LOAD action for internal.get_layer_registry
 * Return the layer registry.
 */
export interface getLayerRegistryLoadAction {
  readonly type: 'uf-api/internal/get_layer_registry/LOAD';
  readonly params: InternalServiceInterface.getLayerRegistryParams;
  readonly promise: Promise<ClientResponse<Array<LayerRegistryItem>>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for internal.get_layer_registry
 * Return the layer registry.
 */
export interface getLayerRegistrySuccessAction {
  readonly type: 'uf-api/internal/get_layer_registry/SUCCESS';
  readonly result: Array<LayerRegistryItem>;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for internal.get_layer_registry
 * Return the layer registry.
 */
export interface getLayerRegistryFailureAction {
  readonly type: 'uf-api/internal/get_layer_registry/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for internal.get_layer_registry
 * Return the layer registry.
 */
export interface getLayerRegistryClearAction {
  readonly type: 'uf-api/internal/get_layer_registry/CLEAR';
  readonly key: string;
}

const getLayerSummaryPrefix = `uf-api/internal/get_layer_summary/`;
/**
 * Action types for internal.get_layer_summary
 *
 * Return the layer summary.
 */
export const getLayerSummaryActionTypes = {
  LOAD: `${getLayerSummaryPrefix}LOAD` as 'uf-api/internal/get_layer_summary/LOAD',
  SUCCESS:
    `${getLayerSummaryPrefix}SUCCESS` as 'uf-api/internal/get_layer_summary/SUCCESS',
  FAILURE:
    `${getLayerSummaryPrefix}FAILURE` as 'uf-api/internal/get_layer_summary/FAILURE',
  CLEAR:
    `${getLayerSummaryPrefix}CLEAR` as 'uf-api/internal/get_layer_summary/CLEAR',
} as const;

/**
 * LOAD action for internal.get_layer_summary
 * Return the layer summary.
 */
export interface getLayerSummaryLoadAction {
  readonly type: 'uf-api/internal/get_layer_summary/LOAD';
  readonly params: InternalServiceInterface.getLayerSummaryParams;
  readonly promise: Promise<ClientResponse<Array<LayerSummaryItem>>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for internal.get_layer_summary
 * Return the layer summary.
 */
export interface getLayerSummarySuccessAction {
  readonly type: 'uf-api/internal/get_layer_summary/SUCCESS';
  readonly result: Array<LayerSummaryItem>;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for internal.get_layer_summary
 * Return the layer summary.
 */
export interface getLayerSummaryFailureAction {
  readonly type: 'uf-api/internal/get_layer_summary/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for internal.get_layer_summary
 * Return the layer summary.
 */
export interface getLayerSummaryClearAction {
  readonly type: 'uf-api/internal/get_layer_summary/CLEAR';
  readonly key: string;
}

const getLayersPrefix = `uf-api/internal/get_layers/`;
/**
 * Action types for internal.get_layers
 *
 * Return a list of layers.
 */
export const getLayersActionTypes = {
  LOAD: `${getLayersPrefix}LOAD` as 'uf-api/internal/get_layers/LOAD',
  SUCCESS: `${getLayersPrefix}SUCCESS` as 'uf-api/internal/get_layers/SUCCESS',
  FAILURE: `${getLayersPrefix}FAILURE` as 'uf-api/internal/get_layers/FAILURE',
  CLEAR: `${getLayersPrefix}CLEAR` as 'uf-api/internal/get_layers/CLEAR',
} as const;

/**
 * LOAD action for internal.get_layers
 * Return a list of layers.
 */
export interface getLayersLoadAction {
  readonly type: 'uf-api/internal/get_layers/LOAD';
  readonly params: InternalServiceInterface.getLayersParams;
  readonly promise: Promise<ClientResponse<LayerReferenceList>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for internal.get_layers
 * Return a list of layers.
 */
export interface getLayersSuccessAction {
  readonly type: 'uf-api/internal/get_layers/SUCCESS';
  readonly result: LayerReferenceList;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for internal.get_layers
 * Return a list of layers.
 */
export interface getLayersFailureAction {
  readonly type: 'uf-api/internal/get_layers/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for internal.get_layers
 * Return a list of layers.
 */
export interface getLayersClearAction {
  readonly type: 'uf-api/internal/get_layers/CLEAR';
  readonly key: string;
}

const getPaintedCanvasActionsInternalPrefix = `uf-api/internal/get_painted_canvas_actions_internal/`;
/**
 * Action types for internal.get_painted_canvas_actions_internal
 *
 * Get actions for painted canvas
 */
export const getPaintedCanvasActionsInternalActionTypes = {
  LOAD: `${getPaintedCanvasActionsInternalPrefix}LOAD` as 'uf-api/internal/get_painted_canvas_actions_internal/LOAD',
  SUCCESS:
    `${getPaintedCanvasActionsInternalPrefix}SUCCESS` as 'uf-api/internal/get_painted_canvas_actions_internal/SUCCESS',
  FAILURE:
    `${getPaintedCanvasActionsInternalPrefix}FAILURE` as 'uf-api/internal/get_painted_canvas_actions_internal/FAILURE',
  CLEAR:
    `${getPaintedCanvasActionsInternalPrefix}CLEAR` as 'uf-api/internal/get_painted_canvas_actions_internal/CLEAR',
} as const;

/**
 * LOAD action for internal.get_painted_canvas_actions_internal
 * Get actions for painted canvas
 */
export interface getPaintedCanvasActionsInternalLoadAction {
  readonly type: 'uf-api/internal/get_painted_canvas_actions_internal/LOAD';
  readonly params: InternalServiceInterface.getPaintedCanvasActionsInternalParams;
  readonly promise: Promise<ClientResponse<Array<PaintingActionInfo>>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for internal.get_painted_canvas_actions_internal
 * Get actions for painted canvas
 */
export interface getPaintedCanvasActionsInternalSuccessAction {
  readonly type: 'uf-api/internal/get_painted_canvas_actions_internal/SUCCESS';
  readonly result: Array<PaintingActionInfo>;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for internal.get_painted_canvas_actions_internal
 * Get actions for painted canvas
 */
export interface getPaintedCanvasActionsInternalFailureAction {
  readonly type: 'uf-api/internal/get_painted_canvas_actions_internal/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for internal.get_painted_canvas_actions_internal
 * Get actions for painted canvas
 */
export interface getPaintedCanvasActionsInternalClearAction {
  readonly type: 'uf-api/internal/get_painted_canvas_actions_internal/CLEAR';
  readonly key: string;
}

const getRunningTasksPrefix = `uf-api/internal/get_running_tasks/`;
/**
 * Action types for internal.get_running_tasks
 *
 * Get the running tasks for a project.
 */
export const getRunningTasksActionTypes = {
  LOAD: `${getRunningTasksPrefix}LOAD` as 'uf-api/internal/get_running_tasks/LOAD',
  SUCCESS:
    `${getRunningTasksPrefix}SUCCESS` as 'uf-api/internal/get_running_tasks/SUCCESS',
  FAILURE:
    `${getRunningTasksPrefix}FAILURE` as 'uf-api/internal/get_running_tasks/FAILURE',
  CLEAR:
    `${getRunningTasksPrefix}CLEAR` as 'uf-api/internal/get_running_tasks/CLEAR',
} as const;

/**
 * LOAD action for internal.get_running_tasks
 * Get the running tasks for a project.
 */
export interface getRunningTasksLoadAction {
  readonly type: 'uf-api/internal/get_running_tasks/LOAD';

  readonly promise: Promise<ClientResponse<Array<Task>>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for internal.get_running_tasks
 * Get the running tasks for a project.
 */
export interface getRunningTasksSuccessAction {
  readonly type: 'uf-api/internal/get_running_tasks/SUCCESS';
  readonly result: Array<Task>;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for internal.get_running_tasks
 * Get the running tasks for a project.
 */
export interface getRunningTasksFailureAction {
  readonly type: 'uf-api/internal/get_running_tasks/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for internal.get_running_tasks
 * Get the running tasks for a project.
 */
export interface getRunningTasksClearAction {
  readonly type: 'uf-api/internal/get_running_tasks/CLEAR';
  readonly key: string;
}

const getUserDevicesPrefix = `uf-api/internal/get_user_devices/`;
/**
 * Action types for internal.get_user_devices
 *
 * Get user devices.
 */
export const getUserDevicesActionTypes = {
  LOAD: `${getUserDevicesPrefix}LOAD` as 'uf-api/internal/get_user_devices/LOAD',
  SUCCESS:
    `${getUserDevicesPrefix}SUCCESS` as 'uf-api/internal/get_user_devices/SUCCESS',
  FAILURE:
    `${getUserDevicesPrefix}FAILURE` as 'uf-api/internal/get_user_devices/FAILURE',
  CLEAR:
    `${getUserDevicesPrefix}CLEAR` as 'uf-api/internal/get_user_devices/CLEAR',
} as const;

/**
 * LOAD action for internal.get_user_devices
 * Get user devices.
 */
export interface getUserDevicesLoadAction {
  readonly type: 'uf-api/internal/get_user_devices/LOAD';

  readonly promise: Promise<ClientResponse<Array<UserDevice>>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for internal.get_user_devices
 * Get user devices.
 */
export interface getUserDevicesSuccessAction {
  readonly type: 'uf-api/internal/get_user_devices/SUCCESS';
  readonly result: Array<UserDevice>;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for internal.get_user_devices
 * Get user devices.
 */
export interface getUserDevicesFailureAction {
  readonly type: 'uf-api/internal/get_user_devices/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for internal.get_user_devices
 * Get user devices.
 */
export interface getUserDevicesClearAction {
  readonly type: 'uf-api/internal/get_user_devices/CLEAR';
  readonly key: string;
}

const getUsersPrefix = `uf-api/internal/get_users/`;
/**
 * Action types for internal.get_users
 *
 */
export const getUsersActionTypes = {
  LOAD: `${getUsersPrefix}LOAD` as 'uf-api/internal/get_users/LOAD',
  SUCCESS: `${getUsersPrefix}SUCCESS` as 'uf-api/internal/get_users/SUCCESS',
  FAILURE: `${getUsersPrefix}FAILURE` as 'uf-api/internal/get_users/FAILURE',
  CLEAR: `${getUsersPrefix}CLEAR` as 'uf-api/internal/get_users/CLEAR',
} as const;

/**
 * LOAD action for internal.get_users
 */
export interface getUsersLoadAction {
  readonly type: 'uf-api/internal/get_users/LOAD';

  readonly promise: Promise<ClientResponse<Array<InternalUserInfo>>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for internal.get_users
 */
export interface getUsersSuccessAction {
  readonly type: 'uf-api/internal/get_users/SUCCESS';
  readonly result: Array<InternalUserInfo>;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for internal.get_users
 */
export interface getUsersFailureAction {
  readonly type: 'uf-api/internal/get_users/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for internal.get_users
 */
export interface getUsersClearAction {
  readonly type: 'uf-api/internal/get_users/CLEAR';
  readonly key: string;
}

const pingPrefix = `uf-api/internal/ping/`;
/**
 * Action types for internal.ping
 *
 */
export const pingActionTypes = {
  LOAD: `${pingPrefix}LOAD` as 'uf-api/internal/ping/LOAD',
  SUCCESS: `${pingPrefix}SUCCESS` as 'uf-api/internal/ping/SUCCESS',
  FAILURE: `${pingPrefix}FAILURE` as 'uf-api/internal/ping/FAILURE',
  CLEAR: `${pingPrefix}CLEAR` as 'uf-api/internal/ping/CLEAR',
} as const;

/**
 * LOAD action for internal.ping
 */
export interface pingLoadAction {
  readonly type: 'uf-api/internal/ping/LOAD';
  readonly params: InternalServiceInterface.pingParams;
  readonly promise: Promise<ClientResponse<{}>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for internal.ping
 */
export interface pingSuccessAction {
  readonly type: 'uf-api/internal/ping/SUCCESS';
  readonly result: {};
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for internal.ping
 */
export interface pingFailureAction {
  readonly type: 'uf-api/internal/ping/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for internal.ping
 */
export interface pingClearAction {
  readonly type: 'uf-api/internal/ping/CLEAR';
  readonly key: string;
}

export const internalActionTypesMap = {
  /** Action types for internal.bake_library */
  bake_library: bakeLibraryActionTypes,
  /** Action types for internal.clear_flask_cache */
  clear_flask_cache: clearFlaskCacheActionTypes,
  /** Action types for internal.clear_subscription_cache */
  clear_subscription_cache: clearSubscriptionCacheActionTypes,
  /** Action types for internal.create_demo_project */
  create_demo_project: createDemoProjectActionTypes,
  /** Action types for internal.export_user_devices */
  export_user_devices: exportUserDevicesActionTypes,
  /** Action types for internal.get_available_feature_flags */
  get_available_feature_flags: getAvailableFeatureFlagsActionTypes,
  /** Action types for internal.get_dynamic_layer_internal */
  get_dynamic_layer_internal: getDynamicLayerInternalActionTypes,
  /** Action types for internal.get_entity_info */
  get_entity_info: getEntityInfoActionTypes,
  /** Action types for internal.get_internal_layer_bounds */
  get_internal_layer_bounds: getInternalLayerBoundsActionTypes,
  /** Action types for internal.get_internal_layer_geo_keys */
  get_internal_layer_geo_keys: getInternalLayerGeoKeysActionTypes,
  /** Action types for internal.get_internal_layer_stats */
  get_internal_layer_stats: getInternalLayerStatsActionTypes,
  /** Action types for internal.get_layer_metadata */
  get_layer_metadata: getLayerMetadataActionTypes,
  /** Action types for internal.get_layer_registry */
  get_layer_registry: getLayerRegistryActionTypes,
  /** Action types for internal.get_layer_summary */
  get_layer_summary: getLayerSummaryActionTypes,
  /** Action types for internal.get_layers */
  get_layers: getLayersActionTypes,
  /** Action types for internal.get_painted_canvas_actions_internal */
  get_painted_canvas_actions_internal:
    getPaintedCanvasActionsInternalActionTypes,
  /** Action types for internal.get_running_tasks */
  get_running_tasks: getRunningTasksActionTypes,
  /** Action types for internal.get_user_devices */
  get_user_devices: getUserDevicesActionTypes,
  /** Action types for internal.get_users */
  get_users: getUsersActionTypes,
  /** Action types for internal.ping */
  ping: pingActionTypes,
} as const;

export const internalDetailsMap = {
  bake_library: {
    /** HTTP method for internal.bake_library */
    httpMethod: 'post' as const,
  },
  clear_flask_cache: {
    /** HTTP method for internal.clear_flask_cache */
    httpMethod: 'post' as const,
  },
  clear_subscription_cache: {
    /** HTTP method for internal.clear_subscription_cache */
    httpMethod: 'post' as const,
  },
  create_demo_project: {
    /** HTTP method for internal.create_demo_project */
    httpMethod: 'post' as const,
  },
  export_user_devices: {
    /** HTTP method for internal.export_user_devices */
    httpMethod: 'get' as const,
  },
  get_available_feature_flags: {
    /** HTTP method for internal.get_available_feature_flags */
    httpMethod: 'get' as const,
  },
  get_dynamic_layer_internal: {
    /** HTTP method for internal.get_dynamic_layer_internal */
    httpMethod: 'get' as const,
  },
  get_entity_info: {
    /** HTTP method for internal.get_entity_info */
    httpMethod: 'get' as const,
  },
  get_internal_layer_bounds: {
    /** HTTP method for internal.get_internal_layer_bounds */
    httpMethod: 'get' as const,
  },
  get_internal_layer_geo_keys: {
    /** HTTP method for internal.get_internal_layer_geo_keys */
    httpMethod: 'get' as const,
  },
  get_internal_layer_stats: {
    /** HTTP method for internal.get_internal_layer_stats */
    httpMethod: 'get' as const,
  },
  get_layer_metadata: {
    /** HTTP method for internal.get_layer_metadata */
    httpMethod: 'get' as const,
  },
  get_layer_registry: {
    /** HTTP method for internal.get_layer_registry */
    httpMethod: 'get' as const,
  },
  get_layer_summary: {
    /** HTTP method for internal.get_layer_summary */
    httpMethod: 'get' as const,
  },
  get_layers: {
    /** HTTP method for internal.get_layers */
    httpMethod: 'get' as const,
  },
  get_painted_canvas_actions_internal: {
    /** HTTP method for internal.get_painted_canvas_actions_internal */
    httpMethod: 'get' as const,
  },
  get_running_tasks: {
    /** HTTP method for internal.get_running_tasks */
    httpMethod: 'get' as const,
  },
  get_user_devices: {
    /** HTTP method for internal.get_user_devices */
    httpMethod: 'get' as const,
  },
  get_users: {
    /** HTTP method for internal.get_users */
    httpMethod: 'get' as const,
  },
  ping: {
    /** HTTP method for internal.ping */
    httpMethod: 'get' as const,
  },
} as const;

/**
 * Local DataState<T> - kept in sync with uf/data/dataState.ts
 */
interface DataState<T> {
  readonly loading: boolean;
  readonly loaded: boolean;
  readonly requestId: string;
  readonly requestTime?: string;
  readonly responseTime?: string;
  readonly requestCount?: number;
  readonly error?: any;
  readonly promise?: Promise<T>;
  readonly data?: T;
  readonly extra?: any;
  readonly key?: string;
}
export interface InternalServiceState {
  /** all recent bakeLibrary results (POST/PUT/PATCH) */
  readonly bake_library: DataState<{}>[];
  /** all recent clearFlaskCache results (POST/PUT/PATCH) */
  readonly clear_flask_cache: DataState<boolean>[];
  /** all recent clearSubscriptionCache results (POST/PUT/PATCH) */
  readonly clear_subscription_cache: DataState<{}>[];
  /** all recent createDemoProject results (POST/PUT/PATCH) */
  readonly create_demo_project: DataState<ProjectReference>[];
  /** exportUserDevices state, by key */
  export_user_devices: {
    readonly [key: string]: DataState<Blob>;
  };
  /** getAvailableFeatureFlags state, by key */
  get_available_feature_flags: {
    readonly [key: string]: DataState<Array<string>>;
  };
  /** getDynamicLayerInternal state, by key */
  get_dynamic_layer_internal: {
    readonly [key: string]: DataState<DynamicLayerMetadata>;
  };
  /** getEntityInfo state, by key */
  get_entity_info: {
    readonly [key: string]: DataState<EntityExplorerEntity>;
  };
  /** getInternalLayerBounds state, by key */
  get_internal_layer_bounds: {
    readonly [key: string]: DataState<LayerBounds>;
  };
  /** getInternalLayerGeoKeys state, by key */
  get_internal_layer_geo_keys: {
    readonly [key: string]: DataState<LayerGeoData>;
  };
  /** getInternalLayerStats state, by key */
  get_internal_layer_stats: {
    readonly [key: string]: DataState<LayerStats>;
  };
  /** getLayerMetadata state, by key */
  get_layer_metadata: {
    readonly [key: string]: DataState<LayerMetadata>;
  };
  /** getLayerRegistry state, by key */
  get_layer_registry: {
    readonly [key: string]: DataState<Array<LayerRegistryItem>>;
  };
  /** getLayerSummary state, by key */
  get_layer_summary: {
    readonly [key: string]: DataState<Array<LayerSummaryItem>>;
  };
  /** getLayers state, by key */
  get_layers: {
    readonly [key: string]: DataState<LayerReferenceList>;
  };
  /** getPaintedCanvasActionsInternal state, by key */
  get_painted_canvas_actions_internal: {
    readonly [key: string]: DataState<Array<PaintingActionInfo>>;
  };
  /** getRunningTasks state, by key */
  get_running_tasks: {
    readonly [key: string]: DataState<Array<Task>>;
  };
  /** getUserDevices state, by key */
  get_user_devices: {
    readonly [key: string]: DataState<Array<UserDevice>>;
  };
  /** getUsers state, by key */
  get_users: {
    readonly [key: string]: DataState<Array<InternalUserInfo>>;
  };
  /** ping state, by key */
  ping: {
    readonly [key: string]: DataState<{}>;
  };
}
