/* eslint-disable @typescript-eslint/naming-convention */
import assert from 'assert';
import { Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import {
  addSymbologyActionTypes,
  deleteSymbologyActionTypes,
  getSymbologyActionTypes,
} from 'uf-api/api/project.service';
import { ProjectServiceInterface } from 'uf-api/api/project.serviceInterface';
import { parseFullPath } from 'uf/base/dataset';
import { makeAsyncApiCaller } from 'uf/base/xhr';
import { isBuiltFormKey } from 'uf/builtforms';
import { getData } from 'uf/data/dataState';
import { makeRequestId } from 'uf/data/helpers';
import { dispatchAsyncAction, makeEnsureActionCreator } from 'uf/data/loader';
import { ColumnKey, LayerId } from 'uf/layers';
import { ensureLayerMetadata } from 'uf/layers/actions/metadata';
import { ensureLayerStats } from 'uf/layers/actions/stats';
import { DEFAULT_GEO_COLUMN_KEY, getColumnKey } from 'uf/layers/geometryKey';
import { makeGetLayerMetadata } from 'uf/layers/selectors/metadata';
import { makeGetLayerVersion } from 'uf/layers/selectors/versions';
import { ProjectId } from 'uf/projects';
import { makeGetProjectLayerIdMap } from 'uf/projects/selectors/virtualLayers';
import {
  LayerColumnSymbology,
  LayerSymbologyEnvelope,
  SYMBOLOGY_FORMAT,
} from 'uf/symbology';
import { ensureBuiltFormStops } from 'uf/symbology/actions/builtforms';
import {
  ensureDivideByColumn,
  loadDivideByColumn,
  loadDivideByColumns,
  setDivideByColumn,
} from 'uf/symbology/actions/divideByColumn';
import {
  clearUserSymbologyActionTypes,
  ClearUserSymbologySuccessAction,
  saveUserSymbologyActionTypes,
  SaveUserSymbologyLoadAction,
  SaveUserSymbologySuccessAction,
} from 'uf/symbology/ActionTypes';
import { DivideByColumnKey } from 'uf/symbology/divideByColumn';
import { makeGetDivideByColumn } from 'uf/symbology/selectors/divideByColumn';
import {
  makeGetUserDataSymbologySavingState,
  makeGetUserDataSymbologyStateGetter,
} from 'uf/symbology/selectors/user';
import { getStatsParams } from 'uf/symbology/stats';
import { makeSymbologyUserDataKey } from 'uf/symbology/user';
import { ViewId } from 'uf/views';
import warning from 'warning';

/**
 * Ensures the user symbology and it's cleaning resources.
 *
 * Note: We can't reuse `loadUserSymbology` here, because user symbology is a
 * computed property. This means we need to call the "ensure" versions of each
 * of the sub-actions, and make sure we keep these two functions in sync as we
 * add additional sub-actions.
 */

export function ensureUserSymbology(
  projectId: ProjectId,
  viewId: ViewId,
  layerId: LayerId,
  columnKey: ColumnKey,
): ThunkAction<any, any, any, any> {
  const getDivideByColumn = makeGetDivideByColumn();
  const getLayerVersion = makeGetLayerVersion();
  return async (dispatch, getState) => {
    // eslint-disable-next-line @typescript-eslint/await-thenable
    await dispatch(ensureDivideByColumn(projectId, viewId, layerId, columnKey));
    const divideByColumn = getDivideByColumn(getState(), {
      projectId,
      layerId,
      columnKey,
    });
    const version = getLayerVersion(getState(), { layerId });
    const statsParams = getStatsParams(
      getColumnKey(columnKey),
      version,
      divideByColumn,
    );

    const actions = [
      ensureUserDataSymbologyEnvelope(
        projectId,
        viewId,
        layerId,
        columnKey,
        divideByColumn,
      ),
      ensureLayerMetadata(layerId),
      ensureLayerStats(layerId, statsParams),
    ];

    if (isBuiltFormKey(columnKey)) {
      actions.push(ensureBuiltFormStops(projectId, layerId));
    }

    return Promise.all(actions.map(action => dispatch(action)));
  };
}

/**
 * Hard loads the user symbology.
 */
export function loadUserSymbology(
  projectId: ProjectId,
  viewId: ViewId,
  layerId: LayerId,
  columnKey: ColumnKey,
): ThunkAction<any, any, any, any> {
  const getDivideByColumn = makeGetDivideByColumn();
  const getLayerVersion = makeGetLayerVersion();

  return async (dispatch, getState) => {
    // eslint-disable-next-line @typescript-eslint/await-thenable
    await dispatch(loadDivideByColumn(projectId, viewId, layerId, columnKey));
    const divideByColumn = getDivideByColumn(getState(), {
      projectId,
      layerId,
      columnKey,
    });
    const version = getLayerVersion(getState(), { layerId });
    const statsParams = getStatsParams(
      getColumnKey(columnKey),
      version,
      divideByColumn,
    );

    const actions = [
      // Hard reload the user symbology envelope, as that is the intention of
      // this action creator
      loadUserDataSymbologyEnvelope(
        projectId,
        viewId,
        layerId,
        columnKey,
        divideByColumn,
      ),

      // The rest of the resources only need to be ensured, because the data
      // would only change from a layer version bump.
      ensureLayerMetadata(layerId),
      ensureLayerStats(layerId, statsParams),
    ];

    if (isBuiltFormKey(columnKey)) {
      actions.push(ensureBuiltFormStops(projectId, layerId));
    }
    return Promise.all(actions.map(action => dispatch(action)));
  };
}

export const clearUserSymbologyAPI = makeAsyncApiCaller<
  ProjectServiceInterface.deleteSymbologyParams,
  any
>(apis => apis.project.delete_symbology);

/**
 * clears user symbology
 */
export function clearUserSymbology(
  projectId: ProjectId,
  viewId: ViewId,
  layerId: LayerId,
  columnKey: ColumnKey,
  divideByColumn: DivideByColumnKey,
): ThunkAction<Promise<{}>, any, any, any> {
  const getVirtualLayerId = makeGetProjectLayerIdMap();
  return async (dispatch: Dispatch, getState) => {
    const columnSymbologyId = makeSymbologyUserDataKey({
      layerId,
      columnKey,
      divideByColumn,
      viewId,
    });
    const { namespace: project_namespace, key: project_key } =
      parseFullPath(projectId);
    const {
      namespace: layer_namespace,
      type: layer_type,
      key: layer_key,
    } = parseFullPath(layerId);
    const virtualLayerId = getVirtualLayerId(getState(), { projectId })[
      layerId
    ];

    const result = await dispatch(
      dispatchAsyncAction(
        deleteSymbologyActionTypes,
        clearUserSymbologyAPI({
          project_namespace,
          project_key,
          layer_namespace,
          layer_key,
          layer_type,
          view_key: viewId,
          column_key: columnKey,
          divide_by_key: divideByColumn || undefined,
        }),
        { projectId, layerId, columnKey, key: columnSymbologyId },
      ),
    );
    const higherAction: ClearUserSymbologySuccessAction = {
      type: clearUserSymbologyActionTypes.SUCCESS,
      projectId,
      layerId,
      virtualLayerId,
      columnKey,
      viewId,
    };
    dispatch(higherAction);
    return result;
  };
}

interface addSymbologyParams
  extends ProjectServiceInterface.addSymbologyParams {
  readonly symbology: LayerSymbologyEnvelope;
}

export const saveUserSymbologyAPI = makeAsyncApiCaller<addSymbologyParams, any>(
  apis => apis.project.add_symbology,
);

function makeLayerSymbologyEnvelope(
  symbology: LayerColumnSymbology[],
): LayerSymbologyEnvelope {
  return {
    type: 'symbology',
    format: SYMBOLOGY_FORMAT,
    symbology,
    originator: 'user',
  };
}

/**
 * Emit API call to save user symbology
 */
export function saveUserSymbology(
  projectId: ProjectId,
  viewId: ViewId,
  layerId: LayerId,
  columnKey: ColumnKey,
  divideByColumn: DivideByColumnKey,
  symbology: LayerColumnSymbology[],
): ThunkAction<Promise<any>, any, any, any> {
  const getVirtualLayerId = makeGetProjectLayerIdMap();
  assert(viewId, 'view id is required');
  return async (dispatch: Dispatch, getState) => {
    const symbologyEnvelope = makeLayerSymbologyEnvelope(symbology);

    const virtualLayerId = getVirtualLayerId(getState(), { projectId })[
      layerId
    ];

    const { namespace: project_namespace, key: project_key } =
      parseFullPath(projectId);
    const {
      namespace: layer_namespace,
      type: layer_type,
      key: layer_key,
    } = parseFullPath(layerId);
    // fire off a "higher action" for epics to safely & easily operate off on
    // partly a hold-over from having MANY save actions,
    // partly because we don't know how to make our custom API system type safe with *extra* params
    const requestId = makeRequestId();
    const higherLoadAction: SaveUserSymbologyLoadAction = {
      type: saveUserSymbologyActionTypes.LOAD,
      projectId,
      viewId,
      layerId,
      virtualLayerId,
      columnKey,
      divideByColumn,
      data: null as any,
      requestId,
    };
    dispatch(higherLoadAction);
    // fire off API action
    const result = await (dispatch(
      dispatchAsyncAction(
        addSymbologyActionTypes,
        saveUserSymbologyAPI({
          project_namespace,
          project_key,
          layer_namespace,
          layer_key,
          layer_type,
          view_key: viewId,
          column_key: columnKey,
          divide_by_key: divideByColumn || undefined,
          symbology: symbologyEnvelope,
        }),
        {
          // pass the projectId and viewId so that epics can do stuff too
          projectId,
          viewId,
          layerId,
          virtualLayerId,
          columnKey,
          divideByColumn,
          key: makeSymbologyUserDataKey({
            layerId,
            columnKey,
            divideByColumn,
            viewId,
          }),
          data: symbologyEnvelope,
        },
      ),
    ) as any as Promise<any>);
    // close the loop on the "higher" action
    const higherSuccessAction: SaveUserSymbologySuccessAction = {
      type: saveUserSymbologyActionTypes.SUCCESS,
      projectId,
      viewId,
      layerId,
      virtualLayerId,
      columnKey,
      divideByColumn,
      // TODO always send result data instead
      data: result || symbologyEnvelope,
      requestId,
    };
    dispatch(higherSuccessAction);
    return result;
  };
}

/**
 * Action creator for copying a user's symbology from one layer to another.
 * This will clone both the regular and density styles for every column and
 * preserve the density toggle pref.
 *
 * NOTE: Any previously saved user symbology will be overwritten, so this is
 * generally safe to use when copying styles to a newly created layer, but it
 * could also be used to "sync" styles at any time between layers.
 */
export function copyUserSymbology(
  projectId: ProjectId,
  viewId: ViewId,
  fromLayerId: string,
  toLayerId: string,
): ThunkAction<Promise<unknown>, any, any, any> {
  const getLayerMetadata = makeGetLayerMetadata();
  const getDivideByColumn = makeGetDivideByColumn();
  return async (dispatch: Dispatch, getState) => {
    try {
      const copyUserSymbologyDeps: Promise<any>[] = [
        dispatch(loadDivideByColumns(projectId, fromLayerId)),
        dispatch(ensureLayerMetadata(fromLayerId)),
      ];
      await Promise.all(copyUserSymbologyDeps);
    } catch (error) {
      warning(
        `Error copying symbology, could not load dependencies for source layer ${fromLayerId}`,
      );
      throw error;
    }

    const fromLayerMetadata = getLayerMetadata(getState(), {
      layerId: fromLayerId,
    });
    const { columns = [] } = fromLayerMetadata;
    const mappableColumnKeys = columns.map(({ key: columnKey }) => columnKey);
    mappableColumnKeys.push(DEFAULT_GEO_COLUMN_KEY);

    return Promise.all(
      mappableColumnKeys.map(async columnKey => {
        const divideByColumn = getDivideByColumn(getState(), {
          projectId,
          layerId: fromLayerId,
          columnKey,
        });
        assert(divideByColumn !== undefined);

        // We always copy the regular styling if it exists
        await dispatch(
          ensureUserDataSymbologyEnvelope(
            projectId,
            viewId,
            fromLayerId,
            columnKey,
            divideByColumn,
          ),
        );

        await dispatch(
          copySymbologyUserData(
            projectId,
            viewId,
            fromLayerId,
            toLayerId,
            columnKey,
          ),
        );

        // This only copies the symbology for the current divideByColumn, if there
        // is one. We do not have an effective way right now to copy all
        // divideByColumn symbology, or any if it isn't currently set.
        if (divideByColumn) {
          await dispatch(
            ensureUserDataSymbologyEnvelope(
              projectId,
              viewId,
              fromLayerId,
              columnKey,
              divideByColumn,
            ),
          );

          await dispatch(
            setDivideByColumn(
              projectId,
              viewId,
              toLayerId,
              columnKey,
              divideByColumn,
            ),
          );
          await dispatch(
            copySymbologyUserData(
              projectId,
              viewId,
              fromLayerId,
              toLayerId,
              columnKey,
              divideByColumn,
            ),
          );
        }
      }),
    );
  };
}

/**
 * Copies a single column symbology from one layer to another.
 */
function copySymbologyUserData(
  projectId: ProjectId,
  viewId: ViewId,
  fromLayerId: string,
  toLayerId: string,
  columnKey: ColumnKey,
  divideByColumn?: DivideByColumnKey,
) {
  const getSymbologyData = makeGetUserDataSymbologyStateGetter();
  return (dispatch: Dispatch, getState) => {
    const symbologyData = getSymbologyData(getState())({
      projectId,
      viewId,
      layerId: fromLayerId,
      columnKey,
    });
    const symbology = getData(symbologyData, null);
    if (!symbology) {
      return null;
    }
    return dispatch(
      saveUserSymbology(
        projectId,
        viewId,
        toLayerId,
        columnKey,
        divideByColumn,
        symbology.symbology,
      ),
    );
  };
}

/**
 * Hits the project.get_symbology endpoint for the saved symbology.
 */
const ensureUserDataSymbologyEnvelope = makeEnsureActionCreator(
  loadUserDataSymbologyEnvelope,
  makeGetUserDataSymbologySavingState(),
  (projectId, viewId, layerId, columnKey, divideByColumn) => ({
    projectId,
    viewId,
    layerId,
    columnKey,
    divideByColumn,
  }),
);

const loadUserSymbologyAPI = makeAsyncApiCaller<
  ProjectServiceInterface.getSymbologyParams,
  LayerSymbologyEnvelope
>(apis => apis.project.get_symbology as any, { 404: () => null });

function loadUserDataSymbologyEnvelope(
  projectId: ProjectId,
  viewId: ViewId,
  layerId: LayerId,
  columnKey: ColumnKey,
  divideByColumn: DivideByColumnKey,
) {
  const { namespace: project_namespace, key: project_key } =
    parseFullPath(projectId);
  const {
    namespace: layer_namespace,
    type: layer_type,
    key: layer_key,
  } = parseFullPath(layerId);
  return dispatchAsyncAction(
    getSymbologyActionTypes,
    loadUserSymbologyAPI({
      layer_namespace,
      layer_key,
      layer_type,
      column_key: columnKey,
      project_namespace,
      project_key,
      divide_by_key: divideByColumn || undefined,
      view_key: viewId,
    }),
    {
      key: makeSymbologyUserDataKey({
        layerId,
        columnKey,
        divideByColumn,
        viewId,
      }),
    },
  );
}
