import {
  Button,
  Classes,
  Dialog,
  FormGroup,
  InputGroup,
  Intent,
  Label,
} from '@blueprintjs/core';
import { boundMethod } from 'autobind-decorator';
import classNames from 'classnames';
import React, { Component } from 'react';
import { t } from 'ttag';

import rootStyles from 'uf/styles/root.module.css';
import { dialogClasses, Sizes } from 'uf/ui/base/sizes';

interface OwnProps {
  name: string;
  description: string;
  onExited: () => void;
  onEditScenario: (name: string, description: string) => void;
}

interface State {
  showModal: boolean;
  form: { name: string; description: string };
  nameIsValid: boolean;
}

export default class ScenarioEditModal extends Component<OwnProps, State> {
  static defaultProps = {
    onExited: () => {},
    onEditScenario: () => {},
  };
  constructor(props) {
    super(props);
    const { name, description } = this.props;
    this.state = {
      showModal: true,
      form: { name, description },
      nameIsValid: validateName(name),
    };
  }

  // This callback fires after animation is complete.
  // parent should use this to stop rendering the modal.
  @boundMethod
  onExited() {
    this.props.onExited();
  }
  @boundMethod
  onSave() {
    const { onEditScenario } = this.props;
    const { name, description } = this.state.form;
    // TODO: add form validation steps here
    this.setState({ showModal: false });
    onEditScenario(name, description);
  }
  // This is used to handle the animation of modal.
  @boundMethod
  close() {
    this.setState({ showModal: false });
  }
  @boundMethod
  handleChange(event) {
    const { name, value } = event.target;
    let newNameIsValid = this.state.nameIsValid;
    if (name === 'name') {
      newNameIsValid = validateName(value);
    }
    this.setState(prevState => ({
      form: {
        ...prevState.form,
        [name]: value,
      },
      nameIsValid: newNameIsValid,
    }));
  }
  render() {
    const { nameIsValid } = this.state;
    const { name, description } = this.state.form;
    const nameIntent = nameIsValid ? null : Intent.DANGER;
    return (
      <Dialog
        transitionDuration={__TESTING__ ? 0 : undefined}
        className={classNames('uf-modal', dialogClasses[Sizes.SMALL])}
        isOpen={this.state.showModal}
        onClose={this.close}
        onClosed={this.onExited}>
        <div className={Classes.DIALOG_BODY}>
          <form>
            <FormGroup
              intent={nameIntent}
              helperText={!nameIsValid && t`Name cannot be blank`}>
              <Label>{t`Name`}</Label>
              <InputGroup
                intent={nameIntent}
                type="text"
                name="name"
                className={'EditScenario-name'}
                onChange={this.handleChange}
                value={name}
              />
            </FormGroup>
            <FormGroup>
              <Label>{t`Description`}</Label>
              <InputGroup
                type="text"
                name="description"
                className={'EditScenario-description'}
                onChange={this.handleChange}
                value={description}
              />
            </FormGroup>
          </form>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button
              className={classNames(
                rootStyles.veryThinMarginHorizontal,
                'Cancel',
              )}
              onClick={this.close}>
              {t`Cancel`}
            </Button>
            <Button
              intent={Intent.PRIMARY}
              className={classNames(
                rootStyles.veryThinMarginHorizontal,
                'EditScenario-save',
              )}
              disabled={!nameIsValid}
              onClick={this.onSave}>
              {t`Save`}
            </Button>
          </div>
        </div>
      </Dialog>
    );
  }
}

// name cannot be zero length
function validateName(name) {
  return !!name.length;
}
