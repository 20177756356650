import { AnyAction } from 'redux';
import { ColumnKey, LayerId } from 'uf/layers';
import { AnyCategoryValue } from 'uf/layers/statsFeatures';
import { ProjectId } from 'uf/projects';
import { LegacyVirtualLayerId } from 'uf/projects/virtualLayers';
import {
  ColumnSymbology,
  DistributionTypes,
  ExtrusionStyleOptions,
  LayerColumnSymbology,
  NoExtrusionStyleOption,
  Theme,
} from 'uf/symbology';
import {
  SetDefaultSymbologyAction,
  SetOverrideSymbologyAction,
  SET_DEFAULT_SYMBOLOGY,
  SET_MAP_EXTRUSION,
  SET_OVERRIDE_SYMBOLOGY,
  SET_SHOW_ZERO,
  UPDATE_CUSTOM_STOPS,
  UPDATE_OPACITY,
  UPDATE_POINT_SIZE,
  UPDATE_RAMP_THEME,
  UPDATE_STOPS,
  UPDATE_STOP_COLOR,
  UPDATE_STROKE_COLOR,
  UPDATE_STROKE_WIDTH,
  UPDATE_THEME_REVERSED,
} from 'uf/symbology/ActionTypes';
import { Bin } from 'uf/symbology/bins';
import { DivideByColumnKey } from 'uf/symbology/divideByColumn';
import { makeSymbologyUserDataKey } from 'uf/symbology/user';
import { ViewId } from 'uf/views';

export function setOverrideSymbology(
  projectId: ProjectId,
  viewId: ViewId,
  layerId: LayerId,
  virtualLayerId: LegacyVirtualLayerId,
  columnKey: ColumnKey,
  symbology: LayerColumnSymbology[],
  divideByColumn: DivideByColumnKey,
): SetOverrideSymbologyAction {
  return {
    type: SET_OVERRIDE_SYMBOLOGY,
    projectId,
    viewId,
    layerId,
    virtualLayerId,
    columnKey,
    symbology,
    symbologyKey: makeSymbologyUserDataKey({
      layerId,
      columnKey,
      divideByColumn,
      viewId,
    }),
    divideByColumn,
  };
}

export function clearOverrideSymbology(
  projectId: ProjectId,
  viewId: ViewId,
  layerId: LayerId | undefined,
  virtualLayerId: LegacyVirtualLayerId,
  columnKey: ColumnKey,
  divideByColumn: DivideByColumnKey,
): SetOverrideSymbologyAction {
  return {
    type: SET_OVERRIDE_SYMBOLOGY,
    projectId,
    viewId,
    layerId,
    virtualLayerId,
    columnKey,
    divideByColumn: divideByColumn || false,
    symbology: null,
    symbologyKey: makeSymbologyUserDataKey({
      layerId,
      columnKey,
      divideByColumn,
      viewId,
    }),
  };
}

export function setDefaultSymbology(
  viewId: ViewId,
  layerId: LayerId,
  virtualLayerId: LegacyVirtualLayerId,
  columnKey: ColumnKey,
  symbology: ColumnSymbology[],
): SetDefaultSymbologyAction {
  return {
    type: SET_DEFAULT_SYMBOLOGY,
    viewId,
    layerId,
    virtualLayerId,
    columnKey,
    symbology,
  };
}

export function updateColorRamp(
  layerId: LayerId,
  columnKey: ColumnKey,
  theme: Theme,
): AnyAction {
  return {
    type: UPDATE_RAMP_THEME,
    layerId,
    columnKey,
    theme,
  };
}

export function updateThemeReversed(
  layerId: LayerId,
  columnKey: ColumnKey,
  themeReversed: boolean,
): AnyAction {
  return {
    type: UPDATE_THEME_REVERSED,
    layerId,
    columnKey,
    themeReversed,
  };
}

export function updateOpacity(
  layerId: LayerId,
  columnKey: ColumnKey,
  opacity: number,
  autosave: boolean,
): AnyAction {
  return {
    type: UPDATE_OPACITY,
    layerId,
    columnKey,
    opacity,
    autosave,
  };
}

export function updateStrokeWidth(
  layerId: LayerId,
  columnKey: ColumnKey,
  strokeWidth: number,
): AnyAction {
  return {
    type: UPDATE_STROKE_WIDTH,
    layerId,
    columnKey,
    strokeWidth,
  };
}

export function updatePointSize(
  layerId: LayerId,
  columnKey: ColumnKey,
  size: number,
) {
  return {
    type: UPDATE_POINT_SIZE,
    layerId,
    columnKey,
    size,
  };
}

export function setShowZero(
  layerId: LayerId,
  columnKey: ColumnKey,
  showZero: boolean,
): AnyAction {
  return {
    type: SET_SHOW_ZERO,
    layerId,
    columnKey,
    showZero,
  };
}

export function setMapExtrusion(
  layerId: LayerId,
  columnKey: ColumnKey,
  extrusion: ExtrusionStyleOptions | NoExtrusionStyleOption,
): AnyAction {
  return {
    type: SET_MAP_EXTRUSION,
    layerId,
    columnKey,
    extrusion,
  };
}

export function updateStops(
  layerId: LayerId,
  columnKey: ColumnKey,
  numStops: number,
  distributionType: DistributionTypes,
): AnyAction {
  return {
    type: UPDATE_STOPS,
    layerId,
    columnKey,
    numStops,
    distributionType,
  };
}

export function updateCustomStops(
  layerId: LayerId,
  columnKey: ColumnKey,
  bins: Bin[],
): AnyAction {
  return {
    type: UPDATE_CUSTOM_STOPS,
    layerId,
    columnKey,
    bins,
  };
}

export function updateStrokeColor(
  layerId: LayerId,
  columnKey: ColumnKey,
  strokeColor: string,
): AnyAction {
  return {
    type: UPDATE_STROKE_COLOR,
    layerId,
    columnKey,
    strokeColor,
  };
}
export function updateStopColor(
  layerId: LayerId,
  columnKey: ColumnKey,
  value: AnyCategoryValue,
  color: string,
): AnyAction {
  return {
    type: UPDATE_STOP_COLOR,
    layerId,
    columnKey,
    value,
    color,
  };
}
