import { IToaster, IToastProps, Position, Toaster } from '@blueprintjs/core';
import { unmountComponentAtNode } from 'react-dom';

export interface TestToasterMock extends IToaster {
  container: HTMLElement;
  toaster: IToaster;

  create: () => void;
  destroy: () => void;
}

class TestToaster implements TestToasterMock {
  container: HTMLElement;
  toaster: IToaster;

  constructor() {
    this.create();
  }

  create() {
    this.container = document.createElement('div');
    this.toaster = Toaster.create(
      {
        className: 'notification-toaster',
        position: Position.BOTTOM,
      },
      this.container,
    );
  }

  destroy() {
    unmountComponentAtNode(this.container);
  }

  show(props: IToastProps, key: string) {
    return this.toaster.show(props, key);
  }

  dismiss(key: string) {
    this.toaster.dismiss(key);
  }

  clear() {
    this.toaster.clear();
  }

  getToasts() {
    return this.toaster.getToasts();
  }
}

let NotificationToaster: IToaster | TestToasterMock;

if (__CLIENT__) {
  NotificationToaster = Toaster.create({
    className: 'notification-toaster',
    position: Position.BOTTOM,
  });
} else if (__TESTING__) {
  NotificationToaster = new TestToaster();
} else if (__SERVER__) {
  // toaster attaches to document.body so we stub it out during SSR
  NotificationToaster = {
    show: () => '',
    dismiss: (key: string) => {},
    clear: () => {},
    getToasts: () => [],
  };
}

export default NotificationToaster;
