import {
  SET_SCENARIO_ORDER,
  SetScenarioOrderAction,
} from 'uf/explore/ActionTypes';
import { registerPersistedAction } from 'uf/persistence';
import { ProjectId } from 'uf/projects';

export function setScenarioOrder(
  projectId: ProjectId,
  order: string[],
): SetScenarioOrderAction {
  return {
    type: SET_SCENARIO_ORDER,
    property: projectId,
    value: order,
  };
}

registerPersistedAction<SetScenarioOrderAction, string, string[]>(
  'scenarioOrder',
  SET_SCENARIO_ORDER,
  (projectId: ProjectId, order: string[]) => setScenarioOrder(projectId, order),
  {
    getKey({ property: projectId }) {
      return projectId;
    },

    getValue({ value: order }) {
      return order;
    },
  },
);
