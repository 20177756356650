import _ from 'lodash';

import { LayerColumn, LayerMetadata, LayerReference } from 'uf-api';
import { EMPTY_OBJECT } from 'uf/base';
import { getUnitName, UnitLabelKeys } from 'uf/base/units';
import { ColumnKey } from 'uf/layers';

import { isGeometryKey } from './geometryKey';

export function getRootLayerId(layerMetadata: LayerMetadata): string {
  return layerMetadata?.display_hints?.root?.full_path;
}

// TODO: add column visibility as an attribute of the column metadata
export function shouldHideColumn(columnKey: LayerColumn) {
  return (
    isGeometryKey(columnKey.key) ||
    columnKey.key === 'uf_unique_id' ||
    columnKey.hidden
  );
}

// Consistent with the frontend API.
// TODO: Get this from swagger definition.
export enum Metatype {
  NUMERIC = 'numeric',
  CATEGORICAL = 'categorical',
}

// predicates for filtering

export function isNumericColumn(column: LayerColumn): boolean {
  return column ? column.metatype === LayerColumn.MetatypeEnum.Numeric : false;
}

export function isNumericColumnKey(
  columnKey: ColumnKey,
  layerMetadata: LayerMetadata,
) {
  const columnMetadata = getColumnMetadata(layerMetadata, columnKey);
  return isNumericColumn(columnMetadata);
}

export function isCategoricalColumn(column: LayerColumn): boolean {
  return column
    ? column.metatype === LayerColumn.MetatypeEnum.Categorical
    : false;
}

export function isColumnFilterable(column: LayerColumn): boolean {
  return isNumericColumn(column) || isCategoricalColumn(column);
}

export function isLayerFilterable(layerMetaData: LayerMetadata): boolean {
  return layerMetaData.columns
    ? layerMetaData.columns.some(isColumnFilterable)
    : false;
}

export function getColumnMetadata(
  layerMetadata: LayerMetadata,
  columnKey: ColumnKey,
): LayerColumn {
  return (
    _.find(layerMetadata.columns, column => column.key === columnKey) ||
    ({} as LayerColumn)
  );
}

export function getColumnMetatype(
  layerMetadata: LayerMetadata,
  columnKey: ColumnKey,
) {
  const columnMetadata = getColumnMetadata(layerMetadata, columnKey);
  return columnMetadata.metatype;
}

export function getLayerColumnUnitKey(
  layerMetadata: LayerMetadata,
  columnKey: ColumnKey,
): UnitLabelKeys {
  const columnMetadata = getColumnMetadata(layerMetadata, columnKey);
  return columnMetadata?.units as UnitLabelKeys;
}

// A column is mappable if it has a defined metatype, otherwise we can't
// generate symbology for it.
export function getMappableColumns(
  layerMetadata: LayerMetadata,
): LayerColumn[] {
  return layerMetadata.columns.filter(column => column.metatype);
}

export function getIsPercent(columnMetadata: LayerColumn): boolean {
  return columnMetadata?.display_hints?.is_percent ?? false;
}

export function getColumnUnitLabel(unit: UnitLabelKeys): string {
  return getUnitName(unit);
}

/**
 * Check if a layer has a "filter parent". This means that the layer was derived
 * from another layer and may need to inherit symbology, or display information
 * about the parent.
 */
export function hasVisibleParent(layerMetadata: LayerMetadata) {
  const {
    layer_type: layerType,
    display_hints: displayHints,
    namespace,
  } = layerMetadata;
  return (
    layerType === 'dynamic' &&
    // Quick hack to make sure we don't display parent info if the parent is
    // inacessible to the user. In the future we should check permissions here.
    displayHints?.parent?.namespace === namespace
  );
}

/**
 * Generic function to extract a `display_hints` object from a LayerReference
 * *or* LayerMetadata. Guaranteed to always return an object.
 *
 * @param layer Any kind of layer object.
 */
export function getLayerDisplayHints<D extends {} = {}>(
  layer: LayerMetadata<D> | LayerReference,
): D {
  if (!layer) {
    return EMPTY_OBJECT as D;
  }
  if ('display_hints' in layer) {
    return layer.display_hints ?? (EMPTY_OBJECT as D);
  } else if ('details' in layer) {
    return layer.details?.display_hints ?? EMPTY_OBJECT;
  }
  return EMPTY_OBJECT as D;
}
