import {
  CuratedColumnSymbology,
  CUSTOM_THEME,
  DEFAULT_OPACITY,
  DistributionTypes,
  InterpolationTypes,
  PaintColorTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/public/dataset/epa_smart_location_blockgroup_2010': {
    d3b: [
      {
        type: SymbologyTypes.FILL,
        paint: {
          'fill-color': {
            property: 'd3b',
            type: PaintColorTypes.INTERVAL,
            stops: [
              { value: 0, label: '0-9 int/sqm', color: '#f7fcf0' },
              { value: 10, label: '10-24 int/sqm', color: '#e3f4dd' },
              { value: 25, label: '25-49 int/sqm', color: '#cfecca' },
              { value: 50, label: '50-74 int/sqm', color: '#b3e1bc' },
              { value: 75, label: '75-99 int/sqm', color: '#8fd3be' },
              { value: 100, label: '100-124 int/sqm', color: '#67c0ca' },
              { value: 125, label: '125-149 int/sqm', color: '#43a5ca' },
              { value: 150, label: '150-200 int/sqm', color: '#2384ba' },
              { value: 200, label: '200-249 int/sqm', color: '#0c63a4' },
              { value: 250, label: '250 or More int/sqm', color: '#084081' },
            ],
          },
          'fill-opacity': DEFAULT_OPACITY,
        },
        display_hints: {
          scale: SymbologyScales.NUMERIC,
          distribution: DistributionTypes.DEFAULT,
          interpolation: InterpolationTypes.INTERVAL,
          theme: CUSTOM_THEME,
        },
      },
    ],
  },
};

export default symbologies;
