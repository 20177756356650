/**
 * UrbanFootprint App API
 * API for serving UrbanFootprint Application
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { BuiltFormPainterEmploymentAllocation } from './builtFormPainterEmploymentAllocation';

/**
 * How to allocate employment values to canvas rows.
 */
export interface BuiltFormPainterEmploymentAllocations<> {
  /**
   * How to allocate agriculture and extraction employment.
   */
  agriculture_extraction_allocation?: Array<BuiltFormPainterEmploymentAllocation>;
  /**
   * How to allocate numerical values.
   */
  allocation_mode?: BuiltFormPainterEmploymentAllocations.AllocationModeEnum;
  /**
   * How to allocate industrial employment.
   */
  industrial_allocation?: Array<BuiltFormPainterEmploymentAllocation>;
  /**
   * How to allocate military employment.
   */
  military_allocation?: Array<BuiltFormPainterEmploymentAllocation>;
  /**
   * How to allocate office employment.
   */
  office_allocation?: Array<BuiltFormPainterEmploymentAllocation>;
  /**
   * How to allocate public employment.
   */
  public_allocation?: Array<BuiltFormPainterEmploymentAllocation>;
  /**
   * How to allocate retail employment.
   */
  retail_allocation?: Array<BuiltFormPainterEmploymentAllocation>;
}
export namespace BuiltFormPainterEmploymentAllocations {
  export type AllocationModeEnum = 'per_feature' | 'per_acre';
  export const AllocationModeEnum = {
    Feature: 'per_feature',
    Acre: 'per_acre',
  } as const;
}
