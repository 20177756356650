import {
  CuratedColumnSymbology,
  DistributionTypes,
  InterpolationTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const censusPlanningdatabaseHousingUnitsLayers = [
  {
    layerId:
      '/public/dataset/us_census_census_planning_database_2018_housing_units',
    columnKey: 'pct_renter_occp_hu_acs_12_16',
  },
  {
    layerId:
      '/public/dataset/us_census_census_planning_database_2019_housing_units',
    columnKey: 'pct_renter_occp_hu_acs_13_17',
  },
  {
    layerId: '/public/databank/cpd_2020_housing_units',
    columnKey: 'pct_renter_occp_hu_acs_14_18',
  },
  {
    layerId: '/public/databank/cpd_2021_housing_units',
    columnKey: 'pct_renter_occp_hu_acs_15_19',
  },
];

const symbologies: Record<
  string,
  Record<string, CuratedColumnSymbology[]>
> = {};
censusPlanningdatabaseHousingUnitsLayers.forEach(({ layerId, columnKey }) => {
  symbologies[layerId] = {
    [columnKey]: [
      {
        display_hints: {
          distribution: DistributionTypes.DEFAULT,
          interpolation: InterpolationTypes.INTERVAL,
          scale: SymbologyScales.NUMERIC,
          showZero: true,
          theme: 'custom',
        },
        paint: {
          'fill-color': {
            property: columnKey,
            stops: [
              {
                color: 'rgb(246,160,180)',
                value: 0,
              },
              {
                color: 'rgb(244,129,155)',
                value: 10,
              },
              {
                color: 'rgb(241,98,130)',
                value: 20,
              },
              {
                color: 'rgb(238,67,106)',
                value: 30,
              },
              {
                color: 'rgb(235,36,81)',
                value: 40,
              },
              {
                color: 'rgb(217,20,64)',
                value: 50,
              },
              {
                color: 'rgb(186,17,55)',
                value: 60,
              },
              {
                color: 'rgb(155,14,46)',
                value: 70,
              },
              {
                color: 'rgb(124,11,37)',
                value: 80,
              },
              {
                color: 'rgb(93,8,27)',
                value: 90,
              },
            ],
            type: 'interval',
          },
          'fill-opacity': 0.5,
        },
        type: SymbologyTypes.FILL,
      },
      {
        display_hints: {
          distribution: DistributionTypes.DEFAULT,
          interpolation: InterpolationTypes.INTERVAL,
          scale: SymbologyScales.NUMERIC,
          showZero: true,
          theme: 'custom',
        },
        paint: {
          'line-color': '#333333',
          'line-opacity': 0.5,
          'line-width': 0.5,
        },
        type: SymbologyTypes.LINE,
      },
    ],
  };
});

export default symbologies;
