import { createSlice } from '@reduxjs/toolkit';

interface ZoomExtent {
  minZoom: number;
  maxZoom: number;
}

export interface ZoomExtentState {
  dialog: { isOpen: boolean; selectedLayerId: string | null };
  zoomByLayerId: Record<string, ZoomExtent>;
}

const initialState: ZoomExtentState = {
  dialog: { isOpen: false, selectedLayerId: null },
  zoomByLayerId: {},
};

export const zoomExtentSlice = createSlice({
  name: 'zoomExtent',
  initialState,
  reducers: {
    dialogClosed: state => {
      state.dialog.isOpen = false;
      state.dialog.selectedLayerId = null;
    },
    dialogOpened: (state, action) => {
      state.dialog.isOpen = true;
      state.dialog.selectedLayerId = action.payload;
    },
    zoomExtentSet: (state, action) => {
      state.zoomByLayerId[action.payload.layerId] = action.payload.zoomExtent;
      state.dialog.isOpen = false;
      state.dialog.selectedLayerId = null;
    },
  },
});

export const { dialogClosed, dialogOpened, zoomExtentSet } =
  zoomExtentSlice.actions;

export const selectZoomExtentDialog = state => state.layers.zoomExtent.dialog;
export const selectZoomExtentsByLayerId = state =>
  state.layers.zoomExtent.zoomByLayerId;
export const selectZoomExtentByLayerId = (state, layerId) =>
  state.layers.zoomExtent.zoomByLayerId[layerId];

export default zoomExtentSlice.reducer;
