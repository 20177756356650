import classNames from 'classnames';
import React, { CSSProperties, FunctionComponent } from 'react';
import { Box } from 'react-layout-components';
import { connect } from 'react-redux';

import { getMapMode } from 'uf/explore/selectors/map';
import { ProjectId } from 'uf/projects';
import ExploreMapSpinner from 'uf/ui/explore/ExploreMap/ExploreMapSpinner';
import ExploreMapTogglePitchButton from 'uf/ui/explore/ExploreMap/ExploreMapTogglePitchButton/ExploreMapTogglePitchButton';

import styles from './ExploreMapToolbar.module.css';

// copy of mapbox's style used for their control buttons
const buttonGroupStyle: CSSProperties = {
  borderRadius: '4px',
  boxShadow: '0 0 0 2px rgba(0, 0, 0, 0.1)',
  marginBottom: 'var(--thin-spacing)',
};

interface OwnProps {
  activeLayerId: string;
  mapLoading: boolean;
  projectId: ProjectId;
}

interface StateProps {
  activeModeKey: string;
}

type ExploreMapToolbarProps = OwnProps & StateProps;

const toolbarClassName = classNames(
  styles.exploreMapToolbar,
  'ExploreMapToolbar',
);

// create this interface to pass to compose to create a type safe composed component
export const ExploreMapToolbar: FunctionComponent<ExploreMapToolbarProps> =
  props => {
    const { mapLoading, projectId } = props;
    return (
      <Box column className={toolbarClassName}>
        <ExploreMapTogglePitchButton
          projectId={projectId}
          style={buttonGroupStyle}
        />
        <ExploreMapSpinner style={buttonGroupStyle} mapLoading={mapLoading} />
      </Box>
    );
  };

function mapStateToProps(state) {
  return {
    activeModeKey: getMapMode(state),
  };
}

export default connect<StateProps, () => {}, OwnProps>(
  mapStateToProps,
  // empty function so dispatch isn't set as prop
  () => ({}),
)(ExploreMapToolbar);
