import _ from 'lodash';
import { useEffect } from 'react';
import warning from 'warning';

import { ColumnKey, LayerId } from 'uf/layers';
import { ProjectId } from 'uf/projects';
import { ensureSymbology as ensureSymbologyAction } from 'uf/symbology/actions/load';
import { makeGetSymbologyState } from 'uf/symbology/selectors';
import useBindAction from 'uf/ui/base/useBindAction/useBindAction';
import { useMakeSelector } from 'uf/ui/base/useMakeSelector';
import { ViewId } from 'uf/views';
import { LegacyVirtualLayerId } from 'uf/projects/virtualLayers';

/**
 * Ensure a single layer+columnKey has symbology loaded into redux
 */
export function useEnsureSymbology(
  projectId: ProjectId,
  viewId: ViewId,
  layerId: LayerId,
  virtualLayerId: LegacyVirtualLayerId,
  columnKey: ColumnKey,
) {
  const ensureSymbology = useBindAction(ensureSymbologyAction);
  useEffect(() => {
    if (layerId && columnKey) {
      ensureSymbology(projectId, viewId, layerId, columnKey);
    }
    // Note that we do not have any useEffect dependencies, because there may be
    // changes in redux outside of the purview of this hook. This means that
    // `ensureSymbology()` will be called on every render in case redux has
    // updated further.
  });

  return useMakeSelector(makeGetSymbologyState, {
    projectId,
    viewId,
    layerId,
    virtualLayerId,
    columnKey,
  });
}

/**
 * Ensure a list of layers have their symbologies loaded into redux
 */
export function useEnsureSymbologies(
  projectId: ProjectId,
  viewId: ViewId,
  layerIds: LayerId[],
  columnKeys: ColumnKey[],
) {
  const ensureSymbology = useBindAction(ensureSymbologyAction);
  warning(
    layerIds.length === columnKeys.length,
    'LayerIds and column key lists must match',
  );
  useEffect(() => {
    _.zip(layerIds, columnKeys).forEach(([layerId, columnKey]) => {
      if (layerId && columnKey) {
        ensureSymbology(projectId, viewId, layerId, columnKey);
      }
    });
    // Note that we do not have any useEffect dependencies, because there may be
    // changes in redux outside of the purview of this hook. This means that
    // `ensureSymbology()` will be called on every render in case redux has
    // updated further.
  });
}
