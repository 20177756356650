import {
  CuratedColumnSymbology,
  InterpolationTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/public/dataset/cal_fire_cal_fire_facilities': {
    owner: [
      {
        display_hints: {
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          scale: SymbologyScales.CATEGORICAL,
          theme: 'custom',
        },
        paint: {
          'circle-color': {
            default: '#e377c2',
            property: 'owner',
            stops: [
              {
                color: '#a4dd00',
                value: 'CITY',
              },
              {
                color: '#dbdf00',
                value: 'COUNTY',
              },
              {
                color: '#fcdc00',
                value: 'DISTRICT OTHER',
              },
              {
                color: '#9f0500',
                value: 'FEDERAL',
              },
              {
                color: '#fe9200',
                value: 'FIRE DISTRICT',
              },
              {
                color: '#f44e3b',
                value: 'STATE',
              },
            ],
            type: 'categorical',
          },
          'circle-opacity': 0.9,
          'circle-radius': 5,
          'circle-stroke-color': '#808080',
          'circle-stroke-width': 2,
        },
        type: SymbologyTypes.CIRCLE,
      },
    ],
  },
};

export default symbologies;
