import _ from 'lodash';
import { AnyAction } from 'redux';
import { Epic, ofType } from 'redux-observable';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  withLatestFrom,
} from 'rxjs/operators';
import warning from 'warning';

import { combineEpics, groupByWithTimeout } from 'uf/base/epics';
import { UFState } from 'uf/state';
import { makeUserDataKey } from 'uf/user';
import { setUserData } from 'uf/user/actions/data';
import { getUserKey } from 'uf/user/selectors/user';

import {
  PersistPrefAction,
  PREF_LOADED,
  PREF_PERSIST,
  PrefLoadedAction,
} from './ActionTypes';
import {
  createPersistKey,
  getEntryForActionType,
  getEntryForKeyPrefix,
  isRegisteredActionType,
  parsePersistKey,
} from './index';
import {
  getShouldLoadPersistence,
  getShouldSavePersistence,
} from './selectors';

// Delay before saving user pref, in case a pref is repeatedly saved (such as
// saving viewport as the user pans around the map)
const SAVE_PREF_DEBOUNCE_TIME = 500;

/**
 * This watches for all actions that have been registered with
 * {@link index:registerPersistedAction} and saves them on the side.
 */
export function makePersistEpic(
  savePrefDebounceTime: number,
): Epic<AnyAction, any, UFState> {
  /**
   * This is the set of playback actions that we have generated in this instance, and so
   * should be ignored. Playback actions are added to this array when they are first
   * generated by `makeAction`, and then removed if they are seen by the
   * persistEpic. This makes sure that we do not restore a pref from userdata,
   * then generate an action, and then re-persist the value we just restored.
   */
  const PlaybackActions = new Set<AnyAction>();

  const persistEpic: Epic<AnyAction, any, UFState> = (action$, state$) => {
    return action$.pipe(
      filter(action => {
        // Actions pass through here only once, so it is safe to remove them as we
        // ignore them.
        if (PlaybackActions.has(action)) {
          PlaybackActions.delete(action);
          return false;
        }
        return true;
      }),
      withLatestFrom(state$),
      filter(([a, state]) => getShouldSavePersistence(state)),
      filter(([{ type }]) => isRegisteredActionType(type)),
      mergeMap(([action, state]) => {
        const entries = getEntryForActionType(action);
        return entries.map(entry => {
          const { getKey, getValue, keyPrefix } = entry;
          const subPaths = getKey(action);
          const value = getValue(action, state);
          const persistKey = createPersistKey(keyPrefix, subPaths);
          const userKey = getUserKey(state$.value);
          return { persistKey, value, userKey };
        });
      }),
      // group by key so we can debounce on a key-by-key basis
      groupByWithTimeout(
        ({ userKey, persistKey }) => `${userKey}:${persistKey}`,
      ),
      mergeMap(saveInfo$ =>
        saveInfo$.pipe(
          // do not save the same value twice in a row
          distinctUntilChanged(
            ({ value: previousValue }, { value: nextValue }) =>
              _.isEqual(previousValue, nextValue),
          ),
          debounceTime(savePrefDebounceTime),
          map(({ persistKey, value, userKey }) => {
            return setUserData(persistKey, { value }, userKey);
          }),
        ),
      ),
    );
  };
  /**
   * Every time a pref is loaded from the server, dispatch the corresponding
   * action that was registered with {@link index:registerPersistedAction}.
   */
  const playbackEpic: Epic<PrefLoadedAction, any, UFState> = (
    action$,
    state$,
  ) => {
    return action$.pipe(
      ofType(PREF_LOADED),
      withLatestFrom(state$),
      filter(([a, state]) => getShouldLoadPersistence(state)),
      filter(([{ key }]) => {
        const { keyPrefix } = parsePersistKey(key);
        const entryExists = !!getEntryForKeyPrefix(keyPrefix);
        warning(entryExists, `No entry found for key prefix: ${keyPrefix}`);
        return entryExists;
      }),
      map(([action]) => {
        const { key, value } = action;
        const parsedKey = parsePersistKey(key);
        const { keyPrefix, subKeys } = parsedKey;
        const entry = getEntryForKeyPrefix(keyPrefix);
        const replayAction = entry.makePlaybackAction(subKeys, value);
        if (replayAction) {
          warning(
            typeof replayAction !== 'function',
            'Persistence playback can do weird things if playback action is a thunk. Use a POJO action instead.',
          );

          PlaybackActions.add(replayAction);
        }
        return replayAction;
      }),
      // allow `makeAction` to return null
      filter(action => !!action),
    );
  };
  return combineEpics({ playbackEpic, persistEpic }, 'persistence');
}

export const debouncedSavePref: Epic<PersistPrefAction, any> = (
  action$,
  state$,
) => {
  return action$.pipe(
    ofType(PREF_PERSIST),
    withLatestFrom(state$),
    filter(([a, state]) => getShouldSavePersistence(state)),
    map(([action]) => ({ action, userKey: getUserKey(state$.value) })),
    groupByWithTimeout(({ action, userKey }) =>
      makeUserDataKey(userKey, action.key),
    ),
    mergeMap(savePref$ => savePref$.pipe(debounceTime(100))),
    map(({ action, userKey }) => {
      return setUserData(action.key, { value: action.value }, userKey);
    }),
  );
};

export function makePersistenceEpics(
  debounceMs: number = SAVE_PREF_DEBOUNCE_TIME,
) {
  return combineEpics(
    {
      persistEpic: makePersistEpic(debounceMs),
      debouncedSavePref,
    },
    'persistence',
  );
}

export default makePersistenceEpics();
