import {
  CuratedColumnSymbology,
  InterpolationTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/public/databank/headstart_national_202210': {
    centertype: [
      {
        display_hints: {
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          is_reverse_theme: true,
          scale: SymbologyScales.CATEGORICAL,
          theme: 'custom',
        },
        paint: {
          'circle-color': {
            default: '#7f7f77',
            property: 'centertype',
            stops: [
              {
                color: '#1F77B4',
                value: 'Head Start',
              },
              {
                color: '#BDDC5F',
                value: 'Early Head Start',
              },
              {
                color: '#FF7F0E',
                value: 'American Indian and Alaska Native Head Start',
              },
              {
                color: '#E377C2',
                value: 'Migrant and Seasonal Head Start',
              },
            ],
            type: 'categorical',
          },
          'circle-opacity': 0.8,
          'circle-stroke-color': 'transparent',
        },
        type: SymbologyTypes.CIRCLE,
      },
    ],
  },
};

export default symbologies;
