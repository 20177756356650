/**
 * UrbanFootprint App API
 * API for serving UrbanFootprint Application
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * True when geometry of a row intersects with some geometry in the given layer.      Right now, this is poly-to-poly intersection.
 */
export interface ExpressionLayerIntersects<Tfilter extends object = any> {
  /**
   * If present and greater than zero, filter returns true when geometries are within this distance in meters of each other.
   */
  buffer_distance?: number;
  /**
   * Filter on the rows of the layer being intersected.
   */
  filter?: Tfilter;
  /**
   * Expression function, set to \"layer_intersects\".
   */
  fn: ExpressionLayerIntersects.FnEnum;
  /**
   * Full path to the layer being intersected.
   */
  layer: string;
  /**
   * Intersection method to use against geometries (centroid/boundary).
   */
  layer_intersection_method?: string;
  /**
   * Version of the layer being intersected.
   */
  version?: string;
}
export namespace ExpressionLayerIntersects {
  export type FnEnum = 'layer_intersects';
  export const FnEnum = {
    LayerIntersects: 'layer_intersects',
  } as const;
}
