import { Feature } from 'geojson';
import _ from 'lodash';
import { createSelector } from 'reselect';
import warning from 'warning';

import { LayerReference } from 'uf-api';
import { makeGetProjectMapMaxBounds } from 'uf/app/selectors';
import { EMPTY_OBJECT } from 'uf/base';
import { cacheableConcat } from 'uf/base/array';
import {
  ceilLngLat,
  floorLngLat,
  LngLat,
  padMapBounds,
  UFLngLatBounds,
} from 'uf/base/map';
import { makeGetFromPropsSelector } from 'uf/base/selector';
import { mapLayerReferencesToEditsTileSources } from 'uf/explore/map/edits';
import { initialMapState, InspectionObject, MapState } from 'uf/explore/state';
import { LayerId } from 'uf/layers';
import { getLayersMetadataStates } from 'uf/layers/selectors/metadata';
import {
  getAllLayerVersions,
  makeGetLayerVersion,
} from 'uf/layers/selectors/versions';
import { LayerSource, MapViewport } from 'uf/map';
import { BaseMap, findBaseMapByStyleUrl } from 'uf/map/baseMaps';
import { MapMode } from 'uf/map/mapmode';
import {
  makeMapMaxBoundsLayerSource,
  mapLayerReferencesToTileSources,
} from 'uf/map/mapStyleAdapters';
import { makeGetLayerEditFeaturesGetter } from 'uf/mapedit/selectors/features';
import { ProjectId } from 'uf/projects';
import { makeGetProjectLayerIdMap } from 'uf/projects/selectors/virtualLayers';
import { LegacyVirtualLayerId } from 'uf/projects/virtualLayers';
import { ScenarioId } from 'uf/scenarios';
import { makeGetDivideByColumnGetter } from 'uf/symbology/selectors/divideByColumn';

import { DivideByColumnKey } from 'uf/symbology/divideByColumn';
import { getLegacyExploreState, makeGetExploreByProjectState } from './explore';
import {
  makeGetProjectLayersMapStyles,
  makeGetVisibleLayerReferences,
} from './layers';

export const getMap = createSelector(
  getLegacyExploreState,
  ({ map = EMPTY_OBJECT }): MapState => map as MapState,
);

export function makeGetExploreMapForProject() {
  return createSelector(
    makeGetExploreByProjectState(),
    explore => explore?.map ?? initialMapState,
  );
}

export const getMapMode = makeGetExploreMapUIStatePropertySelector(
  'mode',
  MapMode.NORMAL,
);

export const getSelectionModesDisabled =
  makeGetExploreMapUIStatePropertySelector('selectionModesDisabled', false);

export const getMapStatus = makeGetExploreMapUIStatePropertySelector(
  'mapStatus',
  null,
);

export function makeGetExploreMapStyle() {
  const getMapForProject = makeGetExploreMapForProject();

  return createSelector(getMapForProject, map => map.mapStyle);
}

export function makeGetExploreMapStyleUrl() {
  return createSelector(
    makeGetExploreMapStyle(),
    mapStyle => mapStyle.styleUrl,
  );
}

export const getExploreMapStyle = makeGetExploreMapUIStatePropertySelector(
  'mapStyle',
  EMPTY_OBJECT,
);
export const getHasContours = createSelector(
  makeGetExploreMapByProjectIdUIStatePropertySelector('mapStyle'),
  mapStyle => (mapStyle ? mapStyle.contoursVisible : false),
);
export const getHasBuildings = createSelector(
  makeGetExploreMapByProjectIdUIStatePropertySelector('mapStyle'),
  mapStyle => (mapStyle ? mapStyle.buildingsVisible : false),
);
export const getHasCitiesLabels = createSelector(
  makeGetExploreMapByProjectIdUIStatePropertySelector('mapStyle'),
  mapStyle => (mapStyle ? mapStyle.citiesLabelVisible : true),
);
export const getHasPlaceLabels = createSelector(
  makeGetExploreMapByProjectIdUIStatePropertySelector('mapStyle'),
  mapStyle => (mapStyle ? mapStyle.placesLabelVisible : true),
);
export const getHasPoiLabels = createSelector(
  makeGetExploreMapByProjectIdUIStatePropertySelector('mapStyle'),
  mapStyle => (mapStyle ? mapStyle.poiLabelVisible : false),
);
export const getHasRoadRailLabels = createSelector(
  makeGetExploreMapByProjectIdUIStatePropertySelector('mapStyle'),
  mapStyle => (mapStyle ? mapStyle.roadRailLabelVisible : false),
);
export const getHasWaterLabels = createSelector(
  makeGetExploreMapByProjectIdUIStatePropertySelector('mapStyle'),
  mapStyle => (mapStyle ? mapStyle.waterLabelVisible : true),
);
export const getHasWaterMask = createSelector(
  makeGetExploreMapByProjectIdUIStatePropertySelector('mapStyle'),
  mapStyle => (mapStyle ? mapStyle.waterMaskVisible : true),
);

export const getExploreMapCenter =
  makeGetExploreMapByProjectIdUIStatePropertySelector('center', null);
export function makeGetExploreMapCenterLngLat() {
  return createSelector(getExploreMapCenter, center => {
    const centerLngLat: LngLat = center
      ? {
          lng: center[0],
          lat: center[1],
        }
      : null;
    return centerLngLat;
  });
}
export const getExploreMapZoom =
  makeGetExploreMapByProjectIdUIStatePropertySelector('zoom', null);
export const getExploreMapHeight =
  makeGetExploreMapByProjectIdUIStatePropertySelector('height');
export const getExploreMapWidth =
  makeGetExploreMapByProjectIdUIStatePropertySelector('width');
export const getExploreMapPitch =
  makeGetExploreMapByProjectIdUIStatePropertySelector('pitch');
export const getExploreMapBearing =
  makeGetExploreMapByProjectIdUIStatePropertySelector('bearing');
export const getExploreMapBounds =
  makeGetExploreMapByProjectIdUIStatePropertySelector('mapBounds');
export const getExploreMapScale =
  makeGetExploreMapByProjectIdUIStatePropertySelector('scale');
export const getMapRequestedBounds = makeGetExploreMapUIStatePropertySelector(
  'requestedMapBounds',
  null,
);
export const getStyleLayers = makeGetExploreMapUIStatePropertySelector(
  'debugStyleLayers',
  EMPTY_OBJECT,
);
export const getInspection = makeGetExploreMapUIStatePropertySelector(
  'inspection',
  EMPTY_OBJECT as InspectionObject,
);
export const getShouldTakeSnapshot = makeGetExploreMapUIStatePropertySelector(
  'takeSnapshot',
  false,
);

export const getExploreMapViewport = createSelector(
  getExploreMapCenter,
  getExploreMapZoom,
  getExploreMapWidth,
  getExploreMapHeight,
  getExploreMapPitch,
  getExploreMapBearing,
  getExploreMapBounds,
  (center, zoom, width, height, pitch, bearing, bounds) => {
    const viewport: MapViewport = {
      center,
      zoom,
      width,
      height,
      pitch,
      bearing,
      bounds,
    };

    return viewport;
  },
);

const FUZZY_PRECISION = 3;
export const getExploreMapFuzzyBounds = createSelector(
  getExploreMapBounds,
  getExploreMapWidth,
  getExploreMapHeight,
  (bounds, width, height): UFLngLatBounds => {
    // pad evenly
    const padded = padMapBounds(bounds, width, height);
    if (!padded) {
      return null;
    }
    return [
      [
        floorLngLat(padded[0][0], FUZZY_PRECISION),
        floorLngLat(padded[0][1], FUZZY_PRECISION),
      ],
      [
        ceilLngLat(padded[1][0], FUZZY_PRECISION),
        ceilLngLat(padded[1][1], FUZZY_PRECISION),
      ],
    ];
  },
);

export function makeGetLayerInspection() {
  return createSelector(
    getInspection,
    makeGetFromPropsSelector<LayerId, 'layerId'>('layerId'),
    (inspection, layerId) => inspection[layerId] || EMPTY_OBJECT,
  );
}

export function makeGetLayerInspectionQuery() {
  const getLayerInspection = makeGetLayerInspection();
  const getLayerVersion = makeGetLayerVersion();
  return createSelector(
    getLayerInspection,
    getLayerVersion,
    (inspection, version) => {
      if (_.isEmpty(inspection)) {
        return EMPTY_OBJECT;
      }
      // TODO: define type for this!
      const query: any = {
        filters: {
          pointIds: { [inspection.properties.geometry_key]: true },
        },
      };

      if (version) {
        query.version = version;
      }

      return query;
    },
  );
}

export function makeGetUfTileSources() {
  return createSelector(
    makeGetVisibleLayerReferences(),
    getAllLayerVersions,
    getLayersMetadataStates,
    makeGetProjectLayersMapStyles(), // mapStyles (for the active columns)
    makeGetProjectMapMaxBounds(),
    makeGetDivideByColumnGetter(),
    makeGetLayerEditFeaturesGetter(),
    makeGetProjectLayerIdMap(),
    makeGetFromPropsSelector<ProjectId, 'projectId'>('projectId'),
    makeGetFromPropsSelector<ScenarioId, 'scenarioId'>('scenarioId'),
    (
      layers,
      layerVersions,
      layerMetadataStates,
      mapStyles,
      mapMaxBounds,
      getDivideByColumn,
      getLayerEditFeatures,
      layerMap,
      projectId,
      unusedScenarioId,
    ): LayerSource[] => {
      const divideByColumns: Record<
        LayerId /* layerId */,
        DivideByColumnKey /* divideByColumn */
      > = {};
      layers.forEach(layer => {
        const { full_path: layerId } = layer;
        const virtualLayerId: LegacyVirtualLayerId = layerMap[layerId];
        const columnKey = mapStyles?.[virtualLayerId]?.activeColumnKey;
        const divideByColumn = getDivideByColumn(projectId, layerId, columnKey);

        divideByColumns[layerId] = divideByColumn;
      });

      const tileSources = mapLayerReferencesToTileSources(
        layers,
        layerVersions,
        layerMetadataStates,
        mapStyles,
        divideByColumns,
        mapMaxBounds,
        layerMap,
      );

      const boundsSources = makeMapMaxBoundsLayerSource(
        projectId,
        mapMaxBounds,
      );

      const editsSources = makeEditsSources(
        layers,
        getLayerEditFeatures,
        projectId,
      );

      return cacheableConcat(tileSources, boundsSources, editsSources);
    },
  );
}

/**
 * Generate tile sources for all the edits layers that have features.
 */
function makeEditsSources(
  layers: LayerReference[],
  getLayerEditFeatures: (projectId: ProjectId, layerId: LayerId) => Feature[],
  projectId: ProjectId,
): LayerSource[] {
  const editsFeatures: Record<LayerId, Feature[]> = {};
  layers.forEach(layer => {
    const { full_path: layerId } = layer;
    const features = getLayerEditFeatures(projectId, layerId);
    editsFeatures[layerId] = features;
  });
  const editsSources = mapLayerReferencesToEditsTileSources(
    layers,
    editsFeatures,
  );
  return editsSources;
}

export function makeGetLayerMappedColumnKey() {
  return createSelector(
    makeGetProjectLayersMapStyles(),
    makeGetProjectLayerIdMap(),
    makeGetFromPropsSelector<LayerId, 'layerId'>('layerId'),
    (layerMapStyles, layerMap, layerId) => {
      const virtualLayerId: LegacyVirtualLayerId = layerMap[layerId];
      warning(!!virtualLayerId, `No virtual layer for ${layerId}`);
      if (virtualLayerId in layerMapStyles) {
        return layerMapStyles[virtualLayerId].activeColumnKey;
      }
      return null;
    },
  );
}

function makeGetExploreMapUIStatePropertySelector<K extends keyof MapState>(
  propertyKey: K,
  defaultValue?: MapState[K],
) {
  // only use defaultValue if one is passed, otherwise falsy values won't resolve correctly
  if (defaultValue !== undefined) {
    return createSelector(getMap, mapState => {
      if (propertyKey in mapState) {
        return mapState[propertyKey];
      }
      return defaultValue;
    });
  }

  return createSelector(getMap, mapState => mapState[propertyKey]);
}

function makeGetExploreMapByProjectIdUIStatePropertySelector<
  K extends keyof MapState,
>(propertyKey: K, defaultValue?: MapState[K]) {
  const getExploreMapForProject = makeGetExploreMapForProject();
  // only use defaultValue if one is passed, otherwise falsy values won't resolve correctly
  if (defaultValue !== undefined) {
    return createSelector(getExploreMapForProject, mapState => {
      if (propertyKey in mapState) {
        return mapState[propertyKey];
      }
      return defaultValue;
    });
  }

  return createSelector(
    getExploreMapForProject,
    mapState => mapState[propertyKey],
  );
}

export function makeGetExploreBaseMap() {
  return createSelector(
    makeGetExploreMapStyleUrl(),
    (styleUrl): BaseMap => findBaseMapByStyleUrl(styleUrl),
  );
}
