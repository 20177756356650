/**
 * UrbanFootprint App API
 * API for serving UrbanFootprint Application
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Various map controls that are visible on or next to the map.
 */
export interface MapExportControlSettings<> {
  /**
   * The type of compass.
   */
  compass_type?: MapExportControlSettings.CompassTypeEnum;
  /**
   * Whether to include all the legend items or only the ones visible on the map
   */
  legend_classes_to_include?: MapExportControlSettings.LegendClassesToIncludeEnum;
  /**
   * The list of column ids to be visible on the legend. Must be the same length as layers argument.
   */
  legend_layer_column_keys?: Array<string>;
  /**
   * The list of layer paths to be visible on the legend. Must be a subset of `layers`
   */
  legend_layers?: Array<string>;
  /**
   * The location of the legend
   */
  legend_type?: MapExportControlSettings.LegendTypeEnum;
  /**
   * The organization logo key.
   */
  logo_key?: string;
  /**
   * The type of logo to show on the map. (deprecated)
   */
  logo_type?: MapExportControlSettings.LogoTypeEnum;
  /**
   * The type of scale.
   */
  scale_type?: MapExportControlSettings.ScaleTypeEnum;
  /**
   * The type of units in the scale.
   */
  scale_units?: MapExportControlSettings.ScaleUnitsEnum;
  /**
   * The title of the map
   */
  title?: string;
}
export namespace MapExportControlSettings {
  export type CompassTypeEnum = 'none' | 'default';
  export const CompassTypeEnum = {
    None: 'none',
    Default: 'default',
  } as const;
  export type LegendClassesToIncludeEnum = 'all' | 'visible_only';
  export const LegendClassesToIncludeEnum = {
    All: 'all',
    VisibleOnly: 'visible_only',
  } as const;
  export type LegendTypeEnum = 'none' | 'separate' | 'overlay_upper_right';
  export const LegendTypeEnum = {
    None: 'none',
    Separate: 'separate',
    OverlayUpperRight: 'overlay_upper_right',
  } as const;
  export type LogoTypeEnum = 'none' | 'uf_logo';
  export const LogoTypeEnum = {
    None: 'none',
    UfLogo: 'uf_logo',
  } as const;
  export type ScaleTypeEnum = 'none' | 'default' | 'alternating_scale_bar';
  export const ScaleTypeEnum = {
    None: 'none',
    Default: 'default',
    AlternatingScaleBar: 'alternating_scale_bar',
  } as const;
  export type ScaleUnitsEnum = 'us' | 'metric';
  export const ScaleUnitsEnum = {
    Us: 'us',
    Metric: 'metric',
  } as const;
}
