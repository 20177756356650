import {
  Button,
  Classes,
  IResizeEntry,
  PopoverInteractionKind,
  Position,
  ResizeSensor,
  Spinner,
  Tooltip,
} from '@blueprintjs/core';
import { mdiDragHorizontal, mdiFilter } from '@mdi/js';
import Icon from '@mdi/react';
import classNames from 'classnames';
import React, {
  FC,
  FunctionComponent,
  memo,
  MouseEvent,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { Box } from 'react-layout-components';
import { t } from 'ttag';

import { LayerReference } from 'uf-api';
import { EMPTY_OBJECT } from 'uf/base';
import { LayerId } from 'uf/layers';
import { filtersAreEmpty, FilterSpec } from 'uf/layers/filters';
import { getParentLayerId, isPaintedLayer } from 'uf/layers/helpers';
import { events } from 'uf/layers/logging';
import { logEvent } from 'uf/logging';
import { ProjectId } from 'uf/projects';
import { LegacyVirtualLayerId } from 'uf/projects/virtualLayers';
import rootStyles from 'uf/styles/root.module.css';
import textStyles from 'uf/styles/text.module.css';
import Clickable from 'uf/ui/base/Clickable/Clickable';
import { CopyableText } from 'uf/ui/base/CopyableText/CopyableText';
import { elementsOverflow } from 'uf/ui/base/domHelpers';
import { IconSize, IconSizeNew } from 'uf/ui/base/icon';
import Kebab from 'uf/ui/base/Kebab/Kebab';
import makeDataAttributes from 'uf/ui/base/makeDataAttributes';
import WithTooltip from 'uf/ui/base/WithTooltip/WithTooltip';
import VisibilityToggle from 'uf/ui/layers/VisibilityToggle/VisibilityToggle';
import SaveWorkingLayerPrompt, {
  FormProps,
  useLayerPromptProps,
} from 'uf/ui/projects/SaveWorkingLayerButton/SaveWorkingLayerPrompt';

import { parseFullPath } from 'uf/base/dataset';
import { storeMarkerPath } from 'uf/ui/base/StoreMarker';
import ArrowLeft from './ArrowLeft';
import { useCreateConvertedLayerCallback } from './hooks';
import styles from './LayerListItem.module.css';
import LayerListItemMenu from './LayerListItemMenu';

export const LAYER_NAME_TEST_ID = 'layer-name';

interface Props {
  className?: string;
  layer: LayerReference;
  layerId: LayerId;
  virtualLayerId: LegacyVirtualLayerId;
  projectId: ProjectId;
  iconPath?: string;
  active?: boolean;
  isPlaceholderLayer?: boolean;
  isBaseCanvas?: boolean;
  isLoading?: boolean;
  isExtruded?: boolean;
  statusMessage?: string;
  visible?: boolean;
  filters?: Partial<FilterSpec>;
  flyoutPanelVisible?: boolean;
  showPaintedOnly?: boolean;
  dragging?: boolean;
  onActivateLayer: (
    projectId: ProjectId,
    virtualLayerId: LegacyVirtualLayerId,
    active: boolean,
  ) => void;
  onDelete: (layer: LayerId) => void;
  onOpenFilterList: (layerId: LayerId) => void;
  onChangeLayerVisibility: (
    virtualLayerId: LegacyVirtualLayerId,
    visible: boolean,
  ) => void;
  onEnableFilter: (enabled: boolean) => void;
  onShowPaintedOnly: (layerId: LayerId, showPaintedOnly: boolean) => void;
  onToggleExtrusion: () => void;
  showLayerId?: boolean;
  onUpdateSelect: () => void;
}

const tooltipOpenDelay = 500;
/** Only used for debugging, to copy the layerId */
const tooltipCloseDelay = 1000;

const preventDefault = (e: MouseEvent) => e.stopPropagation();

const LayerListItem: FunctionComponent<Props> = props => {
  const {
    className,
    layer = EMPTY_OBJECT as LayerReference,
    layerId,
    virtualLayerId,
    projectId,
    iconPath,
    active,
    visible,
    isPlaceholderLayer,
    isBaseCanvas,
    isLoading,
    isExtruded = false,
    statusMessage,
    dragging,
    flyoutPanelVisible,
    showPaintedOnly,
    onActivateLayer,
    filters,
    onDelete,
    onOpenFilterList,
    onChangeLayerVisibility,
    onShowPaintedOnly,
    onToggleExtrusion,
    showLayerId,
    onUpdateSelect,
  } = props;

  const onClick = useCallback(
    e => {
      if (isLoading) {
        return;
      }

      if (dragging) {
        return;
      }

      onActivateLayer(projectId, virtualLayerId, !active);

      // logging the new state of the layer
      logEvent(events.LAYER_SET_ACTIVE, { active: !active, layer, projectId });
    },
    [
      active,
      dragging,
      isLoading,
      layer,
      onActivateLayer,
      projectId,
      virtualLayerId,
    ],
  );
  const [titleOverflows, setTitleOverflows] = useState(false);
  const showTooltip = titleOverflows || showLayerId;

  const layerName = layer?.details?.name;

  const isPainted = !isBaseCanvas && isPaintedLayer(layer);
  const layerDataAttributes = useMemo(
    () =>
      makeDataAttributes({
        layerId: layer.full_path,
        parentLayerId: getParentLayerId(layer),
        isPainted,
        isBaseCanvas,
        isActive: active ? active : undefined,
        isVisible: visible ? visible : undefined,
      }),
    [active, isBaseCanvas, isPainted, layer, visible],
  );

  const onTitleResize = useCallback((entries: IResizeEntry[]) => {
    const overflowed = elementsOverflow(
      entries.map(entry => entry.target),
      1,
    );
    setTitleOverflows(overflowed);
  }, []);

  // create a placeholder item for hidden items.  we need these so DraggableList will keep track
  // of the items when it sets the order after an item has moved.  this is necessary for
  // persisting order across scenarios.
  if (isPlaceholderLayer) {
    return <div className={rootStyles.displayNone} />;
  }

  const layerListItemClass = classNames(
    'uf-layer-list-item-inner',
    'LayerListItem',
    styles.item,
    {
      [styles.active]: active,
      'LayerListItem-BaseCanvas': isBaseCanvas,
      'LayerListItem-active': active,
    },
    className,
  );

  const layerNameClass = classNames(
    styles.layerName,
    Classes.TEXT_OVERFLOW_ELLIPSIS,
    { [styles.layerVisible]: visible },
    'LayerList-activateLayer',
  );

  const title = isLoading ? (
    statusMessage
  ) : (
    <span data-testid={LAYER_NAME_TEST_ID}>{layerName}</span>
  );

  const tooltip = showLayerId ? (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div onClick={preventDefault}>
      <div>{title}</div>
      <CopyableText text={layer.full_path} />{' '}
    </div>
  ) : (
    title
  );
  const isPointOfInterestLayer =
    layer.details?.display_hints?.parent?.key?.includes('safegraph_poi');
  return (
    <Box
      justifyContent="space-between"
      className={layerListItemClass}
      {...layerDataAttributes}>
      <Clickable
        className={classNames(textStyles.ellipsis, rootStyles.flex)}
        onClick={onClick}>
        <Box
          alignItems="center"
          fit
          className={rootStyles.thinPaddingHorizontal}>
          <LayerTypeIcon
            dragging={dragging}
            loading={isLoading}
            layerVisible={visible}
            iconPath={isPointOfInterestLayer ? storeMarkerPath : iconPath}
          />
          <Tooltip
            boundary="viewport"
            wrapperTagName="div"
            targetTagName="div"
            position={Position.TOP}
            className={layerNameClass}
            content={tooltip}
            disabled={!showTooltip}
            popoverClassName={rootStyles.maxWidthLarge}
            interactionKind={PopoverInteractionKind.HOVER}
            hoverOpenDelay={tooltipOpenDelay}
            hoverCloseDelay={showLayerId ? tooltipCloseDelay : undefined}>
            <ResizeSensor onResize={onTitleResize}>
              <div className={Classes.TEXT_OVERFLOW_ELLIPSIS}>{title}</div>
            </ResizeSensor>
          </Tooltip>
        </Box>
      </Clickable>

      {!isLoading && (
        <LayerListItemToolbar
          dragging={dragging}
          isPainted={isPainted}
          isExtruded={isExtruded}
          filters={filters}
          layer={layer}
          layerId={layerId}
          virtualLayerId={virtualLayerId}
          projectId={projectId}
          active={active}
          layerVisible={visible}
          showPaintedOnly={showPaintedOnly}
          onDelete={onDelete}
          onOpenFilterList={onOpenFilterList}
          onActivateLayer={onActivateLayer}
          onChangeLayerVisibility={onChangeLayerVisibility}
          onShowPaintedOnly={onShowPaintedOnly}
          onToggleExtrusion={onToggleExtrusion}
          onUpdateSelect={onUpdateSelect}
        />
      )}

      {active && flyoutPanelVisible && <ArrowLeft />}
    </Box>
  );
};

export default memo(LayerListItem);

interface LayerTypeIconProps {
  iconPath: string;
  layerVisible: boolean;

  /* Whether or not the layer is currently being dragged */
  dragging: boolean;
  loading: boolean;
}

const LayerTypeIcon: React.FunctionComponent<LayerTypeIconProps> = ({
  iconPath,
  dragging,
  loading,
  layerVisible,
}) => {
  if (loading) {
    return (
      <Spinner
        intent="primary"
        className={rootStyles.thinPaddingRight}
        size={IconSize.SMALL}
      />
    );
  }

  const className = classNames(rootStyles.thinMarginRight, styles.layerIcon, {
    [styles.layerVisible]: layerVisible,
  });
  const path = dragging ? mdiDragHorizontal : iconPath;

  return <Icon className={className} path={path} size={IconSizeNew.SMALL} />;
};

interface FilterButtonProps {
  layerVisible: boolean;
  hasFilters: boolean;
  onClick: () => void;
}

const FilterButton: React.FunctionComponent<FilterButtonProps> = ({
  hasFilters,
  onClick,
}) => {
  const iconClass = hasFilters ? styles.hasFilters : null;

  return (
    <WithTooltip text={t`Filter`}>
      <Clickable onClick={onClick}>
        <Icon
          path={mdiFilter}
          className={iconClass}
          size={IconSizeNew.EXTRA_SMALL}
        />
      </Clickable>
    </WithTooltip>
  );
};

interface ExtrusionButtonProps {
  isExtruded?: boolean;
  onClick: () => void;
}

const ExtrusionButton: React.FunctionComponent<ExtrusionButtonProps> = ({
  isExtruded,
  onClick,
}) => {
  if (!isExtruded) {
    return null;
  }

  return (
    <Tooltip position="top" content={t`Turn off 3D extrusion`}>
      <Button
        data-testid="extrude-layer-button"
        minimal
        intent="primary"
        icon="cube"
        onClick={onClick}
      />
    </Tooltip>
  );
};

interface LayerListItemToolbarProps {
  layer: LayerReference;
  layerId: LayerId;
  virtualLayerId: LegacyVirtualLayerId;
  dragging: boolean;
  active?: boolean;
  isPainted?: boolean;
  isExtruded?: boolean;
  layerVisible?: boolean;
  projectId: ProjectId;
  filters?: Partial<FilterSpec>;
  showPaintedOnly?: boolean;
  onDelete: (layerId: LayerId) => void;
  onOpenFilterList: (layerId: LayerId) => void;
  onActivateLayer: (
    projectId: ProjectId,
    virtualLayerId: LegacyVirtualLayerId,
    active: boolean,
  ) => void;
  onChangeLayerVisibility: (
    virtualLayerId: LegacyVirtualLayerId,
    visible: boolean,
  ) => void;
  onShowPaintedOnly: (layerId: LayerId, showPaintedOnly: boolean) => void;
  onToggleExtrusion: () => void;
  onUpdateSelect: () => void;
}

const LayerListItemToolbar: FC<LayerListItemToolbarProps> = ({
  onChangeLayerVisibility,
  layerVisible,
  layer,
  dragging,
  projectId,
  virtualLayerId,
  onShowPaintedOnly,
  showPaintedOnly,
  filters,
  onActivateLayer,
  onOpenFilterList,
  layerId,
  isPainted,
  isExtruded,
  onToggleExtrusion,
  onDelete,
  onUpdateSelect,
}) => {
  const [isKebabOpen, setKebabOpen] = useState<boolean>(false);
  const onChangeLayerVisibilityHandler = useCallback(
    e => {
      e.stopPropagation();

      if (dragging) {
        return;
      }

      onChangeLayerVisibility(virtualLayerId, !layerVisible);
      logEvent(events.LAYER_SET_VISIBILITY, {
        visible: !layerVisible,
        layer,
        projectId,
      });
    },
    [
      onChangeLayerVisibility,
      layerVisible,
      layer,
      dragging,
      projectId,
      virtualLayerId,
    ],
  );

  const onShowPaintedOnlyHandler = useCallback(() => {
    if (dragging) {
      return;
    }

    onShowPaintedOnly(layer.full_path, !showPaintedOnly);
  }, [onShowPaintedOnly, showPaintedOnly, dragging, layer]);

  const onKebabOpen = useCallback(() => {
    if (dragging) {
      return;
    }

    setKebabOpen(true);
  }, [dragging, setKebabOpen]);

  const onKebabClose = useCallback(() => {
    if (dragging) {
      return;
    }

    setKebabOpen(false);
  }, [dragging, setKebabOpen]);

  const hasFilters = !filtersAreEmpty(filters);

  const onOpenFilterListHandler = useCallback(() => {
    if (dragging) {
      return;
    }

    onActivateLayer(projectId, virtualLayerId, true);
    onOpenFilterList(layerId);
  }, [
    onActivateLayer,
    onOpenFilterList,
    dragging,
    layerId,
    virtualLayerId,
    projectId,
  ]);

  // The toolbar holds all the control buttons for interacting with the layer.
  // We show it through css :hover when the mouse is hovering over the list item
  // and via an explicit .show class if there are filters present or the kebab is open.

  const toolbarIconClass = classNames(
    styles.listItemToolbar,
    rootStyles.alignItemsCenter,
    {
      [styles.show]: isKebabOpen,
    },
  );

  // TODO: convert all buttons to blueprint buttons with icons so we unify padding
  const toolbarIconClassWithPadding = classNames(
    toolbarIconClass,
    rootStyles.smallPaddingRight,
  );

  const extrusionButtonClass = classNames(
    toolbarIconClass,
    rootStyles.veryThinPaddingRight, // using a blueprint button so padding is different
    {
      [styles.show]: isExtruded,
    },
  );

  const filterButtonClass = classNames(toolbarIconClassWithPadding, {
    [styles.show]: hasFilters,
  });

  const [showSaveLayer, setShowSaveLayer] = useState<boolean>(false);
  const layerVersion = layer.version;
  const layerName = layer?.details?.name;
  const newLayerDefaults = { name: `Point(s): ${layerName}` };
  const onConvertToPoints = useCallback(() => {
    setShowSaveLayer(true);
  }, []);

  const onCancelSaveLayer = useCallback(() => {
    setShowSaveLayer(false);
  }, []);

  const createConvertedLayerCallback = useCreateConvertedLayerCallback(
    projectId,
    layerId,
    layerVersion,
  );

  const onSaveLayer = useCallback(
    (props: FormProps) =>
      createConvertedLayerCallback(props).finally(() => {
        setShowSaveLayer(false);
      }),
    [createConvertedLayerCallback],
  );
  const layerPromptProps = useLayerPromptProps(onSaveLayer);

  const sameNamespace =
    parseFullPath(projectId).namespace === parseFullPath(layerId).namespace;

  const makeMenu = useCallback(
    () => (
      <LayerListItemMenu
        paintedOnly={showPaintedOnly}
        layerId={layerId}
        projectId={projectId}
        onDelete={onDelete}
        onConvertToPoints={sameNamespace ? onConvertToPoints : null}
        onShowPaintedOnly={isPainted ? onShowPaintedOnlyHandler : null}
        onUpdateSelect={onUpdateSelect}
      />
    ),
    [
      sameNamespace,
      showPaintedOnly,
      layerId,
      projectId,
      onDelete,
      isPainted,
      onShowPaintedOnlyHandler,
      onConvertToPoints,
      onUpdateSelect,
    ],
  );

  return (
    <React.Fragment>
      <div className={extrusionButtonClass}>
        <ExtrusionButton isExtruded={isExtruded} onClick={onToggleExtrusion} />
      </div>

      <div className={filterButtonClass}>
        <FilterButton
          hasFilters={hasFilters}
          layerVisible={layerVisible}
          onClick={onOpenFilterListHandler}
        />
      </div>

      <div className={toolbarIconClassWithPadding}>
        <VisibilityToggle
          layerVisible={layerVisible}
          onClick={onChangeLayerVisibilityHandler}
        />
      </div>

      <div className={toolbarIconClassWithPadding}>
        <Kebab
          horizontal
          small
          overlayPlacement="top"
          menuPlacement="bottom"
          onOpened={onKebabOpen}
          onClosed={onKebabClose}>
          {makeMenu}
        </Kebab>
      </div>

      <SaveWorkingLayerPrompt
        show={showSaveLayer}
        title={
          <div className="h5">{t`Save a new layer as point features`}</div>
        }
        okDisabled={false}
        onOk={onSaveLayer}
        onCancel={onCancelSaveLayer}
        defaults={newLayerDefaults}
        {...layerPromptProps}
      />
    </React.Fragment>
  );
};
