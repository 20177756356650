import { InputGroup, Label } from '@blueprintjs/core';
import { boundMethod } from 'autobind-decorator';
import * as _ from 'lodash';
import * as React from 'react';
import { Box } from 'react-layout-components';
import { t } from 'ttag';

import { LngLat } from 'uf/base/map';
import slug from 'uf/base/slug';
import { MapPosition } from 'uf/map';
import inputStyles from 'uf/styles/input.module.css';
import rootStyles from 'uf/styles/root.module.css';
import FormDialog from 'uf/ui/base/Modals/FormDialog';
import NumericControl from 'uf/ui/forms/NumericControl/NumericControl';

export interface Props {
  show?: boolean;

  /** default lng/lat position */
  lnglat: LngLat;

  /** default scale */
  scale: number;
  pitch: number;
  bearing: number;

  /** event handler that fires as the values change */
  onChange?: (position: MapPosition) => void;

  /** fires when the user hits ok */
  onOk?: (position: MapPosition) => void;

  /** fires when the user hits cancel */
  onCancel?: () => void;
}

export interface State {
  name?: string;
  isValidLngLat: boolean;
}

// put sane upper/lower bounds - but eventually we'll need to pipe
// a maxScale in as a prop, to be project-dependent
const MIN_SCALE = 100;
const MAX_SCALE = 1_500_000;
export class MapPositionDialog extends React.Component<Props, State> {
  static DEBOUNCE_TIME = 500;

  constructor(props) {
    super(props);
    this.state = {
      name: '',
      isValidLngLat: isValidLngLat(props.lnglat),
    };
  }

  @boundMethod
  onOk() {
    const { lnglat, scale, pitch, bearing } = this.props;
    const { name } = this.state;
    this.props.onOk({
      name,
      key: slug(name),
      lnglat,
      scale,
      pitch,
      bearing,
    });
  }

  @boundMethod
  onChangeName(name: string) {
    this.setState({ name });
  }

  @boundMethod
  onPositionChange(position: MapPosition) {
    const { onChange } = this.props;
    const { lnglat } = position;

    if (isValidLngLat(lnglat)) {
      this.setState({ isValidLngLat: true }, () => onChange(position));
    } else {
      this.setState({ isValidLngLat: false });
    }
  }

  render() {
    const { show, onCancel, lnglat, scale, pitch, bearing } = this.props;

    const { name } = this.state;

    const isValid = name && this.state.isValidLngLat && scale;

    return (
      <FormDialog
        show={show}
        title={t`Save map position`}
        onOk={this.onOk}
        okDisabled={!isValid}
        onCancel={onCancel}>
        <MapPositionForm
          debounceTime={MapPositionDialog.DEBOUNCE_TIME}
          lnglat={lnglat}
          scale={scale}
          pitch={pitch}
          bearing={bearing}
          onPositionChange={this.onPositionChange}
          onChangeName={this.onChangeName}
        />
      </FormDialog>
    );
  }
}

interface FormProps {
  lnglat: LngLat;

  /** default scale */
  scale: number;
  pitch: number;
  bearing: number;
  onPositionChange: (position: MapPosition) => void;
  onChangeName: (name: string) => void;

  debounceTime: number;
}

export interface FormState {
  name: string;
  lnglat: LngLat;
  scale: number;

  pitch: number;
  bearing: number;
}

class MapPositionForm extends React.Component<FormProps, FormState> {
  onChangeDebounced: (position: MapPosition) => void;

  constructor(props: FormProps) {
    super(props);

    this.state = {
      lnglat: props.lnglat,
      scale: props.scale || 0,
      pitch: props.pitch || 0,
      bearing: props.bearing || 0,
      name: '',
    };
    this.onChangeDebounced = props.onPositionChange
      ? _.debounce(props.onPositionChange, props.debounceTime)
      : null;
  }

  componentDidUpdate(prevProps: FormProps) {
    const { lnglat, scale, onPositionChange } = this.props;
    // deal with debouncing: after an onChange is fired, the map might take a
    // bit to move into position. That position might not be compatibile with
    // the position the user entered (i.e. if they enter a lng/lat that is out
    // of bounds for the project)
    // TODO: alert the user when the updated lng/lat/scale need to be adjusted.
    if (scale !== prevProps.scale) {
      this.setState({ scale });
    }

    if (
      lnglat &&
      lnglat !== prevProps.lnglat &&
      (lnglat.lat !== prevProps.lnglat.lat ||
        lnglat.lng !== prevProps.lnglat.lng)
    ) {
      this.setState({ lnglat });
    }

    if (prevProps.onPositionChange !== onPositionChange) {
      this.onChangeDebounced = onPositionChange
        ? _.debounce(onPositionChange, this.props.debounceTime)
        : null;
    }
  }

  onPositionChange() {
    if (this.onChangeDebounced) {
      const { scale, lnglat, bearing, pitch } = this.state;
      this.onChangeDebounced({
        scale,
        lnglat,
        bearing,
        pitch,
      });
    }
  }

  @boundMethod
  onChangeLngLat(key: 'lng' | 'lat', value: number) {
    this.setState(
      prevState => ({
        ...prevState,
        lnglat: {
          ...prevState.lnglat,
          [key]: value,
        },
      }),
      () => this.onPositionChange(),
    );
  }

  @boundMethod
  onChangeScale(key: string, value: number) {
    this.setState({ scale: value }, () => this.onPositionChange());
  }

  @boundMethod
  onChangeName(event: React.ChangeEvent<HTMLInputElement>) {
    const name = event.target.value;

    this.setState({ name }, () => this.props.onChangeName(this.state.name));
  }

  render() {
    const {
      lnglat: { lng = 0, lat = 0 },
      scale,
      name,
    } = this.state;

    const lngErrorMessage = !isValidLng(lng)
      ? t`Longitude must be between -180 and 180 degrees.`
      : undefined;

    const latErrorMessage = !isValidLat(lat)
      ? t`Latitude must be between -90 and 90 degrees.`
      : undefined;

    return (
      <React.Fragment>
        <Label>
          {t`Name`}
          <InputGroup
            name="name"
            value={name}
            onChange={this.onChangeName}
            type="text"
          />
        </Label>

        <Box justifyContent="space-between">
          <Box flex={1}>
            <NumericControl
              fill
              title={t`Latitude`}
              controlKey="lat"
              min={-90}
              max={90}
              precision={3}
              className={inputStyles.noSpinner}
              onChange={this.onChangeLngLat}
              tooltipText={latErrorMessage}
              value={lat}
            />
          </Box>
          <Box flex={1}>
            <NumericControl
              fill
              title={t`Longitude`}
              controlKey="lng"
              min={-180}
              max={180}
              precision={3}
              tooltipText={lngErrorMessage}
              className={inputStyles.noSpinner}
              onChange={this.onChangeLngLat}
              value={lng}
            />
          </Box>
          <Box flex={1} alignItems="center">
            {<span className={rootStyles.thickMarginTop}>{t`1:`}</span>}
            <NumericControl
              fill
              title={t`Scale`}
              controlKey="scale"
              className={inputStyles.noSpinner}
              onChange={this.onChangeScale}
              step={1}
              min={MIN_SCALE}
              max={MAX_SCALE}
              value={scale}
            />
          </Box>
        </Box>
      </React.Fragment>
    );
  }
}

function isValidLng(lng: number) {
  return lng >= -180 && lng <= 180;
}

function isValidLat(lat: number) {
  return lat >= -90 && lat <= 90;
}

function isValidLngLat(lnglat: LngLat) {
  return lnglat && isValidLng(lnglat.lng) && isValidLat(lnglat.lat);
}
