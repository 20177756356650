import { createSelector } from 'reselect';

import { EMPTY_OBJECT } from 'uf/base';
import { makeGetFromPropsSelector } from 'uf/base/selector';
import { LayerId } from 'uf/layers';

import { getLayersState } from './layers';

export const getAllLayerVersions = createSelector(
  getLayersState,
  ({ versions }) => versions || (EMPTY_OBJECT as Record<string, string>),
);

export function makeGetAllLayerVersions() {
  return createSelector(getAllLayerVersions, layerVersions => layerVersions);
}

export function makeGetLayerVersion() {
  return createSelector(
    getAllLayerVersions,
    makeGetFromPropsSelector<LayerId, 'layerId'>('layerId'),
    (layersVersion, layerId) => layersVersion[layerId],
  );
}

export function makeGetLayerVersionGetter() {
  return createSelector(
    getAllLayerVersions,
    layersVersions => (layerId: LayerId) => layersVersions[layerId],
  );
}

export function makeGetLayerVersions() {
  return createSelector(
    getAllLayerVersions,
    makeGetFromPropsSelector<LayerId[], 'layerIds'>('layerIds'),
    (allLayerVersions, layerIds) =>
      layerIds.map(layerId => allLayerVersions[layerId]),
  );
}
