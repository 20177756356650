import { Marker } from 'mapbox-gl';
import { AnyAction } from 'redux';

import {
  CLEAR_SEARCH_MARKER,
  SET_SEARCH_MARKER,
  SetSearchMarkerAction,
} from 'uf/explore/ActionTypes';

const initialState = {};

export default function markers(
  state: any = initialState,
  action: AnyAction | SetSearchMarkerAction,
): Record<string, Marker> {
  switch (action.type) {
    case SET_SEARCH_MARKER:
      return {
        ...state,
        search: (action as SetSearchMarkerAction).marker,
      };

    case CLEAR_SEARCH_MARKER: {
      const { search, ...rest } = state;
      return rest || initialState;
    }

    default:
      return state;
  }
}
