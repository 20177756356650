import _ from 'lodash';
import { AnyAction } from 'redux';
import { ActionsObservable, Epic, ofType } from 'redux-observable';
import {
  concat,
  defer,
  merge as observableMerge,
  of as observableOf,
} from 'rxjs';
import { filter, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { t } from 'ttag';

import { LayerStats } from 'uf-api';
import { getLayerStatsActionTypes } from 'uf-api/api/layer.service';
import { enqueueMapExportActionTypes } from 'uf-api/api/mapexport.service';
import { addAppMessage } from 'uf/appservices/actions';
import { getAPIUrl } from 'uf/base/api';
import { combineEpics } from 'uf/base/epics';
import { getData } from 'uf/data/dataState';
import { makeEnsureFetchStream } from 'uf/data/getFetchStream';
import { LayerId } from 'uf/layers';
import { fetchLayerStats } from 'uf/layers/apis';
import {
  FilterSpec,
  getStatsSearchKey,
  getLayerDataApiParams,
  LayerDataParams,
  StatsParams,
} from 'uf/layers/filters';
import { makeGetLayerStatsByKey } from 'uf/layers/selectors/stats';
import { makeGetLayerVersion } from 'uf/layers/selectors/versions';
import { toBoundingBox } from 'uf/map/filters';
import {
  downloadMapFromUrl,
  setMapExportNumGeometries as setMapExportNumGeometriesAction,
} from 'uf/mapexport/actions';
import { UFState } from 'uf/state';
import { bindTaskToResource } from 'uf/tasks/actions';
import { NotificationAction } from 'uf/tasks/ActionTypes';
import { NotificationTypes } from 'uf/tasks/NotificationTypes';
import { makeWebsocketActionType } from 'uf/websockets/actionHelpers';

import {
  BIND_MAPEXPORT_TASK,
  UPDATE_MAP_EXPORT_VIEWPORT,
  UpdateMapExportViewportAction,
} from './ActionTypes';
import { ExportMapTaskMessage } from './tasks';

export function bindMapExportTaskToProject(
  action$: ActionsObservable<AnyAction>,
) {
  return action$
    .ofType(enqueueMapExportActionTypes.SUCCESS)
    .pipe(
      map(({ result: taskId, projectId }) =>
        bindTaskToResource(BIND_MAPEXPORT_TASK, taskId, projectId),
      ),
    );
}

export const showExportMapStartedToast: Epic<
  NotificationAction<ExportMapTaskMessage>,
  any
> = action$ =>
  action$
    .ofType(makeWebsocketActionType(NotificationTypes.MAPEXPORT_STARTED))
    .pipe(
      map(({ result }) => {
        return addAppMessage(
          t`Exporting map. You will be notified once export is complete.`,
          {
            status: 'running',
            replacesMessage: result.uf_task_id,
            timeout: 0,
          },
        );
      }),
    );

export const showExportMapDoneToast: Epic<
  NotificationAction<ExportMapTaskMessage>,
  any
> = action$ =>
  action$
    .ofType(makeWebsocketActionType(NotificationTypes.MAPEXPORT_DONE))
    .pipe(
      map(({ result }) => {
        const { project_path: projectId, export_key: exportKey } = result;
        // TODO: move this to a swagger API call.
        const url = getAPIUrl(
          `mapexport/download${projectId}?export_key=${exportKey}`,
        );

        const onClickDownload = () => downloadMapFromUrl(url);

        return addAppMessage(
          t`Map export is complete. Click here to download the result.`,
          {
            level: 'info',
            status: 'success',
            action: {
              text: t`Download`,
              onClick: onClickDownload,
            },
            replacesMessage: result.uf_task_id,
            timeout: 0,
          },
        );
      }),
    );

export const showExportMapErrorToast: Epic<
  NotificationAction<ExportMapTaskMessage>,
  any
> = action$ =>
  action$
    .ofType(makeWebsocketActionType(NotificationTypes.MAPEXPORT_ERROR))
    .pipe(
      map(({ result }) => {
        return addAppMessage(t`There was an error generating your map.`, {
          level: 'danger',
          status: 'failure',
          replacesMessage: result.uf_task_id,
          timeout: 0,
        });
      }),
    );

export const updateMapExportNumGeometries: Epic<
  UpdateMapExportViewportAction,
  any
> = (action$, state$, { client }) => {
  const getLayerVersion = makeGetLayerVersion();
  const getLayerStats = makeGetLayerStatsByKey();
  return action$.pipe(
    ofType(UPDATE_MAP_EXPORT_VIEWPORT),
    filter(({ viewport }) => !_.isEmpty(viewport.bounds)),
    withLatestFrom(state$),
    mergeMap(([{ viewport, layerIds }, state]) => {
      const { bounds } = viewport;
      const bbox = toBoundingBox(bounds);
      const filters: Partial<FilterSpec> = {
        geometries: { bbox: [bbox] },
      };
      const paramsByLayerId = Object.fromEntries(
        layerIds.map(layerId => {
          const version = getLayerVersion(state, { layerId });
          const params: StatsParams = {
            version,
            filters,
            columns: [],
            row_count: true,
          };
          return [layerId, params];
        }),
      );

      const statsStreams = layerIds.map(layerId =>
        ensureLayerStatsStream(action$, state$, client, {
          layerId,
          params: paramsByLayerId[layerId],
          key: getStatsSearchKey(layerId, paramsByLayerId[layerId]),
        }),
      );

      return concat(
        observableMerge(...statsStreams),
        defer(() => {
          let count = 0;
          layerIds.forEach(layerId => {
            const layerStatsState = getLayerStats(state$.value, {
              key: getStatsSearchKey(layerId, paramsByLayerId[layerId]),
            });
            const layerStats = getData(layerStatsState);
            if (layerStats) {
              count += layerStats.row_count;
            }
          });
          return observableMerge(
            observableOf(setMapExportNumGeometriesAction(count)),
          );
        }),
      );
    }),
  );
};

interface OwnPropsForSelector {
  key: string;
}
interface LayerApiParams {
  layerId: LayerId;
  params: LayerDataParams;
}

type Params = OwnPropsForSelector & LayerApiParams;
type Extra = OwnPropsForSelector & LayerApiParams;

const ensureLayerStatsStream = makeEnsureFetchStream<
  UFState,
  Params,
  LayerStats,
  Extra
>(
  getLayerStatsActionTypes,
  makeGetLayerStatsByKey,
  ({ layerId, params }) => {
    const { apiParams } = getLayerDataApiParams(layerId, params);
    return fetchLayerStats(layerId, apiParams);
  },
  ({ key, layerId, params }) => {
    return { layerId, params, key };
  },
);

export default combineEpics(
  {
    bindMapExportTaskToProject,
    showExportMapStartedToast,
    showExportMapDoneToast,
    showExportMapErrorToast,
    updateMapExportNumGeometries,
  },
  'mapexport',
);
