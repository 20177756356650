import {
  CuratedColumnSymbology,
  InterpolationTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/public/dataset/zoning_san_francisco': {
    zoning: [
      {
        display_hints: {
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          scale: SymbologyScales.CATEGORICAL,
          theme: 'custom',
        },
        paint: {
          'fill-color': {
            default: '#7f7f7f',
            property: 'zoning',
            stops: [
              {
                color: '#f2a0a2',
                value: 'C-2',
              },
              {
                color: '#e9494e',
                value: 'C-3-G',
              },
              {
                color: '#e9494e',
                value: 'C-3-O',
              },
              {
                color: '#e72834',
                value: 'C-3-O(SD)',
              },
              {
                color: '#ec6368',
                value: 'C-3-R',
              },
              {
                color: '#ee8084',
                value: 'C-3-S',
              },
              {
                color: '#c2e4e3',
                value: 'CCB',
              },
              {
                color: '#c1e4e3',
                value: 'CRNC',
              },
              {
                color: '#c3e6ef',
                value: 'CVR',
              },
              {
                color: '#d49ebb',
                value: 'HP-RA',
              },
              {
                color: '#9fa1ac',
                value: 'M-1',
              },
              {
                color: '#8f92a7',
                value: 'M-2',
              },
              {
                color: '#e179c1',
                value: 'MB-O',
              },
              {
                color: '#aebf8c',
                value: 'MB-OS',
              },
              {
                color: '#c9886b',
                value: 'MB-RA',
              },
              {
                color: '#dca055',
                value: 'MUG',
              },
              {
                color: '#eca267',
                value: 'MUO',
              },
              {
                color: '#f4a37b',
                value: 'MUR',
              },
              {
                color: '#d9c1d9',
                value: 'NC-1',
              },
              {
                color: '#c8a3c9',
                value: 'NC-2',
              },
              {
                color: '#b989b9',
                value: 'NC-3',
              },
              {
                color: '#a22d81',
                value: 'NCD-24TH-NOE-VALLEY',
              },
              {
                color: '#a22d81',
                value: 'NCD-BROADWAY',
              },
              {
                color: '#a22d81',
                value: 'NCD-CASTRO',
              },
              {
                color: '#a22d81',
                value: 'NCD-EXCELSIOR',
              },
              {
                color: '#a22d81',
                value: 'NCD-HAIGHT',
              },
              {
                color: '#a22d81',
                value: 'NCD-INNER CLEMENT',
              },
              {
                color: '#a22d81',
                value: 'NCD-INNER SUNSET',
              },
              {
                color: '#4113d2',
                value: 'NCD-IRVING',
              },
              {
                color: '#a22d81',
                value: 'NCD-JAPANTOWN',
              },
              {
                color: '#a22d81',
                value: 'NCD-JUDAH',
              },
              {
                color: '#a22d81',
                value: 'NCD-NORIEGA',
              },
              {
                color: '#a22d81',
                value: 'NCD-NORTH BEACH',
              },
              {
                color: '#a22d81',
                value: 'NCD-OUTER CLEMENT',
              },
              {
                color: '#a22d81',
                value: 'NCD-PACIFIC',
              },
              {
                color: '#a22d81',
                value: 'NCD-POLK',
              },
              {
                color: '#a22d81',
                value: 'NCD-SACRAMENTO',
              },
              {
                color: '#a22d81',
                value: 'NCD-TARAVAL',
              },
              {
                color: '#a22d81',
                value: 'NCD-UNION',
              },
              {
                color: '#a22d81',
                value: 'NCD-UPPER FILLMORE',
              },
              {
                color: '#a22d81',
                value: 'NCD-UPPER MARKET',
              },
              {
                color: '#a22d81',
                value: 'NCD-WEST PORTAL',
              },
              {
                color: '#ad72ad',
                value: 'NC-S',
              },
              {
                color: '#a8318f',
                value: 'NCT-1',
              },
              {
                color: '#a74496',
                value: 'NCT-2',
              },
              {
                color: '#a8318f',
                value: 'NCT-24TH-MISSION',
              },
              {
                color: '#a32882',
                value: 'NCT-3',
              },
              {
                color: '#a8318f',
                value: 'NCT-DIVISADERO',
              },
              {
                color: '#a8318f',
                value: 'NCT-FILLMORE',
              },
              {
                color: '#a8318f',
                value: 'NCT-FOLSOM',
              },
              {
                color: '#a8318f',
                value: 'NCT-GLEN PARK',
              },
              {
                color: '#a8318f',
                value: 'NCT-HAYES',
              },
              {
                color: '#a8318f',
                value: 'NCT-MISSION',
              },
              {
                color: '#a8318f',
                value: 'NCT-OCEAN',
              },
              {
                color: '#a8318f',
                value: 'NCT-SOMA',
              },
              {
                color: '#a8318f',
                value: 'NCT-UPPER MARKET',
              },
              {
                color: '#a8318f',
                value: 'NCT-VALENCIA',
              },
              {
                color: '#898975',
                value: 'P',
              },
              {
                color: '#8087a6',
                value: 'PDR-1-B',
              },
              {
                color: '#6f79a4',
                value: 'PDR-1-D',
              },
              {
                color: '#616ca0',
                value: 'PDR-1-G',
              },
              {
                color: '#5261a0',
                value: 'PDR-2',
              },
              {
                color: '#5f8ca6',
                value: 'PM-CF',
              },
              {
                color: '#4d1d12',
                value: 'PM-MU1',
              },
              {
                color: '#a9472b',
                value: 'PM-MU2',
              },
              {
                color: '#aebf8c',
                value: 'PM-OS',
              },
              {
                color: '#fee8bf',
                value: 'PM-R',
              },
              {
                color: '#e2ecf1',
                value: 'PM-S',
              },
              {
                color: '#f4c163',
                value: 'RC-3',
              },
              {
                color: '#f4b758',
                value: 'RC-4',
              },
              {
                color: '#f7bdbf',
                value: 'RCD',
              },
              {
                color: '#8fa525',
                value: 'RED',
              },
              {
                color: '#8fa525',
                value: 'RED-MX',
              },
              {
                color: '#faf3bb',
                value: 'RH-1',
              },
              {
                color: '#fcf5c5',
                value: 'RH-1(D)',
              },
              {
                color: '#faf2b1',
                value: 'RH-1(S)',
              },
              {
                color: '#f6e69a',
                value: 'RH-2',
              },
              {
                color: '#f09647',
                value: 'RH-3',
              },
              {
                color: '#f7e290',
                value: 'RH DTR',
              },
              {
                color: '#f6dc89',
                value: 'RM-1',
              },
              {
                color: '#f8d57d',
                value: 'RM-2',
              },
              {
                color: '#f5cb73',
                value: 'RM-3',
              },
              {
                color: '#f2ad58',
                value: 'RM-4',
              },
              {
                color: '#f0a04d',
                value: 'RTO',
              },
              {
                color: '#f2a143',
                value: 'RTO-M',
              },
              {
                color: '#c4a03c',
                value: 'SALI',
              },
              {
                color: '#f18b3f',
                value: 'SB-DTR',
              },
              {
                color: '#a9f186',
                value: 'SLI',
              },
              {
                color: '#41a42a',
                value: 'SPD',
              },
              {
                color: '#fa1729',
                value: 'SSO',
              },
              {
                color: '#f07f3c',
                value: 'TB DTR',
              },
              {
                color: '#f4a686',
                value: 'UMU',
              },
              {
                color: '#e5a25e',
                value: 'WMUG',
              },
              {
                color: '#efa371',
                value: 'WMUO',
              },
            ],
            type: 'categorical',
          },
          'fill-opacity': 0.9,
        },
        type: SymbologyTypes.FILL,
      },
      {
        display_hints: {
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          scale: SymbologyScales.CATEGORICAL,
          theme: 'custom',
        },
        paint: {
          'line-color': 'transparent',
          'line-opacity': 0.9,
          'line-width': 1,
        },
        type: SymbologyTypes.LINE,
      },
    ],
    zoning_sim: [
      {
        display_hints: {
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          scale: SymbologyScales.CATEGORICAL,
          theme: 'custom',
        },
        paint: {
          'fill-color': {
            default: '#2ca02c',
            property: 'zoning_sim',
            stops: [
              {
                color: '#f2a0a2',
                value: 'C-2',
              },
              {
                color: '#e9494e',
                value: 'C-3-G',
              },
              {
                color: '#e8343b',
                value: 'C-3-O',
              },
              {
                color: '#e72834',
                value: 'C-3-O(SD)',
              },
              {
                color: '#ec6368',
                value: 'C-3-R',
              },
              {
                color: '#ee8084',
                value: 'C-3-S',
              },
              {
                color: '#c1e4e3',
                value: 'CCB',
              },
              {
                color: '#c1e4e3',
                value: 'CRNC',
              },
              {
                color: '#c3e6ef',
                value: 'CVR',
              },
              {
                color: '#d49ebb',
                value: 'HP-RA',
              },
              {
                color: '#9fa1ac',
                value: 'M-1',
              },
              {
                color: '#8f92a7',
                value: 'M-2',
              },
              {
                color: '#e179c1',
                value: 'MB-O',
              },
              {
                color: '#c9886b',
                value: 'MB-OS',
              },
              {
                color: '#dca055',
                value: 'MB-RA',
              },
              {
                color: '#dca055',
                value: 'MUG',
              },
              {
                color: '#eca267',
                value: 'MUO',
              },
              {
                color: '#f4a37b',
                value: 'MUR',
              },
              {
                color: '#d9c1d9',
                value: 'NC-1',
              },
              {
                color: '#c8a3c9',
                value: 'NC-2',
              },
              {
                color: '#b989b9',
                value: 'NC-3',
              },
              {
                color: '#c65bd0',
                value: 'NCD',
              },
              {
                color: '#ab73ab',
                value: 'NC-S',
              },
              {
                color: '#a22d81',
                value: 'NCT',
              },
              {
                color: '#a65299',
                value: 'NCT-1',
              },
              {
                color: '#a54694',
                value: 'NCT-2',
              },
              {
                color: '#a8318f',
                value: 'NCT-3',
              },
              {
                color: '#898975',
                value: 'P',
              },
              {
                color: '#8087a6',
                value: 'PDR-1-B',
              },
              {
                color: '#6f79a4',
                value: 'PDR-1-D',
              },
              {
                color: '#616ca0',
                value: 'PDR-1-G',
              },
              {
                color: '#5261a0',
                value: 'PDR-2',
              },
              {
                color: '#5f8ca6',
                value: 'PM-CF',
              },
              {
                color: '#4d1d12',
                value: 'PM-MU1',
              },
              {
                color: '#a9472b',
                value: 'PM-MU2',
              },
              {
                color: '#aebf8c',
                value: 'PM-OS',
              },
              {
                color: '#fee8bf',
                value: 'PM-R',
              },
              {
                color: '#d8e5ec',
                value: 'PM-S',
              },
              {
                color: '#f4c163',
                value: 'RC-3',
              },
              {
                color: '#f4b758',
                value: 'RC-4',
              },
              {
                color: '#f7bdbf',
                value: 'RCD',
              },
              {
                color: '#8fa525',
                value: 'RED',
              },
              {
                color: '#8fa525',
                value: 'RED-MX',
              },
              {
                color: '#faf3bb',
                value: 'RH-1',
              },
              {
                color: '#fcf5c5',
                value: 'RH-1(D)',
              },
              {
                color: '#faf2b1',
                value: 'RH-1(S)',
              },
              {
                color: '#faf0a7',
                value: 'RH-2',
              },
              {
                color: '#f6e69a',
                value: 'RH-3',
              },
              {
                color: '#f09647',
                value: 'RH DTR',
              },
              {
                color: '#f7e290',
                value: 'RM-1',
              },
              {
                color: '#f6dc89',
                value: 'RM-2',
              },
              {
                color: '#f8d57d',
                value: 'RM-3',
              },
              {
                color: '#f5cb73',
                value: 'RM-4',
              },
              {
                color: '#f2ad58',
                value: 'RTO',
              },
              {
                color: '#f0a04d',
                value: 'RTO-M',
              },
              {
                color: '#c39f45',
                value: 'SALI',
              },
              {
                color: '#f18b3f',
                value: 'SB-DTR',
              },
              {
                color: '#a9f186',
                value: 'SLI',
              },
              {
                color: '#41a42a',
                value: 'SPD',
              },
              {
                color: '#fa1729',
                value: 'SSO',
              },
              {
                color: '#f07f3c',
                value: 'TB DTR',
              },
              {
                color: '#f4a686',
                value: 'UMU',
              },
              {
                color: '#e5a25e',
                value: 'WMUG',
              },
              {
                color: '#efa371',
                value: 'WMUO',
              },
            ],
            type: 'categorical',
          },
          'fill-opacity': 0.9,
        },
        type: SymbologyTypes.FILL,
      },
      {
        display_hints: {
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          scale: SymbologyScales.CATEGORICAL,
          theme: 'custom',
        },
        paint: {
          'line-color': 'transparent',
          'line-opacity': 0.9,
          'line-width': 1,
        },
        type: SymbologyTypes.LINE,
      },
    ],
  },
};

export default symbologies;
