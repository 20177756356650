import { Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import warning from 'warning';

import { LayerMetadata } from 'uf-api';
import { addAppMessage } from 'uf/appservices/actions';
import { getData, hasError, isLoading, shouldLoad } from 'uf/data/dataState';
import * as ActionTypes from 'uf/explore/ActionTypes';
import {
  makeGetLayerSearchKey,
  makeGetProjectLayersMapStyles,
} from 'uf/explore/selectors/layers';
import { ColumnKey, LayerId } from 'uf/layers';
import { ensureLayerMetadata } from 'uf/layers/actions/metadata';
import { getLayerDataSearchKey, LayerDataParams } from 'uf/layers/filters';
import { ActiveColumnInfo } from 'uf/map/ActiveColumnInfo';
import { registerPersistedAction } from 'uf/persistence';
import { ProjectId } from 'uf/projects';
import { makeGetProjectLayerIdMap } from 'uf/projects/selectors/virtualLayers';
import { LegacyVirtualLayerId } from 'uf/projects/virtualLayers';
import { rollbarWarn } from 'uf/rollbar';
import { beginSearch } from 'uf/search/actions';
import { makeGetSearchResultsWithState } from 'uf/search/selectors';
import { RawRowData } from 'uf/search/state';
import { generateSymbologyLayerColumnKey } from 'uf/symbology/helpers';
import { extractErrorDetails } from 'uf/ui/base/errors';
import { ViewId } from 'uf/views';
import { setLayerMapColumnKey } from 'uf/views/actions/activeColumnKey';

export function setActiveLayer(
  projectId: ProjectId,
  virtualLayerId: LegacyVirtualLayerId,
  active = true,
): ActionTypes.SetActiveLayerAction {
  if (!virtualLayerId || !virtualLayerId.includes(':')) {
    // Do not pass the whole exception object, just pass the string, as rollbar
    // serialization seems to cause out-of-memory errors on the server
    rollbarWarn(
      `Malformed layerId passed to setActiveLayer: ${virtualLayerId}`,
    );
  }
  warning(!!virtualLayerId, `Missing virtual layer id for ${virtualLayerId}`);
  return {
    type: ActionTypes.SET_ACTIVE_LAYER,
    projectId,
    virtualLayerId,
    active,
  };
}

registerPersistedAction<
  ActionTypes.SetActiveLayerAction,
  string[],
  LegacyVirtualLayerId
>(
  'activeVLayer',
  ActionTypes.SET_ACTIVE_LAYER,
  ([projectId], virtualLayerId) => {
    return setActiveLayer(projectId, virtualLayerId);
  },
  {
    getKey({ projectId }) {
      return [projectId];
    },
    getValue({ active, virtualLayerId }, state) {
      return active ? virtualLayerId : null;
    },
  },
);

export function clearActiveLayer(
  projectId: ProjectId,
): ActionTypes.ClearActiveLayerAction {
  return {
    type: ActionTypes.CLEAR_ACTIVE_LAYER,
    projectId,
  };
}

export function setLayerSearchKey(
  projectId: ProjectId,
  layerId: LayerId,
  searchKey: string,
): ActionTypes.SetLayerSearchKeyAction {
  return {
    type: ActionTypes.SET_LAYER_SEARCH_KEY,
    projectId,
    layerId,
    searchKey,
  };
}

// Like setLayerMapColumnKey, but if one is not set, it will look it up and set it.
export function ensureLayerMapColumnKey(
  projectId: ProjectId,
  viewId: ViewId,
  layerId: LayerId,
): ThunkAction<Promise<string>, any, any, any> {
  const getActiveProjectLayersMapStyles = makeGetProjectLayersMapStyles();
  const getProjectLayerIdMap = makeGetProjectLayerIdMap();
  return async (dispatch: Dispatch, getState) => {
    const virtualLayerId: LegacyVirtualLayerId = getProjectLayerIdMap(
      getState(),
      { projectId },
    )[layerId];

    const layerMetadata = await dispatch(ensureLayerMetadata(layerId));
    const layerMapStyles = getActiveProjectLayersMapStyles(getState(), {
      projectId,
      viewId,
    });
    const layerMapStyle: ActiveColumnInfo = layerMapStyles[virtualLayerId];
    // If a column has already been choosen then use that column.
    if (layerMapStyle?.activeColumnKey) {
      return layerMapStyle.activeColumnKey;
    }
    // Calculate the default column for the base or parent layer if one exists.
    let baseLayerId: string = null;
    if (layerMetadata.display_hints?.base_dataset) {
      baseLayerId = layerMetadata.display_hints.base_dataset;
    } else if (layerMetadata.display_hints?.parent) {
      baseLayerId = layerMetadata.display_hints.parent.full_path;
    }
    let columnKey: ColumnKey = null;
    // Get active column from base layer if one exists and
    // it isn't a buffered layer (hackily checked by checking if there is
    // is only a single column).
    if (baseLayerId && layerMetadata.columns.length > 1) {
      columnKey = generateSymbologyLayerColumnKey(
        baseLayerId,
        layerMetadata,
        layerMapStyle?.activeColumnKey,
      );
    }
    // If not column for the base layer, then generate for main layer.
    if (!columnKey) {
      columnKey = generateSymbologyLayerColumnKey(
        layerId,
        layerMetadata,
        layerMapStyle?.activeColumnKey,
      );
    }
    // There should always be a column key at this point.
    if (columnKey) {
      dispatch(
        setLayerMapColumnKey(virtualLayerId, columnKey, projectId, viewId),
      );
    }
    return columnKey;
  };
}

export function loadLayerData(
  layerId: LayerId,
  query: LayerDataParams,
  layerMetadata: LayerMetadata,
): ThunkAction<Promise<RawRowData[]>, any, any, any> {
  warning(!!layerMetadata, 'loading layer data requires layer metadata.');
  const getSearchResultsWithState = makeGetSearchResultsWithState();
  return async (dispatch: Dispatch, getState) => {
    try {
      const layerData = await dispatch(
        beginSearch(layerId, query, layerMetadata),
      );
      return getSearchResultsWithState(getState(), {
        searchKey: layerData.searchKey,
      }).promise;
    } catch (error) {
      dispatch(
        addAppMessage(extractErrorDetails(error).errorDetailRaw, {
          level: 'danger',
          status: 'failure',
        }),
      );
      return Promise.reject(error);
    }
  };
}

export function ensureLayerData(
  layerId: LayerId,
  query: LayerDataParams,
  projectId?: ProjectId,
): ThunkAction<Promise<RawRowData[]>, any, any, any> {
  // TODO: make this fatal, this should always fail.
  warning(
    !!query.version,
    `Missing version in data query for ${layerId}: results likely to be stale`,
  );
  const searchKey = `search:${getLayerDataSearchKey(layerId, query)}`;
  const getLayerSearchKey = makeGetLayerSearchKey();
  const getSearchResultsWithState = makeGetSearchResultsWithState();
  return async (dispatch: Dispatch, getState) => {
    const layerMetadata = await dispatch(ensureLayerMetadata(layerId));
    const resultsState = getSearchResultsWithState(getState(), {
      searchKey,
    });
    let layerData: Record<string, string>[];
    if (shouldLoad(resultsState)) {
      layerData = await dispatch(loadLayerData(layerId, query, layerMetadata));
    } else {
      if (isLoading(resultsState)) {
        return resultsState.promise;
      }
      if (hasError(resultsState)) {
        return Promise.reject(resultsState.error);
      }

      // Must already be loaded
      layerData = getData(resultsState);
    }

    // TODO: move this to an epic
    if (projectId) {
      const previousSearchKey = getLayerSearchKey(getState(), {
        layerId,
        projectId,
      });
      // the data exists now, so we set the search key manually if necessary

      if (searchKey !== previousSearchKey) {
        dispatch(setLayerSearchKey(projectId, layerId, searchKey));
      }
    }
    return layerData;
  };
}
