import { boundMethod } from 'autobind-decorator';
import _ from 'lodash';
import React, { CSSProperties, Fragment, PureComponent } from 'react';
import { VictoryAxis, VictoryLabel } from 'victory';
import { VictoryCommonProps } from 'victory-core';

import { GetComponentProps } from 'uf/ui/base/types';

import { AxisAdjustment, SumFormat } from './chart';
import { Text } from './Text';

interface BarValueLabelsProps<D> {
  data: D[];
  getDatumKey: (datum: D, index?: number) => string;
  keys: string[];
  getDatumValue: (datum: D, key: string) => number;
  horizontal: boolean;
  chartWidth: number;
  axisAdjustment: AxisAdjustment;
  style?: GetComponentProps<typeof VictoryAxis>['style'];

  // SVG props for the label
  fontFamily?: CSSProperties['fontFamily'];
  fontSize?: number;

  formatSum: (datum: D, sum: number) => string | SumFormat[];
  axisProps?: VictoryCommonProps;
}
// The only way to trigger zero labels
const NO_TICK_VALUES = [null];
/**
 * Value labels for bars.
 *
 * Currently labels the sum of all values in each series.
 */
export class BarValueLabels<D extends object> extends PureComponent<
  BarValueLabelsProps<D>
> {
  @boundMethod
  getValueLabel(datum: D) {
    const { keys, getDatumValue, formatSum } = this.props;
    const values = keys.map(key => getDatumValue(datum, key));
    const sum = _.sum(values);
    return formatSum(datum, sum);
  }
  render() {
    const {
      data,
      getDatumKey: getKey,
      keys,
      getDatumValue,
      horizontal,
      chartWidth,
      axisAdjustment,
      style,
      fontFamily,
      fontSize,
      axisProps,
      ...props
    } = this.props;
    const baseStyle: CSSProperties = {
      fontFamily,
      fontSize,
    };
    const { dy = 0 } = axisAdjustment as { dy: number };

    return (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <Fragment>
        {data.map((datum, i) => {
          const sumInfo = this.getValueLabel(datum);
          const label: string =
            typeof sumInfo === 'string'
              ? sumInfo
              : sumInfo
                  .map(({ text }) => text)
                  .filter(text => !!text)
                  .join(' ');
          const labelStyle =
            typeof sumInfo === 'string'
              ? baseStyle
              : sumInfo.map(({ style: sumStyle }) => ({
                  ...baseStyle,
                  ...sumStyle,
                }));

          const otherProps = {
            textComponent: (
              <Text
                chartWidth={chartWidth}
                rightPadding={0}
                fontSize={fontSize}
                fontFamily={fontFamily}
                yAdjustment={dy}
                label={label}
                alignRight
              />
            ),
          };

          const axisLabelComponent = (
            <VictoryLabel
              {...(otherProps as any)}
              x={horizontal ? chartWidth : 0}
              // work around missing types
              style={labelStyle}
              {...axisAdjustment}
            />
          );

          return (
            <VictoryAxis
              {...props}
              dependentAxis
              tickValues={NO_TICK_VALUES}
              // TODO: figure out why orientation is always bottom, even on vertical charts?
              orientation="bottom"
              animate={null}
              key={getKey(datum, i)}
              style={{
                ...style,
                tickLabels: { fill: 'none' },
                axisLabel: { textAnchor: 'end' },
                axis: { stroke: 'none' },
              }}
              label={label}
              axisLabelComponent={axisLabelComponent}
              axisValue={getKey(datum)}
            />
          );
        })}
      </Fragment>
    );
  }
}
