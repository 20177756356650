import _ from 'lodash';
import { Dispatch } from 'redux';

import WebglSpecs from 'uf/ui/base/webgl';
import { getUser } from 'uf/user/selectors/user';

import * as amplitudeActionTypes from './ActionTypes';
import { getAmplitude, getAmplitudeApiEndpoint, getAmplitudeId } from './utils';

const amp = getAmplitude();
const ampId = getAmplitudeId();
const apiEndpoint = getAmplitudeApiEndpoint();

export function initializeAmplitude(amplitude = amp, amplitudeId = ampId) {
  return (dispatch: Dispatch, getState) => {
    if (!(__CLIENT__ || __TESTING__)) {
      return;
    }

    const user = getUser(getState());

    // Don't initialize Amplitude if the user is configured
    // to disallow event logging.
    if (user.disable_user_log) {
      return;
    }

    const webgl = WebglSpecs();
    const settings: any = {};
    if (apiEndpoint) {
      settings.serverUrl = apiEndpoint;
    }
    if (amplitude) {
      if (_.isEmpty(user)) {
        amplitude.init(amplitudeId, null, settings);
      } else {
        const userProperties = {
          key: user.key,
          first_name: user.first_name,
          last_name: user.last_name,
          email: user.email,
          num_organizations: user.organizations.length,
          // amplitude ignores values in lists that aren't strings or numbers
          organizations: user.organizations.map(
            org => `(${org.name}, ${org.key})`,
          ),
          username: user.username,
          uf_frontend_version: __BUILD_INFO__.git_commit,
          webgl: JSON.stringify(webgl),
        };
        // This isn't always present, but if it is we want to call it out
        // separately for amplitude data collection
        if (webgl && 'majorPerformanceCaveat' in webgl) {
          userProperties['webgl.majorPerformanceCaveat'] =
            webgl.majorPerformanceCaveat;
        }

        amplitude.init(amplitudeId, user.email, settings);
        const identifyEvent = new amplitude.Identify();
        Object.keys(userProperties).forEach(key => {
          identifyEvent.set(key, userProperties[key]);
        });
        amplitude.identify(identifyEvent);
      }
    }

    dispatch({
      type: amplitudeActionTypes.INITIALIZE,
    });
  };
}
