import colors from 'material-colors';

import {
  CuratedColumnSymbology,
  CUSTOM_THEME,
  DEFAULT_OPACITY,
  DistributionTypes,
  InterpolationTypes,
  PaintColorTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/public/dataset/national_electric_power_sales_by_state': {
    elec_sales_residential: [
      {
        type: SymbologyTypes.FILL,
        paint: {
          'fill-color': {
            property: 'elec_sales_residential',
            type: PaintColorTypes.INTERVAL,
            stops: [
              { value: 0.1, label: '0 to 14,999', color: '#fef2c0' },
              { value: 15000, label: '15,000 to 29,999', color: '#fdd2a0' },
              { value: 30000, label: '30,000 to 44,999', color: '#fac38e' },
              { value: 45000, label: '45,000 to 59,999', color: '#f8b57d' },
              { value: 60000, label: '60,000 to 74,999', color: '#f5a76b' },
              { value: 75000, label: '75,000 to 89,999', color: '#f29859' },
              { value: 90000, label: '90,000 to 104,999', color: '#f08a47' },
              { value: 105000, label: '105,000 to 119,999', color: '#ed7c35' },
              { value: 120000, label: '120,000 to 134,999', color: '#eb6e24' },
              { value: 135000, label: '135,000 to 149,999', color: '#e85f12' },
              { value: 150000, label: '150,000', color: colors.orange[900] },
            ],
          },
          'fill-opacity': DEFAULT_OPACITY,
        },
        display_hints: {
          scale: SymbologyScales.NUMERIC,
          distribution: DistributionTypes.DEFAULT,
          interpolation: InterpolationTypes.INTERVAL,
          theme: CUSTOM_THEME,
        },
      },
    ],
  },
};

export default symbologies;
