import { Dispatch } from 'redux';
import { push } from 'redux-first-router';
import { ThunkAction } from 'redux-thunk';

import { getCurrentUserActionTypes } from 'uf-api/api/authentication.service';
import { getSignInUrlBase } from 'uf/base/api';
import { makeRequestId } from 'uf/data/helpers';
import { dispatchAsyncAction, makeEnsureActionCreator } from 'uf/data/loader';
import { authenticationActionTypes } from 'uf/user/ActionTypes';
import { getUserInfoState } from 'uf/user/selectors/user';

import { getCurrentUser, login, logout } from './apis';

export function loginUser(email: string, password: string) {
  const loginThunk = (dispatch: Dispatch, getState: any, { client }: any) =>
    login({ email, password })(client);

  return async (dispatch: Dispatch) => {
    const requestId = makeRequestId();
    dispatch({ type: authenticationActionTypes.LOAD, requestId });

    try {
      const result = await dispatch(loginThunk);
      dispatch({ type: authenticationActionTypes.SUCCESS, result, requestId });
      push('/explore');
    } catch (error) {
      dispatch({ type: authenticationActionTypes.FAILURE, error, requestId });
      throw error;
    }
  };
}

// TODO: Refactor this to an epic
export function logoutUser() {
  const logoutThunk: ThunkAction<Promise<boolean>, any, any, any> = (
    dispatch,
    getState,
    { client },
  ) => logout({})(client);

  return async (dispatch: Dispatch) => {
    const requestId = makeRequestId();
    dispatch({ type: authenticationActionTypes.LOAD, requestId });

    try {
      const result = await dispatch(logoutThunk);
      dispatch({ type: authenticationActionTypes.CLEAR, result, requestId });
      return dispatch(() => {
        if (__CLIENT__) {
          window.location.href = getSignInUrlBase();
        } else {
          push(getSignInUrlBase());
        }
      });
    } catch (error) {
      dispatch({ type: authenticationActionTypes.FAILURE, error, requestId });
      throw error;
    }
  };
}

export function loadCurrentUser() {
  return dispatchAsyncAction(getCurrentUserActionTypes, getCurrentUser({}));
}

export const ensureCurrentUser = makeEnsureActionCreator(
  loadCurrentUser,
  getUserInfoState,
);
