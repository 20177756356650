import { createSelector } from 'reselect';

import { makeGetExploreUIProperty } from 'uf/explore/selectors/explore';
import { makeGetLayerMetadata } from 'uf/layers/selectors/metadata';

export const getActiveAnalysisModuleKey = makeGetExploreUIProperty(
  'activeAnalysisModuleKey',
  null,
);

export function makeGetLayerModuleKey() {
  const getLayerMetadata = makeGetLayerMetadata();
  return createSelector(getLayerMetadata, (layerMetadata): string => {
    const { display_hints: displayHints = {} } = layerMetadata;

    const { module_key: moduleKey } = displayHints;

    return moduleKey;
  });
}
