import { Button, ButtonGroup, FormGroup } from '@blueprintjs/core';
import React, { useCallback } from 'react';
import { t } from 'ttag';

import { BoundarySources } from 'uf/projects';
import rootStyles from 'uf/styles/root.module.css';

interface BoundarySourceChoicesProps {
  boundarySource?: BoundarySources;
  onBoundarySourceChange?: (boundarySource: BoundarySources) => void;
  haveSourceProject?: boolean;
  allowUpload?: boolean;
}

const BoundarySourceChoices = ({
  boundarySource,
  haveSourceProject,
  allowUpload,
  onBoundarySourceChange,
}: BoundarySourceChoicesProps): JSX.Element => {
  const onSetWellKnownBoundarySource = useCallback(() => {
    if (onBoundarySourceChange) {
      onBoundarySourceChange(BoundarySources.WELL_KNOWN);
    }
  }, [onBoundarySourceChange]);
  const onSetUserUploadBoundarySource = useCallback(() => {
    if (onBoundarySourceChange) {
      onBoundarySourceChange(BoundarySources.USER_UPLOAD);
    }
  }, [onBoundarySourceChange]);
  const onSetUserSourceProjectBoundarySource = useCallback(() => {
    if (onBoundarySourceChange) {
      onBoundarySourceChange(BoundarySources.SOURCE_PROJECT);
    }
  }, [onBoundarySourceChange]);
  return (
    <FormGroup className={rootStyles.thinPaddingBottom}>
      <ButtonGroup>
        <Button
          active={boundarySource === BoundarySources.WELL_KNOWN}
          className={'CreateProjectForm-availableBoundaries'}
          onClick={onSetWellKnownBoundarySource}>
          {t`Select from available boundaries`}
        </Button>
        {allowUpload && (
          <Button
            active={boundarySource === BoundarySources.USER_UPLOAD}
            className={'CreateProjectForm-uploadPolygon'}
            onClick={onSetUserUploadBoundarySource}>
            {t`Upload a polygon boundary`}
          </Button>
        )}
        {haveSourceProject && (
          <Button
            active={boundarySource === BoundarySources.SOURCE_PROJECT}
            className={'CreateProjectForm-sourceProject'}
            onClick={onSetUserSourceProjectBoundarySource}>
            {t`Use existing project`}
          </Button>
        )}
      </ButtonGroup>
    </FormGroup>
  );
};

export default BoundarySourceChoices;
