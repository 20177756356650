import * as d3Scale from 'd3-scale';
import { GeoJsonGeometryTypes } from 'geojson';
import _ from 'lodash';

import { LayerStats } from 'uf-api';
import { assertNever } from 'uf/base/never';
import { ColumnKey, LayerId } from 'uf/layers';
import { isNullCategory } from 'uf/layers/nullStats';
import { findColumnKeyStats } from 'uf/layers/stats';
import {
  CUSTOM_THEME,
  DataDrivenColorStop,
  DEFAULT_CIRCLE_RADIUS,
  DEFAULT_LINE_WIDTH,
  DEFAULT_NULL_COLOR_STOP,
  DEFAULT_OPACITY,
  InterpolationTypes,
  LayerColumnSymbology,
  PaintColor,
  PaintColorTypes,
  SymbologyDisplayHints,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';
import { makeStyleSymbologyId } from 'uf/symbology/spec/id';
import { makePaintProperty } from 'uf/symbology/spec/paint';
import { getCategories, SymbologyCategoryValue } from 'uf/symbology/stats';

export function createCategoricalSymbology(
  layerId: LayerId,
  columnKey: ColumnKey,
  columnStats: LayerStats,
  ufGeometryType: GeoJsonGeometryTypes,
): LayerColumnSymbology[] {
  if (!ufGeometryType) {
    return [];
  }

  const categories = makeCategories(layerId, columnKey, columnStats);
  if (!categories) {
    return [];
  }

  const paintColor = makeCategoricalPaintColorProperty(categories, columnKey);
  const paintProperties: PaintColor = { paintColor };

  let stopCount;
  if (typeof paintColor === 'string') {
    stopCount = 1;
  } else {
    stopCount = paintColor.stops.length;
  }
  const defaultTheme = getDefaultCategoricalTheme(stopCount);
  const displayHints = makeCategoricalDisplayHints(false, defaultTheme);

  switch (ufGeometryType) {
    case 'MultiPolygon':
    case 'Polygon':
      return [
        generateCategoricalFillSymbology(
          layerId,
          columnKey,
          paintProperties,
          displayHints,
        ),
        generateCategoricalLineSymbology(
          layerId,
          columnKey,
          paintProperties,
          displayHints,
        ),
      ];

    case 'MultiLineString':
    case 'LineString':
      return [
        generateCategoricalLineSymbology(
          layerId,
          columnKey,
          paintProperties,
          displayHints,
        ),
      ];

    case 'MultiPoint':
    case 'Point':
      return [
        generateCategoricalCircleSymbology(
          layerId,
          columnKey,
          paintProperties,
          displayHints,
        ),
      ];

    case 'GeometryCollection':
      return [];

    default:
      return assertNever(ufGeometryType);
  }
}
export function makeCategories(
  layerId: LayerId,
  columnKey: ColumnKey,
  columnStats: LayerStats,
) {
  const stats = findColumnKeyStats(columnStats, columnKey);
  if (!stats) {
    console.warn(
      `Could not get categorical stats for ${layerId} at column ${columnKey}`,
    );
    return [];
  }

  // This happens when there are too many different categorical values from stats.
  const categories = getCategories(columnStats, columnKey);
  if (!categories) {
    console.warn(
      `No categories found for layer ${layerId} at column ${columnKey}`,
    );
  }

  return categories;
}

export function generateCategoricalFillSymbology(
  layerId: LayerId,
  columnKey: ColumnKey,
  paintProperties: PaintColor,
  displayHints: SymbologyDisplayHints,
): LayerColumnSymbology {
  const { paintColor } = paintProperties;

  return {
    id: makeStyleSymbologyId(layerId, columnKey, SymbologyTypes.FILL),
    type: SymbologyTypes.FILL,
    paint: {
      'fill-color': paintColor,
      'fill-opacity': DEFAULT_OPACITY,
    },
    display_hints: displayHints,
  };
}

export function generateCategoricalLineSymbology(
  layerId: LayerId,
  columnKey: ColumnKey,
  paintProperties: PaintColor,
  displayHints: SymbologyDisplayHints,
): LayerColumnSymbology {
  const { paintColor } = paintProperties;

  return {
    id: makeStyleSymbologyId(layerId, columnKey, SymbologyTypes.LINE),
    type: SymbologyTypes.LINE,
    paint: {
      'line-color': paintColor,
      'line-width': DEFAULT_LINE_WIDTH,
      'line-opacity': DEFAULT_OPACITY,
    },
    display_hints: displayHints,
  };
}

export function generateCategoricalCircleSymbology(
  layerId: LayerId,
  columnKey: ColumnKey,
  paintProperties: PaintColor,
  displayHints: SymbologyDisplayHints,
): LayerColumnSymbology {
  const { paintColor } = paintProperties;

  return {
    id: makeStyleSymbologyId(layerId, columnKey, SymbologyTypes.CIRCLE),
    type: SymbologyTypes.CIRCLE,
    paint: {
      'circle-color': paintColor,
      'circle-opacity': DEFAULT_OPACITY,
      'circle-stroke-color': 'transparent',
      'circle-radius': DEFAULT_CIRCLE_RADIUS,
    },
    display_hints: displayHints,
  };
}

function getDefaultCategoricalTheme(stopCount: number) {
  return stopCount < 10 ? 'd3:schemeCategory10' : 'd3:schemeCategory20';
}

function getCategoricalColorScale(categories: string[]) {
  let colors: string[];
  const theme = getDefaultCategoricalTheme(categories.length);
  if (categories.length < 10) {
    colors = d3Scale.schemeCategory10;
  } else {
    colors = d3Scale.schemeCategory20;
  }
  const scale = d3Scale.scaleOrdinal(colors).domain(categories);
  return {
    theme,
    scale,
  };
}

function makeCategoricalPaintColorProperty(
  categories: SymbologyCategoryValue[],
  columnKey: ColumnKey,
) {
  const colorStops: DataDrivenColorStop[] = [];

  if (categories.find(category => isNullCategory(category))) {
    colorStops.push(DEFAULT_NULL_COLOR_STOP);
  }

  // The null category should default to transparent, so don't include it when
  // deciding on theme
  const unstyledCategories = categories.filter(
    category => !isNullCategory(category),
  ) as string[];

  const { scale } = getCategoricalColorScale(unstyledCategories);
  const defaultValue = scale(null);
  colorStops.push(
    ...unstyledCategories.map(value => {
      const categoryValue = getCategeoryValue(value);
      const colorStop: DataDrivenColorStop = {
        value: categoryValue,
        color: scale(value),
      };

      return colorStop;
    }),
  );

  const paintColor = makePaintProperty<DataDrivenColorStop, string>(
    colorStops,
    defaultValue,
    columnKey,
    PaintColorTypes.CATEGORICAL,
  );
  return paintColor;
}

function getCategeoryValue(value: string) {
  if (
    // Explicitly check for booleans, as `false` is a valid category not to be
    // confused with the other falsy values in Javascript, ie: `null` or `undefined`.
    _.isBoolean(value) ||
    _.isNumber(value) ||
    _.isString(value) ||
    isNullCategory(value)
  ) {
    return value;
  }

  return '';
}

function makeCategoricalDisplayHints(isTransparent: boolean, theme) {
  return {
    scale: SymbologyScales.CATEGORICAL,
    distribution: null,
    interpolation: InterpolationTypes.CATEGORICAL,
    theme: isTransparent ? CUSTOM_THEME : theme,
  };
}
