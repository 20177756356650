import { ButtonGroup } from '@blueprintjs/core';
import _ from 'lodash';
import React, { MouseEvent } from 'react';
import ProjectTypeButton from 'uf/ui/projects/CreateProjectDialog/ProjectTypeButton/ProjectTypeButton';
import { useProjectTypes } from 'uf/ui/projects/hooks';

interface OwnProps {
  handleSelect: (event: MouseEvent) => void;
  selectedProjectType: string;
}

const CreateProjectOptions = ({
  handleSelect,
  selectedProjectType,
}: OwnProps) => {
  const { projectTypes } = useProjectTypes();
  return (
    <aside style={{ paddingRight: 18, flex: '1 1 0' }}>
      <ButtonGroup vertical>
        {_.map(projectTypes, projectType => {
          return (
            <ProjectTypeButton
              key={projectType.key}
              projectType={projectType}
              selectedProjectType={selectedProjectType}
              handleSelect={handleSelect}
            />
          );
        })}
      </ButtonGroup>
    </aside>
  );
};

export default CreateProjectOptions;
