import _ from 'lodash';

import { ScenarioAnalysisModuleResults } from 'uf-api';
import { ModuleRunStatus } from 'uf/analysis';
import { ProjectId } from 'uf/projects';
import { ScenarioId } from 'uf/scenarios';

export interface AnalysisStatusState {
  [moduleKey: string]: Record<ProjectId, Record<ScenarioId, ModuleRunStatus>>;
}

// TODO: This should eventually be moved into a selector once we've sorted out
/**
 * getModuleRunStatus
 * Returns the status of per-scenario analysis runs.  Returns null to indicate
 * the module is not currently running.
 * @param moduleStatuses - The analysis statuses by module key.
 * @param moduleKey - The key of the module we want to find the status for.
 * @param projectId - The project id of the analysis run.
 */
export function getModuleRunStatusForProject(
  moduleStatuses: AnalysisStatusState,
  moduleKey: string,
  projectId: ProjectId,
): Record<string, ModuleRunStatus> {
  if (!moduleStatuses) {
    // No modules have ever started.
    return null;
  }
  const moduleStatus = moduleStatuses[moduleKey];
  if (!moduleStatus) {
    // This module hasn't ever started.
    return null;
  }

  const projectStatus = moduleStatus[projectId];
  if (!projectStatus) {
    // This module has never run for this project.
    return null;
  }

  return projectStatus;
}

export function getModuleRunStatus(
  moduleStatuses: AnalysisStatusState,
  moduleKey: string,
  projectId: ProjectId,
  scenarioId: ScenarioId,
) {
  const projectStatus = getModuleRunStatusForProject(
    moduleStatuses,
    moduleKey,
    projectId,
  );

  if (projectStatus === null) {
    return null;
  }

  // Here's where it gets tricky: If we have gotten this far, but
  // there is *no* status. This means we have kicked off an initial load
  // for all scenarios, but we haven't yet heard from the server. So
  // PENDING means we have requested to run the module, but haven't even gotten
  // an ANALYSIS_QUEUED from the server.
  if (_.isEmpty(projectStatus)) {
    return ModuleRunStatus.PENDING;
  }

  const scenarioStatus = projectStatus[scenarioId];

  if (!scenarioStatus) {
    return null;
  }

  return scenarioStatus;
}

// NOTE: Change this when we have more than one policy type (other than default).
export function getIsResultStale(result: ScenarioAnalysisModuleResults) {
  if (_.isEmpty(result)) {
    return false;
  }

  const defaultPolicy = result.policies.find(
    policy => policy.policy_key === 'default',
  );
  return defaultPolicy.stale;
}

const runningStates = new Set([
  ModuleRunStatus.PENDING,
  ModuleRunStatus.QUEUED,
  ModuleRunStatus.STARTED,
]);

export function getModuleIsRunning(moduleRunStatus: ModuleRunStatus) {
  return runningStates.has(moduleRunStatus);
}

export function getModuleIsDone(moduleRunStatus: ModuleRunStatus) {
  return moduleRunStatus === ModuleRunStatus.DONE;
}

export const moduleMethodologyLinks = {
  household_cost:
    'https://docs.urbanfootprint.com/private/analyst/en/index-en.html?contextId=householdcostsanalysis',
  tnc_conservation:
    'https://urbanfootprint.com/conservation-module-methodology/',
  energy_demand:
    'https://docs.urbanfootprint.com/private/analyst/en/index-en.html?contextId=energyanalysis',
  water_demand:
    'https://docs.urbanfootprint.com/private/analyst/en/index-en.html?contextId=wateruseanalysis',
  ghg_emission:
    'https://docs.urbanfootprint.com/private/analyst/en/index-en.html?contextId=emissionsanalysis',
  resiliency:
    'https://docs.urbanfootprint.com/private/analyst/en/index-en.html?contextId=riskresilienceanalysis',
  transit_accessibility:
    'https://docs.urbanfootprint.com/private/analyst/en/index-en.html?contextId=walktransitanalysis',
  transportation_anywhere:
    'https://docs.urbanfootprint.com/private/analyst/en/index-en.html?contextId=transportationanalysis',
  walk_accessibility:
    'https://docs.urbanfootprint.com/private/analyst/en/index-en.html?contextId=walktransitanalysis',
  land_consumption:
    'https://docs.urbanfootprint.com/private/analyst/en/index-en.html?contextId=landconsumptionanalysis',
};
