import colors from 'material-colors';
import { t } from 'ttag';
import warning from 'warning';

import {
  Building,
  BuildingInfo,
  BuildingType,
  BuildingTypeInfo,
  LayerColumn,
  LayerMetadata,
  PlaceType,
  PlaceTypeInfo,
} from 'uf-api/model/models';
import { EMPTY_ARRAY } from 'uf/base';
import { BuiltFormTypes } from 'uf/base/builtForms';
import { KeysMatching } from 'uf/base/types';
import { DataState, getData, isLoaded } from 'uf/data/dataState';
import { ColumnKey } from 'uf/layers';
import { isPaintedLayer } from 'uf/layers/helpers';

export type BuiltFormTypeKeys = 'building' | 'building_type' | 'place_type';

export type BuiltForm = Building | BuildingType | PlaceType;
export type BuiltFormInfo = BuildingInfo | BuildingTypeInfo | PlaceTypeInfo;
export type BuiltFormListState = Record<
  BuiltFormTypeKeys,
  Record<string, DataState<BuiltFormInfo[]>>
>;

export const BUILT_FORM_KEY: ColumnKey = 'built_form_key';

/**
 * Determine if this column key should be treated specially as the column that
 * supports the builtform keys.
 *
 * TODO: Make this somehow make use of ProjectMetadata and/or
 * VirtualLayerReference to know that this is the base canvas or the scenario
 * canvas.
 *
 * @param columnKey Column key
 */

export function isBuiltFormKey(columnKey: ColumnKey) {
  return columnKey === BUILT_FORM_KEY;
}

export const LAND_USE_CATEGORY_LEVEL_1 = 'land_use_category_level_1';
export const LAND_USE_CATEGORY_LEVEL_2 = 'land_use_category_level_2';
export const LAND_USE_CATEGORY_LEVEL_3 = 'land_use_category_level_3';
const LAND_USE_COLUMN_KEYS = [
  LAND_USE_CATEGORY_LEVEL_1,
  LAND_USE_CATEGORY_LEVEL_2,
  LAND_USE_CATEGORY_LEVEL_3,
  BUILT_FORM_KEY,
];

/**
 * Determine if this column key should be treated specially as a land
 * use-related column. Note that this does include BUILT_FORM_KEY, as there is
 * very little that depends on JUST the LAND_USE_* columns
 *
 * TODO: Make this somehow make use of ProjectMetadata and/or
 * VirtualLayerReference to know that this is the base canvas or the scenario
 * canvas.
 */
export function isLandUseColumnKey(
  layerMetadata: LayerMetadata,
  columnKey: ColumnKey,
) {
  return (
    isPaintedLayer(layerMetadata) && LAND_USE_COLUMN_KEYS.includes(columnKey)
  );
}

export function isCanvasBuiltFormKey(
  layerMetadata: LayerMetadata,
  columnKey: ColumnKey,
) {
  return isPaintedLayer(layerMetadata) && columnKey === BUILT_FORM_KEY;
}
/**
 * These are specialized properties that only exist on the baked-out builtform
 * table
 */
interface BuiltFormTableProps {
  /**
   * Defines building type vs placetype
   */
  built_form_type: BuiltFormTypes;
  /**
   * These are generated from
   */
  built_form_category?: string;

  /**
   * Land use category keys
   */
  land_use_category_level_1?: PlaceType.LandUseCategoryLevel1Enum;

  /**
   * Seems wrong - blg_area_det_sf?
   */
  building_area_detached_single_family?: number;

  /**
   * Seems wrong - bldg_area?
   */
  building_area_total?: number;

  /**
   * Seems wrong - floor_count?
   */
  building_avg_number_of_floors?: number;

  /**
   * Seems wrong - bldg_area_ind..?
   */
  bldg_area_industrial_non_warehouse?: number;

  /**
   * Seems wrong - bldg_efficiency_ratio_nonresidential or bldg_efficiency_ratio_residential ?
   */
  gross_net_ratio?: number;

  /**
   * Deprecated for `du_mf`
   */
  du_mf2to4?: number;

  /**
   * Deprecated for `du_mf`
   */
  du_mf5p?: number;

  color?: string;

  sort_column?: string;
}

export type BuiltFormType = BuildingType & PlaceType & Building;
/**
 * This is the type used for values from the builtform table.
 */
export type BuiltFormTableRow = (BuildingType | PlaceType) &
  BuiltFormTableProps;

export const UNIT_AREA_FINE = t`ft\u00b2`;

/**
 * Underscore-based types like `'building'` and `'building_type'`
 */
export enum BUILT_FORM_TYPES {
  BUILDING = 'building',
  BUILDING_TYPE = 'building_type',
  PLACE_TYPE = 'place_type',
}

export function isBuilding(
  builtForm: BuiltForm,
  builtFormType: BUILT_FORM_TYPES,
): builtForm is Building {
  return builtFormType === BUILT_FORM_TYPES.BUILDING;
}
export function isBuildingType(
  builtForm: BuiltForm,
  builtFormType: BUILT_FORM_TYPES,
): builtForm is BuildingType {
  return builtFormType === BUILT_FORM_TYPES.BUILDING_TYPE;
}

export function isPlaceType(
  builtForm: BuiltForm,
  builtFormType?: BUILT_FORM_TYPES,
): builtForm is PlaceType {
  if ('row_area_pct' in builtForm) {
    return true;
  }

  return builtFormType === BUILT_FORM_TYPES.PLACE_TYPE;
}

export function makeLibraryId(namespace: string, key: string) {
  return `/${namespace}/builtforms/${key}`;
}

export function makeLibraryKey(
  libraryId: string,
  builtFormType: BUILT_FORM_TYPES,
) {
  return `${libraryId}:${builtFormType}`;
}

export function makeBuiltFormKey(
  libraryId: string,
  builtFormType: BUILT_FORM_TYPES,
  builtFormKey: string,
) {
  return `${libraryId}:${builtFormType}:${builtFormKey}`;
}

export const BUILT_FORM_LABELS = {
  [BUILT_FORM_TYPES.BUILDING]: t`Building`,
  [BUILT_FORM_TYPES.BUILDING_TYPE]: t`Building Type`,
  [BUILT_FORM_TYPES.PLACE_TYPE]: t`Place Type`,
};

// buildings do no have a prefix as they do have not symbology.
const BUILT_FORM_SYMBOLOGY_PREFIXES = {
  [BUILT_FORM_TYPES.BUILDING_TYPE]: 'bt__',
  [BUILT_FORM_TYPES.PLACE_TYPE]: 'pt__',
};

// helper function that accepts an array of built form type objects and prefixes the keys so that
// we have unique keys (useful for the layer legend and symbology).
export function prefixBuiltFormTypesKeys(
  builtFormTypes: BuiltFormInfo[],
  builtFormTypePrefix: BUILT_FORM_TYPES,
) {
  if (!builtFormTypes) {
    return [];
  }

  const prefix = BUILT_FORM_SYMBOLOGY_PREFIXES[builtFormTypePrefix];

  warning(prefix, 'unknown builtform type, prefix not applied');
  if (!prefix) {
    return builtFormTypes;
  }

  return builtFormTypes.map(bt =>
    prefixBuiltFormTypeKey(bt, builtFormTypePrefix),
  );
}

export function prefixBuiltFormTypeKey(
  builtFormType: BuiltFormInfo,
  builtFormTypePrefix: BUILT_FORM_TYPES,
) {
  if (!builtFormType) {
    return builtFormType;
  }

  const prefix = BUILT_FORM_SYMBOLOGY_PREFIXES[builtFormTypePrefix];

  warning(prefix, 'unknown builtform type, prefix not applied');
  if (!prefix) {
    return builtFormType;
  }

  return {
    ...builtFormType,
    key: `${prefix}${builtFormType.key}`,
  };
}

export const EMPLOYMENT_DENSITIES = [
  {
    key: 'high',
    name: t`High`,
  },
  {
    key: 'mid',
    name: t`Medium`,
  },
  {
    key: 'low',
    name: t`Low`,
  },
];

export const LAND_DEVELOPMENT_CATEGORIES = [
  {
    key: 'urban',
    intersectionDensityKey: 'intersection_density_urban',
    name: t`Urban`,
  },
  {
    key: 'compact',
    intersectionDensityKey: 'intersection_density_compact',
    name: t`Compact`,
  },
  {
    key: 'standard',
    intersectionDensityKey: 'intersection_density_standard',
    name: t`Suburban`,
  },
  {
    key: 'rural',
    intersectionDensityKey: 'intersection_density_rural',
    name: t`Rural`,
  },
  {
    key: 'undeveloped',
    intersectionDensityKey: 'intersection_density_undeveloped',
    name: t`Undeveloped`,
  },
];

export enum LandUseCategoryLevel {
  LEVEL_1 = 'LEVEL_1',
  LEVEL_2 = 'LEVEL_2',
  LEVEL_3 = 'LEVEL_3',
}

export interface LandUseCategoryMetadata {
  key: string;
  name: string;
  color?: string;
  readonly?: boolean;

  header?: true;
}

export const LAND_USE_CATEGORIES_LEVEL_1: LandUseCategoryMetadata[] = [
  {
    key: 'mixed_use',
    name: t`Mixed use`,
    color: '#dc41b8', // magenta
  },
  {
    key: 'residential',
    name: t`Residential`,
    color: '#fcfe6f', // yellow
  },
  {
    key: 'commercial',
    name: t`Commercial`,
    color: colors.red.a200, // red
  },
  {
    key: 'industrial',
    name: t`Industrial/Warehouse`,
    color: '#cf9dec', // purple
  },
  {
    key: 'civic_institutional',
    name: t`Civic/Institutional`,
    color: '#538dd5', // blue
  },
  {
    key: 'transportation_utilities',
    name: t`Transportation/Utilities`,
    color: '#7a7a7a', // grey
  },
  {
    key: 'open_space',
    name: t`Open space`,
    color: '#7dbd4c', // medium green
  },
  {
    key: 'other',
    name: t`Vacant/Other`,
    color: '#efefef', // white
  },
  {
    key: 'agriculture',
    name: t`Agriculture`,
    color: '#C0D9AF', // muted green matching bt__rural_employment
  },
  {
    key: 'natural_resources',
    name: t`Natural resources`,
    color: '#003a00', // dark green
  },
  {
    key: 'natural_conservation',
    name: t`Natural/Conservation`,
    color: '#649c3a', // darker green than parks
  },
  {
    key: 'water',
    name: t`Water`,
    color: '#a4cdfd', // blue
  },
];

export const LAND_USE_CATEGORIES_LEVEL_2: LandUseCategoryMetadata[] = [
  // --- Mixed use --- //
  {
    key: 'mixed_use',
    name: 'Mixed use',
    color: '#dc41b8',
  },

  // --- Residential --- //
  {
    key: 'residential',
    name: t`Residential`,
    color: '#fcfe6f',
    readonly: true,
  },
  {
    key: 'single_family',
    name: 'Single-family',
    color: '#fcfe6f',
  },
  {
    key: 'multifamily',
    name: 'Multifamily',
    color: '#ff901a',
  },

  // --- Commercial --- //
  {
    key: 'commercial',
    name: t`Commercial`,
    color: '#ff0000',
    readonly: true,
  },
  {
    key: 'retail_commercial',
    name: 'Retail/Commercial',
    color: colors.red.a200,
  },
  {
    key: 'office',
    name: 'Office',
    color: '#940000',
  },
  {
    key: 'commercial_recreation',
    name: 'Commercial recreation',
    color: '#ffcccc',
  },

  // --- Industrial/Warehouse --- //
  {
    key: 'industrial',
    name: 'Industrial/Warehouse',
    color: '#cf9dec',
  },

  // --- Civic/Institutional --- //
  {
    key: 'civic_institutional',
    name: t`Civic/Institutional`,
    color: '#538dd5',
    readonly: true,
  },
  {
    key: 'civic_facilities',
    name: 'Civic facilities',
    color: '#538dd5',
  },
  {
    key: 'education',
    name: 'Education',
    color: '#365f91',
  },
  {
    key: 'hospitals',
    name: 'Hospitals',
    color: '#0000d8',
  },
  {
    key: 'military',
    name: 'Military',
    color: '#b0b0b0',
  },
  {
    key: 'other_institutional',
    name: 'Other institutional',
    color: '#001e47',
  },

  // --- Transportation/Utilities --- //
  {
    key: 'transportation_utilities',
    name: 'Transportation/Utilities',
    color: '#7a7a7a',
  },

  // --- Open space --- //
  {
    key: 'parks_recreation',
    name: 'Parks/Recreation',
    color: '#7dbd4c',
  },
  {
    key: 'cemeteries',
    name: 'Cemeteries',
    color: '#093a0e',
  },
  {
    key: 'open_space',
    name: 'Open space',
    color: '#98cb71',
  },

  // --- Vacant/Other --- //
  {
    key: 'other',
    name: 'Vacant/Other',
    color: '#efefef',
  },

  // --- Agriculture --- //
  {
    key: 'agriculture',
    name: 'Agriculture',
    color: '#C0D9AF', // muted green matching bt__rural_employment
  },

  // --- Natural resources --- //
  {
    key: 'natural_resources',
    name: 'Natural resources',
    color: '#003a00',
  },

  // --- Natural/Conservation --- //
  {
    key: 'natural_conservation',
    name: 'Natural/Conservation',
    color: '#649c3a', // darker green than parks
  },

  // --- Water --- //
  {
    key: 'water',
    name: 'Water',
    color: '#a4cdfd',
  },
];

export const LAND_USE_CATEGORIES_LEVEL_3: LandUseCategoryMetadata[] = [
  // --- Mixed use --- //
  {
    key: 'mixed_use_header',
    name: t`Mixed use`,
    header: true,
  },
  {
    key: 'mixed_use',
    name: t`Mixed use`,
    color: '#dc41b8', // magenta
    readonly: true,
  },
  {
    key: 'mixed_use_residential',
    name: t`Mixed use residential`,
    color: '#f679ab', // light magenta
  },
  {
    key: 'mixed_use_commercial',
    name: t`Mixed use commercial`,
    color: '#dc41b8', // medium magenta
  },
  {
    key: 'mixed_use_urban',
    name: t`Mixed use urban`,
    color: '#b12f9c', // dark magenta
  },

  // --- Residential --- //
  {
    key: 'residential_header',
    name: t`Residential`,
    header: true,
  },
  {
    key: 'residential',
    name: t`Residential`,
    color: '#fcfe6f', // yellow
    readonly: true,
  },
  {
    key: 'single_family_detached',
    name: t`Single-family detached`,
    color: '#fcfe6f', // bright yellow
  },
  {
    key: 'single_family_attached',
    name: t`Single-family attached`,
    color: '#fee567', // orange yellow
  },
  {
    key: 'multifamily',
    name: t`Multifamily`,
    color: '#ff901a', // orange
  },
  {
    key: 'group_quarters',
    name: t`Group quarters`,
    color: '#cc5500', // orange brown
  },

  // --- Commercial --- //
  {
    key: 'commercial_header',
    name: t`Commercial`,
    header: true,
  },
  {
    key: 'commercial',
    name: t`Commercial`,
    color: '#ff0000', // red
    readonly: true,
  },
  {
    key: 'retail_services',
    name: t`Retail services`,
    color: '#ff9b99', // light red
  },
  {
    key: 'commercial_centers',
    name: t`Commercial centers`,
    color: colors.red.a200, // light red
  },
  {
    key: 'accommodation',
    name: t`Accommodation`,
    color: '#e61414', // dark red
  },
  {
    key: 'commercial_other',
    name: t`Commercial other`,
    color: '#c20000', // dark red
  },
  {
    key: 'office',
    name: t`Office`,
    color: '#940000', // very dark red
  },
  {
    key: 'commercial_recreation',
    name: t`Commercial recreation`,
    color: '#ffcccc', // pink
  },

  // --- Industrial/Warehouse --- //
  {
    key: 'industrial_warehouse_header',
    name: t`Industrial/Warehouse`,
    header: true,
  },
  {
    key: 'industrial',
    name: t`Industrial/Warehouse`,
    color: '#cf9dec', // purple
    readonly: true,
  },
  {
    key: 'wholesale_warehousing',
    name: t`Wholesale/Warehousing`,
    color: '#e6ccff', // light purple
  },
  {
    key: 'light_industrial',
    name: t`Light industrial`,
    color: '#cf9dec', // medium purple
  },
  {
    key: 'heavy_industrial',
    name: t`Heavy industrial`,
    color: '#8764a6', // dark purple
  },

  // --- Civic/Institutional --- //
  {
    key: 'civic_institutional_header',
    name: t`Civic/Institutional`,
    header: true,
  },
  {
    key: 'civic_institutional',
    name: t`Civic/Institutional`,
    color: '#538dd5', // blue
    readonly: true,
  },
  {
    key: 'civic_facilities',
    name: t`Civic facilities`,
    color: '#538dd5', // grey blue
  },
  {
    key: 'special_use',
    name: t`Special use`,
    color: '#3281e6', // blue
  },
  {
    key: 'emergency_services',
    name: t`Emergency services`,
    color: '#006eff', // blue
  },
  {
    key: 'primary_secondary_education',
    name: t`Primary/Secondary education`,
    color: '#4477b6', // grey blue
  },
  {
    key: 'higher_education',
    name: t`Higher education`,
    color: '#365f91', // dark grey blue
  },
  {
    key: 'hospitals',
    name: t`Hospitals`,
    color: '#0000d8', // dark blue
  },
  {
    key: 'military',
    name: t`Military`,
    color: '#b0b0b0', // grey
  },
  {
    key: 'other_institutional',
    name: t`Other institutional`,
    color: '#001e47', // black
  },

  // --- Transportation/Utilities --- //
  {
    key: 'transportation_utilities_header',
    name: t`Transportation/Utilities`,
    header: true,
  },
  {
    key: 'transportation_utilities',
    name: t`Transportation/Utilities`,
    color: '#7a7a7a', // grey
    readonly: true,
  },
  {
    key: 'transportation',
    name: t`Transportation`,
    color: '#7a7a7a', // grey
  },
  {
    key: 'utilities_communications',
    name: t`Utilities/Communications`,
    color: '#5b4f4f', // brown grey
  },

  // --- Open space --- //
  {
    key: 'open_space_header',
    name: t`Open space`,
    header: true,
  },
  {
    key: 'parks_recreation',
    name: t`Parks/Recreation`,
    color: '#7dbd4c', // light green
  },
  {
    key: 'golf_courses',
    name: t`Golf courses`,
    color: '#b4ff7f', // bright green
  },
  {
    key: 'cemeteries',
    name: t`Cemeteries`,
    color: '#093a0e', // dark green
  },
  {
    key: 'open_space',
    name: t`Open space`,
    color: '#98cb71', // medium green
  },

  // --- Vacant/Other --- //
  {
    key: 'vacant_other_header',
    name: t`Vacant/Other`,
    header: true,
  },
  {
    key: 'vacant',
    name: t`Vacant`,
    color: '#efefef', // white
  },
  {
    key: 'other',
    name: t`Other`,
    color: '#e3e3e3', // light grey
  },

  // --- Agriculture --- //
  {
    key: 'agriculture_header',
    name: t`Agriculture`,
    header: true,
  },
  {
    key: 'agriculture',
    name: t`Agriculture`,
    color: '#C0D9AF', // muted green matching bt__rural_employment
  },

  // --- Natural resources --- //
  {
    key: 'natural_resources_header',
    name: t`Natural resources`,
    header: true,
  },
  {
    key: 'natural_resources',
    name: t`Natural resources`,
    color: '#003a00', // dark green
    readonly: true,
  },
  {
    key: 'forestry',
    name: t`Forestry`,
    color: '#c0d9af', // light green
  },
  {
    key: 'extraction',
    name: t`Extraction`,
    color: '#003a00', // dark green
  },

  // --- Natural/Conservation --- //
  {
    key: 'natural_conservation_header',
    name: t`Natural/Conservation`,
    header: true,
  },
  {
    key: 'natural_conservation',
    name: t`Natural/Conservation`,
    color: '#649c3a', // darker green than parks
  },

  // --- Water --- //
  {
    key: 'water_header',
    name: t`Water`,
    header: true,
  },
  {
    key: 'water',
    name: t`Water`,
    color: '#a4cdfd', // blue
  },
];

const LAND_USE_BY_COLUMN_KEY: Record<string, LandUseCategoryMetadata[]> = {
  [LAND_USE_CATEGORY_LEVEL_1]: LAND_USE_CATEGORIES_LEVEL_1.filter(
    info => !info.header,
  ),
  [LAND_USE_CATEGORY_LEVEL_2]: LAND_USE_CATEGORIES_LEVEL_2.filter(
    info => !info.header,
  ),
  [LAND_USE_CATEGORY_LEVEL_3]: LAND_USE_CATEGORIES_LEVEL_3.filter(
    info => !info.header,
  ),
};

/**
 * Resolve static land use labels for a given column.
 *
 * TODO: make sure this is a canvas that we're resolving, so we don't resolve
 * these labels for just any layer.
 */
export function resolveLandUseLabels(
  keys: string[],
  columnMetadata: LayerColumn,
): LandUseCategoryMetadata[] {
  const hints = columnMetadata.display_hints || {};
  const { symbology_key: symbologyKey } = hints;
  if (symbologyKey && symbologyKey in LAND_USE_BY_COLUMN_KEY) {
    return LAND_USE_BY_COLUMN_KEY[symbologyKey].filter(category =>
      keys.includes(category.key),
    );
  }
  return null;
}

/**
 * Extract the array of BuiltFormInfos for a given builtFormType
 *
 * If there is no such data, or it isn't loaded, returns an empty array.
 *
 * @param builtFormsListState The state from redux
 * @param libraryId
 * @param builtFormType
 */
function getBuiltFormList(
  builtFormsListState: BuiltFormListState,
  libraryId: string,
  builtFormType: BUILT_FORM_TYPES,
): BuiltFormInfo[] {
  const libraryKey = makeLibraryKey(libraryId, builtFormType);
  const state = builtFormsListState?.[builtFormType]?.[libraryKey];
  return isLoaded(state) ? getData(state) : EMPTY_ARRAY;
}

/**
 * Extracts a list of BuiltFormInfos for built forms that can be painted (i.e.
 * building types and placetypes). This is most useful in selectors that need to
 * extract the libraryId from another selector.
 */
export function getPaintableBuiltForms(
  builtFormsListState: BuiltFormListState,
  builtFormsLibraryId: string,
): { buildingTypes: BuiltFormInfo[]; placeTypes: BuiltFormInfo[] } {
  const buildingTypes = getBuiltFormList(
    builtFormsListState,
    builtFormsLibraryId,
    BUILT_FORM_TYPES.BUILDING_TYPE,
  );
  const placeTypes = getBuiltFormList(
    builtFormsListState,
    builtFormsLibraryId,
    BUILT_FORM_TYPES.PLACE_TYPE,
  );
  return { buildingTypes, placeTypes };
}

/**
 * This is the definitive type for a row in a canvas, and should be updated as the canvas is updated
 *
 *
 * Imported from `uf_canvas.columns.v2.yml` */
export interface CanvasItem {
  /** Geography ID */
  id: string;
  /** geometry_key */
  geometry_key: string;
  /** Land Use Category (L1) */
  land_use_category_level_1: string;
  /** Land Use Category (L2) */
  land_use_category_level_2: string;
  /** Land Use Category (L3) */
  land_use_category_level_3: BuildingType.LandUseCategoryLevel3Enum;
  /** Built Form Type */
  built_form_key: string;
  /** Land Development Category */
  land_development_category: PlaceType.LandDevelopmentCategoryEnum;
  /** Intersection Density */
  intersection_density: number;
  /** Gross Area */
  area_gross: number;
  /** Population */
  pop: number;
  /** Households (Occupied Dwelling Units) */
  hh: number;
  /** Dwelling Units */
  du: number;
  /** All Detached Single-Family Dwelling Units */
  du_detsf: number;
  /** Large Lot Detached Single-Family Dwelling Units */
  du_detsf_ll: number;
  /** Small Lot Detached Single-Family Dwelling Units */
  du_detsf_sl: number;
  /** Attached Single-Family Dwelling Units (Townhomes) */
  du_attsf: number;
  /** All Multifamily Dwelling Units */
  du_mf: number;
  /** Multi-Family - 2 to 4 Dwelling Units */
  du_mf2to4: number;
  /** Multi-Family - 5 or More Dwelling Units */
  du_mf5p: number;
  /** Employment */
  emp: number /** All Retail Employment */;
  emp_ret: number;
  /** All Office Employment */
  emp_off: number;
  /** All Public Employment */
  emp_pub: number;
  /** All Industrial Employment */
  emp_ind: number;
  /** All Agriculture Employment */
  emp_ag: number;
  /** Military Employment */
  emp_military: number;
  /** Retail Services Employment */
  emp_retail_services: number;
  /** Restaurants Employment */
  emp_restaurant: number;
  /** Accommodation Employment */
  emp_accommodation: number;
  /** Arts & Entertainment Employment */
  emp_arts_entertainment: number;
  /** Other Retail Employment */
  emp_other_services: number;
  /** Office Services Employment */
  emp_office_services: number;
  /** Public Administration Employment */
  emp_public_admin: number;
  /** Education Employment */
  emp_education: number;
  /** Medical Services Employment */
  emp_medical_services: number;
  /** Manufacturing Employment */
  emp_manufacturing: number;
  /** Wholesale Employment */
  emp_wholesale: number;
  /** Transportation/Warehouses Employment */
  emp_transport_warehousing: number;
  /** Utilities Employment */
  emp_utilities: number;
  /** Construction Employment */
  emp_construction: number;
  /** Agriculture Employment */
  emp_agriculture: number;
  /** Extraction Employment */
  emp_extraction: number;
  /** Small Lot Detached Single-Family Building Area */
  bldg_area_detsf_sl: number;
  /** Large Lot Detached Single-Family Building Area */
  bldg_area_detsf_ll: number;
  /** Attached Single-Family Building Area */
  bldg_area_attsf: number;
  /** Multifamily Building Area */
  bldg_area_mf: number;
  /** Retail Services Building Area */
  bldg_area_retail_services: number;
  /** Restaurants Building Area */
  bldg_area_restaurant: number;
  /** Accommodation Building Area */
  bldg_area_accommodation: number;
  /** Arts & Entertainment Building Area */
  bldg_area_arts_entertainment: number;
  /** Other Retail Building Area */
  bldg_area_other_services: number;
  /** Office Services Building Area */
  bldg_area_office_services: number;
  /** Public Administration Building Area */
  bldg_area_public_admin: number;
  /** Education Building Area */
  bldg_area_education: number;
  /** Medical Services Building Area */
  bldg_area_medical_services: number;
  /** Transportation/Warehouses Building Area */
  bldg_area_transport_warehousing: number;
  /** Wholesale Building Area */
  bldg_area_wholesale: number;
  /** All Detached Single Family Parcel Area */
  area_parcel_res_detsf: number;
  /** Small Lot Detached Single-Family Parcel Area */
  area_parcel_res_detsf_sl: number;
  /** Large Lot Detached Single-Family Parcel Area */
  area_parcel_res_detsf_ll: number;
  /** Attached Single-Family Parcel Area */
  area_parcel_res_attsf: number;
  /** Multifamily Building Parcel Area */
  area_parcel_res_mf: number;
  /** All Retail Parcel Area */
  area_parcel_emp_ret: number;
  /** All Office Parcel Area */
  area_parcel_emp_off: number;
  /** All Public Parcel Area */
  area_parcel_emp_pub: number;
  /** All Industrial Parcel Area */
  area_parcel_emp_ind: number;
  /** All Agriculture Parcel Area */
  area_parcel_emp_ag: number;
  /** All Military Parcel Area */
  area_parcel_emp_military: number;
  /** Residential Parcel Area */
  area_parcel_res: number;
  /** Employment Parcel Area */
  area_parcel_emp: number;
  /** Mixed Use Parcel Area */
  area_parcel_mixed_use: number;
  /** No Use Parcel Area */
  area_parcel_no_use: number;
  /** Net Area */
  area_parcel: number;
  /** Area - Development Condition */
  area_dev_condition: number;
  /** Right-of-Way Area */
  area_row: number;
  /** Residential Irrigated Area */
  residential_irrigated_area: number;
  /** Commercial Irrigated Area */
  commercial_irrigated_area: number;
  /** Population in Group Quarters */
  pop_groupquarter: number;
  /** Has this row been "painted"*/
  uf_is_painted?: boolean;
}
/** All keys in the canvas */
export type CanvasColumnKey = keyof CanvasItem;
/** Keys of numeric values in the canvas */
export type NumericCanvasColumnKey = KeysMatching<CanvasItem, number>;
/** A subset of CanvasItem including only numeric values */
export type NumericCanvasItem = Pick<CanvasItem, NumericCanvasColumnKey>;
