import { boundMethod } from 'autobind-decorator';
import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';
import wrapDisplayName from 'recompose/wrapDisplayName';
import { bindActionCreators, Dispatch } from 'redux';

import { LayerMetadata } from 'uf-api/model/models';
import { omitHocProps, withHocPrefix } from 'uf/base/hocs';
import { DataState, getData } from 'uf/data/dataState';
import { LayerId } from 'uf/layers';
import {
  ensureLayerMetadata,
  loadLayerMetadata,
} from 'uf/layers/actions/metadata';
import {
  makeGetLayerMetadata,
  makeGetLayerMetadataState,
} from 'uf/layers/selectors/metadata';

const layerMetadataActions = withHocPrefix('layerMetadataActions');

interface StateProps {
  layerMetadata?: LayerMetadata;
  layerMetadataState?: DataState<LayerMetadata>;
}

interface DispatchProps {
  [layerMetadataActions: string]: any;
}

export namespace WithLayerMetadata {
  export interface OwnProps {
    layerId: LayerId;
  }

  export type ProvidedProps = StateProps;
}

export type WithLayerMetadataProps = WithLayerMetadata.OwnProps &
  StateProps &
  DispatchProps;

export function withLayerMetadata<PassthroughProps>() {
  return (
    WrappedComponent: React.ComponentType<
      PassthroughProps & WithLayerMetadata.ProvidedProps
    >,
  ) => {
    class WithLayerMetadataHOC extends Component<
      WithLayerMetadataProps & PassthroughProps
    > {
      componentDidMount() {
        this.ensureLayerMetadata();
      }

      componentDidUpdate(prevProps) {
        const { layerId } = this.props;
        if (layerId !== prevProps.layerId) {
          this.ensureLayerMetadata();
        }
      }

      @boundMethod
      ensureLayerMetadata() {
        if (this.props.layerId) {
          this.props[layerMetadataActions].ensureLayerMetadata(
            this.props.layerId,
          );
        }
      }

      render() {
        const props = omitHocProps(this.props);
        return <WrappedComponent {...props} />;
      }
    }

    function makeMapStateToProps() {
      const getLayerMetadata = makeGetLayerMetadata();
      const getLayerMetadataState = makeGetLayerMetadataState();
      return (state, props): StateProps => ({
        layerMetadata: getLayerMetadata(state, { layerId: props.layerId }),
        layerMetadataState: getLayerMetadataState(state, {
          layerId: props.layerId,
        }),
      });
    }

    const wrapped = connect<
      StateProps,
      DispatchProps,
      WithLayerMetadata.OwnProps & PassthroughProps
    >(
      makeMapStateToProps,
      mapDispatchToProps,
    )(WithLayerMetadataHOC);

    wrapped.displayName = wrapDisplayName(
      WrappedComponent,
      'WithLayerMetadataHOC',
    );
    return wrapped;
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  const layerMetadataActionCreators = {
    ensureLayerMetadata,
    loadLayerMetadata,
  };
  return {
    [layerMetadataActions]: bindActionCreators(
      layerMetadataActionCreators,
      dispatch,
    ),
  };
}

interface WithLayerMetadataStateProps {
  layerMetadataState: DataState<LayerMetadata>;
}

interface WithLayerMetadataOwnProps {
  layerId: LayerId;
  children: ({
    layerMetadata,
    layerMetadataState,
  }: {
    layerMetadata: LayerMetadata;
    layerMetadataState: DataState<LayerMetadata>;
  }) => ReactNode;
}

interface WithLayerMetadataDispatchProps {
  ensureLayerMetadata: (layerId: LayerId) => void;
}

// eslint-disable-next-line no-redeclare
class WithLayerMetadata extends Component<
  WithLayerMetadataOwnProps &
    WithLayerMetadataStateProps &
    WithLayerMetadataDispatchProps
> {
  componentDidMount() {
    const { layerId } = this.props;
    if (layerId) {
      this.props.ensureLayerMetadata(this.props.layerId);
    }
  }

  componentDidUpdate(prevProps) {
    const { layerId } = this.props;
    if (layerId && layerId !== prevProps.layerId) {
      this.props.ensureLayerMetadata(this.props.layerId);
    }
  }
  render() {
    const { layerMetadataState, children } = this.props;
    const body = children({
      layerMetadata: getData(layerMetadataState),
      layerMetadataState,
    });
    // Account for common case of `return layerMetadata.name` when name is
    // missing, without crashing.
    return body === undefined ? null : body;
  }
}

const ConnectedWithLayerMetadata = connect<
  WithLayerMetadataStateProps,
  WithLayerMetadataDispatchProps,
  WithLayerMetadataOwnProps
>(
  () => {
    const getLayerMetadataState = makeGetLayerMetadataState();

    return (state, { layerId }: WithLayerMetadataOwnProps) => ({
      layerMetadataState: getLayerMetadataState(state, { layerId }),
    });
  },
  dispatch => ({
    ensureLayerMetadata: bindActionCreators(ensureLayerMetadata, dispatch),
  }),
)(WithLayerMetadata);
export default ConnectedWithLayerMetadata;
