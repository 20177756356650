import {
  CuratedColumnSymbology,
  CUSTOM_THEME,
  DEFAULT_OPACITY,
  DistributionTypes,
  InterpolationTypes,
  PaintColorTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/calthorpeanalytics/dataset/building_characteristics_riverside_city': {
    bldg_far: [
      {
        type: SymbologyTypes.FILL,
        paint: {
          'fill-color': {
            property: 'bldg_far',
            type: PaintColorTypes.INTERVAL,
            stops: [
              { value: 0.0, label: '0.0 - 0.1', color: '#F7FCF0' },
              { value: 0.1, label: '0.1 - 0.25', color: '#CCEAC1' },
              { value: 0.25, label: '0.25 - 0.50', color: '#97D898' },
              { value: 0.5, label: '0.50 - 0.75', color: '#71C78D' },
              { value: 0.75, label: '0.75 - 1.0', color: '#50B590' },
              { value: 1.0, label: '1.0 - 1.25', color: '#33A49C' },
              { value: 1.25, label: '1.25 - 1.50', color: '#1B7692' },
              { value: 1.5, label: '1.50 +', color: '#084081' },
            ],
          },
          'fill-opacity': DEFAULT_OPACITY,
        },
        display_hints: {
          scale: SymbologyScales.NUMERIC,
          distribution: DistributionTypes.DEFAULT,
          interpolation: InterpolationTypes.INTERVAL,
          theme: CUSTOM_THEME,
        },
      },
    ],
  },
};

export default symbologies;
