import { Action } from 'redux';

import {
  FlavoredListActionTypes,
  makeListActionTypes,
} from 'uf/base/listActionHelpers';
import { ColumnKey } from 'uf/layers';
import { ProjectId } from 'uf/projects';
import { LegacyVirtualLayerId } from 'uf/projects/virtualLayers';
import { View, ViewId } from 'uf/views';

export interface ExtraViewParams {
  projectId: ProjectId;
}
type ViewListActionTypes = FlavoredListActionTypes<
  'SET_VIEW_LIST',
  'UPDATE_VIEW',
  'UPSERT_VIEW',
  'UPSERT_VIEWS',
  'ADD_VIEW',
  'DELETE_VIEW'
>;

export const viewListActionTypes: ViewListActionTypes =
  makeListActionTypes('uf/views/list');

/** @deprecated use `visibleLayerListActionTypes.*` instead */
export const SET_LAYER_VISIBILITY = 'uf/views/SET_LAYER_VISIBILITY';

/** @deprecated use SetVisibleLayersVirtualIdsAction */
export interface SetLayerVisibilityAction extends Action {
  type: typeof SET_LAYER_VISIBILITY;
  projectId: ProjectId;
  virtualLayerId: LegacyVirtualLayerId;
  viewId: ViewId;
  visible: boolean;
}

export const CLEAR_VISIBLE_LAYERS = 'uf/views/CLEAR_VISIBLE_LAYERS';
export interface ClearVisibleLayersAction extends Action {
  type: typeof CLEAR_VISIBLE_LAYERS;
  projectId: ProjectId;
  viewId: ViewId;
}

export const SET_LAYER_ORDER = 'uf/views/SET_LAYER_ORDER';
export interface SetLayerOrderAction extends Action {
  type: typeof SET_LAYER_ORDER;
  projectId: ProjectId;
  viewId: ViewId;
  layerOrder: LegacyVirtualLayerId[];
}

type VisibleLayerListActionTypes = FlavoredListActionTypes<
  'layer_visiblity/ADD',
  'layer_visiblity/UPDATE',
  'layer_visiblity/UPSERT',
  'layer_visiblity/UPSERT_ITEMS',
  'layer_visiblity/APPEND',
  'layer_visiblity/REMOVE'
>;

export const visibleLayerListActionTypes: VisibleLayerListActionTypes =
  makeListActionTypes('uf/views/visibility');

export const SET_LAYER_MAP_COLUMN_KEY =
  'uf/views/layers/SET_LAYER_MAP_COLUMN_KEY';
export interface SetActiveColumnAction extends Action {
  type: typeof SET_LAYER_MAP_COLUMN_KEY;
  projectId: ProjectId;
  viewId: ViewId;
  virtualLayerId: LegacyVirtualLayerId;
  columnKey: ColumnKey;
}

export const DUPLICATE_VIEW = 'uf/views/DUPLICATE_VIEW';
export interface DuplicateViewAction extends Action {
  type: typeof DUPLICATE_VIEW;
  projectId: ProjectId;
  sourceViewId: ViewId;
  newView: View;
}
