import colors from 'material-colors';

import {
  CuratedColumnSymbology,
  DistributionTypes,
  InterpolationTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/public/dataset/hud_fair_market_rents_2019': {
    fmr_0bdr: [
      {
        display_hints: {
          distribution: DistributionTypes.QUANTILES,
          interpolation: InterpolationTypes.INTERVAL,
          is_percent: false,
          scale: SymbologyScales.NUMERIC,
          showZero: false,
          theme: 'material:green:range',
        },
        paint: {
          'fill-color': {
            default: 'transparent',
            property: 'fmr_0bdr',
            stops: [
              {
                color: colors.green[100],
                value: 608,
              },
              {
                color: 'rgb(181, 215, 182)',
                value: 633,
              },
              {
                color: 'rgb(162, 200, 163)',
                value: 702,
              },
              {
                color: 'rgb(142, 185, 145)',
                value: 742,
              },
              {
                color: 'rgb(123, 170, 126)',
                value: 808,
              },
              {
                color: 'rgb(104, 154, 107)',
                value: 853,
              },
              {
                color: 'rgb(85, 139, 88)',
                value: 983,
              },
              {
                color: 'rgb(65, 124, 70)',
                value: 1095,
              },
              {
                color: 'rgb(46, 109, 51)',
                value: 1314,
              },
              {
                color: colors.green[900],
                value: 1567,
              },
            ],
            type: 'interval',
          },
          'fill-opacity': 0.5,
        },
        type: SymbologyTypes.FILL,
      },
      {
        display_hints: {
          distribution: DistributionTypes.QUANTILES,
          interpolation: InterpolationTypes.INTERVAL,
          is_percent: false,
          scale: SymbologyScales.NUMERIC,
          showZero: false,
          theme: 'material:blue:range',
        },
        paint: {
          'line-color': 'transparent',
          'line-opacity': 0.5,
          'line-width': 0.5,
        },
        type: SymbologyTypes.LINE,
      },
    ],
  },
};

export default symbologies;
