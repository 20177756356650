import { Epic, ofType } from 'redux-observable';
import { map } from 'rxjs/operators';

import {
  acceptUserInvitationActionTypes,
  acceptUserInvitationSuccessAction,
  rejectUserInvitationActionTypes,
  rejectUserInvitationSuccessAction,
} from 'uf-api/api/user.service';
import { combineEpics } from 'uf/base/epics';
import { loadInvitationsForUser } from 'uf/user/actions/invitations';

export const refreshAfterAcceptReject: Epic<
  acceptUserInvitationSuccessAction | rejectUserInvitationSuccessAction,
  any
> = action$ => {
  return action$.pipe(
    ofType(
      acceptUserInvitationActionTypes.SUCCESS,
      rejectUserInvitationActionTypes.SUCCESS,
    ),
    map(action => {
      return loadInvitationsForUser();
    }),
  );
};

export default combineEpics({ refreshAfterAcceptReject }, 'invitations');
