import * as d3Scale from 'd3-scale';
import { Feature } from 'geojson';
import _ from 'lodash';
import { Expression, Layer, StyleFunction } from 'mapbox-gl';
import warning from 'warning';

import { LayerMetadata } from 'uf-api';
import { EMPTY_OBJECT } from 'uf/base';
import { MapboxExpression } from 'uf/base/map';
import { typeAssertNever } from 'uf/base/never';
import { ColumnKey, LayerId } from 'uf/layers';
import { isNullCategory } from 'uf/layers/nullStats';
import {
  ColumnSymbology,
  DataDrivenColorStop,
  DEFAULT_LINE_WIDTH,
  isNumericSymbology,
  PaintColorProperty,
  PaintHeightProperty,
  PaintOpacityProperty,
  PaintRadiusProperty,
  PaintStrokeColorProperty,
  PaintWidthProperty,
  SymbologyTypes,
} from 'uf/symbology';
import { DivideByColumnKey } from 'uf/symbology/divideByColumn';
import { UFMapLayer } from '..';

const MAX_EXTRUDE_VALUE = 800;

export interface StyleLayerMetadata {
  /**
   * The UF layerId this layer is styling
   */
  layerId: LayerId;
  /** The specific column within this layer that this layer is styling, if any */
  columnKey?: ColumnKey;
  /**
   * If true, this layer is an extra layer specifically for zeros in numeric layers
   */
  isZeroLayer?: boolean;
  /**
   * If true, this is an edits layer that the user is not editing
   */
  previewEditsLayer?: boolean;
  /**
   * If true, this is an edits layer
   */
  editsLayer?: boolean;
  /**
   * If true, this is a selection layer for the column key
   */
  selectionColumnKey?: boolean;
}

/**
 * Extract the UF-specific "metadata" from a style layer.
 */
export function getStyleLayerUFMetadata(layer: Layer): StyleLayerMetadata {
  return layer?.metadata?.uf ?? EMPTY_OBJECT;
}
// a function to make zero values transperent. returns two layers.
// the first layer is every geometry where the numeric value for a given key is NOT zero.
// the second layer is every geometry where the numeric value IS zero.
// the geometries in the second layer are made transparent.
export function makeZeroStyleLayers(
  styleLayer: UFMapLayer,
  key: string,
  layerSupportsOpacity: boolean,
  layerSupportsColor: boolean,
): [
  // no zeros layer
  UFMapLayer,
  // only zeros
  UFMapLayer,
] {
  // all columns except the zero value
  const noZeroValueFilter = ['match', ['get', key], 0, false, true];
  const noNullValueFilter = [
    'match',
    ['typeof', ['get', key]],
    'null',
    false,
    true,
  ];
  const combinedPrimaryFilter = [
    ...styleLayer.filter,
    noZeroValueFilter,
    noNullValueFilter,
  ];
  const primaryLayer: UFMapLayer = {
    ...styleLayer,
    filter: combinedPrimaryFilter,
  };

  // the 'zero' value
  const yesZeroValueFilter = ['match', ['get', key], 0, true, false];
  const yesNullValueFilter = [
    'match',
    ['typeof', ['get', key]],
    'null',
    true,
    false,
  ];
  const combinedZeroFilter = ['any', yesZeroValueFilter, yesNullValueFilter];
  const uf: StyleLayerMetadata = {
    ...getStyleLayerUFMetadata(styleLayer),
    isZeroLayer: true,
  };
  const zeroStyleLayer: UFMapLayer = {
    ...styleLayer,
    id: `${styleLayer.id}|zero_transparency`,
    filter: combinedZeroFilter,
    paint: {},
    metadata: {
      ...styleLayer.metadata,
      uf,
    },
  };

  if (layerSupportsOpacity) {
    // adding opacity paint style as well due to fill-extrusion's lack of
    // support for the "transparent" key for its fill-color
    zeroStyleLayer.paint[`${styleLayer.type}-opacity`] = 0;
  }
  if (layerSupportsColor) {
    // preserving in case mapbox introduces support for transparent
    // fill-extrusion-color
    zeroStyleLayer.paint[`${styleLayer.type}-color`] = 'transparent';
  }

  return [primaryLayer, zeroStyleLayer];
}

interface ParsePaintOptions {
  nullsOnly: boolean;
}

const DEFAULT_PARSE_PAINT_OPTIONS: ParsePaintOptions = {
  nullsOnly: false,
};
export function parseNumericPaintColor(
  symbology: ColumnSymbology,
  paintColor: PaintColorProperty,
  columnKey: ColumnKey,
  min?: number,
  max?: number,
  divideByColumn?: DivideByColumnKey,
  { nullsOnly = false } = DEFAULT_PARSE_PAINT_OPTIONS,
): mapboxgl.FillPaint['fill-color'] {
  if (_.isString(paintColor)) {
    return paintColor;
  }

  const { property, stops, default: defaultColor } = paintColor;
  const { interpolation } = symbology.display_hints;

  // The symbology can contain null stops, but mapbox don't support styling
  // multiple data types in a single style layer, we have to do that in a
  // separate style layer.
  if (nullsOnly) {
    const nullStop = stops.find(stop => isNullCategory(stop.value));
    return [
      'match',
      ['coalesce', ['get', columnKey], 'null-sentinel'],
      'null-sentinel',
      nullStop.color,
      defaultColor || 'transparent',
    ];
  }

  // Numeric style layers needs to account for the min and max values in the layer if it doesn't
  // already, otherwise the style layer and symbology won't be in sync
  const symbologyStops = stops.filter(stop => {
    const isNull = isNullCategory(stop.value);
    return nullsOnly ? isNull : !isNull;
  });
  if (!symbologyStops.length) {
    return defaultColor ?? 'transparent';
  }

  const isNumeric = isNumericSymbology(symbology);
  /**
   * Previously, columns with all null rows were being parsed with invalid (undefined) values for their stops.
   * This initial finalSymbologyStops declaration coerces undefined values to a 0. This prevents Mapbox errors.
   * Note that this is being done on variable declaration in order to avoid modifying the conditional below.
   */
  let finalSymbologyStops: DataDrivenColorStop[] = [
    ...symbologyStops.map(stop => ({ ...stop, value: stop.value || 0 })),
  ];
  const [firstStop] = symbologyStops;
  if (isNumeric && firstStop && firstStop.value > min && min !== undefined) {
    const minStop = { color: firstStop.color, value: min };
    const maxStop = { color: stops[stops.length - 1].color, value: max };
    finalSymbologyStops = finalSymbologyStops.map((stop, i) => {
      if (i === finalSymbologyStops.length - 1) {
        return stop;
      }
      return { ...stop, color: finalSymbologyStops[i + 1].color };
    });

    // We aren't guaranteed that the first stop isn't the min, so check before adding it
    if (finalSymbologyStops[0].value > min) {
      finalSymbologyStops = [minStop, ...finalSymbologyStops];
    }

    // Same for the max, we have no guarantee that the last stop isn't the max.
    if (finalSymbologyStops[finalSymbologyStops.length - 1].value < max) {
      finalSymbologyStops = [...finalSymbologyStops, maxStop];
    }
  }

  if (divideByColumn) {
    // TODO: deal with interpolation
    const expression: Expression = [
      'step',
      ['/', ['get', property], ['get', divideByColumn]],
      defaultColor || 'transparent',
      ..._.flatten(
        finalSymbologyStops.map(({ color, value }) => [value, color]),
      ),
    ];
    return expression;
  }

  if (!finalSymbologyStops.length) {
    return defaultColor ?? 'transparent';
  }

  const parsed: mapboxgl.StyleFunction = {
    property,
    default: defaultColor || 'transparent',
    type: interpolation,
    stops: finalSymbologyStops.map(({ color, value }) => [value, color]),
  };
  return parsed;
}

// TODO: break this up into functions for each kind of property (fill, fill-extrusion, line)...
export function parsePaintPropertyOld(
  symbology: ColumnSymbology,
  columnKey: ColumnKey,
  customExtrusionExpression?: MapboxExpression[],
  min?: number,
  max?: number,
): mapboxgl.Layer['paint'] {
  return _.mapValues(symbology.paint, (value: any, key: string) => {
    switch (key) {
      // `fill-color`, `line-color`, `circle-color`
      case `${symbology.type}-color`:
        return parseNumericPaintColor(symbology, value, columnKey, min, max);

      case `${symbology.type}-width`:
        return parsePaintWidth(symbology, value);

      case `${symbology.type}-height`:
        return customExtrusionExpression
          ? customExtrusionExpression
          : parsePaintHeightOld(symbology, value);

      case `${symbology.type}-opacity`:
        return parsePaintOpacity(symbology, value);

      case `${symbology.type}-stroke-color`:
        return parsePaintStrokeColor(symbology, value);

      default:
        return value;
    }
  });
}
function parsePaintHeightOld(
  symbology: ColumnSymbology,
  paintHeight: PaintHeightProperty,
): mapboxgl.FillExtrusionPaint['fill-extrusion-height'] {
  if (_.isNumber(paintHeight)) {
    return paintHeight;
  }

  const { interpolation } = symbology.display_hints;
  const { property, stops, default: defaultHeight } = paintHeight;

  if (!stops.length) {
    return defaultHeight ?? 0;
  }

  const numericStops = stops.map(({ value }) =>
    typeof value === 'string' ? parseFloat(value) : value,
  ) as number[];

  const parsed: mapboxgl.StyleFunction = {
    property,
    type: interpolation,
    stops: formatNumericStopsWithScale(numericStops),
  };

  return parsed;
}

export function parsePaintWidth(
  symbology: ColumnSymbology,
  paintWidth: PaintWidthProperty,
): mapboxgl.LinePaint['line-width'] {
  if (_.isNumber(paintWidth)) {
    return paintWidth;
  }

  const { property, stops, default: defaultValue } = paintWidth;
  const { interpolation } = symbology.display_hints;

  if (!stops.length) {
    return defaultValue ?? DEFAULT_LINE_WIDTH;
  }

  const parsed: mapboxgl.StyleFunction = {
    property,
    default: defaultValue,
    type: interpolation,
    stops: stops.map(({ width, value }) => [value, width]),
  };

  return parsed;
}

export function parsePaintRadius(
  symbology: ColumnSymbology,
  radiusSymbology: PaintRadiusProperty,
  divideByColumn: DivideByColumnKey,
): mapboxgl.CirclePaint['circle-radius'] {
  if (typeof radiusSymbology === 'number') {
    return radiusSymbology;
  }
  const { property, stops: symbologyStops } = radiusSymbology;

  const stops = symbologyStops.map(({ radius, value }) => [value, radius]);

  const propExpression = divideByColumn
    ? ['/', ['get', property], ['get', divideByColumn]]
    : ['get', property];

  const expression: Expression = [
    'interpolate',
    ['linear'],
    propExpression,
    ..._.flatten(stops),
  ];
  return expression;
}

export function parsePaintOpacity(
  symbology: ColumnSymbology,
  paintOpacity: PaintOpacityProperty,
): mapboxgl.FillPaint['fill-opacity'] {
  if (_.isNumber(paintOpacity)) {
    return paintOpacity;
  }

  const { property, stops, default: defaultOpacity } = paintOpacity;
  const { interpolation } = symbology.display_hints;

  if (!stops.length) {
    // TODO: deal with default
    return defaultOpacity ?? 0;
  }
  const parsed: mapboxgl.StyleFunction = {
    property,
    default: defaultOpacity ?? 0,
    type: interpolation,
    stops: stops.map(({ opacity, value }) => [value, opacity]),
  };

  return parsed;
}

function formatNumericStopsWithScale(numericStops: number[]) {
  const smallestVal = numericStops[0];
  const largestVal = numericStops[numericStops.length - 1];

  // scale stops so extrusions go from 0 to 800m
  const scale = d3Scale
    .scaleLinear()
    .domain([smallestVal, largestVal])
    .range([0, MAX_EXTRUDE_VALUE]);

  return numericStops.map(value => [value, scale(value)]);
}
export function getBinnedFillExtrusionHeightProperty(
  fillColorProperty: PaintColorProperty,
  scaleFactor: number,
): number | StyleFunction {
  if (_.isNumber(fillColorProperty)) {
    return fillColorProperty * scaleFactor;
  }
  if (_.isString(fillColorProperty)) {
    // This should never happen, but our paint property types are too wide to
    // statically ensure it, so we use _.isString for its "type predicate"
    warning(!_.isString(fillColorProperty), 'Cannot extrude string values');
    return null;
  }

  const { stops, property, type } = fillColorProperty;

  if (!stops.length) {
    return 0;
  }
  const scaledValues = stops
    .filter(stop => !isNullCategory(stop.value))
    .map(props => (props.value as number) * scaleFactor);

  return {
    type,
    property,
    stops: formatNumericStopsWithScale(scaledValues),
  };
}

export function getContinuousFillExtrusionHeightProperty(
  max: number,
  scaleFactor: number,
  invertLevels: boolean,
  columnKey: ColumnKey,
  divideByColumn: DivideByColumnKey,
): Expression {
  // TODO: Determine the appropriate extrusion pattern
  const columnExpression: Expression = ['number', ['get', columnKey]];
  let valueExpression = columnExpression;
  if (divideByColumn) {
    const divideByColumnExpression: Expression = [
      'number',
      ['get', divideByColumn],
    ];
    valueExpression = ['/', columnExpression, divideByColumnExpression];
  }
  const baseExpression: Expression = [
    '*',
    ['max', valueExpression, 0],
    scaleFactor,
  ];

  // Skip next steps related to inversion if not requested
  if (!invertLevels) {
    return baseExpression;
  }

  return ['max', ['-', scaleFactor * max, baseExpression], 0];
}

export function parsePaintStrokeColor(
  symbology: ColumnSymbology,
  strokeColor: PaintStrokeColorProperty,
): mapboxgl.CirclePaint['circle-stroke-color'] {
  if (_.isString(strokeColor)) {
    return strokeColor;
  }

  const { property, stops, default: defaultColor } = strokeColor;
  const { interpolation } = symbology.display_hints;

  if (!stops.length) {
    return defaultColor ?? 'transparent';
  }

  const parsed: mapboxgl.StyleFunction = {
    property,
    default: defaultColor || 'transparent',
    type: interpolation,
    stops: stops.map(stop => [stop.value, stop['stroke-color']]),
  };

  return parsed;
}

/**
 * returns a mapbox filter for showing nothing but null or all but null.
 */
export function getPaintNullFilter(
  columnKey: ColumnKey,
  renderNullOnly = false,
): mapboxgl.Expression {
  const operator = renderNullOnly ? '==' : '!=';
  return [operator, ['get', columnKey], null];
}

/**
 * returns a mapbox filter for the corresponding feature type
 */
export function getPaintFilter(
  paintType: mapboxgl.Layer['type'],
): mapboxgl.Expression {
  switch (paintType) {
    case 'fill':
      // filter to return only polygon type features
      return ['match', ['geometry-type'], 'Polygon', true, false];

    case 'fill-extrusion':
      // filter to return only polygon type features
      return ['match', ['geometry-type'], 'Polygon', true, false];

    case 'line':
      // filter to return polygon and linestring features
      // prettier-ignore
      return [
        'any',
        ['match', ['geometry-type'], 'Polygon', true, false],
        ['match', ['geometry-type'], 'LineString', true, false],
      ];

    case 'circle':
      // filter to get only point type features
      return ['match', ['geometry-type'], 'Point', true, false];

    default:
      // no filter at all
      return null;
  }
}

/**
 * Helper to get the opacity key for each symbology type.
 * @param layerType - a mapbox Layer type.  Note that we only return keys for our defined
 * SymbologyTypes, which is a subset of the available mapbox Layer types.
 */
export function getPaintOpacityKey(layerType: string): string {
  const type = layerType as SymbologyTypes;

  switch (type) {
    case SymbologyTypes.CIRCLE:
    case SymbologyTypes.FILL:
    case SymbologyTypes.LINE:
      return `${type}-opacity`;
    // Note that symbol also has a text-opacity.  Might have to refactor this function to return an
    // array of keys instead
    case SymbologyTypes.SYMBOL:
      return 'icon-opacity';
    default: {
      typeAssertNever(type);
      return null;
    }
  }
}

/**
 * Helper to get the width or stroke-width key for each symbology type.
 * @param layerType - a mapbox Layer type.  Note that we only return keys for our defined
 * SymbologyTypes, which is a subset of the available mapbox Layer types.
 */
export function getPaintWidthKey(layerType: string): string {
  const type = layerType as SymbologyTypes;

  switch (type) {
    case SymbologyTypes.LINE:
      return 'line-width';
    case SymbologyTypes.CIRCLE:
      return 'circle-stroke-width';
    case SymbologyTypes.FILL:
      return null;
    case SymbologyTypes.SYMBOL:
      return null;
    default: {
      typeAssertNever(type);
      return null;
    }
  }
}

// HACK until we have a better way to know which layers are selection layers
// TODO: use metadata of style layers to figure this out
export function isSelectionStyleLayer(layer: Layer) {
  return layer.id.includes('_selection_');
}

export function excludeFeaturesFromStyleLayers(
  allStyleLayers: Layer[],
  styleLayerIds: string[],
  featuresToExclude: Feature[],
  layerMetadata: LayerMetadata,
  selectionStyleLayers: Layer[],
): Layer[] {
  if (!featuresToExclude.length) {
    return allStyleLayers;
  }
  const { unique_keys: uniqueKeys = [] } = layerMetadata;
  const excludeEditsExpression: MapboxExpression =
    makeExcludeExpressionByUniqueKeys(featuresToExclude, uniqueKeys);
  if (!excludeEditsExpression) {
    return allStyleLayers;
  }
  return allStyleLayers.map(styleLayer => {
    if (!styleLayerIds.includes(styleLayer.id)) {
      return styleLayer;
    }

    const metadata = getStyleLayerUFMetadata(styleLayer);
    const isFeatureEditsLayer =
      metadata.editsLayer || metadata.previewEditsLayer;
    if (isFeatureEditsLayer) {
      return styleLayer;
    }

    // XXX SUPER HACK: for the selection layer, steal the selection filters from
    // the corresponding selection. This lets the servers' `geometry_key`
    // results match the GeoJSON features.
    const selectionFilters: MapboxExpression = selectionStyleLayers?.length
      ? selectionStyleLayers[0].filter // NOTE: assumes filters in all selection layers are the same
      : null;

    const isFeatureEditsSelectionLayer =
      styleLayer?.metadata?.selectionColumnKey &&
      styleLayer?.metadata?.editsColumn;
    const excludeExpression = isFeatureEditsSelectionLayer
      ? selectionFilters
      : excludeEditsExpression;
    if (styleLayer.filter) {
      return {
        ...styleLayer,
        filter: ['all', styleLayer.filter, excludeExpression],
      };
    }
    return {
      ...styleLayer,
      filter: excludeExpression,
    };
  });
}

function makeExcludeExpressionByUniqueKeys(
  features: Feature[],
  uniqueKeys: string[],
) {
  if (!features.length) {
    return null;
  }
  const allExclusioins: MapboxExpression = features.map(feature => {
    if (uniqueKeys.length === 1) {
      const [columnKey] = uniqueKeys;
      return ['==', ['get', columnKey], feature.properties[columnKey]];
    }
    const props = uniqueKeys.map((columnKey): MapboxExpression => {
      return ['==', ['get', columnKey], feature.properties[columnKey]];
    });
    return props;
  });
  const excludeExpression: MapboxExpression =
    allExclusioins.length === 1
      ? ['!', allExclusioins[0]]
      : ['!', ['any', ...allExclusioins]];
  return excludeExpression;
}
