import colors from 'material-colors';

import {
  CuratedColumnSymbology,
  CUSTOM_THEME,
  DEFAULT_OPACITY,
  DistributionTypes,
  InterpolationTypes,
  PaintColorTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/public/dataset/ventura_general_plan_parcels': {
    high: [
      {
        type: SymbologyTypes.FILL,
        paint: {
          'fill-color': {
            property: 'high',
            type: PaintColorTypes.INTERVAL,
            stops: [
              { value: 0, color: 'rgb(231, 197, 91)' },
              { value: 1, color: 'rgb(215, 183, 82)' },
              { value: 2.5, color: 'rgb(199, 168, 74)' },
              { value: 5, color: 'rgb(184, 154, 65)' },
              { value: 10, color: 'rgb(168, 141, 57)' },
              { value: 15, color: 'rgb(153, 127, 49)' },
              { value: 20, color: 'rgb(138, 114, 41)' },
              { value: 25, color: 'rgb(123, 101, 33)' },
              { value: 30, color: 'rgb(109, 88, 25)' },
              { value: 40, color: 'rgb(95, 76, 18)' },
            ],
          },
          'fill-opacity': DEFAULT_OPACITY,
        },
        display_hints: {
          scale: SymbologyScales.NUMERIC,
          distribution: DistributionTypes.DEFAULT,
          interpolation: InterpolationTypes.INTERVAL,
          theme: CUSTOM_THEME,
        },
      },
    ],

    scag_gp_lu_description: [
      {
        type: SymbologyTypes.FILL,
        paint: {
          'fill-color': {
            property: 'scag_gp_lu_description',
            type: PaintColorTypes.CATEGORICAL,
            stops: [
              { value: 'Unknown', color: '#CCCCCC' },
              { value: 'Residential', color: '#E3C400' },
              { value: 'Single Family Residential', color: colors.yellow.a200 },
              { value: 'Multi-Family Residential', color: '#FFAA00' },
              { value: 'Mobile Homes and Trailer Parks', color: '#FFEBAA' },
              { value: 'Mixed Residential', color: '#E3C400' },
              { value: 'Rural Residential', color: '#E3C400' },
              { value: 'General Commercial', color: '#FF0000' },
              { value: 'General Office Use', color: '#9C0000' },
              { value: 'Retail and Commercial and Services', color: '#FF0000' },
              { value: 'Regional Shopping Center', color: '#FF0000' },
              { value: 'Other Commercial', color: '#FF0000' },
              { value: 'Public Facilities', color: '#7A8EF5' },
              { value: 'Education – K-12', color: '#77B3D7' },
              { value: 'Education – College', color: '#77B3D7' },
              { value: 'Military Installations', color: '#8400A8' },
              { value: 'General Industrial', color: '#0033CF' },
              { value: 'Light Industrial', color: '#0033CF' },
              { value: 'Light Manufacturing', color: '#0033CF' },
              {
                value: 'Light Manufacturing, Assembly, and Industrial Services',
                color: '#0033CF',
              },
              { value: 'Heavy Industrial', color: '#0033CF' },
              { value: 'Transportation', color: '#9C9C9C' },
              { value: 'Utility Facilities', color: '#9C9C9C' },
              { value: 'Mixed Commercial and Industrial', color: '#DF73FF' },
              { value: 'Mixed Residential and Commercial', color: '#A87000' },
              { value: 'Open Space and Recreation', color: '#4CE600' },
              { value: 'Golf Courses', color: '#4CE600' },
              { value: 'Local Parks and Recreation', color: '#4CE600' },
              {
                value: 'State and National Parks and Recreation',
                color: '#4CE600',
              },
              { value: 'Cemeteries', color: '#4CE600' },
              { value: 'Beach Parks', color: '#4CE600' },
              { value: 'Other Open Space and Recreation', color: '#4CE600' },
              { value: 'Agriculture', color: '#267300' },
              { value: 'Water', color: '#BEFFE8' },
              { value: 'Specific Plan', color: '#6B5600' },
              { value: 'Undevelopable or Protected Land', color: '#D1FF73' },
            ],
          },
          'fill-opacity': DEFAULT_OPACITY,
        },
        display_hints: {
          scale: SymbologyScales.CATEGORICAL,
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          theme: CUSTOM_THEME,
        },
      },
    ],

    scag_gp_code: [
      {
        type: SymbologyTypes.FILL,
        paint: {
          'fill-color': {
            property: 'scag_gp_code',
            type: PaintColorTypes.CATEGORICAL,
            stops: [
              { color: '#CD6666', value: '1000' },
              { color: '#E3C400', value: '1100' },
              { color: colors.yellow.a200, value: '1110' },
              { color: colors.yellow.a200, value: '1111' },
              { color: colors.yellow.a200, value: '1112' },
              { color: colors.yellow.a200, value: '1113' },
              { color: '#FFAA00', value: '1120' },
              { color: '#FFAA00', value: '1121' },
              { color: '#FFAA00', value: '1122' },
              { color: '#FFAA00', value: '1123' },
              { color: '#FFAA00', value: '1124' },
              { color: '#FFAA00', value: '1125' },
              { color: '#FFEBAA', value: '1130' },
              { color: '#FFEBAA', value: '1131' },
              { color: '#FFEBAA', value: '1132' },
              { color: '#E3C400', value: '1140' },
              { color: '#FF0000', value: '1200' },
              { color: '#9C0000', value: '1210' },
              { color: '#9C0000', value: '1211' },
              { color: '#9C0000', value: '1212' },
              { color: '#9C0000', value: '1213' },
              { color: '#FF0000', value: '1220' },
              { color: '#FF0000', value: '1221' },
              { color: '#FF0000', value: '1222' },
              { color: '#FF0000', value: '1223' },
              { color: '#FF0000', value: '1230' },
              { color: '#FF0000', value: '1231' },
              { color: '#FF0000', value: '1232' },
              { color: '#FF0000', value: '1233' },
              { color: '#7A8EF5', value: '1240' },
              { color: '#7A8EF5', value: '1241' },
              { color: '#7A8EF5', value: '1242' },
              { color: '#7A8EF5', value: '1243' },
              { color: '#7A8EF5', value: '1244' },
              { color: '#7A8EF5', value: '1245' },
              { color: '#7A8EF5', value: '1246' },
              { color: '#7A8EF5', value: '1247' },
              { color: '#7A8EF5', value: '1250' },
              { color: '#7A8EF5', value: '1251' },
              { color: '#7A8EF5', value: '1252' },
              { color: '#7A8EF5', value: '1253' },
              { color: '#77B3D7', value: '1260' },
              { color: '#77B3D7', value: '1261' },
              { color: '#77B3D7', value: '1262' },
              { color: '#77B3D7', value: '1263' },
              { color: '#77B3D7', value: '1264' },
              { color: '#77B3D7', value: '1265' },
              { color: '#77B3D7', value: '1266' },
              { color: '#8400A8', value: '1270' },
              { color: '#8400A8', value: '1271' },
              { color: '#8400A8', value: '1272' },
              { color: '#8400A8', value: '1273' },
              { color: '#8400A8', value: '1274' },
              { color: '#8400A8', value: '1275' },
              { color: '#8400A8', value: '1276' },
              { color: '#0033CF', value: '1300' },
              { color: '#0033CF', value: '1310' },
              { color: '#0033CF', value: '1311' },
              { color: '#0033CF', value: '1312' },
              { color: '#0033CF', value: '1313' },
              { color: '#0033CF', value: '1314' },
              { color: '#0033CF', value: '1320' },
              { color: '#0033CF', value: '1321' },
              { color: '#0033CF', value: '1322' },
              { color: '#0033CF', value: '1323' },
              { color: '#0033CF', value: '1324' },
              { color: '#0033CF', value: '1325' },
              { color: '#0033CF', value: '1330' },
              { color: '#0033CF', value: '1331' },
              { color: '#0033CF', value: '1332' },
              { color: '#0033CF', value: '1340' },
              { color: '#9C9C9C', value: '1400' },
              { color: '#9C9C9C', value: '1410' },
              { color: '#9C9C9C', value: '1411' },
              { color: '#9C9C9C', value: '1412' },
              { color: '#9C9C9C', value: '1413' },
              { color: '#9C9C9C', value: '1414' },
              { color: '#9C9C9C', value: '1415' },
              { color: '#9C9C9C', value: '1416' },
              { color: '#9C9C9C', value: '1417' },
              { color: '#9C9C9C', value: '1418' },
              { color: '#9C9C9C', value: '1420' },
              { color: '#9C9C9C', value: '1430' },
              { color: '#9C9C9C', value: '1431' },
              { color: '#9C9C9C', value: '1432' },
              { color: '#9C9C9C', value: '1433' },
              { color: '#9C9C9C', value: '1434' },
              { color: '#9C9C9C', value: '1435' },
              { color: '#9C9C9C', value: '1436' },
              { color: '#9C9C9C', value: '1437' },
              { color: '#9C9C9C', value: '1438' },
              { color: '#9C9C9C', value: '1440' },
              { color: '#9C9C9C', value: '1441' },
              { color: '#9C9C9C', value: '1442' },
              { color: '#9C9C9C', value: '1450' },
              { color: '#9C9C9C', value: '1460' },
              { color: '#DF73FF', value: '1500' },
              { color: '#A87000', value: '1600' },
              { color: '#595959', value: '1700' },
              { color: '#4CE600', value: '1800' },
              { color: '#4CE600', value: '1810' },
              { color: '#4CE600', value: '1820' },
              { color: '#4CE600', value: '1830' },
              { color: '#4CE600', value: '1840' },
              { color: '#4CE600', value: '1850' },
              { color: '#4CE600', value: '1860' },
              { color: '#4CE600', value: '1870' },
              { color: '#4CE600', value: '1880' },
              { color: '#A8A800', value: '1900' },
              { color: '#267300', value: '2000' },
              { color: '#267300', value: '2100' },
              { color: '#267300', value: '2110' },
              { color: '#267300', value: '2120' },
              { color: '#267300', value: '2200' },
              { color: '#267300', value: '2300' },
              { color: '#267300', value: '2400' },
              { color: '#267300', value: '2500' },
              { color: '#267300', value: '2600' },
              { color: '#267300', value: '2700' },
              { color: '#A8A800', value: '3000' },
              { color: '#A8A800', value: '3100' },
              { color: '#A8A800', value: '3200' },
              { color: '#A8A800', value: '3300' },
              { color: '#A8A800', value: '3400' },
              { color: '#BEFFE8', value: '4000' },
              { color: '#BEFFE8', value: '4100' },
              { color: '#BEFFE8', value: '4200' },
              { color: '#BEFFE8', value: '4300' },
              { color: '#BEFFE8', value: '4400' },
              { color: '#BEFFE8', value: '4500' },
              { color: '#D1FF73', value: '8888' },
              { color: '#CCCCCC', value: '9999' },
            ],
          },
          'fill-opacity': DEFAULT_OPACITY,
        },
        display_hints: {
          scale: SymbologyScales.CATEGORICAL,
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          theme: CUSTOM_THEME,
        },
      },
    ],
  },

  'public/dataset/california_climate_zones': {
    forecasting_climate_zone_id: [
      {
        type: SymbologyTypes.FILL,
        paint: {
          'fill-color': {
            property: 'forecasting_climate_zone_id',
            type: PaintColorTypes.CATEGORICAL,
            stops: [
              { value: 'CZ1', label: 'Arcata', color: '#4a99fe' },
              { value: 'CZ2', label: 'Santa Rosa', color: '#72d9ec' },
              { value: 'CZ3', label: 'Oakland', color: '#83bff5' },
              { value: 'CZ4', label: 'San-Jose-Reid', color: '#77c3d3' },
              { value: 'CZ5', label: 'Santa Maria', color: '#779dc2' },
              { value: 'CZ6', label: 'Torrance', color: '#72d4fb' },
              { value: 'CZ7', label: 'San Diego-Lindbergh', color: '#5481b8' },
              { value: 'CZ8', label: 'Fullerton', color: '#d5fff1' },
              { value: 'CZ9', label: 'Burbank-Glendale', color: '#ccc8c5' },
              { value: 'CZ10', label: 'Riverside', color: '#6ebbe7' },
              { value: 'CZ11', label: 'Red Bluff', color: '#d2f0f8' },
              { value: 'CZ12', label: 'Sacramento', color: '#bababc' },
              { value: 'CZ13', label: 'Fresno', color: '#acdbf9' },
              { value: 'CZ14', label: 'Palmdale', color: '#a2d3cd' },
              { value: 'CZ15', label: 'Palm Springs-Intl', color: '#99e7f4' },
              { value: 'CZ16', label: 'Blue Canyon', color: '#94bdd1' },
            ],
          },
          'fill-opacity': DEFAULT_OPACITY,
        },
        display_hints: {
          scale: SymbologyScales.CATEGORICAL,
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          theme: CUSTOM_THEME,
        },
      },
    ],
  },
};

export default symbologies;
