import invert from 'lodash/invert';
import { createSelector } from 'reselect';
import { makeGetFromPropsSelector } from 'uf/base/selector';
import { makeGetProjectById } from 'uf/projects/selectors';
import { makeVirtualIdMapForProject } from 'uf/projects/virtualLayers';
import { ScenarioId } from 'uf/scenarios';

/** Get the virtual layerId map for the requested project  */

export function makeGetProjectLayerIdMap() {
  return createSelector(makeGetProjectById(), project =>
    makeVirtualIdMapForProject(project),
  );
}

export function makeGetScenarioLayerIdMap() {
  return createSelector(
    makeGetProjectById(),
    makeGetFromPropsSelector<ScenarioId, 'scenarioId'>('scenarioId'),
    (project, scenarioId) => makeVirtualIdMapForProject(project, scenarioId),
  );
}

/*
 * Provide a way to lookup from virtual layer to the layer id
 */
export function makeGetProjectVirtualLayerIdMap() {
  return createSelector(makeGetProjectById(), project =>
    invert(makeVirtualIdMapForProject(project)),
  );
}
