import MaterialColors from 'material-colors';

import { LayerId } from 'uf/layers';
import {
  CuratedColumnSymbology,
  DEFAULT_CATEGORICAL_DISPLAY_HINTS,
  LayerColumnSymbology,
  SymbologyTypes,
} from 'uf/symbology';
import { makeStyleSymbologyId } from 'uf/symbology/spec/id';

const SELECTION_COLUMN_KEY = '_selection_';

// Selection symbologies are special, partial symbologies.
// They are only used when generating style layers for the map.
const fillSelectionSymbology: CuratedColumnSymbology = {
  type: SymbologyTypes.FILL,
  paint: {
    // very faint fill layer
    'fill-color': MaterialColors.grey[900],
    'fill-opacity': 0.01,
  },
};

const lineSelectionSymbology: CuratedColumnSymbology = {
  type: SymbologyTypes.LINE,
  paint: {
    'line-color': MaterialColors.cyan[500],
    'line-width': 2,
  },
};

const circleSelectionSymbology: CuratedColumnSymbology = {
  type: SymbologyTypes.CIRCLE,
  paint: {
    'circle-stroke-color': MaterialColors.cyan[500],
    'circle-radius': 2,

    // By default mapbox will render circle-color as black, so we might as well be explicit about
    // it since `circle-color` is required by our types.
    'circle-color': 'black',
  },
};

export function makeSelectionSymbology(
  layerId: LayerId,
  type: SymbologyTypes,
): LayerColumnSymbology {
  const id = makeStyleSymbologyId(layerId, SELECTION_COLUMN_KEY, type);

  switch (type) {
    case SymbologyTypes.FILL:
      return {
        ...fillSelectionSymbology,
        id,
        display_hints: DEFAULT_CATEGORICAL_DISPLAY_HINTS,
      };

    case SymbologyTypes.LINE:
      return {
        ...lineSelectionSymbology,
        id,
        display_hints: DEFAULT_CATEGORICAL_DISPLAY_HINTS,
      };

    case SymbologyTypes.CIRCLE:
      return {
        ...circleSelectionSymbology,
        id,
        display_hints: DEFAULT_CATEGORICAL_DISPLAY_HINTS,
      };

    default:
      console.warn(`
        Unkown symbology type ${type}.
        Could not create selection symbology for layer ${layerId}`);
      return {} as LayerColumnSymbology;
  }
}
