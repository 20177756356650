/**
 * UrbanFootprint App API
 * API for serving UrbanFootprint Application
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { ClientResponse } from '../variables';

// @ts-ignore: This is used only for params, but some services have param-free APIs
import { BuiltformsServiceInterface } from './builtforms.serviceInterface';

// @ts-ignore: These imports are for both params and returnType, but we only use returnType
import { Building } from '../model/building';
// @ts-ignore: These imports are for both params and returnType, but we only use returnType
import { BuildingInfo } from '../model/buildingInfo';
// @ts-ignore: These imports are for both params and returnType, but we only use returnType
import { BuildingType } from '../model/buildingType';
// @ts-ignore: These imports are for both params and returnType, but we only use returnType
import { BuildingTypeInfo } from '../model/buildingTypeInfo';
// @ts-ignore: These imports are for both params and returnType, but we only use returnType
import { Library } from '../model/library';
// @ts-ignore: These imports are for both params and returnType, but we only use returnType
import { LibraryBuildingPreviewUpdateData } from '../model/libraryBuildingPreviewUpdateData';
// @ts-ignore: These imports are for both params and returnType, but we only use returnType
import { LibraryDefaults } from '../model/libraryDefaults';
// @ts-ignore: These imports are for both params and returnType, but we only use returnType
import { LibraryVariables } from '../model/libraryVariables';
// @ts-ignore: These imports are for both params and returnType, but we only use returnType
import { PlaceType } from '../model/placeType';
// @ts-ignore: These imports are for both params and returnType, but we only use returnType
import { PlaceTypeInfo } from '../model/placeTypeInfo';
// @ts-ignore: These imports are for both params and returnType, but we only use returnType
import { ProblemDetail } from '../model/problemDetail';

/**
 * Package: api
 */

const createBuildingPrefix = `uf-api/builtforms/create_building/`;
/**
 * Action types for builtforms.create_building
 *
 * Create a new building in a library.
 */
export const createBuildingActionTypes = {
  LOAD: `${createBuildingPrefix}LOAD` as 'uf-api/builtforms/create_building/LOAD',
  SUCCESS:
    `${createBuildingPrefix}SUCCESS` as 'uf-api/builtforms/create_building/SUCCESS',
  FAILURE:
    `${createBuildingPrefix}FAILURE` as 'uf-api/builtforms/create_building/FAILURE',
  CLEAR:
    `${createBuildingPrefix}CLEAR` as 'uf-api/builtforms/create_building/CLEAR',
} as const;

/**
 * LOAD action for builtforms.create_building
 * Create a new building in a library.
 */
export interface createBuildingLoadAction {
  readonly type: 'uf-api/builtforms/create_building/LOAD';
  readonly params: BuiltformsServiceInterface.createBuildingParams;
  readonly promise: Promise<ClientResponse<Building>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for builtforms.create_building
 * Create a new building in a library.
 */
export interface createBuildingSuccessAction {
  readonly type: 'uf-api/builtforms/create_building/SUCCESS';
  readonly result: Building;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for builtforms.create_building
 * Create a new building in a library.
 */
export interface createBuildingFailureAction {
  readonly type: 'uf-api/builtforms/create_building/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for builtforms.create_building
 * Create a new building in a library.
 */
export interface createBuildingClearAction {
  readonly type: 'uf-api/builtforms/create_building/CLEAR';
  readonly key: string;
}

const createBuildingTypePrefix = `uf-api/builtforms/create_building_type/`;
/**
 * Action types for builtforms.create_building_type
 *
 * Create a new building type in a library.
 */
export const createBuildingTypeActionTypes = {
  LOAD: `${createBuildingTypePrefix}LOAD` as 'uf-api/builtforms/create_building_type/LOAD',
  SUCCESS:
    `${createBuildingTypePrefix}SUCCESS` as 'uf-api/builtforms/create_building_type/SUCCESS',
  FAILURE:
    `${createBuildingTypePrefix}FAILURE` as 'uf-api/builtforms/create_building_type/FAILURE',
  CLEAR:
    `${createBuildingTypePrefix}CLEAR` as 'uf-api/builtforms/create_building_type/CLEAR',
} as const;

/**
 * LOAD action for builtforms.create_building_type
 * Create a new building type in a library.
 */
export interface createBuildingTypeLoadAction {
  readonly type: 'uf-api/builtforms/create_building_type/LOAD';
  readonly params: BuiltformsServiceInterface.createBuildingTypeParams;
  readonly promise: Promise<ClientResponse<BuildingType>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for builtforms.create_building_type
 * Create a new building type in a library.
 */
export interface createBuildingTypeSuccessAction {
  readonly type: 'uf-api/builtforms/create_building_type/SUCCESS';
  readonly result: BuildingType;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for builtforms.create_building_type
 * Create a new building type in a library.
 */
export interface createBuildingTypeFailureAction {
  readonly type: 'uf-api/builtforms/create_building_type/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for builtforms.create_building_type
 * Create a new building type in a library.
 */
export interface createBuildingTypeClearAction {
  readonly type: 'uf-api/builtforms/create_building_type/CLEAR';
  readonly key: string;
}

const createPlaceTypePrefix = `uf-api/builtforms/create_place_type/`;
/**
 * Action types for builtforms.create_place_type
 *
 * Create a new place type in a library.
 */
export const createPlaceTypeActionTypes = {
  LOAD: `${createPlaceTypePrefix}LOAD` as 'uf-api/builtforms/create_place_type/LOAD',
  SUCCESS:
    `${createPlaceTypePrefix}SUCCESS` as 'uf-api/builtforms/create_place_type/SUCCESS',
  FAILURE:
    `${createPlaceTypePrefix}FAILURE` as 'uf-api/builtforms/create_place_type/FAILURE',
  CLEAR:
    `${createPlaceTypePrefix}CLEAR` as 'uf-api/builtforms/create_place_type/CLEAR',
} as const;

/**
 * LOAD action for builtforms.create_place_type
 * Create a new place type in a library.
 */
export interface createPlaceTypeLoadAction {
  readonly type: 'uf-api/builtforms/create_place_type/LOAD';
  readonly params: BuiltformsServiceInterface.createPlaceTypeParams;
  readonly promise: Promise<ClientResponse<PlaceType>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for builtforms.create_place_type
 * Create a new place type in a library.
 */
export interface createPlaceTypeSuccessAction {
  readonly type: 'uf-api/builtforms/create_place_type/SUCCESS';
  readonly result: PlaceType;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for builtforms.create_place_type
 * Create a new place type in a library.
 */
export interface createPlaceTypeFailureAction {
  readonly type: 'uf-api/builtforms/create_place_type/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for builtforms.create_place_type
 * Create a new place type in a library.
 */
export interface createPlaceTypeClearAction {
  readonly type: 'uf-api/builtforms/create_place_type/CLEAR';
  readonly key: string;
}

const deleteBuildingPrefix = `uf-api/builtforms/delete_building/`;
/**
 * Action types for builtforms.delete_building
 *
 * Delete a building in a library.
 */
export const deleteBuildingActionTypes = {
  LOAD: `${deleteBuildingPrefix}LOAD` as 'uf-api/builtforms/delete_building/LOAD',
  SUCCESS:
    `${deleteBuildingPrefix}SUCCESS` as 'uf-api/builtforms/delete_building/SUCCESS',
  FAILURE:
    `${deleteBuildingPrefix}FAILURE` as 'uf-api/builtforms/delete_building/FAILURE',
  CLEAR:
    `${deleteBuildingPrefix}CLEAR` as 'uf-api/builtforms/delete_building/CLEAR',
} as const;

/**
 * LOAD action for builtforms.delete_building
 * Delete a building in a library.
 */
export interface deleteBuildingLoadAction {
  readonly type: 'uf-api/builtforms/delete_building/LOAD';
  readonly params: BuiltformsServiceInterface.deleteBuildingParams;
  readonly promise: Promise<ClientResponse<{}>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for builtforms.delete_building
 * Delete a building in a library.
 */
export interface deleteBuildingSuccessAction {
  readonly type: 'uf-api/builtforms/delete_building/SUCCESS';
  readonly result: {};
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for builtforms.delete_building
 * Delete a building in a library.
 */
export interface deleteBuildingFailureAction {
  readonly type: 'uf-api/builtforms/delete_building/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for builtforms.delete_building
 * Delete a building in a library.
 */
export interface deleteBuildingClearAction {
  readonly type: 'uf-api/builtforms/delete_building/CLEAR';
  readonly key: string;
}

const deleteBuildingTypePrefix = `uf-api/builtforms/delete_building_type/`;
/**
 * Action types for builtforms.delete_building_type
 *
 * Delete a building type in a library.
 */
export const deleteBuildingTypeActionTypes = {
  LOAD: `${deleteBuildingTypePrefix}LOAD` as 'uf-api/builtforms/delete_building_type/LOAD',
  SUCCESS:
    `${deleteBuildingTypePrefix}SUCCESS` as 'uf-api/builtforms/delete_building_type/SUCCESS',
  FAILURE:
    `${deleteBuildingTypePrefix}FAILURE` as 'uf-api/builtforms/delete_building_type/FAILURE',
  CLEAR:
    `${deleteBuildingTypePrefix}CLEAR` as 'uf-api/builtforms/delete_building_type/CLEAR',
} as const;

/**
 * LOAD action for builtforms.delete_building_type
 * Delete a building type in a library.
 */
export interface deleteBuildingTypeLoadAction {
  readonly type: 'uf-api/builtforms/delete_building_type/LOAD';
  readonly params: BuiltformsServiceInterface.deleteBuildingTypeParams;
  readonly promise: Promise<ClientResponse<{}>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for builtforms.delete_building_type
 * Delete a building type in a library.
 */
export interface deleteBuildingTypeSuccessAction {
  readonly type: 'uf-api/builtforms/delete_building_type/SUCCESS';
  readonly result: {};
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for builtforms.delete_building_type
 * Delete a building type in a library.
 */
export interface deleteBuildingTypeFailureAction {
  readonly type: 'uf-api/builtforms/delete_building_type/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for builtforms.delete_building_type
 * Delete a building type in a library.
 */
export interface deleteBuildingTypeClearAction {
  readonly type: 'uf-api/builtforms/delete_building_type/CLEAR';
  readonly key: string;
}

const deletePlaceTypePrefix = `uf-api/builtforms/delete_place_type/`;
/**
 * Action types for builtforms.delete_place_type
 *
 * Delete a place type in a library.
 */
export const deletePlaceTypeActionTypes = {
  LOAD: `${deletePlaceTypePrefix}LOAD` as 'uf-api/builtforms/delete_place_type/LOAD',
  SUCCESS:
    `${deletePlaceTypePrefix}SUCCESS` as 'uf-api/builtforms/delete_place_type/SUCCESS',
  FAILURE:
    `${deletePlaceTypePrefix}FAILURE` as 'uf-api/builtforms/delete_place_type/FAILURE',
  CLEAR:
    `${deletePlaceTypePrefix}CLEAR` as 'uf-api/builtforms/delete_place_type/CLEAR',
} as const;

/**
 * LOAD action for builtforms.delete_place_type
 * Delete a place type in a library.
 */
export interface deletePlaceTypeLoadAction {
  readonly type: 'uf-api/builtforms/delete_place_type/LOAD';
  readonly params: BuiltformsServiceInterface.deletePlaceTypeParams;
  readonly promise: Promise<ClientResponse<{}>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for builtforms.delete_place_type
 * Delete a place type in a library.
 */
export interface deletePlaceTypeSuccessAction {
  readonly type: 'uf-api/builtforms/delete_place_type/SUCCESS';
  readonly result: {};
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for builtforms.delete_place_type
 * Delete a place type in a library.
 */
export interface deletePlaceTypeFailureAction {
  readonly type: 'uf-api/builtforms/delete_place_type/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for builtforms.delete_place_type
 * Delete a place type in a library.
 */
export interface deletePlaceTypeClearAction {
  readonly type: 'uf-api/builtforms/delete_place_type/CLEAR';
  readonly key: string;
}

const getBuildingPrefix = `uf-api/builtforms/get_building/`;
/**
 * Action types for builtforms.get_building
 *
 * Get a building
 */
export const getBuildingActionTypes = {
  LOAD: `${getBuildingPrefix}LOAD` as 'uf-api/builtforms/get_building/LOAD',
  SUCCESS:
    `${getBuildingPrefix}SUCCESS` as 'uf-api/builtforms/get_building/SUCCESS',
  FAILURE:
    `${getBuildingPrefix}FAILURE` as 'uf-api/builtforms/get_building/FAILURE',
  CLEAR: `${getBuildingPrefix}CLEAR` as 'uf-api/builtforms/get_building/CLEAR',
} as const;

/**
 * LOAD action for builtforms.get_building
 * Get a building
 */
export interface getBuildingLoadAction {
  readonly type: 'uf-api/builtforms/get_building/LOAD';
  readonly params: BuiltformsServiceInterface.getBuildingParams;
  readonly promise: Promise<ClientResponse<Building>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for builtforms.get_building
 * Get a building
 */
export interface getBuildingSuccessAction {
  readonly type: 'uf-api/builtforms/get_building/SUCCESS';
  readonly result: Building;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for builtforms.get_building
 * Get a building
 */
export interface getBuildingFailureAction {
  readonly type: 'uf-api/builtforms/get_building/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for builtforms.get_building
 * Get a building
 */
export interface getBuildingClearAction {
  readonly type: 'uf-api/builtforms/get_building/CLEAR';
  readonly key: string;
}

const getBuildingPreviewPrefix = `uf-api/builtforms/get_building_preview/`;
/**
 * Action types for builtforms.get_building_preview
 *
 * Preview the effect of changing a single property.
 */
export const getBuildingPreviewActionTypes = {
  LOAD: `${getBuildingPreviewPrefix}LOAD` as 'uf-api/builtforms/get_building_preview/LOAD',
  SUCCESS:
    `${getBuildingPreviewPrefix}SUCCESS` as 'uf-api/builtforms/get_building_preview/SUCCESS',
  FAILURE:
    `${getBuildingPreviewPrefix}FAILURE` as 'uf-api/builtforms/get_building_preview/FAILURE',
  CLEAR:
    `${getBuildingPreviewPrefix}CLEAR` as 'uf-api/builtforms/get_building_preview/CLEAR',
} as const;

/**
 * LOAD action for builtforms.get_building_preview
 * Preview the effect of changing a single property.
 */
export interface getBuildingPreviewLoadAction {
  readonly type: 'uf-api/builtforms/get_building_preview/LOAD';
  readonly params: BuiltformsServiceInterface.getBuildingPreviewParams;
  readonly promise: Promise<ClientResponse<Building>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for builtforms.get_building_preview
 * Preview the effect of changing a single property.
 */
export interface getBuildingPreviewSuccessAction {
  readonly type: 'uf-api/builtforms/get_building_preview/SUCCESS';
  readonly result: Building;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for builtforms.get_building_preview
 * Preview the effect of changing a single property.
 */
export interface getBuildingPreviewFailureAction {
  readonly type: 'uf-api/builtforms/get_building_preview/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for builtforms.get_building_preview
 * Preview the effect of changing a single property.
 */
export interface getBuildingPreviewClearAction {
  readonly type: 'uf-api/builtforms/get_building_preview/CLEAR';
  readonly key: string;
}

const getBuildingPreviewMultiPrefix = `uf-api/builtforms/get_building_preview_multi/`;
/**
 * Action types for builtforms.get_building_preview_multi
 *
 * Preview the effect of changing some properties.
 */
export const getBuildingPreviewMultiActionTypes = {
  LOAD: `${getBuildingPreviewMultiPrefix}LOAD` as 'uf-api/builtforms/get_building_preview_multi/LOAD',
  SUCCESS:
    `${getBuildingPreviewMultiPrefix}SUCCESS` as 'uf-api/builtforms/get_building_preview_multi/SUCCESS',
  FAILURE:
    `${getBuildingPreviewMultiPrefix}FAILURE` as 'uf-api/builtforms/get_building_preview_multi/FAILURE',
  CLEAR:
    `${getBuildingPreviewMultiPrefix}CLEAR` as 'uf-api/builtforms/get_building_preview_multi/CLEAR',
} as const;

/**
 * LOAD action for builtforms.get_building_preview_multi
 * Preview the effect of changing some properties.
 */
export interface getBuildingPreviewMultiLoadAction {
  readonly type: 'uf-api/builtforms/get_building_preview_multi/LOAD';
  readonly params: BuiltformsServiceInterface.getBuildingPreviewMultiParams;
  readonly promise: Promise<ClientResponse<Building>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for builtforms.get_building_preview_multi
 * Preview the effect of changing some properties.
 */
export interface getBuildingPreviewMultiSuccessAction {
  readonly type: 'uf-api/builtforms/get_building_preview_multi/SUCCESS';
  readonly result: Building;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for builtforms.get_building_preview_multi
 * Preview the effect of changing some properties.
 */
export interface getBuildingPreviewMultiFailureAction {
  readonly type: 'uf-api/builtforms/get_building_preview_multi/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for builtforms.get_building_preview_multi
 * Preview the effect of changing some properties.
 */
export interface getBuildingPreviewMultiClearAction {
  readonly type: 'uf-api/builtforms/get_building_preview_multi/CLEAR';
  readonly key: string;
}

const getBuildingTypePrefix = `uf-api/builtforms/get_building_type/`;
/**
 * Action types for builtforms.get_building_type
 *
 * Get a building type
 */
export const getBuildingTypeActionTypes = {
  LOAD: `${getBuildingTypePrefix}LOAD` as 'uf-api/builtforms/get_building_type/LOAD',
  SUCCESS:
    `${getBuildingTypePrefix}SUCCESS` as 'uf-api/builtforms/get_building_type/SUCCESS',
  FAILURE:
    `${getBuildingTypePrefix}FAILURE` as 'uf-api/builtforms/get_building_type/FAILURE',
  CLEAR:
    `${getBuildingTypePrefix}CLEAR` as 'uf-api/builtforms/get_building_type/CLEAR',
} as const;

/**
 * LOAD action for builtforms.get_building_type
 * Get a building type
 */
export interface getBuildingTypeLoadAction {
  readonly type: 'uf-api/builtforms/get_building_type/LOAD';
  readonly params: BuiltformsServiceInterface.getBuildingTypeParams;
  readonly promise: Promise<ClientResponse<BuildingType>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for builtforms.get_building_type
 * Get a building type
 */
export interface getBuildingTypeSuccessAction {
  readonly type: 'uf-api/builtforms/get_building_type/SUCCESS';
  readonly result: BuildingType;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for builtforms.get_building_type
 * Get a building type
 */
export interface getBuildingTypeFailureAction {
  readonly type: 'uf-api/builtforms/get_building_type/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for builtforms.get_building_type
 * Get a building type
 */
export interface getBuildingTypeClearAction {
  readonly type: 'uf-api/builtforms/get_building_type/CLEAR';
  readonly key: string;
}

const getBuildingTypePreviewPrefix = `uf-api/builtforms/get_building_type_preview/`;
/**
 * Action types for builtforms.get_building_type_preview
 *
 * Preview the effect of changing a single property.
 */
export const getBuildingTypePreviewActionTypes = {
  LOAD: `${getBuildingTypePreviewPrefix}LOAD` as 'uf-api/builtforms/get_building_type_preview/LOAD',
  SUCCESS:
    `${getBuildingTypePreviewPrefix}SUCCESS` as 'uf-api/builtforms/get_building_type_preview/SUCCESS',
  FAILURE:
    `${getBuildingTypePreviewPrefix}FAILURE` as 'uf-api/builtforms/get_building_type_preview/FAILURE',
  CLEAR:
    `${getBuildingTypePreviewPrefix}CLEAR` as 'uf-api/builtforms/get_building_type_preview/CLEAR',
} as const;

/**
 * LOAD action for builtforms.get_building_type_preview
 * Preview the effect of changing a single property.
 */
export interface getBuildingTypePreviewLoadAction {
  readonly type: 'uf-api/builtforms/get_building_type_preview/LOAD';
  readonly params: BuiltformsServiceInterface.getBuildingTypePreviewParams;
  readonly promise: Promise<ClientResponse<BuildingType>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for builtforms.get_building_type_preview
 * Preview the effect of changing a single property.
 */
export interface getBuildingTypePreviewSuccessAction {
  readonly type: 'uf-api/builtforms/get_building_type_preview/SUCCESS';
  readonly result: BuildingType;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for builtforms.get_building_type_preview
 * Preview the effect of changing a single property.
 */
export interface getBuildingTypePreviewFailureAction {
  readonly type: 'uf-api/builtforms/get_building_type_preview/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for builtforms.get_building_type_preview
 * Preview the effect of changing a single property.
 */
export interface getBuildingTypePreviewClearAction {
  readonly type: 'uf-api/builtforms/get_building_type_preview/CLEAR';
  readonly key: string;
}

const getLibraryPrefix = `uf-api/builtforms/get_library/`;
/**
 * Action types for builtforms.get_library
 *
 * Get a Library
 */
export const getLibraryActionTypes = {
  LOAD: `${getLibraryPrefix}LOAD` as 'uf-api/builtforms/get_library/LOAD',
  SUCCESS:
    `${getLibraryPrefix}SUCCESS` as 'uf-api/builtforms/get_library/SUCCESS',
  FAILURE:
    `${getLibraryPrefix}FAILURE` as 'uf-api/builtforms/get_library/FAILURE',
  CLEAR: `${getLibraryPrefix}CLEAR` as 'uf-api/builtforms/get_library/CLEAR',
} as const;

/**
 * LOAD action for builtforms.get_library
 * Get a Library
 */
export interface getLibraryLoadAction {
  readonly type: 'uf-api/builtforms/get_library/LOAD';
  readonly params: BuiltformsServiceInterface.getLibraryParams;
  readonly promise: Promise<ClientResponse<Library>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for builtforms.get_library
 * Get a Library
 */
export interface getLibrarySuccessAction {
  readonly type: 'uf-api/builtforms/get_library/SUCCESS';
  readonly result: Library;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for builtforms.get_library
 * Get a Library
 */
export interface getLibraryFailureAction {
  readonly type: 'uf-api/builtforms/get_library/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for builtforms.get_library
 * Get a Library
 */
export interface getLibraryClearAction {
  readonly type: 'uf-api/builtforms/get_library/CLEAR';
  readonly key: string;
}

const getLibraryBuildingTypesPrefix = `uf-api/builtforms/get_library_building_types/`;
/**
 * Action types for builtforms.get_library_building_types
 *
 * Get list of buildings-types in a library.
 */
export const getLibraryBuildingTypesActionTypes = {
  LOAD: `${getLibraryBuildingTypesPrefix}LOAD` as 'uf-api/builtforms/get_library_building_types/LOAD',
  SUCCESS:
    `${getLibraryBuildingTypesPrefix}SUCCESS` as 'uf-api/builtforms/get_library_building_types/SUCCESS',
  FAILURE:
    `${getLibraryBuildingTypesPrefix}FAILURE` as 'uf-api/builtforms/get_library_building_types/FAILURE',
  CLEAR:
    `${getLibraryBuildingTypesPrefix}CLEAR` as 'uf-api/builtforms/get_library_building_types/CLEAR',
} as const;

/**
 * LOAD action for builtforms.get_library_building_types
 * Get list of buildings-types in a library.
 */
export interface getLibraryBuildingTypesLoadAction {
  readonly type: 'uf-api/builtforms/get_library_building_types/LOAD';
  readonly params: BuiltformsServiceInterface.getLibraryBuildingTypesParams;
  readonly promise: Promise<ClientResponse<Array<BuildingTypeInfo>>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for builtforms.get_library_building_types
 * Get list of buildings-types in a library.
 */
export interface getLibraryBuildingTypesSuccessAction {
  readonly type: 'uf-api/builtforms/get_library_building_types/SUCCESS';
  readonly result: Array<BuildingTypeInfo>;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for builtforms.get_library_building_types
 * Get list of buildings-types in a library.
 */
export interface getLibraryBuildingTypesFailureAction {
  readonly type: 'uf-api/builtforms/get_library_building_types/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for builtforms.get_library_building_types
 * Get list of buildings-types in a library.
 */
export interface getLibraryBuildingTypesClearAction {
  readonly type: 'uf-api/builtforms/get_library_building_types/CLEAR';
  readonly key: string;
}

const getLibraryBuildingsPrefix = `uf-api/builtforms/get_library_buildings/`;
/**
 * Action types for builtforms.get_library_buildings
 *
 * Get list of buildings in a library.
 */
export const getLibraryBuildingsActionTypes = {
  LOAD: `${getLibraryBuildingsPrefix}LOAD` as 'uf-api/builtforms/get_library_buildings/LOAD',
  SUCCESS:
    `${getLibraryBuildingsPrefix}SUCCESS` as 'uf-api/builtforms/get_library_buildings/SUCCESS',
  FAILURE:
    `${getLibraryBuildingsPrefix}FAILURE` as 'uf-api/builtforms/get_library_buildings/FAILURE',
  CLEAR:
    `${getLibraryBuildingsPrefix}CLEAR` as 'uf-api/builtforms/get_library_buildings/CLEAR',
} as const;

/**
 * LOAD action for builtforms.get_library_buildings
 * Get list of buildings in a library.
 */
export interface getLibraryBuildingsLoadAction {
  readonly type: 'uf-api/builtforms/get_library_buildings/LOAD';
  readonly params: BuiltformsServiceInterface.getLibraryBuildingsParams;
  readonly promise: Promise<ClientResponse<Array<BuildingInfo>>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for builtforms.get_library_buildings
 * Get list of buildings in a library.
 */
export interface getLibraryBuildingsSuccessAction {
  readonly type: 'uf-api/builtforms/get_library_buildings/SUCCESS';
  readonly result: Array<BuildingInfo>;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for builtforms.get_library_buildings
 * Get list of buildings in a library.
 */
export interface getLibraryBuildingsFailureAction {
  readonly type: 'uf-api/builtforms/get_library_buildings/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for builtforms.get_library_buildings
 * Get list of buildings in a library.
 */
export interface getLibraryBuildingsClearAction {
  readonly type: 'uf-api/builtforms/get_library_buildings/CLEAR';
  readonly key: string;
}

const getLibraryPlaceTypesPrefix = `uf-api/builtforms/get_library_place_types/`;
/**
 * Action types for builtforms.get_library_place_types
 *
 * Get list of places-types in a library.
 */
export const getLibraryPlaceTypesActionTypes = {
  LOAD: `${getLibraryPlaceTypesPrefix}LOAD` as 'uf-api/builtforms/get_library_place_types/LOAD',
  SUCCESS:
    `${getLibraryPlaceTypesPrefix}SUCCESS` as 'uf-api/builtforms/get_library_place_types/SUCCESS',
  FAILURE:
    `${getLibraryPlaceTypesPrefix}FAILURE` as 'uf-api/builtforms/get_library_place_types/FAILURE',
  CLEAR:
    `${getLibraryPlaceTypesPrefix}CLEAR` as 'uf-api/builtforms/get_library_place_types/CLEAR',
} as const;

/**
 * LOAD action for builtforms.get_library_place_types
 * Get list of places-types in a library.
 */
export interface getLibraryPlaceTypesLoadAction {
  readonly type: 'uf-api/builtforms/get_library_place_types/LOAD';
  readonly params: BuiltformsServiceInterface.getLibraryPlaceTypesParams;
  readonly promise: Promise<ClientResponse<Array<PlaceTypeInfo>>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for builtforms.get_library_place_types
 * Get list of places-types in a library.
 */
export interface getLibraryPlaceTypesSuccessAction {
  readonly type: 'uf-api/builtforms/get_library_place_types/SUCCESS';
  readonly result: Array<PlaceTypeInfo>;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for builtforms.get_library_place_types
 * Get list of places-types in a library.
 */
export interface getLibraryPlaceTypesFailureAction {
  readonly type: 'uf-api/builtforms/get_library_place_types/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for builtforms.get_library_place_types
 * Get list of places-types in a library.
 */
export interface getLibraryPlaceTypesClearAction {
  readonly type: 'uf-api/builtforms/get_library_place_types/CLEAR';
  readonly key: string;
}

const getPlaceTypePrefix = `uf-api/builtforms/get_place_type/`;
/**
 * Action types for builtforms.get_place_type
 *
 * Get a place type
 */
export const getPlaceTypeActionTypes = {
  LOAD: `${getPlaceTypePrefix}LOAD` as 'uf-api/builtforms/get_place_type/LOAD',
  SUCCESS:
    `${getPlaceTypePrefix}SUCCESS` as 'uf-api/builtforms/get_place_type/SUCCESS',
  FAILURE:
    `${getPlaceTypePrefix}FAILURE` as 'uf-api/builtforms/get_place_type/FAILURE',
  CLEAR:
    `${getPlaceTypePrefix}CLEAR` as 'uf-api/builtforms/get_place_type/CLEAR',
} as const;

/**
 * LOAD action for builtforms.get_place_type
 * Get a place type
 */
export interface getPlaceTypeLoadAction {
  readonly type: 'uf-api/builtforms/get_place_type/LOAD';
  readonly params: BuiltformsServiceInterface.getPlaceTypeParams;
  readonly promise: Promise<ClientResponse<PlaceType>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for builtforms.get_place_type
 * Get a place type
 */
export interface getPlaceTypeSuccessAction {
  readonly type: 'uf-api/builtforms/get_place_type/SUCCESS';
  readonly result: PlaceType;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for builtforms.get_place_type
 * Get a place type
 */
export interface getPlaceTypeFailureAction {
  readonly type: 'uf-api/builtforms/get_place_type/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for builtforms.get_place_type
 * Get a place type
 */
export interface getPlaceTypeClearAction {
  readonly type: 'uf-api/builtforms/get_place_type/CLEAR';
  readonly key: string;
}

const getPlaceTypePreviewPrefix = `uf-api/builtforms/get_place_type_preview/`;
/**
 * Action types for builtforms.get_place_type_preview
 *
 * Preview the effect of changing a single property.
 */
export const getPlaceTypePreviewActionTypes = {
  LOAD: `${getPlaceTypePreviewPrefix}LOAD` as 'uf-api/builtforms/get_place_type_preview/LOAD',
  SUCCESS:
    `${getPlaceTypePreviewPrefix}SUCCESS` as 'uf-api/builtforms/get_place_type_preview/SUCCESS',
  FAILURE:
    `${getPlaceTypePreviewPrefix}FAILURE` as 'uf-api/builtforms/get_place_type_preview/FAILURE',
  CLEAR:
    `${getPlaceTypePreviewPrefix}CLEAR` as 'uf-api/builtforms/get_place_type_preview/CLEAR',
} as const;

/**
 * LOAD action for builtforms.get_place_type_preview
 * Preview the effect of changing a single property.
 */
export interface getPlaceTypePreviewLoadAction {
  readonly type: 'uf-api/builtforms/get_place_type_preview/LOAD';
  readonly params: BuiltformsServiceInterface.getPlaceTypePreviewParams;
  readonly promise: Promise<ClientResponse<PlaceType>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for builtforms.get_place_type_preview
 * Preview the effect of changing a single property.
 */
export interface getPlaceTypePreviewSuccessAction {
  readonly type: 'uf-api/builtforms/get_place_type_preview/SUCCESS';
  readonly result: PlaceType;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for builtforms.get_place_type_preview
 * Preview the effect of changing a single property.
 */
export interface getPlaceTypePreviewFailureAction {
  readonly type: 'uf-api/builtforms/get_place_type_preview/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for builtforms.get_place_type_preview
 * Preview the effect of changing a single property.
 */
export interface getPlaceTypePreviewClearAction {
  readonly type: 'uf-api/builtforms/get_place_type_preview/CLEAR';
  readonly key: string;
}

const updateBuildingPrefix = `uf-api/builtforms/update_building/`;
/**
 * Action types for builtforms.update_building
 *
 * Update a building
 */
export const updateBuildingActionTypes = {
  LOAD: `${updateBuildingPrefix}LOAD` as 'uf-api/builtforms/update_building/LOAD',
  SUCCESS:
    `${updateBuildingPrefix}SUCCESS` as 'uf-api/builtforms/update_building/SUCCESS',
  FAILURE:
    `${updateBuildingPrefix}FAILURE` as 'uf-api/builtforms/update_building/FAILURE',
  CLEAR:
    `${updateBuildingPrefix}CLEAR` as 'uf-api/builtforms/update_building/CLEAR',
} as const;

/**
 * LOAD action for builtforms.update_building
 * Update a building
 */
export interface updateBuildingLoadAction {
  readonly type: 'uf-api/builtforms/update_building/LOAD';
  readonly params: BuiltformsServiceInterface.updateBuildingParams;
  readonly promise: Promise<ClientResponse<{}>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for builtforms.update_building
 * Update a building
 */
export interface updateBuildingSuccessAction {
  readonly type: 'uf-api/builtforms/update_building/SUCCESS';
  readonly result: {};
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for builtforms.update_building
 * Update a building
 */
export interface updateBuildingFailureAction {
  readonly type: 'uf-api/builtforms/update_building/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for builtforms.update_building
 * Update a building
 */
export interface updateBuildingClearAction {
  readonly type: 'uf-api/builtforms/update_building/CLEAR';
  readonly key: string;
}

const updateBuildingTypePrefix = `uf-api/builtforms/update_building_type/`;
/**
 * Action types for builtforms.update_building_type
 *
 * Update a building type
 */
export const updateBuildingTypeActionTypes = {
  LOAD: `${updateBuildingTypePrefix}LOAD` as 'uf-api/builtforms/update_building_type/LOAD',
  SUCCESS:
    `${updateBuildingTypePrefix}SUCCESS` as 'uf-api/builtforms/update_building_type/SUCCESS',
  FAILURE:
    `${updateBuildingTypePrefix}FAILURE` as 'uf-api/builtforms/update_building_type/FAILURE',
  CLEAR:
    `${updateBuildingTypePrefix}CLEAR` as 'uf-api/builtforms/update_building_type/CLEAR',
} as const;

/**
 * LOAD action for builtforms.update_building_type
 * Update a building type
 */
export interface updateBuildingTypeLoadAction {
  readonly type: 'uf-api/builtforms/update_building_type/LOAD';
  readonly params: BuiltformsServiceInterface.updateBuildingTypeParams;
  readonly promise: Promise<ClientResponse<{}>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for builtforms.update_building_type
 * Update a building type
 */
export interface updateBuildingTypeSuccessAction {
  readonly type: 'uf-api/builtforms/update_building_type/SUCCESS';
  readonly result: {};
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for builtforms.update_building_type
 * Update a building type
 */
export interface updateBuildingTypeFailureAction {
  readonly type: 'uf-api/builtforms/update_building_type/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for builtforms.update_building_type
 * Update a building type
 */
export interface updateBuildingTypeClearAction {
  readonly type: 'uf-api/builtforms/update_building_type/CLEAR';
  readonly key: string;
}

const updateBuildingTypeInfosPrefix = `uf-api/builtforms/update_building_type_infos/`;
/**
 * Action types for builtforms.update_building_type_infos
 *
 * Update a list of BuildingTypeInfos
 */
export const updateBuildingTypeInfosActionTypes = {
  LOAD: `${updateBuildingTypeInfosPrefix}LOAD` as 'uf-api/builtforms/update_building_type_infos/LOAD',
  SUCCESS:
    `${updateBuildingTypeInfosPrefix}SUCCESS` as 'uf-api/builtforms/update_building_type_infos/SUCCESS',
  FAILURE:
    `${updateBuildingTypeInfosPrefix}FAILURE` as 'uf-api/builtforms/update_building_type_infos/FAILURE',
  CLEAR:
    `${updateBuildingTypeInfosPrefix}CLEAR` as 'uf-api/builtforms/update_building_type_infos/CLEAR',
} as const;

/**
 * LOAD action for builtforms.update_building_type_infos
 * Update a list of BuildingTypeInfos
 */
export interface updateBuildingTypeInfosLoadAction {
  readonly type: 'uf-api/builtforms/update_building_type_infos/LOAD';
  readonly params: BuiltformsServiceInterface.updateBuildingTypeInfosParams;
  readonly promise: Promise<ClientResponse<{}>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for builtforms.update_building_type_infos
 * Update a list of BuildingTypeInfos
 */
export interface updateBuildingTypeInfosSuccessAction {
  readonly type: 'uf-api/builtforms/update_building_type_infos/SUCCESS';
  readonly result: {};
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for builtforms.update_building_type_infos
 * Update a list of BuildingTypeInfos
 */
export interface updateBuildingTypeInfosFailureAction {
  readonly type: 'uf-api/builtforms/update_building_type_infos/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for builtforms.update_building_type_infos
 * Update a list of BuildingTypeInfos
 */
export interface updateBuildingTypeInfosClearAction {
  readonly type: 'uf-api/builtforms/update_building_type_infos/CLEAR';
  readonly key: string;
}

const updateLibraryDefaultsPrefix = `uf-api/builtforms/update_library_defaults/`;
/**
 * Action types for builtforms.update_library_defaults
 *
 * Update a library\&#39;s defaults
 */
export const updateLibraryDefaultsActionTypes = {
  LOAD: `${updateLibraryDefaultsPrefix}LOAD` as 'uf-api/builtforms/update_library_defaults/LOAD',
  SUCCESS:
    `${updateLibraryDefaultsPrefix}SUCCESS` as 'uf-api/builtforms/update_library_defaults/SUCCESS',
  FAILURE:
    `${updateLibraryDefaultsPrefix}FAILURE` as 'uf-api/builtforms/update_library_defaults/FAILURE',
  CLEAR:
    `${updateLibraryDefaultsPrefix}CLEAR` as 'uf-api/builtforms/update_library_defaults/CLEAR',
} as const;

/**
 * LOAD action for builtforms.update_library_defaults
 * Update a library\&#39;s defaults
 */
export interface updateLibraryDefaultsLoadAction {
  readonly type: 'uf-api/builtforms/update_library_defaults/LOAD';
  readonly params: BuiltformsServiceInterface.updateLibraryDefaultsParams;
  readonly promise: Promise<ClientResponse<{}>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for builtforms.update_library_defaults
 * Update a library\&#39;s defaults
 */
export interface updateLibraryDefaultsSuccessAction {
  readonly type: 'uf-api/builtforms/update_library_defaults/SUCCESS';
  readonly result: {};
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for builtforms.update_library_defaults
 * Update a library\&#39;s defaults
 */
export interface updateLibraryDefaultsFailureAction {
  readonly type: 'uf-api/builtforms/update_library_defaults/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for builtforms.update_library_defaults
 * Update a library\&#39;s defaults
 */
export interface updateLibraryDefaultsClearAction {
  readonly type: 'uf-api/builtforms/update_library_defaults/CLEAR';
  readonly key: string;
}

const updateLibraryVariablesPrefix = `uf-api/builtforms/update_library_variables/`;
/**
 * Action types for builtforms.update_library_variables
 *
 * Update a library\&#39;s variables
 */
export const updateLibraryVariablesActionTypes = {
  LOAD: `${updateLibraryVariablesPrefix}LOAD` as 'uf-api/builtforms/update_library_variables/LOAD',
  SUCCESS:
    `${updateLibraryVariablesPrefix}SUCCESS` as 'uf-api/builtforms/update_library_variables/SUCCESS',
  FAILURE:
    `${updateLibraryVariablesPrefix}FAILURE` as 'uf-api/builtforms/update_library_variables/FAILURE',
  CLEAR:
    `${updateLibraryVariablesPrefix}CLEAR` as 'uf-api/builtforms/update_library_variables/CLEAR',
} as const;

/**
 * LOAD action for builtforms.update_library_variables
 * Update a library\&#39;s variables
 */
export interface updateLibraryVariablesLoadAction {
  readonly type: 'uf-api/builtforms/update_library_variables/LOAD';
  readonly params: BuiltformsServiceInterface.updateLibraryVariablesParams;
  readonly promise: Promise<ClientResponse<{}>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for builtforms.update_library_variables
 * Update a library\&#39;s variables
 */
export interface updateLibraryVariablesSuccessAction {
  readonly type: 'uf-api/builtforms/update_library_variables/SUCCESS';
  readonly result: {};
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for builtforms.update_library_variables
 * Update a library\&#39;s variables
 */
export interface updateLibraryVariablesFailureAction {
  readonly type: 'uf-api/builtforms/update_library_variables/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for builtforms.update_library_variables
 * Update a library\&#39;s variables
 */
export interface updateLibraryVariablesClearAction {
  readonly type: 'uf-api/builtforms/update_library_variables/CLEAR';
  readonly key: string;
}

const updatePlaceTypePrefix = `uf-api/builtforms/update_place_type/`;
/**
 * Action types for builtforms.update_place_type
 *
 * Update a place type
 */
export const updatePlaceTypeActionTypes = {
  LOAD: `${updatePlaceTypePrefix}LOAD` as 'uf-api/builtforms/update_place_type/LOAD',
  SUCCESS:
    `${updatePlaceTypePrefix}SUCCESS` as 'uf-api/builtforms/update_place_type/SUCCESS',
  FAILURE:
    `${updatePlaceTypePrefix}FAILURE` as 'uf-api/builtforms/update_place_type/FAILURE',
  CLEAR:
    `${updatePlaceTypePrefix}CLEAR` as 'uf-api/builtforms/update_place_type/CLEAR',
} as const;

/**
 * LOAD action for builtforms.update_place_type
 * Update a place type
 */
export interface updatePlaceTypeLoadAction {
  readonly type: 'uf-api/builtforms/update_place_type/LOAD';
  readonly params: BuiltformsServiceInterface.updatePlaceTypeParams;
  readonly promise: Promise<ClientResponse<{}>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for builtforms.update_place_type
 * Update a place type
 */
export interface updatePlaceTypeSuccessAction {
  readonly type: 'uf-api/builtforms/update_place_type/SUCCESS';
  readonly result: {};
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for builtforms.update_place_type
 * Update a place type
 */
export interface updatePlaceTypeFailureAction {
  readonly type: 'uf-api/builtforms/update_place_type/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for builtforms.update_place_type
 * Update a place type
 */
export interface updatePlaceTypeClearAction {
  readonly type: 'uf-api/builtforms/update_place_type/CLEAR';
  readonly key: string;
}

const updatePlaceTypeInfosPrefix = `uf-api/builtforms/update_place_type_infos/`;
/**
 * Action types for builtforms.update_place_type_infos
 *
 * Update a list of PlaceTypeInfos
 */
export const updatePlaceTypeInfosActionTypes = {
  LOAD: `${updatePlaceTypeInfosPrefix}LOAD` as 'uf-api/builtforms/update_place_type_infos/LOAD',
  SUCCESS:
    `${updatePlaceTypeInfosPrefix}SUCCESS` as 'uf-api/builtforms/update_place_type_infos/SUCCESS',
  FAILURE:
    `${updatePlaceTypeInfosPrefix}FAILURE` as 'uf-api/builtforms/update_place_type_infos/FAILURE',
  CLEAR:
    `${updatePlaceTypeInfosPrefix}CLEAR` as 'uf-api/builtforms/update_place_type_infos/CLEAR',
} as const;

/**
 * LOAD action for builtforms.update_place_type_infos
 * Update a list of PlaceTypeInfos
 */
export interface updatePlaceTypeInfosLoadAction {
  readonly type: 'uf-api/builtforms/update_place_type_infos/LOAD';
  readonly params: BuiltformsServiceInterface.updatePlaceTypeInfosParams;
  readonly promise: Promise<ClientResponse<{}>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for builtforms.update_place_type_infos
 * Update a list of PlaceTypeInfos
 */
export interface updatePlaceTypeInfosSuccessAction {
  readonly type: 'uf-api/builtforms/update_place_type_infos/SUCCESS';
  readonly result: {};
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for builtforms.update_place_type_infos
 * Update a list of PlaceTypeInfos
 */
export interface updatePlaceTypeInfosFailureAction {
  readonly type: 'uf-api/builtforms/update_place_type_infos/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for builtforms.update_place_type_infos
 * Update a list of PlaceTypeInfos
 */
export interface updatePlaceTypeInfosClearAction {
  readonly type: 'uf-api/builtforms/update_place_type_infos/CLEAR';
  readonly key: string;
}

export const builtformsActionTypesMap = {
  /** Action types for builtforms.create_building */
  create_building: createBuildingActionTypes,
  /** Action types for builtforms.create_building_type */
  create_building_type: createBuildingTypeActionTypes,
  /** Action types for builtforms.create_place_type */
  create_place_type: createPlaceTypeActionTypes,
  /** Action types for builtforms.delete_building */
  delete_building: deleteBuildingActionTypes,
  /** Action types for builtforms.delete_building_type */
  delete_building_type: deleteBuildingTypeActionTypes,
  /** Action types for builtforms.delete_place_type */
  delete_place_type: deletePlaceTypeActionTypes,
  /** Action types for builtforms.get_building */
  get_building: getBuildingActionTypes,
  /** Action types for builtforms.get_building_preview */
  get_building_preview: getBuildingPreviewActionTypes,
  /** Action types for builtforms.get_building_preview_multi */
  get_building_preview_multi: getBuildingPreviewMultiActionTypes,
  /** Action types for builtforms.get_building_type */
  get_building_type: getBuildingTypeActionTypes,
  /** Action types for builtforms.get_building_type_preview */
  get_building_type_preview: getBuildingTypePreviewActionTypes,
  /** Action types for builtforms.get_library */
  get_library: getLibraryActionTypes,
  /** Action types for builtforms.get_library_building_types */
  get_library_building_types: getLibraryBuildingTypesActionTypes,
  /** Action types for builtforms.get_library_buildings */
  get_library_buildings: getLibraryBuildingsActionTypes,
  /** Action types for builtforms.get_library_place_types */
  get_library_place_types: getLibraryPlaceTypesActionTypes,
  /** Action types for builtforms.get_place_type */
  get_place_type: getPlaceTypeActionTypes,
  /** Action types for builtforms.get_place_type_preview */
  get_place_type_preview: getPlaceTypePreviewActionTypes,
  /** Action types for builtforms.update_building */
  update_building: updateBuildingActionTypes,
  /** Action types for builtforms.update_building_type */
  update_building_type: updateBuildingTypeActionTypes,
  /** Action types for builtforms.update_building_type_infos */
  update_building_type_infos: updateBuildingTypeInfosActionTypes,
  /** Action types for builtforms.update_library_defaults */
  update_library_defaults: updateLibraryDefaultsActionTypes,
  /** Action types for builtforms.update_library_variables */
  update_library_variables: updateLibraryVariablesActionTypes,
  /** Action types for builtforms.update_place_type */
  update_place_type: updatePlaceTypeActionTypes,
  /** Action types for builtforms.update_place_type_infos */
  update_place_type_infos: updatePlaceTypeInfosActionTypes,
} as const;

export const builtformsDetailsMap = {
  create_building: {
    /** HTTP method for builtforms.create_building */
    httpMethod: 'post' as const,
  },
  create_building_type: {
    /** HTTP method for builtforms.create_building_type */
    httpMethod: 'post' as const,
  },
  create_place_type: {
    /** HTTP method for builtforms.create_place_type */
    httpMethod: 'post' as const,
  },
  delete_building: {
    /** HTTP method for builtforms.delete_building */
    httpMethod: 'delete' as const,
  },
  delete_building_type: {
    /** HTTP method for builtforms.delete_building_type */
    httpMethod: 'delete' as const,
  },
  delete_place_type: {
    /** HTTP method for builtforms.delete_place_type */
    httpMethod: 'delete' as const,
  },
  get_building: {
    /** HTTP method for builtforms.get_building */
    httpMethod: 'get' as const,
  },
  get_building_preview: {
    /** HTTP method for builtforms.get_building_preview */
    httpMethod: 'post' as const,
  },
  get_building_preview_multi: {
    /** HTTP method for builtforms.get_building_preview_multi */
    httpMethod: 'post' as const,
  },
  get_building_type: {
    /** HTTP method for builtforms.get_building_type */
    httpMethod: 'get' as const,
  },
  get_building_type_preview: {
    /** HTTP method for builtforms.get_building_type_preview */
    httpMethod: 'post' as const,
  },
  get_library: {
    /** HTTP method for builtforms.get_library */
    httpMethod: 'get' as const,
  },
  get_library_building_types: {
    /** HTTP method for builtforms.get_library_building_types */
    httpMethod: 'get' as const,
  },
  get_library_buildings: {
    /** HTTP method for builtforms.get_library_buildings */
    httpMethod: 'get' as const,
  },
  get_library_place_types: {
    /** HTTP method for builtforms.get_library_place_types */
    httpMethod: 'get' as const,
  },
  get_place_type: {
    /** HTTP method for builtforms.get_place_type */
    httpMethod: 'get' as const,
  },
  get_place_type_preview: {
    /** HTTP method for builtforms.get_place_type_preview */
    httpMethod: 'post' as const,
  },
  update_building: {
    /** HTTP method for builtforms.update_building */
    httpMethod: 'put' as const,
  },
  update_building_type: {
    /** HTTP method for builtforms.update_building_type */
    httpMethod: 'put' as const,
  },
  update_building_type_infos: {
    /** HTTP method for builtforms.update_building_type_infos */
    httpMethod: 'put' as const,
  },
  update_library_defaults: {
    /** HTTP method for builtforms.update_library_defaults */
    httpMethod: 'put' as const,
  },
  update_library_variables: {
    /** HTTP method for builtforms.update_library_variables */
    httpMethod: 'put' as const,
  },
  update_place_type: {
    /** HTTP method for builtforms.update_place_type */
    httpMethod: 'put' as const,
  },
  update_place_type_infos: {
    /** HTTP method for builtforms.update_place_type_infos */
    httpMethod: 'put' as const,
  },
} as const;

/**
 * Local DataState<T> - kept in sync with uf/data/dataState.ts
 */
interface DataState<T> {
  readonly loading: boolean;
  readonly loaded: boolean;
  readonly requestId: string;
  readonly requestTime?: string;
  readonly responseTime?: string;
  readonly requestCount?: number;
  readonly error?: any;
  readonly promise?: Promise<T>;
  readonly data?: T;
  readonly extra?: any;
  readonly key?: string;
}
export interface BuiltformsServiceState {
  /** all recent createBuilding results (POST/PUT/PATCH) */
  readonly create_building: DataState<Building>[];
  /** all recent createBuildingType results (POST/PUT/PATCH) */
  readonly create_building_type: DataState<BuildingType>[];
  /** all recent createPlaceType results (POST/PUT/PATCH) */
  readonly create_place_type: DataState<PlaceType>[];
  /** deleteBuilding state, by key */
  delete_building: {
    readonly [key: string]: DataState<{}>;
  };
  /** deleteBuildingType state, by key */
  delete_building_type: {
    readonly [key: string]: DataState<{}>;
  };
  /** deletePlaceType state, by key */
  delete_place_type: {
    readonly [key: string]: DataState<{}>;
  };
  /** getBuilding state, by key */
  get_building: {
    readonly [key: string]: DataState<Building>;
  };
  /** all recent getBuildingPreview results (POST/PUT/PATCH) */
  readonly get_building_preview: DataState<Building>[];
  /** all recent getBuildingPreviewMulti results (POST/PUT/PATCH) */
  readonly get_building_preview_multi: DataState<Building>[];
  /** getBuildingType state, by key */
  get_building_type: {
    readonly [key: string]: DataState<BuildingType>;
  };
  /** all recent getBuildingTypePreview results (POST/PUT/PATCH) */
  readonly get_building_type_preview: DataState<BuildingType>[];
  /** getLibrary state, by key */
  get_library: {
    readonly [key: string]: DataState<Library>;
  };
  /** getLibraryBuildingTypes state, by key */
  get_library_building_types: {
    readonly [key: string]: DataState<Array<BuildingTypeInfo>>;
  };
  /** getLibraryBuildings state, by key */
  get_library_buildings: {
    readonly [key: string]: DataState<Array<BuildingInfo>>;
  };
  /** getLibraryPlaceTypes state, by key */
  get_library_place_types: {
    readonly [key: string]: DataState<Array<PlaceTypeInfo>>;
  };
  /** getPlaceType state, by key */
  get_place_type: {
    readonly [key: string]: DataState<PlaceType>;
  };
  /** all recent getPlaceTypePreview results (POST/PUT/PATCH) */
  readonly get_place_type_preview: DataState<PlaceType>[];
  /** all recent updateBuilding results (POST/PUT/PATCH) */
  readonly update_building: DataState<{}>[];
  /** all recent updateBuildingType results (POST/PUT/PATCH) */
  readonly update_building_type: DataState<{}>[];
  /** all recent updateBuildingTypeInfos results (POST/PUT/PATCH) */
  readonly update_building_type_infos: DataState<{}>[];
  /** all recent updateLibraryDefaults results (POST/PUT/PATCH) */
  readonly update_library_defaults: DataState<{}>[];
  /** all recent updateLibraryVariables results (POST/PUT/PATCH) */
  readonly update_library_variables: DataState<{}>[];
  /** all recent updatePlaceType results (POST/PUT/PATCH) */
  readonly update_place_type: DataState<{}>[];
  /** all recent updatePlaceTypeInfos results (POST/PUT/PATCH) */
  readonly update_place_type_infos: DataState<{}>[];
}
