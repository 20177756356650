import { createSelector } from 'reselect';
import { makeGetFromPropsSelector } from 'uf/base/selector';
import { DataState } from 'uf/data/dataState';
import { ColumnKey, LayerId } from 'uf/layers';
import { ProjectId } from 'uf/projects';
import { LegacyVirtualLayerId } from 'uf/projects/virtualLayers';
import { LayerColumnSymbology } from 'uf/symbology';
import {
  makeGetBaseSymbologyState,
  makeGetBaseSymbologyStateGetter,
} from 'uf/symbology/selectors/baseSymbologies';
import {
  makeGetUserSymbologyState,
  makeGetUserSymbologyStateGetter,
} from 'uf/symbology/selectors/user';
import { ViewId } from 'uf/views';
import { getMergedSymbologyState } from './merge';

export function makeGetBaseOrUserSymbologyState() {
  return createSelector(
    makeGetUserSymbologyState(),
    makeGetBaseSymbologyState(),
    makeGetFromPropsSelector<LegacyVirtualLayerId, 'virtualLayerId'>(
      'virtualLayerId',
    ),
    (
      userSymbologyState,
      baseSymbologyState,
    ): DataState<LayerColumnSymbology[]> => {
      return getMergedSymbologyState(userSymbologyState, baseSymbologyState);
    },
  );
}

export function makeGetBaseOrUserSymbologyStateGetter() {
  return createSelector(
    makeGetUserSymbologyStateGetter(),
    makeGetBaseSymbologyStateGetter(),
    (getUserSymbologyState, getBaseSymbologyState) =>
      (
        projectId: ProjectId,
        viewId: ViewId,
        layerId: LayerId,
        virtualLayerId: LegacyVirtualLayerId,
        columnKey: ColumnKey,
      ): DataState<LayerColumnSymbology[]> => {
        const userSymbologyState = getUserSymbologyState({
          projectId,
          viewId,
          layerId,
          // virtualLayerId,
          columnKey,
        });
        const baseSymbologyState = getBaseSymbologyState(
          projectId,
          layerId,
          columnKey,
        );
        return getMergedSymbologyState(userSymbologyState, baseSymbologyState);
      },
  );
}
