import loadable from '@loadable/component';
import _ from 'lodash';
import { parse } from 'query-string';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { EnterHook, PlainRoute } from 'react-router';
import { Store } from 'redux';
import { t } from 'ttag';
import UAParser from 'ua-parser-js';
import {
  appInitialized,
  checkServer,
  pageInitialized,
  showAppToasts,
} from 'uf/app/actions';
import { addAppMessage } from 'uf/appservices/actions';
import { getSignInUrlBase } from 'uf/base/api';
import { redirectTo } from 'uf/base/routing';
import { setDebugFlag } from 'uf/debug/actions';
import { UFState } from 'uf/state';
import ErrorView from 'uf/ui/error/ErrorView';
import ExploreHeader from 'uf/ui/explore/ExploreHeader/ExploreHeader';
import ExploreView from 'uf/ui/explore/ExploreView/ExploreView';
import getExploreRoutes from 'uf/ui/explore/routes';
import App from 'uf/ui/main/App/App';
import MainPage from 'uf/ui/main/MainPage/MainPage';
import { loadCurrentUser } from 'uf/user/actions/authentication';
import { addUserFlags, setUserFlags } from 'uf/user/actions/flags';
import {
  getUserIsAuthenticated,
  getUserIsSuperuser,
} from 'uf/user/selectors/user';

const SUPPORTED_EDGE_VERSION = 79;

const SettingsHeader = loadable(
  () =>
    import(
      'uf/ui/settings/SettingsHeader/SettingsHeader' /* webpackChunkName: "settings" */
    ),
);
const SettingsView = loadable(
  () =>
    import(
      'uf/ui/settings/SettingsView/SettingsView' /* webpackChunkName: "settings" */
    ),
);

const ProfileHeader = loadable(
  () =>
    import(
      'uf/ui/profile/ProfileHeader/ProfileHeader' /* webpackChunkName: "settings" */
    ),
);
const ProfileView = loadable(
  () =>
    import(
      'uf/ui/profile/ProfileView/ProfileView' /* webpackChunkName: "settings" */
    ),
);

const MainPortal = props => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(appInitialized());
  });
  return <MainPage {...props} />;
};

/**
 * Create routes.
 * @param store The redux store
 * @param waitForIdle A function which returns a promise, which waits for idle
 */
export default function getRoutes(
  store: Store<UFState>,
  waitForIdle?: () => Promise<any>,
  isBrowserSupported?: boolean,
) {
  const onEnterMapExport = makeOnEnterMapExport(store);
  const handleAppOnEnter = makeAppOnEnterHandler(
    store,
    waitForIdle,
    isBrowserSupported,
  );
  const exitErrorPageIfBrowserSupported =
    makeExitErrorPageIfBrowserSupported(isBrowserSupported);
  const handleMainPageOnEnter = makeMainPageOnEnterHandler(
    store,
    isBrowserSupported,
  );
  const handleAuthPageEnter = makeAuthPageOnEnterHandler(store);

  const appRoutes: PlainRoute[] = [
    {
      onEnter: handleMainPageOnEnter,
      component: MainPortal,
      indexRoute: {
        components: { header: ExploreHeader, body: ExploreView },
      },
      childRoutes: [
        {
          path: 'explore',
          childRoutes: getExploreRoutes(store, waitForIdle),
        },
        {
          path: 'report',
          getChildRoutes: async (location, cb) => {
            try {
              const reportRoutes = await import(
                'uf/ui/reporting/routes' /* webpackChunkName: "report" */
              );
              cb(null, reportRoutes.getReportRoutes(store));
            } catch (ex) {
              cb(ex, null);
            }
          },
        },
        {
          path: 'settings/:orgKey',
          components: {
            header: SettingsHeader,
            body: SettingsView,
          },
        },
        {
          path: 'profile',
          components: {
            header: ProfileHeader,
            body: ProfileView,
          },
        },
        {
          path: 'bf',
          getChildRoutes: getManageRoutes(store),
        },
        {
          // TODO: fix up routing so we don't have redundant routes
          path: 'ae',
          getChildRoutes: getManageRoutes(store),
        },
      ],
    },
    {
      path: 'error',
      component: ErrorView,
      onEnter: exitErrorPageIfBrowserSupported,
    },
    {
      onEnter: handleAuthPageEnter,
      getChildRoutes: async (location, cb) => {
        try {
          const inviteRoutes = await import(
            'uf/ui/invite/routes' /* webpackChunkName: "signup" */
          );
          cb(null, inviteRoutes.getInviteRoutes());
        } catch (ex) {
          cb(ex, null);
        }
      },
    },
  ];

  const routes: PlainRoute[] = [
    {
      path: '/',
      component: App,
      childRoutes: [
        {
          onEnter: handleAppOnEnter,
          childRoutes: appRoutes,
        },
        {
          childRoutes: [
            {
              path: 'internal',
              getChildRoutes: async (location, cb) => {
                try {
                  const internalRoutes = await import(
                    'uf/ui/internal/routes' /* webpackChunkName: "internal" */
                  );
                  cb(null, internalRoutes.getInternalRoutes(store));
                } catch (ex) {
                  cb(ex, null);
                }
              },
            },
            {
              path: 'mapexport',
              onEnter: onEnterMapExport,
              getChildRoutes: async (location, cb) => {
                try {
                  const mapexportRoutes = await import(
                    'uf/ui/mapexport/routes' /* webpackChunkName: "mapexport" */
                  );
                  cb(null, mapexportRoutes.default(store, waitForIdle));
                } catch (ex) {
                  cb(ex, null);
                }
              },
            },
          ],
        },
      ],
    },
  ];
  return routes;
}

function getManageRoutes(
  store: Store<UFState>,
): (
  partialNextState: any,
  callback: (err: any, routesArray: PlainRoute[]) => void,
) => void {
  return async (location, cb) => {
    try {
      const manageRoutes = await import(
        'uf/ui/manage/routes' /* webpackChunkName: "manage" */
      );
      cb(null, manageRoutes.getManageRoutes(store));
    } catch (ex) {
      cb(ex, null);
    }
  };
}

function makeEnsureLoggedInHandler(store: Store<UFState>): EnterHook {
  return (nextState, replace) => {
    if (!getUserIsAuthenticated(store.getState())) {
      return redirectTo(
        replace,
        getSignInUrlBase({ pathname: nextState.location.pathname }),
        {
          reason: 'not_authenticated',
        },
      );
    }
    return true;
  };
}

function makeAppOnEnterHandler(
  store: Store<UFState>,
  waitForIdle: () => Promise<any>,
  isBrowserSupported: boolean,
): EnterHook {
  const verifyUserAgent = makeVerifyUserAgent(store, isBrowserSupported);
  return async (nextState, replace, done: (error?) => void) => {
    const { location } = nextState;
    // if we haven't already landed on the error page due to a user agent check,
    // then do a user agent check before making all the xhr calls that boot up the app.
    if (
      !location.search.includes('browsersupport') &&
      !verifyUserAgent(nextState, replace)
    ) {
      return done();
    }

    const pendingRequests: Promise<any>[] = [];
    pendingRequests.push(store.dispatch(checkServer()));

    const currentUserRequest = store.dispatch(loadCurrentUser()).catch(ex => {
      // If no one is logged in then return a null user.
      if (ex.status === 401) {
        return null;
      }
      throw ex;
    });

    pendingRequests.push(currentUserRequest);

    // TODO: move to an epic
    currentUserRequest.then(() => {
      if (__DEVELOPMENT__ || getUserIsSuperuser(store.getState())) {
        store.dispatch(addUserFlags(['developer']));
      }
    });

    const query = location.query || parse(location.search || '');
    Object.keys(query).forEach(key => {
      if (key.startsWith('debug')) {
        store.dispatch(setDebugFlag(key, query[key]));
      }
    });

    if (query.feature_flags) {
      const flags =
        typeof query.feature_flags === 'string'
          ? query.feature_flags.split(',')
          : _.flatten(query.feature_flags.map(flag => flag.split(',')));
      store.dispatch(setUserFlags(flags));
    }

    if (waitForIdle) {
      pendingRequests.push(waitForIdle());
    }

    try {
      await Promise.all(pendingRequests);
      store.dispatch(pageInitialized());
      done();
    } catch (error) {
      store.dispatch(pageInitialized());
      if (!__DEVELOPMENT__) {
        const errorString = encodeURIComponent(`${error}`);
        redirectTo(
          replace,
          getSignInUrlBase({
            reason: errorString,
            pathname: location.pathname,
          }),
        );
      } else {
        console.error('Error rendering page: ', error);
      }
      done(error);
    }
  };
}

function makeVerifyUserAgent(store, isBrowserSupported): EnterHook {
  return ({ location }, replace) => {
    const query = location.query || parse(location.search || '');

    if (__SERVER__) {
      if (!('nobrowsercheck' in query) && !isBrowserSupported) {
        return redirectTo(replace, '/error', { error: 'browsersupport' });
      }
    }

    // warn if we support the browser and it is not chrome
    if (__CLIENT__) {
      const userAgent = window.navigator.userAgent;
      const parsedUserAgent = new UAParser(userAgent);
      let { name, version } = parsedUserAgent.getBrowser();
      [version] = version ? /[0-9]+\.[0-9]+/.exec(version) : [null];
      name = name || t`an unknown browser`;

      // TODO: remove this when Edge 82 is released. (at which point Edge 18
      // will be so old, we can drop support)
      if (name === 'Edge' && parseFloat(version) < SUPPORTED_EDGE_VERSION) {
        const message = t`UrbanFootprint works best with the latest version of Google Chrome,
         Microsoft Edge, or Firefox. You are using ${name} ${version}. We recommend that you
         upgrade your browser.`;

        const messageId = 'browser-incompatibility';
        store.dispatch(
          addAppMessage(message, {
            id: messageId,
            level: 'info',
            timeout: 0,
            action: {
              text: t`DOWNLOAD`,
              href: 'https://www.google.com/chrome/',
              target: '_blank',
            },
            replacesMessage: messageId,
          }),
        );
      }
    }

    return true;
  };
}

function makeExitErrorPageIfBrowserSupported(isBrowserSupported): EnterHook {
  return ({ location }, replace) => {
    const query = location.query || parse(location.search || '');

    // Note that we are *NOT* firing appInitialized() because if we're in an
    // error page, the app has *NOT* actually been initialized! If we redirect,
    // then we rely on the target page to call appInitialized().

    // get off the browser support page if we're currently on it!
    if (query.error === 'browsersupport' && isBrowserSupported) {
      return redirectTo(replace, '/');
    }
    return true;
  };
}

// TODO move into a hook
function makeMainPageOnEnterHandler(
  store: Store<UFState>,
  isBrowserSupported: boolean,
): EnterHook {
  const verifyUserAgent = makeVerifyUserAgent(store, isBrowserSupported);
  const ensureUserLoggedIn = makeEnsureLoggedInHandler(store);

  return (nextState, replace) => {
    if (!verifyUserAgent(nextState, replace)) {
      return;
    }
    ensureUserLoggedIn(nextState, replace);
  };
}

function makeAuthPageOnEnterHandler(store: Store<UFState>): EnterHook {
  return () => {
    if (__CLIENT__ || __TESTING__) {
      store.dispatch(appInitialized());
    }
  };
}

function makeOnEnterMapExport(store: Store<UFState>): EnterHook {
  return () => {
    store.dispatch(showAppToasts(false));
  };
}

if (module.hot) {
  module.hot.accept();
}
