import { makeCustomAsyncActionTypes } from 'uf/data/ActionTypes';

/**
 * Convert a websocket message like MAPEXPORT_STARTED into a redux
 * action type like uf/websockets/message/MAPEXPORT_STARTED.
 *
 * @param websocketMessageType Websocket message type.
 */
export function makeWebsocketActionType(websocketMessageType: string): string {
  return `uf/websockets/message/${websocketMessageType}`;
}

/**
 * Create action types for LOAD/SUCCESS/FAILURE. This is very similar to
 * {@link makeAsyncActionTypes} except that the messages themselves are
 * websocket message types that come from the server, like MAPEXPORT_STARTED.
 *
 * @param load Websocket message type to signal start of background task.
 * @param success Webscoket message type to signal the completion of background task.
 * @param failure Websocket message type to signal the failure of background task.
 */
export function makeWebsocketActionTypes(
  load: string,
  success: string,
  failure: string,
) {
  return makeCustomAsyncActionTypes(
    makeWebsocketActionType(load),
    makeWebsocketActionType(success),
    makeWebsocketActionType(failure),
  );
}
