/**
 * UrbanFootprint App API
 * API for serving UrbanFootprint Application
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { ClientResponse } from '../variables';

// @ts-ignore: This is used only for params, but some services have param-free APIs
import { ReportServiceInterface } from './report.serviceInterface';

// @ts-ignore: These imports are for both params and returnType, but we only use returnType
import { ProblemDetail } from '../model/problemDetail';
// @ts-ignore: These imports are for both params and returnType, but we only use returnType
import { ReportResults } from '../model/reportResults';
// @ts-ignore: These imports are for both params and returnType, but we only use returnType
import { ReportSpec } from '../model/reportSpec';

/**
 * Package: api
 */

const getReportPrefix = `uf-api/report/get_report/`;
/**
 * Action types for report.get_report
 *
 * Get the report for the specified scenarios.
 */
export const getReportActionTypes = {
  LOAD: `${getReportPrefix}LOAD` as 'uf-api/report/get_report/LOAD',
  SUCCESS: `${getReportPrefix}SUCCESS` as 'uf-api/report/get_report/SUCCESS',
  FAILURE: `${getReportPrefix}FAILURE` as 'uf-api/report/get_report/FAILURE',
  CLEAR: `${getReportPrefix}CLEAR` as 'uf-api/report/get_report/CLEAR',
} as const;

/**
 * LOAD action for report.get_report
 * Get the report for the specified scenarios.
 */
export interface getReportLoadAction {
  readonly type: 'uf-api/report/get_report/LOAD';
  readonly params: ReportServiceInterface.getReportParams;
  readonly promise: Promise<ClientResponse<ReportResults>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for report.get_report
 * Get the report for the specified scenarios.
 */
export interface getReportSuccessAction {
  readonly type: 'uf-api/report/get_report/SUCCESS';
  readonly result: ReportResults;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for report.get_report
 * Get the report for the specified scenarios.
 */
export interface getReportFailureAction {
  readonly type: 'uf-api/report/get_report/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for report.get_report
 * Get the report for the specified scenarios.
 */
export interface getReportClearAction {
  readonly type: 'uf-api/report/get_report/CLEAR';
  readonly key: string;
}

const getReportXlsPrefix = `uf-api/report/get_report_xls/`;
/**
 * Action types for report.get_report_xls
 *
 * Get the report for the specified scenarios.
 */
export const getReportXlsActionTypes = {
  LOAD: `${getReportXlsPrefix}LOAD` as 'uf-api/report/get_report_xls/LOAD',
  SUCCESS:
    `${getReportXlsPrefix}SUCCESS` as 'uf-api/report/get_report_xls/SUCCESS',
  FAILURE:
    `${getReportXlsPrefix}FAILURE` as 'uf-api/report/get_report_xls/FAILURE',
  CLEAR: `${getReportXlsPrefix}CLEAR` as 'uf-api/report/get_report_xls/CLEAR',
} as const;

/**
 * LOAD action for report.get_report_xls
 * Get the report for the specified scenarios.
 */
export interface getReportXlsLoadAction {
  readonly type: 'uf-api/report/get_report_xls/LOAD';
  readonly params: ReportServiceInterface.getReportXlsParams;
  readonly promise: Promise<ClientResponse<Blob>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for report.get_report_xls
 * Get the report for the specified scenarios.
 */
export interface getReportXlsSuccessAction {
  readonly type: 'uf-api/report/get_report_xls/SUCCESS';
  readonly result: Blob;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for report.get_report_xls
 * Get the report for the specified scenarios.
 */
export interface getReportXlsFailureAction {
  readonly type: 'uf-api/report/get_report_xls/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for report.get_report_xls
 * Get the report for the specified scenarios.
 */
export interface getReportXlsClearAction {
  readonly type: 'uf-api/report/get_report_xls/CLEAR';
  readonly key: string;
}

export const reportActionTypesMap = {
  /** Action types for report.get_report */
  get_report: getReportActionTypes,
  /** Action types for report.get_report_xls */
  get_report_xls: getReportXlsActionTypes,
} as const;

export const reportDetailsMap = {
  get_report: {
    /** HTTP method for report.get_report */
    httpMethod: 'post' as const,
  },
  get_report_xls: {
    /** HTTP method for report.get_report_xls */
    httpMethod: 'post' as const,
  },
} as const;

/**
 * Local DataState<T> - kept in sync with uf/data/dataState.ts
 */
interface DataState<T> {
  readonly loading: boolean;
  readonly loaded: boolean;
  readonly requestId: string;
  readonly requestTime?: string;
  readonly responseTime?: string;
  readonly requestCount?: number;
  readonly error?: any;
  readonly promise?: Promise<T>;
  readonly data?: T;
  readonly extra?: any;
  readonly key?: string;
}
export interface ReportServiceState {
  /** all recent getReport results (POST/PUT/PATCH) */
  readonly get_report: DataState<ReportResults>[];
  /** all recent getReportXls results (POST/PUT/PATCH) */
  readonly get_report_xls: DataState<Blob>[];
}
