import { LegacyReport } from 'uf/reporting/index';

export const healthCharts: LegacyReport = Object.freeze({
  key: 'public_health',
  name: 'Public Health',
  charts: [
    {
      name: 'Adult Walk Minutes',
      key: 'adult_all_walk_minutes',
      series: [
        {
          key: 'adult_all_walk_minutes',
          source: 'analysis',
          sourceKey: 'public_health',
          layerKey: 'ph_summary_avg_min_per_day_adult',
          columnKey: 'adult_all_walk_minutes',
        },
      ],
    },
    {
      name: 'Adult Bike Minutes',
      key: 'adult_all_bike_minutes',
      series: [
        {
          key: 'adult_all_bike_minutes',
          source: 'analysis',
          sourceKey: 'public_health',
          layerKey: 'ph_summary_avg_min_per_day_adult',
          columnKey: 'adult_all_bike_minutes',
        },
      ],
    },
    {
      name: 'Adult Auto Minutes',
      key: 'adult_all_auto_minutes',
      series: [
        {
          key: 'adult_all_auto_minutes',
          source: 'analysis',
          sourceKey: 'public_health',
          layerKey: 'ph_summary_avg_min_per_day_adult',
          columnKey: 'adult_all_auto_minutes',
        },
      ],
    },
    {
      name: 'Adult Pct Overweight',
      key: 'adult_all_overweight',
      series: [
        {
          key: 'adult_all_overweight',
          source: 'analysis',
          sourceKey: 'public_health',
          layerKey: 'ph_summary_pct_adult_conditions',
          columnKey: 'adult_all_overweight',
        },
      ],
    },
    {
      name: 'Adult Pct Obese',
      key: 'adult_all_obese',
      series: [
        {
          key: 'adult_all_obese',
          source: 'analysis',
          sourceKey: 'public_health',
          layerKey: 'ph_summary_pct_adult_conditions',
          columnKey: 'adult_all_obese',
        },
      ],
    },
    {
      name: 'Adult Pct High Blood Pressure',
      key: 'adult_all_high_bp',
      series: [
        {
          key: 'adult_all_high_bp',
          source: 'analysis',
          sourceKey: 'public_health',
          layerKey: 'ph_summary_pct_adult_conditions',
          columnKey: 'adult_all_high_bp',
        },
      ],
    },
    {
      name: 'Adult Pct Heart Disease',
      key: 'adult_all_heart_dis',
      series: [
        {
          key: 'adult_all_heart_dis',
          source: 'analysis',
          sourceKey: 'public_health',
          layerKey: 'ph_summary_pct_adult_conditions',
          columnKey: 'adult_all_heart_dis',
        },
      ],
    },
    {
      name: 'Adult Pct Type 2 Diabetes',
      key: 'adult_all_dia_type2',
      series: [
        {
          key: 'adult_all_dia_type2',
          source: 'analysis',
          sourceKey: 'public_health',
          layerKey: 'ph_summary_pct_adult_conditions',
          columnKey: 'adult_all_dia_type2',
        },
      ],
    },
  ],
});
