/**
 * UrbanFootprint App API
 * API for serving UrbanFootprint Application
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { ClientResponse } from '../variables';

// @ts-ignore: This is used only for params, but some services have param-free APIs
import { LayerServiceInterface } from './layer.serviceInterface';

// @ts-ignore: These imports are for both params and returnType, but we only use returnType
import { AppLayer } from '../model/appLayer';
// @ts-ignore: These imports are for both params and returnType, but we only use returnType
import { GeometryType } from '../model/geometryType';
// @ts-ignore: These imports are for both params and returnType, but we only use returnType
import { LayerBounds } from '../model/layerBounds';
// @ts-ignore: These imports are for both params and returnType, but we only use returnType
import { LayerBreaks } from '../model/layerBreaks';
// @ts-ignore: These imports are for both params and returnType, but we only use returnType
import { LayerData } from '../model/layerData';
// @ts-ignore: These imports are for both params and returnType, but we only use returnType
import { LayerGeoData } from '../model/layerGeoData';
// @ts-ignore: These imports are for both params and returnType, but we only use returnType
import { LayerMetadata } from '../model/layerMetadata';
// @ts-ignore: These imports are for both params and returnType, but we only use returnType
import { LayerReference } from '../model/layerReference';
// @ts-ignore: These imports are for both params and returnType, but we only use returnType
import { LayerStats } from '../model/layerStats';
// @ts-ignore: These imports are for both params and returnType, but we only use returnType
import { ProblemDetail } from '../model/problemDetail';
// @ts-ignore: These imports are for both params and returnType, but we only use returnType
import { TaskEnqueueResultItem } from '../model/taskEnqueueResultItem';

/**
 * Package: api
 */

const addDatabankToOrgPrefix = `uf-api/layer/add_databank_to_org/`;
/**
 * Action types for layer.add_databank_to_org
 *
 * Add databank to organization.
 */
export const addDatabankToOrgActionTypes = {
  LOAD: `${addDatabankToOrgPrefix}LOAD` as 'uf-api/layer/add_databank_to_org/LOAD',
  SUCCESS:
    `${addDatabankToOrgPrefix}SUCCESS` as 'uf-api/layer/add_databank_to_org/SUCCESS',
  FAILURE:
    `${addDatabankToOrgPrefix}FAILURE` as 'uf-api/layer/add_databank_to_org/FAILURE',
  CLEAR:
    `${addDatabankToOrgPrefix}CLEAR` as 'uf-api/layer/add_databank_to_org/CLEAR',
} as const;

/**
 * LOAD action for layer.add_databank_to_org
 * Add databank to organization.
 */
export interface addDatabankToOrgLoadAction {
  readonly type: 'uf-api/layer/add_databank_to_org/LOAD';
  readonly params: LayerServiceInterface.addDatabankToOrgParams;
  readonly promise: Promise<ClientResponse<{}>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for layer.add_databank_to_org
 * Add databank to organization.
 */
export interface addDatabankToOrgSuccessAction {
  readonly type: 'uf-api/layer/add_databank_to_org/SUCCESS';
  readonly result: {};
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for layer.add_databank_to_org
 * Add databank to organization.
 */
export interface addDatabankToOrgFailureAction {
  readonly type: 'uf-api/layer/add_databank_to_org/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for layer.add_databank_to_org
 * Add databank to organization.
 */
export interface addDatabankToOrgClearAction {
  readonly type: 'uf-api/layer/add_databank_to_org/CLEAR';
  readonly key: string;
}

const createDatabankPrefix = `uf-api/layer/create_databank/`;
/**
 * Action types for layer.create_databank
 *
 * Create a new databank
 */
export const createDatabankActionTypes = {
  LOAD: `${createDatabankPrefix}LOAD` as 'uf-api/layer/create_databank/LOAD',
  SUCCESS:
    `${createDatabankPrefix}SUCCESS` as 'uf-api/layer/create_databank/SUCCESS',
  FAILURE:
    `${createDatabankPrefix}FAILURE` as 'uf-api/layer/create_databank/FAILURE',
  CLEAR: `${createDatabankPrefix}CLEAR` as 'uf-api/layer/create_databank/CLEAR',
} as const;

/**
 * LOAD action for layer.create_databank
 * Create a new databank
 */
export interface createDatabankLoadAction {
  readonly type: 'uf-api/layer/create_databank/LOAD';
  readonly params: LayerServiceInterface.createDatabankParams;
  readonly promise: Promise<ClientResponse<LayerReference>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for layer.create_databank
 * Create a new databank
 */
export interface createDatabankSuccessAction {
  readonly type: 'uf-api/layer/create_databank/SUCCESS';
  readonly result: LayerReference;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for layer.create_databank
 * Create a new databank
 */
export interface createDatabankFailureAction {
  readonly type: 'uf-api/layer/create_databank/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for layer.create_databank
 * Create a new databank
 */
export interface createDatabankClearAction {
  readonly type: 'uf-api/layer/create_databank/CLEAR';
  readonly key: string;
}

const downloadExportedLayerPrefix = `uf-api/layer/download_exported_layer/`;
/**
 * Action types for layer.download_exported_layer
 *
 * Download a layer that was previously enqueued.
 */
export const downloadExportedLayerActionTypes = {
  LOAD: `${downloadExportedLayerPrefix}LOAD` as 'uf-api/layer/download_exported_layer/LOAD',
  SUCCESS:
    `${downloadExportedLayerPrefix}SUCCESS` as 'uf-api/layer/download_exported_layer/SUCCESS',
  FAILURE:
    `${downloadExportedLayerPrefix}FAILURE` as 'uf-api/layer/download_exported_layer/FAILURE',
  CLEAR:
    `${downloadExportedLayerPrefix}CLEAR` as 'uf-api/layer/download_exported_layer/CLEAR',
} as const;

/**
 * LOAD action for layer.download_exported_layer
 * Download a layer that was previously enqueued.
 */
export interface downloadExportedLayerLoadAction {
  readonly type: 'uf-api/layer/download_exported_layer/LOAD';
  readonly params: LayerServiceInterface.downloadExportedLayerParams;
  readonly promise: Promise<ClientResponse<{}>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for layer.download_exported_layer
 * Download a layer that was previously enqueued.
 */
export interface downloadExportedLayerSuccessAction {
  readonly type: 'uf-api/layer/download_exported_layer/SUCCESS';
  readonly result: {};
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for layer.download_exported_layer
 * Download a layer that was previously enqueued.
 */
export interface downloadExportedLayerFailureAction {
  readonly type: 'uf-api/layer/download_exported_layer/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for layer.download_exported_layer
 * Download a layer that was previously enqueued.
 */
export interface downloadExportedLayerClearAction {
  readonly type: 'uf-api/layer/download_exported_layer/CLEAR';
  readonly key: string;
}

const enqueueLayerExportTaskPrefix = `uf-api/layer/enqueue_layer_export_task/`;
/**
 * Action types for layer.enqueue_layer_export_task
 *
 * Enqueue a layer export task.
 */
export const enqueueLayerExportTaskActionTypes = {
  LOAD: `${enqueueLayerExportTaskPrefix}LOAD` as 'uf-api/layer/enqueue_layer_export_task/LOAD',
  SUCCESS:
    `${enqueueLayerExportTaskPrefix}SUCCESS` as 'uf-api/layer/enqueue_layer_export_task/SUCCESS',
  FAILURE:
    `${enqueueLayerExportTaskPrefix}FAILURE` as 'uf-api/layer/enqueue_layer_export_task/FAILURE',
  CLEAR:
    `${enqueueLayerExportTaskPrefix}CLEAR` as 'uf-api/layer/enqueue_layer_export_task/CLEAR',
} as const;

/**
 * LOAD action for layer.enqueue_layer_export_task
 * Enqueue a layer export task.
 */
export interface enqueueLayerExportTaskLoadAction {
  readonly type: 'uf-api/layer/enqueue_layer_export_task/LOAD';
  readonly params: LayerServiceInterface.enqueueLayerExportTaskParams;
  readonly promise: Promise<ClientResponse<TaskEnqueueResultItem>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for layer.enqueue_layer_export_task
 * Enqueue a layer export task.
 */
export interface enqueueLayerExportTaskSuccessAction {
  readonly type: 'uf-api/layer/enqueue_layer_export_task/SUCCESS';
  readonly result: TaskEnqueueResultItem;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for layer.enqueue_layer_export_task
 * Enqueue a layer export task.
 */
export interface enqueueLayerExportTaskFailureAction {
  readonly type: 'uf-api/layer/enqueue_layer_export_task/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for layer.enqueue_layer_export_task
 * Enqueue a layer export task.
 */
export interface enqueueLayerExportTaskClearAction {
  readonly type: 'uf-api/layer/enqueue_layer_export_task/CLEAR';
  readonly key: string;
}

const getAppLayersByContextPrefix = `uf-api/layer/get_app_layers_by_context/`;
/**
 * Action types for layer.get_app_layers_by_context
 *
 * Get layer reference for a context
 */
export const getAppLayersByContextActionTypes = {
  LOAD: `${getAppLayersByContextPrefix}LOAD` as 'uf-api/layer/get_app_layers_by_context/LOAD',
  SUCCESS:
    `${getAppLayersByContextPrefix}SUCCESS` as 'uf-api/layer/get_app_layers_by_context/SUCCESS',
  FAILURE:
    `${getAppLayersByContextPrefix}FAILURE` as 'uf-api/layer/get_app_layers_by_context/FAILURE',
  CLEAR:
    `${getAppLayersByContextPrefix}CLEAR` as 'uf-api/layer/get_app_layers_by_context/CLEAR',
} as const;

/**
 * LOAD action for layer.get_app_layers_by_context
 * Get layer reference for a context
 */
export interface getAppLayersByContextLoadAction {
  readonly type: 'uf-api/layer/get_app_layers_by_context/LOAD';
  readonly params: LayerServiceInterface.getAppLayersByContextParams;
  readonly promise: Promise<ClientResponse<Array<AppLayer>>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for layer.get_app_layers_by_context
 * Get layer reference for a context
 */
export interface getAppLayersByContextSuccessAction {
  readonly type: 'uf-api/layer/get_app_layers_by_context/SUCCESS';
  readonly result: Array<AppLayer>;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for layer.get_app_layers_by_context
 * Get layer reference for a context
 */
export interface getAppLayersByContextFailureAction {
  readonly type: 'uf-api/layer/get_app_layers_by_context/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for layer.get_app_layers_by_context
 * Get layer reference for a context
 */
export interface getAppLayersByContextClearAction {
  readonly type: 'uf-api/layer/get_app_layers_by_context/CLEAR';
  readonly key: string;
}

const getLatestVersionPrefix = `uf-api/layer/get_latest_version/`;
/**
 * Action types for layer.get_latest_version
 *
 * Get latest version of a layer.
 */
export const getLatestVersionActionTypes = {
  LOAD: `${getLatestVersionPrefix}LOAD` as 'uf-api/layer/get_latest_version/LOAD',
  SUCCESS:
    `${getLatestVersionPrefix}SUCCESS` as 'uf-api/layer/get_latest_version/SUCCESS',
  FAILURE:
    `${getLatestVersionPrefix}FAILURE` as 'uf-api/layer/get_latest_version/FAILURE',
  CLEAR:
    `${getLatestVersionPrefix}CLEAR` as 'uf-api/layer/get_latest_version/CLEAR',
} as const;

/**
 * LOAD action for layer.get_latest_version
 * Get latest version of a layer.
 */
export interface getLatestVersionLoadAction {
  readonly type: 'uf-api/layer/get_latest_version/LOAD';
  readonly params: LayerServiceInterface.getLatestVersionParams;
  readonly promise: Promise<ClientResponse<string>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for layer.get_latest_version
 * Get latest version of a layer.
 */
export interface getLatestVersionSuccessAction {
  readonly type: 'uf-api/layer/get_latest_version/SUCCESS';
  readonly result: string;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for layer.get_latest_version
 * Get latest version of a layer.
 */
export interface getLatestVersionFailureAction {
  readonly type: 'uf-api/layer/get_latest_version/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for layer.get_latest_version
 * Get latest version of a layer.
 */
export interface getLatestVersionClearAction {
  readonly type: 'uf-api/layer/get_latest_version/CLEAR';
  readonly key: string;
}

const getLayerPrefix = `uf-api/layer/get_layer/`;
/**
 * Action types for layer.get_layer
 *
 * Get metadata for a layer
 */
export const getLayerActionTypes = {
  LOAD: `${getLayerPrefix}LOAD` as 'uf-api/layer/get_layer/LOAD',
  SUCCESS: `${getLayerPrefix}SUCCESS` as 'uf-api/layer/get_layer/SUCCESS',
  FAILURE: `${getLayerPrefix}FAILURE` as 'uf-api/layer/get_layer/FAILURE',
  CLEAR: `${getLayerPrefix}CLEAR` as 'uf-api/layer/get_layer/CLEAR',
} as const;

/**
 * LOAD action for layer.get_layer
 * Get metadata for a layer
 */
export interface getLayerLoadAction {
  readonly type: 'uf-api/layer/get_layer/LOAD';
  readonly params: LayerServiceInterface.getLayerParams;
  readonly promise: Promise<ClientResponse<LayerMetadata>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for layer.get_layer
 * Get metadata for a layer
 */
export interface getLayerSuccessAction {
  readonly type: 'uf-api/layer/get_layer/SUCCESS';
  readonly result: LayerMetadata;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for layer.get_layer
 * Get metadata for a layer
 */
export interface getLayerFailureAction {
  readonly type: 'uf-api/layer/get_layer/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for layer.get_layer
 * Get metadata for a layer
 */
export interface getLayerClearAction {
  readonly type: 'uf-api/layer/get_layer/CLEAR';
  readonly key: string;
}

const getLayerBoundsPrefix = `uf-api/layer/get_layer_bounds/`;
/**
 * Action types for layer.get_layer_bounds
 *
 * Get geographic bounds for a layer
 */
export const getLayerBoundsActionTypes = {
  LOAD: `${getLayerBoundsPrefix}LOAD` as 'uf-api/layer/get_layer_bounds/LOAD',
  SUCCESS:
    `${getLayerBoundsPrefix}SUCCESS` as 'uf-api/layer/get_layer_bounds/SUCCESS',
  FAILURE:
    `${getLayerBoundsPrefix}FAILURE` as 'uf-api/layer/get_layer_bounds/FAILURE',
  CLEAR:
    `${getLayerBoundsPrefix}CLEAR` as 'uf-api/layer/get_layer_bounds/CLEAR',
} as const;

/**
 * LOAD action for layer.get_layer_bounds
 * Get geographic bounds for a layer
 */
export interface getLayerBoundsLoadAction {
  readonly type: 'uf-api/layer/get_layer_bounds/LOAD';
  readonly params: LayerServiceInterface.getLayerBoundsParams;
  readonly promise: Promise<ClientResponse<LayerBounds>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for layer.get_layer_bounds
 * Get geographic bounds for a layer
 */
export interface getLayerBoundsSuccessAction {
  readonly type: 'uf-api/layer/get_layer_bounds/SUCCESS';
  readonly result: LayerBounds;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for layer.get_layer_bounds
 * Get geographic bounds for a layer
 */
export interface getLayerBoundsFailureAction {
  readonly type: 'uf-api/layer/get_layer_bounds/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for layer.get_layer_bounds
 * Get geographic bounds for a layer
 */
export interface getLayerBoundsClearAction {
  readonly type: 'uf-api/layer/get_layer_bounds/CLEAR';
  readonly key: string;
}

const getLayerBreaksPrefix = `uf-api/layer/get_layer_breaks/`;
/**
 * Action types for layer.get_layer_breaks
 *
 * Get breaks for a layer
 */
export const getLayerBreaksActionTypes = {
  LOAD: `${getLayerBreaksPrefix}LOAD` as 'uf-api/layer/get_layer_breaks/LOAD',
  SUCCESS:
    `${getLayerBreaksPrefix}SUCCESS` as 'uf-api/layer/get_layer_breaks/SUCCESS',
  FAILURE:
    `${getLayerBreaksPrefix}FAILURE` as 'uf-api/layer/get_layer_breaks/FAILURE',
  CLEAR:
    `${getLayerBreaksPrefix}CLEAR` as 'uf-api/layer/get_layer_breaks/CLEAR',
} as const;

/**
 * LOAD action for layer.get_layer_breaks
 * Get breaks for a layer
 */
export interface getLayerBreaksLoadAction {
  readonly type: 'uf-api/layer/get_layer_breaks/LOAD';
  readonly params: LayerServiceInterface.getLayerBreaksParams;
  readonly promise: Promise<ClientResponse<LayerBreaks>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for layer.get_layer_breaks
 * Get breaks for a layer
 */
export interface getLayerBreaksSuccessAction {
  readonly type: 'uf-api/layer/get_layer_breaks/SUCCESS';
  readonly result: LayerBreaks;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for layer.get_layer_breaks
 * Get breaks for a layer
 */
export interface getLayerBreaksFailureAction {
  readonly type: 'uf-api/layer/get_layer_breaks/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for layer.get_layer_breaks
 * Get breaks for a layer
 */
export interface getLayerBreaksClearAction {
  readonly type: 'uf-api/layer/get_layer_breaks/CLEAR';
  readonly key: string;
}

const getLayerBreaksMultiPrefix = `uf-api/layer/get_layer_breaks_multi/`;
/**
 * Action types for layer.get_layer_breaks_multi
 *
 * Get breaks for several layers.
 */
export const getLayerBreaksMultiActionTypes = {
  LOAD: `${getLayerBreaksMultiPrefix}LOAD` as 'uf-api/layer/get_layer_breaks_multi/LOAD',
  SUCCESS:
    `${getLayerBreaksMultiPrefix}SUCCESS` as 'uf-api/layer/get_layer_breaks_multi/SUCCESS',
  FAILURE:
    `${getLayerBreaksMultiPrefix}FAILURE` as 'uf-api/layer/get_layer_breaks_multi/FAILURE',
  CLEAR:
    `${getLayerBreaksMultiPrefix}CLEAR` as 'uf-api/layer/get_layer_breaks_multi/CLEAR',
} as const;

/**
 * LOAD action for layer.get_layer_breaks_multi
 * Get breaks for several layers.
 */
export interface getLayerBreaksMultiLoadAction {
  readonly type: 'uf-api/layer/get_layer_breaks_multi/LOAD';
  readonly params: LayerServiceInterface.getLayerBreaksMultiParams;
  readonly promise: Promise<ClientResponse<LayerBreaks>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for layer.get_layer_breaks_multi
 * Get breaks for several layers.
 */
export interface getLayerBreaksMultiSuccessAction {
  readonly type: 'uf-api/layer/get_layer_breaks_multi/SUCCESS';
  readonly result: LayerBreaks;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for layer.get_layer_breaks_multi
 * Get breaks for several layers.
 */
export interface getLayerBreaksMultiFailureAction {
  readonly type: 'uf-api/layer/get_layer_breaks_multi/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for layer.get_layer_breaks_multi
 * Get breaks for several layers.
 */
export interface getLayerBreaksMultiClearAction {
  readonly type: 'uf-api/layer/get_layer_breaks_multi/CLEAR';
  readonly key: string;
}

const getLayerDataPrefix = `uf-api/layer/get_layer_data/`;
/**
 * Action types for layer.get_layer_data
 *
 * Get properties for a layer
 */
export const getLayerDataActionTypes = {
  LOAD: `${getLayerDataPrefix}LOAD` as 'uf-api/layer/get_layer_data/LOAD',
  SUCCESS:
    `${getLayerDataPrefix}SUCCESS` as 'uf-api/layer/get_layer_data/SUCCESS',
  FAILURE:
    `${getLayerDataPrefix}FAILURE` as 'uf-api/layer/get_layer_data/FAILURE',
  CLEAR: `${getLayerDataPrefix}CLEAR` as 'uf-api/layer/get_layer_data/CLEAR',
} as const;

/**
 * LOAD action for layer.get_layer_data
 * Get properties for a layer
 */
export interface getLayerDataLoadAction {
  readonly type: 'uf-api/layer/get_layer_data/LOAD';
  readonly params: LayerServiceInterface.getLayerDataParams;
  readonly promise: Promise<ClientResponse<LayerData>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for layer.get_layer_data
 * Get properties for a layer
 */
export interface getLayerDataSuccessAction {
  readonly type: 'uf-api/layer/get_layer_data/SUCCESS';
  readonly result: LayerData;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for layer.get_layer_data
 * Get properties for a layer
 */
export interface getLayerDataFailureAction {
  readonly type: 'uf-api/layer/get_layer_data/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for layer.get_layer_data
 * Get properties for a layer
 */
export interface getLayerDataClearAction {
  readonly type: 'uf-api/layer/get_layer_data/CLEAR';
  readonly key: string;
}

const getLayerGeoKeysPrefix = `uf-api/layer/get_layer_geo_keys/`;
/**
 * Action types for layer.get_layer_geo_keys
 *
 * Get geo keys for a layer by filter
 */
export const getLayerGeoKeysActionTypes = {
  LOAD: `${getLayerGeoKeysPrefix}LOAD` as 'uf-api/layer/get_layer_geo_keys/LOAD',
  SUCCESS:
    `${getLayerGeoKeysPrefix}SUCCESS` as 'uf-api/layer/get_layer_geo_keys/SUCCESS',
  FAILURE:
    `${getLayerGeoKeysPrefix}FAILURE` as 'uf-api/layer/get_layer_geo_keys/FAILURE',
  CLEAR:
    `${getLayerGeoKeysPrefix}CLEAR` as 'uf-api/layer/get_layer_geo_keys/CLEAR',
} as const;

/**
 * LOAD action for layer.get_layer_geo_keys
 * Get geo keys for a layer by filter
 */
export interface getLayerGeoKeysLoadAction {
  readonly type: 'uf-api/layer/get_layer_geo_keys/LOAD';
  readonly params: LayerServiceInterface.getLayerGeoKeysParams;
  readonly promise: Promise<ClientResponse<LayerGeoData>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for layer.get_layer_geo_keys
 * Get geo keys for a layer by filter
 */
export interface getLayerGeoKeysSuccessAction {
  readonly type: 'uf-api/layer/get_layer_geo_keys/SUCCESS';
  readonly result: LayerGeoData;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for layer.get_layer_geo_keys
 * Get geo keys for a layer by filter
 */
export interface getLayerGeoKeysFailureAction {
  readonly type: 'uf-api/layer/get_layer_geo_keys/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for layer.get_layer_geo_keys
 * Get geo keys for a layer by filter
 */
export interface getLayerGeoKeysClearAction {
  readonly type: 'uf-api/layer/get_layer_geo_keys/CLEAR';
  readonly key: string;
}

const getLayerGeojsonPrefix = `uf-api/layer/get_layer_geojson/`;
/**
 * Action types for layer.get_layer_geojson
 *
 * Get GeoJSON FeatureCollection for a layer
 */
export const getLayerGeojsonActionTypes = {
  LOAD: `${getLayerGeojsonPrefix}LOAD` as 'uf-api/layer/get_layer_geojson/LOAD',
  SUCCESS:
    `${getLayerGeojsonPrefix}SUCCESS` as 'uf-api/layer/get_layer_geojson/SUCCESS',
  FAILURE:
    `${getLayerGeojsonPrefix}FAILURE` as 'uf-api/layer/get_layer_geojson/FAILURE',
  CLEAR:
    `${getLayerGeojsonPrefix}CLEAR` as 'uf-api/layer/get_layer_geojson/CLEAR',
} as const;

/**
 * LOAD action for layer.get_layer_geojson
 * Get GeoJSON FeatureCollection for a layer
 */
export interface getLayerGeojsonLoadAction {
  readonly type: 'uf-api/layer/get_layer_geojson/LOAD';
  readonly params: LayerServiceInterface.getLayerGeojsonParams;
  readonly promise: Promise<ClientResponse<object>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for layer.get_layer_geojson
 * Get GeoJSON FeatureCollection for a layer
 */
export interface getLayerGeojsonSuccessAction {
  readonly type: 'uf-api/layer/get_layer_geojson/SUCCESS';
  readonly result: object;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for layer.get_layer_geojson
 * Get GeoJSON FeatureCollection for a layer
 */
export interface getLayerGeojsonFailureAction {
  readonly type: 'uf-api/layer/get_layer_geojson/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for layer.get_layer_geojson
 * Get GeoJSON FeatureCollection for a layer
 */
export interface getLayerGeojsonClearAction {
  readonly type: 'uf-api/layer/get_layer_geojson/CLEAR';
  readonly key: string;
}

const getLayerGeometryTypePrefix = `uf-api/layer/get_layer_geometry_type/`;
/**
 * Action types for layer.get_layer_geometry_type
 *
 * Get the geometry type for a layer.
 */
export const getLayerGeometryTypeActionTypes = {
  LOAD: `${getLayerGeometryTypePrefix}LOAD` as 'uf-api/layer/get_layer_geometry_type/LOAD',
  SUCCESS:
    `${getLayerGeometryTypePrefix}SUCCESS` as 'uf-api/layer/get_layer_geometry_type/SUCCESS',
  FAILURE:
    `${getLayerGeometryTypePrefix}FAILURE` as 'uf-api/layer/get_layer_geometry_type/FAILURE',
  CLEAR:
    `${getLayerGeometryTypePrefix}CLEAR` as 'uf-api/layer/get_layer_geometry_type/CLEAR',
} as const;

/**
 * LOAD action for layer.get_layer_geometry_type
 * Get the geometry type for a layer.
 */
export interface getLayerGeometryTypeLoadAction {
  readonly type: 'uf-api/layer/get_layer_geometry_type/LOAD';
  readonly params: LayerServiceInterface.getLayerGeometryTypeParams;
  readonly promise: Promise<ClientResponse<GeometryType>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for layer.get_layer_geometry_type
 * Get the geometry type for a layer.
 */
export interface getLayerGeometryTypeSuccessAction {
  readonly type: 'uf-api/layer/get_layer_geometry_type/SUCCESS';
  readonly result: GeometryType;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for layer.get_layer_geometry_type
 * Get the geometry type for a layer.
 */
export interface getLayerGeometryTypeFailureAction {
  readonly type: 'uf-api/layer/get_layer_geometry_type/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for layer.get_layer_geometry_type
 * Get the geometry type for a layer.
 */
export interface getLayerGeometryTypeClearAction {
  readonly type: 'uf-api/layer/get_layer_geometry_type/CLEAR';
  readonly key: string;
}

const getLayerSpecPrefix = `uf-api/layer/get_layer_spec/`;
/**
 * Action types for layer.get_layer_spec
 *
 * Get load spec for a layer
 */
export const getLayerSpecActionTypes = {
  LOAD: `${getLayerSpecPrefix}LOAD` as 'uf-api/layer/get_layer_spec/LOAD',
  SUCCESS:
    `${getLayerSpecPrefix}SUCCESS` as 'uf-api/layer/get_layer_spec/SUCCESS',
  FAILURE:
    `${getLayerSpecPrefix}FAILURE` as 'uf-api/layer/get_layer_spec/FAILURE',
  CLEAR: `${getLayerSpecPrefix}CLEAR` as 'uf-api/layer/get_layer_spec/CLEAR',
} as const;

/**
 * LOAD action for layer.get_layer_spec
 * Get load spec for a layer
 */
export interface getLayerSpecLoadAction {
  readonly type: 'uf-api/layer/get_layer_spec/LOAD';
  readonly params: LayerServiceInterface.getLayerSpecParams;
  readonly promise: Promise<ClientResponse<string>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for layer.get_layer_spec
 * Get load spec for a layer
 */
export interface getLayerSpecSuccessAction {
  readonly type: 'uf-api/layer/get_layer_spec/SUCCESS';
  readonly result: string;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for layer.get_layer_spec
 * Get load spec for a layer
 */
export interface getLayerSpecFailureAction {
  readonly type: 'uf-api/layer/get_layer_spec/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for layer.get_layer_spec
 * Get load spec for a layer
 */
export interface getLayerSpecClearAction {
  readonly type: 'uf-api/layer/get_layer_spec/CLEAR';
  readonly key: string;
}

const getLayerStatsPrefix = `uf-api/layer/get_layer_stats/`;
/**
 * Action types for layer.get_layer_stats
 *
 * Get stats for a layer
 */
export const getLayerStatsActionTypes = {
  LOAD: `${getLayerStatsPrefix}LOAD` as 'uf-api/layer/get_layer_stats/LOAD',
  SUCCESS:
    `${getLayerStatsPrefix}SUCCESS` as 'uf-api/layer/get_layer_stats/SUCCESS',
  FAILURE:
    `${getLayerStatsPrefix}FAILURE` as 'uf-api/layer/get_layer_stats/FAILURE',
  CLEAR: `${getLayerStatsPrefix}CLEAR` as 'uf-api/layer/get_layer_stats/CLEAR',
} as const;

/**
 * LOAD action for layer.get_layer_stats
 * Get stats for a layer
 */
export interface getLayerStatsLoadAction {
  readonly type: 'uf-api/layer/get_layer_stats/LOAD';
  readonly params: LayerServiceInterface.getLayerStatsParams;
  readonly promise: Promise<ClientResponse<LayerStats>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for layer.get_layer_stats
 * Get stats for a layer
 */
export interface getLayerStatsSuccessAction {
  readonly type: 'uf-api/layer/get_layer_stats/SUCCESS';
  readonly result: LayerStats;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for layer.get_layer_stats
 * Get stats for a layer
 */
export interface getLayerStatsFailureAction {
  readonly type: 'uf-api/layer/get_layer_stats/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for layer.get_layer_stats
 * Get stats for a layer
 */
export interface getLayerStatsClearAction {
  readonly type: 'uf-api/layer/get_layer_stats/CLEAR';
  readonly key: string;
}

const loadDatabankDataPrefix = `uf-api/layer/load_databank_data/`;
/**
 * Action types for layer.load_databank_data
 *
 * Load data for databank.
 */
export const loadDatabankDataActionTypes = {
  LOAD: `${loadDatabankDataPrefix}LOAD` as 'uf-api/layer/load_databank_data/LOAD',
  SUCCESS:
    `${loadDatabankDataPrefix}SUCCESS` as 'uf-api/layer/load_databank_data/SUCCESS',
  FAILURE:
    `${loadDatabankDataPrefix}FAILURE` as 'uf-api/layer/load_databank_data/FAILURE',
  CLEAR:
    `${loadDatabankDataPrefix}CLEAR` as 'uf-api/layer/load_databank_data/CLEAR',
} as const;

/**
 * LOAD action for layer.load_databank_data
 * Load data for databank.
 */
export interface loadDatabankDataLoadAction {
  readonly type: 'uf-api/layer/load_databank_data/LOAD';
  readonly params: LayerServiceInterface.loadDatabankDataParams;
  readonly promise: Promise<ClientResponse<string>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for layer.load_databank_data
 * Load data for databank.
 */
export interface loadDatabankDataSuccessAction {
  readonly type: 'uf-api/layer/load_databank_data/SUCCESS';
  readonly result: string;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for layer.load_databank_data
 * Load data for databank.
 */
export interface loadDatabankDataFailureAction {
  readonly type: 'uf-api/layer/load_databank_data/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for layer.load_databank_data
 * Load data for databank.
 */
export interface loadDatabankDataClearAction {
  readonly type: 'uf-api/layer/load_databank_data/CLEAR';
  readonly key: string;
}

const loadDatabankGeosPrefix = `uf-api/layer/load_databank_geos/`;
/**
 * Action types for layer.load_databank_geos
 *
 * Load geos for databank.
 */
export const loadDatabankGeosActionTypes = {
  LOAD: `${loadDatabankGeosPrefix}LOAD` as 'uf-api/layer/load_databank_geos/LOAD',
  SUCCESS:
    `${loadDatabankGeosPrefix}SUCCESS` as 'uf-api/layer/load_databank_geos/SUCCESS',
  FAILURE:
    `${loadDatabankGeosPrefix}FAILURE` as 'uf-api/layer/load_databank_geos/FAILURE',
  CLEAR:
    `${loadDatabankGeosPrefix}CLEAR` as 'uf-api/layer/load_databank_geos/CLEAR',
} as const;

/**
 * LOAD action for layer.load_databank_geos
 * Load geos for databank.
 */
export interface loadDatabankGeosLoadAction {
  readonly type: 'uf-api/layer/load_databank_geos/LOAD';
  readonly params: LayerServiceInterface.loadDatabankGeosParams;
  readonly promise: Promise<ClientResponse<string>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for layer.load_databank_geos
 * Load geos for databank.
 */
export interface loadDatabankGeosSuccessAction {
  readonly type: 'uf-api/layer/load_databank_geos/SUCCESS';
  readonly result: string;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for layer.load_databank_geos
 * Load geos for databank.
 */
export interface loadDatabankGeosFailureAction {
  readonly type: 'uf-api/layer/load_databank_geos/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for layer.load_databank_geos
 * Load geos for databank.
 */
export interface loadDatabankGeosClearAction {
  readonly type: 'uf-api/layer/load_databank_geos/CLEAR';
  readonly key: string;
}

const setReferenceLayerPrefix = `uf-api/layer/set_reference_layer/`;
/**
 * Action types for layer.set_reference_layer
 *
 * Toggles the flag on a layer to make it a reference layer or not.
 */
export const setReferenceLayerActionTypes = {
  LOAD: `${setReferenceLayerPrefix}LOAD` as 'uf-api/layer/set_reference_layer/LOAD',
  SUCCESS:
    `${setReferenceLayerPrefix}SUCCESS` as 'uf-api/layer/set_reference_layer/SUCCESS',
  FAILURE:
    `${setReferenceLayerPrefix}FAILURE` as 'uf-api/layer/set_reference_layer/FAILURE',
  CLEAR:
    `${setReferenceLayerPrefix}CLEAR` as 'uf-api/layer/set_reference_layer/CLEAR',
} as const;

/**
 * LOAD action for layer.set_reference_layer
 * Toggles the flag on a layer to make it a reference layer or not.
 */
export interface setReferenceLayerLoadAction {
  readonly type: 'uf-api/layer/set_reference_layer/LOAD';
  readonly params: LayerServiceInterface.setReferenceLayerParams;
  readonly promise: Promise<ClientResponse<string>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for layer.set_reference_layer
 * Toggles the flag on a layer to make it a reference layer or not.
 */
export interface setReferenceLayerSuccessAction {
  readonly type: 'uf-api/layer/set_reference_layer/SUCCESS';
  readonly result: string;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for layer.set_reference_layer
 * Toggles the flag on a layer to make it a reference layer or not.
 */
export interface setReferenceLayerFailureAction {
  readonly type: 'uf-api/layer/set_reference_layer/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for layer.set_reference_layer
 * Toggles the flag on a layer to make it a reference layer or not.
 */
export interface setReferenceLayerClearAction {
  readonly type: 'uf-api/layer/set_reference_layer/CLEAR';
  readonly key: string;
}

export const layerActionTypesMap = {
  /** Action types for layer.add_databank_to_org */
  add_databank_to_org: addDatabankToOrgActionTypes,
  /** Action types for layer.create_databank */
  create_databank: createDatabankActionTypes,
  /** Action types for layer.download_exported_layer */
  download_exported_layer: downloadExportedLayerActionTypes,
  /** Action types for layer.enqueue_layer_export_task */
  enqueue_layer_export_task: enqueueLayerExportTaskActionTypes,
  /** Action types for layer.get_app_layers_by_context */
  get_app_layers_by_context: getAppLayersByContextActionTypes,
  /** Action types for layer.get_latest_version */
  get_latest_version: getLatestVersionActionTypes,
  /** Action types for layer.get_layer */
  get_layer: getLayerActionTypes,
  /** Action types for layer.get_layer_bounds */
  get_layer_bounds: getLayerBoundsActionTypes,
  /** Action types for layer.get_layer_breaks */
  get_layer_breaks: getLayerBreaksActionTypes,
  /** Action types for layer.get_layer_breaks_multi */
  get_layer_breaks_multi: getLayerBreaksMultiActionTypes,
  /** Action types for layer.get_layer_data */
  get_layer_data: getLayerDataActionTypes,
  /** Action types for layer.get_layer_geo_keys */
  get_layer_geo_keys: getLayerGeoKeysActionTypes,
  /** Action types for layer.get_layer_geojson */
  get_layer_geojson: getLayerGeojsonActionTypes,
  /** Action types for layer.get_layer_geometry_type */
  get_layer_geometry_type: getLayerGeometryTypeActionTypes,
  /** Action types for layer.get_layer_spec */
  get_layer_spec: getLayerSpecActionTypes,
  /** Action types for layer.get_layer_stats */
  get_layer_stats: getLayerStatsActionTypes,
  /** Action types for layer.load_databank_data */
  load_databank_data: loadDatabankDataActionTypes,
  /** Action types for layer.load_databank_geos */
  load_databank_geos: loadDatabankGeosActionTypes,
  /** Action types for layer.set_reference_layer */
  set_reference_layer: setReferenceLayerActionTypes,
} as const;

export const layerDetailsMap = {
  add_databank_to_org: {
    /** HTTP method for layer.add_databank_to_org */
    httpMethod: 'post' as const,
  },
  create_databank: {
    /** HTTP method for layer.create_databank */
    httpMethod: 'post' as const,
  },
  download_exported_layer: {
    /** HTTP method for layer.download_exported_layer */
    httpMethod: 'get' as const,
  },
  enqueue_layer_export_task: {
    /** HTTP method for layer.enqueue_layer_export_task */
    httpMethod: 'post' as const,
  },
  get_app_layers_by_context: {
    /** HTTP method for layer.get_app_layers_by_context */
    httpMethod: 'get' as const,
  },
  get_latest_version: {
    /** HTTP method for layer.get_latest_version */
    httpMethod: 'get' as const,
  },
  get_layer: {
    /** HTTP method for layer.get_layer */
    httpMethod: 'get' as const,
  },
  get_layer_bounds: {
    /** HTTP method for layer.get_layer_bounds */
    httpMethod: 'get' as const,
  },
  get_layer_breaks: {
    /** HTTP method for layer.get_layer_breaks */
    httpMethod: 'get' as const,
  },
  get_layer_breaks_multi: {
    /** HTTP method for layer.get_layer_breaks_multi */
    httpMethod: 'get' as const,
  },
  get_layer_data: {
    /** HTTP method for layer.get_layer_data */
    httpMethod: 'get' as const,
  },
  get_layer_geo_keys: {
    /** HTTP method for layer.get_layer_geo_keys */
    httpMethod: 'get' as const,
  },
  get_layer_geojson: {
    /** HTTP method for layer.get_layer_geojson */
    httpMethod: 'get' as const,
  },
  get_layer_geometry_type: {
    /** HTTP method for layer.get_layer_geometry_type */
    httpMethod: 'get' as const,
  },
  get_layer_spec: {
    /** HTTP method for layer.get_layer_spec */
    httpMethod: 'get' as const,
  },
  get_layer_stats: {
    /** HTTP method for layer.get_layer_stats */
    httpMethod: 'get' as const,
  },
  load_databank_data: {
    /** HTTP method for layer.load_databank_data */
    httpMethod: 'post' as const,
  },
  load_databank_geos: {
    /** HTTP method for layer.load_databank_geos */
    httpMethod: 'post' as const,
  },
  set_reference_layer: {
    /** HTTP method for layer.set_reference_layer */
    httpMethod: 'put' as const,
  },
} as const;

/**
 * Local DataState<T> - kept in sync with uf/data/dataState.ts
 */
interface DataState<T> {
  readonly loading: boolean;
  readonly loaded: boolean;
  readonly requestId: string;
  readonly requestTime?: string;
  readonly responseTime?: string;
  readonly requestCount?: number;
  readonly error?: any;
  readonly promise?: Promise<T>;
  readonly data?: T;
  readonly extra?: any;
  readonly key?: string;
}
export interface LayerServiceState {
  /** all recent addDatabankToOrg results (POST/PUT/PATCH) */
  readonly add_databank_to_org: DataState<{}>[];
  /** all recent createDatabank results (POST/PUT/PATCH) */
  readonly create_databank: DataState<LayerReference>[];
  /** downloadExportedLayer state, by key */
  download_exported_layer: {
    readonly [key: string]: DataState<{}>;
  };
  /** all recent enqueueLayerExportTask results (POST/PUT/PATCH) */
  readonly enqueue_layer_export_task: DataState<TaskEnqueueResultItem>[];
  /** getAppLayersByContext state, by key */
  get_app_layers_by_context: {
    readonly [key: string]: DataState<Array<AppLayer>>;
  };
  /** getLatestVersion state, by key */
  get_latest_version: {
    readonly [key: string]: DataState<string>;
  };
  /** getLayer state, by key */
  get_layer: {
    readonly [key: string]: DataState<LayerMetadata>;
  };
  /** getLayerBounds state, by key */
  get_layer_bounds: {
    readonly [key: string]: DataState<LayerBounds>;
  };
  /** getLayerBreaks state, by key */
  get_layer_breaks: {
    readonly [key: string]: DataState<LayerBreaks>;
  };
  /** getLayerBreaksMulti state, by key */
  get_layer_breaks_multi: {
    readonly [key: string]: DataState<LayerBreaks>;
  };
  /** getLayerData state, by key */
  get_layer_data: {
    readonly [key: string]: DataState<LayerData>;
  };
  /** getLayerGeoKeys state, by key */
  get_layer_geo_keys: {
    readonly [key: string]: DataState<LayerGeoData>;
  };
  /** getLayerGeojson state, by key */
  get_layer_geojson: {
    readonly [key: string]: DataState<object>;
  };
  /** getLayerGeometryType state, by key */
  get_layer_geometry_type: {
    readonly [key: string]: DataState<GeometryType>;
  };
  /** getLayerSpec state, by key */
  get_layer_spec: {
    readonly [key: string]: DataState<string>;
  };
  /** getLayerStats state, by key */
  get_layer_stats: {
    readonly [key: string]: DataState<LayerStats>;
  };
  /** all recent loadDatabankData results (POST/PUT/PATCH) */
  readonly load_databank_data: DataState<string>[];
  /** all recent loadDatabankGeos results (POST/PUT/PATCH) */
  readonly load_databank_geos: DataState<string>[];
  /** all recent setReferenceLayer results (POST/PUT/PATCH) */
  readonly set_reference_layer: DataState<string>[];
}
