import { Epic, ofType } from 'redux-observable';
import { merge } from 'rxjs';
import {
  filter,
  first,
  map,
  mergeMap,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import { t } from 'ttag';

import { getProjectActionTypes } from 'uf-api/api/project.service';
import { addAppMessage } from 'uf/appservices/actions';
import { Message } from 'uf/appservices/messages';
import { combineEpics } from 'uf/base/epics';
import { setActiveLayer } from 'uf/explore/actions/layers';
import { events } from 'uf/layers/logging';
import { logEvent } from 'uf/logging';
import { makeGetProjectLayerIdMap } from 'uf/projects/selectors/virtualLayers';
import { ImportLayerTaskMessage } from 'uf/projects/tasks';
import { NotificationAction } from 'uf/tasks/ActionTypes';
import { NotificationTypes } from 'uf/tasks/NotificationTypes';
import { makeWebsocketActionType } from 'uf/websockets/actionHelpers';

export const importLayerStartedEpic: Epic<
  NotificationAction<ImportLayerTaskMessage>,
  any
> = (action$, state$) => {
  return action$.pipe(
    ofType(makeWebsocketActionType(NotificationTypes.LAYER_IMPORT_STARTED)),
    withLatestFrom(state$),
    filter(([action, state]) => {
      /*
       * The BE controls when the task started message is sent.
       * Due to this we need to be sure an error wasn't messaged before the task started message.
       * Otherwise the loading toast will override the error message and never disappear.
       */
      return !state.appservices.messages?.some(
        task => task.id === action.result.task_id && task.status !== 'running',
      );
    }),
    map(([action, state]) => {
      logEvent(events.LAYER_IMPORT_STARTED, { ...action.result });
      const text = `Uploading layer "${action.result.info.layer_name}"...`;
      const toastMessage: Message = {
        status: 'running',
        timeout: 0,
        id: action.result.task_id,
      };

      return addAppMessage(text, toastMessage);
    }),
  );
};

export const importLayerDoneEpic: Epic<
  NotificationAction<ImportLayerTaskMessage>,
  any
> = (action$, state$) => {
  return action$.pipe(
    ofType(makeWebsocketActionType(NotificationTypes.LAYER_IMPORT_DONE)),
    switchMap(({ result }) => {
      const projectId = result.scope;
      const getProjectLayerMap = makeGetProjectLayerIdMap();
      return action$.pipe(
        ofType(getProjectActionTypes.SUCCESS),
        first(
          loadProjectAction => loadProjectAction.result.full_path === projectId,
        ),
        withLatestFrom(state$),
        /*
         * Two possible notifications can come out of this epic:
         * 1. A success message for the layer upload
         * 2. A success message for the geocode result
         */
        mergeMap(([x, state]) => {
          logEvent(events.LAYER_IMPORT_DONE, { ...result });
          const {
            result: { layer_ref: layerId, geo_res: geoRes },
          } = result;
          const layerMap = getProjectLayerMap(state, { projectId });
          const virtualLayerId = layerMap[layerId];
          const onClickAction = setActiveLayer(projectId, virtualLayerId);
          const notifications = [];
          notifications.push(
            addAppMessage(
              `Successfully uploaded layer "${result.info.layer_name}"`,
              {
                level: 'success',
                status: 'success',
                timeout: 0,
                id: result.task_id,
                action: {
                  text: 'VIEW',
                  onClick: () => onClickAction,
                },
              },
            ),
          );
          if (geoRes) {
            notifications.push(
              addAppMessage(
                `Successfully matched "${result.info.layer_name}". ${geoRes.unmatched} could not be matched.`,
                {
                  level: 'success',
                  status: 'success',
                  timeout: 0,
                  id: `${result.task_id}-geocode-result`,
                  action: {
                    text: 'VIEW',
                    onClick: () => onClickAction,
                  },
                },
              ),
            );
          }
          return merge(notifications);
        }),
      );
    }),
  );
};

export const importLayerErrorEpic: Epic<
  NotificationAction<ImportLayerTaskMessage>,
  any
> = action$ => {
  return action$
    .ofType(makeWebsocketActionType(NotificationTypes.LAYER_IMPORT_ERROR))
    .pipe(
      map(({ result }) => {
        logEvent(events.LAYER_IMPORT_ERROR, { ...result });
        const detail = getErrorDetail(result);
        return addAppMessage(detail, {
          level: 'danger',
          status: 'failure',
          timeout: 0,
          id: result.task_id,
        });
      }),
    );
};

/**
 * Gets a detailed error message based on the error code
 * @param result the error message
 */
function getErrorDetail(result): string {
  const { type_code: typeCode } = result.problem;
  const filename = result.info.filename;
  switch (typeCode) {
    case 'ambiguous_columns':
      return t`${filename} has too many possible geometry columns.`;
    case 'invalid_zip':
      return t`${filename} is not a valid zipped shapefile.`;
    case 'file_size_exceeds_limit':
      return t`${filename} exceeds the current filesize limit.`;
    case 'too_many_features':
      return t`${filename} exceeds limit on number of features.`;
    case 'unknown_type_error':
      return t`${filename} contains an unsupported data type.`;
    case 'no_features':
      return t`${filename} does not have any geometries.`;
    case 'no_valid_features':
      return t`${filename} does not have valid geometries.`;
    case 'no_projection':
      return t`${filename} does not have a projection specified.`;
    case 'invalid_projection':
      return t`${filename} has an invalid projection specified. Export with WGS84 (EPSG 4326) instead.`;
    case 'out_of_scope':
      return t`The geometries in ${filename} are outside of the scope of the project.`;
    case 'too_many_columns': {
      const columnCount = result?.problem?.ext?.column_count;
      const columnLimit = result?.problem?.ext?.max_column_limit;

      // If we have the numerical amounts, we can display a more informative error mesage
      if (columnCount && columnLimit) {
        return t`${filename} has ${columnCount} columns, which exceeds the maximum of ${columnLimit} columns.`;
      }
      return t`${filename} exceeds the current column limit.`;
    }
    case 'missing_data_file': {
      const missingExtension = result?.problem?.ext?.missing_extension;
      return getMissingExtensionDetail(missingExtension, filename);
    }
    //  We can string switch cases together
    case 'couldnt_generate_id_field':
    case 'geo_load_error':
    case 'data_load_error':
      return t`Could not import ${filename}: ${result.problem.title}`;
    case 'too_many_layers':
      return t`${filename} has too many layers. Files must have only one layer.`;
    case 'mixed_geometry_types':
      return t`Could not import ${filename} because it contains 2 or more geometry types.
               Please try again using a layer that contains a single geometry type.`;
    case 'max_geocode_rows':
      return t`Could not geocode ${filename} because the limit is 20,000 rows.`;
    default:
      return t`Could not import ${filename}`;
  }
}

/**
 * Get an error message based on the missing file extension
 * @param missingExt the missing file extension
 * @param filename the filename of the file with a mission extension
 */
function getMissingExtensionDetail(
  missingExt: string,
  filename: string,
): string {
  switch (missingExt) {
    case '.shp':
      return t`${filename} is missing a geometries (.shp) file.`;
    case '.shx':
      return t`${filename} is missing an index (.shx) file.`;
    case '.dbf':
      return t`${filename} is missing an attributes (.dbf) file.`;
    case '.prj':
      return t`${filename} is missing a projection (.prj) file.`;
    default:
      return t`${filename} is missing a ${missingExt} file.`;
  }
}

export default combineEpics(
  {
    importLayerStartedEpic,
    importLayerDoneEpic,
    importLayerErrorEpic,
  },
  'importLayers',
);
