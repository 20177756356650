import { Mode } from '@mapbox/mapbox-gl-draw';
import { createSelector, defaultMemoize } from 'reselect';

import { EMPTY_ARRAY, EMPTY_OBJECT } from 'uf/base';
import { makeIdListKey } from 'uf/base/ids';
import { makeGetFromPropsSelector } from 'uf/base/selector';
import { TabKey } from 'uf/explore/details';
import {
  ExploreState,
  JoinItem,
  LegacyExploreState,
  UiState,
} from 'uf/explore/state';
import { LayerId } from 'uf/layers';
import { ProjectId } from 'uf/projects';
import { UFState } from 'uf/state';

/**
 * @deprecated use `makeGetExploreByProjectState` when possible
 */
export function getLegacyExploreState(state: UFState): LegacyExploreState {
  const { explore = EMPTY_OBJECT as LegacyExploreState } = state;
  return explore;
}

function getExploreProjectState(state: UFState): Record<string, ExploreState> {
  const { exploreByProjectId = EMPTY_OBJECT as Record<string, ExploreState> } =
    state;
  return exploreByProjectId;
}

export function makeGetExploreByProjectState() {
  return createSelector(
    getExploreProjectState,
    makeGetFromPropsSelector<ProjectId, 'projectId'>('projectId'),
    (exploreProjectState, projectId): ExploreState =>
      exploreProjectState[projectId] || (EMPTY_OBJECT as ExploreState),
  );
}

export function makeGetLayerActiveDetailTabKey() {
  return createSelector(
    makeGetExploreByProjectState(),
    makeGetFromPropsSelector<LayerId, 'layerId'>('layerId'),
    (state, layerId): TabKey => {
      return state.detailsTabKey?.[layerId] || TabKey.STYLE;
    },
  );
}
export function makeGetLayerActiveDetailTabKeyGetter() {
  return createSelector(makeGetExploreByProjectState(), state =>
    defaultMemoize(
      (layerId: LayerId): TabKey =>
        state.detailsTabKey?.[layerId] || TabKey.STYLE,
    ),
  );
}

export function makeGetDrawingMode() {
  return createSelector(
    makeGetExploreByProjectState(),
    makeGetFromPropsSelector<LayerId, 'layerId'>('layerId'),
    (state, layerId): Mode<'box_select_vertices'> =>
      state?.drawingMode?.[layerId]?.mode || 'simple_select',
  );
}

const getExploreUIState = createSelector(
  getLegacyExploreState,
  exploreState => exploreState.ui || (EMPTY_OBJECT as UiState),
);

/**
 * Get the mapping from layer
 */
export const getShowPaintedOnly = createSelector(
  getExploreUIState,
  uiState =>
    uiState.showPaintedOnly || (EMPTY_OBJECT as Record<string, boolean>),
);

export const getExploreUIPropertiesState = createSelector(
  getExploreUIState,
  exploreUIState =>
    exploreUIState.properties || (EMPTY_OBJECT as Record<string, any>),
);

/**
 * Generic selector creator. Generally used to get at a specific property:
 *
 * Just declare this locally in your component:
 *   const getChartsVisible = makeGetExploreUIProperty('chartPanelVisible', false);
 *
 * And then use in @connect:
 *
 * @connect(state => ({ chartPanelVisible: getChartsVisible(state) }))
 */

export function makeGetExploreUIProperty<T = any>(
  propertyKey: string,
  defaultValue = EMPTY_OBJECT as T,
) {
  return createSelector(getExploreUIPropertiesState, (state): T => {
    if (propertyKey in state) {
      return state[propertyKey];
    }
    return defaultValue;
  });
}

// TODO: make this a generic selector with the key passed in as a prop
const getFilterListItems = createSelector(
  getExploreUIState,
  exploreUIState => exploreUIState.filterListItems,
);

function getLayerFilterId(layerId: LayerId, parentLayerId: string) {
  if (parentLayerId) {
    return makeIdListKey([parentLayerId, layerId]);
  }
  return layerId;
}

export function makeGetFilterListItems() {
  return createSelector(
    getFilterListItems,
    makeGetFromPropsSelector<LayerId, 'layerId'>('layerId'),
    makeGetFromPropsSelector<ProjectId, 'projectId'>('projectId'),
    makeGetFromPropsSelector<LayerId, 'parentLayerId'>('parentLayerId'),
    (filterListItems, layerId, projectId, parentLayerId): JoinItem[] => {
      const projectItems =
        filterListItems[projectId] ||
        (EMPTY_OBJECT as Record<string, JoinItem[]>);
      const filterId = getLayerFilterId(layerId, parentLayerId);
      return projectItems[filterId] || EMPTY_ARRAY;
    },
  );
}

export function makeGetFilterListItemColumns() {
  return createSelector(makeGetFilterListItems(), joinItems =>
    joinItems.map(({ key }) => key),
  );
}

const getGeoJoinListItems = createSelector(
  getExploreUIState,
  exploreUIState => exploreUIState.geojoinListItems,
);

export function makeGetGeoJoinListItems() {
  return createSelector(
    getGeoJoinListItems,
    makeGetFromPropsSelector<ProjectId, 'projectId'>('projectId'),
    makeGetFromPropsSelector<LayerId, 'layerId'>('layerId'),
    // TODO: further link by layerid?
    (joinItems, projectId, layerId): JoinItem[] => {
      const projectItems =
        joinItems[projectId] || (EMPTY_OBJECT as Record<string, JoinItem[]>);
      return projectItems[layerId] || EMPTY_ARRAY;
    },
  );
}

export const getExploreStatsState = createSelector(
  makeGetExploreByProjectState(),
  exploreState =>
    exploreState.stats || (EMPTY_OBJECT as typeof exploreState.stats),
);

const getAnalysisUIVisibleState = makeGetExploreUIProperty(
  'analysisUIVisible',
  false,
);

const getBuildUIVisibleState = makeGetExploreUIProperty(
  'paintUIVisible',
  false,
);

export const getExploreViewVisible = makeGetExploreUIProperty(
  'exploreViewVisible',
  true,
);
export const getReportViewVisible = makeGetExploreUIProperty(
  'reportViewVisible',
  true,
);
export const getManageViewVisible = makeGetExploreUIProperty(
  'manageViewVisible',
  false,
);

export const getVisibleSecondaryPanel = makeGetExploreUIProperty(
  'visibleSecondaryPanel',
  null,
);

export const getFilterListVisible = createSelector(
  getVisibleSecondaryPanel,
  panel => panel === 'filterList',
);

export const getBuildUIVisible = createSelector(
  getBuildUIVisibleState,
  getExploreViewVisible,
  (paintUIVisible, exploreViewVisible) => paintUIVisible && exploreViewVisible,
);

export const getAnalysisUIVisible = createSelector(
  getAnalysisUIVisibleState,
  getExploreViewVisible,
  (analysisUIVisible, exploreViewVisible) =>
    analysisUIVisible && exploreViewVisible,
);
