import { Action } from 'redux';

import {
  AppendItemAction,
  ListActionCreatorsExtra,
  ListActionTypes,
  SetListAction,
} from 'uf/base/listActionHelpers';
import { UFState } from 'uf/state';

import { RegisterOptions, registerPersistedAction } from './';

/**
 * Register persistence for an entire set of list actions.
 *
 * This is very similar to `registerPersistedListAction` except that it applies
 * to multiple actions, and the same getKey/getValue is used in each
 * registration. This means that you typically need to use the `state` parameter
 * to `getValue` along with a selector to extract the list value from the store.
 *
 * Typical usage:
 * ```
 * // in ActionTypes.ts
 * const listActionTypes = makeListActionTypes(...);
 *
 * // in actions.ts
 * export const listActions = makeListActionCreatorsWithExtra(
 *   listActionTypes,
 *   (projectId: ProjectId, layerId: LayerId) => ({ projectId, layerId}));
 *
 * registerListPersistence(
 *   listActions,
 *   listActionTypes,
 *   ({projectId, layerId}) => ([projectId, layerId]),
 *   ({projectId, layerId}, state) => getProjectListThing(state, {projectId, layerId});
 * ```
 *
 * @param prefix persistence prefix
 * @param listActions Action creators created by
 *   `makeListActionCreatorsWithExtra()`
 * @param listActionTypes The action types created by `makeListActionTypes()`,
 * @param dataResolvers
 * @param dataResolvers.getKey Extract the persistence key from the `extra` parameters
 * @param dataResolvers.getValue Extract the value to store, typically with a selector
 */
export function registerListPersistence<
  V,
  EA extends any[],
  EP,
  SET extends string,
  UPDATE extends string,
  UPSERT extends string,
  UPSERT_ITEMS extends string,
  APPEND extends string,
  REMOVE extends string,
>(
  prefix: string,
  listActions: ListActionCreatorsExtra<
    V,
    EA,
    EP,
    SET,
    UPDATE,
    UPSERT,
    UPSERT_ITEMS,
    APPEND,
    REMOVE
  >,
  listActionTypes: ListActionTypes<
    SET,
    UPDATE,
    UPSERT,
    UPSERT_ITEMS,
    APPEND,
    REMOVE
  >,
  { getKey, getValue }: RegisterOptions<EP & Action, EA, V[], UFState>,
) {
  const unregisterSet = registerPersistedAction<
    SetListAction<V> & EP,
    EA,
    V[],
    UFState
  >(
    prefix,
    listActionTypes.SET,
    (extraArgs, value) => listActions.setList(value, ...extraArgs),
    {
      getValue,
      getKey,
    },
  );
  const unregisterOthers = [
    listActionTypes.APPEND,
    listActionTypes.REMOVE,
    listActionTypes.UPDATE,
    listActionTypes.UPSERT,
    listActionTypes.UPSERT_ITEMS,
  ].map(actionType =>
    registerPersistedAction<AppendItemAction<V> & EP, EA, V[], UFState>(
      prefix,
      actionType,
      null,
      {
        getValue,
        getKey,
      },
    ),
  );
  const unregistrations = [unregisterSet, ...unregisterOthers];

  return () => {
    unregistrations.forEach(unregister => unregister());
  };
}
