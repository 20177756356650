import colors from 'material-colors';

import { DEFAULT_GEO_COLUMN_KEY } from 'uf/layers/geometryKey';
import {
  CuratedColumnSymbology,
  DEFAULT_OPACITY,
  InterpolationTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const tazLayerList = [
  'public/dataset/ambag_taz_2015',
  'public/dataset/ambag_taz_2040',
];

const symbologies: Record<
  string,
  Record<string, CuratedColumnSymbology[]>
> = {};

tazLayerList.forEach(tazLayerPath => {
  symbologies[tazLayerPath] = {
    [DEFAULT_GEO_COLUMN_KEY]: [
      {
        type: SymbologyTypes.LINE,
        paint: {
          'line-width': 1,
          'line-color': colors.grey[500],
          'line-opacity': DEFAULT_OPACITY,
        },
        display_hints: {
          scale: SymbologyScales.CATEGORICAL,
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          theme: 'material:grey:500',
        },
      },
    ],
  };
});

export default symbologies;
