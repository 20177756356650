import area from '@turf/area';
import { lineString, point, polygon } from '@turf/helpers';
import { LineString, Point, Polygon } from 'geojson';
import _ from 'lodash';
import { deflate } from 'pako';
import warning from 'warning';

import {
  ExpressionAnd,
  ExpressionBoundingBox,
  ExpressionColumn,
  ExpressionEquals,
  ExpressionGeometry,
  ExpressionGreaterThan,
  ExpressionGreaterThanOrEquals,
  ExpressionIn,
  ExpressionIntersects,
  ExpressionIsNull,
  ExpressionLayerIntersects,
  ExpressionLessThan,
  ExpressionLessThanOrEquals,
  ExpressionNot,
  ExpressionOr,
  ExpressionPoint,
  ExpressionStartsWith,
  ExpressionWithinBoundingBox,
  ExpressionWithinPolygon,
  ExpressionWithinTile,
  LayerColumn,
} from 'uf-api';
import { getCanonicalParameters } from 'uf/base/api';
import { getBooleanValue } from 'uf/base/formatting';
import { BoundingBox, PolygonGeometry } from 'uf/base/geometry';
import { roundLngLat } from 'uf/base/map';
import { assertNever } from 'uf/base/never';
import { makeReduxDataKey } from 'uf/data/helpers';
import { ColumnKey, LayerId } from 'uf/layers';
import { Metatype } from 'uf/layers/metadata';

import { BUFFER_UNITS } from './buffers';
import { GEOMETRY_KEY } from './geometryKey';

/**
 * The minimum area of a polygon or bounding box, before it is converted to just
 * a linestring. Really this should just be zero, but due to rounding errors,
 * `turf` sometimes gives us very small areas for what we would consider a
 * straight line or a point.
 */
const MINIMUM_AREA_SQUARE_METERS = 0.01;

/**
 * A generalized type to cover all expression types.
 *
 * Unfortunately there isn't a way to generate this list automatically. This
 * list should stay in sync with the available expression types generated by
 * swagger.
 */
export type Expression =
  | ExpressionAnd
  | ExpressionBoundingBox
  | ExpressionColumn
  | ExpressionEquals
  | ExpressionGeometry
  | ExpressionGreaterThan
  | ExpressionGreaterThanOrEquals
  | ExpressionIn
  | ExpressionIntersects
  | ExpressionIsNull
  | ExpressionLayerIntersects
  | ExpressionLessThan
  | ExpressionLessThanOrEquals
  | ExpressionNot
  | ExpressionOr
  | ExpressionPoint
  | ExpressionStartsWith
  | ExpressionWithinBoundingBox
  | ExpressionWithinPolygon
  | ExpressionWithinTile;

export interface SearchParamsBase {
  version?: string;
  filters?: Partial<FilterSpec>;
}

export interface StatsParams extends SearchParamsBase {
  version: string;
  columns?: string[];
  row_count?: boolean;
}

export interface LayerDataParams extends SearchParamsBase {
  // TODO: can we remove Partial?

  limit?: number;
  sortParams?: {
    sortColumn: string;
    sortDirection: string;
  };
}

export interface ExpressionSearchParams {
  version?: string;
  filters?: Expression[];
}

/**
 * The root object for client-side representation of a filter.
 *
 * This object will get converted over to to server-side expressions using
 * {@link convertFiltersToFilterQuery}.
 */
export interface FilterSpec {
  disabled?: boolean;
  columns: ColumnFilters;
  pointIds: PointFilterSpec;
  geometries: GeometryFilterSpec;
  layers: LayerFilters;
  preFilters: ColumnFilters;

  version?: string;
  buffer?: FilterBuffer;
  joinMethod?: JoinLayerMethod;
}

export interface FilterBuffer {
  distance: number;
  unit: string;

  displayDistance: number;
  displayUnit: BUFFER_UNITS;
}

// TODO: move these somewhere global

export type ColumnFilters = Record<ColumnKey, ColumnFilter<any>>;
export type LayerFilters = Record<LayerId, Partial<FilterSpec>>;

export interface CategoricalColumnFilter<T = string> {
  /**
   * Indicates that this is a categorical feature
   */
  columnMetatype: Metatype.CATEGORICAL;

  /** The actual type of the column. Almost always String, but it is reasonable
   * for this to be a number or boolean */
  type: LayerColumn.TypeEnum;

  /**
   * The possible values that this column can have.
   */

  filterValue: CategoricalFilterValue<T>;
  /**
   * Should the value 'null' be included as a value to filter on?
   */
  includeNull?: boolean;
}

export interface NumericColumnFilter {
  columnMetatype: Metatype.NUMERIC;

  filterValue: NumericFilterValue;
}

// For hand-written filters (currently used in LocationPicker)
export interface ManualFilter {
  columnMetatype: 'manual';

  fn: string;
  filterValue: any;
}

export type ColumnFilter<T = string> =
  | ManualFilter
  | NumericColumnFilter
  | CategoricalColumnFilter<T>;
export type ColumnFilterValue<T = string> =
  | NumericFilterValue
  | CategoricalFilterValue<T>;

// Define these separately to make sure it contains at least min or max, or both
interface NumericFilterMin {
  min: number;
  /** Should we use ">=" (true) or ">" (false) */
  minInclusive: boolean;
  includeNull?: boolean;
}
interface NumericFilterMax {
  max: number;
  /** Should we use "<=" (true) or "<" (false) */
  maxInclusive: boolean;
  includeNull?: boolean;
}
type NumericFilterMaxMin = NumericFilterMin & NumericFilterMax;
/* Include min, max, or both */
export type NumericFilterValue =
  | NumericFilterMin
  | NumericFilterMax
  | NumericFilterMaxMin;

export const EmptyNumericFilterValue: NumericFilterValue = {
  min: null,
  minInclusive: true,
  max: null,
  maxInclusive: true,
  includeNull: false,
};
export type CategoricalFilterValue<T = string> = T[];

export type PointFilterSpec = Record<string, boolean>;

export interface GeometryPolygonFilterSpec {
  polygon?: PolygonGeometry[];
}

export interface GeometryBboxFilterSpec {
  bbox?: BoundingBox[];
}
export type GeometryFilterSpec = GeometryPolygonFilterSpec &
  GeometryBboxFilterSpec;

export type JoinLayerMethod = 'layer_intersects' | 'not_layer_intersects';

/**
 * This is to make migration to FilterSpec easier. Either use it directly, or
 * destructure it into your filter before you add a property:
 *
 *   const columnFilter: FilterSpec = {...EmptyFilterSpec, columns: {
 *     ...
 *     }
 *   };
 */
export const EmptyFilterSpec: FilterSpec = {
  columns: {},
  pointIds: {},
  geometries: {},
  layers: {},
  preFilters: {},
};

Object.freeze(EmptyFilterSpec);

/**
 * Convert the frontend's shape of filters to something the server can use.
 *
 * The incoming filters are in the form:
 * [{ <columnKey>: <filterValue>,
 *    <columnKey>: <filterValue>,
 *    ...,
 * }]
 *
 * Where <filterValue> varies in shape depending on the metatype of the column.
 *
 * See docs/Filtering.md for details on the output.
 */
export function convertFiltersToExpressions(
  filters: Partial<FilterSpec>,
): Expression[] {
  if (filtersAreEmpty(filters)) {
    // TODO: should we return EMPTY_ARRAY?
    return null;
  }

  const {
    disabled,
    columns: columnFilters = {} as Record<string, ColumnFilter>,
    pointIds: pointFilter = {} as PointFilterSpec,
    geometries: geometryFilters = {} as GeometryFilterSpec,
    layers: layerFilters = {} as LayerFilters,
    preFilters = {} as Record<string, ColumnFilter>,
  } = filters;

  if (disabled) {
    return null;
  }

  const geometryQuery = convertGeometryFilters(geometryFilters);
  const inclusionQuery = convertPointInclusionFilters(pointFilter);
  const exclusionQuery = convertPointExclusionFilters(pointFilter);
  const columnExpressions: Expression[] = [];
  columnExpressions.push(...convertColumnFilters(columnFilters));
  columnExpressions.push(...convertLayerFilters(layerFilters));
  const preFilterExpressions = convertColumnFilters(preFilters);

  /* The current filter schema:
   *                  AND
   *                 /   \--preFilters
   *                OR
   *              /    \
   *     inclusions    AND---------------
   *                  /   \               \
   *         --------OR   NOT             columnFilters---
   *       /        / \     \                  /  \       \
   *    polygon1 box1 box2  exclusions   layers   columns buffers
   *                                     /  \
   *                               buffer   columns
   *
   * Explanation:
   * There are several things applying filteres here: user selections,
   * layer filters, and pre filtering.  Beginning with selection we have:
   *
   *                OR
   *              /    \
   *     inclusions    AND
   *                  /   \
   *         --------OR   NOT
   *       /        / \     \
   *    polygon1 box1 box2  exclusions
   *
   * Here we allow the user to select areas of the map using boxes, polygons and point selections
   * (the inclusions and exclusions). Any of these can be additive if the user holds Shift while
   * making the selection. These are AND'ed with the layer/column filters:
   *
   *                   AND---------------
   *                                      \
   *                                      columnFilters---
   *                                           /  \       \
   *                                     layers   columns buffers
   *                                     /  \
   *                               buffer   columns
   *
   *  Here we allow the user to filter by the active layer's columns/buffers.  They can also
   *  join with another layer and create buffers and column filters on the joined layer:
   *
   *                                          /
   *                                     layers
   *                                     /  \
   *                               buffer   columns
   *
   *  Note that inclusions are OR'ed with all of these filters to allow the user to
   *  specifically include certain geometries regardless of the other filters applied.  However,
   *  the other selections (box and polygon) are AND'ed with the column filters such that the user
   *  can make column filter, i.e. population density, and make a box on the map to select only a
   *  portion of the map that satisfies the column filter.
   *
   *  Finally we have the preFilters which was added to allow us to apply specific column filters
   *  for custom requests that we have.  The use case here is when we want to show the user certain
   *  stats from their selection, i.e. showing stats for only the painted geometries in the current
   *  selection.  This functionality may be expanded later as we need it.
   */

  const userFilterExpressions = getUserFilters(
    columnExpressions,
    geometryQuery,
    inclusionQuery,
    exclusionQuery,
  );

  const expressions = applyPreFilters(
    preFilterExpressions,
    userFilterExpressions,
  );

  return optimizeToplevelExpressions(expressions);
}

function getUserFilters(
  columnExpressions: Expression[],
  geometryQuery: Expression,
  inclusionQuery: Expression,
  exclusionQuery: Expression,
): Expression[] {
  const andExpressions = [...columnExpressions, geometryQuery, exclusionQuery];

  const andQuery: ExpressionAnd = {
    fn: 'and',
    expressions: andExpressions,
  };

  return [
    {
      fn: 'or',
      expressions: [inclusionQuery, andQuery],
    },
  ];
}

function applyPreFilters(
  preFilterExpressions: Expression[],
  otherFilterExpressions: Expression[],
) {
  const filterQuery: ExpressionAnd[] = [
    {
      fn: 'and',
      expressions: [...preFilterExpressions, ...otherFilterExpressions],
    },
  ];

  return filterQuery;
}

function convertGeometryFilters(geometryFilters: GeometryFilterSpec) {
  const geometryQuery: ExpressionOr = {
    fn: 'or',
    expressions: [],
  };

  if ('bbox' in geometryFilters) {
    geometryFilters.bbox.forEach(bboxGeometry => {
      const { nw, se } = bboxGeometry;
      const w = roundLngLat(nw.lng);
      const s = roundLngLat(se.lat);
      const e = roundLngLat(se.lng);
      const n = roundLngLat(nw.lat);
      const bbox: ExpressionBoundingBox = {
        fn: 'bbox',
        bounds: [w, s, e, n],
      };
      geometryQuery.expressions.push({
        fn: 'intersects',
        left_geo: bbox,
      });
    });
  }

  if ('polygon' in geometryFilters) {
    geometryFilters.polygon.forEach(polygonGeometry => {
      const intersect: ExpressionIntersects = {
        fn: 'intersects',
        left_geo: {
          fn: 'geometry',
          geometry: makePolygonGeometry(polygonGeometry),
        },
      };
      geometryQuery.expressions.push(intersect);
    });
  }

  return geometryQuery;
}

/**
 * Convert lnglat polygons to a GeoJSON Geometry that can be used for spatial
 * intersection. Note that a polygon with effectively zero area are illegal so:
 * 1) all points along a straight line are just converted to a LineString
 * 2) all identical poitns are converted to a Point
 *
 * @param polygonGeometry C
 */
function makePolygonGeometry(
  polygonGeometry: PolygonGeometry,
): Point | LineString | Polygon {
  const roundedGeometry = polygonGeometry.map(
    ([lng, lat]): [number, number] => [roundLngLat(lng), roundLngLat(lat)],
  );

  const roundedPolygon = polygon([roundedGeometry]).geometry;
  if (area(roundedPolygon) < MINIMUM_AREA_SQUARE_METERS) {
    const allLngMatch = roundedGeometry.every(
      ([lng, lat]) => lng === roundedGeometry[0][0],
    );
    const allLatMatch = roundedGeometry.every(
      ([lng, lat]) => lat === roundedGeometry[0][1],
    );
    if (allLngMatch && allLatMatch) {
      return point(roundedGeometry[0]).geometry;
    }
    // cannot have a polygon with zero area, but a line string is fine
    return lineString(roundedGeometry).geometry;
  }
  return roundedPolygon;
}

function convertPointInclusionFilters(
  pointFilter: PointFilterSpec,
): Expression {
  // pointIds to add (OR'ed) to filter
  const inclusions = _(pointFilter)
    .pickBy() // truthy-only
    .keys()
    .sortBy() // consistently ordered, for caching
    .value();

  if (_.isEmpty(inclusions)) {
    return {} as ExpressionIn;
  }

  const inclusionQuery: ExpressionIn = {
    fn: 'in',
    value: {
      fn: 'column',
      key: GEOMETRY_KEY,
    },
    one_of: _.sortBy(inclusions) as unknown[] as object[],
  };

  return inclusionQuery;
}

function convertPointExclusionFilters(
  pointFilter: PointFilterSpec,
): Expression {
  // pointIds to subtract from filter
  const exclusions = _(pointFilter)
    .omitBy(t => !!t) // truthy-only
    .keys()
    .sortBy() // consistently ordered, for caching
    .value();

  if (_.isEmpty(exclusions)) {
    return {} as Expression;
  }

  const exclusionQuery: ExpressionNot = {
    fn: 'not',
    expression: {
      fn: 'in',
      value: {
        fn: 'column',
        key: GEOMETRY_KEY,
      },
      one_of: _.sortBy(exclusions),
    },
  };

  return exclusionQuery;
}

function convertColumnFilters(
  columnFilters: Record<string, ColumnFilter>,
): Expression[] {
  const expressions = [];
  const columnKeys = Object.keys(columnFilters).sort();
  columnKeys.forEach(columnKey => {
    const filter = columnFilters[columnKey];

    const { columnMetatype } = filter;

    switch (filter.columnMetatype) {
      case LayerColumn.MetatypeEnum.Categorical: {
        const { filterValue } = filter;
        // Don't generate filters for zero selection
        if (!filterValue?.length) {
          break;
        }
        // Values are stored as the keys in filterValue.
        const stringValues = _.sortBy(filterValue).filter(
          value => value !== null && value !== undefined,
        );
        const values = parseFilterValues(stringValues, filter.type);
        const inExpression: ExpressionIn<ExpressionColumn> = values.length
          ? {
              fn: 'in',
              value: {
                fn: 'column',
                key: columnKey,
              },
              one_of: _.sortBy(values) as any[],
            }
          : null;

        if (
          filter?.includeNull ||
          filterValue.includes(null) ||
          filterValue.includes(undefined)
        ) {
          const includeNullExpression: ExpressionIsNull<ExpressionColumn> = {
            fn: 'is_null',
            expression: { fn: 'column', key: columnKey },
          };
          if (inExpression) {
            const wrapper: ExpressionOr = {
              fn: 'or',
              expressions: [inExpression, includeNullExpression],
            };
            expressions.push(wrapper);
          } else {
            expressions.push(includeNullExpression);
          }
        } else {
          expressions.push(inExpression);
        }
        break;
      }

      case LayerColumn.MetatypeEnum.Numeric: {
        const { filterValue } = filter;
        const numericExpressions = [];
        // filterValue has min/max
        if ('min' in filterValue) {
          const { minInclusive } = filterValue;
          numericExpressions.push({
            fn: minInclusive ? '>=' : '>',
            left: {
              fn: 'column',
              key: columnKey,
            },
            right: filterValue.min,
          });
        }

        if ('max' in filterValue) {
          const { maxInclusive } = filterValue;
          numericExpressions.push({
            fn: maxInclusive ? '<=' : '<',
            left: {
              fn: 'column',
              key: columnKey,
            },
            right: filterValue.max,
          });
        }

        if (filterValue.includeNull) {
          const includeNullExpression: ExpressionIsNull<ExpressionColumn> = {
            fn: 'is_null',
            expression: { fn: 'column', key: columnKey },
          };

          if (numericExpressions.length) {
            const andExpression: ExpressionAnd = {
              fn: 'and',
              expressions: numericExpressions,
            };
            const orExpression: ExpressionOr = {
              fn: 'or',
              expressions: [andExpression, includeNullExpression],
            };
            expressions.push(orExpression);
          } else {
            expressions.push(includeNullExpression);
          }
        } else {
          expressions.push(...numericExpressions);
        }
        break;
      }

      case 'manual': {
        const { fn, filterValue } = filter;
        if (fn) {
          switch (fn) {
            // Inclusively filter by "like" expressions using "or"
            case 'like': {
              const { value, one_of: filterList } = filterValue;
              if (filterList?.length) {
                const baseExpression = {
                  fn: 'or',
                  expressions: filterList.map(pattern => ({
                    fn,
                    pattern,
                    value,
                  })),
                };
                expressions.push(baseExpression);
              }
              break;
            }
            default:
              expressions.push({
                fn,
                ...filterValue,
              });
              break;
          }
        }
        break;
      }

      default:
        warning(
          false,
          `Unknown metatype "${columnMetatype}" for "${columnKey}"`,
        );
    }
  });
  return expressions;
}

function parseFilterValues(values: string[], type: LayerColumn.TypeEnum) {
  switch (type) {
    case LayerColumn.TypeEnum.String:
    case LayerColumn.TypeEnum.Bytes:
      return values;
    case LayerColumn.TypeEnum.Bigint:
    case LayerColumn.TypeEnum.Int:
      return values.map(value => parseInt(value, 10));
    case LayerColumn.TypeEnum.Float:
      return values.map(value => parseFloat(value));
    case LayerColumn.TypeEnum.Boolean:
      return values.map(value => getBooleanValue(value));
    default:
      assertNever(type);
  }
}

function convertLayerFilters(layerFilters: LayerFilters): Expression[] {
  const layerKeys = Object.keys(layerFilters).sort();
  return layerKeys.map(layerId => {
    const layerFilter: Partial<FilterSpec> = layerFilters[layerId];
    const { version, buffer, joinMethod, ...filter } = layerFilter;

    const expressions = convertFiltersToExpressions(filter);
    let filterQuery: Expression;
    if (expressions) {
      if (expressions.length > 1) {
        filterQuery = {
          fn: 'and',
          expressions,
        };
      } else {
        filterQuery = expressions[0];
      }
    }
    const result: Expression = {
      fn: 'layer_intersects',
      layer: layerId,
      version,
    };
    if (filterQuery) {
      result.filter = filterQuery;
    }
    if (buffer) {
      result.buffer_distance = buffer.distance;
    }
    if (joinMethod === 'not_layer_intersects') {
      const notIntersectsResult: Expression = {
        fn: 'and',
        expressions: [{ fn: 'not', expression: result }],
      };
      return notIntersectsResult;
    }
    return result;
  });
}

/**
 *
 */
function optimizeToplevelExpressions(expressions: Expression[]): Expression[] {
  // first combine all expressions into a single 'and'
  const optimized = optimizeExpressions([
    {
      fn: 'and',
      expressions,
    },
  ]);
  if (optimized.length === 1 && optimized[0].fn === 'and') {
    return optimized[0].expressions as Expression[];
  }
  return optimized;
}

/**
 * Optimize an array of expressions. Note that this will filter out any
 * expressions that resolve to nothing
 */
export function optimizeExpressions(expressions: Expression[]): Expression[] {
  return expressions
    .map(expression => optimizeExpression(expression))
    .filter(expression => !_.isEmpty(expression));
}

/**
 * Optimize an expression. If the expression will ultimately have no effect,
 * returns null. For example, `{ fn: 'and', expressions: [] }` resolves to null.
 */
export function optimizeExpression(expression: Expression): Expression {
  if (expression.fn === 'and' || expression.fn === 'or') {
    const subExpressions = optimizeExpressions(
      expression.expressions as Expression[],
    );
    if (!subExpressions.length) {
      return null;
    }
    if (subExpressions.length === 1) {
      return subExpressions[0];
    }

    const exactSubExpressions = subExpressions.filter(
      subExpression => subExpression.fn === expression.fn,
    ) as typeof expression[];
    const otherSubExpressions = subExpressions.filter(
      subExpression => subExpression.fn !== expression.fn,
    );

    const hoistedExpressions = _.flatten(
      exactSubExpressions.map(
        exactSubExpression => exactSubExpression.expressions,
      ),
    );
    return {
      ...expression,
      expressions: [...hoistedExpressions, ...otherSubExpressions],
    };
  }
  return expression;
}

/**
 * Get a full search key from a layer. Use this if you don't actually
 * care about the server query, but do need to store something by
 * search key.
 */
export function getStatsSearchKey(layerId: LayerId, params: StatsParams) {
  const { key } = getStatsApiParams(layerId, params);
  return key;
}

/**
 * Get a full search key from a layer. Use this if you don't actually
 * care about the server query, but do need to store something by
 * search key.
 */
export function getLayerDataSearchKey(
  layerId: LayerId,
  params: LayerDataParams = {},
) {
  const { key } = getLayerDataApiParams(layerId, params);
  return key;
}

/**
 * Utility function to determine if a filter object is empty or
 * not. This function has special knowledge of how filter objects are
 * constructed, so it should always be used rather than inspecting
 * individual filter fields.
 */
export function filtersAreEmpty(filters: Partial<FilterSpec>) {
  if (!filters) {
    return true;
  }
  const {
    columns: columnFilters = {},
    pointIds: pointFilter = {},
    geometries: geometryFilter = {},
    layers: layerFilters = {},
  } = filters;

  return (
    hasEmptyColumnFilters(columnFilters) &&
    isEmptyGeometryFilter(geometryFilter) &&
    isEmptyPointFilter(pointFilter) &&
    isEmptyLayerFilters(layerFilters)
  );
}

export function hasEmptyColumnFilters(
  columnFilters: Record<ColumnKey, ColumnFilter>,
) {
  if (!columnFilters) {
    return true;
  }
  const nonEmptyColumnFilters = Object.keys(columnFilters)
    .map(columnKey => !isEmptyColumnFilter(columnFilters[columnKey], columnKey))
    .filter(_.identity);
  const emptyColumnFilters = nonEmptyColumnFilters.length === 0;
  return emptyColumnFilters;
}

export function isEmptyColumnFilter(
  columnFilter: ColumnFilter,
  columnKey: ColumnKey,
) {
  if (!columnFilter) {
    return true;
  }

  const { columnMetatype } = columnFilter;

  switch (columnFilter.columnMetatype) {
    case Metatype.NUMERIC:
      return isEmptyNumericFilter(columnFilter.filterValue);

    case Metatype.CATEGORICAL:
      return isEmptyCategoricalFilter(columnFilter.filterValue);

    case 'manual':
      return false;

    default:
      warning(false, `Unknown metatype "${columnMetatype}" for "${columnKey}"`);
      // we don't support filtering on any other types, so we assume the filter is just empty.
      return true;
  }
}

function isEmptyPointFilter(pointFilter: PointFilterSpec) {
  return !Object.keys(pointFilter).length;
}

function isEmptyGeometryFilter(geometryFilter: GeometryFilterSpec) {
  if ('bbox' in geometryFilter) {
    if (geometryFilter.bbox.some(box => !_.isEmpty(box))) {
      return false;
    }
  }
  if ('polygon' in geometryFilter) {
    if (geometryFilter.polygon?.some(poly => !_.isEmpty(poly))) {
      return false;
    }
  }
  return true;
}

function isEmptyNumericFilter(columnFilter: NumericFilterValue) {
  return !columnFilter || !('max' in columnFilter || 'min' in columnFilter);
}

function isEmptyCategoricalFilter(columnFilter: CategoricalFilterValue) {
  return !columnFilter?.length;
}

function isEmptyLayerFilters(layerFilters) {
  return _.isEmpty(layerFilters);
}
const FILTER_COMPRESSION_THRESHOLD = 1024;

interface FiltersClause {
  filters: string;
}

/**
 * Encode filters for calling the API
 * @param filters
 */
export function getFilterClause(
  filters: Expression[],
  threshold = FILTER_COMPRESSION_THRESHOLD,
): FiltersClause {
  if (!filters || !filters.length) {
    return null;
  }
  const filterJson = JSON.stringify(filters);
  if (filterJson.length > threshold) {
    const filterCompressed = btoa(deflate(filterJson, { to: 'string' }))
      .replace(/\+/g, '-')
      .replace(/\//g, '_');
    return { filters: filterCompressed };
  }
  return { filters: filterJson };
}

export function getLayerDataApiParams<T extends LayerDataParams>(
  layerId: LayerId,
  params: T,
): { key: string; apiParams: Omit<T, 'filters'> & { filters: Expression[] } } {
  const { filters, version } = params;
  warning(version, `Missing version while creating query for ${layerId}`);
  const expressions = convertFiltersToExpressions(filters);
  const apiParams = getCanonicalParameters({
    ...params,
    filters: expressions,
  });
  const key = makeReduxDataKey(layerId, apiParams);
  return { apiParams, key };
}

export function getStatsApiParams(
  layerId: LayerId,
  params: StatsParams,
): {
  key: string;
  apiParams: Omit<StatsParams, 'filters'> & { filters: Expression[] };
} {
  const { filters, version, columns } = params;
  warning(version, `Missing version while creating query for ${layerId}`);
  const expressions = convertFiltersToExpressions(filters);
  const apiParams = getCanonicalParameters({
    ...params,
    columns: _.sortBy(columns),
    filters: expressions,
  });
  const key = makeReduxDataKey(layerId, apiParams);
  return { apiParams, key };
}

/**
 * Create a categorical filter for a specific value.
 *
 * @param columnKey The key of the column like built_form_key or route_id
 * @param value The value of the column you're looking for, like 'bt__foo'
 * @param allowNulls If we should also set the `allowNulls` flag on the filter.
 */
export function makeCategoricalFilter(
  columnKey: ColumnKey,
  value: string,
  allowNulls = false,
  type: LayerColumn.TypeEnum = LayerColumn.TypeEnum.String,
): Partial<FilterSpec> {
  if (value === null) {
    if (!allowNulls) {
      warning(false, 'Trying to generate a filter for a null value');
      return null;
    }
    return {
      columns: {
        [columnKey]: {
          columnMetatype: Metatype.CATEGORICAL,
          type,
          filterValue: [],
          includeNull: true,
        },
      },
    };
  }
  return {
    columns: {
      [columnKey]: {
        columnMetatype: Metatype.CATEGORICAL,
        type,
        filterValue: [value],
      },
    },
  };
}

/**
 * Merge two `FilterSpec`s to produce a filter that would be the intersection of
 * these two filters, i.e. with the AND of both filters applied.
 */
export function mergeFilters(
  filter1: Partial<FilterSpec>,
  filter2: Partial<FilterSpec>,
): Partial<FilterSpec> {
  if (!filter1) {
    return filter2;
  }
  if (!filter2) {
    return filter1;
  }
  const filter1mergeable = isMergeable(filter1);
  const filter2mergeable = isMergeable(filter2);
  const versionsCompatible = filter1.version === filter2.version;
  warning(filter1mergeable, 'Cannot merge filters, first filter not mergeable');
  warning(
    filter2mergeable,
    'Cannot merge filters, second filter not mergeable',
  );
  warning(
    versionsCompatible,
    `Cannot merge filters, versions not compatible ${filter1.version} !== ${filter2.version}`,
  );
  return {
    geometries: {
      ...filter1?.geometries,
      ...filter2?.geometries,
      polygon: [
        ...(filter1?.geometries?.polygon ?? []),
        ...(filter2?.geometries?.polygon ?? []),
      ],
      bbox: [
        ...(filter1?.geometries?.bbox ?? []),
        ...(filter2?.geometries?.bbox ?? []),
      ],
    },
    columns: mergeColumnFilters(filter1.columns, filter2.columns),
    layers: {
      ...filter1.layers,
      ...filter2.layers,
    },
    pointIds: {
      ...filter1.pointIds,
      ...filter2.pointIds,
    },
    preFilters: {
      ...filter1.preFilters,
      ...filter2.preFilters,
    },
    version: filter1.version ?? filter2.version,
  };
}

function mergeColumnFilters<T>(
  filter1Columns: Record<ColumnKey, ColumnFilter<T>>,
  filter2Columns: Record<ColumnKey, ColumnFilter<T>>,
): Record<ColumnKey, ColumnFilter<T>> {
  return {
    ...filter1Columns,
    ...filter2Columns,
  };
}
/**
 * Determines if a filter can be merged with other filters.
 *
 * the `buffer` and `filter` properties are somewhat ambiguous when it comes to
 * merging filters, because they aren't really about filtering, they're about
 * saving buffered layers, or querying columns for stats... so for now if any of
 * these are set, we just say the filter isn't mergeable.
 * @param filter
 */
function isMergeable(filter: Partial<FilterSpec>) {
  return !filter.buffer && !filter.disabled;
}
