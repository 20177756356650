import { GeoJsonGeometryTypes } from 'geojson';
import _, { isNumber, partialRight, uniqBy } from 'lodash';
import { LayerMetadata, LayerStats } from 'uf-api';

import { assertNever } from 'uf/base/never';
import { isBuiltFormKey } from 'uf/builtforms';
import { ColumnKey, LayerId } from 'uf/layers';
import { getUfGeometryType, isPaintedLayer } from 'uf/layers/helpers';
import {
  getColumnMetadata,
  isCategoricalColumn,
  isNumericColumn,
} from 'uf/layers/metadata';
import {
  NULL_CATEGORY,
  NullCategory,
  isNullCategory,
} from 'uf/layers/nullStats';
import { findColumnKeyStats, getCategoricalStatsValues } from 'uf/layers/stats';
import {
  CuratedColumnSymbology,
  DEFAULT_CATEGORICAL_DISPLAY_HINTS,
  DEFAULT_NUMERIC_DISPLAY_HINTS,
  DataDrivenPaintProperty,
  DataDrivenStop,
  LayerColumnSymbology,
  PaintProperty,
  PaintPropertyKey,
  PaintPropertyType,
  SymbologyTypes,
  isFillSymbology,
  isNumericSymbology,
} from 'uf/symbology';

import { BuiltFormStop } from 'uf/symbology/builtforms';
import { makeStyleSymbologyId } from 'uf/symbology/spec/id';
import { getPaintProperty, isDataDriven } from 'uf/symbology/spec/paint';
import { createMissingCuratedSymbologies } from 'uf/symbology/styles/dynamic/createMissingCuratedSymbologies';
import { DivideByColumnKey } from './divideByColumn';

/**
 *
 * Takes "all three" symbologies and removes any that don't match the known ufGeometryType.
 *
 * Background:
 * When we didn't know the shapes in a layer we generated FILL, LINE, and CIRCLE symbologies to be
 * sure that something would show on the map. This had limitations though for the symbology editor.
 * Now, all layer metadatas have a uf_geometry_type display hint that can better inform how we
 * generate symbologies.
 */
export function removeUnusedSymbologies(
  symbologies: LayerColumnSymbology[],
  ufGeometryType: GeoJsonGeometryTypes,
): LayerColumnSymbology[] {
  return symbologies.filter(symbology => {
    switch (ufGeometryType) {
      case 'MultiPolygon':
      case 'Polygon':
        return ['fill', 'line'].includes(symbology.type);

      case 'MultiLineString':
      case 'LineString':
        return ['line', 'symbol'].includes(symbology.type);

      case 'MultiPoint':
      case 'Point':
        return symbology.type === 'circle';

      case 'GeometryCollection':
        return false;

      default:
        return assertNever(ufGeometryType);
    }
  });
}

/**
 * Removes all stop label properties from any data-driven symbology property.
 *
 * Background:
 * For numeric symbologies, we should never include a `label` property in the symbology stops.
 * This wasn't too big a deal before the days of custom bins, however now that we have custom bins
 * the label and the value in the stop are prone to drifting out-of-sync. To remedy this, we should
 * let the component figure out how to label the bin and leave symbology out of it.
 */
export function removeNumericStopLabels(
  symbologies: LayerColumnSymbology[],
): LayerColumnSymbology[] {
  return symbologies.map(symbology => {
    // TODO: Fix LayerColumnSymbology type definition so that we know which SymbologyPaintProperty
    // to cast this as
    const newPaint = {};

    if (!isNumericSymbology(symbology)) {
      return symbology;
    }

    Object.entries(symbology.paint).forEach(
      ([paintPropertyKey, paintProperty]) => {
        if (!isDataDriven(paintProperty)) {
          newPaint[paintPropertyKey] = paintProperty;
          return;
        }

        newPaint[paintPropertyKey] = {
          ...paintProperty,
          stops: paintProperty.stops.map(stop => _.omit(stop, 'label')),
        };
      },
    );

    return {
      ...symbology,
      paint: newPaint,
    };
  });
}

/**
 * Overwrites whatever ids are stored in the symbology with ids from the layer.
 *
 * Background:
 * We switched our canvas layer types from `painted` to `canvas` and copied all the
 * saved symbologies on the backend to new user_data keys. However, the symbology ids have layer ids
 * baked into them, so we need to update them to include the new layer ids instead.
 */
export function insertSymbologyIds(
  symbologies: CuratedColumnSymbology[],
  layerId: LayerId,
  columnKey: ColumnKey,
  divideByColumn?: DivideByColumnKey,
): LayerColumnSymbology[];

export function insertSymbologyIds(
  symbologies: LayerColumnSymbology[],
  layerId: LayerId,
  columnKey: ColumnKey,
  divideByColumn: DivideByColumnKey,
): LayerColumnSymbology[] {
  return symbologies.map(symbology => ({
    ...symbology,
    id: makeStyleSymbologyId(
      layerId,
      columnKey,
      symbology.type,
      divideByColumn,
    ),
  }));
}

/**
 * Overwrites whatever property (column key) is stored in the symbology with column key from the layer.
 * This allows us to design a single curated style and share the style key across column
 * of the dataset, and across layers. It is independant of layer symbology type as well.
 *
 * Background:
 * The Covid-19 layer is a time series layer, where each column represents one calendar day.
 * The columns all have the same symbology_key hint so they can be styled the same, but since data-driven
 * style properties require an explicit columnKey, we need replace whatever was curated at the time
 * with the actual column being mapped.
 */
export function insertColumnKeys(
  symbologies: LayerColumnSymbology[],
  columnKey: ColumnKey,
): LayerColumnSymbology[] {
  return symbologies.map(symbology => ({
    ...symbology,
    paint: replaceStyleSymbologyColumnKey(
      columnKey,
      symbology.paint,
      symbology.type,
    ),
  }));
}

/**
 * Overwrites the current color stops with the given built form stops.
 *
 * Background:
 * Painting enables user to add or remove built forms from the canvas, so we need to
 * keep the symbology consistent with what's on the ground.
 */
export function setBuiltFormStops(
  symbologies: LayerColumnSymbology[],
  builtFormStops: BuiltFormStop[],
): LayerColumnSymbology[] {
  return symbologies.map(symbology => {
    if (!isFillSymbology(symbology)) {
      return symbology;
    }

    const paintColor = getPaintProperty(symbology, 'color') as PaintProperty<
      DataDrivenStop,
      string
    >;

    const stops = filterUnusedStops(builtFormStops);
    return {
      ...symbology,
      paint: {
        ...symbology.paint,
        [`${symbology.type}-color`]: { ...paintColor, stops },
      },
    };
  });
}

/**
 * Converts hardcoded symbologies to the common LayerColumnSymbology format used by the rest of
 * uf/symbology.
 *
 * Background:
 * When folks first began hardcoding symbologies, they wanted to avoid having to
 * manually declare every property in the LayerColumnSymbologyOld interface. This mostly resulted
 * in:
 *   1. Missing display hints, hence the CuratedColumnSymbology type w/ an optional display_hints.
 *   2. Missing symbologies, ie: Polygon-based layers may have a missing LINE symbology.
 */
export function convertToLayerColumnSymbologyOld(
  layerId: LayerId,
  columnKey: ColumnKey,
  symbologies: CuratedColumnSymbology[],
  ufGeometryType: GeoJsonGeometryTypes,
): LayerColumnSymbology[] {
  const symbologiesWithDisplayHints = symbologies.map(symbology => {
    if (isNumericSymbology(symbology)) {
      return addMissingDisplayHints(symbology, DEFAULT_NUMERIC_DISPLAY_HINTS);
    }
    return addMissingDisplayHints(symbology, DEFAULT_CATEGORICAL_DISPLAY_HINTS);
  });

  const symbologiesWithIdsAndDisplayHints = insertSymbologyIds(
    symbologiesWithDisplayHints,
    layerId,
    columnKey,
    false,
  );

  const finalSymbologies = addMissingSymbologies(
    layerId,
    columnKey,
    symbologiesWithIdsAndDisplayHints,
    ufGeometryType,
  );
  return finalSymbologies;
}

/**
 * Enforce that the symbology matches the layer's stats for min/max.
 *
 * Background:
 * Curated symbology is created in a special, national canvas project and will
 * likely have min/max ranges that exceed the project-scoped copy of the layer.
 *
 * Also, User symbologies that were saved prior to introducing this cleaning
 * step may contain unclamped stops, so we'll want to handle that case too.
 */
export function clampSymbologiesToLayerMinMax(
  symbologies: LayerColumnSymbology[],
  min: number,
  max: number,
): LayerColumnSymbology[] {
  return symbologies.map(symbology => {
    const { paint } = symbology;
    const cleanedPaintProperties = _.mapValues(paint, (paintProperty: any) => {
      if (!isDataDriven(paintProperty)) {
        return paintProperty;
      }
      const { stops } = paintProperty;

      return {
        ...paintProperty,
        stops: clampStops(stops as DataDrivenStop[], min, max),
      };
    });

    return {
      ...symbology,
      paint: cleanedPaintProperties,
    };
  });
}

/**
 * Enforce that categorical symbology does not contain unused categories.
 *
 * Background:
 * When a user saves a filter layer, we copy all the styles from the parent to
 * the new layer. Depending on the filter that was applied, we need to be sure
 * that we don't symbolize categories that don't exist in the filtered layer.
 *
 * This is the categorical equivalent of `clampSymbologiesToLayerMinMax`
 *
 */
export function pruneUnusedCategories<
  T extends string | boolean | NullCategory,
>(
  symbologies: LayerColumnSymbology[],
  usedCategories: T[],
): LayerColumnSymbology[] {
  return symbologies.map(symbology => {
    const { paint } = symbology;
    const cleanedPaintProperties = _.mapValues(
      paint,
      (paintProperty: PaintPropertyType) => {
        if (!isDataDriven(paintProperty)) {
          return paintProperty;
        }
        const { stops } = paintProperty as DataDrivenPaintProperty<any>;

        const usedStops = stops.filter(
          ({ value }) =>
            usedCategories.findIndex(category => _.isEqual(category, value)) !==
            -1,
        );

        const uniqueStops = dedupeStops(usedStops);

        // now uniquely
        return {
          ...paintProperty,
          stops: uniqueStops,
        };
      },
    ) as PaintPropertyType;

    return {
      ...symbology,
      paint: cleanedPaintProperties,
    };
  });
}

// dedupeStops handles `NULL_CATRGORY`s
export const dedupeStops = partialRight(uniqBy, ({ value }) =>
  isNullCategory(value) ? NULL_CATEGORY : value,
);

/**
 * adds categorical stops from extraSymbologies to symbologies that aren't already accounted for.
 * stops are assumed to have a 'value' prop to identify them by.
 * @param newSymbologies the column symbology to add stops to
 * @param extraSymbologies the column symbology to take stops from if they aren't in newSymbologies.
 */
export function unionCategories(
  newSymbologies: LayerColumnSymbology[],
  extraSymbologies: LayerColumnSymbology[],
): LayerColumnSymbology[] {
  if (!extraSymbologies) {
    return newSymbologies;
  }
  return newSymbologies.map(symbology => {
    const { paint } = symbology;
    // find the first matching symbology by type
    const extraSymbologiesByType = extraSymbologies.filter(
      ({ type }) => type === symbology.type,
    );
    const extraSymbology = extraSymbologiesByType[0];
    if (!extraSymbology) {
      return symbology;
    }

    const combinedProperties = _.mapValues(
      paint,
      (paintProperty: any, paintPropertyKey: PaintPropertyKey) => {
        if (!isDataDriven(paintProperty)) {
          return paintProperty;
        }
        const { stops: newStops } =
          paintProperty as DataDrivenPaintProperty<any>;

        const extraPaintProperty: DataDrivenPaintProperty<any> =
          extraSymbology?.paint[paintPropertyKey];

        if (!isDataDriven(extraPaintProperty)) {
          return paintProperty;
        }

        const { stops: extraStops } = extraPaintProperty;

        return {
          ...paintProperty,
          stops: dedupeStops([...newStops, ...extraStops]),
        };
      },
    ) as PaintPropertyType;
    return {
      ...symbology,
      paint: combinedProperties,
    };
  });
}

export function addMissingSymbologies(
  layerId: LayerId,
  columnKey: ColumnKey,
  symbologies: LayerColumnSymbology[],
  ufGeometryType: GeoJsonGeometryTypes,
): LayerColumnSymbology[] {
  const finalSymbologies = [...symbologies];

  if (isMissingSymbologies(finalSymbologies, ufGeometryType)) {
    const missingSymbologies = createMissingCuratedSymbologies(
      finalSymbologies,
      layerId,
      columnKey,
      ufGeometryType,
    );

    finalSymbologies.push(...missingSymbologies);
  }

  return finalSymbologies;
}

function isMissingSymbologies(
  symbologies: LayerColumnSymbology[],
  ufGeometryType: GeoJsonGeometryTypes,
) {
  const hasFill = symbologies.find(({ type }) => type === SymbologyTypes.FILL);
  const hasLine = symbologies.find(({ type }) => type === SymbologyTypes.LINE);
  const hasCircle = symbologies.find(
    ({ type }) => type === SymbologyTypes.CIRCLE,
  );

  switch (ufGeometryType) {
    case 'Polygon':
    case 'MultiPolygon':
      return !hasFill || !hasLine;

    case 'LineString':
    case 'MultiLineString':
      return !hasLine;

    case 'Point':
    case 'MultiPoint':
      return !hasCircle;

    case 'GeometryCollection':
      return false;

    default:
      assertNever(ufGeometryType);
  }
}

function addMissingDisplayHints(
  symbology: CuratedColumnSymbology,
  displayHints: any,
): CuratedColumnSymbology {
  return {
    ...symbology,
    display_hints: {
      ...symbology.display_hints,
      ...displayHints,
    },
  };
}

export function clampStops(
  stops: DataDrivenStop[],
  // Without strict null checks, the undefined has no automatic checking.
  // This is to notify the developer that we may see undefined values
  minValue: number | undefined,
  maxValue: number | undefined,
): DataDrivenStop[] {
  /*
   * Curated stops might go beyond the max value, ie:
   *
   * Input:
   *   maxValue: 50
   *   stops: [
   *    { value: 0, color: 'red' },
   *    { value: 5, color: 'orange'},
   *    { value: 15, color: 'yellow' },
   *    { value: 20, color: 'green' },
   *    { value: 90, color: 'blue' },
   *  ]
   *
   * Output:
   *   stops: [
   *    { value: 0, color: 'red' },
   *    { value: 5, color: 'orange'},
   *    { value: 15, color: 'yellow' },
   *    { value: 20, color: 'green' },
   *  ]
   */
  // recycle nullStop OR create a one if minValue is null, otherwise minValue is used to provide a default first stop
  const nullStop =
    stops.find(stop => isNullCategory(stop.value)) ||
    (minValue === null
      ? {
          value: NULL_CATEGORY,
        }
      : null);

  let updatedStops: DataDrivenStop[] = stops
    .filter(stop => isNumber(stop.value))
    .filter(({ value }) => (value as number) <= maxValue);

  // If all the values were above the max, just return a single stop w/ the min
  if (!updatedStops.length) {
    if (isNumber(minValue)) {
      updatedStops = [{ ...stops[0], value: minValue }];
    }
    if (nullStop) {
      updatedStops = [nullStop, ...updatedStops];
    }
    return updatedStops;
  }

  /*
   * Curated stops might start below the min value, ie:
   *
   * Input:
   *   minValue: 16
   *   stops: [
   *    { value: 0, color: 'red' },
   *    { value: 5, color: 'orange'},
   *    { value: 15, color: 'yellow' },
   *    { value: 20, color: 'green' },
   *    { value: 90, color: 'blue' },
   *  ]
   *
   * Output:
   *   stops: [
   *    { value: 16, color: 'yellow' },
   *    { value: 20, color: 'green' },
   *    { value: 90, color: 'blue' },
   *  ]
   */
  if (isNumber(minValue)) {
    const indexOfLastStopLessThanMin = _.findLastIndex(
      updatedStops,
      ({ value }) => (value as number) < minValue,
    );

    if (indexOfLastStopLessThanMin > -1) {
      const updated = updatedStops
        // delete everything up to the last stop less than the min
        .slice(indexOfLastStopLessThanMin)
        // set the first stop as the min stop
        .map((stop, i) => (i === 0 ? { ...stop, value: minValue } : stop));

      if (nullStop) {
        return [nullStop, ...updated];
      }

      return updated;
    }
  }

  /*
   * Curated stops might start above the min value, ie:
   *
   * Input:
   *   minValue: 16
   *   stops: [
   *    { value: 50, color: 'red' },
   *    { value: 100, color: 'orange'},
   *    { value: 150, color: 'yellow' },
   *    { value: 200, color: 'green' },
   *    { value: 900, color: 'blue' },
   *  ]
   *
   * Output:
   *   stops: [
   *    { value: 16, color: 'red' },
   *    { value: 100, color: 'orange'},
   *    { value: 150, color: 'yellow' },
   *    { value: 200, color: 'green' },
   *    { value: 900, color: 'blue' },
   *  ]
   */
  const [firstStop, ...restStops] = updatedStops;
  if (isNumber(minValue) && (firstStop.value as number) > minValue) {
    const minStop = { ...firstStop, value: minValue };
    if (nullStop) {
      return [nullStop, minStop, ...restStops];
    }
    return [minStop, ...restStops];
  }

  if (nullStop) {
    return [nullStop, ...updatedStops];
  }
  return updatedStops;
}

function filterUnusedStops(stops: BuiltFormStop[] = []) {
  // It's okay to not specify `used` at all,
  // we'll still give you those stops.
  return stops.filter(({ used }) => used);
}

export function replaceStyleSymbologyColumnKey(
  columnKey: ColumnKey,
  symbologyPaints: PaintPropertyType,
  symbologyType: SymbologyTypes,
) {
  const updatedPaintPropertyType = _.mapValues(
    symbologyPaints,
    (paintProperty: any) => {
      if (isDataDriven(paintProperty)) {
        return {
          ...paintProperty,
          property: columnKey,
        };
      }
      return paintProperty;
    },
  );

  return updatedPaintPropertyType;
}

/**
 * 'Cleans' the symbology.  Every time we load symbology we need to make sure that it
 * is up to date with changes that were made to the map.  If a user paints, makes changes to
 * builtforms, or changes bins, we need to update and prune the symbology for that layer.
 */
export function cleanSymbology(
  columnKey: ColumnKey,
  divideByColumn: DivideByColumnKey,
  nextSymbology: LayerColumnSymbology[],
  baseSymbology: LayerColumnSymbology[],
  layerMetadata: LayerMetadata,
  layerStats: LayerStats,
  builtFormStops: BuiltFormStop[],
): LayerColumnSymbology[] {
  let cleanedSymbologies = nextSymbology;
  // Only keep symbologies that match the shape of the layer
  if (cleanedSymbologies.length === 3) {
    // What does it mean if symbology length is more than 3?
    const ufGeometryType = getUfGeometryType(layerMetadata);
    cleanedSymbologies = removeUnusedSymbologies(
      cleanedSymbologies,
      ufGeometryType,
    );
  }

  // Numeric cleaning
  const columnMetadata = getColumnMetadata(layerMetadata, columnKey);
  const columnStats = findColumnKeyStats(layerStats, columnKey);
  if (isNumericColumn(columnMetadata)) {
    // Numeric labels should only be generated at the component level
    cleanedSymbologies = removeNumericStopLabels(cleanedSymbologies);

    // Make sure the symbology fits the stats of the layer
    cleanedSymbologies = clampSymbologiesToLayerMinMax(
      cleanedSymbologies,
      columnStats.numeric.min,
      columnStats.numeric.max,
    );
  }

  // Categorical cleaning
  if (isCategoricalColumn(columnMetadata)) {
    const categories = getCategoricalStatsValues(columnStats);
    cleanedSymbologies = pruneUnusedCategories(cleanedSymbologies, categories);
    // keep categorical symbology in sync when stats change.  i.e. when a user paints with a
    // category that we have no user persisted value for, we make sure to include it here.
    cleanedSymbologies = unionCategories(cleanedSymbologies, baseSymbology);
  }

  // Sync built form stops with the most current built forms on painted layers only
  if (isPaintedLayer(layerMetadata) && isBuiltFormKey(columnKey)) {
    cleanedSymbologies = setBuiltFormStops(cleanedSymbologies, builtFormStops);
  }

  // Account for changes to layer ids, ie: `painted` -> `canvas`
  cleanedSymbologies = insertSymbologyIds(
    cleanedSymbologies,
    layerMetadata.full_path,
    columnKey,
    divideByColumn,
  );

  return cleanedSymbologies;
}
