import { Location } from 'history';
import { match, MatchHistoryArgs, MatchLocationArgs } from 'react-router';

export function asyncMatch(args: MatchLocationArgs | MatchHistoryArgs) {
  const { history, routes, location } = args;
  return new Promise<{
    redirectLocation: Location;
    renderProps: any;
  }>((resolve, reject) => {
    match(
      { history, routes, location },
      (error, redirectLocation, renderProps) => {
        if (error) {
          reject(error);
        } else {
          resolve({ redirectLocation, renderProps });
        }
      },
    );
  });
}
