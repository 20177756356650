import {
  CuratedColumnSymbology,
  CUSTOM_THEME,
  DEFAULT_OPACITY,
  InterpolationTypes,
  PaintColorTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

import MPORegionList from './mpo_regions';

const symbologies: Record<
  string,
  Record<string, CuratedColumnSymbology[]>
> = {};

MPORegionList.forEach(regionList => {
  symbologies[`/public/dataset/california_ag_production_${regionList}`] = {
    raster_val: [
      {
        type: SymbologyTypes.FILL,
        paint: {
          'fill-color': {
            property: 'raster_val',
            type: PaintColorTypes.CATEGORICAL,
            stops: [
              { value: 1, label: 'Less than 1,000', color: '#ffdc73' },
              { value: 2, label: '1,000 - 5,000', color: '#ffcf40' },
              { value: 3, label: '5,000 - 10,000', color: '#ffbf00' },
              { value: 4, label: '10,000 - 20,000', color: '#bf9b30' },
              { value: 5, label: 'Greater than 20,000', color: '#a67c00' },
            ],
          },
          'fill-opacity': DEFAULT_OPACITY,
        },
        display_hints: {
          scale: SymbologyScales.CATEGORICAL,
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          theme: CUSTOM_THEME,
        },
      },
    ],
  };
});

export default symbologies;
