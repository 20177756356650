/**
 * UrbanFootprint App API
 * API for serving UrbanFootprint Application
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Represents the parameters for a buffer operation.      If no SRID is specified, the system will try to find the appropriate UTM     SRID using the bounds of the layer being buffered. If calculating the bounds     of the layer is unfeasible due to the complexity of the layer, the US Equal     Area Projection (2163) will be used instead.
 */
export interface BufferOptions<> {
  /**
   * The length of the buffer.
   */
  buffer_distance: number;
  /**
   * The spatial reference id in which the buffer should be performed.
   */
  buffer_srid?: number;
  /**
   * The unit of measurement of the buffer distance.
   */
  buffer_units: BufferOptions.BufferUnitsEnum;
}
export namespace BufferOptions {
  export type BufferUnitsEnum = 'meter';
  export const BufferUnitsEnum = {
    Meter: 'meter',
  } as const;
}
