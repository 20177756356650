import _ from 'lodash';
import { createSelector } from 'reselect';
import { Api, GetLayerGeoKeysApiArg, LayerGeoData } from 'uf-api-rtk/store/Api';
import { EMPTY_ARRAY } from 'uf/base';
import { parseFullPath } from 'uf/base/dataset';
import {
  makeGetFromPropsSelector,
  makeGetterSelector,
  makeRTKGetterSelector,
} from 'uf/base/selector';
import { DataState } from 'uf/data/dataState';
import { LayerId } from 'uf/layers';
import {
  convertFiltersToExpressions,
  getFilterClause,
} from 'uf/layers/filters';
import { makeGetLayerVersion } from 'uf/layers/selectors/versions';
import { makeGetLayerFilters } from './filters';

export function makeGetLayerSelectionState() {
  return createSelector(
    makeRTKGetterSelector(Api.endpoints.getLayerGeoKeys.select),
    makeGetFromPropsSelector<LayerId, 'layerId'>('layerId'),
    makeGetFromPropsSelector<LayerId, 'parentLayerId'>('parentLayerId'),
    makeGetLayerVersion(),
    makeGetLayerFilters(),
    (getLayerGeoKeys, layerId, parentLayerId, version, filterSpec) => {
      const filters = convertFiltersToExpressions(filterSpec);
      const { namespace, type: layerType, key } = parseFullPath(layerId);
      const args: GetLayerGeoKeysApiArg = {
        namespace,
        layerType,
        key,
        version,
        ...getFilterClause(filters),
      };
      const data = getLayerGeoKeys(args);
      return {
        loading: !data,
        loaded: !!data,
        data,
      } as DataState<LayerGeoData>;
    },
  );
}

export function makeGetLayerSelectedKeys() {
  return createSelector(makeGetLayerSelectionState(), selectionState => {
    if (!selectionState) {
      return EMPTY_ARRAY;
    }
    return _.uniq(selectionState.data?.geo_keys);
  });
}

export function makeGetLayerSelectedKeysGetter() {
  return makeGetterSelector(makeGetLayerSelectedKeys());
}
