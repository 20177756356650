import colors from 'material-colors';

import {
  CuratedColumnSymbology,
  CUSTOM_THEME,
  DEFAULT_OPACITY,
  DistributionTypes,
  InterpolationTypes,
  PaintColorTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/public/dataset/california_census_rates': {
    hh_agg_inc_rate: [
      {
        type: SymbologyTypes.FILL,
        paint: {
          'fill-color': {
            property: 'hh_agg_inc_rate',
            type: PaintColorTypes.INTERVAL,
            stops: [
              { value: 0.1, label: '$0 to $10,000', color: colors.red[900] },
              {
                value: 10000,
                label: '$10,000 to $20,000',
                color: colors.deepOrange[400],
              },
              {
                value: 20000,
                label: '$20,000 to $30,000',
                color: colors.orange[300],
              },
              {
                value: 30000,
                label: '$30,000 to $40,000',
                color: colors.amber[200],
              },
              {
                value: 40000,
                label: '$40,000 to $50,000',
                color: colors.yellow[200],
              },
              {
                value: 50000,
                label: '$50,000 to $60,000',
                color: colors.lime[200],
              },
              {
                value: 60000,
                label: '$60,000 to $75,000',
                color: colors.lightGreen[200],
              },
              {
                value: 75000,
                label: '$75,000 to $100,000',
                color: colors.green[300],
              },
              {
                value: 100000,
                label: '$100,000 to $125,000',
                color: colors.lightBlue[200],
              },
              {
                value: 125000,
                label: '$125,000 to $150,000',
                color: colors.blue[200],
              },
              {
                value: 150000,
                label: '$150,000 to $200,000',
                color: colors.indigo[400],
              },
              {
                value: 200000,
                label: '$200,000 or More',
                color: colors.indigo[900],
              },
            ],
          },
          'fill-opacity': DEFAULT_OPACITY,
        },
        display_hints: {
          scale: SymbologyScales.NUMERIC,
          distribution: DistributionTypes.DEFAULT,
          interpolation: InterpolationTypes.INTERVAL,
          theme: CUSTOM_THEME,
        },
      },
    ],
  },
};

export default symbologies;
