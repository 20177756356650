import {
  mdiCursorDefault,
  mdiDraw,
  mdiHandRight,
  mdiInformationOutline,
  mdiSelectionDrag,
  mdiVectorPolyline,
} from '@mdi/js';
import { t } from 'ttag';

import { MapMode } from 'uf/map/mapmode';
import { getHotKeyText } from 'uf/ui/base/hotkeys';
import { ExploreMapHotKeys } from 'uf/ui/explore/ExploreMap/hotKeys';

interface MapButtonInfo {
  title: string;
  iconPath: string;
  modeKey: MapMode;
  hotKey?: string;
  getDisabled: (hasActiveLayerId: boolean) => boolean;
}
export const buttonsByKey: Record<MapMode, MapButtonInfo> = {
  [MapMode.NORMAL]: {
    title: t`Pan map`,
    iconPath: mdiHandRight,
    modeKey: MapMode.NORMAL,
    hotKey: getHotKeyText(ExploreMapHotKeys.MAP_PAN),
    getDisabled: hasActiveLayer => false,
  },
  [MapMode.BOX_SELECTION]: {
    title: t`Box selection`,
    iconPath: mdiSelectionDrag,
    modeKey: MapMode.BOX_SELECTION,
    hotKey: getHotKeyText(ExploreMapHotKeys.MAP_BOX_SELECT),
    getDisabled: hasActiveLayer => !hasActiveLayer,
  },
  [MapMode.POLYGON_SELECTION]: {
    title: t`Polygon selection`,
    iconPath: mdiVectorPolyline,
    modeKey: MapMode.POLYGON_SELECTION,
    hotKey: getHotKeyText(ExploreMapHotKeys.MAP_POLYGON_SELECT),
    getDisabled: hasActiveLayer => !hasActiveLayer,
  },
  [MapMode.POINT_SELECTION]: {
    title: t`Point selection`,
    iconPath: mdiCursorDefault,
    modeKey: MapMode.POINT_SELECTION,
    hotKey: getHotKeyText(ExploreMapHotKeys.MAP_POINT_SELECT),
    getDisabled: hasActiveLayer => !hasActiveLayer,
  },
  [MapMode.INSPECTION]: {
    title: t`Inspection`,
    iconPath: mdiInformationOutline,
    modeKey: MapMode.INSPECTION,
    hotKey: getHotKeyText(ExploreMapHotKeys.MAP_PAN),
    getDisabled: hasActiveLayer => !hasActiveLayer,
  },
  [MapMode.DRAW]: {
    title: t`Draw`,
    iconPath: mdiDraw,
    modeKey: MapMode.DRAW,
    getDisabled: () => false,
  },
};

export default [
  buttonsByKey[MapMode.NORMAL],
  buttonsByKey[MapMode.BOX_SELECTION],
  buttonsByKey[MapMode.POLYGON_SELECTION],
  buttonsByKey[MapMode.POINT_SELECTION],
  buttonsByKey[MapMode.INSPECTION],
];
