import { Button, IButtonProps } from '@blueprintjs/core';
import React, { ReactNode } from 'react';
import { useDispatch } from 'react-redux';
import { t } from 'ttag';
import { OrganizationInfo } from 'uf-api';
import { openProjectDialog } from 'uf/projects/projectDialogSlice';
import CanPerform from 'uf/ui/base/CanPerform/CanPerform';
import { getOrganizationIds } from 'uf/ui/projects/CreateProjectButton/helpers';
import { sources as settingsSources } from 'uf/ui/settings/logging';
import { useProjectTypes } from 'uf/ui/projects/hooks';
import _ from 'lodash';

type ButtonProps = Pick<
  IButtonProps,
  'className' | 'disabled' | 'minimal' | 'intent' | 'icon'
>;

export interface OwnProps {
  organization: OrganizationInfo | OrganizationInfo[];
  /** for compatibility with HTML buttons */
  buttonProps?: ButtonProps;
  children?: ReactNode;
}

const CreateProjectButton = ({
  organization,
  children,
  buttonProps,
}: OwnProps) => {
  const dispatch = useDispatch();
  const { projectTypes } = useProjectTypes();
  const handleClick = () => {
    dispatch(openProjectDialog(settingsSources.PROJECT_SETTINGS_PAGE));
  };
  // NOTE: we force permission recheck when the project list changes because the button may not
  // re-render when a new project is created or deleted from the settings page.
  const organizationIds = getOrganizationIds(organization);
  return (
    <div>
      <CanPerform
        any
        cache={false}
        verb="project.create"
        performRecheck
        resources={organizationIds}>
        {({ disabled }) => (
          <Button
            title={t`Create new project`}
            onClick={handleClick}
            disabled={disabled || _.isEmpty(projectTypes)}
            {...buttonProps}>
            {children}
          </Button>
        )}
      </CanPerform>
    </div>
  );
};

export default CreateProjectButton;
