import {
  KILOMETERS_PER_FOOT,
  KILOMETERS_PER_METER,
  KILOMETERS_PER_MILE,
  SQUARE_FEET_PER_ACRE,
  SQUARE_FEET_PER_HECTARE,
  UnitLabelKeys,
} from 'uf/base/units';

/**
 * Helper function to convert lengths/areas defined in UF to lengths defined in turf.js.  Areas are
 * converted to to lengths just by taking the square root.
 */
export function convertToKilometers(
  size: number,
  unitKey: UnitLabelKeys,
): number {
  switch (unitKey) {
    case 'miles':
      return size * KILOMETERS_PER_MILE;
    case 'feet':
      return size * KILOMETERS_PER_FOOT;
    case 'meters':
      return size * KILOMETERS_PER_METER;
    case 'kilometer':
      return size;
    case 'kilometers':
      return size;
    case 'acres':
      return Math.sqrt(SQUARE_FEET_PER_ACRE * size) * KILOMETERS_PER_FOOT;
    case 'hectares':
      return Math.sqrt(SQUARE_FEET_PER_HECTARE * size) * KILOMETERS_PER_FOOT;
    case 'square_miles':
      return Math.sqrt(size) * KILOMETERS_PER_MILE;
    case 'square_feet':
      return Math.sqrt(size) * KILOMETERS_PER_FOOT;
    case 'square_meters':
      return Math.sqrt(size) * KILOMETERS_PER_METER;
    case 'square_kilometers':
      return Math.sqrt(size);
    default:
      return size;
  }
}
