import _ from 'lodash';
import { Expression, StyleFunction } from 'mapbox-gl';

import { ColumnKey } from 'uf/layers';
import { isNullCategory } from 'uf/layers/nullStats';
import {
  parsePaintOpacity,
  parsePaintWidth,
} from 'uf/map/stylelayers/stylelayers';
import {
  LayerColumnSymbology,
  PaintColorProperty,
  PaintStrokeColorProperty,
} from 'uf/symbology';

interface ParsePaintOptions {
  nullsOnly: boolean;
}

const DEFAULT_OPTIONS: ParsePaintOptions = {
  nullsOnly: false,
};
export function parseCategoricalPaintProperty(
  symbology: LayerColumnSymbology,
  columnKey: ColumnKey,
  { nullsOnly } = DEFAULT_OPTIONS,
): mapboxgl.Layer['paint'] {
  const paintProperty: mapboxgl.Layer['paint'] = _.mapValues(
    symbology.paint,
    (value: any, key: string) => {
      switch (key) {
        // `fill-color`, `line-color`, `circle-color`
        case `${symbology.type}-color`:
          return parsePaintColor(symbology, value, columnKey, {
            nullsOnly,
          });

        case `${symbology.type}-width`:
          // No data-driven support yet, so no nullsOnly options needed
          return parsePaintWidth(symbology, value);

        case `${symbology.type}-opacity`:
          // No data-driven support yet, so no nullsOnly options needed
          return parsePaintOpacity(symbology, value);

        case `${symbology.type}-stroke-color`:
          return parsePaintStrokeColor(symbology, value, {
            nullsOnly,
          });

        default:
          return value;
      }
    },
  );
  return paintProperty;
}

function parsePaintColor(
  symbology: LayerColumnSymbology,
  paintColor: PaintColorProperty,
  columnKey: ColumnKey,
  { nullsOnly = false }: ParsePaintOptions,
): string | StyleFunction | Expression {
  if (_.isString(paintColor)) {
    return paintColor;
  }

  const { property, stops, default: defaultColor } = paintColor;
  const { interpolation } = symbology.display_hints;

  // The symbology can contain null categories, but mapbox don't support styling
  // multiple data types in a single style layer, we have to do that in a
  // separate style layer.
  if (nullsOnly) {
    const nullStop = stops.find(stop => isNullCategory(stop.value));
    return [
      'match',
      ['coalesce', ['get', columnKey], 'null-sentinel'],
      'null-sentinel',
      nullStop?.color ?? 'transparent',
      defaultColor || 'transparent',
    ];
  }

  const filteredStops = stops.filter(stop => {
    const isNull = isNullCategory(stop.value);
    return nullsOnly ? isNull : !isNull;
  });

  if (!filteredStops.length) {
    return defaultColor || 'transparent';
  }
  const parsed: mapboxgl.StyleFunction = {
    property,
    default: defaultColor || 'transparent',
    type: interpolation,
    stops: filteredStops.map(({ color, value }) => {
      if (isNullCategory(value)) {
        return [null, color];
      }
      return [value, color];
    }),
  };

  return parsed;
}

function parsePaintStrokeColor(
  symbology: LayerColumnSymbology,
  strokeColor: PaintStrokeColorProperty,
  { nullsOnly }: ParsePaintOptions,
): mapboxgl.CirclePaint['circle-stroke-color'] {
  if (_.isString(strokeColor)) {
    return strokeColor;
  }

  const { property, stops, default: defaultColor } = strokeColor;
  const { interpolation } = symbology.display_hints;

  if (!stops) {
    return defaultColor ?? 'transparent';
  }
  const filteredStops = stops.filter(stop => {
    const isNull = isNullCategory(stop.value);
    return nullsOnly ? isNull : !isNull;
  });

  const parsed: mapboxgl.StyleFunction = {
    property,
    default: defaultColor || 'transparent',
    type: interpolation,
    stops: filteredStops.map(stop => [stop.value, stop['stroke-color']]),
  };

  return parsed;
}
