import { createSelector } from 'reselect';
import { t } from 'ttag';

import { EMPTY_ARRAY, EMPTY_OBJECT } from 'uf/base';
import { sortedNumerically } from 'uf/base/array';
import { makeGetFromPropsSelector } from 'uf/base/selector';
import { ProjectId } from 'uf/projects';
import { DEFAULT_VIEW_ID, View } from 'uf/views';
import { ViewsByProject } from 'uf/views/state';

import { getViewsState } from './';

const EmptyViewsList: View[] = [
  {
    key: DEFAULT_VIEW_ID,
    name: t`All layers`,
  },
];

const getViewsByProject = createSelector(
  getViewsState,
  (views): ViewsByProject => views.views ?? EMPTY_OBJECT,
);

/** Get the exact list of views in the store */
export function makeGetProjectViewsList() {
  return createSelector(
    getViewsByProject,
    makeGetFromPropsSelector<ProjectId, 'projectId'>('projectId'),
    (views, projectId): View[] => views?.[projectId] ?? EMPTY_ARRAY,
  );
}

/** Get the list of views, including the magical "All views" view, which is not stored in redux */
export function makeGetProjectViewsForDisplay() {
  return createSelector(makeGetProjectViewsList(), (views): View[] => {
    const sortedViews = sortedNumerically(views, view => view.name);
    return [...EmptyViewsList, ...sortedViews];
  });
}
