import 'uf/api/state';

import { shallowEqual } from 'recompose';
import {
  createSelector,
  defaultMemoize,
  ParametricSelector,
  Selector,
} from 'reselect';

import { UFApiState } from 'uf-api';
import { EMPTY_ARRAY, EMPTY_OBJECT } from 'uf/base';
import { risonEncode } from 'uf/base/rison';
import { makeGetFromPropsSelector } from 'uf/base/selector';
import { EmptyKeyedState } from 'uf/data/dataState';
import { UFState } from 'uf/state';

function getApiState(state: UFState): UFApiState {
  return state?.api ?? (EMPTY_OBJECT as UFApiState);
}

/**
 * Extracts only the API calls where the result is an array (i.e. from
 * POST/PUT/etc)
 */
type ArrayOfResults = {
  [T in keyof UFApiState]: {
    [O in keyof UFApiState[T]]: UFApiState[T][O] extends any[]
      ? UFApiState[T][O]
      : never;
  };
};

/**
 * Extracts only the API calls where the result if an object (i.e. from GET/etc)
 */
type ObjectsOfResults = {
  [T in keyof UFApiState]: {
    [O in keyof UFApiState[T]]: UFApiState[T][O] extends any[]
      ? never
      : UFApiState[T][O];
  };
};

type ArrayType<T> = T extends (infer AT)[] ? AT : never;

/**
 * Create a selector that gets the "result root" of an array-based API call.
 *
 *  * For POST/PUT/etc calls, this will be an array of DataState<R> where R is
 *    the result
 *  * For GET/etc calls, this will be an object mapping keys to DataState.
 *
 * This is meant to be used to compose slightly more specific selectors, like:
 *
 * ```
 * function makeGetPaintResults() {
 *   return createSelector(
 *     makeGetApiArrayResults('paint', 'paint_base_scenario'),
 *     makeGetFromPropsSelector('projectId'),
 *     (results, projectId) =>
 *       _.sortBy(results.filter(result => result.loaded && result.key === projectId),
 *                result => result.requestTime)[0],
 *   )
 * }
 * ```
 *
 * @param tag The API tag, like 'layer', or 'paint'
 * @param operation The operation like 'get_layer' or 'paint_scenario_canvas'
 */
export function makeGetApiArrayResults<
  T extends keyof ArrayOfResults,
  O extends keyof ArrayOfResults[T],
>(tag: T, operation: O): Selector<UFState, ArrayOfResults[T][O]> {
  return createSelector(
    getApiState,
    state => state?.[tag]?.[operation as string] ?? EMPTY_ARRAY,
  );
}

/** Get a specific request, by request id */
export function makeGetApiArrayResultByRequestId<
  T extends keyof ArrayOfResults,
  O extends keyof ArrayOfResults[T],
>(
  tag: T,
  operation: O,
): ParametricSelector<
  UFState,
  Record<'requestId', string>,
  ArrayType<ArrayOfResults[T][O]>
> {
  return createSelector(
    makeGetApiArrayResults<T, O>(tag, operation),
    makeGetFromPropsSelector<string, 'requestId'>('requestId'),
    (states, requestId) => {
      return (
        (states as unknown as any[]).find(
          dataState => dataState.requestId === requestId,
        ) ?? EmptyKeyedState
      );
    },
  );
}

/**
 * Create a selector that gets the "result root" of an key-based API call. This
 * object will map request keys to DataState<T> for the given result T.
 *
 * This is meant to be used to compose slightly more specific selectors, like:
 *
 * ```
 * function makeGetPaintActions() {
 *   return createSelector(
 *     makeGetApiObjectResults('paint', 'get_painted_canvas_actions'),
 *     makeGetFromPropsSelector<LayerId, 'layerId'>('layerId'),
 *     makeGetFromPropsSelector<string, 'version'>('version'),
 *     (results, projectId, version) => results[makeRevertableKey(layerId, version)]
 *   )
 * }
 * ```
 *
 * @param tag The API tag, like 'layer', or 'paint'
 * @param operation The operation like 'get_layer' or 'paint_scenario_canvas'
 */
export function makeGetApiObjectResults<
  T extends keyof ObjectsOfResults,
  O extends keyof ObjectsOfResults[T],
>(tag: T, operation: O): Selector<UFState, ObjectsOfResults[T][O]> {
  return createSelector(
    getApiState,
    state => state?.[tag]?.[operation as string] ?? EMPTY_OBJECT,
  );
}

type SelectorGetter<
  T extends keyof ObjectsOfResults,
  O extends keyof ObjectsOfResults[T],
  P,
> = (params: P) => ValueOf<ObjectsOfResults[T][O]>;

export function makeGetApiObjectGetter<
  T extends keyof ObjectsOfResults,
  O extends keyof ObjectsOfResults[T],
  P,
>(
  tag: T,
  operation: O,
  makeKey: (params: P) => string,
): Selector<UFState, SelectorGetter<T, O, P>> {
  const getter = createSelector(
    makeGetApiObjectResults(tag, operation),
    (resultsObject): SelectorGetter<T, O, P> =>
      defaultMemoize((params: P) => {
        const key = makeKey(params);
        return resultsObject?.[key];
      }),
  );
  return getter;
}
type ValueOf<T> = T[keyof T];

/**
 * Create a selector with all of the parameters.
 *
 * @param tag
 * @param operation
 * @param makeKey The same key
 */
export function makeGetApiObjectState<
  T extends keyof ObjectsOfResults,
  O extends keyof ObjectsOfResults[T],
  P extends object,
>(
  tag: T,
  operation: O,
  makeKey: (params: P) => string = risonEncode,
): ParametricSelector<UFState, P, ValueOf<ObjectsOfResults[T][O]>> {
  return createSelector(
    makeGetApiObjectResults(tag, operation),
    (s, ownProps: P) => ownProps,
    (dataStateObject, params): ValueOf<ObjectsOfResults[T][O]> => {
      const key = makeKey(params);
      return dataStateObject[key] ?? EmptyKeyedState;
    },
  );
}

export function makeGetApiObjectStateGetter<
  T extends keyof ObjectsOfResults,
  O extends keyof ObjectsOfResults[T],
  P extends object,
>(
  tag: T,
  operation: O,
  makeKey: (params: P) => string = risonEncode,
): Selector<UFState, (p: P) => ValueOf<ObjectsOfResults[T][O]>> {
  return createSelector(
    makeGetApiObjectResults(tag, operation),
    specificState => {
      return defaultMemoize((params: P) => {
        const key = makeKey(params);
        const result = specificState[key];
        return result;
      }, shallowEqual);
    },
  );
}
