/**
 * UrbanFootprint App API
 * API for serving UrbanFootprint Application
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { ClientResponse } from '../variables';

// @ts-ignore: This is used only for params, but some services have param-free APIs
import { PaintServiceInterface } from './paint.serviceInterface';

// @ts-ignore: These imports are for both params and returnType, but we only use returnType
import { PaintingActionInfo } from '../model/paintingActionInfo';
// @ts-ignore: These imports are for both params and returnType, but we only use returnType
import { PaintingRevertibleActions } from '../model/paintingRevertibleActions';
// @ts-ignore: These imports are for both params and returnType, but we only use returnType
import { ProblemDetail } from '../model/problemDetail';

/**
 * Package: api
 */

const enqueuePaintBaseScenarioCanvasPrefix = `uf-api/paint/enqueue_paint_base_scenario_canvas/`;
/**
 * Action types for paint.enqueue_paint_base_scenario_canvas
 *
 * Enqueue painting action to the project\&#39;s base scenario\&#39;s painted canvas, and enqueue tasks to update dependent scenarios.
 */
export const enqueuePaintBaseScenarioCanvasActionTypes = {
  LOAD: `${enqueuePaintBaseScenarioCanvasPrefix}LOAD` as 'uf-api/paint/enqueue_paint_base_scenario_canvas/LOAD',
  SUCCESS:
    `${enqueuePaintBaseScenarioCanvasPrefix}SUCCESS` as 'uf-api/paint/enqueue_paint_base_scenario_canvas/SUCCESS',
  FAILURE:
    `${enqueuePaintBaseScenarioCanvasPrefix}FAILURE` as 'uf-api/paint/enqueue_paint_base_scenario_canvas/FAILURE',
  CLEAR:
    `${enqueuePaintBaseScenarioCanvasPrefix}CLEAR` as 'uf-api/paint/enqueue_paint_base_scenario_canvas/CLEAR',
} as const;

/**
 * LOAD action for paint.enqueue_paint_base_scenario_canvas
 * Enqueue painting action to the project\&#39;s base scenario\&#39;s painted canvas, and enqueue tasks to update dependent scenarios.
 */
export interface enqueuePaintBaseScenarioCanvasLoadAction {
  readonly type: 'uf-api/paint/enqueue_paint_base_scenario_canvas/LOAD';
  readonly params: PaintServiceInterface.enqueuePaintBaseScenarioCanvasParams;
  readonly promise: Promise<ClientResponse<string>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for paint.enqueue_paint_base_scenario_canvas
 * Enqueue painting action to the project\&#39;s base scenario\&#39;s painted canvas, and enqueue tasks to update dependent scenarios.
 */
export interface enqueuePaintBaseScenarioCanvasSuccessAction {
  readonly type: 'uf-api/paint/enqueue_paint_base_scenario_canvas/SUCCESS';
  readonly result: string;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for paint.enqueue_paint_base_scenario_canvas
 * Enqueue painting action to the project\&#39;s base scenario\&#39;s painted canvas, and enqueue tasks to update dependent scenarios.
 */
export interface enqueuePaintBaseScenarioCanvasFailureAction {
  readonly type: 'uf-api/paint/enqueue_paint_base_scenario_canvas/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for paint.enqueue_paint_base_scenario_canvas
 * Enqueue painting action to the project\&#39;s base scenario\&#39;s painted canvas, and enqueue tasks to update dependent scenarios.
 */
export interface enqueuePaintBaseScenarioCanvasClearAction {
  readonly type: 'uf-api/paint/enqueue_paint_base_scenario_canvas/CLEAR';
  readonly key: string;
}

const enqueuePaintScenarioCanvasPrefix = `uf-api/paint/enqueue_paint_scenario_canvas/`;
/**
 * Action types for paint.enqueue_paint_scenario_canvas
 *
 * Enqueue painting action to a scenario.
 */
export const enqueuePaintScenarioCanvasActionTypes = {
  LOAD: `${enqueuePaintScenarioCanvasPrefix}LOAD` as 'uf-api/paint/enqueue_paint_scenario_canvas/LOAD',
  SUCCESS:
    `${enqueuePaintScenarioCanvasPrefix}SUCCESS` as 'uf-api/paint/enqueue_paint_scenario_canvas/SUCCESS',
  FAILURE:
    `${enqueuePaintScenarioCanvasPrefix}FAILURE` as 'uf-api/paint/enqueue_paint_scenario_canvas/FAILURE',
  CLEAR:
    `${enqueuePaintScenarioCanvasPrefix}CLEAR` as 'uf-api/paint/enqueue_paint_scenario_canvas/CLEAR',
} as const;

/**
 * LOAD action for paint.enqueue_paint_scenario_canvas
 * Enqueue painting action to a scenario.
 */
export interface enqueuePaintScenarioCanvasLoadAction {
  readonly type: 'uf-api/paint/enqueue_paint_scenario_canvas/LOAD';
  readonly params: PaintServiceInterface.enqueuePaintScenarioCanvasParams;
  readonly promise: Promise<ClientResponse<string>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for paint.enqueue_paint_scenario_canvas
 * Enqueue painting action to a scenario.
 */
export interface enqueuePaintScenarioCanvasSuccessAction {
  readonly type: 'uf-api/paint/enqueue_paint_scenario_canvas/SUCCESS';
  readonly result: string;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for paint.enqueue_paint_scenario_canvas
 * Enqueue painting action to a scenario.
 */
export interface enqueuePaintScenarioCanvasFailureAction {
  readonly type: 'uf-api/paint/enqueue_paint_scenario_canvas/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for paint.enqueue_paint_scenario_canvas
 * Enqueue painting action to a scenario.
 */
export interface enqueuePaintScenarioCanvasClearAction {
  readonly type: 'uf-api/paint/enqueue_paint_scenario_canvas/CLEAR';
  readonly key: string;
}

const getPaintedCanvasActionByKeyPrefix = `uf-api/paint/get_painted_canvas_action_by_key/`;
/**
 * Action types for paint.get_painted_canvas_action_by_key
 *
 * Get action by key
 */
export const getPaintedCanvasActionByKeyActionTypes = {
  LOAD: `${getPaintedCanvasActionByKeyPrefix}LOAD` as 'uf-api/paint/get_painted_canvas_action_by_key/LOAD',
  SUCCESS:
    `${getPaintedCanvasActionByKeyPrefix}SUCCESS` as 'uf-api/paint/get_painted_canvas_action_by_key/SUCCESS',
  FAILURE:
    `${getPaintedCanvasActionByKeyPrefix}FAILURE` as 'uf-api/paint/get_painted_canvas_action_by_key/FAILURE',
  CLEAR:
    `${getPaintedCanvasActionByKeyPrefix}CLEAR` as 'uf-api/paint/get_painted_canvas_action_by_key/CLEAR',
} as const;

/**
 * LOAD action for paint.get_painted_canvas_action_by_key
 * Get action by key
 */
export interface getPaintedCanvasActionByKeyLoadAction {
  readonly type: 'uf-api/paint/get_painted_canvas_action_by_key/LOAD';
  readonly params: PaintServiceInterface.getPaintedCanvasActionByKeyParams;
  readonly promise: Promise<ClientResponse<object>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for paint.get_painted_canvas_action_by_key
 * Get action by key
 */
export interface getPaintedCanvasActionByKeySuccessAction {
  readonly type: 'uf-api/paint/get_painted_canvas_action_by_key/SUCCESS';
  readonly result: object;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for paint.get_painted_canvas_action_by_key
 * Get action by key
 */
export interface getPaintedCanvasActionByKeyFailureAction {
  readonly type: 'uf-api/paint/get_painted_canvas_action_by_key/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for paint.get_painted_canvas_action_by_key
 * Get action by key
 */
export interface getPaintedCanvasActionByKeyClearAction {
  readonly type: 'uf-api/paint/get_painted_canvas_action_by_key/CLEAR';
  readonly key: string;
}

const getPaintedCanvasActionsV2Prefix = `uf-api/paint/get_painted_canvas_actions_v2/`;
/**
 * Action types for paint.get_painted_canvas_actions_v2
 *
 * Get actions for painted canvas
 */
export const getPaintedCanvasActionsV2ActionTypes = {
  LOAD: `${getPaintedCanvasActionsV2Prefix}LOAD` as 'uf-api/paint/get_painted_canvas_actions_v2/LOAD',
  SUCCESS:
    `${getPaintedCanvasActionsV2Prefix}SUCCESS` as 'uf-api/paint/get_painted_canvas_actions_v2/SUCCESS',
  FAILURE:
    `${getPaintedCanvasActionsV2Prefix}FAILURE` as 'uf-api/paint/get_painted_canvas_actions_v2/FAILURE',
  CLEAR:
    `${getPaintedCanvasActionsV2Prefix}CLEAR` as 'uf-api/paint/get_painted_canvas_actions_v2/CLEAR',
} as const;

/**
 * LOAD action for paint.get_painted_canvas_actions_v2
 * Get actions for painted canvas
 */
export interface getPaintedCanvasActionsV2LoadAction {
  readonly type: 'uf-api/paint/get_painted_canvas_actions_v2/LOAD';
  readonly params: PaintServiceInterface.getPaintedCanvasActionsV2Params;
  readonly promise: Promise<ClientResponse<Array<PaintingActionInfo>>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for paint.get_painted_canvas_actions_v2
 * Get actions for painted canvas
 */
export interface getPaintedCanvasActionsV2SuccessAction {
  readonly type: 'uf-api/paint/get_painted_canvas_actions_v2/SUCCESS';
  readonly result: Array<PaintingActionInfo>;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for paint.get_painted_canvas_actions_v2
 * Get actions for painted canvas
 */
export interface getPaintedCanvasActionsV2FailureAction {
  readonly type: 'uf-api/paint/get_painted_canvas_actions_v2/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for paint.get_painted_canvas_actions_v2
 * Get actions for painted canvas
 */
export interface getPaintedCanvasActionsV2ClearAction {
  readonly type: 'uf-api/paint/get_painted_canvas_actions_v2/CLEAR';
  readonly key: string;
}

const getRevertibleActionsV2Prefix = `uf-api/paint/get_revertible_actions_v2/`;
/**
 * Action types for paint.get_revertible_actions_v2
 *
 * Get recent user actions to undo or redo for painted canvas.
 */
export const getRevertibleActionsV2ActionTypes = {
  LOAD: `${getRevertibleActionsV2Prefix}LOAD` as 'uf-api/paint/get_revertible_actions_v2/LOAD',
  SUCCESS:
    `${getRevertibleActionsV2Prefix}SUCCESS` as 'uf-api/paint/get_revertible_actions_v2/SUCCESS',
  FAILURE:
    `${getRevertibleActionsV2Prefix}FAILURE` as 'uf-api/paint/get_revertible_actions_v2/FAILURE',
  CLEAR:
    `${getRevertibleActionsV2Prefix}CLEAR` as 'uf-api/paint/get_revertible_actions_v2/CLEAR',
} as const;

/**
 * LOAD action for paint.get_revertible_actions_v2
 * Get recent user actions to undo or redo for painted canvas.
 */
export interface getRevertibleActionsV2LoadAction {
  readonly type: 'uf-api/paint/get_revertible_actions_v2/LOAD';
  readonly params: PaintServiceInterface.getRevertibleActionsV2Params;
  readonly promise: Promise<ClientResponse<PaintingRevertibleActions>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for paint.get_revertible_actions_v2
 * Get recent user actions to undo or redo for painted canvas.
 */
export interface getRevertibleActionsV2SuccessAction {
  readonly type: 'uf-api/paint/get_revertible_actions_v2/SUCCESS';
  readonly result: PaintingRevertibleActions;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for paint.get_revertible_actions_v2
 * Get recent user actions to undo or redo for painted canvas.
 */
export interface getRevertibleActionsV2FailureAction {
  readonly type: 'uf-api/paint/get_revertible_actions_v2/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for paint.get_revertible_actions_v2
 * Get recent user actions to undo or redo for painted canvas.
 */
export interface getRevertibleActionsV2ClearAction {
  readonly type: 'uf-api/paint/get_revertible_actions_v2/CLEAR';
  readonly key: string;
}

export const paintActionTypesMap = {
  /** Action types for paint.enqueue_paint_base_scenario_canvas */
  enqueue_paint_base_scenario_canvas: enqueuePaintBaseScenarioCanvasActionTypes,
  /** Action types for paint.enqueue_paint_scenario_canvas */
  enqueue_paint_scenario_canvas: enqueuePaintScenarioCanvasActionTypes,
  /** Action types for paint.get_painted_canvas_action_by_key */
  get_painted_canvas_action_by_key: getPaintedCanvasActionByKeyActionTypes,
  /** Action types for paint.get_painted_canvas_actions_v2 */
  get_painted_canvas_actions_v2: getPaintedCanvasActionsV2ActionTypes,
  /** Action types for paint.get_revertible_actions_v2 */
  get_revertible_actions_v2: getRevertibleActionsV2ActionTypes,
} as const;

export const paintDetailsMap = {
  enqueue_paint_base_scenario_canvas: {
    /** HTTP method for paint.enqueue_paint_base_scenario_canvas */
    httpMethod: 'post' as const,
  },
  enqueue_paint_scenario_canvas: {
    /** HTTP method for paint.enqueue_paint_scenario_canvas */
    httpMethod: 'post' as const,
  },
  get_painted_canvas_action_by_key: {
    /** HTTP method for paint.get_painted_canvas_action_by_key */
    httpMethod: 'get' as const,
  },
  get_painted_canvas_actions_v2: {
    /** HTTP method for paint.get_painted_canvas_actions_v2 */
    httpMethod: 'get' as const,
  },
  get_revertible_actions_v2: {
    /** HTTP method for paint.get_revertible_actions_v2 */
    httpMethod: 'get' as const,
  },
} as const;

/**
 * Local DataState<T> - kept in sync with uf/data/dataState.ts
 */
interface DataState<T> {
  readonly loading: boolean;
  readonly loaded: boolean;
  readonly requestId: string;
  readonly requestTime?: string;
  readonly responseTime?: string;
  readonly requestCount?: number;
  readonly error?: any;
  readonly promise?: Promise<T>;
  readonly data?: T;
  readonly extra?: any;
  readonly key?: string;
}
export interface PaintServiceState {
  /** all recent enqueuePaintBaseScenarioCanvas results (POST/PUT/PATCH) */
  readonly enqueue_paint_base_scenario_canvas: DataState<string>[];
  /** all recent enqueuePaintScenarioCanvas results (POST/PUT/PATCH) */
  readonly enqueue_paint_scenario_canvas: DataState<string>[];
  /** getPaintedCanvasActionByKey state, by key */
  get_painted_canvas_action_by_key: {
    readonly [key: string]: DataState<object>;
  };
  /** getPaintedCanvasActionsV2 state, by key */
  get_painted_canvas_actions_v2: {
    readonly [key: string]: DataState<Array<PaintingActionInfo>>;
  };
  /** getRevertibleActionsV2 state, by key */
  get_revertible_actions_v2: {
    readonly [key: string]: DataState<PaintingRevertibleActions>;
  };
}
