import { Button } from '@blueprintjs/core';
import { mdiRefresh } from '@mdi/js';
import Icon from '@mdi/react';
import React from 'react';
import { t } from 'ttag';

import { IconSizeNew } from 'uf/ui/base/icon';

interface OwnProps {
  onClick: () => void;
}

const ErrorBoundaryReloadButton: React.FunctionComponent<OwnProps> = props => {
  const { onClick } = props;

  return (
    <Button
      icon={
        <Icon
          path={mdiRefresh}
          size={IconSizeNew.SMALL}
          className={'ErrorReload-button'}
        />
      }
      intent="primary"
      onClick={onClick}>{t`Reload`}</Button>
  );
};

export default ErrorBoundaryReloadButton;
