import colors from 'material-colors';

import {
  CuratedColumnSymbology,
  CUSTOM_THEME,
  DEFAULT_OPACITY,
  InterpolationTypes,
  PaintColorTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/public/dataset/alternative_fuel_stations_us': {
    fuel_type_code: [
      {
        type: SymbologyTypes.CIRCLE,
        paint: {
          'circle-radius': 3,
          'circle-color': {
            property: 'fuel_type_code',
            type: PaintColorTypes.CATEGORICAL,
            stops: [
              {
                value: 'BD',
                label: 'Biodiesel (B20 and above)',
                color: colors.lightGreen[200],
              },
              {
                value: 'CNG',
                label: 'Compressed Natural Gas',
                color: colors.teal[200],
              },
              {
                value: 'E85',
                label: 'Ethanol (E85)',
                color: colors.orange[200],
              },
              { value: 'ELEC', label: 'Electric', color: colors.yellow[200] },
              { value: 'HY', label: 'Hydrogen', color: colors.blue[900] },
              {
                value: 'LNG',
                label: 'Liquified Natural Gas',
                color: colors.red[200],
              },
              {
                value: 'LPG',
                label: 'Liquified Peroleum Gas (Propane)',
                color: colors.purple[200],
              },
            ],
          },
          'circle-opacity': DEFAULT_OPACITY,
        },
        display_hints: {
          scale: SymbologyScales.CATEGORICAL,
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          theme: CUSTOM_THEME,
        },
      },
    ],
  },
};

export default symbologies;
