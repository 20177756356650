import { Button } from '@blueprintjs/core';
import { mdiPlusThick } from '@mdi/js';
import { Icon } from '@mdi/react';
import React from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { t } from 'ttag';
import { openProjectDialog } from 'uf/projects/projectDialogSlice';
import tw from 'uf/tailwindcss-classnames';
import CanPerform from 'uf/ui/base/CanPerform/CanPerform';
import { getOrganizationIds } from 'uf/ui/projects/CreateProjectButton/helpers';
import { useProjectTypes } from 'uf/ui/projects/hooks';
import { sources } from 'uf/ui/projects/logging';
import { getUserOrganizations } from 'uf/user/selectors/user';
import styles from './CreateProjectMenuButton.module.css';

const CreateProjectMenuButton: React.FC = () => {
  const dispatch = useDispatch();
  const { projectTypes } = useProjectTypes();
  const organizations = useSelector(getUserOrganizations);
  const organizationIds = getOrganizationIds(organizations);
  const handleClick = () => {
    dispatch(openProjectDialog(sources.PROJECT_MENU));
  };
  return (
    <CanPerform
      any
      cache={false}
      verb="project.create"
      performRecheck
      resources={organizationIds}>
      {({ disabled }) => (
        <Button
          data-testid="create-project-button"
          disabled={disabled || _.isEmpty(projectTypes)}
          className={`${tw(
            'tw-w-full',
            'tw-shadow-none',
            'tw-bg-none',
            'tw-bg-white',
            'tw-font-bold',
            'tw-inline',
          )} ${styles.createProjectButton}`}
          onClick={handleClick}>
          <span
            className={tw(
              'tw-flex',
              'tw-justify-start',
              'tw-items-center',
              'tw-gap-x-3',
            )}>
            <Icon
              path={mdiPlusThick}
              size={0.8}
              className={styles.createProjectIcon}
            />
            <span>{t`Create a new project`}</span>
          </span>
        </Button>
      )}
    </CanPerform>
  );
};

export default CreateProjectMenuButton;
