import { Button, Popover, Position, Tooltip } from '@blueprintjs/core';
import { mdiDownload } from '@mdi/js';
import Icon from '@mdi/react';
import classNames from 'classnames';
import * as React from 'react';
import { t } from 'ttag';

import { ColumnKey, LayerId } from 'uf/layers';
import iconStyles from 'uf/styles/icons.module.css';
import textStyles from 'uf/styles/text.module.css';
import CloseButton from 'uf/ui/base/CloseButton/CloseButton';
import { IconSizeNew } from 'uf/ui/base/icon';
import PaneHeader from 'uf/ui/base/Pane/PaneHeader';
import LayerExportMenuItems from 'uf/ui/layers/LayerExportMenuItems/LayerExportMenuItems';

import styles from './ExploreDetailsHeader.module.css';
import useUserFlag from 'uf/ui/user/useUserFlag';
import { useSelector } from 'react-redux';
import { makeGetLayerMetadata } from 'uf/layers/selectors/metadata';
import { storeMarkerPath } from 'uf/ui/base/StoreMarker';

interface Props {
  layerId: LayerId;
  columnKey: ColumnKey;
  layerName: string;
  layerIconPath: string;
  onClose: () => void;
}

const titleClassName = textStyles.ellipsisParent;
const ExploreDetailsHeader: React.FunctionComponent<Props> = props => {
  const { layerId, columnKey, layerName, layerIconPath, onClose } = props;
  const layer = useSelector(state =>
    makeGetLayerMetadata()(state, { layerId }),
  );
  const hasPreSalesOrgFlag = useUserFlag('pre-sales');
  const isPointOfInterestLayer =
    layer?.display_hints?.parent?.key?.includes('safegraph_poi');
  return (
    <PaneHeader
      iconPath={isPointOfInterestLayer ? storeMarkerPath : layerIconPath}
      title={layerName}
      className={styles.header}
      titleClassName={titleClassName}>
      <Popover
        position={Position.BOTTOM_RIGHT}
        minimal
        content={
          <LayerExportMenuItems layerId={layerId} columnKey={columnKey} />
        }>
        <Tooltip
          content={
            hasPreSalesOrgFlag
              ? t`Downloading layers is disabled for pre-sales organizations`
              : t`Download layer`
          }
          autoFocus={false}>
          <Button
            disabled={hasPreSalesOrgFlag}
            minimal
            small
            className={classNames(iconStyles.colorDefault, 'ExportLayerMenu')}
            icon={<Icon path={mdiDownload} size={IconSizeNew.SMALL} />}
          />
        </Tooltip>
      </Popover>
      <Tooltip content={t`Close pane`}>
        <CloseButton onClose={onClose} />
      </Tooltip>
    </PaneHeader>
  );
};
export default ExploreDetailsHeader;
