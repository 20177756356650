import { FormGroup, Radio } from '@blueprintjs/core';
import classNames from 'classnames';
import React, { useCallback } from 'react';
import { t } from 'ttag';

import { ProjectRoles } from 'uf/projects';

import styles from './ProjectRoleChoices.module.css';

interface Props {
  projectRole: ProjectRoles;
  onProjectRoleChange: (projectRole: ProjectRoles) => void;
}

export const ProjectRoleChoices: React.FunctionComponent<Props> = props => {
  const { projectRole, onProjectRoleChange } = props;

  const onProjectRoleChangeHandler = useCallback(
    e => {
      const value = e?.target?.value as ProjectRoles;
      onProjectRoleChange(value);
    },
    [onProjectRoleChange],
  );

  const standardRoleChecked = projectRole === ProjectRoles.STANDARD;
  const explorerRoleChecked = projectRole === ProjectRoles.EXPLORER;

  return (
    <FormGroup label={t`Project Type`}>
      <Radio
        className={classNames(styles.radio, 'CreateProjectForm-projectRole')}
        value={ProjectRoles.STANDARD}
        checked={standardRoleChecked}
        onChange={onProjectRoleChangeHandler}>
        {t`Standard`}
      </Radio>
      <Radio
        className={classNames(styles.radio, 'CreateProjectForm-projectRole')}
        value={ProjectRoles.EXPLORER}
        checked={explorerRoleChecked}
        onChange={onProjectRoleChangeHandler}>
        {t`Explorer`}
      </Radio>
    </FormGroup>
  );
};
