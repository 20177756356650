import { Action as RFRAction } from 'redux-first-router';

import { ROUTE_SETTINGS } from 'uf/organizations/ActionTypes';

export function setSettingsRoute(orgKey: string): RFRAction {
  return {
    type: ROUTE_SETTINGS,
    payload: {
      namespace: orgKey,
    },
  };
}
