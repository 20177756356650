import MaterialColors from 'material-colors';

import { ColumnKey, LayerId } from 'uf/layers';
import {
  DataDrivenColorStop,
  DEFAULT_OPACITY,
  InterpolationTypes,
  LayerColumnSymbology,
  PaintColorTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';
import { makePaintProperty } from 'uf/symbology/spec/paint';

export const PROJECT_AREA_CANVAS_KEY = 'project_area_canvas';
export const CONTEXT_AREA_CANVAS_KEY = 'context_area_canvas';

export const PROJECT_CANVAS_OPACITY = 0.7;
export const CONTEXT_CANVAS_OPACITY = 0.5;

export function createBuiltFormSymbology(
  layerId: LayerId,
  columnKey: ColumnKey,
  builtFormStops: DataDrivenColorStop[],
  symbologyKey: string,
) {
  const fillColor = makePaintProperty(
    builtFormStops,
    'transparent',
    columnKey,
    PaintColorTypes.CATEGORICAL,
  );

  const fillSymbology: LayerColumnSymbology = {
    id: `${layerId}|${columnKey}|${SymbologyTypes.FILL}`,
    type: SymbologyTypes.FILL,
    paint: {
      'fill-color': fillColor,
      'fill-opacity': DEFAULT_OPACITY,
    },
    display_hints: {
      scale: SymbologyScales.CATEGORICAL,
      distribution: null,
      interpolation: InterpolationTypes.CATEGORICAL,
      theme: 'custom',
    },
  };

  const lineSymbology: LayerColumnSymbology = {
    id: `${layerId}|${columnKey}|${SymbologyTypes.LINE}`,
    type: SymbologyTypes.LINE,
    paint: {
      'line-color': MaterialColors.grey[500],
      'line-width': 0.5,
      'line-opacity': DEFAULT_OPACITY,
    },
    display_hints: {
      scale: SymbologyScales.CATEGORICAL,
      distribution: null,
      interpolation: InterpolationTypes.CATEGORICAL,
      theme: 'material:grey:500',
    },
  };

  if (symbologyKey === PROJECT_AREA_CANVAS_KEY) {
    fillSymbology.paint['fill-opacity'] = PROJECT_CANVAS_OPACITY;
    lineSymbology.paint['line-opacity'] = PROJECT_CANVAS_OPACITY;
  } else if (symbologyKey === CONTEXT_AREA_CANVAS_KEY) {
    fillSymbology.paint['fill-opacity'] = CONTEXT_CANVAS_OPACITY;
    lineSymbology.paint['line-opacity'] = CONTEXT_CANVAS_OPACITY;
  }

  return [fillSymbology, lineSymbology];
}
