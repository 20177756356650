import { Map } from 'mapbox-gl';

/**
 * Normal Mode:
 * Gives the map drag panning and box zooming abilities.
 * This is the default mode of the map.
 * @param map - the mapboxgl map instance
 */

export function setNormalMode(map: Map) {
  // not available in mocks
  if (!map || !map.getCanvasContainer) {
    console.error('Missing map');
    return;
  }
  const canvas = map.getCanvasContainer();
  // setting cursor to '' enables default behavior
  canvas.style.cursor = '';
  map.boxZoom.enable();
  map.dragPan.enable();
  map.doubleClickZoom.enable();
}
