import {
  CreateEvent,
  DirectSelectOptions,
  Mode,
  ModeChangeEvent,
  ModeOptions,
  SelectionChangeEvent,
  SimpleSelectOptions,
  UpdateEvent,
} from '@mapbox/mapbox-gl-draw';
import { Geometries } from '@turf/helpers';
import { coordAll } from '@turf/meta';
import { Feature } from 'geojson';
import _ from 'lodash';
import { Map } from 'mapbox-gl';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { extractFeatureCoordinates } from 'uf/base/map';
import { typeAssertNever } from 'uf/base/never';
import { CoordPathsByFeature } from 'uf/map/state';
import usePrevious from 'uf/ui/base/usePrevious/usePrevious';
import { BoxSelectVerticesOptions } from 'uf/ui/map/DrawModeBoxSelectVertices';
import { DrawMode } from 'uf/ui/map/modes/draw';

interface DrawEventListeners {
  onSetMode: (mode: Mode, modeOptions: ModeOptions) => void;

  onCreateFeatures: (features: Feature[]) => Feature[];
  onUpdateFeature: (feature: Feature) => void;
  onSelectFeatures: (
    features: Feature[],
    coordPaths: CoordPathsByFeature,
  ) => void;
}

/**
 * This is a hook that provides callbacks to the MapDraw component
 *
 * To use:
 *   const onInitialize = useDraw(....);
 *
 * Then initialize it with a map:
 *
 *   onInitialize(drawMode: DrawMode)
 */
export function useDraw(
  featureEdits: Feature[],
  selectedFeatureIds: string[],
  selectedCoordPaths: CoordPathsByFeature,
  mode: Mode<'box_select_vertices'>,
  events: DrawEventListeners,
): (drawMode: DrawMode) => void {
  const { onCreateFeatures, onSelectFeatures, onUpdateFeature, onSetMode } =
    events;
  // Hold onto the draw mode within this hook. This is effectively the "owner"
  // of the hook and nobody outside the hook should have access to the actual
  // drawMode object.
  const [drawMode, setDrawMode] = useState<DrawMode>(null);

  // syncs the mode, selected features, and selected coord paths
  useUpdateMode(
    drawMode,
    mode,
    featureEdits,
    selectedFeatureIds,
    selectedCoordPaths,
  );

  // Sync the editable features
  useUpdateFeatureList(featureEdits, drawMode);

  useDrawEventListeners(
    drawMode,
    onCreateFeatures,
    onUpdateFeature,
    onSelectFeatures,
    onSetMode,
  );

  const initializeDraw = useCallback(
    (dm: DrawMode) => {
      setDrawMode(dm);
      setupMapForEdit(dm, featureEdits);
    },
    [featureEdits],
  );

  return initializeDraw;
}

/**
 * An internal hook to keep gl-draw in sync with `mode`.
 */

function useUpdateMode(
  drawMode: DrawMode,
  mode: Mode<'box_select_vertices'>,
  featureEdits: Feature[],
  selectedFeatureIds: string[],
  selectedCoordPaths: CoordPathsByFeature,
) {
  useEffect(() => {
    // Note: due to complicated timing issues between draw initialization and
    // cleanup, and react effect timing, we need to be careful here: we will get
    // old `drawMode` after we have exited the mode, but the only way to tell if
    // it is "old" is if `mapboxDraw` is null. Note that we cannot just use
    // `mapboxDraw` itself as an effect dependency, in as the effect will just
    // get the old value of mapboxDraw.
    if (
      !drawMode?.mapboxDraw ||
      !shouldUpdateMode(drawMode, mode, selectedFeatureIds, selectedCoordPaths)
    ) {
      return;
    }

    switch (mode) {
      case 'direct_select': {
        // After creating a feature, mapbox-gl-draw will immediately request to be
        // put into `direct_select` mode, with the new feature selected.
        if (selectedFeatureIds?.length === 1) {
          const modeOptions: DirectSelectOptions = {
            featureId: selectedFeatureIds[0],
            coordPath: selectedCoordPaths[selectedFeatureIds[0]],
          };
          drawMode.mapboxDraw.changeMode(mode, modeOptions);
        } else {
          // TODO: Can this even happen?
          drawMode.mapboxDraw.changeMode('simple_select', {
            featureIds: selectedFeatureIds,
          });
        }
        break;
      }
      case 'simple_select': {
        const modeOptions: SimpleSelectOptions = {
          featureIds: selectedFeatureIds,
        };
        drawMode.mapboxDraw.changeMode(mode, modeOptions);
        break;
      }
      case 'box_select_vertices': {
        const modeOptions: BoxSelectVerticesOptions = {
          features: featureEdits as Feature<Geometries>[],
        };
        drawMode.mapboxDraw.changeMode(mode, modeOptions);
        break;
      }
      case 'draw_line_string':
      case 'draw_point':
      case 'draw_polygon':
        // TODO: Make an independent case for 'draw_line_string', as it can be
        // used to further edit existing lines
        drawMode.mapboxDraw.changeMode(mode, {});
        break;
      default:
        typeAssertNever(mode);
    }
  }, [
    mode,
    drawMode,
    selectedFeatureIds,
    featureEdits,
    selectedCoordPaths,
    drawMode?.mapboxDraw,
    selectedFeatureIds?.length,
  ]);
}

/**
 * Should we update the mode, given our current expected state?
 */
function shouldUpdateMode(
  drawMode: DrawMode,
  nextMode: Mode<'box_select_vertices'>,
  nextSelectedFeatureIds: string[],
  nextSelectedCoordPathsByFeature: CoordPathsByFeature,
): boolean {
  const currentMode = drawMode.mapboxDraw.getMode();

  // did the mode change?
  if (currentMode !== nextMode) {
    return true;
  }

  // we're in the same mode, but maybe the feature selection has changed?
  const currentSelectedIds = drawMode.mapboxDraw.getSelectedIds();
  if (
    !_.isEqual(nextSelectedFeatureIds, currentSelectedIds) &&
    // This is an awkward mode we get into when we have just finished drawing a
    // feature: mapbox-draw thinks some features are selected, but useDraw
    // doesn't know about them yet, so useDraw thinks we should clear the
    // selection. Unfortunatley this means we cannot programmatically clear the
    // selection, only set it. TODO: come up with a better solution to handle
    // this transition state.
    !_.isEmpty(nextSelectedFeatureIds)
  ) {
    return true;
  }

  // maybe the selected vertices have changed?
  if (nextMode === 'direct_select') {
    const currentSelectedPoints = coordAll(
      drawMode.mapboxDraw.getSelectedPoints(),
    );

    // `direct_select` can only have a single selected feature, so this is safe
    const selectedFeatureId = nextSelectedFeatureIds[0];
    const nextSelectedCoordPaths =
      nextSelectedCoordPathsByFeature[selectedFeatureId];

    // This is sort of a hack, because we're not actually comparing the
    // coordPath values here, but it's not really possible without tracking both
    // coordPaths and LngLats in redux, which requires keeping them both in sync
    // as users drag and drop the features around.
    if (currentSelectedPoints?.length !== nextSelectedCoordPaths?.length) {
      return true;
    }
  }
}

/**
 * Dynamically update the event listeners if they change.
 */
function useDrawEventListeners(
  drawMode: DrawMode,
  onCreateFeatures: (features: Feature[]) => Feature[],
  onUpdateFeature: (feature: Feature) => void,
  onSelectFeatures: (
    features: Feature[],
    coordPaths: CoordPathsByFeature,
  ) => void,
  onSetDrawingMode: (mode: Mode, modeOptions: ModeOptions) => void,
) {
  // TODO: generalize some of this to account for the full suite of drawing events
  const onCreate = useCallback(
    (e: CreateEvent) => {
      const { features } = e;
      const newFeatures = onCreateFeatures(features);
      newFeatures.forEach(feature => {
        // Initialize the properties in MapboxDraw
        Object.entries(feature.properties).forEach(([key, value]) => {
          drawMode.mapboxDraw.setFeatureProperty(
            feature.id as string,
            key,
            value,
          );
        });
      });
    },
    [drawMode, onCreateFeatures],
  );
  useEffect(() => {
    if (!drawMode?.mapboxDraw) {
      return;
    }
    drawMode.on('onCreate', onCreate);
  }, [drawMode, drawMode?.mapboxDraw, onCreate]);

  const onUpdate = useCallback(
    (e: UpdateEvent) => {
      const { features } = e;
      features.forEach(feature => {
        onUpdateFeature(feature);
      });
    },
    [onUpdateFeature],
  );
  useEffect(() => {
    if (!drawMode?.mapboxDraw) {
      return;
    }
    drawMode.on('onUpdate', onUpdate);
  }, [drawMode, drawMode?.mapboxDraw, onUpdate]);

  const onSelectionChange = useCallback(
    (e: SelectionChangeEvent) => {
      const { features = [], points = [] } = e;

      // Turn the selection points into coordPaths
      const coordPaths: CoordPathsByFeature = _.mapValues(
        _.keyBy(features, 'id'),
        feature => {
          const coords = extractFeatureCoordinates(feature);
          return points.map(point => {
            return coords
              .findIndex(coord => _.isEqual(coord, point.geometry.coordinates))
              .toString();
          });
        },
      );

      onSelectFeatures(features, coordPaths);
    },
    [onSelectFeatures],
  );
  useEffect(() => {
    if (!drawMode?.mapboxDraw) {
      return;
    }
    drawMode.on('onSelectionChange', onSelectionChange);
  }, [drawMode, drawMode?.mapboxDraw, onSelectionChange]);

  const onModeChange = useCallback(
    (e: ModeChangeEvent) => {
      const { mode, target: map, modeOptions } = e;
      setCursorForMode(map, mode);
      onSetDrawingMode(mode, modeOptions);
    },
    [onSetDrawingMode],
  );
  useEffect(() => {
    if (!drawMode?.mapboxDraw) {
      return;
    }
    drawMode.on('onModeChange', onModeChange);
  }, [drawMode, drawMode?.mapboxDraw, onModeChange]);
}

/**
 * An internal hook to keep draw mode in sync with the features in `featureEdits`
 *
 * @param featureEdits Features that should be in the editor
 * @param drawMode The DrawMode
 */
function useUpdateFeatureList(featureEdits: Feature[], drawMode: DrawMode) {
  const featureIds = useMemo(
    () => featureEdits.map(({ id }) => id),
    [featureEdits],
  );
  const previousIds = usePrevious(featureIds, _.isEqual);
  const featureListChanged = previousIds !== featureIds;
  const previousFeatureEdits = usePrevious(featureEdits, _.isEqual);
  useEffect(() => {
    if (drawMode?.mapboxDraw && featureListChanged) {
      const newFeatureIds = _.difference(featureIds, previousIds);
      const removedFeatureIds = _.difference(previousIds, featureIds);
      const newFeatures = featureEdits.filter(feature =>
        newFeatureIds.includes(feature.id),
      );

      newFeatures.forEach(feature => drawMode.mapboxDraw.add(feature));

      const updatedFeatures = _.differenceBy(
        featureEdits,
        previousFeatureEdits,
        'geometry',
      );
      updatedFeatures.forEach(feature => drawMode.mapboxDraw.add(feature));

      drawMode.mapboxDraw.delete(removedFeatureIds as string[]);
    }
  }, [
    featureListChanged,
    featureEdits,
    previousFeatureEdits,
    previousIds,
    featureIds,
    drawMode,
    drawMode?.mapboxDraw,
  ]);
}

function setupMapForEdit(drawMode: DrawMode, featureEdits: Feature[]) {
  const div = drawMode.map.getCanvasContainer();
  div.style.cursor = 'pointer';
  // Add all currently edited features to mapbox-draw
  drawMode.mapboxDraw.set({
    type: 'FeatureCollection',
    features: featureEdits,
  });
}

function setCursorForMode(map: Map, mode: Mode) {
  const div = map.getCanvasContainer();
  switch (mode) {
    case 'direct_select':
    case 'simple_select':
      div.style.cursor = 'pointer';
      break;
    case 'draw_line_string':
    case 'draw_point':
    case 'draw_polygon':
      div.style.cursor = 'crosshair';
      break;
    default:
      typeAssertNever(mode);
  }
}
