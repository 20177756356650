import {
  CuratedColumnSymbology,
  InterpolationTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/public/dataset/mbta_transit_lines_greater_boston': {
    line: [
      {
        display_hints: {
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          scale: SymbologyScales.CATEGORICAL,
          theme: 'custom',
        },
        paint: {
          'line-color': {
            default: '#8c564b',
            property: 'line',
            stops: [
              {
                color: '#1f77b4',
                value: 'BLUE',
              },
              {
                color: '#68bc00',
                value: 'GREEN',
              },
              {
                color: '#fe9200',
                value: 'ORANGE',
              },
              {
                color: '#d62728',
                value: 'RED',
              },
              {
                color: '#999999',
                value: 'SILVER',
              },
            ],
            type: 'categorical',
          },
          'line-opacity': 0.9,
          'line-width': 2,
        },
        type: SymbologyTypes.LINE,
      },
    ],
  },
};

export default symbologies;
