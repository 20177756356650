import colors from 'material-colors';

import {
  CuratedColumnSymbology,
  CUSTOM_THEME,
  DEFAULT_OPACITY,
  InterpolationTypes,
  PaintColorTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const landUseLayers = [
  {
    layerId: '/public/dataset/ambag_2015_land_use___ambag',
    columnKey: '2015_bcs',
  },
  {
    layerId: '/public/dataset/ambag_2040_land_use___ambag',
    columnKey: '2040_futur',
  },
];

const symbologies: Record<
  string,
  Record<string, CuratedColumnSymbology[]>
> = {};

landUseLayers.forEach(({ layerId, columnKey }) => {
  symbologies[layerId] = {
    [columnKey]: [
      {
        type: SymbologyTypes.FILL,
        paint: {
          'fill-color': {
            property: `${columnKey}`,
            type: PaintColorTypes.CATEGORICAL,
            stops: [
              { value: 'AT', color: '#999999' },
              { value: 'IND', color: '#009ce0' },
              { value: 'INS', color: '#ca7af5' },
              { value: 'N/A', color: '#cccccc' },
              { value: 'NU-1', color: '#a4dd00' },
              { value: 'NU-2', color: '#d4a3a3' },
              { value: 'NU-3', color: '#ffdd8f' },
              { value: 'NU-4', color: '#ffffcf' },
              { value: 'OSR', color: '#3e9c79' },
              { value: 'S-1', color: colors.yellow.a200 },
              { value: 'S-2', color: '#d4cd00' },
              { value: 'S-3', color: '#ff614d' },
              { value: 'S-4', color: '#bf0000' },
              { value: 'S-5', color: '#bd6f6f' },
              { value: 'S-6', color: '#ffaa00' },
              { value: 'T-1', color: '#e3c78f' },
              { value: 'T-2', color: '#c2a978' },
              { value: 'T-3', color: '#d33115' },
              { value: 'T-4', color: '#e67a00' },
              { value: 'U-1', color: '#9e7d57' },
              { value: 'U-2', color: '#594611' },
              { value: 'U-3', color: '#730000' },
              { value: 'U-4', color: '#bd5800' },
            ],
          },
          'fill-opacity': DEFAULT_OPACITY,
        },
        display_hints: {
          scale: SymbologyScales.CATEGORICAL,
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          theme: CUSTOM_THEME,
        },
      },
    ],
  };
});

export default symbologies;
