import _ from 'lodash';
import { Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { addAppMessage } from 'uf/appservices/actions';
import { BuildInfo } from 'uf/base/buildinfo';
import { normalizeError } from 'uf/base/swagger';
import { ApiWrapper, SwaggerThunkExtra, xhrErrorToJSError } from 'uf/base/xhr';
import { isLoading } from 'uf/data/dataState';
import { makeAsyncAction } from 'uf/data/loader';
import { LayerId } from 'uf/layers';
import { registerPersistedAction } from 'uf/persistence';
import { ProjectId } from 'uf/projects';
import { removeWorkingLayer } from 'uf/projects/actions';
import { getProjectList } from 'uf/projects/selectors';
import { ScenarioId } from 'uf/scenarios';
import { getLastActiveProject } from 'uf/user/actions/projects';
import { getLastActiveProjectState } from 'uf/user/selectors/projects';
import { getUserKey } from 'uf/user/selectors/user';

import {
  ACTIVE_PROJECT_READY,
  ActiveProjectReadyAction,
  APP_INITIALIZED,
  AppInitializedAction,
  CLEAR_ACTIVE_PROJECT,
  ClearActiveProjectAction,
  FRONTEND_VERSION_MISMATCH,
  FrontendVersionMismatchAction,
  loadFrontendVersionActionTypes,
  PAGE_INITIALIZED,
  SET_ACTIVE_PROJECT,
  SET_ACTIVE_SCENARIO,
  SetActiveProjectAction,
  SetActiveScenarioAction,
  SHOW_APP_TOASTS,
  ShowAppToastsAction,
} from './ActionTypes';
import { getActiveProjectId } from './selectors';

// used to signal that a page has been loaded client side. This happens before
// the APP_INITIALIZED method. This is fired on ALL pages, including error
// pages.
export function pageInitialized() {
  return {
    type: PAGE_INITIALIZED,
  };
}

// used to signal that a working app has been loaded client side.  this is
// picked up by some of our third-party integrations that need to kick off after
// state has been loaded.
export function appInitialized(): AppInitializedAction {
  return {
    type: APP_INITIALIZED,
  };
}

function fetchFrontendVersion(): Promise<BuildInfo> {
  return fetch('/version.json').then(response => response.json());
}

export function checkServer(): ThunkAction<
  Promise<ApiWrapper>,
  any,
  SwaggerThunkExtra,
  any
> {
  return (dispatch: Dispatch, getState, { client }) => {
    return client.clientReady().catch(error => {
      // TODO: reconcile this with normalizeError
      try {
        const appError = xhrErrorToJSError(error);
        dispatch(addAppMessage(appError, { level: 'danger', timeout: 0 }));
      } catch (ex) {
        const defaultMessage = 'Unknown Server Error';
        const { errObj = { message: defaultMessage } } = error;
        const { message } = errObj;
        dispatch(addAppMessage(message, { level: 'danger', timeout: 0 }));
      }
      return null;
    });
  };
}
export const loadFrontendVersion = makeAsyncAction(
  loadFrontendVersionActionTypes,
  fetchFrontendVersion,
);

interface SetActiveProjectOptions {
  refreshMap?: boolean;
  redirectToExplore?: boolean;
  forceProjectReload?: boolean;
}

const defaultSetActiveProjectOptions: SetActiveProjectOptions = {
  refreshMap: false,

  // By default, always redirect to explore when the active project is set.
  // This gets overidden when the app is loaded into a /bf route and the
  // libraryId in the route causes a setActiveProject to fire.
  // (See uf/ui/builtforms/routes.js)
  redirectToExplore: true,

  forceProjectReload: false,
};

export function setActiveProject(
  projectId: ProjectId,
  options = defaultSetActiveProjectOptions,
): SetActiveProjectAction {
  return {
    type: SET_ACTIVE_PROJECT,
    property: 'activeProjectId',
    value: projectId,
    ...defaultSetActiveProjectOptions,
    ...options,
  };
}

/**
 * Make sure that a project is selected.
 * This generally is fired at startup after projects have loaded.
 */
export function ensureActiveProject(options = defaultSetActiveProjectOptions) {
  return (dispatch: Dispatch, getState) => {
    // if we already have a last active project, escape
    const activeProjectId = getActiveProjectId(getState());
    const lastActiveProjectState = getLastActiveProjectState(getState());
    const lastActiveProjectLoading = isLoading(lastActiveProjectState);
    const projects = getProjectList(getState());

    if (activeProjectId) {
      const projectExists = projects.some(
        project => project.full_path === activeProjectId,
      );
      if (projectExists) {
        return;
      }
      dispatch(
        addAppMessage(
          'The specified project could not be found – rerouting to your default view',
          { status: 'failure' },
        ),
      );
    }

    if (lastActiveProjectLoading) {
      const lastActiveProjectId = lastActiveProjectState?.data?.value?.value;
      if (lastActiveProjectId) {
        dispatch(setActiveProject(lastActiveProjectId, options));
      }
      return lastActiveProjectState.promise;
    }

    // otherwise check the server for user data
    return dispatch(getLastActiveProject(getUserKey(getState())))
      .then(response => {
        const lastActiveProjectId = response?.value?.value;
        // make sure project wasn't deleted
        const projectExists = projects.some(
          project => project.full_path === lastActiveProjectId,
        );

        // set the active project to the last project the user selecte
        if (lastActiveProjectId && projectExists) {
          dispatch(setActiveProject(lastActiveProjectId, options));
          return;
        }

        // otherwise set the active project to the first project in the users's project list
        const firstProject = _.head(projects);
        if (firstProject) {
          dispatch(setActiveProject(firstProject.full_path, options));
        }
      })
      .catch(normalizeError);
  };
}

export function activeProjectReady(
  projectId: ProjectId,
): ActiveProjectReadyAction {
  return {
    type: ACTIVE_PROJECT_READY,
    projectId,
  };
}

const defaultClearActiveProjectOptions: SetActiveProjectOptions = {
  refreshMap: false,

  // By default, always redirect to explore when the active project is set.
  // This gets overidden when the app is loaded into a /bf route and the
  // libraryId in the route causes a setActiveProject to fire.
  // (See uf/ui/builtforms/routes.js)
  redirectToExplore: true,

  forceProjectReload: false,
};

export function clearActiveProject(
  options = defaultClearActiveProjectOptions,
): ClearActiveProjectAction {
  return {
    type: CLEAR_ACTIVE_PROJECT,
    ...options,
  };
}

export function setActiveScenario(
  projectId: ProjectId,
  scenarioId: ScenarioId,
): SetActiveScenarioAction {
  return {
    type: SET_ACTIVE_SCENARIO,
    projectId,
    property: projectId,
    value: scenarioId,
  };
}

registerPersistedAction<SetActiveScenarioAction, string, string>(
  'activeScenarioId',
  SET_ACTIVE_SCENARIO,
  (projectId, scenarioId) => setActiveScenario(projectId, scenarioId),
  {
    getKey({ property: projectId }) {
      return projectId;
    },

    getValue({ value: scenarioId }) {
      return scenarioId;
    },
  },
);

export function removeWorkingLayerFromActiveProject(layerId: LayerId) {
  return (dispatch: Dispatch, getState) => {
    const activeProjectId = getActiveProjectId(getState());
    return dispatch(removeWorkingLayer(activeProjectId, layerId));
  };
}

export function showAppToasts(value: boolean): ShowAppToastsAction {
  return {
    type: SHOW_APP_TOASTS,
    value,
  };
}

export function frontendVersionMismatch(): FrontendVersionMismatchAction {
  return {
    type: FRONTEND_VERSION_MISMATCH,
  };
}
