import { combineReducers } from 'redux';

import { makeKeyedListReducer } from 'uf/base/reducerFactories';
import { presenceActionTypes } from 'uf/collaboration/ActionTypes';
import { Collaborator } from 'uf/collaboration/Collaborator';

// we key the presence of users by their websocket connection because a user might have more than
// one application open in the same session (i.e. multiple tabs).  it is up to the consumers of this
// state to dedupe this list appropriately.
export default combineReducers({
  projects: makeKeyedListReducer<Collaborator, 'key', string>(
    presenceActionTypes,
    'key',
    collaborator => collaborator.connectionId,
  ),
});
