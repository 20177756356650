import { LayerId } from './';
import { FilterSpec, getLayerDataApiParams } from './filters';

export interface LayerBoundsParams {
  layerId: LayerId;
  version: string;
  filters?: Partial<FilterSpec>;
}

/**
 * Make redux key for layer bounds call
 */
export function makeLayerBoundsKey(params: LayerBoundsParams) {
  const { layerId, version, filters } = params;

  const { key } = getLayerDataApiParams(layerId, { version, filters });
  return key;
}
