import { AnyAction } from 'redux';

import { EMPTY_ARRAY } from 'uf/base';
import { getNextId } from 'uf/base/ids';
import {
  ADD_CHART,
  REMOVE_CHART,
  SET_ACTIVE_CHART_TYPE_MODE,
  SET_HISTOGRAM_FILTER_MODE,
  SET_HISTOGRAM_WEIGH_BY,
} from 'uf/explore/ActionTypes';
import { ChartTypeMode } from 'uf/explore/charts';

const initialState = {
  nextChartId: getNextId('chart'),
  activeChartTypeMode: ChartTypeMode.PROGRAM_LEVEL_SUMMARY,
  activeCharts: EMPTY_ARRAY,
};

export default function charts(
  state = initialState,
  action: AnyAction = {} as AnyAction,
) {
  switch (action.type) {
    case ADD_CHART:
      return {
        ...state,
        nextChartId: getNextId('chart', state.nextChartId),
        activeCharts: [
          ...state.activeCharts,
          {
            id: state.nextChartId,
            layerId: action.layerId,
            columnKey: action.columnKey,
            filters: action.filters,
            filterMode: action.filterMode,
          },
        ],
      };

    case REMOVE_CHART: {
      const chartIndex = state.activeCharts.findIndex(
        chart => chart.id === action.chartId,
      );
      if (chartIndex === -1) {
        console.error(`No chart found in ${action.layerId}: ${action.chartId}`);
        return state;
      }
      return {
        ...state,
        activeCharts: state.activeCharts.filter(
          chart => chart.id !== action.chartId,
        ),
      };
    }

    case SET_HISTOGRAM_FILTER_MODE: {
      const { chartId, filterMode } = action;
      const chartIndex = state.activeCharts.findIndex(
        chart => chart.id === chartId,
      );
      if (chartIndex === -1) {
        console.error(`No chart found in ${action.layerId}: ${action.chartId}`);
        return state;
      }
      const newChart = {
        ...state.activeCharts[chartIndex],
        filterMode,
      };
      return {
        ...state,
        activeCharts: [
          ...state.activeCharts.slice(0, chartIndex),
          newChart,
          ...state.activeCharts.slice(chartIndex + 1),
        ],
      };
    }

    case SET_HISTOGRAM_WEIGH_BY: {
      const { chartId, columnKey } = action;
      const chartIndex = state.activeCharts.findIndex(
        chart => chart.id === chartId,
      );
      if (chartIndex === -1) {
        console.error(`No chart found in ${action.layerId}: ${action.chartId}`);
        return state;
      }
      const newChart = {
        ...state.activeCharts[chartIndex],
        weighByColumnKey: columnKey,
      };
      return {
        ...state,
        activeCharts: [
          ...state.activeCharts.slice(0, chartIndex),
          newChart,
          ...state.activeCharts.slice(chartIndex + 1),
        ],
      };
    }

    // TODO: deprecated.  We no longer have a button group that toggles between
    // these so we don't need 'modes'.  remove this with #9027
    case SET_ACTIVE_CHART_TYPE_MODE:
      return {
        ...state,
        activeChartTypeMode: action.chartTypeMode,
      };

    default:
      return state;
  }
}
