import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
  useGetUserDataQuery,
  useSetUserDataMutation,
} from 'uf-api-rtk/store/Api';
import {
  getActiveProjectId,
  selectActiveLayerId,
  selectActiveViewId,
} from 'uf/app/selectors';
import {
  COLUMN_FREEZE,
  COLUMN_REORDER,
  COLUMN_SORT,
  COLUMN_UNFREEZE,
} from 'uf/explore/logging';
import { ColumnKey } from 'uf/layers';
import { logEvent } from 'uf/logging';
import { getUser } from 'uf/user/selectors/user';

export enum SortDirection {
  ASC = 'asc',
  DESC = 'desc',
}

export interface Column {
  key: string;
  isFavorite?: boolean;
  isFrozen?: boolean;
  width?: number;
}

interface PersistedColumnState {
  columns?: Column[];
  sortColumn?: string;
  sortDirection?: SortDirection;
}

interface NormalizedColumnState {
  entities: Record<string, Column>;
  ids: ColumnKey[];
  columns?: Column[];
  sortColumn?: string;
  sortDirection?: SortDirection;
}

const useColumnState = () => {
  const viewId = useSelector(selectActiveViewId);
  const layerId = useSelector(selectActiveLayerId);
  const projectId = useSelector(getActiveProjectId);
  const user = useSelector(getUser);
  const userKey = user.key;
  const userDataKey = `${userKey}:columnState:${projectId}${
    viewId ? `|${viewId}` : ''
  }|${layerId}`;
  const { currentData } = useGetUserDataQuery(
    { userKey, key: userDataKey },
    { skip: !projectId || !layerId },
  );
  const [setUserData] = useSetUserDataMutation();
  const {
    entities: columnsById = {},
    ids = [],
    columns = [],
    sortColumn,
    sortDirection,
  } = (currentData ?? {}) as NormalizedColumnState;
  // Map any previously set column state and fallback to keys for any unset columns
  const getMappedColumnsByKeys = useCallback(
    columnKeys => columnKeys.map(key => columnsById[key] || { key }),
    [columnsById],
  );
  const updateUserData = useCallback(
    (newData: PersistedColumnState) => {
      void setUserData({
        userKey,
        key: userDataKey,
        body: {
          columns,
          sortColumn,
          sortDirection,
          ...newData,
        },
      });
    },
    [columns, setUserData, sortColumn, sortDirection, userDataKey, userKey],
  );
  // Handles toggling favorites and setting column width
  const setColumnState = useCallback(
    (columnKey: ColumnKey, newState: Partial<Column>) => {
      // If there's no persisted column state for the selected view & layer
      if (!currentData) {
        updateUserData({ columns: [{ key: columnKey, ...newState }] });
      } else {
        const columnIndex = ids.findIndex(id => id === columnKey);
        const newColumns = [...columns];
        if (columnIndex > -1) {
          newColumns[columnIndex] = {
            key: columnKey,
            ...columnsById[columnKey],
            ...newState,
          };
        } else {
          newColumns.push({ key: columnKey, ...newState });
        }
        updateUserData({ columns: newColumns });
      }
    },
    [currentData, updateUserData, ids, columns, columnsById],
  );
  const setColumnOrder = (orderedColumns: Column[]) => {
    updateUserData({ columns: orderedColumns });
    logEvent(COLUMN_REORDER, {
      layerId,
      columnKeys: orderedColumns.map(col => col.key),
    });
  };
  const setFrozenColumns = (
    frozenColumn: ColumnKey,
    orderedColumns: Column[],
    didFreeze: boolean,
  ) => {
    updateUserData({ columns: orderedColumns });

    logEvent(didFreeze ? COLUMN_FREEZE : COLUMN_UNFREEZE, {
      layerId,
      columnKey: frozenColumn,
    });
  };
  const setSortColumn = (
    newSortColumn: ColumnKey,
    newSortDirection: SortDirection,
  ) => {
    // Clear sorting state if toggling the same sort column and director
    if (sortColumn === newSortColumn && sortDirection === newSortDirection) {
      updateUserData({ sortColumn: null, sortDirection: null });
    } else {
      updateUserData({
        sortColumn: newSortColumn,
        sortDirection: newSortDirection,
      });
    }
    logEvent(COLUMN_SORT, {
      columnKey: newSortColumn,
      layerId,
    });
  };
  return {
    setColumnState,
    setColumnOrder,
    setSortColumn,
    setFrozenColumns,
    getMappedColumnsByKeys,
    columnsById,
    ids,
    sortColumn,
    sortDirection,
  };
};

export default useColumnState;
