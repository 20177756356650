import { createSelector } from 'reselect';

import { LayerMetadata, LayerStats } from 'uf-api';
import { makeGetFromPropsSelector } from 'uf/base/selector';
import { DataState, getData, isLoaded } from 'uf/data/dataState';
import { ColumnKey, LayerId } from 'uf/layers';
import { getRootLayerId } from 'uf/layers/metadata';
import {
  makeGetLayerMetadataState,
  makeGetLayerMetadataStateGetter,
} from 'uf/layers/selectors/metadata';
import { ProjectId } from 'uf/projects';
import { LayerColumnSymbology } from 'uf/symbology';
import { getCleanedSymbologyDataState } from 'uf/symbology/selectors/curated/clean';
import {
  makeGetCuratedSymbologyLoadingState,
  makeGetCuratedSymbologyLoadingStateGetter,
} from 'uf/symbology/selectors/curated/loadingState';
import {
  makeGetSymbologyStatsState,
  makeGetSymbologyStatsStateGetter,
} from 'uf/symbology/selectors/stats';
import LayerSymbologies from 'uf/symbology/styles/LayerSymbologies';
import { makeGetUserFlag } from 'uf/user/selectors/flags';

/**
 * Lookup a curated symbology by direct match on layerId + columnKey.
 */
export function makeGetCuratedSymbologyFromLayerSymbologiesFile() {
  return createSelector(
    makeGetCuratedSymbologyLoadingState(),
    makeGetFromPropsSelector<LayerId, 'layerId'>('layerId'),
    makeGetFromPropsSelector<ColumnKey, 'columnKey'>('columnKey'),
    makeGetLayerMetadataState(),
    makeGetSymbologyStatsState(),
    makeGetUserFlag('symbology-symbols'),
    (
      loadingState,
      layerId,
      columnKey,
      layerMetadataState,
      layerStatsState,
      hasSymbolSupportFlag,
    ): DataState<LayerColumnSymbology[]> => {
      return getCuratedSymbologyFromLayerSymbologiesFiles(
        layerId,
        columnKey,
        loadingState,
        layerMetadataState,
        layerStatsState,
        hasSymbolSupportFlag,
      );
    },
  );
}
export function makeGetCuratedSymbologyFromLayerSymbologiesFileGetter() {
  return createSelector(
    makeGetCuratedSymbologyLoadingStateGetter(),
    makeGetLayerMetadataStateGetter(),
    makeGetSymbologyStatsStateGetter(),
    makeGetUserFlag('symbology-symbols'),
    (
        getLoadingState,
        getLayerMetadataState,
        getLayerStatsState,
        hasSymbolSupportFlag,
      ) =>
      (
        projectId: ProjectId,
        layerId: LayerId,
        columnKey: ColumnKey,
      ): DataState<LayerColumnSymbology[]> => {
        const loadingState = getLoadingState(projectId, layerId, columnKey);
        const layerMetadataState = getLayerMetadataState(layerId);
        const layerStatsState = getLayerStatsState(
          projectId,
          layerId,
          columnKey,
        );
        return getCuratedSymbologyFromLayerSymbologiesFiles(
          layerId,
          columnKey,
          loadingState,
          layerMetadataState,
          layerStatsState,
          hasSymbolSupportFlag,
        );
      },
  );
}

function getCuratedSymbologyFromLayerSymbologiesFiles(
  layerId: LayerId,
  columnKey: ColumnKey,
  loadingState: DataState<null>,
  layerMetadataState: DataState<LayerMetadata>,
  layerStatsState: DataState<LayerStats>,
  hasSymbolSupportFlag: boolean,
): DataState<LayerColumnSymbology[]> {
  if (!isLoaded(loadingState)) {
    return loadingState;
  }

  const layerMetadata = getData(layerMetadataState);
  const layerStats = getData(layerStatsState);

  // If we have an exact match for this layer we'll use it
  const hardcodedSymbologies = findLayerSymbologies(layerId, columnKey);
  if (hardcodedSymbologies) {
    return getCleanedSymbologyDataState(
      layerId,
      columnKey,
      layerMetadata,
      layerStats,
      hardcodedSymbologies,
      loadingState,
      hasSymbolSupportFlag,
    );
  }

  // Otherwise we'll see if we have a match for the root layer
  const rootLayerId = getRootLayerId(layerMetadata);
  if (rootLayerId) {
    const rootLayerHardcodedSymbologies = findLayerSymbologies(
      rootLayerId,
      columnKey,
    );

    if (rootLayerHardcodedSymbologies) {
      return getCleanedSymbologyDataState(
        layerId,
        columnKey,
        layerMetadata,
        layerStats,
        rootLayerHardcodedSymbologies,
        loadingState,
        hasSymbolSupportFlag,
      );
    }
  }

  return loadingState;
}

function findLayerSymbologies(layerId: LayerId, columnKey: ColumnKey) {
  return LayerSymbologies?.[layerId]?.[columnKey];
}
