import { AnyAction } from 'redux';
import { ActionsObservable } from 'redux-observable';
import { map } from 'rxjs/operators';

import { PAGE_INITIALIZED } from 'uf/app/ActionTypes';
import { combineEpics } from 'uf/base/epics';

import { initializeAmplitude as initializeAmplitudeAction } from './actions';

export function initializeAmplitude(action$: ActionsObservable<AnyAction>) {
  return action$
    .ofType(PAGE_INITIALIZED)
    .pipe(map(() => initializeAmplitudeAction()));
}

export default combineEpics({ initializeAmplitude }, 'amplitude');
