import { Action } from 'redux';

import { TypedUserData } from 'uf/user/state';

export const ENABLE_SAVE_PERSISTENCE = 'uf/persistence/ENABLE_SAVE_PERSISTENCE';
export interface EnableSavePersistenceAction {
  type: typeof ENABLE_SAVE_PERSISTENCE;
  value: boolean;
}
export const ENABLE_LOAD_PERSISTENCE = 'uf/persistence/ENABLE_LOAD_PERSISTENCE';
export interface EnableLoadPersistenceAction {
  type: typeof ENABLE_LOAD_PERSISTENCE;
  value: boolean;
}

export const PREF_LOADED = 'uf/persistence/PREF_LOADED';
export interface PrefLoadedAction<T extends object = any> extends Action {
  type: typeof PREF_LOADED;
  key: string;
  value: TypedUserData<T>;
}

export const PREF_PERSIST = 'uf/persistence/PREF_PERSIST';
export interface PersistPrefAction extends Action {
  type: typeof PREF_PERSIST;
  key: string;
  value: any;
}
