import { risonEncode } from 'uf/base/rison';
import { ProjectId } from 'uf/projects';
import { ScenarioId } from 'uf/scenarios';

enum ParameterKeyString {}
export type ParameterKey = ParameterKeyString | string;
export function makeAnalysisSchemaKey(
  projectId: ProjectId,
  moduleKey: string,
): string {
  return risonEncode({ projectId, moduleKey });
}

// TODO: Move to uf/project
export function makeAnalysisParameterKey(
  projectId: ProjectId,
  scenarioKey: string,
  moduleKey: string,
): string {
  return risonEncode({ projectId, scenarioKey, moduleKey });
}

export interface AnalysisParameterActionInfo {
  projectId: ProjectId;
  scenarioId: ScenarioId;
  moduleKey: string;
}
