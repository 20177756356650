import { AnyAction } from 'redux';
import { GeocodeResponse } from '@mapbox/mapbox-sdk/services/geocoding';
import { forwardGecodeActionTypes, SELECT_FEATURE } from 'uf/mapi/ActionTypes';
import { MapiState } from 'uf/mapi/state';

const initialState: MapiState = Object.freeze<MapiState>({
  query: '',
  status: 'LOADING',
});

export default (
  state: MapiState = initialState,
  action: AnyAction,
): MapiState => {
  switch (action.type) {
    case SELECT_FEATURE:
      return {
        ...state,
        query: action.query,
      };
    case forwardGecodeActionTypes.LOAD:
      return {
        ...state,
        query: action.query,
        status: 'LOADING',
      };

    case forwardGecodeActionTypes.SUCCESS:
      if (state.query !== action.query) {
        return state;
      }
      return {
        ...state,
        status: 'LOADED',
        results: action.result.body as GeocodeResponse,
      };

    case forwardGecodeActionTypes.CLEAR:
      return initialState;

    /**
     * We don't save any map errors in state, but we can still use them in Epics for showing
     * snackbars, etc..
     */
    case forwardGecodeActionTypes.FAILURE:
      return state;

    default:
      return state;
  }
};
