import {
  MapExportControlSettings,
  MapExportDisplaySettings,
  MapExportOutputSettings,
} from 'uf-api';
import { Dimensions, DimensionType, UFLngLatBounds } from 'uf/base/map';
import { LayerId } from 'uf/layers';
import { MapViewport } from 'uf/map';
import { MapExportTitle } from 'uf/mapexport/state';
import { ProjectId } from 'uf/projects';
import { makeWebsocketActionTypes } from 'uf/websockets/actionHelpers';

import { mapExport } from './websocketMessages';

export const DOWNLOAD_STARTED = 'uf/mapexport/DOWNLOAD_STARTED';
export interface MapExportDownloadStarted {
  type: typeof DOWNLOAD_STARTED;
}

export const SET_INCLUDE_BASEMAP = 'uf/mapexport/SET_INCLUDE_BASEMAP';
export interface SetIncludeBaseMapAction {
  type: typeof SET_INCLUDE_BASEMAP;
  includeBaseMap: boolean;
}

export const SET_EXPORT_FORMAT = 'uf/mapexport/SET_EXPORT_FORMAT';
export interface SetExportFormatAction {
  type: typeof SET_EXPORT_FORMAT;
  exportFormat: MapExportOutputSettings.FormatEnum;
}

export const SET_LEGEND_PLACEMENT = 'uf/mapexport/SET_LEGEND_PLACEMENT';
export interface SetLegendPlacementAction {
  type: typeof SET_LEGEND_PLACEMENT;
  legendPlacement: MapExportControlSettings.LegendTypeEnum;
}

export const SET_LEGEND_CLASSES_TO_INCLUDE =
  'uf/mapexport/SET_LEGEND_CLASSES_TO_INCLUDE';
export interface SetLegendClassesToIncludeAction {
  type: typeof SET_LEGEND_CLASSES_TO_INCLUDE;
  legendClassesToInclude: MapExportControlSettings.LegendClassesToIncludeEnum;
}
export const SET_MAP_EXPORT_LEGEND_LAYER =
  'uf/mapexport/SET_MAP_EXPORT_LEGEND_LAYER';
export interface SetMapExportLegendLayerAction {
  type: typeof SET_MAP_EXPORT_LEGEND_LAYER;
  layerId: LayerId;
  include: boolean;
}

export const CLEAR_MAP_EXPORT_LEGEND_LAYERS =
  'uf/mapexport/CLEAR_MAP_EXPORT_LEGEND_LAYERS';
export interface ClearMapExportLegendLayersAction {
  type: typeof CLEAR_MAP_EXPORT_LEGEND_LAYERS;
}

export const SET_MAP_SCALEBAR_VISIBLE = 'uf/mapexport/SET_MAP_SCALEBAR_VISIBLE';
export interface SetMapScaleBarVisibleAction {
  type: typeof SET_MAP_SCALEBAR_VISIBLE;
  scaleBarVisible: boolean;
}

export const SET_MAP_COMPASS_VISIBLE = 'uf/mapexport/SET_MAP_COMPASS_VISIBLE';
export interface SetMapCompassVisibleAction {
  type: typeof SET_MAP_COMPASS_VISIBLE;
  compassVisible: boolean;
}

export const SET_MAP_TITLE = 'uf/mapexport/SET_MAP_TITLE';
export interface SetMapTitleAction {
  type: typeof SET_MAP_TITLE;
  title: MapExportTitle;
}

export const SET_MAP_TITLE_VISIBLE = 'uf/mapexport/SET_MAP_TITLE_VISIBLE';
export interface SetMapTitleVisibleAction {
  type: typeof SET_MAP_TITLE_VISIBLE;
  titleVisible: boolean;
}

export const RESET_MAP_EXPORT = 'uf/mapexport/RESET_MAP_EXPORT';
export interface ResetMapExportAction {
  type: typeof RESET_MAP_EXPORT;
}

export const SET_ORIENTATION = 'uf/mapexport/SET_ORIENTATION';
export interface SetPageOrientationAction {
  type: typeof SET_ORIENTATION;
  pageOrientation: MapExportDisplaySettings.OrientationEnum;
}

export const SET_MAP_EXPORT_BOOKMARK = 'uf/mapexport/SET_MAP_EXPORT_BOOKMARK';
export interface SetMapExportBookmarkAction {
  type: typeof SET_MAP_EXPORT_BOOKMARK;
  key: string;
  projectId: ProjectId;
}

export const UPDATE_MAP_EXPORT_VIEWPORT =
  'uf/mapexport/UPDATE_MAP_EXPORT_VIEWPORT';
export interface UpdateMapExportViewportAction {
  type: typeof UPDATE_MAP_EXPORT_VIEWPORT;
  projectId: ProjectId;
  layerIds: LayerId[];
  viewport: Partial<MapViewport>;
}

export const SET_MAP_SCALE = 'uf/mapexport/SET_MAP_SCALE';
export interface SetMapExportScaleAction {
  type: typeof SET_MAP_SCALE;
  scale: number;
}

export const SET_MAP_SCALE_RATIO = 'uf/mapexport/SET_MAP_SCALE_RATIO';
export interface SetMapExportScaleRatioAction {
  type: typeof SET_MAP_SCALE_RATIO;
  scaleRatio: number;
}

export const SET_MAP_REQUESTED_SCALE = 'uf/mapexport/SET_MAP_REQUESTED_SCALE';
export interface SetMapExportRequestedScaleAction {
  type: typeof SET_MAP_REQUESTED_SCALE;
  requestedScale: number;
}

export const SET_MAP_RESOLUTION = 'uf/mapexport/SET_MAP_RESOLUTION';
export interface SetMapExportResolutionAction {
  type: typeof SET_MAP_RESOLUTION;
  resolution: number;
}

export const SET_MAP_EXPORT_BOUNDS = 'uf/mapexport/SET_MAP_EXPORT_BOUNDS';
export interface SetMapExportBoundsAction {
  type: typeof SET_MAP_EXPORT_BOUNDS;
  bounds: UFLngLatBounds;
}

export const SET_MAP_EXPORT_REQUESTED_BOUNDS =
  'uf/mapexport/SET_MAP_EXPORT_REQUESTED_BOUNDS';
export interface SetMapExportRequestedBoundsAction {
  type: typeof SET_MAP_EXPORT_REQUESTED_BOUNDS;
  requestedBounds: UFLngLatBounds;
}

export const SET_MAP_DIMENSION_TYPE = 'uf/mapexport/SET_MAP_DIMENSION_TYPE';
export interface SetMapExportDimensionTypeAction {
  type: typeof SET_MAP_DIMENSION_TYPE;
  dimensionType: DimensionType;
}

export const SET_MAP_DIMENSIONS = 'uf/mapexport/SET_MAP_DIMENSIONS';
export interface SetMapExportDimensionsAction {
  type: typeof SET_MAP_DIMENSIONS;
  dimensions: Dimensions;
}
export const SET_MAP_PHYSICAL_DIMENSIONS =
  'uf/mapexport/SET_MAP_PHYSICAL_DIMENSIONS';
export interface SetMapExportPhysicalDimensionsAction {
  type: typeof SET_MAP_PHYSICAL_DIMENSIONS;
  dimensions: Dimensions;
}

export const SET_MAP_EXPORT_LOGO = 'uf/mapexport/SET_LOGO';
export interface SetMapExportLogo {
  type: typeof SET_MAP_EXPORT_LOGO;
  logoKey: string;
}
export const SET_MAP_EXPORT_LOGO_VISIBLE = 'uf/mapexport/SET_LOGO_VISIBLE';
export interface SetMapExportLogoVisible {
  type: typeof SET_MAP_EXPORT_LOGO_VISIBLE;
  visible: boolean;
}

export const SET_MAP_EXPORT_NUM_GEOMETRIES = 'uf/mapexport/SET_NUM_GEOMETRIES';
export interface SetMapExportNumGeometriesAction {
  type: typeof SET_MAP_EXPORT_NUM_GEOMETRIES;
  value: number;
}
export const mapExportTaskActionTypes = makeWebsocketActionTypes(
  mapExport.MAPEXPORT_STARTED,
  mapExport.MAPEXPORT_DONE,
  mapExport.MAPEXPORT_ERROR,
);

const mapExportTaskActionTypesPrefix = 'uf/mapexport/task';
export const BIND_MAPEXPORT_TASK = `${mapExportTaskActionTypesPrefix}/BIND_ENQUEUE_MAP_EXPORT_TASK`;

// TODO: get rid of this pattern, we aren't using it anywhere else
export type MapExportActions =
  | ClearMapExportLegendLayersAction
  | ResetMapExportAction
  | SetExportFormatAction
  | SetIncludeBaseMapAction
  | SetLegendClassesToIncludeAction
  | SetLegendPlacementAction
  | SetMapCompassVisibleAction
  | SetMapExportBookmarkAction
  | SetMapExportBoundsAction
  | SetMapExportRequestedBoundsAction
  | SetMapExportDimensionTypeAction
  | SetMapExportDimensionsAction
  | SetMapExportLegendLayerAction
  | SetMapExportLogo
  | SetMapExportLogoVisible
  | SetMapExportPhysicalDimensionsAction
  | SetMapExportRequestedBoundsAction
  | SetMapExportRequestedScaleAction
  | SetMapExportResolutionAction
  | SetMapExportScaleAction
  | SetMapExportScaleRatioAction
  | UpdateMapExportViewportAction
  | SetMapScaleBarVisibleAction
  | SetMapTitleAction
  | SetMapTitleVisibleAction
  | SetPageOrientationAction
  | SetMapExportNumGeometriesAction;
