import { Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { UserAction } from 'uf-api';
import { canPerformActionsActionTypes } from 'uf-api/api/authentication.service';
import { isLoading, shouldLoad } from 'uf/data/dataState';
import { dispatchAsyncAction } from 'uf/data/loader';
import { PermissionAction } from 'uf/user/permissions';
import {
  makeGetCheckFeature,
  makeGetMatchingCanPerformStates,
} from 'uf/user/selectors/permissions';

import { canPerformActions } from './apis';

/**
 * A way of doing a one-off check at runtime. This vastly simplifies
 * the API, which takes an array of { verb, resource }.
 *
 * Example usage:
 *   loadCheckFeature('export', '/my/dataset/layerId')
 *     .then(success => this.setState({ canExport: true }))
 */
export function loadCheckFeature(verb: string, resource: string) {
  return loadCheckFeatures([{ verb, resource }]);
}

/**
 * Similar to loadCheckFeature, but allows querying multiple values.
 *
 * query is in the form [{ verb, resource }, { verb, resource }, ...]
 */
export function loadCheckFeatures(
  query: PermissionAction[],
): ThunkAction<Promise<UserAction[]>, any, any, any> {
  const queryKey = JSON.stringify(query);
  return (dispatch: Dispatch) =>
    dispatch(
      dispatchAsyncAction(
        canPerformActionsActionTypes,
        canPerformActions({
          actions: query.map(action => `${action.verb}:${action.resource}`),
        }),
        {
          extra: {
            timestamp: Date.now(),
            query,
          },
          key: queryKey,
        },
      ),
    );
}

export function ensureCheckFeatures(
  verbs: string[],
  resources: string[],
): ThunkAction<Promise<any[]>, any, any, any> {
  return (dispatch: Dispatch, getState) => {
    const permissionsChecks: Promise<any>[] = [];
    resources.forEach(resource => {
      verbs.forEach(verb => {
        const getMatchingCanPerformStates = makeGetMatchingCanPerformStates();
        const getCheckFeature = makeGetCheckFeature();
        const featureStates = getMatchingCanPerformStates(getState(), {
          verb,
          resource,
        });
        const firstLoading = featureStates.find(d => isLoading(d));

        if (featureStates.length === 0 || featureStates.some(shouldLoad)) {
          permissionsChecks.push(dispatch(loadCheckFeature(verb, resource)));
        } else if (firstLoading) {
          permissionsChecks.push(
            firstLoading.promise.then(() =>
              getCheckFeature(getState(), { verb, resource }),
            ),
          );
        } else {
          // If we get here, then there is at least one featureState, and
          // it is loaded.
          permissionsChecks.push(
            Promise.resolve(getCheckFeature(getState(), { verb, resource })),
          );
        }
      });
    });
    return Promise.all(permissionsChecks);
  };
}

export function ensureCheckFeature(verb: string, resource: string) {
  const getMatchingCanPerformStates = makeGetMatchingCanPerformStates();
  const getCheckFeature = makeGetCheckFeature();
  return (dispatch: Dispatch, getState) => {
    const featureStates = getMatchingCanPerformStates(getState(), {
      verb,
      resource,
    });

    if (featureStates.length === 0 || featureStates.some(shouldLoad)) {
      return dispatch(loadCheckFeature(verb, resource)).then(() =>
        getCheckFeature(getState(), { verb, resource }),
      );
    }

    const firstLoading = featureStates.find(d => isLoading(d));
    if (firstLoading) {
      return firstLoading.promise.then(() =>
        getCheckFeature(getState(), { verb, resource }),
      );
    }

    // If we get here, then there is at least one featureState, and
    // it is loaded.
    return Promise.resolve(getCheckFeature(getState(), { verb, resource }));
  };
}
