import {
  CuratedColumnSymbology,
  DistributionTypes,
  InterpolationTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/public/dataset/census_planning_database_cbg_us_2016': {
    pct_prs_blw_pov_lev_acs_10_14: [
      {
        display_hints: {
          distribution: DistributionTypes.CUSTOM,
          interpolation: InterpolationTypes.INTERVAL,
          is_reverse_theme: true,
          scale: SymbologyScales.NUMERIC,
          showZero: true,
          theme: 'custom',
        },
        paint: {
          'fill-color': {
            default: 'transparent',
            property: 'pct_prs_blw_pov_lev_acs_10_14',
            stops: [
              {
                color: 'rgb(8, 98, 101)',
                label: '0 - 2.5',
                value: 0,
              },
              {
                color: 'rgb(64, 159, 148)',
                label: '2.5 - 5',
                value: 2.5,
              },
              {
                color: 'rgb(116, 198, 180)',
                label: '5 - 7.5',
                value: 5,
              },
              {
                color: 'rgb(163, 216, 196)',
                label: '7.5 - 10',
                value: 7.5,
              },
              {
                color: 'rgb(208, 228, 205)',
                label: '10 - 15',
                value: 10,
              },
              {
                color: 'rgb(232, 222, 191)',
                label: '15 - 20',
                value: 15,
              },
              {
                color: 'rgb(235, 193, 148)',
                label: '20 - 30',
                value: 20,
              },
              {
                color: 'rgb(231, 146, 96)',
                label: '30 - 40',
                value: 30,
              },
              {
                color: 'rgb(226, 102, 58)',
                label: '40 - 50',
                value: 40,
              },
              {
                color: 'rgb(219, 67, 37)',
                label: '50 - 100',
                value: 50,
              },
            ],
            type: 'interval',
          },
          'fill-opacity': 0.5,
        },
        type: SymbologyTypes.FILL,
      },
      {
        display_hints: {
          distribution: DistributionTypes.CUSTOM,
          interpolation: InterpolationTypes.INTERVAL,
          scale: SymbologyScales.NUMERIC,
          showZero: true,
          theme: 'custom',
        },
        paint: {
          'line-color': '#333333',
          'line-opacity': 0.5,
          'line-width': 0.5,
        },
        type: SymbologyTypes.LINE,
      },
    ],
  },
};

export default symbologies;
