import {
  CuratedColumnSymbology,
  CUSTOM_THEME,
  DEFAULT_OPACITY,
  DistributionTypes,
  InterpolationTypes,
  PaintColorTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

import stateList from './us_states';

const symbologies: Record<
  string,
  Record<string, CuratedColumnSymbology[]>
> = {};

stateList.forEach(stateName => {
  symbologies[`/public/dataset/osm_building_footprints_${stateName}`] = {
    height: [
      {
        type: SymbologyTypes.FILL,
        paint: {
          'fill-color': {
            property: 'height',
            type: PaintColorTypes.INTERVAL,
            stops: [
              { value: 0, label: 'Less than 5', color: '#ecedf1' },
              { value: 5, label: '5 - 20', color: '#215895' },
              { value: 20, label: '20 - 40', color: '#2fa190' },
              { value: 40, label: '40 - 70', color: '#3dd13b' },
              { value: 70, label: '70 - 100', color: '#8ff041' },
              { value: 100, label: '100 - 150', color: '#00ff00' },
              { value: 150, label: 'Greater than 150', color: '#00ffef' },
            ],
          },
          'fill-opacity': DEFAULT_OPACITY,
        },
        display_hints: {
          scale: SymbologyScales.NUMERIC,
          distribution: DistributionTypes.DEFAULT,
          interpolation: InterpolationTypes.INTERVAL,
          theme: CUSTOM_THEME,
        },
      },
    ],
  };
});

export default symbologies;
