import { combineEpics } from 'uf/base/epics';
import { ROUTE_SETTINGS } from 'uf/organizations/ActionTypes';
import { makeAddRootQueryEpic } from 'uf/routing/query';
import { ROUTE_PROFILE } from 'uf/user/ActionTypes';

export const addRootQueriesToProfileRoutes =
  makeAddRootQueryEpic(ROUTE_PROFILE);
export const addRootQueriesToSettingsRoutes =
  makeAddRootQueryEpic(ROUTE_SETTINGS);

export default combineEpics(
  {
    addRootQueriesToProfileRoutes,
    addRootQueriesToSettingsRoutes,
  },
  'userRoutingEpics',
);
