import colors from 'material-colors';

import {
  CuratedColumnSymbology,
  CUSTOM_THEME,
  DEFAULT_OPACITY,
  InterpolationTypes,
  PaintColorTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/public/databank/carb_priority_populations_california': {
    display: [
      {
        type: SymbologyTypes.FILL,
        paint: {
          'fill-color': {
            property: 'display',
            type: PaintColorTypes.CATEGORICAL,
            stops: [
              {
                value: 1,
                label: 'SB 535 Disadvantaged Communities',
                color: colors.red[200],
              },
              {
                value: 3,
                label: 'AB 1550 Low-income Communities',
                color: colors.blue[200],
              },
              {
                value: 2,
                label:
                  'SB 535 Disadvantaged Communities and AB 1550 Low-income Communities',
                color: colors.purple[200],
              },
              {
                value: 4,
                label:
                  'AB 1550 Low-income Communities within a 1/2 mile of a SB 535 Disadvantaged Community',
                color: colors.amber[200],
              },
              {
                value: 0,
                label: 'Unaffected Communities',
                color: 'transparent',
              },
            ],
          },
          'fill-opacity': DEFAULT_OPACITY,
        },
        display_hints: {
          scale: SymbologyScales.CATEGORICAL,
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          theme: CUSTOM_THEME,
        },
      },
    ],
  },
};

export default symbologies;
