import { makeAsyncActionTypes } from 'uf/data/ActionTypes';
import { makeWebsocketActionTypes } from 'uf/websockets/actionHelpers';

import { createBufferedLayer } from './websocketMessages';

const projectsBasePrefix = 'uf/projects';

export const UPDATE_PROJECT_STATUS = `${projectsBasePrefix}/UPDATE_PROJECT_STATUS`;

const createBufferedLayerActionTypesPrefix = `${projectsBasePrefix}/create_buffered_layer`;
export const startCreateBufferedLayerActionTypes = makeAsyncActionTypes(
  createBufferedLayerActionTypesPrefix,
);
export const createBufferedLayerTaskActionTypes = makeWebsocketActionTypes(
  createBufferedLayer.LAYER_BUFFER_STARTED,
  createBufferedLayer.LAYER_BUFFER_DONE,
  createBufferedLayer.LAYER_BUFFER_ERROR,
);

export const BIND_CREATE_BUFFERED_LAYER_TASK = `${createBufferedLayerActionTypesPrefix}/BIND_CREATE_BUFFERED_LAYER_TASK`;

const createDynamicLayerActionTypesPrefix = `${projectsBasePrefix}/create_dynamic_layer`;
export const createDynamicLayerActionTypes = makeAsyncActionTypes(
  createDynamicLayerActionTypesPrefix,
);

const updateLayerNameActionTypesPrefix = `${projectsBasePrefix}/update_layer_name`;
export const updateLayerNameActionTypes = makeAsyncActionTypes(
  updateLayerNameActionTypesPrefix,
);
