import { FormGroup } from '@blueprintjs/core';
import React from 'react';
import { t } from 'ttag';

import { ProjectMetadata } from 'uf-api';
import { BoundarySources } from 'uf/projects';
import { CensusLocation } from 'uf/projects/create';
import { TTailwindString } from 'uf/tailwindcss-classnames';
import BoundaryPicker from 'uf/ui/projects/CreateProjectForm/BoundaryPicker/BoundaryPicker';
import BoundarySourceChoices from 'uf/ui/projects/CreateProjectForm/BoundarySourceChoices/BoundarySourceChoices';
import FormSection from 'uf/ui/projects/CreateProjectForm/FormSection';

interface Props {
  projectBoundarySource?: BoundarySources;
  projectArea?: any;
  sourceProject?: ProjectMetadata;
  projectType?: string;
  setProjectArea?: (location: CensusLocation | null) => void;
  onUserUploadChange?: (uploadKey: string) => void;
  onNameChange?: (name: string) => void;
  onProjectBoundarySourceChange?: (boundarySource: BoundarySources) => void;
  titleClasses?: TTailwindString;
}

const ProjectBoundaryField: React.FunctionComponent<Props> = props => {
  const {
    projectArea,
    setProjectArea,
    onUserUploadChange,
    onNameChange,
    projectType,
    projectBoundarySource,
    onProjectBoundarySourceChange,
    sourceProject,
    children,
    titleClasses,
  } = props;

  return (
    <FormSection
      className={'CreateProjectForm-projectArea'}
      title={t`Project Area`}
      titleClass={titleClasses}
      infoText={t`The Project Area is your area of study, where you can assess existing conditions and build scenarios`}>
      <FormGroup className="tw-w-full" intent={projectArea ? 'success' : null}>
        <BoundarySourceChoices
          allowUpload
          boundarySource={projectBoundarySource}
          onBoundarySourceChange={onProjectBoundarySourceChange}
          haveSourceProject={!!sourceProject}
        />
        <BoundaryPicker
          boundarySource={projectBoundarySource}
          projectType={projectType}
          wellKnownLabel={t`Location`}
          wellKnownLabelInfo={t`(required)`}
          wellKnownPlaceholder={t`Enter location...`}
          wellKnownClassName={'CreateProjectForm-projectBoundary'}
          onWellKnownChange={setProjectArea}
          userUploadLabel={t`Project Boundary Name`}
          onUserUploadChange={onUserUploadChange}
          onNameChange={onNameChange}
        />
      </FormGroup>
      {children}
    </FormSection>
  );
};
export default ProjectBoundaryField;
