import {
  CuratedColumnSymbology,
  CUSTOM_THEME,
  DEFAULT_OPACITY,
  DistributionTypes,
  InterpolationTypes,
  PaintColorTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/cityofmadison/dataset/madison_ph_outcomes_base': {
    adult_all_walk_minutes: [
      {
        type: SymbologyTypes.FILL,
        paint: {
          'fill-color': {
            property: 'adult_all_walk_minutes',
            type: PaintColorTypes.INTERVAL,
            stops: [
              { value: 0, color: '#E8E8E8' },
              { value: 1, color: '#C3523C' },
              { value: 2.5, color: '#B1782D' },
              { value: 5, color: '#9F9F1E' },
              { value: 7.5, color: '#8DC60F' },
              { value: 10, color: '#7BED00' },
              { value: 15, color: '#5FBC1E' },
              { value: 20, color: '#438C3D' },
              { value: 25, color: '#275C5B' },
              { value: 30, color: '#0B2C7A' },
            ],
          },
          'fill-opacity': DEFAULT_OPACITY,
        },
        display_hints: {
          scale: SymbologyScales.NUMERIC,
          distribution: DistributionTypes.DEFAULT,
          interpolation: InterpolationTypes.INTERVAL,
          theme: CUSTOM_THEME,
        },
      },
    ],
  },
};

export default symbologies;
