import {
  CuratedColumnSymbology,
  CUSTOM_THEME,
  DEFAULT_OPACITY,
  InterpolationTypes,
  PaintColorTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/public/dataset/cpad_holdings': {
    agncy_lev: [
      {
        type: SymbologyTypes.FILL,
        paint: {
          'fill-color': {
            property: 'agncy_lev',
            type: PaintColorTypes.CATEGORICAL,
            stops: [
              { value: 'City', color: '#69BEBA' },
              { value: 'County', color: '#355C9D' },
              { value: 'Federal', color: '#3AA33A' },
              { value: 'Joint', color: '#AAAAAA' },
              { value: 'Non Profit', color: '#C8A778' },
              { value: 'Private', color: '#8F4509' },
              { value: 'Special District', color: '#9A7ECE' },
              { value: 'State', color: '#A8BE17' },
            ],
          },
          'fill-opacity': DEFAULT_OPACITY,
        },
        display_hints: {
          scale: SymbologyScales.CATEGORICAL,
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          theme: CUSTOM_THEME,
        },
      },
    ],
    access_typ: [
      {
        type: SymbologyTypes.FILL,
        paint: {
          'fill-color': {
            property: 'access_typ',
            type: PaintColorTypes.CATEGORICAL,
            stops: [
              { value: 'Open Access', color: '#92DA9B' },
              { value: 'Unknown Access', color: '#DEDEDE' },
              { value: 'No Public Access', color: '#F4CFC7' },
              { value: 'Restricted Access', color: '#87C283' },
            ],
          },
          'fill-opacity': DEFAULT_OPACITY,
        },
        display_hints: {
          scale: SymbologyScales.CATEGORICAL,
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          theme: CUSTOM_THEME,
        },
      },
    ],
  },
};

export default symbologies;
