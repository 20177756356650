import { WebsocketMessage } from 'uf-ws/WebsocketActions';
import { DataState, DataStateMap } from 'uf/data/dataState';
import { NotificationTypes } from 'uf/tasks/NotificationTypes';
import { TaskInfo } from 'uf/tasks/TaskInfos';
import { TaskStatuses } from 'uf/tasks/TaskStatuses';
import { TaskTypes } from 'uf/tasks/TaskTypes';

/**
 * A message that comes over websockets to describe a running task
 */
export interface TaskMessage<I = TaskInfo, R = any> extends WebsocketMessage {
  /**
   *  The type of message like MAPEXPORT_DONE
   */
  type: NotificationTypes;

  /**
   * deprecated but some systems still use this on the backend
   */
  uf_task_id?: string;

  /**
   * The task id that this message is associated with.  Should be required, but we are straddling
   * uf_task_id and task_id right now.
   */
  task_id?: string;

  /**
   * The type of task this is, i.e. for analysis or import etc.
   */
  task_type?: TaskTypes;

  /**
   * Present if a problem was encountered running the task.
   */
  problem?: any;

  /**
   * Present if a task was finished succesfully.
   */
  result?: R;

  status?: TaskStatuses;

  /**
   * Optional information about a task.
   */
  info?: I;

  /**
   * this is needed for now until old message types go away
   */
  [extraProps: string]: any;
}

/**
 * This are the requests that initiated the tasks. Typically this is like
 * `enqueue_map_export` or something. These requests should return a
 * `uf_task_id` as their response.
 */
export interface TaskRequestMap<T> {
  [requestKey: string]: DataStateMap<T>;
}

/**
 * State of a given task - keeps track of whether it is
 * started/loading/done/error much like any other DataState
 */
export interface TaskLoadStateMap<M> {
  [taskId: string]: DataState<M>;
}

/**
 * Map of resources to all task ids that the frontend has seen, in the order
 * first seen by the frontend. Look up the actual state of the task in
 * {@link TaskLoadStateMap}.
 */
export interface TaskIdsMap {
  [resourceId: string]: string[];
}

/**
 * Redux state shape for tasks
 *
 *  * M is the type for the websocket message
 *  * T is the type for the initial request (typically 'string')
 */
export interface TaskState<M extends TaskMessage, T> {
  enqueued: TaskRequestMap<T>;
  tasks: TaskLoadStateMap<M>;
  taskIds: TaskIdsMap;
}

export const EmptyTaskMessage: TaskMessage = {
  status: null,
  type: null,
  scope: null,
  scope_type: null,
};
