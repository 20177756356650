import { combineEpics } from 'uf/base/epics';

import layerEpics from './layers';

export default combineEpics(
  {
    layerEpics,
  },
  'import',
);
