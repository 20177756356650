/**
 * UrbanFootprint App API
 * API for serving UrbanFootprint Application
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Parameter metadata about a project LAS run.
 */
export interface ProjectLayerAnalysisRunParameter<> {
  /**
   * The buffer radius value.
   */
  buffer?: number;
  /**
   * The buffer unit.
   */
  buffer_unit?: ProjectLayerAnalysisRunParameter.BufferUnitEnum;
  /**
   * The census unit key.
   */
  census_unit_key?: number;
  /**
   * The insight key.
   */
  insight_key: number;
}
export namespace ProjectLayerAnalysisRunParameter {
  export type BufferUnitEnum = 'miles' | 'meters' | 'kilometers' | 'feet';
  export const BufferUnitEnum = {
    Miles: 'miles',
    Meters: 'meters',
    Kilometers: 'kilometers',
    Feet: 'feet',
  } as const;
}
