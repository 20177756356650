/**
 * UrbanFootprint App API
 * API for serving UrbanFootprint Application
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { ClientResponse } from '../variables';

// @ts-ignore: This is used only for params, but some services have param-free APIs
import { DynamicServiceInterface } from './dynamic.serviceInterface';

// @ts-ignore: These imports are for both params and returnType, but we only use returnType
import { DynamicLayerMetadata } from '../model/dynamicLayerMetadata';
// @ts-ignore: These imports are for both params and returnType, but we only use returnType
import { ProblemDetail } from '../model/problemDetail';

/**
 * Package: api
 */

const getDynamicLayerPrefix = `uf-api/dynamic/get_dynamic_layer/`;
/**
 * Action types for dynamic.get_dynamic_layer
 *
 * Get dynamic layer metadata
 */
export const getDynamicLayerActionTypes = {
  LOAD: `${getDynamicLayerPrefix}LOAD` as 'uf-api/dynamic/get_dynamic_layer/LOAD',
  SUCCESS:
    `${getDynamicLayerPrefix}SUCCESS` as 'uf-api/dynamic/get_dynamic_layer/SUCCESS',
  FAILURE:
    `${getDynamicLayerPrefix}FAILURE` as 'uf-api/dynamic/get_dynamic_layer/FAILURE',
  CLEAR:
    `${getDynamicLayerPrefix}CLEAR` as 'uf-api/dynamic/get_dynamic_layer/CLEAR',
} as const;

/**
 * LOAD action for dynamic.get_dynamic_layer
 * Get dynamic layer metadata
 */
export interface getDynamicLayerLoadAction {
  readonly type: 'uf-api/dynamic/get_dynamic_layer/LOAD';
  readonly params: DynamicServiceInterface.getDynamicLayerParams;
  readonly promise: Promise<ClientResponse<DynamicLayerMetadata>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for dynamic.get_dynamic_layer
 * Get dynamic layer metadata
 */
export interface getDynamicLayerSuccessAction {
  readonly type: 'uf-api/dynamic/get_dynamic_layer/SUCCESS';
  readonly result: DynamicLayerMetadata;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for dynamic.get_dynamic_layer
 * Get dynamic layer metadata
 */
export interface getDynamicLayerFailureAction {
  readonly type: 'uf-api/dynamic/get_dynamic_layer/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for dynamic.get_dynamic_layer
 * Get dynamic layer metadata
 */
export interface getDynamicLayerClearAction {
  readonly type: 'uf-api/dynamic/get_dynamic_layer/CLEAR';
  readonly key: string;
}

const getDynamicLayerFilterPrefix = `uf-api/dynamic/get_dynamic_layer_filter/`;
/**
 * Action types for dynamic.get_dynamic_layer_filter
 *
 * Get filter for dynamic layer
 */
export const getDynamicLayerFilterActionTypes = {
  LOAD: `${getDynamicLayerFilterPrefix}LOAD` as 'uf-api/dynamic/get_dynamic_layer_filter/LOAD',
  SUCCESS:
    `${getDynamicLayerFilterPrefix}SUCCESS` as 'uf-api/dynamic/get_dynamic_layer_filter/SUCCESS',
  FAILURE:
    `${getDynamicLayerFilterPrefix}FAILURE` as 'uf-api/dynamic/get_dynamic_layer_filter/FAILURE',
  CLEAR:
    `${getDynamicLayerFilterPrefix}CLEAR` as 'uf-api/dynamic/get_dynamic_layer_filter/CLEAR',
} as const;

/**
 * LOAD action for dynamic.get_dynamic_layer_filter
 * Get filter for dynamic layer
 */
export interface getDynamicLayerFilterLoadAction {
  readonly type: 'uf-api/dynamic/get_dynamic_layer_filter/LOAD';
  readonly params: DynamicServiceInterface.getDynamicLayerFilterParams;
  readonly promise: Promise<ClientResponse<object>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for dynamic.get_dynamic_layer_filter
 * Get filter for dynamic layer
 */
export interface getDynamicLayerFilterSuccessAction {
  readonly type: 'uf-api/dynamic/get_dynamic_layer_filter/SUCCESS';
  readonly result: object;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for dynamic.get_dynamic_layer_filter
 * Get filter for dynamic layer
 */
export interface getDynamicLayerFilterFailureAction {
  readonly type: 'uf-api/dynamic/get_dynamic_layer_filter/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for dynamic.get_dynamic_layer_filter
 * Get filter for dynamic layer
 */
export interface getDynamicLayerFilterClearAction {
  readonly type: 'uf-api/dynamic/get_dynamic_layer_filter/CLEAR';
  readonly key: string;
}

const updateDynamicLayerFilterPrefix = `uf-api/dynamic/update_dynamic_layer_filter/`;
/**
 * Action types for dynamic.update_dynamic_layer_filter
 *
 * Create new version of dynamic layer with filter
 */
export const updateDynamicLayerFilterActionTypes = {
  LOAD: `${updateDynamicLayerFilterPrefix}LOAD` as 'uf-api/dynamic/update_dynamic_layer_filter/LOAD',
  SUCCESS:
    `${updateDynamicLayerFilterPrefix}SUCCESS` as 'uf-api/dynamic/update_dynamic_layer_filter/SUCCESS',
  FAILURE:
    `${updateDynamicLayerFilterPrefix}FAILURE` as 'uf-api/dynamic/update_dynamic_layer_filter/FAILURE',
  CLEAR:
    `${updateDynamicLayerFilterPrefix}CLEAR` as 'uf-api/dynamic/update_dynamic_layer_filter/CLEAR',
} as const;

/**
 * LOAD action for dynamic.update_dynamic_layer_filter
 * Create new version of dynamic layer with filter
 */
export interface updateDynamicLayerFilterLoadAction {
  readonly type: 'uf-api/dynamic/update_dynamic_layer_filter/LOAD';
  readonly params: DynamicServiceInterface.updateDynamicLayerFilterParams;
  readonly promise: Promise<ClientResponse<object>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for dynamic.update_dynamic_layer_filter
 * Create new version of dynamic layer with filter
 */
export interface updateDynamicLayerFilterSuccessAction {
  readonly type: 'uf-api/dynamic/update_dynamic_layer_filter/SUCCESS';
  readonly result: object;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for dynamic.update_dynamic_layer_filter
 * Create new version of dynamic layer with filter
 */
export interface updateDynamicLayerFilterFailureAction {
  readonly type: 'uf-api/dynamic/update_dynamic_layer_filter/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for dynamic.update_dynamic_layer_filter
 * Create new version of dynamic layer with filter
 */
export interface updateDynamicLayerFilterClearAction {
  readonly type: 'uf-api/dynamic/update_dynamic_layer_filter/CLEAR';
  readonly key: string;
}

export const dynamicActionTypesMap = {
  /** Action types for dynamic.get_dynamic_layer */
  get_dynamic_layer: getDynamicLayerActionTypes,
  /** Action types for dynamic.get_dynamic_layer_filter */
  get_dynamic_layer_filter: getDynamicLayerFilterActionTypes,
  /** Action types for dynamic.update_dynamic_layer_filter */
  update_dynamic_layer_filter: updateDynamicLayerFilterActionTypes,
} as const;

export const dynamicDetailsMap = {
  get_dynamic_layer: {
    /** HTTP method for dynamic.get_dynamic_layer */
    httpMethod: 'get' as const,
  },
  get_dynamic_layer_filter: {
    /** HTTP method for dynamic.get_dynamic_layer_filter */
    httpMethod: 'get' as const,
  },
  update_dynamic_layer_filter: {
    /** HTTP method for dynamic.update_dynamic_layer_filter */
    httpMethod: 'put' as const,
  },
} as const;

/**
 * Local DataState<T> - kept in sync with uf/data/dataState.ts
 */
interface DataState<T> {
  readonly loading: boolean;
  readonly loaded: boolean;
  readonly requestId: string;
  readonly requestTime?: string;
  readonly responseTime?: string;
  readonly requestCount?: number;
  readonly error?: any;
  readonly promise?: Promise<T>;
  readonly data?: T;
  readonly extra?: any;
  readonly key?: string;
}
export interface DynamicServiceState {
  /** getDynamicLayer state, by key */
  get_dynamic_layer: {
    readonly [key: string]: DataState<DynamicLayerMetadata>;
  };
  /** getDynamicLayerFilter state, by key */
  get_dynamic_layer_filter: {
    readonly [key: string]: DataState<object>;
  };
  /** all recent updateDynamicLayerFilter results (POST/PUT/PATCH) */
  readonly update_dynamic_layer_filter: DataState<object>[];
}
