import { ActionsObservable, Epic, ofType } from 'redux-observable';
import { AnyAction } from 'redux';
import { map, withLatestFrom } from 'rxjs/operators';

import { SELECT_FEATURE, forwardGecodeActionTypes } from 'uf/mapi/ActionTypes';
import { combineEpics } from 'uf/base/epics';
import { UFState } from 'uf/state';
import { getActiveProjectId } from 'uf/app/selectors';
import { setExtentsBounds, setExtentsPosition } from 'uf/explore/actions/map';
import { MapPosition } from 'uf/map';
import { UFLngLatBounds } from 'uf/base/map';
import { setSearchMarker, clearSearchMarker } from 'uf/explore/actions/markers';

export const gotoFeature: Epic<AnyAction, any, UFState> = (
  action$: ActionsObservable<AnyAction>,
  state$,
) => {
  return action$.pipe(
    ofType(SELECT_FEATURE),
    withLatestFrom(state$),
    map(([action, state]) => {
      const { feature } = action;
      const projectId = getActiveProjectId(state);
      if (feature.bbox) {
        const requestedBounds: UFLngLatBounds = [
          [feature.bbox[0], feature.bbox[1]],
          [feature.bbox[2], feature.bbox[3]],
        ];
        return setExtentsBounds(projectId, requestedBounds);
      }
      const position: MapPosition = {
        lnglat: { lng: feature.center[0], lat: feature.center[1] },
        scale: 9000,
        pitch: 0,
        bearing: 0,
      };
      return setExtentsPosition(projectId, position);
    }),
  );
};

export const pinFeature: Epic<AnyAction, any, UFState> = (
  action$: ActionsObservable<AnyAction>,
  state$,
) => {
  return action$.pipe(
    ofType(SELECT_FEATURE),
    withLatestFrom(state$),
    map(([action, state]) => {
      const { feature } = action;
      const projectId = getActiveProjectId(state);
      return setSearchMarker(feature.center, projectId);
    }),
  );
};

export const clearPinnedFeature: Epic<AnyAction, any, UFState> = (
  action$: ActionsObservable<AnyAction>,
  state$,
) => {
  return action$.pipe(
    ofType(forwardGecodeActionTypes.CLEAR),
    withLatestFrom(state$),
    map(([action, state]) => {
      const projectId = getActiveProjectId(state);
      return clearSearchMarker(projectId);
    }),
  );
};

const epic = combineEpics(
  { gotoFeature, pinFeature, clearPinnedFeature },
  'search',
);

export default epic;
