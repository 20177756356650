import { Feature } from 'geojson';
import { Layer, LngLat, Map, Point } from 'mapbox-gl';
import { useCallback, useEffect, useRef } from 'react';

import { PolygonGeometry } from 'uf/base/geometry';
import { assertNever } from 'uf/base/never';
import { MapMode } from 'uf/map/mapmode';
import { setBoxSelectionMode } from 'uf/ui/map/modes/boxSelection';
import { DrawMode, setDrawMode } from 'uf/ui/map/modes/draw';
import { setInspectionMode } from 'uf/ui/map/modes/inspection';
import { setNormalMode } from 'uf/ui/map/modes/normal';
import { setPointSelectionMode } from 'uf/ui/map/modes/pointSelection';
import { setPolygonSelectionMode } from 'uf/ui/map/modes/polygonSelection';

/**
 * Manage map modes
 *
 * Usage
 *
 * ```
 *   const setMode = useMode(...);
 * ```
 *
 * Returns a function that you can call to switch modes, and this hook will
 * manage the cleanup/callbacks/etc.
 */
export function useMode(
  map: Map,
  interactiveStyleLayers: Layer[],
  drawStyleLayers: Layer[],
  onPolygonSelection: (shape: PolygonGeometry, add: boolean) => void,
  onPointSelection: (features: Feature[], add: boolean) => void,
  onInspection: (point: Point, lngLat: LngLat, map: Map) => void,
  onDrawMode: (draw: DrawMode) => void,
  onMapModeChanged: (mode: MapMode, prevMode: MapMode, map: Map) => void,
  mapMode: MapMode,
  activeLayerId: string,
) {
  const unsetModeRef = useRef<() => void>(null);
  // these must be wrapped as refs or else they will not receive state updates
  // https://urbanfootprint.atlassian.net/browse/UF-2088
  const callbacks = {
    onPolygonSelection,
    onPointSelection,
    onInspection,
    onDrawMode,
    onMapModeChanged,
  };
  const callbacksRef = useRef(callbacks);
  callbacksRef.current = callbacks;
  const modeRef = useRef<MapMode>(MapMode.NORMAL);
  // when the user clicks on "inspection" or "point select", we plumb the callback
  const setMode = useCallback(
    (m: Map, mode: MapMode) => {
      // this needs to be upto date with the latest
      unsetModeRef.current = enterMode(
        mode,
        m,
        drawStyleLayers,
        interactiveStyleLayers,
        (...args) => callbacksRef.current.onPolygonSelection(...args),
        (...args) => callbacksRef.current.onPointSelection(...args),
        (...args) => callbacksRef.current.onInspection(...args),
        (...args) => callbacksRef.current.onDrawMode(...args),
      );

      if (callbacksRef.current.onMapModeChanged) {
        callbacksRef.current.onMapModeChanged(mode, modeRef.current, m);
      }
      modeRef.current = mode;
    },
    [drawStyleLayers, interactiveStyleLayers],
  );

  const previousMode = useRef(mapMode);
  const previousLayerId = useRef(activeLayerId);
  useEffect(() => {
    const shouldChangeMode =
      previousMode.current !== mapMode ||
      previousLayerId.current !== activeLayerId;
    if (shouldChangeMode) {
      // note that unsetting the old mode cannot be done in the effect cleanup
      // because we do not always update the mode. One way to manage this would
      // be to keep `setMode` out of the dependency list, but setMode does
      // actually change because it depends on other input props
      const unsetMode = unsetModeRef.current;
      if (unsetMode) {
        unsetMode();
      }
      setMode(map, mapMode);
    }
    // remember these for the next iteration
    previousLayerId.current = activeLayerId;
    previousMode.current = mapMode;
  }, [
    map,
    mapMode,
    setMode,
    // we need to reset the mode whenever the active layer changes, but it would
    // be better to get this via another signal besides the actually layer id.
    activeLayerId,
  ]);

  return setMode;
}

function enterMode(
  mode: MapMode,
  map: Map,
  drawStyleLayers: Layer[],
  interactiveStyleLayers: Layer[],
  onPolygonSelection: (shape: PolygonGeometry, add: boolean) => void,
  onPointSelection: (features: Feature[], add: boolean) => void,
  onInspection: (point: Point, lngLat: LngLat, map: Map) => void,
  onDrawMode: (draw: DrawMode) => void,
): () => void {
  switch (mode) {
    case MapMode.NORMAL:
      // setNormalMode returns undefined since there is nothing to unset
      setNormalMode(map);
      return null;
    case MapMode.BOX_SELECTION: {
      return setBoxSelectionMode(map, onPolygonSelection);
    }
    case MapMode.POLYGON_SELECTION:
      return setPolygonSelectionMode(map, onPolygonSelection);
    case MapMode.POINT_SELECTION:
      return setPointSelectionMode(
        map,
        interactiveStyleLayers.map(layer => layer.id),
        onPointSelection,
      );
    case MapMode.INSPECTION:
      return setInspectionMode(map, onInspection);
    case MapMode.DRAW: {
      const { drawMode, exitMode } = setDrawMode(map, drawStyleLayers);
      onDrawMode(drawMode);
      return exitMode;
    }
    default:
      return assertNever(mode);
  }
}
