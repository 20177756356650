import { AnalysisServiceInterface } from './analysis.serviceInterface';
import { analysisActionTypesMap } from './analysis.service';
import { analysisDetailsMap } from './analysis.service';
import { AnalysisServiceState } from './analysis.service';
import { AppServiceInterface } from './app.serviceInterface';
import { appActionTypesMap } from './app.service';
import { appDetailsMap } from './app.service';
import { AppServiceState } from './app.service';
import { AuthenticationServiceInterface } from './authentication.serviceInterface';
import { authenticationActionTypesMap } from './authentication.service';
import { authenticationDetailsMap } from './authentication.service';
import { AuthenticationServiceState } from './authentication.service';
import { BuiltformServiceInterface } from './builtform.serviceInterface';
import { builtformActionTypesMap } from './builtform.service';
import { builtformDetailsMap } from './builtform.service';
import { BuiltformServiceState } from './builtform.service';
import { BuiltformsServiceInterface } from './builtforms.serviceInterface';
import { builtformsActionTypesMap } from './builtforms.service';
import { builtformsDetailsMap } from './builtforms.service';
import { BuiltformsServiceState } from './builtforms.service';
import { DashboardServiceInterface } from './dashboard.serviceInterface';
import { dashboardActionTypesMap } from './dashboard.service';
import { dashboardDetailsMap } from './dashboard.service';
import { DashboardServiceState } from './dashboard.service';
import { DynamicServiceInterface } from './dynamic.serviceInterface';
import { dynamicActionTypesMap } from './dynamic.service';
import { dynamicDetailsMap } from './dynamic.service';
import { DynamicServiceState } from './dynamic.service';
import { GeoServiceInterface } from './geo.serviceInterface';
import { geoActionTypesMap } from './geo.service';
import { geoDetailsMap } from './geo.service';
import { GeoServiceState } from './geo.service';
import { InternalServiceInterface } from './internal.serviceInterface';
import { internalActionTypesMap } from './internal.service';
import { internalDetailsMap } from './internal.service';
import { InternalServiceState } from './internal.service';
import { LayerServiceInterface } from './layer.serviceInterface';
import { layerActionTypesMap } from './layer.service';
import { layerDetailsMap } from './layer.service';
import { LayerServiceState } from './layer.service';
import { MapexportServiceInterface } from './mapexport.serviceInterface';
import { mapexportActionTypesMap } from './mapexport.service';
import { mapexportDetailsMap } from './mapexport.service';
import { MapexportServiceState } from './mapexport.service';
import { MetadataServiceInterface } from './metadata.serviceInterface';
import { metadataActionTypesMap } from './metadata.service';
import { metadataDetailsMap } from './metadata.service';
import { MetadataServiceState } from './metadata.service';
import { OrganizationServiceInterface } from './organization.serviceInterface';
import { organizationActionTypesMap } from './organization.service';
import { organizationDetailsMap } from './organization.service';
import { OrganizationServiceState } from './organization.service';
import { PaintServiceInterface } from './paint.serviceInterface';
import { paintActionTypesMap } from './paint.service';
import { paintDetailsMap } from './paint.service';
import { PaintServiceState } from './paint.service';
import { PortfolioServiceInterface } from './portfolio.serviceInterface';
import { portfolioActionTypesMap } from './portfolio.service';
import { portfolioDetailsMap } from './portfolio.service';
import { PortfolioServiceState } from './portfolio.service';
import { ProjectServiceInterface } from './project.serviceInterface';
import { projectActionTypesMap } from './project.service';
import { projectDetailsMap } from './project.service';
import { ProjectServiceState } from './project.service';
import { ProjectTypeServiceInterface } from './projectType.serviceInterface';
import { projectTypeActionTypesMap } from './projectType.service';
import { projectTypeDetailsMap } from './projectType.service';
import { ProjectTypeServiceState } from './projectType.service';
import { ReportServiceInterface } from './report.serviceInterface';
import { reportActionTypesMap } from './report.service';
import { reportDetailsMap } from './report.service';
import { ReportServiceState } from './report.service';
import { SettingsServiceInterface } from './settings.serviceInterface';
import { settingsActionTypesMap } from './settings.service';
import { settingsDetailsMap } from './settings.service';
import { SettingsServiceState } from './settings.service';
import { SummaryServiceInterface } from './summary.serviceInterface';
import { summaryActionTypesMap } from './summary.service';
import { summaryDetailsMap } from './summary.service';
import { SummaryServiceState } from './summary.service';
import { UserServiceInterface } from './user.serviceInterface';
import { userActionTypesMap } from './user.service';
import { userDetailsMap } from './user.service';
import { UserServiceState } from './user.service';
import { UserdataServiceInterface } from './userdata.serviceInterface';
import { userdataActionTypesMap } from './userdata.service';
import { userdataDetailsMap } from './userdata.service';
import { UserdataServiceState } from './userdata.service';

export interface UFApiClient {
  readonly analysis: AnalysisServiceInterface;

  readonly app: AppServiceInterface;

  readonly authentication: AuthenticationServiceInterface;

  readonly builtform: BuiltformServiceInterface;

  readonly builtforms: BuiltformsServiceInterface;

  readonly dashboard: DashboardServiceInterface;

  readonly dynamic: DynamicServiceInterface;

  readonly geo: GeoServiceInterface;

  readonly internal: InternalServiceInterface;

  readonly layer: LayerServiceInterface;

  readonly mapexport: MapexportServiceInterface;

  readonly metadata: MetadataServiceInterface;

  readonly organization: OrganizationServiceInterface;

  readonly paint: PaintServiceInterface;

  readonly portfolio: PortfolioServiceInterface;

  readonly project: ProjectServiceInterface;

  readonly projectType: ProjectTypeServiceInterface;

  readonly report: ReportServiceInterface;

  readonly settings: SettingsServiceInterface;

  readonly summary: SummaryServiceInterface;

  readonly user: UserServiceInterface;

  readonly userdata: UserdataServiceInterface;

  /**
   * Part of the swagger-js client
   */
  requestInterceptor?: (request: any) => any;
}

export const UFApiActionTypesMap = {
  analysis: analysisActionTypesMap,

  app: appActionTypesMap,

  authentication: authenticationActionTypesMap,

  builtform: builtformActionTypesMap,

  builtforms: builtformsActionTypesMap,

  dashboard: dashboardActionTypesMap,

  dynamic: dynamicActionTypesMap,

  geo: geoActionTypesMap,

  internal: internalActionTypesMap,

  layer: layerActionTypesMap,

  mapexport: mapexportActionTypesMap,

  metadata: metadataActionTypesMap,

  organization: organizationActionTypesMap,

  paint: paintActionTypesMap,

  portfolio: portfolioActionTypesMap,

  project: projectActionTypesMap,

  projectType: projectTypeActionTypesMap,

  report: reportActionTypesMap,

  settings: settingsActionTypesMap,

  summary: summaryActionTypesMap,

  user: userActionTypesMap,

  userdata: userdataActionTypesMap,
};

export const UFApiDetailsMap = {
  analysis: analysisDetailsMap,

  app: appDetailsMap,

  authentication: authenticationDetailsMap,

  builtform: builtformDetailsMap,

  builtforms: builtformsDetailsMap,

  dashboard: dashboardDetailsMap,

  dynamic: dynamicDetailsMap,

  geo: geoDetailsMap,

  internal: internalDetailsMap,

  layer: layerDetailsMap,

  mapexport: mapexportDetailsMap,

  metadata: metadataDetailsMap,

  organization: organizationDetailsMap,

  paint: paintDetailsMap,

  portfolio: portfolioDetailsMap,

  project: projectDetailsMap,

  projectType: projectTypeDetailsMap,

  report: reportDetailsMap,

  settings: settingsDetailsMap,

  summary: summaryDetailsMap,

  user: userDetailsMap,

  userdata: userdataDetailsMap,
};

export interface UFApiState {
  readonly analysis: AnalysisServiceState;

  readonly app: AppServiceState;

  readonly authentication: AuthenticationServiceState;

  readonly builtform: BuiltformServiceState;

  readonly builtforms: BuiltformsServiceState;

  readonly dashboard: DashboardServiceState;

  readonly dynamic: DynamicServiceState;

  readonly geo: GeoServiceState;

  readonly internal: InternalServiceState;

  readonly layer: LayerServiceState;

  readonly mapexport: MapexportServiceState;

  readonly metadata: MetadataServiceState;

  readonly organization: OrganizationServiceState;

  readonly paint: PaintServiceState;

  readonly portfolio: PortfolioServiceState;

  readonly project: ProjectServiceState;

  readonly projectType: ProjectTypeServiceState;

  readonly report: ReportServiceState;

  readonly settings: SettingsServiceState;

  readonly summary: SummaryServiceState;

  readonly user: UserServiceState;

  readonly userdata: UserdataServiceState;
}
