import classNames from 'classnames';
import React, { FunctionComponent, useCallback, useState } from 'react';

import { ProjectId } from 'uf/projects';
import FormDialog from 'uf/ui/base/Modals/FormDialog';
import { Sizes } from 'uf/ui/base/sizes';
import LayerManager from 'uf/ui/layers/LayerManager/LayerManager';
import WithAvailableProjectLayers from 'uf/ui/projects/hocs/WithAvailableProjectLayers';

import styles from './LayerManagerDialog.module.css';

interface OwnProps {
  projectId: ProjectId;
  show: boolean;
  onOk: () => void;
  restrictedLayerIds?: string[];
}

const LayerManagerDialog: FunctionComponent<OwnProps> = props => {
  const { projectId, restrictedLayerIds, show, onOk } = props;
  const [activeLayerId, setActiveLayerId] = useState(null);
  const [isCollapsedByCategory, setIsCollapsedByCategory] = useState({});
  const handleSelectLayer = useCallback(
    layerId => {
      if (activeLayerId !== layerId) {
        setActiveLayerId(layerId);
      }
    },
    [activeLayerId],
  );
  const handleToggleCategoryCollapsed = useCallback(
    categoryKey => collapsed => {
      setIsCollapsedByCategory({
        ...isCollapsedByCategory,
        [categoryKey]: collapsed,
      });
    },
    [isCollapsedByCategory],
  );
  const layerManagerDialogClassName = classNames(
    styles.layerManagerDialog,
    styles.layerManagerDialogDimensions,
    'LayerManagerDialog',
  );
  return (
    <FormDialog
      size={Sizes.XLARGE}
      show={show}
      onHide={onOk}
      title="Layer Manager"
      okText="Close"
      onOk={onOk}
      dialogClassName={layerManagerDialogClassName}
      controlsClassNamePrefix={'LayerManagerDialog'}>
      <WithAvailableProjectLayers projectId={projectId}>
        {availableProjectLayersState => {
          return (
            <LayerManager
              projectId={projectId}
              availableProjectLayersState={availableProjectLayersState}
              restrictedLayerIds={restrictedLayerIds}
              layerManagerActiveLayerId={activeLayerId}
              onSetActiveLayerId={handleSelectLayer}
              isCollapsedByCategory={isCollapsedByCategory}
              onToggleCategoryCollapsed={handleToggleCategoryCollapsed}
            />
          );
        }}
      </WithAvailableProjectLayers>
    </FormDialog>
  );
};
export default LayerManagerDialog;
