import { BuildingTypeInfo, PlaceTypeInfo } from 'uf-api';
import {
  updateBuildingTypeInfosSuccessAction,
  updatePlaceTypeInfosSuccessAction,
} from 'uf-api/api/builtforms.service';
import { ProjectId } from 'uf/projects';

import { BUILT_FORM_TYPES } from './index';

const builtFormsPrefix = 'uf/builtforms';

export const clearBuiltFormItemsActionType = `${builtFormsPrefix}/CLEAR_BUILTFORM_ITEMS`;
export const clearBuiltFormListsActionType = `${builtFormsPrefix}/CLEAR_BUILTFORM_LISTS`;

const editorPrefix = `${builtFormsPrefix}/editor`;
export const UPDATE_EDITOR_BUILTFORM = `${editorPrefix}/UPDATE_EDITOR_BUILTFORM`;
export const CLEAR_EDITOR_BUILTFORM = `${editorPrefix}/CLEAR_EDITOR_BUILTFORM`;
export const SET_EDITOR_PROPERTY = `${editorPrefix}/SET_EDITOR_PROPERTY`;
export const SET_EDITOR_CURRENT_BUILTFORM =
  'uf/builtforms/editor/SET_EDITOR_CURRENT_BUILTFORM';
export interface SetEditorCurrentBuiltformAction {
  type: typeof SET_EDITOR_CURRENT_BUILTFORM;
  projectId: ProjectId;
  value: {
    libraryId: string;
    builtFormType: BUILT_FORM_TYPES;
    builtFormKey: string;
  };
}

export type UpdateBuildingTypeInfosSuccessAction =
  updateBuildingTypeInfosSuccessAction & {
    actionParams: { libraryId: string; buildingTypeInfos: BuildingTypeInfo[] };
  };
export type UpdatePlaceTypeInfosSuccessAction =
  updatePlaceTypeInfosSuccessAction & {
    actionParams: { libraryId: string; placeTypeInfos: PlaceTypeInfo[] };
  };

export const INITIALIZE_BUILTFORM_EDITOR =
  'uf/builtforms/editor/INITIALIZE_BUILTFORM_EDITOR';
export interface InitializeBuiltformEditor {
  type: typeof INITIALIZE_BUILTFORM_EDITOR;
  libraryId: string;
  builtFormKey: string;
  builtFormType: BUILT_FORM_TYPES;
}

export const ROUTE_BUILT_FORM_EDITOR_WITH_KEY = 'BUILT_FORM_EDITOR_WITH_KEY';
export const ROUTE_BUILT_FORM_EDITOR = 'BUILT_FORM_EDITOR';
