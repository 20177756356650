import _ from 'lodash';
import { AnyAction, combineReducers } from 'redux';

import { EMPTY_OBJECT } from 'uf/base';
import {
  makeKeyedReducer,
  makeSetValueOnlyReducer,
  makeSetValueReducer,
} from 'uf/base/reducerFactories';
import { BuiltForm, makeBuiltFormKey } from 'uf/builtforms';
import {
  CLEAR_EDITOR_BUILTFORM,
  SET_EDITOR_CURRENT_BUILTFORM,
  SET_EDITOR_PROPERTY,
  UPDATE_EDITOR_BUILTFORM,
} from 'uf/builtforms/ActionTypes';
import { BuiltFormReference, EditorState } from 'uf/builtforms/state';

export default combineReducers<EditorState>({
  // All data about all built forms appears here
  data: dataReducer,

  // Random states of the editor (like if we've saved or not, etc) are here.
  properties: makeSetValueReducer(SET_EDITOR_PROPERTY),

  currentBuiltForm: makeKeyedReducer<
    BuiltFormReference,
    'projectId',
    AnyAction & Record<'projectId', string>
  >(
    makeSetValueOnlyReducer<BuiltFormReference>(
      SET_EDITOR_CURRENT_BUILTFORM,
      EMPTY_OBJECT as BuiltFormReference,
    ),
    'projectId',
  ),
});

function dataReducer(state = {}, action) {
  switch (action.type) {
    case UPDATE_EDITOR_BUILTFORM: {
      const { builtForm, libraryId, builtFormType, builtFormKey } = action;
      const key = makeBuiltFormKey(libraryId, builtFormType, builtFormKey);
      return {
        ...state,
        [key]: {
          ...builtForm,
        } as BuiltForm,
      };
    }

    case CLEAR_EDITOR_BUILTFORM: {
      const { libraryId, builtFormType, builtFormKey } = action;
      const key = makeBuiltFormKey(libraryId, builtFormType, builtFormKey);
      const builtFormState = _.omit(state, [key]);
      return builtFormState;
    }
    default:
      return state;
  }
}
