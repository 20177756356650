import { createSelector } from 'reselect';

import { EMPTY_ARRAY } from 'uf/base';
import { makeGetFromPropsSelector } from 'uf/base/selector';
import { makeGetExploreByProjectState } from 'uf/explore/selectors/explore';
import { LayerId } from 'uf/layers';

export const getExploreFeatureIdsState = createSelector(
  makeGetExploreByProjectState(),
  exploreState => exploreState.featureIdsFromSplit,
);

export function makeGetSplitParcelFeatureIds() {
  return createSelector(
    getExploreFeatureIdsState,
    makeGetFromPropsSelector<LayerId, 'layerId'>('layerId'),
    (featuresState, layerId): string[] => {
      return featuresState?.[layerId] || EMPTY_ARRAY;
    },
  );
}

export function makeGetTooManyFeaturesFromSplit() {
  return createSelector(
    makeGetExploreByProjectState(),
    makeGetFromPropsSelector<LayerId, 'layerId'>('layerId'),
    (exploreState, layerId) => {
      return exploreState?.tooManyFeaturesFromSplit?.[layerId] ?? false;
    },
  );
}

export function makeGetFailedIntersections() {
  return createSelector(
    makeGetExploreByProjectState(),
    makeGetFromPropsSelector<LayerId, 'layerId'>('layerId'),
    (exploreState, layerId) => {
      return exploreState?.failedIntersections?.[layerId] || 0;
    },
  );
}
export const getExploreGridOptions = createSelector(
  makeGetExploreByProjectState(),
  exploreState => exploreState.gridOptionsForFeatureSplitting,
);

export function makeGetSplittingEnabled() {
  return createSelector(
    makeGetExploreByProjectState(),
    exploreState => exploreState?.splittingEnabled || false,
  );
}
