import { LngLat, Marker, MarkerOptions } from 'mapbox-gl';
import {
  CLEAR_SEARCH_MARKER,
  SET_SEARCH_MARKER,
  SetSearchMarkerAction,
} from 'uf/explore/ActionTypes';
import { ProjectId } from 'uf/projects';

export function setSearchMarker(
  latlng: LngLat,
  projectId: ProjectId,
  options?: MarkerOptions,
): SetSearchMarkerAction {
  const marker = new Marker(options);
  marker.setLngLat(latlng);
  return {
    type: SET_SEARCH_MARKER,
    projectId,
    marker,
  };
}

export function clearSearchMarker(projectId: ProjectId) {
  return { type: CLEAR_SEARCH_MARKER, projectId };
}
