import { AnchorButton } from '@blueprintjs/core';
import { mdiHelpCircle } from '@mdi/js';
import { Icon } from '@mdi/react';
import React from 'react';
import { useSelector } from 'react-redux';
import { useGetOrganizationQuery } from 'uf-api-rtk/store/Api';
import { selectApp } from 'uf/app/slice';
import { logEvent } from 'uf/logging';
import { IconSizeNew } from 'uf/ui/base/icon';
import { sources } from 'uf/ui/user/logging';
import {
  FEATURE_DOCUMENTATION_OPENED,
  RESILIENCE_INSIGHTS_HELP_OPENED,
} from 'uf/user/logging';

const HelpLink = () => {
  const { activeProjectId, activeOrgId } = useSelector(selectApp);
  const { data: organization } = useGetOrganizationQuery(
    { key: activeOrgId },
    { skip: !activeOrgId },
  );
  const hasHelpUrl = !!organization?.insights_help_url;
  const handleClick = () => {
    logEvent(
      hasHelpUrl
        ? RESILIENCE_INSIGHTS_HELP_OPENED
        : FEATURE_DOCUMENTATION_OPENED,
      {
        projectId: activeProjectId,
        source: sources.HELP_LINK,
      },
    );
  };
  return (
    <AnchorButton
      minimal
      target="_blank"
      rel="noreferrer noopener"
      className="tw-p-0 tw-text-blueGray-400 hover:tw-text-white tw-flex tw-flex-grow tw-w-16"
      onClick={handleClick}
      href={
        hasHelpUrl
          ? organization.insights_help_url
          : 'https://docs.urbanfootprint.com/private/analyst'
      }
      icon={<Icon path={mdiHelpCircle} size={IconSizeNew.SMALL} />}
    />
  );
};

export default HelpLink;
