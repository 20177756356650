import { Dispatch } from 'redux';

import { UserData } from 'uf-api';
import {
  deleteUserDataActionTypes,
  getUserDataActionTypes,
  listUserDataItemsActionTypes,
  setUserDataActionTypes,
} from 'uf-api/api/userdata.service';
import { dispatchAsyncAction } from 'uf/data/loader';
import { makeUserDataKey } from 'uf/user';

import {
  deleteUserData as deleteUserDataAPI,
  getUserData as getUserDataAPI,
  listUserDataItems as listUserDataItemsAPI,
  setUserData as setUserDataAPI,
} from './apis';

export function setUserData<T extends object>(
  key: string,
  data: T,
  userKey: string,
  extra?: object,
) {
  return dispatchAsyncAction(
    setUserDataActionTypes,
    setUserDataAPI({ key, user_key: userKey, data }),
    {
      key: makeUserDataKey(userKey, key),
      data,
      extra: {
        ...extra,
        userKey,
        dataKey: key,
      },
    },
  );
}

export function loadUserData<E>(key: string, userKey: string, extra?: E) {
  return (dispatch: Dispatch, getState) => {
    return dispatch(
      dispatchAsyncAction<UserData, E>(
        getUserDataActionTypes,
        getUserDataAPI({ key, user_key: userKey }),
        {
          key: makeUserDataKey(userKey, key),
          extra: {
            ...extra,
            userKey,
            dataKey: key,
          },
        },
        (error): UserData => {
          // 404s can be ignored. It just means that the user hasn't set a value for that key.
          // We return null when there is no key found.
          if (error.status !== 404) {
            throw error;
          }
          return null;
        },
      ),
    );
  };
}

export function deleteUserData(key: string, userKey: string, extra?: object) {
  return dispatchAsyncAction(
    deleteUserDataActionTypes,
    deleteUserDataAPI({ key, user_key: userKey }),
    {
      key: makeUserDataKey(userKey, key),
      extra: {
        ...extra,
        userKey,
        dataKey: key,
      },
    },
  );
}

export function loadUserDataItems(userKey: string, prefix: string) {
  return dispatchAsyncAction(
    listUserDataItemsActionTypes,
    listUserDataItemsAPI({ user_key: userKey, prefix }),
    { key: makeUserDataKey(userKey, prefix), extra: { userKey, prefix } },
  );
}
