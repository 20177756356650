import { Dispatch } from 'redux';

import { ScenarioUpdate } from 'uf-api';
import { makeSetPropValueAction } from 'uf/base/actionHelpers';
import { ProjectId } from 'uf/projects';
import UpdateStatusTypes from 'uf/projects/StatusTypes';
import { ScenarioUpdateStatus } from 'uf/scenarios/update';

import { SET_SCENARIO_UPDATE_STATUS } from './ActionTypes';
import { fetchScenarioUpdateStatus } from './apis';

export const updateScenarioStatus =
  makeSetPropValueAction<ScenarioUpdateStatus>(SET_SCENARIO_UPDATE_STATUS);

export function getScenarioUpdateStatus(
  scenarioKey: string,
  projectId: ProjectId,
) {
  return (dispatch: Dispatch, getState, { client }) =>
    fetchScenarioUpdateStatus(
      scenarioKey,
      projectId,
    )(client)
      .then((result: ScenarioUpdate) => {
        dispatch(
          updateScenarioStatus(scenarioKey, {
            status: UpdateStatusTypes.IN_PROGRESS,
            info: result,
          }),
        );
      })
      .catch(error => {
        // a 404 here means that there is no update in progress
        if (error.status === 404) {
          dispatch(
            updateScenarioStatus(scenarioKey, {
              status: UpdateStatusTypes.NOT_IN_PROGRESS,
              info: {} as ScenarioUpdate,
            }),
          );
        }
      });
}
