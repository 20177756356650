import {
  Button,
  Classes,
  Icon,
  Menu,
  MenuItem,
  Popover,
  Position,
  Spinner,
} from '@blueprintjs/core';
import classNames from 'classnames';
import React, { FunctionComponent, useCallback } from 'react';

import { ScenarioMetadata } from 'uf-api';
import buttonStyles from 'uf/styles/buttons.module.css';
import popupStyles from 'uf/styles/popups.module.css';
import { IconSize } from 'uf/ui/base/icon';

import styles from './ScenarioBar.module.css';

const popoverMenuStyle = classNames(
  popupStyles.dropdownLarge,
  popupStyles.dropdown,
  popupStyles.maxLargeWidth,
);

interface OwnProps {
  scenarios: ScenarioMetadata[];
  pendingScenarioIds: string[];
  onItemClick: (scenario: ScenarioMetadata) => void;
}
const ScenarioOverflowMenu: React.FC<OwnProps> = props => {
  const { pendingScenarioIds, scenarios, onItemClick } = props;

  return (
    <Popover
      position={Position.BOTTOM_LEFT}
      popoverClassName={popoverMenuStyle}
      content={
        <Menu>
          {scenarios.map(scenario => (
            <ScenarioMenuItem
              key={scenario.full_path}
              scenario={scenario}
              isPending={pendingScenarioIds.includes(scenario.full_path)}
              onItemClick={onItemClick}
            />
          ))}
        </Menu>
      }>
      <Button
        minimal
        icon={<Icon icon="more" className={styles.moreMenuIcon} />}
        className={classNames(
          Classes.TEXT_OVERFLOW_ELLIPSIS,
          styles.moreMenuButton,
          buttonStyles.squareBottom,
        )}
      />
    </Popover>
  );
};

export default ScenarioOverflowMenu;
interface ScenarioMenuItemProps {
  scenario: ScenarioMetadata;
  isPending: boolean;
  onItemClick: (scenario: ScenarioMetadata) => void;
}

const ScenarioMenuItem: FunctionComponent<ScenarioMenuItemProps> = ({
  scenario,
  isPending,
  onItemClick,
}) => {
  const onClick = useCallback(
    () => onItemClick(scenario),
    [onItemClick, scenario],
  );
  return (
    <MenuItem
      disabled={isPending}
      onClick={onClick}
      text={scenario.name}
      icon={
        isPending ? <Spinner size={IconSize.SMALL} intent="primary" /> : null
      }
    />
  );
};
