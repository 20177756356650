/**
 * UrbanFootprint App API
 * API for serving UrbanFootprint Application
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Return True when `value` contains `pattern`.
 */
export interface ExpressionLike<Tvalue extends object = any> {
  /**
   * If the value is case sensitive. (default is case insensitive)
   */
  case_sensitive?: boolean;
  /**
   * Expression function, set to \"like\".
   */
  fn: ExpressionLike.FnEnum;
  /**
   * the pattern to compare against
   */
  pattern: string;
  /**
   * the value to test
   */
  value: Tvalue;
}
export namespace ExpressionLike {
  export type FnEnum = 'like';
  export const FnEnum = {
    Like: 'like',
  } as const;
}
