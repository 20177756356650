import {
  mdiCircleMedium,
  mdiScatterPlot,
  mdiVectorLine,
  mdiVectorSquare,
} from '@mdi/js';
import { GeoJsonGeometryTypes } from 'geojson';
import React, { FunctionComponent, useCallback } from 'react';

import { typeAssertNever } from 'uf/base/never';
import { LayerId } from 'uf/layers';
import { ProjectId } from 'uf/projects';
import { AnnotatedLayerReference } from 'uf/projects/selectors/availableLayers';
import CollapsibleList, {
  CollapsibleListProps,
} from 'uf/ui/base/CollapsibleList/CollapsibleList';

import CategoryListItem from './CategoryListItem';

interface CategoryListProps extends CollapsibleListProps {
  projectId: ProjectId;
  layerReferences: AnnotatedLayerReference[];
  onLayerClick: (layerId: LayerId) => void;
}

const CategoryList: FunctionComponent<CategoryListProps> = ({
  layerReferences,
  onLayerClick,
  projectId,
  ...collapsibleListProps
}) => {
  return (
    <CollapsibleList {...collapsibleListProps}>
      {layerReferences.map(layerReference => {
        return (
          <CategoryListLayerItem
            key={layerReference.layer.full_path}
            layerReference={layerReference}
            onLayerClick={onLayerClick}
            projectId={projectId}
          />
        );
      })}
    </CollapsibleList>
  );
};
export default CategoryList;
interface CategoryListLayerItemProps {
  layerReference: AnnotatedLayerReference;
  onLayerClick: (layerId: LayerId) => void;
  projectId: ProjectId;
}

const CategoryListLayerItem: FunctionComponent<CategoryListLayerItemProps> = ({
  layerReference,
  onLayerClick,
  projectId,
}) => {
  const {
    active,
    restricted,
    selected,
    layer: { full_path: layerId, details = {} },
  } = layerReference;

  let itemIcon = mdiCircleMedium;
  const geomType = details?.display_hints
    ?.uf_geometry_type as GeoJsonGeometryTypes;
  switch (geomType) {
    case 'LineString':
    case 'MultiLineString':
      itemIcon = mdiVectorLine;
      break;
    case 'Point':
    case 'MultiPoint':
      itemIcon = mdiScatterPlot;
      break;
    case 'Polygon':
    case 'MultiPolygon':
      itemIcon = mdiVectorSquare;
      break;
    case 'GeometryCollection':
      break;
    default:
      typeAssertNever(geomType);
  }

  const onSelectItem = useCallback(
    () => onLayerClick(layerId),
    [layerId, onLayerClick],
  );
  const li = (
    <CategoryListItem
      projectId={projectId}
      layerId={layerId}
      title={details.name}
      iconPath={itemIcon}
      active={active}
      disabled={selected && restricted}
      selected={selected}
      onSelectItem={onSelectItem}
    />
  );
  return li;
};
