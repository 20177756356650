import classNames from 'classnames';
import React, {
  CSSProperties,
  Fragment,
  FunctionComponent,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import FlipMove from 'react-flip-move';
import { Box } from 'react-layout-components';
import { useDispatch, useSelector } from 'react-redux';
import { jt, t } from 'ttag';

import { LayerReference } from 'uf-api';
import { arrayMoveElement } from 'uf/base/array';
import { LayerInfoWithExtrusion } from 'uf/explore/layerOrdering';
import { ColumnKey, LayerId } from 'uf/layers';
import { FilterSpec } from 'uf/layers/filters';
import { LayerRole } from 'uf/layers/state';
import { ProjectId } from 'uf/projects';
import { updateLayerName } from 'uf/projects/actions/dynamicLayers';
import { LegacyVirtualLayerId } from 'uf/projects/virtualLayers';
import rootStyles from 'uf/styles/root.module.css';
import ConfirmationModal from 'uf/ui/base/Modals/Confirmation';
import { LayerIconPathByRole } from 'uf/ui/layers/icons';
import layerListItemStyles from 'uf/ui/layers/LayerListItem/LayerListItem.module.css';
import SaveWorkingLayerPrompt from 'uf/ui/projects/SaveWorkingLayerButton/SaveWorkingLayerPrompt';
import useUserFlag from 'uf/ui/user/useUserFlag';
import { ViewId } from 'uf/views';

import DraggableLayerListItem, { ListItemData } from './DraggableLayerListItem';
import { selectZoomExtentDialog } from 'uf/layers/reducer/zoomExtentSlice';
import LayerZoomExtentDialog from 'uf/ui/layers/LayerZoomExtentDialog/LayerZoomExtentDialog';

const layerListClassName = classNames(
  rootStyles.flexBox,
  rootStyles.flexColumn,
);
interface Props {
  droppableId: string;
  style?: CSSProperties;
  layers: LayerInfoWithExtrusion[];
  getLayerRole: (layerId: LayerId) => LayerRole;
  deletableLayerIds: LayerId[];
  visibleLayerIds: LayerId[];
  activeLayerId: LayerId;
  baseCanvasId: LayerId;
  projectId: ProjectId;
  viewId: ViewId;
  onActivateLayer: (
    projectId: ProjectId,
    virtualLayerId: LegacyVirtualLayerId,
    active: boolean,
  ) => void;
  onOpenFilterList: (layerId: LayerId) => void;
  flyoutPanelVisible: boolean;
  showPaintedOnly: Record<LayerId, boolean>;
  onChangeLayerVisibility: (
    projectId: ProjectId,
    virtualLayerId: LegacyVirtualLayerId,
    viewId: ViewId,
    visible: boolean,
  ) => void;
  onOrderLayer: (layerOrderKeys: LegacyVirtualLayerId[]) => void;
  onDeleteLayer: (layerId: LayerId) => void;
  onEnableFilter: (
    projectId: ProjectId,
    layerId: LayerId,
    enabled: boolean,
  ) => void;
  layerFiltersGetter: (
    layerId: LayerId,
    parentLayerId: LayerId,
  ) => Partial<FilterSpec>;
  onShowPaintedOnly: (layerId: LayerId, showPaintedOnly: boolean) => void;
  onToggleExtrusion: (layerId: LayerId, columnKey: ColumnKey) => void;
}

const LayerList: FunctionComponent<Props> = props => {
  const {
    onOrderLayer,
    droppableId,
    layers,
    getLayerRole,
    visibleLayerIds,
    activeLayerId,
    baseCanvasId,
    projectId,
    viewId,
    layerFiltersGetter,
    onActivateLayer,
    onOpenFilterList,
    flyoutPanelVisible,
    showPaintedOnly,
    deletableLayerIds,
    onEnableFilter,
    onChangeLayerVisibility,
    onDeleteLayer,
    onShowPaintedOnly,
    onToggleExtrusion,
  } = props;
  const showZoomExtentOption = useUserFlag('layer-zoom-extent');
  const zoomExtentDialog = useSelector(selectZoomExtentDialog);
  const dispatch = useDispatch();
  const [pendingDeleteLayer, setPendingDeleteLayer] =
    useState<LayerReference>(null);
  const [pendingUpdateLayer, setPendingUpdateLayer] =
    useState<LayerReference>(null);
  const onDragEnd = useCallback(
    (result: DropResult) => {
      const order = layers.map(({ layerOrderKey }) => layerOrderKey);

      if (!result.destination || !result.source) {
        return;
      }

      const newOrder = arrayMoveElement(
        order,
        result.source.index,
        result.destination.index,
      );

      onOrderLayer(newOrder);
    },
    [layers, onOrderLayer],
  );

  const items: ListItemData[] = useMemo(
    () =>
      makeItems(
        layers,
        activeLayerId,
        baseCanvasId,
        visibleLayerIds,
        showPaintedOnly,
        deletableLayerIds,
        getLayerRole,
        layerFiltersGetter,
        setPendingDeleteLayer,
        setPendingUpdateLayer,
      ),
    [
      activeLayerId,
      baseCanvasId,
      deletableLayerIds,
      getLayerRole,
      layerFiltersGetter,
      layers,
      showPaintedOnly,
      visibleLayerIds,
    ],
  );

  const onExitDeleteModal = useCallback(() => setPendingDeleteLayer(null), []);
  const onExitUpdateModal = useCallback(() => setPendingUpdateLayer(null), []);
  const onSubmitDelete = useCallback(
    () => onDeleteLayer(pendingDeleteLayer.full_path),
    [onDeleteLayer, pendingDeleteLayer?.full_path],
  );
  const onSubmitUpdate = useCallback(
    async (input: { name: string }) => {
      await dispatch(
        updateLayerName(pendingUpdateLayer.full_path, projectId, input.name),
      );
      setPendingUpdateLayer(null);
    },
    [dispatch, pendingUpdateLayer, projectId],
  );
  const confirmDeleteMessage = useMemo(() => {
    if (!pendingDeleteLayer) {
      return null;
    }
    const layerName = <b key="name">{pendingDeleteLayer.details.name}</b>;

    return pendingDeleteLayer ? (
      <h5>{jt`Delete layer ${layerName}?`}</h5>
    ) : null;
  }, [pendingDeleteLayer]);

  const showLayerId = useUserFlag('dev-show-debug-ids');
  return (
    <Fragment>
      <ConfirmationModal
        title={t`Delete layer`}
        primaryButtonStyle="danger"
        submitMessage={confirmDeleteMessage}
        show={!!pendingDeleteLayer}
        submitText={t`Delete`}
        onCancel={onExitDeleteModal}
        onSubmit={onSubmitDelete}
      />
      <SaveWorkingLayerPrompt
        show={!!pendingUpdateLayer}
        title={t`Change layer name`}
        onOk={onSubmitUpdate}
        onCancel={onExitUpdateModal}
        defaults={{ name: pendingUpdateLayer?.details?.name }}
      />
      {showZoomExtentOption && zoomExtentDialog.isOpen && (
        <LayerZoomExtentDialog />
      )}
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={droppableId}>
          {(provided, s) => (
            <div
              /*
               * Use vanilla div so we can pass the innerRef to an actual DOM
               * node. TODO: Switch to <Box> once ref support lands:
               * https://github.com/robinweser/react-layout-components/pull/53
               */
              ref={provided.innerRef}
              className={layerListClassName}
              {...provided.droppableProps}>
              <FlipMove>
                {items.map((item: ListItemData, index) => (
                  <DraggableLayerListItem
                    key={item.itemKey}
                    index={index}
                    projectId={projectId}
                    viewId={viewId}
                    onActivateLayer={onActivateLayer}
                    onChangeLayerVisibility={onChangeLayerVisibility}
                    onEnableFilter={onEnableFilter}
                    onOpenFilterList={onOpenFilterList}
                    onShowPaintedOnly={onShowPaintedOnly}
                    onToggleExtrusion={onToggleExtrusion}
                    flyoutPanelVisible={flyoutPanelVisible}
                    showLayerId={showLayerId}
                    {...item}
                  />
                ))}
              </FlipMove>
              {provided.placeholder && (
                <Box
                  alignItems="flex-end"
                  className={layerListItemStyles.dragAndDropPlaceholder}>
                  {provided.placeholder}
                </Box>
              )}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </Fragment>
  );
};
export default LayerList;

function makeItems(
  layers: LayerInfoWithExtrusion[],
  activeLayerId: LayerId,
  baseCanvasId: LayerId,
  visibleLayerIds: LayerId[],
  showPaintedOnly: Record<LayerId, boolean>,
  deletableLayerIds: LayerId[],
  getLayerRole: (layerId: LayerId) => LayerRole,
  layerFiltersGetter: (
    layerId: LayerId,
    parentLayerId: LayerId,
  ) => Partial<FilterSpec>,
  setPendingDeleteLayer: React.Dispatch<React.SetStateAction<LayerReference>>,
  setPendingUpdateLayer: React.Dispatch<React.SetStateAction<LayerReference>>,
): ListItemData[] {
  return layers.map(
    ({
      layerInfo: layer,
      extrudedColumn,
      layerOrderKey: virtualLayerId,
      isPlaceholderLayer,
      status,
    }): ListItemData => {
      const layerIconPath = LayerIconPathByRole[getLayerRole(layer.full_path)];
      return {
        layer,
        layerId: layer.full_path,
        virtualLayerId,
        layerIconPath,
        isPlaceholderLayer,
        extrudedColumn,
        isLoading: !!status?.loading,
        statusMessage: status?.message || '',
        itemKey: virtualLayerId,
        active: layer.full_path === activeLayerId,
        isBaseCanvas: layer.full_path === baseCanvasId,
        visible: !!visibleLayerIds.includes(layer.full_path),
        filters: layerFiltersGetter(layer.full_path, null),
        showPaintedOnly: !!showPaintedOnly[layer.full_path],
        ...(deletableLayerIds.includes(layer.full_path) && {
          onDelete: setPendingDeleteLayer,
          onUpdateSelect: setPendingUpdateLayer,
        }),
      };
    },
  );
}
