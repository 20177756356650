import colors from 'material-colors';
import { t } from 'ttag';

import { getUnitName, SQ_FT } from 'uf/base/units';
import { LegacyChart, LegacyReport } from 'uf/reporting/index';

const IN_SENTENCE = true;

const populationChart: LegacyChart = {
  name: t`Population`,
  key: 'pop',
  subtitle: t`Number of residents`,
  series: [
    {
      key: 'development-pop',
      source: 'canvas',
      sourceKey: 'painted_layer',
      columnKey: 'pop',
      stat: 'sum',
    },
  ],
};

const dwellingUnitsChart: LegacyChart = {
  name: t`Housing`,
  key: 'du',
  subtitle: t`Number of dwelling units`,
  series: [
    {
      key: 'development-du',
      source: 'canvas',
      sourceKey: 'painted_layer',
      columnKey: 'du',
      stat: 'sum',
    },
  ],
};

const householdsChart: LegacyChart = {
  name: t`Households`,
  key: 'hh',
  subtitle: t`Number of households`,
  series: [
    {
      key: 'development-hh',
      source: 'canvas',
      sourceKey: 'painted_layer',
      columnKey: 'hh',
      stat: 'sum',
    },
  ],
};

const employmentChart: LegacyChart = {
  name: t`Jobs`,
  key: 'emp',
  subtitle: t`Number of employees`,
  series: [
    {
      key: 'development-emp',
      source: 'canvas',
      sourceKey: 'painted_layer',
      columnKey: 'emp',
      stat: 'sum',
    },
  ],
};

const dwellingUnitsByTypeChart: LegacyChart = {
  name: t`Housing by Type`,
  key: 'du-by-type',
  subtitle: t`Number of dwelling units`,
  series: [
    {
      key: 'du_detsf_ll',
      source: 'canvas',
      sourceKey: 'painted_layer',
      columnKey: 'du_detsf_ll',
      stat: 'sum',
    },
    {
      key: 'du_detsf_sl',
      source: 'canvas',
      sourceKey: 'painted_layer',
      columnKey: 'du_detsf_sl',
      stat: 'sum',
    },
    {
      key: 'du_attsf',
      source: 'canvas',
      sourceKey: 'painted_layer',
      columnKey: 'du_attsf',
      stat: 'sum',
    },
    {
      key: 'du_mf',
      source: 'canvas',
      sourceKey: 'painted_layer',
      columnKey: 'du_mf',
      stat: 'sum',
    },
  ],
};

const employmentByTypeChart: LegacyChart = {
  name: t`Jobs by Sector`,
  key: 'emp-by-type',
  subtitle: t`Number of employees`,
  series: [
    {
      key: 'emp_ret',
      source: 'canvas',
      sourceKey: 'painted_layer',
      columnKey: 'emp_ret',
      stat: 'sum',
    },
    {
      key: 'emp_off',
      source: 'canvas',
      sourceKey: 'painted_layer',
      columnKey: 'emp_off',
      stat: 'sum',
    },
    {
      key: 'emp_pub',
      source: 'canvas',
      sourceKey: 'painted_layer',
      columnKey: 'emp_pub',
      stat: 'sum',
    },
    {
      key: 'emp_ind',
      source: 'canvas',
      sourceKey: 'painted_layer',
      columnKey: 'emp_ind',
      stat: 'sum',
    },
    {
      key: 'emp_ag',
      source: 'canvas',
      sourceKey: 'painted_layer',
      columnKey: 'emp_ag',
      stat: 'sum',
    },
    {
      key: 'emp_military',
      source: 'canvas',
      sourceKey: 'painted_layer',
      columnKey: 'emp_military',
      stat: 'sum',
    },
  ],
};

const buildingAreaResidential: LegacyChart = {
  name: t`Building Area: Residential`,
  key: 'bldg-area-res',
  subtitle: t`Floor area`,
  units: getUnitName(SQ_FT, IN_SENTENCE),
  series: [
    {
      key: 'bldg_area_detsf_ll',
      source: 'canvas',
      sourceKey: 'painted_layer',
      columnKey: 'bldg_area_detsf_ll',
      stat: 'sum',
    },
    {
      key: 'bldg_area_detsf_sl',
      source: 'canvas',
      sourceKey: 'painted_layer',
      columnKey: 'bldg_area_detsf_sl',
      stat: 'sum',
    },
    {
      key: 'bldg_area_attsf',
      source: 'canvas',
      sourceKey: 'painted_layer',
      columnKey: 'bldg_area_attsf',
      stat: 'sum',
    },
    {
      key: 'bldg_area_mf',
      source: 'canvas',
      sourceKey: 'painted_layer',
      columnKey: 'bldg_area_mf',
      stat: 'sum',
    },
  ],
};

const buildingAreaRetail: LegacyChart = {
  name: t`Building Area: Retail`,
  key: 'bldg-area-retail',
  subtitle: t`Floor area`,
  units: getUnitName(SQ_FT, IN_SENTENCE),
  series: [
    {
      key: 'bldg_area_retail_services',
      source: 'canvas',
      sourceKey: 'painted_layer',
      columnKey: 'bldg_area_retail_services',
      stat: 'sum',
    },
    {
      key: 'bldg_area_restaurant',
      source: 'canvas',
      sourceKey: 'painted_layer',
      columnKey: 'bldg_area_restaurant',
      stat: 'sum',
    },
    {
      key: 'bldg_area_arts_entertainment',
      source: 'canvas',
      sourceKey: 'painted_layer',
      columnKey: 'bldg_area_arts_entertainment',
      stat: 'sum',
    },
    {
      key: 'bldg_area_accommodation',
      source: 'canvas',
      sourceKey: 'painted_layer',
      columnKey: 'bldg_area_accommodation',
      stat: 'sum',
    },
    {
      key: 'bldg_area_other_services',
      source: 'canvas',
      sourceKey: 'painted_layer',
      columnKey: 'bldg_area_other_services',
      stat: 'sum',
    },
  ],
};

const buildingAreaOffice: LegacyChart = {
  name: t`Building Area: Office`,
  key: 'bldg-area-office',
  subtitle: t`Floor area`,
  units: getUnitName(SQ_FT, IN_SENTENCE),
  series: [
    {
      key: 'bldg_area_office_services',
      source: 'canvas',
      sourceKey: 'painted_layer',
      columnKey: 'bldg_area_office_services',
      stat: 'sum',
    },
    {
      key: 'bldg_area_medical_services',
      source: 'canvas',
      sourceKey: 'painted_layer',
      columnKey: 'bldg_area_medical_services',
      stat: 'sum',
    },
  ],
};

const buildingAreaPublic: LegacyChart = {
  name: t`Building Area: Public`,
  key: 'bldg-area-public',
  subtitle: t`Floor area`,
  units: getUnitName(SQ_FT, IN_SENTENCE),
  series: [
    {
      key: 'bldg_area_public_admin',
      source: 'canvas',
      sourceKey: 'painted_layer',
      columnKey: 'bldg_area_public_admin',
      stat: 'sum',
    },
    {
      key: 'bldg_area_education',
      source: 'canvas',
      sourceKey: 'painted_layer',
      columnKey: 'bldg_area_education',
      stat: 'sum',
    },
  ],
};

const buildingAreaIndustrial: LegacyChart = {
  name: t`Building Area: Industrial`,
  key: 'bldg-area-industrial',
  subtitle: t`Floor area`,
  units: getUnitName(SQ_FT, IN_SENTENCE),
  series: [
    {
      key: 'bldg_area_transport_warehousing',
      source: 'canvas',
      sourceKey: 'painted_layer',
      columnKey: 'bldg_area_transport_warehousing',
      stat: 'sum',
    },
    {
      key: 'bldg_area_wholesale',
      source: 'canvas',
      sourceKey: 'painted_layer',
      columnKey: 'bldg_area_wholesale',
      stat: 'sum',
    },
  ],
};

export const paintTrackingCharts: LegacyReport = Object.freeze({
  key: 'paint_tracking',
  name: t`Summary Statistics`,
  colorSpec: {
    primaryColor: colors.indigo[400],
    interpolateFromColor: colors.indigo[100],
    interpolateToColor: colors.indigo[900],
  },
  charts: [
    populationChart,
    dwellingUnitsChart,
    householdsChart,
    employmentChart,
    dwellingUnitsByTypeChart,
    employmentByTypeChart,
    buildingAreaResidential,
    buildingAreaRetail,
    buildingAreaOffice,
    buildingAreaPublic,
    buildingAreaIndustrial,
  ],
});
