import {
  CuratedColumnSymbology,
  CUSTOM_THEME,
  DEFAULT_OPACITY,
  InterpolationTypes,
  PaintColorTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/public/dataset/usfs_pacific_northwest_burn_probability_2018_or_and_wa': {
    fire_risk: [
      {
        type: SymbologyTypes.FILL,
        paint: {
          'fill-color': {
            property: 'fire_risk',
            type: PaintColorTypes.CATEGORICAL,
            stops: [
              { value: 'No Risk', color: '#808080' },
              { value: 'Non-Wildland/Non-Urban', color: '#A4DD00' },
              { value: 'Very Low', color: '#3672fa' },
              { value: 'Low', color: '#00dbfd' },
              { value: 'Moderate', color: '#FF7F0E' },
              { value: 'High', color: '#D33115' },
              { value: 'Very High', color: '#9F0500' },
            ],
          },
          'fill-opacity': DEFAULT_OPACITY,
        },
        display_hints: {
          scale: SymbologyScales.CATEGORICAL,
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          theme: CUSTOM_THEME,
        },
      },
      {
        type: SymbologyTypes.LINE,
        paint: {
          'line-color': {
            property: 'fire_risk',
            type: PaintColorTypes.CATEGORICAL,
            stops: [
              { value: 'No Risk', color: '#808080' },
              { value: 'Non-Wildland/Non-Urban', color: '#A4DD00' },
              { value: 'Very Low', color: '#3672fa' },
              { value: 'Low', color: '#00dbfd' },
              { value: 'Moderate', color: '#FF7F0E' },
              { value: 'High', color: '#D33115' },
              { value: 'Very High', color: '#9F0500' },
            ],
          },
          'line-opacity': DEFAULT_OPACITY,
        },
        display_hints: {
          scale: SymbologyScales.CATEGORICAL,
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          theme: CUSTOM_THEME,
        },
      },
    ],
  },
};

export default symbologies;
