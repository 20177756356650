/**
 * UrbanFootprint App API
 * API for serving UrbanFootprint Application
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { ClientResponse } from '../variables';

// @ts-ignore: This is used only for params, but some services have param-free APIs
import { PortfolioServiceInterface } from './portfolio.serviceInterface';

// @ts-ignore: These imports are for both params and returnType, but we only use returnType
import { PortfolioInfo } from '../model/portfolioInfo';
// @ts-ignore: These imports are for both params and returnType, but we only use returnType
import { ProblemDetail } from '../model/problemDetail';

/**
 * Package: api
 */

const addOrgToPortfolioPrefix = `uf-api/portfolio/add_org_to_portfolio/`;
/**
 * Action types for portfolio.add_org_to_portfolio
 *
 * Assign an organization to a specified portfolio.
 */
export const addOrgToPortfolioActionTypes = {
  LOAD: `${addOrgToPortfolioPrefix}LOAD` as 'uf-api/portfolio/add_org_to_portfolio/LOAD',
  SUCCESS:
    `${addOrgToPortfolioPrefix}SUCCESS` as 'uf-api/portfolio/add_org_to_portfolio/SUCCESS',
  FAILURE:
    `${addOrgToPortfolioPrefix}FAILURE` as 'uf-api/portfolio/add_org_to_portfolio/FAILURE',
  CLEAR:
    `${addOrgToPortfolioPrefix}CLEAR` as 'uf-api/portfolio/add_org_to_portfolio/CLEAR',
} as const;

/**
 * LOAD action for portfolio.add_org_to_portfolio
 * Assign an organization to a specified portfolio.
 */
export interface addOrgToPortfolioLoadAction {
  readonly type: 'uf-api/portfolio/add_org_to_portfolio/LOAD';
  readonly params: PortfolioServiceInterface.addOrgToPortfolioParams;
  readonly promise: Promise<ClientResponse<{}>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for portfolio.add_org_to_portfolio
 * Assign an organization to a specified portfolio.
 */
export interface addOrgToPortfolioSuccessAction {
  readonly type: 'uf-api/portfolio/add_org_to_portfolio/SUCCESS';
  readonly result: {};
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for portfolio.add_org_to_portfolio
 * Assign an organization to a specified portfolio.
 */
export interface addOrgToPortfolioFailureAction {
  readonly type: 'uf-api/portfolio/add_org_to_portfolio/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for portfolio.add_org_to_portfolio
 * Assign an organization to a specified portfolio.
 */
export interface addOrgToPortfolioClearAction {
  readonly type: 'uf-api/portfolio/add_org_to_portfolio/CLEAR';
  readonly key: string;
}

const createPortfolioPrefix = `uf-api/portfolio/create_portfolio/`;
/**
 * Action types for portfolio.create_portfolio
 *
 * Create a portfolio.
 */
export const createPortfolioActionTypes = {
  LOAD: `${createPortfolioPrefix}LOAD` as 'uf-api/portfolio/create_portfolio/LOAD',
  SUCCESS:
    `${createPortfolioPrefix}SUCCESS` as 'uf-api/portfolio/create_portfolio/SUCCESS',
  FAILURE:
    `${createPortfolioPrefix}FAILURE` as 'uf-api/portfolio/create_portfolio/FAILURE',
  CLEAR:
    `${createPortfolioPrefix}CLEAR` as 'uf-api/portfolio/create_portfolio/CLEAR',
} as const;

/**
 * LOAD action for portfolio.create_portfolio
 * Create a portfolio.
 */
export interface createPortfolioLoadAction {
  readonly type: 'uf-api/portfolio/create_portfolio/LOAD';
  readonly params: PortfolioServiceInterface.createPortfolioParams;
  readonly promise: Promise<ClientResponse<PortfolioInfo>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for portfolio.create_portfolio
 * Create a portfolio.
 */
export interface createPortfolioSuccessAction {
  readonly type: 'uf-api/portfolio/create_portfolio/SUCCESS';
  readonly result: PortfolioInfo;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for portfolio.create_portfolio
 * Create a portfolio.
 */
export interface createPortfolioFailureAction {
  readonly type: 'uf-api/portfolio/create_portfolio/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for portfolio.create_portfolio
 * Create a portfolio.
 */
export interface createPortfolioClearAction {
  readonly type: 'uf-api/portfolio/create_portfolio/CLEAR';
  readonly key: string;
}

const deletePortfolioPrefix = `uf-api/portfolio/delete_portfolio/`;
/**
 * Action types for portfolio.delete_portfolio
 *
 * Delete a portfolio.
 */
export const deletePortfolioActionTypes = {
  LOAD: `${deletePortfolioPrefix}LOAD` as 'uf-api/portfolio/delete_portfolio/LOAD',
  SUCCESS:
    `${deletePortfolioPrefix}SUCCESS` as 'uf-api/portfolio/delete_portfolio/SUCCESS',
  FAILURE:
    `${deletePortfolioPrefix}FAILURE` as 'uf-api/portfolio/delete_portfolio/FAILURE',
  CLEAR:
    `${deletePortfolioPrefix}CLEAR` as 'uf-api/portfolio/delete_portfolio/CLEAR',
} as const;

/**
 * LOAD action for portfolio.delete_portfolio
 * Delete a portfolio.
 */
export interface deletePortfolioLoadAction {
  readonly type: 'uf-api/portfolio/delete_portfolio/LOAD';
  readonly params: PortfolioServiceInterface.deletePortfolioParams;
  readonly promise: Promise<ClientResponse<{}>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for portfolio.delete_portfolio
 * Delete a portfolio.
 */
export interface deletePortfolioSuccessAction {
  readonly type: 'uf-api/portfolio/delete_portfolio/SUCCESS';
  readonly result: {};
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for portfolio.delete_portfolio
 * Delete a portfolio.
 */
export interface deletePortfolioFailureAction {
  readonly type: 'uf-api/portfolio/delete_portfolio/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for portfolio.delete_portfolio
 * Delete a portfolio.
 */
export interface deletePortfolioClearAction {
  readonly type: 'uf-api/portfolio/delete_portfolio/CLEAR';
  readonly key: string;
}

const getPortfolioPrefix = `uf-api/portfolio/get_portfolio/`;
/**
 * Action types for portfolio.get_portfolio
 *
 * Get a portfolio.
 */
export const getPortfolioActionTypes = {
  LOAD: `${getPortfolioPrefix}LOAD` as 'uf-api/portfolio/get_portfolio/LOAD',
  SUCCESS:
    `${getPortfolioPrefix}SUCCESS` as 'uf-api/portfolio/get_portfolio/SUCCESS',
  FAILURE:
    `${getPortfolioPrefix}FAILURE` as 'uf-api/portfolio/get_portfolio/FAILURE',
  CLEAR: `${getPortfolioPrefix}CLEAR` as 'uf-api/portfolio/get_portfolio/CLEAR',
} as const;

/**
 * LOAD action for portfolio.get_portfolio
 * Get a portfolio.
 */
export interface getPortfolioLoadAction {
  readonly type: 'uf-api/portfolio/get_portfolio/LOAD';
  readonly params: PortfolioServiceInterface.getPortfolioParams;
  readonly promise: Promise<ClientResponse<PortfolioInfo>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for portfolio.get_portfolio
 * Get a portfolio.
 */
export interface getPortfolioSuccessAction {
  readonly type: 'uf-api/portfolio/get_portfolio/SUCCESS';
  readonly result: PortfolioInfo;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for portfolio.get_portfolio
 * Get a portfolio.
 */
export interface getPortfolioFailureAction {
  readonly type: 'uf-api/portfolio/get_portfolio/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for portfolio.get_portfolio
 * Get a portfolio.
 */
export interface getPortfolioClearAction {
  readonly type: 'uf-api/portfolio/get_portfolio/CLEAR';
  readonly key: string;
}

const listPortfoliosPrefix = `uf-api/portfolio/list_portfolios/`;
/**
 * Action types for portfolio.list_portfolios
 *
 * List active portfolios.
 */
export const listPortfoliosActionTypes = {
  LOAD: `${listPortfoliosPrefix}LOAD` as 'uf-api/portfolio/list_portfolios/LOAD',
  SUCCESS:
    `${listPortfoliosPrefix}SUCCESS` as 'uf-api/portfolio/list_portfolios/SUCCESS',
  FAILURE:
    `${listPortfoliosPrefix}FAILURE` as 'uf-api/portfolio/list_portfolios/FAILURE',
  CLEAR:
    `${listPortfoliosPrefix}CLEAR` as 'uf-api/portfolio/list_portfolios/CLEAR',
} as const;

/**
 * LOAD action for portfolio.list_portfolios
 * List active portfolios.
 */
export interface listPortfoliosLoadAction {
  readonly type: 'uf-api/portfolio/list_portfolios/LOAD';
  readonly params: PortfolioServiceInterface.listPortfoliosParams;
  readonly promise: Promise<ClientResponse<Array<PortfolioInfo>>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for portfolio.list_portfolios
 * List active portfolios.
 */
export interface listPortfoliosSuccessAction {
  readonly type: 'uf-api/portfolio/list_portfolios/SUCCESS';
  readonly result: Array<PortfolioInfo>;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for portfolio.list_portfolios
 * List active portfolios.
 */
export interface listPortfoliosFailureAction {
  readonly type: 'uf-api/portfolio/list_portfolios/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for portfolio.list_portfolios
 * List active portfolios.
 */
export interface listPortfoliosClearAction {
  readonly type: 'uf-api/portfolio/list_portfolios/CLEAR';
  readonly key: string;
}

const listUserPortfoliosPrefix = `uf-api/portfolio/list_user_portfolios/`;
/**
 * Action types for portfolio.list_user_portfolios
 *
 * List user portfolios.
 */
export const listUserPortfoliosActionTypes = {
  LOAD: `${listUserPortfoliosPrefix}LOAD` as 'uf-api/portfolio/list_user_portfolios/LOAD',
  SUCCESS:
    `${listUserPortfoliosPrefix}SUCCESS` as 'uf-api/portfolio/list_user_portfolios/SUCCESS',
  FAILURE:
    `${listUserPortfoliosPrefix}FAILURE` as 'uf-api/portfolio/list_user_portfolios/FAILURE',
  CLEAR:
    `${listUserPortfoliosPrefix}CLEAR` as 'uf-api/portfolio/list_user_portfolios/CLEAR',
} as const;

/**
 * LOAD action for portfolio.list_user_portfolios
 * List user portfolios.
 */
export interface listUserPortfoliosLoadAction {
  readonly type: 'uf-api/portfolio/list_user_portfolios/LOAD';
  readonly params: PortfolioServiceInterface.listUserPortfoliosParams;
  readonly promise: Promise<ClientResponse<Array<PortfolioInfo>>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for portfolio.list_user_portfolios
 * List user portfolios.
 */
export interface listUserPortfoliosSuccessAction {
  readonly type: 'uf-api/portfolio/list_user_portfolios/SUCCESS';
  readonly result: Array<PortfolioInfo>;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for portfolio.list_user_portfolios
 * List user portfolios.
 */
export interface listUserPortfoliosFailureAction {
  readonly type: 'uf-api/portfolio/list_user_portfolios/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for portfolio.list_user_portfolios
 * List user portfolios.
 */
export interface listUserPortfoliosClearAction {
  readonly type: 'uf-api/portfolio/list_user_portfolios/CLEAR';
  readonly key: string;
}

const updatePortfolioPrefix = `uf-api/portfolio/update_portfolio/`;
/**
 * Action types for portfolio.update_portfolio
 *
 * Update a portfolio.
 */
export const updatePortfolioActionTypes = {
  LOAD: `${updatePortfolioPrefix}LOAD` as 'uf-api/portfolio/update_portfolio/LOAD',
  SUCCESS:
    `${updatePortfolioPrefix}SUCCESS` as 'uf-api/portfolio/update_portfolio/SUCCESS',
  FAILURE:
    `${updatePortfolioPrefix}FAILURE` as 'uf-api/portfolio/update_portfolio/FAILURE',
  CLEAR:
    `${updatePortfolioPrefix}CLEAR` as 'uf-api/portfolio/update_portfolio/CLEAR',
} as const;

/**
 * LOAD action for portfolio.update_portfolio
 * Update a portfolio.
 */
export interface updatePortfolioLoadAction {
  readonly type: 'uf-api/portfolio/update_portfolio/LOAD';
  readonly params: PortfolioServiceInterface.updatePortfolioParams;
  readonly promise: Promise<ClientResponse<{}>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for portfolio.update_portfolio
 * Update a portfolio.
 */
export interface updatePortfolioSuccessAction {
  readonly type: 'uf-api/portfolio/update_portfolio/SUCCESS';
  readonly result: {};
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for portfolio.update_portfolio
 * Update a portfolio.
 */
export interface updatePortfolioFailureAction {
  readonly type: 'uf-api/portfolio/update_portfolio/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for portfolio.update_portfolio
 * Update a portfolio.
 */
export interface updatePortfolioClearAction {
  readonly type: 'uf-api/portfolio/update_portfolio/CLEAR';
  readonly key: string;
}

export const portfolioActionTypesMap = {
  /** Action types for portfolio.add_org_to_portfolio */
  add_org_to_portfolio: addOrgToPortfolioActionTypes,
  /** Action types for portfolio.create_portfolio */
  create_portfolio: createPortfolioActionTypes,
  /** Action types for portfolio.delete_portfolio */
  delete_portfolio: deletePortfolioActionTypes,
  /** Action types for portfolio.get_portfolio */
  get_portfolio: getPortfolioActionTypes,
  /** Action types for portfolio.list_portfolios */
  list_portfolios: listPortfoliosActionTypes,
  /** Action types for portfolio.list_user_portfolios */
  list_user_portfolios: listUserPortfoliosActionTypes,
  /** Action types for portfolio.update_portfolio */
  update_portfolio: updatePortfolioActionTypes,
} as const;

export const portfolioDetailsMap = {
  add_org_to_portfolio: {
    /** HTTP method for portfolio.add_org_to_portfolio */
    httpMethod: 'put' as const,
  },
  create_portfolio: {
    /** HTTP method for portfolio.create_portfolio */
    httpMethod: 'post' as const,
  },
  delete_portfolio: {
    /** HTTP method for portfolio.delete_portfolio */
    httpMethod: 'delete' as const,
  },
  get_portfolio: {
    /** HTTP method for portfolio.get_portfolio */
    httpMethod: 'get' as const,
  },
  list_portfolios: {
    /** HTTP method for portfolio.list_portfolios */
    httpMethod: 'get' as const,
  },
  list_user_portfolios: {
    /** HTTP method for portfolio.list_user_portfolios */
    httpMethod: 'get' as const,
  },
  update_portfolio: {
    /** HTTP method for portfolio.update_portfolio */
    httpMethod: 'put' as const,
  },
} as const;

/**
 * Local DataState<T> - kept in sync with uf/data/dataState.ts
 */
interface DataState<T> {
  readonly loading: boolean;
  readonly loaded: boolean;
  readonly requestId: string;
  readonly requestTime?: string;
  readonly responseTime?: string;
  readonly requestCount?: number;
  readonly error?: any;
  readonly promise?: Promise<T>;
  readonly data?: T;
  readonly extra?: any;
  readonly key?: string;
}
export interface PortfolioServiceState {
  /** all recent addOrgToPortfolio results (POST/PUT/PATCH) */
  readonly add_org_to_portfolio: DataState<{}>[];
  /** all recent createPortfolio results (POST/PUT/PATCH) */
  readonly create_portfolio: DataState<PortfolioInfo>[];
  /** deletePortfolio state, by key */
  delete_portfolio: {
    readonly [key: string]: DataState<{}>;
  };
  /** getPortfolio state, by key */
  get_portfolio: {
    readonly [key: string]: DataState<PortfolioInfo>;
  };
  /** listPortfolios state, by key */
  list_portfolios: {
    readonly [key: string]: DataState<Array<PortfolioInfo>>;
  };
  /** listUserPortfolios state, by key */
  list_user_portfolios: {
    readonly [key: string]: DataState<Array<PortfolioInfo>>;
  };
  /** all recent updatePortfolio results (POST/PUT/PATCH) */
  readonly update_portfolio: DataState<{}>[];
}
