import { PaintServiceInterface } from 'uf-api/api/paint.serviceInterface';
import {
  BuiltFormPainterParams,
  PaintingActionInfo,
  SplitCanvasPainterParams,
} from 'uf-api/model/models';
import { parseFullPath } from 'uf/base/dataset';
import { makeAsyncApiCaller } from 'uf/base/xhr';
import { ClientOperation } from 'uf/data/loader';
import { Expression, getFilterClause } from 'uf/layers/filters';
import { Painter } from 'uf/painting';
import { ProjectId } from 'uf/projects';

const enqueuePaintBaseScenarioCanvas = makeAsyncApiCaller(
  apis => apis.paint.enqueue_paint_base_scenario_canvas,
);
const enqueuePaintScenarioCanvas = makeAsyncApiCaller(
  apis => apis.paint.enqueue_paint_scenario_canvas,
);

export function enqueuePaintScenarioAPI(
  projectId: ProjectId,
  scenarioKey: string,
  filters: Expression[],
  painter:
    | typeof PaintingActionInfo.PainterEnum.Undo
    | typeof PaintingActionInfo.PainterEnum.Redo,
  params: { action: string },
  isBaseScenario: boolean,
): ClientOperation<string>;
export function enqueuePaintScenarioAPI(
  projectId: ProjectId,
  scenarioKey: string,
  filters: Expression[],
  painter: typeof PaintingActionInfo.PainterEnum.BuiltForm,
  params: BuiltFormPainterParams,
  isBaseScenario: boolean,
): ClientOperation<string>;
export function enqueuePaintScenarioAPI(
  projectId: ProjectId,
  scenarioKey: null,
  filters: Expression[],
  painter: typeof PaintingActionInfo.PainterEnum.Delete,
  params: {},
  isBaseScenario: true,
): ClientOperation<string>;
export function enqueuePaintScenarioAPI(
  projectId: ProjectId,
  scenarioKey: string,
  filters: Expression[],
  painter: typeof PaintingActionInfo.PainterEnum.SplitCanvas,
  params: SplitCanvasPainterParams,
  isBaseScenario: boolean,
): ClientOperation<string>;
export function enqueuePaintScenarioAPI(
  projectId: ProjectId,
  scenarioKey: string,
  filters: Expression[],
  painter: 'revert_to_base',
  params: {},
  isBaseScenario: boolean,
): ClientOperation<string>;
export function enqueuePaintScenarioAPI<T extends object>(
  projectId: ProjectId,
  scenarioKey: string,
  filters: Expression[],
  painter: Painter,
  params: T,
  isBaseScenario: boolean,
): ClientOperation<string> {
  const { namespace, key } = parseFullPath(projectId);
  if (isBaseScenario) {
    const paintParams: PaintServiceInterface.enqueuePaintBaseScenarioCanvasParams =
      {
        namespace,
        key,
        ...getFilterClause(filters),
        painter,
        params: JSON.stringify(params),
      };

    return enqueuePaintBaseScenarioCanvas(paintParams);
  }
  const paintParams: PaintServiceInterface.enqueuePaintScenarioCanvasParams = {
    namespace,
    key,
    scenario_key: scenarioKey,
    ...getFilterClause(filters),
    painter:
      painter as PaintServiceInterface.enqueuePaintScenarioCanvasParams['painter'],
    params: JSON.stringify(params),
  };

  return enqueuePaintScenarioCanvas(paintParams);
}

// This is painter-specific, defined by the server
export interface BaseEditParams {
  built_form_key: string;
  apply_net_density: boolean;
  built_forms_layer: {
    path: string;
    version: string;
  };
  key_only: boolean;
}

export function enqueuePaintBaseScenarioCanvasAPI(
  projectId: ProjectId,
  filters: Expression[],
  params: BuiltFormPainterParams,
) {
  const { namespace, key } = parseFullPath(projectId);

  const paintParams: PaintServiceInterface.enqueuePaintBaseScenarioCanvasParams =
    {
      namespace,
      key,
      ...getFilterClause(filters),
      painter: PaintingActionInfo.PainterEnum.BuiltForm,
      params: JSON.stringify(params),
    };

  return enqueuePaintBaseScenarioCanvas(paintParams);
}
