import { Collapse, Divider, Tag } from '@blueprintjs/core';
import _ from 'lodash';
import React, { useCallback, useState } from 'react';
import tw from 'uf/tailwindcss-classnames';
import { useProjectType, useProjectTypeLayers } from 'uf/ui/projects/hooks';

const ProjectTypeInformation = ({
  projectTypeKey,
}: {
  projectTypeKey: string;
}) => {
  const [isHidden, setIsHidden] = useState(false);
  const { projectType } = useProjectType(projectTypeKey);
  const { layersByCategory, status } = useProjectTypeLayers(projectTypeKey);
  const handleToggleHidden = useCallback(() => {
    setIsHidden(state => !state);
  }, []);
  return (
    <section data-testid="project-type-information">
      <h3 className={tw('tw-mb-6', 'tw-font-medium')}>{projectType?.name}</h3>
      <h4>{projectType?.long_description}</h4>
      {!!_.size(layersByCategory) && !status.isLoading && !status.isFetching && (
        <div>
          <div className={tw('tw-flex', 'tw-my-4')}>
            <h4 className={tw('tw-mr-2', 'tw-font-bold')}>Included Layers</h4>
            <span>
              (
              <button
                className={tw('tw-text-blue-700')}
                onClick={handleToggleHidden}>
                {isHidden ? 'show' : 'hide'}
              </button>
              )
            </span>
          </div>
          <Collapse isOpen={!isHidden}>
            <div
              data-testid="project-type-layer-list"
              className={tw(
                'tw-bg-gray-100',
                'tw-px-6',
                'tw-pt-4',
                'tw-pb-2',
                'tw-rounded',
              )}>
              {Object.values(layersByCategory).map(({ category, layers }) => {
                return (
                  <>
                    <h6
                      className={tw(
                        'tw-mb-2',
                        'tw-text-black',
                        'tw-font-bold',
                        'tw-text-xl',
                      )}>
                      {category}
                    </h6>
                    <div className={tw('tw-flex', 'tw-flex-wrap')}>
                      {layers.map((layer, index) => {
                        return (
                          <Tag
                            round
                            className={tw(
                              'tw-mr-2',
                              'tw-mb-4',
                              'tw-whitespace-no-wrap',
                              'tw-bg-white',
                              'tw-text-black',
                              'tw-p-2',
                            )}
                            key={`${_.snakeCase(layer)}-${index}`}>
                            {layer}
                          </Tag>
                        );
                      })}
                    </div>
                  </>
                );
              })}
            </div>
          </Collapse>
          <Divider className={tw('tw-mx-0', 'tw-my-4')} />
        </div>
      )}
    </section>
  );
};

export default ProjectTypeInformation;
