/**
 * UrbanFootprint App API
 * API for serving UrbanFootprint Application
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * True if the value of all the expressions are true.
 */
export interface ExpressionAnd<> {
  /**
   *
   */
  expressions: Array<object>;
  /**
   * Expression function, set to \"and\".
   */
  fn: ExpressionAnd.FnEnum;
}
export namespace ExpressionAnd {
  export type FnEnum = 'and';
  export const FnEnum = {
    And: 'and',
  } as const;
}
