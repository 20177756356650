/**
 * UrbanFootprint App API
 * API for serving UrbanFootprint Application
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Contains column breaks for numeric columns.
 */
export interface LayerColumnBreaks<> {
  /**
   * Requested number of breaks to be calculated
   */
  break_count: number;
  /**
   * Type of breaks computed (logarithmic, linear, quantiles..)
   */
  break_type: LayerColumnBreaks.BreakTypeEnum;
  /**
   * An array of breaks based on column values distribution
   */
  breaks: Array<number>;
  /**
   * A column key
   */
  column_key: string;
  /**
   * If True, indicates that this column contains nulls.
   */
  contains_nulls?: boolean;
}
export namespace LayerColumnBreaks {
  export type BreakTypeEnum =
    | 'linear'
    | 'logarithmic'
    | 'quantiles'
    | 'natural';
  export const BreakTypeEnum = {
    Linear: 'linear',
    Logarithmic: 'logarithmic',
    Quantiles: 'quantiles',
    Natural: 'natural',
  } as const;
}
