import {
  AppendItemAction,
  ListActionTypes,
  makeListActionTypes,
  RemoveItemAction,
  SetListAction,
  UpdateItemAction,
  UpsertItemAction,
  UpsertItemsAction,
} from 'uf/base/listActionHelpers';

export interface KeyedListActionTypes extends ListActionTypes {
  CLEAR_ALL: string;
}

export function makeKeyedListActionTypes(
  actionBaseType: string,
): KeyedListActionTypes {
  return {
    ...makeListActionTypes(actionBaseType),
    CLEAR_ALL: `${actionBaseType}/CLEAR_ALL`,
  };
}

export interface SetKeyedListAction<T, K> extends SetListAction<T> {
  key: K;
}

export interface UpdateKeyedListItemAction<T, K> extends UpdateItemAction<T> {
  key: K;
}

export interface UpsertKeyedListItemAction<T, K> extends UpsertItemAction<T> {
  key: K;
}
export interface UpsertKeyedListItemsAction<T, K> extends UpsertItemsAction<T> {
  key: K;
}

export interface AppendKeyedListItemAction<T, K> extends AppendItemAction<T> {
  key: K;
}

export interface RemoveKeyedListItemAction<T, K> extends RemoveItemAction<T> {
  key: K;
}

export interface ClearAllListsAction {
  type: string;
}

export interface KeyedListActionCreators<T, K = string> {
  setList: (key: K, list: T[]) => SetKeyedListAction<T, K>;
  updateItem: (
    key: K,
    item: T,
    optionalNewItem?: T,
  ) => UpdateKeyedListItemAction<T, K>;
  upsertItem: (key: K, item: T) => UpsertKeyedListItemAction<T, K>;
  upsertItems: (key: K, items: T[]) => UpsertKeyedListItemsAction<T, K>;
  appendItem: (key: K, items: T | T[]) => AppendKeyedListItemAction<T, K>;
  removeItem: (key: K, items: T | T[]) => RemoveKeyedListItemAction<T, K>;
  clearAllLists: () => ClearAllListsAction;
}

export interface KeyedListActionCreatorsWithExtra<
  T,
  EA extends any[],
  EP,
  K = string,
> {
  setList: (key: K, list: T[], ...extra: EA) => SetKeyedListAction<T, K> & EP;
  updateItem: (
    key: K,
    item: T,
    optionalNewItem?: T,
    ...extra: EA
  ) => UpdateKeyedListItemAction<T, K> & EP;
  upsertItem: (
    key: K,
    item: T,
    ...extra: EA
  ) => UpsertKeyedListItemAction<T, K> & EP;
  upsertItems: (
    key: K,
    items: T[],
    ...extra: EA
  ) => UpsertKeyedListItemsAction<T, K> & EP;
  appendItem: (
    key: K,
    items: T | T[],
    ...extra: EA
  ) => AppendKeyedListItemAction<T, K> & EP;
  removeItem: (
    key: K,
    items: T | T[],
    ...extra: EA
  ) => RemoveKeyedListItemAction<T, K> & EP;
  clearAllLists: () => ClearAllListsAction;
}

export function makeKeyedListActionCreators<T, K = string>(
  actionTypes: KeyedListActionTypes,
): KeyedListActionCreators<T, K> {
  return {
    setList: (key: K, list: T[]): SetKeyedListAction<T, K> => {
      return {
        type: actionTypes.SET,
        key,
        list,
      };
    },
    updateItem: (
      key: K,
      item: T,
      optionalNewItem?: T,
    ): UpdateKeyedListItemAction<T, K> => {
      const newItem = optionalNewItem && { newItem: optionalNewItem };
      return {
        type: actionTypes.UPDATE,
        key,
        item,
        ...newItem,
      };
    },
    upsertItem: (key: K, item: T): UpsertKeyedListItemAction<T, K> => {
      return {
        type: actionTypes.UPSERT,
        key,
        item,
      };
    },
    upsertItems: (key: K, items: T[]): UpsertKeyedListItemsAction<T, K> => {
      return {
        type: actionTypes.UPSERT_ITEMS,
        key,
        items,
      };
    },
    appendItem: (key: K, items: T | T[]): AppendKeyedListItemAction<T, K> => ({
      type: actionTypes.APPEND,
      key,
      items,
    }),
    removeItem: (key: K, items: T | T[]): RemoveKeyedListItemAction<T, K> => ({
      type: actionTypes.REMOVE,
      key,
      items,
    }),
    clearAllLists: () => ({
      type: actionTypes.CLEAR_ALL,
    }),
  };
}

// TODO: use makeKeyedListActionCreators here
export function makeKeyedListActionCreatorsWithExtra<
  T,
  EA extends any[],
  EP,
  K = string,
>(
  actionTypes: KeyedListActionTypes,
  makeExtraProps: (...extraArg: EA) => EP,
): KeyedListActionCreatorsWithExtra<T, EA, EP, K> {
  return {
    setList: (
      key: K,
      list: T[],
      ...extra: EA
    ): SetKeyedListAction<T, K> & EP => {
      return {
        type: actionTypes.SET,
        key,
        list,
        ...(makeExtraProps ? makeExtraProps(...extra) : undefined),
      };
    },
    updateItem: (
      key: K,
      item: T,
      optionalNewItem: T,
      ...extra: EA
    ): UpdateKeyedListItemAction<T, K> & EP => {
      const newItem = optionalNewItem && { newItem: optionalNewItem };
      return {
        type: actionTypes.UPDATE,
        key,
        item,
        ...newItem,
        ...(makeExtraProps ? makeExtraProps(...extra) : undefined),
      };
    },
    upsertItem: (
      key: K,
      item: T,
      ...extra: EA
    ): UpsertKeyedListItemAction<T, K> & EP => {
      return {
        type: actionTypes.UPDATE,
        key,
        item,
        ...(makeExtraProps ? makeExtraProps(...extra) : undefined),
      };
    },
    upsertItems: (
      key: K,
      items: T[],
      ...extra: EA
    ): UpsertKeyedListItemsAction<T, K> & EP => {
      return {
        type: actionTypes.UPDATE,
        key,
        items,
        ...(makeExtraProps ? makeExtraProps(...extra) : undefined),
      };
    },
    appendItem: (
      key: K,
      items: T | T[],
      ...extra: EA
    ): AppendKeyedListItemAction<T, K> & EP => ({
      type: actionTypes.APPEND,
      key,
      items,
      ...(makeExtraProps ? makeExtraProps(...extra) : undefined),
    }),
    removeItem: (
      key: K,
      items: T | T[],
      ...extra: EA
    ): RemoveKeyedListItemAction<T, K> & EP => ({
      type: actionTypes.REMOVE,
      key,
      items,
      ...(makeExtraProps ? makeExtraProps(...extra) : undefined),
    }),
    clearAllLists: () => ({
      type: actionTypes.CLEAR_ALL,
    }),
  };
}
