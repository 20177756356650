import React from 'react';

export const storeMarkerPath =
  'M21.5789 4.44444H2.21053V2H21.5789V4.44444ZM13.1053 16.0556C13.1053 17.4489 13.6258 18.94 14.3158 20.3333V21.5556H2.21053V14.2222H1V11.7778L2.21053 5.66667H21.5789L22.4263 9.94444C21.5789 9.55333 20.7074 9.33333 19.7632 9.33333C16.1316 9.33333 13.1053 12.3889 13.1053 16.0556ZM11.8947 14.2222H4.63158V19.1111H11.8947V14.2222ZM24 16.0556C24 19.2333 19.7632 24 19.7632 24C19.7632 24 15.5263 19.2333 15.5263 16.0556C15.5263 13.7333 17.4632 11.7778 19.7632 11.7778C22.0632 11.7778 24 13.7333 24 16.0556ZM21.2158 16.1778C21.2158 15.4444 20.4895 14.7111 19.7632 14.7111C19.0368 14.7111 18.3105 15.3222 18.3105 16.1778C18.3105 16.9111 18.9158 17.6444 19.7632 17.6444C20.6105 17.6444 21.3368 16.9111 21.2158 16.1778Z';

export default function StoreMarker({
  className,
  color,
}: {
  className?: string;
  color?: string;
}) {
  return (
    <div className={className}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path d={storeMarkerPath} fill={color || 'currentColor'} />
      </svg>
    </div>
  );
}
