import { Epic, ofType } from 'redux-observable';
import { concat, defer, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { SwaggerThunkExtra } from 'uf/base/xhr';
import { getData, isLoaded } from 'uf/data/dataState';
import { makeGetLayerGeojson } from 'uf/layers/selectors/geojson';
import { ensureGeoJsonStream } from 'uf/layers/streams/geojson';
import { featureEditListActions } from 'uf/mapedit/actions/features';
import {
  LOAD_FEATURES_INTO_EDITS,
  LoadSelectionIntoEditsAction,
} from 'uf/mapedit/ActionTypes';
import { makeGetLayerEditFeatures } from 'uf/mapedit/selectors/features';
import { UFState } from 'uf/state';

export const copyGeoJsonToEdits: Epic<
  LoadSelectionIntoEditsAction,
  any,
  UFState,
  SwaggerThunkExtra
> = (action$, state$, { client }) => {
  const getLayerGeojson = makeGetLayerGeojson();
  const getLayerEditFeatures = makeGetLayerEditFeatures();
  return action$.pipe(
    ofType(LOAD_FEATURES_INTO_EDITS),

    mergeMap(action => {
      const { layerId, version, filters } = action;
      return concat(
        ensureGeoJsonStream(action$, state$, client, {
          layerId,
          filters,
          version,
        }),
        defer(() => {
          const { projectId } = action;
          const featureState = getLayerGeojson(state$.value, {
            filters,
            layerId,
            version,
          });
          // might have thrown an error
          if (!isLoaded(featureState)) {
            return;
          }
          const featureCollection = getData(featureState);
          const currentEdits = getLayerEditFeatures(state$.value, {
            layerId,
            projectId,
          });
          const currentEditsFeatureIds = currentEdits.map(({ id }) => id);

          // We do not want to replace any items that may already be loaded into the editor
          const newItems = featureCollection.features.filter(feature => {
            return !currentEditsFeatureIds.includes(feature.id);
          });
          if (!newItems.length) {
            return;
          }
          return of(
            featureEditListActions.appendItem(
              newItems,
              projectId,
              layerId,
              true,
            ),
          );
        }),
      );
    }),
  );
};
