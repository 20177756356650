import {
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
  FormGroup,
  NumericInput,
} from '@blueprintjs/core';
import _ from 'lodash';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { t } from 'ttag';
import {
  dialogClosed,
  selectZoomExtentByLayerId,
  selectZoomExtentDialog,
  zoomExtentSet,
} from 'uf/layers/reducer/zoomExtentSlice';

const DEFAULT_ZOOM_VALUES = { minZoom: 0, maxZoom: 22 };

export default function LayerZoomExtentDialog() {
  const zoomExtentDialog = useSelector(selectZoomExtentDialog);
  const currentZoomValues = useSelector(state =>
    selectZoomExtentByLayerId(state, zoomExtentDialog.selectedLayerId),
  );
  const [zoomValue, setZoomValue] = useState(
    currentZoomValues || DEFAULT_ZOOM_VALUES,
  );
  const isSubmitDisabled =
    // Disable if the zoom values haven't changed from what was previously set
    (currentZoomValues && _.isEqual(currentZoomValues, zoomValue)) ||
    // or if the min zoom is greater than the max zoom
    zoomValue.minZoom > zoomValue.maxZoom ||
    // or the zoom values haven't changed from the default values
    (!currentZoomValues && _.isEqual(zoomValue, DEFAULT_ZOOM_VALUES));
  const dispatch = useDispatch();
  const handleChange = field => (value: number) => {
    setZoomValue({ ...zoomValue, [field]: value });
  };
  const handleSubmit = () => {
    dispatch(
      zoomExtentSet({
        layerId: zoomExtentDialog.selectedLayerId,
        zoomExtent: zoomValue,
      }),
    );
  };
  return (
    <Dialog
      title="Set zoom extent"
      isOpen
      onClose={() => dispatch(dialogClosed())}>
      <DialogBody>
        <h4 className="tw-pb-8">
          If the layer is <strong>currently visible</strong> on the map, it must
          be toggled off and on again in order to apply the defined min and max
          zoom levels. These values will not persist if the browser is
          refreshed.
        </h4>
        <FormGroup label={t`Min Zoom`} labelFor="minZoom">
          <NumericInput
            name="minZoom"
            id="minZoom"
            min={DEFAULT_ZOOM_VALUES.minZoom}
            max={DEFAULT_ZOOM_VALUES.maxZoom}
            fill
            onValueChange={handleChange('minZoom')}
            value={zoomValue.minZoom}
          />
        </FormGroup>
        <FormGroup label={t`Max Zoom`} labelFor="maxZoom">
          <NumericInput
            name="maxZoom"
            id="maxZoom"
            min={DEFAULT_ZOOM_VALUES.minZoom}
            max={DEFAULT_ZOOM_VALUES.maxZoom}
            fill
            onValueChange={handleChange('maxZoom')}
            value={zoomValue.maxZoom}
          />
        </FormGroup>
      </DialogBody>
      <DialogFooter
        minimal
        className="tw-mx-8 tw-mb-8"
        actions={
          <>
            <Button text="Cancel" onClick={() => dispatch(dialogClosed())} />
            <Button
              onClick={handleSubmit}
              disabled={isSubmitDisabled}
              text="Save"
              intent="primary"
            />
          </>
        }
      />
    </Dialog>
  );
}
