import colors from 'material-colors';

import { DEFAULT_GEO_COLUMN_KEY } from 'uf/layers/geometryKey';
import {
  CuratedColumnSymbology,
  DEFAULT_OPACITY,
  InterpolationTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

// TODO: consolidate other boundary only styles here for consistency
//  TODO theme is copied from color and should ideally be autogenerated
const styleBoundaryOnlyLayerList = [
  {
    layerPath: '/public/dataset/school_districts_elementary_2019_10_23',
    color: colors.blue[500],
    theme: 'material:blue:500',
    width: 4,
  },
  {
    layerPath: '/public/dataset/school_districts_secondary_2019_10_23',
    color: colors.blue[900],
    theme: 'material:blue:900',
    width: 4,
  },
  {
    layerPath: '/public/dataset/school_districts_unified_2019_10_23',
    color: colors.blue[300],
    theme: 'material:blue:300',
    width: 4,
  },
  {
    layerPath: '/public/dataset/california_water_districts_private',
    color: colors.blue[900],
    theme: 'material:blue:900',
    width: 4,
  },
  {
    layerPath: '/public/dataset/california_wild_and_scenic_rivers',
    color: colors.blue[600],
    theme: 'material:blue:600',
    width: 3,
  },
  {
    layerPath: '/public/dataset/tiger_block_group_2010',
    color: colors.red[200],
    theme: 'material:red:200',
    width: 1,
  },
  {
    layerPath: '/public/dataset/census_tiger_cbsa',
    color: colors.yellow[300],
    theme: 'material:yellow:300',
    width: 4,
  },
  {
    layerPath: '/public/dataset/census_tiger_congressional_districts',
    color: colors.green[400],
    theme: 'material:green:400',
    width: 2,
  },
  {
    layerPath: '/public/dataset/census_tiger_county',
    color: colors.red[200],
    theme: 'material:red:200',
    width: 3,
  },
  {
    layerPath: '/public/dataset/census_tiger_place',
    color: colors.teal[900],
    theme: 'material:teal:900',
    width: 2,
  },
  {
    layerPath: '/public/dataset/tiger_place_2019',
    color: colors.teal[900],
    theme: 'material:teal:900',
    width: 2,
  },
  {
    layerPath: '/public/dataset/census_tiger_state',
    color: colors.grey[900],
    theme: 'material:grey:900',
    width: 5,
  },
  {
    layerPath: '/public/dataset/census_tiger_taz',
    color: colors.grey[500],
    theme: 'material:grey:500',
    width: 1,
  },
  {
    layerPath: '/public/dataset/tiger_tract_2010',
    color: colors.blue[500],
    theme: 'material:blue:500',
    width: 2,
  },
  {
    layerPath: '/public/dataset/zillow_neighborhoods',
    color: colors.red[300],
    theme: 'material:red:300',
    width: 2,
  },
  {
    layerPath: '/public/databank/tiger_cd_2021_databank_v1',
    color: colors.red[200],
    theme: 'material:red:200',
    width: 2,
  },
];

const symbologies: Record<
  string,
  Record<string, CuratedColumnSymbology[]>
> = {};

styleBoundaryOnlyLayerList.forEach(boundaryOnlyLayer => {
  symbologies[boundaryOnlyLayer.layerPath] = {
    [DEFAULT_GEO_COLUMN_KEY]: [
      {
        type: SymbologyTypes.LINE,
        paint: {
          'line-width': boundaryOnlyLayer.width,
          'line-color': boundaryOnlyLayer.color,
          'line-opacity': DEFAULT_OPACITY,
        },
        display_hints: {
          scale: SymbologyScales.CATEGORICAL,
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          theme: boundaryOnlyLayer.theme,
        },
      },
    ],
  };
});

export default symbologies;
