import { AnchorButton, Popover } from '@blueprintjs/core';
import classNames from 'classnames';
import React from 'react';

import { UserAction } from 'uf-api';
import { ProjectId } from 'uf/projects';
import CanPerform from 'uf/ui/base/CanPerform/CanPerform';
import { GetComponentProps } from 'uf/ui/base/types';
import BaseScenarioOptionsMenu from 'uf/ui/scenarios/ScenarioOptionsMenu/BaseScenarioOptionsMenu';
import ScenarioOptionsMenu from 'uf/ui/scenarios/ScenarioOptionsMenu/ScenarioOptionsMenu';

interface ScenarioTabMenuProps {
  isBase: boolean;
  dataAttributes: any;
  baseScenarioMenuProps: Omit<
    GetComponentProps<typeof BaseScenarioOptionsMenu>,
    'closeMenu'
  >;
  scenarioMenuProps: Omit<
    GetComponentProps<typeof ScenarioOptionsMenu>,
    'closeMenu'
  >;

  className?: string;
  disabled?: boolean;

  projectId: ProjectId;
}

const closeMenu = () => {};

const ScenarioTabMenu: React.FunctionComponent<ScenarioTabMenuProps> =
  props => {
    const {
      dataAttributes,
      isBase,
      scenarioMenuProps,
      baseScenarioMenuProps,
      className,
      disabled,
      projectId,
    } = props;

    /**
     * we need to stop the click propagation so that clicking on the menu doesn't activate the
     * scenario tab.  we cannot stop propagation on the AnchorButton because then the Popover won't
     * receive the click event. use a span as the default display is inline.
     */
    return (
      <CanPerform cache={false} verb="scenario.create" resources={projectId}>
        {({ disabled: scenarioCreateDisabled, reason }) => {
          if (
            isBase &&
            scenarioCreateDisabled &&
            reason === UserAction.ReasonEnum.Permission &&
            !baseScenarioMenuProps.showCreateProject
          ) {
            return null;
          }
          return (
            // TODO: figure out how to do keyboard navigation here
            // eslint-disable-next-line max-len
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
            <span onClick={stopPropagation}>
              <Popover
                boundary="viewport"
                disabled={disabled}
                targetClassName={'ScenarioTabMenu'}
                content={
                  isBase ? (
                    <BaseScenarioOptionsMenu
                      closeMenu={closeMenu}
                      {...baseScenarioMenuProps}
                    />
                  ) : (
                    <ScenarioOptionsMenu
                      closeMenu={closeMenu}
                      {...scenarioMenuProps}
                    />
                  )
                }>
                <AnchorButton
                  minimal
                  disabled={disabled}
                  className={classNames(
                    className,
                    'ScenarioBar-scenario-kebab',
                  )}
                  icon="caret-down"
                  {...dataAttributes}
                />
              </Popover>
            </span>
          );
        }}
      </CanPerform>
    );
  };

export default ScenarioTabMenu;

function stopPropagation(event) {
  event.stopPropagation();
}
