import _ from 'lodash';

import { ProjectMetadata } from 'uf-api';
import { UFLngLatBounds } from 'uf/base/map';

export function getMaxMapBounds(project: ProjectMetadata) {
  const { max_map_bounds: maxMapBounds } = project;
  if (_.isEmpty(maxMapBounds)) {
    return null;
  }

  const bounds: UFLngLatBounds = [
    [maxMapBounds[0], maxMapBounds[1]],
    [maxMapBounds[2], maxMapBounds[3]],
  ];
  return bounds;
}
