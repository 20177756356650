import { ProjectId } from 'uf/projects';
import { ScenarioId } from 'uf/scenarios';

export const SET_SCENARIO_VISIBLE = 'uf/reporting/SET_SCENARIO_VISIBLE';
export interface SetScenarioVisibleAction {
  type: typeof SET_SCENARIO_VISIBLE;
  projectId: ProjectId;
  scenarioId: ScenarioId;
  value: boolean;
}

export const SET_SCENARIOS_VISIBLE = 'uf/reporting/SET_SCENARIOS_VISIBLE';

export interface SetScenariosVisibleAction {
  type: typeof SET_SCENARIOS_VISIBLE;
  projectId: ProjectId;
  scenarioIds: string[];
  visible: boolean[];
}

export const SET_ACTIVE_MODULE = 'uf/reporting/SET_ACTIVE_MODULE';
export interface SetActiveModuleAction {
  type: typeof SET_ACTIVE_MODULE;
  projectId: ProjectId;
  value: string;
}

export const ROUTE_REPORT = 'REPORT';
