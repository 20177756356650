import { Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { UserData } from 'uf-api';
import { loadUserDataItems } from 'uf/user/actions/data';
import { getUserKey } from 'uf/user/selectors/user';

import { createPersistKey } from './';
import {
  ENABLE_LOAD_PERSISTENCE,
  ENABLE_SAVE_PERSISTENCE,
  EnableLoadPersistenceAction,
  EnableSavePersistenceAction,
  PersistPrefAction,
  PREF_LOADED,
  PREF_PERSIST,
  PrefLoadedAction,
} from './ActionTypes';

// TODO: remove this code, there don't seem to be any users of it
/**
 *
 * @param key Key of pref
 * @param value Value of pref
 */
export function prefLoaded<T extends object = any>(
  key: string,
  value: T,
): PrefLoadedAction<T> {
  return {
    type: PREF_LOADED,
    key,
    value,
  };
}

// TODO: remove this code, it is deprecated
export function persistPref<T>(key: string, value: T): PersistPrefAction {
  return {
    type: PREF_PERSIST,
    key,
    value,
  };
}

/**
 * Load all values for a given prefix + params combination
 *
 * For example, `loadPersistedValues('proj1', ['prop1', 'prop2'])` will
 *   load all keys starting with `"persist:prop1:proj1"` and `"persist:prop2:proj2"`
 */
export function loadPersistedValues(
  subKey: string,
  keyPrefixes: string[],
): ThunkAction<Promise<PrefLoadedAction[][]>, any, any, any> {
  return (dispatch: Dispatch, getState) => {
    const userKey = getUserKey(getState());

    const prefixes = keyPrefixes.map(keyPrefix =>
      createPersistKey(keyPrefix, subKey),
    );

    return Promise.all(
      prefixes.map(prefix =>
        dispatch(loadUserDataItems(userKey, prefix)).then(
          (userDataItems: UserData[]) => {
            return userDataItems.map(userDataItem =>
              dispatch(
                prefLoaded(userDataItem.key, userDataItem?.value?.value),
              ),
            );
          },
        ),
      ),
    );
  };
}

/**
 * A global flag to tell UF whether or not values should be persisted or not
 * @param enabled Set this to `true` or `false` depending on if you want the system to
 * persist/playback persisted values.
 *
 * This is used, for example, in Map Export, where we don't want to load any persisted values since
 * they come from the route instead.
 */
export function enableSavePersistence(
  enabled: boolean = true,
): EnableSavePersistenceAction {
  return {
    type: ENABLE_SAVE_PERSISTENCE,
    value: enabled,
  };
}
export function enableLoadPersistence(
  enabled: boolean = true,
): EnableLoadPersistenceAction {
  return {
    type: ENABLE_LOAD_PERSISTENCE,
    value: enabled,
  };
}
