import mapboxgl, {
  AttributionControl,
  LngLat,
  LngLatBounds,
  Map,
  NavigationControl,
  ScaleControl,
} from 'mapbox-gl';

declare global {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Window {
    mapboxgl: any;
  }
}

interface MapboxglModule {
  Map: typeof Map;

  NavigationControl: typeof NavigationControl;
  ScaleControl: typeof ScaleControl;
  LngLatBounds: typeof LngLatBounds;
  AttributionControl: typeof AttributionControl;
  accessToken: string;
  LngLat: typeof LngLat;

  supported: () => boolean;
}

if (__CLIENT__) {
  // This should only exist browser-side, unless mapbox improves its webpack support.
  if (__DEVELOPMENT__) {
    window.mapboxgl = mapboxgl;
  }

  // TODO: On the client side, we need to get this from the server at serve time.
  (mapboxgl as MapboxglModule).accessToken = process.env.UF_MAPBOX_API_KEY;
}

export default mapboxgl as MapboxglModule;
