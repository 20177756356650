import { Mode, ModeOptions } from '@mapbox/mapbox-gl-draw';
import { ReduxMapControl } from '@mapbox/mapbox-gl-redux';
import classNames from 'classnames';
import { Feature } from 'geojson';
import _ from 'lodash';
import { Layer, Map, Marker } from 'mapbox-gl';
import React, {
  FunctionComponent,
  memo,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ThunkActionDispatch, ThunkActionMapDispatch } from 'redux-thunk';
import { t } from 'ttag';
import { LayerMetadata } from 'uf-api/model/models';
import {
  getActiveProjectContextBounds,
  getActiveProjectId,
  getActiveProjectProjectBounds,
  getActiveScenarioId,
} from 'uf/app/selectors';
import { EMPTY_ARRAY, EMPTY_OBJECT, LOADING } from 'uf/base';
import {
  getScaleFromZoom,
  isEqualCenter,
  MapboxExpression,
  UFLngLatBounds,
} from 'uf/base/map';
import { DataState, isLoading } from 'uf/data/dataState';
import { setDrawingMode } from 'uf/explore/actions';
import { selectFeatures as selectFeaturesAction } from 'uf/explore/actions/drawingMode';
import * as filterActionCreators from 'uf/explore/actions/filters';
import * as mapActionCreators from 'uf/explore/actions/map';
import { ensureLayerSelection as ensureLayerSelectionAction } from 'uf/explore/actions/selection';
import { baseMapLayers, setLayerVisibility } from 'uf/explore/baseMapLayers';
import { getSalientSelectionLayers } from 'uf/explore/salientSelection';
import { makeGetLayerEditsSelectedFeatureIds } from 'uf/explore/selectors/drawingMode';
import { makeGetDrawingMode } from 'uf/explore/selectors/explore';
import { makeGetLayerFilters } from 'uf/explore/selectors/filters';
import {
  getActiveLayerId,
  makeGetProjectLayersMapStyles,
  makeGetVisibleLayerIds,
} from 'uf/explore/selectors/layers';
import {
  getExploreMapBearing,
  getExploreMapBounds,
  getExploreMapCenter,
  getExploreMapPitch,
  getExploreMapScale,
  getExploreMapZoom,
  getHasBuildings,
  getHasCitiesLabels,
  getHasContours,
  getHasPlaceLabels,
  getHasPoiLabels,
  getHasRoadRailLabels,
  getHasWaterLabels,
  getHasWaterMask,
  getInspection,
  getMapMode,
  getMapRequestedBounds,
  getMapStatus,
  getShouldTakeSnapshot,
  makeGetExploreMapStyleUrl,
  makeGetLayerMappedColumnKey,
  makeGetUfTileSources,
} from 'uf/explore/selectors/map';
import { makeGetAllMarkers } from 'uf/explore/selectors/markers';
import {
  makeGetLayerSelectedKeys,
  makeGetLayerSelectionState,
} from 'uf/explore/selectors/selection';
import {
  makeGetHoverStyleLayers,
  makeGetLayerStyleLayers,
  makeGetSelectionStyleLayers,
  makeGetVisibleStyleLayers,
} from 'uf/explore/selectors/styleLayers';
import { makeGetActiveViewId } from 'uf/explore/selectors/views';
import { makeGetVisibilityFilters } from 'uf/explore/selectors/visibility';
import { InspectionObject } from 'uf/explore/state';
import { ColumnKey, LayerId } from 'uf/layers';
import { filtersAreEmpty, FilterSpec } from 'uf/layers/filters';
import { getUfGeometryType } from 'uf/layers/helpers';
import { makeGetLayerMetadata } from 'uf/layers/selectors/metadata';
import { makeGetLayerVersion } from 'uf/layers/selectors/versions';
import { LayerSource, MapViewport } from 'uf/map';
import { MapMode } from 'uf/map/mapmode';
import { CoordPathsByFeature } from 'uf/map/state';
import {
  getStyleLayerUFMetadata,
  isSelectionStyleLayer,
} from 'uf/map/stylelayers/stylelayers';
import { featureEditListActions } from 'uf/mapedit/actions/features';
import {
  makeGetEditsFilteredFeatures,
  makeGetLayerEditDefaultProperties,
  makeGetLayerEditFeatures,
} from 'uf/mapedit/selectors/features';
import { ProjectId } from 'uf/projects';
import { makeGetProjectLayerIdMap } from 'uf/projects/selectors/virtualLayers';
import { LegacyVirtualLayerId } from 'uf/projects/virtualLayers';
import ErrorBoundary from 'uf/ui/base/ErrorBoundary/ErrorBoundary';
import { useMakeSelector } from 'uf/ui/base/useMakeSelector';
import { useUniqDebounced } from 'uf/ui/base/useUniqDebounced/useUniqDebounced';
import InteractiveMapView from 'uf/ui/map/InteractiveMapView/InteractiveMapView';
import mapboxgl from 'uf/ui/map/mapboxgl';
import { getMapViewport } from 'uf/ui/map/MapView/helpers';
import { useDrawFeatures } from 'uf/ui/mapedit/useDrawFeatures/useDrawFeatures';
import { useEnsureSymbologies } from 'uf/ui/symbology/hooks';
import { getFlagHeaders, makeGetUserFlag } from 'uf/user/selectors/flags';
import { ViewId } from 'uf/views';
import BaseMapSwitcher from './BaseMapSwitcher/BaseMapSwitcher';
import ExploreMapInspectionPanel from './ExploreMapInspectionPanel/ExploreMapInspectionPanel';
import ExploreMapToolbar from './ExploreMapToolbar/ExploreMapToolbar';
import { useInspection } from './useInspection';
import { useMapSelection } from './useMapSelection';

const POINT_HOVER_MODES = [MapMode.INSPECTION, MapMode.POINT_SELECTION];
const MAP_STATUS_DEBOUNCE_TIME = 200;

interface StateProps {
  projectId: ProjectId;
  viewId: ViewId;
  /** Data state for requesting the selection from the server. TODO: factor this
   * to just a boolean `selectionLoading` */
  activeLayerSelectionState: DataState<any>;
  activeLayerMetadata: LayerMetadata;
  activeLayerFilters: Partial<FilterSpec>;

  mapStatus: string;
  mapMode: MapMode;
  inspection: Record<LayerId, InspectionObject>;

  /** Desired center position */
  center: [number, number];
  /** Desired pitch */
  pitch: number;
  /** Desired bearing */
  bearing: number;
  /** Desired bounds */
  mapBounds: UFLngLatBounds;
  /** Desired zoom */
  zoom: number;
  /** Desired scale */
  scale: number;

  /**
   * Mapbox inputs to tell the map to update.
   * Be sure to clear them in the handleViewportChanged
   */
  requestedMapBounds: UFLngLatBounds;

  /** Basemap style url */
  styleUrl: string;

  /** TODO: Break out to basemap props */
  contoursVisible: boolean;
  /** TODO: Break out to basemap props */
  buildingsVisible: boolean;
  /** TODO: Break out to basemap props */
  citiesLabelVisible: boolean;
  /** TODO: Break out to basemap props */
  placesLabelVisible: boolean;
  /** TODO: Break out to basemap props */
  poiLabelVisible: boolean;
  /** TODO: Break out to basemap props */
  roadRailLabelVisible: boolean;
  /** TODO: Break out to basemap props */
  waterLabelVisible: boolean;
  /** TODO: Break out to basemap props */
  waterMaskVisible: boolean;

  /** Maximum bounds for the map */
  mapMaxBounds: UFLngLatBounds;
  /** Should we show a "fog of war" beyond the map max bounds? */
  shouldShowMaxBoundsMask: boolean;

  /** The current bounds of the project */
  projectBounds: UFLngLatBounds;

  /** Should we show tile boundaries and tile zoom labels (for debugging) */
  showTileBoundaries: boolean;

  /** Headers to use with making tile requests */
  headers?: Record<string, string>;

  /** Tile sources to be passed to mapbox */
  tileSources: LayerSource[];

  /** Style layers to be passed to mapbox, zero or more for each layer in
   * visibleLayerIds */
  visibleStyleLayers: Layer[];

  /** Style layers for hover - not included with `visibleStyleLayers`, and
   * relies on sources in tileSources */
  hoverStyleLayers: Layer[];

  /** Style layers for the active layer */
  activeStyleLayers: Layer[];
  /** Styles to color the current selection */
  selectionStyleLayers: Layer[];

  /** the keys of the geometries that are currently selected for the active layer */
  selectedGeoKeys: string[];

  /** Make the current selection stand out by dimming all other style layers provided to ExploreMap */
  salientSelection?: boolean;
  /** The currently "active" layer  - the primary layer that the user will
   * interact with */
  activeLayerId: string;

  /** The version of the currently "active" layer */
  activeLayerVersion: string;

  /** The list of visible layers,  */
  visibleLayerIds: string[];

  /**
   * Set this to true to take a snapshot on the next render. Make sure to clear
   * this with `onSnapshot`, or it will keep taking snapshots.
   */
  takeSnapshot: boolean;

  /* features currently being edited, if any */
  featureEdits: Feature[];
  featureEditDefaultProperties: Record<string, any>;
  /** Features being edited that are selected by the current filters */
  selectedFeatureEdits: Feature[];

  /** Features that are active in the draw tool right now */
  layerEditsSelectedFeatureIds: string[];

  /** Provided only for testing purposes: provide a map here, and it will be used
   * instead of the one passed from onMapInitialized */
  injectMap?: Map;

  drawingMode: Mode<'box_select_vertices'>;

  /**
   * Debounce time, in milliseconds for status updates - set to 0 for immediate
   * updates, defaults to 200ms
   * */
  mapStatusDebounceTime?: number;

  markers: Record<string, Marker>;
}

interface DispatchProps {
  mapActions: ThunkActionMapDispatch<Partial<typeof mapActionCreators>>;
  filterActions: {
    clearSelectionFilters: typeof filterActionCreators.clearSelectionFilters;
    updateGeometryFilter: typeof filterActionCreators.updateGeometryFilter;
    updatePointFilter: typeof filterActionCreators.updatePointFilter;
  };
  ensureLayerSelection: ThunkActionDispatch<typeof ensureLayerSelectionAction>;
  mapeditFeatureActions: ThunkActionMapDispatch<
    Partial<typeof featureEditListActions>
  >;
  selectFeatures: (
    projectId: ProjectId,
    layerId: LayerId,
    features: Feature[],
    coordPaths: CoordPathsByFeature,
  ) => void;
  onSetDrawingMode: (
    projectId: ProjectId,
    layerId: LayerId,
    mode: Mode,
    modeOptions: ModeOptions,
  ) => void;
}

interface DimensionsHocProps {
  height: number;
  width: number;
}

type Props = StateProps & DispatchProps & DimensionsHocProps;

const ExploreMapInternal: FunctionComponent<Props> = props => {
  const {
    activeLayerId,
    activeLayerMetadata,

    tileSources,
    styleUrl,

    // TODO: combine all of these into a single "viewport" prop
    width = 100,
    height = 100,
    center,
    pitch,
    bearing,
    zoom,

    takeSnapshot,
    mapMaxBounds,
    requestedMapBounds,
    shouldShowMaxBoundsMask,
    showTileBoundaries,
    visibleStyleLayers = EMPTY_ARRAY as Layer[],
    projectId,
    viewId,
    headers,

    inspection,
    mapStatus,
    mapMode,
    activeLayerSelectionState,
    hoverStyleLayers = EMPTY_ARRAY as Layer[],
    activeStyleLayers = EMPTY_ARRAY as Layer[],
    selectionStyleLayers = EMPTY_ARRAY as Layer[],
    selectedGeoKeys = EMPTY_ARRAY,
    salientSelection,
    featureEditDefaultProperties,
    selectedFeatureEdits,
    layerEditsSelectedFeatureIds,
    mapeditFeatureActions,
    activeLayerVersion,
    activeLayerFilters,
    mapActions: {
      updateMapStatus,
      updateTileSources,
      updateMapMaxBounds,
      updateStyleLayers,
      snapshotReady,
      updateMapViewport,
      updateMapBounds,
      updateMapScale,
      fitMapToBounds,
      updateMapInspection,
      mapError,
      clearMapInspection,
    },
    filterActions: {
      clearSelectionFilters,
      updateGeometryFilter,
      updatePointFilter,
    },
    drawingMode,
    onSetDrawingMode,

    selectFeatures,
    visibleLayerIds = EMPTY_ARRAY as string[],
    ensureLayerSelection,
    injectMap,
    mapStatusDebounceTime = MAP_STATUS_DEBOUNCE_TIME,
    markers,
  } = props;

  const activeStyleLayerIds = useMemo(
    () => activeStyleLayers.map(({ id }) => id),
    [activeStyleLayers],
  );
  const onUpdateMapMaxBounds = useCallback(
    (maxMapBounds: UFLngLatBounds) => {
      updateMapMaxBounds(projectId, maxMapBounds);
    },
    [projectId, updateMapMaxBounds],
  );

  const activeLayerFeatureType = _.isEmpty(activeLayerMetadata)
    ? null
    : getUfGeometryType(activeLayerMetadata);
  const containerStyle: React.CSSProperties = {
    position: 'relative',
    width,
    height,
  };

  const updateMapStatusDebounced = useUniqDebounced(
    updateMapStatus,
    mapStatusDebounceTime,
  );

  const showInspectionPanel =
    mapMode === MapMode.INSPECTION && inspection[activeLayerId];

  const mapLoading =
    mapStatus === LOADING ||
    (isLoading(activeLayerSelectionState) &&
      !filtersAreEmpty(activeLayerFilters));

  // Be sure we have a project_bounds available to us.
  // This value will be sent to mapbox and invalid inputs
  // will crash the map. This is a defensive measure.
  let exploreMapMaxBounds: UFLngLatBounds;
  if (shouldShowMaxBoundsMask && !_.isEmpty(mapMaxBounds)) {
    exploreMapMaxBounds = mapMaxBounds;
  }

  // hover only applies in "point" related modes for now.
  // TODO: further separate "hovering" from "activating"/"selecting" so that
  // inspection mode can keep the selected feature looking highlighted.
  const activeHoverStyleLayers = POINT_HOVER_MODES.includes(mapMode)
    ? hoverStyleLayers
    : (EMPTY_ARRAY as Layer[]);

  useEffect(() => {
    if (activeLayerId && !filtersAreEmpty(activeLayerFilters)) {
      ensureLayerSelection(
        activeLayerId,
        activeLayerVersion,
        activeLayerFilters,
      );
    }
  }, [
    projectId,
    activeLayerId,
    activeLayerVersion,
    activeLayerFilters,
    ensureLayerSelection,
  ]);

  useEnsureExploreMapSymbologies(projectId, viewId, visibleLayerIds);

  const onViewportChanged = useCallback(
    (viewport: Partial<MapViewport>, map: Map, fromUser: boolean) => {
      const previousViewport: Partial<MapViewport> = {
        height,
        width,
        zoom,
        center,
        pitch,
        bearing,
      };
      updateViewport(
        map,
        projectId,
        viewport,
        previousViewport,
        updateMapViewport,
        updateMapBounds,
        updateMapScale,
      );
    },
    [
      projectId,
      bearing,
      center,
      height,
      pitch,
      updateMapViewport,
      updateMapBounds,
      updateMapScale,
      width,
      zoom,
    ],
  );

  // when the map has finished fitting, clear it so we don't keep resetting it
  const onFitToRequestedBounds = useCallback(
    () => fitMapToBounds(projectId, null),
    [fitMapToBounds, projectId],
  );

  const onMapInitialization = useCallback(
    (unusedOptions, map: Map) => {
      initializeMap(
        map,
        projectId,
        width,
        height,
        updateMapViewport,
        updateMapScale,
      );
    },
    [projectId, height, width, updateMapViewport, updateMapScale],
  );

  const { onPolygonSelection, onPointSelection } = useMapSelection(
    projectId,
    activeLayerId,
    selectedGeoKeys,
    clearSelectionFilters,
    updateGeometryFilter,
    updatePointFilter,
  );

  const { onInspection, onClearInspection } = useInspection(
    activeLayerId,
    activeStyleLayerIds,
    updateMapInspection,
    clearMapInspection,
  );

  const { appendItem, updateItem } = mapeditFeatureActions;
  const onDrawMode = useDrawFeatures(
    projectId,
    activeLayerId,
    activeLayerMetadata,
    selectedFeatureEdits,
    layerEditsSelectedFeatureIds,
    drawingMode,
    featureEditDefaultProperties,
    { onSetDrawingMode, updateItem, appendItem, selectFeatures },
  );

  // Note that these must stay in the same order on every render - do not filter any out
  const baseMapLayerProps = baseMapLayers
    .map(({ featureProp }) => featureProp)
    .map(featureProp => props[featureProp]);
  const onRefreshBasemap = useCallback((map: Map) => {
    refreshBasemap(map, props);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, baseMapLayerProps);

  if (__TESTING__) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      if (injectMap) {
        onMapInitialization({}, injectMap);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // [] is the way to singnal "only once"
  }

  const styleLayersFiltered = useFilteredStyleLayers(
    projectId,
    visibleStyleLayers,
  );

  const styleLayers = useSalientSelectionLayers(
    salientSelection,
    styleLayersFiltered,
  );

  return (
    <div
      className={classNames('uf-explore-map-container', 'ExploreMap')}
      style={containerStyle}>
      <ErrorBoundary
        title={t`Error loading map view`}
        componentName="ExploreMap">
        <InteractiveMapView
          mapMode={mapMode}
          style={styleUrl}
          onRefreshBasemap={onRefreshBasemap}
          attributionStyle="text"
          mapMaxBounds={exploreMapMaxBounds}
          center={center}
          pitch={pitch}
          bearing={bearing}
          zoom={zoom}
          onViewportChanged={onViewportChanged}
          requestedMapBounds={requestedMapBounds}
          onFitToRequestedBounds={onFitToRequestedBounds}
          showTileBoundaries={showTileBoundaries}
          width={width}
          height={height}
          activeLayerId={activeLayerId}
          activeLayerFeatureType={activeLayerFeatureType}
          tileSources={tileSources}
          styleLayers={styleLayers}
          interactiveStyleLayers={activeHoverStyleLayers}
          activeStyleLayers={activeStyleLayers}
          selectionStyleLayers={selectionStyleLayers}
          takeSnapshot={takeSnapshot}
          onMapInitialized={onMapInitialization}
          onPolygonSelection={onPolygonSelection}
          onPointSelection={onPointSelection}
          onInspection={onInspection}
          onMapMaxBoundsChanged={onUpdateMapMaxBounds}
          onMapStatusChanged={updateMapStatusDebounced}
          onTileSourcesChanged={updateTileSources}
          onStyleLayersChanged={updateStyleLayers}
          onSnapshot={snapshotReady}
          onError={mapError}
          onDrawMode={onDrawMode}
          headers={headers}
          globalMapProperty={'__map'}
          markers={markers}
        />

        <ExploreMapToolbar
          projectId={projectId}
          mapLoading={mapLoading}
          activeLayerId={activeLayerId}
        />

        <BaseMapSwitcher />
        {showInspectionPanel && (
          <ExploreMapInspectionPanel
            activeLayerMetadata={activeLayerMetadata}
            inspection={inspection[activeLayerId]}
            onClearInspection={onClearInspection}
          />
        )}
      </ErrorBoundary>
    </div>
  );
};

export const ExploreMap = memo(ExploreMapInternal);
ExploreMap.displayName = 'ExploreMap';

/** Make sure all symbologies for the currently visible layers are loaded */
function useEnsureExploreMapSymbologies(
  projectId: ProjectId,
  viewId: ViewId,
  visibleLayerIds: string[],
) {
  const mapStyles = useMakeSelector(makeGetProjectLayersMapStyles, {
    projectId,
    viewId,
  });
  const layerMap = useMakeSelector(makeGetProjectLayerIdMap, { projectId });
  const columnKeys = useMemo(
    () =>
      visibleLayerIds.map((layerId): string => {
        const virtualLayerId: LegacyVirtualLayerId = layerMap[layerId];
        return mapStyles[virtualLayerId]?.activeColumnKey;
      }),
    [layerMap, mapStyles, visibleLayerIds],
  );
  useEnsureSymbologies(projectId, viewId, visibleLayerIds, columnKeys);
}

/**
 * sync redux with the actual values in the map instance.  there is some risk
 * here that this will cause a rerender if the prop values from redux that
 * initialized the map are not the same as the ones returned from mapbox.
 */
function initializeMap(
  map: Map,
  projectId: ProjectId,
  width: number,
  height: number,
  updateMapViewport: (
    projectId: ProjectId,
    viewport: Partial<MapViewport>,
  ) => void,
  updateMapScale: (projectId: ProjectId, scale: number) => void,
) {
  const viewport: Partial<MapViewport> = {
    ...getMapViewport(map),
    width,
    height,
  };
  updateMapViewport(projectId, viewport);
  map.getCanvas().style.outline = 'none';
  map.addControl(new mapboxgl.NavigationControl());
  map.addControl(new mapboxgl.ScaleControl({ unit: 'imperial' }));
  map.addControl(new ReduxMapControl('explore-map'));
  updateScale(map, projectId, updateMapScale);
}

/**
 * Update the map viewport to the desired height/width/zoom/center/pitch.
 *
 * @param map a mapbox map instance
 * @param projectId the corresponding project id
 * @param currentViewport The current viewport from the map
 * @param previousViewport The viewport as stored in redux
 * @param updateMapViewport A callback to call when the viewport actually
 *   changes (can happen with most map changes, plus if the window changes size)
 * @param updateMapBounds A callback to call when the bounds of the map changes
 *   (can happen with most map changes, plus if the window changes size)
 * @param updateMapScale A callback to call when the map zoom changes
 */
function updateViewport(
  map: Map,
  projectId: ProjectId,
  currentViewport: Partial<MapViewport>,
  previousViewport: Partial<MapViewport>,
  updateMapViewport: (
    projectId: ProjectId,
    viewport: Partial<MapViewport>,
  ) => void,
  updateMapBounds: (projectId: ProjectId, mapBounds: UFLngLatBounds) => void,
  updateMapScale: (projectId: ProjectId, scale: number) => void,
) {
  const { height, width, zoom, center, pitch, bearing } = previousViewport;
  const {
    zoom: newZoom,
    center: newCenter,
    bearing: newBearing,
    pitch: newPitch,
    bounds: newMapBounds,
  } = currentViewport;
  const newViewport: MapViewport = {
    height,
    width,
  } as MapViewport;
  if (!_.isEqual(zoom, newZoom)) {
    newViewport.zoom = newZoom;
  }
  if (!isEqualCenter(center, newCenter)) {
    newViewport.center = newCenter;
  }
  if (!_.isEqual(pitch, newPitch)) {
    newViewport.pitch = newPitch;
  }

  if (!_.isEqual(bearing, newBearing)) {
    newViewport.bearing = newBearing;
  }
  // only update viewport in redux if something changed
  if (updateMapViewport && !_.isEmpty(newViewport)) {
    updateMapViewport(projectId, newViewport);
  }
  // always update the map bounds
  if (updateMapBounds) {
    updateMapBounds(projectId, newMapBounds);
  }
  if (updateMapScale) {
    updateScale(map, projectId, updateMapScale);
  }
}

// Because we are passed Partial<MapViewport>'s, we don't know if zoom/center are passed.  Use this
// function to grab the latest zoom/center from the map.
function updateScale(
  map: Map,
  projectId: ProjectId,
  updateMapScale: (projectId: ProjectId, scale: number) => void,
) {
  const zoom = map.getZoom();
  const center = map.getCenter();
  const scale = getScaleFromZoom(zoom, center.lat);
  updateMapScale(projectId, scale);
}

function refreshBasemap(map: Map, props: Props) {
  baseMapLayers.forEach(({ featureProp, mapLayerIds }) => {
    const propValue = props[featureProp];
    mapLayerIds.forEach(mapLayerId => {
      setLayerVisibility(map, mapLayerId, propValue);
    });
  });
}

function makeMapStateToProps() {
  const getShowTileBoundariesFlag = makeGetUserFlag('dev-show-tile-boundaries');
  const getLayerSelectionState = makeGetLayerSelectionState();
  const getLayerVersion = makeGetLayerVersion();

  const getLayerStyleLayers = makeGetLayerStyleLayers();
  const getSelectionStyleLayers = makeGetSelectionStyleLayers();
  const getVisibleStyleLayers = makeGetVisibleStyleLayers();
  const getHoverStyleLayers = makeGetHoverStyleLayers();
  const getAllMarkers = makeGetAllMarkers();

  const getUfTileSources = makeGetUfTileSources();

  const getLayerMappedColumnKey = makeGetLayerMappedColumnKey();
  const getLayerEditsSelectedFeatures = makeGetEditsFilteredFeatures();
  const getLayerEditsFeatures = makeGetLayerEditFeatures();
  const getLayerEditsDefaultProperties = makeGetLayerEditDefaultProperties();
  const getExploreMapStyleUrl = makeGetExploreMapStyleUrl();
  const getVisibleLayerIds = makeGetVisibleLayerIds();
  const getLayerEditsSelectedFeatureIds = makeGetLayerEditsSelectedFeatureIds();
  const getLayerFilters = makeGetLayerFilters();
  const getLayerSelectedKeys = makeGetLayerSelectedKeys();

  const getLayerMetadata = makeGetLayerMetadata();
  const getActiveViewId = makeGetActiveViewId();
  const getProjectLayerIdMap = makeGetProjectLayerIdMap();
  return (state): StateProps => {
    const projectId = getActiveProjectId(state);
    const scenarioId = getActiveScenarioId(state);
    const viewId = getActiveViewId(state, { projectId });
    const layerMap = getProjectLayerIdMap(state, { projectId });
    const noProjectSelected = !projectId;
    const mapStyles = makeGetProjectLayersMapStyles()(state, {
      projectId,
      viewId,
    });
    // default is to restrict zoom-level to 8 or higher (unless feature flag overrides)
    let showMapMaxBounds = true;
    if (noProjectSelected) {
      showMapMaxBounds = false;
    }
    const markers = getAllMarkers(state, { projectId });

    const activeLayerId = getActiveLayerId(state, {
      projectId,
      scenarioId,
      viewId,
    });
    const activeMappingVirtualLayerId = layerMap[activeLayerId];
    const layerVersion = getLayerVersion(state, {
      layerId: activeLayerId,
    });
    const activeLayerVersion = layerVersion;

    const columnKey = getLayerMappedColumnKey(state, {
      projectId,
      layerId: activeLayerId,
      viewId,
    });
    const { activeColumnKey } = mapStyles[activeMappingVirtualLayerId] || {
      activeColumnKey: null,
    };
    const getMapDrawingMode = makeGetDrawingMode();

    const filters = getLayerFilters(state, {
      projectId,
      layerId: activeLayerId,
      parentLayerId: null,
    });

    const mapMode = getMapMode(state);

    return {
      projectId,
      viewId,
      mapStatus: getMapStatus(state),
      mapMode,
      inspection: getInspection(state),

      center: getExploreMapCenter(state, { projectId }),
      pitch: getExploreMapPitch(state, { projectId }),
      bearing: getExploreMapBearing(state, { projectId }),
      zoom: getExploreMapZoom(state, { projectId }),
      scale: getExploreMapScale(state, { projectId }),

      mapBounds: getExploreMapBounds(state, { projectId }),

      styleUrl: getExploreMapStyleUrl(state, { projectId }),
      contoursVisible: getHasContours(state, { projectId }),
      buildingsVisible: getHasBuildings(state, { projectId }),
      citiesLabelVisible: getHasCitiesLabels(state, { projectId }),
      placesLabelVisible: getHasPlaceLabels(state, { projectId }),
      poiLabelVisible: getHasPoiLabels(state, { projectId }),
      roadRailLabelVisible: getHasRoadRailLabels(state, { projectId }),
      waterLabelVisible: getHasWaterLabels(state, { projectId }),
      waterMaskVisible: getHasWaterMask(state, { projectId }),
      mapMaxBounds: getActiveProjectContextBounds(state),
      projectBounds: getActiveProjectProjectBounds(state),

      requestedMapBounds: getMapRequestedBounds(state),

      shouldShowMaxBoundsMask: showMapMaxBounds,
      showTileBoundaries: getShowTileBoundariesFlag(state),
      headers: getFlagHeaders(state),
      tileSources: getUfTileSources(state, { projectId, scenarioId, viewId }),
      visibleLayerIds: getVisibleLayerIds(state, {
        projectId,
        scenarioId,
        viewId,
      }),
      activeStyleLayers: getLayerStyleLayers(state, {
        projectId,
        layerId: activeLayerId,
        columnKey,
        scenarioId,
        viewId,
      }),
      selectionStyleLayers: activeLayerId
        ? getSelectionStyleLayers(state, {
            projectId,
            layerId: activeLayerId,
            columnKey,
            parentLayerId: null,
          })
        : EMPTY_ARRAY,
      selectedGeoKeys: activeLayerId
        ? getLayerSelectedKeys(state, {
            projectId,
            layerId: activeLayerId,
            parentLayerId: null,
          })
        : EMPTY_ARRAY,
      salientSelection: null, // not active layer id?
      visibleStyleLayers: getVisibleStyleLayers(state, {
        projectId,
        scenarioId,
        viewId,
      }),
      hoverStyleLayers: activeLayerId
        ? getHoverStyleLayers(state, {
            projectId,
            layerId: activeLayerId,
            columnKey: activeColumnKey,
          })
        : EMPTY_ARRAY,
      takeSnapshot: getShouldTakeSnapshot(state),
      activeLayerId,
      activeLayerVersion,
      activeLayerSelectionState: activeLayerId
        ? getLayerSelectionState(state, {
            projectId,
            layerId: activeLayerId,
            parentLayerId: null,
          })
        : (EMPTY_OBJECT as any),
      activeLayerMetadata: activeLayerId
        ? getLayerMetadata(state, {
            layerId: activeLayerId,
          })
        : (EMPTY_OBJECT as any),
      activeLayerFilters: filters,
      featureEdits: activeLayerId
        ? getLayerEditsFeatures(state, {
            projectId,
            layerId: activeLayerId,
          })
        : EMPTY_ARRAY,
      featureEditDefaultProperties: activeLayerId
        ? getLayerEditsDefaultProperties(state, {
            projectId,
            layerId: activeLayerId,
          })
        : EMPTY_OBJECT,
      selectedFeatureEdits: activeLayerId
        ? getLayerEditsSelectedFeatures(state, {
            projectId,
            layerId: activeLayerId,
            filters,
          })
        : EMPTY_ARRAY,
      layerEditsSelectedFeatureIds: activeLayerId
        ? getLayerEditsSelectedFeatureIds(state, {
            projectId,
            layerId: activeLayerId,
          })
        : EMPTY_ARRAY,
      drawingMode: getMapDrawingMode(state, {
        projectId,
        layerId: activeLayerId,
      }),
      markers,
    };
  };
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
  return {
    ...bindActionCreators(
      {
        ensureLayerSelection: ensureLayerSelectionAction,
        selectFeatures: selectFeaturesAction,
        onSetDrawingMode: setDrawingMode,
      },
      dispatch,
    ),
    mapActions: bindActionCreators(mapActionCreators, dispatch),
    filterActions: {
      ...bindActionCreators(
        {
          clearSelectionFilters: filterActionCreators.clearSelectionFilters,
          updateGeometryFilter: filterActionCreators.updateGeometryFilter,
          updatePointFilter: filterActionCreators.updatePointFilter,
        },
        dispatch,
      ),
    },
    mapeditFeatureActions: bindActionCreators(
      { ...featureEditListActions },
      dispatch,
    ),
  };
}

// TODO: Figure out how to use compose for this?
export default connect<StateProps, DispatchProps, DimensionsHocProps>(
  makeMapStateToProps,
  mapDispatchToProps,
)(ExploreMap);

/**
 * Dim all layers not in selectionStyleLayers
 */
function useSalientSelectionLayers(
  salientSelection: boolean,
  styleLayers: Layer[],
): Layer[] {
  return useMemo(() => {
    if (!salientSelection) {
      return styleLayers;
    }
    const selectionStyleLayers = styleLayers.filter(isSelectionStyleLayer);
    return getSalientSelectionLayers(
      styleLayers,
      selectionStyleLayers.map(({ id }) => id),
    );
  }, [salientSelection, styleLayers]);
}

/** Add visibility filters, if any are set */
function useFilteredStyleLayers(projectId: ProjectId, styleLayers: Layer[]) {
  const visibilityByLayer = useMakeSelector(makeGetVisibilityFilters, {
    projectId,
  });

  const filteredLayers = useMemo(
    () =>
      styleLayers.map(styleLayer => {
        const { layerId } = getStyleLayerUFMetadata(styleLayer);
        if (_.isEmpty(visibilityByLayer[layerId])) {
          return styleLayer;
        }
        const filters: MapboxExpression = makeVisibilityFilters(
          visibilityByLayer,
          layerId,
        );

        if (!filters.length) {
          return styleLayer;
        }
        const exclusionFilter: MapboxExpression = ['all', ...filters];

        return {
          ...styleLayer,
          filter: styleLayer.filter
            ? ['all', exclusionFilter, styleLayer.filter]
            : exclusionFilter,
        };
      }),
    [styleLayers, visibilityByLayer],
  );

  return filteredLayers;
}
function makeVisibilityFilters(
  visibilityByLayer: Record<LayerId, Record<ColumnKey, string>>,
  sourceLayerId: string,
): MapboxExpression {
  const layerValues: [string, string][] = Object.entries(
    visibilityByLayer[sourceLayerId],
  );
  return layerValues
    .filter(([key, value]) => value !== null)
    .map(([key, value]) => {
      return ['==', ['get', key], value];
    });
}
