import { Store } from 'redux';

import specs from 'uf/api/specs';
import { createSwaggerClient } from 'uf/base/xhr';
import createStore from 'uf/createStore';
import { initializeRum } from 'uf/datadog';
import { createMapiClient } from 'uf/mapi/client';
import { enableSavePersistence } from 'uf/persistence/actions';
import { initializeRollbar } from 'uf/rollbar';
import { createHistory } from 'uf/routing/history';
import syncBrowserUrlWithReduxState from 'uf/routing/syncBrowserUrlWithReduxState';
import getRoutes from 'uf/ui/routes';
import { addFlagHeaderToRequests } from 'uf/user/backendFlags';
import { websocketConnect } from 'uf/websockets';

interface Subscription {
  unsubscribe: () => void;
  store: Store;
}

let subscriptions: Subscription[] = [];
export async function createClientStore(origin: string) {
  try {
    const { specApi, specData, specManagement } = specs;
    const client = await createSwaggerClient({
      origin,
      specApi,
      specData,
      specManagement,
    });
    const mapi = createMapiClient();
    // See ./Page.js for details.
    const history = createHistory();
    const store = createStore(client, mapi, window.__data, history);
    addFlagHeaderToRequests(client, store);
    // This was disabled on the server, but we want to enable it before any
    // actions fire that might trigger saving persistence
    store.dispatch(enableSavePersistence(true));
    const routes = getRoutes(store);
    // update the url when state changes
    const unsubscribe = syncBrowserUrlWithReduxState(store);
    if (module.hot) {
      // so they can be re-subscribed later
      subscriptions.push({ unsubscribe, store });
    }
    websocketConnect(client, store.dispatch);
    initializeRollbar();
    initializeRum();
    return { store, routes, history, mapi };
  } catch (error) {
    console.error(error);
  }
}

if (module.hot) {
  module.hot.accept('uf/routing/syncBrowserUrlWithReduxState', () => {
    console.warn('Hot-reloading URL syncing code, re-subscribing');
    const oldSubscriptions = [...subscriptions];
    subscriptions = oldSubscriptions.map(
      ({ unsubscribe: unsubscribePrevious, store }) => {
        unsubscribePrevious();
        const unsubscribe = syncBrowserUrlWithReduxState(store);
        return { store, unsubscribe };
      },
    );
  });
  module.hot.accept(['uf/websockets', 'uf/user/backendFlags'], m => {
    console.warn('Hot-reloading root files: ', m);
  });
}
