import { GeoJsonGeometryTypes } from 'geojson';

import { ColumnKey, LayerId } from 'uf/layers';
import { isDefaultGeoColumnKey, isGeometryKey } from 'uf/layers/geometryKey';
import {
  CUSTOM_THEME,
  DataDrivenStop,
  getScaleType,
  LayerColumnSymbology,
  PaintColor,
  PaintProperty,
  SymbologyScales,
} from 'uf/symbology';
import {
  getPaintProperty,
  makeTransparentPaintColor,
} from 'uf/symbology/spec/paint';

import {
  generateCategoricalFillSymbology,
  generateCategoricalLineSymbology,
} from './createCategoricalSymbology';
import {
  generateDefaultGeometryFillSymbology,
  generateDefaultGeometryLineSymbology,
} from './createDefaultGeometrySymbology';
import {
  generateNumericFillSymbology,
  generateNumericLineSymbology,
} from './createNumericSymbology';

export function createMissingCuratedSymbologies(
  symbologies: LayerColumnSymbology[],
  layerId: LayerId,
  columnKey: ColumnKey,
  ufGeometryType: GeoJsonGeometryTypes,
) {
  // Backfill any missing default geo (NONE column) symbologies.
  // These have categorical scales, but should be backfilled differently
  // as they do not require data-driven properties.
  if (isDefaultGeoColumnKey(columnKey) || isGeometryKey(columnKey)) {
    return generateMissingDefaultGeometrySymbologies(
      symbologies,
      layerId,
      columnKey,
      ufGeometryType,
    );
  }

  // Use the first symbology as the source of truth for the scale.
  const [rootSymbology] = symbologies;
  const scale = getScaleType(rootSymbology);

  // Backfill any missing categorical symbologies.
  // These should have generated data-driven properties and transparent colors.
  if (scale === SymbologyScales.CATEGORICAL) {
    return generateMissingCategoricalSymbologies(
      symbologies,
      layerId,
      columnKey,
      ufGeometryType,
    );
  }

  // Backfill any missing numeric symbologies.
  // These should have the same data-driven stops, with transparent colors,
  // and the same display hints as the curated symbologies, except custom theme.
  if (scale === SymbologyScales.NUMERIC) {
    return generateMissingNumericSymbologies(
      symbologies,
      layerId,
      columnKey,
      ufGeometryType,
    );
  }

  return [];
}

// Generating missing numeric symbologies requires
// cloning the available symbology's color stop values and display hints.
function generateMissingNumericSymbologies(
  symbologies: LayerColumnSymbology[],
  layerId: LayerId,
  columnKey: ColumnKey,
  ufGeometryType: GeoJsonGeometryTypes,
): LayerColumnSymbology[] {
  // Assume the first symbology is data driven. TODO: Enforce this in test!
  const [rootSymbology] = symbologies;
  const paintColor = getPaintProperty<
    DataDrivenStop,
    PaintProperty<DataDrivenStop, string>
  >(rootSymbology, 'color') as PaintProperty<DataDrivenStop, string>;
  const transparentPaintColor = makeTransparentPaintColor(paintColor);
  const paintProperties: PaintColor = { paintColor: transparentPaintColor };
  const displayHints = {
    ...rootSymbology.display_hints,
    theme: CUSTOM_THEME,
  };

  const fillSymbology = symbologies.find(({ type }) => type === 'fill');
  const lineSymbology = symbologies.find(({ type }) => type === 'line');

  if (['MultiPolygon', 'Polygon'].includes(ufGeometryType)) {
    const missingSymbologies: LayerColumnSymbology[] = [];
    if (!fillSymbology) {
      const transparentFillSymbology = generateNumericFillSymbology(
        layerId,
        columnKey,
        paintProperties,
        displayHints,
      );
      missingSymbologies.push(transparentFillSymbology);
    }

    if (!lineSymbology) {
      const transparentLineSymbology = generateNumericLineSymbology(
        layerId,
        columnKey,
        { paintColor: 'transparent' },
        displayHints,
      );
      missingSymbologies.push(transparentLineSymbology);
    }

    return missingSymbologies;
  }

  return [];
}

// Generating missing categorical symbology does not
// require any info about the available symbologies.
function generateMissingCategoricalSymbologies(
  symbologies: LayerColumnSymbology[],
  layerId: LayerId,
  columnKey: ColumnKey,
  ufGeometryType: GeoJsonGeometryTypes,
): LayerColumnSymbology[] {
  // Assume the first symbology is data driven. TODO: Enforce this in test!
  const [rootSymbology] = symbologies;
  const paintColor = getPaintProperty(rootSymbology, 'color') as PaintProperty<
    DataDrivenStop,
    string
  >;
  const transparentPaintColor = makeTransparentPaintColor(paintColor);
  const paintProperties = { paintColor: transparentPaintColor };
  const displayHints = {
    ...rootSymbology.display_hints,
    theme: CUSTOM_THEME,
  };

  const fillSymbology = symbologies.find(({ type }) => type === 'fill');
  const lineSymbology = symbologies.find(({ type }) => type === 'line');

  if (['MultiPolygon', 'Polygon'].includes(ufGeometryType)) {
    const missingSymbologies: LayerColumnSymbology[] = [];
    if (!fillSymbology) {
      const transparentFillSymbology = generateCategoricalFillSymbology(
        layerId,
        columnKey,
        paintProperties,
        displayHints,
      );
      missingSymbologies.push(transparentFillSymbology);
    }

    if (!lineSymbology) {
      const transparentLineSymbology = generateCategoricalLineSymbology(
        layerId,
        columnKey,
        { paintColor: 'transparent' },
        displayHints,
      );
      missingSymbologies.push(transparentLineSymbology);
    }

    return missingSymbologies;
  }

  return [];
}

function generateMissingDefaultGeometrySymbologies(
  symbologies: LayerColumnSymbology[],
  layerId: LayerId,
  columnKey: ColumnKey,
  ufGeometryType: GeoJsonGeometryTypes,
): LayerColumnSymbology[] {
  const fillSymbology = symbologies.find(({ type }) => type === 'fill');
  const lineSymbology = symbologies.find(({ type }) => type === 'line');

  // Polygon layers need both a fill and line symbology, but we may not have curated everything.
  // This will create a transparent fill/line symbology where needed
  if (['MultiPolygon', 'Polygon'].includes(ufGeometryType)) {
    const missingSymbologies: LayerColumnSymbology[] = [];
    if (!fillSymbology) {
      const transparentFillSymbology = generateDefaultGeometryFillSymbology(
        layerId,
        columnKey,
      );
      missingSymbologies.push(transparentFillSymbology);
    }

    if (!lineSymbology) {
      const transparentLineSymbology = generateDefaultGeometryLineSymbology(
        layerId,
        columnKey,
        true,
      );
      missingSymbologies.push(transparentLineSymbology);
    }

    return missingSymbologies;
  }

  return [];
}
