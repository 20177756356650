import _ from 'lodash';
import { createSelector } from 'reselect';
import { t } from 'ttag';

import {
  AnalysisModuleInfo,
  PolicyAnalysisModuleResults,
  ScenarioAnalysisModuleResults,
  ScenarioMetadata,
} from 'uf-api';
import { ModuleKey, ModuleRunStatus } from 'uf/analysis';
import {
  AnalysisStatusState,
  getModuleIsDone,
  getModuleIsRunning,
  getModuleRunStatusForProject,
} from 'uf/analysis/helpers';
import { getActiveProject } from 'uf/app/selectors';
import { EMPTY_ARRAY, EMPTY_OBJECT } from 'uf/base';
import { makeGetFromPropsSelector } from 'uf/base/selector';
import { makeGetOrderedScenariosForProject } from 'uf/explore/selectors/scenarios';
import { ProjectId } from 'uf/projects';
import {
  makeGetProjectById,
  makeGetProjectScenario,
} from 'uf/projects/selectors';
import { ScenarioId } from 'uf/scenarios';

function getAnalysisState(state) {
  return state.analysis || EMPTY_OBJECT;
}

export const getAnalysisStatus = createSelector(
  getAnalysisState,
  (analysisState): AnalysisStatusState => analysisState.status || EMPTY_OBJECT,
);

export const getActiveProjectAnalysisModules = createSelector(
  getActiveProject,
  (project): AnalysisModuleInfo[] => {
    if (!project) {
      return EMPTY_ARRAY;
    }

    const { analysis_modules: modules } = project;
    if (!modules) {
      return EMPTY_ARRAY;
    }
    return modules;
  },
);

export function makeGetProjectAnalysisModules() {
  return createSelector(
    makeGetProjectById(),
    (project): AnalysisModuleInfo[] => {
      if (!project) {
        return EMPTY_ARRAY;
      }

      const { analysis_modules: modules } = project;
      if (!modules) {
        return EMPTY_ARRAY;
      }
      return modules;
    },
  );
}

export function makeGetProjectAnalysisModuleKeys() {
  return createSelector(
    makeGetProjectAnalysisModules(),
    (modules): ModuleKey[] => modules.map(module => module.module_key),
  );
}

function makeGetProjectAnalysisModulesByKey() {
  return createSelector(
    makeGetProjectAnalysisModules(),
    (modules: AnalysisModuleInfo[]) => _.keyBy(modules, 'module_key'),
  );
}

export const getActiveProjectAnalysisModulesByKey = createSelector(
  getActiveProjectAnalysisModules,
  analysisModules => _.keyBy(analysisModules, 'module_key'),
);

export interface ScenarioAnalysisResultsByModule {
  [moduleKey: string]: Record<ScenarioId, PolicyAnalysisModuleResults[]>;
}
/**
 * returns analysis results by scenario key by module key:
 *
 * Example:
 * {
 *   water_demand: {
 *     '/ns/scenario/scenario_key_1': [policyResult1, policyResult2],
 *     '/ns/scenario/scenario_key_2': [],
 *   },
 *   energy_demand: {},
 * }
 */
export function makeGetProjectAnalysisResultsByModuleKey() {
  const getProjectScenarios = makeGetOrderedScenariosForProject();
  return createSelector(
    getProjectScenarios,
    makeGetProjectAnalysisModulesByKey(),
    (scenarios, modulesByKey): ScenarioAnalysisResultsByModule => {
      const resultsByModuleKey = _.mapValues(modulesByKey, () => ({}));

      scenarios.forEach(scenario => {
        const results = scenario.analysis_results || [];
        results.forEach(({ policies = [], module_key: moduleKey }) => {
          policies.forEach(policy => {
            const moduleResults = resultsByModuleKey[moduleKey];

            if (moduleResults) {
              if (moduleResults[scenario.full_path]) {
                moduleResults[scenario.full_path].push(policy);
              } else {
                moduleResults[scenario.full_path] = [policy];
              }
            } else {
              // TODO: figure out why specific modules like fiscal_cost_madison
              // are missing from this object
              console.warn('Missing results for ', moduleKey);
              resultsByModuleKey[moduleKey] = {
                [scenario.full_path]: [policy],
              };
            }
          });
        });
      });

      return resultsByModuleKey;
    },
  );
}

export function makeGetScenarioAnalysisResults() {
  const getProjectScenario = makeGetProjectScenario();
  return createSelector(
    getProjectScenario,
    (scenario: ScenarioMetadata): ScenarioAnalysisModuleResults[] => {
      if (!scenario) {
        return EMPTY_ARRAY;
      }
      return scenario.analysis_results || EMPTY_ARRAY;
    },
  );
}

export function makeGetScenarioAnalysisResultsForModuleKey() {
  const getScenarioAnalysisResults = makeGetScenarioAnalysisResults();
  return createSelector(
    makeGetFromPropsSelector<string, 'moduleKey'>('moduleKey'),
    getScenarioAnalysisResults,
    (moduleKey: string, analysisResults: ScenarioAnalysisModuleResults[]) =>
      analysisResults.find(results => results.module_key === moduleKey),
  );
}

export interface AnalysisModuleStateInfo {
  moduleKey: string;
  moduleIsRunning: boolean;
  moduleIsDone: boolean;
  hasResults: boolean;
  statusMessage: string;
}

export function makeGetAnalysisModuleInfosForAnalysisEditor() {
  const getProjectAnalysisResultsByModuleKey =
    makeGetProjectAnalysisResultsByModuleKey();

  return createSelector(
    getActiveProjectAnalysisModules,
    getProjectAnalysisResultsByModuleKey,
    getAnalysisStatus,
    makeGetFromPropsSelector<ProjectId, 'projectId'>('projectId'),
    (
      modules,
      analysisResultsByModuleKey,
      moduleStatuses,
      projectId,
    ): AnalysisModuleStateInfo[] => {
      const moduleListItemInfo = modules.map(module => {
        const moduleKey = module.module_key;
        const scenariosWithResults = analysisResultsByModuleKey[moduleKey];
        const scenarioIds = Object.keys(scenariosWithResults);
        const runStatuses = getModuleRunStatusForProject(
          moduleStatuses,
          moduleKey,
          projectId,
        );
        const moduleIsRunning = _.some(runStatuses, status =>
          getModuleIsRunning(status),
        );
        const moduleIsDone = _.some(runStatuses, status =>
          getModuleIsDone(status),
        );
        const statusMessage = getModuleListItemStatusMessage(
          scenariosWithResults,
          runStatuses,
          moduleIsDone,
        );

        return {
          moduleKey,
          moduleIsRunning,
          moduleIsDone,
          hasResults: !!scenarioIds.length,
          statusMessage,
        };
      });

      return moduleListItemInfo;
    },
  );
}

function getModuleListItemStatusMessage(
  scenariosWithResults: Record<string, PolicyAnalysisModuleResults[]>,
  scenarioRunStatuses: Record<string, ModuleRunStatus>,
  moduleIsDone: boolean,
) {
  const numScenariosRunning = _.filter(
    scenarioRunStatuses,
    (status: ModuleRunStatus) => getModuleIsRunning(status),
  ).length;
  const numScenariosWithStaleResults = _.filter(
    scenariosWithResults,
    policies => policies.some(policy => policy.stale),
  ).length;
  const numScenariosWithResults = Object.keys(scenariosWithResults).length;

  if (numScenariosRunning === 1) {
    return t`Running for 1 scenario...`;
  }

  if (numScenariosRunning > 1) {
    return t`Running for ${numScenariosRunning} scenarios...`;
  }

  if (numScenariosWithStaleResults === 1) {
    return t`Out of date for 1 scenario`;
  }

  if (numScenariosWithStaleResults > 1) {
    return t`Out of date for ${numScenariosWithStaleResults} scenarios`;
  }

  // special case here because we are notifiied that the module completes before the project
  // updates. perform this check to prevent the status message from flashing 'Not run for any
  // scenarios' as it transitions between 'Running for 1 scenario...' to 'Run for 1 scenario'.
  if (numScenariosWithResults === 0 && moduleIsDone) {
    return t`Run for 1 scenario`;
  }

  if (numScenariosWithResults === 0) {
    return t`Not run for any scenarios`;
  }

  if (numScenariosWithResults === 1) {
    return t`Run for 1 scenario`;
  }

  if (numScenariosWithResults > 1) {
    return t`Run for ${numScenariosWithResults} scenarios`;
  }
}
