import colors from 'material-colors';
import { t } from 'ttag';

import { ACRES, getUnitName } from 'uf/base/units';
import { LegacyChart, LegacyReport } from 'uf/reporting/index';

import { defaultEmptyChartContent } from './emptyChartMessage';

const IN_SENTENCE = true;

const tncSource = {
  source: 'analysis',
  sourceKey: 'tnc_conservation',
} as const;

const TotalNaturalLandGains = {
  ...tncSource,
  key: 'total_natural_land_gains',
  layerKey: 'tnc_conservation_landtypes_gains_total',
  columnKey: 'natural_gains',
  color: colors.lime[700],
};

const TotalUrbanLandGains = {
  ...tncSource,
  key: 'total_urban_land_gains',
  layerKey: 'tnc_conservation_landtypes_gains_total',
  columnKey: 'urban_gains',
  color: colors.blueGrey[700],
};

const TotalAgLandGains = {
  ...tncSource,
  key: 'total_ag_land_gains',
  layerKey: 'tnc_conservation_landtypes_gains_total',
  columnKey: 'agriculture_gains',
  color: colors.yellow[700],
};

const TotalNaturalLandLosses = {
  ...tncSource,
  key: 'total_natural_land_losses',
  layerKey: 'tnc_conservation_landtypes_loss_total',
  columnKey: 'natural_losses',
  color: colors.lime[700],
};

const TotalUrbanLandLosses = {
  ...tncSource,
  key: 'total_urban_land_losses',
  layerKey: 'tnc_conservation_landtypes_loss_total',
  columnKey: 'urban_losses',
  color: colors.blueGrey[700],
};

const TotalAgLandLosses = {
  ...tncSource,
  key: 'total_ag_land_losses',
  layerKey: 'tnc_conservation_landtypes_loss_total',
  columnKey: 'agriculture_losses',
  color: colors.yellow[700],
};

export const tncChartsSharedGen: LegacyChart[] = [
  {
    name: t`Increase in Land Cover Area by Type`,
    key: 'total_land_gains',
    description: t`Total Increase in Acreage of Land Cover Types`,
    subtitle: t`Change`,
    series: [TotalUrbanLandGains, TotalAgLandGains, TotalNaturalLandGains],
    units: getUnitName(ACRES, IN_SENTENCE),
    showLegend: true,
    showFullLabel: true,
    emptyChartContent: defaultEmptyChartContent,
  },
  {
    name: t`Decrease in Land Cover Area by Type`,
    description: t`Total Decrease in Acreage of Land Cover Types`,
    subtitle: t`Change`,
    key: 'total_land_losses',
    series: [TotalUrbanLandLosses, TotalNaturalLandLosses, TotalAgLandLosses],
    units: getUnitName(ACRES, IN_SENTENCE),
    showLegend: true,
    showFullLabel: true,
    emptyChartContent: defaultEmptyChartContent,
  },
];

export const tncChartsGen: LegacyReport = Object.freeze({
  key: 'tnc_conservation',
  name: t`Conservation`,
  colorSpec: {
    primaryColor: colors.lime[700],
    interpolateFromColor: 'rgb(255, 214, 79)',
    interpolateToColor: 'rgb(27, 94, 31)',
  },
  charts: [...tncChartsSharedGen],
});
