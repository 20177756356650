import {
  CuratedColumnSymbology,
  InterpolationTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/public/dataset/city_of_somerville_green_line_ext_stops': {
    name: [
      {
        display_hints: {
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          scale: SymbologyScales.CATEGORICAL,
          theme: 'd3:schemeCategory10',
        },
        paint: {
          'circle-color': {
            default: '#7f7f7f',
            property: 'name',
            stops: [
              {
                color: '#1f77b4',
                value: 'Ball Square',
              },
              {
                color: '#ff7f0e',
                value: 'College Avenue',
              },
              {
                color: '#2ca02c',
                value: 'Gilman Square',
              },
              {
                color: '#d62728',
                value: 'Lowell Street',
              },
              {
                color: '#9467bd',
                value: 'Union Square',
              },
              {
                color: '#8c564b',
                value: 'Washington Street',
              },
              {
                color: '#e377c2',
                value: 'Winthrop Street',
              },
            ],
            type: 'categorical',
          },
          'circle-opacity': 0.9,
          'circle-radius': 8,
          'circle-stroke-color': 'transparent',
        },
        type: SymbologyTypes.CIRCLE,
      },
    ],
  },
};

export default symbologies;
