import { LayerMetadata, LayerStats } from 'uf-api';
import { DataState } from 'uf/data/dataState';
import { ColumnKey, LayerId } from 'uf/layers';
import { getUfGeometryType } from 'uf/layers/helpers';
import {
  getColumnMetadata,
  isCategoricalColumn,
  isNumericColumn,
} from 'uf/layers/metadata';
import { findColumnKeyStats, getCategoricalStatsValues } from 'uf/layers/stats';
import {
  CuratedColumnSymbology,
  LayerColumnSymbology,
  SymbologyTypes,
} from 'uf/symbology';
import {
  clampSymbologiesToLayerMinMax,
  convertToLayerColumnSymbologyOld,
  insertColumnKeys,
  insertSymbologyIds,
  pruneUnusedCategories,
  removeNumericStopLabels,
} from 'uf/symbology/clean';
import { columnUsesStats } from 'uf/symbology/stats';

export function getCleanedSymbologyDataState(
  layerId: LayerId,
  columnKey: ColumnKey,
  layerMetadata: LayerMetadata,
  layerStats: LayerStats,
  hardcodedSymbologies: CuratedColumnSymbology[],
  loadingState: DataState<null>,
  hasSymbolSupportFlag: boolean,
): DataState<LayerColumnSymbology[]> {
  const symbology = cleanHardcodedSymbologies(
    layerId,
    columnKey,
    layerMetadata,
    layerStats,
    hardcodedSymbologies,
    hasSymbolSupportFlag,
  );
  return { ...loadingState, data: symbology };
}

function cleanHardcodedSymbologies(
  layerId: LayerId,
  columnKey: ColumnKey,
  layerMetadata: LayerMetadata,
  layerStats: LayerStats,
  curatedSymbologies: CuratedColumnSymbology[],
  hasSymbolSupportFlag: boolean,
): LayerColumnSymbology[] {
  const ufGeometryType = getUfGeometryType(layerMetadata);
  const columnMetadata = getColumnMetadata(layerMetadata, columnKey);

  let finalSymbologies = convertToLayerColumnSymbologyOld(
    layerId,
    columnKey,
    curatedSymbologies,
    ufGeometryType,
  );

  if (!hasSymbolSupportFlag) {
    finalSymbologies = finalSymbologies.filter(
      ({ type }) => type !== SymbologyTypes.SYMBOL,
    );
  }

  // add missing symbology ids
  finalSymbologies = insertSymbologyIds(finalSymbologies, layerId, columnKey);

  // replace column keys in the style for the the layer's column key
  finalSymbologies = insertColumnKeys(finalSymbologies, columnKey);

  if (columnUsesStats(layerMetadata, columnKey)) {
    const columnStats = findColumnKeyStats(layerStats, columnKey);
    if (isNumericColumn(columnMetadata)) {
      finalSymbologies = removeNumericStopLabels(finalSymbologies);
      finalSymbologies = clampSymbologiesToLayerMinMax(
        finalSymbologies,
        columnStats.numeric.min,
        columnStats.numeric.max,
      );
    }

    if (isCategoricalColumn(columnMetadata)) {
      const categories = getCategoricalStatsValues(columnStats);
      finalSymbologies = pruneUnusedCategories(finalSymbologies, categories);
    }
  }
  return finalSymbologies;
}
