/**
 * UrbanFootprint App API
 * API for serving UrbanFootprint Application
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Return the value of the column with the given `key`.
 */
export interface ExpressionColumn<> {
  /**
   * Expression function, set to \"column\".
   */
  fn: ExpressionColumn.FnEnum;
  /**
   * The key of the column.
   */
  key: string;
}
export namespace ExpressionColumn {
  export type FnEnum = 'column';
  export const FnEnum = {
    Column: 'column',
  } as const;
}
