import { createSelector, Selector } from 'reselect';

import { UserData } from 'uf-api';
import { EMPTY_OBJECT } from 'uf/base';
import { makeGetFromPropsSelector } from 'uf/base/selector';
import { DataState } from 'uf/data/dataState';
import { ColumnKey, LayerId } from 'uf/layers';
import { ProjectId } from 'uf/projects';
import { UFState } from 'uf/state';
import { getDivideByColumnFromUserDataState } from 'uf/symbology/data';
import {
  DivideByColumn,
  DivideByColumnKey,
  makeDivideByColumnUserDataKey,
} from 'uf/symbology/divideByColumn';
import { makeUserDataKey } from 'uf/user';
import { getUserDataState, getUserKey } from 'uf/user/selectors/user';
import { TypedUserData } from 'uf/user/state';

export function makeGetDivideByColumnState() {
  return createSelector(
    getUserDataState,
    getUserKey,
    makeGetFromPropsSelector<ProjectId, 'projectId'>('projectId'),
    makeGetFromPropsSelector<LayerId, 'layerId'>('layerId'),
    makeGetFromPropsSelector<ColumnKey, 'columnKey'>('columnKey'),
    (userDataState, userKey, projectId, layerId, columnKey) =>
      getDivideByColumnState(
        userDataState,
        userKey,
        projectId,
        layerId,
        columnKey,
      ),
  );
}

export type GetDivideByColumnState = (
  projectId: ProjectId,
  layerId: LayerId,
  columnKey: ColumnKey,
) => DataState<TypedUserData<DivideByColumn>>;
export function makeGetDivideByColumnStateGetter(): Selector<
  UFState,
  GetDivideByColumnState
> {
  return createSelector(
    getUserDataState,
    getUserKey,
    (userDataState, userKey) =>
      (projectId: ProjectId, layerId: LayerId, columnKey: ColumnKey) =>
        getDivideByColumnState(
          userDataState,
          userKey,
          projectId,
          layerId,
          columnKey,
        ),
  );
}

export function makeGetDivideByColumn() {
  return createSelector(makeGetDivideByColumnState(), divideByColumnState =>
    getDivideByColumnFromUserDataState(divideByColumnState),
  );
}

export type GetDivideByColumn = (
  projectId: ProjectId,
  layerId: LayerId,
  columnKey: ColumnKey,
) => DivideByColumnKey;
export function makeGetDivideByColumnGetter() {
  return createSelector(
    getUserDataState,
    getUserKey,
    (userDataState, userKey) =>
      (projectId: ProjectId, layerId: LayerId, columnKey: ColumnKey) =>
        getDivideByColumnFromUserDataState(
          getDivideByColumnState(
            userDataState,
            userKey,
            projectId,
            layerId,
            columnKey,
          ),
        ),
  );
}

function getDivideByColumnState(
  userDataState: Record<string, DataState<UserData>>,
  userKey: string,
  projectId: ProjectId,
  layerId: LayerId,
  columnKey: ColumnKey,
): DataState<TypedUserData<DivideByColumn>> {
  const divideByKey = makeDivideByColumnUserDataKey(
    projectId,
    layerId,
    columnKey,
  );
  const key = makeUserDataKey(userKey, divideByKey);
  return userDataState[key] || (EMPTY_OBJECT as DataState<UserData>);
}
