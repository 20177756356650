/**
 * UrbanFootprint App API
 * API for serving UrbanFootprint Application
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Global Variables used throughout the Library.
 */
export interface LibraryVariables<> {
  /**
   * Area per AGRICULTURE employee, high density.
   */
  area_per_emp_ag_agriculture_high?: number;
  /**
   * Area per AGRICULTURE employee, low density.
   */
  area_per_emp_ag_agriculture_low?: number;
  /**
   * Area per AGRICULTURE employee, mid density.
   */
  area_per_emp_ag_agriculture_mid?: number;
  /**
   * Area per EXTRACTION employee, high density.
   */
  area_per_emp_ag_extraction_high?: number;
  /**
   * Area per EXTRACTION employee, low density.
   */
  area_per_emp_ag_extraction_low?: number;
  /**
   * Area per EXTRACTION employee, mid density.
   */
  area_per_emp_ag_extraction_mid?: number;
  /**
   * Area per CONSTRUCTION employee, high density.
   */
  area_per_emp_ind_construction_high?: number;
  /**
   * Area per CONSTRUCTION employee, low density.
   */
  area_per_emp_ind_construction_low?: number;
  /**
   * Area per CONSTRUCTION employee, mid density.
   */
  area_per_emp_ind_construction_mid?: number;
  /**
   * Area per MANUFACTURING employee, high density.
   */
  area_per_emp_ind_manufacturing_high?: number;
  /**
   * Area per MANUFACTURING employee, low density.
   */
  area_per_emp_ind_manufacturing_low?: number;
  /**
   * Area per MANUFACTURING employee, mid density.
   */
  area_per_emp_ind_manufacturing_mid?: number;
  /**
   * Area per TRANSPORT_WARESHOUSING employee, high density.
   */
  area_per_emp_ind_transport_warehousing_high?: number;
  /**
   * Area per TRANSPORT_WARESHOUSING employee, low density.
   */
  area_per_emp_ind_transport_warehousing_low?: number;
  /**
   * Area per TRANSPORT_WARESHOUSING employee, mid density.
   */
  area_per_emp_ind_transport_warehousing_mid?: number;
  /**
   * Area per UTILITIES employee, high density.
   */
  area_per_emp_ind_utilities_high?: number;
  /**
   * Area per UTILITIES employee, low density.
   */
  area_per_emp_ind_utilities_low?: number;
  /**
   * Area per UTILITIES employee, mid density.
   */
  area_per_emp_ind_utilities_mid?: number;
  /**
   * Area per WHOLESALE employee, high density.
   */
  area_per_emp_ind_wholesale_high?: number;
  /**
   * Area per WHOLESALE employee, low density.
   */
  area_per_emp_ind_wholesale_low?: number;
  /**
   * Area per WHOLESALE employee, mid density.
   */
  area_per_emp_ind_wholesale_mid?: number;
  /**
   * Area per military employee, high density.
   */
  area_per_emp_military_high?: number;
  /**
   * Area per military employee, low density.
   */
  area_per_emp_military_low?: number;
  /**
   * Area per military employee, mid density.
   */
  area_per_emp_military_mid?: number;
  /**
   * Area per MEDICAL_SERVICES employee, high density.
   */
  area_per_emp_off_medical_services_high?: number;
  /**
   * Area per MEDICAL_SERVICES employee, low density.
   */
  area_per_emp_off_medical_services_low?: number;
  /**
   * Area per MEDICAL_SERVICES employee, mid density.
   */
  area_per_emp_off_medical_services_mid?: number;
  /**
   * Area per OFFICE_SERVICES employee, high density.
   */
  area_per_emp_off_office_services_high?: number;
  /**
   * Area per OFFICE_SERVICES employee, low density.
   */
  area_per_emp_off_office_services_low?: number;
  /**
   * Area per OFFICE_SERVICES employee, mid density.
   */
  area_per_emp_off_office_services_mid?: number;
  /**
   * Area per EDUCATION employee, high density.
   */
  area_per_emp_pub_education_high?: number;
  /**
   * Area per EDUCATION employee, low density.
   */
  area_per_emp_pub_education_low?: number;
  /**
   * Area per EDUCATION employee, mid density.
   */
  area_per_emp_pub_education_mid?: number;
  /**
   * Area per PUBLIC_ADMIN employee, high density.
   */
  area_per_emp_pub_public_admin_high?: number;
  /**
   * Area per PUBLIC_ADMIN employee, low density.
   */
  area_per_emp_pub_public_admin_low?: number;
  /**
   * Area per PUBLIC_ADMIN employee, mid density.
   */
  area_per_emp_pub_public_admin_mid?: number;
  /**
   * Area per ACCOMMODATION employee, high density.
   */
  area_per_emp_ret_accommodation_high?: number;
  /**
   * Area per ACCOMMODATION employee, low density.
   */
  area_per_emp_ret_accommodation_low?: number;
  /**
   * Area per ACCOMMODATION employee, mid density.
   */
  area_per_emp_ret_accommodation_mid?: number;
  /**
   * Area per ARTS_ENTERTAINMENT employee, high density.
   */
  area_per_emp_ret_arts_entertainment_high?: number;
  /**
   * Area per ARTS_ENTERTAINMENT employee, low density.
   */
  area_per_emp_ret_arts_entertainment_low?: number;
  /**
   * Area per ARTS_ENTERTAINMENT employee, mid density.
   */
  area_per_emp_ret_arts_entertainment_mid?: number;
  /**
   * Area per OTHER_SERVICES employee, high density.
   */
  area_per_emp_ret_other_services_high?: number;
  /**
   * Area per OTHER_SERVICES employee, low density.
   */
  area_per_emp_ret_other_services_low?: number;
  /**
   * Area per OTHER_SERVICES employee, mid density.
   */
  area_per_emp_ret_other_services_mid?: number;
  /**
   * Area per RESTAURANT employee, high density.
   */
  area_per_emp_ret_restaurant_high?: number;
  /**
   * Area per RESTAURANT employee, low density.
   */
  area_per_emp_ret_restaurant_low?: number;
  /**
   * Area per RESTAURANT employee, mid density.
   */
  area_per_emp_ret_restaurant_mid?: number;
  /**
   * Area per RETAIL_SERVICES employee, high density.
   */
  area_per_emp_ret_retail_services_high?: number;
  /**
   * Area per RETAIL_SERVICES employee, low density.
   */
  area_per_emp_ret_retail_services_low?: number;
  /**
   * Area per RETAIL_SERVICES employee, mid density.
   */
  area_per_emp_ret_retail_services_mid?: number;
  /**
   * The constant to multiply by to convert coarse area units (eg. Acres) to fine area units (eg. Square Feet.)
   */
  area_scale_down_factor?: number;
  /**
   * Average of detsf acc households.
   */
  hh_size_acc?: number;
  /**
   * Average of detsf attsf households.
   */
  hh_size_attsf?: number;
  /**
   * Average of detsf ll households.
   */
  hh_size_detsf_ll?: number;
  /**
   * Average of detsf sl households.
   */
  hh_size_detsf_sl?: number;
  /**
   * Average of detsf mf households.
   */
  hh_size_mf?: number;
  /**
   * Intersection density for Compact Land Development Category.
   */
  intersection_density_compact?: number;
  /**
   * Intersection density for Rural Land Development Category.
   */
  intersection_density_rural?: number;
  /**
   * Intersection density for Standard Land Development Category.
   */
  intersection_density_standard?: number;
  /**
   * Intersection density for Undeveloped Land Development Category.
   */
  intersection_density_undeveloped?: number;
  /**
   * Intersection density for Urban Land Development Category.
   */
  intersection_density_urban?: number;
  /**
   * Measurement System used in built forms.
   */
  measurement_system?: LibraryVariables.MeasurementSystemEnum;
  /**
   * Non-Residential Occupancy Rate
   */
  occupancy_rate_nonresidential?: number;
  /**
   * Residential Occupancy Rate
   */
  occupancy_rate_residential?: number;
}
export namespace LibraryVariables {
  export type MeasurementSystemEnum = 'us' | 'metric';
  export const MeasurementSystemEnum = {
    Us: 'us',
    Metric: 'metric',
  } as const;
}
