import {
  CuratedColumnSymbology,
  CUSTOM_THEME,
  DEFAULT_OPACITY,
  DistributionTypes,
  InterpolationTypes,
  PaintColorTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

import stateList from './us_states';

const symbologies: Record<
  string,
  Record<string, CuratedColumnSymbology[]>
> = {};

stateList.forEach(stateName => {
  symbologies[`/public/dataset/habitatcores20170305_${stateName}`] = {
    score: [
      {
        type: SymbologyTypes.FILL,
        paint: {
          'fill-color': {
            property: 'score',
            type: PaintColorTypes.INTERVAL,
            stops: [
              { value: 0, color: '#edfaf4' },
              { value: 1.6, label: 'Good (1.60 - 3.31)', color: '#dcf5e9' },
              { value: 3.32, label: 'Better (3.32 - 4.12)', color: '#76a882' },
              { value: 4.13, label: 'Best (4.13 +)', color: '#226633' },
            ],
          },
          'fill-opacity': DEFAULT_OPACITY,
        },
        display_hints: {
          scale: SymbologyScales.NUMERIC,
          distribution: DistributionTypes.DEFAULT,
          interpolation: InterpolationTypes.INTERVAL,
          theme: CUSTOM_THEME,
        },
      },
    ],
  };
});

export default symbologies;
