export default [
  'alabama',
  'arizona',
  'arkansas',
  'california',
  'colorado',
  'connecticut',
  'delaware',
  'district_of_columbia',
  'florida',
  'georgia',
  'hawaii',
  'idaho',
  'illinois',
  'indiana',
  'iowa',
  'kansas',
  'kentucky',
  'louisiana',
  'maine',
  'maryland',
  'massachusetts',
  'michigan',
  'minnesota',
  'mississippi',
  'missouri',
  'montana',
  'nebraska',
  'nevada',
  'new_hampshire',
  'new_jersey',
  'new_mexico',
  'new_york',
  'north_carolina',
  'north_dakota',
  'ohio',
  'oklahoma',
  'oregon',
  'pennsylvania',
  'rhode_island',
  'south_carolina',
  'south_dakota',
  'tennessee',
  'texas',
  'utah',
  'vermont',
  'virginia',
  'washington',
  'west_virginia',
  'wisconsin',
  'wyoming',
];
