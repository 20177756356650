import { boundMethod } from 'autobind-decorator';
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { t } from 'ttag';

import { importLayer as importLayerActionCreator } from 'uf/import/actions/layers';
import { ProjectId } from 'uf/projects';
import FormDialog from 'uf/ui/base/Modals/FormDialog';
import LayerImportForm from 'uf/ui/layers/LayerImportForm/LayerImportForm';

interface OwnProps {
  projectId: ProjectId;
  show?: boolean;
  onOk?: () => void;
  onCancel?: () => void;
}

interface DispatchProps {
  // TODO: fix this type when bindActionCreators handles thunks
  importLayer: any;
}

export type LayerImportDialogProps = OwnProps & DispatchProps;

interface LayerImportDialogState {
  error: Error;
  formData: any;
  busy: boolean;
}

export class LayerImportDialog extends Component<
  LayerImportDialogProps,
  LayerImportDialogState
> {
  state = {
    error: null,
    formData: null,
    busy: false,
  };

  componentDidUpdate(prevProps: Readonly<OwnProps & DispatchProps>): void {
    if (prevProps.show !== this.props.show) {
      // clear out the file every time the dialog opens
      this.setState({ error: null, formData: null });
    }
  }

  @boundMethod
  onUpload() {
    const { projectId, importLayer } = this.props;
    const { formData } = this.state;
    this.setState({ busy: true, error: null });
    importLayer(projectId, formData.file, formData.name)
      .then(() => {
        const { onOk } = this.props;
        this.setState({ busy: false });
        if (onOk) {
          onOk();
        }
      })
      .catch(error => {
        this.setState({ error, busy: false });
      });
  }
  @boundMethod
  onCancel() {
    this.setState({ error: null, busy: false });
    const { onCancel } = this.props;
    if (onCancel) {
      onCancel();
    }
  }

  @boundMethod
  onFormUpdate(formData) {
    this.setState({ formData });
  }
  canUpload() {
    const { formData, busy } = this.state;
    return !busy && formData && formData.file;
  }
  render() {
    const { show } = this.props;
    const { busy, error } = this.state;
    return (
      <FormDialog
        show={show}
        busy={busy}
        error={error}
        onCancel={this.onCancel}
        onHide={_.noop}
        cancelText={busy ? t`Close` : t`Cancel`}
        title={t`Upload New Data Layer`}
        okText={t`Upload`}
        okDisabled={!this.canUpload()}
        onOk={this.onUpload}>
        <LayerImportForm disabled={busy} onFormUpdate={this.onFormUpdate} />
      </FormDialog>
    );
  }
}

export default connect<never, DispatchProps, OwnProps>(
  null,
  (dispatch: Dispatch) => ({
    importLayer: bindActionCreators(importLayerActionCreator, dispatch),
  }),
)(LayerImportDialog);
