import { combineEpics } from 'uf/base/epics';

import analysis from './analysis';
import painting from './painting';
import routing from './routing';

export default combineEpics(
  {
    routing,
    analysis,
    painting,
  },
  'reporting',
);
