import {
  CuratedColumnSymbology,
  CUSTOM_THEME,
  DEFAULT_OPACITY,
  InterpolationTypes,
  PaintColorTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

import stateList from './us_states';

const symbologies: Record<
  string,
  Record<string, CuratedColumnSymbology[]>
> = {};

stateList.forEach(stateName => {
  symbologies[`/public/dataset/tiger_rails_${stateName}`] = {
    mtfcc: [
      {
        type: SymbologyTypes.LINE,
        paint: {
          'line-color': {
            property: 'mtfcc',
            type: PaintColorTypes.CATEGORICAL,
            stops: [
              {
                value: 'R1011',
                label: 'Railroad Feature (Main, Spur, or Yard)',
                color: '#e3d740',
              },
              {
                value: 'R1051',
                label:
                  'Carline, Streetcar Track, Monorail, Other Mass Transit Rail',
                color: '#ffa040',
              },
              {
                value: 'R1052',
                label: 'Cog Rail Line, Incline Rail Line, Tram',
                color: '#ff4940',
              },
            ],
          },
          'line-opacity': DEFAULT_OPACITY,
        },
        display_hints: {
          scale: SymbologyScales.CATEGORICAL,
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          theme: CUSTOM_THEME,
        },
      },
    ],
  };
});

export default symbologies;
