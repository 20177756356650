import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  useGetUserSettingsQuery,
  useUpdateGlobalSettingMutation,
  useUpdateOrgSettingMutation,
} from 'uf-api-rtk/store/Api';
import { setActiveOrg } from 'uf/app/slice';
import { setActiveProject } from 'uf/app/actions';
import { getActiveOrganizationKey, getActiveProjectId } from 'uf/app/selectors';
import { parseFullPath } from 'uf/base/dataset';
import { ProjectId } from 'uf/projects';
import { getUser } from 'uf/user/selectors/user';
import { setUIProperty } from 'uf/explore/actions';

export function useCurrentUser() {
  return useSelector(getUser);
}

export function usePreferredOrgKey(): [
  string | undefined,
  (orgKey: string) => void,
] {
  const dispatch = useDispatch();
  const { data } = useGetUserSettingsQuery();
  const activeOrgKey = useSelector(getActiveOrganizationKey);
  const currentOrgKey = activeOrgKey || data?.active_org_key;
  const [mutation] = useUpdateGlobalSettingMutation();
  const setDefaultOrgKey = useCallback(
    (orgKey: string) => {
      dispatch(setActiveOrg(orgKey));
      if (window.location.pathname.startsWith('/settings')) {
        // navigate to the settings page for new org if already in settings
        dispatch(setUIProperty('settingsViewVisible', orgKey));
      } else {
        // navigate to explore project view in other cases and hide url defined elements
        dispatch(setUIProperty('exploreViewVisible', true));
        dispatch(setUIProperty('analysisUIVisible', false));
        dispatch(setUIProperty('paintUIVisible', false));
      }
      mutation({
        userGlobalSetting: { active_org_key: orgKey },
      }).then(response => {
        // load new active project on success
        if ('data' in response) {
          const nextProject = response.data.active_project_full_path;
          if (nextProject) {
            dispatch(
              setActiveProject(nextProject, {
                redirectToExplore: false,
                forceProjectReload: true,
              }),
            );
          }
        }
      });
    },
    [dispatch, mutation],
  );
  return [currentOrgKey, setDefaultOrgKey];
}

export function usePreferredProjectId(
  orgKey: string,
): [ProjectId | undefined, (projectId: ProjectId) => void] {
  const dispatch = useDispatch();
  const { data } = useGetUserSettingsQuery();
  const activeProjectId = useSelector(getActiveProjectId);
  const currentProjectId = activeProjectId || data?.active_project_full_path;
  const [mutation] = useUpdateOrgSettingMutation();
  const setDefaultProjectId = useCallback(
    (projectId: ProjectId) => {
      dispatch(
        setActiveProject(projectId, {
          redirectToExplore: false,
          forceProjectReload: true,
        }),
      );
      mutation({
        orgKey,
        userOrgSetting: {
          active_project_key: parseFullPath(projectId).key,
        },
      });
    },
    [mutation, dispatch, orgKey],
  );
  return [currentProjectId, setDefaultProjectId];
}
