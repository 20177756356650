import {
  Button,
  Classes,
  Intent,
  IResizeEntry,
  Position,
  ResizeSensor,
  Spinner,
  Tooltip,
} from '@blueprintjs/core';
import loadable from '@loadable/component';
import { boundMethod } from 'autobind-decorator';
import classNames from 'classnames';
import React, { Component, Fragment } from 'react';
import {
  DraggableProvidedDraggableProps,
  DraggableProvidedDragHandleProps,
} from 'react-beautiful-dnd';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ThunkActionMapDispatch } from 'redux-thunk';
import { t } from 'ttag';
import { ProjectMetadata, ScenarioMetadata } from 'uf-api';
import * as appActionCreators from 'uf/app/actions';
import { getNextScenarioOrdinal } from 'uf/app/selectors';
import { EMPTY_OBJECT } from 'uf/base';
import { Omit } from 'uf/base/types';
import * as exploreActionCreators from 'uf/explore/actions';
import { ProjectId } from 'uf/projects';
import * as projectActionCreators from 'uf/projects/actions';
import { ScenarioId } from 'uf/scenarios';
import rootStyles from 'uf/styles/root.module.css';
import { tw } from 'uf/tailwindcss-classnames';
import { elementsOverflow } from 'uf/ui/base/domHelpers';
import { IconSize } from 'uf/ui/base/icon';
import ConfirmationModal from 'uf/ui/base/Modals/Confirmation';
import { GetComponentProps } from 'uf/ui/base/types';
import ScenarioEditModal from 'uf/ui/scenarios/ScenarioEditModal/ScenarioEditModal';
import BaseScenarioOptionsMenu from 'uf/ui/scenarios/ScenarioOptionsMenu/BaseScenarioOptionsMenu';
import ScenarioOptionsMenu from 'uf/ui/scenarios/ScenarioOptionsMenu/ScenarioOptionsMenu';
import ScenarioTabMenu from 'uf/ui/scenarios/ScenarioTab/ScenarioTabMenu';
import { makeGetUserFlag } from 'uf/user/selectors/flags';
import styles from './ScenarioTab.module.css';

const CreateProjectDialog = loadable(
  () =>
    import(
      'uf/ui/projects/CreateProjectDialog/CreateProjectDialog' /* webpackChunkName: "project" */
    ),
);

interface StateProps {
  nextScenarioOrdinal?: number;
  hasCloneProjectFlag: boolean;
}

interface OwnProps {
  projectId: ProjectId;

  // Temporary, until we figure out a better create-project approach
  project?: ProjectMetadata;
  scenario: ScenarioMetadata;
  disabled?: boolean;
  isUpdating?: boolean;
  statusMessage?: string;
  active?: boolean;
  // loading state of all scenarios, this might be broken right now
  scenariosLoading?: boolean;
  dataAttributes?: object;

  // if this scenario is loading we show a skeleton state
  loading?: boolean;
  innerRef?: (ref: HTMLElement) => any;
  dragAndDropProps?: DraggableProvidedDraggableProps &
    DraggableProvidedDragHandleProps;
}

interface DispatchProps {
  setActiveScenario?: (projectId: ProjectId, scenarioId: ScenarioId) => void;
  projectActions?: ThunkActionMapDispatch<typeof projectActionCreators>;
  setUIProperty?: (property: string, value: any) => void;
}

type Props = OwnProps & DispatchProps & StateProps;
interface State {
  scenarioEditModalOpen: boolean;
  scenarioDeleteModalOpen: boolean;

  createProjectModalOpen: boolean;

  overflowed: boolean;
}
const scenarioTabClass = classNames(
  'ScenarioTab',
  rootStyles.justifyContentSpaceBetween,
  styles.scenarioTab,
  Classes.TEXT_OVERFLOW_ELLIPSIS,
);

export class ScenarioTab extends Component<Props, State> {
  static defaultProps = {
    scenario: EMPTY_OBJECT,
  };
  state = {
    scenarioEditModalOpen: false,
    scenarioDeleteModalOpen: false,
    createProjectModalOpen: false,
    overflowed: false,
  };

  @boundMethod
  showScenarioEditModal() {
    this.setState({
      scenarioEditModalOpen: true,
    });
  }

  @boundMethod
  hideScenarioEditModal() {
    this.setState({
      scenarioEditModalOpen: false,
    });
  }

  @boundMethod
  showScenarioDeleteModal() {
    this.setState({
      scenarioDeleteModalOpen: true,
    });
  }

  @boundMethod
  hideScenarioDeleteModal() {
    this.setState({
      scenarioDeleteModalOpen: false,
    });
  }

  @boundMethod
  showCreateProjectModal() {
    this.setState({
      createProjectModalOpen: true,
    });
  }

  @boundMethod
  hideCreateProjectModal() {
    this.setState({
      createProjectModalOpen: false,
    });
  }

  @boundMethod
  handleScenarioClick() {
    const {
      projectId,
      scenario: { full_path: scenarioId },
      setActiveScenario,
    } = this.props;

    setActiveScenario(projectId, scenarioId);
  }

  // TODO:
  // Move this handler to the EditScenarioModal, make it a connected component
  @boundMethod
  handleEditScenario(name, description) {
    const {
      projectId,
      scenario: { full_path: scenarioId },
      projectActions: { editScenario },
    } = this.props;

    editScenario(scenarioId, projectId, name, description);
  }

  // TODO:
  // Move this handler to the ScenarioOptionsMenu, make it a connected component
  @boundMethod
  handleDeleteScenario() {
    const {
      scenario: { full_path: scenarioId },
      projectId,
      projectActions: { deleteScenarioAPI: deleteScenario },
    } = this.props;
    deleteScenario(projectId, scenarioId);
  }

  // TODO:
  // Move this handler to the ScenarioOptionsMenu, make it a connected component
  @boundMethod
  handleCloneScenario() {
    const {
      projectId,
      scenario: { full_path: scenarioId, name },
      nextScenarioOrdinal,
      projectActions: { cloneScenario },
    } = this.props;

    cloneScenario(projectId, scenarioId, name, nextScenarioOrdinal);
  }

  @boundMethod
  getBaseScenarioMenuProps() {
    const {
      scenario: { full_path: scenarioId },
      projectId,
      scenariosLoading,
      hasCloneProjectFlag,
    } = this.props;

    const baseScenarioMenuProps: Omit<
      GetComponentProps<typeof BaseScenarioOptionsMenu>,
      'closeMenu'
    > = {
      projectId,
      scenarioId,
      onCloneScenario: this.handleCloneScenario,
      onCreateProject: this.showCreateProjectModal,
      showCreateProject: hasCloneProjectFlag,
      scenariosLoading,
    };

    return baseScenarioMenuProps;
  }

  @boundMethod
  getScenarioMenuProps() {
    const {
      scenario: { full_path: scenarioId },
      projectId,
      scenariosLoading,
      hasCloneProjectFlag,
    } = this.props;

    const scenarioMenuProps: Omit<
      GetComponentProps<typeof ScenarioOptionsMenu>,
      'closeMenu'
    > = {
      scenarioId,
      projectId,
      onCloneScenario: this.handleCloneScenario,
      onEditScenario: this.showScenarioEditModal,
      onDeleteScenario: this.showScenarioDeleteModal,
      onCreateProject: this.showCreateProjectModal,
      showCreateProject: hasCloneProjectFlag,
      scenariosLoading,
    };

    return scenarioMenuProps;
  }

  @boundMethod
  onResize(entries: IResizeEntry[]) {
    const overflowed = elementsOverflow(
      entries.map(entry => entry.target),
      1,
    );
    this.setState({ overflowed });
  }

  render() {
    const {
      active,
      dataAttributes,
      projectId,
      scenario: {
        full_path: scenarioId,
        name: scenarioName,
        base_scenario: isBase,
        description,
      },
      loading,
      innerRef,
      dragAndDropProps,
      isUpdating,
    } = this.props;

    const {
      scenarioEditModalOpen,
      scenarioDeleteModalOpen,
      createProjectModalOpen,
      overflowed,
    } = this.state;

    const baseScenarioMenuProps = this.getBaseScenarioMenuProps();
    const scenarioMenuProps = this.getScenarioMenuProps();
    const scenarioNameClass = 'ScenarioTab-name';

    // the tabs have a background one shade lighter than our normal 'dark theme' buttons
    const scenarioTabClassName = active
      ? classNames(scenarioTabClass, tw('tw-bg-white'))
      : classNames(
          scenarioTabClass,
          styles.notActive,
          tw('tw-text-white', 'tw-bg-blueGray-600', 'hover:tw-bg-blueGray-500'),
        );

    // decrease the shade (make it lighter) of the menu on hover to make it stick out from the tab
    const scenarioTabMenuClass = !active
      ? tw('tw-bg-transparent', 'hover:tw-bg-blueGray-400')
      : undefined;

    let rightIcon = (
      <ScenarioTabMenu
        disabled={loading || isUpdating}
        className={scenarioTabMenuClass}
        dataAttributes={dataAttributes}
        scenarioMenuProps={scenarioMenuProps}
        baseScenarioMenuProps={baseScenarioMenuProps}
        isBase={isBase}
        projectId={projectId}
      />
    );
    if (loading || isUpdating) {
      // using a style here to override blueprint css rule:
      // .bp4-button:empty::before, .bp4-button > :last-child
      rightIcon = (
        <div style={{ marginRight: 'var(--thin-spacing)' }}>
          <Spinner intent={Intent.PRIMARY} size={IconSize.SMALL} />
        </div>
      );
    }

    return (
      <Fragment>
        <Tooltip
          boundary="viewport"
          position={Position.BOTTOM}
          disabled={!overflowed}
          content={scenarioName}>
          <ResizeSensor onResize={this.onResize}>
            <Button
              elementRef={innerRef}
              disabled={loading}
              minimal
              active={active}
              {...dragAndDropProps}
              className={scenarioTabClassName}
              onClick={this.handleScenarioClick}
              rightIcon={rightIcon}>
              <span className={scenarioNameClass}>{scenarioName}</span>
            </Button>
          </ResizeSensor>
        </Tooltip>
        {createProjectModalOpen && (
          <CreateProjectDialog
            show={createProjectModalOpen}
            onClose={this.hideCreateProjectModal}
            sourceProjectId={projectId}
            sourceScenarioId={scenarioId}
          />
        )}
        {scenarioEditModalOpen && (
          <ScenarioEditModal
            name={scenarioName}
            description={description}
            onEditScenario={this.handleEditScenario}
            onExited={this.hideScenarioEditModal}
          />
        )}
        {scenarioDeleteModalOpen && (
          <ConfirmationModal
            legacyShowModal
            primaryButtonStyle="danger"
            submitMessage={<h5>{t`Delete the scenario '${scenarioName}'?`}</h5>}
            submitText="Delete"
            onSubmit={this.handleDeleteScenario}
            onExited={this.hideScenarioDeleteModal}
          />
        )}
      </Fragment>
    );
  }
}

export default connect<StateProps, DispatchProps, OwnProps>(
  makeMapStateToProps,
  mapDispatchToProps,
)(ScenarioTab);

function makeMapStateToProps() {
  const getCloneProjectFromScenarioFlag = makeGetUserFlag(
    'project-clone-from-scenario',
  );
  return (state): StateProps => ({
    nextScenarioOrdinal: getNextScenarioOrdinal(state),
    hasCloneProjectFlag: getCloneProjectFromScenarioFlag(state),
  });
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
  return {
    setActiveScenario: bindActionCreators(
      appActionCreators.setActiveScenario,
      dispatch,
    ),
    projectActions: bindActionCreators(projectActionCreators, dispatch),
    setUIProperty: bindActionCreators(
      exploreActionCreators.setUIProperty,
      dispatch,
    ),
  };
}
