import { AnyAction } from 'redux';
import { Epic, ofType } from 'redux-observable';
import { combineEpics } from 'uf/base/epics';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { t } from 'ttag';
import { addAppMessage } from 'uf/appservices/actions';
import { createBufferedLayerTaskActionTypes } from 'uf/projects/ActionTypes';
import { makeGetProjectLayerIdMap } from 'uf/projects/selectors/virtualLayers';
import { setActiveLayer } from 'uf/explore/actions/layers';

/**
 * An epic to handle buffered layer creation done
 */
export const handleBufferLayerCreationDone: Epic<AnyAction, any> = (
  action$,
  state$,
) => {
  const getProjectLayerIdMap = makeGetProjectLayerIdMap();
  return action$.pipe(
    ofType(createBufferedLayerTaskActionTypes.SUCCESS),
    switchMap(action =>
      state$.pipe(
        map(state => {
          const result = action.result;
          const {
            info: { project_id: projectId },
            result: layerId,
          } = result;
          const layerMap = getProjectLayerIdMap(state, { projectId });
          return layerMap[layerId];
        }),
        filter(virtualLayerId => !!virtualLayerId),
        take(1),
        map(virtualLayerId => {
          const result = action.result;
          const {
            info: { project_id: projectId, layer_name: layerName },
            task_id: taskId,
          } = result;
          const onClickAction = setActiveLayer(projectId, virtualLayerId);
          return addAppMessage(t`Buffered layer ${layerName} created.`, {
            level: 'success',
            status: 'success',
            replacesMessage: taskId,
            action: {
              text: t`VIEW`,
              onClick: () => onClickAction,
            },
          });
        }),
      ),
    ),
  );
};

export default combineEpics(
  {
    handleBufferLayerCreationDone,
  },
  'tasks',
);
