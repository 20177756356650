import {
  CuratedColumnSymbology,
  CUSTOM_THEME,
  DEFAULT_OPACITY,
  InterpolationTypes,
  PaintColorTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/public/dataset/epa_facility_registry_service': {
    interest_type: [
      {
        type: SymbologyTypes.CIRCLE,
        paint: {
          'circle-radius': 3,
          'circle-color': {
            property: 'interest_type',
            type: PaintColorTypes.CATEGORICAL,
            stops: [
              {
                value: 'AIR EMISSIONS CLASSIFICATION UNKNOWN',
                color: '#C0C0C0',
              },
              { value: 'AIR MAJOR', color: '#C0C0C0' },
              { value: 'AIR MINOR', color: '#C0C0C0' },
              { value: 'AIR MONITORING SITE', color: '#C0C0C0' },
              { value: 'AIR - NOT APPLICABLE', color: '#C0C0C0' },
              { value: 'AIR PROGRAM', color: '#C0C0C0' },
              { value: 'AIR PROGRAM - RESERVATION', color: '#C0C0C0' },
              { value: 'AIR SYNTHETIC MINOR', color: '#C0C0C0' },
              { value: 'BIOSOLIDS', color: '#C0C0C0' },
              { value: 'BRAC', color: '#C0C0C0' },
              { value: 'BROWNFIELDS PROPERTY', color: '#8c564b' },
              { value: 'CESQG', color: '#C0C0C0' },
              { value: 'CLOSED CRIMINAL ENFORCEMENT CASE', color: '#C0C0C0' },
              { value: 'COMBINED SEWER OVERFLOW', color: '#C0C0C0' },
              { value: 'COMMUNITY WATER SYSTEM', color: '#C0C0C0' },
              { value: 'COMPLIANCE ACTIVITY', color: '#C0C0C0' },
              { value: 'COMPLIANCE AND EMISSION REPORTING', color: '#C0C0C0' },
              { value: 'COMPLIANCE ASSISTANCE', color: '#C0C0C0' },
              {
                value: 'CONCENTRATED ANIMAL FEEDING OPERATION',
                color: '#C0C0C0',
              },
              { value: 'CONSTRUCTION & DEMOLITION LANDFILL', color: '#C0C0C0' },
              { value: 'CRITERIA AIR POLLUTANT', color: '#C0C0C0' },
              { value: 'ELECTRIC GENERATOR', color: '#C0C0C0' },
              {
                value: 'ELECTRIC POWER GENERATED (BIOMASS BASED)',
                color: '#fad38c',
              },
              {
                value: 'ELECTRIC POWER GENERATED (COAL BASED)',
                color: '#fad38c',
              },
              {
                value: 'ELECTRIC POWER GENERATED (GAS BASED)',
                color: '#fad38c',
              },
              {
                value: 'ELECTRIC POWER GENERATED (OIL BASED)',
                color: '#fad38c',
              },
              {
                value: 'ELECTRIC POWER GENERATED (WATER BASED)',
                color: '#fad38c',
              },
              {
                value: 'ELECTRIC POWER GENERATED (WIND BASED)',
                color: '#fad38c',
              },
              { value: 'ENFORCEMENT/COMPLIANCE ACTIVITY', color: '#C0C0C0' },
              { value: 'ETHANOL FACILITY', color: '#C0C0C0' },
              {
                value: 'FEDERAL FACILITY HAZARDOUS WASTE DOCKET',
                color: '#C0C0C0',
              },
              { value: 'FORMAL ENFORCEMENT ACTION', color: '#C0C0C0' },
              { value: 'FRP', color: '#C0C0C0' },
              { value: 'GASOLINE AND DIESEL PRODUCERS', color: '#C0C0C0' },
              { value: 'GREENHOUSE GAS REPORTER', color: '#C0C0C0' },
              { value: 'HAZARDOUS AIR POLLUTANT MAJOR', color: '#ff7f0e' },
              {
                value: 'HAZARDOUS AND CRITERIA AIR POLLUTANT MAJOR',
                color: '#ff7f0e',
              },
              {
                value: 'HAZARDOUS, SOLID & C&D WASTE LANDFILL',
                color: '#ff7f0e',
              },
              { value: 'HAZARDOUS WASTE BIENNIAL REPORTER', color: '#ff7f0e' },
              { value: 'HAZARDOUS WASTE LANDFILL', color: '#ff7f0e' },
              { value: 'ICIS-NPDES MAJOR', color: '#C0C0C0' },
              { value: 'ICIS-NPDES MINOR', color: '#C0C0C0' },
              { value: 'ICIS-NPDES NON-MAJOR', color: '#C0C0C0' },
              { value: 'ICIS-NPDES UNPERMITTED', color: '#C0C0C0' },
              { value: 'LANDFILL GAS (LFG) RECOVERY', color: '#C0C0C0' },
              {
                value: 'LEAKING UNDERGROUND STORAGE TANK - ARRA',
                color: '#C0C0C0',
              },
              { value: 'LGQ', color: '#C0C0C0' },
              {
                value: 'NON-TRANSIENT NON-COMMUNITY WATER SYSTEM',
                color: '#C0C0C0',
              },
              { value: 'NPDES PERMIT', color: '#C0C0C0' },
              { value: 'NPDES PRETREATMENT PROGRAM', color: '#C0C0C0' },
              { value: 'OTHER HAZARDOUS WASTE', color: '#ff7f0e' },
              { value: 'PESTICIDE PRODUCER', color: '#C0C0C0' },
              { value: 'PHASE II MS4', color: '#C0C0C0' },
              { value: 'PHASE I MS4', color: '#C0C0C0' },
              { value: 'POTW', color: '#C0C0C0' },
              { value: 'RAD NESHAPS', color: '#C0C0C0' },
              { value: 'RAD NPL', color: '#C0C0C0' },
              { value: 'RAD WIPP', color: '#C0C0C0' },
              { value: 'RMP REPORTER', color: '#C0C0C0' },
              { value: 'SOLID & C&D WASTE LANDFILL', color: '#f7b6d2' },
              { value: 'SOLID WASTE LANDFILL', color: '#f7b6d2' },
              { value: 'SPCC', color: '#C0C0C0' },
              { value: 'SQG', color: '#C0C0C0' },
              { value: 'STATE MASTER', color: '#C0C0C0' },
              { value: 'STATE REGULATED TSD', color: '#C0C0C0' },
              { value: 'STORM WATER CONSTRUCTION', color: '#C0C0C0' },
              { value: 'STORM WATER INDUSRIAL', color: '#C0C0C0' },
              { value: 'SUPERFUND (NON-NPL)', color: '#d62728' },
              { value: 'SUPERFUND', color: '#d62728' },
              { value: 'TRANSFER FACILITY', color: '#C0C0C0' },
              {
                value: 'TRANSIENT NON-COMMNITY WATER SYSTEM',
                color: '#C0C0C0',
              },
              { value: 'TRANSPORTER', color: '#C0C0C0' },
              { value: 'TRIBAL MASTER', color: '#C0C0C0' },
              { value: 'TRI REPORTER', color: '#C0C0C0' },
              { value: 'TSCA REPORTER', color: '#C0C0C0' },
              { value: 'TSCA SUBMITTER', color: '#C0C0C0' },
              { value: 'TSD', color: '#C0C0C0' },
              { value: 'UNSPECIFIED UNIVERSE', color: '#C0C0C0' },
              { value: 'WATER TREATMENT PLANT', color: '#17becf' },
            ],
          },
          'circle-opacity': DEFAULT_OPACITY,
        },
        display_hints: {
          scale: SymbologyScales.CATEGORICAL,
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          theme: CUSTOM_THEME,
        },
      },
    ],
  },
};

export default symbologies;
