export interface PromiseWrapper<T> {
  resolve: (value: T) => void;
  reject: (error) => void;
  resolved: boolean;
  promise: Promise<T>;
}

export function makeUnresolvedPromise<T>(): PromiseWrapper<T> {
  const wrapper: PromiseWrapper<T> = {
    resolve: null,
    reject: null,
    resolved: false,
    promise: null,
  };
  const futurePromise = new Promise<T>((resolve, reject) => {
    wrapper.resolve = result => {
      resolve(result);
      wrapper.resolved = true;
    };

    wrapper.reject = error => {
      reject(error);
      wrapper.resolved = true;
    };
  });
  wrapper.promise = futurePromise;
  return wrapper;
}
