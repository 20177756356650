import { BaseQueryFn, SkipToken } from '@reduxjs/toolkit/dist/query';
import {
  BaseEndpointDefinition,
  QueryArgFrom,
} from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { ParametricSelector } from 'reselect';

/**
 * Creates a selector that grabs a REQUIRED property off of the props passed to
 * the selector. Will complain loudly if the property isn't passed, and will
 * throw an exception in tests.
 *
 * IMPORTANT NOTE: These should NOT be shared as inputs to multiple other
 * selectors as it could cause them to clobbers each other's memoization
 *
 * Usage:
 *
 * const getLayerIdFromProps = makeGetFromPropsSelector<string,
 * 'layerId'>('layerId');
 */
export function makeGetFromPropsSelector<T, K extends string>(
  property: K,
): ParametricSelector<any, Record<K, T>, T> {
  return function getFromProps(
    state,
    props: Record<K, T> = {} as Record<K, T>,
  ) {
    if (!(property in props)) {
      const error = `Missing property ${property}.
Did you forget to pass a props argument to a selector? ${JSON.stringify(
        props,
      )}`;
      console.warn(error);
      if (__TESTING__) {
        throw new Error(error);
      }
      return undefined;
    }
    return props[property];
  };
}

/**
 * Creates a selector that grabs a property off the props passed to the
 * selector. Does not require the parameter to be there, nor will it complain if
 * it is missing.
 */
export function makeGetFromPropsOptionalSelector<T, K extends string>(
  property: K,
): ParametricSelector<any, Partial<Record<K, T>>, T> {
  return function getFromProps(state, props: Partial<Record<K, T>> = {}) {
    return props[property];
  };
}

/**
 * Converts non-Getter selectors, eg selector(state, params) into a Getter selector(state)(params)
 */
export function makeGetterSelector<State, Params, Return>(
  selector: ParametricSelector<State, Params, Return>,
) {
  return (s: State) => (p: Params) => selector(s, p);
}

type QueryResultSelectorFactory<QueryArg, ResultType, RootState> = (
  queryArg:
    | QueryArgFrom<BaseEndpointDefinition<QueryArg, BaseQueryFn, ResultType>>
    | SkipToken,
) => (state: RootState) => { data?: ResultType };

/**
 * Convert an RTK endpoint selector, eg Api.endpoints.get_layer.select(params)(state)
 * into a parameterized selector: selector(state, params)
 */
export function unwrapRTKQuerySelector<State, Params, Return>(
  selector: QueryResultSelectorFactory<Params, Return, State>,
): ParametricSelector<State, Params, Return> {
  return (s: State, p: Params) => selector(p)(s).data;
}

/**
 * Convert an RTK endpoint selector, eg Api.endpoints.get_layer.select(params)(state)
 * into a Getter selector(state)(params) which can be used in a createSelector
 */
export function makeRTKGetterSelector<State, Params, Return>(
  selector: QueryResultSelectorFactory<Params, Return, State>,
) {
  return makeGetterSelector(unwrapRTKQuerySelector(selector));
}
