import {
  AnchorButton,
  Popover,
  PopperModifiers,
  Position,
  Tooltip,
} from '@blueprintjs/core';
import {
  mdiChevronDown,
  mdiChevronLeft,
  mdiChevronRight,
  mdiChevronUp,
} from '@mdi/js';
import Icon from '@mdi/react';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { Box } from 'react-layout-components';
import rootStyles from 'uf/styles/root.module.css';
import { tw } from 'uf/tailwindcss-classnames';
import { IconSizeNew } from 'uf/ui/base/icon';
import uniqueId from 'uniqid';
import styles from './CollapseTab.module.css';

const topPositions: Position[] = ['top-left', 'top', 'top-right'];
const bottomPositions: Position[] = ['bottom-left', 'bottom', 'bottom-right'];
const rightPositions: Position[] = ['right-top', 'right', 'right-bottom'];
const leftPositions: Position[] = ['left-top', 'left', 'left-bottom'];

interface OwnProps {
  /**
   * Main className to style the content of the tabbybit
   */
  buttonClassName?: string;
  /**
   * Exposed from Blueprint to get styling just right sometimes.
   */
  popoverClassName?: string;
  /**
   * Exposed from Blueprint to get styling just right sometimes.
   */
  targetClassName?: string;
  contentStyle?: React.CSSProperties;

  /**
   * Where to place the tabby bit
   */
  position?: Position;
  /**
   * Offset of the tabby bit. This is taken from Popover.js' offset parameter
   * than can be a string like '20px' or '20px, 30px' which will work along the
   * major and minor axis, depending of the position. The first value will
   * always move the tab along the target, the second will move it into or away
   * from it.
   */
  offset?: string;
  /**
   * Whether the target component is visible.
   */
  open?: boolean;
  /**
   * Hides the tabbybit.
   */
  hideTabbyBit?: boolean;
  /**
   * Label for the tabbybit.
   */
  label?: React.ReactNode;
  /**
   * Indicates the state of the details pane specifically for this components
   * rendered over the ExplorePanel.
   */
  detailsPaneCollapsed?: boolean;

  disabled?: boolean;
  disabledTooltipText?: string;

  /**
   * The length of the target component. This is necessary if a middle position
   * is used like 'top' or 'bottom' to retain the position of the tabbybit when
   * the target is collapsed.
   */
  targetLength?: string;
  /**
   * Callback fired when the tabbybit is clicked.
   */
  onClick?: () => void;

  /* for testing only */
  hoverOpenDelay?: number;
  transitionDuration?: number;
}

/**
 * Renders a tab that hangs off the target component.
 */
const CollapseTab: React.FC<OwnProps> = props => {
  const {
    children,
    buttonClassName,
    targetClassName,
    popoverClassName,
    contentStyle,
    position = 'right',
    offset,
    open,
    hideTabbyBit,
    label,
    detailsPaneCollapsed = false,
    targetLength,
    disabled,
    disabledTooltipText,
    onClick,
    hoverOpenDelay,
    transitionDuration,
  } = props;

  // Updating the offset prop does not update the underlying props in Popover
  // so we rev the key to force an update.
  const [offsetRerenderKey, setOffsetRerenderKey] = useState(() => uniqueId());
  useEffect(() => {
    setOffsetRerenderKey(uniqueId());
  }, [offset]);

  let modifiers: PopperModifiers;
  if (offset) {
    modifiers = { offset: { offset } };
  }

  const popoverClass = getPopoverClass(position);
  // to accomodate the logic to show the appropriate chevronIcon a visible tab for
  // the explore panel, iconStatusFlag is made to depend on the value passed to open.
  // defaults to passing open similar to the original behavior
  const iconStatusFlag = detailsPaneCollapsed ? !open : open;
  const iconPath = getArrowIconPath(position, iconStatusFlag);
  const labelClass = getLabelClass(position);

  const containerClass = isVerticalTab(position)
    ? rootStyles.veryThinPaddingVertical
    : rootStyles.veryThinPaddingHorizontal;

  const placeholderStyle = isVerticalTab(position)
    ? { height: targetLength }
    : { width: targetLength };

  return (
    <Popover
      portalClassName={classNames(styles.popover, tw('tw-z-10'))}
      key={offsetRerenderKey}
      enforceFocus={false}
      modifiers={modifiers}
      popoverClassName={classNames(popoverClassName, popoverClass)}
      className={classNames(targetClassName, styles.popoverTarget)}
      targetClassName={styles.popoverTaget}
      minimal
      isOpen={!hideTabbyBit}
      transitionDuration={0}
      position={position}>
      {/* target */}
      {open ? (
        children
      ) : (
        <div className="placeholder" style={placeholderStyle} />
      )}

      {/* content */}
      <Tooltip
        openOnTargetFocus={false}
        disabled={!disabled}
        hoverOpenDelay={hoverOpenDelay}
        transitionDuration={transitionDuration}
        position={getSide(position)}
        content={disabled ? disabledTooltipText : undefined}>
        {/* Using AnchorButton to get the tooltip to work when the button is disabled */}
        <AnchorButton
          disabled={disabled}
          small
          outlined
          className={buttonClassName}
          style={contentStyle}
          onClick={onClick}>
          <Box
            center
            column={isVerticalTab(position)}
            className={containerClass}>
            {<Icon path={iconPath} size={IconSizeNew.SMALL} />}
            <div className={labelClass}>{label}</div>
          </Box>
        </AnchorButton>
      </Tooltip>
    </Popover>
  );
};

export default CollapseTab;

function getSide(position: Position): Position {
  if (topPositions.includes(position)) {
    return 'top';
  }

  if (bottomPositions.includes(position)) {
    return 'bottom';
  }

  if (leftPositions.includes(position)) {
    return 'left';
  }

  if (rightPositions.includes(position)) {
    return 'right';
  }
}

function getPopoverClass(position: Position): string {
  const side = getSide(position);

  return classNames(styles.popover, styles[side]);
}

function getArrowIconPath(position: Position, open: boolean) {
  if (topPositions.includes(position)) {
    return open ? mdiChevronDown : mdiChevronUp;
  }

  if (bottomPositions.includes(position)) {
    return open ? mdiChevronUp : mdiChevronDown;
  }

  if (leftPositions.includes(position)) {
    return open ? mdiChevronRight : mdiChevronLeft;
  }

  if (rightPositions.includes(position)) {
    return open ? mdiChevronLeft : mdiChevronRight;
  }
}

function getLabelClass(position: Position) {
  if (topPositions.includes(position) || bottomPositions.includes(position)) {
    return tw('tw-p-1');
  }

  if (rightPositions.includes(position)) {
    return classNames(styles.rightTab, tw('tw-p-1'));
  }

  if (leftPositions.includes(position)) {
    return classNames(styles.leftTab, tw('tw-p-1'));
  }
}

function isVerticalTab(position: Position) {
  return rightPositions.includes(position) || leftPositions.includes(position);
}
