import { combineReducers } from 'redux';

import { enqueueMapExportActionTypes } from 'uf-api/api/mapexport.service';
import {
  MapExportControlSettings,
  MapExportDisplaySettings,
  MapExportOutputSettings,
} from 'uf-api/model/models';
import { DimensionType } from 'uf/base/map';
import {
  getDimensions,
  getPhysicalDimensions,
  Scale,
  UF_LOGO_KEY,
} from 'uf/mapexport';
import { TaskMessage } from 'uf/tasks/MessageTypes';
import { makeCombinedLegacyTaskReducer } from 'uf/tasks/reducerHelpers';

import {
  BIND_MAPEXPORT_TASK,
  CLEAR_MAP_EXPORT_LEGEND_LAYERS,
  MapExportActions,
  mapExportTaskActionTypes,
  RESET_MAP_EXPORT,
  SET_EXPORT_FORMAT,
  SET_INCLUDE_BASEMAP,
  SET_LEGEND_CLASSES_TO_INCLUDE,
  SET_LEGEND_PLACEMENT,
  SET_MAP_COMPASS_VISIBLE,
  SET_MAP_DIMENSION_TYPE,
  SET_MAP_DIMENSIONS,
  SET_MAP_EXPORT_BOOKMARK,
  SET_MAP_EXPORT_BOUNDS,
  SET_MAP_EXPORT_LEGEND_LAYER,
  SET_MAP_EXPORT_LOGO,
  SET_MAP_EXPORT_LOGO_VISIBLE,
  SET_MAP_EXPORT_NUM_GEOMETRIES,
  SET_MAP_EXPORT_REQUESTED_BOUNDS,
  SET_MAP_PHYSICAL_DIMENSIONS,
  SET_MAP_REQUESTED_SCALE,
  SET_MAP_RESOLUTION,
  SET_MAP_SCALE,
  SET_MAP_SCALE_RATIO,
  SET_MAP_SCALEBAR_VISIBLE,
  SET_MAP_TITLE,
  SET_MAP_TITLE_VISIBLE,
  SET_ORIENTATION,
  UPDATE_MAP_EXPORT_VIEWPORT,
} from './ActionTypes';
import { MapExportState, MapExportUIState } from './state';

const DEFAULT_RESOLUTION = 144;
const DEFAULT_WIDTH_INCHES = 8.5;
const DEFAULT_HEIGHT_INCHES = 11;

const initialState: MapExportUIState = Object.freeze({
  exportFormat: MapExportOutputSettings.FormatEnum.Png,
  // TODO: remove orientation alltogether.  At one point we were going to actually rotate the
  // exported image for landscape but we decided that was dumb and it was better to jsut output
  // a wider image.
  pageOrientation: MapExportDisplaySettings.OrientationEnum.Landscape,
  legendPlacement: MapExportControlSettings.LegendTypeEnum.OverlayUpperRight,
  legendClassesToInclude:
    MapExportControlSettings.LegendClassesToIncludeEnum.VisibleOnly,
  excludedLegendLayers: [],

  includeBaseMap: true,
  scale: Scale.OneTo1000,
  scaleRatio: 1,
  requestedScale: null,
  bookmarkKey: {},
  resolution: DEFAULT_RESOLUTION,
  numGeometries: 0,
  // These can be changed by the user in the export map,
  // but should default to the explore map values.
  map: null,
  bearing: 0,
  bounds: null,
  requestedBounds: null,
  zoom: 10,
  center: [0, 0] as [number, number],
  width: 0,
  height: 0,
  pitch: 0,
  dimensionType: DimensionType.Print,
  dimensions: {
    width: DEFAULT_WIDTH_INCHES * DEFAULT_RESOLUTION,
    height: DEFAULT_HEIGHT_INCHES * DEFAULT_RESOLUTION,
  },
  physicalDimensions: {
    width: DEFAULT_WIDTH_INCHES,
    height: DEFAULT_HEIGHT_INCHES,
  },
  title: {
    text: '',
  },
  scaleBarVisible: true,
  compassVisible: true,
  titleVisible: true,
  logoType: MapExportControlSettings.LogoTypeEnum.UfLogo,
  compassType: MapExportControlSettings.CompassTypeEnum.Default,
  scaleType: MapExportControlSettings.ScaleTypeEnum.Default,
  scaleUnits: MapExportControlSettings.ScaleUnitsEnum.Us,
  logoKey: UF_LOGO_KEY,
  logoVisible: true,
});

function uiState(
  state: MapExportUIState = initialState,
  action: MapExportActions,
): MapExportUIState {
  switch (action.type) {
    /*
     * Content Settings
     */
    case SET_INCLUDE_BASEMAP:
      return {
        ...state,
        includeBaseMap: action.includeBaseMap,
      };

    case SET_MAP_EXPORT_NUM_GEOMETRIES: {
      const { value } = action;
      return {
        ...state,
        numGeometries: value,
      };
    }

    /*
     * Control Settings
     */
    case SET_LEGEND_PLACEMENT:
      return {
        ...state,
        legendPlacement: action.legendPlacement,
      };

    case SET_LEGEND_CLASSES_TO_INCLUDE:
      return {
        ...state,
        legendClassesToInclude: action.legendClassesToInclude,
      };

    case SET_ORIENTATION:
      return {
        ...state,
        pageOrientation: action.pageOrientation,
      };

    case SET_MAP_EXPORT_LEGEND_LAYER: {
      const { excludedLegendLayers } = state;
      const { include, layerId } = action;
      const isLayerExcluded = excludedLegendLayers.includes(layerId);

      let newExcludedLegendLayers = excludedLegendLayers;
      if (include && isLayerExcluded) {
        // include a previously excluded layer
        newExcludedLegendLayers = excludedLegendLayers.filter(
          excludedLayerId => excludedLayerId !== layerId,
        );
      } else if (!include && !isLayerExcluded) {
        // exclude a previously included layer
        newExcludedLegendLayers = [...excludedLegendLayers, layerId];
      }

      return {
        ...state,
        excludedLegendLayers: newExcludedLegendLayers,
      };
    }

    case CLEAR_MAP_EXPORT_LEGEND_LAYERS:
      return {
        ...state,
        excludedLegendLayers: [],
      };

    /*
     * Display Settings
     */
    case SET_MAP_EXPORT_BOOKMARK:
      return {
        ...state,
        bookmarkKey: {
          ...state.bookmarkKey,
          [action.projectId]: action.key,
        },
      };

    case UPDATE_MAP_EXPORT_VIEWPORT:
      return {
        ...state,
        ...action.viewport,
      };

    /*
     * Output Settings
     */
    case SET_EXPORT_FORMAT:
      return {
        ...state,
        exportFormat: action.exportFormat,
      };

    case SET_MAP_SCALE:
      return {
        ...state,
        scale: action.scale,
      };

    case SET_MAP_SCALE_RATIO:
      return {
        ...state,
        scaleRatio: action.scaleRatio,
      };

    case SET_MAP_REQUESTED_SCALE:
      return {
        ...state,
        requestedScale: action.requestedScale,
      };

    case SET_MAP_RESOLUTION: {
      const { dimensionType } = state;
      if (dimensionType === DimensionType.Print) {
        const dimensions = getDimensions(
          state.physicalDimensions,
          action.resolution,
        );
        return {
          ...state,
          resolution: action.resolution,
          dimensions,
        };
      }

      const physicalDimensions = getPhysicalDimensions(
        state.dimensions,
        action.resolution,
      );
      return {
        ...state,
        resolution: action.resolution,
        physicalDimensions,
      };
    }

    case SET_MAP_EXPORT_BOUNDS: {
      return {
        ...state,
        bounds: action.bounds,
      };
    }

    case SET_MAP_EXPORT_REQUESTED_BOUNDS: {
      return {
        ...state,
        requestedBounds: action.requestedBounds,
      };
    }

    case SET_MAP_DIMENSION_TYPE: {
      return {
        ...state,
        dimensionType: action.dimensionType,
      };
    }

    case SET_MAP_DIMENSIONS: {
      return {
        ...state,
        dimensions: action.dimensions,
        physicalDimensions: getPhysicalDimensions(
          action.dimensions,
          state.resolution,
        ),
      };
    }

    case SET_MAP_PHYSICAL_DIMENSIONS: {
      return {
        ...state,
        physicalDimensions: action.dimensions,
        dimensions: getDimensions(action.dimensions, state.resolution),
      };
    }

    case SET_MAP_SCALEBAR_VISIBLE: {
      return {
        ...state,
        scaleBarVisible: action.scaleBarVisible,
      };
    }

    case SET_MAP_COMPASS_VISIBLE: {
      return {
        ...state,
        compassVisible: action.compassVisible,
      };
    }

    case SET_MAP_TITLE: {
      return {
        ...state,
        title: action.title,
      };
    }
    case SET_MAP_TITLE_VISIBLE: {
      return {
        ...state,
        titleVisible: action.titleVisible,
      };
    }

    case SET_MAP_EXPORT_LOGO:
      return {
        ...state,
        logoKey: action.logoKey,
      };

    case SET_MAP_EXPORT_LOGO_VISIBLE:
      return {
        ...state,
        logoVisible: action.visible,
      };

    case RESET_MAP_EXPORT:
      return initialState;

    default:
      return state;
  }
}

export default combineReducers<MapExportState>({
  requests: makeCombinedLegacyTaskReducer<string, TaskMessage>(
    enqueueMapExportActionTypes,
    BIND_MAPEXPORT_TASK,
    mapExportTaskActionTypes,
  ),
  uiState,
});
