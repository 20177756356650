import { createSelector } from 'reselect';

import { EMPTY_ARRAY, EMPTY_OBJECT } from 'uf/base';
import { LegacyVirtualLayerId } from 'uf/projects/virtualLayers';
import { makeGetViewsLayersState } from 'uf/views/selectors';

export function makeGetViewLayerOrder() {
  return createSelector(
    makeGetViewsLayersState(),
    ({ layerOrder }): LegacyVirtualLayerId[] => layerOrder || EMPTY_ARRAY,
  );
}

export function makeGetViewVisibleVirtualLayers() {
  return createSelector(
    makeGetViewsLayersState(),
    ({ visibleLayerIds }): LegacyVirtualLayerId[] => {
      return visibleLayerIds || EMPTY_ARRAY;
    },
  );
}

export function makeGetViewMapColumnKeys() {
  return createSelector(
    makeGetViewsLayersState(),
    ({ activeColumns }): Record<LegacyVirtualLayerId, string> =>
      activeColumns ?? EMPTY_OBJECT,
  );
}
