import { Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { t } from 'ttag';
import warning from 'warning';
import { ScopeTypes } from 'uf-ws/WebsocketActions';
import { getActiveProjectId } from 'uf/app/selectors';
import { addAppMessage } from 'uf/appservices/actions';
import { Message } from 'uf/appservices/messages';
import { Api } from 'uf-api-rtk/store/Api';
import { typeAssertNever } from 'uf/base/never';
import {
  clearBuilding,
  clearBuildingType,
  clearPlaceType,
} from 'uf/builtforms/actions/data';
import { updateLayerVersion } from 'uf/layers/actions/versions';
import {
  LAS_RUN_CANCELLED,
  LAS_RUN_COMPLETED,
  LAS_RUN_FAILED,
} from 'uf/location-analysis/logging';
import { logEvent } from 'uf/logging';
import {
  updateProjectPaintingStatus,
  updateScenarioPaintingStatus,
} from 'uf/painting/actions/status';
import UpdateStatusTypes from 'uf/projects/StatusTypes';
import { loadProject, updateProjectStatus } from 'uf/projects/actions';
import { createBufferedLayer } from 'uf/projects/websocketMessages';
import { updateScenarioStatus } from 'uf/scenarios/actions';
import { UFState } from 'uf/state';
import { NotificationAction } from 'uf/tasks/ActionTypes';
import { TaskMessage } from 'uf/tasks/MessageTypes';
import { NotificationTypes } from 'uf/tasks/NotificationTypes';
import { TaskStatuses } from 'uf/tasks/TaskStatuses';
import { TaskTypes } from 'uf/tasks/TaskTypes';
import { getSessionId, getUsername } from 'uf/user/selectors/user';
import { makeWebsocketActionType } from 'uf/websockets/actionHelpers';
import { setActiveLayer } from 'uf/explore/actions/layers';
import { setActiveProject } from 'uf/app/actions';

// TODO: need to break up message routing into tasks and notifications.  currently
// shouldHandleTaskNotifications will let through either as long as the scope is fine.
/**
 * Use to decide if a server notification should be handled. This
 * basically makes sure that if stray/stale messages come in from
 * websockets, they don't confuse the app.
 */
export function shouldHandleTaskNotification(
  message: TaskMessage,
  state: UFState,
) {
  if (!(message.type in NotificationTypes)) {
    return false;
  }

  switch (message.scope_type) {
    case ScopeTypes.SESSION:
      return getSessionId(state) === message.scope;

    case ScopeTypes.USER:
      return getUsername(state) === message.scope;

    case ScopeTypes.PROJECT:
      return getActiveProjectId(state) === message.scope;

    default:
      warning(
        false,
        t`Unknown message scope: ${message.scope_type} for message ${message.type}`,
      );
  }
  return false;
}

/**
 * This is the primary entrypoint for converting websocket notifications into redux actions.
 * At this point we know the message is valid and intended for us, we
 * just need to do something about it.
 *
 * TODO: Move this stuff into separate epics depending on the message.type.
 */
export function dispatchNotification(
  message: TaskMessage,
): ThunkAction<any, any, never, any> {
  return (dispatch: Dispatch, getState) => {
    const notificationAction: NotificationAction = {
      type: makeWebsocketActionType(message.type),
      result: message,
      // new system uses task_id, fallback to old uf_task_id if necessary
      key: message.task_id || message.uf_task_id,
    };

    dispatch(notificationAction);
    // For now this is a giant switch(), but eventually all these
    // cases should be handled by epics using the above message
    switch (message.type) {
      case NotificationTypes.PONG:
        dispatch(addAppMessage(message.text, { level: 'success' }));
        break;

      case NotificationTypes.PROJECT_UPDATED: {
        warning(
          message.scope_type === ScopeTypes.PROJECT,
          t`Project updated with unexpected scope_type ${message.scope_type}`,
        );
        const projectId = message.scope;
        dispatch(loadProject(projectId));
        break;
      }

      case NotificationTypes.PROJECT_LAYER_UPDATED: {
        warning(
          message.scope_type === ScopeTypes.PROJECT,
          t`Project updated with unexpected scope_type ${message.scope_type}`,
        );
        const { layer: layerId, version: layerVersion } = message;
        dispatch(updateLayerVersion(layerId, layerVersion));
        break;
      }

      case createBufferedLayer.LAYER_BUFFER_STARTED: {
        dispatch(
          addAppMessage(t`Creating buffered layer...`, {
            status: 'running',
            replacesMessage: message.task_id,
            timeout: 0,
          }),
        );
        break;
      }

      case createBufferedLayer.LAYER_BUFFER_ERROR: {
        dispatch(
          addAppMessage(t`There was an error creating the buffered layer.`, {
            level: 'danger',
          }),
        );
        break;
      }

      case NotificationTypes.SCENARIO_UPDATE_BEGIN: {
        dispatch(
          updateScenarioStatus(message.scenario_key, {
            status: UpdateStatusTypes.IN_PROGRESS,
            info: message.update,
          }),
        );
        break;
      }

      case NotificationTypes.SCENARIO_UPDATE_SUCCEEDED: {
        dispatch(
          updateScenarioStatus(message.scenario_key, {
            status: UpdateStatusTypes.SUCCEEDED,
            info: message.update,
          }),
        );
        break;
      }

      case NotificationTypes.SCENARIO_UPDATE_FAILED: {
        dispatch(
          addAppMessage(t`The scenario update failed.`, {
            level: 'danger',
          }),
        );
        dispatch(
          updateScenarioStatus(message.scenario_key, {
            status: UpdateStatusTypes.FAILED,
            info: message.update,
          }),
        );
        break;
      }

      case NotificationTypes.SCENARIO_UPDATE_CANCELLED: {
        dispatch(
          addAppMessage(t`The scenario update was cancelled.`, {
            level: 'danger',
          }),
        );
        dispatch(
          updateScenarioStatus(message.scenario_key, {
            status: UpdateStatusTypes.CANCELLED,
            info: message.update,
          }),
        );
        break;
      }

      case NotificationTypes.PROJECT_UPDATE_BEGIN: {
        warning(
          message.scope_type === ScopeTypes.PROJECT,
          t`Project updated with unexpected scope_type ${message.scope_type}`,
        );
        const projectId = message.scope;
        dispatch(
          updateProjectStatus(projectId, {
            status: UpdateStatusTypes.IN_PROGRESS,
            info: message.update,
          }),
        );
        break;
      }

      case NotificationTypes.PROJECT_UPDATE_SUCCEEDED: {
        warning(
          message.scope_type === ScopeTypes.PROJECT,
          t`Project updated with unexpected scope_type ${message.scope_type}`,
        );
        const projectId = message.scope;
        dispatch(
          updateProjectStatus(projectId, {
            status: UpdateStatusTypes.SUCCEEDED,
            info: message.update,
          }),
        );
        dispatch(loadProject(projectId));
        break;
      }

      case NotificationTypes.PROJECT_UPDATE_CANCELLED: {
        warning(
          message.scope_type === ScopeTypes.PROJECT,
          t`Project updated with unexpected scope_type ${message.scope_type}`,
        );
        const projectId = message.scope;
        dispatch(
          updateProjectStatus(projectId, {
            status: 'CANCELLED',
            info: message.update,
          }),
        );
        break;
      }

      case NotificationTypes.PAINTING_LOCKED: {
        dispatch(updateScenarioPaintingStatus(message.scenario_key, message));
        break;
      }

      case NotificationTypes.PAINTING_UNLOCKED: {
        dispatch(updateScenarioPaintingStatus(message.scenario_key, message));
        break;
      }

      case NotificationTypes.ALL_PAINTINGS_LOCKED: {
        warning(
          message.scope_type === ScopeTypes.PROJECT,
          t`All painting locked with unexpected scope_type ${message.scope_type}`,
        );
        const projectId = message.scope;

        dispatch(updateProjectPaintingStatus(projectId, message));
        break;
      }

      case NotificationTypes.ALL_PAINTINGS_UNLOCKED: {
        warning(
          message.scope_type === ScopeTypes.PROJECT,
          t`All painting unlocked with unexpected scope_type ${message.scope_type}`,
        );
        const projectId = message.scope;

        dispatch(updateProjectPaintingStatus(projectId, message));
        break;
      }

      // handled via epic
      case NotificationTypes.LAYER_EXPORT_STARTED:
      case NotificationTypes.LAYER_EXPORT_DONE:
      case NotificationTypes.LAYER_EXPORT_ERROR:
      case NotificationTypes.COLUMN_EXPORT_STARTED:
      case NotificationTypes.COLUMN_EXPORT_DONE:
      case NotificationTypes.COLUMN_EXPORT_ERROR:
        break;

      // handled via epic
      case NotificationTypes.LAYER_IMPORT_STARTED:
      case NotificationTypes.LAYER_IMPORT_DONE:
      case NotificationTypes.LAYER_IMPORT_ERROR:
        break;

      // handled via epic
      case NotificationTypes.MAPEXPORT_STARTED:
      case NotificationTypes.MAPEXPORT_DONE:
      case NotificationTypes.MAPEXPORT_ERROR:
        break;

      // handled via epic
      case NotificationTypes.SCENARIO_DELETED:
        break;

      // handled via epic
      case createBufferedLayer.LAYER_BUFFER_DONE:
        break;

      case NotificationTypes.BUILTFORMS_UPDATED: {
        const {
          library_path: libraryId,
          buildings = [],
          building_types: buildingTypes = [],
          place_types: placeTypes = [],
        } = message;

        buildings.forEach(key => {
          dispatch(clearBuilding(libraryId, key));
        });

        buildingTypes.forEach(key => {
          dispatch(clearBuildingType(libraryId, key));
        });

        placeTypes.forEach(key => {
          dispatch(clearPlaceType(libraryId, key));
        });
        break;
      }

      case NotificationTypes.LAYER_POINT_ON_SURFACE_STARTED: {
        const { task_id: taskId, info } = message;
        if (info.parent.includes('poi_parcel')) {
          dispatch(
            addAppMessage('Finding brands and businesses...', {
              status: 'running',
              replacesMessage: taskId,
              timeout: 0,
            }),
          );
        }
        break;
      }

      case NotificationTypes.LAYER_POINT_ON_SURFACE_DONE: {
        const { task_id: taskId, status, info, result } = message;
        const { layer_name: layerName, project_id: projectId, parent } = info;
        const virtualLayerId = `working:${result}`;
        if (status === TaskStatuses.DONE && parent.includes('poi_parcel')) {
          dispatch(
            addAppMessage(`"${layerName}" is now available.`, {
              level: 'success',
              status: 'success',
              replacesMessage: taskId,
              action: {
                level: 'info',
                text: 'View',
                onClick: () => {
                  dispatch(setActiveLayer(projectId, virtualLayerId, true));
                  return { type: 'noop' };
                },
              },
            }),
          );
        }
        break;
      }
      case NotificationTypes.LAYER_POINT_ON_SURFACE_ERROR: {
        const { task_id: taskId, info, problem } = message;
        const { layer_name: layerName, parent } = info;
        if (parent.includes('poi_parcel')) {
          dispatch(
            addAppMessage(
              problem?.type_code === 'no_data_for_query'
                ? `No matches found for "${layerName}" in the project area.`
                : `There was an error generating ${layerName}.`,
              {
                level: 'danger',
                status: 'failure',
                replacesMessage: taskId,
              },
            ),
          );
        }
        break;
      }

      case NotificationTypes.TASK_NOTIFICATION: {
        switch (message.task_type) {
          case TaskTypes.TASK_TYPE_PROJECT_LAYER_ANALYSIS:
          case TaskTypes.TASK_TYPE_PROJECT_LAYER_ANALYSIS_RESULT: {
            const { task_id: taskId, status, info, result } = message;
            const {
              layer_name: layerName,
              insight_name: insightName,
              insight_has_dashboard: insightHasDashboard,
              detailed_message: detailedMessage = '',
            } = info;

            const defaultMessage = `${insightName} for ${layerName} in progress. This may take a few hours`;
            const dashboardMessage = insightHasDashboard
              ? " - you will receive an email when it's done."
              : '.';

            if (status === 'running' || status === 'enqueued') {
              dispatch(
                addAppMessage(`${defaultMessage}${dashboardMessage}`, {
                  status: status as Message.MessageStatus,
                  replacesMessage: taskId,
                  timeout: 0,
                  action: {
                    level: 'danger',
                    text: t`CANCEL`,
                    onClick: () => {
                      // This is a special case to manage cache invalidation. Do not repeat this pattern.
                      void dispatch(
                        Api.endpoints.setProjectLayerAnalysisRunStatus.initiate(
                          {
                            key: taskId,
                            status: 'cancelled',
                          },
                        ),
                      );
                      logEvent(LAS_RUN_CANCELLED, info);
                      // This allows us to play nicely with legacy epic
                      return { type: LAS_RUN_CANCELLED };
                    },
                  },
                }),
              );
            }
            if (status === 'error') {
              dispatch(
                addAppMessage(
                  t`There was an error generating ${insightName} for ${layerName}. ${detailedMessage}`,
                  {
                    replacesMessage: taskId,
                    level: 'danger',
                    status: 'failure',
                  },
                ),
              );
              logEvent(LAS_RUN_FAILED, info);
            }
            if (status === 'done') {
              const projectId = result?.project?.full_path;

              result?.layers?.forEach(layer => {
                const { details, full_path: layerId } = layer;
                // Prefix layerId to play well with virtualLayerIds
                // TODO: Validate that this will always be the prefix
                const virtualLayerId = `working:${layerId}`;
                // This is a special case to manage cache invalidation. Do not repeat this pattern.
                void dispatch(
                  Api.endpoints.setProjectLayerAnalysisRunStatus.initiate({
                    key: taskId,
                    status: 'complete',
                  }),
                );
                logEvent(LAS_RUN_COMPLETED, info);
                dispatch(
                  addAppMessage(
                    t`"${details.name}" is now available. ${detailedMessage}`,
                    {
                      level: 'success',
                      status: 'success',
                      replacesMessage: taskId,
                      action: {
                        level: 'info',
                        text: t`View`,
                        onClick: () => {
                          // Switch to layer's project if not currently active
                          if (getState().app.activeProjectId !== projectId) {
                            dispatch(
                              setActiveProject(projectId, {
                                forceProjectReload: true,
                              }),
                            );
                            // Otherwise, switch to the active layer
                          } else {
                            dispatch(
                              setActiveLayer(projectId, virtualLayerId, true),
                            );
                          }
                          return { type: 'noop' };
                        },
                      },
                    },
                  ),
                );
              });
            }
            break;
          }

          case TaskTypes.TASK_TYPE_PROJECT_LAYER_ANALYSIS_DASHBOARD: {
            const { task_id: taskId, status, result } = message;
            if (status === 'done') {
              const {
                dashboard_name: dashboardName,
                dashboard_url: dashboardURL,
              } = result;
              dispatch(
                addAppMessage(t`Dashboard ${dashboardName} is now available.`, {
                  level: 'success',
                  status: 'success',
                  replacesMessage: taskId,

                  action: {
                    text: t`VIEW`,
                    href: dashboardURL,
                    target: '_blank',
                  },
                }),
              );
            }
            break;
          }

          case TaskTypes.TASK_TYPE_PROJECT_ADD_NEW_CLIPPED_LAYER:
          case TaskTypes.TASK_TYPE_PROJECT_CREATE_CONTEXT_CANVAS_LAYER:
          case TaskTypes.TASK_TYPE_PROJECT_CREATE_BASE_SCENARIO:
          case TaskTypes.TASK_TYPE_PROJECT_COPY_AND_BAKE_BUILT_FORMS: {
            if (message.status === TaskStatuses.DONE) {
              const projectId = message.scope;
              dispatch(loadProject(projectId));
            }
            break;
          }

          // handled by epics
          case TaskTypes.TASK_TYPE_PROJECT_SCENARIO_CLONE:
          case TaskTypes.TASK_TYPE_PROJECT_CREATE_BUFFERED_LAYER:
          case TaskTypes.TASK_TYPE_SCENARIO_MODULE_ANALYSIS:
          case TaskTypes.TASK_TYPE_PROJECT_IMPORT_LAYER:
          case TaskTypes.TASK_TYPE_PROJECT_PREPARE_MODULE_PARAMS:
          case TaskTypes.TASK_TYPE_EXPORT_LAYER:
          case TaskTypes.TASK_TYPE_PROJECT_EXPORT_LAYER:
          case TaskTypes.TASK_TYPE_TRANSIT_CHANGESET_APPLY:
          case TaskTypes.TASK_TYPE_SCENARIO_PAINT:
          case TaskTypes.TASK_TYPE_BASE_SCENARIO_PAINT:
          case TaskTypes.TASK_TYPE_PROJECT_CREATE_POINT_ON_SURFACE_LAYER:
          case TaskTypes.TASK_TYPE_EXPORT_COLUMNS:
            break;

          // No need to change any app state since this just adds a new
          // project specific reference layer to the layer manager.
          case TaskTypes.TASK_TYPE_PROJECT_CREATE_PARCEL_ATTRIBUTES_LAYER:
            break;

          default:
            typeAssertNever(message.task_type);
            if (__DEVELOPMENT__) {
              dispatch(
                addAppMessage(
                  t`Unknown message task type from server: ${message.task_type}`,
                  { level: 'danger' },
                ),
              );
            }
            warning(
              false,
              t`Unknown message task type from server: ${message.task_type}`,
            );
        }
        break;
      }

      default:
        if (__DEVELOPMENT__ || __TESTING__) {
          dispatch(
            addAppMessage(t`Unknown message from server: ${message.type}`, {
              level: 'danger',
            }),
          );
        }
    }
  };
}
