import classNames from 'classnames';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { t } from 'ttag';

import { LayerMetadata } from 'uf-api';
import { useGetLayerGeometryTypeQuery } from 'uf-api-rtk/store/Api';
import {
  getActiveProjectId,
  getBaseScenarioId,
  makeGetActiveScenarioIdForProject,
} from 'uf/app/selectors';
import * as exploreActionCreators from 'uf/explore/actions';
import * as exploreLayerActionCreators from 'uf/explore/actions/layers';
import { TabKey } from 'uf/explore/details';
import { makeGetLayerActiveDetailTabKey } from 'uf/explore/selectors/explore';
import {
  getActiveLayerId,
  makeGetLayerActiveColumnKey,
} from 'uf/explore/selectors/layers';
import { makeGetActiveViewId } from 'uf/explore/selectors/views';
import { ColumnKey, LayerId } from 'uf/layers';
import { makeGetLayerMetadata } from 'uf/layers/selectors/metadata';
import { LayerRole } from 'uf/layers/state';
import { ProjectId } from 'uf/projects';
import { makeGetLayerRoleForProject } from 'uf/projects/selectors/layerRole';
import { makeGetProjectLayerIdMap } from 'uf/projects/selectors/virtualLayers';
import { LegacyVirtualLayerId } from 'uf/projects/virtualLayers';
import { ScenarioId } from 'uf/scenarios';
import rootStyles from 'uf/styles/root.module.css';
import tabStyles from 'uf/styles/tabs.module.css';
import {
  useEnsurePermissions,
  usePermissions,
} from 'uf/ui/base/CanPerform/CanPerform';
import ErrorBoundary from 'uf/ui/base/ErrorBoundary/ErrorBoundary';
import Pane from 'uf/ui/base/Pane/Pane';
import usePrevious from 'uf/ui/base/usePrevious/usePrevious';
import { useLayerName } from 'uf/ui/explore/hooks';
import { LayerIconPathByRole } from 'uf/ui/layers/icons';
import {
  getUserActiveOrganizationFlags,
  getUserFlags,
} from 'uf/user/selectors/flags';
import { ViewId } from 'uf/views';

import { UFState } from 'uf/state';
import ExploreDetailsBody from './ExploreDetailsBody';
import ExploreDetailsHeader from './ExploreDetailsHeader';
import { DetailsPaneTabs, TabInfoProps } from './tabs';
import useAvailableInsights from 'uf/ui/location-analysis/hooks';

const TAB_PANE_PADDING_WIDTH = 16;

interface StateProps {
  projectId: ProjectId;
  viewId: ViewId;
  scenarioId: ScenarioId;
  layerId: LayerId;
  virtualLayerId: LegacyVirtualLayerId;
  columnKey: ColumnKey;

  activeTabKey: TabKey;

  layer: LayerMetadata;
  layerRole: LayerRole;
  isBaseScenario: boolean;

  /** All feature flags currently set, used for flag-based panel display */
  featureFlags: string[];
}

interface DispatchProps {
  onSetTab: (projectId: ProjectId, layerId: LayerId, tabKey: string) => void;
  clearActiveLayer: (projectId: ProjectId) => void;
}

interface OwnProps {
  width?: number;
  /** A way to force the subpane to show. used mainly for tests */
  showSubComponent?: boolean;
}

type Props = StateProps & DispatchProps & OwnProps;

const exploreDetailsPaneClassName = classNames(
  'DetailsPane',
  rootStyles.lightBackground,
  rootStyles.flex,
  rootStyles.defaultBorderLeft,
  tabStyles.tabs,
  tabStyles.left,
  tabStyles.scroll,
  tabStyles.flex,
);
export const ExploreDetailsPane: FunctionComponent<Props> = props => {
  const {
    projectId,
    layerId,
    columnKey,
    layer,
    layerRole,
    width,
    activeTabKey,
    featureFlags,
    isBaseScenario,
    clearActiveLayer,
    onSetTab,
    showSubComponent,
  } = props;
  const { namespace, layer_type: layerType, key } = layer;
  const { data: layerGeometry } = useGetLayerGeometryTypeQuery(
    {
      namespace,
      layerType,
      key,
    },
    { skip: !layer },
  );
  const { shouldShowSubComponent, onShowSubComponent, onHideSubComponent } =
    useSubComponent(showSubComponent);

  const layerName = useLayerName(layerId);

  const onClose = useCallback(
    () => clearActiveLayer(projectId),
    [clearActiveLayer, projectId],
  );

  const onSetActiveTabKey = useCallback(
    (tabKey: TabKey) => onSetTab(projectId, layerId, tabKey),
    [projectId, layerId, onSetTab],
  );

  const verb = 'scenario.update';
  const resources = [projectId];
  useEnsurePermissions(verb, resources, true);
  const { allowedInsightKeys } = useAvailableInsights(
    key,
    namespace,
    layerType,
    layerGeometry,
  );

  const { permissions } = usePermissions(resources, verb);
  const canScenarioUpdate = permissions[0] ?? false;

  const visibleTabInfos = useMemo(
    () =>
      getVisibleTabs(
        layerRole,
        layer,
        canScenarioUpdate,
        isBaseScenario,
        featureFlags,
        layerGeometry?.simple_geometry_type,
        allowedInsightKeys,
      ),
    [
      layerRole,
      layer,
      canScenarioUpdate,
      isBaseScenario,
      featureFlags,
      layerGeometry,
      allowedInsightKeys,
    ],
  );

  const activeTabInfo = visibleTabInfos.find(item => item.key === activeTabKey);

  useSetSubComponent(layerId, activeTabKey, onHideSubComponent);

  // if we ever switch to a tab that isn't supported, switch back to the first
  // available tab.
  useEffect(() => {
    const visibleTabKeys = visibleTabInfos.map(item => item.key);
    if (!visibleTabKeys.includes(activeTabKey)) {
      onSetActiveTabKey(visibleTabInfos[0]?.key);
    }
  }, [activeTabKey, onSetActiveTabKey, visibleTabInfos]);

  const tabInfoProps: TabInfoProps = {
    ...props,
    width: width - 2 * TAB_PANE_PADDING_WIDTH,
    onShowSubComponent,
    onHideSubComponent,
  };

  const shouldRenderSubComponent =
    shouldShowSubComponent && activeTabInfo && activeTabInfo.getSubComponent;

  let content: React.ReactNode = (
    <ErrorBoundary
      title={t`Error loading layer details`}
      componentName="ExploreDetailsPane">
      <ExploreDetailsHeader
        layerId={layerId}
        layerName={layer ? layerName : ''}
        layerIconPath={LayerIconPathByRole[layerRole]}
        columnKey={columnKey}
        onClose={onClose}
      />
      <ExploreDetailsBody
        activeKey={activeTabKey}
        tabInfos={visibleTabInfos}
        tabInfoProps={tabInfoProps}
        onSelectTab={onSetActiveTabKey}
      />
    </ErrorBoundary>
  );

  if (shouldRenderSubComponent && activeTabInfo) {
    content = activeTabInfo.getSubComponent(tabInfoProps);
  }

  return (
    <Pane
      column
      className={exploreDetailsPaneClassName}
      flexShrink={0}
      width={width}>
      {content}
    </Pane>
  );
};

// All components under this should be passed the current project/layer/scenario ids,
// rather than using getActive* directly.
export default connect<StateProps, DispatchProps, OwnProps>(
  () => {
    const getLayerActiveColumnKey = makeGetLayerActiveColumnKey();
    const getLayerMetadata = makeGetLayerMetadata();
    const getLayerRole = makeGetLayerRoleForProject();
    const getLayerActiveTabKey = makeGetLayerActiveDetailTabKey();

    const getActiveViewId = makeGetActiveViewId();
    const getActiveScenarioId = makeGetActiveScenarioIdForProject();
    const getProjectLayerIdMap = makeGetProjectLayerIdMap();
    return (state: UFState): StateProps => {
      const projectId = getActiveProjectId(state);
      const viewId = getActiveViewId(state, { projectId });
      const scenarioId = getActiveScenarioId(state, { projectId });
      const layerId = getActiveLayerId(state, {
        projectId,
        scenarioId,
        viewId,
      });
      const isBaseScenario = scenarioId === getBaseScenarioId(state);
      const virtualLayerId = getProjectLayerIdMap(state, { projectId })[
        layerId
      ];
      return {
        projectId,
        scenarioId,
        viewId,
        layerId,
        virtualLayerId,
        columnKey: getLayerActiveColumnKey(state, {
          projectId,
          virtualLayerId,
          viewId,
        }),
        layer: getLayerMetadata(state, { layerId }),
        layerRole: getLayerRole(state, { layerId, projectId, scenarioId }),
        isBaseScenario,
        activeTabKey: getLayerActiveTabKey(state, {
          projectId,
          layerId,
        }),
        featureFlags: [
          ...getUserFlags(state),
          ...getUserActiveOrganizationFlags(state),
        ],
      };
    };
  },
  (dispatch: Dispatch): DispatchProps => ({
    onSetTab: bindActionCreators(
      exploreActionCreators.setDetailsPaneTabKey,
      dispatch,
    ),
    clearActiveLayer: bindActionCreators(
      exploreLayerActionCreators.clearActiveLayer,
      dispatch,
    ),
  }),
)(ExploreDetailsPane);

function useSetSubComponent(
  layerId: LayerId,
  activeTabKey: TabKey,
  onHideSubComponent: () => void,
) {
  const previousLayerId = usePrevious(layerId);
  const previousTabKey = usePrevious(activeTabKey);
  // switch to main component on layer switch
  useEffect(() => {
    const layerSwitched = layerId !== previousLayerId;
    if (layerSwitched || activeTabKey !== previousTabKey) {
      onHideSubComponent();
    }
  }, [
    layerId,
    previousLayerId,
    activeTabKey,
    previousTabKey,
    onHideSubComponent,
  ]);
}

function useSubComponent(forceShowComponent: boolean) {
  const [showSubComponent, setShowSubComponent] = useState(false);

  const onShowSubComponent = useCallback(
    () => setShowSubComponent(true),
    [setShowSubComponent],
  );
  const onHideSubComponent = useCallback(
    () => setShowSubComponent(false),
    [setShowSubComponent],
  );
  // force always overrides if it is not undefined
  const shouldShowSubComponent = forceShowComponent ?? showSubComponent;
  return { onShowSubComponent, onHideSubComponent, shouldShowSubComponent };
}

function getVisibleTabs(
  layerRole: LayerRole,
  layer: LayerMetadata,
  canScenarioUpdate: boolean,
  isBaseScenario: boolean,
  featureFlags: string[],
  geometryType: string,
  allowedInsightKeys: Set<number>,
) {
  return DetailsPaneTabs.filter(({ shouldShow }) =>
    shouldShow(
      layerRole,
      layer,
      canScenarioUpdate,
      isBaseScenario,
      featureFlags ?? [],
      geometryType,
      allowedInsightKeys,
    ),
  );
}
