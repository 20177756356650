import _ from 'lodash';
import { createSelector } from 'reselect';

import { ScenarioMetadata } from 'uf-api';
import { EMPTY_ARRAY, EMPTY_OBJECT } from 'uf/base';
import { cacheableConcat } from 'uf/base/array';
import { parseFullPath } from 'uf/base/dataset';
import { makeGetFromPropsSelector } from 'uf/base/selector';
import { DataState, isLoading } from 'uf/data/dataState';
import { ProjectId } from 'uf/projects';
import { CreateScenarioDataStateExtraProp } from 'uf/projects/actions';
import { makeGetProjectScenarios } from 'uf/projects/selectors';
import { ScenarioCloneTaskMessage } from 'uf/projects/tasks';
import { ScenarioUpdateStatus } from 'uf/scenarios/update';
import { UFState } from 'uf/state';
import { TaskStatuses } from 'uf/tasks/TaskStatuses';

import { ScenarioId } from './';
import { CloneStatusState, DeleteStatusState, ScenariosState } from './state';

export function getScenariosState(state: UFState): ScenariosState {
  return state?.scenarios || (EMPTY_OBJECT as ScenariosState);
}
export const getDeletedScenariosState = createSelector(
  getScenariosState,
  (scenarios): DeleteStatusState =>
    scenarios.deleteStatus || (EMPTY_OBJECT as DeleteStatusState),
);

export const getClonedScenariosState = createSelector(
  getScenariosState,
  (scenarios): CloneStatusState =>
    scenarios.cloneStatus || (EMPTY_OBJECT as CloneStatusState),
);

export const getScenariosUpdateStatus = createSelector(
  getScenariosState,
  (scenarios): Record<string, ScenarioUpdateStatus> =>
    scenarios.updateStatus || EMPTY_OBJECT,
);

export const getDeletedScenarioKeys = createSelector(
  getDeletedScenariosState,
  (messages): string[] => Object.keys(messages),
);

export const getScenarioUpdateStatus = createSelector(
  getScenariosUpdateStatus,
  makeGetFromPropsSelector<ScenarioId, 'scenarioId'>('scenarioId'),
  (statuses, id): ScenarioUpdateStatus => statuses[getScenarioKeyfromId(id)],
);

// helper function to get scenarioKey from the Id while handling the null case (activeScenarioId is
// null for the base scenario).
export function getScenarioKeyfromId(scenarioId: ScenarioId) {
  if (_.isEmpty(scenarioId)) {
    return null;
  }

  const { key } = parseFullPath(scenarioId);
  return key;
}

const CLONE_IN_PROGRESS_OR_COMPLETE = [
  TaskStatuses.ENQUEUED,
  TaskStatuses.RUNNING,
  TaskStatuses.DONE,
];

const CLONE_FAILED_OR_CANCELLED = [TaskStatuses.ERROR, TaskStatuses.CANCELLED];

/**
 * Selector to get the websocket task messages for cloned scenarios.  Retrieves messages for
 * scenarios that are currently being cloned and those that completed successfully.
 */
export const getClonedScenarioMessagesForProject = createSelector(
  makeGetFromPropsSelector<ProjectId, 'projectId'>('projectId'),
  getClonedScenariosState,
  (projectId, cloneStatuses): ScenarioCloneTaskMessage[] => {
    return _(cloneStatuses.tasks)
      .mapValues(dataState => dataState.extra.result)
      .filter(({ status }) => CLONE_IN_PROGRESS_OR_COMPLETE.includes(status))
      .filter(({ scope }) => scope === projectId)
      .value();
  },
);

/**
 * Selector to get the websocket task messages for cloned scenarios.  Retrieves messages for
 * scenarios that have failed or been cancelled.
 */
export const getFailedClonedScenarioMessagesForProject = createSelector(
  makeGetFromPropsSelector<ProjectId, 'projectId'>('projectId'),
  getClonedScenariosState,
  (projectId, cloneStatuses): ScenarioCloneTaskMessage[] => {
    return _(cloneStatuses.tasks)
      .mapValues(dataState => dataState.extra.result)
      .filter(({ status }) => CLONE_FAILED_OR_CANCELLED.includes(status))
      .filter(({ scope }) => scope === projectId)
      .value();
  },
);

export const getClonedScenarioDataStatesForProject = createSelector(
  makeGetFromPropsSelector<ProjectId, 'projectId'>('projectId'),
  getClonedScenariosState,
  (
    projectId,
    cloneStatuses,
  ): DataState<string, CreateScenarioDataStateExtraProp>[] => {
    return _.filter(
      cloneStatuses.enqueued,
      dataState => dataState.extra.projectId === projectId,
    );
  },
);

/**
 * Selector that returns stubbed scenario metadata for scenarios currently being created.
 */
export const getPendingScenarioMetadatasForProject = createSelector(
  getClonedScenarioDataStatesForProject,
  getClonedScenarioMessagesForProject,
  makeGetProjectScenarios(),
  (dataStates, taskMessages, projectScenarios): ScenarioMetadata[] => {
    const projectScenarioIds = projectScenarios.map(
      ({ full_path: fullPath }) => fullPath,
    );

    // fill out scenario metadatas from websocket task messages.
    const pendingTaskMetadatas = taskMessages
      .map((message): ScenarioMetadata => {
        const {
          scope,
          info: { scenario_key: scenarioKey, scenario_name: scenarioName },
        } = message;
        const { namespace } = parseFullPath(scope, 'project');

        const scenarioId = `/${namespace}/scenario/${scenarioKey}`;
        return {
          full_path: scenarioId,
          name: scenarioName,
          key: scenarioKey,
          namespace,
        };
      })
      // no longer 'pending' if we find it in the project
      .filter(
        ({ full_path: scenarioId }) => !projectScenarioIds.includes(scenarioId),
      );

    // the data states from the create scenario actions have no information about the scenario, so
    // we pass 'stubbed' in the properties.  we can use this to inform the ui what to show.
    const dataMetadatas = dataStates
      .filter(s => isLoading(s))
      .map((dataState, index): ScenarioMetadata => {
        const { namespace } = parseFullPath(dataState.extra.projectId);
        return {
          full_path: `/${namespace}/scenario/stubbed_${index}`,
          key: `stubbed_${index}`,
          namespace,
        };
      });

    const metadatas = [
      ...pendingTaskMetadatas, // show pending scenario's with information
      ...dataMetadatas.slice(pendingTaskMetadatas.length), // fill in 'stubbed' scenarios
    ];
    if (!metadatas.length) {
      return EMPTY_ARRAY;
    }

    return metadatas;
  },
);

export const getPendingScenarioIdsForProject = createSelector(
  getPendingScenarioMetadatasForProject,
  metadatas => {
    return metadatas.map(({ full_path: fullPath }) => fullPath);
  },
);
/**
 * returns the sorted list of scenarios in the active project with the scenarios that are currently
 * being cloned at the end.  We remove any scenarios if we find a matching task that says it was
 * deleted.  Pending and deleted messages only last until refresh.
 */
export const getPendingAndProjectScenarioMetadatas = createSelector(
  makeGetProjectScenarios(),
  getPendingScenarioMetadatasForProject,
  getDeletedScenarioKeys,
  (projectScenarios, pendingScenarios, deletedKeys): ScenarioMetadata[] =>
    cacheableConcat(
      [...projectScenarios, ...pendingScenarios].filter(
        ({ key }) => !deletedKeys.includes(key),
      ),
    ),
);
