import classNames from 'classnames';
import React, { CSSProperties, FunctionComponent } from 'react';

import rootStyles from 'uf/styles/root.module.css';

import styles from './Key.module.css';

interface Props {
  className?: string;

  style?: CSSProperties;

  /**
   * the character to stylize as a keyboard button.
   */
  character: string;
}
const Key: FunctionComponent<Props> = ({
  className,
  character,
  style = {},
}) => {
  const keyClass = classNames(className, rootStyles.minimalPadding, styles.key);
  return (
    <span className={keyClass} style={style}>
      {character}
    </span>
  );
};

export default Key;
