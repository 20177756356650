import { t } from 'ttag';
import { NULL_CATEGORY } from 'uf/layers/nullStats';
import { DataDrivenStopValue } from '.';

export interface Bin {
  lowerBound: number;
  upperBound: number;
  fillColor: string;
  lineColor: string;
}

export const MIN_CUSTOM_BINS = 1;

// TODO: Move to uf/ui/symbology/bins.ts
export const BinSummaryMessages = {
  ELIMINATE_BINS_MESSAGE: t`Eliminates existing bin(s)`,
  INDEX_OUT_OF_BOUNDS: t`Index out of bounds`,
  BOUNDS_OVERLAP: t`Lower bound cannot be greater than upper bound`,
  MINIMUM_NUMBER_OF_BINS: t`Must have at least 1 bin`,
  SPLIT_OUT_OF_BOUNDS: t`Split value must be between the lower and upper bounds`,
};

export function isNullBin(bin: Bin) {
  return bin.upperBound === undefined;
}

export interface UpdateBinResult {
  /**
   * If an update can be performed
   */
  canUpdate: boolean;

  /**
   * Number of lower bins that were swallowed by the edited bin
   */
  numSwallowedLower: number;

  /**
   * Number of upper bins that were swallowed by the edited bin
   */
  numSwallowedUpper: number;
  /**
   * New index of the edited bin
   */
  newIndex: number;

  message: string;
  /**
   * Resulting bins
   */
  bins: Bin[];
}
/**
 * Updates the bounds for a bin, collapsing neigbors when appropriate.
 *
 * @param bins the list of bins to work on
 * @param editIndex the index of the bin to edit
 * @param lowerBound the new lowerBound of the bin
 * @param upperBound the new upperBound of the bin
 * @param min the min value in the dataset
 * @param max the max value in the dataset
 * @param numSwallowedLower The number of lower bins swallowed by the update, used
 * internally for recursion only.
 * @param numSwallowedUpper The number of upper bins swallowed by the update, used
 * internally for recursion only.
 * @return summary of changes created from the new bounds;
 */
export function updateBinBounds(
  bins: Bin[],
  editIndex: number,
  lowerBound: number,
  upperBound: number,
  min: number,
  max: number,
  // Only used when recursing, you should not need to pass these explicitly
  numSwallowedLower: number = 0,
  numSwallowedUpper: number = 0,
): UpdateBinResult {
  /*
   * Base case:
   * Lower bound cannot be greater than upper bound.
   */
  if (lowerBound > upperBound) {
    return {
      canUpdate: false,
      numSwallowedLower,
      numSwallowedUpper,
      newIndex: editIndex,
      message: BinSummaryMessages.BOUNDS_OVERLAP,
      bins,
    };
  }

  /*
   * Base case:
   * When swallowing bins, never let an update occur on a bin that doesn't exist.
   * ie: the -1st bin
   */
  if (editIndex < 0 || editIndex >= bins.length) {
    return {
      canUpdate: false,
      numSwallowedLower,
      numSwallowedUpper,
      newIndex: editIndex,
      message: BinSummaryMessages.INDEX_OUT_OF_BOUNDS,
      bins,
    };
  }

  if (shouldSwallowPreviousBin(lowerBound, editIndex, bins, min)) {
    const newBins = [...bins];
    const previousBinIndex = editIndex - 1;
    newBins.splice(previousBinIndex, 1);
    return updateBinBounds(
      newBins,
      previousBinIndex,
      lowerBound,
      upperBound,
      min,
      max,
      numSwallowedLower + 1,
      numSwallowedUpper,
    );
  }

  if (shouldSwallowNextBin(upperBound, editIndex, bins, max)) {
    const newBins = [...bins];
    newBins.splice(editIndex + 1, 1);
    return updateBinBounds(
      newBins,
      editIndex,
      lowerBound,
      upperBound,
      min,
      max,
      numSwallowedLower,
      numSwallowedUpper + 1,
    );
  }

  const newBin: Bin = {
    ...bins[editIndex],
    lowerBound,
    upperBound,
  };

  const hasNullBin = isNullBin(bins[0]);
  const finalBins = [...bins];
  finalBins[editIndex] = newBin;

  // after swallowing neighbors we adjust the bounds of the neighbors to keep the intervals
  // contiguous:

  // adjust left neighbor upperbound, but only if it isn't a null bin
  if (editIndex > 0 && !(editIndex === 1 && hasNullBin)) {
    const leftNeigbor: Bin = {
      ...bins[editIndex - 1],
      upperBound: lowerBound,
    };
    finalBins[editIndex - 1] = leftNeigbor;
  }

  // adjust right neighbor lowerbound
  if (editIndex < finalBins.length - 1) {
    const rightNeighbor: Bin = {
      ...bins[editIndex + 1],
      lowerBound: upperBound,
    };
    finalBins[editIndex + 1] = rightNeighbor;
  }

  // this logic assumes no upper/lower bounds.  if we allow bounding we will need to include another
  // condition here.
  const firstRealBin = hasNullBin ? 1 : 0;
  finalBins[firstRealBin] = {
    ...finalBins[firstRealBin],
    lowerBound: Number.NEGATIVE_INFINITY,
  };
  finalBins[finalBins.length - 1] = {
    ...finalBins[finalBins.length - 1],
    upperBound: Number.POSITIVE_INFINITY,
  };

  let canUpdate = true;
  let message = null;
  if (numSwallowedLower || numSwallowedUpper) {
    message = BinSummaryMessages.ELIMINATE_BINS_MESSAGE;
  }
  if (finalBins.length < MIN_CUSTOM_BINS) {
    canUpdate = false;
    message = BinSummaryMessages.MINIMUM_NUMBER_OF_BINS;
  }

  return {
    canUpdate,
    numSwallowedLower,
    numSwallowedUpper,
    newIndex: editIndex,
    message,
    bins: finalBins,
  };
}

function shouldSwallowPreviousBin(
  newLowerBound: number,
  editIndex: number,
  bins: Bin[],
  min: number,
) {
  // We can't swallow the previous bin if this IS the first bin,
  // nor if the previous bin is still within range of our min, ie:
  // 0 to 10
  // 10 to 20 -> 9 to 20 should not swallow the first bin!
  // 20 to 30
  const isFirstBin = editIndex === 0;
  const isSecondBin = editIndex === 1;
  const firstBinStillValid = isSecondBin && newLowerBound > min;
  if (isFirstBin || firstBinStillValid) {
    return false;
  }

  // This assumes no lower bound for the first bin. If we allow bounding the lower edge
  // we will have to include another condition here.
  const [firstBin] = bins;
  if (isSecondBin && newLowerBound < firstBin.upperBound) {
    return true;
  }

  const previousBin = bins[editIndex - 1];
  if (!isSecondBin && newLowerBound <= previousBin.lowerBound) {
    return true;
  }

  return false;
}

function shouldSwallowNextBin(
  newUpperBound: number,
  editIndex: number,
  bins: Bin[],
  max: number,
) {
  // We can't swallow the upper bin if this IS the top-most bin!
  // nor if the next bin is still within range of our max, ie:
  // 0 to 10
  // 10 to 20 -> 10 to 21 should not swallow the last bin!
  // 20 to 30
  const isLastBin = editIndex === bins.length - 1;
  const isNextToLastBin = editIndex === bins.length - 2;
  const lastBinStillValid = isNextToLastBin && newUpperBound < max;
  if (isLastBin || lastBinStillValid) {
    return false;
  }

  // This assumes no upper bound for edge bin. if we allow bounding the upper edge we will
  // have to include another condition here.
  const nextUpperBound = isNextToLastBin
    ? bins[editIndex + 1].lowerBound
    : bins[editIndex + 1].upperBound;

  if (isNextToLastBin && newUpperBound > nextUpperBound) {
    return true;
  }

  if (!isNextToLastBin && newUpperBound >= nextUpperBound) {
    return true;
  }

  return false;
}

export function getStopValuesFromBins(bins: Bin[]): DataDrivenStopValue[] {
  const [firstBin, ...restBins] = bins;

  if (isNullBin(firstBin)) {
    return restBins.length
      ? [NULL_CATEGORY, ...getStopValuesFromBins(restBins)]
      : [NULL_CATEGORY];
  }

  // first stop is a throwaway, needs to be lower than second stop
  // TODO: pass min/max to this function to properly set lower/upper bounds
  return [
    firstBin.upperBound > 0 ? 0 : firstBin.upperBound - 1,
    ...restBins.map(({ lowerBound }) => lowerBound),
  ];
}

/**
 * Removes the bin at the specified index and returns a new list of bins.  This is the same action
 * as swallowing the bin by it's upper neighbor.  For the case where we are removing the last bin
 * and we have no upper neighbor, just remove it and call updateBinBounds in to make sure edge
 * bounds are assigned appropriate values.
 */
export function deleteBinAtIndex(
  bins: Bin[],
  index: number,
  min: number,
  max: number,
) {
  if (index < 0 || index >= bins.length) {
    return {
      canUpdate: false,
      lowerSwallowed: 0,
      upperSwallowed: 0,
      newIndex: 0,
      message: null,
      bins,
    };
  }
  const lastBin = index === bins.length - 1;
  if (lastBin) {
    const newBins = bins.slice(0, -1);

    const newLastIndex = newBins.length - 1;
    const newLastBin = newBins[newLastIndex];
    return updateBinBounds(
      newBins,
      newLastIndex,
      newLastBin.lowerBound,
      newLastBin.upperBound,
      min,
      max,
    );
  }

  const currentLowerBound = bins[index].lowerBound;
  const neighborIndex = index + 1;
  const neighborUpperBound = bins[neighborIndex].upperBound;
  return updateBinBounds(
    bins,
    neighborIndex,
    currentLowerBound,
    neighborUpperBound,
    min,
    max,
  );
}

export function insertNewBin(
  bins: Bin[],
  lowerBound: number,
  upperBound: number,
  min: number,
  max: number,
): UpdateBinResult {
  // assume a single bin is [-Inf, Inf] so there is no way to insert.  return an error.
  if (bins.length < 2) {
    return {
      canUpdate: false,
      numSwallowedLower: 0,
      numSwallowedUpper: 0,
      newIndex: 0,
      message: null,
      bins,
    };
  }

  let newBins: Bin[] = [...bins];
  const newBin: Bin = {
    lowerBound,
    upperBound,
    fillColor: 'transparent',
    lineColor: 'transparent',
  };

  let insertIndex = bins.findIndex(bin => bin.upperBound >= upperBound);

  const lowestBin = bins[0];
  const highestBin = bins[bins.length - 1];
  const lowerEdge = lowestBin.upperBound;
  const upperEdge = highestBin.lowerBound;

  // when there are only two bins there are no two points to 'insert' a new bin between so we bind
  // the lowerBound to the middle point of the two bins and insert a new bin from that point to the
  // new upperBound. i.e. if we already have 100, and we are given 200, we'd get this:
  // [-Inf, 100], [100, +Inf] -> [-Inf, 100][100, 200][200, +Inf]
  if (bins.length === 2) {
    const firstBin = bins[0];
    const lastBin = {
      ...bins[1],
      lowerBound: upperBound,
    };
    newBins = [firstBin, newBin, lastBin];
    // when there are 3 or more bins we have two edge cases, when the new bin straddles an edge bin
    // and when the new bin lies outside an edge bin.  for the straddle case we perform this:
    // bins:    [-Inf, 100], [100, 200], [200, Inf]
    //
    // new bin: [ 50, 150] (straddles 100)
    //
    // returns: [-Inf, 50], [50, 150], [150, 200], [200, Inf]
    //
    // on a number line this looks like:
    // <--------100-------200---->
    // becomes
    // <----50-----150----200---->
  } else if (isStraddling(lowerEdge, lowerBound, upperBound)) {
    const edgeBin = {
      ...newBin,
      lowerBound: Number.NEGATIVE_INFINITY,
      upperBound: lowerBound,
    };

    const straddledBin = {
      ...lowestBin,
      lowerBound,
      upperBound,
    };

    const innerBin = {
      ...bins[1],
      lowerBound: upperBound,
    };

    newBins = [edgeBin, straddledBin, innerBin, ...bins.slice(2)];
    insertIndex = 1;
  } else if (isStraddling(upperEdge, lowerBound, upperBound)) {
    const edgeBin = {
      ...newBin,
      lowerBound: upperBound,
      upperBound: Number.POSITIVE_INFINITY,
    };

    const straddledBin = {
      ...highestBin,
      lowerBound,
      upperBound,
    };

    const innerBin = {
      ...bins[bins.length - 2],
      upperBound: lowerBound,
    };

    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    newBins = [...bins.slice(0, -2), innerBin, straddledBin, edgeBin];
    insertIndex = newBins.length - 2;

    // when a new bin lies outside an edge bin we get this:
    // bins:    [-Inf, 100], [100, 200], [200, Inf]
    //
    // new bin: [ 0, 50] (below 100)
    //
    // returns: [-Inf, 0], [0, 50], [50, 100], [100, 200], [200, Inf]
    //
    // on a number line this looks like:
    // <----------------100----200---->
    // becomes
    // <----0----50-----100----200---->
  } else if (isBelow(lowerEdge, lowerBound, upperBound)) {
    const edgeBin = {
      ...newBin,
      lowerBound: Number.NEGATIVE_INFINITY,
      upperBound: lowerBound,
    };

    const innerBin = {
      ...lowestBin,
      lowerBound: upperBound,
    };

    newBins = [edgeBin, newBin, innerBin, ...bins.slice(1)];
    insertIndex = 1;
  } else if (isAbove(upperEdge, lowerBound, upperBound)) {
    const edgeBin = {
      ...newBin,
      lowerBound: upperBound,
      upperBound: Number.POSITIVE_INFINITY,
    };

    const innerBin = {
      ...highestBin,
      upperBound: lowerBound,
    };

    newBins = [...bins.slice(0, -1), innerBin, newBin, edgeBin];
    insertIndex = newBins.length - 2;

    // if we are within an existing bin then do nothing, updateBinBounds will adjust the bounds
  } else if (isWithinAnInnerBin(bins, lowerBound, upperBound)) {
    // else, we must be stradding a bound, insert a new bin!
  } else {
    newBins = [...bins];
    newBins.splice(insertIndex, 0, newBin);
  }

  const result = updateBinBounds(
    newBins,
    insertIndex,
    lowerBound,
    upperBound,
    min,
    max,
  );
  return result;
}

export function splitBin(bin: Bin, value: number): UpdateBinResult {
  const { lowerBound, upperBound } = bin;

  if (value > upperBound || value < lowerBound) {
    return {
      canUpdate: false,
      numSwallowedLower: 0,
      numSwallowedUpper: 0,
      newIndex: null,
      message: BinSummaryMessages.SPLIT_OUT_OF_BOUNDS,
      bins: null,
    };
  }

  const lowerBin: Bin = {
    ...bin,
    upperBound: value,
  };

  const upperBin: Bin = {
    ...bin,
    lowerBound: value,
  };

  return {
    canUpdate: true,
    numSwallowedLower: 0,
    numSwallowedUpper: 0,
    newIndex: null,
    message: null,
    bins: [lowerBin, upperBin],
  };
}

function isStraddling(value: number, low: number, high: number) {
  return low <= value && high >= value;
}

function isBelow(value: number, low: number, high: number) {
  return low <= value && high <= value;
}

function isAbove(value: number, low: number, high: number) {
  return low >= value && high >= value;
}

function isWithinAnInnerBin(bins: Bin[], low: number, high: number) {
  const innerBins = bins.slice(1, -1);
  return innerBins.some(
    ({ lowerBound, upperBound }) => low > lowerBound && high < upperBound,
  );
}
