/**
 * UrbanFootprint App API
 * API for serving UrbanFootprint Application
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { ClientResponse } from '../variables';

// @ts-ignore: This is used only for params, but some services have param-free APIs
import { MetadataServiceInterface } from './metadata.serviceInterface';

// @ts-ignore: These imports are for both params and returnType, but we only use returnType
import { ProblemDetail } from '../model/problemDetail';
// @ts-ignore: These imports are for both params and returnType, but we only use returnType
import { TaskEnqueueResultItem } from '../model/taskEnqueueResultItem';

/**
 * Package: api
 */

const downloadExportedColumnMetadataPrefix = `uf-api/metadata/download_exported_column_metadata/`;
/**
 * Action types for metadata.download_exported_column_metadata
 *
 * Download column metadata that was previously enqueued.
 */
export const downloadExportedColumnMetadataActionTypes = {
  LOAD: `${downloadExportedColumnMetadataPrefix}LOAD` as 'uf-api/metadata/download_exported_column_metadata/LOAD',
  SUCCESS:
    `${downloadExportedColumnMetadataPrefix}SUCCESS` as 'uf-api/metadata/download_exported_column_metadata/SUCCESS',
  FAILURE:
    `${downloadExportedColumnMetadataPrefix}FAILURE` as 'uf-api/metadata/download_exported_column_metadata/FAILURE',
  CLEAR:
    `${downloadExportedColumnMetadataPrefix}CLEAR` as 'uf-api/metadata/download_exported_column_metadata/CLEAR',
} as const;

/**
 * LOAD action for metadata.download_exported_column_metadata
 * Download column metadata that was previously enqueued.
 */
export interface downloadExportedColumnMetadataLoadAction {
  readonly type: 'uf-api/metadata/download_exported_column_metadata/LOAD';
  readonly params: MetadataServiceInterface.downloadExportedColumnMetadataParams;
  readonly promise: Promise<ClientResponse<{}>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for metadata.download_exported_column_metadata
 * Download column metadata that was previously enqueued.
 */
export interface downloadExportedColumnMetadataSuccessAction {
  readonly type: 'uf-api/metadata/download_exported_column_metadata/SUCCESS';
  readonly result: {};
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for metadata.download_exported_column_metadata
 * Download column metadata that was previously enqueued.
 */
export interface downloadExportedColumnMetadataFailureAction {
  readonly type: 'uf-api/metadata/download_exported_column_metadata/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for metadata.download_exported_column_metadata
 * Download column metadata that was previously enqueued.
 */
export interface downloadExportedColumnMetadataClearAction {
  readonly type: 'uf-api/metadata/download_exported_column_metadata/CLEAR';
  readonly key: string;
}

const enqueueColumnExportTaskPrefix = `uf-api/metadata/enqueue_column_export_task/`;
/**
 * Action types for metadata.enqueue_column_export_task
 *
 * Enqueue a column description export task.
 */
export const enqueueColumnExportTaskActionTypes = {
  LOAD: `${enqueueColumnExportTaskPrefix}LOAD` as 'uf-api/metadata/enqueue_column_export_task/LOAD',
  SUCCESS:
    `${enqueueColumnExportTaskPrefix}SUCCESS` as 'uf-api/metadata/enqueue_column_export_task/SUCCESS',
  FAILURE:
    `${enqueueColumnExportTaskPrefix}FAILURE` as 'uf-api/metadata/enqueue_column_export_task/FAILURE',
  CLEAR:
    `${enqueueColumnExportTaskPrefix}CLEAR` as 'uf-api/metadata/enqueue_column_export_task/CLEAR',
} as const;

/**
 * LOAD action for metadata.enqueue_column_export_task
 * Enqueue a column description export task.
 */
export interface enqueueColumnExportTaskLoadAction {
  readonly type: 'uf-api/metadata/enqueue_column_export_task/LOAD';
  readonly params: MetadataServiceInterface.enqueueColumnExportTaskParams;
  readonly promise: Promise<ClientResponse<TaskEnqueueResultItem>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for metadata.enqueue_column_export_task
 * Enqueue a column description export task.
 */
export interface enqueueColumnExportTaskSuccessAction {
  readonly type: 'uf-api/metadata/enqueue_column_export_task/SUCCESS';
  readonly result: TaskEnqueueResultItem;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for metadata.enqueue_column_export_task
 * Enqueue a column description export task.
 */
export interface enqueueColumnExportTaskFailureAction {
  readonly type: 'uf-api/metadata/enqueue_column_export_task/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for metadata.enqueue_column_export_task
 * Enqueue a column description export task.
 */
export interface enqueueColumnExportTaskClearAction {
  readonly type: 'uf-api/metadata/enqueue_column_export_task/CLEAR';
  readonly key: string;
}

export const metadataActionTypesMap = {
  /** Action types for metadata.download_exported_column_metadata */
  download_exported_column_metadata: downloadExportedColumnMetadataActionTypes,
  /** Action types for metadata.enqueue_column_export_task */
  enqueue_column_export_task: enqueueColumnExportTaskActionTypes,
} as const;

export const metadataDetailsMap = {
  download_exported_column_metadata: {
    /** HTTP method for metadata.download_exported_column_metadata */
    httpMethod: 'get' as const,
  },
  enqueue_column_export_task: {
    /** HTTP method for metadata.enqueue_column_export_task */
    httpMethod: 'post' as const,
  },
} as const;

/**
 * Local DataState<T> - kept in sync with uf/data/dataState.ts
 */
interface DataState<T> {
  readonly loading: boolean;
  readonly loaded: boolean;
  readonly requestId: string;
  readonly requestTime?: string;
  readonly responseTime?: string;
  readonly requestCount?: number;
  readonly error?: any;
  readonly promise?: Promise<T>;
  readonly data?: T;
  readonly extra?: any;
  readonly key?: string;
}
export interface MetadataServiceState {
  /** downloadExportedColumnMetadata state, by key */
  download_exported_column_metadata: {
    readonly [key: string]: DataState<{}>;
  };
  /** all recent enqueueColumnExportTask results (POST/PUT/PATCH) */
  readonly enqueue_column_export_task: DataState<TaskEnqueueResultItem>[];
}
