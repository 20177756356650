export enum TaskTypes {
  TASK_TYPE_PROJECT_CREATE_BASE_SCENARIO = 'project_create_base_scenario',
  TASK_TYPE_PROJECT_ADD_NEW_CLIPPED_LAYER = 'project_add_new_clipped_layer',
  TASK_TYPE_PROJECT_CREATE_CONTEXT_CANVAS_LAYER = 'project_create_context_canvas_layer',
  TASK_TYPE_PROJECT_COPY_AND_BAKE_BUILT_FORMS = 'project_copy_and_bake_built_forms',
  TASK_TYPE_PROJECT_CREATE_PARCEL_ATTRIBUTES_LAYER = 'project_create_parcel_attributes_layer',
  TASK_TYPE_PROJECT_PREPARE_MODULE_PARAMS = 'project_prepare_module_params',
  TASK_TYPE_PROJECT_IMPORT_LAYER = 'project_import_layer',
  TASK_TYPE_PROJECT_CREATE_BUFFERED_LAYER = 'project_create_buffered_layer',
  TASK_TYPE_PROJECT_CREATE_POINT_ON_SURFACE_LAYER = 'project_create_point_on_surface_layer',
  TASK_TYPE_SCENARIO_MODULE_ANALYSIS = 'scenario_module_analysis',
  TASK_TYPE_EXPORT_LAYER = 'export_layer',
  TASK_TYPE_PROJECT_EXPORT_LAYER = 'project_export_layer',
  TASK_TYPE_PROJECT_SCENARIO_CLONE = 'project_scenario_clone',
  TASK_TYPE_TRANSIT_CHANGESET_APPLY = 'transit_changeset_apply',
  TASK_TYPE_SCENARIO_PAINT = 'project_scenario_paint',
  TASK_TYPE_BASE_SCENARIO_PAINT = 'project_base_scenario_paint',
  TASK_TYPE_EXPORT_COLUMNS = 'export_columns',
  TASK_TYPE_PROJECT_LAYER_ANALYSIS = 'project_layer_analysis',
  TASK_TYPE_PROJECT_LAYER_ANALYSIS_RESULT = 'project_layer_analysis_result',
  TASK_TYPE_PROJECT_LAYER_ANALYSIS_DASHBOARD = 'project_layer_analysis_dashboard',
}
