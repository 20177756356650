import { boundMethod } from 'autobind-decorator';
import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';
import wrapDisplayName from 'recompose/wrapDisplayName';
import { bindActionCreators, Dispatch } from 'redux';

import { Subscription } from 'uf-api';
import { omitHocProps, withHocPrefix } from 'uf/base/hocs';
import { DataState } from 'uf/data/dataState';
import * as organizationActionCreators from 'uf/organizations/actions';
import {
  makeGetOrganizationsSubscriptionStates,
  makeGetOrganizationSubscription,
  makeGetOrganizationSubscriptionState,
} from 'uf/organizations/selectors';
import { getUserOrganizationKeys } from 'uf/user/selectors/user';
import { selectApp } from 'uf/app/slice';

const organizationActions = withHocPrefix('organizationActions');

export interface WithSubscriptionProps {
  subscription?: Subscription;
  subscriptionState?: DataState<Subscription>;
}

interface OwnProps {
  // required, passed in from parent
  organizationKey?: string;
}

interface StateProps {
  // Not passed to wrapped component
  [organizationActions: string]: typeof organizationActionCreators;
}

type WithSubscriptionHOCProps = WithSubscriptionProps & OwnProps & StateProps;

export function withSubscription(
  subscriptionPropName = 'subscription',
  subscriptionStatePropName = 'subscriptionState',
) {
  return WrappedComponent => {
    class WithSubscriptionHOC extends Component<WithSubscriptionHOCProps> {
      componentDidMount() {
        this.ensureSubscription();
      }

      componentDidUpdate(prevProps) {
        const { organizationKey } = this.props;
        if (organizationKey !== prevProps.organizationKey) {
          this.ensureSubscription();
        }
      }

      @boundMethod
      ensureSubscription() {
        if (this.props.organizationKey) {
          this.props[organizationActions].ensureOrganizationSubscription(
            this.props.organizationKey,
          );
        }
      }

      render() {
        const props = omitHocProps(this.props);
        return <WrappedComponent {...props} />;
      }
    }

    function makeMapStateToProps() {
      const getOrganizationSubscription = makeGetOrganizationSubscription();
      const getOrganizationSubscriptionState =
        makeGetOrganizationSubscriptionState();
      return (state, props) => ({
        [subscriptionPropName]: getOrganizationSubscription(state, {
          organizationKey: props.organizationKey,
        }),
        [subscriptionStatePropName]: getOrganizationSubscriptionState(state, {
          organizationKey: props.organizationKey,
        }),
      });
    }
    const wrapped = connect(
      makeMapStateToProps,
      mapDispatchToProps,
    )(WithSubscriptionHOC as React.ComponentClass | React.SFC);
    wrapDisplayName(WrappedComponent, WithSubscriptionHOC.name);
    return wrapped;
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    [organizationActions]: bindActionCreators(
      organizationActionCreators,
      dispatch,
    ),
  };
}

interface AllSubscriptionsOwnProps {
  children: (
    subscriptions: DataState<Subscription>[],
    organizationKey: string,
  ) => ReactNode;
}

interface AllSubscriptionStateProps {
  subscriptions: DataState<Subscription>[];
  organizationKeys: string[];
  organizationKey: string;
}

interface AllSubscriptionDispatchProps {
  ensureOrganizationSubscription: (orgKey: string) => void;
}

type AllSubscriptionProps = AllSubscriptionsOwnProps &
  AllSubscriptionStateProps &
  AllSubscriptionDispatchProps;

class WithAllSubscriptions extends Component<AllSubscriptionProps> {
  componentDidMount() {
    this.ensureSubscriptions();
  }
  componentDidUpdate(prevProps: AllSubscriptionProps) {
    if (prevProps.organizationKeys !== this.props.organizationKeys) {
      this.ensureSubscriptions();
    }
  }

  ensureSubscriptions() {
    const { organizationKeys, ensureOrganizationSubscription } = this.props;
    organizationKeys.forEach(orgKey => {
      ensureOrganizationSubscription(orgKey);
    });
  }
  render() {
    const { children, subscriptions, organizationKey } = this.props;
    return children(subscriptions, organizationKey);
  }
}

export const ConnectedWithAllSubscriptions = connect<
  AllSubscriptionStateProps,
  AllSubscriptionDispatchProps,
  AllSubscriptionsOwnProps
>(
  () => {
    const getOrganizationsSubscriptionStates =
      makeGetOrganizationsSubscriptionStates();
    return state => {
      const organizationKeys = getUserOrganizationKeys(state);
      const organizationKey = selectApp(state).activeOrgId;
      return {
        organizationKey,
        organizationKeys,
        subscriptions: getOrganizationsSubscriptionStates(state, {
          organizationKeys,
        }),
      };
    };
  },
  dispatch => ({
    ensureOrganizationSubscription: bindActionCreators(
      organizationActionCreators.ensureOrganizationSubscription,
      dispatch,
    ),
  }),
)(WithAllSubscriptions);
