import {
  CuratedColumnSymbology,
  DistributionTypes,
  InterpolationTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const ERILayers = [
  {
    layerId: '/calthorpeanalytics/databank/eri_alabama_v1',
    columnKey: 'hh_eviction_risk_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/eri_alaska_v1',
    columnKey: 'hh_eviction_risk_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/eri_arizona_v1',
    columnKey: 'hh_eviction_risk_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/eri_arkansas_v1',
    columnKey: 'hh_eviction_risk_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/eri_california_v1',
    columnKey: 'hh_eviction_risk_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/eri_colorado_v1',
    columnKey: 'hh_eviction_risk_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/eri_connecticut_v1',
    columnKey: 'hh_eviction_risk_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/eri_delaware_v1',
    columnKey: 'hh_eviction_risk_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/eri_florida_v1',
    columnKey: 'hh_eviction_risk_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/eri_georgia_v1',
    columnKey: 'hh_eviction_risk_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/eri_hawaii_v1',
    columnKey: 'hh_eviction_risk_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/eri_idaho_v1',
    columnKey: 'hh_eviction_risk_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/eri_illinois_v1',
    columnKey: 'hh_eviction_risk_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/eri_indiana_v1',
    columnKey: 'hh_eviction_risk_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/eri_iowa_v1',
    columnKey: 'hh_eviction_risk_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/eri_kansas_v1',
    columnKey: 'hh_eviction_risk_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/eri_kentucky_v1',
    columnKey: 'hh_eviction_risk_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/eri_louisiana_v1',
    columnKey: 'hh_eviction_risk_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/eri_maine_v1',
    columnKey: 'hh_eviction_risk_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/eri_maryland_v1',
    columnKey: 'hh_eviction_risk_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/eri_massachusetts_v1',
    columnKey: 'hh_eviction_risk_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/eri_michigan_v1',
    columnKey: 'hh_eviction_risk_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/eri_minnesota_v1',
    columnKey: 'hh_eviction_risk_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/eri_mississippi_v1',
    columnKey: 'hh_eviction_risk_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/eri_missouri_v1',
    columnKey: 'hh_eviction_risk_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/eri_montana_v1',
    columnKey: 'hh_eviction_risk_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/eri_nebraska_v1',
    columnKey: 'hh_eviction_risk_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/eri_nevada_v1',
    columnKey: 'hh_eviction_risk_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/eri_new_hampshire_v1',
    columnKey: 'hh_eviction_risk_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/eri_new_jersey_v1',
    columnKey: 'hh_eviction_risk_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/eri_new_mexico_v1',
    columnKey: 'hh_eviction_risk_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/eri_new_york_v1',
    columnKey: 'hh_eviction_risk_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/eri_north_carolina_v1',
    columnKey: 'hh_eviction_risk_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/eri_north_dakota_v1',
    columnKey: 'hh_eviction_risk_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/eri_ohio_v1',
    columnKey: 'hh_eviction_risk_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/eri_oklahoma_v1',
    columnKey: 'hh_eviction_risk_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/eri_oregon_v1',
    columnKey: 'hh_eviction_risk_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/eri_pennsylvania_v1',
    columnKey: 'hh_eviction_risk_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/eri_rhode_island_v1',
    columnKey: 'hh_eviction_risk_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/eri_south_carolina_v1',
    columnKey: 'hh_eviction_risk_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/eri_south_dakota_v1',
    columnKey: 'hh_eviction_risk_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/eri_tennessee_v1',
    columnKey: 'hh_eviction_risk_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/eri_texas_v1',
    columnKey: 'hh_eviction_risk_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/eri_utah_v1',
    columnKey: 'hh_eviction_risk_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/eri_vermont_v1',
    columnKey: 'hh_eviction_risk_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/eri_virginia_v1',
    columnKey: 'hh_eviction_risk_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/eri_washington_v1',
    columnKey: 'hh_eviction_risk_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/eri_west_virginia_v1',
    columnKey: 'hh_eviction_risk_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/eri_wisconsin_v1',
    columnKey: 'hh_eviction_risk_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/eri_wyoming_v1',
    columnKey: 'hh_eviction_risk_pct',
  },
  {
    layerId: '/calthorpeanalytics/databank/eri_district_of_columbia_v1',
    columnKey: 'hh_eviction_risk_pct',
  },
];

const symbologies: Record<
  string,
  Record<string, CuratedColumnSymbology[]>
> = {};
ERILayers.forEach(({ layerId, columnKey }) => {
  symbologies[layerId] = {
    [columnKey]: [
      {
        display_hints: {
          distribution: DistributionTypes.DEFAULT,
          interpolation: InterpolationTypes.INTERVAL,
          scale: SymbologyScales.NUMERIC,
          showZero: true,
          theme: 'd3:interpolateGnBu',
        },
        paint: {
          'fill-color': {
            property: columnKey,
            stops: [
              {
                color: '#f7fcf0',
                value: 0,
              },
              {
                color: '#e0f3db',
                value: 5,
              },
              {
                color: '#ccebc5',
                value: 8,
              },
              {
                color: '#a8ddb5',
                value: 11,
              },
              {
                color: '#7bccc4',
                value: 13,
              },
              {
                color: '#4eb3d3',
                value: 15,
              },
              {
                color: '#2b8cbe',
                value: 18,
              },
              {
                color: '#08589e',
                value: 23,
              },
            ],
            type: 'interval',
          },
          'fill-opacity': 0.5,
        },
        type: SymbologyTypes.FILL,
      },
      {
        display_hints: {
          distribution: DistributionTypes.DEFAULT,
          interpolation: InterpolationTypes.INTERVAL,
          scale: SymbologyScales.NUMERIC,
          showZero: true,
          theme: 'custom',
        },
        paint: {
          'line-color': '#333333',
          'line-opacity': 0.5,
          'line-width': 0.5,
        },
        type: SymbologyTypes.LINE,
      },
    ],
  };
});

export default symbologies;
