import { Reducer } from 'redux';

import { EMPTY_OBJECT } from 'uf/base';
import {
  FailureAction,
  LoadAction,
  LoadActionTypes,
  SuccessAction,
  UpdateStateAction,
} from 'uf/data/ActionTypes';
import {
  DataState,
  initialState,
  makeClearedState,
  makeErrorState,
  makeLoadedState,
  makeLoadingState,
} from 'uf/data/dataState';

/**
 * Create a reducer that reduces state based on the 3-state promise.
 * actionType should be a unique string like 'cec/cohorts/schema'.
 */
export function makePromiseReducer<T>(
  actionTypes: LoadActionTypes,
): Reducer<DataState<T>, any> {
  return function reducer(
    state: DataState<T> = initialState,
    action: UpdateStateAction<T> = EMPTY_OBJECT as UpdateStateAction<T>,
  ): DataState<T> {
    switch (action.type) {
      case actionTypes.LOAD:
        return makeLoadingState(state, action as LoadAction);
      case actionTypes.SUCCESS:
        return makeLoadedState(state, action as SuccessAction<T>);
      case actionTypes.FAILURE:
        return makeErrorState(state, action as FailureAction);
      case actionTypes.CLEAR:
        return makeClearedState(state);
      default:
        return state;
    }
  };
}
