import {
  HTMLInputProps,
  IInputGroupProps,
  IPopoverProps,
  MenuDivider,
  MenuItem,
  Spinner,
  SpinnerSize,
} from '@blueprintjs/core';
import {
  IItemModifiers,
  IItemRendererProps,
  Suggest,
} from '@blueprintjs/select';
import classNames from 'classnames';
import _ from 'lodash';
import React, {
  Fragment,
  FunctionComponent,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { t } from 'ttag';
import warning from 'warning';

import { LayerId } from 'uf/layers';
import { FilterSpec } from 'uf/layers/filters';
import { CensusLocation } from 'uf/projects/create';
import { RawSearchResult } from 'uf/search/state';
import rootStyles from 'uf/styles/root.module.css';
import { tw } from 'uf/tailwindcss-classnames';
import ErrorMessage from 'uf/ui/base/ErrorMessage/ErrorMessage';
import { withLayerSearchData } from 'uf/ui/search/hocs/withLayerSearchData';

import { useBoundaryTypes } from 'uf/ui/projects/hooks';
import { useBeginSearch, useDisambiguations, useEnsureMetadata } from './hooks';
import {
  LocationPickerSection,
  LocationPickerSourceLayer,
  LocationRow,
} from './types';

interface OwnProps {
  autoFocus?: boolean;
  placeholder?: string;
  selectDefaultArea?: boolean;

  onChange?: (location: CensusLocation | null) => void;
  projectType?: string;
  className?: string;
  /**
   * The property in the disambiguation layer for disambiguating on
   */
  disambiguationProperty?: keyof LocationRow;
  /**
   * The number of results to allow, default set to 50.
   */
  requestLimit?: number;
  /**
   * Useful for testing
   */
  debounceTime?: number;

  sourceLayers?: LocationPickerSourceLayer[];
  /**
   * User selected boundary types
   */
  selectedBoundaryTypes?: { name: string; value: string }[];
  /**
   * User selected US States and Territories
   */
  selectedCensusStates?: { name: string; value: string }[];
}
interface StateProps {
  disambiguationLayerId: LayerId;
  disambiguationQuery: Partial<FilterSpec>;
}

interface WithLayerSearchProps {
  disambiguationData?: RawSearchResult;
}

interface Item {
  title: ReactNode;
  header?: true;
  message?: true;
  location?: LocationRow;
  source: LocationPickerSourceLayer;
}

type Props = OwnProps & WithLayerSearchProps;

const DEFAULT_ITEM_SEARCH_QUERY = 'United States';
const DEFAULT_ITEM_SECTION_SOURCE_NAME = 'Regions';
const DEFAULT_ITEM_SUGGESTION_GEOID = 'conus_ak_hi';

const defaultLayerId: LayerId = '/uf_databank/databank/autocomplete_layer_v1';
const AUTOCOMPLETE_LAYER_PATH: LayerId =
  (process.env.UF_AUTOCOMPLETE_PROJECT_CREATION_LAYER as LayerId) ||
  defaultLayerId;

const popoverProps: IPopoverProps = {
  wrapperTagName: 'div',
  targetTagName: 'div',
  minimal: true,
  popoverClassName: classNames(
    rootStyles.maxHeightLarge,
    tw('tw-overflow-auto'),
  ),
};
const headerClassName = tw('tw-sticky', 'tw-top-0', 'tw-bg-white');
const DEBOUNCE_TIME = 100;

const mapUserFiltersToFilterExpression = (boundaryTypes, censusStates) => {
  return boundaryTypes.map(boundaryType => ({
    name: boundaryType,
    key: boundaryType,
    layerId: AUTOCOMPLETE_LAYER_PATH,
    columnFilter: {
      boundary_type: {
        columnMetatype: 'manual' as const,
        fn: 'in',
        filterValue: {
          value: {
            fn: 'column',
            key: 'boundary_type',
          },
          one_of: [boundaryType],
        },
      },
      statefp: {
        columnMetatype: 'manual',
        fn: 'like',
        filterValue: {
          value: {
            fn: 'column',
            key: 'statefp',
          },
          one_of: censusStates,
        },
      },
    },
  }));
};

export const LocationPicker: FunctionComponent<Props> = ({
  sourceLayers: passedSourceLayers,
  debounceTime = DEBOUNCE_TIME,
  requestLimit = 50,
  disambiguationProperty = 'statefp',
  placeholder = t`Type a location`,
  selectDefaultArea = false,
  disambiguationData,
  projectType,
  onChange,
  className,
  selectedBoundaryTypes,
  selectedCensusStates,
}) => {
  const [activeQueryString, setActiveQueryString] = useState('');
  const [selectedItem, setSelectedItem] = useState<Item | null>(null);
  const allBoundaryTypes = useBoundaryTypes();
  const boundaries = useMemo(
    () =>
      // If a user has selected boundary types or census states to filter by, use those
      selectedBoundaryTypes || allBoundaryTypes,
    [selectedBoundaryTypes, allBoundaryTypes],
  );
  const sourceLayers: LocationPickerSourceLayer[] = useMemo(() => {
    if (passedSourceLayers) {
      return passedSourceLayers;
    }
    return mapUserFiltersToFilterExpression(
      boundaries.map(type => type.value),
      selectedCensusStates?.map(state => state.value),
    );
  }, [passedSourceLayers, selectedCensusStates, boundaries]);
  const [sections, setSections] = useState<LocationPickerSection[]>([]);

  const disambiguationsById = useDisambiguations(disambiguationData);

  const onChangeCallback = useCallback(
    (item: Item | null) => {
      if (item) {
        onChange({
          layerId: item.source.layerId,
          feature: item.location,
          label: typeof item.title === 'string' ? item.title : t`(Missing)`,
        });
      } else {
        onChange(null);
      }
      setSelectedItem(item);
    },
    [onChange],
  );

  const getSuggestionValue = useCallback(
    (suggestion: LocationRow): string => {
      const { name, [disambiguationProperty]: disambiguationKey } = suggestion;
      // Look for disambiguation data, essentially checking if
      // disambiguationsById[suggestion[disambiguationProperty]] exists.
      const disambiguator = disambiguationKey
        ? disambiguationsById[disambiguationKey]
        : null;
      if (disambiguator?.name) {
        return `${name} (${disambiguator.name})`;
      }
      return name;
    },
    [disambiguationProperty, disambiguationsById],
  );
  const metadataById = useEnsureMetadata(sourceLayers);

  const { onSearch, busy, error } = useBeginSearch(
    requestLimit,
    sourceLayers,
    metadataById,
    setSections,
    debounceTime,
  );

  // Initial search to populate default project area
  useEffect(() => {
    if (onSearch && selectDefaultArea) {
      onSearch(DEFAULT_ITEM_SEARCH_QUERY);
    }
  }, [onSearch, selectDefaultArea]);

  // Reset selected item when project type changes
  useEffect(() => {
    onChangeCallback(null);
    setActiveQueryString('');
  }, [projectType, onChangeCallback]);

  // Select default area if project type is general and no area is selected
  useEffect(() => {
    if (selectDefaultArea && !selectedItem) {
      const sourceCategory = sections.find(
        section => section.title === DEFAULT_ITEM_SECTION_SOURCE_NAME,
      );
      if (sourceCategory) {
        const defaultItem = sourceCategory?.suggestions.find(
          suggestion => suggestion.geoid === DEFAULT_ITEM_SUGGESTION_GEOID,
        );
        if (defaultItem) {
          onChangeCallback({
            title: defaultItem.name,
            location: defaultItem,
            source: sourceCategory.source,
          });
          // Clear sections so users won't see the default area in the dropdown
          setSections([]);
        }
      }
    }
  }, [
    projectType,
    selectDefaultArea,
    onChangeCallback,
    selectedItem,
    sections,
  ]);

  const handleSearch = useCallback(
    (queryString: string) => {
      setActiveQueryString(queryString);
      onSearch(queryString);
    },
    [onSearch],
  );

  const renderSuggestion = useCallback(
    (
      result: LocationRow,
      modifiers: IItemModifiers,
      onClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void,
      query: string,
    ) => {
      const lowerQuery = query?.toLocaleLowerCase() ?? '';
      // Make sure to use the query that goes with the result, since the
      // results come in asynchronously.
      const value = getSuggestionValue(result);
      const queryPosition: number = value
        .toLocaleLowerCase()
        .indexOf(lowerQuery);
      if (queryPosition === -1) {
        warning(false, `Could not find ${lowerQuery} in ${value}`);
        return (
          <MenuItem
            active={modifiers.active}
            disabled={modifiers.disabled}
            text={value}
            onClick={onClick}
          />
        );
      }
      const leading = value.substring(0, queryPosition);
      const match = value.substring(queryPosition, lowerQuery.length);
      const trailing = value.substring(queryPosition + lowerQuery.length);
      const text = (
        <Fragment>
          {leading}
          <strong>{match}</strong>
          {trailing}
        </Fragment>
      );
      return (
        <MenuItem
          active={modifiers.active}
          disabled={modifiers.disabled}
          onClick={onClick}
          text={text}
        />
      );
    },
    [getSuggestionValue],
  );

  const inputProps: IInputGroupProps & HTMLInputProps = useMemo(
    () => ({
      placeholder,
      spellCheck: false,
      disabled: _.isEmpty(metadataById),
      rightElement: busy ? <Spinner size={SpinnerSize.SMALL} /> : null,
      className,
      fill: true,
      // Fire search request again on focus to handle filter changes
      ...(activeQueryString && {
        onFocus: () => handleSearch(activeQueryString),
      }),
    }),
    [
      busy,
      className,
      metadataById,
      placeholder,
      handleSearch,
      activeQueryString,
    ],
  );

  const itemRenderer = useCallback(
    (item: Item, itemProps: IItemRendererProps) => {
      if (item.header) {
        return <MenuDivider title={item.title} className={headerClassName} />;
      }
      if (item.message) {
        return <MenuItem disabled text={item.title} />;
      }
      return renderSuggestion(
        item.location,
        itemProps.modifiers,
        itemProps.handleClick,
        itemProps.query,
      );
    },
    [renderSuggestion],
  );

  const itemsWithSections = useMemo((): Item[] => {
    if (error) {
      const errorItem: Item = {
        message: true,
        source: null,
        title: <ErrorMessage error={error} />,
      };
      return [errorItem];
    }
    return sections.flatMap((section): Item[] => {
      if (!section.suggestions.length) {
        return [];
      }
      const header: Item = {
        title: section.title,
        header: true,
        source: section.source,
      };
      const items: Item[] = section.suggestions.map(suggestion => ({
        title: suggestion.name,
        location: suggestion,
        source: section.source,
      }));
      return [header, ...items];
    });
  }, [error, sections]);

  const itemValueRenderer = useCallback(
    (item: Item) => {
      return getSuggestionValue(item.location);
    },
    [getSuggestionValue],
  );

  return (
    <Suggest
      items={itemsWithSections}
      closeOnSelect
      inputValueRenderer={itemValueRenderer}
      itemRenderer={itemRenderer}
      query={activeQueryString}
      onQueryChange={handleSearch}
      popoverProps={popoverProps}
      initialContent={
        <MenuItem disabled text={t`Begin typing to find locations.`} />
      }
      itemDisabled={'header'}
      onItemSelect={onChangeCallback}
      inputProps={inputProps}
      selectedItem={selectedItem}
      // Don't display previously active item if field is cleared
    />
  );
};
export default compose<Props, OwnProps>(
  connect<StateProps, never, OwnProps>(
    /**
     * Pass some constants via connect instead of using defaultProps, because they need to be passed
     *  to WithLayerSearchData, and defaultProps can't be seen by hocs that wrap a component.
     *
     *     ┌────────────┐
     *     │  OwnProps  │
     *     └────────────┘
     *           │
     *           │
     * ┌─────────▼──────────┐
     * │                    │
     * │        HOC         │
     * │                    │
     * │ ┌────────────────┐ │
     * │ │ LocationPicker │ │
     * │ │                │ │
     * │ │ defaultProps:  │ │
     * │ │   foo: string  │ │
     * │ └────────────────┘ │
     * └────────────────────┘
     */
    (): StateProps => ({
      disambiguationLayerId: '/public/dataset/census_tiger_state',
      disambiguationQuery: {},
    }),
  ),
  withLayerSearchData(
    'disambiguationLayerId',
    'disambiguationQuery',
    'disambiguationData',
  ),
)(LocationPicker);
