/**
 * UrbanFootprint App API
 * API for serving UrbanFootprint Application
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Details about a user action that cannot be performed.
 */
export interface UserActionReasonDetail<> {
  /**
   * The maximum allowed features that can be exported in a layer.
   */
  max_export_feature_count?: number;
  /**
   * A key representing a more specific type of case when a user cannot perform an action.
   */
  reason_detail_type: UserActionReasonDetail.ReasonDetailTypeEnum;
}
export namespace UserActionReasonDetail {
  export type ReasonDetailTypeEnum = 'feature_count';
  export const ReasonDetailTypeEnum = {
    FeatureCount: 'feature_count',
  } as const;
}
