import { Dispatch } from 'redux';

import { setActiveProject } from 'uf/app/actions';
import { setUIProperty } from 'uf/explore/actions';
import { makeProjectId } from 'uf/projects/ids';
import { setActiveModule, setScenariosVisible } from 'uf/reporting/actions';
import { SetInitialRoute } from 'uf/routing/state';

export const setInitialReportRoute: SetInitialRoute = (
  location,
  query,
  dispatch: Dispatch,
) => {
  dispatch(setUIProperty('reportViewVisible', true));
  const { namespace, type: moduleKey, projectKey } = location.payload as any;
  const projectId = makeProjectId(namespace, projectKey);
  const { scenarios = '' } = query;
  const scenarioIds = scenarios ? scenarios.split(',') : [];
  const visible = scenarioIds.map(() => true);
  dispatch(setActiveProject(projectId));
  dispatch(setScenariosVisible(projectId, scenarioIds, visible));
  dispatch(setActiveModule(projectId, moduleKey));
};
