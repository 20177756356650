import { BoundingBox } from 'uf/base/geometry';
import { UFLngLatBounds } from 'uf/base/map';

/**
 * MapboxGL and the UF Frontend interpret map bounds as the SW and NE corners of the viewport.
 * However, the FilterSpec thinks about bounds as the NW and SE corners.
 *
 * If you have a UFLngLatBounds and need to make stats/filter calls with a bbox expression, you can
 * use this to convert between the two formats.
 * @param ufLngLatBounds
 */
export function toBoundingBox(ufLngLatBounds: UFLngLatBounds): BoundingBox {
  const [[swLng, swLat], [neLng, neLat]] = ufLngLatBounds;
  return {
    nw: { lng: swLng, lat: neLat },
    se: { lng: neLng, lat: swLat },
  };
}
