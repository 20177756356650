import _ from 'lodash';
import { createSelector } from 'reselect';

import { AnalysisParameterValidationError } from 'uf-api';
import { ModuleKey } from 'uf/analysis';
import {
  AnalysisParameterActionInfo,
  makeAnalysisParameterKey,
  ParameterKey,
} from 'uf/analysiseditor';
import { AnalysisEditorState, EditorState } from 'uf/analysiseditor/state';
import { makeGetApiArrayResults } from 'uf/api/selectors';
import { EMPTY_OBJECT } from 'uf/base';
import { parseFullPath } from 'uf/base/dataset';
import { makeGetFromPropsSelector } from 'uf/base/selector';
import { DataState } from 'uf/data/dataState';
import { ProjectId } from 'uf/projects';
import { ScenarioId } from 'uf/scenarios';

import { getAnalysisEditorState, getProjectParamsState } from './index';

const getAnalysisEditorEditingState = createSelector(
  getAnalysisEditorState,
  (editorState: AnalysisEditorState) => editorState.editors,
);

const getAnalysisEditorPreviewState = createSelector(
  getAnalysisEditorState,
  (editorState: AnalysisEditorState) => editorState.preview,
);

const getAnalysisEditorSavingState = makeGetApiArrayResults(
  'project',
  'update_scenario_analysis_params',
);

/**
 * Get all (unsaved) edits that the user has made.
 */
export function makeGetAnalysisEditorEdits() {
  return createSelector(
    getAnalysisEditorEditingState,
    makeGetFromPropsSelector<ModuleKey, 'moduleKey'>('moduleKey'),
    (editingState, moduleKey): Record<ScenarioId, EditorState> => {
      return editingState[moduleKey] || EMPTY_OBJECT;
    },
  );
}

/**
 * Gets all dirty edits for a given project and moduleKey.  compares the unsaved edits with the last
 * saved values return from the server.
 */
export function makeGetAnalysisEditorDirtyEdits() {
  return createSelector(
    getAnalysisEditorEditingState,
    getProjectParamsState,
    makeGetFromPropsSelector<ProjectId, 'projectId'>('projectId'),
    makeGetFromPropsSelector<string, 'moduleKey'>('moduleKey'),
    (
      editingState,
      paramsState,
      projectId,
      moduleKey,
    ): Record<
      ModuleKey,
      Record<ScenarioId, Record<ParameterKey, string | boolean | number>>
    > => {
      const dirtyEdits = {};
      const moduleEdits = editingState[moduleKey];

      _.forEach(moduleEdits, (scenario: EditorState, scenarioId) => {
        const { key: scenarioKey } = parseFullPath(scenarioId);
        const editValues = scenario?.data?.parameters;

        const analysisParamKey = makeAnalysisParameterKey(
          projectId,
          scenarioId,
          moduleKey,
        );
        const params = paramsState[analysisParamKey];

        const moduleParams = params?.data?.scenario_module_params;

        const scenarioModuleParams = moduleParams?.find(
          s => s.key === scenarioKey,
        );

        const paramValues = scenarioModuleParams?.module_params?.parameters;

        _.forEach(editValues, (editValue, paramKey) => {
          if (!paramValues || editValue !== paramValues[paramKey]) {
            _.set(dirtyEdits, [moduleKey, scenarioId, paramKey], editValue);
          }
        });
      });

      return dirtyEdits;
    },
  );
}

/**
 * returns an array of all preview error states for a given project and moduleKey.
 * The scenarioId, projectId and moduleKey is contained in each response.
 */
export function makeGetModulePreviewErrorStates() {
  return createSelector(
    getAnalysisEditorPreviewState,
    makeGetFromPropsSelector<ProjectId, 'projectId'>('projectId'),
    makeGetFromPropsSelector<string, 'moduleKey'>('moduleKey'),
    (
      previewState,
      projectId,
      moduleKey,
    ): DataState<
      AnalysisParameterValidationError[],
      AnalysisParameterActionInfo
    >[] => _.filter(previewState, ({ extra }) => extra.moduleKey === moduleKey),
  );
}

/**
 *  gets all preview errors for a module in a project
 */
export function makeGetModulePreviewErrors() {
  return createSelector(
    makeGetModulePreviewErrorStates(),
    (previewStates): AnalysisParameterValidationError[] =>
      _(previewStates)
        .map(({ data }) => data)
        .flatten()
        .compact() // some responses have no errors so 'data' is undefined
        .value(),
  );
}

export function makeGetAnalysisEditorPreviews() {
  return createSelector(
    getAnalysisEditorPreviewState,
    makeGetFromPropsSelector<ProjectId, 'projectId'>('projectId'),
    makeGetFromPropsSelector<ModuleKey, 'moduleKey'>('moduleKey'),
    (
      previewState,
      projectId,
      moduleKey,
    ): Record<
      string,
      DataState<AnalysisParameterValidationError[], AnalysisParameterActionInfo>
    > => {
      return _.pickBy(
        previewState,
        ({ extra }) =>
          extra.moduleKey === moduleKey && extra.projectId === projectId,
      );
    },
  );
}

export function makeGetAnalysisEditorSaves() {
  return createSelector(
    getAnalysisEditorSavingState,
    makeGetFromPropsSelector<ProjectId, 'projectId'>('projectId'),
    makeGetFromPropsSelector<ModuleKey, 'moduleKey'>('moduleKey'),
    (
      savingStates,
      projectId,
      moduleKey,
    ): DataState<
      AnalysisParameterValidationError[],
      AnalysisParameterActionInfo
    >[] => {
      const c = savingStates.filter(saveState => {
        const { extra } = saveState;
        return extra.moduleKey === moduleKey && extra.projectId === projectId;
      });
      return c;
    },
  );
}
