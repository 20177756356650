import {
  CuratedColumnSymbology,
  DistributionTypes,
  InterpolationTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const censusPlanningdatabaseIncomeLayers = [
  {
    layerId: '/public/dataset/us_census_census_planning_database_2018_income',
    columnKey: 'avg_agg_hh_inc_acs_12_16',
  },
  {
    layerId: '/public/dataset/us_census_census_planning_database_2019_income',
    columnKey: 'avg_agg_hh_inc_acs_13_17',
  },
  {
    layerId: '/public/databank/cpd_2020_income',
    columnKey: 'avg_agg_hh_inc_acs_14_18',
  },
  {
    layerId: '/public/databank/cpd_2021_income',
    columnKey: 'avg_agg_hh_inc_acs_15_19',
  },
];

const symbologies: Record<
  string,
  Record<string, CuratedColumnSymbology[]>
> = {};
censusPlanningdatabaseIncomeLayers.forEach(({ layerId, columnKey }) => {
  symbologies[layerId] = {
    [columnKey]: [
      {
        display_hints: {
          distribution: DistributionTypes.DEFAULT,
          interpolation: InterpolationTypes.INTERVAL,
          scale: SymbologyScales.NUMERIC,
          showZero: true,
          theme: 'uf:#8C5109,#D9B365,#F6E8C3,#F5F5F5,#C7EAE5,#59B4AC,#01665E',
        },
        paint: {
          'fill-color': {
            property: columnKey,
            stops: [
              {
                color: 'rgb(140, 81, 9)',
                value: 40000,
              },
              {
                color: 'rgb(184, 138, 64)',
                value: 50000,
              },
              {
                color: 'rgb(219, 186, 120)',
                value: 60000,
              },
              {
                color: 'rgb(238, 218, 172)',
                value: 70000,
              },
              {
                color: 'rgb(244, 236, 213)',
                value: 80000,
              },
              {
                color: 'rgb(238, 241, 234)',
                value: 90000,
              },
              {
                color: 'rgb(215, 237, 233)',
                value: 100000,
              },
              {
                color: 'rgb(172, 219, 214)',
                value: 125000,
              },
              {
                color: 'rgb(113, 189, 182)',
                value: 150000,
              },
              {
                color: 'rgb(55, 148, 140)',
                value: 175000,
              },
              {
                color: 'rgb(1, 102, 94)',
                value: 200000,
              },
            ],
            type: 'interval',
          },
          'fill-opacity': 0.5,
        },
        type: SymbologyTypes.FILL,
      },
      {
        display_hints: {
          distribution: DistributionTypes.DEFAULT,
          interpolation: InterpolationTypes.INTERVAL,
          scale: SymbologyScales.NUMERIC,
          showZero: true,
          theme: 'custom',
        },
        paint: {
          'line-color': '#333333',
          'line-opacity': 0.5,
          'line-width': 0.5,
        },
        type: SymbologyTypes.LINE,
      },
    ],
  };
});

export default symbologies;
