import { createSelector } from 'reselect';

import { makeGetFromPropsSelector } from 'uf/base/selector';
import { DataState } from 'uf/data/dataState';
import { ColumnKey, LayerId } from 'uf/layers';
import { ProjectId } from 'uf/projects';
import { LayerColumnSymbology } from 'uf/symbology';
import {
  makeGetCuratedSymbologyState,
  makeGetCuratedSymbologyStateGetter,
} from 'uf/symbology/selectors/curated';
import {
  makeGetDefaultSymbologyState,
  makeGetDefaultSymbologyStateGetter,
} from 'uf/symbology/selectors/defaultSymbology';
import { getMergedSymbologyState } from './merge';

/**
 * The system's symbology, either generated or curated-- no user saved stuff
 * here!
 */
export function makeGetBaseSymbologyState() {
  return createSelector(
    makeGetCuratedSymbologyState(),
    makeGetDefaultSymbologyState(),
    makeGetFromPropsSelector<ProjectId, 'projectId'>('projectId'),
    makeGetFromPropsSelector<LayerId, 'layerId'>('layerId'),
    makeGetFromPropsSelector<ColumnKey, 'columnKey'>('columnKey'),
    (
      curatedSymbologyState,
      defaultSymbologyState,
    ): DataState<LayerColumnSymbology[]> => {
      return getMergedSymbologyState(
        curatedSymbologyState,
        defaultSymbologyState,
      );
    },
  );
}
export function makeGetBaseSymbologyStateGetter() {
  return createSelector(
    makeGetCuratedSymbologyStateGetter(),
    makeGetDefaultSymbologyStateGetter(),
    (getCuratedSymbologyState, getDefaultSymbologyState) =>
      (
        projectId: ProjectId,
        layerId: LayerId,
        columnKey: ColumnKey,
      ): DataState<LayerColumnSymbology[]> => {
        const curatedSymbologyState = getCuratedSymbologyState(
          projectId,
          layerId,
          columnKey,
        );
        const defaultSymbologyState = getDefaultSymbologyState(
          projectId,
          layerId,
          columnKey,
        );
        return getMergedSymbologyState(
          curatedSymbologyState,
          defaultSymbologyState,
        );
      },
  );
}
