/**
 * UrbanFootprint App API
 * API for serving UrbanFootprint Application
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * A reason why validating failed.
 */
export interface BuiltFormValidationError<Tparams extends object = any> {
  /**
   * Why the field is in error.
   */
  code?: BuiltFormValidationError.CodeEnum;
  /**
   * The name of the field in error.
   */
  field?: string;
  /**
   * Code specific parameters for the error.
   */
  params?: Tparams;
}
export namespace BuiltFormValidationError {
  export type CodeEnum = 'allocation' | 'empty' | 'invalid_subcomponents';
  export const CodeEnum = {
    Allocation: 'allocation',
    Empty: 'empty',
    InvalidSubcomponents: 'invalid_subcomponents',
  } as const;
}
