import { mdiOpenInNew } from '@mdi/js';
import Icon from '@mdi/react';
import { Href } from 'history';
import React from 'react';

const DEFAULT_ICON_SIZE = 12;

interface OwnProps {
  /**
   * Link text
   */
  text: string;
  /**
   * A size value in pixels
   */
  iconSize?: string | number;
  /**
   * Whether to display the external link icon
   */
  showIcon?: boolean;
}

interface LinkProps extends OwnProps {
  /**
   * External link destination
   */
  href: Href;
  onClick?: never;
}

interface ButtonProps extends OwnProps {
  href?: never;
  /**
   * Optional callback in lieu of href
   */
  onClick: () => void;
}

/**
 * A link component for usage inline with other text
 */
const InlineLink = ({
  href,
  text,
  iconSize = DEFAULT_ICON_SIZE,
  showIcon = false,
  onClick,
}: LinkProps | ButtonProps): JSX.Element => {
  /**
   * Allow keydown interactions to fire onClick. Having a keydown handler is required by
   * our eslint configuration when a click handler is applied to a non-interactive element.
   * This is handled by default when an href is supplied.
   */
  const handleKeyDown = ({ key }) => {
    if (key === 'Enter' && onClick) {
      onClick();
    }
  };
  const role = href ? 'link' : 'button';
  return (
    <a
      aria-label={`${text}-${role}`}
      role={role}
      tabIndex={0}
      style={{ color: '#106ba3' }}
      onClick={onClick}
      onKeyDown={handleKeyDown}
      {...(!!href && {
        href,
        target: '_blank',
        rel: 'noopener noreferrer',
      })}>
      <span>{text}</span>
      {showIcon && (
        <Icon
          style={{ display: 'inline', marginLeft: 2 }}
          path={mdiOpenInNew}
          // Enforce pixel units (plain numbers function as a rem multiplier)
          size={`${Number(iconSize)}px`}
        />
      )}
    </a>
  );
};

export default InlineLink;
