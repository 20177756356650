import { FilterSpec } from 'uf/layers/filters';
import { TaskMessage } from 'uf/tasks/MessageTypes';
import { CombinedTaskState } from 'uf/tasks/state';
import { ZoomExtentState } from './reducer/zoomExtentSlice';

declare module 'uf/state' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface UFState {
    layers: LayersState;
  }
}

export type LayerColumnFilters = Record<string, Partial<FilterSpec>>;

export interface LayersState {
  export: CombinedTaskState<string, TaskMessage>;
  versions: Record<string, string>;
  zoomExtent: ZoomExtentState;
}

export enum LayerRole {
  CONTEXT_AREA = 'context_area',
  PROJECT_AREA = 'project_area',
  BASE_CANVAS = 'base_canvas',
  SCENARIO_CANVAS = 'scenario_canvas',
  REFERENCE = 'reference',
  ANALYSIS = 'analysis',
  WORKING = 'working',
  UNKNOWN = 'unknown',
}
