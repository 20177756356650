import colors from 'material-colors';

import {
  CuratedColumnSymbology,
  CUSTOM_THEME,
  DEFAULT_OPACITY,
  InterpolationTypes,
  PaintColorTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

import MPORegionList from './mpo_regions';

const symbologies: Record<
  string,
  Record<string, CuratedColumnSymbology[]>
> = {};

MPORegionList.forEach(regionList => {
  symbologies[`/public/dataset/california_ag_water_demand_${regionList}`] = {
    raster_val: [
      {
        type: SymbologyTypes.FILL,
        paint: {
          'fill-color': {
            property: 'raster_val',
            type: PaintColorTypes.CATEGORICAL,
            stops: [
              { value: 1, label: 'Less than 1', color: '#cceae7' },
              { value: 2, label: '1 - 2', color: '#99d5cf' },
              { value: 3, label: '2 - 3', color: '#66c0b7' },
              { value: 4, label: '3 - 4', color: '#32ab9f' },
              { value: 5, label: 'Greater than 4', color: colors.teal[500] },
            ],
          },
          'fill-opacity': DEFAULT_OPACITY,
        },
        display_hints: {
          scale: SymbologyScales.CATEGORICAL,
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          theme: CUSTOM_THEME,
        },
      },
    ],
  };
});

export default symbologies;
