import { combineReducers } from 'redux';

import { ReportResults } from 'uf-api';
import {
  getReportActionTypes,
  getReportXlsActionTypes,
} from 'uf-api/api/report.service';
import {
  makeKeyedReducer,
  makeSetManyKeyedValuesReducer,
  makeSetValueOnlyReducer,
} from 'uf/base/reducerFactories';
import { makeKeyedPromiseReducer } from 'uf/data/makeKeyedPromiseReducer';
import { ReportingState } from 'uf/reporting/state';

import {
  SET_ACTIVE_MODULE,
  SET_SCENARIOS_VISIBLE,
  SetActiveModuleAction,
} from './ActionTypes';

const reports = makeKeyedPromiseReducer<ReportResults>(getReportActionTypes);
const exportReducer = makeKeyedPromiseReducer<Blob>(getReportXlsActionTypes);

const activeModule = makeSetValueOnlyReducer<string, SetActiveModuleAction>(
  SET_ACTIVE_MODULE,
);

export default combineReducers<ReportingState>({
  reports,
  export: exportReducer,
  scenariosVisible: makeKeyedReducer(
    makeSetManyKeyedValuesReducer(
      SET_SCENARIOS_VISIBLE,
      ['scenarioIds'],
      ['visible'],
    ),
    'projectId',
  ),
  activeModule: makeKeyedReducer(activeModule, 'projectId'),
});
