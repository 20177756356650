import Rollbar from 'rollbar';
import warning from 'warning';

// The rollbar instance should never be exported
let rollbarInstance: Rollbar = null;

export function initializeRollbar() {
  if (!process.env.UF_ROLLBAR_POST_CLIENT_ITEM_API_KEY) {
    warning(
      __DEVELOPMENT__,
      'Possible misconfiguration: Rollbar is NOT enabled',
    );
    return;
  }
  rollbarInstance = new Rollbar({
    autoInstrument: {
      // This totally bogs down the CPU when the redux logger is doing console.log()
      log: !process.env.UF_SHOW_REDUX_LOGGER,
    },
    accessToken: process.env.UF_ROLLBAR_POST_CLIENT_ITEM_API_KEY,
    captureUncaught: true,
    captureUnhandledRejections: true,
    environment: process.env.UF_ENV_NAME,
    // Temporarily remove scrubbing to see if it stops the non-responsiveness
    scrubTelemetryInputs: false,
    payload: {
      server: {
        root: 'webpack:///./',
        branch: __BUILD_INFO__.git_branch,
      },
      client: {
        javascript: {
          source_map_enabled: true,
          guess_uncaught_frames: true,
          code_version: __BUILD_INFO__.git_commit,
        },
      },
    },
  });
}

export function configureRollbarUser(
  id: string,
  username: string,
  email: string,
) {
  if (!rollbarInstance) {
    return;
  }

  rollbarInstance.configure({
    payload: {
      person: {
        id,
        username,
        email,
      },
    },
  });
}

export function setRollbarContextProperty(property: string, value: string) {
  if (!rollbarInstance) {
    return;
  }
  // rollbar APIs are kinda finnicky here.
  const payload: any = rollbarInstance.options.payload;
  rollbarInstance.configure({
    payload: {
      ...payload,
      context: {
        ...payload.context,
        [property]: value,
      },
    },
  });
}

export function rollbarError(...args: any) {
  if (!rollbarInstance) {
    console.warn('Would report to rollbar in production:', ...args);
    return;
  }
  rollbarInstance.error(...args);
}

export function rollbarWarn(...args: any) {
  if (!rollbarInstance) {
    console.warn('Would warn using rollbar in production:', ...args);
    return;
  }
  rollbarInstance.warn(...args);
}
