import { ofType } from 'redux-observable';
import { pipe } from 'rxjs';
import { filter } from 'rxjs/operators';

import { makeWebsocketActionType } from 'uf/websockets/actionHelpers';

import { NotificationAction } from './ActionTypes';
import { TaskMessage } from './MessageTypes';
import { NotificationTypes } from './NotificationTypes';
import { TaskStatuses } from './TaskStatuses';
import { TaskTypes } from './TaskTypes';

const TaskNotificationActionType = makeWebsocketActionType(
  NotificationTypes.TASK_NOTIFICATION,
);
export function ofNotificationType<T extends TaskMessage>(
  taskType: TaskTypes,
  taskStatus?: TaskStatuses,
) {
  if (taskStatus) {
    return pipe(
      ofType(TaskNotificationActionType),
      filter(
        (action: NotificationAction<T>) => action.result.task_type === taskType,
      ),
      filter(action => action.result.status === taskStatus),
    );
  }
  return pipe(
    ofType(TaskNotificationActionType),
    filter(
      (action: NotificationAction<T>) => action.result.task_type === taskType,
    ),
  );
}
