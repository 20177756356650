import _ from 'lodash';

/**
 * Helper function that takes a dictionary and prefixes each key
 * with 'data-' for use as HTML5 data attributes.
 *
 * Note that capitalization will not be preserved when the
 * attributes are attached to a dom node.
 */
export default function makeDataAttributes(dataAttributes: any = {}) {
  return _.mapKeys(
    dataAttributes,
    (unusedValue, key) => `data-${key.toLowerCase()}`,
  );
}
