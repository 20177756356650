import { AnyAction } from 'redux';
import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import {
  concat as observableConcat,
  defer as observableDefer,
  empty,
  forkJoin,
  Observable,
  of as observableOf,
} from 'rxjs';
import { catchError, filter, first, map, mergeMap } from 'rxjs/operators';

import { SwaggerClient } from 'uf/base/xhr';
import {
  FailureAction,
  LoadAction,
  LoadActionTypes,
  SuccessAction,
  UpdateStateAction,
} from 'uf/data/ActionTypes';
import { DataState, isLoaded, isLoading } from 'uf/data/dataState';

import { makeRequestId } from './helpers';

/**
 * A function that returns an observable stream of async actions for fetching data.
 *
 * @typeparam T the data type returned from the api call
 * @typeparam E 'extra' props to add to the async actions
 * @param asyncActions - LOAD, SUCCESS, FAILURE actions for data fetching
 * @param apiCall - a function that returns a promise of the data
 * @param extra - 'extra' props to add to the asyncActions
 */
export function getFetchStream<T, E = any>(
  asyncActions: LoadActionTypes,
  apiCall: () => Promise<T>,
  extra?: E,
): Observable<UpdateStateAction<T>> {
  const promise: Promise<T> = apiCall();
  const requestTime = new Date().toISOString();
  const requestId = makeRequestId();
  const loadAction: LoadAction = {
    type: asyncActions.LOAD,
    promise,
    ...extra,
    requestTime,
    responseTime: null,
    requestId,
  };

  const promiseObserver = () =>
    forkJoin<T>([promise]).pipe(
      map(results => {
        const [result] = results;
        const successAction: SuccessAction<T> = {
          type: asyncActions.SUCCESS,
          result,
          requestTime,
          responseTime: new Date().toISOString(),
          requestId,
          ...extra,
        };
        return successAction;
      }),
      catchError(error => {
        const failureAction: FailureAction = {
          type: asyncActions.FAILURE,
          error,
          requestTime,
          responseTime: new Date().toISOString(),
          requestId,
          ...extra,
        };

        return observableOf(failureAction);
      }),
    );
  const actions = [
    observableOf(loadAction),
    observableDefer(() => promiseObserver()),
  ];
  // concat will will wait for the result of each observable before moving on to the next
  return observableConcat(...actions);
}

/**
 * Create a new stream that is either a no-op, or dispatches LOAD/SUCCESS events
 * when a stream loads. This is meant to be used inside of another epic.
 *
 * @param action$
 * @param loadActionTypes The action types to dispatch with
 * @param getDataState Return a DataState to check if somethign is loaded
 * @param apiCall Make the API call
 * @param extra Generate any extra to be added to the DataState
 */
function ensureFetchStream<T, EP = never>(
  action$: ActionsObservable<AnyAction>,
  loadActionTypes: LoadActionTypes,
  getDataState: () => DataState<T>,
  apiCall: () => Promise<T>,
  extra: EP,
) {
  const dataState = getDataState();
  if (isLoaded(dataState)) {
    return empty();
  }

  // Since it's loading, wait until the first matching success
  if (isLoading(dataState)) {
    const { requestId: currentRequestId } = dataState;
    return action$.pipe(
      ofType<SuccessAction<T> | FailureAction>(
        loadActionTypes.SUCCESS,
        loadActionTypes.FAILURE,
      ),
      filter(loadAction => {
        const { requestId } = loadAction;
        return requestId === currentRequestId;
      }),
      first(),
      // Emit an empty stream, since the SUCCESS should be emitted by earlier
      // calls to getFetchStream
      mergeMap(() => empty()),
    );
  }

  // otherwise we need to fetch the necessary data.
  const fetch$ = getFetchStream<T>(loadActionTypes, () => apiCall(), extra);

  return fetch$;
}

type EnsureFn<S, P, A> = (
  action$: ActionsObservable<AnyAction>,
  state$: StateObservable<S>,
  client: SwaggerClient,
  params: P,
) => Observable<A>;
/**
 * Factory to create an "ensure" style stream for a given api.
 *
 * The result of this may be stored at the toplevel:
 *
 * ```
 * const ensureFooStream = makeEnsureFetchStream(
 *   fooActionTypes, // LOAD/SUCCESS/FAILURE action type map
 *   makeGetFoo(), // selector get to get it from the state
 *   fetchFoo, // API caller to actually make network call
 *   makeExtraFoo, // function to generate extra for the actions
 * );
 * ```
 *
 * And then you can use the result in an epic:
 * ```
 * function myEpic(action$, state$, { client }) {
 *   return action$.pipe(
 *     ofType(DO_SOMETHING)
 *
 *     mergeMap(somethingAction =>
 *       ensureFooStream(
 *         action$,
 *         state$,
 *         client,
 *         { layerId: somethingAction.layerId }))
 *   );
 * }
 * ```
 * @param loadActionTypes
 * @param makeSelector
 * @param fetchValue
 * @param makeExtra
 */
// TODO: ownProps and params should not share the same type
export function makeEnsureFetchStream<S, P, T, E>(
  loadActionTypes: LoadActionTypes,
  makeSelector: () => (state: S, ownProps: P) => DataState<T>,
  fetchValue: (params: P) => (client: SwaggerClient) => Promise<T>,
  makeExtra: (params: P) => E,
): EnsureFn<S, P, UpdateStateAction<T>> {
  const selector = makeSelector();
  return (
    action$: ActionsObservable<AnyAction>,
    state$: StateObservable<S>,
    client: SwaggerClient,
    params: P,
  ) => {
    const fetcher = fetchValue(params);
    return ensureFetchStream<T, E>(
      action$,
      loadActionTypes,
      () => selector(state$.value, params),
      () => fetcher(client),
      makeExtra(params),
    );
  };
}
