import colors from 'material-colors';

import {
  CuratedColumnSymbology,
  CUSTOM_THEME,
  DEFAULT_OPACITY,
  InterpolationTypes,
  PaintColorTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/public/dataset/census_tiger_arealm': {
    mtfcc: [
      {
        type: SymbologyTypes.FILL,
        paint: {
          'fill-color': {
            property: 'mtfcc',
            type: PaintColorTypes.CATEGORICAL,
            stops: [
              {
                value: 'K2540',
                label: 'University or College',
                color: '#bcd3ff',
              },
              {
                value: 'K2451',
                label: 'Airport or Airfield',
                color: '#dedcd9',
              },
              {
                value: 'K2456',
                label: 'Airport—Intermodal Transportation Hub/Terminal',
                color: '#f4ecdc',
              },
              {
                value: 'K2457',
                label: 'Airport—Statistical Representation',
                color: '#f5831b',
              },
              { value: 'K2180', label: 'Park', color: '#002a00' },
              {
                value: 'K2181',
                label: 'National Park Service Land',
                color: '#005300',
              },
              {
                value: 'K2182',
                label: 'National Forest or Other Federal Land',
                color: '#016501',
              },
              {
                value: 'K2183',
                label: 'Tribal Park, Forest, or Recreation Area',
                color: '#038500',
              },
              {
                value: 'K2184',
                label: 'State Park, Forest, or Recreation Area',
                color: '#2b9900',
              },
              {
                value: 'K2185',
                label: 'Regional Park, Forest, or Recreation Area',
                color: '#54ad00',
              },
              {
                value: 'K2186',
                label: 'County Park, Forest, or Recreation Area',
                color: '#81c300',
              },
              {
                value: 'K2187',
                label: 'County Subdivision Park, Forest, or Recreation Area',
                color: '#a0d300',
              },
              {
                value: 'K2188',
                label: 'Incorporated Place Park, Forest, or Recreation Area',
                color: '#bde100',
              },
              {
                value: 'K2189',
                label: 'Private Park, Forest, or Recreation Area',
                color: '#e2f400',
              },
              {
                value: 'K2190',
                label:
                  'Other Park, Forest, or Recreation Area (quasi-public, independent park, commission, etc.)',
                color: colors.yellow[400],
              },
              {
                value: 'K1231',
                label: 'Hospital/Hospice/Urgent Care Facility',
                color: '#d71929',
              },
              { value: 'K2165', label: 'Government Center', color: '#efcac4' },
              {
                value: 'K1235',
                label: 'Juvenile Institution',
                color: '#cd2620',
              },
              {
                value: 'K1236',
                label: 'Local Jail or Detention Center',
                color: '#f7d488',
              },
              {
                value: 'K1237',
                label: 'Federal Penitentiary, State Prison, or Prison Farm',
                color: '#ff5f22',
              },
              { value: 'C3023', label: 'Island', color: '#ecd27d' },
              {
                value: 'C3026',
                label: 'Quarry (not water-filled), Open Pit Mine or Mine',
                color: '#2b3b8e',
              },
              {
                value: 'K1121',
                label: 'Apartment Building or Complex',
                color: '#fe31c7',
              },
              {
                value: 'K1223',
                label: 'Trailer Court or Mobile Home Park',
                color: '#ee5f9d',
              },
              {
                value: 'K1234',
                label: 'County Home or Poor Farm',
                color: '#f498c1',
              },
              {
                value: 'K1238',
                label: 'Other Correctional Institution',
                color: '#fc8e3b',
              },
              {
                value: 'K1239',
                label:
                  'Convent, Monastery, Rectory, Other Religious Group Quarters',
                color: '#fffd50',
              },
              { value: 'K2100', label: 'Governmental', color: '#ab5593' },
              { value: 'K2146', label: 'Community Center', color: '#8c4ea4' },
              {
                value: 'K2300',
                label: 'Commercial Workplace',
                color: '#541a57',
              },
              {
                value: 'K2361',
                label: 'Shopping Center or Major Retail Center',
                color: '#532f75',
              },
              {
                value: 'K2362',
                label: 'Industrial Building or Industrial Park',
                color: '#373673',
              },
              {
                value: 'K2363',
                label: 'Office Building or Office Park',
                color: '#1b3776',
              },
              {
                value: 'K2364',
                label: 'Farm/Vineyard/Winery/Orchard',
                color: '#463f8d',
              },
              {
                value: 'K2366',
                label: 'Other Employment Center',
                color: '#fff3ae',
              },
              {
                value: 'K2400',
                label: 'Transportation Terminal',
                color: '#ffe874',
              },
              { value: 'K2424', label: 'Marina', color: '#ffe874' },
              { value: 'K2432', label: 'Pier/Dock', color: '#0088d1' },
              {
                value: 'K2452',
                label: 'Train Station, Trolley or Mass Transit Rail Station',
                color: '#ffe241',
              },
              { value: 'K2453', label: 'Bus Terminal', color: '#fed801' },
              { value: 'K2454', label: 'Marine Terminal', color: '#005299' },
              { value: 'K2455', label: 'Seaplane Anchorage', color: '#00296b' },
              {
                value: 'K2458',
                label: 'Park and Ride Facility/Parking Lot',
                color: '#cf8600',
              },
              { value: 'K2543', label: 'School or Academy', color: '#bcd3ff' },
              {
                value: 'K2545',
                label:
                  'Museum, Visitor Center, Cultural Center, or Tourist Attraction',
                color: '#b55b00',
              },
              { value: 'K2561', label: 'Golf Course', color: '#93cc93' },
              { value: 'K2564', label: 'Amusement Center', color: '#8d1700' },
              { value: 'K2582', label: 'Cemetery', color: '#332d36' },
              { value: 'K2586', label: 'Zoo', color: '#3498db' },
              { value: 'K3544', label: 'Place of Worship', color: '#8e44ad' },
            ],
          },
          'fill-opacity': DEFAULT_OPACITY,
        },
        display_hints: {
          scale: SymbologyScales.CATEGORICAL,
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          theme: CUSTOM_THEME,
        },
      },
    ],
  },
};

export default symbologies;
