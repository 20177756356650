import { runAnalysisModuleForProjectActionTypes } from 'uf-api/api/project.service';
import { ModuleRunStatus } from 'uf/analysis';
import { fetchRunAnalysis } from 'uf/analysis/actions/apis';
import { SET_ANALYSIS_STATUS } from 'uf/analysis/ActionTypes';
import { dispatchAsyncAction } from 'uf/data/loader';
import { ProjectId } from 'uf/projects';
import { ScenarioId } from 'uf/scenarios';

/**
 * run an analysis module for a scenario in a project.
 * @param moduleKey the key of the analysis module to ru for.
 * @param projectId the id of the project to run the analysis module for.
 * @param scenarioId the id of the scenario to run the analysis module for.
 */
export function runAnalysis(
  moduleKey: string,
  projectId: ProjectId,
  scenarioId: ScenarioId,
) {
  return dispatchAsyncAction(
    runAnalysisModuleForProjectActionTypes,
    fetchRunAnalysis(moduleKey, projectId, scenarioId),
    { projectId, moduleKey, scenarioId },
  );
}

export function setAnalysisStatus(
  projectId: ProjectId,
  moduleKey: string,
  scenarioId: ScenarioId,
  status: ModuleRunStatus,
) {
  return {
    type: SET_ANALYSIS_STATUS,
    projectId,
    scenarioId,
    moduleKey,
    status,
  };
}
