import _ from 'lodash';
import { createSelector } from 'reselect';

import { EMPTY_ARRAY } from 'uf/base';
import { makeGetFromPropsSelector } from 'uf/base/selector';
import { getData } from 'uf/data/dataState';
import { ColumnKey, LayerId } from 'uf/layers';
import { getUfGeometryType } from 'uf/layers/helpers';
import { makeGetLayerMetadataState } from 'uf/layers/selectors/metadata';
import { ProjectId } from 'uf/projects';
import { LegacyVirtualLayerId } from 'uf/projects/virtualLayers';
import { DataDrivenColorStop } from 'uf/symbology';
import { getCanonicalSymbology } from 'uf/symbology/helpers';
import { makeGetBaseOrUserSymbologyState } from 'uf/symbology/selectors/combinedSymbologies';
import { getPaintProperty, isDataDriven } from 'uf/symbology/spec/paint';
import { ViewId } from 'uf/views';
import { makeGetViewOverrideSymbologyState } from 'uf/views/selectors/symbology';

/**
 * Returns the color stops that have override values different from the
 * base symbology.
 */
export function makeGetColorStopEdits() {
  return createSelector(
    makeGetBaseOrUserSymbologyState(),
    makeGetViewOverrideSymbologyState(),
    makeGetLayerMetadataState(),
    makeGetFromPropsSelector<ProjectId, 'projectId'>('projectId'),
    makeGetFromPropsSelector<ViewId, 'viewId'>('viewId'),
    makeGetFromPropsSelector<LayerId, 'layerId'>('layerId'),
    makeGetFromPropsSelector<LegacyVirtualLayerId, 'virtualLayerId'>(
      'virtualLayerId',
    ),
    makeGetFromPropsSelector<ColumnKey, 'columnKey'>('columnKey'),
    (
      baseOrUserSymbologyState,
      overrideSymbology,
      layerMetadataState,
    ): DataDrivenColorStop[] => {
      if (!overrideSymbology.length) {
        return EMPTY_ARRAY;
      }

      const finalBaseSymbology = getData(baseOrUserSymbologyState);
      if (!finalBaseSymbology.length) {
        return EMPTY_ARRAY;
      }

      const layerMetadata = getData(layerMetadataState);
      const ufGeometryType = getUfGeometryType(layerMetadata);

      const symbologyColorProperty = getPaintProperty(
        getCanonicalSymbology(finalBaseSymbology, ufGeometryType),
        'color',
      );

      const overrideSymbologyColorProperty = getPaintProperty(
        getCanonicalSymbology(overrideSymbology, ufGeometryType),
        'color',
      );

      if (
        !isDataDriven(symbologyColorProperty) ||
        !isDataDriven(overrideSymbologyColorProperty)
      ) {
        return EMPTY_ARRAY;
      }

      const edits = _.differenceWith<DataDrivenColorStop, DataDrivenColorStop>(
        overrideSymbologyColorProperty.stops,
        symbologyColorProperty.stops,
        (overrideStop, symbologyStop) =>
          overrideStop.value === symbologyStop.value &&
          overrideStop.color === symbologyStop.color,
      );

      if (!edits.length) {
        return EMPTY_ARRAY;
      }

      return edits;
    },
  );
}

export function makeGetColorStopEditsGetter() {
  const getColorStopEdits = makeGetColorStopEdits();
  return createSelector(
    // TODO: fix this
    state => state,
    state =>
      (
        projectId: ProjectId,
        viewId: ViewId,
        layerId: LayerId,
        virtualLayerId: LegacyVirtualLayerId,
        columnKey: ColumnKey,
      ) =>
        getColorStopEdits(state, {
          projectId,
          viewId,
          layerId,
          virtualLayerId,
          columnKey,
        }),
  );
}
