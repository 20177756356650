import { Button, Popover, Position, Tooltip } from '@blueprintjs/core';
import { mdiDotsHorizontal, mdiDotsVertical } from '@mdi/js';
import Icon from '@mdi/react';
import { boundMethod } from 'autobind-decorator';
import classNames from 'classnames';
import React, { Component, createRef, MouseEventHandler } from 'react';
import { t } from 'ttag';

import rootStyles from 'uf/styles/root.module.css';
import { IconSizeNew } from 'uf/ui/base/icon';
import { GetComponentProps } from 'uf/ui/base/types';

interface Props {
  onClick?: MouseEventHandler;
  className?: string;
  dataAttributes?: any;
  overlayPlacement?: Position;
  menuPlacement?: Position;
  tooltipText?: JSX.Element | string;
  /**
   * handler for when kebab is about to open
   */
  onOpen?: () => void;

  /**
   * handler for when kebab is about to close
   */
  onClose?: () => void;
  /**
   * handler for just after kebab closed
   */
  onClosed?: () => void;
  /**
   * handler for just after kebab opened
   */
  onOpened?: () => void;

  horizontal?: boolean;
  children: (props: { closeMenu: () => void }) => string | JSX.Element;
}

// TODO: use DropdownWithTooltip.tsx
export default class Kebab extends Component<
  Props & GetComponentProps<Button>
> {
  static defaultProps: Partial<Props> = {
    overlayPlacement: Position.RIGHT,
    menuPlacement: Position.RIGHT,
    tooltipText: t`Open menu`,
  };

  popover = createRef<any>();

  @boundMethod
  closePopover() {
    if (this.popover.current) {
      this.popover.current.setOpenState(false);
    }
  }

  render() {
    const {
      className,
      dataAttributes,
      overlayPlacement,
      menuPlacement,
      onOpen,
      onClose,
      onClosed,
      onOpened,
      tooltipText,
      horizontal,
      small,
      onClick: unusedOnClick,
      children,
      ...passthroughProps
    } = this.props;

    const dotsPath = horizontal ? mdiDotsHorizontal : mdiDotsVertical;

    const iconSize = small ? IconSizeNew.SMALL : IconSizeNew.MEDIUM;

    const buttonStyle: React.CSSProperties = {
      verticalAlign: horizontal ? 'baseline' : null,
      width: small ? '18px' : '24px',
      height: small ? '18px' : '24px',
      minWidth: small ? '18px' : '24px',
      minHeight: small ? '18px' : '24px',
      padding: 0,
    };

    const buttonClass = classNames(
      className,
      rootStyles.displayInlineBlock,
      'Kebab',
    );

    return (
      <Popover
        content={children({ closeMenu: this.closePopover })}
        position={menuPlacement}
        onClosing={onClose}
        onOpening={onOpen}
        onClosed={onClosed}
        onOpened={onOpened}
        ref={this.popover}>
        <Tooltip content={tooltipText} position={overlayPlacement}>
          <Button
            minimal
            className={buttonClass}
            style={buttonStyle}
            small={small}
            icon={
              <Icon
                path={dotsPath}
                size={iconSize}
                title={
                  typeof tooltipText === 'string' ? tooltipText : undefined
                }
              />
            }
            {...dataAttributes}
            {...passthroughProps}
          />
        </Tooltip>
      </Popover>
    );
  }
}
