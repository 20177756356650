/**
 * UrbanFootprint App API
 * API for serving UrbanFootprint Application
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { BuiltFormValidationError } from './builtFormValidationError';
import { BuildingTypeInfo } from './buildingTypeInfo';
import { WebLink } from './webLink';

/**
 * A Building in a built form Library.
 */
export interface Building<> {
  /**
   * The building\'s street address
   */
  address?: string;
  /**
   * The total parcel area, in coarse units.
   */
  area_parcel?: number;
  /**
   * If the category is agriculture, then the size of the parcel in coarse units.
   */
  area_parcel_agriculture?: number;
  /**
   * Allocated in excess or deficit of parcel area.
   */
  area_parcel_allocation_error?: number;
  /**
   * Portion allocated in excess or deficit of parcel area.
   */
  area_parcel_allocation_error_pct?: number;
  /**
   * If the category is civic_institutional, then the size of the parcel in coarse units.
   */
  area_parcel_civic_institutional?: number;
  /**
   * If the category is commercial, then the size of the parcel in coarse units.
   */
  area_parcel_commercial?: number;
  /**
   * Area parcel in fine units.
   */
  area_parcel_fine?: number;
  /**
   * If the category is industrial, then the size of the parcel in coarse units.
   */
  area_parcel_industrial?: number;
  /**
   * If the category is mixed_use, then the size of the parcel in coarse units.
   */
  area_parcel_mixed_use?: number;
  /**
   * If the category is natural_conservation, then the size of the parcel in coarse units.
   */
  area_parcel_natural_conservation?: number;
  /**
   * If the category is natural_resources, then the size of the parcel in coarse units.
   */
  area_parcel_natural_resources?: number;
  /**
   * If the category is open_space, then the size of the parcel in coarse units.
   */
  area_parcel_open_space?: number;
  /**
   * If the category is other, then the size of the parcel in coarse units.
   */
  area_parcel_other?: number;
  /**
   * If Building is residential, the size of the parcel in coarse units.
   */
  area_parcel_res?: number;
  /**
   * If there are acc du, then the size of the parcel in coarse units.
   */
  area_parcel_res_acc?: number;
  /**
   * If there are attsf du, then the size of the parcel in coarse units..
   */
  area_parcel_res_attsf?: number;
  /**
   * If there are detsf ll du, then the size of the parcel in coarse units.
   */
  area_parcel_res_detsf_ll?: number;
  /**
   * If there are detsf sl du, then the size of the parcel in coarse units..
   */
  area_parcel_res_detsf_sl?: number;
  /**
   * If there are mf du, then the size of the parcel in coarse units..
   */
  area_parcel_res_mf?: number;
  /**
   * If the category is residential, then the size of the parcel in coarse units.
   */
  area_parcel_residential?: number;
  /**
   * If the category is transportation_utilities, then the size of the parcel in coarse units.
   */
  area_parcel_transportation_utilities?: number;
  /**
   * If the category is water, then the size of the parcel in coarse units.
   */
  area_parcel_water?: number;
  /**
   * Equal to area parcel if there is any DU.
   */
  area_parcel_with_residential?: number;
  /**
   * The amount of park area.
   */
  area_park?: number;
  /**
   * Average Area Per Employee
   */
  area_per_emp?: number;
  /**
   * Area occupied by a Agricultural employmee.
   */
  area_per_emp_ag?: number;
  /**
   * Area per AGRICULTURE employee.
   */
  area_per_emp_ag_agriculture?: number;
  /**
   * Area per EXTRACTION employee.
   */
  area_per_emp_ag_extraction?: number;
  /**
   * Area occupied by a Industrial employmee.
   */
  area_per_emp_ind?: number;
  /**
   * Area per CONSTRUCTION employee.
   */
  area_per_emp_ind_construction?: number;
  /**
   * Area per MANUFACTURING employee.
   */
  area_per_emp_ind_manufacturing?: number;
  /**
   * Area per TRANSPORT_WARESHOUSING employee.
   */
  area_per_emp_ind_transport_warehousing?: number;
  /**
   * Area per UTILITIES employee.
   */
  area_per_emp_ind_utilities?: number;
  /**
   * Area per WHOLESALE employee.
   */
  area_per_emp_ind_wholesale?: number;
  /**
   * Area per military employee.
   */
  area_per_emp_military?: number;
  /**
   * Area occupied by a Office employmee.
   */
  area_per_emp_off?: number;
  /**
   * Area per MEDICAL_SERVICES employee.
   */
  area_per_emp_off_medical_services?: number;
  /**
   * Area per OFFICE_SERVICES employee.
   */
  area_per_emp_off_office_services?: number;
  /**
   * Area occupied by a Public employmee.
   */
  area_per_emp_pub?: number;
  /**
   * Area per EDUCATION employee.
   */
  area_per_emp_pub_education?: number;
  /**
   * Area per PUBLIC_ADMIN employee.
   */
  area_per_emp_pub_public_admin?: number;
  /**
   * Area occupied by a Retail employmee.
   */
  area_per_emp_ret?: number;
  /**
   * Area per ACCOMMODATION employee.
   */
  area_per_emp_ret_accommodation?: number;
  /**
   * Area per ARTS_ENTERTAINMENT employee.
   */
  area_per_emp_ret_arts_entertainment?: number;
  /**
   * Area per OTHER_SERVICES employee.
   */
  area_per_emp_ret_other_services?: number;
  /**
   * Area per RESTAURANT employee.
   */
  area_per_emp_ret_restaurant?: number;
  /**
   * Area per RETAIL_SERVICES employee.
   */
  area_per_emp_ret_retail_services?: number;
  /**
   * Unit Size for Accessory unit.
   */
  avg_unit_area_acc?: number;
  /**
   * Unit Size for Single-family attached.
   */
  avg_unit_area_attsf?: number;
  /**
   * Unit Size for Single-family detached large lot.
   */
  avg_unit_area_detsf_ll?: number;
  /**
   * Unit Size for Single-family detached small lot.
   */
  avg_unit_area_detsf_sl?: number;
  /**
   * Unit Size for Multifamily.
   */
  avg_unit_area_mf?: number;
  /**
   * Area occupied by Accessory residences.
   */
  bldg_area_acc?: number;
  /**
   * Area occupied by ACCOMMODATION employment.
   */
  bldg_area_accommodation?: number;
  /**
   * Proportion of Area occupied by ACCOMMODATION employment.
   */
  bldg_area_accommodation_pct?: number;
  /**
   * Area occupied by Agricultural employment.
   */
  bldg_area_ag?: number;
  /**
   * Building Area Agricultural Portion.
   */
  bldg_area_ag_pct?: number;
  /**
   * Area occupied by AGRICULTURE employment.
   */
  bldg_area_agriculture?: number;
  /**
   * Proportion of Area occupied by AGRICULTURE employment.
   */
  bldg_area_agriculture_pct?: number;
  /**
   * Allocated in excess or deficit of total area.
   */
  bldg_area_allocation_error?: number;
  /**
   * Portion allocated in excess or deficit of total area.
   */
  bldg_area_allocation_error_pct?: number;
  /**
   * Area occupied by ARTS_ENTERTAINMENT employment.
   */
  bldg_area_arts_entertainment?: number;
  /**
   * Proportion of Area occupied by ARTS_ENTERTAINMENT employment.
   */
  bldg_area_arts_entertainment_pct?: number;
  /**
   * Area occupied by Single-family attached residences.
   */
  bldg_area_attsf?: number;
  /**
   * Area Belowground
   */
  bldg_area_belowground?: number;
  /**
   * Area occupied by CONSTRUCTION employment.
   */
  bldg_area_construction?: number;
  /**
   * Proportion of Area occupied by CONSTRUCTION employment.
   */
  bldg_area_construction_pct?: number;
  /**
   * Area occupied by Single-family detached large lot residences.
   */
  bldg_area_detsf_ll?: number;
  /**
   * Area occupied by Single-family detached small lot residences.
   */
  bldg_area_detsf_sl?: number;
  /**
   * Area occupied by EDUCATION employment.
   */
  bldg_area_education?: number;
  /**
   * Proportion of Area occupied by EDUCATION employment.
   */
  bldg_area_education_pct?: number;
  /**
   * Allocated in excess or deficit of bldg_area_nonres.
   */
  bldg_area_emp_allocation_error?: number;
  /**
   * Portion allocated in excess or deficit of bldg_area_nonres.
   */
  bldg_area_emp_allocation_error_pct?: number;
  /**
   * Area occupied by EXTRACTION employment.
   */
  bldg_area_extraction?: number;
  /**
   * Proportion of Area occupied by EXTRACTION employment.
   */
  bldg_area_extraction_pct?: number;
  /**
   * The footprint of the building.
   */
  bldg_area_footprint?: number;
  /**
   * The portion of area_parcel that is bldg_area_footprint.
   */
  bldg_area_footprint_area_parcel_pct?: number;
  /**
   * Area occupied by Industrial employment.
   */
  bldg_area_ind?: number;
  /**
   * Building Area Industrial Portion.
   */
  bldg_area_ind_pct?: number;
  /**
   * Area occupied by MANUFACTURING employment.
   */
  bldg_area_manufacturing?: number;
  /**
   * Proportion of Area occupied by MANUFACTURING employment.
   */
  bldg_area_manufacturing_pct?: number;
  /**
   * Area occupied by MEDICAL_SERVICES employment.
   */
  bldg_area_medical_services?: number;
  /**
   * Proportion of Area occupied by MEDICAL_SERVICES employment.
   */
  bldg_area_medical_services_pct?: number;
  /**
   * Area occupied by Multifamily residences.
   */
  bldg_area_mf?: number;
  /**
   * Area occupied by Military employment.
   */
  bldg_area_military?: number;
  /**
   * Building Area Military Portion.
   */
  bldg_area_military_pct?: number;
  /**
   * Area of building area that is non-residential
   */
  bldg_area_nonres?: number;
  /**
   * Percentage of building area that is non-residential
   */
  bldg_area_nonres_pct?: number;
  /**
   * Non-residential Net Area
   */
  bldg_area_nonresidential_net?: number;
  /**
   * Area occupied by Office employment.
   */
  bldg_area_off?: number;
  /**
   * Building Area Office Portion.
   */
  bldg_area_off_pct?: number;
  /**
   * Area occupied by OFFICE_SERVICES employment.
   */
  bldg_area_office_services?: number;
  /**
   * Proportion of Area occupied by OFFICE_SERVICES employment.
   */
  bldg_area_office_services_pct?: number;
  /**
   * Area occupied by OTHER_SERVICES employment.
   */
  bldg_area_other_services?: number;
  /**
   * Proportion of Area occupied by OTHER_SERVICES employment.
   */
  bldg_area_other_services_pct?: number;
  /**
   * Area occupied by Public employment.
   */
  bldg_area_pub?: number;
  /**
   * Building Area Public Portion.
   */
  bldg_area_pub_pct?: number;
  /**
   * Area occupied by PUBLIC_ADMIN employment.
   */
  bldg_area_public_admin?: number;
  /**
   * Proportion of Area occupied by PUBLIC_ADMIN employment.
   */
  bldg_area_public_admin_pct?: number;
  /**
   * Area of building area that is residential
   */
  bldg_area_res?: number;
  /**
   * Percentage of building area that is residential
   */
  bldg_area_res_pct?: number;
  /**
   * Allocated in excess or deficit of bldg_area_residential_net.
   */
  bldg_area_res_type_allocation_error?: number;
  /**
   * Portion allocated in excess or deficit of bldg_area_residential_net.
   */
  bldg_area_res_type_allocation_error_pct?: number;
  /**
   * Residential Net Area
   */
  bldg_area_residential_net?: number;
  /**
   * Area occupied by RESTAURANT employment.
   */
  bldg_area_restaurant?: number;
  /**
   * Proportion of Area occupied by RESTAURANT employment.
   */
  bldg_area_restaurant_pct?: number;
  /**
   * Area occupied by Retail employment.
   */
  bldg_area_ret?: number;
  /**
   * Building Area Retail Portion.
   */
  bldg_area_ret_pct?: number;
  /**
   * Area occupied by RETAIL_SERVICES employment.
   */
  bldg_area_retail_services?: number;
  /**
   * Proportion of Area occupied by RETAIL_SERVICES employment.
   */
  bldg_area_retail_services_pct?: number;
  /**
   * Total Building Area.
   */
  bldg_area_total?: number;
  /**
   * Total Net Area.
   */
  bldg_area_total_net?: number;
  /**
   * Area occupied by TRANSPORT_WARESHOUSING employment.
   */
  bldg_area_transport_warehousing?: number;
  /**
   * Proportion of Area occupied by TRANSPORT_WARESHOUSING employment.
   */
  bldg_area_transport_warehousing_pct?: number;
  /**
   * Area occupied by UTILITIES employment.
   */
  bldg_area_utilities?: number;
  /**
   * Proportion of Area occupied by UTILITIES employment.
   */
  bldg_area_utilities_pct?: number;
  /**
   * Area occupied by WHOLESALE employment.
   */
  bldg_area_wholesale?: number;
  /**
   * Proportion of Area occupied by WHOLESALE employment.
   */
  bldg_area_wholesale_pct?: number;
  /**
   * Ratio of net/gross non-residential area.
   */
  bldg_efficiency_ratio_nonresidential?: number;
  /**
   * Ratio of net/gross residential area.
   */
  bldg_efficiency_ratio_residential?: number;
  /**
   * Area of building area that is residential
   */
  bldg_height?: number;
  /**
   * List of Building Types that this buidling is a member of.
   */
  building_types?: Array<BuildingTypeInfo>;
  /**
   * The date this model instance was created.
   */
  created?: Date;
  /**
   * The user who created this model instance.
   */
  creator?: string;
  /**
   * Description of the building.
   */
  description?: string;
  /**
   * Total Dwelling Units
   */
  du?: number;
  /**
   * Accessory dwelling units.
   */
  du_acc?: number;
  /**
   * Single-family attached dwelling units.
   */
  du_attsf?: number;
  /**
   * Single-family detached large lot dwelling units.
   */
  du_detsf_ll?: number;
  /**
   * Single-family detached small lot dwelling units.
   */
  du_detsf_sl?: number;
  /**
   * Multifamily dwelling units.
   */
  du_mf?: number;
  /**
   * Total Employment Count
   */
  emp?: number;
  /**
   * Number of ACCOMMODATION employees.
   */
  emp_accommodation?: number;
  /**
   * Number of Agricultural employees.
   */
  emp_ag?: number;
  /**
   * Percent Agriculture Employees in Agriculture.
   */
  emp_ag_agriculture_pct?: number;
  /**
   * Percent Agriculture Employees in Extraction.
   */
  emp_ag_extraction_pct?: number;
  /**
   * Portion of total employment that is Agricultural.
   */
  emp_ag_pct?: number;
  /**
   * Allocated in excess or deficit of emp_ag_pct.
   */
  emp_ag_pct_allocation_error?: number;
  /**
   * Number of AGRICULTURE employees.
   */
  emp_agriculture?: number;
  /**
   * Number of ARTS_ENTERTAINMENT employees.
   */
  emp_arts_entertainment?: number;
  /**
   * Number of CONSTRUCTION employees.
   */
  emp_construction?: number;
  /**
   * Number of EDUCATION employees.
   */
  emp_education?: number;
  /**
   * Number of EXTRACTION employees.
   */
  emp_extraction?: number;
  /**
   * Number of Industrial employees.
   */
  emp_ind?: number;
  /**
   * Percent Industrial Employees in Construction.
   */
  emp_ind_construction_pct?: number;
  /**
   * Percent Industrial Employees in Manufacturing.
   */
  emp_ind_manufacturing_pct?: number;
  /**
   * Portion of total employment that is Industrial.
   */
  emp_ind_pct?: number;
  /**
   * Allocated in excess or deficit of emp_ind_pct.
   */
  emp_ind_pct_allocation_error?: number;
  /**
   * Percent Industrial Employees in Transport Warehousing.
   */
  emp_ind_transport_warehousing_pct?: number;
  /**
   * Percent Industrial Employees in Utilities.
   */
  emp_ind_utilities_pct?: number;
  /**
   * Percent Industrial Employees in Wholesale .
   */
  emp_ind_wholesale_pct?: number;
  /**
   * Number of MANUFACTURING employees.
   */
  emp_manufacturing?: number;
  /**
   * Number of MEDICAL_SERVICES employees.
   */
  emp_medical_services?: number;
  /**
   * Number of Military employees.
   */
  emp_military?: number;
  /**
   * Portion of total employment that is Military.
   */
  emp_military_pct?: number;
  /**
   * Number of Office employees.
   */
  emp_off?: number;
  /**
   * Percent Office Employees in Medical Services.
   */
  emp_off_medical_services_pct?: number;
  /**
   * Percent Office Employees in Office Services.
   */
  emp_off_office_services_pct?: number;
  /**
   * Portion of total employment that is Office.
   */
  emp_off_pct?: number;
  /**
   * Allocated in excess or deficit of emp_off_pct.
   */
  emp_off_pct_allocation_error?: number;
  /**
   * Number of OFFICE_SERVICES employees.
   */
  emp_office_services?: number;
  /**
   * Number of OTHER_SERVICES employees.
   */
  emp_other_services?: number;
  /**
   * Number of Public employees.
   */
  emp_pub?: number;
  /**
   * Percent Public Employees in Education.
   */
  emp_pub_education_pct?: number;
  /**
   * Portion of total employment that is Public.
   */
  emp_pub_pct?: number;
  /**
   * Allocated in excess or deficit of emp_pub_pct.
   */
  emp_pub_pct_allocation_error?: number;
  /**
   * Percent Public Employees in Public Administration.
   */
  emp_pub_public_admin_pct?: number;
  /**
   * Number of PUBLIC_ADMIN employees.
   */
  emp_public_admin?: number;
  /**
   * Number of RESTAURANT employees.
   */
  emp_restaurant?: number;
  /**
   * Number of Retail employees.
   */
  emp_ret?: number;
  /**
   * Percent Retail Employees in Accommodation .
   */
  emp_ret_accommodation_pct?: number;
  /**
   * Percent Retail Employees in Arts and Entertainment.
   */
  emp_ret_arts_entertainment_pct?: number;
  /**
   * Percent Retail Employees in Other Services.
   */
  emp_ret_other_services_pct?: number;
  /**
   * Portion of total employment that is Retail.
   */
  emp_ret_pct?: number;
  /**
   * Allocated in excess or deficit of emp_ret_pct.
   */
  emp_ret_pct_allocation_error?: number;
  /**
   * Percent Retail Employees in Restaurants.
   */
  emp_ret_restaurant_pct?: number;
  /**
   * Percent Retail Employees in Retail Services.
   */
  emp_ret_retail_services_pct?: number;
  /**
   * Number of RETAIL_SERVICES employees.
   */
  emp_retail_services?: number;
  /**
   * Number of TRANSPORT_WARESHOUSING employees.
   */
  emp_transport_warehousing?: number;
  /**
   * Number of UTILITIES employees.
   */
  emp_utilities?: number;
  /**
   * Number of WHOLESALE employees.
   */
  emp_wholesale?: number;
  /**
   * Employment density for the building
   */
  employment_density_category?: Building.EmploymentDensityCategoryEnum;
  /**
   * Building FAR.
   */
  far_building?: number;
  /**
   * Building, non-residential FAR.
   */
  far_building_nonresidential?: number;
  /**
   * Building, residential FAR.
   */
  far_building_residential?: number;
  /**
   * Parking FAR.
   */
  far_parking?: number;
  /**
   * Total FAR.
   */
  far_total?: number;
  /**
   * Number of floors in the building.
   */
  floor_count?: number;
  /**
   * The height of a single floor in the building.
   */
  floor_height?: number;
  /**
   * Area of Additional (i.e. not parking or building) Hardscaping.
   */
  hardscape_area_other?: number;
  /**
   * The portion of area_parcel that is hardscape_area_other.
   */
  hardscape_area_other_area_parcel_pct?: number;
  /**
   * Total Hardscape Area
   */
  hardscape_area_total?: number;
  /**
   * Households (occupied dwelling units)
   */
  hh?: number;
  /**
   * Size of Parking Stall - Internal.
   */
  internal_parking_stall_size?: number;
  /**
   * Irrigated Area
   */
  irrigated_area?: number;
  /**
   * Non-Residential Irrigated Area
   */
  irrigated_area_nonresidential?: number;
  /**
   * Non-Residential area irrigated portion
   */
  irrigated_area_nonresidential_pct?: number;
  /**
   * Residential Irrigated Area
   */
  irrigated_area_residential?: number;
  /**
   * Residential area irrigated portion
   */
  irrigated_area_residential_pct?: number;
  /**
   * 1 if agriculture, 0 if not.
   */
  is_agriculture?: number;
  /**
   * 1 if civic_institutional, 0 if not.
   */
  is_civic_institutional?: number;
  /**
   * 1 if commercial, 0 if not.
   */
  is_commercial?: number;
  /**
   * 1 if high, 0 if not.
   */
  is_employment_density_high?: number;
  /**
   * 1 if low, 0 if not.
   */
  is_employment_density_low?: number;
  /**
   * 1 if mid, 0 if not.
   */
  is_employment_density_mid?: number;
  /**
   * 1 if industrial, 0 if not.
   */
  is_industrial?: number;
  /**
   * 1 if mixed_use, 0 if not.
   */
  is_mixed_use?: number;
  /**
   * 1 if natural_conservation, 0 if not.
   */
  is_natural_conservation?: number;
  /**
   * 1 if natural_resources, 0 if not.
   */
  is_natural_resources?: number;
  /**
   * 1 if open_space, 0 if not.
   */
  is_open_space?: number;
  /**
   * 1 if other, 0 if not.
   */
  is_other?: number;
  /**
   * 1 if residential, 0 if not.
   */
  is_residential?: number;
  /**
   * 1 if transportation_utilities, 0 if not.
   */
  is_transportation_utilities?: number;
  /**
   * 1 if water, 0 if not.
   */
  is_water?: number;
  /**
   * The key of this Building.
   */
  key?: string;
  /**
   * Coarse-grained land use category
   */
  land_use_category_level_1?: string;
  /**
   * Medium-grained land use category
   */
  land_use_category_level_2?: string;
  /**
   * Fine-grained land use Category
   */
  land_use_category_level_3?: Building.LandUseCategoryLevel3Enum;
  /**
   * The path to the library this Building belongs to.
   */
  library_path?: string;
  /**
   * URLs to find more information about the building.
   */
  links?: Array<WebLink>;
  /**
   * The Building name.
   */
  name?: string;
  /**
   * The namespace of this Building.
   */
  namespace?: string;
  /**
   * Total Non-Hardscape Area
   */
  nonhardscape_area?: number;
  /**
   * The portion of area_parcel that is nonhardscape_area.
   */
  nonhardscape_area_area_parcel_pct?: number;
  /**
   * Portion of non-hardscape area that is irrigated.
   */
  nonhardscape_area_irrigated_pct?: number;
  /**
   * Internal Parking Area.
   */
  parking_area_internal?: number;
  /**
   * Structured Parking Area.
   */
  parking_area_structured?: number;
  /**
   * Footprint of Parking Structure.
   */
  parking_area_structured_footprint?: number;
  /**
   * The portion of area_parcel that is parking_area_structured_footprint.
   */
  parking_area_structured_footprint_area_parcel_pct?: number;
  /**
   * Surface Parking Area.
   */
  parking_area_surface?: number;
  /**
   * The portion of area_parcel that is parking_area_surface.
   */
  parking_area_surface_area_parcel_pct?: number;
  /**
   * Number of Parking Spaces - Internal.
   */
  parking_spaces_internal?: number;
  /**
   * Number of Parking Spaces - Structured.
   */
  parking_spaces_structured?: number;
  /**
   * Number of Parking Spaces - Surface.
   */
  parking_spaces_surface?: number;
  /**
   * Total Parking Spaces.
   */
  parking_spaces_total?: number;
  /**
   * Residential Population
   */
  pop?: number;
  /**
   * Residential population in group quarters.
   */
  pop_groupquarter?: number;
  /**
   * Where this building originated from.
   */
  source?: string;
  /**
   * Size of Parking Stall- Structured.
   */
  structured_parking_stall_size?: number;
  /**
   * Size of Parking Stall - Surface.
   */
  surface_parking_stall_size?: number;
  /**
   * Coarse-grained land use Category (deprecated)
   */
  uf_land_use_category?: Building.UfLandUseCategoryEnum;
  /**
   * Land use codes for this building.
   */
  uf_land_use_codes?: Array<string>;
  /**
   * The date this model instance was last updated.
   */
  updated?: Date;
  /**
   * The user who last updated this model instance.
   */
  updated_by?: string;
  /**
   * If the Building is used in any canvas in the project.
   */
  used?: boolean;
  /**
   * If the Building is valid and ready to be used in Building Types.
   */
  valid?: boolean;
  /**
   * List of errors making this building invalid.
   */
  validation_errors?: Array<BuiltFormValidationError>;
}
export namespace Building {
  export type EmploymentDensityCategoryEnum = 'high' | 'mid' | 'low';
  export const EmploymentDensityCategoryEnum = {
    High: 'high',
    Mid: 'mid',
    Low: 'low',
  } as const;
  export type LandUseCategoryLevel3Enum =
    | 'mixed_use'
    | 'mixed_use_residential'
    | 'mixed_use_commercial'
    | 'mixed_use_urban'
    | 'residential'
    | 'single_family_detached'
    | 'single_family_attached'
    | 'multifamily'
    | 'group_quarters'
    | 'commercial'
    | 'retail_services'
    | 'commercial_centers'
    | 'accommodation'
    | 'commercial_other'
    | 'office'
    | 'commercial_recreation'
    | 'industrial'
    | 'wholesale_warehousing'
    | 'light_industrial'
    | 'heavy_industrial'
    | 'civic_institutional'
    | 'civic_facilities'
    | 'special_use'
    | 'emergency_services'
    | 'primary_secondary_education'
    | 'higher_education'
    | 'hospitals'
    | 'military'
    | 'other_institutional'
    | 'transportation_utilities'
    | 'transportation'
    | 'utilities_communications'
    | 'parks_recreation'
    | 'golf_courses'
    | 'cemeteries'
    | 'open_space'
    | 'vacant'
    | 'other'
    | 'agriculture'
    | 'natural_resources'
    | 'forestry'
    | 'extraction'
    | 'natural_conservation'
    | 'water';
  export const LandUseCategoryLevel3Enum = {
    MixedUse: 'mixed_use',
    MixedUseResidential: 'mixed_use_residential',
    MixedUseCommercial: 'mixed_use_commercial',
    MixedUseUrban: 'mixed_use_urban',
    Residential: 'residential',
    SingleFamilyDetached: 'single_family_detached',
    SingleFamilyAttached: 'single_family_attached',
    Multifamily: 'multifamily',
    GroupQuarters: 'group_quarters',
    Commercial: 'commercial',
    RetailServices: 'retail_services',
    CommercialCenters: 'commercial_centers',
    Accommodation: 'accommodation',
    CommercialOther: 'commercial_other',
    Office: 'office',
    CommercialRecreation: 'commercial_recreation',
    Industrial: 'industrial',
    WholesaleWarehousing: 'wholesale_warehousing',
    LightIndustrial: 'light_industrial',
    HeavyIndustrial: 'heavy_industrial',
    CivicInstitutional: 'civic_institutional',
    CivicFacilities: 'civic_facilities',
    SpecialUse: 'special_use',
    EmergencyServices: 'emergency_services',
    PrimarySecondaryEducation: 'primary_secondary_education',
    HigherEducation: 'higher_education',
    Hospitals: 'hospitals',
    Military: 'military',
    OtherInstitutional: 'other_institutional',
    TransportationUtilities: 'transportation_utilities',
    Transportation: 'transportation',
    UtilitiesCommunications: 'utilities_communications',
    ParksRecreation: 'parks_recreation',
    GolfCourses: 'golf_courses',
    Cemeteries: 'cemeteries',
    OpenSpace: 'open_space',
    Vacant: 'vacant',
    Other: 'other',
    Agriculture: 'agriculture',
    NaturalResources: 'natural_resources',
    Forestry: 'forestry',
    Extraction: 'extraction',
    NaturalConservation: 'natural_conservation',
    Water: 'water',
  } as const;
  export type UfLandUseCategoryEnum =
    | 'mixed_use'
    | 'residential'
    | 'commercial'
    | 'industrial'
    | 'civic_institutional'
    | 'transportation_utilities'
    | 'open_space'
    | 'other'
    | 'natural_resources'
    | 'natural'
    | 'conservation_module_landscape_types';
  export const UfLandUseCategoryEnum = {
    MixedUse: 'mixed_use',
    Residential: 'residential',
    Commercial: 'commercial',
    Industrial: 'industrial',
    CivicInstitutional: 'civic_institutional',
    TransportationUtilities: 'transportation_utilities',
    OpenSpace: 'open_space',
    Other: 'other',
    NaturalResources: 'natural_resources',
    Natural: 'natural',
    ConservationModuleLandscapeTypes: 'conservation_module_landscape_types',
  } as const;
}
