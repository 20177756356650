import colors from 'material-colors';
import { t } from 'ttag';

import { BTU, getUnitName, KWH, THM } from 'uf/base/units';
import { LegacyChartSeries, LegacyReport } from 'uf/reporting/index';

import { buildingEnergyEmissionChart } from './emissions';
import { totalResidentialEnergyCostChart } from './household';

const IN_SENTENCE = true;

const residentialEnergySeries: LegacyChartSeries = {
  key: 'total_res_energy',
  source: 'analysis',
  sourceKey: 'energy_demand',
  layerKey: 'energy_demand_results',
  columnKey: 'residential_energy_use',
  stat: 'sum',
};

const commercialEnergySeries: LegacyChartSeries = {
  key: 'total_com_energy',
  source: 'analysis',
  sourceKey: 'energy_demand',
  layerKey: 'energy_demand_results',
  columnKey: 'commercial_energy_use',
  stat: 'sum',
};

const totalEnergyUseChart = {
  name: t`Total Annual Energy Use`,
  description: t`Annual residential and commercial building energy use`,
  subtitle: t`Electricity and gas consumed`,
  key: 'total_energy',
  series: [residentialEnergySeries, commercialEnergySeries],
  units: getUnitName(BTU, IN_SENTENCE),
};

const residentialElectricitySeries: LegacyChartSeries = {
  key: 'total_residential_electricity_use',
  source: 'analysis',
  sourceKey: 'energy_demand',
  layerKey: 'energy_demand_results',
  columnKey: 'residential_electricity_use',
  stat: 'sum',
};

const commercialElectricitySeries: LegacyChartSeries = {
  key: 'total_commercial_electricity_use',
  source: 'analysis',
  sourceKey: 'energy_demand',
  layerKey: 'energy_demand_results',
  columnKey: 'commercial_electricity_use',
  stat: 'sum',
};

const totalElectricityUseChart = {
  name: t`Annual Electricity Use`,
  description: t`Annual residential and commercial building electricity use`,
  key: 'total_electricity',
  series: [residentialElectricitySeries, commercialElectricitySeries],
  units: getUnitName(KWH),
};

const residentialGasSeries: LegacyChartSeries = {
  key: 'total_residential_gas_use',
  source: 'analysis',
  sourceKey: 'energy_demand',
  layerKey: 'energy_demand_results',
  columnKey: 'residential_gas_use',
  stat: 'sum',
};

const commercialGasSeries: LegacyChartSeries = {
  key: 'total_commercial_gas_use',
  source: 'analysis',
  sourceKey: 'energy_demand',
  layerKey: 'energy_demand_results',
  columnKey: 'commercial_gas_use',
  stat: 'sum',
};

const totalGasUseChart = {
  name: t`Annual Gas Use`,
  description: t`Annual residential and commercial building natural gas use`,
  key: 'total_gas',
  series: [residentialGasSeries, commercialGasSeries],
  units: getUnitName(THM),
};

const perHouseholdResidentialUse: LegacyChartSeries = {
  key: 'per_household_res_energy_use',
  source: 'analysis',
  sourceKey: 'energy_demand',
  layerKey: 'energy_summary_per_household',
  columnKey: 'residential_per_household_use',
};

const perCapitaResidentialUse: LegacyChartSeries = {
  key: 'per_capita_res_energy_use',
  source: 'analysis',
  sourceKey: 'energy_demand',
  layerKey: 'energy_summary_per_capita',
  columnKey: 'residential_per_capita_use',
};

const perCapitaCommercialUse: LegacyChartSeries = {
  key: 'per_capita_com_energy_use',
  source: 'analysis',
  sourceKey: 'energy_demand',
  layerKey: 'energy_summary_per_capita',
  columnKey: 'commercial_per_employee_use',
};

export const energyCharts: LegacyReport = Object.freeze({
  key: 'energy_demand',
  name: 'Energy Use',
  colorSpec: {
    primaryColor: 'rgb(254, 185, 73)',
    interpolateFromColor: colors.yellow[300],
    interpolateToColor: colors.yellow[900],
  },
  charts: [
    totalEnergyUseChart,
    totalElectricityUseChart,
    totalGasUseChart,
    {
      name: t`Per Household Annual Energy Use`,
      description: t`Annual average building energy use per household`,
      subtitle: t`Average electricity and gas consumption`,
      key: 'per_household_energy',
      series: [perHouseholdResidentialUse],
      units: getUnitName(BTU, IN_SENTENCE),
    },
    totalResidentialEnergyCostChart,
    buildingEnergyEmissionChart,
  ],
});

const ldcSeries: LegacyChartSeries[] = [
  'urban',
  'compact',
  'standard',
  'rural',
].map(key => ({
  key: `per_capita_ldc_${key}`,
  source: 'analysis',
  sourceKey: 'energy_demand',
  layerKey: 'energy_summary_res_use_by_ldc',
  columnKey: key,
}));

export const energyAnalysisCharts: LegacyReport = Object.freeze({
  key: 'energy_demand',
  name: 'Energy',
  charts: [
    totalEnergyUseChart,
    totalElectricityUseChart,
    totalGasUseChart,
    {
      name: t`Per Capita Annual Energy Use`,
      description: t`Annual average building energy use per resident and per employee`,
      subtitle: t`Average electricity and gas consumption`,
      key: 'per_capita_energy',
      series: [perCapitaResidentialUse, perCapitaCommercialUse],
      units: getUnitName(BTU, IN_SENTENCE),
    },
    {
      name: t`Per Household Annual Energy Use`,
      description: t`Annual average building energy use per household`,
      subtitle: t`Average electricity and gas consumption`,
      key: 'per_household_energy',
      series: [perHouseholdResidentialUse],
      units: getUnitName(BTU, IN_SENTENCE),
    },
    {
      name: t`Per Household Annual Residential Energy Use by Land Development Category`,
      description: t`Per household annual average building energy
      use by land development category`,
      subtitle: t`Average electricity and gas consumption`,
      key: 'per_household_by_ldc',
      series: ldcSeries,
      units: getUnitName(BTU, IN_SENTENCE),
    },
  ],
});
