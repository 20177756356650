/**
 * UrbanFootprint App API
 * API for serving UrbanFootprint Application
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { ClientResponse } from '../variables';

// @ts-ignore: This is used only for params, but some services have param-free APIs
import { UserdataServiceInterface } from './userdata.serviceInterface';

// @ts-ignore: These imports are for both params and returnType, but we only use returnType
import { LayerReference } from '../model/layerReference';
// @ts-ignore: These imports are for both params and returnType, but we only use returnType
import { ProblemDetail } from '../model/problemDetail';
// @ts-ignore: These imports are for both params and returnType, but we only use returnType
import { UserData } from '../model/userData';

/**
 * Package: api
 */

const addLayerToFavoritesPrefix = `uf-api/userdata/add_layer_to_favorites/`;
/**
 * Action types for userdata.add_layer_to_favorites
 *
 * Add a layer to the user favorite list.
 */
export const addLayerToFavoritesActionTypes = {
  LOAD: `${addLayerToFavoritesPrefix}LOAD` as 'uf-api/userdata/add_layer_to_favorites/LOAD',
  SUCCESS:
    `${addLayerToFavoritesPrefix}SUCCESS` as 'uf-api/userdata/add_layer_to_favorites/SUCCESS',
  FAILURE:
    `${addLayerToFavoritesPrefix}FAILURE` as 'uf-api/userdata/add_layer_to_favorites/FAILURE',
  CLEAR:
    `${addLayerToFavoritesPrefix}CLEAR` as 'uf-api/userdata/add_layer_to_favorites/CLEAR',
} as const;

/**
 * LOAD action for userdata.add_layer_to_favorites
 * Add a layer to the user favorite list.
 */
export interface addLayerToFavoritesLoadAction {
  readonly type: 'uf-api/userdata/add_layer_to_favorites/LOAD';
  readonly params: UserdataServiceInterface.addLayerToFavoritesParams;
  readonly promise: Promise<ClientResponse<{}>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for userdata.add_layer_to_favorites
 * Add a layer to the user favorite list.
 */
export interface addLayerToFavoritesSuccessAction {
  readonly type: 'uf-api/userdata/add_layer_to_favorites/SUCCESS';
  readonly result: {};
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for userdata.add_layer_to_favorites
 * Add a layer to the user favorite list.
 */
export interface addLayerToFavoritesFailureAction {
  readonly type: 'uf-api/userdata/add_layer_to_favorites/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for userdata.add_layer_to_favorites
 * Add a layer to the user favorite list.
 */
export interface addLayerToFavoritesClearAction {
  readonly type: 'uf-api/userdata/add_layer_to_favorites/CLEAR';
  readonly key: string;
}

const deleteLayerFromFavoritesPrefix = `uf-api/userdata/delete_layer_from_favorites/`;
/**
 * Action types for userdata.delete_layer_from_favorites
 *
 * Delete a user layer favorite
 */
export const deleteLayerFromFavoritesActionTypes = {
  LOAD: `${deleteLayerFromFavoritesPrefix}LOAD` as 'uf-api/userdata/delete_layer_from_favorites/LOAD',
  SUCCESS:
    `${deleteLayerFromFavoritesPrefix}SUCCESS` as 'uf-api/userdata/delete_layer_from_favorites/SUCCESS',
  FAILURE:
    `${deleteLayerFromFavoritesPrefix}FAILURE` as 'uf-api/userdata/delete_layer_from_favorites/FAILURE',
  CLEAR:
    `${deleteLayerFromFavoritesPrefix}CLEAR` as 'uf-api/userdata/delete_layer_from_favorites/CLEAR',
} as const;

/**
 * LOAD action for userdata.delete_layer_from_favorites
 * Delete a user layer favorite
 */
export interface deleteLayerFromFavoritesLoadAction {
  readonly type: 'uf-api/userdata/delete_layer_from_favorites/LOAD';
  readonly params: UserdataServiceInterface.deleteLayerFromFavoritesParams;
  readonly promise: Promise<ClientResponse<{}>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for userdata.delete_layer_from_favorites
 * Delete a user layer favorite
 */
export interface deleteLayerFromFavoritesSuccessAction {
  readonly type: 'uf-api/userdata/delete_layer_from_favorites/SUCCESS';
  readonly result: {};
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for userdata.delete_layer_from_favorites
 * Delete a user layer favorite
 */
export interface deleteLayerFromFavoritesFailureAction {
  readonly type: 'uf-api/userdata/delete_layer_from_favorites/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for userdata.delete_layer_from_favorites
 * Delete a user layer favorite
 */
export interface deleteLayerFromFavoritesClearAction {
  readonly type: 'uf-api/userdata/delete_layer_from_favorites/CLEAR';
  readonly key: string;
}

const deleteUserDataPrefix = `uf-api/userdata/delete_user_data/`;
/**
 * Action types for userdata.delete_user_data
 *
 * Set user data
 */
export const deleteUserDataActionTypes = {
  LOAD: `${deleteUserDataPrefix}LOAD` as 'uf-api/userdata/delete_user_data/LOAD',
  SUCCESS:
    `${deleteUserDataPrefix}SUCCESS` as 'uf-api/userdata/delete_user_data/SUCCESS',
  FAILURE:
    `${deleteUserDataPrefix}FAILURE` as 'uf-api/userdata/delete_user_data/FAILURE',
  CLEAR:
    `${deleteUserDataPrefix}CLEAR` as 'uf-api/userdata/delete_user_data/CLEAR',
} as const;

/**
 * LOAD action for userdata.delete_user_data
 * Set user data
 */
export interface deleteUserDataLoadAction {
  readonly type: 'uf-api/userdata/delete_user_data/LOAD';
  readonly params: UserdataServiceInterface.deleteUserDataParams;
  readonly promise: Promise<ClientResponse<{}>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for userdata.delete_user_data
 * Set user data
 */
export interface deleteUserDataSuccessAction {
  readonly type: 'uf-api/userdata/delete_user_data/SUCCESS';
  readonly result: {};
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for userdata.delete_user_data
 * Set user data
 */
export interface deleteUserDataFailureAction {
  readonly type: 'uf-api/userdata/delete_user_data/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for userdata.delete_user_data
 * Set user data
 */
export interface deleteUserDataClearAction {
  readonly type: 'uf-api/userdata/delete_user_data/CLEAR';
  readonly key: string;
}

const getUserDataPrefix = `uf-api/userdata/get_user_data/`;
/**
 * Action types for userdata.get_user_data
 *
 * Get user data
 */
export const getUserDataActionTypes = {
  LOAD: `${getUserDataPrefix}LOAD` as 'uf-api/userdata/get_user_data/LOAD',
  SUCCESS:
    `${getUserDataPrefix}SUCCESS` as 'uf-api/userdata/get_user_data/SUCCESS',
  FAILURE:
    `${getUserDataPrefix}FAILURE` as 'uf-api/userdata/get_user_data/FAILURE',
  CLEAR: `${getUserDataPrefix}CLEAR` as 'uf-api/userdata/get_user_data/CLEAR',
} as const;

/**
 * LOAD action for userdata.get_user_data
 * Get user data
 */
export interface getUserDataLoadAction {
  readonly type: 'uf-api/userdata/get_user_data/LOAD';
  readonly params: UserdataServiceInterface.getUserDataParams;
  readonly promise: Promise<ClientResponse<UserData>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for userdata.get_user_data
 * Get user data
 */
export interface getUserDataSuccessAction {
  readonly type: 'uf-api/userdata/get_user_data/SUCCESS';
  readonly result: UserData;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for userdata.get_user_data
 * Get user data
 */
export interface getUserDataFailureAction {
  readonly type: 'uf-api/userdata/get_user_data/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for userdata.get_user_data
 * Get user data
 */
export interface getUserDataClearAction {
  readonly type: 'uf-api/userdata/get_user_data/CLEAR';
  readonly key: string;
}

const listFavoriteLayersPrefix = `uf-api/userdata/list_favorite_layers/`;
/**
 * Action types for userdata.list_favorite_layers
 *
 * Get user favorites
 */
export const listFavoriteLayersActionTypes = {
  LOAD: `${listFavoriteLayersPrefix}LOAD` as 'uf-api/userdata/list_favorite_layers/LOAD',
  SUCCESS:
    `${listFavoriteLayersPrefix}SUCCESS` as 'uf-api/userdata/list_favorite_layers/SUCCESS',
  FAILURE:
    `${listFavoriteLayersPrefix}FAILURE` as 'uf-api/userdata/list_favorite_layers/FAILURE',
  CLEAR:
    `${listFavoriteLayersPrefix}CLEAR` as 'uf-api/userdata/list_favorite_layers/CLEAR',
} as const;

/**
 * LOAD action for userdata.list_favorite_layers
 * Get user favorites
 */
export interface listFavoriteLayersLoadAction {
  readonly type: 'uf-api/userdata/list_favorite_layers/LOAD';
  readonly params: UserdataServiceInterface.listFavoriteLayersParams;
  readonly promise: Promise<ClientResponse<Array<LayerReference>>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for userdata.list_favorite_layers
 * Get user favorites
 */
export interface listFavoriteLayersSuccessAction {
  readonly type: 'uf-api/userdata/list_favorite_layers/SUCCESS';
  readonly result: Array<LayerReference>;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for userdata.list_favorite_layers
 * Get user favorites
 */
export interface listFavoriteLayersFailureAction {
  readonly type: 'uf-api/userdata/list_favorite_layers/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for userdata.list_favorite_layers
 * Get user favorites
 */
export interface listFavoriteLayersClearAction {
  readonly type: 'uf-api/userdata/list_favorite_layers/CLEAR';
  readonly key: string;
}

const listUserDataItemsPrefix = `uf-api/userdata/list_user_data_items/`;
/**
 * Action types for userdata.list_user_data_items
 *
 * Get a list of data items that a user has
 */
export const listUserDataItemsActionTypes = {
  LOAD: `${listUserDataItemsPrefix}LOAD` as 'uf-api/userdata/list_user_data_items/LOAD',
  SUCCESS:
    `${listUserDataItemsPrefix}SUCCESS` as 'uf-api/userdata/list_user_data_items/SUCCESS',
  FAILURE:
    `${listUserDataItemsPrefix}FAILURE` as 'uf-api/userdata/list_user_data_items/FAILURE',
  CLEAR:
    `${listUserDataItemsPrefix}CLEAR` as 'uf-api/userdata/list_user_data_items/CLEAR',
} as const;

/**
 * LOAD action for userdata.list_user_data_items
 * Get a list of data items that a user has
 */
export interface listUserDataItemsLoadAction {
  readonly type: 'uf-api/userdata/list_user_data_items/LOAD';
  readonly params: UserdataServiceInterface.listUserDataItemsParams;
  readonly promise: Promise<ClientResponse<Array<UserData>>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for userdata.list_user_data_items
 * Get a list of data items that a user has
 */
export interface listUserDataItemsSuccessAction {
  readonly type: 'uf-api/userdata/list_user_data_items/SUCCESS';
  readonly result: Array<UserData>;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for userdata.list_user_data_items
 * Get a list of data items that a user has
 */
export interface listUserDataItemsFailureAction {
  readonly type: 'uf-api/userdata/list_user_data_items/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for userdata.list_user_data_items
 * Get a list of data items that a user has
 */
export interface listUserDataItemsClearAction {
  readonly type: 'uf-api/userdata/list_user_data_items/CLEAR';
  readonly key: string;
}

const listUserDataKeysPrefix = `uf-api/userdata/list_user_data_keys/`;
/**
 * Action types for userdata.list_user_data_keys
 *
 * Get a list of keys that the user has data for.
 */
export const listUserDataKeysActionTypes = {
  LOAD: `${listUserDataKeysPrefix}LOAD` as 'uf-api/userdata/list_user_data_keys/LOAD',
  SUCCESS:
    `${listUserDataKeysPrefix}SUCCESS` as 'uf-api/userdata/list_user_data_keys/SUCCESS',
  FAILURE:
    `${listUserDataKeysPrefix}FAILURE` as 'uf-api/userdata/list_user_data_keys/FAILURE',
  CLEAR:
    `${listUserDataKeysPrefix}CLEAR` as 'uf-api/userdata/list_user_data_keys/CLEAR',
} as const;

/**
 * LOAD action for userdata.list_user_data_keys
 * Get a list of keys that the user has data for.
 */
export interface listUserDataKeysLoadAction {
  readonly type: 'uf-api/userdata/list_user_data_keys/LOAD';
  readonly params: UserdataServiceInterface.listUserDataKeysParams;
  readonly promise: Promise<ClientResponse<Array<string>>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for userdata.list_user_data_keys
 * Get a list of keys that the user has data for.
 */
export interface listUserDataKeysSuccessAction {
  readonly type: 'uf-api/userdata/list_user_data_keys/SUCCESS';
  readonly result: Array<string>;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for userdata.list_user_data_keys
 * Get a list of keys that the user has data for.
 */
export interface listUserDataKeysFailureAction {
  readonly type: 'uf-api/userdata/list_user_data_keys/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for userdata.list_user_data_keys
 * Get a list of keys that the user has data for.
 */
export interface listUserDataKeysClearAction {
  readonly type: 'uf-api/userdata/list_user_data_keys/CLEAR';
  readonly key: string;
}

const setUserDataPrefix = `uf-api/userdata/set_user_data/`;
/**
 * Action types for userdata.set_user_data
 *
 * Set user data
 */
export const setUserDataActionTypes = {
  LOAD: `${setUserDataPrefix}LOAD` as 'uf-api/userdata/set_user_data/LOAD',
  SUCCESS:
    `${setUserDataPrefix}SUCCESS` as 'uf-api/userdata/set_user_data/SUCCESS',
  FAILURE:
    `${setUserDataPrefix}FAILURE` as 'uf-api/userdata/set_user_data/FAILURE',
  CLEAR: `${setUserDataPrefix}CLEAR` as 'uf-api/userdata/set_user_data/CLEAR',
} as const;

/**
 * LOAD action for userdata.set_user_data
 * Set user data
 */
export interface setUserDataLoadAction {
  readonly type: 'uf-api/userdata/set_user_data/LOAD';
  readonly params: UserdataServiceInterface.setUserDataParams;
  readonly promise: Promise<ClientResponse<{}>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for userdata.set_user_data
 * Set user data
 */
export interface setUserDataSuccessAction {
  readonly type: 'uf-api/userdata/set_user_data/SUCCESS';
  readonly result: {};
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for userdata.set_user_data
 * Set user data
 */
export interface setUserDataFailureAction {
  readonly type: 'uf-api/userdata/set_user_data/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for userdata.set_user_data
 * Set user data
 */
export interface setUserDataClearAction {
  readonly type: 'uf-api/userdata/set_user_data/CLEAR';
  readonly key: string;
}

export const userdataActionTypesMap = {
  /** Action types for userdata.add_layer_to_favorites */
  add_layer_to_favorites: addLayerToFavoritesActionTypes,
  /** Action types for userdata.delete_layer_from_favorites */
  delete_layer_from_favorites: deleteLayerFromFavoritesActionTypes,
  /** Action types for userdata.delete_user_data */
  delete_user_data: deleteUserDataActionTypes,
  /** Action types for userdata.get_user_data */
  get_user_data: getUserDataActionTypes,
  /** Action types for userdata.list_favorite_layers */
  list_favorite_layers: listFavoriteLayersActionTypes,
  /** Action types for userdata.list_user_data_items */
  list_user_data_items: listUserDataItemsActionTypes,
  /** Action types for userdata.list_user_data_keys */
  list_user_data_keys: listUserDataKeysActionTypes,
  /** Action types for userdata.set_user_data */
  set_user_data: setUserDataActionTypes,
} as const;

export const userdataDetailsMap = {
  add_layer_to_favorites: {
    /** HTTP method for userdata.add_layer_to_favorites */
    httpMethod: 'put' as const,
  },
  delete_layer_from_favorites: {
    /** HTTP method for userdata.delete_layer_from_favorites */
    httpMethod: 'delete' as const,
  },
  delete_user_data: {
    /** HTTP method for userdata.delete_user_data */
    httpMethod: 'delete' as const,
  },
  get_user_data: {
    /** HTTP method for userdata.get_user_data */
    httpMethod: 'get' as const,
  },
  list_favorite_layers: {
    /** HTTP method for userdata.list_favorite_layers */
    httpMethod: 'get' as const,
  },
  list_user_data_items: {
    /** HTTP method for userdata.list_user_data_items */
    httpMethod: 'get' as const,
  },
  list_user_data_keys: {
    /** HTTP method for userdata.list_user_data_keys */
    httpMethod: 'get' as const,
  },
  set_user_data: {
    /** HTTP method for userdata.set_user_data */
    httpMethod: 'put' as const,
  },
} as const;

/**
 * Local DataState<T> - kept in sync with uf/data/dataState.ts
 */
interface DataState<T> {
  readonly loading: boolean;
  readonly loaded: boolean;
  readonly requestId: string;
  readonly requestTime?: string;
  readonly responseTime?: string;
  readonly requestCount?: number;
  readonly error?: any;
  readonly promise?: Promise<T>;
  readonly data?: T;
  readonly extra?: any;
  readonly key?: string;
}
export interface UserdataServiceState {
  /** all recent addLayerToFavorites results (POST/PUT/PATCH) */
  readonly add_layer_to_favorites: DataState<{}>[];
  /** deleteLayerFromFavorites state, by key */
  delete_layer_from_favorites: {
    readonly [key: string]: DataState<{}>;
  };
  /** deleteUserData state, by key */
  delete_user_data: {
    readonly [key: string]: DataState<{}>;
  };
  /** getUserData state, by key */
  get_user_data: {
    readonly [key: string]: DataState<UserData>;
  };
  /** listFavoriteLayers state, by key */
  list_favorite_layers: {
    readonly [key: string]: DataState<Array<LayerReference>>;
  };
  /** listUserDataItems state, by key */
  list_user_data_items: {
    readonly [key: string]: DataState<Array<UserData>>;
  };
  /** listUserDataKeys state, by key */
  list_user_data_keys: {
    readonly [key: string]: DataState<Array<string>>;
  };
  /** all recent setUserData results (POST/PUT/PATCH) */
  readonly set_user_data: DataState<{}>[];
}
