import { Mode } from '@mapbox/mapbox-gl-draw';
import { Layer, Marker, Source } from 'mapbox-gl';

import { BuiltFormTypes } from 'uf/base/builtForms';
import { GridOptions } from 'uf/base/geo';
import { UFLngLatBounds } from 'uf/base/map';
import { ColumnKey, LayerId } from 'uf/layers';
import { FilterSpec } from 'uf/layers/filters';
import { ActiveColumnInfo } from 'uf/map/ActiveColumnInfo';
import { BaseMapsById } from 'uf/map/baseMaps';
import { MapMode } from 'uf/map/mapmode';
import { CoordPathsByFeature } from 'uf/map/state';
import { EmploymentDirectPaintingState } from 'uf/painting/employment';
import { ResidentialDirectPainting } from 'uf/painting/residential';
import { ProjectId } from 'uf/projects';
import { LegacyVirtualLayerId } from 'uf/projects/virtualLayers';
import { TaskMessage } from 'uf/tasks/MessageTypes';
import { CombinedTaskState } from 'uf/tasks/state';
import { ViewId } from 'uf/views';

import { TabKey } from './details';

declare module 'uf/state' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface UFState {
    explore: LegacyExploreState;
    exploreByProjectId: Record<string, ExploreState>;
  }
}

/**
 * The global explore state, across all projects. Most of this state should be
 * moved out to be per-project into `ExploreState`
 */
export interface LegacyExploreState {
  /** @deprecated Use the one from ExploreState */
  map?: MapState;

  /** @deprecated Use the one from ExploreState */
  scenarios?: ExploreScenariosState;

  /** @deprecated Use the one from ExploreState */
  ui?: UiState;

  paintBaseTasks: CombinedTaskState<string, TaskMessage>;
  paintTasks: CombinedTaskState<string, TaskMessage>;
}

/** The per-project explore state */
export interface ExploreState {
  charts?: any; // TODO: fill this in
  detailsTabKey?: Record<string, TabKey>;
  drawingMode?: Record<string /* layerId */, DrawingMode>;

  /**
   * whether or not too many features would be created from splitting a feature.  because
   * calculations involving too many features can crash the app, we limit the number of features a
   * user can create from splitting.  if too many features would be created, we don't allow the
   * operation.
   */
  tooManyFeaturesFromSplit?: Record<string, boolean>; // stored by layer id
  /**
   * feature ids created from splitting geometries, by layer
   **/
  featureIdsFromSplit?: Record<LayerId, string[]>;
  splittingEnabled?: boolean;
  failedIntersections?: Record<string, number>;
  gridOptionsForFeatureSplitting: GridOptions;
  // by project by layer
  filters?: Record<string, Partial<FilterSpec>>;
  layers?: ExploreLayersState;
  map?: MapState;
  paint?: PaintState;
  scenarios?: ExploreScenariosState;
  stats?: Record<string, string>;
  ui?: UiState;

  visibilityFilters?: Record<LayerId, Record<ColumnKey, string>>;

  activeViewId: ViewId;
  markers?: Record<string, Marker>;
}

export interface DrawingMode {
  mode: Mode<'box_select_vertices'>;

  /**
   * The list of feature ids currently selected by the user
   */
  selectedFeatureIds: string[];

  /**
   * The specific vertices that were selected in the selected features. This
   * exists when the user is selecting on a LineString or MultiLineString
   * layer, which represents lines as a collection of vertices.
   */
  selectedCoordPaths: CoordPathsByFeature;
}

export interface JoinItem {
  key: string;
  collapsed: boolean;
}

export interface UiState {
  filterListItems: Record<ProjectId, Record<string, JoinItem[]>>;
  geojoinListItems: Record<ProjectId, Record<string, JoinItem[]>>;
  properties: Record<string, any>;

  showPaintedOnly: Record<string, boolean>;
}

const LEBANON_LNG = -98.58;
const LEBANON_LAT = 39.83;
// https://en.wikipedia.org/wiki/Geographic_center_of_the_contiguous_United_States
export const LEBANON_KS: [number, number] = [LEBANON_LNG, LEBANON_LAT];
export const initialMapState = Object.freeze<MapState>({
  // updated light-v9 basemap
  mapStyle: {
    styleUrl: BaseMapsById.light.url,
    contoursVisible: false,
    buildingsVisible: false,
    citiesLabelVisible: true,
    placesLabelVisible: true,
    poiLabelVisible: false,
    roadRailLabelVisible: false,
    waterLabelVisible: true,
    waterMaskVisible: false,
  },
  requestedMapBounds: null,
  mapBounds: null,

  // The max bounds can change when the user resizes their browser.
  mapMaxBounds: null,

  // pitch is for storing the state of the map
  pitch: 0,

  // bearing is for storing the state of the map
  bearing: 0,
  width: null,
  height: null,
  scale: null,
  takeSnapshot: false,

  center: LEBANON_KS,
  zoom: 3,
  mode: MapMode.NORMAL,
  selectionModesDisabled: false,
  mapStatus: '',
  debugTileSources: {},
  debugStyleLayers: {},
  inspection: {} as InspectionObject,
});

// TODO: combine this with MapState in uf/map/state
export interface MapState {
  mode: MapMode;
  selectionModesDisabled: boolean;
  center: [number, number];
  zoom: number;
  width: number;
  height: number;

  mapStyle: MapStyle;

  mapBounds: UFLngLatBounds;
  mapMaxBounds: UFLngLatBounds;

  scale: number;

  requestedMapBounds: UFLngLatBounds;

  /**
   * A read-only debugging property for looking at the tile sources on the map.
   */
  debugTileSources: {
    [tileSourceId: string]: Source;
  };

  /**
   * A read-only debugging property for looking at the style layers on the map.
   */
  debugStyleLayers: {
    [styleLayerId: string]: Layer;
  };
  inspection: Record<LayerId, InspectionObject>;

  takeSnapshot: boolean;

  pitch: number;
  bearing: number;

  mapStatus: string;
}
export interface InspectionObject {
  point: [number, number];
  lngLat: [number, number];
  properties: Record<string, any>;
}

interface MapStyle {
  styleUrl?: string;
  contoursVisible?: boolean;
  buildingsVisible?: boolean;
  citiesLabelVisible?: boolean;
  placesLabelVisible?: boolean;
  poiLabelVisible?: boolean;
  roadRailLabelVisible?: boolean;
  waterLabelVisible?: boolean;
  waterMaskVisible?: boolean;
}
export type ProjectStyleInfo = Record<LegacyVirtualLayerId, ActiveColumnInfo>;

export interface ExploreLayersState {
  activeLayerVirtualId: LegacyVirtualLayerId;
  order: LegacyVirtualLayerId[];
  /** Mapping of virtualLayerId => active column */
  mapStyles: ProjectStyleInfo;
  queryStates: Record<LayerId, ExploreLayerSearchState>;
  visibleLayerVirtualIds: LegacyVirtualLayerId[];
}

export interface ExploreScenariosState {
  order: Record<ProjectId, string[]>;
}

export interface ExploreLayerSearchState {
  searchKey: string;
}

export interface BuiltFormInfo {
  builtFormKey: string;
  builtFormType: BuiltFormTypes;
}

export interface PaintState {
  /** List of recently-used builtforms (i.e. recently-painted) */
  recent: BuiltFormInfo[];
  /** The current builtform that the user is hovering over (for paint preview) */
  hover?: BuiltFormInfo;

  /** The selected builtform that will be used for painting */
  current?: BuiltFormInfo;

  /** Override of residential info */
  residential?: ResidentialDirectPainting;

  /** Override of employment info */
  employment?: EmploymentDirectPaintingState;
}
