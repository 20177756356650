import _ from 'lodash';
import { createSelector } from 'reselect';

import { ProjectAvailableLayerItem, ProjectAvailableLayers } from 'uf-api';
import { EMPTY_ARRAY } from 'uf/base';
import { makeGetFromPropsSelector } from 'uf/base/selector';
import {
  DataState,
  EmptyKeyedState,
  getData,
  isLoading,
} from 'uf/data/dataState';
import getCategoryLabel from 'uf/projects/layerCategories';
import { getReferenceLayersFromLayerList } from 'uf/projects/layerList';

import { ProjectId } from '../';
import { makeGetProjectById, makeGetProjectState } from './index';
import { getAvailableProjectLayersState } from './layers';

export function makeGetAvailableProjectLayers() {
  return createSelector(
    getAvailableProjectLayersState,
    makeGetFromPropsSelector<ProjectId, 'projectId'>('projectId'),
    (availableLayersState, projectId): DataState<ProjectAvailableLayers> =>
      availableLayersState[projectId],
  );
}

export const getAvailableProjectLayersById = createSelector(
  getAvailableProjectLayersState,
  makeGetFromPropsSelector<ProjectId, 'projectId'>('projectId'),
  (availableProjectLayersState, projectId): DataState<ProjectAvailableLayers> =>
    availableProjectLayersState[projectId] || EmptyKeyedState,
);

export function makeGetProjectLayerListReferenceLayers() {
  return createSelector(makeGetProjectById(), project => {
    const referenceLayers = getReferenceLayersFromLayerList(project);
    return _.isEmpty(referenceLayers) ? EMPTY_ARRAY : referenceLayers;
  });
}

function makeGetProjectAvailableReferenceLayerItems() {
  return createSelector(
    getAvailableProjectLayersById,
    (availableProjectLayers): ProjectAvailableLayerItem[] => {
      const availableLayers = getData<ProjectAvailableLayers>(
        availableProjectLayers,
      );
      if (!availableLayers) {
        return EMPTY_ARRAY;
      }

      if (!availableLayers.reference) {
        return EMPTY_ARRAY;
      }

      return availableLayers.reference;
    },
  );
}

export interface LoadingDataState {
  isLoadingData: boolean;
  hasData: boolean;
}

export function makeGetProjectLayersCategoryListLoadingState() {
  return createSelector(
    makeGetProjectState(),
    getAvailableProjectLayersById,
    (projectState, availableLayersState): LoadingDataState => {
      const isLoadingData =
        isLoading(projectState) || isLoading(availableLayersState);
      const hasData =
        !_.isEmpty(getData(projectState)) &&
        !_.isEmpty(getData(availableLayersState));

      return { isLoadingData, hasData };
    },
  );
}

export interface LayerListCategory {
  categoryKey: string;
  categoryLabel: string;
  layerReferences: AnnotatedLayerReference[];
  collapsed?: boolean;
}

export function makeGetProjectLayersCategoryList() {
  const getProjectAvailableReferenceLayerItems =
    makeGetProjectAvailableReferenceLayerItems();
  return createSelector(
    getProjectAvailableReferenceLayerItems,
    (
      availableReferenceLayerItems: ProjectAvailableLayerItem[],
    ): LayerListCategory[] => {
      if (_.isEmpty(availableReferenceLayerItems)) {
        return EMPTY_ARRAY;
      }

      const categorizedReferenceLayers = groupByCategory(
        availableReferenceLayerItems,
      );

      // TODO: Combine categorized layers from other types, eg: working, analysis, etc...
      const combinedCategories = categorizedReferenceLayers;

      return _.map(
        combinedCategories,
        (category, categoryKey): LayerListCategory => ({
          categoryKey,
          categoryLabel: getCategoryLabel(categoryKey),
          layerReferences: sortLayerList(category),
        }),
      );
    },
  );
}

/*
 * Helpers
 */

export interface AnnotatedLayerReference extends ProjectAvailableLayerItem {
  active?: boolean;
  restricted?: boolean;
}

function groupByCategory(
  layers: ProjectAvailableLayerItem[],
): Record<string, AnnotatedLayerReference[]> {
  return _.groupBy<AnnotatedLayerReference>(layers, ({ layer }) => {
    return layer?.details?.display_hints?.layer_category;
  });
}

function sortLayerList(
  layers: AnnotatedLayerReference[],
): AnnotatedLayerReference[] {
  // TODO build a custom sorting for scale based on feedback
  return _.orderBy<AnnotatedLayerReference>(
    layers,
    [
      layer => layer?.layer?.details?.display_hints?.key_group,
      layer => layer?.layer?.details?.display_hints?.vintage,
      layer => layer?.layer?.details?.name,
    ],
    ['asc', 'desc', 'asc'],
  );
}
