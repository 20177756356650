import _ from 'lodash';

import { LayerReference } from 'uf-api/model/models';

/**
 * A single item in a list of layers.
 */
export interface LayerListItem {
  /**
   * Brief description of the layer.
   */
  description?: string;
  /**
   * Simply namespace, layer_type, and key joined by \"/\"
   */
  full_path?: string;
  /**
   * The canonical identifier to the layer.
   */
  key?: string;
  /**
   * The latest version available for this layer.
   */
  latest_version?: string;
  /**
   * The type of layer - eg. 'dataset', 'analysis', etc.
   */
  layer_type?: string;
  /**
   * The human readable name of the layer.
   */
  name?: string;
  /**
   * The namespace of a layer - normally a group or a user name.
   */
  namespace?: string;
  /**
   * The URL template for the tile, with {z}/{x}/{y} formatting
   */
  tile_url?: string;
  /**
   * Pinned version of this layer.
   */
  version?: string;
}

export function convertLayerListItemToLayerReference(
  layer: LayerListItem | LayerReference,
): LayerReference {
  if (!layer) {
    return null;
  }

  // remove this once backend code lands that converts built_forms_layer to a LayerReference
  if (isLayerReference(layer)) {
    return layer;
  }

  const {
    full_path: layerId,
    key,
    layer_type: layerType,
    namespace,
    version,
    latest_version: latestVersion,
    name,
    tile_url: tileUrl,
  } = layer;

  const layerReference: LayerReference = {
    details: {
      display_hints: {},
      latest_version: latestVersion,
      name,
      tile_url: tileUrl,
    },
    full_path: layerId,
    key,
    layer_type: layerType,
    namespace,
    version,
  };

  return layerReference;
}

function isLayerReference(layer): layer is LayerReference {
  if (!_.isEmpty(layer.details)) {
    return true;
  }

  return false;
}
