import { Button } from '@blueprintjs/core';
import classNames from 'classnames';
import copy from 'copy-to-clipboard';
import React, {
  CSSProperties,
  FunctionComponent,
  ReactNode,
  useCallback,
  useState,
} from 'react';

import { assertNever } from 'uf/base/never';
import tw from 'uf/tailwindcss-classnames';
import { useTimeout } from 'uf/ui/base/useTimeout/useTimeout';

interface Props {
  text: string;
  /** String to show instead of the text, in case it is expected to be big */
  altText?: ReactNode;
  fontStyle?: 'normal' | 'code';
  className?: string;
  style?: CSSProperties;
}

const CLEAR_COPY_ICON_TIMEOUT = 2000;
/**
 * A component to display text and a button which copies that text to the
 * clipboard. Usually this text is a code of some kind so the default style is a
 * `<code>` which makes it monospace. To use a `<span>`, use
 * `fontStyle="normal"`.
 */
export const CopyableText: FunctionComponent<Props> = props => {
  const { text, altText, fontStyle = 'code', className, style } = props;
  const [copied, setCopied] = useState(false);
  const clearCopied = useCallback(() => setCopied(false), []);
  const clearSetting = useTimeout(clearCopied, CLEAR_COPY_ICON_TIMEOUT);

  const hoverClass = classNames(
    className,
    // only show hover when the actual text is on the screen
    altText ? null : tw('group-hover:tw-bg-blueGray-200'),
  );
  const onClick = useCallback(
    e => {
      // Prevent the click from bubbling up and dismissing tooltips or opening
      // menus
      e.stopPropagation();
      setCopied(true);
      copy(text);
      clearSetting();
    },
    [clearSetting, text],
  );

  if (!text) {
    return null;
  }

  const button = (
    <Button
      small
      minimal
      icon={copied ? 'saved' : 'clipboard'}
      onClick={onClick}
    />
  );

  switch (fontStyle) {
    case 'normal':
      return (
        <span className={tw('tw-group', 'tw-select-all')}>
          <span className={hoverClass} style={style}>
            {altText ?? text}
          </span>{' '}
          {button}
        </span>
      );

    case 'code': {
      return (
        <span className={tw('tw-group', 'tw-select-all')}>
          <code className={hoverClass} style={style}>
            {altText ?? text}
          </code>{' '}
          {button}
        </span>
      );
    }
    default:
      assertNever(fontStyle);
      return null;
  }
};
