import { symbologyTypeSupportsOpacity } from 'uf/symbology/helpers';

export default function updateOpacity(symbology, { opacity }) {
  const { type: symbologyType } = symbology;
  if (!symbologyTypeSupportsOpacity(symbologyType)) {
    return symbology;
  }

  const paintOpacityProperty = `${symbologyType}-opacity`;

  const updatedSymbology = {
    ...symbology,
    paint: {
      ...symbology.paint,
      [paintOpacityProperty]: opacity,
    },
  };

  return updatedSymbology;
}
