import { LayerColumnSymbology } from 'uf/symbology';

export function updatePointSize(
  symbology: LayerColumnSymbology,
  size: number,
): LayerColumnSymbology {
  if (symbology.type !== 'circle') {
    return symbology;
  }

  const newSymbology: LayerColumnSymbology = {
    ...symbology,
    paint: {
      ...symbology.paint,
      'circle-radius': size,
    },
  };

  return newSymbology;
}
