import colors from 'material-colors';

import { DEFAULT_GEO_COLUMN_KEY } from 'uf/layers/geometryKey';
import {
  DEFAULT_OPACITY,
  InterpolationTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const cityParcelList = [
  'oakland',
  'albuquerque',
  'arlington',
  'austin',
  'baltimore',
  'boulder',
  'chattanooga',
  'chesapeake',
  'chicago',
  'cleveland',
  'detroit',
  'hartford',
  'johns_creek',
  'lexington',
  'los_angeles',
  'new_orleans',
  'new_york_city',
  'norfolk',
  'oakland',
  'philadelphia',
  'pittsburgh',
  'providence',
  'raleigh',
  'salt_lake_city',
  'san_jose',
  'virginia_beach',
  'washington',
];

const symbologies = {};

cityParcelList.forEach(cityName => {
  symbologies[`/public/dataset/parcels_${cityName}`] = {
    [DEFAULT_GEO_COLUMN_KEY]: [
      {
        type: SymbologyTypes.LINE,
        paint: {
          'line-width': 1,
          'line-color': colors.grey[600],
          'line-opacity': DEFAULT_OPACITY,
        },
        display_hints: {
          scale: SymbologyScales.CATEGORICAL,
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          theme: 'material:grey:600',
        },
      },
    ],
  };
});

export default symbologies;
