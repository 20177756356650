/**
 * UrbanFootprint App API
 * API for serving UrbanFootprint Application
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { GeoJSONGeometry } from './geoJSONGeometry';

/**
 * Return a PostGIS Geometry
 */
export interface ExpressionGeometry<> {
  /**
   * Expression function, set to \"geometry\".
   */
  fn: ExpressionGeometry.FnEnum;
  geometry: GeoJSONGeometry;
}
export namespace ExpressionGeometry {
  export type FnEnum = 'geometry';
  export const FnEnum = {
    Geometry: 'geometry',
  } as const;
}
