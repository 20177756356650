import { Button, Position, Tooltip } from '@blueprintjs/core';
import classNames from 'classnames';
import React, { FunctionComponent, ReactNode } from 'react';

import rootStyles from 'uf/styles/root.module.css';
import { GetComponentProps } from 'uf/ui/base/types';
import WithTooltip, {
  TooltipTestOptions,
} from 'uf/ui/base/WithTooltip/WithTooltip';

interface OwnProps {
  // WithTooltip Props
  tooltipText?: ReactNode;
  hideTooltip?: boolean;
  placement?: Position;
  testOptions?: TooltipTestOptions;
  containerClassName?: string;
  targetClassName?: string;

  // [extraProps: string]: any;

  icon?: React.ReactNode;
  text?: string;
}

type WithTooltipButtonProps = OwnProps &
  GetComponentProps<Button> &
  Omit<GetComponentProps<Tooltip>, 'content'>;

const WithTooltipButton: FunctionComponent<WithTooltipButtonProps> = props => {
  const {
    // ownProps
    tooltipText,
    hideTooltip,
    placement = 'top',
    testOptions,
    children,
    className,
    disabled,
    containerClassName,
    minimal,
    small,
    targetTagName,
    targetClassName,
    wrapperTagName,
    icon,
    text,
    fill,
    popoverClassName,
    // buttonProps
    ...buttonProps
  } = props;
  const buttonClass = classNames(
    {
      [rootStyles.disablePointerEvents]: disabled,
    },
    className,
  );
  const minimalProp = minimal;
  const smallProp = small;
  const intent = null;

  if (!tooltipText) {
    return (
      <Button
        disabled={disabled}
        className={buttonClass}
        minimal={minimalProp}
        small={smallProp}
        intent={intent}
        icon={icon}
        text={text}
        fill={fill}
        {...buttonProps}>
        {children}
      </Button>
    );
  }

  const withTooltipProps = {
    text: tooltipText,
    hide: hideTooltip,
    placement,
    testOptions,
    className: containerClassName,
    targetTagName,
    wrapperTagName,
    targetClassName,
    popoverClassName,
    fill,
  };
  return (
    <WithTooltip {...withTooltipProps}>
      <Button
        disabled={disabled}
        className={buttonClass}
        minimal={minimalProp}
        small={smallProp}
        icon={icon}
        text={text}
        intent={intent}
        {...buttonProps}>
        {children}
      </Button>
    </WithTooltip>
  );
};
export default WithTooltipButton;
