import { Intent } from '@blueprintjs/core';
import classNames from 'classnames';
import _ from 'lodash';
import React, { Fragment } from 'react';
import { Box } from 'react-layout-components';
import { connect } from 'react-redux';
import { t } from 'ttag';

import { ProjectListItem } from 'uf-api';
import { OrganizationInfo } from 'uf-api-management';
import { isLoaded } from 'uf/data/dataState';
import { getProjectList, getProjectsListState } from 'uf/projects/selectors';
import buttonStyles from 'uf/styles/buttons.module.css';
import rootStyles from 'uf/styles/root.module.css';
import textStyles from 'uf/styles/text.module.css';
import SharingCircles from 'uf/ui/collaboration/SharingCircles/SharingCircles';
import DeveloperTools from 'uf/ui/debug/DeveloperTools/DeveloperTools';
import CreateProjectButton, {
  OwnProps as CreateProjectButtonProps,
} from 'uf/ui/projects/CreateProjectButton/CreateProjectButton';
import ProjectMenu from 'uf/ui/projects/ProjectMenu/ProjectMenu';
import UserMenu from 'uf/ui/user/UserMenu/UserMenu';
import { getUserOrganizations } from 'uf/user/selectors/user';

import HelpLink from 'uf/ui/user/HelpLink/HelpLink';
import styles from './Header.module.css';

const headerClass = classNames(
  'HeaderMenu',
  rootStyles.thinMarginRight,
  rootStyles.alignItemsCenter,
  rootStyles.justifyContentCenter,
);
const helpAndUserClass = classNames(rootStyles.shrink0, 'HelpAndUserMenu');

export type HeaderMenuKeys =
  | 'projectMenu'
  | 'subscriptionStatusLink'
  | 'developerTools'
  | 'helpLink'
  | 'sharingCircles'
  | 'userMenu'
  | 'createProjectButton';

export const allHeaderMenus: Record<HeaderMenuKeys, boolean> = {
  projectMenu: true,
  subscriptionStatusLink: true,
  developerTools: true,
  helpLink: true,
  sharingCircles: true,
  userMenu: true,
  createProjectButton: true,
};

interface OwnProps {
  menus?: Record<HeaderMenuKeys, boolean>;
  className?: string;
}

interface StateProps {
  organizations: OrganizationInfo[];
  onlyDemoProjects: boolean;
}

type HeaderMenusProps = OwnProps & StateProps;

export const HeaderMenus: React.FunctionComponent<HeaderMenusProps> = ({
  menus,
  organizations,
  onlyDemoProjects,
  className,
}) => {
  if (_.isEmpty(menus)) {
    return null;
  }

  const createProjectButtonProps: CreateProjectButtonProps['buttonProps'] = {
    intent: Intent.PRIMARY,
    className: textStyles.noWrap,
  };

  const iconClass = classNames(styles.headerIconButton, styles.invertColor);
  const projectMenuClass = buttonStyles.dark;

  return (
    <Box flexShrink={0} className={classNames(className, headerClass)}>
      {menus.projectMenu && <ProjectMenu className={projectMenuClass} />}
      {menus.developerTools && <DeveloperTools className={iconClass} />}
      <Box className={helpAndUserClass}>
        {menus.sharingCircles && (
          <SharingCircles
            maxCircles={5}
            className={rootStyles.thinMarginRight}
          />
        )}
        {menus.helpLink && <HelpLink />}
        {menus.userMenu && (
          <UserMenu
            className={iconClass}
            targetClassName={rootStyles.thinMarginRight}
          />
        )}
      </Box>
      {menus.createProjectButton && (
        <Fragment>
          {!onlyDemoProjects && (
            <div>
              <CreateProjectButton
                buttonProps={createProjectButtonProps}
                organization={organizations}>
                {t`Create project`}
              </CreateProjectButton>
            </div>
          )}
        </Fragment>
      )}
    </Box>
  );
};

function makeMapStateToProps() {
  return (state): StateProps => {
    const projectsListLoaded = isLoaded(getProjectsListState(state));
    const projectList: ProjectListItem[] = getProjectList(state);

    return {
      organizations: getUserOrganizations(state),
      onlyDemoProjects:
        projectsListLoaded &&
        projectList.every(project => project.demo_project),
    };
  };
}

export default connect<StateProps, never, OwnProps>(makeMapStateToProps)(
  HeaderMenus,
);
