import { AnyAction, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { t } from 'ttag';

import { addAppMessage } from 'uf/appservices/actions';
import { UFState } from 'uf/state';
import { getUser, getUserDisplayName } from 'uf/user/selectors/user';

import {
  INITIALIZE_INTERCOM,
  SHOW_INTERCOM,
  UPDATE_INTERCOM,
} from './ActionTypes';

declare global {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Window {
    intercomAppId: string;
    Intercom: any;
  }
}
export function initializeIntercom(): ThunkAction<AnyAction, any, any, any> {
  return (dispatch: Dispatch, getState) => {
    if (!(__CLIENT__ || __TESTING__) || !window.intercomAppId) {
      return;
    }
    if (!window.Intercom) {
      dispatch(
        addAppMessage(
          t`It looks like you have an ad-blocker installed in your browser that is blocking our support tools. Please add UrbanFootprint as an approved site to receive online support.`,
        ),
      );
      return;
    }
    const user = getUser(getState());
    const userName = getUserDisplayName(getState());
    const intercomSettings = {
      app_id: window.intercomAppId,
      name: userName,
      email: user.email,
      user_hash: user.intercom_user_hash,
      alignment: 'left',
      vertical_padding: 35,
      horizontal_padding: 10,
    };
    window.Intercom('boot', intercomSettings);
    return dispatch({
      type: INITIALIZE_INTERCOM,
    });
  };
}

export function showIntercom(message?: string) {
  return {
    type: SHOW_INTERCOM,
    message,
  };
}

export function updateIntercom(): ThunkAction<void, UFState, any, any> {
  return dispatch => {
    window.Intercom.update();
    dispatch({ type: UPDATE_INTERCOM });
  };
}
