import { MenuItem } from '@blueprintjs/core';
import { IItemRendererProps } from '@blueprintjs/select';
import {
  mdiCheck,
  mdiContentCopy,
  mdiDelete,
  mdiLayers,
  mdiPencil,
} from '@mdi/js';
import Icon from '@mdi/react';
import classNames from 'classnames';
import React, { FunctionComponent, useCallback } from 'react';
import { Box } from 'react-layout-components';
import { logEvent } from 'uf/logging';
import { ProjectId } from 'uf/projects';

import { tw } from 'uf/tailwindcss-classnames';
import { IconSizeNew } from 'uf/ui/base/icon';
import NestedIconButton from 'uf/ui/base/NestedIconButton/NestedIconButton';
import { View, ViewId } from 'uf/views';
import { events } from 'uf/views/logging';

interface ViewMenuItemProps {
  view: View;
  description: string;
  itemProps: IItemRendererProps;
  onCopy: (view: View) => void;
  onDelete: (viewId: ViewId) => void;
  onEdit: (viewId: ViewId) => void;
  projectId: ProjectId;
}
export const ViewMenuItem: FunctionComponent<ViewMenuItemProps> = props => {
  const { view, description, itemProps, onCopy, onDelete, onEdit, projectId } =
    props;

  const { key: viewId, name } = view;
  const onCopyView = useCallback(() => {
    logEvent(events.DUPLICATE_VIEW, { view, projectId });
    return onCopy(view);
  }, [view, projectId, onCopy]);
  const onDeleteView = useCallback(() => {
    logEvent(events.DELETE_VIEW, { view, projectId });
    return onDelete(viewId);
  }, [view, projectId, onDelete, viewId]);
  const onEditView = useCallback(() => onEdit(viewId), [viewId, onEdit]);
  return (
    <MenuItem
      text={
        <Box column>
          <span>{name}</span>
          <span className={classNames('h6', tw('tw-text-muted'))}>
            {description}
          </span>
        </Box>
      }
      className={tw('tw-group')}
      onClick={itemProps.handleClick}
      disabled={itemProps.modifiers.disabled}
      icon={
        <Icon
          path={itemProps.modifiers.active ? mdiCheck : mdiLayers}
          className={tw('tw-h-6', 'tw-w-6', {
            'tw-text-primary': itemProps.modifiers.active,
          })}
        />
      }
      labelElement={
        <Box className={tw('tw-invisible', 'group-hover:tw-visible')}>
          {onEdit && (
            <NestedIconButton
              data-role="edit"
              onClick={onEditView}
              iconPath={mdiPencil}
              iconSize={IconSizeNew.SMALL}
            />
          )}
          <NestedIconButton
            data-role="copy"
            onClick={onCopyView}
            iconPath={mdiContentCopy}
            iconSize={IconSizeNew.SMALL}
          />
          {onDelete && (
            <NestedIconButton
              data-role="delete"
              onClick={onDeleteView}
              iconPath={mdiDelete}
              iconSize={IconSizeNew.SMALL}
            />
          )}
        </Box>
      }
    />
  );
};
