import classNames from 'classnames';
import React, { PureComponent } from 'react';
import { Box } from 'react-layout-components';

import rootStyles from 'uf/styles/root.module.css';
import textStyles from 'uf/styles/text.module.css';
import { SeriesLabel } from 'uf/ui/charts/SeriesLabel/SeriesLabel';

interface Props<D> {
  data: D[];

  seriesKeys: string[];
  getSeriesLabel: (key: string) => string;
  getColor: (datum: D, key: string) => string;
}

const seriesClassName = classNames(
  rootStyles.thinPaddingRight,
  rootStyles.veryThinPaddingVertical,
  textStyles.ellipsis,
  textStyles.mutedText,
);
export class ChartLegend<D> extends PureComponent<Props<D>> {
  render() {
    const { seriesKeys, data, getSeriesLabel, getColor } = this.props;

    const datum: D = data.length ? data[0] : ({} as D);

    return (
      <Box wrap className={rootStyles.thinPaddingBottom}>
        {seriesKeys.map(key => (
          <SeriesLabel
            key={key}
            color={getColor(datum, key)}
            label={getSeriesLabel(key)}
            className={seriesClassName}
          />
        ))}
      </Box>
    );
  }
}
