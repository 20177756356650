import {
  Divider,
  H6,
  Icon,
  IconName,
  Menu,
  MenuItem,
  PopoverInteractionKind,
  Position,
  Tooltip,
} from '@blueprintjs/core';
import { ColumnHeaderCell2 as ColumnHeaderCell } from '@blueprintjs/table';
import classNames from 'classnames';
import React, { CSSProperties, Fragment, useCallback } from 'react';
import { Box } from 'react-layout-components';
import { useSelector } from 'react-redux';
import { t } from 'ttag';

import { mdiTransferLeft, mdiTransferRight } from '@mdi/js';
import MdiIcon from '@mdi/react';
import { LayerNumericStats } from 'uf-api';
import { LayerColumn } from 'uf-api-rtk/store/Api';
import { selectActiveLayerId } from 'uf/app/selectors';
import { formatNumeric, formatPercent } from 'uf/base/formatting';
import { getUnitAbbreviation, UnitLabelKeys } from 'uf/base/units';
import { COLUMN_FAVORITE, COLUMN_UNFAVORITE } from 'uf/explore/logging';
import { formatValue } from 'uf/layers/formatting';
import { getIsPercent } from 'uf/layers/metadata';
import { logEvent } from 'uf/logging';
import rootStyles from 'uf/styles/root.module.css';
import { CopyableText } from 'uf/ui/base/CopyableText/CopyableText';
import { IconSizeNew } from 'uf/ui/base/icon';
import { columnClassName } from 'uf/ui/charts/Table/helpers';
import styles from 'uf/ui/charts/Table/Table.module.css';
import { useColumnState } from 'uf/ui/explore/ExploreDataViewer/hooks';

/** Number of milliseconds until the tooltip opens/closes - allows for easier
 * navigation into the tooltip */
const TOOLTIP_INTERACTION_DELAY = 100;

const labelStyle: CSSProperties = {
  height: '30px',
  textAlign: 'center',
  margin: 0,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
};
interface Props {
  column?: LayerColumn;
  style?: CSSProperties;
  statCellValue?: LayerNumericStats;
  currentSummaryStatType?: string;
  columnWidth?: number;
  onFreeze?: (columnKey) => void;
}
const statStyle: CSSProperties = {
  textAlign: 'center',
};

const cellWrapperStyle: CSSProperties = {
  overflow: 'hidden',
  marginBottom: -5,
};
const cellStatStyle: CSSProperties = { fontWeight: 'normal' };

const ExploreDataTableHeaderCell: React.FunctionComponent<Props> = ({
  column,
  currentSummaryStatType,
  statCellValue,
  style,
  onFreeze,
  ...headerProps
}) => {
  const { type, units, name, key: columnKey } = column;
  const {
    sortDirection,
    sortColumn,
    setColumnState,
    setSortColumn,
    columnsById,
  } = useColumnState();
  const columnState = columnsById[columnKey];
  const layerId = useSelector(selectActiveLayerId);
  const isActive = sortColumn === column?.key;
  const className = classNames(
    columnClassName(type),
    styles.header,
    styles.active,
    columnState?.isFavorite && styles.favorite,
  );
  const statValue =
    (getIsPercent(column) && currentSummaryStatType === 'sum') || !statCellValue
      ? '-'
      : formatValue(statCellValue[currentSummaryStatType], column);
  const text = name || columnKey;
  const iconType = type === 'string' ? 'sort-alphabetical' : 'sort-numerical';
  const handleSort = useCallback(
    e => {
      const { direction } = e.currentTarget.dataset;
      setSortColumn(columnKey, direction);
    },
    [columnKey, setSortColumn],
  );
  const handleFavorite = () => {
    const newFavoriteStatus = columnState ? !columnState.isFavorite : true;
    setColumnState(columnKey, {
      isFavorite: newFavoriteStatus,
    });
    logEvent(newFavoriteStatus ? COLUMN_FAVORITE : COLUMN_UNFAVORITE, {
      layerId,
      columnKey,
    });
  };

  function renderMenu() {
    return (
      <Menu data-testid="column-menu" className={styles.menu}>
        <MenuItem
          data-testid="sort-menu-item-asc"
          icon={iconType}
          data-direction="asc"
          onClick={handleSort}
          active={isActive && sortDirection === 'asc'}
          text="Sort ascending"
        />
        <MenuItem
          data-testid="sort-menu-item-desc"
          icon={`${iconType}-desc` as IconName}
          data-direction="desc"
          onClick={handleSort}
          active={isActive && sortDirection === 'desc'}
          text="Sort descending"
        />
        <Divider />
        <MenuItem
          data-testid="column-menu-freeze"
          icon={
            <MdiIcon
              size={IconSizeNew.SMALL}
              path={!columnState?.isFrozen ? mdiTransferLeft : mdiTransferRight}
            />
          }
          onClick={() => onFreeze(columnKey)}
          text={!columnState?.isFrozen ? 'Freeze column' : 'Unfreeze column'}
        />
        <MenuItem
          data-testid="column-menu-favorite"
          icon={!columnState?.isFavorite ? 'star' : 'star-empty'}
          onClick={handleFavorite}
          text={
            !columnState?.isFavorite ? 'Favorite column' : 'Unfavorite column'
          }
        />
      </Menu>
    );
  }
  function renderColumnLabel(label) {
    return <div style={labelStyle}>{label}</div>;
  }
  return (
    <Tooltip
      disabled={!text}
      position={Position.TOP}
      boundary="viewport"
      interactionKind={PopoverInteractionKind.HOVER}
      hoverCloseDelay={TOOLTIP_INTERACTION_DELAY}
      hoverOpenDelay={TOOLTIP_INTERACTION_DELAY}
      content={
        <TableTooltip
          title={text}
          columnId={columnKey}
          units={units}
          isPercent={getIsPercent(column)}
          stats={statCellValue}
        />
      }>
      <div style={{ ...style, ...cellWrapperStyle }}>
        <ColumnHeaderCell
          data-testid="data-table-stat-value"
          enableColumnReordering={false}
          reorderHandle={null}
          style={statStyle}>
          <span style={cellStatStyle}>{statValue}</span>
        </ColumnHeaderCell>
        <ColumnHeaderCell
          {...headerProps}
          data-testid="data-table-column-name"
          className={className}
          name={text}
          nameRenderer={renderColumnLabel}
          menuRenderer={renderMenu}
          isActive={isActive}
          menuIcon={
            <Icon
              title={`column-menu-${isActive ? 'active' : 'passive'}`}
              // eslint-disable-next-line @typescript-eslint/no-magic-numbers
              iconSize={isActive ? 12 : 16}
              icon={
                isActive
                  ? (`${iconType}${
                      sortDirection === 'desc' ? '-desc' : ''
                    }` as IconName)
                  : 'chevron-down'
              }
            />
          }
        />
      </div>
    </Tooltip>
  );
};
export default ExploreDataTableHeaderCell;
interface TableTooltipProps {
  title?: string;
  columnId?: string;
  stats?: any;
  isPercent?: boolean;
  units: string;
}

const TableTooltip: React.FunctionComponent<TableTooltipProps> = ({
  title,
  columnId,
  stats,
  isPercent,
  units,
}) => {
  const formatFn = isPercent ? formatPercent : formatNumeric;
  const unitText = getUnitAbbreviation(units as UnitLabelKeys) ?? units ?? '';
  return (
    <Box column justifyContent="center">
      {title && <H6 style={{ color: 'white' }}>{title}</H6>}
      {columnId && (
        <div>
          (<code>{columnId}</code>)
        </div>
      )}
      {stats && (
        <Fragment>
          {!isPercent && (
            <Box justifyContent="space-between">
              <span className={rootStyles.thinPaddingRight}>{t`Sum:`}</span>
              <span>
                <CopyableText
                  fontStyle="normal"
                  text={`${stats.sum}`}
                  altText={`${formatNumeric(stats.sum)} ${unitText}`}
                />
              </span>
            </Box>
          )}
          <Box justifyContent="space-between">
            <span className={rootStyles.thinPaddingRight}>{t`Mean:`}</span>
            <span>
              <CopyableText
                fontStyle="normal"
                text={`${stats.avg}`}
                altText={`${formatFn(stats.avg)} ${unitText}`}
              />
            </span>
          </Box>
          <Box justifyContent="space-between">
            <span className={rootStyles.thinPaddingRight}>{t`Range:`}</span>
            <span>
              <CopyableText
                fontStyle="normal"
                text={`${stats.min}, ${stats.max}`}
                altText={`${formatFn(stats.min)} - ${formatFn(
                  stats.max,
                )} ${unitText}`}
              />
            </span>
          </Box>
        </Fragment>
      )}
    </Box>
  );
};
