import colors from 'material-colors';
import { t } from 'ttag';

import { LegacyChartSeries, LegacyReport } from 'uf/reporting/index';

const SOURCE = 'analysis';
const SOURCEKEY = 'transit_accessibility';

const schoolAccess: LegacyChartSeries = {
  key: 'percent_of_population_in_20_min_transit_range_schools',
  source: SOURCE,
  sourceKey: SOURCEKEY,
  layerKey: 'percent_access_poi_school',
  columnKey: 'school_20_minutes',
};

const hospitalAccess: LegacyChartSeries = {
  key: 'percent_of_population_in_30_min_transit_range_hospitals',
  source: SOURCE,
  sourceKey: SOURCEKEY,
  layerKey: 'percent_access_poi_hospital',
  columnKey: 'hospital_30_minutes',
};

const poi1Access: LegacyChartSeries = {
  key: 'percent_of_population_in_30_min_transit_range_poi_1',
  source: SOURCE,
  sourceKey: SOURCEKEY,
  layerKey: 'percent_access_poi_poi_1',
  columnKey: 'poi_1_30_minutes',
};

const poi2Access: LegacyChartSeries = {
  key: 'percent_of_population_in_30_min_transit_range_poi_2',
  source: SOURCE,
  sourceKey: SOURCEKEY,
  layerKey: 'percent_access_poi_poi_2',
  columnKey: 'poi_2_30_minutes',
};

const poi3Access: LegacyChartSeries = {
  key: 'percent_of_population_in_30_min_transit_range_poi_3',
  source: SOURCE,
  sourceKey: SOURCEKEY,
  layerKey: 'percent_access_poi_poi_3',
  columnKey: 'poi_3_30_minutes',
};

const retailAccess: LegacyChartSeries = {
  key: 'percent_of_population_in_10_min_transit_range_retail',
  source: SOURCE,
  sourceKey: SOURCEKEY,
  layerKey: 'percent_access_poi_retail',
  columnKey: 'retail_10_minutes',
};

const parkAccess: LegacyChartSeries = {
  key: 'percent_of_population_in_10_min_transit_range_park',
  source: SOURCE,
  sourceKey: SOURCEKEY,
  layerKey: 'percent_access_poi_park',
  columnKey: 'park_10_minutes',
};

const schoolSeries: LegacyChartSeries[] = ['10', '20', '30'].map(key => ({
  key: `percent_of_population_in_${key}_min_transit_range_schools`,
  source: SOURCE,
  sourceKey: SOURCEKEY,
  layerKey: 'percent_access_poi_school',
  columnKey: `school_${key}_minutes`,
}));

const hospitalSeries: LegacyChartSeries[] = ['10', '20', '30'].map(key => ({
  key: `percent_of_population_in_${key}_min_transit_range_hospitals`,
  source: SOURCE,
  sourceKey: SOURCEKEY,
  layerKey: 'percent_access_poi_hospital',
  columnKey: `hospital_${key}_minutes`,
}));

const poi1Series: LegacyChartSeries[] = ['10', '20', '30'].map(key => ({
  key: `percent_of_population_in_${key}_min_transit_range_poi_1`,
  source: SOURCE,
  sourceKey: SOURCEKEY,
  layerKey: 'percent_access_poi_poi_1',
  columnKey: `poi_1_${key}_minutes`,
}));

const poi2Series: LegacyChartSeries[] = ['10', '20', '30'].map(key => ({
  key: `percent_of_population_in_${key}_min_transit_range_poi_2`,
  source: SOURCE,
  sourceKey: SOURCEKEY,
  layerKey: 'percent_access_poi_poi_2',
  columnKey: `poi_2_${key}_minutes`,
}));

const poi3Series: LegacyChartSeries[] = ['10', '20', '30'].map(key => ({
  key: `percent_of_population_in_${key}_min_transit_range_poi_3`,
  source: SOURCE,
  sourceKey: SOURCEKEY,
  layerKey: 'percent_access_poi_poi_3',
  columnKey: `poi_3_${key}_minutes`,
}));

const restaurantSeries: LegacyChartSeries[] = ['10', '20', '30'].map(key => ({
  key: `percent_of_population_in_${key}_min_transit_range_restaurants`,
  source: SOURCE,
  sourceKey: SOURCEKEY,
  layerKey: 'percent_access_poi_restaurant',
  columnKey: `restaurant_${key}_minutes`,
}));

const retailSeries: LegacyChartSeries[] = ['10', '20', '30'].map(key => ({
  key: `percent_of_population_in_${key}_min_transit_range_retails`,
  source: SOURCE,
  sourceKey: SOURCEKEY,
  layerKey: 'percent_access_poi_retail',
  columnKey: `retail_${key}_minutes`,
}));

const parkSeries: LegacyChartSeries[] = ['10', '20', '30'].map(key => ({
  key: `percent_of_population_in_${key}_min_transit_range_parks`,
  source: SOURCE,
  sourceKey: SOURCEKEY,
  layerKey: 'percent_access_poi_park',
  columnKey: `park_${key}_minutes`,
}));

const jobAccess10PctSeries: LegacyChartSeries[] = ['15', '30', '45', '60'].map(
  key => ({
    key: `proportional_job_access_by_transit_10_pct_${key}_min`,
    source: SOURCE,
    sourceKey: SOURCEKEY,
    layerKey: 'proportional_job_access_10',
    columnKey: `percentage_${key}_minutes`,
  }),
);

const jobAccess25PctSeries: LegacyChartSeries[] = ['15', '30', '45', '60'].map(
  key => ({
    key: `proportional_job_access_by_transit_25_pct_${key}_min`,
    source: SOURCE,
    sourceKey: SOURCEKEY,
    layerKey: 'proportional_job_access_25',
    columnKey: `percentage_${key}_minutes`,
  }),
);

const jobAccess50PctSeries: LegacyChartSeries[] = ['15', '30', '45', '60'].map(
  key => ({
    key: `proportional_job_access_by_transit_50_pct_${key}_min`,
    source: SOURCE,
    sourceKey: SOURCEKEY,
    layerKey: 'proportional_job_access_50',
    columnKey: `percentage_${key}_minutes`,
  }),
);

const jobAccess10Pct: LegacyChartSeries = {
  key: 'proportional_job_access_by_transit_10_pct',
  source: SOURCE,
  sourceKey: SOURCEKEY,
  layerKey: 'proportional_job_access_10',
  columnKey: 'percentage_30_minutes',
};

const jobAccess25Pct: LegacyChartSeries = {
  key: 'proportional_job_access_by_transit_25_pct',
  source: SOURCE,
  sourceKey: SOURCEKEY,
  layerKey: 'proportional_job_access_25',
  columnKey: 'percentage_30_minutes',
};

const jobAccess50Pct: LegacyChartSeries = {
  key: 'proportional_job_access_by_transit_50_pct',
  source: SOURCE,
  sourceKey: SOURCEKEY,
  layerKey: 'proportional_job_access_50',
  columnKey: 'percentage_30_minutes',
};

export const accessibilityTransitCharts: LegacyReport = Object.freeze({
  key: SOURCEKEY,
  name: 'Transit Accessibility',
  colorSpec: {
    primaryColor: 'rgb(66, 164, 245)',
    interpolateFromColor: colors.purple[100],
    interpolateToColor: 'rgb(0, 105, 93)',
  },
  charts: [
    {
      key: 'pct_access_park',
      description: t`Percent of residents that can access a park within 10 minutes.`,
      subtitle: t`Percent of residents within 10 minutes`,
      name: t`Parks Access`,
      series: [parkAccess],
    },
    {
      key: 'pct_access_school',
      description: t`Percent of residents that can access a school within 20 minutes.`,
      subtitle: t`Percent of residents within 20 minutes`,
      name: t`Schools Access`,
      series: [schoolAccess],
    },
    {
      key: 'pct_access_hospital',
      description: t`Percent of residents that can access a hospital within 30 minutes.`,
      subtitle: t`Percent of residents within 30 minutes`,
      name: t`Hospitals Access`,
      series: [hospitalAccess],
    },
    {
      key: 'pct_access_poi_1',
      description: t`Percent of residents that can access POI #1 within 30 minutes.`,
      subtitle: t`Percent of residents within 30 minutes`,
      name: t`POI #1 Access`,
      series: [poi1Access],
      hideZeroValues: true,
    },
    {
      key: 'pct_access_poi_2',
      description: t`Percent of residents that can access POI #2 within 30 minutes.`,
      subtitle: t`Percent of residents within 30 minutes`,
      name: t`POI #2 Access`,
      series: [poi2Access],
      hideZeroValues: true,
    },
    {
      key: 'pct_access_poi_3',
      description: t`Percent of residents that can access POI #3 within 30 minutes.`,
      subtitle: t`Percent of residents within 30 minutes`,
      name: t`POI #3 Access`,
      series: [poi3Access],
      hideZeroValues: true,
    },
    {
      key: 'pct_access_retail',
      description: t`Percent of residents that can access nth nearest retail within 10 minutes.`,
      subtitle: t`Percent of residents within 10 minutes`,
      name: t`Retail Access`,
      series: [retailAccess],
    },
    {
      key: 'proportional_job_access_by_transit_10_pct',
      description: t`Percent of residents able to reach at least 10% of total area jobs within 30 min.`,
      subtitle: t`Jobs within 30 minutes`,
      name: t`Employment Access: 10+ Percent`,
      series: [jobAccess10Pct],
    },
    {
      key: 'proportional_job_access_by_transit_25_pct',
      description: t`Percent of residents able to reach at least 25% of total area jobs within 30 min.`,
      subtitle: t`Jobs within 30 minutes`,
      name: t`Employment Access: 25+ Percent`,
      series: [jobAccess25Pct],
    },
    {
      key: 'proportional_job_access_by_transit_50_pct',
      description: t`Percent of residents able to reach at least 50% of total area jobs within 30 min.`,
      subtitle: t`Jobs within 30 minutes`,
      name: t`Employment Access: 50+ Percent`,
      series: [jobAccess50Pct],
    },
  ],
});

export const accessibilityTransitAnalysisCharts: LegacyReport = Object.freeze({
  key: SOURCEKEY,
  name: 'Transit Accessibility',
  charts: [
    {
      key: 'pct_access_park',
      description: t`Percent of residents that can access a
    park within a specific time frame.`,
      subtitle: t`Percent of residents within X minutes walk and transit`,
      name: t`Parks Access`,
      series: parkSeries,
    },
    {
      key: 'pct_access_school',
      description: t`Percent of residents that can access a
    school within a specific time frame.`,
      subtitle: t`Percent of residents within X minutes walk and transit`,
      name: t`Schools Access`,
      series: schoolSeries,
    },
    {
      key: 'pct_access_hospital',
      description: t`Percent of residents that can access a
    hospital within a specific time frame.`,
      subtitle: t`Percent of residents within X minutes walk and transit`,
      name: t`Hospitals Access`,
      series: hospitalSeries,
    },
    {
      key: 'pct_access_poi_1',
      description: t`Percent of residents that can access POI #1 within a specific time frame.`,
      subtitle: t`Percent of residents within X minutes walk and transit`,
      name: t`POI #1 Access`,
      series: poi1Series,
      hideZeroValues: true,
    },
    {
      key: 'pct_access_poi_2',
      description: t`Percent of residents that can access POI #2 within a specific time frame.`,
      subtitle: t`Percent of residents within X minutes walk and transit`,
      name: t`POI #2 Access`,
      series: poi2Series,
      hideZeroValues: true,
    },
    {
      key: 'pct_access_poi_3',
      description: t`Percent of residents that can access POI #3 within a specific time frame.`,
      subtitle: t`Percent of residents within X minutes walk and transit`,
      name: t`POI #3 Access`,
      series: poi3Series,
      hideZeroValues: true,
    },
    {
      key: 'pct_access_restaurant',
      description: t`Percent of residents that can access a
    restaurant within a specific time frame.`,
      subtitle: t`Percent of residents within X minutes walk and transit`,
      name: t`Restaurants Access`,
      series: restaurantSeries,
    },
    {
      key: 'pct_access_retail',
      description: t`Percent of residents that can access nth nearest
    retail within a specific time frame.`,
      subtitle: t`Percent of residents within X minutes walk and transit`,
      name: t`Retail Access`,
      series: retailSeries,
    },
    {
      key: 'job_access_10_pct',
      description: t`Percentage of residents that can access at least
    10% of all jobs in project area within a specific time frame.`,
      name: t`Employment Access: 10+ Percent`,
      subtitle: t`Percent of residents that can reach 10% or more of area's jobs within X minutes`,
      series: jobAccess10PctSeries,
    },
    {
      key: 'job_access_25_pct',
      description: t`Percentage of residents that can access at least
    25% of all jobs in project area within a specific time frame.`,
      name: t`Employment Access: 25+ Percent`,
      subtitle: t`Percent of residents that can reach 25% or more of area's jobs within X minutes`,
      series: jobAccess25PctSeries,
    },
    {
      key: 'job_access_50_pct',
      description: t`Percentage of residents that can access at least
    50% of all jobs in project area within a specific time frame.`,
      name: t`Employment Access: 50+ Percent`,
      subtitle: t`Percent of residents that can reach 50% or more of area's jobs within X minutes`,
      series: jobAccess50PctSeries,
    },
  ],
});
