import {
  CuratedColumnSymbology,
  CUSTOM_THEME,
  InterpolationTypes,
  PaintColorTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/public/dataset/cal_fire_fire_hazard_severity_zones___california_aggregated':
    {
      haz_class: [
        {
          type: SymbologyTypes.FILL,
          paint: {
            'fill-color': {
              property: 'haz_class',
              type: PaintColorTypes.CATEGORICAL,
              stops: [
                { value: 'Urban Unzoned', color: 'transparent' },
                { value: 'Non-Wildland/Non-Urban', color: 'transparent' },
                { value: 'Moderate', color: '#FF7F0E' },
                { value: 'High', color: '#D33115' },
                { value: 'Very High', color: '#9F0500' },
              ],
            },
            'fill-opacity': 0.5,
          },
          display_hints: {
            scale: SymbologyScales.CATEGORICAL,
            distribution: null,
            interpolation: InterpolationTypes.CATEGORICAL,
            theme: CUSTOM_THEME,
          },
        },
        {
          type: SymbologyTypes.LINE,
          paint: {
            'line-color': {
              property: 'haz_class',
              type: PaintColorTypes.CATEGORICAL,
              stops: [
                { value: 'Urban Unzoned', color: 'transparent' },
                { value: 'Non-Wildland/Non-Urban', color: 'transparent' },
                { value: 'Moderate', color: '#FF7F0E' },
                { value: 'High', color: '#D33115' },
                { value: 'Very High', color: '#9F0500' },
              ],
            },
            'line-opacity': 0.5,
          },
          display_hints: {
            scale: SymbologyScales.CATEGORICAL,
            distribution: null,
            interpolation: InterpolationTypes.CATEGORICAL,
            theme: CUSTOM_THEME,
          },
        },
      ],
    },
};

export default symbologies;
