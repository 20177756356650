import colors from 'material-colors';
import { t } from 'ttag';

import { ACRES, DOLLARS, getUnitName } from 'uf/base/units';
import { LegacyReport } from 'uf/reporting/index';

import { defaultEmptyChartContent } from './emptyChartMessage';

const IN_SENTENCE = true;
const tncSource = {
  source: 'analysis',
  sourceKey: 'tnc_conservation',
} as const;

const ConversionNetFmmpLocalFromAgToUrbanSeries = {
  ...tncSource,
  key: 'tnc_conservation_agro_conversion_netfmmp_local_from_ag_to_urban',
  layerKey: 'tnc_conservation_agro_conversion_net',
  columnKey: 'fmmp_local_from_ag_to_urban',
  color: colors.yellow[200],
};

const ConversionNetFmmpPrimeFromAgToUrbanSeries = {
  ...tncSource,
  key: 'tnc_conservation_agro_conversion_netfmmp_prime_from_ag_to_urban',
  layerKey: 'tnc_conservation_agro_conversion_net',
  columnKey: 'fmmp_prime_from_ag_to_urban',
  color: colors.lightGreen[400],
};

const ConversionNetFmmpStateFromAgToUrbanSeries = {
  ...tncSource,
  key: 'tnc_conservation_agro_conversion_netfmmp_state_from_ag_to_urban',
  layerKey: 'tnc_conservation_agro_conversion_net',
  columnKey: 'fmmp_state_from_ag_to_urban',
  color: colors.lime[300],
};

const ConversionNetFmmpUniqueFromAgToUrbanSeries = {
  ...tncSource,
  key: 'tnc_conservation_agro_conversion_netfmmp_unique_from_ag_to_urban',
  layerKey: 'tnc_conservation_agro_conversion_net',
  columnKey: 'fmmp_unique_from_ag_to_urban',
  color: colors.lime[100],
};

const ProductionTotalAgCroplandSeries = {
  ...tncSource,
  key: 'tnc_conservation_agro_production_totalag_cropland',
  layerKey: 'tnc_conservation_agro_production_total',
  columnKey: 'ag_cropland',
  color: colors.lime[200],
};

const ProductionTotalAgDeciduousOrchardSeries = {
  ...tncSource,
  key: 'tnc_conservation_agro_production_totalag_deciduous_orchard',
  layerKey: 'tnc_conservation_agro_production_total',
  columnKey: 'ag_deciduous_orchard',
  color: colors.green[900],
};

const ProductionTotalAgDrylandGrainCropsSeries = {
  ...tncSource,
  key: 'tnc_conservation_agro_production_totalag_dryland_grain_crops',
  layerKey: 'tnc_conservation_agro_production_total',
  columnKey: 'ag_dryland_grain_crops',
  color: colors.lime[600],
};

const ProductionTotalAgEvergreenOrchardSeries = {
  ...tncSource,
  key: 'tnc_conservation_agro_production_totalag_evergreen_orchard',
  layerKey: 'tnc_conservation_agro_production_total',
  columnKey: 'ag_evergreen_orchard',
  color: colors.green[600],
};

const ProductionTotalAgIrrigatedGrainCropsSeries = {
  ...tncSource,
  key: 'tnc_conservation_agro_production_totalag_irrigated_grain_crops',
  layerKey: 'tnc_conservation_agro_production_total',
  columnKey: 'ag_irrigated_grain_crops',
  color: colors.lime[900],
};

const ProductionTotalAgIrrigatedHayfieldSeries = {
  ...tncSource,
  key: 'tnc_conservation_agro_production_totalag_irrigated_hayfield',
  layerKey: 'tnc_conservation_agro_production_total',
  columnKey: 'ag_irrigated_hayfield',
  color: colors.lime[400],
};

const ProductionTotalAgIrrigatedRowAndFieldCropsSeries = {
  ...tncSource,
  key: 'tnc_conservation_agro_production_totalag_irrigated_row_and_field_crops',
  layerKey: 'tnc_conservation_agro_production_total',
  columnKey: 'ag_irrigated_row_and_field_crops',
  color: colors.lime[800],
};

const ProductionTotalAgOrchardVineyardSeries = {
  ...tncSource,
  key: 'tnc_conservation_agro_production_totalag_orchard_vineyard',
  layerKey: 'tnc_conservation_agro_production_total',
  columnKey: 'ag_orchard_vineyard',
  color: colors.lightGreen[800],
};

const ProductionTotalAgRiceSeries = {
  ...tncSource,
  key: 'tnc_conservation_agro_production_totalag_rice',
  layerKey: 'tnc_conservation_agro_production_total',
  columnKey: 'ag_rice',
  color: colors.grey[300],
};

const ProductionTotalAgVineyardSeries = {
  ...tncSource,
  key: 'tnc_conservation_agro_production_totalag_vineyard',
  layerKey: 'tnc_conservation_agro_production_total',
  columnKey: 'ag_vineyard',
  color: colors.purple[700],
};

const ProductionNetAgCroplandSeries = {
  ...tncSource,
  key: 'tnc_conservation_agro_production_netag_cropland',
  layerKey: 'tnc_conservation_agro_production_net',
  columnKey: 'ag_cropland',
  color: colors.lime[200],
};

const ProductionNetAgDeciduousOrchardSeries = {
  ...tncSource,
  key: 'tnc_conservation_agro_production_netag_deciduous_orchard',
  layerKey: 'tnc_conservation_agro_production_net',
  columnKey: 'ag_deciduous_orchard',
  color: colors.green[900],
};

const ProductionNetAgDrylandGrainCropsSeries = {
  ...tncSource,
  key: 'tnc_conservation_agro_production_netag_dryland_grain_crops',
  layerKey: 'tnc_conservation_agro_production_net',
  columnKey: 'ag_dryland_grain_crops',
  color: colors.lime[600],
};

const ProductionNetAgEvergreenOrchardSeries = {
  ...tncSource,
  key: 'tnc_conservation_agro_production_netag_evergreen_orchard',
  layerKey: 'tnc_conservation_agro_production_net',
  columnKey: 'ag_evergreen_orchard',
  color: colors.green[600],
};

const ProductionNetAgIrrigatedGrainCropsSeries = {
  ...tncSource,
  key: 'tnc_conservation_agro_production_netag_irrigated_grain_crops',
  layerKey: 'tnc_conservation_agro_production_net',
  columnKey: 'ag_irrigated_grain_crops',
  color: colors.lime[900],
};

const ProductionNetAgIrrigatedHayfieldSeries = {
  ...tncSource,
  key: 'tnc_conservation_agro_production_netag_irrigated_hayfield',
  layerKey: 'tnc_conservation_agro_production_net',
  columnKey: 'ag_irrigated_hayfield',
  color: colors.lime[400],
};

const ProductionNetAgIrrigatedRowAndFieldCropsSeries = {
  ...tncSource,
  key: 'tnc_conservation_agro_production_netag_irrigated_row_and_field_crops',
  layerKey: 'tnc_conservation_agro_production_net',
  columnKey: 'ag_irrigated_row_and_field_crops',
  color: colors.lime[800],
};

const ProductionNetAgOrchardVineyardSeries = {
  ...tncSource,
  key: 'tnc_conservation_agro_production_netag_orchard_vineyard',
  layerKey: 'tnc_conservation_agro_production_net',
  columnKey: 'ag_orchard_vineyard',
  color: colors.lightGreen[800],
};

const ProductionNetAgRiceSeries = {
  ...tncSource,
  key: 'tnc_conservation_agro_production_netag_rice',
  layerKey: 'tnc_conservation_agro_production_net',
  columnKey: 'ag_rice',
  color: colors.grey[300],
};

const ProductionNetAgVineyardSeries = {
  ...tncSource,
  key: 'tnc_conservation_agro_production_netag_vineyard',
  layerKey: 'tnc_conservation_agro_production_net',
  columnKey: 'ag_vineyard',
  color: colors.purple[700],
};

const ProductionTotalAgCroplandGainsSeries = {
  ...tncSource,
  key: 'tnc_conservation_agro_value_gains_totalag_cropland',
  layerKey: 'tnc_conservation_agro_value_gains_total',
  columnKey: 'ag_cropland_gains',
  color: colors.lime[200],
};

const ProductionTotalAgDeciduousOrchardGainsSeries = {
  ...tncSource,
  key: 'tnc_conservation_agro_value_gains_totalag_deciduous_orchard',
  layerKey: 'tnc_conservation_agro_value_gains_total',
  columnKey: 'ag_deciduous_orchard_gains',
  color: colors.green[900],
};

const ProductionTotalAgDrylandGrainCropsGainsSeries = {
  ...tncSource,
  key: 'tnc_conservation_agro_value_gains_totalag_dryland_grain_crops',
  layerKey: 'tnc_conservation_agro_value_gains_total',
  columnKey: 'ag_dryland_grain_crops_gains',
  color: colors.lime[600],
};

const ProductionTotalAgEvergreenOrchardGainsSeries = {
  ...tncSource,
  key: 'tnc_conservation_agro_value_gains_totalag_evergreen_orchard',
  layerKey: 'tnc_conservation_agro_value_gains_total',
  columnKey: 'ag_evergreen_orchard_gains',
  color: colors.green[600],
};

const ProductionTotalAgIrrigatedGrainCropsGainsSeries = {
  ...tncSource,
  key: 'tnc_conservation_agro_value_gains_totalag_irrigated_grain_crops',
  layerKey: 'tnc_conservation_agro_value_gains_total',
  columnKey: 'ag_irrigated_grain_crops_gains',
  color: colors.lime[900],
};

const ProductionTotalAgIrrigatedHayfieldGainsSeries = {
  ...tncSource,
  key: 'tnc_conservation_agro_value_gains_totalag_irrigated_hayfield',
  layerKey: 'tnc_conservation_agro_value_gains_total',
  columnKey: 'ag_irrigated_hayfield_gains',
  color: colors.lime[400],
};

const ProductionTotalAgIrrigatedRowAndFieldCropsGainsSeries = {
  ...tncSource,
  key: 'tnc_conservation_agro_value_gains_totalag_irrigated_row_and_field_crops',
  layerKey: 'tnc_conservation_agro_value_gains_total',
  columnKey: 'ag_irrigated_row_and_field_crops_gains',
  color: colors.lime[800],
};

const ProductionTotalAgOrchardVineyardGainsSeries = {
  ...tncSource,
  key: 'tnc_conservation_agro_value_gains_totalag_orchard_vineyard',
  layerKey: 'tnc_conservation_agro_value_gains_total',
  columnKey: 'ag_orchard_vineyard_gains',
  color: colors.lightGreen[800],
};

const ProductionTotalAgRiceGainsSeries = {
  ...tncSource,
  key: 'tnc_conservation_agro_value_gains_totalag_rice',
  layerKey: 'tnc_conservation_agro_value_gains_total',
  columnKey: 'ag_rice_gains',
  color: colors.grey[300],
};

const ProductionTotalAgVineyardGainsSeries = {
  ...tncSource,
  key: 'tnc_conservation_agro_value_gains_totalag_vineyard',
  layerKey: 'tnc_conservation_agro_value_gains_total',
  columnKey: 'ag_vineyard_gains',
  color: colors.purple[700],
};

const ProductionTotalAgCroplandLossesSeries = {
  ...tncSource,
  key: 'tnc_conservation_agro_value_loss_totalag_cropland',
  layerKey: 'tnc_conservation_agro_value_loss_total',
  columnKey: 'ag_cropland_losses',
  color: colors.lime[200],
};

const ProductionTotalAgDeciduousOrchardLossesSeries = {
  ...tncSource,
  key: 'tnc_conservation_agro_value_loss_totalag_deciduous_orchard',
  layerKey: 'tnc_conservation_agro_value_loss_total',
  columnKey: 'ag_deciduous_orchard_losses',
  color: colors.green[900],
};

const ProductionTotalAgDrylandGrainCropsLossesSeries = {
  ...tncSource,
  key: 'tnc_conservation_agro_value_loss_totalag_dryland_grain_crops',
  layerKey: 'tnc_conservation_agro_value_loss_total',
  columnKey: 'ag_dryland_grain_crops_losses',
  color: colors.lime[600],
};

const ProductionTotalAgEvergreenOrchardLossesSeries = {
  ...tncSource,
  key: 'tnc_conservation_agro_value_loss_totalag_evergreen_orchard',
  layerKey: 'tnc_conservation_agro_value_loss_total',
  columnKey: 'ag_evergreen_orchard_losses',
  color: colors.green[600],
};

const ProductionTotalAgIrrigatedGrainCropsLossesSeries = {
  ...tncSource,
  key: 'tnc_conservation_agro_value_loss_totalag_irrigated_grain_crops',
  layerKey: 'tnc_conservation_agro_value_loss_total',
  columnKey: 'ag_irrigated_grain_crops_losses',
  color: colors.lime[900],
};

const ProductionTotalAgIrrigatedHayfieldLossesSeries = {
  ...tncSource,
  key: 'tnc_conservation_agro_value_loss_totalag_irrigated_hayfield',
  layerKey: 'tnc_conservation_agro_value_loss_total',
  columnKey: 'ag_irrigated_hayfield_losses',
  color: colors.lime[400],
};

const ProductionTotalAgIrrigatedRowAndFieldCropsLossesSeries = {
  ...tncSource,
  key: 'tnc_conservation_agro_value_loss_totalag_irrigated_row_and_field_crops',
  layerKey: 'tnc_conservation_agro_value_loss_total',
  columnKey: 'ag_irrigated_row_and_field_crops_losses',
  color: colors.lime[800],
};

const ProductionTotalAgOrchardVineyardLossesSeries = {
  ...tncSource,
  key: 'tnc_conservation_agro_value_loss_totalag_orchard_vineyard',
  layerKey: 'tnc_conservation_agro_value_loss_total',
  columnKey: 'ag_orchard_vineyard_losses',
  color: colors.lightGreen[800],
};

const ProductionTotalAgRiceLossesSeries = {
  ...tncSource,
  key: 'tnc_conservation_agro_value_loss_totalag_rice',
  layerKey: 'tnc_conservation_agro_value_loss_total',
  columnKey: 'ag_rice_losses',
  color: colors.grey[300],
};

const ProductionTotalAgVineyardLossesSeries = {
  ...tncSource,
  key: 'tnc_conservation_agro_value_loss_totalag_vineyard',
  layerKey: 'tnc_conservation_agro_value_loss_total',
  columnKey: 'ag_vineyard_losses',
  color: colors.purple[700],
};

const TotalSummaryCroplandSeries = {
  ...tncSource,
  key: 'tnc_conservation_agro_summary_totalsummary_cropland',
  layerKey: 'tnc_conservation_agro_summary_total',
  columnKey: 'summary_cropland',
  color: colors.lime[200],
};

const TotalSummaryDeciduousOrchardSeries = {
  ...tncSource,
  key: 'tnc_conservation_agro_summary_totalsummary_deciduous_orchard',
  layerKey: 'tnc_conservation_agro_summary_total',
  columnKey: 'summary_deciduous_orchard',
  color: colors.green[900],
};

const TotalSummaryDrylandGrainCropsSeries = {
  ...tncSource,
  key: 'tnc_conservation_agro_summary_totalsummary_dryland_grain_crops',
  layerKey: 'tnc_conservation_agro_summary_total',
  columnKey: 'summary_dryland_grain_crops',
  color: colors.lime[600],
};

const TotalSummaryEvergreenOrchardSeries = {
  ...tncSource,
  key: 'tnc_conservation_agro_summary_totalsummary_evergreen_orchard',
  layerKey: 'tnc_conservation_agro_summary_total',
  columnKey: 'summary_evergreen_orchard',
  color: colors.green[600],
};

const TotalSummaryIrrigatedGrainCropsSeries = {
  ...tncSource,
  key: 'tnc_conservation_agro_summary_totalsummary_irrigated_grain_crops',
  layerKey: 'tnc_conservation_agro_summary_total',
  columnKey: 'summary_irrigated_grain_crops',
  color: colors.lime[900],
};

const TotalSummaryIrrigatedHayfieldSeries = {
  ...tncSource,
  key: 'tnc_conservation_agro_summary_totalsummary_irrigated_hayfield',
  layerKey: 'tnc_conservation_agro_summary_total',
  columnKey: 'summary_irrigated_hayfield',
  color: colors.lime[400],
};

const TotalSummaryIrrigatedRowAndFieldCropsSeries = {
  ...tncSource,
  key: 'tnc_conservation_agro_summary_totalsummary_irrigated_row_and_field_crops',
  layerKey: 'tnc_conservation_agro_summary_total',
  columnKey: 'summary_irrigated_row_and_field_crops',
  color: colors.lime[800],
};

const TotalSummaryOrchardVineyardSeries = {
  ...tncSource,
  key: 'tnc_conservation_agro_summary_totalsummary_orchard_vineyard',
  layerKey: 'tnc_conservation_agro_summary_total',
  columnKey: 'summary_orchard_vineyard',
  color: colors.lightGreen[800],
};

const TotalSummaryRiceSeries = {
  ...tncSource,
  key: 'tnc_conservation_agro_summary_totalsummary_rice',
  layerKey: 'tnc_conservation_agro_summary_total',
  columnKey: 'summary_rice',
  color: colors.grey[300],
};

const TotalSummaryVineyardSeries = {
  ...tncSource,
  key: 'tnc_conservation_agro_summary_totalsummary_vineyard',
  layerKey: 'tnc_conservation_agro_summary_total',
  columnKey: 'summary_vineyard',
  color: colors.purple[700],
};

const NetSummaryCroplandSeries = {
  ...tncSource,
  key: 'tnc_conservation_agro_summary_netsummary_cropland',
  layerKey: 'tnc_conservation_agro_summary_net',
  columnKey: 'summary_cropland',
  color: colors.lime[200],
};

const NetSummaryDeciduousOrchardSeries = {
  ...tncSource,
  key: 'tnc_conservation_agro_summary_netsummary_deciduous_orchard',
  layerKey: 'tnc_conservation_agro_summary_net',
  columnKey: 'summary_deciduous_orchard',
  color: colors.green[900],
};

const NetSummaryDrylandGrainCropsSeries = {
  ...tncSource,
  key: 'tnc_conservation_agro_summary_netsummary_dryland_grain_crops',
  layerKey: 'tnc_conservation_agro_summary_net',
  columnKey: 'summary_dryland_grain_crops',
  color: colors.lime[600],
};

const NetSummaryEvergreenOrchardSeries = {
  ...tncSource,
  key: 'tnc_conservation_agro_summary_netsummary_evergreen_orchard',
  layerKey: 'tnc_conservation_agro_summary_net',
  columnKey: 'summary_evergreen_orchard',
  color: colors.green[600],
};

const NetSummaryIrrigatedGrainCropsSeries = {
  ...tncSource,
  key: 'tnc_conservation_agro_summary_netsummary_irrigated_grain_crops',
  layerKey: 'tnc_conservation_agro_summary_net',
  columnKey: 'summary_irrigated_grain_crops',
  color: colors.lime[900],
};

const NetSummaryIrrigatedHayfieldSeries = {
  ...tncSource,
  key: 'tnc_conservation_agro_summary_netsummary_irrigated_hayfield',
  layerKey: 'tnc_conservation_agro_summary_net',
  columnKey: 'summary_irrigated_hayfield',
  color: colors.lime[400],
};

const NetSummaryIrrigatedRowAndFieldCropsSeries = {
  ...tncSource,
  key: 'tnc_conservation_agro_summary_netsummary_irrigated_row_and_field_crops',
  layerKey: 'tnc_conservation_agro_summary_net',
  columnKey: 'summary_irrigated_row_and_field_crops',
  color: colors.lime[800],
};

const NetSummaryOrchardVineyardSeries = {
  ...tncSource,
  key: 'tnc_conservation_agro_summary_netsummary_orchard_vineyard',
  layerKey: 'tnc_conservation_agro_summary_net',
  columnKey: 'summary_orchard_vineyard',
  color: colors.lightGreen[800],
};

const NetSummaryRiceSeries = {
  ...tncSource,
  key: 'tnc_conservation_agro_summary_netsummary_rice',
  layerKey: 'tnc_conservation_agro_summary_net',
  columnKey: 'summary_rice',
  color: colors.grey[300],
};

const NetSummaryVineyardSeries = {
  ...tncSource,
  key: 'tnc_conservation_agro_summary_netsummary_vineyard',
  layerKey: 'tnc_conservation_agro_summary_net',
  columnKey: 'summary_vineyard',
  color: colors.purple[700],
};

const TotalSummaryCroplandGainsSeries = {
  ...tncSource,
  key: 'tnc_conservation_agro_area_gains_totalsummary_cropland',
  layerKey: 'tnc_conservation_agro_area_gains_total',
  columnKey: 'summary_cropland_gains',
  color: colors.lime[200],
};

const TotalSummaryDeciduousOrchardGainsSeries = {
  ...tncSource,
  key: 'tnc_conservation_agro_area_gains_totalsummary_deciduous_orchard',
  layerKey: 'tnc_conservation_agro_area_gains_total',
  columnKey: 'summary_deciduous_orchard_gains',
  color: colors.green[900],
};

const TotalSummaryDrylandGrainCropsGainsSeries = {
  ...tncSource,
  key: 'tnc_conservation_agro_area_gains_totalsummary_dryland_grain_crops',
  layerKey: 'tnc_conservation_agro_area_gains_total',
  columnKey: 'summary_dryland_grain_crops_gains',
  color: colors.lime[600],
};

const TotalSummaryEvergreenOrchardGainsSeries = {
  ...tncSource,
  key: 'tnc_conservation_agro_area_gains_totalsummary_evergreen_orchard',
  layerKey: 'tnc_conservation_agro_area_gains_total',
  columnKey: 'summary_evergreen_orchard_gains',
  color: colors.green[600],
};

const TotalSummaryIrrigatedGrainCropsGainsSeries = {
  ...tncSource,
  key: 'tnc_conservation_agro_area_gains_totalsummary_irrigated_grain_crops',
  layerKey: 'tnc_conservation_agro_area_gains_total',
  columnKey: 'summary_irrigated_grain_crops_gains',
  color: colors.lime[900],
};

const TotalSummaryIrrigatedHayfieldGainsSeries = {
  ...tncSource,
  key: 'tnc_conservation_agro_area_gains_totalsummary_irrigated_hayfield',
  layerKey: 'tnc_conservation_agro_area_gains_total',
  columnKey: 'summary_irrigated_hayfield_gains',
  color: colors.lime[400],
};

const TotalSummaryIrrigatedRowAndFieldCropsGainsSeries = {
  ...tncSource,
  key: 'tnc_conservation_agro_area_gains_totalsummary_irrigated_row_and_field_crops',
  layerKey: 'tnc_conservation_agro_area_gains_total',
  columnKey: 'summary_irrigated_row_and_field_crops_gains',
  color: colors.lime[800],
};

const TotalSummaryOrchardVineyardGainsSeries = {
  ...tncSource,
  key: 'tnc_conservation_agro_area_gains_totalsummary_orchard_vineyard',
  layerKey: 'tnc_conservation_agro_area_gains_total',
  columnKey: 'summary_orchard_vineyard_gains',
  color: colors.lightGreen[800],
};

const TotalSummaryRiceGainsSeries = {
  ...tncSource,
  key: 'tnc_conservation_agro_area_gains_totalsummary_rice',
  layerKey: 'tnc_conservation_agro_area_gains_total',
  columnKey: 'summary_rice_gains',
  color: colors.grey[300],
};

const TotalSummaryVineyardGainsSeries = {
  ...tncSource,
  key: 'tnc_conservation_agro_area_gains_totalsummary_vineyard',
  layerKey: 'tnc_conservation_agro_area_gains_total',
  columnKey: 'summary_vineyard_gains',
  color: colors.purple[700],
};

const TotalSummaryCroplandLossesSeries = {
  ...tncSource,
  key: 'tnc_conservation_agro_area_loss_totalsummary_cropland',
  layerKey: 'tnc_conservation_agro_area_loss_total',
  columnKey: 'summary_cropland_losses',
  color: colors.lime[200],
};

const TotalSummaryDeciduousOrchardLossesSeries = {
  ...tncSource,
  key: 'tnc_conservation_agro_area_loss_totalsummary_deciduous_orchard',
  layerKey: 'tnc_conservation_agro_area_loss_total',
  columnKey: 'summary_deciduous_orchard_losses',
  color: colors.green[900],
};

const TotalSummaryDrylandGrainCropsLossesSeries = {
  ...tncSource,
  key: 'tnc_conservation_agro_area_loss_totalsummary_dryland_grain_crops',
  layerKey: 'tnc_conservation_agro_area_loss_total',
  columnKey: 'summary_dryland_grain_crops_losses',
  color: colors.lime[600],
};

const TotalSummaryEvergreenOrchardLossesSeries = {
  ...tncSource,
  key: 'tnc_conservation_agro_area_loss_totalsummary_evergreen_orchard',
  layerKey: 'tnc_conservation_agro_area_loss_total',
  columnKey: 'summary_evergreen_orchard_losses',
  color: colors.green[600],
};

const TotalSummaryIrrigatedGrainCropsLossesSeries = {
  ...tncSource,
  key: 'tnc_conservation_agro_area_loss_totalsummary_irrigated_grain_crops',
  layerKey: 'tnc_conservation_agro_area_loss_total',
  columnKey: 'summary_irrigated_grain_crops_losses',
  color: colors.lime[900],
};

const TotalSummaryIrrigatedHayfieldLossesSeries = {
  ...tncSource,
  key: 'tnc_conservation_agro_area_loss_totalsummary_irrigated_hayfield',
  layerKey: 'tnc_conservation_agro_area_loss_total',
  columnKey: 'summary_irrigated_hayfield_losses',
  color: colors.lime[400],
};

const TotalSummaryIrrigatedRowAndFieldCropsLossesSeries = {
  ...tncSource,
  key: 'tnc_conservation_agro_area_loss_totalsummary_irrigated_row_and_field_crops',
  layerKey: 'tnc_conservation_agro_area_loss_total',
  columnKey: 'summary_irrigated_row_and_field_crops_losses',
  color: colors.lime[800],
};

const TotalSummaryOrchardVineyardLossesSeries = {
  ...tncSource,
  key: 'tnc_conservation_agro_area_loss_totalsummary_orchard_vineyard',
  layerKey: 'tnc_conservation_agro_area_loss_total',
  columnKey: 'summary_orchard_vineyard_losses',
  color: colors.lightGreen[800],
};

const TotalSummaryRiceLossesSeries = {
  ...tncSource,
  key: 'tnc_conservation_agro_area_loss_totalsummary_rice',
  layerKey: 'tnc_conservation_agro_area_loss_total',
  columnKey: 'summary_rice_losses',
  color: colors.grey[300],
};

const TotalSummaryVineyardLossesSeries = {
  ...tncSource,
  key: 'tnc_conservation_agro_area_loss_totalsummary_vineyard',
  layerKey: 'tnc_conservation_agro_area_loss_total',
  columnKey: 'summary_vineyard_losses',
  color: colors.purple[700],
};

export const tncChartsSharedAgriculture = [
  {
    name: t`Total Production Value`,
    key: 'total_production_value',
    description: t`Total Production Value`,
    units: getUnitName(DOLLARS),
    series: [
      ProductionTotalAgCroplandSeries,
      ProductionTotalAgDrylandGrainCropsSeries,
      ProductionTotalAgIrrigatedGrainCropsSeries,
      ProductionTotalAgIrrigatedHayfieldSeries,
      ProductionTotalAgIrrigatedRowAndFieldCropsSeries,
      ProductionTotalAgRiceSeries,
      ProductionTotalAgDeciduousOrchardSeries,
      ProductionTotalAgEvergreenOrchardSeries,
      ProductionTotalAgOrchardVineyardSeries,
      ProductionTotalAgVineyardSeries,
    ],
    emptyChartContent: defaultEmptyChartContent,
    showLegend: true,
    showFullLabel: true,
  },
  {
    name: t`Net Change in Production Value`,
    key: 'net_production_value',
    description: t`Net Change in Production Value`,
    units: getUnitName(DOLLARS),
    series: [
      ProductionNetAgCroplandSeries,
      ProductionNetAgDrylandGrainCropsSeries,
      ProductionNetAgIrrigatedGrainCropsSeries,
      ProductionNetAgIrrigatedHayfieldSeries,
      ProductionNetAgIrrigatedRowAndFieldCropsSeries,
      ProductionNetAgRiceSeries,
      ProductionNetAgDeciduousOrchardSeries,
      ProductionNetAgEvergreenOrchardSeries,
      ProductionNetAgOrchardVineyardSeries,
      ProductionNetAgVineyardSeries,
    ],
    emptyChartContent: defaultEmptyChartContent,
    showLegend: true,
    showFullLabel: true,
  },
  {
    name: t`Increase in Production Value`,
    key: 'gains_production_value',
    subtitle: t`Change`,
    description: t`Increase in Production Value`,
    units: getUnitName(DOLLARS, IN_SENTENCE),
    series: [
      ProductionTotalAgCroplandGainsSeries,
      ProductionTotalAgDrylandGrainCropsGainsSeries,
      ProductionTotalAgIrrigatedGrainCropsGainsSeries,
      ProductionTotalAgIrrigatedHayfieldGainsSeries,
      ProductionTotalAgIrrigatedRowAndFieldCropsGainsSeries,
      ProductionTotalAgRiceGainsSeries,
      ProductionTotalAgDeciduousOrchardGainsSeries,
      ProductionTotalAgEvergreenOrchardGainsSeries,
      ProductionTotalAgOrchardVineyardGainsSeries,
      ProductionTotalAgVineyardGainsSeries,
    ],
    emptyChartContent: defaultEmptyChartContent,
    showLegend: true,
    showFullLabel: true,
  },
  {
    name: t`Decrease in Production Value`,
    key: 'losses_production_value',
    subtitle: t`Change`,
    description: t`Decrease in Production Value`,
    units: getUnitName(DOLLARS, IN_SENTENCE),
    series: [
      ProductionTotalAgCroplandLossesSeries,
      ProductionTotalAgDrylandGrainCropsLossesSeries,
      ProductionTotalAgIrrigatedGrainCropsLossesSeries,
      ProductionTotalAgIrrigatedHayfieldLossesSeries,
      ProductionTotalAgIrrigatedRowAndFieldCropsLossesSeries,
      ProductionTotalAgRiceLossesSeries,
      ProductionTotalAgDeciduousOrchardLossesSeries,
      ProductionTotalAgEvergreenOrchardLossesSeries,
      ProductionTotalAgOrchardVineyardLossesSeries,
      ProductionTotalAgVineyardLossesSeries,
    ],
    emptyChartContent: defaultEmptyChartContent,
    showLegend: true,
    showFullLabel: true,
  },
  {
    name: t`Total Agricultural Area by Crop Class`,
    key: 'total_acreage_summary',
    description: t`Total Acreage by Crop Class`,
    units: getUnitName(ACRES),
    series: [
      TotalSummaryCroplandSeries,
      TotalSummaryDrylandGrainCropsSeries,
      TotalSummaryIrrigatedGrainCropsSeries,
      TotalSummaryIrrigatedHayfieldSeries,
      TotalSummaryIrrigatedRowAndFieldCropsSeries,
      TotalSummaryRiceSeries,
      TotalSummaryDeciduousOrchardSeries,
      TotalSummaryEvergreenOrchardSeries,
      TotalSummaryOrchardVineyardSeries,
      TotalSummaryVineyardSeries,
    ],
    emptyChartContent: defaultEmptyChartContent,
    showLegend: true,
    showFullLabel: true,
  },
  {
    name: t`Net Changes in Agricultural Area by Crop Class`,
    key: 'net_acreage_summary',
    description: t`Net Changes in Acreage by Crop Class`,
    units: getUnitName(ACRES),
    series: [
      NetSummaryCroplandSeries,
      NetSummaryDrylandGrainCropsSeries,
      NetSummaryIrrigatedGrainCropsSeries,
      NetSummaryIrrigatedHayfieldSeries,
      NetSummaryIrrigatedRowAndFieldCropsSeries,
      NetSummaryRiceSeries,
      NetSummaryDeciduousOrchardSeries,
      NetSummaryEvergreenOrchardSeries,
      NetSummaryOrchardVineyardSeries,
      NetSummaryVineyardSeries,
    ],
    emptyChartContent: defaultEmptyChartContent,
    showLegend: true,
    showFullLabel: true,
  },
  {
    name: t`Increase in Agricultural Area by Crop Class`,
    key: 'gains_acreage_summary',
    subtitle: t`Change`,
    description: t`Increase in Acreage by Crop Class`,
    units: getUnitName(ACRES, IN_SENTENCE),
    series: [
      TotalSummaryCroplandGainsSeries,
      TotalSummaryDrylandGrainCropsGainsSeries,
      TotalSummaryIrrigatedGrainCropsGainsSeries,
      TotalSummaryIrrigatedHayfieldGainsSeries,
      TotalSummaryIrrigatedRowAndFieldCropsGainsSeries,
      TotalSummaryRiceGainsSeries,
      TotalSummaryDeciduousOrchardGainsSeries,
      TotalSummaryEvergreenOrchardGainsSeries,
      TotalSummaryOrchardVineyardGainsSeries,
      TotalSummaryVineyardGainsSeries,
    ],
    emptyChartContent: defaultEmptyChartContent,
    showLegend: true,
    showFullLabel: true,
  },
  {
    name: t`Decrease in Agricultural Area by Crop Class`,
    key: 'losses_acreage_summary',
    subtitle: t`Change`,
    description: t`Decrease in Acreage by Crop Class`,
    units: getUnitName(ACRES, IN_SENTENCE),
    series: [
      TotalSummaryCroplandLossesSeries,
      TotalSummaryDrylandGrainCropsLossesSeries,
      TotalSummaryIrrigatedGrainCropsLossesSeries,
      TotalSummaryIrrigatedHayfieldLossesSeries,
      TotalSummaryIrrigatedRowAndFieldCropsLossesSeries,
      TotalSummaryRiceLossesSeries,
      TotalSummaryDeciduousOrchardLossesSeries,
      TotalSummaryEvergreenOrchardLossesSeries,
      TotalSummaryOrchardVineyardLossesSeries,
      TotalSummaryVineyardLossesSeries,
    ],
    emptyChartContent: defaultEmptyChartContent,
    showLegend: true,
    showFullLabel: true,
  },
  {
    name: t`Development on Cultivated Crop Lands by FMMP Designation`,
    key: 'total_ag_to_urban_conversion',
    description: t`Development on Cultivated Crop Lands`,
    units: getUnitName(ACRES),
    series: [
      ConversionNetFmmpPrimeFromAgToUrbanSeries,
      ConversionNetFmmpStateFromAgToUrbanSeries,
      ConversionNetFmmpUniqueFromAgToUrbanSeries,
      ConversionNetFmmpLocalFromAgToUrbanSeries,
    ],
    emptyChartContent: defaultEmptyChartContent,
    showLegend: true,
    showFullLabel: true,
  },
];

export const tncChartsAgriculture: LegacyReport = Object.freeze({
  key: 'tnc_conservation_agriculture',
  name: t`Conservation - Agriculture`,
  colorSpec: {
    primaryColor: colors.lime[700],
    interpolateFromColor: 'rgb(255, 214, 79)',
    interpolateToColor: 'rgb(27, 94, 31)',
  },
  charts: [...tncChartsSharedAgriculture],
});
