/**
 * UrbanFootprint App API
 * API for serving UrbanFootprint Application
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Map sizing and orientation.
 */
export interface MapExportDisplaySettings<> {
  /**
   * The X side of the aspect ratio
   */
  aspect_x?: number;
  /**
   * The Y side of the aspect ratio
   */
  aspect_y?: number;
  /**
   * The map bearing (rotation) in degrees, where 0 means north is up, positive rotates counter-clockwise, negative rotates clockwise
   */
  bearing?: number;
  /**
   * Bounds of the map, in [ne, sw] format.
   */
  bounds?: Array<Array<number>>;
  /**
   * Center of the map, in [lng, lat] format.
   */
  center: Array<number>;
  /**
   * The output page orientation.
   */
  orientation?: MapExportDisplaySettings.OrientationEnum;
  /**
   * The map pitch in degrees, from 0 (vertical) to 60 (fully tilted).
   */
  pitch?: number;
  /**
   * To show max map bounds box in export
   */
  show_bounds?: boolean;
  /**
   * Height of the source viewport in pixels
   */
  source_height: number;
  /**
   * Width of the source viewport in pixels
   */
  source_width: number;
  /**
   * Zoom used on the source map.
   */
  source_zoom: number;
}
export namespace MapExportDisplaySettings {
  export type OrientationEnum = 'landscape' | 'portrait';
  export const OrientationEnum = {
    Landscape: 'landscape',
    Portrait: 'portrait',
  } as const;
}
