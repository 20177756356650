import _ from 'lodash';
import { Map } from 'mapbox-gl';
import React, { FC, useCallback } from 'react';
import { render, unmountComponentAtNode } from 'react-dom';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { addAppMessage } from 'uf/appservices/actions';
import { Message } from 'uf/appservices/messages';
import warning from 'warning';
import { ATTRIBUTION_TEXT } from './copyText';

export function ignoreElements(hiddenClassNames: string[], element: Element) {
  // Remove all elements with class in hiddenClassNames
  // eslint-disable-next-line no-restricted-syntax
  for (const hiddenClassName of hiddenClassNames) {
    if (element.classList.contains(hiddenClassName)) {
      return true;
    }
  }
  return false;
}

const OverlayWithAttribution: FC<{
  width: number;
  height: number;
  children: React.ReactNode;
}> = ({ width, height, children }) => {
  return (
    <div style={{ position: 'relative', width, height }}>
      {children}
      <div style={{ position: 'absolute', width, height, top: 0 }}>
        <div
          style={{
            display: 'flex',
            width,
            height,
            flexDirection: 'row-reverse',
          }}>
          <div
            style={{
              alignSelf: 'end',
              padding: '0px 3px 9px',
              lineHeight: '8px',
              fontSize: '10px',
              fontFamily: 'Helvetica Neue,Arial,Helvetica,sans-serif',
              color: '#000000',
              backgroundColor: 'hsla(0,0%,100%,.5)',
            }}>
            {ATTRIBUTION_TEXT}
          </div>
        </div>
      </div>
    </div>
  );
};

export function onErrorFactory(dispatch: Dispatch) {
  return (error: Error) => {
    console.error(error);
    const text = error.toString();
    const toastMessage: Message = {
      status: 'failure',
      // stack?
    };
    dispatch(addAppMessage(text, toastMessage));
  };
}

// wrap html2canvas with a dynamic import as to not interfere with SSR
async function html2canvas(...args: any[]): Promise<HTMLCanvasElement> {
  const fn: (...innerArgs: any[]) => Promise<HTMLCanvasElement> = (
    await import('html2canvas')
  ).default as any;
  return fn(...args);
}

export function useSnapshot(
  map: Map | null,
  onSnapshot: (url: string) => void,
  hiddenClassNames = [
    'mapboxgl-ctrl-group', // zoom controls
    'map-reset-control',
    'mapboxgl-ctrl-attrib',
    'mapboxgl-canvas', // actual map canvas
  ],
) {
  const onError = onErrorFactory(useDispatch());
  const handleSnapshot = useCallback(() => {
    if (!map) {
      onError(new Error('Map was not ready when quick snapshot was requested'));
      // on the off chance that the map unmounted before this event had a chance to fire
      // notify user?
      return;
    }

    warning(map.areTilesLoaded(), 'Map tiles are not ready');
    warning(map.isStyleLoaded(), 'Map styles are not ready');
    warning(map.loaded(), 'Map is not loaded');

    const mapPaneRef = map.getContainer();
    const width = mapPaneRef.offsetWidth;
    const height = mapPaneRef.offsetHeight;

    // copy our map image before yielding control
    const mapImage = map.getCanvas().toDataURL();

    async function draw(): Promise<void> {
      // capture only the overlays since we already have the map
      const desiredOverlay = map
        .getContainer()
        .querySelector('.mapboxgl-control-container');
      const overlayImage = (
        await html2canvas(desiredOverlay, {
          ignoreElements: _.partial(ignoreElements, hiddenClassNames),
          width,
          height,
          // render as a transparent background
          backgroundColor: null,
        })
      ).toDataURL();

      // callback to convert DOM into an image url and send it to be consumed
      async function doFullRender() {
        const attribution = await html2canvas(renderSpace);
        const url = attribution.toDataURL();
        onSnapshot(url);
      }
      function cleanup() {
        unmountComponentAtNode(renderSpace);
        renderSpace.remove();
      }
      function onReady() {
        doFullRender().catch(onError).finally(cleanup);
      }
      const attributionElement = (
        <OverlayWithAttribution width={width} height={height}>
          <img
            width={width}
            height={height}
            src={overlayImage}
            alt="overlay"
            style={{ zIndex: -1, position: 'absolute', width, height, top: 0 }}
          />
          <img
            width={width}
            height={height}
            src={mapImage}
            alt="map"
            style={{ zIndex: -2, position: 'absolute', width, height, top: 0 }}
          />
        </OverlayWithAttribution>
      );
      // create a temporary rendering space
      const renderSpace = document.createElement('div');
      renderSpace.style.width = `${mapPaneRef.offsetWidth}px`;
      renderSpace.style.height = `${mapPaneRef.offsetHeight}px`;
      document.body.append(renderSpace);
      render(attributionElement, renderSpace, onReady);
    }

    draw().catch(onError);
  }, [map, hiddenClassNames, onError, onSnapshot]);

  return useCallback(() => {
    map.once('render', handleSnapshot);
    map.setZoom(map.getZoom());
  }, [handleSnapshot, map]);
}
