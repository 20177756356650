import {
  Button,
  Menu,
  MenuItem,
  Popover,
  PopoverInteractionKind,
  Position,
  Tooltip,
} from '@blueprintjs/core';
import { mdiContentCopy, mdiPlus } from '@mdi/js';
import { Icon } from '@mdi/react';
import { boundMethod } from 'autobind-decorator';
import classNames from 'classnames';
import React, { Component } from 'react';
import { Box } from 'react-layout-components';
import { t } from 'ttag';

import { ScenarioMetadata } from 'uf-api';
import buttonStyles from 'uf/styles/buttons.module.css';
import colorStyles from 'uf/styles/colors.module.css';
import popupStyles from 'uf/styles/popups.module.css';
import rootStyles from 'uf/styles/root.module.css';
import textStyles from 'uf/styles/text.module.css';
import { IconSizeNew } from 'uf/ui/base/icon';

const buttonStyle: React.CSSProperties = {
  // override blueprint padding left/right of 10px so that the button is 30px/30px
  paddingRight: 6,
  paddingLeft: 6,
  // blueprint's OverflowList has a 'spacer' element on the right side of it that is 1px wide.
  // shifting this button to the left so that we maintain var(--very-thin-spacing) between this
  // button and the tabs (or overflow menu button).
  marginLeft: -1,
};

const popoverMenuStyle = classNames(
  popupStyles.dropdownLarge,
  popupStyles.dropdown,
);

interface CreateScenarioMenuProps {
  scenarios: ScenarioMetadata[];
  disabled?: boolean;
  tooltipText: string;
  onClickItem: (scenario: ScenarioMetadata) => void;
}

const iconClassName = classNames(
  textStyles.lightText,
  'CreateScenarioMenu-add',
);
export class CreateScenarioMenu extends Component<CreateScenarioMenuProps> {
  @boundMethod
  onClick(e: React.MouseEvent<HTMLElement, MouseEvent>) {
    const { scenarios, onClickItem } = this.props;

    const scenarioId = e?.currentTarget?.dataset?.scenarioid;
    const scenario = scenarios.find(s => s.full_path === scenarioId);
    onClickItem(scenario);
  }

  @boundMethod
  renderMenu() {
    const { scenarios } = this.props;

    return (
      <Menu>
        <Box
          className={classNames(
            rootStyles.veryThinPadding,
            colorStyles.lightText,
          )}>
          {t`Create new scenario from`}
        </Box>
        {scenarios.map(scenario => {
          return (
            <MenuItem
              data-scenarioid={scenario.full_path}
              key={scenario.key}
              onClick={this.onClick}
              text={scenario.name}
              icon={
                <Icon path={mdiContentCopy} size={IconSizeNew.EXTRA_SMALL} />
              }
            />
          );
        })}
      </Menu>
    );
  }

  render() {
    const { disabled, tooltipText } = this.props;

    return (
      <Popover
        disabled={disabled}
        position={Position.BOTTOM_LEFT}
        boundary="viewport"
        minimal
        content={this.renderMenu()}
        popoverClassName={popoverMenuStyle}>
        <Tooltip
          boundary="viewport"
          content={tooltipText || t`Create new scenario`}
          interactionKind={PopoverInteractionKind.HOVER}
          hoverOpenDelay={500}>
          <Button
            disabled={disabled}
            minimal
            className={buttonStyles.dark}
            style={buttonStyle}
            icon={
              <Icon
                path={mdiPlus}
                size={IconSizeNew.EXTRA_SMALL}
                className={iconClassName}
              />
            }
          />
        </Tooltip>
      </Popover>
    );
  }
}
