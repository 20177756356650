import {
  CuratedColumnSymbology,
  InterpolationTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/public/dataset/city_of_somerville_green_line_ext': {
    '*': [
      {
        display_hints: {
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          scale: SymbologyScales.CATEGORICAL,
          theme: 'custom',
        },
        paint: {
          'line-color': '#68bc00',
          'line-opacity': 0.9,
          'line-width': 2,
        },
        type: SymbologyTypes.LINE,
      },
    ],
  },
};

export default symbologies;
