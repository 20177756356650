/* eslint-disable @typescript-eslint/naming-convention */
import { produce } from 'immer';
import _ from 'lodash';

import { EMPTY_OBJECT } from 'uf/base';
import {
  AddJoinedLayerFilterAction,
  ClearBufferFilterAction,
  ClearColumnFilterAction,
  ClearFiltersAction,
  ClearGeometryFilters,
  ClearJoinedLayerFilterAction,
  ClearPointFilterAction,
  ClearSelectionFiltersAction,
  EnableFiltersAction,
  UpdateBufferFilterAction,
  UpdateColumnFilterAction,
  UpdateGeometryFilterAction,
  UpdateJoinLayerMethodAction,
  UpdatePointFilterAction,
} from 'uf/layers/ActionTypes';
import { ColumnFilter, FilterSpec } from 'uf/layers/filters';

export const initialState: Record<string, Partial<FilterSpec>> = {};

export function makeFilterReducer(
  ADD_JOINED_LAYER_FILTER: string,
  CLEAR_BUFFER_FILTER: string,
  CLEAR_COLUMN_FILTERS: string,
  CLEAR_FILTERS: string,
  CLEAR_GEOMETRY_FILTERS: string,
  CLEAR_POINT_FILTER: string,
  CLEAR_SELECTION_FILTERS: string,
  ENABLE_FILTERS: string,
  UPDATE_BUFFER_FILTER: string,
  UPDATE_COLUMN_FILTER: string,
  UPDATE_GEOMETRY_FILTERS: string,
  UPDATE_POINT_FILTER: string,
  UPDATE_JOIN_LAYER_METHOD: string,
  CLEAR_JOINED_LAYER_FILTER: string,
) {
  type FilterActions =
    | AddJoinedLayerFilterAction<typeof ADD_JOINED_LAYER_FILTER>
    | ClearBufferFilterAction<typeof CLEAR_BUFFER_FILTER>
    | ClearColumnFilterAction<typeof CLEAR_COLUMN_FILTERS>
    | ClearFiltersAction<typeof CLEAR_FILTERS>
    | ClearGeometryFilters<typeof CLEAR_GEOMETRY_FILTERS>
    | ClearPointFilterAction<typeof CLEAR_POINT_FILTER>
    | ClearSelectionFiltersAction<typeof CLEAR_SELECTION_FILTERS>
    | EnableFiltersAction<typeof ENABLE_FILTERS>
    | UpdateBufferFilterAction<typeof UPDATE_BUFFER_FILTER>
    | UpdateColumnFilterAction<typeof UPDATE_COLUMN_FILTER>
    | UpdateGeometryFilterAction<typeof UPDATE_GEOMETRY_FILTERS>
    | UpdatePointFilterAction<typeof UPDATE_POINT_FILTER>
    | UpdateJoinLayerMethodAction<typeof UPDATE_JOIN_LAYER_METHOD>
    | ClearJoinedLayerFilterAction<typeof CLEAR_JOINED_LAYER_FILTER>;

  return produce(
    (state, action: FilterActions): Record<string, Partial<FilterSpec>> => {
      switch (action.type) {
        case UPDATE_COLUMN_FILTER:
          return updateColumnFilter(
            action as UpdateColumnFilterAction<typeof UPDATE_COLUMN_FILTER>,
            state,
          );

        case CLEAR_COLUMN_FILTERS:
          return clearColumnFilters(
            action as ClearColumnFilterAction<typeof CLEAR_COLUMN_FILTERS>,
            state,
          );

        case ADD_JOINED_LAYER_FILTER:
          return addJoinedLayerFilter(
            action as AddJoinedLayerFilterAction<
              typeof ADD_JOINED_LAYER_FILTER
            >,
            state,
          );

        case CLEAR_JOINED_LAYER_FILTER:
          return clearJoinedLayerFilter(
            action as ClearJoinedLayerFilterAction<
              typeof CLEAR_JOINED_LAYER_FILTER
            >,
            state,
          );

        case UPDATE_JOIN_LAYER_METHOD:
          return updateJoinLayerMethod(
            action as UpdateJoinLayerMethodAction<
              typeof UPDATE_JOIN_LAYER_METHOD
            >,
            state,
          );

        case UPDATE_BUFFER_FILTER:
          return updateBufferFilter(
            action as UpdateBufferFilterAction<typeof UPDATE_BUFFER_FILTER>,
            state,
          );

        case CLEAR_BUFFER_FILTER:
          return clearBufferFilter(
            action as ClearBufferFilterAction<typeof CLEAR_BUFFER_FILTER>,
            state,
          );

        case UPDATE_POINT_FILTER:
          return updatePointFilter(
            action as UpdatePointFilterAction<typeof UPDATE_POINT_FILTER>,
            state,
          );

        case CLEAR_POINT_FILTER: {
          const { layerId } = action;
          return _.set(state, [layerId, 'pointIds'], {});
        }

        case UPDATE_GEOMETRY_FILTERS:
          return updateGeometryFilters(
            action as UpdateGeometryFilterAction<
              typeof UPDATE_GEOMETRY_FILTERS
            >,
            state,
          );

        case CLEAR_GEOMETRY_FILTERS: {
          const { layerId } = action;
          return _.set(state, [layerId, 'geometries'], EMPTY_OBJECT);
        }

        case CLEAR_SELECTION_FILTERS: {
          const { layerId } = action;
          _.set(state, [layerId, 'pointIds'], EMPTY_OBJECT);
          return _.set(state, [layerId, 'geometries'], EMPTY_OBJECT);
        }

        case ENABLE_FILTERS: {
          const { layerId, enabled } = action as EnableFiltersAction<
            typeof ENABLE_FILTERS
          >;
          return _.set(state, [layerId, 'disabled'], !enabled);
        }
        case CLEAR_FILTERS: {
          const { layerId } = action;
          if (_.isEmpty(state[layerId])) {
            return state;
          }
          return _.set(state, layerId, EMPTY_OBJECT);
        }

        default:
          // TODO: figure out how to type this so we can get type safety
          // typeAssertNever(action);
          return state;
      }
    },
    initialState,
  );
}

function updateColumnFilter(
  action: UpdateColumnFilterAction<any>,
  state: Record<string, Partial<FilterSpec>>,
): Record<string, Partial<FilterSpec>> {
  const {
    layerId,
    parentLayerId,
    version,
    columnKey,
    filterValue,
    columnMetatype,
    columnType,
  } = action;

  const columnFilter: ColumnFilter = {
    columnMetatype,
    type: columnType,
    filterValue,
  } as ColumnFilter;
  const path: string[] = parentLayerId
    ? [parentLayerId, 'layers', layerId]
    : [layerId];

  if (version !== undefined) {
    _.set(state, [...path, 'version'], version);
  }
  return _.set(state, [...path, 'columns', columnKey], columnFilter);
}

function addJoinedLayerFilter(
  action: AddJoinedLayerFilterAction<any>,
  state: Record<string, Partial<FilterSpec>>,
): Record<string, Partial<FilterSpec>> {
  const {
    layerId,
    version,
    parentLayerId,
    joinMethod = 'layer_intersects',
  } = action;

  if (!parentLayerId) {
    return state;
  }

  const path = [parentLayerId, 'layers', layerId];
  _.set(state, [...path, 'joinMethod'], joinMethod);
  return _.set(state, [...path, 'version'], version);
}

function clearJoinedLayerFilter(
  action: ClearJoinedLayerFilterAction<any>,
  state: Record<string, Partial<FilterSpec>>,
): Record<string, Partial<FilterSpec>> {
  const { layerId, parentLayerId } = action;
  if (!parentLayerId) {
    return state;
  }
  const path = [parentLayerId, 'layers', layerId];
  _.unset(state, path);
  return state;
}

function updateJoinLayerMethod(
  action: UpdateJoinLayerMethodAction<any>,
  state: Record<string, Partial<FilterSpec>>,
) {
  const { layerId, parentLayerId, joinMethod, version } = action;
  if (!parentLayerId) {
    return state;
  }

  const path = [parentLayerId, 'layers', layerId];
  _.set(state, [...path, 'joinMethod'], joinMethod);
  _.set(state, [...path, 'version'], version);
  return state;
}

function clearColumnFilters(
  action: ClearColumnFilterAction<any>,
  state: Record<string, Partial<FilterSpec>>,
): Record<string, Partial<FilterSpec>> {
  const { layerId, parentLayerId, columnKeys } = action;

  const path = parentLayerId
    ? [parentLayerId, 'layers', layerId, 'columns']
    : [layerId, 'columns'];

  // if the state does not exist yet, escape.
  if (!_.get(state, path)) {
    return state;
  }

  // if no column keys provided, remove all column filters for that joined layer
  if (!columnKeys) {
    _.unset(state, path);
    return state;
  }
  if (typeof columnKeys === 'string') {
    _.unset(state, [...path, columnKeys]);
  } else {
    (columnKeys as string[]).forEach(columnKey => {
      _.unset(state, [...path, columnKey]);
    });
  }
  return state;
}

function updatePointFilter(
  action: UpdatePointFilterAction<any>,
  state: Record<string, Partial<FilterSpec>>,
): Record<string, Partial<FilterSpec>> {
  const { layerId, pointIds, currentGeoKeys, updateOnly } = action;
  // break out if the user made an invalid selection
  if (!pointIds) {
    return state;
  }
  const path = [layerId, 'pointIds'];

  // if in add mode, we'll add the new pointId to the filter
  // 'true' values mean the point is to included in the filter
  // 'false' values mean the point is be be excluded in the filter
  if (updateOnly) {
    return _.update(state, path, currentPointIds => {
      // If at least one of them match ones the user had explicitly selected, then
      // that must mean they want to *subtract* the previously selected items
      if (pointIds.some(pointId => pointId in currentPointIds)) {
        return _.omit(currentPointIds, pointIds);
      }

      // else if ALL of the points were previously matched via geometry/filter,
      // then explicitly exclude the ones that were selected.
      const geoKeysSet = new Set(currentGeoKeys);
      if (pointIds.every(pointId => geoKeysSet.has(pointId))) {
        const removePointIds = _.fromPairs(
          pointIds.map(pointId => [pointId, false]),
        );
        return _.assign(currentPointIds, removePointIds);
      }

      // lastly, just add it if not previously selected in any way
      const addPointIds = _.fromPairs(pointIds.map(pointId => [pointId, true]));
      return _.assign(currentPointIds, addPointIds);
    });
  }

  // if not in add mode, clear the pointIds and select only the new one
  const finalPointIds = _.fromPairs(pointIds.map(pointId => [pointId, true]));
  return _.set(state, path, finalPointIds);
}

function updateBufferFilter(
  action: UpdateBufferFilterAction<any>,
  state: Record<string, Partial<FilterSpec>>,
): Record<string, Partial<FilterSpec>> {
  const { layerId, version, buffer, parentLayerId } = action;

  const path = parentLayerId ? [parentLayerId, 'layers', layerId] : [layerId];
  _.set(state, [...path, 'buffer'], buffer);
  _.set(state, [...path, 'version'], version);
  return state;
}

function updateGeometryFilters(
  action: UpdateGeometryFilterAction<any>,
  state: Record<string, Partial<FilterSpec>>,
): Record<string, Partial<FilterSpec>> {
  const { layerId, geometryType, geometry } = action;
  return _.update(state, [layerId, 'geometries', geometryType], v => {
    if (!_.isEmpty(v)) {
      return [...v, geometry];
    }
    return [geometry];
  });
}

function clearBufferFilter(
  action: ClearBufferFilterAction<any>,
  state: Record<string, Partial<FilterSpec>>,
): Record<string, Partial<FilterSpec>> {
  const { layerId, parentLayerId } = action;
  const path = parentLayerId
    ? [parentLayerId, 'layers', layerId, 'buffer']
    : [layerId, 'buffer'];

  _.unset(state, path);
  return state;
}
