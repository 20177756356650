import { PropertyAction } from './reducerFactories';

/**
 * Helpers for redux actions
 */

export type SetPropertyActionCreator<V, E = never> = (
  property: string,
  value: V,
  extra?: E,
) => PropertyAction<V, E>;

/**
 * Make an action creator. Usage:
 *
 *   const setUserProperty = makeSetValueAction('SET_USER_PROPERTY');
 *
 * and then later, use it:
 *
 *   dispatch(setUserProperty('property', 'value'));
 */
export function makeSetPropValueAction<T, E = never>(
  actionType: string,
): SetPropertyActionCreator<T, E> {
  return (property: string, value: T, extra?): PropertyAction<T, E> => ({
    type: actionType,
    property,
    value,
    ...(extra ? { extra } : undefined),
  });
}

/**
 * Make an action creator. Builds off of makeSetPropValueAction.  While the previous requires both
 * value & property, makeSetValueAction also bakes in the property so only the value needs to passed
 * in when dispatched.  Usage:
 *
 *   const setFlubberMode = makeSetValueAction('SET_UI_PROPERTY', 'flubberMode');
 *
 * and then later, use it:
 *
 *   dispatch(setFlubberMode('bouncy'));
 *
 */
export function makeSetValueAction<V, E = never>(
  type: string,
  property: string,
) {
  const setValuePropAction = makeSetPropValueAction<V, E>(type);
  return (value: V, extra?: E) => setValuePropAction(property, value, extra);
}
