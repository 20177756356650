import { t } from 'ttag';

import { UserAction } from 'uf-api';
import { assertNever } from 'uf/base/never';
import { Flavor } from 'uf/base/types';

const EXPORT_ENABLED = t`Export this layer.`;

const EXPORT_DISABLED = t`Export is disabled at this time.`;

/** The value to show for a column value when it is NULL in the db */
export const EmptyColumnValueMessage = t`No value (NULL)`;

export function getExportPermissionText(
  canExport: boolean,
  reason: UserAction.ReasonEnum,
  enabledText = EXPORT_ENABLED,
  disabledText = EXPORT_DISABLED,
) {
  if (canExport) {
    return enabledText;
  }
  if (!reason) {
    return disabledText;
  }
  switch (reason) {
    case 'subscription':
      return t`This feature is disabled in your free trial. Subscribe now to export.`;

    case 'restriction':
      return t`This layer has export restrictions. Contact support for additional information.`;

    case 'permission':
      return t`Your account does not have permission to export this layer.`;

    default:
      return assertNever(reason, t`This layer cannot be exported.`);
  }
}

export function makeLayerId(namespace: string, layerType: string, key: string) {
  return `/${namespace}/${layerType}/${key}` as LayerId;
}

export type LayerId = Flavor<string, 'LayerId'>;
export type LayerVersion = Flavor<string, 'LayerVersion'>;
export type ColumnKey = Flavor<string, 'ColumnKey'>;
export interface LayerManagerCollapsedState {
  [categoryKey: string]: boolean;
}
