/**
 * UrbanFootprint App API
 * API for serving UrbanFootprint Application
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { ClientResponse } from '../variables';

// @ts-ignore: This is used only for params, but some services have param-free APIs
import { SettingsServiceInterface } from './settings.serviceInterface';

// @ts-ignore: These imports are for both params and returnType, but we only use returnType
import { ProblemDetail } from '../model/problemDetail';
// @ts-ignore: These imports are for both params and returnType, but we only use returnType
import { UserGlobalSetting } from '../model/userGlobalSetting';
// @ts-ignore: These imports are for both params and returnType, but we only use returnType
import { UserOrgSetting } from '../model/userOrgSetting';
// @ts-ignore: These imports are for both params and returnType, but we only use returnType
import { UserSetting } from '../model/userSetting';

/**
 * Package: api
 */

const getUserSettingsPrefix = `uf-api/settings/get_user_settings/`;
/**
 * Action types for settings.get_user_settings
 *
 * Get everything the client needs to quickly setting a view
 */
export const getUserSettingsActionTypes = {
  LOAD: `${getUserSettingsPrefix}LOAD` as 'uf-api/settings/get_user_settings/LOAD',
  SUCCESS:
    `${getUserSettingsPrefix}SUCCESS` as 'uf-api/settings/get_user_settings/SUCCESS',
  FAILURE:
    `${getUserSettingsPrefix}FAILURE` as 'uf-api/settings/get_user_settings/FAILURE',
  CLEAR:
    `${getUserSettingsPrefix}CLEAR` as 'uf-api/settings/get_user_settings/CLEAR',
} as const;

/**
 * LOAD action for settings.get_user_settings
 * Get everything the client needs to quickly setting a view
 */
export interface getUserSettingsLoadAction {
  readonly type: 'uf-api/settings/get_user_settings/LOAD';

  readonly promise: Promise<ClientResponse<UserSetting>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for settings.get_user_settings
 * Get everything the client needs to quickly setting a view
 */
export interface getUserSettingsSuccessAction {
  readonly type: 'uf-api/settings/get_user_settings/SUCCESS';
  readonly result: UserSetting;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for settings.get_user_settings
 * Get everything the client needs to quickly setting a view
 */
export interface getUserSettingsFailureAction {
  readonly type: 'uf-api/settings/get_user_settings/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for settings.get_user_settings
 * Get everything the client needs to quickly setting a view
 */
export interface getUserSettingsClearAction {
  readonly type: 'uf-api/settings/get_user_settings/CLEAR';
  readonly key: string;
}

const updateGlobalSettingPrefix = `uf-api/settings/update_global_setting/`;
/**
 * Action types for settings.update_global_setting
 *
 * Update global-level user settings
 */
export const updateGlobalSettingActionTypes = {
  LOAD: `${updateGlobalSettingPrefix}LOAD` as 'uf-api/settings/update_global_setting/LOAD',
  SUCCESS:
    `${updateGlobalSettingPrefix}SUCCESS` as 'uf-api/settings/update_global_setting/SUCCESS',
  FAILURE:
    `${updateGlobalSettingPrefix}FAILURE` as 'uf-api/settings/update_global_setting/FAILURE',
  CLEAR:
    `${updateGlobalSettingPrefix}CLEAR` as 'uf-api/settings/update_global_setting/CLEAR',
} as const;

/**
 * LOAD action for settings.update_global_setting
 * Update global-level user settings
 */
export interface updateGlobalSettingLoadAction {
  readonly type: 'uf-api/settings/update_global_setting/LOAD';
  readonly params: SettingsServiceInterface.updateGlobalSettingParams;
  readonly promise: Promise<ClientResponse<UserSetting>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for settings.update_global_setting
 * Update global-level user settings
 */
export interface updateGlobalSettingSuccessAction {
  readonly type: 'uf-api/settings/update_global_setting/SUCCESS';
  readonly result: UserSetting;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for settings.update_global_setting
 * Update global-level user settings
 */
export interface updateGlobalSettingFailureAction {
  readonly type: 'uf-api/settings/update_global_setting/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for settings.update_global_setting
 * Update global-level user settings
 */
export interface updateGlobalSettingClearAction {
  readonly type: 'uf-api/settings/update_global_setting/CLEAR';
  readonly key: string;
}

const updateOrgSettingPrefix = `uf-api/settings/update_org_setting/`;
/**
 * Action types for settings.update_org_setting
 *
 * Update org-level user settings
 */
export const updateOrgSettingActionTypes = {
  LOAD: `${updateOrgSettingPrefix}LOAD` as 'uf-api/settings/update_org_setting/LOAD',
  SUCCESS:
    `${updateOrgSettingPrefix}SUCCESS` as 'uf-api/settings/update_org_setting/SUCCESS',
  FAILURE:
    `${updateOrgSettingPrefix}FAILURE` as 'uf-api/settings/update_org_setting/FAILURE',
  CLEAR:
    `${updateOrgSettingPrefix}CLEAR` as 'uf-api/settings/update_org_setting/CLEAR',
} as const;

/**
 * LOAD action for settings.update_org_setting
 * Update org-level user settings
 */
export interface updateOrgSettingLoadAction {
  readonly type: 'uf-api/settings/update_org_setting/LOAD';
  readonly params: SettingsServiceInterface.updateOrgSettingParams;
  readonly promise: Promise<ClientResponse<UserSetting>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for settings.update_org_setting
 * Update org-level user settings
 */
export interface updateOrgSettingSuccessAction {
  readonly type: 'uf-api/settings/update_org_setting/SUCCESS';
  readonly result: UserSetting;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for settings.update_org_setting
 * Update org-level user settings
 */
export interface updateOrgSettingFailureAction {
  readonly type: 'uf-api/settings/update_org_setting/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for settings.update_org_setting
 * Update org-level user settings
 */
export interface updateOrgSettingClearAction {
  readonly type: 'uf-api/settings/update_org_setting/CLEAR';
  readonly key: string;
}

const updateProjectSettingPrefix = `uf-api/settings/update_project_setting/`;
/**
 * Action types for settings.update_project_setting
 *
 * Update project-level user settings
 */
export const updateProjectSettingActionTypes = {
  LOAD: `${updateProjectSettingPrefix}LOAD` as 'uf-api/settings/update_project_setting/LOAD',
  SUCCESS:
    `${updateProjectSettingPrefix}SUCCESS` as 'uf-api/settings/update_project_setting/SUCCESS',
  FAILURE:
    `${updateProjectSettingPrefix}FAILURE` as 'uf-api/settings/update_project_setting/FAILURE',
  CLEAR:
    `${updateProjectSettingPrefix}CLEAR` as 'uf-api/settings/update_project_setting/CLEAR',
} as const;

/**
 * LOAD action for settings.update_project_setting
 * Update project-level user settings
 */
export interface updateProjectSettingLoadAction {
  readonly type: 'uf-api/settings/update_project_setting/LOAD';
  readonly params: SettingsServiceInterface.updateProjectSettingParams;
  readonly promise: Promise<ClientResponse<UserSetting>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for settings.update_project_setting
 * Update project-level user settings
 */
export interface updateProjectSettingSuccessAction {
  readonly type: 'uf-api/settings/update_project_setting/SUCCESS';
  readonly result: UserSetting;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for settings.update_project_setting
 * Update project-level user settings
 */
export interface updateProjectSettingFailureAction {
  readonly type: 'uf-api/settings/update_project_setting/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for settings.update_project_setting
 * Update project-level user settings
 */
export interface updateProjectSettingClearAction {
  readonly type: 'uf-api/settings/update_project_setting/CLEAR';
  readonly key: string;
}

export const settingsActionTypesMap = {
  /** Action types for settings.get_user_settings */
  get_user_settings: getUserSettingsActionTypes,
  /** Action types for settings.update_global_setting */
  update_global_setting: updateGlobalSettingActionTypes,
  /** Action types for settings.update_org_setting */
  update_org_setting: updateOrgSettingActionTypes,
  /** Action types for settings.update_project_setting */
  update_project_setting: updateProjectSettingActionTypes,
} as const;

export const settingsDetailsMap = {
  get_user_settings: {
    /** HTTP method for settings.get_user_settings */
    httpMethod: 'get' as const,
  },
  update_global_setting: {
    /** HTTP method for settings.update_global_setting */
    httpMethod: 'put' as const,
  },
  update_org_setting: {
    /** HTTP method for settings.update_org_setting */
    httpMethod: 'put' as const,
  },
  update_project_setting: {
    /** HTTP method for settings.update_project_setting */
    httpMethod: 'put' as const,
  },
} as const;

/**
 * Local DataState<T> - kept in sync with uf/data/dataState.ts
 */
interface DataState<T> {
  readonly loading: boolean;
  readonly loaded: boolean;
  readonly requestId: string;
  readonly requestTime?: string;
  readonly responseTime?: string;
  readonly requestCount?: number;
  readonly error?: any;
  readonly promise?: Promise<T>;
  readonly data?: T;
  readonly extra?: any;
  readonly key?: string;
}
export interface SettingsServiceState {
  /** getUserSettings state, by key */
  get_user_settings: {
    readonly [key: string]: DataState<UserSetting>;
  };
  /** all recent updateGlobalSetting results (POST/PUT/PATCH) */
  readonly update_global_setting: DataState<UserSetting>[];
  /** all recent updateOrgSetting results (POST/PUT/PATCH) */
  readonly update_org_setting: DataState<UserSetting>[];
  /** all recent updateProjectSetting results (POST/PUT/PATCH) */
  readonly update_project_setting: DataState<UserSetting>[];
}
