import colors from 'material-colors';

import {
  CuratedColumnSymbology,
  CUSTOM_THEME,
  DEFAULT_OPACITY,
  DistributionTypes,
  InterpolationTypes,
  PaintColorTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/public/dataset/sonoma_vmt_outcomes_base': {
    vmt_daily_per_hh: [
      {
        type: SymbologyTypes.FILL,
        paint: {
          'fill-color': {
            property: 'vmt_daily_per_hh',
            type: PaintColorTypes.INTERVAL,
            stops: [
              { value: 0, color: '#E8E8E8' },
              { value: 1, color: '#004d1a' },
              { value: 20, color: '#009933' },
              { value: 30, color: '#00CC33' },
              { value: 40, color: colors.yellow.a200 },
              { value: 60, color: '#FF9900' },
              { value: 80, color: '#cc1500' },
            ],
          },
          'fill-opacity': DEFAULT_OPACITY,
        },
        display_hints: {
          scale: SymbologyScales.NUMERIC,
          distribution: DistributionTypes.DEFAULT,
          interpolation: InterpolationTypes.INTERVAL,
          theme: CUSTOM_THEME,
        },
      },
    ],
  },
};

export default symbologies;
