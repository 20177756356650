import { AnyAction } from 'redux';

/**
 * Helper for matching up the LOAD and SUCCESS actions of api calls inside an epic.
 *
 * Example:
 *
 *   return action$.pipe(
 *     ofType(getFoo.LOAD),
 *     switchMap(loadAction => {
 *       // get foo's value at time of load
 *       const prevFoo = getFoo(state$.value);
 *
 *       return action$.pipe(
 *         ofType(getFoo.SUCCESS),
 *         filter(successAction => matchRequestId(loadAction, successAction)),
 *         map(successAction => {
 *           // get foo's value after save
 *           const foo = getFoo(state$.value);
 *           return myFooAction(foo);
 *         }),
 *       );
 *     }),
 *   );
 */
export function matchRequestId(
  loadAction: AnyAction & { requestId: string },
  successAction: AnyAction & { requestId: string },
) {
  return loadAction.requestId === successAction.requestId;
}
