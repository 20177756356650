import {
  CuratedColumnSymbology,
  InterpolationTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/public/dataset/city_of_philadelphia_zoning_base_districts_philadelphia': {
    long_code: [
      {
        display_hints: {
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          scale: SymbologyScales.CATEGORICAL,
          theme: 'custom',
        },
        paint: {
          'fill-color': {
            default: '#bcbd22',
            property: 'long_code',
            stops: [
              {
                color: '#fda1ff',
                value: 'CA-1',
              },
              {
                color: '#fda1ff',
                value: 'CA-2',
              },
              {
                color: '#fda1ff',
                value: 'CMX-1',
              },
              {
                color: '#fa28ff',
                value: 'CMX-2',
              },
              {
                color: '#fa28ff',
                value: 'CMX-2.5',
              },
              {
                color: '#fa28ff',
                value: 'CMX-3',
              },
              {
                color: '#d62728',
                value: 'CMX-4',
              },
              {
                color: '#d33115',
                value: 'CMX-5',
              },
              {
                color: '#aea1ff',
                value: 'I-1',
              },
              {
                color: '#aea1ff',
                value: 'I-2',
              },
              {
                color: '#aea1ff',
                value: 'I-3',
              },
              {
                color: '#c49c94',
                value: 'ICMX',
              },
              {
                color: '#e377c2',
                value: 'I-P',
              },
              {
                color: '#f7b6d2',
                value: 'IRMX',
              },
              {
                color: '#e27300',
                value: 'RM-1',
              },
              {
                color: '#e27300',
                value: 'RM-2',
              },
              {
                color: '#e27300',
                value: 'RM-3',
              },
              {
                color: '#e27300',
                value: 'RM-4',
              },
              {
                color: '#f44e3b',
                value: 'RMX-1',
              },
              {
                color: '#f44e3b',
                value: 'RMX-2',
              },
              {
                color: '#f44e3b',
                value: 'RMX-3',
              },
              {
                color: '#fe9200',
                value: 'RSA-1',
              },
              {
                color: '#fe9200',
                value: 'RSA-2',
              },
              {
                color: '#fe9200',
                value: 'RSA-3',
              },
              {
                color: '#fe9200',
                value: 'RSA-4',
              },
              {
                color: '#fe9200',
                value: 'RSA-5',
              },
              {
                color: '#fcdc00',
                value: 'RSD-1',
              },
              {
                color: '#fcdc00',
                value: 'RSD-2',
              },
              {
                color: '#fcdc00',
                value: 'RSD-3',
              },
              {
                color: '#fe9200',
                value: 'RTA-1',
              },
              {
                color: '#808080',
                value: 'SP-AIR',
              },
              {
                color: '#999999',
                value: 'SP-ENT',
              },
              {
                color: '#999999',
                value: 'SP-INS',
              },
              {
                color: '#999999',
                value: 'SP-PO-A',
              },
              {
                color: '#7f7f7f',
                value: 'SP-PO-P',
              },
              {
                color: '#c7c7c7',
                value: 'SP-STA',
              },
            ],
            type: 'categorical',
          },
          'fill-opacity': 0.9,
        },
        type: SymbologyTypes.FILL,
      },
      {
        display_hints: {
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          scale: SymbologyScales.CATEGORICAL,
          theme: 'custom',
        },
        paint: {
          'line-color': 'transparent',
          'line-opacity': 0.9,
          'line-width': 0.5,
        },
        type: SymbologyTypes.LINE,
      },
    ],
  },
};

export default symbologies;
