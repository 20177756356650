/**
 * UrbanFootprint App API
 * API for serving UrbanFootprint Application
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { ClientResponse } from '../variables';

// @ts-ignore: This is used only for params, but some services have param-free APIs
import { OrganizationServiceInterface } from './organization.serviceInterface';

// @ts-ignore: These imports are for both params and returnType, but we only use returnType
import { BillingInfo } from '../model/billingInfo';
// @ts-ignore: These imports are for both params and returnType, but we only use returnType
import { FeatureFlags } from '../model/featureFlags';
// @ts-ignore: These imports are for both params and returnType, but we only use returnType
import { InsightCategory } from '../model/insightCategory';
// @ts-ignore: These imports are for both params and returnType, but we only use returnType
import { InvitationList } from '../model/invitationList';
// @ts-ignore: These imports are for both params and returnType, but we only use returnType
import { MemberCount } from '../model/memberCount';
// @ts-ignore: These imports are for both params and returnType, but we only use returnType
import { MemberList } from '../model/memberList';
// @ts-ignore: These imports are for both params and returnType, but we only use returnType
import { OrganizationFileList } from '../model/organizationFileList';
// @ts-ignore: These imports are for both params and returnType, but we only use returnType
import { OrganizationFilesListItem } from '../model/organizationFilesListItem';
// @ts-ignore: These imports are for both params and returnType, but we only use returnType
import { OrganizationInfo } from '../model/organizationInfo';
// @ts-ignore: These imports are for both params and returnType, but we only use returnType
import { OrganizationInfoList } from '../model/organizationInfoList';
// @ts-ignore: These imports are for both params and returnType, but we only use returnType
import { OrganizationLogoInfo } from '../model/organizationLogoInfo';
// @ts-ignore: These imports are for both params and returnType, but we only use returnType
import { ProblemDetail } from '../model/problemDetail';
// @ts-ignore: These imports are for both params and returnType, but we only use returnType
import { ProjectList } from '../model/projectList';
// @ts-ignore: These imports are for both params and returnType, but we only use returnType
import { Subscription } from '../model/subscription';

/**
 * Package: api
 */

const addMemberToOrganizationPrefix = `uf-api/organization/add_member_to_organization/`;
/**
 * Action types for organization.add_member_to_organization
 *
 * Adds a member to an organization.
 */
export const addMemberToOrganizationActionTypes = {
  LOAD: `${addMemberToOrganizationPrefix}LOAD` as 'uf-api/organization/add_member_to_organization/LOAD',
  SUCCESS:
    `${addMemberToOrganizationPrefix}SUCCESS` as 'uf-api/organization/add_member_to_organization/SUCCESS',
  FAILURE:
    `${addMemberToOrganizationPrefix}FAILURE` as 'uf-api/organization/add_member_to_organization/FAILURE',
  CLEAR:
    `${addMemberToOrganizationPrefix}CLEAR` as 'uf-api/organization/add_member_to_organization/CLEAR',
} as const;

/**
 * LOAD action for organization.add_member_to_organization
 * Adds a member to an organization.
 */
export interface addMemberToOrganizationLoadAction {
  readonly type: 'uf-api/organization/add_member_to_organization/LOAD';
  readonly params: OrganizationServiceInterface.addMemberToOrganizationParams;
  readonly promise: Promise<ClientResponse<{}>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for organization.add_member_to_organization
 * Adds a member to an organization.
 */
export interface addMemberToOrganizationSuccessAction {
  readonly type: 'uf-api/organization/add_member_to_organization/SUCCESS';
  readonly result: {};
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for organization.add_member_to_organization
 * Adds a member to an organization.
 */
export interface addMemberToOrganizationFailureAction {
  readonly type: 'uf-api/organization/add_member_to_organization/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for organization.add_member_to_organization
 * Adds a member to an organization.
 */
export interface addMemberToOrganizationClearAction {
  readonly type: 'uf-api/organization/add_member_to_organization/CLEAR';
  readonly key: string;
}

const addOrgLogoPrefix = `uf-api/organization/add_org_logo/`;
/**
 * Action types for organization.add_org_logo
 *
 * Add a new logo for an organization.
 */
export const addOrgLogoActionTypes = {
  LOAD: `${addOrgLogoPrefix}LOAD` as 'uf-api/organization/add_org_logo/LOAD',
  SUCCESS:
    `${addOrgLogoPrefix}SUCCESS` as 'uf-api/organization/add_org_logo/SUCCESS',
  FAILURE:
    `${addOrgLogoPrefix}FAILURE` as 'uf-api/organization/add_org_logo/FAILURE',
  CLEAR: `${addOrgLogoPrefix}CLEAR` as 'uf-api/organization/add_org_logo/CLEAR',
} as const;

/**
 * LOAD action for organization.add_org_logo
 * Add a new logo for an organization.
 */
export interface addOrgLogoLoadAction {
  readonly type: 'uf-api/organization/add_org_logo/LOAD';
  readonly params: OrganizationServiceInterface.addOrgLogoParams;
  readonly promise: Promise<ClientResponse<OrganizationLogoInfo>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for organization.add_org_logo
 * Add a new logo for an organization.
 */
export interface addOrgLogoSuccessAction {
  readonly type: 'uf-api/organization/add_org_logo/SUCCESS';
  readonly result: OrganizationLogoInfo;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for organization.add_org_logo
 * Add a new logo for an organization.
 */
export interface addOrgLogoFailureAction {
  readonly type: 'uf-api/organization/add_org_logo/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for organization.add_org_logo
 * Add a new logo for an organization.
 */
export interface addOrgLogoClearAction {
  readonly type: 'uf-api/organization/add_org_logo/CLEAR';
  readonly key: string;
}

const cancelInvitationPrefix = `uf-api/organization/cancel_invitation/`;
/**
 * Action types for organization.cancel_invitation
 *
 * Cancel a pending invitation.
 */
export const cancelInvitationActionTypes = {
  LOAD: `${cancelInvitationPrefix}LOAD` as 'uf-api/organization/cancel_invitation/LOAD',
  SUCCESS:
    `${cancelInvitationPrefix}SUCCESS` as 'uf-api/organization/cancel_invitation/SUCCESS',
  FAILURE:
    `${cancelInvitationPrefix}FAILURE` as 'uf-api/organization/cancel_invitation/FAILURE',
  CLEAR:
    `${cancelInvitationPrefix}CLEAR` as 'uf-api/organization/cancel_invitation/CLEAR',
} as const;

/**
 * LOAD action for organization.cancel_invitation
 * Cancel a pending invitation.
 */
export interface cancelInvitationLoadAction {
  readonly type: 'uf-api/organization/cancel_invitation/LOAD';
  readonly params: OrganizationServiceInterface.cancelInvitationParams;
  readonly promise: Promise<ClientResponse<{}>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for organization.cancel_invitation
 * Cancel a pending invitation.
 */
export interface cancelInvitationSuccessAction {
  readonly type: 'uf-api/organization/cancel_invitation/SUCCESS';
  readonly result: {};
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for organization.cancel_invitation
 * Cancel a pending invitation.
 */
export interface cancelInvitationFailureAction {
  readonly type: 'uf-api/organization/cancel_invitation/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for organization.cancel_invitation
 * Cancel a pending invitation.
 */
export interface cancelInvitationClearAction {
  readonly type: 'uf-api/organization/cancel_invitation/CLEAR';
  readonly key: string;
}

const changeSubscriptionPlanPrefix = `uf-api/organization/change_subscription_plan/`;
/**
 * Action types for organization.change_subscription_plan
 *
 * Change the plan of an organization.
 */
export const changeSubscriptionPlanActionTypes = {
  LOAD: `${changeSubscriptionPlanPrefix}LOAD` as 'uf-api/organization/change_subscription_plan/LOAD',
  SUCCESS:
    `${changeSubscriptionPlanPrefix}SUCCESS` as 'uf-api/organization/change_subscription_plan/SUCCESS',
  FAILURE:
    `${changeSubscriptionPlanPrefix}FAILURE` as 'uf-api/organization/change_subscription_plan/FAILURE',
  CLEAR:
    `${changeSubscriptionPlanPrefix}CLEAR` as 'uf-api/organization/change_subscription_plan/CLEAR',
} as const;

/**
 * LOAD action for organization.change_subscription_plan
 * Change the plan of an organization.
 */
export interface changeSubscriptionPlanLoadAction {
  readonly type: 'uf-api/organization/change_subscription_plan/LOAD';
  readonly params: OrganizationServiceInterface.changeSubscriptionPlanParams;
  readonly promise: Promise<ClientResponse<Subscription>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for organization.change_subscription_plan
 * Change the plan of an organization.
 */
export interface changeSubscriptionPlanSuccessAction {
  readonly type: 'uf-api/organization/change_subscription_plan/SUCCESS';
  readonly result: Subscription;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for organization.change_subscription_plan
 * Change the plan of an organization.
 */
export interface changeSubscriptionPlanFailureAction {
  readonly type: 'uf-api/organization/change_subscription_plan/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for organization.change_subscription_plan
 * Change the plan of an organization.
 */
export interface changeSubscriptionPlanClearAction {
  readonly type: 'uf-api/organization/change_subscription_plan/CLEAR';
  readonly key: string;
}

const createOrganizationPrefix = `uf-api/organization/create_organization/`;
/**
 * Action types for organization.create_organization
 *
 * Create Organization with key.
 */
export const createOrganizationActionTypes = {
  LOAD: `${createOrganizationPrefix}LOAD` as 'uf-api/organization/create_organization/LOAD',
  SUCCESS:
    `${createOrganizationPrefix}SUCCESS` as 'uf-api/organization/create_organization/SUCCESS',
  FAILURE:
    `${createOrganizationPrefix}FAILURE` as 'uf-api/organization/create_organization/FAILURE',
  CLEAR:
    `${createOrganizationPrefix}CLEAR` as 'uf-api/organization/create_organization/CLEAR',
} as const;

/**
 * LOAD action for organization.create_organization
 * Create Organization with key.
 */
export interface createOrganizationLoadAction {
  readonly type: 'uf-api/organization/create_organization/LOAD';
  readonly params: OrganizationServiceInterface.createOrganizationParams;
  readonly promise: Promise<ClientResponse<OrganizationInfo>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for organization.create_organization
 * Create Organization with key.
 */
export interface createOrganizationSuccessAction {
  readonly type: 'uf-api/organization/create_organization/SUCCESS';
  readonly result: OrganizationInfo;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for organization.create_organization
 * Create Organization with key.
 */
export interface createOrganizationFailureAction {
  readonly type: 'uf-api/organization/create_organization/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for organization.create_organization
 * Create Organization with key.
 */
export interface createOrganizationClearAction {
  readonly type: 'uf-api/organization/create_organization/CLEAR';
  readonly key: string;
}

const deleteOrgLogoPrefix = `uf-api/organization/delete_org_logo/`;
/**
 * Action types for organization.delete_org_logo
 *
 * Delete an organization logo.
 */
export const deleteOrgLogoActionTypes = {
  LOAD: `${deleteOrgLogoPrefix}LOAD` as 'uf-api/organization/delete_org_logo/LOAD',
  SUCCESS:
    `${deleteOrgLogoPrefix}SUCCESS` as 'uf-api/organization/delete_org_logo/SUCCESS',
  FAILURE:
    `${deleteOrgLogoPrefix}FAILURE` as 'uf-api/organization/delete_org_logo/FAILURE',
  CLEAR:
    `${deleteOrgLogoPrefix}CLEAR` as 'uf-api/organization/delete_org_logo/CLEAR',
} as const;

/**
 * LOAD action for organization.delete_org_logo
 * Delete an organization logo.
 */
export interface deleteOrgLogoLoadAction {
  readonly type: 'uf-api/organization/delete_org_logo/LOAD';
  readonly params: OrganizationServiceInterface.deleteOrgLogoParams;
  readonly promise: Promise<ClientResponse<{}>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for organization.delete_org_logo
 * Delete an organization logo.
 */
export interface deleteOrgLogoSuccessAction {
  readonly type: 'uf-api/organization/delete_org_logo/SUCCESS';
  readonly result: {};
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for organization.delete_org_logo
 * Delete an organization logo.
 */
export interface deleteOrgLogoFailureAction {
  readonly type: 'uf-api/organization/delete_org_logo/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for organization.delete_org_logo
 * Delete an organization logo.
 */
export interface deleteOrgLogoClearAction {
  readonly type: 'uf-api/organization/delete_org_logo/CLEAR';
  readonly key: string;
}

const deleteOrganizationPrefix = `uf-api/organization/delete_organization/`;
/**
 * Action types for organization.delete_organization
 *
 * Delete organization, projects, and layers.
 */
export const deleteOrganizationActionTypes = {
  LOAD: `${deleteOrganizationPrefix}LOAD` as 'uf-api/organization/delete_organization/LOAD',
  SUCCESS:
    `${deleteOrganizationPrefix}SUCCESS` as 'uf-api/organization/delete_organization/SUCCESS',
  FAILURE:
    `${deleteOrganizationPrefix}FAILURE` as 'uf-api/organization/delete_organization/FAILURE',
  CLEAR:
    `${deleteOrganizationPrefix}CLEAR` as 'uf-api/organization/delete_organization/CLEAR',
} as const;

/**
 * LOAD action for organization.delete_organization
 * Delete organization, projects, and layers.
 */
export interface deleteOrganizationLoadAction {
  readonly type: 'uf-api/organization/delete_organization/LOAD';
  readonly params: OrganizationServiceInterface.deleteOrganizationParams;
  readonly promise: Promise<ClientResponse<{}>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for organization.delete_organization
 * Delete organization, projects, and layers.
 */
export interface deleteOrganizationSuccessAction {
  readonly type: 'uf-api/organization/delete_organization/SUCCESS';
  readonly result: {};
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for organization.delete_organization
 * Delete organization, projects, and layers.
 */
export interface deleteOrganizationFailureAction {
  readonly type: 'uf-api/organization/delete_organization/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for organization.delete_organization
 * Delete organization, projects, and layers.
 */
export interface deleteOrganizationClearAction {
  readonly type: 'uf-api/organization/delete_organization/CLEAR';
  readonly key: string;
}

const editMemberInOrganizationPrefix = `uf-api/organization/edit_member_in_organization/`;
/**
 * Action types for organization.edit_member_in_organization
 *
 * Edit the role of a member in an organization
 */
export const editMemberInOrganizationActionTypes = {
  LOAD: `${editMemberInOrganizationPrefix}LOAD` as 'uf-api/organization/edit_member_in_organization/LOAD',
  SUCCESS:
    `${editMemberInOrganizationPrefix}SUCCESS` as 'uf-api/organization/edit_member_in_organization/SUCCESS',
  FAILURE:
    `${editMemberInOrganizationPrefix}FAILURE` as 'uf-api/organization/edit_member_in_organization/FAILURE',
  CLEAR:
    `${editMemberInOrganizationPrefix}CLEAR` as 'uf-api/organization/edit_member_in_organization/CLEAR',
} as const;

/**
 * LOAD action for organization.edit_member_in_organization
 * Edit the role of a member in an organization
 */
export interface editMemberInOrganizationLoadAction {
  readonly type: 'uf-api/organization/edit_member_in_organization/LOAD';
  readonly params: OrganizationServiceInterface.editMemberInOrganizationParams;
  readonly promise: Promise<ClientResponse<{}>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for organization.edit_member_in_organization
 * Edit the role of a member in an organization
 */
export interface editMemberInOrganizationSuccessAction {
  readonly type: 'uf-api/organization/edit_member_in_organization/SUCCESS';
  readonly result: {};
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for organization.edit_member_in_organization
 * Edit the role of a member in an organization
 */
export interface editMemberInOrganizationFailureAction {
  readonly type: 'uf-api/organization/edit_member_in_organization/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for organization.edit_member_in_organization
 * Edit the role of a member in an organization
 */
export interface editMemberInOrganizationClearAction {
  readonly type: 'uf-api/organization/edit_member_in_organization/CLEAR';
  readonly key: string;
}

const getBillingInfoPrefix = `uf-api/organization/get_billing_info/`;
/**
 * Action types for organization.get_billing_info
 *
 * Get Billing Information
 */
export const getBillingInfoActionTypes = {
  LOAD: `${getBillingInfoPrefix}LOAD` as 'uf-api/organization/get_billing_info/LOAD',
  SUCCESS:
    `${getBillingInfoPrefix}SUCCESS` as 'uf-api/organization/get_billing_info/SUCCESS',
  FAILURE:
    `${getBillingInfoPrefix}FAILURE` as 'uf-api/organization/get_billing_info/FAILURE',
  CLEAR:
    `${getBillingInfoPrefix}CLEAR` as 'uf-api/organization/get_billing_info/CLEAR',
} as const;

/**
 * LOAD action for organization.get_billing_info
 * Get Billing Information
 */
export interface getBillingInfoLoadAction {
  readonly type: 'uf-api/organization/get_billing_info/LOAD';
  readonly params: OrganizationServiceInterface.getBillingInfoParams;
  readonly promise: Promise<ClientResponse<BillingInfo>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for organization.get_billing_info
 * Get Billing Information
 */
export interface getBillingInfoSuccessAction {
  readonly type: 'uf-api/organization/get_billing_info/SUCCESS';
  readonly result: BillingInfo;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for organization.get_billing_info
 * Get Billing Information
 */
export interface getBillingInfoFailureAction {
  readonly type: 'uf-api/organization/get_billing_info/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for organization.get_billing_info
 * Get Billing Information
 */
export interface getBillingInfoClearAction {
  readonly type: 'uf-api/organization/get_billing_info/CLEAR';
  readonly key: string;
}

const getFilteredMemberCountInOrganizationPrefix = `uf-api/organization/get_filtered_member_count_in_organization/`;
/**
 * Action types for organization.get_filtered_member_count_in_organization
 *
 * Return a count of non-internal members for the given organization.
 */
export const getFilteredMemberCountInOrganizationActionTypes = {
  LOAD: `${getFilteredMemberCountInOrganizationPrefix}LOAD` as 'uf-api/organization/get_filtered_member_count_in_organization/LOAD',
  SUCCESS:
    `${getFilteredMemberCountInOrganizationPrefix}SUCCESS` as 'uf-api/organization/get_filtered_member_count_in_organization/SUCCESS',
  FAILURE:
    `${getFilteredMemberCountInOrganizationPrefix}FAILURE` as 'uf-api/organization/get_filtered_member_count_in_organization/FAILURE',
  CLEAR:
    `${getFilteredMemberCountInOrganizationPrefix}CLEAR` as 'uf-api/organization/get_filtered_member_count_in_organization/CLEAR',
} as const;

/**
 * LOAD action for organization.get_filtered_member_count_in_organization
 * Return a count of non-internal members for the given organization.
 */
export interface getFilteredMemberCountInOrganizationLoadAction {
  readonly type: 'uf-api/organization/get_filtered_member_count_in_organization/LOAD';
  readonly params: OrganizationServiceInterface.getFilteredMemberCountInOrganizationParams;
  readonly promise: Promise<ClientResponse<MemberCount>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for organization.get_filtered_member_count_in_organization
 * Return a count of non-internal members for the given organization.
 */
export interface getFilteredMemberCountInOrganizationSuccessAction {
  readonly type: 'uf-api/organization/get_filtered_member_count_in_organization/SUCCESS';
  readonly result: MemberCount;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for organization.get_filtered_member_count_in_organization
 * Return a count of non-internal members for the given organization.
 */
export interface getFilteredMemberCountInOrganizationFailureAction {
  readonly type: 'uf-api/organization/get_filtered_member_count_in_organization/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for organization.get_filtered_member_count_in_organization
 * Return a count of non-internal members for the given organization.
 */
export interface getFilteredMemberCountInOrganizationClearAction {
  readonly type: 'uf-api/organization/get_filtered_member_count_in_organization/CLEAR';
  readonly key: string;
}

const getInvitationsForOrganizationPrefix = `uf-api/organization/get_invitations_for_organization/`;
/**
 * Action types for organization.get_invitations_for_organization
 *
 * Return a list of invitations for the given organization.
 */
export const getInvitationsForOrganizationActionTypes = {
  LOAD: `${getInvitationsForOrganizationPrefix}LOAD` as 'uf-api/organization/get_invitations_for_organization/LOAD',
  SUCCESS:
    `${getInvitationsForOrganizationPrefix}SUCCESS` as 'uf-api/organization/get_invitations_for_organization/SUCCESS',
  FAILURE:
    `${getInvitationsForOrganizationPrefix}FAILURE` as 'uf-api/organization/get_invitations_for_organization/FAILURE',
  CLEAR:
    `${getInvitationsForOrganizationPrefix}CLEAR` as 'uf-api/organization/get_invitations_for_organization/CLEAR',
} as const;

/**
 * LOAD action for organization.get_invitations_for_organization
 * Return a list of invitations for the given organization.
 */
export interface getInvitationsForOrganizationLoadAction {
  readonly type: 'uf-api/organization/get_invitations_for_organization/LOAD';
  readonly params: OrganizationServiceInterface.getInvitationsForOrganizationParams;
  readonly promise: Promise<ClientResponse<InvitationList>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for organization.get_invitations_for_organization
 * Return a list of invitations for the given organization.
 */
export interface getInvitationsForOrganizationSuccessAction {
  readonly type: 'uf-api/organization/get_invitations_for_organization/SUCCESS';
  readonly result: InvitationList;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for organization.get_invitations_for_organization
 * Return a list of invitations for the given organization.
 */
export interface getInvitationsForOrganizationFailureAction {
  readonly type: 'uf-api/organization/get_invitations_for_organization/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for organization.get_invitations_for_organization
 * Return a list of invitations for the given organization.
 */
export interface getInvitationsForOrganizationClearAction {
  readonly type: 'uf-api/organization/get_invitations_for_organization/CLEAR';
  readonly key: string;
}

const getMembersInfoInOrganizationPrefix = `uf-api/organization/get_members_info_in_organization/`;
/**
 * Action types for organization.get_members_info_in_organization
 *
 * Return a list of members for the given organization.
 */
export const getMembersInfoInOrganizationActionTypes = {
  LOAD: `${getMembersInfoInOrganizationPrefix}LOAD` as 'uf-api/organization/get_members_info_in_organization/LOAD',
  SUCCESS:
    `${getMembersInfoInOrganizationPrefix}SUCCESS` as 'uf-api/organization/get_members_info_in_organization/SUCCESS',
  FAILURE:
    `${getMembersInfoInOrganizationPrefix}FAILURE` as 'uf-api/organization/get_members_info_in_organization/FAILURE',
  CLEAR:
    `${getMembersInfoInOrganizationPrefix}CLEAR` as 'uf-api/organization/get_members_info_in_organization/CLEAR',
} as const;

/**
 * LOAD action for organization.get_members_info_in_organization
 * Return a list of members for the given organization.
 */
export interface getMembersInfoInOrganizationLoadAction {
  readonly type: 'uf-api/organization/get_members_info_in_organization/LOAD';
  readonly params: OrganizationServiceInterface.getMembersInfoInOrganizationParams;
  readonly promise: Promise<ClientResponse<MemberList>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for organization.get_members_info_in_organization
 * Return a list of members for the given organization.
 */
export interface getMembersInfoInOrganizationSuccessAction {
  readonly type: 'uf-api/organization/get_members_info_in_organization/SUCCESS';
  readonly result: MemberList;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for organization.get_members_info_in_organization
 * Return a list of members for the given organization.
 */
export interface getMembersInfoInOrganizationFailureAction {
  readonly type: 'uf-api/organization/get_members_info_in_organization/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for organization.get_members_info_in_organization
 * Return a list of members for the given organization.
 */
export interface getMembersInfoInOrganizationClearAction {
  readonly type: 'uf-api/organization/get_members_info_in_organization/CLEAR';
  readonly key: string;
}

const getOrgFileUrlPrefix = `uf-api/organization/get_org_file_url/`;
/**
 * Action types for organization.get_org_file_url
 *
 * Return a signed URL for a file to upload.
 */
export const getOrgFileUrlActionTypes = {
  LOAD: `${getOrgFileUrlPrefix}LOAD` as 'uf-api/organization/get_org_file_url/LOAD',
  SUCCESS:
    `${getOrgFileUrlPrefix}SUCCESS` as 'uf-api/organization/get_org_file_url/SUCCESS',
  FAILURE:
    `${getOrgFileUrlPrefix}FAILURE` as 'uf-api/organization/get_org_file_url/FAILURE',
  CLEAR:
    `${getOrgFileUrlPrefix}CLEAR` as 'uf-api/organization/get_org_file_url/CLEAR',
} as const;

/**
 * LOAD action for organization.get_org_file_url
 * Return a signed URL for a file to upload.
 */
export interface getOrgFileUrlLoadAction {
  readonly type: 'uf-api/organization/get_org_file_url/LOAD';
  readonly params: OrganizationServiceInterface.getOrgFileUrlParams;
  readonly promise: Promise<ClientResponse<OrganizationFilesListItem>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for organization.get_org_file_url
 * Return a signed URL for a file to upload.
 */
export interface getOrgFileUrlSuccessAction {
  readonly type: 'uf-api/organization/get_org_file_url/SUCCESS';
  readonly result: OrganizationFilesListItem;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for organization.get_org_file_url
 * Return a signed URL for a file to upload.
 */
export interface getOrgFileUrlFailureAction {
  readonly type: 'uf-api/organization/get_org_file_url/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for organization.get_org_file_url
 * Return a signed URL for a file to upload.
 */
export interface getOrgFileUrlClearAction {
  readonly type: 'uf-api/organization/get_org_file_url/CLEAR';
  readonly key: string;
}

const getOrgFilesPrefix = `uf-api/organization/get_org_files/`;
/**
 * Action types for organization.get_org_files
 *
 * Returns the list of files uploaded for an org by current user.
 */
export const getOrgFilesActionTypes = {
  LOAD: `${getOrgFilesPrefix}LOAD` as 'uf-api/organization/get_org_files/LOAD',
  SUCCESS:
    `${getOrgFilesPrefix}SUCCESS` as 'uf-api/organization/get_org_files/SUCCESS',
  FAILURE:
    `${getOrgFilesPrefix}FAILURE` as 'uf-api/organization/get_org_files/FAILURE',
  CLEAR:
    `${getOrgFilesPrefix}CLEAR` as 'uf-api/organization/get_org_files/CLEAR',
} as const;

/**
 * LOAD action for organization.get_org_files
 * Returns the list of files uploaded for an org by current user.
 */
export interface getOrgFilesLoadAction {
  readonly type: 'uf-api/organization/get_org_files/LOAD';
  readonly params: OrganizationServiceInterface.getOrgFilesParams;
  readonly promise: Promise<ClientResponse<OrganizationFileList>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for organization.get_org_files
 * Returns the list of files uploaded for an org by current user.
 */
export interface getOrgFilesSuccessAction {
  readonly type: 'uf-api/organization/get_org_files/SUCCESS';
  readonly result: OrganizationFileList;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for organization.get_org_files
 * Returns the list of files uploaded for an org by current user.
 */
export interface getOrgFilesFailureAction {
  readonly type: 'uf-api/organization/get_org_files/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for organization.get_org_files
 * Returns the list of files uploaded for an org by current user.
 */
export interface getOrgFilesClearAction {
  readonly type: 'uf-api/organization/get_org_files/CLEAR';
  readonly key: string;
}

const getOrgLogoInfosPrefix = `uf-api/organization/get_org_logo_infos/`;
/**
 * Action types for organization.get_org_logo_infos
 *
 * Get metadata about available logos for an org.
 */
export const getOrgLogoInfosActionTypes = {
  LOAD: `${getOrgLogoInfosPrefix}LOAD` as 'uf-api/organization/get_org_logo_infos/LOAD',
  SUCCESS:
    `${getOrgLogoInfosPrefix}SUCCESS` as 'uf-api/organization/get_org_logo_infos/SUCCESS',
  FAILURE:
    `${getOrgLogoInfosPrefix}FAILURE` as 'uf-api/organization/get_org_logo_infos/FAILURE',
  CLEAR:
    `${getOrgLogoInfosPrefix}CLEAR` as 'uf-api/organization/get_org_logo_infos/CLEAR',
} as const;

/**
 * LOAD action for organization.get_org_logo_infos
 * Get metadata about available logos for an org.
 */
export interface getOrgLogoInfosLoadAction {
  readonly type: 'uf-api/organization/get_org_logo_infos/LOAD';
  readonly params: OrganizationServiceInterface.getOrgLogoInfosParams;
  readonly promise: Promise<ClientResponse<Array<OrganizationLogoInfo>>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for organization.get_org_logo_infos
 * Get metadata about available logos for an org.
 */
export interface getOrgLogoInfosSuccessAction {
  readonly type: 'uf-api/organization/get_org_logo_infos/SUCCESS';
  readonly result: Array<OrganizationLogoInfo>;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for organization.get_org_logo_infos
 * Get metadata about available logos for an org.
 */
export interface getOrgLogoInfosFailureAction {
  readonly type: 'uf-api/organization/get_org_logo_infos/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for organization.get_org_logo_infos
 * Get metadata about available logos for an org.
 */
export interface getOrgLogoInfosClearAction {
  readonly type: 'uf-api/organization/get_org_logo_infos/CLEAR';
  readonly key: string;
}

const getOrganizationPrefix = `uf-api/organization/get_organization/`;
/**
 * Action types for organization.get_organization
 *
 * Information about Organization that current user can access.
 */
export const getOrganizationActionTypes = {
  LOAD: `${getOrganizationPrefix}LOAD` as 'uf-api/organization/get_organization/LOAD',
  SUCCESS:
    `${getOrganizationPrefix}SUCCESS` as 'uf-api/organization/get_organization/SUCCESS',
  FAILURE:
    `${getOrganizationPrefix}FAILURE` as 'uf-api/organization/get_organization/FAILURE',
  CLEAR:
    `${getOrganizationPrefix}CLEAR` as 'uf-api/organization/get_organization/CLEAR',
} as const;

/**
 * LOAD action for organization.get_organization
 * Information about Organization that current user can access.
 */
export interface getOrganizationLoadAction {
  readonly type: 'uf-api/organization/get_organization/LOAD';
  readonly params: OrganizationServiceInterface.getOrganizationParams;
  readonly promise: Promise<ClientResponse<OrganizationInfo>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for organization.get_organization
 * Information about Organization that current user can access.
 */
export interface getOrganizationSuccessAction {
  readonly type: 'uf-api/organization/get_organization/SUCCESS';
  readonly result: OrganizationInfo;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for organization.get_organization
 * Information about Organization that current user can access.
 */
export interface getOrganizationFailureAction {
  readonly type: 'uf-api/organization/get_organization/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for organization.get_organization
 * Information about Organization that current user can access.
 */
export interface getOrganizationClearAction {
  readonly type: 'uf-api/organization/get_organization/CLEAR';
  readonly key: string;
}

const getOrganizationFeatureFlagsPrefix = `uf-api/organization/get_organization_feature_flags/`;
/**
 * Action types for organization.get_organization_feature_flags
 *
 * Return list of feature flags enabled for this organization.
 */
export const getOrganizationFeatureFlagsActionTypes = {
  LOAD: `${getOrganizationFeatureFlagsPrefix}LOAD` as 'uf-api/organization/get_organization_feature_flags/LOAD',
  SUCCESS:
    `${getOrganizationFeatureFlagsPrefix}SUCCESS` as 'uf-api/organization/get_organization_feature_flags/SUCCESS',
  FAILURE:
    `${getOrganizationFeatureFlagsPrefix}FAILURE` as 'uf-api/organization/get_organization_feature_flags/FAILURE',
  CLEAR:
    `${getOrganizationFeatureFlagsPrefix}CLEAR` as 'uf-api/organization/get_organization_feature_flags/CLEAR',
} as const;

/**
 * LOAD action for organization.get_organization_feature_flags
 * Return list of feature flags enabled for this organization.
 */
export interface getOrganizationFeatureFlagsLoadAction {
  readonly type: 'uf-api/organization/get_organization_feature_flags/LOAD';
  readonly params: OrganizationServiceInterface.getOrganizationFeatureFlagsParams;
  readonly promise: Promise<ClientResponse<FeatureFlags>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for organization.get_organization_feature_flags
 * Return list of feature flags enabled for this organization.
 */
export interface getOrganizationFeatureFlagsSuccessAction {
  readonly type: 'uf-api/organization/get_organization_feature_flags/SUCCESS';
  readonly result: FeatureFlags;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for organization.get_organization_feature_flags
 * Return list of feature flags enabled for this organization.
 */
export interface getOrganizationFeatureFlagsFailureAction {
  readonly type: 'uf-api/organization/get_organization_feature_flags/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for organization.get_organization_feature_flags
 * Return list of feature flags enabled for this organization.
 */
export interface getOrganizationFeatureFlagsClearAction {
  readonly type: 'uf-api/organization/get_organization_feature_flags/CLEAR';
  readonly key: string;
}

const getOrganizationInsightsPrefix = `uf-api/organization/get_organization_insights/`;
/**
 * Action types for organization.get_organization_insights
 *
 * Return a list of insights for the given organization.
 */
export const getOrganizationInsightsActionTypes = {
  LOAD: `${getOrganizationInsightsPrefix}LOAD` as 'uf-api/organization/get_organization_insights/LOAD',
  SUCCESS:
    `${getOrganizationInsightsPrefix}SUCCESS` as 'uf-api/organization/get_organization_insights/SUCCESS',
  FAILURE:
    `${getOrganizationInsightsPrefix}FAILURE` as 'uf-api/organization/get_organization_insights/FAILURE',
  CLEAR:
    `${getOrganizationInsightsPrefix}CLEAR` as 'uf-api/organization/get_organization_insights/CLEAR',
} as const;

/**
 * LOAD action for organization.get_organization_insights
 * Return a list of insights for the given organization.
 */
export interface getOrganizationInsightsLoadAction {
  readonly type: 'uf-api/organization/get_organization_insights/LOAD';
  readonly params: OrganizationServiceInterface.getOrganizationInsightsParams;
  readonly promise: Promise<ClientResponse<Array<InsightCategory>>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for organization.get_organization_insights
 * Return a list of insights for the given organization.
 */
export interface getOrganizationInsightsSuccessAction {
  readonly type: 'uf-api/organization/get_organization_insights/SUCCESS';
  readonly result: Array<InsightCategory>;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for organization.get_organization_insights
 * Return a list of insights for the given organization.
 */
export interface getOrganizationInsightsFailureAction {
  readonly type: 'uf-api/organization/get_organization_insights/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for organization.get_organization_insights
 * Return a list of insights for the given organization.
 */
export interface getOrganizationInsightsClearAction {
  readonly type: 'uf-api/organization/get_organization_insights/CLEAR';
  readonly key: string;
}

const getOrganizationsPrefix = `uf-api/organization/get_organizations/`;
/**
 * Action types for organization.get_organizations
 *
 * Get list of Organizations accessible to current user.
 */
export const getOrganizationsActionTypes = {
  LOAD: `${getOrganizationsPrefix}LOAD` as 'uf-api/organization/get_organizations/LOAD',
  SUCCESS:
    `${getOrganizationsPrefix}SUCCESS` as 'uf-api/organization/get_organizations/SUCCESS',
  FAILURE:
    `${getOrganizationsPrefix}FAILURE` as 'uf-api/organization/get_organizations/FAILURE',
  CLEAR:
    `${getOrganizationsPrefix}CLEAR` as 'uf-api/organization/get_organizations/CLEAR',
} as const;

/**
 * LOAD action for organization.get_organizations
 * Get list of Organizations accessible to current user.
 */
export interface getOrganizationsLoadAction {
  readonly type: 'uf-api/organization/get_organizations/LOAD';

  readonly promise: Promise<ClientResponse<OrganizationInfoList>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for organization.get_organizations
 * Get list of Organizations accessible to current user.
 */
export interface getOrganizationsSuccessAction {
  readonly type: 'uf-api/organization/get_organizations/SUCCESS';
  readonly result: OrganizationInfoList;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for organization.get_organizations
 * Get list of Organizations accessible to current user.
 */
export interface getOrganizationsFailureAction {
  readonly type: 'uf-api/organization/get_organizations/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for organization.get_organizations
 * Get list of Organizations accessible to current user.
 */
export interface getOrganizationsClearAction {
  readonly type: 'uf-api/organization/get_organizations/CLEAR';
  readonly key: string;
}

const getProjectsInOrganizationPrefix = `uf-api/organization/get_projects_in_organization/`;
/**
 * Action types for organization.get_projects_in_organization
 *
 * Return a list of projects for the given organization.
 */
export const getProjectsInOrganizationActionTypes = {
  LOAD: `${getProjectsInOrganizationPrefix}LOAD` as 'uf-api/organization/get_projects_in_organization/LOAD',
  SUCCESS:
    `${getProjectsInOrganizationPrefix}SUCCESS` as 'uf-api/organization/get_projects_in_organization/SUCCESS',
  FAILURE:
    `${getProjectsInOrganizationPrefix}FAILURE` as 'uf-api/organization/get_projects_in_organization/FAILURE',
  CLEAR:
    `${getProjectsInOrganizationPrefix}CLEAR` as 'uf-api/organization/get_projects_in_organization/CLEAR',
} as const;

/**
 * LOAD action for organization.get_projects_in_organization
 * Return a list of projects for the given organization.
 */
export interface getProjectsInOrganizationLoadAction {
  readonly type: 'uf-api/organization/get_projects_in_organization/LOAD';
  readonly params: OrganizationServiceInterface.getProjectsInOrganizationParams;
  readonly promise: Promise<ClientResponse<ProjectList>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for organization.get_projects_in_organization
 * Return a list of projects for the given organization.
 */
export interface getProjectsInOrganizationSuccessAction {
  readonly type: 'uf-api/organization/get_projects_in_organization/SUCCESS';
  readonly result: ProjectList;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for organization.get_projects_in_organization
 * Return a list of projects for the given organization.
 */
export interface getProjectsInOrganizationFailureAction {
  readonly type: 'uf-api/organization/get_projects_in_organization/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for organization.get_projects_in_organization
 * Return a list of projects for the given organization.
 */
export interface getProjectsInOrganizationClearAction {
  readonly type: 'uf-api/organization/get_projects_in_organization/CLEAR';
  readonly key: string;
}

const getSubscriptionPrefix = `uf-api/organization/get_subscription/`;
/**
 * Action types for organization.get_subscription
 *
 * Return the subscription info for an organization.
 */
export const getSubscriptionActionTypes = {
  LOAD: `${getSubscriptionPrefix}LOAD` as 'uf-api/organization/get_subscription/LOAD',
  SUCCESS:
    `${getSubscriptionPrefix}SUCCESS` as 'uf-api/organization/get_subscription/SUCCESS',
  FAILURE:
    `${getSubscriptionPrefix}FAILURE` as 'uf-api/organization/get_subscription/FAILURE',
  CLEAR:
    `${getSubscriptionPrefix}CLEAR` as 'uf-api/organization/get_subscription/CLEAR',
} as const;

/**
 * LOAD action for organization.get_subscription
 * Return the subscription info for an organization.
 */
export interface getSubscriptionLoadAction {
  readonly type: 'uf-api/organization/get_subscription/LOAD';
  readonly params: OrganizationServiceInterface.getSubscriptionParams;
  readonly promise: Promise<ClientResponse<Subscription>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for organization.get_subscription
 * Return the subscription info for an organization.
 */
export interface getSubscriptionSuccessAction {
  readonly type: 'uf-api/organization/get_subscription/SUCCESS';
  readonly result: Subscription;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for organization.get_subscription
 * Return the subscription info for an organization.
 */
export interface getSubscriptionFailureAction {
  readonly type: 'uf-api/organization/get_subscription/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for organization.get_subscription
 * Return the subscription info for an organization.
 */
export interface getSubscriptionClearAction {
  readonly type: 'uf-api/organization/get_subscription/CLEAR';
  readonly key: string;
}

const inviteUserToOrganizationPrefix = `uf-api/organization/invite_user_to_organization/`;
/**
 * Action types for organization.invite_user_to_organization
 *
 * Invite a new user to join an organization.
 */
export const inviteUserToOrganizationActionTypes = {
  LOAD: `${inviteUserToOrganizationPrefix}LOAD` as 'uf-api/organization/invite_user_to_organization/LOAD',
  SUCCESS:
    `${inviteUserToOrganizationPrefix}SUCCESS` as 'uf-api/organization/invite_user_to_organization/SUCCESS',
  FAILURE:
    `${inviteUserToOrganizationPrefix}FAILURE` as 'uf-api/organization/invite_user_to_organization/FAILURE',
  CLEAR:
    `${inviteUserToOrganizationPrefix}CLEAR` as 'uf-api/organization/invite_user_to_organization/CLEAR',
} as const;

/**
 * LOAD action for organization.invite_user_to_organization
 * Invite a new user to join an organization.
 */
export interface inviteUserToOrganizationLoadAction {
  readonly type: 'uf-api/organization/invite_user_to_organization/LOAD';
  readonly params: OrganizationServiceInterface.inviteUserToOrganizationParams;
  readonly promise: Promise<ClientResponse<{}>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for organization.invite_user_to_organization
 * Invite a new user to join an organization.
 */
export interface inviteUserToOrganizationSuccessAction {
  readonly type: 'uf-api/organization/invite_user_to_organization/SUCCESS';
  readonly result: {};
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for organization.invite_user_to_organization
 * Invite a new user to join an organization.
 */
export interface inviteUserToOrganizationFailureAction {
  readonly type: 'uf-api/organization/invite_user_to_organization/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for organization.invite_user_to_organization
 * Invite a new user to join an organization.
 */
export interface inviteUserToOrganizationClearAction {
  readonly type: 'uf-api/organization/invite_user_to_organization/CLEAR';
  readonly key: string;
}

const removeMemberFromOrganizationPrefix = `uf-api/organization/remove_member_from_organization/`;
/**
 * Action types for organization.remove_member_from_organization
 *
 * Remove a member from an organization.
 */
export const removeMemberFromOrganizationActionTypes = {
  LOAD: `${removeMemberFromOrganizationPrefix}LOAD` as 'uf-api/organization/remove_member_from_organization/LOAD',
  SUCCESS:
    `${removeMemberFromOrganizationPrefix}SUCCESS` as 'uf-api/organization/remove_member_from_organization/SUCCESS',
  FAILURE:
    `${removeMemberFromOrganizationPrefix}FAILURE` as 'uf-api/organization/remove_member_from_organization/FAILURE',
  CLEAR:
    `${removeMemberFromOrganizationPrefix}CLEAR` as 'uf-api/organization/remove_member_from_organization/CLEAR',
} as const;

/**
 * LOAD action for organization.remove_member_from_organization
 * Remove a member from an organization.
 */
export interface removeMemberFromOrganizationLoadAction {
  readonly type: 'uf-api/organization/remove_member_from_organization/LOAD';
  readonly params: OrganizationServiceInterface.removeMemberFromOrganizationParams;
  readonly promise: Promise<ClientResponse<{}>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for organization.remove_member_from_organization
 * Remove a member from an organization.
 */
export interface removeMemberFromOrganizationSuccessAction {
  readonly type: 'uf-api/organization/remove_member_from_organization/SUCCESS';
  readonly result: {};
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for organization.remove_member_from_organization
 * Remove a member from an organization.
 */
export interface removeMemberFromOrganizationFailureAction {
  readonly type: 'uf-api/organization/remove_member_from_organization/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for organization.remove_member_from_organization
 * Remove a member from an organization.
 */
export interface removeMemberFromOrganizationClearAction {
  readonly type: 'uf-api/organization/remove_member_from_organization/CLEAR';
  readonly key: string;
}

const requestSalesContactPrefix = `uf-api/organization/request_sales_contact/`;
/**
 * Action types for organization.request_sales_contact
 *
 * Request that the sales team contact the current user about upgrading this organization\&#39;s plan
 */
export const requestSalesContactActionTypes = {
  LOAD: `${requestSalesContactPrefix}LOAD` as 'uf-api/organization/request_sales_contact/LOAD',
  SUCCESS:
    `${requestSalesContactPrefix}SUCCESS` as 'uf-api/organization/request_sales_contact/SUCCESS',
  FAILURE:
    `${requestSalesContactPrefix}FAILURE` as 'uf-api/organization/request_sales_contact/FAILURE',
  CLEAR:
    `${requestSalesContactPrefix}CLEAR` as 'uf-api/organization/request_sales_contact/CLEAR',
} as const;

/**
 * LOAD action for organization.request_sales_contact
 * Request that the sales team contact the current user about upgrading this organization\&#39;s plan
 */
export interface requestSalesContactLoadAction {
  readonly type: 'uf-api/organization/request_sales_contact/LOAD';
  readonly params: OrganizationServiceInterface.requestSalesContactParams;
  readonly promise: Promise<ClientResponse<{}>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for organization.request_sales_contact
 * Request that the sales team contact the current user about upgrading this organization\&#39;s plan
 */
export interface requestSalesContactSuccessAction {
  readonly type: 'uf-api/organization/request_sales_contact/SUCCESS';
  readonly result: {};
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for organization.request_sales_contact
 * Request that the sales team contact the current user about upgrading this organization\&#39;s plan
 */
export interface requestSalesContactFailureAction {
  readonly type: 'uf-api/organization/request_sales_contact/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for organization.request_sales_contact
 * Request that the sales team contact the current user about upgrading this organization\&#39;s plan
 */
export interface requestSalesContactClearAction {
  readonly type: 'uf-api/organization/request_sales_contact/CLEAR';
  readonly key: string;
}

const updateBillingInfoPrefix = `uf-api/organization/update_billing_info/`;
/**
 * Action types for organization.update_billing_info
 *
 * Update Billing Information
 */
export const updateBillingInfoActionTypes = {
  LOAD: `${updateBillingInfoPrefix}LOAD` as 'uf-api/organization/update_billing_info/LOAD',
  SUCCESS:
    `${updateBillingInfoPrefix}SUCCESS` as 'uf-api/organization/update_billing_info/SUCCESS',
  FAILURE:
    `${updateBillingInfoPrefix}FAILURE` as 'uf-api/organization/update_billing_info/FAILURE',
  CLEAR:
    `${updateBillingInfoPrefix}CLEAR` as 'uf-api/organization/update_billing_info/CLEAR',
} as const;

/**
 * LOAD action for organization.update_billing_info
 * Update Billing Information
 */
export interface updateBillingInfoLoadAction {
  readonly type: 'uf-api/organization/update_billing_info/LOAD';
  readonly params: OrganizationServiceInterface.updateBillingInfoParams;
  readonly promise: Promise<ClientResponse<{}>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for organization.update_billing_info
 * Update Billing Information
 */
export interface updateBillingInfoSuccessAction {
  readonly type: 'uf-api/organization/update_billing_info/SUCCESS';
  readonly result: {};
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for organization.update_billing_info
 * Update Billing Information
 */
export interface updateBillingInfoFailureAction {
  readonly type: 'uf-api/organization/update_billing_info/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for organization.update_billing_info
 * Update Billing Information
 */
export interface updateBillingInfoClearAction {
  readonly type: 'uf-api/organization/update_billing_info/CLEAR';
  readonly key: string;
}

const updateOrganizationInsightsHelpUrlPrefix = `uf-api/organization/update_organization_insights_help_url/`;
/**
 * Action types for organization.update_organization_insights_help_url
 *
 * Change the insights help URL of an organization.
 */
export const updateOrganizationInsightsHelpUrlActionTypes = {
  LOAD: `${updateOrganizationInsightsHelpUrlPrefix}LOAD` as 'uf-api/organization/update_organization_insights_help_url/LOAD',
  SUCCESS:
    `${updateOrganizationInsightsHelpUrlPrefix}SUCCESS` as 'uf-api/organization/update_organization_insights_help_url/SUCCESS',
  FAILURE:
    `${updateOrganizationInsightsHelpUrlPrefix}FAILURE` as 'uf-api/organization/update_organization_insights_help_url/FAILURE',
  CLEAR:
    `${updateOrganizationInsightsHelpUrlPrefix}CLEAR` as 'uf-api/organization/update_organization_insights_help_url/CLEAR',
} as const;

/**
 * LOAD action for organization.update_organization_insights_help_url
 * Change the insights help URL of an organization.
 */
export interface updateOrganizationInsightsHelpUrlLoadAction {
  readonly type: 'uf-api/organization/update_organization_insights_help_url/LOAD';
  readonly params: OrganizationServiceInterface.updateOrganizationInsightsHelpUrlParams;
  readonly promise: Promise<ClientResponse<{}>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for organization.update_organization_insights_help_url
 * Change the insights help URL of an organization.
 */
export interface updateOrganizationInsightsHelpUrlSuccessAction {
  readonly type: 'uf-api/organization/update_organization_insights_help_url/SUCCESS';
  readonly result: {};
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for organization.update_organization_insights_help_url
 * Change the insights help URL of an organization.
 */
export interface updateOrganizationInsightsHelpUrlFailureAction {
  readonly type: 'uf-api/organization/update_organization_insights_help_url/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for organization.update_organization_insights_help_url
 * Change the insights help URL of an organization.
 */
export interface updateOrganizationInsightsHelpUrlClearAction {
  readonly type: 'uf-api/organization/update_organization_insights_help_url/CLEAR';
  readonly key: string;
}

const updateOrganizationNamePrefix = `uf-api/organization/update_organization_name/`;
/**
 * Action types for organization.update_organization_name
 *
 * Change the name of an organization.
 */
export const updateOrganizationNameActionTypes = {
  LOAD: `${updateOrganizationNamePrefix}LOAD` as 'uf-api/organization/update_organization_name/LOAD',
  SUCCESS:
    `${updateOrganizationNamePrefix}SUCCESS` as 'uf-api/organization/update_organization_name/SUCCESS',
  FAILURE:
    `${updateOrganizationNamePrefix}FAILURE` as 'uf-api/organization/update_organization_name/FAILURE',
  CLEAR:
    `${updateOrganizationNamePrefix}CLEAR` as 'uf-api/organization/update_organization_name/CLEAR',
} as const;

/**
 * LOAD action for organization.update_organization_name
 * Change the name of an organization.
 */
export interface updateOrganizationNameLoadAction {
  readonly type: 'uf-api/organization/update_organization_name/LOAD';
  readonly params: OrganizationServiceInterface.updateOrganizationNameParams;
  readonly promise: Promise<ClientResponse<{}>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for organization.update_organization_name
 * Change the name of an organization.
 */
export interface updateOrganizationNameSuccessAction {
  readonly type: 'uf-api/organization/update_organization_name/SUCCESS';
  readonly result: {};
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for organization.update_organization_name
 * Change the name of an organization.
 */
export interface updateOrganizationNameFailureAction {
  readonly type: 'uf-api/organization/update_organization_name/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for organization.update_organization_name
 * Change the name of an organization.
 */
export interface updateOrganizationNameClearAction {
  readonly type: 'uf-api/organization/update_organization_name/CLEAR';
  readonly key: string;
}

const updateSubscriptionSeatsPrefix = `uf-api/organization/update_subscription_seats/`;
/**
 * Action types for organization.update_subscription_seats
 *
 * Update the number of seats in an organization\&#39;s subscription.
 */
export const updateSubscriptionSeatsActionTypes = {
  LOAD: `${updateSubscriptionSeatsPrefix}LOAD` as 'uf-api/organization/update_subscription_seats/LOAD',
  SUCCESS:
    `${updateSubscriptionSeatsPrefix}SUCCESS` as 'uf-api/organization/update_subscription_seats/SUCCESS',
  FAILURE:
    `${updateSubscriptionSeatsPrefix}FAILURE` as 'uf-api/organization/update_subscription_seats/FAILURE',
  CLEAR:
    `${updateSubscriptionSeatsPrefix}CLEAR` as 'uf-api/organization/update_subscription_seats/CLEAR',
} as const;

/**
 * LOAD action for organization.update_subscription_seats
 * Update the number of seats in an organization\&#39;s subscription.
 */
export interface updateSubscriptionSeatsLoadAction {
  readonly type: 'uf-api/organization/update_subscription_seats/LOAD';
  readonly params: OrganizationServiceInterface.updateSubscriptionSeatsParams;
  readonly promise: Promise<ClientResponse<Subscription>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for organization.update_subscription_seats
 * Update the number of seats in an organization\&#39;s subscription.
 */
export interface updateSubscriptionSeatsSuccessAction {
  readonly type: 'uf-api/organization/update_subscription_seats/SUCCESS';
  readonly result: Subscription;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for organization.update_subscription_seats
 * Update the number of seats in an organization\&#39;s subscription.
 */
export interface updateSubscriptionSeatsFailureAction {
  readonly type: 'uf-api/organization/update_subscription_seats/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for organization.update_subscription_seats
 * Update the number of seats in an organization\&#39;s subscription.
 */
export interface updateSubscriptionSeatsClearAction {
  readonly type: 'uf-api/organization/update_subscription_seats/CLEAR';
  readonly key: string;
}

export const organizationActionTypesMap = {
  /** Action types for organization.add_member_to_organization */
  add_member_to_organization: addMemberToOrganizationActionTypes,
  /** Action types for organization.add_org_logo */
  add_org_logo: addOrgLogoActionTypes,
  /** Action types for organization.cancel_invitation */
  cancel_invitation: cancelInvitationActionTypes,
  /** Action types for organization.change_subscription_plan */
  change_subscription_plan: changeSubscriptionPlanActionTypes,
  /** Action types for organization.create_organization */
  create_organization: createOrganizationActionTypes,
  /** Action types for organization.delete_org_logo */
  delete_org_logo: deleteOrgLogoActionTypes,
  /** Action types for organization.delete_organization */
  delete_organization: deleteOrganizationActionTypes,
  /** Action types for organization.edit_member_in_organization */
  edit_member_in_organization: editMemberInOrganizationActionTypes,
  /** Action types for organization.get_billing_info */
  get_billing_info: getBillingInfoActionTypes,
  /** Action types for organization.get_filtered_member_count_in_organization */
  get_filtered_member_count_in_organization:
    getFilteredMemberCountInOrganizationActionTypes,
  /** Action types for organization.get_invitations_for_organization */
  get_invitations_for_organization: getInvitationsForOrganizationActionTypes,
  /** Action types for organization.get_members_info_in_organization */
  get_members_info_in_organization: getMembersInfoInOrganizationActionTypes,
  /** Action types for organization.get_org_file_url */
  get_org_file_url: getOrgFileUrlActionTypes,
  /** Action types for organization.get_org_files */
  get_org_files: getOrgFilesActionTypes,
  /** Action types for organization.get_org_logo_infos */
  get_org_logo_infos: getOrgLogoInfosActionTypes,
  /** Action types for organization.get_organization */
  get_organization: getOrganizationActionTypes,
  /** Action types for organization.get_organization_feature_flags */
  get_organization_feature_flags: getOrganizationFeatureFlagsActionTypes,
  /** Action types for organization.get_organization_insights */
  get_organization_insights: getOrganizationInsightsActionTypes,
  /** Action types for organization.get_organizations */
  get_organizations: getOrganizationsActionTypes,
  /** Action types for organization.get_projects_in_organization */
  get_projects_in_organization: getProjectsInOrganizationActionTypes,
  /** Action types for organization.get_subscription */
  get_subscription: getSubscriptionActionTypes,
  /** Action types for organization.invite_user_to_organization */
  invite_user_to_organization: inviteUserToOrganizationActionTypes,
  /** Action types for organization.remove_member_from_organization */
  remove_member_from_organization: removeMemberFromOrganizationActionTypes,
  /** Action types for organization.request_sales_contact */
  request_sales_contact: requestSalesContactActionTypes,
  /** Action types for organization.update_billing_info */
  update_billing_info: updateBillingInfoActionTypes,
  /** Action types for organization.update_organization_insights_help_url */
  update_organization_insights_help_url:
    updateOrganizationInsightsHelpUrlActionTypes,
  /** Action types for organization.update_organization_name */
  update_organization_name: updateOrganizationNameActionTypes,
  /** Action types for organization.update_subscription_seats */
  update_subscription_seats: updateSubscriptionSeatsActionTypes,
} as const;

export const organizationDetailsMap = {
  add_member_to_organization: {
    /** HTTP method for organization.add_member_to_organization */
    httpMethod: 'post' as const,
  },
  add_org_logo: {
    /** HTTP method for organization.add_org_logo */
    httpMethod: 'post' as const,
  },
  cancel_invitation: {
    /** HTTP method for organization.cancel_invitation */
    httpMethod: 'delete' as const,
  },
  change_subscription_plan: {
    /** HTTP method for organization.change_subscription_plan */
    httpMethod: 'put' as const,
  },
  create_organization: {
    /** HTTP method for organization.create_organization */
    httpMethod: 'post' as const,
  },
  delete_org_logo: {
    /** HTTP method for organization.delete_org_logo */
    httpMethod: 'delete' as const,
  },
  delete_organization: {
    /** HTTP method for organization.delete_organization */
    httpMethod: 'delete' as const,
  },
  edit_member_in_organization: {
    /** HTTP method for organization.edit_member_in_organization */
    httpMethod: 'put' as const,
  },
  get_billing_info: {
    /** HTTP method for organization.get_billing_info */
    httpMethod: 'get' as const,
  },
  get_filtered_member_count_in_organization: {
    /** HTTP method for organization.get_filtered_member_count_in_organization */
    httpMethod: 'get' as const,
  },
  get_invitations_for_organization: {
    /** HTTP method for organization.get_invitations_for_organization */
    httpMethod: 'get' as const,
  },
  get_members_info_in_organization: {
    /** HTTP method for organization.get_members_info_in_organization */
    httpMethod: 'get' as const,
  },
  get_org_file_url: {
    /** HTTP method for organization.get_org_file_url */
    httpMethod: 'get' as const,
  },
  get_org_files: {
    /** HTTP method for organization.get_org_files */
    httpMethod: 'get' as const,
  },
  get_org_logo_infos: {
    /** HTTP method for organization.get_org_logo_infos */
    httpMethod: 'get' as const,
  },
  get_organization: {
    /** HTTP method for organization.get_organization */
    httpMethod: 'get' as const,
  },
  get_organization_feature_flags: {
    /** HTTP method for organization.get_organization_feature_flags */
    httpMethod: 'get' as const,
  },
  get_organization_insights: {
    /** HTTP method for organization.get_organization_insights */
    httpMethod: 'get' as const,
  },
  get_organizations: {
    /** HTTP method for organization.get_organizations */
    httpMethod: 'get' as const,
  },
  get_projects_in_organization: {
    /** HTTP method for organization.get_projects_in_organization */
    httpMethod: 'get' as const,
  },
  get_subscription: {
    /** HTTP method for organization.get_subscription */
    httpMethod: 'get' as const,
  },
  invite_user_to_organization: {
    /** HTTP method for organization.invite_user_to_organization */
    httpMethod: 'post' as const,
  },
  remove_member_from_organization: {
    /** HTTP method for organization.remove_member_from_organization */
    httpMethod: 'delete' as const,
  },
  request_sales_contact: {
    /** HTTP method for organization.request_sales_contact */
    httpMethod: 'post' as const,
  },
  update_billing_info: {
    /** HTTP method for organization.update_billing_info */
    httpMethod: 'post' as const,
  },
  update_organization_insights_help_url: {
    /** HTTP method for organization.update_organization_insights_help_url */
    httpMethod: 'put' as const,
  },
  update_organization_name: {
    /** HTTP method for organization.update_organization_name */
    httpMethod: 'put' as const,
  },
  update_subscription_seats: {
    /** HTTP method for organization.update_subscription_seats */
    httpMethod: 'put' as const,
  },
} as const;

/**
 * Local DataState<T> - kept in sync with uf/data/dataState.ts
 */
interface DataState<T> {
  readonly loading: boolean;
  readonly loaded: boolean;
  readonly requestId: string;
  readonly requestTime?: string;
  readonly responseTime?: string;
  readonly requestCount?: number;
  readonly error?: any;
  readonly promise?: Promise<T>;
  readonly data?: T;
  readonly extra?: any;
  readonly key?: string;
}
export interface OrganizationServiceState {
  /** all recent addMemberToOrganization results (POST/PUT/PATCH) */
  readonly add_member_to_organization: DataState<{}>[];
  /** all recent addOrgLogo results (POST/PUT/PATCH) */
  readonly add_org_logo: DataState<OrganizationLogoInfo>[];
  /** cancelInvitation state, by key */
  cancel_invitation: {
    readonly [key: string]: DataState<{}>;
  };
  /** all recent changeSubscriptionPlan results (POST/PUT/PATCH) */
  readonly change_subscription_plan: DataState<Subscription>[];
  /** all recent createOrganization results (POST/PUT/PATCH) */
  readonly create_organization: DataState<OrganizationInfo>[];
  /** deleteOrgLogo state, by key */
  delete_org_logo: {
    readonly [key: string]: DataState<{}>;
  };
  /** deleteOrganization state, by key */
  delete_organization: {
    readonly [key: string]: DataState<{}>;
  };
  /** all recent editMemberInOrganization results (POST/PUT/PATCH) */
  readonly edit_member_in_organization: DataState<{}>[];
  /** getBillingInfo state, by key */
  get_billing_info: {
    readonly [key: string]: DataState<BillingInfo>;
  };
  /** getFilteredMemberCountInOrganization state, by key */
  get_filtered_member_count_in_organization: {
    readonly [key: string]: DataState<MemberCount>;
  };
  /** getInvitationsForOrganization state, by key */
  get_invitations_for_organization: {
    readonly [key: string]: DataState<InvitationList>;
  };
  /** getMembersInfoInOrganization state, by key */
  get_members_info_in_organization: {
    readonly [key: string]: DataState<MemberList>;
  };
  /** getOrgFileUrl state, by key */
  get_org_file_url: {
    readonly [key: string]: DataState<OrganizationFilesListItem>;
  };
  /** getOrgFiles state, by key */
  get_org_files: {
    readonly [key: string]: DataState<OrganizationFileList>;
  };
  /** getOrgLogoInfos state, by key */
  get_org_logo_infos: {
    readonly [key: string]: DataState<Array<OrganizationLogoInfo>>;
  };
  /** getOrganization state, by key */
  get_organization: {
    readonly [key: string]: DataState<OrganizationInfo>;
  };
  /** getOrganizationFeatureFlags state, by key */
  get_organization_feature_flags: {
    readonly [key: string]: DataState<FeatureFlags>;
  };
  /** getOrganizationInsights state, by key */
  get_organization_insights: {
    readonly [key: string]: DataState<Array<InsightCategory>>;
  };
  /** getOrganizations state, by key */
  get_organizations: {
    readonly [key: string]: DataState<OrganizationInfoList>;
  };
  /** getProjectsInOrganization state, by key */
  get_projects_in_organization: {
    readonly [key: string]: DataState<ProjectList>;
  };
  /** getSubscription state, by key */
  get_subscription: {
    readonly [key: string]: DataState<Subscription>;
  };
  /** all recent inviteUserToOrganization results (POST/PUT/PATCH) */
  readonly invite_user_to_organization: DataState<{}>[];
  /** removeMemberFromOrganization state, by key */
  remove_member_from_organization: {
    readonly [key: string]: DataState<{}>;
  };
  /** all recent requestSalesContact results (POST/PUT/PATCH) */
  readonly request_sales_contact: DataState<{}>[];
  /** all recent updateBillingInfo results (POST/PUT/PATCH) */
  readonly update_billing_info: DataState<{}>[];
  /** all recent updateOrganizationInsightsHelpUrl results (POST/PUT/PATCH) */
  readonly update_organization_insights_help_url: DataState<{}>[];
  /** all recent updateOrganizationName results (POST/PUT/PATCH) */
  readonly update_organization_name: DataState<{}>[];
  /** all recent updateSubscriptionSeats results (POST/PUT/PATCH) */
  readonly update_subscription_seats: DataState<Subscription>[];
}
