import { GeoJsonGeometryTypes } from 'geojson';
import * as _ from 'lodash';

import { LayerColumnBreaks } from 'uf-api';
import { LayerServiceInterface } from 'uf-api/api/layer.serviceInterface';
import { isNullCategory } from 'uf/layers/nullStats';
import {
  CUSTOM_THEME,
  DataDrivenColorStop,
  DistributionQueryStringCrosswalk,
  LayerColumnSymbology,
  PaintColorTypes,
  PaintProperty,
} from 'uf/symbology';
import { getCanonicalSymbology } from 'uf/symbology/helpers';
import {
  makePaintProperty,
  makePaintPropertyKey,
} from 'uf/symbology/spec/paint';
import { makeNumericPaintColorProperty } from 'uf/symbology/styles/dynamic/createNumericSymbology';

// TODO: clean up this type
export interface NewProperties {
  column_breaks: LayerColumnBreaks[];
  params: LayerServiceInterface.getLayerBreaksParams;
}

// TODO: Generalize this to update distribution for any data-driven properties, not just paintColor
export default function updateDistributionAndStops(
  symbologies: LayerColumnSymbology[],
  newProperties: NewProperties,
  ufGeometryType: GeoJsonGeometryTypes,
): LayerColumnSymbology[] {
  // If we received an empty list of symbologies, bail here
  if (!symbologies.length) {
    return symbologies;
  }

  // This is safe because we don't generate more than 1 type of symbology per layer
  const symbology = getCanonicalSymbology(symbologies, ufGeometryType);

  const paintColorProperty = makePaintPropertyKey(symbology, 'color');

  const {
    display_hints: { theme, is_reverse_theme: isReverseTheme },
  } = symbology;

  const { stops, property: columnKey } = symbology.paint[paintColorProperty];

  const {
    params: { break_type: distributionValue },
    column_breaks: columnBreaks,
  } = newProperties;

  // Changing the distribution requires a known distribution and theme properties,
  // ie: not custom or categorical (which is represented as undefined distribution)
  const hasValidDistribution =
    distributionValue in DistributionQueryStringCrosswalk;
  if (!hasValidDistribution) {
    return symbologies;
  }

  // Nothing to change if there are no stops
  const hasValidStops = stops && stops.length > 0;
  if (!hasValidStops) {
    return symbologies;
  }

  const distribution = DistributionQueryStringCrosswalk[distributionValue];

  const breaks = columnBreaks.find(b => b.column_key === columnKey);
  const domain = _.uniq(breaks.breaks);

  let paintColor:
    | 'transparent'
    | PaintProperty<DataDrivenColorStop, 'transparent'>;

  // If the user has custom colors, we'll preserve them and just update the stop
  // values.
  const nullStop = stops.find(stop => isNullCategory(stop.value));
  const nonNullStops = stops.filter(stop => stop !== nullStop);

  if (theme === CUSTOM_THEME) {
    // Take the domain and map it to the existing color stops
    const newNonNullStops: DataDrivenColorStop[] = _.zip(
      nonNullStops as DataDrivenColorStop[],
      domain,
    )
      .filter(([, newDomainValue]) => _.isNumber(newDomainValue))
      .map(([stop, newDomainValue]) => {
        return {
          ...stop,
          // Use transparent when there are more values than colors in the custom theme
          color: stop?.color ? stop.color : 'transparent',
          value: newDomainValue,
        };
      });

    const newStops: DataDrivenColorStop[] = nullStop
      ? [nullStop, ...newNonNullStops]
      : newNonNullStops;

    paintColor = makePaintProperty(
      newStops,
      'transparent',
      columnKey,
      PaintColorTypes.INTERVAL,
    );
  } else {
    // Take the domain and map it to new color stops
    paintColor = makeNumericPaintColorProperty(domain, columnKey, theme, {
      isReverseTheme,
      includeNull: !!nullStop,
      nullStop,
    });
  }

  const newSymbology: LayerColumnSymbology = {
    ...symbology,
    paint: {
      ...symbology.paint,
      [paintColorProperty]: paintColor,
    },
    display_hints: {
      ...symbology.display_hints,
      distribution,
    },
  };

  if (['MultiPolygon', 'Polygon'].includes(ufGeometryType)) {
    const lineSymbology = symbologies.find(({ type }) => type === 'line');
    return [newSymbology, lineSymbology];
  }

  return [newSymbology];
}
