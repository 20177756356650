import {
  Button,
  Position,
  Spinner,
  SpinnerSize,
  Tooltip,
} from '@blueprintjs/core';
import { mdiCheck, mdiPencil } from '@mdi/js';
import Icon from '@mdi/react';
import { boundMethod } from 'autobind-decorator';
import classNames from 'classnames';
import React, { Component } from 'react';
import { Box } from 'react-layout-components';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import Link from 'redux-first-router-link';
import { ThunkActionMapDispatch } from 'redux-thunk';
import { t } from 'ttag';

import { AnalysisModuleInfo } from 'uf-api';
import { ModuleRunStatus } from 'uf/analysis';
import * as analysisActionCreators from 'uf/analysis/actions/analysis';
import {
  getIsResultStale,
  getModuleIsRunning,
  getModuleRunStatus,
} from 'uf/analysis/helpers';
import {
  getActiveProjectAnalysisModules,
  getAnalysisStatus,
  makeGetScenarioAnalysisResultsForModuleKey,
} from 'uf/analysis/selectors/analysis';
import { linkToAnalysisEditorModule } from 'uf/analysiseditor/routing/link';
import { assertNever } from 'uf/base/never';
import { ProjectId } from 'uf/projects';
import { ScenarioId } from 'uf/scenarios';
import colorStyles from 'uf/styles/colors.module.css';
import iconStyles from 'uf/styles/icons.module.css';
import rootStyles from 'uf/styles/root.module.css';
import AnalysisToolSwatch from 'uf/ui/analysis/AnalysisToolSwatch/AnalysisToolSwatch';
import moduleStyles, { AnalysisStyle } from 'uf/ui/analysis/moduleStyles';
import CanPerform from 'uf/ui/base/CanPerform/CanPerform';
import Clickable from 'uf/ui/base/Clickable/Clickable';
import { IconSizeNew } from 'uf/ui/base/icon';
import makeDataAttributes from 'uf/ui/base/makeDataAttributes';

import AnalysisKebab from './AnalysisKebab';
import styles from './AnalysisModuleListItem.module.css';
import AnalysisRunIcon from './AnalysisRunIcon';
import AnalysisStatusMessage from './AnalysisStatusMessage';

interface OwnProps {
  moduleKey: string;
  /**
   * Provide a module info to override whatever may have been provided from
   * redux.
   */
  module?: AnalysisModuleInfo;
  projectId: ProjectId;
  scenarioId?: ScenarioId;
  isBaseScenario?: boolean;

  /**
   * project level status message.  can also be used to provide a custom message.
   */
  statusMessage?: string;

  /**
   *  project level results - true if any scenario has analysis results
   */
  hasResults?: boolean;

  /**
   *  project level - true if any scenario is running the module
   */
  moduleIsRunning?: boolean;
  /**
   *  project level - true if the module has a run status of 'DONE' for any scenario
   */
  moduleIsDone?: boolean;
  onActivateModule?: (moduleKey: string) => void;
  className?: string;

  /**
   * Setting this to true will show focus styling similar to the LayerList items.
   */
  active?: boolean;

  /**
   * Setting this to true will prevent the Run and Kebab buttons from rendering on hover.
   */
  hideHoverControls?: boolean;

  /**
   * mode determines the level info shown.  If 'project', statuses will reflect results of all
   * scenarios for the supplied projectId.  If 'scenario', statuses only reflect results for the
   * supplied scenarioId
   */
  mode?: 'project' | 'scenario';
}

interface StateProps {
  module: AnalysisModuleInfo;
  /**
   * if the analysis module has results for the provided scenarioId
   */
  hasResultsForScenario: boolean;

  /**
   * if the analysis module is running for the provided scenarioId
   */
  moduleIsRunningForScenario: boolean;

  /**
   * if the analysis module is running for the provided scenarioId
   */
  analysisStaleForScenario: boolean;

  /**
   * the analysis module's run status is running for the provided scenarioId
   */
  runStatusForScenario: ModuleRunStatus;
}

interface DispatchProps {
  analysisActions: ThunkActionMapDispatch<typeof analysisActionCreators>;
}

type AnalysisModuleListItemProps = OwnProps & StateProps & DispatchProps;

interface AnalysisModuleListItemState {
  menuOpen: boolean;
}

export class AnalysisModuleListItem extends Component<
  AnalysisModuleListItemProps,
  AnalysisModuleListItemState
> {
  static defaultProps = {
    mode: 'scenario' as OwnProps['mode'],
    isBaseScenario: false,
  };

  state = {
    menuOpen: false,
  };

  @boundMethod
  onClickModule() {
    const { moduleKey, onActivateModule } = this.props;
    if (onActivateModule && moduleKey) {
      onActivateModule(moduleKey);
    }
  }

  @boundMethod
  onRunForScenario(moduleKey: string) {
    const { projectId, scenarioId, analysisActions } = this.props;
    if (projectId) {
      analysisActions.runAnalysis(moduleKey, projectId, scenarioId);
    }
  }

  @boundMethod
  onMenuOpen() {
    this.setState({ menuOpen: true });
  }

  @boundMethod
  onMenuClose() {
    this.setState({ menuOpen: false });
  }

  renderAnalysisRunButton() {
    const {
      moduleKey,
      projectId,
      isBaseScenario,
      hasResultsForScenario,
      moduleIsRunningForScenario,
      analysisStaleForScenario,
      module,
      runStatusForScenario,
    } = this.props;

    const { menuOpen } = this.state;
    // If the project hasn't loaded yet, there can be no modules.
    if (!module) {
      return null;
    }

    // check run status in case this is the first time we run the module so we don't have to
    // wait for the project to get the results.
    const analysisDoneForScenario =
      hasResultsForScenario || runStatusForScenario === 'ANALYSIS_DONE';
    const analysisUpToDateForScenario =
      analysisDoneForScenario && !analysisStaleForScenario;

    const showSpinner = moduleIsRunningForScenario;

    const analysisRunIconClassName = classNames({
      [styles.showOnHover]: !showSpinner,
      [rootStyles.displayNone]: showSpinner,
      [styles.show]: menuOpen && !showSpinner,
    });

    if (isBaseScenario) {
      return (
        <AnalysisRunIcon
          className={analysisRunIconClassName}
          moduleKey={moduleKey}
          analysisUpToDate={analysisUpToDateForScenario}
          isBaseScenario={isBaseScenario}
          onRun={this.onRunForScenario}
        />
      );
    }

    return (
      <CanPerform verb="analysis.run" resources={projectId}>
        {({ disabled }) => (
          <AnalysisRunIcon
            invalidSubscription={disabled}
            className={analysisRunIconClassName}
            moduleKey={moduleKey}
            analysisUpToDate={analysisUpToDateForScenario}
            isBaseScenario={isBaseScenario}
            onRun={this.onRunForScenario}
          />
        )}
      </CanPerform>
    );
  }

  getSwatchStale() {
    const {
      mode,
      hasResults,
      moduleIsRunning,
      moduleIsDone,
      hasResultsForScenario,
      moduleIsRunningForScenario,
      runStatusForScenario,
    } = this.props;

    const analysisDoneForScenario =
      hasResultsForScenario || runStatusForScenario === 'ANALYSIS_DONE';

    switch (mode) {
      case 'project':
        return !(hasResults || moduleIsRunning || moduleIsDone);
      case 'scenario':
        return !(
          hasResultsForScenario ||
          moduleIsRunningForScenario ||
          analysisDoneForScenario
        );
      default:
        assertNever(mode, 'invalid mode supplied to AnalysisModuleListItem');
    }
  }

  render() {
    const {
      moduleKey,
      active,
      projectId,
      isBaseScenario,
      hasResultsForScenario,
      moduleIsRunningForScenario,
      statusMessage,
      module,
      runStatusForScenario,
      hideHoverControls,
      analysisStaleForScenario,
    } = this.props;

    const { menuOpen } = this.state;
    const showKebab = menuOpen;

    // If the project hasn't loaded yet, there can be no modules.
    if (!module) {
      return null;
    }

    // this is temporary until the backend returns display hints for the modules
    // also, we shouldn't be using name but there currently isn't a key to use
    const displayHints = moduleStyles[moduleKey] || ({} as AnalysisStyle);

    // check run status in case this is the first time we run the module so we don't have to
    // wait for the project to get the results.
    const analysisDoneForScenario =
      hasResultsForScenario || runStatusForScenario === 'ANALYSIS_DONE';
    const analysisUpToDate =
      analysisDoneForScenario && !analysisStaleForScenario;

    const staleSwatch = this.getSwatchStale();

    const showSpinner = moduleIsRunningForScenario;
    const showCheckMark = analysisUpToDate && !moduleIsRunningForScenario;

    /** Item classes */
    const listItemClassName = classNames(
      styles.item,
      rootStyles.veryThinPaddingRight,
      {
        [styles.active]: active,
      },
    );

    const buttonClassName = classNames(
      rootStyles.flexBox,
      rootStyles.flex,
      rootStyles.fitHeight,
      rootStyles.smallPaddingTop,
      rootStyles.smallPaddingBottom,
      'AnalysisModuleListItem',
    );

    /** Toolbar classes */
    const listItemToolbarClassName = classNames(styles.listItemToolbar, {
      [styles.show]: showCheckMark || showSpinner || showKebab,
    });

    const checkMarkClassName = classNames(
      colorStyles.primary,
      iconStyles.size18px,
      styles.hideOnHover,
      'AnalysisItem-has-run',
    );

    const kebabClassName = classNames(
      rootStyles.thinMarginHorizontal,
      styles.showOnHover,
      styles.placeholder,
      {
        [styles.show]: showKebab,
      },
    );

    return (
      <Box
        flexShrink={0}
        justifyContent="space-between"
        className={listItemClassName}>
        <Clickable
          className={buttonClassName}
          onClick={this.onClickModule}
          {...makeDataAttributes({ moduleKey })}>
          <Box
            justifyContent="space-between"
            alignItems="center"
            className={rootStyles.smallMarginLeft}>
            <AnalysisToolSwatch
              className={rootStyles.thinMarginRight}
              label={module.name}
              stale={staleSwatch}
              iconPath={displayHints.iconPath}
            />

            <Box
              className={styles.text}
              column
              alignItems="flex-start"
              justifyContent="space-between">
              <div className="h4">{module.name}</div>

              <CanPerform verb="analysis.run" resources={projectId}>
                {({ disabled }) => (
                  <AnalysisStatusMessage
                    statusMessage={statusMessage}
                    invalidSubscription={disabled}
                    analysisStale={analysisStaleForScenario}
                    analysisDone={analysisDoneForScenario}
                    runStatus={runStatusForScenario}
                    isBaseScenario={isBaseScenario}
                  />
                )}
              </CanPerform>
            </Box>
          </Box>
        </Clickable>

        {!hideHoverControls && (
          <Box alignItems="center" className={listItemToolbarClassName}>
            <Tooltip
              position={Position.TOP}
              content={t`Edit analysis parameters`}>
              <Link to={linkToAnalysisEditorModule(projectId, moduleKey)}>
                <Button
                  minimal
                  small
                  className={styles.showOnHover}
                  icon={<Icon path={mdiPencil} size={IconSizeNew.SMALL} />}
                />
              </Link>
            </Tooltip>
            {this.renderAnalysisRunButton()}
            {showSpinner && (
              <Tooltip content={t`Analysis is running...`}>
                <Spinner size={SpinnerSize.SMALL} />
              </Tooltip>
            )}
            {showCheckMark && (
              <Icon
                path={mdiCheck}
                size={IconSizeNew.SMALL}
                className={checkMarkClassName}
              />
            )}
            <AnalysisKebab
              onOpen={this.onMenuOpen}
              onClose={this.onMenuClose}
              className={kebabClassName}
              moduleKey={moduleKey}
            />
          </Box>
        )}
      </Box>
    );
  }
}

function makeMapStateToProps() {
  const getScenarioAnalysisResults =
    makeGetScenarioAnalysisResultsForModuleKey();

  return (
    state,
    { moduleKey, projectId, scenarioId, module: providedModule },
  ): StateProps => {
    const results = getScenarioAnalysisResults(state, {
      moduleKey,
      scenarioId,
      projectId,
    });
    const analysisStaleForScenario = getIsResultStale(results);
    const modules = getActiveProjectAnalysisModules(state);
    const module = providedModule
      ? providedModule
      : modules.find(m => m.module_key === moduleKey);
    const moduleStatuses = getAnalysisStatus(state);
    const runStatusForScenario = getModuleRunStatus(
      moduleStatuses,
      moduleKey,
      projectId,
      scenarioId,
    );
    const moduleIsRunningForScenario = getModuleIsRunning(runStatusForScenario);

    return {
      hasResultsForScenario: !!results,
      moduleIsRunningForScenario,
      analysisStaleForScenario,
      runStatusForScenario,
      module,
    };
  };
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
  return {
    analysisActions: bindActionCreators(analysisActionCreators, dispatch),
  };
}

export default connect<StateProps, DispatchProps, OwnProps>(
  makeMapStateToProps,
  mapDispatchToProps,
)(AnalysisModuleListItem);
