/**
 * Reducer for analysis module run status.
 */
import { runAnalysisModuleForProjectActionTypes } from 'uf-api/api/project.service';
import { ModuleRunStatus } from 'uf/analysis';
import { SET_ANALYSIS_STATUS } from 'uf/analysis/ActionTypes';

const initialState = {};

// Status is stored as:
// {
//   [moduleKey]: {
//     [projectId]: {
//       [scenarioId]: 'PENDING',
//     },
//   },
// }
//
export default function status(state = initialState, action) {
  switch (action.type) {
    case runAnalysisModuleForProjectActionTypes.LOAD: {
      const moduleState = state[action.moduleKey] || {};
      const projectState = moduleState[action.projectId] || {};
      // If no scenario is specified, we assume this applies to all scenarios.
      // Without a scenario, we blow away the entire state for the project, because
      // we don't know the full list of scenarios at this point.
      const newScenarioState = action.scenarioId
        ? { [action.scenarioId]: ModuleRunStatus.PENDING }
        : null;
      return {
        ...state,
        [action.moduleKey]: {
          ...moduleState,
          [action.projectId]: {
            ...projectState,
            ...newScenarioState,
          },
        },
      };
    }

    case runAnalysisModuleForProjectActionTypes.FAILURE: {
      const moduleState = state[action.moduleKey] || {};
      const projectState = moduleState[action.projectId] || {};
      const scenarioId = action.scenarioId;
      return {
        ...state,
        [action.moduleKey]: {
          ...moduleState,
          [action.projectId]: {
            ...projectState,
            [scenarioId]: ModuleRunStatus.ERROR,
          },
        },
      };
    }

    case SET_ANALYSIS_STATUS: {
      const { scenarioId, status: statusString, moduleKey, projectId } = action;
      const moduleState = state[moduleKey] || {};
      const projectState = moduleState[projectId] || {};

      return {
        ...state,
        [moduleKey]: {
          ...moduleState,
          [projectId]: {
            ...projectState,
            [scenarioId]: statusString,
          },
        },
      };
    }

    default:
      return state;
  }
}
