// MS Edge needs at least a second to initiate the download before we
// can remove the node. Other browsers do not need as long, but this
// only delays DOM cleanup, not the actual download.
export const DOWNLOAD_CLEANUP_DELAY = 1000;

export function downloadUrl(url: string, filename = '') {
  const anchor = document.createElement('a');

  let blobURL: string;
  if (url.startsWith('data:')) {
    const blob = dataURIToBlob(url);
    if (URL.createObjectURL) {
      blobURL = window.URL.createObjectURL(blob);
      anchor.href = blobURL;
    }
  } else {
    anchor.href = url;
  }

  initiateDownload(anchor, filename, blobURL);
}

function initiateDownload(
  anchor: HTMLAnchorElement,
  filename: string,
  blobURL: string,
) {
  // as per spec, the content-disposition header takes precedence here.
  // If it is empty and content-disposition isn't set, this should
  // prompt the user.
  anchor.download = filename;
  document.body.appendChild(anchor);

  const timeout = __TESTING__ ? 0 : DOWNLOAD_CLEANUP_DELAY;

  // Cleanup: remove the anchor *and* revoke the blob url if any.
  anchor.addEventListener('click', () => {
    // Make sure to remove the anchor after the click, so that we
    // don't leak memory by retaining a DOM node with the entire
    // downloaded file as a blob.
    setTimeout(() => {
      anchor.remove();
      if (blobURL && URL.revokeObjectURL) {
        URL.revokeObjectURL(blobURL);
      }
      // MSEdge seems to require at least a full second for the download
      // to "begin" before it is safe to remove the anchor.
    }, timeout);
  });

  anchor.click();
}

// TODO: Since this simulates a user clicking a download link, we should
// consider moving to the async download pattern, which actually creates a
// download link
export function downloadData(data: BlobPart, mimetype: string, filename = '') {
  const blob = new Blob([data], { type: mimetype });
  const anchor = document.createElement('a');

  anchor.href = window.URL.createObjectURL(blob);

  initiateDownload(anchor, filename, anchor.href);
}

// adapted from https://stackoverflow.com/questions/12168909/blob-from-dataurl
function dataURIToBlob(dataURI: string) {
  const splitPoint = dataURI.indexOf(',');
  const binPart = atob(dataURI.slice(splitPoint + 1));

  const mimePart = dataURI.slice(0, splitPoint);
  const mimeString = mimePart.split(':')[1].split(';')[0];

  const buffer = new ArrayBuffer(binPart.length);
  const bufferView = new Uint8Array(buffer);
  for (let i = 0; i < binPart.length; i += 1) {
    bufferView[i] = binPart.charCodeAt(i);
  }

  return new Blob([buffer], { type: mimeString });
}
