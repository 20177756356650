/**
 * UrbanFootprint App API
 * API for serving UrbanFootprint Application
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { ClientResponse } from '../variables';

// @ts-ignore: This is used only for params, but some services have param-free APIs
import { BuiltformServiceInterface } from './builtform.serviceInterface';

// @ts-ignore: These imports are for both params and returnType, but we only use returnType
import { ProblemDetail } from '../model/problemDetail';

/**
 * Package: api
 */

const getBuiltformAstsPrefix = `uf-api/builtform/get_builtform_asts/`;
/**
 * Action types for builtform.get_builtform_asts
 *
 * Get ASTs for Built Form Calcs
 */
export const getBuiltformAstsActionTypes = {
  LOAD: `${getBuiltformAstsPrefix}LOAD` as 'uf-api/builtform/get_builtform_asts/LOAD',
  SUCCESS:
    `${getBuiltformAstsPrefix}SUCCESS` as 'uf-api/builtform/get_builtform_asts/SUCCESS',
  FAILURE:
    `${getBuiltformAstsPrefix}FAILURE` as 'uf-api/builtform/get_builtform_asts/FAILURE',
  CLEAR:
    `${getBuiltformAstsPrefix}CLEAR` as 'uf-api/builtform/get_builtform_asts/CLEAR',
} as const;

/**
 * LOAD action for builtform.get_builtform_asts
 * Get ASTs for Built Form Calcs
 */
export interface getBuiltformAstsLoadAction {
  readonly type: 'uf-api/builtform/get_builtform_asts/LOAD';

  readonly promise: Promise<ClientResponse<object>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for builtform.get_builtform_asts
 * Get ASTs for Built Form Calcs
 */
export interface getBuiltformAstsSuccessAction {
  readonly type: 'uf-api/builtform/get_builtform_asts/SUCCESS';
  readonly result: object;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for builtform.get_builtform_asts
 * Get ASTs for Built Form Calcs
 */
export interface getBuiltformAstsFailureAction {
  readonly type: 'uf-api/builtform/get_builtform_asts/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for builtform.get_builtform_asts
 * Get ASTs for Built Form Calcs
 */
export interface getBuiltformAstsClearAction {
  readonly type: 'uf-api/builtform/get_builtform_asts/CLEAR';
  readonly key: string;
}

export const builtformActionTypesMap = {
  /** Action types for builtform.get_builtform_asts */
  get_builtform_asts: getBuiltformAstsActionTypes,
} as const;

export const builtformDetailsMap = {
  get_builtform_asts: {
    /** HTTP method for builtform.get_builtform_asts */
    httpMethod: 'get' as const,
  },
} as const;

/**
 * Local DataState<T> - kept in sync with uf/data/dataState.ts
 */
interface DataState<T> {
  readonly loading: boolean;
  readonly loaded: boolean;
  readonly requestId: string;
  readonly requestTime?: string;
  readonly responseTime?: string;
  readonly requestCount?: number;
  readonly error?: any;
  readonly promise?: Promise<T>;
  readonly data?: T;
  readonly extra?: any;
  readonly key?: string;
}
export interface BuiltformServiceState {
  /** getBuiltformAsts state, by key */
  get_builtform_asts: {
    readonly [key: string]: DataState<object>;
  };
}
