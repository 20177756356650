import { Divider } from '@blueprintjs/core';
import classNames from 'classnames';
import React, { useCallback } from 'react';
import { Box } from 'react-layout-components';
import { useSelector } from 'react-redux';
import { setExtentsPosition } from 'uf/explore/actions/map';
import {
  getExploreMapBearing,
  getExploreMapPitch,
  getExploreMapScale,
  makeGetExploreMapCenterLngLat,
} from 'uf/explore/selectors/map';
import { LayerId } from 'uf/layers';
import { MapPosition } from 'uf/map';
import { ProjectId } from 'uf/projects';
import buttonStyles from 'uf/styles/buttons.module.css';
import rootStyles from 'uf/styles/root.module.css';
import { tw } from 'uf/tailwindcss-classnames';
import useBindAction from 'uf/ui/base/useBindAction/useBindAction';
import { useMakeSelector } from 'uf/ui/base/useMakeSelector';
import ExploreBookmarks from 'uf/ui/explore/ExploreMap/ExploreBookmarks';
import SaveStyleLayersButton from 'uf/ui/explore/ExploreMap/SaveStyleLayersButton';
import ExploreMapExportButton from 'uf/ui/explore/ExploreMapExportButton/ExploreMapExportButton';
import ExploreSnapshotButton from 'uf/ui/explore/ExploreSnapshotButton/ExploreSnapshotButton';
import { MAPBOX_PADDING } from 'uf/ui/map/layout';
import GeoSearchbox from 'uf/ui/search/GeoSearchbox/GeoSearchbox';
import { SelectionModeButtons } from './SelectionModeButtons';
import { ZoomButtons } from './ZoomButtons';

const topToolbarStyle: React.CSSProperties = {
  paddingRight: MAPBOX_PADDING,
  paddingTop: 'var(--very-thin-spacing)',
  paddingBottom: 'var(--very-thin-spacing)',
  borderBottom: 'var(--default-border)',
};

interface OwnProps {
  projectId: ProjectId;
  layerId: LayerId;
}

export const controlButtonClassName = classNames(
  buttonStyles.button,
  rootStyles.veryThinMarginHorizontal,
);
const ExploreMapTopToolbar: React.FunctionComponent<OwnProps> = ({
  projectId,
  layerId,
}) => {
  const pitch = useSelector(state => getExploreMapPitch(state, { projectId }));
  const bearing = useSelector(state =>
    getExploreMapBearing(state, { projectId }),
  );
  const scale = useSelector(state => getExploreMapScale(state, { projectId }));
  const centerLngLat = useMakeSelector(makeGetExploreMapCenterLngLat, {
    projectId,
  });

  const isExploreSelectionModeDisabled = false;

  const onSetPosition = useBindAction(
    useCallback(
      (position: MapPosition) => {
        return setExtentsPosition(projectId, position);
      },
      [projectId],
    ),
  );

  return (
    <Box width="100%" style={topToolbarStyle} justifyContent="space-between">
      <Box>
        <SelectionModeButtons
          disabled={isExploreSelectionModeDisabled}
          layerId={layerId}
          projectId={projectId}
          buttonClassName={controlButtonClassName}
        />
      </Box>
      <Box>
        <GeoSearchbox
          className={tw('tw-w-96', 'tw-flex-none')}
          inputClassName={tw('tw-w-full')}
          resultsClassName={tw('tw-w-96')}
        />
        <ZoomButtons projectId={projectId} layerId={layerId} />
        <ExploreBookmarks
          className={buttonStyles.button}
          minimal
          center={centerLngLat}
          scale={scale}
          pitch={pitch}
          bearing={bearing}
          onSetPosition={onSetPosition}
        />
        <Divider />
        <ExploreMapExportButton
          className={buttonStyles.button}
          minimal
          tooltipPlacement="bottom"
        />
        <ExploreSnapshotButton
          className={buttonStyles.button}
          minimal
          tooltipPlacement="bottom"
        />
        <SaveStyleLayersButton />
      </Box>
    </Box>
  );
};

export default ExploreMapTopToolbar;
