import Icon from '@mdi/react';
import classNames from 'classnames';
import React, { FunctionComponent } from 'react';
import { Box } from 'react-layout-components';

import { IconSizeNew } from 'uf/ui/base/icon';

import styles from './AnalysisToolSwatch.module.css';

interface AnalysisToolSwatchProps {
  label?: string;
  iconPath?: string;
  stale?: boolean;
  className?: string;
}

const AnalysisToolSwatch: FunctionComponent<AnalysisToolSwatchProps> =
  props => {
    const { label, iconPath, stale, className } = props;
    const swatchClassName = classNames(className, styles.swatch, {
      [styles.stale]: stale,
    }); // TODO: make a more generalized <Swatch /> component

    return (
      <Box
        className={swatchClassName}
        alignItems="center"
        justifyContent="center"
        title={label}>
        <Icon
          path={iconPath}
          className={styles.icon}
          size={IconSizeNew.SMALL}
        />
      </Box>
    );
  };

export default AnalysisToolSwatch;
