import { Action as RFRAction } from 'redux-first-router';

import {
  ROUTE_ANALYSIS_EDITOR,
  ROUTE_ANALYSIS_EDITOR_WITH_KEY,
} from 'uf/analysiseditor/ActionTypes';
import { makeRoutingActionType } from 'uf/routing/actions';

export function setAnalysisEditorRoute(
  namespace: string,
  projectKey: string,
  flags?: string[],
): RFRAction {
  return {
    type: makeRoutingActionType(ROUTE_ANALYSIS_EDITOR),
    query: flagsQuery(flags),
    payload: {
      namespace,
      projectKey,
    },
  };
}

export function setAnalysisEditorWithKeyRoute(
  namespace: string,
  projectKey: string,
  moduleKey: string,
  flags?: string[],
): RFRAction {
  return {
    type: makeRoutingActionType(ROUTE_ANALYSIS_EDITOR_WITH_KEY),
    query: flagsQuery(flags),
    payload: {
      namespace,
      projectKey,
      moduleKey,
    },
  };
}

/**
 * Get the value of `action.query` for RFRActions, based on feature flags.
 *
 * TODO: generalize this functionality into a helper method for use with all
 * RFRActions
 *
 * @param flags flags that came in from the query
 */
function flagsQuery(flags: string[]) {
  if (!flags) {
    return null;
  }
  // Hide `developer` flag from URL for now, since that will just pollute the
  // dev experience
  const urlFlags = flags.filter(flag => flag !== 'developer');

  if (urlFlags.length) {
    return {
      feature_flags: urlFlags.join(','),
    };
  }
  return null;
}
