import uniqId from 'uniqid';

/**
 * Creates an SVG source file suitable for
 * @param path an SVG path string like "M0,0" or whatever
 */
export function makeSvgSourceFromPath(path: string, id: string) {
  // we could use react-dom/server's renderToStaticMarkup but thats a big
  // package for this single usecase.
  return `<?xml version="1.0" encoding="UTF-8"?>
  <!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
  <svg id="${id}" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24">
    <path d="${path}" />
  </svg>`;
}

/**
 * Creates a data url with a complete SVG source, suitable for an <img> tag src,
 * or `Image`.
 *
 * @param path an SVG path string like "M0,0" or whatever
 */
export function makeSvgDataUrlFromPath(path: string) {
  const encoded = encodeURIComponent(makeSvgSourceFromPath(path, uniqId()));
  return `data:image/svg+xml,${encoded}`;
}
