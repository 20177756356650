import bbox from '@turf/bbox';
import bboxPolygon from '@turf/bbox-polygon';
import { featureCollection } from '@turf/helpers';
import { createSelector } from 'reselect';
import { LayerBounds } from 'uf-api';
import { getData, isLoaded } from 'uf/data/dataState';
import { makeGetLayerBounds } from 'uf/layers/selectors/bounds';
import { makeGetEditsFilteredFeatures } from 'uf/mapedit/selectors/features';

export function makeGetLayerBoundsWithEdits() {
  return createSelector(
    makeGetLayerBounds(),
    makeGetEditsFilteredFeatures(),
    (layerBoundsState, layerEdits): LayerBounds => {
      // include in the bbox all our layer edits
      const features = [...layerEdits];

      // consider: it would be very strange to answer this without knowledge of the layer metadata
      if (isLoaded(layerBoundsState)) {
        const bounds = getData(layerBoundsState);
        // retrieve layer bounding box and tack onto feature list we wish to bbox
        if (bounds) {
          const { x_min: xMin, y_min: yMin, x_max: xMax, y_max: yMax } = bounds;
          const layerBoundsFeature = bboxPolygon([xMin, yMin, xMax, yMax]);
          features.push(layerBoundsFeature);
        }
      }
      if (!features.length) {
        return null;
      }
      const featureBounds = bbox(featureCollection(features));
      const [xMin, yMin, xMax, yMax] = featureBounds;
      return {
        x_min: xMin,
        y_min: yMin,
        x_max: xMax,
        y_max: yMax,
      };
    },
  );
}
