import { NotificationAction } from 'uf/tasks/ActionTypes';
import { TaskMessage } from 'uf/tasks/MessageTypes';

/**
 * Call this when you expect this message id to be
 * resolved/completed. relatedType is usually the action type that kicked this off.
 *
 * Typically this is done with an epic:
 *
 * const bindMyActionToResource: Epic<AnyAction> = action$ => {
 *   return action$
 *     .ofType(MyAction.SUCCESS)
 *     .map(({ result: taskId, resourceId }) =>
 *         bindTaskToResource(BIND_MY_TASK, taskId, resourceId);
 * }
 */
export function bindTaskToResource<T>(
  type: string,
  taskId: string,
  resourceId: T,
) {
  return {
    type,
    resourceId,
    taskId,
  };
}
export function notificationAction<T = TaskMessage, E = any>(
  type: string,
  // the websocket message
  result: T,
  // either task_id or uf_task_id
  key: string,
  extra?: E,
): NotificationAction<T, E> {
  return {
    result,
    type,
    key,
    extra,
  };
}
