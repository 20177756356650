import { BUILT_FORM_TYPES, BuiltForm } from 'uf/builtforms';
import {
  CLEAR_EDITOR_BUILTFORM,
  clearBuiltFormItemsActionType,
  clearBuiltFormListsActionType,
  INITIALIZE_BUILTFORM_EDITOR,
  InitializeBuiltformEditor,
  SET_EDITOR_CURRENT_BUILTFORM,
  SetEditorCurrentBuiltformAction,
  UPDATE_EDITOR_BUILTFORM,
} from 'uf/builtforms/ActionTypes';
import { ProjectId } from 'uf/projects';

export function updateEditorBuiltForm(
  libraryId: string,
  builtFormType: BUILT_FORM_TYPES,
  builtFormKey: string,
  builtForm: BuiltForm,
) {
  return {
    type: UPDATE_EDITOR_BUILTFORM,
    libraryId,
    builtFormType,
    builtFormKey,
    builtForm,
  };
}

export function clearEditorBuiltForm(
  libraryId: string,
  builtFormType: BUILT_FORM_TYPES,
  builtFormKey: string,
) {
  return {
    type: CLEAR_EDITOR_BUILTFORM,
    libraryId,
    builtFormType,
    builtFormKey,
  };
}

export function clearBuiltFormItems() {
  return {
    type: clearBuiltFormItemsActionType,
  };
}

export function clearBuiltFormLists() {
  return {
    type: clearBuiltFormListsActionType,
  };
}

export function setEditorCurrentBuiltForm(
  projectId: ProjectId,
  libraryId: string,
  builtFormType: BUILT_FORM_TYPES,
  builtFormKey?: string,
): SetEditorCurrentBuiltformAction {
  return {
    type: SET_EDITOR_CURRENT_BUILTFORM,
    projectId,
    value: {
      libraryId,
      builtFormType,
      builtFormKey,
    },
  };
}

export function initializeBuiltformEditor(
  libraryId: string,
  builtFormType: BUILT_FORM_TYPES,
  builtFormKey?: string,
): InitializeBuiltformEditor {
  return {
    type: INITIALIZE_BUILTFORM_EDITOR,
    libraryId,
    builtFormKey,
    builtFormType,
  };
}
