/**
 * UrbanFootprint App API
 * API for serving UrbanFootprint Application
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { ClientResponse } from '../variables';

// @ts-ignore: This is used only for params, but some services have param-free APIs
import { ProjectTypeServiceInterface } from './projectType.serviceInterface';

// @ts-ignore: These imports are for both params and returnType, but we only use returnType
import { ProblemDetail } from '../model/problemDetail';
// @ts-ignore: These imports are for both params and returnType, but we only use returnType
import { ProjectTypeIconInfo } from '../model/projectTypeIconInfo';
// @ts-ignore: These imports are for both params and returnType, but we only use returnType
import { ProjectTypeLayerMetadataList } from '../model/projectTypeLayerMetadataList';
// @ts-ignore: These imports are for both params and returnType, but we only use returnType
import { ProjectTypeList } from '../model/projectTypeList';

/**
 * Package: api
 */

const addProjectTypeIconPrefix = `uf-api/projectType/add_project_type_icon/`;
/**
 * Action types for projectType.add_project_type_icon
 *
 * Add icon to project type
 */
export const addProjectTypeIconActionTypes = {
  LOAD: `${addProjectTypeIconPrefix}LOAD` as 'uf-api/projectType/add_project_type_icon/LOAD',
  SUCCESS:
    `${addProjectTypeIconPrefix}SUCCESS` as 'uf-api/projectType/add_project_type_icon/SUCCESS',
  FAILURE:
    `${addProjectTypeIconPrefix}FAILURE` as 'uf-api/projectType/add_project_type_icon/FAILURE',
  CLEAR:
    `${addProjectTypeIconPrefix}CLEAR` as 'uf-api/projectType/add_project_type_icon/CLEAR',
} as const;

/**
 * LOAD action for projectType.add_project_type_icon
 * Add icon to project type
 */
export interface addProjectTypeIconLoadAction {
  readonly type: 'uf-api/projectType/add_project_type_icon/LOAD';
  readonly params: ProjectTypeServiceInterface.addProjectTypeIconParams;
  readonly promise: Promise<ClientResponse<ProjectTypeIconInfo>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for projectType.add_project_type_icon
 * Add icon to project type
 */
export interface addProjectTypeIconSuccessAction {
  readonly type: 'uf-api/projectType/add_project_type_icon/SUCCESS';
  readonly result: ProjectTypeIconInfo;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for projectType.add_project_type_icon
 * Add icon to project type
 */
export interface addProjectTypeIconFailureAction {
  readonly type: 'uf-api/projectType/add_project_type_icon/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for projectType.add_project_type_icon
 * Add icon to project type
 */
export interface addProjectTypeIconClearAction {
  readonly type: 'uf-api/projectType/add_project_type_icon/CLEAR';
  readonly key: string;
}

const getProjectTypeLayersPrefix = `uf-api/projectType/get_project_type_layers/`;
/**
 * Action types for projectType.get_project_type_layers
 *
 * Returns a list of all layers for a project type.
 */
export const getProjectTypeLayersActionTypes = {
  LOAD: `${getProjectTypeLayersPrefix}LOAD` as 'uf-api/projectType/get_project_type_layers/LOAD',
  SUCCESS:
    `${getProjectTypeLayersPrefix}SUCCESS` as 'uf-api/projectType/get_project_type_layers/SUCCESS',
  FAILURE:
    `${getProjectTypeLayersPrefix}FAILURE` as 'uf-api/projectType/get_project_type_layers/FAILURE',
  CLEAR:
    `${getProjectTypeLayersPrefix}CLEAR` as 'uf-api/projectType/get_project_type_layers/CLEAR',
} as const;

/**
 * LOAD action for projectType.get_project_type_layers
 * Returns a list of all layers for a project type.
 */
export interface getProjectTypeLayersLoadAction {
  readonly type: 'uf-api/projectType/get_project_type_layers/LOAD';
  readonly params: ProjectTypeServiceInterface.getProjectTypeLayersParams;
  readonly promise: Promise<ClientResponse<ProjectTypeLayerMetadataList>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for projectType.get_project_type_layers
 * Returns a list of all layers for a project type.
 */
export interface getProjectTypeLayersSuccessAction {
  readonly type: 'uf-api/projectType/get_project_type_layers/SUCCESS';
  readonly result: ProjectTypeLayerMetadataList;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for projectType.get_project_type_layers
 * Returns a list of all layers for a project type.
 */
export interface getProjectTypeLayersFailureAction {
  readonly type: 'uf-api/projectType/get_project_type_layers/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for projectType.get_project_type_layers
 * Returns a list of all layers for a project type.
 */
export interface getProjectTypeLayersClearAction {
  readonly type: 'uf-api/projectType/get_project_type_layers/CLEAR';
  readonly key: string;
}

const getProjectTypesPrefix = `uf-api/projectType/get_project_types/`;
/**
 * Action types for projectType.get_project_types
 *
 * Returns a list of all project types.
 */
export const getProjectTypesActionTypes = {
  LOAD: `${getProjectTypesPrefix}LOAD` as 'uf-api/projectType/get_project_types/LOAD',
  SUCCESS:
    `${getProjectTypesPrefix}SUCCESS` as 'uf-api/projectType/get_project_types/SUCCESS',
  FAILURE:
    `${getProjectTypesPrefix}FAILURE` as 'uf-api/projectType/get_project_types/FAILURE',
  CLEAR:
    `${getProjectTypesPrefix}CLEAR` as 'uf-api/projectType/get_project_types/CLEAR',
} as const;

/**
 * LOAD action for projectType.get_project_types
 * Returns a list of all project types.
 */
export interface getProjectTypesLoadAction {
  readonly type: 'uf-api/projectType/get_project_types/LOAD';
  readonly params: ProjectTypeServiceInterface.getProjectTypesParams;
  readonly promise: Promise<ClientResponse<ProjectTypeList>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for projectType.get_project_types
 * Returns a list of all project types.
 */
export interface getProjectTypesSuccessAction {
  readonly type: 'uf-api/projectType/get_project_types/SUCCESS';
  readonly result: ProjectTypeList;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for projectType.get_project_types
 * Returns a list of all project types.
 */
export interface getProjectTypesFailureAction {
  readonly type: 'uf-api/projectType/get_project_types/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for projectType.get_project_types
 * Returns a list of all project types.
 */
export interface getProjectTypesClearAction {
  readonly type: 'uf-api/projectType/get_project_types/CLEAR';
  readonly key: string;
}

export const projectTypeActionTypesMap = {
  /** Action types for projectType.add_project_type_icon */
  add_project_type_icon: addProjectTypeIconActionTypes,
  /** Action types for projectType.get_project_type_layers */
  get_project_type_layers: getProjectTypeLayersActionTypes,
  /** Action types for projectType.get_project_types */
  get_project_types: getProjectTypesActionTypes,
} as const;

export const projectTypeDetailsMap = {
  add_project_type_icon: {
    /** HTTP method for projectType.add_project_type_icon */
    httpMethod: 'post' as const,
  },
  get_project_type_layers: {
    /** HTTP method for projectType.get_project_type_layers */
    httpMethod: 'get' as const,
  },
  get_project_types: {
    /** HTTP method for projectType.get_project_types */
    httpMethod: 'get' as const,
  },
} as const;

/**
 * Local DataState<T> - kept in sync with uf/data/dataState.ts
 */
interface DataState<T> {
  readonly loading: boolean;
  readonly loaded: boolean;
  readonly requestId: string;
  readonly requestTime?: string;
  readonly responseTime?: string;
  readonly requestCount?: number;
  readonly error?: any;
  readonly promise?: Promise<T>;
  readonly data?: T;
  readonly extra?: any;
  readonly key?: string;
}
export interface ProjectTypeServiceState {
  /** all recent addProjectTypeIcon results (POST/PUT/PATCH) */
  readonly add_project_type_icon: DataState<ProjectTypeIconInfo>[];
  /** getProjectTypeLayers state, by key */
  get_project_type_layers: {
    readonly [key: string]: DataState<ProjectTypeLayerMetadataList>;
  };
  /** getProjectTypes state, by key */
  get_project_types: {
    readonly [key: string]: DataState<ProjectTypeList>;
  };
}
