import React from 'react';
import { t } from 'ttag';

interface MailToLinkProps {
  mailTo?: string;
  subject?: string;
  body?: string;
}

const MailToLink: React.FunctionComponent<MailToLinkProps> = ({
  mailTo,
  subject = t`No Subject`,
  body = '',
  children,
}) => {
  const encodedSubject = encodeURIComponent(subject);
  const encodedBody = encodeURIComponent(body);
  const href = `mailto:${mailTo}?subject=${encodedSubject}&body=${encodedBody}`;

  return (
    <a href={href} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  );
};

export default MailToLink;
