import React, { CSSProperties } from 'react';

const ITEM_HEIGHT = 20;
const ITEM_PADDING = 8;
const ARROW_SIZE = ITEM_HEIGHT + ITEM_PADDING * 2;
const ARROW_BORDER = ARROW_SIZE / 2;

// TODO: move the entire flyout to an overlay.
const arrowLeftStyle: CSSProperties = {
  width: 0,
  height: 0,
  borderTop: `${ARROW_BORDER}px solid transparent`,
  borderBottom: `${ARROW_BORDER}px solid transparent`,
  borderRight: `${ARROW_BORDER}px solid white`,
  position: 'absolute',
  right: `${-(ARROW_BORDER / 2)}px`,
  top: 0,
};

export default function ArrowLeft() {
  return <div style={arrowLeftStyle} />;
}
