import { makeAsyncActionTypes } from 'uf/data/ActionTypes';
import { importLayer } from 'uf/import/websocketMessages';
import { makeWebsocketActionTypes } from 'uf/websockets/actionHelpers';

const importPrefix = 'uf/import';
export const importActionTypes = makeAsyncActionTypes(importPrefix);

export const importLayerTaskActionTypes = makeWebsocketActionTypes(
  importLayer.LAYER_IMPORT_STARTED,
  importLayer.LAYER_IMPORT_DONE,
  importLayer.LAYER_IMPORT_ERROR,
);

export const BIND_IMPORT_LAYER_TASK = `${importPrefix}/BIND_IMPORT_LAYER_TASK`;
