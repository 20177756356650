import _ from 'lodash';
import { Dispatch, Store } from 'redux';
import watch from 'redux-watch';

import { parseFullPath } from 'uf/base/dataset';
import { makeLibraryId } from 'uf/builtforms';
import {
  setBuiltFormEditorRoute,
  setBuiltFormEditorWithKeyRoute,
} from 'uf/builtforms/actions';
import { ensureLibrary } from 'uf/builtforms/actions/data';
import { setEditorCurrentBuiltForm } from 'uf/builtforms/actions/editor';
import {
  BuiltFormsEditorContext,
  getEditorContext,
  getEditorCurrentBuiltFormState,
} from 'uf/builtforms/selectors';
import { getLocationState } from 'uf/routing/selectors';
import { SyncStateWithUrl } from 'uf/routing/state';

export const syncBuiltFormsStateWithUrl: SyncStateWithUrl = (store: Store) => {
  const watchBuiltFormCurrentBuiltFormState = watch(() =>
    getEditorContext(store.getState()),
  );
  const unsubscribe = store.subscribe(
    watchBuiltFormCurrentBuiltFormState((newState: BuiltFormsEditorContext) => {
      const { manageViewVisible, manageViewEditor, currentBuiltForm } =
        newState;
      if (
        !manageViewVisible ||
        manageViewEditor !== 'builtforms' ||
        !currentBuiltForm
      ) {
        return;
      }

      const { libraryId, builtFormType, builtFormKey } = currentBuiltForm;
      if (!libraryId) {
        return;
      }

      const { namespace, key } = parseFullPath(libraryId, 'builtforms');

      if (builtFormKey) {
        store.dispatch(
          setBuiltFormEditorWithKeyRoute(
            builtFormType,
            namespace,
            key,
            builtFormKey,
          ),
        );
      } else {
        store.dispatch(setBuiltFormEditorRoute(builtFormType, namespace, key));
      }
    }),
  );
  return unsubscribe;
};

export function makeUpdateEditorStateFromUrl() {
  return async (dispatch: Dispatch, getState) => {
    const state = getState();
    const location = getLocationState(state);
    const { type, namespace, libraryKey, key } = location.payload as any;
    const libraryId = makeLibraryId(namespace, libraryKey);
    const library = await dispatch(ensureLibrary(libraryId));

    // This might happen if the project is missing a library, like if the
    // library hasn't yet been materialized during project creation. We'll catch
    // it the next time around when the state includes the project library.
    if (_.isEmpty(library)) {
      return;
    }
    const { project_path: projectId } = library;

    const currentBuiltFormState = getEditorCurrentBuiltFormState(state);
    const currentBuiltForm = currentBuiltFormState[projectId];
    if (_.isEmpty(currentBuiltForm)) {
      return;
    }
    if (
      currentBuiltForm.builtFormKey === key &&
      currentBuiltForm.builtFormType === type &&
      currentBuiltForm.libraryId === libraryId
    ) {
      return;
    }

    return dispatch(setEditorCurrentBuiltForm(projectId, libraryId, type, key));
  };
}
