import { Intent, Menu, MenuDivider, Tag, Tooltip } from '@blueprintjs/core';
import { mdiCallMade, mdiContentCopy } from '@mdi/js';
import { Icon } from '@mdi/react';
import React, { Fragment } from 'react';
import { t } from 'ttag';

import { UserAction } from 'uf-api';
import { ProjectId } from 'uf/projects';
import { getCreateScenarioPermissionText, ScenarioId } from 'uf/scenarios';
import CanPerform from 'uf/ui/base/CanPerform/CanPerform';
import { IconSizeNew } from 'uf/ui/base/icon';
import OverflowMenuItem from 'uf/ui/base/OverflowMenuItem/OverflowMenuItem';

import styles from './ScenarioOptionsMenu.module.css';

interface Props {
  projectId: ProjectId;
  scenarioId: ScenarioId;
  scenariosLoading?: boolean;
  closeMenu: () => void;
  onCloneScenario: () => void;
  onCreateProject: () => void;

  showCreateProject?: boolean;
}
const BaseScenarioOverflowMenu: React.FunctionComponent<Props> = props => {
  const {
    projectId,
    scenarioId,
    closeMenu,
    onCloneScenario,
    onCreateProject,
    scenariosLoading,
    showCreateProject,
    // when rendered via Kebab, we must allow props to pass
    // through to the Popover for correct positioning
    ...restProps
  } = props;

  return (
    <Menu className={styles.menu} {...restProps}>
      <CanPerform cache={false} verb="scenario.create" resources={projectId}>
        {({ disabled, reason }) => {
          let label = null;
          if (disabled) {
            if (reason === UserAction.ReasonEnum.Permission) {
              return null;
            }
            const tooltipText = getCreateScenarioPermissionText(
              !disabled,
              reason,
            );
            label = (
              <Tooltip content={tooltipText} hoverOpenDelay={500}>
                {t`Create scenario`}
              </Tooltip>
            );
          } else {
            label = t`Create scenario`;
          }
          return (
            <OverflowMenuItem
              disabled={disabled}
              closeAfterLoading
              closeMenu={closeMenu}
              className={'Scenario-create'}
              loading={scenariosLoading}
              label={label}
              icon={
                <Icon path={mdiContentCopy} size={IconSizeNew.EXTRA_SMALL} />
              }
              onSelect={onCloneScenario}
            />
          );
        }}
      </CanPerform>
      {showCreateProject && (
        <Fragment>
          <MenuDivider />
          <OverflowMenuItem
            label={t`Create new project from this scenario`}
            labelElement={<Tag intent={Intent.WARNING}>{t`BETA`}</Tag>}
            icon={<Icon path={mdiCallMade} size={IconSizeNew.EXTRA_SMALL} />}
            onClick={onCreateProject}
          />
        </Fragment>
      )}
    </Menu>
  );
};

export default BaseScenarioOverflowMenu;
