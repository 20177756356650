import colors from 'material-colors';
import { t } from 'ttag';

import { DOLLARS, getUnitName } from 'uf/base/units';
import { LegacyReport } from 'uf/reporting/index';

const fiscalCostSource = {
  source: 'analysis',
  sourceKey: 'fiscal_cost',
} as const;

const CumulativeCostByCategoryTotalNetworkSeries = {
  ...fiscalCostSource,
  key: 'cumulative_cost_by_category_total_network',
  layerKey: 'cumulative_cost_by_category_summary',
  columnKey: 'total_network',
  color: colors.teal[600],
};

const CumulativeCostByCategoryTotalEMSSeries = {
  ...fiscalCostSource,
  key: 'cumulative_cost_by_category_total_ems',
  layerKey: 'cumulative_cost_by_category_summary',
  columnKey: 'total_ems',
  color: colors.amber[600],
};

const EmergencyServiceCostFireStationsSeries = {
  ...fiscalCostSource,
  key: 'emergency_service_cost_fire_stations',
  layerKey: 'emergency_service_cost_summary',
  columnKey: 'fire_stations',
  color: colors.deepOrange[900],
};

const EmergencyServiceCostFireEnginesSeries = {
  ...fiscalCostSource,
  key: 'emergency_service_cost_fire_engines',
  layerKey: 'emergency_service_cost_summary',
  columnKey: 'fire_engines',
  color: colors.red[400],
};

const EmergencyServiceCostLaddersSeries = {
  ...fiscalCostSource,
  key: 'emergency_service_cost_ladders',
  layerKey: 'emergency_service_cost_summary',
  columnKey: 'ladders',
  color: colors.brown[300],
};

const EmergencyServiceCostAmbSeries = {
  ...fiscalCostSource,
  key: 'emergency_service_cost_amb',
  layerKey: 'emergency_service_cost_summary',
  columnKey: 'ambulances',
  color: colors.grey[400],
};

const EmergencyServiceCostOpEnginesSeries = {
  ...fiscalCostSource,
  key: 'emergency_service_cost_op_engines',
  layerKey: 'emergency_service_cost_summary',
  columnKey: 'operation_engines',
  color: colors.red[400],
};

const EmergencyServiceCostOpAmbSeries = {
  ...fiscalCostSource,
  key: 'emergency_service_cost_op_amb',
  layerKey: 'emergency_service_cost_summary',
  columnKey: 'operation_ambulances',
  color: colors.grey[400],
};

const NetworkCostByTypeTotalCapitalSeries = {
  ...fiscalCostSource,
  key: 'network_cost_by_type_total_capital',
  layerKey: 'network_cost_by_type_summary',
  columnKey: 'total_capital',
  color: colors.green[700],
};

const NetworkCostByTypeTotalMaintenanceSeries = {
  ...fiscalCostSource,
  key: 'network_cost_by_type_total_maintenance',
  layerKey: 'network_cost_by_type_summary',
  columnKey: 'total_maintenance',
  color: colors.lightGreen[400],
};

const NetworkCostByTypeTotalReconstructionSeries = {
  ...fiscalCostSource,
  key: 'network_cost_by_type_total_recontruction',
  layerKey: 'network_cost_by_type_summary',
  columnKey: 'total_reconstruction',
  color: colors.blueGrey[400],
};

const NetworkCostByCategoryRoadsSeries = {
  ...fiscalCostSource,
  key: 'network_cost_by_category_roads',
  layerKey: 'network_cost_by_category_summary',
  columnKey: 'roads',
  color: colors.grey[700],
};

const NetworkCostByCategorySidewalkSeries = {
  ...fiscalCostSource,
  key: 'network_cost_by_category_sdwlk',
  layerKey: 'network_cost_by_category_summary',
  columnKey: 'sidewalks',
  color: colors.grey[300],
};

const NetworkCostByCategoryWaterSeries = {
  ...fiscalCostSource,
  key: 'network_cost_by_category_water',
  layerKey: 'network_cost_by_category_summary',
  columnKey: 'water',
  color: colors.lightBlue[600],
};

const NetworkCostByCategorySewersSeries = {
  ...fiscalCostSource,
  key: 'network_cost_by_category_sewers',
  layerKey: 'network_cost_by_category_summary',
  columnKey: 'sewers',
  color: colors.lime[600],
};

const NetworkCostByCategoryStormSeries = {
  ...fiscalCostSource,
  key: 'network_cost_by_category_storms',
  layerKey: 'network_cost_by_category_summary',
  columnKey: 'storm',
  color: colors.brown[600],
};

export const fiscalCostCharts: LegacyReport = Object.freeze({
  key: 'fiscal_cost',
  name: 'Fiscal Cost',
  charts: [
    {
      name: t`Combined Cumulative Fiscal Costs`,
      key: 'combined_cumulative_fiscal_cost_by_category',
      units: getUnitName(DOLLARS),
      series: [
        CumulativeCostByCategoryTotalNetworkSeries,
        CumulativeCostByCategoryTotalEMSSeries,
      ],
    },
    {
      name: t`Emergency Services: Capital Costs`,
      key: 'capital_costs_emergency_services',
      units: getUnitName(DOLLARS),
      series: [
        EmergencyServiceCostFireStationsSeries,
        EmergencyServiceCostFireEnginesSeries,
        EmergencyServiceCostAmbSeries,
        EmergencyServiceCostLaddersSeries,
      ],
    },
    {
      name: t`Emergency Services: Cumulative Operational Costs`,
      key: 'operation_costs_emergency_services',
      units: getUnitName(DOLLARS),
      series: [
        EmergencyServiceCostOpEnginesSeries,
        EmergencyServiceCostOpAmbSeries,
      ],
    },
    {
      name: t`Cumulative Network Infrastructure Costs`,
      key: 'cumulative_network_infra_cost_by_type',
      units: getUnitName(DOLLARS),
      series: [
        NetworkCostByTypeTotalCapitalSeries,
        NetworkCostByTypeTotalMaintenanceSeries,
        NetworkCostByTypeTotalReconstructionSeries,
      ],
    },
    {
      name: t`Cumulative Network Infrastructure Costs by Category`,
      key: 'new_property_tax_revenue',
      units: getUnitName(DOLLARS),
      series: [
        NetworkCostByCategoryRoadsSeries,
        NetworkCostByCategorySidewalkSeries,
        NetworkCostByCategoryWaterSeries,
        NetworkCostByCategorySewersSeries,
        NetworkCostByCategoryStormSeries,
      ],
    },
  ],
});
