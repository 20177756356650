/**
 * Issue a hard redirect, bypassing react-router.
 */
export function redirectTo(
  replaceLocation: (path: string) => void,
  path: string,
  query: Record<string, string> = {},
) {
  const fallbackOrigin = __CLIENT__
    ? window.location.origin
    : process.env.UF_APP_ORIGIN;
  const baseUrl = new URL(path, fallbackOrigin);
  Object.keys(query).forEach(key => {
    baseUrl.searchParams.set(key, query[key]);
  });

  if (__CLIENT__) {
    window.location.href = baseUrl.href;
  } else {
    // on the server this gets handled with an actual 301 redirect.
    replaceLocation(baseUrl.href);
  }
}
