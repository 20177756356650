import { H6, Tooltip } from '@blueprintjs/core';
import { Cell } from '@blueprintjs/table';
import React, { FunctionComponent, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { LayerColumn } from 'uf-api-rtk/store/Api';
import { selectApp } from 'uf/app/slice';
import { CELL_TOOLTIP } from 'uf/explore/logging';
import { LayerTableDataRow } from 'uf/layers/layerData';
import { logEvent } from 'uf/logging';
import { tw } from 'uf/tailwindcss-classnames';
import { CopyableText } from 'uf/ui/base/CopyableText/CopyableText';
interface DataCellProps {
  datum: LayerTableDataRow;
  column: LayerColumn;
  className: string;
  onDoubleClick: () => void;
}

export const DataCell: FunctionComponent<DataCellProps> = ({
  datum,
  onDoubleClick,
  column,
  ...props
}) => {
  // Grab active layer and project ID for logging purposes
  const { activeLayerId, activeProjectId } = useSelector(selectApp);
  const handleOpened = useCallback(() => {
    logEvent(CELL_TOOLTIP, {
      projectId: activeProjectId,
      layerId: activeLayerId,
      columnKey: column.key,
    });
  }, [activeLayerId, activeProjectId, column.key]);
  if (!datum) {
    return <Cell {...props} />;
  }
  const displayValue = formatRowItem(datum);
  /**
   * The native table tooltip is has minimal styling and does not expose position options.
   * For this reason we're wrapping the cell value in the Tooltip component. Note that
   * wrapping the Cell is not an option as the tooltip will render at the bottom
   * of the table.
   */
  return (
    <Cell {...props}>
      <Tooltip
        interactionKind="hover"
        hoverCloseDelay={100}
        placement="top"
        onOpened={handleOpened}
        content={
          <>
            <H6 className={tw('tw-text-white')}>{column.name}</H6>
            <CopyableText altText={displayValue} text={displayValue} />
          </>
        }>
        <div onDoubleClick={onDoubleClick}>{displayValue}</div>
      </Tooltip>
    </Cell>
  );
};

export const formatRowItem = (datum: LayerTableDataRow): string => {
  const { f, v } = datum;
  return f || v;
};
