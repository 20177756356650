// COPY from uf/websockets

/**
 * Constants and utilities for interacting with the websockets-based
 * notification service.
 */

/**
 * These are message types sent to the websocket server to tell the server we're
 * interested in a resource.
 */
export enum Actions {
  SUBSCRIBE = 'SUBSCRIBE',
  UNSUBSCRIBE = 'UNSUBSCRIBE',
  NOTIFY = 'NOTIFY',
}

/**
 * These are scopes that the websocket server knows about, to be used with {@link Actions}.  NOTE:
 * This needs to be kept in sync with ScopeTypes found in uf/api/notify/interface.py.
 */
export enum ScopeTypes {
  SESSION = 'SESSION',
  USER = 'USER',
  PROJECT = 'PROJECT',
}

export const scopeTypes: ScopeTypes[] = Object.values(ScopeTypes);

export interface WebsocketMessage {
  action?: Actions;
  scope_type: ScopeTypes;
  scope: string;
  // identifies the sender of the message
  connection_id?: string;
}

/**
 * Create a websocket message that will be sent to the server. For the id we send the user's current
 * session id.
 * @param action Subscribe/Unsubscribe/Notify
 * @param scopeType Session/Username/Project
 * @param scope the actual session id / userkey / projectid
 */
export function makeWebsocketMessage(
  action: Actions,
  scopeType: ScopeTypes,
  scope: string,
): WebsocketMessage {
  return {
    action,
    scope_type: scopeType,
    scope,
  };
}
