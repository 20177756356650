import {
  CuratedColumnSymbology,
  InterpolationTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/public/dataset/city_of_seattle_zoning_seattle': {
    zonelut: [
      {
        display_hints: {
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          scale: SymbologyScales.CATEGORICAL,
          theme: 'custom',
        },
        paint: {
          'fill-color': {
            default: '#ffbb78',
            property: 'zonelut',
            stops: [
              {
                color: '#fda1ff',
                value: 'C1',
              },
              {
                color: '#fda1ff',
                value: 'C2',
              },
              {
                color: '#ff7f0e',
                value: 'DH1',
              },
              {
                color: '#ffbb78',
                value: 'DH2',
              },
              {
                color: '#653294',
                value: 'DMC',
              },
              {
                color: '#653294',
                value: 'DMRC',
              },
              {
                color: '#d62728',
                value: 'DMRR',
              },
              {
                color: '#653294',
                value: 'DOC1',
              },
              {
                color: '#653294',
                value: 'DOC2',
              },
              {
                color: '#7b64ff',
                value: 'DRC',
              },
              {
                color: '#c45100',
                value: 'HR',
              },
              {
                color: '#c49c94',
                value: 'IB',
              },
              {
                color: '#e377c2',
                value: 'IC',
              },
              {
                color: '#f7b6d2',
                value: 'IDM',
              },
              {
                color: '#7f7f7f',
                value: 'IDR',
              },
              {
                color: '#aea1ff',
                value: 'IG1',
              },
              {
                color: '#aea1ff',
                value: 'IG2',
              },
              {
                color: '#e27300',
                value: 'LR1',
              },
              {
                color: '#e27300',
                value: 'LR1 RC',
              },
              {
                color: '#e27300',
                value: 'LR2',
              },
              {
                color: '#e27300',
                value: 'LR2 RC',
              },
              {
                color: '#e27300',
                value: 'LR3',
              },
              {
                color: '#e27300',
                value: 'LR3 RC',
              },
              {
                color: '#68ccca',
                value: 'MIO',
              },
              {
                color: '#808080',
                value: 'MPC',
              },
              {
                color: '#e27300',
                value: 'MR',
              },
              {
                color: '#e27300',
                value: 'MR RC',
              },
              {
                color: '#f44e3b',
                value: 'NC1',
              },
              {
                color: '#f44e3b',
                value: 'NC2',
              },
              {
                color: '#f44e3b',
                value: 'NC3',
              },
              {
                color: '#f44e3b',
                value: 'PMM',
              },
              {
                color: '#f44e3b',
                value: 'PSM',
              },
              {
                color: '#fe9200',
                value: 'RSL',
              },
              {
                color: '#fcdc00',
                value: 'SF 5000',
              },
              {
                color: '#fcdc00',
                value: 'SF 7200',
              },
              {
                color: '#fcdc00',
                value: 'SF 9600',
              },
              {
                color: '#f44e3b',
                value: 'SMD',
              },
              {
                color: '#f44e3b',
                value: 'SMNG',
              },
              {
                color: '#f44e3b',
                value: 'SMNR',
              },
              {
                color: '#f44e3b',
                value: 'SMRB',
              },
              {
                color: '#f44e3b',
                value: 'SMSLLU',
              },
              {
                color: '#f44e3b',
                value: 'SMU',
              },
              {
                color: '#f44e3b',
                value: 'SMUP',
              },
            ],
            type: 'categorical',
          },
          'fill-opacity': 0.9,
        },
        type: SymbologyTypes.FILL,
      },
      {
        display_hints: {
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          scale: SymbologyScales.CATEGORICAL,
          theme: 'custom',
        },
        paint: {
          'line-color': '#999999',
          'line-opacity': 0.9,
          'line-width': 0.5,
        },
        type: SymbologyTypes.LINE,
      },
    ],
  },
};

export default symbologies;
