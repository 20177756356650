import { emptySplitApi as api } from 'uf-api-rtk/store/emptyApi';
export const addTagTypes = [
  'authentication',
  'builtforms',
  'user',
  'layer',
  'project',
  'dynamic',
  'geo',
  'builtform',
  'internal',
  'mapexport',
  'metadata',
  'organization',
  'dashboard',
  'paint',
  'portfolio',
  'summary',
  'analysis',
  'project_type',
  'report',
  'settings',
  'userdata',
  'app',
] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: build => ({
      beginAcceptInvitation: build.query<
        BeginAcceptInvitationApiResponse,
        BeginAcceptInvitationApiArg
      >({
        query: queryArg => ({
          url: `/accept_invite`,
          params: { token: queryArg.token },
        }),
        providesTags: ['authentication'],
      }),
      authenticated: build.query<AuthenticatedApiResponse, AuthenticatedApiArg>(
        {
          query: queryArg => ({
            url: `/authenticated`,
            params: {
              state: queryArg.state,
              code: queryArg.code,
              error: queryArg.error,
              error_description: queryArg.errorDescription,
              error_uri: queryArg.errorUri,
            },
          }),
          providesTags: ['authentication'],
        },
      ),
      deleteBuildingType: build.mutation<
        DeleteBuildingTypeApiResponse,
        DeleteBuildingTypeApiArg
      >({
        query: queryArg => ({
          url: `/builtforms/${queryArg.libraryNamespace}/builtforms/${queryArg.libraryKey}/building_types/${queryArg.key}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['builtforms'],
      }),
      getBuildingType: build.query<
        GetBuildingTypeApiResponse,
        GetBuildingTypeApiArg
      >({
        query: queryArg => ({
          url: `/builtforms/${queryArg.libraryNamespace}/builtforms/${queryArg.libraryKey}/building_types/${queryArg.key}`,
        }),
        providesTags: ['builtforms'],
      }),
      createBuildingType: build.mutation<
        CreateBuildingTypeApiResponse,
        CreateBuildingTypeApiArg
      >({
        query: queryArg => ({
          url: `/builtforms/${queryArg.libraryNamespace}/builtforms/${queryArg.libraryKey}/building_types/${queryArg.key}`,
          method: 'POST',
          body: queryArg.body,
        }),
        invalidatesTags: ['builtforms'],
      }),
      updateBuildingType: build.mutation<
        UpdateBuildingTypeApiResponse,
        UpdateBuildingTypeApiArg
      >({
        query: queryArg => ({
          url: `/builtforms/${queryArg.libraryNamespace}/builtforms/${queryArg.libraryKey}/building_types/${queryArg.key}`,
          method: 'PUT',
          body: queryArg.buildingType,
        }),
        invalidatesTags: ['builtforms'],
      }),
      getBuildingTypePreview: build.mutation<
        GetBuildingTypePreviewApiResponse,
        GetBuildingTypePreviewApiArg
      >({
        query: queryArg => ({
          url: `/builtforms/${queryArg.libraryNamespace}/builtforms/${queryArg.libraryKey}/building_types_preview`,
          method: 'POST',
          body: queryArg.buildingType,
        }),
        invalidatesTags: ['builtforms'],
      }),
      deleteBuilding: build.mutation<
        DeleteBuildingApiResponse,
        DeleteBuildingApiArg
      >({
        query: queryArg => ({
          url: `/builtforms/${queryArg.libraryNamespace}/builtforms/${queryArg.libraryKey}/buildings/${queryArg.key}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['builtforms'],
      }),
      getBuilding: build.query<GetBuildingApiResponse, GetBuildingApiArg>({
        query: queryArg => ({
          url: `/builtforms/${queryArg.libraryNamespace}/builtforms/${queryArg.libraryKey}/buildings/${queryArg.key}`,
        }),
        providesTags: ['builtforms'],
      }),
      createBuilding: build.mutation<
        CreateBuildingApiResponse,
        CreateBuildingApiArg
      >({
        query: queryArg => ({
          url: `/builtforms/${queryArg.libraryNamespace}/builtforms/${queryArg.libraryKey}/buildings/${queryArg.key}`,
          method: 'POST',
          body: queryArg.body,
        }),
        invalidatesTags: ['builtforms'],
      }),
      updateBuilding: build.mutation<
        UpdateBuildingApiResponse,
        UpdateBuildingApiArg
      >({
        query: queryArg => ({
          url: `/builtforms/${queryArg.libraryNamespace}/builtforms/${queryArg.libraryKey}/buildings/${queryArg.key}`,
          method: 'PUT',
          body: queryArg.building,
        }),
        invalidatesTags: ['builtforms'],
      }),
      getBuildingPreview: build.mutation<
        GetBuildingPreviewApiResponse,
        GetBuildingPreviewApiArg
      >({
        query: queryArg => ({
          url: `/builtforms/${queryArg.libraryNamespace}/builtforms/${queryArg.libraryKey}/buildings_preview`,
          method: 'POST',
          body: queryArg.building,
          params: {
            prop_name: queryArg.propName,
            string_value: queryArg.stringValue,
            number_value: queryArg.numberValue,
            with_defaults: queryArg.withDefaults,
          },
        }),
        invalidatesTags: ['builtforms'],
      }),
      getBuildingPreviewMulti: build.mutation<
        GetBuildingPreviewMultiApiResponse,
        GetBuildingPreviewMultiApiArg
      >({
        query: queryArg => ({
          url: `/builtforms/${queryArg.libraryNamespace}/builtforms/${queryArg.libraryKey}/buildings_preview_multi`,
          method: 'POST',
          body: queryArg.libraryBuildingPreviewUpdateData,
          params: { with_defaults: queryArg.withDefaults },
        }),
        invalidatesTags: ['builtforms'],
      }),
      deletePlaceType: build.mutation<
        DeletePlaceTypeApiResponse,
        DeletePlaceTypeApiArg
      >({
        query: queryArg => ({
          url: `/builtforms/${queryArg.libraryNamespace}/builtforms/${queryArg.libraryKey}/place_types/${queryArg.key}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['builtforms'],
      }),
      getPlaceType: build.query<GetPlaceTypeApiResponse, GetPlaceTypeApiArg>({
        query: queryArg => ({
          url: `/builtforms/${queryArg.libraryNamespace}/builtforms/${queryArg.libraryKey}/place_types/${queryArg.key}`,
        }),
        providesTags: ['builtforms'],
      }),
      createPlaceType: build.mutation<
        CreatePlaceTypeApiResponse,
        CreatePlaceTypeApiArg
      >({
        query: queryArg => ({
          url: `/builtforms/${queryArg.libraryNamespace}/builtforms/${queryArg.libraryKey}/place_types/${queryArg.key}`,
          method: 'POST',
          body: queryArg.body,
        }),
        invalidatesTags: ['builtforms'],
      }),
      updatePlaceType: build.mutation<
        UpdatePlaceTypeApiResponse,
        UpdatePlaceTypeApiArg
      >({
        query: queryArg => ({
          url: `/builtforms/${queryArg.libraryNamespace}/builtforms/${queryArg.libraryKey}/place_types/${queryArg.key}`,
          method: 'PUT',
          body: queryArg.placeType,
        }),
        invalidatesTags: ['builtforms'],
      }),
      getPlaceTypePreview: build.mutation<
        GetPlaceTypePreviewApiResponse,
        GetPlaceTypePreviewApiArg
      >({
        query: queryArg => ({
          url: `/builtforms/${queryArg.libraryNamespace}/builtforms/${queryArg.libraryKey}/place_types_preview`,
          method: 'POST',
          body: queryArg.placeType,
        }),
        invalidatesTags: ['builtforms'],
      }),
      updateBuildingTypeInfos: build.mutation<
        UpdateBuildingTypeInfosApiResponse,
        UpdateBuildingTypeInfosApiArg
      >({
        query: queryArg => ({
          url: `/builtforms/${queryArg.libraryNamespace}/builtforms/${queryArg.libraryKey}/update_building_type_infos`,
          method: 'PUT',
          body: queryArg.buildingTypeList,
        }),
        invalidatesTags: ['builtforms'],
      }),
      updatePlaceTypeInfos: build.mutation<
        UpdatePlaceTypeInfosApiResponse,
        UpdatePlaceTypeInfosApiArg
      >({
        query: queryArg => ({
          url: `/builtforms/${queryArg.libraryNamespace}/builtforms/${queryArg.libraryKey}/update_place_type_infos`,
          method: 'PUT',
          body: queryArg.placeTypeList,
        }),
        invalidatesTags: ['builtforms'],
      }),
      getLibrary: build.query<GetLibraryApiResponse, GetLibraryApiArg>({
        query: queryArg => ({
          url: `/builtforms/${queryArg['namespace']}/builtforms/${queryArg.key}`,
        }),
        providesTags: ['builtforms'],
      }),
      getLibraryBuildingTypes: build.query<
        GetLibraryBuildingTypesApiResponse,
        GetLibraryBuildingTypesApiArg
      >({
        query: queryArg => ({
          url: `/builtforms/${queryArg['namespace']}/builtforms/${queryArg.key}/building_types`,
        }),
        providesTags: ['builtforms'],
      }),
      getLibraryBuildings: build.query<
        GetLibraryBuildingsApiResponse,
        GetLibraryBuildingsApiArg
      >({
        query: queryArg => ({
          url: `/builtforms/${queryArg['namespace']}/builtforms/${queryArg.key}/buildings`,
        }),
        providesTags: ['builtforms'],
      }),
      updateLibraryDefaults: build.mutation<
        UpdateLibraryDefaultsApiResponse,
        UpdateLibraryDefaultsApiArg
      >({
        query: queryArg => ({
          url: `/builtforms/${queryArg['namespace']}/builtforms/${queryArg.key}/defaults`,
          method: 'PUT',
          body: queryArg.libraryDefaults,
        }),
        invalidatesTags: ['builtforms'],
      }),
      getLibraryPlaceTypes: build.query<
        GetLibraryPlaceTypesApiResponse,
        GetLibraryPlaceTypesApiArg
      >({
        query: queryArg => ({
          url: `/builtforms/${queryArg['namespace']}/builtforms/${queryArg.key}/place_types`,
        }),
        providesTags: ['builtforms'],
      }),
      updateLibraryVariables: build.mutation<
        UpdateLibraryVariablesApiResponse,
        UpdateLibraryVariablesApiArg
      >({
        query: queryArg => ({
          url: `/builtforms/${queryArg['namespace']}/builtforms/${queryArg.key}/variables`,
          method: 'PUT',
          body: queryArg.libraryVariables,
        }),
        invalidatesTags: ['builtforms'],
      }),
      getCurrentUser: build.query<
        GetCurrentUserApiResponse,
        GetCurrentUserApiArg
      >({
        query: () => ({ url: `/current_user` }),
        providesTags: ['authentication'],
      }),
      canPerformActions: build.query<
        CanPerformActionsApiResponse,
        CanPerformActionsApiArg
      >({
        query: queryArg => ({
          url: `/current_user/can_perform`,
          params: { actions: queryArg.actions },
        }),
        providesTags: ['authentication'],
      }),
      getInvitationsForUser: build.query<
        GetInvitationsForUserApiResponse,
        GetInvitationsForUserApiArg
      >({
        query: () => ({ url: `/current_user/invitation` }),
        providesTags: ['user'],
      }),
      rejectUserInvitation: build.mutation<
        RejectUserInvitationApiResponse,
        RejectUserInvitationApiArg
      >({
        query: queryArg => ({
          url: `/current_user/invitation/${queryArg.token}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['user'],
      }),
      getUserInvitation: build.query<
        GetUserInvitationApiResponse,
        GetUserInvitationApiArg
      >({
        query: queryArg => ({
          url: `/current_user/invitation/${queryArg.token}`,
        }),
        providesTags: ['user'],
      }),
      acceptUserInvitation: build.mutation<
        AcceptUserInvitationApiResponse,
        AcceptUserInvitationApiArg
      >({
        query: queryArg => ({
          url: `/current_user/invitation/${queryArg.token}/accept`,
          method: 'POST',
        }),
        invalidatesTags: ['user'],
      }),
      cancelTask: build.mutation<CancelTaskApiResponse, CancelTaskApiArg>({
        query: queryArg => ({
          url: `/current_user/task/${queryArg.id}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['user'],
      }),
      createDatabank: build.mutation<
        CreateDatabankApiResponse,
        CreateDatabankApiArg
      >({
        query: queryArg => ({
          url: `/databank`,
          method: 'POST',
          body: queryArg.body,
        }),
        invalidatesTags: ['layer'],
      }),
      loadDatabankData: build.mutation<
        LoadDatabankDataApiResponse,
        LoadDatabankDataApiArg
      >({
        query: queryArg => ({
          url: `/databank/${queryArg.key}/data`,
          method: 'POST',
          body: queryArg.body,
        }),
        invalidatesTags: ['layer'],
      }),
      loadDatabankGeos: build.mutation<
        LoadDatabankGeosApiResponse,
        LoadDatabankGeosApiArg
      >({
        query: queryArg => ({
          url: `/databank/${queryArg.key}/geos`,
          method: 'POST',
          body: queryArg.body,
        }),
        invalidatesTags: ['layer'],
      }),
      addDatabankToOrg: build.mutation<
        AddDatabankToOrgApiResponse,
        AddDatabankToOrgApiArg
      >({
        query: queryArg => ({
          url: `/databank/${queryArg.key}/org/${queryArg.orgKey}`,
          method: 'POST',
        }),
        invalidatesTags: ['layer'],
      }),
      downloadExportedLayer: build.query<
        DownloadExportedLayerApiResponse,
        DownloadExportedLayerApiArg
      >({
        query: queryArg => ({
          url: `/download_exported_layer/${queryArg['namespace']}/${queryArg.layerType}/${queryArg.key}/${queryArg.exportKey}`,
        }),
        providesTags: ['layer'],
      }),
      downloadExported: build.query<
        DownloadExportedApiResponse,
        DownloadExportedApiArg
      >({
        query: queryArg => ({
          url: `/download_exported_layer/${queryArg.projectNamespace}/project/${queryArg.projectKey}/${queryArg.layerNamespace}/${queryArg.layerType}/${queryArg.layerKey}/${queryArg.exportKey}`,
        }),
        providesTags: ['project'],
      }),
      getDynamicLayer: build.query<
        GetDynamicLayerApiResponse,
        GetDynamicLayerApiArg
      >({
        query: queryArg => ({
          url: `/dynamic/${queryArg['namespace']}/${queryArg.key}`,
          params: { version: queryArg.version },
        }),
        providesTags: ['dynamic'],
      }),
      getDynamicLayerFilter: build.query<
        GetDynamicLayerFilterApiResponse,
        GetDynamicLayerFilterApiArg
      >({
        query: queryArg => ({
          url: `/dynamic/${queryArg['namespace']}/${queryArg.key}/filter`,
          params: { version: queryArg.version },
        }),
        providesTags: ['dynamic'],
      }),
      updateDynamicLayerFilter: build.mutation<
        UpdateDynamicLayerFilterApiResponse,
        UpdateDynamicLayerFilterApiArg
      >({
        query: queryArg => ({
          url: `/dynamic/${queryArg['namespace']}/${queryArg.key}/filter`,
          method: 'PUT',
          body: queryArg.body,
        }),
        invalidatesTags: ['dynamic'],
      }),
      emailVerified: build.query<EmailVerifiedApiResponse, EmailVerifiedApiArg>(
        {
          query: queryArg => ({
            url: `/email_verified`,
            params: {
              email: queryArg.email,
              success: queryArg.success,
              message: queryArg.message,
              invite_token: queryArg.inviteToken,
            },
          }),
          providesTags: ['authentication'],
        },
      ),
      forgotPassword: build.query<
        ForgotPasswordApiResponse,
        ForgotPasswordApiArg
      >({
        query: queryArg => ({
          url: `/forgot_password`,
          params: { default_email: queryArg.defaultEmail },
        }),
        providesTags: ['authentication'],
      }),
      loadTemporaryShapes: build.mutation<
        LoadTemporaryShapesApiResponse,
        LoadTemporaryShapesApiArg
      >({
        query: queryArg => ({
          url: `/geometries/temp_shapes`,
          method: 'POST',
          body: queryArg.body,
        }),
        invalidatesTags: ['geo'],
      }),
      getBuiltformAsts: build.query<
        GetBuiltformAstsApiResponse,
        GetBuiltformAstsApiArg
      >({
        query: () => ({ url: `/internal/builtform/asts` }),
        providesTags: ['builtform'],
      }),
      bakeLibrary: build.mutation<BakeLibraryApiResponse, BakeLibraryApiArg>({
        query: queryArg => ({
          url: `/internal/builtforms/${queryArg['namespace']}/builtforms/${queryArg.key}/bake`,
          method: 'POST',
          params: { revalidate: queryArg.revalidate },
        }),
        invalidatesTags: ['internal'],
      }),
      clearFlaskCache: build.mutation<
        ClearFlaskCacheApiResponse,
        ClearFlaskCacheApiArg
      >({
        query: () => ({ url: `/internal/clear_flask_cache`, method: 'POST' }),
        invalidatesTags: ['internal'],
      }),
      createDemoProject: build.mutation<
        CreateDemoProjectApiResponse,
        CreateDemoProjectApiArg
      >({
        query: queryArg => ({
          url: `/internal/create_demo_project`,
          method: 'POST',
          body: queryArg.body,
        }),
        invalidatesTags: ['internal'],
      }),
      getDynamicLayerInternal: build.query<
        GetDynamicLayerInternalApiResponse,
        GetDynamicLayerInternalApiArg
      >({
        query: queryArg => ({
          url: `/internal/dynamic/${queryArg['namespace']}/${queryArg.key}`,
          params: { version: queryArg.version },
        }),
        providesTags: ['internal'],
      }),
      getEntityInfo: build.query<GetEntityInfoApiResponse, GetEntityInfoApiArg>(
        {
          query: queryArg => ({
            url: `/internal/entity_info`,
            params: { full_path: queryArg.fullPath },
          }),
          providesTags: ['internal'],
        },
      ),
      exportUserDevices: build.query<
        ExportUserDevicesApiResponse,
        ExportUserDevicesApiArg
      >({
        query: () => ({ url: `/internal/export_user_devices` }),
        providesTags: ['internal'],
      }),
      getAvailableFeatureFlags: build.query<
        GetAvailableFeatureFlagsApiResponse,
        GetAvailableFeatureFlagsApiArg
      >({
        query: () => ({ url: `/internal/feature_flags` }),
        providesTags: ['internal'],
      }),
      getLayers: build.query<GetLayersApiResponse, GetLayersApiArg>({
        query: queryArg => ({
          url: `/internal/layer`,
          params: {
            namespace: queryArg['namespace'],
            layer_type: queryArg.layerType,
            key: queryArg.key,
          },
        }),
        providesTags: ['internal'],
      }),
      getLayerRegistry: build.query<
        GetLayerRegistryApiResponse,
        GetLayerRegistryApiArg
      >({
        query: queryArg => ({
          url: `/internal/layer/registry`,
          params: {
            namespace: queryArg['namespace'],
            layer_type: queryArg.layerType,
            key: queryArg.key,
          },
        }),
        providesTags: ['internal'],
      }),
      getLayerSummary: build.query<
        GetLayerSummaryApiResponse,
        GetLayerSummaryApiArg
      >({
        query: queryArg => ({
          url: `/internal/layer/summary`,
          params: {
            namespace: queryArg['namespace'],
            layer_type: queryArg.layerType,
            key: queryArg.key,
            version: queryArg.version,
          },
        }),
        providesTags: ['internal'],
      }),
      getLayerMetadata: build.query<
        GetLayerMetadataApiResponse,
        GetLayerMetadataApiArg
      >({
        query: queryArg => ({
          url: `/internal/layer/${queryArg['namespace']}/${queryArg.layerType}/${queryArg.key}`,
        }),
        providesTags: ['internal'],
      }),
      getInternalLayerBounds: build.query<
        GetInternalLayerBoundsApiResponse,
        GetInternalLayerBoundsApiArg
      >({
        query: queryArg => ({
          url: `/internal/layer_bounds/${queryArg['namespace']}/${queryArg.layerType}/${queryArg.key}`,
          params: { version: queryArg.version, filters: queryArg.filters },
        }),
        providesTags: ['internal'],
      }),
      getInternalLayerGeoKeys: build.query<
        GetInternalLayerGeoKeysApiResponse,
        GetInternalLayerGeoKeysApiArg
      >({
        query: queryArg => ({
          url: `/internal/layer_geo_keys/${queryArg['namespace']}/${queryArg.layerType}/${queryArg.key}`,
          params: {
            version: queryArg.version,
            filters: queryArg.filters,
            limit: queryArg.limit,
            uncached: queryArg.uncached,
          },
        }),
        providesTags: ['internal'],
      }),
      getInternalLayerStats: build.query<
        GetInternalLayerStatsApiResponse,
        GetInternalLayerStatsApiArg
      >({
        query: queryArg => ({
          url: `/internal/layer_stats/${queryArg['namespace']}/${queryArg.layerType}/${queryArg.key}`,
          params: {
            version: queryArg.version,
            filters: queryArg.filters,
            columns: queryArg.columns,
            histograms: queryArg.histograms,
            histogram_weigh_by: queryArg.histogramWeighBy,
            uncached: queryArg.uncached,
            normalize_by: queryArg.normalizeBy,
            row_count: queryArg.rowCount,
            max_categorical_values: queryArg.maxCategoricalValues,
          },
        }),
        providesTags: ['internal'],
      }),
      clearSubscriptionCache: build.mutation<
        ClearSubscriptionCacheApiResponse,
        ClearSubscriptionCacheApiArg
      >({
        query: queryArg => ({
          url: `/internal/organization/${queryArg.orgKey}/clear_subscription_cache`,
          method: 'POST',
        }),
        invalidatesTags: ['internal'],
      }),
      getPaintedCanvasActionsInternal: build.query<
        GetPaintedCanvasActionsInternalApiResponse,
        GetPaintedCanvasActionsInternalApiArg
      >({
        query: queryArg => ({
          url: `/internal/painted_canvas/${queryArg['namespace']}/${queryArg.layerType}/${queryArg.key}/actions`,
          params: {
            painter: queryArg.painter,
            creator: queryArg.creator,
            active: queryArg.active,
            limit: queryArg.limit,
          },
        }),
        providesTags: ['internal'],
      }),
      ping: build.query<PingApiResponse, PingApiArg>({
        query: queryArg => ({
          url: `/internal/ping`,
          params: {
            notify_session: queryArg.notifySession,
            notify_user: queryArg.notifyUser,
            notify_project_id: queryArg.notifyProjectId,
          },
        }),
        providesTags: ['internal'],
      }),
      getRunningTasks: build.query<
        GetRunningTasksApiResponse,
        GetRunningTasksApiArg
      >({
        query: () => ({ url: `/internal/running_tasks` }),
        providesTags: ['internal'],
      }),
      getUsers: build.query<GetUsersApiResponse, GetUsersApiArg>({
        query: () => ({ url: `/internal/user` }),
        providesTags: ['internal'],
      }),
      getUserDevices: build.query<
        GetUserDevicesApiResponse,
        GetUserDevicesApiArg
      >({
        query: () => ({ url: `/internal/user_devices` }),
        providesTags: ['internal'],
      }),
      getLayer: build.query<GetLayerApiResponse, GetLayerApiArg>({
        query: queryArg => ({
          url: `/layer/${queryArg['namespace']}/${queryArg.layerType}/${queryArg.key}`,
        }),
        providesTags: ['layer'],
      }),
      getLayerBounds: build.query<
        GetLayerBoundsApiResponse,
        GetLayerBoundsApiArg
      >({
        query: queryArg => ({
          url: `/layer_bounds/${queryArg['namespace']}/${queryArg.layerType}/${queryArg.key}`,
          params: { version: queryArg.version, filters: queryArg.filters },
        }),
        providesTags: ['layer'],
      }),
      getLayerBreaksMulti: build.query<
        GetLayerBreaksMultiApiResponse,
        GetLayerBreaksMultiApiArg
      >({
        query: queryArg => ({
          url: `/layer_breaks`,
          params: {
            break_count: queryArg.breakCount,
            break_type: queryArg.breakType,
            columns: queryArg.columns,
            filters: queryArg.filters,
            uncached: queryArg.uncached,
            layers: queryArg.layers,
            aggregation_method: queryArg.aggregationMethod,
          },
        }),
        providesTags: ['layer'],
      }),
      getLayerBreaks: build.query<
        GetLayerBreaksApiResponse,
        GetLayerBreaksApiArg
      >({
        query: queryArg => ({
          url: `/layer_breaks/${queryArg['namespace']}/${queryArg.layerType}/${queryArg.key}`,
          params: {
            version: queryArg.version,
            filters: queryArg.filters,
            break_count: queryArg.breakCount,
            break_type: queryArg.breakType,
            columns: queryArg.columns,
            uncached: queryArg.uncached,
            normalize_by: queryArg.normalizeBy,
          },
        }),
        providesTags: ['layer'],
      }),
      getAppLayersByContext: build.query<
        GetAppLayersByContextApiResponse,
        GetAppLayersByContextApiArg
      >({
        query: queryArg => ({
          url: `/layer_context`,
          params: { contexts: queryArg.contexts },
        }),
        providesTags: ['layer'],
      }),
      getLayerData: build.query<GetLayerDataApiResponse, GetLayerDataApiArg>({
        query: queryArg => ({
          url: `/layer_data/${queryArg['namespace']}/${queryArg.layerType}/${queryArg.key}`,
          params: {
            version: queryArg.version,
            filters: queryArg.filters,
            limit: queryArg.limit,
            sort_column: queryArg.sortColumn,
            sort_direction: queryArg.sortDirection,
          },
        }),
        providesTags: ['layer'],
      }),
      getLayerGeoKeys: build.query<
        GetLayerGeoKeysApiResponse,
        GetLayerGeoKeysApiArg
      >({
        query: queryArg => ({
          url: `/layer_geo_keys/${queryArg['namespace']}/${queryArg.layerType}/${queryArg.key}`,
          params: {
            version: queryArg.version,
            filters: queryArg.filters,
            limit: queryArg.limit,
            uncached: queryArg.uncached,
          },
        }),
        providesTags: ['layer'],
      }),
      getLayerGeojson: build.query<
        GetLayerGeojsonApiResponse,
        GetLayerGeojsonApiArg
      >({
        query: queryArg => ({
          url: `/layer_geojson/${queryArg['namespace']}/${queryArg.layerType}/${queryArg.key}`,
          params: {
            version: queryArg.version,
            filters: queryArg.filters,
            limit: queryArg.limit,
            columns: queryArg.columns,
            sort_column: queryArg.sortColumn,
            sort_direction: queryArg.sortDirection,
          },
        }),
        providesTags: ['layer'],
      }),
      getLayerGeometryType: build.query<
        GetLayerGeometryTypeApiResponse,
        GetLayerGeometryTypeApiArg
      >({
        query: queryArg => ({
          url: `/layer_geometry_type/${queryArg['namespace']}/${queryArg.layerType}/${queryArg.key}`,
        }),
        providesTags: ['layer'],
      }),
      getLatestVersion: build.query<
        GetLatestVersionApiResponse,
        GetLatestVersionApiArg
      >({
        query: queryArg => ({
          url: `/layer_latest_version/${queryArg['namespace']}/${queryArg.layerType}/${queryArg.key}`,
        }),
        providesTags: ['layer'],
      }),
      setReferenceLayer: build.mutation<
        SetReferenceLayerApiResponse,
        SetReferenceLayerApiArg
      >({
        query: queryArg => ({
          url: `/layer_set_reference_layer/${queryArg['namespace']}/${queryArg.layerType}/${queryArg.key}`,
          method: 'PUT',
          params: {
            is_reference_layer: queryArg.isReferenceLayer,
            is_public: queryArg.isPublic,
            target_org_namespace: queryArg.targetOrgNamespace,
            convex_hull_geom_uri: queryArg.convexHullGeomUri,
          },
        }),
        invalidatesTags: ['layer'],
      }),
      getLayerSpec: build.query<GetLayerSpecApiResponse, GetLayerSpecApiArg>({
        query: queryArg => ({
          url: `/layer_spec/${queryArg['namespace']}/${queryArg.layerType}/${queryArg.key}`,
        }),
        providesTags: ['layer'],
      }),
      getLayerStats: build.query<GetLayerStatsApiResponse, GetLayerStatsApiArg>(
        {
          query: queryArg => ({
            url: `/layer_stats/${queryArg['namespace']}/${queryArg.layerType}/${queryArg.key}`,
            params: {
              version: queryArg.version,
              filters: queryArg.filters,
              columns: queryArg.columns,
              histograms: queryArg.histograms,
              histogram_weigh_by: queryArg.histogramWeighBy,
              uncached: queryArg.uncached,
              normalize_by: queryArg.normalizeBy,
              row_count: queryArg.rowCount,
              max_categorical_values: queryArg.maxCategoricalValues,
            },
          }),
          providesTags: ['layer'],
        },
      ),
      login: build.mutation<LoginApiResponse, LoginApiArg>({
        query: queryArg => ({
          url: `/login`,
          method: 'POST',
          body: queryArg.body,
        }),
        invalidatesTags: ['authentication'],
      }),
      loginTokens: build.mutation<LoginTokensApiResponse, LoginTokensApiArg>({
        query: queryArg => ({
          url: `/login_tokens`,
          method: 'POST',
          body: queryArg.body,
        }),
        invalidatesTags: ['authentication'],
      }),
      logout: build.query<LogoutApiResponse, LogoutApiArg>({
        query: () => ({ url: `/logout` }),
        providesTags: ['authentication'],
      }),
      downloadMapExport: build.query<
        DownloadMapExportApiResponse,
        DownloadMapExportApiArg
      >({
        query: queryArg => ({
          url: `/mapexport/download/${queryArg['namespace']}/project/${queryArg.key}`,
          params: { export_key: queryArg.exportKey },
        }),
        providesTags: ['mapexport'],
      }),
      enqueueMapExport: build.mutation<
        EnqueueMapExportApiResponse,
        EnqueueMapExportApiArg
      >({
        query: queryArg => ({
          url: `/mapexport/enqueue_map_export/${queryArg['namespace']}/project/${queryArg.key}`,
          method: 'POST',
          body: queryArg.mapExportRequest,
        }),
        invalidatesTags: ['mapexport'],
      }),
      downloadExportedColumnMetadata: build.query<
        DownloadExportedColumnMetadataApiResponse,
        DownloadExportedColumnMetadataApiArg
      >({
        query: queryArg => ({
          url: `/metadata/columns/download/${queryArg['namespace']}/${queryArg.layerType}/${queryArg.key}/${queryArg.exportKey}`,
        }),
        providesTags: ['metadata'],
      }),
      enqueueColumnExportTask: build.mutation<
        EnqueueColumnExportTaskApiResponse,
        EnqueueColumnExportTaskApiArg
      >({
        query: queryArg => ({
          url: `/metadata/columns/${queryArg['namespace']}/${queryArg.layerType}/${queryArg.key}`,
          method: 'POST',
          body: queryArg.body,
          params: { summary_type: queryArg.summaryType },
        }),
        invalidatesTags: ['metadata'],
      }),
      getOrganizations: build.query<
        GetOrganizationsApiResponse,
        GetOrganizationsApiArg
      >({
        query: () => ({ url: `/organization` }),
        providesTags: ['organization'],
      }),
      deleteOrganization: build.mutation<
        DeleteOrganizationApiResponse,
        DeleteOrganizationApiArg
      >({
        query: queryArg => ({
          url: `/organization/${queryArg.key}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['organization'],
      }),
      getOrganization: build.query<
        GetOrganizationApiResponse,
        GetOrganizationApiArg
      >({
        query: queryArg => ({ url: `/organization/${queryArg.key}` }),
        providesTags: ['organization'],
      }),
      createOrganization: build.mutation<
        CreateOrganizationApiResponse,
        CreateOrganizationApiArg
      >({
        query: queryArg => ({
          url: `/organization/${queryArg.key}`,
          method: 'POST',
          body: queryArg.body,
        }),
        invalidatesTags: ['organization'],
      }),
      getBillingInfo: build.query<
        GetBillingInfoApiResponse,
        GetBillingInfoApiArg
      >({
        query: queryArg => ({
          url: `/organization/${queryArg.key}/billing_info`,
        }),
        providesTags: ['organization'],
      }),
      updateBillingInfo: build.mutation<
        UpdateBillingInfoApiResponse,
        UpdateBillingInfoApiArg
      >({
        query: queryArg => ({
          url: `/organization/${queryArg.key}/billing_info`,
          method: 'POST',
          body: queryArg.body,
        }),
        invalidatesTags: ['organization'],
      }),
      getCanvasTypes: build.query<
        GetCanvasTypesApiResponse,
        GetCanvasTypesApiArg
      >({
        query: queryArg => ({
          url: `/organization/${queryArg.key}/canvas_types`,
          params: {
            project_area_query: queryArg.projectAreaQuery,
            project_area_shapes_ref: queryArg.projectAreaShapesRef,
          },
        }),
        providesTags: ['project'],
      }),
      getOrganizationFeatureFlags: build.query<
        GetOrganizationFeatureFlagsApiResponse,
        GetOrganizationFeatureFlagsApiArg
      >({
        query: queryArg => ({
          url: `/organization/${queryArg.key}/feature_flags`,
        }),
        providesTags: ['organization'],
      }),
      getOrgFiles: build.query<GetOrgFilesApiResponse, GetOrgFilesApiArg>({
        query: queryArg => ({ url: `/organization/${queryArg.key}/files` }),
        providesTags: ['organization'],
      }),
      getOrganizationInsights: build.query<
        GetOrganizationInsightsApiResponse,
        GetOrganizationInsightsApiArg
      >({
        query: queryArg => ({ url: `/organization/${queryArg.key}/insights` }),
        providesTags: ['organization'],
      }),
      updateOrganizationInsightsHelpUrl: build.mutation<
        UpdateOrganizationInsightsHelpUrlApiResponse,
        UpdateOrganizationInsightsHelpUrlApiArg
      >({
        query: queryArg => ({
          url: `/organization/${queryArg.key}/insights_help_url`,
          method: 'PUT',
          body: queryArg.body,
        }),
        invalidatesTags: ['organization'],
      }),
      getMembersInfoInOrganization: build.query<
        GetMembersInfoInOrganizationApiResponse,
        GetMembersInfoInOrganizationApiArg
      >({
        query: queryArg => ({ url: `/organization/${queryArg.key}/members` }),
        providesTags: ['organization'],
      }),
      addMemberToOrganization: build.mutation<
        AddMemberToOrganizationApiResponse,
        AddMemberToOrganizationApiArg
      >({
        query: queryArg => ({
          url: `/organization/${queryArg.key}/members`,
          method: 'POST',
          body: queryArg.body,
        }),
        invalidatesTags: ['organization'],
      }),
      getFilteredMemberCountInOrganization: build.query<
        GetFilteredMemberCountInOrganizationApiResponse,
        GetFilteredMemberCountInOrganizationApiArg
      >({
        query: queryArg => ({
          url: `/organization/${queryArg.key}/members/count`,
        }),
        providesTags: ['organization'],
      }),
      updateOrganizationName: build.mutation<
        UpdateOrganizationNameApiResponse,
        UpdateOrganizationNameApiArg
      >({
        query: queryArg => ({
          url: `/organization/${queryArg.key}/name`,
          method: 'PUT',
          body: queryArg.body,
        }),
        invalidatesTags: ['organization'],
      }),
      getProjectsInOrganization: build.query<
        GetProjectsInOrganizationApiResponse,
        GetProjectsInOrganizationApiArg
      >({
        query: queryArg => ({ url: `/organization/${queryArg.key}/projects` }),
        providesTags: ['organization'],
      }),
      requestSalesContact: build.mutation<
        RequestSalesContactApiResponse,
        RequestSalesContactApiArg
      >({
        query: queryArg => ({
          url: `/organization/${queryArg.key}/request_sales_contact`,
          method: 'POST',
        }),
        invalidatesTags: ['organization'],
      }),
      getSubscription: build.query<
        GetSubscriptionApiResponse,
        GetSubscriptionApiArg
      >({
        query: queryArg => ({
          url: `/organization/${queryArg.key}/subscription`,
        }),
        providesTags: ['organization'],
      }),
      changeSubscriptionPlan: build.mutation<
        ChangeSubscriptionPlanApiResponse,
        ChangeSubscriptionPlanApiArg
      >({
        query: queryArg => ({
          url: `/organization/${queryArg.key}/subscription/plan_code`,
          method: 'PUT',
          body: queryArg.body,
          params: { preview: queryArg.preview },
        }),
        invalidatesTags: ['organization'],
      }),
      updateSubscriptionSeats: build.mutation<
        UpdateSubscriptionSeatsApiResponse,
        UpdateSubscriptionSeatsApiArg
      >({
        query: queryArg => ({
          url: `/organization/${queryArg.key}/subscription/seats`,
          method: 'PUT',
          body: queryArg.body,
          params: { preview: queryArg.preview },
        }),
        invalidatesTags: ['organization'],
      }),
      getOrgFileUrl: build.query<GetOrgFileUrlApiResponse, GetOrgFileUrlApiArg>(
        {
          query: queryArg => ({
            url: `/organization/${queryArg.key}/url/${queryArg.file}`,
            params: { method: queryArg.method },
          }),
          providesTags: ['organization'],
        },
      ),
      deleteOrgDashboard: build.mutation<
        DeleteOrgDashboardApiResponse,
        DeleteOrgDashboardApiArg
      >({
        query: queryArg => ({
          url: `/organization/${queryArg.orgKey}/dashboard/${queryArg.dashboardId}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['dashboard'],
      }),
      getDashboardEmbed: build.query<
        GetDashboardEmbedApiResponse,
        GetDashboardEmbedApiArg
      >({
        query: queryArg => ({
          url: `/organization/${queryArg.orgKey}/dashboard/${queryArg.dashboardId}`,
        }),
        providesTags: ['dashboard'],
      }),
      updateOrgDashboard: build.mutation<
        UpdateOrgDashboardApiResponse,
        UpdateOrgDashboardApiArg
      >({
        query: queryArg => ({
          url: `/organization/${queryArg.orgKey}/dashboard/${queryArg.dashboardId}`,
          method: 'PUT',
          body: queryArg.dashboard,
        }),
        invalidatesTags: ['dashboard'],
      }),
      getOrgDashboards: build.query<
        GetOrgDashboardsApiResponse,
        GetOrgDashboardsApiArg
      >({
        query: queryArg => ({
          url: `/organization/${queryArg.orgKey}/dashboards`,
        }),
        providesTags: ['dashboard'],
      }),
      getInvitationsForOrganization: build.query<
        GetInvitationsForOrganizationApiResponse,
        GetInvitationsForOrganizationApiArg
      >({
        query: queryArg => ({
          url: `/organization/${queryArg.orgKey}/invitation`,
        }),
        providesTags: ['organization'],
      }),
      inviteUserToOrganization: build.mutation<
        InviteUserToOrganizationApiResponse,
        InviteUserToOrganizationApiArg
      >({
        query: queryArg => ({
          url: `/organization/${queryArg.orgKey}/invitation`,
          method: 'POST',
          body: queryArg.body,
        }),
        invalidatesTags: ['organization'],
      }),
      cancelInvitation: build.mutation<
        CancelInvitationApiResponse,
        CancelInvitationApiArg
      >({
        query: queryArg => ({
          url: `/organization/${queryArg.orgKey}/invitation/${queryArg.token}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['organization'],
      }),
      deleteOrgLogo: build.mutation<
        DeleteOrgLogoApiResponse,
        DeleteOrgLogoApiArg
      >({
        query: queryArg => ({
          url: `/organization/${queryArg.orgKey}/logo/${queryArg.logoKey}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['organization'],
      }),
      getOrgLogoInfos: build.query<
        GetOrgLogoInfosApiResponse,
        GetOrgLogoInfosApiArg
      >({
        query: queryArg => ({ url: `/organization/${queryArg.orgKey}/logos` }),
        providesTags: ['organization'],
      }),
      addOrgLogo: build.mutation<AddOrgLogoApiResponse, AddOrgLogoApiArg>({
        query: queryArg => ({
          url: `/organization/${queryArg.orgKey}/logos`,
          method: 'POST',
          body: queryArg.body,
        }),
        invalidatesTags: ['organization'],
      }),
      removeMemberFromOrganization: build.mutation<
        RemoveMemberFromOrganizationApiResponse,
        RemoveMemberFromOrganizationApiArg
      >({
        query: queryArg => ({
          url: `/organization/${queryArg.orgKey}/members/${queryArg.userKey}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['organization'],
      }),
      editMemberInOrganization: build.mutation<
        EditMemberInOrganizationApiResponse,
        EditMemberInOrganizationApiArg
      >({
        query: queryArg => ({
          url: `/organization/${queryArg.orgKey}/members/${queryArg.userKey}`,
          method: 'PUT',
          body: queryArg.body,
        }),
        invalidatesTags: ['organization'],
      }),
      getPaintedCanvasActionsV2: build.query<
        GetPaintedCanvasActionsV2ApiResponse,
        GetPaintedCanvasActionsV2ApiArg
      >({
        query: queryArg => ({
          url: `/painted_canvas/${queryArg['namespace']}/${queryArg.layerType}/${queryArg.key}/actions`,
          params: {
            painter: queryArg.painter,
            creator: queryArg.creator,
            active: queryArg.active,
            limit: queryArg.limit,
          },
        }),
        providesTags: ['paint'],
      }),
      getPaintedCanvasActionByKey: build.query<
        GetPaintedCanvasActionByKeyApiResponse,
        GetPaintedCanvasActionByKeyApiArg
      >({
        query: queryArg => ({
          url: `/painted_canvas/${queryArg['namespace']}/${queryArg.layerType}/${queryArg.key}/actions/${queryArg.actionKey}`,
        }),
        providesTags: ['paint'],
      }),
      getRevertibleActionsV2: build.query<
        GetRevertibleActionsV2ApiResponse,
        GetRevertibleActionsV2ApiArg
      >({
        query: queryArg => ({
          url: `/painted_canvas/${queryArg['namespace']}/${queryArg.layerType}/${queryArg.key}/revertible_actions`,
        }),
        providesTags: ['paint'],
      }),
      listPortfolios: build.query<
        ListPortfoliosApiResponse,
        ListPortfoliosApiArg
      >({
        query: queryArg => ({
          url: `/portfolio`,
          params: {
            include_deleted: queryArg.includeDeleted,
            search: queryArg.search,
          },
        }),
        providesTags: ['portfolio'],
      }),
      createPortfolio: build.mutation<
        CreatePortfolioApiResponse,
        CreatePortfolioApiArg
      >({
        query: queryArg => ({
          url: `/portfolio`,
          method: 'POST',
          body: queryArg.body,
        }),
        invalidatesTags: ['portfolio'],
      }),
      deletePortfolio: build.mutation<
        DeletePortfolioApiResponse,
        DeletePortfolioApiArg
      >({
        query: queryArg => ({
          url: `/portfolio/${queryArg.key}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['portfolio'],
      }),
      getPortfolio: build.query<GetPortfolioApiResponse, GetPortfolioApiArg>({
        query: queryArg => ({
          url: `/portfolio/${queryArg.key}`,
          params: { include_deleted: queryArg.includeDeleted },
        }),
        providesTags: ['portfolio'],
      }),
      updatePortfolio: build.mutation<
        UpdatePortfolioApiResponse,
        UpdatePortfolioApiArg
      >({
        query: queryArg => ({
          url: `/portfolio/${queryArg.key}`,
          method: 'PUT',
          body: queryArg.portfolioInfo,
        }),
        invalidatesTags: ['portfolio'],
      }),
      addOrgToPortfolio: build.mutation<
        AddOrgToPortfolioApiResponse,
        AddOrgToPortfolioApiArg
      >({
        query: queryArg => ({
          url: `/portfolio/${queryArg.portfolioKey}/orgs`,
          method: 'PUT',
          body: queryArg.body,
        }),
        invalidatesTags: ['portfolio'],
      }),
      getProgramLevelSummary: build.query<
        GetProgramLevelSummaryApiResponse,
        GetProgramLevelSummaryApiArg
      >({
        query: queryArg => ({
          url: `/program_level_summary/${queryArg['namespace']}/${queryArg.layerType}/${queryArg.key}`,
          params: { version: queryArg.version },
        }),
        providesTags: ['summary'],
      }),
      getProjects: build.query<GetProjectsApiResponse, GetProjectsApiArg>({
        query: () => ({ url: `/project` }),
        providesTags: ['project'],
      }),
      createProject: build.mutation<
        CreateProjectApiResponse,
        CreateProjectApiArg
      >({
        query: queryArg => ({
          url: `/project`,
          method: 'POST',
          body: queryArg.body,
        }),
        invalidatesTags: ['project'],
      }),
      setProjectLayerAnalysisRunDashboard: build.mutation<
        SetProjectLayerAnalysisRunDashboardApiResponse,
        SetProjectLayerAnalysisRunDashboardApiArg
      >({
        query: queryArg => ({
          url: `/project/analysis_layer_task/${queryArg.key}/dashboard`,
          method: 'POST',
        }),
        invalidatesTags: ['project'],
      }),
      updateProjectLayerAnalysisRunDashboard: build.mutation<
        UpdateProjectLayerAnalysisRunDashboardApiResponse,
        UpdateProjectLayerAnalysisRunDashboardApiArg
      >({
        query: queryArg => ({
          url: `/project/analysis_layer_task/${queryArg.key}/dashboard`,
          method: 'PUT',
        }),
        invalidatesTags: ['project'],
      }),
      deleteProjectLayerAnalysisRunDashboard: build.mutation<
        DeleteProjectLayerAnalysisRunDashboardApiResponse,
        DeleteProjectLayerAnalysisRunDashboardApiArg
      >({
        query: queryArg => ({
          url: `/project/analysis_layer_task/${queryArg.key}/dashboard/${queryArg.id}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['project'],
      }),
      setProjectLayerAnalysisRunOutputTable: build.mutation<
        SetProjectLayerAnalysisRunOutputTableApiResponse,
        SetProjectLayerAnalysisRunOutputTableApiArg
      >({
        query: queryArg => ({
          url: `/project/analysis_layer_task/${queryArg.key}/result`,
          method: 'POST',
        }),
        invalidatesTags: ['project'],
      }),
      setProjectLayerAnalysisRunDashboardModel: build.mutation<
        SetProjectLayerAnalysisRunDashboardModelApiResponse,
        SetProjectLayerAnalysisRunDashboardModelApiArg
      >({
        query: queryArg => ({
          url: `/project/analysis_layer_task/${queryArg.key}/status`,
          method: 'POST',
        }),
        invalidatesTags: ['project'],
      }),
      setProjectLayerAnalysisRunStatus: build.mutation<
        SetProjectLayerAnalysisRunStatusApiResponse,
        SetProjectLayerAnalysisRunStatusApiArg
      >({
        query: queryArg => ({
          url: `/project/analysis_layer_task/${queryArg.key}/status/${queryArg.status}`,
          method: 'POST',
        }),
        invalidatesTags: ['project'],
      }),
      deleteProject: build.mutation<
        DeleteProjectApiResponse,
        DeleteProjectApiArg
      >({
        query: queryArg => ({
          url: `/project/${queryArg['namespace']}/project/${queryArg.key}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['project'],
      }),
      getProject: build.query<GetProjectApiResponse, GetProjectApiArg>({
        query: queryArg => ({
          url: `/project/${queryArg['namespace']}/project/${queryArg.key}`,
        }),
        providesTags: ['project'],
      }),
      editProject: build.mutation<EditProjectApiResponse, EditProjectApiArg>({
        query: queryArg => ({
          url: `/project/${queryArg['namespace']}/project/${queryArg.key}`,
          method: 'PUT',
          body: queryArg.body,
        }),
        invalidatesTags: ['project'],
      }),
      getAnalysisModules: build.query<
        GetAnalysisModulesApiResponse,
        GetAnalysisModulesApiArg
      >({
        query: queryArg => ({
          url: `/project/${queryArg['namespace']}/project/${queryArg.key}/analysis`,
        }),
        providesTags: ['project'],
      }),
      getAnalysisModule: build.query<
        GetAnalysisModuleApiResponse,
        GetAnalysisModuleApiArg
      >({
        query: queryArg => ({
          url: `/project/${queryArg['namespace']}/project/${queryArg.key}/analysis/${queryArg.moduleKey}`,
        }),
        providesTags: ['project'],
      }),
      runAnalysisModuleForProject: build.mutation<
        RunAnalysisModuleForProjectApiResponse,
        RunAnalysisModuleForProjectApiArg
      >({
        query: queryArg => ({
          url: `/project/${queryArg['namespace']}/project/${queryArg.key}/analysis/${queryArg['module']}/run`,
          method: 'POST',
          params: { scenario: queryArg.scenario },
        }),
        invalidatesTags: ['project'],
      }),
      getRunningProjectLayerAnalysisRuns: build.query<
        GetRunningProjectLayerAnalysisRunsApiResponse,
        GetRunningProjectLayerAnalysisRunsApiArg
      >({
        query: queryArg => ({
          url: `/project/${queryArg['namespace']}/project/${queryArg.key}/analysis_layer_task/running`,
        }),
        providesTags: ['project'],
      }),
      getProjectAnalysisParams: build.query<
        GetProjectAnalysisParamsApiResponse,
        GetProjectAnalysisParamsApiArg
      >({
        query: queryArg => ({
          url: `/project/${queryArg['namespace']}/project/${queryArg.key}/analysis_params/${queryArg.moduleKey}`,
          params: { scenario_key: queryArg.scenarioKey },
        }),
        providesTags: ['project'],
      }),
      getProjectAvailableLayers: build.query<
        GetProjectAvailableLayersApiResponse,
        GetProjectAvailableLayersApiArg
      >({
        query: queryArg => ({
          url: `/project/${queryArg['namespace']}/project/${queryArg.key}/available_layers`,
        }),
        providesTags: ['project'],
      }),
      enqueuePaintBaseScenarioCanvas: build.mutation<
        EnqueuePaintBaseScenarioCanvasApiResponse,
        EnqueuePaintBaseScenarioCanvasApiArg
      >({
        query: queryArg => ({
          url: `/project/${queryArg['namespace']}/project/${queryArg.key}/base_scenario/enqueue_paint`,
          method: 'POST',
          body: queryArg.body,
        }),
        invalidatesTags: ['paint'],
      }),
      enqueueProjectBufferedLayerTask: build.mutation<
        EnqueueProjectBufferedLayerTaskApiResponse,
        EnqueueProjectBufferedLayerTaskApiArg
      >({
        query: queryArg => ({
          url: `/project/${queryArg['namespace']}/project/${queryArg.key}/buffered_layer_task`,
          method: 'POST',
          body: queryArg.body,
        }),
        invalidatesTags: ['project'],
      }),
      enqueueProjectConvertLayerTask: build.mutation<
        EnqueueProjectConvertLayerTaskApiResponse,
        EnqueueProjectConvertLayerTaskApiArg
      >({
        query: queryArg => ({
          url: `/project/${queryArg['namespace']}/project/${queryArg.key}/convert_layer_task`,
          method: 'POST',
          body: queryArg.body,
        }),
        invalidatesTags: ['project'],
      }),
      enqueueCreateBaseCanvasTask: build.mutation<
        EnqueueCreateBaseCanvasTaskApiResponse,
        EnqueueCreateBaseCanvasTaskApiArg
      >({
        query: queryArg => ({
          url: `/project/${queryArg['namespace']}/project/${queryArg.key}/create_base_canvas`,
          method: 'POST',
          body: queryArg.body,
        }),
        invalidatesTags: ['project'],
      }),
      enqueueCreateContextCanvasTask: build.mutation<
        EnqueueCreateContextCanvasTaskApiResponse,
        EnqueueCreateContextCanvasTaskApiArg
      >({
        query: queryArg => ({
          url: `/project/${queryArg['namespace']}/project/${queryArg.key}/create_context_canvas`,
          method: 'POST',
          body: queryArg.body,
        }),
        invalidatesTags: ['project'],
      }),
      createProjectDynamicLayer: build.mutation<
        CreateProjectDynamicLayerApiResponse,
        CreateProjectDynamicLayerApiArg
      >({
        query: queryArg => ({
          url: `/project/${queryArg['namespace']}/project/${queryArg.key}/dynamic_layers`,
          method: 'POST',
          body: queryArg.body,
        }),
        invalidatesTags: ['project'],
      }),
      enqueuePaintScenarioCanvas: build.mutation<
        EnqueuePaintScenarioCanvasApiResponse,
        EnqueuePaintScenarioCanvasApiArg
      >({
        query: queryArg => ({
          url: `/project/${queryArg['namespace']}/project/${queryArg.key}/enqueue_paint/${queryArg.scenarioKey}`,
          method: 'POST',
          body: queryArg.body,
        }),
        invalidatesTags: ['paint'],
      }),
      exportModuleParams: build.query<
        ExportModuleParamsApiResponse,
        ExportModuleParamsApiArg
      >({
        query: queryArg => ({
          url: `/project/${queryArg['namespace']}/project/${queryArg.key}/export_module_params/${queryArg.moduleKey}`,
        }),
        providesTags: ['project'],
      }),
      removeFromLayerList: build.mutation<
        RemoveFromLayerListApiResponse,
        RemoveFromLayerListApiArg
      >({
        query: queryArg => ({
          url: `/project/${queryArg['namespace']}/project/${queryArg.key}/layers`,
          method: 'DELETE',
          params: {
            layer_path: queryArg.layerPath,
            module_key: queryArg.moduleKey,
            module_output_key: queryArg.moduleOutputKey,
          },
        }),
        invalidatesTags: ['project'],
      }),
      addToLayerList: build.mutation<
        AddToLayerListApiResponse,
        AddToLayerListApiArg
      >({
        query: queryArg => ({
          url: `/project/${queryArg['namespace']}/project/${queryArg.key}/layers`,
          method: 'PUT',
          params: {
            layer_path: queryArg.layerPath,
            module_key: queryArg.moduleKey,
            module_output_key: queryArg.moduleOutputKey,
          },
        }),
        invalidatesTags: ['project'],
      }),
      updateScenarioAnalysisParams: build.mutation<
        UpdateScenarioAnalysisParamsApiResponse,
        UpdateScenarioAnalysisParamsApiArg
      >({
        query: queryArg => ({
          url: `/project/${queryArg['namespace']}/project/${queryArg.key}/scenario_analysis_params/${queryArg.scenarioKey}`,
          method: 'PUT',
          body: queryArg.analysisModuleParameters,
          params: { preview: queryArg.preview },
        }),
        invalidatesTags: ['project'],
      }),
      createScenario: build.mutation<
        CreateScenarioApiResponse,
        CreateScenarioApiArg
      >({
        query: queryArg => ({
          url: `/project/${queryArg['namespace']}/project/${queryArg.key}/scenarios`,
          method: 'POST',
          body: queryArg.body,
        }),
        invalidatesTags: ['project'],
      }),
      editScenario: build.mutation<EditScenarioApiResponse, EditScenarioApiArg>(
        {
          query: queryArg => ({
            url: `/project/${queryArg['namespace']}/project/${queryArg.key}/scenarios/${queryArg.scenarioKey}`,
            method: 'PUT',
            body: queryArg.body,
          }),
          invalidatesTags: ['project'],
        },
      ),
      getScenarioUpdateInProgress: build.query<
        GetScenarioUpdateInProgressApiResponse,
        GetScenarioUpdateInProgressApiArg
      >({
        query: queryArg => ({
          url: `/project/${queryArg['namespace']}/project/${queryArg.key}/scenarios/${queryArg.scenarioKey}/update_in_progress`,
        }),
        providesTags: ['project'],
      }),
      getProjectTasks: build.query<
        GetProjectTasksApiResponse,
        GetProjectTasksApiArg
      >({
        query: queryArg => ({
          url: `/project/${queryArg['namespace']}/project/${queryArg.key}/tasks`,
        }),
        providesTags: ['project'],
      }),
      getProjectUpdateInProgress: build.query<
        GetProjectUpdateInProgressApiResponse,
        GetProjectUpdateInProgressApiArg
      >({
        query: queryArg => ({
          url: `/project/${queryArg['namespace']}/project/${queryArg.key}/update_in_progress`,
        }),
        providesTags: ['project'],
      }),
      getProjectWorkingLayers: build.query<
        GetProjectWorkingLayersApiResponse,
        GetProjectWorkingLayersApiArg
      >({
        query: queryArg => ({
          url: `/project/${queryArg['namespace']}/project/${queryArg.key}/working_layers`,
        }),
        providesTags: ['project'],
      }),
      getAnalysisOutputModuleMetadata: build.query<
        GetAnalysisOutputModuleMetadataApiResponse,
        GetAnalysisOutputModuleMetadataApiArg
      >({
        query: queryArg => ({
          url: `/project/${queryArg['namespace']}/project/${queryArg.projectKey}/analysis/${queryArg.moduleKey}/${queryArg.outputModuleKey}`,
        }),
        providesTags: ['analysis'],
      }),
      deleteScenario: build.mutation<
        DeleteScenarioApiResponse,
        DeleteScenarioApiArg
      >({
        query: queryArg => ({
          url: `/project/${queryArg['namespace']}/scenario/${queryArg.key}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['project'],
      }),
      runLayerServiceForProject: build.mutation<
        RunLayerServiceForProjectApiResponse,
        RunLayerServiceForProjectApiArg
      >({
        query: queryArg => ({
          url: `/project/${queryArg.projectNamespace}/project/${queryArg.projectKey}/analysis_layer_task/${queryArg.layerNamespace}/${queryArg.layerType}/${queryArg.layerKey}`,
          method: 'POST',
          body: queryArg.projectLayerAnalysisRunParameter,
        }),
        invalidatesTags: ['project'],
      }),
      enqueueLayerExportTask: build.mutation<
        EnqueueLayerExportTaskApiResponse,
        EnqueueLayerExportTaskApiArg
      >({
        query: queryArg => ({
          url: `/project/${queryArg.projectNamespace}/project/${queryArg.projectKey}/enqueue_export_task`,
          method: 'POST',
          body: queryArg.body,
          params: {
            layer_path: queryArg.layerPath,
            version: queryArg.version,
            filters: queryArg.filters,
            file_format: queryArg.fileFormat,
            include_geometries: queryArg.includeGeometries,
            column: queryArg.column,
          },
        }),
        invalidatesTags: ['layer'],
      }),
      updateLayerName: build.mutation<
        UpdateLayerNameApiResponse,
        UpdateLayerNameApiArg
      >({
        query: queryArg => ({
          url: `/project/${queryArg.projectNamespace}/project/${queryArg.projectKey}/layer/${queryArg.layerNamespace}/${queryArg.layerType}/${queryArg.layerKey}/name`,
          method: 'PUT',
          body: queryArg.body,
        }),
        invalidatesTags: ['project'],
      }),
      deleteSymbology: build.mutation<
        DeleteSymbologyApiResponse,
        DeleteSymbologyApiArg
      >({
        query: queryArg => ({
          url: `/project/${queryArg.projectNamespace}/project/${queryArg.projectKey}/symbology/${queryArg.layerNamespace}/${queryArg.layerType}/${queryArg.layerKey}/${queryArg.viewKey}/${queryArg.columnKey}`,
          method: 'DELETE',
          params: { divide_by_key: queryArg.divideByKey },
        }),
        invalidatesTags: ['project'],
      }),
      getSymbology: build.query<GetSymbologyApiResponse, GetSymbologyApiArg>({
        query: queryArg => ({
          url: `/project/${queryArg.projectNamespace}/project/${queryArg.projectKey}/symbology/${queryArg.layerNamespace}/${queryArg.layerType}/${queryArg.layerKey}/${queryArg.viewKey}/${queryArg.columnKey}`,
          params: { divide_by_key: queryArg.divideByKey },
        }),
        providesTags: ['project'],
      }),
      addSymbology: build.mutation<AddSymbologyApiResponse, AddSymbologyApiArg>(
        {
          query: queryArg => ({
            url: `/project/${queryArg.projectNamespace}/project/${queryArg.projectKey}/symbology/${queryArg.layerNamespace}/${queryArg.layerType}/${queryArg.layerKey}/${queryArg.viewKey}/${queryArg.columnKey}`,
            method: 'POST',
            body: queryArg.symbology,
            params: { divide_by_key: queryArg.divideByKey },
          }),
          invalidatesTags: ['project'],
        },
      ),
      enqueueImportLayer: build.mutation<
        EnqueueImportLayerApiResponse,
        EnqueueImportLayerApiArg
      >({
        query: queryArg => ({
          url: `/project/${queryArg.projectNamespace}/project/${queryArg.projectKey}/working_layers/enqueue_import`,
          method: 'POST',
          body: queryArg.body,
        }),
        invalidatesTags: ['project'],
      }),
      removeProjectWorkingLayer: build.mutation<
        RemoveProjectWorkingLayerApiResponse,
        RemoveProjectWorkingLayerApiArg
      >({
        query: queryArg => ({
          url: `/project/${queryArg.projectNamespace}/project/${queryArg.projectKey}/working_layers/${queryArg.layerNamespace}/${queryArg.layerType}/${queryArg.layerKey}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['project'],
      }),
      addProjectWorkingLayer: build.mutation<
        AddProjectWorkingLayerApiResponse,
        AddProjectWorkingLayerApiArg
      >({
        query: queryArg => ({
          url: `/project/${queryArg.projectNamespace}/project/${queryArg.projectKey}/working_layers/${queryArg.layerNamespace}/${queryArg.layerType}/${queryArg.layerKey}`,
          method: 'PUT',
        }),
        invalidatesTags: ['project'],
      }),
      addProjectTypeIcon: build.mutation<
        AddProjectTypeIconApiResponse,
        AddProjectTypeIconApiArg
      >({
        query: queryArg => ({
          url: `/project_type_icon/${queryArg.projectTypeKey}`,
          method: 'POST',
          body: queryArg.body,
          params: {
            name: queryArg.name,
            mimetype: queryArg.mimetype,
            filename: queryArg.filename,
          },
        }),
        invalidatesTags: ['project_type'],
      }),
      getProjectTypeLayers: build.query<
        GetProjectTypeLayersApiResponse,
        GetProjectTypeLayersApiArg
      >({
        query: queryArg => ({
          url: `/project_type_layers`,
          params: {
            project_type_key: queryArg.projectTypeKey,
            org_key: queryArg.orgKey,
          },
        }),
        providesTags: ['project_type'],
      }),
      getProjectTypes: build.query<
        GetProjectTypesApiResponse,
        GetProjectTypesApiArg
      >({
        query: queryArg => ({
          url: `/project_types`,
          params: { org_key: queryArg.orgKey },
        }),
        providesTags: ['project_type'],
      }),
      getProjectsV2: build.query<GetProjectsV2ApiResponse, GetProjectsV2ApiArg>(
        {
          query: () => ({ url: `/project_v2` }),
          providesTags: ['project'],
        },
      ),
      getReport: build.mutation<GetReportApiResponse, GetReportApiArg>({
        query: queryArg => ({
          url: `/report/${queryArg['namespace']}/project/${queryArg.key}`,
          method: 'POST',
          body: queryArg.reportSpec,
          params: { scenario_paths: queryArg.scenarioPaths },
        }),
        invalidatesTags: ['report'],
      }),
      getReportXls: build.mutation<GetReportXlsApiResponse, GetReportXlsApiArg>(
        {
          query: queryArg => ({
            url: `/report/${queryArg['namespace']}/project/${queryArg.key}/xls`,
            method: 'POST',
            body: queryArg.reportSpec,
            params: { scenario_paths: queryArg.scenarioPaths },
          }),
          invalidatesTags: ['report'],
        },
      ),
      getUserSettings: build.query<
        GetUserSettingsApiResponse,
        GetUserSettingsApiArg
      >({
        query: () => ({ url: `/settings` }),
        providesTags: ['settings'],
      }),
      updateGlobalSetting: build.mutation<
        UpdateGlobalSettingApiResponse,
        UpdateGlobalSettingApiArg
      >({
        query: queryArg => ({
          url: `/settings/global`,
          method: 'PUT',
          body: queryArg.userGlobalSetting,
        }),
        invalidatesTags: ['settings'],
      }),
      updateOrgSetting: build.mutation<
        UpdateOrgSettingApiResponse,
        UpdateOrgSettingApiArg
      >({
        query: queryArg => ({
          url: `/settings/org/${queryArg.orgKey}`,
          method: 'PUT',
          body: queryArg.userOrgSetting,
        }),
        invalidatesTags: ['settings'],
      }),
      updateProjectSetting: build.mutation<
        UpdateProjectSettingApiResponse,
        UpdateProjectSettingApiArg
      >({
        query: queryArg => ({
          url: `/settings/project/${queryArg.projectNamespace}/${queryArg.projectKey}`,
          method: 'PUT',
          body: queryArg.body,
        }),
        invalidatesTags: ['settings'],
      }),
      signin: build.query<SigninApiResponse, SigninApiArg>({
        query: queryArg => ({
          url: `/signin`,
          params: {
            default_email: queryArg.defaultEmail,
            redirect_to: queryArg.redirectTo,
          },
        }),
        providesTags: ['authentication'],
      }),
      signout: build.query<SignoutApiResponse, SignoutApiArg>({
        query: queryArg => ({
          url: `/signout`,
          params: {
            redirect_path: queryArg.redirectPath,
            redirect_to: queryArg.redirectTo,
          },
        }),
        providesTags: ['authentication'],
      }),
      getTopicEmbed: build.query<GetTopicEmbedApiResponse, GetTopicEmbedApiArg>(
        {
          query: queryArg => ({ url: `/topic/${queryArg.topicId}` }),
          providesTags: ['dashboard'],
        },
      ),
      listUserPortfolios: build.query<
        ListUserPortfoliosApiResponse,
        ListUserPortfoliosApiArg
      >({
        query: queryArg => ({ url: `/user/${queryArg.userKey}/portfolios` }),
        providesTags: ['portfolio'],
      }),
      deleteUserData: build.mutation<
        DeleteUserDataApiResponse,
        DeleteUserDataApiArg
      >({
        query: queryArg => ({
          url: `/user_data/${queryArg.userKey}`,
          method: 'DELETE',
          params: { key: queryArg.key },
        }),
        invalidatesTags: ['userdata'],
      }),
      getUserData: build.query<GetUserDataApiResponse, GetUserDataApiArg>({
        query: queryArg => ({
          url: `/user_data/${queryArg.userKey}`,
          params: { key: queryArg.key },
        }),
        providesTags: ['userdata'],
      }),
      setUserData: build.mutation<SetUserDataApiResponse, SetUserDataApiArg>({
        query: queryArg => ({
          url: `/user_data/${queryArg.userKey}`,
          method: 'PUT',
          body: queryArg.body,
          params: { key: queryArg.key },
        }),
        invalidatesTags: ['userdata'],
      }),
      listFavoriteLayers: build.query<
        ListFavoriteLayersApiResponse,
        ListFavoriteLayersApiArg
      >({
        query: queryArg => ({
          url: `/user_data/${queryArg.userKey}/favorite_layers`,
        }),
        providesTags: ['userdata'],
      }),
      deleteLayerFromFavorites: build.mutation<
        DeleteLayerFromFavoritesApiResponse,
        DeleteLayerFromFavoritesApiArg
      >({
        query: queryArg => ({
          url: `/user_data/${queryArg.userKey}/favorite_layers/${queryArg.layerNamespace}/${queryArg.layerType}/${queryArg.layerKey}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['userdata'],
      }),
      addLayerToFavorites: build.mutation<
        AddLayerToFavoritesApiResponse,
        AddLayerToFavoritesApiArg
      >({
        query: queryArg => ({
          url: `/user_data/${queryArg.userKey}/favorite_layers/${queryArg.layerNamespace}/${queryArg.layerType}/${queryArg.layerKey}`,
          method: 'PUT',
        }),
        invalidatesTags: ['userdata'],
      }),
      listUserDataKeys: build.query<
        ListUserDataKeysApiResponse,
        ListUserDataKeysApiArg
      >({
        query: queryArg => ({
          url: `/user_data/${queryArg.userKey}/list`,
          params: { prefix: queryArg.prefix },
        }),
        providesTags: ['userdata'],
      }),
      listUserDataItems: build.query<
        ListUserDataItemsApiResponse,
        ListUserDataItemsApiArg
      >({
        query: queryArg => ({
          url: `/user_data/${queryArg.userKey}/list/items`,
          params: { prefix: queryArg.prefix },
        }),
        providesTags: ['userdata'],
      }),
      getVersion: build.query<GetVersionApiResponse, GetVersionApiArg>({
        query: () => ({ url: `/version` }),
        providesTags: ['app'],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as Api };
export type BeginAcceptInvitationApiResponse = unknown;
export type BeginAcceptInvitationApiArg = {
  /** Invitation code. */
  token: string;
};
export type AuthenticatedApiResponse = unknown;
export type AuthenticatedApiArg = {
  /** Exact value passed to authorize. */
  state?: string;
  /** On success, authorization code for calling service. */
  code?: string;
  /** On error, error code representing error. */
  error?: string;
  /** Human-readable description of error. */
  errorDescription?: string;
  /** Page with additional information about error. */
  errorUri?: string;
};
export type DeleteBuildingTypeApiResponse =
  /** status 204 Successfully deleted building type. */ undefined;
export type DeleteBuildingTypeApiArg = {
  /** Namespace of built forms library */
  libraryNamespace: string;
  /** Key of built forms library */
  libraryKey: string;
  /** Key of Building Type */
  key: string;
};
export type GetBuildingTypeApiResponse =
  /** status 200 Success. */ BuildingType;
export type GetBuildingTypeApiArg = {
  /** Namespace of built forms library */
  libraryNamespace: string;
  /** Key of built forms library */
  libraryKey: string;
  /** Key of Building Type */
  key: string;
};
export type CreateBuildingTypeApiResponse =
  /** status 200 The newly created building type. */ BuildingType;
export type CreateBuildingTypeApiArg = {
  /** Namespace of built forms library */
  libraryNamespace: string;
  /** Key of built forms library */
  libraryKey: string;
  /** Key of Building Type */
  key: string;
  body: {
    name: string;
  };
};
export type UpdateBuildingTypeApiResponse =
  /** status 204 Success. */ undefined;
export type UpdateBuildingTypeApiArg = {
  /** Namespace of built forms library */
  libraryNamespace: string;
  /** Key of built forms library */
  libraryKey: string;
  /** Key of Building Type */
  key: string;
  buildingType: BuildingType;
};
export type GetBuildingTypePreviewApiResponse =
  /** status 200 Success. */ BuildingType;
export type GetBuildingTypePreviewApiArg = {
  /** Namespace of built forms library */
  libraryNamespace: string;
  /** Key of built forms library */
  libraryKey: string;
  buildingType: BuildingType;
};
export type DeleteBuildingApiResponse =
  /** status 204 Successfully deleted building. */ undefined;
export type DeleteBuildingApiArg = {
  /** Namespace of built forms library */
  libraryNamespace: string;
  /** Key of built forms library */
  libraryKey: string;
  /** Key of Building */
  key: string;
};
export type GetBuildingApiResponse = /** status 200 Success. */ Building;
export type GetBuildingApiArg = {
  /** Namespace of built forms library */
  libraryNamespace: string;
  /** Key of built forms library */
  libraryKey: string;
  /** Key of Building */
  key: string;
};
export type CreateBuildingApiResponse =
  /** status 200 The newly created building. */ Building;
export type CreateBuildingApiArg = {
  /** Namespace of built forms library */
  libraryNamespace: string;
  /** Key of built forms library */
  libraryKey: string;
  /** Key of Building */
  key: string;
  body: {
    name: string;
  };
};
export type UpdateBuildingApiResponse = /** status 204 Success. */ undefined;
export type UpdateBuildingApiArg = {
  /** Namespace of built forms library */
  libraryNamespace: string;
  /** Key of built forms library */
  libraryKey: string;
  /** Key of Building */
  key: string;
  building: Building;
};
export type GetBuildingPreviewApiResponse = /** status 200 Success. */ Building;
export type GetBuildingPreviewApiArg = {
  /** Namespace of built forms library */
  libraryNamespace: string;
  /** Key of built forms library */
  libraryKey: string;
  /** The property to change. */
  propName?: string;
  /** The value to change the property to, if it is a string property. */
  stringValue?: string;
  /** The value to change the property to, if it is a number property. */
  numberValue?: number;
  /** If set, apply the default values to the building */
  withDefaults?: boolean;
  building: Building;
};
export type GetBuildingPreviewMultiApiResponse =
  /** status 200 Success. */ Building;
export type GetBuildingPreviewMultiApiArg = {
  /** Namespace of built forms library */
  libraryNamespace: string;
  /** Key of built forms library */
  libraryKey: string;
  /** If set, apply the default values to the building */
  withDefaults?: boolean;
  libraryBuildingPreviewUpdateData: LibraryBuildingPreviewUpdateData;
};
export type DeletePlaceTypeApiResponse =
  /** status 204 Successfully deleted place type. */ undefined;
export type DeletePlaceTypeApiArg = {
  /** Namespace of built forms library */
  libraryNamespace: string;
  /** Key of built forms library */
  libraryKey: string;
  /** Key of Place Type */
  key: string;
};
export type GetPlaceTypeApiResponse = /** status 200 Success. */ PlaceType;
export type GetPlaceTypeApiArg = {
  /** Namespace of built forms library */
  libraryNamespace: string;
  /** Key of built forms library */
  libraryKey: string;
  /** Key of Place Type */
  key: string;
};
export type CreatePlaceTypeApiResponse =
  /** status 200 The newly created place type. */ PlaceType;
export type CreatePlaceTypeApiArg = {
  /** Namespace of built forms library */
  libraryNamespace: string;
  /** Key of built forms library */
  libraryKey: string;
  /** Key of Place Type */
  key: string;
  body: {
    name: string;
  };
};
export type UpdatePlaceTypeApiResponse = /** status 204 Success. */ undefined;
export type UpdatePlaceTypeApiArg = {
  /** Namespace of built forms library */
  libraryNamespace: string;
  /** Key of built forms library */
  libraryKey: string;
  /** Key of Place Type */
  key: string;
  placeType: PlaceType;
};
export type GetPlaceTypePreviewApiResponse =
  /** status 200 Success. */ PlaceType;
export type GetPlaceTypePreviewApiArg = {
  /** Namespace of built forms library */
  libraryNamespace: string;
  /** Key of built forms library */
  libraryKey: string;
  placeType: PlaceType;
};
export type UpdateBuildingTypeInfosApiResponse =
  /** status 204 Success. */ undefined;
export type UpdateBuildingTypeInfosApiArg = {
  /** Namespace of built forms library */
  libraryNamespace: string;
  /** Key of built forms library */
  libraryKey: string;
  buildingTypeList: BuildingTypeList;
};
export type UpdatePlaceTypeInfosApiResponse =
  /** status 204 Success. */ undefined;
export type UpdatePlaceTypeInfosApiArg = {
  /** Namespace of built forms library */
  libraryNamespace: string;
  /** Key of built forms library */
  libraryKey: string;
  placeTypeList: PlaceTypeList;
};
export type GetLibraryApiResponse = /** status 200 Success. */ Library;
export type GetLibraryApiArg = {
  /** Namespace (organization) of built forms library */
  namespace: string;
  /** Key of built forms library */
  key: string;
};
export type GetLibraryBuildingTypesApiResponse =
  /** status 200 List of buildings types in a library. */ BuildingTypeList;
export type GetLibraryBuildingTypesApiArg = {
  /** Namespace (organization) of built forms library */
  namespace: string;
  /** Key of built forms library */
  key: string;
};
export type GetLibraryBuildingsApiResponse =
  /** status 200 List of buildings in a library. */ BuildingList;
export type GetLibraryBuildingsApiArg = {
  /** Namespace (organization) of built forms library */
  namespace: string;
  /** Key of built forms library */
  key: string;
};
export type UpdateLibraryDefaultsApiResponse =
  /** status 204 Success. */ undefined;
export type UpdateLibraryDefaultsApiArg = {
  /** Namespace (organization) of built forms library */
  namespace: string;
  /** Key of built forms library */
  key: string;
  libraryDefaults: LibraryDefaults;
};
export type GetLibraryPlaceTypesApiResponse =
  /** status 200 List of places types in a library. */ PlaceTypeList;
export type GetLibraryPlaceTypesApiArg = {
  /** Namespace (organization) of built forms library */
  namespace: string;
  /** Key of built forms library */
  key: string;
};
export type UpdateLibraryVariablesApiResponse =
  /** status 204 Success. */ undefined;
export type UpdateLibraryVariablesApiArg = {
  /** Namespace (organization) of built forms library */
  namespace: string;
  /** Key of built forms library */
  key: string;
  libraryVariables: LibraryVariables;
};
export type GetCurrentUserApiResponse = /** status 200 the current user */ User;
export type GetCurrentUserApiArg = void;
export type CanPerformActionsApiResponse =
  /** status 200 Results for each action pair */ UserActionList;
export type CanPerformActionsApiArg = {
  actions: string[];
};
export type GetInvitationsForUserApiResponse =
  /** status 200 List of pending invitations. */ InvitationList;
export type GetInvitationsForUserApiArg = void;
export type RejectUserInvitationApiResponse =
  /** status 204 Invitation successfully rejected */ undefined;
export type RejectUserInvitationApiArg = {
  /** Invite token. */
  token: string;
};
export type GetUserInvitationApiResponse =
  /** status 200 Information about invitation */ InvitationListItem;
export type GetUserInvitationApiArg = {
  /** Invite token. */
  token: string;
};
export type AcceptUserInvitationApiResponse =
  /** status 204 Successfully joined organization */ undefined;
export type AcceptUserInvitationApiArg = {
  /** Invite token. */
  token: string;
};
export type CancelTaskApiResponse =
  /** status 204 Successfully cancelled task. */ undefined;
export type CancelTaskApiArg = {
  /** Task id. */
  id: string;
};
export type CreateDatabankApiResponse =
  /** status 200 Successfully created databank. */ LayerReference;
export type CreateDatabankApiArg = {
  body: {
    source?: string;
    load_spec_path?: string;
    namespace?: string;
    key?: string;
    geometry_type_key?: string;
    ignore_columns?: string;
  };
};
export type LoadDatabankDataApiResponse =
  /** status 200 The uf_task_id of the async task that will load the databank data. */ string;
export type LoadDatabankDataApiArg = {
  /** Key of layer */
  key: string;
  body: {
    max_version?: string;
  };
};
export type LoadDatabankGeosApiResponse =
  /** status 200 The uf_task_id of the async task that will load the databank geos. */ string;
export type LoadDatabankGeosApiArg = {
  /** Key of layer */
  key: string;
  body: {
    max_version?: string;
  };
};
export type AddDatabankToOrgApiResponse =
  /** status 204 Successfully added databank to organization */ undefined;
export type AddDatabankToOrgApiArg = {
  /** Key of layer */
  key: string;
  /** Key of organization. */
  orgKey: string;
};
export type DownloadExportedLayerApiResponse = unknown;
export type DownloadExportedLayerApiArg = {
  /** Namespace (organization) of layer */
  namespace: string;
  /** Type of layer - eg. 'dataset', 'analysis', etc. */
  layerType: string;
  /** Key of layer */
  key: string;
  /** A unique key (delivered from websockets) */
  exportKey: string;
};
export type DownloadExportedApiResponse = unknown;
export type DownloadExportedApiArg = {
  /** Namespace (organization) of project. */
  projectNamespace: string;
  /** Key of project. */
  projectKey: string;
  /** Namespace (organization) of layer */
  layerNamespace: string;
  /** Type of layer - eg. 'dataset', 'analysis', etc. */
  layerType: string;
  /** Key of layer. */
  layerKey: string;
  /** A unique key (delivered from websockets). */
  exportKey: string;
};
export type GetDynamicLayerApiResponse =
  /** status 200 DynamicLayerMetadata. */ DynamicLayerMetadata;
export type GetDynamicLayerApiArg = {
  /** Namespace (organization) of layer */
  namespace: string;
  /** Key of layer */
  key: string;
  /** Version of layer */
  version?: string;
};
export type GetDynamicLayerFilterApiResponse =
  /** status 200 Filters for dynamic layer. */ object;
export type GetDynamicLayerFilterApiArg = {
  /** Namespace (organization) of layer */
  namespace: string;
  /** Key of layer */
  key: string;
  /** Version of layer */
  version?: string;
};
export type UpdateDynamicLayerFilterApiResponse =
  /** status 200 Filter for dynamic layer. */ object;
export type UpdateDynamicLayerFilterApiArg = {
  /** Namespace (organization) of layer */
  namespace: string;
  /** Key of layer */
  key: string;
  /** Boolean filter expressions to change to. */
  body: object;
};
export type EmailVerifiedApiResponse = unknown;
export type EmailVerifiedApiArg = {
  /** email address that was verified. */
  email?: string;
  /** indicates the email verification succeeded. */
  success?: boolean;
  /** details about the email verification. */
  message?: string;
  /** invite token associated with signup. */
  inviteToken?: string;
};
export type ForgotPasswordApiResponse = unknown;
export type ForgotPasswordApiArg = {
  /** Default email address to prefill in forgot password page. */
  defaultEmail?: string;
};
export type LoadTemporaryShapesApiResponse =
  /** status 200 Successfully loaded. */ string;
export type LoadTemporaryShapesApiArg = {
  body: {
    formData: Blob;
  };
};
export type GetBuiltformAstsApiResponse =
  /** status 200 JSON representation of calculations. */ object;
export type GetBuiltformAstsApiArg = void;
export type BakeLibraryApiResponse =
  /** status 204 Baking process has started. */ undefined;
export type BakeLibraryApiArg = {
  /** Namespace (organization) of built forms library */
  namespace: string;
  /** Key of built forms library */
  key: string;
  /** Re-validate all the builtforms. */
  revalidate?: boolean;
};
export type ClearFlaskCacheApiResponse =
  /** status 200 Success of clearing the cache. */ BooleanItem;
export type ClearFlaskCacheApiArg = void;
export type CreateDemoProjectApiResponse =
  /** status 200 Key of the new project. */ ProjectReference;
export type CreateDemoProjectApiArg = {
  body: {
    namespace: string;
  };
};
export type GetDynamicLayerInternalApiResponse =
  /** status 200 DynamicLayerMetadata. */ DynamicLayerMetadata;
export type GetDynamicLayerInternalApiArg = {
  /** Namespace (organization) of layer */
  namespace: string;
  /** Key of layer */
  key: string;
  /** Version of layer */
  version?: string;
};
export type GetEntityInfoApiResponse =
  /** status 200 The resulting report */ EntityExplorerEntity;
export type GetEntityInfoApiArg = {
  /** Full Path to entity - version can be ?version= format, or delimited with a semicolon. */
  fullPath: string;
};
export type ExportUserDevicesApiResponse =
  /** status 200 The resulting file. */ Blob;
export type ExportUserDevicesApiArg = void;
export type GetAvailableFeatureFlagsApiResponse =
  /** status 200 A list of flags that can be passed back through APIs. */ string[];
export type GetAvailableFeatureFlagsApiArg = void;
export type GetLayersApiResponse =
  /** status 200 A list of layers. */ LayerReferenceList;
export type GetLayersApiArg = {
  /** Search for layers by namespace */
  namespace?: string;
  /** Search for layers by type */
  layerType?: string;
  /** Search for layers by key */
  key?: string;
};
export type GetLayerRegistryApiResponse =
  /** status 200 A list of layer registry entries. */ LayerRegistryList;
export type GetLayerRegistryApiArg = {
  /** Search for layers by namespace */
  namespace?: string;
  /** Search for layers by type */
  layerType?: string;
  /** Search for layers by key */
  key?: string;
};
export type GetLayerSummaryApiResponse =
  /** status 200 A list of layer summary entries. */ LayerSummaryList;
export type GetLayerSummaryApiArg = {
  /** Search for layers by namespace */
  namespace?: string;
  /** Search for layers by type */
  layerType?: string;
  /** Search for layers by key */
  key?: string;
  /** Search for layers by version */
  version?: string;
};
export type GetLayerMetadataApiResponse =
  /** status 200 Layer Metadata object. */ LayerMetadata;
export type GetLayerMetadataApiArg = {
  /** Namespace (organization) of layer */
  namespace: string;
  /** Type of layer - eg. 'dataset', 'analysis', etc. */
  layerType: string;
  /** Key of layer */
  key: string;
};
export type GetInternalLayerBoundsApiResponse =
  /** status 200 Layer Bounds object. */ LayerBounds;
export type GetInternalLayerBoundsApiArg = {
  /** Namespace (organization) of layer */
  namespace: string;
  /** Type of layer - eg. 'dataset', 'analysis', etc. */
  layerType: string;
  /** Key of layer */
  key: string;
  /** Version of layer */
  version?: string;
  /** JSON list of boolean expressions. Optionally gzipped and urlsafe base64 encoded */
  filters?: string;
};
export type GetInternalLayerGeoKeysApiResponse =
  /** status 200 Layer GeoData object */ LayerGeoData;
export type GetInternalLayerGeoKeysApiArg = {
  /** Namespace (organization) of layer */
  namespace: string;
  /** Type of layer - eg. 'dataset', 'analysis', etc. */
  layerType: string;
  /** Key of layer */
  key: string;
  /** Version of layer */
  version?: string;
  /** JSON list of boolean expressions. Optionally gzipped and urlsafe base64 encoded */
  filters?: string;
  /** Number of geos to return in a single response. */
  limit?: number;
  /** Any value indicates that cache should be bypassed. */
  uncached?: string;
};
export type GetInternalLayerStatsApiResponse =
  /** status 200 Layer Stats object. */ LayerStats;
export type GetInternalLayerStatsApiArg = {
  /** Namespace (organization) of layer */
  namespace: string;
  /** Type of layer - eg. 'dataset', 'analysis', etc. */
  layerType: string;
  /** Key of layer */
  key: string;
  /** Version of layer */
  version?: string;
  /** JSON list of boolean expressions. Optionally gzipped and urlsafe base64 encoded */
  filters?: string;
  /** Specifies columns to return. If empty, fetches all columns. */
  columns?: string[];
  /** If true, histograms will be calculated */
  histograms?: boolean;
  /** Name of a column to weight histogram results by. */
  histogramWeighBy?: string;
  /** Any value indicates that cache should be bypassed. */
  uncached?: string;
  /** A column to divide the results by. Useful for getting density figures, e.g. pop per acre */
  normalizeBy?: string;
  /** If true, row counts will be included. */
  rowCount?: boolean;
  /** Override the default limit to allow categorical counts to include more results. */
  maxCategoricalValues?: number;
};
export type ClearSubscriptionCacheApiResponse =
  /** status 204 Successfully cleared cache key. */ undefined;
export type ClearSubscriptionCacheApiArg = {
  /** Key of organization. */
  orgKey: string;
};
export type GetPaintedCanvasActionsInternalApiResponse =
  /** status 200 List of painting actions */ PaintingActionList;
export type GetPaintedCanvasActionsInternalApiArg = {
  /** Namespace (organization) of layer */
  namespace: string;
  /** Type of layer - eg. 'dataset', 'analysis', etc. */
  layerType: string;
  /** Key of layer */
  key: string;
  /** Filter actions by painter type. */
  painter?:
    | 'column'
    | 'row'
    | 'built_form'
    | 'undo'
    | 'redo'
    | 'revert_to_base'
    | 'delete';
  /** Filter actions by user key of creator. */
  creator?: string;
  /** Filter to only active or inactive actions. */
  active?: boolean;
  /** Maximum number of painting actions to return. */
  limit?: number;
};
export type PingApiResponse =
  /** status 204 Successfully enqueued a ping task. */ undefined;
export type PingApiArg = {
  /** Whether to notify the current session. */
  notifySession?: boolean;
  /** Whether to notify the current user. */
  notifyUser?: boolean;
  /** Which project to notify. */
  notifyProjectId?: string;
};
export type GetRunningTasksApiResponse =
  /** status 200 Successfully retrieved metadata. */ TaskList;
export type GetRunningTasksApiArg = void;
export type GetUsersApiResponse =
  /** status 200 A list of basic user info. */ InternalUserList;
export type GetUsersApiArg = void;
export type GetUserDevicesApiResponse =
  /** status 200 Successfully retrieved user devices */ UserDeviceList;
export type GetUserDevicesApiArg = void;
export type GetLayerApiResponse =
  /** status 200 Layer Metadata object. */ LayerMetadata;
export type GetLayerApiArg = {
  /** Namespace (organization) of layer */
  namespace: string;
  /** Type of layer - eg. 'dataset', 'analysis', etc. */
  layerType: string;
  /** Key of layer */
  key: string;
};
export type GetLayerBoundsApiResponse =
  /** status 200 Layer Bounds object. */ LayerBounds;
export type GetLayerBoundsApiArg = {
  /** Namespace (organization) of layer */
  namespace: string;
  /** Type of layer - eg. 'dataset', 'analysis', etc. */
  layerType: string;
  /** Key of layer */
  key: string;
  /** Version of layer */
  version?: string;
  /** JSON list of boolean expressions. Optionally gzipped and urlsafe base64 encoded */
  filters?: string;
};
export type GetLayerBreaksMultiApiResponse =
  /** status 200 Layer Breaks object. */ LayerBreaks;
export type GetLayerBreaksMultiApiArg = {
  /** Used to produce discrete buckets; value n creates n-1 buckets. */
  breakCount: number;
  /** Type of breaks to compute (e.g. linear, quantiles). */
  breakType: 'linear' | 'logarithmic' | 'quantiles' | 'natural';
  /** Specifies columns to return. If empty, fetches all columns. */
  columns?: string[];
  /** JSON list of boolean expressions. Optionally gzipped and urlsafe base64 encoded */
  filters?: string;
  /** Any value indicates that cache should be bypassed. */
  uncached?: string;
  /** A JSON encoded list of LayerReferences. */
  layers: string;
  /** Method to aggregate the results of the layers. */
  aggregationMethod?: 'first';
};
export type GetLayerBreaksApiResponse =
  /** status 200 Layer Breaks object. */ LayerBreaks;
export type GetLayerBreaksApiArg = {
  /** Namespace (organization) of layer */
  namespace: string;
  /** Type of layer - eg. 'dataset', 'analysis', etc. */
  layerType: string;
  /** Key of layer */
  key: string;
  /** Version of layer */
  version?: string;
  /** JSON list of boolean expressions. Optionally gzipped and urlsafe base64 encoded */
  filters?: string;
  /** Used to produce discrete buckets; value n creates n-1 buckets. */
  breakCount: number;
  /** Type of breaks to compute (e.g. linear, quantiles). */
  breakType: 'linear' | 'logarithmic' | 'quantiles' | 'natural';
  /** Specifies columns to return. If empty, fetches all columns. */
  columns?: string[];
  /** Any value indicates that cache should be bypassed. */
  uncached?: string;
  /** A column to divide the results by. Useful for getting density figures, e.g. pop per acre */
  normalizeBy?: string;
};
export type GetAppLayersByContextApiResponse =
  /** status 200 Layer Breaks object. */ AppLayerList;
export type GetAppLayersByContextApiArg = {
  /** The list of contexts associated with layers. */
  contexts: string[];
};
export type GetLayerDataApiResponse =
  /** status 200 Layer Properties object. */ LayerData;
export type GetLayerDataApiArg = {
  /** Namespace (organization) of layer */
  namespace: string;
  /** Type of layer - eg. 'dataset', 'analysis', etc. */
  layerType: string;
  /** Key of layer */
  key: string;
  /** Version of layer */
  version?: string;
  /** JSON list of boolean expressions. Optionally gzipped and urlsafe base64 encoded */
  filters?: string;
  /** Number of rows to return in a single response. */
  limit?: number;
  /** Column to sort by */
  sortColumn?: string;
  /** Order direction (asc/desc) */
  sortDirection?: 'asc' | 'desc';
};
export type GetLayerGeoKeysApiResponse =
  /** status 200 Layer GeoData object */ LayerGeoData;
export type GetLayerGeoKeysApiArg = {
  /** Namespace (organization) of layer */
  namespace: string;
  /** Type of layer - eg. 'dataset', 'analysis', etc. */
  layerType: string;
  /** Key of layer */
  key: string;
  /** Version of layer */
  version?: string;
  /** JSON list of boolean expressions. Optionally gzipped and urlsafe base64 encoded */
  filters?: string;
  /** Number of geos to return in a single response. */
  limit?: number;
  /** Any value indicates that cache should be bypassed. */
  uncached?: string;
};
export type GetLayerGeojsonApiResponse =
  /** status 200 GeoJSON FeatureCollection for layer. */ object;
export type GetLayerGeojsonApiArg = {
  /** Namespace (organization) of layer */
  namespace: string;
  /** Type of layer - eg. 'dataset', 'analysis', etc. */
  layerType: string;
  /** Key of layer */
  key: string;
  /** Version of layer */
  version?: string;
  /** JSON list of boolean expressions. Optionally gzipped and urlsafe base64 encoded */
  filters?: string;
  /** Number of rows to return in a single response. */
  limit?: number;
  /** Specifies columns to return. If empty, fetches all columns. */
  columns?: string[];
  /** Column to sort by */
  sortColumn?: string;
  /** Order direction (asc/desc) */
  sortDirection?: 'asc' | 'desc';
};
export type GetLayerGeometryTypeApiResponse =
  /** status 200 Layer GeometryType object. */ GeometryType;
export type GetLayerGeometryTypeApiArg = {
  /** Namespace (organization) of layer */
  namespace: string;
  /** Type of layer - eg. 'dataset', 'analysis', etc. */
  layerType: string;
  /** Key of layer */
  key: string;
};
export type GetLatestVersionApiResponse =
  /** status 200 Latest version string. */ string;
export type GetLatestVersionApiArg = {
  /** Namespace (organization) of layer */
  namespace: string;
  /** Type of layer - eg. 'dataset', 'analysis', etc. */
  layerType: string;
  /** Key of layer */
  key: string;
};
export type SetReferenceLayerApiResponse =
  /** status 200 The Celery task id, if the process launched an asynchronous Celery task */ string;
export type SetReferenceLayerApiArg = {
  /** Namespace (organization) of layer */
  namespace: string;
  /** Type of layer - eg. 'dataset', 'analysis', etc. */
  layerType: string;
  /** Key of layer */
  key: string;
  /** If true, will be set to a reference layer, if false will be set to not a reference layer */
  isReferenceLayer: boolean;
  /** If true, the layer will be listed in the layer registry for anyone to use. */
  isPublic?: boolean;
  /** If set, then the layer will be added to that org's layer registry */
  targetOrgNamespace?: string;
  /** If set, expected to be a uri to a file containing the GEOJSON representation of the layer's convex hull to use instead of calculating one based on they layer's geometries.  This should be rare. */
  convexHullGeomUri?: string;
};
export type GetLayerSpecApiResponse =
  /** status 200 Load specification yaml. */ string;
export type GetLayerSpecApiArg = {
  /** Namespace (organization) of layer */
  namespace: string;
  /** Type of layer - eg. 'dataset', 'analysis', etc. */
  layerType: string;
  /** Key of layer */
  key: string;
};
export type GetLayerStatsApiResponse =
  /** status 200 Layer Stats object. */ LayerStats;
export type GetLayerStatsApiArg = {
  /** Namespace (organization) of layer */
  namespace: string;
  /** Type of layer - eg. 'dataset', 'analysis', etc. */
  layerType: string;
  /** Key of layer */
  key: string;
  /** Version of layer */
  version?: string;
  /** JSON list of boolean expressions. Optionally gzipped and urlsafe base64 encoded */
  filters?: string;
  /** Specifies columns to return. If empty, fetches all columns. */
  columns?: string[];
  /** If true, histograms will be calculated */
  histograms?: boolean;
  /** Name of a column to weight histogram results by. */
  histogramWeighBy?: string;
  /** Any value indicates that cache should be bypassed. */
  uncached?: string;
  /** A column to divide the results by. Useful for getting density figures, e.g. pop per acre */
  normalizeBy?: string;
  /** If true, row counts will be included. */
  rowCount?: boolean;
  /** Override the default limit to allow categorical counts to include more results. */
  maxCategoricalValues?: number;
};
export type LoginApiResponse = /** status 200 login successful */ User;
export type LoginApiArg = {
  body: {
    email: string;
    password: string;
  };
};
export type LoginTokensApiResponse = /** status 204 Success. */ undefined;
export type LoginTokensApiArg = {
  body: {
    access_token: string;
    id_token: string;
  };
};
export type LogoutApiResponse = /** status 200 logout successful */ BooleanItem;
export type LogoutApiArg = void;
export type DownloadMapExportApiResponse =
  /** status 200 The resulting map. */ Blob;
export type DownloadMapExportApiArg = {
  /** Namespace (organization) of project. */
  namespace: string;
  /** Key of project. */
  key: string;
  /** A unique key (delivered form websockets) */
  exportKey: string;
};
export type EnqueueMapExportApiResponse =
  /** status 200 The uf_task_id of the async task that will render the report. */ string;
export type EnqueueMapExportApiArg = {
  /** Namespace (organization) of project. */
  namespace: string;
  /** Key of project. */
  key: string;
  mapExportRequest: MapExportRequest;
};
export type DownloadExportedColumnMetadataApiResponse = unknown;
export type DownloadExportedColumnMetadataApiArg = {
  /** Namespace (organization) of layer */
  namespace: string;
  /** Type of layer - eg. 'dataset', 'analysis', etc. */
  layerType: string;
  /** Key of layer */
  key: string;
  /** A unique key (delivered from websockets) */
  exportKey: string;
};
export type EnqueueColumnExportTaskApiResponse =
  /** status 200 The task enqueue result. */ TaskEnqueueResultItem;
export type EnqueueColumnExportTaskApiArg = {
  /** Namespace (organization) of layer */
  namespace: string;
  /** Type of layer - eg. 'dataset', 'analysis', etc. */
  layerType: string;
  /** Key of layer */
  key: string;
  /** Column summary type */
  summaryType?: string;
  body: string[];
};
export type GetOrganizationsApiResponse =
  /** status 200 List of Organizations. */ OrganizationInfoList;
export type GetOrganizationsApiArg = void;
export type DeleteOrganizationApiResponse =
  /** status 204 Successfully deleted organization (or organization does not exist). */ undefined;
export type DeleteOrganizationApiArg = {
  /** Key of organization. */
  key: string;
};
export type GetOrganizationApiResponse =
  /** status 200 Detailed information about Organization. */ OrganizationInfo;
export type GetOrganizationApiArg = {
  /** Key of organization. */
  key: string;
};
export type CreateOrganizationApiResponse =
  /** status 200 Info about newly created Organization. */ OrganizationInfo;
export type CreateOrganizationApiArg = {
  /** Key of organization. */
  key: string;
  body: {
    name?: string;
  };
};
export type GetBillingInfoApiResponse =
  /** status 200 Organization Billing Information */ BillingInfo;
export type GetBillingInfoApiArg = {
  /** Key of organization. */
  key: string;
};
export type UpdateBillingInfoApiResponse = /** status 204 Success. */ undefined;
export type UpdateBillingInfoApiArg = {
  /** Key of organization. */
  key: string;
  body: {
    recurly_token: string;
  };
};
export type GetCanvasTypesApiResponse =
  /** status 200 The canvas type keys available for new projects. */ CanvasTypeList;
export type GetCanvasTypesApiArg = {
  /** Key of organization. */
  key: string;
  /** A layer query that represents the geographic extent of a potential project area. */
  projectAreaQuery?: string;
  /** Reference to temporary shapes to be used to create project layer. */
  projectAreaShapesRef?: string;
};
export type GetOrganizationFeatureFlagsApiResponse =
  /** status 200 List of enabled feature flags. */ FeatureFlags;
export type GetOrganizationFeatureFlagsApiArg = {
  /** Key of organization. */
  key: string;
};
export type GetOrgFilesApiResponse =
  /** status 200 Success. */ OrganizationFileList;
export type GetOrgFilesApiArg = {
  /** Key of organization. */
  key: string;
};
export type GetOrganizationInsightsApiResponse =
  /** status 200 A list of insights. */ InsightCategoryList;
export type GetOrganizationInsightsApiArg = {
  /** Key of organization. */
  key: string;
};
export type UpdateOrganizationInsightsHelpUrlApiResponse =
  /** status 204 Successfully updated organization insights help url */ undefined;
export type UpdateOrganizationInsightsHelpUrlApiArg = {
  /** Key of organization. */
  key: string;
  body: {
    insights_help_url?: string;
  };
};
export type GetMembersInfoInOrganizationApiResponse =
  /** status 200 A list of basic member info. */ MemberList;
export type GetMembersInfoInOrganizationApiArg = {
  /** Key of organization. */
  key: string;
};
export type AddMemberToOrganizationApiResponse =
  /** status 204 Successfully added member */ undefined;
export type AddMemberToOrganizationApiArg = {
  /** Key of organization. */
  key: string;
  body: {
    user_email: string;
    role?: string;
  };
};
export type GetFilteredMemberCountInOrganizationApiResponse =
  /** status 200 A count of non-internal members in an organization. */ MemberCount;
export type GetFilteredMemberCountInOrganizationApiArg = {
  /** Key of organization. */
  key: string;
};
export type UpdateOrganizationNameApiResponse =
  /** status 204 Successfully updated organization */ undefined;
export type UpdateOrganizationNameApiArg = {
  /** Key of organization. */
  key: string;
  body: {
    name: string;
  };
};
export type GetProjectsInOrganizationApiResponse =
  /** status 200 A list of basic project info. */ ProjectList;
export type GetProjectsInOrganizationApiArg = {
  /** Key of organization. */
  key: string;
};
export type RequestSalesContactApiResponse =
  /** status 204 Success. */ undefined;
export type RequestSalesContactApiArg = {
  /** Key of organization. */
  key: string;
};
export type GetSubscriptionApiResponse =
  /** status 200 The subscription information for an organization. */ Subscription;
export type GetSubscriptionApiArg = {
  /** Key of organization. */
  key: string;
};
export type ChangeSubscriptionPlanApiResponse =
  /** status 200 The subscription information for an organization. */ Subscription;
export type ChangeSubscriptionPlanApiArg = {
  /** Key of organization. */
  key: string;
  /** If true, does a dry-run and returns what the subscription would look like if the seats were updated. */
  preview?: boolean;
  body: {
    plan_code: string;
    num_seats?: number;
  };
};
export type UpdateSubscriptionSeatsApiResponse =
  /** status 200 Successfully updated/previewed the number of seats. */ Subscription;
export type UpdateSubscriptionSeatsApiArg = {
  /** Key of organization. */
  key: string;
  /** If true, does a dry-run and returns what the subscription would look like if the seats were updated. */
  preview?: boolean;
  body: {
    seats: number;
  };
};
export type GetOrgFileUrlApiResponse =
  /** status 200 Success. */ OrganizationFilesListItem;
export type GetOrgFileUrlApiArg = {
  /** Key of organization. */
  key: string;
  /** Name of file */
  file: string;
  /** method for URL */
  method: 'GET' | 'PUT';
};
export type DeleteOrgDashboardApiResponse =
  /** status 204 Successfully deleted dashboard. */ undefined;
export type DeleteOrgDashboardApiArg = {
  /** Key of organization. */
  orgKey: string;
  /** The id of the Quicksight dashboard. */
  dashboardId: string;
};
export type GetDashboardEmbedApiResponse =
  /** status 200 A dashboard embed result. */ EmbeddedDashboardResult;
export type GetDashboardEmbedApiArg = {
  /** Key of organization. */
  orgKey: string;
  /** The id of the Quicksight dashboard. */
  dashboardId: string;
};
export type UpdateOrgDashboardApiResponse =
  /** status 204 Successfully updated the dashboard name. */ undefined;
export type UpdateOrgDashboardApiArg = {
  /** Key of organization. */
  orgKey: string;
  /** The id of the Quicksight dashboard. */
  dashboardId: string;
  /** The new name of the dashboard. */
  dashboard: Dashboard;
};
export type GetOrgDashboardsApiResponse =
  /** status 200 A list of project dashboards. */ ProjectDashboardList;
export type GetOrgDashboardsApiArg = {
  /** Key of organization. */
  orgKey: string;
};
export type GetInvitationsForOrganizationApiResponse =
  /** status 200 A list of pending invitations. */ InvitationList;
export type GetInvitationsForOrganizationApiArg = {
  /** Key of organization. */
  orgKey: string;
};
export type InviteUserToOrganizationApiResponse =
  /** status 204 Successfully invited member. */ undefined;
export type InviteUserToOrganizationApiArg = {
  /** Key of organization. */
  orgKey: string;
  body: {
    user_email: string;
    first_name?: string;
    last_name?: string;
    role?: string;
    resend?: boolean;
  };
};
export type CancelInvitationApiResponse =
  /** status 204 Invite successfully cancelled. */ undefined;
export type CancelInvitationApiArg = {
  /** Key of organization. */
  orgKey: string;
  /** Invite token. */
  token: string;
};
export type DeleteOrgLogoApiResponse =
  /** status 204 Successfully deleted organization logo (or it does not exist). */ undefined;
export type DeleteOrgLogoApiArg = {
  /** Key of organization. */
  orgKey: string;
  /** The logo key. */
  logoKey: string;
};
export type GetOrgLogoInfosApiResponse =
  /** status 200 Success. */ OrganizationLogoList;
export type GetOrgLogoInfosApiArg = {
  /** Key of organization. */
  orgKey: string;
};
export type AddOrgLogoApiResponse =
  /** status 200 Success. */ OrganizationLogoInfo;
export type AddOrgLogoApiArg = {
  /** Key of organization. */
  orgKey: string;
  body: {
    formData: Blob;
    name?: string;
  };
};
export type RemoveMemberFromOrganizationApiResponse =
  /** status 204 Successfully removed member */ undefined;
export type RemoveMemberFromOrganizationApiArg = {
  /** Key of organization. */
  orgKey: string;
  /** Key of user */
  userKey: string;
};
export type EditMemberInOrganizationApiResponse =
  /** status 204 Successfully updated member */ undefined;
export type EditMemberInOrganizationApiArg = {
  /** Key of organization. */
  orgKey: string;
  /** Key of user */
  userKey: string;
  body: {
    role: 'owner' | 'admin' | 'editor' | 'viewer' | 'collaborator';
  };
};
export type GetPaintedCanvasActionsV2ApiResponse =
  /** status 200 List of painting actions */ PaintingActionList;
export type GetPaintedCanvasActionsV2ApiArg = {
  /** Namespace (organization) of layer */
  namespace: string;
  /** Type of layer - eg. 'dataset', 'analysis', etc. */
  layerType: string;
  /** Key of layer */
  key: string;
  /** Filter actions by painter type. */
  painter?:
    | 'column'
    | 'row'
    | 'built_form'
    | 'undo'
    | 'redo'
    | 'revert_to_base'
    | 'delete';
  /** Filter actions by user key of creator. */
  creator?: string;
  /** Filter to only active or inactive actions. */
  active?: boolean;
  /** Maximum number of painting actions to return. */
  limit?: number;
};
export type GetPaintedCanvasActionByKeyApiResponse =
  /** status 200 Painting action matching action_key */ PaintingActionInfo[];
export type GetPaintedCanvasActionByKeyApiArg = {
  /** Namespace (organization) of layer */
  namespace: string;
  /** Type of layer - eg. 'dataset', 'analysis', etc. */
  layerType: string;
  /** Key of layer */
  key: string;
  /** Key of painting action */
  actionKey: string;
};
export type GetRevertibleActionsV2ApiResponse =
  /** status 200 Revertible actions for painted canvas. */ PaintingRevertibleActions;
export type GetRevertibleActionsV2ApiArg = {
  /** Namespace (organization) of layer */
  namespace: string;
  /** Type of layer - eg. 'dataset', 'analysis', etc. */
  layerType: string;
  /** Key of layer */
  key: string;
};
export type ListPortfoliosApiResponse =
  /** status 200 The portfolios. */ PortfolioInfo[];
export type ListPortfoliosApiArg = {
  /** If true, include deleted portfolios in results. */
  includeDeleted?: boolean;
  /** A search term used for filtering portfolios. */
  search?: string;
};
export type CreatePortfolioApiResponse =
  /** status 200 The created portfolio. */ PortfolioInfo;
export type CreatePortfolioApiArg = {
  body: {
    key?: string;
    name?: string;
  };
};
export type DeletePortfolioApiResponse = /** status 204 Success. */ undefined;
export type DeletePortfolioApiArg = {
  /** The portfolio key. */
  key: string;
};
export type GetPortfolioApiResponse =
  /** status 200 The portfolio. */ PortfolioInfo;
export type GetPortfolioApiArg = {
  /** The portfolio key. */
  key: string;
  /** If true, return the portfolio even if it is deleted. */
  includeDeleted?: boolean;
};
export type UpdatePortfolioApiResponse =
  /** status 204 Successfully added org to portfolio. */ undefined;
export type UpdatePortfolioApiArg = {
  /** The portfolio key. */
  key: string;
  /** The updated portfolio. */
  portfolioInfo: PortfolioInfo;
};
export type AddOrgToPortfolioApiResponse =
  /** status 204 Successfully added org to portfolio. */ undefined;
export type AddOrgToPortfolioApiArg = {
  /** The portfolio key. */
  portfolioKey: string;
  body: {
    org_key: string;
  };
};
export type GetProgramLevelSummaryApiResponse =
  /** status 200 Full summary. */ ProgramLevelSummary;
export type GetProgramLevelSummaryApiArg = {
  /** Namespace (organization) of layer */
  namespace: string;
  /** Type of layer - eg. 'dataset', 'analysis', etc. */
  layerType: string;
  /** Key of layer */
  key: string;
  /** Version of layer */
  version: string;
};
export type GetProjectsApiResponse =
  /** status 200 A list of basic project info. */ ProjectList;
export type GetProjectsApiArg = void;
export type CreateProjectApiResponse =
  /** status 200 Create a project. */ ProjectMetadata;
export type CreateProjectApiArg = {
  body: {
    namespace: string;
    key: string;
    name: string;
    context_area_query?: string;
    project_area_query?: string;
    description?: string;
    canvas_type?: 'block' | 'parcel';
    context_canvas_type?: 'block' | 'parcel';
    project_area_shapes_ref?: string;
    project_type?: string;
    role?: 'standard' | 'explorer';
  };
};
export type SetProjectLayerAnalysisRunDashboardApiResponse =
  /** status 200 Successfully set dashboard. */ undefined;
export type SetProjectLayerAnalysisRunDashboardApiArg = {
  /** Task ID. */
  key: string;
};
export type UpdateProjectLayerAnalysisRunDashboardApiResponse =
  /** status 204 Successfully updated the dashboard name. */ undefined;
export type UpdateProjectLayerAnalysisRunDashboardApiArg = {
  /** Task ID. */
  key: string;
};
export type DeleteProjectLayerAnalysisRunDashboardApiResponse =
  /** status 204 Successfully deleted dashboard. */ undefined;
export type DeleteProjectLayerAnalysisRunDashboardApiArg = {
  /** Task ID. */
  key: string;
  /** Dashboard ID. */
  id: string;
};
export type SetProjectLayerAnalysisRunOutputTableApiResponse =
  /** status 200 Successfully set result URI(s). */ undefined;
export type SetProjectLayerAnalysisRunOutputTableApiArg = {
  /** Task ID. */
  key: string;
};
export type SetProjectLayerAnalysisRunDashboardModelApiResponse =
  /** status 200 Successfully set status. */ undefined;
export type SetProjectLayerAnalysisRunDashboardModelApiArg = {
  /** Task ID. */
  key: string;
};
export type SetProjectLayerAnalysisRunStatusApiResponse =
  /** status 200 Successfully set status. */ undefined;
export type SetProjectLayerAnalysisRunStatusApiArg = {
  /** Task ID. */
  key: string;
  /** Task status. */
  status:
    | 'created'
    | 'running'
    | 'processed'
    | 'loading'
    | 'complete'
    | 'error'
    | 'cancelled';
};
export type DeleteProjectApiResponse =
  /** status 204 Successfully deleted project. */ undefined;
export type DeleteProjectApiArg = {
  /** Namespace (organization) of project. */
  namespace: string;
  /** Key of project. */
  key: string;
};
export type GetProjectApiResponse =
  /** status 200 Project metadata. */ ProjectMetadata;
export type GetProjectApiArg = {
  /** Namespace (organization) of project. */
  namespace: string;
  /** Key of project. */
  key: string;
};
export type EditProjectApiResponse =
  /** status 204 Successfully updated project. */ undefined;
export type EditProjectApiArg = {
  /** Namespace (organization) of project. */
  namespace: string;
  /** Key of project. */
  key: string;
  body: {
    name?: string;
    description?: string;
  };
};
export type GetAnalysisModulesApiResponse =
  /** status 200 The list of module keys. */ string[];
export type GetAnalysisModulesApiArg = {
  /** Namespace (organization) of project. */
  namespace: string;
  /** Key of project. */
  key: string;
};
export type GetAnalysisModuleApiResponse =
  /** status 200 The analysis module metadata. */ AnalysisModuleInfo;
export type GetAnalysisModuleApiArg = {
  /** Namespace (organization) of project. */
  namespace: string;
  /** Key of project. */
  key: string;
  /** Key of analysis module */
  moduleKey: string;
};
export type RunAnalysisModuleForProjectApiResponse =
  /** status 200 The uf_task_id of the async task that will run the analysis module. */ string;
export type RunAnalysisModuleForProjectApiArg = {
  /** Namespace (organization) of project. */
  namespace: string;
  /** Key of project. */
  key: string;
  /** the key of the analysis module to execute */
  module: string;
  /** The path of the scenario to run. */
  scenario: string;
};
export type GetRunningProjectLayerAnalysisRunsApiResponse =
  /** status 200 The list of running task keys. */ string[];
export type GetRunningProjectLayerAnalysisRunsApiArg = {
  /** Namespace (organization) of project. */
  namespace: string;
  /** Key of project. */
  key: string;
};
export type GetProjectAnalysisParamsApiResponse =
  /** status 200 The scenario analysis params. */ ProjectModuleParams;
export type GetProjectAnalysisParamsApiArg = {
  /** Namespace (organization) of project. */
  namespace: string;
  /** Key of project. */
  key: string;
  /** Key of analysis module */
  moduleKey: string;
  scenarioKey?: string;
};
export type GetProjectAvailableLayersApiResponse =
  /** status 200 All layers that are available to the project. */ ProjectAvailableLayers;
export type GetProjectAvailableLayersApiArg = {
  /** Namespace (organization) of project. */
  namespace: string;
  /** Key of project. */
  key: string;
};
export type EnqueuePaintBaseScenarioCanvasApiResponse =
  /** status 202 Task ID of painting task to be applied. */ string;
export type EnqueuePaintBaseScenarioCanvasApiArg = {
  /** Namespace (organization) of project. */
  namespace: string;
  /** Key of project. */
  key: string;
  body: {
    painter:
      | 'column'
      | 'row'
      | 'built_form'
      | 'undo'
      | 'redo'
      | 'revert_to_base'
      | 'delete'
      | 'split_canvas';
    params: string;
    filters?: string;
    display_hints?: string;
  };
};
export type EnqueueProjectBufferedLayerTaskApiResponse =
  /** status 200 The uf_task_id of the async task that will create the buffered layer. */ string;
export type EnqueueProjectBufferedLayerTaskApiArg = {
  /** Namespace (organization) of project. */
  namespace: string;
  /** Key of project. */
  key: string;
  body: {
    parent: string;
    parent_version?: string;
    filter?: string;
    buffer_distance: number;
    buffer_units: string;
    buffer_srid?: number;
    name?: string;
    description?: string;
  };
};
export type EnqueueProjectConvertLayerTaskApiResponse =
  /** status 200 The uf_task_id of the async task that will create the new layer. */ string;
export type EnqueueProjectConvertLayerTaskApiArg = {
  /** Namespace (organization) of project. */
  namespace: string;
  /** Key of project. */
  key: string;
  body: {
    parent: string;
    conversion_method: 'point_on_surface';
    parent_version?: string;
    filter?: string;
    name?: string;
    description?: string;
  };
};
export type EnqueueCreateBaseCanvasTaskApiResponse =
  /** status 200 The uf_task_id of the async task that will create the canvas. */ string;
export type EnqueueCreateBaseCanvasTaskApiArg = {
  /** Namespace (organization) of project. */
  namespace: string;
  /** Key of project. */
  key: string;
  body: {
    canvas_type?: 'block' | 'parcel';
    project_base_canvas_ref?: string;
    built_forms_library_path?: string;
    intersection_method?: 'centroid' | 'boundary';
  };
};
export type EnqueueCreateContextCanvasTaskApiResponse =
  /** status 200 The uf_task_id of the async task that will create the canvas. */ string;
export type EnqueueCreateContextCanvasTaskApiArg = {
  /** Namespace (organization) of project. */
  namespace: string;
  /** Key of project. */
  key: string;
  body: {
    canvas_type?: 'block' | 'parcel';
    intersection_method?: 'centroid' | 'boundary';
  };
};
export type CreateProjectDynamicLayerApiResponse =
  /** status 200 Successfully created new Dynamic Layer in project. */ LayerReference;
export type CreateProjectDynamicLayerApiArg = {
  /** Namespace (organization) of project. */
  namespace: string;
  /** Key of project. */
  key: string;
  body: {
    parent: string;
    parent_version?: string;
    filter?: string;
    name?: string;
    description?: string;
  };
};
export type EnqueuePaintScenarioCanvasApiResponse =
  /** status 202 Task ID of painting task to be applied. */ string;
export type EnqueuePaintScenarioCanvasApiArg = {
  /** Namespace (organization) of project. */
  namespace: string;
  /** Key of project. */
  key: string;
  /** Key of scenario. */
  scenarioKey: string;
  body: {
    painter:
      | 'column'
      | 'row'
      | 'built_form'
      | 'undo'
      | 'redo'
      | 'revert_to_base';
    params: string;
    filters?: string;
    display_hints?: string;
  };
};
export type ExportModuleParamsApiResponse =
  /** status 200 The exported parameters, in .xls format. */ Blob;
export type ExportModuleParamsApiArg = {
  /** Namespace (organization) of project. */
  namespace: string;
  /** Key of project. */
  key: string;
  /** Key of analysis module */
  moduleKey: string;
};
export type RemoveFromLayerListApiResponse =
  /** status 204 Successfully removed layer. */ undefined;
export type RemoveFromLayerListApiArg = {
  /** Namespace (organization) of project. */
  namespace: string;
  /** Key of project. */
  key: string;
  /** the layer path. */
  layerPath?: string;
  /** the analysis module key */
  moduleKey?: string;
  /** the analysis module output layer key */
  moduleOutputKey?: string;
};
export type AddToLayerListApiResponse =
  /** status 204 Successfully added layer. */ undefined;
export type AddToLayerListApiArg = {
  /** Namespace (organization) of project. */
  namespace: string;
  /** Key of project. */
  key: string;
  /** the layer path. */
  layerPath?: string;
  /** the analysis module key */
  moduleKey?: string;
  /** the analysis module output layer key */
  moduleOutputKey?: string;
};
export type UpdateScenarioAnalysisParamsApiResponse =
  /** status 200 Validation errors. */
  AnalysisParameterValidationErrorList | /** status 204 Success. */ undefined;
export type UpdateScenarioAnalysisParamsApiArg = {
  /** Namespace (organization) of project. */
  namespace: string;
  /** Key of project. */
  key: string;
  /** Key of scenario. */
  scenarioKey: string;
  preview?: boolean;
  analysisModuleParameters: AnalysisModuleParameters;
};
export type CreateScenarioApiResponse = /** status 204 Success */ undefined;
export type CreateScenarioApiArg = {
  /** Namespace (organization) of project. */
  namespace: string;
  /** Key of project. */
  key: string;
  body: {
    scenario_key: string;
    name: string;
    description: string;
    base_scenario?: string;
  };
};
export type EditScenarioApiResponse = /** status 204 Success. */ undefined;
export type EditScenarioApiArg = {
  /** Namespace (organization) of project. */
  namespace: string;
  /** Key of project. */
  key: string;
  /** Key of scenario. */
  scenarioKey: string;
  body: {
    name: string;
    description?: string;
  };
};
export type GetScenarioUpdateInProgressApiResponse =
  /** status 200 Success. */ ScenarioUpdate;
export type GetScenarioUpdateInProgressApiArg = {
  /** Namespace (organization) of project. */
  namespace: string;
  /** Key of project. */
  key: string;
  /** Key of scenario. */
  scenarioKey: string;
};
export type GetProjectTasksApiResponse =
  /** status 200 Successfully retrieved metadata. */ TaskList;
export type GetProjectTasksApiArg = {
  /** Namespace (organization) of project. */
  namespace: string;
  /** Key of project. */
  key: string;
};
export type GetProjectUpdateInProgressApiResponse =
  /** status 200 Success. */ ProjectUpdate;
export type GetProjectUpdateInProgressApiArg = {
  /** Namespace (organization) of project. */
  namespace: string;
  /** Key of project. */
  key: string;
};
export type GetProjectWorkingLayersApiResponse =
  /** status 200 List of working layers in project. */ LayerList;
export type GetProjectWorkingLayersApiArg = {
  /** Namespace (organization) of project. */
  namespace: string;
  /** Key of project. */
  key: string;
};
export type GetAnalysisOutputModuleMetadataApiResponse =
  /** status 200 Successfully retrieved metadata. */ AnalysisOutputLayerMetadata;
export type GetAnalysisOutputModuleMetadataApiArg = {
  /** Namespace (organization) of project. */
  namespace: string;
  /** Key of project. */
  projectKey: string;
  /** Key of analysis module */
  moduleKey: string;
  /** the key of the output module in the analysis module */
  outputModuleKey: string;
};
export type DeleteScenarioApiResponse = /** status 204 Success. */ undefined;
export type DeleteScenarioApiArg = {
  /** Namespace (organization) of scenario. */
  namespace: string;
  /** Key of scenario. */
  key: string;
};
export type RunLayerServiceForProjectApiResponse =
  /** status 200 The uf_task_id of the async task that will create the new layer. */ string;
export type RunLayerServiceForProjectApiArg = {
  /** Namespace (organization) of project. */
  projectNamespace: string;
  /** Key of project. */
  projectKey: string;
  /** Namespace (organization) of layer */
  layerNamespace: string;
  /** Type of layer - eg. 'dataset', 'analysis', etc. */
  layerType: string;
  /** Key of layer. */
  layerKey: string;
  /** The parameters necessary to run LAS. */
  projectLayerAnalysisRunParameter: ProjectLayerAnalysisRunParameter;
};
export type EnqueueLayerExportTaskApiResponse =
  /** status 200 The task enqueue result. */ TaskEnqueueResultItem;
export type EnqueueLayerExportTaskApiArg = {
  /** Namespace (organization) of project. */
  projectNamespace: string;
  /** Key of project. */
  projectKey: string;
  /** Full path to the layer */
  layerPath?: string;
  /** The version of the layer data */
  version?: string;
  /** JSON list of boolean expressions. Optionally gzipped and urlsafe base64 encoded */
  filters?: string;
  /** a key representing the file format to return */
  fileFormat: 'csv' | 'shp' | 'svg' | 'gpkg' | 'geojson' | 'shp_split';
  /** include geometries with export */
  includeGeometries?: boolean;
  /** column to export */
  column?: string;
  body: string[];
};
export type UpdateLayerNameApiResponse =
  /** status 204 Successfully updated layer */ undefined;
export type UpdateLayerNameApiArg = {
  /** Namespace (organization) of project. */
  projectNamespace: string;
  /** Key of project. */
  projectKey: string;
  /** Namespace (organization) of layer */
  layerNamespace: string;
  /** Type of layer - eg. 'dataset', 'analysis', etc. */
  layerType: string;
  /** Key of layer. */
  layerKey: string;
  body: {
    name: string;
  };
};
export type DeleteSymbologyApiResponse = /** status 204 Success. */ undefined;
export type DeleteSymbologyApiArg = {
  /** Namespace (organization) of project. */
  projectNamespace: string;
  /** Key of project. */
  projectKey: string;
  /** Namespace (organization) of layer */
  layerNamespace: string;
  /** Type of layer - eg. 'dataset', 'analysis', etc. */
  layerType: string;
  /** Key of layer. */
  layerKey: string;
  /** Key of view */
  viewKey: string;
  /** Key of symbology column */
  columnKey: string;
  /** Key of symbology divide by column */
  divideByKey?: string;
};
export type GetSymbologyApiResponse =
  /** status 200 A JSON symbology representation. */ Symbology;
export type GetSymbologyApiArg = {
  /** Namespace (organization) of project. */
  projectNamespace: string;
  /** Key of project. */
  projectKey: string;
  /** Namespace (organization) of layer */
  layerNamespace: string;
  /** Type of layer - eg. 'dataset', 'analysis', etc. */
  layerType: string;
  /** Key of layer. */
  layerKey: string;
  /** Key of view */
  viewKey: string;
  /** Key of symbology column */
  columnKey: string;
  /** Key of symbology divide by column */
  divideByKey?: string;
};
export type AddSymbologyApiResponse = /** status 204 Success. */ undefined;
export type AddSymbologyApiArg = {
  /** Namespace (organization) of project. */
  projectNamespace: string;
  /** Key of project. */
  projectKey: string;
  /** Namespace (organization) of layer */
  layerNamespace: string;
  /** Type of layer - eg. 'dataset', 'analysis', etc. */
  layerType: string;
  /** Key of layer. */
  layerKey: string;
  /** Key of view */
  viewKey: string;
  /** Key of symbology column */
  columnKey: string;
  /** Key of symbology divide by column */
  divideByKey?: string;
  /** A JSON symbology representation. */
  symbology: Symbology;
};
export type EnqueueImportLayerApiResponse =
  /** status 200 The uf_task_id of the async task that will create the buffered layer. */ string;
export type EnqueueImportLayerApiArg = {
  /** Namespace (organization) of project. */
  projectNamespace: string;
  /** Key of project. */
  projectKey: string;
  body: {
    formData?: Blob;
    name?: string;
  };
};
export type RemoveProjectWorkingLayerApiResponse =
  /** status 204 Successfully removed working layer from project. */ undefined;
export type RemoveProjectWorkingLayerApiArg = {
  /** Namespace (organization) of project. */
  projectNamespace: string;
  /** Key of project. */
  projectKey: string;
  /** Namespace (organization) of layer */
  layerNamespace: string;
  /** Type of layer - eg. 'dataset', 'analysis', etc. */
  layerType: string;
  /** Key of layer. */
  layerKey: string;
};
export type AddProjectWorkingLayerApiResponse =
  /** status 200 Successfully added working layer to project. */ LayerReference;
export type AddProjectWorkingLayerApiArg = {
  /** Namespace (organization) of project. */
  projectNamespace: string;
  /** Key of project. */
  projectKey: string;
  /** Namespace (organization) of layer */
  layerNamespace: string;
  /** Type of layer - eg. 'dataset', 'analysis', etc. */
  layerType: string;
  /** Key of layer. */
  layerKey: string;
};
export type AddProjectTypeIconApiResponse =
  /** status 200 A list of all layers for a project type. */ ProjectTypeIconInfo;
export type AddProjectTypeIconApiArg = {
  /** Key of the associated project type. */
  projectTypeKey: string;
  /** The name of the icon. */
  name?: string;
  /** The image mimetype */
  mimetype?:
    | 'image/jpeg'
    | 'image/svg+xml'
    | 'image/png'
    | 'image/gif'
    | 'image/webp';
  /** The image filename. */
  filename?: string;
  body: {
    formData: Blob;
  };
};
export type GetProjectTypeLayersApiResponse =
  /** status 200 A list of all layers for a project type. */ ProjectTypeLayerMetadataList;
export type GetProjectTypeLayersApiArg = {
  /** Key of the associated project type. */
  projectTypeKey: string;
  /** Key of the associated organization. */
  orgKey?: string;
};
export type GetProjectTypesApiResponse =
  /** status 200 A list of all the project types. */ ProjectTypeList;
export type GetProjectTypesApiArg = {
  /** Key of the associated organization. */
  orgKey?: string;
};
export type GetProjectsV2ApiResponse =
  /** status 200 A list of items containing organization names/keys and projects for that org. */ OrganizationProjectsList;
export type GetProjectsV2ApiArg = void;
export type GetReportApiResponse =
  /** status 200 The resulting report */ ReportResults;
export type GetReportApiArg = {
  /** Namespace (organization) of project. */
  namespace: string;
  /** Key of project. */
  key: string;
  /** List of scenario paths to run this report against. */
  scenarioPaths: string[];
  reportSpec: ReportSpec;
};
export type GetReportXlsApiResponse =
  /** status 200 The resulting report in excel (.xls) format. */ Blob;
export type GetReportXlsApiArg = {
  /** Namespace (organization) of project. */
  namespace: string;
  /** Key of project. */
  key: string;
  /** List of scenario paths to run this report against. */
  scenarioPaths: string[];
  reportSpec: ReportSpec;
};
export type GetUserSettingsApiResponse =
  /** status 200 Get everything the client needs to quickly setting a view */ UserSetting;
export type GetUserSettingsApiArg = void;
export type UpdateGlobalSettingApiResponse =
  /** status 200 Successfully updated */ UserSetting;
export type UpdateGlobalSettingApiArg = {
  /** The global-level settings. */
  userGlobalSetting: UserGlobalSetting;
};
export type UpdateOrgSettingApiResponse =
  /** status 200 Successfully updated */ UserSetting;
export type UpdateOrgSettingApiArg = {
  /** Key of organization. */
  orgKey: string;
  /** The org-level settings. */
  userOrgSetting: UserOrgSetting;
};
export type UpdateProjectSettingApiResponse =
  /** status 200 Successfully updated */ UserSetting;
export type UpdateProjectSettingApiArg = {
  /** Namespace (organization) of project. */
  projectNamespace: string;
  /** Key of project. */
  projectKey: string;
  /** The project level settings */
  body: object;
};
export type SigninApiResponse = unknown;
export type SigninApiArg = {
  /** Default email address to prefill in signin page. */
  defaultEmail?: string;
  /** Where to redirect to after authentication. */
  redirectTo?: string;
};
export type SignoutApiResponse = unknown;
export type SignoutApiArg = {
  /** Path to redirect to after signing out. */
  redirectPath?: string;
  /** Full url to redirect to after signing out. */
  redirectTo?: string;
};
export type GetTopicEmbedApiResponse =
  /** status 200 A topic embed result. */ EmbeddedTopicResult;
export type GetTopicEmbedApiArg = {
  /** The id of the Quicksight topic. */
  topicId: string;
};
export type ListUserPortfoliosApiResponse =
  /** status 200 The user portfolios. */ PortfolioInfo[];
export type ListUserPortfoliosApiArg = {
  /** Key of user */
  userKey: string;
};
export type DeleteUserDataApiResponse = /** status 204 Success */ undefined;
export type DeleteUserDataApiArg = {
  /** Key of user */
  userKey: string;
  /** Key of the user data. */
  key: string;
};
export type GetUserDataApiResponse =
  /** status 200 User data object. */ UserData;
export type GetUserDataApiArg = {
  /** Key of user */
  userKey: string;
  /** Key of the user data. */
  key: string;
};
export type SetUserDataApiResponse = /** status 204 Success */ undefined;
export type SetUserDataApiArg = {
  /** Key of user */
  userKey: string;
  /** Key of the user data. */
  key: string;
  /** The data to set. */
  body: object;
};
export type ListFavoriteLayersApiResponse =
  /** status 200 List of favorite layers for user. */ LayerList;
export type ListFavoriteLayersApiArg = {
  /** Key of user */
  userKey: string;
};
export type DeleteLayerFromFavoritesApiResponse =
  /** status 204 Success */ undefined;
export type DeleteLayerFromFavoritesApiArg = {
  /** Key of user */
  userKey: string;
  /** Namespace (organization) of layer */
  layerNamespace: string;
  /** Type of layer - eg. 'dataset', 'analysis', etc. */
  layerType: string;
  /** Key of layer. */
  layerKey: string;
};
export type AddLayerToFavoritesApiResponse =
  /** status 204 Successfully added layer. */ undefined;
export type AddLayerToFavoritesApiArg = {
  /** Key of user */
  userKey: string;
  /** Namespace (organization) of layer */
  layerNamespace: string;
  /** Type of layer - eg. 'dataset', 'analysis', etc. */
  layerType: string;
  /** Key of layer. */
  layerKey: string;
};
export type ListUserDataKeysApiResponse =
  /** status 200 User data object. */ string[];
export type ListUserDataKeysApiArg = {
  /** Key of user */
  userKey: string;
  /** Prefix to find all keys starting with this string. If left out, returns all keys. */
  prefix?: string;
};
export type ListUserDataItemsApiResponse =
  /** status 200 An array of user data objects. */ UserDataList;
export type ListUserDataItemsApiArg = {
  /** Key of user */
  userKey: string;
  /** Prefix to find all keys starting with this string. If left out, returns all keys. */
  prefix?: string;
};
export type GetVersionApiResponse = /** status 200 Success. */ AppVersion;
export type GetVersionApiArg = void;
export type ProblemDetail = {
  detail?: string;
  instance?: string;
  status: number;
  title: string;
  type?: string;
};
export type BuiltFormItemWeight = {
  blend_pct?: number;
  key?: string;
  name?: string;
  used?: boolean;
  valid?: boolean;
};
export type PlaceTypeInfo = {
  key: string;
  library_path: string;
  name: string;
  namespace: string;
  style?: object;
  used?: boolean;
  valid?: boolean;
};
export type BuiltFormValidationError = {
  code?: 'allocation' | 'empty' | 'invalid_subcomponents';
  field?: string;
  params?: object;
};
export type BuildingType = {
  area_parcel?: number;
  area_parcel_agriculture?: number;
  area_parcel_allocation_error?: number;
  area_parcel_allocation_error_pct?: number;
  area_parcel_civic_institutional?: number;
  area_parcel_commercial?: number;
  area_parcel_fine?: number;
  area_parcel_industrial?: number;
  area_parcel_mixed_use?: number;
  area_parcel_natural_conservation?: number;
  area_parcel_natural_resources?: number;
  area_parcel_open_space?: number;
  area_parcel_other?: number;
  area_parcel_res?: number;
  area_parcel_res_acc?: number;
  area_parcel_res_attsf?: number;
  area_parcel_res_detsf_ll?: number;
  area_parcel_res_detsf_sl?: number;
  area_parcel_res_mf?: number;
  area_parcel_residential?: number;
  area_parcel_transportation_utilities?: number;
  area_parcel_water?: number;
  area_parcel_with_residential?: number;
  area_park?: number;
  area_per_emp?: number;
  area_per_emp_ag?: number;
  area_per_emp_ag_agriculture?: number;
  area_per_emp_ag_extraction?: number;
  area_per_emp_ind?: number;
  area_per_emp_ind_construction?: number;
  area_per_emp_ind_manufacturing?: number;
  area_per_emp_ind_transport_warehousing?: number;
  area_per_emp_ind_utilities?: number;
  area_per_emp_ind_wholesale?: number;
  area_per_emp_military?: number;
  area_per_emp_off?: number;
  area_per_emp_off_medical_services?: number;
  area_per_emp_off_office_services?: number;
  area_per_emp_pub?: number;
  area_per_emp_pub_education?: number;
  area_per_emp_pub_public_admin?: number;
  area_per_emp_ret?: number;
  area_per_emp_ret_accommodation?: number;
  area_per_emp_ret_arts_entertainment?: number;
  area_per_emp_ret_other_services?: number;
  area_per_emp_ret_restaurant?: number;
  area_per_emp_ret_retail_services?: number;
  avg_unit_area_acc?: number;
  avg_unit_area_attsf?: number;
  avg_unit_area_detsf_ll?: number;
  avg_unit_area_detsf_sl?: number;
  avg_unit_area_mf?: number;
  bldg_area_acc?: number;
  bldg_area_accommodation?: number;
  bldg_area_accommodation_pct?: number;
  bldg_area_ag?: number;
  bldg_area_ag_pct?: number;
  bldg_area_agriculture?: number;
  bldg_area_agriculture_pct?: number;
  bldg_area_allocation_error?: number;
  bldg_area_allocation_error_pct?: number;
  bldg_area_arts_entertainment?: number;
  bldg_area_arts_entertainment_pct?: number;
  bldg_area_attsf?: number;
  bldg_area_belowground?: number;
  bldg_area_construction?: number;
  bldg_area_construction_pct?: number;
  bldg_area_detsf_ll?: number;
  bldg_area_detsf_sl?: number;
  bldg_area_education?: number;
  bldg_area_education_pct?: number;
  bldg_area_emp_allocation_error?: number;
  bldg_area_emp_allocation_error_pct?: number;
  bldg_area_extraction?: number;
  bldg_area_extraction_pct?: number;
  bldg_area_footprint?: number;
  bldg_area_footprint_area_parcel_pct?: number;
  bldg_area_ind?: number;
  bldg_area_ind_pct?: number;
  bldg_area_manufacturing?: number;
  bldg_area_manufacturing_pct?: number;
  bldg_area_medical_services?: number;
  bldg_area_medical_services_pct?: number;
  bldg_area_mf?: number;
  bldg_area_military?: number;
  bldg_area_military_pct?: number;
  bldg_area_nonres?: number;
  bldg_area_nonres_pct?: number;
  bldg_area_nonresidential_net?: number;
  bldg_area_off?: number;
  bldg_area_off_pct?: number;
  bldg_area_office_services?: number;
  bldg_area_office_services_pct?: number;
  bldg_area_other_services?: number;
  bldg_area_other_services_pct?: number;
  bldg_area_pub?: number;
  bldg_area_pub_pct?: number;
  bldg_area_public_admin?: number;
  bldg_area_public_admin_pct?: number;
  bldg_area_res?: number;
  bldg_area_res_pct?: number;
  bldg_area_res_type_allocation_error?: number;
  bldg_area_res_type_allocation_error_pct?: number;
  bldg_area_residential_net?: number;
  bldg_area_restaurant?: number;
  bldg_area_restaurant_pct?: number;
  bldg_area_ret?: number;
  bldg_area_ret_pct?: number;
  bldg_area_retail_services?: number;
  bldg_area_retail_services_pct?: number;
  bldg_area_total?: number;
  bldg_area_total_net?: number;
  bldg_area_transport_warehousing?: number;
  bldg_area_transport_warehousing_pct?: number;
  bldg_area_utilities?: number;
  bldg_area_utilities_pct?: number;
  bldg_area_wholesale?: number;
  bldg_area_wholesale_pct?: number;
  bldg_efficiency_ratio_nonresidential?: number;
  bldg_efficiency_ratio_residential?: number;
  bldg_height?: number;
  blend_allocation_error?: number;
  buildings?: BuiltFormItemWeight[];
  created?: string;
  creator?: string;
  description?: string;
  du?: number;
  du_acc?: number;
  du_attsf?: number;
  du_detsf_ll?: number;
  du_detsf_sl?: number;
  du_mf?: number;
  emp?: number;
  emp_accommodation?: number;
  emp_ag?: number;
  emp_ag_agriculture_pct?: number;
  emp_ag_extraction_pct?: number;
  emp_ag_pct?: number;
  emp_ag_pct_allocation_error?: number;
  emp_agriculture?: number;
  emp_arts_entertainment?: number;
  emp_construction?: number;
  emp_education?: number;
  emp_extraction?: number;
  emp_ind?: number;
  emp_ind_construction_pct?: number;
  emp_ind_manufacturing_pct?: number;
  emp_ind_pct?: number;
  emp_ind_pct_allocation_error?: number;
  emp_ind_transport_warehousing_pct?: number;
  emp_ind_utilities_pct?: number;
  emp_ind_wholesale_pct?: number;
  emp_manufacturing?: number;
  emp_medical_services?: number;
  emp_military?: number;
  emp_military_pct?: number;
  emp_off?: number;
  emp_off_medical_services_pct?: number;
  emp_off_office_services_pct?: number;
  emp_off_pct?: number;
  emp_off_pct_allocation_error?: number;
  emp_office_services?: number;
  emp_other_services?: number;
  emp_pub?: number;
  emp_pub_education_pct?: number;
  emp_pub_pct?: number;
  emp_pub_pct_allocation_error?: number;
  emp_pub_public_admin_pct?: number;
  emp_public_admin?: number;
  emp_restaurant?: number;
  emp_ret?: number;
  emp_ret_accommodation_pct?: number;
  emp_ret_arts_entertainment_pct?: number;
  emp_ret_other_services_pct?: number;
  emp_ret_pct?: number;
  emp_ret_pct_allocation_error?: number;
  emp_ret_restaurant_pct?: number;
  emp_ret_retail_services_pct?: number;
  emp_retail_services?: number;
  emp_transport_warehousing?: number;
  emp_utilities?: number;
  emp_wholesale?: number;
  far_building?: number;
  far_building_nonresidential?: number;
  far_building_residential?: number;
  far_parking?: number;
  far_total?: number;
  floor_count?: number;
  floor_height?: number;
  hardscape_area_other?: number;
  hardscape_area_other_area_parcel_pct?: number;
  hardscape_area_total?: number;
  hh?: number;
  internal_parking_stall_size?: number;
  irrigated_area?: number;
  irrigated_area_nonresidential?: number;
  irrigated_area_nonresidential_pct?: number;
  irrigated_area_residential?: number;
  irrigated_area_residential_pct?: number;
  is_agriculture?: number;
  is_civic_institutional?: number;
  is_commercial?: number;
  is_employment_density_high?: number;
  is_employment_density_low?: number;
  is_employment_density_mid?: number;
  is_industrial?: number;
  is_mixed_use?: number;
  is_natural_conservation?: number;
  is_natural_resources?: number;
  is_open_space?: number;
  is_other?: number;
  is_residential?: number;
  is_transportation_utilities?: number;
  is_water?: number;
  key?: string;
  land_use_category_level_1?: string;
  land_use_category_level_2?: string;
  land_use_category_level_3?:
    | 'mixed_use'
    | 'mixed_use_residential'
    | 'mixed_use_commercial'
    | 'mixed_use_urban'
    | 'residential'
    | 'single_family_detached'
    | 'single_family_attached'
    | 'multifamily'
    | 'group_quarters'
    | 'commercial'
    | 'retail_services'
    | 'commercial_centers'
    | 'accommodation'
    | 'commercial_other'
    | 'office'
    | 'commercial_recreation'
    | 'industrial'
    | 'wholesale_warehousing'
    | 'light_industrial'
    | 'heavy_industrial'
    | 'civic_institutional'
    | 'civic_facilities'
    | 'special_use'
    | 'emergency_services'
    | 'primary_secondary_education'
    | 'higher_education'
    | 'hospitals'
    | 'military'
    | 'other_institutional'
    | 'transportation_utilities'
    | 'transportation'
    | 'utilities_communications'
    | 'parks_recreation'
    | 'golf_courses'
    | 'cemeteries'
    | 'open_space'
    | 'vacant'
    | 'other'
    | 'agriculture'
    | 'natural_resources'
    | 'forestry'
    | 'extraction'
    | 'natural_conservation'
    | 'water';
  library_path?: string;
  name?: string;
  namespace?: string;
  nonhardscape_area?: number;
  nonhardscape_area_area_parcel_pct?: number;
  nonhardscape_area_irrigated_pct?: number;
  parking_area_internal?: number;
  parking_area_structured?: number;
  parking_area_structured_footprint?: number;
  parking_area_structured_footprint_area_parcel_pct?: number;
  parking_area_surface?: number;
  parking_area_surface_area_parcel_pct?: number;
  parking_spaces_internal?: number;
  parking_spaces_structured?: number;
  parking_spaces_surface?: number;
  parking_spaces_total?: number;
  place_types?: PlaceTypeInfo[];
  pop?: number;
  pop_groupquarter?: number;
  structured_parking_stall_size?: number;
  style?: object;
  surface_parking_stall_size?: number;
  uf_land_use_category?:
    | 'mixed_use'
    | 'residential'
    | 'commercial'
    | 'industrial'
    | 'civic_institutional'
    | 'transportation_utilities'
    | 'open_space'
    | 'other'
    | 'natural_resources'
    | 'natural'
    | 'conservation_module_landscape_types';
  uf_land_use_codes?: string[];
  updated?: string;
  updated_by?: string;
  used?: boolean;
  valid?: boolean;
  validation_errors?: BuiltFormValidationError[];
};
export type BuildingTypeInfo = {
  key: string;
  library_path: string;
  name: string;
  namespace: string;
  style?: object;
  used?: boolean;
  valid?: boolean;
};
export type WebLink = {
  label?: string;
  url: string;
};
export type Building = {
  address?: string;
  area_parcel?: number;
  area_parcel_agriculture?: number;
  area_parcel_allocation_error?: number;
  area_parcel_allocation_error_pct?: number;
  area_parcel_civic_institutional?: number;
  area_parcel_commercial?: number;
  area_parcel_fine?: number;
  area_parcel_industrial?: number;
  area_parcel_mixed_use?: number;
  area_parcel_natural_conservation?: number;
  area_parcel_natural_resources?: number;
  area_parcel_open_space?: number;
  area_parcel_other?: number;
  area_parcel_res?: number;
  area_parcel_res_acc?: number;
  area_parcel_res_attsf?: number;
  area_parcel_res_detsf_ll?: number;
  area_parcel_res_detsf_sl?: number;
  area_parcel_res_mf?: number;
  area_parcel_residential?: number;
  area_parcel_transportation_utilities?: number;
  area_parcel_water?: number;
  area_parcel_with_residential?: number;
  area_park?: number;
  area_per_emp?: number;
  area_per_emp_ag?: number;
  area_per_emp_ag_agriculture?: number;
  area_per_emp_ag_extraction?: number;
  area_per_emp_ind?: number;
  area_per_emp_ind_construction?: number;
  area_per_emp_ind_manufacturing?: number;
  area_per_emp_ind_transport_warehousing?: number;
  area_per_emp_ind_utilities?: number;
  area_per_emp_ind_wholesale?: number;
  area_per_emp_military?: number;
  area_per_emp_off?: number;
  area_per_emp_off_medical_services?: number;
  area_per_emp_off_office_services?: number;
  area_per_emp_pub?: number;
  area_per_emp_pub_education?: number;
  area_per_emp_pub_public_admin?: number;
  area_per_emp_ret?: number;
  area_per_emp_ret_accommodation?: number;
  area_per_emp_ret_arts_entertainment?: number;
  area_per_emp_ret_other_services?: number;
  area_per_emp_ret_restaurant?: number;
  area_per_emp_ret_retail_services?: number;
  avg_unit_area_acc?: number;
  avg_unit_area_attsf?: number;
  avg_unit_area_detsf_ll?: number;
  avg_unit_area_detsf_sl?: number;
  avg_unit_area_mf?: number;
  bldg_area_acc?: number;
  bldg_area_accommodation?: number;
  bldg_area_accommodation_pct?: number;
  bldg_area_ag?: number;
  bldg_area_ag_pct?: number;
  bldg_area_agriculture?: number;
  bldg_area_agriculture_pct?: number;
  bldg_area_allocation_error?: number;
  bldg_area_allocation_error_pct?: number;
  bldg_area_arts_entertainment?: number;
  bldg_area_arts_entertainment_pct?: number;
  bldg_area_attsf?: number;
  bldg_area_belowground?: number;
  bldg_area_construction?: number;
  bldg_area_construction_pct?: number;
  bldg_area_detsf_ll?: number;
  bldg_area_detsf_sl?: number;
  bldg_area_education?: number;
  bldg_area_education_pct?: number;
  bldg_area_emp_allocation_error?: number;
  bldg_area_emp_allocation_error_pct?: number;
  bldg_area_extraction?: number;
  bldg_area_extraction_pct?: number;
  bldg_area_footprint?: number;
  bldg_area_footprint_area_parcel_pct?: number;
  bldg_area_ind?: number;
  bldg_area_ind_pct?: number;
  bldg_area_manufacturing?: number;
  bldg_area_manufacturing_pct?: number;
  bldg_area_medical_services?: number;
  bldg_area_medical_services_pct?: number;
  bldg_area_mf?: number;
  bldg_area_military?: number;
  bldg_area_military_pct?: number;
  bldg_area_nonres?: number;
  bldg_area_nonres_pct?: number;
  bldg_area_nonresidential_net?: number;
  bldg_area_off?: number;
  bldg_area_off_pct?: number;
  bldg_area_office_services?: number;
  bldg_area_office_services_pct?: number;
  bldg_area_other_services?: number;
  bldg_area_other_services_pct?: number;
  bldg_area_pub?: number;
  bldg_area_pub_pct?: number;
  bldg_area_public_admin?: number;
  bldg_area_public_admin_pct?: number;
  bldg_area_res?: number;
  bldg_area_res_pct?: number;
  bldg_area_res_type_allocation_error?: number;
  bldg_area_res_type_allocation_error_pct?: number;
  bldg_area_residential_net?: number;
  bldg_area_restaurant?: number;
  bldg_area_restaurant_pct?: number;
  bldg_area_ret?: number;
  bldg_area_ret_pct?: number;
  bldg_area_retail_services?: number;
  bldg_area_retail_services_pct?: number;
  bldg_area_total?: number;
  bldg_area_total_net?: number;
  bldg_area_transport_warehousing?: number;
  bldg_area_transport_warehousing_pct?: number;
  bldg_area_utilities?: number;
  bldg_area_utilities_pct?: number;
  bldg_area_wholesale?: number;
  bldg_area_wholesale_pct?: number;
  bldg_efficiency_ratio_nonresidential?: number;
  bldg_efficiency_ratio_residential?: number;
  bldg_height?: number;
  building_types?: BuildingTypeInfo[];
  created?: string;
  creator?: string;
  description?: string;
  du?: number;
  du_acc?: number;
  du_attsf?: number;
  du_detsf_ll?: number;
  du_detsf_sl?: number;
  du_mf?: number;
  emp?: number;
  emp_accommodation?: number;
  emp_ag?: number;
  emp_ag_agriculture_pct?: number;
  emp_ag_extraction_pct?: number;
  emp_ag_pct?: number;
  emp_ag_pct_allocation_error?: number;
  emp_agriculture?: number;
  emp_arts_entertainment?: number;
  emp_construction?: number;
  emp_education?: number;
  emp_extraction?: number;
  emp_ind?: number;
  emp_ind_construction_pct?: number;
  emp_ind_manufacturing_pct?: number;
  emp_ind_pct?: number;
  emp_ind_pct_allocation_error?: number;
  emp_ind_transport_warehousing_pct?: number;
  emp_ind_utilities_pct?: number;
  emp_ind_wholesale_pct?: number;
  emp_manufacturing?: number;
  emp_medical_services?: number;
  emp_military?: number;
  emp_military_pct?: number;
  emp_off?: number;
  emp_off_medical_services_pct?: number;
  emp_off_office_services_pct?: number;
  emp_off_pct?: number;
  emp_off_pct_allocation_error?: number;
  emp_office_services?: number;
  emp_other_services?: number;
  emp_pub?: number;
  emp_pub_education_pct?: number;
  emp_pub_pct?: number;
  emp_pub_pct_allocation_error?: number;
  emp_pub_public_admin_pct?: number;
  emp_public_admin?: number;
  emp_restaurant?: number;
  emp_ret?: number;
  emp_ret_accommodation_pct?: number;
  emp_ret_arts_entertainment_pct?: number;
  emp_ret_other_services_pct?: number;
  emp_ret_pct?: number;
  emp_ret_pct_allocation_error?: number;
  emp_ret_restaurant_pct?: number;
  emp_ret_retail_services_pct?: number;
  emp_retail_services?: number;
  emp_transport_warehousing?: number;
  emp_utilities?: number;
  emp_wholesale?: number;
  employment_density_category?: 'high' | 'mid' | 'low';
  far_building?: number;
  far_building_nonresidential?: number;
  far_building_residential?: number;
  far_parking?: number;
  far_total?: number;
  floor_count?: number;
  floor_height?: number;
  hardscape_area_other?: number;
  hardscape_area_other_area_parcel_pct?: number;
  hardscape_area_total?: number;
  hh?: number;
  internal_parking_stall_size?: number;
  irrigated_area?: number;
  irrigated_area_nonresidential?: number;
  irrigated_area_nonresidential_pct?: number;
  irrigated_area_residential?: number;
  irrigated_area_residential_pct?: number;
  is_agriculture?: number;
  is_civic_institutional?: number;
  is_commercial?: number;
  is_employment_density_high?: number;
  is_employment_density_low?: number;
  is_employment_density_mid?: number;
  is_industrial?: number;
  is_mixed_use?: number;
  is_natural_conservation?: number;
  is_natural_resources?: number;
  is_open_space?: number;
  is_other?: number;
  is_residential?: number;
  is_transportation_utilities?: number;
  is_water?: number;
  key?: string;
  land_use_category_level_1?: string;
  land_use_category_level_2?: string;
  land_use_category_level_3?:
    | 'mixed_use'
    | 'mixed_use_residential'
    | 'mixed_use_commercial'
    | 'mixed_use_urban'
    | 'residential'
    | 'single_family_detached'
    | 'single_family_attached'
    | 'multifamily'
    | 'group_quarters'
    | 'commercial'
    | 'retail_services'
    | 'commercial_centers'
    | 'accommodation'
    | 'commercial_other'
    | 'office'
    | 'commercial_recreation'
    | 'industrial'
    | 'wholesale_warehousing'
    | 'light_industrial'
    | 'heavy_industrial'
    | 'civic_institutional'
    | 'civic_facilities'
    | 'special_use'
    | 'emergency_services'
    | 'primary_secondary_education'
    | 'higher_education'
    | 'hospitals'
    | 'military'
    | 'other_institutional'
    | 'transportation_utilities'
    | 'transportation'
    | 'utilities_communications'
    | 'parks_recreation'
    | 'golf_courses'
    | 'cemeteries'
    | 'open_space'
    | 'vacant'
    | 'other'
    | 'agriculture'
    | 'natural_resources'
    | 'forestry'
    | 'extraction'
    | 'natural_conservation'
    | 'water';
  library_path?: string;
  links?: WebLink[];
  name?: string;
  namespace?: string;
  nonhardscape_area?: number;
  nonhardscape_area_area_parcel_pct?: number;
  nonhardscape_area_irrigated_pct?: number;
  parking_area_internal?: number;
  parking_area_structured?: number;
  parking_area_structured_footprint?: number;
  parking_area_structured_footprint_area_parcel_pct?: number;
  parking_area_surface?: number;
  parking_area_surface_area_parcel_pct?: number;
  parking_spaces_internal?: number;
  parking_spaces_structured?: number;
  parking_spaces_surface?: number;
  parking_spaces_total?: number;
  pop?: number;
  pop_groupquarter?: number;
  source?: string;
  structured_parking_stall_size?: number;
  surface_parking_stall_size?: number;
  uf_land_use_category?:
    | 'mixed_use'
    | 'residential'
    | 'commercial'
    | 'industrial'
    | 'civic_institutional'
    | 'transportation_utilities'
    | 'open_space'
    | 'other'
    | 'natural_resources'
    | 'natural'
    | 'conservation_module_landscape_types';
  uf_land_use_codes?: string[];
  updated?: string;
  updated_by?: string;
  used?: boolean;
  valid?: boolean;
  validation_errors?: BuiltFormValidationError[];
};
export type LibraryBuildingPreviewUpdateValue = {
  number_value?: number;
  prop_name: string;
  string_value?: string;
};
export type LibraryBuildingPreviewUpdateData = {
  building?: Building;
  updates?: LibraryBuildingPreviewUpdateValue[];
};
export type PlaceType = {
  area_parcel?: number;
  area_parcel_agriculture?: number;
  area_parcel_allocation_error?: number;
  area_parcel_allocation_error_pct?: number;
  area_parcel_civic_institutional?: number;
  area_parcel_commercial?: number;
  area_parcel_fine?: number;
  area_parcel_industrial?: number;
  area_parcel_mixed_use?: number;
  area_parcel_natural_conservation?: number;
  area_parcel_natural_resources?: number;
  area_parcel_open_space?: number;
  area_parcel_other?: number;
  area_parcel_res?: number;
  area_parcel_res_acc?: number;
  area_parcel_res_attsf?: number;
  area_parcel_res_detsf_ll?: number;
  area_parcel_res_detsf_sl?: number;
  area_parcel_res_mf?: number;
  area_parcel_residential?: number;
  area_parcel_transportation_utilities?: number;
  area_parcel_water?: number;
  area_parcel_with_residential?: number;
  area_park?: number;
  area_per_emp?: number;
  area_per_emp_ag?: number;
  area_per_emp_ag_agriculture?: number;
  area_per_emp_ag_extraction?: number;
  area_per_emp_ind?: number;
  area_per_emp_ind_construction?: number;
  area_per_emp_ind_manufacturing?: number;
  area_per_emp_ind_transport_warehousing?: number;
  area_per_emp_ind_utilities?: number;
  area_per_emp_ind_wholesale?: number;
  area_per_emp_military?: number;
  area_per_emp_off?: number;
  area_per_emp_off_medical_services?: number;
  area_per_emp_off_office_services?: number;
  area_per_emp_pub?: number;
  area_per_emp_pub_education?: number;
  area_per_emp_pub_public_admin?: number;
  area_per_emp_ret?: number;
  area_per_emp_ret_accommodation?: number;
  area_per_emp_ret_arts_entertainment?: number;
  area_per_emp_ret_other_services?: number;
  area_per_emp_ret_restaurant?: number;
  area_per_emp_ret_retail_services?: number;
  avg_unit_area_acc?: number;
  avg_unit_area_attsf?: number;
  avg_unit_area_detsf_ll?: number;
  avg_unit_area_detsf_sl?: number;
  avg_unit_area_mf?: number;
  bldg_area_acc?: number;
  bldg_area_accommodation?: number;
  bldg_area_accommodation_pct?: number;
  bldg_area_ag?: number;
  bldg_area_ag_pct?: number;
  bldg_area_agriculture?: number;
  bldg_area_agriculture_pct?: number;
  bldg_area_allocation_error?: number;
  bldg_area_allocation_error_pct?: number;
  bldg_area_arts_entertainment?: number;
  bldg_area_arts_entertainment_pct?: number;
  bldg_area_attsf?: number;
  bldg_area_belowground?: number;
  bldg_area_construction?: number;
  bldg_area_construction_pct?: number;
  bldg_area_detsf_ll?: number;
  bldg_area_detsf_sl?: number;
  bldg_area_education?: number;
  bldg_area_education_pct?: number;
  bldg_area_emp_allocation_error?: number;
  bldg_area_emp_allocation_error_pct?: number;
  bldg_area_extraction?: number;
  bldg_area_extraction_pct?: number;
  bldg_area_footprint?: number;
  bldg_area_footprint_area_parcel_pct?: number;
  bldg_area_ind?: number;
  bldg_area_ind_pct?: number;
  bldg_area_manufacturing?: number;
  bldg_area_manufacturing_pct?: number;
  bldg_area_medical_services?: number;
  bldg_area_medical_services_pct?: number;
  bldg_area_mf?: number;
  bldg_area_military?: number;
  bldg_area_military_pct?: number;
  bldg_area_nonres?: number;
  bldg_area_nonres_pct?: number;
  bldg_area_nonresidential_net?: number;
  bldg_area_off?: number;
  bldg_area_off_pct?: number;
  bldg_area_office_services?: number;
  bldg_area_office_services_pct?: number;
  bldg_area_other_services?: number;
  bldg_area_other_services_pct?: number;
  bldg_area_pub?: number;
  bldg_area_pub_pct?: number;
  bldg_area_public_admin?: number;
  bldg_area_public_admin_pct?: number;
  bldg_area_res?: number;
  bldg_area_res_pct?: number;
  bldg_area_res_type_allocation_error?: number;
  bldg_area_res_type_allocation_error_pct?: number;
  bldg_area_residential_net?: number;
  bldg_area_restaurant?: number;
  bldg_area_restaurant_pct?: number;
  bldg_area_ret?: number;
  bldg_area_ret_pct?: number;
  bldg_area_retail_services?: number;
  bldg_area_retail_services_pct?: number;
  bldg_area_total?: number;
  bldg_area_total_net?: number;
  bldg_area_transport_warehousing?: number;
  bldg_area_transport_warehousing_pct?: number;
  bldg_area_utilities?: number;
  bldg_area_utilities_pct?: number;
  bldg_area_wholesale?: number;
  bldg_area_wholesale_pct?: number;
  bldg_efficiency_ratio_nonresidential?: number;
  bldg_efficiency_ratio_residential?: number;
  bldg_height?: number;
  blend_allocation_error?: number;
  building_types?: BuiltFormItemWeight[];
  created?: string;
  creator?: string;
  description?: string;
  du?: number;
  du_acc?: number;
  du_attsf?: number;
  du_detsf_ll?: number;
  du_detsf_sl?: number;
  du_mf?: number;
  emp?: number;
  emp_accommodation?: number;
  emp_ag?: number;
  emp_ag_agriculture_pct?: number;
  emp_ag_extraction_pct?: number;
  emp_ag_pct?: number;
  emp_ag_pct_allocation_error?: number;
  emp_agriculture?: number;
  emp_arts_entertainment?: number;
  emp_construction?: number;
  emp_education?: number;
  emp_extraction?: number;
  emp_ind?: number;
  emp_ind_construction_pct?: number;
  emp_ind_manufacturing_pct?: number;
  emp_ind_pct?: number;
  emp_ind_pct_allocation_error?: number;
  emp_ind_transport_warehousing_pct?: number;
  emp_ind_utilities_pct?: number;
  emp_ind_wholesale_pct?: number;
  emp_manufacturing?: number;
  emp_medical_services?: number;
  emp_military?: number;
  emp_military_pct?: number;
  emp_off?: number;
  emp_off_medical_services_pct?: number;
  emp_off_office_services_pct?: number;
  emp_off_pct?: number;
  emp_off_pct_allocation_error?: number;
  emp_office_services?: number;
  emp_other_services?: number;
  emp_pub?: number;
  emp_pub_education_pct?: number;
  emp_pub_pct?: number;
  emp_pub_pct_allocation_error?: number;
  emp_pub_public_admin_pct?: number;
  emp_public_admin?: number;
  emp_restaurant?: number;
  emp_ret?: number;
  emp_ret_accommodation_pct?: number;
  emp_ret_arts_entertainment_pct?: number;
  emp_ret_other_services_pct?: number;
  emp_ret_pct?: number;
  emp_ret_pct_allocation_error?: number;
  emp_ret_restaurant_pct?: number;
  emp_ret_retail_services_pct?: number;
  emp_retail_services?: number;
  emp_transport_warehousing?: number;
  emp_utilities?: number;
  emp_wholesale?: number;
  far_building?: number;
  far_building_nonresidential?: number;
  far_building_residential?: number;
  far_parking?: number;
  far_total?: number;
  floor_count?: number;
  floor_height?: number;
  hardscape_area_other?: number;
  hardscape_area_other_area_parcel_pct?: number;
  hardscape_area_total?: number;
  hh?: number;
  internal_parking_stall_size?: number;
  intersection_density?: number;
  irrigated_area?: number;
  irrigated_area_nonresidential?: number;
  irrigated_area_nonresidential_pct?: number;
  irrigated_area_residential?: number;
  irrigated_area_residential_pct?: number;
  is_agriculture?: number;
  is_civic_institutional?: number;
  is_commercial?: number;
  is_employment_density_high?: number;
  is_employment_density_low?: number;
  is_employment_density_mid?: number;
  is_industrial?: number;
  is_mixed_use?: number;
  is_natural_conservation?: number;
  is_natural_resources?: number;
  is_open_space?: number;
  is_other?: number;
  is_residential?: number;
  is_transportation_utilities?: number;
  is_water?: number;
  key?: string;
  land_development_category?:
    | 'urban'
    | 'compact'
    | 'standard'
    | 'rural'
    | 'undeveloped';
  land_use_category_level_1?:
    | 'mixed_use'
    | 'residential'
    | 'commercial'
    | 'industrial'
    | 'civic_institutional'
    | 'transportation_utilities'
    | 'open_space'
    | 'other'
    | 'agriculture'
    | 'natural_resources'
    | 'natural_conservation'
    | 'water';
  land_use_category_level_2?: string;
  land_use_category_level_3?: string;
  library_path?: string;
  name?: string;
  namespace?: string;
  nonhardscape_area?: number;
  nonhardscape_area_area_parcel_pct?: number;
  nonhardscape_area_irrigated_pct?: number;
  parking_area_internal?: number;
  parking_area_structured?: number;
  parking_area_structured_footprint?: number;
  parking_area_structured_footprint_area_parcel_pct?: number;
  parking_area_surface?: number;
  parking_area_surface_area_parcel_pct?: number;
  parking_spaces_internal?: number;
  parking_spaces_structured?: number;
  parking_spaces_surface?: number;
  parking_spaces_total?: number;
  pop?: number;
  pop_groupquarter?: number;
  row_allocation_error_pct?: number;
  row_area_pct?: number;
  row_arterial_pct?: number;
  row_collector_pct?: number;
  row_local_pct?: number;
  structured_parking_stall_size?: number;
  style?: object;
  surface_parking_stall_size?: number;
  uf_land_use_category?:
    | 'mixed_use'
    | 'residential'
    | 'commercial'
    | 'industrial'
    | 'civic_institutional'
    | 'transportation_utilities'
    | 'open_space'
    | 'other'
    | 'natural_resources'
    | 'natural'
    | 'conservation_module_landscape_types';
  uf_land_use_codes?: string[];
  updated?: string;
  updated_by?: string;
  used?: boolean;
  valid?: boolean;
  validation_errors?: BuiltFormValidationError[];
};
export type BuildingTypeList = BuildingTypeInfo[];
export type PlaceTypeList = PlaceTypeInfo[];
export type LibraryDefaults = {
  building?: Building;
  place_type?: PlaceType;
};
export type LayerReferenceDetails = {
  display_hints?: object;
  latest_version?: string;
  name?: string;
  tile_url?: string;
};
export type LayerReference = {
  details?: LayerReferenceDetails;
  full_path?: string;
  key: string;
  layer_type: string;
  namespace: string;
  version?: string;
};
export type LibraryVariables = {
  area_per_emp_ag_agriculture_high?: number;
  area_per_emp_ag_agriculture_low?: number;
  area_per_emp_ag_agriculture_mid?: number;
  area_per_emp_ag_extraction_high?: number;
  area_per_emp_ag_extraction_low?: number;
  area_per_emp_ag_extraction_mid?: number;
  area_per_emp_ind_construction_high?: number;
  area_per_emp_ind_construction_low?: number;
  area_per_emp_ind_construction_mid?: number;
  area_per_emp_ind_manufacturing_high?: number;
  area_per_emp_ind_manufacturing_low?: number;
  area_per_emp_ind_manufacturing_mid?: number;
  area_per_emp_ind_transport_warehousing_high?: number;
  area_per_emp_ind_transport_warehousing_low?: number;
  area_per_emp_ind_transport_warehousing_mid?: number;
  area_per_emp_ind_utilities_high?: number;
  area_per_emp_ind_utilities_low?: number;
  area_per_emp_ind_utilities_mid?: number;
  area_per_emp_ind_wholesale_high?: number;
  area_per_emp_ind_wholesale_low?: number;
  area_per_emp_ind_wholesale_mid?: number;
  area_per_emp_military_high?: number;
  area_per_emp_military_low?: number;
  area_per_emp_military_mid?: number;
  area_per_emp_off_medical_services_high?: number;
  area_per_emp_off_medical_services_low?: number;
  area_per_emp_off_medical_services_mid?: number;
  area_per_emp_off_office_services_high?: number;
  area_per_emp_off_office_services_low?: number;
  area_per_emp_off_office_services_mid?: number;
  area_per_emp_pub_education_high?: number;
  area_per_emp_pub_education_low?: number;
  area_per_emp_pub_education_mid?: number;
  area_per_emp_pub_public_admin_high?: number;
  area_per_emp_pub_public_admin_low?: number;
  area_per_emp_pub_public_admin_mid?: number;
  area_per_emp_ret_accommodation_high?: number;
  area_per_emp_ret_accommodation_low?: number;
  area_per_emp_ret_accommodation_mid?: number;
  area_per_emp_ret_arts_entertainment_high?: number;
  area_per_emp_ret_arts_entertainment_low?: number;
  area_per_emp_ret_arts_entertainment_mid?: number;
  area_per_emp_ret_other_services_high?: number;
  area_per_emp_ret_other_services_low?: number;
  area_per_emp_ret_other_services_mid?: number;
  area_per_emp_ret_restaurant_high?: number;
  area_per_emp_ret_restaurant_low?: number;
  area_per_emp_ret_restaurant_mid?: number;
  area_per_emp_ret_retail_services_high?: number;
  area_per_emp_ret_retail_services_low?: number;
  area_per_emp_ret_retail_services_mid?: number;
  area_scale_down_factor?: number;
  hh_size_acc?: number;
  hh_size_attsf?: number;
  hh_size_detsf_ll?: number;
  hh_size_detsf_sl?: number;
  hh_size_mf?: number;
  intersection_density_compact?: number;
  intersection_density_rural?: number;
  intersection_density_standard?: number;
  intersection_density_undeveloped?: number;
  intersection_density_urban?: number;
  measurement_system?: 'us' | 'metric';
  occupancy_rate_nonresidential?: number;
  occupancy_rate_residential?: number;
};
export type Library = {
  created?: string;
  creator?: string;
  dataset_path?: string;
  defaults?: LibraryDefaults;
  key: string;
  name: string;
  namespace: string;
  never_bake?: boolean;
  project_path?: string;
  uf_land_use_layer?: LayerReference;
  updated?: string;
  updated_by?: string;
  variables?: LibraryVariables;
};
export type BuildingInfo = {
  key: string;
  library_path: string;
  name: string;
  namespace: string;
  used?: boolean;
  valid?: boolean;
};
export type BuildingList = BuildingInfo[];
export type OrganizationInfo = {
  created?: string;
  creator?: string;
  deleted?: string;
  deleted_by?: string;
  full_path?: string;
  insights_help_url?: string;
  key: string;
  name?: string;
  portfolio_key?: string;
  updated?: string;
  updated_by?: string;
};
export type User = {
  active?: boolean;
  active_org_key?: string;
  created?: string;
  creator?: string;
  disable_user_log?: boolean;
  email?: string;
  first_name?: string;
  intercom_user_hash?: string;
  is_superuser?: boolean;
  is_support?: boolean;
  key?: string;
  last_name?: string;
  organizations?: OrganizationInfo[];
  session_id?: string;
  updated?: string;
  updated_by?: string;
  username?: string;
};
export type UserActionReasonDetail = {
  max_export_feature_count?: number;
  reason_detail_type: 'feature_count';
};
export type UserAction = {
  can_do?: boolean;
  reason?: 'permission' | 'subscription' | 'restriction';
  reason_detail?: UserActionReasonDetail;
  resource: string;
  verb: string;
};
export type UserActionList = UserAction[];
export type InvitationListItem = {
  accepted?: string;
  cancelled?: string;
  created?: string;
  expires?: string;
  invited_email?: string;
  invited_first_name?: string;
  invited_last_name?: string;
  invited_org_key?: string;
  invited_org_name?: string;
  invited_org_role?: string;
  sent?: string;
  token?: string;
};
export type InvitationList = {
  items?: InvitationListItem[];
};
export type DynamicLayerMetadata = {
  description?: string;
  filter?: object;
  full_path?: string;
  key: string;
  name?: string;
  namespace: string;
  parent: LayerReference;
  version: string;
};
export type BooleanItem = boolean;
export type ProjectReference = {
  full_path?: string;
  key: string;
  namespace: string;
};
export type EntityExplorerValue = {
  full_path?: string;
  number_value?: number;
  object_value?: object;
  string_value?: string;
};
export type EntityExplorerProperty = {
  name?: string;
  values?: EntityExplorerValue[];
};
export type EntityExplorerEntity = {
  name?: string;
  properties?: EntityExplorerProperty[];
};
export type LayerReferenceList = {
  items?: LayerReference[];
};
export type LayerRestriction = {
  details?: string;
  restriction_type: 'layer.export' | 'layer.combined_export';
};
export type LayerRegistryItem = {
  auto_generated?: boolean;
  create_date?: string;
  full_path?: string;
  is_reference_layer?: boolean;
  key?: string;
  layer_type?: string;
  namespace?: string;
  project_created_in?: string;
  restrictions?: LayerRestriction[];
  visibility?: string;
};
export type LayerRegistryList = LayerRegistryItem[];
export type LayerSummaryItem = {
  convex_hull?: object;
  full_path?: string;
  key?: string;
  layer_type?: string;
  namespace?: string;
  version?: string;
};
export type LayerSummaryList = LayerSummaryItem[];
export type LayerColumnValidValuesChoice = {
  name?: string;
  value: string;
};
export type LayerColumnValidValuesRange = {
  max_value?: number;
  max_value_exclusive?: boolean;
  min_value?: number;
  min_value_exclusive?: boolean;
};
export type LayerColumnValidValues = {
  choices?: LayerColumnValidValuesChoice[];
  range?: LayerColumnValidValuesRange;
};
export type LayerColumn = {
  abbreviation?: string;
  category?: string;
  description?: string;
  display_hints?: object;
  hidden?: boolean;
  key: string;
  metatype?: 'categorical' | 'numeric';
  name?: string;
  not_mappable?: boolean;
  number_format?: object;
  source?: string;
  terse_abbreviation?: string;
  terse_name?: string;
  type?: 'string' | 'boolean' | 'int' | 'bigint' | 'float' | 'bytes';
  units?: string;
  valid_values?: LayerColumnValidValues;
};
export type License = {};
export type LayerSource = {
  name?: string;
  url?: string;
};
export type LayerMetadata = {
  abbreviation?: string;
  columns: LayerColumn[];
  description?: string;
  display_hints?: object;
  full_path?: string;
  geometry_type_key?: string;
  key: string;
  layer_type: string;
  license?: License;
  name?: string;
  namespace: string;
  source?: LayerSource;
  supply_pattern?: string;
  terse_abbreviation?: string;
  terse_name?: string;
  tile_url?: string;
  unique_keys: string[];
};
export type LayerBounds = {
  srid?: number;
  x_max: number;
  x_min: number;
  y_max: number;
  y_min: number;
};
export type LayerGeoData = {
  geo_keys?: string[];
};
export type LayerTextStats = {
  median_length?: number;
};
export type ValueBucket = {
  count?: number;
  max?: number;
  min?: number;
  value?: string;
  weighted?: number;
};
export type LayerCategoricalStats = {
  text?: LayerTextStats;
  values?: ValueBucket[];
  values_count?: number;
};
export type LayerNumericStats = {
  avg?: number;
  histogram?: ValueBucket[];
  max?: number;
  min?: number;
  null_count?: number;
  sum?: number;
};
export type LayerColumnStats = {
  categorical?: LayerCategoricalStats;
  column_key?: string;
  numeric?: LayerNumericStats;
};
export type LayerStats = {
  column_stats?: LayerColumnStats[];
  row_count?: number;
};
export type PaintingActionInfo = {
  active?: boolean;
  canvas: LayerReference;
  created?: string;
  creator?: string;
  display_hints?: object;
  filter?: object;
  key: string;
  painter:
    | 'column'
    | 'row'
    | 'built_form'
    | 'undo'
    | 'redo'
    | 'delete'
    | 'split_canvas';
  params?: object;
};
export type PaintingActionList = PaintingActionInfo[];
export type TaskInternalInfo = {
  feature_flags: string[];
  params: object;
};
export type TaskProblem = {
  detail?: string;
  ext?: string;
  status?: number;
  title?: string;
  type_code?: string;
};
export type Task = {
  end_time?: string;
  enqueue_time: string;
  info?: object;
  internal?: TaskInternalInfo;
  problem?: TaskProblem;
  project_path?: string;
  run_start_time?: string;
  status?: 'enqueued' | 'running' | 'done' | 'error' | 'cancelled';
  task_id: string;
  task_type: string;
};
export type TaskList = Task[];
export type InternalUserInfo = {
  created?: string;
  creator?: string;
  recurly_account_codes?: object;
  updated?: string;
  updated_by?: string;
  user?: User;
};
export type InternalUserList = InternalUserInfo[];
export type UserDevice = {
  created: string;
  id: string;
  last_accessed?: string;
  organizations?: OrganizationInfo[];
  user_agent?: string;
  user_email: string;
  user_key: string;
};
export type UserDeviceList = UserDevice[];
export type LayerColumnBreaks = {
  break_count: number;
  break_type: 'linear' | 'logarithmic' | 'quantiles' | 'natural';
  breaks: number[];
  column_key: string;
  contains_nulls?: boolean;
};
export type LayerBreaks = {
  column_breaks?: LayerColumnBreaks[];
};
export type AppLayer = {
  context?: string;
  layer?: LayerReference;
};
export type AppLayerList = AppLayer[];
export type LayerData = {
  column_keys?: string[];
  rows?: string[][];
};
export type GeometryType = {
  error_message?: string;
  key: string;
  materialized?: boolean;
  name: string;
  namespace?: string;
  owning_layer?: string;
  parent_key?: string;
  parent_version?: number;
  simple_geometry_type?: 'POINT' | 'LINESTRING' | 'POLYGON';
  status: 'IN_PROGRESS' | 'ERROR' | 'DONE';
  version?: string;
  versioned?: boolean;
};
export type MapExportLayerStyleBreak = {
  category?: string;
  fill_color?: string;
  fill_opacity?: number;
  max_value?: number;
  min_value?: number;
  radius?: number;
  stroke_color?: string;
  stroke_opacity?: number;
  stroke_width?: number;
};
export type MapExportLayerStyle = {
  divide_by_column?: string;
  layer_style?: MapExportLayerStyleBreak[];
  show_zero_values?: boolean;
};
export type MapExportContentSettings = {
  basemap?: string;
  layer_column_keys?: string[];
  layer_styles?: MapExportLayerStyle[];
  layers?: string[];
  show_feature_ids?: string[];
  view_id?: string;
};
export type MapExportControlSettings = {
  compass_type?: 'none' | 'default';
  legend_classes_to_include?: 'all' | 'visible_only';
  legend_layer_column_keys?: string[];
  legend_layers?: string[];
  legend_type?: 'none' | 'separate' | 'overlay_upper_right';
  logo_key?: string;
  logo_type?: 'none' | 'uf_logo';
  scale_type?: 'none' | 'default' | 'alternating_scale_bar';
  scale_units?: 'us' | 'metric';
  title?: string;
};
export type MapExportDisplaySettings = {
  aspect_x?: number;
  aspect_y?: number;
  bearing?: number;
  bounds?: number[][];
  center: number[];
  orientation?: 'landscape' | 'portrait';
  pitch?: number;
  show_bounds?: boolean;
  source_height: number;
  source_width: number;
  source_zoom: number;
};
export type MapExportOutputSettings = {
  dpi?: number;
  format?: 'png' | 'svg';
  height?: number;
  scale_factor?: number;
  width?: number;
};
export type MapExportRequest = {
  content_settings?: MapExportContentSettings;
  control_settings?: MapExportControlSettings;
  display_settings?: MapExportDisplaySettings;
  output_settings?: MapExportOutputSettings;
};
export type TaskEnqueueResultItem = {
  enqueued?: boolean;
  task_id?: string;
};
export type OrganizationInfoList = {
  items?: OrganizationInfo[];
};
export type CreditCardInfo = {
  card_type?: string;
  last_four?: string;
  month?: string;
  year?: string;
};
export type BillingInfo = {
  address_1?: string;
  address_2?: string;
  cc_info?: CreditCardInfo;
  city?: string;
  country_code?: string;
  first_name?: string;
  last_name?: string;
  postal_code?: string;
  state?: string;
};
export type CanvasDisabledReason = {
  code?: string;
  message?: string;
};
export type CanvasTypeInfo = {
  beta?: boolean;
  disabled?: CanvasDisabledReason;
  type_key: string;
};
export type CanvasTypeList = CanvasTypeInfo[];
export type FeatureFlags = {
  items?: string[];
};
export type OrganizationFilesListItem = {
  created?: string;
  name: string;
  url?: string;
};
export type OrganizationFileList = {
  items?: OrganizationFilesListItem[];
};
export type CensusUnit = {
  id?: string;
  key: number;
  name: string;
};
export type RequiredColumn = {
  key: string;
};
export type Insight = {
  can_buffer?: boolean;
  census_units?: CensusUnit[];
  has_dashboard?: boolean;
  help_url?: string;
  id?: string;
  input_geo_types?: string[];
  key: number;
  name: string;
  required_columns?: RequiredColumn[];
};
export type InsightCategory = {
  insights?: Insight[];
  name?: string;
};
export type InsightCategoryList = InsightCategory[];
export type MemberListItem = {
  email?: string;
  first_name?: string;
  key: string;
  last_name?: string;
  role?: 'collaborator' | 'viewer' | 'editor' | 'admin' | 'owner' | 'public';
};
export type MemberList = {
  items?: MemberListItem[];
};
export type MemberCount = {
  count: number;
};
export type ProjectListItem = {
  created?: string;
  creator?: string;
  demo_project?: boolean;
  description?: string;
  full_path?: string;
  include_base_canvas?: boolean;
  key: string;
  name?: string;
  namespace: string;
  project_type?: string;
  updated?: string;
  updated_by?: string;
};
export type ProjectList = {
  items?: ProjectListItem[];
};
export type BillingPeriod = {
  interval_length?: number;
  interval_unit?: 'daily' | 'monthly' | 'yearly';
};
export type Subscription = {
  billing_period?: BillingPeriod;
  cost_per_billing_period?: number;
  expired_at?: string;
  features?: (
    | 'layer.export'
    | 'layer.import'
    | 'project.read'
    | 'project.create'
    | 'scenario.create'
    | 'analysis.run'
    | 'builtforms.update'
  )[];
  num_projects?: number;
  num_scenarios?: number;
  num_seats?: number;
  plan_code?: string;
  state?: 'trial' | 'active' | 'expired';
  trial_end_date?: string;
};
export type ProjectTypeStyleMetadata = {
  alt_color?: string;
  background_color?: string;
  color?: string;
};
export type ProjectTypeMetadata = {
  created?: string;
  creator?: string;
  icon_url?: string;
  image_blob_key?: string;
  include_base_canvas?: boolean;
  key?: string;
  long_description?: string;
  medium_description?: string;
  name?: string;
  short_description?: string;
  style?: ProjectTypeStyleMetadata;
  updated?: string;
  updated_by?: string;
};
export type EmbeddedDashboardResult = {
  creation_date?: string;
  dashboard_id?: string;
  dashboard_name?: string;
  embed_url?: string;
  input_layer_name?: string;
  insight_key?: string;
  org_key?: string;
  project_key?: string;
  project_type?: ProjectTypeMetadata;
  user_email?: string;
};
export type Dashboard = {
  created?: string;
  creator?: string;
  id?: string;
  name?: string;
};
export type ProjectDashboard = {
  dashboards: Dashboard[];
  key: string;
  name: string;
};
export type ProjectDashboardList = ProjectDashboard[];
export type OrganizationLogoInfo = {
  created?: string;
  creator?: string;
  key: string;
  mime_type: string;
  name?: string;
  org_key: string;
  original_filename?: string;
  updated?: string;
  updated_by?: string;
  url?: string;
};
export type OrganizationLogoList = OrganizationLogoInfo[];
export type PaintingRevertibleActions = {
  canvas: LayerReference;
  redo_action?: PaintingActionInfo;
  redo_root_action?: PaintingActionInfo;
  undo_action?: PaintingActionInfo;
  undo_root_action?: PaintingActionInfo;
  user?: string;
};
export type PortfolioInfo = {
  created?: string;
  creator?: string;
  deleted?: string;
  deleted_by?: string;
  full_path?: string;
  key: string;
  name?: string;
  organizations?: OrganizationInfo[];
  updated?: string;
  updated_by?: string;
};
export type SummaryDataItem = {
  data_items?: SummaryDataItem[];
  key?: string;
  label?: string;
  value?: number;
};
export type ProgramLevelSummary = {
  data_items?: SummaryDataItem[];
  key: string;
  layer_type: string;
  namespace: string;
  version: string;
};
export type AnalysisModuleParameterCategoryMetadata = {
  abbreviation?: string;
  description?: string;
  display_hints?: object;
  key: string;
  name: string;
  parent_category_key?: string;
  terse_abbreviation?: string;
  terse_name?: string;
};
export type AnalysisModuleParameterMetadata = {
  abbreviation?: string;
  category?: string;
  converted_default_value?: object;
  default_value?: string;
  description?: string;
  display_hints?: object;
  display_units?: string;
  display_valid_values?: LayerColumnValidValues;
  dynamic_valid_value_constraint?: 'layer_geometry_type_point';
  dynamic_valid_values?: boolean;
  key: string;
  metatype?: 'categorical' | 'numeric';
  name: string;
  optional?: boolean;
  sensitive?: boolean;
  source_layer?: string;
  terse_abbreviation?: string;
  terse_name?: string;
  type: 'string' | 'boolean' | 'int' | 'float' | 'layer_path';
  units?: string;
  valid_values?: LayerColumnValidValues;
};
export type AnalysisModuleOutputLayerInfo = {
  description?: string;
  module_key: string;
  module_output_key: string;
  name?: string;
};
export type AnalysisModuleInfo = {
  description?: string;
  experimental?: boolean;
  module_dependencies?: string[];
  module_key: string;
  module_param_categories?: AnalysisModuleParameterCategoryMetadata[];
  module_params?: AnalysisModuleParameterMetadata[];
  name: string;
  output_layer_infos?: AnalysisModuleOutputLayerInfo[];
};
export type AnalysisModuleParameters = {
  module_key: string;
  parameters?: object;
  units?: object;
};
export type AnalysisModuleOutputLayer = {
  layer: LayerReference;
  module_output_key: string;
};
export type AnalysisModuleResults = {
  key: string;
  output_layers?: AnalysisModuleOutputLayer[];
};
export type PolicyAnalysisModuleResults = {
  policy_key?: string;
  results?: AnalysisModuleResults;
  stale?: boolean;
};
export type ScenarioAnalysisModuleResults = {
  module_key?: string;
  policies?: PolicyAnalysisModuleResults[];
};
export type UfCanvasSet = {
  development_layer?: LayerReference;
};
export type ScenarioMetadata = {
  analysis_parameters?: AnalysisModuleParameters[];
  analysis_results?: ScenarioAnalysisModuleResults[];
  base_edits_painted_canvas?: LayerReference;
  base_scenario?: boolean;
  canvas_set?: UfCanvasSet;
  created?: string;
  creator?: string;
  description?: string;
  full_path?: string;
  key?: string;
  name?: string;
  namespace?: string;
  painted_uf_canvas?: LayerReference;
  project_path?: string;
  updated?: string;
  updated_by?: string;
};
export type AnalysisModuleOutputReference = {
  module_key: string;
  module_output_key: string;
};
export type ProjectLayerItem = {
  layer?: LayerReference;
  module_output?: AnalysisModuleOutputReference;
};
export type ProjectMetadata = {
  analysis_modules?: AnalysisModuleInfo[];
  base_scenario?: ScenarioMetadata;
  built_forms_layer?: LayerReference;
  built_forms_library?: string;
  center_lat?: number;
  center_lng?: number;
  context_area_layer?: LayerReference;
  created?: string;
  creator?: string;
  demo_project?: boolean;
  description?: string;
  full_path?: string;
  include_base_canvas?: boolean;
  key?: string;
  layer_list?: ProjectLayerItem[];
  map_bounds?: number[];
  map_zoom?: number;
  max_map_bounds?: number[];
  name?: string;
  namespace?: string;
  next_scenario_ordinal?: number;
  project_area_layer?: LayerReference;
  project_map_bounds?: number[];
  project_schema_version?: -999 | -998 | 1 | 2;
  project_type?: string;
  reference_layers?: LayerReference[];
  scenarios?: ScenarioMetadata[];
  updated?: string;
  updated_by?: string;
  working_layers?: LayerReference[];
};
export type ScenarioModuleParams = {
  key: string;
  module_params?: AnalysisModuleParameters;
  namespace: string;
};
export type ProjectModuleParams = {
  key: string;
  namespace: string;
  scenario_module_params?: ScenarioModuleParams[];
};
export type ProjectAvailableLayerItem = {
  allow_in_favorites?: boolean;
  layer?: LayerReference;
  selected?: boolean;
};
export type ProjectAvailableLayers = {
  reference?: ProjectAvailableLayerItem[];
};
export type AnalysisParameterValidationError = {
  error_key: 'wrong_type' | 'invalid_choice' | 'out_of_range';
  module_key: string;
  param_key: string;
};
export type AnalysisParameterValidationErrorList =
  AnalysisParameterValidationError[];
export type ScenarioUpdate = {
  params?: object;
  start_time: string;
  task_start_time?: string;
  uf_task_id?: string;
  update_type: 'REAPPLY_PAINTS' | 'DEMO_PAINTS' | 'BASE_EDIT_SCENARIO_UPDATE';
  user_key: string;
};
export type ProjectUpdate = {
  params?: object;
  start_time: string;
  task_start_time?: string;
  uf_task_id?: string;
  update_type:
    | 'BAKE_BUILT_FORMS'
    | 'BASE_EDIT_BASE_SCENARIO_UPDATE'
    | 'SCHEMA_MIGRATE_PROJECT_UPDATE';
  user_key: string;
};
export type LayerList = LayerReference[];
export type AnalysisOutputLayerMetadata = {
  columns: LayerColumn[];
  description?: string;
  display_hints?: object;
  geometry_type_key?: string;
  key: string;
  name?: string;
  source?: LayerSource;
  unique_keys: string[];
};
export type ProjectLayerAnalysisRunParameter = {
  buffer?: number;
  buffer_unit?: 'miles' | 'meters' | 'kilometers' | 'feet';
  census_unit_key?: number;
  insight_key: number;
};
export type Symbology = object;
export type ProjectTypeIconInfo = {
  icon_url?: string;
  key: string;
  mime_type: string;
  name?: string;
  original_filename?: string;
};
export type ProjectTypeLayerMetadata = {
  category?: string;
  key: string;
  layer_type: string;
  name: string;
  namespace: string;
};
export type ProjectTypeLayerMetadataList = {
  items?: ProjectTypeLayerMetadata[];
};
export type ProjectTypeStyle = {
  alt_color?: string;
  background_color?: string;
  color?: string;
};
export type ProjectType = {
  icon_url?: string;
  image_blob_key?: string;
  include_base_canvas?: boolean;
  key?: string;
  long_description?: string;
  medium_description?: string;
  name?: string;
  short_description?: string;
  style?: ProjectTypeStyle;
};
export type ProjectTypeList = {
  items?: ProjectType[];
};
export type OrganizationProjectsListItem = {
  organization: OrganizationInfo;
  projects?: ProjectListItem[];
};
export type OrganizationProjectsList = {
  items?: OrganizationProjectsListItem[];
};
export type ReportScenarioResult = {
  scenario_path?: string;
  values?: number[];
};
export type ReportSeriesResult = {
  abbreviation?: string;
  is_percent?: boolean;
  key?: string;
  name?: string;
  scenarios?: ReportScenarioResult[];
  terse_abbreviation?: string;
  terse_name?: string;
  units?: string;
};
export type ReportResult = {
  description?: string;
  key?: string;
  name?: string;
  series?: ReportSeriesResult[];
};
export type ReportResults = {
  key?: string;
  name?: string;
  project_name?: string;
  results?: ReportResult[];
};
export type ReportOutputGroupSpec = {
  description?: string;
  key?: string;
  name?: string;
  series_keys?: string[];
};
export type ReportAnalysisSourceSpec = {
  analysis_module?: string;
  column_key?: string;
  layer_key?: string;
};
export type ReportCanvasSourceSpec = {
  column_key?: string;
  layer_key?: 'development_layer' | 'painted_layer';
};
export type ReportSeriesSpec = {
  aggregate_by?: 'sum' | 'avg' | 'median';
  analysis_source?: ReportAnalysisSourceSpec;
  canvas_source?: ReportCanvasSourceSpec;
  key?: string;
  name?: string;
};
export type ReportSpec = {
  key?: string;
  name?: string;
  output_groups: ReportOutputGroupSpec[];
  series?: ReportSeriesSpec[];
};
export type UserSetting = {
  active_layer_id?: string;
  active_org_key?: string;
  active_project_full_path?: string;
  active_project_key?: string;
  active_project_namespace?: string;
  active_scenario_full_path?: string;
  active_scenario_key?: string;
  active_scenario_namespace?: string;
  active_view_full_path?: string;
  active_view_key?: string;
  active_view_namespace?: string;
  active_virtual_layer_id?: string;
  created?: string;
  creator?: string;
  updated?: string;
  updated_by?: string;
};
export type UserGlobalSetting = {
  active_org_key?: string;
};
export type UserOrgSetting = {
  active_project_key?: string;
};
export type EmbeddedTopicResult = {
  embed_url?: string;
  name?: string;
  topic_id?: string;
};
export type UserData = {
  created?: string;
  creator?: string;
  key?: string;
  updated?: string;
  updated_by?: string;
  user_key?: string;
  value?: object;
};
export type UserDataList = UserData[];
export type AppVersion = {
  build_date?: string;
  git_branch?: string;
  git_commit?: string;
};
export const {
  useBeginAcceptInvitationQuery,
  useAuthenticatedQuery,
  useDeleteBuildingTypeMutation,
  useGetBuildingTypeQuery,
  useCreateBuildingTypeMutation,
  useUpdateBuildingTypeMutation,
  useGetBuildingTypePreviewMutation,
  useDeleteBuildingMutation,
  useGetBuildingQuery,
  useCreateBuildingMutation,
  useUpdateBuildingMutation,
  useGetBuildingPreviewMutation,
  useGetBuildingPreviewMultiMutation,
  useDeletePlaceTypeMutation,
  useGetPlaceTypeQuery,
  useCreatePlaceTypeMutation,
  useUpdatePlaceTypeMutation,
  useGetPlaceTypePreviewMutation,
  useUpdateBuildingTypeInfosMutation,
  useUpdatePlaceTypeInfosMutation,
  useGetLibraryQuery,
  useGetLibraryBuildingTypesQuery,
  useGetLibraryBuildingsQuery,
  useUpdateLibraryDefaultsMutation,
  useGetLibraryPlaceTypesQuery,
  useUpdateLibraryVariablesMutation,
  useGetCurrentUserQuery,
  useCanPerformActionsQuery,
  useGetInvitationsForUserQuery,
  useRejectUserInvitationMutation,
  useGetUserInvitationQuery,
  useAcceptUserInvitationMutation,
  useCancelTaskMutation,
  useCreateDatabankMutation,
  useLoadDatabankDataMutation,
  useLoadDatabankGeosMutation,
  useAddDatabankToOrgMutation,
  useDownloadExportedLayerQuery,
  useDownloadExportedQuery,
  useGetDynamicLayerQuery,
  useGetDynamicLayerFilterQuery,
  useUpdateDynamicLayerFilterMutation,
  useEmailVerifiedQuery,
  useForgotPasswordQuery,
  useLoadTemporaryShapesMutation,
  useGetBuiltformAstsQuery,
  useBakeLibraryMutation,
  useClearFlaskCacheMutation,
  useCreateDemoProjectMutation,
  useGetDynamicLayerInternalQuery,
  useGetEntityInfoQuery,
  useExportUserDevicesQuery,
  useGetAvailableFeatureFlagsQuery,
  useGetLayersQuery,
  useGetLayerRegistryQuery,
  useGetLayerSummaryQuery,
  useGetLayerMetadataQuery,
  useGetInternalLayerBoundsQuery,
  useGetInternalLayerGeoKeysQuery,
  useGetInternalLayerStatsQuery,
  useClearSubscriptionCacheMutation,
  useGetPaintedCanvasActionsInternalQuery,
  usePingQuery,
  useGetRunningTasksQuery,
  useGetUsersQuery,
  useGetUserDevicesQuery,
  useGetLayerQuery,
  useGetLayerBoundsQuery,
  useGetLayerBreaksMultiQuery,
  useGetLayerBreaksQuery,
  useGetAppLayersByContextQuery,
  useGetLayerDataQuery,
  useGetLayerGeoKeysQuery,
  useGetLayerGeojsonQuery,
  useGetLayerGeometryTypeQuery,
  useGetLatestVersionQuery,
  useSetReferenceLayerMutation,
  useGetLayerSpecQuery,
  useGetLayerStatsQuery,
  useLoginMutation,
  useLoginTokensMutation,
  useLogoutQuery,
  useDownloadMapExportQuery,
  useEnqueueMapExportMutation,
  useDownloadExportedColumnMetadataQuery,
  useEnqueueColumnExportTaskMutation,
  useGetOrganizationsQuery,
  useDeleteOrganizationMutation,
  useGetOrganizationQuery,
  useCreateOrganizationMutation,
  useGetBillingInfoQuery,
  useUpdateBillingInfoMutation,
  useGetCanvasTypesQuery,
  useGetOrganizationFeatureFlagsQuery,
  useGetOrgFilesQuery,
  useGetOrganizationInsightsQuery,
  useUpdateOrganizationInsightsHelpUrlMutation,
  useGetMembersInfoInOrganizationQuery,
  useAddMemberToOrganizationMutation,
  useGetFilteredMemberCountInOrganizationQuery,
  useUpdateOrganizationNameMutation,
  useGetProjectsInOrganizationQuery,
  useRequestSalesContactMutation,
  useGetSubscriptionQuery,
  useChangeSubscriptionPlanMutation,
  useUpdateSubscriptionSeatsMutation,
  useGetOrgFileUrlQuery,
  useDeleteOrgDashboardMutation,
  useGetDashboardEmbedQuery,
  useUpdateOrgDashboardMutation,
  useGetOrgDashboardsQuery,
  useGetInvitationsForOrganizationQuery,
  useInviteUserToOrganizationMutation,
  useCancelInvitationMutation,
  useDeleteOrgLogoMutation,
  useGetOrgLogoInfosQuery,
  useAddOrgLogoMutation,
  useRemoveMemberFromOrganizationMutation,
  useEditMemberInOrganizationMutation,
  useGetPaintedCanvasActionsV2Query,
  useGetPaintedCanvasActionByKeyQuery,
  useGetRevertibleActionsV2Query,
  useListPortfoliosQuery,
  useCreatePortfolioMutation,
  useDeletePortfolioMutation,
  useGetPortfolioQuery,
  useUpdatePortfolioMutation,
  useAddOrgToPortfolioMutation,
  useGetProgramLevelSummaryQuery,
  useGetProjectsQuery,
  useCreateProjectMutation,
  useSetProjectLayerAnalysisRunDashboardMutation,
  useUpdateProjectLayerAnalysisRunDashboardMutation,
  useDeleteProjectLayerAnalysisRunDashboardMutation,
  useSetProjectLayerAnalysisRunOutputTableMutation,
  useSetProjectLayerAnalysisRunDashboardModelMutation,
  useSetProjectLayerAnalysisRunStatusMutation,
  useDeleteProjectMutation,
  useGetProjectQuery,
  useEditProjectMutation,
  useGetAnalysisModulesQuery,
  useGetAnalysisModuleQuery,
  useRunAnalysisModuleForProjectMutation,
  useGetRunningProjectLayerAnalysisRunsQuery,
  useGetProjectAnalysisParamsQuery,
  useGetProjectAvailableLayersQuery,
  useEnqueuePaintBaseScenarioCanvasMutation,
  useEnqueueProjectBufferedLayerTaskMutation,
  useEnqueueProjectConvertLayerTaskMutation,
  useEnqueueCreateBaseCanvasTaskMutation,
  useEnqueueCreateContextCanvasTaskMutation,
  useCreateProjectDynamicLayerMutation,
  useEnqueuePaintScenarioCanvasMutation,
  useExportModuleParamsQuery,
  useRemoveFromLayerListMutation,
  useAddToLayerListMutation,
  useUpdateScenarioAnalysisParamsMutation,
  useCreateScenarioMutation,
  useEditScenarioMutation,
  useGetScenarioUpdateInProgressQuery,
  useGetProjectTasksQuery,
  useGetProjectUpdateInProgressQuery,
  useGetProjectWorkingLayersQuery,
  useGetAnalysisOutputModuleMetadataQuery,
  useDeleteScenarioMutation,
  useRunLayerServiceForProjectMutation,
  useEnqueueLayerExportTaskMutation,
  useUpdateLayerNameMutation,
  useDeleteSymbologyMutation,
  useGetSymbologyQuery,
  useAddSymbologyMutation,
  useEnqueueImportLayerMutation,
  useRemoveProjectWorkingLayerMutation,
  useAddProjectWorkingLayerMutation,
  useAddProjectTypeIconMutation,
  useGetProjectTypeLayersQuery,
  useGetProjectTypesQuery,
  useGetProjectsV2Query,
  useGetReportMutation,
  useGetReportXlsMutation,
  useGetUserSettingsQuery,
  useUpdateGlobalSettingMutation,
  useUpdateOrgSettingMutation,
  useUpdateProjectSettingMutation,
  useSigninQuery,
  useSignoutQuery,
  useGetTopicEmbedQuery,
  useListUserPortfoliosQuery,
  useDeleteUserDataMutation,
  useGetUserDataQuery,
  useSetUserDataMutation,
  useListFavoriteLayersQuery,
  useDeleteLayerFromFavoritesMutation,
  useAddLayerToFavoritesMutation,
  useListUserDataKeysQuery,
  useListUserDataItemsQuery,
  useGetVersionQuery,
} = injectedRtkApi;
