import {
  SET_LAYER_VERSIONS,
  UPDATE_LAYER_VERSION,
} from 'uf/layers/ActionTypes';

const initialState = {};

export default function versions(state = initialState, action) {
  switch (action.type) {
    case SET_LAYER_VERSIONS:
      return {
        ...state,
        ...action.versions,
      };
    case UPDATE_LAYER_VERSION:
      return {
        ...state,
        [action.layerId]: action.version,
      };
    default:
      return state;
  }
}
