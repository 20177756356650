import _ from 'lodash';
import { t } from 'ttag';
import warning from 'warning';

import {
  BuiltFormPainterEmploymentAllocation,
  BuiltFormPainterEmploymentAllocations,
  BuiltFormPainterEmploymentSubCategoryWeight,
  Library,
} from 'uf-api';
import { FormatNumericOptions } from 'uf/base/formatting';
import { typeAssertNever } from 'uf/base/never';
import { getUnitAbbreviation } from 'uf/base/units';
import { CanvasColumnKey, NumericCanvasColumnKey } from 'uf/builtforms';
import {
  EmploymentCategoryType,
  EmploymentTypeInfo,
} from 'uf/builtforms/employment';

export const employmentAllocationsTypeNames: Record<
  BuiltFormPainterEmploymentAllocations.AllocationModeEnum,
  string
> = {
  [BuiltFormPainterEmploymentAllocations.AllocationModeEnum
    .Feature]: t`Apply per feature`,
  [BuiltFormPainterEmploymentAllocations.AllocationModeEnum
    .Acre]: t`Apply per acre`,
};

// Array in the order we want them in the UI
export const EmploymentAllocationModes = [
  BuiltFormPainterEmploymentAllocations.AllocationModeEnum.Feature,
  BuiltFormPainterEmploymentAllocations.AllocationModeEnum.Acre,
];

export enum EmploymentEditMode {
  BuildingFloorArea = 'BuildingFloorArea',
  Employees = 'Employees',
}

export const employmentEditModeNames: Record<EmploymentEditMode, string> = {
  [EmploymentEditMode.BuildingFloorArea]: t`Building floor area`,
  [EmploymentEditMode.Employees]: t`Employees`,
};

// Array in the order we want them in the UI
export const EmploymentEditModes = [
  EmploymentEditMode.BuildingFloorArea,
  EmploymentEditMode.Employees,
];

export const employmentDensityNames: Record<
  BuiltFormPainterEmploymentAllocation.DensityEnum,
  string
> = {
  [BuiltFormPainterEmploymentAllocation.DensityEnum.Low]: t`Low`,
  [BuiltFormPainterEmploymentAllocation.DensityEnum.Mid]: t`Medium`,
  [BuiltFormPainterEmploymentAllocation.DensityEnum.High]: t`High`,
};
export const employmentDensityOrderedList = [
  BuiltFormPainterEmploymentAllocation.DensityEnum.Low,
  BuiltFormPainterEmploymentAllocation.DensityEnum.Mid,
  BuiltFormPainterEmploymentAllocation.DensityEnum.High,
];

export interface EmploymentAllocation {
  id: string;
  employmentType: EmploymentTypeInfo;
  employees: number;
  density: BuiltFormPainterEmploymentAllocation.DensityEnum;
  buildingArea: number;
  subsectors?: EmploymentSubsectorAllocation[];
}

export interface EmploymentSubsectorAllocation {
  id: BuiltFormPainterEmploymentSubCategoryWeight.SubcategoryKeyEnum;
  title: string;
  buildingArea: number;
  percentEmployment: number;
  density: number;
  densityUnits: string;
  employees: number;
  canvasColumnKey: CanvasColumnKey;
  buildingAreaColumnKey: NumericCanvasColumnKey;
}
/**
 * Detailed information about a residential painting. This data will be boiled
 * down into a single paint
 */
export interface EmploymentDirectPaintingState {
  editMode: EmploymentEditMode;
  allocationMode: BuiltFormPainterEmploymentAllocations.AllocationModeEnum;
  allocations: EmploymentAllocation[];
  employeeTotal: number;
  isZero: boolean;
}

interface EmploymentDensityKeys {
  [BuiltFormPainterEmploymentAllocation.DensityEnum.Low]: string;
  [BuiltFormPainterEmploymentAllocation.DensityEnum.Mid]: string;
  [BuiltFormPainterEmploymentAllocation.DensityEnum.High]: string;
}

interface EmploymentSubsectorInfo {
  id: BuiltFormPainterEmploymentSubCategoryWeight.SubcategoryKeyEnum;
  title: string;
  percentEmploymentKey: string;
  densityKeys: EmploymentDensityKeys;
  densityUnits: string;
  canvasColumnKey: CanvasColumnKey;
  buildingAreaColumnKey: NumericCanvasColumnKey;
}

const sqftPerEmployee = getUnitAbbreviation('square_feet_per_employee');

const agricultureSubsectors: EmploymentSubsectorInfo[] = [
  {
    id: 'agriculture',
    canvasColumnKey: 'emp_agriculture',
    // no building area for agriculture in the canvas
    buildingAreaColumnKey: null,
    title: t`Agriculture`,
    percentEmploymentKey: 'emp_ag_agriculture_pct',
    densityKeys: {
      [BuiltFormPainterEmploymentAllocation.DensityEnum.Low]:
        'area_per_emp_ag_agriculture_low',
      [BuiltFormPainterEmploymentAllocation.DensityEnum.Mid]:
        'area_per_emp_ag_agriculture_mid',
      [BuiltFormPainterEmploymentAllocation.DensityEnum.High]:
        'area_per_emp_ag_agriculture_high',
    },
    densityUnits: sqftPerEmployee,
  },
  {
    id: 'extraction',
    canvasColumnKey: 'emp_extraction',
    // no building area for extraction in the canvas
    buildingAreaColumnKey: null,
    title: t`Extraction`,
    percentEmploymentKey: 'emp_ag_extraction_pct',
    densityKeys: {
      [BuiltFormPainterEmploymentAllocation.DensityEnum.Low]:
        'area_per_emp_ag_extraction_low',
      [BuiltFormPainterEmploymentAllocation.DensityEnum.Mid]:
        'area_per_emp_ag_extraction_mid',
      [BuiltFormPainterEmploymentAllocation.DensityEnum.High]:
        'area_per_emp_ag_extraction_high',
    },
    densityUnits: sqftPerEmployee,
  },
];

const industrialSubsectors: EmploymentSubsectorInfo[] = [
  {
    id: 'manufacturing',
    title: t`Manufacturing`,
    canvasColumnKey: 'emp_manufacturing',
    // no building area for manufacturing in the canvas
    buildingAreaColumnKey: null,
    percentEmploymentKey: 'emp_ind_manufacturing_pct',
    densityKeys: {
      [BuiltFormPainterEmploymentAllocation.DensityEnum.Low]:
        'area_per_emp_ind_manufacturing_low',
      [BuiltFormPainterEmploymentAllocation.DensityEnum.Mid]:
        'area_per_emp_ind_manufacturing_mid',
      [BuiltFormPainterEmploymentAllocation.DensityEnum.High]:
        'area_per_emp_ind_manufacturing_high',
    },
    densityUnits: sqftPerEmployee,
  },
  {
    id: 'wholesale',
    title: t`Wholesale`,
    canvasColumnKey: 'emp_wholesale',
    buildingAreaColumnKey: 'bldg_area_wholesale',
    percentEmploymentKey: 'emp_ind_wholesale_pct',
    densityKeys: {
      [BuiltFormPainterEmploymentAllocation.DensityEnum.Low]:
        'area_per_emp_ind_wholesale_low',
      [BuiltFormPainterEmploymentAllocation.DensityEnum.Mid]:
        'area_per_emp_ind_wholesale_mid',
      [BuiltFormPainterEmploymentAllocation.DensityEnum.High]:
        'area_per_emp_ind_wholesale_high',
    },
    densityUnits: sqftPerEmployee,
  },
  {
    id: 'transport_warehousing',
    title: t`Transportation`,
    canvasColumnKey: 'emp_transport_warehousing',
    buildingAreaColumnKey: 'bldg_area_transport_warehousing',
    percentEmploymentKey: 'emp_ind_transport_warehousing_pct',
    densityKeys: {
      [BuiltFormPainterEmploymentAllocation.DensityEnum.Low]:
        'area_per_emp_ind_transport_warehousing_low',
      [BuiltFormPainterEmploymentAllocation.DensityEnum.Mid]:
        'area_per_emp_ind_transport_warehousing_mid',
      [BuiltFormPainterEmploymentAllocation.DensityEnum.High]:
        'area_per_emp_ind_transport_warehousing_high',
    },
    densityUnits: sqftPerEmployee,
  },
  {
    id: 'construction',
    title: t`Construction`,
    canvasColumnKey: 'emp_construction',
    // no building area for construction in the canvas
    buildingAreaColumnKey: null,
    percentEmploymentKey: 'emp_ind_construction_pct',
    densityKeys: {
      [BuiltFormPainterEmploymentAllocation.DensityEnum.Low]:
        'area_per_emp_ind_construction_low',
      [BuiltFormPainterEmploymentAllocation.DensityEnum.Mid]:
        'area_per_emp_ind_construction_mid',
      [BuiltFormPainterEmploymentAllocation.DensityEnum.High]:
        'area_per_emp_ind_construction_high',
    },
    densityUnits: sqftPerEmployee,
  },
  {
    id: 'utilities',
    title: t`Utilities`,
    canvasColumnKey: 'emp_utilities',
    // no building area for utilities in the canvas
    buildingAreaColumnKey: null,
    percentEmploymentKey: 'emp_ind_utilities_pct',
    densityKeys: {
      [BuiltFormPainterEmploymentAllocation.DensityEnum.Low]:
        'area_per_emp_ind_utilities_low',
      [BuiltFormPainterEmploymentAllocation.DensityEnum.Mid]:
        'area_per_emp_ind_utilities_mid',
      [BuiltFormPainterEmploymentAllocation.DensityEnum.High]:
        'area_per_emp_ind_utilities_high',
    },
    densityUnits: sqftPerEmployee,
  },
];

const officeSubsectors: EmploymentSubsectorInfo[] = [
  {
    id: 'medical_services',
    title: t`Medical services`,
    canvasColumnKey: 'emp_medical_services',
    buildingAreaColumnKey: 'bldg_area_medical_services',
    percentEmploymentKey: 'emp_off_medical_services_pct',
    densityKeys: {
      [BuiltFormPainterEmploymentAllocation.DensityEnum.Low]:
        'area_per_emp_off_medical_services_low',
      [BuiltFormPainterEmploymentAllocation.DensityEnum.Mid]:
        'area_per_emp_off_medical_services_mid',
      [BuiltFormPainterEmploymentAllocation.DensityEnum.High]:
        'area_per_emp_off_medical_services_high',
    },
    densityUnits: sqftPerEmployee,
  },
  {
    id: 'office_services',
    title: t`Office services`,
    canvasColumnKey: 'emp_office_services',
    buildingAreaColumnKey: 'bldg_area_office_services',
    percentEmploymentKey: 'emp_off_office_services_pct',
    densityKeys: {
      [BuiltFormPainterEmploymentAllocation.DensityEnum.Low]:
        'area_per_emp_off_office_services_low',
      [BuiltFormPainterEmploymentAllocation.DensityEnum.Mid]:
        'area_per_emp_off_office_services_mid',
      [BuiltFormPainterEmploymentAllocation.DensityEnum.High]:
        'area_per_emp_off_office_services_high',
    },
    densityUnits: sqftPerEmployee,
  },
];

const publicSubsectors: EmploymentSubsectorInfo[] = [
  {
    id: 'education',
    title: t`Education`,
    canvasColumnKey: 'emp_education',
    buildingAreaColumnKey: 'bldg_area_education',
    percentEmploymentKey: 'emp_pub_education_pct',
    densityKeys: {
      [BuiltFormPainterEmploymentAllocation.DensityEnum.Low]:
        'area_per_emp_pub_education_low',
      [BuiltFormPainterEmploymentAllocation.DensityEnum.Mid]:
        'area_per_emp_pub_education_mid',
      [BuiltFormPainterEmploymentAllocation.DensityEnum.High]:
        'area_per_emp_pub_education_high',
    },
    densityUnits: sqftPerEmployee,
  },
  {
    id: 'public_admin',
    title: t`Public administration`,
    canvasColumnKey: 'emp_public_admin',
    buildingAreaColumnKey: 'bldg_area_public_admin',
    percentEmploymentKey: 'emp_pub_public_admin_pct',
    densityKeys: {
      [BuiltFormPainterEmploymentAllocation.DensityEnum.Low]:
        'area_per_emp_pub_public_admin_low',
      [BuiltFormPainterEmploymentAllocation.DensityEnum.Mid]:
        'area_per_emp_pub_public_admin_mid',
      [BuiltFormPainterEmploymentAllocation.DensityEnum.High]:
        'area_per_emp_pub_public_admin_high',
    },
    densityUnits: sqftPerEmployee,
  },
];

const retailSubsectors: EmploymentSubsectorInfo[] = [
  {
    id: 'accommodation',
    title: t`Accommodation`,
    canvasColumnKey: 'emp_accommodation',
    buildingAreaColumnKey: 'bldg_area_accommodation',
    percentEmploymentKey: 'emp_ret_accommodation_pct',
    densityKeys: {
      [BuiltFormPainterEmploymentAllocation.DensityEnum.Low]:
        'area_per_emp_ret_accommodation_low',
      [BuiltFormPainterEmploymentAllocation.DensityEnum.Mid]:
        'area_per_emp_ret_accommodation_mid',
      [BuiltFormPainterEmploymentAllocation.DensityEnum.High]:
        'area_per_emp_ret_accommodation_high',
    },
    densityUnits: sqftPerEmployee,
  },
  {
    id: 'arts_entertainment',
    title: t`Entertainment`,
    canvasColumnKey: 'emp_arts_entertainment',
    buildingAreaColumnKey: 'bldg_area_arts_entertainment',
    percentEmploymentKey: 'emp_ret_arts_entertainment_pct',
    densityKeys: {
      [BuiltFormPainterEmploymentAllocation.DensityEnum.Low]:
        'area_per_emp_ret_arts_entertainment_low',
      [BuiltFormPainterEmploymentAllocation.DensityEnum.Mid]:
        'area_per_emp_ret_arts_entertainment_mid',
      [BuiltFormPainterEmploymentAllocation.DensityEnum.High]:
        'area_per_emp_ret_arts_entertainment_high',
    },
    densityUnits: sqftPerEmployee,
  },
  {
    id: 'other_services',
    title: t`Other services`,
    canvasColumnKey: 'emp_other_services',
    buildingAreaColumnKey: 'bldg_area_other_services',
    percentEmploymentKey: 'emp_ret_other_services_pct',
    densityKeys: {
      [BuiltFormPainterEmploymentAllocation.DensityEnum.Low]:
        'area_per_emp_ret_other_services_low',
      [BuiltFormPainterEmploymentAllocation.DensityEnum.Mid]:
        'area_per_emp_ret_other_services_mid',
      [BuiltFormPainterEmploymentAllocation.DensityEnum.High]:
        'area_per_emp_ret_other_services_high',
    },
    densityUnits: sqftPerEmployee,
  },
  {
    id: 'restaurant',
    title: t`Restaurant`,
    canvasColumnKey: 'emp_restaurant',
    buildingAreaColumnKey: 'bldg_area_restaurant',
    percentEmploymentKey: 'emp_ret_restaurant_pct',
    densityKeys: {
      [BuiltFormPainterEmploymentAllocation.DensityEnum.Low]:
        'area_per_emp_ret_restaurant_low',
      [BuiltFormPainterEmploymentAllocation.DensityEnum.Mid]:
        'area_per_emp_ret_restaurant_mid',
      [BuiltFormPainterEmploymentAllocation.DensityEnum.High]:
        'area_per_emp_ret_restaurant_high',
    },
    densityUnits: sqftPerEmployee,
  },
  {
    id: 'retail_services',
    title: t`Retail services`,
    canvasColumnKey: 'emp_retail_services',
    buildingAreaColumnKey: 'bldg_area_retail_services',
    percentEmploymentKey: 'emp_ret_retail_services_pct',
    densityKeys: {
      [BuiltFormPainterEmploymentAllocation.DensityEnum.Low]:
        'area_per_emp_ret_retail_services_low',
      [BuiltFormPainterEmploymentAllocation.DensityEnum.Mid]:
        'area_per_emp_ret_retail_services_mid',
      [BuiltFormPainterEmploymentAllocation.DensityEnum.High]:
        'area_per_emp_ret_retail_services_high',
    },
    densityUnits: sqftPerEmployee,
  },
];

const militaryDensityKeys = {
  [BuiltFormPainterEmploymentAllocation.DensityEnum.Low]:
    'area_per_emp_military_low',
  [BuiltFormPainterEmploymentAllocation.DensityEnum.Mid]:
    'area_per_emp_military_mid',
  [BuiltFormPainterEmploymentAllocation.DensityEnum.High]:
    'area_per_emp_military_high',
};

/**
 * military does not have subsectors, so we need another lookup table
 */
const employmentDensityKeysByEmploymentType: Partial<
  Record<EmploymentCategoryType, EmploymentDensityKeys>
> = {
  emp_military: militaryDensityKeys,
};

export const employmentSubsectorInfosByEmploymentType: Record<
  EmploymentCategoryType,
  EmploymentSubsectorInfo[]
> = {
  emp_ag: agricultureSubsectors,
  emp_ind: industrialSubsectors,
  emp_off: officeSubsectors,
  emp_pub: publicSubsectors,
  emp_ret: retailSubsectors,
  emp_military: [],
};

export const employmentBuildingAreaKeys = _.map(
  employmentSubsectorInfosByEmploymentType,
  subsectorInfo => {
    return subsectorInfo.map(info => info.buildingAreaColumnKey);
  },
).flat();

/**
 * Sums the employee count of all allocations
 */
export function getEmployeeTotal(
  allocations: EmploymentAllocation[],
  librarySettings: Library,
  isZero: boolean,
): number {
  if (isZero) {
    return 0;
  }
  const hasEmployeeValues = allocations.some(({ employees }) =>
    Number.isFinite(employees),
  );
  const employeeTotal = hasEmployeeValues
    ? _.sum(
        allocations.map(allocation =>
          getEmployeesForAllocation(allocation, librarySettings),
        ),
      )
    : null;
  return employeeTotal;
}

/**
 * Returns the employee count for a single allocation, assuming we know what the building area is.
 *
 * If there are no subsectors for the allocations's employment category the employment is simply:
 *   employment = buildingArea * density
 *
 * If there are subsectors we have to solve the equation:
 *   buildingArea = P1*E*D1 + P2*E*D2 ... Pn*E*Dn
 *
 * Or:
 *   E = buildingArea / (P1*D1 + P2*D2 ... PnDn)
 *
 * Where:
 *   P = percentEmployment for the subsector,
 *   E = total employment for the allocation,
 *   D = density in (ft^2/employees) for the subsector
 *
 * @param allocation
 * @param librarySettings
 */
export function getEmployeesForAllocation(
  allocation: EmploymentAllocation,
  librarySettings: Library,
): number {
  const { buildingArea, density, employmentType } = allocation;
  if (!Number.isFinite(buildingArea)) {
    return null;
  }

  const subsectorInfos =
    employmentSubsectorInfosByEmploymentType[employmentType.empKey];

  // solve for:  employment = buildingArea * density
  if (_.isEmpty(subsectorInfos)) {
    const densityKeysByDensity =
      employmentDensityKeysByEmploymentType[employmentType.empKey];
    const densityKey = densityKeysByDensity[density];
    const densityValue: number = librarySettings.variables[densityKey];
    const employees = allocation.buildingArea / densityValue;
    return Number.isFinite(employees) ? employees : 0;
  }

  // solve for:  buildingArea = P1*E*D1 + P2*E*D2 ... Pn*E*Dn
  const weightedDensities = _.sum(
    subsectorInfos.map(subsector => {
      const { percentEmploymentKey, densityKeys } = subsector;

      const percentEmployees =
        librarySettings?.defaults?.building?.[percentEmploymentKey];
      const densityKey = densityKeys[density];
      const densityValue = librarySettings?.variables?.[densityKey];

      return percentEmployees * densityValue;
    }),
  );

  const employees = buildingArea / weightedDensities;

  return Number.isFinite(employees) ? employees : null;
}

/**
 * Returns a list of subsector allocations for an allocation.  Calculates the employees and
 * buildingArea for each subsector.  If the user is currently editing the buildingArea for the
 * allocation mode (edit mode set to 'BuildingArea'), then use that variable update values in each
 * subsector.  Likewise if 'Employees' is set
 * @param allocation The allocation to calculate subsector allocations for
 * @param mode The edit mode.  The allocation variable the user is editing, either buildingArea or
 * employees
 * @param librarySettings
 */
export function getSubsectorAllocations(
  allocation: EmploymentAllocation,
  mode: EmploymentEditMode,
  librarySettings: Library,
): EmploymentSubsectorAllocation[] {
  const employmentType = allocation.employmentType.empKey;
  const subsectors = employmentSubsectorInfosByEmploymentType[employmentType];
  if (!subsectors) {
    return null;
  }
  const totalPercent = _.sum(
    subsectors.map(
      ({ percentEmploymentKey }) =>
        librarySettings?.defaults?.building?.[percentEmploymentKey],
    ),
  );
  warning(
    totalPercent === 1,
    `Allocations do not add up to 100%: ${totalPercent}`,
  );

  const { density: densityLevel } = allocation;

  let totalEmployees = null;
  switch (mode) {
    case EmploymentEditMode.BuildingFloorArea: {
      totalEmployees = getEmployeesForAllocation(allocation, librarySettings);
      break;
    }
    case EmploymentEditMode.Employees: {
      totalEmployees = allocation.employees;
      break;
    }
    default:
      typeAssertNever(mode);
  }
  return subsectors.map(subsector => {
    const {
      id,
      title,
      percentEmploymentKey,
      densityKeys,
      densityUnits,
      canvasColumnKey,
      buildingAreaColumnKey,
    } = subsector;

    const percentEmployment =
      librarySettings?.defaults?.building?.[percentEmploymentKey];
    const densityKey = densityKeys[densityLevel];
    const density = librarySettings?.variables?.[densityKey];

    // if the user is editing building floor area, then we calculate employees.  Conversely, if the
    // user is editing the employees directly, then we calculate the building floor area.
    let employees = null;
    let buildingArea = null;
    if (
      percentEmployment &&
      density &&
      Number.isFinite(density) &&
      Number.isFinite(totalEmployees)
    ) {
      employees = totalEmployees * percentEmployment;
      buildingArea = totalEmployees * percentEmployment * density;
    }

    const subsectorAllocation: EmploymentSubsectorAllocation = {
      id,
      title,
      percentEmployment,
      density,
      densityUnits,
      buildingArea,
      employees,
      canvasColumnKey,
      buildingAreaColumnKey,
    };

    return subsectorAllocation;
  });
}

/**
 * Returns a list of employment allocations.  calculates employees for each allocation.
 * @param allocations
 * @param librarySettings
 */
export function updateEmployeesForAllocations(
  allocations: EmploymentAllocation[],
  librarySettings: Library,
): EmploymentAllocation[] {
  return allocations.map(allocation => {
    const employees = getEmployeesForAllocation(allocation, librarySettings);
    return {
      ...allocation,
      employees,
    };
  });
}

/*
 * Returns the employee count for a single allocation.  This is either calculated by summing the
 * subsector employee counts or, if there are no subsectors, by dividing the building area by the
 * density.
 *
 * @param allocation
 * @param librarySettings
 */
export function getBuildingAreaForAllocation(
  allocation: EmploymentAllocation,
  librarySettings: Library,
): number {
  const { density, employmentType } = allocation;

  const subsectorInfos =
    employmentSubsectorInfosByEmploymentType[employmentType.empKey];

  if (_.isEmpty(subsectorInfos)) {
    const densityKeysByDensity =
      employmentDensityKeysByEmploymentType[employmentType.empKey];
    const densityKey = densityKeysByDensity[density];
    const densityValue: number = librarySettings.variables[densityKey];
    const buildingArea = allocation.employees * densityValue;
    return Number.isFinite(buildingArea) ? buildingArea : null;
  }

  const subsectorAllocations = getSubsectorAllocations(
    allocation,
    EmploymentEditMode.Employees,
    librarySettings,
  );

  const hasBuildingAreaValues = subsectorAllocations.some(({ buildingArea }) =>
    Number.isFinite(buildingArea),
  );
  const buildingArea = hasBuildingAreaValues
    ? _.sum(subsectorAllocations.map(item => item.buildingArea))
    : null;

  return buildingArea;
}
export function updateBuildingAreaForAllocations(
  allocations: EmploymentAllocation[],
  librarySettings: Library,
): EmploymentAllocation[] {
  return allocations.map(allocation => {
    const buildingArea = getBuildingAreaForAllocation(
      allocation,
      librarySettings,
    );
    return {
      ...allocation,
      buildingArea,
    };
  });
}

/**
 * Checks for the special case where the user chose to 'zero out' employment
 */
export function isZeroedAllocation(
  allocations: EmploymentAllocation[],
  empTotal: number,
) {
  return allocations.length === 0 && empTotal === 0;
}

// employees should be formatted to have 1 decimal place
export const employeeFormattingOptions: FormatNumericOptions = {
  maximumFractionDigits: 1,
  minimumFractionDigits: 1,
  fractionalPrecision: 0,
};

// building area should be rounded to the nearest whole number
export const buildingAreaFormattingOptions: FormatNumericOptions = {
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
  fractionalPrecision: 0,
};
