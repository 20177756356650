import { Epic, ofType, StateObservable } from 'redux-observable';
import { concat, defer, of as observableOf } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';

import { LayerBounds, UserData } from 'uf-api';
import { getLayerBoundsActionTypes } from 'uf-api/api/layer.service';
import {
  ACTIVE_PROJECT_READY,
  ActiveProjectReadyAction,
} from 'uf/app/ActionTypes';
import {
  getActiveProjectId,
  getActiveProjectProjectBounds,
} from 'uf/app/selectors';
import { combineEpics } from 'uf/base/epics';
import {
  getCenterFromBounds,
  getLngLatBoundsFromLayerBounds,
  getZoomFromBounds,
} from 'uf/base/map';
import { SwaggerThunkExtra } from 'uf/base/xhr';
import { getData, isLoaded, isLoading } from 'uf/data/dataState';
import { makeEnsureFetchStream } from 'uf/data/getFetchStream';
import {
  setExploreMapViewport,
  updateMapViewport,
} from 'uf/explore/actions/map';
import {
  UPDATE_MAP_VIEWPORT,
  UpdateMapViewportAction,
  ZOOM_TO_LAYER,
  ZoomToLayerAction,
} from 'uf/explore/ActionTypes';
import { makeGetLayerBoundsWithEdits } from 'uf/explore/selectors/bounds';
import {
  getExploreMapHeight,
  getExploreMapViewport,
  getExploreMapWidth,
} from 'uf/explore/selectors/map';
import { LayerId } from 'uf/layers';
import { fetchLayerBounds } from 'uf/layers/apis';
import {
  convertFiltersToExpressions,
  FilterSpec,
  getLayerDataApiParams,
} from 'uf/layers/filters';
import { makeGetLayerBounds } from 'uf/layers/selectors/bounds';
import { MapViewport } from 'uf/map';
import { createPersistKey, PERSIST_PREFIX } from 'uf/persistence';
import { ProjectId } from 'uf/projects';
import { UFState } from 'uf/state';
import { getUser, makeGetUserDataStateByPrefix } from 'uf/user/selectors/user';

interface ZoomExtra {
  key: string;
  layerId: LayerId;
  filters: Partial<FilterSpec>;
}

interface GetLayerBoundsParams {
  projectId: ProjectId;
  layerId: LayerId;
  version: string;
  filters: Partial<FilterSpec>;
}

/**
 *
 */
const ensureLayerBoundsStream = makeEnsureFetchStream(
  getLayerBoundsActionTypes,
  makeGetLayerBounds,
  fetchValue,
  makeExtra,
);
export const zoomToLayerEpic: Epic<
  ZoomToLayerAction,
  any,
  UFState,
  SwaggerThunkExtra
> = (action$, state$, { client }) => {
  const getLayerBounds = makeGetLayerBoundsWithEdits();

  return action$.pipe(
    ofType(ZOOM_TO_LAYER),
    mergeMap(zoomAction => {
      const {
        layerId,
        projectId,
        layerVersion: version,
        layerFilters: filters,
      } = zoomAction;
      const params: GetLayerBoundsParams = {
        projectId,
        layerId,
        version,
        filters,
      };
      return concat(
        ensureLayerBoundsStream(action$, state$, client, params),
        defer(() => {
          const bounds = getLayerBounds(state$.value, params);
          if (bounds) {
            return observableOf(
              updateViewportFromState(projectId, bounds, state$),
            );
          }
        }),
      );
    }),
  );
};

/**
 * An epic to emit an action containing all properties of MapViewport.  UPDATE_MAP_VIEWPORT is
 * allowed to contain only the updated portions of a viewport.  However, we want to persist all the
 * properties of the viewport so we make sure to include all of them here.
 */
export const setExploreMapViewportEpic: Epic<UpdateMapViewportAction, any> = (
  action$,
  state$,
) => {
  return action$.pipe(
    ofType(UPDATE_MAP_VIEWPORT),
    // if updateMapViewport saves to redux, we DONT fire setExploreMapViewport.  This allows
    // multiple updates to happen before we fire this action.  We need this to make sure we don't
    // clobber redux when multiple updates happen before we setExploreMapViewport.  The normal case
    // is when updateMapViewport does not save to redux, and this epic picks up those changes, and
    // then fires setExploreMapViewport to update redux and also persist the viewport to user-data.
    filter(({ save }) => !save),
    map(({ viewport }) => {
      const state = state$.value;
      // TODO: #16638 pass projectId in to updateMapViewport
      const projectId = getActiveProjectId(state);
      const oldViewport = getExploreMapViewport(state, { projectId });
      const updatedViewport = { ...oldViewport, ...viewport };
      return setExploreMapViewport(projectId, updatedViewport);
    }),
  );
};

/**
 * An epic to set a default viewport for a project if no persisted values exist.
 */
export const setViewportOnProjectReadyEpic: Epic<
  ActiveProjectReadyAction,
  any
> = (action$, state$) => {
  const getPersistedDataStates = makeGetUserDataStateByPrefix(
    'prefix',
    'userKey',
  );
  return action$.pipe(
    ofType(ACTIVE_PROJECT_READY),
    map(({ projectId }) => {
      const state = state$.value;
      const { key: userKey } = getUser(state);

      const persistKey = createPersistKey('exploreMapViewport', [projectId]);
      const userPersistKey = `${userKey}:${persistKey}`;

      const persistedDataStates = getPersistedDataStates(state, {
        prefix: PERSIST_PREFIX,
        userKey,
      });
      // TODO: make a selector for this specifically?
      const persistedExploreViewportState = persistedDataStates[userPersistKey];

      // set default if no persisted value found
      if (
        !isLoaded(persistedExploreViewportState) &&
        !isLoading(persistedExploreViewportState)
      ) {
        const { height, width } = getExploreMapViewport(state$.value, {
          projectId,
        });
        let viewport: Partial<MapViewport> = { pitch: 0, bearing: 0 };
        const bounds = getActiveProjectProjectBounds(state$.value);

        if (bounds) {
          const zoom = getZoomFromBounds(bounds, height, width);
          const center = getCenterFromBounds(bounds);
          viewport = { ...viewport, center, zoom };
        }

        return updateMapViewport(projectId, viewport);
      }

      // otherwise use persisted value
      const persistedViewport = getData<UserData<{ value: MapViewport }>>(
        persistedExploreViewportState,
      )?.value?.value;
      return setExploreMapViewport(projectId, persistedViewport);
    }),
  );
};

export default combineEpics(
  {
    zoomToLayerEpic,
    setExploreMapViewportEpic,
    setDefaultExploreMapViewportEpic: setViewportOnProjectReadyEpic,
  },
  'map',
);

function makeExtra(params: GetLayerBoundsParams): ZoomExtra {
  const { layerId, version, filters } = params;
  const { key } = getLayerDataApiParams(layerId, {
    filters,
    version,
  });
  return { key, layerId, filters };
}

function fetchValue(params: GetLayerBoundsParams) {
  const { layerId, version, filters } = params;
  const fetcher = fetchLayerBounds(
    layerId,
    version,
    convertFiltersToExpressions(filters),
  );
  return fetcher;
}

// TODO: related to #16638 pass projectId in to this function
function updateViewportFromState(
  projectId: ProjectId,
  layerBounds: LayerBounds,
  state$: StateObservable<UFState>,
) {
  const bounds = getLngLatBoundsFromLayerBounds(layerBounds);
  const mapWidth = getExploreMapWidth(state$.value, { projectId });
  const mapHeight = getExploreMapHeight(state$.value, { projectId });
  const zoom = getZoomFromBounds(bounds, mapWidth, mapHeight);
  const center = getCenterFromBounds(bounds);
  // TODO: related to #16638 pass projectId in to this action
  return updateMapViewport(projectId, { zoom, center });
}
