import classNames from 'classnames';
import * as React from 'react';

import rootStyles from 'uf/styles/root.module.css';
import { tw } from 'uf/tailwindcss-classnames';

import styles from './CollapsibleListItem.module.css';

const listGroupItemClass = classNames(
  // Reset default padding so that items in the list are closer together
  rootStyles.thinPadding,

  // Remove rounded corners so items look better when stacked
  rootStyles.noBorderRadius,

  rootStyles.defaultBorderColor,
  styles.listItem,
);

export interface CollapsibleListItemProps {
  className?: string;
  onClick?: () => void;
  active?: boolean;
  style?: React.CSSProperties;
}
export const CollapsibleListItem: React.FunctionComponent<CollapsibleListItemProps> =
  ({ className, active, ...restProps }) => {
    const activeClassName = active ? tw('tw-bg-blue-50') : null;
    return (
      <div
        className={classNames(listGroupItemClass, className, activeClassName)}
        {...restProps}
      />
    );
  };
