import { createSelector } from 'reselect';
import { LayerMetadata, LayerStats } from 'uf-api';
import { makeGetApiObjectResults } from 'uf/api/selectors';
import { makeGetFromPropsSelector, makeGetterSelector } from 'uf/base/selector';
import { isBuiltFormKey } from 'uf/builtforms';
import {
  combineDataStates,
  DataState,
  EmptyKeyedState,
  getData,
  isLoaded,
} from 'uf/data/dataState';
import { ColumnKey, LayerId } from 'uf/layers';
import { makeGetLayerMetadataState } from 'uf/layers/selectors/metadata';
import { ProjectId } from 'uf/projects';
import {
  LayerColumnSymbology,
  LayerSymbologyEnvelope,
  ViewId,
} from 'uf/symbology';
import { BuiltFormStop } from 'uf/symbology/builtforms';
import { cleanSymbology } from 'uf/symbology/clean';
import { DivideByColumn, DivideByColumnKey } from 'uf/symbology/divideByColumn';
import { makeGetDefaultSymbologyState } from 'uf/symbology/selectors/defaultSymbology';
import {
  makeGetDivideByColumn,
  makeGetDivideByColumnGetter,
  makeGetDivideByColumnState,
} from 'uf/symbology/selectors/divideByColumn';
import { makeGetSymbologyStatsState } from 'uf/symbology/selectors/stats';
import { makeSymbologyUserDataKey } from 'uf/symbology/user';
import { TypedUserData } from 'uf/user/state';
import {
  makeGetBuiltFormsStops,
  makeGetBuiltFormsStopsLoadingState,
} from './builtforms';

export function makeGetUserSymbologyState() {
  return createSelector(
    makeGetFromPropsSelector<ColumnKey, 'columnKey'>('columnKey'),
    makeGetUserSymbologyLoadingState(),
    makeGetUserDataSymbologyState(),
    makeGetDefaultSymbologyState(),
    makeGetLayerMetadataState(),
    makeGetBuiltFormsStops(),
    makeGetSymbologyStatsState(),
    makeGetDivideByColumn(),
    (
      columnKey,
      loadingState,
      userDataSymbologyState,
      defaultSymbologyState,
      layerMetadataState,
      builtFormStops,
      layerStatsState,
      divideByColumn,
    ) => {
      const userSymbology = getData<LayerSymbologyEnvelope>(
        userDataSymbologyState,
        null,
      )?.symbology;

      return getUserSymbologyDataState(
        columnKey,
        loadingState,
        userSymbology,
        defaultSymbologyState,
        layerMetadataState,
        builtFormStops,
        layerStatsState,
        divideByColumn,
      );
    },
  );
}

export function makeGetUserSymbologyStateGetter() {
  return makeGetterSelector(makeGetUserSymbologyState());
}

/**
 *  Gets a DataState for user symbology after it has been 'cleaned'
 */
function getUserSymbologyDataState(
  columnKey: ColumnKey,
  loadingState: DataState<null>,
  userSymbology: LayerColumnSymbology[],
  defaultSymbologyState: DataState<LayerColumnSymbology[]>,
  layerMetadataState: DataState<LayerMetadata>,
  builtFormStops: BuiltFormStop[],
  layerStatsState: DataState<LayerStats>,
  divideByColumn: DivideByColumnKey,
): DataState<LayerColumnSymbology[]> {
  const defaultSymbology = getData(defaultSymbologyState, null);
  const layerMetadata = getData(layerMetadataState, null);
  const layerStats = getData(layerStatsState, null);

  const haveSupportingData = !!(layerMetadata && layerStats);
  if (!isLoaded(loadingState) || !haveSupportingData) {
    return {
      ...loadingState,
      // If we do actually have user symbology, count it as loaded. Note that
      // this state might still be *loading*, but this validates current user
      // data as usable for updates during a save.
      loaded: !!(userSymbology && haveSupportingData),
      data: userSymbology,
    };
  }

  // if not found in viewSymbology, will be undefined.  if not found in userDataSymbology, will be
  // null.
  if (!userSymbology) {
    return { ...loadingState, data: null };
  }

  const symbology = cleanSymbology(
    columnKey,
    divideByColumn,
    userSymbology,
    defaultSymbology,
    layerMetadata,
    layerStats,
    builtFormStops,
  );

  // Merge the combined loading state with the data so that all the
  // dataState methods work as expected, eg: isLoading(), isLoaded(),
  // getData(), etc..
  return { ...loadingState, data: symbology };
}
export function makeHasUserSymbology() {
  return createSelector(makeGetUserSymbologyState(), userSymbologyState => {
    return !!getData(userSymbologyState, null);
  });
}

/**
 * temporary selector to get the correct loading state while we migrate to view symbology
 */
function makeGetSymbologyLoadingState() {
  return createSelector(makeGetUserDataSymbologySavingState(), savingState => {
    return savingState;
  });
}

// TODO: Delete this in favor of the getter selector for callers in this file
export function makeGetUserSymbologyLoadingState() {
  return createSelector(
    makeGetDivideByColumnState(),
    makeGetSymbologyLoadingState(),
    makeGetLayerMetadataState(),
    makeGetBuiltFormsStopsLoadingState(),
    makeGetSymbologyStatsState(),
    makeGetFromPropsSelector<ColumnKey, 'columnKey'>('columnKey'),
    (
      divideByColumnState,
      symbologyLoadingState,
      layerMetadataState,
      builtFormStopsLoadingState,
      layerStatsState,
      columnKey,
    ) => {
      return getUserSymbologyLoadingState(
        divideByColumnState,
        symbologyLoadingState,
        layerMetadataState,
        layerStatsState,
        isBuiltFormKey(columnKey) ? builtFormStopsLoadingState : null,
      );
    },
  );
}

function getUserSymbologyLoadingState(
  divideByColumnState: DataState<TypedUserData<DivideByColumn>>,
  userSymbologyLoadingState: DataState<LayerSymbologyEnvelope>,
  layerMetadataState: DataState<LayerMetadata>,
  layerStatsState: DataState<LayerStats>,
  builtFormStopsLoadingState?: DataState<null>,
): DataState<null> {
  const loadingStates: DataState<
    | TypedUserData<DivideByColumn>
    | LayerSymbologyEnvelope
    | LayerMetadata
    | BuiltFormStop[]
    | LayerStats
  >[] = [
    divideByColumnState,
    userSymbologyLoadingState,
    layerMetadataState,
    layerStatsState,
  ];

  if (builtFormStopsLoadingState) {
    loadingStates.push(builtFormStopsLoadingState);
  }

  return combineDataStates(loadingStates);
}

/**
 * Gets the data state for a particular layer's symbology.  This does not return the actual
 * symbology, just the data state.  This is useful for obtaining the loading state.
 */
export function makeGetUserDataSymbologySavingState() {
  return createSelector(
    makeGetFromPropsSelector<ProjectId, 'projectId'>('projectId'),
    makeGetFromPropsSelector<LayerId, 'layerId'>('layerId'),
    makeGetFromPropsSelector<ColumnKey, 'columnKey'>('columnKey'),
    makeGetFromPropsSelector<ViewId, 'viewId'>('viewId'),
    makeGetUserDataSymbologySavingStateGetter(),
    (
      projectId,
      layerId,
      columnKey,
      viewId,
      getUserDataSymbologySavingState,
    ): DataState<LayerSymbologyEnvelope> => {
      return getUserDataSymbologySavingState(
        projectId,
        layerId,
        columnKey,
        viewId,
      );
    },
  );
}

export function makeGetUserDataSymbologySavingStateGetter() {
  return createSelector(
    makeGetApiObjectResults('project', 'get_symbology'),
    makeGetDivideByColumnGetter(),
    (
        symbologyStates,
        getDivideByColumn,
      ): ((
        projectId: ProjectId,
        layerId: LayerId,
        columnKey: ColumnKey,
        viewId: ViewId,
      ) => DataState<LayerSymbologyEnvelope>) =>
      (projectId, layerId, columnKey, viewId) => {
        const divideByColumn = getDivideByColumn(projectId, layerId, columnKey);
        const dataKey = makeSymbologyUserDataKey({
          layerId,
          columnKey,
          divideByColumn,
          viewId,
        });
        return (
          (symbologyStates[dataKey] as DataState<LayerSymbologyEnvelope>) ||
          (EmptyKeyedState as DataState<LayerSymbologyEnvelope>)
        );
      },
  );
}

/**
 * gets user symbology from userdata slice of redux`
 */
export function makeGetUserDataSymbologyState() {
  return createSelector(
    makeGetUserDataSymbologySavingStateGetter(),
    makeGetFromPropsSelector<ProjectId, 'projectId'>('projectId'),
    makeGetFromPropsSelector<LayerId, 'layerId'>('layerId'),
    makeGetFromPropsSelector<ColumnKey, 'columnKey'>('columnKey'),
    makeGetFromPropsSelector<ViewId, 'viewId'>('viewId'),
    (getUserDataSymbologyState, projectId, layerId, columnKey, viewId) => {
      return getUserDataSymbologyState(projectId, layerId, columnKey, viewId);
    },
  );
}

export function makeGetUserDataSymbologyStateGetter() {
  return makeGetterSelector(makeGetUserDataSymbologyState());
}
