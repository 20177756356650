import { Position, Tooltip } from '@blueprintjs/core';
import { boundMethod } from 'autobind-decorator';
import _ from 'lodash';
import React, { Component } from 'react';

import NestedIconButton from 'uf/ui/base/NestedIconButton/NestedIconButton';

interface IconToggleButtonProps {
  className?: string;
  selected?: boolean;
  disabled?: boolean;
  onClick?: (selected: boolean) => void;
  iconPath?: string;
  iconToggledPath?: string;
  debounce?: number;
  tooltipText?: string;
  toggledTooltipText?: string;
  tooltipPlacement?: Position;
}

interface IconToggleButtonState {
  selected: boolean;
}

/**
 * Icon class for individual items in the layer list of the layer manager.
 * It also contains props for a blueprint tooltip embedded in the class
 */
export default class IconToggleButton extends Component<
  IconToggleButtonProps,
  IconToggleButtonState
> {
  static defaultProps = {
    onClick: selected => {},
    selected: false,
    debounce: 0,
    tooltipPlacement: Position.TOP,
  };
  onClick: (selected: boolean) => void;

  state: IconToggleButtonState = {
    selected: this.props.selected,
  };

  constructor(props) {
    super(props);
    const { debounce, onClick } = props;

    if (debounce) {
      this.onClick = _.debounce(onClick, debounce);
    } else {
      this.onClick = onClick;
    }
  }

  componentDidUpdate(prevProps: Readonly<IconToggleButtonProps>): void {
    const { selected } = this.props;
    if (prevProps.selected !== selected) {
      this.setState({ selected });
    }
  }

  @boundMethod
  onToggle(e) {
    if (this.props.disabled) {
      return;
    }
    this.setState(state => ({ selected: !state.selected }));
    this.onClick(!this.state.selected);
  }

  render() {
    const {
      className,
      iconToggledPath,
      iconPath,
      onClick,
      tooltipText,
      toggledTooltipText,
      tooltipPlacement,
      ...extraProps
    } = this.props;
    const { selected } = this.state;

    const contentText = selected ? toggledTooltipText : tooltipText;
    const currentIconPath = selected ? iconToggledPath : iconPath;

    return (
      <Tooltip content={contentText} position={tooltipPlacement}>
        <NestedIconButton
          onClick={this.onToggle}
          iconPath={currentIconPath}
          className={className}
          {...extraProps}
        />
      </Tooltip>
    );
  }
}
