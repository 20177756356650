import { Epic } from 'redux-observable';
import { merge as observableMerge, of as observableOf } from 'rxjs';
import { mergeMap, withLatestFrom } from 'rxjs/operators';

import { makeGetProjectAnalysisModuleKeys } from 'uf/analysis/selectors/analysis';
import { clearAnalysisSchema } from 'uf/analysiseditor/actions';
import { NotificationAction } from 'uf/tasks/ActionTypes';
import { NotificationTypes } from 'uf/tasks/NotificationTypes';
import { makeWebsocketActionType } from 'uf/websockets/actionHelpers';

export const clearAnalysisParamsOnProjectUpdate: Epic<NotificationAction, any> =
  (action$, state$) => {
    const getProjectAnalysisModuleKeys = makeGetProjectAnalysisModuleKeys();
    return action$
      .ofType(makeWebsocketActionType(NotificationTypes.PROJECT_UPDATED))
      .pipe(
        withLatestFrom(state$),
        mergeMap(
          ([
            {
              result: { scope: projectId },
            },
            state,
          ]) => {
            const moduleKeys = getProjectAnalysisModuleKeys(state, {
              projectId,
            });
            return observableMerge(
              ...moduleKeys.map(moduleKey =>
                observableOf(clearAnalysisSchema(projectId, moduleKey)),
              ),
            );
          },
        ),
      );
  };
