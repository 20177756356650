import _ from 'lodash';
import warning from 'warning';

import { LegacyReport } from './index';
import {
  accessibilityTransitAnalysisCharts,
  accessibilityTransitCharts,
} from './specs/accessibility_transit';
import {
  accessibilityWalkAnalysisCharts,
  accessibilityWalkCharts,
} from './specs/accessibility_walk';
import { aduCharts } from './specs/adu';
import { censusDemographicsCharts } from './specs/census_demographics';
import { drmCharts } from './specs/drm';
import { emissionsAnalysisCharts, emissionsCharts } from './specs/emissions';
import { energyAnalysisCharts, energyCharts } from './specs/energy';
import { exampleCharts } from './specs/example';
import { fiscalCostCharts } from './specs/fiscal_cost';
import { fiscalCostMadisonCharts } from './specs/fiscal_cost_madison';
import { fiscalRevenueCharts } from './specs/fiscal_revenue';
import { fiscalRevenueMadisonCharts } from './specs/fiscal_revenue_madison';
import { healthCharts } from './specs/health';
import {
  householdCostAnalysisCharts,
  householdCostCharts,
} from './specs/household';
import {
  landConsumptionAnalysisCharts,
  landConsumptionCharts,
} from './specs/land';
import { paintTrackingCharts } from './specs/paintTracking';
import { resiliencyAnalysisCharts, resiliencyCharts } from './specs/resiliency';
import { suitabilityCharts } from './specs/suitability';
import { tncChartsAgriculture } from './specs/tnc_conservation_agriculture';
import { tncAnalysisCharts } from './specs/tnc_conservation_all';
import { tncChartsCarbon } from './specs/tnc_conservation_carbon';
import { tncChartsGen } from './specs/tnc_conservation_general';
import { tncChartsHabitat } from './specs/tnc_conservation_habitat';
import { tncChartsWater } from './specs/tnc_conservation_water';
import { vmtAnalysisCharts, vmtCharts } from './specs/transportation';
import { waterAnalysisCharts, waterCharts } from './specs/water';

/**
 * This is the master list of reporting specs.
 *
 * This is an array of "sections". A section looks like:
 *
 * {
 *   key,   // machine-readable key unique to the list of sections
 *   name,  // Localizable name of the section
 *   charts: [...array of charts ]
 * }
 *
 * Typically the key will match an analysis module, but it doesn't have to!
 *
 * A chart looks like:
 * {
 *   key, // machine-readable key unique to this section
 *   name, // Localizable name of the chart
 *   description, // Longer description, if any
 *   series: [...array of one or more series...],
 *   groupStyle, // 'grouped' or 'stacked' - if there are multiple series (default stacked)
 *   orientation, // 'vertical' or 'horizontal' (def. vertical)
 *   ...etc.. (should style go in its own object?)
 * }
 *
 * A series looks like:
 * {
 *   key, // machine-readable key unique to this chart (?)
 *   source, // one of 'analysis', 'canvas', 'layer', 'program_level', etc
 *   sourceKey: // key relative to 'source', for example:
 *              // * 'water_demand' for analysis means the water_demand layer
 *              // * blank for canvas
 *              // * etc..
 *   layerKey: // The key for the layer
 *   columnKey: // The key for the column
 *   aggregate: // If we should aggregate, the method of aggregation
 * }
 */

// NOTE: These lists are here just for clarity.  The order comes from the analysis_modlues on
// the project.
// fiscalCharts removed from specs temporarily
const defaultReportingCharts: readonly LegacyReport[] = Object.freeze([
  waterCharts,
  energyCharts,
  emissionsCharts,
  householdCostCharts,
  landConsumptionCharts,
  accessibilityWalkCharts,
  accessibilityTransitCharts,
]);

const defaultAnalysisCharts: readonly LegacyReport[] = Object.freeze([
  waterAnalysisCharts,
  energyAnalysisCharts,
  emissionsAnalysisCharts,
  householdCostAnalysisCharts,
  landConsumptionAnalysisCharts,
  accessibilityWalkAnalysisCharts,
  accessibilityTransitAnalysisCharts,
]);

const betaCharts: readonly LegacyReport[] = Object.freeze([
  drmCharts,
  healthCharts,
  fiscalRevenueCharts,
  fiscalRevenueMadisonCharts,
  fiscalCostCharts,
  fiscalCostMadisonCharts,
]);

const conservationCharts: readonly LegacyReport[] = Object.freeze([
  tncChartsCarbon,
  tncChartsWater,
  tncChartsHabitat,
  tncChartsAgriculture,
  tncChartsGen,
]);

const conservationAnalysisCharts: readonly LegacyReport[] = Object.freeze([
  tncAnalysisCharts,
]);

const experimentalCharts: readonly LegacyReport[] = Object.freeze([
  exampleCharts,
  resiliencyCharts,
  censusDemographicsCharts,
  aduCharts,
  suitabilityCharts,
]);

const experimentalAnalysisCharts: readonly LegacyReport[] = Object.freeze([
  exampleCharts,
  resiliencyAnalysisCharts,
  censusDemographicsCharts,
  aduCharts,
  suitabilityCharts,
]);

/**
 * These charts are used by the reporting view - generally these are used to
 * compare multiple scenarios, so the values within a series are stacked into a
 * single bar.
 */
const reportingCharts: readonly LegacyReport[] = Object.freeze([
  ...defaultReportingCharts,
  ...vmtCharts,
  ...betaCharts,
  ...conservationCharts,
  ...experimentalCharts,
]);

/**
 * These charts are used by the analysis chart view - generally these are used
 * for a single scenario, so each value within a series gets its own bar.
 */
const analysisCharts: readonly LegacyReport[] = Object.freeze([
  ...defaultAnalysisCharts,
  ...vmtAnalysisCharts,
  ...betaCharts,
  ...conservationAnalysisCharts,
  ...experimentalAnalysisCharts,
]);

// Charts for the reporting view
const reportingChartsByKey = _.keyBy(reportingCharts, 'key');

// Charts for the analysis view
const analysisChartsByKey = _.keyBy(analysisCharts, 'key');

function checkUniqueKeys(items, label) {
  const keys = items.map(item => item.key).sort();
  const uniqKeys = _.sortedUniq(keys);

  warning(_.isEqual(uniqKeys, keys), `Duplicated keys in ${label}: ${keys}`);
}

export function getReportSpecification(moduleKeys: string[]) {
  return getOrderedChartSpecs(
    filterTNCCharts(moduleKeys),
    reportingChartsByKey,
  );
}

export function getAnalysisSpecification(moduleKeys: string[]) {
  return getOrderedChartSpecs(moduleKeys, analysisChartsByKey);
}

/**
 * Get a list of all charts in the order specified.
 *
 * @param moduleKeys List of module keys
 * @param chartsByKey charts keyed by module key.
 */
function getOrderedChartSpecs(
  moduleKeys: string[],
  chartsByKey: { [key: string]: LegacyReport },
) {
  const reportSpecs: LegacyReport[] = [
    paintTrackingCharts,
    ...moduleKeys.map(key => chartsByKey[key]),
  ];
  checkUniqueKeys(reportSpecs, 'specs');
  reportSpecs.forEach(spec => {
    checkUniqueKeys(spec.charts, 'charts');
    spec.charts.forEach(chart => checkUniqueKeys(chart.series, 'series'));
  });
  return reportSpecs;
}

/**
 * Replace single TNC module with special list of tnc chart specs.
 *
 * @param moduleKeys List of well-known module keys
 */
function filterTNCCharts(moduleKeys: string[]) {
  let filteredModuleKeys = moduleKeys;
  if (moduleKeys.includes('tnc_conservation')) {
    filteredModuleKeys = moduleKeys.filter(
      moduleKey => moduleKey !== 'tnc_conservation',
    );
    filteredModuleKeys.push(
      'tnc_conservation',
      'tnc_conservation_carbon',
      'tnc_conservation_water',
      'tnc_conservation_habitat',
      'tnc_conservation_agriculture',
    );
  }
  return filteredModuleKeys;
}
