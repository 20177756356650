import * as React from 'react';
import logo from 'uf/assets/logo-with-text.svg';

interface LogoProps {
  height: number;
  style?: React.CSSProperties;
  className?: string;
}

const Logo: React.FunctionComponent<LogoProps> = ({
  height,
  style,
  className,
}) => {
  const imageStyle: React.CSSProperties = {
    ...style,
    height: `${height}px`,
    transition: 'width ease 1s, height ease 1s',
  };

  return (
    <img
      style={imageStyle}
      className={className}
      src={logo}
      alt="UrbanFootprint"
    />
  );
};

export default Logo;
