import colors from 'material-colors';

import {
  CuratedColumnSymbology,
  CUSTOM_THEME,
  DEFAULT_OPACITY,
  InterpolationTypes,
  PaintColorTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

import MPORegionList from './mpo_regions';

const symbologies: Record<
  string,
  Record<string, CuratedColumnSymbology[]>
> = {};

MPORegionList.forEach(regionList => {
  symbologies[`/public/dataset/california_soc0_30_${regionList}`] = {
    buckets: [
      {
        type: SymbologyTypes.FILL,
        paint: {
          'fill-color': {
            property: 'buckets',
            type: PaintColorTypes.CATEGORICAL,
            stops: [
              {
                value: 0.0,
                label: 'Far Below Average Organic Soil Carbon Density',
                color: '#eec870',
              },
              {
                value: 1.0,
                label: 'Below Average Organic Soil Carbon Density',
                color: '#bea059',
              },
              {
                value: 2.0,
                label: 'Average Organic Soil Carbon Density',
                color: '#776438',
              },
              {
                value: 3.0,
                label: 'Above Average Organic Soil Carbon Density',
                color: '#2f2816',
              },
              {
                value: 4.0,
                label: 'Far Above Average Organic Soil Carbon Density',
                color: colors.black,
              },
            ],
          },
          'fill-opacity': DEFAULT_OPACITY,
        },
        display_hints: {
          scale: SymbologyScales.CATEGORICAL,
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          theme: CUSTOM_THEME,
        },
      },
    ],
  };
});

export default symbologies;
