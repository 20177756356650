import { GeoJsonGeometryTypes } from 'geojson';
import _ from 'lodash';

import { LayerColumnBreaks } from 'uf-api';
import { assertNever } from 'uf/base/never';
import { ColumnKey, LayerId } from 'uf/layers';
import {
  CUSTOM_THEME,
  DEFAULT_CIRCLE_RADIUS,
  DEFAULT_LINE_WIDTH,
  DEFAULT_NULL_COLOR_STOP,
  DEFAULT_NUMERIC_DISPLAY_HINTS,
  DEFAULT_NUMERIC_DISTRIBUTION,
  DEFAULT_OPACITY,
  DataDrivenColorStop,
  FALLBACK_THEME,
  InterpolationTypes,
  LayerColumnSymbology,
  PaintColor,
  PaintColorTypes,
  SymbologyDisplayHints,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';
import { makeStyleSymbologyId } from 'uf/symbology/spec/id';
import { makePaintProperty } from 'uf/symbology/spec/paint';
import { generateThemeColorScale } from 'uf/symbology/theme';

export function canCreateNumericSymbology(
  columnBreaks: LayerColumnBreaks,
  min: number,
  max: number,
): boolean {
  const hasBreakStops = _.has(columnBreaks, 'break_count');
  const hasBreakType = _.has(columnBreaks, 'break_type');
  const hasMinMax = _.isNumber(min) && _.isNumber(max);
  const hasEnoughBreaks = hasBreakStops && columnBreaks.break_count > 0;
  const isAllNull =
    columnBreaks?.contains_nulls && columnBreaks?.break_count === 0;
  return (
    (columnBreaks &&
      hasBreakStops &&
      hasBreakType &&
      // isAllNull is a special exception
      ((hasMinMax && hasEnoughBreaks) || isAllNull)) ||
    false
  );
}

export function createNumericSymbology(
  layerId: LayerId,
  columnKey: ColumnKey,
  columnBreaks: LayerColumnBreaks,
  min: number,
  max: number,
  ufGeometryType: GeoJsonGeometryTypes,
  isPercent?: boolean,
): LayerColumnSymbology[] {
  if (!canCreateNumericSymbology(columnBreaks, min, max)) {
    return [];
  }

  const uniqueBreaks = _.uniq(columnBreaks.breaks);
  const domain =
    min === max ? uniqueBreaks : uniqueBreaks.filter(value => value !== max);
  const includeNull = columnBreaks.contains_nulls;
  const paintColor = makeNumericPaintColorProperty(
    domain,
    columnKey,
    undefined,
    { isReverseTheme: false, includeNull },
  );
  const paintProperties: PaintColor = { paintColor };
  const showZero = min === max && max === 0;

  const displayHints = makeNumericDisplayHints(false, isPercent, showZero);

  switch (ufGeometryType) {
    case 'MultiPolygon':
    case 'Polygon':
      return [
        generateNumericFillSymbology(
          layerId,
          columnKey,
          paintProperties,
          displayHints,
        ),
        // strokes on polygons cannot be data-driven
        generateNumericLineSymbology(
          layerId,
          columnKey,
          { paintColor: 'transparent' },
          displayHints,
        ),
      ];

    case 'MultiLineString':
    case 'LineString':
      return [
        generateNumericLineSymbology(
          layerId,
          columnKey,
          paintProperties,
          displayHints,
        ),
      ];

    case 'MultiPoint':
    case 'Point':
      return [
        generateNumericCircleSymbology(
          layerId,
          columnKey,
          paintProperties,
          displayHints,
        ),
      ];

    case 'GeometryCollection':
      // unsupported format
      return [];

    default:
      assertNever(ufGeometryType);
      return [];
  }
}

export function generateNumericFillSymbology(
  layerId: LayerId,
  columnKey: ColumnKey,
  paintProperties: PaintColor,
  displayHints: SymbologyDisplayHints,
): LayerColumnSymbology {
  const { paintColor } = paintProperties;

  return {
    id: makeStyleSymbologyId(layerId, columnKey, SymbologyTypes.FILL),
    type: SymbologyTypes.FILL,
    paint: {
      'fill-color': paintColor,
      'fill-opacity': DEFAULT_OPACITY,
    },
    display_hints: displayHints,
  };
}

export function generateNumericLineSymbology(
  layerId: LayerId,
  columnKey: ColumnKey,
  paintProperties: PaintColor,
  displayHints: SymbologyDisplayHints,
): LayerColumnSymbology {
  const { paintColor } = paintProperties;
  return {
    id: makeStyleSymbologyId(layerId, columnKey, SymbologyTypes.LINE),
    type: SymbologyTypes.LINE,
    paint: {
      'line-color': paintColor,
      'line-opacity': DEFAULT_OPACITY,
      'line-width': DEFAULT_LINE_WIDTH,
    },
    display_hints: displayHints,
  };
}

export function generateNumericCircleSymbology(
  layerId: LayerId,
  columnKey: ColumnKey,
  paintProperties: PaintColor,
  displayHints: SymbologyDisplayHints,
): LayerColumnSymbology {
  const { paintColor } = paintProperties;
  return {
    id: makeStyleSymbologyId(layerId, columnKey, SymbologyTypes.CIRCLE),
    type: SymbologyTypes.CIRCLE,
    paint: {
      'circle-color': paintColor,
      'circle-opacity': DEFAULT_OPACITY,
      'circle-stroke-color': 'transparent',
      'circle-radius': DEFAULT_CIRCLE_RADIUS,
    },
    display_hints: displayHints,
  };
}

interface NumericPaintPropertyOptions {
  isReverseTheme?: boolean;
  symbologyNullSupportFlag?: boolean;
  includeNull?: boolean;
  nullStop?: DataDrivenColorStop;
}
export function makeNumericPaintColorProperty(
  domain: number[],
  columnKey: ColumnKey,
  theme = FALLBACK_THEME,
  {
    isReverseTheme,
    includeNull,
    nullStop = DEFAULT_NULL_COLOR_STOP,
  }: NumericPaintPropertyOptions,
) {
  const uniqueStops = _.uniq(domain);
  const colorScale = generateThemeColorScale(
    uniqueStops.length,
    theme,
    SymbologyScales.NUMERIC,
  );

  colorScale(1);

  let colors: string[] = uniqueStops.map((unusedValue, i) => colorScale(i + 1));
  if (isReverseTheme) {
    colors = colors.reverse();
  }

  let colorStops = uniqueStops.map(
    (value, i): DataDrivenColorStop => ({
      value,
      color: colors[i],
    }),
  );

  if (includeNull) {
    colorStops = [nullStop, ...colorStops];
  }

  return makePaintProperty(
    colorStops,
    'transparent',
    columnKey,
    PaintColorTypes.INTERVAL,
  );
}

function makeNumericDisplayHints(
  isTransparent: boolean,
  isPercent: boolean,
  showZero: boolean = false,
) {
  return {
    ...DEFAULT_NUMERIC_DISPLAY_HINTS,
    distribution: DEFAULT_NUMERIC_DISTRIBUTION,
    interpolation: InterpolationTypes.INTERVAL,
    theme: isTransparent ? CUSTOM_THEME : FALLBACK_THEME,
    is_percent: isPercent,
    showZero,
  };
}
