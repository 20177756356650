import {
  CuratedColumnSymbology,
  CUSTOM_THEME,
  DEFAULT_OPACITY,
  InterpolationTypes,
  PaintColorTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/public/dataset/beaumont_general_plan_land_use': {
    land_use: [
      {
        type: SymbologyTypes.FILL,
        paint: {
          'fill-color': {
            property: 'land_use',
            type: PaintColorTypes.CATEGORICAL,
            stops: [
              { value: 'RR', label: 'Rural Residential', color: '#ffffbe' },
              {
                value: 'SFR',
                label: 'Single-Family Residential',
                color: '#ffff73',
              },
              {
                value: 'MFR',
                label: 'Multi-Family Residential',
                color: '#bd7759',
              },
              { value: 'GC', label: 'General Commercial', color: '#e69800' },
              { value: 'CC', label: 'Community Commercial', color: '#e64c00' },
              { value: 'I', label: 'Industrial', color: '#4446c7' },
              {
                value: 'CIO',
                label: 'Commercial Industrial Overlay',
                color: '#bee8ff',
              },
              {
                value: 'UVO',
                label: 'Urban Village Overlay',
                color: '#c9bdd4',
              },
              {
                value: 'BAO',
                label: 'Beaumont Area Overlay',
                color: '#b16db5',
              },
              { value: 'PF', label: 'Public Facilities', color: '#e1e1e1' },
              {
                value: 'RC',
                label: 'Recreation and Conservation',
                color: '#70a800',
              },
              { value: 'RailRoad', label: 'Rail Road', color: '#5fb6cd' },
            ],
          },
          'fill-opacity': DEFAULT_OPACITY,
        },
        display_hints: {
          scale: SymbologyScales.CATEGORICAL,
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          theme: CUSTOM_THEME,
        },
      },
    ],
  },

  '/raimiassociates/dataset/beaumont_general_plan_land_use': {
    land_use: [
      {
        type: SymbologyTypes.FILL,
        paint: {
          'fill-color': {
            property: 'land_use',
            type: PaintColorTypes.CATEGORICAL,
            stops: [
              { value: 'RR', label: 'Rural Residential', color: '#ffffbe' },
              {
                value: 'SFR',
                label: 'Single-Family Residential',
                color: '#ffff73',
              },
              {
                value: 'MFR',
                label: 'Multi-Family Residential',
                color: '#bd7759',
              },
              { value: 'GC', label: 'General Commercial', color: '#e69800' },
              { value: 'CC', label: 'Community Commercial', color: '#e64c00' },
              { value: 'I', label: 'Industrial', color: '#4446c7' },
              {
                value: 'CIO',
                label: 'Commercial Industrial Overlay',
                color: '#bee8ff',
              },
              {
                value: 'UVO',
                label: 'Urban Village Overlay',
                color: '#c9bdd4',
              },
              {
                value: 'BAO',
                label: 'Beaumont Area Overlay',
                color: '#b16db5',
              },
              { value: 'PF', label: 'Public Facilities', color: '#e1e1e1' },
              {
                value: 'RC',
                label: 'Recreation and Conservation',
                color: '#70a800',
              },
              { value: 'RailRoad', label: 'Rail Road', color: '#5fb6cd' },
            ],
          },
          'fill-opacity': DEFAULT_OPACITY,
        },
        display_hints: {
          scale: SymbologyScales.CATEGORICAL,
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          theme: CUSTOM_THEME,
        },
      },
    ],
  },
};

export default symbologies;
