import { ColumnKey, LayerId } from 'uf/layers';
import { ProjectId } from 'uf/projects';

export type DivideByColumnKey = ColumnKey | false;

/**
 * Set this in user_data to tell a layer to use the density-based symbology, eg: pop / gross_acres
 */
export interface DivideByColumn {
  divideByColumn: DivideByColumnKey;
}

const DIVIDE_BY_COLUMN_USER_DATA_PREFIX = 'divideByColumn:';

/**
 *  Useful for making bulk user data requests by prefix for the divideByColumn
 */
export function makeDivideByColumnUserDataPrefixForLayer(
  projectId: ProjectId,
  layerId: LayerId,
) {
  return `${DIVIDE_BY_COLUMN_USER_DATA_PREFIX}${projectId}|${layerId}`;
}

// TODO: make this have a projectId and viewId
export function makeDivideByColumnUserDataKey(
  projectId: ProjectId,
  layerId: LayerId,
  columnKey: ColumnKey,
) {
  const layerPrefix = makeDivideByColumnUserDataPrefixForLayer(
    projectId,
    layerId,
  );
  return `${layerPrefix}|${columnKey}`;
}
