import _ from 'lodash';

import { LayerReference, ProjectMetadata } from 'uf-api/model/models';

export function getReferenceLayersFromLayerList(
  project: ProjectMetadata,
): LayerReference[] {
  const {
    layer_list: layerList = [],
    project_area_layer: projectAreaLayer = {} as LayerReference,
    context_area_layer: contextAreaLayer = {} as LayerReference,
  } = project;

  if (_.isEmpty(layerList)) {
    return [];
  }

  const referenceLayers = layerList
    .filter(item => item.layer) // filter out analysis modules
    .map(item => item.layer)
    .filter(layer => {
      const isBoundaryLayer =
        layer.full_path === projectAreaLayer.full_path ||
        layer.full_path === contextAreaLayer.full_path;

      // const { layer_type: layerType } = parseFullPath(layer.full_path);
      // const isWorkingLayer = layerType === 'dynamic';

      // TODO:@alecf This is hack to fix a regression introduced in PR
      // https://github.com/CalthorpeAnalytics/uf/pull/8773 which caused the
      // "parcel reference layer" to be removed from the layer list of all
      // projects. This restores the previous, seemingly broken behavior.
      const isWorkingLayer = false;

      // return all layers that aren't boundary layers or working layers
      return !isBoundaryLayer && !isWorkingLayer;
    });

  return referenceLayers;
}
