import {
  CuratedColumnSymbology,
  CUSTOM_THEME,
  DEFAULT_OPACITY,
  DistributionTypes,
  InterpolationTypes,
  PaintColorTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/public/dataset/denver_w_contour_lines': {
    contourelevation: [
      {
        type: SymbologyTypes.LINE,
        paint: {
          'line-color': {
            property: 'contourelevation',
            type: PaintColorTypes.INTERVAL,
            stops: [
              { value: 0, color: '#80ce66' },
              { value: 1000, color: '#9cc59e' },
              { value: 2000, color: '#71a373' },
              { value: 3000, color: '#448148' },
              { value: 4000, color: '#175f1c' },
              { value: 5000, color: '#194234' },
              { value: 6000, color: '#e9c3ba' },
              { value: 7000, color: '#d3a293' },
              { value: 8000, color: '#a26d5f' },
              { value: 9000, color: '#7c4c40' },
              { value: 10000, color: '#594628' },
              { value: 11000, color: '#4e3028' },
              { value: 12000, color: '#5c5c5c' },
              { value: 13000, color: '#b1b1b1' },
              { value: 14000, color: '#ebebeb' },
              { value: 15000, color: '#fdfdfd' },
            ],
          },
          'line-opacity': DEFAULT_OPACITY,
        },
        display_hints: {
          scale: SymbologyScales.NUMERIC,
          distribution: DistributionTypes.DEFAULT,
          interpolation: InterpolationTypes.INTERVAL,
          theme: CUSTOM_THEME,
        },
      },
    ],
  },
};

export default symbologies;
