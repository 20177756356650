/**
 * UrbanFootprint App API
 * API for serving UrbanFootprint Application
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Restrict to data found at a point.  Values expected to be in WGS84.
 */
export interface ExpressionPoint<> {
  /**
   * Expression function, set to \"point\".
   */
  fn: ExpressionPoint.FnEnum;
  /**
   * Latitude
   */
  lat: number;
  /**
   * Longitude
   */
  lng: number;
  /**
   * Projection ID
   */
  srid?: number;
}
export namespace ExpressionPoint {
  export type FnEnum = 'point';
  export const FnEnum = {
    Point: 'point',
  } as const;
}
