import colors from 'material-colors';

import { DEFAULT_GEO_COLUMN_KEY } from 'uf/layers/geometryKey';
import {
  CuratedColumnSymbology,
  InterpolationTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const boundaryLayerList = [
  '/public/dataset/ambag_city_limits_sjb',
  '/public/dataset/ambag_city_limits_hollister',
];

const symbologies: Record<
  string,
  Record<string, CuratedColumnSymbology[]>
> = {};

boundaryLayerList.forEach(boundaryLayerPath => {
  symbologies[boundaryLayerPath] = {
    [DEFAULT_GEO_COLUMN_KEY]: [
      {
        display_hints: {
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          scale: SymbologyScales.CATEGORICAL,
          theme: 'custom',
        },
        paint: {
          'fill-color': 'transparent',
          'fill-opacity': 1,
        },
        type: SymbologyTypes.FILL,
      },
      {
        display_hints: {
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          scale: SymbologyScales.CATEGORICAL,
          theme: 'custom',
        },
        paint: {
          'line-color': colors.black,
          'line-opacity': 1,
          'line-width': 2,
        },
        type: SymbologyTypes.LINE,
      },
    ],
  };
});

export default symbologies;
