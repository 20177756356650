import {
  CuratedColumnSymbology,
  InterpolationTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/public/dataset/city_of_minneapolis_zoning_primary_minneapolis': {
    zone_code: [
      {
        display_hints: {
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          scale: SymbologyScales.CATEGORICAL,
          theme: 'custom',
        },
        paint: {
          'fill-color': {
            default: '#d62728',
            property: 'zone_code',
            stops: [
              {
                color: '#d33115',
                value: 'B4-1',
              },
              {
                color: '#d33115',
                value: 'B4-2',
              },
              {
                color: '#d33115',
                value: 'B4C-1',
              },
              {
                color: '#d33115',
                value: 'B4C-2',
              },
              {
                color: '#d33115',
                value: 'B4N',
              },
              {
                color: '#d33115',
                value: 'B4S-1',
              },
              {
                color: '#d62728',
                value: 'B4S-2',
              },
              {
                color: '#fda1ff',
                value: 'C1',
              },
              {
                color: '#fda1ff',
                value: 'C2',
              },
              {
                color: '#fa28ff',
                value: 'C3A',
              },
              {
                color: '#fa28ff',
                value: 'C3S',
              },
              {
                color: '#ab149e',
                value: 'C4',
              },
              {
                color: '#aea1ff',
                value: 'I1',
              },
              {
                color: '#aea1ff',
                value: 'I2',
              },
              {
                color: '#7b64ff',
                value: 'I3',
              },
              {
                color: '#7b64ff',
                value: 'OR1',
              },
              {
                color: '#653294',
                value: 'OR2',
              },
              {
                color: '#653294',
                value: 'OR3',
              },
              {
                color: '#fcdc00',
                value: 'R1',
              },
              {
                color: '#fcc400',
                value: 'R1A',
              },
              {
                color: '#fb9e00',
                value: 'R2',
              },
              {
                color: '#fb9e00',
                value: 'R2B',
              },
              {
                color: '#fe9200',
                value: 'R3',
              },
              {
                color: '#e27300',
                value: 'R4',
              },
              {
                color: '#f44e3b',
                value: 'R5',
              },
              {
                color: '#d33115',
                value: 'R6',
              },
            ],
            type: 'categorical',
          },
          'fill-opacity': 0.9,
        },
        type: SymbologyTypes.FILL,
      },
      {
        display_hints: {
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          scale: SymbologyScales.CATEGORICAL,
          theme: 'custom',
        },
        paint: {
          'line-color': '#999999',
          'line-opacity': 0.9,
          'line-width': 1,
        },
        type: SymbologyTypes.LINE,
      },
    ],
  },
};

export default symbologies;
