import { Button, IPopoverProps } from '@blueprintjs/core';
import { ItemRenderer, Select } from '@blueprintjs/select';
import classNames from 'classnames';
import { filter } from 'fuzzaldrin-plus';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { Box } from 'react-layout-components';
import { t } from 'ttag';

import { logEvent } from 'uf/logging';
import { ProjectId } from 'uf/projects';
import popupStyles from 'uf/styles/popups.module.css';
import useBindAction from 'uf/ui/base/useBindAction/useBindAction';
import { useMakeSelector } from 'uf/ui/base/useMakeSelector';
import { ViewEditor, ViewEditorMode } from 'uf/ui/views/ViewEditor/ViewEditor';
import { ViewMenuButton } from 'uf/ui/views/ViewMenuButton/ViewMenuButton';
import { DEFAULT_VIEW_ID, View, ViewId } from 'uf/views';
import { viewListActions } from 'uf/views/actions';
import { events } from 'uf/views/logging';
import { makeGetProjectViewsForDisplay } from 'uf/views/selectors/views';

import { ViewMenuItem } from './ViewMenuItem';

interface ViewListItem {
  view: View;
  onCopy: (view: View) => void;
  onDelete: (viewId: ViewId) => void;
  onEdit: (viewId: ViewId) => void;
  projectId: ProjectId;
}

const renderItem: ItemRenderer<ViewListItem> = (viewListItem, itemProps) => {
  const { view, onCopy, onDelete, onEdit, projectId } = viewListItem;
  const description =
    view.key === DEFAULT_VIEW_ID ? t`Your default view` : null;
  const canChange = view.key !== DEFAULT_VIEW_ID;

  return (
    <ViewMenuItem
      onCopy={onCopy}
      key={view.key}
      view={view}
      onDelete={canChange ? onDelete : null}
      onEdit={canChange ? onEdit : null}
      description={description}
      itemProps={itemProps}
      projectId={projectId}
    />
  );
};
const popoverProps: Partial<IPopoverProps> = {
  fill: true,
  popoverClassName: classNames(popupStyles.dropdownLarge, popupStyles.dropdown),
};

interface Props {
  projectId: ProjectId;
  viewId: ViewId;
  onSelectViewId: (viewId: ViewId) => void;
  className?: string;
}
export const ViewPicker: FC<Props> = ({
  projectId,
  viewId,
  onSelectViewId,
  className,
}) => {
  const views = useMakeSelector(makeGetProjectViewsForDisplay, { projectId });
  const onDeleteView = useBindAction(viewListActions.removeItem);
  const [viewToEdit, setViewToEdit] = useState<View>();
  const [mode, setViewEditMode] = useState(ViewEditorMode.EDIT);

  const onViewSelect = useCallback(
    (item: ViewListItem) => {
      logEvent(events.ACTIVATE_VIEW, { view: item.view, projectId });
      onSelectViewId(item.view.key);
    },
    [onSelectViewId, projectId],
  );

  const onViewCreate = useCallback(
    (view: View, prevView: View) => {
      logEvent(events.DUPLICATE_VIEW, {
        view,
        projectId,
        prevView,
        fromSaveNewButton: true,
      });
      onSelectViewId(view.key);
    },
    [onSelectViewId, projectId],
  );

  const onCopy = useCallback((view: View) => {
    setViewToEdit(view);
    setViewEditMode(ViewEditorMode.DUPLICATE);
  }, []);

  const onDelete = useCallback(
    (viewKey: ViewId) => {
      const view = views.find(({ key }) => key === viewKey);
      onDeleteView(view, projectId);
    },
    [onDeleteView, projectId, views],
  );

  const onEdit = useCallback(
    (viewKey: ViewId) => {
      onSelectViewId(viewKey);
      setViewToEdit(views.find(({ key }) => key === viewKey));
      setViewEditMode(ViewEditorMode.EDIT);
    },
    [onSelectViewId, views],
  );

  const onSaveView = useCallback(
    (view: View, prevView: View) => {
      logEvent(events.EDIT_VIEW, { view, prevView, projectId });
      onSelectViewId(view.key);
      setViewToEdit(null);
    },
    [onSelectViewId, projectId],
  );

  const onCancelEdit = useCallback(() => {
    setViewToEdit(null);
  }, []);

  const viewListItems: ViewListItem[] = useMemo(
    () =>
      views.map<ViewListItem>(view => ({
        view,
        onCopy,
        onDelete,
        onEdit,
        projectId,
      })),
    [onCopy, onDelete, onEdit, projectId, views],
  );

  const currentViewItem =
    viewListItems.find(({ view }) => view.key === viewId) ||
    viewListItems.find(({ view }) => view.key === DEFAULT_VIEW_ID);

  return (
    <Box className={className}>
      <Select
        popoverProps={popoverProps}
        items={viewListItems}
        activeItem={currentViewItem}
        itemRenderer={renderItem}
        onItemSelect={onViewSelect}
        itemListPredicate={filterViews}>
        <Button minimal outlined fill rightIcon="caret-down">
          {currentViewItem.view.name}
        </Button>
      </Select>
      <ViewMenuButton
        projectId={projectId}
        currentView={currentViewItem.view}
        onCreate={onViewCreate}
      />
      <ViewEditor
        projectId={projectId}
        mode={mode}
        view={viewToEdit}
        show={!!viewToEdit}
        onCancel={onCancelEdit}
        onSaveView={onSaveView}
      />
    </Box>
  );
};

const filterViews = (query: string, items: ViewListItem[]) => {
  if (!query) {
    return items;
  }
  // Decorate
  const namedItems = items.map(item => ({ name: item.view.name, item }));
  // Sort
  const views = filter(namedItems, query, { key: 'name' })
    // Undecorate
    .map(namedItem => namedItem.item);
  return views;
};
