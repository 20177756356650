import { mapMiddleware } from '@mapbox/mapbox-gl-redux';
import MapiClient from '@mapbox/mapbox-sdk/lib/classes/mapi-client';
import { History } from 'history';
// Shared code to create a redux store on the server *or* the browser
import { configureStore } from '@reduxjs/toolkit';
import queryString from 'query-string';
import { Middleware, Store } from 'redux';
import reduxCatch from 'redux-catch';
import { connectRoutes } from 'redux-first-router';
import { createLogger } from 'redux-logger';
import { createEpicMiddleware, Options } from 'redux-observable';

import { CloseEpicStream, EpicDependecies } from 'uf/base/epics';
import initializeStateFromUrl from 'uf/routing/initializeStateFromUrl';
import routesMap from 'uf/routing/routesMap';
import { LocationState } from 'uf/routing/state';
import { UFState } from 'uf/state';

import { SwaggerClient } from './base/xhr';
// this blows up in non local environments if no relative import, probably a webpack thing
import { enhancedApi as Api } from 'uf-api-rtk/store';
import { isUfAction, shouldShowAction } from './logger';
import rootEpic from './rootEpic';
import { createRootReducer } from './rootReducer';
import { FutureHistory } from './routing/history';

if (module.hot) {
  module.hot.accept('uf/routing/initializeStateFromUrl', () => {
    console.warn(
      'Hot-reloading URL initialization code, but not reloading (this is normal)',
    );
  });
}

declare global {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface NodeModule {
    hot: any;
  }
}

// fake for RTK typing
declare module 'uf/state' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface UFState {
    'api-rtk': any;
  }
}

/**
 * Use this instead of redux's createStore.
 *
 * @param client - A Swagger api client, added to redux-thunk.
 *   This will get passed as the "extra" parameter in action dispatchers.
 *   (see https://github.com/gaearon/redux-thunk#injecting-a-custom-argument)
 * @param mapi - a Mapbox client
 * @param data - The current redux state.
 * @param browserHistory - A history or history-like object.
 */
export default function createStore(
  client: SwaggerClient,
  mapi: MapiClient,
  data: any,
  browserHistory: History & FutureHistory,
  extraMiddleware: Middleware[] = [],
): Store<UFState> {
  const {
    reducer: locationReducer,
    middleware: locationMiddleware,
    enhancer: locationEnhancer,
  } = connectRoutes(routesMap, {
    createHistory: () => browserHistory,
    querySerializer: queryString,
  });

  const epicOptions: Options<EpicDependecies> = {
    dependencies: { client, mapi },
  };
  const epicMiddleware = createEpicMiddleware(epicOptions);
  const middleware: Middleware[] = [
    reduxCatch(errorHandler),
    ...extraMiddleware,
    locationMiddleware,
    epicMiddleware,
    mapMiddleware,
    Api.middleware,
  ];

  if (
    (__DEVELOPMENT__ || process.env.UF_SHOW_REDUX_LOGGER) &&
    __CLIENT__ &&
    !process.env.UF_SUPPRESS_REDUX_LOGGER
  ) {
    const logger = createCustomLogger();
    middleware.push(logger);
  }

  const store = configureStore({
    reducer: createRootReducer({
      location: locationReducer,
    } as unknown as LocationState),
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        thunk: { extraArgument: { client, mapi } },
        serializableCheck: false,
        immutableCheck: false,
      }).concat(...middleware),
    preloadedState: data,
    enhancers: [locationEnhancer as any],
    devTools: __DEVELOPMENT__,
  });

  epicMiddleware.run(rootEpic);

  initializeStateFromUrl(store as unknown as Store<UFState>, browserHistory);

  if (__DEVELOPMENT__ && module.hot) {
    // TODO: This is supposed to be the filename, but the HMR is using
    // some kind of numeric keys instead.
    module.hot.accept('./rootReducer.ts', () => {
      const nextReducer = require('./rootReducer');
      store.replaceReducer(
        nextReducer.createRootReducer({ location: locationReducer }),
      );
    });

    module.hot.accept('./rootEpic', () => {
      const newRootEpic = require('./rootEpic').default;
      store.dispatch(CloseEpicStream);
      epicMiddleware.run(newRootEpic);
    });
  }

  return store as unknown as Store<UFState>;
}

function createCustomLogger() {
  return createLogger({
    collapsed: true,
    predicate: (getState, action) => shouldShowAction(action),
    colors: {
      title: action => {
        // There are three actions to indicate asyncronous work, so we give them
        // each their own distinct color so it's easy to see what work is being
        // done
        if (isUfAction(action)) {
          if (action.type.endsWith('/LOAD')) {
            return 'blue';
          }
          if (action.type.endsWith('/SUCCESS')) {
            return 'green';
          }
          if (action.type.endsWith('/FAILURE')) {
            return 'red';
          }
        }

        // There are lots of mapbox actions, so we make them grey so that
        // regular UI actions will stand out
        if (action.type.startsWith('mapbox-')) {
          return 'slategray';
        }

        // Explicit default
        return 'black';
      },
    },
  });
}

function errorHandler(error: Error, getState, lastAction) {
  const actionType = lastAction?.type;
  Promise.reject(error);
  console.error(
    `Redux middleware error firing ${actionType}`,
    error,
    lastAction,
  );
}
