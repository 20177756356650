import {
  Button,
  Classes,
  Dialog,
  DialogBody,
  DialogFooter,
  Tab,
  Tabs,
  TextArea,
} from '@blueprintjs/core';
import classNames from 'classnames';
import React, { useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { t } from 'ttag';
import styles from './PointsOfInterestDialog.module.css';
import PointsOfInterestSelection from './PointsOfInterestSelection';
import SelectedTags, { PointOfInterestTag } from './SelectedTags';
import { getPlaces, usePointsOfInterest } from './usePointsOfInterest';
import { Brand } from 'uf/layers/layerData';

const INITIAL_FORM_STATE = {
  places: '',
  selectedIds: [],
};

export default function PointsOfInterestDialog({ isOpen, onClose }) {
  const [tab, setTab] = useState('brands-and-businesses');
  const { brandsById = {}, brandIds = [], onSubmit } = usePointsOfInterest();
  const {
    control,
    handleSubmit: handleSubmitForm,
    setValue,
    watch,
    reset,
  } = useForm({
    defaultValues: INITIAL_FORM_STATE,
  });
  const { places, selectedIds }: { places: string; selectedIds: string[] } =
    watch();
  const placeCount = getPlaces(places).length;
  const items: Brand[] = useMemo(
    () =>
      brandIds.map(id => ({
        ...brandsById[id],
        selected: selectedIds.includes(id),
      })),
    [brandIds, brandsById, selectedIds],
  );
  const handleSelect = value => {
    const newIds = selectedIds.includes(value)
      ? selectedIds.filter(item => item !== value)
      : [...selectedIds, value];
    setValue('selectedIds', newIds);
  };
  const handleChange = value => {
    setValue('places', value, {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true,
    });
  };
  const handleClose = () => {
    reset();
    onClose();
  };
  const handleSubmit = data => {
    const selectedItems = data.selectedIds.map(id => brandsById[id]);
    const placesInput = getPlaces(data.places);
    const filterData =
      tab === 'brands-and-businesses' ? selectedItems : placesInput;
    onSubmit(filterData);
    handleClose();
  };
  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      canOutsideClickClose
      className="tw-bg-white tw-w-3/5"
      // 60px is the default y-margin for the dialog
      style={{ height: 'calc(100vh - 60px)' }}>
      <form
        className="tw-flex tw-flex-col tw-flex-grow tw-justify-between"
        onSubmit={handleSubmitForm(handleSubmit)}>
        <header
          className={classNames(
            Classes.DIALOG_HEADER,
            'tw-shadow-none',
            'tw-block',
            'tw-p-8 ',
          )}>
          <h4 className="tw-text-3xl tw-font-bold tw-pb-6">{t`Find brands & businesses`}</h4>
          <p>
            <em>{t`For best results, search in "Brands & Business Categories" for common retail brands or business categories such as dentists or florists. Try "Places" if you can't find your location.`}</em>
          </p>
        </header>
        <DialogBody className="tw-p-0 tw-flex tw-flex-col tw-flex-grow tw-max-h-full">
          <Tabs
            onChange={(tabId: string) => setTab(tabId)}
            selectedTabId={tab}
            className={classNames(
              'tw-py-4',
              'tw-flex',
              'tw-flex-col',
              'tw-flex-grow',
              styles.tabs,
            )}>
            <Tab
              id="brands-and-businesses"
              title="Brands & Business Categories"
              panel={
                <section className="tw-flex tw-flex-col tw-flex-grow">
                  <PointsOfInterestSelection
                    onClick={handleSelect}
                    items={items}
                    control={control}
                  />
                  <SelectedTags>
                    {selectedIds.map(id => {
                      const { name, national_count: count } = brandsById[id];
                      return (
                        <PointOfInterestTag
                          key={id}
                          value={id}
                          label={name}
                          count={count}
                          onClick={handleSelect}
                        />
                      );
                    })}
                  </SelectedTags>
                </section>
              }
            />
            <Tab
              id="places"
              title="Places"
              panel={
                <section className="tw-p-1 tw-mx-8 tw-my-6 tw-flex tw-flex-col tw-flex-grow">
                  <p className="tw-mb-2">{t`Enter place names below, separated by line breaks or semicolons (";")`}</p>
                  <Controller
                    name="places"
                    control={control}
                    render={({ field }) => (
                      <TextArea
                        {...field}
                        style={{ height: 'calc(100% - 18px)' }}
                        className="tw-leading-8"
                        value={places}
                        placeholder="Type or paste..."
                        onChange={e => handleChange(e.target.value)}
                        maxLength={250}
                        fill
                      />
                    )}
                  />
                </section>
              }
            />
          </Tabs>
        </DialogBody>
        <DialogFooter
          minimal
          className="tw-mx-8 tw-mb-8"
          actions={
            <>
              <Button text="Cancel" onClick={handleClose} />
              <Button
                type="submit"
                data-testid="add-businesses-button"
                text="Add businesses"
                intent="primary"
                disabled={!selectedIds.length && !placeCount}
              />
            </>
          }
        />
      </form>
    </Dialog>
  );
}
