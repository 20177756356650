import { CSSProperties } from 'react';

/**
 * Width of a bar
 *
 * TODO: move this to a prop once we fully switch to the new bar chart format
 */
export const DEFAULT_BAR_WIDTH = 15;

/**
 * The default font size for the label
 */
export const DEFAULT_TOTALS_FONT_SIZE = 12;

/**
 * The space between the blueprint target and the tip of the arrow in the
 * tooltip. Seems to be hardcoded into blueprint or popover.js, or a strange
 * side effect of using tooltips on foreignObject children in SVG
 */
export const BAR_TOOLTIP_DISTANCE = 6;

export interface DatumSummary<D> {
  y: number;
  // From VictoryCharts, This is effective the "min", or where the bar should begin
  y0: number;
  x: string;
  originalDatum: D;
}

export type AxisAdjustment =
  | {
      dy: number;
    }
  | { dx: number };

export interface SumFormat {
  text: string;
  style?: CSSProperties;
}
