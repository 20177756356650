import { createSelector } from 'reselect';

import { EMPTY_OBJECT } from 'uf/base';
import { makeGetFromPropsSelector } from 'uf/base/selector';
import { LayerId } from 'uf/layers';
import {
  FilterSpec,
  GeometryFilterSpec,
  PointFilterSpec,
} from 'uf/layers/filters';
import { getLayerFilters } from 'uf/layers/selectors/filters';
import { LayerColumnFilters } from 'uf/layers/state';
import { ProjectId } from 'uf/projects';

import { makeGetExploreByProjectState } from './explore';

/**
 * helper to get the filters state for all layers in a project.  should not be exported.
 * makeGetLayerFilters sor makeGetLayerFiltersGetter should be used instead.
 */
function makeGetAllLayerFilters() {
  return createSelector(
    makeGetExploreByProjectState(),
    makeGetFromPropsSelector<ProjectId, 'projectId'>('projectId'),
    (state): LayerColumnFilters => state.filters || EMPTY_OBJECT,
  );
}

export function makeGetLayerFilters() {
  return createSelector(
    makeGetAllLayerFilters(),
    makeGetFromPropsSelector<LayerId, 'layerId'>('layerId'),
    makeGetFromPropsSelector<LayerId, 'parentLayerId'>('parentLayerId'),
    (allFilters, layerId, parentLayerId) => {
      return getLayerFilters(allFilters, layerId, parentLayerId);
    },
  );
}

export type FilterGetter = (
  layerId: LayerId,
  parentLayerId: LayerId,
) => Partial<FilterSpec>;
export function makeGetLayerFiltersGetter() {
  return createSelector(
    makeGetAllLayerFilters(),
    (allFilters): FilterGetter =>
      (layerId: LayerId, parentLayerId: string) => {
        return getLayerFilters(allFilters, layerId, parentLayerId);
      },
  );
}

export function makeGetLayerGeometryFilters() {
  return createSelector(
    makeGetLayerFilters(),
    (layerFilters): GeometryFilterSpec =>
      layerFilters.geometries || EMPTY_OBJECT,
  );
}
export function makeGetLayerPointFilter() {
  return createSelector(
    makeGetLayerFilters(),
    (layerFilters): PointFilterSpec => layerFilters.pointIds || EMPTY_OBJECT,
  );
}
