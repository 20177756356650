import { Menu, MenuItem } from '@blueprintjs/core';
import { GeocodeFeature } from '@mapbox/mapbox-sdk/services/geocoding';
import React, { Fragment, FunctionComponent, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { t } from 'ttag';

import { logEvent } from 'uf/logging';
import { gotoFeature } from 'uf/mapi/actions';
import { getSearchInfo } from 'uf/mapi/selectors';
import useBindAction from 'uf/ui/base/useBindAction/useBindAction';
import { events } from 'uf/ui/explore/logging';
import GeoSearchboxInput from './GeoSearchboxInput';

interface GeoSearchboxMenuProps {
  displayInput?: boolean;
  className?: string;
}

interface GeoSearchMenuItemProps {
  feature: GeocodeFeature;
}

const GeoSearchMenuItem: FunctionComponent<GeoSearchMenuItemProps> = props => {
  const { feature } = props;
  const bindGoto = useBindAction(gotoFeature);
  const doMapPlacement = useCallback(
    e => [logEvent(events.SEARCH_MATCH_SELECTED), bindGoto(feature)],
    [bindGoto, feature],
  );
  return (
    <MenuItem
      key={feature.id}
      text={
        <Fragment>
          <p>{feature.text}</p>
          <p className="tw-text-sm tw-text-gray-700 bp4-text-overflow-ellipsis">
            {feature.place_name}
          </p>
        </Fragment>
      }
      onClick={doMapPlacement}
    />
  );
};

const GeoSearchboxMenu: FunctionComponent<GeoSearchboxMenuProps> = props => {
  const { displayInput, className } = props;
  const { results } = useSelector(getSearchInfo);

  return (
    <Menu className={className}>
      {displayInput ? <MenuItem text={GeoSearchboxInput} /> : null}
      {results?.features
        ? results.features.map(f => (
            <GeoSearchMenuItem key={f.id} feature={f} />
          ))
        : null}
      {!results?.features || results.features.length ? null : (
        <MenuItem text={<NoResults />} />
      )}
    </Menu>
  );
};

const NoResults: FunctionComponent = () => {
  const text = t`No results found`;
  return <p className="tw-text-sm tw-text-gray-700">{text}</p>;
};

export default GeoSearchboxMenu;
