import {
  CuratedColumnSymbology,
  InterpolationTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/public/dataset/mbta_transit_stops_greater_boston': {
    line: [
      {
        display_hints: {
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          scale: SymbologyScales.CATEGORICAL,
          theme: 'custom',
        },
        paint: {
          'circle-color': {
            default: '#8c564b',
            property: 'line',
            stops: [
              {
                color: '#1f77b4',
                value: 'BLUE',
              },
              {
                color: '#68ccca',
                value: 'BLUE/GREEN',
              },
              {
                color: '#808900',
                value: 'BLUE/ORANGE',
              },
              {
                color: '#68bc00',
                value: 'GREEN',
              },
              {
                color: '#dbdf00',
                value: 'GREEN/ORANGE',
              },
              {
                color: '#fcdc00',
                value: 'GREEN/RED',
              },
              {
                color: '#fe9200',
                value: 'ORANGE',
              },
              {
                color: '#9f0500',
                value: 'ORANGE/RED',
              },
              {
                color: '#d33115',
                value: 'RED',
              },
              {
                color: '#999999',
                value: 'SILVER',
              },
            ],
            type: 'categorical',
          },
          'circle-opacity': 0.9,
          'circle-radius': 8,
          'circle-stroke-color': 'transparent',
        },
        type: SymbologyTypes.CIRCLE,
      },
    ],
  },
};

export default symbologies;
