import colors from 'material-colors';
import { t } from 'ttag';

import { DOLLARS, getUnitName } from 'uf/base/units';
import { LegacyChartSeries, LegacyReport } from 'uf/reporting/index';

const IN_SENTENCE = true;

const perHouseholdWaterCostSeries: LegacyChartSeries = {
  key: 'residential_water_cost_per_hh',
  source: 'analysis',
  sourceKey: 'household_cost',
  layerKey: 'household_cost_summary_per_hh',
  columnKey: 'residential_water_cost_per_hh',
};

const perHouseholdEnergyCostSeries: LegacyChartSeries = {
  key: 'residential_energy_cost_per_hh',
  source: 'analysis',
  sourceKey: 'household_cost',
  layerKey: 'household_cost_summary_per_hh',
  columnKey: 'residential_energy_cost_per_hh',
};

const perHouseholdTransportCostSeries: LegacyChartSeries = {
  key: 'transportation_cost_per_hh',
  source: 'analysis',
  sourceKey: 'household_cost',
  layerKey: 'household_cost_summary_per_hh',
  columnKey: 'transportation_cost_per_hh',
};

const perHouseholdTransportFuelCostSeries: LegacyChartSeries = {
  key: 'fuel_cost_per_hh',
  source: 'analysis',
  sourceKey: 'household_cost',
  layerKey: 'household_transportation_cost_summary_per_hh',
  columnKey: 'fuel_cost_per_hh',
};

const perHouseholdTransportOandMCostSeries: LegacyChartSeries = {
  key: 'ownership_and_maintenance_cost_per_hh',
  source: 'analysis',
  sourceKey: 'household_cost',
  layerKey: 'household_transportation_cost_summary_per_hh',
  columnKey: 'ownership_and_maintenance_cost_per_hh',
};

const perHouseholdAnnualCostsChart = {
  name: t`Per Household Annual Auto and Utility Costs`,
  description: t`Average annual household costs for driving and energy/water utilities`,
  subtitle: t`Average`,
  key: 'average_household_costs',
  units: getUnitName(DOLLARS, IN_SENTENCE),
  series: [
    perHouseholdTransportCostSeries,
    perHouseholdEnergyCostSeries,
    perHouseholdWaterCostSeries,
  ],
};

export const perHouseholdTransportOandMCostChart = {
  key: 'ownership_and_maintenance_cost_per_hh',
  description: t`Average annual household automobile-related costs`,
  name: t`Per Household Annual Auto Costs by Type`,
  subtitle: t`Average`,
  units: getUnitName(DOLLARS, IN_SENTENCE),
  series: [
    perHouseholdTransportFuelCostSeries,
    perHouseholdTransportOandMCostSeries,
  ],
};

const totalWaterCostSeries: LegacyChartSeries = {
  key: 'residential_water_cost',
  source: 'analysis',
  sourceKey: 'household_cost',
  layerKey: 'household_cost_results',
  columnKey: 'residential_water_cost',
  stat: 'sum',
};

const totalEnergyCostSeries: LegacyChartSeries = {
  key: 'residential_energy_cost',
  source: 'analysis',
  sourceKey: 'household_cost',
  layerKey: 'household_cost_results',
  columnKey: 'residential_energy_cost',
  stat: 'sum',
};

const totalTravelCostSeries: LegacyChartSeries = {
  key: 'transportation_cost',
  source: 'analysis',
  sourceKey: 'household_cost',
  layerKey: 'household_cost_results',
  columnKey: 'transportation_cost',
  stat: 'sum',
};

const totalWaterCostChart = {
  name: t`Annual Residential Water Costs`,
  description: t`Total annual household costs for water utilities`,
  subtitle: t`Total`,
  key: 'residential_water_cost',
  units: getUnitName(DOLLARS, IN_SENTENCE),
  series: [totalWaterCostSeries],
};

export const totalResidentialEnergyCostChart = {
  name: t`Annual Residential Energy Costs`,
  description: t`Total annual household costs for energy utilities`,
  subtitle: t`Total`,
  key: 'residential_energy_cost',
  units: getUnitName(DOLLARS, IN_SENTENCE),
  series: [totalEnergyCostSeries],
};

export const totalTravelCostChart = {
  name: t`Annual Passenger Vehicle Costs`,
  description: t`Total annual household costs for driving`,
  subtitle: t`Total`,
  key: 'transportation_cost',
  units: getUnitName(DOLLARS, IN_SENTENCE),
  series: [totalTravelCostSeries],
};

export const householdCostCharts: LegacyReport = Object.freeze({
  key: 'household_cost',
  name: 'Household Costs',
  colorSpec: {
    primaryColor: 'rgb(102, 119, 80)',
    interpolateFromColor: colors.lightGreen[300],
    interpolateToColor: colors.grey[900],
  },
  charts: [
    totalWaterCostChart,
    totalResidentialEnergyCostChart,
    totalTravelCostChart,
    perHouseholdAnnualCostsChart,
    perHouseholdTransportOandMCostChart,
  ],
});

export const householdCostAnalysisCharts: LegacyReport = Object.freeze({
  key: 'household_cost',
  name: 'Household Costs',
  charts: [
    {
      name: t`Annual Household Auto and Utility Costs`,
      description: t`Total annual household costs for driving and energy/water utilities`,
      subtitle: t`Total`,
      units: getUnitName(DOLLARS, IN_SENTENCE),
      key: 'total_residential_cost',
      series: [
        totalWaterCostSeries,
        totalEnergyCostSeries,
        totalTravelCostSeries,
      ],
    },
    perHouseholdAnnualCostsChart,
    perHouseholdTransportOandMCostChart,
  ],
});
