import { History } from 'history';
import { Store } from 'redux';

import {
  ROUTE_ANALYSIS_EDITOR,
  ROUTE_ANALYSIS_EDITOR_WITH_KEY,
} from 'uf/analysiseditor/ActionTypes';
import { setInitialAnalysisEditorRoute } from 'uf/analysiseditor/routing/initialize';
import {
  ROUTE_BUILT_FORM_EDITOR,
  ROUTE_BUILT_FORM_EDITOR_WITH_KEY,
} from 'uf/builtforms/ActionTypes';
import { setInitialBuiltFormRoute } from 'uf/builtforms/routing/initialize';
import { ROUTE_EXPLORE, ROUTE_EXPLORE_NEW } from 'uf/explore/ActionTypes';
import { setInitialExploreRoute } from 'uf/explore/routing/initialize';
import { ROUTE_INTERNAL } from 'uf/internal/ActionTypes';
import { setInitialInternalRoute } from 'uf/internal/routing/initialize';
import { ROUTE_REPORT } from 'uf/reporting/ActionTypes';
import { setInitialReportRoute } from 'uf/reporting/routing/initialize';
import { makeRoutingActionType } from 'uf/routing/actions';
import { getLocationState } from 'uf/routing/selectors';
import { UFState } from 'uf/state';

import { FutureHistory } from './history';

export default function initializeStateFromUrl(
  store: Store<UFState>,
  history: History & FutureHistory,
) {
  const state = store.getState();
  // use this for location type
  const location = getLocationState(state);
  // grab the query as it was passed in the request
  const query = history.location.query;
  // handle modes of explore view
  if (
    location.type === makeRoutingActionType(ROUTE_EXPLORE) ||
    location.type === makeRoutingActionType(ROUTE_EXPLORE_NEW)
  ) {
    setInitialExploreRoute(location, query, store.dispatch);

    // handle report view
  } else if (location.type === makeRoutingActionType(ROUTE_REPORT)) {
    setInitialReportRoute(location, query, store.dispatch);

    // handle built for editor view
  } else if (
    location.type === makeRoutingActionType(ROUTE_BUILT_FORM_EDITOR) ||
    location.type === makeRoutingActionType(ROUTE_BUILT_FORM_EDITOR_WITH_KEY)
  ) {
    setInitialBuiltFormRoute(location, query, store.dispatch);
    // handle analysis editor view
  } else if (
    location.type === makeRoutingActionType(ROUTE_ANALYSIS_EDITOR) ||
    location.type === makeRoutingActionType(ROUTE_ANALYSIS_EDITOR_WITH_KEY)
  ) {
    setInitialAnalysisEditorRoute(location, query, store.dispatch);
  } else if (location.type === makeRoutingActionType(ROUTE_INTERNAL)) {
    setInitialInternalRoute(location, query, store.dispatch);
  }
}
