import _ from 'lodash';
import { Action, ActionCreator, AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { ThirdPartyAny } from 'uf/base/types';

/**
 * Register an action creator to persist its value after it fires, and play back
 * the action after the value is restored.
 *
 * For example:
 *   registerPersistedAction('explore.visibleLayers',
 *                           SET_LAYER_VISIBILITY,
 *                           (layerId, visible) => setLayerVisibility(layerId, visible), {
 *                             getValue: action => action.visible,
 *                             getKey: action => action.layerId,
 *                           });
 *
 *
 * @param keyPrefix The leading key, i.e. 'explore.layersVisible'
 * @param actionType The action type, i.e. SET_LAYER_VISIBLE
 * @param makePlaybackAction The function that is called to set the action.
 * @param options Callbacks for saving/persisting.
 */
export function registerPersistedAction<
  A extends Action,
  SK = any,
  V = any,
  S = any,
>(
  keyPrefix: string,
  actionType: string,
  makePlaybackAction: (
    subkeys: SK,
    value: V,
  ) => A | ThunkAction<void, S, ThirdPartyAny, A>,
  options: RegisterOptions<A, SK, V, S>,
) {
  const registryEntry: EpicRegistryEntry<A, SK, V, S> = {
    ...DefaultOptions,
    keyPrefix,
    makePlaybackAction,
    ...options,
  };
  if (makePlaybackAction) {
    RegistryByKeyPrefix[keyPrefix] = registryEntry;
  }
  RegistryByActionType[actionType] = [
    ...(RegistryByActionType[actionType] ?? []),
    registryEntry,
  ];

  return () => {
    if (RegistryByKeyPrefix[keyPrefix] === registryEntry) {
      delete RegistryByKeyPrefix[keyPrefix];
    }
    if (RegistryByActionType[actionType]?.includes(registryEntry)) {
      RegistryByActionType[actionType] = RegistryByActionType[
        actionType
      ].filter(entry => entry !== registryEntry);
    }
  };
}

/**
 * Options for recording/playback of persisted actions.
 */
export interface RegisterOptions<A extends Action, SK = any, V = any, S = any> {
  /**
   * Extract the value that should be saved. For instance, this might just
   * return `true` or `false` for a pref for setting a layer visible or not.
   */
  getValue: (action: A, state: S) => V;

  /**
   * Extract the key that it should be saved at. This is for when the pref is
   * parameterized, for instance this could just be [layerId] when setting a
   * pref for a particular layer.
   */
  getKey?: (action: A) => SK;
}

const DefaultOptions: Partial<RegisterOptions<AnyAction>> = {
  getKey(unusedAction) {
    return [];
  },
};

export function isRegisteredActionType(type: string): boolean {
  return type in RegistryByActionType;
}

export function getEntryForActionType<A extends Action>(action: A) {
  return RegistryByActionType[action.type];
}

export function getEntryForKeyPrefix(keyPrefix: string) {
  return RegistryByKeyPrefix[keyPrefix];
}

/**
 * Get a list of registered key prefixes, e.g. 'activeColumn', etc
 */
export function getRegisteredPrefixes(): string[] {
  return Object.keys(RegistryByKeyPrefix).sort();
}

export const PERSIST_PREFIX = 'persist';

export function createPersistKey<SK = string>(
  keyPrefix: string,
  subKeys: SK | SK[],
) {
  let persistKey = `${PERSIST_PREFIX}:${escape(keyPrefix)}`;
  if (!_.isEmpty(subKeys)) {
    const sKeys: SK[] = _.flatten([subKeys]);
    const escapedKeys = sKeys.map((subKey: any) => escape(subKey));
    persistKey = `${persistKey}:${escapedKeys.join(':')}`;
  }
  return persistKey;
}

export interface ParsedKey<SK = any> {
  keyPrefix: string;
  subKeys: SK;
}

/**
 * Parse a persistence key into its component bits. A key looks like this:
 *
 *   persist:key-prefix:rison-encoded-subkeys
 *
 * @param persistKey
 * @returns An object with the key prefix and any sub keys that were provided.
 */
export function parsePersistKey<SK extends string[] = string[]>(
  persistKey: string,
): ParsedKey<SK> {
  const [prefix, keyPrefix, ...subKeyBits] = persistKey.split(':');
  if (prefix !== PERSIST_PREFIX) {
    return null;
  }

  const subKeys = subKeyBits.map(subKey => unescape(subKey)) as SK;

  return {
    keyPrefix: unescape(keyPrefix),
    subKeys,
  };
}

interface EpicRegistryEntry<A extends Action, SK, V, S>
  extends RegisterOptions<A, SK, V, S> {
  keyPrefix: string;
  makePlaybackAction: ActionCreator<any>;
}

// These are internal datastructures that should NOT be exported outside of this file.
const RegistryByKeyPrefix: Record<
  string,
  EpicRegistryEntry<any, any, any, any>
> = {};
const RegistryByActionType: Record<
  string,
  EpicRegistryEntry<any, any, any, any>[]
> = {};
