import { ExtendedKeyMapOptions, KeyMap } from 'react-hotkeys';
import { t } from 'ttag';

// p Pan
// space Hold space bar to quick pan
// i Inspection/Identify
// s Point selection
// b Box selection
// r Polygon selection
// x Clear selection
// e Export
// q Quick export

export interface ExploreMapHotKeyMap extends KeyMap {
  MAP_PAN: ExtendedKeyMapOptions;
  MAP_INSPECT: ExtendedKeyMapOptions;
  MAP_POINT_SELECT: ExtendedKeyMapOptions;
  MAP_BOX_SELECT: ExtendedKeyMapOptions;
  MAP_POLYGON_SELECT: ExtendedKeyMapOptions;
  MAP_CLEAR_SELECTION: ExtendedKeyMapOptions;
  MAP_SNAPSHOT: ExtendedKeyMapOptions;
  DO_NOTHING: ExtendedKeyMapOptions;
}

type KeyMapKeys = keyof ExploreMapHotKeyMap;

export type ExploreMapHotKeyHandlers = {
  [K in KeyMapKeys]: any;
};
// TODO: ExtendedKeyMapOptions interface requires 'sequences' and 'action'.  These should not be
// required, remove typecast when the type is fixed.
export const ExploreMapHotKeys: ExploreMapHotKeyMap = {
  MAP_PAN: {
    sequence: 'p',
    name: t`Map Pan`,
    group: 'map',
    description: t`Click and drag to pan the map.`,
  } as ExtendedKeyMapOptions,
  MAP_INSPECT: {
    sequence: 'i',
    name: t`Map Pan`,
    group: 'map',
    description: t`Click and drag to pan the map.`,
  } as ExtendedKeyMapOptions,
  MAP_POINT_SELECT: {
    sequence: 's',
    name: t`Point Select`,
    group: 'map',
    description: t`Click to select a feature. Ctrl + Click to deselect a feature.`,
  } as ExtendedKeyMapOptions,
  MAP_BOX_SELECT: {
    sequence: 'b',
    name: t`Box Select`,
    group: 'map',
    description: t`Click to create a box on the map to select items.`,
  } as ExtendedKeyMapOptions,
  MAP_POLYGON_SELECT: {
    sequence: 'r',
    name: t`Polygon Select`,
    group: 'map',
    description: t`Click to create a polygon on the map to select items.`,
  } as ExtendedKeyMapOptions,
  MAP_CLEAR_SELECTION: {
    sequence: 'c',
    name: t`Clear Selection`,
    group: 'map',
    description: t`Clears the current selection.`,
  } as ExtendedKeyMapOptions,
  MAP_SNAPSHOT: {
    sequence: 'q',
    name: t`Export Snapshot`,
    group: 'map',
    description: t`Export a quick map snapshot.`,
  } as ExtendedKeyMapOptions,
  DO_NOTHING: {
    sequence: null,
    sequences: ['command+c', 'ctrl+c', 'alt+c'],
    action: 'keypress',
  },
};
