import { useSelector } from 'react-redux';
import {
  getActiveProjectId,
  makeGetActiveScenarioIdForProject,
} from 'uf/app/selectors';
import { getLayerDetails } from 'uf/explore/selectors/layers';
import { makeGetActiveViewId } from 'uf/explore/selectors/views';
import { LayerId } from 'uf/layers';

/**
 * Get the up-to-date layer name based off the project's layer state
 * @returns the name of the layer
 */
export function useLayerName(layerId: LayerId) {
  const getActiveViewId = makeGetActiveViewId();
  const getActiveScenarioId = makeGetActiveScenarioIdForProject();
  const projectId = useSelector(state => getActiveProjectId(state));
  const viewId = useSelector(state => getActiveViewId(state, { projectId }));
  const scenarioId = useSelector(state =>
    getActiveScenarioId(state, { projectId }),
  );
  const layerInfo = useSelector(state =>
    getLayerDetails(state, { layerId, projectId, scenarioId, viewId }),
  );
  return layerInfo?.name || '';
}
