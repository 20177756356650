import { Dispatch } from 'redux';
import { LayerQuery, LayerReference } from 'uf-api';
import { loadTemporaryShapesActionTypes } from 'uf-api/api/geo.service';
import {
  addToLayerListActionTypes,
  createScenarioActionTypes,
  deleteProjectActionTypes,
  deleteScenarioActionTypes,
  editProjectActionTypes,
  editScenarioActionTypes,
  getCanvasTypesActionTypes,
  getProjectActionTypes,
  getProjectAvailableLayersActionTypes,
  getProjectsV2ActionTypes,
  getProjectTasksActionTypes,
  getProjectUpdateInProgressActionTypes,
  removeFromLayerListActionTypes,
  removeProjectWorkingLayerActionTypes,
} from 'uf-api/api/project.service';
import { makeSetPropValueAction } from 'uf/base/actionHelpers';
import { parseFullPath } from 'uf/base/dataset';
import { postfixKeywithUniqId } from 'uf/base/keys';
import { makeAsyncApiCaller } from 'uf/base/xhr';
import { dispatchAsyncAction, makeEnsureActionCreator } from 'uf/data/loader';
import { LayerId } from 'uf/layers';
import { Expression } from 'uf/layers/filters';
import {
  BoundarySources,
  ProjectId,
  PROJECT_LIST_REDUX_KEY,
} from 'uf/projects';
import { UPDATE_PROJECT_STATUS } from 'uf/projects/ActionTypes';
import {
  deleteProjectAPI,
  editProjectAPI,
  editScenarioOperation,
  fetchAvailableProjectLayersAPI,
  fetchProject,
  fetchProjectUpdateStatus,
  fetchTasksForProject,
  fetchUserProjectsAPI,
  getCanvasTypesAPI,
  removeWorkingLayerAPI,
  updateLayerListAPI,
} from 'uf/projects/apis';
import { makeLayerQuery } from 'uf/projects/ids';
import { makeGetProjectState } from 'uf/projects/selectors';
import UpdateStatusTypes from 'uf/projects/StatusTypes';
import { ScenarioCloneTaskMessage } from 'uf/projects/tasks';
import { ScenarioId } from 'uf/scenarios';

// TODO: Include analysis module params
export function updateLayerList(
  projectId: ProjectId,
  layerId: LayerId,
  isRemove = false,
) {
  const actionTypes = isRemove
    ? removeFromLayerListActionTypes
    : addToLayerListActionTypes;
  return dispatchAsyncAction(
    actionTypes,
    updateLayerListAPI(projectId, layerId, isRemove),
    { key: projectId, layerId, projectId },
  );
}

export function loadAvailableProjectLayers(projectId: ProjectId) {
  return dispatchAsyncAction(
    getProjectAvailableLayersActionTypes,
    fetchAvailableProjectLayersAPI(projectId),
    { key: projectId },
  );
}

export function editScenario(
  scenarioId: ScenarioId,
  projectId: ProjectId,
  name: string,
  description: string,
) {
  const editOperation = editScenarioOperation(
    scenarioId,
    projectId,
    name,
    description,
  );
  return dispatchAsyncAction(editScenarioActionTypes, editOperation);
}

const deleteScenario = makeAsyncApiCaller(apis => apis.project.delete_scenario);

export function deleteScenarioAPI(
  projectId: ProjectId,
  scenarioId: ScenarioId,
) {
  const { namespace, key } = parseFullPath(scenarioId, 'scenario');

  return dispatchAsyncAction(
    deleteScenarioActionTypes,
    deleteScenario({ namespace, key }),
    {
      projectId,
      scenarioId,
    },
  );
}

const createScenario = makeAsyncApiCaller(apis => apis.project.create_scenario);
function cloneScenarioOperation(
  projectId: ProjectId,
  scenarioId: ScenarioId,
  name: string,
  nextScenarioOrdinal: number,
) {
  const { key } = parseFullPath(projectId, 'project');
  const { namespace } = parseFullPath(scenarioId);
  const params = {
    namespace,
    key,
    scenario_key: postfixKeywithUniqId(
      `${key}_scenario_${nextScenarioOrdinal}`,
    ),
    name: `Scenario #${nextScenarioOrdinal}`,
    description: `Clone of ${name}`,
    base_scenario: scenarioId,
  };

  return createScenario(params);
}

export interface CreateScenarioDataStateExtraProp {
  projectId: ProjectId;
}

export interface CreateScenarioTaskActionExtraProp {
  result: ScenarioCloneTaskMessage;
}

export function cloneScenario(
  projectId: ProjectId,
  scenarioId: ScenarioId,
  scenarioName: string,
  nextScenarioOrdinal: number,
) {
  const cloneOperation = cloneScenarioOperation(
    projectId,
    scenarioId,
    scenarioName,
    nextScenarioOrdinal,
  );
  const { key } = parseFullPath(scenarioId);

  return dispatchAsyncAction<{}, CreateScenarioDataStateExtraProp>(
    createScenarioActionTypes,
    cloneOperation,
    {
      // nested extra because dispatchAsyncAction spreads the extra prop into the action, which is
      // then passed to the keyedPromiseReducer which expects an extra prop.
      extra: {
        projectId,
      },
      key,
    },
  );
}

export function loadUserProjects() {
  return dispatchAsyncAction(
    getProjectsV2ActionTypes,
    fetchUserProjectsAPI({}),
    { key: PROJECT_LIST_REDUX_KEY },
  );
}

export function loadProject(projectId: ProjectId, refreshMap = false) {
  return dispatchAsyncAction(getProjectActionTypes, fetchProject(projectId), {
    key: projectId,
    refreshMap,
    projectId,
  });
}

export const ensureProject = makeEnsureActionCreator(
  loadProject,
  makeGetProjectState(),
  projectId => ({ projectId }),
);

export interface RemoveLayerExtra {
  key: string;
  layerId: LayerId;
  projectId: ProjectId;
}
export function removeWorkingLayer(projectId: ProjectId, layerId: LayerId) {
  return dispatchAsyncAction<{}, RemoveLayerExtra>(
    removeProjectWorkingLayerActionTypes,
    removeWorkingLayerAPI(projectId, layerId),
    { key: `${projectId}:${layerId}`, layerId, projectId },
  );
}

export interface CreateProjectRequest {
  name: string;
  description: string;
  organizationKey: string;
  projectAreaLayerId?: string;
  projectAreaLayerFilter?: Expression;
  projectAreaUploadKey?: string;
  projectCanvasTypeKey?: 'parcel' | 'block';
  projectCanvasLayer?: LayerReference;
  contextAreaLayerId?: string;
  contextAreaLayerFilter?: Expression;
  contextCanvasTypeKey?: 'parcel' | 'block';
  builtFormsLibraryId?: string;
  projectRole: 'standard' | 'explorer';
  intersectionMethod?: 'centroid' | 'boundary';
  projectType?: string;
}

const deleteProjectDefaultOptions = { refreshOrganizationProjectList: false };
export function deleteProject(
  namespace: string,
  projectKey: string,
  options = deleteProjectDefaultOptions,
) {
  return (dispatch: Dispatch) =>
    dispatch(
      dispatchAsyncAction(
        deleteProjectActionTypes,
        deleteProjectAPI(namespace, projectKey),
        { namespace, projectKey, options },
      ),
    );
}

const editProjectDefaultOptions = { refreshOrganizationProjectList: false };
export function editProject(
  namespace: string,
  projectKey: string,
  name: string,
  description: string,
  options = editProjectDefaultOptions,
) {
  return dispatchAsyncAction(
    editProjectActionTypes,
    editProjectAPI(namespace, projectKey, name, description),
    {
      namespace,
      projectKey,
      name,
      description,
      options,
    },
  );
}

export function getCanvasTypes(
  organizationKey: string,
  boundarySourceType: BoundarySources,
  options: {
    areaLayerId?: string;
    areaUploadKey?: string;
    areaFilter?: Expression;
  },
) {
  const { areaLayerId, areaFilter, areaUploadKey } = options;
  let canvasArea: LayerQuery;
  if (boundarySourceType === BoundarySources.WELL_KNOWN) {
    canvasArea = makeLayerQuery(areaLayerId, null, areaFilter);
  }

  return dispatchAsyncAction(
    getCanvasTypesActionTypes,
    getCanvasTypesAPI(organizationKey, boundarySourceType, {
      canvasArea,
      areaUploadKey,
    }),
  );
}

export const updateProjectStatus = makeSetPropValueAction(
  UPDATE_PROJECT_STATUS,
);

export function loadProjectUpdateStatus(projectId: ProjectId) {
  return (dispatch: Dispatch) =>
    dispatch(
      dispatchAsyncAction(
        getProjectUpdateInProgressActionTypes,
        fetchProjectUpdateStatus(projectId),
      ),
    )
      .then(result =>
        dispatch(
          updateProjectStatus(projectId, {
            status: UpdateStatusTypes.IN_PROGRESS,
            info: result,
          }),
        ),
      )
      .catch(error => {
        // a 404 here means that there is no update in progress
        if (error.status === 404) {
          return dispatch(
            updateProjectStatus(projectId, {
              status: UpdateStatusTypes.NOT_IN_PROGRESS,
              info: {},
            }),
          );
        }
        throw error;
      });
}
export function loadTasksForProject(projectId: ProjectId) {
  return dispatchAsyncAction(
    getProjectTasksActionTypes,
    fetchTasksForProject(projectId),
    { key: projectId },
  );
}

const loadTemporaryShapes = makeAsyncApiCaller(
  apis => apis.geo.load_temporary_shapes,
);

export function uploadTemporaryShapes(formData: Blob) {
  return dispatchAsyncAction(
    loadTemporaryShapesActionTypes,
    loadTemporaryShapes({ formData }),
  );
}
