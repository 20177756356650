import _ from 'lodash';
import React, { useCallback, useEffect } from 'react';
import { Box } from 'react-layout-components';
import { connect } from 'react-redux';

import { LayerMetadata, LayerStats } from 'uf-api';
import { LayerNumericStats, ProjectModuleParams } from 'uf-api-rtk/store/Api';
import { ensureScenarioAnalysisParams } from 'uf/analysiseditor/actions';
import {
  getActiveProjectId,
  makeGetActiveScenarioIdForProject,
} from 'uf/app/selectors';
import { DataState, getError, isLoading } from 'uf/data/dataState';
import { setSummaryStatsType } from 'uf/explore/actions';
import { makeGetLayerModuleKey } from 'uf/explore/selectors/analysis';
import { makeGetLayerFilters } from 'uf/explore/selectors/filters';
import { getActiveLayerId } from 'uf/explore/selectors/layers';
import {
  getLayerFilteredStats,
  getSummaryStatsType,
  makeGetLayerExploreFilteredStatsState,
} from 'uf/explore/selectors/stats';
import { makeGetActiveViewId } from 'uf/explore/selectors/views';
import { LayerId, LayerVersion } from 'uf/layers';
import { beginExport, BeginExportAction } from 'uf/layers/actions/export';
import { ensureLayerMetadata } from 'uf/layers/actions/metadata';
import { ensureLayerStats } from 'uf/layers/actions/stats';
import { FilterSpec } from 'uf/layers/filters';
import { events } from 'uf/layers/logging';
import { isNumericColumn } from 'uf/layers/metadata';
import { makeGetLayerMetadata } from 'uf/layers/selectors/metadata';
import { getLayerStats } from 'uf/layers/selectors/stats';
import { makeGetLayerVersion } from 'uf/layers/selectors/versions';
import { LayerStatsParams } from 'uf/layers/stats';
import { logEvent } from 'uf/logging';
import { ProjectId } from 'uf/projects';
import { ScenarioId } from 'uf/scenarios';
import rootStyles from 'uf/styles/root.module.css';
import { useDebouncedState } from 'uf/ui/base/useDebouncedState';
import { sources } from 'uf/ui/explore/logging';

import ExploreColumnSummary from './ExploreColumnSummary/ExploreColumnSummary';
import ExploreDataTable from './ExploreDataTable/ExploreDataTable';
import { useTableData } from './hooks';
import { useFormattedTableData } from './hooks/useFormattedTableData';

const DEFAULT_COLUMN_FILTER_DEBOUNCE_TIME = 100;

interface OwnProps {
  columnFilterDebounceTime?: number;
  width?: number;
  ensureLayerMetadata: (layerId: LayerId) => void;
  ensureLayerStats: (layerId: LayerId, params?: LayerStatsParams) => void;
  beginExport: BeginExportAction;
  setSummaryStatsType: typeof setSummaryStatsType;
  ensureScenarioAnalysisParams: (
    projectId: ProjectId,
    scenarioId: ScenarioId,
    moduleKey: string,
  ) => Promise<ProjectModuleParams>;
}
interface StateProps {
  layerId: LayerId;
  layerMetadata: LayerMetadata;
  filteredStats: LayerStats;

  filteredStatsState: DataState<LayerStats>;
  totalStats: LayerStats;
  layerVersion: LayerVersion;
  /**
   * TODO: Make a real type in AggregateTypes.js
   * For now these are just 'min', 'max', 'sum', 'avg'
   */
  currentSummaryStatType: string;
  filters: Partial<FilterSpec>;
  projectId: ProjectId;
  scenarioId: ScenarioId;
  moduleKey: string;
}

type Props = StateProps & OwnProps;

function getNumericColumns(metadata: LayerMetadata): string[] {
  // Get the column keys for all columns with numeric metatype.

  if (!metadata || _.isEmpty(metadata.columns)) {
    return [];
  }

  return metadata.columns
    .filter(col => isNumericColumn(col))
    .map(col => col.key);
}

export const ExploreDataViewer: React.FunctionComponent<Props> = ({
  filteredStats: stats,
  filteredStatsState,
  filters,
  layerId,
  layerMetadata,
  totalStats,
  layerVersion: version,
  currentSummaryStatType,
  width,
  projectId,
  scenarioId,
  moduleKey,
  columnFilterDebounceTime = DEFAULT_COLUMN_FILTER_DEBOUNCE_TIME,
  ...actions
}) => {
  const [columnFilter, setColumnFilter, currentColumnFilter] =
    useDebouncedState('', columnFilterDebounceTime);
  const { data } = useTableData();
  const { columns, filteredStats, onReorderColumns, onFreezeColumn } =
    useFormattedTableData(layerMetadata.columns, stats, columnFilter);
  useEffect(() => {
    actions.ensureLayerMetadata(layerId);
  }, [layerId, actions]);
  useEffect(() => {
    // Check first since we can't pass undefined to rison.encode, and we're not
    // always looking at an analysis layer
    if (moduleKey) {
      void actions.ensureScenarioAnalysisParams(
        projectId,
        scenarioId,
        moduleKey,
      );
    }
  }, [actions, moduleKey, projectId, scenarioId]);

  useEffect(() => {
    if (!layerId || !layerMetadata) {
      return;
    }

    const numericColumns = getNumericColumns(layerMetadata);

    actions.ensureLayerStats(layerId, {
      version,
      columns: numericColumns,
    });
    actions.ensureLayerStats(layerId, {
      version,
      filters,
      columns: numericColumns,
    });
  }, [filters, layerId, layerMetadata, version, actions]);

  // always reset column filter when we change layers
  useEffect(() => {
    setColumnFilter('');
  }, [layerId, setColumnFilter]);
  const onExport = useCallback(() => {
    void actions.beginExport(
      projectId,
      layerId,
      null,
      'csv',
      false,
      filters,
      null,
      columns,
    );

    logEvent(events.LAYER_EXPORTED, {
      projectId,
      layerId,
      type: 'csv',
      source: sources.EXPLORE_DATA_VIEWER,
      columns,
    });
  }, [actions, columns, filters, layerId, projectId]);

  const setSummaryType = useCallback(
    (key: string) => {
      actions.setSummaryStatsType(projectId, key as keyof LayerNumericStats);
    },
    [actions, projectId],
  );

  const isStatsLoading = isLoading(filteredStatsState);
  const statsError = getError(filteredStatsState);

  if (!data || !data.rows) {
    return (
      <div style={{ width: '100%', height: '100%', position: 'relative' }}>
        <div>Error: Missing all table data</div>;
      </div>
    );
  }

  return (
    <Box
      fit
      column
      className={'ExploreDataViewer'}
      style={{
        position: 'relative',
        height: '100%',
        width: '100%',
        overflow: 'hidden',
      }}>
      <ExploreColumnSummary
        layerId={layerId}
        filteredRowCount={stats.row_count}
        totalRowCount={totalStats.row_count}
        columns={columns}
        currentStatType={currentSummaryStatType}
        setStatType={setSummaryType}
        busy={isStatsLoading}
        error={statsError}
        onExport={onExport}
        onSetColumnFilter={setColumnFilter}
        columnFilter={currentColumnFilter}
      />
      <Box flex={1} className={rootStyles.overflowHidden}>
        <ExploreDataTable
          data={data}
          columns={columns}
          onFreezeColumn={onFreezeColumn}
          onReorderColumns={onReorderColumns}
          statCellData={filteredStats}
          currentSummaryStatType={currentSummaryStatType}
          isStatsLoading={isStatsLoading}
        />
      </Box>
    </Box>
  );
};

export default connect(
  () => {
    const getLayerFilters = makeGetLayerFilters();
    const getLayerFilteredStatsState = makeGetLayerExploreFilteredStatsState();
    const getLayerVersion = makeGetLayerVersion();
    const getLayerModuleKey = makeGetLayerModuleKey();
    const getLayerMetadata = makeGetLayerMetadata();
    const getActiveScenarioId = makeGetActiveScenarioIdForProject();
    return (state): StateProps => {
      const projectId = getActiveProjectId(state);
      const scenarioId = getActiveScenarioId(state, {
        projectId,
      });
      const viewId = makeGetActiveViewId()(state, { projectId });
      const layerId = getActiveLayerId(state, {
        projectId,
        scenarioId,
        viewId,
      });
      const layerMetadata = getLayerMetadata(state, { layerId });
      const columns = getNumericColumns(layerMetadata);
      const moduleKey = getLayerModuleKey(state, { layerId });

      return {
        layerId,
        layerMetadata,
        filteredStats: getLayerFilteredStats(state, {
          projectId,
          layerId,
          columns,
          parentLayerId: null,
        }),
        filteredStatsState: getLayerFilteredStatsState(state, {
          projectId,
          layerId,
          columns,
          parentLayerId: null,
        }),
        totalStats: getLayerStats(state, {
          layerId,
          columns,
          parentLayerId: null,
        }),
        layerVersion: getLayerVersion(state, { layerId }),
        currentSummaryStatType: getSummaryStatsType(state, { projectId }),
        filters: getLayerFilters(state, {
          projectId,
          layerId,
          parentLayerId: null,
        }),
        projectId,
        moduleKey,
        scenarioId,
      };
    };
  },
  {
    ensureLayerMetadata,
    ensureLayerStats,
    beginExport,
    ensureScenarioAnalysisParams,
    setSummaryStatsType,
  },
)(ExploreDataViewer);
