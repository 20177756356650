/**
 * TODO: Move all of this to uf/map.
 * There should be nothing about maps in symbology!
 */
import _ from 'lodash';
import { t } from 'ttag';

type BaseMapIds =
  | 'light'
  | 'dark'
  | 'basic'
  | 'satellite'
  | 'streets'
  | 'bright'
  | 'satelliteFaded'
  | 'minimalLand'
  | 'minimalLandDark'
  | 'terrain'
  | 'schematic'
  | 'blank';

export const SatelliteBaseMapIds: BaseMapIds[] = [
  'satellite',
  'satelliteFaded',
];

export interface BaseMap {
  id: BaseMapIds;
  label: string;
  labelColor: string;
  url: string;
}

export const BaseMapsList: BaseMap[] = [
  {
    id: 'light',
    label: t`Light`,
    labelColor: 'black',
    url: 'mapbox://styles/hello-ca/ckja7lax97jhv19qmvnk44szf', // v2.2
  },
  {
    id: 'dark',
    label: t`Dark`,
    labelColor: 'whitesmoke',
    url: 'mapbox://styles/hello-ca/cjqgymw48lda52sln1l7egt16', // v2
  },
  {
    id: 'basic',
    label: t`Basic`,
    labelColor: 'black',
    url: 'mapbox://styles/hello-ca/cjt8s1z2t2hk51fnkwghql4kl', // v2.1
  },
  {
    id: 'satellite',
    label: t`Satellite`,
    labelColor: 'whitesmoke',
    url: 'mapbox://styles/hello-ca/cjt8s46pa1zca1fmeuyschdqk', // v2.1
  },
  {
    id: 'streets',
    label: t`Streets`,
    labelColor: 'black',
    url: 'mapbox://styles/hello-ca/cjt8s7zig0szm1fldekuk9xpx', // v2.1
  },
  {
    id: 'bright',
    label: t`Bright`,
    labelColor: 'black',
    url: 'mapbox://styles/hello-ca/cjt8s0iam1s3z1gpgm2f26rty', // v2.1
  },
  {
    id: 'satelliteFaded',
    label: t`Satellite Faded`,
    labelColor: 'black',
    url: 'mapbox://styles/hello-ca/cjt8rotbj39jp1fmwm40fftrn', // v2.1
  },
  {
    id: 'minimalLand',
    label: t`Minimal Land Light`,
    labelColor: 'black',
    url: 'mapbox://styles/hello-ca/cjpbhcmie1w442so8mnm6f79m', // v2
  },
  {
    id: 'minimalLandDark',
    label: t`Minimal Land Dark`,
    labelColor: 'whitesmoke',
    url: 'mapbox://styles/hello-ca/cjpycbrr33ug92rnt8xoe0y6n', // v2
  },
  {
    id: 'terrain',
    label: t`Terrain`,
    labelColor: 'black',
    url: 'mapbox://styles/hello-ca/cjqguwr5n0r4y2roeutipl594', // v2
  },
  {
    id: 'schematic',
    label: t`Schematic`,
    labelColor: 'black',
    url: 'mapbox://styles/hello-ca/cjpyd85nf0pw52smr8vha379i', // v2
  },
  {
    id: 'blank',
    label: t`Blank`,
    labelColor: 'black',
    url: 'mapbox://styles/hello-ca/cjt8rrsc835nz1foag5iou631', // v2.1
  },
];

export const EMPTY_BASE_MAP = 'mapbox://styles/mapbox/empty-v9';

export const BaseMapsById = _.keyBy(BaseMapsList, 'id') as Record<
  BaseMapIds,
  BaseMap
>;

export function findBaseMapByStyleUrl(styleUrl: string): BaseMap {
  const baseMap = _.find(BaseMapsById, map => map.url === styleUrl);
  return baseMap;
}
