import {
  CuratedColumnSymbology,
  CUSTOM_THEME,
  DEFAULT_OPACITY,
  InterpolationTypes,
  PaintColorTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/public/databank/city_of_fresno_zoning_city_of_fresno_ca': {
    description: [
      {
        type: SymbologyTypes.FILL,
        paint: {
          'fill-color': {
            property: 'description',
            type: PaintColorTypes.CATEGORICAL,
            stops: [
              { value: 'Business Park', color: '#E7BEFF' },
              { value: 'Commercial Community', color: '#FF6209' },
              { value: 'Commercial General', color: '#FF1304' },
              { value: 'Commercial Highway and Auto', color: '#CC0066' },
              { value: 'Commercial Main Street', color: '#AD80BD' },
              { value: 'Commercial Recreation', color: '#E64B00' },
              { value: 'Commercial Regional', color: '#BF3996' },
              { value: 'Corridor/Center Mixed Use', color: '#C448FE' },
              { value: 'Downtown Core', color: '#740C45' },
              { value: 'Downtown General', color: '#A83845' },
              { value: 'Downtown Neighborhood', color: '#B8778B' },
              { value: 'Heavy Industrial', color: '#686868' },
              { value: 'Light Industrial', color: '#9C9C9C' },
              { value: 'Mobile Home Park', color: '#FEAC03' },
              { value: 'Neighborhood Mixed Use', color: '#BD96FE' },
              { value: 'Office', color: '#FF96F5' },
              { value: 'Open Space', color: '#57AB54' },
              { value: 'Park and Recreation', color: '#57AB54' },
              { value: 'Public and Institutional', color: '#05C4FE' },
              { value: 'Regional Mixed Use', color: '#8400A8' },
              { value: 'Residential Estate', color: '#FEFFBE' },
              {
                value: 'Residential Multi-Family, High Density',
                color: '#730401',
              },
              {
                value: 'Residential Multi-Family, Medium High Density',
                color: '#FEAC03',
              },
              {
                value: 'Residential Multi-Family, Urban Neighborhood',
                color: '#A86F02',
              },
              {
                value: 'Residential Single-Family, Medium Density',
                color: '#FFD37F',
              },
              {
                value: 'Residential Single-Family, Medium Low Density',
                color: '#FDFF00',
              },
              {
                value: 'Residential Single-Family, Low Density',
                color: '#FEFFBE',
              },
              {
                value: 'Residential Single-Family, Very Low Density',
                color: '#FEFFBE',
              },
              {
                value: 'Residential Single-Family, Extremely Low Density',
                color: '#FEFFBE',
              },
            ],
          },
          'fill-opacity': DEFAULT_OPACITY,
        },
        display_hints: {
          scale: SymbologyScales.CATEGORICAL,
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          theme: CUSTOM_THEME,
        },
      },
    ],
  },
};

export default symbologies;
