import {
  makeKeyedReducer,
  makeSetValueOnlyReducer,
} from 'uf/base/reducerFactories';
import { UPDATE_VISIBILITY_FILTER } from 'uf/explore/ActionTypes';

const visibilityFilters = makeKeyedReducer(
  makeKeyedReducer(
    makeSetValueOnlyReducer<string>(UPDATE_VISIBILITY_FILTER, null),
    'columnKey',
  ),
  'layerId',
);
export default visibilityFilters;
