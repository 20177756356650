import uniqid from 'uniqid';

import { LayerQuery } from 'uf-api';
import { parseFullPath } from 'uf/base/dataset';
import slug from 'uf/base/slug';
import { LayerId } from 'uf/layers';
import { Expression } from 'uf/layers/filters';

// Number from api/swagger.yaml.tmpl
const MAXIMUM_PROJECT_KEY_LENGTH = 55;

export function generateProjectKey(name: string) {
  const sluggedName = slug(name);
  const suffix = `_${uniqid()}`;
  // Make sure that the key isn't too long, as specified in the schema
  const key =
    sluggedName.substring(0, MAXIMUM_PROJECT_KEY_LENGTH - suffix.length) +
    suffix;

  return key;
}

/**
 * Construct a LayerQuery-shaped object.
 */
export function makeLayerQuery(
  layerId: LayerId,
  version: string,
  filter: Expression,
): LayerQuery {
  const { namespace, type, key } = parseFullPath(layerId);
  return {
    layer: {
      namespace,
      layer_type: type,
      key,
      version,
    },
    filter,
  };
}

export function makeProjectId(namespace: string, key: string) {
  return `/${namespace}/project/${key}`;
}
