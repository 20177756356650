import { GeoJsonGeometryTypes } from 'geojson';

import { assertNever } from 'uf/base/never';
import {
  CUSTOM_THEME,
  DataDrivenColorProperty,
  DistributionTypes,
  isCircleSymbology,
  isFillSymbology,
  isLineSymbology,
  LayerColumnSymbology,
  PaintPropertyKey,
  SymbologyDisplayHints,
} from 'uf/symbology';
import { Bin, getStopValuesFromBins } from 'uf/symbology/bins';
import { prunePaintProperty } from 'uf/symbology/helpers';
import { makePaintPropertyKey } from 'uf/symbology/spec/paint';

const COLOR_SUFFIX = 'color';

/**
 * Updates the stop color for a specific legend stop.
 * This happens when the user opens the custom bins editor and changes the range.
 *
 * @param symbologies
 * @param ufGeometryType how we know what should and shouldn't be converted
 * @param bins bins that with all information needed to populate stops for each
 * symbology type
 */
export function updateCustomStops(
  symbologies: LayerColumnSymbology[],
  ufGeometryType: GeoJsonGeometryTypes,
  bins: Bin[],
  min?: number,
  max?: number,
): LayerColumnSymbology[] {
  switch (ufGeometryType) {
    case 'MultiPolygon':
    case 'Polygon':
      return updatePolygonLayerStops(symbologies, bins, min, max);

    case 'MultiLineString':
    case 'LineString':
      return updateLineLayerStops(symbologies, bins, min, max);

    case 'MultiPoint':
    case 'Point':
      return updatePointLayerStops(symbologies, bins, min, max);

    case 'GeometryCollection':
      return [];

    default:
      return assertNever(ufGeometryType);
  }
}

function updatePolygonLayerStops(
  symbologies: LayerColumnSymbology[],
  bins: Bin[],
  min: number,
  max: number,
): LayerColumnSymbology[] {
  return symbologies.map(fillSymbology => {
    if (!isFillSymbology(fillSymbology)) {
      return fillSymbology;
    }
    const paintPropertyKey = makePaintPropertyKey(fillSymbology, COLOR_SUFFIX);

    const binColorKey = 'fillColor';
    const stopColorKey = 'color';
    const updatedFillSymbology = updateDataDrivenStops(
      fillSymbology,
      bins,
      min,
      max,
      paintPropertyKey,
      binColorKey,
      stopColorKey,
    );
    return updatedFillSymbology;
  });
}

function updateLineLayerStops(
  symbologies: LayerColumnSymbology[],
  bins: Bin[],
  min: number,
  max: number,
): LayerColumnSymbology[] {
  return symbologies.map(lineSymbology => {
    if (!isLineSymbology(lineSymbology)) {
      return lineSymbology;
    }
    const paintPropertyKey = makePaintPropertyKey(lineSymbology, COLOR_SUFFIX);
    const binColorKey = 'lineColor';
    const stopColorKey = 'color';
    const updatedLineSymbology = updateDataDrivenStops(
      lineSymbology,
      bins,
      min,
      max,
      paintPropertyKey,
      binColorKey,
      stopColorKey,
    );
    return updatedLineSymbology;
  });
}

function updatePointLayerStops(
  symbologies: LayerColumnSymbology[],
  bins: Bin[],
  min: number,
  max: number,
): LayerColumnSymbology[] {
  return symbologies.map(circleSymbology => {
    if (!isCircleSymbology(circleSymbology)) {
      return circleSymbology;
    }
    const paintPropertyKey = makePaintPropertyKey(
      circleSymbology,
      COLOR_SUFFIX,
    );
    const binColorKey = 'fillColor';
    const stopColorKey = 'color';
    const updatedCircleSymbology = updateDataDrivenStops(
      circleSymbology,
      bins,
      min,
      max,
      paintPropertyKey,
      binColorKey,
      stopColorKey,
    );
    return updatedCircleSymbology;
  });
}

/**
 * Updates the stops of a symbology for a given paint property.
 *
 * If the gven symbology did not previously contain this paint property,
 * it will clone the symbology's data-driven color property by default.
 *
 * @param symbology the symbology to update
 * @param bins bins to convert to symbology stops
 * @param min the minimum value a stop can be
 * @param max the maximum value a stop can be
 * @param paintPropertyKey eg: circle-stroke-color or line-color
 * @param binColorKey the Bin property to get the color value from
 * @param stopColorKey the stop property to store the color value in
 */
function updateDataDrivenStops(
  symbology: LayerColumnSymbology,
  bins: Bin[],
  min: number,
  max: number,
  paintPropertyKey: PaintPropertyKey,
  binColorKey: string,
  stopColorKey: string,
): LayerColumnSymbology {
  const stopValues = getStopValuesFromBins(bins);

  const colorValues = bins.map(bin => bin[binColorKey]);
  const stops = stopValues.map((value, index) => ({
    value,
    [stopColorKey]: colorValues[index],
  }));

  const paintProperty = symbology?.paint?.[paintPropertyKey];
  let updatedPaintProperty: DataDrivenColorProperty = {
    ...paintProperty,
    stops,
  };

  // The user may try to set a custom bin that is greater than the max or less
  // than the min of the dataset. "Pruning the bins" re-aligns the intervals w/
  // the data again.
  updatedPaintProperty = prunePaintProperty(updatedPaintProperty, min, max);

  const displayHints: SymbologyDisplayHints = {
    ...symbology.display_hints,
    distribution: DistributionTypes.CUSTOM,
    theme: CUSTOM_THEME,
  };

  return {
    ...symbology,
    paint: {
      ...symbology.paint,
      [paintPropertyKey]: updatedPaintProperty,
    },
    display_hints: displayHints || symbology.display_hints,
  };
}
