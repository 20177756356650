export enum IconSize {
  'EXTRA_SMALL' = 14,
  'SMALL' = 18,
  'MEDIUM' = 24,
  'LARGE' = 36,
  'EXTRA_LARGE' = 54,
  'JUMBO' = 100,
}

/**
 * Icon size constants for use with `<Icon>` from `@mdi/react`. These are based
 * on the sizing where 1 = 1.5rem, or about 15px.
 */
export enum IconSizeNew {
  /**
   * About 14px
   */
  EXTRA_SMALL = 0.93,
  /**
   * About 18px
   */
  SMALL = 1.2,
  /**
   * About 26px
   */
  MEDIUM = 1.733333,
  /**
   * About 36px
   */
  LARGE = 2.4,
  /**
   * About 54px
   */
  EXTRA_LARGE = 3.6,
  /**
   * About 90px
   */
  JUMBO = 6,
}
