/**
 * UrbanFootprint App API
 * API for serving UrbanFootprint Application
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { LayerQuery } from './layerQuery';

/**
 * The current state of a dataset version.
 */
export interface VersionStatus<> {
  /**
   * Error message, if any
   */
  error_message?: string;
  /**
   * The key of the dataset.
   */
  key: string;
  /**
   * The namespace of the dataset.
   */
  namespace: string;
  parent_query?: LayerQuery;
  /**
   * The state of the load.
   */
  status: VersionStatus.StatusEnum;
  /**
   * The version of the dataset being loaded
   */
  version: number;
}
export namespace VersionStatus {
  export type StatusEnum =
    | 'IN_PROGRESS'
    | 'ERROR'
    | 'INDEX_IN_PROGRESS'
    | 'INDEX_ERROR'
    | 'DONE';
  export const StatusEnum = {
    INPROGRESS: 'IN_PROGRESS',
    ERROR: 'ERROR',
    INDEXINPROGRESS: 'INDEX_IN_PROGRESS',
    INDEXERROR: 'INDEX_ERROR',
    DONE: 'DONE',
  } as const;
}
