import { useEffect, useRef } from 'react';

/**
 * A hook which returns the previous value of another value - i.e. the previous
 * value that was updated.
 *
 * Usage:
 * ```
 * const prevValue = usePrevious(currentValue);
 * if (prevValue > currentValue) {
 *   // ...going down...
 * }
 * ```
 *
 * @param value The value to track
 * @param comparator a function that checks if two are equal. Defaults to an
 *   exact comparison (`===`)
 */
export default function usePrevious<T>(
  value: T,
  comparator?: (a: T, b: T) => boolean,
): T {
  const ref = useRef<T>();
  useEffect(() => {
    if (!comparator || !comparator(ref.current, value)) {
      ref.current = value;
    }
  }, [value, comparator]);
  return ref.current;
}
