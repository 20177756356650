import colors from 'material-colors';

import {
  CuratedColumnSymbology,
  CUSTOM_THEME,
  DEFAULT_OPACITY,
  InterpolationTypes,
  PaintColorTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/public/dataset/tnc_terrestrial_ecoregions': {
    wwf_mhtnam: [
      {
        type: SymbologyTypes.FILL,
        paint: {
          'fill-color': {
            property: 'wwf_mhtnam',
            type: PaintColorTypes.CATEGORICAL,
            stops: [
              {
                value: 'Tropical and Subtropical Moist Broadleaf Forests',
                color: '#2D632D',
              },
              {
                value: 'Tropical and Subtropical Dry Broadleaf Forests',
                color: '#94AD3A',
              },
              {
                value: 'Tropical and Subtropical Coniferous Forests',
                color: '#5CCB9C',
              },
              {
                value: 'Temperate Broadleaf and Mixed Forests',
                color: '#42C141',
              },
              { value: 'Temperate Conifer Forests', color: '#35A59D' },
              { value: 'Boreal Forests/Taiga', color: '#2E7979' },
              {
                value:
                  'Tropical and Subtropical Grasslands, Savannas and Shrublands',
                color: '#AFD47F',
              },
              {
                value: 'Temperate Grasslands, Savannas and Shrublands',
                color: '#EEDD82',
              },
              { value: 'Flooded Grasslands and Savannas', color: '#87CEFA' },
              { value: 'Montane Grasslands and Shrublands', color: '#C2BF00' },
              { value: 'Tundra', color: '#72E5D1' },
              {
                value: 'Mediterranean Forests, Woodlands and Scrub',
                color: '#E87461',
              },
              { value: 'Deserts and Xeric Shrublands', color: '#FEFAC2' },
              { value: 'Mangroves', color: '#9f0500' },
              { value: 'Inland Water', color: '#0062b1' },
              { value: 'Rock and Ice', color: '#EFEFEF' },
            ],
          },
          'fill-opacity': DEFAULT_OPACITY,
        },
        display_hints: {
          scale: SymbologyScales.CATEGORICAL,
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          theme: CUSTOM_THEME,
        },
      },
      {
        type: SymbologyTypes.LINE,
        paint: {
          'line-color': {
            property: 'wwf_mhtnam',
            type: PaintColorTypes.CATEGORICAL,
            stops: [
              {
                value: 'Tropical and Subtropical Moist Broadleaf Forests',
                color: '#00ff00',
              },
              {
                value: 'Tropical and Subtropical Dry Broadleaf Forests',
                color: '#9acd32',
              },
              {
                value: 'Tropical and Subtropical Coniferous Forests',
                color: '#7cfc00',
              },
              {
                value: 'Temperate Broadleaf and Mixed Forests',
                color: '#00fa9a',
              },
              { value: 'Temperate Conifer Forests', color: '#228b22' },
              { value: 'Boreal Forests/Taiga', color: '#2e8b57' },
              {
                value:
                  'Tropical and Subtropical Grasslands, Savannas and Shrublands',
                color: '#fff1b9',
              },
              {
                value: 'Temperate Grasslands, Savannas and Shrublands',
                color: '#ffda00',
              },
              { value: 'Flooded Grasslands and Savannas', color: '#00ffef' },
              { value: 'Montane Grasslands and Shrublands', color: '#c9af8c' },
              { value: 'Tundra', color: '#3cb371' },
              {
                value: 'Mediterranean Forests, Woodlands and Scrub',
                color: '#ff0800',
              },
              { value: 'Deserts and Xeric Shrublands', color: '#cc4a4c' },
              { value: 'Mangroves', color: '#862b92' },
              { value: 'Inland Water', color: '#d1eefe' },
              { value: 'Rock and Ice', color: colors.white },
            ],
          },
          'line-opacity': DEFAULT_OPACITY,
        },
        display_hints: {
          scale: SymbologyScales.CATEGORICAL,
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          theme: CUSTOM_THEME,
        },
      },
    ],
  },
};

export default symbologies;
