import { combineReducers } from 'redux';

import { makeSetValueReducer } from 'uf/base/reducerFactories';
import {
  SET_BASE_EDIT_CONFIRMED_SAFE,
  SET_BASE_EDIT_KEY_ONLY,
  UPDATE_PROJECT_PAINTING_STATUS,
  UPDATE_SCENARIO_PAINTING_STATUS,
} from 'uf/painting/ActionTypes';
import { PaintState } from 'uf/painting/state';

import paintPending from './paintPending';

const scenarioStatus = makeSetValueReducer(UPDATE_SCENARIO_PAINTING_STATUS);
const projectStatus = makeSetValueReducer(UPDATE_PROJECT_PAINTING_STATUS);

const painting = combineReducers<PaintState>({
  scenarioStatus,
  projectStatus,
  paintPending,
  baseEditKeyOnly: makeSetValueReducer<boolean>(SET_BASE_EDIT_KEY_ONLY),
  baseEditConfirmedSafe: makeSetValueReducer<boolean>(
    SET_BASE_EDIT_CONFIRMED_SAFE,
  ),
});

export default painting;
