import colors from 'material-colors';

import { DEFAULT_GEO_COLUMN_KEY } from 'uf/layers/geometryKey';
import {
  CuratedColumnSymbology,
  DEFAULT_OPACITY,
  InterpolationTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

import stateList from './us_states';

const symbologies: Record<
  string,
  Record<string, CuratedColumnSymbology[]>
> = {};

stateList.forEach(stateName => {
  symbologies[`/public/dataset/lcpconnectors20170305_${stateName}`] = {
    [DEFAULT_GEO_COLUMN_KEY]: [
      {
        type: SymbologyTypes.LINE,
        paint: {
          'line-width': 3,
          'line-color': colors.blue[900],
          'line-opacity': DEFAULT_OPACITY,
        },
        display_hints: {
          scale: SymbologyScales.CATEGORICAL,
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          theme: 'material:blue:900',
        },
      },
    ],
  };
});

export default symbologies;
