import {
  CuratedColumnSymbology,
  CUSTOM_THEME,
  DEFAULT_OPACITY,
  DistributionTypes,
  InterpolationTypes,
  PaintColorTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/public/dataset/hud_fair_market_rents': {
    fmr_1bdr: [
      {
        type: SymbologyTypes.FILL,
        paint: {
          'fill-color': {
            property: 'fmr_1bdr',
            type: PaintColorTypes.INTERVAL,
            stops: [
              { value: 0, color: '#e6f6e1' },
              { value: 250, color: '#aadeba' },
              { value: 500, color: '#8bd2bf' },
              { value: 750, color: '#6bc2c9' },
              { value: 1000, color: '#4caecd' },
              { value: 1250, color: '#3193c2' },
              { value: 1500, color: '#1978b4' },
              { value: 1750, color: '#0a5d9f' },
              { value: 2000, color: '#084081' },
            ],
          },
          'fill-opacity': DEFAULT_OPACITY,
        },
        display_hints: {
          scale: SymbologyScales.NUMERIC,
          distribution: DistributionTypes.DEFAULT,
          interpolation: InterpolationTypes.INTERVAL,
          theme: CUSTOM_THEME,
        },
      },
    ],
  },
};

export default symbologies;
