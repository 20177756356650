import { t } from 'ttag';

// This should be used when the response is coming from the server
export const defaultServerError = t`There was an unexpected response from the server. Please try again.`;

// Parse some JSON, probably from the server, without throwing an exception. This
// turns JSON parsing errors into problem JSON, as per https://tools.ietf.org/html/rfc7807
export function parseServerJSON(
  str: string,
  errorMessage = defaultServerError,
) {
  try {
    return JSON.parse(str);
  } catch (e) {
    // simulate an rfc7807-compliant JSON error
    return {
      type: `jsonerror:${e}`,
      title: errorMessage,
    };
  }
}
