import {
  CuratedColumnSymbology,
  CUSTOM_THEME,
  DEFAULT_OPACITY,
  DistributionTypes,
  InterpolationTypes,
  PaintColorTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/public/dataset/sonoma_reference_data_parcel': {
    smart_rail_station_distance: [
      {
        type: SymbologyTypes.FILL,
        paint: {
          'fill-color': {
            property: 'smart_rail_station_distance',
            type: PaintColorTypes.INTERVAL,
            stops: [
              { value: 100, color: 'rgb(18, 18, 137)' },
              { value: 500, color: 'rgb(42, 36, 152)' },
              { value: 1000, color: 'rgb(61, 53, 166)' },
              { value: 2000, color: 'rgb(77, 70, 181)' },
              { value: 3000, color: 'rgb(94, 87, 196)' },
              { value: 4000, color: 'rgb(110, 104, 211)' },
              { value: 5000, color: 'rgb(125, 122, 225)' },
              { value: 10000, color: 'rgb(142, 140, 240)' },
            ],
          },
          'fill-opacity': DEFAULT_OPACITY,
        },
        display_hints: {
          scale: SymbologyScales.NUMERIC,
          distribution: DistributionTypes.DEFAULT,
          interpolation: InterpolationTypes.INTERVAL,
          theme: CUSTOM_THEME,
        },
      },
    ],
  },
};

export default symbologies;
