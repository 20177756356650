/**
 * UrbanFootprint App API
 * API for serving UrbanFootprint Application
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * GeoJSON Geometry
 */
export interface GeoJSONGeometry<Tcoordinates extends object = any> {
  /**
   * Coordinates of geometry based on geometry type
   */
  coordinates: Tcoordinates;
  /**
   * Type of geometry
   */
  type: GeoJSONGeometry.TypeEnum;
}
export namespace GeoJSONGeometry {
  export type TypeEnum =
    | 'Point'
    | 'LineString'
    | 'Polygon'
    | 'MultiPoint'
    | 'MultiLineString'
    | 'MultiPolygon';
  export const TypeEnum = {
    Point: 'Point',
    LineString: 'LineString',
    Polygon: 'Polygon',
    MultiPoint: 'MultiPoint',
    MultiLineString: 'MultiLineString',
    MultiPolygon: 'MultiPolygon',
  } as const;
}
