import { LayerColumn, LayerMetadata } from 'uf-api';
import { formatValue } from 'uf/layers/formatting';
import { LayerTableData, LayerTableDataRow } from 'uf/layers/layerData';
import { shouldHideColumn } from 'uf/layers/metadata';

export function convertLayerDataToDataTable(
  data: Record<string, string | number>[],
  layerMetadata: LayerMetadata,
  valueLabelMap: Record<string, Record<string, string>>,
): LayerTableData {
  let columns: LayerColumn[];
  if (layerMetadata?.columns) {
    columns = layerMetadata.columns.filter(column => !shouldHideColumn(column));
  } else {
    columns = Object.keys(data).map(key => ({ key }));
  }

  return {
    cols: columns,
    rows: data.map(row => {
      return columns.map<LayerTableDataRow>(column => {
        const value = row[column.key];
        const f =
          valueLabelMap?.[column.key]?.[value] || formatValue(value, column);
        return {
          key: column.key,
          v: value,
          f,
        };
      });
    }),
  };
}
