import { getLayerGeojsonActionTypes } from 'uf-api/api/layer.service';
import { parseFullPath } from 'uf/base/dataset';
import { makeEnsureFetchStream } from 'uf/data/getFetchStream';
import { fetchLayerGeojson } from 'uf/layers/actions/geojson';
import {
  getFilterClause,
  getLayerDataApiParams,
  getLayerDataSearchKey,
} from 'uf/layers/filters';
import { makeGetLayerGeojson } from 'uf/layers/selectors/geojson';

export const ensureGeoJsonStream = makeEnsureFetchStream(
  getLayerGeojsonActionTypes,
  makeGetLayerGeojson,
  params => {
    const { layerId } = params;
    const { key: layerKey, namespace, type } = parseFullPath(layerId);
    const { apiParams } = getLayerDataApiParams(layerId, params);
    const realParams = {
      ...apiParams,
      namespace,
      key: layerKey,
      layer_type: type,
      limit: 1000,
      ...getFilterClause(apiParams.filters),
    };
    return fetchLayerGeojson(realParams);
  },
  params => {
    const { layerId, ...rest } = params;
    return { key: getLayerDataSearchKey(layerId, rest) };
  },
);
