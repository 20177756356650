import { t } from 'ttag';

import { Flavor } from 'uf/base/types';

interface FeatureVisibility {
  contoursVisible: boolean;
  buildingsVisible: boolean;
  citiesLabelVisible: boolean;
  placesLabelVisible: boolean;
  poiLabelVisible: boolean;
  roadRailLabelVisible: boolean;
  waterLabelVisible: boolean;
  waterMaskVisible: boolean;
}

export type BaseMapLayerId = Flavor<string, 'BaseMapLayerId'>;

interface BaseLayers {
  featureProp: keyof FeatureVisibility;
  featureLabel: string;
  mapLayerIds: BaseMapLayerId[];
}

// Mapbox features for which we would want to toggle visibiliy
export const baseMapLayers: BaseLayers[] = [
  {
    featureProp: 'contoursVisible',
    featureLabel: t`Elevation Contours`,
    mapLayerIds: ['contour', 'contour_labels'],
  },
  {
    featureProp: 'buildingsVisible',
    featureLabel: t`3D Buildings`,
    // TODO perhaps add building footprints to the list below?
    mapLayerIds: ['building'],
  },
  {
    featureProp: 'citiesLabelVisible',
    featureLabel: t`City Labels`,
    mapLayerIds: [
      'place-city-lg-n',
      'place-city-lg-s',
      'place-city-md-n',
      'place-city-md-s',
      'place-city-sm',
      'place-town',
    ],
  },
  {
    featureProp: 'placesLabelVisible',
    featureLabel: t`Place Labels`,
    mapLayerIds: [
      'place-islands',
      'place-village',
      'place-hamlet',
      'place-suburb',
      'place-neighbourhood',
      'place-islets-archipelago-aboriginal',
      'airport-label',
    ],
  },
  {
    featureProp: 'poiLabelVisible',
    featureLabel: t`Points of Interest Labels`,
    mapLayerIds: [
      'poi-scalerank1',
      'poi-scalerank2',
      'poi-scalerank3',
      'poi-parks-scalerank1',
      'poi-parks-scalerank2',
      'poi-parks-scalerank3',
      'poi-parks-scalerank4',
    ],
  },
  {
    featureProp: 'roadRailLabelVisible',
    featureLabel: t`Roads and Rail Labels`,
    mapLayerIds: [
      'road-label-large',
      'road-label-medium',
      'road-label-small',
      'motorway-junction',
      'road-shields-white',
      'road-shields-black',
      'rail-label',
    ],
  },
  {
    featureProp: 'waterLabelVisible',
    featureLabel: t`Water Labels`,
    mapLayerIds: ['water-label', 'waterway-label', 'water-label-sm'],
  },
  {
    featureProp: 'waterMaskVisible',
    featureLabel: t`Oceans and Seas`,
    mapLayerIds: ['water-mask'],
  },
];

export function setLayerVisibility(
  map: mapboxgl.Map,
  mapLayerId: string,
  propValue: boolean,
) {
  const mapLayerVisibility = map.getLayoutProperty(mapLayerId, 'visibility');
  // call setLayoutProperty and switch value based on prop value
  if (propValue && mapLayerVisibility === 'none') {
    map.setLayoutProperty(mapLayerId, 'visibility', 'visible');
  } else if (!propValue && mapLayerVisibility === 'visible') {
    map.setLayoutProperty(mapLayerId, 'visibility', 'none');
  }
}

export function getVisibleFeatureIds(props: FeatureVisibility) {
  const showFeatures: BaseMapLayerId[] = [];
  baseMapLayers.forEach(({ featureProp, mapLayerIds }) => {
    const visible: boolean = props[featureProp];
    if (visible) {
      showFeatures.push(...mapLayerIds);
    }
  });
  return showFeatures;
}
