/**
 * UrbanFootprint App API
 * API for serving UrbanFootprint Application
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * True if expression is false.
 */
export interface ExpressionNot<Texpression extends object = any> {
  /**
   * not
   */
  expression: Texpression;
  /**
   * Expression function, set to \"not\".
   */
  fn: ExpressionNot.FnEnum;
}
export namespace ExpressionNot {
  export type FnEnum = 'not';
  export const FnEnum = {
    Not: 'not',
  } as const;
}
