import colors from 'material-colors';

import {
  CuratedColumnSymbology,
  CUSTOM_THEME,
  DEFAULT_OPACITY,
  DistributionTypes,
  InterpolationTypes,
  PaintColorTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/public/dataset/national_energy_consumption_calibration_factors_by_state': {
    res_elec_calibration_factor: [
      {
        type: SymbologyTypes.FILL,
        paint: {
          'fill-color': {
            property: 'res_elec_calibration_factor',
            type: PaintColorTypes.INTERVAL,
            stops: [
              { value: 0.1, label: '0 to 0.39', color: colors.orange[800] },
              { value: 0.4, label: '0.4 to 0.59', color: colors.orange[500] },
              { value: 0.6, label: '0.6 to 0.79', color: colors.orange[200] },
              { value: 0.8, label: '0.8 to 0.99', color: colors.yellow[100] },
              { value: 1, label: '1.0 to 1.19', color: '#fefaee' },
              {
                value: 1.2,
                label: '1.2 to 1.39',
                color: colors.lightGreen[200],
              },
              { value: 1.4, label: '1.4 to 1.59', color: '#509157' },
              { value: 1.6, label: '1.6', color: '#175c3f' },
            ],
          },
          'fill-opacity': DEFAULT_OPACITY,
        },
        display_hints: {
          scale: SymbologyScales.NUMERIC,
          distribution: DistributionTypes.DEFAULT,
          interpolation: InterpolationTypes.INTERVAL,
          theme: CUSTOM_THEME,
        },
      },
    ],
  },
};

export default symbologies;
