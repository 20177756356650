import React, { Fragment, FunctionComponent } from 'react';
import { connect } from 'react-redux';

import {
  AnalysisModuleStateInfo,
  makeGetAnalysisModuleInfosForAnalysisEditor,
} from 'uf/analysis/selectors/analysis';
import {
  getActiveProjectId,
  getActiveScenarioId,
  getBaseScenarioId,
} from 'uf/app/selectors';
import { ProjectId } from 'uf/projects';
import { ScenarioId } from 'uf/scenarios';
import AnalysisModuleListItem from 'uf/ui/analysis/AnalysisModuleListItem/AnalysisModuleListItem';

interface OwnProps {
  activeModuleKey: string;
  onSetActiveModule: (moduleKey: string) => void;

  /**
   * Controls whether to render the active module's list item with a blue background and light-blue
   * left border. This is akin to the styling of the LayerList.
   */
  disableActiveStyling?: boolean;

  /**
   * Controls whether to render the control buttons which appear when hovering over an analysis
   * module's list item, ie: Run and Kebab buttons
   */
  hideHoverControls?: boolean;

  /**
   * mode determines the level info shown.  If 'project', statuses will reflect results of all
   * scenarios for the supplied projectId.  If 'scenario', statuses only reflect results for the
   * supplied scenarioId
   */
  mode?: 'project' | 'scenario';
}

interface StateProps {
  projectId: ProjectId;
  scenarioId: ScenarioId;
  baseScenarioId: string;
  moduleListItemInfos: AnalysisModuleStateInfo[];
}

type AnalysisModulesProps = OwnProps & StateProps;

export const AnalysisModules: FunctionComponent<AnalysisModulesProps> =
  props => {
    const {
      onSetActiveModule,
      moduleListItemInfos,
      projectId,
      scenarioId,
      baseScenarioId,
      disableActiveStyling,
      hideHoverControls,
      activeModuleKey,
      mode = 'scenario',
    } = props;
    const isBaseScenario = baseScenarioId === scenarioId;
    return (
      <Fragment>
        {moduleListItemInfos.map(
          ({
            moduleKey,
            hasResults,
            moduleIsRunning,
            moduleIsDone,
            statusMessage,
          }) => (
            <AnalysisModuleListItem
              mode={mode}
              active={!disableActiveStyling && moduleKey === activeModuleKey}
              hasResults={hasResults}
              moduleIsRunning={moduleIsRunning}
              moduleIsDone={moduleIsDone}
              statusMessage={hideHoverControls ? statusMessage : undefined}
              hideHoverControls={hideHoverControls}
              key={moduleKey}
              moduleKey={moduleKey}
              projectId={projectId}
              scenarioId={scenarioId}
              isBaseScenario={isBaseScenario}
              onActivateModule={onSetActiveModule}
            />
          ),
        )}
      </Fragment>
    );
  };

const makeMapStateToProps = () => {
  const getAnalysisModuleInfosForAnalysisEditor =
    makeGetAnalysisModuleInfosForAnalysisEditor();

  return (state): StateProps => {
    const projectId = getActiveProjectId(state);
    return {
      projectId,
      scenarioId: getActiveScenarioId(state),
      baseScenarioId: getBaseScenarioId(state),
      moduleListItemInfos: getAnalysisModuleInfosForAnalysisEditor(state, {
        projectId,
      }),
    };
  };
};

export default connect<StateProps>(makeMapStateToProps, null)(AnalysisModules);
