import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { filter, map } from 'rxjs/operators';

import { combineEpics } from 'uf/base/epics';
import { UFState } from 'uf/state';
import {
  dispatchNotification,
  shouldHandleTaskNotification,
} from 'uf/tasks/messageRouting';
import { TaskMessage } from 'uf/tasks/MessageTypes';
import {
  WebsocketMessageAction,
  WEBSOCKETS_RECEIVE_MESSAGE,
} from 'uf/websockets/actionTypes';

/**
 * An epic to handle websocket task messages.
 */
export function websocketTaskNotificationMessage(
  action$: ActionsObservable<WebsocketMessageAction<TaskMessage>>,
  state$: StateObservable<UFState>,
) {
  return action$.pipe(
    ofType(WEBSOCKETS_RECEIVE_MESSAGE),
    filter(action =>
      shouldHandleTaskNotification(action.message, state$.value),
    ),
    // TODO: set up separate epics to dispatch based on on action.message.task_type
    map(action => dispatchNotification(action.message)),
  );
}

export default combineEpics(
  {
    websocketTaskNotificationMessage,
  },
  'tasks',
);
