import {
  CuratedColumnSymbology,
  CUSTOM_THEME,
  DEFAULT_OPACITY,
  InterpolationTypes,
  PaintColorTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/public/dataset/ca_powerplants': {
    sector_nam: [
      {
        type: SymbologyTypes.CIRCLE,
        paint: {
          'circle-radius': 3,
          'circle-color': {
            property: 'sector_nam',
            type: PaintColorTypes.CATEGORICAL,
            stops: [
              { value: 'Commercial CHP', color: '#a6cee3' },
              { value: 'Commercial Non-CHP', color: '#1f78b4' },
              { value: 'Industrial CHP', color: '#b2df8a' },
              { value: 'Industrial Non-CHP', color: '#33a02c' },
              { value: 'IPP CHP', color: '#fb9a99' },
              { value: 'IPP Non-CHP', color: '#e31a1c' },
              { value: 'Electric Utility', color: '#fdbf6f' },
            ],
          },
          'circle-opacity': DEFAULT_OPACITY,
        },
        display_hints: {
          scale: SymbologyScales.CATEGORICAL,
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          theme: CUSTOM_THEME,
        },
      },
    ],
  },
};

export default symbologies;
