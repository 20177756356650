import { Tooltip } from '@blueprintjs/core';
import { mdiAlphabetical, mdiCircleMedium, mdiNumeric, mdiStar } from '@mdi/js';
import Icon from '@mdi/react';
import classNames from 'classnames';
import * as React from 'react';
import { Box } from 'react-layout-components';
import { t } from 'ttag';

import { LayerColumn } from 'uf-api';
import { isColumnNumberType } from 'uf/layers/formatting';
import rootStyles from 'uf/styles/root.module.css';
import textStyles from 'uf/styles/text.module.css';
import { IconSizeNew } from 'uf/ui/base/icon';
import useUserFlag from 'uf/ui/user/useUserFlag';

import localStyles from './LayerMetadataSummary.module.css';
import ReadMore from 'uf/ui/base/ReadMore/ReadMore';
import { DEV_LAYER_METADATA_EXTRAS } from 'uf/user/selectors/flags';

interface Props {
  columnMetadata: LayerColumn;
  uniqueKeys: string[];
  charLimit?: number;
  hideTypeColumn: boolean;
}

const colClassName = classNames(
  'h4',
  localStyles.layerDescriptionLineHeight,
  rootStyles.breakWord,
  rootStyles.wrapLongWords,
);

const devColumnKeyClassName = classNames(
  'h5',
  localStyles.layerDescriptionLineHeight,
  rootStyles.breakWord,
  textStyles.mutedText,
);

const unitsClassName = classNames(devColumnKeyClassName, textStyles.monospace);

const primaryKeyIcon = (
  <Icon
    path={mdiStar}
    size={IconSizeNew.SMALL}
    className={textStyles.mutedText}
  />
);

const missingMetatypeIcon = (
  <Icon
    path={mdiCircleMedium}
    size={IconSizeNew.SMALL}
    className={textStyles.mutedText}
  />
);

const ColumnMetadataSummary: React.FunctionComponent<Props> = ({
  columnMetadata,
  uniqueKeys,
  hideTypeColumn,
  charLimit,
}) => {
  const hasDevLayerMetadataExtrasFlag = useUserFlag(DEV_LAYER_METADATA_EXTRAS);

  const { name, key, description, type, units, metatype } = columnMetadata;

  const isUniqueKey = uniqueKeys.includes(key);

  const isNumeric = isColumnNumberType(columnMetadata);

  const hasNecessaryUnits = !isNumeric || isUniqueKey || !!units;

  let isMetatypeNumeric = false;
  let iconTooltipContent = t`Categorical column`;

  if (metatype === 'numeric') {
    isMetatypeNumeric = true;
    iconTooltipContent = t`Numerical column`;
  }

  const metatypeIcon = (
    <Icon
      path={isMetatypeNumeric ? mdiNumeric : mdiAlphabetical}
      size={IconSizeNew.SMALL}
      className={textStyles.mutedText}
    />
  );

  const columnTextClassName = rootStyles.thinMarginLeft;
  return (
    <tr>
      <td className={colClassName}>
        <Box>
          {isUniqueKey && (
            <Tooltip content={t`Unique ID`}>{primaryKeyIcon}</Tooltip>
          )}
          {metatype && !isUniqueKey && (
            <Tooltip content={iconTooltipContent}>{metatypeIcon}</Tooltip>
          )}
          {!metatype && !isUniqueKey && (
            <Tooltip content={t`Metatype unavailable`}>
              {missingMetatypeIcon}
            </Tooltip>
          )}
          <Box flex={1} column className={columnTextClassName}>
            <span>{name}</span>
            <span>
              <Box column>
                {hasNecessaryUnits && (
                  <span className={unitsClassName}>{units}</span>
                )}
                {hasDevLayerMetadataExtrasFlag && (
                  <span className={devColumnKeyClassName}>{key}</span>
                )}
              </Box>
            </span>
          </Box>
        </Box>
      </td>
      <td className={colClassName}>
        {description ? (
          <ReadMore text={description} charLimit={charLimit} />
        ) : (
          <small>{t`No description provided`}</small>
        )}
      </td>
      {!hideTypeColumn && (
        <td className="h4">
          <span className={textStyles.monospace}>{type}</span>
        </td>
      )}
    </tr>
  );
};

export default ColumnMetadataSummary;
