import { combineReducers } from 'redux';

import { SymbologyState } from 'uf/symbology/state';

import override from './override';

export default combineReducers<SymbologyState>({
  // The source of truth for symbology when being actively configured in the symbology editor.
  // This takes precedence over the `base` symbology.
  override,
});
