import {
  CuratedColumnSymbology,
  CUSTOM_THEME,
  DEFAULT_OPACITY,
  InterpolationTypes,
  PaintColorTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/public/dataset/elk_grove_land_use': {
    land_use_m: [
      {
        type: SymbologyTypes.FILL,
        paint: {
          'fill-color': {
            property: 'land_use_m',
            type: PaintColorTypes.CATEGORICAL,
            stops: [
              {
                value: 'Agriculture Production (Crop and/or Grazing)',
                color: '#a6cee3',
              },
              { value: 'Automall', color: '#1f78b4' },
              { value: 'Cemeteries', color: '#b2df8a' },
              { value: 'Civic Uses', color: '#33a02c' },
              { value: 'General Commercial', color: '#fb9a99' },
              { value: 'Heavy Industrial', color: '#e31a1c' },
              { value: 'Hotel/Motel', color: '#fdbf6f' },
              { value: 'Large Medical Facilities', color: '#ff7f00' },
              { value: 'Light Industrial/Warehouse', color: '#cab2d6' },
              { value: 'Neighborhood Serving Commercial', color: '#6a3d9a' },
              { value: 'Office', color: '#ffff99' },
              { value: 'Open Space', color: '#b15928' },
              { value: 'Parks and Recreation Facilities', color: '#e41a1c' },
              { value: 'Religious Institutions', color: '#377eb8' },
              { value: 'Residential - Mobile Home', color: '#4daf4a' },
              { value: 'Residential - Multiple Family', color: '#984ea3' },
              { value: 'Residential - Neighborhood', color: '#ff7f00' },
              { value: 'Residential - Rural', color: '#ffff33' },
              { value: 'Right of Way', color: '#a65628' },
              { value: 'School', color: '#f781bf' },
              { value: 'Service Facilities', color: '#999999' },
            ],
          },
          'fill-opacity': DEFAULT_OPACITY,
        },
        display_hints: {
          scale: SymbologyScales.CATEGORICAL,
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          theme: CUSTOM_THEME,
        },
      },
    ],
  },
};

export default symbologies;
