/**
 * UrbanFootprint App API
 * API for serving UrbanFootprint Application
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * True if the expression is null.
 */
export interface ExpressionIsNull<Texpression extends object = any> {
  /**
   * The expression to check for null values.
   */
  expression: Texpression;
  /**
   * Expression function, set to \"is_null\".
   */
  fn: ExpressionIsNull.FnEnum;
}
export namespace ExpressionIsNull {
  export type FnEnum = 'is_null';
  export const FnEnum = {
    IsNull: 'is_null',
  } as const;
}
