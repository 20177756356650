import { createSelector } from 'reselect';

import { DEFAULT_VIEW_ID } from 'uf/views';

import { makeGetExploreByProjectState } from './explore';

/**
 * Get the active view id from project state
 * @deprecated use `selectActiveViewId` to grab value from app state
 * @returns a view id
 */
export function makeGetActiveViewId() {
  return createSelector(
    makeGetExploreByProjectState(),
    state => state?.activeViewId ?? DEFAULT_VIEW_ID,
  );
}
