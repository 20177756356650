import _ from 'lodash';
import { Action as RFRAction } from 'redux-first-router';

import { parseFullPath } from 'uf/base/dataset';
import { ProjectId } from 'uf/projects';
import { ROUTE_REPORT } from 'uf/reporting/ActionTypes';
import { Query } from 'uf/routing';
import { makeRoutingActionType } from 'uf/routing/actions';

export function setReportRoute(
  reportSpecKey: string,
  projectId: ProjectId,
  scenarioIds: string[],
): RFRAction {
  const { namespace, key } = parseFullPath(projectId);
  const query: Query = {};

  if (!_.isEmpty(scenarioIds)) {
    query.scenarios = scenarioIds.join(',');
  }

  return {
    type: makeRoutingActionType(ROUTE_REPORT),
    payload: {
      type: reportSpecKey,
      namespace,
      projectKey: key,
    },
    meta: {
      query,
      location: null,
    },
  };
}
