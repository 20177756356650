export enum TabKey {
  STYLE = 'style',
  BUILD = 'build',
  FILTER = 'filter',
  NEW_FILTER = 'new_filter',
  EDIT = 'edit',
  BUFFER = 'buffer',
  SOURCE = 'source',
  FEATURE_EDIT = 'feature_edit',
  TRANSIT_EDIT = 'transit_edit',
  INSIGHTS = 'insights',
}
