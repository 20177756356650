import { makeCustomAsyncActionTypes } from 'uf/data/ActionTypes';
import { makeTaskActionType } from 'uf/tasks/actionHelpers';
import { TaskStatuses } from 'uf/tasks/TaskStatuses';

const scenarioBasePrefix = 'uf/scenarios';

export const SET_SCENARIO_UPDATE_STATUS = `${scenarioBasePrefix}/SET_UPDATE_STATUS`;
export const SET_SCENARIO_DELETE_STATUS = `${scenarioBasePrefix}/SET_DELETE_STATUS`;

export const BIND_CREATE_SCENARIO_TASK = `${scenarioBasePrefix}/BIND_CREATE_SCENARIO_TASK`;

export const createScenarioMessageTypes = {
  [TaskStatuses.ENQUEUED]: 'CREATE_SCENARIO_STARTED',
  [TaskStatuses.DONE]: 'CREATE_SCENARIO_DONE',
  [TaskStatuses.ERROR]: 'CREATE_SCENARIO_ERROR',
} as const;

export const createScenarioTaskActionTypes = makeCustomAsyncActionTypes(
  makeTaskActionType(createScenarioMessageTypes[TaskStatuses.ENQUEUED]),
  makeTaskActionType(createScenarioMessageTypes[TaskStatuses.DONE]),
  makeTaskActionType(createScenarioMessageTypes[TaskStatuses.ERROR]),
);
