import { Button, Menu, MenuItem, Popover, Position } from '@blueprintjs/core';
import { mdiContentCopy, mdiDelete, mdiDotsVertical, mdiPencil } from '@mdi/js';
import Icon from '@mdi/react';
import React, {
  Fragment,
  FunctionComponent,
  useCallback,
  useState,
} from 'react';
import { t } from 'ttag';

import { ProjectId } from 'uf/projects';
import tw from 'uf/tailwindcss-classnames';
import { IconSizeNew } from 'uf/ui/base/icon';
import useBindAction from 'uf/ui/base/useBindAction/useBindAction';
import { ViewEditor, ViewEditorMode } from 'uf/ui/views/ViewEditor/ViewEditor';
import { DEFAULT_VIEW_ID, View } from 'uf/views';
import { viewListActions } from 'uf/views/actions';

interface Props {
  projectId: ProjectId;

  currentView?: View;

  onCreate?: (newView: View, prevView?: View) => void;
}
export const ViewMenuButton: FunctionComponent<Props> = props => {
  const { currentView, projectId, onCreate } = props;

  const [viewToClone, setViewToClone] = useState<View>(null);
  const [mode, setMode] = useState(ViewEditorMode.DUPLICATE);
  const onDeleteView = useBindAction(viewListActions.removeItem);

  const onAdd = useCallback(() => {
    setViewToClone(currentView);
    setMode(ViewEditorMode.DUPLICATE);
  }, [currentView]);
  const onCancel = useCallback(() => {
    setViewToClone(null);
  }, []);
  const onCreateView = useCallback(
    (view: View, prevView: View) => {
      setViewToClone(null);
      onCreate(view, prevView);
    },
    [onCreate],
  );
  const onEdit = useCallback(() => {
    setViewToClone(currentView);
    setMode(ViewEditorMode.EDIT);
  }, [currentView]);

  const onDelete = useCallback(() => {
    onDeleteView(currentView, projectId);
  }, [currentView, onDeleteView, projectId]);

  const canEdit = currentView?.key !== DEFAULT_VIEW_ID;

  return (
    <Fragment>
      <Popover
        content={
          <ViewMenu
            onAdd={onAdd}
            canEdit={canEdit}
            onEdit={onEdit}
            onDelete={onDelete}
          />
        }
        position={Position.RIGHT_TOP}>
        <Button
          icon={<Icon path={mdiDotsVertical} size={IconSizeNew.SMALL} />}
          small
          minimal
          className={tw('tw-m-1')}
        />
      </Popover>
      <ViewEditor
        view={viewToClone}
        mode={mode}
        show={!!viewToClone}
        onSaveView={onCreateView}
        onCancel={onCancel}
        projectId={projectId}
      />
    </Fragment>
  );
};
interface ViewMenuProps {
  onAdd: () => void;
  canEdit: boolean;
  onEdit: () => void;
  onDelete: () => void;
}

const ViewMenu: FunctionComponent<ViewMenuProps> = ({
  onAdd,
  canEdit,
  onEdit,
  onDelete,
}) => {
  return (
    <Menu>
      <MenuItem
        onClick={onAdd}
        icon={<Icon size={IconSizeNew.SMALL} path={mdiContentCopy} />}
        text={t`Save a copy`}
      />
      {canEdit && (
        <MenuItem
          onClick={onEdit}
          icon={<Icon size={IconSizeNew.SMALL} path={mdiPencil} />}
          text={t`Edit details`}
        />
      )}
      {canEdit && (
        <MenuItem
          onClick={onDelete}
          icon={<Icon size={IconSizeNew.SMALL} path={mdiDelete} />}
          text={t`Delete view`}
        />
      )}
    </Menu>
  );
};
