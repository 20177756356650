import { Button, Card, Tag } from '@blueprintjs/core';
import { Tooltip2 } from '@blueprintjs/popover2';
import { mdiClose } from '@mdi/js';
import Icon from '@mdi/react';
import React from 'react';
import styles from './PointsOfInterestDialog.module.css';
import classNames from 'classnames';

interface PointOfInterestTagProps {
  value: string;
  label: string;
  count: number;
  onClick: (string) => void;
}

export function PointOfInterestTag({
  value,
  label,
  count,
  onClick,
}: PointOfInterestTagProps) {
  return (
    <Tooltip2
      key={value}
      content={`${count} nationwide`}
      targetProps={{ className: 'tw-max-w-full' }}>
      <Tag
        data-testid="point-of-interest-tag"
        className="tw-my-1 tw-mr-1"
        intent="primary"
        rightIcon={
          <Button
            aria-label={`Remove ${label}`}
            icon={<Icon path={mdiClose} size={1} />}
            className="tw-text-white tw-p-0 tw-min-h-0 tw-min-w-0"
            small
            minimal
            onClick={() => onClick(value)}
          />
        }>
        {label}
      </Tag>
    </Tooltip2>
  );
}

interface Props {
  children: React.ReactNode;
}

export default function SelectedTags({ children }: Props) {
  return (
    <section className="tw-p-8">
      <h4 className="tw-font-bold tw-pb-6">Selected</h4>
      <Card
        className={classNames(
          'tw-p-1',
          'tw-h-32',
          'tw-overflow-y-scroll',
          styles.selection,
        )}>
        {children}
      </Card>
    </section>
  );
}
