// Create whitelists of mimetypes
// See: https://github.com/okonet/attr-accept

export const IMAGE_MIMETYPES = 'image/*';

export const GDB_MIMETYPES = ['application/zip'];

export const GDB_EXTENSIONS = ['.gdb', '.zip'];

export const GEOJSON_MIMETYPES = ['application/geo+json', 'application/json'];

export const GEOJSON_EXTENSIONS = ['.geojson', '.json'];

export const GEOPACKAGE_MIMETYPES = ['application/geopackage+sqlite3'];

export const GEOPACKAGE_EXTENSIONS = ['.gpkg'];

export const SHAPEFILE_MIMETYPES = ['application/zip'];

export const SHAPEFILE_EXTENSIONS = ['.zip'];

export const CSV_MIMETYPES = ['text/csv'];

export const CSV_EXTENSIONS = ['.csv'];
