import { Flavor } from './types';

export interface ListActionTypes<
  SET extends string = string,
  UPDATE extends string = string,
  UPSERT extends string = string,
  UPSERT_ITEMS extends string = string,
  APPEND extends string = string,
  REMOVE extends string = string,
> {
  SET: SET;
  UPDATE: UPDATE;
  UPSERT: UPSERT;
  UPSERT_ITEMS: UPSERT_ITEMS;
  APPEND: APPEND;
  REMOVE: REMOVE;
}

/**
 * A type used to create an action types structure with uniquely-typed keys.
 * Each type parameter needs a unique string value. The *values* of the type
 * string don't necessarily need to line up with the keys of ListActionTypes,
 * but they should be expressive and unique within the list of types.
 *
 * Usage:
 * ```
 * type MyObjListActionTypes = FlavoredListActionTypes<
 *   'add_myobj',
 *   'update_myobj',
 *   'upsert_myobj',
 *   'upsert_myobjs',
 *   'append_myobj',
 *   'remove_myobj'>;
 *```
 */
export type FlavoredListActionTypes<
  SET extends string,
  UPDATE extends string,
  UPSERT extends string,
  UPSERT_ITEMS extends string,
  APPEND extends string,
  REMOVE extends string,
> = ListActionTypes<
  Flavor<string, SET>,
  Flavor<string, UPDATE>,
  Flavor<string, UPSERT>,
  Flavor<string, UPSERT_ITEMS>,
  Flavor<string, APPEND>,
  Flavor<string, REMOVE>
>;

/**
 * Used to create action types for a list. Relies on a properly formed
 * FlavoredListActionTypes for construction of uniquely typed list action types.
 *
 * ```
 * type MyObjListActionTypes = FlavoredListActionTypes<
 *   'add_myobj',
 *   'update_myobj',
 *   'upsert_myobj',
 *   'upsert_myobjs',
 *   'append_myobj',
 *   'remove_myobj'>;
 *
 * // adding an explicit type will make sure makeListActionTypes
 * // gets cast correctly
 * const myObjActionTypes: MyObjListActionTypes =
 *   makeListActionTypes('uf/myobj');
 * ```
 */
export function makeListActionTypes<
  SET extends string,
  UPDATE extends string,
  UPSERT extends string,
  UPSERT_ITEMS extends string,
  ADD extends string,
  REMOVE extends string,
>(
  actionBaseType: string,
): ListActionTypes<SET, UPDATE, UPSERT, UPSERT_ITEMS, ADD, REMOVE> {
  return {
    SET: `${actionBaseType}/SET` as SET,
    UPDATE: `${actionBaseType}/UPDATE` as UPDATE,
    UPSERT: `${actionBaseType}/UPSERT` as UPSERT,
    UPSERT_ITEMS: `${actionBaseType}/UPSERT_ITEMS` as UPSERT_ITEMS,
    APPEND: `${actionBaseType}/APPEND` as ADD,
    REMOVE: `${actionBaseType}/REMOVE` as REMOVE,
  };
}

export interface SetListAction<T, K extends string = string> {
  type: K;
  list: T[];
}

export interface UpdateItemAction<T, K extends string = string> {
  type: K;
  item: T;
  newItem?: T;
}

export interface UpsertItemAction<T, K extends string = string> {
  type: K;
  item: T;
  newItem?: T;
}

export interface UpsertItemsAction<T, K extends string = string> {
  type: K;
  items: T[];
}

export interface AppendItemAction<T, K extends string = string> {
  type: K;
  items: T | T[];
}

export interface RemoveItemAction<T, K extends string = string> {
  type: K;
  items: T | T[];
}

export type ListAction<T> =
  | SetListAction<T>
  | UpdateItemAction<T>
  | UpsertItemAction<T>
  | UpsertItemsAction<T>
  | AppendItemAction<T>
  | RemoveItemAction<T>;

export interface ListActionCreators<
  T,
  SET extends string = string,
  UPDATE extends string = string,
  UPSERT extends string = string,
  UPSERT_ITEMS extends string = string,
  APPEND extends string = string,
  REMOVE extends string = string,
> {
  setList: (list: T[]) => SetListAction<T, SET>;
  updateItem: (item: T, optionalNewItem?: T) => UpdateItemAction<T, UPDATE>;
  upsertItem: (item: T, optionalNewItem?: T) => UpsertItemAction<T, UPSERT>;
  upsertItems: (items: T[]) => UpsertItemsAction<T, UPSERT_ITEMS>;
  appendItem: (items: T | T[]) => AppendItemAction<T, APPEND>;
  removeItem: (items: T | T[]) => RemoveItemAction<T, REMOVE>;
}

export interface ListActionCreatorsWithExtra<T, EA extends any[], EP> {
  setList: (list: T[], ...extra: EA) => SetListAction<T> & EP;
  updateItem: (
    item: T,
    optionalNewItem?: T,
    ...extra: EA
  ) => UpdateItemAction<T> & EP;
  upsertItem: (
    item: T,
    optionalNewItem?: T,
    ...extra: EA
  ) => UpsertItemAction<T> & EP;
  appendItem: (items: T | T[], ...extra: EA) => AppendItemAction<T> & EP;
  removeItem: (items: T | T[], ...extra: EA) => RemoveItemAction<T> & EP;
}

export function makeListActionCreators<
  T,
  SET extends string = string,
  UPDATE extends string = string,
  UPSERT extends string = string,
  UPSERT_ITEMS extends string = string,
  APPEND extends string = string,
  REMOVE extends string = string,
>(
  actionTypes: ListActionTypes<
    SET,
    UPDATE,
    UPSERT,
    UPSERT_ITEMS,
    APPEND,
    REMOVE
  >,
): ListActionCreators<T, SET, UPDATE, UPSERT, UPSERT_ITEMS, APPEND, REMOVE> {
  return {
    setList: (list: T[]): SetListAction<T, SET> => {
      return {
        type: actionTypes.SET,
        list,
      };
    },
    updateItem: (item: T, optionalNewItem?: T): UpdateItemAction<T, UPDATE> => {
      const newItem = optionalNewItem && { newItem: optionalNewItem };
      return {
        type: actionTypes.UPDATE,
        item,
        ...newItem,
      };
    },
    upsertItem: (item: T, optionalNewItem?: T): UpsertItemAction<T, UPSERT> => {
      const newItem = optionalNewItem && { newItem: optionalNewItem };
      return {
        type: actionTypes.UPSERT,
        item,
        ...newItem,
      };
    },
    upsertItems: (items: T[]): UpsertItemsAction<T, UPSERT_ITEMS> => {
      return {
        type: actionTypes.UPSERT_ITEMS,
        items,
      };
    },
    appendItem: (items: T | T[]): AppendItemAction<T, APPEND> => ({
      type: actionTypes.APPEND,
      items,
    }),
    removeItem: (items: T | T[]): RemoveItemAction<T, REMOVE> => ({
      type: actionTypes.REMOVE,
      items,
    }),
  };
}

export interface ListActionCreatorsExtra<
  T,
  EA extends any[],
  EP,
  SET extends string = string,
  UPDATE extends string = string,
  UPSERT extends string = string,
  UPSERT_ITEMS extends string = string,
  APPEND extends string = string,
  REMOVE extends string = string,
> {
  setList: (list: T[], ...extra: EA) => SetListAction<T, SET> & EP;
  updateItem: (
    item: T,
    optionalNewItem?: T,
    ...extra: EA
  ) => UpdateItemAction<T, UPDATE> & EP;
  upsertItem: (
    item: T,
    optionalNewItem?: T,
    ...extra: EA
  ) => UpsertItemAction<T, UPSERT> & EP;
  upsertItems: (
    items: T[],
    ...extra: EA
  ) => UpsertItemsAction<T, UPSERT_ITEMS> & EP;
  appendItem: (
    items: T | T[],
    ...extra: EA
  ) => AppendItemAction<T, APPEND> & EP;
  removeItem: (
    items: T | T[],
    ...extra: EA
  ) => RemoveItemAction<T, REMOVE> & EP;
}

/**
 * Create a set of action helpers that take extra parameters that will be
 * inserted into the actions.
 *
 * @param actionTypes A object mapping well-known SET/UPDATE/etc to action types
 * @param makeExtraProps A function that takes the extra arguments and returns
 *   the extra parameters
 */
export function makeListActionCreatorsWithExtra<
  T, // the type of items being stored in the list
  EA extends any[], // the extra arguments passed to the action creators
  EP, // the extra parameters passed along in the action
  SET extends string = string,
  UPDATE extends string = string,
  UPSERT extends string = string,
  UPSERT_ITEMS extends string = string,
  APPEND extends string = string,
  REMOVE extends string = string,
>(
  actionTypes: ListActionTypes<
    SET,
    UPDATE,
    UPSERT,
    UPSERT_ITEMS,
    APPEND,
    REMOVE
  >,
  makeExtraProps: (...extraArg: EA) => EP,
): ListActionCreatorsExtra<
  T,
  EA,
  EP,
  SET,
  UPDATE,
  UPSERT,
  UPSERT_ITEMS,
  APPEND,
  REMOVE
> {
  return {
    setList: (list: T[], ...extra: EA): SetListAction<T, SET> & EP => {
      return {
        type: actionTypes.SET,
        list,
        ...(makeExtraProps ? makeExtraProps(...extra) : undefined),
      };
    },
    updateItem: (
      item: T,
      optionalNewItem?: T,
      ...extra: EA
    ): UpdateItemAction<T, UPDATE> & EP => {
      const newItem = optionalNewItem && { newItem: optionalNewItem };
      return {
        type: actionTypes.UPDATE,
        item,
        ...newItem,
        ...(makeExtraProps ? makeExtraProps(...extra) : undefined),
      };
    },
    upsertItem: (
      item: T,
      optionalNewItem?: T,
      ...extra: EA
    ): UpsertItemAction<T, UPSERT> & EP => {
      const newItem = optionalNewItem && { newItem: optionalNewItem };
      return {
        type: actionTypes.UPSERT,
        item,
        ...newItem,
        ...(makeExtraProps ? makeExtraProps(...extra) : undefined),
      };
    },
    upsertItems: (
      items: T[],
      ...extra: EA
    ): UpsertItemsAction<T, UPSERT_ITEMS> & EP => {
      return {
        type: actionTypes.UPSERT_ITEMS,
        items,
        ...(makeExtraProps ? makeExtraProps(...extra) : undefined),
      };
    },
    appendItem: (
      items: T | T[],
      ...extra: EA
    ): AppendItemAction<T, APPEND> & EP => ({
      type: actionTypes.APPEND,
      items,
      ...(makeExtraProps ? makeExtraProps(...extra) : undefined),
    }),
    removeItem: (items: T | T[], ...extra: EA) => ({
      type: actionTypes.REMOVE,
      items,
      ...(makeExtraProps ? makeExtraProps(...extra) : undefined),
    }),
  };
}
