import { Tooltip } from '@blueprintjs/core';
import { mdiStar, mdiStarOutline } from '@mdi/js';
import Icon from '@mdi/react';
import classNames from 'classnames';
import React, { FC, memo, useCallback, useMemo } from 'react';
import { t } from 'ttag';
import {
  AddLayerToFavoritesApiArg,
  DeleteLayerFromFavoritesApiArg,
  useAddLayerToFavoritesMutation,
  useDeleteLayerFromFavoritesMutation,
} from 'uf-api-rtk/store/Api';
import { makeLayerId } from 'uf/layers';
import { logEvent } from 'uf/logging';
import tw from 'uf/tailwindcss-classnames';
import { useFavoriteLayers } from 'uf/ui/layers/hooks';
import { useCurrentUser } from 'uf/ui/user/hooks';

export interface Props {
  className?: string;
  layerKey: string;
  layerNamespace: string;
  layerType: string;
}

export const events = {
  LAYER_FAVORITED: 'uf.layers.favorited',
  LAYER_UNFAVORITED: 'uf.layers.unfavorited',
};

// icon size appears to be between medium & small
const ICON_SIZE = 1.1;

export function useToggleFavoriteLayer(
  layerKey: string,
  layerNamespace: string,
  layerType: string,
) {
  const fullPath = makeLayerId(layerNamespace, layerType, layerKey);
  const user = useCurrentUser();
  const userKey = user?.key;
  const { favoriteLayers, refetch } = useFavoriteLayers();
  const entry = favoriteLayers.find(e => e.full_path === fullPath);
  const active = !!entry;
  const options: AddLayerToFavoritesApiArg | DeleteLayerFromFavoritesApiArg =
    useMemo(
      () => ({
        userKey,
        layerKey,
        layerNamespace,
        layerType,
      }),
      [userKey, layerKey, layerNamespace, layerType],
    );
  const [turnOn, { isLoading: turnOnStatus }] =
    useAddLayerToFavoritesMutation();
  const [turnOff, { isLoading: turnOffStatus }] =
    useDeleteLayerFromFavoritesMutation();

  const toggleValue = useCallback(async () => {
    if (active) {
      logEvent(events.LAYER_UNFAVORITED, {
        layerKey,
        layerNamespace,
        layerType,
      });
      await turnOff(options);
    } else {
      logEvent(events.LAYER_FAVORITED, { layerKey, layerNamespace, layerType });
      await turnOn(options);
    }
    await refetch();
  }, [
    active,
    turnOff,
    turnOn,
    refetch,
    layerKey,
    layerNamespace,
    layerType,
    options,
  ]);
  // TODO(jasonkraus): remove cast with future version of typescript
  return [active, toggleValue, turnOnStatus, turnOffStatus] as [
    boolean,
    () => void,
    any,
    any,
  ];
}

export const FavoriteLayerStar: FC<Props> = ({
  layerKey,
  layerNamespace,
  layerType,
  className,
}) => {
  const [active, toggleValue] = useToggleFavoriteLayer(
    layerKey,
    layerNamespace,
    layerType,
  );
  const onClick = useCallback(
    e => {
      e.preventDefault();
      toggleValue();
    },
    [toggleValue],
  );
  const tooltip = active ? t`Remove from favorites` : t`Add to favorites`;
  return (
    <Tooltip content={tooltip} placement="top">
      <button
        onClick={onClick}
        onKeyPress={onClick}
        role="checkbox"
        aria-checked={active}
        tabIndex={-9}
        className={classNames(
          tw('tw-inline', 'hover:tw-bg-gray-300'),
          className,
        )}>
        <Icon
          size={ICON_SIZE}
          className={tw('tw-inline', 'tw-m-1', 'tw-text-blueGray-600')}
          path={active ? mdiStar : mdiStarOutline}
        />
      </button>
    </Tooltip>
  );
};

export default memo(FavoriteLayerStar);
