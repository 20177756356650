import colors from 'material-colors';

import {
  CuratedColumnSymbology,
  CUSTOM_THEME,
  DEFAULT_OPACITY,
  InterpolationTypes,
  PaintColorTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const seaRiseLayerList = [
  'usgs_cosmos_0_25m_v2',
  'usgs_cosmos_0_5m_v2',
  'usgs_cosmos_0_75m_v2',
  'usgs_cosmos_0m_v2',
  'usgs_cosmos_1_25m_v2',
  'usgs_cosmos_1_5m_v2',
  'usgs_cosmos_1_75m_v2',
  'usgs_cosmos_1m_v2',
  'usgs_cosmos_2m_v2',
  'usgs_cosmos_5m_v2',
];

const symbologies: Record<
  string,
  Record<string, CuratedColumnSymbology[]>
> = {};
seaRiseLayerList.forEach(layerName => {
  symbologies[`/public/databank/${layerName}`] = {
    depth: [
      {
        type: SymbologyTypes.FILL,
        paint: {
          'fill-color': {
            property: 'depth',
            type: PaintColorTypes.CATEGORICAL,
            stops: [
              { value: '0 - 0.5m', color: colors.blue[100] },
              { value: '0.5 - 1.0m', color: colors.blue[200] },
              { value: '1.0 - 1.5m', color: colors.blue[300] },
              { value: '1.5 - 2.0m', color: colors.blue[400] },
              { value: '2.0 - 2.5m', color: colors.blue[500] },
              { value: '2.5 - 3.0m', color: colors.blue[600] },
              { value: '3.0 - 3.5m', color: colors.blue[700] },
              { value: '3.5 - 4.0m', color: colors.blue[800] },
              { value: '4.0m +', color: colors.blue[900] },
            ],
          },
          'fill-opacity': DEFAULT_OPACITY,
        },
        display_hints: {
          scale: SymbologyScales.CATEGORICAL,
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          theme: CUSTOM_THEME,
        },
      },
      {
        type: SymbologyTypes.LINE,
        paint: {
          'line-color': {
            property: 'depth',
            type: PaintColorTypes.CATEGORICAL,
            stops: [
              { value: '0 - 0.5m', color: colors.blue[100] },
              { value: '0.5 - 1.0m', color: colors.blue[200] },
              { value: '1.0 - 1.5m', color: colors.blue[300] },
              { value: '1.5 - 2.0m', color: colors.blue[400] },
              { value: '2.0 - 2.5m', color: colors.blue[500] },
              { value: '2.5 - 3.0m', color: colors.blue[600] },
              { value: '3.0 - 3.5m', color: colors.blue[700] },
              { value: '3.5 - 4.0m', color: colors.blue[800] },
              { value: '4.0m +', color: colors.blue[900] },
            ],
          },
          'line-opacity': DEFAULT_OPACITY,
        },
        display_hints: {
          scale: SymbologyScales.CATEGORICAL,
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          theme: CUSTOM_THEME,
        },
      },
    ],
  };
});

export default symbologies;
