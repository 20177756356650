import colors from 'material-colors';

import {
  CuratedColumnSymbology,
  CUSTOM_THEME,
  DEFAULT_OPACITY,
  InterpolationTypes,
  PaintColorTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/public/dataset/national_commercial_building_energy_use_rate': {
    climate_zone_name: [
      {
        type: SymbologyTypes.FILL,
        paint: {
          'fill-color': {
            property: 'climate_zone_name',
            type: PaintColorTypes.CATEGORICAL,
            stops: [
              {
                value: 'cold/very-cold',
                label: 'Cold to Very Cold',
                color: colors.lightBlue[100],
              },
              {
                value: 'hot-dry/mixed-dry/hot-humid',
                label: 'Hot-Dry, Mixed-Dry or Hot-Humid',
                color: colors.orange[200],
              },
              { value: 'marine', label: 'Marine', color: colors.green[400] },
              {
                value: 'mixed-humid',
                label: 'Mixed-Humid',
                color: colors.red[400],
              },
              { value: 'N/A', label: 'N/A', color: colors.purple[200] },
            ],
          },
          'fill-opacity': DEFAULT_OPACITY,
        },
        display_hints: {
          scale: SymbologyScales.CATEGORICAL,
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          theme: CUSTOM_THEME,
        },
      },
    ],
  },
};

export default symbologies;
