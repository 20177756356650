/* eslint-disable */
/* tslint:disable */
import classnamesLib from 'classnames';

export type TScreenReaders = 'tw-sr-only' | 'tw-not-sr-only';

export type TAccessibility = TScreenReaders;

export type TBackgroundAttachment =
  | 'tw-bg-fixed'
  | 'tw-bg-local'
  | 'tw-bg-scroll';

export type TBackgroundClip =
  | 'tw-bg-clip-border'
  | 'tw-bg-clip-padding'
  | 'tw-bg-clip-content'
  | 'tw-bg-clip-text';

export type TBackgroundColor =
  | 'tw-bg-primary'
  | 'tw-bg-muted'
  | 'tw-bg-warning'
  | 'tw-bg-danger'
  | 'tw-bg-fail'
  | 'tw-bg-info'
  | 'tw-bg-red-50'
  | 'tw-bg-red-100'
  | 'tw-bg-red-200'
  | 'tw-bg-red-300'
  | 'tw-bg-red-400'
  | 'tw-bg-red-500'
  | 'tw-bg-red-600'
  | 'tw-bg-red-700'
  | 'tw-bg-red-800'
  | 'tw-bg-red-900'
  | 'tw-bg-red-a100'
  | 'tw-bg-red-a200'
  | 'tw-bg-red-a400'
  | 'tw-bg-red-a700'
  | 'tw-bg-pink-50'
  | 'tw-bg-pink-100'
  | 'tw-bg-pink-200'
  | 'tw-bg-pink-300'
  | 'tw-bg-pink-400'
  | 'tw-bg-pink-500'
  | 'tw-bg-pink-600'
  | 'tw-bg-pink-700'
  | 'tw-bg-pink-800'
  | 'tw-bg-pink-900'
  | 'tw-bg-pink-a100'
  | 'tw-bg-pink-a200'
  | 'tw-bg-pink-a400'
  | 'tw-bg-pink-a700'
  | 'tw-bg-purple-50'
  | 'tw-bg-purple-100'
  | 'tw-bg-purple-200'
  | 'tw-bg-purple-300'
  | 'tw-bg-purple-400'
  | 'tw-bg-purple-500'
  | 'tw-bg-purple-600'
  | 'tw-bg-purple-700'
  | 'tw-bg-purple-800'
  | 'tw-bg-purple-900'
  | 'tw-bg-purple-a100'
  | 'tw-bg-purple-a200'
  | 'tw-bg-purple-a400'
  | 'tw-bg-purple-a700'
  | 'tw-bg-deepPurple-50'
  | 'tw-bg-deepPurple-100'
  | 'tw-bg-deepPurple-200'
  | 'tw-bg-deepPurple-300'
  | 'tw-bg-deepPurple-400'
  | 'tw-bg-deepPurple-500'
  | 'tw-bg-deepPurple-600'
  | 'tw-bg-deepPurple-700'
  | 'tw-bg-deepPurple-800'
  | 'tw-bg-deepPurple-900'
  | 'tw-bg-deepPurple-a100'
  | 'tw-bg-deepPurple-a200'
  | 'tw-bg-deepPurple-a400'
  | 'tw-bg-deepPurple-a700'
  | 'tw-bg-indigo-50'
  | 'tw-bg-indigo-100'
  | 'tw-bg-indigo-200'
  | 'tw-bg-indigo-300'
  | 'tw-bg-indigo-400'
  | 'tw-bg-indigo-500'
  | 'tw-bg-indigo-600'
  | 'tw-bg-indigo-700'
  | 'tw-bg-indigo-800'
  | 'tw-bg-indigo-900'
  | 'tw-bg-indigo-a100'
  | 'tw-bg-indigo-a200'
  | 'tw-bg-indigo-a400'
  | 'tw-bg-indigo-a700'
  | 'tw-bg-blue-50'
  | 'tw-bg-blue-100'
  | 'tw-bg-blue-200'
  | 'tw-bg-blue-300'
  | 'tw-bg-blue-400'
  | 'tw-bg-blue-500'
  | 'tw-bg-blue-600'
  | 'tw-bg-blue-700'
  | 'tw-bg-blue-800'
  | 'tw-bg-blue-900'
  | 'tw-bg-blue-a100'
  | 'tw-bg-blue-a200'
  | 'tw-bg-blue-a400'
  | 'tw-bg-blue-a700'
  | 'tw-bg-lightBlue-50'
  | 'tw-bg-lightBlue-100'
  | 'tw-bg-lightBlue-200'
  | 'tw-bg-lightBlue-300'
  | 'tw-bg-lightBlue-400'
  | 'tw-bg-lightBlue-500'
  | 'tw-bg-lightBlue-600'
  | 'tw-bg-lightBlue-700'
  | 'tw-bg-lightBlue-800'
  | 'tw-bg-lightBlue-900'
  | 'tw-bg-lightBlue-a100'
  | 'tw-bg-lightBlue-a200'
  | 'tw-bg-lightBlue-a400'
  | 'tw-bg-lightBlue-a700'
  | 'tw-bg-cyan-50'
  | 'tw-bg-cyan-100'
  | 'tw-bg-cyan-200'
  | 'tw-bg-cyan-300'
  | 'tw-bg-cyan-400'
  | 'tw-bg-cyan-500'
  | 'tw-bg-cyan-600'
  | 'tw-bg-cyan-700'
  | 'tw-bg-cyan-800'
  | 'tw-bg-cyan-900'
  | 'tw-bg-cyan-a100'
  | 'tw-bg-cyan-a200'
  | 'tw-bg-cyan-a400'
  | 'tw-bg-cyan-a700'
  | 'tw-bg-teal-50'
  | 'tw-bg-teal-100'
  | 'tw-bg-teal-200'
  | 'tw-bg-teal-300'
  | 'tw-bg-teal-400'
  | 'tw-bg-teal-500'
  | 'tw-bg-teal-600'
  | 'tw-bg-teal-700'
  | 'tw-bg-teal-800'
  | 'tw-bg-teal-900'
  | 'tw-bg-teal-a100'
  | 'tw-bg-teal-a200'
  | 'tw-bg-teal-a400'
  | 'tw-bg-teal-a700'
  | 'tw-bg-green-50'
  | 'tw-bg-green-100'
  | 'tw-bg-green-200'
  | 'tw-bg-green-300'
  | 'tw-bg-green-400'
  | 'tw-bg-green-500'
  | 'tw-bg-green-600'
  | 'tw-bg-green-700'
  | 'tw-bg-green-800'
  | 'tw-bg-green-900'
  | 'tw-bg-green-a100'
  | 'tw-bg-green-a200'
  | 'tw-bg-green-a400'
  | 'tw-bg-green-a700'
  | 'tw-bg-lightGreen-50'
  | 'tw-bg-lightGreen-100'
  | 'tw-bg-lightGreen-200'
  | 'tw-bg-lightGreen-300'
  | 'tw-bg-lightGreen-400'
  | 'tw-bg-lightGreen-500'
  | 'tw-bg-lightGreen-600'
  | 'tw-bg-lightGreen-700'
  | 'tw-bg-lightGreen-800'
  | 'tw-bg-lightGreen-900'
  | 'tw-bg-lightGreen-a100'
  | 'tw-bg-lightGreen-a200'
  | 'tw-bg-lightGreen-a400'
  | 'tw-bg-lightGreen-a700'
  | 'tw-bg-lime-50'
  | 'tw-bg-lime-100'
  | 'tw-bg-lime-200'
  | 'tw-bg-lime-300'
  | 'tw-bg-lime-400'
  | 'tw-bg-lime-500'
  | 'tw-bg-lime-600'
  | 'tw-bg-lime-700'
  | 'tw-bg-lime-800'
  | 'tw-bg-lime-900'
  | 'tw-bg-lime-a100'
  | 'tw-bg-lime-a200'
  | 'tw-bg-lime-a400'
  | 'tw-bg-lime-a700'
  | 'tw-bg-yellow-50'
  | 'tw-bg-yellow-100'
  | 'tw-bg-yellow-200'
  | 'tw-bg-yellow-300'
  | 'tw-bg-yellow-400'
  | 'tw-bg-yellow-500'
  | 'tw-bg-yellow-600'
  | 'tw-bg-yellow-700'
  | 'tw-bg-yellow-800'
  | 'tw-bg-yellow-900'
  | 'tw-bg-yellow-a100'
  | 'tw-bg-yellow-a200'
  | 'tw-bg-yellow-a400'
  | 'tw-bg-yellow-a700'
  | 'tw-bg-amber-50'
  | 'tw-bg-amber-100'
  | 'tw-bg-amber-200'
  | 'tw-bg-amber-300'
  | 'tw-bg-amber-400'
  | 'tw-bg-amber-500'
  | 'tw-bg-amber-600'
  | 'tw-bg-amber-700'
  | 'tw-bg-amber-800'
  | 'tw-bg-amber-900'
  | 'tw-bg-amber-a100'
  | 'tw-bg-amber-a200'
  | 'tw-bg-amber-a400'
  | 'tw-bg-amber-a700'
  | 'tw-bg-orange-50'
  | 'tw-bg-orange-100'
  | 'tw-bg-orange-200'
  | 'tw-bg-orange-300'
  | 'tw-bg-orange-400'
  | 'tw-bg-orange-500'
  | 'tw-bg-orange-600'
  | 'tw-bg-orange-700'
  | 'tw-bg-orange-800'
  | 'tw-bg-orange-900'
  | 'tw-bg-orange-a100'
  | 'tw-bg-orange-a200'
  | 'tw-bg-orange-a400'
  | 'tw-bg-orange-a700'
  | 'tw-bg-deepOrange-50'
  | 'tw-bg-deepOrange-100'
  | 'tw-bg-deepOrange-200'
  | 'tw-bg-deepOrange-300'
  | 'tw-bg-deepOrange-400'
  | 'tw-bg-deepOrange-500'
  | 'tw-bg-deepOrange-600'
  | 'tw-bg-deepOrange-700'
  | 'tw-bg-deepOrange-800'
  | 'tw-bg-deepOrange-900'
  | 'tw-bg-deepOrange-a100'
  | 'tw-bg-deepOrange-a200'
  | 'tw-bg-deepOrange-a400'
  | 'tw-bg-deepOrange-a700'
  | 'tw-bg-brown-50'
  | 'tw-bg-brown-100'
  | 'tw-bg-brown-200'
  | 'tw-bg-brown-300'
  | 'tw-bg-brown-400'
  | 'tw-bg-brown-500'
  | 'tw-bg-brown-600'
  | 'tw-bg-brown-700'
  | 'tw-bg-brown-800'
  | 'tw-bg-brown-900'
  | 'tw-bg-gray-50'
  | 'tw-bg-gray-100'
  | 'tw-bg-gray-200'
  | 'tw-bg-gray-300'
  | 'tw-bg-gray-400'
  | 'tw-bg-gray-500'
  | 'tw-bg-gray-600'
  | 'tw-bg-gray-700'
  | 'tw-bg-gray-800'
  | 'tw-bg-gray-900'
  | 'tw-bg-blueGray-50'
  | 'tw-bg-blueGray-100'
  | 'tw-bg-blueGray-200'
  | 'tw-bg-blueGray-300'
  | 'tw-bg-blueGray-400'
  | 'tw-bg-blueGray-500'
  | 'tw-bg-blueGray-600'
  | 'tw-bg-blueGray-700'
  | 'tw-bg-blueGray-800'
  | 'tw-bg-blueGray-900'
  | 'tw-bg-black'
  | 'tw-bg-white'
  | 'tw-bg-transparent';

export type TBackgroundOpacity =
  | 'tw-bg-opacity-0'
  | 'tw-bg-opacity-25'
  | 'tw-bg-opacity-50'
  | 'tw-bg-opacity-75'
  | 'tw-bg-opacity-100';

export type TBackgroundPosition =
  | 'tw-bg-bottom'
  | 'tw-bg-center'
  | 'tw-bg-left'
  | 'tw-bg-left-bottom'
  | 'tw-bg-left-top'
  | 'tw-bg-right'
  | 'tw-bg-right-bottom'
  | 'tw-bg-right-top'
  | 'tw-bg-top';

export type TBackgroundRepeat =
  | 'tw-bg-repeat'
  | 'tw-bg-no-repeat'
  | 'tw-bg-repeat-x'
  | 'tw-bg-repeat-y'
  | 'tw-bg-repeat-round'
  | 'tw-bg-repeat-space';

export type TBackgroundSize = 'tw-bg-auto' | 'tw-bg-cover' | 'tw-bg-contain';

export type TBackgroundImage =
  | 'tw-bg-none'
  | 'tw-bg-gradient-to-t'
  | 'tw-bg-gradient-to-tr'
  | 'tw-bg-gradient-to-r'
  | 'tw-bg-gradient-to-br'
  | 'tw-bg-gradient-to-b'
  | 'tw-bg-gradient-to-bl'
  | 'tw-bg-gradient-to-l'
  | 'tw-bg-gradient-to-tl';

export type TGradientColorStops =
  | 'tw-from-primary'
  | 'tw-via-primary'
  | 'tw-to-primary'
  | 'tw-from-muted'
  | 'tw-via-muted'
  | 'tw-to-muted'
  | 'tw-from-warning'
  | 'tw-via-warning'
  | 'tw-to-warning'
  | 'tw-from-danger'
  | 'tw-via-danger'
  | 'tw-to-danger'
  | 'tw-from-fail'
  | 'tw-via-fail'
  | 'tw-to-fail'
  | 'tw-from-info'
  | 'tw-via-info'
  | 'tw-to-info'
  | 'tw-from-red-50'
  | 'tw-via-red-50'
  | 'tw-to-red-50'
  | 'tw-from-red-100'
  | 'tw-via-red-100'
  | 'tw-to-red-100'
  | 'tw-from-red-200'
  | 'tw-via-red-200'
  | 'tw-to-red-200'
  | 'tw-from-red-300'
  | 'tw-via-red-300'
  | 'tw-to-red-300'
  | 'tw-from-red-400'
  | 'tw-via-red-400'
  | 'tw-to-red-400'
  | 'tw-from-red-500'
  | 'tw-via-red-500'
  | 'tw-to-red-500'
  | 'tw-from-red-600'
  | 'tw-via-red-600'
  | 'tw-to-red-600'
  | 'tw-from-red-700'
  | 'tw-via-red-700'
  | 'tw-to-red-700'
  | 'tw-from-red-800'
  | 'tw-via-red-800'
  | 'tw-to-red-800'
  | 'tw-from-red-900'
  | 'tw-via-red-900'
  | 'tw-to-red-900'
  | 'tw-from-red-a100'
  | 'tw-via-red-a100'
  | 'tw-to-red-a100'
  | 'tw-from-red-a200'
  | 'tw-via-red-a200'
  | 'tw-to-red-a200'
  | 'tw-from-red-a400'
  | 'tw-via-red-a400'
  | 'tw-to-red-a400'
  | 'tw-from-red-a700'
  | 'tw-via-red-a700'
  | 'tw-to-red-a700'
  | 'tw-from-pink-50'
  | 'tw-via-pink-50'
  | 'tw-to-pink-50'
  | 'tw-from-pink-100'
  | 'tw-via-pink-100'
  | 'tw-to-pink-100'
  | 'tw-from-pink-200'
  | 'tw-via-pink-200'
  | 'tw-to-pink-200'
  | 'tw-from-pink-300'
  | 'tw-via-pink-300'
  | 'tw-to-pink-300'
  | 'tw-from-pink-400'
  | 'tw-via-pink-400'
  | 'tw-to-pink-400'
  | 'tw-from-pink-500'
  | 'tw-via-pink-500'
  | 'tw-to-pink-500'
  | 'tw-from-pink-600'
  | 'tw-via-pink-600'
  | 'tw-to-pink-600'
  | 'tw-from-pink-700'
  | 'tw-via-pink-700'
  | 'tw-to-pink-700'
  | 'tw-from-pink-800'
  | 'tw-via-pink-800'
  | 'tw-to-pink-800'
  | 'tw-from-pink-900'
  | 'tw-via-pink-900'
  | 'tw-to-pink-900'
  | 'tw-from-pink-a100'
  | 'tw-via-pink-a100'
  | 'tw-to-pink-a100'
  | 'tw-from-pink-a200'
  | 'tw-via-pink-a200'
  | 'tw-to-pink-a200'
  | 'tw-from-pink-a400'
  | 'tw-via-pink-a400'
  | 'tw-to-pink-a400'
  | 'tw-from-pink-a700'
  | 'tw-via-pink-a700'
  | 'tw-to-pink-a700'
  | 'tw-from-purple-50'
  | 'tw-via-purple-50'
  | 'tw-to-purple-50'
  | 'tw-from-purple-100'
  | 'tw-via-purple-100'
  | 'tw-to-purple-100'
  | 'tw-from-purple-200'
  | 'tw-via-purple-200'
  | 'tw-to-purple-200'
  | 'tw-from-purple-300'
  | 'tw-via-purple-300'
  | 'tw-to-purple-300'
  | 'tw-from-purple-400'
  | 'tw-via-purple-400'
  | 'tw-to-purple-400'
  | 'tw-from-purple-500'
  | 'tw-via-purple-500'
  | 'tw-to-purple-500'
  | 'tw-from-purple-600'
  | 'tw-via-purple-600'
  | 'tw-to-purple-600'
  | 'tw-from-purple-700'
  | 'tw-via-purple-700'
  | 'tw-to-purple-700'
  | 'tw-from-purple-800'
  | 'tw-via-purple-800'
  | 'tw-to-purple-800'
  | 'tw-from-purple-900'
  | 'tw-via-purple-900'
  | 'tw-to-purple-900'
  | 'tw-from-purple-a100'
  | 'tw-via-purple-a100'
  | 'tw-to-purple-a100'
  | 'tw-from-purple-a200'
  | 'tw-via-purple-a200'
  | 'tw-to-purple-a200'
  | 'tw-from-purple-a400'
  | 'tw-via-purple-a400'
  | 'tw-to-purple-a400'
  | 'tw-from-purple-a700'
  | 'tw-via-purple-a700'
  | 'tw-to-purple-a700'
  | 'tw-from-deepPurple-50'
  | 'tw-via-deepPurple-50'
  | 'tw-to-deepPurple-50'
  | 'tw-from-deepPurple-100'
  | 'tw-via-deepPurple-100'
  | 'tw-to-deepPurple-100'
  | 'tw-from-deepPurple-200'
  | 'tw-via-deepPurple-200'
  | 'tw-to-deepPurple-200'
  | 'tw-from-deepPurple-300'
  | 'tw-via-deepPurple-300'
  | 'tw-to-deepPurple-300'
  | 'tw-from-deepPurple-400'
  | 'tw-via-deepPurple-400'
  | 'tw-to-deepPurple-400'
  | 'tw-from-deepPurple-500'
  | 'tw-via-deepPurple-500'
  | 'tw-to-deepPurple-500'
  | 'tw-from-deepPurple-600'
  | 'tw-via-deepPurple-600'
  | 'tw-to-deepPurple-600'
  | 'tw-from-deepPurple-700'
  | 'tw-via-deepPurple-700'
  | 'tw-to-deepPurple-700'
  | 'tw-from-deepPurple-800'
  | 'tw-via-deepPurple-800'
  | 'tw-to-deepPurple-800'
  | 'tw-from-deepPurple-900'
  | 'tw-via-deepPurple-900'
  | 'tw-to-deepPurple-900'
  | 'tw-from-deepPurple-a100'
  | 'tw-via-deepPurple-a100'
  | 'tw-to-deepPurple-a100'
  | 'tw-from-deepPurple-a200'
  | 'tw-via-deepPurple-a200'
  | 'tw-to-deepPurple-a200'
  | 'tw-from-deepPurple-a400'
  | 'tw-via-deepPurple-a400'
  | 'tw-to-deepPurple-a400'
  | 'tw-from-deepPurple-a700'
  | 'tw-via-deepPurple-a700'
  | 'tw-to-deepPurple-a700'
  | 'tw-from-indigo-50'
  | 'tw-via-indigo-50'
  | 'tw-to-indigo-50'
  | 'tw-from-indigo-100'
  | 'tw-via-indigo-100'
  | 'tw-to-indigo-100'
  | 'tw-from-indigo-200'
  | 'tw-via-indigo-200'
  | 'tw-to-indigo-200'
  | 'tw-from-indigo-300'
  | 'tw-via-indigo-300'
  | 'tw-to-indigo-300'
  | 'tw-from-indigo-400'
  | 'tw-via-indigo-400'
  | 'tw-to-indigo-400'
  | 'tw-from-indigo-500'
  | 'tw-via-indigo-500'
  | 'tw-to-indigo-500'
  | 'tw-from-indigo-600'
  | 'tw-via-indigo-600'
  | 'tw-to-indigo-600'
  | 'tw-from-indigo-700'
  | 'tw-via-indigo-700'
  | 'tw-to-indigo-700'
  | 'tw-from-indigo-800'
  | 'tw-via-indigo-800'
  | 'tw-to-indigo-800'
  | 'tw-from-indigo-900'
  | 'tw-via-indigo-900'
  | 'tw-to-indigo-900'
  | 'tw-from-indigo-a100'
  | 'tw-via-indigo-a100'
  | 'tw-to-indigo-a100'
  | 'tw-from-indigo-a200'
  | 'tw-via-indigo-a200'
  | 'tw-to-indigo-a200'
  | 'tw-from-indigo-a400'
  | 'tw-via-indigo-a400'
  | 'tw-to-indigo-a400'
  | 'tw-from-indigo-a700'
  | 'tw-via-indigo-a700'
  | 'tw-to-indigo-a700'
  | 'tw-from-blue-50'
  | 'tw-via-blue-50'
  | 'tw-to-blue-50'
  | 'tw-from-blue-100'
  | 'tw-via-blue-100'
  | 'tw-to-blue-100'
  | 'tw-from-blue-200'
  | 'tw-via-blue-200'
  | 'tw-to-blue-200'
  | 'tw-from-blue-300'
  | 'tw-via-blue-300'
  | 'tw-to-blue-300'
  | 'tw-from-blue-400'
  | 'tw-via-blue-400'
  | 'tw-to-blue-400'
  | 'tw-from-blue-500'
  | 'tw-via-blue-500'
  | 'tw-to-blue-500'
  | 'tw-from-blue-600'
  | 'tw-via-blue-600'
  | 'tw-to-blue-600'
  | 'tw-from-blue-700'
  | 'tw-via-blue-700'
  | 'tw-to-blue-700'
  | 'tw-from-blue-800'
  | 'tw-via-blue-800'
  | 'tw-to-blue-800'
  | 'tw-from-blue-900'
  | 'tw-via-blue-900'
  | 'tw-to-blue-900'
  | 'tw-from-blue-a100'
  | 'tw-via-blue-a100'
  | 'tw-to-blue-a100'
  | 'tw-from-blue-a200'
  | 'tw-via-blue-a200'
  | 'tw-to-blue-a200'
  | 'tw-from-blue-a400'
  | 'tw-via-blue-a400'
  | 'tw-to-blue-a400'
  | 'tw-from-blue-a700'
  | 'tw-via-blue-a700'
  | 'tw-to-blue-a700'
  | 'tw-from-lightBlue-50'
  | 'tw-via-lightBlue-50'
  | 'tw-to-lightBlue-50'
  | 'tw-from-lightBlue-100'
  | 'tw-via-lightBlue-100'
  | 'tw-to-lightBlue-100'
  | 'tw-from-lightBlue-200'
  | 'tw-via-lightBlue-200'
  | 'tw-to-lightBlue-200'
  | 'tw-from-lightBlue-300'
  | 'tw-via-lightBlue-300'
  | 'tw-to-lightBlue-300'
  | 'tw-from-lightBlue-400'
  | 'tw-via-lightBlue-400'
  | 'tw-to-lightBlue-400'
  | 'tw-from-lightBlue-500'
  | 'tw-via-lightBlue-500'
  | 'tw-to-lightBlue-500'
  | 'tw-from-lightBlue-600'
  | 'tw-via-lightBlue-600'
  | 'tw-to-lightBlue-600'
  | 'tw-from-lightBlue-700'
  | 'tw-via-lightBlue-700'
  | 'tw-to-lightBlue-700'
  | 'tw-from-lightBlue-800'
  | 'tw-via-lightBlue-800'
  | 'tw-to-lightBlue-800'
  | 'tw-from-lightBlue-900'
  | 'tw-via-lightBlue-900'
  | 'tw-to-lightBlue-900'
  | 'tw-from-lightBlue-a100'
  | 'tw-via-lightBlue-a100'
  | 'tw-to-lightBlue-a100'
  | 'tw-from-lightBlue-a200'
  | 'tw-via-lightBlue-a200'
  | 'tw-to-lightBlue-a200'
  | 'tw-from-lightBlue-a400'
  | 'tw-via-lightBlue-a400'
  | 'tw-to-lightBlue-a400'
  | 'tw-from-lightBlue-a700'
  | 'tw-via-lightBlue-a700'
  | 'tw-to-lightBlue-a700'
  | 'tw-from-cyan-50'
  | 'tw-via-cyan-50'
  | 'tw-to-cyan-50'
  | 'tw-from-cyan-100'
  | 'tw-via-cyan-100'
  | 'tw-to-cyan-100'
  | 'tw-from-cyan-200'
  | 'tw-via-cyan-200'
  | 'tw-to-cyan-200'
  | 'tw-from-cyan-300'
  | 'tw-via-cyan-300'
  | 'tw-to-cyan-300'
  | 'tw-from-cyan-400'
  | 'tw-via-cyan-400'
  | 'tw-to-cyan-400'
  | 'tw-from-cyan-500'
  | 'tw-via-cyan-500'
  | 'tw-to-cyan-500'
  | 'tw-from-cyan-600'
  | 'tw-via-cyan-600'
  | 'tw-to-cyan-600'
  | 'tw-from-cyan-700'
  | 'tw-via-cyan-700'
  | 'tw-to-cyan-700'
  | 'tw-from-cyan-800'
  | 'tw-via-cyan-800'
  | 'tw-to-cyan-800'
  | 'tw-from-cyan-900'
  | 'tw-via-cyan-900'
  | 'tw-to-cyan-900'
  | 'tw-from-cyan-a100'
  | 'tw-via-cyan-a100'
  | 'tw-to-cyan-a100'
  | 'tw-from-cyan-a200'
  | 'tw-via-cyan-a200'
  | 'tw-to-cyan-a200'
  | 'tw-from-cyan-a400'
  | 'tw-via-cyan-a400'
  | 'tw-to-cyan-a400'
  | 'tw-from-cyan-a700'
  | 'tw-via-cyan-a700'
  | 'tw-to-cyan-a700'
  | 'tw-from-teal-50'
  | 'tw-via-teal-50'
  | 'tw-to-teal-50'
  | 'tw-from-teal-100'
  | 'tw-via-teal-100'
  | 'tw-to-teal-100'
  | 'tw-from-teal-200'
  | 'tw-via-teal-200'
  | 'tw-to-teal-200'
  | 'tw-from-teal-300'
  | 'tw-via-teal-300'
  | 'tw-to-teal-300'
  | 'tw-from-teal-400'
  | 'tw-via-teal-400'
  | 'tw-to-teal-400'
  | 'tw-from-teal-500'
  | 'tw-via-teal-500'
  | 'tw-to-teal-500'
  | 'tw-from-teal-600'
  | 'tw-via-teal-600'
  | 'tw-to-teal-600'
  | 'tw-from-teal-700'
  | 'tw-via-teal-700'
  | 'tw-to-teal-700'
  | 'tw-from-teal-800'
  | 'tw-via-teal-800'
  | 'tw-to-teal-800'
  | 'tw-from-teal-900'
  | 'tw-via-teal-900'
  | 'tw-to-teal-900'
  | 'tw-from-teal-a100'
  | 'tw-via-teal-a100'
  | 'tw-to-teal-a100'
  | 'tw-from-teal-a200'
  | 'tw-via-teal-a200'
  | 'tw-to-teal-a200'
  | 'tw-from-teal-a400'
  | 'tw-via-teal-a400'
  | 'tw-to-teal-a400'
  | 'tw-from-teal-a700'
  | 'tw-via-teal-a700'
  | 'tw-to-teal-a700'
  | 'tw-from-green-50'
  | 'tw-via-green-50'
  | 'tw-to-green-50'
  | 'tw-from-green-100'
  | 'tw-via-green-100'
  | 'tw-to-green-100'
  | 'tw-from-green-200'
  | 'tw-via-green-200'
  | 'tw-to-green-200'
  | 'tw-from-green-300'
  | 'tw-via-green-300'
  | 'tw-to-green-300'
  | 'tw-from-green-400'
  | 'tw-via-green-400'
  | 'tw-to-green-400'
  | 'tw-from-green-500'
  | 'tw-via-green-500'
  | 'tw-to-green-500'
  | 'tw-from-green-600'
  | 'tw-via-green-600'
  | 'tw-to-green-600'
  | 'tw-from-green-700'
  | 'tw-via-green-700'
  | 'tw-to-green-700'
  | 'tw-from-green-800'
  | 'tw-via-green-800'
  | 'tw-to-green-800'
  | 'tw-from-green-900'
  | 'tw-via-green-900'
  | 'tw-to-green-900'
  | 'tw-from-green-a100'
  | 'tw-via-green-a100'
  | 'tw-to-green-a100'
  | 'tw-from-green-a200'
  | 'tw-via-green-a200'
  | 'tw-to-green-a200'
  | 'tw-from-green-a400'
  | 'tw-via-green-a400'
  | 'tw-to-green-a400'
  | 'tw-from-green-a700'
  | 'tw-via-green-a700'
  | 'tw-to-green-a700'
  | 'tw-from-lightGreen-50'
  | 'tw-via-lightGreen-50'
  | 'tw-to-lightGreen-50'
  | 'tw-from-lightGreen-100'
  | 'tw-via-lightGreen-100'
  | 'tw-to-lightGreen-100'
  | 'tw-from-lightGreen-200'
  | 'tw-via-lightGreen-200'
  | 'tw-to-lightGreen-200'
  | 'tw-from-lightGreen-300'
  | 'tw-via-lightGreen-300'
  | 'tw-to-lightGreen-300'
  | 'tw-from-lightGreen-400'
  | 'tw-via-lightGreen-400'
  | 'tw-to-lightGreen-400'
  | 'tw-from-lightGreen-500'
  | 'tw-via-lightGreen-500'
  | 'tw-to-lightGreen-500'
  | 'tw-from-lightGreen-600'
  | 'tw-via-lightGreen-600'
  | 'tw-to-lightGreen-600'
  | 'tw-from-lightGreen-700'
  | 'tw-via-lightGreen-700'
  | 'tw-to-lightGreen-700'
  | 'tw-from-lightGreen-800'
  | 'tw-via-lightGreen-800'
  | 'tw-to-lightGreen-800'
  | 'tw-from-lightGreen-900'
  | 'tw-via-lightGreen-900'
  | 'tw-to-lightGreen-900'
  | 'tw-from-lightGreen-a100'
  | 'tw-via-lightGreen-a100'
  | 'tw-to-lightGreen-a100'
  | 'tw-from-lightGreen-a200'
  | 'tw-via-lightGreen-a200'
  | 'tw-to-lightGreen-a200'
  | 'tw-from-lightGreen-a400'
  | 'tw-via-lightGreen-a400'
  | 'tw-to-lightGreen-a400'
  | 'tw-from-lightGreen-a700'
  | 'tw-via-lightGreen-a700'
  | 'tw-to-lightGreen-a700'
  | 'tw-from-lime-50'
  | 'tw-via-lime-50'
  | 'tw-to-lime-50'
  | 'tw-from-lime-100'
  | 'tw-via-lime-100'
  | 'tw-to-lime-100'
  | 'tw-from-lime-200'
  | 'tw-via-lime-200'
  | 'tw-to-lime-200'
  | 'tw-from-lime-300'
  | 'tw-via-lime-300'
  | 'tw-to-lime-300'
  | 'tw-from-lime-400'
  | 'tw-via-lime-400'
  | 'tw-to-lime-400'
  | 'tw-from-lime-500'
  | 'tw-via-lime-500'
  | 'tw-to-lime-500'
  | 'tw-from-lime-600'
  | 'tw-via-lime-600'
  | 'tw-to-lime-600'
  | 'tw-from-lime-700'
  | 'tw-via-lime-700'
  | 'tw-to-lime-700'
  | 'tw-from-lime-800'
  | 'tw-via-lime-800'
  | 'tw-to-lime-800'
  | 'tw-from-lime-900'
  | 'tw-via-lime-900'
  | 'tw-to-lime-900'
  | 'tw-from-lime-a100'
  | 'tw-via-lime-a100'
  | 'tw-to-lime-a100'
  | 'tw-from-lime-a200'
  | 'tw-via-lime-a200'
  | 'tw-to-lime-a200'
  | 'tw-from-lime-a400'
  | 'tw-via-lime-a400'
  | 'tw-to-lime-a400'
  | 'tw-from-lime-a700'
  | 'tw-via-lime-a700'
  | 'tw-to-lime-a700'
  | 'tw-from-yellow-50'
  | 'tw-via-yellow-50'
  | 'tw-to-yellow-50'
  | 'tw-from-yellow-100'
  | 'tw-via-yellow-100'
  | 'tw-to-yellow-100'
  | 'tw-from-yellow-200'
  | 'tw-via-yellow-200'
  | 'tw-to-yellow-200'
  | 'tw-from-yellow-300'
  | 'tw-via-yellow-300'
  | 'tw-to-yellow-300'
  | 'tw-from-yellow-400'
  | 'tw-via-yellow-400'
  | 'tw-to-yellow-400'
  | 'tw-from-yellow-500'
  | 'tw-via-yellow-500'
  | 'tw-to-yellow-500'
  | 'tw-from-yellow-600'
  | 'tw-via-yellow-600'
  | 'tw-to-yellow-600'
  | 'tw-from-yellow-700'
  | 'tw-via-yellow-700'
  | 'tw-to-yellow-700'
  | 'tw-from-yellow-800'
  | 'tw-via-yellow-800'
  | 'tw-to-yellow-800'
  | 'tw-from-yellow-900'
  | 'tw-via-yellow-900'
  | 'tw-to-yellow-900'
  | 'tw-from-yellow-a100'
  | 'tw-via-yellow-a100'
  | 'tw-to-yellow-a100'
  | 'tw-from-yellow-a200'
  | 'tw-via-yellow-a200'
  | 'tw-to-yellow-a200'
  | 'tw-from-yellow-a400'
  | 'tw-via-yellow-a400'
  | 'tw-to-yellow-a400'
  | 'tw-from-yellow-a700'
  | 'tw-via-yellow-a700'
  | 'tw-to-yellow-a700'
  | 'tw-from-amber-50'
  | 'tw-via-amber-50'
  | 'tw-to-amber-50'
  | 'tw-from-amber-100'
  | 'tw-via-amber-100'
  | 'tw-to-amber-100'
  | 'tw-from-amber-200'
  | 'tw-via-amber-200'
  | 'tw-to-amber-200'
  | 'tw-from-amber-300'
  | 'tw-via-amber-300'
  | 'tw-to-amber-300'
  | 'tw-from-amber-400'
  | 'tw-via-amber-400'
  | 'tw-to-amber-400'
  | 'tw-from-amber-500'
  | 'tw-via-amber-500'
  | 'tw-to-amber-500'
  | 'tw-from-amber-600'
  | 'tw-via-amber-600'
  | 'tw-to-amber-600'
  | 'tw-from-amber-700'
  | 'tw-via-amber-700'
  | 'tw-to-amber-700'
  | 'tw-from-amber-800'
  | 'tw-via-amber-800'
  | 'tw-to-amber-800'
  | 'tw-from-amber-900'
  | 'tw-via-amber-900'
  | 'tw-to-amber-900'
  | 'tw-from-amber-a100'
  | 'tw-via-amber-a100'
  | 'tw-to-amber-a100'
  | 'tw-from-amber-a200'
  | 'tw-via-amber-a200'
  | 'tw-to-amber-a200'
  | 'tw-from-amber-a400'
  | 'tw-via-amber-a400'
  | 'tw-to-amber-a400'
  | 'tw-from-amber-a700'
  | 'tw-via-amber-a700'
  | 'tw-to-amber-a700'
  | 'tw-from-orange-50'
  | 'tw-via-orange-50'
  | 'tw-to-orange-50'
  | 'tw-from-orange-100'
  | 'tw-via-orange-100'
  | 'tw-to-orange-100'
  | 'tw-from-orange-200'
  | 'tw-via-orange-200'
  | 'tw-to-orange-200'
  | 'tw-from-orange-300'
  | 'tw-via-orange-300'
  | 'tw-to-orange-300'
  | 'tw-from-orange-400'
  | 'tw-via-orange-400'
  | 'tw-to-orange-400'
  | 'tw-from-orange-500'
  | 'tw-via-orange-500'
  | 'tw-to-orange-500'
  | 'tw-from-orange-600'
  | 'tw-via-orange-600'
  | 'tw-to-orange-600'
  | 'tw-from-orange-700'
  | 'tw-via-orange-700'
  | 'tw-to-orange-700'
  | 'tw-from-orange-800'
  | 'tw-via-orange-800'
  | 'tw-to-orange-800'
  | 'tw-from-orange-900'
  | 'tw-via-orange-900'
  | 'tw-to-orange-900'
  | 'tw-from-orange-a100'
  | 'tw-via-orange-a100'
  | 'tw-to-orange-a100'
  | 'tw-from-orange-a200'
  | 'tw-via-orange-a200'
  | 'tw-to-orange-a200'
  | 'tw-from-orange-a400'
  | 'tw-via-orange-a400'
  | 'tw-to-orange-a400'
  | 'tw-from-orange-a700'
  | 'tw-via-orange-a700'
  | 'tw-to-orange-a700'
  | 'tw-from-deepOrange-50'
  | 'tw-via-deepOrange-50'
  | 'tw-to-deepOrange-50'
  | 'tw-from-deepOrange-100'
  | 'tw-via-deepOrange-100'
  | 'tw-to-deepOrange-100'
  | 'tw-from-deepOrange-200'
  | 'tw-via-deepOrange-200'
  | 'tw-to-deepOrange-200'
  | 'tw-from-deepOrange-300'
  | 'tw-via-deepOrange-300'
  | 'tw-to-deepOrange-300'
  | 'tw-from-deepOrange-400'
  | 'tw-via-deepOrange-400'
  | 'tw-to-deepOrange-400'
  | 'tw-from-deepOrange-500'
  | 'tw-via-deepOrange-500'
  | 'tw-to-deepOrange-500'
  | 'tw-from-deepOrange-600'
  | 'tw-via-deepOrange-600'
  | 'tw-to-deepOrange-600'
  | 'tw-from-deepOrange-700'
  | 'tw-via-deepOrange-700'
  | 'tw-to-deepOrange-700'
  | 'tw-from-deepOrange-800'
  | 'tw-via-deepOrange-800'
  | 'tw-to-deepOrange-800'
  | 'tw-from-deepOrange-900'
  | 'tw-via-deepOrange-900'
  | 'tw-to-deepOrange-900'
  | 'tw-from-deepOrange-a100'
  | 'tw-via-deepOrange-a100'
  | 'tw-to-deepOrange-a100'
  | 'tw-from-deepOrange-a200'
  | 'tw-via-deepOrange-a200'
  | 'tw-to-deepOrange-a200'
  | 'tw-from-deepOrange-a400'
  | 'tw-via-deepOrange-a400'
  | 'tw-to-deepOrange-a400'
  | 'tw-from-deepOrange-a700'
  | 'tw-via-deepOrange-a700'
  | 'tw-to-deepOrange-a700'
  | 'tw-from-brown-50'
  | 'tw-via-brown-50'
  | 'tw-to-brown-50'
  | 'tw-from-brown-100'
  | 'tw-via-brown-100'
  | 'tw-to-brown-100'
  | 'tw-from-brown-200'
  | 'tw-via-brown-200'
  | 'tw-to-brown-200'
  | 'tw-from-brown-300'
  | 'tw-via-brown-300'
  | 'tw-to-brown-300'
  | 'tw-from-brown-400'
  | 'tw-via-brown-400'
  | 'tw-to-brown-400'
  | 'tw-from-brown-500'
  | 'tw-via-brown-500'
  | 'tw-to-brown-500'
  | 'tw-from-brown-600'
  | 'tw-via-brown-600'
  | 'tw-to-brown-600'
  | 'tw-from-brown-700'
  | 'tw-via-brown-700'
  | 'tw-to-brown-700'
  | 'tw-from-brown-800'
  | 'tw-via-brown-800'
  | 'tw-to-brown-800'
  | 'tw-from-brown-900'
  | 'tw-via-brown-900'
  | 'tw-to-brown-900'
  | 'tw-from-gray-50'
  | 'tw-via-gray-50'
  | 'tw-to-gray-50'
  | 'tw-from-gray-100'
  | 'tw-via-gray-100'
  | 'tw-to-gray-100'
  | 'tw-from-gray-200'
  | 'tw-via-gray-200'
  | 'tw-to-gray-200'
  | 'tw-from-gray-300'
  | 'tw-via-gray-300'
  | 'tw-to-gray-300'
  | 'tw-from-gray-400'
  | 'tw-via-gray-400'
  | 'tw-to-gray-400'
  | 'tw-from-gray-500'
  | 'tw-via-gray-500'
  | 'tw-to-gray-500'
  | 'tw-from-gray-600'
  | 'tw-via-gray-600'
  | 'tw-to-gray-600'
  | 'tw-from-gray-700'
  | 'tw-via-gray-700'
  | 'tw-to-gray-700'
  | 'tw-from-gray-800'
  | 'tw-via-gray-800'
  | 'tw-to-gray-800'
  | 'tw-from-gray-900'
  | 'tw-via-gray-900'
  | 'tw-to-gray-900'
  | 'tw-from-blueGray-50'
  | 'tw-via-blueGray-50'
  | 'tw-to-blueGray-50'
  | 'tw-from-blueGray-100'
  | 'tw-via-blueGray-100'
  | 'tw-to-blueGray-100'
  | 'tw-from-blueGray-200'
  | 'tw-via-blueGray-200'
  | 'tw-to-blueGray-200'
  | 'tw-from-blueGray-300'
  | 'tw-via-blueGray-300'
  | 'tw-to-blueGray-300'
  | 'tw-from-blueGray-400'
  | 'tw-via-blueGray-400'
  | 'tw-to-blueGray-400'
  | 'tw-from-blueGray-500'
  | 'tw-via-blueGray-500'
  | 'tw-to-blueGray-500'
  | 'tw-from-blueGray-600'
  | 'tw-via-blueGray-600'
  | 'tw-to-blueGray-600'
  | 'tw-from-blueGray-700'
  | 'tw-via-blueGray-700'
  | 'tw-to-blueGray-700'
  | 'tw-from-blueGray-800'
  | 'tw-via-blueGray-800'
  | 'tw-to-blueGray-800'
  | 'tw-from-blueGray-900'
  | 'tw-via-blueGray-900'
  | 'tw-to-blueGray-900'
  | 'tw-from-black'
  | 'tw-via-black'
  | 'tw-to-black'
  | 'tw-from-white'
  | 'tw-via-white'
  | 'tw-to-white'
  | 'tw-from-transparent'
  | 'tw-via-transparent'
  | 'tw-to-transparent';

export type TBackgrounds =
  | TBackgroundAttachment
  | TBackgroundClip
  | TBackgroundColor
  | TBackgroundOpacity
  | TBackgroundPosition
  | TBackgroundRepeat
  | TBackgroundSize
  | TBackgroundImage
  | TGradientColorStops;

export type TBorderColor =
  | 'tw-border-primary'
  | 'tw-border-muted'
  | 'tw-border-warning'
  | 'tw-border-danger'
  | 'tw-border-fail'
  | 'tw-border-info'
  | 'tw-border-red-50'
  | 'tw-border-red-100'
  | 'tw-border-red-200'
  | 'tw-border-red-300'
  | 'tw-border-red-400'
  | 'tw-border-red-500'
  | 'tw-border-red-600'
  | 'tw-border-red-700'
  | 'tw-border-red-800'
  | 'tw-border-red-900'
  | 'tw-border-red-a100'
  | 'tw-border-red-a200'
  | 'tw-border-red-a400'
  | 'tw-border-red-a700'
  | 'tw-border-pink-50'
  | 'tw-border-pink-100'
  | 'tw-border-pink-200'
  | 'tw-border-pink-300'
  | 'tw-border-pink-400'
  | 'tw-border-pink-500'
  | 'tw-border-pink-600'
  | 'tw-border-pink-700'
  | 'tw-border-pink-800'
  | 'tw-border-pink-900'
  | 'tw-border-pink-a100'
  | 'tw-border-pink-a200'
  | 'tw-border-pink-a400'
  | 'tw-border-pink-a700'
  | 'tw-border-purple-50'
  | 'tw-border-purple-100'
  | 'tw-border-purple-200'
  | 'tw-border-purple-300'
  | 'tw-border-purple-400'
  | 'tw-border-purple-500'
  | 'tw-border-purple-600'
  | 'tw-border-purple-700'
  | 'tw-border-purple-800'
  | 'tw-border-purple-900'
  | 'tw-border-purple-a100'
  | 'tw-border-purple-a200'
  | 'tw-border-purple-a400'
  | 'tw-border-purple-a700'
  | 'tw-border-deepPurple-50'
  | 'tw-border-deepPurple-100'
  | 'tw-border-deepPurple-200'
  | 'tw-border-deepPurple-300'
  | 'tw-border-deepPurple-400'
  | 'tw-border-deepPurple-500'
  | 'tw-border-deepPurple-600'
  | 'tw-border-deepPurple-700'
  | 'tw-border-deepPurple-800'
  | 'tw-border-deepPurple-900'
  | 'tw-border-deepPurple-a100'
  | 'tw-border-deepPurple-a200'
  | 'tw-border-deepPurple-a400'
  | 'tw-border-deepPurple-a700'
  | 'tw-border-indigo-50'
  | 'tw-border-indigo-100'
  | 'tw-border-indigo-200'
  | 'tw-border-indigo-300'
  | 'tw-border-indigo-400'
  | 'tw-border-indigo-500'
  | 'tw-border-indigo-600'
  | 'tw-border-indigo-700'
  | 'tw-border-indigo-800'
  | 'tw-border-indigo-900'
  | 'tw-border-indigo-a100'
  | 'tw-border-indigo-a200'
  | 'tw-border-indigo-a400'
  | 'tw-border-indigo-a700'
  | 'tw-border-blue-50'
  | 'tw-border-blue-100'
  | 'tw-border-blue-200'
  | 'tw-border-blue-300'
  | 'tw-border-blue-400'
  | 'tw-border-blue-500'
  | 'tw-border-blue-600'
  | 'tw-border-blue-700'
  | 'tw-border-blue-800'
  | 'tw-border-blue-900'
  | 'tw-border-blue-a100'
  | 'tw-border-blue-a200'
  | 'tw-border-blue-a400'
  | 'tw-border-blue-a700'
  | 'tw-border-lightBlue-50'
  | 'tw-border-lightBlue-100'
  | 'tw-border-lightBlue-200'
  | 'tw-border-lightBlue-300'
  | 'tw-border-lightBlue-400'
  | 'tw-border-lightBlue-500'
  | 'tw-border-lightBlue-600'
  | 'tw-border-lightBlue-700'
  | 'tw-border-lightBlue-800'
  | 'tw-border-lightBlue-900'
  | 'tw-border-lightBlue-a100'
  | 'tw-border-lightBlue-a200'
  | 'tw-border-lightBlue-a400'
  | 'tw-border-lightBlue-a700'
  | 'tw-border-cyan-50'
  | 'tw-border-cyan-100'
  | 'tw-border-cyan-200'
  | 'tw-border-cyan-300'
  | 'tw-border-cyan-400'
  | 'tw-border-cyan-500'
  | 'tw-border-cyan-600'
  | 'tw-border-cyan-700'
  | 'tw-border-cyan-800'
  | 'tw-border-cyan-900'
  | 'tw-border-cyan-a100'
  | 'tw-border-cyan-a200'
  | 'tw-border-cyan-a400'
  | 'tw-border-cyan-a700'
  | 'tw-border-teal-50'
  | 'tw-border-teal-100'
  | 'tw-border-teal-200'
  | 'tw-border-teal-300'
  | 'tw-border-teal-400'
  | 'tw-border-teal-500'
  | 'tw-border-teal-600'
  | 'tw-border-teal-700'
  | 'tw-border-teal-800'
  | 'tw-border-teal-900'
  | 'tw-border-teal-a100'
  | 'tw-border-teal-a200'
  | 'tw-border-teal-a400'
  | 'tw-border-teal-a700'
  | 'tw-border-green-50'
  | 'tw-border-green-100'
  | 'tw-border-green-200'
  | 'tw-border-green-300'
  | 'tw-border-green-400'
  | 'tw-border-green-500'
  | 'tw-border-green-600'
  | 'tw-border-green-700'
  | 'tw-border-green-800'
  | 'tw-border-green-900'
  | 'tw-border-green-a100'
  | 'tw-border-green-a200'
  | 'tw-border-green-a400'
  | 'tw-border-green-a700'
  | 'tw-border-lightGreen-50'
  | 'tw-border-lightGreen-100'
  | 'tw-border-lightGreen-200'
  | 'tw-border-lightGreen-300'
  | 'tw-border-lightGreen-400'
  | 'tw-border-lightGreen-500'
  | 'tw-border-lightGreen-600'
  | 'tw-border-lightGreen-700'
  | 'tw-border-lightGreen-800'
  | 'tw-border-lightGreen-900'
  | 'tw-border-lightGreen-a100'
  | 'tw-border-lightGreen-a200'
  | 'tw-border-lightGreen-a400'
  | 'tw-border-lightGreen-a700'
  | 'tw-border-lime-50'
  | 'tw-border-lime-100'
  | 'tw-border-lime-200'
  | 'tw-border-lime-300'
  | 'tw-border-lime-400'
  | 'tw-border-lime-500'
  | 'tw-border-lime-600'
  | 'tw-border-lime-700'
  | 'tw-border-lime-800'
  | 'tw-border-lime-900'
  | 'tw-border-lime-a100'
  | 'tw-border-lime-a200'
  | 'tw-border-lime-a400'
  | 'tw-border-lime-a700'
  | 'tw-border-yellow-50'
  | 'tw-border-yellow-100'
  | 'tw-border-yellow-200'
  | 'tw-border-yellow-300'
  | 'tw-border-yellow-400'
  | 'tw-border-yellow-500'
  | 'tw-border-yellow-600'
  | 'tw-border-yellow-700'
  | 'tw-border-yellow-800'
  | 'tw-border-yellow-900'
  | 'tw-border-yellow-a100'
  | 'tw-border-yellow-a200'
  | 'tw-border-yellow-a400'
  | 'tw-border-yellow-a700'
  | 'tw-border-amber-50'
  | 'tw-border-amber-100'
  | 'tw-border-amber-200'
  | 'tw-border-amber-300'
  | 'tw-border-amber-400'
  | 'tw-border-amber-500'
  | 'tw-border-amber-600'
  | 'tw-border-amber-700'
  | 'tw-border-amber-800'
  | 'tw-border-amber-900'
  | 'tw-border-amber-a100'
  | 'tw-border-amber-a200'
  | 'tw-border-amber-a400'
  | 'tw-border-amber-a700'
  | 'tw-border-orange-50'
  | 'tw-border-orange-100'
  | 'tw-border-orange-200'
  | 'tw-border-orange-300'
  | 'tw-border-orange-400'
  | 'tw-border-orange-500'
  | 'tw-border-orange-600'
  | 'tw-border-orange-700'
  | 'tw-border-orange-800'
  | 'tw-border-orange-900'
  | 'tw-border-orange-a100'
  | 'tw-border-orange-a200'
  | 'tw-border-orange-a400'
  | 'tw-border-orange-a700'
  | 'tw-border-deepOrange-50'
  | 'tw-border-deepOrange-100'
  | 'tw-border-deepOrange-200'
  | 'tw-border-deepOrange-300'
  | 'tw-border-deepOrange-400'
  | 'tw-border-deepOrange-500'
  | 'tw-border-deepOrange-600'
  | 'tw-border-deepOrange-700'
  | 'tw-border-deepOrange-800'
  | 'tw-border-deepOrange-900'
  | 'tw-border-deepOrange-a100'
  | 'tw-border-deepOrange-a200'
  | 'tw-border-deepOrange-a400'
  | 'tw-border-deepOrange-a700'
  | 'tw-border-brown-50'
  | 'tw-border-brown-100'
  | 'tw-border-brown-200'
  | 'tw-border-brown-300'
  | 'tw-border-brown-400'
  | 'tw-border-brown-500'
  | 'tw-border-brown-600'
  | 'tw-border-brown-700'
  | 'tw-border-brown-800'
  | 'tw-border-brown-900'
  | 'tw-border-gray-50'
  | 'tw-border-gray-100'
  | 'tw-border-gray-200'
  | 'tw-border-gray-300'
  | 'tw-border-gray-400'
  | 'tw-border-gray-500'
  | 'tw-border-gray-600'
  | 'tw-border-gray-700'
  | 'tw-border-gray-800'
  | 'tw-border-gray-900'
  | 'tw-border-blueGray-50'
  | 'tw-border-blueGray-100'
  | 'tw-border-blueGray-200'
  | 'tw-border-blueGray-300'
  | 'tw-border-blueGray-400'
  | 'tw-border-blueGray-500'
  | 'tw-border-blueGray-600'
  | 'tw-border-blueGray-700'
  | 'tw-border-blueGray-800'
  | 'tw-border-blueGray-900'
  | 'tw-border-black'
  | 'tw-border-white'
  | 'tw-border-transparent';

export type TBorderOpacity =
  | 'tw-border-opacity-0'
  | 'tw-border-opacity-25'
  | 'tw-border-opacity-50'
  | 'tw-border-opacity-75'
  | 'tw-border-opacity-100';

export type TBorderStyle =
  | 'tw-border-solid'
  | 'tw-border-dashed'
  | 'tw-border-dotted'
  | 'tw-border-double'
  | 'tw-border-none';

export type TBorderWidth =
  | 'tw-border-t-0'
  | 'tw-border-r-0'
  | 'tw-border-b-0'
  | 'tw-border-l-0'
  | 'tw-border-0'
  | 'tw-border-t-2'
  | 'tw-border-r-2'
  | 'tw-border-b-2'
  | 'tw-border-l-2'
  | 'tw-border-2'
  | 'tw-border-t-4'
  | 'tw-border-r-4'
  | 'tw-border-b-4'
  | 'tw-border-l-4'
  | 'tw-border-4'
  | 'tw-border-t-8'
  | 'tw-border-r-8'
  | 'tw-border-b-8'
  | 'tw-border-l-8'
  | 'tw-border-8'
  | 'tw-border-t'
  | 'tw-border-r'
  | 'tw-border-b'
  | 'tw-border-l'
  | 'tw-border';

export type TBorderRadius =
  | 'tw-rounded-t-none'
  | 'tw-rounded-r-none'
  | 'tw-rounded-b-none'
  | 'tw-rounded-l-none'
  | 'tw-rounded-tr-none'
  | 'tw-rounded-tl-none'
  | 'tw-rounded-br-none'
  | 'tw-rounded-bl-none'
  | 'tw-rounded-none'
  | 'tw-rounded-t-sm'
  | 'tw-rounded-r-sm'
  | 'tw-rounded-b-sm'
  | 'tw-rounded-l-sm'
  | 'tw-rounded-tr-sm'
  | 'tw-rounded-tl-sm'
  | 'tw-rounded-br-sm'
  | 'tw-rounded-bl-sm'
  | 'tw-rounded-sm'
  | 'tw-rounded-t'
  | 'tw-rounded-r'
  | 'tw-rounded-b'
  | 'tw-rounded-l'
  | 'tw-rounded-tr'
  | 'tw-rounded-tl'
  | 'tw-rounded-br'
  | 'tw-rounded-bl'
  | 'tw-rounded'
  | 'tw-rounded-t-md'
  | 'tw-rounded-r-md'
  | 'tw-rounded-b-md'
  | 'tw-rounded-l-md'
  | 'tw-rounded-tr-md'
  | 'tw-rounded-tl-md'
  | 'tw-rounded-br-md'
  | 'tw-rounded-bl-md'
  | 'tw-rounded-md'
  | 'tw-rounded-t-lg'
  | 'tw-rounded-r-lg'
  | 'tw-rounded-b-lg'
  | 'tw-rounded-l-lg'
  | 'tw-rounded-tr-lg'
  | 'tw-rounded-tl-lg'
  | 'tw-rounded-br-lg'
  | 'tw-rounded-bl-lg'
  | 'tw-rounded-lg'
  | 'tw-rounded-t-xl'
  | 'tw-rounded-r-xl'
  | 'tw-rounded-b-xl'
  | 'tw-rounded-l-xl'
  | 'tw-rounded-tr-xl'
  | 'tw-rounded-tl-xl'
  | 'tw-rounded-br-xl'
  | 'tw-rounded-bl-xl'
  | 'tw-rounded-xl'
  | 'tw-rounded-t-2xl'
  | 'tw-rounded-r-2xl'
  | 'tw-rounded-b-2xl'
  | 'tw-rounded-l-2xl'
  | 'tw-rounded-tr-2xl'
  | 'tw-rounded-tl-2xl'
  | 'tw-rounded-br-2xl'
  | 'tw-rounded-bl-2xl'
  | 'tw-rounded-2xl'
  | 'tw-rounded-t-3xl'
  | 'tw-rounded-r-3xl'
  | 'tw-rounded-b-3xl'
  | 'tw-rounded-l-3xl'
  | 'tw-rounded-tr-3xl'
  | 'tw-rounded-tl-3xl'
  | 'tw-rounded-br-3xl'
  | 'tw-rounded-bl-3xl'
  | 'tw-rounded-3xl'
  | 'tw-rounded-t-full'
  | 'tw-rounded-r-full'
  | 'tw-rounded-b-full'
  | 'tw-rounded-l-full'
  | 'tw-rounded-tr-full'
  | 'tw-rounded-tl-full'
  | 'tw-rounded-br-full'
  | 'tw-rounded-bl-full'
  | 'tw-rounded-full';

export type TDivideWidth =
  | 'tw-divide-x-0'
  | 'tw-divide-y-0'
  | 'tw-divide-x-2'
  | 'tw-divide-y-2'
  | 'tw-divide-x-4'
  | 'tw-divide-y-4'
  | 'tw-divide-x-8'
  | 'tw-divide-y-8'
  | 'tw-divide-x'
  | 'tw-divide-y'
  | 'tw-divide-x-reverse'
  | 'tw-divide-y-reverse';

export type TDivideOpacity =
  | 'tw-divide-opacity-0'
  | 'tw-divide-opacity-25'
  | 'tw-divide-opacity-50'
  | 'tw-divide-opacity-75'
  | 'tw-divide-opacity-100';

export type TDivideColor =
  | 'tw-divide-primary'
  | 'tw-divide-muted'
  | 'tw-divide-warning'
  | 'tw-divide-danger'
  | 'tw-divide-fail'
  | 'tw-divide-info'
  | 'tw-divide-red-50'
  | 'tw-divide-red-100'
  | 'tw-divide-red-200'
  | 'tw-divide-red-300'
  | 'tw-divide-red-400'
  | 'tw-divide-red-500'
  | 'tw-divide-red-600'
  | 'tw-divide-red-700'
  | 'tw-divide-red-800'
  | 'tw-divide-red-900'
  | 'tw-divide-red-a100'
  | 'tw-divide-red-a200'
  | 'tw-divide-red-a400'
  | 'tw-divide-red-a700'
  | 'tw-divide-pink-50'
  | 'tw-divide-pink-100'
  | 'tw-divide-pink-200'
  | 'tw-divide-pink-300'
  | 'tw-divide-pink-400'
  | 'tw-divide-pink-500'
  | 'tw-divide-pink-600'
  | 'tw-divide-pink-700'
  | 'tw-divide-pink-800'
  | 'tw-divide-pink-900'
  | 'tw-divide-pink-a100'
  | 'tw-divide-pink-a200'
  | 'tw-divide-pink-a400'
  | 'tw-divide-pink-a700'
  | 'tw-divide-purple-50'
  | 'tw-divide-purple-100'
  | 'tw-divide-purple-200'
  | 'tw-divide-purple-300'
  | 'tw-divide-purple-400'
  | 'tw-divide-purple-500'
  | 'tw-divide-purple-600'
  | 'tw-divide-purple-700'
  | 'tw-divide-purple-800'
  | 'tw-divide-purple-900'
  | 'tw-divide-purple-a100'
  | 'tw-divide-purple-a200'
  | 'tw-divide-purple-a400'
  | 'tw-divide-purple-a700'
  | 'tw-divide-deepPurple-50'
  | 'tw-divide-deepPurple-100'
  | 'tw-divide-deepPurple-200'
  | 'tw-divide-deepPurple-300'
  | 'tw-divide-deepPurple-400'
  | 'tw-divide-deepPurple-500'
  | 'tw-divide-deepPurple-600'
  | 'tw-divide-deepPurple-700'
  | 'tw-divide-deepPurple-800'
  | 'tw-divide-deepPurple-900'
  | 'tw-divide-deepPurple-a100'
  | 'tw-divide-deepPurple-a200'
  | 'tw-divide-deepPurple-a400'
  | 'tw-divide-deepPurple-a700'
  | 'tw-divide-indigo-50'
  | 'tw-divide-indigo-100'
  | 'tw-divide-indigo-200'
  | 'tw-divide-indigo-300'
  | 'tw-divide-indigo-400'
  | 'tw-divide-indigo-500'
  | 'tw-divide-indigo-600'
  | 'tw-divide-indigo-700'
  | 'tw-divide-indigo-800'
  | 'tw-divide-indigo-900'
  | 'tw-divide-indigo-a100'
  | 'tw-divide-indigo-a200'
  | 'tw-divide-indigo-a400'
  | 'tw-divide-indigo-a700'
  | 'tw-divide-blue-50'
  | 'tw-divide-blue-100'
  | 'tw-divide-blue-200'
  | 'tw-divide-blue-300'
  | 'tw-divide-blue-400'
  | 'tw-divide-blue-500'
  | 'tw-divide-blue-600'
  | 'tw-divide-blue-700'
  | 'tw-divide-blue-800'
  | 'tw-divide-blue-900'
  | 'tw-divide-blue-a100'
  | 'tw-divide-blue-a200'
  | 'tw-divide-blue-a400'
  | 'tw-divide-blue-a700'
  | 'tw-divide-lightBlue-50'
  | 'tw-divide-lightBlue-100'
  | 'tw-divide-lightBlue-200'
  | 'tw-divide-lightBlue-300'
  | 'tw-divide-lightBlue-400'
  | 'tw-divide-lightBlue-500'
  | 'tw-divide-lightBlue-600'
  | 'tw-divide-lightBlue-700'
  | 'tw-divide-lightBlue-800'
  | 'tw-divide-lightBlue-900'
  | 'tw-divide-lightBlue-a100'
  | 'tw-divide-lightBlue-a200'
  | 'tw-divide-lightBlue-a400'
  | 'tw-divide-lightBlue-a700'
  | 'tw-divide-cyan-50'
  | 'tw-divide-cyan-100'
  | 'tw-divide-cyan-200'
  | 'tw-divide-cyan-300'
  | 'tw-divide-cyan-400'
  | 'tw-divide-cyan-500'
  | 'tw-divide-cyan-600'
  | 'tw-divide-cyan-700'
  | 'tw-divide-cyan-800'
  | 'tw-divide-cyan-900'
  | 'tw-divide-cyan-a100'
  | 'tw-divide-cyan-a200'
  | 'tw-divide-cyan-a400'
  | 'tw-divide-cyan-a700'
  | 'tw-divide-teal-50'
  | 'tw-divide-teal-100'
  | 'tw-divide-teal-200'
  | 'tw-divide-teal-300'
  | 'tw-divide-teal-400'
  | 'tw-divide-teal-500'
  | 'tw-divide-teal-600'
  | 'tw-divide-teal-700'
  | 'tw-divide-teal-800'
  | 'tw-divide-teal-900'
  | 'tw-divide-teal-a100'
  | 'tw-divide-teal-a200'
  | 'tw-divide-teal-a400'
  | 'tw-divide-teal-a700'
  | 'tw-divide-green-50'
  | 'tw-divide-green-100'
  | 'tw-divide-green-200'
  | 'tw-divide-green-300'
  | 'tw-divide-green-400'
  | 'tw-divide-green-500'
  | 'tw-divide-green-600'
  | 'tw-divide-green-700'
  | 'tw-divide-green-800'
  | 'tw-divide-green-900'
  | 'tw-divide-green-a100'
  | 'tw-divide-green-a200'
  | 'tw-divide-green-a400'
  | 'tw-divide-green-a700'
  | 'tw-divide-lightGreen-50'
  | 'tw-divide-lightGreen-100'
  | 'tw-divide-lightGreen-200'
  | 'tw-divide-lightGreen-300'
  | 'tw-divide-lightGreen-400'
  | 'tw-divide-lightGreen-500'
  | 'tw-divide-lightGreen-600'
  | 'tw-divide-lightGreen-700'
  | 'tw-divide-lightGreen-800'
  | 'tw-divide-lightGreen-900'
  | 'tw-divide-lightGreen-a100'
  | 'tw-divide-lightGreen-a200'
  | 'tw-divide-lightGreen-a400'
  | 'tw-divide-lightGreen-a700'
  | 'tw-divide-lime-50'
  | 'tw-divide-lime-100'
  | 'tw-divide-lime-200'
  | 'tw-divide-lime-300'
  | 'tw-divide-lime-400'
  | 'tw-divide-lime-500'
  | 'tw-divide-lime-600'
  | 'tw-divide-lime-700'
  | 'tw-divide-lime-800'
  | 'tw-divide-lime-900'
  | 'tw-divide-lime-a100'
  | 'tw-divide-lime-a200'
  | 'tw-divide-lime-a400'
  | 'tw-divide-lime-a700'
  | 'tw-divide-yellow-50'
  | 'tw-divide-yellow-100'
  | 'tw-divide-yellow-200'
  | 'tw-divide-yellow-300'
  | 'tw-divide-yellow-400'
  | 'tw-divide-yellow-500'
  | 'tw-divide-yellow-600'
  | 'tw-divide-yellow-700'
  | 'tw-divide-yellow-800'
  | 'tw-divide-yellow-900'
  | 'tw-divide-yellow-a100'
  | 'tw-divide-yellow-a200'
  | 'tw-divide-yellow-a400'
  | 'tw-divide-yellow-a700'
  | 'tw-divide-amber-50'
  | 'tw-divide-amber-100'
  | 'tw-divide-amber-200'
  | 'tw-divide-amber-300'
  | 'tw-divide-amber-400'
  | 'tw-divide-amber-500'
  | 'tw-divide-amber-600'
  | 'tw-divide-amber-700'
  | 'tw-divide-amber-800'
  | 'tw-divide-amber-900'
  | 'tw-divide-amber-a100'
  | 'tw-divide-amber-a200'
  | 'tw-divide-amber-a400'
  | 'tw-divide-amber-a700'
  | 'tw-divide-orange-50'
  | 'tw-divide-orange-100'
  | 'tw-divide-orange-200'
  | 'tw-divide-orange-300'
  | 'tw-divide-orange-400'
  | 'tw-divide-orange-500'
  | 'tw-divide-orange-600'
  | 'tw-divide-orange-700'
  | 'tw-divide-orange-800'
  | 'tw-divide-orange-900'
  | 'tw-divide-orange-a100'
  | 'tw-divide-orange-a200'
  | 'tw-divide-orange-a400'
  | 'tw-divide-orange-a700'
  | 'tw-divide-deepOrange-50'
  | 'tw-divide-deepOrange-100'
  | 'tw-divide-deepOrange-200'
  | 'tw-divide-deepOrange-300'
  | 'tw-divide-deepOrange-400'
  | 'tw-divide-deepOrange-500'
  | 'tw-divide-deepOrange-600'
  | 'tw-divide-deepOrange-700'
  | 'tw-divide-deepOrange-800'
  | 'tw-divide-deepOrange-900'
  | 'tw-divide-deepOrange-a100'
  | 'tw-divide-deepOrange-a200'
  | 'tw-divide-deepOrange-a400'
  | 'tw-divide-deepOrange-a700'
  | 'tw-divide-brown-50'
  | 'tw-divide-brown-100'
  | 'tw-divide-brown-200'
  | 'tw-divide-brown-300'
  | 'tw-divide-brown-400'
  | 'tw-divide-brown-500'
  | 'tw-divide-brown-600'
  | 'tw-divide-brown-700'
  | 'tw-divide-brown-800'
  | 'tw-divide-brown-900'
  | 'tw-divide-gray-50'
  | 'tw-divide-gray-100'
  | 'tw-divide-gray-200'
  | 'tw-divide-gray-300'
  | 'tw-divide-gray-400'
  | 'tw-divide-gray-500'
  | 'tw-divide-gray-600'
  | 'tw-divide-gray-700'
  | 'tw-divide-gray-800'
  | 'tw-divide-gray-900'
  | 'tw-divide-blueGray-50'
  | 'tw-divide-blueGray-100'
  | 'tw-divide-blueGray-200'
  | 'tw-divide-blueGray-300'
  | 'tw-divide-blueGray-400'
  | 'tw-divide-blueGray-500'
  | 'tw-divide-blueGray-600'
  | 'tw-divide-blueGray-700'
  | 'tw-divide-blueGray-800'
  | 'tw-divide-blueGray-900'
  | 'tw-divide-black'
  | 'tw-divide-white'
  | 'tw-divide-transparent';

export type TDivideStyle =
  | 'tw-divide-solid'
  | 'tw-divide-dashed'
  | 'tw-divide-dotted'
  | 'tw-divide-double'
  | 'tw-divide-none';

export type TBorders =
  | TBorderColor
  | TBorderOpacity
  | TBorderStyle
  | TBorderWidth
  | TBorderRadius
  | TDivideWidth
  | TDivideOpacity
  | TDivideColor
  | TDivideStyle;

export type TBorderCollapse = 'tw-border-collapse' | 'tw-border-separate';

export type TTableLayout = 'tw-table-auto' | 'tw-table-fixed';

export type TTables = TBorderCollapse | TTableLayout;

export type TBoxShadow =
  | 'tw-shadow-xs'
  | 'tw-shadow-sm'
  | 'tw-shadow'
  | 'tw-shadow-md'
  | 'tw-shadow-lg'
  | 'tw-shadow-xl'
  | 'tw-shadow-2xl'
  | 'tw-shadow-inner'
  | 'tw-shadow-outline'
  | 'tw-shadow-none';

export type TOpacity =
  | 'tw-opacity-0'
  | 'tw-opacity-25'
  | 'tw-opacity-50'
  | 'tw-opacity-75'
  | 'tw-opacity-100';

export type TEffects = TBoxShadow | TOpacity;

export type TTransitionProperty =
  | 'tw-transition-none'
  | 'tw-transition-all'
  | 'tw-transition'
  | 'tw-transition-colors'
  | 'tw-transition-opacity'
  | 'tw-transition-shadow'
  | 'tw-transition-transform';

export type TTransitionDuration =
  | 'tw-duration-75'
  | 'tw-duration-100'
  | 'tw-duration-150'
  | 'tw-duration-200'
  | 'tw-duration-300'
  | 'tw-duration-500'
  | 'tw-duration-700'
  | 'tw-duration-1000';

export type TTransitionTimingFunction =
  | 'tw-ease-linear'
  | 'tw-ease-in'
  | 'tw-ease-out'
  | 'tw-ease-in-out';

export type TTransitionDelay =
  | 'tw-delay-75'
  | 'tw-delay-100'
  | 'tw-delay-150'
  | 'tw-delay-200'
  | 'tw-delay-300'
  | 'tw-delay-500'
  | 'tw-delay-700'
  | 'tw-delay-1000';

export type TAnimation =
  | 'tw-animate-none'
  | 'tw-animate-spin'
  | 'tw-animate-ping'
  | 'tw-animate-pulse'
  | 'tw-animate-bounce';

export type TTransitionsAndAnimations =
  | TTransitionProperty
  | TTransitionDuration
  | TTransitionTimingFunction
  | TTransitionDelay
  | TAnimation;

export type TFlexDirection =
  | 'tw-flex-row'
  | 'tw-flex-row-reverse'
  | 'tw-flex-col'
  | 'tw-flex-col-reverse';

export type TFlexWrap =
  | 'tw-flex-no-wrap'
  | 'tw-flex-wrap'
  | 'tw-flex-wrap-reverse';

export type TAlignItems =
  | 'tw-items-stretch'
  | 'tw-items-start'
  | 'tw-items-center'
  | 'tw-items-end'
  | 'tw-items-baseline';

export type TAlignContent =
  | 'tw-content-start'
  | 'tw-content-center'
  | 'tw-content-end'
  | 'tw-content-between'
  | 'tw-content-around';

export type TAlignSelf =
  | 'tw-self-auto'
  | 'tw-self-start'
  | 'tw-self-center'
  | 'tw-self-end'
  | 'tw-self-stretch';

export type TPlaceContent =
  | 'tw-place-content-start'
  | 'tw-place-content-center'
  | 'tw-place-content-end'
  | 'tw-place-content-between'
  | 'tw-place-content-around'
  | 'tw-place-content-evenly'
  | 'tw-place-content-stretch';

export type TPlaceItems =
  | 'tw-place-items-auto'
  | 'tw-place-items-start'
  | 'tw-place-items-center'
  | 'tw-place-items-end'
  | 'tw-place-items-stretch';

export type TPlaceSelf =
  | 'tw-place-self-auto'
  | 'tw-place-self-start'
  | 'tw-place-self-center'
  | 'tw-place-self-end'
  | 'tw-place-self-stretch';

export type TJustifyContent =
  | 'tw-justify-start'
  | 'tw-justify-center'
  | 'tw-justify-end'
  | 'tw-justify-between'
  | 'tw-justify-around';

export type TJustifyItems =
  | 'tw-justify-items-auto'
  | 'tw-justify-items-start'
  | 'tw-justify-items-center'
  | 'tw-justify-items-end'
  | 'tw-justify-items-stretch';

export type TJustifySelf =
  | 'tw-justify-self-auto'
  | 'tw-justify-self-start'
  | 'tw-justify-self-center'
  | 'tw-justify-self-end'
  | 'tw-justify-self-stretch';

export type TFlex =
  | 'tw-flex-initial'
  | 'tw-flex-1'
  | 'tw-flex-auto'
  | 'tw-flex-none';

export type TFlexGrow = 'tw-flex-grow-0' | 'tw-flex-grow';

export type TFlexShrink = 'tw-flex-shrink-0' | 'tw-flex-shrink';

export type TOrder =
  | 'tw-order-1'
  | 'tw-order-2'
  | 'tw-order-3'
  | 'tw-order-4'
  | 'tw-order-5'
  | 'tw-order-6'
  | 'tw-order-7'
  | 'tw-order-8'
  | 'tw-order-9'
  | 'tw-order-10'
  | 'tw-order-11'
  | 'tw-order-12'
  | 'tw-order-first'
  | 'tw-order-last'
  | 'tw-order-none';

export type TFlexBox =
  | TFlexDirection
  | TFlexWrap
  | TAlignItems
  | TAlignContent
  | TAlignSelf
  | TPlaceContent
  | TPlaceItems
  | TPlaceSelf
  | TJustifyContent
  | TJustifyItems
  | TJustifySelf
  | TFlex
  | TFlexGrow
  | TFlexShrink
  | TOrder;

export type TGridTemplateColumns =
  | 'tw-grid-cols-1'
  | 'tw-grid-cols-2'
  | 'tw-grid-cols-3'
  | 'tw-grid-cols-4'
  | 'tw-grid-cols-5'
  | 'tw-grid-cols-6'
  | 'tw-grid-cols-7'
  | 'tw-grid-cols-8'
  | 'tw-grid-cols-9'
  | 'tw-grid-cols-10'
  | 'tw-grid-cols-11'
  | 'tw-grid-cols-12'
  | 'tw-grid-cols-none';

export type TGridAutoColumns =
  | 'tw-auto-cols-auto'
  | 'tw-auto-cols-min'
  | 'tw-auto-cols-max'
  | 'tw-auto-cols-fr';

export type TGridColumn =
  | 'tw-col-auto'
  | 'tw-col-span-1'
  | 'tw-col-span-2'
  | 'tw-col-span-3'
  | 'tw-col-span-4'
  | 'tw-col-span-5'
  | 'tw-col-span-6'
  | 'tw-col-span-7'
  | 'tw-col-span-8'
  | 'tw-col-span-9'
  | 'tw-col-span-10'
  | 'tw-col-span-11'
  | 'tw-col-span-12'
  | 'tw-col-span-full';

export type TGridColumnStart =
  | 'tw-col-start-1'
  | 'tw-col-start-2'
  | 'tw-col-start-3'
  | 'tw-col-start-4'
  | 'tw-col-start-5'
  | 'tw-col-start-6'
  | 'tw-col-start-7'
  | 'tw-col-start-8'
  | 'tw-col-start-9'
  | 'tw-col-start-10'
  | 'tw-col-start-11'
  | 'tw-col-start-12'
  | 'tw-col-start-13'
  | 'tw-col-start-auto';

export type TGridColumnEnd =
  | 'tw-col-end-1'
  | 'tw-col-end-2'
  | 'tw-col-end-3'
  | 'tw-col-end-4'
  | 'tw-col-end-5'
  | 'tw-col-end-6'
  | 'tw-col-end-7'
  | 'tw-col-end-8'
  | 'tw-col-end-9'
  | 'tw-col-end-10'
  | 'tw-col-end-11'
  | 'tw-col-end-12'
  | 'tw-col-end-13'
  | 'tw-col-end-auto';

export type TGridTemplateRows =
  | 'tw-grid-rows-1'
  | 'tw-grid-rows-2'
  | 'tw-grid-rows-3'
  | 'tw-grid-rows-4'
  | 'tw-grid-rows-5'
  | 'tw-grid-rows-6'
  | 'tw-grid-rows-none';

export type TGridAutoRows =
  | 'tw-auto-rows-auto'
  | 'tw-auto-rows-min'
  | 'tw-auto-rows-max'
  | 'tw-auto-rows-fr';

export type TGridRow =
  | 'tw-row-auto'
  | 'tw-row-span-1'
  | 'tw-row-span-2'
  | 'tw-row-span-3'
  | 'tw-row-span-4'
  | 'tw-row-span-5'
  | 'tw-row-span-6'
  | 'tw-row-span-full';

export type TGridRowStart =
  | 'tw-row-start-1'
  | 'tw-row-start-2'
  | 'tw-row-start-3'
  | 'tw-row-start-4'
  | 'tw-row-start-5'
  | 'tw-row-start-6'
  | 'tw-row-start-7'
  | 'tw-row-start-auto';

export type TGridRowEnd =
  | 'tw-row-end-1'
  | 'tw-row-end-2'
  | 'tw-row-end-3'
  | 'tw-row-end-4'
  | 'tw-row-end-5'
  | 'tw-row-end-6'
  | 'tw-row-end-7'
  | 'tw-row-end-auto';

export type TGap =
  | 'tw-gap-0'
  | 'tw-gap-1'
  | 'tw-gap-2'
  | 'tw-gap-3'
  | 'tw-gap-4'
  | 'tw-gap-5'
  | 'tw-gap-6'
  | 'tw-gap-8'
  | 'tw-gap-10'
  | 'tw-gap-12'
  | 'tw-gap-16'
  | 'tw-gap-20'
  | 'tw-gap-24'
  | 'tw-gap-32'
  | 'tw-gap-40'
  | 'tw-gap-48'
  | 'tw-gap-56'
  | 'tw-gap-64'
  | 'tw-gap-px'
  | 'tw-gap-y-0'
  | 'tw-gap-y-1'
  | 'tw-gap-y-2'
  | 'tw-gap-y-3'
  | 'tw-gap-y-4'
  | 'tw-gap-y-5'
  | 'tw-gap-y-6'
  | 'tw-gap-y-8'
  | 'tw-gap-y-10'
  | 'tw-gap-y-12'
  | 'tw-gap-y-16'
  | 'tw-gap-y-20'
  | 'tw-gap-y-24'
  | 'tw-gap-y-32'
  | 'tw-gap-y-40'
  | 'tw-gap-y-48'
  | 'tw-gap-y-56'
  | 'tw-gap-y-64'
  | 'tw-gap-y-px'
  | 'tw-gap-x-0'
  | 'tw-gap-x-1'
  | 'tw-gap-x-2'
  | 'tw-gap-x-3'
  | 'tw-gap-x-4'
  | 'tw-gap-x-5'
  | 'tw-gap-x-6'
  | 'tw-gap-x-8'
  | 'tw-gap-x-10'
  | 'tw-gap-x-12'
  | 'tw-gap-x-16'
  | 'tw-gap-x-20'
  | 'tw-gap-x-24'
  | 'tw-gap-x-32'
  | 'tw-gap-x-40'
  | 'tw-gap-x-48'
  | 'tw-gap-x-56'
  | 'tw-gap-x-64'
  | 'tw-gap-x-px';

export type TGridAutoFlow =
  | 'tw-grid-flow-row'
  | 'tw-grid-flow-col'
  | 'tw-grid-flow-row-dense'
  | 'tw-grid-flow-col-dense';

export type TGrid =
  | TGridTemplateColumns
  | TGridAutoColumns
  | TGridColumn
  | TGridColumnStart
  | TGridColumnEnd
  | TGridTemplateRows
  | TGridAutoRows
  | TGridRow
  | TGridRowStart
  | TGridRowEnd
  | TGap
  | TGridAutoFlow;

export type TPadding =
  | 'tw-p-0'
  | 'tw-p-1'
  | 'tw-p-2'
  | 'tw-p-3'
  | 'tw-p-4'
  | 'tw-p-5'
  | 'tw-p-6'
  | 'tw-p-8'
  | 'tw-p-10'
  | 'tw-p-12'
  | 'tw-p-16'
  | 'tw-p-20'
  | 'tw-p-24'
  | 'tw-p-32'
  | 'tw-p-40'
  | 'tw-p-48'
  | 'tw-p-56'
  | 'tw-p-64'
  | 'tw-p-px'
  | 'tw-py-0'
  | 'tw-py-1'
  | 'tw-py-2'
  | 'tw-py-3'
  | 'tw-py-4'
  | 'tw-py-5'
  | 'tw-py-6'
  | 'tw-py-8'
  | 'tw-py-10'
  | 'tw-py-12'
  | 'tw-py-16'
  | 'tw-py-20'
  | 'tw-py-24'
  | 'tw-py-32'
  | 'tw-py-40'
  | 'tw-py-48'
  | 'tw-py-56'
  | 'tw-py-64'
  | 'tw-py-px'
  | 'tw-px-0'
  | 'tw-px-1'
  | 'tw-px-2'
  | 'tw-px-3'
  | 'tw-px-4'
  | 'tw-px-5'
  | 'tw-px-6'
  | 'tw-px-8'
  | 'tw-px-10'
  | 'tw-px-12'
  | 'tw-px-16'
  | 'tw-px-20'
  | 'tw-px-24'
  | 'tw-px-32'
  | 'tw-px-40'
  | 'tw-px-48'
  | 'tw-px-56'
  | 'tw-px-64'
  | 'tw-px-px'
  | 'tw-pt-0'
  | 'tw-pt-1'
  | 'tw-pt-2'
  | 'tw-pt-3'
  | 'tw-pt-4'
  | 'tw-pt-5'
  | 'tw-pt-6'
  | 'tw-pt-8'
  | 'tw-pt-10'
  | 'tw-pt-12'
  | 'tw-pt-16'
  | 'tw-pt-20'
  | 'tw-pt-24'
  | 'tw-pt-32'
  | 'tw-pt-40'
  | 'tw-pt-48'
  | 'tw-pt-56'
  | 'tw-pt-64'
  | 'tw-pt-px'
  | 'tw-pr-0'
  | 'tw-pr-1'
  | 'tw-pr-2'
  | 'tw-pr-3'
  | 'tw-pr-4'
  | 'tw-pr-5'
  | 'tw-pr-6'
  | 'tw-pr-8'
  | 'tw-pr-10'
  | 'tw-pr-12'
  | 'tw-pr-16'
  | 'tw-pr-20'
  | 'tw-pr-24'
  | 'tw-pr-32'
  | 'tw-pr-40'
  | 'tw-pr-48'
  | 'tw-pr-56'
  | 'tw-pr-64'
  | 'tw-pr-px'
  | 'tw-pb-0'
  | 'tw-pb-1'
  | 'tw-pb-2'
  | 'tw-pb-3'
  | 'tw-pb-4'
  | 'tw-pb-5'
  | 'tw-pb-6'
  | 'tw-pb-8'
  | 'tw-pb-10'
  | 'tw-pb-12'
  | 'tw-pb-16'
  | 'tw-pb-20'
  | 'tw-pb-24'
  | 'tw-pb-32'
  | 'tw-pb-40'
  | 'tw-pb-48'
  | 'tw-pb-56'
  | 'tw-pb-64'
  | 'tw-pb-px'
  | 'tw-pl-0'
  | 'tw-pl-1'
  | 'tw-pl-2'
  | 'tw-pl-3'
  | 'tw-pl-4'
  | 'tw-pl-5'
  | 'tw-pl-6'
  | 'tw-pl-8'
  | 'tw-pl-10'
  | 'tw-pl-12'
  | 'tw-pl-16'
  | 'tw-pl-20'
  | 'tw-pl-24'
  | 'tw-pl-32'
  | 'tw-pl-40'
  | 'tw-pl-48'
  | 'tw-pl-56'
  | 'tw-pl-64'
  | 'tw-pl-px';

export type TMargin =
  | 'tw-m-0'
  | 'tw-m-1'
  | 'tw-m-2'
  | 'tw-m-3'
  | 'tw-m-4'
  | 'tw-m-5'
  | 'tw-m-6'
  | 'tw-m-8'
  | 'tw-m-10'
  | 'tw-m-12'
  | 'tw-m-16'
  | 'tw-m-20'
  | 'tw-m-24'
  | 'tw-m-32'
  | 'tw-m-40'
  | 'tw-m-48'
  | 'tw-m-56'
  | 'tw-m-64'
  | 'tw-m-auto'
  | 'tw-m-px'
  | 'tw--m-0'
  | 'tw--m-1'
  | 'tw--m-2'
  | 'tw--m-3'
  | 'tw--m-4'
  | 'tw--m-5'
  | 'tw--m-6'
  | 'tw--m-8'
  | 'tw--m-10'
  | 'tw--m-12'
  | 'tw--m-16'
  | 'tw--m-20'
  | 'tw--m-24'
  | 'tw--m-32'
  | 'tw--m-40'
  | 'tw--m-48'
  | 'tw--m-56'
  | 'tw--m-64'
  | 'tw--m-px'
  | 'tw-my-0'
  | 'tw-my-1'
  | 'tw-my-2'
  | 'tw-my-3'
  | 'tw-my-4'
  | 'tw-my-5'
  | 'tw-my-6'
  | 'tw-my-8'
  | 'tw-my-10'
  | 'tw-my-12'
  | 'tw-my-16'
  | 'tw-my-20'
  | 'tw-my-24'
  | 'tw-my-32'
  | 'tw-my-40'
  | 'tw-my-48'
  | 'tw-my-56'
  | 'tw-my-64'
  | 'tw-my-auto'
  | 'tw-my-px'
  | 'tw--my-0'
  | 'tw--my-1'
  | 'tw--my-2'
  | 'tw--my-3'
  | 'tw--my-4'
  | 'tw--my-5'
  | 'tw--my-6'
  | 'tw--my-8'
  | 'tw--my-10'
  | 'tw--my-12'
  | 'tw--my-16'
  | 'tw--my-20'
  | 'tw--my-24'
  | 'tw--my-32'
  | 'tw--my-40'
  | 'tw--my-48'
  | 'tw--my-56'
  | 'tw--my-64'
  | 'tw--my-px'
  | 'tw-mx-0'
  | 'tw-mx-1'
  | 'tw-mx-2'
  | 'tw-mx-3'
  | 'tw-mx-4'
  | 'tw-mx-5'
  | 'tw-mx-6'
  | 'tw-mx-8'
  | 'tw-mx-10'
  | 'tw-mx-12'
  | 'tw-mx-16'
  | 'tw-mx-20'
  | 'tw-mx-24'
  | 'tw-mx-32'
  | 'tw-mx-40'
  | 'tw-mx-48'
  | 'tw-mx-56'
  | 'tw-mx-64'
  | 'tw-mx-auto'
  | 'tw-mx-px'
  | 'tw--mx-0'
  | 'tw--mx-1'
  | 'tw--mx-2'
  | 'tw--mx-3'
  | 'tw--mx-4'
  | 'tw--mx-5'
  | 'tw--mx-6'
  | 'tw--mx-8'
  | 'tw--mx-10'
  | 'tw--mx-12'
  | 'tw--mx-16'
  | 'tw--mx-20'
  | 'tw--mx-24'
  | 'tw--mx-32'
  | 'tw--mx-40'
  | 'tw--mx-48'
  | 'tw--mx-56'
  | 'tw--mx-64'
  | 'tw--mx-px'
  | 'tw-mt-0'
  | 'tw-mt-1'
  | 'tw-mt-2'
  | 'tw-mt-3'
  | 'tw-mt-4'
  | 'tw-mt-5'
  | 'tw-mt-6'
  | 'tw-mt-8'
  | 'tw-mt-10'
  | 'tw-mt-12'
  | 'tw-mt-16'
  | 'tw-mt-20'
  | 'tw-mt-24'
  | 'tw-mt-32'
  | 'tw-mt-40'
  | 'tw-mt-48'
  | 'tw-mt-56'
  | 'tw-mt-64'
  | 'tw-mt-auto'
  | 'tw-mt-px'
  | 'tw--mt-0'
  | 'tw--mt-1'
  | 'tw--mt-2'
  | 'tw--mt-3'
  | 'tw--mt-4'
  | 'tw--mt-5'
  | 'tw--mt-6'
  | 'tw--mt-8'
  | 'tw--mt-10'
  | 'tw--mt-12'
  | 'tw--mt-16'
  | 'tw--mt-20'
  | 'tw--mt-24'
  | 'tw--mt-32'
  | 'tw--mt-40'
  | 'tw--mt-48'
  | 'tw--mt-56'
  | 'tw--mt-64'
  | 'tw--mt-px'
  | 'tw-mr-0'
  | 'tw-mr-1'
  | 'tw-mr-2'
  | 'tw-mr-3'
  | 'tw-mr-4'
  | 'tw-mr-5'
  | 'tw-mr-6'
  | 'tw-mr-8'
  | 'tw-mr-10'
  | 'tw-mr-12'
  | 'tw-mr-16'
  | 'tw-mr-20'
  | 'tw-mr-24'
  | 'tw-mr-32'
  | 'tw-mr-40'
  | 'tw-mr-48'
  | 'tw-mr-56'
  | 'tw-mr-64'
  | 'tw-mr-auto'
  | 'tw-mr-px'
  | 'tw--mr-0'
  | 'tw--mr-1'
  | 'tw--mr-2'
  | 'tw--mr-3'
  | 'tw--mr-4'
  | 'tw--mr-5'
  | 'tw--mr-6'
  | 'tw--mr-8'
  | 'tw--mr-10'
  | 'tw--mr-12'
  | 'tw--mr-16'
  | 'tw--mr-20'
  | 'tw--mr-24'
  | 'tw--mr-32'
  | 'tw--mr-40'
  | 'tw--mr-48'
  | 'tw--mr-56'
  | 'tw--mr-64'
  | 'tw--mr-px'
  | 'tw-mb-0'
  | 'tw-mb-1'
  | 'tw-mb-2'
  | 'tw-mb-3'
  | 'tw-mb-4'
  | 'tw-mb-5'
  | 'tw-mb-6'
  | 'tw-mb-8'
  | 'tw-mb-10'
  | 'tw-mb-12'
  | 'tw-mb-16'
  | 'tw-mb-20'
  | 'tw-mb-24'
  | 'tw-mb-32'
  | 'tw-mb-40'
  | 'tw-mb-48'
  | 'tw-mb-56'
  | 'tw-mb-64'
  | 'tw-mb-auto'
  | 'tw-mb-px'
  | 'tw--mb-0'
  | 'tw--mb-1'
  | 'tw--mb-2'
  | 'tw--mb-3'
  | 'tw--mb-4'
  | 'tw--mb-5'
  | 'tw--mb-6'
  | 'tw--mb-8'
  | 'tw--mb-10'
  | 'tw--mb-12'
  | 'tw--mb-16'
  | 'tw--mb-20'
  | 'tw--mb-24'
  | 'tw--mb-32'
  | 'tw--mb-40'
  | 'tw--mb-48'
  | 'tw--mb-56'
  | 'tw--mb-64'
  | 'tw--mb-px'
  | 'tw-ml-0'
  | 'tw-ml-1'
  | 'tw-ml-2'
  | 'tw-ml-3'
  | 'tw-ml-4'
  | 'tw-ml-5'
  | 'tw-ml-6'
  | 'tw-ml-8'
  | 'tw-ml-10'
  | 'tw-ml-12'
  | 'tw-ml-16'
  | 'tw-ml-20'
  | 'tw-ml-24'
  | 'tw-ml-32'
  | 'tw-ml-40'
  | 'tw-ml-48'
  | 'tw-ml-56'
  | 'tw-ml-64'
  | 'tw-ml-auto'
  | 'tw-ml-px'
  | 'tw--ml-0'
  | 'tw--ml-1'
  | 'tw--ml-2'
  | 'tw--ml-3'
  | 'tw--ml-4'
  | 'tw--ml-5'
  | 'tw--ml-6'
  | 'tw--ml-8'
  | 'tw--ml-10'
  | 'tw--ml-12'
  | 'tw--ml-16'
  | 'tw--ml-20'
  | 'tw--ml-24'
  | 'tw--ml-32'
  | 'tw--ml-40'
  | 'tw--ml-48'
  | 'tw--ml-56'
  | 'tw--ml-64'
  | 'tw--ml-px';

export type TSpace =
  | 'tw-space-x-0'
  | 'tw-space-x-1'
  | 'tw-space-x-2'
  | 'tw-space-x-3'
  | 'tw-space-x-4'
  | 'tw-space-x-5'
  | 'tw-space-x-6'
  | 'tw-space-x-8'
  | 'tw-space-x-10'
  | 'tw-space-x-12'
  | 'tw-space-x-16'
  | 'tw-space-x-20'
  | 'tw-space-x-24'
  | 'tw-space-x-32'
  | 'tw-space-x-40'
  | 'tw-space-x-48'
  | 'tw-space-x-56'
  | 'tw-space-x-64'
  | 'tw-space-x-px'
  | 'tw--space-x-0'
  | 'tw--space-x-1'
  | 'tw--space-x-2'
  | 'tw--space-x-3'
  | 'tw--space-x-4'
  | 'tw--space-x-5'
  | 'tw--space-x-6'
  | 'tw--space-x-8'
  | 'tw--space-x-10'
  | 'tw--space-x-12'
  | 'tw--space-x-16'
  | 'tw--space-x-20'
  | 'tw--space-x-24'
  | 'tw--space-x-32'
  | 'tw--space-x-40'
  | 'tw--space-x-48'
  | 'tw--space-x-56'
  | 'tw--space-x-64'
  | 'tw--space-x-px'
  | 'tw-space-x-reverse'
  | 'tw-space-y-0'
  | 'tw-space-y-1'
  | 'tw-space-y-2'
  | 'tw-space-y-3'
  | 'tw-space-y-4'
  | 'tw-space-y-5'
  | 'tw-space-y-6'
  | 'tw-space-y-8'
  | 'tw-space-y-10'
  | 'tw-space-y-12'
  | 'tw-space-y-16'
  | 'tw-space-y-20'
  | 'tw-space-y-24'
  | 'tw-space-y-32'
  | 'tw-space-y-40'
  | 'tw-space-y-48'
  | 'tw-space-y-56'
  | 'tw-space-y-64'
  | 'tw-space-y-px'
  | 'tw--space-y-0'
  | 'tw--space-y-1'
  | 'tw--space-y-2'
  | 'tw--space-y-3'
  | 'tw--space-y-4'
  | 'tw--space-y-5'
  | 'tw--space-y-6'
  | 'tw--space-y-8'
  | 'tw--space-y-10'
  | 'tw--space-y-12'
  | 'tw--space-y-16'
  | 'tw--space-y-20'
  | 'tw--space-y-24'
  | 'tw--space-y-32'
  | 'tw--space-y-40'
  | 'tw--space-y-48'
  | 'tw--space-y-56'
  | 'tw--space-y-64'
  | 'tw--space-y-px'
  | 'tw-space-y-reverse';

export type TSpacing = TPadding | TMargin | TSpace;

export type TAppearance = 'tw-appearance-none';

export type TCursor =
  | 'tw-cursor-auto'
  | 'tw-cursor-default'
  | 'tw-cursor-pointer'
  | 'tw-cursor-wait'
  | 'tw-cursor-text'
  | 'tw-cursor-move'
  | 'tw-cursor-not-allowed';

export type TOutline =
  | 'tw-outline-none'
  | 'tw-outline-white'
  | 'tw-outline-black';

export type TPointerEvents =
  | 'tw-pointer-events-none'
  | 'tw-pointer-events-auto';

export type TResize =
  | 'tw-resize-none'
  | 'tw-resize'
  | 'tw-resize-y'
  | 'tw-resize-x';

export type TUserSelect =
  | 'tw-select-none'
  | 'tw-select-text'
  | 'tw-select-all'
  | 'tw-select-auto';

export type TInteractivity =
  | TAppearance
  | TCursor
  | TOutline
  | TPointerEvents
  | TResize
  | TUserSelect;

export type TDisplay =
  | 'tw-block'
  | 'tw-inline-block'
  | 'tw-inline'
  | 'tw-flex'
  | 'tw-inline-flex'
  | 'tw-grid'
  | 'tw-inline-grid'
  | 'tw-table'
  | 'tw-table-row'
  | 'tw-table-cell'
  | 'tw-contents'
  | 'tw-hidden';

export type TBoxSizing = 'tw-box-border' | 'tw-box-content';

export type TContainer = 'tw-container';

export type TFloat =
  | 'tw-float-right'
  | 'tw-float-left'
  | 'tw-float-none'
  | 'tw-clearfix';

export type TClear =
  | 'tw-clear-left'
  | 'tw-clear-right'
  | 'tw-clear-both'
  | 'tw-clear-none';

export type TObjectFit =
  | 'tw-object-contain'
  | 'tw-object-cover'
  | 'tw-object-fill'
  | 'tw-object-none'
  | 'tw-object-scale-down';

export type TObjectPosition =
  | 'tw-object-bottom'
  | 'tw-object-center'
  | 'tw-object-left'
  | 'tw-object-left-bottom'
  | 'tw-object-left-top'
  | 'tw-object-right'
  | 'tw-object-right-bottom'
  | 'tw-object-right-top'
  | 'tw-object-top';

export type TOverflow =
  | 'tw-overflow-auto'
  | 'tw-overflow-hidden'
  | 'tw-overflow-visible'
  | 'tw-overflow-scroll'
  | 'tw-overflow-x-auto'
  | 'tw-overflow-y-auto'
  | 'tw-overflow-x-hidden'
  | 'tw-overflow-y-hidden'
  | 'tw-overflow-x-visible'
  | 'tw-overflow-y-visible'
  | 'tw-overflow-x-scroll'
  | 'tw-overflow-y-scroll'
  | 'tw-scrolling-touch'
  | 'tw-scrolling-auto';

export type TOverscrollBehavior =
  | 'tw-overscroll-auto'
  | 'tw-overscroll-contain'
  | 'tw-overscroll-none'
  | 'tw-overscroll-y-auto'
  | 'tw-overscroll-y-contain'
  | 'tw-overscroll-y-none'
  | 'tw-overscroll-x-auto'
  | 'tw-overscroll-x-contain'
  | 'tw-overscroll-x-none';

export type TPosition =
  | 'tw-static'
  | 'tw-fixed'
  | 'tw-absolute'
  | 'tw-relative'
  | 'tw-sticky';

export type TInset =
  | 'tw-inset-0'
  | 'tw-inset-x-0'
  | 'tw-inset-y-0'
  | 'tw-top-0'
  | 'tw-right-0'
  | 'tw-bottom-0'
  | 'tw-left-0'
  | 'tw-inset-auto'
  | 'tw-inset-x-auto'
  | 'tw-inset-y-auto'
  | 'tw-top-auto'
  | 'tw-right-auto'
  | 'tw-bottom-auto'
  | 'tw-left-auto'
  | 'tw--inset-1'
  | 'tw--inset-x-1'
  | 'tw--inset-y-1'
  | 'tw--top-1'
  | 'tw--right-1'
  | 'tw--bottom-1'
  | 'tw--left-1'
  | 'tw--inset-2'
  | 'tw--inset-x-2'
  | 'tw--inset-y-2'
  | 'tw--top-2'
  | 'tw--right-2'
  | 'tw--bottom-2'
  | 'tw--left-2';

export type TVisibility = 'tw-visible' | 'tw-invisible';

export type TZIndex =
  | 'tw-z-0'
  | 'tw-z-10'
  | 'tw-z-20'
  | 'tw-z-30'
  | 'tw-z-40'
  | 'tw-z-50'
  | 'tw-z-auto';

export type TLayout =
  | TDisplay
  | TBoxSizing
  | TContainer
  | TFloat
  | TClear
  | TObjectFit
  | TObjectPosition
  | TOverflow
  | TOverscrollBehavior
  | TPosition
  | TInset
  | TVisibility
  | TZIndex;

export type TWidth =
  | 'tw-w-0'
  | 'tw-w-1'
  | 'tw-w-2'
  | 'tw-w-3'
  | 'tw-w-4'
  | 'tw-w-5'
  | 'tw-w-6'
  | 'tw-w-8'
  | 'tw-w-10'
  | 'tw-w-12'
  | 'tw-w-16'
  | 'tw-w-20'
  | 'tw-w-24'
  | 'tw-w-32'
  | 'tw-w-40'
  | 'tw-w-48'
  | 'tw-w-56'
  | 'tw-w-64'
  | 'tw-w-72'
  | 'tw-w-80'
  | 'tw-w-96'
  | 'tw-w-auto'
  | 'tw-w-px'
  | 'tw-w-1/2'
  | 'tw-w-1/3'
  | 'tw-w-2/3'
  | 'tw-w-1/4'
  | 'tw-w-2/4'
  | 'tw-w-3/4'
  | 'tw-w-1/5'
  | 'tw-w-2/5'
  | 'tw-w-3/5'
  | 'tw-w-4/5'
  | 'tw-w-1/6'
  | 'tw-w-2/6'
  | 'tw-w-3/6'
  | 'tw-w-4/6'
  | 'tw-w-5/6'
  | 'tw-w-1/12'
  | 'tw-w-2/12'
  | 'tw-w-3/12'
  | 'tw-w-4/12'
  | 'tw-w-5/12'
  | 'tw-w-6/12'
  | 'tw-w-7/12'
  | 'tw-w-8/12'
  | 'tw-w-9/12'
  | 'tw-w-10/12'
  | 'tw-w-11/12'
  | 'tw-w-full'
  | 'tw-w-screen';

export type TMinWidth = 'tw-min-w-0' | 'tw-min-w-full';

export type TMaxWidth =
  | 'tw-max-w-none'
  | 'tw-max-w-xs'
  | 'tw-max-w-sm'
  | 'tw-max-w-md'
  | 'tw-max-w-lg'
  | 'tw-max-w-xl'
  | 'tw-max-w-2xl'
  | 'tw-max-w-3xl'
  | 'tw-max-w-4xl'
  | 'tw-max-w-5xl'
  | 'tw-max-w-6xl'
  | 'tw-max-w-full'
  | 'tw-max-w-screen-sm'
  | 'tw-max-w-screen-md'
  | 'tw-max-w-screen-lg'
  | 'tw-max-w-screen-xl';

export type THeight =
  | 'tw-h-0'
  | 'tw-h-1'
  | 'tw-h-2'
  | 'tw-h-3'
  | 'tw-h-4'
  | 'tw-h-5'
  | 'tw-h-6'
  | 'tw-h-8'
  | 'tw-h-10'
  | 'tw-h-12'
  | 'tw-h-16'
  | 'tw-h-20'
  | 'tw-h-24'
  | 'tw-h-32'
  | 'tw-h-40'
  | 'tw-h-48'
  | 'tw-h-56'
  | 'tw-h-64'
  | 'tw-h-72'
  | 'tw-h-80'
  | 'tw-h-96'
  | 'tw-h-auto'
  | 'tw-h-px'
  | 'tw-h-full'
  | 'tw-h-screen';

export type TMinHeight = 'tw-min-h-0' | 'tw-min-h-full' | 'tw-min-h-screen';

export type TMaxHeight = 'tw-max-h-full' | 'tw-max-h-screen';

export type TSizing =
  | TWidth
  | TMinWidth
  | TMaxWidth
  | THeight
  | TMinHeight
  | TMaxHeight;

export type TFill = 'tw-fill-current';

export type TStroke = 'tw-stroke-current';

export type TStrokeWidth = 'tw-stroke-0' | 'tw-stroke-1' | 'tw-stroke-2';

export type TSVG = TFill | TStroke | TStrokeWidth;

export type TScale =
  | 'tw-scale-0'
  | 'tw-scale-50'
  | 'tw-scale-75'
  | 'tw-scale-90'
  | 'tw-scale-95'
  | 'tw-scale-100'
  | 'tw-scale-105'
  | 'tw-scale-110'
  | 'tw-scale-125'
  | 'tw-scale-150'
  | 'tw-scale-x-0'
  | 'tw-scale-x-50'
  | 'tw-scale-x-75'
  | 'tw-scale-x-90'
  | 'tw-scale-x-95'
  | 'tw-scale-x-100'
  | 'tw-scale-x-105'
  | 'tw-scale-x-110'
  | 'tw-scale-x-125'
  | 'tw-scale-x-150'
  | 'tw-scale-y-0'
  | 'tw-scale-y-50'
  | 'tw-scale-y-75'
  | 'tw-scale-y-90'
  | 'tw-scale-y-95'
  | 'tw-scale-y-100'
  | 'tw-scale-y-105'
  | 'tw-scale-y-110'
  | 'tw-scale-y-125'
  | 'tw-scale-y-150';

export type TRotate =
  | 'tw-rotate-0'
  | 'tw-rotate-1'
  | 'tw-rotate-2'
  | 'tw-rotate-3'
  | 'tw-rotate-6'
  | 'tw-rotate-12'
  | 'tw-rotate-45'
  | 'tw-rotate-90'
  | 'tw-rotate-180'
  | 'tw--rotate-180'
  | 'tw--rotate-90'
  | 'tw--rotate-45'
  | 'tw--rotate-12'
  | 'tw--rotate-6'
  | 'tw--rotate-3'
  | 'tw--rotate-2'
  | 'tw--rotate-1';

export type TTranslate =
  | 'tw-translate-x-0'
  | 'tw-translate-x-1'
  | 'tw-translate-x-2'
  | 'tw-translate-x-3'
  | 'tw-translate-x-4'
  | 'tw-translate-x-5'
  | 'tw-translate-x-6'
  | 'tw-translate-x-8'
  | 'tw-translate-x-10'
  | 'tw-translate-x-12'
  | 'tw-translate-x-16'
  | 'tw-translate-x-20'
  | 'tw-translate-x-24'
  | 'tw-translate-x-32'
  | 'tw-translate-x-40'
  | 'tw-translate-x-48'
  | 'tw-translate-x-56'
  | 'tw-translate-x-64'
  | 'tw-translate-x-px'
  | 'tw--translate-x-0'
  | 'tw--translate-x-1'
  | 'tw--translate-x-2'
  | 'tw--translate-x-3'
  | 'tw--translate-x-4'
  | 'tw--translate-x-5'
  | 'tw--translate-x-6'
  | 'tw--translate-x-8'
  | 'tw--translate-x-10'
  | 'tw--translate-x-12'
  | 'tw--translate-x-16'
  | 'tw--translate-x-20'
  | 'tw--translate-x-24'
  | 'tw--translate-x-32'
  | 'tw--translate-x-40'
  | 'tw--translate-x-48'
  | 'tw--translate-x-56'
  | 'tw--translate-x-64'
  | 'tw--translate-x-px'
  | 'tw--translate-x-full'
  | 'tw--translate-x-1/2'
  | 'tw-translate-x-1/2'
  | 'tw-translate-x-full'
  | 'tw-translate-y-0'
  | 'tw-translate-y-1'
  | 'tw-translate-y-2'
  | 'tw-translate-y-3'
  | 'tw-translate-y-4'
  | 'tw-translate-y-5'
  | 'tw-translate-y-6'
  | 'tw-translate-y-8'
  | 'tw-translate-y-10'
  | 'tw-translate-y-12'
  | 'tw-translate-y-16'
  | 'tw-translate-y-20'
  | 'tw-translate-y-24'
  | 'tw-translate-y-32'
  | 'tw-translate-y-40'
  | 'tw-translate-y-48'
  | 'tw-translate-y-56'
  | 'tw-translate-y-64'
  | 'tw-translate-y-px'
  | 'tw--translate-y-0'
  | 'tw--translate-y-1'
  | 'tw--translate-y-2'
  | 'tw--translate-y-3'
  | 'tw--translate-y-4'
  | 'tw--translate-y-5'
  | 'tw--translate-y-6'
  | 'tw--translate-y-8'
  | 'tw--translate-y-10'
  | 'tw--translate-y-12'
  | 'tw--translate-y-16'
  | 'tw--translate-y-20'
  | 'tw--translate-y-24'
  | 'tw--translate-y-32'
  | 'tw--translate-y-40'
  | 'tw--translate-y-48'
  | 'tw--translate-y-56'
  | 'tw--translate-y-64'
  | 'tw--translate-y-px'
  | 'tw--translate-y-full'
  | 'tw--translate-y-1/2'
  | 'tw-translate-y-1/2'
  | 'tw-translate-y-full';

export type TSkew =
  | 'tw-skew-x-0'
  | 'tw-skew-x-1'
  | 'tw-skew-x-2'
  | 'tw-skew-x-3'
  | 'tw-skew-x-6'
  | 'tw-skew-x-12'
  | 'tw--skew-x-12'
  | 'tw--skew-x-6'
  | 'tw--skew-x-3'
  | 'tw--skew-x-2'
  | 'tw--skew-x-1'
  | 'tw-skew-y-0'
  | 'tw-skew-y-1'
  | 'tw-skew-y-2'
  | 'tw-skew-y-3'
  | 'tw-skew-y-6'
  | 'tw-skew-y-12'
  | 'tw--skew-y-12'
  | 'tw--skew-y-6'
  | 'tw--skew-y-3'
  | 'tw--skew-y-2'
  | 'tw--skew-y-1';

export type TTransformOrigin =
  | 'tw-origin-center'
  | 'tw-origin-top'
  | 'tw-origin-top-right'
  | 'tw-origin-right'
  | 'tw-origin-bottom-right'
  | 'tw-origin-bottom'
  | 'tw-origin-bottom-left'
  | 'tw-origin-left'
  | 'tw-origin-top-left';

export type TTransforms =
  | TScale
  | TRotate
  | TTranslate
  | TSkew
  | TTransformOrigin;

export type TFontFamily = 'tw-font-sans' | 'tw-font-mono';

export type TFontSize =
  | 'tw-text-xs'
  | 'tw-text-sm'
  | 'tw-text-base'
  | 'tw-text-lg'
  | 'tw-text-xl'
  | 'tw-text-2xl'
  | 'tw-text-3xl'
  | 'tw-text-4xl'
  | 'tw-text-5xl'
  | 'tw-text-6xl';

export type TFontSmoothing = 'tw-antialiased' | 'tw-subpixel-antialiased';

export type TFontStyle = 'tw-italic' | 'tw-non-italic';

export type TFontWeight =
  | 'tw-font-hairline'
  | 'tw-font-thin'
  | 'tw-font-light'
  | 'tw-font-normal'
  | 'tw-font-medium'
  | 'tw-font-semibold'
  | 'tw-font-bold'
  | 'tw-font-extrabold'
  | 'tw-font-black';

export type TFontVariantNumeric =
  | 'tw-normal-nums'
  | 'tw-ordinal'
  | 'tw-slashed-zero'
  | 'tw-lining-nums'
  | 'tw-oldstyle-nums'
  | 'tw-proportional-nums'
  | 'tw-tabular-nums'
  | 'tw-diagonal-fractions'
  | 'tw-stacked-fractions';

export type TLetterSpacing =
  | 'tw-tracking-tighter'
  | 'tw-tracking-tight'
  | 'tw-tracking-normal'
  | 'tw-tracking-wide'
  | 'tw-tracking-wider'
  | 'tw-tracking-widest';

export type TLineHeight =
  | 'tw-leading-3'
  | 'tw-leading-4'
  | 'tw-leading-5'
  | 'tw-leading-6'
  | 'tw-leading-7'
  | 'tw-leading-8'
  | 'tw-leading-9'
  | 'tw-leading-10'
  | 'tw-leading-none'
  | 'tw-leading-tight'
  | 'tw-leading-snug'
  | 'tw-leading-normal'
  | 'tw-leading-relaxed'
  | 'tw-leading-loose';

export type TListStyleType =
  | 'tw-list-none'
  | 'tw-list-disc'
  | 'tw-list-decimal';

export type TListStylePosition = 'tw-list-inside' | 'tw-list-outside';

export type TPlaceholderColor =
  | 'tw-placeholder-primary'
  | 'tw-placeholder-muted'
  | 'tw-placeholder-warning'
  | 'tw-placeholder-danger'
  | 'tw-placeholder-fail'
  | 'tw-placeholder-info'
  | 'tw-placeholder-red-50'
  | 'tw-placeholder-red-100'
  | 'tw-placeholder-red-200'
  | 'tw-placeholder-red-300'
  | 'tw-placeholder-red-400'
  | 'tw-placeholder-red-500'
  | 'tw-placeholder-red-600'
  | 'tw-placeholder-red-700'
  | 'tw-placeholder-red-800'
  | 'tw-placeholder-red-900'
  | 'tw-placeholder-red-a100'
  | 'tw-placeholder-red-a200'
  | 'tw-placeholder-red-a400'
  | 'tw-placeholder-red-a700'
  | 'tw-placeholder-pink-50'
  | 'tw-placeholder-pink-100'
  | 'tw-placeholder-pink-200'
  | 'tw-placeholder-pink-300'
  | 'tw-placeholder-pink-400'
  | 'tw-placeholder-pink-500'
  | 'tw-placeholder-pink-600'
  | 'tw-placeholder-pink-700'
  | 'tw-placeholder-pink-800'
  | 'tw-placeholder-pink-900'
  | 'tw-placeholder-pink-a100'
  | 'tw-placeholder-pink-a200'
  | 'tw-placeholder-pink-a400'
  | 'tw-placeholder-pink-a700'
  | 'tw-placeholder-purple-50'
  | 'tw-placeholder-purple-100'
  | 'tw-placeholder-purple-200'
  | 'tw-placeholder-purple-300'
  | 'tw-placeholder-purple-400'
  | 'tw-placeholder-purple-500'
  | 'tw-placeholder-purple-600'
  | 'tw-placeholder-purple-700'
  | 'tw-placeholder-purple-800'
  | 'tw-placeholder-purple-900'
  | 'tw-placeholder-purple-a100'
  | 'tw-placeholder-purple-a200'
  | 'tw-placeholder-purple-a400'
  | 'tw-placeholder-purple-a700'
  | 'tw-placeholder-deepPurple-50'
  | 'tw-placeholder-deepPurple-100'
  | 'tw-placeholder-deepPurple-200'
  | 'tw-placeholder-deepPurple-300'
  | 'tw-placeholder-deepPurple-400'
  | 'tw-placeholder-deepPurple-500'
  | 'tw-placeholder-deepPurple-600'
  | 'tw-placeholder-deepPurple-700'
  | 'tw-placeholder-deepPurple-800'
  | 'tw-placeholder-deepPurple-900'
  | 'tw-placeholder-deepPurple-a100'
  | 'tw-placeholder-deepPurple-a200'
  | 'tw-placeholder-deepPurple-a400'
  | 'tw-placeholder-deepPurple-a700'
  | 'tw-placeholder-indigo-50'
  | 'tw-placeholder-indigo-100'
  | 'tw-placeholder-indigo-200'
  | 'tw-placeholder-indigo-300'
  | 'tw-placeholder-indigo-400'
  | 'tw-placeholder-indigo-500'
  | 'tw-placeholder-indigo-600'
  | 'tw-placeholder-indigo-700'
  | 'tw-placeholder-indigo-800'
  | 'tw-placeholder-indigo-900'
  | 'tw-placeholder-indigo-a100'
  | 'tw-placeholder-indigo-a200'
  | 'tw-placeholder-indigo-a400'
  | 'tw-placeholder-indigo-a700'
  | 'tw-placeholder-blue-50'
  | 'tw-placeholder-blue-100'
  | 'tw-placeholder-blue-200'
  | 'tw-placeholder-blue-300'
  | 'tw-placeholder-blue-400'
  | 'tw-placeholder-blue-500'
  | 'tw-placeholder-blue-600'
  | 'tw-placeholder-blue-700'
  | 'tw-placeholder-blue-800'
  | 'tw-placeholder-blue-900'
  | 'tw-placeholder-blue-a100'
  | 'tw-placeholder-blue-a200'
  | 'tw-placeholder-blue-a400'
  | 'tw-placeholder-blue-a700'
  | 'tw-placeholder-lightBlue-50'
  | 'tw-placeholder-lightBlue-100'
  | 'tw-placeholder-lightBlue-200'
  | 'tw-placeholder-lightBlue-300'
  | 'tw-placeholder-lightBlue-400'
  | 'tw-placeholder-lightBlue-500'
  | 'tw-placeholder-lightBlue-600'
  | 'tw-placeholder-lightBlue-700'
  | 'tw-placeholder-lightBlue-800'
  | 'tw-placeholder-lightBlue-900'
  | 'tw-placeholder-lightBlue-a100'
  | 'tw-placeholder-lightBlue-a200'
  | 'tw-placeholder-lightBlue-a400'
  | 'tw-placeholder-lightBlue-a700'
  | 'tw-placeholder-cyan-50'
  | 'tw-placeholder-cyan-100'
  | 'tw-placeholder-cyan-200'
  | 'tw-placeholder-cyan-300'
  | 'tw-placeholder-cyan-400'
  | 'tw-placeholder-cyan-500'
  | 'tw-placeholder-cyan-600'
  | 'tw-placeholder-cyan-700'
  | 'tw-placeholder-cyan-800'
  | 'tw-placeholder-cyan-900'
  | 'tw-placeholder-cyan-a100'
  | 'tw-placeholder-cyan-a200'
  | 'tw-placeholder-cyan-a400'
  | 'tw-placeholder-cyan-a700'
  | 'tw-placeholder-teal-50'
  | 'tw-placeholder-teal-100'
  | 'tw-placeholder-teal-200'
  | 'tw-placeholder-teal-300'
  | 'tw-placeholder-teal-400'
  | 'tw-placeholder-teal-500'
  | 'tw-placeholder-teal-600'
  | 'tw-placeholder-teal-700'
  | 'tw-placeholder-teal-800'
  | 'tw-placeholder-teal-900'
  | 'tw-placeholder-teal-a100'
  | 'tw-placeholder-teal-a200'
  | 'tw-placeholder-teal-a400'
  | 'tw-placeholder-teal-a700'
  | 'tw-placeholder-green-50'
  | 'tw-placeholder-green-100'
  | 'tw-placeholder-green-200'
  | 'tw-placeholder-green-300'
  | 'tw-placeholder-green-400'
  | 'tw-placeholder-green-500'
  | 'tw-placeholder-green-600'
  | 'tw-placeholder-green-700'
  | 'tw-placeholder-green-800'
  | 'tw-placeholder-green-900'
  | 'tw-placeholder-green-a100'
  | 'tw-placeholder-green-a200'
  | 'tw-placeholder-green-a400'
  | 'tw-placeholder-green-a700'
  | 'tw-placeholder-lightGreen-50'
  | 'tw-placeholder-lightGreen-100'
  | 'tw-placeholder-lightGreen-200'
  | 'tw-placeholder-lightGreen-300'
  | 'tw-placeholder-lightGreen-400'
  | 'tw-placeholder-lightGreen-500'
  | 'tw-placeholder-lightGreen-600'
  | 'tw-placeholder-lightGreen-700'
  | 'tw-placeholder-lightGreen-800'
  | 'tw-placeholder-lightGreen-900'
  | 'tw-placeholder-lightGreen-a100'
  | 'tw-placeholder-lightGreen-a200'
  | 'tw-placeholder-lightGreen-a400'
  | 'tw-placeholder-lightGreen-a700'
  | 'tw-placeholder-lime-50'
  | 'tw-placeholder-lime-100'
  | 'tw-placeholder-lime-200'
  | 'tw-placeholder-lime-300'
  | 'tw-placeholder-lime-400'
  | 'tw-placeholder-lime-500'
  | 'tw-placeholder-lime-600'
  | 'tw-placeholder-lime-700'
  | 'tw-placeholder-lime-800'
  | 'tw-placeholder-lime-900'
  | 'tw-placeholder-lime-a100'
  | 'tw-placeholder-lime-a200'
  | 'tw-placeholder-lime-a400'
  | 'tw-placeholder-lime-a700'
  | 'tw-placeholder-yellow-50'
  | 'tw-placeholder-yellow-100'
  | 'tw-placeholder-yellow-200'
  | 'tw-placeholder-yellow-300'
  | 'tw-placeholder-yellow-400'
  | 'tw-placeholder-yellow-500'
  | 'tw-placeholder-yellow-600'
  | 'tw-placeholder-yellow-700'
  | 'tw-placeholder-yellow-800'
  | 'tw-placeholder-yellow-900'
  | 'tw-placeholder-yellow-a100'
  | 'tw-placeholder-yellow-a200'
  | 'tw-placeholder-yellow-a400'
  | 'tw-placeholder-yellow-a700'
  | 'tw-placeholder-amber-50'
  | 'tw-placeholder-amber-100'
  | 'tw-placeholder-amber-200'
  | 'tw-placeholder-amber-300'
  | 'tw-placeholder-amber-400'
  | 'tw-placeholder-amber-500'
  | 'tw-placeholder-amber-600'
  | 'tw-placeholder-amber-700'
  | 'tw-placeholder-amber-800'
  | 'tw-placeholder-amber-900'
  | 'tw-placeholder-amber-a100'
  | 'tw-placeholder-amber-a200'
  | 'tw-placeholder-amber-a400'
  | 'tw-placeholder-amber-a700'
  | 'tw-placeholder-orange-50'
  | 'tw-placeholder-orange-100'
  | 'tw-placeholder-orange-200'
  | 'tw-placeholder-orange-300'
  | 'tw-placeholder-orange-400'
  | 'tw-placeholder-orange-500'
  | 'tw-placeholder-orange-600'
  | 'tw-placeholder-orange-700'
  | 'tw-placeholder-orange-800'
  | 'tw-placeholder-orange-900'
  | 'tw-placeholder-orange-a100'
  | 'tw-placeholder-orange-a200'
  | 'tw-placeholder-orange-a400'
  | 'tw-placeholder-orange-a700'
  | 'tw-placeholder-deepOrange-50'
  | 'tw-placeholder-deepOrange-100'
  | 'tw-placeholder-deepOrange-200'
  | 'tw-placeholder-deepOrange-300'
  | 'tw-placeholder-deepOrange-400'
  | 'tw-placeholder-deepOrange-500'
  | 'tw-placeholder-deepOrange-600'
  | 'tw-placeholder-deepOrange-700'
  | 'tw-placeholder-deepOrange-800'
  | 'tw-placeholder-deepOrange-900'
  | 'tw-placeholder-deepOrange-a100'
  | 'tw-placeholder-deepOrange-a200'
  | 'tw-placeholder-deepOrange-a400'
  | 'tw-placeholder-deepOrange-a700'
  | 'tw-placeholder-brown-50'
  | 'tw-placeholder-brown-100'
  | 'tw-placeholder-brown-200'
  | 'tw-placeholder-brown-300'
  | 'tw-placeholder-brown-400'
  | 'tw-placeholder-brown-500'
  | 'tw-placeholder-brown-600'
  | 'tw-placeholder-brown-700'
  | 'tw-placeholder-brown-800'
  | 'tw-placeholder-brown-900'
  | 'tw-placeholder-gray-50'
  | 'tw-placeholder-gray-100'
  | 'tw-placeholder-gray-200'
  | 'tw-placeholder-gray-300'
  | 'tw-placeholder-gray-400'
  | 'tw-placeholder-gray-500'
  | 'tw-placeholder-gray-600'
  | 'tw-placeholder-gray-700'
  | 'tw-placeholder-gray-800'
  | 'tw-placeholder-gray-900'
  | 'tw-placeholder-blueGray-50'
  | 'tw-placeholder-blueGray-100'
  | 'tw-placeholder-blueGray-200'
  | 'tw-placeholder-blueGray-300'
  | 'tw-placeholder-blueGray-400'
  | 'tw-placeholder-blueGray-500'
  | 'tw-placeholder-blueGray-600'
  | 'tw-placeholder-blueGray-700'
  | 'tw-placeholder-blueGray-800'
  | 'tw-placeholder-blueGray-900'
  | 'tw-placeholder-black'
  | 'tw-placeholder-white'
  | 'tw-placeholder-transparent';

export type TPlaceholderOpacity =
  | 'tw-placeholder-opacity-0'
  | 'tw-placeholder-opacity-25'
  | 'tw-placeholder-opacity-50'
  | 'tw-placeholder-opacity-75'
  | 'tw-placeholder-opacity-100';

export type TTextAlign =
  | 'tw-text-left'
  | 'tw-text-center'
  | 'tw-text-right'
  | 'tw-text-justify';

export type TTextColor =
  | 'tw-text-primary'
  | 'tw-text-muted'
  | 'tw-text-warning'
  | 'tw-text-danger'
  | 'tw-text-fail'
  | 'tw-text-info'
  | 'tw-text-red-50'
  | 'tw-text-red-100'
  | 'tw-text-red-200'
  | 'tw-text-red-300'
  | 'tw-text-red-400'
  | 'tw-text-red-500'
  | 'tw-text-red-600'
  | 'tw-text-red-700'
  | 'tw-text-red-800'
  | 'tw-text-red-900'
  | 'tw-text-red-a100'
  | 'tw-text-red-a200'
  | 'tw-text-red-a400'
  | 'tw-text-red-a700'
  | 'tw-text-pink-50'
  | 'tw-text-pink-100'
  | 'tw-text-pink-200'
  | 'tw-text-pink-300'
  | 'tw-text-pink-400'
  | 'tw-text-pink-500'
  | 'tw-text-pink-600'
  | 'tw-text-pink-700'
  | 'tw-text-pink-800'
  | 'tw-text-pink-900'
  | 'tw-text-pink-a100'
  | 'tw-text-pink-a200'
  | 'tw-text-pink-a400'
  | 'tw-text-pink-a700'
  | 'tw-text-purple-50'
  | 'tw-text-purple-100'
  | 'tw-text-purple-200'
  | 'tw-text-purple-300'
  | 'tw-text-purple-400'
  | 'tw-text-purple-500'
  | 'tw-text-purple-600'
  | 'tw-text-purple-700'
  | 'tw-text-purple-800'
  | 'tw-text-purple-900'
  | 'tw-text-purple-a100'
  | 'tw-text-purple-a200'
  | 'tw-text-purple-a400'
  | 'tw-text-purple-a700'
  | 'tw-text-deepPurple-50'
  | 'tw-text-deepPurple-100'
  | 'tw-text-deepPurple-200'
  | 'tw-text-deepPurple-300'
  | 'tw-text-deepPurple-400'
  | 'tw-text-deepPurple-500'
  | 'tw-text-deepPurple-600'
  | 'tw-text-deepPurple-700'
  | 'tw-text-deepPurple-800'
  | 'tw-text-deepPurple-900'
  | 'tw-text-deepPurple-a100'
  | 'tw-text-deepPurple-a200'
  | 'tw-text-deepPurple-a400'
  | 'tw-text-deepPurple-a700'
  | 'tw-text-indigo-50'
  | 'tw-text-indigo-100'
  | 'tw-text-indigo-200'
  | 'tw-text-indigo-300'
  | 'tw-text-indigo-400'
  | 'tw-text-indigo-500'
  | 'tw-text-indigo-600'
  | 'tw-text-indigo-700'
  | 'tw-text-indigo-800'
  | 'tw-text-indigo-900'
  | 'tw-text-indigo-a100'
  | 'tw-text-indigo-a200'
  | 'tw-text-indigo-a400'
  | 'tw-text-indigo-a700'
  | 'tw-text-blue-50'
  | 'tw-text-blue-100'
  | 'tw-text-blue-200'
  | 'tw-text-blue-300'
  | 'tw-text-blue-400'
  | 'tw-text-blue-500'
  | 'tw-text-blue-600'
  | 'tw-text-blue-700'
  | 'tw-text-blue-800'
  | 'tw-text-blue-900'
  | 'tw-text-blue-a100'
  | 'tw-text-blue-a200'
  | 'tw-text-blue-a400'
  | 'tw-text-blue-a700'
  | 'tw-text-lightBlue-50'
  | 'tw-text-lightBlue-100'
  | 'tw-text-lightBlue-200'
  | 'tw-text-lightBlue-300'
  | 'tw-text-lightBlue-400'
  | 'tw-text-lightBlue-500'
  | 'tw-text-lightBlue-600'
  | 'tw-text-lightBlue-700'
  | 'tw-text-lightBlue-800'
  | 'tw-text-lightBlue-900'
  | 'tw-text-lightBlue-a100'
  | 'tw-text-lightBlue-a200'
  | 'tw-text-lightBlue-a400'
  | 'tw-text-lightBlue-a700'
  | 'tw-text-cyan-50'
  | 'tw-text-cyan-100'
  | 'tw-text-cyan-200'
  | 'tw-text-cyan-300'
  | 'tw-text-cyan-400'
  | 'tw-text-cyan-500'
  | 'tw-text-cyan-600'
  | 'tw-text-cyan-700'
  | 'tw-text-cyan-800'
  | 'tw-text-cyan-900'
  | 'tw-text-cyan-a100'
  | 'tw-text-cyan-a200'
  | 'tw-text-cyan-a400'
  | 'tw-text-cyan-a700'
  | 'tw-text-teal-50'
  | 'tw-text-teal-100'
  | 'tw-text-teal-200'
  | 'tw-text-teal-300'
  | 'tw-text-teal-400'
  | 'tw-text-teal-500'
  | 'tw-text-teal-600'
  | 'tw-text-teal-700'
  | 'tw-text-teal-800'
  | 'tw-text-teal-900'
  | 'tw-text-teal-a100'
  | 'tw-text-teal-a200'
  | 'tw-text-teal-a400'
  | 'tw-text-teal-a700'
  | 'tw-text-green-50'
  | 'tw-text-green-100'
  | 'tw-text-green-200'
  | 'tw-text-green-300'
  | 'tw-text-green-400'
  | 'tw-text-green-500'
  | 'tw-text-green-600'
  | 'tw-text-green-700'
  | 'tw-text-green-800'
  | 'tw-text-green-900'
  | 'tw-text-green-a100'
  | 'tw-text-green-a200'
  | 'tw-text-green-a400'
  | 'tw-text-green-a700'
  | 'tw-text-lightGreen-50'
  | 'tw-text-lightGreen-100'
  | 'tw-text-lightGreen-200'
  | 'tw-text-lightGreen-300'
  | 'tw-text-lightGreen-400'
  | 'tw-text-lightGreen-500'
  | 'tw-text-lightGreen-600'
  | 'tw-text-lightGreen-700'
  | 'tw-text-lightGreen-800'
  | 'tw-text-lightGreen-900'
  | 'tw-text-lightGreen-a100'
  | 'tw-text-lightGreen-a200'
  | 'tw-text-lightGreen-a400'
  | 'tw-text-lightGreen-a700'
  | 'tw-text-lime-50'
  | 'tw-text-lime-100'
  | 'tw-text-lime-200'
  | 'tw-text-lime-300'
  | 'tw-text-lime-400'
  | 'tw-text-lime-500'
  | 'tw-text-lime-600'
  | 'tw-text-lime-700'
  | 'tw-text-lime-800'
  | 'tw-text-lime-900'
  | 'tw-text-lime-a100'
  | 'tw-text-lime-a200'
  | 'tw-text-lime-a400'
  | 'tw-text-lime-a700'
  | 'tw-text-yellow-50'
  | 'tw-text-yellow-100'
  | 'tw-text-yellow-200'
  | 'tw-text-yellow-300'
  | 'tw-text-yellow-400'
  | 'tw-text-yellow-500'
  | 'tw-text-yellow-600'
  | 'tw-text-yellow-700'
  | 'tw-text-yellow-800'
  | 'tw-text-yellow-900'
  | 'tw-text-yellow-a100'
  | 'tw-text-yellow-a200'
  | 'tw-text-yellow-a400'
  | 'tw-text-yellow-a700'
  | 'tw-text-amber-50'
  | 'tw-text-amber-100'
  | 'tw-text-amber-200'
  | 'tw-text-amber-300'
  | 'tw-text-amber-400'
  | 'tw-text-amber-500'
  | 'tw-text-amber-600'
  | 'tw-text-amber-700'
  | 'tw-text-amber-800'
  | 'tw-text-amber-900'
  | 'tw-text-amber-a100'
  | 'tw-text-amber-a200'
  | 'tw-text-amber-a400'
  | 'tw-text-amber-a700'
  | 'tw-text-orange-50'
  | 'tw-text-orange-100'
  | 'tw-text-orange-200'
  | 'tw-text-orange-300'
  | 'tw-text-orange-400'
  | 'tw-text-orange-500'
  | 'tw-text-orange-600'
  | 'tw-text-orange-700'
  | 'tw-text-orange-800'
  | 'tw-text-orange-900'
  | 'tw-text-orange-a100'
  | 'tw-text-orange-a200'
  | 'tw-text-orange-a400'
  | 'tw-text-orange-a700'
  | 'tw-text-deepOrange-50'
  | 'tw-text-deepOrange-100'
  | 'tw-text-deepOrange-200'
  | 'tw-text-deepOrange-300'
  | 'tw-text-deepOrange-400'
  | 'tw-text-deepOrange-500'
  | 'tw-text-deepOrange-600'
  | 'tw-text-deepOrange-700'
  | 'tw-text-deepOrange-800'
  | 'tw-text-deepOrange-900'
  | 'tw-text-deepOrange-a100'
  | 'tw-text-deepOrange-a200'
  | 'tw-text-deepOrange-a400'
  | 'tw-text-deepOrange-a700'
  | 'tw-text-brown-50'
  | 'tw-text-brown-100'
  | 'tw-text-brown-200'
  | 'tw-text-brown-300'
  | 'tw-text-brown-400'
  | 'tw-text-brown-500'
  | 'tw-text-brown-600'
  | 'tw-text-brown-700'
  | 'tw-text-brown-800'
  | 'tw-text-brown-900'
  | 'tw-text-gray-50'
  | 'tw-text-gray-100'
  | 'tw-text-gray-200'
  | 'tw-text-gray-300'
  | 'tw-text-gray-400'
  | 'tw-text-gray-500'
  | 'tw-text-gray-600'
  | 'tw-text-gray-700'
  | 'tw-text-gray-800'
  | 'tw-text-gray-900'
  | 'tw-text-blueGray-50'
  | 'tw-text-blueGray-100'
  | 'tw-text-blueGray-200'
  | 'tw-text-blueGray-300'
  | 'tw-text-blueGray-400'
  | 'tw-text-blueGray-500'
  | 'tw-text-blueGray-600'
  | 'tw-text-blueGray-700'
  | 'tw-text-blueGray-800'
  | 'tw-text-blueGray-900'
  | 'tw-text-black'
  | 'tw-text-white'
  | 'tw-text-transparent';

export type TTextDecoration =
  | 'tw-underline'
  | 'tw-line-through'
  | 'tw-no-underline';

export type TTextOpacity =
  | 'tw-text-opacity-0'
  | 'tw-text-opacity-25'
  | 'tw-text-opacity-50'
  | 'tw-text-opacity-75'
  | 'tw-text-opacity-100';

export type TTextTransform =
  | 'tw-uppercase'
  | 'tw-lowercase'
  | 'tw-capitalize'
  | 'tw-normal-case';

export type TVerticalAlign =
  | 'tw-align-baseline'
  | 'tw-align-top'
  | 'tw-align-middle'
  | 'tw-align-bottom'
  | 'tw-align-text-top'
  | 'tw-align-text-bottom';

export type TWhitespace =
  | 'tw-whitespace-normal'
  | 'tw-whitespace-no-wrap'
  | 'tw-whitespace-pre'
  | 'tw-whitespace-pre-line'
  | 'tw-whitespace-pre-wrap';

export type TWordBreak =
  | 'tw-break-normal'
  | 'tw-break-words'
  | 'tw-break-all'
  | 'tw-truncate';

export type TTypography =
  | TFontFamily
  | TFontSize
  | TFontSmoothing
  | TFontStyle
  | TFontWeight
  | TFontVariantNumeric
  | TLetterSpacing
  | TLineHeight
  | TListStyleType
  | TListStylePosition
  | TPlaceholderColor
  | TPlaceholderOpacity
  | TTextAlign
  | TTextColor
  | TTextDecoration
  | TTextOpacity
  | TTextTransform
  | TVerticalAlign
  | TWhitespace
  | TWordBreak;

export type TPseudoClasses =
  | 'sm:tw-content-start'
  | 'md:tw-content-start'
  | 'lg:tw-content-start'
  | 'xl:tw-content-start'
  | 'sm:tw-content-center'
  | 'md:tw-content-center'
  | 'lg:tw-content-center'
  | 'xl:tw-content-center'
  | 'sm:tw-content-end'
  | 'md:tw-content-end'
  | 'lg:tw-content-end'
  | 'xl:tw-content-end'
  | 'sm:tw-content-between'
  | 'md:tw-content-between'
  | 'lg:tw-content-between'
  | 'xl:tw-content-between'
  | 'sm:tw-content-around'
  | 'md:tw-content-around'
  | 'lg:tw-content-around'
  | 'xl:tw-content-around'
  | 'sm:tw-items-stretch'
  | 'md:tw-items-stretch'
  | 'lg:tw-items-stretch'
  | 'xl:tw-items-stretch'
  | 'sm:tw-items-start'
  | 'md:tw-items-start'
  | 'lg:tw-items-start'
  | 'xl:tw-items-start'
  | 'sm:tw-items-center'
  | 'md:tw-items-center'
  | 'lg:tw-items-center'
  | 'xl:tw-items-center'
  | 'sm:tw-items-end'
  | 'md:tw-items-end'
  | 'lg:tw-items-end'
  | 'xl:tw-items-end'
  | 'sm:tw-items-baseline'
  | 'md:tw-items-baseline'
  | 'lg:tw-items-baseline'
  | 'xl:tw-items-baseline'
  | 'sm:tw-self-auto'
  | 'md:tw-self-auto'
  | 'lg:tw-self-auto'
  | 'xl:tw-self-auto'
  | 'sm:tw-self-start'
  | 'md:tw-self-start'
  | 'lg:tw-self-start'
  | 'xl:tw-self-start'
  | 'sm:tw-self-center'
  | 'md:tw-self-center'
  | 'lg:tw-self-center'
  | 'xl:tw-self-center'
  | 'sm:tw-self-end'
  | 'md:tw-self-end'
  | 'lg:tw-self-end'
  | 'xl:tw-self-end'
  | 'sm:tw-self-stretch'
  | 'md:tw-self-stretch'
  | 'lg:tw-self-stretch'
  | 'xl:tw-self-stretch'
  | 'sm:tw-appearance-none'
  | 'md:tw-appearance-none'
  | 'lg:tw-appearance-none'
  | 'xl:tw-appearance-none'
  | 'sm:tw-bg-fixed'
  | 'md:tw-bg-fixed'
  | 'lg:tw-bg-fixed'
  | 'xl:tw-bg-fixed'
  | 'sm:tw-bg-local'
  | 'md:tw-bg-local'
  | 'lg:tw-bg-local'
  | 'xl:tw-bg-local'
  | 'sm:tw-bg-scroll'
  | 'md:tw-bg-scroll'
  | 'lg:tw-bg-scroll'
  | 'xl:tw-bg-scroll'
  | 'sm:tw-bg-clip-border'
  | 'md:tw-bg-clip-border'
  | 'lg:tw-bg-clip-border'
  | 'xl:tw-bg-clip-border'
  | 'sm:tw-bg-clip-padding'
  | 'md:tw-bg-clip-padding'
  | 'lg:tw-bg-clip-padding'
  | 'xl:tw-bg-clip-padding'
  | 'sm:tw-bg-clip-content'
  | 'md:tw-bg-clip-content'
  | 'lg:tw-bg-clip-content'
  | 'xl:tw-bg-clip-content'
  | 'sm:tw-bg-clip-text'
  | 'md:tw-bg-clip-text'
  | 'lg:tw-bg-clip-text'
  | 'xl:tw-bg-clip-text'
  | 'sm:tw-bg-primary'
  | 'md:tw-bg-primary'
  | 'lg:tw-bg-primary'
  | 'xl:tw-bg-primary'
  | 'hover:tw-bg-primary'
  | 'focus:tw-bg-primary'
  | 'group-hover:tw-bg-primary'
  | 'tw-group'
  | 'sm:tw-bg-muted'
  | 'md:tw-bg-muted'
  | 'lg:tw-bg-muted'
  | 'xl:tw-bg-muted'
  | 'hover:tw-bg-muted'
  | 'focus:tw-bg-muted'
  | 'group-hover:tw-bg-muted'
  | 'tw-group'
  | 'sm:tw-bg-warning'
  | 'md:tw-bg-warning'
  | 'lg:tw-bg-warning'
  | 'xl:tw-bg-warning'
  | 'hover:tw-bg-warning'
  | 'focus:tw-bg-warning'
  | 'group-hover:tw-bg-warning'
  | 'tw-group'
  | 'sm:tw-bg-danger'
  | 'md:tw-bg-danger'
  | 'lg:tw-bg-danger'
  | 'xl:tw-bg-danger'
  | 'hover:tw-bg-danger'
  | 'focus:tw-bg-danger'
  | 'group-hover:tw-bg-danger'
  | 'tw-group'
  | 'sm:tw-bg-fail'
  | 'md:tw-bg-fail'
  | 'lg:tw-bg-fail'
  | 'xl:tw-bg-fail'
  | 'hover:tw-bg-fail'
  | 'focus:tw-bg-fail'
  | 'group-hover:tw-bg-fail'
  | 'tw-group'
  | 'sm:tw-bg-info'
  | 'md:tw-bg-info'
  | 'lg:tw-bg-info'
  | 'xl:tw-bg-info'
  | 'hover:tw-bg-info'
  | 'focus:tw-bg-info'
  | 'group-hover:tw-bg-info'
  | 'tw-group'
  | 'sm:tw-bg-red-50'
  | 'md:tw-bg-red-50'
  | 'lg:tw-bg-red-50'
  | 'xl:tw-bg-red-50'
  | 'hover:tw-bg-red-50'
  | 'focus:tw-bg-red-50'
  | 'group-hover:tw-bg-red-50'
  | 'tw-group'
  | 'sm:tw-bg-red-100'
  | 'md:tw-bg-red-100'
  | 'lg:tw-bg-red-100'
  | 'xl:tw-bg-red-100'
  | 'hover:tw-bg-red-100'
  | 'focus:tw-bg-red-100'
  | 'group-hover:tw-bg-red-100'
  | 'tw-group'
  | 'sm:tw-bg-red-200'
  | 'md:tw-bg-red-200'
  | 'lg:tw-bg-red-200'
  | 'xl:tw-bg-red-200'
  | 'hover:tw-bg-red-200'
  | 'focus:tw-bg-red-200'
  | 'group-hover:tw-bg-red-200'
  | 'tw-group'
  | 'sm:tw-bg-red-300'
  | 'md:tw-bg-red-300'
  | 'lg:tw-bg-red-300'
  | 'xl:tw-bg-red-300'
  | 'hover:tw-bg-red-300'
  | 'focus:tw-bg-red-300'
  | 'group-hover:tw-bg-red-300'
  | 'tw-group'
  | 'sm:tw-bg-red-400'
  | 'md:tw-bg-red-400'
  | 'lg:tw-bg-red-400'
  | 'xl:tw-bg-red-400'
  | 'hover:tw-bg-red-400'
  | 'focus:tw-bg-red-400'
  | 'group-hover:tw-bg-red-400'
  | 'tw-group'
  | 'sm:tw-bg-red-500'
  | 'md:tw-bg-red-500'
  | 'lg:tw-bg-red-500'
  | 'xl:tw-bg-red-500'
  | 'hover:tw-bg-red-500'
  | 'focus:tw-bg-red-500'
  | 'group-hover:tw-bg-red-500'
  | 'tw-group'
  | 'sm:tw-bg-red-600'
  | 'md:tw-bg-red-600'
  | 'lg:tw-bg-red-600'
  | 'xl:tw-bg-red-600'
  | 'hover:tw-bg-red-600'
  | 'focus:tw-bg-red-600'
  | 'group-hover:tw-bg-red-600'
  | 'tw-group'
  | 'sm:tw-bg-red-700'
  | 'md:tw-bg-red-700'
  | 'lg:tw-bg-red-700'
  | 'xl:tw-bg-red-700'
  | 'hover:tw-bg-red-700'
  | 'focus:tw-bg-red-700'
  | 'group-hover:tw-bg-red-700'
  | 'tw-group'
  | 'sm:tw-bg-red-800'
  | 'md:tw-bg-red-800'
  | 'lg:tw-bg-red-800'
  | 'xl:tw-bg-red-800'
  | 'hover:tw-bg-red-800'
  | 'focus:tw-bg-red-800'
  | 'group-hover:tw-bg-red-800'
  | 'tw-group'
  | 'sm:tw-bg-red-900'
  | 'md:tw-bg-red-900'
  | 'lg:tw-bg-red-900'
  | 'xl:tw-bg-red-900'
  | 'hover:tw-bg-red-900'
  | 'focus:tw-bg-red-900'
  | 'group-hover:tw-bg-red-900'
  | 'tw-group'
  | 'sm:tw-bg-red-a100'
  | 'md:tw-bg-red-a100'
  | 'lg:tw-bg-red-a100'
  | 'xl:tw-bg-red-a100'
  | 'hover:tw-bg-red-a100'
  | 'focus:tw-bg-red-a100'
  | 'group-hover:tw-bg-red-a100'
  | 'tw-group'
  | 'sm:tw-bg-red-a200'
  | 'md:tw-bg-red-a200'
  | 'lg:tw-bg-red-a200'
  | 'xl:tw-bg-red-a200'
  | 'hover:tw-bg-red-a200'
  | 'focus:tw-bg-red-a200'
  | 'group-hover:tw-bg-red-a200'
  | 'tw-group'
  | 'sm:tw-bg-red-a400'
  | 'md:tw-bg-red-a400'
  | 'lg:tw-bg-red-a400'
  | 'xl:tw-bg-red-a400'
  | 'hover:tw-bg-red-a400'
  | 'focus:tw-bg-red-a400'
  | 'group-hover:tw-bg-red-a400'
  | 'tw-group'
  | 'sm:tw-bg-red-a700'
  | 'md:tw-bg-red-a700'
  | 'lg:tw-bg-red-a700'
  | 'xl:tw-bg-red-a700'
  | 'hover:tw-bg-red-a700'
  | 'focus:tw-bg-red-a700'
  | 'group-hover:tw-bg-red-a700'
  | 'tw-group'
  | 'sm:tw-bg-pink-50'
  | 'md:tw-bg-pink-50'
  | 'lg:tw-bg-pink-50'
  | 'xl:tw-bg-pink-50'
  | 'hover:tw-bg-pink-50'
  | 'focus:tw-bg-pink-50'
  | 'group-hover:tw-bg-pink-50'
  | 'tw-group'
  | 'sm:tw-bg-pink-100'
  | 'md:tw-bg-pink-100'
  | 'lg:tw-bg-pink-100'
  | 'xl:tw-bg-pink-100'
  | 'hover:tw-bg-pink-100'
  | 'focus:tw-bg-pink-100'
  | 'group-hover:tw-bg-pink-100'
  | 'tw-group'
  | 'sm:tw-bg-pink-200'
  | 'md:tw-bg-pink-200'
  | 'lg:tw-bg-pink-200'
  | 'xl:tw-bg-pink-200'
  | 'hover:tw-bg-pink-200'
  | 'focus:tw-bg-pink-200'
  | 'group-hover:tw-bg-pink-200'
  | 'tw-group'
  | 'sm:tw-bg-pink-300'
  | 'md:tw-bg-pink-300'
  | 'lg:tw-bg-pink-300'
  | 'xl:tw-bg-pink-300'
  | 'hover:tw-bg-pink-300'
  | 'focus:tw-bg-pink-300'
  | 'group-hover:tw-bg-pink-300'
  | 'tw-group'
  | 'sm:tw-bg-pink-400'
  | 'md:tw-bg-pink-400'
  | 'lg:tw-bg-pink-400'
  | 'xl:tw-bg-pink-400'
  | 'hover:tw-bg-pink-400'
  | 'focus:tw-bg-pink-400'
  | 'group-hover:tw-bg-pink-400'
  | 'tw-group'
  | 'sm:tw-bg-pink-500'
  | 'md:tw-bg-pink-500'
  | 'lg:tw-bg-pink-500'
  | 'xl:tw-bg-pink-500'
  | 'hover:tw-bg-pink-500'
  | 'focus:tw-bg-pink-500'
  | 'group-hover:tw-bg-pink-500'
  | 'tw-group'
  | 'sm:tw-bg-pink-600'
  | 'md:tw-bg-pink-600'
  | 'lg:tw-bg-pink-600'
  | 'xl:tw-bg-pink-600'
  | 'hover:tw-bg-pink-600'
  | 'focus:tw-bg-pink-600'
  | 'group-hover:tw-bg-pink-600'
  | 'tw-group'
  | 'sm:tw-bg-pink-700'
  | 'md:tw-bg-pink-700'
  | 'lg:tw-bg-pink-700'
  | 'xl:tw-bg-pink-700'
  | 'hover:tw-bg-pink-700'
  | 'focus:tw-bg-pink-700'
  | 'group-hover:tw-bg-pink-700'
  | 'tw-group'
  | 'sm:tw-bg-pink-800'
  | 'md:tw-bg-pink-800'
  | 'lg:tw-bg-pink-800'
  | 'xl:tw-bg-pink-800'
  | 'hover:tw-bg-pink-800'
  | 'focus:tw-bg-pink-800'
  | 'group-hover:tw-bg-pink-800'
  | 'tw-group'
  | 'sm:tw-bg-pink-900'
  | 'md:tw-bg-pink-900'
  | 'lg:tw-bg-pink-900'
  | 'xl:tw-bg-pink-900'
  | 'hover:tw-bg-pink-900'
  | 'focus:tw-bg-pink-900'
  | 'group-hover:tw-bg-pink-900'
  | 'tw-group'
  | 'sm:tw-bg-pink-a100'
  | 'md:tw-bg-pink-a100'
  | 'lg:tw-bg-pink-a100'
  | 'xl:tw-bg-pink-a100'
  | 'hover:tw-bg-pink-a100'
  | 'focus:tw-bg-pink-a100'
  | 'group-hover:tw-bg-pink-a100'
  | 'tw-group'
  | 'sm:tw-bg-pink-a200'
  | 'md:tw-bg-pink-a200'
  | 'lg:tw-bg-pink-a200'
  | 'xl:tw-bg-pink-a200'
  | 'hover:tw-bg-pink-a200'
  | 'focus:tw-bg-pink-a200'
  | 'group-hover:tw-bg-pink-a200'
  | 'tw-group'
  | 'sm:tw-bg-pink-a400'
  | 'md:tw-bg-pink-a400'
  | 'lg:tw-bg-pink-a400'
  | 'xl:tw-bg-pink-a400'
  | 'hover:tw-bg-pink-a400'
  | 'focus:tw-bg-pink-a400'
  | 'group-hover:tw-bg-pink-a400'
  | 'tw-group'
  | 'sm:tw-bg-pink-a700'
  | 'md:tw-bg-pink-a700'
  | 'lg:tw-bg-pink-a700'
  | 'xl:tw-bg-pink-a700'
  | 'hover:tw-bg-pink-a700'
  | 'focus:tw-bg-pink-a700'
  | 'group-hover:tw-bg-pink-a700'
  | 'tw-group'
  | 'sm:tw-bg-purple-50'
  | 'md:tw-bg-purple-50'
  | 'lg:tw-bg-purple-50'
  | 'xl:tw-bg-purple-50'
  | 'hover:tw-bg-purple-50'
  | 'focus:tw-bg-purple-50'
  | 'group-hover:tw-bg-purple-50'
  | 'tw-group'
  | 'sm:tw-bg-purple-100'
  | 'md:tw-bg-purple-100'
  | 'lg:tw-bg-purple-100'
  | 'xl:tw-bg-purple-100'
  | 'hover:tw-bg-purple-100'
  | 'focus:tw-bg-purple-100'
  | 'group-hover:tw-bg-purple-100'
  | 'tw-group'
  | 'sm:tw-bg-purple-200'
  | 'md:tw-bg-purple-200'
  | 'lg:tw-bg-purple-200'
  | 'xl:tw-bg-purple-200'
  | 'hover:tw-bg-purple-200'
  | 'focus:tw-bg-purple-200'
  | 'group-hover:tw-bg-purple-200'
  | 'tw-group'
  | 'sm:tw-bg-purple-300'
  | 'md:tw-bg-purple-300'
  | 'lg:tw-bg-purple-300'
  | 'xl:tw-bg-purple-300'
  | 'hover:tw-bg-purple-300'
  | 'focus:tw-bg-purple-300'
  | 'group-hover:tw-bg-purple-300'
  | 'tw-group'
  | 'sm:tw-bg-purple-400'
  | 'md:tw-bg-purple-400'
  | 'lg:tw-bg-purple-400'
  | 'xl:tw-bg-purple-400'
  | 'hover:tw-bg-purple-400'
  | 'focus:tw-bg-purple-400'
  | 'group-hover:tw-bg-purple-400'
  | 'tw-group'
  | 'sm:tw-bg-purple-500'
  | 'md:tw-bg-purple-500'
  | 'lg:tw-bg-purple-500'
  | 'xl:tw-bg-purple-500'
  | 'hover:tw-bg-purple-500'
  | 'focus:tw-bg-purple-500'
  | 'group-hover:tw-bg-purple-500'
  | 'tw-group'
  | 'sm:tw-bg-purple-600'
  | 'md:tw-bg-purple-600'
  | 'lg:tw-bg-purple-600'
  | 'xl:tw-bg-purple-600'
  | 'hover:tw-bg-purple-600'
  | 'focus:tw-bg-purple-600'
  | 'group-hover:tw-bg-purple-600'
  | 'tw-group'
  | 'sm:tw-bg-purple-700'
  | 'md:tw-bg-purple-700'
  | 'lg:tw-bg-purple-700'
  | 'xl:tw-bg-purple-700'
  | 'hover:tw-bg-purple-700'
  | 'focus:tw-bg-purple-700'
  | 'group-hover:tw-bg-purple-700'
  | 'tw-group'
  | 'sm:tw-bg-purple-800'
  | 'md:tw-bg-purple-800'
  | 'lg:tw-bg-purple-800'
  | 'xl:tw-bg-purple-800'
  | 'hover:tw-bg-purple-800'
  | 'focus:tw-bg-purple-800'
  | 'group-hover:tw-bg-purple-800'
  | 'tw-group'
  | 'sm:tw-bg-purple-900'
  | 'md:tw-bg-purple-900'
  | 'lg:tw-bg-purple-900'
  | 'xl:tw-bg-purple-900'
  | 'hover:tw-bg-purple-900'
  | 'focus:tw-bg-purple-900'
  | 'group-hover:tw-bg-purple-900'
  | 'tw-group'
  | 'sm:tw-bg-purple-a100'
  | 'md:tw-bg-purple-a100'
  | 'lg:tw-bg-purple-a100'
  | 'xl:tw-bg-purple-a100'
  | 'hover:tw-bg-purple-a100'
  | 'focus:tw-bg-purple-a100'
  | 'group-hover:tw-bg-purple-a100'
  | 'tw-group'
  | 'sm:tw-bg-purple-a200'
  | 'md:tw-bg-purple-a200'
  | 'lg:tw-bg-purple-a200'
  | 'xl:tw-bg-purple-a200'
  | 'hover:tw-bg-purple-a200'
  | 'focus:tw-bg-purple-a200'
  | 'group-hover:tw-bg-purple-a200'
  | 'tw-group'
  | 'sm:tw-bg-purple-a400'
  | 'md:tw-bg-purple-a400'
  | 'lg:tw-bg-purple-a400'
  | 'xl:tw-bg-purple-a400'
  | 'hover:tw-bg-purple-a400'
  | 'focus:tw-bg-purple-a400'
  | 'group-hover:tw-bg-purple-a400'
  | 'tw-group'
  | 'sm:tw-bg-purple-a700'
  | 'md:tw-bg-purple-a700'
  | 'lg:tw-bg-purple-a700'
  | 'xl:tw-bg-purple-a700'
  | 'hover:tw-bg-purple-a700'
  | 'focus:tw-bg-purple-a700'
  | 'group-hover:tw-bg-purple-a700'
  | 'tw-group'
  | 'sm:tw-bg-deepPurple-50'
  | 'md:tw-bg-deepPurple-50'
  | 'lg:tw-bg-deepPurple-50'
  | 'xl:tw-bg-deepPurple-50'
  | 'hover:tw-bg-deepPurple-50'
  | 'focus:tw-bg-deepPurple-50'
  | 'group-hover:tw-bg-deepPurple-50'
  | 'tw-group'
  | 'sm:tw-bg-deepPurple-100'
  | 'md:tw-bg-deepPurple-100'
  | 'lg:tw-bg-deepPurple-100'
  | 'xl:tw-bg-deepPurple-100'
  | 'hover:tw-bg-deepPurple-100'
  | 'focus:tw-bg-deepPurple-100'
  | 'group-hover:tw-bg-deepPurple-100'
  | 'tw-group'
  | 'sm:tw-bg-deepPurple-200'
  | 'md:tw-bg-deepPurple-200'
  | 'lg:tw-bg-deepPurple-200'
  | 'xl:tw-bg-deepPurple-200'
  | 'hover:tw-bg-deepPurple-200'
  | 'focus:tw-bg-deepPurple-200'
  | 'group-hover:tw-bg-deepPurple-200'
  | 'tw-group'
  | 'sm:tw-bg-deepPurple-300'
  | 'md:tw-bg-deepPurple-300'
  | 'lg:tw-bg-deepPurple-300'
  | 'xl:tw-bg-deepPurple-300'
  | 'hover:tw-bg-deepPurple-300'
  | 'focus:tw-bg-deepPurple-300'
  | 'group-hover:tw-bg-deepPurple-300'
  | 'tw-group'
  | 'sm:tw-bg-deepPurple-400'
  | 'md:tw-bg-deepPurple-400'
  | 'lg:tw-bg-deepPurple-400'
  | 'xl:tw-bg-deepPurple-400'
  | 'hover:tw-bg-deepPurple-400'
  | 'focus:tw-bg-deepPurple-400'
  | 'group-hover:tw-bg-deepPurple-400'
  | 'tw-group'
  | 'sm:tw-bg-deepPurple-500'
  | 'md:tw-bg-deepPurple-500'
  | 'lg:tw-bg-deepPurple-500'
  | 'xl:tw-bg-deepPurple-500'
  | 'hover:tw-bg-deepPurple-500'
  | 'focus:tw-bg-deepPurple-500'
  | 'group-hover:tw-bg-deepPurple-500'
  | 'tw-group'
  | 'sm:tw-bg-deepPurple-600'
  | 'md:tw-bg-deepPurple-600'
  | 'lg:tw-bg-deepPurple-600'
  | 'xl:tw-bg-deepPurple-600'
  | 'hover:tw-bg-deepPurple-600'
  | 'focus:tw-bg-deepPurple-600'
  | 'group-hover:tw-bg-deepPurple-600'
  | 'tw-group'
  | 'sm:tw-bg-deepPurple-700'
  | 'md:tw-bg-deepPurple-700'
  | 'lg:tw-bg-deepPurple-700'
  | 'xl:tw-bg-deepPurple-700'
  | 'hover:tw-bg-deepPurple-700'
  | 'focus:tw-bg-deepPurple-700'
  | 'group-hover:tw-bg-deepPurple-700'
  | 'tw-group'
  | 'sm:tw-bg-deepPurple-800'
  | 'md:tw-bg-deepPurple-800'
  | 'lg:tw-bg-deepPurple-800'
  | 'xl:tw-bg-deepPurple-800'
  | 'hover:tw-bg-deepPurple-800'
  | 'focus:tw-bg-deepPurple-800'
  | 'group-hover:tw-bg-deepPurple-800'
  | 'tw-group'
  | 'sm:tw-bg-deepPurple-900'
  | 'md:tw-bg-deepPurple-900'
  | 'lg:tw-bg-deepPurple-900'
  | 'xl:tw-bg-deepPurple-900'
  | 'hover:tw-bg-deepPurple-900'
  | 'focus:tw-bg-deepPurple-900'
  | 'group-hover:tw-bg-deepPurple-900'
  | 'tw-group'
  | 'sm:tw-bg-deepPurple-a100'
  | 'md:tw-bg-deepPurple-a100'
  | 'lg:tw-bg-deepPurple-a100'
  | 'xl:tw-bg-deepPurple-a100'
  | 'hover:tw-bg-deepPurple-a100'
  | 'focus:tw-bg-deepPurple-a100'
  | 'group-hover:tw-bg-deepPurple-a100'
  | 'tw-group'
  | 'sm:tw-bg-deepPurple-a200'
  | 'md:tw-bg-deepPurple-a200'
  | 'lg:tw-bg-deepPurple-a200'
  | 'xl:tw-bg-deepPurple-a200'
  | 'hover:tw-bg-deepPurple-a200'
  | 'focus:tw-bg-deepPurple-a200'
  | 'group-hover:tw-bg-deepPurple-a200'
  | 'tw-group'
  | 'sm:tw-bg-deepPurple-a400'
  | 'md:tw-bg-deepPurple-a400'
  | 'lg:tw-bg-deepPurple-a400'
  | 'xl:tw-bg-deepPurple-a400'
  | 'hover:tw-bg-deepPurple-a400'
  | 'focus:tw-bg-deepPurple-a400'
  | 'group-hover:tw-bg-deepPurple-a400'
  | 'tw-group'
  | 'sm:tw-bg-deepPurple-a700'
  | 'md:tw-bg-deepPurple-a700'
  | 'lg:tw-bg-deepPurple-a700'
  | 'xl:tw-bg-deepPurple-a700'
  | 'hover:tw-bg-deepPurple-a700'
  | 'focus:tw-bg-deepPurple-a700'
  | 'group-hover:tw-bg-deepPurple-a700'
  | 'tw-group'
  | 'sm:tw-bg-indigo-50'
  | 'md:tw-bg-indigo-50'
  | 'lg:tw-bg-indigo-50'
  | 'xl:tw-bg-indigo-50'
  | 'hover:tw-bg-indigo-50'
  | 'focus:tw-bg-indigo-50'
  | 'group-hover:tw-bg-indigo-50'
  | 'tw-group'
  | 'sm:tw-bg-indigo-100'
  | 'md:tw-bg-indigo-100'
  | 'lg:tw-bg-indigo-100'
  | 'xl:tw-bg-indigo-100'
  | 'hover:tw-bg-indigo-100'
  | 'focus:tw-bg-indigo-100'
  | 'group-hover:tw-bg-indigo-100'
  | 'tw-group'
  | 'sm:tw-bg-indigo-200'
  | 'md:tw-bg-indigo-200'
  | 'lg:tw-bg-indigo-200'
  | 'xl:tw-bg-indigo-200'
  | 'hover:tw-bg-indigo-200'
  | 'focus:tw-bg-indigo-200'
  | 'group-hover:tw-bg-indigo-200'
  | 'tw-group'
  | 'sm:tw-bg-indigo-300'
  | 'md:tw-bg-indigo-300'
  | 'lg:tw-bg-indigo-300'
  | 'xl:tw-bg-indigo-300'
  | 'hover:tw-bg-indigo-300'
  | 'focus:tw-bg-indigo-300'
  | 'group-hover:tw-bg-indigo-300'
  | 'tw-group'
  | 'sm:tw-bg-indigo-400'
  | 'md:tw-bg-indigo-400'
  | 'lg:tw-bg-indigo-400'
  | 'xl:tw-bg-indigo-400'
  | 'hover:tw-bg-indigo-400'
  | 'focus:tw-bg-indigo-400'
  | 'group-hover:tw-bg-indigo-400'
  | 'tw-group'
  | 'sm:tw-bg-indigo-500'
  | 'md:tw-bg-indigo-500'
  | 'lg:tw-bg-indigo-500'
  | 'xl:tw-bg-indigo-500'
  | 'hover:tw-bg-indigo-500'
  | 'focus:tw-bg-indigo-500'
  | 'group-hover:tw-bg-indigo-500'
  | 'tw-group'
  | 'sm:tw-bg-indigo-600'
  | 'md:tw-bg-indigo-600'
  | 'lg:tw-bg-indigo-600'
  | 'xl:tw-bg-indigo-600'
  | 'hover:tw-bg-indigo-600'
  | 'focus:tw-bg-indigo-600'
  | 'group-hover:tw-bg-indigo-600'
  | 'tw-group'
  | 'sm:tw-bg-indigo-700'
  | 'md:tw-bg-indigo-700'
  | 'lg:tw-bg-indigo-700'
  | 'xl:tw-bg-indigo-700'
  | 'hover:tw-bg-indigo-700'
  | 'focus:tw-bg-indigo-700'
  | 'group-hover:tw-bg-indigo-700'
  | 'tw-group'
  | 'sm:tw-bg-indigo-800'
  | 'md:tw-bg-indigo-800'
  | 'lg:tw-bg-indigo-800'
  | 'xl:tw-bg-indigo-800'
  | 'hover:tw-bg-indigo-800'
  | 'focus:tw-bg-indigo-800'
  | 'group-hover:tw-bg-indigo-800'
  | 'tw-group'
  | 'sm:tw-bg-indigo-900'
  | 'md:tw-bg-indigo-900'
  | 'lg:tw-bg-indigo-900'
  | 'xl:tw-bg-indigo-900'
  | 'hover:tw-bg-indigo-900'
  | 'focus:tw-bg-indigo-900'
  | 'group-hover:tw-bg-indigo-900'
  | 'tw-group'
  | 'sm:tw-bg-indigo-a100'
  | 'md:tw-bg-indigo-a100'
  | 'lg:tw-bg-indigo-a100'
  | 'xl:tw-bg-indigo-a100'
  | 'hover:tw-bg-indigo-a100'
  | 'focus:tw-bg-indigo-a100'
  | 'group-hover:tw-bg-indigo-a100'
  | 'tw-group'
  | 'sm:tw-bg-indigo-a200'
  | 'md:tw-bg-indigo-a200'
  | 'lg:tw-bg-indigo-a200'
  | 'xl:tw-bg-indigo-a200'
  | 'hover:tw-bg-indigo-a200'
  | 'focus:tw-bg-indigo-a200'
  | 'group-hover:tw-bg-indigo-a200'
  | 'tw-group'
  | 'sm:tw-bg-indigo-a400'
  | 'md:tw-bg-indigo-a400'
  | 'lg:tw-bg-indigo-a400'
  | 'xl:tw-bg-indigo-a400'
  | 'hover:tw-bg-indigo-a400'
  | 'focus:tw-bg-indigo-a400'
  | 'group-hover:tw-bg-indigo-a400'
  | 'tw-group'
  | 'sm:tw-bg-indigo-a700'
  | 'md:tw-bg-indigo-a700'
  | 'lg:tw-bg-indigo-a700'
  | 'xl:tw-bg-indigo-a700'
  | 'hover:tw-bg-indigo-a700'
  | 'focus:tw-bg-indigo-a700'
  | 'group-hover:tw-bg-indigo-a700'
  | 'tw-group'
  | 'sm:tw-bg-blue-50'
  | 'md:tw-bg-blue-50'
  | 'lg:tw-bg-blue-50'
  | 'xl:tw-bg-blue-50'
  | 'hover:tw-bg-blue-50'
  | 'focus:tw-bg-blue-50'
  | 'group-hover:tw-bg-blue-50'
  | 'tw-group'
  | 'sm:tw-bg-blue-100'
  | 'md:tw-bg-blue-100'
  | 'lg:tw-bg-blue-100'
  | 'xl:tw-bg-blue-100'
  | 'hover:tw-bg-blue-100'
  | 'focus:tw-bg-blue-100'
  | 'group-hover:tw-bg-blue-100'
  | 'tw-group'
  | 'sm:tw-bg-blue-200'
  | 'md:tw-bg-blue-200'
  | 'lg:tw-bg-blue-200'
  | 'xl:tw-bg-blue-200'
  | 'hover:tw-bg-blue-200'
  | 'focus:tw-bg-blue-200'
  | 'group-hover:tw-bg-blue-200'
  | 'tw-group'
  | 'sm:tw-bg-blue-300'
  | 'md:tw-bg-blue-300'
  | 'lg:tw-bg-blue-300'
  | 'xl:tw-bg-blue-300'
  | 'hover:tw-bg-blue-300'
  | 'focus:tw-bg-blue-300'
  | 'group-hover:tw-bg-blue-300'
  | 'tw-group'
  | 'sm:tw-bg-blue-400'
  | 'md:tw-bg-blue-400'
  | 'lg:tw-bg-blue-400'
  | 'xl:tw-bg-blue-400'
  | 'hover:tw-bg-blue-400'
  | 'focus:tw-bg-blue-400'
  | 'group-hover:tw-bg-blue-400'
  | 'tw-group'
  | 'sm:tw-bg-blue-500'
  | 'md:tw-bg-blue-500'
  | 'lg:tw-bg-blue-500'
  | 'xl:tw-bg-blue-500'
  | 'hover:tw-bg-blue-500'
  | 'focus:tw-bg-blue-500'
  | 'group-hover:tw-bg-blue-500'
  | 'tw-group'
  | 'sm:tw-bg-blue-600'
  | 'md:tw-bg-blue-600'
  | 'lg:tw-bg-blue-600'
  | 'xl:tw-bg-blue-600'
  | 'hover:tw-bg-blue-600'
  | 'focus:tw-bg-blue-600'
  | 'group-hover:tw-bg-blue-600'
  | 'tw-group'
  | 'sm:tw-bg-blue-700'
  | 'md:tw-bg-blue-700'
  | 'lg:tw-bg-blue-700'
  | 'xl:tw-bg-blue-700'
  | 'hover:tw-bg-blue-700'
  | 'focus:tw-bg-blue-700'
  | 'group-hover:tw-bg-blue-700'
  | 'tw-group'
  | 'sm:tw-bg-blue-800'
  | 'md:tw-bg-blue-800'
  | 'lg:tw-bg-blue-800'
  | 'xl:tw-bg-blue-800'
  | 'hover:tw-bg-blue-800'
  | 'focus:tw-bg-blue-800'
  | 'group-hover:tw-bg-blue-800'
  | 'tw-group'
  | 'sm:tw-bg-blue-900'
  | 'md:tw-bg-blue-900'
  | 'lg:tw-bg-blue-900'
  | 'xl:tw-bg-blue-900'
  | 'hover:tw-bg-blue-900'
  | 'focus:tw-bg-blue-900'
  | 'group-hover:tw-bg-blue-900'
  | 'tw-group'
  | 'sm:tw-bg-blue-a100'
  | 'md:tw-bg-blue-a100'
  | 'lg:tw-bg-blue-a100'
  | 'xl:tw-bg-blue-a100'
  | 'hover:tw-bg-blue-a100'
  | 'focus:tw-bg-blue-a100'
  | 'group-hover:tw-bg-blue-a100'
  | 'tw-group'
  | 'sm:tw-bg-blue-a200'
  | 'md:tw-bg-blue-a200'
  | 'lg:tw-bg-blue-a200'
  | 'xl:tw-bg-blue-a200'
  | 'hover:tw-bg-blue-a200'
  | 'focus:tw-bg-blue-a200'
  | 'group-hover:tw-bg-blue-a200'
  | 'tw-group'
  | 'sm:tw-bg-blue-a400'
  | 'md:tw-bg-blue-a400'
  | 'lg:tw-bg-blue-a400'
  | 'xl:tw-bg-blue-a400'
  | 'hover:tw-bg-blue-a400'
  | 'focus:tw-bg-blue-a400'
  | 'group-hover:tw-bg-blue-a400'
  | 'tw-group'
  | 'sm:tw-bg-blue-a700'
  | 'md:tw-bg-blue-a700'
  | 'lg:tw-bg-blue-a700'
  | 'xl:tw-bg-blue-a700'
  | 'hover:tw-bg-blue-a700'
  | 'focus:tw-bg-blue-a700'
  | 'group-hover:tw-bg-blue-a700'
  | 'tw-group'
  | 'sm:tw-bg-lightBlue-50'
  | 'md:tw-bg-lightBlue-50'
  | 'lg:tw-bg-lightBlue-50'
  | 'xl:tw-bg-lightBlue-50'
  | 'hover:tw-bg-lightBlue-50'
  | 'focus:tw-bg-lightBlue-50'
  | 'group-hover:tw-bg-lightBlue-50'
  | 'tw-group'
  | 'sm:tw-bg-lightBlue-100'
  | 'md:tw-bg-lightBlue-100'
  | 'lg:tw-bg-lightBlue-100'
  | 'xl:tw-bg-lightBlue-100'
  | 'hover:tw-bg-lightBlue-100'
  | 'focus:tw-bg-lightBlue-100'
  | 'group-hover:tw-bg-lightBlue-100'
  | 'tw-group'
  | 'sm:tw-bg-lightBlue-200'
  | 'md:tw-bg-lightBlue-200'
  | 'lg:tw-bg-lightBlue-200'
  | 'xl:tw-bg-lightBlue-200'
  | 'hover:tw-bg-lightBlue-200'
  | 'focus:tw-bg-lightBlue-200'
  | 'group-hover:tw-bg-lightBlue-200'
  | 'tw-group'
  | 'sm:tw-bg-lightBlue-300'
  | 'md:tw-bg-lightBlue-300'
  | 'lg:tw-bg-lightBlue-300'
  | 'xl:tw-bg-lightBlue-300'
  | 'hover:tw-bg-lightBlue-300'
  | 'focus:tw-bg-lightBlue-300'
  | 'group-hover:tw-bg-lightBlue-300'
  | 'tw-group'
  | 'sm:tw-bg-lightBlue-400'
  | 'md:tw-bg-lightBlue-400'
  | 'lg:tw-bg-lightBlue-400'
  | 'xl:tw-bg-lightBlue-400'
  | 'hover:tw-bg-lightBlue-400'
  | 'focus:tw-bg-lightBlue-400'
  | 'group-hover:tw-bg-lightBlue-400'
  | 'tw-group'
  | 'sm:tw-bg-lightBlue-500'
  | 'md:tw-bg-lightBlue-500'
  | 'lg:tw-bg-lightBlue-500'
  | 'xl:tw-bg-lightBlue-500'
  | 'hover:tw-bg-lightBlue-500'
  | 'focus:tw-bg-lightBlue-500'
  | 'group-hover:tw-bg-lightBlue-500'
  | 'tw-group'
  | 'sm:tw-bg-lightBlue-600'
  | 'md:tw-bg-lightBlue-600'
  | 'lg:tw-bg-lightBlue-600'
  | 'xl:tw-bg-lightBlue-600'
  | 'hover:tw-bg-lightBlue-600'
  | 'focus:tw-bg-lightBlue-600'
  | 'group-hover:tw-bg-lightBlue-600'
  | 'tw-group'
  | 'sm:tw-bg-lightBlue-700'
  | 'md:tw-bg-lightBlue-700'
  | 'lg:tw-bg-lightBlue-700'
  | 'xl:tw-bg-lightBlue-700'
  | 'hover:tw-bg-lightBlue-700'
  | 'focus:tw-bg-lightBlue-700'
  | 'group-hover:tw-bg-lightBlue-700'
  | 'tw-group'
  | 'sm:tw-bg-lightBlue-800'
  | 'md:tw-bg-lightBlue-800'
  | 'lg:tw-bg-lightBlue-800'
  | 'xl:tw-bg-lightBlue-800'
  | 'hover:tw-bg-lightBlue-800'
  | 'focus:tw-bg-lightBlue-800'
  | 'group-hover:tw-bg-lightBlue-800'
  | 'tw-group'
  | 'sm:tw-bg-lightBlue-900'
  | 'md:tw-bg-lightBlue-900'
  | 'lg:tw-bg-lightBlue-900'
  | 'xl:tw-bg-lightBlue-900'
  | 'hover:tw-bg-lightBlue-900'
  | 'focus:tw-bg-lightBlue-900'
  | 'group-hover:tw-bg-lightBlue-900'
  | 'tw-group'
  | 'sm:tw-bg-lightBlue-a100'
  | 'md:tw-bg-lightBlue-a100'
  | 'lg:tw-bg-lightBlue-a100'
  | 'xl:tw-bg-lightBlue-a100'
  | 'hover:tw-bg-lightBlue-a100'
  | 'focus:tw-bg-lightBlue-a100'
  | 'group-hover:tw-bg-lightBlue-a100'
  | 'tw-group'
  | 'sm:tw-bg-lightBlue-a200'
  | 'md:tw-bg-lightBlue-a200'
  | 'lg:tw-bg-lightBlue-a200'
  | 'xl:tw-bg-lightBlue-a200'
  | 'hover:tw-bg-lightBlue-a200'
  | 'focus:tw-bg-lightBlue-a200'
  | 'group-hover:tw-bg-lightBlue-a200'
  | 'tw-group'
  | 'sm:tw-bg-lightBlue-a400'
  | 'md:tw-bg-lightBlue-a400'
  | 'lg:tw-bg-lightBlue-a400'
  | 'xl:tw-bg-lightBlue-a400'
  | 'hover:tw-bg-lightBlue-a400'
  | 'focus:tw-bg-lightBlue-a400'
  | 'group-hover:tw-bg-lightBlue-a400'
  | 'tw-group'
  | 'sm:tw-bg-lightBlue-a700'
  | 'md:tw-bg-lightBlue-a700'
  | 'lg:tw-bg-lightBlue-a700'
  | 'xl:tw-bg-lightBlue-a700'
  | 'hover:tw-bg-lightBlue-a700'
  | 'focus:tw-bg-lightBlue-a700'
  | 'group-hover:tw-bg-lightBlue-a700'
  | 'tw-group'
  | 'sm:tw-bg-cyan-50'
  | 'md:tw-bg-cyan-50'
  | 'lg:tw-bg-cyan-50'
  | 'xl:tw-bg-cyan-50'
  | 'hover:tw-bg-cyan-50'
  | 'focus:tw-bg-cyan-50'
  | 'group-hover:tw-bg-cyan-50'
  | 'tw-group'
  | 'sm:tw-bg-cyan-100'
  | 'md:tw-bg-cyan-100'
  | 'lg:tw-bg-cyan-100'
  | 'xl:tw-bg-cyan-100'
  | 'hover:tw-bg-cyan-100'
  | 'focus:tw-bg-cyan-100'
  | 'group-hover:tw-bg-cyan-100'
  | 'tw-group'
  | 'sm:tw-bg-cyan-200'
  | 'md:tw-bg-cyan-200'
  | 'lg:tw-bg-cyan-200'
  | 'xl:tw-bg-cyan-200'
  | 'hover:tw-bg-cyan-200'
  | 'focus:tw-bg-cyan-200'
  | 'group-hover:tw-bg-cyan-200'
  | 'tw-group'
  | 'sm:tw-bg-cyan-300'
  | 'md:tw-bg-cyan-300'
  | 'lg:tw-bg-cyan-300'
  | 'xl:tw-bg-cyan-300'
  | 'hover:tw-bg-cyan-300'
  | 'focus:tw-bg-cyan-300'
  | 'group-hover:tw-bg-cyan-300'
  | 'tw-group'
  | 'sm:tw-bg-cyan-400'
  | 'md:tw-bg-cyan-400'
  | 'lg:tw-bg-cyan-400'
  | 'xl:tw-bg-cyan-400'
  | 'hover:tw-bg-cyan-400'
  | 'focus:tw-bg-cyan-400'
  | 'group-hover:tw-bg-cyan-400'
  | 'tw-group'
  | 'sm:tw-bg-cyan-500'
  | 'md:tw-bg-cyan-500'
  | 'lg:tw-bg-cyan-500'
  | 'xl:tw-bg-cyan-500'
  | 'hover:tw-bg-cyan-500'
  | 'focus:tw-bg-cyan-500'
  | 'group-hover:tw-bg-cyan-500'
  | 'tw-group'
  | 'sm:tw-bg-cyan-600'
  | 'md:tw-bg-cyan-600'
  | 'lg:tw-bg-cyan-600'
  | 'xl:tw-bg-cyan-600'
  | 'hover:tw-bg-cyan-600'
  | 'focus:tw-bg-cyan-600'
  | 'group-hover:tw-bg-cyan-600'
  | 'tw-group'
  | 'sm:tw-bg-cyan-700'
  | 'md:tw-bg-cyan-700'
  | 'lg:tw-bg-cyan-700'
  | 'xl:tw-bg-cyan-700'
  | 'hover:tw-bg-cyan-700'
  | 'focus:tw-bg-cyan-700'
  | 'group-hover:tw-bg-cyan-700'
  | 'tw-group'
  | 'sm:tw-bg-cyan-800'
  | 'md:tw-bg-cyan-800'
  | 'lg:tw-bg-cyan-800'
  | 'xl:tw-bg-cyan-800'
  | 'hover:tw-bg-cyan-800'
  | 'focus:tw-bg-cyan-800'
  | 'group-hover:tw-bg-cyan-800'
  | 'tw-group'
  | 'sm:tw-bg-cyan-900'
  | 'md:tw-bg-cyan-900'
  | 'lg:tw-bg-cyan-900'
  | 'xl:tw-bg-cyan-900'
  | 'hover:tw-bg-cyan-900'
  | 'focus:tw-bg-cyan-900'
  | 'group-hover:tw-bg-cyan-900'
  | 'tw-group'
  | 'sm:tw-bg-cyan-a100'
  | 'md:tw-bg-cyan-a100'
  | 'lg:tw-bg-cyan-a100'
  | 'xl:tw-bg-cyan-a100'
  | 'hover:tw-bg-cyan-a100'
  | 'focus:tw-bg-cyan-a100'
  | 'group-hover:tw-bg-cyan-a100'
  | 'tw-group'
  | 'sm:tw-bg-cyan-a200'
  | 'md:tw-bg-cyan-a200'
  | 'lg:tw-bg-cyan-a200'
  | 'xl:tw-bg-cyan-a200'
  | 'hover:tw-bg-cyan-a200'
  | 'focus:tw-bg-cyan-a200'
  | 'group-hover:tw-bg-cyan-a200'
  | 'tw-group'
  | 'sm:tw-bg-cyan-a400'
  | 'md:tw-bg-cyan-a400'
  | 'lg:tw-bg-cyan-a400'
  | 'xl:tw-bg-cyan-a400'
  | 'hover:tw-bg-cyan-a400'
  | 'focus:tw-bg-cyan-a400'
  | 'group-hover:tw-bg-cyan-a400'
  | 'tw-group'
  | 'sm:tw-bg-cyan-a700'
  | 'md:tw-bg-cyan-a700'
  | 'lg:tw-bg-cyan-a700'
  | 'xl:tw-bg-cyan-a700'
  | 'hover:tw-bg-cyan-a700'
  | 'focus:tw-bg-cyan-a700'
  | 'group-hover:tw-bg-cyan-a700'
  | 'tw-group'
  | 'sm:tw-bg-teal-50'
  | 'md:tw-bg-teal-50'
  | 'lg:tw-bg-teal-50'
  | 'xl:tw-bg-teal-50'
  | 'hover:tw-bg-teal-50'
  | 'focus:tw-bg-teal-50'
  | 'group-hover:tw-bg-teal-50'
  | 'tw-group'
  | 'sm:tw-bg-teal-100'
  | 'md:tw-bg-teal-100'
  | 'lg:tw-bg-teal-100'
  | 'xl:tw-bg-teal-100'
  | 'hover:tw-bg-teal-100'
  | 'focus:tw-bg-teal-100'
  | 'group-hover:tw-bg-teal-100'
  | 'tw-group'
  | 'sm:tw-bg-teal-200'
  | 'md:tw-bg-teal-200'
  | 'lg:tw-bg-teal-200'
  | 'xl:tw-bg-teal-200'
  | 'hover:tw-bg-teal-200'
  | 'focus:tw-bg-teal-200'
  | 'group-hover:tw-bg-teal-200'
  | 'tw-group'
  | 'sm:tw-bg-teal-300'
  | 'md:tw-bg-teal-300'
  | 'lg:tw-bg-teal-300'
  | 'xl:tw-bg-teal-300'
  | 'hover:tw-bg-teal-300'
  | 'focus:tw-bg-teal-300'
  | 'group-hover:tw-bg-teal-300'
  | 'tw-group'
  | 'sm:tw-bg-teal-400'
  | 'md:tw-bg-teal-400'
  | 'lg:tw-bg-teal-400'
  | 'xl:tw-bg-teal-400'
  | 'hover:tw-bg-teal-400'
  | 'focus:tw-bg-teal-400'
  | 'group-hover:tw-bg-teal-400'
  | 'tw-group'
  | 'sm:tw-bg-teal-500'
  | 'md:tw-bg-teal-500'
  | 'lg:tw-bg-teal-500'
  | 'xl:tw-bg-teal-500'
  | 'hover:tw-bg-teal-500'
  | 'focus:tw-bg-teal-500'
  | 'group-hover:tw-bg-teal-500'
  | 'tw-group'
  | 'sm:tw-bg-teal-600'
  | 'md:tw-bg-teal-600'
  | 'lg:tw-bg-teal-600'
  | 'xl:tw-bg-teal-600'
  | 'hover:tw-bg-teal-600'
  | 'focus:tw-bg-teal-600'
  | 'group-hover:tw-bg-teal-600'
  | 'tw-group'
  | 'sm:tw-bg-teal-700'
  | 'md:tw-bg-teal-700'
  | 'lg:tw-bg-teal-700'
  | 'xl:tw-bg-teal-700'
  | 'hover:tw-bg-teal-700'
  | 'focus:tw-bg-teal-700'
  | 'group-hover:tw-bg-teal-700'
  | 'tw-group'
  | 'sm:tw-bg-teal-800'
  | 'md:tw-bg-teal-800'
  | 'lg:tw-bg-teal-800'
  | 'xl:tw-bg-teal-800'
  | 'hover:tw-bg-teal-800'
  | 'focus:tw-bg-teal-800'
  | 'group-hover:tw-bg-teal-800'
  | 'tw-group'
  | 'sm:tw-bg-teal-900'
  | 'md:tw-bg-teal-900'
  | 'lg:tw-bg-teal-900'
  | 'xl:tw-bg-teal-900'
  | 'hover:tw-bg-teal-900'
  | 'focus:tw-bg-teal-900'
  | 'group-hover:tw-bg-teal-900'
  | 'tw-group'
  | 'sm:tw-bg-teal-a100'
  | 'md:tw-bg-teal-a100'
  | 'lg:tw-bg-teal-a100'
  | 'xl:tw-bg-teal-a100'
  | 'hover:tw-bg-teal-a100'
  | 'focus:tw-bg-teal-a100'
  | 'group-hover:tw-bg-teal-a100'
  | 'tw-group'
  | 'sm:tw-bg-teal-a200'
  | 'md:tw-bg-teal-a200'
  | 'lg:tw-bg-teal-a200'
  | 'xl:tw-bg-teal-a200'
  | 'hover:tw-bg-teal-a200'
  | 'focus:tw-bg-teal-a200'
  | 'group-hover:tw-bg-teal-a200'
  | 'tw-group'
  | 'sm:tw-bg-teal-a400'
  | 'md:tw-bg-teal-a400'
  | 'lg:tw-bg-teal-a400'
  | 'xl:tw-bg-teal-a400'
  | 'hover:tw-bg-teal-a400'
  | 'focus:tw-bg-teal-a400'
  | 'group-hover:tw-bg-teal-a400'
  | 'tw-group'
  | 'sm:tw-bg-teal-a700'
  | 'md:tw-bg-teal-a700'
  | 'lg:tw-bg-teal-a700'
  | 'xl:tw-bg-teal-a700'
  | 'hover:tw-bg-teal-a700'
  | 'focus:tw-bg-teal-a700'
  | 'group-hover:tw-bg-teal-a700'
  | 'tw-group'
  | 'sm:tw-bg-green-50'
  | 'md:tw-bg-green-50'
  | 'lg:tw-bg-green-50'
  | 'xl:tw-bg-green-50'
  | 'hover:tw-bg-green-50'
  | 'focus:tw-bg-green-50'
  | 'group-hover:tw-bg-green-50'
  | 'tw-group'
  | 'sm:tw-bg-green-100'
  | 'md:tw-bg-green-100'
  | 'lg:tw-bg-green-100'
  | 'xl:tw-bg-green-100'
  | 'hover:tw-bg-green-100'
  | 'focus:tw-bg-green-100'
  | 'group-hover:tw-bg-green-100'
  | 'tw-group'
  | 'sm:tw-bg-green-200'
  | 'md:tw-bg-green-200'
  | 'lg:tw-bg-green-200'
  | 'xl:tw-bg-green-200'
  | 'hover:tw-bg-green-200'
  | 'focus:tw-bg-green-200'
  | 'group-hover:tw-bg-green-200'
  | 'tw-group'
  | 'sm:tw-bg-green-300'
  | 'md:tw-bg-green-300'
  | 'lg:tw-bg-green-300'
  | 'xl:tw-bg-green-300'
  | 'hover:tw-bg-green-300'
  | 'focus:tw-bg-green-300'
  | 'group-hover:tw-bg-green-300'
  | 'tw-group'
  | 'sm:tw-bg-green-400'
  | 'md:tw-bg-green-400'
  | 'lg:tw-bg-green-400'
  | 'xl:tw-bg-green-400'
  | 'hover:tw-bg-green-400'
  | 'focus:tw-bg-green-400'
  | 'group-hover:tw-bg-green-400'
  | 'tw-group'
  | 'sm:tw-bg-green-500'
  | 'md:tw-bg-green-500'
  | 'lg:tw-bg-green-500'
  | 'xl:tw-bg-green-500'
  | 'hover:tw-bg-green-500'
  | 'focus:tw-bg-green-500'
  | 'group-hover:tw-bg-green-500'
  | 'tw-group'
  | 'sm:tw-bg-green-600'
  | 'md:tw-bg-green-600'
  | 'lg:tw-bg-green-600'
  | 'xl:tw-bg-green-600'
  | 'hover:tw-bg-green-600'
  | 'focus:tw-bg-green-600'
  | 'group-hover:tw-bg-green-600'
  | 'tw-group'
  | 'sm:tw-bg-green-700'
  | 'md:tw-bg-green-700'
  | 'lg:tw-bg-green-700'
  | 'xl:tw-bg-green-700'
  | 'hover:tw-bg-green-700'
  | 'focus:tw-bg-green-700'
  | 'group-hover:tw-bg-green-700'
  | 'tw-group'
  | 'sm:tw-bg-green-800'
  | 'md:tw-bg-green-800'
  | 'lg:tw-bg-green-800'
  | 'xl:tw-bg-green-800'
  | 'hover:tw-bg-green-800'
  | 'focus:tw-bg-green-800'
  | 'group-hover:tw-bg-green-800'
  | 'tw-group'
  | 'sm:tw-bg-green-900'
  | 'md:tw-bg-green-900'
  | 'lg:tw-bg-green-900'
  | 'xl:tw-bg-green-900'
  | 'hover:tw-bg-green-900'
  | 'focus:tw-bg-green-900'
  | 'group-hover:tw-bg-green-900'
  | 'tw-group'
  | 'sm:tw-bg-green-a100'
  | 'md:tw-bg-green-a100'
  | 'lg:tw-bg-green-a100'
  | 'xl:tw-bg-green-a100'
  | 'hover:tw-bg-green-a100'
  | 'focus:tw-bg-green-a100'
  | 'group-hover:tw-bg-green-a100'
  | 'tw-group'
  | 'sm:tw-bg-green-a200'
  | 'md:tw-bg-green-a200'
  | 'lg:tw-bg-green-a200'
  | 'xl:tw-bg-green-a200'
  | 'hover:tw-bg-green-a200'
  | 'focus:tw-bg-green-a200'
  | 'group-hover:tw-bg-green-a200'
  | 'tw-group'
  | 'sm:tw-bg-green-a400'
  | 'md:tw-bg-green-a400'
  | 'lg:tw-bg-green-a400'
  | 'xl:tw-bg-green-a400'
  | 'hover:tw-bg-green-a400'
  | 'focus:tw-bg-green-a400'
  | 'group-hover:tw-bg-green-a400'
  | 'tw-group'
  | 'sm:tw-bg-green-a700'
  | 'md:tw-bg-green-a700'
  | 'lg:tw-bg-green-a700'
  | 'xl:tw-bg-green-a700'
  | 'hover:tw-bg-green-a700'
  | 'focus:tw-bg-green-a700'
  | 'group-hover:tw-bg-green-a700'
  | 'tw-group'
  | 'sm:tw-bg-lightGreen-50'
  | 'md:tw-bg-lightGreen-50'
  | 'lg:tw-bg-lightGreen-50'
  | 'xl:tw-bg-lightGreen-50'
  | 'hover:tw-bg-lightGreen-50'
  | 'focus:tw-bg-lightGreen-50'
  | 'group-hover:tw-bg-lightGreen-50'
  | 'tw-group'
  | 'sm:tw-bg-lightGreen-100'
  | 'md:tw-bg-lightGreen-100'
  | 'lg:tw-bg-lightGreen-100'
  | 'xl:tw-bg-lightGreen-100'
  | 'hover:tw-bg-lightGreen-100'
  | 'focus:tw-bg-lightGreen-100'
  | 'group-hover:tw-bg-lightGreen-100'
  | 'tw-group'
  | 'sm:tw-bg-lightGreen-200'
  | 'md:tw-bg-lightGreen-200'
  | 'lg:tw-bg-lightGreen-200'
  | 'xl:tw-bg-lightGreen-200'
  | 'hover:tw-bg-lightGreen-200'
  | 'focus:tw-bg-lightGreen-200'
  | 'group-hover:tw-bg-lightGreen-200'
  | 'tw-group'
  | 'sm:tw-bg-lightGreen-300'
  | 'md:tw-bg-lightGreen-300'
  | 'lg:tw-bg-lightGreen-300'
  | 'xl:tw-bg-lightGreen-300'
  | 'hover:tw-bg-lightGreen-300'
  | 'focus:tw-bg-lightGreen-300'
  | 'group-hover:tw-bg-lightGreen-300'
  | 'tw-group'
  | 'sm:tw-bg-lightGreen-400'
  | 'md:tw-bg-lightGreen-400'
  | 'lg:tw-bg-lightGreen-400'
  | 'xl:tw-bg-lightGreen-400'
  | 'hover:tw-bg-lightGreen-400'
  | 'focus:tw-bg-lightGreen-400'
  | 'group-hover:tw-bg-lightGreen-400'
  | 'tw-group'
  | 'sm:tw-bg-lightGreen-500'
  | 'md:tw-bg-lightGreen-500'
  | 'lg:tw-bg-lightGreen-500'
  | 'xl:tw-bg-lightGreen-500'
  | 'hover:tw-bg-lightGreen-500'
  | 'focus:tw-bg-lightGreen-500'
  | 'group-hover:tw-bg-lightGreen-500'
  | 'tw-group'
  | 'sm:tw-bg-lightGreen-600'
  | 'md:tw-bg-lightGreen-600'
  | 'lg:tw-bg-lightGreen-600'
  | 'xl:tw-bg-lightGreen-600'
  | 'hover:tw-bg-lightGreen-600'
  | 'focus:tw-bg-lightGreen-600'
  | 'group-hover:tw-bg-lightGreen-600'
  | 'tw-group'
  | 'sm:tw-bg-lightGreen-700'
  | 'md:tw-bg-lightGreen-700'
  | 'lg:tw-bg-lightGreen-700'
  | 'xl:tw-bg-lightGreen-700'
  | 'hover:tw-bg-lightGreen-700'
  | 'focus:tw-bg-lightGreen-700'
  | 'group-hover:tw-bg-lightGreen-700'
  | 'tw-group'
  | 'sm:tw-bg-lightGreen-800'
  | 'md:tw-bg-lightGreen-800'
  | 'lg:tw-bg-lightGreen-800'
  | 'xl:tw-bg-lightGreen-800'
  | 'hover:tw-bg-lightGreen-800'
  | 'focus:tw-bg-lightGreen-800'
  | 'group-hover:tw-bg-lightGreen-800'
  | 'tw-group'
  | 'sm:tw-bg-lightGreen-900'
  | 'md:tw-bg-lightGreen-900'
  | 'lg:tw-bg-lightGreen-900'
  | 'xl:tw-bg-lightGreen-900'
  | 'hover:tw-bg-lightGreen-900'
  | 'focus:tw-bg-lightGreen-900'
  | 'group-hover:tw-bg-lightGreen-900'
  | 'tw-group'
  | 'sm:tw-bg-lightGreen-a100'
  | 'md:tw-bg-lightGreen-a100'
  | 'lg:tw-bg-lightGreen-a100'
  | 'xl:tw-bg-lightGreen-a100'
  | 'hover:tw-bg-lightGreen-a100'
  | 'focus:tw-bg-lightGreen-a100'
  | 'group-hover:tw-bg-lightGreen-a100'
  | 'tw-group'
  | 'sm:tw-bg-lightGreen-a200'
  | 'md:tw-bg-lightGreen-a200'
  | 'lg:tw-bg-lightGreen-a200'
  | 'xl:tw-bg-lightGreen-a200'
  | 'hover:tw-bg-lightGreen-a200'
  | 'focus:tw-bg-lightGreen-a200'
  | 'group-hover:tw-bg-lightGreen-a200'
  | 'tw-group'
  | 'sm:tw-bg-lightGreen-a400'
  | 'md:tw-bg-lightGreen-a400'
  | 'lg:tw-bg-lightGreen-a400'
  | 'xl:tw-bg-lightGreen-a400'
  | 'hover:tw-bg-lightGreen-a400'
  | 'focus:tw-bg-lightGreen-a400'
  | 'group-hover:tw-bg-lightGreen-a400'
  | 'tw-group'
  | 'sm:tw-bg-lightGreen-a700'
  | 'md:tw-bg-lightGreen-a700'
  | 'lg:tw-bg-lightGreen-a700'
  | 'xl:tw-bg-lightGreen-a700'
  | 'hover:tw-bg-lightGreen-a700'
  | 'focus:tw-bg-lightGreen-a700'
  | 'group-hover:tw-bg-lightGreen-a700'
  | 'tw-group'
  | 'sm:tw-bg-lime-50'
  | 'md:tw-bg-lime-50'
  | 'lg:tw-bg-lime-50'
  | 'xl:tw-bg-lime-50'
  | 'hover:tw-bg-lime-50'
  | 'focus:tw-bg-lime-50'
  | 'group-hover:tw-bg-lime-50'
  | 'tw-group'
  | 'sm:tw-bg-lime-100'
  | 'md:tw-bg-lime-100'
  | 'lg:tw-bg-lime-100'
  | 'xl:tw-bg-lime-100'
  | 'hover:tw-bg-lime-100'
  | 'focus:tw-bg-lime-100'
  | 'group-hover:tw-bg-lime-100'
  | 'tw-group'
  | 'sm:tw-bg-lime-200'
  | 'md:tw-bg-lime-200'
  | 'lg:tw-bg-lime-200'
  | 'xl:tw-bg-lime-200'
  | 'hover:tw-bg-lime-200'
  | 'focus:tw-bg-lime-200'
  | 'group-hover:tw-bg-lime-200'
  | 'tw-group'
  | 'sm:tw-bg-lime-300'
  | 'md:tw-bg-lime-300'
  | 'lg:tw-bg-lime-300'
  | 'xl:tw-bg-lime-300'
  | 'hover:tw-bg-lime-300'
  | 'focus:tw-bg-lime-300'
  | 'group-hover:tw-bg-lime-300'
  | 'tw-group'
  | 'sm:tw-bg-lime-400'
  | 'md:tw-bg-lime-400'
  | 'lg:tw-bg-lime-400'
  | 'xl:tw-bg-lime-400'
  | 'hover:tw-bg-lime-400'
  | 'focus:tw-bg-lime-400'
  | 'group-hover:tw-bg-lime-400'
  | 'tw-group'
  | 'sm:tw-bg-lime-500'
  | 'md:tw-bg-lime-500'
  | 'lg:tw-bg-lime-500'
  | 'xl:tw-bg-lime-500'
  | 'hover:tw-bg-lime-500'
  | 'focus:tw-bg-lime-500'
  | 'group-hover:tw-bg-lime-500'
  | 'tw-group'
  | 'sm:tw-bg-lime-600'
  | 'md:tw-bg-lime-600'
  | 'lg:tw-bg-lime-600'
  | 'xl:tw-bg-lime-600'
  | 'hover:tw-bg-lime-600'
  | 'focus:tw-bg-lime-600'
  | 'group-hover:tw-bg-lime-600'
  | 'tw-group'
  | 'sm:tw-bg-lime-700'
  | 'md:tw-bg-lime-700'
  | 'lg:tw-bg-lime-700'
  | 'xl:tw-bg-lime-700'
  | 'hover:tw-bg-lime-700'
  | 'focus:tw-bg-lime-700'
  | 'group-hover:tw-bg-lime-700'
  | 'tw-group'
  | 'sm:tw-bg-lime-800'
  | 'md:tw-bg-lime-800'
  | 'lg:tw-bg-lime-800'
  | 'xl:tw-bg-lime-800'
  | 'hover:tw-bg-lime-800'
  | 'focus:tw-bg-lime-800'
  | 'group-hover:tw-bg-lime-800'
  | 'tw-group'
  | 'sm:tw-bg-lime-900'
  | 'md:tw-bg-lime-900'
  | 'lg:tw-bg-lime-900'
  | 'xl:tw-bg-lime-900'
  | 'hover:tw-bg-lime-900'
  | 'focus:tw-bg-lime-900'
  | 'group-hover:tw-bg-lime-900'
  | 'tw-group'
  | 'sm:tw-bg-lime-a100'
  | 'md:tw-bg-lime-a100'
  | 'lg:tw-bg-lime-a100'
  | 'xl:tw-bg-lime-a100'
  | 'hover:tw-bg-lime-a100'
  | 'focus:tw-bg-lime-a100'
  | 'group-hover:tw-bg-lime-a100'
  | 'tw-group'
  | 'sm:tw-bg-lime-a200'
  | 'md:tw-bg-lime-a200'
  | 'lg:tw-bg-lime-a200'
  | 'xl:tw-bg-lime-a200'
  | 'hover:tw-bg-lime-a200'
  | 'focus:tw-bg-lime-a200'
  | 'group-hover:tw-bg-lime-a200'
  | 'tw-group'
  | 'sm:tw-bg-lime-a400'
  | 'md:tw-bg-lime-a400'
  | 'lg:tw-bg-lime-a400'
  | 'xl:tw-bg-lime-a400'
  | 'hover:tw-bg-lime-a400'
  | 'focus:tw-bg-lime-a400'
  | 'group-hover:tw-bg-lime-a400'
  | 'tw-group'
  | 'sm:tw-bg-lime-a700'
  | 'md:tw-bg-lime-a700'
  | 'lg:tw-bg-lime-a700'
  | 'xl:tw-bg-lime-a700'
  | 'hover:tw-bg-lime-a700'
  | 'focus:tw-bg-lime-a700'
  | 'group-hover:tw-bg-lime-a700'
  | 'tw-group'
  | 'sm:tw-bg-yellow-50'
  | 'md:tw-bg-yellow-50'
  | 'lg:tw-bg-yellow-50'
  | 'xl:tw-bg-yellow-50'
  | 'hover:tw-bg-yellow-50'
  | 'focus:tw-bg-yellow-50'
  | 'group-hover:tw-bg-yellow-50'
  | 'tw-group'
  | 'sm:tw-bg-yellow-100'
  | 'md:tw-bg-yellow-100'
  | 'lg:tw-bg-yellow-100'
  | 'xl:tw-bg-yellow-100'
  | 'hover:tw-bg-yellow-100'
  | 'focus:tw-bg-yellow-100'
  | 'group-hover:tw-bg-yellow-100'
  | 'tw-group'
  | 'sm:tw-bg-yellow-200'
  | 'md:tw-bg-yellow-200'
  | 'lg:tw-bg-yellow-200'
  | 'xl:tw-bg-yellow-200'
  | 'hover:tw-bg-yellow-200'
  | 'focus:tw-bg-yellow-200'
  | 'group-hover:tw-bg-yellow-200'
  | 'tw-group'
  | 'sm:tw-bg-yellow-300'
  | 'md:tw-bg-yellow-300'
  | 'lg:tw-bg-yellow-300'
  | 'xl:tw-bg-yellow-300'
  | 'hover:tw-bg-yellow-300'
  | 'focus:tw-bg-yellow-300'
  | 'group-hover:tw-bg-yellow-300'
  | 'tw-group'
  | 'sm:tw-bg-yellow-400'
  | 'md:tw-bg-yellow-400'
  | 'lg:tw-bg-yellow-400'
  | 'xl:tw-bg-yellow-400'
  | 'hover:tw-bg-yellow-400'
  | 'focus:tw-bg-yellow-400'
  | 'group-hover:tw-bg-yellow-400'
  | 'tw-group'
  | 'sm:tw-bg-yellow-500'
  | 'md:tw-bg-yellow-500'
  | 'lg:tw-bg-yellow-500'
  | 'xl:tw-bg-yellow-500'
  | 'hover:tw-bg-yellow-500'
  | 'focus:tw-bg-yellow-500'
  | 'group-hover:tw-bg-yellow-500'
  | 'tw-group'
  | 'sm:tw-bg-yellow-600'
  | 'md:tw-bg-yellow-600'
  | 'lg:tw-bg-yellow-600'
  | 'xl:tw-bg-yellow-600'
  | 'hover:tw-bg-yellow-600'
  | 'focus:tw-bg-yellow-600'
  | 'group-hover:tw-bg-yellow-600'
  | 'tw-group'
  | 'sm:tw-bg-yellow-700'
  | 'md:tw-bg-yellow-700'
  | 'lg:tw-bg-yellow-700'
  | 'xl:tw-bg-yellow-700'
  | 'hover:tw-bg-yellow-700'
  | 'focus:tw-bg-yellow-700'
  | 'group-hover:tw-bg-yellow-700'
  | 'tw-group'
  | 'sm:tw-bg-yellow-800'
  | 'md:tw-bg-yellow-800'
  | 'lg:tw-bg-yellow-800'
  | 'xl:tw-bg-yellow-800'
  | 'hover:tw-bg-yellow-800'
  | 'focus:tw-bg-yellow-800'
  | 'group-hover:tw-bg-yellow-800'
  | 'tw-group'
  | 'sm:tw-bg-yellow-900'
  | 'md:tw-bg-yellow-900'
  | 'lg:tw-bg-yellow-900'
  | 'xl:tw-bg-yellow-900'
  | 'hover:tw-bg-yellow-900'
  | 'focus:tw-bg-yellow-900'
  | 'group-hover:tw-bg-yellow-900'
  | 'tw-group'
  | 'sm:tw-bg-yellow-a100'
  | 'md:tw-bg-yellow-a100'
  | 'lg:tw-bg-yellow-a100'
  | 'xl:tw-bg-yellow-a100'
  | 'hover:tw-bg-yellow-a100'
  | 'focus:tw-bg-yellow-a100'
  | 'group-hover:tw-bg-yellow-a100'
  | 'tw-group'
  | 'sm:tw-bg-yellow-a200'
  | 'md:tw-bg-yellow-a200'
  | 'lg:tw-bg-yellow-a200'
  | 'xl:tw-bg-yellow-a200'
  | 'hover:tw-bg-yellow-a200'
  | 'focus:tw-bg-yellow-a200'
  | 'group-hover:tw-bg-yellow-a200'
  | 'tw-group'
  | 'sm:tw-bg-yellow-a400'
  | 'md:tw-bg-yellow-a400'
  | 'lg:tw-bg-yellow-a400'
  | 'xl:tw-bg-yellow-a400'
  | 'hover:tw-bg-yellow-a400'
  | 'focus:tw-bg-yellow-a400'
  | 'group-hover:tw-bg-yellow-a400'
  | 'tw-group'
  | 'sm:tw-bg-yellow-a700'
  | 'md:tw-bg-yellow-a700'
  | 'lg:tw-bg-yellow-a700'
  | 'xl:tw-bg-yellow-a700'
  | 'hover:tw-bg-yellow-a700'
  | 'focus:tw-bg-yellow-a700'
  | 'group-hover:tw-bg-yellow-a700'
  | 'tw-group'
  | 'sm:tw-bg-amber-50'
  | 'md:tw-bg-amber-50'
  | 'lg:tw-bg-amber-50'
  | 'xl:tw-bg-amber-50'
  | 'hover:tw-bg-amber-50'
  | 'focus:tw-bg-amber-50'
  | 'group-hover:tw-bg-amber-50'
  | 'tw-group'
  | 'sm:tw-bg-amber-100'
  | 'md:tw-bg-amber-100'
  | 'lg:tw-bg-amber-100'
  | 'xl:tw-bg-amber-100'
  | 'hover:tw-bg-amber-100'
  | 'focus:tw-bg-amber-100'
  | 'group-hover:tw-bg-amber-100'
  | 'tw-group'
  | 'sm:tw-bg-amber-200'
  | 'md:tw-bg-amber-200'
  | 'lg:tw-bg-amber-200'
  | 'xl:tw-bg-amber-200'
  | 'hover:tw-bg-amber-200'
  | 'focus:tw-bg-amber-200'
  | 'group-hover:tw-bg-amber-200'
  | 'tw-group'
  | 'sm:tw-bg-amber-300'
  | 'md:tw-bg-amber-300'
  | 'lg:tw-bg-amber-300'
  | 'xl:tw-bg-amber-300'
  | 'hover:tw-bg-amber-300'
  | 'focus:tw-bg-amber-300'
  | 'group-hover:tw-bg-amber-300'
  | 'tw-group'
  | 'sm:tw-bg-amber-400'
  | 'md:tw-bg-amber-400'
  | 'lg:tw-bg-amber-400'
  | 'xl:tw-bg-amber-400'
  | 'hover:tw-bg-amber-400'
  | 'focus:tw-bg-amber-400'
  | 'group-hover:tw-bg-amber-400'
  | 'tw-group'
  | 'sm:tw-bg-amber-500'
  | 'md:tw-bg-amber-500'
  | 'lg:tw-bg-amber-500'
  | 'xl:tw-bg-amber-500'
  | 'hover:tw-bg-amber-500'
  | 'focus:tw-bg-amber-500'
  | 'group-hover:tw-bg-amber-500'
  | 'tw-group'
  | 'sm:tw-bg-amber-600'
  | 'md:tw-bg-amber-600'
  | 'lg:tw-bg-amber-600'
  | 'xl:tw-bg-amber-600'
  | 'hover:tw-bg-amber-600'
  | 'focus:tw-bg-amber-600'
  | 'group-hover:tw-bg-amber-600'
  | 'tw-group'
  | 'sm:tw-bg-amber-700'
  | 'md:tw-bg-amber-700'
  | 'lg:tw-bg-amber-700'
  | 'xl:tw-bg-amber-700'
  | 'hover:tw-bg-amber-700'
  | 'focus:tw-bg-amber-700'
  | 'group-hover:tw-bg-amber-700'
  | 'tw-group'
  | 'sm:tw-bg-amber-800'
  | 'md:tw-bg-amber-800'
  | 'lg:tw-bg-amber-800'
  | 'xl:tw-bg-amber-800'
  | 'hover:tw-bg-amber-800'
  | 'focus:tw-bg-amber-800'
  | 'group-hover:tw-bg-amber-800'
  | 'tw-group'
  | 'sm:tw-bg-amber-900'
  | 'md:tw-bg-amber-900'
  | 'lg:tw-bg-amber-900'
  | 'xl:tw-bg-amber-900'
  | 'hover:tw-bg-amber-900'
  | 'focus:tw-bg-amber-900'
  | 'group-hover:tw-bg-amber-900'
  | 'tw-group'
  | 'sm:tw-bg-amber-a100'
  | 'md:tw-bg-amber-a100'
  | 'lg:tw-bg-amber-a100'
  | 'xl:tw-bg-amber-a100'
  | 'hover:tw-bg-amber-a100'
  | 'focus:tw-bg-amber-a100'
  | 'group-hover:tw-bg-amber-a100'
  | 'tw-group'
  | 'sm:tw-bg-amber-a200'
  | 'md:tw-bg-amber-a200'
  | 'lg:tw-bg-amber-a200'
  | 'xl:tw-bg-amber-a200'
  | 'hover:tw-bg-amber-a200'
  | 'focus:tw-bg-amber-a200'
  | 'group-hover:tw-bg-amber-a200'
  | 'tw-group'
  | 'sm:tw-bg-amber-a400'
  | 'md:tw-bg-amber-a400'
  | 'lg:tw-bg-amber-a400'
  | 'xl:tw-bg-amber-a400'
  | 'hover:tw-bg-amber-a400'
  | 'focus:tw-bg-amber-a400'
  | 'group-hover:tw-bg-amber-a400'
  | 'tw-group'
  | 'sm:tw-bg-amber-a700'
  | 'md:tw-bg-amber-a700'
  | 'lg:tw-bg-amber-a700'
  | 'xl:tw-bg-amber-a700'
  | 'hover:tw-bg-amber-a700'
  | 'focus:tw-bg-amber-a700'
  | 'group-hover:tw-bg-amber-a700'
  | 'tw-group'
  | 'sm:tw-bg-orange-50'
  | 'md:tw-bg-orange-50'
  | 'lg:tw-bg-orange-50'
  | 'xl:tw-bg-orange-50'
  | 'hover:tw-bg-orange-50'
  | 'focus:tw-bg-orange-50'
  | 'group-hover:tw-bg-orange-50'
  | 'tw-group'
  | 'sm:tw-bg-orange-100'
  | 'md:tw-bg-orange-100'
  | 'lg:tw-bg-orange-100'
  | 'xl:tw-bg-orange-100'
  | 'hover:tw-bg-orange-100'
  | 'focus:tw-bg-orange-100'
  | 'group-hover:tw-bg-orange-100'
  | 'tw-group'
  | 'sm:tw-bg-orange-200'
  | 'md:tw-bg-orange-200'
  | 'lg:tw-bg-orange-200'
  | 'xl:tw-bg-orange-200'
  | 'hover:tw-bg-orange-200'
  | 'focus:tw-bg-orange-200'
  | 'group-hover:tw-bg-orange-200'
  | 'tw-group'
  | 'sm:tw-bg-orange-300'
  | 'md:tw-bg-orange-300'
  | 'lg:tw-bg-orange-300'
  | 'xl:tw-bg-orange-300'
  | 'hover:tw-bg-orange-300'
  | 'focus:tw-bg-orange-300'
  | 'group-hover:tw-bg-orange-300'
  | 'tw-group'
  | 'sm:tw-bg-orange-400'
  | 'md:tw-bg-orange-400'
  | 'lg:tw-bg-orange-400'
  | 'xl:tw-bg-orange-400'
  | 'hover:tw-bg-orange-400'
  | 'focus:tw-bg-orange-400'
  | 'group-hover:tw-bg-orange-400'
  | 'tw-group'
  | 'sm:tw-bg-orange-500'
  | 'md:tw-bg-orange-500'
  | 'lg:tw-bg-orange-500'
  | 'xl:tw-bg-orange-500'
  | 'hover:tw-bg-orange-500'
  | 'focus:tw-bg-orange-500'
  | 'group-hover:tw-bg-orange-500'
  | 'tw-group'
  | 'sm:tw-bg-orange-600'
  | 'md:tw-bg-orange-600'
  | 'lg:tw-bg-orange-600'
  | 'xl:tw-bg-orange-600'
  | 'hover:tw-bg-orange-600'
  | 'focus:tw-bg-orange-600'
  | 'group-hover:tw-bg-orange-600'
  | 'tw-group'
  | 'sm:tw-bg-orange-700'
  | 'md:tw-bg-orange-700'
  | 'lg:tw-bg-orange-700'
  | 'xl:tw-bg-orange-700'
  | 'hover:tw-bg-orange-700'
  | 'focus:tw-bg-orange-700'
  | 'group-hover:tw-bg-orange-700'
  | 'tw-group'
  | 'sm:tw-bg-orange-800'
  | 'md:tw-bg-orange-800'
  | 'lg:tw-bg-orange-800'
  | 'xl:tw-bg-orange-800'
  | 'hover:tw-bg-orange-800'
  | 'focus:tw-bg-orange-800'
  | 'group-hover:tw-bg-orange-800'
  | 'tw-group'
  | 'sm:tw-bg-orange-900'
  | 'md:tw-bg-orange-900'
  | 'lg:tw-bg-orange-900'
  | 'xl:tw-bg-orange-900'
  | 'hover:tw-bg-orange-900'
  | 'focus:tw-bg-orange-900'
  | 'group-hover:tw-bg-orange-900'
  | 'tw-group'
  | 'sm:tw-bg-orange-a100'
  | 'md:tw-bg-orange-a100'
  | 'lg:tw-bg-orange-a100'
  | 'xl:tw-bg-orange-a100'
  | 'hover:tw-bg-orange-a100'
  | 'focus:tw-bg-orange-a100'
  | 'group-hover:tw-bg-orange-a100'
  | 'tw-group'
  | 'sm:tw-bg-orange-a200'
  | 'md:tw-bg-orange-a200'
  | 'lg:tw-bg-orange-a200'
  | 'xl:tw-bg-orange-a200'
  | 'hover:tw-bg-orange-a200'
  | 'focus:tw-bg-orange-a200'
  | 'group-hover:tw-bg-orange-a200'
  | 'tw-group'
  | 'sm:tw-bg-orange-a400'
  | 'md:tw-bg-orange-a400'
  | 'lg:tw-bg-orange-a400'
  | 'xl:tw-bg-orange-a400'
  | 'hover:tw-bg-orange-a400'
  | 'focus:tw-bg-orange-a400'
  | 'group-hover:tw-bg-orange-a400'
  | 'tw-group'
  | 'sm:tw-bg-orange-a700'
  | 'md:tw-bg-orange-a700'
  | 'lg:tw-bg-orange-a700'
  | 'xl:tw-bg-orange-a700'
  | 'hover:tw-bg-orange-a700'
  | 'focus:tw-bg-orange-a700'
  | 'group-hover:tw-bg-orange-a700'
  | 'tw-group'
  | 'sm:tw-bg-deepOrange-50'
  | 'md:tw-bg-deepOrange-50'
  | 'lg:tw-bg-deepOrange-50'
  | 'xl:tw-bg-deepOrange-50'
  | 'hover:tw-bg-deepOrange-50'
  | 'focus:tw-bg-deepOrange-50'
  | 'group-hover:tw-bg-deepOrange-50'
  | 'tw-group'
  | 'sm:tw-bg-deepOrange-100'
  | 'md:tw-bg-deepOrange-100'
  | 'lg:tw-bg-deepOrange-100'
  | 'xl:tw-bg-deepOrange-100'
  | 'hover:tw-bg-deepOrange-100'
  | 'focus:tw-bg-deepOrange-100'
  | 'group-hover:tw-bg-deepOrange-100'
  | 'tw-group'
  | 'sm:tw-bg-deepOrange-200'
  | 'md:tw-bg-deepOrange-200'
  | 'lg:tw-bg-deepOrange-200'
  | 'xl:tw-bg-deepOrange-200'
  | 'hover:tw-bg-deepOrange-200'
  | 'focus:tw-bg-deepOrange-200'
  | 'group-hover:tw-bg-deepOrange-200'
  | 'tw-group'
  | 'sm:tw-bg-deepOrange-300'
  | 'md:tw-bg-deepOrange-300'
  | 'lg:tw-bg-deepOrange-300'
  | 'xl:tw-bg-deepOrange-300'
  | 'hover:tw-bg-deepOrange-300'
  | 'focus:tw-bg-deepOrange-300'
  | 'group-hover:tw-bg-deepOrange-300'
  | 'tw-group'
  | 'sm:tw-bg-deepOrange-400'
  | 'md:tw-bg-deepOrange-400'
  | 'lg:tw-bg-deepOrange-400'
  | 'xl:tw-bg-deepOrange-400'
  | 'hover:tw-bg-deepOrange-400'
  | 'focus:tw-bg-deepOrange-400'
  | 'group-hover:tw-bg-deepOrange-400'
  | 'tw-group'
  | 'sm:tw-bg-deepOrange-500'
  | 'md:tw-bg-deepOrange-500'
  | 'lg:tw-bg-deepOrange-500'
  | 'xl:tw-bg-deepOrange-500'
  | 'hover:tw-bg-deepOrange-500'
  | 'focus:tw-bg-deepOrange-500'
  | 'group-hover:tw-bg-deepOrange-500'
  | 'tw-group'
  | 'sm:tw-bg-deepOrange-600'
  | 'md:tw-bg-deepOrange-600'
  | 'lg:tw-bg-deepOrange-600'
  | 'xl:tw-bg-deepOrange-600'
  | 'hover:tw-bg-deepOrange-600'
  | 'focus:tw-bg-deepOrange-600'
  | 'group-hover:tw-bg-deepOrange-600'
  | 'tw-group'
  | 'sm:tw-bg-deepOrange-700'
  | 'md:tw-bg-deepOrange-700'
  | 'lg:tw-bg-deepOrange-700'
  | 'xl:tw-bg-deepOrange-700'
  | 'hover:tw-bg-deepOrange-700'
  | 'focus:tw-bg-deepOrange-700'
  | 'group-hover:tw-bg-deepOrange-700'
  | 'tw-group'
  | 'sm:tw-bg-deepOrange-800'
  | 'md:tw-bg-deepOrange-800'
  | 'lg:tw-bg-deepOrange-800'
  | 'xl:tw-bg-deepOrange-800'
  | 'hover:tw-bg-deepOrange-800'
  | 'focus:tw-bg-deepOrange-800'
  | 'group-hover:tw-bg-deepOrange-800'
  | 'tw-group'
  | 'sm:tw-bg-deepOrange-900'
  | 'md:tw-bg-deepOrange-900'
  | 'lg:tw-bg-deepOrange-900'
  | 'xl:tw-bg-deepOrange-900'
  | 'hover:tw-bg-deepOrange-900'
  | 'focus:tw-bg-deepOrange-900'
  | 'group-hover:tw-bg-deepOrange-900'
  | 'tw-group'
  | 'sm:tw-bg-deepOrange-a100'
  | 'md:tw-bg-deepOrange-a100'
  | 'lg:tw-bg-deepOrange-a100'
  | 'xl:tw-bg-deepOrange-a100'
  | 'hover:tw-bg-deepOrange-a100'
  | 'focus:tw-bg-deepOrange-a100'
  | 'group-hover:tw-bg-deepOrange-a100'
  | 'tw-group'
  | 'sm:tw-bg-deepOrange-a200'
  | 'md:tw-bg-deepOrange-a200'
  | 'lg:tw-bg-deepOrange-a200'
  | 'xl:tw-bg-deepOrange-a200'
  | 'hover:tw-bg-deepOrange-a200'
  | 'focus:tw-bg-deepOrange-a200'
  | 'group-hover:tw-bg-deepOrange-a200'
  | 'tw-group'
  | 'sm:tw-bg-deepOrange-a400'
  | 'md:tw-bg-deepOrange-a400'
  | 'lg:tw-bg-deepOrange-a400'
  | 'xl:tw-bg-deepOrange-a400'
  | 'hover:tw-bg-deepOrange-a400'
  | 'focus:tw-bg-deepOrange-a400'
  | 'group-hover:tw-bg-deepOrange-a400'
  | 'tw-group'
  | 'sm:tw-bg-deepOrange-a700'
  | 'md:tw-bg-deepOrange-a700'
  | 'lg:tw-bg-deepOrange-a700'
  | 'xl:tw-bg-deepOrange-a700'
  | 'hover:tw-bg-deepOrange-a700'
  | 'focus:tw-bg-deepOrange-a700'
  | 'group-hover:tw-bg-deepOrange-a700'
  | 'tw-group'
  | 'sm:tw-bg-brown-50'
  | 'md:tw-bg-brown-50'
  | 'lg:tw-bg-brown-50'
  | 'xl:tw-bg-brown-50'
  | 'hover:tw-bg-brown-50'
  | 'focus:tw-bg-brown-50'
  | 'group-hover:tw-bg-brown-50'
  | 'tw-group'
  | 'sm:tw-bg-brown-100'
  | 'md:tw-bg-brown-100'
  | 'lg:tw-bg-brown-100'
  | 'xl:tw-bg-brown-100'
  | 'hover:tw-bg-brown-100'
  | 'focus:tw-bg-brown-100'
  | 'group-hover:tw-bg-brown-100'
  | 'tw-group'
  | 'sm:tw-bg-brown-200'
  | 'md:tw-bg-brown-200'
  | 'lg:tw-bg-brown-200'
  | 'xl:tw-bg-brown-200'
  | 'hover:tw-bg-brown-200'
  | 'focus:tw-bg-brown-200'
  | 'group-hover:tw-bg-brown-200'
  | 'tw-group'
  | 'sm:tw-bg-brown-300'
  | 'md:tw-bg-brown-300'
  | 'lg:tw-bg-brown-300'
  | 'xl:tw-bg-brown-300'
  | 'hover:tw-bg-brown-300'
  | 'focus:tw-bg-brown-300'
  | 'group-hover:tw-bg-brown-300'
  | 'tw-group'
  | 'sm:tw-bg-brown-400'
  | 'md:tw-bg-brown-400'
  | 'lg:tw-bg-brown-400'
  | 'xl:tw-bg-brown-400'
  | 'hover:tw-bg-brown-400'
  | 'focus:tw-bg-brown-400'
  | 'group-hover:tw-bg-brown-400'
  | 'tw-group'
  | 'sm:tw-bg-brown-500'
  | 'md:tw-bg-brown-500'
  | 'lg:tw-bg-brown-500'
  | 'xl:tw-bg-brown-500'
  | 'hover:tw-bg-brown-500'
  | 'focus:tw-bg-brown-500'
  | 'group-hover:tw-bg-brown-500'
  | 'tw-group'
  | 'sm:tw-bg-brown-600'
  | 'md:tw-bg-brown-600'
  | 'lg:tw-bg-brown-600'
  | 'xl:tw-bg-brown-600'
  | 'hover:tw-bg-brown-600'
  | 'focus:tw-bg-brown-600'
  | 'group-hover:tw-bg-brown-600'
  | 'tw-group'
  | 'sm:tw-bg-brown-700'
  | 'md:tw-bg-brown-700'
  | 'lg:tw-bg-brown-700'
  | 'xl:tw-bg-brown-700'
  | 'hover:tw-bg-brown-700'
  | 'focus:tw-bg-brown-700'
  | 'group-hover:tw-bg-brown-700'
  | 'tw-group'
  | 'sm:tw-bg-brown-800'
  | 'md:tw-bg-brown-800'
  | 'lg:tw-bg-brown-800'
  | 'xl:tw-bg-brown-800'
  | 'hover:tw-bg-brown-800'
  | 'focus:tw-bg-brown-800'
  | 'group-hover:tw-bg-brown-800'
  | 'tw-group'
  | 'sm:tw-bg-brown-900'
  | 'md:tw-bg-brown-900'
  | 'lg:tw-bg-brown-900'
  | 'xl:tw-bg-brown-900'
  | 'hover:tw-bg-brown-900'
  | 'focus:tw-bg-brown-900'
  | 'group-hover:tw-bg-brown-900'
  | 'tw-group'
  | 'sm:tw-bg-gray-50'
  | 'md:tw-bg-gray-50'
  | 'lg:tw-bg-gray-50'
  | 'xl:tw-bg-gray-50'
  | 'hover:tw-bg-gray-50'
  | 'focus:tw-bg-gray-50'
  | 'group-hover:tw-bg-gray-50'
  | 'tw-group'
  | 'sm:tw-bg-gray-100'
  | 'md:tw-bg-gray-100'
  | 'lg:tw-bg-gray-100'
  | 'xl:tw-bg-gray-100'
  | 'hover:tw-bg-gray-100'
  | 'focus:tw-bg-gray-100'
  | 'group-hover:tw-bg-gray-100'
  | 'tw-group'
  | 'sm:tw-bg-gray-200'
  | 'md:tw-bg-gray-200'
  | 'lg:tw-bg-gray-200'
  | 'xl:tw-bg-gray-200'
  | 'hover:tw-bg-gray-200'
  | 'focus:tw-bg-gray-200'
  | 'group-hover:tw-bg-gray-200'
  | 'tw-group'
  | 'sm:tw-bg-gray-300'
  | 'md:tw-bg-gray-300'
  | 'lg:tw-bg-gray-300'
  | 'xl:tw-bg-gray-300'
  | 'hover:tw-bg-gray-300'
  | 'focus:tw-bg-gray-300'
  | 'group-hover:tw-bg-gray-300'
  | 'tw-group'
  | 'sm:tw-bg-gray-400'
  | 'md:tw-bg-gray-400'
  | 'lg:tw-bg-gray-400'
  | 'xl:tw-bg-gray-400'
  | 'hover:tw-bg-gray-400'
  | 'focus:tw-bg-gray-400'
  | 'group-hover:tw-bg-gray-400'
  | 'tw-group'
  | 'sm:tw-bg-gray-500'
  | 'md:tw-bg-gray-500'
  | 'lg:tw-bg-gray-500'
  | 'xl:tw-bg-gray-500'
  | 'hover:tw-bg-gray-500'
  | 'focus:tw-bg-gray-500'
  | 'group-hover:tw-bg-gray-500'
  | 'tw-group'
  | 'sm:tw-bg-gray-600'
  | 'md:tw-bg-gray-600'
  | 'lg:tw-bg-gray-600'
  | 'xl:tw-bg-gray-600'
  | 'hover:tw-bg-gray-600'
  | 'focus:tw-bg-gray-600'
  | 'group-hover:tw-bg-gray-600'
  | 'tw-group'
  | 'sm:tw-bg-gray-700'
  | 'md:tw-bg-gray-700'
  | 'lg:tw-bg-gray-700'
  | 'xl:tw-bg-gray-700'
  | 'hover:tw-bg-gray-700'
  | 'focus:tw-bg-gray-700'
  | 'group-hover:tw-bg-gray-700'
  | 'tw-group'
  | 'sm:tw-bg-gray-800'
  | 'md:tw-bg-gray-800'
  | 'lg:tw-bg-gray-800'
  | 'xl:tw-bg-gray-800'
  | 'hover:tw-bg-gray-800'
  | 'focus:tw-bg-gray-800'
  | 'group-hover:tw-bg-gray-800'
  | 'tw-group'
  | 'sm:tw-bg-gray-900'
  | 'md:tw-bg-gray-900'
  | 'lg:tw-bg-gray-900'
  | 'xl:tw-bg-gray-900'
  | 'hover:tw-bg-gray-900'
  | 'focus:tw-bg-gray-900'
  | 'group-hover:tw-bg-gray-900'
  | 'tw-group'
  | 'sm:tw-bg-blueGray-50'
  | 'md:tw-bg-blueGray-50'
  | 'lg:tw-bg-blueGray-50'
  | 'xl:tw-bg-blueGray-50'
  | 'hover:tw-bg-blueGray-50'
  | 'focus:tw-bg-blueGray-50'
  | 'group-hover:tw-bg-blueGray-50'
  | 'tw-group'
  | 'sm:tw-bg-blueGray-100'
  | 'md:tw-bg-blueGray-100'
  | 'lg:tw-bg-blueGray-100'
  | 'xl:tw-bg-blueGray-100'
  | 'hover:tw-bg-blueGray-100'
  | 'focus:tw-bg-blueGray-100'
  | 'group-hover:tw-bg-blueGray-100'
  | 'tw-group'
  | 'sm:tw-bg-blueGray-200'
  | 'md:tw-bg-blueGray-200'
  | 'lg:tw-bg-blueGray-200'
  | 'xl:tw-bg-blueGray-200'
  | 'hover:tw-bg-blueGray-200'
  | 'focus:tw-bg-blueGray-200'
  | 'group-hover:tw-bg-blueGray-200'
  | 'tw-group'
  | 'sm:tw-bg-blueGray-300'
  | 'md:tw-bg-blueGray-300'
  | 'lg:tw-bg-blueGray-300'
  | 'xl:tw-bg-blueGray-300'
  | 'hover:tw-bg-blueGray-300'
  | 'focus:tw-bg-blueGray-300'
  | 'group-hover:tw-bg-blueGray-300'
  | 'tw-group'
  | 'sm:tw-bg-blueGray-400'
  | 'md:tw-bg-blueGray-400'
  | 'lg:tw-bg-blueGray-400'
  | 'xl:tw-bg-blueGray-400'
  | 'hover:tw-bg-blueGray-400'
  | 'focus:tw-bg-blueGray-400'
  | 'group-hover:tw-bg-blueGray-400'
  | 'tw-group'
  | 'sm:tw-bg-blueGray-500'
  | 'md:tw-bg-blueGray-500'
  | 'lg:tw-bg-blueGray-500'
  | 'xl:tw-bg-blueGray-500'
  | 'hover:tw-bg-blueGray-500'
  | 'focus:tw-bg-blueGray-500'
  | 'group-hover:tw-bg-blueGray-500'
  | 'tw-group'
  | 'sm:tw-bg-blueGray-600'
  | 'md:tw-bg-blueGray-600'
  | 'lg:tw-bg-blueGray-600'
  | 'xl:tw-bg-blueGray-600'
  | 'hover:tw-bg-blueGray-600'
  | 'focus:tw-bg-blueGray-600'
  | 'group-hover:tw-bg-blueGray-600'
  | 'tw-group'
  | 'sm:tw-bg-blueGray-700'
  | 'md:tw-bg-blueGray-700'
  | 'lg:tw-bg-blueGray-700'
  | 'xl:tw-bg-blueGray-700'
  | 'hover:tw-bg-blueGray-700'
  | 'focus:tw-bg-blueGray-700'
  | 'group-hover:tw-bg-blueGray-700'
  | 'tw-group'
  | 'sm:tw-bg-blueGray-800'
  | 'md:tw-bg-blueGray-800'
  | 'lg:tw-bg-blueGray-800'
  | 'xl:tw-bg-blueGray-800'
  | 'hover:tw-bg-blueGray-800'
  | 'focus:tw-bg-blueGray-800'
  | 'group-hover:tw-bg-blueGray-800'
  | 'tw-group'
  | 'sm:tw-bg-blueGray-900'
  | 'md:tw-bg-blueGray-900'
  | 'lg:tw-bg-blueGray-900'
  | 'xl:tw-bg-blueGray-900'
  | 'hover:tw-bg-blueGray-900'
  | 'focus:tw-bg-blueGray-900'
  | 'group-hover:tw-bg-blueGray-900'
  | 'tw-group'
  | 'sm:tw-bg-black'
  | 'md:tw-bg-black'
  | 'lg:tw-bg-black'
  | 'xl:tw-bg-black'
  | 'hover:tw-bg-black'
  | 'focus:tw-bg-black'
  | 'group-hover:tw-bg-black'
  | 'tw-group'
  | 'sm:tw-bg-white'
  | 'md:tw-bg-white'
  | 'lg:tw-bg-white'
  | 'xl:tw-bg-white'
  | 'hover:tw-bg-white'
  | 'focus:tw-bg-white'
  | 'group-hover:tw-bg-white'
  | 'tw-group'
  | 'sm:tw-bg-transparent'
  | 'md:tw-bg-transparent'
  | 'lg:tw-bg-transparent'
  | 'xl:tw-bg-transparent'
  | 'hover:tw-bg-transparent'
  | 'focus:tw-bg-transparent'
  | 'group-hover:tw-bg-transparent'
  | 'tw-group'
  | 'sm:tw-bg-none'
  | 'md:tw-bg-none'
  | 'lg:tw-bg-none'
  | 'xl:tw-bg-none'
  | 'sm:tw-bg-gradient-to-t'
  | 'md:tw-bg-gradient-to-t'
  | 'lg:tw-bg-gradient-to-t'
  | 'xl:tw-bg-gradient-to-t'
  | 'sm:tw-bg-gradient-to-tr'
  | 'md:tw-bg-gradient-to-tr'
  | 'lg:tw-bg-gradient-to-tr'
  | 'xl:tw-bg-gradient-to-tr'
  | 'sm:tw-bg-gradient-to-r'
  | 'md:tw-bg-gradient-to-r'
  | 'lg:tw-bg-gradient-to-r'
  | 'xl:tw-bg-gradient-to-r'
  | 'sm:tw-bg-gradient-to-br'
  | 'md:tw-bg-gradient-to-br'
  | 'lg:tw-bg-gradient-to-br'
  | 'xl:tw-bg-gradient-to-br'
  | 'sm:tw-bg-gradient-to-b'
  | 'md:tw-bg-gradient-to-b'
  | 'lg:tw-bg-gradient-to-b'
  | 'xl:tw-bg-gradient-to-b'
  | 'sm:tw-bg-gradient-to-bl'
  | 'md:tw-bg-gradient-to-bl'
  | 'lg:tw-bg-gradient-to-bl'
  | 'xl:tw-bg-gradient-to-bl'
  | 'sm:tw-bg-gradient-to-l'
  | 'md:tw-bg-gradient-to-l'
  | 'lg:tw-bg-gradient-to-l'
  | 'xl:tw-bg-gradient-to-l'
  | 'sm:tw-bg-gradient-to-tl'
  | 'md:tw-bg-gradient-to-tl'
  | 'lg:tw-bg-gradient-to-tl'
  | 'xl:tw-bg-gradient-to-tl'
  | 'sm:tw-from-primary'
  | 'md:tw-from-primary'
  | 'lg:tw-from-primary'
  | 'xl:tw-from-primary'
  | 'hover:tw-from-primary'
  | 'focus:tw-from-primary'
  | 'sm:tw-via-primary'
  | 'md:tw-via-primary'
  | 'lg:tw-via-primary'
  | 'xl:tw-via-primary'
  | 'hover:tw-via-primary'
  | 'focus:tw-via-primary'
  | 'sm:tw-to-primary'
  | 'md:tw-to-primary'
  | 'lg:tw-to-primary'
  | 'xl:tw-to-primary'
  | 'hover:tw-to-primary'
  | 'focus:tw-to-primary'
  | 'sm:tw-from-muted'
  | 'md:tw-from-muted'
  | 'lg:tw-from-muted'
  | 'xl:tw-from-muted'
  | 'hover:tw-from-muted'
  | 'focus:tw-from-muted'
  | 'sm:tw-via-muted'
  | 'md:tw-via-muted'
  | 'lg:tw-via-muted'
  | 'xl:tw-via-muted'
  | 'hover:tw-via-muted'
  | 'focus:tw-via-muted'
  | 'sm:tw-to-muted'
  | 'md:tw-to-muted'
  | 'lg:tw-to-muted'
  | 'xl:tw-to-muted'
  | 'hover:tw-to-muted'
  | 'focus:tw-to-muted'
  | 'sm:tw-from-warning'
  | 'md:tw-from-warning'
  | 'lg:tw-from-warning'
  | 'xl:tw-from-warning'
  | 'hover:tw-from-warning'
  | 'focus:tw-from-warning'
  | 'sm:tw-via-warning'
  | 'md:tw-via-warning'
  | 'lg:tw-via-warning'
  | 'xl:tw-via-warning'
  | 'hover:tw-via-warning'
  | 'focus:tw-via-warning'
  | 'sm:tw-to-warning'
  | 'md:tw-to-warning'
  | 'lg:tw-to-warning'
  | 'xl:tw-to-warning'
  | 'hover:tw-to-warning'
  | 'focus:tw-to-warning'
  | 'sm:tw-from-danger'
  | 'md:tw-from-danger'
  | 'lg:tw-from-danger'
  | 'xl:tw-from-danger'
  | 'hover:tw-from-danger'
  | 'focus:tw-from-danger'
  | 'sm:tw-via-danger'
  | 'md:tw-via-danger'
  | 'lg:tw-via-danger'
  | 'xl:tw-via-danger'
  | 'hover:tw-via-danger'
  | 'focus:tw-via-danger'
  | 'sm:tw-to-danger'
  | 'md:tw-to-danger'
  | 'lg:tw-to-danger'
  | 'xl:tw-to-danger'
  | 'hover:tw-to-danger'
  | 'focus:tw-to-danger'
  | 'sm:tw-from-fail'
  | 'md:tw-from-fail'
  | 'lg:tw-from-fail'
  | 'xl:tw-from-fail'
  | 'hover:tw-from-fail'
  | 'focus:tw-from-fail'
  | 'sm:tw-via-fail'
  | 'md:tw-via-fail'
  | 'lg:tw-via-fail'
  | 'xl:tw-via-fail'
  | 'hover:tw-via-fail'
  | 'focus:tw-via-fail'
  | 'sm:tw-to-fail'
  | 'md:tw-to-fail'
  | 'lg:tw-to-fail'
  | 'xl:tw-to-fail'
  | 'hover:tw-to-fail'
  | 'focus:tw-to-fail'
  | 'sm:tw-from-info'
  | 'md:tw-from-info'
  | 'lg:tw-from-info'
  | 'xl:tw-from-info'
  | 'hover:tw-from-info'
  | 'focus:tw-from-info'
  | 'sm:tw-via-info'
  | 'md:tw-via-info'
  | 'lg:tw-via-info'
  | 'xl:tw-via-info'
  | 'hover:tw-via-info'
  | 'focus:tw-via-info'
  | 'sm:tw-to-info'
  | 'md:tw-to-info'
  | 'lg:tw-to-info'
  | 'xl:tw-to-info'
  | 'hover:tw-to-info'
  | 'focus:tw-to-info'
  | 'sm:tw-from-red-50'
  | 'md:tw-from-red-50'
  | 'lg:tw-from-red-50'
  | 'xl:tw-from-red-50'
  | 'hover:tw-from-red-50'
  | 'focus:tw-from-red-50'
  | 'sm:tw-via-red-50'
  | 'md:tw-via-red-50'
  | 'lg:tw-via-red-50'
  | 'xl:tw-via-red-50'
  | 'hover:tw-via-red-50'
  | 'focus:tw-via-red-50'
  | 'sm:tw-to-red-50'
  | 'md:tw-to-red-50'
  | 'lg:tw-to-red-50'
  | 'xl:tw-to-red-50'
  | 'hover:tw-to-red-50'
  | 'focus:tw-to-red-50'
  | 'sm:tw-from-red-100'
  | 'md:tw-from-red-100'
  | 'lg:tw-from-red-100'
  | 'xl:tw-from-red-100'
  | 'hover:tw-from-red-100'
  | 'focus:tw-from-red-100'
  | 'sm:tw-via-red-100'
  | 'md:tw-via-red-100'
  | 'lg:tw-via-red-100'
  | 'xl:tw-via-red-100'
  | 'hover:tw-via-red-100'
  | 'focus:tw-via-red-100'
  | 'sm:tw-to-red-100'
  | 'md:tw-to-red-100'
  | 'lg:tw-to-red-100'
  | 'xl:tw-to-red-100'
  | 'hover:tw-to-red-100'
  | 'focus:tw-to-red-100'
  | 'sm:tw-from-red-200'
  | 'md:tw-from-red-200'
  | 'lg:tw-from-red-200'
  | 'xl:tw-from-red-200'
  | 'hover:tw-from-red-200'
  | 'focus:tw-from-red-200'
  | 'sm:tw-via-red-200'
  | 'md:tw-via-red-200'
  | 'lg:tw-via-red-200'
  | 'xl:tw-via-red-200'
  | 'hover:tw-via-red-200'
  | 'focus:tw-via-red-200'
  | 'sm:tw-to-red-200'
  | 'md:tw-to-red-200'
  | 'lg:tw-to-red-200'
  | 'xl:tw-to-red-200'
  | 'hover:tw-to-red-200'
  | 'focus:tw-to-red-200'
  | 'sm:tw-from-red-300'
  | 'md:tw-from-red-300'
  | 'lg:tw-from-red-300'
  | 'xl:tw-from-red-300'
  | 'hover:tw-from-red-300'
  | 'focus:tw-from-red-300'
  | 'sm:tw-via-red-300'
  | 'md:tw-via-red-300'
  | 'lg:tw-via-red-300'
  | 'xl:tw-via-red-300'
  | 'hover:tw-via-red-300'
  | 'focus:tw-via-red-300'
  | 'sm:tw-to-red-300'
  | 'md:tw-to-red-300'
  | 'lg:tw-to-red-300'
  | 'xl:tw-to-red-300'
  | 'hover:tw-to-red-300'
  | 'focus:tw-to-red-300'
  | 'sm:tw-from-red-400'
  | 'md:tw-from-red-400'
  | 'lg:tw-from-red-400'
  | 'xl:tw-from-red-400'
  | 'hover:tw-from-red-400'
  | 'focus:tw-from-red-400'
  | 'sm:tw-via-red-400'
  | 'md:tw-via-red-400'
  | 'lg:tw-via-red-400'
  | 'xl:tw-via-red-400'
  | 'hover:tw-via-red-400'
  | 'focus:tw-via-red-400'
  | 'sm:tw-to-red-400'
  | 'md:tw-to-red-400'
  | 'lg:tw-to-red-400'
  | 'xl:tw-to-red-400'
  | 'hover:tw-to-red-400'
  | 'focus:tw-to-red-400'
  | 'sm:tw-from-red-500'
  | 'md:tw-from-red-500'
  | 'lg:tw-from-red-500'
  | 'xl:tw-from-red-500'
  | 'hover:tw-from-red-500'
  | 'focus:tw-from-red-500'
  | 'sm:tw-via-red-500'
  | 'md:tw-via-red-500'
  | 'lg:tw-via-red-500'
  | 'xl:tw-via-red-500'
  | 'hover:tw-via-red-500'
  | 'focus:tw-via-red-500'
  | 'sm:tw-to-red-500'
  | 'md:tw-to-red-500'
  | 'lg:tw-to-red-500'
  | 'xl:tw-to-red-500'
  | 'hover:tw-to-red-500'
  | 'focus:tw-to-red-500'
  | 'sm:tw-from-red-600'
  | 'md:tw-from-red-600'
  | 'lg:tw-from-red-600'
  | 'xl:tw-from-red-600'
  | 'hover:tw-from-red-600'
  | 'focus:tw-from-red-600'
  | 'sm:tw-via-red-600'
  | 'md:tw-via-red-600'
  | 'lg:tw-via-red-600'
  | 'xl:tw-via-red-600'
  | 'hover:tw-via-red-600'
  | 'focus:tw-via-red-600'
  | 'sm:tw-to-red-600'
  | 'md:tw-to-red-600'
  | 'lg:tw-to-red-600'
  | 'xl:tw-to-red-600'
  | 'hover:tw-to-red-600'
  | 'focus:tw-to-red-600'
  | 'sm:tw-from-red-700'
  | 'md:tw-from-red-700'
  | 'lg:tw-from-red-700'
  | 'xl:tw-from-red-700'
  | 'hover:tw-from-red-700'
  | 'focus:tw-from-red-700'
  | 'sm:tw-via-red-700'
  | 'md:tw-via-red-700'
  | 'lg:tw-via-red-700'
  | 'xl:tw-via-red-700'
  | 'hover:tw-via-red-700'
  | 'focus:tw-via-red-700'
  | 'sm:tw-to-red-700'
  | 'md:tw-to-red-700'
  | 'lg:tw-to-red-700'
  | 'xl:tw-to-red-700'
  | 'hover:tw-to-red-700'
  | 'focus:tw-to-red-700'
  | 'sm:tw-from-red-800'
  | 'md:tw-from-red-800'
  | 'lg:tw-from-red-800'
  | 'xl:tw-from-red-800'
  | 'hover:tw-from-red-800'
  | 'focus:tw-from-red-800'
  | 'sm:tw-via-red-800'
  | 'md:tw-via-red-800'
  | 'lg:tw-via-red-800'
  | 'xl:tw-via-red-800'
  | 'hover:tw-via-red-800'
  | 'focus:tw-via-red-800'
  | 'sm:tw-to-red-800'
  | 'md:tw-to-red-800'
  | 'lg:tw-to-red-800'
  | 'xl:tw-to-red-800'
  | 'hover:tw-to-red-800'
  | 'focus:tw-to-red-800'
  | 'sm:tw-from-red-900'
  | 'md:tw-from-red-900'
  | 'lg:tw-from-red-900'
  | 'xl:tw-from-red-900'
  | 'hover:tw-from-red-900'
  | 'focus:tw-from-red-900'
  | 'sm:tw-via-red-900'
  | 'md:tw-via-red-900'
  | 'lg:tw-via-red-900'
  | 'xl:tw-via-red-900'
  | 'hover:tw-via-red-900'
  | 'focus:tw-via-red-900'
  | 'sm:tw-to-red-900'
  | 'md:tw-to-red-900'
  | 'lg:tw-to-red-900'
  | 'xl:tw-to-red-900'
  | 'hover:tw-to-red-900'
  | 'focus:tw-to-red-900'
  | 'sm:tw-from-red-a100'
  | 'md:tw-from-red-a100'
  | 'lg:tw-from-red-a100'
  | 'xl:tw-from-red-a100'
  | 'hover:tw-from-red-a100'
  | 'focus:tw-from-red-a100'
  | 'sm:tw-via-red-a100'
  | 'md:tw-via-red-a100'
  | 'lg:tw-via-red-a100'
  | 'xl:tw-via-red-a100'
  | 'hover:tw-via-red-a100'
  | 'focus:tw-via-red-a100'
  | 'sm:tw-to-red-a100'
  | 'md:tw-to-red-a100'
  | 'lg:tw-to-red-a100'
  | 'xl:tw-to-red-a100'
  | 'hover:tw-to-red-a100'
  | 'focus:tw-to-red-a100'
  | 'sm:tw-from-red-a200'
  | 'md:tw-from-red-a200'
  | 'lg:tw-from-red-a200'
  | 'xl:tw-from-red-a200'
  | 'hover:tw-from-red-a200'
  | 'focus:tw-from-red-a200'
  | 'sm:tw-via-red-a200'
  | 'md:tw-via-red-a200'
  | 'lg:tw-via-red-a200'
  | 'xl:tw-via-red-a200'
  | 'hover:tw-via-red-a200'
  | 'focus:tw-via-red-a200'
  | 'sm:tw-to-red-a200'
  | 'md:tw-to-red-a200'
  | 'lg:tw-to-red-a200'
  | 'xl:tw-to-red-a200'
  | 'hover:tw-to-red-a200'
  | 'focus:tw-to-red-a200'
  | 'sm:tw-from-red-a400'
  | 'md:tw-from-red-a400'
  | 'lg:tw-from-red-a400'
  | 'xl:tw-from-red-a400'
  | 'hover:tw-from-red-a400'
  | 'focus:tw-from-red-a400'
  | 'sm:tw-via-red-a400'
  | 'md:tw-via-red-a400'
  | 'lg:tw-via-red-a400'
  | 'xl:tw-via-red-a400'
  | 'hover:tw-via-red-a400'
  | 'focus:tw-via-red-a400'
  | 'sm:tw-to-red-a400'
  | 'md:tw-to-red-a400'
  | 'lg:tw-to-red-a400'
  | 'xl:tw-to-red-a400'
  | 'hover:tw-to-red-a400'
  | 'focus:tw-to-red-a400'
  | 'sm:tw-from-red-a700'
  | 'md:tw-from-red-a700'
  | 'lg:tw-from-red-a700'
  | 'xl:tw-from-red-a700'
  | 'hover:tw-from-red-a700'
  | 'focus:tw-from-red-a700'
  | 'sm:tw-via-red-a700'
  | 'md:tw-via-red-a700'
  | 'lg:tw-via-red-a700'
  | 'xl:tw-via-red-a700'
  | 'hover:tw-via-red-a700'
  | 'focus:tw-via-red-a700'
  | 'sm:tw-to-red-a700'
  | 'md:tw-to-red-a700'
  | 'lg:tw-to-red-a700'
  | 'xl:tw-to-red-a700'
  | 'hover:tw-to-red-a700'
  | 'focus:tw-to-red-a700'
  | 'sm:tw-from-pink-50'
  | 'md:tw-from-pink-50'
  | 'lg:tw-from-pink-50'
  | 'xl:tw-from-pink-50'
  | 'hover:tw-from-pink-50'
  | 'focus:tw-from-pink-50'
  | 'sm:tw-via-pink-50'
  | 'md:tw-via-pink-50'
  | 'lg:tw-via-pink-50'
  | 'xl:tw-via-pink-50'
  | 'hover:tw-via-pink-50'
  | 'focus:tw-via-pink-50'
  | 'sm:tw-to-pink-50'
  | 'md:tw-to-pink-50'
  | 'lg:tw-to-pink-50'
  | 'xl:tw-to-pink-50'
  | 'hover:tw-to-pink-50'
  | 'focus:tw-to-pink-50'
  | 'sm:tw-from-pink-100'
  | 'md:tw-from-pink-100'
  | 'lg:tw-from-pink-100'
  | 'xl:tw-from-pink-100'
  | 'hover:tw-from-pink-100'
  | 'focus:tw-from-pink-100'
  | 'sm:tw-via-pink-100'
  | 'md:tw-via-pink-100'
  | 'lg:tw-via-pink-100'
  | 'xl:tw-via-pink-100'
  | 'hover:tw-via-pink-100'
  | 'focus:tw-via-pink-100'
  | 'sm:tw-to-pink-100'
  | 'md:tw-to-pink-100'
  | 'lg:tw-to-pink-100'
  | 'xl:tw-to-pink-100'
  | 'hover:tw-to-pink-100'
  | 'focus:tw-to-pink-100'
  | 'sm:tw-from-pink-200'
  | 'md:tw-from-pink-200'
  | 'lg:tw-from-pink-200'
  | 'xl:tw-from-pink-200'
  | 'hover:tw-from-pink-200'
  | 'focus:tw-from-pink-200'
  | 'sm:tw-via-pink-200'
  | 'md:tw-via-pink-200'
  | 'lg:tw-via-pink-200'
  | 'xl:tw-via-pink-200'
  | 'hover:tw-via-pink-200'
  | 'focus:tw-via-pink-200'
  | 'sm:tw-to-pink-200'
  | 'md:tw-to-pink-200'
  | 'lg:tw-to-pink-200'
  | 'xl:tw-to-pink-200'
  | 'hover:tw-to-pink-200'
  | 'focus:tw-to-pink-200'
  | 'sm:tw-from-pink-300'
  | 'md:tw-from-pink-300'
  | 'lg:tw-from-pink-300'
  | 'xl:tw-from-pink-300'
  | 'hover:tw-from-pink-300'
  | 'focus:tw-from-pink-300'
  | 'sm:tw-via-pink-300'
  | 'md:tw-via-pink-300'
  | 'lg:tw-via-pink-300'
  | 'xl:tw-via-pink-300'
  | 'hover:tw-via-pink-300'
  | 'focus:tw-via-pink-300'
  | 'sm:tw-to-pink-300'
  | 'md:tw-to-pink-300'
  | 'lg:tw-to-pink-300'
  | 'xl:tw-to-pink-300'
  | 'hover:tw-to-pink-300'
  | 'focus:tw-to-pink-300'
  | 'sm:tw-from-pink-400'
  | 'md:tw-from-pink-400'
  | 'lg:tw-from-pink-400'
  | 'xl:tw-from-pink-400'
  | 'hover:tw-from-pink-400'
  | 'focus:tw-from-pink-400'
  | 'sm:tw-via-pink-400'
  | 'md:tw-via-pink-400'
  | 'lg:tw-via-pink-400'
  | 'xl:tw-via-pink-400'
  | 'hover:tw-via-pink-400'
  | 'focus:tw-via-pink-400'
  | 'sm:tw-to-pink-400'
  | 'md:tw-to-pink-400'
  | 'lg:tw-to-pink-400'
  | 'xl:tw-to-pink-400'
  | 'hover:tw-to-pink-400'
  | 'focus:tw-to-pink-400'
  | 'sm:tw-from-pink-500'
  | 'md:tw-from-pink-500'
  | 'lg:tw-from-pink-500'
  | 'xl:tw-from-pink-500'
  | 'hover:tw-from-pink-500'
  | 'focus:tw-from-pink-500'
  | 'sm:tw-via-pink-500'
  | 'md:tw-via-pink-500'
  | 'lg:tw-via-pink-500'
  | 'xl:tw-via-pink-500'
  | 'hover:tw-via-pink-500'
  | 'focus:tw-via-pink-500'
  | 'sm:tw-to-pink-500'
  | 'md:tw-to-pink-500'
  | 'lg:tw-to-pink-500'
  | 'xl:tw-to-pink-500'
  | 'hover:tw-to-pink-500'
  | 'focus:tw-to-pink-500'
  | 'sm:tw-from-pink-600'
  | 'md:tw-from-pink-600'
  | 'lg:tw-from-pink-600'
  | 'xl:tw-from-pink-600'
  | 'hover:tw-from-pink-600'
  | 'focus:tw-from-pink-600'
  | 'sm:tw-via-pink-600'
  | 'md:tw-via-pink-600'
  | 'lg:tw-via-pink-600'
  | 'xl:tw-via-pink-600'
  | 'hover:tw-via-pink-600'
  | 'focus:tw-via-pink-600'
  | 'sm:tw-to-pink-600'
  | 'md:tw-to-pink-600'
  | 'lg:tw-to-pink-600'
  | 'xl:tw-to-pink-600'
  | 'hover:tw-to-pink-600'
  | 'focus:tw-to-pink-600'
  | 'sm:tw-from-pink-700'
  | 'md:tw-from-pink-700'
  | 'lg:tw-from-pink-700'
  | 'xl:tw-from-pink-700'
  | 'hover:tw-from-pink-700'
  | 'focus:tw-from-pink-700'
  | 'sm:tw-via-pink-700'
  | 'md:tw-via-pink-700'
  | 'lg:tw-via-pink-700'
  | 'xl:tw-via-pink-700'
  | 'hover:tw-via-pink-700'
  | 'focus:tw-via-pink-700'
  | 'sm:tw-to-pink-700'
  | 'md:tw-to-pink-700'
  | 'lg:tw-to-pink-700'
  | 'xl:tw-to-pink-700'
  | 'hover:tw-to-pink-700'
  | 'focus:tw-to-pink-700'
  | 'sm:tw-from-pink-800'
  | 'md:tw-from-pink-800'
  | 'lg:tw-from-pink-800'
  | 'xl:tw-from-pink-800'
  | 'hover:tw-from-pink-800'
  | 'focus:tw-from-pink-800'
  | 'sm:tw-via-pink-800'
  | 'md:tw-via-pink-800'
  | 'lg:tw-via-pink-800'
  | 'xl:tw-via-pink-800'
  | 'hover:tw-via-pink-800'
  | 'focus:tw-via-pink-800'
  | 'sm:tw-to-pink-800'
  | 'md:tw-to-pink-800'
  | 'lg:tw-to-pink-800'
  | 'xl:tw-to-pink-800'
  | 'hover:tw-to-pink-800'
  | 'focus:tw-to-pink-800'
  | 'sm:tw-from-pink-900'
  | 'md:tw-from-pink-900'
  | 'lg:tw-from-pink-900'
  | 'xl:tw-from-pink-900'
  | 'hover:tw-from-pink-900'
  | 'focus:tw-from-pink-900'
  | 'sm:tw-via-pink-900'
  | 'md:tw-via-pink-900'
  | 'lg:tw-via-pink-900'
  | 'xl:tw-via-pink-900'
  | 'hover:tw-via-pink-900'
  | 'focus:tw-via-pink-900'
  | 'sm:tw-to-pink-900'
  | 'md:tw-to-pink-900'
  | 'lg:tw-to-pink-900'
  | 'xl:tw-to-pink-900'
  | 'hover:tw-to-pink-900'
  | 'focus:tw-to-pink-900'
  | 'sm:tw-from-pink-a100'
  | 'md:tw-from-pink-a100'
  | 'lg:tw-from-pink-a100'
  | 'xl:tw-from-pink-a100'
  | 'hover:tw-from-pink-a100'
  | 'focus:tw-from-pink-a100'
  | 'sm:tw-via-pink-a100'
  | 'md:tw-via-pink-a100'
  | 'lg:tw-via-pink-a100'
  | 'xl:tw-via-pink-a100'
  | 'hover:tw-via-pink-a100'
  | 'focus:tw-via-pink-a100'
  | 'sm:tw-to-pink-a100'
  | 'md:tw-to-pink-a100'
  | 'lg:tw-to-pink-a100'
  | 'xl:tw-to-pink-a100'
  | 'hover:tw-to-pink-a100'
  | 'focus:tw-to-pink-a100'
  | 'sm:tw-from-pink-a200'
  | 'md:tw-from-pink-a200'
  | 'lg:tw-from-pink-a200'
  | 'xl:tw-from-pink-a200'
  | 'hover:tw-from-pink-a200'
  | 'focus:tw-from-pink-a200'
  | 'sm:tw-via-pink-a200'
  | 'md:tw-via-pink-a200'
  | 'lg:tw-via-pink-a200'
  | 'xl:tw-via-pink-a200'
  | 'hover:tw-via-pink-a200'
  | 'focus:tw-via-pink-a200'
  | 'sm:tw-to-pink-a200'
  | 'md:tw-to-pink-a200'
  | 'lg:tw-to-pink-a200'
  | 'xl:tw-to-pink-a200'
  | 'hover:tw-to-pink-a200'
  | 'focus:tw-to-pink-a200'
  | 'sm:tw-from-pink-a400'
  | 'md:tw-from-pink-a400'
  | 'lg:tw-from-pink-a400'
  | 'xl:tw-from-pink-a400'
  | 'hover:tw-from-pink-a400'
  | 'focus:tw-from-pink-a400'
  | 'sm:tw-via-pink-a400'
  | 'md:tw-via-pink-a400'
  | 'lg:tw-via-pink-a400'
  | 'xl:tw-via-pink-a400'
  | 'hover:tw-via-pink-a400'
  | 'focus:tw-via-pink-a400'
  | 'sm:tw-to-pink-a400'
  | 'md:tw-to-pink-a400'
  | 'lg:tw-to-pink-a400'
  | 'xl:tw-to-pink-a400'
  | 'hover:tw-to-pink-a400'
  | 'focus:tw-to-pink-a400'
  | 'sm:tw-from-pink-a700'
  | 'md:tw-from-pink-a700'
  | 'lg:tw-from-pink-a700'
  | 'xl:tw-from-pink-a700'
  | 'hover:tw-from-pink-a700'
  | 'focus:tw-from-pink-a700'
  | 'sm:tw-via-pink-a700'
  | 'md:tw-via-pink-a700'
  | 'lg:tw-via-pink-a700'
  | 'xl:tw-via-pink-a700'
  | 'hover:tw-via-pink-a700'
  | 'focus:tw-via-pink-a700'
  | 'sm:tw-to-pink-a700'
  | 'md:tw-to-pink-a700'
  | 'lg:tw-to-pink-a700'
  | 'xl:tw-to-pink-a700'
  | 'hover:tw-to-pink-a700'
  | 'focus:tw-to-pink-a700'
  | 'sm:tw-from-purple-50'
  | 'md:tw-from-purple-50'
  | 'lg:tw-from-purple-50'
  | 'xl:tw-from-purple-50'
  | 'hover:tw-from-purple-50'
  | 'focus:tw-from-purple-50'
  | 'sm:tw-via-purple-50'
  | 'md:tw-via-purple-50'
  | 'lg:tw-via-purple-50'
  | 'xl:tw-via-purple-50'
  | 'hover:tw-via-purple-50'
  | 'focus:tw-via-purple-50'
  | 'sm:tw-to-purple-50'
  | 'md:tw-to-purple-50'
  | 'lg:tw-to-purple-50'
  | 'xl:tw-to-purple-50'
  | 'hover:tw-to-purple-50'
  | 'focus:tw-to-purple-50'
  | 'sm:tw-from-purple-100'
  | 'md:tw-from-purple-100'
  | 'lg:tw-from-purple-100'
  | 'xl:tw-from-purple-100'
  | 'hover:tw-from-purple-100'
  | 'focus:tw-from-purple-100'
  | 'sm:tw-via-purple-100'
  | 'md:tw-via-purple-100'
  | 'lg:tw-via-purple-100'
  | 'xl:tw-via-purple-100'
  | 'hover:tw-via-purple-100'
  | 'focus:tw-via-purple-100'
  | 'sm:tw-to-purple-100'
  | 'md:tw-to-purple-100'
  | 'lg:tw-to-purple-100'
  | 'xl:tw-to-purple-100'
  | 'hover:tw-to-purple-100'
  | 'focus:tw-to-purple-100'
  | 'sm:tw-from-purple-200'
  | 'md:tw-from-purple-200'
  | 'lg:tw-from-purple-200'
  | 'xl:tw-from-purple-200'
  | 'hover:tw-from-purple-200'
  | 'focus:tw-from-purple-200'
  | 'sm:tw-via-purple-200'
  | 'md:tw-via-purple-200'
  | 'lg:tw-via-purple-200'
  | 'xl:tw-via-purple-200'
  | 'hover:tw-via-purple-200'
  | 'focus:tw-via-purple-200'
  | 'sm:tw-to-purple-200'
  | 'md:tw-to-purple-200'
  | 'lg:tw-to-purple-200'
  | 'xl:tw-to-purple-200'
  | 'hover:tw-to-purple-200'
  | 'focus:tw-to-purple-200'
  | 'sm:tw-from-purple-300'
  | 'md:tw-from-purple-300'
  | 'lg:tw-from-purple-300'
  | 'xl:tw-from-purple-300'
  | 'hover:tw-from-purple-300'
  | 'focus:tw-from-purple-300'
  | 'sm:tw-via-purple-300'
  | 'md:tw-via-purple-300'
  | 'lg:tw-via-purple-300'
  | 'xl:tw-via-purple-300'
  | 'hover:tw-via-purple-300'
  | 'focus:tw-via-purple-300'
  | 'sm:tw-to-purple-300'
  | 'md:tw-to-purple-300'
  | 'lg:tw-to-purple-300'
  | 'xl:tw-to-purple-300'
  | 'hover:tw-to-purple-300'
  | 'focus:tw-to-purple-300'
  | 'sm:tw-from-purple-400'
  | 'md:tw-from-purple-400'
  | 'lg:tw-from-purple-400'
  | 'xl:tw-from-purple-400'
  | 'hover:tw-from-purple-400'
  | 'focus:tw-from-purple-400'
  | 'sm:tw-via-purple-400'
  | 'md:tw-via-purple-400'
  | 'lg:tw-via-purple-400'
  | 'xl:tw-via-purple-400'
  | 'hover:tw-via-purple-400'
  | 'focus:tw-via-purple-400'
  | 'sm:tw-to-purple-400'
  | 'md:tw-to-purple-400'
  | 'lg:tw-to-purple-400'
  | 'xl:tw-to-purple-400'
  | 'hover:tw-to-purple-400'
  | 'focus:tw-to-purple-400'
  | 'sm:tw-from-purple-500'
  | 'md:tw-from-purple-500'
  | 'lg:tw-from-purple-500'
  | 'xl:tw-from-purple-500'
  | 'hover:tw-from-purple-500'
  | 'focus:tw-from-purple-500'
  | 'sm:tw-via-purple-500'
  | 'md:tw-via-purple-500'
  | 'lg:tw-via-purple-500'
  | 'xl:tw-via-purple-500'
  | 'hover:tw-via-purple-500'
  | 'focus:tw-via-purple-500'
  | 'sm:tw-to-purple-500'
  | 'md:tw-to-purple-500'
  | 'lg:tw-to-purple-500'
  | 'xl:tw-to-purple-500'
  | 'hover:tw-to-purple-500'
  | 'focus:tw-to-purple-500'
  | 'sm:tw-from-purple-600'
  | 'md:tw-from-purple-600'
  | 'lg:tw-from-purple-600'
  | 'xl:tw-from-purple-600'
  | 'hover:tw-from-purple-600'
  | 'focus:tw-from-purple-600'
  | 'sm:tw-via-purple-600'
  | 'md:tw-via-purple-600'
  | 'lg:tw-via-purple-600'
  | 'xl:tw-via-purple-600'
  | 'hover:tw-via-purple-600'
  | 'focus:tw-via-purple-600'
  | 'sm:tw-to-purple-600'
  | 'md:tw-to-purple-600'
  | 'lg:tw-to-purple-600'
  | 'xl:tw-to-purple-600'
  | 'hover:tw-to-purple-600'
  | 'focus:tw-to-purple-600'
  | 'sm:tw-from-purple-700'
  | 'md:tw-from-purple-700'
  | 'lg:tw-from-purple-700'
  | 'xl:tw-from-purple-700'
  | 'hover:tw-from-purple-700'
  | 'focus:tw-from-purple-700'
  | 'sm:tw-via-purple-700'
  | 'md:tw-via-purple-700'
  | 'lg:tw-via-purple-700'
  | 'xl:tw-via-purple-700'
  | 'hover:tw-via-purple-700'
  | 'focus:tw-via-purple-700'
  | 'sm:tw-to-purple-700'
  | 'md:tw-to-purple-700'
  | 'lg:tw-to-purple-700'
  | 'xl:tw-to-purple-700'
  | 'hover:tw-to-purple-700'
  | 'focus:tw-to-purple-700'
  | 'sm:tw-from-purple-800'
  | 'md:tw-from-purple-800'
  | 'lg:tw-from-purple-800'
  | 'xl:tw-from-purple-800'
  | 'hover:tw-from-purple-800'
  | 'focus:tw-from-purple-800'
  | 'sm:tw-via-purple-800'
  | 'md:tw-via-purple-800'
  | 'lg:tw-via-purple-800'
  | 'xl:tw-via-purple-800'
  | 'hover:tw-via-purple-800'
  | 'focus:tw-via-purple-800'
  | 'sm:tw-to-purple-800'
  | 'md:tw-to-purple-800'
  | 'lg:tw-to-purple-800'
  | 'xl:tw-to-purple-800'
  | 'hover:tw-to-purple-800'
  | 'focus:tw-to-purple-800'
  | 'sm:tw-from-purple-900'
  | 'md:tw-from-purple-900'
  | 'lg:tw-from-purple-900'
  | 'xl:tw-from-purple-900'
  | 'hover:tw-from-purple-900'
  | 'focus:tw-from-purple-900'
  | 'sm:tw-via-purple-900'
  | 'md:tw-via-purple-900'
  | 'lg:tw-via-purple-900'
  | 'xl:tw-via-purple-900'
  | 'hover:tw-via-purple-900'
  | 'focus:tw-via-purple-900'
  | 'sm:tw-to-purple-900'
  | 'md:tw-to-purple-900'
  | 'lg:tw-to-purple-900'
  | 'xl:tw-to-purple-900'
  | 'hover:tw-to-purple-900'
  | 'focus:tw-to-purple-900'
  | 'sm:tw-from-purple-a100'
  | 'md:tw-from-purple-a100'
  | 'lg:tw-from-purple-a100'
  | 'xl:tw-from-purple-a100'
  | 'hover:tw-from-purple-a100'
  | 'focus:tw-from-purple-a100'
  | 'sm:tw-via-purple-a100'
  | 'md:tw-via-purple-a100'
  | 'lg:tw-via-purple-a100'
  | 'xl:tw-via-purple-a100'
  | 'hover:tw-via-purple-a100'
  | 'focus:tw-via-purple-a100'
  | 'sm:tw-to-purple-a100'
  | 'md:tw-to-purple-a100'
  | 'lg:tw-to-purple-a100'
  | 'xl:tw-to-purple-a100'
  | 'hover:tw-to-purple-a100'
  | 'focus:tw-to-purple-a100'
  | 'sm:tw-from-purple-a200'
  | 'md:tw-from-purple-a200'
  | 'lg:tw-from-purple-a200'
  | 'xl:tw-from-purple-a200'
  | 'hover:tw-from-purple-a200'
  | 'focus:tw-from-purple-a200'
  | 'sm:tw-via-purple-a200'
  | 'md:tw-via-purple-a200'
  | 'lg:tw-via-purple-a200'
  | 'xl:tw-via-purple-a200'
  | 'hover:tw-via-purple-a200'
  | 'focus:tw-via-purple-a200'
  | 'sm:tw-to-purple-a200'
  | 'md:tw-to-purple-a200'
  | 'lg:tw-to-purple-a200'
  | 'xl:tw-to-purple-a200'
  | 'hover:tw-to-purple-a200'
  | 'focus:tw-to-purple-a200'
  | 'sm:tw-from-purple-a400'
  | 'md:tw-from-purple-a400'
  | 'lg:tw-from-purple-a400'
  | 'xl:tw-from-purple-a400'
  | 'hover:tw-from-purple-a400'
  | 'focus:tw-from-purple-a400'
  | 'sm:tw-via-purple-a400'
  | 'md:tw-via-purple-a400'
  | 'lg:tw-via-purple-a400'
  | 'xl:tw-via-purple-a400'
  | 'hover:tw-via-purple-a400'
  | 'focus:tw-via-purple-a400'
  | 'sm:tw-to-purple-a400'
  | 'md:tw-to-purple-a400'
  | 'lg:tw-to-purple-a400'
  | 'xl:tw-to-purple-a400'
  | 'hover:tw-to-purple-a400'
  | 'focus:tw-to-purple-a400'
  | 'sm:tw-from-purple-a700'
  | 'md:tw-from-purple-a700'
  | 'lg:tw-from-purple-a700'
  | 'xl:tw-from-purple-a700'
  | 'hover:tw-from-purple-a700'
  | 'focus:tw-from-purple-a700'
  | 'sm:tw-via-purple-a700'
  | 'md:tw-via-purple-a700'
  | 'lg:tw-via-purple-a700'
  | 'xl:tw-via-purple-a700'
  | 'hover:tw-via-purple-a700'
  | 'focus:tw-via-purple-a700'
  | 'sm:tw-to-purple-a700'
  | 'md:tw-to-purple-a700'
  | 'lg:tw-to-purple-a700'
  | 'xl:tw-to-purple-a700'
  | 'hover:tw-to-purple-a700'
  | 'focus:tw-to-purple-a700'
  | 'sm:tw-from-deepPurple-50'
  | 'md:tw-from-deepPurple-50'
  | 'lg:tw-from-deepPurple-50'
  | 'xl:tw-from-deepPurple-50'
  | 'hover:tw-from-deepPurple-50'
  | 'focus:tw-from-deepPurple-50'
  | 'sm:tw-via-deepPurple-50'
  | 'md:tw-via-deepPurple-50'
  | 'lg:tw-via-deepPurple-50'
  | 'xl:tw-via-deepPurple-50'
  | 'hover:tw-via-deepPurple-50'
  | 'focus:tw-via-deepPurple-50'
  | 'sm:tw-to-deepPurple-50'
  | 'md:tw-to-deepPurple-50'
  | 'lg:tw-to-deepPurple-50'
  | 'xl:tw-to-deepPurple-50'
  | 'hover:tw-to-deepPurple-50'
  | 'focus:tw-to-deepPurple-50'
  | 'sm:tw-from-deepPurple-100'
  | 'md:tw-from-deepPurple-100'
  | 'lg:tw-from-deepPurple-100'
  | 'xl:tw-from-deepPurple-100'
  | 'hover:tw-from-deepPurple-100'
  | 'focus:tw-from-deepPurple-100'
  | 'sm:tw-via-deepPurple-100'
  | 'md:tw-via-deepPurple-100'
  | 'lg:tw-via-deepPurple-100'
  | 'xl:tw-via-deepPurple-100'
  | 'hover:tw-via-deepPurple-100'
  | 'focus:tw-via-deepPurple-100'
  | 'sm:tw-to-deepPurple-100'
  | 'md:tw-to-deepPurple-100'
  | 'lg:tw-to-deepPurple-100'
  | 'xl:tw-to-deepPurple-100'
  | 'hover:tw-to-deepPurple-100'
  | 'focus:tw-to-deepPurple-100'
  | 'sm:tw-from-deepPurple-200'
  | 'md:tw-from-deepPurple-200'
  | 'lg:tw-from-deepPurple-200'
  | 'xl:tw-from-deepPurple-200'
  | 'hover:tw-from-deepPurple-200'
  | 'focus:tw-from-deepPurple-200'
  | 'sm:tw-via-deepPurple-200'
  | 'md:tw-via-deepPurple-200'
  | 'lg:tw-via-deepPurple-200'
  | 'xl:tw-via-deepPurple-200'
  | 'hover:tw-via-deepPurple-200'
  | 'focus:tw-via-deepPurple-200'
  | 'sm:tw-to-deepPurple-200'
  | 'md:tw-to-deepPurple-200'
  | 'lg:tw-to-deepPurple-200'
  | 'xl:tw-to-deepPurple-200'
  | 'hover:tw-to-deepPurple-200'
  | 'focus:tw-to-deepPurple-200'
  | 'sm:tw-from-deepPurple-300'
  | 'md:tw-from-deepPurple-300'
  | 'lg:tw-from-deepPurple-300'
  | 'xl:tw-from-deepPurple-300'
  | 'hover:tw-from-deepPurple-300'
  | 'focus:tw-from-deepPurple-300'
  | 'sm:tw-via-deepPurple-300'
  | 'md:tw-via-deepPurple-300'
  | 'lg:tw-via-deepPurple-300'
  | 'xl:tw-via-deepPurple-300'
  | 'hover:tw-via-deepPurple-300'
  | 'focus:tw-via-deepPurple-300'
  | 'sm:tw-to-deepPurple-300'
  | 'md:tw-to-deepPurple-300'
  | 'lg:tw-to-deepPurple-300'
  | 'xl:tw-to-deepPurple-300'
  | 'hover:tw-to-deepPurple-300'
  | 'focus:tw-to-deepPurple-300'
  | 'sm:tw-from-deepPurple-400'
  | 'md:tw-from-deepPurple-400'
  | 'lg:tw-from-deepPurple-400'
  | 'xl:tw-from-deepPurple-400'
  | 'hover:tw-from-deepPurple-400'
  | 'focus:tw-from-deepPurple-400'
  | 'sm:tw-via-deepPurple-400'
  | 'md:tw-via-deepPurple-400'
  | 'lg:tw-via-deepPurple-400'
  | 'xl:tw-via-deepPurple-400'
  | 'hover:tw-via-deepPurple-400'
  | 'focus:tw-via-deepPurple-400'
  | 'sm:tw-to-deepPurple-400'
  | 'md:tw-to-deepPurple-400'
  | 'lg:tw-to-deepPurple-400'
  | 'xl:tw-to-deepPurple-400'
  | 'hover:tw-to-deepPurple-400'
  | 'focus:tw-to-deepPurple-400'
  | 'sm:tw-from-deepPurple-500'
  | 'md:tw-from-deepPurple-500'
  | 'lg:tw-from-deepPurple-500'
  | 'xl:tw-from-deepPurple-500'
  | 'hover:tw-from-deepPurple-500'
  | 'focus:tw-from-deepPurple-500'
  | 'sm:tw-via-deepPurple-500'
  | 'md:tw-via-deepPurple-500'
  | 'lg:tw-via-deepPurple-500'
  | 'xl:tw-via-deepPurple-500'
  | 'hover:tw-via-deepPurple-500'
  | 'focus:tw-via-deepPurple-500'
  | 'sm:tw-to-deepPurple-500'
  | 'md:tw-to-deepPurple-500'
  | 'lg:tw-to-deepPurple-500'
  | 'xl:tw-to-deepPurple-500'
  | 'hover:tw-to-deepPurple-500'
  | 'focus:tw-to-deepPurple-500'
  | 'sm:tw-from-deepPurple-600'
  | 'md:tw-from-deepPurple-600'
  | 'lg:tw-from-deepPurple-600'
  | 'xl:tw-from-deepPurple-600'
  | 'hover:tw-from-deepPurple-600'
  | 'focus:tw-from-deepPurple-600'
  | 'sm:tw-via-deepPurple-600'
  | 'md:tw-via-deepPurple-600'
  | 'lg:tw-via-deepPurple-600'
  | 'xl:tw-via-deepPurple-600'
  | 'hover:tw-via-deepPurple-600'
  | 'focus:tw-via-deepPurple-600'
  | 'sm:tw-to-deepPurple-600'
  | 'md:tw-to-deepPurple-600'
  | 'lg:tw-to-deepPurple-600'
  | 'xl:tw-to-deepPurple-600'
  | 'hover:tw-to-deepPurple-600'
  | 'focus:tw-to-deepPurple-600'
  | 'sm:tw-from-deepPurple-700'
  | 'md:tw-from-deepPurple-700'
  | 'lg:tw-from-deepPurple-700'
  | 'xl:tw-from-deepPurple-700'
  | 'hover:tw-from-deepPurple-700'
  | 'focus:tw-from-deepPurple-700'
  | 'sm:tw-via-deepPurple-700'
  | 'md:tw-via-deepPurple-700'
  | 'lg:tw-via-deepPurple-700'
  | 'xl:tw-via-deepPurple-700'
  | 'hover:tw-via-deepPurple-700'
  | 'focus:tw-via-deepPurple-700'
  | 'sm:tw-to-deepPurple-700'
  | 'md:tw-to-deepPurple-700'
  | 'lg:tw-to-deepPurple-700'
  | 'xl:tw-to-deepPurple-700'
  | 'hover:tw-to-deepPurple-700'
  | 'focus:tw-to-deepPurple-700'
  | 'sm:tw-from-deepPurple-800'
  | 'md:tw-from-deepPurple-800'
  | 'lg:tw-from-deepPurple-800'
  | 'xl:tw-from-deepPurple-800'
  | 'hover:tw-from-deepPurple-800'
  | 'focus:tw-from-deepPurple-800'
  | 'sm:tw-via-deepPurple-800'
  | 'md:tw-via-deepPurple-800'
  | 'lg:tw-via-deepPurple-800'
  | 'xl:tw-via-deepPurple-800'
  | 'hover:tw-via-deepPurple-800'
  | 'focus:tw-via-deepPurple-800'
  | 'sm:tw-to-deepPurple-800'
  | 'md:tw-to-deepPurple-800'
  | 'lg:tw-to-deepPurple-800'
  | 'xl:tw-to-deepPurple-800'
  | 'hover:tw-to-deepPurple-800'
  | 'focus:tw-to-deepPurple-800'
  | 'sm:tw-from-deepPurple-900'
  | 'md:tw-from-deepPurple-900'
  | 'lg:tw-from-deepPurple-900'
  | 'xl:tw-from-deepPurple-900'
  | 'hover:tw-from-deepPurple-900'
  | 'focus:tw-from-deepPurple-900'
  | 'sm:tw-via-deepPurple-900'
  | 'md:tw-via-deepPurple-900'
  | 'lg:tw-via-deepPurple-900'
  | 'xl:tw-via-deepPurple-900'
  | 'hover:tw-via-deepPurple-900'
  | 'focus:tw-via-deepPurple-900'
  | 'sm:tw-to-deepPurple-900'
  | 'md:tw-to-deepPurple-900'
  | 'lg:tw-to-deepPurple-900'
  | 'xl:tw-to-deepPurple-900'
  | 'hover:tw-to-deepPurple-900'
  | 'focus:tw-to-deepPurple-900'
  | 'sm:tw-from-deepPurple-a100'
  | 'md:tw-from-deepPurple-a100'
  | 'lg:tw-from-deepPurple-a100'
  | 'xl:tw-from-deepPurple-a100'
  | 'hover:tw-from-deepPurple-a100'
  | 'focus:tw-from-deepPurple-a100'
  | 'sm:tw-via-deepPurple-a100'
  | 'md:tw-via-deepPurple-a100'
  | 'lg:tw-via-deepPurple-a100'
  | 'xl:tw-via-deepPurple-a100'
  | 'hover:tw-via-deepPurple-a100'
  | 'focus:tw-via-deepPurple-a100'
  | 'sm:tw-to-deepPurple-a100'
  | 'md:tw-to-deepPurple-a100'
  | 'lg:tw-to-deepPurple-a100'
  | 'xl:tw-to-deepPurple-a100'
  | 'hover:tw-to-deepPurple-a100'
  | 'focus:tw-to-deepPurple-a100'
  | 'sm:tw-from-deepPurple-a200'
  | 'md:tw-from-deepPurple-a200'
  | 'lg:tw-from-deepPurple-a200'
  | 'xl:tw-from-deepPurple-a200'
  | 'hover:tw-from-deepPurple-a200'
  | 'focus:tw-from-deepPurple-a200'
  | 'sm:tw-via-deepPurple-a200'
  | 'md:tw-via-deepPurple-a200'
  | 'lg:tw-via-deepPurple-a200'
  | 'xl:tw-via-deepPurple-a200'
  | 'hover:tw-via-deepPurple-a200'
  | 'focus:tw-via-deepPurple-a200'
  | 'sm:tw-to-deepPurple-a200'
  | 'md:tw-to-deepPurple-a200'
  | 'lg:tw-to-deepPurple-a200'
  | 'xl:tw-to-deepPurple-a200'
  | 'hover:tw-to-deepPurple-a200'
  | 'focus:tw-to-deepPurple-a200'
  | 'sm:tw-from-deepPurple-a400'
  | 'md:tw-from-deepPurple-a400'
  | 'lg:tw-from-deepPurple-a400'
  | 'xl:tw-from-deepPurple-a400'
  | 'hover:tw-from-deepPurple-a400'
  | 'focus:tw-from-deepPurple-a400'
  | 'sm:tw-via-deepPurple-a400'
  | 'md:tw-via-deepPurple-a400'
  | 'lg:tw-via-deepPurple-a400'
  | 'xl:tw-via-deepPurple-a400'
  | 'hover:tw-via-deepPurple-a400'
  | 'focus:tw-via-deepPurple-a400'
  | 'sm:tw-to-deepPurple-a400'
  | 'md:tw-to-deepPurple-a400'
  | 'lg:tw-to-deepPurple-a400'
  | 'xl:tw-to-deepPurple-a400'
  | 'hover:tw-to-deepPurple-a400'
  | 'focus:tw-to-deepPurple-a400'
  | 'sm:tw-from-deepPurple-a700'
  | 'md:tw-from-deepPurple-a700'
  | 'lg:tw-from-deepPurple-a700'
  | 'xl:tw-from-deepPurple-a700'
  | 'hover:tw-from-deepPurple-a700'
  | 'focus:tw-from-deepPurple-a700'
  | 'sm:tw-via-deepPurple-a700'
  | 'md:tw-via-deepPurple-a700'
  | 'lg:tw-via-deepPurple-a700'
  | 'xl:tw-via-deepPurple-a700'
  | 'hover:tw-via-deepPurple-a700'
  | 'focus:tw-via-deepPurple-a700'
  | 'sm:tw-to-deepPurple-a700'
  | 'md:tw-to-deepPurple-a700'
  | 'lg:tw-to-deepPurple-a700'
  | 'xl:tw-to-deepPurple-a700'
  | 'hover:tw-to-deepPurple-a700'
  | 'focus:tw-to-deepPurple-a700'
  | 'sm:tw-from-indigo-50'
  | 'md:tw-from-indigo-50'
  | 'lg:tw-from-indigo-50'
  | 'xl:tw-from-indigo-50'
  | 'hover:tw-from-indigo-50'
  | 'focus:tw-from-indigo-50'
  | 'sm:tw-via-indigo-50'
  | 'md:tw-via-indigo-50'
  | 'lg:tw-via-indigo-50'
  | 'xl:tw-via-indigo-50'
  | 'hover:tw-via-indigo-50'
  | 'focus:tw-via-indigo-50'
  | 'sm:tw-to-indigo-50'
  | 'md:tw-to-indigo-50'
  | 'lg:tw-to-indigo-50'
  | 'xl:tw-to-indigo-50'
  | 'hover:tw-to-indigo-50'
  | 'focus:tw-to-indigo-50'
  | 'sm:tw-from-indigo-100'
  | 'md:tw-from-indigo-100'
  | 'lg:tw-from-indigo-100'
  | 'xl:tw-from-indigo-100'
  | 'hover:tw-from-indigo-100'
  | 'focus:tw-from-indigo-100'
  | 'sm:tw-via-indigo-100'
  | 'md:tw-via-indigo-100'
  | 'lg:tw-via-indigo-100'
  | 'xl:tw-via-indigo-100'
  | 'hover:tw-via-indigo-100'
  | 'focus:tw-via-indigo-100'
  | 'sm:tw-to-indigo-100'
  | 'md:tw-to-indigo-100'
  | 'lg:tw-to-indigo-100'
  | 'xl:tw-to-indigo-100'
  | 'hover:tw-to-indigo-100'
  | 'focus:tw-to-indigo-100'
  | 'sm:tw-from-indigo-200'
  | 'md:tw-from-indigo-200'
  | 'lg:tw-from-indigo-200'
  | 'xl:tw-from-indigo-200'
  | 'hover:tw-from-indigo-200'
  | 'focus:tw-from-indigo-200'
  | 'sm:tw-via-indigo-200'
  | 'md:tw-via-indigo-200'
  | 'lg:tw-via-indigo-200'
  | 'xl:tw-via-indigo-200'
  | 'hover:tw-via-indigo-200'
  | 'focus:tw-via-indigo-200'
  | 'sm:tw-to-indigo-200'
  | 'md:tw-to-indigo-200'
  | 'lg:tw-to-indigo-200'
  | 'xl:tw-to-indigo-200'
  | 'hover:tw-to-indigo-200'
  | 'focus:tw-to-indigo-200'
  | 'sm:tw-from-indigo-300'
  | 'md:tw-from-indigo-300'
  | 'lg:tw-from-indigo-300'
  | 'xl:tw-from-indigo-300'
  | 'hover:tw-from-indigo-300'
  | 'focus:tw-from-indigo-300'
  | 'sm:tw-via-indigo-300'
  | 'md:tw-via-indigo-300'
  | 'lg:tw-via-indigo-300'
  | 'xl:tw-via-indigo-300'
  | 'hover:tw-via-indigo-300'
  | 'focus:tw-via-indigo-300'
  | 'sm:tw-to-indigo-300'
  | 'md:tw-to-indigo-300'
  | 'lg:tw-to-indigo-300'
  | 'xl:tw-to-indigo-300'
  | 'hover:tw-to-indigo-300'
  | 'focus:tw-to-indigo-300'
  | 'sm:tw-from-indigo-400'
  | 'md:tw-from-indigo-400'
  | 'lg:tw-from-indigo-400'
  | 'xl:tw-from-indigo-400'
  | 'hover:tw-from-indigo-400'
  | 'focus:tw-from-indigo-400'
  | 'sm:tw-via-indigo-400'
  | 'md:tw-via-indigo-400'
  | 'lg:tw-via-indigo-400'
  | 'xl:tw-via-indigo-400'
  | 'hover:tw-via-indigo-400'
  | 'focus:tw-via-indigo-400'
  | 'sm:tw-to-indigo-400'
  | 'md:tw-to-indigo-400'
  | 'lg:tw-to-indigo-400'
  | 'xl:tw-to-indigo-400'
  | 'hover:tw-to-indigo-400'
  | 'focus:tw-to-indigo-400'
  | 'sm:tw-from-indigo-500'
  | 'md:tw-from-indigo-500'
  | 'lg:tw-from-indigo-500'
  | 'xl:tw-from-indigo-500'
  | 'hover:tw-from-indigo-500'
  | 'focus:tw-from-indigo-500'
  | 'sm:tw-via-indigo-500'
  | 'md:tw-via-indigo-500'
  | 'lg:tw-via-indigo-500'
  | 'xl:tw-via-indigo-500'
  | 'hover:tw-via-indigo-500'
  | 'focus:tw-via-indigo-500'
  | 'sm:tw-to-indigo-500'
  | 'md:tw-to-indigo-500'
  | 'lg:tw-to-indigo-500'
  | 'xl:tw-to-indigo-500'
  | 'hover:tw-to-indigo-500'
  | 'focus:tw-to-indigo-500'
  | 'sm:tw-from-indigo-600'
  | 'md:tw-from-indigo-600'
  | 'lg:tw-from-indigo-600'
  | 'xl:tw-from-indigo-600'
  | 'hover:tw-from-indigo-600'
  | 'focus:tw-from-indigo-600'
  | 'sm:tw-via-indigo-600'
  | 'md:tw-via-indigo-600'
  | 'lg:tw-via-indigo-600'
  | 'xl:tw-via-indigo-600'
  | 'hover:tw-via-indigo-600'
  | 'focus:tw-via-indigo-600'
  | 'sm:tw-to-indigo-600'
  | 'md:tw-to-indigo-600'
  | 'lg:tw-to-indigo-600'
  | 'xl:tw-to-indigo-600'
  | 'hover:tw-to-indigo-600'
  | 'focus:tw-to-indigo-600'
  | 'sm:tw-from-indigo-700'
  | 'md:tw-from-indigo-700'
  | 'lg:tw-from-indigo-700'
  | 'xl:tw-from-indigo-700'
  | 'hover:tw-from-indigo-700'
  | 'focus:tw-from-indigo-700'
  | 'sm:tw-via-indigo-700'
  | 'md:tw-via-indigo-700'
  | 'lg:tw-via-indigo-700'
  | 'xl:tw-via-indigo-700'
  | 'hover:tw-via-indigo-700'
  | 'focus:tw-via-indigo-700'
  | 'sm:tw-to-indigo-700'
  | 'md:tw-to-indigo-700'
  | 'lg:tw-to-indigo-700'
  | 'xl:tw-to-indigo-700'
  | 'hover:tw-to-indigo-700'
  | 'focus:tw-to-indigo-700'
  | 'sm:tw-from-indigo-800'
  | 'md:tw-from-indigo-800'
  | 'lg:tw-from-indigo-800'
  | 'xl:tw-from-indigo-800'
  | 'hover:tw-from-indigo-800'
  | 'focus:tw-from-indigo-800'
  | 'sm:tw-via-indigo-800'
  | 'md:tw-via-indigo-800'
  | 'lg:tw-via-indigo-800'
  | 'xl:tw-via-indigo-800'
  | 'hover:tw-via-indigo-800'
  | 'focus:tw-via-indigo-800'
  | 'sm:tw-to-indigo-800'
  | 'md:tw-to-indigo-800'
  | 'lg:tw-to-indigo-800'
  | 'xl:tw-to-indigo-800'
  | 'hover:tw-to-indigo-800'
  | 'focus:tw-to-indigo-800'
  | 'sm:tw-from-indigo-900'
  | 'md:tw-from-indigo-900'
  | 'lg:tw-from-indigo-900'
  | 'xl:tw-from-indigo-900'
  | 'hover:tw-from-indigo-900'
  | 'focus:tw-from-indigo-900'
  | 'sm:tw-via-indigo-900'
  | 'md:tw-via-indigo-900'
  | 'lg:tw-via-indigo-900'
  | 'xl:tw-via-indigo-900'
  | 'hover:tw-via-indigo-900'
  | 'focus:tw-via-indigo-900'
  | 'sm:tw-to-indigo-900'
  | 'md:tw-to-indigo-900'
  | 'lg:tw-to-indigo-900'
  | 'xl:tw-to-indigo-900'
  | 'hover:tw-to-indigo-900'
  | 'focus:tw-to-indigo-900'
  | 'sm:tw-from-indigo-a100'
  | 'md:tw-from-indigo-a100'
  | 'lg:tw-from-indigo-a100'
  | 'xl:tw-from-indigo-a100'
  | 'hover:tw-from-indigo-a100'
  | 'focus:tw-from-indigo-a100'
  | 'sm:tw-via-indigo-a100'
  | 'md:tw-via-indigo-a100'
  | 'lg:tw-via-indigo-a100'
  | 'xl:tw-via-indigo-a100'
  | 'hover:tw-via-indigo-a100'
  | 'focus:tw-via-indigo-a100'
  | 'sm:tw-to-indigo-a100'
  | 'md:tw-to-indigo-a100'
  | 'lg:tw-to-indigo-a100'
  | 'xl:tw-to-indigo-a100'
  | 'hover:tw-to-indigo-a100'
  | 'focus:tw-to-indigo-a100'
  | 'sm:tw-from-indigo-a200'
  | 'md:tw-from-indigo-a200'
  | 'lg:tw-from-indigo-a200'
  | 'xl:tw-from-indigo-a200'
  | 'hover:tw-from-indigo-a200'
  | 'focus:tw-from-indigo-a200'
  | 'sm:tw-via-indigo-a200'
  | 'md:tw-via-indigo-a200'
  | 'lg:tw-via-indigo-a200'
  | 'xl:tw-via-indigo-a200'
  | 'hover:tw-via-indigo-a200'
  | 'focus:tw-via-indigo-a200'
  | 'sm:tw-to-indigo-a200'
  | 'md:tw-to-indigo-a200'
  | 'lg:tw-to-indigo-a200'
  | 'xl:tw-to-indigo-a200'
  | 'hover:tw-to-indigo-a200'
  | 'focus:tw-to-indigo-a200'
  | 'sm:tw-from-indigo-a400'
  | 'md:tw-from-indigo-a400'
  | 'lg:tw-from-indigo-a400'
  | 'xl:tw-from-indigo-a400'
  | 'hover:tw-from-indigo-a400'
  | 'focus:tw-from-indigo-a400'
  | 'sm:tw-via-indigo-a400'
  | 'md:tw-via-indigo-a400'
  | 'lg:tw-via-indigo-a400'
  | 'xl:tw-via-indigo-a400'
  | 'hover:tw-via-indigo-a400'
  | 'focus:tw-via-indigo-a400'
  | 'sm:tw-to-indigo-a400'
  | 'md:tw-to-indigo-a400'
  | 'lg:tw-to-indigo-a400'
  | 'xl:tw-to-indigo-a400'
  | 'hover:tw-to-indigo-a400'
  | 'focus:tw-to-indigo-a400'
  | 'sm:tw-from-indigo-a700'
  | 'md:tw-from-indigo-a700'
  | 'lg:tw-from-indigo-a700'
  | 'xl:tw-from-indigo-a700'
  | 'hover:tw-from-indigo-a700'
  | 'focus:tw-from-indigo-a700'
  | 'sm:tw-via-indigo-a700'
  | 'md:tw-via-indigo-a700'
  | 'lg:tw-via-indigo-a700'
  | 'xl:tw-via-indigo-a700'
  | 'hover:tw-via-indigo-a700'
  | 'focus:tw-via-indigo-a700'
  | 'sm:tw-to-indigo-a700'
  | 'md:tw-to-indigo-a700'
  | 'lg:tw-to-indigo-a700'
  | 'xl:tw-to-indigo-a700'
  | 'hover:tw-to-indigo-a700'
  | 'focus:tw-to-indigo-a700'
  | 'sm:tw-from-blue-50'
  | 'md:tw-from-blue-50'
  | 'lg:tw-from-blue-50'
  | 'xl:tw-from-blue-50'
  | 'hover:tw-from-blue-50'
  | 'focus:tw-from-blue-50'
  | 'sm:tw-via-blue-50'
  | 'md:tw-via-blue-50'
  | 'lg:tw-via-blue-50'
  | 'xl:tw-via-blue-50'
  | 'hover:tw-via-blue-50'
  | 'focus:tw-via-blue-50'
  | 'sm:tw-to-blue-50'
  | 'md:tw-to-blue-50'
  | 'lg:tw-to-blue-50'
  | 'xl:tw-to-blue-50'
  | 'hover:tw-to-blue-50'
  | 'focus:tw-to-blue-50'
  | 'sm:tw-from-blue-100'
  | 'md:tw-from-blue-100'
  | 'lg:tw-from-blue-100'
  | 'xl:tw-from-blue-100'
  | 'hover:tw-from-blue-100'
  | 'focus:tw-from-blue-100'
  | 'sm:tw-via-blue-100'
  | 'md:tw-via-blue-100'
  | 'lg:tw-via-blue-100'
  | 'xl:tw-via-blue-100'
  | 'hover:tw-via-blue-100'
  | 'focus:tw-via-blue-100'
  | 'sm:tw-to-blue-100'
  | 'md:tw-to-blue-100'
  | 'lg:tw-to-blue-100'
  | 'xl:tw-to-blue-100'
  | 'hover:tw-to-blue-100'
  | 'focus:tw-to-blue-100'
  | 'sm:tw-from-blue-200'
  | 'md:tw-from-blue-200'
  | 'lg:tw-from-blue-200'
  | 'xl:tw-from-blue-200'
  | 'hover:tw-from-blue-200'
  | 'focus:tw-from-blue-200'
  | 'sm:tw-via-blue-200'
  | 'md:tw-via-blue-200'
  | 'lg:tw-via-blue-200'
  | 'xl:tw-via-blue-200'
  | 'hover:tw-via-blue-200'
  | 'focus:tw-via-blue-200'
  | 'sm:tw-to-blue-200'
  | 'md:tw-to-blue-200'
  | 'lg:tw-to-blue-200'
  | 'xl:tw-to-blue-200'
  | 'hover:tw-to-blue-200'
  | 'focus:tw-to-blue-200'
  | 'sm:tw-from-blue-300'
  | 'md:tw-from-blue-300'
  | 'lg:tw-from-blue-300'
  | 'xl:tw-from-blue-300'
  | 'hover:tw-from-blue-300'
  | 'focus:tw-from-blue-300'
  | 'sm:tw-via-blue-300'
  | 'md:tw-via-blue-300'
  | 'lg:tw-via-blue-300'
  | 'xl:tw-via-blue-300'
  | 'hover:tw-via-blue-300'
  | 'focus:tw-via-blue-300'
  | 'sm:tw-to-blue-300'
  | 'md:tw-to-blue-300'
  | 'lg:tw-to-blue-300'
  | 'xl:tw-to-blue-300'
  | 'hover:tw-to-blue-300'
  | 'focus:tw-to-blue-300'
  | 'sm:tw-from-blue-400'
  | 'md:tw-from-blue-400'
  | 'lg:tw-from-blue-400'
  | 'xl:tw-from-blue-400'
  | 'hover:tw-from-blue-400'
  | 'focus:tw-from-blue-400'
  | 'sm:tw-via-blue-400'
  | 'md:tw-via-blue-400'
  | 'lg:tw-via-blue-400'
  | 'xl:tw-via-blue-400'
  | 'hover:tw-via-blue-400'
  | 'focus:tw-via-blue-400'
  | 'sm:tw-to-blue-400'
  | 'md:tw-to-blue-400'
  | 'lg:tw-to-blue-400'
  | 'xl:tw-to-blue-400'
  | 'hover:tw-to-blue-400'
  | 'focus:tw-to-blue-400'
  | 'sm:tw-from-blue-500'
  | 'md:tw-from-blue-500'
  | 'lg:tw-from-blue-500'
  | 'xl:tw-from-blue-500'
  | 'hover:tw-from-blue-500'
  | 'focus:tw-from-blue-500'
  | 'sm:tw-via-blue-500'
  | 'md:tw-via-blue-500'
  | 'lg:tw-via-blue-500'
  | 'xl:tw-via-blue-500'
  | 'hover:tw-via-blue-500'
  | 'focus:tw-via-blue-500'
  | 'sm:tw-to-blue-500'
  | 'md:tw-to-blue-500'
  | 'lg:tw-to-blue-500'
  | 'xl:tw-to-blue-500'
  | 'hover:tw-to-blue-500'
  | 'focus:tw-to-blue-500'
  | 'sm:tw-from-blue-600'
  | 'md:tw-from-blue-600'
  | 'lg:tw-from-blue-600'
  | 'xl:tw-from-blue-600'
  | 'hover:tw-from-blue-600'
  | 'focus:tw-from-blue-600'
  | 'sm:tw-via-blue-600'
  | 'md:tw-via-blue-600'
  | 'lg:tw-via-blue-600'
  | 'xl:tw-via-blue-600'
  | 'hover:tw-via-blue-600'
  | 'focus:tw-via-blue-600'
  | 'sm:tw-to-blue-600'
  | 'md:tw-to-blue-600'
  | 'lg:tw-to-blue-600'
  | 'xl:tw-to-blue-600'
  | 'hover:tw-to-blue-600'
  | 'focus:tw-to-blue-600'
  | 'sm:tw-from-blue-700'
  | 'md:tw-from-blue-700'
  | 'lg:tw-from-blue-700'
  | 'xl:tw-from-blue-700'
  | 'hover:tw-from-blue-700'
  | 'focus:tw-from-blue-700'
  | 'sm:tw-via-blue-700'
  | 'md:tw-via-blue-700'
  | 'lg:tw-via-blue-700'
  | 'xl:tw-via-blue-700'
  | 'hover:tw-via-blue-700'
  | 'focus:tw-via-blue-700'
  | 'sm:tw-to-blue-700'
  | 'md:tw-to-blue-700'
  | 'lg:tw-to-blue-700'
  | 'xl:tw-to-blue-700'
  | 'hover:tw-to-blue-700'
  | 'focus:tw-to-blue-700'
  | 'sm:tw-from-blue-800'
  | 'md:tw-from-blue-800'
  | 'lg:tw-from-blue-800'
  | 'xl:tw-from-blue-800'
  | 'hover:tw-from-blue-800'
  | 'focus:tw-from-blue-800'
  | 'sm:tw-via-blue-800'
  | 'md:tw-via-blue-800'
  | 'lg:tw-via-blue-800'
  | 'xl:tw-via-blue-800'
  | 'hover:tw-via-blue-800'
  | 'focus:tw-via-blue-800'
  | 'sm:tw-to-blue-800'
  | 'md:tw-to-blue-800'
  | 'lg:tw-to-blue-800'
  | 'xl:tw-to-blue-800'
  | 'hover:tw-to-blue-800'
  | 'focus:tw-to-blue-800'
  | 'sm:tw-from-blue-900'
  | 'md:tw-from-blue-900'
  | 'lg:tw-from-blue-900'
  | 'xl:tw-from-blue-900'
  | 'hover:tw-from-blue-900'
  | 'focus:tw-from-blue-900'
  | 'sm:tw-via-blue-900'
  | 'md:tw-via-blue-900'
  | 'lg:tw-via-blue-900'
  | 'xl:tw-via-blue-900'
  | 'hover:tw-via-blue-900'
  | 'focus:tw-via-blue-900'
  | 'sm:tw-to-blue-900'
  | 'md:tw-to-blue-900'
  | 'lg:tw-to-blue-900'
  | 'xl:tw-to-blue-900'
  | 'hover:tw-to-blue-900'
  | 'focus:tw-to-blue-900'
  | 'sm:tw-from-blue-a100'
  | 'md:tw-from-blue-a100'
  | 'lg:tw-from-blue-a100'
  | 'xl:tw-from-blue-a100'
  | 'hover:tw-from-blue-a100'
  | 'focus:tw-from-blue-a100'
  | 'sm:tw-via-blue-a100'
  | 'md:tw-via-blue-a100'
  | 'lg:tw-via-blue-a100'
  | 'xl:tw-via-blue-a100'
  | 'hover:tw-via-blue-a100'
  | 'focus:tw-via-blue-a100'
  | 'sm:tw-to-blue-a100'
  | 'md:tw-to-blue-a100'
  | 'lg:tw-to-blue-a100'
  | 'xl:tw-to-blue-a100'
  | 'hover:tw-to-blue-a100'
  | 'focus:tw-to-blue-a100'
  | 'sm:tw-from-blue-a200'
  | 'md:tw-from-blue-a200'
  | 'lg:tw-from-blue-a200'
  | 'xl:tw-from-blue-a200'
  | 'hover:tw-from-blue-a200'
  | 'focus:tw-from-blue-a200'
  | 'sm:tw-via-blue-a200'
  | 'md:tw-via-blue-a200'
  | 'lg:tw-via-blue-a200'
  | 'xl:tw-via-blue-a200'
  | 'hover:tw-via-blue-a200'
  | 'focus:tw-via-blue-a200'
  | 'sm:tw-to-blue-a200'
  | 'md:tw-to-blue-a200'
  | 'lg:tw-to-blue-a200'
  | 'xl:tw-to-blue-a200'
  | 'hover:tw-to-blue-a200'
  | 'focus:tw-to-blue-a200'
  | 'sm:tw-from-blue-a400'
  | 'md:tw-from-blue-a400'
  | 'lg:tw-from-blue-a400'
  | 'xl:tw-from-blue-a400'
  | 'hover:tw-from-blue-a400'
  | 'focus:tw-from-blue-a400'
  | 'sm:tw-via-blue-a400'
  | 'md:tw-via-blue-a400'
  | 'lg:tw-via-blue-a400'
  | 'xl:tw-via-blue-a400'
  | 'hover:tw-via-blue-a400'
  | 'focus:tw-via-blue-a400'
  | 'sm:tw-to-blue-a400'
  | 'md:tw-to-blue-a400'
  | 'lg:tw-to-blue-a400'
  | 'xl:tw-to-blue-a400'
  | 'hover:tw-to-blue-a400'
  | 'focus:tw-to-blue-a400'
  | 'sm:tw-from-blue-a700'
  | 'md:tw-from-blue-a700'
  | 'lg:tw-from-blue-a700'
  | 'xl:tw-from-blue-a700'
  | 'hover:tw-from-blue-a700'
  | 'focus:tw-from-blue-a700'
  | 'sm:tw-via-blue-a700'
  | 'md:tw-via-blue-a700'
  | 'lg:tw-via-blue-a700'
  | 'xl:tw-via-blue-a700'
  | 'hover:tw-via-blue-a700'
  | 'focus:tw-via-blue-a700'
  | 'sm:tw-to-blue-a700'
  | 'md:tw-to-blue-a700'
  | 'lg:tw-to-blue-a700'
  | 'xl:tw-to-blue-a700'
  | 'hover:tw-to-blue-a700'
  | 'focus:tw-to-blue-a700'
  | 'sm:tw-from-lightBlue-50'
  | 'md:tw-from-lightBlue-50'
  | 'lg:tw-from-lightBlue-50'
  | 'xl:tw-from-lightBlue-50'
  | 'hover:tw-from-lightBlue-50'
  | 'focus:tw-from-lightBlue-50'
  | 'sm:tw-via-lightBlue-50'
  | 'md:tw-via-lightBlue-50'
  | 'lg:tw-via-lightBlue-50'
  | 'xl:tw-via-lightBlue-50'
  | 'hover:tw-via-lightBlue-50'
  | 'focus:tw-via-lightBlue-50'
  | 'sm:tw-to-lightBlue-50'
  | 'md:tw-to-lightBlue-50'
  | 'lg:tw-to-lightBlue-50'
  | 'xl:tw-to-lightBlue-50'
  | 'hover:tw-to-lightBlue-50'
  | 'focus:tw-to-lightBlue-50'
  | 'sm:tw-from-lightBlue-100'
  | 'md:tw-from-lightBlue-100'
  | 'lg:tw-from-lightBlue-100'
  | 'xl:tw-from-lightBlue-100'
  | 'hover:tw-from-lightBlue-100'
  | 'focus:tw-from-lightBlue-100'
  | 'sm:tw-via-lightBlue-100'
  | 'md:tw-via-lightBlue-100'
  | 'lg:tw-via-lightBlue-100'
  | 'xl:tw-via-lightBlue-100'
  | 'hover:tw-via-lightBlue-100'
  | 'focus:tw-via-lightBlue-100'
  | 'sm:tw-to-lightBlue-100'
  | 'md:tw-to-lightBlue-100'
  | 'lg:tw-to-lightBlue-100'
  | 'xl:tw-to-lightBlue-100'
  | 'hover:tw-to-lightBlue-100'
  | 'focus:tw-to-lightBlue-100'
  | 'sm:tw-from-lightBlue-200'
  | 'md:tw-from-lightBlue-200'
  | 'lg:tw-from-lightBlue-200'
  | 'xl:tw-from-lightBlue-200'
  | 'hover:tw-from-lightBlue-200'
  | 'focus:tw-from-lightBlue-200'
  | 'sm:tw-via-lightBlue-200'
  | 'md:tw-via-lightBlue-200'
  | 'lg:tw-via-lightBlue-200'
  | 'xl:tw-via-lightBlue-200'
  | 'hover:tw-via-lightBlue-200'
  | 'focus:tw-via-lightBlue-200'
  | 'sm:tw-to-lightBlue-200'
  | 'md:tw-to-lightBlue-200'
  | 'lg:tw-to-lightBlue-200'
  | 'xl:tw-to-lightBlue-200'
  | 'hover:tw-to-lightBlue-200'
  | 'focus:tw-to-lightBlue-200'
  | 'sm:tw-from-lightBlue-300'
  | 'md:tw-from-lightBlue-300'
  | 'lg:tw-from-lightBlue-300'
  | 'xl:tw-from-lightBlue-300'
  | 'hover:tw-from-lightBlue-300'
  | 'focus:tw-from-lightBlue-300'
  | 'sm:tw-via-lightBlue-300'
  | 'md:tw-via-lightBlue-300'
  | 'lg:tw-via-lightBlue-300'
  | 'xl:tw-via-lightBlue-300'
  | 'hover:tw-via-lightBlue-300'
  | 'focus:tw-via-lightBlue-300'
  | 'sm:tw-to-lightBlue-300'
  | 'md:tw-to-lightBlue-300'
  | 'lg:tw-to-lightBlue-300'
  | 'xl:tw-to-lightBlue-300'
  | 'hover:tw-to-lightBlue-300'
  | 'focus:tw-to-lightBlue-300'
  | 'sm:tw-from-lightBlue-400'
  | 'md:tw-from-lightBlue-400'
  | 'lg:tw-from-lightBlue-400'
  | 'xl:tw-from-lightBlue-400'
  | 'hover:tw-from-lightBlue-400'
  | 'focus:tw-from-lightBlue-400'
  | 'sm:tw-via-lightBlue-400'
  | 'md:tw-via-lightBlue-400'
  | 'lg:tw-via-lightBlue-400'
  | 'xl:tw-via-lightBlue-400'
  | 'hover:tw-via-lightBlue-400'
  | 'focus:tw-via-lightBlue-400'
  | 'sm:tw-to-lightBlue-400'
  | 'md:tw-to-lightBlue-400'
  | 'lg:tw-to-lightBlue-400'
  | 'xl:tw-to-lightBlue-400'
  | 'hover:tw-to-lightBlue-400'
  | 'focus:tw-to-lightBlue-400'
  | 'sm:tw-from-lightBlue-500'
  | 'md:tw-from-lightBlue-500'
  | 'lg:tw-from-lightBlue-500'
  | 'xl:tw-from-lightBlue-500'
  | 'hover:tw-from-lightBlue-500'
  | 'focus:tw-from-lightBlue-500'
  | 'sm:tw-via-lightBlue-500'
  | 'md:tw-via-lightBlue-500'
  | 'lg:tw-via-lightBlue-500'
  | 'xl:tw-via-lightBlue-500'
  | 'hover:tw-via-lightBlue-500'
  | 'focus:tw-via-lightBlue-500'
  | 'sm:tw-to-lightBlue-500'
  | 'md:tw-to-lightBlue-500'
  | 'lg:tw-to-lightBlue-500'
  | 'xl:tw-to-lightBlue-500'
  | 'hover:tw-to-lightBlue-500'
  | 'focus:tw-to-lightBlue-500'
  | 'sm:tw-from-lightBlue-600'
  | 'md:tw-from-lightBlue-600'
  | 'lg:tw-from-lightBlue-600'
  | 'xl:tw-from-lightBlue-600'
  | 'hover:tw-from-lightBlue-600'
  | 'focus:tw-from-lightBlue-600'
  | 'sm:tw-via-lightBlue-600'
  | 'md:tw-via-lightBlue-600'
  | 'lg:tw-via-lightBlue-600'
  | 'xl:tw-via-lightBlue-600'
  | 'hover:tw-via-lightBlue-600'
  | 'focus:tw-via-lightBlue-600'
  | 'sm:tw-to-lightBlue-600'
  | 'md:tw-to-lightBlue-600'
  | 'lg:tw-to-lightBlue-600'
  | 'xl:tw-to-lightBlue-600'
  | 'hover:tw-to-lightBlue-600'
  | 'focus:tw-to-lightBlue-600'
  | 'sm:tw-from-lightBlue-700'
  | 'md:tw-from-lightBlue-700'
  | 'lg:tw-from-lightBlue-700'
  | 'xl:tw-from-lightBlue-700'
  | 'hover:tw-from-lightBlue-700'
  | 'focus:tw-from-lightBlue-700'
  | 'sm:tw-via-lightBlue-700'
  | 'md:tw-via-lightBlue-700'
  | 'lg:tw-via-lightBlue-700'
  | 'xl:tw-via-lightBlue-700'
  | 'hover:tw-via-lightBlue-700'
  | 'focus:tw-via-lightBlue-700'
  | 'sm:tw-to-lightBlue-700'
  | 'md:tw-to-lightBlue-700'
  | 'lg:tw-to-lightBlue-700'
  | 'xl:tw-to-lightBlue-700'
  | 'hover:tw-to-lightBlue-700'
  | 'focus:tw-to-lightBlue-700'
  | 'sm:tw-from-lightBlue-800'
  | 'md:tw-from-lightBlue-800'
  | 'lg:tw-from-lightBlue-800'
  | 'xl:tw-from-lightBlue-800'
  | 'hover:tw-from-lightBlue-800'
  | 'focus:tw-from-lightBlue-800'
  | 'sm:tw-via-lightBlue-800'
  | 'md:tw-via-lightBlue-800'
  | 'lg:tw-via-lightBlue-800'
  | 'xl:tw-via-lightBlue-800'
  | 'hover:tw-via-lightBlue-800'
  | 'focus:tw-via-lightBlue-800'
  | 'sm:tw-to-lightBlue-800'
  | 'md:tw-to-lightBlue-800'
  | 'lg:tw-to-lightBlue-800'
  | 'xl:tw-to-lightBlue-800'
  | 'hover:tw-to-lightBlue-800'
  | 'focus:tw-to-lightBlue-800'
  | 'sm:tw-from-lightBlue-900'
  | 'md:tw-from-lightBlue-900'
  | 'lg:tw-from-lightBlue-900'
  | 'xl:tw-from-lightBlue-900'
  | 'hover:tw-from-lightBlue-900'
  | 'focus:tw-from-lightBlue-900'
  | 'sm:tw-via-lightBlue-900'
  | 'md:tw-via-lightBlue-900'
  | 'lg:tw-via-lightBlue-900'
  | 'xl:tw-via-lightBlue-900'
  | 'hover:tw-via-lightBlue-900'
  | 'focus:tw-via-lightBlue-900'
  | 'sm:tw-to-lightBlue-900'
  | 'md:tw-to-lightBlue-900'
  | 'lg:tw-to-lightBlue-900'
  | 'xl:tw-to-lightBlue-900'
  | 'hover:tw-to-lightBlue-900'
  | 'focus:tw-to-lightBlue-900'
  | 'sm:tw-from-lightBlue-a100'
  | 'md:tw-from-lightBlue-a100'
  | 'lg:tw-from-lightBlue-a100'
  | 'xl:tw-from-lightBlue-a100'
  | 'hover:tw-from-lightBlue-a100'
  | 'focus:tw-from-lightBlue-a100'
  | 'sm:tw-via-lightBlue-a100'
  | 'md:tw-via-lightBlue-a100'
  | 'lg:tw-via-lightBlue-a100'
  | 'xl:tw-via-lightBlue-a100'
  | 'hover:tw-via-lightBlue-a100'
  | 'focus:tw-via-lightBlue-a100'
  | 'sm:tw-to-lightBlue-a100'
  | 'md:tw-to-lightBlue-a100'
  | 'lg:tw-to-lightBlue-a100'
  | 'xl:tw-to-lightBlue-a100'
  | 'hover:tw-to-lightBlue-a100'
  | 'focus:tw-to-lightBlue-a100'
  | 'sm:tw-from-lightBlue-a200'
  | 'md:tw-from-lightBlue-a200'
  | 'lg:tw-from-lightBlue-a200'
  | 'xl:tw-from-lightBlue-a200'
  | 'hover:tw-from-lightBlue-a200'
  | 'focus:tw-from-lightBlue-a200'
  | 'sm:tw-via-lightBlue-a200'
  | 'md:tw-via-lightBlue-a200'
  | 'lg:tw-via-lightBlue-a200'
  | 'xl:tw-via-lightBlue-a200'
  | 'hover:tw-via-lightBlue-a200'
  | 'focus:tw-via-lightBlue-a200'
  | 'sm:tw-to-lightBlue-a200'
  | 'md:tw-to-lightBlue-a200'
  | 'lg:tw-to-lightBlue-a200'
  | 'xl:tw-to-lightBlue-a200'
  | 'hover:tw-to-lightBlue-a200'
  | 'focus:tw-to-lightBlue-a200'
  | 'sm:tw-from-lightBlue-a400'
  | 'md:tw-from-lightBlue-a400'
  | 'lg:tw-from-lightBlue-a400'
  | 'xl:tw-from-lightBlue-a400'
  | 'hover:tw-from-lightBlue-a400'
  | 'focus:tw-from-lightBlue-a400'
  | 'sm:tw-via-lightBlue-a400'
  | 'md:tw-via-lightBlue-a400'
  | 'lg:tw-via-lightBlue-a400'
  | 'xl:tw-via-lightBlue-a400'
  | 'hover:tw-via-lightBlue-a400'
  | 'focus:tw-via-lightBlue-a400'
  | 'sm:tw-to-lightBlue-a400'
  | 'md:tw-to-lightBlue-a400'
  | 'lg:tw-to-lightBlue-a400'
  | 'xl:tw-to-lightBlue-a400'
  | 'hover:tw-to-lightBlue-a400'
  | 'focus:tw-to-lightBlue-a400'
  | 'sm:tw-from-lightBlue-a700'
  | 'md:tw-from-lightBlue-a700'
  | 'lg:tw-from-lightBlue-a700'
  | 'xl:tw-from-lightBlue-a700'
  | 'hover:tw-from-lightBlue-a700'
  | 'focus:tw-from-lightBlue-a700'
  | 'sm:tw-via-lightBlue-a700'
  | 'md:tw-via-lightBlue-a700'
  | 'lg:tw-via-lightBlue-a700'
  | 'xl:tw-via-lightBlue-a700'
  | 'hover:tw-via-lightBlue-a700'
  | 'focus:tw-via-lightBlue-a700'
  | 'sm:tw-to-lightBlue-a700'
  | 'md:tw-to-lightBlue-a700'
  | 'lg:tw-to-lightBlue-a700'
  | 'xl:tw-to-lightBlue-a700'
  | 'hover:tw-to-lightBlue-a700'
  | 'focus:tw-to-lightBlue-a700'
  | 'sm:tw-from-cyan-50'
  | 'md:tw-from-cyan-50'
  | 'lg:tw-from-cyan-50'
  | 'xl:tw-from-cyan-50'
  | 'hover:tw-from-cyan-50'
  | 'focus:tw-from-cyan-50'
  | 'sm:tw-via-cyan-50'
  | 'md:tw-via-cyan-50'
  | 'lg:tw-via-cyan-50'
  | 'xl:tw-via-cyan-50'
  | 'hover:tw-via-cyan-50'
  | 'focus:tw-via-cyan-50'
  | 'sm:tw-to-cyan-50'
  | 'md:tw-to-cyan-50'
  | 'lg:tw-to-cyan-50'
  | 'xl:tw-to-cyan-50'
  | 'hover:tw-to-cyan-50'
  | 'focus:tw-to-cyan-50'
  | 'sm:tw-from-cyan-100'
  | 'md:tw-from-cyan-100'
  | 'lg:tw-from-cyan-100'
  | 'xl:tw-from-cyan-100'
  | 'hover:tw-from-cyan-100'
  | 'focus:tw-from-cyan-100'
  | 'sm:tw-via-cyan-100'
  | 'md:tw-via-cyan-100'
  | 'lg:tw-via-cyan-100'
  | 'xl:tw-via-cyan-100'
  | 'hover:tw-via-cyan-100'
  | 'focus:tw-via-cyan-100'
  | 'sm:tw-to-cyan-100'
  | 'md:tw-to-cyan-100'
  | 'lg:tw-to-cyan-100'
  | 'xl:tw-to-cyan-100'
  | 'hover:tw-to-cyan-100'
  | 'focus:tw-to-cyan-100'
  | 'sm:tw-from-cyan-200'
  | 'md:tw-from-cyan-200'
  | 'lg:tw-from-cyan-200'
  | 'xl:tw-from-cyan-200'
  | 'hover:tw-from-cyan-200'
  | 'focus:tw-from-cyan-200'
  | 'sm:tw-via-cyan-200'
  | 'md:tw-via-cyan-200'
  | 'lg:tw-via-cyan-200'
  | 'xl:tw-via-cyan-200'
  | 'hover:tw-via-cyan-200'
  | 'focus:tw-via-cyan-200'
  | 'sm:tw-to-cyan-200'
  | 'md:tw-to-cyan-200'
  | 'lg:tw-to-cyan-200'
  | 'xl:tw-to-cyan-200'
  | 'hover:tw-to-cyan-200'
  | 'focus:tw-to-cyan-200'
  | 'sm:tw-from-cyan-300'
  | 'md:tw-from-cyan-300'
  | 'lg:tw-from-cyan-300'
  | 'xl:tw-from-cyan-300'
  | 'hover:tw-from-cyan-300'
  | 'focus:tw-from-cyan-300'
  | 'sm:tw-via-cyan-300'
  | 'md:tw-via-cyan-300'
  | 'lg:tw-via-cyan-300'
  | 'xl:tw-via-cyan-300'
  | 'hover:tw-via-cyan-300'
  | 'focus:tw-via-cyan-300'
  | 'sm:tw-to-cyan-300'
  | 'md:tw-to-cyan-300'
  | 'lg:tw-to-cyan-300'
  | 'xl:tw-to-cyan-300'
  | 'hover:tw-to-cyan-300'
  | 'focus:tw-to-cyan-300'
  | 'sm:tw-from-cyan-400'
  | 'md:tw-from-cyan-400'
  | 'lg:tw-from-cyan-400'
  | 'xl:tw-from-cyan-400'
  | 'hover:tw-from-cyan-400'
  | 'focus:tw-from-cyan-400'
  | 'sm:tw-via-cyan-400'
  | 'md:tw-via-cyan-400'
  | 'lg:tw-via-cyan-400'
  | 'xl:tw-via-cyan-400'
  | 'hover:tw-via-cyan-400'
  | 'focus:tw-via-cyan-400'
  | 'sm:tw-to-cyan-400'
  | 'md:tw-to-cyan-400'
  | 'lg:tw-to-cyan-400'
  | 'xl:tw-to-cyan-400'
  | 'hover:tw-to-cyan-400'
  | 'focus:tw-to-cyan-400'
  | 'sm:tw-from-cyan-500'
  | 'md:tw-from-cyan-500'
  | 'lg:tw-from-cyan-500'
  | 'xl:tw-from-cyan-500'
  | 'hover:tw-from-cyan-500'
  | 'focus:tw-from-cyan-500'
  | 'sm:tw-via-cyan-500'
  | 'md:tw-via-cyan-500'
  | 'lg:tw-via-cyan-500'
  | 'xl:tw-via-cyan-500'
  | 'hover:tw-via-cyan-500'
  | 'focus:tw-via-cyan-500'
  | 'sm:tw-to-cyan-500'
  | 'md:tw-to-cyan-500'
  | 'lg:tw-to-cyan-500'
  | 'xl:tw-to-cyan-500'
  | 'hover:tw-to-cyan-500'
  | 'focus:tw-to-cyan-500'
  | 'sm:tw-from-cyan-600'
  | 'md:tw-from-cyan-600'
  | 'lg:tw-from-cyan-600'
  | 'xl:tw-from-cyan-600'
  | 'hover:tw-from-cyan-600'
  | 'focus:tw-from-cyan-600'
  | 'sm:tw-via-cyan-600'
  | 'md:tw-via-cyan-600'
  | 'lg:tw-via-cyan-600'
  | 'xl:tw-via-cyan-600'
  | 'hover:tw-via-cyan-600'
  | 'focus:tw-via-cyan-600'
  | 'sm:tw-to-cyan-600'
  | 'md:tw-to-cyan-600'
  | 'lg:tw-to-cyan-600'
  | 'xl:tw-to-cyan-600'
  | 'hover:tw-to-cyan-600'
  | 'focus:tw-to-cyan-600'
  | 'sm:tw-from-cyan-700'
  | 'md:tw-from-cyan-700'
  | 'lg:tw-from-cyan-700'
  | 'xl:tw-from-cyan-700'
  | 'hover:tw-from-cyan-700'
  | 'focus:tw-from-cyan-700'
  | 'sm:tw-via-cyan-700'
  | 'md:tw-via-cyan-700'
  | 'lg:tw-via-cyan-700'
  | 'xl:tw-via-cyan-700'
  | 'hover:tw-via-cyan-700'
  | 'focus:tw-via-cyan-700'
  | 'sm:tw-to-cyan-700'
  | 'md:tw-to-cyan-700'
  | 'lg:tw-to-cyan-700'
  | 'xl:tw-to-cyan-700'
  | 'hover:tw-to-cyan-700'
  | 'focus:tw-to-cyan-700'
  | 'sm:tw-from-cyan-800'
  | 'md:tw-from-cyan-800'
  | 'lg:tw-from-cyan-800'
  | 'xl:tw-from-cyan-800'
  | 'hover:tw-from-cyan-800'
  | 'focus:tw-from-cyan-800'
  | 'sm:tw-via-cyan-800'
  | 'md:tw-via-cyan-800'
  | 'lg:tw-via-cyan-800'
  | 'xl:tw-via-cyan-800'
  | 'hover:tw-via-cyan-800'
  | 'focus:tw-via-cyan-800'
  | 'sm:tw-to-cyan-800'
  | 'md:tw-to-cyan-800'
  | 'lg:tw-to-cyan-800'
  | 'xl:tw-to-cyan-800'
  | 'hover:tw-to-cyan-800'
  | 'focus:tw-to-cyan-800'
  | 'sm:tw-from-cyan-900'
  | 'md:tw-from-cyan-900'
  | 'lg:tw-from-cyan-900'
  | 'xl:tw-from-cyan-900'
  | 'hover:tw-from-cyan-900'
  | 'focus:tw-from-cyan-900'
  | 'sm:tw-via-cyan-900'
  | 'md:tw-via-cyan-900'
  | 'lg:tw-via-cyan-900'
  | 'xl:tw-via-cyan-900'
  | 'hover:tw-via-cyan-900'
  | 'focus:tw-via-cyan-900'
  | 'sm:tw-to-cyan-900'
  | 'md:tw-to-cyan-900'
  | 'lg:tw-to-cyan-900'
  | 'xl:tw-to-cyan-900'
  | 'hover:tw-to-cyan-900'
  | 'focus:tw-to-cyan-900'
  | 'sm:tw-from-cyan-a100'
  | 'md:tw-from-cyan-a100'
  | 'lg:tw-from-cyan-a100'
  | 'xl:tw-from-cyan-a100'
  | 'hover:tw-from-cyan-a100'
  | 'focus:tw-from-cyan-a100'
  | 'sm:tw-via-cyan-a100'
  | 'md:tw-via-cyan-a100'
  | 'lg:tw-via-cyan-a100'
  | 'xl:tw-via-cyan-a100'
  | 'hover:tw-via-cyan-a100'
  | 'focus:tw-via-cyan-a100'
  | 'sm:tw-to-cyan-a100'
  | 'md:tw-to-cyan-a100'
  | 'lg:tw-to-cyan-a100'
  | 'xl:tw-to-cyan-a100'
  | 'hover:tw-to-cyan-a100'
  | 'focus:tw-to-cyan-a100'
  | 'sm:tw-from-cyan-a200'
  | 'md:tw-from-cyan-a200'
  | 'lg:tw-from-cyan-a200'
  | 'xl:tw-from-cyan-a200'
  | 'hover:tw-from-cyan-a200'
  | 'focus:tw-from-cyan-a200'
  | 'sm:tw-via-cyan-a200'
  | 'md:tw-via-cyan-a200'
  | 'lg:tw-via-cyan-a200'
  | 'xl:tw-via-cyan-a200'
  | 'hover:tw-via-cyan-a200'
  | 'focus:tw-via-cyan-a200'
  | 'sm:tw-to-cyan-a200'
  | 'md:tw-to-cyan-a200'
  | 'lg:tw-to-cyan-a200'
  | 'xl:tw-to-cyan-a200'
  | 'hover:tw-to-cyan-a200'
  | 'focus:tw-to-cyan-a200'
  | 'sm:tw-from-cyan-a400'
  | 'md:tw-from-cyan-a400'
  | 'lg:tw-from-cyan-a400'
  | 'xl:tw-from-cyan-a400'
  | 'hover:tw-from-cyan-a400'
  | 'focus:tw-from-cyan-a400'
  | 'sm:tw-via-cyan-a400'
  | 'md:tw-via-cyan-a400'
  | 'lg:tw-via-cyan-a400'
  | 'xl:tw-via-cyan-a400'
  | 'hover:tw-via-cyan-a400'
  | 'focus:tw-via-cyan-a400'
  | 'sm:tw-to-cyan-a400'
  | 'md:tw-to-cyan-a400'
  | 'lg:tw-to-cyan-a400'
  | 'xl:tw-to-cyan-a400'
  | 'hover:tw-to-cyan-a400'
  | 'focus:tw-to-cyan-a400'
  | 'sm:tw-from-cyan-a700'
  | 'md:tw-from-cyan-a700'
  | 'lg:tw-from-cyan-a700'
  | 'xl:tw-from-cyan-a700'
  | 'hover:tw-from-cyan-a700'
  | 'focus:tw-from-cyan-a700'
  | 'sm:tw-via-cyan-a700'
  | 'md:tw-via-cyan-a700'
  | 'lg:tw-via-cyan-a700'
  | 'xl:tw-via-cyan-a700'
  | 'hover:tw-via-cyan-a700'
  | 'focus:tw-via-cyan-a700'
  | 'sm:tw-to-cyan-a700'
  | 'md:tw-to-cyan-a700'
  | 'lg:tw-to-cyan-a700'
  | 'xl:tw-to-cyan-a700'
  | 'hover:tw-to-cyan-a700'
  | 'focus:tw-to-cyan-a700'
  | 'sm:tw-from-teal-50'
  | 'md:tw-from-teal-50'
  | 'lg:tw-from-teal-50'
  | 'xl:tw-from-teal-50'
  | 'hover:tw-from-teal-50'
  | 'focus:tw-from-teal-50'
  | 'sm:tw-via-teal-50'
  | 'md:tw-via-teal-50'
  | 'lg:tw-via-teal-50'
  | 'xl:tw-via-teal-50'
  | 'hover:tw-via-teal-50'
  | 'focus:tw-via-teal-50'
  | 'sm:tw-to-teal-50'
  | 'md:tw-to-teal-50'
  | 'lg:tw-to-teal-50'
  | 'xl:tw-to-teal-50'
  | 'hover:tw-to-teal-50'
  | 'focus:tw-to-teal-50'
  | 'sm:tw-from-teal-100'
  | 'md:tw-from-teal-100'
  | 'lg:tw-from-teal-100'
  | 'xl:tw-from-teal-100'
  | 'hover:tw-from-teal-100'
  | 'focus:tw-from-teal-100'
  | 'sm:tw-via-teal-100'
  | 'md:tw-via-teal-100'
  | 'lg:tw-via-teal-100'
  | 'xl:tw-via-teal-100'
  | 'hover:tw-via-teal-100'
  | 'focus:tw-via-teal-100'
  | 'sm:tw-to-teal-100'
  | 'md:tw-to-teal-100'
  | 'lg:tw-to-teal-100'
  | 'xl:tw-to-teal-100'
  | 'hover:tw-to-teal-100'
  | 'focus:tw-to-teal-100'
  | 'sm:tw-from-teal-200'
  | 'md:tw-from-teal-200'
  | 'lg:tw-from-teal-200'
  | 'xl:tw-from-teal-200'
  | 'hover:tw-from-teal-200'
  | 'focus:tw-from-teal-200'
  | 'sm:tw-via-teal-200'
  | 'md:tw-via-teal-200'
  | 'lg:tw-via-teal-200'
  | 'xl:tw-via-teal-200'
  | 'hover:tw-via-teal-200'
  | 'focus:tw-via-teal-200'
  | 'sm:tw-to-teal-200'
  | 'md:tw-to-teal-200'
  | 'lg:tw-to-teal-200'
  | 'xl:tw-to-teal-200'
  | 'hover:tw-to-teal-200'
  | 'focus:tw-to-teal-200'
  | 'sm:tw-from-teal-300'
  | 'md:tw-from-teal-300'
  | 'lg:tw-from-teal-300'
  | 'xl:tw-from-teal-300'
  | 'hover:tw-from-teal-300'
  | 'focus:tw-from-teal-300'
  | 'sm:tw-via-teal-300'
  | 'md:tw-via-teal-300'
  | 'lg:tw-via-teal-300'
  | 'xl:tw-via-teal-300'
  | 'hover:tw-via-teal-300'
  | 'focus:tw-via-teal-300'
  | 'sm:tw-to-teal-300'
  | 'md:tw-to-teal-300'
  | 'lg:tw-to-teal-300'
  | 'xl:tw-to-teal-300'
  | 'hover:tw-to-teal-300'
  | 'focus:tw-to-teal-300'
  | 'sm:tw-from-teal-400'
  | 'md:tw-from-teal-400'
  | 'lg:tw-from-teal-400'
  | 'xl:tw-from-teal-400'
  | 'hover:tw-from-teal-400'
  | 'focus:tw-from-teal-400'
  | 'sm:tw-via-teal-400'
  | 'md:tw-via-teal-400'
  | 'lg:tw-via-teal-400'
  | 'xl:tw-via-teal-400'
  | 'hover:tw-via-teal-400'
  | 'focus:tw-via-teal-400'
  | 'sm:tw-to-teal-400'
  | 'md:tw-to-teal-400'
  | 'lg:tw-to-teal-400'
  | 'xl:tw-to-teal-400'
  | 'hover:tw-to-teal-400'
  | 'focus:tw-to-teal-400'
  | 'sm:tw-from-teal-500'
  | 'md:tw-from-teal-500'
  | 'lg:tw-from-teal-500'
  | 'xl:tw-from-teal-500'
  | 'hover:tw-from-teal-500'
  | 'focus:tw-from-teal-500'
  | 'sm:tw-via-teal-500'
  | 'md:tw-via-teal-500'
  | 'lg:tw-via-teal-500'
  | 'xl:tw-via-teal-500'
  | 'hover:tw-via-teal-500'
  | 'focus:tw-via-teal-500'
  | 'sm:tw-to-teal-500'
  | 'md:tw-to-teal-500'
  | 'lg:tw-to-teal-500'
  | 'xl:tw-to-teal-500'
  | 'hover:tw-to-teal-500'
  | 'focus:tw-to-teal-500'
  | 'sm:tw-from-teal-600'
  | 'md:tw-from-teal-600'
  | 'lg:tw-from-teal-600'
  | 'xl:tw-from-teal-600'
  | 'hover:tw-from-teal-600'
  | 'focus:tw-from-teal-600'
  | 'sm:tw-via-teal-600'
  | 'md:tw-via-teal-600'
  | 'lg:tw-via-teal-600'
  | 'xl:tw-via-teal-600'
  | 'hover:tw-via-teal-600'
  | 'focus:tw-via-teal-600'
  | 'sm:tw-to-teal-600'
  | 'md:tw-to-teal-600'
  | 'lg:tw-to-teal-600'
  | 'xl:tw-to-teal-600'
  | 'hover:tw-to-teal-600'
  | 'focus:tw-to-teal-600'
  | 'sm:tw-from-teal-700'
  | 'md:tw-from-teal-700'
  | 'lg:tw-from-teal-700'
  | 'xl:tw-from-teal-700'
  | 'hover:tw-from-teal-700'
  | 'focus:tw-from-teal-700'
  | 'sm:tw-via-teal-700'
  | 'md:tw-via-teal-700'
  | 'lg:tw-via-teal-700'
  | 'xl:tw-via-teal-700'
  | 'hover:tw-via-teal-700'
  | 'focus:tw-via-teal-700'
  | 'sm:tw-to-teal-700'
  | 'md:tw-to-teal-700'
  | 'lg:tw-to-teal-700'
  | 'xl:tw-to-teal-700'
  | 'hover:tw-to-teal-700'
  | 'focus:tw-to-teal-700'
  | 'sm:tw-from-teal-800'
  | 'md:tw-from-teal-800'
  | 'lg:tw-from-teal-800'
  | 'xl:tw-from-teal-800'
  | 'hover:tw-from-teal-800'
  | 'focus:tw-from-teal-800'
  | 'sm:tw-via-teal-800'
  | 'md:tw-via-teal-800'
  | 'lg:tw-via-teal-800'
  | 'xl:tw-via-teal-800'
  | 'hover:tw-via-teal-800'
  | 'focus:tw-via-teal-800'
  | 'sm:tw-to-teal-800'
  | 'md:tw-to-teal-800'
  | 'lg:tw-to-teal-800'
  | 'xl:tw-to-teal-800'
  | 'hover:tw-to-teal-800'
  | 'focus:tw-to-teal-800'
  | 'sm:tw-from-teal-900'
  | 'md:tw-from-teal-900'
  | 'lg:tw-from-teal-900'
  | 'xl:tw-from-teal-900'
  | 'hover:tw-from-teal-900'
  | 'focus:tw-from-teal-900'
  | 'sm:tw-via-teal-900'
  | 'md:tw-via-teal-900'
  | 'lg:tw-via-teal-900'
  | 'xl:tw-via-teal-900'
  | 'hover:tw-via-teal-900'
  | 'focus:tw-via-teal-900'
  | 'sm:tw-to-teal-900'
  | 'md:tw-to-teal-900'
  | 'lg:tw-to-teal-900'
  | 'xl:tw-to-teal-900'
  | 'hover:tw-to-teal-900'
  | 'focus:tw-to-teal-900'
  | 'sm:tw-from-teal-a100'
  | 'md:tw-from-teal-a100'
  | 'lg:tw-from-teal-a100'
  | 'xl:tw-from-teal-a100'
  | 'hover:tw-from-teal-a100'
  | 'focus:tw-from-teal-a100'
  | 'sm:tw-via-teal-a100'
  | 'md:tw-via-teal-a100'
  | 'lg:tw-via-teal-a100'
  | 'xl:tw-via-teal-a100'
  | 'hover:tw-via-teal-a100'
  | 'focus:tw-via-teal-a100'
  | 'sm:tw-to-teal-a100'
  | 'md:tw-to-teal-a100'
  | 'lg:tw-to-teal-a100'
  | 'xl:tw-to-teal-a100'
  | 'hover:tw-to-teal-a100'
  | 'focus:tw-to-teal-a100'
  | 'sm:tw-from-teal-a200'
  | 'md:tw-from-teal-a200'
  | 'lg:tw-from-teal-a200'
  | 'xl:tw-from-teal-a200'
  | 'hover:tw-from-teal-a200'
  | 'focus:tw-from-teal-a200'
  | 'sm:tw-via-teal-a200'
  | 'md:tw-via-teal-a200'
  | 'lg:tw-via-teal-a200'
  | 'xl:tw-via-teal-a200'
  | 'hover:tw-via-teal-a200'
  | 'focus:tw-via-teal-a200'
  | 'sm:tw-to-teal-a200'
  | 'md:tw-to-teal-a200'
  | 'lg:tw-to-teal-a200'
  | 'xl:tw-to-teal-a200'
  | 'hover:tw-to-teal-a200'
  | 'focus:tw-to-teal-a200'
  | 'sm:tw-from-teal-a400'
  | 'md:tw-from-teal-a400'
  | 'lg:tw-from-teal-a400'
  | 'xl:tw-from-teal-a400'
  | 'hover:tw-from-teal-a400'
  | 'focus:tw-from-teal-a400'
  | 'sm:tw-via-teal-a400'
  | 'md:tw-via-teal-a400'
  | 'lg:tw-via-teal-a400'
  | 'xl:tw-via-teal-a400'
  | 'hover:tw-via-teal-a400'
  | 'focus:tw-via-teal-a400'
  | 'sm:tw-to-teal-a400'
  | 'md:tw-to-teal-a400'
  | 'lg:tw-to-teal-a400'
  | 'xl:tw-to-teal-a400'
  | 'hover:tw-to-teal-a400'
  | 'focus:tw-to-teal-a400'
  | 'sm:tw-from-teal-a700'
  | 'md:tw-from-teal-a700'
  | 'lg:tw-from-teal-a700'
  | 'xl:tw-from-teal-a700'
  | 'hover:tw-from-teal-a700'
  | 'focus:tw-from-teal-a700'
  | 'sm:tw-via-teal-a700'
  | 'md:tw-via-teal-a700'
  | 'lg:tw-via-teal-a700'
  | 'xl:tw-via-teal-a700'
  | 'hover:tw-via-teal-a700'
  | 'focus:tw-via-teal-a700'
  | 'sm:tw-to-teal-a700'
  | 'md:tw-to-teal-a700'
  | 'lg:tw-to-teal-a700'
  | 'xl:tw-to-teal-a700'
  | 'hover:tw-to-teal-a700'
  | 'focus:tw-to-teal-a700'
  | 'sm:tw-from-green-50'
  | 'md:tw-from-green-50'
  | 'lg:tw-from-green-50'
  | 'xl:tw-from-green-50'
  | 'hover:tw-from-green-50'
  | 'focus:tw-from-green-50'
  | 'sm:tw-via-green-50'
  | 'md:tw-via-green-50'
  | 'lg:tw-via-green-50'
  | 'xl:tw-via-green-50'
  | 'hover:tw-via-green-50'
  | 'focus:tw-via-green-50'
  | 'sm:tw-to-green-50'
  | 'md:tw-to-green-50'
  | 'lg:tw-to-green-50'
  | 'xl:tw-to-green-50'
  | 'hover:tw-to-green-50'
  | 'focus:tw-to-green-50'
  | 'sm:tw-from-green-100'
  | 'md:tw-from-green-100'
  | 'lg:tw-from-green-100'
  | 'xl:tw-from-green-100'
  | 'hover:tw-from-green-100'
  | 'focus:tw-from-green-100'
  | 'sm:tw-via-green-100'
  | 'md:tw-via-green-100'
  | 'lg:tw-via-green-100'
  | 'xl:tw-via-green-100'
  | 'hover:tw-via-green-100'
  | 'focus:tw-via-green-100'
  | 'sm:tw-to-green-100'
  | 'md:tw-to-green-100'
  | 'lg:tw-to-green-100'
  | 'xl:tw-to-green-100'
  | 'hover:tw-to-green-100'
  | 'focus:tw-to-green-100'
  | 'sm:tw-from-green-200'
  | 'md:tw-from-green-200'
  | 'lg:tw-from-green-200'
  | 'xl:tw-from-green-200'
  | 'hover:tw-from-green-200'
  | 'focus:tw-from-green-200'
  | 'sm:tw-via-green-200'
  | 'md:tw-via-green-200'
  | 'lg:tw-via-green-200'
  | 'xl:tw-via-green-200'
  | 'hover:tw-via-green-200'
  | 'focus:tw-via-green-200'
  | 'sm:tw-to-green-200'
  | 'md:tw-to-green-200'
  | 'lg:tw-to-green-200'
  | 'xl:tw-to-green-200'
  | 'hover:tw-to-green-200'
  | 'focus:tw-to-green-200'
  | 'sm:tw-from-green-300'
  | 'md:tw-from-green-300'
  | 'lg:tw-from-green-300'
  | 'xl:tw-from-green-300'
  | 'hover:tw-from-green-300'
  | 'focus:tw-from-green-300'
  | 'sm:tw-via-green-300'
  | 'md:tw-via-green-300'
  | 'lg:tw-via-green-300'
  | 'xl:tw-via-green-300'
  | 'hover:tw-via-green-300'
  | 'focus:tw-via-green-300'
  | 'sm:tw-to-green-300'
  | 'md:tw-to-green-300'
  | 'lg:tw-to-green-300'
  | 'xl:tw-to-green-300'
  | 'hover:tw-to-green-300'
  | 'focus:tw-to-green-300'
  | 'sm:tw-from-green-400'
  | 'md:tw-from-green-400'
  | 'lg:tw-from-green-400'
  | 'xl:tw-from-green-400'
  | 'hover:tw-from-green-400'
  | 'focus:tw-from-green-400'
  | 'sm:tw-via-green-400'
  | 'md:tw-via-green-400'
  | 'lg:tw-via-green-400'
  | 'xl:tw-via-green-400'
  | 'hover:tw-via-green-400'
  | 'focus:tw-via-green-400'
  | 'sm:tw-to-green-400'
  | 'md:tw-to-green-400'
  | 'lg:tw-to-green-400'
  | 'xl:tw-to-green-400'
  | 'hover:tw-to-green-400'
  | 'focus:tw-to-green-400'
  | 'sm:tw-from-green-500'
  | 'md:tw-from-green-500'
  | 'lg:tw-from-green-500'
  | 'xl:tw-from-green-500'
  | 'hover:tw-from-green-500'
  | 'focus:tw-from-green-500'
  | 'sm:tw-via-green-500'
  | 'md:tw-via-green-500'
  | 'lg:tw-via-green-500'
  | 'xl:tw-via-green-500'
  | 'hover:tw-via-green-500'
  | 'focus:tw-via-green-500'
  | 'sm:tw-to-green-500'
  | 'md:tw-to-green-500'
  | 'lg:tw-to-green-500'
  | 'xl:tw-to-green-500'
  | 'hover:tw-to-green-500'
  | 'focus:tw-to-green-500'
  | 'sm:tw-from-green-600'
  | 'md:tw-from-green-600'
  | 'lg:tw-from-green-600'
  | 'xl:tw-from-green-600'
  | 'hover:tw-from-green-600'
  | 'focus:tw-from-green-600'
  | 'sm:tw-via-green-600'
  | 'md:tw-via-green-600'
  | 'lg:tw-via-green-600'
  | 'xl:tw-via-green-600'
  | 'hover:tw-via-green-600'
  | 'focus:tw-via-green-600'
  | 'sm:tw-to-green-600'
  | 'md:tw-to-green-600'
  | 'lg:tw-to-green-600'
  | 'xl:tw-to-green-600'
  | 'hover:tw-to-green-600'
  | 'focus:tw-to-green-600'
  | 'sm:tw-from-green-700'
  | 'md:tw-from-green-700'
  | 'lg:tw-from-green-700'
  | 'xl:tw-from-green-700'
  | 'hover:tw-from-green-700'
  | 'focus:tw-from-green-700'
  | 'sm:tw-via-green-700'
  | 'md:tw-via-green-700'
  | 'lg:tw-via-green-700'
  | 'xl:tw-via-green-700'
  | 'hover:tw-via-green-700'
  | 'focus:tw-via-green-700'
  | 'sm:tw-to-green-700'
  | 'md:tw-to-green-700'
  | 'lg:tw-to-green-700'
  | 'xl:tw-to-green-700'
  | 'hover:tw-to-green-700'
  | 'focus:tw-to-green-700'
  | 'sm:tw-from-green-800'
  | 'md:tw-from-green-800'
  | 'lg:tw-from-green-800'
  | 'xl:tw-from-green-800'
  | 'hover:tw-from-green-800'
  | 'focus:tw-from-green-800'
  | 'sm:tw-via-green-800'
  | 'md:tw-via-green-800'
  | 'lg:tw-via-green-800'
  | 'xl:tw-via-green-800'
  | 'hover:tw-via-green-800'
  | 'focus:tw-via-green-800'
  | 'sm:tw-to-green-800'
  | 'md:tw-to-green-800'
  | 'lg:tw-to-green-800'
  | 'xl:tw-to-green-800'
  | 'hover:tw-to-green-800'
  | 'focus:tw-to-green-800'
  | 'sm:tw-from-green-900'
  | 'md:tw-from-green-900'
  | 'lg:tw-from-green-900'
  | 'xl:tw-from-green-900'
  | 'hover:tw-from-green-900'
  | 'focus:tw-from-green-900'
  | 'sm:tw-via-green-900'
  | 'md:tw-via-green-900'
  | 'lg:tw-via-green-900'
  | 'xl:tw-via-green-900'
  | 'hover:tw-via-green-900'
  | 'focus:tw-via-green-900'
  | 'sm:tw-to-green-900'
  | 'md:tw-to-green-900'
  | 'lg:tw-to-green-900'
  | 'xl:tw-to-green-900'
  | 'hover:tw-to-green-900'
  | 'focus:tw-to-green-900'
  | 'sm:tw-from-green-a100'
  | 'md:tw-from-green-a100'
  | 'lg:tw-from-green-a100'
  | 'xl:tw-from-green-a100'
  | 'hover:tw-from-green-a100'
  | 'focus:tw-from-green-a100'
  | 'sm:tw-via-green-a100'
  | 'md:tw-via-green-a100'
  | 'lg:tw-via-green-a100'
  | 'xl:tw-via-green-a100'
  | 'hover:tw-via-green-a100'
  | 'focus:tw-via-green-a100'
  | 'sm:tw-to-green-a100'
  | 'md:tw-to-green-a100'
  | 'lg:tw-to-green-a100'
  | 'xl:tw-to-green-a100'
  | 'hover:tw-to-green-a100'
  | 'focus:tw-to-green-a100'
  | 'sm:tw-from-green-a200'
  | 'md:tw-from-green-a200'
  | 'lg:tw-from-green-a200'
  | 'xl:tw-from-green-a200'
  | 'hover:tw-from-green-a200'
  | 'focus:tw-from-green-a200'
  | 'sm:tw-via-green-a200'
  | 'md:tw-via-green-a200'
  | 'lg:tw-via-green-a200'
  | 'xl:tw-via-green-a200'
  | 'hover:tw-via-green-a200'
  | 'focus:tw-via-green-a200'
  | 'sm:tw-to-green-a200'
  | 'md:tw-to-green-a200'
  | 'lg:tw-to-green-a200'
  | 'xl:tw-to-green-a200'
  | 'hover:tw-to-green-a200'
  | 'focus:tw-to-green-a200'
  | 'sm:tw-from-green-a400'
  | 'md:tw-from-green-a400'
  | 'lg:tw-from-green-a400'
  | 'xl:tw-from-green-a400'
  | 'hover:tw-from-green-a400'
  | 'focus:tw-from-green-a400'
  | 'sm:tw-via-green-a400'
  | 'md:tw-via-green-a400'
  | 'lg:tw-via-green-a400'
  | 'xl:tw-via-green-a400'
  | 'hover:tw-via-green-a400'
  | 'focus:tw-via-green-a400'
  | 'sm:tw-to-green-a400'
  | 'md:tw-to-green-a400'
  | 'lg:tw-to-green-a400'
  | 'xl:tw-to-green-a400'
  | 'hover:tw-to-green-a400'
  | 'focus:tw-to-green-a400'
  | 'sm:tw-from-green-a700'
  | 'md:tw-from-green-a700'
  | 'lg:tw-from-green-a700'
  | 'xl:tw-from-green-a700'
  | 'hover:tw-from-green-a700'
  | 'focus:tw-from-green-a700'
  | 'sm:tw-via-green-a700'
  | 'md:tw-via-green-a700'
  | 'lg:tw-via-green-a700'
  | 'xl:tw-via-green-a700'
  | 'hover:tw-via-green-a700'
  | 'focus:tw-via-green-a700'
  | 'sm:tw-to-green-a700'
  | 'md:tw-to-green-a700'
  | 'lg:tw-to-green-a700'
  | 'xl:tw-to-green-a700'
  | 'hover:tw-to-green-a700'
  | 'focus:tw-to-green-a700'
  | 'sm:tw-from-lightGreen-50'
  | 'md:tw-from-lightGreen-50'
  | 'lg:tw-from-lightGreen-50'
  | 'xl:tw-from-lightGreen-50'
  | 'hover:tw-from-lightGreen-50'
  | 'focus:tw-from-lightGreen-50'
  | 'sm:tw-via-lightGreen-50'
  | 'md:tw-via-lightGreen-50'
  | 'lg:tw-via-lightGreen-50'
  | 'xl:tw-via-lightGreen-50'
  | 'hover:tw-via-lightGreen-50'
  | 'focus:tw-via-lightGreen-50'
  | 'sm:tw-to-lightGreen-50'
  | 'md:tw-to-lightGreen-50'
  | 'lg:tw-to-lightGreen-50'
  | 'xl:tw-to-lightGreen-50'
  | 'hover:tw-to-lightGreen-50'
  | 'focus:tw-to-lightGreen-50'
  | 'sm:tw-from-lightGreen-100'
  | 'md:tw-from-lightGreen-100'
  | 'lg:tw-from-lightGreen-100'
  | 'xl:tw-from-lightGreen-100'
  | 'hover:tw-from-lightGreen-100'
  | 'focus:tw-from-lightGreen-100'
  | 'sm:tw-via-lightGreen-100'
  | 'md:tw-via-lightGreen-100'
  | 'lg:tw-via-lightGreen-100'
  | 'xl:tw-via-lightGreen-100'
  | 'hover:tw-via-lightGreen-100'
  | 'focus:tw-via-lightGreen-100'
  | 'sm:tw-to-lightGreen-100'
  | 'md:tw-to-lightGreen-100'
  | 'lg:tw-to-lightGreen-100'
  | 'xl:tw-to-lightGreen-100'
  | 'hover:tw-to-lightGreen-100'
  | 'focus:tw-to-lightGreen-100'
  | 'sm:tw-from-lightGreen-200'
  | 'md:tw-from-lightGreen-200'
  | 'lg:tw-from-lightGreen-200'
  | 'xl:tw-from-lightGreen-200'
  | 'hover:tw-from-lightGreen-200'
  | 'focus:tw-from-lightGreen-200'
  | 'sm:tw-via-lightGreen-200'
  | 'md:tw-via-lightGreen-200'
  | 'lg:tw-via-lightGreen-200'
  | 'xl:tw-via-lightGreen-200'
  | 'hover:tw-via-lightGreen-200'
  | 'focus:tw-via-lightGreen-200'
  | 'sm:tw-to-lightGreen-200'
  | 'md:tw-to-lightGreen-200'
  | 'lg:tw-to-lightGreen-200'
  | 'xl:tw-to-lightGreen-200'
  | 'hover:tw-to-lightGreen-200'
  | 'focus:tw-to-lightGreen-200'
  | 'sm:tw-from-lightGreen-300'
  | 'md:tw-from-lightGreen-300'
  | 'lg:tw-from-lightGreen-300'
  | 'xl:tw-from-lightGreen-300'
  | 'hover:tw-from-lightGreen-300'
  | 'focus:tw-from-lightGreen-300'
  | 'sm:tw-via-lightGreen-300'
  | 'md:tw-via-lightGreen-300'
  | 'lg:tw-via-lightGreen-300'
  | 'xl:tw-via-lightGreen-300'
  | 'hover:tw-via-lightGreen-300'
  | 'focus:tw-via-lightGreen-300'
  | 'sm:tw-to-lightGreen-300'
  | 'md:tw-to-lightGreen-300'
  | 'lg:tw-to-lightGreen-300'
  | 'xl:tw-to-lightGreen-300'
  | 'hover:tw-to-lightGreen-300'
  | 'focus:tw-to-lightGreen-300'
  | 'sm:tw-from-lightGreen-400'
  | 'md:tw-from-lightGreen-400'
  | 'lg:tw-from-lightGreen-400'
  | 'xl:tw-from-lightGreen-400'
  | 'hover:tw-from-lightGreen-400'
  | 'focus:tw-from-lightGreen-400'
  | 'sm:tw-via-lightGreen-400'
  | 'md:tw-via-lightGreen-400'
  | 'lg:tw-via-lightGreen-400'
  | 'xl:tw-via-lightGreen-400'
  | 'hover:tw-via-lightGreen-400'
  | 'focus:tw-via-lightGreen-400'
  | 'sm:tw-to-lightGreen-400'
  | 'md:tw-to-lightGreen-400'
  | 'lg:tw-to-lightGreen-400'
  | 'xl:tw-to-lightGreen-400'
  | 'hover:tw-to-lightGreen-400'
  | 'focus:tw-to-lightGreen-400'
  | 'sm:tw-from-lightGreen-500'
  | 'md:tw-from-lightGreen-500'
  | 'lg:tw-from-lightGreen-500'
  | 'xl:tw-from-lightGreen-500'
  | 'hover:tw-from-lightGreen-500'
  | 'focus:tw-from-lightGreen-500'
  | 'sm:tw-via-lightGreen-500'
  | 'md:tw-via-lightGreen-500'
  | 'lg:tw-via-lightGreen-500'
  | 'xl:tw-via-lightGreen-500'
  | 'hover:tw-via-lightGreen-500'
  | 'focus:tw-via-lightGreen-500'
  | 'sm:tw-to-lightGreen-500'
  | 'md:tw-to-lightGreen-500'
  | 'lg:tw-to-lightGreen-500'
  | 'xl:tw-to-lightGreen-500'
  | 'hover:tw-to-lightGreen-500'
  | 'focus:tw-to-lightGreen-500'
  | 'sm:tw-from-lightGreen-600'
  | 'md:tw-from-lightGreen-600'
  | 'lg:tw-from-lightGreen-600'
  | 'xl:tw-from-lightGreen-600'
  | 'hover:tw-from-lightGreen-600'
  | 'focus:tw-from-lightGreen-600'
  | 'sm:tw-via-lightGreen-600'
  | 'md:tw-via-lightGreen-600'
  | 'lg:tw-via-lightGreen-600'
  | 'xl:tw-via-lightGreen-600'
  | 'hover:tw-via-lightGreen-600'
  | 'focus:tw-via-lightGreen-600'
  | 'sm:tw-to-lightGreen-600'
  | 'md:tw-to-lightGreen-600'
  | 'lg:tw-to-lightGreen-600'
  | 'xl:tw-to-lightGreen-600'
  | 'hover:tw-to-lightGreen-600'
  | 'focus:tw-to-lightGreen-600'
  | 'sm:tw-from-lightGreen-700'
  | 'md:tw-from-lightGreen-700'
  | 'lg:tw-from-lightGreen-700'
  | 'xl:tw-from-lightGreen-700'
  | 'hover:tw-from-lightGreen-700'
  | 'focus:tw-from-lightGreen-700'
  | 'sm:tw-via-lightGreen-700'
  | 'md:tw-via-lightGreen-700'
  | 'lg:tw-via-lightGreen-700'
  | 'xl:tw-via-lightGreen-700'
  | 'hover:tw-via-lightGreen-700'
  | 'focus:tw-via-lightGreen-700'
  | 'sm:tw-to-lightGreen-700'
  | 'md:tw-to-lightGreen-700'
  | 'lg:tw-to-lightGreen-700'
  | 'xl:tw-to-lightGreen-700'
  | 'hover:tw-to-lightGreen-700'
  | 'focus:tw-to-lightGreen-700'
  | 'sm:tw-from-lightGreen-800'
  | 'md:tw-from-lightGreen-800'
  | 'lg:tw-from-lightGreen-800'
  | 'xl:tw-from-lightGreen-800'
  | 'hover:tw-from-lightGreen-800'
  | 'focus:tw-from-lightGreen-800'
  | 'sm:tw-via-lightGreen-800'
  | 'md:tw-via-lightGreen-800'
  | 'lg:tw-via-lightGreen-800'
  | 'xl:tw-via-lightGreen-800'
  | 'hover:tw-via-lightGreen-800'
  | 'focus:tw-via-lightGreen-800'
  | 'sm:tw-to-lightGreen-800'
  | 'md:tw-to-lightGreen-800'
  | 'lg:tw-to-lightGreen-800'
  | 'xl:tw-to-lightGreen-800'
  | 'hover:tw-to-lightGreen-800'
  | 'focus:tw-to-lightGreen-800'
  | 'sm:tw-from-lightGreen-900'
  | 'md:tw-from-lightGreen-900'
  | 'lg:tw-from-lightGreen-900'
  | 'xl:tw-from-lightGreen-900'
  | 'hover:tw-from-lightGreen-900'
  | 'focus:tw-from-lightGreen-900'
  | 'sm:tw-via-lightGreen-900'
  | 'md:tw-via-lightGreen-900'
  | 'lg:tw-via-lightGreen-900'
  | 'xl:tw-via-lightGreen-900'
  | 'hover:tw-via-lightGreen-900'
  | 'focus:tw-via-lightGreen-900'
  | 'sm:tw-to-lightGreen-900'
  | 'md:tw-to-lightGreen-900'
  | 'lg:tw-to-lightGreen-900'
  | 'xl:tw-to-lightGreen-900'
  | 'hover:tw-to-lightGreen-900'
  | 'focus:tw-to-lightGreen-900'
  | 'sm:tw-from-lightGreen-a100'
  | 'md:tw-from-lightGreen-a100'
  | 'lg:tw-from-lightGreen-a100'
  | 'xl:tw-from-lightGreen-a100'
  | 'hover:tw-from-lightGreen-a100'
  | 'focus:tw-from-lightGreen-a100'
  | 'sm:tw-via-lightGreen-a100'
  | 'md:tw-via-lightGreen-a100'
  | 'lg:tw-via-lightGreen-a100'
  | 'xl:tw-via-lightGreen-a100'
  | 'hover:tw-via-lightGreen-a100'
  | 'focus:tw-via-lightGreen-a100'
  | 'sm:tw-to-lightGreen-a100'
  | 'md:tw-to-lightGreen-a100'
  | 'lg:tw-to-lightGreen-a100'
  | 'xl:tw-to-lightGreen-a100'
  | 'hover:tw-to-lightGreen-a100'
  | 'focus:tw-to-lightGreen-a100'
  | 'sm:tw-from-lightGreen-a200'
  | 'md:tw-from-lightGreen-a200'
  | 'lg:tw-from-lightGreen-a200'
  | 'xl:tw-from-lightGreen-a200'
  | 'hover:tw-from-lightGreen-a200'
  | 'focus:tw-from-lightGreen-a200'
  | 'sm:tw-via-lightGreen-a200'
  | 'md:tw-via-lightGreen-a200'
  | 'lg:tw-via-lightGreen-a200'
  | 'xl:tw-via-lightGreen-a200'
  | 'hover:tw-via-lightGreen-a200'
  | 'focus:tw-via-lightGreen-a200'
  | 'sm:tw-to-lightGreen-a200'
  | 'md:tw-to-lightGreen-a200'
  | 'lg:tw-to-lightGreen-a200'
  | 'xl:tw-to-lightGreen-a200'
  | 'hover:tw-to-lightGreen-a200'
  | 'focus:tw-to-lightGreen-a200'
  | 'sm:tw-from-lightGreen-a400'
  | 'md:tw-from-lightGreen-a400'
  | 'lg:tw-from-lightGreen-a400'
  | 'xl:tw-from-lightGreen-a400'
  | 'hover:tw-from-lightGreen-a400'
  | 'focus:tw-from-lightGreen-a400'
  | 'sm:tw-via-lightGreen-a400'
  | 'md:tw-via-lightGreen-a400'
  | 'lg:tw-via-lightGreen-a400'
  | 'xl:tw-via-lightGreen-a400'
  | 'hover:tw-via-lightGreen-a400'
  | 'focus:tw-via-lightGreen-a400'
  | 'sm:tw-to-lightGreen-a400'
  | 'md:tw-to-lightGreen-a400'
  | 'lg:tw-to-lightGreen-a400'
  | 'xl:tw-to-lightGreen-a400'
  | 'hover:tw-to-lightGreen-a400'
  | 'focus:tw-to-lightGreen-a400'
  | 'sm:tw-from-lightGreen-a700'
  | 'md:tw-from-lightGreen-a700'
  | 'lg:tw-from-lightGreen-a700'
  | 'xl:tw-from-lightGreen-a700'
  | 'hover:tw-from-lightGreen-a700'
  | 'focus:tw-from-lightGreen-a700'
  | 'sm:tw-via-lightGreen-a700'
  | 'md:tw-via-lightGreen-a700'
  | 'lg:tw-via-lightGreen-a700'
  | 'xl:tw-via-lightGreen-a700'
  | 'hover:tw-via-lightGreen-a700'
  | 'focus:tw-via-lightGreen-a700'
  | 'sm:tw-to-lightGreen-a700'
  | 'md:tw-to-lightGreen-a700'
  | 'lg:tw-to-lightGreen-a700'
  | 'xl:tw-to-lightGreen-a700'
  | 'hover:tw-to-lightGreen-a700'
  | 'focus:tw-to-lightGreen-a700'
  | 'sm:tw-from-lime-50'
  | 'md:tw-from-lime-50'
  | 'lg:tw-from-lime-50'
  | 'xl:tw-from-lime-50'
  | 'hover:tw-from-lime-50'
  | 'focus:tw-from-lime-50'
  | 'sm:tw-via-lime-50'
  | 'md:tw-via-lime-50'
  | 'lg:tw-via-lime-50'
  | 'xl:tw-via-lime-50'
  | 'hover:tw-via-lime-50'
  | 'focus:tw-via-lime-50'
  | 'sm:tw-to-lime-50'
  | 'md:tw-to-lime-50'
  | 'lg:tw-to-lime-50'
  | 'xl:tw-to-lime-50'
  | 'hover:tw-to-lime-50'
  | 'focus:tw-to-lime-50'
  | 'sm:tw-from-lime-100'
  | 'md:tw-from-lime-100'
  | 'lg:tw-from-lime-100'
  | 'xl:tw-from-lime-100'
  | 'hover:tw-from-lime-100'
  | 'focus:tw-from-lime-100'
  | 'sm:tw-via-lime-100'
  | 'md:tw-via-lime-100'
  | 'lg:tw-via-lime-100'
  | 'xl:tw-via-lime-100'
  | 'hover:tw-via-lime-100'
  | 'focus:tw-via-lime-100'
  | 'sm:tw-to-lime-100'
  | 'md:tw-to-lime-100'
  | 'lg:tw-to-lime-100'
  | 'xl:tw-to-lime-100'
  | 'hover:tw-to-lime-100'
  | 'focus:tw-to-lime-100'
  | 'sm:tw-from-lime-200'
  | 'md:tw-from-lime-200'
  | 'lg:tw-from-lime-200'
  | 'xl:tw-from-lime-200'
  | 'hover:tw-from-lime-200'
  | 'focus:tw-from-lime-200'
  | 'sm:tw-via-lime-200'
  | 'md:tw-via-lime-200'
  | 'lg:tw-via-lime-200'
  | 'xl:tw-via-lime-200'
  | 'hover:tw-via-lime-200'
  | 'focus:tw-via-lime-200'
  | 'sm:tw-to-lime-200'
  | 'md:tw-to-lime-200'
  | 'lg:tw-to-lime-200'
  | 'xl:tw-to-lime-200'
  | 'hover:tw-to-lime-200'
  | 'focus:tw-to-lime-200'
  | 'sm:tw-from-lime-300'
  | 'md:tw-from-lime-300'
  | 'lg:tw-from-lime-300'
  | 'xl:tw-from-lime-300'
  | 'hover:tw-from-lime-300'
  | 'focus:tw-from-lime-300'
  | 'sm:tw-via-lime-300'
  | 'md:tw-via-lime-300'
  | 'lg:tw-via-lime-300'
  | 'xl:tw-via-lime-300'
  | 'hover:tw-via-lime-300'
  | 'focus:tw-via-lime-300'
  | 'sm:tw-to-lime-300'
  | 'md:tw-to-lime-300'
  | 'lg:tw-to-lime-300'
  | 'xl:tw-to-lime-300'
  | 'hover:tw-to-lime-300'
  | 'focus:tw-to-lime-300'
  | 'sm:tw-from-lime-400'
  | 'md:tw-from-lime-400'
  | 'lg:tw-from-lime-400'
  | 'xl:tw-from-lime-400'
  | 'hover:tw-from-lime-400'
  | 'focus:tw-from-lime-400'
  | 'sm:tw-via-lime-400'
  | 'md:tw-via-lime-400'
  | 'lg:tw-via-lime-400'
  | 'xl:tw-via-lime-400'
  | 'hover:tw-via-lime-400'
  | 'focus:tw-via-lime-400'
  | 'sm:tw-to-lime-400'
  | 'md:tw-to-lime-400'
  | 'lg:tw-to-lime-400'
  | 'xl:tw-to-lime-400'
  | 'hover:tw-to-lime-400'
  | 'focus:tw-to-lime-400'
  | 'sm:tw-from-lime-500'
  | 'md:tw-from-lime-500'
  | 'lg:tw-from-lime-500'
  | 'xl:tw-from-lime-500'
  | 'hover:tw-from-lime-500'
  | 'focus:tw-from-lime-500'
  | 'sm:tw-via-lime-500'
  | 'md:tw-via-lime-500'
  | 'lg:tw-via-lime-500'
  | 'xl:tw-via-lime-500'
  | 'hover:tw-via-lime-500'
  | 'focus:tw-via-lime-500'
  | 'sm:tw-to-lime-500'
  | 'md:tw-to-lime-500'
  | 'lg:tw-to-lime-500'
  | 'xl:tw-to-lime-500'
  | 'hover:tw-to-lime-500'
  | 'focus:tw-to-lime-500'
  | 'sm:tw-from-lime-600'
  | 'md:tw-from-lime-600'
  | 'lg:tw-from-lime-600'
  | 'xl:tw-from-lime-600'
  | 'hover:tw-from-lime-600'
  | 'focus:tw-from-lime-600'
  | 'sm:tw-via-lime-600'
  | 'md:tw-via-lime-600'
  | 'lg:tw-via-lime-600'
  | 'xl:tw-via-lime-600'
  | 'hover:tw-via-lime-600'
  | 'focus:tw-via-lime-600'
  | 'sm:tw-to-lime-600'
  | 'md:tw-to-lime-600'
  | 'lg:tw-to-lime-600'
  | 'xl:tw-to-lime-600'
  | 'hover:tw-to-lime-600'
  | 'focus:tw-to-lime-600'
  | 'sm:tw-from-lime-700'
  | 'md:tw-from-lime-700'
  | 'lg:tw-from-lime-700'
  | 'xl:tw-from-lime-700'
  | 'hover:tw-from-lime-700'
  | 'focus:tw-from-lime-700'
  | 'sm:tw-via-lime-700'
  | 'md:tw-via-lime-700'
  | 'lg:tw-via-lime-700'
  | 'xl:tw-via-lime-700'
  | 'hover:tw-via-lime-700'
  | 'focus:tw-via-lime-700'
  | 'sm:tw-to-lime-700'
  | 'md:tw-to-lime-700'
  | 'lg:tw-to-lime-700'
  | 'xl:tw-to-lime-700'
  | 'hover:tw-to-lime-700'
  | 'focus:tw-to-lime-700'
  | 'sm:tw-from-lime-800'
  | 'md:tw-from-lime-800'
  | 'lg:tw-from-lime-800'
  | 'xl:tw-from-lime-800'
  | 'hover:tw-from-lime-800'
  | 'focus:tw-from-lime-800'
  | 'sm:tw-via-lime-800'
  | 'md:tw-via-lime-800'
  | 'lg:tw-via-lime-800'
  | 'xl:tw-via-lime-800'
  | 'hover:tw-via-lime-800'
  | 'focus:tw-via-lime-800'
  | 'sm:tw-to-lime-800'
  | 'md:tw-to-lime-800'
  | 'lg:tw-to-lime-800'
  | 'xl:tw-to-lime-800'
  | 'hover:tw-to-lime-800'
  | 'focus:tw-to-lime-800'
  | 'sm:tw-from-lime-900'
  | 'md:tw-from-lime-900'
  | 'lg:tw-from-lime-900'
  | 'xl:tw-from-lime-900'
  | 'hover:tw-from-lime-900'
  | 'focus:tw-from-lime-900'
  | 'sm:tw-via-lime-900'
  | 'md:tw-via-lime-900'
  | 'lg:tw-via-lime-900'
  | 'xl:tw-via-lime-900'
  | 'hover:tw-via-lime-900'
  | 'focus:tw-via-lime-900'
  | 'sm:tw-to-lime-900'
  | 'md:tw-to-lime-900'
  | 'lg:tw-to-lime-900'
  | 'xl:tw-to-lime-900'
  | 'hover:tw-to-lime-900'
  | 'focus:tw-to-lime-900'
  | 'sm:tw-from-lime-a100'
  | 'md:tw-from-lime-a100'
  | 'lg:tw-from-lime-a100'
  | 'xl:tw-from-lime-a100'
  | 'hover:tw-from-lime-a100'
  | 'focus:tw-from-lime-a100'
  | 'sm:tw-via-lime-a100'
  | 'md:tw-via-lime-a100'
  | 'lg:tw-via-lime-a100'
  | 'xl:tw-via-lime-a100'
  | 'hover:tw-via-lime-a100'
  | 'focus:tw-via-lime-a100'
  | 'sm:tw-to-lime-a100'
  | 'md:tw-to-lime-a100'
  | 'lg:tw-to-lime-a100'
  | 'xl:tw-to-lime-a100'
  | 'hover:tw-to-lime-a100'
  | 'focus:tw-to-lime-a100'
  | 'sm:tw-from-lime-a200'
  | 'md:tw-from-lime-a200'
  | 'lg:tw-from-lime-a200'
  | 'xl:tw-from-lime-a200'
  | 'hover:tw-from-lime-a200'
  | 'focus:tw-from-lime-a200'
  | 'sm:tw-via-lime-a200'
  | 'md:tw-via-lime-a200'
  | 'lg:tw-via-lime-a200'
  | 'xl:tw-via-lime-a200'
  | 'hover:tw-via-lime-a200'
  | 'focus:tw-via-lime-a200'
  | 'sm:tw-to-lime-a200'
  | 'md:tw-to-lime-a200'
  | 'lg:tw-to-lime-a200'
  | 'xl:tw-to-lime-a200'
  | 'hover:tw-to-lime-a200'
  | 'focus:tw-to-lime-a200'
  | 'sm:tw-from-lime-a400'
  | 'md:tw-from-lime-a400'
  | 'lg:tw-from-lime-a400'
  | 'xl:tw-from-lime-a400'
  | 'hover:tw-from-lime-a400'
  | 'focus:tw-from-lime-a400'
  | 'sm:tw-via-lime-a400'
  | 'md:tw-via-lime-a400'
  | 'lg:tw-via-lime-a400'
  | 'xl:tw-via-lime-a400'
  | 'hover:tw-via-lime-a400'
  | 'focus:tw-via-lime-a400'
  | 'sm:tw-to-lime-a400'
  | 'md:tw-to-lime-a400'
  | 'lg:tw-to-lime-a400'
  | 'xl:tw-to-lime-a400'
  | 'hover:tw-to-lime-a400'
  | 'focus:tw-to-lime-a400'
  | 'sm:tw-from-lime-a700'
  | 'md:tw-from-lime-a700'
  | 'lg:tw-from-lime-a700'
  | 'xl:tw-from-lime-a700'
  | 'hover:tw-from-lime-a700'
  | 'focus:tw-from-lime-a700'
  | 'sm:tw-via-lime-a700'
  | 'md:tw-via-lime-a700'
  | 'lg:tw-via-lime-a700'
  | 'xl:tw-via-lime-a700'
  | 'hover:tw-via-lime-a700'
  | 'focus:tw-via-lime-a700'
  | 'sm:tw-to-lime-a700'
  | 'md:tw-to-lime-a700'
  | 'lg:tw-to-lime-a700'
  | 'xl:tw-to-lime-a700'
  | 'hover:tw-to-lime-a700'
  | 'focus:tw-to-lime-a700'
  | 'sm:tw-from-yellow-50'
  | 'md:tw-from-yellow-50'
  | 'lg:tw-from-yellow-50'
  | 'xl:tw-from-yellow-50'
  | 'hover:tw-from-yellow-50'
  | 'focus:tw-from-yellow-50'
  | 'sm:tw-via-yellow-50'
  | 'md:tw-via-yellow-50'
  | 'lg:tw-via-yellow-50'
  | 'xl:tw-via-yellow-50'
  | 'hover:tw-via-yellow-50'
  | 'focus:tw-via-yellow-50'
  | 'sm:tw-to-yellow-50'
  | 'md:tw-to-yellow-50'
  | 'lg:tw-to-yellow-50'
  | 'xl:tw-to-yellow-50'
  | 'hover:tw-to-yellow-50'
  | 'focus:tw-to-yellow-50'
  | 'sm:tw-from-yellow-100'
  | 'md:tw-from-yellow-100'
  | 'lg:tw-from-yellow-100'
  | 'xl:tw-from-yellow-100'
  | 'hover:tw-from-yellow-100'
  | 'focus:tw-from-yellow-100'
  | 'sm:tw-via-yellow-100'
  | 'md:tw-via-yellow-100'
  | 'lg:tw-via-yellow-100'
  | 'xl:tw-via-yellow-100'
  | 'hover:tw-via-yellow-100'
  | 'focus:tw-via-yellow-100'
  | 'sm:tw-to-yellow-100'
  | 'md:tw-to-yellow-100'
  | 'lg:tw-to-yellow-100'
  | 'xl:tw-to-yellow-100'
  | 'hover:tw-to-yellow-100'
  | 'focus:tw-to-yellow-100'
  | 'sm:tw-from-yellow-200'
  | 'md:tw-from-yellow-200'
  | 'lg:tw-from-yellow-200'
  | 'xl:tw-from-yellow-200'
  | 'hover:tw-from-yellow-200'
  | 'focus:tw-from-yellow-200'
  | 'sm:tw-via-yellow-200'
  | 'md:tw-via-yellow-200'
  | 'lg:tw-via-yellow-200'
  | 'xl:tw-via-yellow-200'
  | 'hover:tw-via-yellow-200'
  | 'focus:tw-via-yellow-200'
  | 'sm:tw-to-yellow-200'
  | 'md:tw-to-yellow-200'
  | 'lg:tw-to-yellow-200'
  | 'xl:tw-to-yellow-200'
  | 'hover:tw-to-yellow-200'
  | 'focus:tw-to-yellow-200'
  | 'sm:tw-from-yellow-300'
  | 'md:tw-from-yellow-300'
  | 'lg:tw-from-yellow-300'
  | 'xl:tw-from-yellow-300'
  | 'hover:tw-from-yellow-300'
  | 'focus:tw-from-yellow-300'
  | 'sm:tw-via-yellow-300'
  | 'md:tw-via-yellow-300'
  | 'lg:tw-via-yellow-300'
  | 'xl:tw-via-yellow-300'
  | 'hover:tw-via-yellow-300'
  | 'focus:tw-via-yellow-300'
  | 'sm:tw-to-yellow-300'
  | 'md:tw-to-yellow-300'
  | 'lg:tw-to-yellow-300'
  | 'xl:tw-to-yellow-300'
  | 'hover:tw-to-yellow-300'
  | 'focus:tw-to-yellow-300'
  | 'sm:tw-from-yellow-400'
  | 'md:tw-from-yellow-400'
  | 'lg:tw-from-yellow-400'
  | 'xl:tw-from-yellow-400'
  | 'hover:tw-from-yellow-400'
  | 'focus:tw-from-yellow-400'
  | 'sm:tw-via-yellow-400'
  | 'md:tw-via-yellow-400'
  | 'lg:tw-via-yellow-400'
  | 'xl:tw-via-yellow-400'
  | 'hover:tw-via-yellow-400'
  | 'focus:tw-via-yellow-400'
  | 'sm:tw-to-yellow-400'
  | 'md:tw-to-yellow-400'
  | 'lg:tw-to-yellow-400'
  | 'xl:tw-to-yellow-400'
  | 'hover:tw-to-yellow-400'
  | 'focus:tw-to-yellow-400'
  | 'sm:tw-from-yellow-500'
  | 'md:tw-from-yellow-500'
  | 'lg:tw-from-yellow-500'
  | 'xl:tw-from-yellow-500'
  | 'hover:tw-from-yellow-500'
  | 'focus:tw-from-yellow-500'
  | 'sm:tw-via-yellow-500'
  | 'md:tw-via-yellow-500'
  | 'lg:tw-via-yellow-500'
  | 'xl:tw-via-yellow-500'
  | 'hover:tw-via-yellow-500'
  | 'focus:tw-via-yellow-500'
  | 'sm:tw-to-yellow-500'
  | 'md:tw-to-yellow-500'
  | 'lg:tw-to-yellow-500'
  | 'xl:tw-to-yellow-500'
  | 'hover:tw-to-yellow-500'
  | 'focus:tw-to-yellow-500'
  | 'sm:tw-from-yellow-600'
  | 'md:tw-from-yellow-600'
  | 'lg:tw-from-yellow-600'
  | 'xl:tw-from-yellow-600'
  | 'hover:tw-from-yellow-600'
  | 'focus:tw-from-yellow-600'
  | 'sm:tw-via-yellow-600'
  | 'md:tw-via-yellow-600'
  | 'lg:tw-via-yellow-600'
  | 'xl:tw-via-yellow-600'
  | 'hover:tw-via-yellow-600'
  | 'focus:tw-via-yellow-600'
  | 'sm:tw-to-yellow-600'
  | 'md:tw-to-yellow-600'
  | 'lg:tw-to-yellow-600'
  | 'xl:tw-to-yellow-600'
  | 'hover:tw-to-yellow-600'
  | 'focus:tw-to-yellow-600'
  | 'sm:tw-from-yellow-700'
  | 'md:tw-from-yellow-700'
  | 'lg:tw-from-yellow-700'
  | 'xl:tw-from-yellow-700'
  | 'hover:tw-from-yellow-700'
  | 'focus:tw-from-yellow-700'
  | 'sm:tw-via-yellow-700'
  | 'md:tw-via-yellow-700'
  | 'lg:tw-via-yellow-700'
  | 'xl:tw-via-yellow-700'
  | 'hover:tw-via-yellow-700'
  | 'focus:tw-via-yellow-700'
  | 'sm:tw-to-yellow-700'
  | 'md:tw-to-yellow-700'
  | 'lg:tw-to-yellow-700'
  | 'xl:tw-to-yellow-700'
  | 'hover:tw-to-yellow-700'
  | 'focus:tw-to-yellow-700'
  | 'sm:tw-from-yellow-800'
  | 'md:tw-from-yellow-800'
  | 'lg:tw-from-yellow-800'
  | 'xl:tw-from-yellow-800'
  | 'hover:tw-from-yellow-800'
  | 'focus:tw-from-yellow-800'
  | 'sm:tw-via-yellow-800'
  | 'md:tw-via-yellow-800'
  | 'lg:tw-via-yellow-800'
  | 'xl:tw-via-yellow-800'
  | 'hover:tw-via-yellow-800'
  | 'focus:tw-via-yellow-800'
  | 'sm:tw-to-yellow-800'
  | 'md:tw-to-yellow-800'
  | 'lg:tw-to-yellow-800'
  | 'xl:tw-to-yellow-800'
  | 'hover:tw-to-yellow-800'
  | 'focus:tw-to-yellow-800'
  | 'sm:tw-from-yellow-900'
  | 'md:tw-from-yellow-900'
  | 'lg:tw-from-yellow-900'
  | 'xl:tw-from-yellow-900'
  | 'hover:tw-from-yellow-900'
  | 'focus:tw-from-yellow-900'
  | 'sm:tw-via-yellow-900'
  | 'md:tw-via-yellow-900'
  | 'lg:tw-via-yellow-900'
  | 'xl:tw-via-yellow-900'
  | 'hover:tw-via-yellow-900'
  | 'focus:tw-via-yellow-900'
  | 'sm:tw-to-yellow-900'
  | 'md:tw-to-yellow-900'
  | 'lg:tw-to-yellow-900'
  | 'xl:tw-to-yellow-900'
  | 'hover:tw-to-yellow-900'
  | 'focus:tw-to-yellow-900'
  | 'sm:tw-from-yellow-a100'
  | 'md:tw-from-yellow-a100'
  | 'lg:tw-from-yellow-a100'
  | 'xl:tw-from-yellow-a100'
  | 'hover:tw-from-yellow-a100'
  | 'focus:tw-from-yellow-a100'
  | 'sm:tw-via-yellow-a100'
  | 'md:tw-via-yellow-a100'
  | 'lg:tw-via-yellow-a100'
  | 'xl:tw-via-yellow-a100'
  | 'hover:tw-via-yellow-a100'
  | 'focus:tw-via-yellow-a100'
  | 'sm:tw-to-yellow-a100'
  | 'md:tw-to-yellow-a100'
  | 'lg:tw-to-yellow-a100'
  | 'xl:tw-to-yellow-a100'
  | 'hover:tw-to-yellow-a100'
  | 'focus:tw-to-yellow-a100'
  | 'sm:tw-from-yellow-a200'
  | 'md:tw-from-yellow-a200'
  | 'lg:tw-from-yellow-a200'
  | 'xl:tw-from-yellow-a200'
  | 'hover:tw-from-yellow-a200'
  | 'focus:tw-from-yellow-a200'
  | 'sm:tw-via-yellow-a200'
  | 'md:tw-via-yellow-a200'
  | 'lg:tw-via-yellow-a200'
  | 'xl:tw-via-yellow-a200'
  | 'hover:tw-via-yellow-a200'
  | 'focus:tw-via-yellow-a200'
  | 'sm:tw-to-yellow-a200'
  | 'md:tw-to-yellow-a200'
  | 'lg:tw-to-yellow-a200'
  | 'xl:tw-to-yellow-a200'
  | 'hover:tw-to-yellow-a200'
  | 'focus:tw-to-yellow-a200'
  | 'sm:tw-from-yellow-a400'
  | 'md:tw-from-yellow-a400'
  | 'lg:tw-from-yellow-a400'
  | 'xl:tw-from-yellow-a400'
  | 'hover:tw-from-yellow-a400'
  | 'focus:tw-from-yellow-a400'
  | 'sm:tw-via-yellow-a400'
  | 'md:tw-via-yellow-a400'
  | 'lg:tw-via-yellow-a400'
  | 'xl:tw-via-yellow-a400'
  | 'hover:tw-via-yellow-a400'
  | 'focus:tw-via-yellow-a400'
  | 'sm:tw-to-yellow-a400'
  | 'md:tw-to-yellow-a400'
  | 'lg:tw-to-yellow-a400'
  | 'xl:tw-to-yellow-a400'
  | 'hover:tw-to-yellow-a400'
  | 'focus:tw-to-yellow-a400'
  | 'sm:tw-from-yellow-a700'
  | 'md:tw-from-yellow-a700'
  | 'lg:tw-from-yellow-a700'
  | 'xl:tw-from-yellow-a700'
  | 'hover:tw-from-yellow-a700'
  | 'focus:tw-from-yellow-a700'
  | 'sm:tw-via-yellow-a700'
  | 'md:tw-via-yellow-a700'
  | 'lg:tw-via-yellow-a700'
  | 'xl:tw-via-yellow-a700'
  | 'hover:tw-via-yellow-a700'
  | 'focus:tw-via-yellow-a700'
  | 'sm:tw-to-yellow-a700'
  | 'md:tw-to-yellow-a700'
  | 'lg:tw-to-yellow-a700'
  | 'xl:tw-to-yellow-a700'
  | 'hover:tw-to-yellow-a700'
  | 'focus:tw-to-yellow-a700'
  | 'sm:tw-from-amber-50'
  | 'md:tw-from-amber-50'
  | 'lg:tw-from-amber-50'
  | 'xl:tw-from-amber-50'
  | 'hover:tw-from-amber-50'
  | 'focus:tw-from-amber-50'
  | 'sm:tw-via-amber-50'
  | 'md:tw-via-amber-50'
  | 'lg:tw-via-amber-50'
  | 'xl:tw-via-amber-50'
  | 'hover:tw-via-amber-50'
  | 'focus:tw-via-amber-50'
  | 'sm:tw-to-amber-50'
  | 'md:tw-to-amber-50'
  | 'lg:tw-to-amber-50'
  | 'xl:tw-to-amber-50'
  | 'hover:tw-to-amber-50'
  | 'focus:tw-to-amber-50'
  | 'sm:tw-from-amber-100'
  | 'md:tw-from-amber-100'
  | 'lg:tw-from-amber-100'
  | 'xl:tw-from-amber-100'
  | 'hover:tw-from-amber-100'
  | 'focus:tw-from-amber-100'
  | 'sm:tw-via-amber-100'
  | 'md:tw-via-amber-100'
  | 'lg:tw-via-amber-100'
  | 'xl:tw-via-amber-100'
  | 'hover:tw-via-amber-100'
  | 'focus:tw-via-amber-100'
  | 'sm:tw-to-amber-100'
  | 'md:tw-to-amber-100'
  | 'lg:tw-to-amber-100'
  | 'xl:tw-to-amber-100'
  | 'hover:tw-to-amber-100'
  | 'focus:tw-to-amber-100'
  | 'sm:tw-from-amber-200'
  | 'md:tw-from-amber-200'
  | 'lg:tw-from-amber-200'
  | 'xl:tw-from-amber-200'
  | 'hover:tw-from-amber-200'
  | 'focus:tw-from-amber-200'
  | 'sm:tw-via-amber-200'
  | 'md:tw-via-amber-200'
  | 'lg:tw-via-amber-200'
  | 'xl:tw-via-amber-200'
  | 'hover:tw-via-amber-200'
  | 'focus:tw-via-amber-200'
  | 'sm:tw-to-amber-200'
  | 'md:tw-to-amber-200'
  | 'lg:tw-to-amber-200'
  | 'xl:tw-to-amber-200'
  | 'hover:tw-to-amber-200'
  | 'focus:tw-to-amber-200'
  | 'sm:tw-from-amber-300'
  | 'md:tw-from-amber-300'
  | 'lg:tw-from-amber-300'
  | 'xl:tw-from-amber-300'
  | 'hover:tw-from-amber-300'
  | 'focus:tw-from-amber-300'
  | 'sm:tw-via-amber-300'
  | 'md:tw-via-amber-300'
  | 'lg:tw-via-amber-300'
  | 'xl:tw-via-amber-300'
  | 'hover:tw-via-amber-300'
  | 'focus:tw-via-amber-300'
  | 'sm:tw-to-amber-300'
  | 'md:tw-to-amber-300'
  | 'lg:tw-to-amber-300'
  | 'xl:tw-to-amber-300'
  | 'hover:tw-to-amber-300'
  | 'focus:tw-to-amber-300'
  | 'sm:tw-from-amber-400'
  | 'md:tw-from-amber-400'
  | 'lg:tw-from-amber-400'
  | 'xl:tw-from-amber-400'
  | 'hover:tw-from-amber-400'
  | 'focus:tw-from-amber-400'
  | 'sm:tw-via-amber-400'
  | 'md:tw-via-amber-400'
  | 'lg:tw-via-amber-400'
  | 'xl:tw-via-amber-400'
  | 'hover:tw-via-amber-400'
  | 'focus:tw-via-amber-400'
  | 'sm:tw-to-amber-400'
  | 'md:tw-to-amber-400'
  | 'lg:tw-to-amber-400'
  | 'xl:tw-to-amber-400'
  | 'hover:tw-to-amber-400'
  | 'focus:tw-to-amber-400'
  | 'sm:tw-from-amber-500'
  | 'md:tw-from-amber-500'
  | 'lg:tw-from-amber-500'
  | 'xl:tw-from-amber-500'
  | 'hover:tw-from-amber-500'
  | 'focus:tw-from-amber-500'
  | 'sm:tw-via-amber-500'
  | 'md:tw-via-amber-500'
  | 'lg:tw-via-amber-500'
  | 'xl:tw-via-amber-500'
  | 'hover:tw-via-amber-500'
  | 'focus:tw-via-amber-500'
  | 'sm:tw-to-amber-500'
  | 'md:tw-to-amber-500'
  | 'lg:tw-to-amber-500'
  | 'xl:tw-to-amber-500'
  | 'hover:tw-to-amber-500'
  | 'focus:tw-to-amber-500'
  | 'sm:tw-from-amber-600'
  | 'md:tw-from-amber-600'
  | 'lg:tw-from-amber-600'
  | 'xl:tw-from-amber-600'
  | 'hover:tw-from-amber-600'
  | 'focus:tw-from-amber-600'
  | 'sm:tw-via-amber-600'
  | 'md:tw-via-amber-600'
  | 'lg:tw-via-amber-600'
  | 'xl:tw-via-amber-600'
  | 'hover:tw-via-amber-600'
  | 'focus:tw-via-amber-600'
  | 'sm:tw-to-amber-600'
  | 'md:tw-to-amber-600'
  | 'lg:tw-to-amber-600'
  | 'xl:tw-to-amber-600'
  | 'hover:tw-to-amber-600'
  | 'focus:tw-to-amber-600'
  | 'sm:tw-from-amber-700'
  | 'md:tw-from-amber-700'
  | 'lg:tw-from-amber-700'
  | 'xl:tw-from-amber-700'
  | 'hover:tw-from-amber-700'
  | 'focus:tw-from-amber-700'
  | 'sm:tw-via-amber-700'
  | 'md:tw-via-amber-700'
  | 'lg:tw-via-amber-700'
  | 'xl:tw-via-amber-700'
  | 'hover:tw-via-amber-700'
  | 'focus:tw-via-amber-700'
  | 'sm:tw-to-amber-700'
  | 'md:tw-to-amber-700'
  | 'lg:tw-to-amber-700'
  | 'xl:tw-to-amber-700'
  | 'hover:tw-to-amber-700'
  | 'focus:tw-to-amber-700'
  | 'sm:tw-from-amber-800'
  | 'md:tw-from-amber-800'
  | 'lg:tw-from-amber-800'
  | 'xl:tw-from-amber-800'
  | 'hover:tw-from-amber-800'
  | 'focus:tw-from-amber-800'
  | 'sm:tw-via-amber-800'
  | 'md:tw-via-amber-800'
  | 'lg:tw-via-amber-800'
  | 'xl:tw-via-amber-800'
  | 'hover:tw-via-amber-800'
  | 'focus:tw-via-amber-800'
  | 'sm:tw-to-amber-800'
  | 'md:tw-to-amber-800'
  | 'lg:tw-to-amber-800'
  | 'xl:tw-to-amber-800'
  | 'hover:tw-to-amber-800'
  | 'focus:tw-to-amber-800'
  | 'sm:tw-from-amber-900'
  | 'md:tw-from-amber-900'
  | 'lg:tw-from-amber-900'
  | 'xl:tw-from-amber-900'
  | 'hover:tw-from-amber-900'
  | 'focus:tw-from-amber-900'
  | 'sm:tw-via-amber-900'
  | 'md:tw-via-amber-900'
  | 'lg:tw-via-amber-900'
  | 'xl:tw-via-amber-900'
  | 'hover:tw-via-amber-900'
  | 'focus:tw-via-amber-900'
  | 'sm:tw-to-amber-900'
  | 'md:tw-to-amber-900'
  | 'lg:tw-to-amber-900'
  | 'xl:tw-to-amber-900'
  | 'hover:tw-to-amber-900'
  | 'focus:tw-to-amber-900'
  | 'sm:tw-from-amber-a100'
  | 'md:tw-from-amber-a100'
  | 'lg:tw-from-amber-a100'
  | 'xl:tw-from-amber-a100'
  | 'hover:tw-from-amber-a100'
  | 'focus:tw-from-amber-a100'
  | 'sm:tw-via-amber-a100'
  | 'md:tw-via-amber-a100'
  | 'lg:tw-via-amber-a100'
  | 'xl:tw-via-amber-a100'
  | 'hover:tw-via-amber-a100'
  | 'focus:tw-via-amber-a100'
  | 'sm:tw-to-amber-a100'
  | 'md:tw-to-amber-a100'
  | 'lg:tw-to-amber-a100'
  | 'xl:tw-to-amber-a100'
  | 'hover:tw-to-amber-a100'
  | 'focus:tw-to-amber-a100'
  | 'sm:tw-from-amber-a200'
  | 'md:tw-from-amber-a200'
  | 'lg:tw-from-amber-a200'
  | 'xl:tw-from-amber-a200'
  | 'hover:tw-from-amber-a200'
  | 'focus:tw-from-amber-a200'
  | 'sm:tw-via-amber-a200'
  | 'md:tw-via-amber-a200'
  | 'lg:tw-via-amber-a200'
  | 'xl:tw-via-amber-a200'
  | 'hover:tw-via-amber-a200'
  | 'focus:tw-via-amber-a200'
  | 'sm:tw-to-amber-a200'
  | 'md:tw-to-amber-a200'
  | 'lg:tw-to-amber-a200'
  | 'xl:tw-to-amber-a200'
  | 'hover:tw-to-amber-a200'
  | 'focus:tw-to-amber-a200'
  | 'sm:tw-from-amber-a400'
  | 'md:tw-from-amber-a400'
  | 'lg:tw-from-amber-a400'
  | 'xl:tw-from-amber-a400'
  | 'hover:tw-from-amber-a400'
  | 'focus:tw-from-amber-a400'
  | 'sm:tw-via-amber-a400'
  | 'md:tw-via-amber-a400'
  | 'lg:tw-via-amber-a400'
  | 'xl:tw-via-amber-a400'
  | 'hover:tw-via-amber-a400'
  | 'focus:tw-via-amber-a400'
  | 'sm:tw-to-amber-a400'
  | 'md:tw-to-amber-a400'
  | 'lg:tw-to-amber-a400'
  | 'xl:tw-to-amber-a400'
  | 'hover:tw-to-amber-a400'
  | 'focus:tw-to-amber-a400'
  | 'sm:tw-from-amber-a700'
  | 'md:tw-from-amber-a700'
  | 'lg:tw-from-amber-a700'
  | 'xl:tw-from-amber-a700'
  | 'hover:tw-from-amber-a700'
  | 'focus:tw-from-amber-a700'
  | 'sm:tw-via-amber-a700'
  | 'md:tw-via-amber-a700'
  | 'lg:tw-via-amber-a700'
  | 'xl:tw-via-amber-a700'
  | 'hover:tw-via-amber-a700'
  | 'focus:tw-via-amber-a700'
  | 'sm:tw-to-amber-a700'
  | 'md:tw-to-amber-a700'
  | 'lg:tw-to-amber-a700'
  | 'xl:tw-to-amber-a700'
  | 'hover:tw-to-amber-a700'
  | 'focus:tw-to-amber-a700'
  | 'sm:tw-from-orange-50'
  | 'md:tw-from-orange-50'
  | 'lg:tw-from-orange-50'
  | 'xl:tw-from-orange-50'
  | 'hover:tw-from-orange-50'
  | 'focus:tw-from-orange-50'
  | 'sm:tw-via-orange-50'
  | 'md:tw-via-orange-50'
  | 'lg:tw-via-orange-50'
  | 'xl:tw-via-orange-50'
  | 'hover:tw-via-orange-50'
  | 'focus:tw-via-orange-50'
  | 'sm:tw-to-orange-50'
  | 'md:tw-to-orange-50'
  | 'lg:tw-to-orange-50'
  | 'xl:tw-to-orange-50'
  | 'hover:tw-to-orange-50'
  | 'focus:tw-to-orange-50'
  | 'sm:tw-from-orange-100'
  | 'md:tw-from-orange-100'
  | 'lg:tw-from-orange-100'
  | 'xl:tw-from-orange-100'
  | 'hover:tw-from-orange-100'
  | 'focus:tw-from-orange-100'
  | 'sm:tw-via-orange-100'
  | 'md:tw-via-orange-100'
  | 'lg:tw-via-orange-100'
  | 'xl:tw-via-orange-100'
  | 'hover:tw-via-orange-100'
  | 'focus:tw-via-orange-100'
  | 'sm:tw-to-orange-100'
  | 'md:tw-to-orange-100'
  | 'lg:tw-to-orange-100'
  | 'xl:tw-to-orange-100'
  | 'hover:tw-to-orange-100'
  | 'focus:tw-to-orange-100'
  | 'sm:tw-from-orange-200'
  | 'md:tw-from-orange-200'
  | 'lg:tw-from-orange-200'
  | 'xl:tw-from-orange-200'
  | 'hover:tw-from-orange-200'
  | 'focus:tw-from-orange-200'
  | 'sm:tw-via-orange-200'
  | 'md:tw-via-orange-200'
  | 'lg:tw-via-orange-200'
  | 'xl:tw-via-orange-200'
  | 'hover:tw-via-orange-200'
  | 'focus:tw-via-orange-200'
  | 'sm:tw-to-orange-200'
  | 'md:tw-to-orange-200'
  | 'lg:tw-to-orange-200'
  | 'xl:tw-to-orange-200'
  | 'hover:tw-to-orange-200'
  | 'focus:tw-to-orange-200'
  | 'sm:tw-from-orange-300'
  | 'md:tw-from-orange-300'
  | 'lg:tw-from-orange-300'
  | 'xl:tw-from-orange-300'
  | 'hover:tw-from-orange-300'
  | 'focus:tw-from-orange-300'
  | 'sm:tw-via-orange-300'
  | 'md:tw-via-orange-300'
  | 'lg:tw-via-orange-300'
  | 'xl:tw-via-orange-300'
  | 'hover:tw-via-orange-300'
  | 'focus:tw-via-orange-300'
  | 'sm:tw-to-orange-300'
  | 'md:tw-to-orange-300'
  | 'lg:tw-to-orange-300'
  | 'xl:tw-to-orange-300'
  | 'hover:tw-to-orange-300'
  | 'focus:tw-to-orange-300'
  | 'sm:tw-from-orange-400'
  | 'md:tw-from-orange-400'
  | 'lg:tw-from-orange-400'
  | 'xl:tw-from-orange-400'
  | 'hover:tw-from-orange-400'
  | 'focus:tw-from-orange-400'
  | 'sm:tw-via-orange-400'
  | 'md:tw-via-orange-400'
  | 'lg:tw-via-orange-400'
  | 'xl:tw-via-orange-400'
  | 'hover:tw-via-orange-400'
  | 'focus:tw-via-orange-400'
  | 'sm:tw-to-orange-400'
  | 'md:tw-to-orange-400'
  | 'lg:tw-to-orange-400'
  | 'xl:tw-to-orange-400'
  | 'hover:tw-to-orange-400'
  | 'focus:tw-to-orange-400'
  | 'sm:tw-from-orange-500'
  | 'md:tw-from-orange-500'
  | 'lg:tw-from-orange-500'
  | 'xl:tw-from-orange-500'
  | 'hover:tw-from-orange-500'
  | 'focus:tw-from-orange-500'
  | 'sm:tw-via-orange-500'
  | 'md:tw-via-orange-500'
  | 'lg:tw-via-orange-500'
  | 'xl:tw-via-orange-500'
  | 'hover:tw-via-orange-500'
  | 'focus:tw-via-orange-500'
  | 'sm:tw-to-orange-500'
  | 'md:tw-to-orange-500'
  | 'lg:tw-to-orange-500'
  | 'xl:tw-to-orange-500'
  | 'hover:tw-to-orange-500'
  | 'focus:tw-to-orange-500'
  | 'sm:tw-from-orange-600'
  | 'md:tw-from-orange-600'
  | 'lg:tw-from-orange-600'
  | 'xl:tw-from-orange-600'
  | 'hover:tw-from-orange-600'
  | 'focus:tw-from-orange-600'
  | 'sm:tw-via-orange-600'
  | 'md:tw-via-orange-600'
  | 'lg:tw-via-orange-600'
  | 'xl:tw-via-orange-600'
  | 'hover:tw-via-orange-600'
  | 'focus:tw-via-orange-600'
  | 'sm:tw-to-orange-600'
  | 'md:tw-to-orange-600'
  | 'lg:tw-to-orange-600'
  | 'xl:tw-to-orange-600'
  | 'hover:tw-to-orange-600'
  | 'focus:tw-to-orange-600'
  | 'sm:tw-from-orange-700'
  | 'md:tw-from-orange-700'
  | 'lg:tw-from-orange-700'
  | 'xl:tw-from-orange-700'
  | 'hover:tw-from-orange-700'
  | 'focus:tw-from-orange-700'
  | 'sm:tw-via-orange-700'
  | 'md:tw-via-orange-700'
  | 'lg:tw-via-orange-700'
  | 'xl:tw-via-orange-700'
  | 'hover:tw-via-orange-700'
  | 'focus:tw-via-orange-700'
  | 'sm:tw-to-orange-700'
  | 'md:tw-to-orange-700'
  | 'lg:tw-to-orange-700'
  | 'xl:tw-to-orange-700'
  | 'hover:tw-to-orange-700'
  | 'focus:tw-to-orange-700'
  | 'sm:tw-from-orange-800'
  | 'md:tw-from-orange-800'
  | 'lg:tw-from-orange-800'
  | 'xl:tw-from-orange-800'
  | 'hover:tw-from-orange-800'
  | 'focus:tw-from-orange-800'
  | 'sm:tw-via-orange-800'
  | 'md:tw-via-orange-800'
  | 'lg:tw-via-orange-800'
  | 'xl:tw-via-orange-800'
  | 'hover:tw-via-orange-800'
  | 'focus:tw-via-orange-800'
  | 'sm:tw-to-orange-800'
  | 'md:tw-to-orange-800'
  | 'lg:tw-to-orange-800'
  | 'xl:tw-to-orange-800'
  | 'hover:tw-to-orange-800'
  | 'focus:tw-to-orange-800'
  | 'sm:tw-from-orange-900'
  | 'md:tw-from-orange-900'
  | 'lg:tw-from-orange-900'
  | 'xl:tw-from-orange-900'
  | 'hover:tw-from-orange-900'
  | 'focus:tw-from-orange-900'
  | 'sm:tw-via-orange-900'
  | 'md:tw-via-orange-900'
  | 'lg:tw-via-orange-900'
  | 'xl:tw-via-orange-900'
  | 'hover:tw-via-orange-900'
  | 'focus:tw-via-orange-900'
  | 'sm:tw-to-orange-900'
  | 'md:tw-to-orange-900'
  | 'lg:tw-to-orange-900'
  | 'xl:tw-to-orange-900'
  | 'hover:tw-to-orange-900'
  | 'focus:tw-to-orange-900'
  | 'sm:tw-from-orange-a100'
  | 'md:tw-from-orange-a100'
  | 'lg:tw-from-orange-a100'
  | 'xl:tw-from-orange-a100'
  | 'hover:tw-from-orange-a100'
  | 'focus:tw-from-orange-a100'
  | 'sm:tw-via-orange-a100'
  | 'md:tw-via-orange-a100'
  | 'lg:tw-via-orange-a100'
  | 'xl:tw-via-orange-a100'
  | 'hover:tw-via-orange-a100'
  | 'focus:tw-via-orange-a100'
  | 'sm:tw-to-orange-a100'
  | 'md:tw-to-orange-a100'
  | 'lg:tw-to-orange-a100'
  | 'xl:tw-to-orange-a100'
  | 'hover:tw-to-orange-a100'
  | 'focus:tw-to-orange-a100'
  | 'sm:tw-from-orange-a200'
  | 'md:tw-from-orange-a200'
  | 'lg:tw-from-orange-a200'
  | 'xl:tw-from-orange-a200'
  | 'hover:tw-from-orange-a200'
  | 'focus:tw-from-orange-a200'
  | 'sm:tw-via-orange-a200'
  | 'md:tw-via-orange-a200'
  | 'lg:tw-via-orange-a200'
  | 'xl:tw-via-orange-a200'
  | 'hover:tw-via-orange-a200'
  | 'focus:tw-via-orange-a200'
  | 'sm:tw-to-orange-a200'
  | 'md:tw-to-orange-a200'
  | 'lg:tw-to-orange-a200'
  | 'xl:tw-to-orange-a200'
  | 'hover:tw-to-orange-a200'
  | 'focus:tw-to-orange-a200'
  | 'sm:tw-from-orange-a400'
  | 'md:tw-from-orange-a400'
  | 'lg:tw-from-orange-a400'
  | 'xl:tw-from-orange-a400'
  | 'hover:tw-from-orange-a400'
  | 'focus:tw-from-orange-a400'
  | 'sm:tw-via-orange-a400'
  | 'md:tw-via-orange-a400'
  | 'lg:tw-via-orange-a400'
  | 'xl:tw-via-orange-a400'
  | 'hover:tw-via-orange-a400'
  | 'focus:tw-via-orange-a400'
  | 'sm:tw-to-orange-a400'
  | 'md:tw-to-orange-a400'
  | 'lg:tw-to-orange-a400'
  | 'xl:tw-to-orange-a400'
  | 'hover:tw-to-orange-a400'
  | 'focus:tw-to-orange-a400'
  | 'sm:tw-from-orange-a700'
  | 'md:tw-from-orange-a700'
  | 'lg:tw-from-orange-a700'
  | 'xl:tw-from-orange-a700'
  | 'hover:tw-from-orange-a700'
  | 'focus:tw-from-orange-a700'
  | 'sm:tw-via-orange-a700'
  | 'md:tw-via-orange-a700'
  | 'lg:tw-via-orange-a700'
  | 'xl:tw-via-orange-a700'
  | 'hover:tw-via-orange-a700'
  | 'focus:tw-via-orange-a700'
  | 'sm:tw-to-orange-a700'
  | 'md:tw-to-orange-a700'
  | 'lg:tw-to-orange-a700'
  | 'xl:tw-to-orange-a700'
  | 'hover:tw-to-orange-a700'
  | 'focus:tw-to-orange-a700'
  | 'sm:tw-from-deepOrange-50'
  | 'md:tw-from-deepOrange-50'
  | 'lg:tw-from-deepOrange-50'
  | 'xl:tw-from-deepOrange-50'
  | 'hover:tw-from-deepOrange-50'
  | 'focus:tw-from-deepOrange-50'
  | 'sm:tw-via-deepOrange-50'
  | 'md:tw-via-deepOrange-50'
  | 'lg:tw-via-deepOrange-50'
  | 'xl:tw-via-deepOrange-50'
  | 'hover:tw-via-deepOrange-50'
  | 'focus:tw-via-deepOrange-50'
  | 'sm:tw-to-deepOrange-50'
  | 'md:tw-to-deepOrange-50'
  | 'lg:tw-to-deepOrange-50'
  | 'xl:tw-to-deepOrange-50'
  | 'hover:tw-to-deepOrange-50'
  | 'focus:tw-to-deepOrange-50'
  | 'sm:tw-from-deepOrange-100'
  | 'md:tw-from-deepOrange-100'
  | 'lg:tw-from-deepOrange-100'
  | 'xl:tw-from-deepOrange-100'
  | 'hover:tw-from-deepOrange-100'
  | 'focus:tw-from-deepOrange-100'
  | 'sm:tw-via-deepOrange-100'
  | 'md:tw-via-deepOrange-100'
  | 'lg:tw-via-deepOrange-100'
  | 'xl:tw-via-deepOrange-100'
  | 'hover:tw-via-deepOrange-100'
  | 'focus:tw-via-deepOrange-100'
  | 'sm:tw-to-deepOrange-100'
  | 'md:tw-to-deepOrange-100'
  | 'lg:tw-to-deepOrange-100'
  | 'xl:tw-to-deepOrange-100'
  | 'hover:tw-to-deepOrange-100'
  | 'focus:tw-to-deepOrange-100'
  | 'sm:tw-from-deepOrange-200'
  | 'md:tw-from-deepOrange-200'
  | 'lg:tw-from-deepOrange-200'
  | 'xl:tw-from-deepOrange-200'
  | 'hover:tw-from-deepOrange-200'
  | 'focus:tw-from-deepOrange-200'
  | 'sm:tw-via-deepOrange-200'
  | 'md:tw-via-deepOrange-200'
  | 'lg:tw-via-deepOrange-200'
  | 'xl:tw-via-deepOrange-200'
  | 'hover:tw-via-deepOrange-200'
  | 'focus:tw-via-deepOrange-200'
  | 'sm:tw-to-deepOrange-200'
  | 'md:tw-to-deepOrange-200'
  | 'lg:tw-to-deepOrange-200'
  | 'xl:tw-to-deepOrange-200'
  | 'hover:tw-to-deepOrange-200'
  | 'focus:tw-to-deepOrange-200'
  | 'sm:tw-from-deepOrange-300'
  | 'md:tw-from-deepOrange-300'
  | 'lg:tw-from-deepOrange-300'
  | 'xl:tw-from-deepOrange-300'
  | 'hover:tw-from-deepOrange-300'
  | 'focus:tw-from-deepOrange-300'
  | 'sm:tw-via-deepOrange-300'
  | 'md:tw-via-deepOrange-300'
  | 'lg:tw-via-deepOrange-300'
  | 'xl:tw-via-deepOrange-300'
  | 'hover:tw-via-deepOrange-300'
  | 'focus:tw-via-deepOrange-300'
  | 'sm:tw-to-deepOrange-300'
  | 'md:tw-to-deepOrange-300'
  | 'lg:tw-to-deepOrange-300'
  | 'xl:tw-to-deepOrange-300'
  | 'hover:tw-to-deepOrange-300'
  | 'focus:tw-to-deepOrange-300'
  | 'sm:tw-from-deepOrange-400'
  | 'md:tw-from-deepOrange-400'
  | 'lg:tw-from-deepOrange-400'
  | 'xl:tw-from-deepOrange-400'
  | 'hover:tw-from-deepOrange-400'
  | 'focus:tw-from-deepOrange-400'
  | 'sm:tw-via-deepOrange-400'
  | 'md:tw-via-deepOrange-400'
  | 'lg:tw-via-deepOrange-400'
  | 'xl:tw-via-deepOrange-400'
  | 'hover:tw-via-deepOrange-400'
  | 'focus:tw-via-deepOrange-400'
  | 'sm:tw-to-deepOrange-400'
  | 'md:tw-to-deepOrange-400'
  | 'lg:tw-to-deepOrange-400'
  | 'xl:tw-to-deepOrange-400'
  | 'hover:tw-to-deepOrange-400'
  | 'focus:tw-to-deepOrange-400'
  | 'sm:tw-from-deepOrange-500'
  | 'md:tw-from-deepOrange-500'
  | 'lg:tw-from-deepOrange-500'
  | 'xl:tw-from-deepOrange-500'
  | 'hover:tw-from-deepOrange-500'
  | 'focus:tw-from-deepOrange-500'
  | 'sm:tw-via-deepOrange-500'
  | 'md:tw-via-deepOrange-500'
  | 'lg:tw-via-deepOrange-500'
  | 'xl:tw-via-deepOrange-500'
  | 'hover:tw-via-deepOrange-500'
  | 'focus:tw-via-deepOrange-500'
  | 'sm:tw-to-deepOrange-500'
  | 'md:tw-to-deepOrange-500'
  | 'lg:tw-to-deepOrange-500'
  | 'xl:tw-to-deepOrange-500'
  | 'hover:tw-to-deepOrange-500'
  | 'focus:tw-to-deepOrange-500'
  | 'sm:tw-from-deepOrange-600'
  | 'md:tw-from-deepOrange-600'
  | 'lg:tw-from-deepOrange-600'
  | 'xl:tw-from-deepOrange-600'
  | 'hover:tw-from-deepOrange-600'
  | 'focus:tw-from-deepOrange-600'
  | 'sm:tw-via-deepOrange-600'
  | 'md:tw-via-deepOrange-600'
  | 'lg:tw-via-deepOrange-600'
  | 'xl:tw-via-deepOrange-600'
  | 'hover:tw-via-deepOrange-600'
  | 'focus:tw-via-deepOrange-600'
  | 'sm:tw-to-deepOrange-600'
  | 'md:tw-to-deepOrange-600'
  | 'lg:tw-to-deepOrange-600'
  | 'xl:tw-to-deepOrange-600'
  | 'hover:tw-to-deepOrange-600'
  | 'focus:tw-to-deepOrange-600'
  | 'sm:tw-from-deepOrange-700'
  | 'md:tw-from-deepOrange-700'
  | 'lg:tw-from-deepOrange-700'
  | 'xl:tw-from-deepOrange-700'
  | 'hover:tw-from-deepOrange-700'
  | 'focus:tw-from-deepOrange-700'
  | 'sm:tw-via-deepOrange-700'
  | 'md:tw-via-deepOrange-700'
  | 'lg:tw-via-deepOrange-700'
  | 'xl:tw-via-deepOrange-700'
  | 'hover:tw-via-deepOrange-700'
  | 'focus:tw-via-deepOrange-700'
  | 'sm:tw-to-deepOrange-700'
  | 'md:tw-to-deepOrange-700'
  | 'lg:tw-to-deepOrange-700'
  | 'xl:tw-to-deepOrange-700'
  | 'hover:tw-to-deepOrange-700'
  | 'focus:tw-to-deepOrange-700'
  | 'sm:tw-from-deepOrange-800'
  | 'md:tw-from-deepOrange-800'
  | 'lg:tw-from-deepOrange-800'
  | 'xl:tw-from-deepOrange-800'
  | 'hover:tw-from-deepOrange-800'
  | 'focus:tw-from-deepOrange-800'
  | 'sm:tw-via-deepOrange-800'
  | 'md:tw-via-deepOrange-800'
  | 'lg:tw-via-deepOrange-800'
  | 'xl:tw-via-deepOrange-800'
  | 'hover:tw-via-deepOrange-800'
  | 'focus:tw-via-deepOrange-800'
  | 'sm:tw-to-deepOrange-800'
  | 'md:tw-to-deepOrange-800'
  | 'lg:tw-to-deepOrange-800'
  | 'xl:tw-to-deepOrange-800'
  | 'hover:tw-to-deepOrange-800'
  | 'focus:tw-to-deepOrange-800'
  | 'sm:tw-from-deepOrange-900'
  | 'md:tw-from-deepOrange-900'
  | 'lg:tw-from-deepOrange-900'
  | 'xl:tw-from-deepOrange-900'
  | 'hover:tw-from-deepOrange-900'
  | 'focus:tw-from-deepOrange-900'
  | 'sm:tw-via-deepOrange-900'
  | 'md:tw-via-deepOrange-900'
  | 'lg:tw-via-deepOrange-900'
  | 'xl:tw-via-deepOrange-900'
  | 'hover:tw-via-deepOrange-900'
  | 'focus:tw-via-deepOrange-900'
  | 'sm:tw-to-deepOrange-900'
  | 'md:tw-to-deepOrange-900'
  | 'lg:tw-to-deepOrange-900'
  | 'xl:tw-to-deepOrange-900'
  | 'hover:tw-to-deepOrange-900'
  | 'focus:tw-to-deepOrange-900'
  | 'sm:tw-from-deepOrange-a100'
  | 'md:tw-from-deepOrange-a100'
  | 'lg:tw-from-deepOrange-a100'
  | 'xl:tw-from-deepOrange-a100'
  | 'hover:tw-from-deepOrange-a100'
  | 'focus:tw-from-deepOrange-a100'
  | 'sm:tw-via-deepOrange-a100'
  | 'md:tw-via-deepOrange-a100'
  | 'lg:tw-via-deepOrange-a100'
  | 'xl:tw-via-deepOrange-a100'
  | 'hover:tw-via-deepOrange-a100'
  | 'focus:tw-via-deepOrange-a100'
  | 'sm:tw-to-deepOrange-a100'
  | 'md:tw-to-deepOrange-a100'
  | 'lg:tw-to-deepOrange-a100'
  | 'xl:tw-to-deepOrange-a100'
  | 'hover:tw-to-deepOrange-a100'
  | 'focus:tw-to-deepOrange-a100'
  | 'sm:tw-from-deepOrange-a200'
  | 'md:tw-from-deepOrange-a200'
  | 'lg:tw-from-deepOrange-a200'
  | 'xl:tw-from-deepOrange-a200'
  | 'hover:tw-from-deepOrange-a200'
  | 'focus:tw-from-deepOrange-a200'
  | 'sm:tw-via-deepOrange-a200'
  | 'md:tw-via-deepOrange-a200'
  | 'lg:tw-via-deepOrange-a200'
  | 'xl:tw-via-deepOrange-a200'
  | 'hover:tw-via-deepOrange-a200'
  | 'focus:tw-via-deepOrange-a200'
  | 'sm:tw-to-deepOrange-a200'
  | 'md:tw-to-deepOrange-a200'
  | 'lg:tw-to-deepOrange-a200'
  | 'xl:tw-to-deepOrange-a200'
  | 'hover:tw-to-deepOrange-a200'
  | 'focus:tw-to-deepOrange-a200'
  | 'sm:tw-from-deepOrange-a400'
  | 'md:tw-from-deepOrange-a400'
  | 'lg:tw-from-deepOrange-a400'
  | 'xl:tw-from-deepOrange-a400'
  | 'hover:tw-from-deepOrange-a400'
  | 'focus:tw-from-deepOrange-a400'
  | 'sm:tw-via-deepOrange-a400'
  | 'md:tw-via-deepOrange-a400'
  | 'lg:tw-via-deepOrange-a400'
  | 'xl:tw-via-deepOrange-a400'
  | 'hover:tw-via-deepOrange-a400'
  | 'focus:tw-via-deepOrange-a400'
  | 'sm:tw-to-deepOrange-a400'
  | 'md:tw-to-deepOrange-a400'
  | 'lg:tw-to-deepOrange-a400'
  | 'xl:tw-to-deepOrange-a400'
  | 'hover:tw-to-deepOrange-a400'
  | 'focus:tw-to-deepOrange-a400'
  | 'sm:tw-from-deepOrange-a700'
  | 'md:tw-from-deepOrange-a700'
  | 'lg:tw-from-deepOrange-a700'
  | 'xl:tw-from-deepOrange-a700'
  | 'hover:tw-from-deepOrange-a700'
  | 'focus:tw-from-deepOrange-a700'
  | 'sm:tw-via-deepOrange-a700'
  | 'md:tw-via-deepOrange-a700'
  | 'lg:tw-via-deepOrange-a700'
  | 'xl:tw-via-deepOrange-a700'
  | 'hover:tw-via-deepOrange-a700'
  | 'focus:tw-via-deepOrange-a700'
  | 'sm:tw-to-deepOrange-a700'
  | 'md:tw-to-deepOrange-a700'
  | 'lg:tw-to-deepOrange-a700'
  | 'xl:tw-to-deepOrange-a700'
  | 'hover:tw-to-deepOrange-a700'
  | 'focus:tw-to-deepOrange-a700'
  | 'sm:tw-from-brown-50'
  | 'md:tw-from-brown-50'
  | 'lg:tw-from-brown-50'
  | 'xl:tw-from-brown-50'
  | 'hover:tw-from-brown-50'
  | 'focus:tw-from-brown-50'
  | 'sm:tw-via-brown-50'
  | 'md:tw-via-brown-50'
  | 'lg:tw-via-brown-50'
  | 'xl:tw-via-brown-50'
  | 'hover:tw-via-brown-50'
  | 'focus:tw-via-brown-50'
  | 'sm:tw-to-brown-50'
  | 'md:tw-to-brown-50'
  | 'lg:tw-to-brown-50'
  | 'xl:tw-to-brown-50'
  | 'hover:tw-to-brown-50'
  | 'focus:tw-to-brown-50'
  | 'sm:tw-from-brown-100'
  | 'md:tw-from-brown-100'
  | 'lg:tw-from-brown-100'
  | 'xl:tw-from-brown-100'
  | 'hover:tw-from-brown-100'
  | 'focus:tw-from-brown-100'
  | 'sm:tw-via-brown-100'
  | 'md:tw-via-brown-100'
  | 'lg:tw-via-brown-100'
  | 'xl:tw-via-brown-100'
  | 'hover:tw-via-brown-100'
  | 'focus:tw-via-brown-100'
  | 'sm:tw-to-brown-100'
  | 'md:tw-to-brown-100'
  | 'lg:tw-to-brown-100'
  | 'xl:tw-to-brown-100'
  | 'hover:tw-to-brown-100'
  | 'focus:tw-to-brown-100'
  | 'sm:tw-from-brown-200'
  | 'md:tw-from-brown-200'
  | 'lg:tw-from-brown-200'
  | 'xl:tw-from-brown-200'
  | 'hover:tw-from-brown-200'
  | 'focus:tw-from-brown-200'
  | 'sm:tw-via-brown-200'
  | 'md:tw-via-brown-200'
  | 'lg:tw-via-brown-200'
  | 'xl:tw-via-brown-200'
  | 'hover:tw-via-brown-200'
  | 'focus:tw-via-brown-200'
  | 'sm:tw-to-brown-200'
  | 'md:tw-to-brown-200'
  | 'lg:tw-to-brown-200'
  | 'xl:tw-to-brown-200'
  | 'hover:tw-to-brown-200'
  | 'focus:tw-to-brown-200'
  | 'sm:tw-from-brown-300'
  | 'md:tw-from-brown-300'
  | 'lg:tw-from-brown-300'
  | 'xl:tw-from-brown-300'
  | 'hover:tw-from-brown-300'
  | 'focus:tw-from-brown-300'
  | 'sm:tw-via-brown-300'
  | 'md:tw-via-brown-300'
  | 'lg:tw-via-brown-300'
  | 'xl:tw-via-brown-300'
  | 'hover:tw-via-brown-300'
  | 'focus:tw-via-brown-300'
  | 'sm:tw-to-brown-300'
  | 'md:tw-to-brown-300'
  | 'lg:tw-to-brown-300'
  | 'xl:tw-to-brown-300'
  | 'hover:tw-to-brown-300'
  | 'focus:tw-to-brown-300'
  | 'sm:tw-from-brown-400'
  | 'md:tw-from-brown-400'
  | 'lg:tw-from-brown-400'
  | 'xl:tw-from-brown-400'
  | 'hover:tw-from-brown-400'
  | 'focus:tw-from-brown-400'
  | 'sm:tw-via-brown-400'
  | 'md:tw-via-brown-400'
  | 'lg:tw-via-brown-400'
  | 'xl:tw-via-brown-400'
  | 'hover:tw-via-brown-400'
  | 'focus:tw-via-brown-400'
  | 'sm:tw-to-brown-400'
  | 'md:tw-to-brown-400'
  | 'lg:tw-to-brown-400'
  | 'xl:tw-to-brown-400'
  | 'hover:tw-to-brown-400'
  | 'focus:tw-to-brown-400'
  | 'sm:tw-from-brown-500'
  | 'md:tw-from-brown-500'
  | 'lg:tw-from-brown-500'
  | 'xl:tw-from-brown-500'
  | 'hover:tw-from-brown-500'
  | 'focus:tw-from-brown-500'
  | 'sm:tw-via-brown-500'
  | 'md:tw-via-brown-500'
  | 'lg:tw-via-brown-500'
  | 'xl:tw-via-brown-500'
  | 'hover:tw-via-brown-500'
  | 'focus:tw-via-brown-500'
  | 'sm:tw-to-brown-500'
  | 'md:tw-to-brown-500'
  | 'lg:tw-to-brown-500'
  | 'xl:tw-to-brown-500'
  | 'hover:tw-to-brown-500'
  | 'focus:tw-to-brown-500'
  | 'sm:tw-from-brown-600'
  | 'md:tw-from-brown-600'
  | 'lg:tw-from-brown-600'
  | 'xl:tw-from-brown-600'
  | 'hover:tw-from-brown-600'
  | 'focus:tw-from-brown-600'
  | 'sm:tw-via-brown-600'
  | 'md:tw-via-brown-600'
  | 'lg:tw-via-brown-600'
  | 'xl:tw-via-brown-600'
  | 'hover:tw-via-brown-600'
  | 'focus:tw-via-brown-600'
  | 'sm:tw-to-brown-600'
  | 'md:tw-to-brown-600'
  | 'lg:tw-to-brown-600'
  | 'xl:tw-to-brown-600'
  | 'hover:tw-to-brown-600'
  | 'focus:tw-to-brown-600'
  | 'sm:tw-from-brown-700'
  | 'md:tw-from-brown-700'
  | 'lg:tw-from-brown-700'
  | 'xl:tw-from-brown-700'
  | 'hover:tw-from-brown-700'
  | 'focus:tw-from-brown-700'
  | 'sm:tw-via-brown-700'
  | 'md:tw-via-brown-700'
  | 'lg:tw-via-brown-700'
  | 'xl:tw-via-brown-700'
  | 'hover:tw-via-brown-700'
  | 'focus:tw-via-brown-700'
  | 'sm:tw-to-brown-700'
  | 'md:tw-to-brown-700'
  | 'lg:tw-to-brown-700'
  | 'xl:tw-to-brown-700'
  | 'hover:tw-to-brown-700'
  | 'focus:tw-to-brown-700'
  | 'sm:tw-from-brown-800'
  | 'md:tw-from-brown-800'
  | 'lg:tw-from-brown-800'
  | 'xl:tw-from-brown-800'
  | 'hover:tw-from-brown-800'
  | 'focus:tw-from-brown-800'
  | 'sm:tw-via-brown-800'
  | 'md:tw-via-brown-800'
  | 'lg:tw-via-brown-800'
  | 'xl:tw-via-brown-800'
  | 'hover:tw-via-brown-800'
  | 'focus:tw-via-brown-800'
  | 'sm:tw-to-brown-800'
  | 'md:tw-to-brown-800'
  | 'lg:tw-to-brown-800'
  | 'xl:tw-to-brown-800'
  | 'hover:tw-to-brown-800'
  | 'focus:tw-to-brown-800'
  | 'sm:tw-from-brown-900'
  | 'md:tw-from-brown-900'
  | 'lg:tw-from-brown-900'
  | 'xl:tw-from-brown-900'
  | 'hover:tw-from-brown-900'
  | 'focus:tw-from-brown-900'
  | 'sm:tw-via-brown-900'
  | 'md:tw-via-brown-900'
  | 'lg:tw-via-brown-900'
  | 'xl:tw-via-brown-900'
  | 'hover:tw-via-brown-900'
  | 'focus:tw-via-brown-900'
  | 'sm:tw-to-brown-900'
  | 'md:tw-to-brown-900'
  | 'lg:tw-to-brown-900'
  | 'xl:tw-to-brown-900'
  | 'hover:tw-to-brown-900'
  | 'focus:tw-to-brown-900'
  | 'sm:tw-from-gray-50'
  | 'md:tw-from-gray-50'
  | 'lg:tw-from-gray-50'
  | 'xl:tw-from-gray-50'
  | 'hover:tw-from-gray-50'
  | 'focus:tw-from-gray-50'
  | 'sm:tw-via-gray-50'
  | 'md:tw-via-gray-50'
  | 'lg:tw-via-gray-50'
  | 'xl:tw-via-gray-50'
  | 'hover:tw-via-gray-50'
  | 'focus:tw-via-gray-50'
  | 'sm:tw-to-gray-50'
  | 'md:tw-to-gray-50'
  | 'lg:tw-to-gray-50'
  | 'xl:tw-to-gray-50'
  | 'hover:tw-to-gray-50'
  | 'focus:tw-to-gray-50'
  | 'sm:tw-from-gray-100'
  | 'md:tw-from-gray-100'
  | 'lg:tw-from-gray-100'
  | 'xl:tw-from-gray-100'
  | 'hover:tw-from-gray-100'
  | 'focus:tw-from-gray-100'
  | 'sm:tw-via-gray-100'
  | 'md:tw-via-gray-100'
  | 'lg:tw-via-gray-100'
  | 'xl:tw-via-gray-100'
  | 'hover:tw-via-gray-100'
  | 'focus:tw-via-gray-100'
  | 'sm:tw-to-gray-100'
  | 'md:tw-to-gray-100'
  | 'lg:tw-to-gray-100'
  | 'xl:tw-to-gray-100'
  | 'hover:tw-to-gray-100'
  | 'focus:tw-to-gray-100'
  | 'sm:tw-from-gray-200'
  | 'md:tw-from-gray-200'
  | 'lg:tw-from-gray-200'
  | 'xl:tw-from-gray-200'
  | 'hover:tw-from-gray-200'
  | 'focus:tw-from-gray-200'
  | 'sm:tw-via-gray-200'
  | 'md:tw-via-gray-200'
  | 'lg:tw-via-gray-200'
  | 'xl:tw-via-gray-200'
  | 'hover:tw-via-gray-200'
  | 'focus:tw-via-gray-200'
  | 'sm:tw-to-gray-200'
  | 'md:tw-to-gray-200'
  | 'lg:tw-to-gray-200'
  | 'xl:tw-to-gray-200'
  | 'hover:tw-to-gray-200'
  | 'focus:tw-to-gray-200'
  | 'sm:tw-from-gray-300'
  | 'md:tw-from-gray-300'
  | 'lg:tw-from-gray-300'
  | 'xl:tw-from-gray-300'
  | 'hover:tw-from-gray-300'
  | 'focus:tw-from-gray-300'
  | 'sm:tw-via-gray-300'
  | 'md:tw-via-gray-300'
  | 'lg:tw-via-gray-300'
  | 'xl:tw-via-gray-300'
  | 'hover:tw-via-gray-300'
  | 'focus:tw-via-gray-300'
  | 'sm:tw-to-gray-300'
  | 'md:tw-to-gray-300'
  | 'lg:tw-to-gray-300'
  | 'xl:tw-to-gray-300'
  | 'hover:tw-to-gray-300'
  | 'focus:tw-to-gray-300'
  | 'sm:tw-from-gray-400'
  | 'md:tw-from-gray-400'
  | 'lg:tw-from-gray-400'
  | 'xl:tw-from-gray-400'
  | 'hover:tw-from-gray-400'
  | 'focus:tw-from-gray-400'
  | 'sm:tw-via-gray-400'
  | 'md:tw-via-gray-400'
  | 'lg:tw-via-gray-400'
  | 'xl:tw-via-gray-400'
  | 'hover:tw-via-gray-400'
  | 'focus:tw-via-gray-400'
  | 'sm:tw-to-gray-400'
  | 'md:tw-to-gray-400'
  | 'lg:tw-to-gray-400'
  | 'xl:tw-to-gray-400'
  | 'hover:tw-to-gray-400'
  | 'focus:tw-to-gray-400'
  | 'sm:tw-from-gray-500'
  | 'md:tw-from-gray-500'
  | 'lg:tw-from-gray-500'
  | 'xl:tw-from-gray-500'
  | 'hover:tw-from-gray-500'
  | 'focus:tw-from-gray-500'
  | 'sm:tw-via-gray-500'
  | 'md:tw-via-gray-500'
  | 'lg:tw-via-gray-500'
  | 'xl:tw-via-gray-500'
  | 'hover:tw-via-gray-500'
  | 'focus:tw-via-gray-500'
  | 'sm:tw-to-gray-500'
  | 'md:tw-to-gray-500'
  | 'lg:tw-to-gray-500'
  | 'xl:tw-to-gray-500'
  | 'hover:tw-to-gray-500'
  | 'focus:tw-to-gray-500'
  | 'sm:tw-from-gray-600'
  | 'md:tw-from-gray-600'
  | 'lg:tw-from-gray-600'
  | 'xl:tw-from-gray-600'
  | 'hover:tw-from-gray-600'
  | 'focus:tw-from-gray-600'
  | 'sm:tw-via-gray-600'
  | 'md:tw-via-gray-600'
  | 'lg:tw-via-gray-600'
  | 'xl:tw-via-gray-600'
  | 'hover:tw-via-gray-600'
  | 'focus:tw-via-gray-600'
  | 'sm:tw-to-gray-600'
  | 'md:tw-to-gray-600'
  | 'lg:tw-to-gray-600'
  | 'xl:tw-to-gray-600'
  | 'hover:tw-to-gray-600'
  | 'focus:tw-to-gray-600'
  | 'sm:tw-from-gray-700'
  | 'md:tw-from-gray-700'
  | 'lg:tw-from-gray-700'
  | 'xl:tw-from-gray-700'
  | 'hover:tw-from-gray-700'
  | 'focus:tw-from-gray-700'
  | 'sm:tw-via-gray-700'
  | 'md:tw-via-gray-700'
  | 'lg:tw-via-gray-700'
  | 'xl:tw-via-gray-700'
  | 'hover:tw-via-gray-700'
  | 'focus:tw-via-gray-700'
  | 'sm:tw-to-gray-700'
  | 'md:tw-to-gray-700'
  | 'lg:tw-to-gray-700'
  | 'xl:tw-to-gray-700'
  | 'hover:tw-to-gray-700'
  | 'focus:tw-to-gray-700'
  | 'sm:tw-from-gray-800'
  | 'md:tw-from-gray-800'
  | 'lg:tw-from-gray-800'
  | 'xl:tw-from-gray-800'
  | 'hover:tw-from-gray-800'
  | 'focus:tw-from-gray-800'
  | 'sm:tw-via-gray-800'
  | 'md:tw-via-gray-800'
  | 'lg:tw-via-gray-800'
  | 'xl:tw-via-gray-800'
  | 'hover:tw-via-gray-800'
  | 'focus:tw-via-gray-800'
  | 'sm:tw-to-gray-800'
  | 'md:tw-to-gray-800'
  | 'lg:tw-to-gray-800'
  | 'xl:tw-to-gray-800'
  | 'hover:tw-to-gray-800'
  | 'focus:tw-to-gray-800'
  | 'sm:tw-from-gray-900'
  | 'md:tw-from-gray-900'
  | 'lg:tw-from-gray-900'
  | 'xl:tw-from-gray-900'
  | 'hover:tw-from-gray-900'
  | 'focus:tw-from-gray-900'
  | 'sm:tw-via-gray-900'
  | 'md:tw-via-gray-900'
  | 'lg:tw-via-gray-900'
  | 'xl:tw-via-gray-900'
  | 'hover:tw-via-gray-900'
  | 'focus:tw-via-gray-900'
  | 'sm:tw-to-gray-900'
  | 'md:tw-to-gray-900'
  | 'lg:tw-to-gray-900'
  | 'xl:tw-to-gray-900'
  | 'hover:tw-to-gray-900'
  | 'focus:tw-to-gray-900'
  | 'sm:tw-from-blueGray-50'
  | 'md:tw-from-blueGray-50'
  | 'lg:tw-from-blueGray-50'
  | 'xl:tw-from-blueGray-50'
  | 'hover:tw-from-blueGray-50'
  | 'focus:tw-from-blueGray-50'
  | 'sm:tw-via-blueGray-50'
  | 'md:tw-via-blueGray-50'
  | 'lg:tw-via-blueGray-50'
  | 'xl:tw-via-blueGray-50'
  | 'hover:tw-via-blueGray-50'
  | 'focus:tw-via-blueGray-50'
  | 'sm:tw-to-blueGray-50'
  | 'md:tw-to-blueGray-50'
  | 'lg:tw-to-blueGray-50'
  | 'xl:tw-to-blueGray-50'
  | 'hover:tw-to-blueGray-50'
  | 'focus:tw-to-blueGray-50'
  | 'sm:tw-from-blueGray-100'
  | 'md:tw-from-blueGray-100'
  | 'lg:tw-from-blueGray-100'
  | 'xl:tw-from-blueGray-100'
  | 'hover:tw-from-blueGray-100'
  | 'focus:tw-from-blueGray-100'
  | 'sm:tw-via-blueGray-100'
  | 'md:tw-via-blueGray-100'
  | 'lg:tw-via-blueGray-100'
  | 'xl:tw-via-blueGray-100'
  | 'hover:tw-via-blueGray-100'
  | 'focus:tw-via-blueGray-100'
  | 'sm:tw-to-blueGray-100'
  | 'md:tw-to-blueGray-100'
  | 'lg:tw-to-blueGray-100'
  | 'xl:tw-to-blueGray-100'
  | 'hover:tw-to-blueGray-100'
  | 'focus:tw-to-blueGray-100'
  | 'sm:tw-from-blueGray-200'
  | 'md:tw-from-blueGray-200'
  | 'lg:tw-from-blueGray-200'
  | 'xl:tw-from-blueGray-200'
  | 'hover:tw-from-blueGray-200'
  | 'focus:tw-from-blueGray-200'
  | 'sm:tw-via-blueGray-200'
  | 'md:tw-via-blueGray-200'
  | 'lg:tw-via-blueGray-200'
  | 'xl:tw-via-blueGray-200'
  | 'hover:tw-via-blueGray-200'
  | 'focus:tw-via-blueGray-200'
  | 'sm:tw-to-blueGray-200'
  | 'md:tw-to-blueGray-200'
  | 'lg:tw-to-blueGray-200'
  | 'xl:tw-to-blueGray-200'
  | 'hover:tw-to-blueGray-200'
  | 'focus:tw-to-blueGray-200'
  | 'sm:tw-from-blueGray-300'
  | 'md:tw-from-blueGray-300'
  | 'lg:tw-from-blueGray-300'
  | 'xl:tw-from-blueGray-300'
  | 'hover:tw-from-blueGray-300'
  | 'focus:tw-from-blueGray-300'
  | 'sm:tw-via-blueGray-300'
  | 'md:tw-via-blueGray-300'
  | 'lg:tw-via-blueGray-300'
  | 'xl:tw-via-blueGray-300'
  | 'hover:tw-via-blueGray-300'
  | 'focus:tw-via-blueGray-300'
  | 'sm:tw-to-blueGray-300'
  | 'md:tw-to-blueGray-300'
  | 'lg:tw-to-blueGray-300'
  | 'xl:tw-to-blueGray-300'
  | 'hover:tw-to-blueGray-300'
  | 'focus:tw-to-blueGray-300'
  | 'sm:tw-from-blueGray-400'
  | 'md:tw-from-blueGray-400'
  | 'lg:tw-from-blueGray-400'
  | 'xl:tw-from-blueGray-400'
  | 'hover:tw-from-blueGray-400'
  | 'focus:tw-from-blueGray-400'
  | 'sm:tw-via-blueGray-400'
  | 'md:tw-via-blueGray-400'
  | 'lg:tw-via-blueGray-400'
  | 'xl:tw-via-blueGray-400'
  | 'hover:tw-via-blueGray-400'
  | 'focus:tw-via-blueGray-400'
  | 'sm:tw-to-blueGray-400'
  | 'md:tw-to-blueGray-400'
  | 'lg:tw-to-blueGray-400'
  | 'xl:tw-to-blueGray-400'
  | 'hover:tw-to-blueGray-400'
  | 'focus:tw-to-blueGray-400'
  | 'sm:tw-from-blueGray-500'
  | 'md:tw-from-blueGray-500'
  | 'lg:tw-from-blueGray-500'
  | 'xl:tw-from-blueGray-500'
  | 'hover:tw-from-blueGray-500'
  | 'focus:tw-from-blueGray-500'
  | 'sm:tw-via-blueGray-500'
  | 'md:tw-via-blueGray-500'
  | 'lg:tw-via-blueGray-500'
  | 'xl:tw-via-blueGray-500'
  | 'hover:tw-via-blueGray-500'
  | 'focus:tw-via-blueGray-500'
  | 'sm:tw-to-blueGray-500'
  | 'md:tw-to-blueGray-500'
  | 'lg:tw-to-blueGray-500'
  | 'xl:tw-to-blueGray-500'
  | 'hover:tw-to-blueGray-500'
  | 'focus:tw-to-blueGray-500'
  | 'sm:tw-from-blueGray-600'
  | 'md:tw-from-blueGray-600'
  | 'lg:tw-from-blueGray-600'
  | 'xl:tw-from-blueGray-600'
  | 'hover:tw-from-blueGray-600'
  | 'focus:tw-from-blueGray-600'
  | 'sm:tw-via-blueGray-600'
  | 'md:tw-via-blueGray-600'
  | 'lg:tw-via-blueGray-600'
  | 'xl:tw-via-blueGray-600'
  | 'hover:tw-via-blueGray-600'
  | 'focus:tw-via-blueGray-600'
  | 'sm:tw-to-blueGray-600'
  | 'md:tw-to-blueGray-600'
  | 'lg:tw-to-blueGray-600'
  | 'xl:tw-to-blueGray-600'
  | 'hover:tw-to-blueGray-600'
  | 'focus:tw-to-blueGray-600'
  | 'sm:tw-from-blueGray-700'
  | 'md:tw-from-blueGray-700'
  | 'lg:tw-from-blueGray-700'
  | 'xl:tw-from-blueGray-700'
  | 'hover:tw-from-blueGray-700'
  | 'focus:tw-from-blueGray-700'
  | 'sm:tw-via-blueGray-700'
  | 'md:tw-via-blueGray-700'
  | 'lg:tw-via-blueGray-700'
  | 'xl:tw-via-blueGray-700'
  | 'hover:tw-via-blueGray-700'
  | 'focus:tw-via-blueGray-700'
  | 'sm:tw-to-blueGray-700'
  | 'md:tw-to-blueGray-700'
  | 'lg:tw-to-blueGray-700'
  | 'xl:tw-to-blueGray-700'
  | 'hover:tw-to-blueGray-700'
  | 'focus:tw-to-blueGray-700'
  | 'sm:tw-from-blueGray-800'
  | 'md:tw-from-blueGray-800'
  | 'lg:tw-from-blueGray-800'
  | 'xl:tw-from-blueGray-800'
  | 'hover:tw-from-blueGray-800'
  | 'focus:tw-from-blueGray-800'
  | 'sm:tw-via-blueGray-800'
  | 'md:tw-via-blueGray-800'
  | 'lg:tw-via-blueGray-800'
  | 'xl:tw-via-blueGray-800'
  | 'hover:tw-via-blueGray-800'
  | 'focus:tw-via-blueGray-800'
  | 'sm:tw-to-blueGray-800'
  | 'md:tw-to-blueGray-800'
  | 'lg:tw-to-blueGray-800'
  | 'xl:tw-to-blueGray-800'
  | 'hover:tw-to-blueGray-800'
  | 'focus:tw-to-blueGray-800'
  | 'sm:tw-from-blueGray-900'
  | 'md:tw-from-blueGray-900'
  | 'lg:tw-from-blueGray-900'
  | 'xl:tw-from-blueGray-900'
  | 'hover:tw-from-blueGray-900'
  | 'focus:tw-from-blueGray-900'
  | 'sm:tw-via-blueGray-900'
  | 'md:tw-via-blueGray-900'
  | 'lg:tw-via-blueGray-900'
  | 'xl:tw-via-blueGray-900'
  | 'hover:tw-via-blueGray-900'
  | 'focus:tw-via-blueGray-900'
  | 'sm:tw-to-blueGray-900'
  | 'md:tw-to-blueGray-900'
  | 'lg:tw-to-blueGray-900'
  | 'xl:tw-to-blueGray-900'
  | 'hover:tw-to-blueGray-900'
  | 'focus:tw-to-blueGray-900'
  | 'sm:tw-from-black'
  | 'md:tw-from-black'
  | 'lg:tw-from-black'
  | 'xl:tw-from-black'
  | 'hover:tw-from-black'
  | 'focus:tw-from-black'
  | 'sm:tw-via-black'
  | 'md:tw-via-black'
  | 'lg:tw-via-black'
  | 'xl:tw-via-black'
  | 'hover:tw-via-black'
  | 'focus:tw-via-black'
  | 'sm:tw-to-black'
  | 'md:tw-to-black'
  | 'lg:tw-to-black'
  | 'xl:tw-to-black'
  | 'hover:tw-to-black'
  | 'focus:tw-to-black'
  | 'sm:tw-from-white'
  | 'md:tw-from-white'
  | 'lg:tw-from-white'
  | 'xl:tw-from-white'
  | 'hover:tw-from-white'
  | 'focus:tw-from-white'
  | 'sm:tw-via-white'
  | 'md:tw-via-white'
  | 'lg:tw-via-white'
  | 'xl:tw-via-white'
  | 'hover:tw-via-white'
  | 'focus:tw-via-white'
  | 'sm:tw-to-white'
  | 'md:tw-to-white'
  | 'lg:tw-to-white'
  | 'xl:tw-to-white'
  | 'hover:tw-to-white'
  | 'focus:tw-to-white'
  | 'sm:tw-from-transparent'
  | 'md:tw-from-transparent'
  | 'lg:tw-from-transparent'
  | 'xl:tw-from-transparent'
  | 'hover:tw-from-transparent'
  | 'focus:tw-from-transparent'
  | 'sm:tw-via-transparent'
  | 'md:tw-via-transparent'
  | 'lg:tw-via-transparent'
  | 'xl:tw-via-transparent'
  | 'hover:tw-via-transparent'
  | 'focus:tw-via-transparent'
  | 'sm:tw-to-transparent'
  | 'md:tw-to-transparent'
  | 'lg:tw-to-transparent'
  | 'xl:tw-to-transparent'
  | 'hover:tw-to-transparent'
  | 'focus:tw-to-transparent'
  | 'sm:tw-bg-opacity-0'
  | 'md:tw-bg-opacity-0'
  | 'lg:tw-bg-opacity-0'
  | 'xl:tw-bg-opacity-0'
  | 'hover:tw-bg-opacity-0'
  | 'focus:tw-bg-opacity-0'
  | 'sm:tw-bg-opacity-25'
  | 'md:tw-bg-opacity-25'
  | 'lg:tw-bg-opacity-25'
  | 'xl:tw-bg-opacity-25'
  | 'hover:tw-bg-opacity-25'
  | 'focus:tw-bg-opacity-25'
  | 'sm:tw-bg-opacity-50'
  | 'md:tw-bg-opacity-50'
  | 'lg:tw-bg-opacity-50'
  | 'xl:tw-bg-opacity-50'
  | 'hover:tw-bg-opacity-50'
  | 'focus:tw-bg-opacity-50'
  | 'sm:tw-bg-opacity-75'
  | 'md:tw-bg-opacity-75'
  | 'lg:tw-bg-opacity-75'
  | 'xl:tw-bg-opacity-75'
  | 'hover:tw-bg-opacity-75'
  | 'focus:tw-bg-opacity-75'
  | 'sm:tw-bg-opacity-100'
  | 'md:tw-bg-opacity-100'
  | 'lg:tw-bg-opacity-100'
  | 'xl:tw-bg-opacity-100'
  | 'hover:tw-bg-opacity-100'
  | 'focus:tw-bg-opacity-100'
  | 'sm:tw-bg-bottom'
  | 'md:tw-bg-bottom'
  | 'lg:tw-bg-bottom'
  | 'xl:tw-bg-bottom'
  | 'sm:tw-bg-center'
  | 'md:tw-bg-center'
  | 'lg:tw-bg-center'
  | 'xl:tw-bg-center'
  | 'sm:tw-bg-left'
  | 'md:tw-bg-left'
  | 'lg:tw-bg-left'
  | 'xl:tw-bg-left'
  | 'sm:tw-bg-left-bottom'
  | 'md:tw-bg-left-bottom'
  | 'lg:tw-bg-left-bottom'
  | 'xl:tw-bg-left-bottom'
  | 'sm:tw-bg-left-top'
  | 'md:tw-bg-left-top'
  | 'lg:tw-bg-left-top'
  | 'xl:tw-bg-left-top'
  | 'sm:tw-bg-right'
  | 'md:tw-bg-right'
  | 'lg:tw-bg-right'
  | 'xl:tw-bg-right'
  | 'sm:tw-bg-right-bottom'
  | 'md:tw-bg-right-bottom'
  | 'lg:tw-bg-right-bottom'
  | 'xl:tw-bg-right-bottom'
  | 'sm:tw-bg-right-top'
  | 'md:tw-bg-right-top'
  | 'lg:tw-bg-right-top'
  | 'xl:tw-bg-right-top'
  | 'sm:tw-bg-top'
  | 'md:tw-bg-top'
  | 'lg:tw-bg-top'
  | 'xl:tw-bg-top'
  | 'sm:tw-bg-repeat'
  | 'md:tw-bg-repeat'
  | 'lg:tw-bg-repeat'
  | 'xl:tw-bg-repeat'
  | 'sm:tw-bg-no-repeat'
  | 'md:tw-bg-no-repeat'
  | 'lg:tw-bg-no-repeat'
  | 'xl:tw-bg-no-repeat'
  | 'sm:tw-bg-repeat-x'
  | 'md:tw-bg-repeat-x'
  | 'lg:tw-bg-repeat-x'
  | 'xl:tw-bg-repeat-x'
  | 'sm:tw-bg-repeat-y'
  | 'md:tw-bg-repeat-y'
  | 'lg:tw-bg-repeat-y'
  | 'xl:tw-bg-repeat-y'
  | 'sm:tw-bg-repeat-round'
  | 'md:tw-bg-repeat-round'
  | 'lg:tw-bg-repeat-round'
  | 'xl:tw-bg-repeat-round'
  | 'sm:tw-bg-repeat-space'
  | 'md:tw-bg-repeat-space'
  | 'lg:tw-bg-repeat-space'
  | 'xl:tw-bg-repeat-space'
  | 'sm:tw-bg-auto'
  | 'md:tw-bg-auto'
  | 'lg:tw-bg-auto'
  | 'xl:tw-bg-auto'
  | 'sm:tw-bg-cover'
  | 'md:tw-bg-cover'
  | 'lg:tw-bg-cover'
  | 'xl:tw-bg-cover'
  | 'sm:tw-bg-contain'
  | 'md:tw-bg-contain'
  | 'lg:tw-bg-contain'
  | 'xl:tw-bg-contain'
  | 'sm:tw-border-collapse'
  | 'md:tw-border-collapse'
  | 'lg:tw-border-collapse'
  | 'xl:tw-border-collapse'
  | 'sm:tw-border-separate'
  | 'md:tw-border-separate'
  | 'lg:tw-border-separate'
  | 'xl:tw-border-separate'
  | 'sm:tw-border-primary'
  | 'md:tw-border-primary'
  | 'lg:tw-border-primary'
  | 'xl:tw-border-primary'
  | 'hover:tw-border-primary'
  | 'focus:tw-border-primary'
  | 'sm:tw-border-muted'
  | 'md:tw-border-muted'
  | 'lg:tw-border-muted'
  | 'xl:tw-border-muted'
  | 'hover:tw-border-muted'
  | 'focus:tw-border-muted'
  | 'sm:tw-border-warning'
  | 'md:tw-border-warning'
  | 'lg:tw-border-warning'
  | 'xl:tw-border-warning'
  | 'hover:tw-border-warning'
  | 'focus:tw-border-warning'
  | 'sm:tw-border-danger'
  | 'md:tw-border-danger'
  | 'lg:tw-border-danger'
  | 'xl:tw-border-danger'
  | 'hover:tw-border-danger'
  | 'focus:tw-border-danger'
  | 'sm:tw-border-fail'
  | 'md:tw-border-fail'
  | 'lg:tw-border-fail'
  | 'xl:tw-border-fail'
  | 'hover:tw-border-fail'
  | 'focus:tw-border-fail'
  | 'sm:tw-border-info'
  | 'md:tw-border-info'
  | 'lg:tw-border-info'
  | 'xl:tw-border-info'
  | 'hover:tw-border-info'
  | 'focus:tw-border-info'
  | 'sm:tw-border-red-50'
  | 'md:tw-border-red-50'
  | 'lg:tw-border-red-50'
  | 'xl:tw-border-red-50'
  | 'hover:tw-border-red-50'
  | 'focus:tw-border-red-50'
  | 'sm:tw-border-red-100'
  | 'md:tw-border-red-100'
  | 'lg:tw-border-red-100'
  | 'xl:tw-border-red-100'
  | 'hover:tw-border-red-100'
  | 'focus:tw-border-red-100'
  | 'sm:tw-border-red-200'
  | 'md:tw-border-red-200'
  | 'lg:tw-border-red-200'
  | 'xl:tw-border-red-200'
  | 'hover:tw-border-red-200'
  | 'focus:tw-border-red-200'
  | 'sm:tw-border-red-300'
  | 'md:tw-border-red-300'
  | 'lg:tw-border-red-300'
  | 'xl:tw-border-red-300'
  | 'hover:tw-border-red-300'
  | 'focus:tw-border-red-300'
  | 'sm:tw-border-red-400'
  | 'md:tw-border-red-400'
  | 'lg:tw-border-red-400'
  | 'xl:tw-border-red-400'
  | 'hover:tw-border-red-400'
  | 'focus:tw-border-red-400'
  | 'sm:tw-border-red-500'
  | 'md:tw-border-red-500'
  | 'lg:tw-border-red-500'
  | 'xl:tw-border-red-500'
  | 'hover:tw-border-red-500'
  | 'focus:tw-border-red-500'
  | 'sm:tw-border-red-600'
  | 'md:tw-border-red-600'
  | 'lg:tw-border-red-600'
  | 'xl:tw-border-red-600'
  | 'hover:tw-border-red-600'
  | 'focus:tw-border-red-600'
  | 'sm:tw-border-red-700'
  | 'md:tw-border-red-700'
  | 'lg:tw-border-red-700'
  | 'xl:tw-border-red-700'
  | 'hover:tw-border-red-700'
  | 'focus:tw-border-red-700'
  | 'sm:tw-border-red-800'
  | 'md:tw-border-red-800'
  | 'lg:tw-border-red-800'
  | 'xl:tw-border-red-800'
  | 'hover:tw-border-red-800'
  | 'focus:tw-border-red-800'
  | 'sm:tw-border-red-900'
  | 'md:tw-border-red-900'
  | 'lg:tw-border-red-900'
  | 'xl:tw-border-red-900'
  | 'hover:tw-border-red-900'
  | 'focus:tw-border-red-900'
  | 'sm:tw-border-red-a100'
  | 'md:tw-border-red-a100'
  | 'lg:tw-border-red-a100'
  | 'xl:tw-border-red-a100'
  | 'hover:tw-border-red-a100'
  | 'focus:tw-border-red-a100'
  | 'sm:tw-border-red-a200'
  | 'md:tw-border-red-a200'
  | 'lg:tw-border-red-a200'
  | 'xl:tw-border-red-a200'
  | 'hover:tw-border-red-a200'
  | 'focus:tw-border-red-a200'
  | 'sm:tw-border-red-a400'
  | 'md:tw-border-red-a400'
  | 'lg:tw-border-red-a400'
  | 'xl:tw-border-red-a400'
  | 'hover:tw-border-red-a400'
  | 'focus:tw-border-red-a400'
  | 'sm:tw-border-red-a700'
  | 'md:tw-border-red-a700'
  | 'lg:tw-border-red-a700'
  | 'xl:tw-border-red-a700'
  | 'hover:tw-border-red-a700'
  | 'focus:tw-border-red-a700'
  | 'sm:tw-border-pink-50'
  | 'md:tw-border-pink-50'
  | 'lg:tw-border-pink-50'
  | 'xl:tw-border-pink-50'
  | 'hover:tw-border-pink-50'
  | 'focus:tw-border-pink-50'
  | 'sm:tw-border-pink-100'
  | 'md:tw-border-pink-100'
  | 'lg:tw-border-pink-100'
  | 'xl:tw-border-pink-100'
  | 'hover:tw-border-pink-100'
  | 'focus:tw-border-pink-100'
  | 'sm:tw-border-pink-200'
  | 'md:tw-border-pink-200'
  | 'lg:tw-border-pink-200'
  | 'xl:tw-border-pink-200'
  | 'hover:tw-border-pink-200'
  | 'focus:tw-border-pink-200'
  | 'sm:tw-border-pink-300'
  | 'md:tw-border-pink-300'
  | 'lg:tw-border-pink-300'
  | 'xl:tw-border-pink-300'
  | 'hover:tw-border-pink-300'
  | 'focus:tw-border-pink-300'
  | 'sm:tw-border-pink-400'
  | 'md:tw-border-pink-400'
  | 'lg:tw-border-pink-400'
  | 'xl:tw-border-pink-400'
  | 'hover:tw-border-pink-400'
  | 'focus:tw-border-pink-400'
  | 'sm:tw-border-pink-500'
  | 'md:tw-border-pink-500'
  | 'lg:tw-border-pink-500'
  | 'xl:tw-border-pink-500'
  | 'hover:tw-border-pink-500'
  | 'focus:tw-border-pink-500'
  | 'sm:tw-border-pink-600'
  | 'md:tw-border-pink-600'
  | 'lg:tw-border-pink-600'
  | 'xl:tw-border-pink-600'
  | 'hover:tw-border-pink-600'
  | 'focus:tw-border-pink-600'
  | 'sm:tw-border-pink-700'
  | 'md:tw-border-pink-700'
  | 'lg:tw-border-pink-700'
  | 'xl:tw-border-pink-700'
  | 'hover:tw-border-pink-700'
  | 'focus:tw-border-pink-700'
  | 'sm:tw-border-pink-800'
  | 'md:tw-border-pink-800'
  | 'lg:tw-border-pink-800'
  | 'xl:tw-border-pink-800'
  | 'hover:tw-border-pink-800'
  | 'focus:tw-border-pink-800'
  | 'sm:tw-border-pink-900'
  | 'md:tw-border-pink-900'
  | 'lg:tw-border-pink-900'
  | 'xl:tw-border-pink-900'
  | 'hover:tw-border-pink-900'
  | 'focus:tw-border-pink-900'
  | 'sm:tw-border-pink-a100'
  | 'md:tw-border-pink-a100'
  | 'lg:tw-border-pink-a100'
  | 'xl:tw-border-pink-a100'
  | 'hover:tw-border-pink-a100'
  | 'focus:tw-border-pink-a100'
  | 'sm:tw-border-pink-a200'
  | 'md:tw-border-pink-a200'
  | 'lg:tw-border-pink-a200'
  | 'xl:tw-border-pink-a200'
  | 'hover:tw-border-pink-a200'
  | 'focus:tw-border-pink-a200'
  | 'sm:tw-border-pink-a400'
  | 'md:tw-border-pink-a400'
  | 'lg:tw-border-pink-a400'
  | 'xl:tw-border-pink-a400'
  | 'hover:tw-border-pink-a400'
  | 'focus:tw-border-pink-a400'
  | 'sm:tw-border-pink-a700'
  | 'md:tw-border-pink-a700'
  | 'lg:tw-border-pink-a700'
  | 'xl:tw-border-pink-a700'
  | 'hover:tw-border-pink-a700'
  | 'focus:tw-border-pink-a700'
  | 'sm:tw-border-purple-50'
  | 'md:tw-border-purple-50'
  | 'lg:tw-border-purple-50'
  | 'xl:tw-border-purple-50'
  | 'hover:tw-border-purple-50'
  | 'focus:tw-border-purple-50'
  | 'sm:tw-border-purple-100'
  | 'md:tw-border-purple-100'
  | 'lg:tw-border-purple-100'
  | 'xl:tw-border-purple-100'
  | 'hover:tw-border-purple-100'
  | 'focus:tw-border-purple-100'
  | 'sm:tw-border-purple-200'
  | 'md:tw-border-purple-200'
  | 'lg:tw-border-purple-200'
  | 'xl:tw-border-purple-200'
  | 'hover:tw-border-purple-200'
  | 'focus:tw-border-purple-200'
  | 'sm:tw-border-purple-300'
  | 'md:tw-border-purple-300'
  | 'lg:tw-border-purple-300'
  | 'xl:tw-border-purple-300'
  | 'hover:tw-border-purple-300'
  | 'focus:tw-border-purple-300'
  | 'sm:tw-border-purple-400'
  | 'md:tw-border-purple-400'
  | 'lg:tw-border-purple-400'
  | 'xl:tw-border-purple-400'
  | 'hover:tw-border-purple-400'
  | 'focus:tw-border-purple-400'
  | 'sm:tw-border-purple-500'
  | 'md:tw-border-purple-500'
  | 'lg:tw-border-purple-500'
  | 'xl:tw-border-purple-500'
  | 'hover:tw-border-purple-500'
  | 'focus:tw-border-purple-500'
  | 'sm:tw-border-purple-600'
  | 'md:tw-border-purple-600'
  | 'lg:tw-border-purple-600'
  | 'xl:tw-border-purple-600'
  | 'hover:tw-border-purple-600'
  | 'focus:tw-border-purple-600'
  | 'sm:tw-border-purple-700'
  | 'md:tw-border-purple-700'
  | 'lg:tw-border-purple-700'
  | 'xl:tw-border-purple-700'
  | 'hover:tw-border-purple-700'
  | 'focus:tw-border-purple-700'
  | 'sm:tw-border-purple-800'
  | 'md:tw-border-purple-800'
  | 'lg:tw-border-purple-800'
  | 'xl:tw-border-purple-800'
  | 'hover:tw-border-purple-800'
  | 'focus:tw-border-purple-800'
  | 'sm:tw-border-purple-900'
  | 'md:tw-border-purple-900'
  | 'lg:tw-border-purple-900'
  | 'xl:tw-border-purple-900'
  | 'hover:tw-border-purple-900'
  | 'focus:tw-border-purple-900'
  | 'sm:tw-border-purple-a100'
  | 'md:tw-border-purple-a100'
  | 'lg:tw-border-purple-a100'
  | 'xl:tw-border-purple-a100'
  | 'hover:tw-border-purple-a100'
  | 'focus:tw-border-purple-a100'
  | 'sm:tw-border-purple-a200'
  | 'md:tw-border-purple-a200'
  | 'lg:tw-border-purple-a200'
  | 'xl:tw-border-purple-a200'
  | 'hover:tw-border-purple-a200'
  | 'focus:tw-border-purple-a200'
  | 'sm:tw-border-purple-a400'
  | 'md:tw-border-purple-a400'
  | 'lg:tw-border-purple-a400'
  | 'xl:tw-border-purple-a400'
  | 'hover:tw-border-purple-a400'
  | 'focus:tw-border-purple-a400'
  | 'sm:tw-border-purple-a700'
  | 'md:tw-border-purple-a700'
  | 'lg:tw-border-purple-a700'
  | 'xl:tw-border-purple-a700'
  | 'hover:tw-border-purple-a700'
  | 'focus:tw-border-purple-a700'
  | 'sm:tw-border-deepPurple-50'
  | 'md:tw-border-deepPurple-50'
  | 'lg:tw-border-deepPurple-50'
  | 'xl:tw-border-deepPurple-50'
  | 'hover:tw-border-deepPurple-50'
  | 'focus:tw-border-deepPurple-50'
  | 'sm:tw-border-deepPurple-100'
  | 'md:tw-border-deepPurple-100'
  | 'lg:tw-border-deepPurple-100'
  | 'xl:tw-border-deepPurple-100'
  | 'hover:tw-border-deepPurple-100'
  | 'focus:tw-border-deepPurple-100'
  | 'sm:tw-border-deepPurple-200'
  | 'md:tw-border-deepPurple-200'
  | 'lg:tw-border-deepPurple-200'
  | 'xl:tw-border-deepPurple-200'
  | 'hover:tw-border-deepPurple-200'
  | 'focus:tw-border-deepPurple-200'
  | 'sm:tw-border-deepPurple-300'
  | 'md:tw-border-deepPurple-300'
  | 'lg:tw-border-deepPurple-300'
  | 'xl:tw-border-deepPurple-300'
  | 'hover:tw-border-deepPurple-300'
  | 'focus:tw-border-deepPurple-300'
  | 'sm:tw-border-deepPurple-400'
  | 'md:tw-border-deepPurple-400'
  | 'lg:tw-border-deepPurple-400'
  | 'xl:tw-border-deepPurple-400'
  | 'hover:tw-border-deepPurple-400'
  | 'focus:tw-border-deepPurple-400'
  | 'sm:tw-border-deepPurple-500'
  | 'md:tw-border-deepPurple-500'
  | 'lg:tw-border-deepPurple-500'
  | 'xl:tw-border-deepPurple-500'
  | 'hover:tw-border-deepPurple-500'
  | 'focus:tw-border-deepPurple-500'
  | 'sm:tw-border-deepPurple-600'
  | 'md:tw-border-deepPurple-600'
  | 'lg:tw-border-deepPurple-600'
  | 'xl:tw-border-deepPurple-600'
  | 'hover:tw-border-deepPurple-600'
  | 'focus:tw-border-deepPurple-600'
  | 'sm:tw-border-deepPurple-700'
  | 'md:tw-border-deepPurple-700'
  | 'lg:tw-border-deepPurple-700'
  | 'xl:tw-border-deepPurple-700'
  | 'hover:tw-border-deepPurple-700'
  | 'focus:tw-border-deepPurple-700'
  | 'sm:tw-border-deepPurple-800'
  | 'md:tw-border-deepPurple-800'
  | 'lg:tw-border-deepPurple-800'
  | 'xl:tw-border-deepPurple-800'
  | 'hover:tw-border-deepPurple-800'
  | 'focus:tw-border-deepPurple-800'
  | 'sm:tw-border-deepPurple-900'
  | 'md:tw-border-deepPurple-900'
  | 'lg:tw-border-deepPurple-900'
  | 'xl:tw-border-deepPurple-900'
  | 'hover:tw-border-deepPurple-900'
  | 'focus:tw-border-deepPurple-900'
  | 'sm:tw-border-deepPurple-a100'
  | 'md:tw-border-deepPurple-a100'
  | 'lg:tw-border-deepPurple-a100'
  | 'xl:tw-border-deepPurple-a100'
  | 'hover:tw-border-deepPurple-a100'
  | 'focus:tw-border-deepPurple-a100'
  | 'sm:tw-border-deepPurple-a200'
  | 'md:tw-border-deepPurple-a200'
  | 'lg:tw-border-deepPurple-a200'
  | 'xl:tw-border-deepPurple-a200'
  | 'hover:tw-border-deepPurple-a200'
  | 'focus:tw-border-deepPurple-a200'
  | 'sm:tw-border-deepPurple-a400'
  | 'md:tw-border-deepPurple-a400'
  | 'lg:tw-border-deepPurple-a400'
  | 'xl:tw-border-deepPurple-a400'
  | 'hover:tw-border-deepPurple-a400'
  | 'focus:tw-border-deepPurple-a400'
  | 'sm:tw-border-deepPurple-a700'
  | 'md:tw-border-deepPurple-a700'
  | 'lg:tw-border-deepPurple-a700'
  | 'xl:tw-border-deepPurple-a700'
  | 'hover:tw-border-deepPurple-a700'
  | 'focus:tw-border-deepPurple-a700'
  | 'sm:tw-border-indigo-50'
  | 'md:tw-border-indigo-50'
  | 'lg:tw-border-indigo-50'
  | 'xl:tw-border-indigo-50'
  | 'hover:tw-border-indigo-50'
  | 'focus:tw-border-indigo-50'
  | 'sm:tw-border-indigo-100'
  | 'md:tw-border-indigo-100'
  | 'lg:tw-border-indigo-100'
  | 'xl:tw-border-indigo-100'
  | 'hover:tw-border-indigo-100'
  | 'focus:tw-border-indigo-100'
  | 'sm:tw-border-indigo-200'
  | 'md:tw-border-indigo-200'
  | 'lg:tw-border-indigo-200'
  | 'xl:tw-border-indigo-200'
  | 'hover:tw-border-indigo-200'
  | 'focus:tw-border-indigo-200'
  | 'sm:tw-border-indigo-300'
  | 'md:tw-border-indigo-300'
  | 'lg:tw-border-indigo-300'
  | 'xl:tw-border-indigo-300'
  | 'hover:tw-border-indigo-300'
  | 'focus:tw-border-indigo-300'
  | 'sm:tw-border-indigo-400'
  | 'md:tw-border-indigo-400'
  | 'lg:tw-border-indigo-400'
  | 'xl:tw-border-indigo-400'
  | 'hover:tw-border-indigo-400'
  | 'focus:tw-border-indigo-400'
  | 'sm:tw-border-indigo-500'
  | 'md:tw-border-indigo-500'
  | 'lg:tw-border-indigo-500'
  | 'xl:tw-border-indigo-500'
  | 'hover:tw-border-indigo-500'
  | 'focus:tw-border-indigo-500'
  | 'sm:tw-border-indigo-600'
  | 'md:tw-border-indigo-600'
  | 'lg:tw-border-indigo-600'
  | 'xl:tw-border-indigo-600'
  | 'hover:tw-border-indigo-600'
  | 'focus:tw-border-indigo-600'
  | 'sm:tw-border-indigo-700'
  | 'md:tw-border-indigo-700'
  | 'lg:tw-border-indigo-700'
  | 'xl:tw-border-indigo-700'
  | 'hover:tw-border-indigo-700'
  | 'focus:tw-border-indigo-700'
  | 'sm:tw-border-indigo-800'
  | 'md:tw-border-indigo-800'
  | 'lg:tw-border-indigo-800'
  | 'xl:tw-border-indigo-800'
  | 'hover:tw-border-indigo-800'
  | 'focus:tw-border-indigo-800'
  | 'sm:tw-border-indigo-900'
  | 'md:tw-border-indigo-900'
  | 'lg:tw-border-indigo-900'
  | 'xl:tw-border-indigo-900'
  | 'hover:tw-border-indigo-900'
  | 'focus:tw-border-indigo-900'
  | 'sm:tw-border-indigo-a100'
  | 'md:tw-border-indigo-a100'
  | 'lg:tw-border-indigo-a100'
  | 'xl:tw-border-indigo-a100'
  | 'hover:tw-border-indigo-a100'
  | 'focus:tw-border-indigo-a100'
  | 'sm:tw-border-indigo-a200'
  | 'md:tw-border-indigo-a200'
  | 'lg:tw-border-indigo-a200'
  | 'xl:tw-border-indigo-a200'
  | 'hover:tw-border-indigo-a200'
  | 'focus:tw-border-indigo-a200'
  | 'sm:tw-border-indigo-a400'
  | 'md:tw-border-indigo-a400'
  | 'lg:tw-border-indigo-a400'
  | 'xl:tw-border-indigo-a400'
  | 'hover:tw-border-indigo-a400'
  | 'focus:tw-border-indigo-a400'
  | 'sm:tw-border-indigo-a700'
  | 'md:tw-border-indigo-a700'
  | 'lg:tw-border-indigo-a700'
  | 'xl:tw-border-indigo-a700'
  | 'hover:tw-border-indigo-a700'
  | 'focus:tw-border-indigo-a700'
  | 'sm:tw-border-blue-50'
  | 'md:tw-border-blue-50'
  | 'lg:tw-border-blue-50'
  | 'xl:tw-border-blue-50'
  | 'hover:tw-border-blue-50'
  | 'focus:tw-border-blue-50'
  | 'sm:tw-border-blue-100'
  | 'md:tw-border-blue-100'
  | 'lg:tw-border-blue-100'
  | 'xl:tw-border-blue-100'
  | 'hover:tw-border-blue-100'
  | 'focus:tw-border-blue-100'
  | 'sm:tw-border-blue-200'
  | 'md:tw-border-blue-200'
  | 'lg:tw-border-blue-200'
  | 'xl:tw-border-blue-200'
  | 'hover:tw-border-blue-200'
  | 'focus:tw-border-blue-200'
  | 'sm:tw-border-blue-300'
  | 'md:tw-border-blue-300'
  | 'lg:tw-border-blue-300'
  | 'xl:tw-border-blue-300'
  | 'hover:tw-border-blue-300'
  | 'focus:tw-border-blue-300'
  | 'sm:tw-border-blue-400'
  | 'md:tw-border-blue-400'
  | 'lg:tw-border-blue-400'
  | 'xl:tw-border-blue-400'
  | 'hover:tw-border-blue-400'
  | 'focus:tw-border-blue-400'
  | 'sm:tw-border-blue-500'
  | 'md:tw-border-blue-500'
  | 'lg:tw-border-blue-500'
  | 'xl:tw-border-blue-500'
  | 'hover:tw-border-blue-500'
  | 'focus:tw-border-blue-500'
  | 'sm:tw-border-blue-600'
  | 'md:tw-border-blue-600'
  | 'lg:tw-border-blue-600'
  | 'xl:tw-border-blue-600'
  | 'hover:tw-border-blue-600'
  | 'focus:tw-border-blue-600'
  | 'sm:tw-border-blue-700'
  | 'md:tw-border-blue-700'
  | 'lg:tw-border-blue-700'
  | 'xl:tw-border-blue-700'
  | 'hover:tw-border-blue-700'
  | 'focus:tw-border-blue-700'
  | 'sm:tw-border-blue-800'
  | 'md:tw-border-blue-800'
  | 'lg:tw-border-blue-800'
  | 'xl:tw-border-blue-800'
  | 'hover:tw-border-blue-800'
  | 'focus:tw-border-blue-800'
  | 'sm:tw-border-blue-900'
  | 'md:tw-border-blue-900'
  | 'lg:tw-border-blue-900'
  | 'xl:tw-border-blue-900'
  | 'hover:tw-border-blue-900'
  | 'focus:tw-border-blue-900'
  | 'sm:tw-border-blue-a100'
  | 'md:tw-border-blue-a100'
  | 'lg:tw-border-blue-a100'
  | 'xl:tw-border-blue-a100'
  | 'hover:tw-border-blue-a100'
  | 'focus:tw-border-blue-a100'
  | 'sm:tw-border-blue-a200'
  | 'md:tw-border-blue-a200'
  | 'lg:tw-border-blue-a200'
  | 'xl:tw-border-blue-a200'
  | 'hover:tw-border-blue-a200'
  | 'focus:tw-border-blue-a200'
  | 'sm:tw-border-blue-a400'
  | 'md:tw-border-blue-a400'
  | 'lg:tw-border-blue-a400'
  | 'xl:tw-border-blue-a400'
  | 'hover:tw-border-blue-a400'
  | 'focus:tw-border-blue-a400'
  | 'sm:tw-border-blue-a700'
  | 'md:tw-border-blue-a700'
  | 'lg:tw-border-blue-a700'
  | 'xl:tw-border-blue-a700'
  | 'hover:tw-border-blue-a700'
  | 'focus:tw-border-blue-a700'
  | 'sm:tw-border-lightBlue-50'
  | 'md:tw-border-lightBlue-50'
  | 'lg:tw-border-lightBlue-50'
  | 'xl:tw-border-lightBlue-50'
  | 'hover:tw-border-lightBlue-50'
  | 'focus:tw-border-lightBlue-50'
  | 'sm:tw-border-lightBlue-100'
  | 'md:tw-border-lightBlue-100'
  | 'lg:tw-border-lightBlue-100'
  | 'xl:tw-border-lightBlue-100'
  | 'hover:tw-border-lightBlue-100'
  | 'focus:tw-border-lightBlue-100'
  | 'sm:tw-border-lightBlue-200'
  | 'md:tw-border-lightBlue-200'
  | 'lg:tw-border-lightBlue-200'
  | 'xl:tw-border-lightBlue-200'
  | 'hover:tw-border-lightBlue-200'
  | 'focus:tw-border-lightBlue-200'
  | 'sm:tw-border-lightBlue-300'
  | 'md:tw-border-lightBlue-300'
  | 'lg:tw-border-lightBlue-300'
  | 'xl:tw-border-lightBlue-300'
  | 'hover:tw-border-lightBlue-300'
  | 'focus:tw-border-lightBlue-300'
  | 'sm:tw-border-lightBlue-400'
  | 'md:tw-border-lightBlue-400'
  | 'lg:tw-border-lightBlue-400'
  | 'xl:tw-border-lightBlue-400'
  | 'hover:tw-border-lightBlue-400'
  | 'focus:tw-border-lightBlue-400'
  | 'sm:tw-border-lightBlue-500'
  | 'md:tw-border-lightBlue-500'
  | 'lg:tw-border-lightBlue-500'
  | 'xl:tw-border-lightBlue-500'
  | 'hover:tw-border-lightBlue-500'
  | 'focus:tw-border-lightBlue-500'
  | 'sm:tw-border-lightBlue-600'
  | 'md:tw-border-lightBlue-600'
  | 'lg:tw-border-lightBlue-600'
  | 'xl:tw-border-lightBlue-600'
  | 'hover:tw-border-lightBlue-600'
  | 'focus:tw-border-lightBlue-600'
  | 'sm:tw-border-lightBlue-700'
  | 'md:tw-border-lightBlue-700'
  | 'lg:tw-border-lightBlue-700'
  | 'xl:tw-border-lightBlue-700'
  | 'hover:tw-border-lightBlue-700'
  | 'focus:tw-border-lightBlue-700'
  | 'sm:tw-border-lightBlue-800'
  | 'md:tw-border-lightBlue-800'
  | 'lg:tw-border-lightBlue-800'
  | 'xl:tw-border-lightBlue-800'
  | 'hover:tw-border-lightBlue-800'
  | 'focus:tw-border-lightBlue-800'
  | 'sm:tw-border-lightBlue-900'
  | 'md:tw-border-lightBlue-900'
  | 'lg:tw-border-lightBlue-900'
  | 'xl:tw-border-lightBlue-900'
  | 'hover:tw-border-lightBlue-900'
  | 'focus:tw-border-lightBlue-900'
  | 'sm:tw-border-lightBlue-a100'
  | 'md:tw-border-lightBlue-a100'
  | 'lg:tw-border-lightBlue-a100'
  | 'xl:tw-border-lightBlue-a100'
  | 'hover:tw-border-lightBlue-a100'
  | 'focus:tw-border-lightBlue-a100'
  | 'sm:tw-border-lightBlue-a200'
  | 'md:tw-border-lightBlue-a200'
  | 'lg:tw-border-lightBlue-a200'
  | 'xl:tw-border-lightBlue-a200'
  | 'hover:tw-border-lightBlue-a200'
  | 'focus:tw-border-lightBlue-a200'
  | 'sm:tw-border-lightBlue-a400'
  | 'md:tw-border-lightBlue-a400'
  | 'lg:tw-border-lightBlue-a400'
  | 'xl:tw-border-lightBlue-a400'
  | 'hover:tw-border-lightBlue-a400'
  | 'focus:tw-border-lightBlue-a400'
  | 'sm:tw-border-lightBlue-a700'
  | 'md:tw-border-lightBlue-a700'
  | 'lg:tw-border-lightBlue-a700'
  | 'xl:tw-border-lightBlue-a700'
  | 'hover:tw-border-lightBlue-a700'
  | 'focus:tw-border-lightBlue-a700'
  | 'sm:tw-border-cyan-50'
  | 'md:tw-border-cyan-50'
  | 'lg:tw-border-cyan-50'
  | 'xl:tw-border-cyan-50'
  | 'hover:tw-border-cyan-50'
  | 'focus:tw-border-cyan-50'
  | 'sm:tw-border-cyan-100'
  | 'md:tw-border-cyan-100'
  | 'lg:tw-border-cyan-100'
  | 'xl:tw-border-cyan-100'
  | 'hover:tw-border-cyan-100'
  | 'focus:tw-border-cyan-100'
  | 'sm:tw-border-cyan-200'
  | 'md:tw-border-cyan-200'
  | 'lg:tw-border-cyan-200'
  | 'xl:tw-border-cyan-200'
  | 'hover:tw-border-cyan-200'
  | 'focus:tw-border-cyan-200'
  | 'sm:tw-border-cyan-300'
  | 'md:tw-border-cyan-300'
  | 'lg:tw-border-cyan-300'
  | 'xl:tw-border-cyan-300'
  | 'hover:tw-border-cyan-300'
  | 'focus:tw-border-cyan-300'
  | 'sm:tw-border-cyan-400'
  | 'md:tw-border-cyan-400'
  | 'lg:tw-border-cyan-400'
  | 'xl:tw-border-cyan-400'
  | 'hover:tw-border-cyan-400'
  | 'focus:tw-border-cyan-400'
  | 'sm:tw-border-cyan-500'
  | 'md:tw-border-cyan-500'
  | 'lg:tw-border-cyan-500'
  | 'xl:tw-border-cyan-500'
  | 'hover:tw-border-cyan-500'
  | 'focus:tw-border-cyan-500'
  | 'sm:tw-border-cyan-600'
  | 'md:tw-border-cyan-600'
  | 'lg:tw-border-cyan-600'
  | 'xl:tw-border-cyan-600'
  | 'hover:tw-border-cyan-600'
  | 'focus:tw-border-cyan-600'
  | 'sm:tw-border-cyan-700'
  | 'md:tw-border-cyan-700'
  | 'lg:tw-border-cyan-700'
  | 'xl:tw-border-cyan-700'
  | 'hover:tw-border-cyan-700'
  | 'focus:tw-border-cyan-700'
  | 'sm:tw-border-cyan-800'
  | 'md:tw-border-cyan-800'
  | 'lg:tw-border-cyan-800'
  | 'xl:tw-border-cyan-800'
  | 'hover:tw-border-cyan-800'
  | 'focus:tw-border-cyan-800'
  | 'sm:tw-border-cyan-900'
  | 'md:tw-border-cyan-900'
  | 'lg:tw-border-cyan-900'
  | 'xl:tw-border-cyan-900'
  | 'hover:tw-border-cyan-900'
  | 'focus:tw-border-cyan-900'
  | 'sm:tw-border-cyan-a100'
  | 'md:tw-border-cyan-a100'
  | 'lg:tw-border-cyan-a100'
  | 'xl:tw-border-cyan-a100'
  | 'hover:tw-border-cyan-a100'
  | 'focus:tw-border-cyan-a100'
  | 'sm:tw-border-cyan-a200'
  | 'md:tw-border-cyan-a200'
  | 'lg:tw-border-cyan-a200'
  | 'xl:tw-border-cyan-a200'
  | 'hover:tw-border-cyan-a200'
  | 'focus:tw-border-cyan-a200'
  | 'sm:tw-border-cyan-a400'
  | 'md:tw-border-cyan-a400'
  | 'lg:tw-border-cyan-a400'
  | 'xl:tw-border-cyan-a400'
  | 'hover:tw-border-cyan-a400'
  | 'focus:tw-border-cyan-a400'
  | 'sm:tw-border-cyan-a700'
  | 'md:tw-border-cyan-a700'
  | 'lg:tw-border-cyan-a700'
  | 'xl:tw-border-cyan-a700'
  | 'hover:tw-border-cyan-a700'
  | 'focus:tw-border-cyan-a700'
  | 'sm:tw-border-teal-50'
  | 'md:tw-border-teal-50'
  | 'lg:tw-border-teal-50'
  | 'xl:tw-border-teal-50'
  | 'hover:tw-border-teal-50'
  | 'focus:tw-border-teal-50'
  | 'sm:tw-border-teal-100'
  | 'md:tw-border-teal-100'
  | 'lg:tw-border-teal-100'
  | 'xl:tw-border-teal-100'
  | 'hover:tw-border-teal-100'
  | 'focus:tw-border-teal-100'
  | 'sm:tw-border-teal-200'
  | 'md:tw-border-teal-200'
  | 'lg:tw-border-teal-200'
  | 'xl:tw-border-teal-200'
  | 'hover:tw-border-teal-200'
  | 'focus:tw-border-teal-200'
  | 'sm:tw-border-teal-300'
  | 'md:tw-border-teal-300'
  | 'lg:tw-border-teal-300'
  | 'xl:tw-border-teal-300'
  | 'hover:tw-border-teal-300'
  | 'focus:tw-border-teal-300'
  | 'sm:tw-border-teal-400'
  | 'md:tw-border-teal-400'
  | 'lg:tw-border-teal-400'
  | 'xl:tw-border-teal-400'
  | 'hover:tw-border-teal-400'
  | 'focus:tw-border-teal-400'
  | 'sm:tw-border-teal-500'
  | 'md:tw-border-teal-500'
  | 'lg:tw-border-teal-500'
  | 'xl:tw-border-teal-500'
  | 'hover:tw-border-teal-500'
  | 'focus:tw-border-teal-500'
  | 'sm:tw-border-teal-600'
  | 'md:tw-border-teal-600'
  | 'lg:tw-border-teal-600'
  | 'xl:tw-border-teal-600'
  | 'hover:tw-border-teal-600'
  | 'focus:tw-border-teal-600'
  | 'sm:tw-border-teal-700'
  | 'md:tw-border-teal-700'
  | 'lg:tw-border-teal-700'
  | 'xl:tw-border-teal-700'
  | 'hover:tw-border-teal-700'
  | 'focus:tw-border-teal-700'
  | 'sm:tw-border-teal-800'
  | 'md:tw-border-teal-800'
  | 'lg:tw-border-teal-800'
  | 'xl:tw-border-teal-800'
  | 'hover:tw-border-teal-800'
  | 'focus:tw-border-teal-800'
  | 'sm:tw-border-teal-900'
  | 'md:tw-border-teal-900'
  | 'lg:tw-border-teal-900'
  | 'xl:tw-border-teal-900'
  | 'hover:tw-border-teal-900'
  | 'focus:tw-border-teal-900'
  | 'sm:tw-border-teal-a100'
  | 'md:tw-border-teal-a100'
  | 'lg:tw-border-teal-a100'
  | 'xl:tw-border-teal-a100'
  | 'hover:tw-border-teal-a100'
  | 'focus:tw-border-teal-a100'
  | 'sm:tw-border-teal-a200'
  | 'md:tw-border-teal-a200'
  | 'lg:tw-border-teal-a200'
  | 'xl:tw-border-teal-a200'
  | 'hover:tw-border-teal-a200'
  | 'focus:tw-border-teal-a200'
  | 'sm:tw-border-teal-a400'
  | 'md:tw-border-teal-a400'
  | 'lg:tw-border-teal-a400'
  | 'xl:tw-border-teal-a400'
  | 'hover:tw-border-teal-a400'
  | 'focus:tw-border-teal-a400'
  | 'sm:tw-border-teal-a700'
  | 'md:tw-border-teal-a700'
  | 'lg:tw-border-teal-a700'
  | 'xl:tw-border-teal-a700'
  | 'hover:tw-border-teal-a700'
  | 'focus:tw-border-teal-a700'
  | 'sm:tw-border-green-50'
  | 'md:tw-border-green-50'
  | 'lg:tw-border-green-50'
  | 'xl:tw-border-green-50'
  | 'hover:tw-border-green-50'
  | 'focus:tw-border-green-50'
  | 'sm:tw-border-green-100'
  | 'md:tw-border-green-100'
  | 'lg:tw-border-green-100'
  | 'xl:tw-border-green-100'
  | 'hover:tw-border-green-100'
  | 'focus:tw-border-green-100'
  | 'sm:tw-border-green-200'
  | 'md:tw-border-green-200'
  | 'lg:tw-border-green-200'
  | 'xl:tw-border-green-200'
  | 'hover:tw-border-green-200'
  | 'focus:tw-border-green-200'
  | 'sm:tw-border-green-300'
  | 'md:tw-border-green-300'
  | 'lg:tw-border-green-300'
  | 'xl:tw-border-green-300'
  | 'hover:tw-border-green-300'
  | 'focus:tw-border-green-300'
  | 'sm:tw-border-green-400'
  | 'md:tw-border-green-400'
  | 'lg:tw-border-green-400'
  | 'xl:tw-border-green-400'
  | 'hover:tw-border-green-400'
  | 'focus:tw-border-green-400'
  | 'sm:tw-border-green-500'
  | 'md:tw-border-green-500'
  | 'lg:tw-border-green-500'
  | 'xl:tw-border-green-500'
  | 'hover:tw-border-green-500'
  | 'focus:tw-border-green-500'
  | 'sm:tw-border-green-600'
  | 'md:tw-border-green-600'
  | 'lg:tw-border-green-600'
  | 'xl:tw-border-green-600'
  | 'hover:tw-border-green-600'
  | 'focus:tw-border-green-600'
  | 'sm:tw-border-green-700'
  | 'md:tw-border-green-700'
  | 'lg:tw-border-green-700'
  | 'xl:tw-border-green-700'
  | 'hover:tw-border-green-700'
  | 'focus:tw-border-green-700'
  | 'sm:tw-border-green-800'
  | 'md:tw-border-green-800'
  | 'lg:tw-border-green-800'
  | 'xl:tw-border-green-800'
  | 'hover:tw-border-green-800'
  | 'focus:tw-border-green-800'
  | 'sm:tw-border-green-900'
  | 'md:tw-border-green-900'
  | 'lg:tw-border-green-900'
  | 'xl:tw-border-green-900'
  | 'hover:tw-border-green-900'
  | 'focus:tw-border-green-900'
  | 'sm:tw-border-green-a100'
  | 'md:tw-border-green-a100'
  | 'lg:tw-border-green-a100'
  | 'xl:tw-border-green-a100'
  | 'hover:tw-border-green-a100'
  | 'focus:tw-border-green-a100'
  | 'sm:tw-border-green-a200'
  | 'md:tw-border-green-a200'
  | 'lg:tw-border-green-a200'
  | 'xl:tw-border-green-a200'
  | 'hover:tw-border-green-a200'
  | 'focus:tw-border-green-a200'
  | 'sm:tw-border-green-a400'
  | 'md:tw-border-green-a400'
  | 'lg:tw-border-green-a400'
  | 'xl:tw-border-green-a400'
  | 'hover:tw-border-green-a400'
  | 'focus:tw-border-green-a400'
  | 'sm:tw-border-green-a700'
  | 'md:tw-border-green-a700'
  | 'lg:tw-border-green-a700'
  | 'xl:tw-border-green-a700'
  | 'hover:tw-border-green-a700'
  | 'focus:tw-border-green-a700'
  | 'sm:tw-border-lightGreen-50'
  | 'md:tw-border-lightGreen-50'
  | 'lg:tw-border-lightGreen-50'
  | 'xl:tw-border-lightGreen-50'
  | 'hover:tw-border-lightGreen-50'
  | 'focus:tw-border-lightGreen-50'
  | 'sm:tw-border-lightGreen-100'
  | 'md:tw-border-lightGreen-100'
  | 'lg:tw-border-lightGreen-100'
  | 'xl:tw-border-lightGreen-100'
  | 'hover:tw-border-lightGreen-100'
  | 'focus:tw-border-lightGreen-100'
  | 'sm:tw-border-lightGreen-200'
  | 'md:tw-border-lightGreen-200'
  | 'lg:tw-border-lightGreen-200'
  | 'xl:tw-border-lightGreen-200'
  | 'hover:tw-border-lightGreen-200'
  | 'focus:tw-border-lightGreen-200'
  | 'sm:tw-border-lightGreen-300'
  | 'md:tw-border-lightGreen-300'
  | 'lg:tw-border-lightGreen-300'
  | 'xl:tw-border-lightGreen-300'
  | 'hover:tw-border-lightGreen-300'
  | 'focus:tw-border-lightGreen-300'
  | 'sm:tw-border-lightGreen-400'
  | 'md:tw-border-lightGreen-400'
  | 'lg:tw-border-lightGreen-400'
  | 'xl:tw-border-lightGreen-400'
  | 'hover:tw-border-lightGreen-400'
  | 'focus:tw-border-lightGreen-400'
  | 'sm:tw-border-lightGreen-500'
  | 'md:tw-border-lightGreen-500'
  | 'lg:tw-border-lightGreen-500'
  | 'xl:tw-border-lightGreen-500'
  | 'hover:tw-border-lightGreen-500'
  | 'focus:tw-border-lightGreen-500'
  | 'sm:tw-border-lightGreen-600'
  | 'md:tw-border-lightGreen-600'
  | 'lg:tw-border-lightGreen-600'
  | 'xl:tw-border-lightGreen-600'
  | 'hover:tw-border-lightGreen-600'
  | 'focus:tw-border-lightGreen-600'
  | 'sm:tw-border-lightGreen-700'
  | 'md:tw-border-lightGreen-700'
  | 'lg:tw-border-lightGreen-700'
  | 'xl:tw-border-lightGreen-700'
  | 'hover:tw-border-lightGreen-700'
  | 'focus:tw-border-lightGreen-700'
  | 'sm:tw-border-lightGreen-800'
  | 'md:tw-border-lightGreen-800'
  | 'lg:tw-border-lightGreen-800'
  | 'xl:tw-border-lightGreen-800'
  | 'hover:tw-border-lightGreen-800'
  | 'focus:tw-border-lightGreen-800'
  | 'sm:tw-border-lightGreen-900'
  | 'md:tw-border-lightGreen-900'
  | 'lg:tw-border-lightGreen-900'
  | 'xl:tw-border-lightGreen-900'
  | 'hover:tw-border-lightGreen-900'
  | 'focus:tw-border-lightGreen-900'
  | 'sm:tw-border-lightGreen-a100'
  | 'md:tw-border-lightGreen-a100'
  | 'lg:tw-border-lightGreen-a100'
  | 'xl:tw-border-lightGreen-a100'
  | 'hover:tw-border-lightGreen-a100'
  | 'focus:tw-border-lightGreen-a100'
  | 'sm:tw-border-lightGreen-a200'
  | 'md:tw-border-lightGreen-a200'
  | 'lg:tw-border-lightGreen-a200'
  | 'xl:tw-border-lightGreen-a200'
  | 'hover:tw-border-lightGreen-a200'
  | 'focus:tw-border-lightGreen-a200'
  | 'sm:tw-border-lightGreen-a400'
  | 'md:tw-border-lightGreen-a400'
  | 'lg:tw-border-lightGreen-a400'
  | 'xl:tw-border-lightGreen-a400'
  | 'hover:tw-border-lightGreen-a400'
  | 'focus:tw-border-lightGreen-a400'
  | 'sm:tw-border-lightGreen-a700'
  | 'md:tw-border-lightGreen-a700'
  | 'lg:tw-border-lightGreen-a700'
  | 'xl:tw-border-lightGreen-a700'
  | 'hover:tw-border-lightGreen-a700'
  | 'focus:tw-border-lightGreen-a700'
  | 'sm:tw-border-lime-50'
  | 'md:tw-border-lime-50'
  | 'lg:tw-border-lime-50'
  | 'xl:tw-border-lime-50'
  | 'hover:tw-border-lime-50'
  | 'focus:tw-border-lime-50'
  | 'sm:tw-border-lime-100'
  | 'md:tw-border-lime-100'
  | 'lg:tw-border-lime-100'
  | 'xl:tw-border-lime-100'
  | 'hover:tw-border-lime-100'
  | 'focus:tw-border-lime-100'
  | 'sm:tw-border-lime-200'
  | 'md:tw-border-lime-200'
  | 'lg:tw-border-lime-200'
  | 'xl:tw-border-lime-200'
  | 'hover:tw-border-lime-200'
  | 'focus:tw-border-lime-200'
  | 'sm:tw-border-lime-300'
  | 'md:tw-border-lime-300'
  | 'lg:tw-border-lime-300'
  | 'xl:tw-border-lime-300'
  | 'hover:tw-border-lime-300'
  | 'focus:tw-border-lime-300'
  | 'sm:tw-border-lime-400'
  | 'md:tw-border-lime-400'
  | 'lg:tw-border-lime-400'
  | 'xl:tw-border-lime-400'
  | 'hover:tw-border-lime-400'
  | 'focus:tw-border-lime-400'
  | 'sm:tw-border-lime-500'
  | 'md:tw-border-lime-500'
  | 'lg:tw-border-lime-500'
  | 'xl:tw-border-lime-500'
  | 'hover:tw-border-lime-500'
  | 'focus:tw-border-lime-500'
  | 'sm:tw-border-lime-600'
  | 'md:tw-border-lime-600'
  | 'lg:tw-border-lime-600'
  | 'xl:tw-border-lime-600'
  | 'hover:tw-border-lime-600'
  | 'focus:tw-border-lime-600'
  | 'sm:tw-border-lime-700'
  | 'md:tw-border-lime-700'
  | 'lg:tw-border-lime-700'
  | 'xl:tw-border-lime-700'
  | 'hover:tw-border-lime-700'
  | 'focus:tw-border-lime-700'
  | 'sm:tw-border-lime-800'
  | 'md:tw-border-lime-800'
  | 'lg:tw-border-lime-800'
  | 'xl:tw-border-lime-800'
  | 'hover:tw-border-lime-800'
  | 'focus:tw-border-lime-800'
  | 'sm:tw-border-lime-900'
  | 'md:tw-border-lime-900'
  | 'lg:tw-border-lime-900'
  | 'xl:tw-border-lime-900'
  | 'hover:tw-border-lime-900'
  | 'focus:tw-border-lime-900'
  | 'sm:tw-border-lime-a100'
  | 'md:tw-border-lime-a100'
  | 'lg:tw-border-lime-a100'
  | 'xl:tw-border-lime-a100'
  | 'hover:tw-border-lime-a100'
  | 'focus:tw-border-lime-a100'
  | 'sm:tw-border-lime-a200'
  | 'md:tw-border-lime-a200'
  | 'lg:tw-border-lime-a200'
  | 'xl:tw-border-lime-a200'
  | 'hover:tw-border-lime-a200'
  | 'focus:tw-border-lime-a200'
  | 'sm:tw-border-lime-a400'
  | 'md:tw-border-lime-a400'
  | 'lg:tw-border-lime-a400'
  | 'xl:tw-border-lime-a400'
  | 'hover:tw-border-lime-a400'
  | 'focus:tw-border-lime-a400'
  | 'sm:tw-border-lime-a700'
  | 'md:tw-border-lime-a700'
  | 'lg:tw-border-lime-a700'
  | 'xl:tw-border-lime-a700'
  | 'hover:tw-border-lime-a700'
  | 'focus:tw-border-lime-a700'
  | 'sm:tw-border-yellow-50'
  | 'md:tw-border-yellow-50'
  | 'lg:tw-border-yellow-50'
  | 'xl:tw-border-yellow-50'
  | 'hover:tw-border-yellow-50'
  | 'focus:tw-border-yellow-50'
  | 'sm:tw-border-yellow-100'
  | 'md:tw-border-yellow-100'
  | 'lg:tw-border-yellow-100'
  | 'xl:tw-border-yellow-100'
  | 'hover:tw-border-yellow-100'
  | 'focus:tw-border-yellow-100'
  | 'sm:tw-border-yellow-200'
  | 'md:tw-border-yellow-200'
  | 'lg:tw-border-yellow-200'
  | 'xl:tw-border-yellow-200'
  | 'hover:tw-border-yellow-200'
  | 'focus:tw-border-yellow-200'
  | 'sm:tw-border-yellow-300'
  | 'md:tw-border-yellow-300'
  | 'lg:tw-border-yellow-300'
  | 'xl:tw-border-yellow-300'
  | 'hover:tw-border-yellow-300'
  | 'focus:tw-border-yellow-300'
  | 'sm:tw-border-yellow-400'
  | 'md:tw-border-yellow-400'
  | 'lg:tw-border-yellow-400'
  | 'xl:tw-border-yellow-400'
  | 'hover:tw-border-yellow-400'
  | 'focus:tw-border-yellow-400'
  | 'sm:tw-border-yellow-500'
  | 'md:tw-border-yellow-500'
  | 'lg:tw-border-yellow-500'
  | 'xl:tw-border-yellow-500'
  | 'hover:tw-border-yellow-500'
  | 'focus:tw-border-yellow-500'
  | 'sm:tw-border-yellow-600'
  | 'md:tw-border-yellow-600'
  | 'lg:tw-border-yellow-600'
  | 'xl:tw-border-yellow-600'
  | 'hover:tw-border-yellow-600'
  | 'focus:tw-border-yellow-600'
  | 'sm:tw-border-yellow-700'
  | 'md:tw-border-yellow-700'
  | 'lg:tw-border-yellow-700'
  | 'xl:tw-border-yellow-700'
  | 'hover:tw-border-yellow-700'
  | 'focus:tw-border-yellow-700'
  | 'sm:tw-border-yellow-800'
  | 'md:tw-border-yellow-800'
  | 'lg:tw-border-yellow-800'
  | 'xl:tw-border-yellow-800'
  | 'hover:tw-border-yellow-800'
  | 'focus:tw-border-yellow-800'
  | 'sm:tw-border-yellow-900'
  | 'md:tw-border-yellow-900'
  | 'lg:tw-border-yellow-900'
  | 'xl:tw-border-yellow-900'
  | 'hover:tw-border-yellow-900'
  | 'focus:tw-border-yellow-900'
  | 'sm:tw-border-yellow-a100'
  | 'md:tw-border-yellow-a100'
  | 'lg:tw-border-yellow-a100'
  | 'xl:tw-border-yellow-a100'
  | 'hover:tw-border-yellow-a100'
  | 'focus:tw-border-yellow-a100'
  | 'sm:tw-border-yellow-a200'
  | 'md:tw-border-yellow-a200'
  | 'lg:tw-border-yellow-a200'
  | 'xl:tw-border-yellow-a200'
  | 'hover:tw-border-yellow-a200'
  | 'focus:tw-border-yellow-a200'
  | 'sm:tw-border-yellow-a400'
  | 'md:tw-border-yellow-a400'
  | 'lg:tw-border-yellow-a400'
  | 'xl:tw-border-yellow-a400'
  | 'hover:tw-border-yellow-a400'
  | 'focus:tw-border-yellow-a400'
  | 'sm:tw-border-yellow-a700'
  | 'md:tw-border-yellow-a700'
  | 'lg:tw-border-yellow-a700'
  | 'xl:tw-border-yellow-a700'
  | 'hover:tw-border-yellow-a700'
  | 'focus:tw-border-yellow-a700'
  | 'sm:tw-border-amber-50'
  | 'md:tw-border-amber-50'
  | 'lg:tw-border-amber-50'
  | 'xl:tw-border-amber-50'
  | 'hover:tw-border-amber-50'
  | 'focus:tw-border-amber-50'
  | 'sm:tw-border-amber-100'
  | 'md:tw-border-amber-100'
  | 'lg:tw-border-amber-100'
  | 'xl:tw-border-amber-100'
  | 'hover:tw-border-amber-100'
  | 'focus:tw-border-amber-100'
  | 'sm:tw-border-amber-200'
  | 'md:tw-border-amber-200'
  | 'lg:tw-border-amber-200'
  | 'xl:tw-border-amber-200'
  | 'hover:tw-border-amber-200'
  | 'focus:tw-border-amber-200'
  | 'sm:tw-border-amber-300'
  | 'md:tw-border-amber-300'
  | 'lg:tw-border-amber-300'
  | 'xl:tw-border-amber-300'
  | 'hover:tw-border-amber-300'
  | 'focus:tw-border-amber-300'
  | 'sm:tw-border-amber-400'
  | 'md:tw-border-amber-400'
  | 'lg:tw-border-amber-400'
  | 'xl:tw-border-amber-400'
  | 'hover:tw-border-amber-400'
  | 'focus:tw-border-amber-400'
  | 'sm:tw-border-amber-500'
  | 'md:tw-border-amber-500'
  | 'lg:tw-border-amber-500'
  | 'xl:tw-border-amber-500'
  | 'hover:tw-border-amber-500'
  | 'focus:tw-border-amber-500'
  | 'sm:tw-border-amber-600'
  | 'md:tw-border-amber-600'
  | 'lg:tw-border-amber-600'
  | 'xl:tw-border-amber-600'
  | 'hover:tw-border-amber-600'
  | 'focus:tw-border-amber-600'
  | 'sm:tw-border-amber-700'
  | 'md:tw-border-amber-700'
  | 'lg:tw-border-amber-700'
  | 'xl:tw-border-amber-700'
  | 'hover:tw-border-amber-700'
  | 'focus:tw-border-amber-700'
  | 'sm:tw-border-amber-800'
  | 'md:tw-border-amber-800'
  | 'lg:tw-border-amber-800'
  | 'xl:tw-border-amber-800'
  | 'hover:tw-border-amber-800'
  | 'focus:tw-border-amber-800'
  | 'sm:tw-border-amber-900'
  | 'md:tw-border-amber-900'
  | 'lg:tw-border-amber-900'
  | 'xl:tw-border-amber-900'
  | 'hover:tw-border-amber-900'
  | 'focus:tw-border-amber-900'
  | 'sm:tw-border-amber-a100'
  | 'md:tw-border-amber-a100'
  | 'lg:tw-border-amber-a100'
  | 'xl:tw-border-amber-a100'
  | 'hover:tw-border-amber-a100'
  | 'focus:tw-border-amber-a100'
  | 'sm:tw-border-amber-a200'
  | 'md:tw-border-amber-a200'
  | 'lg:tw-border-amber-a200'
  | 'xl:tw-border-amber-a200'
  | 'hover:tw-border-amber-a200'
  | 'focus:tw-border-amber-a200'
  | 'sm:tw-border-amber-a400'
  | 'md:tw-border-amber-a400'
  | 'lg:tw-border-amber-a400'
  | 'xl:tw-border-amber-a400'
  | 'hover:tw-border-amber-a400'
  | 'focus:tw-border-amber-a400'
  | 'sm:tw-border-amber-a700'
  | 'md:tw-border-amber-a700'
  | 'lg:tw-border-amber-a700'
  | 'xl:tw-border-amber-a700'
  | 'hover:tw-border-amber-a700'
  | 'focus:tw-border-amber-a700'
  | 'sm:tw-border-orange-50'
  | 'md:tw-border-orange-50'
  | 'lg:tw-border-orange-50'
  | 'xl:tw-border-orange-50'
  | 'hover:tw-border-orange-50'
  | 'focus:tw-border-orange-50'
  | 'sm:tw-border-orange-100'
  | 'md:tw-border-orange-100'
  | 'lg:tw-border-orange-100'
  | 'xl:tw-border-orange-100'
  | 'hover:tw-border-orange-100'
  | 'focus:tw-border-orange-100'
  | 'sm:tw-border-orange-200'
  | 'md:tw-border-orange-200'
  | 'lg:tw-border-orange-200'
  | 'xl:tw-border-orange-200'
  | 'hover:tw-border-orange-200'
  | 'focus:tw-border-orange-200'
  | 'sm:tw-border-orange-300'
  | 'md:tw-border-orange-300'
  | 'lg:tw-border-orange-300'
  | 'xl:tw-border-orange-300'
  | 'hover:tw-border-orange-300'
  | 'focus:tw-border-orange-300'
  | 'sm:tw-border-orange-400'
  | 'md:tw-border-orange-400'
  | 'lg:tw-border-orange-400'
  | 'xl:tw-border-orange-400'
  | 'hover:tw-border-orange-400'
  | 'focus:tw-border-orange-400'
  | 'sm:tw-border-orange-500'
  | 'md:tw-border-orange-500'
  | 'lg:tw-border-orange-500'
  | 'xl:tw-border-orange-500'
  | 'hover:tw-border-orange-500'
  | 'focus:tw-border-orange-500'
  | 'sm:tw-border-orange-600'
  | 'md:tw-border-orange-600'
  | 'lg:tw-border-orange-600'
  | 'xl:tw-border-orange-600'
  | 'hover:tw-border-orange-600'
  | 'focus:tw-border-orange-600'
  | 'sm:tw-border-orange-700'
  | 'md:tw-border-orange-700'
  | 'lg:tw-border-orange-700'
  | 'xl:tw-border-orange-700'
  | 'hover:tw-border-orange-700'
  | 'focus:tw-border-orange-700'
  | 'sm:tw-border-orange-800'
  | 'md:tw-border-orange-800'
  | 'lg:tw-border-orange-800'
  | 'xl:tw-border-orange-800'
  | 'hover:tw-border-orange-800'
  | 'focus:tw-border-orange-800'
  | 'sm:tw-border-orange-900'
  | 'md:tw-border-orange-900'
  | 'lg:tw-border-orange-900'
  | 'xl:tw-border-orange-900'
  | 'hover:tw-border-orange-900'
  | 'focus:tw-border-orange-900'
  | 'sm:tw-border-orange-a100'
  | 'md:tw-border-orange-a100'
  | 'lg:tw-border-orange-a100'
  | 'xl:tw-border-orange-a100'
  | 'hover:tw-border-orange-a100'
  | 'focus:tw-border-orange-a100'
  | 'sm:tw-border-orange-a200'
  | 'md:tw-border-orange-a200'
  | 'lg:tw-border-orange-a200'
  | 'xl:tw-border-orange-a200'
  | 'hover:tw-border-orange-a200'
  | 'focus:tw-border-orange-a200'
  | 'sm:tw-border-orange-a400'
  | 'md:tw-border-orange-a400'
  | 'lg:tw-border-orange-a400'
  | 'xl:tw-border-orange-a400'
  | 'hover:tw-border-orange-a400'
  | 'focus:tw-border-orange-a400'
  | 'sm:tw-border-orange-a700'
  | 'md:tw-border-orange-a700'
  | 'lg:tw-border-orange-a700'
  | 'xl:tw-border-orange-a700'
  | 'hover:tw-border-orange-a700'
  | 'focus:tw-border-orange-a700'
  | 'sm:tw-border-deepOrange-50'
  | 'md:tw-border-deepOrange-50'
  | 'lg:tw-border-deepOrange-50'
  | 'xl:tw-border-deepOrange-50'
  | 'hover:tw-border-deepOrange-50'
  | 'focus:tw-border-deepOrange-50'
  | 'sm:tw-border-deepOrange-100'
  | 'md:tw-border-deepOrange-100'
  | 'lg:tw-border-deepOrange-100'
  | 'xl:tw-border-deepOrange-100'
  | 'hover:tw-border-deepOrange-100'
  | 'focus:tw-border-deepOrange-100'
  | 'sm:tw-border-deepOrange-200'
  | 'md:tw-border-deepOrange-200'
  | 'lg:tw-border-deepOrange-200'
  | 'xl:tw-border-deepOrange-200'
  | 'hover:tw-border-deepOrange-200'
  | 'focus:tw-border-deepOrange-200'
  | 'sm:tw-border-deepOrange-300'
  | 'md:tw-border-deepOrange-300'
  | 'lg:tw-border-deepOrange-300'
  | 'xl:tw-border-deepOrange-300'
  | 'hover:tw-border-deepOrange-300'
  | 'focus:tw-border-deepOrange-300'
  | 'sm:tw-border-deepOrange-400'
  | 'md:tw-border-deepOrange-400'
  | 'lg:tw-border-deepOrange-400'
  | 'xl:tw-border-deepOrange-400'
  | 'hover:tw-border-deepOrange-400'
  | 'focus:tw-border-deepOrange-400'
  | 'sm:tw-border-deepOrange-500'
  | 'md:tw-border-deepOrange-500'
  | 'lg:tw-border-deepOrange-500'
  | 'xl:tw-border-deepOrange-500'
  | 'hover:tw-border-deepOrange-500'
  | 'focus:tw-border-deepOrange-500'
  | 'sm:tw-border-deepOrange-600'
  | 'md:tw-border-deepOrange-600'
  | 'lg:tw-border-deepOrange-600'
  | 'xl:tw-border-deepOrange-600'
  | 'hover:tw-border-deepOrange-600'
  | 'focus:tw-border-deepOrange-600'
  | 'sm:tw-border-deepOrange-700'
  | 'md:tw-border-deepOrange-700'
  | 'lg:tw-border-deepOrange-700'
  | 'xl:tw-border-deepOrange-700'
  | 'hover:tw-border-deepOrange-700'
  | 'focus:tw-border-deepOrange-700'
  | 'sm:tw-border-deepOrange-800'
  | 'md:tw-border-deepOrange-800'
  | 'lg:tw-border-deepOrange-800'
  | 'xl:tw-border-deepOrange-800'
  | 'hover:tw-border-deepOrange-800'
  | 'focus:tw-border-deepOrange-800'
  | 'sm:tw-border-deepOrange-900'
  | 'md:tw-border-deepOrange-900'
  | 'lg:tw-border-deepOrange-900'
  | 'xl:tw-border-deepOrange-900'
  | 'hover:tw-border-deepOrange-900'
  | 'focus:tw-border-deepOrange-900'
  | 'sm:tw-border-deepOrange-a100'
  | 'md:tw-border-deepOrange-a100'
  | 'lg:tw-border-deepOrange-a100'
  | 'xl:tw-border-deepOrange-a100'
  | 'hover:tw-border-deepOrange-a100'
  | 'focus:tw-border-deepOrange-a100'
  | 'sm:tw-border-deepOrange-a200'
  | 'md:tw-border-deepOrange-a200'
  | 'lg:tw-border-deepOrange-a200'
  | 'xl:tw-border-deepOrange-a200'
  | 'hover:tw-border-deepOrange-a200'
  | 'focus:tw-border-deepOrange-a200'
  | 'sm:tw-border-deepOrange-a400'
  | 'md:tw-border-deepOrange-a400'
  | 'lg:tw-border-deepOrange-a400'
  | 'xl:tw-border-deepOrange-a400'
  | 'hover:tw-border-deepOrange-a400'
  | 'focus:tw-border-deepOrange-a400'
  | 'sm:tw-border-deepOrange-a700'
  | 'md:tw-border-deepOrange-a700'
  | 'lg:tw-border-deepOrange-a700'
  | 'xl:tw-border-deepOrange-a700'
  | 'hover:tw-border-deepOrange-a700'
  | 'focus:tw-border-deepOrange-a700'
  | 'sm:tw-border-brown-50'
  | 'md:tw-border-brown-50'
  | 'lg:tw-border-brown-50'
  | 'xl:tw-border-brown-50'
  | 'hover:tw-border-brown-50'
  | 'focus:tw-border-brown-50'
  | 'sm:tw-border-brown-100'
  | 'md:tw-border-brown-100'
  | 'lg:tw-border-brown-100'
  | 'xl:tw-border-brown-100'
  | 'hover:tw-border-brown-100'
  | 'focus:tw-border-brown-100'
  | 'sm:tw-border-brown-200'
  | 'md:tw-border-brown-200'
  | 'lg:tw-border-brown-200'
  | 'xl:tw-border-brown-200'
  | 'hover:tw-border-brown-200'
  | 'focus:tw-border-brown-200'
  | 'sm:tw-border-brown-300'
  | 'md:tw-border-brown-300'
  | 'lg:tw-border-brown-300'
  | 'xl:tw-border-brown-300'
  | 'hover:tw-border-brown-300'
  | 'focus:tw-border-brown-300'
  | 'sm:tw-border-brown-400'
  | 'md:tw-border-brown-400'
  | 'lg:tw-border-brown-400'
  | 'xl:tw-border-brown-400'
  | 'hover:tw-border-brown-400'
  | 'focus:tw-border-brown-400'
  | 'sm:tw-border-brown-500'
  | 'md:tw-border-brown-500'
  | 'lg:tw-border-brown-500'
  | 'xl:tw-border-brown-500'
  | 'hover:tw-border-brown-500'
  | 'focus:tw-border-brown-500'
  | 'sm:tw-border-brown-600'
  | 'md:tw-border-brown-600'
  | 'lg:tw-border-brown-600'
  | 'xl:tw-border-brown-600'
  | 'hover:tw-border-brown-600'
  | 'focus:tw-border-brown-600'
  | 'sm:tw-border-brown-700'
  | 'md:tw-border-brown-700'
  | 'lg:tw-border-brown-700'
  | 'xl:tw-border-brown-700'
  | 'hover:tw-border-brown-700'
  | 'focus:tw-border-brown-700'
  | 'sm:tw-border-brown-800'
  | 'md:tw-border-brown-800'
  | 'lg:tw-border-brown-800'
  | 'xl:tw-border-brown-800'
  | 'hover:tw-border-brown-800'
  | 'focus:tw-border-brown-800'
  | 'sm:tw-border-brown-900'
  | 'md:tw-border-brown-900'
  | 'lg:tw-border-brown-900'
  | 'xl:tw-border-brown-900'
  | 'hover:tw-border-brown-900'
  | 'focus:tw-border-brown-900'
  | 'sm:tw-border-gray-50'
  | 'md:tw-border-gray-50'
  | 'lg:tw-border-gray-50'
  | 'xl:tw-border-gray-50'
  | 'hover:tw-border-gray-50'
  | 'focus:tw-border-gray-50'
  | 'sm:tw-border-gray-100'
  | 'md:tw-border-gray-100'
  | 'lg:tw-border-gray-100'
  | 'xl:tw-border-gray-100'
  | 'hover:tw-border-gray-100'
  | 'focus:tw-border-gray-100'
  | 'sm:tw-border-gray-200'
  | 'md:tw-border-gray-200'
  | 'lg:tw-border-gray-200'
  | 'xl:tw-border-gray-200'
  | 'hover:tw-border-gray-200'
  | 'focus:tw-border-gray-200'
  | 'sm:tw-border-gray-300'
  | 'md:tw-border-gray-300'
  | 'lg:tw-border-gray-300'
  | 'xl:tw-border-gray-300'
  | 'hover:tw-border-gray-300'
  | 'focus:tw-border-gray-300'
  | 'sm:tw-border-gray-400'
  | 'md:tw-border-gray-400'
  | 'lg:tw-border-gray-400'
  | 'xl:tw-border-gray-400'
  | 'hover:tw-border-gray-400'
  | 'focus:tw-border-gray-400'
  | 'sm:tw-border-gray-500'
  | 'md:tw-border-gray-500'
  | 'lg:tw-border-gray-500'
  | 'xl:tw-border-gray-500'
  | 'hover:tw-border-gray-500'
  | 'focus:tw-border-gray-500'
  | 'sm:tw-border-gray-600'
  | 'md:tw-border-gray-600'
  | 'lg:tw-border-gray-600'
  | 'xl:tw-border-gray-600'
  | 'hover:tw-border-gray-600'
  | 'focus:tw-border-gray-600'
  | 'sm:tw-border-gray-700'
  | 'md:tw-border-gray-700'
  | 'lg:tw-border-gray-700'
  | 'xl:tw-border-gray-700'
  | 'hover:tw-border-gray-700'
  | 'focus:tw-border-gray-700'
  | 'sm:tw-border-gray-800'
  | 'md:tw-border-gray-800'
  | 'lg:tw-border-gray-800'
  | 'xl:tw-border-gray-800'
  | 'hover:tw-border-gray-800'
  | 'focus:tw-border-gray-800'
  | 'sm:tw-border-gray-900'
  | 'md:tw-border-gray-900'
  | 'lg:tw-border-gray-900'
  | 'xl:tw-border-gray-900'
  | 'hover:tw-border-gray-900'
  | 'focus:tw-border-gray-900'
  | 'sm:tw-border-blueGray-50'
  | 'md:tw-border-blueGray-50'
  | 'lg:tw-border-blueGray-50'
  | 'xl:tw-border-blueGray-50'
  | 'hover:tw-border-blueGray-50'
  | 'focus:tw-border-blueGray-50'
  | 'sm:tw-border-blueGray-100'
  | 'md:tw-border-blueGray-100'
  | 'lg:tw-border-blueGray-100'
  | 'xl:tw-border-blueGray-100'
  | 'hover:tw-border-blueGray-100'
  | 'focus:tw-border-blueGray-100'
  | 'sm:tw-border-blueGray-200'
  | 'md:tw-border-blueGray-200'
  | 'lg:tw-border-blueGray-200'
  | 'xl:tw-border-blueGray-200'
  | 'hover:tw-border-blueGray-200'
  | 'focus:tw-border-blueGray-200'
  | 'sm:tw-border-blueGray-300'
  | 'md:tw-border-blueGray-300'
  | 'lg:tw-border-blueGray-300'
  | 'xl:tw-border-blueGray-300'
  | 'hover:tw-border-blueGray-300'
  | 'focus:tw-border-blueGray-300'
  | 'sm:tw-border-blueGray-400'
  | 'md:tw-border-blueGray-400'
  | 'lg:tw-border-blueGray-400'
  | 'xl:tw-border-blueGray-400'
  | 'hover:tw-border-blueGray-400'
  | 'focus:tw-border-blueGray-400'
  | 'sm:tw-border-blueGray-500'
  | 'md:tw-border-blueGray-500'
  | 'lg:tw-border-blueGray-500'
  | 'xl:tw-border-blueGray-500'
  | 'hover:tw-border-blueGray-500'
  | 'focus:tw-border-blueGray-500'
  | 'sm:tw-border-blueGray-600'
  | 'md:tw-border-blueGray-600'
  | 'lg:tw-border-blueGray-600'
  | 'xl:tw-border-blueGray-600'
  | 'hover:tw-border-blueGray-600'
  | 'focus:tw-border-blueGray-600'
  | 'sm:tw-border-blueGray-700'
  | 'md:tw-border-blueGray-700'
  | 'lg:tw-border-blueGray-700'
  | 'xl:tw-border-blueGray-700'
  | 'hover:tw-border-blueGray-700'
  | 'focus:tw-border-blueGray-700'
  | 'sm:tw-border-blueGray-800'
  | 'md:tw-border-blueGray-800'
  | 'lg:tw-border-blueGray-800'
  | 'xl:tw-border-blueGray-800'
  | 'hover:tw-border-blueGray-800'
  | 'focus:tw-border-blueGray-800'
  | 'sm:tw-border-blueGray-900'
  | 'md:tw-border-blueGray-900'
  | 'lg:tw-border-blueGray-900'
  | 'xl:tw-border-blueGray-900'
  | 'hover:tw-border-blueGray-900'
  | 'focus:tw-border-blueGray-900'
  | 'sm:tw-border-black'
  | 'md:tw-border-black'
  | 'lg:tw-border-black'
  | 'xl:tw-border-black'
  | 'hover:tw-border-black'
  | 'focus:tw-border-black'
  | 'sm:tw-border-white'
  | 'md:tw-border-white'
  | 'lg:tw-border-white'
  | 'xl:tw-border-white'
  | 'hover:tw-border-white'
  | 'focus:tw-border-white'
  | 'sm:tw-border-transparent'
  | 'md:tw-border-transparent'
  | 'lg:tw-border-transparent'
  | 'xl:tw-border-transparent'
  | 'hover:tw-border-transparent'
  | 'focus:tw-border-transparent'
  | 'sm:tw-border-opacity-0'
  | 'md:tw-border-opacity-0'
  | 'lg:tw-border-opacity-0'
  | 'xl:tw-border-opacity-0'
  | 'hover:tw-border-opacity-0'
  | 'focus:tw-border-opacity-0'
  | 'sm:tw-border-opacity-25'
  | 'md:tw-border-opacity-25'
  | 'lg:tw-border-opacity-25'
  | 'xl:tw-border-opacity-25'
  | 'hover:tw-border-opacity-25'
  | 'focus:tw-border-opacity-25'
  | 'sm:tw-border-opacity-50'
  | 'md:tw-border-opacity-50'
  | 'lg:tw-border-opacity-50'
  | 'xl:tw-border-opacity-50'
  | 'hover:tw-border-opacity-50'
  | 'focus:tw-border-opacity-50'
  | 'sm:tw-border-opacity-75'
  | 'md:tw-border-opacity-75'
  | 'lg:tw-border-opacity-75'
  | 'xl:tw-border-opacity-75'
  | 'hover:tw-border-opacity-75'
  | 'focus:tw-border-opacity-75'
  | 'sm:tw-border-opacity-100'
  | 'md:tw-border-opacity-100'
  | 'lg:tw-border-opacity-100'
  | 'xl:tw-border-opacity-100'
  | 'hover:tw-border-opacity-100'
  | 'focus:tw-border-opacity-100'
  | 'sm:tw-rounded-t-none'
  | 'md:tw-rounded-t-none'
  | 'lg:tw-rounded-t-none'
  | 'xl:tw-rounded-t-none'
  | 'sm:tw-rounded-r-none'
  | 'md:tw-rounded-r-none'
  | 'lg:tw-rounded-r-none'
  | 'xl:tw-rounded-r-none'
  | 'sm:tw-rounded-b-none'
  | 'md:tw-rounded-b-none'
  | 'lg:tw-rounded-b-none'
  | 'xl:tw-rounded-b-none'
  | 'sm:tw-rounded-l-none'
  | 'md:tw-rounded-l-none'
  | 'lg:tw-rounded-l-none'
  | 'xl:tw-rounded-l-none'
  | 'sm:tw-rounded-tr-none'
  | 'md:tw-rounded-tr-none'
  | 'lg:tw-rounded-tr-none'
  | 'xl:tw-rounded-tr-none'
  | 'sm:tw-rounded-tl-none'
  | 'md:tw-rounded-tl-none'
  | 'lg:tw-rounded-tl-none'
  | 'xl:tw-rounded-tl-none'
  | 'sm:tw-rounded-br-none'
  | 'md:tw-rounded-br-none'
  | 'lg:tw-rounded-br-none'
  | 'xl:tw-rounded-br-none'
  | 'sm:tw-rounded-bl-none'
  | 'md:tw-rounded-bl-none'
  | 'lg:tw-rounded-bl-none'
  | 'xl:tw-rounded-bl-none'
  | 'sm:tw-rounded-none'
  | 'md:tw-rounded-none'
  | 'lg:tw-rounded-none'
  | 'xl:tw-rounded-none'
  | 'sm:tw-rounded-t-sm'
  | 'md:tw-rounded-t-sm'
  | 'lg:tw-rounded-t-sm'
  | 'xl:tw-rounded-t-sm'
  | 'sm:tw-rounded-r-sm'
  | 'md:tw-rounded-r-sm'
  | 'lg:tw-rounded-r-sm'
  | 'xl:tw-rounded-r-sm'
  | 'sm:tw-rounded-b-sm'
  | 'md:tw-rounded-b-sm'
  | 'lg:tw-rounded-b-sm'
  | 'xl:tw-rounded-b-sm'
  | 'sm:tw-rounded-l-sm'
  | 'md:tw-rounded-l-sm'
  | 'lg:tw-rounded-l-sm'
  | 'xl:tw-rounded-l-sm'
  | 'sm:tw-rounded-tr-sm'
  | 'md:tw-rounded-tr-sm'
  | 'lg:tw-rounded-tr-sm'
  | 'xl:tw-rounded-tr-sm'
  | 'sm:tw-rounded-tl-sm'
  | 'md:tw-rounded-tl-sm'
  | 'lg:tw-rounded-tl-sm'
  | 'xl:tw-rounded-tl-sm'
  | 'sm:tw-rounded-br-sm'
  | 'md:tw-rounded-br-sm'
  | 'lg:tw-rounded-br-sm'
  | 'xl:tw-rounded-br-sm'
  | 'sm:tw-rounded-bl-sm'
  | 'md:tw-rounded-bl-sm'
  | 'lg:tw-rounded-bl-sm'
  | 'xl:tw-rounded-bl-sm'
  | 'sm:tw-rounded-sm'
  | 'md:tw-rounded-sm'
  | 'lg:tw-rounded-sm'
  | 'xl:tw-rounded-sm'
  | 'sm:tw-rounded-t'
  | 'md:tw-rounded-t'
  | 'lg:tw-rounded-t'
  | 'xl:tw-rounded-t'
  | 'sm:tw-rounded-r'
  | 'md:tw-rounded-r'
  | 'lg:tw-rounded-r'
  | 'xl:tw-rounded-r'
  | 'sm:tw-rounded-b'
  | 'md:tw-rounded-b'
  | 'lg:tw-rounded-b'
  | 'xl:tw-rounded-b'
  | 'sm:tw-rounded-l'
  | 'md:tw-rounded-l'
  | 'lg:tw-rounded-l'
  | 'xl:tw-rounded-l'
  | 'sm:tw-rounded-tr'
  | 'md:tw-rounded-tr'
  | 'lg:tw-rounded-tr'
  | 'xl:tw-rounded-tr'
  | 'sm:tw-rounded-tl'
  | 'md:tw-rounded-tl'
  | 'lg:tw-rounded-tl'
  | 'xl:tw-rounded-tl'
  | 'sm:tw-rounded-br'
  | 'md:tw-rounded-br'
  | 'lg:tw-rounded-br'
  | 'xl:tw-rounded-br'
  | 'sm:tw-rounded-bl'
  | 'md:tw-rounded-bl'
  | 'lg:tw-rounded-bl'
  | 'xl:tw-rounded-bl'
  | 'sm:tw-rounded'
  | 'md:tw-rounded'
  | 'lg:tw-rounded'
  | 'xl:tw-rounded'
  | 'sm:tw-rounded-t-md'
  | 'md:tw-rounded-t-md'
  | 'lg:tw-rounded-t-md'
  | 'xl:tw-rounded-t-md'
  | 'sm:tw-rounded-r-md'
  | 'md:tw-rounded-r-md'
  | 'lg:tw-rounded-r-md'
  | 'xl:tw-rounded-r-md'
  | 'sm:tw-rounded-b-md'
  | 'md:tw-rounded-b-md'
  | 'lg:tw-rounded-b-md'
  | 'xl:tw-rounded-b-md'
  | 'sm:tw-rounded-l-md'
  | 'md:tw-rounded-l-md'
  | 'lg:tw-rounded-l-md'
  | 'xl:tw-rounded-l-md'
  | 'sm:tw-rounded-tr-md'
  | 'md:tw-rounded-tr-md'
  | 'lg:tw-rounded-tr-md'
  | 'xl:tw-rounded-tr-md'
  | 'sm:tw-rounded-tl-md'
  | 'md:tw-rounded-tl-md'
  | 'lg:tw-rounded-tl-md'
  | 'xl:tw-rounded-tl-md'
  | 'sm:tw-rounded-br-md'
  | 'md:tw-rounded-br-md'
  | 'lg:tw-rounded-br-md'
  | 'xl:tw-rounded-br-md'
  | 'sm:tw-rounded-bl-md'
  | 'md:tw-rounded-bl-md'
  | 'lg:tw-rounded-bl-md'
  | 'xl:tw-rounded-bl-md'
  | 'sm:tw-rounded-md'
  | 'md:tw-rounded-md'
  | 'lg:tw-rounded-md'
  | 'xl:tw-rounded-md'
  | 'sm:tw-rounded-t-lg'
  | 'md:tw-rounded-t-lg'
  | 'lg:tw-rounded-t-lg'
  | 'xl:tw-rounded-t-lg'
  | 'sm:tw-rounded-r-lg'
  | 'md:tw-rounded-r-lg'
  | 'lg:tw-rounded-r-lg'
  | 'xl:tw-rounded-r-lg'
  | 'sm:tw-rounded-b-lg'
  | 'md:tw-rounded-b-lg'
  | 'lg:tw-rounded-b-lg'
  | 'xl:tw-rounded-b-lg'
  | 'sm:tw-rounded-l-lg'
  | 'md:tw-rounded-l-lg'
  | 'lg:tw-rounded-l-lg'
  | 'xl:tw-rounded-l-lg'
  | 'sm:tw-rounded-tr-lg'
  | 'md:tw-rounded-tr-lg'
  | 'lg:tw-rounded-tr-lg'
  | 'xl:tw-rounded-tr-lg'
  | 'sm:tw-rounded-tl-lg'
  | 'md:tw-rounded-tl-lg'
  | 'lg:tw-rounded-tl-lg'
  | 'xl:tw-rounded-tl-lg'
  | 'sm:tw-rounded-br-lg'
  | 'md:tw-rounded-br-lg'
  | 'lg:tw-rounded-br-lg'
  | 'xl:tw-rounded-br-lg'
  | 'sm:tw-rounded-bl-lg'
  | 'md:tw-rounded-bl-lg'
  | 'lg:tw-rounded-bl-lg'
  | 'xl:tw-rounded-bl-lg'
  | 'sm:tw-rounded-lg'
  | 'md:tw-rounded-lg'
  | 'lg:tw-rounded-lg'
  | 'xl:tw-rounded-lg'
  | 'sm:tw-rounded-t-xl'
  | 'md:tw-rounded-t-xl'
  | 'lg:tw-rounded-t-xl'
  | 'xl:tw-rounded-t-xl'
  | 'sm:tw-rounded-r-xl'
  | 'md:tw-rounded-r-xl'
  | 'lg:tw-rounded-r-xl'
  | 'xl:tw-rounded-r-xl'
  | 'sm:tw-rounded-b-xl'
  | 'md:tw-rounded-b-xl'
  | 'lg:tw-rounded-b-xl'
  | 'xl:tw-rounded-b-xl'
  | 'sm:tw-rounded-l-xl'
  | 'md:tw-rounded-l-xl'
  | 'lg:tw-rounded-l-xl'
  | 'xl:tw-rounded-l-xl'
  | 'sm:tw-rounded-tr-xl'
  | 'md:tw-rounded-tr-xl'
  | 'lg:tw-rounded-tr-xl'
  | 'xl:tw-rounded-tr-xl'
  | 'sm:tw-rounded-tl-xl'
  | 'md:tw-rounded-tl-xl'
  | 'lg:tw-rounded-tl-xl'
  | 'xl:tw-rounded-tl-xl'
  | 'sm:tw-rounded-br-xl'
  | 'md:tw-rounded-br-xl'
  | 'lg:tw-rounded-br-xl'
  | 'xl:tw-rounded-br-xl'
  | 'sm:tw-rounded-bl-xl'
  | 'md:tw-rounded-bl-xl'
  | 'lg:tw-rounded-bl-xl'
  | 'xl:tw-rounded-bl-xl'
  | 'sm:tw-rounded-xl'
  | 'md:tw-rounded-xl'
  | 'lg:tw-rounded-xl'
  | 'xl:tw-rounded-xl'
  | 'sm:tw-rounded-t-2xl'
  | 'md:tw-rounded-t-2xl'
  | 'lg:tw-rounded-t-2xl'
  | 'xl:tw-rounded-t-2xl'
  | 'sm:tw-rounded-r-2xl'
  | 'md:tw-rounded-r-2xl'
  | 'lg:tw-rounded-r-2xl'
  | 'xl:tw-rounded-r-2xl'
  | 'sm:tw-rounded-b-2xl'
  | 'md:tw-rounded-b-2xl'
  | 'lg:tw-rounded-b-2xl'
  | 'xl:tw-rounded-b-2xl'
  | 'sm:tw-rounded-l-2xl'
  | 'md:tw-rounded-l-2xl'
  | 'lg:tw-rounded-l-2xl'
  | 'xl:tw-rounded-l-2xl'
  | 'sm:tw-rounded-tr-2xl'
  | 'md:tw-rounded-tr-2xl'
  | 'lg:tw-rounded-tr-2xl'
  | 'xl:tw-rounded-tr-2xl'
  | 'sm:tw-rounded-tl-2xl'
  | 'md:tw-rounded-tl-2xl'
  | 'lg:tw-rounded-tl-2xl'
  | 'xl:tw-rounded-tl-2xl'
  | 'sm:tw-rounded-br-2xl'
  | 'md:tw-rounded-br-2xl'
  | 'lg:tw-rounded-br-2xl'
  | 'xl:tw-rounded-br-2xl'
  | 'sm:tw-rounded-bl-2xl'
  | 'md:tw-rounded-bl-2xl'
  | 'lg:tw-rounded-bl-2xl'
  | 'xl:tw-rounded-bl-2xl'
  | 'sm:tw-rounded-2xl'
  | 'md:tw-rounded-2xl'
  | 'lg:tw-rounded-2xl'
  | 'xl:tw-rounded-2xl'
  | 'sm:tw-rounded-t-3xl'
  | 'md:tw-rounded-t-3xl'
  | 'lg:tw-rounded-t-3xl'
  | 'xl:tw-rounded-t-3xl'
  | 'sm:tw-rounded-r-3xl'
  | 'md:tw-rounded-r-3xl'
  | 'lg:tw-rounded-r-3xl'
  | 'xl:tw-rounded-r-3xl'
  | 'sm:tw-rounded-b-3xl'
  | 'md:tw-rounded-b-3xl'
  | 'lg:tw-rounded-b-3xl'
  | 'xl:tw-rounded-b-3xl'
  | 'sm:tw-rounded-l-3xl'
  | 'md:tw-rounded-l-3xl'
  | 'lg:tw-rounded-l-3xl'
  | 'xl:tw-rounded-l-3xl'
  | 'sm:tw-rounded-tr-3xl'
  | 'md:tw-rounded-tr-3xl'
  | 'lg:tw-rounded-tr-3xl'
  | 'xl:tw-rounded-tr-3xl'
  | 'sm:tw-rounded-tl-3xl'
  | 'md:tw-rounded-tl-3xl'
  | 'lg:tw-rounded-tl-3xl'
  | 'xl:tw-rounded-tl-3xl'
  | 'sm:tw-rounded-br-3xl'
  | 'md:tw-rounded-br-3xl'
  | 'lg:tw-rounded-br-3xl'
  | 'xl:tw-rounded-br-3xl'
  | 'sm:tw-rounded-bl-3xl'
  | 'md:tw-rounded-bl-3xl'
  | 'lg:tw-rounded-bl-3xl'
  | 'xl:tw-rounded-bl-3xl'
  | 'sm:tw-rounded-3xl'
  | 'md:tw-rounded-3xl'
  | 'lg:tw-rounded-3xl'
  | 'xl:tw-rounded-3xl'
  | 'sm:tw-rounded-t-full'
  | 'md:tw-rounded-t-full'
  | 'lg:tw-rounded-t-full'
  | 'xl:tw-rounded-t-full'
  | 'sm:tw-rounded-r-full'
  | 'md:tw-rounded-r-full'
  | 'lg:tw-rounded-r-full'
  | 'xl:tw-rounded-r-full'
  | 'sm:tw-rounded-b-full'
  | 'md:tw-rounded-b-full'
  | 'lg:tw-rounded-b-full'
  | 'xl:tw-rounded-b-full'
  | 'sm:tw-rounded-l-full'
  | 'md:tw-rounded-l-full'
  | 'lg:tw-rounded-l-full'
  | 'xl:tw-rounded-l-full'
  | 'sm:tw-rounded-tr-full'
  | 'md:tw-rounded-tr-full'
  | 'lg:tw-rounded-tr-full'
  | 'xl:tw-rounded-tr-full'
  | 'sm:tw-rounded-tl-full'
  | 'md:tw-rounded-tl-full'
  | 'lg:tw-rounded-tl-full'
  | 'xl:tw-rounded-tl-full'
  | 'sm:tw-rounded-br-full'
  | 'md:tw-rounded-br-full'
  | 'lg:tw-rounded-br-full'
  | 'xl:tw-rounded-br-full'
  | 'sm:tw-rounded-bl-full'
  | 'md:tw-rounded-bl-full'
  | 'lg:tw-rounded-bl-full'
  | 'xl:tw-rounded-bl-full'
  | 'sm:tw-rounded-full'
  | 'md:tw-rounded-full'
  | 'lg:tw-rounded-full'
  | 'xl:tw-rounded-full'
  | 'sm:tw-border-solid'
  | 'md:tw-border-solid'
  | 'lg:tw-border-solid'
  | 'xl:tw-border-solid'
  | 'sm:tw-border-dashed'
  | 'md:tw-border-dashed'
  | 'lg:tw-border-dashed'
  | 'xl:tw-border-dashed'
  | 'sm:tw-border-dotted'
  | 'md:tw-border-dotted'
  | 'lg:tw-border-dotted'
  | 'xl:tw-border-dotted'
  | 'sm:tw-border-double'
  | 'md:tw-border-double'
  | 'lg:tw-border-double'
  | 'xl:tw-border-double'
  | 'sm:tw-border-none'
  | 'md:tw-border-none'
  | 'lg:tw-border-none'
  | 'xl:tw-border-none'
  | 'sm:tw-border-t-0'
  | 'md:tw-border-t-0'
  | 'lg:tw-border-t-0'
  | 'xl:tw-border-t-0'
  | 'sm:tw-border-r-0'
  | 'md:tw-border-r-0'
  | 'lg:tw-border-r-0'
  | 'xl:tw-border-r-0'
  | 'sm:tw-border-b-0'
  | 'md:tw-border-b-0'
  | 'lg:tw-border-b-0'
  | 'xl:tw-border-b-0'
  | 'sm:tw-border-l-0'
  | 'md:tw-border-l-0'
  | 'lg:tw-border-l-0'
  | 'xl:tw-border-l-0'
  | 'sm:tw-border-0'
  | 'md:tw-border-0'
  | 'lg:tw-border-0'
  | 'xl:tw-border-0'
  | 'sm:tw-border-t-2'
  | 'md:tw-border-t-2'
  | 'lg:tw-border-t-2'
  | 'xl:tw-border-t-2'
  | 'sm:tw-border-r-2'
  | 'md:tw-border-r-2'
  | 'lg:tw-border-r-2'
  | 'xl:tw-border-r-2'
  | 'sm:tw-border-b-2'
  | 'md:tw-border-b-2'
  | 'lg:tw-border-b-2'
  | 'xl:tw-border-b-2'
  | 'sm:tw-border-l-2'
  | 'md:tw-border-l-2'
  | 'lg:tw-border-l-2'
  | 'xl:tw-border-l-2'
  | 'sm:tw-border-2'
  | 'md:tw-border-2'
  | 'lg:tw-border-2'
  | 'xl:tw-border-2'
  | 'sm:tw-border-t-4'
  | 'md:tw-border-t-4'
  | 'lg:tw-border-t-4'
  | 'xl:tw-border-t-4'
  | 'sm:tw-border-r-4'
  | 'md:tw-border-r-4'
  | 'lg:tw-border-r-4'
  | 'xl:tw-border-r-4'
  | 'sm:tw-border-b-4'
  | 'md:tw-border-b-4'
  | 'lg:tw-border-b-4'
  | 'xl:tw-border-b-4'
  | 'sm:tw-border-l-4'
  | 'md:tw-border-l-4'
  | 'lg:tw-border-l-4'
  | 'xl:tw-border-l-4'
  | 'sm:tw-border-4'
  | 'md:tw-border-4'
  | 'lg:tw-border-4'
  | 'xl:tw-border-4'
  | 'sm:tw-border-t-8'
  | 'md:tw-border-t-8'
  | 'lg:tw-border-t-8'
  | 'xl:tw-border-t-8'
  | 'sm:tw-border-r-8'
  | 'md:tw-border-r-8'
  | 'lg:tw-border-r-8'
  | 'xl:tw-border-r-8'
  | 'sm:tw-border-b-8'
  | 'md:tw-border-b-8'
  | 'lg:tw-border-b-8'
  | 'xl:tw-border-b-8'
  | 'sm:tw-border-l-8'
  | 'md:tw-border-l-8'
  | 'lg:tw-border-l-8'
  | 'xl:tw-border-l-8'
  | 'sm:tw-border-8'
  | 'md:tw-border-8'
  | 'lg:tw-border-8'
  | 'xl:tw-border-8'
  | 'sm:tw-border-t'
  | 'md:tw-border-t'
  | 'lg:tw-border-t'
  | 'xl:tw-border-t'
  | 'sm:tw-border-r'
  | 'md:tw-border-r'
  | 'lg:tw-border-r'
  | 'xl:tw-border-r'
  | 'sm:tw-border-b'
  | 'md:tw-border-b'
  | 'lg:tw-border-b'
  | 'xl:tw-border-b'
  | 'sm:tw-border-l'
  | 'md:tw-border-l'
  | 'lg:tw-border-l'
  | 'xl:tw-border-l'
  | 'sm:tw-border'
  | 'md:tw-border'
  | 'lg:tw-border'
  | 'xl:tw-border'
  | 'sm:tw-shadow-xs'
  | 'md:tw-shadow-xs'
  | 'lg:tw-shadow-xs'
  | 'xl:tw-shadow-xs'
  | 'hover:tw-shadow-xs'
  | 'focus:tw-shadow-xs'
  | 'sm:tw-shadow-sm'
  | 'md:tw-shadow-sm'
  | 'lg:tw-shadow-sm'
  | 'xl:tw-shadow-sm'
  | 'hover:tw-shadow-sm'
  | 'focus:tw-shadow-sm'
  | 'sm:tw-shadow'
  | 'md:tw-shadow'
  | 'lg:tw-shadow'
  | 'xl:tw-shadow'
  | 'hover:tw-shadow'
  | 'focus:tw-shadow'
  | 'sm:tw-shadow-md'
  | 'md:tw-shadow-md'
  | 'lg:tw-shadow-md'
  | 'xl:tw-shadow-md'
  | 'hover:tw-shadow-md'
  | 'focus:tw-shadow-md'
  | 'sm:tw-shadow-lg'
  | 'md:tw-shadow-lg'
  | 'lg:tw-shadow-lg'
  | 'xl:tw-shadow-lg'
  | 'hover:tw-shadow-lg'
  | 'focus:tw-shadow-lg'
  | 'sm:tw-shadow-xl'
  | 'md:tw-shadow-xl'
  | 'lg:tw-shadow-xl'
  | 'xl:tw-shadow-xl'
  | 'hover:tw-shadow-xl'
  | 'focus:tw-shadow-xl'
  | 'sm:tw-shadow-2xl'
  | 'md:tw-shadow-2xl'
  | 'lg:tw-shadow-2xl'
  | 'xl:tw-shadow-2xl'
  | 'hover:tw-shadow-2xl'
  | 'focus:tw-shadow-2xl'
  | 'sm:tw-shadow-inner'
  | 'md:tw-shadow-inner'
  | 'lg:tw-shadow-inner'
  | 'xl:tw-shadow-inner'
  | 'hover:tw-shadow-inner'
  | 'focus:tw-shadow-inner'
  | 'sm:tw-shadow-outline'
  | 'md:tw-shadow-outline'
  | 'lg:tw-shadow-outline'
  | 'xl:tw-shadow-outline'
  | 'hover:tw-shadow-outline'
  | 'focus:tw-shadow-outline'
  | 'sm:tw-shadow-none'
  | 'md:tw-shadow-none'
  | 'lg:tw-shadow-none'
  | 'xl:tw-shadow-none'
  | 'hover:tw-shadow-none'
  | 'focus:tw-shadow-none'
  | 'sm:tw-box-border'
  | 'md:tw-box-border'
  | 'lg:tw-box-border'
  | 'xl:tw-box-border'
  | 'sm:tw-box-content'
  | 'md:tw-box-content'
  | 'lg:tw-box-content'
  | 'xl:tw-box-content'
  | 'sm:tw-container'
  | 'md:tw-container'
  | 'lg:tw-container'
  | 'xl:tw-container'
  | 'sm:tw-cursor-auto'
  | 'md:tw-cursor-auto'
  | 'lg:tw-cursor-auto'
  | 'xl:tw-cursor-auto'
  | 'sm:tw-cursor-default'
  | 'md:tw-cursor-default'
  | 'lg:tw-cursor-default'
  | 'xl:tw-cursor-default'
  | 'sm:tw-cursor-pointer'
  | 'md:tw-cursor-pointer'
  | 'lg:tw-cursor-pointer'
  | 'xl:tw-cursor-pointer'
  | 'sm:tw-cursor-wait'
  | 'md:tw-cursor-wait'
  | 'lg:tw-cursor-wait'
  | 'xl:tw-cursor-wait'
  | 'sm:tw-cursor-text'
  | 'md:tw-cursor-text'
  | 'lg:tw-cursor-text'
  | 'xl:tw-cursor-text'
  | 'sm:tw-cursor-move'
  | 'md:tw-cursor-move'
  | 'lg:tw-cursor-move'
  | 'xl:tw-cursor-move'
  | 'sm:tw-cursor-not-allowed'
  | 'md:tw-cursor-not-allowed'
  | 'lg:tw-cursor-not-allowed'
  | 'xl:tw-cursor-not-allowed'
  | 'sm:tw-block'
  | 'md:tw-block'
  | 'lg:tw-block'
  | 'xl:tw-block'
  | 'sm:tw-inline-block'
  | 'md:tw-inline-block'
  | 'lg:tw-inline-block'
  | 'xl:tw-inline-block'
  | 'sm:tw-inline'
  | 'md:tw-inline'
  | 'lg:tw-inline'
  | 'xl:tw-inline'
  | 'sm:tw-flex'
  | 'md:tw-flex'
  | 'lg:tw-flex'
  | 'xl:tw-flex'
  | 'sm:tw-inline-flex'
  | 'md:tw-inline-flex'
  | 'lg:tw-inline-flex'
  | 'xl:tw-inline-flex'
  | 'sm:tw-grid'
  | 'md:tw-grid'
  | 'lg:tw-grid'
  | 'xl:tw-grid'
  | 'sm:tw-inline-grid'
  | 'md:tw-inline-grid'
  | 'lg:tw-inline-grid'
  | 'xl:tw-inline-grid'
  | 'sm:tw-table'
  | 'md:tw-table'
  | 'lg:tw-table'
  | 'xl:tw-table'
  | 'sm:tw-table-row'
  | 'md:tw-table-row'
  | 'lg:tw-table-row'
  | 'xl:tw-table-row'
  | 'sm:tw-table-cell'
  | 'md:tw-table-cell'
  | 'lg:tw-table-cell'
  | 'xl:tw-table-cell'
  | 'sm:tw-contents'
  | 'md:tw-contents'
  | 'lg:tw-contents'
  | 'xl:tw-contents'
  | 'sm:tw-hidden'
  | 'md:tw-hidden'
  | 'lg:tw-hidden'
  | 'xl:tw-hidden'
  | 'sm:tw-divide-primary'
  | 'md:tw-divide-primary'
  | 'lg:tw-divide-primary'
  | 'xl:tw-divide-primary'
  | 'sm:tw-divide-muted'
  | 'md:tw-divide-muted'
  | 'lg:tw-divide-muted'
  | 'xl:tw-divide-muted'
  | 'sm:tw-divide-warning'
  | 'md:tw-divide-warning'
  | 'lg:tw-divide-warning'
  | 'xl:tw-divide-warning'
  | 'sm:tw-divide-danger'
  | 'md:tw-divide-danger'
  | 'lg:tw-divide-danger'
  | 'xl:tw-divide-danger'
  | 'sm:tw-divide-fail'
  | 'md:tw-divide-fail'
  | 'lg:tw-divide-fail'
  | 'xl:tw-divide-fail'
  | 'sm:tw-divide-info'
  | 'md:tw-divide-info'
  | 'lg:tw-divide-info'
  | 'xl:tw-divide-info'
  | 'sm:tw-divide-red-50'
  | 'md:tw-divide-red-50'
  | 'lg:tw-divide-red-50'
  | 'xl:tw-divide-red-50'
  | 'sm:tw-divide-red-100'
  | 'md:tw-divide-red-100'
  | 'lg:tw-divide-red-100'
  | 'xl:tw-divide-red-100'
  | 'sm:tw-divide-red-200'
  | 'md:tw-divide-red-200'
  | 'lg:tw-divide-red-200'
  | 'xl:tw-divide-red-200'
  | 'sm:tw-divide-red-300'
  | 'md:tw-divide-red-300'
  | 'lg:tw-divide-red-300'
  | 'xl:tw-divide-red-300'
  | 'sm:tw-divide-red-400'
  | 'md:tw-divide-red-400'
  | 'lg:tw-divide-red-400'
  | 'xl:tw-divide-red-400'
  | 'sm:tw-divide-red-500'
  | 'md:tw-divide-red-500'
  | 'lg:tw-divide-red-500'
  | 'xl:tw-divide-red-500'
  | 'sm:tw-divide-red-600'
  | 'md:tw-divide-red-600'
  | 'lg:tw-divide-red-600'
  | 'xl:tw-divide-red-600'
  | 'sm:tw-divide-red-700'
  | 'md:tw-divide-red-700'
  | 'lg:tw-divide-red-700'
  | 'xl:tw-divide-red-700'
  | 'sm:tw-divide-red-800'
  | 'md:tw-divide-red-800'
  | 'lg:tw-divide-red-800'
  | 'xl:tw-divide-red-800'
  | 'sm:tw-divide-red-900'
  | 'md:tw-divide-red-900'
  | 'lg:tw-divide-red-900'
  | 'xl:tw-divide-red-900'
  | 'sm:tw-divide-red-a100'
  | 'md:tw-divide-red-a100'
  | 'lg:tw-divide-red-a100'
  | 'xl:tw-divide-red-a100'
  | 'sm:tw-divide-red-a200'
  | 'md:tw-divide-red-a200'
  | 'lg:tw-divide-red-a200'
  | 'xl:tw-divide-red-a200'
  | 'sm:tw-divide-red-a400'
  | 'md:tw-divide-red-a400'
  | 'lg:tw-divide-red-a400'
  | 'xl:tw-divide-red-a400'
  | 'sm:tw-divide-red-a700'
  | 'md:tw-divide-red-a700'
  | 'lg:tw-divide-red-a700'
  | 'xl:tw-divide-red-a700'
  | 'sm:tw-divide-pink-50'
  | 'md:tw-divide-pink-50'
  | 'lg:tw-divide-pink-50'
  | 'xl:tw-divide-pink-50'
  | 'sm:tw-divide-pink-100'
  | 'md:tw-divide-pink-100'
  | 'lg:tw-divide-pink-100'
  | 'xl:tw-divide-pink-100'
  | 'sm:tw-divide-pink-200'
  | 'md:tw-divide-pink-200'
  | 'lg:tw-divide-pink-200'
  | 'xl:tw-divide-pink-200'
  | 'sm:tw-divide-pink-300'
  | 'md:tw-divide-pink-300'
  | 'lg:tw-divide-pink-300'
  | 'xl:tw-divide-pink-300'
  | 'sm:tw-divide-pink-400'
  | 'md:tw-divide-pink-400'
  | 'lg:tw-divide-pink-400'
  | 'xl:tw-divide-pink-400'
  | 'sm:tw-divide-pink-500'
  | 'md:tw-divide-pink-500'
  | 'lg:tw-divide-pink-500'
  | 'xl:tw-divide-pink-500'
  | 'sm:tw-divide-pink-600'
  | 'md:tw-divide-pink-600'
  | 'lg:tw-divide-pink-600'
  | 'xl:tw-divide-pink-600'
  | 'sm:tw-divide-pink-700'
  | 'md:tw-divide-pink-700'
  | 'lg:tw-divide-pink-700'
  | 'xl:tw-divide-pink-700'
  | 'sm:tw-divide-pink-800'
  | 'md:tw-divide-pink-800'
  | 'lg:tw-divide-pink-800'
  | 'xl:tw-divide-pink-800'
  | 'sm:tw-divide-pink-900'
  | 'md:tw-divide-pink-900'
  | 'lg:tw-divide-pink-900'
  | 'xl:tw-divide-pink-900'
  | 'sm:tw-divide-pink-a100'
  | 'md:tw-divide-pink-a100'
  | 'lg:tw-divide-pink-a100'
  | 'xl:tw-divide-pink-a100'
  | 'sm:tw-divide-pink-a200'
  | 'md:tw-divide-pink-a200'
  | 'lg:tw-divide-pink-a200'
  | 'xl:tw-divide-pink-a200'
  | 'sm:tw-divide-pink-a400'
  | 'md:tw-divide-pink-a400'
  | 'lg:tw-divide-pink-a400'
  | 'xl:tw-divide-pink-a400'
  | 'sm:tw-divide-pink-a700'
  | 'md:tw-divide-pink-a700'
  | 'lg:tw-divide-pink-a700'
  | 'xl:tw-divide-pink-a700'
  | 'sm:tw-divide-purple-50'
  | 'md:tw-divide-purple-50'
  | 'lg:tw-divide-purple-50'
  | 'xl:tw-divide-purple-50'
  | 'sm:tw-divide-purple-100'
  | 'md:tw-divide-purple-100'
  | 'lg:tw-divide-purple-100'
  | 'xl:tw-divide-purple-100'
  | 'sm:tw-divide-purple-200'
  | 'md:tw-divide-purple-200'
  | 'lg:tw-divide-purple-200'
  | 'xl:tw-divide-purple-200'
  | 'sm:tw-divide-purple-300'
  | 'md:tw-divide-purple-300'
  | 'lg:tw-divide-purple-300'
  | 'xl:tw-divide-purple-300'
  | 'sm:tw-divide-purple-400'
  | 'md:tw-divide-purple-400'
  | 'lg:tw-divide-purple-400'
  | 'xl:tw-divide-purple-400'
  | 'sm:tw-divide-purple-500'
  | 'md:tw-divide-purple-500'
  | 'lg:tw-divide-purple-500'
  | 'xl:tw-divide-purple-500'
  | 'sm:tw-divide-purple-600'
  | 'md:tw-divide-purple-600'
  | 'lg:tw-divide-purple-600'
  | 'xl:tw-divide-purple-600'
  | 'sm:tw-divide-purple-700'
  | 'md:tw-divide-purple-700'
  | 'lg:tw-divide-purple-700'
  | 'xl:tw-divide-purple-700'
  | 'sm:tw-divide-purple-800'
  | 'md:tw-divide-purple-800'
  | 'lg:tw-divide-purple-800'
  | 'xl:tw-divide-purple-800'
  | 'sm:tw-divide-purple-900'
  | 'md:tw-divide-purple-900'
  | 'lg:tw-divide-purple-900'
  | 'xl:tw-divide-purple-900'
  | 'sm:tw-divide-purple-a100'
  | 'md:tw-divide-purple-a100'
  | 'lg:tw-divide-purple-a100'
  | 'xl:tw-divide-purple-a100'
  | 'sm:tw-divide-purple-a200'
  | 'md:tw-divide-purple-a200'
  | 'lg:tw-divide-purple-a200'
  | 'xl:tw-divide-purple-a200'
  | 'sm:tw-divide-purple-a400'
  | 'md:tw-divide-purple-a400'
  | 'lg:tw-divide-purple-a400'
  | 'xl:tw-divide-purple-a400'
  | 'sm:tw-divide-purple-a700'
  | 'md:tw-divide-purple-a700'
  | 'lg:tw-divide-purple-a700'
  | 'xl:tw-divide-purple-a700'
  | 'sm:tw-divide-deepPurple-50'
  | 'md:tw-divide-deepPurple-50'
  | 'lg:tw-divide-deepPurple-50'
  | 'xl:tw-divide-deepPurple-50'
  | 'sm:tw-divide-deepPurple-100'
  | 'md:tw-divide-deepPurple-100'
  | 'lg:tw-divide-deepPurple-100'
  | 'xl:tw-divide-deepPurple-100'
  | 'sm:tw-divide-deepPurple-200'
  | 'md:tw-divide-deepPurple-200'
  | 'lg:tw-divide-deepPurple-200'
  | 'xl:tw-divide-deepPurple-200'
  | 'sm:tw-divide-deepPurple-300'
  | 'md:tw-divide-deepPurple-300'
  | 'lg:tw-divide-deepPurple-300'
  | 'xl:tw-divide-deepPurple-300'
  | 'sm:tw-divide-deepPurple-400'
  | 'md:tw-divide-deepPurple-400'
  | 'lg:tw-divide-deepPurple-400'
  | 'xl:tw-divide-deepPurple-400'
  | 'sm:tw-divide-deepPurple-500'
  | 'md:tw-divide-deepPurple-500'
  | 'lg:tw-divide-deepPurple-500'
  | 'xl:tw-divide-deepPurple-500'
  | 'sm:tw-divide-deepPurple-600'
  | 'md:tw-divide-deepPurple-600'
  | 'lg:tw-divide-deepPurple-600'
  | 'xl:tw-divide-deepPurple-600'
  | 'sm:tw-divide-deepPurple-700'
  | 'md:tw-divide-deepPurple-700'
  | 'lg:tw-divide-deepPurple-700'
  | 'xl:tw-divide-deepPurple-700'
  | 'sm:tw-divide-deepPurple-800'
  | 'md:tw-divide-deepPurple-800'
  | 'lg:tw-divide-deepPurple-800'
  | 'xl:tw-divide-deepPurple-800'
  | 'sm:tw-divide-deepPurple-900'
  | 'md:tw-divide-deepPurple-900'
  | 'lg:tw-divide-deepPurple-900'
  | 'xl:tw-divide-deepPurple-900'
  | 'sm:tw-divide-deepPurple-a100'
  | 'md:tw-divide-deepPurple-a100'
  | 'lg:tw-divide-deepPurple-a100'
  | 'xl:tw-divide-deepPurple-a100'
  | 'sm:tw-divide-deepPurple-a200'
  | 'md:tw-divide-deepPurple-a200'
  | 'lg:tw-divide-deepPurple-a200'
  | 'xl:tw-divide-deepPurple-a200'
  | 'sm:tw-divide-deepPurple-a400'
  | 'md:tw-divide-deepPurple-a400'
  | 'lg:tw-divide-deepPurple-a400'
  | 'xl:tw-divide-deepPurple-a400'
  | 'sm:tw-divide-deepPurple-a700'
  | 'md:tw-divide-deepPurple-a700'
  | 'lg:tw-divide-deepPurple-a700'
  | 'xl:tw-divide-deepPurple-a700'
  | 'sm:tw-divide-indigo-50'
  | 'md:tw-divide-indigo-50'
  | 'lg:tw-divide-indigo-50'
  | 'xl:tw-divide-indigo-50'
  | 'sm:tw-divide-indigo-100'
  | 'md:tw-divide-indigo-100'
  | 'lg:tw-divide-indigo-100'
  | 'xl:tw-divide-indigo-100'
  | 'sm:tw-divide-indigo-200'
  | 'md:tw-divide-indigo-200'
  | 'lg:tw-divide-indigo-200'
  | 'xl:tw-divide-indigo-200'
  | 'sm:tw-divide-indigo-300'
  | 'md:tw-divide-indigo-300'
  | 'lg:tw-divide-indigo-300'
  | 'xl:tw-divide-indigo-300'
  | 'sm:tw-divide-indigo-400'
  | 'md:tw-divide-indigo-400'
  | 'lg:tw-divide-indigo-400'
  | 'xl:tw-divide-indigo-400'
  | 'sm:tw-divide-indigo-500'
  | 'md:tw-divide-indigo-500'
  | 'lg:tw-divide-indigo-500'
  | 'xl:tw-divide-indigo-500'
  | 'sm:tw-divide-indigo-600'
  | 'md:tw-divide-indigo-600'
  | 'lg:tw-divide-indigo-600'
  | 'xl:tw-divide-indigo-600'
  | 'sm:tw-divide-indigo-700'
  | 'md:tw-divide-indigo-700'
  | 'lg:tw-divide-indigo-700'
  | 'xl:tw-divide-indigo-700'
  | 'sm:tw-divide-indigo-800'
  | 'md:tw-divide-indigo-800'
  | 'lg:tw-divide-indigo-800'
  | 'xl:tw-divide-indigo-800'
  | 'sm:tw-divide-indigo-900'
  | 'md:tw-divide-indigo-900'
  | 'lg:tw-divide-indigo-900'
  | 'xl:tw-divide-indigo-900'
  | 'sm:tw-divide-indigo-a100'
  | 'md:tw-divide-indigo-a100'
  | 'lg:tw-divide-indigo-a100'
  | 'xl:tw-divide-indigo-a100'
  | 'sm:tw-divide-indigo-a200'
  | 'md:tw-divide-indigo-a200'
  | 'lg:tw-divide-indigo-a200'
  | 'xl:tw-divide-indigo-a200'
  | 'sm:tw-divide-indigo-a400'
  | 'md:tw-divide-indigo-a400'
  | 'lg:tw-divide-indigo-a400'
  | 'xl:tw-divide-indigo-a400'
  | 'sm:tw-divide-indigo-a700'
  | 'md:tw-divide-indigo-a700'
  | 'lg:tw-divide-indigo-a700'
  | 'xl:tw-divide-indigo-a700'
  | 'sm:tw-divide-blue-50'
  | 'md:tw-divide-blue-50'
  | 'lg:tw-divide-blue-50'
  | 'xl:tw-divide-blue-50'
  | 'sm:tw-divide-blue-100'
  | 'md:tw-divide-blue-100'
  | 'lg:tw-divide-blue-100'
  | 'xl:tw-divide-blue-100'
  | 'sm:tw-divide-blue-200'
  | 'md:tw-divide-blue-200'
  | 'lg:tw-divide-blue-200'
  | 'xl:tw-divide-blue-200'
  | 'sm:tw-divide-blue-300'
  | 'md:tw-divide-blue-300'
  | 'lg:tw-divide-blue-300'
  | 'xl:tw-divide-blue-300'
  | 'sm:tw-divide-blue-400'
  | 'md:tw-divide-blue-400'
  | 'lg:tw-divide-blue-400'
  | 'xl:tw-divide-blue-400'
  | 'sm:tw-divide-blue-500'
  | 'md:tw-divide-blue-500'
  | 'lg:tw-divide-blue-500'
  | 'xl:tw-divide-blue-500'
  | 'sm:tw-divide-blue-600'
  | 'md:tw-divide-blue-600'
  | 'lg:tw-divide-blue-600'
  | 'xl:tw-divide-blue-600'
  | 'sm:tw-divide-blue-700'
  | 'md:tw-divide-blue-700'
  | 'lg:tw-divide-blue-700'
  | 'xl:tw-divide-blue-700'
  | 'sm:tw-divide-blue-800'
  | 'md:tw-divide-blue-800'
  | 'lg:tw-divide-blue-800'
  | 'xl:tw-divide-blue-800'
  | 'sm:tw-divide-blue-900'
  | 'md:tw-divide-blue-900'
  | 'lg:tw-divide-blue-900'
  | 'xl:tw-divide-blue-900'
  | 'sm:tw-divide-blue-a100'
  | 'md:tw-divide-blue-a100'
  | 'lg:tw-divide-blue-a100'
  | 'xl:tw-divide-blue-a100'
  | 'sm:tw-divide-blue-a200'
  | 'md:tw-divide-blue-a200'
  | 'lg:tw-divide-blue-a200'
  | 'xl:tw-divide-blue-a200'
  | 'sm:tw-divide-blue-a400'
  | 'md:tw-divide-blue-a400'
  | 'lg:tw-divide-blue-a400'
  | 'xl:tw-divide-blue-a400'
  | 'sm:tw-divide-blue-a700'
  | 'md:tw-divide-blue-a700'
  | 'lg:tw-divide-blue-a700'
  | 'xl:tw-divide-blue-a700'
  | 'sm:tw-divide-lightBlue-50'
  | 'md:tw-divide-lightBlue-50'
  | 'lg:tw-divide-lightBlue-50'
  | 'xl:tw-divide-lightBlue-50'
  | 'sm:tw-divide-lightBlue-100'
  | 'md:tw-divide-lightBlue-100'
  | 'lg:tw-divide-lightBlue-100'
  | 'xl:tw-divide-lightBlue-100'
  | 'sm:tw-divide-lightBlue-200'
  | 'md:tw-divide-lightBlue-200'
  | 'lg:tw-divide-lightBlue-200'
  | 'xl:tw-divide-lightBlue-200'
  | 'sm:tw-divide-lightBlue-300'
  | 'md:tw-divide-lightBlue-300'
  | 'lg:tw-divide-lightBlue-300'
  | 'xl:tw-divide-lightBlue-300'
  | 'sm:tw-divide-lightBlue-400'
  | 'md:tw-divide-lightBlue-400'
  | 'lg:tw-divide-lightBlue-400'
  | 'xl:tw-divide-lightBlue-400'
  | 'sm:tw-divide-lightBlue-500'
  | 'md:tw-divide-lightBlue-500'
  | 'lg:tw-divide-lightBlue-500'
  | 'xl:tw-divide-lightBlue-500'
  | 'sm:tw-divide-lightBlue-600'
  | 'md:tw-divide-lightBlue-600'
  | 'lg:tw-divide-lightBlue-600'
  | 'xl:tw-divide-lightBlue-600'
  | 'sm:tw-divide-lightBlue-700'
  | 'md:tw-divide-lightBlue-700'
  | 'lg:tw-divide-lightBlue-700'
  | 'xl:tw-divide-lightBlue-700'
  | 'sm:tw-divide-lightBlue-800'
  | 'md:tw-divide-lightBlue-800'
  | 'lg:tw-divide-lightBlue-800'
  | 'xl:tw-divide-lightBlue-800'
  | 'sm:tw-divide-lightBlue-900'
  | 'md:tw-divide-lightBlue-900'
  | 'lg:tw-divide-lightBlue-900'
  | 'xl:tw-divide-lightBlue-900'
  | 'sm:tw-divide-lightBlue-a100'
  | 'md:tw-divide-lightBlue-a100'
  | 'lg:tw-divide-lightBlue-a100'
  | 'xl:tw-divide-lightBlue-a100'
  | 'sm:tw-divide-lightBlue-a200'
  | 'md:tw-divide-lightBlue-a200'
  | 'lg:tw-divide-lightBlue-a200'
  | 'xl:tw-divide-lightBlue-a200'
  | 'sm:tw-divide-lightBlue-a400'
  | 'md:tw-divide-lightBlue-a400'
  | 'lg:tw-divide-lightBlue-a400'
  | 'xl:tw-divide-lightBlue-a400'
  | 'sm:tw-divide-lightBlue-a700'
  | 'md:tw-divide-lightBlue-a700'
  | 'lg:tw-divide-lightBlue-a700'
  | 'xl:tw-divide-lightBlue-a700'
  | 'sm:tw-divide-cyan-50'
  | 'md:tw-divide-cyan-50'
  | 'lg:tw-divide-cyan-50'
  | 'xl:tw-divide-cyan-50'
  | 'sm:tw-divide-cyan-100'
  | 'md:tw-divide-cyan-100'
  | 'lg:tw-divide-cyan-100'
  | 'xl:tw-divide-cyan-100'
  | 'sm:tw-divide-cyan-200'
  | 'md:tw-divide-cyan-200'
  | 'lg:tw-divide-cyan-200'
  | 'xl:tw-divide-cyan-200'
  | 'sm:tw-divide-cyan-300'
  | 'md:tw-divide-cyan-300'
  | 'lg:tw-divide-cyan-300'
  | 'xl:tw-divide-cyan-300'
  | 'sm:tw-divide-cyan-400'
  | 'md:tw-divide-cyan-400'
  | 'lg:tw-divide-cyan-400'
  | 'xl:tw-divide-cyan-400'
  | 'sm:tw-divide-cyan-500'
  | 'md:tw-divide-cyan-500'
  | 'lg:tw-divide-cyan-500'
  | 'xl:tw-divide-cyan-500'
  | 'sm:tw-divide-cyan-600'
  | 'md:tw-divide-cyan-600'
  | 'lg:tw-divide-cyan-600'
  | 'xl:tw-divide-cyan-600'
  | 'sm:tw-divide-cyan-700'
  | 'md:tw-divide-cyan-700'
  | 'lg:tw-divide-cyan-700'
  | 'xl:tw-divide-cyan-700'
  | 'sm:tw-divide-cyan-800'
  | 'md:tw-divide-cyan-800'
  | 'lg:tw-divide-cyan-800'
  | 'xl:tw-divide-cyan-800'
  | 'sm:tw-divide-cyan-900'
  | 'md:tw-divide-cyan-900'
  | 'lg:tw-divide-cyan-900'
  | 'xl:tw-divide-cyan-900'
  | 'sm:tw-divide-cyan-a100'
  | 'md:tw-divide-cyan-a100'
  | 'lg:tw-divide-cyan-a100'
  | 'xl:tw-divide-cyan-a100'
  | 'sm:tw-divide-cyan-a200'
  | 'md:tw-divide-cyan-a200'
  | 'lg:tw-divide-cyan-a200'
  | 'xl:tw-divide-cyan-a200'
  | 'sm:tw-divide-cyan-a400'
  | 'md:tw-divide-cyan-a400'
  | 'lg:tw-divide-cyan-a400'
  | 'xl:tw-divide-cyan-a400'
  | 'sm:tw-divide-cyan-a700'
  | 'md:tw-divide-cyan-a700'
  | 'lg:tw-divide-cyan-a700'
  | 'xl:tw-divide-cyan-a700'
  | 'sm:tw-divide-teal-50'
  | 'md:tw-divide-teal-50'
  | 'lg:tw-divide-teal-50'
  | 'xl:tw-divide-teal-50'
  | 'sm:tw-divide-teal-100'
  | 'md:tw-divide-teal-100'
  | 'lg:tw-divide-teal-100'
  | 'xl:tw-divide-teal-100'
  | 'sm:tw-divide-teal-200'
  | 'md:tw-divide-teal-200'
  | 'lg:tw-divide-teal-200'
  | 'xl:tw-divide-teal-200'
  | 'sm:tw-divide-teal-300'
  | 'md:tw-divide-teal-300'
  | 'lg:tw-divide-teal-300'
  | 'xl:tw-divide-teal-300'
  | 'sm:tw-divide-teal-400'
  | 'md:tw-divide-teal-400'
  | 'lg:tw-divide-teal-400'
  | 'xl:tw-divide-teal-400'
  | 'sm:tw-divide-teal-500'
  | 'md:tw-divide-teal-500'
  | 'lg:tw-divide-teal-500'
  | 'xl:tw-divide-teal-500'
  | 'sm:tw-divide-teal-600'
  | 'md:tw-divide-teal-600'
  | 'lg:tw-divide-teal-600'
  | 'xl:tw-divide-teal-600'
  | 'sm:tw-divide-teal-700'
  | 'md:tw-divide-teal-700'
  | 'lg:tw-divide-teal-700'
  | 'xl:tw-divide-teal-700'
  | 'sm:tw-divide-teal-800'
  | 'md:tw-divide-teal-800'
  | 'lg:tw-divide-teal-800'
  | 'xl:tw-divide-teal-800'
  | 'sm:tw-divide-teal-900'
  | 'md:tw-divide-teal-900'
  | 'lg:tw-divide-teal-900'
  | 'xl:tw-divide-teal-900'
  | 'sm:tw-divide-teal-a100'
  | 'md:tw-divide-teal-a100'
  | 'lg:tw-divide-teal-a100'
  | 'xl:tw-divide-teal-a100'
  | 'sm:tw-divide-teal-a200'
  | 'md:tw-divide-teal-a200'
  | 'lg:tw-divide-teal-a200'
  | 'xl:tw-divide-teal-a200'
  | 'sm:tw-divide-teal-a400'
  | 'md:tw-divide-teal-a400'
  | 'lg:tw-divide-teal-a400'
  | 'xl:tw-divide-teal-a400'
  | 'sm:tw-divide-teal-a700'
  | 'md:tw-divide-teal-a700'
  | 'lg:tw-divide-teal-a700'
  | 'xl:tw-divide-teal-a700'
  | 'sm:tw-divide-green-50'
  | 'md:tw-divide-green-50'
  | 'lg:tw-divide-green-50'
  | 'xl:tw-divide-green-50'
  | 'sm:tw-divide-green-100'
  | 'md:tw-divide-green-100'
  | 'lg:tw-divide-green-100'
  | 'xl:tw-divide-green-100'
  | 'sm:tw-divide-green-200'
  | 'md:tw-divide-green-200'
  | 'lg:tw-divide-green-200'
  | 'xl:tw-divide-green-200'
  | 'sm:tw-divide-green-300'
  | 'md:tw-divide-green-300'
  | 'lg:tw-divide-green-300'
  | 'xl:tw-divide-green-300'
  | 'sm:tw-divide-green-400'
  | 'md:tw-divide-green-400'
  | 'lg:tw-divide-green-400'
  | 'xl:tw-divide-green-400'
  | 'sm:tw-divide-green-500'
  | 'md:tw-divide-green-500'
  | 'lg:tw-divide-green-500'
  | 'xl:tw-divide-green-500'
  | 'sm:tw-divide-green-600'
  | 'md:tw-divide-green-600'
  | 'lg:tw-divide-green-600'
  | 'xl:tw-divide-green-600'
  | 'sm:tw-divide-green-700'
  | 'md:tw-divide-green-700'
  | 'lg:tw-divide-green-700'
  | 'xl:tw-divide-green-700'
  | 'sm:tw-divide-green-800'
  | 'md:tw-divide-green-800'
  | 'lg:tw-divide-green-800'
  | 'xl:tw-divide-green-800'
  | 'sm:tw-divide-green-900'
  | 'md:tw-divide-green-900'
  | 'lg:tw-divide-green-900'
  | 'xl:tw-divide-green-900'
  | 'sm:tw-divide-green-a100'
  | 'md:tw-divide-green-a100'
  | 'lg:tw-divide-green-a100'
  | 'xl:tw-divide-green-a100'
  | 'sm:tw-divide-green-a200'
  | 'md:tw-divide-green-a200'
  | 'lg:tw-divide-green-a200'
  | 'xl:tw-divide-green-a200'
  | 'sm:tw-divide-green-a400'
  | 'md:tw-divide-green-a400'
  | 'lg:tw-divide-green-a400'
  | 'xl:tw-divide-green-a400'
  | 'sm:tw-divide-green-a700'
  | 'md:tw-divide-green-a700'
  | 'lg:tw-divide-green-a700'
  | 'xl:tw-divide-green-a700'
  | 'sm:tw-divide-lightGreen-50'
  | 'md:tw-divide-lightGreen-50'
  | 'lg:tw-divide-lightGreen-50'
  | 'xl:tw-divide-lightGreen-50'
  | 'sm:tw-divide-lightGreen-100'
  | 'md:tw-divide-lightGreen-100'
  | 'lg:tw-divide-lightGreen-100'
  | 'xl:tw-divide-lightGreen-100'
  | 'sm:tw-divide-lightGreen-200'
  | 'md:tw-divide-lightGreen-200'
  | 'lg:tw-divide-lightGreen-200'
  | 'xl:tw-divide-lightGreen-200'
  | 'sm:tw-divide-lightGreen-300'
  | 'md:tw-divide-lightGreen-300'
  | 'lg:tw-divide-lightGreen-300'
  | 'xl:tw-divide-lightGreen-300'
  | 'sm:tw-divide-lightGreen-400'
  | 'md:tw-divide-lightGreen-400'
  | 'lg:tw-divide-lightGreen-400'
  | 'xl:tw-divide-lightGreen-400'
  | 'sm:tw-divide-lightGreen-500'
  | 'md:tw-divide-lightGreen-500'
  | 'lg:tw-divide-lightGreen-500'
  | 'xl:tw-divide-lightGreen-500'
  | 'sm:tw-divide-lightGreen-600'
  | 'md:tw-divide-lightGreen-600'
  | 'lg:tw-divide-lightGreen-600'
  | 'xl:tw-divide-lightGreen-600'
  | 'sm:tw-divide-lightGreen-700'
  | 'md:tw-divide-lightGreen-700'
  | 'lg:tw-divide-lightGreen-700'
  | 'xl:tw-divide-lightGreen-700'
  | 'sm:tw-divide-lightGreen-800'
  | 'md:tw-divide-lightGreen-800'
  | 'lg:tw-divide-lightGreen-800'
  | 'xl:tw-divide-lightGreen-800'
  | 'sm:tw-divide-lightGreen-900'
  | 'md:tw-divide-lightGreen-900'
  | 'lg:tw-divide-lightGreen-900'
  | 'xl:tw-divide-lightGreen-900'
  | 'sm:tw-divide-lightGreen-a100'
  | 'md:tw-divide-lightGreen-a100'
  | 'lg:tw-divide-lightGreen-a100'
  | 'xl:tw-divide-lightGreen-a100'
  | 'sm:tw-divide-lightGreen-a200'
  | 'md:tw-divide-lightGreen-a200'
  | 'lg:tw-divide-lightGreen-a200'
  | 'xl:tw-divide-lightGreen-a200'
  | 'sm:tw-divide-lightGreen-a400'
  | 'md:tw-divide-lightGreen-a400'
  | 'lg:tw-divide-lightGreen-a400'
  | 'xl:tw-divide-lightGreen-a400'
  | 'sm:tw-divide-lightGreen-a700'
  | 'md:tw-divide-lightGreen-a700'
  | 'lg:tw-divide-lightGreen-a700'
  | 'xl:tw-divide-lightGreen-a700'
  | 'sm:tw-divide-lime-50'
  | 'md:tw-divide-lime-50'
  | 'lg:tw-divide-lime-50'
  | 'xl:tw-divide-lime-50'
  | 'sm:tw-divide-lime-100'
  | 'md:tw-divide-lime-100'
  | 'lg:tw-divide-lime-100'
  | 'xl:tw-divide-lime-100'
  | 'sm:tw-divide-lime-200'
  | 'md:tw-divide-lime-200'
  | 'lg:tw-divide-lime-200'
  | 'xl:tw-divide-lime-200'
  | 'sm:tw-divide-lime-300'
  | 'md:tw-divide-lime-300'
  | 'lg:tw-divide-lime-300'
  | 'xl:tw-divide-lime-300'
  | 'sm:tw-divide-lime-400'
  | 'md:tw-divide-lime-400'
  | 'lg:tw-divide-lime-400'
  | 'xl:tw-divide-lime-400'
  | 'sm:tw-divide-lime-500'
  | 'md:tw-divide-lime-500'
  | 'lg:tw-divide-lime-500'
  | 'xl:tw-divide-lime-500'
  | 'sm:tw-divide-lime-600'
  | 'md:tw-divide-lime-600'
  | 'lg:tw-divide-lime-600'
  | 'xl:tw-divide-lime-600'
  | 'sm:tw-divide-lime-700'
  | 'md:tw-divide-lime-700'
  | 'lg:tw-divide-lime-700'
  | 'xl:tw-divide-lime-700'
  | 'sm:tw-divide-lime-800'
  | 'md:tw-divide-lime-800'
  | 'lg:tw-divide-lime-800'
  | 'xl:tw-divide-lime-800'
  | 'sm:tw-divide-lime-900'
  | 'md:tw-divide-lime-900'
  | 'lg:tw-divide-lime-900'
  | 'xl:tw-divide-lime-900'
  | 'sm:tw-divide-lime-a100'
  | 'md:tw-divide-lime-a100'
  | 'lg:tw-divide-lime-a100'
  | 'xl:tw-divide-lime-a100'
  | 'sm:tw-divide-lime-a200'
  | 'md:tw-divide-lime-a200'
  | 'lg:tw-divide-lime-a200'
  | 'xl:tw-divide-lime-a200'
  | 'sm:tw-divide-lime-a400'
  | 'md:tw-divide-lime-a400'
  | 'lg:tw-divide-lime-a400'
  | 'xl:tw-divide-lime-a400'
  | 'sm:tw-divide-lime-a700'
  | 'md:tw-divide-lime-a700'
  | 'lg:tw-divide-lime-a700'
  | 'xl:tw-divide-lime-a700'
  | 'sm:tw-divide-yellow-50'
  | 'md:tw-divide-yellow-50'
  | 'lg:tw-divide-yellow-50'
  | 'xl:tw-divide-yellow-50'
  | 'sm:tw-divide-yellow-100'
  | 'md:tw-divide-yellow-100'
  | 'lg:tw-divide-yellow-100'
  | 'xl:tw-divide-yellow-100'
  | 'sm:tw-divide-yellow-200'
  | 'md:tw-divide-yellow-200'
  | 'lg:tw-divide-yellow-200'
  | 'xl:tw-divide-yellow-200'
  | 'sm:tw-divide-yellow-300'
  | 'md:tw-divide-yellow-300'
  | 'lg:tw-divide-yellow-300'
  | 'xl:tw-divide-yellow-300'
  | 'sm:tw-divide-yellow-400'
  | 'md:tw-divide-yellow-400'
  | 'lg:tw-divide-yellow-400'
  | 'xl:tw-divide-yellow-400'
  | 'sm:tw-divide-yellow-500'
  | 'md:tw-divide-yellow-500'
  | 'lg:tw-divide-yellow-500'
  | 'xl:tw-divide-yellow-500'
  | 'sm:tw-divide-yellow-600'
  | 'md:tw-divide-yellow-600'
  | 'lg:tw-divide-yellow-600'
  | 'xl:tw-divide-yellow-600'
  | 'sm:tw-divide-yellow-700'
  | 'md:tw-divide-yellow-700'
  | 'lg:tw-divide-yellow-700'
  | 'xl:tw-divide-yellow-700'
  | 'sm:tw-divide-yellow-800'
  | 'md:tw-divide-yellow-800'
  | 'lg:tw-divide-yellow-800'
  | 'xl:tw-divide-yellow-800'
  | 'sm:tw-divide-yellow-900'
  | 'md:tw-divide-yellow-900'
  | 'lg:tw-divide-yellow-900'
  | 'xl:tw-divide-yellow-900'
  | 'sm:tw-divide-yellow-a100'
  | 'md:tw-divide-yellow-a100'
  | 'lg:tw-divide-yellow-a100'
  | 'xl:tw-divide-yellow-a100'
  | 'sm:tw-divide-yellow-a200'
  | 'md:tw-divide-yellow-a200'
  | 'lg:tw-divide-yellow-a200'
  | 'xl:tw-divide-yellow-a200'
  | 'sm:tw-divide-yellow-a400'
  | 'md:tw-divide-yellow-a400'
  | 'lg:tw-divide-yellow-a400'
  | 'xl:tw-divide-yellow-a400'
  | 'sm:tw-divide-yellow-a700'
  | 'md:tw-divide-yellow-a700'
  | 'lg:tw-divide-yellow-a700'
  | 'xl:tw-divide-yellow-a700'
  | 'sm:tw-divide-amber-50'
  | 'md:tw-divide-amber-50'
  | 'lg:tw-divide-amber-50'
  | 'xl:tw-divide-amber-50'
  | 'sm:tw-divide-amber-100'
  | 'md:tw-divide-amber-100'
  | 'lg:tw-divide-amber-100'
  | 'xl:tw-divide-amber-100'
  | 'sm:tw-divide-amber-200'
  | 'md:tw-divide-amber-200'
  | 'lg:tw-divide-amber-200'
  | 'xl:tw-divide-amber-200'
  | 'sm:tw-divide-amber-300'
  | 'md:tw-divide-amber-300'
  | 'lg:tw-divide-amber-300'
  | 'xl:tw-divide-amber-300'
  | 'sm:tw-divide-amber-400'
  | 'md:tw-divide-amber-400'
  | 'lg:tw-divide-amber-400'
  | 'xl:tw-divide-amber-400'
  | 'sm:tw-divide-amber-500'
  | 'md:tw-divide-amber-500'
  | 'lg:tw-divide-amber-500'
  | 'xl:tw-divide-amber-500'
  | 'sm:tw-divide-amber-600'
  | 'md:tw-divide-amber-600'
  | 'lg:tw-divide-amber-600'
  | 'xl:tw-divide-amber-600'
  | 'sm:tw-divide-amber-700'
  | 'md:tw-divide-amber-700'
  | 'lg:tw-divide-amber-700'
  | 'xl:tw-divide-amber-700'
  | 'sm:tw-divide-amber-800'
  | 'md:tw-divide-amber-800'
  | 'lg:tw-divide-amber-800'
  | 'xl:tw-divide-amber-800'
  | 'sm:tw-divide-amber-900'
  | 'md:tw-divide-amber-900'
  | 'lg:tw-divide-amber-900'
  | 'xl:tw-divide-amber-900'
  | 'sm:tw-divide-amber-a100'
  | 'md:tw-divide-amber-a100'
  | 'lg:tw-divide-amber-a100'
  | 'xl:tw-divide-amber-a100'
  | 'sm:tw-divide-amber-a200'
  | 'md:tw-divide-amber-a200'
  | 'lg:tw-divide-amber-a200'
  | 'xl:tw-divide-amber-a200'
  | 'sm:tw-divide-amber-a400'
  | 'md:tw-divide-amber-a400'
  | 'lg:tw-divide-amber-a400'
  | 'xl:tw-divide-amber-a400'
  | 'sm:tw-divide-amber-a700'
  | 'md:tw-divide-amber-a700'
  | 'lg:tw-divide-amber-a700'
  | 'xl:tw-divide-amber-a700'
  | 'sm:tw-divide-orange-50'
  | 'md:tw-divide-orange-50'
  | 'lg:tw-divide-orange-50'
  | 'xl:tw-divide-orange-50'
  | 'sm:tw-divide-orange-100'
  | 'md:tw-divide-orange-100'
  | 'lg:tw-divide-orange-100'
  | 'xl:tw-divide-orange-100'
  | 'sm:tw-divide-orange-200'
  | 'md:tw-divide-orange-200'
  | 'lg:tw-divide-orange-200'
  | 'xl:tw-divide-orange-200'
  | 'sm:tw-divide-orange-300'
  | 'md:tw-divide-orange-300'
  | 'lg:tw-divide-orange-300'
  | 'xl:tw-divide-orange-300'
  | 'sm:tw-divide-orange-400'
  | 'md:tw-divide-orange-400'
  | 'lg:tw-divide-orange-400'
  | 'xl:tw-divide-orange-400'
  | 'sm:tw-divide-orange-500'
  | 'md:tw-divide-orange-500'
  | 'lg:tw-divide-orange-500'
  | 'xl:tw-divide-orange-500'
  | 'sm:tw-divide-orange-600'
  | 'md:tw-divide-orange-600'
  | 'lg:tw-divide-orange-600'
  | 'xl:tw-divide-orange-600'
  | 'sm:tw-divide-orange-700'
  | 'md:tw-divide-orange-700'
  | 'lg:tw-divide-orange-700'
  | 'xl:tw-divide-orange-700'
  | 'sm:tw-divide-orange-800'
  | 'md:tw-divide-orange-800'
  | 'lg:tw-divide-orange-800'
  | 'xl:tw-divide-orange-800'
  | 'sm:tw-divide-orange-900'
  | 'md:tw-divide-orange-900'
  | 'lg:tw-divide-orange-900'
  | 'xl:tw-divide-orange-900'
  | 'sm:tw-divide-orange-a100'
  | 'md:tw-divide-orange-a100'
  | 'lg:tw-divide-orange-a100'
  | 'xl:tw-divide-orange-a100'
  | 'sm:tw-divide-orange-a200'
  | 'md:tw-divide-orange-a200'
  | 'lg:tw-divide-orange-a200'
  | 'xl:tw-divide-orange-a200'
  | 'sm:tw-divide-orange-a400'
  | 'md:tw-divide-orange-a400'
  | 'lg:tw-divide-orange-a400'
  | 'xl:tw-divide-orange-a400'
  | 'sm:tw-divide-orange-a700'
  | 'md:tw-divide-orange-a700'
  | 'lg:tw-divide-orange-a700'
  | 'xl:tw-divide-orange-a700'
  | 'sm:tw-divide-deepOrange-50'
  | 'md:tw-divide-deepOrange-50'
  | 'lg:tw-divide-deepOrange-50'
  | 'xl:tw-divide-deepOrange-50'
  | 'sm:tw-divide-deepOrange-100'
  | 'md:tw-divide-deepOrange-100'
  | 'lg:tw-divide-deepOrange-100'
  | 'xl:tw-divide-deepOrange-100'
  | 'sm:tw-divide-deepOrange-200'
  | 'md:tw-divide-deepOrange-200'
  | 'lg:tw-divide-deepOrange-200'
  | 'xl:tw-divide-deepOrange-200'
  | 'sm:tw-divide-deepOrange-300'
  | 'md:tw-divide-deepOrange-300'
  | 'lg:tw-divide-deepOrange-300'
  | 'xl:tw-divide-deepOrange-300'
  | 'sm:tw-divide-deepOrange-400'
  | 'md:tw-divide-deepOrange-400'
  | 'lg:tw-divide-deepOrange-400'
  | 'xl:tw-divide-deepOrange-400'
  | 'sm:tw-divide-deepOrange-500'
  | 'md:tw-divide-deepOrange-500'
  | 'lg:tw-divide-deepOrange-500'
  | 'xl:tw-divide-deepOrange-500'
  | 'sm:tw-divide-deepOrange-600'
  | 'md:tw-divide-deepOrange-600'
  | 'lg:tw-divide-deepOrange-600'
  | 'xl:tw-divide-deepOrange-600'
  | 'sm:tw-divide-deepOrange-700'
  | 'md:tw-divide-deepOrange-700'
  | 'lg:tw-divide-deepOrange-700'
  | 'xl:tw-divide-deepOrange-700'
  | 'sm:tw-divide-deepOrange-800'
  | 'md:tw-divide-deepOrange-800'
  | 'lg:tw-divide-deepOrange-800'
  | 'xl:tw-divide-deepOrange-800'
  | 'sm:tw-divide-deepOrange-900'
  | 'md:tw-divide-deepOrange-900'
  | 'lg:tw-divide-deepOrange-900'
  | 'xl:tw-divide-deepOrange-900'
  | 'sm:tw-divide-deepOrange-a100'
  | 'md:tw-divide-deepOrange-a100'
  | 'lg:tw-divide-deepOrange-a100'
  | 'xl:tw-divide-deepOrange-a100'
  | 'sm:tw-divide-deepOrange-a200'
  | 'md:tw-divide-deepOrange-a200'
  | 'lg:tw-divide-deepOrange-a200'
  | 'xl:tw-divide-deepOrange-a200'
  | 'sm:tw-divide-deepOrange-a400'
  | 'md:tw-divide-deepOrange-a400'
  | 'lg:tw-divide-deepOrange-a400'
  | 'xl:tw-divide-deepOrange-a400'
  | 'sm:tw-divide-deepOrange-a700'
  | 'md:tw-divide-deepOrange-a700'
  | 'lg:tw-divide-deepOrange-a700'
  | 'xl:tw-divide-deepOrange-a700'
  | 'sm:tw-divide-brown-50'
  | 'md:tw-divide-brown-50'
  | 'lg:tw-divide-brown-50'
  | 'xl:tw-divide-brown-50'
  | 'sm:tw-divide-brown-100'
  | 'md:tw-divide-brown-100'
  | 'lg:tw-divide-brown-100'
  | 'xl:tw-divide-brown-100'
  | 'sm:tw-divide-brown-200'
  | 'md:tw-divide-brown-200'
  | 'lg:tw-divide-brown-200'
  | 'xl:tw-divide-brown-200'
  | 'sm:tw-divide-brown-300'
  | 'md:tw-divide-brown-300'
  | 'lg:tw-divide-brown-300'
  | 'xl:tw-divide-brown-300'
  | 'sm:tw-divide-brown-400'
  | 'md:tw-divide-brown-400'
  | 'lg:tw-divide-brown-400'
  | 'xl:tw-divide-brown-400'
  | 'sm:tw-divide-brown-500'
  | 'md:tw-divide-brown-500'
  | 'lg:tw-divide-brown-500'
  | 'xl:tw-divide-brown-500'
  | 'sm:tw-divide-brown-600'
  | 'md:tw-divide-brown-600'
  | 'lg:tw-divide-brown-600'
  | 'xl:tw-divide-brown-600'
  | 'sm:tw-divide-brown-700'
  | 'md:tw-divide-brown-700'
  | 'lg:tw-divide-brown-700'
  | 'xl:tw-divide-brown-700'
  | 'sm:tw-divide-brown-800'
  | 'md:tw-divide-brown-800'
  | 'lg:tw-divide-brown-800'
  | 'xl:tw-divide-brown-800'
  | 'sm:tw-divide-brown-900'
  | 'md:tw-divide-brown-900'
  | 'lg:tw-divide-brown-900'
  | 'xl:tw-divide-brown-900'
  | 'sm:tw-divide-gray-50'
  | 'md:tw-divide-gray-50'
  | 'lg:tw-divide-gray-50'
  | 'xl:tw-divide-gray-50'
  | 'sm:tw-divide-gray-100'
  | 'md:tw-divide-gray-100'
  | 'lg:tw-divide-gray-100'
  | 'xl:tw-divide-gray-100'
  | 'sm:tw-divide-gray-200'
  | 'md:tw-divide-gray-200'
  | 'lg:tw-divide-gray-200'
  | 'xl:tw-divide-gray-200'
  | 'sm:tw-divide-gray-300'
  | 'md:tw-divide-gray-300'
  | 'lg:tw-divide-gray-300'
  | 'xl:tw-divide-gray-300'
  | 'sm:tw-divide-gray-400'
  | 'md:tw-divide-gray-400'
  | 'lg:tw-divide-gray-400'
  | 'xl:tw-divide-gray-400'
  | 'sm:tw-divide-gray-500'
  | 'md:tw-divide-gray-500'
  | 'lg:tw-divide-gray-500'
  | 'xl:tw-divide-gray-500'
  | 'sm:tw-divide-gray-600'
  | 'md:tw-divide-gray-600'
  | 'lg:tw-divide-gray-600'
  | 'xl:tw-divide-gray-600'
  | 'sm:tw-divide-gray-700'
  | 'md:tw-divide-gray-700'
  | 'lg:tw-divide-gray-700'
  | 'xl:tw-divide-gray-700'
  | 'sm:tw-divide-gray-800'
  | 'md:tw-divide-gray-800'
  | 'lg:tw-divide-gray-800'
  | 'xl:tw-divide-gray-800'
  | 'sm:tw-divide-gray-900'
  | 'md:tw-divide-gray-900'
  | 'lg:tw-divide-gray-900'
  | 'xl:tw-divide-gray-900'
  | 'sm:tw-divide-blueGray-50'
  | 'md:tw-divide-blueGray-50'
  | 'lg:tw-divide-blueGray-50'
  | 'xl:tw-divide-blueGray-50'
  | 'sm:tw-divide-blueGray-100'
  | 'md:tw-divide-blueGray-100'
  | 'lg:tw-divide-blueGray-100'
  | 'xl:tw-divide-blueGray-100'
  | 'sm:tw-divide-blueGray-200'
  | 'md:tw-divide-blueGray-200'
  | 'lg:tw-divide-blueGray-200'
  | 'xl:tw-divide-blueGray-200'
  | 'sm:tw-divide-blueGray-300'
  | 'md:tw-divide-blueGray-300'
  | 'lg:tw-divide-blueGray-300'
  | 'xl:tw-divide-blueGray-300'
  | 'sm:tw-divide-blueGray-400'
  | 'md:tw-divide-blueGray-400'
  | 'lg:tw-divide-blueGray-400'
  | 'xl:tw-divide-blueGray-400'
  | 'sm:tw-divide-blueGray-500'
  | 'md:tw-divide-blueGray-500'
  | 'lg:tw-divide-blueGray-500'
  | 'xl:tw-divide-blueGray-500'
  | 'sm:tw-divide-blueGray-600'
  | 'md:tw-divide-blueGray-600'
  | 'lg:tw-divide-blueGray-600'
  | 'xl:tw-divide-blueGray-600'
  | 'sm:tw-divide-blueGray-700'
  | 'md:tw-divide-blueGray-700'
  | 'lg:tw-divide-blueGray-700'
  | 'xl:tw-divide-blueGray-700'
  | 'sm:tw-divide-blueGray-800'
  | 'md:tw-divide-blueGray-800'
  | 'lg:tw-divide-blueGray-800'
  | 'xl:tw-divide-blueGray-800'
  | 'sm:tw-divide-blueGray-900'
  | 'md:tw-divide-blueGray-900'
  | 'lg:tw-divide-blueGray-900'
  | 'xl:tw-divide-blueGray-900'
  | 'sm:tw-divide-black'
  | 'md:tw-divide-black'
  | 'lg:tw-divide-black'
  | 'xl:tw-divide-black'
  | 'sm:tw-divide-white'
  | 'md:tw-divide-white'
  | 'lg:tw-divide-white'
  | 'xl:tw-divide-white'
  | 'sm:tw-divide-transparent'
  | 'md:tw-divide-transparent'
  | 'lg:tw-divide-transparent'
  | 'xl:tw-divide-transparent'
  | 'sm:tw-divide-opacity-0'
  | 'md:tw-divide-opacity-0'
  | 'lg:tw-divide-opacity-0'
  | 'xl:tw-divide-opacity-0'
  | 'sm:tw-divide-opacity-25'
  | 'md:tw-divide-opacity-25'
  | 'lg:tw-divide-opacity-25'
  | 'xl:tw-divide-opacity-25'
  | 'sm:tw-divide-opacity-50'
  | 'md:tw-divide-opacity-50'
  | 'lg:tw-divide-opacity-50'
  | 'xl:tw-divide-opacity-50'
  | 'sm:tw-divide-opacity-75'
  | 'md:tw-divide-opacity-75'
  | 'lg:tw-divide-opacity-75'
  | 'xl:tw-divide-opacity-75'
  | 'sm:tw-divide-opacity-100'
  | 'md:tw-divide-opacity-100'
  | 'lg:tw-divide-opacity-100'
  | 'xl:tw-divide-opacity-100'
  | 'sm:tw-divide-solid'
  | 'md:tw-divide-solid'
  | 'lg:tw-divide-solid'
  | 'xl:tw-divide-solid'
  | 'sm:tw-divide-dashed'
  | 'md:tw-divide-dashed'
  | 'lg:tw-divide-dashed'
  | 'xl:tw-divide-dashed'
  | 'sm:tw-divide-dotted'
  | 'md:tw-divide-dotted'
  | 'lg:tw-divide-dotted'
  | 'xl:tw-divide-dotted'
  | 'sm:tw-divide-double'
  | 'md:tw-divide-double'
  | 'lg:tw-divide-double'
  | 'xl:tw-divide-double'
  | 'sm:tw-divide-none'
  | 'md:tw-divide-none'
  | 'lg:tw-divide-none'
  | 'xl:tw-divide-none'
  | 'sm:tw-divide-x-0'
  | 'md:tw-divide-x-0'
  | 'lg:tw-divide-x-0'
  | 'xl:tw-divide-x-0'
  | 'sm:tw-divide-y-0'
  | 'md:tw-divide-y-0'
  | 'lg:tw-divide-y-0'
  | 'xl:tw-divide-y-0'
  | 'sm:tw-divide-x-2'
  | 'md:tw-divide-x-2'
  | 'lg:tw-divide-x-2'
  | 'xl:tw-divide-x-2'
  | 'sm:tw-divide-y-2'
  | 'md:tw-divide-y-2'
  | 'lg:tw-divide-y-2'
  | 'xl:tw-divide-y-2'
  | 'sm:tw-divide-x-4'
  | 'md:tw-divide-x-4'
  | 'lg:tw-divide-x-4'
  | 'xl:tw-divide-x-4'
  | 'sm:tw-divide-y-4'
  | 'md:tw-divide-y-4'
  | 'lg:tw-divide-y-4'
  | 'xl:tw-divide-y-4'
  | 'sm:tw-divide-x-8'
  | 'md:tw-divide-x-8'
  | 'lg:tw-divide-x-8'
  | 'xl:tw-divide-x-8'
  | 'sm:tw-divide-y-8'
  | 'md:tw-divide-y-8'
  | 'lg:tw-divide-y-8'
  | 'xl:tw-divide-y-8'
  | 'sm:tw-divide-x'
  | 'md:tw-divide-x'
  | 'lg:tw-divide-x'
  | 'xl:tw-divide-x'
  | 'sm:tw-divide-y'
  | 'md:tw-divide-y'
  | 'lg:tw-divide-y'
  | 'xl:tw-divide-y'
  | 'sm:tw-divide-x-reverse'
  | 'md:tw-divide-x-reverse'
  | 'lg:tw-divide-x-reverse'
  | 'xl:tw-divide-x-reverse'
  | 'sm:tw-divide-y-reverse'
  | 'md:tw-divide-y-reverse'
  | 'lg:tw-divide-y-reverse'
  | 'xl:tw-divide-y-reverse'
  | 'sm:tw-fill-current'
  | 'md:tw-fill-current'
  | 'lg:tw-fill-current'
  | 'xl:tw-fill-current'
  | 'sm:tw-flex-initial'
  | 'md:tw-flex-initial'
  | 'lg:tw-flex-initial'
  | 'xl:tw-flex-initial'
  | 'sm:tw-flex-1'
  | 'md:tw-flex-1'
  | 'lg:tw-flex-1'
  | 'xl:tw-flex-1'
  | 'sm:tw-flex-auto'
  | 'md:tw-flex-auto'
  | 'lg:tw-flex-auto'
  | 'xl:tw-flex-auto'
  | 'sm:tw-flex-none'
  | 'md:tw-flex-none'
  | 'lg:tw-flex-none'
  | 'xl:tw-flex-none'
  | 'sm:tw-flex-row'
  | 'md:tw-flex-row'
  | 'lg:tw-flex-row'
  | 'xl:tw-flex-row'
  | 'sm:tw-flex-row-reverse'
  | 'md:tw-flex-row-reverse'
  | 'lg:tw-flex-row-reverse'
  | 'xl:tw-flex-row-reverse'
  | 'sm:tw-flex-col'
  | 'md:tw-flex-col'
  | 'lg:tw-flex-col'
  | 'xl:tw-flex-col'
  | 'sm:tw-flex-col-reverse'
  | 'md:tw-flex-col-reverse'
  | 'lg:tw-flex-col-reverse'
  | 'xl:tw-flex-col-reverse'
  | 'sm:tw-flex-grow-0'
  | 'md:tw-flex-grow-0'
  | 'lg:tw-flex-grow-0'
  | 'xl:tw-flex-grow-0'
  | 'sm:tw-flex-grow'
  | 'md:tw-flex-grow'
  | 'lg:tw-flex-grow'
  | 'xl:tw-flex-grow'
  | 'sm:tw-flex-shrink-0'
  | 'md:tw-flex-shrink-0'
  | 'lg:tw-flex-shrink-0'
  | 'xl:tw-flex-shrink-0'
  | 'sm:tw-flex-shrink'
  | 'md:tw-flex-shrink'
  | 'lg:tw-flex-shrink'
  | 'xl:tw-flex-shrink'
  | 'sm:tw-flex-no-wrap'
  | 'md:tw-flex-no-wrap'
  | 'lg:tw-flex-no-wrap'
  | 'xl:tw-flex-no-wrap'
  | 'sm:tw-flex-wrap'
  | 'md:tw-flex-wrap'
  | 'lg:tw-flex-wrap'
  | 'xl:tw-flex-wrap'
  | 'sm:tw-flex-wrap-reverse'
  | 'md:tw-flex-wrap-reverse'
  | 'lg:tw-flex-wrap-reverse'
  | 'xl:tw-flex-wrap-reverse'
  | 'sm:tw-float-right'
  | 'md:tw-float-right'
  | 'lg:tw-float-right'
  | 'xl:tw-float-right'
  | 'sm:tw-float-left'
  | 'md:tw-float-left'
  | 'lg:tw-float-left'
  | 'xl:tw-float-left'
  | 'sm:tw-float-none'
  | 'md:tw-float-none'
  | 'lg:tw-float-none'
  | 'xl:tw-float-none'
  | 'sm:tw-clearfix'
  | 'md:tw-clearfix'
  | 'lg:tw-clearfix'
  | 'xl:tw-clearfix'
  | 'sm:tw-clear-left'
  | 'md:tw-clear-left'
  | 'lg:tw-clear-left'
  | 'xl:tw-clear-left'
  | 'sm:tw-clear-right'
  | 'md:tw-clear-right'
  | 'lg:tw-clear-right'
  | 'xl:tw-clear-right'
  | 'sm:tw-clear-both'
  | 'md:tw-clear-both'
  | 'lg:tw-clear-both'
  | 'xl:tw-clear-both'
  | 'sm:tw-clear-none'
  | 'md:tw-clear-none'
  | 'lg:tw-clear-none'
  | 'xl:tw-clear-none'
  | 'sm:tw-font-sans'
  | 'md:tw-font-sans'
  | 'lg:tw-font-sans'
  | 'xl:tw-font-sans'
  | 'sm:tw-font-mono'
  | 'md:tw-font-mono'
  | 'lg:tw-font-mono'
  | 'xl:tw-font-mono'
  | 'sm:tw-text-xs'
  | 'md:tw-text-xs'
  | 'lg:tw-text-xs'
  | 'xl:tw-text-xs'
  | 'sm:tw-text-sm'
  | 'md:tw-text-sm'
  | 'lg:tw-text-sm'
  | 'xl:tw-text-sm'
  | 'sm:tw-text-base'
  | 'md:tw-text-base'
  | 'lg:tw-text-base'
  | 'xl:tw-text-base'
  | 'sm:tw-text-lg'
  | 'md:tw-text-lg'
  | 'lg:tw-text-lg'
  | 'xl:tw-text-lg'
  | 'sm:tw-text-xl'
  | 'md:tw-text-xl'
  | 'lg:tw-text-xl'
  | 'xl:tw-text-xl'
  | 'sm:tw-text-2xl'
  | 'md:tw-text-2xl'
  | 'lg:tw-text-2xl'
  | 'xl:tw-text-2xl'
  | 'sm:tw-text-3xl'
  | 'md:tw-text-3xl'
  | 'lg:tw-text-3xl'
  | 'xl:tw-text-3xl'
  | 'sm:tw-text-4xl'
  | 'md:tw-text-4xl'
  | 'lg:tw-text-4xl'
  | 'xl:tw-text-4xl'
  | 'sm:tw-text-5xl'
  | 'md:tw-text-5xl'
  | 'lg:tw-text-5xl'
  | 'xl:tw-text-5xl'
  | 'sm:tw-text-6xl'
  | 'md:tw-text-6xl'
  | 'lg:tw-text-6xl'
  | 'xl:tw-text-6xl'
  | 'sm:tw-antialiased'
  | 'md:tw-antialiased'
  | 'lg:tw-antialiased'
  | 'xl:tw-antialiased'
  | 'sm:tw-subpixel-antialiased'
  | 'md:tw-subpixel-antialiased'
  | 'lg:tw-subpixel-antialiased'
  | 'xl:tw-subpixel-antialiased'
  | 'sm:tw-normal-nums'
  | 'md:tw-normal-nums'
  | 'lg:tw-normal-nums'
  | 'xl:tw-normal-nums'
  | 'sm:tw-ordinal'
  | 'md:tw-ordinal'
  | 'lg:tw-ordinal'
  | 'xl:tw-ordinal'
  | 'sm:tw-slashed-zero'
  | 'md:tw-slashed-zero'
  | 'lg:tw-slashed-zero'
  | 'xl:tw-slashed-zero'
  | 'sm:tw-lining-nums'
  | 'md:tw-lining-nums'
  | 'lg:tw-lining-nums'
  | 'xl:tw-lining-nums'
  | 'sm:tw-oldstyle-nums'
  | 'md:tw-oldstyle-nums'
  | 'lg:tw-oldstyle-nums'
  | 'xl:tw-oldstyle-nums'
  | 'sm:tw-proportional-nums'
  | 'md:tw-proportional-nums'
  | 'lg:tw-proportional-nums'
  | 'xl:tw-proportional-nums'
  | 'sm:tw-tabular-nums'
  | 'md:tw-tabular-nums'
  | 'lg:tw-tabular-nums'
  | 'xl:tw-tabular-nums'
  | 'sm:tw-diagonal-fractions'
  | 'md:tw-diagonal-fractions'
  | 'lg:tw-diagonal-fractions'
  | 'xl:tw-diagonal-fractions'
  | 'sm:tw-stacked-fractions'
  | 'md:tw-stacked-fractions'
  | 'lg:tw-stacked-fractions'
  | 'xl:tw-stacked-fractions'
  | 'sm:tw-italic'
  | 'md:tw-italic'
  | 'lg:tw-italic'
  | 'xl:tw-italic'
  | 'sm:tw-non-italic'
  | 'md:tw-non-italic'
  | 'lg:tw-non-italic'
  | 'xl:tw-non-italic'
  | 'sm:tw-font-hairline'
  | 'md:tw-font-hairline'
  | 'lg:tw-font-hairline'
  | 'xl:tw-font-hairline'
  | 'hover:tw-font-hairline'
  | 'focus:tw-font-hairline'
  | 'sm:tw-font-thin'
  | 'md:tw-font-thin'
  | 'lg:tw-font-thin'
  | 'xl:tw-font-thin'
  | 'hover:tw-font-thin'
  | 'focus:tw-font-thin'
  | 'sm:tw-font-light'
  | 'md:tw-font-light'
  | 'lg:tw-font-light'
  | 'xl:tw-font-light'
  | 'hover:tw-font-light'
  | 'focus:tw-font-light'
  | 'sm:tw-font-normal'
  | 'md:tw-font-normal'
  | 'lg:tw-font-normal'
  | 'xl:tw-font-normal'
  | 'hover:tw-font-normal'
  | 'focus:tw-font-normal'
  | 'sm:tw-font-medium'
  | 'md:tw-font-medium'
  | 'lg:tw-font-medium'
  | 'xl:tw-font-medium'
  | 'hover:tw-font-medium'
  | 'focus:tw-font-medium'
  | 'sm:tw-font-semibold'
  | 'md:tw-font-semibold'
  | 'lg:tw-font-semibold'
  | 'xl:tw-font-semibold'
  | 'hover:tw-font-semibold'
  | 'focus:tw-font-semibold'
  | 'sm:tw-font-bold'
  | 'md:tw-font-bold'
  | 'lg:tw-font-bold'
  | 'xl:tw-font-bold'
  | 'hover:tw-font-bold'
  | 'focus:tw-font-bold'
  | 'sm:tw-font-extrabold'
  | 'md:tw-font-extrabold'
  | 'lg:tw-font-extrabold'
  | 'xl:tw-font-extrabold'
  | 'hover:tw-font-extrabold'
  | 'focus:tw-font-extrabold'
  | 'sm:tw-font-black'
  | 'md:tw-font-black'
  | 'lg:tw-font-black'
  | 'xl:tw-font-black'
  | 'hover:tw-font-black'
  | 'focus:tw-font-black'
  | 'sm:tw-h-0'
  | 'md:tw-h-0'
  | 'lg:tw-h-0'
  | 'xl:tw-h-0'
  | 'sm:tw-h-1'
  | 'md:tw-h-1'
  | 'lg:tw-h-1'
  | 'xl:tw-h-1'
  | 'sm:tw-h-2'
  | 'md:tw-h-2'
  | 'lg:tw-h-2'
  | 'xl:tw-h-2'
  | 'sm:tw-h-3'
  | 'md:tw-h-3'
  | 'lg:tw-h-3'
  | 'xl:tw-h-3'
  | 'sm:tw-h-4'
  | 'md:tw-h-4'
  | 'lg:tw-h-4'
  | 'xl:tw-h-4'
  | 'sm:tw-h-5'
  | 'md:tw-h-5'
  | 'lg:tw-h-5'
  | 'xl:tw-h-5'
  | 'sm:tw-h-6'
  | 'md:tw-h-6'
  | 'lg:tw-h-6'
  | 'xl:tw-h-6'
  | 'sm:tw-h-8'
  | 'md:tw-h-8'
  | 'lg:tw-h-8'
  | 'xl:tw-h-8'
  | 'sm:tw-h-10'
  | 'md:tw-h-10'
  | 'lg:tw-h-10'
  | 'xl:tw-h-10'
  | 'sm:tw-h-12'
  | 'md:tw-h-12'
  | 'lg:tw-h-12'
  | 'xl:tw-h-12'
  | 'sm:tw-h-16'
  | 'md:tw-h-16'
  | 'lg:tw-h-16'
  | 'xl:tw-h-16'
  | 'sm:tw-h-20'
  | 'md:tw-h-20'
  | 'lg:tw-h-20'
  | 'xl:tw-h-20'
  | 'sm:tw-h-24'
  | 'md:tw-h-24'
  | 'lg:tw-h-24'
  | 'xl:tw-h-24'
  | 'sm:tw-h-32'
  | 'md:tw-h-32'
  | 'lg:tw-h-32'
  | 'xl:tw-h-32'
  | 'sm:tw-h-40'
  | 'md:tw-h-40'
  | 'lg:tw-h-40'
  | 'xl:tw-h-40'
  | 'sm:tw-h-48'
  | 'md:tw-h-48'
  | 'lg:tw-h-48'
  | 'xl:tw-h-48'
  | 'sm:tw-h-56'
  | 'md:tw-h-56'
  | 'lg:tw-h-56'
  | 'xl:tw-h-56'
  | 'sm:tw-h-64'
  | 'md:tw-h-64'
  | 'lg:tw-h-64'
  | 'xl:tw-h-64'
  | 'sm:tw-h-auto'
  | 'md:tw-h-auto'
  | 'lg:tw-h-auto'
  | 'xl:tw-h-auto'
  | 'sm:tw-h-px'
  | 'md:tw-h-px'
  | 'lg:tw-h-px'
  | 'xl:tw-h-px'
  | 'sm:tw-h-full'
  | 'md:tw-h-full'
  | 'lg:tw-h-full'
  | 'xl:tw-h-full'
  | 'sm:tw-h-screen'
  | 'md:tw-h-screen'
  | 'lg:tw-h-screen'
  | 'xl:tw-h-screen'
  | 'sm:tw-inset-0'
  | 'md:tw-inset-0'
  | 'lg:tw-inset-0'
  | 'xl:tw-inset-0'
  | 'sm:tw-inset-x-0'
  | 'md:tw-inset-x-0'
  | 'lg:tw-inset-x-0'
  | 'xl:tw-inset-x-0'
  | 'sm:tw-inset-y-0'
  | 'md:tw-inset-y-0'
  | 'lg:tw-inset-y-0'
  | 'xl:tw-inset-y-0'
  | 'sm:tw-top-0'
  | 'md:tw-top-0'
  | 'lg:tw-top-0'
  | 'xl:tw-top-0'
  | 'sm:tw-right-0'
  | 'md:tw-right-0'
  | 'lg:tw-right-0'
  | 'xl:tw-right-0'
  | 'sm:tw-bottom-0'
  | 'md:tw-bottom-0'
  | 'lg:tw-bottom-0'
  | 'xl:tw-bottom-0'
  | 'sm:tw-left-0'
  | 'md:tw-left-0'
  | 'lg:tw-left-0'
  | 'xl:tw-left-0'
  | 'sm:tw-inset-auto'
  | 'md:tw-inset-auto'
  | 'lg:tw-inset-auto'
  | 'xl:tw-inset-auto'
  | 'sm:tw-inset-x-auto'
  | 'md:tw-inset-x-auto'
  | 'lg:tw-inset-x-auto'
  | 'xl:tw-inset-x-auto'
  | 'sm:tw-inset-y-auto'
  | 'md:tw-inset-y-auto'
  | 'lg:tw-inset-y-auto'
  | 'xl:tw-inset-y-auto'
  | 'sm:tw-top-auto'
  | 'md:tw-top-auto'
  | 'lg:tw-top-auto'
  | 'xl:tw-top-auto'
  | 'sm:tw-right-auto'
  | 'md:tw-right-auto'
  | 'lg:tw-right-auto'
  | 'xl:tw-right-auto'
  | 'sm:tw-bottom-auto'
  | 'md:tw-bottom-auto'
  | 'lg:tw-bottom-auto'
  | 'xl:tw-bottom-auto'
  | 'sm:tw-left-auto'
  | 'md:tw-left-auto'
  | 'lg:tw-left-auto'
  | 'xl:tw-left-auto'
  | 'sm:tw--inset-1'
  | 'md:tw--inset-1'
  | 'lg:tw--inset-1'
  | 'xl:tw--inset-1'
  | 'sm:tw--inset-x-1'
  | 'md:tw--inset-x-1'
  | 'lg:tw--inset-x-1'
  | 'xl:tw--inset-x-1'
  | 'sm:tw--inset-y-1'
  | 'md:tw--inset-y-1'
  | 'lg:tw--inset-y-1'
  | 'xl:tw--inset-y-1'
  | 'sm:tw--top-1'
  | 'md:tw--top-1'
  | 'lg:tw--top-1'
  | 'xl:tw--top-1'
  | 'sm:tw--right-1'
  | 'md:tw--right-1'
  | 'lg:tw--right-1'
  | 'xl:tw--right-1'
  | 'sm:tw--bottom-1'
  | 'md:tw--bottom-1'
  | 'lg:tw--bottom-1'
  | 'xl:tw--bottom-1'
  | 'sm:tw--left-1'
  | 'md:tw--left-1'
  | 'lg:tw--left-1'
  | 'xl:tw--left-1'
  | 'sm:tw--inset-2'
  | 'md:tw--inset-2'
  | 'lg:tw--inset-2'
  | 'xl:tw--inset-2'
  | 'sm:tw--inset-x-2'
  | 'md:tw--inset-x-2'
  | 'lg:tw--inset-x-2'
  | 'xl:tw--inset-x-2'
  | 'sm:tw--inset-y-2'
  | 'md:tw--inset-y-2'
  | 'lg:tw--inset-y-2'
  | 'xl:tw--inset-y-2'
  | 'sm:tw--top-2'
  | 'md:tw--top-2'
  | 'lg:tw--top-2'
  | 'xl:tw--top-2'
  | 'sm:tw--right-2'
  | 'md:tw--right-2'
  | 'lg:tw--right-2'
  | 'xl:tw--right-2'
  | 'sm:tw--bottom-2'
  | 'md:tw--bottom-2'
  | 'lg:tw--bottom-2'
  | 'xl:tw--bottom-2'
  | 'sm:tw--left-2'
  | 'md:tw--left-2'
  | 'lg:tw--left-2'
  | 'xl:tw--left-2'
  | 'sm:tw-justify-start'
  | 'md:tw-justify-start'
  | 'lg:tw-justify-start'
  | 'xl:tw-justify-start'
  | 'sm:tw-justify-center'
  | 'md:tw-justify-center'
  | 'lg:tw-justify-center'
  | 'xl:tw-justify-center'
  | 'sm:tw-justify-end'
  | 'md:tw-justify-end'
  | 'lg:tw-justify-end'
  | 'xl:tw-justify-end'
  | 'sm:tw-justify-between'
  | 'md:tw-justify-between'
  | 'lg:tw-justify-between'
  | 'xl:tw-justify-between'
  | 'sm:tw-justify-around'
  | 'md:tw-justify-around'
  | 'lg:tw-justify-around'
  | 'xl:tw-justify-around'
  | 'sm:tw-justify-items-auto'
  | 'md:tw-justify-items-auto'
  | 'lg:tw-justify-items-auto'
  | 'xl:tw-justify-items-auto'
  | 'sm:tw-justify-items-start'
  | 'md:tw-justify-items-start'
  | 'lg:tw-justify-items-start'
  | 'xl:tw-justify-items-start'
  | 'sm:tw-justify-items-center'
  | 'md:tw-justify-items-center'
  | 'lg:tw-justify-items-center'
  | 'xl:tw-justify-items-center'
  | 'sm:tw-justify-items-end'
  | 'md:tw-justify-items-end'
  | 'lg:tw-justify-items-end'
  | 'xl:tw-justify-items-end'
  | 'sm:tw-justify-items-stretch'
  | 'md:tw-justify-items-stretch'
  | 'lg:tw-justify-items-stretch'
  | 'xl:tw-justify-items-stretch'
  | 'sm:tw-justify-self-auto'
  | 'md:tw-justify-self-auto'
  | 'lg:tw-justify-self-auto'
  | 'xl:tw-justify-self-auto'
  | 'sm:tw-justify-self-start'
  | 'md:tw-justify-self-start'
  | 'lg:tw-justify-self-start'
  | 'xl:tw-justify-self-start'
  | 'sm:tw-justify-self-center'
  | 'md:tw-justify-self-center'
  | 'lg:tw-justify-self-center'
  | 'xl:tw-justify-self-center'
  | 'sm:tw-justify-self-end'
  | 'md:tw-justify-self-end'
  | 'lg:tw-justify-self-end'
  | 'xl:tw-justify-self-end'
  | 'sm:tw-justify-self-stretch'
  | 'md:tw-justify-self-stretch'
  | 'lg:tw-justify-self-stretch'
  | 'xl:tw-justify-self-stretch'
  | 'sm:tw-tracking-tighter'
  | 'md:tw-tracking-tighter'
  | 'lg:tw-tracking-tighter'
  | 'xl:tw-tracking-tighter'
  | 'sm:tw-tracking-tight'
  | 'md:tw-tracking-tight'
  | 'lg:tw-tracking-tight'
  | 'xl:tw-tracking-tight'
  | 'sm:tw-tracking-normal'
  | 'md:tw-tracking-normal'
  | 'lg:tw-tracking-normal'
  | 'xl:tw-tracking-normal'
  | 'sm:tw-tracking-wide'
  | 'md:tw-tracking-wide'
  | 'lg:tw-tracking-wide'
  | 'xl:tw-tracking-wide'
  | 'sm:tw-tracking-wider'
  | 'md:tw-tracking-wider'
  | 'lg:tw-tracking-wider'
  | 'xl:tw-tracking-wider'
  | 'sm:tw-tracking-widest'
  | 'md:tw-tracking-widest'
  | 'lg:tw-tracking-widest'
  | 'xl:tw-tracking-widest'
  | 'sm:tw-leading-3'
  | 'md:tw-leading-3'
  | 'lg:tw-leading-3'
  | 'xl:tw-leading-3'
  | 'sm:tw-leading-4'
  | 'md:tw-leading-4'
  | 'lg:tw-leading-4'
  | 'xl:tw-leading-4'
  | 'sm:tw-leading-5'
  | 'md:tw-leading-5'
  | 'lg:tw-leading-5'
  | 'xl:tw-leading-5'
  | 'sm:tw-leading-6'
  | 'md:tw-leading-6'
  | 'lg:tw-leading-6'
  | 'xl:tw-leading-6'
  | 'sm:tw-leading-7'
  | 'md:tw-leading-7'
  | 'lg:tw-leading-7'
  | 'xl:tw-leading-7'
  | 'sm:tw-leading-8'
  | 'md:tw-leading-8'
  | 'lg:tw-leading-8'
  | 'xl:tw-leading-8'
  | 'sm:tw-leading-9'
  | 'md:tw-leading-9'
  | 'lg:tw-leading-9'
  | 'xl:tw-leading-9'
  | 'sm:tw-leading-10'
  | 'md:tw-leading-10'
  | 'lg:tw-leading-10'
  | 'xl:tw-leading-10'
  | 'sm:tw-leading-none'
  | 'md:tw-leading-none'
  | 'lg:tw-leading-none'
  | 'xl:tw-leading-none'
  | 'sm:tw-leading-tight'
  | 'md:tw-leading-tight'
  | 'lg:tw-leading-tight'
  | 'xl:tw-leading-tight'
  | 'sm:tw-leading-snug'
  | 'md:tw-leading-snug'
  | 'lg:tw-leading-snug'
  | 'xl:tw-leading-snug'
  | 'sm:tw-leading-normal'
  | 'md:tw-leading-normal'
  | 'lg:tw-leading-normal'
  | 'xl:tw-leading-normal'
  | 'sm:tw-leading-relaxed'
  | 'md:tw-leading-relaxed'
  | 'lg:tw-leading-relaxed'
  | 'xl:tw-leading-relaxed'
  | 'sm:tw-leading-loose'
  | 'md:tw-leading-loose'
  | 'lg:tw-leading-loose'
  | 'xl:tw-leading-loose'
  | 'sm:tw-list-inside'
  | 'md:tw-list-inside'
  | 'lg:tw-list-inside'
  | 'xl:tw-list-inside'
  | 'sm:tw-list-outside'
  | 'md:tw-list-outside'
  | 'lg:tw-list-outside'
  | 'xl:tw-list-outside'
  | 'sm:tw-list-none'
  | 'md:tw-list-none'
  | 'lg:tw-list-none'
  | 'xl:tw-list-none'
  | 'sm:tw-list-disc'
  | 'md:tw-list-disc'
  | 'lg:tw-list-disc'
  | 'xl:tw-list-disc'
  | 'sm:tw-list-decimal'
  | 'md:tw-list-decimal'
  | 'lg:tw-list-decimal'
  | 'xl:tw-list-decimal'
  | 'sm:tw-m-0'
  | 'md:tw-m-0'
  | 'lg:tw-m-0'
  | 'xl:tw-m-0'
  | 'sm:tw-m-1'
  | 'md:tw-m-1'
  | 'lg:tw-m-1'
  | 'xl:tw-m-1'
  | 'sm:tw-m-2'
  | 'md:tw-m-2'
  | 'lg:tw-m-2'
  | 'xl:tw-m-2'
  | 'sm:tw-m-3'
  | 'md:tw-m-3'
  | 'lg:tw-m-3'
  | 'xl:tw-m-3'
  | 'sm:tw-m-4'
  | 'md:tw-m-4'
  | 'lg:tw-m-4'
  | 'xl:tw-m-4'
  | 'sm:tw-m-5'
  | 'md:tw-m-5'
  | 'lg:tw-m-5'
  | 'xl:tw-m-5'
  | 'sm:tw-m-6'
  | 'md:tw-m-6'
  | 'lg:tw-m-6'
  | 'xl:tw-m-6'
  | 'sm:tw-m-8'
  | 'md:tw-m-8'
  | 'lg:tw-m-8'
  | 'xl:tw-m-8'
  | 'sm:tw-m-10'
  | 'md:tw-m-10'
  | 'lg:tw-m-10'
  | 'xl:tw-m-10'
  | 'sm:tw-m-12'
  | 'md:tw-m-12'
  | 'lg:tw-m-12'
  | 'xl:tw-m-12'
  | 'sm:tw-m-16'
  | 'md:tw-m-16'
  | 'lg:tw-m-16'
  | 'xl:tw-m-16'
  | 'sm:tw-m-20'
  | 'md:tw-m-20'
  | 'lg:tw-m-20'
  | 'xl:tw-m-20'
  | 'sm:tw-m-24'
  | 'md:tw-m-24'
  | 'lg:tw-m-24'
  | 'xl:tw-m-24'
  | 'sm:tw-m-32'
  | 'md:tw-m-32'
  | 'lg:tw-m-32'
  | 'xl:tw-m-32'
  | 'sm:tw-m-40'
  | 'md:tw-m-40'
  | 'lg:tw-m-40'
  | 'xl:tw-m-40'
  | 'sm:tw-m-48'
  | 'md:tw-m-48'
  | 'lg:tw-m-48'
  | 'xl:tw-m-48'
  | 'sm:tw-m-56'
  | 'md:tw-m-56'
  | 'lg:tw-m-56'
  | 'xl:tw-m-56'
  | 'sm:tw-m-64'
  | 'md:tw-m-64'
  | 'lg:tw-m-64'
  | 'xl:tw-m-64'
  | 'sm:tw-m-auto'
  | 'md:tw-m-auto'
  | 'lg:tw-m-auto'
  | 'xl:tw-m-auto'
  | 'sm:tw-m-px'
  | 'md:tw-m-px'
  | 'lg:tw-m-px'
  | 'xl:tw-m-px'
  | 'sm:tw--m-0'
  | 'md:tw--m-0'
  | 'lg:tw--m-0'
  | 'xl:tw--m-0'
  | 'sm:tw--m-1'
  | 'md:tw--m-1'
  | 'lg:tw--m-1'
  | 'xl:tw--m-1'
  | 'sm:tw--m-2'
  | 'md:tw--m-2'
  | 'lg:tw--m-2'
  | 'xl:tw--m-2'
  | 'sm:tw--m-3'
  | 'md:tw--m-3'
  | 'lg:tw--m-3'
  | 'xl:tw--m-3'
  | 'sm:tw--m-4'
  | 'md:tw--m-4'
  | 'lg:tw--m-4'
  | 'xl:tw--m-4'
  | 'sm:tw--m-5'
  | 'md:tw--m-5'
  | 'lg:tw--m-5'
  | 'xl:tw--m-5'
  | 'sm:tw--m-6'
  | 'md:tw--m-6'
  | 'lg:tw--m-6'
  | 'xl:tw--m-6'
  | 'sm:tw--m-8'
  | 'md:tw--m-8'
  | 'lg:tw--m-8'
  | 'xl:tw--m-8'
  | 'sm:tw--m-10'
  | 'md:tw--m-10'
  | 'lg:tw--m-10'
  | 'xl:tw--m-10'
  | 'sm:tw--m-12'
  | 'md:tw--m-12'
  | 'lg:tw--m-12'
  | 'xl:tw--m-12'
  | 'sm:tw--m-16'
  | 'md:tw--m-16'
  | 'lg:tw--m-16'
  | 'xl:tw--m-16'
  | 'sm:tw--m-20'
  | 'md:tw--m-20'
  | 'lg:tw--m-20'
  | 'xl:tw--m-20'
  | 'sm:tw--m-24'
  | 'md:tw--m-24'
  | 'lg:tw--m-24'
  | 'xl:tw--m-24'
  | 'sm:tw--m-32'
  | 'md:tw--m-32'
  | 'lg:tw--m-32'
  | 'xl:tw--m-32'
  | 'sm:tw--m-40'
  | 'md:tw--m-40'
  | 'lg:tw--m-40'
  | 'xl:tw--m-40'
  | 'sm:tw--m-48'
  | 'md:tw--m-48'
  | 'lg:tw--m-48'
  | 'xl:tw--m-48'
  | 'sm:tw--m-56'
  | 'md:tw--m-56'
  | 'lg:tw--m-56'
  | 'xl:tw--m-56'
  | 'sm:tw--m-64'
  | 'md:tw--m-64'
  | 'lg:tw--m-64'
  | 'xl:tw--m-64'
  | 'sm:tw--m-px'
  | 'md:tw--m-px'
  | 'lg:tw--m-px'
  | 'xl:tw--m-px'
  | 'sm:tw-my-0'
  | 'md:tw-my-0'
  | 'lg:tw-my-0'
  | 'xl:tw-my-0'
  | 'sm:tw-my-1'
  | 'md:tw-my-1'
  | 'lg:tw-my-1'
  | 'xl:tw-my-1'
  | 'sm:tw-my-2'
  | 'md:tw-my-2'
  | 'lg:tw-my-2'
  | 'xl:tw-my-2'
  | 'sm:tw-my-3'
  | 'md:tw-my-3'
  | 'lg:tw-my-3'
  | 'xl:tw-my-3'
  | 'sm:tw-my-4'
  | 'md:tw-my-4'
  | 'lg:tw-my-4'
  | 'xl:tw-my-4'
  | 'sm:tw-my-5'
  | 'md:tw-my-5'
  | 'lg:tw-my-5'
  | 'xl:tw-my-5'
  | 'sm:tw-my-6'
  | 'md:tw-my-6'
  | 'lg:tw-my-6'
  | 'xl:tw-my-6'
  | 'sm:tw-my-8'
  | 'md:tw-my-8'
  | 'lg:tw-my-8'
  | 'xl:tw-my-8'
  | 'sm:tw-my-10'
  | 'md:tw-my-10'
  | 'lg:tw-my-10'
  | 'xl:tw-my-10'
  | 'sm:tw-my-12'
  | 'md:tw-my-12'
  | 'lg:tw-my-12'
  | 'xl:tw-my-12'
  | 'sm:tw-my-16'
  | 'md:tw-my-16'
  | 'lg:tw-my-16'
  | 'xl:tw-my-16'
  | 'sm:tw-my-20'
  | 'md:tw-my-20'
  | 'lg:tw-my-20'
  | 'xl:tw-my-20'
  | 'sm:tw-my-24'
  | 'md:tw-my-24'
  | 'lg:tw-my-24'
  | 'xl:tw-my-24'
  | 'sm:tw-my-32'
  | 'md:tw-my-32'
  | 'lg:tw-my-32'
  | 'xl:tw-my-32'
  | 'sm:tw-my-40'
  | 'md:tw-my-40'
  | 'lg:tw-my-40'
  | 'xl:tw-my-40'
  | 'sm:tw-my-48'
  | 'md:tw-my-48'
  | 'lg:tw-my-48'
  | 'xl:tw-my-48'
  | 'sm:tw-my-56'
  | 'md:tw-my-56'
  | 'lg:tw-my-56'
  | 'xl:tw-my-56'
  | 'sm:tw-my-64'
  | 'md:tw-my-64'
  | 'lg:tw-my-64'
  | 'xl:tw-my-64'
  | 'sm:tw-my-auto'
  | 'md:tw-my-auto'
  | 'lg:tw-my-auto'
  | 'xl:tw-my-auto'
  | 'sm:tw-my-px'
  | 'md:tw-my-px'
  | 'lg:tw-my-px'
  | 'xl:tw-my-px'
  | 'sm:tw--my-0'
  | 'md:tw--my-0'
  | 'lg:tw--my-0'
  | 'xl:tw--my-0'
  | 'sm:tw--my-1'
  | 'md:tw--my-1'
  | 'lg:tw--my-1'
  | 'xl:tw--my-1'
  | 'sm:tw--my-2'
  | 'md:tw--my-2'
  | 'lg:tw--my-2'
  | 'xl:tw--my-2'
  | 'sm:tw--my-3'
  | 'md:tw--my-3'
  | 'lg:tw--my-3'
  | 'xl:tw--my-3'
  | 'sm:tw--my-4'
  | 'md:tw--my-4'
  | 'lg:tw--my-4'
  | 'xl:tw--my-4'
  | 'sm:tw--my-5'
  | 'md:tw--my-5'
  | 'lg:tw--my-5'
  | 'xl:tw--my-5'
  | 'sm:tw--my-6'
  | 'md:tw--my-6'
  | 'lg:tw--my-6'
  | 'xl:tw--my-6'
  | 'sm:tw--my-8'
  | 'md:tw--my-8'
  | 'lg:tw--my-8'
  | 'xl:tw--my-8'
  | 'sm:tw--my-10'
  | 'md:tw--my-10'
  | 'lg:tw--my-10'
  | 'xl:tw--my-10'
  | 'sm:tw--my-12'
  | 'md:tw--my-12'
  | 'lg:tw--my-12'
  | 'xl:tw--my-12'
  | 'sm:tw--my-16'
  | 'md:tw--my-16'
  | 'lg:tw--my-16'
  | 'xl:tw--my-16'
  | 'sm:tw--my-20'
  | 'md:tw--my-20'
  | 'lg:tw--my-20'
  | 'xl:tw--my-20'
  | 'sm:tw--my-24'
  | 'md:tw--my-24'
  | 'lg:tw--my-24'
  | 'xl:tw--my-24'
  | 'sm:tw--my-32'
  | 'md:tw--my-32'
  | 'lg:tw--my-32'
  | 'xl:tw--my-32'
  | 'sm:tw--my-40'
  | 'md:tw--my-40'
  | 'lg:tw--my-40'
  | 'xl:tw--my-40'
  | 'sm:tw--my-48'
  | 'md:tw--my-48'
  | 'lg:tw--my-48'
  | 'xl:tw--my-48'
  | 'sm:tw--my-56'
  | 'md:tw--my-56'
  | 'lg:tw--my-56'
  | 'xl:tw--my-56'
  | 'sm:tw--my-64'
  | 'md:tw--my-64'
  | 'lg:tw--my-64'
  | 'xl:tw--my-64'
  | 'sm:tw--my-px'
  | 'md:tw--my-px'
  | 'lg:tw--my-px'
  | 'xl:tw--my-px'
  | 'sm:tw-mx-0'
  | 'md:tw-mx-0'
  | 'lg:tw-mx-0'
  | 'xl:tw-mx-0'
  | 'sm:tw-mx-1'
  | 'md:tw-mx-1'
  | 'lg:tw-mx-1'
  | 'xl:tw-mx-1'
  | 'sm:tw-mx-2'
  | 'md:tw-mx-2'
  | 'lg:tw-mx-2'
  | 'xl:tw-mx-2'
  | 'sm:tw-mx-3'
  | 'md:tw-mx-3'
  | 'lg:tw-mx-3'
  | 'xl:tw-mx-3'
  | 'sm:tw-mx-4'
  | 'md:tw-mx-4'
  | 'lg:tw-mx-4'
  | 'xl:tw-mx-4'
  | 'sm:tw-mx-5'
  | 'md:tw-mx-5'
  | 'lg:tw-mx-5'
  | 'xl:tw-mx-5'
  | 'sm:tw-mx-6'
  | 'md:tw-mx-6'
  | 'lg:tw-mx-6'
  | 'xl:tw-mx-6'
  | 'sm:tw-mx-8'
  | 'md:tw-mx-8'
  | 'lg:tw-mx-8'
  | 'xl:tw-mx-8'
  | 'sm:tw-mx-10'
  | 'md:tw-mx-10'
  | 'lg:tw-mx-10'
  | 'xl:tw-mx-10'
  | 'sm:tw-mx-12'
  | 'md:tw-mx-12'
  | 'lg:tw-mx-12'
  | 'xl:tw-mx-12'
  | 'sm:tw-mx-16'
  | 'md:tw-mx-16'
  | 'lg:tw-mx-16'
  | 'xl:tw-mx-16'
  | 'sm:tw-mx-20'
  | 'md:tw-mx-20'
  | 'lg:tw-mx-20'
  | 'xl:tw-mx-20'
  | 'sm:tw-mx-24'
  | 'md:tw-mx-24'
  | 'lg:tw-mx-24'
  | 'xl:tw-mx-24'
  | 'sm:tw-mx-32'
  | 'md:tw-mx-32'
  | 'lg:tw-mx-32'
  | 'xl:tw-mx-32'
  | 'sm:tw-mx-40'
  | 'md:tw-mx-40'
  | 'lg:tw-mx-40'
  | 'xl:tw-mx-40'
  | 'sm:tw-mx-48'
  | 'md:tw-mx-48'
  | 'lg:tw-mx-48'
  | 'xl:tw-mx-48'
  | 'sm:tw-mx-56'
  | 'md:tw-mx-56'
  | 'lg:tw-mx-56'
  | 'xl:tw-mx-56'
  | 'sm:tw-mx-64'
  | 'md:tw-mx-64'
  | 'lg:tw-mx-64'
  | 'xl:tw-mx-64'
  | 'sm:tw-mx-auto'
  | 'md:tw-mx-auto'
  | 'lg:tw-mx-auto'
  | 'xl:tw-mx-auto'
  | 'sm:tw-mx-px'
  | 'md:tw-mx-px'
  | 'lg:tw-mx-px'
  | 'xl:tw-mx-px'
  | 'sm:tw--mx-0'
  | 'md:tw--mx-0'
  | 'lg:tw--mx-0'
  | 'xl:tw--mx-0'
  | 'sm:tw--mx-1'
  | 'md:tw--mx-1'
  | 'lg:tw--mx-1'
  | 'xl:tw--mx-1'
  | 'sm:tw--mx-2'
  | 'md:tw--mx-2'
  | 'lg:tw--mx-2'
  | 'xl:tw--mx-2'
  | 'sm:tw--mx-3'
  | 'md:tw--mx-3'
  | 'lg:tw--mx-3'
  | 'xl:tw--mx-3'
  | 'sm:tw--mx-4'
  | 'md:tw--mx-4'
  | 'lg:tw--mx-4'
  | 'xl:tw--mx-4'
  | 'sm:tw--mx-5'
  | 'md:tw--mx-5'
  | 'lg:tw--mx-5'
  | 'xl:tw--mx-5'
  | 'sm:tw--mx-6'
  | 'md:tw--mx-6'
  | 'lg:tw--mx-6'
  | 'xl:tw--mx-6'
  | 'sm:tw--mx-8'
  | 'md:tw--mx-8'
  | 'lg:tw--mx-8'
  | 'xl:tw--mx-8'
  | 'sm:tw--mx-10'
  | 'md:tw--mx-10'
  | 'lg:tw--mx-10'
  | 'xl:tw--mx-10'
  | 'sm:tw--mx-12'
  | 'md:tw--mx-12'
  | 'lg:tw--mx-12'
  | 'xl:tw--mx-12'
  | 'sm:tw--mx-16'
  | 'md:tw--mx-16'
  | 'lg:tw--mx-16'
  | 'xl:tw--mx-16'
  | 'sm:tw--mx-20'
  | 'md:tw--mx-20'
  | 'lg:tw--mx-20'
  | 'xl:tw--mx-20'
  | 'sm:tw--mx-24'
  | 'md:tw--mx-24'
  | 'lg:tw--mx-24'
  | 'xl:tw--mx-24'
  | 'sm:tw--mx-32'
  | 'md:tw--mx-32'
  | 'lg:tw--mx-32'
  | 'xl:tw--mx-32'
  | 'sm:tw--mx-40'
  | 'md:tw--mx-40'
  | 'lg:tw--mx-40'
  | 'xl:tw--mx-40'
  | 'sm:tw--mx-48'
  | 'md:tw--mx-48'
  | 'lg:tw--mx-48'
  | 'xl:tw--mx-48'
  | 'sm:tw--mx-56'
  | 'md:tw--mx-56'
  | 'lg:tw--mx-56'
  | 'xl:tw--mx-56'
  | 'sm:tw--mx-64'
  | 'md:tw--mx-64'
  | 'lg:tw--mx-64'
  | 'xl:tw--mx-64'
  | 'sm:tw--mx-px'
  | 'md:tw--mx-px'
  | 'lg:tw--mx-px'
  | 'xl:tw--mx-px'
  | 'sm:tw-mt-0'
  | 'md:tw-mt-0'
  | 'lg:tw-mt-0'
  | 'xl:tw-mt-0'
  | 'sm:tw-mt-1'
  | 'md:tw-mt-1'
  | 'lg:tw-mt-1'
  | 'xl:tw-mt-1'
  | 'sm:tw-mt-2'
  | 'md:tw-mt-2'
  | 'lg:tw-mt-2'
  | 'xl:tw-mt-2'
  | 'sm:tw-mt-3'
  | 'md:tw-mt-3'
  | 'lg:tw-mt-3'
  | 'xl:tw-mt-3'
  | 'sm:tw-mt-4'
  | 'md:tw-mt-4'
  | 'lg:tw-mt-4'
  | 'xl:tw-mt-4'
  | 'sm:tw-mt-5'
  | 'md:tw-mt-5'
  | 'lg:tw-mt-5'
  | 'xl:tw-mt-5'
  | 'sm:tw-mt-6'
  | 'md:tw-mt-6'
  | 'lg:tw-mt-6'
  | 'xl:tw-mt-6'
  | 'sm:tw-mt-8'
  | 'md:tw-mt-8'
  | 'lg:tw-mt-8'
  | 'xl:tw-mt-8'
  | 'sm:tw-mt-10'
  | 'md:tw-mt-10'
  | 'lg:tw-mt-10'
  | 'xl:tw-mt-10'
  | 'sm:tw-mt-12'
  | 'md:tw-mt-12'
  | 'lg:tw-mt-12'
  | 'xl:tw-mt-12'
  | 'sm:tw-mt-16'
  | 'md:tw-mt-16'
  | 'lg:tw-mt-16'
  | 'xl:tw-mt-16'
  | 'sm:tw-mt-20'
  | 'md:tw-mt-20'
  | 'lg:tw-mt-20'
  | 'xl:tw-mt-20'
  | 'sm:tw-mt-24'
  | 'md:tw-mt-24'
  | 'lg:tw-mt-24'
  | 'xl:tw-mt-24'
  | 'sm:tw-mt-32'
  | 'md:tw-mt-32'
  | 'lg:tw-mt-32'
  | 'xl:tw-mt-32'
  | 'sm:tw-mt-40'
  | 'md:tw-mt-40'
  | 'lg:tw-mt-40'
  | 'xl:tw-mt-40'
  | 'sm:tw-mt-48'
  | 'md:tw-mt-48'
  | 'lg:tw-mt-48'
  | 'xl:tw-mt-48'
  | 'sm:tw-mt-56'
  | 'md:tw-mt-56'
  | 'lg:tw-mt-56'
  | 'xl:tw-mt-56'
  | 'sm:tw-mt-64'
  | 'md:tw-mt-64'
  | 'lg:tw-mt-64'
  | 'xl:tw-mt-64'
  | 'sm:tw-mt-auto'
  | 'md:tw-mt-auto'
  | 'lg:tw-mt-auto'
  | 'xl:tw-mt-auto'
  | 'sm:tw-mt-px'
  | 'md:tw-mt-px'
  | 'lg:tw-mt-px'
  | 'xl:tw-mt-px'
  | 'sm:tw--mt-0'
  | 'md:tw--mt-0'
  | 'lg:tw--mt-0'
  | 'xl:tw--mt-0'
  | 'sm:tw--mt-1'
  | 'md:tw--mt-1'
  | 'lg:tw--mt-1'
  | 'xl:tw--mt-1'
  | 'sm:tw--mt-2'
  | 'md:tw--mt-2'
  | 'lg:tw--mt-2'
  | 'xl:tw--mt-2'
  | 'sm:tw--mt-3'
  | 'md:tw--mt-3'
  | 'lg:tw--mt-3'
  | 'xl:tw--mt-3'
  | 'sm:tw--mt-4'
  | 'md:tw--mt-4'
  | 'lg:tw--mt-4'
  | 'xl:tw--mt-4'
  | 'sm:tw--mt-5'
  | 'md:tw--mt-5'
  | 'lg:tw--mt-5'
  | 'xl:tw--mt-5'
  | 'sm:tw--mt-6'
  | 'md:tw--mt-6'
  | 'lg:tw--mt-6'
  | 'xl:tw--mt-6'
  | 'sm:tw--mt-8'
  | 'md:tw--mt-8'
  | 'lg:tw--mt-8'
  | 'xl:tw--mt-8'
  | 'sm:tw--mt-10'
  | 'md:tw--mt-10'
  | 'lg:tw--mt-10'
  | 'xl:tw--mt-10'
  | 'sm:tw--mt-12'
  | 'md:tw--mt-12'
  | 'lg:tw--mt-12'
  | 'xl:tw--mt-12'
  | 'sm:tw--mt-16'
  | 'md:tw--mt-16'
  | 'lg:tw--mt-16'
  | 'xl:tw--mt-16'
  | 'sm:tw--mt-20'
  | 'md:tw--mt-20'
  | 'lg:tw--mt-20'
  | 'xl:tw--mt-20'
  | 'sm:tw--mt-24'
  | 'md:tw--mt-24'
  | 'lg:tw--mt-24'
  | 'xl:tw--mt-24'
  | 'sm:tw--mt-32'
  | 'md:tw--mt-32'
  | 'lg:tw--mt-32'
  | 'xl:tw--mt-32'
  | 'sm:tw--mt-40'
  | 'md:tw--mt-40'
  | 'lg:tw--mt-40'
  | 'xl:tw--mt-40'
  | 'sm:tw--mt-48'
  | 'md:tw--mt-48'
  | 'lg:tw--mt-48'
  | 'xl:tw--mt-48'
  | 'sm:tw--mt-56'
  | 'md:tw--mt-56'
  | 'lg:tw--mt-56'
  | 'xl:tw--mt-56'
  | 'sm:tw--mt-64'
  | 'md:tw--mt-64'
  | 'lg:tw--mt-64'
  | 'xl:tw--mt-64'
  | 'sm:tw--mt-px'
  | 'md:tw--mt-px'
  | 'lg:tw--mt-px'
  | 'xl:tw--mt-px'
  | 'sm:tw-mr-0'
  | 'md:tw-mr-0'
  | 'lg:tw-mr-0'
  | 'xl:tw-mr-0'
  | 'sm:tw-mr-1'
  | 'md:tw-mr-1'
  | 'lg:tw-mr-1'
  | 'xl:tw-mr-1'
  | 'sm:tw-mr-2'
  | 'md:tw-mr-2'
  | 'lg:tw-mr-2'
  | 'xl:tw-mr-2'
  | 'sm:tw-mr-3'
  | 'md:tw-mr-3'
  | 'lg:tw-mr-3'
  | 'xl:tw-mr-3'
  | 'sm:tw-mr-4'
  | 'md:tw-mr-4'
  | 'lg:tw-mr-4'
  | 'xl:tw-mr-4'
  | 'sm:tw-mr-5'
  | 'md:tw-mr-5'
  | 'lg:tw-mr-5'
  | 'xl:tw-mr-5'
  | 'sm:tw-mr-6'
  | 'md:tw-mr-6'
  | 'lg:tw-mr-6'
  | 'xl:tw-mr-6'
  | 'sm:tw-mr-8'
  | 'md:tw-mr-8'
  | 'lg:tw-mr-8'
  | 'xl:tw-mr-8'
  | 'sm:tw-mr-10'
  | 'md:tw-mr-10'
  | 'lg:tw-mr-10'
  | 'xl:tw-mr-10'
  | 'sm:tw-mr-12'
  | 'md:tw-mr-12'
  | 'lg:tw-mr-12'
  | 'xl:tw-mr-12'
  | 'sm:tw-mr-16'
  | 'md:tw-mr-16'
  | 'lg:tw-mr-16'
  | 'xl:tw-mr-16'
  | 'sm:tw-mr-20'
  | 'md:tw-mr-20'
  | 'lg:tw-mr-20'
  | 'xl:tw-mr-20'
  | 'sm:tw-mr-24'
  | 'md:tw-mr-24'
  | 'lg:tw-mr-24'
  | 'xl:tw-mr-24'
  | 'sm:tw-mr-32'
  | 'md:tw-mr-32'
  | 'lg:tw-mr-32'
  | 'xl:tw-mr-32'
  | 'sm:tw-mr-40'
  | 'md:tw-mr-40'
  | 'lg:tw-mr-40'
  | 'xl:tw-mr-40'
  | 'sm:tw-mr-48'
  | 'md:tw-mr-48'
  | 'lg:tw-mr-48'
  | 'xl:tw-mr-48'
  | 'sm:tw-mr-56'
  | 'md:tw-mr-56'
  | 'lg:tw-mr-56'
  | 'xl:tw-mr-56'
  | 'sm:tw-mr-64'
  | 'md:tw-mr-64'
  | 'lg:tw-mr-64'
  | 'xl:tw-mr-64'
  | 'sm:tw-mr-auto'
  | 'md:tw-mr-auto'
  | 'lg:tw-mr-auto'
  | 'xl:tw-mr-auto'
  | 'sm:tw-mr-px'
  | 'md:tw-mr-px'
  | 'lg:tw-mr-px'
  | 'xl:tw-mr-px'
  | 'sm:tw--mr-0'
  | 'md:tw--mr-0'
  | 'lg:tw--mr-0'
  | 'xl:tw--mr-0'
  | 'sm:tw--mr-1'
  | 'md:tw--mr-1'
  | 'lg:tw--mr-1'
  | 'xl:tw--mr-1'
  | 'sm:tw--mr-2'
  | 'md:tw--mr-2'
  | 'lg:tw--mr-2'
  | 'xl:tw--mr-2'
  | 'sm:tw--mr-3'
  | 'md:tw--mr-3'
  | 'lg:tw--mr-3'
  | 'xl:tw--mr-3'
  | 'sm:tw--mr-4'
  | 'md:tw--mr-4'
  | 'lg:tw--mr-4'
  | 'xl:tw--mr-4'
  | 'sm:tw--mr-5'
  | 'md:tw--mr-5'
  | 'lg:tw--mr-5'
  | 'xl:tw--mr-5'
  | 'sm:tw--mr-6'
  | 'md:tw--mr-6'
  | 'lg:tw--mr-6'
  | 'xl:tw--mr-6'
  | 'sm:tw--mr-8'
  | 'md:tw--mr-8'
  | 'lg:tw--mr-8'
  | 'xl:tw--mr-8'
  | 'sm:tw--mr-10'
  | 'md:tw--mr-10'
  | 'lg:tw--mr-10'
  | 'xl:tw--mr-10'
  | 'sm:tw--mr-12'
  | 'md:tw--mr-12'
  | 'lg:tw--mr-12'
  | 'xl:tw--mr-12'
  | 'sm:tw--mr-16'
  | 'md:tw--mr-16'
  | 'lg:tw--mr-16'
  | 'xl:tw--mr-16'
  | 'sm:tw--mr-20'
  | 'md:tw--mr-20'
  | 'lg:tw--mr-20'
  | 'xl:tw--mr-20'
  | 'sm:tw--mr-24'
  | 'md:tw--mr-24'
  | 'lg:tw--mr-24'
  | 'xl:tw--mr-24'
  | 'sm:tw--mr-32'
  | 'md:tw--mr-32'
  | 'lg:tw--mr-32'
  | 'xl:tw--mr-32'
  | 'sm:tw--mr-40'
  | 'md:tw--mr-40'
  | 'lg:tw--mr-40'
  | 'xl:tw--mr-40'
  | 'sm:tw--mr-48'
  | 'md:tw--mr-48'
  | 'lg:tw--mr-48'
  | 'xl:tw--mr-48'
  | 'sm:tw--mr-56'
  | 'md:tw--mr-56'
  | 'lg:tw--mr-56'
  | 'xl:tw--mr-56'
  | 'sm:tw--mr-64'
  | 'md:tw--mr-64'
  | 'lg:tw--mr-64'
  | 'xl:tw--mr-64'
  | 'sm:tw--mr-px'
  | 'md:tw--mr-px'
  | 'lg:tw--mr-px'
  | 'xl:tw--mr-px'
  | 'sm:tw-mb-0'
  | 'md:tw-mb-0'
  | 'lg:tw-mb-0'
  | 'xl:tw-mb-0'
  | 'sm:tw-mb-1'
  | 'md:tw-mb-1'
  | 'lg:tw-mb-1'
  | 'xl:tw-mb-1'
  | 'sm:tw-mb-2'
  | 'md:tw-mb-2'
  | 'lg:tw-mb-2'
  | 'xl:tw-mb-2'
  | 'sm:tw-mb-3'
  | 'md:tw-mb-3'
  | 'lg:tw-mb-3'
  | 'xl:tw-mb-3'
  | 'sm:tw-mb-4'
  | 'md:tw-mb-4'
  | 'lg:tw-mb-4'
  | 'xl:tw-mb-4'
  | 'sm:tw-mb-5'
  | 'md:tw-mb-5'
  | 'lg:tw-mb-5'
  | 'xl:tw-mb-5'
  | 'sm:tw-mb-6'
  | 'md:tw-mb-6'
  | 'lg:tw-mb-6'
  | 'xl:tw-mb-6'
  | 'sm:tw-mb-8'
  | 'md:tw-mb-8'
  | 'lg:tw-mb-8'
  | 'xl:tw-mb-8'
  | 'sm:tw-mb-10'
  | 'md:tw-mb-10'
  | 'lg:tw-mb-10'
  | 'xl:tw-mb-10'
  | 'sm:tw-mb-12'
  | 'md:tw-mb-12'
  | 'lg:tw-mb-12'
  | 'xl:tw-mb-12'
  | 'sm:tw-mb-16'
  | 'md:tw-mb-16'
  | 'lg:tw-mb-16'
  | 'xl:tw-mb-16'
  | 'sm:tw-mb-20'
  | 'md:tw-mb-20'
  | 'lg:tw-mb-20'
  | 'xl:tw-mb-20'
  | 'sm:tw-mb-24'
  | 'md:tw-mb-24'
  | 'lg:tw-mb-24'
  | 'xl:tw-mb-24'
  | 'sm:tw-mb-32'
  | 'md:tw-mb-32'
  | 'lg:tw-mb-32'
  | 'xl:tw-mb-32'
  | 'sm:tw-mb-40'
  | 'md:tw-mb-40'
  | 'lg:tw-mb-40'
  | 'xl:tw-mb-40'
  | 'sm:tw-mb-48'
  | 'md:tw-mb-48'
  | 'lg:tw-mb-48'
  | 'xl:tw-mb-48'
  | 'sm:tw-mb-56'
  | 'md:tw-mb-56'
  | 'lg:tw-mb-56'
  | 'xl:tw-mb-56'
  | 'sm:tw-mb-64'
  | 'md:tw-mb-64'
  | 'lg:tw-mb-64'
  | 'xl:tw-mb-64'
  | 'sm:tw-mb-auto'
  | 'md:tw-mb-auto'
  | 'lg:tw-mb-auto'
  | 'xl:tw-mb-auto'
  | 'sm:tw-mb-px'
  | 'md:tw-mb-px'
  | 'lg:tw-mb-px'
  | 'xl:tw-mb-px'
  | 'sm:tw--mb-0'
  | 'md:tw--mb-0'
  | 'lg:tw--mb-0'
  | 'xl:tw--mb-0'
  | 'sm:tw--mb-1'
  | 'md:tw--mb-1'
  | 'lg:tw--mb-1'
  | 'xl:tw--mb-1'
  | 'sm:tw--mb-2'
  | 'md:tw--mb-2'
  | 'lg:tw--mb-2'
  | 'xl:tw--mb-2'
  | 'sm:tw--mb-3'
  | 'md:tw--mb-3'
  | 'lg:tw--mb-3'
  | 'xl:tw--mb-3'
  | 'sm:tw--mb-4'
  | 'md:tw--mb-4'
  | 'lg:tw--mb-4'
  | 'xl:tw--mb-4'
  | 'sm:tw--mb-5'
  | 'md:tw--mb-5'
  | 'lg:tw--mb-5'
  | 'xl:tw--mb-5'
  | 'sm:tw--mb-6'
  | 'md:tw--mb-6'
  | 'lg:tw--mb-6'
  | 'xl:tw--mb-6'
  | 'sm:tw--mb-8'
  | 'md:tw--mb-8'
  | 'lg:tw--mb-8'
  | 'xl:tw--mb-8'
  | 'sm:tw--mb-10'
  | 'md:tw--mb-10'
  | 'lg:tw--mb-10'
  | 'xl:tw--mb-10'
  | 'sm:tw--mb-12'
  | 'md:tw--mb-12'
  | 'lg:tw--mb-12'
  | 'xl:tw--mb-12'
  | 'sm:tw--mb-16'
  | 'md:tw--mb-16'
  | 'lg:tw--mb-16'
  | 'xl:tw--mb-16'
  | 'sm:tw--mb-20'
  | 'md:tw--mb-20'
  | 'lg:tw--mb-20'
  | 'xl:tw--mb-20'
  | 'sm:tw--mb-24'
  | 'md:tw--mb-24'
  | 'lg:tw--mb-24'
  | 'xl:tw--mb-24'
  | 'sm:tw--mb-32'
  | 'md:tw--mb-32'
  | 'lg:tw--mb-32'
  | 'xl:tw--mb-32'
  | 'sm:tw--mb-40'
  | 'md:tw--mb-40'
  | 'lg:tw--mb-40'
  | 'xl:tw--mb-40'
  | 'sm:tw--mb-48'
  | 'md:tw--mb-48'
  | 'lg:tw--mb-48'
  | 'xl:tw--mb-48'
  | 'sm:tw--mb-56'
  | 'md:tw--mb-56'
  | 'lg:tw--mb-56'
  | 'xl:tw--mb-56'
  | 'sm:tw--mb-64'
  | 'md:tw--mb-64'
  | 'lg:tw--mb-64'
  | 'xl:tw--mb-64'
  | 'sm:tw--mb-px'
  | 'md:tw--mb-px'
  | 'lg:tw--mb-px'
  | 'xl:tw--mb-px'
  | 'sm:tw-ml-0'
  | 'md:tw-ml-0'
  | 'lg:tw-ml-0'
  | 'xl:tw-ml-0'
  | 'sm:tw-ml-1'
  | 'md:tw-ml-1'
  | 'lg:tw-ml-1'
  | 'xl:tw-ml-1'
  | 'sm:tw-ml-2'
  | 'md:tw-ml-2'
  | 'lg:tw-ml-2'
  | 'xl:tw-ml-2'
  | 'sm:tw-ml-3'
  | 'md:tw-ml-3'
  | 'lg:tw-ml-3'
  | 'xl:tw-ml-3'
  | 'sm:tw-ml-4'
  | 'md:tw-ml-4'
  | 'lg:tw-ml-4'
  | 'xl:tw-ml-4'
  | 'sm:tw-ml-5'
  | 'md:tw-ml-5'
  | 'lg:tw-ml-5'
  | 'xl:tw-ml-5'
  | 'sm:tw-ml-6'
  | 'md:tw-ml-6'
  | 'lg:tw-ml-6'
  | 'xl:tw-ml-6'
  | 'sm:tw-ml-8'
  | 'md:tw-ml-8'
  | 'lg:tw-ml-8'
  | 'xl:tw-ml-8'
  | 'sm:tw-ml-10'
  | 'md:tw-ml-10'
  | 'lg:tw-ml-10'
  | 'xl:tw-ml-10'
  | 'sm:tw-ml-12'
  | 'md:tw-ml-12'
  | 'lg:tw-ml-12'
  | 'xl:tw-ml-12'
  | 'sm:tw-ml-16'
  | 'md:tw-ml-16'
  | 'lg:tw-ml-16'
  | 'xl:tw-ml-16'
  | 'sm:tw-ml-20'
  | 'md:tw-ml-20'
  | 'lg:tw-ml-20'
  | 'xl:tw-ml-20'
  | 'sm:tw-ml-24'
  | 'md:tw-ml-24'
  | 'lg:tw-ml-24'
  | 'xl:tw-ml-24'
  | 'sm:tw-ml-32'
  | 'md:tw-ml-32'
  | 'lg:tw-ml-32'
  | 'xl:tw-ml-32'
  | 'sm:tw-ml-40'
  | 'md:tw-ml-40'
  | 'lg:tw-ml-40'
  | 'xl:tw-ml-40'
  | 'sm:tw-ml-48'
  | 'md:tw-ml-48'
  | 'lg:tw-ml-48'
  | 'xl:tw-ml-48'
  | 'sm:tw-ml-56'
  | 'md:tw-ml-56'
  | 'lg:tw-ml-56'
  | 'xl:tw-ml-56'
  | 'sm:tw-ml-64'
  | 'md:tw-ml-64'
  | 'lg:tw-ml-64'
  | 'xl:tw-ml-64'
  | 'sm:tw-ml-auto'
  | 'md:tw-ml-auto'
  | 'lg:tw-ml-auto'
  | 'xl:tw-ml-auto'
  | 'sm:tw-ml-px'
  | 'md:tw-ml-px'
  | 'lg:tw-ml-px'
  | 'xl:tw-ml-px'
  | 'sm:tw--ml-0'
  | 'md:tw--ml-0'
  | 'lg:tw--ml-0'
  | 'xl:tw--ml-0'
  | 'sm:tw--ml-1'
  | 'md:tw--ml-1'
  | 'lg:tw--ml-1'
  | 'xl:tw--ml-1'
  | 'sm:tw--ml-2'
  | 'md:tw--ml-2'
  | 'lg:tw--ml-2'
  | 'xl:tw--ml-2'
  | 'sm:tw--ml-3'
  | 'md:tw--ml-3'
  | 'lg:tw--ml-3'
  | 'xl:tw--ml-3'
  | 'sm:tw--ml-4'
  | 'md:tw--ml-4'
  | 'lg:tw--ml-4'
  | 'xl:tw--ml-4'
  | 'sm:tw--ml-5'
  | 'md:tw--ml-5'
  | 'lg:tw--ml-5'
  | 'xl:tw--ml-5'
  | 'sm:tw--ml-6'
  | 'md:tw--ml-6'
  | 'lg:tw--ml-6'
  | 'xl:tw--ml-6'
  | 'sm:tw--ml-8'
  | 'md:tw--ml-8'
  | 'lg:tw--ml-8'
  | 'xl:tw--ml-8'
  | 'sm:tw--ml-10'
  | 'md:tw--ml-10'
  | 'lg:tw--ml-10'
  | 'xl:tw--ml-10'
  | 'sm:tw--ml-12'
  | 'md:tw--ml-12'
  | 'lg:tw--ml-12'
  | 'xl:tw--ml-12'
  | 'sm:tw--ml-16'
  | 'md:tw--ml-16'
  | 'lg:tw--ml-16'
  | 'xl:tw--ml-16'
  | 'sm:tw--ml-20'
  | 'md:tw--ml-20'
  | 'lg:tw--ml-20'
  | 'xl:tw--ml-20'
  | 'sm:tw--ml-24'
  | 'md:tw--ml-24'
  | 'lg:tw--ml-24'
  | 'xl:tw--ml-24'
  | 'sm:tw--ml-32'
  | 'md:tw--ml-32'
  | 'lg:tw--ml-32'
  | 'xl:tw--ml-32'
  | 'sm:tw--ml-40'
  | 'md:tw--ml-40'
  | 'lg:tw--ml-40'
  | 'xl:tw--ml-40'
  | 'sm:tw--ml-48'
  | 'md:tw--ml-48'
  | 'lg:tw--ml-48'
  | 'xl:tw--ml-48'
  | 'sm:tw--ml-56'
  | 'md:tw--ml-56'
  | 'lg:tw--ml-56'
  | 'xl:tw--ml-56'
  | 'sm:tw--ml-64'
  | 'md:tw--ml-64'
  | 'lg:tw--ml-64'
  | 'xl:tw--ml-64'
  | 'sm:tw--ml-px'
  | 'md:tw--ml-px'
  | 'lg:tw--ml-px'
  | 'xl:tw--ml-px'
  | 'sm:tw-max-h-full'
  | 'md:tw-max-h-full'
  | 'lg:tw-max-h-full'
  | 'xl:tw-max-h-full'
  | 'sm:tw-max-h-screen'
  | 'md:tw-max-h-screen'
  | 'lg:tw-max-h-screen'
  | 'xl:tw-max-h-screen'
  | 'sm:tw-max-w-none'
  | 'md:tw-max-w-none'
  | 'lg:tw-max-w-none'
  | 'xl:tw-max-w-none'
  | 'sm:tw-max-w-xs'
  | 'md:tw-max-w-xs'
  | 'lg:tw-max-w-xs'
  | 'xl:tw-max-w-xs'
  | 'sm:tw-max-w-sm'
  | 'md:tw-max-w-sm'
  | 'lg:tw-max-w-sm'
  | 'xl:tw-max-w-sm'
  | 'sm:tw-max-w-md'
  | 'md:tw-max-w-md'
  | 'lg:tw-max-w-md'
  | 'xl:tw-max-w-md'
  | 'sm:tw-max-w-lg'
  | 'md:tw-max-w-lg'
  | 'lg:tw-max-w-lg'
  | 'xl:tw-max-w-lg'
  | 'sm:tw-max-w-xl'
  | 'md:tw-max-w-xl'
  | 'lg:tw-max-w-xl'
  | 'xl:tw-max-w-xl'
  | 'sm:tw-max-w-2xl'
  | 'md:tw-max-w-2xl'
  | 'lg:tw-max-w-2xl'
  | 'xl:tw-max-w-2xl'
  | 'sm:tw-max-w-3xl'
  | 'md:tw-max-w-3xl'
  | 'lg:tw-max-w-3xl'
  | 'xl:tw-max-w-3xl'
  | 'sm:tw-max-w-4xl'
  | 'md:tw-max-w-4xl'
  | 'lg:tw-max-w-4xl'
  | 'xl:tw-max-w-4xl'
  | 'sm:tw-max-w-5xl'
  | 'md:tw-max-w-5xl'
  | 'lg:tw-max-w-5xl'
  | 'xl:tw-max-w-5xl'
  | 'sm:tw-max-w-6xl'
  | 'md:tw-max-w-6xl'
  | 'lg:tw-max-w-6xl'
  | 'xl:tw-max-w-6xl'
  | 'sm:tw-max-w-full'
  | 'md:tw-max-w-full'
  | 'lg:tw-max-w-full'
  | 'xl:tw-max-w-full'
  | 'sm:tw-max-w-screen-sm'
  | 'md:tw-max-w-screen-sm'
  | 'lg:tw-max-w-screen-sm'
  | 'xl:tw-max-w-screen-sm'
  | 'sm:tw-max-w-screen-md'
  | 'md:tw-max-w-screen-md'
  | 'lg:tw-max-w-screen-md'
  | 'xl:tw-max-w-screen-md'
  | 'sm:tw-max-w-screen-lg'
  | 'md:tw-max-w-screen-lg'
  | 'lg:tw-max-w-screen-lg'
  | 'xl:tw-max-w-screen-lg'
  | 'sm:tw-max-w-screen-xl'
  | 'md:tw-max-w-screen-xl'
  | 'lg:tw-max-w-screen-xl'
  | 'xl:tw-max-w-screen-xl'
  | 'sm:tw-min-h-0'
  | 'md:tw-min-h-0'
  | 'lg:tw-min-h-0'
  | 'xl:tw-min-h-0'
  | 'sm:tw-min-h-full'
  | 'md:tw-min-h-full'
  | 'lg:tw-min-h-full'
  | 'xl:tw-min-h-full'
  | 'sm:tw-min-h-screen'
  | 'md:tw-min-h-screen'
  | 'lg:tw-min-h-screen'
  | 'xl:tw-min-h-screen'
  | 'sm:tw-min-w-0'
  | 'md:tw-min-w-0'
  | 'lg:tw-min-w-0'
  | 'xl:tw-min-w-0'
  | 'sm:tw-min-w-full'
  | 'md:tw-min-w-full'
  | 'lg:tw-min-w-full'
  | 'xl:tw-min-w-full'
  | 'sm:tw-object-contain'
  | 'md:tw-object-contain'
  | 'lg:tw-object-contain'
  | 'xl:tw-object-contain'
  | 'sm:tw-object-cover'
  | 'md:tw-object-cover'
  | 'lg:tw-object-cover'
  | 'xl:tw-object-cover'
  | 'sm:tw-object-fill'
  | 'md:tw-object-fill'
  | 'lg:tw-object-fill'
  | 'xl:tw-object-fill'
  | 'sm:tw-object-none'
  | 'md:tw-object-none'
  | 'lg:tw-object-none'
  | 'xl:tw-object-none'
  | 'sm:tw-object-scale-down'
  | 'md:tw-object-scale-down'
  | 'lg:tw-object-scale-down'
  | 'xl:tw-object-scale-down'
  | 'sm:tw-object-bottom'
  | 'md:tw-object-bottom'
  | 'lg:tw-object-bottom'
  | 'xl:tw-object-bottom'
  | 'sm:tw-object-center'
  | 'md:tw-object-center'
  | 'lg:tw-object-center'
  | 'xl:tw-object-center'
  | 'sm:tw-object-left'
  | 'md:tw-object-left'
  | 'lg:tw-object-left'
  | 'xl:tw-object-left'
  | 'sm:tw-object-left-bottom'
  | 'md:tw-object-left-bottom'
  | 'lg:tw-object-left-bottom'
  | 'xl:tw-object-left-bottom'
  | 'sm:tw-object-left-top'
  | 'md:tw-object-left-top'
  | 'lg:tw-object-left-top'
  | 'xl:tw-object-left-top'
  | 'sm:tw-object-right'
  | 'md:tw-object-right'
  | 'lg:tw-object-right'
  | 'xl:tw-object-right'
  | 'sm:tw-object-right-bottom'
  | 'md:tw-object-right-bottom'
  | 'lg:tw-object-right-bottom'
  | 'xl:tw-object-right-bottom'
  | 'sm:tw-object-right-top'
  | 'md:tw-object-right-top'
  | 'lg:tw-object-right-top'
  | 'xl:tw-object-right-top'
  | 'sm:tw-object-top'
  | 'md:tw-object-top'
  | 'lg:tw-object-top'
  | 'xl:tw-object-top'
  | 'sm:tw-opacity-0'
  | 'md:tw-opacity-0'
  | 'lg:tw-opacity-0'
  | 'xl:tw-opacity-0'
  | 'hover:tw-opacity-0'
  | 'focus:tw-opacity-0'
  | 'sm:tw-opacity-25'
  | 'md:tw-opacity-25'
  | 'lg:tw-opacity-25'
  | 'xl:tw-opacity-25'
  | 'hover:tw-opacity-25'
  | 'focus:tw-opacity-25'
  | 'sm:tw-opacity-50'
  | 'md:tw-opacity-50'
  | 'lg:tw-opacity-50'
  | 'xl:tw-opacity-50'
  | 'hover:tw-opacity-50'
  | 'focus:tw-opacity-50'
  | 'sm:tw-opacity-75'
  | 'md:tw-opacity-75'
  | 'lg:tw-opacity-75'
  | 'xl:tw-opacity-75'
  | 'hover:tw-opacity-75'
  | 'focus:tw-opacity-75'
  | 'sm:tw-opacity-100'
  | 'md:tw-opacity-100'
  | 'lg:tw-opacity-100'
  | 'xl:tw-opacity-100'
  | 'hover:tw-opacity-100'
  | 'focus:tw-opacity-100'
  | 'sm:tw-order-1'
  | 'md:tw-order-1'
  | 'lg:tw-order-1'
  | 'xl:tw-order-1'
  | 'sm:tw-order-2'
  | 'md:tw-order-2'
  | 'lg:tw-order-2'
  | 'xl:tw-order-2'
  | 'sm:tw-order-3'
  | 'md:tw-order-3'
  | 'lg:tw-order-3'
  | 'xl:tw-order-3'
  | 'sm:tw-order-4'
  | 'md:tw-order-4'
  | 'lg:tw-order-4'
  | 'xl:tw-order-4'
  | 'sm:tw-order-5'
  | 'md:tw-order-5'
  | 'lg:tw-order-5'
  | 'xl:tw-order-5'
  | 'sm:tw-order-6'
  | 'md:tw-order-6'
  | 'lg:tw-order-6'
  | 'xl:tw-order-6'
  | 'sm:tw-order-7'
  | 'md:tw-order-7'
  | 'lg:tw-order-7'
  | 'xl:tw-order-7'
  | 'sm:tw-order-8'
  | 'md:tw-order-8'
  | 'lg:tw-order-8'
  | 'xl:tw-order-8'
  | 'sm:tw-order-9'
  | 'md:tw-order-9'
  | 'lg:tw-order-9'
  | 'xl:tw-order-9'
  | 'sm:tw-order-10'
  | 'md:tw-order-10'
  | 'lg:tw-order-10'
  | 'xl:tw-order-10'
  | 'sm:tw-order-11'
  | 'md:tw-order-11'
  | 'lg:tw-order-11'
  | 'xl:tw-order-11'
  | 'sm:tw-order-12'
  | 'md:tw-order-12'
  | 'lg:tw-order-12'
  | 'xl:tw-order-12'
  | 'sm:tw-order-first'
  | 'md:tw-order-first'
  | 'lg:tw-order-first'
  | 'xl:tw-order-first'
  | 'sm:tw-order-last'
  | 'md:tw-order-last'
  | 'lg:tw-order-last'
  | 'xl:tw-order-last'
  | 'sm:tw-order-none'
  | 'md:tw-order-none'
  | 'lg:tw-order-none'
  | 'xl:tw-order-none'
  | 'sm:tw-outline-none'
  | 'md:tw-outline-none'
  | 'lg:tw-outline-none'
  | 'xl:tw-outline-none'
  | 'focus:tw-outline-none'
  | 'sm:tw-outline-white'
  | 'md:tw-outline-white'
  | 'lg:tw-outline-white'
  | 'xl:tw-outline-white'
  | 'focus:tw-outline-white'
  | 'sm:tw-outline-black'
  | 'md:tw-outline-black'
  | 'lg:tw-outline-black'
  | 'xl:tw-outline-black'
  | 'focus:tw-outline-black'
  | 'sm:tw-overflow-auto'
  | 'md:tw-overflow-auto'
  | 'lg:tw-overflow-auto'
  | 'xl:tw-overflow-auto'
  | 'sm:tw-overflow-hidden'
  | 'md:tw-overflow-hidden'
  | 'lg:tw-overflow-hidden'
  | 'xl:tw-overflow-hidden'
  | 'sm:tw-overflow-visible'
  | 'md:tw-overflow-visible'
  | 'lg:tw-overflow-visible'
  | 'xl:tw-overflow-visible'
  | 'sm:tw-overflow-scroll'
  | 'md:tw-overflow-scroll'
  | 'lg:tw-overflow-scroll'
  | 'xl:tw-overflow-scroll'
  | 'sm:tw-overflow-x-auto'
  | 'md:tw-overflow-x-auto'
  | 'lg:tw-overflow-x-auto'
  | 'xl:tw-overflow-x-auto'
  | 'sm:tw-overflow-y-auto'
  | 'md:tw-overflow-y-auto'
  | 'lg:tw-overflow-y-auto'
  | 'xl:tw-overflow-y-auto'
  | 'sm:tw-overflow-x-hidden'
  | 'md:tw-overflow-x-hidden'
  | 'lg:tw-overflow-x-hidden'
  | 'xl:tw-overflow-x-hidden'
  | 'sm:tw-overflow-y-hidden'
  | 'md:tw-overflow-y-hidden'
  | 'lg:tw-overflow-y-hidden'
  | 'xl:tw-overflow-y-hidden'
  | 'sm:tw-overflow-x-visible'
  | 'md:tw-overflow-x-visible'
  | 'lg:tw-overflow-x-visible'
  | 'xl:tw-overflow-x-visible'
  | 'sm:tw-overflow-y-visible'
  | 'md:tw-overflow-y-visible'
  | 'lg:tw-overflow-y-visible'
  | 'xl:tw-overflow-y-visible'
  | 'sm:tw-overflow-x-scroll'
  | 'md:tw-overflow-x-scroll'
  | 'lg:tw-overflow-x-scroll'
  | 'xl:tw-overflow-x-scroll'
  | 'sm:tw-overflow-y-scroll'
  | 'md:tw-overflow-y-scroll'
  | 'lg:tw-overflow-y-scroll'
  | 'xl:tw-overflow-y-scroll'
  | 'sm:tw-scrolling-touch'
  | 'md:tw-scrolling-touch'
  | 'lg:tw-scrolling-touch'
  | 'xl:tw-scrolling-touch'
  | 'sm:tw-scrolling-auto'
  | 'md:tw-scrolling-auto'
  | 'lg:tw-scrolling-auto'
  | 'xl:tw-scrolling-auto'
  | 'sm:tw-overscroll-auto'
  | 'md:tw-overscroll-auto'
  | 'lg:tw-overscroll-auto'
  | 'xl:tw-overscroll-auto'
  | 'sm:tw-overscroll-contain'
  | 'md:tw-overscroll-contain'
  | 'lg:tw-overscroll-contain'
  | 'xl:tw-overscroll-contain'
  | 'sm:tw-overscroll-none'
  | 'md:tw-overscroll-none'
  | 'lg:tw-overscroll-none'
  | 'xl:tw-overscroll-none'
  | 'sm:tw-overscroll-y-auto'
  | 'md:tw-overscroll-y-auto'
  | 'lg:tw-overscroll-y-auto'
  | 'xl:tw-overscroll-y-auto'
  | 'sm:tw-overscroll-y-contain'
  | 'md:tw-overscroll-y-contain'
  | 'lg:tw-overscroll-y-contain'
  | 'xl:tw-overscroll-y-contain'
  | 'sm:tw-overscroll-y-none'
  | 'md:tw-overscroll-y-none'
  | 'lg:tw-overscroll-y-none'
  | 'xl:tw-overscroll-y-none'
  | 'sm:tw-overscroll-x-auto'
  | 'md:tw-overscroll-x-auto'
  | 'lg:tw-overscroll-x-auto'
  | 'xl:tw-overscroll-x-auto'
  | 'sm:tw-overscroll-x-contain'
  | 'md:tw-overscroll-x-contain'
  | 'lg:tw-overscroll-x-contain'
  | 'xl:tw-overscroll-x-contain'
  | 'sm:tw-overscroll-x-none'
  | 'md:tw-overscroll-x-none'
  | 'lg:tw-overscroll-x-none'
  | 'xl:tw-overscroll-x-none'
  | 'sm:tw-p-0'
  | 'md:tw-p-0'
  | 'lg:tw-p-0'
  | 'xl:tw-p-0'
  | 'sm:tw-p-1'
  | 'md:tw-p-1'
  | 'lg:tw-p-1'
  | 'xl:tw-p-1'
  | 'sm:tw-p-2'
  | 'md:tw-p-2'
  | 'lg:tw-p-2'
  | 'xl:tw-p-2'
  | 'sm:tw-p-3'
  | 'md:tw-p-3'
  | 'lg:tw-p-3'
  | 'xl:tw-p-3'
  | 'sm:tw-p-4'
  | 'md:tw-p-4'
  | 'lg:tw-p-4'
  | 'xl:tw-p-4'
  | 'sm:tw-p-5'
  | 'md:tw-p-5'
  | 'lg:tw-p-5'
  | 'xl:tw-p-5'
  | 'sm:tw-p-6'
  | 'md:tw-p-6'
  | 'lg:tw-p-6'
  | 'xl:tw-p-6'
  | 'sm:tw-p-8'
  | 'md:tw-p-8'
  | 'lg:tw-p-8'
  | 'xl:tw-p-8'
  | 'sm:tw-p-10'
  | 'md:tw-p-10'
  | 'lg:tw-p-10'
  | 'xl:tw-p-10'
  | 'sm:tw-p-12'
  | 'md:tw-p-12'
  | 'lg:tw-p-12'
  | 'xl:tw-p-12'
  | 'sm:tw-p-16'
  | 'md:tw-p-16'
  | 'lg:tw-p-16'
  | 'xl:tw-p-16'
  | 'sm:tw-p-20'
  | 'md:tw-p-20'
  | 'lg:tw-p-20'
  | 'xl:tw-p-20'
  | 'sm:tw-p-24'
  | 'md:tw-p-24'
  | 'lg:tw-p-24'
  | 'xl:tw-p-24'
  | 'sm:tw-p-32'
  | 'md:tw-p-32'
  | 'lg:tw-p-32'
  | 'xl:tw-p-32'
  | 'sm:tw-p-40'
  | 'md:tw-p-40'
  | 'lg:tw-p-40'
  | 'xl:tw-p-40'
  | 'sm:tw-p-48'
  | 'md:tw-p-48'
  | 'lg:tw-p-48'
  | 'xl:tw-p-48'
  | 'sm:tw-p-56'
  | 'md:tw-p-56'
  | 'lg:tw-p-56'
  | 'xl:tw-p-56'
  | 'sm:tw-p-64'
  | 'md:tw-p-64'
  | 'lg:tw-p-64'
  | 'xl:tw-p-64'
  | 'sm:tw-p-px'
  | 'md:tw-p-px'
  | 'lg:tw-p-px'
  | 'xl:tw-p-px'
  | 'sm:tw-py-0'
  | 'md:tw-py-0'
  | 'lg:tw-py-0'
  | 'xl:tw-py-0'
  | 'sm:tw-py-1'
  | 'md:tw-py-1'
  | 'lg:tw-py-1'
  | 'xl:tw-py-1'
  | 'sm:tw-py-2'
  | 'md:tw-py-2'
  | 'lg:tw-py-2'
  | 'xl:tw-py-2'
  | 'sm:tw-py-3'
  | 'md:tw-py-3'
  | 'lg:tw-py-3'
  | 'xl:tw-py-3'
  | 'sm:tw-py-4'
  | 'md:tw-py-4'
  | 'lg:tw-py-4'
  | 'xl:tw-py-4'
  | 'sm:tw-py-5'
  | 'md:tw-py-5'
  | 'lg:tw-py-5'
  | 'xl:tw-py-5'
  | 'sm:tw-py-6'
  | 'md:tw-py-6'
  | 'lg:tw-py-6'
  | 'xl:tw-py-6'
  | 'sm:tw-py-8'
  | 'md:tw-py-8'
  | 'lg:tw-py-8'
  | 'xl:tw-py-8'
  | 'sm:tw-py-10'
  | 'md:tw-py-10'
  | 'lg:tw-py-10'
  | 'xl:tw-py-10'
  | 'sm:tw-py-12'
  | 'md:tw-py-12'
  | 'lg:tw-py-12'
  | 'xl:tw-py-12'
  | 'sm:tw-py-16'
  | 'md:tw-py-16'
  | 'lg:tw-py-16'
  | 'xl:tw-py-16'
  | 'sm:tw-py-20'
  | 'md:tw-py-20'
  | 'lg:tw-py-20'
  | 'xl:tw-py-20'
  | 'sm:tw-py-24'
  | 'md:tw-py-24'
  | 'lg:tw-py-24'
  | 'xl:tw-py-24'
  | 'sm:tw-py-32'
  | 'md:tw-py-32'
  | 'lg:tw-py-32'
  | 'xl:tw-py-32'
  | 'sm:tw-py-40'
  | 'md:tw-py-40'
  | 'lg:tw-py-40'
  | 'xl:tw-py-40'
  | 'sm:tw-py-48'
  | 'md:tw-py-48'
  | 'lg:tw-py-48'
  | 'xl:tw-py-48'
  | 'sm:tw-py-56'
  | 'md:tw-py-56'
  | 'lg:tw-py-56'
  | 'xl:tw-py-56'
  | 'sm:tw-py-64'
  | 'md:tw-py-64'
  | 'lg:tw-py-64'
  | 'xl:tw-py-64'
  | 'sm:tw-py-px'
  | 'md:tw-py-px'
  | 'lg:tw-py-px'
  | 'xl:tw-py-px'
  | 'sm:tw-px-0'
  | 'md:tw-px-0'
  | 'lg:tw-px-0'
  | 'xl:tw-px-0'
  | 'sm:tw-px-1'
  | 'md:tw-px-1'
  | 'lg:tw-px-1'
  | 'xl:tw-px-1'
  | 'sm:tw-px-2'
  | 'md:tw-px-2'
  | 'lg:tw-px-2'
  | 'xl:tw-px-2'
  | 'sm:tw-px-3'
  | 'md:tw-px-3'
  | 'lg:tw-px-3'
  | 'xl:tw-px-3'
  | 'sm:tw-px-4'
  | 'md:tw-px-4'
  | 'lg:tw-px-4'
  | 'xl:tw-px-4'
  | 'sm:tw-px-5'
  | 'md:tw-px-5'
  | 'lg:tw-px-5'
  | 'xl:tw-px-5'
  | 'sm:tw-px-6'
  | 'md:tw-px-6'
  | 'lg:tw-px-6'
  | 'xl:tw-px-6'
  | 'sm:tw-px-8'
  | 'md:tw-px-8'
  | 'lg:tw-px-8'
  | 'xl:tw-px-8'
  | 'sm:tw-px-10'
  | 'md:tw-px-10'
  | 'lg:tw-px-10'
  | 'xl:tw-px-10'
  | 'sm:tw-px-12'
  | 'md:tw-px-12'
  | 'lg:tw-px-12'
  | 'xl:tw-px-12'
  | 'sm:tw-px-16'
  | 'md:tw-px-16'
  | 'lg:tw-px-16'
  | 'xl:tw-px-16'
  | 'sm:tw-px-20'
  | 'md:tw-px-20'
  | 'lg:tw-px-20'
  | 'xl:tw-px-20'
  | 'sm:tw-px-24'
  | 'md:tw-px-24'
  | 'lg:tw-px-24'
  | 'xl:tw-px-24'
  | 'sm:tw-px-32'
  | 'md:tw-px-32'
  | 'lg:tw-px-32'
  | 'xl:tw-px-32'
  | 'sm:tw-px-40'
  | 'md:tw-px-40'
  | 'lg:tw-px-40'
  | 'xl:tw-px-40'
  | 'sm:tw-px-48'
  | 'md:tw-px-48'
  | 'lg:tw-px-48'
  | 'xl:tw-px-48'
  | 'sm:tw-px-56'
  | 'md:tw-px-56'
  | 'lg:tw-px-56'
  | 'xl:tw-px-56'
  | 'sm:tw-px-64'
  | 'md:tw-px-64'
  | 'lg:tw-px-64'
  | 'xl:tw-px-64'
  | 'sm:tw-px-px'
  | 'md:tw-px-px'
  | 'lg:tw-px-px'
  | 'xl:tw-px-px'
  | 'sm:tw-pt-0'
  | 'md:tw-pt-0'
  | 'lg:tw-pt-0'
  | 'xl:tw-pt-0'
  | 'sm:tw-pt-1'
  | 'md:tw-pt-1'
  | 'lg:tw-pt-1'
  | 'xl:tw-pt-1'
  | 'sm:tw-pt-2'
  | 'md:tw-pt-2'
  | 'lg:tw-pt-2'
  | 'xl:tw-pt-2'
  | 'sm:tw-pt-3'
  | 'md:tw-pt-3'
  | 'lg:tw-pt-3'
  | 'xl:tw-pt-3'
  | 'sm:tw-pt-4'
  | 'md:tw-pt-4'
  | 'lg:tw-pt-4'
  | 'xl:tw-pt-4'
  | 'sm:tw-pt-5'
  | 'md:tw-pt-5'
  | 'lg:tw-pt-5'
  | 'xl:tw-pt-5'
  | 'sm:tw-pt-6'
  | 'md:tw-pt-6'
  | 'lg:tw-pt-6'
  | 'xl:tw-pt-6'
  | 'sm:tw-pt-8'
  | 'md:tw-pt-8'
  | 'lg:tw-pt-8'
  | 'xl:tw-pt-8'
  | 'sm:tw-pt-10'
  | 'md:tw-pt-10'
  | 'lg:tw-pt-10'
  | 'xl:tw-pt-10'
  | 'sm:tw-pt-12'
  | 'md:tw-pt-12'
  | 'lg:tw-pt-12'
  | 'xl:tw-pt-12'
  | 'sm:tw-pt-16'
  | 'md:tw-pt-16'
  | 'lg:tw-pt-16'
  | 'xl:tw-pt-16'
  | 'sm:tw-pt-20'
  | 'md:tw-pt-20'
  | 'lg:tw-pt-20'
  | 'xl:tw-pt-20'
  | 'sm:tw-pt-24'
  | 'md:tw-pt-24'
  | 'lg:tw-pt-24'
  | 'xl:tw-pt-24'
  | 'sm:tw-pt-32'
  | 'md:tw-pt-32'
  | 'lg:tw-pt-32'
  | 'xl:tw-pt-32'
  | 'sm:tw-pt-40'
  | 'md:tw-pt-40'
  | 'lg:tw-pt-40'
  | 'xl:tw-pt-40'
  | 'sm:tw-pt-48'
  | 'md:tw-pt-48'
  | 'lg:tw-pt-48'
  | 'xl:tw-pt-48'
  | 'sm:tw-pt-56'
  | 'md:tw-pt-56'
  | 'lg:tw-pt-56'
  | 'xl:tw-pt-56'
  | 'sm:tw-pt-64'
  | 'md:tw-pt-64'
  | 'lg:tw-pt-64'
  | 'xl:tw-pt-64'
  | 'sm:tw-pt-px'
  | 'md:tw-pt-px'
  | 'lg:tw-pt-px'
  | 'xl:tw-pt-px'
  | 'sm:tw-pr-0'
  | 'md:tw-pr-0'
  | 'lg:tw-pr-0'
  | 'xl:tw-pr-0'
  | 'sm:tw-pr-1'
  | 'md:tw-pr-1'
  | 'lg:tw-pr-1'
  | 'xl:tw-pr-1'
  | 'sm:tw-pr-2'
  | 'md:tw-pr-2'
  | 'lg:tw-pr-2'
  | 'xl:tw-pr-2'
  | 'sm:tw-pr-3'
  | 'md:tw-pr-3'
  | 'lg:tw-pr-3'
  | 'xl:tw-pr-3'
  | 'sm:tw-pr-4'
  | 'md:tw-pr-4'
  | 'lg:tw-pr-4'
  | 'xl:tw-pr-4'
  | 'sm:tw-pr-5'
  | 'md:tw-pr-5'
  | 'lg:tw-pr-5'
  | 'xl:tw-pr-5'
  | 'sm:tw-pr-6'
  | 'md:tw-pr-6'
  | 'lg:tw-pr-6'
  | 'xl:tw-pr-6'
  | 'sm:tw-pr-8'
  | 'md:tw-pr-8'
  | 'lg:tw-pr-8'
  | 'xl:tw-pr-8'
  | 'sm:tw-pr-10'
  | 'md:tw-pr-10'
  | 'lg:tw-pr-10'
  | 'xl:tw-pr-10'
  | 'sm:tw-pr-12'
  | 'md:tw-pr-12'
  | 'lg:tw-pr-12'
  | 'xl:tw-pr-12'
  | 'sm:tw-pr-16'
  | 'md:tw-pr-16'
  | 'lg:tw-pr-16'
  | 'xl:tw-pr-16'
  | 'sm:tw-pr-20'
  | 'md:tw-pr-20'
  | 'lg:tw-pr-20'
  | 'xl:tw-pr-20'
  | 'sm:tw-pr-24'
  | 'md:tw-pr-24'
  | 'lg:tw-pr-24'
  | 'xl:tw-pr-24'
  | 'sm:tw-pr-32'
  | 'md:tw-pr-32'
  | 'lg:tw-pr-32'
  | 'xl:tw-pr-32'
  | 'sm:tw-pr-40'
  | 'md:tw-pr-40'
  | 'lg:tw-pr-40'
  | 'xl:tw-pr-40'
  | 'sm:tw-pr-48'
  | 'md:tw-pr-48'
  | 'lg:tw-pr-48'
  | 'xl:tw-pr-48'
  | 'sm:tw-pr-56'
  | 'md:tw-pr-56'
  | 'lg:tw-pr-56'
  | 'xl:tw-pr-56'
  | 'sm:tw-pr-64'
  | 'md:tw-pr-64'
  | 'lg:tw-pr-64'
  | 'xl:tw-pr-64'
  | 'sm:tw-pr-px'
  | 'md:tw-pr-px'
  | 'lg:tw-pr-px'
  | 'xl:tw-pr-px'
  | 'sm:tw-pb-0'
  | 'md:tw-pb-0'
  | 'lg:tw-pb-0'
  | 'xl:tw-pb-0'
  | 'sm:tw-pb-1'
  | 'md:tw-pb-1'
  | 'lg:tw-pb-1'
  | 'xl:tw-pb-1'
  | 'sm:tw-pb-2'
  | 'md:tw-pb-2'
  | 'lg:tw-pb-2'
  | 'xl:tw-pb-2'
  | 'sm:tw-pb-3'
  | 'md:tw-pb-3'
  | 'lg:tw-pb-3'
  | 'xl:tw-pb-3'
  | 'sm:tw-pb-4'
  | 'md:tw-pb-4'
  | 'lg:tw-pb-4'
  | 'xl:tw-pb-4'
  | 'sm:tw-pb-5'
  | 'md:tw-pb-5'
  | 'lg:tw-pb-5'
  | 'xl:tw-pb-5'
  | 'sm:tw-pb-6'
  | 'md:tw-pb-6'
  | 'lg:tw-pb-6'
  | 'xl:tw-pb-6'
  | 'sm:tw-pb-8'
  | 'md:tw-pb-8'
  | 'lg:tw-pb-8'
  | 'xl:tw-pb-8'
  | 'sm:tw-pb-10'
  | 'md:tw-pb-10'
  | 'lg:tw-pb-10'
  | 'xl:tw-pb-10'
  | 'sm:tw-pb-12'
  | 'md:tw-pb-12'
  | 'lg:tw-pb-12'
  | 'xl:tw-pb-12'
  | 'sm:tw-pb-16'
  | 'md:tw-pb-16'
  | 'lg:tw-pb-16'
  | 'xl:tw-pb-16'
  | 'sm:tw-pb-20'
  | 'md:tw-pb-20'
  | 'lg:tw-pb-20'
  | 'xl:tw-pb-20'
  | 'sm:tw-pb-24'
  | 'md:tw-pb-24'
  | 'lg:tw-pb-24'
  | 'xl:tw-pb-24'
  | 'sm:tw-pb-32'
  | 'md:tw-pb-32'
  | 'lg:tw-pb-32'
  | 'xl:tw-pb-32'
  | 'sm:tw-pb-40'
  | 'md:tw-pb-40'
  | 'lg:tw-pb-40'
  | 'xl:tw-pb-40'
  | 'sm:tw-pb-48'
  | 'md:tw-pb-48'
  | 'lg:tw-pb-48'
  | 'xl:tw-pb-48'
  | 'sm:tw-pb-56'
  | 'md:tw-pb-56'
  | 'lg:tw-pb-56'
  | 'xl:tw-pb-56'
  | 'sm:tw-pb-64'
  | 'md:tw-pb-64'
  | 'lg:tw-pb-64'
  | 'xl:tw-pb-64'
  | 'sm:tw-pb-px'
  | 'md:tw-pb-px'
  | 'lg:tw-pb-px'
  | 'xl:tw-pb-px'
  | 'sm:tw-pl-0'
  | 'md:tw-pl-0'
  | 'lg:tw-pl-0'
  | 'xl:tw-pl-0'
  | 'sm:tw-pl-1'
  | 'md:tw-pl-1'
  | 'lg:tw-pl-1'
  | 'xl:tw-pl-1'
  | 'sm:tw-pl-2'
  | 'md:tw-pl-2'
  | 'lg:tw-pl-2'
  | 'xl:tw-pl-2'
  | 'sm:tw-pl-3'
  | 'md:tw-pl-3'
  | 'lg:tw-pl-3'
  | 'xl:tw-pl-3'
  | 'sm:tw-pl-4'
  | 'md:tw-pl-4'
  | 'lg:tw-pl-4'
  | 'xl:tw-pl-4'
  | 'sm:tw-pl-5'
  | 'md:tw-pl-5'
  | 'lg:tw-pl-5'
  | 'xl:tw-pl-5'
  | 'sm:tw-pl-6'
  | 'md:tw-pl-6'
  | 'lg:tw-pl-6'
  | 'xl:tw-pl-6'
  | 'sm:tw-pl-8'
  | 'md:tw-pl-8'
  | 'lg:tw-pl-8'
  | 'xl:tw-pl-8'
  | 'sm:tw-pl-10'
  | 'md:tw-pl-10'
  | 'lg:tw-pl-10'
  | 'xl:tw-pl-10'
  | 'sm:tw-pl-12'
  | 'md:tw-pl-12'
  | 'lg:tw-pl-12'
  | 'xl:tw-pl-12'
  | 'sm:tw-pl-16'
  | 'md:tw-pl-16'
  | 'lg:tw-pl-16'
  | 'xl:tw-pl-16'
  | 'sm:tw-pl-20'
  | 'md:tw-pl-20'
  | 'lg:tw-pl-20'
  | 'xl:tw-pl-20'
  | 'sm:tw-pl-24'
  | 'md:tw-pl-24'
  | 'lg:tw-pl-24'
  | 'xl:tw-pl-24'
  | 'sm:tw-pl-32'
  | 'md:tw-pl-32'
  | 'lg:tw-pl-32'
  | 'xl:tw-pl-32'
  | 'sm:tw-pl-40'
  | 'md:tw-pl-40'
  | 'lg:tw-pl-40'
  | 'xl:tw-pl-40'
  | 'sm:tw-pl-48'
  | 'md:tw-pl-48'
  | 'lg:tw-pl-48'
  | 'xl:tw-pl-48'
  | 'sm:tw-pl-56'
  | 'md:tw-pl-56'
  | 'lg:tw-pl-56'
  | 'xl:tw-pl-56'
  | 'sm:tw-pl-64'
  | 'md:tw-pl-64'
  | 'lg:tw-pl-64'
  | 'xl:tw-pl-64'
  | 'sm:tw-pl-px'
  | 'md:tw-pl-px'
  | 'lg:tw-pl-px'
  | 'xl:tw-pl-px'
  | 'sm:tw-place-content-start'
  | 'md:tw-place-content-start'
  | 'lg:tw-place-content-start'
  | 'xl:tw-place-content-start'
  | 'sm:tw-place-content-center'
  | 'md:tw-place-content-center'
  | 'lg:tw-place-content-center'
  | 'xl:tw-place-content-center'
  | 'sm:tw-place-content-end'
  | 'md:tw-place-content-end'
  | 'lg:tw-place-content-end'
  | 'xl:tw-place-content-end'
  | 'sm:tw-place-content-between'
  | 'md:tw-place-content-between'
  | 'lg:tw-place-content-between'
  | 'xl:tw-place-content-between'
  | 'sm:tw-place-content-around'
  | 'md:tw-place-content-around'
  | 'lg:tw-place-content-around'
  | 'xl:tw-place-content-around'
  | 'sm:tw-place-content-evenly'
  | 'md:tw-place-content-evenly'
  | 'lg:tw-place-content-evenly'
  | 'xl:tw-place-content-evenly'
  | 'sm:tw-place-content-stretch'
  | 'md:tw-place-content-stretch'
  | 'lg:tw-place-content-stretch'
  | 'xl:tw-place-content-stretch'
  | 'sm:tw-place-items-auto'
  | 'md:tw-place-items-auto'
  | 'lg:tw-place-items-auto'
  | 'xl:tw-place-items-auto'
  | 'sm:tw-place-items-start'
  | 'md:tw-place-items-start'
  | 'lg:tw-place-items-start'
  | 'xl:tw-place-items-start'
  | 'sm:tw-place-items-center'
  | 'md:tw-place-items-center'
  | 'lg:tw-place-items-center'
  | 'xl:tw-place-items-center'
  | 'sm:tw-place-items-end'
  | 'md:tw-place-items-end'
  | 'lg:tw-place-items-end'
  | 'xl:tw-place-items-end'
  | 'sm:tw-place-items-stretch'
  | 'md:tw-place-items-stretch'
  | 'lg:tw-place-items-stretch'
  | 'xl:tw-place-items-stretch'
  | 'sm:tw-place-self-auto'
  | 'md:tw-place-self-auto'
  | 'lg:tw-place-self-auto'
  | 'xl:tw-place-self-auto'
  | 'sm:tw-place-self-start'
  | 'md:tw-place-self-start'
  | 'lg:tw-place-self-start'
  | 'xl:tw-place-self-start'
  | 'sm:tw-place-self-center'
  | 'md:tw-place-self-center'
  | 'lg:tw-place-self-center'
  | 'xl:tw-place-self-center'
  | 'sm:tw-place-self-end'
  | 'md:tw-place-self-end'
  | 'lg:tw-place-self-end'
  | 'xl:tw-place-self-end'
  | 'sm:tw-place-self-stretch'
  | 'md:tw-place-self-stretch'
  | 'lg:tw-place-self-stretch'
  | 'xl:tw-place-self-stretch'
  | 'sm:tw-placeholder-primary'
  | 'md:tw-placeholder-primary'
  | 'lg:tw-placeholder-primary'
  | 'xl:tw-placeholder-primary'
  | 'focus:tw-placeholder-primary'
  | 'sm:tw-placeholder-muted'
  | 'md:tw-placeholder-muted'
  | 'lg:tw-placeholder-muted'
  | 'xl:tw-placeholder-muted'
  | 'focus:tw-placeholder-muted'
  | 'sm:tw-placeholder-warning'
  | 'md:tw-placeholder-warning'
  | 'lg:tw-placeholder-warning'
  | 'xl:tw-placeholder-warning'
  | 'focus:tw-placeholder-warning'
  | 'sm:tw-placeholder-danger'
  | 'md:tw-placeholder-danger'
  | 'lg:tw-placeholder-danger'
  | 'xl:tw-placeholder-danger'
  | 'focus:tw-placeholder-danger'
  | 'sm:tw-placeholder-fail'
  | 'md:tw-placeholder-fail'
  | 'lg:tw-placeholder-fail'
  | 'xl:tw-placeholder-fail'
  | 'focus:tw-placeholder-fail'
  | 'sm:tw-placeholder-info'
  | 'md:tw-placeholder-info'
  | 'lg:tw-placeholder-info'
  | 'xl:tw-placeholder-info'
  | 'focus:tw-placeholder-info'
  | 'sm:tw-placeholder-red-50'
  | 'md:tw-placeholder-red-50'
  | 'lg:tw-placeholder-red-50'
  | 'xl:tw-placeholder-red-50'
  | 'focus:tw-placeholder-red-50'
  | 'sm:tw-placeholder-red-100'
  | 'md:tw-placeholder-red-100'
  | 'lg:tw-placeholder-red-100'
  | 'xl:tw-placeholder-red-100'
  | 'focus:tw-placeholder-red-100'
  | 'sm:tw-placeholder-red-200'
  | 'md:tw-placeholder-red-200'
  | 'lg:tw-placeholder-red-200'
  | 'xl:tw-placeholder-red-200'
  | 'focus:tw-placeholder-red-200'
  | 'sm:tw-placeholder-red-300'
  | 'md:tw-placeholder-red-300'
  | 'lg:tw-placeholder-red-300'
  | 'xl:tw-placeholder-red-300'
  | 'focus:tw-placeholder-red-300'
  | 'sm:tw-placeholder-red-400'
  | 'md:tw-placeholder-red-400'
  | 'lg:tw-placeholder-red-400'
  | 'xl:tw-placeholder-red-400'
  | 'focus:tw-placeholder-red-400'
  | 'sm:tw-placeholder-red-500'
  | 'md:tw-placeholder-red-500'
  | 'lg:tw-placeholder-red-500'
  | 'xl:tw-placeholder-red-500'
  | 'focus:tw-placeholder-red-500'
  | 'sm:tw-placeholder-red-600'
  | 'md:tw-placeholder-red-600'
  | 'lg:tw-placeholder-red-600'
  | 'xl:tw-placeholder-red-600'
  | 'focus:tw-placeholder-red-600'
  | 'sm:tw-placeholder-red-700'
  | 'md:tw-placeholder-red-700'
  | 'lg:tw-placeholder-red-700'
  | 'xl:tw-placeholder-red-700'
  | 'focus:tw-placeholder-red-700'
  | 'sm:tw-placeholder-red-800'
  | 'md:tw-placeholder-red-800'
  | 'lg:tw-placeholder-red-800'
  | 'xl:tw-placeholder-red-800'
  | 'focus:tw-placeholder-red-800'
  | 'sm:tw-placeholder-red-900'
  | 'md:tw-placeholder-red-900'
  | 'lg:tw-placeholder-red-900'
  | 'xl:tw-placeholder-red-900'
  | 'focus:tw-placeholder-red-900'
  | 'sm:tw-placeholder-red-a100'
  | 'md:tw-placeholder-red-a100'
  | 'lg:tw-placeholder-red-a100'
  | 'xl:tw-placeholder-red-a100'
  | 'focus:tw-placeholder-red-a100'
  | 'sm:tw-placeholder-red-a200'
  | 'md:tw-placeholder-red-a200'
  | 'lg:tw-placeholder-red-a200'
  | 'xl:tw-placeholder-red-a200'
  | 'focus:tw-placeholder-red-a200'
  | 'sm:tw-placeholder-red-a400'
  | 'md:tw-placeholder-red-a400'
  | 'lg:tw-placeholder-red-a400'
  | 'xl:tw-placeholder-red-a400'
  | 'focus:tw-placeholder-red-a400'
  | 'sm:tw-placeholder-red-a700'
  | 'md:tw-placeholder-red-a700'
  | 'lg:tw-placeholder-red-a700'
  | 'xl:tw-placeholder-red-a700'
  | 'focus:tw-placeholder-red-a700'
  | 'sm:tw-placeholder-pink-50'
  | 'md:tw-placeholder-pink-50'
  | 'lg:tw-placeholder-pink-50'
  | 'xl:tw-placeholder-pink-50'
  | 'focus:tw-placeholder-pink-50'
  | 'sm:tw-placeholder-pink-100'
  | 'md:tw-placeholder-pink-100'
  | 'lg:tw-placeholder-pink-100'
  | 'xl:tw-placeholder-pink-100'
  | 'focus:tw-placeholder-pink-100'
  | 'sm:tw-placeholder-pink-200'
  | 'md:tw-placeholder-pink-200'
  | 'lg:tw-placeholder-pink-200'
  | 'xl:tw-placeholder-pink-200'
  | 'focus:tw-placeholder-pink-200'
  | 'sm:tw-placeholder-pink-300'
  | 'md:tw-placeholder-pink-300'
  | 'lg:tw-placeholder-pink-300'
  | 'xl:tw-placeholder-pink-300'
  | 'focus:tw-placeholder-pink-300'
  | 'sm:tw-placeholder-pink-400'
  | 'md:tw-placeholder-pink-400'
  | 'lg:tw-placeholder-pink-400'
  | 'xl:tw-placeholder-pink-400'
  | 'focus:tw-placeholder-pink-400'
  | 'sm:tw-placeholder-pink-500'
  | 'md:tw-placeholder-pink-500'
  | 'lg:tw-placeholder-pink-500'
  | 'xl:tw-placeholder-pink-500'
  | 'focus:tw-placeholder-pink-500'
  | 'sm:tw-placeholder-pink-600'
  | 'md:tw-placeholder-pink-600'
  | 'lg:tw-placeholder-pink-600'
  | 'xl:tw-placeholder-pink-600'
  | 'focus:tw-placeholder-pink-600'
  | 'sm:tw-placeholder-pink-700'
  | 'md:tw-placeholder-pink-700'
  | 'lg:tw-placeholder-pink-700'
  | 'xl:tw-placeholder-pink-700'
  | 'focus:tw-placeholder-pink-700'
  | 'sm:tw-placeholder-pink-800'
  | 'md:tw-placeholder-pink-800'
  | 'lg:tw-placeholder-pink-800'
  | 'xl:tw-placeholder-pink-800'
  | 'focus:tw-placeholder-pink-800'
  | 'sm:tw-placeholder-pink-900'
  | 'md:tw-placeholder-pink-900'
  | 'lg:tw-placeholder-pink-900'
  | 'xl:tw-placeholder-pink-900'
  | 'focus:tw-placeholder-pink-900'
  | 'sm:tw-placeholder-pink-a100'
  | 'md:tw-placeholder-pink-a100'
  | 'lg:tw-placeholder-pink-a100'
  | 'xl:tw-placeholder-pink-a100'
  | 'focus:tw-placeholder-pink-a100'
  | 'sm:tw-placeholder-pink-a200'
  | 'md:tw-placeholder-pink-a200'
  | 'lg:tw-placeholder-pink-a200'
  | 'xl:tw-placeholder-pink-a200'
  | 'focus:tw-placeholder-pink-a200'
  | 'sm:tw-placeholder-pink-a400'
  | 'md:tw-placeholder-pink-a400'
  | 'lg:tw-placeholder-pink-a400'
  | 'xl:tw-placeholder-pink-a400'
  | 'focus:tw-placeholder-pink-a400'
  | 'sm:tw-placeholder-pink-a700'
  | 'md:tw-placeholder-pink-a700'
  | 'lg:tw-placeholder-pink-a700'
  | 'xl:tw-placeholder-pink-a700'
  | 'focus:tw-placeholder-pink-a700'
  | 'sm:tw-placeholder-purple-50'
  | 'md:tw-placeholder-purple-50'
  | 'lg:tw-placeholder-purple-50'
  | 'xl:tw-placeholder-purple-50'
  | 'focus:tw-placeholder-purple-50'
  | 'sm:tw-placeholder-purple-100'
  | 'md:tw-placeholder-purple-100'
  | 'lg:tw-placeholder-purple-100'
  | 'xl:tw-placeholder-purple-100'
  | 'focus:tw-placeholder-purple-100'
  | 'sm:tw-placeholder-purple-200'
  | 'md:tw-placeholder-purple-200'
  | 'lg:tw-placeholder-purple-200'
  | 'xl:tw-placeholder-purple-200'
  | 'focus:tw-placeholder-purple-200'
  | 'sm:tw-placeholder-purple-300'
  | 'md:tw-placeholder-purple-300'
  | 'lg:tw-placeholder-purple-300'
  | 'xl:tw-placeholder-purple-300'
  | 'focus:tw-placeholder-purple-300'
  | 'sm:tw-placeholder-purple-400'
  | 'md:tw-placeholder-purple-400'
  | 'lg:tw-placeholder-purple-400'
  | 'xl:tw-placeholder-purple-400'
  | 'focus:tw-placeholder-purple-400'
  | 'sm:tw-placeholder-purple-500'
  | 'md:tw-placeholder-purple-500'
  | 'lg:tw-placeholder-purple-500'
  | 'xl:tw-placeholder-purple-500'
  | 'focus:tw-placeholder-purple-500'
  | 'sm:tw-placeholder-purple-600'
  | 'md:tw-placeholder-purple-600'
  | 'lg:tw-placeholder-purple-600'
  | 'xl:tw-placeholder-purple-600'
  | 'focus:tw-placeholder-purple-600'
  | 'sm:tw-placeholder-purple-700'
  | 'md:tw-placeholder-purple-700'
  | 'lg:tw-placeholder-purple-700'
  | 'xl:tw-placeholder-purple-700'
  | 'focus:tw-placeholder-purple-700'
  | 'sm:tw-placeholder-purple-800'
  | 'md:tw-placeholder-purple-800'
  | 'lg:tw-placeholder-purple-800'
  | 'xl:tw-placeholder-purple-800'
  | 'focus:tw-placeholder-purple-800'
  | 'sm:tw-placeholder-purple-900'
  | 'md:tw-placeholder-purple-900'
  | 'lg:tw-placeholder-purple-900'
  | 'xl:tw-placeholder-purple-900'
  | 'focus:tw-placeholder-purple-900'
  | 'sm:tw-placeholder-purple-a100'
  | 'md:tw-placeholder-purple-a100'
  | 'lg:tw-placeholder-purple-a100'
  | 'xl:tw-placeholder-purple-a100'
  | 'focus:tw-placeholder-purple-a100'
  | 'sm:tw-placeholder-purple-a200'
  | 'md:tw-placeholder-purple-a200'
  | 'lg:tw-placeholder-purple-a200'
  | 'xl:tw-placeholder-purple-a200'
  | 'focus:tw-placeholder-purple-a200'
  | 'sm:tw-placeholder-purple-a400'
  | 'md:tw-placeholder-purple-a400'
  | 'lg:tw-placeholder-purple-a400'
  | 'xl:tw-placeholder-purple-a400'
  | 'focus:tw-placeholder-purple-a400'
  | 'sm:tw-placeholder-purple-a700'
  | 'md:tw-placeholder-purple-a700'
  | 'lg:tw-placeholder-purple-a700'
  | 'xl:tw-placeholder-purple-a700'
  | 'focus:tw-placeholder-purple-a700'
  | 'sm:tw-placeholder-deepPurple-50'
  | 'md:tw-placeholder-deepPurple-50'
  | 'lg:tw-placeholder-deepPurple-50'
  | 'xl:tw-placeholder-deepPurple-50'
  | 'focus:tw-placeholder-deepPurple-50'
  | 'sm:tw-placeholder-deepPurple-100'
  | 'md:tw-placeholder-deepPurple-100'
  | 'lg:tw-placeholder-deepPurple-100'
  | 'xl:tw-placeholder-deepPurple-100'
  | 'focus:tw-placeholder-deepPurple-100'
  | 'sm:tw-placeholder-deepPurple-200'
  | 'md:tw-placeholder-deepPurple-200'
  | 'lg:tw-placeholder-deepPurple-200'
  | 'xl:tw-placeholder-deepPurple-200'
  | 'focus:tw-placeholder-deepPurple-200'
  | 'sm:tw-placeholder-deepPurple-300'
  | 'md:tw-placeholder-deepPurple-300'
  | 'lg:tw-placeholder-deepPurple-300'
  | 'xl:tw-placeholder-deepPurple-300'
  | 'focus:tw-placeholder-deepPurple-300'
  | 'sm:tw-placeholder-deepPurple-400'
  | 'md:tw-placeholder-deepPurple-400'
  | 'lg:tw-placeholder-deepPurple-400'
  | 'xl:tw-placeholder-deepPurple-400'
  | 'focus:tw-placeholder-deepPurple-400'
  | 'sm:tw-placeholder-deepPurple-500'
  | 'md:tw-placeholder-deepPurple-500'
  | 'lg:tw-placeholder-deepPurple-500'
  | 'xl:tw-placeholder-deepPurple-500'
  | 'focus:tw-placeholder-deepPurple-500'
  | 'sm:tw-placeholder-deepPurple-600'
  | 'md:tw-placeholder-deepPurple-600'
  | 'lg:tw-placeholder-deepPurple-600'
  | 'xl:tw-placeholder-deepPurple-600'
  | 'focus:tw-placeholder-deepPurple-600'
  | 'sm:tw-placeholder-deepPurple-700'
  | 'md:tw-placeholder-deepPurple-700'
  | 'lg:tw-placeholder-deepPurple-700'
  | 'xl:tw-placeholder-deepPurple-700'
  | 'focus:tw-placeholder-deepPurple-700'
  | 'sm:tw-placeholder-deepPurple-800'
  | 'md:tw-placeholder-deepPurple-800'
  | 'lg:tw-placeholder-deepPurple-800'
  | 'xl:tw-placeholder-deepPurple-800'
  | 'focus:tw-placeholder-deepPurple-800'
  | 'sm:tw-placeholder-deepPurple-900'
  | 'md:tw-placeholder-deepPurple-900'
  | 'lg:tw-placeholder-deepPurple-900'
  | 'xl:tw-placeholder-deepPurple-900'
  | 'focus:tw-placeholder-deepPurple-900'
  | 'sm:tw-placeholder-deepPurple-a100'
  | 'md:tw-placeholder-deepPurple-a100'
  | 'lg:tw-placeholder-deepPurple-a100'
  | 'xl:tw-placeholder-deepPurple-a100'
  | 'focus:tw-placeholder-deepPurple-a100'
  | 'sm:tw-placeholder-deepPurple-a200'
  | 'md:tw-placeholder-deepPurple-a200'
  | 'lg:tw-placeholder-deepPurple-a200'
  | 'xl:tw-placeholder-deepPurple-a200'
  | 'focus:tw-placeholder-deepPurple-a200'
  | 'sm:tw-placeholder-deepPurple-a400'
  | 'md:tw-placeholder-deepPurple-a400'
  | 'lg:tw-placeholder-deepPurple-a400'
  | 'xl:tw-placeholder-deepPurple-a400'
  | 'focus:tw-placeholder-deepPurple-a400'
  | 'sm:tw-placeholder-deepPurple-a700'
  | 'md:tw-placeholder-deepPurple-a700'
  | 'lg:tw-placeholder-deepPurple-a700'
  | 'xl:tw-placeholder-deepPurple-a700'
  | 'focus:tw-placeholder-deepPurple-a700'
  | 'sm:tw-placeholder-indigo-50'
  | 'md:tw-placeholder-indigo-50'
  | 'lg:tw-placeholder-indigo-50'
  | 'xl:tw-placeholder-indigo-50'
  | 'focus:tw-placeholder-indigo-50'
  | 'sm:tw-placeholder-indigo-100'
  | 'md:tw-placeholder-indigo-100'
  | 'lg:tw-placeholder-indigo-100'
  | 'xl:tw-placeholder-indigo-100'
  | 'focus:tw-placeholder-indigo-100'
  | 'sm:tw-placeholder-indigo-200'
  | 'md:tw-placeholder-indigo-200'
  | 'lg:tw-placeholder-indigo-200'
  | 'xl:tw-placeholder-indigo-200'
  | 'focus:tw-placeholder-indigo-200'
  | 'sm:tw-placeholder-indigo-300'
  | 'md:tw-placeholder-indigo-300'
  | 'lg:tw-placeholder-indigo-300'
  | 'xl:tw-placeholder-indigo-300'
  | 'focus:tw-placeholder-indigo-300'
  | 'sm:tw-placeholder-indigo-400'
  | 'md:tw-placeholder-indigo-400'
  | 'lg:tw-placeholder-indigo-400'
  | 'xl:tw-placeholder-indigo-400'
  | 'focus:tw-placeholder-indigo-400'
  | 'sm:tw-placeholder-indigo-500'
  | 'md:tw-placeholder-indigo-500'
  | 'lg:tw-placeholder-indigo-500'
  | 'xl:tw-placeholder-indigo-500'
  | 'focus:tw-placeholder-indigo-500'
  | 'sm:tw-placeholder-indigo-600'
  | 'md:tw-placeholder-indigo-600'
  | 'lg:tw-placeholder-indigo-600'
  | 'xl:tw-placeholder-indigo-600'
  | 'focus:tw-placeholder-indigo-600'
  | 'sm:tw-placeholder-indigo-700'
  | 'md:tw-placeholder-indigo-700'
  | 'lg:tw-placeholder-indigo-700'
  | 'xl:tw-placeholder-indigo-700'
  | 'focus:tw-placeholder-indigo-700'
  | 'sm:tw-placeholder-indigo-800'
  | 'md:tw-placeholder-indigo-800'
  | 'lg:tw-placeholder-indigo-800'
  | 'xl:tw-placeholder-indigo-800'
  | 'focus:tw-placeholder-indigo-800'
  | 'sm:tw-placeholder-indigo-900'
  | 'md:tw-placeholder-indigo-900'
  | 'lg:tw-placeholder-indigo-900'
  | 'xl:tw-placeholder-indigo-900'
  | 'focus:tw-placeholder-indigo-900'
  | 'sm:tw-placeholder-indigo-a100'
  | 'md:tw-placeholder-indigo-a100'
  | 'lg:tw-placeholder-indigo-a100'
  | 'xl:tw-placeholder-indigo-a100'
  | 'focus:tw-placeholder-indigo-a100'
  | 'sm:tw-placeholder-indigo-a200'
  | 'md:tw-placeholder-indigo-a200'
  | 'lg:tw-placeholder-indigo-a200'
  | 'xl:tw-placeholder-indigo-a200'
  | 'focus:tw-placeholder-indigo-a200'
  | 'sm:tw-placeholder-indigo-a400'
  | 'md:tw-placeholder-indigo-a400'
  | 'lg:tw-placeholder-indigo-a400'
  | 'xl:tw-placeholder-indigo-a400'
  | 'focus:tw-placeholder-indigo-a400'
  | 'sm:tw-placeholder-indigo-a700'
  | 'md:tw-placeholder-indigo-a700'
  | 'lg:tw-placeholder-indigo-a700'
  | 'xl:tw-placeholder-indigo-a700'
  | 'focus:tw-placeholder-indigo-a700'
  | 'sm:tw-placeholder-blue-50'
  | 'md:tw-placeholder-blue-50'
  | 'lg:tw-placeholder-blue-50'
  | 'xl:tw-placeholder-blue-50'
  | 'focus:tw-placeholder-blue-50'
  | 'sm:tw-placeholder-blue-100'
  | 'md:tw-placeholder-blue-100'
  | 'lg:tw-placeholder-blue-100'
  | 'xl:tw-placeholder-blue-100'
  | 'focus:tw-placeholder-blue-100'
  | 'sm:tw-placeholder-blue-200'
  | 'md:tw-placeholder-blue-200'
  | 'lg:tw-placeholder-blue-200'
  | 'xl:tw-placeholder-blue-200'
  | 'focus:tw-placeholder-blue-200'
  | 'sm:tw-placeholder-blue-300'
  | 'md:tw-placeholder-blue-300'
  | 'lg:tw-placeholder-blue-300'
  | 'xl:tw-placeholder-blue-300'
  | 'focus:tw-placeholder-blue-300'
  | 'sm:tw-placeholder-blue-400'
  | 'md:tw-placeholder-blue-400'
  | 'lg:tw-placeholder-blue-400'
  | 'xl:tw-placeholder-blue-400'
  | 'focus:tw-placeholder-blue-400'
  | 'sm:tw-placeholder-blue-500'
  | 'md:tw-placeholder-blue-500'
  | 'lg:tw-placeholder-blue-500'
  | 'xl:tw-placeholder-blue-500'
  | 'focus:tw-placeholder-blue-500'
  | 'sm:tw-placeholder-blue-600'
  | 'md:tw-placeholder-blue-600'
  | 'lg:tw-placeholder-blue-600'
  | 'xl:tw-placeholder-blue-600'
  | 'focus:tw-placeholder-blue-600'
  | 'sm:tw-placeholder-blue-700'
  | 'md:tw-placeholder-blue-700'
  | 'lg:tw-placeholder-blue-700'
  | 'xl:tw-placeholder-blue-700'
  | 'focus:tw-placeholder-blue-700'
  | 'sm:tw-placeholder-blue-800'
  | 'md:tw-placeholder-blue-800'
  | 'lg:tw-placeholder-blue-800'
  | 'xl:tw-placeholder-blue-800'
  | 'focus:tw-placeholder-blue-800'
  | 'sm:tw-placeholder-blue-900'
  | 'md:tw-placeholder-blue-900'
  | 'lg:tw-placeholder-blue-900'
  | 'xl:tw-placeholder-blue-900'
  | 'focus:tw-placeholder-blue-900'
  | 'sm:tw-placeholder-blue-a100'
  | 'md:tw-placeholder-blue-a100'
  | 'lg:tw-placeholder-blue-a100'
  | 'xl:tw-placeholder-blue-a100'
  | 'focus:tw-placeholder-blue-a100'
  | 'sm:tw-placeholder-blue-a200'
  | 'md:tw-placeholder-blue-a200'
  | 'lg:tw-placeholder-blue-a200'
  | 'xl:tw-placeholder-blue-a200'
  | 'focus:tw-placeholder-blue-a200'
  | 'sm:tw-placeholder-blue-a400'
  | 'md:tw-placeholder-blue-a400'
  | 'lg:tw-placeholder-blue-a400'
  | 'xl:tw-placeholder-blue-a400'
  | 'focus:tw-placeholder-blue-a400'
  | 'sm:tw-placeholder-blue-a700'
  | 'md:tw-placeholder-blue-a700'
  | 'lg:tw-placeholder-blue-a700'
  | 'xl:tw-placeholder-blue-a700'
  | 'focus:tw-placeholder-blue-a700'
  | 'sm:tw-placeholder-lightBlue-50'
  | 'md:tw-placeholder-lightBlue-50'
  | 'lg:tw-placeholder-lightBlue-50'
  | 'xl:tw-placeholder-lightBlue-50'
  | 'focus:tw-placeholder-lightBlue-50'
  | 'sm:tw-placeholder-lightBlue-100'
  | 'md:tw-placeholder-lightBlue-100'
  | 'lg:tw-placeholder-lightBlue-100'
  | 'xl:tw-placeholder-lightBlue-100'
  | 'focus:tw-placeholder-lightBlue-100'
  | 'sm:tw-placeholder-lightBlue-200'
  | 'md:tw-placeholder-lightBlue-200'
  | 'lg:tw-placeholder-lightBlue-200'
  | 'xl:tw-placeholder-lightBlue-200'
  | 'focus:tw-placeholder-lightBlue-200'
  | 'sm:tw-placeholder-lightBlue-300'
  | 'md:tw-placeholder-lightBlue-300'
  | 'lg:tw-placeholder-lightBlue-300'
  | 'xl:tw-placeholder-lightBlue-300'
  | 'focus:tw-placeholder-lightBlue-300'
  | 'sm:tw-placeholder-lightBlue-400'
  | 'md:tw-placeholder-lightBlue-400'
  | 'lg:tw-placeholder-lightBlue-400'
  | 'xl:tw-placeholder-lightBlue-400'
  | 'focus:tw-placeholder-lightBlue-400'
  | 'sm:tw-placeholder-lightBlue-500'
  | 'md:tw-placeholder-lightBlue-500'
  | 'lg:tw-placeholder-lightBlue-500'
  | 'xl:tw-placeholder-lightBlue-500'
  | 'focus:tw-placeholder-lightBlue-500'
  | 'sm:tw-placeholder-lightBlue-600'
  | 'md:tw-placeholder-lightBlue-600'
  | 'lg:tw-placeholder-lightBlue-600'
  | 'xl:tw-placeholder-lightBlue-600'
  | 'focus:tw-placeholder-lightBlue-600'
  | 'sm:tw-placeholder-lightBlue-700'
  | 'md:tw-placeholder-lightBlue-700'
  | 'lg:tw-placeholder-lightBlue-700'
  | 'xl:tw-placeholder-lightBlue-700'
  | 'focus:tw-placeholder-lightBlue-700'
  | 'sm:tw-placeholder-lightBlue-800'
  | 'md:tw-placeholder-lightBlue-800'
  | 'lg:tw-placeholder-lightBlue-800'
  | 'xl:tw-placeholder-lightBlue-800'
  | 'focus:tw-placeholder-lightBlue-800'
  | 'sm:tw-placeholder-lightBlue-900'
  | 'md:tw-placeholder-lightBlue-900'
  | 'lg:tw-placeholder-lightBlue-900'
  | 'xl:tw-placeholder-lightBlue-900'
  | 'focus:tw-placeholder-lightBlue-900'
  | 'sm:tw-placeholder-lightBlue-a100'
  | 'md:tw-placeholder-lightBlue-a100'
  | 'lg:tw-placeholder-lightBlue-a100'
  | 'xl:tw-placeholder-lightBlue-a100'
  | 'focus:tw-placeholder-lightBlue-a100'
  | 'sm:tw-placeholder-lightBlue-a200'
  | 'md:tw-placeholder-lightBlue-a200'
  | 'lg:tw-placeholder-lightBlue-a200'
  | 'xl:tw-placeholder-lightBlue-a200'
  | 'focus:tw-placeholder-lightBlue-a200'
  | 'sm:tw-placeholder-lightBlue-a400'
  | 'md:tw-placeholder-lightBlue-a400'
  | 'lg:tw-placeholder-lightBlue-a400'
  | 'xl:tw-placeholder-lightBlue-a400'
  | 'focus:tw-placeholder-lightBlue-a400'
  | 'sm:tw-placeholder-lightBlue-a700'
  | 'md:tw-placeholder-lightBlue-a700'
  | 'lg:tw-placeholder-lightBlue-a700'
  | 'xl:tw-placeholder-lightBlue-a700'
  | 'focus:tw-placeholder-lightBlue-a700'
  | 'sm:tw-placeholder-cyan-50'
  | 'md:tw-placeholder-cyan-50'
  | 'lg:tw-placeholder-cyan-50'
  | 'xl:tw-placeholder-cyan-50'
  | 'focus:tw-placeholder-cyan-50'
  | 'sm:tw-placeholder-cyan-100'
  | 'md:tw-placeholder-cyan-100'
  | 'lg:tw-placeholder-cyan-100'
  | 'xl:tw-placeholder-cyan-100'
  | 'focus:tw-placeholder-cyan-100'
  | 'sm:tw-placeholder-cyan-200'
  | 'md:tw-placeholder-cyan-200'
  | 'lg:tw-placeholder-cyan-200'
  | 'xl:tw-placeholder-cyan-200'
  | 'focus:tw-placeholder-cyan-200'
  | 'sm:tw-placeholder-cyan-300'
  | 'md:tw-placeholder-cyan-300'
  | 'lg:tw-placeholder-cyan-300'
  | 'xl:tw-placeholder-cyan-300'
  | 'focus:tw-placeholder-cyan-300'
  | 'sm:tw-placeholder-cyan-400'
  | 'md:tw-placeholder-cyan-400'
  | 'lg:tw-placeholder-cyan-400'
  | 'xl:tw-placeholder-cyan-400'
  | 'focus:tw-placeholder-cyan-400'
  | 'sm:tw-placeholder-cyan-500'
  | 'md:tw-placeholder-cyan-500'
  | 'lg:tw-placeholder-cyan-500'
  | 'xl:tw-placeholder-cyan-500'
  | 'focus:tw-placeholder-cyan-500'
  | 'sm:tw-placeholder-cyan-600'
  | 'md:tw-placeholder-cyan-600'
  | 'lg:tw-placeholder-cyan-600'
  | 'xl:tw-placeholder-cyan-600'
  | 'focus:tw-placeholder-cyan-600'
  | 'sm:tw-placeholder-cyan-700'
  | 'md:tw-placeholder-cyan-700'
  | 'lg:tw-placeholder-cyan-700'
  | 'xl:tw-placeholder-cyan-700'
  | 'focus:tw-placeholder-cyan-700'
  | 'sm:tw-placeholder-cyan-800'
  | 'md:tw-placeholder-cyan-800'
  | 'lg:tw-placeholder-cyan-800'
  | 'xl:tw-placeholder-cyan-800'
  | 'focus:tw-placeholder-cyan-800'
  | 'sm:tw-placeholder-cyan-900'
  | 'md:tw-placeholder-cyan-900'
  | 'lg:tw-placeholder-cyan-900'
  | 'xl:tw-placeholder-cyan-900'
  | 'focus:tw-placeholder-cyan-900'
  | 'sm:tw-placeholder-cyan-a100'
  | 'md:tw-placeholder-cyan-a100'
  | 'lg:tw-placeholder-cyan-a100'
  | 'xl:tw-placeholder-cyan-a100'
  | 'focus:tw-placeholder-cyan-a100'
  | 'sm:tw-placeholder-cyan-a200'
  | 'md:tw-placeholder-cyan-a200'
  | 'lg:tw-placeholder-cyan-a200'
  | 'xl:tw-placeholder-cyan-a200'
  | 'focus:tw-placeholder-cyan-a200'
  | 'sm:tw-placeholder-cyan-a400'
  | 'md:tw-placeholder-cyan-a400'
  | 'lg:tw-placeholder-cyan-a400'
  | 'xl:tw-placeholder-cyan-a400'
  | 'focus:tw-placeholder-cyan-a400'
  | 'sm:tw-placeholder-cyan-a700'
  | 'md:tw-placeholder-cyan-a700'
  | 'lg:tw-placeholder-cyan-a700'
  | 'xl:tw-placeholder-cyan-a700'
  | 'focus:tw-placeholder-cyan-a700'
  | 'sm:tw-placeholder-teal-50'
  | 'md:tw-placeholder-teal-50'
  | 'lg:tw-placeholder-teal-50'
  | 'xl:tw-placeholder-teal-50'
  | 'focus:tw-placeholder-teal-50'
  | 'sm:tw-placeholder-teal-100'
  | 'md:tw-placeholder-teal-100'
  | 'lg:tw-placeholder-teal-100'
  | 'xl:tw-placeholder-teal-100'
  | 'focus:tw-placeholder-teal-100'
  | 'sm:tw-placeholder-teal-200'
  | 'md:tw-placeholder-teal-200'
  | 'lg:tw-placeholder-teal-200'
  | 'xl:tw-placeholder-teal-200'
  | 'focus:tw-placeholder-teal-200'
  | 'sm:tw-placeholder-teal-300'
  | 'md:tw-placeholder-teal-300'
  | 'lg:tw-placeholder-teal-300'
  | 'xl:tw-placeholder-teal-300'
  | 'focus:tw-placeholder-teal-300'
  | 'sm:tw-placeholder-teal-400'
  | 'md:tw-placeholder-teal-400'
  | 'lg:tw-placeholder-teal-400'
  | 'xl:tw-placeholder-teal-400'
  | 'focus:tw-placeholder-teal-400'
  | 'sm:tw-placeholder-teal-500'
  | 'md:tw-placeholder-teal-500'
  | 'lg:tw-placeholder-teal-500'
  | 'xl:tw-placeholder-teal-500'
  | 'focus:tw-placeholder-teal-500'
  | 'sm:tw-placeholder-teal-600'
  | 'md:tw-placeholder-teal-600'
  | 'lg:tw-placeholder-teal-600'
  | 'xl:tw-placeholder-teal-600'
  | 'focus:tw-placeholder-teal-600'
  | 'sm:tw-placeholder-teal-700'
  | 'md:tw-placeholder-teal-700'
  | 'lg:tw-placeholder-teal-700'
  | 'xl:tw-placeholder-teal-700'
  | 'focus:tw-placeholder-teal-700'
  | 'sm:tw-placeholder-teal-800'
  | 'md:tw-placeholder-teal-800'
  | 'lg:tw-placeholder-teal-800'
  | 'xl:tw-placeholder-teal-800'
  | 'focus:tw-placeholder-teal-800'
  | 'sm:tw-placeholder-teal-900'
  | 'md:tw-placeholder-teal-900'
  | 'lg:tw-placeholder-teal-900'
  | 'xl:tw-placeholder-teal-900'
  | 'focus:tw-placeholder-teal-900'
  | 'sm:tw-placeholder-teal-a100'
  | 'md:tw-placeholder-teal-a100'
  | 'lg:tw-placeholder-teal-a100'
  | 'xl:tw-placeholder-teal-a100'
  | 'focus:tw-placeholder-teal-a100'
  | 'sm:tw-placeholder-teal-a200'
  | 'md:tw-placeholder-teal-a200'
  | 'lg:tw-placeholder-teal-a200'
  | 'xl:tw-placeholder-teal-a200'
  | 'focus:tw-placeholder-teal-a200'
  | 'sm:tw-placeholder-teal-a400'
  | 'md:tw-placeholder-teal-a400'
  | 'lg:tw-placeholder-teal-a400'
  | 'xl:tw-placeholder-teal-a400'
  | 'focus:tw-placeholder-teal-a400'
  | 'sm:tw-placeholder-teal-a700'
  | 'md:tw-placeholder-teal-a700'
  | 'lg:tw-placeholder-teal-a700'
  | 'xl:tw-placeholder-teal-a700'
  | 'focus:tw-placeholder-teal-a700'
  | 'sm:tw-placeholder-green-50'
  | 'md:tw-placeholder-green-50'
  | 'lg:tw-placeholder-green-50'
  | 'xl:tw-placeholder-green-50'
  | 'focus:tw-placeholder-green-50'
  | 'sm:tw-placeholder-green-100'
  | 'md:tw-placeholder-green-100'
  | 'lg:tw-placeholder-green-100'
  | 'xl:tw-placeholder-green-100'
  | 'focus:tw-placeholder-green-100'
  | 'sm:tw-placeholder-green-200'
  | 'md:tw-placeholder-green-200'
  | 'lg:tw-placeholder-green-200'
  | 'xl:tw-placeholder-green-200'
  | 'focus:tw-placeholder-green-200'
  | 'sm:tw-placeholder-green-300'
  | 'md:tw-placeholder-green-300'
  | 'lg:tw-placeholder-green-300'
  | 'xl:tw-placeholder-green-300'
  | 'focus:tw-placeholder-green-300'
  | 'sm:tw-placeholder-green-400'
  | 'md:tw-placeholder-green-400'
  | 'lg:tw-placeholder-green-400'
  | 'xl:tw-placeholder-green-400'
  | 'focus:tw-placeholder-green-400'
  | 'sm:tw-placeholder-green-500'
  | 'md:tw-placeholder-green-500'
  | 'lg:tw-placeholder-green-500'
  | 'xl:tw-placeholder-green-500'
  | 'focus:tw-placeholder-green-500'
  | 'sm:tw-placeholder-green-600'
  | 'md:tw-placeholder-green-600'
  | 'lg:tw-placeholder-green-600'
  | 'xl:tw-placeholder-green-600'
  | 'focus:tw-placeholder-green-600'
  | 'sm:tw-placeholder-green-700'
  | 'md:tw-placeholder-green-700'
  | 'lg:tw-placeholder-green-700'
  | 'xl:tw-placeholder-green-700'
  | 'focus:tw-placeholder-green-700'
  | 'sm:tw-placeholder-green-800'
  | 'md:tw-placeholder-green-800'
  | 'lg:tw-placeholder-green-800'
  | 'xl:tw-placeholder-green-800'
  | 'focus:tw-placeholder-green-800'
  | 'sm:tw-placeholder-green-900'
  | 'md:tw-placeholder-green-900'
  | 'lg:tw-placeholder-green-900'
  | 'xl:tw-placeholder-green-900'
  | 'focus:tw-placeholder-green-900'
  | 'sm:tw-placeholder-green-a100'
  | 'md:tw-placeholder-green-a100'
  | 'lg:tw-placeholder-green-a100'
  | 'xl:tw-placeholder-green-a100'
  | 'focus:tw-placeholder-green-a100'
  | 'sm:tw-placeholder-green-a200'
  | 'md:tw-placeholder-green-a200'
  | 'lg:tw-placeholder-green-a200'
  | 'xl:tw-placeholder-green-a200'
  | 'focus:tw-placeholder-green-a200'
  | 'sm:tw-placeholder-green-a400'
  | 'md:tw-placeholder-green-a400'
  | 'lg:tw-placeholder-green-a400'
  | 'xl:tw-placeholder-green-a400'
  | 'focus:tw-placeholder-green-a400'
  | 'sm:tw-placeholder-green-a700'
  | 'md:tw-placeholder-green-a700'
  | 'lg:tw-placeholder-green-a700'
  | 'xl:tw-placeholder-green-a700'
  | 'focus:tw-placeholder-green-a700'
  | 'sm:tw-placeholder-lightGreen-50'
  | 'md:tw-placeholder-lightGreen-50'
  | 'lg:tw-placeholder-lightGreen-50'
  | 'xl:tw-placeholder-lightGreen-50'
  | 'focus:tw-placeholder-lightGreen-50'
  | 'sm:tw-placeholder-lightGreen-100'
  | 'md:tw-placeholder-lightGreen-100'
  | 'lg:tw-placeholder-lightGreen-100'
  | 'xl:tw-placeholder-lightGreen-100'
  | 'focus:tw-placeholder-lightGreen-100'
  | 'sm:tw-placeholder-lightGreen-200'
  | 'md:tw-placeholder-lightGreen-200'
  | 'lg:tw-placeholder-lightGreen-200'
  | 'xl:tw-placeholder-lightGreen-200'
  | 'focus:tw-placeholder-lightGreen-200'
  | 'sm:tw-placeholder-lightGreen-300'
  | 'md:tw-placeholder-lightGreen-300'
  | 'lg:tw-placeholder-lightGreen-300'
  | 'xl:tw-placeholder-lightGreen-300'
  | 'focus:tw-placeholder-lightGreen-300'
  | 'sm:tw-placeholder-lightGreen-400'
  | 'md:tw-placeholder-lightGreen-400'
  | 'lg:tw-placeholder-lightGreen-400'
  | 'xl:tw-placeholder-lightGreen-400'
  | 'focus:tw-placeholder-lightGreen-400'
  | 'sm:tw-placeholder-lightGreen-500'
  | 'md:tw-placeholder-lightGreen-500'
  | 'lg:tw-placeholder-lightGreen-500'
  | 'xl:tw-placeholder-lightGreen-500'
  | 'focus:tw-placeholder-lightGreen-500'
  | 'sm:tw-placeholder-lightGreen-600'
  | 'md:tw-placeholder-lightGreen-600'
  | 'lg:tw-placeholder-lightGreen-600'
  | 'xl:tw-placeholder-lightGreen-600'
  | 'focus:tw-placeholder-lightGreen-600'
  | 'sm:tw-placeholder-lightGreen-700'
  | 'md:tw-placeholder-lightGreen-700'
  | 'lg:tw-placeholder-lightGreen-700'
  | 'xl:tw-placeholder-lightGreen-700'
  | 'focus:tw-placeholder-lightGreen-700'
  | 'sm:tw-placeholder-lightGreen-800'
  | 'md:tw-placeholder-lightGreen-800'
  | 'lg:tw-placeholder-lightGreen-800'
  | 'xl:tw-placeholder-lightGreen-800'
  | 'focus:tw-placeholder-lightGreen-800'
  | 'sm:tw-placeholder-lightGreen-900'
  | 'md:tw-placeholder-lightGreen-900'
  | 'lg:tw-placeholder-lightGreen-900'
  | 'xl:tw-placeholder-lightGreen-900'
  | 'focus:tw-placeholder-lightGreen-900'
  | 'sm:tw-placeholder-lightGreen-a100'
  | 'md:tw-placeholder-lightGreen-a100'
  | 'lg:tw-placeholder-lightGreen-a100'
  | 'xl:tw-placeholder-lightGreen-a100'
  | 'focus:tw-placeholder-lightGreen-a100'
  | 'sm:tw-placeholder-lightGreen-a200'
  | 'md:tw-placeholder-lightGreen-a200'
  | 'lg:tw-placeholder-lightGreen-a200'
  | 'xl:tw-placeholder-lightGreen-a200'
  | 'focus:tw-placeholder-lightGreen-a200'
  | 'sm:tw-placeholder-lightGreen-a400'
  | 'md:tw-placeholder-lightGreen-a400'
  | 'lg:tw-placeholder-lightGreen-a400'
  | 'xl:tw-placeholder-lightGreen-a400'
  | 'focus:tw-placeholder-lightGreen-a400'
  | 'sm:tw-placeholder-lightGreen-a700'
  | 'md:tw-placeholder-lightGreen-a700'
  | 'lg:tw-placeholder-lightGreen-a700'
  | 'xl:tw-placeholder-lightGreen-a700'
  | 'focus:tw-placeholder-lightGreen-a700'
  | 'sm:tw-placeholder-lime-50'
  | 'md:tw-placeholder-lime-50'
  | 'lg:tw-placeholder-lime-50'
  | 'xl:tw-placeholder-lime-50'
  | 'focus:tw-placeholder-lime-50'
  | 'sm:tw-placeholder-lime-100'
  | 'md:tw-placeholder-lime-100'
  | 'lg:tw-placeholder-lime-100'
  | 'xl:tw-placeholder-lime-100'
  | 'focus:tw-placeholder-lime-100'
  | 'sm:tw-placeholder-lime-200'
  | 'md:tw-placeholder-lime-200'
  | 'lg:tw-placeholder-lime-200'
  | 'xl:tw-placeholder-lime-200'
  | 'focus:tw-placeholder-lime-200'
  | 'sm:tw-placeholder-lime-300'
  | 'md:tw-placeholder-lime-300'
  | 'lg:tw-placeholder-lime-300'
  | 'xl:tw-placeholder-lime-300'
  | 'focus:tw-placeholder-lime-300'
  | 'sm:tw-placeholder-lime-400'
  | 'md:tw-placeholder-lime-400'
  | 'lg:tw-placeholder-lime-400'
  | 'xl:tw-placeholder-lime-400'
  | 'focus:tw-placeholder-lime-400'
  | 'sm:tw-placeholder-lime-500'
  | 'md:tw-placeholder-lime-500'
  | 'lg:tw-placeholder-lime-500'
  | 'xl:tw-placeholder-lime-500'
  | 'focus:tw-placeholder-lime-500'
  | 'sm:tw-placeholder-lime-600'
  | 'md:tw-placeholder-lime-600'
  | 'lg:tw-placeholder-lime-600'
  | 'xl:tw-placeholder-lime-600'
  | 'focus:tw-placeholder-lime-600'
  | 'sm:tw-placeholder-lime-700'
  | 'md:tw-placeholder-lime-700'
  | 'lg:tw-placeholder-lime-700'
  | 'xl:tw-placeholder-lime-700'
  | 'focus:tw-placeholder-lime-700'
  | 'sm:tw-placeholder-lime-800'
  | 'md:tw-placeholder-lime-800'
  | 'lg:tw-placeholder-lime-800'
  | 'xl:tw-placeholder-lime-800'
  | 'focus:tw-placeholder-lime-800'
  | 'sm:tw-placeholder-lime-900'
  | 'md:tw-placeholder-lime-900'
  | 'lg:tw-placeholder-lime-900'
  | 'xl:tw-placeholder-lime-900'
  | 'focus:tw-placeholder-lime-900'
  | 'sm:tw-placeholder-lime-a100'
  | 'md:tw-placeholder-lime-a100'
  | 'lg:tw-placeholder-lime-a100'
  | 'xl:tw-placeholder-lime-a100'
  | 'focus:tw-placeholder-lime-a100'
  | 'sm:tw-placeholder-lime-a200'
  | 'md:tw-placeholder-lime-a200'
  | 'lg:tw-placeholder-lime-a200'
  | 'xl:tw-placeholder-lime-a200'
  | 'focus:tw-placeholder-lime-a200'
  | 'sm:tw-placeholder-lime-a400'
  | 'md:tw-placeholder-lime-a400'
  | 'lg:tw-placeholder-lime-a400'
  | 'xl:tw-placeholder-lime-a400'
  | 'focus:tw-placeholder-lime-a400'
  | 'sm:tw-placeholder-lime-a700'
  | 'md:tw-placeholder-lime-a700'
  | 'lg:tw-placeholder-lime-a700'
  | 'xl:tw-placeholder-lime-a700'
  | 'focus:tw-placeholder-lime-a700'
  | 'sm:tw-placeholder-yellow-50'
  | 'md:tw-placeholder-yellow-50'
  | 'lg:tw-placeholder-yellow-50'
  | 'xl:tw-placeholder-yellow-50'
  | 'focus:tw-placeholder-yellow-50'
  | 'sm:tw-placeholder-yellow-100'
  | 'md:tw-placeholder-yellow-100'
  | 'lg:tw-placeholder-yellow-100'
  | 'xl:tw-placeholder-yellow-100'
  | 'focus:tw-placeholder-yellow-100'
  | 'sm:tw-placeholder-yellow-200'
  | 'md:tw-placeholder-yellow-200'
  | 'lg:tw-placeholder-yellow-200'
  | 'xl:tw-placeholder-yellow-200'
  | 'focus:tw-placeholder-yellow-200'
  | 'sm:tw-placeholder-yellow-300'
  | 'md:tw-placeholder-yellow-300'
  | 'lg:tw-placeholder-yellow-300'
  | 'xl:tw-placeholder-yellow-300'
  | 'focus:tw-placeholder-yellow-300'
  | 'sm:tw-placeholder-yellow-400'
  | 'md:tw-placeholder-yellow-400'
  | 'lg:tw-placeholder-yellow-400'
  | 'xl:tw-placeholder-yellow-400'
  | 'focus:tw-placeholder-yellow-400'
  | 'sm:tw-placeholder-yellow-500'
  | 'md:tw-placeholder-yellow-500'
  | 'lg:tw-placeholder-yellow-500'
  | 'xl:tw-placeholder-yellow-500'
  | 'focus:tw-placeholder-yellow-500'
  | 'sm:tw-placeholder-yellow-600'
  | 'md:tw-placeholder-yellow-600'
  | 'lg:tw-placeholder-yellow-600'
  | 'xl:tw-placeholder-yellow-600'
  | 'focus:tw-placeholder-yellow-600'
  | 'sm:tw-placeholder-yellow-700'
  | 'md:tw-placeholder-yellow-700'
  | 'lg:tw-placeholder-yellow-700'
  | 'xl:tw-placeholder-yellow-700'
  | 'focus:tw-placeholder-yellow-700'
  | 'sm:tw-placeholder-yellow-800'
  | 'md:tw-placeholder-yellow-800'
  | 'lg:tw-placeholder-yellow-800'
  | 'xl:tw-placeholder-yellow-800'
  | 'focus:tw-placeholder-yellow-800'
  | 'sm:tw-placeholder-yellow-900'
  | 'md:tw-placeholder-yellow-900'
  | 'lg:tw-placeholder-yellow-900'
  | 'xl:tw-placeholder-yellow-900'
  | 'focus:tw-placeholder-yellow-900'
  | 'sm:tw-placeholder-yellow-a100'
  | 'md:tw-placeholder-yellow-a100'
  | 'lg:tw-placeholder-yellow-a100'
  | 'xl:tw-placeholder-yellow-a100'
  | 'focus:tw-placeholder-yellow-a100'
  | 'sm:tw-placeholder-yellow-a200'
  | 'md:tw-placeholder-yellow-a200'
  | 'lg:tw-placeholder-yellow-a200'
  | 'xl:tw-placeholder-yellow-a200'
  | 'focus:tw-placeholder-yellow-a200'
  | 'sm:tw-placeholder-yellow-a400'
  | 'md:tw-placeholder-yellow-a400'
  | 'lg:tw-placeholder-yellow-a400'
  | 'xl:tw-placeholder-yellow-a400'
  | 'focus:tw-placeholder-yellow-a400'
  | 'sm:tw-placeholder-yellow-a700'
  | 'md:tw-placeholder-yellow-a700'
  | 'lg:tw-placeholder-yellow-a700'
  | 'xl:tw-placeholder-yellow-a700'
  | 'focus:tw-placeholder-yellow-a700'
  | 'sm:tw-placeholder-amber-50'
  | 'md:tw-placeholder-amber-50'
  | 'lg:tw-placeholder-amber-50'
  | 'xl:tw-placeholder-amber-50'
  | 'focus:tw-placeholder-amber-50'
  | 'sm:tw-placeholder-amber-100'
  | 'md:tw-placeholder-amber-100'
  | 'lg:tw-placeholder-amber-100'
  | 'xl:tw-placeholder-amber-100'
  | 'focus:tw-placeholder-amber-100'
  | 'sm:tw-placeholder-amber-200'
  | 'md:tw-placeholder-amber-200'
  | 'lg:tw-placeholder-amber-200'
  | 'xl:tw-placeholder-amber-200'
  | 'focus:tw-placeholder-amber-200'
  | 'sm:tw-placeholder-amber-300'
  | 'md:tw-placeholder-amber-300'
  | 'lg:tw-placeholder-amber-300'
  | 'xl:tw-placeholder-amber-300'
  | 'focus:tw-placeholder-amber-300'
  | 'sm:tw-placeholder-amber-400'
  | 'md:tw-placeholder-amber-400'
  | 'lg:tw-placeholder-amber-400'
  | 'xl:tw-placeholder-amber-400'
  | 'focus:tw-placeholder-amber-400'
  | 'sm:tw-placeholder-amber-500'
  | 'md:tw-placeholder-amber-500'
  | 'lg:tw-placeholder-amber-500'
  | 'xl:tw-placeholder-amber-500'
  | 'focus:tw-placeholder-amber-500'
  | 'sm:tw-placeholder-amber-600'
  | 'md:tw-placeholder-amber-600'
  | 'lg:tw-placeholder-amber-600'
  | 'xl:tw-placeholder-amber-600'
  | 'focus:tw-placeholder-amber-600'
  | 'sm:tw-placeholder-amber-700'
  | 'md:tw-placeholder-amber-700'
  | 'lg:tw-placeholder-amber-700'
  | 'xl:tw-placeholder-amber-700'
  | 'focus:tw-placeholder-amber-700'
  | 'sm:tw-placeholder-amber-800'
  | 'md:tw-placeholder-amber-800'
  | 'lg:tw-placeholder-amber-800'
  | 'xl:tw-placeholder-amber-800'
  | 'focus:tw-placeholder-amber-800'
  | 'sm:tw-placeholder-amber-900'
  | 'md:tw-placeholder-amber-900'
  | 'lg:tw-placeholder-amber-900'
  | 'xl:tw-placeholder-amber-900'
  | 'focus:tw-placeholder-amber-900'
  | 'sm:tw-placeholder-amber-a100'
  | 'md:tw-placeholder-amber-a100'
  | 'lg:tw-placeholder-amber-a100'
  | 'xl:tw-placeholder-amber-a100'
  | 'focus:tw-placeholder-amber-a100'
  | 'sm:tw-placeholder-amber-a200'
  | 'md:tw-placeholder-amber-a200'
  | 'lg:tw-placeholder-amber-a200'
  | 'xl:tw-placeholder-amber-a200'
  | 'focus:tw-placeholder-amber-a200'
  | 'sm:tw-placeholder-amber-a400'
  | 'md:tw-placeholder-amber-a400'
  | 'lg:tw-placeholder-amber-a400'
  | 'xl:tw-placeholder-amber-a400'
  | 'focus:tw-placeholder-amber-a400'
  | 'sm:tw-placeholder-amber-a700'
  | 'md:tw-placeholder-amber-a700'
  | 'lg:tw-placeholder-amber-a700'
  | 'xl:tw-placeholder-amber-a700'
  | 'focus:tw-placeholder-amber-a700'
  | 'sm:tw-placeholder-orange-50'
  | 'md:tw-placeholder-orange-50'
  | 'lg:tw-placeholder-orange-50'
  | 'xl:tw-placeholder-orange-50'
  | 'focus:tw-placeholder-orange-50'
  | 'sm:tw-placeholder-orange-100'
  | 'md:tw-placeholder-orange-100'
  | 'lg:tw-placeholder-orange-100'
  | 'xl:tw-placeholder-orange-100'
  | 'focus:tw-placeholder-orange-100'
  | 'sm:tw-placeholder-orange-200'
  | 'md:tw-placeholder-orange-200'
  | 'lg:tw-placeholder-orange-200'
  | 'xl:tw-placeholder-orange-200'
  | 'focus:tw-placeholder-orange-200'
  | 'sm:tw-placeholder-orange-300'
  | 'md:tw-placeholder-orange-300'
  | 'lg:tw-placeholder-orange-300'
  | 'xl:tw-placeholder-orange-300'
  | 'focus:tw-placeholder-orange-300'
  | 'sm:tw-placeholder-orange-400'
  | 'md:tw-placeholder-orange-400'
  | 'lg:tw-placeholder-orange-400'
  | 'xl:tw-placeholder-orange-400'
  | 'focus:tw-placeholder-orange-400'
  | 'sm:tw-placeholder-orange-500'
  | 'md:tw-placeholder-orange-500'
  | 'lg:tw-placeholder-orange-500'
  | 'xl:tw-placeholder-orange-500'
  | 'focus:tw-placeholder-orange-500'
  | 'sm:tw-placeholder-orange-600'
  | 'md:tw-placeholder-orange-600'
  | 'lg:tw-placeholder-orange-600'
  | 'xl:tw-placeholder-orange-600'
  | 'focus:tw-placeholder-orange-600'
  | 'sm:tw-placeholder-orange-700'
  | 'md:tw-placeholder-orange-700'
  | 'lg:tw-placeholder-orange-700'
  | 'xl:tw-placeholder-orange-700'
  | 'focus:tw-placeholder-orange-700'
  | 'sm:tw-placeholder-orange-800'
  | 'md:tw-placeholder-orange-800'
  | 'lg:tw-placeholder-orange-800'
  | 'xl:tw-placeholder-orange-800'
  | 'focus:tw-placeholder-orange-800'
  | 'sm:tw-placeholder-orange-900'
  | 'md:tw-placeholder-orange-900'
  | 'lg:tw-placeholder-orange-900'
  | 'xl:tw-placeholder-orange-900'
  | 'focus:tw-placeholder-orange-900'
  | 'sm:tw-placeholder-orange-a100'
  | 'md:tw-placeholder-orange-a100'
  | 'lg:tw-placeholder-orange-a100'
  | 'xl:tw-placeholder-orange-a100'
  | 'focus:tw-placeholder-orange-a100'
  | 'sm:tw-placeholder-orange-a200'
  | 'md:tw-placeholder-orange-a200'
  | 'lg:tw-placeholder-orange-a200'
  | 'xl:tw-placeholder-orange-a200'
  | 'focus:tw-placeholder-orange-a200'
  | 'sm:tw-placeholder-orange-a400'
  | 'md:tw-placeholder-orange-a400'
  | 'lg:tw-placeholder-orange-a400'
  | 'xl:tw-placeholder-orange-a400'
  | 'focus:tw-placeholder-orange-a400'
  | 'sm:tw-placeholder-orange-a700'
  | 'md:tw-placeholder-orange-a700'
  | 'lg:tw-placeholder-orange-a700'
  | 'xl:tw-placeholder-orange-a700'
  | 'focus:tw-placeholder-orange-a700'
  | 'sm:tw-placeholder-deepOrange-50'
  | 'md:tw-placeholder-deepOrange-50'
  | 'lg:tw-placeholder-deepOrange-50'
  | 'xl:tw-placeholder-deepOrange-50'
  | 'focus:tw-placeholder-deepOrange-50'
  | 'sm:tw-placeholder-deepOrange-100'
  | 'md:tw-placeholder-deepOrange-100'
  | 'lg:tw-placeholder-deepOrange-100'
  | 'xl:tw-placeholder-deepOrange-100'
  | 'focus:tw-placeholder-deepOrange-100'
  | 'sm:tw-placeholder-deepOrange-200'
  | 'md:tw-placeholder-deepOrange-200'
  | 'lg:tw-placeholder-deepOrange-200'
  | 'xl:tw-placeholder-deepOrange-200'
  | 'focus:tw-placeholder-deepOrange-200'
  | 'sm:tw-placeholder-deepOrange-300'
  | 'md:tw-placeholder-deepOrange-300'
  | 'lg:tw-placeholder-deepOrange-300'
  | 'xl:tw-placeholder-deepOrange-300'
  | 'focus:tw-placeholder-deepOrange-300'
  | 'sm:tw-placeholder-deepOrange-400'
  | 'md:tw-placeholder-deepOrange-400'
  | 'lg:tw-placeholder-deepOrange-400'
  | 'xl:tw-placeholder-deepOrange-400'
  | 'focus:tw-placeholder-deepOrange-400'
  | 'sm:tw-placeholder-deepOrange-500'
  | 'md:tw-placeholder-deepOrange-500'
  | 'lg:tw-placeholder-deepOrange-500'
  | 'xl:tw-placeholder-deepOrange-500'
  | 'focus:tw-placeholder-deepOrange-500'
  | 'sm:tw-placeholder-deepOrange-600'
  | 'md:tw-placeholder-deepOrange-600'
  | 'lg:tw-placeholder-deepOrange-600'
  | 'xl:tw-placeholder-deepOrange-600'
  | 'focus:tw-placeholder-deepOrange-600'
  | 'sm:tw-placeholder-deepOrange-700'
  | 'md:tw-placeholder-deepOrange-700'
  | 'lg:tw-placeholder-deepOrange-700'
  | 'xl:tw-placeholder-deepOrange-700'
  | 'focus:tw-placeholder-deepOrange-700'
  | 'sm:tw-placeholder-deepOrange-800'
  | 'md:tw-placeholder-deepOrange-800'
  | 'lg:tw-placeholder-deepOrange-800'
  | 'xl:tw-placeholder-deepOrange-800'
  | 'focus:tw-placeholder-deepOrange-800'
  | 'sm:tw-placeholder-deepOrange-900'
  | 'md:tw-placeholder-deepOrange-900'
  | 'lg:tw-placeholder-deepOrange-900'
  | 'xl:tw-placeholder-deepOrange-900'
  | 'focus:tw-placeholder-deepOrange-900'
  | 'sm:tw-placeholder-deepOrange-a100'
  | 'md:tw-placeholder-deepOrange-a100'
  | 'lg:tw-placeholder-deepOrange-a100'
  | 'xl:tw-placeholder-deepOrange-a100'
  | 'focus:tw-placeholder-deepOrange-a100'
  | 'sm:tw-placeholder-deepOrange-a200'
  | 'md:tw-placeholder-deepOrange-a200'
  | 'lg:tw-placeholder-deepOrange-a200'
  | 'xl:tw-placeholder-deepOrange-a200'
  | 'focus:tw-placeholder-deepOrange-a200'
  | 'sm:tw-placeholder-deepOrange-a400'
  | 'md:tw-placeholder-deepOrange-a400'
  | 'lg:tw-placeholder-deepOrange-a400'
  | 'xl:tw-placeholder-deepOrange-a400'
  | 'focus:tw-placeholder-deepOrange-a400'
  | 'sm:tw-placeholder-deepOrange-a700'
  | 'md:tw-placeholder-deepOrange-a700'
  | 'lg:tw-placeholder-deepOrange-a700'
  | 'xl:tw-placeholder-deepOrange-a700'
  | 'focus:tw-placeholder-deepOrange-a700'
  | 'sm:tw-placeholder-brown-50'
  | 'md:tw-placeholder-brown-50'
  | 'lg:tw-placeholder-brown-50'
  | 'xl:tw-placeholder-brown-50'
  | 'focus:tw-placeholder-brown-50'
  | 'sm:tw-placeholder-brown-100'
  | 'md:tw-placeholder-brown-100'
  | 'lg:tw-placeholder-brown-100'
  | 'xl:tw-placeholder-brown-100'
  | 'focus:tw-placeholder-brown-100'
  | 'sm:tw-placeholder-brown-200'
  | 'md:tw-placeholder-brown-200'
  | 'lg:tw-placeholder-brown-200'
  | 'xl:tw-placeholder-brown-200'
  | 'focus:tw-placeholder-brown-200'
  | 'sm:tw-placeholder-brown-300'
  | 'md:tw-placeholder-brown-300'
  | 'lg:tw-placeholder-brown-300'
  | 'xl:tw-placeholder-brown-300'
  | 'focus:tw-placeholder-brown-300'
  | 'sm:tw-placeholder-brown-400'
  | 'md:tw-placeholder-brown-400'
  | 'lg:tw-placeholder-brown-400'
  | 'xl:tw-placeholder-brown-400'
  | 'focus:tw-placeholder-brown-400'
  | 'sm:tw-placeholder-brown-500'
  | 'md:tw-placeholder-brown-500'
  | 'lg:tw-placeholder-brown-500'
  | 'xl:tw-placeholder-brown-500'
  | 'focus:tw-placeholder-brown-500'
  | 'sm:tw-placeholder-brown-600'
  | 'md:tw-placeholder-brown-600'
  | 'lg:tw-placeholder-brown-600'
  | 'xl:tw-placeholder-brown-600'
  | 'focus:tw-placeholder-brown-600'
  | 'sm:tw-placeholder-brown-700'
  | 'md:tw-placeholder-brown-700'
  | 'lg:tw-placeholder-brown-700'
  | 'xl:tw-placeholder-brown-700'
  | 'focus:tw-placeholder-brown-700'
  | 'sm:tw-placeholder-brown-800'
  | 'md:tw-placeholder-brown-800'
  | 'lg:tw-placeholder-brown-800'
  | 'xl:tw-placeholder-brown-800'
  | 'focus:tw-placeholder-brown-800'
  | 'sm:tw-placeholder-brown-900'
  | 'md:tw-placeholder-brown-900'
  | 'lg:tw-placeholder-brown-900'
  | 'xl:tw-placeholder-brown-900'
  | 'focus:tw-placeholder-brown-900'
  | 'sm:tw-placeholder-gray-50'
  | 'md:tw-placeholder-gray-50'
  | 'lg:tw-placeholder-gray-50'
  | 'xl:tw-placeholder-gray-50'
  | 'focus:tw-placeholder-gray-50'
  | 'sm:tw-placeholder-gray-100'
  | 'md:tw-placeholder-gray-100'
  | 'lg:tw-placeholder-gray-100'
  | 'xl:tw-placeholder-gray-100'
  | 'focus:tw-placeholder-gray-100'
  | 'sm:tw-placeholder-gray-200'
  | 'md:tw-placeholder-gray-200'
  | 'lg:tw-placeholder-gray-200'
  | 'xl:tw-placeholder-gray-200'
  | 'focus:tw-placeholder-gray-200'
  | 'sm:tw-placeholder-gray-300'
  | 'md:tw-placeholder-gray-300'
  | 'lg:tw-placeholder-gray-300'
  | 'xl:tw-placeholder-gray-300'
  | 'focus:tw-placeholder-gray-300'
  | 'sm:tw-placeholder-gray-400'
  | 'md:tw-placeholder-gray-400'
  | 'lg:tw-placeholder-gray-400'
  | 'xl:tw-placeholder-gray-400'
  | 'focus:tw-placeholder-gray-400'
  | 'sm:tw-placeholder-gray-500'
  | 'md:tw-placeholder-gray-500'
  | 'lg:tw-placeholder-gray-500'
  | 'xl:tw-placeholder-gray-500'
  | 'focus:tw-placeholder-gray-500'
  | 'sm:tw-placeholder-gray-600'
  | 'md:tw-placeholder-gray-600'
  | 'lg:tw-placeholder-gray-600'
  | 'xl:tw-placeholder-gray-600'
  | 'focus:tw-placeholder-gray-600'
  | 'sm:tw-placeholder-gray-700'
  | 'md:tw-placeholder-gray-700'
  | 'lg:tw-placeholder-gray-700'
  | 'xl:tw-placeholder-gray-700'
  | 'focus:tw-placeholder-gray-700'
  | 'sm:tw-placeholder-gray-800'
  | 'md:tw-placeholder-gray-800'
  | 'lg:tw-placeholder-gray-800'
  | 'xl:tw-placeholder-gray-800'
  | 'focus:tw-placeholder-gray-800'
  | 'sm:tw-placeholder-gray-900'
  | 'md:tw-placeholder-gray-900'
  | 'lg:tw-placeholder-gray-900'
  | 'xl:tw-placeholder-gray-900'
  | 'focus:tw-placeholder-gray-900'
  | 'sm:tw-placeholder-blueGray-50'
  | 'md:tw-placeholder-blueGray-50'
  | 'lg:tw-placeholder-blueGray-50'
  | 'xl:tw-placeholder-blueGray-50'
  | 'focus:tw-placeholder-blueGray-50'
  | 'sm:tw-placeholder-blueGray-100'
  | 'md:tw-placeholder-blueGray-100'
  | 'lg:tw-placeholder-blueGray-100'
  | 'xl:tw-placeholder-blueGray-100'
  | 'focus:tw-placeholder-blueGray-100'
  | 'sm:tw-placeholder-blueGray-200'
  | 'md:tw-placeholder-blueGray-200'
  | 'lg:tw-placeholder-blueGray-200'
  | 'xl:tw-placeholder-blueGray-200'
  | 'focus:tw-placeholder-blueGray-200'
  | 'sm:tw-placeholder-blueGray-300'
  | 'md:tw-placeholder-blueGray-300'
  | 'lg:tw-placeholder-blueGray-300'
  | 'xl:tw-placeholder-blueGray-300'
  | 'focus:tw-placeholder-blueGray-300'
  | 'sm:tw-placeholder-blueGray-400'
  | 'md:tw-placeholder-blueGray-400'
  | 'lg:tw-placeholder-blueGray-400'
  | 'xl:tw-placeholder-blueGray-400'
  | 'focus:tw-placeholder-blueGray-400'
  | 'sm:tw-placeholder-blueGray-500'
  | 'md:tw-placeholder-blueGray-500'
  | 'lg:tw-placeholder-blueGray-500'
  | 'xl:tw-placeholder-blueGray-500'
  | 'focus:tw-placeholder-blueGray-500'
  | 'sm:tw-placeholder-blueGray-600'
  | 'md:tw-placeholder-blueGray-600'
  | 'lg:tw-placeholder-blueGray-600'
  | 'xl:tw-placeholder-blueGray-600'
  | 'focus:tw-placeholder-blueGray-600'
  | 'sm:tw-placeholder-blueGray-700'
  | 'md:tw-placeholder-blueGray-700'
  | 'lg:tw-placeholder-blueGray-700'
  | 'xl:tw-placeholder-blueGray-700'
  | 'focus:tw-placeholder-blueGray-700'
  | 'sm:tw-placeholder-blueGray-800'
  | 'md:tw-placeholder-blueGray-800'
  | 'lg:tw-placeholder-blueGray-800'
  | 'xl:tw-placeholder-blueGray-800'
  | 'focus:tw-placeholder-blueGray-800'
  | 'sm:tw-placeholder-blueGray-900'
  | 'md:tw-placeholder-blueGray-900'
  | 'lg:tw-placeholder-blueGray-900'
  | 'xl:tw-placeholder-blueGray-900'
  | 'focus:tw-placeholder-blueGray-900'
  | 'sm:tw-placeholder-black'
  | 'md:tw-placeholder-black'
  | 'lg:tw-placeholder-black'
  | 'xl:tw-placeholder-black'
  | 'focus:tw-placeholder-black'
  | 'sm:tw-placeholder-white'
  | 'md:tw-placeholder-white'
  | 'lg:tw-placeholder-white'
  | 'xl:tw-placeholder-white'
  | 'focus:tw-placeholder-white'
  | 'sm:tw-placeholder-transparent'
  | 'md:tw-placeholder-transparent'
  | 'lg:tw-placeholder-transparent'
  | 'xl:tw-placeholder-transparent'
  | 'focus:tw-placeholder-transparent'
  | 'sm:tw-placeholder-opacity-0'
  | 'md:tw-placeholder-opacity-0'
  | 'lg:tw-placeholder-opacity-0'
  | 'xl:tw-placeholder-opacity-0'
  | 'focus:tw-placeholder-opacity-0'
  | 'sm:tw-placeholder-opacity-25'
  | 'md:tw-placeholder-opacity-25'
  | 'lg:tw-placeholder-opacity-25'
  | 'xl:tw-placeholder-opacity-25'
  | 'focus:tw-placeholder-opacity-25'
  | 'sm:tw-placeholder-opacity-50'
  | 'md:tw-placeholder-opacity-50'
  | 'lg:tw-placeholder-opacity-50'
  | 'xl:tw-placeholder-opacity-50'
  | 'focus:tw-placeholder-opacity-50'
  | 'sm:tw-placeholder-opacity-75'
  | 'md:tw-placeholder-opacity-75'
  | 'lg:tw-placeholder-opacity-75'
  | 'xl:tw-placeholder-opacity-75'
  | 'focus:tw-placeholder-opacity-75'
  | 'sm:tw-placeholder-opacity-100'
  | 'md:tw-placeholder-opacity-100'
  | 'lg:tw-placeholder-opacity-100'
  | 'xl:tw-placeholder-opacity-100'
  | 'focus:tw-placeholder-opacity-100'
  | 'sm:tw-pointer-events-none'
  | 'md:tw-pointer-events-none'
  | 'lg:tw-pointer-events-none'
  | 'xl:tw-pointer-events-none'
  | 'sm:tw-pointer-events-auto'
  | 'md:tw-pointer-events-auto'
  | 'lg:tw-pointer-events-auto'
  | 'xl:tw-pointer-events-auto'
  | 'sm:tw-static'
  | 'md:tw-static'
  | 'lg:tw-static'
  | 'xl:tw-static'
  | 'sm:tw-fixed'
  | 'md:tw-fixed'
  | 'lg:tw-fixed'
  | 'xl:tw-fixed'
  | 'sm:tw-absolute'
  | 'md:tw-absolute'
  | 'lg:tw-absolute'
  | 'xl:tw-absolute'
  | 'sm:tw-relative'
  | 'md:tw-relative'
  | 'lg:tw-relative'
  | 'xl:tw-relative'
  | 'sm:tw-sticky'
  | 'md:tw-sticky'
  | 'lg:tw-sticky'
  | 'xl:tw-sticky'
  | 'sm:tw-resize-none'
  | 'md:tw-resize-none'
  | 'lg:tw-resize-none'
  | 'xl:tw-resize-none'
  | 'sm:tw-resize'
  | 'md:tw-resize'
  | 'lg:tw-resize'
  | 'xl:tw-resize'
  | 'sm:tw-resize-y'
  | 'md:tw-resize-y'
  | 'lg:tw-resize-y'
  | 'xl:tw-resize-y'
  | 'sm:tw-resize-x'
  | 'md:tw-resize-x'
  | 'lg:tw-resize-x'
  | 'xl:tw-resize-x'
  | 'sm:tw-space-x-0'
  | 'md:tw-space-x-0'
  | 'lg:tw-space-x-0'
  | 'xl:tw-space-x-0'
  | 'sm:tw-space-x-1'
  | 'md:tw-space-x-1'
  | 'lg:tw-space-x-1'
  | 'xl:tw-space-x-1'
  | 'sm:tw-space-x-2'
  | 'md:tw-space-x-2'
  | 'lg:tw-space-x-2'
  | 'xl:tw-space-x-2'
  | 'sm:tw-space-x-3'
  | 'md:tw-space-x-3'
  | 'lg:tw-space-x-3'
  | 'xl:tw-space-x-3'
  | 'sm:tw-space-x-4'
  | 'md:tw-space-x-4'
  | 'lg:tw-space-x-4'
  | 'xl:tw-space-x-4'
  | 'sm:tw-space-x-5'
  | 'md:tw-space-x-5'
  | 'lg:tw-space-x-5'
  | 'xl:tw-space-x-5'
  | 'sm:tw-space-x-6'
  | 'md:tw-space-x-6'
  | 'lg:tw-space-x-6'
  | 'xl:tw-space-x-6'
  | 'sm:tw-space-x-8'
  | 'md:tw-space-x-8'
  | 'lg:tw-space-x-8'
  | 'xl:tw-space-x-8'
  | 'sm:tw-space-x-10'
  | 'md:tw-space-x-10'
  | 'lg:tw-space-x-10'
  | 'xl:tw-space-x-10'
  | 'sm:tw-space-x-12'
  | 'md:tw-space-x-12'
  | 'lg:tw-space-x-12'
  | 'xl:tw-space-x-12'
  | 'sm:tw-space-x-16'
  | 'md:tw-space-x-16'
  | 'lg:tw-space-x-16'
  | 'xl:tw-space-x-16'
  | 'sm:tw-space-x-20'
  | 'md:tw-space-x-20'
  | 'lg:tw-space-x-20'
  | 'xl:tw-space-x-20'
  | 'sm:tw-space-x-24'
  | 'md:tw-space-x-24'
  | 'lg:tw-space-x-24'
  | 'xl:tw-space-x-24'
  | 'sm:tw-space-x-32'
  | 'md:tw-space-x-32'
  | 'lg:tw-space-x-32'
  | 'xl:tw-space-x-32'
  | 'sm:tw-space-x-40'
  | 'md:tw-space-x-40'
  | 'lg:tw-space-x-40'
  | 'xl:tw-space-x-40'
  | 'sm:tw-space-x-48'
  | 'md:tw-space-x-48'
  | 'lg:tw-space-x-48'
  | 'xl:tw-space-x-48'
  | 'sm:tw-space-x-56'
  | 'md:tw-space-x-56'
  | 'lg:tw-space-x-56'
  | 'xl:tw-space-x-56'
  | 'sm:tw-space-x-64'
  | 'md:tw-space-x-64'
  | 'lg:tw-space-x-64'
  | 'xl:tw-space-x-64'
  | 'sm:tw-space-x-px'
  | 'md:tw-space-x-px'
  | 'lg:tw-space-x-px'
  | 'xl:tw-space-x-px'
  | 'sm:tw--space-x-0'
  | 'md:tw--space-x-0'
  | 'lg:tw--space-x-0'
  | 'xl:tw--space-x-0'
  | 'sm:tw--space-x-1'
  | 'md:tw--space-x-1'
  | 'lg:tw--space-x-1'
  | 'xl:tw--space-x-1'
  | 'sm:tw--space-x-2'
  | 'md:tw--space-x-2'
  | 'lg:tw--space-x-2'
  | 'xl:tw--space-x-2'
  | 'sm:tw--space-x-3'
  | 'md:tw--space-x-3'
  | 'lg:tw--space-x-3'
  | 'xl:tw--space-x-3'
  | 'sm:tw--space-x-4'
  | 'md:tw--space-x-4'
  | 'lg:tw--space-x-4'
  | 'xl:tw--space-x-4'
  | 'sm:tw--space-x-5'
  | 'md:tw--space-x-5'
  | 'lg:tw--space-x-5'
  | 'xl:tw--space-x-5'
  | 'sm:tw--space-x-6'
  | 'md:tw--space-x-6'
  | 'lg:tw--space-x-6'
  | 'xl:tw--space-x-6'
  | 'sm:tw--space-x-8'
  | 'md:tw--space-x-8'
  | 'lg:tw--space-x-8'
  | 'xl:tw--space-x-8'
  | 'sm:tw--space-x-10'
  | 'md:tw--space-x-10'
  | 'lg:tw--space-x-10'
  | 'xl:tw--space-x-10'
  | 'sm:tw--space-x-12'
  | 'md:tw--space-x-12'
  | 'lg:tw--space-x-12'
  | 'xl:tw--space-x-12'
  | 'sm:tw--space-x-16'
  | 'md:tw--space-x-16'
  | 'lg:tw--space-x-16'
  | 'xl:tw--space-x-16'
  | 'sm:tw--space-x-20'
  | 'md:tw--space-x-20'
  | 'lg:tw--space-x-20'
  | 'xl:tw--space-x-20'
  | 'sm:tw--space-x-24'
  | 'md:tw--space-x-24'
  | 'lg:tw--space-x-24'
  | 'xl:tw--space-x-24'
  | 'sm:tw--space-x-32'
  | 'md:tw--space-x-32'
  | 'lg:tw--space-x-32'
  | 'xl:tw--space-x-32'
  | 'sm:tw--space-x-40'
  | 'md:tw--space-x-40'
  | 'lg:tw--space-x-40'
  | 'xl:tw--space-x-40'
  | 'sm:tw--space-x-48'
  | 'md:tw--space-x-48'
  | 'lg:tw--space-x-48'
  | 'xl:tw--space-x-48'
  | 'sm:tw--space-x-56'
  | 'md:tw--space-x-56'
  | 'lg:tw--space-x-56'
  | 'xl:tw--space-x-56'
  | 'sm:tw--space-x-64'
  | 'md:tw--space-x-64'
  | 'lg:tw--space-x-64'
  | 'xl:tw--space-x-64'
  | 'sm:tw--space-x-px'
  | 'md:tw--space-x-px'
  | 'lg:tw--space-x-px'
  | 'xl:tw--space-x-px'
  | 'sm:tw-space-x-reverse'
  | 'md:tw-space-x-reverse'
  | 'lg:tw-space-x-reverse'
  | 'xl:tw-space-x-reverse'
  | 'sm:tw-space-y-0'
  | 'md:tw-space-y-0'
  | 'lg:tw-space-y-0'
  | 'xl:tw-space-y-0'
  | 'sm:tw-space-y-1'
  | 'md:tw-space-y-1'
  | 'lg:tw-space-y-1'
  | 'xl:tw-space-y-1'
  | 'sm:tw-space-y-2'
  | 'md:tw-space-y-2'
  | 'lg:tw-space-y-2'
  | 'xl:tw-space-y-2'
  | 'sm:tw-space-y-3'
  | 'md:tw-space-y-3'
  | 'lg:tw-space-y-3'
  | 'xl:tw-space-y-3'
  | 'sm:tw-space-y-4'
  | 'md:tw-space-y-4'
  | 'lg:tw-space-y-4'
  | 'xl:tw-space-y-4'
  | 'sm:tw-space-y-5'
  | 'md:tw-space-y-5'
  | 'lg:tw-space-y-5'
  | 'xl:tw-space-y-5'
  | 'sm:tw-space-y-6'
  | 'md:tw-space-y-6'
  | 'lg:tw-space-y-6'
  | 'xl:tw-space-y-6'
  | 'sm:tw-space-y-8'
  | 'md:tw-space-y-8'
  | 'lg:tw-space-y-8'
  | 'xl:tw-space-y-8'
  | 'sm:tw-space-y-10'
  | 'md:tw-space-y-10'
  | 'lg:tw-space-y-10'
  | 'xl:tw-space-y-10'
  | 'sm:tw-space-y-12'
  | 'md:tw-space-y-12'
  | 'lg:tw-space-y-12'
  | 'xl:tw-space-y-12'
  | 'sm:tw-space-y-16'
  | 'md:tw-space-y-16'
  | 'lg:tw-space-y-16'
  | 'xl:tw-space-y-16'
  | 'sm:tw-space-y-20'
  | 'md:tw-space-y-20'
  | 'lg:tw-space-y-20'
  | 'xl:tw-space-y-20'
  | 'sm:tw-space-y-24'
  | 'md:tw-space-y-24'
  | 'lg:tw-space-y-24'
  | 'xl:tw-space-y-24'
  | 'sm:tw-space-y-32'
  | 'md:tw-space-y-32'
  | 'lg:tw-space-y-32'
  | 'xl:tw-space-y-32'
  | 'sm:tw-space-y-40'
  | 'md:tw-space-y-40'
  | 'lg:tw-space-y-40'
  | 'xl:tw-space-y-40'
  | 'sm:tw-space-y-48'
  | 'md:tw-space-y-48'
  | 'lg:tw-space-y-48'
  | 'xl:tw-space-y-48'
  | 'sm:tw-space-y-56'
  | 'md:tw-space-y-56'
  | 'lg:tw-space-y-56'
  | 'xl:tw-space-y-56'
  | 'sm:tw-space-y-64'
  | 'md:tw-space-y-64'
  | 'lg:tw-space-y-64'
  | 'xl:tw-space-y-64'
  | 'sm:tw-space-y-px'
  | 'md:tw-space-y-px'
  | 'lg:tw-space-y-px'
  | 'xl:tw-space-y-px'
  | 'sm:tw--space-y-0'
  | 'md:tw--space-y-0'
  | 'lg:tw--space-y-0'
  | 'xl:tw--space-y-0'
  | 'sm:tw--space-y-1'
  | 'md:tw--space-y-1'
  | 'lg:tw--space-y-1'
  | 'xl:tw--space-y-1'
  | 'sm:tw--space-y-2'
  | 'md:tw--space-y-2'
  | 'lg:tw--space-y-2'
  | 'xl:tw--space-y-2'
  | 'sm:tw--space-y-3'
  | 'md:tw--space-y-3'
  | 'lg:tw--space-y-3'
  | 'xl:tw--space-y-3'
  | 'sm:tw--space-y-4'
  | 'md:tw--space-y-4'
  | 'lg:tw--space-y-4'
  | 'xl:tw--space-y-4'
  | 'sm:tw--space-y-5'
  | 'md:tw--space-y-5'
  | 'lg:tw--space-y-5'
  | 'xl:tw--space-y-5'
  | 'sm:tw--space-y-6'
  | 'md:tw--space-y-6'
  | 'lg:tw--space-y-6'
  | 'xl:tw--space-y-6'
  | 'sm:tw--space-y-8'
  | 'md:tw--space-y-8'
  | 'lg:tw--space-y-8'
  | 'xl:tw--space-y-8'
  | 'sm:tw--space-y-10'
  | 'md:tw--space-y-10'
  | 'lg:tw--space-y-10'
  | 'xl:tw--space-y-10'
  | 'sm:tw--space-y-12'
  | 'md:tw--space-y-12'
  | 'lg:tw--space-y-12'
  | 'xl:tw--space-y-12'
  | 'sm:tw--space-y-16'
  | 'md:tw--space-y-16'
  | 'lg:tw--space-y-16'
  | 'xl:tw--space-y-16'
  | 'sm:tw--space-y-20'
  | 'md:tw--space-y-20'
  | 'lg:tw--space-y-20'
  | 'xl:tw--space-y-20'
  | 'sm:tw--space-y-24'
  | 'md:tw--space-y-24'
  | 'lg:tw--space-y-24'
  | 'xl:tw--space-y-24'
  | 'sm:tw--space-y-32'
  | 'md:tw--space-y-32'
  | 'lg:tw--space-y-32'
  | 'xl:tw--space-y-32'
  | 'sm:tw--space-y-40'
  | 'md:tw--space-y-40'
  | 'lg:tw--space-y-40'
  | 'xl:tw--space-y-40'
  | 'sm:tw--space-y-48'
  | 'md:tw--space-y-48'
  | 'lg:tw--space-y-48'
  | 'xl:tw--space-y-48'
  | 'sm:tw--space-y-56'
  | 'md:tw--space-y-56'
  | 'lg:tw--space-y-56'
  | 'xl:tw--space-y-56'
  | 'sm:tw--space-y-64'
  | 'md:tw--space-y-64'
  | 'lg:tw--space-y-64'
  | 'xl:tw--space-y-64'
  | 'sm:tw--space-y-px'
  | 'md:tw--space-y-px'
  | 'lg:tw--space-y-px'
  | 'xl:tw--space-y-px'
  | 'sm:tw-space-y-reverse'
  | 'md:tw-space-y-reverse'
  | 'lg:tw-space-y-reverse'
  | 'xl:tw-space-y-reverse'
  | 'sm:tw-stroke-current'
  | 'md:tw-stroke-current'
  | 'lg:tw-stroke-current'
  | 'xl:tw-stroke-current'
  | 'sm:tw-stroke-0'
  | 'md:tw-stroke-0'
  | 'lg:tw-stroke-0'
  | 'xl:tw-stroke-0'
  | 'sm:tw-stroke-1'
  | 'md:tw-stroke-1'
  | 'lg:tw-stroke-1'
  | 'xl:tw-stroke-1'
  | 'sm:tw-stroke-2'
  | 'md:tw-stroke-2'
  | 'lg:tw-stroke-2'
  | 'xl:tw-stroke-2'
  | 'sm:tw-table-auto'
  | 'md:tw-table-auto'
  | 'lg:tw-table-auto'
  | 'xl:tw-table-auto'
  | 'sm:tw-table-fixed'
  | 'md:tw-table-fixed'
  | 'lg:tw-table-fixed'
  | 'xl:tw-table-fixed'
  | 'sm:tw-text-left'
  | 'md:tw-text-left'
  | 'lg:tw-text-left'
  | 'xl:tw-text-left'
  | 'sm:tw-text-center'
  | 'md:tw-text-center'
  | 'lg:tw-text-center'
  | 'xl:tw-text-center'
  | 'sm:tw-text-right'
  | 'md:tw-text-right'
  | 'lg:tw-text-right'
  | 'xl:tw-text-right'
  | 'sm:tw-text-justify'
  | 'md:tw-text-justify'
  | 'lg:tw-text-justify'
  | 'xl:tw-text-justify'
  | 'sm:tw-text-primary'
  | 'md:tw-text-primary'
  | 'lg:tw-text-primary'
  | 'xl:tw-text-primary'
  | 'hover:tw-text-primary'
  | 'focus:tw-text-primary'
  | 'sm:tw-text-muted'
  | 'md:tw-text-muted'
  | 'lg:tw-text-muted'
  | 'xl:tw-text-muted'
  | 'hover:tw-text-muted'
  | 'focus:tw-text-muted'
  | 'sm:tw-text-warning'
  | 'md:tw-text-warning'
  | 'lg:tw-text-warning'
  | 'xl:tw-text-warning'
  | 'hover:tw-text-warning'
  | 'focus:tw-text-warning'
  | 'sm:tw-text-danger'
  | 'md:tw-text-danger'
  | 'lg:tw-text-danger'
  | 'xl:tw-text-danger'
  | 'hover:tw-text-danger'
  | 'focus:tw-text-danger'
  | 'sm:tw-text-fail'
  | 'md:tw-text-fail'
  | 'lg:tw-text-fail'
  | 'xl:tw-text-fail'
  | 'hover:tw-text-fail'
  | 'focus:tw-text-fail'
  | 'sm:tw-text-info'
  | 'md:tw-text-info'
  | 'lg:tw-text-info'
  | 'xl:tw-text-info'
  | 'hover:tw-text-info'
  | 'focus:tw-text-info'
  | 'sm:tw-text-red-50'
  | 'md:tw-text-red-50'
  | 'lg:tw-text-red-50'
  | 'xl:tw-text-red-50'
  | 'hover:tw-text-red-50'
  | 'focus:tw-text-red-50'
  | 'sm:tw-text-red-100'
  | 'md:tw-text-red-100'
  | 'lg:tw-text-red-100'
  | 'xl:tw-text-red-100'
  | 'hover:tw-text-red-100'
  | 'focus:tw-text-red-100'
  | 'sm:tw-text-red-200'
  | 'md:tw-text-red-200'
  | 'lg:tw-text-red-200'
  | 'xl:tw-text-red-200'
  | 'hover:tw-text-red-200'
  | 'focus:tw-text-red-200'
  | 'sm:tw-text-red-300'
  | 'md:tw-text-red-300'
  | 'lg:tw-text-red-300'
  | 'xl:tw-text-red-300'
  | 'hover:tw-text-red-300'
  | 'focus:tw-text-red-300'
  | 'sm:tw-text-red-400'
  | 'md:tw-text-red-400'
  | 'lg:tw-text-red-400'
  | 'xl:tw-text-red-400'
  | 'hover:tw-text-red-400'
  | 'focus:tw-text-red-400'
  | 'sm:tw-text-red-500'
  | 'md:tw-text-red-500'
  | 'lg:tw-text-red-500'
  | 'xl:tw-text-red-500'
  | 'hover:tw-text-red-500'
  | 'focus:tw-text-red-500'
  | 'sm:tw-text-red-600'
  | 'md:tw-text-red-600'
  | 'lg:tw-text-red-600'
  | 'xl:tw-text-red-600'
  | 'hover:tw-text-red-600'
  | 'focus:tw-text-red-600'
  | 'sm:tw-text-red-700'
  | 'md:tw-text-red-700'
  | 'lg:tw-text-red-700'
  | 'xl:tw-text-red-700'
  | 'hover:tw-text-red-700'
  | 'focus:tw-text-red-700'
  | 'sm:tw-text-red-800'
  | 'md:tw-text-red-800'
  | 'lg:tw-text-red-800'
  | 'xl:tw-text-red-800'
  | 'hover:tw-text-red-800'
  | 'focus:tw-text-red-800'
  | 'sm:tw-text-red-900'
  | 'md:tw-text-red-900'
  | 'lg:tw-text-red-900'
  | 'xl:tw-text-red-900'
  | 'hover:tw-text-red-900'
  | 'focus:tw-text-red-900'
  | 'sm:tw-text-red-a100'
  | 'md:tw-text-red-a100'
  | 'lg:tw-text-red-a100'
  | 'xl:tw-text-red-a100'
  | 'hover:tw-text-red-a100'
  | 'focus:tw-text-red-a100'
  | 'sm:tw-text-red-a200'
  | 'md:tw-text-red-a200'
  | 'lg:tw-text-red-a200'
  | 'xl:tw-text-red-a200'
  | 'hover:tw-text-red-a200'
  | 'focus:tw-text-red-a200'
  | 'sm:tw-text-red-a400'
  | 'md:tw-text-red-a400'
  | 'lg:tw-text-red-a400'
  | 'xl:tw-text-red-a400'
  | 'hover:tw-text-red-a400'
  | 'focus:tw-text-red-a400'
  | 'sm:tw-text-red-a700'
  | 'md:tw-text-red-a700'
  | 'lg:tw-text-red-a700'
  | 'xl:tw-text-red-a700'
  | 'hover:tw-text-red-a700'
  | 'focus:tw-text-red-a700'
  | 'sm:tw-text-pink-50'
  | 'md:tw-text-pink-50'
  | 'lg:tw-text-pink-50'
  | 'xl:tw-text-pink-50'
  | 'hover:tw-text-pink-50'
  | 'focus:tw-text-pink-50'
  | 'sm:tw-text-pink-100'
  | 'md:tw-text-pink-100'
  | 'lg:tw-text-pink-100'
  | 'xl:tw-text-pink-100'
  | 'hover:tw-text-pink-100'
  | 'focus:tw-text-pink-100'
  | 'sm:tw-text-pink-200'
  | 'md:tw-text-pink-200'
  | 'lg:tw-text-pink-200'
  | 'xl:tw-text-pink-200'
  | 'hover:tw-text-pink-200'
  | 'focus:tw-text-pink-200'
  | 'sm:tw-text-pink-300'
  | 'md:tw-text-pink-300'
  | 'lg:tw-text-pink-300'
  | 'xl:tw-text-pink-300'
  | 'hover:tw-text-pink-300'
  | 'focus:tw-text-pink-300'
  | 'sm:tw-text-pink-400'
  | 'md:tw-text-pink-400'
  | 'lg:tw-text-pink-400'
  | 'xl:tw-text-pink-400'
  | 'hover:tw-text-pink-400'
  | 'focus:tw-text-pink-400'
  | 'sm:tw-text-pink-500'
  | 'md:tw-text-pink-500'
  | 'lg:tw-text-pink-500'
  | 'xl:tw-text-pink-500'
  | 'hover:tw-text-pink-500'
  | 'focus:tw-text-pink-500'
  | 'sm:tw-text-pink-600'
  | 'md:tw-text-pink-600'
  | 'lg:tw-text-pink-600'
  | 'xl:tw-text-pink-600'
  | 'hover:tw-text-pink-600'
  | 'focus:tw-text-pink-600'
  | 'sm:tw-text-pink-700'
  | 'md:tw-text-pink-700'
  | 'lg:tw-text-pink-700'
  | 'xl:tw-text-pink-700'
  | 'hover:tw-text-pink-700'
  | 'focus:tw-text-pink-700'
  | 'sm:tw-text-pink-800'
  | 'md:tw-text-pink-800'
  | 'lg:tw-text-pink-800'
  | 'xl:tw-text-pink-800'
  | 'hover:tw-text-pink-800'
  | 'focus:tw-text-pink-800'
  | 'sm:tw-text-pink-900'
  | 'md:tw-text-pink-900'
  | 'lg:tw-text-pink-900'
  | 'xl:tw-text-pink-900'
  | 'hover:tw-text-pink-900'
  | 'focus:tw-text-pink-900'
  | 'sm:tw-text-pink-a100'
  | 'md:tw-text-pink-a100'
  | 'lg:tw-text-pink-a100'
  | 'xl:tw-text-pink-a100'
  | 'hover:tw-text-pink-a100'
  | 'focus:tw-text-pink-a100'
  | 'sm:tw-text-pink-a200'
  | 'md:tw-text-pink-a200'
  | 'lg:tw-text-pink-a200'
  | 'xl:tw-text-pink-a200'
  | 'hover:tw-text-pink-a200'
  | 'focus:tw-text-pink-a200'
  | 'sm:tw-text-pink-a400'
  | 'md:tw-text-pink-a400'
  | 'lg:tw-text-pink-a400'
  | 'xl:tw-text-pink-a400'
  | 'hover:tw-text-pink-a400'
  | 'focus:tw-text-pink-a400'
  | 'sm:tw-text-pink-a700'
  | 'md:tw-text-pink-a700'
  | 'lg:tw-text-pink-a700'
  | 'xl:tw-text-pink-a700'
  | 'hover:tw-text-pink-a700'
  | 'focus:tw-text-pink-a700'
  | 'sm:tw-text-purple-50'
  | 'md:tw-text-purple-50'
  | 'lg:tw-text-purple-50'
  | 'xl:tw-text-purple-50'
  | 'hover:tw-text-purple-50'
  | 'focus:tw-text-purple-50'
  | 'sm:tw-text-purple-100'
  | 'md:tw-text-purple-100'
  | 'lg:tw-text-purple-100'
  | 'xl:tw-text-purple-100'
  | 'hover:tw-text-purple-100'
  | 'focus:tw-text-purple-100'
  | 'sm:tw-text-purple-200'
  | 'md:tw-text-purple-200'
  | 'lg:tw-text-purple-200'
  | 'xl:tw-text-purple-200'
  | 'hover:tw-text-purple-200'
  | 'focus:tw-text-purple-200'
  | 'sm:tw-text-purple-300'
  | 'md:tw-text-purple-300'
  | 'lg:tw-text-purple-300'
  | 'xl:tw-text-purple-300'
  | 'hover:tw-text-purple-300'
  | 'focus:tw-text-purple-300'
  | 'sm:tw-text-purple-400'
  | 'md:tw-text-purple-400'
  | 'lg:tw-text-purple-400'
  | 'xl:tw-text-purple-400'
  | 'hover:tw-text-purple-400'
  | 'focus:tw-text-purple-400'
  | 'sm:tw-text-purple-500'
  | 'md:tw-text-purple-500'
  | 'lg:tw-text-purple-500'
  | 'xl:tw-text-purple-500'
  | 'hover:tw-text-purple-500'
  | 'focus:tw-text-purple-500'
  | 'sm:tw-text-purple-600'
  | 'md:tw-text-purple-600'
  | 'lg:tw-text-purple-600'
  | 'xl:tw-text-purple-600'
  | 'hover:tw-text-purple-600'
  | 'focus:tw-text-purple-600'
  | 'sm:tw-text-purple-700'
  | 'md:tw-text-purple-700'
  | 'lg:tw-text-purple-700'
  | 'xl:tw-text-purple-700'
  | 'hover:tw-text-purple-700'
  | 'focus:tw-text-purple-700'
  | 'sm:tw-text-purple-800'
  | 'md:tw-text-purple-800'
  | 'lg:tw-text-purple-800'
  | 'xl:tw-text-purple-800'
  | 'hover:tw-text-purple-800'
  | 'focus:tw-text-purple-800'
  | 'sm:tw-text-purple-900'
  | 'md:tw-text-purple-900'
  | 'lg:tw-text-purple-900'
  | 'xl:tw-text-purple-900'
  | 'hover:tw-text-purple-900'
  | 'focus:tw-text-purple-900'
  | 'sm:tw-text-purple-a100'
  | 'md:tw-text-purple-a100'
  | 'lg:tw-text-purple-a100'
  | 'xl:tw-text-purple-a100'
  | 'hover:tw-text-purple-a100'
  | 'focus:tw-text-purple-a100'
  | 'sm:tw-text-purple-a200'
  | 'md:tw-text-purple-a200'
  | 'lg:tw-text-purple-a200'
  | 'xl:tw-text-purple-a200'
  | 'hover:tw-text-purple-a200'
  | 'focus:tw-text-purple-a200'
  | 'sm:tw-text-purple-a400'
  | 'md:tw-text-purple-a400'
  | 'lg:tw-text-purple-a400'
  | 'xl:tw-text-purple-a400'
  | 'hover:tw-text-purple-a400'
  | 'focus:tw-text-purple-a400'
  | 'sm:tw-text-purple-a700'
  | 'md:tw-text-purple-a700'
  | 'lg:tw-text-purple-a700'
  | 'xl:tw-text-purple-a700'
  | 'hover:tw-text-purple-a700'
  | 'focus:tw-text-purple-a700'
  | 'sm:tw-text-deepPurple-50'
  | 'md:tw-text-deepPurple-50'
  | 'lg:tw-text-deepPurple-50'
  | 'xl:tw-text-deepPurple-50'
  | 'hover:tw-text-deepPurple-50'
  | 'focus:tw-text-deepPurple-50'
  | 'sm:tw-text-deepPurple-100'
  | 'md:tw-text-deepPurple-100'
  | 'lg:tw-text-deepPurple-100'
  | 'xl:tw-text-deepPurple-100'
  | 'hover:tw-text-deepPurple-100'
  | 'focus:tw-text-deepPurple-100'
  | 'sm:tw-text-deepPurple-200'
  | 'md:tw-text-deepPurple-200'
  | 'lg:tw-text-deepPurple-200'
  | 'xl:tw-text-deepPurple-200'
  | 'hover:tw-text-deepPurple-200'
  | 'focus:tw-text-deepPurple-200'
  | 'sm:tw-text-deepPurple-300'
  | 'md:tw-text-deepPurple-300'
  | 'lg:tw-text-deepPurple-300'
  | 'xl:tw-text-deepPurple-300'
  | 'hover:tw-text-deepPurple-300'
  | 'focus:tw-text-deepPurple-300'
  | 'sm:tw-text-deepPurple-400'
  | 'md:tw-text-deepPurple-400'
  | 'lg:tw-text-deepPurple-400'
  | 'xl:tw-text-deepPurple-400'
  | 'hover:tw-text-deepPurple-400'
  | 'focus:tw-text-deepPurple-400'
  | 'sm:tw-text-deepPurple-500'
  | 'md:tw-text-deepPurple-500'
  | 'lg:tw-text-deepPurple-500'
  | 'xl:tw-text-deepPurple-500'
  | 'hover:tw-text-deepPurple-500'
  | 'focus:tw-text-deepPurple-500'
  | 'sm:tw-text-deepPurple-600'
  | 'md:tw-text-deepPurple-600'
  | 'lg:tw-text-deepPurple-600'
  | 'xl:tw-text-deepPurple-600'
  | 'hover:tw-text-deepPurple-600'
  | 'focus:tw-text-deepPurple-600'
  | 'sm:tw-text-deepPurple-700'
  | 'md:tw-text-deepPurple-700'
  | 'lg:tw-text-deepPurple-700'
  | 'xl:tw-text-deepPurple-700'
  | 'hover:tw-text-deepPurple-700'
  | 'focus:tw-text-deepPurple-700'
  | 'sm:tw-text-deepPurple-800'
  | 'md:tw-text-deepPurple-800'
  | 'lg:tw-text-deepPurple-800'
  | 'xl:tw-text-deepPurple-800'
  | 'hover:tw-text-deepPurple-800'
  | 'focus:tw-text-deepPurple-800'
  | 'sm:tw-text-deepPurple-900'
  | 'md:tw-text-deepPurple-900'
  | 'lg:tw-text-deepPurple-900'
  | 'xl:tw-text-deepPurple-900'
  | 'hover:tw-text-deepPurple-900'
  | 'focus:tw-text-deepPurple-900'
  | 'sm:tw-text-deepPurple-a100'
  | 'md:tw-text-deepPurple-a100'
  | 'lg:tw-text-deepPurple-a100'
  | 'xl:tw-text-deepPurple-a100'
  | 'hover:tw-text-deepPurple-a100'
  | 'focus:tw-text-deepPurple-a100'
  | 'sm:tw-text-deepPurple-a200'
  | 'md:tw-text-deepPurple-a200'
  | 'lg:tw-text-deepPurple-a200'
  | 'xl:tw-text-deepPurple-a200'
  | 'hover:tw-text-deepPurple-a200'
  | 'focus:tw-text-deepPurple-a200'
  | 'sm:tw-text-deepPurple-a400'
  | 'md:tw-text-deepPurple-a400'
  | 'lg:tw-text-deepPurple-a400'
  | 'xl:tw-text-deepPurple-a400'
  | 'hover:tw-text-deepPurple-a400'
  | 'focus:tw-text-deepPurple-a400'
  | 'sm:tw-text-deepPurple-a700'
  | 'md:tw-text-deepPurple-a700'
  | 'lg:tw-text-deepPurple-a700'
  | 'xl:tw-text-deepPurple-a700'
  | 'hover:tw-text-deepPurple-a700'
  | 'focus:tw-text-deepPurple-a700'
  | 'sm:tw-text-indigo-50'
  | 'md:tw-text-indigo-50'
  | 'lg:tw-text-indigo-50'
  | 'xl:tw-text-indigo-50'
  | 'hover:tw-text-indigo-50'
  | 'focus:tw-text-indigo-50'
  | 'sm:tw-text-indigo-100'
  | 'md:tw-text-indigo-100'
  | 'lg:tw-text-indigo-100'
  | 'xl:tw-text-indigo-100'
  | 'hover:tw-text-indigo-100'
  | 'focus:tw-text-indigo-100'
  | 'sm:tw-text-indigo-200'
  | 'md:tw-text-indigo-200'
  | 'lg:tw-text-indigo-200'
  | 'xl:tw-text-indigo-200'
  | 'hover:tw-text-indigo-200'
  | 'focus:tw-text-indigo-200'
  | 'sm:tw-text-indigo-300'
  | 'md:tw-text-indigo-300'
  | 'lg:tw-text-indigo-300'
  | 'xl:tw-text-indigo-300'
  | 'hover:tw-text-indigo-300'
  | 'focus:tw-text-indigo-300'
  | 'sm:tw-text-indigo-400'
  | 'md:tw-text-indigo-400'
  | 'lg:tw-text-indigo-400'
  | 'xl:tw-text-indigo-400'
  | 'hover:tw-text-indigo-400'
  | 'focus:tw-text-indigo-400'
  | 'sm:tw-text-indigo-500'
  | 'md:tw-text-indigo-500'
  | 'lg:tw-text-indigo-500'
  | 'xl:tw-text-indigo-500'
  | 'hover:tw-text-indigo-500'
  | 'focus:tw-text-indigo-500'
  | 'sm:tw-text-indigo-600'
  | 'md:tw-text-indigo-600'
  | 'lg:tw-text-indigo-600'
  | 'xl:tw-text-indigo-600'
  | 'hover:tw-text-indigo-600'
  | 'focus:tw-text-indigo-600'
  | 'sm:tw-text-indigo-700'
  | 'md:tw-text-indigo-700'
  | 'lg:tw-text-indigo-700'
  | 'xl:tw-text-indigo-700'
  | 'hover:tw-text-indigo-700'
  | 'focus:tw-text-indigo-700'
  | 'sm:tw-text-indigo-800'
  | 'md:tw-text-indigo-800'
  | 'lg:tw-text-indigo-800'
  | 'xl:tw-text-indigo-800'
  | 'hover:tw-text-indigo-800'
  | 'focus:tw-text-indigo-800'
  | 'sm:tw-text-indigo-900'
  | 'md:tw-text-indigo-900'
  | 'lg:tw-text-indigo-900'
  | 'xl:tw-text-indigo-900'
  | 'hover:tw-text-indigo-900'
  | 'focus:tw-text-indigo-900'
  | 'sm:tw-text-indigo-a100'
  | 'md:tw-text-indigo-a100'
  | 'lg:tw-text-indigo-a100'
  | 'xl:tw-text-indigo-a100'
  | 'hover:tw-text-indigo-a100'
  | 'focus:tw-text-indigo-a100'
  | 'sm:tw-text-indigo-a200'
  | 'md:tw-text-indigo-a200'
  | 'lg:tw-text-indigo-a200'
  | 'xl:tw-text-indigo-a200'
  | 'hover:tw-text-indigo-a200'
  | 'focus:tw-text-indigo-a200'
  | 'sm:tw-text-indigo-a400'
  | 'md:tw-text-indigo-a400'
  | 'lg:tw-text-indigo-a400'
  | 'xl:tw-text-indigo-a400'
  | 'hover:tw-text-indigo-a400'
  | 'focus:tw-text-indigo-a400'
  | 'sm:tw-text-indigo-a700'
  | 'md:tw-text-indigo-a700'
  | 'lg:tw-text-indigo-a700'
  | 'xl:tw-text-indigo-a700'
  | 'hover:tw-text-indigo-a700'
  | 'focus:tw-text-indigo-a700'
  | 'sm:tw-text-blue-50'
  | 'md:tw-text-blue-50'
  | 'lg:tw-text-blue-50'
  | 'xl:tw-text-blue-50'
  | 'hover:tw-text-blue-50'
  | 'focus:tw-text-blue-50'
  | 'sm:tw-text-blue-100'
  | 'md:tw-text-blue-100'
  | 'lg:tw-text-blue-100'
  | 'xl:tw-text-blue-100'
  | 'hover:tw-text-blue-100'
  | 'focus:tw-text-blue-100'
  | 'sm:tw-text-blue-200'
  | 'md:tw-text-blue-200'
  | 'lg:tw-text-blue-200'
  | 'xl:tw-text-blue-200'
  | 'hover:tw-text-blue-200'
  | 'focus:tw-text-blue-200'
  | 'sm:tw-text-blue-300'
  | 'md:tw-text-blue-300'
  | 'lg:tw-text-blue-300'
  | 'xl:tw-text-blue-300'
  | 'hover:tw-text-blue-300'
  | 'focus:tw-text-blue-300'
  | 'sm:tw-text-blue-400'
  | 'md:tw-text-blue-400'
  | 'lg:tw-text-blue-400'
  | 'xl:tw-text-blue-400'
  | 'hover:tw-text-blue-400'
  | 'focus:tw-text-blue-400'
  | 'sm:tw-text-blue-500'
  | 'md:tw-text-blue-500'
  | 'lg:tw-text-blue-500'
  | 'xl:tw-text-blue-500'
  | 'hover:tw-text-blue-500'
  | 'focus:tw-text-blue-500'
  | 'sm:tw-text-blue-600'
  | 'md:tw-text-blue-600'
  | 'lg:tw-text-blue-600'
  | 'xl:tw-text-blue-600'
  | 'hover:tw-text-blue-600'
  | 'focus:tw-text-blue-600'
  | 'sm:tw-text-blue-700'
  | 'md:tw-text-blue-700'
  | 'lg:tw-text-blue-700'
  | 'xl:tw-text-blue-700'
  | 'hover:tw-text-blue-700'
  | 'focus:tw-text-blue-700'
  | 'sm:tw-text-blue-800'
  | 'md:tw-text-blue-800'
  | 'lg:tw-text-blue-800'
  | 'xl:tw-text-blue-800'
  | 'hover:tw-text-blue-800'
  | 'focus:tw-text-blue-800'
  | 'sm:tw-text-blue-900'
  | 'md:tw-text-blue-900'
  | 'lg:tw-text-blue-900'
  | 'xl:tw-text-blue-900'
  | 'hover:tw-text-blue-900'
  | 'focus:tw-text-blue-900'
  | 'sm:tw-text-blue-a100'
  | 'md:tw-text-blue-a100'
  | 'lg:tw-text-blue-a100'
  | 'xl:tw-text-blue-a100'
  | 'hover:tw-text-blue-a100'
  | 'focus:tw-text-blue-a100'
  | 'sm:tw-text-blue-a200'
  | 'md:tw-text-blue-a200'
  | 'lg:tw-text-blue-a200'
  | 'xl:tw-text-blue-a200'
  | 'hover:tw-text-blue-a200'
  | 'focus:tw-text-blue-a200'
  | 'sm:tw-text-blue-a400'
  | 'md:tw-text-blue-a400'
  | 'lg:tw-text-blue-a400'
  | 'xl:tw-text-blue-a400'
  | 'hover:tw-text-blue-a400'
  | 'focus:tw-text-blue-a400'
  | 'sm:tw-text-blue-a700'
  | 'md:tw-text-blue-a700'
  | 'lg:tw-text-blue-a700'
  | 'xl:tw-text-blue-a700'
  | 'hover:tw-text-blue-a700'
  | 'focus:tw-text-blue-a700'
  | 'sm:tw-text-lightBlue-50'
  | 'md:tw-text-lightBlue-50'
  | 'lg:tw-text-lightBlue-50'
  | 'xl:tw-text-lightBlue-50'
  | 'hover:tw-text-lightBlue-50'
  | 'focus:tw-text-lightBlue-50'
  | 'sm:tw-text-lightBlue-100'
  | 'md:tw-text-lightBlue-100'
  | 'lg:tw-text-lightBlue-100'
  | 'xl:tw-text-lightBlue-100'
  | 'hover:tw-text-lightBlue-100'
  | 'focus:tw-text-lightBlue-100'
  | 'sm:tw-text-lightBlue-200'
  | 'md:tw-text-lightBlue-200'
  | 'lg:tw-text-lightBlue-200'
  | 'xl:tw-text-lightBlue-200'
  | 'hover:tw-text-lightBlue-200'
  | 'focus:tw-text-lightBlue-200'
  | 'sm:tw-text-lightBlue-300'
  | 'md:tw-text-lightBlue-300'
  | 'lg:tw-text-lightBlue-300'
  | 'xl:tw-text-lightBlue-300'
  | 'hover:tw-text-lightBlue-300'
  | 'focus:tw-text-lightBlue-300'
  | 'sm:tw-text-lightBlue-400'
  | 'md:tw-text-lightBlue-400'
  | 'lg:tw-text-lightBlue-400'
  | 'xl:tw-text-lightBlue-400'
  | 'hover:tw-text-lightBlue-400'
  | 'focus:tw-text-lightBlue-400'
  | 'sm:tw-text-lightBlue-500'
  | 'md:tw-text-lightBlue-500'
  | 'lg:tw-text-lightBlue-500'
  | 'xl:tw-text-lightBlue-500'
  | 'hover:tw-text-lightBlue-500'
  | 'focus:tw-text-lightBlue-500'
  | 'sm:tw-text-lightBlue-600'
  | 'md:tw-text-lightBlue-600'
  | 'lg:tw-text-lightBlue-600'
  | 'xl:tw-text-lightBlue-600'
  | 'hover:tw-text-lightBlue-600'
  | 'focus:tw-text-lightBlue-600'
  | 'sm:tw-text-lightBlue-700'
  | 'md:tw-text-lightBlue-700'
  | 'lg:tw-text-lightBlue-700'
  | 'xl:tw-text-lightBlue-700'
  | 'hover:tw-text-lightBlue-700'
  | 'focus:tw-text-lightBlue-700'
  | 'sm:tw-text-lightBlue-800'
  | 'md:tw-text-lightBlue-800'
  | 'lg:tw-text-lightBlue-800'
  | 'xl:tw-text-lightBlue-800'
  | 'hover:tw-text-lightBlue-800'
  | 'focus:tw-text-lightBlue-800'
  | 'sm:tw-text-lightBlue-900'
  | 'md:tw-text-lightBlue-900'
  | 'lg:tw-text-lightBlue-900'
  | 'xl:tw-text-lightBlue-900'
  | 'hover:tw-text-lightBlue-900'
  | 'focus:tw-text-lightBlue-900'
  | 'sm:tw-text-lightBlue-a100'
  | 'md:tw-text-lightBlue-a100'
  | 'lg:tw-text-lightBlue-a100'
  | 'xl:tw-text-lightBlue-a100'
  | 'hover:tw-text-lightBlue-a100'
  | 'focus:tw-text-lightBlue-a100'
  | 'sm:tw-text-lightBlue-a200'
  | 'md:tw-text-lightBlue-a200'
  | 'lg:tw-text-lightBlue-a200'
  | 'xl:tw-text-lightBlue-a200'
  | 'hover:tw-text-lightBlue-a200'
  | 'focus:tw-text-lightBlue-a200'
  | 'sm:tw-text-lightBlue-a400'
  | 'md:tw-text-lightBlue-a400'
  | 'lg:tw-text-lightBlue-a400'
  | 'xl:tw-text-lightBlue-a400'
  | 'hover:tw-text-lightBlue-a400'
  | 'focus:tw-text-lightBlue-a400'
  | 'sm:tw-text-lightBlue-a700'
  | 'md:tw-text-lightBlue-a700'
  | 'lg:tw-text-lightBlue-a700'
  | 'xl:tw-text-lightBlue-a700'
  | 'hover:tw-text-lightBlue-a700'
  | 'focus:tw-text-lightBlue-a700'
  | 'sm:tw-text-cyan-50'
  | 'md:tw-text-cyan-50'
  | 'lg:tw-text-cyan-50'
  | 'xl:tw-text-cyan-50'
  | 'hover:tw-text-cyan-50'
  | 'focus:tw-text-cyan-50'
  | 'sm:tw-text-cyan-100'
  | 'md:tw-text-cyan-100'
  | 'lg:tw-text-cyan-100'
  | 'xl:tw-text-cyan-100'
  | 'hover:tw-text-cyan-100'
  | 'focus:tw-text-cyan-100'
  | 'sm:tw-text-cyan-200'
  | 'md:tw-text-cyan-200'
  | 'lg:tw-text-cyan-200'
  | 'xl:tw-text-cyan-200'
  | 'hover:tw-text-cyan-200'
  | 'focus:tw-text-cyan-200'
  | 'sm:tw-text-cyan-300'
  | 'md:tw-text-cyan-300'
  | 'lg:tw-text-cyan-300'
  | 'xl:tw-text-cyan-300'
  | 'hover:tw-text-cyan-300'
  | 'focus:tw-text-cyan-300'
  | 'sm:tw-text-cyan-400'
  | 'md:tw-text-cyan-400'
  | 'lg:tw-text-cyan-400'
  | 'xl:tw-text-cyan-400'
  | 'hover:tw-text-cyan-400'
  | 'focus:tw-text-cyan-400'
  | 'sm:tw-text-cyan-500'
  | 'md:tw-text-cyan-500'
  | 'lg:tw-text-cyan-500'
  | 'xl:tw-text-cyan-500'
  | 'hover:tw-text-cyan-500'
  | 'focus:tw-text-cyan-500'
  | 'sm:tw-text-cyan-600'
  | 'md:tw-text-cyan-600'
  | 'lg:tw-text-cyan-600'
  | 'xl:tw-text-cyan-600'
  | 'hover:tw-text-cyan-600'
  | 'focus:tw-text-cyan-600'
  | 'sm:tw-text-cyan-700'
  | 'md:tw-text-cyan-700'
  | 'lg:tw-text-cyan-700'
  | 'xl:tw-text-cyan-700'
  | 'hover:tw-text-cyan-700'
  | 'focus:tw-text-cyan-700'
  | 'sm:tw-text-cyan-800'
  | 'md:tw-text-cyan-800'
  | 'lg:tw-text-cyan-800'
  | 'xl:tw-text-cyan-800'
  | 'hover:tw-text-cyan-800'
  | 'focus:tw-text-cyan-800'
  | 'sm:tw-text-cyan-900'
  | 'md:tw-text-cyan-900'
  | 'lg:tw-text-cyan-900'
  | 'xl:tw-text-cyan-900'
  | 'hover:tw-text-cyan-900'
  | 'focus:tw-text-cyan-900'
  | 'sm:tw-text-cyan-a100'
  | 'md:tw-text-cyan-a100'
  | 'lg:tw-text-cyan-a100'
  | 'xl:tw-text-cyan-a100'
  | 'hover:tw-text-cyan-a100'
  | 'focus:tw-text-cyan-a100'
  | 'sm:tw-text-cyan-a200'
  | 'md:tw-text-cyan-a200'
  | 'lg:tw-text-cyan-a200'
  | 'xl:tw-text-cyan-a200'
  | 'hover:tw-text-cyan-a200'
  | 'focus:tw-text-cyan-a200'
  | 'sm:tw-text-cyan-a400'
  | 'md:tw-text-cyan-a400'
  | 'lg:tw-text-cyan-a400'
  | 'xl:tw-text-cyan-a400'
  | 'hover:tw-text-cyan-a400'
  | 'focus:tw-text-cyan-a400'
  | 'sm:tw-text-cyan-a700'
  | 'md:tw-text-cyan-a700'
  | 'lg:tw-text-cyan-a700'
  | 'xl:tw-text-cyan-a700'
  | 'hover:tw-text-cyan-a700'
  | 'focus:tw-text-cyan-a700'
  | 'sm:tw-text-teal-50'
  | 'md:tw-text-teal-50'
  | 'lg:tw-text-teal-50'
  | 'xl:tw-text-teal-50'
  | 'hover:tw-text-teal-50'
  | 'focus:tw-text-teal-50'
  | 'sm:tw-text-teal-100'
  | 'md:tw-text-teal-100'
  | 'lg:tw-text-teal-100'
  | 'xl:tw-text-teal-100'
  | 'hover:tw-text-teal-100'
  | 'focus:tw-text-teal-100'
  | 'sm:tw-text-teal-200'
  | 'md:tw-text-teal-200'
  | 'lg:tw-text-teal-200'
  | 'xl:tw-text-teal-200'
  | 'hover:tw-text-teal-200'
  | 'focus:tw-text-teal-200'
  | 'sm:tw-text-teal-300'
  | 'md:tw-text-teal-300'
  | 'lg:tw-text-teal-300'
  | 'xl:tw-text-teal-300'
  | 'hover:tw-text-teal-300'
  | 'focus:tw-text-teal-300'
  | 'sm:tw-text-teal-400'
  | 'md:tw-text-teal-400'
  | 'lg:tw-text-teal-400'
  | 'xl:tw-text-teal-400'
  | 'hover:tw-text-teal-400'
  | 'focus:tw-text-teal-400'
  | 'sm:tw-text-teal-500'
  | 'md:tw-text-teal-500'
  | 'lg:tw-text-teal-500'
  | 'xl:tw-text-teal-500'
  | 'hover:tw-text-teal-500'
  | 'focus:tw-text-teal-500'
  | 'sm:tw-text-teal-600'
  | 'md:tw-text-teal-600'
  | 'lg:tw-text-teal-600'
  | 'xl:tw-text-teal-600'
  | 'hover:tw-text-teal-600'
  | 'focus:tw-text-teal-600'
  | 'sm:tw-text-teal-700'
  | 'md:tw-text-teal-700'
  | 'lg:tw-text-teal-700'
  | 'xl:tw-text-teal-700'
  | 'hover:tw-text-teal-700'
  | 'focus:tw-text-teal-700'
  | 'sm:tw-text-teal-800'
  | 'md:tw-text-teal-800'
  | 'lg:tw-text-teal-800'
  | 'xl:tw-text-teal-800'
  | 'hover:tw-text-teal-800'
  | 'focus:tw-text-teal-800'
  | 'sm:tw-text-teal-900'
  | 'md:tw-text-teal-900'
  | 'lg:tw-text-teal-900'
  | 'xl:tw-text-teal-900'
  | 'hover:tw-text-teal-900'
  | 'focus:tw-text-teal-900'
  | 'sm:tw-text-teal-a100'
  | 'md:tw-text-teal-a100'
  | 'lg:tw-text-teal-a100'
  | 'xl:tw-text-teal-a100'
  | 'hover:tw-text-teal-a100'
  | 'focus:tw-text-teal-a100'
  | 'sm:tw-text-teal-a200'
  | 'md:tw-text-teal-a200'
  | 'lg:tw-text-teal-a200'
  | 'xl:tw-text-teal-a200'
  | 'hover:tw-text-teal-a200'
  | 'focus:tw-text-teal-a200'
  | 'sm:tw-text-teal-a400'
  | 'md:tw-text-teal-a400'
  | 'lg:tw-text-teal-a400'
  | 'xl:tw-text-teal-a400'
  | 'hover:tw-text-teal-a400'
  | 'focus:tw-text-teal-a400'
  | 'sm:tw-text-teal-a700'
  | 'md:tw-text-teal-a700'
  | 'lg:tw-text-teal-a700'
  | 'xl:tw-text-teal-a700'
  | 'hover:tw-text-teal-a700'
  | 'focus:tw-text-teal-a700'
  | 'sm:tw-text-green-50'
  | 'md:tw-text-green-50'
  | 'lg:tw-text-green-50'
  | 'xl:tw-text-green-50'
  | 'hover:tw-text-green-50'
  | 'focus:tw-text-green-50'
  | 'sm:tw-text-green-100'
  | 'md:tw-text-green-100'
  | 'lg:tw-text-green-100'
  | 'xl:tw-text-green-100'
  | 'hover:tw-text-green-100'
  | 'focus:tw-text-green-100'
  | 'sm:tw-text-green-200'
  | 'md:tw-text-green-200'
  | 'lg:tw-text-green-200'
  | 'xl:tw-text-green-200'
  | 'hover:tw-text-green-200'
  | 'focus:tw-text-green-200'
  | 'sm:tw-text-green-300'
  | 'md:tw-text-green-300'
  | 'lg:tw-text-green-300'
  | 'xl:tw-text-green-300'
  | 'hover:tw-text-green-300'
  | 'focus:tw-text-green-300'
  | 'sm:tw-text-green-400'
  | 'md:tw-text-green-400'
  | 'lg:tw-text-green-400'
  | 'xl:tw-text-green-400'
  | 'hover:tw-text-green-400'
  | 'focus:tw-text-green-400'
  | 'sm:tw-text-green-500'
  | 'md:tw-text-green-500'
  | 'lg:tw-text-green-500'
  | 'xl:tw-text-green-500'
  | 'hover:tw-text-green-500'
  | 'focus:tw-text-green-500'
  | 'sm:tw-text-green-600'
  | 'md:tw-text-green-600'
  | 'lg:tw-text-green-600'
  | 'xl:tw-text-green-600'
  | 'hover:tw-text-green-600'
  | 'focus:tw-text-green-600'
  | 'sm:tw-text-green-700'
  | 'md:tw-text-green-700'
  | 'lg:tw-text-green-700'
  | 'xl:tw-text-green-700'
  | 'hover:tw-text-green-700'
  | 'focus:tw-text-green-700'
  | 'sm:tw-text-green-800'
  | 'md:tw-text-green-800'
  | 'lg:tw-text-green-800'
  | 'xl:tw-text-green-800'
  | 'hover:tw-text-green-800'
  | 'focus:tw-text-green-800'
  | 'sm:tw-text-green-900'
  | 'md:tw-text-green-900'
  | 'lg:tw-text-green-900'
  | 'xl:tw-text-green-900'
  | 'hover:tw-text-green-900'
  | 'focus:tw-text-green-900'
  | 'sm:tw-text-green-a100'
  | 'md:tw-text-green-a100'
  | 'lg:tw-text-green-a100'
  | 'xl:tw-text-green-a100'
  | 'hover:tw-text-green-a100'
  | 'focus:tw-text-green-a100'
  | 'sm:tw-text-green-a200'
  | 'md:tw-text-green-a200'
  | 'lg:tw-text-green-a200'
  | 'xl:tw-text-green-a200'
  | 'hover:tw-text-green-a200'
  | 'focus:tw-text-green-a200'
  | 'sm:tw-text-green-a400'
  | 'md:tw-text-green-a400'
  | 'lg:tw-text-green-a400'
  | 'xl:tw-text-green-a400'
  | 'hover:tw-text-green-a400'
  | 'focus:tw-text-green-a400'
  | 'sm:tw-text-green-a700'
  | 'md:tw-text-green-a700'
  | 'lg:tw-text-green-a700'
  | 'xl:tw-text-green-a700'
  | 'hover:tw-text-green-a700'
  | 'focus:tw-text-green-a700'
  | 'sm:tw-text-lightGreen-50'
  | 'md:tw-text-lightGreen-50'
  | 'lg:tw-text-lightGreen-50'
  | 'xl:tw-text-lightGreen-50'
  | 'hover:tw-text-lightGreen-50'
  | 'focus:tw-text-lightGreen-50'
  | 'sm:tw-text-lightGreen-100'
  | 'md:tw-text-lightGreen-100'
  | 'lg:tw-text-lightGreen-100'
  | 'xl:tw-text-lightGreen-100'
  | 'hover:tw-text-lightGreen-100'
  | 'focus:tw-text-lightGreen-100'
  | 'sm:tw-text-lightGreen-200'
  | 'md:tw-text-lightGreen-200'
  | 'lg:tw-text-lightGreen-200'
  | 'xl:tw-text-lightGreen-200'
  | 'hover:tw-text-lightGreen-200'
  | 'focus:tw-text-lightGreen-200'
  | 'sm:tw-text-lightGreen-300'
  | 'md:tw-text-lightGreen-300'
  | 'lg:tw-text-lightGreen-300'
  | 'xl:tw-text-lightGreen-300'
  | 'hover:tw-text-lightGreen-300'
  | 'focus:tw-text-lightGreen-300'
  | 'sm:tw-text-lightGreen-400'
  | 'md:tw-text-lightGreen-400'
  | 'lg:tw-text-lightGreen-400'
  | 'xl:tw-text-lightGreen-400'
  | 'hover:tw-text-lightGreen-400'
  | 'focus:tw-text-lightGreen-400'
  | 'sm:tw-text-lightGreen-500'
  | 'md:tw-text-lightGreen-500'
  | 'lg:tw-text-lightGreen-500'
  | 'xl:tw-text-lightGreen-500'
  | 'hover:tw-text-lightGreen-500'
  | 'focus:tw-text-lightGreen-500'
  | 'sm:tw-text-lightGreen-600'
  | 'md:tw-text-lightGreen-600'
  | 'lg:tw-text-lightGreen-600'
  | 'xl:tw-text-lightGreen-600'
  | 'hover:tw-text-lightGreen-600'
  | 'focus:tw-text-lightGreen-600'
  | 'sm:tw-text-lightGreen-700'
  | 'md:tw-text-lightGreen-700'
  | 'lg:tw-text-lightGreen-700'
  | 'xl:tw-text-lightGreen-700'
  | 'hover:tw-text-lightGreen-700'
  | 'focus:tw-text-lightGreen-700'
  | 'sm:tw-text-lightGreen-800'
  | 'md:tw-text-lightGreen-800'
  | 'lg:tw-text-lightGreen-800'
  | 'xl:tw-text-lightGreen-800'
  | 'hover:tw-text-lightGreen-800'
  | 'focus:tw-text-lightGreen-800'
  | 'sm:tw-text-lightGreen-900'
  | 'md:tw-text-lightGreen-900'
  | 'lg:tw-text-lightGreen-900'
  | 'xl:tw-text-lightGreen-900'
  | 'hover:tw-text-lightGreen-900'
  | 'focus:tw-text-lightGreen-900'
  | 'sm:tw-text-lightGreen-a100'
  | 'md:tw-text-lightGreen-a100'
  | 'lg:tw-text-lightGreen-a100'
  | 'xl:tw-text-lightGreen-a100'
  | 'hover:tw-text-lightGreen-a100'
  | 'focus:tw-text-lightGreen-a100'
  | 'sm:tw-text-lightGreen-a200'
  | 'md:tw-text-lightGreen-a200'
  | 'lg:tw-text-lightGreen-a200'
  | 'xl:tw-text-lightGreen-a200'
  | 'hover:tw-text-lightGreen-a200'
  | 'focus:tw-text-lightGreen-a200'
  | 'sm:tw-text-lightGreen-a400'
  | 'md:tw-text-lightGreen-a400'
  | 'lg:tw-text-lightGreen-a400'
  | 'xl:tw-text-lightGreen-a400'
  | 'hover:tw-text-lightGreen-a400'
  | 'focus:tw-text-lightGreen-a400'
  | 'sm:tw-text-lightGreen-a700'
  | 'md:tw-text-lightGreen-a700'
  | 'lg:tw-text-lightGreen-a700'
  | 'xl:tw-text-lightGreen-a700'
  | 'hover:tw-text-lightGreen-a700'
  | 'focus:tw-text-lightGreen-a700'
  | 'sm:tw-text-lime-50'
  | 'md:tw-text-lime-50'
  | 'lg:tw-text-lime-50'
  | 'xl:tw-text-lime-50'
  | 'hover:tw-text-lime-50'
  | 'focus:tw-text-lime-50'
  | 'sm:tw-text-lime-100'
  | 'md:tw-text-lime-100'
  | 'lg:tw-text-lime-100'
  | 'xl:tw-text-lime-100'
  | 'hover:tw-text-lime-100'
  | 'focus:tw-text-lime-100'
  | 'sm:tw-text-lime-200'
  | 'md:tw-text-lime-200'
  | 'lg:tw-text-lime-200'
  | 'xl:tw-text-lime-200'
  | 'hover:tw-text-lime-200'
  | 'focus:tw-text-lime-200'
  | 'sm:tw-text-lime-300'
  | 'md:tw-text-lime-300'
  | 'lg:tw-text-lime-300'
  | 'xl:tw-text-lime-300'
  | 'hover:tw-text-lime-300'
  | 'focus:tw-text-lime-300'
  | 'sm:tw-text-lime-400'
  | 'md:tw-text-lime-400'
  | 'lg:tw-text-lime-400'
  | 'xl:tw-text-lime-400'
  | 'hover:tw-text-lime-400'
  | 'focus:tw-text-lime-400'
  | 'sm:tw-text-lime-500'
  | 'md:tw-text-lime-500'
  | 'lg:tw-text-lime-500'
  | 'xl:tw-text-lime-500'
  | 'hover:tw-text-lime-500'
  | 'focus:tw-text-lime-500'
  | 'sm:tw-text-lime-600'
  | 'md:tw-text-lime-600'
  | 'lg:tw-text-lime-600'
  | 'xl:tw-text-lime-600'
  | 'hover:tw-text-lime-600'
  | 'focus:tw-text-lime-600'
  | 'sm:tw-text-lime-700'
  | 'md:tw-text-lime-700'
  | 'lg:tw-text-lime-700'
  | 'xl:tw-text-lime-700'
  | 'hover:tw-text-lime-700'
  | 'focus:tw-text-lime-700'
  | 'sm:tw-text-lime-800'
  | 'md:tw-text-lime-800'
  | 'lg:tw-text-lime-800'
  | 'xl:tw-text-lime-800'
  | 'hover:tw-text-lime-800'
  | 'focus:tw-text-lime-800'
  | 'sm:tw-text-lime-900'
  | 'md:tw-text-lime-900'
  | 'lg:tw-text-lime-900'
  | 'xl:tw-text-lime-900'
  | 'hover:tw-text-lime-900'
  | 'focus:tw-text-lime-900'
  | 'sm:tw-text-lime-a100'
  | 'md:tw-text-lime-a100'
  | 'lg:tw-text-lime-a100'
  | 'xl:tw-text-lime-a100'
  | 'hover:tw-text-lime-a100'
  | 'focus:tw-text-lime-a100'
  | 'sm:tw-text-lime-a200'
  | 'md:tw-text-lime-a200'
  | 'lg:tw-text-lime-a200'
  | 'xl:tw-text-lime-a200'
  | 'hover:tw-text-lime-a200'
  | 'focus:tw-text-lime-a200'
  | 'sm:tw-text-lime-a400'
  | 'md:tw-text-lime-a400'
  | 'lg:tw-text-lime-a400'
  | 'xl:tw-text-lime-a400'
  | 'hover:tw-text-lime-a400'
  | 'focus:tw-text-lime-a400'
  | 'sm:tw-text-lime-a700'
  | 'md:tw-text-lime-a700'
  | 'lg:tw-text-lime-a700'
  | 'xl:tw-text-lime-a700'
  | 'hover:tw-text-lime-a700'
  | 'focus:tw-text-lime-a700'
  | 'sm:tw-text-yellow-50'
  | 'md:tw-text-yellow-50'
  | 'lg:tw-text-yellow-50'
  | 'xl:tw-text-yellow-50'
  | 'hover:tw-text-yellow-50'
  | 'focus:tw-text-yellow-50'
  | 'sm:tw-text-yellow-100'
  | 'md:tw-text-yellow-100'
  | 'lg:tw-text-yellow-100'
  | 'xl:tw-text-yellow-100'
  | 'hover:tw-text-yellow-100'
  | 'focus:tw-text-yellow-100'
  | 'sm:tw-text-yellow-200'
  | 'md:tw-text-yellow-200'
  | 'lg:tw-text-yellow-200'
  | 'xl:tw-text-yellow-200'
  | 'hover:tw-text-yellow-200'
  | 'focus:tw-text-yellow-200'
  | 'sm:tw-text-yellow-300'
  | 'md:tw-text-yellow-300'
  | 'lg:tw-text-yellow-300'
  | 'xl:tw-text-yellow-300'
  | 'hover:tw-text-yellow-300'
  | 'focus:tw-text-yellow-300'
  | 'sm:tw-text-yellow-400'
  | 'md:tw-text-yellow-400'
  | 'lg:tw-text-yellow-400'
  | 'xl:tw-text-yellow-400'
  | 'hover:tw-text-yellow-400'
  | 'focus:tw-text-yellow-400'
  | 'sm:tw-text-yellow-500'
  | 'md:tw-text-yellow-500'
  | 'lg:tw-text-yellow-500'
  | 'xl:tw-text-yellow-500'
  | 'hover:tw-text-yellow-500'
  | 'focus:tw-text-yellow-500'
  | 'sm:tw-text-yellow-600'
  | 'md:tw-text-yellow-600'
  | 'lg:tw-text-yellow-600'
  | 'xl:tw-text-yellow-600'
  | 'hover:tw-text-yellow-600'
  | 'focus:tw-text-yellow-600'
  | 'sm:tw-text-yellow-700'
  | 'md:tw-text-yellow-700'
  | 'lg:tw-text-yellow-700'
  | 'xl:tw-text-yellow-700'
  | 'hover:tw-text-yellow-700'
  | 'focus:tw-text-yellow-700'
  | 'sm:tw-text-yellow-800'
  | 'md:tw-text-yellow-800'
  | 'lg:tw-text-yellow-800'
  | 'xl:tw-text-yellow-800'
  | 'hover:tw-text-yellow-800'
  | 'focus:tw-text-yellow-800'
  | 'sm:tw-text-yellow-900'
  | 'md:tw-text-yellow-900'
  | 'lg:tw-text-yellow-900'
  | 'xl:tw-text-yellow-900'
  | 'hover:tw-text-yellow-900'
  | 'focus:tw-text-yellow-900'
  | 'sm:tw-text-yellow-a100'
  | 'md:tw-text-yellow-a100'
  | 'lg:tw-text-yellow-a100'
  | 'xl:tw-text-yellow-a100'
  | 'hover:tw-text-yellow-a100'
  | 'focus:tw-text-yellow-a100'
  | 'sm:tw-text-yellow-a200'
  | 'md:tw-text-yellow-a200'
  | 'lg:tw-text-yellow-a200'
  | 'xl:tw-text-yellow-a200'
  | 'hover:tw-text-yellow-a200'
  | 'focus:tw-text-yellow-a200'
  | 'sm:tw-text-yellow-a400'
  | 'md:tw-text-yellow-a400'
  | 'lg:tw-text-yellow-a400'
  | 'xl:tw-text-yellow-a400'
  | 'hover:tw-text-yellow-a400'
  | 'focus:tw-text-yellow-a400'
  | 'sm:tw-text-yellow-a700'
  | 'md:tw-text-yellow-a700'
  | 'lg:tw-text-yellow-a700'
  | 'xl:tw-text-yellow-a700'
  | 'hover:tw-text-yellow-a700'
  | 'focus:tw-text-yellow-a700'
  | 'sm:tw-text-amber-50'
  | 'md:tw-text-amber-50'
  | 'lg:tw-text-amber-50'
  | 'xl:tw-text-amber-50'
  | 'hover:tw-text-amber-50'
  | 'focus:tw-text-amber-50'
  | 'sm:tw-text-amber-100'
  | 'md:tw-text-amber-100'
  | 'lg:tw-text-amber-100'
  | 'xl:tw-text-amber-100'
  | 'hover:tw-text-amber-100'
  | 'focus:tw-text-amber-100'
  | 'sm:tw-text-amber-200'
  | 'md:tw-text-amber-200'
  | 'lg:tw-text-amber-200'
  | 'xl:tw-text-amber-200'
  | 'hover:tw-text-amber-200'
  | 'focus:tw-text-amber-200'
  | 'sm:tw-text-amber-300'
  | 'md:tw-text-amber-300'
  | 'lg:tw-text-amber-300'
  | 'xl:tw-text-amber-300'
  | 'hover:tw-text-amber-300'
  | 'focus:tw-text-amber-300'
  | 'sm:tw-text-amber-400'
  | 'md:tw-text-amber-400'
  | 'lg:tw-text-amber-400'
  | 'xl:tw-text-amber-400'
  | 'hover:tw-text-amber-400'
  | 'focus:tw-text-amber-400'
  | 'sm:tw-text-amber-500'
  | 'md:tw-text-amber-500'
  | 'lg:tw-text-amber-500'
  | 'xl:tw-text-amber-500'
  | 'hover:tw-text-amber-500'
  | 'focus:tw-text-amber-500'
  | 'sm:tw-text-amber-600'
  | 'md:tw-text-amber-600'
  | 'lg:tw-text-amber-600'
  | 'xl:tw-text-amber-600'
  | 'hover:tw-text-amber-600'
  | 'focus:tw-text-amber-600'
  | 'sm:tw-text-amber-700'
  | 'md:tw-text-amber-700'
  | 'lg:tw-text-amber-700'
  | 'xl:tw-text-amber-700'
  | 'hover:tw-text-amber-700'
  | 'focus:tw-text-amber-700'
  | 'sm:tw-text-amber-800'
  | 'md:tw-text-amber-800'
  | 'lg:tw-text-amber-800'
  | 'xl:tw-text-amber-800'
  | 'hover:tw-text-amber-800'
  | 'focus:tw-text-amber-800'
  | 'sm:tw-text-amber-900'
  | 'md:tw-text-amber-900'
  | 'lg:tw-text-amber-900'
  | 'xl:tw-text-amber-900'
  | 'hover:tw-text-amber-900'
  | 'focus:tw-text-amber-900'
  | 'sm:tw-text-amber-a100'
  | 'md:tw-text-amber-a100'
  | 'lg:tw-text-amber-a100'
  | 'xl:tw-text-amber-a100'
  | 'hover:tw-text-amber-a100'
  | 'focus:tw-text-amber-a100'
  | 'sm:tw-text-amber-a200'
  | 'md:tw-text-amber-a200'
  | 'lg:tw-text-amber-a200'
  | 'xl:tw-text-amber-a200'
  | 'hover:tw-text-amber-a200'
  | 'focus:tw-text-amber-a200'
  | 'sm:tw-text-amber-a400'
  | 'md:tw-text-amber-a400'
  | 'lg:tw-text-amber-a400'
  | 'xl:tw-text-amber-a400'
  | 'hover:tw-text-amber-a400'
  | 'focus:tw-text-amber-a400'
  | 'sm:tw-text-amber-a700'
  | 'md:tw-text-amber-a700'
  | 'lg:tw-text-amber-a700'
  | 'xl:tw-text-amber-a700'
  | 'hover:tw-text-amber-a700'
  | 'focus:tw-text-amber-a700'
  | 'sm:tw-text-orange-50'
  | 'md:tw-text-orange-50'
  | 'lg:tw-text-orange-50'
  | 'xl:tw-text-orange-50'
  | 'hover:tw-text-orange-50'
  | 'focus:tw-text-orange-50'
  | 'sm:tw-text-orange-100'
  | 'md:tw-text-orange-100'
  | 'lg:tw-text-orange-100'
  | 'xl:tw-text-orange-100'
  | 'hover:tw-text-orange-100'
  | 'focus:tw-text-orange-100'
  | 'sm:tw-text-orange-200'
  | 'md:tw-text-orange-200'
  | 'lg:tw-text-orange-200'
  | 'xl:tw-text-orange-200'
  | 'hover:tw-text-orange-200'
  | 'focus:tw-text-orange-200'
  | 'sm:tw-text-orange-300'
  | 'md:tw-text-orange-300'
  | 'lg:tw-text-orange-300'
  | 'xl:tw-text-orange-300'
  | 'hover:tw-text-orange-300'
  | 'focus:tw-text-orange-300'
  | 'sm:tw-text-orange-400'
  | 'md:tw-text-orange-400'
  | 'lg:tw-text-orange-400'
  | 'xl:tw-text-orange-400'
  | 'hover:tw-text-orange-400'
  | 'focus:tw-text-orange-400'
  | 'sm:tw-text-orange-500'
  | 'md:tw-text-orange-500'
  | 'lg:tw-text-orange-500'
  | 'xl:tw-text-orange-500'
  | 'hover:tw-text-orange-500'
  | 'focus:tw-text-orange-500'
  | 'sm:tw-text-orange-600'
  | 'md:tw-text-orange-600'
  | 'lg:tw-text-orange-600'
  | 'xl:tw-text-orange-600'
  | 'hover:tw-text-orange-600'
  | 'focus:tw-text-orange-600'
  | 'sm:tw-text-orange-700'
  | 'md:tw-text-orange-700'
  | 'lg:tw-text-orange-700'
  | 'xl:tw-text-orange-700'
  | 'hover:tw-text-orange-700'
  | 'focus:tw-text-orange-700'
  | 'sm:tw-text-orange-800'
  | 'md:tw-text-orange-800'
  | 'lg:tw-text-orange-800'
  | 'xl:tw-text-orange-800'
  | 'hover:tw-text-orange-800'
  | 'focus:tw-text-orange-800'
  | 'sm:tw-text-orange-900'
  | 'md:tw-text-orange-900'
  | 'lg:tw-text-orange-900'
  | 'xl:tw-text-orange-900'
  | 'hover:tw-text-orange-900'
  | 'focus:tw-text-orange-900'
  | 'sm:tw-text-orange-a100'
  | 'md:tw-text-orange-a100'
  | 'lg:tw-text-orange-a100'
  | 'xl:tw-text-orange-a100'
  | 'hover:tw-text-orange-a100'
  | 'focus:tw-text-orange-a100'
  | 'sm:tw-text-orange-a200'
  | 'md:tw-text-orange-a200'
  | 'lg:tw-text-orange-a200'
  | 'xl:tw-text-orange-a200'
  | 'hover:tw-text-orange-a200'
  | 'focus:tw-text-orange-a200'
  | 'sm:tw-text-orange-a400'
  | 'md:tw-text-orange-a400'
  | 'lg:tw-text-orange-a400'
  | 'xl:tw-text-orange-a400'
  | 'hover:tw-text-orange-a400'
  | 'focus:tw-text-orange-a400'
  | 'sm:tw-text-orange-a700'
  | 'md:tw-text-orange-a700'
  | 'lg:tw-text-orange-a700'
  | 'xl:tw-text-orange-a700'
  | 'hover:tw-text-orange-a700'
  | 'focus:tw-text-orange-a700'
  | 'sm:tw-text-deepOrange-50'
  | 'md:tw-text-deepOrange-50'
  | 'lg:tw-text-deepOrange-50'
  | 'xl:tw-text-deepOrange-50'
  | 'hover:tw-text-deepOrange-50'
  | 'focus:tw-text-deepOrange-50'
  | 'sm:tw-text-deepOrange-100'
  | 'md:tw-text-deepOrange-100'
  | 'lg:tw-text-deepOrange-100'
  | 'xl:tw-text-deepOrange-100'
  | 'hover:tw-text-deepOrange-100'
  | 'focus:tw-text-deepOrange-100'
  | 'sm:tw-text-deepOrange-200'
  | 'md:tw-text-deepOrange-200'
  | 'lg:tw-text-deepOrange-200'
  | 'xl:tw-text-deepOrange-200'
  | 'hover:tw-text-deepOrange-200'
  | 'focus:tw-text-deepOrange-200'
  | 'sm:tw-text-deepOrange-300'
  | 'md:tw-text-deepOrange-300'
  | 'lg:tw-text-deepOrange-300'
  | 'xl:tw-text-deepOrange-300'
  | 'hover:tw-text-deepOrange-300'
  | 'focus:tw-text-deepOrange-300'
  | 'sm:tw-text-deepOrange-400'
  | 'md:tw-text-deepOrange-400'
  | 'lg:tw-text-deepOrange-400'
  | 'xl:tw-text-deepOrange-400'
  | 'hover:tw-text-deepOrange-400'
  | 'focus:tw-text-deepOrange-400'
  | 'sm:tw-text-deepOrange-500'
  | 'md:tw-text-deepOrange-500'
  | 'lg:tw-text-deepOrange-500'
  | 'xl:tw-text-deepOrange-500'
  | 'hover:tw-text-deepOrange-500'
  | 'focus:tw-text-deepOrange-500'
  | 'sm:tw-text-deepOrange-600'
  | 'md:tw-text-deepOrange-600'
  | 'lg:tw-text-deepOrange-600'
  | 'xl:tw-text-deepOrange-600'
  | 'hover:tw-text-deepOrange-600'
  | 'focus:tw-text-deepOrange-600'
  | 'sm:tw-text-deepOrange-700'
  | 'md:tw-text-deepOrange-700'
  | 'lg:tw-text-deepOrange-700'
  | 'xl:tw-text-deepOrange-700'
  | 'hover:tw-text-deepOrange-700'
  | 'focus:tw-text-deepOrange-700'
  | 'sm:tw-text-deepOrange-800'
  | 'md:tw-text-deepOrange-800'
  | 'lg:tw-text-deepOrange-800'
  | 'xl:tw-text-deepOrange-800'
  | 'hover:tw-text-deepOrange-800'
  | 'focus:tw-text-deepOrange-800'
  | 'sm:tw-text-deepOrange-900'
  | 'md:tw-text-deepOrange-900'
  | 'lg:tw-text-deepOrange-900'
  | 'xl:tw-text-deepOrange-900'
  | 'hover:tw-text-deepOrange-900'
  | 'focus:tw-text-deepOrange-900'
  | 'sm:tw-text-deepOrange-a100'
  | 'md:tw-text-deepOrange-a100'
  | 'lg:tw-text-deepOrange-a100'
  | 'xl:tw-text-deepOrange-a100'
  | 'hover:tw-text-deepOrange-a100'
  | 'focus:tw-text-deepOrange-a100'
  | 'sm:tw-text-deepOrange-a200'
  | 'md:tw-text-deepOrange-a200'
  | 'lg:tw-text-deepOrange-a200'
  | 'xl:tw-text-deepOrange-a200'
  | 'hover:tw-text-deepOrange-a200'
  | 'focus:tw-text-deepOrange-a200'
  | 'sm:tw-text-deepOrange-a400'
  | 'md:tw-text-deepOrange-a400'
  | 'lg:tw-text-deepOrange-a400'
  | 'xl:tw-text-deepOrange-a400'
  | 'hover:tw-text-deepOrange-a400'
  | 'focus:tw-text-deepOrange-a400'
  | 'sm:tw-text-deepOrange-a700'
  | 'md:tw-text-deepOrange-a700'
  | 'lg:tw-text-deepOrange-a700'
  | 'xl:tw-text-deepOrange-a700'
  | 'hover:tw-text-deepOrange-a700'
  | 'focus:tw-text-deepOrange-a700'
  | 'sm:tw-text-brown-50'
  | 'md:tw-text-brown-50'
  | 'lg:tw-text-brown-50'
  | 'xl:tw-text-brown-50'
  | 'hover:tw-text-brown-50'
  | 'focus:tw-text-brown-50'
  | 'sm:tw-text-brown-100'
  | 'md:tw-text-brown-100'
  | 'lg:tw-text-brown-100'
  | 'xl:tw-text-brown-100'
  | 'hover:tw-text-brown-100'
  | 'focus:tw-text-brown-100'
  | 'sm:tw-text-brown-200'
  | 'md:tw-text-brown-200'
  | 'lg:tw-text-brown-200'
  | 'xl:tw-text-brown-200'
  | 'hover:tw-text-brown-200'
  | 'focus:tw-text-brown-200'
  | 'sm:tw-text-brown-300'
  | 'md:tw-text-brown-300'
  | 'lg:tw-text-brown-300'
  | 'xl:tw-text-brown-300'
  | 'hover:tw-text-brown-300'
  | 'focus:tw-text-brown-300'
  | 'sm:tw-text-brown-400'
  | 'md:tw-text-brown-400'
  | 'lg:tw-text-brown-400'
  | 'xl:tw-text-brown-400'
  | 'hover:tw-text-brown-400'
  | 'focus:tw-text-brown-400'
  | 'sm:tw-text-brown-500'
  | 'md:tw-text-brown-500'
  | 'lg:tw-text-brown-500'
  | 'xl:tw-text-brown-500'
  | 'hover:tw-text-brown-500'
  | 'focus:tw-text-brown-500'
  | 'sm:tw-text-brown-600'
  | 'md:tw-text-brown-600'
  | 'lg:tw-text-brown-600'
  | 'xl:tw-text-brown-600'
  | 'hover:tw-text-brown-600'
  | 'focus:tw-text-brown-600'
  | 'sm:tw-text-brown-700'
  | 'md:tw-text-brown-700'
  | 'lg:tw-text-brown-700'
  | 'xl:tw-text-brown-700'
  | 'hover:tw-text-brown-700'
  | 'focus:tw-text-brown-700'
  | 'sm:tw-text-brown-800'
  | 'md:tw-text-brown-800'
  | 'lg:tw-text-brown-800'
  | 'xl:tw-text-brown-800'
  | 'hover:tw-text-brown-800'
  | 'focus:tw-text-brown-800'
  | 'sm:tw-text-brown-900'
  | 'md:tw-text-brown-900'
  | 'lg:tw-text-brown-900'
  | 'xl:tw-text-brown-900'
  | 'hover:tw-text-brown-900'
  | 'focus:tw-text-brown-900'
  | 'sm:tw-text-gray-50'
  | 'md:tw-text-gray-50'
  | 'lg:tw-text-gray-50'
  | 'xl:tw-text-gray-50'
  | 'hover:tw-text-gray-50'
  | 'focus:tw-text-gray-50'
  | 'sm:tw-text-gray-100'
  | 'md:tw-text-gray-100'
  | 'lg:tw-text-gray-100'
  | 'xl:tw-text-gray-100'
  | 'hover:tw-text-gray-100'
  | 'focus:tw-text-gray-100'
  | 'sm:tw-text-gray-200'
  | 'md:tw-text-gray-200'
  | 'lg:tw-text-gray-200'
  | 'xl:tw-text-gray-200'
  | 'hover:tw-text-gray-200'
  | 'focus:tw-text-gray-200'
  | 'sm:tw-text-gray-300'
  | 'md:tw-text-gray-300'
  | 'lg:tw-text-gray-300'
  | 'xl:tw-text-gray-300'
  | 'hover:tw-text-gray-300'
  | 'focus:tw-text-gray-300'
  | 'sm:tw-text-gray-400'
  | 'md:tw-text-gray-400'
  | 'lg:tw-text-gray-400'
  | 'xl:tw-text-gray-400'
  | 'hover:tw-text-gray-400'
  | 'focus:tw-text-gray-400'
  | 'sm:tw-text-gray-500'
  | 'md:tw-text-gray-500'
  | 'lg:tw-text-gray-500'
  | 'xl:tw-text-gray-500'
  | 'hover:tw-text-gray-500'
  | 'focus:tw-text-gray-500'
  | 'sm:tw-text-gray-600'
  | 'md:tw-text-gray-600'
  | 'lg:tw-text-gray-600'
  | 'xl:tw-text-gray-600'
  | 'hover:tw-text-gray-600'
  | 'focus:tw-text-gray-600'
  | 'sm:tw-text-gray-700'
  | 'md:tw-text-gray-700'
  | 'lg:tw-text-gray-700'
  | 'xl:tw-text-gray-700'
  | 'hover:tw-text-gray-700'
  | 'focus:tw-text-gray-700'
  | 'sm:tw-text-gray-800'
  | 'md:tw-text-gray-800'
  | 'lg:tw-text-gray-800'
  | 'xl:tw-text-gray-800'
  | 'hover:tw-text-gray-800'
  | 'focus:tw-text-gray-800'
  | 'sm:tw-text-gray-900'
  | 'md:tw-text-gray-900'
  | 'lg:tw-text-gray-900'
  | 'xl:tw-text-gray-900'
  | 'hover:tw-text-gray-900'
  | 'focus:tw-text-gray-900'
  | 'sm:tw-text-blueGray-50'
  | 'md:tw-text-blueGray-50'
  | 'lg:tw-text-blueGray-50'
  | 'xl:tw-text-blueGray-50'
  | 'hover:tw-text-blueGray-50'
  | 'focus:tw-text-blueGray-50'
  | 'sm:tw-text-blueGray-100'
  | 'md:tw-text-blueGray-100'
  | 'lg:tw-text-blueGray-100'
  | 'xl:tw-text-blueGray-100'
  | 'hover:tw-text-blueGray-100'
  | 'focus:tw-text-blueGray-100'
  | 'sm:tw-text-blueGray-200'
  | 'md:tw-text-blueGray-200'
  | 'lg:tw-text-blueGray-200'
  | 'xl:tw-text-blueGray-200'
  | 'hover:tw-text-blueGray-200'
  | 'focus:tw-text-blueGray-200'
  | 'sm:tw-text-blueGray-300'
  | 'md:tw-text-blueGray-300'
  | 'lg:tw-text-blueGray-300'
  | 'xl:tw-text-blueGray-300'
  | 'hover:tw-text-blueGray-300'
  | 'focus:tw-text-blueGray-300'
  | 'sm:tw-text-blueGray-400'
  | 'md:tw-text-blueGray-400'
  | 'lg:tw-text-blueGray-400'
  | 'xl:tw-text-blueGray-400'
  | 'hover:tw-text-blueGray-400'
  | 'focus:tw-text-blueGray-400'
  | 'sm:tw-text-blueGray-500'
  | 'md:tw-text-blueGray-500'
  | 'lg:tw-text-blueGray-500'
  | 'xl:tw-text-blueGray-500'
  | 'hover:tw-text-blueGray-500'
  | 'focus:tw-text-blueGray-500'
  | 'sm:tw-text-blueGray-600'
  | 'md:tw-text-blueGray-600'
  | 'lg:tw-text-blueGray-600'
  | 'xl:tw-text-blueGray-600'
  | 'hover:tw-text-blueGray-600'
  | 'focus:tw-text-blueGray-600'
  | 'sm:tw-text-blueGray-700'
  | 'md:tw-text-blueGray-700'
  | 'lg:tw-text-blueGray-700'
  | 'xl:tw-text-blueGray-700'
  | 'hover:tw-text-blueGray-700'
  | 'focus:tw-text-blueGray-700'
  | 'sm:tw-text-blueGray-800'
  | 'md:tw-text-blueGray-800'
  | 'lg:tw-text-blueGray-800'
  | 'xl:tw-text-blueGray-800'
  | 'hover:tw-text-blueGray-800'
  | 'focus:tw-text-blueGray-800'
  | 'sm:tw-text-blueGray-900'
  | 'md:tw-text-blueGray-900'
  | 'lg:tw-text-blueGray-900'
  | 'xl:tw-text-blueGray-900'
  | 'hover:tw-text-blueGray-900'
  | 'focus:tw-text-blueGray-900'
  | 'sm:tw-text-black'
  | 'md:tw-text-black'
  | 'lg:tw-text-black'
  | 'xl:tw-text-black'
  | 'hover:tw-text-black'
  | 'focus:tw-text-black'
  | 'sm:tw-text-white'
  | 'md:tw-text-white'
  | 'lg:tw-text-white'
  | 'xl:tw-text-white'
  | 'hover:tw-text-white'
  | 'focus:tw-text-white'
  | 'sm:tw-text-transparent'
  | 'md:tw-text-transparent'
  | 'lg:tw-text-transparent'
  | 'xl:tw-text-transparent'
  | 'hover:tw-text-transparent'
  | 'focus:tw-text-transparent'
  | 'sm:tw-text-opacity-0'
  | 'md:tw-text-opacity-0'
  | 'lg:tw-text-opacity-0'
  | 'xl:tw-text-opacity-0'
  | 'hover:tw-text-opacity-0'
  | 'focus:tw-text-opacity-0'
  | 'sm:tw-text-opacity-25'
  | 'md:tw-text-opacity-25'
  | 'lg:tw-text-opacity-25'
  | 'xl:tw-text-opacity-25'
  | 'hover:tw-text-opacity-25'
  | 'focus:tw-text-opacity-25'
  | 'sm:tw-text-opacity-50'
  | 'md:tw-text-opacity-50'
  | 'lg:tw-text-opacity-50'
  | 'xl:tw-text-opacity-50'
  | 'hover:tw-text-opacity-50'
  | 'focus:tw-text-opacity-50'
  | 'sm:tw-text-opacity-75'
  | 'md:tw-text-opacity-75'
  | 'lg:tw-text-opacity-75'
  | 'xl:tw-text-opacity-75'
  | 'hover:tw-text-opacity-75'
  | 'focus:tw-text-opacity-75'
  | 'sm:tw-text-opacity-100'
  | 'md:tw-text-opacity-100'
  | 'lg:tw-text-opacity-100'
  | 'xl:tw-text-opacity-100'
  | 'hover:tw-text-opacity-100'
  | 'focus:tw-text-opacity-100'
  | 'sm:tw-underline'
  | 'md:tw-underline'
  | 'lg:tw-underline'
  | 'xl:tw-underline'
  | 'hover:tw-underline'
  | 'focus:tw-underline'
  | 'sm:tw-line-through'
  | 'md:tw-line-through'
  | 'lg:tw-line-through'
  | 'xl:tw-line-through'
  | 'hover:tw-line-through'
  | 'focus:tw-line-through'
  | 'sm:tw-no-underline'
  | 'md:tw-no-underline'
  | 'lg:tw-no-underline'
  | 'xl:tw-no-underline'
  | 'hover:tw-no-underline'
  | 'focus:tw-no-underline'
  | 'sm:tw-uppercase'
  | 'md:tw-uppercase'
  | 'lg:tw-uppercase'
  | 'xl:tw-uppercase'
  | 'sm:tw-lowercase'
  | 'md:tw-lowercase'
  | 'lg:tw-lowercase'
  | 'xl:tw-lowercase'
  | 'sm:tw-capitalize'
  | 'md:tw-capitalize'
  | 'lg:tw-capitalize'
  | 'xl:tw-capitalize'
  | 'sm:tw-normal-case'
  | 'md:tw-normal-case'
  | 'lg:tw-normal-case'
  | 'xl:tw-normal-case'
  | 'sm:tw-select-none'
  | 'md:tw-select-none'
  | 'lg:tw-select-none'
  | 'xl:tw-select-none'
  | 'sm:tw-select-text'
  | 'md:tw-select-text'
  | 'lg:tw-select-text'
  | 'xl:tw-select-text'
  | 'sm:tw-select-all'
  | 'md:tw-select-all'
  | 'lg:tw-select-all'
  | 'xl:tw-select-all'
  | 'sm:tw-select-auto'
  | 'md:tw-select-auto'
  | 'lg:tw-select-auto'
  | 'xl:tw-select-auto'
  | 'sm:tw-align-baseline'
  | 'md:tw-align-baseline'
  | 'lg:tw-align-baseline'
  | 'xl:tw-align-baseline'
  | 'sm:tw-align-top'
  | 'md:tw-align-top'
  | 'lg:tw-align-top'
  | 'xl:tw-align-top'
  | 'sm:tw-align-middle'
  | 'md:tw-align-middle'
  | 'lg:tw-align-middle'
  | 'xl:tw-align-middle'
  | 'sm:tw-align-bottom'
  | 'md:tw-align-bottom'
  | 'lg:tw-align-bottom'
  | 'xl:tw-align-bottom'
  | 'sm:tw-align-text-top'
  | 'md:tw-align-text-top'
  | 'lg:tw-align-text-top'
  | 'xl:tw-align-text-top'
  | 'sm:tw-align-text-bottom'
  | 'md:tw-align-text-bottom'
  | 'lg:tw-align-text-bottom'
  | 'xl:tw-align-text-bottom'
  | 'group-hover:tw-visible'
  | 'tw-group'
  | 'sm:tw-visible'
  | 'md:tw-visible'
  | 'lg:tw-visible'
  | 'xl:tw-visible'
  | 'group-hover:tw-invisible'
  | 'tw-group'
  | 'sm:tw-invisible'
  | 'md:tw-invisible'
  | 'lg:tw-invisible'
  | 'xl:tw-invisible'
  | 'sm:tw-whitespace-normal'
  | 'md:tw-whitespace-normal'
  | 'lg:tw-whitespace-normal'
  | 'xl:tw-whitespace-normal'
  | 'sm:tw-whitespace-no-wrap'
  | 'md:tw-whitespace-no-wrap'
  | 'lg:tw-whitespace-no-wrap'
  | 'xl:tw-whitespace-no-wrap'
  | 'sm:tw-whitespace-pre'
  | 'md:tw-whitespace-pre'
  | 'lg:tw-whitespace-pre'
  | 'xl:tw-whitespace-pre'
  | 'sm:tw-whitespace-pre-line'
  | 'md:tw-whitespace-pre-line'
  | 'lg:tw-whitespace-pre-line'
  | 'xl:tw-whitespace-pre-line'
  | 'sm:tw-whitespace-pre-wrap'
  | 'md:tw-whitespace-pre-wrap'
  | 'lg:tw-whitespace-pre-wrap'
  | 'xl:tw-whitespace-pre-wrap'
  | 'sm:tw-w-0'
  | 'md:tw-w-0'
  | 'lg:tw-w-0'
  | 'xl:tw-w-0'
  | 'sm:tw-w-1'
  | 'md:tw-w-1'
  | 'lg:tw-w-1'
  | 'xl:tw-w-1'
  | 'sm:tw-w-2'
  | 'md:tw-w-2'
  | 'lg:tw-w-2'
  | 'xl:tw-w-2'
  | 'sm:tw-w-3'
  | 'md:tw-w-3'
  | 'lg:tw-w-3'
  | 'xl:tw-w-3'
  | 'sm:tw-w-4'
  | 'md:tw-w-4'
  | 'lg:tw-w-4'
  | 'xl:tw-w-4'
  | 'sm:tw-w-5'
  | 'md:tw-w-5'
  | 'lg:tw-w-5'
  | 'xl:tw-w-5'
  | 'sm:tw-w-6'
  | 'md:tw-w-6'
  | 'lg:tw-w-6'
  | 'xl:tw-w-6'
  | 'sm:tw-w-8'
  | 'md:tw-w-8'
  | 'lg:tw-w-8'
  | 'xl:tw-w-8'
  | 'sm:tw-w-10'
  | 'md:tw-w-10'
  | 'lg:tw-w-10'
  | 'xl:tw-w-10'
  | 'sm:tw-w-12'
  | 'md:tw-w-12'
  | 'lg:tw-w-12'
  | 'xl:tw-w-12'
  | 'sm:tw-w-16'
  | 'md:tw-w-16'
  | 'lg:tw-w-16'
  | 'xl:tw-w-16'
  | 'sm:tw-w-20'
  | 'md:tw-w-20'
  | 'lg:tw-w-20'
  | 'xl:tw-w-20'
  | 'sm:tw-w-24'
  | 'md:tw-w-24'
  | 'lg:tw-w-24'
  | 'xl:tw-w-24'
  | 'sm:tw-w-32'
  | 'md:tw-w-32'
  | 'lg:tw-w-32'
  | 'xl:tw-w-32'
  | 'sm:tw-w-40'
  | 'md:tw-w-40'
  | 'lg:tw-w-40'
  | 'xl:tw-w-40'
  | 'sm:tw-w-48'
  | 'md:tw-w-48'
  | 'lg:tw-w-48'
  | 'xl:tw-w-48'
  | 'sm:tw-w-56'
  | 'md:tw-w-56'
  | 'lg:tw-w-56'
  | 'xl:tw-w-56'
  | 'sm:tw-w-64'
  | 'md:tw-w-64'
  | 'lg:tw-w-64'
  | 'xl:tw-w-64'
  | 'sm:tw-w-auto'
  | 'md:tw-w-auto'
  | 'lg:tw-w-auto'
  | 'xl:tw-w-auto'
  | 'sm:tw-w-px'
  | 'md:tw-w-px'
  | 'lg:tw-w-px'
  | 'xl:tw-w-px'
  | 'sm:tw-w-1/2'
  | 'md:tw-w-1/2'
  | 'lg:tw-w-1/2'
  | 'xl:tw-w-1/2'
  | 'sm:tw-w-1/3'
  | 'md:tw-w-1/3'
  | 'lg:tw-w-1/3'
  | 'xl:tw-w-1/3'
  | 'sm:tw-w-2/3'
  | 'md:tw-w-2/3'
  | 'lg:tw-w-2/3'
  | 'xl:tw-w-2/3'
  | 'sm:tw-w-1/4'
  | 'md:tw-w-1/4'
  | 'lg:tw-w-1/4'
  | 'xl:tw-w-1/4'
  | 'sm:tw-w-2/4'
  | 'md:tw-w-2/4'
  | 'lg:tw-w-2/4'
  | 'xl:tw-w-2/4'
  | 'sm:tw-w-3/4'
  | 'md:tw-w-3/4'
  | 'lg:tw-w-3/4'
  | 'xl:tw-w-3/4'
  | 'sm:tw-w-1/5'
  | 'md:tw-w-1/5'
  | 'lg:tw-w-1/5'
  | 'xl:tw-w-1/5'
  | 'sm:tw-w-2/5'
  | 'md:tw-w-2/5'
  | 'lg:tw-w-2/5'
  | 'xl:tw-w-2/5'
  | 'sm:tw-w-3/5'
  | 'md:tw-w-3/5'
  | 'lg:tw-w-3/5'
  | 'xl:tw-w-3/5'
  | 'sm:tw-w-4/5'
  | 'md:tw-w-4/5'
  | 'lg:tw-w-4/5'
  | 'xl:tw-w-4/5'
  | 'sm:tw-w-1/6'
  | 'md:tw-w-1/6'
  | 'lg:tw-w-1/6'
  | 'xl:tw-w-1/6'
  | 'sm:tw-w-2/6'
  | 'md:tw-w-2/6'
  | 'lg:tw-w-2/6'
  | 'xl:tw-w-2/6'
  | 'sm:tw-w-3/6'
  | 'md:tw-w-3/6'
  | 'lg:tw-w-3/6'
  | 'xl:tw-w-3/6'
  | 'sm:tw-w-4/6'
  | 'md:tw-w-4/6'
  | 'lg:tw-w-4/6'
  | 'xl:tw-w-4/6'
  | 'sm:tw-w-5/6'
  | 'md:tw-w-5/6'
  | 'lg:tw-w-5/6'
  | 'xl:tw-w-5/6'
  | 'sm:tw-w-1/12'
  | 'md:tw-w-1/12'
  | 'lg:tw-w-1/12'
  | 'xl:tw-w-1/12'
  | 'sm:tw-w-2/12'
  | 'md:tw-w-2/12'
  | 'lg:tw-w-2/12'
  | 'xl:tw-w-2/12'
  | 'sm:tw-w-3/12'
  | 'md:tw-w-3/12'
  | 'lg:tw-w-3/12'
  | 'xl:tw-w-3/12'
  | 'sm:tw-w-4/12'
  | 'md:tw-w-4/12'
  | 'lg:tw-w-4/12'
  | 'xl:tw-w-4/12'
  | 'sm:tw-w-5/12'
  | 'md:tw-w-5/12'
  | 'lg:tw-w-5/12'
  | 'xl:tw-w-5/12'
  | 'sm:tw-w-6/12'
  | 'md:tw-w-6/12'
  | 'lg:tw-w-6/12'
  | 'xl:tw-w-6/12'
  | 'sm:tw-w-7/12'
  | 'md:tw-w-7/12'
  | 'lg:tw-w-7/12'
  | 'xl:tw-w-7/12'
  | 'sm:tw-w-8/12'
  | 'md:tw-w-8/12'
  | 'lg:tw-w-8/12'
  | 'xl:tw-w-8/12'
  | 'sm:tw-w-9/12'
  | 'md:tw-w-9/12'
  | 'lg:tw-w-9/12'
  | 'xl:tw-w-9/12'
  | 'sm:tw-w-10/12'
  | 'md:tw-w-10/12'
  | 'lg:tw-w-10/12'
  | 'xl:tw-w-10/12'
  | 'sm:tw-w-11/12'
  | 'md:tw-w-11/12'
  | 'lg:tw-w-11/12'
  | 'xl:tw-w-11/12'
  | 'sm:tw-w-full'
  | 'md:tw-w-full'
  | 'lg:tw-w-full'
  | 'xl:tw-w-full'
  | 'sm:tw-w-screen'
  | 'md:tw-w-screen'
  | 'lg:tw-w-screen'
  | 'xl:tw-w-screen'
  | 'sm:tw-break-normal'
  | 'md:tw-break-normal'
  | 'lg:tw-break-normal'
  | 'xl:tw-break-normal'
  | 'sm:tw-break-words'
  | 'md:tw-break-words'
  | 'lg:tw-break-words'
  | 'xl:tw-break-words'
  | 'sm:tw-break-all'
  | 'md:tw-break-all'
  | 'lg:tw-break-all'
  | 'xl:tw-break-all'
  | 'sm:tw-truncate'
  | 'md:tw-truncate'
  | 'lg:tw-truncate'
  | 'xl:tw-truncate'
  | 'sm:tw-z-0'
  | 'md:tw-z-0'
  | 'lg:tw-z-0'
  | 'xl:tw-z-0'
  | 'sm:tw-z-10'
  | 'md:tw-z-10'
  | 'lg:tw-z-10'
  | 'xl:tw-z-10'
  | 'sm:tw-z-20'
  | 'md:tw-z-20'
  | 'lg:tw-z-20'
  | 'xl:tw-z-20'
  | 'sm:tw-z-30'
  | 'md:tw-z-30'
  | 'lg:tw-z-30'
  | 'xl:tw-z-30'
  | 'sm:tw-z-40'
  | 'md:tw-z-40'
  | 'lg:tw-z-40'
  | 'xl:tw-z-40'
  | 'sm:tw-z-50'
  | 'md:tw-z-50'
  | 'lg:tw-z-50'
  | 'xl:tw-z-50'
  | 'sm:tw-z-auto'
  | 'md:tw-z-auto'
  | 'lg:tw-z-auto'
  | 'xl:tw-z-auto'
  | 'sm:tw-gap-0'
  | 'md:tw-gap-0'
  | 'lg:tw-gap-0'
  | 'xl:tw-gap-0'
  | 'sm:tw-gap-1'
  | 'md:tw-gap-1'
  | 'lg:tw-gap-1'
  | 'xl:tw-gap-1'
  | 'sm:tw-gap-2'
  | 'md:tw-gap-2'
  | 'lg:tw-gap-2'
  | 'xl:tw-gap-2'
  | 'sm:tw-gap-3'
  | 'md:tw-gap-3'
  | 'lg:tw-gap-3'
  | 'xl:tw-gap-3'
  | 'sm:tw-gap-4'
  | 'md:tw-gap-4'
  | 'lg:tw-gap-4'
  | 'xl:tw-gap-4'
  | 'sm:tw-gap-5'
  | 'md:tw-gap-5'
  | 'lg:tw-gap-5'
  | 'xl:tw-gap-5'
  | 'sm:tw-gap-6'
  | 'md:tw-gap-6'
  | 'lg:tw-gap-6'
  | 'xl:tw-gap-6'
  | 'sm:tw-gap-8'
  | 'md:tw-gap-8'
  | 'lg:tw-gap-8'
  | 'xl:tw-gap-8'
  | 'sm:tw-gap-10'
  | 'md:tw-gap-10'
  | 'lg:tw-gap-10'
  | 'xl:tw-gap-10'
  | 'sm:tw-gap-12'
  | 'md:tw-gap-12'
  | 'lg:tw-gap-12'
  | 'xl:tw-gap-12'
  | 'sm:tw-gap-16'
  | 'md:tw-gap-16'
  | 'lg:tw-gap-16'
  | 'xl:tw-gap-16'
  | 'sm:tw-gap-20'
  | 'md:tw-gap-20'
  | 'lg:tw-gap-20'
  | 'xl:tw-gap-20'
  | 'sm:tw-gap-24'
  | 'md:tw-gap-24'
  | 'lg:tw-gap-24'
  | 'xl:tw-gap-24'
  | 'sm:tw-gap-32'
  | 'md:tw-gap-32'
  | 'lg:tw-gap-32'
  | 'xl:tw-gap-32'
  | 'sm:tw-gap-40'
  | 'md:tw-gap-40'
  | 'lg:tw-gap-40'
  | 'xl:tw-gap-40'
  | 'sm:tw-gap-48'
  | 'md:tw-gap-48'
  | 'lg:tw-gap-48'
  | 'xl:tw-gap-48'
  | 'sm:tw-gap-56'
  | 'md:tw-gap-56'
  | 'lg:tw-gap-56'
  | 'xl:tw-gap-56'
  | 'sm:tw-gap-64'
  | 'md:tw-gap-64'
  | 'lg:tw-gap-64'
  | 'xl:tw-gap-64'
  | 'sm:tw-gap-px'
  | 'md:tw-gap-px'
  | 'lg:tw-gap-px'
  | 'xl:tw-gap-px'
  | 'sm:tw-gap-y-0'
  | 'md:tw-gap-y-0'
  | 'lg:tw-gap-y-0'
  | 'xl:tw-gap-y-0'
  | 'sm:tw-gap-y-1'
  | 'md:tw-gap-y-1'
  | 'lg:tw-gap-y-1'
  | 'xl:tw-gap-y-1'
  | 'sm:tw-gap-y-2'
  | 'md:tw-gap-y-2'
  | 'lg:tw-gap-y-2'
  | 'xl:tw-gap-y-2'
  | 'sm:tw-gap-y-3'
  | 'md:tw-gap-y-3'
  | 'lg:tw-gap-y-3'
  | 'xl:tw-gap-y-3'
  | 'sm:tw-gap-y-4'
  | 'md:tw-gap-y-4'
  | 'lg:tw-gap-y-4'
  | 'xl:tw-gap-y-4'
  | 'sm:tw-gap-y-5'
  | 'md:tw-gap-y-5'
  | 'lg:tw-gap-y-5'
  | 'xl:tw-gap-y-5'
  | 'sm:tw-gap-y-6'
  | 'md:tw-gap-y-6'
  | 'lg:tw-gap-y-6'
  | 'xl:tw-gap-y-6'
  | 'sm:tw-gap-y-8'
  | 'md:tw-gap-y-8'
  | 'lg:tw-gap-y-8'
  | 'xl:tw-gap-y-8'
  | 'sm:tw-gap-y-10'
  | 'md:tw-gap-y-10'
  | 'lg:tw-gap-y-10'
  | 'xl:tw-gap-y-10'
  | 'sm:tw-gap-y-12'
  | 'md:tw-gap-y-12'
  | 'lg:tw-gap-y-12'
  | 'xl:tw-gap-y-12'
  | 'sm:tw-gap-y-16'
  | 'md:tw-gap-y-16'
  | 'lg:tw-gap-y-16'
  | 'xl:tw-gap-y-16'
  | 'sm:tw-gap-y-20'
  | 'md:tw-gap-y-20'
  | 'lg:tw-gap-y-20'
  | 'xl:tw-gap-y-20'
  | 'sm:tw-gap-y-24'
  | 'md:tw-gap-y-24'
  | 'lg:tw-gap-y-24'
  | 'xl:tw-gap-y-24'
  | 'sm:tw-gap-y-32'
  | 'md:tw-gap-y-32'
  | 'lg:tw-gap-y-32'
  | 'xl:tw-gap-y-32'
  | 'sm:tw-gap-y-40'
  | 'md:tw-gap-y-40'
  | 'lg:tw-gap-y-40'
  | 'xl:tw-gap-y-40'
  | 'sm:tw-gap-y-48'
  | 'md:tw-gap-y-48'
  | 'lg:tw-gap-y-48'
  | 'xl:tw-gap-y-48'
  | 'sm:tw-gap-y-56'
  | 'md:tw-gap-y-56'
  | 'lg:tw-gap-y-56'
  | 'xl:tw-gap-y-56'
  | 'sm:tw-gap-y-64'
  | 'md:tw-gap-y-64'
  | 'lg:tw-gap-y-64'
  | 'xl:tw-gap-y-64'
  | 'sm:tw-gap-y-px'
  | 'md:tw-gap-y-px'
  | 'lg:tw-gap-y-px'
  | 'xl:tw-gap-y-px'
  | 'sm:tw-gap-x-0'
  | 'md:tw-gap-x-0'
  | 'lg:tw-gap-x-0'
  | 'xl:tw-gap-x-0'
  | 'sm:tw-gap-x-1'
  | 'md:tw-gap-x-1'
  | 'lg:tw-gap-x-1'
  | 'xl:tw-gap-x-1'
  | 'sm:tw-gap-x-2'
  | 'md:tw-gap-x-2'
  | 'lg:tw-gap-x-2'
  | 'xl:tw-gap-x-2'
  | 'sm:tw-gap-x-3'
  | 'md:tw-gap-x-3'
  | 'lg:tw-gap-x-3'
  | 'xl:tw-gap-x-3'
  | 'sm:tw-gap-x-4'
  | 'md:tw-gap-x-4'
  | 'lg:tw-gap-x-4'
  | 'xl:tw-gap-x-4'
  | 'sm:tw-gap-x-5'
  | 'md:tw-gap-x-5'
  | 'lg:tw-gap-x-5'
  | 'xl:tw-gap-x-5'
  | 'sm:tw-gap-x-6'
  | 'md:tw-gap-x-6'
  | 'lg:tw-gap-x-6'
  | 'xl:tw-gap-x-6'
  | 'sm:tw-gap-x-8'
  | 'md:tw-gap-x-8'
  | 'lg:tw-gap-x-8'
  | 'xl:tw-gap-x-8'
  | 'sm:tw-gap-x-10'
  | 'md:tw-gap-x-10'
  | 'lg:tw-gap-x-10'
  | 'xl:tw-gap-x-10'
  | 'sm:tw-gap-x-12'
  | 'md:tw-gap-x-12'
  | 'lg:tw-gap-x-12'
  | 'xl:tw-gap-x-12'
  | 'sm:tw-gap-x-16'
  | 'md:tw-gap-x-16'
  | 'lg:tw-gap-x-16'
  | 'xl:tw-gap-x-16'
  | 'sm:tw-gap-x-20'
  | 'md:tw-gap-x-20'
  | 'lg:tw-gap-x-20'
  | 'xl:tw-gap-x-20'
  | 'sm:tw-gap-x-24'
  | 'md:tw-gap-x-24'
  | 'lg:tw-gap-x-24'
  | 'xl:tw-gap-x-24'
  | 'sm:tw-gap-x-32'
  | 'md:tw-gap-x-32'
  | 'lg:tw-gap-x-32'
  | 'xl:tw-gap-x-32'
  | 'sm:tw-gap-x-40'
  | 'md:tw-gap-x-40'
  | 'lg:tw-gap-x-40'
  | 'xl:tw-gap-x-40'
  | 'sm:tw-gap-x-48'
  | 'md:tw-gap-x-48'
  | 'lg:tw-gap-x-48'
  | 'xl:tw-gap-x-48'
  | 'sm:tw-gap-x-56'
  | 'md:tw-gap-x-56'
  | 'lg:tw-gap-x-56'
  | 'xl:tw-gap-x-56'
  | 'sm:tw-gap-x-64'
  | 'md:tw-gap-x-64'
  | 'lg:tw-gap-x-64'
  | 'xl:tw-gap-x-64'
  | 'sm:tw-gap-x-px'
  | 'md:tw-gap-x-px'
  | 'lg:tw-gap-x-px'
  | 'xl:tw-gap-x-px'
  | 'sm:tw-grid-flow-row'
  | 'md:tw-grid-flow-row'
  | 'lg:tw-grid-flow-row'
  | 'xl:tw-grid-flow-row'
  | 'sm:tw-grid-flow-col'
  | 'md:tw-grid-flow-col'
  | 'lg:tw-grid-flow-col'
  | 'xl:tw-grid-flow-col'
  | 'sm:tw-grid-flow-row-dense'
  | 'md:tw-grid-flow-row-dense'
  | 'lg:tw-grid-flow-row-dense'
  | 'xl:tw-grid-flow-row-dense'
  | 'sm:tw-grid-flow-col-dense'
  | 'md:tw-grid-flow-col-dense'
  | 'lg:tw-grid-flow-col-dense'
  | 'xl:tw-grid-flow-col-dense'
  | 'sm:tw-grid-cols-1'
  | 'md:tw-grid-cols-1'
  | 'lg:tw-grid-cols-1'
  | 'xl:tw-grid-cols-1'
  | 'sm:tw-grid-cols-2'
  | 'md:tw-grid-cols-2'
  | 'lg:tw-grid-cols-2'
  | 'xl:tw-grid-cols-2'
  | 'sm:tw-grid-cols-3'
  | 'md:tw-grid-cols-3'
  | 'lg:tw-grid-cols-3'
  | 'xl:tw-grid-cols-3'
  | 'sm:tw-grid-cols-4'
  | 'md:tw-grid-cols-4'
  | 'lg:tw-grid-cols-4'
  | 'xl:tw-grid-cols-4'
  | 'sm:tw-grid-cols-5'
  | 'md:tw-grid-cols-5'
  | 'lg:tw-grid-cols-5'
  | 'xl:tw-grid-cols-5'
  | 'sm:tw-grid-cols-6'
  | 'md:tw-grid-cols-6'
  | 'lg:tw-grid-cols-6'
  | 'xl:tw-grid-cols-6'
  | 'sm:tw-grid-cols-7'
  | 'md:tw-grid-cols-7'
  | 'lg:tw-grid-cols-7'
  | 'xl:tw-grid-cols-7'
  | 'sm:tw-grid-cols-8'
  | 'md:tw-grid-cols-8'
  | 'lg:tw-grid-cols-8'
  | 'xl:tw-grid-cols-8'
  | 'sm:tw-grid-cols-9'
  | 'md:tw-grid-cols-9'
  | 'lg:tw-grid-cols-9'
  | 'xl:tw-grid-cols-9'
  | 'sm:tw-grid-cols-10'
  | 'md:tw-grid-cols-10'
  | 'lg:tw-grid-cols-10'
  | 'xl:tw-grid-cols-10'
  | 'sm:tw-grid-cols-11'
  | 'md:tw-grid-cols-11'
  | 'lg:tw-grid-cols-11'
  | 'xl:tw-grid-cols-11'
  | 'sm:tw-grid-cols-12'
  | 'md:tw-grid-cols-12'
  | 'lg:tw-grid-cols-12'
  | 'xl:tw-grid-cols-12'
  | 'sm:tw-grid-cols-none'
  | 'md:tw-grid-cols-none'
  | 'lg:tw-grid-cols-none'
  | 'xl:tw-grid-cols-none'
  | 'sm:tw-auto-cols-auto'
  | 'md:tw-auto-cols-auto'
  | 'lg:tw-auto-cols-auto'
  | 'xl:tw-auto-cols-auto'
  | 'sm:tw-auto-cols-min'
  | 'md:tw-auto-cols-min'
  | 'lg:tw-auto-cols-min'
  | 'xl:tw-auto-cols-min'
  | 'sm:tw-auto-cols-max'
  | 'md:tw-auto-cols-max'
  | 'lg:tw-auto-cols-max'
  | 'xl:tw-auto-cols-max'
  | 'sm:tw-auto-cols-fr'
  | 'md:tw-auto-cols-fr'
  | 'lg:tw-auto-cols-fr'
  | 'xl:tw-auto-cols-fr'
  | 'sm:tw-col-auto'
  | 'md:tw-col-auto'
  | 'lg:tw-col-auto'
  | 'xl:tw-col-auto'
  | 'sm:tw-col-span-1'
  | 'md:tw-col-span-1'
  | 'lg:tw-col-span-1'
  | 'xl:tw-col-span-1'
  | 'sm:tw-col-span-2'
  | 'md:tw-col-span-2'
  | 'lg:tw-col-span-2'
  | 'xl:tw-col-span-2'
  | 'sm:tw-col-span-3'
  | 'md:tw-col-span-3'
  | 'lg:tw-col-span-3'
  | 'xl:tw-col-span-3'
  | 'sm:tw-col-span-4'
  | 'md:tw-col-span-4'
  | 'lg:tw-col-span-4'
  | 'xl:tw-col-span-4'
  | 'sm:tw-col-span-5'
  | 'md:tw-col-span-5'
  | 'lg:tw-col-span-5'
  | 'xl:tw-col-span-5'
  | 'sm:tw-col-span-6'
  | 'md:tw-col-span-6'
  | 'lg:tw-col-span-6'
  | 'xl:tw-col-span-6'
  | 'sm:tw-col-span-7'
  | 'md:tw-col-span-7'
  | 'lg:tw-col-span-7'
  | 'xl:tw-col-span-7'
  | 'sm:tw-col-span-8'
  | 'md:tw-col-span-8'
  | 'lg:tw-col-span-8'
  | 'xl:tw-col-span-8'
  | 'sm:tw-col-span-9'
  | 'md:tw-col-span-9'
  | 'lg:tw-col-span-9'
  | 'xl:tw-col-span-9'
  | 'sm:tw-col-span-10'
  | 'md:tw-col-span-10'
  | 'lg:tw-col-span-10'
  | 'xl:tw-col-span-10'
  | 'sm:tw-col-span-11'
  | 'md:tw-col-span-11'
  | 'lg:tw-col-span-11'
  | 'xl:tw-col-span-11'
  | 'sm:tw-col-span-12'
  | 'md:tw-col-span-12'
  | 'lg:tw-col-span-12'
  | 'xl:tw-col-span-12'
  | 'sm:tw-col-span-full'
  | 'md:tw-col-span-full'
  | 'lg:tw-col-span-full'
  | 'xl:tw-col-span-full'
  | 'sm:tw-col-start-1'
  | 'md:tw-col-start-1'
  | 'lg:tw-col-start-1'
  | 'xl:tw-col-start-1'
  | 'sm:tw-col-start-2'
  | 'md:tw-col-start-2'
  | 'lg:tw-col-start-2'
  | 'xl:tw-col-start-2'
  | 'sm:tw-col-start-3'
  | 'md:tw-col-start-3'
  | 'lg:tw-col-start-3'
  | 'xl:tw-col-start-3'
  | 'sm:tw-col-start-4'
  | 'md:tw-col-start-4'
  | 'lg:tw-col-start-4'
  | 'xl:tw-col-start-4'
  | 'sm:tw-col-start-5'
  | 'md:tw-col-start-5'
  | 'lg:tw-col-start-5'
  | 'xl:tw-col-start-5'
  | 'sm:tw-col-start-6'
  | 'md:tw-col-start-6'
  | 'lg:tw-col-start-6'
  | 'xl:tw-col-start-6'
  | 'sm:tw-col-start-7'
  | 'md:tw-col-start-7'
  | 'lg:tw-col-start-7'
  | 'xl:tw-col-start-7'
  | 'sm:tw-col-start-8'
  | 'md:tw-col-start-8'
  | 'lg:tw-col-start-8'
  | 'xl:tw-col-start-8'
  | 'sm:tw-col-start-9'
  | 'md:tw-col-start-9'
  | 'lg:tw-col-start-9'
  | 'xl:tw-col-start-9'
  | 'sm:tw-col-start-10'
  | 'md:tw-col-start-10'
  | 'lg:tw-col-start-10'
  | 'xl:tw-col-start-10'
  | 'sm:tw-col-start-11'
  | 'md:tw-col-start-11'
  | 'lg:tw-col-start-11'
  | 'xl:tw-col-start-11'
  | 'sm:tw-col-start-12'
  | 'md:tw-col-start-12'
  | 'lg:tw-col-start-12'
  | 'xl:tw-col-start-12'
  | 'sm:tw-col-start-13'
  | 'md:tw-col-start-13'
  | 'lg:tw-col-start-13'
  | 'xl:tw-col-start-13'
  | 'sm:tw-col-start-auto'
  | 'md:tw-col-start-auto'
  | 'lg:tw-col-start-auto'
  | 'xl:tw-col-start-auto'
  | 'sm:tw-col-end-1'
  | 'md:tw-col-end-1'
  | 'lg:tw-col-end-1'
  | 'xl:tw-col-end-1'
  | 'sm:tw-col-end-2'
  | 'md:tw-col-end-2'
  | 'lg:tw-col-end-2'
  | 'xl:tw-col-end-2'
  | 'sm:tw-col-end-3'
  | 'md:tw-col-end-3'
  | 'lg:tw-col-end-3'
  | 'xl:tw-col-end-3'
  | 'sm:tw-col-end-4'
  | 'md:tw-col-end-4'
  | 'lg:tw-col-end-4'
  | 'xl:tw-col-end-4'
  | 'sm:tw-col-end-5'
  | 'md:tw-col-end-5'
  | 'lg:tw-col-end-5'
  | 'xl:tw-col-end-5'
  | 'sm:tw-col-end-6'
  | 'md:tw-col-end-6'
  | 'lg:tw-col-end-6'
  | 'xl:tw-col-end-6'
  | 'sm:tw-col-end-7'
  | 'md:tw-col-end-7'
  | 'lg:tw-col-end-7'
  | 'xl:tw-col-end-7'
  | 'sm:tw-col-end-8'
  | 'md:tw-col-end-8'
  | 'lg:tw-col-end-8'
  | 'xl:tw-col-end-8'
  | 'sm:tw-col-end-9'
  | 'md:tw-col-end-9'
  | 'lg:tw-col-end-9'
  | 'xl:tw-col-end-9'
  | 'sm:tw-col-end-10'
  | 'md:tw-col-end-10'
  | 'lg:tw-col-end-10'
  | 'xl:tw-col-end-10'
  | 'sm:tw-col-end-11'
  | 'md:tw-col-end-11'
  | 'lg:tw-col-end-11'
  | 'xl:tw-col-end-11'
  | 'sm:tw-col-end-12'
  | 'md:tw-col-end-12'
  | 'lg:tw-col-end-12'
  | 'xl:tw-col-end-12'
  | 'sm:tw-col-end-13'
  | 'md:tw-col-end-13'
  | 'lg:tw-col-end-13'
  | 'xl:tw-col-end-13'
  | 'sm:tw-col-end-auto'
  | 'md:tw-col-end-auto'
  | 'lg:tw-col-end-auto'
  | 'xl:tw-col-end-auto'
  | 'sm:tw-grid-rows-1'
  | 'md:tw-grid-rows-1'
  | 'lg:tw-grid-rows-1'
  | 'xl:tw-grid-rows-1'
  | 'sm:tw-grid-rows-2'
  | 'md:tw-grid-rows-2'
  | 'lg:tw-grid-rows-2'
  | 'xl:tw-grid-rows-2'
  | 'sm:tw-grid-rows-3'
  | 'md:tw-grid-rows-3'
  | 'lg:tw-grid-rows-3'
  | 'xl:tw-grid-rows-3'
  | 'sm:tw-grid-rows-4'
  | 'md:tw-grid-rows-4'
  | 'lg:tw-grid-rows-4'
  | 'xl:tw-grid-rows-4'
  | 'sm:tw-grid-rows-5'
  | 'md:tw-grid-rows-5'
  | 'lg:tw-grid-rows-5'
  | 'xl:tw-grid-rows-5'
  | 'sm:tw-grid-rows-6'
  | 'md:tw-grid-rows-6'
  | 'lg:tw-grid-rows-6'
  | 'xl:tw-grid-rows-6'
  | 'sm:tw-grid-rows-none'
  | 'md:tw-grid-rows-none'
  | 'lg:tw-grid-rows-none'
  | 'xl:tw-grid-rows-none'
  | 'sm:tw-auto-rows-auto'
  | 'md:tw-auto-rows-auto'
  | 'lg:tw-auto-rows-auto'
  | 'xl:tw-auto-rows-auto'
  | 'sm:tw-auto-rows-min'
  | 'md:tw-auto-rows-min'
  | 'lg:tw-auto-rows-min'
  | 'xl:tw-auto-rows-min'
  | 'sm:tw-auto-rows-max'
  | 'md:tw-auto-rows-max'
  | 'lg:tw-auto-rows-max'
  | 'xl:tw-auto-rows-max'
  | 'sm:tw-auto-rows-fr'
  | 'md:tw-auto-rows-fr'
  | 'lg:tw-auto-rows-fr'
  | 'xl:tw-auto-rows-fr'
  | 'sm:tw-row-auto'
  | 'md:tw-row-auto'
  | 'lg:tw-row-auto'
  | 'xl:tw-row-auto'
  | 'sm:tw-row-span-1'
  | 'md:tw-row-span-1'
  | 'lg:tw-row-span-1'
  | 'xl:tw-row-span-1'
  | 'sm:tw-row-span-2'
  | 'md:tw-row-span-2'
  | 'lg:tw-row-span-2'
  | 'xl:tw-row-span-2'
  | 'sm:tw-row-span-3'
  | 'md:tw-row-span-3'
  | 'lg:tw-row-span-3'
  | 'xl:tw-row-span-3'
  | 'sm:tw-row-span-4'
  | 'md:tw-row-span-4'
  | 'lg:tw-row-span-4'
  | 'xl:tw-row-span-4'
  | 'sm:tw-row-span-5'
  | 'md:tw-row-span-5'
  | 'lg:tw-row-span-5'
  | 'xl:tw-row-span-5'
  | 'sm:tw-row-span-6'
  | 'md:tw-row-span-6'
  | 'lg:tw-row-span-6'
  | 'xl:tw-row-span-6'
  | 'sm:tw-row-span-full'
  | 'md:tw-row-span-full'
  | 'lg:tw-row-span-full'
  | 'xl:tw-row-span-full'
  | 'sm:tw-row-start-1'
  | 'md:tw-row-start-1'
  | 'lg:tw-row-start-1'
  | 'xl:tw-row-start-1'
  | 'sm:tw-row-start-2'
  | 'md:tw-row-start-2'
  | 'lg:tw-row-start-2'
  | 'xl:tw-row-start-2'
  | 'sm:tw-row-start-3'
  | 'md:tw-row-start-3'
  | 'lg:tw-row-start-3'
  | 'xl:tw-row-start-3'
  | 'sm:tw-row-start-4'
  | 'md:tw-row-start-4'
  | 'lg:tw-row-start-4'
  | 'xl:tw-row-start-4'
  | 'sm:tw-row-start-5'
  | 'md:tw-row-start-5'
  | 'lg:tw-row-start-5'
  | 'xl:tw-row-start-5'
  | 'sm:tw-row-start-6'
  | 'md:tw-row-start-6'
  | 'lg:tw-row-start-6'
  | 'xl:tw-row-start-6'
  | 'sm:tw-row-start-7'
  | 'md:tw-row-start-7'
  | 'lg:tw-row-start-7'
  | 'xl:tw-row-start-7'
  | 'sm:tw-row-start-auto'
  | 'md:tw-row-start-auto'
  | 'lg:tw-row-start-auto'
  | 'xl:tw-row-start-auto'
  | 'sm:tw-row-end-1'
  | 'md:tw-row-end-1'
  | 'lg:tw-row-end-1'
  | 'xl:tw-row-end-1'
  | 'sm:tw-row-end-2'
  | 'md:tw-row-end-2'
  | 'lg:tw-row-end-2'
  | 'xl:tw-row-end-2'
  | 'sm:tw-row-end-3'
  | 'md:tw-row-end-3'
  | 'lg:tw-row-end-3'
  | 'xl:tw-row-end-3'
  | 'sm:tw-row-end-4'
  | 'md:tw-row-end-4'
  | 'lg:tw-row-end-4'
  | 'xl:tw-row-end-4'
  | 'sm:tw-row-end-5'
  | 'md:tw-row-end-5'
  | 'lg:tw-row-end-5'
  | 'xl:tw-row-end-5'
  | 'sm:tw-row-end-6'
  | 'md:tw-row-end-6'
  | 'lg:tw-row-end-6'
  | 'xl:tw-row-end-6'
  | 'sm:tw-row-end-7'
  | 'md:tw-row-end-7'
  | 'lg:tw-row-end-7'
  | 'xl:tw-row-end-7'
  | 'sm:tw-row-end-auto'
  | 'md:tw-row-end-auto'
  | 'lg:tw-row-end-auto'
  | 'xl:tw-row-end-auto'
  | 'sm:tw-origin-center'
  | 'md:tw-origin-center'
  | 'lg:tw-origin-center'
  | 'xl:tw-origin-center'
  | 'sm:tw-origin-top'
  | 'md:tw-origin-top'
  | 'lg:tw-origin-top'
  | 'xl:tw-origin-top'
  | 'sm:tw-origin-top-right'
  | 'md:tw-origin-top-right'
  | 'lg:tw-origin-top-right'
  | 'xl:tw-origin-top-right'
  | 'sm:tw-origin-right'
  | 'md:tw-origin-right'
  | 'lg:tw-origin-right'
  | 'xl:tw-origin-right'
  | 'sm:tw-origin-bottom-right'
  | 'md:tw-origin-bottom-right'
  | 'lg:tw-origin-bottom-right'
  | 'xl:tw-origin-bottom-right'
  | 'sm:tw-origin-bottom'
  | 'md:tw-origin-bottom'
  | 'lg:tw-origin-bottom'
  | 'xl:tw-origin-bottom'
  | 'sm:tw-origin-bottom-left'
  | 'md:tw-origin-bottom-left'
  | 'lg:tw-origin-bottom-left'
  | 'xl:tw-origin-bottom-left'
  | 'sm:tw-origin-left'
  | 'md:tw-origin-left'
  | 'lg:tw-origin-left'
  | 'xl:tw-origin-left'
  | 'sm:tw-origin-top-left'
  | 'md:tw-origin-top-left'
  | 'lg:tw-origin-top-left'
  | 'xl:tw-origin-top-left'
  | 'sm:tw-scale-0'
  | 'md:tw-scale-0'
  | 'lg:tw-scale-0'
  | 'xl:tw-scale-0'
  | 'hover:tw-scale-0'
  | 'focus:tw-scale-0'
  | 'sm:tw-scale-50'
  | 'md:tw-scale-50'
  | 'lg:tw-scale-50'
  | 'xl:tw-scale-50'
  | 'hover:tw-scale-50'
  | 'focus:tw-scale-50'
  | 'sm:tw-scale-75'
  | 'md:tw-scale-75'
  | 'lg:tw-scale-75'
  | 'xl:tw-scale-75'
  | 'hover:tw-scale-75'
  | 'focus:tw-scale-75'
  | 'sm:tw-scale-90'
  | 'md:tw-scale-90'
  | 'lg:tw-scale-90'
  | 'xl:tw-scale-90'
  | 'hover:tw-scale-90'
  | 'focus:tw-scale-90'
  | 'sm:tw-scale-95'
  | 'md:tw-scale-95'
  | 'lg:tw-scale-95'
  | 'xl:tw-scale-95'
  | 'hover:tw-scale-95'
  | 'focus:tw-scale-95'
  | 'sm:tw-scale-100'
  | 'md:tw-scale-100'
  | 'lg:tw-scale-100'
  | 'xl:tw-scale-100'
  | 'hover:tw-scale-100'
  | 'focus:tw-scale-100'
  | 'sm:tw-scale-105'
  | 'md:tw-scale-105'
  | 'lg:tw-scale-105'
  | 'xl:tw-scale-105'
  | 'hover:tw-scale-105'
  | 'focus:tw-scale-105'
  | 'sm:tw-scale-110'
  | 'md:tw-scale-110'
  | 'lg:tw-scale-110'
  | 'xl:tw-scale-110'
  | 'hover:tw-scale-110'
  | 'focus:tw-scale-110'
  | 'sm:tw-scale-125'
  | 'md:tw-scale-125'
  | 'lg:tw-scale-125'
  | 'xl:tw-scale-125'
  | 'hover:tw-scale-125'
  | 'focus:tw-scale-125'
  | 'sm:tw-scale-150'
  | 'md:tw-scale-150'
  | 'lg:tw-scale-150'
  | 'xl:tw-scale-150'
  | 'hover:tw-scale-150'
  | 'focus:tw-scale-150'
  | 'sm:tw-scale-x-0'
  | 'md:tw-scale-x-0'
  | 'lg:tw-scale-x-0'
  | 'xl:tw-scale-x-0'
  | 'hover:tw-scale-x-0'
  | 'focus:tw-scale-x-0'
  | 'sm:tw-scale-x-50'
  | 'md:tw-scale-x-50'
  | 'lg:tw-scale-x-50'
  | 'xl:tw-scale-x-50'
  | 'hover:tw-scale-x-50'
  | 'focus:tw-scale-x-50'
  | 'sm:tw-scale-x-75'
  | 'md:tw-scale-x-75'
  | 'lg:tw-scale-x-75'
  | 'xl:tw-scale-x-75'
  | 'hover:tw-scale-x-75'
  | 'focus:tw-scale-x-75'
  | 'sm:tw-scale-x-90'
  | 'md:tw-scale-x-90'
  | 'lg:tw-scale-x-90'
  | 'xl:tw-scale-x-90'
  | 'hover:tw-scale-x-90'
  | 'focus:tw-scale-x-90'
  | 'sm:tw-scale-x-95'
  | 'md:tw-scale-x-95'
  | 'lg:tw-scale-x-95'
  | 'xl:tw-scale-x-95'
  | 'hover:tw-scale-x-95'
  | 'focus:tw-scale-x-95'
  | 'sm:tw-scale-x-100'
  | 'md:tw-scale-x-100'
  | 'lg:tw-scale-x-100'
  | 'xl:tw-scale-x-100'
  | 'hover:tw-scale-x-100'
  | 'focus:tw-scale-x-100'
  | 'sm:tw-scale-x-105'
  | 'md:tw-scale-x-105'
  | 'lg:tw-scale-x-105'
  | 'xl:tw-scale-x-105'
  | 'hover:tw-scale-x-105'
  | 'focus:tw-scale-x-105'
  | 'sm:tw-scale-x-110'
  | 'md:tw-scale-x-110'
  | 'lg:tw-scale-x-110'
  | 'xl:tw-scale-x-110'
  | 'hover:tw-scale-x-110'
  | 'focus:tw-scale-x-110'
  | 'sm:tw-scale-x-125'
  | 'md:tw-scale-x-125'
  | 'lg:tw-scale-x-125'
  | 'xl:tw-scale-x-125'
  | 'hover:tw-scale-x-125'
  | 'focus:tw-scale-x-125'
  | 'sm:tw-scale-x-150'
  | 'md:tw-scale-x-150'
  | 'lg:tw-scale-x-150'
  | 'xl:tw-scale-x-150'
  | 'hover:tw-scale-x-150'
  | 'focus:tw-scale-x-150'
  | 'sm:tw-scale-y-0'
  | 'md:tw-scale-y-0'
  | 'lg:tw-scale-y-0'
  | 'xl:tw-scale-y-0'
  | 'hover:tw-scale-y-0'
  | 'focus:tw-scale-y-0'
  | 'sm:tw-scale-y-50'
  | 'md:tw-scale-y-50'
  | 'lg:tw-scale-y-50'
  | 'xl:tw-scale-y-50'
  | 'hover:tw-scale-y-50'
  | 'focus:tw-scale-y-50'
  | 'sm:tw-scale-y-75'
  | 'md:tw-scale-y-75'
  | 'lg:tw-scale-y-75'
  | 'xl:tw-scale-y-75'
  | 'hover:tw-scale-y-75'
  | 'focus:tw-scale-y-75'
  | 'sm:tw-scale-y-90'
  | 'md:tw-scale-y-90'
  | 'lg:tw-scale-y-90'
  | 'xl:tw-scale-y-90'
  | 'hover:tw-scale-y-90'
  | 'focus:tw-scale-y-90'
  | 'sm:tw-scale-y-95'
  | 'md:tw-scale-y-95'
  | 'lg:tw-scale-y-95'
  | 'xl:tw-scale-y-95'
  | 'hover:tw-scale-y-95'
  | 'focus:tw-scale-y-95'
  | 'sm:tw-scale-y-100'
  | 'md:tw-scale-y-100'
  | 'lg:tw-scale-y-100'
  | 'xl:tw-scale-y-100'
  | 'hover:tw-scale-y-100'
  | 'focus:tw-scale-y-100'
  | 'sm:tw-scale-y-105'
  | 'md:tw-scale-y-105'
  | 'lg:tw-scale-y-105'
  | 'xl:tw-scale-y-105'
  | 'hover:tw-scale-y-105'
  | 'focus:tw-scale-y-105'
  | 'sm:tw-scale-y-110'
  | 'md:tw-scale-y-110'
  | 'lg:tw-scale-y-110'
  | 'xl:tw-scale-y-110'
  | 'hover:tw-scale-y-110'
  | 'focus:tw-scale-y-110'
  | 'sm:tw-scale-y-125'
  | 'md:tw-scale-y-125'
  | 'lg:tw-scale-y-125'
  | 'xl:tw-scale-y-125'
  | 'hover:tw-scale-y-125'
  | 'focus:tw-scale-y-125'
  | 'sm:tw-scale-y-150'
  | 'md:tw-scale-y-150'
  | 'lg:tw-scale-y-150'
  | 'xl:tw-scale-y-150'
  | 'hover:tw-scale-y-150'
  | 'focus:tw-scale-y-150'
  | 'sm:tw-rotate-0'
  | 'md:tw-rotate-0'
  | 'lg:tw-rotate-0'
  | 'xl:tw-rotate-0'
  | 'hover:tw-rotate-0'
  | 'focus:tw-rotate-0'
  | 'sm:tw-rotate-1'
  | 'md:tw-rotate-1'
  | 'lg:tw-rotate-1'
  | 'xl:tw-rotate-1'
  | 'hover:tw-rotate-1'
  | 'focus:tw-rotate-1'
  | 'sm:tw-rotate-2'
  | 'md:tw-rotate-2'
  | 'lg:tw-rotate-2'
  | 'xl:tw-rotate-2'
  | 'hover:tw-rotate-2'
  | 'focus:tw-rotate-2'
  | 'sm:tw-rotate-3'
  | 'md:tw-rotate-3'
  | 'lg:tw-rotate-3'
  | 'xl:tw-rotate-3'
  | 'hover:tw-rotate-3'
  | 'focus:tw-rotate-3'
  | 'sm:tw-rotate-6'
  | 'md:tw-rotate-6'
  | 'lg:tw-rotate-6'
  | 'xl:tw-rotate-6'
  | 'hover:tw-rotate-6'
  | 'focus:tw-rotate-6'
  | 'sm:tw-rotate-12'
  | 'md:tw-rotate-12'
  | 'lg:tw-rotate-12'
  | 'xl:tw-rotate-12'
  | 'hover:tw-rotate-12'
  | 'focus:tw-rotate-12'
  | 'sm:tw-rotate-45'
  | 'md:tw-rotate-45'
  | 'lg:tw-rotate-45'
  | 'xl:tw-rotate-45'
  | 'hover:tw-rotate-45'
  | 'focus:tw-rotate-45'
  | 'sm:tw-rotate-90'
  | 'md:tw-rotate-90'
  | 'lg:tw-rotate-90'
  | 'xl:tw-rotate-90'
  | 'hover:tw-rotate-90'
  | 'focus:tw-rotate-90'
  | 'sm:tw-rotate-180'
  | 'md:tw-rotate-180'
  | 'lg:tw-rotate-180'
  | 'xl:tw-rotate-180'
  | 'hover:tw-rotate-180'
  | 'focus:tw-rotate-180'
  | 'sm:tw--rotate-180'
  | 'md:tw--rotate-180'
  | 'lg:tw--rotate-180'
  | 'xl:tw--rotate-180'
  | 'hover:tw--rotate-180'
  | 'focus:tw--rotate-180'
  | 'sm:tw--rotate-90'
  | 'md:tw--rotate-90'
  | 'lg:tw--rotate-90'
  | 'xl:tw--rotate-90'
  | 'hover:tw--rotate-90'
  | 'focus:tw--rotate-90'
  | 'sm:tw--rotate-45'
  | 'md:tw--rotate-45'
  | 'lg:tw--rotate-45'
  | 'xl:tw--rotate-45'
  | 'hover:tw--rotate-45'
  | 'focus:tw--rotate-45'
  | 'sm:tw--rotate-12'
  | 'md:tw--rotate-12'
  | 'lg:tw--rotate-12'
  | 'xl:tw--rotate-12'
  | 'hover:tw--rotate-12'
  | 'focus:tw--rotate-12'
  | 'sm:tw--rotate-6'
  | 'md:tw--rotate-6'
  | 'lg:tw--rotate-6'
  | 'xl:tw--rotate-6'
  | 'hover:tw--rotate-6'
  | 'focus:tw--rotate-6'
  | 'sm:tw--rotate-3'
  | 'md:tw--rotate-3'
  | 'lg:tw--rotate-3'
  | 'xl:tw--rotate-3'
  | 'hover:tw--rotate-3'
  | 'focus:tw--rotate-3'
  | 'sm:tw--rotate-2'
  | 'md:tw--rotate-2'
  | 'lg:tw--rotate-2'
  | 'xl:tw--rotate-2'
  | 'hover:tw--rotate-2'
  | 'focus:tw--rotate-2'
  | 'sm:tw--rotate-1'
  | 'md:tw--rotate-1'
  | 'lg:tw--rotate-1'
  | 'xl:tw--rotate-1'
  | 'hover:tw--rotate-1'
  | 'focus:tw--rotate-1'
  | 'sm:tw-translate-x-0'
  | 'md:tw-translate-x-0'
  | 'lg:tw-translate-x-0'
  | 'xl:tw-translate-x-0'
  | 'hover:tw-translate-x-0'
  | 'focus:tw-translate-x-0'
  | 'sm:tw-translate-x-1'
  | 'md:tw-translate-x-1'
  | 'lg:tw-translate-x-1'
  | 'xl:tw-translate-x-1'
  | 'hover:tw-translate-x-1'
  | 'focus:tw-translate-x-1'
  | 'sm:tw-translate-x-2'
  | 'md:tw-translate-x-2'
  | 'lg:tw-translate-x-2'
  | 'xl:tw-translate-x-2'
  | 'hover:tw-translate-x-2'
  | 'focus:tw-translate-x-2'
  | 'sm:tw-translate-x-3'
  | 'md:tw-translate-x-3'
  | 'lg:tw-translate-x-3'
  | 'xl:tw-translate-x-3'
  | 'hover:tw-translate-x-3'
  | 'focus:tw-translate-x-3'
  | 'sm:tw-translate-x-4'
  | 'md:tw-translate-x-4'
  | 'lg:tw-translate-x-4'
  | 'xl:tw-translate-x-4'
  | 'hover:tw-translate-x-4'
  | 'focus:tw-translate-x-4'
  | 'sm:tw-translate-x-5'
  | 'md:tw-translate-x-5'
  | 'lg:tw-translate-x-5'
  | 'xl:tw-translate-x-5'
  | 'hover:tw-translate-x-5'
  | 'focus:tw-translate-x-5'
  | 'sm:tw-translate-x-6'
  | 'md:tw-translate-x-6'
  | 'lg:tw-translate-x-6'
  | 'xl:tw-translate-x-6'
  | 'hover:tw-translate-x-6'
  | 'focus:tw-translate-x-6'
  | 'sm:tw-translate-x-8'
  | 'md:tw-translate-x-8'
  | 'lg:tw-translate-x-8'
  | 'xl:tw-translate-x-8'
  | 'hover:tw-translate-x-8'
  | 'focus:tw-translate-x-8'
  | 'sm:tw-translate-x-10'
  | 'md:tw-translate-x-10'
  | 'lg:tw-translate-x-10'
  | 'xl:tw-translate-x-10'
  | 'hover:tw-translate-x-10'
  | 'focus:tw-translate-x-10'
  | 'sm:tw-translate-x-12'
  | 'md:tw-translate-x-12'
  | 'lg:tw-translate-x-12'
  | 'xl:tw-translate-x-12'
  | 'hover:tw-translate-x-12'
  | 'focus:tw-translate-x-12'
  | 'sm:tw-translate-x-16'
  | 'md:tw-translate-x-16'
  | 'lg:tw-translate-x-16'
  | 'xl:tw-translate-x-16'
  | 'hover:tw-translate-x-16'
  | 'focus:tw-translate-x-16'
  | 'sm:tw-translate-x-20'
  | 'md:tw-translate-x-20'
  | 'lg:tw-translate-x-20'
  | 'xl:tw-translate-x-20'
  | 'hover:tw-translate-x-20'
  | 'focus:tw-translate-x-20'
  | 'sm:tw-translate-x-24'
  | 'md:tw-translate-x-24'
  | 'lg:tw-translate-x-24'
  | 'xl:tw-translate-x-24'
  | 'hover:tw-translate-x-24'
  | 'focus:tw-translate-x-24'
  | 'sm:tw-translate-x-32'
  | 'md:tw-translate-x-32'
  | 'lg:tw-translate-x-32'
  | 'xl:tw-translate-x-32'
  | 'hover:tw-translate-x-32'
  | 'focus:tw-translate-x-32'
  | 'sm:tw-translate-x-40'
  | 'md:tw-translate-x-40'
  | 'lg:tw-translate-x-40'
  | 'xl:tw-translate-x-40'
  | 'hover:tw-translate-x-40'
  | 'focus:tw-translate-x-40'
  | 'sm:tw-translate-x-48'
  | 'md:tw-translate-x-48'
  | 'lg:tw-translate-x-48'
  | 'xl:tw-translate-x-48'
  | 'hover:tw-translate-x-48'
  | 'focus:tw-translate-x-48'
  | 'sm:tw-translate-x-56'
  | 'md:tw-translate-x-56'
  | 'lg:tw-translate-x-56'
  | 'xl:tw-translate-x-56'
  | 'hover:tw-translate-x-56'
  | 'focus:tw-translate-x-56'
  | 'sm:tw-translate-x-64'
  | 'md:tw-translate-x-64'
  | 'lg:tw-translate-x-64'
  | 'xl:tw-translate-x-64'
  | 'hover:tw-translate-x-64'
  | 'focus:tw-translate-x-64'
  | 'sm:tw-translate-x-px'
  | 'md:tw-translate-x-px'
  | 'lg:tw-translate-x-px'
  | 'xl:tw-translate-x-px'
  | 'hover:tw-translate-x-px'
  | 'focus:tw-translate-x-px'
  | 'sm:tw--translate-x-0'
  | 'md:tw--translate-x-0'
  | 'lg:tw--translate-x-0'
  | 'xl:tw--translate-x-0'
  | 'hover:tw--translate-x-0'
  | 'focus:tw--translate-x-0'
  | 'sm:tw--translate-x-1'
  | 'md:tw--translate-x-1'
  | 'lg:tw--translate-x-1'
  | 'xl:tw--translate-x-1'
  | 'hover:tw--translate-x-1'
  | 'focus:tw--translate-x-1'
  | 'sm:tw--translate-x-2'
  | 'md:tw--translate-x-2'
  | 'lg:tw--translate-x-2'
  | 'xl:tw--translate-x-2'
  | 'hover:tw--translate-x-2'
  | 'focus:tw--translate-x-2'
  | 'sm:tw--translate-x-3'
  | 'md:tw--translate-x-3'
  | 'lg:tw--translate-x-3'
  | 'xl:tw--translate-x-3'
  | 'hover:tw--translate-x-3'
  | 'focus:tw--translate-x-3'
  | 'sm:tw--translate-x-4'
  | 'md:tw--translate-x-4'
  | 'lg:tw--translate-x-4'
  | 'xl:tw--translate-x-4'
  | 'hover:tw--translate-x-4'
  | 'focus:tw--translate-x-4'
  | 'sm:tw--translate-x-5'
  | 'md:tw--translate-x-5'
  | 'lg:tw--translate-x-5'
  | 'xl:tw--translate-x-5'
  | 'hover:tw--translate-x-5'
  | 'focus:tw--translate-x-5'
  | 'sm:tw--translate-x-6'
  | 'md:tw--translate-x-6'
  | 'lg:tw--translate-x-6'
  | 'xl:tw--translate-x-6'
  | 'hover:tw--translate-x-6'
  | 'focus:tw--translate-x-6'
  | 'sm:tw--translate-x-8'
  | 'md:tw--translate-x-8'
  | 'lg:tw--translate-x-8'
  | 'xl:tw--translate-x-8'
  | 'hover:tw--translate-x-8'
  | 'focus:tw--translate-x-8'
  | 'sm:tw--translate-x-10'
  | 'md:tw--translate-x-10'
  | 'lg:tw--translate-x-10'
  | 'xl:tw--translate-x-10'
  | 'hover:tw--translate-x-10'
  | 'focus:tw--translate-x-10'
  | 'sm:tw--translate-x-12'
  | 'md:tw--translate-x-12'
  | 'lg:tw--translate-x-12'
  | 'xl:tw--translate-x-12'
  | 'hover:tw--translate-x-12'
  | 'focus:tw--translate-x-12'
  | 'sm:tw--translate-x-16'
  | 'md:tw--translate-x-16'
  | 'lg:tw--translate-x-16'
  | 'xl:tw--translate-x-16'
  | 'hover:tw--translate-x-16'
  | 'focus:tw--translate-x-16'
  | 'sm:tw--translate-x-20'
  | 'md:tw--translate-x-20'
  | 'lg:tw--translate-x-20'
  | 'xl:tw--translate-x-20'
  | 'hover:tw--translate-x-20'
  | 'focus:tw--translate-x-20'
  | 'sm:tw--translate-x-24'
  | 'md:tw--translate-x-24'
  | 'lg:tw--translate-x-24'
  | 'xl:tw--translate-x-24'
  | 'hover:tw--translate-x-24'
  | 'focus:tw--translate-x-24'
  | 'sm:tw--translate-x-32'
  | 'md:tw--translate-x-32'
  | 'lg:tw--translate-x-32'
  | 'xl:tw--translate-x-32'
  | 'hover:tw--translate-x-32'
  | 'focus:tw--translate-x-32'
  | 'sm:tw--translate-x-40'
  | 'md:tw--translate-x-40'
  | 'lg:tw--translate-x-40'
  | 'xl:tw--translate-x-40'
  | 'hover:tw--translate-x-40'
  | 'focus:tw--translate-x-40'
  | 'sm:tw--translate-x-48'
  | 'md:tw--translate-x-48'
  | 'lg:tw--translate-x-48'
  | 'xl:tw--translate-x-48'
  | 'hover:tw--translate-x-48'
  | 'focus:tw--translate-x-48'
  | 'sm:tw--translate-x-56'
  | 'md:tw--translate-x-56'
  | 'lg:tw--translate-x-56'
  | 'xl:tw--translate-x-56'
  | 'hover:tw--translate-x-56'
  | 'focus:tw--translate-x-56'
  | 'sm:tw--translate-x-64'
  | 'md:tw--translate-x-64'
  | 'lg:tw--translate-x-64'
  | 'xl:tw--translate-x-64'
  | 'hover:tw--translate-x-64'
  | 'focus:tw--translate-x-64'
  | 'sm:tw--translate-x-px'
  | 'md:tw--translate-x-px'
  | 'lg:tw--translate-x-px'
  | 'xl:tw--translate-x-px'
  | 'hover:tw--translate-x-px'
  | 'focus:tw--translate-x-px'
  | 'sm:tw--translate-x-full'
  | 'md:tw--translate-x-full'
  | 'lg:tw--translate-x-full'
  | 'xl:tw--translate-x-full'
  | 'hover:tw--translate-x-full'
  | 'focus:tw--translate-x-full'
  | 'sm:tw--translate-x-1/2'
  | 'md:tw--translate-x-1/2'
  | 'lg:tw--translate-x-1/2'
  | 'xl:tw--translate-x-1/2'
  | 'hover:tw--translate-x-1/2'
  | 'focus:tw--translate-x-1/2'
  | 'sm:tw-translate-x-1/2'
  | 'md:tw-translate-x-1/2'
  | 'lg:tw-translate-x-1/2'
  | 'xl:tw-translate-x-1/2'
  | 'hover:tw-translate-x-1/2'
  | 'focus:tw-translate-x-1/2'
  | 'sm:tw-translate-x-full'
  | 'md:tw-translate-x-full'
  | 'lg:tw-translate-x-full'
  | 'xl:tw-translate-x-full'
  | 'hover:tw-translate-x-full'
  | 'focus:tw-translate-x-full'
  | 'sm:tw-translate-y-0'
  | 'md:tw-translate-y-0'
  | 'lg:tw-translate-y-0'
  | 'xl:tw-translate-y-0'
  | 'hover:tw-translate-y-0'
  | 'focus:tw-translate-y-0'
  | 'sm:tw-translate-y-1'
  | 'md:tw-translate-y-1'
  | 'lg:tw-translate-y-1'
  | 'xl:tw-translate-y-1'
  | 'hover:tw-translate-y-1'
  | 'focus:tw-translate-y-1'
  | 'sm:tw-translate-y-2'
  | 'md:tw-translate-y-2'
  | 'lg:tw-translate-y-2'
  | 'xl:tw-translate-y-2'
  | 'hover:tw-translate-y-2'
  | 'focus:tw-translate-y-2'
  | 'sm:tw-translate-y-3'
  | 'md:tw-translate-y-3'
  | 'lg:tw-translate-y-3'
  | 'xl:tw-translate-y-3'
  | 'hover:tw-translate-y-3'
  | 'focus:tw-translate-y-3'
  | 'sm:tw-translate-y-4'
  | 'md:tw-translate-y-4'
  | 'lg:tw-translate-y-4'
  | 'xl:tw-translate-y-4'
  | 'hover:tw-translate-y-4'
  | 'focus:tw-translate-y-4'
  | 'sm:tw-translate-y-5'
  | 'md:tw-translate-y-5'
  | 'lg:tw-translate-y-5'
  | 'xl:tw-translate-y-5'
  | 'hover:tw-translate-y-5'
  | 'focus:tw-translate-y-5'
  | 'sm:tw-translate-y-6'
  | 'md:tw-translate-y-6'
  | 'lg:tw-translate-y-6'
  | 'xl:tw-translate-y-6'
  | 'hover:tw-translate-y-6'
  | 'focus:tw-translate-y-6'
  | 'sm:tw-translate-y-8'
  | 'md:tw-translate-y-8'
  | 'lg:tw-translate-y-8'
  | 'xl:tw-translate-y-8'
  | 'hover:tw-translate-y-8'
  | 'focus:tw-translate-y-8'
  | 'sm:tw-translate-y-10'
  | 'md:tw-translate-y-10'
  | 'lg:tw-translate-y-10'
  | 'xl:tw-translate-y-10'
  | 'hover:tw-translate-y-10'
  | 'focus:tw-translate-y-10'
  | 'sm:tw-translate-y-12'
  | 'md:tw-translate-y-12'
  | 'lg:tw-translate-y-12'
  | 'xl:tw-translate-y-12'
  | 'hover:tw-translate-y-12'
  | 'focus:tw-translate-y-12'
  | 'sm:tw-translate-y-16'
  | 'md:tw-translate-y-16'
  | 'lg:tw-translate-y-16'
  | 'xl:tw-translate-y-16'
  | 'hover:tw-translate-y-16'
  | 'focus:tw-translate-y-16'
  | 'sm:tw-translate-y-20'
  | 'md:tw-translate-y-20'
  | 'lg:tw-translate-y-20'
  | 'xl:tw-translate-y-20'
  | 'hover:tw-translate-y-20'
  | 'focus:tw-translate-y-20'
  | 'sm:tw-translate-y-24'
  | 'md:tw-translate-y-24'
  | 'lg:tw-translate-y-24'
  | 'xl:tw-translate-y-24'
  | 'hover:tw-translate-y-24'
  | 'focus:tw-translate-y-24'
  | 'sm:tw-translate-y-32'
  | 'md:tw-translate-y-32'
  | 'lg:tw-translate-y-32'
  | 'xl:tw-translate-y-32'
  | 'hover:tw-translate-y-32'
  | 'focus:tw-translate-y-32'
  | 'sm:tw-translate-y-40'
  | 'md:tw-translate-y-40'
  | 'lg:tw-translate-y-40'
  | 'xl:tw-translate-y-40'
  | 'hover:tw-translate-y-40'
  | 'focus:tw-translate-y-40'
  | 'sm:tw-translate-y-48'
  | 'md:tw-translate-y-48'
  | 'lg:tw-translate-y-48'
  | 'xl:tw-translate-y-48'
  | 'hover:tw-translate-y-48'
  | 'focus:tw-translate-y-48'
  | 'sm:tw-translate-y-56'
  | 'md:tw-translate-y-56'
  | 'lg:tw-translate-y-56'
  | 'xl:tw-translate-y-56'
  | 'hover:tw-translate-y-56'
  | 'focus:tw-translate-y-56'
  | 'sm:tw-translate-y-64'
  | 'md:tw-translate-y-64'
  | 'lg:tw-translate-y-64'
  | 'xl:tw-translate-y-64'
  | 'hover:tw-translate-y-64'
  | 'focus:tw-translate-y-64'
  | 'sm:tw-translate-y-px'
  | 'md:tw-translate-y-px'
  | 'lg:tw-translate-y-px'
  | 'xl:tw-translate-y-px'
  | 'hover:tw-translate-y-px'
  | 'focus:tw-translate-y-px'
  | 'sm:tw--translate-y-0'
  | 'md:tw--translate-y-0'
  | 'lg:tw--translate-y-0'
  | 'xl:tw--translate-y-0'
  | 'hover:tw--translate-y-0'
  | 'focus:tw--translate-y-0'
  | 'sm:tw--translate-y-1'
  | 'md:tw--translate-y-1'
  | 'lg:tw--translate-y-1'
  | 'xl:tw--translate-y-1'
  | 'hover:tw--translate-y-1'
  | 'focus:tw--translate-y-1'
  | 'sm:tw--translate-y-2'
  | 'md:tw--translate-y-2'
  | 'lg:tw--translate-y-2'
  | 'xl:tw--translate-y-2'
  | 'hover:tw--translate-y-2'
  | 'focus:tw--translate-y-2'
  | 'sm:tw--translate-y-3'
  | 'md:tw--translate-y-3'
  | 'lg:tw--translate-y-3'
  | 'xl:tw--translate-y-3'
  | 'hover:tw--translate-y-3'
  | 'focus:tw--translate-y-3'
  | 'sm:tw--translate-y-4'
  | 'md:tw--translate-y-4'
  | 'lg:tw--translate-y-4'
  | 'xl:tw--translate-y-4'
  | 'hover:tw--translate-y-4'
  | 'focus:tw--translate-y-4'
  | 'sm:tw--translate-y-5'
  | 'md:tw--translate-y-5'
  | 'lg:tw--translate-y-5'
  | 'xl:tw--translate-y-5'
  | 'hover:tw--translate-y-5'
  | 'focus:tw--translate-y-5'
  | 'sm:tw--translate-y-6'
  | 'md:tw--translate-y-6'
  | 'lg:tw--translate-y-6'
  | 'xl:tw--translate-y-6'
  | 'hover:tw--translate-y-6'
  | 'focus:tw--translate-y-6'
  | 'sm:tw--translate-y-8'
  | 'md:tw--translate-y-8'
  | 'lg:tw--translate-y-8'
  | 'xl:tw--translate-y-8'
  | 'hover:tw--translate-y-8'
  | 'focus:tw--translate-y-8'
  | 'sm:tw--translate-y-10'
  | 'md:tw--translate-y-10'
  | 'lg:tw--translate-y-10'
  | 'xl:tw--translate-y-10'
  | 'hover:tw--translate-y-10'
  | 'focus:tw--translate-y-10'
  | 'sm:tw--translate-y-12'
  | 'md:tw--translate-y-12'
  | 'lg:tw--translate-y-12'
  | 'xl:tw--translate-y-12'
  | 'hover:tw--translate-y-12'
  | 'focus:tw--translate-y-12'
  | 'sm:tw--translate-y-16'
  | 'md:tw--translate-y-16'
  | 'lg:tw--translate-y-16'
  | 'xl:tw--translate-y-16'
  | 'hover:tw--translate-y-16'
  | 'focus:tw--translate-y-16'
  | 'sm:tw--translate-y-20'
  | 'md:tw--translate-y-20'
  | 'lg:tw--translate-y-20'
  | 'xl:tw--translate-y-20'
  | 'hover:tw--translate-y-20'
  | 'focus:tw--translate-y-20'
  | 'sm:tw--translate-y-24'
  | 'md:tw--translate-y-24'
  | 'lg:tw--translate-y-24'
  | 'xl:tw--translate-y-24'
  | 'hover:tw--translate-y-24'
  | 'focus:tw--translate-y-24'
  | 'sm:tw--translate-y-32'
  | 'md:tw--translate-y-32'
  | 'lg:tw--translate-y-32'
  | 'xl:tw--translate-y-32'
  | 'hover:tw--translate-y-32'
  | 'focus:tw--translate-y-32'
  | 'sm:tw--translate-y-40'
  | 'md:tw--translate-y-40'
  | 'lg:tw--translate-y-40'
  | 'xl:tw--translate-y-40'
  | 'hover:tw--translate-y-40'
  | 'focus:tw--translate-y-40'
  | 'sm:tw--translate-y-48'
  | 'md:tw--translate-y-48'
  | 'lg:tw--translate-y-48'
  | 'xl:tw--translate-y-48'
  | 'hover:tw--translate-y-48'
  | 'focus:tw--translate-y-48'
  | 'sm:tw--translate-y-56'
  | 'md:tw--translate-y-56'
  | 'lg:tw--translate-y-56'
  | 'xl:tw--translate-y-56'
  | 'hover:tw--translate-y-56'
  | 'focus:tw--translate-y-56'
  | 'sm:tw--translate-y-64'
  | 'md:tw--translate-y-64'
  | 'lg:tw--translate-y-64'
  | 'xl:tw--translate-y-64'
  | 'hover:tw--translate-y-64'
  | 'focus:tw--translate-y-64'
  | 'sm:tw--translate-y-px'
  | 'md:tw--translate-y-px'
  | 'lg:tw--translate-y-px'
  | 'xl:tw--translate-y-px'
  | 'hover:tw--translate-y-px'
  | 'focus:tw--translate-y-px'
  | 'sm:tw--translate-y-full'
  | 'md:tw--translate-y-full'
  | 'lg:tw--translate-y-full'
  | 'xl:tw--translate-y-full'
  | 'hover:tw--translate-y-full'
  | 'focus:tw--translate-y-full'
  | 'sm:tw--translate-y-1/2'
  | 'md:tw--translate-y-1/2'
  | 'lg:tw--translate-y-1/2'
  | 'xl:tw--translate-y-1/2'
  | 'hover:tw--translate-y-1/2'
  | 'focus:tw--translate-y-1/2'
  | 'sm:tw-translate-y-1/2'
  | 'md:tw-translate-y-1/2'
  | 'lg:tw-translate-y-1/2'
  | 'xl:tw-translate-y-1/2'
  | 'hover:tw-translate-y-1/2'
  | 'focus:tw-translate-y-1/2'
  | 'sm:tw-translate-y-full'
  | 'md:tw-translate-y-full'
  | 'lg:tw-translate-y-full'
  | 'xl:tw-translate-y-full'
  | 'hover:tw-translate-y-full'
  | 'focus:tw-translate-y-full'
  | 'sm:tw-skew-x-0'
  | 'md:tw-skew-x-0'
  | 'lg:tw-skew-x-0'
  | 'xl:tw-skew-x-0'
  | 'hover:tw-skew-x-0'
  | 'focus:tw-skew-x-0'
  | 'sm:tw-skew-x-1'
  | 'md:tw-skew-x-1'
  | 'lg:tw-skew-x-1'
  | 'xl:tw-skew-x-1'
  | 'hover:tw-skew-x-1'
  | 'focus:tw-skew-x-1'
  | 'sm:tw-skew-x-2'
  | 'md:tw-skew-x-2'
  | 'lg:tw-skew-x-2'
  | 'xl:tw-skew-x-2'
  | 'hover:tw-skew-x-2'
  | 'focus:tw-skew-x-2'
  | 'sm:tw-skew-x-3'
  | 'md:tw-skew-x-3'
  | 'lg:tw-skew-x-3'
  | 'xl:tw-skew-x-3'
  | 'hover:tw-skew-x-3'
  | 'focus:tw-skew-x-3'
  | 'sm:tw-skew-x-6'
  | 'md:tw-skew-x-6'
  | 'lg:tw-skew-x-6'
  | 'xl:tw-skew-x-6'
  | 'hover:tw-skew-x-6'
  | 'focus:tw-skew-x-6'
  | 'sm:tw-skew-x-12'
  | 'md:tw-skew-x-12'
  | 'lg:tw-skew-x-12'
  | 'xl:tw-skew-x-12'
  | 'hover:tw-skew-x-12'
  | 'focus:tw-skew-x-12'
  | 'sm:tw--skew-x-12'
  | 'md:tw--skew-x-12'
  | 'lg:tw--skew-x-12'
  | 'xl:tw--skew-x-12'
  | 'hover:tw--skew-x-12'
  | 'focus:tw--skew-x-12'
  | 'sm:tw--skew-x-6'
  | 'md:tw--skew-x-6'
  | 'lg:tw--skew-x-6'
  | 'xl:tw--skew-x-6'
  | 'hover:tw--skew-x-6'
  | 'focus:tw--skew-x-6'
  | 'sm:tw--skew-x-3'
  | 'md:tw--skew-x-3'
  | 'lg:tw--skew-x-3'
  | 'xl:tw--skew-x-3'
  | 'hover:tw--skew-x-3'
  | 'focus:tw--skew-x-3'
  | 'sm:tw--skew-x-2'
  | 'md:tw--skew-x-2'
  | 'lg:tw--skew-x-2'
  | 'xl:tw--skew-x-2'
  | 'hover:tw--skew-x-2'
  | 'focus:tw--skew-x-2'
  | 'sm:tw--skew-x-1'
  | 'md:tw--skew-x-1'
  | 'lg:tw--skew-x-1'
  | 'xl:tw--skew-x-1'
  | 'hover:tw--skew-x-1'
  | 'focus:tw--skew-x-1'
  | 'sm:tw-skew-y-0'
  | 'md:tw-skew-y-0'
  | 'lg:tw-skew-y-0'
  | 'xl:tw-skew-y-0'
  | 'hover:tw-skew-y-0'
  | 'focus:tw-skew-y-0'
  | 'sm:tw-skew-y-1'
  | 'md:tw-skew-y-1'
  | 'lg:tw-skew-y-1'
  | 'xl:tw-skew-y-1'
  | 'hover:tw-skew-y-1'
  | 'focus:tw-skew-y-1'
  | 'sm:tw-skew-y-2'
  | 'md:tw-skew-y-2'
  | 'lg:tw-skew-y-2'
  | 'xl:tw-skew-y-2'
  | 'hover:tw-skew-y-2'
  | 'focus:tw-skew-y-2'
  | 'sm:tw-skew-y-3'
  | 'md:tw-skew-y-3'
  | 'lg:tw-skew-y-3'
  | 'xl:tw-skew-y-3'
  | 'hover:tw-skew-y-3'
  | 'focus:tw-skew-y-3'
  | 'sm:tw-skew-y-6'
  | 'md:tw-skew-y-6'
  | 'lg:tw-skew-y-6'
  | 'xl:tw-skew-y-6'
  | 'hover:tw-skew-y-6'
  | 'focus:tw-skew-y-6'
  | 'sm:tw-skew-y-12'
  | 'md:tw-skew-y-12'
  | 'lg:tw-skew-y-12'
  | 'xl:tw-skew-y-12'
  | 'hover:tw-skew-y-12'
  | 'focus:tw-skew-y-12'
  | 'sm:tw--skew-y-12'
  | 'md:tw--skew-y-12'
  | 'lg:tw--skew-y-12'
  | 'xl:tw--skew-y-12'
  | 'hover:tw--skew-y-12'
  | 'focus:tw--skew-y-12'
  | 'sm:tw--skew-y-6'
  | 'md:tw--skew-y-6'
  | 'lg:tw--skew-y-6'
  | 'xl:tw--skew-y-6'
  | 'hover:tw--skew-y-6'
  | 'focus:tw--skew-y-6'
  | 'sm:tw--skew-y-3'
  | 'md:tw--skew-y-3'
  | 'lg:tw--skew-y-3'
  | 'xl:tw--skew-y-3'
  | 'hover:tw--skew-y-3'
  | 'focus:tw--skew-y-3'
  | 'sm:tw--skew-y-2'
  | 'md:tw--skew-y-2'
  | 'lg:tw--skew-y-2'
  | 'xl:tw--skew-y-2'
  | 'hover:tw--skew-y-2'
  | 'focus:tw--skew-y-2'
  | 'sm:tw--skew-y-1'
  | 'md:tw--skew-y-1'
  | 'lg:tw--skew-y-1'
  | 'xl:tw--skew-y-1'
  | 'hover:tw--skew-y-1'
  | 'focus:tw--skew-y-1'
  | 'sm:tw-transition-none'
  | 'md:tw-transition-none'
  | 'lg:tw-transition-none'
  | 'xl:tw-transition-none'
  | 'sm:tw-transition-all'
  | 'md:tw-transition-all'
  | 'lg:tw-transition-all'
  | 'xl:tw-transition-all'
  | 'sm:tw-transition'
  | 'md:tw-transition'
  | 'lg:tw-transition'
  | 'xl:tw-transition'
  | 'sm:tw-transition-colors'
  | 'md:tw-transition-colors'
  | 'lg:tw-transition-colors'
  | 'xl:tw-transition-colors'
  | 'sm:tw-transition-opacity'
  | 'md:tw-transition-opacity'
  | 'lg:tw-transition-opacity'
  | 'xl:tw-transition-opacity'
  | 'sm:tw-transition-shadow'
  | 'md:tw-transition-shadow'
  | 'lg:tw-transition-shadow'
  | 'xl:tw-transition-shadow'
  | 'sm:tw-transition-transform'
  | 'md:tw-transition-transform'
  | 'lg:tw-transition-transform'
  | 'xl:tw-transition-transform'
  | 'sm:tw-ease-linear'
  | 'md:tw-ease-linear'
  | 'lg:tw-ease-linear'
  | 'xl:tw-ease-linear'
  | 'sm:tw-ease-in'
  | 'md:tw-ease-in'
  | 'lg:tw-ease-in'
  | 'xl:tw-ease-in'
  | 'sm:tw-ease-out'
  | 'md:tw-ease-out'
  | 'lg:tw-ease-out'
  | 'xl:tw-ease-out'
  | 'sm:tw-ease-in-out'
  | 'md:tw-ease-in-out'
  | 'lg:tw-ease-in-out'
  | 'xl:tw-ease-in-out'
  | 'sm:tw-duration-75'
  | 'md:tw-duration-75'
  | 'lg:tw-duration-75'
  | 'xl:tw-duration-75'
  | 'sm:tw-duration-100'
  | 'md:tw-duration-100'
  | 'lg:tw-duration-100'
  | 'xl:tw-duration-100'
  | 'sm:tw-duration-150'
  | 'md:tw-duration-150'
  | 'lg:tw-duration-150'
  | 'xl:tw-duration-150'
  | 'sm:tw-duration-200'
  | 'md:tw-duration-200'
  | 'lg:tw-duration-200'
  | 'xl:tw-duration-200'
  | 'sm:tw-duration-300'
  | 'md:tw-duration-300'
  | 'lg:tw-duration-300'
  | 'xl:tw-duration-300'
  | 'sm:tw-duration-500'
  | 'md:tw-duration-500'
  | 'lg:tw-duration-500'
  | 'xl:tw-duration-500'
  | 'sm:tw-duration-700'
  | 'md:tw-duration-700'
  | 'lg:tw-duration-700'
  | 'xl:tw-duration-700'
  | 'sm:tw-duration-1000'
  | 'md:tw-duration-1000'
  | 'lg:tw-duration-1000'
  | 'xl:tw-duration-1000'
  | 'sm:tw-delay-75'
  | 'md:tw-delay-75'
  | 'lg:tw-delay-75'
  | 'xl:tw-delay-75'
  | 'sm:tw-delay-100'
  | 'md:tw-delay-100'
  | 'lg:tw-delay-100'
  | 'xl:tw-delay-100'
  | 'sm:tw-delay-150'
  | 'md:tw-delay-150'
  | 'lg:tw-delay-150'
  | 'xl:tw-delay-150'
  | 'sm:tw-delay-200'
  | 'md:tw-delay-200'
  | 'lg:tw-delay-200'
  | 'xl:tw-delay-200'
  | 'sm:tw-delay-300'
  | 'md:tw-delay-300'
  | 'lg:tw-delay-300'
  | 'xl:tw-delay-300'
  | 'sm:tw-delay-500'
  | 'md:tw-delay-500'
  | 'lg:tw-delay-500'
  | 'xl:tw-delay-500'
  | 'sm:tw-delay-700'
  | 'md:tw-delay-700'
  | 'lg:tw-delay-700'
  | 'xl:tw-delay-700'
  | 'sm:tw-delay-1000'
  | 'md:tw-delay-1000'
  | 'lg:tw-delay-1000'
  | 'xl:tw-delay-1000'
  | 'sm:tw-animate-none'
  | 'md:tw-animate-none'
  | 'lg:tw-animate-none'
  | 'xl:tw-animate-none'
  | 'sm:tw-animate-spin'
  | 'md:tw-animate-spin'
  | 'lg:tw-animate-spin'
  | 'xl:tw-animate-spin'
  | 'sm:tw-animate-ping'
  | 'md:tw-animate-ping'
  | 'lg:tw-animate-ping'
  | 'xl:tw-animate-ping'
  | 'sm:tw-animate-pulse'
  | 'md:tw-animate-pulse'
  | 'lg:tw-animate-pulse'
  | 'xl:tw-animate-pulse'
  | 'sm:tw-animate-bounce'
  | 'md:tw-animate-bounce'
  | 'lg:tw-animate-bounce'
  | 'xl:tw-animate-bounce'
  | 'sm:tw-sr-only'
  | 'md:tw-sr-only'
  | 'lg:tw-sr-only'
  | 'xl:tw-sr-only'
  | 'focus:tw-sr-only'
  | 'sm:tw-not-sr-only'
  | 'md:tw-not-sr-only'
  | 'lg:tw-not-sr-only'
  | 'xl:tw-not-sr-only'
  | 'focus:tw-not-sr-only';

export type TCustomFormsPluginClasses =
  | 'form-input'
  | 'form-textarea'
  | 'form-select'
  | 'form-multiselect'
  | 'form-checkbox'
  | 'form-radio';

export type TTypographyPluginClasses =
  | 'prose'
  | 'prose-sm'
  | 'prose-lg'
  | 'prose-xl'
  | 'prose-2xl';

export type TClasses =
  | TLayout
  | TTypography
  | TBackgrounds
  | TBorders
  | TFlexBox
  | TGrid
  | TSpacing
  | TSizing
  | TTables
  | TEffects
  | TTransforms
  | TTransitionsAndAnimations
  | TInteractivity
  | TSVG
  | TAccessibility
  | TPseudoClasses;

export type TTailwindString = 'TAILWIND_STRING';

export type TKey = TClasses | TTailwindString;

export type TArg =
  | TClasses
  | null
  | undefined
  | { [key in TKey]?: boolean }
  | TTailwindString;

export type TTailwind = (...args: TArg[]) => TTailwindString;

export const classnames: TTailwind = classnamesLib as any;

export const tw = classnames;

export default tw;
