import 'cross-fetch/polyfill';

import MapiClient from '@mapbox/mapbox-sdk/lib/classes/mapi-client';
import Swagger from 'swagger-client';

import { ClientResponse, UFApiClient } from 'uf-api';
import { UFApiClient as UFDataApiClient } from 'uf-api-data';
import { UFApiClient as UFManagementApiClient } from 'uf-api-management';
import { normalizeError } from 'uf/base/swagger';

import { ErrorAny } from './types';

type EventListenerList = EventListener[];

export class ClientBase {
  eventListeners: Record<string, EventListenerList>;

  constructor() {
    this.eventListeners = {};
  }
  /**
   * Mimic the DOM event system:
   * https://developer.mozilla.org/en-US/docs/Web/Guide/Events/Creating_and_triggering_events
   *
   * Among other things, this lets us generalize error handling and
   * allow websockets to re-subscribe after a disconnect.
   */
  addEventListener(type, callback) {
    if (!(type in this.eventListeners)) {
      this.eventListeners[type] = [];
    }
    this.eventListeners[type].push(callback);
  }

  dispatchEvent(event) {
    if (!(event.type in this.eventListeners)) {
      return;
    }

    // Should these be fired asynchronously?
    const listeners = this.eventListeners[event.type];
    listeners.forEach(listener => {
      try {
        listener(event);
      } catch (error) {
        console.error(error);
      }
    });
  }
}

export interface ApiWrapper {
  requestInterceptor: (apiReq: SwaggerRequest) => void;
  apis: UFApiClient;
}

export interface ManagementApiWrapper {
  requestInterceptor: (apiReq: SwaggerRequest) => void;
  apis: UFManagementApiClient;
}

export interface DataApiWrapper {
  requestInterceptor: (apiReq: SwaggerRequest) => void;
  apis: UFDataApiClient;
}

// this is a bit of a dummy class meant to maintain global state of all outstanding requests.
// This will be used so that we can have:
// - UI for showing that there are requests outstanding
// - UI for showing errors
// - unifying/merging duplicate requests
// - Common API behavior (i.e. retrieving entities, filters, etc)
export class SwaggerClient extends ClientBase {
  cookie?: string;

  private swaggerApiPromise: Promise<ApiWrapper>;

  private swaggerManagementPromise: Promise<ManagementApiWrapper>;
  private swaggerDataPromise: Promise<DataApiWrapper>;

  socketPromise: Promise<{}>;
  socket: Partial<WebSocket>;

  requestInterceptors: ((apiReq: SwaggerRequest) => void)[] = [];

  constructor(
    origin: string,
    swaggerClient: ApiWrapper,
    swaggerManagementClient: ManagementApiWrapper,
    swaggerDataClient: DataApiWrapper,
    cookie: string,
  ) {
    super();
    this.cookie = cookie;

    // this is null when there is an error fetching swagger.json
    if (swaggerClient) {
      swaggerClient.requestInterceptor = this.requestInterceptor.bind(this);
    }

    // this is null when there is an error fetching swagger.json
    if (swaggerManagementClient) {
      swaggerManagementClient.requestInterceptor =
        this.requestInterceptor.bind(this);
    }

    // this is null when there is an error fetching swagger.json
    if (swaggerDataClient) {
      swaggerDataClient.requestInterceptor = this.requestInterceptor.bind(this);
    }

    // TODO: move this somewhere more generalized
    this.addRequestInterceptor(req => {
      req.headers['UF-Frontend-Version'] = __BUILD_INFO__.git_commit;
      return req;
    });

    // hack, backwards compatibility
    this.swaggerApiPromise = swaggerClient
      ? Promise.resolve(swaggerClient)
      : Promise.reject(
          new Error(`Unable to connect to API server at ${origin}`),
        );

    this.swaggerManagementPromise = swaggerManagementClient
      ? Promise.resolve(swaggerManagementClient)
      : Promise.reject(
          new Error(`Unable to connect to API Management server at ${origin}`),
        );

    this.swaggerDataPromise = swaggerDataClient
      ? Promise.resolve(swaggerDataClient)
      : Promise.reject(
          new Error(`Unable to connect to API Management server at ${origin}`),
        );
  }

  requestInterceptor(apiReq: SwaggerRequest) {
    this.requestInterceptors.forEach(requestInterceptor => {
      requestInterceptor(apiReq);
    });
  }
  // Returns a promise that resolves when the swagger client is ready.
  // This can be called repeatedly to keep calling swagger APIs.
  //
  // To use:
  //   client.clientReady().then(
  //     swagger => swagger.apis.version.get_version()
  //       .then(version => ...));
  //
  // or, thanks to the magic of promises:
  //   client.clientReady()
  //     .then(swagger => swagger.apis.version.get_version())
  //     .then(version => ...);
  clientReady(): Promise<ApiWrapper> {
    // TODO: remove this and let people call swagger directly
    return this.swaggerApiPromise;
  }

  swaggerManagement() {
    // TODO: remove this and let people call swagger directly
    return this.swaggerManagementPromise;
  }

  swaggerData() {
    // TODO: remove this and let people call swagger directly
    return this.swaggerDataPromise;
  }

  /**
   * Add a request interceptor, which can modify the request before it goes out
   * over the wire. Use this to inject/modify headers, etc.
   */
  addRequestInterceptor(method: (apiReq: SwaggerRequest) => void) {
    this.requestInterceptors.push(method);
  }
}

interface SwaggerRequest {
  headers: Record<string, string>;
  url: string;

  query: any;
}
export interface SwaggerThunkExtra {
  client: SwaggerClient;
  mapi: MapiClient;
}

interface SwaggerClientOptions {
  origin: string;
  cookie?: string;
  specApi: object;
  specManagement: object;
  specData: object;
}

function generateSwaggerLibraryClient<W>(
  resolvedSpec: any,
  specUrl: string,
  authorizations: { withUFCookie: string },
): Promise<W> {
  if (!resolvedSpec) {
    return null;
  }

  Swagger.http.withCredentials = true;
  return Swagger({
    spec: resolvedSpec,
    url: specUrl,
    authorizations,
    enableCookies: true,
    usePromise: true,
  });
}

/**
 * Asynchronously create a swagger client
 */
export async function createSwaggerClient({
  origin,
  cookie,
  specApi,
  specManagement,
  specData,
}: SwaggerClientOptions) {
  const swaggerApiSpecUrl = `${origin}/api/swagger.json`;
  const swaggerManagementSpecUrl = `${origin}/api-management/swagger.json`;
  const swaggerDataSpecUrl = `${origin}/api-data/swagger.json`;

  const authorizations = cookie ? { withUFCookie: cookie } : null;
  const swaggerClientApiGet = generateSwaggerLibraryClient<ApiWrapper>(
    specApi,
    swaggerApiSpecUrl,
    authorizations,
  );
  const swaggerClientManagementGet =
    generateSwaggerLibraryClient<ManagementApiWrapper>(
      specManagement,
      swaggerManagementSpecUrl,
      authorizations,
    );

  const swaggerClientDataGet = generateSwaggerLibraryClient<DataApiWrapper>(
    specData,
    swaggerDataSpecUrl,
    authorizations,
  );

  const [swaggerClientApi, swaggerClientManagement, swaggerClientData] =
    await Promise.all([
      swaggerClientApiGet,
      swaggerClientManagementGet,
      swaggerClientDataGet,
    ]);
  return new SwaggerClient(
    origin,
    swaggerClientApi,
    swaggerClientManagement,
    swaggerClientData,
    cookie,
  );
}

/**
 * Convert an XHR error object into something akin to a
 * JavaScript exception object. This makes an assumption that the XHR
 * contains JSON as described in https://tools.ietf.org/html/rfc7807
 * and possibly has the specialized key `traceback`.
 */
export function xhrErrorToJSError(error: ErrorAny) {
  if (typeof error === 'string') {
    return {
      message: error,
    };
  }

  if (!error.statusText) {
    return error;
  }

  // For XHR errors, parse the results from the server.
  const serverError = JSON.parse(error.statusText);
  const errorObject = {
    message: serverError.title,
    detail: serverError.detail,
    stack: serverError.traceback,
  };

  return errorObject;
}

/**
 * A function which resolves a swagger client to one of its API calls
 */
type ResolveApiCaller<P, R> = (
  client: UFApiClient,
) => (params: P, extraHttpRequestParams?: any) => Promise<ClientResponse<R>>;

/**
 * A function that can be used to call a swagger API call.
 */
type ResolvedApiResult<P, R> = (
  params: P,
) => (client: SwaggerClient) => Promise<R>;

type RawResolvedApiResult<P, R> = (
  params: P,
) => (client: SwaggerClient) => Promise<ClientResponse<R>>;

/**
 * For providing static responses to non-200 HTTP status codes.
 */
interface ResultMap<R> {
  [statusCode: number]: (error: any) => R;
}
/**
 * Create a dispatcher for use with dispatchAsyncAction
 *
 * Generally this looks like
 *
 * const getFoo = makeAsyncApiCaller(client => client.apis.tagName.get_foo);
 *
 * @param getApi A function that maps a swagger client to an API call
 */
export function makeAsyncApiCaller<P, R>(
  getApi: ResolveApiCaller<P, R>,
  resultMap: ResultMap<R> = {},
): ResolvedApiResult<P, R> {
  return (params: P) => async (client: SwaggerClient) => {
    try {
      return await callSwagger<P, R>(getApi, params, client).then(
        result => result.obj,
      );
    } catch (ex) {
      if (ex.status in resultMap) {
        const transform = resultMap[ex.status];
        return transform(ex);
      }
      return normalizeError(ex);
    }
  };
}

/**
 * Exactly like `makeAsyncApiCaller` above, but does not unpack the
 * `ClientResponse`. Temporary until we can figure out a better way to give the
 * caller access to headers.
 */
export function makeAsyncApiCallerRaw<P, R>(
  getApi: ResolveApiCaller<P, R>,
  resultMap: ResultMap<R> = {},
): RawResolvedApiResult<P, R> {
  return (params: P) => async (client: SwaggerClient) => {
    try {
      return await callSwagger<P, R>(getApi, params, client);
    } catch (ex) {
      if (ex.status in resultMap) {
        const transform = resultMap[ex.status];

        return { obj: transform(resultMap[ex.status]) };
      }
      return normalizeError(ex);
    }
  };
}

async function callSwagger<P, R>(
  getApi: ResolveApiCaller<P, R>,
  params: P,
  client: SwaggerClient,
) {
  const swagger = await client.clientReady();
  const call = getApi(swagger.apis);
  const result = await call(params);
  return result;
}
