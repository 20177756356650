import {
  CuratedColumnSymbology,
  CUSTOM_THEME,
  InterpolationTypes,
  PaintColorTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/public/dataset/greeninfo_cced_2018': {
    pubaccess: [
      {
        type: SymbologyTypes.FILL,
        paint: {
          'fill-color': {
            property: 'pubaccess',
            type: PaintColorTypes.CATEGORICAL,
            stops: [
              {
                value: 'Closed',
                color: '#9f0500',
              },
              {
                value: 'Open Access',
                color: '#68bc00',
              },
              {
                value: 'Restricted Access',
                color: '#fcc400',
              },
            ],
            default: '#d62728',
          },
          'fill-opacity': 0.8,
        },
        display_hints: {
          scale: SymbologyScales.CATEGORICAL,
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          theme: CUSTOM_THEME,
        },
      },
      {
        type: SymbologyTypes.LINE,
        paint: {
          'line-color': {
            property: 'pubaccess',
            type: PaintColorTypes.CATEGORICAL,
            stops: [
              {
                value: 'Closed',
                color: '#1f77b4',
              },
              {
                value: 'Open Access',
                color: '#ff7f0e',
              },
              {
                value: 'Restricted Access',
                color: '#2ca02c',
              },
            ],
            default: '#d62728',
          },
          'line-width': 1,
          'line-opacity': 0.8,
        },
        display_hints: {
          scale: SymbologyScales.CATEGORICAL,
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          theme: 'd3:schemeCategory10',
        },
      },
    ],
    duration: [
      {
        type: SymbologyTypes.FILL,
        paint: {
          'fill-color': {
            property: 'duration',
            type: PaintColorTypes.CATEGORICAL,
            stops: [
              {
                value: 'Permanent',
                color: '#16a5a5',
              },
              {
                value: 'Temporary',
                color: '#fcc400',
              },
              {
                value: 'Unknown',
                color: '#fe9200',
              },
            ],
            default: '#d62728',
          },
          'fill-opacity': 0.9,
        },
        display_hints: {
          scale: SymbologyScales.CATEGORICAL,
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          theme: 'custom',
        },
      },
      {
        type: SymbologyTypes.LINE,
        paint: {
          'line-color': {
            property: 'duration',
            type: PaintColorTypes.CATEGORICAL,
            stops: [
              {
                value: 'Permanent',
                color: '#1f77b4',
              },
              {
                value: 'Temporary',
                color: '#ff7f0e',
              },
              {
                value: 'Unknown',
                color: '#2ca02c',
              },
            ],
            default: '#d62728',
          },
          'line-width': 0.5,
          'line-opacity': 0.9,
        },
        display_hints: {
          scale: SymbologyScales.CATEGORICAL,
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          theme: 'd3:schemeCategory10',
        },
      },
    ],
  },
};

export default symbologies;
