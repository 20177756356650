import { User } from 'uf-api';
import {
  makeKeyedListActionTypes,
  UpsertKeyedListItemAction,
} from 'uf/base/keyedListActionHelpers';
import { CollaborationMessage } from 'uf/collaboration/MessageTypes';
import { ProjectId } from 'uf/projects';

export const RECEIVE_COLLABORATION_MESSAGE = 'uf/collaboration/RECEIVE';

export interface ReceiveCollaborationMessage<T> {
  type: typeof RECEIVE_COLLABORATION_MESSAGE;
  message: CollaborationMessage<T>;
}

export const presenceActionTypes = makeKeyedListActionTypes(
  'uf/collaboration/PRESENCE',
);

export interface UpdatePresence
  extends UpsertKeyedListItemAction<User, 'projectId'> {
  type: typeof presenceActionTypes.UPSERT;
  projectId: ProjectId;
}

export const SEND_COLLABORATION_MESSAGE = 'uf/collaboration/SEND';

export interface SendCollaborationMessage<T> {
  type: typeof SEND_COLLABORATION_MESSAGE;
  message: CollaborationMessage<T>;
}
