import { enqueueMapExportActionTypes } from 'uf-api/api/mapexport.service';
import {
  MapExportControlSettings,
  MapExportDisplaySettings,
  MapExportOutputSettings,
  MapExportRequest,
} from 'uf-api/model/models';
import { downloadUrl } from 'uf/base/download';
import { Dimensions, DimensionType, UFLngLatBounds } from 'uf/base/map';
import { risonEncode } from 'uf/base/rison';
import { dispatchAsyncAction } from 'uf/data/loader';
import { LayerId } from 'uf/layers';
import { MapViewport } from 'uf/map';
import { MapExportTitle } from 'uf/mapexport/state';
import { ProjectId } from 'uf/projects';

import {
  CLEAR_MAP_EXPORT_LEGEND_LAYERS,
  DOWNLOAD_STARTED,
  MapExportActions,
  RESET_MAP_EXPORT,
  SET_EXPORT_FORMAT,
  SET_INCLUDE_BASEMAP,
  SET_LEGEND_CLASSES_TO_INCLUDE,
  SET_LEGEND_PLACEMENT,
  SET_MAP_COMPASS_VISIBLE,
  SET_MAP_DIMENSION_TYPE,
  SET_MAP_DIMENSIONS,
  SET_MAP_EXPORT_BOOKMARK,
  SET_MAP_EXPORT_BOUNDS,
  SET_MAP_EXPORT_LEGEND_LAYER,
  SET_MAP_EXPORT_LOGO,
  SET_MAP_EXPORT_LOGO_VISIBLE,
  SET_MAP_EXPORT_NUM_GEOMETRIES,
  SET_MAP_EXPORT_REQUESTED_BOUNDS,
  SET_MAP_PHYSICAL_DIMENSIONS,
  SET_MAP_REQUESTED_SCALE,
  SET_MAP_RESOLUTION,
  SET_MAP_SCALE,
  SET_MAP_SCALE_RATIO,
  SET_MAP_SCALEBAR_VISIBLE,
  SET_MAP_TITLE,
  SET_MAP_TITLE_VISIBLE,
  SET_ORIENTATION,
  UPDATE_MAP_EXPORT_VIEWPORT,
} from './ActionTypes';
import { startMapExportAPI } from './apis';

export function downloadMapFromUrl(url: string) {
  downloadUrl(url);
  return {
    type: DOWNLOAD_STARTED,
  };
}
/*
 * Content Settings
 */
export function setIncludeBaseMap(includeBaseMap: boolean): MapExportActions {
  return {
    type: SET_INCLUDE_BASEMAP,
    includeBaseMap,
  };
}

export function setExportFormat(
  exportFormat: MapExportOutputSettings.FormatEnum,
): MapExportActions {
  return {
    type: SET_EXPORT_FORMAT,
    exportFormat,
  };
}

export function setLegendPlacement(
  legendPlacement: MapExportControlSettings.LegendTypeEnum,
): MapExportActions {
  return {
    type: SET_LEGEND_PLACEMENT,
    legendPlacement,
  };
}

export function setLegendClassesToInclude(
  legendClassesToInclude: MapExportControlSettings.LegendClassesToIncludeEnum,
): MapExportActions {
  return {
    type: SET_LEGEND_CLASSES_TO_INCLUDE,
    legendClassesToInclude,
  };
}

export function setMapExportLegendLayer(
  layerId: LayerId,
  include: boolean = true,
): MapExportActions {
  return {
    type: SET_MAP_EXPORT_LEGEND_LAYER,
    layerId,
    include,
  };
}

// Useful for clearing out the legend layer state when the dialog closes
export function clearMapExportLegendLayers(): MapExportActions {
  return {
    type: CLEAR_MAP_EXPORT_LEGEND_LAYERS,
  };
}

export function setMapExportNumGeometries(value: number): MapExportActions {
  return {
    type: SET_MAP_EXPORT_NUM_GEOMETRIES,
    value,
  };
}

export function setPageOrientation(
  pageOrientation: MapExportDisplaySettings.OrientationEnum,
): MapExportActions {
  return {
    type: SET_ORIENTATION,
    pageOrientation,
  };
}

/*
 * Display Settings
 */
export function setMapExportBookmark(
  projectId: ProjectId,
  key: string,
): MapExportActions {
  return {
    type: SET_MAP_EXPORT_BOOKMARK,
    key,
    projectId,
  };
}

export function updateMapExportViewport(
  projectId: ProjectId,
  layerIds: LayerId[],
  viewport: Partial<MapViewport>,
): MapExportActions {
  return {
    type: UPDATE_MAP_EXPORT_VIEWPORT,
    projectId,
    layerIds,
    viewport,
  };
}

export function resetMapExport(): MapExportActions {
  return {
    type: RESET_MAP_EXPORT,
  };
}

export function setMapExportScale(scale: number): MapExportActions {
  return {
    type: SET_MAP_SCALE,
    scale,
  };
}

export function setMapExportScaleRatio(scaleRatio: number): MapExportActions {
  return {
    type: SET_MAP_SCALE_RATIO,
    scaleRatio,
  };
}

export function setMapExportRequestedScale(
  requestedScale: number,
): MapExportActions {
  return {
    type: SET_MAP_REQUESTED_SCALE,
    requestedScale,
  };
}

export function setMapExportBounds(bounds: UFLngLatBounds): MapExportActions {
  return {
    type: SET_MAP_EXPORT_BOUNDS,
    bounds,
  };
}

export function setMapExportRequestedBounds(
  requestedBounds: UFLngLatBounds,
): MapExportActions {
  return {
    type: SET_MAP_EXPORT_REQUESTED_BOUNDS,
    requestedBounds,
  };
}

export function setMapExportResolution(resolution: number): MapExportActions {
  return {
    type: SET_MAP_RESOLUTION,
    resolution,
  };
}

/**
 * Set the map's dimension type.  We allow the user to fix the size of the image pixels or
 * inches.  Because we store both in redux, we need to know which one is fixed so we can update the
 * other correspondingly.
 */
export function setMapExportDimensionType(
  dimensionType: DimensionType,
): MapExportActions {
  return {
    type: SET_MAP_DIMENSION_TYPE,
    dimensionType,
  };
}

/**
 * Set the map's dimensions in pixels
 */
export function setMapExportDimensions(
  dimensions: Dimensions,
): MapExportActions {
  return {
    type: SET_MAP_DIMENSIONS,
    dimensions,
  };
}

/**
 * Set the map's dimensions in inches
 */
export function setMapExportPhysicalDimensions(
  dimensions: Dimensions,
): MapExportActions {
  return {
    type: SET_MAP_PHYSICAL_DIMENSIONS,
    dimensions,
  };
}

export function setMapExportScaleBarVisible(
  scaleBarVisible: boolean,
): MapExportActions {
  return {
    type: SET_MAP_SCALEBAR_VISIBLE,
    scaleBarVisible,
  };
}

export function setMapExportCompassVisible(
  compassVisible: boolean,
): MapExportActions {
  return {
    type: SET_MAP_COMPASS_VISIBLE,
    compassVisible,
  };
}

export function setMapExportTitle(title: MapExportTitle): MapExportActions {
  return {
    type: SET_MAP_TITLE,
    title,
  };
}

export function setMapExportTitleVisible(
  titleVisible: boolean,
): MapExportActions {
  return {
    type: SET_MAP_TITLE_VISIBLE,
    titleVisible,
  };
}

export function setMapExportLogo(logoKey: string): MapExportActions {
  return {
    type: SET_MAP_EXPORT_LOGO,
    logoKey,
  };
}

export function setMapExportLogoVisible(visible: boolean): MapExportActions {
  return {
    type: SET_MAP_EXPORT_LOGO_VISIBLE,
    visible,
  };
}

export function startMapExport(
  projectId: ProjectId,
  request: MapExportRequest,
) {
  const key = risonEncode({ projectId, request });
  return dispatchAsyncAction(
    enqueueMapExportActionTypes,
    startMapExportAPI(projectId, request),
    { projectId, request, key },
  );
}
