__webpack_public_path__ = process.env.UF_ASSET_ORIGIN
  ? `${process.env.UF_ASSET_ORIGIN}/dist/`
  : '/dist/';

/**
 * Used to do manual resolution of webpack urls. Resolves issues when the path
 * includes leading /dist/
 */
export function assetUrl(path: string) {
  // URL can't account for __webpack_public_path__ as just /dist/
  if (path.startsWith(__webpack_public_path__)) {
    return path;
  }
  const u = new URL(path, __webpack_public_path__);
  return `${u}`;
}
