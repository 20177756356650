import { Context } from '@datadog/browser-core';
import warning from 'warning';

import { parseFullPath } from 'uf/base/dataset';
import { ProjectId } from 'uf/projects';

import { ThirdPartyAny } from './base/types';

let datadogRum = null;
if (__CLIENT__) {
  ({ datadogRum } = require('@datadog/browser-rum'));
}
let datadogEnabled = false;

export function initializeRum() {
  if (!datadogRum) {
    return;
  }

  const ddAppId = process.env.UF_DATADOG_APP_ID;
  const ddClientToken = process.env.UF_DATADOG_CLIENT_TOKEN;
  datadogEnabled = !!(ddAppId && ddClientToken);

  if (!datadogEnabled) {
    warning(
      __DEVELOPMENT__,
      'Possible misconfiguration: Datadog RUM is NOT enabled',
    );
    return;
  }

  const ddService = `uf-frontend-browser-${process.env.DD_ENV}`;
  const ddEnv = process.env.DD_ENV;
  const ddVersion = process.env.DD_VERSION;
  let ddAllowedTracingOrigins;
  if (process.env.UF_API_SERVER_ADDR) {
    ddAllowedTracingOrigins = [process.env.UF_API_SERVER_ADDR];
  }

  datadogRum.init({
    applicationId: ddAppId,
    clientToken: ddClientToken,
    service: ddService,
    env: ddEnv,
    version: ddVersion,
    trackInteractions: true,
    allowedTracingOrigins: ddAllowedTracingOrigins,
    resourceSampleRate: 100,
    sampleRate: 100,
  });
}

export function configureRumUser(id: string, email: string) {
  // Datadog expects user attributes to be usr.id and usr.email
  addRumGlobalContext('usr', { id, email });
}

export function configureRumProject(projectId: ProjectId) {
  const { namespace, key } = parseFullPath(projectId, 'project');
  addRumGlobalContext('project', { namespace, key, path: projectId });
}

export function addRumGlobalContext(key: string, value: ThirdPartyAny) {
  if (!datadogEnabled) {
    return;
  }

  datadogRum.addRumGlobalContext(key, value);
}

export function addRumUserAction(name: string, context: Context) {
  if (!datadogEnabled) {
    return;
  }

  datadogRum.addUserAction(name, context);
}
