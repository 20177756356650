import { Mode, ModeOptions } from '@mapbox/mapbox-gl-draw';
import { Feature } from 'geojson';
import { useCallback } from 'react';

import { LayerMetadata } from 'uf-api/model/models';
import { makeGetLayerEditsSelectedVertices } from 'uf/explore/selectors/drawingMode';
import { LayerId } from 'uf/layers';
import { CoordPathsByFeature } from 'uf/map/state';
import { generateUniqueFeatureProperties } from 'uf/mapedit/metadata';
import { ProjectId } from 'uf/projects';
import { useMakeSelector } from 'uf/ui/base/useMakeSelector';
import { useDraw } from 'uf/ui/map/useDraw/useDraw';

interface DrawFeaturesEventListeners {
  onSetDrawingMode: (
    projectId: ProjectId,
    layerId: LayerId,
    mode: Mode<'box_select_vertices'>,
    modeOptions: ModeOptions,
  ) => void;
  updateItem: (
    item: Feature,
    unused: null,
    projectId: ProjectId,
    layerId: LayerId,
    fromUser?: boolean,
  ) => void;
  appendItem: (
    item: Feature,
    projectId: ProjectId,
    layerId: LayerId,
    fromUser?: boolean,
  ) => void;
  selectFeatures: (
    projectId: ProjectId,
    layerId: LayerId,
    features: Feature[],
    coordPaths: CoordPathsByFeature,
  ) => void;
}
/**
 * A hook similar to `useDraw` that is aware of layers.
 *
 * this hook actually wraps `useDraw` and then does layer-specific operations
 * like assigning unique ids, etc
 */
export function useDrawFeatures(
  projectId: ProjectId,
  layerId: LayerId,
  layerMetadata: LayerMetadata,
  featureEdits: Feature[],
  layerEditsSelectedFeatureIds: string[],
  drawingMode: Mode<'box_select_vertices'>,
  defaultProperties: Record<string, any> = {},
  events: DrawFeaturesEventListeners,
) {
  const { appendItem, onSetDrawingMode, selectFeatures, updateItem } = events;
  const setDrawingMode = useCallback(
    (mode: Mode, modeOptions: ModeOptions) => {
      onSetDrawingMode(projectId, layerId, mode, modeOptions);
    },
    [projectId, layerId, onSetDrawingMode],
  );
  const onUpdateItem = useCallback(
    (feature: Feature) => {
      updateItem(feature, null, projectId, layerId, true);
    },
    [updateItem, projectId, layerId],
  );

  const onCreateItems = useCallback(
    (features: Feature[]) => {
      // assign unique properties to each feature
      const newFeatures = assignProperties(
        features,
        layerMetadata,
        defaultProperties,
      );

      // and store in redux
      newFeatures.forEach(feature => {
        appendItem(feature, projectId, layerId, true);
      });
      // return the updated features so draw mode can deal with them
      return newFeatures;
    },
    [layerMetadata, defaultProperties, appendItem, projectId, layerId],
  );
  const onSelectFeatures = useCallback(
    (features: Feature[], coordPaths: CoordPathsByFeature) => {
      selectFeatures(projectId, layerId, features, coordPaths);
    },
    [projectId, layerId, selectFeatures],
  );
  const selectedCoordPaths = useMakeSelector(
    makeGetLayerEditsSelectedVertices,
    { projectId, layerId },
  );
  const onDrawMode = useDraw(
    featureEdits,
    layerEditsSelectedFeatureIds,
    selectedCoordPaths,
    drawingMode,
    {
      onCreateFeatures: onCreateItems,
      onUpdateFeature: onUpdateItem,
      onSelectFeatures,
      onSetMode: setDrawingMode,
    },
  );
  return onDrawMode;
}

/**
 * Assign unique values to new features, based on the unique properties as
 * defined in the metadata for the layer.
 */
function assignProperties(
  features: Feature[],
  layerMetadata: LayerMetadata,
  defaultProperties: Record<string, any>,
) {
  return features.map((feature): Feature => {
    const properties = generateUniqueFeatureProperties(layerMetadata);
    // TODO: use `makeFeatureId` to fix the feature id - and then replace the one
    // stored in the draw mode with the updated id
    const newFeature: Feature = {
      ...feature,
      properties: {
        ...feature.properties,
        ...properties,
        ...defaultProperties,
      },
    };
    return newFeature;
  });
}
