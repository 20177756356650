import { parseFullPath } from 'uf/base/dataset';
import { makeAsyncApiCaller } from 'uf/base/xhr';
import { ProjectId } from 'uf/projects';

const getScenarioUpdateInProgress = makeAsyncApiCaller(
  apis => apis.project.get_scenario_update_in_progress,
);

export function fetchScenarioUpdateStatus(
  scenarioKey: string,
  projectId: ProjectId,
) {
  const { namespace, key } = parseFullPath(projectId, 'project');
  const params = {
    namespace,
    key,
    scenario_key: scenarioKey,
  };

  return getScenarioUpdateInProgress(params);
}
