import {
  CuratedColumnSymbology,
  CUSTOM_THEME,
  DEFAULT_OPACITY,
  InterpolationTypes,
  PaintColorTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/public/dataset/census_blocks': {
    uatype: [
      {
        type: SymbologyTypes.LINE,
        paint: {
          'line-color': {
            property: 'uatype',
            type: PaintColorTypes.CATEGORICAL,
            stops: [
              { value: 'C', color: 'rgba(244,67,54,.05)' },
              { value: 'U', color: 'rgba(76,175,80,.05)' },
            ],
          },
          'line-opacity': DEFAULT_OPACITY,
        },
        display_hints: {
          scale: SymbologyScales.CATEGORICAL,
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          theme: CUSTOM_THEME,
        },
      },
    ],
  },
};

export default symbologies;
