import { ActionsObservable, Epic, ofType } from 'redux-observable';
import { filter, map, tap, withLatestFrom } from 'rxjs/operators';

import { SET_ACTIVE_PROJECT, SetActiveProjectAction } from 'uf/app/ActionTypes';
import { combineEpics } from 'uf/base/epics';
import { getRegisteredPrefixes } from 'uf/persistence';
import { loadPersistedValues } from 'uf/persistence/actions';
import { getShouldLoadPersistence } from 'uf/persistence/selectors';
import { UFState } from 'uf/state';

export const loadProjectPersistence: Epic<
  SetActiveProjectAction,
  any,
  UFState
> = (action$: ActionsObservable<SetActiveProjectAction>, state$) => {
  return action$.pipe(
    ofType(SET_ACTIVE_PROJECT),
    tap(({ value: projectId }) => {
      // For debugging, adds to server logs
      /* eslint-disable no-console  */
      console.log(`Loading project preferences for ${projectId}.`);
      /* eslint-enable no-console  */
    }),
    withLatestFrom(state$),
    filter(([action, state]) => getShouldLoadPersistence(state)),
    map(([action]) => {
      const keys = getRegisteredPrefixes();
      const { value: projectId } = action;
      return loadPersistedValues(projectId, keys);
    }),
  );
};

const epic = combineEpics({ loadProjectPersistence }, 'persistence');

export default epic;
