import { Button, Menu, MenuItem, Popover, Position } from '@blueprintjs/core';
import { mdiCheck } from '@mdi/js';
import Icon from '@mdi/react';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { t } from 'ttag';
import {
  favoritesFilterDisabled,
  favoritesFilterEnabled,
  selectTableState,
} from 'uf/explore/reducer/tableSlice';
import { IconSizeNew } from 'uf/ui/base/icon';

const ColumnFilterMenu = () => {
  const dispatch = useDispatch();
  const { showOnlyFavorites } = useSelector(selectTableState);
  const handleShowOnlyFavoritesClick = () => {
    dispatch(favoritesFilterEnabled());
  };
  const handleShowAllClick = () => {
    dispatch(favoritesFilterDisabled());
  };
  return (
    <Popover
      position={Position.BOTTOM_RIGHT}
      minimal
      content={
        <Menu>
          <MenuItem
            onClick={handleShowAllClick}
            icon={
              <Icon
                color="#137cbd"
                size={IconSizeNew.SMALL}
                path={!showOnlyFavorites ? mdiCheck : ''}
              />
            }
            text={t`Show all columns`}
          />
          <MenuItem
            onClick={handleShowOnlyFavoritesClick}
            icon={
              <Icon
                color="#137cbd"
                size={IconSizeNew.SMALL}
                path={showOnlyFavorites ? mdiCheck : ''}
              />
            }
            text={t`Show favorites only`}
          />
        </Menu>
      }>
      <Button
        small
        icon={showOnlyFavorites ? 'star' : 'star-empty'}
        rightIcon="chevron-down"
      />
    </Popover>
  );
};

export default ColumnFilterMenu;
