import {
  CuratedColumnSymbology,
  CUSTOM_THEME,
  DEFAULT_OPACITY,
  DistributionTypes,
  InterpolationTypes,
  PaintColorTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/cityofmadison/dataset/madison_energy_use_base': {
    total_electricity_use: [
      {
        type: SymbologyTypes.FILL,
        paint: {
          'fill-color': {
            property: 'total_electricity_use',
            type: PaintColorTypes.INTERVAL,
            stops: [
              { value: 0, color: '#909090' },
              { value: 10000, color: '#97D704' },
              { value: 50000, color: '#B2D103' },
              { value: 100000, color: '#CBCA03' },
              { value: 250000, color: '#C5A703' },
              { value: 500000, color: '#BF8603' },
              { value: 750000, color: '#B96602' },
              { value: 1000000, color: '#B34802' },
              { value: 2500000, color: '#AD2C02' },
              { value: 5000000, color: '#A71102' },
              { value: 10000000, color: '#A1020B' },
            ],
          },
          'fill-opacity': DEFAULT_OPACITY,
        },
        display_hints: {
          scale: SymbologyScales.NUMERIC,
          distribution: DistributionTypes.DEFAULT,
          interpolation: InterpolationTypes.INTERVAL,
          theme: CUSTOM_THEME,
        },
      },
    ],
  },
};

export default symbologies;
