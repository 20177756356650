import {
  Callout,
  FormGroup,
  InputGroup,
  Intent,
  Label,
  Radio,
} from '@blueprintjs/core';
import { boundMethod } from 'autobind-decorator';
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { ThunkActionMapDispatch } from 'redux-thunk';
import { jt, t } from 'ttag';
import {
  OrganizationInfo,
  ProjectMetadata,
  ScenarioMetadata,
  UserAction,
} from 'uf-api';
import { assertNever } from 'uf/base/never';
import {
  BoundarySources,
  CanvasTypes,
  DefaultProjectTypes,
  ProjectRoles,
} from 'uf/projects';
import * as projectActionCreators from 'uf/projects/actions';
import { CreateProjectRequest } from 'uf/projects/actions';
import { CensusLocation, makeFeatureBoundary } from 'uf/projects/create';
import { UFState } from 'uf/state';
import tw from 'uf/tailwindcss-classnames';
import Flex from 'uf/ui/base/Flex';
import LocationPicker from 'uf/ui/base/LocationPicker/LocationPicker';
import WithTooltip from 'uf/ui/base/WithTooltip/WithTooltip';
import { getUserOrganizations } from 'uf/user/selectors/user';
import warning from 'warning';
import BoundarySourceChoices from './BoundarySourceChoices/BoundarySourceChoices';
import CanvasTypeChoices from './CanvasTypeChoices/CanvasTypeChoices';
import { CreateProjectFlags } from './CreateProjectFlags';
import FormSection from './FormSection';
import OrganizationSelect from './OrganizationSelection/OrganizationSelect';
import ProjectBoundaryField from './ProjectBoundaryField/ProjectBoundaryField';
import {
  ENABLE_BLOCK_CANVAS_FEATURE_FLAG,
  makeGetUserFlag,
} from 'uf/user/selectors/flags';

enum CanvasSources {
  PROJECT_AREA = 'projectCanvasType',
  CONTEXT_AREA = 'contextCanvasType',
}

const initialState: CreateProjectFormState = Object.freeze({
  // React-bootstrap inputs require an empty string initially
  // otherwise it warns about controlled vs uncontrolled components
  name: '',
  description: '',
  // Default to location picker for project boundary
  projectBoundarySource: BoundarySources.WELL_KNOWN,
  projectArea: null,
  projectAreaUploadKey: '',
  projectCanvasName: '',
  [CanvasSources.PROJECT_AREA]: {
    canvasTypeKey: CanvasTypes.PARCEL,
    parcelsAvailable: false,
    parcelCanvasDisabledReason: null,
    blocksAvailable: false,
    canvasTypeLoading: false,
    canvasTypeLoaded: false,
    canvasTypeError: null,
  },
  contextArea: null,
  contextBoundarySource: BoundarySources.WELL_KNOWN,
  [CanvasSources.CONTEXT_AREA]: {
    canvasTypeKey: CanvasTypes.BLOCK,
    parcelsAvailable: false,
    parcelCanvasDisabledReason: null,
    blocksAvailable: false,
    canvasTypeLoading: false,
    canvasTypeLoaded: false,
    canvasTypeError: null,
  },
  enableParcelSplitting: false,
  projectRole: ProjectRoles.STANDARD,
  intersectionMethod: 'centroid',
});

interface OwnProps {
  onSetError?: (error: any) => void;
  onFormUpdate?: (formData: CreateProjectFormState) => void;
  organizationKey?: string;
  defaultOrganizationKey?: string;

  sourceProject?: ProjectMetadata;
  sourceScenario?: ScenarioMetadata;
  projectType?: string;
  showProjectTypeOptions?: boolean;
}

interface StateProps {
  showCanvasOptions: boolean;
  organizations: OrganizationInfo[];
}

interface DispatchProps {
  projectActions: ThunkActionMapDispatch<typeof projectActionCreators>;
}

type Props = OwnProps & StateProps & DispatchProps;

export interface CreateProjectFormState extends Partial<CreateProjectRequest> {
  organizationKey?: string;
  showCanvasOptions?: boolean;
  // React-bootstrap inputs require an empty string initially
  // otherwise it warns about controlled vs uncontrolled components
  name?: string;
  description?: string;
  // Default to location picker for project boundary
  projectBoundarySource?: BoundarySources;
  projectArea?: CensusLocation;
  projectAreaUploadKey?: string;
  projectCanvasName?: string;
  [CanvasSources.PROJECT_AREA]?: {
    canvasTypeKey: CanvasTypes;
    parcelsAvailable: boolean;
    parcelCanvasDisabledReason: string;
    blocksAvailable: boolean;
    canvasTypeLoading: boolean;
    canvasTypeLoaded: boolean;
    canvasTypeError: Error;
  };
  contextBoundarySource?: BoundarySources;
  contextArea?: CensusLocation;
  [CanvasSources.CONTEXT_AREA]?: {
    canvasTypeKey: CanvasTypes;
    parcelsAvailable: boolean;
    parcelCanvasDisabledReason: null;
    blocksAvailable: boolean;
    canvasTypeLoading: boolean;
    canvasTypeLoaded: boolean;
    canvasTypeError: Error;
  };

  projectCanvasTypeKey?: CanvasTypes;
  contextCanvasTypeKey?: CanvasTypes;
  valid?: boolean;
  enableParcelSplitting?: boolean;
  enableBaseCanvas?: boolean;
  projectRole?: ProjectRoles;
}

export class CreateProjectForm extends Component<
  Props,
  CreateProjectFormState
> {
  state: CreateProjectFormState;
  mounted: boolean;
  currentProjectCanvasTypePromise: Promise<any[]>;
  currentContextCanvasTypePromise: Promise<any[]>;
  constructor(props) {
    super(props);
    const { organizations, showCanvasOptions } = props;
    this.state = {
      ...initialState,
      /**
       * Initialize organization key based on available values.
       * Conditional rendering and logic should prevent unintended reassignments.
       * This allows clearing out the selection state and invalidating the form
       */
      showCanvasOptions,
      organizationKey:
        this.props.organizationKey ||
        this.props.defaultOrganizationKey ||
        organizations?.[0]?.key ||
        null,
    };
  }

  componentDidMount() {
    this.mounted = true;
    if (this.props.sourceProject) {
      this.setState({
        projectBoundarySource: BoundarySources.SOURCE_PROJECT,
        contextBoundarySource: BoundarySources.SOURCE_PROJECT,
      });
    }
  }

  componentDidUpdate(prevProps: Props, prevState: CreateProjectFormState) {
    if (this.state !== prevState) {
      this.updateForm();
    }
    const { organizations } = this.props;
    if (prevProps.organizations !== organizations) {
      if (organizations?.length === 1) {
        this.setState({ organizationKey: organizations[0].key });
      }
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  @boundMethod
  onInterscetionMethodChange(e) {
    this.updateProjectProperty('intersectionMethod', e.target.value);
  }

  @boundMethod
  onNameChange(e) {
    this.updateProjectProperty('name', e.target.value);
  }

  @boundMethod
  onOrganizationChange(e) {
    const {
      target: { value },
    } = e;
    const { sourceProject } = this.props;
    warning(
      !!sourceProject,
      'Should never change organization while pinned to a project',
    );

    this.updateProjectProperty('organizationKey', value, () => {
      const {
        projectBoundarySource,
        contextArea,
        projectArea,
        projectAreaUploadKey,
        organizationKey,
      } = this.state;
      // We perform the canvas type checks after the org key changes in local state
      switch (projectBoundarySource) {
        case BoundarySources.WELL_KNOWN:
          this.updateProjectCanvasTypeChoices({
            projectArea,
            organizationKey,
            projectBoundarySource,
          });
          break;
        case BoundarySources.USER_UPLOAD:
          this.updateProjectCanvasTypeChoices({
            projectAreaUploadKey,
            organizationKey,
            projectBoundarySource,
          });
          break;
        case BoundarySources.SOURCE_PROJECT:
          // should never happen
          break;
        default:
          assertNever(
            projectBoundarySource,
            `Unknown project boundary source detected: ${projectBoundarySource}`,
          );
          break;
      }
      if (contextArea) {
        this.updateContextCanvasTypeChoices(contextArea);
      }
    });
  }

  @boundMethod
  onDescriptionChange(e) {
    this.updateProjectProperty('description', e.target.value);
  }

  @boundMethod
  onContextCanvasTypeChange(canvasType: CanvasTypes) {
    this.setState(prevState => ({
      [CanvasSources.CONTEXT_AREA]: {
        ...prevState[CanvasSources.CONTEXT_AREA],
        canvasTypeKey: canvasType,
      },
    }));
  }
  @boundMethod
  onProjectCanvasTypeChange(canvasType: CanvasTypes) {
    this.setState(prevState => ({
      [CanvasSources.PROJECT_AREA]: {
        ...prevState[CanvasSources.PROJECT_AREA],
        canvasTypeKey: canvasType,
      },
    }));
  }

  @boundMethod
  onProjectBoundarySourceChange(newProjectBoundarySource: BoundarySources) {
    this.setState(prevState => {
      return {
        projectArea: null,
        projectAreaUploadKey: null,
        projectBoundarySource: newProjectBoundarySource,
        [CanvasSources.PROJECT_AREA]: {
          ...prevState[CanvasSources.PROJECT_AREA],
          canvasTypeLoaded: false,
          parcelsAvailable: false,
          blocksAvailable: false,
        },
      };
    });
  }

  @boundMethod
  onContextBoundarySourceChange(newContextBoundarySource: BoundarySources) {
    this.setState(prevState => ({
      contextArea: null,
      contextBoundarySource: newContextBoundarySource,
    }));
  }

  @boundMethod
  onProjectRoleChange(projectRole: ProjectRoles) {
    this.updateProjectProperty('projectRole', projectRole);
  }

  @boundMethod
  setProjectArea(projectArea) {
    this.setState(
      prevState => ({
        projectArea,
        [CanvasSources.PROJECT_AREA]: {
          ...prevState[CanvasSources.PROJECT_AREA],
          parcelCanvasDisabledReason: null,
          parcelsAvailable: false,
          blocksAvailable: false,
          canvasTypeLoading: false,
          canvasTypeLoaded: false,
          canvasTypeError: null,
        },
      }),
      () => {
        this.updateProjectCanvasTypeChoices({
          projectArea,
          organizationKey: this.state.organizationKey,
          projectBoundarySource: this.state.projectBoundarySource,
        });
      },
    );
  }

  @boundMethod
  setProjectAreaUploadKey(uploadKey: string) {
    this.setState({ projectAreaUploadKey: uploadKey }, () => {
      this.updateProjectCanvasTypeChoices({
        projectAreaUploadKey: uploadKey,
        organizationKey: this.state.organizationKey,
        projectBoundarySource: this.state.projectBoundarySource,
      });
    });
  }

  @boundMethod
  setProjectAreaUploadName(uploadName: string) {
    this.setState({ projectCanvasName: uploadName });
  }

  @boundMethod
  setContextArea(contextArea) {
    this.setState(
      prevState => ({
        contextArea,
        [CanvasSources.CONTEXT_AREA]: {
          ...prevState[CanvasSources.CONTEXT_AREA],
          parcelsAvailable: false,
          parcelCanvasDisabledReason: null,
          blocksAvailable: false,
          canvasTypeLoading: false,
          canvasTypeLoaded: false,
          canvasTypeError: null,
        },
      }),
      () => {
        this.updateContextCanvasTypeChoices(contextArea);
      },
    );
  }

  // Before requesting block/parcel coverage for the project or context area,
  // put the form in a loading state.
  @boundMethod
  setCanvasTypeChoicesLoading(canvasSource: CanvasSources) {
    const loadingState = {
      parcelsAvailable: false,
      parcelCanvasDisabledReason: null,
      blocksAvailable: false,
      canvasTypeLoading: true,
      canvasTypeError: null,
    };

    this.setState(
      (prevState): Partial<CreateProjectFormState> => ({
        [canvasSource]: {
          ...prevState[canvasSource],
          ...loadingState,
        },
      }),
    );
  }

  // After requesting block/parcel coverage for the project or context area,
  // show the correct choices based on what's available.
  @boundMethod
  setCanvasTypeChoices(canvasSource: CanvasSources, result: any[]) {
    const blocksItem = _.find(result, ['type_key', CanvasTypes.BLOCK]);
    const parcelsItem = _.find(result, ['type_key', CanvasTypes.PARCEL]);
    const blocksAvailable = blocksItem && !blocksItem.disabled;
    const parcelsAvailable = parcelsItem && !parcelsItem.disabled;
    const parcelCanvasDisabledReason = parcelsItem?.disabled;
    const canvasTypeChoices = {
      blocksAvailable: !!blocksAvailable,
      parcelsAvailable: !!parcelsAvailable,
      canvasTypeLoading: false,
      parcelCanvasDisabledReason,
      canvasTypeLoaded: true,
      canvasTypeError: null,
    };
    this.setState(prevState => {
      // Is the selected canvas type no
      // longer available? If so, deselect it.
      let { canvasTypeKey } = prevState[canvasSource];
      if (!_.find(result, ['type_key', canvasTypeKey])) {
        canvasTypeKey = null;
      }
      return {
        [canvasSource]: {
          ...prevState[canvasSource],
          ...canvasTypeChoices,
          canvasTypeKey,
        },
      };
    });
  }

  @boundMethod
  setCanvasTypeError(canvasSource, error) {
    this.setState(
      (prevState): Partial<CreateProjectFormState> => ({
        [canvasSource]: {
          ...prevState[canvasSource],
          canvasTypeError: error,
          canvasTypeLoading: false,
          canvasTypeLoaded: false,
        },
      }),
    );
  }

  @boundMethod
  updateProjectProperty<P extends keyof CreateProjectFormState>(
    property: P,
    value: CreateProjectFormState[P],
    callback?: () => void,
  ) {
    this.setState(prevState => {
      return { [property]: value };
    }, callback);
  }

  updateForm() {
    const {
      name: projectName,
      description,
      projectBoundarySource,
      projectArea,
      projectAreaUploadKey,
      projectCanvasName,
      [CanvasSources.PROJECT_AREA]: { canvasTypeKey: projectCanvasTypeKey },
      contextArea,
      contextBoundarySource,
      [CanvasSources.CONTEXT_AREA]: { canvasTypeKey: contextCanvasTypeKey },
      enableParcelSplitting,
      projectRole,
      intersectionMethod,
    } = this.state;
    const { onFormUpdate } = this.props;
    const { organizationKey } = this.state;
    const valid = this.isFormValid();
    // mandatory fields
    const formData: CreateProjectFormState = {
      valid,
      name: projectName,
      description,
      organizationKey,
      projectCanvasTypeKey,
      projectCanvasName,
      projectBoundarySource,
      contextBoundarySource,
      enableParcelSplitting,
      projectRole,
      intersectionMethod,
    };
    switch (projectBoundarySource) {
      case BoundarySources.WELL_KNOWN:
        formData.projectArea = projectArea;
        break;
      case BoundarySources.USER_UPLOAD:
        formData.projectAreaUploadKey = projectAreaUploadKey;
        break;
      case BoundarySources.SOURCE_PROJECT:
        // nothing to do here, parent will manage it
        break;
      default:
        assertNever(
          projectBoundarySource,
          `Unknown project boundary source detected: ${projectBoundarySource}`,
        );
        break;
    }

    if (contextBoundarySource === BoundarySources.WELL_KNOWN && contextArea) {
      formData.contextArea = contextArea;
      formData.contextCanvasTypeKey = contextCanvasTypeKey;
    }
    if (onFormUpdate) {
      onFormUpdate(formData);
    }
  }

  isProjectAreaValid() {
    const { projectBoundarySource, projectArea, projectAreaUploadKey } =
      this.state;
    const { sourceProject } = this.props;

    switch (projectBoundarySource) {
      case BoundarySources.WELL_KNOWN:
        return !!projectArea;
      case BoundarySources.USER_UPLOAD:
        return !!projectAreaUploadKey;
      case BoundarySources.SOURCE_PROJECT:
        return !!sourceProject;

      default:
        assertNever(
          projectBoundarySource,
          `Uknown project boundary source detected: ${projectBoundarySource}`,
        );
        break;
    }
    return false;
  }
  isContextAreaValid() {
    const { contextBoundarySource } = this.state;
    const { sourceProject } = this.props;

    switch (contextBoundarySource) {
      case BoundarySources.WELL_KNOWN:
        // it's always ok to leave out the context area
        return true;
      case BoundarySources.USER_UPLOAD:
        return false;
      case BoundarySources.SOURCE_PROJECT:
        return !!sourceProject;

      default:
        assertNever(
          contextBoundarySource,
          `Uknown contxt boundary source detected: ${contextBoundarySource}`,
        );
        break;
    }
    return false;
  }

  isFormValid() {
    const {
      name: projectName,
      [CanvasSources.PROJECT_AREA]: { canvasTypeKey: projectCanvasTypeKey },
      intersectionMethod,
    } = this.state;
    const { organizationKey } = this.state;
    const valid =
      !_.isEmpty(projectName) &&
      !_.isEmpty(organizationKey) &&
      this.isProjectAreaValid() &&
      this.isContextAreaValid() &&
      !_.isEmpty(projectCanvasTypeKey) &&
      !_.isEmpty(intersectionMethod);
    return valid;
  }

  // TODO: Share more of this code with updateContextCanvasTypeChoices
  @boundMethod
  updateProjectCanvasTypeChoices(options: {
    projectArea?: CensusLocation;
    projectAreaUploadKey?: string;
    organizationKey: string;
    projectBoundarySource: BoundarySources;
  }) {
    const {
      projectArea,
      projectAreaUploadKey,
      organizationKey,
      projectBoundarySource,
    } = options;
    const { projectActions } = this.props;

    if (!organizationKey || (!projectArea && !projectAreaUploadKey)) {
      return;
    }
    // Invalidate any prior values
    this.setCanvasTypeChoicesLoading(CanvasSources.PROJECT_AREA);
    let projectCanvasTypePromise: Promise<any[]>;
    switch (projectBoundarySource) {
      case BoundarySources.WELL_KNOWN: {
        if (projectArea) {
          const { layerId: projectAreaLayerId, filter: projectAreaFilter } =
            makeFeatureBoundary(projectArea);
          projectCanvasTypePromise = projectActions.getCanvasTypes(
            organizationKey,
            projectBoundarySource,
            { areaLayerId: projectAreaLayerId, areaFilter: projectAreaFilter },
          );
        }
        break;
      }
      case BoundarySources.USER_UPLOAD: {
        if (projectAreaUploadKey) {
          projectCanvasTypePromise = projectActions.getCanvasTypes(
            organizationKey,
            projectBoundarySource,
            { areaUploadKey: projectAreaUploadKey },
          );
        }
        break;
      }
      case BoundarySources.SOURCE_PROJECT: {
        // Nothing to update, canvas type comes from source project
        projectCanvasTypePromise = Promise.resolve([]);
        break;
      }
      default:
        assertNever(projectBoundarySource);
    }
    this.currentProjectCanvasTypePromise = projectCanvasTypePromise;
    projectCanvasTypePromise
      .then(result => {
        if (
          !this.mounted &&
          this.currentProjectCanvasTypePromise !== projectCanvasTypePromise
        ) {
          return;
        }
        this.currentProjectCanvasTypePromise = null;

        this.setCanvasTypeChoices(CanvasSources.PROJECT_AREA, result);
      })
      .catch(error => {
        if (this.currentProjectCanvasTypePromise !== projectCanvasTypePromise) {
          throw error;
        }
        this.currentProjectCanvasTypePromise = null;
        this.setCanvasTypeError(CanvasSources.PROJECT_AREA, error);
      });
  }

  // TODO: Share more of this code with updateProjectCanvasTypeChoices
  @boundMethod
  updateContextCanvasTypeChoices(contextArea) {
    const { projectActions } = this.props;
    const { organizationKey } = this.state;
    if (!organizationKey || !contextArea) {
      return;
    }
    // Invalidate any prior values
    this.setCanvasTypeChoicesLoading(CanvasSources.CONTEXT_AREA);
    const { layerId: contextAreaLayerId, filter: contextAreaFilter } =
      makeFeatureBoundary(contextArea);
    const contextCanvasTypePromise = projectActions.getCanvasTypes(
      organizationKey,
      BoundarySources.WELL_KNOWN,
      { areaLayerId: contextAreaLayerId, areaFilter: contextAreaFilter },
    );
    this.currentContextCanvasTypePromise = contextCanvasTypePromise;
    contextCanvasTypePromise
      .then(result => {
        if (
          !this.mounted &&
          this.currentContextCanvasTypePromise !== contextCanvasTypePromise
        ) {
          return;
        }
        this.currentContextCanvasTypePromise = null;
        this.setCanvasTypeChoices(CanvasSources.CONTEXT_AREA, result);
      })
      .catch(error => {
        if (this.currentContextCanvasTypePromise !== contextCanvasTypePromise) {
          throw error;
        }
        this.currentContextCanvasTypePromise = null;
        this.setCanvasTypeError(CanvasSources.CONTEXT_AREA, error);
      });
  }

  @boundMethod
  onHavePermission(
    canPerform: boolean,
    disabledReason?: UserAction.ReasonEnum,
    resource?: string,
  ) {
    const { defaultOrganizationKey } = this.props;
    if (canPerform && `/${defaultOrganizationKey}` === resource) {
      this.updateProjectProperty('organizationKey', defaultOrganizationKey);
    }
  }

  render() {
    const {
      organizations,
      organizationKey,
      sourceProject,
      sourceScenario,
      projectType,
      showProjectTypeOptions,
      showCanvasOptions,
    } = this.props;
    const {
      name: projectName,
      description: projectDescription,
      projectArea,
      projectBoundarySource,
      [CanvasSources.PROJECT_AREA]: {
        canvasTypeKey: projectCanvasTypeKey,
        parcelsAvailable: projectParcelsAvailable,
        blocksAvailable: projectBlocksAvailable,
        parcelCanvasDisabledReason: projectParcelCanvasDisabledReason,
        canvasTypeLoading: projectCanvasTypeLoading,
        canvasTypeLoaded: projectCanvasTypeLoaded,
        canvasTypeError: projectCanvasTypeError,
      },
      contextArea,
      contextBoundarySource,
      [CanvasSources.CONTEXT_AREA]: {
        canvasTypeKey: contextCanvasTypeKey,
        parcelsAvailable: contextParcelsAvailable,
        blocksAvailable: contextBlocksAvailable,
        parcelCanvasDisabledReason: contextParcelCanvasDisabledReason,
        canvasTypeLoading: contextCanvasTypeLoading,
        canvasTypeLoaded: contextCanvasTypeLoaded,
        canvasTypeError: contextCanvasTypeError,
      },
      organizationKey: currentOrganizationKey,
      projectRole,
      intersectionMethod,
    } = this.state;
    const selectedOrganization = _.find(organizations, [
      'key',
      this.state.organizationKey,
    ]);

    const { onSetError } = this.props;

    /*
     * if block canvas is disabled, we have
     * new edge case error messages to display
     * we also need to disable the project
     * create button
     */
    if (onSetError && !showCanvasOptions) {
      if (projectCanvasTypeError) {
        onSetError('Error checking parcel coverage for project area');
      } else if (contextCanvasTypeError) {
        onSetError('Error checking parcel coverage for context area');
      } else if (
        !projectCanvasTypeLoading &&
        projectArea &&
        !projectParcelsAvailable
      ) {
        onSetError('No parcel data for project area');
      } else if (
        !contextCanvasTypeLoading &&
        contextArea &&
        !contextParcelsAvailable
      ) {
        onSetError('No parcel data for context area');
      } else {
        onSetError(null);
      }
    } else if (onSetError) {
      onSetError(null);
    }

    if (!organizationKey && organizations?.length === 0) {
      // User has no personal organization *and* is not a member of
      // any organizations. This is a strange state for an account to
      // be in!
      return (
        <div>
          {t`You are not a member of any organizations. Please contact support.`}
        </div>
      );
    }

    const haveSourceProject = !!(sourceProject && sourceScenario);
    const titleClasses = showProjectTypeOptions
      ? tw('tw-text-3xl', 'tw-font-normal')
      : null;
    return (
      <>
        {haveSourceProject &&
          renderSourceProjectCallout(sourceProject.name, sourceScenario.name)}
        <FormSection title={t`Project Information`} titleClass={titleClasses}>
          <FormGroup
            className="tw-w-full"
            label={t`Project Name`}
            labelInfo={t`(required)`}>
            <InputGroup
              name="name"
              type="text"
              className={'CreateProjectForm-projectName'}
              value={projectName}
              placeholder={t`Enter project name`}
              onChange={this.onNameChange}
            />
          </FormGroup>
          {!organizationKey && ( // if orgKey supplied form props, don't show this
            <OrganizationSelect
              organizations={organizations}
              currentOrganizationKey={currentOrganizationKey}
              projectType={projectType}
              onChange={this.onOrganizationChange}
              onHavePermission={this.onHavePermission}
            />
          )}
          <FormGroup className="tw-w-full" label={t`Project Description`}>
            <InputGroup
              type="text"
              name="description"
              value={projectDescription}
              placeholder={t`Enter project description`}
              className={'CreateProjectForm-projectDescription'}
              onChange={this.onDescriptionChange}
            />
          </FormGroup>
        </FormSection>
        <ProjectBoundaryField
          projectBoundarySource={projectBoundarySource}
          onProjectBoundarySourceChange={this.onProjectBoundarySourceChange}
          projectArea={projectArea}
          sourceProject={sourceProject}
          setProjectArea={this.setProjectArea}
          onUserUploadChange={this.setProjectAreaUploadKey}
          onNameChange={this.setProjectAreaUploadName}
          projectType={projectType}
          titleClasses={titleClasses}>
          {projectType === DefaultProjectTypes.LAND_USE && (
            <Flex className="tw-w-full" justifyContent="between">
              {showCanvasOptions && !haveSourceProject && (
                <CanvasTypeChoices
                  id="projectCanvasTypeKey"
                  data-testid="projectCanvasTypeOptions"
                  choicesLabel={t`Project canvas type`}
                  canvasType={projectCanvasTypeKey}
                  canvasArea={projectArea}
                  defaultCanvasType={CanvasTypes.PARCEL}
                  canvasTypeLoading={projectCanvasTypeLoading}
                  canvasTypeLoaded={projectCanvasTypeLoaded}
                  canvasTypeError={projectCanvasTypeError}
                  organization={selectedOrganization}
                  parcelCanvasAvailable={projectParcelsAvailable}
                  parcelCanvasDisabledReason={projectParcelCanvasDisabledReason}
                  blockCanvasAvailable={projectBlocksAvailable}
                  onCanvasTypeChange={this.onProjectCanvasTypeChange}
                />
              )}
              <FormGroup label={t`Geometry selection method`}>
                <div>
                  <WithTooltip
                    placement="right"
                    text={t`Selects only features whose centerpoint intersects the boundary shape (recommended)`}>
                    <Radio
                      label={t`Within the boundary shape`}
                      onChange={this.onInterscetionMethodChange}
                      value="centroid"
                      checked={intersectionMethod === 'centroid'}
                    />
                  </WithTooltip>
                </div>
                <div>
                  <WithTooltip
                    placement="right"
                    text={t`Selects all parcels or blocks that have any overlap with the boundary shape`}>
                    <Radio
                      label={t`Intersecting the boundary shape`}
                      onChange={this.onInterscetionMethodChange}
                      value="boundary"
                      checked={intersectionMethod === 'boundary'}
                    />
                  </WithTooltip>
                </div>
              </FormGroup>
            </Flex>
          )}
        </ProjectBoundaryField>

        {projectType === DefaultProjectTypes.LAND_USE && (
          <FormSection
            title={t`Context Area`}
            titleClass={titleClasses}
            infoText={t`The Context Area corresponds to the larger geographic area encompassing the Project Area
            (the geometry selection method chosen for the Project Area will apply here)`}>
            {showCanvasOptions && haveSourceProject && (
              <BoundarySourceChoices
                boundarySource={contextBoundarySource}
                onBoundarySourceChange={this.onContextBoundarySourceChange}
                haveSourceProject
              />
            )}
            {contextBoundarySource === BoundarySources.WELL_KNOWN && (
              <FormGroup
                className="tw-w-full"
                intent={contextArea ? 'success' : null}>
                <Label>{t`Location`}</Label>
                <LocationPicker
                  projectType={projectType}
                  placeholder={t`Enter location...(optional)`}
                  onChange={this.setContextArea}
                  className={'CreateProjectForm-contextArea'}
                />
              </FormGroup>
            )}
            {contextBoundarySource === BoundarySources.SOURCE_PROJECT && (
              <div>
                The new context area boundary will be a duplicate of the project
                area boundary from the current project.
              </div>
            )}
            {showCanvasOptions && !haveSourceProject && (
              <CanvasTypeChoices
                id="contextCanvasTypeKey"
                data-testid="contextCanvasTypeOptions"
                choicesLabel={t`Context canvas type`}
                canvasType={contextCanvasTypeKey}
                defaultCanvasType={CanvasTypes.BLOCK}
                canvasArea={contextArea}
                canvasTypeLoading={contextCanvasTypeLoading}
                canvasTypeLoaded={contextCanvasTypeLoaded}
                canvasTypeError={contextCanvasTypeError}
                organization={selectedOrganization}
                parcelCanvasAvailable={contextParcelsAvailable}
                parcelCanvasDisabledReason={contextParcelCanvasDisabledReason}
                blockCanvasAvailable={contextBlocksAvailable}
                onCanvasTypeChange={this.onContextCanvasTypeChange}
              />
            )}
            <CreateProjectFlags
              projectRole={projectRole}
              onProjectRoleChange={this.onProjectRoleChange}
            />
          </FormSection>
        )}
      </>
    );
  }
}

export default connect<StateProps, DispatchProps, OwnProps, UFState>(
  (state): StateProps => {
    const getUserFlag = makeGetUserFlag(ENABLE_BLOCK_CANVAS_FEATURE_FLAG);
    return {
      organizations: getUserOrganizations(state),
      showCanvasOptions: getUserFlag(state),
    };
  },
  (dispatch: Dispatch): DispatchProps => ({
    projectActions: bindActionCreators(projectActionCreators, dispatch),
  }),
)(CreateProjectForm);

function renderSourceProjectCallout(
  sourceProjectName: string,
  sourceScenarioName: string,
) {
  const projectNameItalics = (
    <em key="source-project-name">{sourceProjectName}</em>
  );
  const scenarioNameItalics = (
    <em key="source-scenario-name">{sourceScenarioName}</em>
  );
  const projectSourceMessage = jt`This project will be based on the project ${projectNameItalics}.
        The Base Canvas land use layer for the new project will be a duplicate of the canvas layer
        from the scenario ${scenarioNameItalics}.`;

  return <Callout intent={Intent.PRIMARY}>{projectSourceMessage}</Callout>;
}
