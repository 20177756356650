import { Reducer } from 'redux';

import { EMPTY_ARRAY } from 'uf/base';
import { cacheableConcat } from 'uf/base/array';
import {
  FailureAction,
  LoadAction,
  LoadActionTypes,
  SuccessAction,
  UpdateStateAction,
} from 'uf/data/ActionTypes';
import {
  DataState,
  makeErrorState,
  makeLoadedState,
  makeLoadingState,
} from 'uf/data/dataState';

export function makeDataStateListReducer<T>(
  actionTypes: LoadActionTypes,
): Reducer<DataState<T>[]> {
  return (
    state: DataState<T>[] = EMPTY_ARRAY,
    action: UpdateStateAction<T>,
  ) => {
    switch (action.type) {
      case actionTypes.LOAD:
        return [...state, makeLoadingState(null, action as LoadAction)];

      case actionTypes.SUCCESS:
        return cacheableConcat(
          state.map(dataState => {
            if (dataState.requestId !== action.requestId) {
              return dataState;
            }
            return makeLoadedState(dataState, action as SuccessAction<T>);
          }),
        );

      case actionTypes.FAILURE:
        return cacheableConcat(
          state.map(dataState => {
            if (dataState.requestId !== action.requestId) {
              return dataState;
            }
            return makeErrorState(dataState, action as FailureAction);
          }),
        );

      case actionTypes.CLEAR:
        return cacheableConcat(
          state.filter(dataState => dataState.requestId !== action.requestId),
        );

      default:
        return state;
    }
  };
}
