import { createSlice } from '@reduxjs/toolkit';
import { SHOW_INTERCOM } from 'uf/intercom/ActionTypes';

interface LocationAnalysisDialogState {
  isOpen: boolean;
}

const initialState: LocationAnalysisDialogState = {
  isOpen: false,
};

export const locationAnalysisDialogSlice = createSlice({
  name: 'locationAnalysisDialog',
  initialState,
  reducers: {
    dialogClosed: state => {
      state.isOpen = false;
    },
    dialogOpened: state => {
      state.isOpen = true;
    },
  },
  extraReducers(builder) {
    builder.addCase(SHOW_INTERCOM, () => {
      // Close dialog when an Intercom call is made
      return initialState;
    });
  },
});

export const { dialogClosed, dialogOpened } =
  locationAnalysisDialogSlice.actions;

export const selectLocationAnalysisDialog = state =>
  state.locationAnalysisDialog;

export default locationAnalysisDialogSlice.reducer;
