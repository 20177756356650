import { ActionsObservable, Epic, ofType } from 'redux-observable';
import { merge as observableMerge, of as observableOf } from 'rxjs';
import { filter, mergeMap, take, withLatestFrom } from 'rxjs/operators';

import {
  getLibraryActionTypes,
  getLibrarySuccessAction,
} from 'uf-api/api/builtforms.service';
import { setActiveProject } from 'uf/app/actions';
import { combineEpics } from 'uf/base/epics';
import { makeLibraryId } from 'uf/builtforms';
import { setEditorCurrentBuiltForm } from 'uf/builtforms/actions/editor';
import {
  INITIALIZE_BUILTFORM_EDITOR,
  InitializeBuiltformEditor,
  ROUTE_BUILT_FORM_EDITOR,
  ROUTE_BUILT_FORM_EDITOR_WITH_KEY,
} from 'uf/builtforms/ActionTypes';
import { ensureProject } from 'uf/projects/actions';
import { makeAddRootQueryEpic } from 'uf/routing/query';

export const addRootQueriesEpic = makeAddRootQueryEpic(
  ROUTE_BUILT_FORM_EDITOR,
  ROUTE_BUILT_FORM_EDITOR_WITH_KEY,
);

/**
 * Initialize the builtforms editor from the libraryId, the first time a url is
 * loaded.
 *
 * This waits for the given library to load, then extracts the projectId out of
 * it, and set up the rest of the editor as appropriate.
 *
 * TODO: generalize this so that it is true for all cases, and not just the
 * initial page load.
 */
export const loadLibraryOnInitialSetBuiltForm: Epic<any, any> = action$ => {
  const init$ = action$.pipe(
    ofType(INITIALIZE_BUILTFORM_EDITOR),
  ) as ActionsObservable<InitializeBuiltformEditor>;

  const load$ = action$.pipe(
    ofType(getLibraryActionTypes.SUCCESS),
  ) as ActionsObservable<getLibrarySuccessAction>;

  return load$.pipe(
    withLatestFrom(init$),
    filter(([loadAction, initializeAction]) => {
      const { libraryId } = initializeAction;
      const { result: library } = loadAction;
      const loadedLibraryId = makeLibraryId(library.namespace, library.key);
      return libraryId === loadedLibraryId;
    }),
    // we only fire once, since this should only happen on initialization and
    // not every time a library is loaded. After this, the url is updated via
    // react-first-router, and synced automatically.
    take(1),
    mergeMap(([loaded, initializeAction]) => {
      const { result: library } = loaded;
      const { project_path: projectId } = library;
      const { libraryId, builtFormType, builtFormKey } = initializeAction;

      return observableMerge(
        observableOf(setActiveProject(projectId, { redirectToExplore: false })),
        observableOf(ensureProject(projectId)),
        observableOf(
          setEditorCurrentBuiltForm(
            projectId,
            libraryId,
            builtFormType,
            builtFormKey,
          ),
        ),
      );
    }),
  );
};

export default combineEpics(
  {
    addRootQueriesEpic,
    loadLibraryOnInitialSetBuiltForm,
  },
  'builtFormsRoutingEpics',
);
