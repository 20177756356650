import {
  ROUTE_ANALYSIS_EDITOR,
  ROUTE_ANALYSIS_EDITOR_WITH_KEY,
} from 'uf/analysiseditor/ActionTypes';
import { combineEpics } from 'uf/base/epics';
import { makeAddRootQueryEpic } from 'uf/routing/query';

export const addRootQueriesEpic = makeAddRootQueryEpic(
  ROUTE_ANALYSIS_EDITOR,
  ROUTE_ANALYSIS_EDITOR_WITH_KEY,
);

export default combineEpics(
  {
    addRootQueriesEpic,
  },
  'analysisEditorRoutingEpics',
);
