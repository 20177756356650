import React, { Fragment, FunctionComponent } from 'react';
import { hot } from 'react-hot-loader/root';

/**
 * This is the full page for every app served by the frontend.
 */
const App: FunctionComponent = ({ children }) => {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <Fragment>{children}</Fragment>;
};
export default hot(App);
