/**
 * UrbanFootprint App API
 * API for serving UrbanFootprint Application
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Represents a geometry type.
 */
export interface GeometryType<> {
  /**
   * Error message, if any
   */
  error_message?: string;
  /**
   * The geometry type\'s key.
   */
  key: string;
  /**
   * If true, the geometry type has fully materialized and, if versioned, can be written upon.
   */
  materialized?: boolean;
  /**
   * The geometry type\'s full name.
   */
  name: string;
  /**
   * The geometry type\'s namespace.
   */
  namespace?: string;
  /**
   * Layer path that owns this geo type.
   */
  owning_layer?: string;
  /**
   * The key of this type\'s parent geo type.
   */
  parent_key?: string;
  /**
   * The version of this type\'s parent geo type.
   */
  parent_version?: number;
  /**
   * The basic type of geometries in this geo type.
   */
  simple_geometry_type?: GeometryType.SimpleGeometryTypeEnum;
  /**
   * The state of the load.
   */
  status: GeometryType.StatusEnum;
  /**
   * Version of geometry type.
   */
  version?: string;
  /**
   * A versioned and materialized geometry type can be mutated.
   */
  versioned?: boolean;
}
export namespace GeometryType {
  export type SimpleGeometryTypeEnum = 'POINT' | 'LINESTRING' | 'POLYGON';
  export const SimpleGeometryTypeEnum = {
    POINT: 'POINT',
    LINESTRING: 'LINESTRING',
    POLYGON: 'POLYGON',
  } as const;
  export type StatusEnum = 'IN_PROGRESS' | 'ERROR' | 'DONE';
  export const StatusEnum = {
    INPROGRESS: 'IN_PROGRESS',
    ERROR: 'ERROR',
    DONE: 'DONE',
  } as const;
}
