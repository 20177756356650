import { TaskEnqueueResultItem } from 'uf-api-rtk/store/Api';
import { enqueueLayerExportTaskActionTypes } from 'uf-api/api/layer.service';
import { LayerServiceInterface } from 'uf-api/api/layer.serviceInterface';
import { risonEncode } from 'uf/base/rison';
import { dispatchAsyncAction } from 'uf/data/loader';
import { ColumnKey, LayerId } from 'uf/layers';
import { startExport } from 'uf/layers/apis';
import { convertFiltersToExpressions, FilterSpec } from 'uf/layers/filters';
import { ProjectId } from 'uf/projects';

export type BeginExportAction = (
  projectId: ProjectId,
  layerId: LayerId,
  version: string,
  fileFormat: LayerServiceInterface.enqueueLayerExportTaskParams['file_format'],
  includeGeometries: boolean,
  filters: Partial<FilterSpec>,
  columnKey: ColumnKey,
  columns?: string[],
) => Promise<TaskEnqueueResultItem>;

export function beginExport(
  projectId: ProjectId,
  layerId: LayerId,
  version: string,
  fileFormat: LayerServiceInterface.enqueueLayerExportTaskParams['file_format'],
  includeGeometries: boolean,
  filters: Partial<FilterSpec>,
  columnKey: ColumnKey,
  columns?: string[],
) {
  const expressions = convertFiltersToExpressions(filters) || [];
  const filterString = filters ? risonEncode(filters) : '*';

  const key = [
    projectId,
    layerId,
    version,
    fileFormat,
    includeGeometries,
    filterString,
    columnKey,
  ]
    .map(String)
    .join('|');

  const startExportApi = startExport(
    projectId,
    layerId,
    version,
    fileFormat,
    includeGeometries,
    expressions,
    columnKey,
    columns,
  );

  return dispatchAsyncAction(
    enqueueLayerExportTaskActionTypes,
    startExportApi,
    {
      key,
      layerId,
      projectId,
    },
  );
}
