import { Spinner, SpinnerSize } from '@blueprintjs/core';
import { Tooltip2 as Tooltip } from '@blueprintjs/popover2';
import Icon from '@mdi/react';
import classNames from 'classnames';
import React, { MouseEventHandler } from 'react';
import iconStyles from 'uf/styles/icons.module.css';
import rootStyles from 'uf/styles/root.module.css';
import textStyles from 'uf/styles/text.module.css';
import Flex from 'uf/ui/base/Flex';
import NestedIconButton from 'uf/ui/base/NestedIconButton/NestedIconButton';
import { IconSizeNew } from 'uf/ui/base/icon';
import styles from './Pane.module.css';

const iconClassName = classNames(iconStyles.colorDefault, iconStyles.size24px);

const PaneHeaderIcon = ({
  iconPath,
  onClick,
}: {
  iconPath: string;
  onClick: MouseEventHandler;
}) => {
  if (!iconPath) {
    return null;
  }
  if (onClick) {
    return (
      <Flex alignItems="center" className={iconStyles.size36px}>
        <NestedIconButton
          iconSize={IconSizeNew.MEDIUM}
          iconPath={iconPath}
          className={iconClassName}
          onClick={onClick}
        />
      </Flex>
    );
  }
  return (
    <Flex alignItems="center" className={iconStyles.size36px}>
      <Icon path={iconPath} size={IconSizeNew.MEDIUM} />
    </Flex>
  );
};

interface Props {
  iconClass?: string;
  iconPath?: string;
  title?: React.ReactNode;
  children?: React.ReactNode;
  onClick?: MouseEventHandler;
  className?: string;
  titleClassName?: string;
  sticky?: boolean;
  showSpinner?: boolean;
  tooltip?: string;
}

const PaneHeader = ({
  iconPath,
  onClick,
  children,
  className,
  titleClassName,
  sticky,
  showSpinner,
  title,
  tooltip,
}: Props) => {
  const headerClassName = classNames(
    className,
    rootStyles.thinPaddingHorizontal,
    {
      [styles.stickyHeader]: !!sticky,
    },
  );
  return (
    <Flex justifyContent="between" className={headerClassName}>
      <Flex
        alignItems="center"
        className={classNames(
          textStyles.ellipsisParent,
          rootStyles.thinPaddingTop,
          titleClassName,
        )}>
        {showSpinner ? (
          <Flex alignItems="center" className={iconStyles.size36px}>
            <Tooltip
              content={tooltip}
              placement="bottom-start"
              disabled={!tooltip}>
              <Spinner size={SpinnerSize.SMALL} />
            </Tooltip>
          </Flex>
        ) : (
          <PaneHeaderIcon iconPath={iconPath} onClick={onClick} />
        )}
        {typeof title === 'string' ? (
          <div
            className={classNames(
              iconStyles.colorDefault,
              textStyles.ellipsis,
              'h3',
            )}>
            {title}
          </div>
        ) : (
          title
        )}
      </Flex>
      <Flex alignItems="center" className={rootStyles.veryThinPaddingVertical}>
        {children}
      </Flex>
    </Flex>
  );
};

export default PaneHeader;
