import { Position, Spinner, SpinnerSize } from '@blueprintjs/core';
import loadable from '@loadable/component';
import { mdiDownload } from '@mdi/js';
import Icon from '@mdi/react';
import { boundMethod } from 'autobind-decorator';
import classNames from 'classnames';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { t } from 'ttag';

import { getActiveProjectId, getActiveScenarioId } from 'uf/app/selectors';
import { DataState, isTaskLoading } from 'uf/data/dataState';
import { getVisibleLayerIdsForScenario } from 'uf/explore/selectors/layers';
import { makeGetActiveViewId } from 'uf/explore/selectors/views';
import { LayerId } from 'uf/layers';
import { logEvent } from 'uf/logging';
import { events as mapEvents } from 'uf/map/logging';
import * as mapExportActionCreators from 'uf/mapexport/actions';
import { makeGetMapExportProjectTasks } from 'uf/mapexport/selectors';
import { ProjectId } from 'uf/projects';
import { ScenarioId } from 'uf/scenarios';
import { TaskMessage } from 'uf/tasks/MessageTypes';
import { IconSizeNew } from 'uf/ui/base/icon';
import MapButton from 'uf/ui/map/MapButton/MapButton';
import { ViewId } from 'uf/views';

const exportIconClassName = 'Map-export-advanced';
const exportButtonClassName = 'MapButton-mapexport';

const MapExportDialog = loadable(
  () =>
    import(
      'uf/ui/mapexport/MapExportDialog/MapExportDialog' /* webpackChunkName: 'mapexport' */
    ),
);
interface OwnProps {
  className?: string;
  minimal?: boolean;
  tooltipPlacement?: Position;
}

interface StateProps {
  tasks: DataState<TaskMessage>[];
  projectId: ProjectId;
  scenarioId: ScenarioId;
  viewId: ViewId;
  layerIds: LayerId[];
}

interface DispatchProps {
  mapExportActions: any;
}

type ExploreMapExportButtonProps = OwnProps & StateProps & DispatchProps;

interface ExploreMapExportButtonState {
  showDialog: boolean;
}
const initialState: ExploreMapExportButtonState = Object.freeze({
  showDialog: false,
});

export class ExploreMapExportButton extends Component<
  ExploreMapExportButtonProps,
  ExploreMapExportButtonState
> {
  state = initialState;

  @boundMethod
  openDialog() {
    this.setState({ showDialog: true });
    const { projectId } = this.props;
    logEvent(mapEvents.MAP_EXPORT_ADVANCED_BUTTON_CLICKED, { projectId });
  }

  @boundMethod
  closeDialog() {
    this.setState({ showDialog: false });

    // Layer exclusions during export depend on what layers are currently attached to project.
    // For now we'll just clear this out whenever the user closes the dialog to prevent these two
    // from getting out of sync.
    // TODO:
    // Make this a scenario-based dictionary and do book-keeping in an epic whenever the user:
    //   - adds/removes layers via the Layer Manager
    //   - deletes the scenario entirely
    this.props.mapExportActions.clearMapExportLegendLayers();
  }

  render() {
    const {
      tasks,
      tooltipPlacement,
      minimal,
      className,
      projectId,
      scenarioId,
      layerIds,
      viewId,
    } = this.props;

    const { showDialog } = this.state;

    const loadingTasks = tasks.filter(task => isTaskLoading(task));
    const tooltipText = loadingTasks.length
      ? t`Exporting map. You will be notified once export is complete.`
      : t`Create a map layout for export`;

    const icon = loadingTasks.length ? (
      <Spinner size={SpinnerSize.SMALL} />
    ) : (
      <Icon
        path={mdiDownload}
        className={exportIconClassName}
        size={IconSizeNew.SMALL}
      />
    );

    return (
      <Fragment>
        <MapButton
          minimal={minimal}
          id="map-export"
          style={{ width: 'auto' }}
          disabled={!!loadingTasks.length}
          className={classNames(className, exportButtonClassName)}
          onClick={showDialog ? this.closeDialog : this.openDialog}
          tooltipText={tooltipText}
          icon={icon}
          text={t`Export`}
          tooltipPlacement={tooltipPlacement}
        />

        <MapExportDialog
          show={showDialog}
          onCloseDialog={this.closeDialog}
          projectId={projectId}
          viewId={viewId}
          scenarioId={scenarioId}
          layerIds={layerIds}
        />
      </Fragment>
    );
  }
}

function makeMapStateToProps() {
  const getMapExportProjectTasks = makeGetMapExportProjectTasks();
  const getActiveViewId = makeGetActiveViewId();
  return (state): StateProps => {
    const projectId = getActiveProjectId(state);
    const scenarioId = getActiveScenarioId(state);
    const viewId = getActiveViewId(state, { projectId });
    const tasks = getMapExportProjectTasks(state, { projectId });
    const layerIds = getVisibleLayerIdsForScenario(state, {
      projectId,
      scenarioId,
      viewId,
    });
    return {
      tasks,
      projectId,
      viewId,
      scenarioId,
      layerIds,
    };
  };
}

export default connect<StateProps, DispatchProps, OwnProps>(
  makeMapStateToProps,
  (dispatch: Dispatch) => ({
    mapExportActions: bindActionCreators(mapExportActionCreators, dispatch),
  }),
)(ExploreMapExportButton);
