import _ from 'lodash';
import { createSelector } from 'reselect';

import { User } from 'uf-api';
import { EMPTY_ARRAY, EMPTY_OBJECT } from 'uf/base';
import { cacheableConcat } from 'uf/base/array';
import { makeGetFromPropsSelector } from 'uf/base/selector';
import { Collaborator } from 'uf/collaboration/Collaborator';
import { CollaborationState } from 'uf/collaboration/state';
import { ProjectId } from 'uf/projects';
import { UFState } from 'uf/state';
import { getUser } from 'uf/user/selectors/user';

export function getCollaborationState(state: UFState): CollaborationState {
  return state?.collaboration || (EMPTY_OBJECT as CollaborationState);
}

/**
 * A selector that returns a deduped list of collaborators present on a project.  The list pulls
 * frthe the collaboration state that tracks users' presence on a project, including the current
 * user.  We remove the current user and dedupe other users if they show up with multiple connectionIds.
 */
export function makeGetCollaboratorsForProject() {
  const getProjectId = makeGetFromPropsSelector<ProjectId, 'projectId'>(
    'projectId',
  );
  return createSelector(
    getCollaborationState,
    getProjectId,
    getUser,
    (state: CollaborationState, projectId: ProjectId, user: User) => {
      const { projects } = state;
      const collaborators: Collaborator[] = projects[projectId];
      if (_.isEmpty(collaborators)) {
        return EMPTY_ARRAY;
      }

      const filteredCollaborators = _.uniqBy(collaborators, c => c.key)
        // don't show self
        .filter(({ key }) => key !== user.key);

      return cacheableConcat(filteredCollaborators);
    },
  );
}
