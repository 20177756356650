import {
  CLEAR_OVERRIDE_SYMBOLOGY,
  SET_OVERRIDE_SYMBOLOGY,
} from 'uf/symbology/ActionTypes';
import { OverrideSymbologyState } from 'uf/symbology/state';

const initialState: OverrideSymbologyState = Object.freeze({});

export default function override(
  state: OverrideSymbologyState = initialState,
  action,
) {
  switch (action.type) {
    case CLEAR_OVERRIDE_SYMBOLOGY:
      return initialState;

    case SET_OVERRIDE_SYMBOLOGY:
      return {
        ...state,
        [action.symbologyKey]: action.symbology,
      };

    default:
      return state;
  }
}
