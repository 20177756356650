import { LayerId } from 'uf/layers';
import { ProjectId } from 'uf/projects';

export const SPLIT_FEATURE = 'uf/mapedit/SPLIT_FEATURE';
export interface SplitFeatureAction {
  type: typeof SPLIT_FEATURE;
  layerId: LayerId;
  projectId: ProjectId;
}

export const UNSPLIT_FEATURE = 'uf/mapedit/UNSPLIT_FEATURE';
export interface UnSplitFeatureAction {
  type: typeof UNSPLIT_FEATURE;
  layerId: LayerId;
  projectId: ProjectId;
}

export const SPLITTING_ENABLED = 'uf/mapedit/SPLITTING_ENABLED';
export interface SetSplittingEnabledAction {
  type: typeof SPLITTING_ENABLED;
  projectId: ProjectId;
  value: boolean;
}

export const CANCEL_SPLIT_FEATURE = 'uf/mapedit/CANCEL_SPLIT_FEATURE';
export interface CancelSplitFeatureAction {
  type: typeof CANCEL_SPLIT_FEATURE;
  layerId: LayerId;
  projectId: ProjectId;
}
