import _ from 'lodash';

import { ProjectMetadata, ScenarioMetadata } from 'uf-api';
import { EMPTY_ARRAY } from 'uf/base';
import { Flavor } from 'uf/base/types';

export const PROJECT_LIST_REDUX_KEY = 'user';

export enum CanvasTypes {
  BLOCK = 'block',
  PARCEL = 'parcel',
}

export enum BoundarySources {
  /** From a well-known boundary, usually from the census */
  WELL_KNOWN = 'WELL_KNOWN',
  /** From a user-uploaded source file */
  USER_UPLOAD = 'USER_UPLOAD',

  /** From another project */
  SOURCE_PROJECT = 'SOURCE_PROJECT',
}

export type ProjectId = Flavor<string, 'ProjectId'>;
export type ProjectParams = Record<'projectId', ProjectId>;
export function makeProjectKey(params: ProjectParams) {
  return params?.projectId;
}

/**
 * Get a list of all the scenarios in the project, accounting for missing base scenario, etc.
 */
export function getScenarios(project: ProjectMetadata): ScenarioMetadata[] {
  if (_.isEmpty(project)) {
    return EMPTY_ARRAY;
  }
  const scenarios: ScenarioMetadata[] = [];
  if (project.base_scenario) {
    scenarios.push(project.base_scenario);
  }
  if (project.scenarios) {
    scenarios.push(...project.scenarios.filter(s => !!s));
  }
  return scenarios;
}

export enum ProjectRoles {
  STANDARD = 'standard',
  EXPLORER = 'explorer',
}

export enum DefaultProjectTypes {
  GENERAL = 'general',
  LAND_USE = 'land_use',
}
