import MapiClient from '@mapbox/mapbox-sdk/lib/classes/mapi-client';
import createBrowserMapiClient from '@mapbox/mapbox-sdk/lib/browser/browser-client';

export function createMapiClient(): MapiClient {
  if (!process.env.UF_MAPBOX_API_KEY) {
    return null;
  }
  return createBrowserMapiClient({
    accessToken: process.env.UF_MAPBOX_API_KEY,
  });
}
