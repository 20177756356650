import { t } from 'ttag';

export const NUMERIC_STAT_SUM = {
  key: 'sum',
  label: t`Sum`,
  description: t`The sum of all values in the column. Sum for rates (e.g. "Water use per capita") do not account for weighting by another column (e.g. "Population").`,
};

export const NUMERIC_STAT_MEAN = {
  key: 'avg',
  label: t`Mean`,
  description: t`The mean average of all values in the column. Mean for rates (e.g. "Water use per capita") do not account for weighting by another column (e.g. "Population").`,
};

export const NUMERIC_STAT_MIN = {
  key: 'min',
  label: t`Min`,
  description: t`The lowest value in the column`,
};

export const NUMERIC_STAT_MAX = {
  key: 'max',
  label: t`Max`,
  description: t`The highest value in the column`,
};
