import { Epic, ofType } from 'redux-observable';
import { merge as observableMerge, of as observableOf } from 'rxjs';
import { filter, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import warning from 'warning';

import {
  getProjectActionTypes,
  getProjectSuccessAction,
} from 'uf-api/api/project.service';
import { ScopeTypes } from 'uf-ws/WebsocketActions';
import { makeGetProjectAllLayerReferences } from 'uf/app/selectors';
import { combineEpics } from 'uf/base/epics';
import { loadLayerData } from 'uf/explore/actions/layers';
import { makeGetLayerFilters } from 'uf/explore/selectors/filters';
import {
  setLayerVersions,
  updateLayerVersion,
} from 'uf/layers/actions/versions';
import { makeGetLayerMetadata } from 'uf/layers/selectors/metadata';
import {
  BaseScenarioPaintTaskMessageBase,
  ScenarioPaintTaskMessageBase,
} from 'uf/painting/tasks';
import { NotificationAction } from 'uf/tasks/ActionTypes';
import { ofNotificationType } from 'uf/tasks/observables';
import { TaskStatuses } from 'uf/tasks/TaskStatuses';
import { TaskTypes } from 'uf/tasks/TaskTypes';

/**
 * Sets the initial version for every layer.
 */
export const setLayerVersionsEpic: Epic<getProjectSuccessAction, any> = (
  action$,
  state$,
) => {
  const getProjectAllLayerReferences = makeGetProjectAllLayerReferences();
  return action$.pipe(
    ofType(getProjectActionTypes.SUCCESS),
    withLatestFrom(state$),
    map(([action, state]) => {
      const layers = getProjectAllLayerReferences(state, {
        projectId: action.key,
      });
      const versions = {};
      layers.forEach(({ full_path: fullPath, version, details }) => {
        const latestVersion = details?.latest_version;
        warning(
          !!version || !!latestVersion,
          `Layer ${fullPath} is missing a version.`,
        );
        versions[fullPath] = latestVersion || version;
      });
      return setLayerVersions(versions);
    }),
  );
};
/**
 * After a successful paint action, we need the redux store to do some other work
 * before our components have everything they need to render post-paint, eg:
 * new stats, new row data, etc...
 *
 * A user interaction dispatches the initial paint action. Here is an epic
 * to dispatch the other actions that should be dispatched if the paint was successful.
 */
export const setPaintedCanvasVersionAsyncPaint: Epic<NotificationAction, any> =
  (action$, state$) => {
    const getLayerFilters = makeGetLayerFilters();

    const getLayerMetadata = makeGetLayerMetadata();
    return observableMerge(
      action$.pipe(
        ofNotificationType<ScenarioPaintTaskMessageBase>(
          TaskTypes.TASK_TYPE_SCENARIO_PAINT,
          TaskStatuses.DONE,
        ),
      ),
      action$.pipe(
        ofNotificationType<BaseScenarioPaintTaskMessageBase>(
          TaskTypes.TASK_TYPE_BASE_SCENARIO_PAINT,
          TaskStatuses.DONE,
        ),
      ),
    ).pipe(
      // Use merge instead of concat, since we
      // don't need these to dispatch in order
      map(action => {
        const {
          result: { canvas },
        } = action.result;
        // And refetch the data for the active layer and all its filters.
        // This will populate the table.
        const layerMetadata = getLayerMetadata(state$.value, {
          layerId: canvas?.full_path,
        });
        const projectId =
          action.result.scope_type === ScopeTypes.PROJECT
            ? action.result.scope
            : null;
        return { canvas, projectId, layerMetadata };
      }),
      filter(
        ({ canvas, projectId, layerMetadata }) =>
          !!canvas && !!projectId && !!layerMetadata,
      ),
      mergeMap(({ projectId, canvas, layerMetadata }) => {
        const { details, version } = canvas;
        const latestVersion = details?.latest_version ?? version;
        // Set the paint action id to begin updating the map
        const setPaintedCanvasVersionAction = updateLayerVersion(
          canvas.full_path,
          latestVersion,
        );

        const loadLayerDataAction = loadLayerData(
          canvas.full_path,
          {
            filters: getLayerFilters(state$.value, {
              projectId,
              layerId: canvas.full_path,
              parentLayerId: null,
            }),
          },
          layerMetadata,
        );
        return observableMerge(
          observableOf(setPaintedCanvasVersionAction),
          observableOf(loadLayerDataAction),
        );
      }),
    );
  };
export default combineEpics(
  {
    setLayerVersionsEpic,
    setPaintedCanvasVersionAsyncPaint,
  },
  'versions',
);
