import { AnyAction, Dispatch } from 'redux';

import { logEvent } from 'uf/logging';
import { LOG } from 'uf/logging/ActionTypes';

export function logAction(actionType: string, action: AnyAction) {
  return (dispatch: Dispatch) => {
    logEvent(actionType, action);

    dispatch({
      type: LOG,
      event: actionType,
      payload: action,
    });
  };
}
