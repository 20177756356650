import React from 'react';
import { VictoryLabelProps } from 'victory-core';

import textStyles from 'uf/styles/text.module.css';
import { THIN_SPACING } from 'uf/ui/base/spacing';

interface TextProps extends VictoryLabelProps {
  chartWidth: number;
  rightPadding: number;
  fontSize: number;
  fontFamily?: string;
  yAdjustment: number;
  label: string;
  alignRight?: boolean;
}

export const Text: React.FunctionComponent<TextProps> = props => {
  const {
    chartWidth,
    rightPadding,
    fontSize,
    label,
    yAdjustment,
    alignRight,
    fontFamily,
    ...textProps
  } = props;
  const y = textProps.y + yAdjustment;
  const height = fontSize + 4;
  const textAlign = alignRight ? 'right' : null;
  const textStyle: React.CSSProperties = {
    width: getClipWidth(chartWidth, rightPadding, alignRight),
    textAlign,
    fontFamily,
    fontSize,
  };

  return (
    <foreignObject y={y} height={height} width={chartWidth}>
      <div style={textStyle} className={textStyles.ellipsis}>
        {label}
      </div>
    </foreignObject>
  );
};
const getClipWidth = (
  chartWidth: number,
  rightPadding: number,
  alignRight: boolean,
): number => {
  const spacing = alignRight ? 0 : THIN_SPACING;
  return chartWidth - rightPadding - spacing;
};
