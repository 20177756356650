/**
 * UrbanFootprint App API
 * API for serving UrbanFootprint Application
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Details about an invalid param value.
 */
export interface AnalysisParameterValidationError<> {
  /**
   * A key representing the type of validation error.
   */
  error_key: AnalysisParameterValidationError.ErrorKeyEnum;
  /**
   * The analysis module the param belongs to.
   */
  module_key: string;
  /**
   * The key of the parameter.
   */
  param_key: string;
}
export namespace AnalysisParameterValidationError {
  export type ErrorKeyEnum = 'wrong_type' | 'invalid_choice' | 'out_of_range';
  export const ErrorKeyEnum = {
    WrongType: 'wrong_type',
    InvalidChoice: 'invalid_choice',
    OutOfRange: 'out_of_range',
  } as const;
}
