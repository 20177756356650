import { Expression } from 'mapbox-gl';

import { UFMapLayer } from 'uf/map';

const paintedGeometriesFilter: Expression = ['get', 'uf_is_painted'];

const unpaintedGeometriesFilter: Expression = ['!', ['get', 'uf_is_painted']];

export function makePaintedOnlyStyleLayers(styleLayer: UFMapLayer): [
  // The painted only features
  UFMapLayer,
  // Transparent, non-painted features
  UFMapLayer,
] {
  const paintedStyleLayer: UFMapLayer = {
    ...styleLayer,
    id: `${styleLayer.id}|painted`,
    filter: [...styleLayer.filter, paintedGeometriesFilter],
  };

  const unpaintedStyleLayer: UFMapLayer = {
    ...styleLayer,
    id: `${styleLayer.id}|not_painted`,
    filter: unpaintedGeometriesFilter,
    paint: { [`${styleLayer.type}-color`]: 'transparent' },
  };

  return [paintedStyleLayer, unpaintedStyleLayer];
}
