// Use for switch statements for exhaustiveness checking
export function assertNever(value: never, message?: string): never {
  throw new Error(message ? message : `Unknown value ${value}`);
}

export function rejectNever<T = any>(
  value: never,
  message?: string,
): Promise<T> {
  return Promise.reject(
    new Error(message ? message : `Unknown value ${value}`),
  );
}

/**
 * A runtime no-op but compile-time check to make sure something is `never`.
 *
 * Useful at the end of reducers.
 */
// eslint-disable-next-line @typescript-eslint/no-empty-function
export function typeAssertNever(value: never) {}
