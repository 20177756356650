import {
  CuratedColumnSymbology,
  CUSTOM_THEME,
  DEFAULT_OPACITY,
  InterpolationTypes,
  PaintColorTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/public/dataset/california_farm_mapping_monitoring_program': {
    type_long: [
      {
        type: SymbologyTypes.FILL,
        paint: {
          'fill-color': {
            property: 'type_long',
            type: PaintColorTypes.CATEGORICAL,
            stops: [
              { value: 'Prime Farmland', color: '#8cb25a' },
              { value: 'Farmland of Statewide Importance', color: '#bcd783' },
              { value: 'Unique Farmland', color: '#e2f7ae' },
              { value: 'Farmland of Local Importance', color: '#f9fcde' },
              { value: 'Local Potential', color: '#fcf6f2' },
              { value: 'Grazing Land', color: '#e3dbc4' },
              { value: 'Confined Animal Agriculture', color: '#b27b5a' },
              { value: 'Urban and Built-up Land', color: '#f29ca8' },
              { value: 'Rural Residential Land', color: '#f2cdd1' },
              {
                value: 'Semi-Agricultural and Rural Commercial Land',
                color: '#f4d387',
              },
              {
                value: 'Nonagricultural or Natural Vegetation',
                color: '#dbc399',
              },
              { value: 'Other Land', color: '#ebeced' },
              { value: 'Water', color: '#94ced4' },
              { value: 'Vacant/Disturbed Land', color: '#d1d0d2' },
              { value: 'Area not mapped', color: '#f3f3f4' },
            ],
          },
          'fill-opacity': DEFAULT_OPACITY,
        },
        display_hints: {
          scale: SymbologyScales.CATEGORICAL,
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          theme: CUSTOM_THEME,
        },
      },
    ],
  },
};

export default symbologies;
