import { FormGroup, InputGroup } from '@blueprintjs/core';
import React, {
  ChangeEvent,
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { t } from 'ttag';

import FormDialog from 'uf/ui/base/Modals/FormDialog';
import { View } from 'uf/views';

interface Props {
  view?: View;
  onChange: (view: View) => void;
  onCancel: () => void;
  show?: boolean;
}

export const EditViewDialog: FunctionComponent<Props> = props => {
  const { show, view, onChange, onCancel } = props;

  // When a view is changed, update the locally-edited properties
  const [viewName, setViewName] = useState<string>();
  useEffect(() => {
    setViewName(view?.name);
  }, [view]);
  const onChangeName = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setViewName(event.target.value);
  }, []);

  const onSubmit = useCallback(() => {
    onChange({
      ...view,
      name: viewName,
    });
  }, [onChange, view, viewName]);
  return (
    <FormDialog show={show} onOk={onSubmit} onCancel={onCancel}>
      <FormGroup label={t`Name the view`}>
        <InputGroup
          placeholder={t`New view`}
          onChange={onChangeName}
          value={viewName}
        />
      </FormGroup>
    </FormDialog>
  );
};
