import {
  CuratedColumnSymbology,
  InterpolationTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/public/dataset/cal_fire_cal_fire_fire_hazard_reduction_projects': {
    status: [
      {
        display_hints: {
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          scale: SymbologyScales.CATEGORICAL,
          theme: 'custom',
        },
        paint: {
          'fill-color': {
            default: '#2ca02c',
            property: 'status',
            stops: [
              {
                color: '#68bc00',
                value: 'Complete',
              },
              {
                color: '#e27300',
                value: 'Maint',
              },
            ],
            type: 'categorical',
          },
          'fill-opacity': 0.6,
        },
        type: SymbologyTypes.FILL,
      },
      {
        display_hints: {
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          scale: SymbologyScales.CATEGORICAL,
          theme: 'custom',
        },
        paint: {
          'line-color': '#999999',
          'line-opacity': 0.6,
          'line-width': 1,
        },
        type: SymbologyTypes.LINE,
      },
    ],
  },
};

export default symbologies;
