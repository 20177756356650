import colors from 'material-colors';

import {
  CuratedColumnSymbology,
  CUSTOM_THEME,
  DEFAULT_OPACITY,
  DistributionTypes,
  InterpolationTypes,
  PaintColorTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/public/dataset/california_health_disadvantage_index': {
    hdi_pctile: [
      {
        type: SymbologyTypes.FILL,
        paint: {
          'fill-color': {
            property: 'hdi_pctile',
            type: PaintColorTypes.INTERVAL,
            stops: [
              { value: 0, label: 'Ineligible', color: colors.white },
              {
                value: 0.01,
                label: '0 to 25 (Least Disadvantaged)',
                color: '#fff8ec',
              },
              { value: 26, label: '26 to 50', color: '#fee6c5' },
              { value: 51, label: '51 to 75', color: '#fec6ac' },
              {
                value: 76,
                label: '76 to 100 (Most Disadvantaged)',
                color: '#eb988f',
              },
            ],
          },
          'fill-opacity': DEFAULT_OPACITY,
        },
        display_hints: {
          scale: SymbologyScales.NUMERIC,
          distribution: DistributionTypes.DEFAULT,
          interpolation: InterpolationTypes.INTERVAL,
          theme: CUSTOM_THEME,
        },
      },
    ],
  },
};

export default symbologies;
