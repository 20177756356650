import { AnyAction } from 'redux';
import { ActionsObservable, Epic, ofType } from 'redux-observable';
import {
  combineLatest as observableCombineLatest,
  merge as observableMerge,
  of as observableOf,
} from 'rxjs';
import {
  distinct,
  distinctUntilChanged,
  distinctUntilKeyChanged,
  filter,
  map,
  mergeMap,
  scan,
  withLatestFrom,
} from 'rxjs/operators';
import warning from 'warning';

import {
  getProjectActionTypes,
  getProjectsV2ActionTypes,
  removeProjectWorkingLayerActionTypes,
  removeProjectWorkingLayerSuccessAction,
} from 'uf-api/api/project.service';
import {
  getUserDataActionTypes,
  getUserDataSuccessAction,
} from 'uf-api/api/userdata.service';
import { activeProjectReady, ensureActiveProject } from 'uf/app/actions';
import {
  ACTIVE_PROJECT_READY,
  ActiveProjectReadyAction,
  SET_ACTIVE_PROJECT,
  SetActiveProjectAction,
} from 'uf/app/ActionTypes';
import {
  getActiveProjectId,
  getActiveScenarioId,
  makeGetActiveScenarioIdForProject,
} from 'uf/app/selectors';
import { combineEpics } from 'uf/base/epics';
import { invert } from 'uf/base/objects';
import { getData } from 'uf/data/dataState';
import { setUIProperty } from 'uf/explore/actions';
import { clearActiveLayer } from 'uf/explore/actions/layers';
import { isPlaceHolder, makeLayerOrderKey } from 'uf/explore/layerOrdering';
import {
  getActiveLayerId,
  makeGetVisibleVirtualIdsByLayerIdMap,
} from 'uf/explore/selectors/layers';
import { makeGetActiveViewId } from 'uf/explore/selectors/views';
import {
  ensureProject,
  loadProject,
  loadProjectUpdateStatus,
  RemoveLayerExtra,
} from 'uf/projects/actions';
import {
  makeGetDevelopmentLayerForProject,
  makeGetProjectAreaLayerForProject,
} from 'uf/projects/selectors/layers';
import { makeGetProjectLayerIdMap } from 'uf/projects/selectors/virtualLayers';
import { LayerType, LegacyVirtualLayerId } from 'uf/projects/virtualLayers';
import { UFState } from 'uf/state';
import { VISIBLE_LAYER_IDS_PREFKEY } from 'uf/user';
import {
  makeMatchActionsWithPrefKey,
  parseProjectUserPrefKey,
} from 'uf/user/helpers';
import { getPersistedVisibleLayerVirtualIdsState } from 'uf/user/selectors/layers';
import {
  clearVisibleLayers,
  setLayerVisibility,
  visibleLayersListActions,
} from 'uf/views/actions/layers';

/**
 * Epics for dealing with project switching and creation
 */

/**
 * An epic to load the initial project
 *
 * When the app initially loads, a project is loaded for the user.  Normally, this will be the
 * last project that they had selected.  Otherwise, we select the first project that they have
 * in their project list.
 */
export function loadInitialProject(action$: ActionsObservable<AnyAction>) {
  return action$.ofType(getProjectsV2ActionTypes.SUCCESS).pipe(
    map(() =>
      ensureActiveProject({
        redirectToExplore: false,
        forceProjectReload: true,
        refreshMap: true,
      }),
    ),
  );
}

/**
 * An epic to update the scenarios and map position after a project is selected.
 *
 * Every time redux receives an action of type `uf/app/SET_ACTIVE_PROJECT`:
 *   - Recenter the map to the project's center lat/lng coordinates
 *   - Set the map zoom level to the project's starting map zoom level
 *   - Set the active scenario to null
 *   - Redirect to /explore
 */
export const setupActiveProject: Epic<SetActiveProjectAction, any> = (
  action$,
  state$,
) => {
  const getActiveViewId = makeGetActiveViewId();
  return action$.pipe(
    ofType(SET_ACTIVE_PROJECT),
    distinctUntilChanged((action1, action2) => action1.value === action2.value),
    filter(({ value: activeProjectId }) => !!activeProjectId),
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
      const allActions = [];

      const projectId = getActiveProjectId(state);
      const viewId = getActiveViewId(state, { projectId });

      // should we instead be marking all other projects stale
      // whenever you switch to a new project?
      if (action.forceProjectReload) {
        allActions.push(observableOf(loadProject(projectId, true)));
      } else {
        allActions.push(observableOf(ensureProject(projectId, true)));
      }

      const clearActiveLayerAction = clearActiveLayer(projectId);
      allActions.push(observableOf(clearActiveLayerAction));

      const clearVisibleLayersAction = clearVisibleLayers(projectId, viewId);
      allActions.push(observableOf(clearVisibleLayersAction));

      if (action.redirectToExplore) {
        allActions.push(
          observableOf(setUIProperty('exploreViewVisible', true)),
        );
      }

      return observableMerge(...allActions);
    }),
  );
};

/**
 * Emit a ACTIVE_PROJECT_READY action whenever the user activates a
 * loaded project, or switches to a loaded project and then loads
 * it. This is generally preferable to SET_ACTIVE_LAYER because the
 * layer is guaranteed to be both active *and* loaded.
 */
export function emitActiveProjectReady(action$: ActionsObservable<AnyAction>) {
  const projectLoad$ = action$.pipe(
    ofType(getProjectActionTypes.SUCCESS),
    map(({ key: projectId }) => projectId),
    distinct(),
  );

  // A stream of all projects that have ever loaded
  // i.e. [a], [a, b], [a, b, c], etc..
  const allLoaded$ = projectLoad$.pipe(
    scan((loadedProjectIds, projectId) => [...loadedProjectIds, projectId], []),
  );

  // A running stream of the currently active project, ignoring repeats but allowing duplicates
  const setActiveProject$ = action$.pipe(
    ofType(SET_ACTIVE_PROJECT),
    map(({ value: projectId }) => projectId),
    distinctUntilChanged(),
  );

  // A stream of project ids when the project loads before being set active
  const loadedFirst$ = setActiveProject$.pipe(
    withLatestFrom(allLoaded$),
    filter(([projectId, loaded]) => loaded.includes(projectId)),
    map(([projectId]) => projectId),
  );

  // a stream of project ids when the project is set active before the load
  const setActiveFirst$ = allLoaded$.pipe(
    withLatestFrom(setActiveProject$),
    filter(([loaded, projectId]) => loaded.includes(projectId)),
    map(([, projectId]) => projectId),
  );

  return observableMerge(
    // Each of these should be a stream that emits the active project id,
    // so that distinctUntilChanged will filter properly
    loadedFirst$,
    setActiveFirst$,
  ).pipe(
    distinctUntilChanged(),
    map(projectId => activeProjectReady(projectId)),
  );
}

export function ensureProjectUpdateStatus(
  action$: ActionsObservable<AnyAction>,
) {
  return action$.pipe(
    ofType(SET_ACTIVE_PROJECT),
    distinctUntilChanged((action1, action2) => action1.value === action2.value),
    filter(({ value: activeProjectId }) => !!activeProjectId),
    map(({ value: projectId }) => loadProjectUpdateStatus(projectId)),
  );
}

const matchVisibleLayerIdsPrefKey = makeMatchActionsWithPrefKey(
  VISIBLE_LAYER_IDS_PREFKEY,
);

/**
 * Whenever we load the first project or switch projects, activate the last persisted layers.
 */
export const setPersistedLayersVisibleOnSwitchProject: Epic<
  ActiveProjectReadyAction | getUserDataSuccessAction,
  any
> = (action$, state$) => {
  // since we load virtual ids,  we need to use active project ready here because we need to
  // grab the actual layer ids out of the project.
  const activeProjectReady$ = action$.pipe(
    ofType<ActiveProjectReadyAction>(ACTIVE_PROJECT_READY),
    distinctUntilKeyChanged('projectId'),
  );

  const getPersistedVisibleLayerVirtualIds$ = action$
    .ofType<getUserDataSuccessAction>(getUserDataActionTypes.SUCCESS)
    .pipe(
      filter(action => !!action.result),
      filter(action => {
        const isVisibleLayersPrefKey = matchVisibleLayerIdsPrefKey(action);
        return isVisibleLayersPrefKey;
      }),
      distinctUntilKeyChanged('key'),
    );

  const getActiveViewId = makeGetActiveViewId();
  const getVisibleVirtualIdsByLayerIdMap =
    makeGetVisibleVirtualIdsByLayerIdMap();
  return observableCombineLatest([
    activeProjectReady$,
    getPersistedVisibleLayerVirtualIds$,
  ]).pipe(
    // make sure the project ids are the same because combineLatest will emit the last value
    // when either of them emits so we need to make sure both streams are related to the same
    // project.
    filter(
      ([
        activeProjectReadyAction,
        loadPersistedVisibleLayerVirtualIdsAction,
      ]) => {
        const { projectId: persistedProjectId } = parseProjectUserPrefKey(
          loadPersistedVisibleLayerVirtualIdsAction.key,
        );
        const activeProjectId = activeProjectReadyAction.projectId;
        return persistedProjectId === activeProjectId;
      },
    ),
    withLatestFrom(state$),
    mergeMap(([[activeProject, persistedVisibleVirtualIds], state]) => {
      const { projectId } = activeProject;
      const scenarioId = getActiveScenarioId(state);
      const virtualIds: LegacyVirtualLayerId[] =
        persistedVisibleVirtualIds?.result?.value?.value;
      const viewId = getActiveViewId(state, { projectId });

      const layerIdsByVirtualIds = invert(
        getVisibleVirtualIdsByLayerIdMap(state, {
          projectId,
          scenarioId,
          viewId,
        }),
      );
      const actions = virtualIds
        .filter(virtualId => {
          const layerId = layerIdsByVirtualIds[virtualId];
          return layerId && !isPlaceHolder(layerId);
        })
        .map(virtualId => {
          // if we hydrate redux on a scenario that does not have the layer, we need to
          // insert a placeholder.  this typically happens with painted and analysis layers
          return observableOf(
            setLayerVisibility(projectId, virtualId, viewId, true),
          );
        });

      const setVirtualIds = observableOf(
        visibleLayersListActions.setList(
          Array.from(virtualIds),
          projectId,
          viewId,
        ),
      );

      // it is important to set the visible layer virtual ids in redux BEFORE setting layers
      // visible, otherwise the virtual id list will get overwritten to just the last layer set
      // visible
      return observableMerge(setVirtualIds, ...actions);
    }),
  );
};

/**
 * Whenever we switch projects, set default layers if no persisted layers found.
 */
export const setDefaultLayersVisibleOnSwitchProject: Epic<
  getUserDataSuccessAction | ActiveProjectReadyAction,
  any,
  UFState
> = (action$, state$) => {
  const getDevelopmentLayerForProject = makeGetDevelopmentLayerForProject();
  const getProjectAreaLayerForProject = makeGetProjectAreaLayerForProject();
  const getActiveViewId = makeGetActiveViewId();
  const getProjectLayerIdMap = makeGetProjectLayerIdMap();

  const activeProjectReady$ = action$.pipe(
    ofType<ActiveProjectReadyAction>(ACTIVE_PROJECT_READY),
    distinctUntilKeyChanged('projectId'),
  );

  const getPersistedVisibleLayerVirtualIds$ = action$.pipe(
    // Success action w/out a result is a 404, aka no persisted layers found
    ofType<getUserDataSuccessAction>(getUserDataActionTypes.SUCCESS),
    filter(action => !action.result),
    filter(action => {
      const isVisibleLayersPrefKey = matchVisibleLayerIdsPrefKey(action);
      return isVisibleLayersPrefKey;
    }),
    distinctUntilKeyChanged('key'),
  );

  return observableCombineLatest(
    activeProjectReady$,
    getPersistedVisibleLayerVirtualIds$,
  ).pipe(
    // make sure the project ids are the same because combineLatest will emit the last value
    // when either of them emits so we need to make sure both streams are related to the same
    // project.
    filter(([activeProjectReadyAction, loadPersistedVisibleLayerIdsAction]) => {
      const { projectId: persistedProjectId } = parseProjectUserPrefKey(
        loadPersistedVisibleLayerIdsAction.key,
      );
      const activeProjectId = activeProjectReadyAction.projectId;
      const projectIdsMatch = persistedProjectId === activeProjectId;
      return projectIdsMatch;
    }),
    withLatestFrom(state$),
    // make sure we don't already have persisted values
    filter(([action, state]) => {
      const activeProjectId = getActiveProjectId(state);
      const persistedVisibleLayerIdsState =
        getPersistedVisibleLayerVirtualIdsState(state, {
          projectId: activeProjectId,
        });
      return !getData(persistedVisibleLayerIdsState)?.value?.value;
    }),
    mergeMap(([action, state]) => {
      warning(
        false,
        'No persisted values found for visible layers.  Setting default layers visible.',
      );

      const projectId = getActiveProjectId(state);

      const viewId = getActiveViewId(state, { projectId });
      const layerIds = [];
      const projectAreaLayerId = getProjectAreaLayerForProject(state, {
        projectId,
      });
      if (projectAreaLayerId) {
        layerIds.push(projectAreaLayerId);
      }

      const baseCanvasLayerId = getDevelopmentLayerForProject(state, {
        projectId,
      });
      if (baseCanvasLayerId) {
        layerIds.push(baseCanvasLayerId);
      }

      const layerIdMap = getProjectLayerIdMap(state, { projectId });
      const actions = layerIds.map(layerId =>
        observableOf(
          setLayerVisibility(projectId, layerIdMap[layerId], viewId, true),
        ),
      );

      // if we are setting default ids, make sure to clear out any previously loaded virtual
      // ids from other projects.
      const setVirtualIdsAction = observableOf(
        visibleLayersListActions.setList(
          [
            'base:base',
            makeLayerOrderKey(LayerType.BOUNDARY, projectAreaLayerId),
          ],
          projectId,
          viewId,
        ),
      );

      return observableMerge(setVirtualIdsAction, ...actions);
    }),
  );
};

// TODO: Rmove this once selectors handle this case automatically
export const unsetActiveLayerAfterDeleteFromLayerList: Epic<
  removeProjectWorkingLayerSuccessAction & RemoveLayerExtra,
  any
> = (action$, state$) => {
  const getActiveViewId = makeGetActiveViewId();
  const getActiveScenarioForProject = makeGetActiveScenarioIdForProject();
  const getProjectLayerIdMap = makeGetProjectLayerIdMap();
  return action$.pipe(
    ofType(removeProjectWorkingLayerActionTypes.SUCCESS),
    withLatestFrom(state$),
    filter(([action, state]) => {
      const { projectId } = action;
      const scenarioId = getActiveScenarioForProject(state, {
        projectId,
      });
      const viewId = getActiveViewId(state, { projectId });
      return (
        action.layerId ===
        getActiveLayerId(state, {
          projectId,
          scenarioId,
          viewId,
        })
      );
    }),
    map(([action, state]) => {
      const { projectId, layerId } = action;
      const layerIdMap = getProjectLayerIdMap(state, { projectId });
      const viewId = getActiveViewId(state, { projectId });
      return setLayerVisibility(projectId, layerIdMap[layerId], viewId, false);
    }),
  );
};

export default combineEpics(
  {
    loadInitialProject,
    setupActiveProject,
    emitActiveProjectReady,
    ensureProjectUpdateStatus,
    setPersistedLayersVisibleOnSwitchProject,
    setDefaultLayersVisibleOnSwitchProject,
    unsetActiveLayerAfterDeleteFromLayerList,
  },
  'project',
);
