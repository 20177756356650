import _ from 'lodash';
import { AnyAction } from 'redux';
import { ActionsObservable, Epic, ofType } from 'redux-observable';
import { merge as observableMerge, of as observableOf } from 'rxjs';
import {
  filter,
  first,
  groupBy,
  map,
  mergeMap,
  switchMap,
  tap,
  throttleTime,
  withLatestFrom,
} from 'rxjs/operators';
import { t } from 'ttag';
import warning from 'warning';

import {
  enqueuePaintBaseScenarioCanvasActionTypes,
  enqueuePaintBaseScenarioCanvasSuccessAction,
  enqueuePaintScenarioCanvasActionTypes,
  enqueuePaintScenarioCanvasSuccessAction,
} from 'uf-api/api/paint.service';
import {
  createProjectActionTypes,
  getProjectActionTypes,
} from 'uf-api/api/project.service';
import { ScopeTypes } from 'uf-ws/WebsocketActions';
import { SetActiveProjectAction, SET_ACTIVE_PROJECT } from 'uf/app/ActionTypes';
import {
  getActiveProjectId,
  getActiveScenarioCanvasLayerId,
  makeGetActiveScenarioIdForProject,
} from 'uf/app/selectors';
import { addAppMessage } from 'uf/appservices/actions';
import { combineEpics } from 'uf/base/epics';
import { typeAssertNever } from 'uf/base/never';
import { invert } from 'uf/base/objects';
import { setDetailsPaneTabKey, setUIProperty } from 'uf/explore/actions';
import {
  SetActiveAnalysisModuleKeyOptions,
  setActiveLayerByModuleKey,
} from 'uf/explore/actions/analysis';
import {
  clearActiveLayer,
  ensureLayerMapColumnKey,
  setActiveLayer,
} from 'uf/explore/actions/layers';
import { updateMapMode } from 'uf/explore/actions/map';
import { addToRecentPaintHistory } from 'uf/explore/actions/paint';
import {
  ADD_CHART,
  BIND_PAINT_BASE_SCENARIO_TASK,
  BIND_PAINT_SCENARIO_TASK,
  MAP_ERROR,
  SetActiveLayerAction,
  SetUIPropertyAction,
  SET_ACTIVE_LAYER,
  SET_DETAILS_BUILD_TAB,
  SET_UI_PROPERTY,
} from 'uf/explore/ActionTypes';
import { TabKey } from 'uf/explore/details';
import {
  getActiveLayerId,
  makeGetVisibleVirtualLayerIds,
} from 'uf/explore/selectors/layers';
import { getCurrentPaintingBuiltForm } from 'uf/explore/selectors/paint';
import { makeGetActiveViewId } from 'uf/explore/selectors/views';
import { ensureLayerMetadata } from 'uf/layers/actions/metadata';
import { makeGetLayerMetadata } from 'uf/layers/selectors/metadata';
import { MapMode } from 'uf/map/mapmode';
import { parseTileSourceId } from 'uf/map/tileSources';
import { ScenarioBuiltFormPaintTaskMessage } from 'uf/painting/tasks';
import { ProjectId } from 'uf/projects';
import { loadProject } from 'uf/projects/actions';
import { makeGetScenarioCanvasLayerId } from 'uf/projects/selectors/layers';
import {
  makeGetProjectLayerIdMap,
  makeGetScenarioLayerIdMap,
} from 'uf/projects/selectors/virtualLayers';
import { UFState } from 'uf/state';
import { NotificationAction } from 'uf/tasks/ActionTypes';
import { makeBindEpic } from 'uf/tasks/epicHelpers';
import { ofNotificationType } from 'uf/tasks/observables';
import { TaskStatuses } from 'uf/tasks/TaskStatuses';
import { TaskTypes } from 'uf/tasks/TaskTypes';
import { DEFAULT_VIEW_ID } from 'uf/views';
import { visibleLayersListActions } from 'uf/views/actions/layers';
import {
  SetLayerVisibilityAction,
  SET_LAYER_VISIBILITY,
} from 'uf/views/ActionTypes';

const MAP_ERROR_COOLDOWN = 1000;

// third argument 'shouldReject' passed in to disble unhandled promise rejections in test
export const showAppMessageWhenMapErrors: Epic = (
  action$: ActionsObservable<AnyAction>,
  state$,
) => {
  const getLayerMetadata = makeGetLayerMetadata();
  return action$.pipe(
    ofType(MAP_ERROR),
    // throttle notifying user by sourceId
    // errors without a sourceId will only display 1 error in a cooldown period
    groupBy(({ error }) => error.sourceId),
    mergeMap(grouped =>
      grouped.pipe(
        throttleTime(MAP_ERROR_COOLDOWN),
        map(action => {
          const {
            error: { sourceId },
          } = action;
          if (sourceId) {
            // lookup layer name with the source id
            const { layerId } = parseTileSourceId(sourceId);
            const layerMetadata = getLayerMetadata(state$.value, { layerId });
            return addAppMessage(
              t`Something isn't right with layer "${layerMetadata.name}". Try refreshing your browser. If that does not work, please contact UrbanFootprint support`,
              { status: 'failure' },
            );
          }
          const mostSpecificError = action.error?.error || action.error;
          return addAppMessage(
            t`Something isn't right with a layer; "${mostSpecificError}". Try refreshing your browser. If that does not work, please contact UrbanFootprint support`,
            { status: 'failure' },
          );
        }),
      ),
    ),
  );
};

/**
 * Epics are another way to dispatch actions to the store,
 * and a convenient way to do async redux management.
 *
 * With redux-observable middleware installed, an action hits the
 * reducers then is passed as a stream object to your "epics".
 *
 * Once inside an epic, use any RxJS Observable patterns you like
 * and be sure the final, returned stream is an action.
 *
 * The returned stream action will be dispatched to the store immediately.
 */

export function showHistogramsWhenRequested(
  action$: ActionsObservable<AnyAction>,
) {
  return action$
    .ofType(ADD_CHART)
    .pipe(map(() => setUIProperty('chartPanelVisible', true)));
}

// This is a hack: we need to load metadata for all the painted
// layers, so they can be consolidated in the layer list with their
// base. This is temporary until we have an API to load
// layer info for a project.
export const ensureMetadataForPaintedLayers: Epic<AnyAction, any> = (
  action$,
  state$,
) => {
  return action$.pipe(
    ofType(getProjectActionTypes.SUCCESS),
    map(() => getActiveScenarioCanvasLayerId(state$.value)),
    filter(_.identity),
    map(paintedCanvasId => ensureLayerMetadata(paintedCanvasId)),
  );
};

// TODO: Make the act of setting an active layer trigger two actions,
// one to set it visible, and another to set it as the active layer.
const updateMapModeOnDeactivateLayer: Epic<SetActiveLayerAction, any> = (
  action$,
  state$,
  dep,
) => {
  return action$.pipe(
    ofType(SET_ACTIVE_LAYER),
    filter(action => !action.active),
    map(({ projectId }) => {
      return updateMapMode(projectId, MapMode.NORMAL);
    }),
  );
};

const updateMapModeOnActiveLayerNotVisible: Epic<
  SetLayerVisibilityAction,
  any
> = (action$, state$) => {
  const getActiveScenario = makeGetActiveScenarioIdForProject();
  return action$.pipe(
    ofType(SET_LAYER_VISIBILITY),
    filter(({ projectId }) => {
      const scenarioId = getActiveScenario(state$.value, { projectId });
      const viewId = makeGetActiveViewId()(state$.value, { projectId });
      return !getActiveLayerId(state$.value, { projectId, scenarioId, viewId });
    }),
    map(action => updateMapMode(action.projectId, MapMode.NORMAL)),
  );
};
export const resetMapToNormalMode = combineEpics(
  {
    updateMapModeOnDeactivateLayer,
    updateMapModeOnActiveLayerNotVisible,
  },
  'resetMapToNormalMode',
);
export const bindPaintScenarioTaskToProjectScenario =
  makeBindEpic<enqueuePaintScenarioCanvasSuccessAction>(
    enqueuePaintScenarioCanvasActionTypes.SUCCESS,
    BIND_PAINT_SCENARIO_TASK,
  );
export const bindPaintBaseScenarioTaskToProjectScenario =
  makeBindEpic<enqueuePaintBaseScenarioCanvasSuccessAction>(
    enqueuePaintBaseScenarioCanvasActionTypes.SUCCESS,
    BIND_PAINT_BASE_SCENARIO_TASK,
  );

// When a paint is successful, add the current built form to the recent bar
export const addToRecentBuiltFormsOnAsyncPaint: Epic<NotificationAction, any> =
  (action$, state$) => {
    return observableMerge(
      action$.pipe(
        ofNotificationType<ScenarioBuiltFormPaintTaskMessage>(
          TaskTypes.TASK_TYPE_SCENARIO_PAINT,
          TaskStatuses.DONE,
        ),
      ),
      action$.pipe(
        ofNotificationType<ScenarioBuiltFormPaintTaskMessage>(
          TaskTypes.TASK_TYPE_BASE_SCENARIO_PAINT,
          TaskStatuses.DONE,
        ),
      ),
    ).pipe(
      filter(action => action.result.result.painter === 'built_form'),
      map(action => {
        const projectId =
          action.result.scope_type === ScopeTypes.PROJECT
            ? action.result.scope
            : null;
        const builtFormKey = action.result.result.params.built_form_key;
        return { projectId, builtFormKey };
      }),
      filter(({ projectId, builtFormKey }) => !!projectId && !!builtFormKey),
      map(({ projectId, builtFormKey }) => ({
        projectId,
        currentBuiltForm: getCurrentPaintingBuiltForm(state$.value, {
          projectId,
        }),
        builtFormKey,
      })),
      // Make sure it is still the most current. A better solution would be to
      // look this up in the master list of builtforms, but that list is already
      // divided up by type
      filter(
        ({ currentBuiltForm, builtFormKey }) =>
          builtFormKey && currentBuiltForm?.builtFormKey === builtFormKey,
      ),
      map(({ projectId, currentBuiltForm }) =>
        addToRecentPaintHistory(
          projectId,
          currentBuiltForm.builtFormType,
          currentBuiltForm.builtFormKey,
        ),
      ),
    );
  };

/**
 * When
 * @param action$
 * @param state$
 */
export const reloadProjectAfterPaint: Epic<NotificationAction, any> = (
  action$,
  state$,
) => {
  return observableMerge(
    action$.pipe(
      ofNotificationType<ScenarioBuiltFormPaintTaskMessage>(
        TaskTypes.TASK_TYPE_SCENARIO_PAINT,
        TaskStatuses.DONE,
      ),
    ),
    action$.pipe(
      ofNotificationType<ScenarioBuiltFormPaintTaskMessage>(
        TaskTypes.TASK_TYPE_BASE_SCENARIO_PAINT,
        TaskStatuses.DONE,
      ),
    ),
  ).pipe(
    filter(action => {
      warning(
        !!action?.result?.info?.project?.full_path,
        'Missing project in websocket message',
      );
      return !!action?.result?.info?.project;
    }),
    map(action => {
      const projectId: ProjectId = action.result.info.project.full_path;
      return loadProject(projectId);
    }),
  );
};

export const activateLayerWhenAnalysisModuleActivated: Epic<
  SetUIPropertyAction<string, SetActiveAnalysisModuleKeyOptions>,
  any
> = (action$, state$) => {
  const getActiveViewId = makeGetActiveViewId();
  const getActiveScenarioIdForProject = makeGetActiveScenarioIdForProject();
  return action$.pipe(
    ofType(SET_UI_PROPERTY),
    filter(
      action =>
        action.property === 'activeAnalysisModuleKey' &&
        action.extra &&
        action.extra.activateLayers,
    ),
    withLatestFrom(state$),
    map(([action, state]) => {
      const projectId = getActiveProjectId(state);
      const viewId = getActiveViewId(state, { projectId });
      const scenarioId = getActiveScenarioIdForProject(state, {
        projectId,
      });
      return setActiveLayerByModuleKey(
        projectId,
        viewId,
        scenarioId,
        action.value,
      );
    }),
  );
};

// make sure there is always a mapped column key
export const ensureMapColumnKeyForVisibleLayer: Epic<
  SetLayerVisibilityAction | SetActiveLayerAction,
  any
> = (action$, state$) => {
  const getScenarioLayerIdMap = makeGetScenarioLayerIdMap();
  const getActiveScenarioId = makeGetActiveScenarioIdForProject();
  const getActiveViewId = makeGetActiveViewId();
  return action$.pipe(
    // TODO: Make this just SET_LAYER_VISIBILITY once set active layer has an
    // epic that triggers visibility
    ofType(SET_ACTIVE_LAYER, SET_LAYER_VISIBILITY),
    filter(
      action =>
        (action.type === SET_LAYER_VISIBILITY && action.visible) ||
        (action.type === SET_ACTIVE_LAYER && action.active),
    ),
    tap(action => {
      warning(!!action.virtualLayerId, `Missing layerId in ${action.type}`);
    }),
    // TODO: figure out why these are sometimes coming through without a layer id
    filter(action => !!action.virtualLayerId),
    withLatestFrom(state$),
    map(([action, state]) => {
      const { projectId } = action;
      const viewId =
        action.type === SET_LAYER_VISIBILITY
          ? action.viewId
          : getActiveViewId(state, { projectId });
      const scenarioId = getActiveScenarioId(state, {
        projectId,
      });
      const layerMap = getScenarioLayerIdMap(state, {
        projectId,
        scenarioId,
      });
      const virtualLayerIdToLayerId = invert(layerMap);
      const layerId = virtualLayerIdToLayerId[action.virtualLayerId];
      return ensureLayerMapColumnKey(projectId, viewId, layerId);
    }),
  );
};

/*
 * Keep a list of virtual ids for the visible layers.
 * Notice we use a Set here to avoid duplicates since multiple
 * layerIds can map to the same virtualId.
 */
export const updateVisibleLayerVirtualIds: Epic<
  SetActiveLayerAction | SetLayerVisibilityAction,
  any,
  UFState
> = (action$, state$) => {
  const getVisibleVirtualLayerIds = makeGetVisibleVirtualLayerIds();
  return action$.pipe(
    ofType(SET_ACTIVE_LAYER, SET_LAYER_VISIBILITY),
    withLatestFrom(state$),
    map(([action, state]) => {
      const { projectId, virtualLayerId } = action;
      const viewId = getViewId(state, projectId, action);
      const virtualIds = new Set(
        getVisibleVirtualLayerIds(state, {
          projectId,
          viewId,
        }),
      );

      switch (action.type) {
        case SET_ACTIVE_LAYER: {
          if (action.active) {
            virtualIds.add(virtualLayerId);
          }
          break;
        }

        case SET_LAYER_VISIBILITY: {
          if (action.visible) {
            virtualIds.add(virtualLayerId);
          } else {
            virtualIds.delete(virtualLayerId);
          }
          break;
        }
        default:
          typeAssertNever(action);
      }
      return visibleLayersListActions.setList(
        Array.from(virtualIds),
        projectId,
        viewId,
      );
    }),
  );
};

/**
 * Get the viewid depending on the action
 *
 * TODO: put the viewId in SetLayerVisibilityAction and get rid of this
 */
function getViewId(
  state: UFState,
  projectId: ProjectId,
  action: SetActiveLayerAction | SetLayerVisibilityAction,
) {
  switch (action.type) {
    case SET_ACTIVE_LAYER:
      return makeGetActiveViewId()(state, { projectId });
    case SET_LAYER_VISIBILITY:
      return action.viewId;
    default:
      typeAssertNever(action);
      return DEFAULT_VIEW_ID;
  }
}

export function setDefaultExploreStateForNewProjects(
  action$: ActionsObservable<AnyAction>,
) {
  return action$.pipe(
    ofType(createProjectActionTypes.SUCCESS),
    switchMap(createProjectAction =>
      action$
        .ofType(SET_ACTIVE_PROJECT)
        .pipe(
          first(
            (setActiveProjectAction: SetActiveProjectAction) =>
              createProjectAction.result.full_path ===
              setActiveProjectAction.value,
          ),
        ),
    ),
    mergeMap((activeProjectAction: SetActiveProjectAction) => {
      const { value: projectId } = activeProjectAction;
      return observableMerge(
        observableOf(clearActiveLayer(projectId)),
        observableOf(setUIProperty('exploreViewVisible', true)),
        observableOf(setUIProperty('chartPanelVisible', false)),
        observableOf(setUIProperty('paintUIVisible', false)),
        observableOf(setUIProperty('analysisUIVisible', false)),
      );
    }),
  );
}

export const setBuildTabVisibleForProject: Epic<AnyAction, any> = (
  action$,
  state$,
) => {
  // TODO: move these out
  const getScenarioCanvasLayerId = makeGetScenarioCanvasLayerId();
  const getProjectLayerMap = makeGetProjectLayerIdMap();
  return action$.pipe(
    ofType(SET_DETAILS_BUILD_TAB),
    mergeMap(action => {
      const { projectId, scenarioId } = action;
      const state = state$.value;
      const layerId = getScenarioCanvasLayerId(state, {
        projectId,
        scenarioId,
      });
      const layerMap = getProjectLayerMap(state, { projectId });
      const virtualLayerId = layerMap[layerId];
      return observableMerge(
        observableOf(setActiveLayer(projectId, virtualLayerId)),
        observableOf(setDetailsPaneTabKey(projectId, layerId, TabKey.BUILD)),
      );
    }),
  );
};
export default combineEpics(
  {
    showAppMessageWhenMapErrors,
    showHistogramsWhenRequested,
    ensureMetadataForPaintedLayers,
    addToRecentBuiltFormsOnAsyncPaint,
    resetMapToNormalMode,
    activateLayerWhenAnalysisModuleActivated,
    ensureMapColumnKeyForVisibleLayer,
    updateVisibleLayerVirtualIds,
    setDefaultExploreStateForNewProjects,
    setBuildTabVisibleForProject,
    bindPaintScenarioTaskToProjectScenario,
    bindPaintBaseScenarioTaskToProjectScenario,
    reloadProjectAfterPaint,
  },
  'explore',
);
