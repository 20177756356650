import { Action } from 'redux';

export interface LoadActionTypes {
  LOAD: string;
  SUCCESS: string;
  FAILURE: string;
  CLEAR?: string;
}

export interface BaseAction extends Action {
  key?: string;
  extra?: any;
  [extraProps: string]: any;
}

export interface LoadStateAction extends BaseAction {
  requestId: string;
  requestTime?: string;
  responseTime?: string;
}

export interface LoadAction extends LoadStateAction {
  promise: Promise<any>;
}

export interface SuccessAction<T> extends LoadStateAction {
  result: T;
}

export interface FailureAction<E = any> extends LoadStateAction {
  error: E;
}

export type ClearAction = BaseAction;

export type UpdateStateAction<T, E = any> =
  | LoadAction
  | SuccessAction<T>
  | FailureAction<E>
  | ClearAction;

export function makeAsyncActionTypes(actionTypeBase: string): LoadActionTypes {
  return {
    LOAD: `${actionTypeBase}/LOAD`,
    SUCCESS: `${actionTypeBase}/SUCCESS`,
    FAILURE: `${actionTypeBase}/FAILURE`,
    CLEAR: `${actionTypeBase}/CLEAR`,
  };
}

/**
 * Create LOAD/SUCCESS/FAILURE/CLEAR action types when you already
 * have action types defined.
 */
export function makeCustomAsyncActionTypes(
  loadActionType: string,
  successActionType: string,
  failureActionType: string,
  clearActionType: string = null,
): LoadActionTypes {
  const actionTypes: LoadActionTypes = {
    LOAD: `${loadActionType}`,
    SUCCESS: `${successActionType}`,
    FAILURE: `${failureActionType}`,
  };

  if (clearActionType) {
    actionTypes.CLEAR = `${clearActionType}`;
  }

  return actionTypes;
}
