import { Button, Classes, Dialog, H6 } from '@blueprintjs/core';
import classNames from 'classnames';
import React, { useState } from 'react';
import { Box } from 'react-layout-components';
import { useDispatch, useSelector } from 'react-redux';
import { jt, t } from 'ttag';
import { useGetOrgFileUrlQuery } from 'uf-api-rtk/store/Api';
import { getActiveOrganizationKey } from 'uf/app/selectors';
import { addAppMessage } from 'uf/appservices/actions';
import { showIntercom } from 'uf/intercom/actions';
import {
  dialogClosed,
  selectLocationAnalysisDialog,
} from 'uf/location-analysis/locationAnalysisDialogSlice';
import tw from 'uf/tailwindcss-classnames';
import FileUpload from 'uf/ui/base/FileUpload/FileUpload';
import InlineLink from 'uf/ui/base/InlineLink/InlineLink';
import MessageArea from 'uf/ui/base/MessageArea/MessageArea';
import { dialogClasses, Sizes } from 'uf/ui/base/sizes';
import { uploadFileToSignedUrl } from 'uf/ui/settings/FileUploadSettings/useSignedUpload';

const FILE_UPLOAD_SIZE = 1e9; // 1GB

const LocationAnalysisDialog = () => {
  const dispatch = useDispatch();
  const [file, setFile] = useState<File | null>(null);
  const dialog = useSelector(selectLocationAnalysisDialog);
  const orgKey = useSelector(getActiveOrganizationKey);
  const { data } = useGetOrgFileUrlQuery(
    {
      key: orgKey,
      file: file?.name,
      method: 'PUT',
    },
    { skip: !file || !orgKey },
  );
  const handleIntercomClick = () => {
    dispatch(showIntercom());
  };
  const handleDrop = newFile => {
    setFile(newFile);
  };
  const handleClose = () => {
    setFile(null);
    dispatch(dialogClosed());
  };
  const handleSubmit = async () => {
    await uploadFileToSignedUrl(file, data?.url)
      .then(() => {
        dispatch(
          addAppMessage('File uploaded', {
            level: 'info',
            status: 'success',
          }),
        );
        dispatch(dialogClosed());
      })
      .catch(() => {
        dispatch(
          addAppMessage('Error uploading file', {
            level: 'danger',
            status: 'failure',
          }),
        );
      });
  };
  const intercomLink = (
    <InlineLink onClick={handleIntercomClick} text="Intercom" showIcon />
  );
  const documentationLink = (
    <InlineLink
      href="https://docs.urbanfootprint.com/resilienceinsights/en/index-en.html?contextId=uploadlas"
      text="run the Location Analysis Service"
    />
  );
  return (
    <Dialog
      onClose={handleClose}
      className={classNames(tw('tw-bg-white'), dialogClasses[Sizes.MEDIUM])}
      isOpen={dialog.isOpen}
      isCloseButtonShown={false}
      title="Location Analysis Service">
      <Box column className={Classes.DIALOG_BODY}>
        <p className={tw('tw-pb-10')}>
          {jt`Upload a CSV file with point locations or a GeoJSON file with lines to ${documentationLink}. We will add the associated contextual insights layers to your organization and let you know when they are ready.`}
        </p>
        <H6>{t`Upload Assets`}</H6>
        <FileUpload
          accept=".zip,.csv,.geojson"
          small
          maxFileSize={FILE_UPLOAD_SIZE}
          onDrop={handleDrop}
          emptyMessage={t`Drop your files here, or click to upload them.`}
        />
        <MessageArea className={tw('tw-m-0', 'tw-mt-2')}>
          {jt`Have any questions or need help finding locations? Reach out to our team via ${intercomLink}`}
        </MessageArea>
      </Box>
      <div className={Classes.DIALOG_FOOTER}>
        <Box alignSelf="flex-end" className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button onClick={handleClose}>{t`Cancel`}</Button>
          <Button
            data-testid="location-analysis-submit-button"
            disabled={!file}
            onClick={handleSubmit}
            intent="primary">
            {t`Request Location Analysis`}
          </Button>
        </Box>
      </div>
    </Dialog>
  );
};

export default LocationAnalysisDialog;
