/**
 * UrbanFootprint App API
 * API for serving UrbanFootprint Application
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { ClientResponse } from '../variables';

// @ts-ignore: This is used only for params, but some services have param-free APIs
import { SummaryServiceInterface } from './summary.serviceInterface';

// @ts-ignore: These imports are for both params and returnType, but we only use returnType
import { ProblemDetail } from '../model/problemDetail';
// @ts-ignore: These imports are for both params and returnType, but we only use returnType
import { ProgramLevelSummary } from '../model/programLevelSummary';

/**
 * Package: api
 */

const getProgramLevelSummaryPrefix = `uf-api/summary/get_program_level_summary/`;
/**
 * Action types for summary.get_program_level_summary
 *
 * Get full program level summary for a canvas layer.
 */
export const getProgramLevelSummaryActionTypes = {
  LOAD: `${getProgramLevelSummaryPrefix}LOAD` as 'uf-api/summary/get_program_level_summary/LOAD',
  SUCCESS:
    `${getProgramLevelSummaryPrefix}SUCCESS` as 'uf-api/summary/get_program_level_summary/SUCCESS',
  FAILURE:
    `${getProgramLevelSummaryPrefix}FAILURE` as 'uf-api/summary/get_program_level_summary/FAILURE',
  CLEAR:
    `${getProgramLevelSummaryPrefix}CLEAR` as 'uf-api/summary/get_program_level_summary/CLEAR',
} as const;

/**
 * LOAD action for summary.get_program_level_summary
 * Get full program level summary for a canvas layer.
 */
export interface getProgramLevelSummaryLoadAction {
  readonly type: 'uf-api/summary/get_program_level_summary/LOAD';
  readonly params: SummaryServiceInterface.getProgramLevelSummaryParams;
  readonly promise: Promise<ClientResponse<ProgramLevelSummary>>;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
  readonly key?: string;
}

/**
 * SUCCESS action for summary.get_program_level_summary
 * Get full program level summary for a canvas layer.
 */
export interface getProgramLevelSummarySuccessAction {
  readonly type: 'uf-api/summary/get_program_level_summary/SUCCESS';
  readonly result: ProgramLevelSummary;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * FAILURE action for summary.get_program_level_summary
 * Get full program level summary for a canvas layer.
 */
export interface getProgramLevelSummaryFailureAction {
  readonly type: 'uf-api/summary/get_program_level_summary/FAILURE';
  readonly error: any;
  readonly key?: string;
  readonly requestId: string;
  readonly requestTime: string;
  readonly responseTime: string;
}

/**
 * CLEAR action for summary.get_program_level_summary
 * Get full program level summary for a canvas layer.
 */
export interface getProgramLevelSummaryClearAction {
  readonly type: 'uf-api/summary/get_program_level_summary/CLEAR';
  readonly key: string;
}

export const summaryActionTypesMap = {
  /** Action types for summary.get_program_level_summary */
  get_program_level_summary: getProgramLevelSummaryActionTypes,
} as const;

export const summaryDetailsMap = {
  get_program_level_summary: {
    /** HTTP method for summary.get_program_level_summary */
    httpMethod: 'get' as const,
  },
} as const;

/**
 * Local DataState<T> - kept in sync with uf/data/dataState.ts
 */
interface DataState<T> {
  readonly loading: boolean;
  readonly loaded: boolean;
  readonly requestId: string;
  readonly requestTime?: string;
  readonly responseTime?: string;
  readonly requestCount?: number;
  readonly error?: any;
  readonly promise?: Promise<T>;
  readonly data?: T;
  readonly extra?: any;
  readonly key?: string;
}
export interface SummaryServiceState {
  /** getProgramLevelSummary state, by key */
  get_program_level_summary: {
    readonly [key: string]: DataState<ProgramLevelSummary>;
  };
}
