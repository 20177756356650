import { createSelector } from 'reselect';

import { LayerMetadata, LayerStats } from 'uf-api';
import { makeGetFromPropsSelector } from 'uf/base/selector';
import { DataState, getData, isLoaded } from 'uf/data/dataState';
import { ColumnKey, LayerId } from 'uf/layers';
import {
  makeGetLayerMetadataState,
  makeGetLayerMetadataStateGetter,
} from 'uf/layers/selectors/metadata';
import { ProjectId } from 'uf/projects';
import { LayerColumnSymbology } from 'uf/symbology';
import { getCleanedSymbologyDataState } from 'uf/symbology/selectors/curated/clean';
import {
  makeGetCuratedSymbologyLoadingState,
  makeGetCuratedSymbologyLoadingStateGetter,
} from 'uf/symbology/selectors/curated/loadingState';
import {
  makeGetSymbologyStatsState,
  makeGetSymbologyStatsStateGetter,
} from 'uf/symbology/selectors/stats';
import KeySymbologies from 'uf/symbology/styles/KeySymbologies';
import { getSymbologyKey } from 'uf/symbology/symbologyKey';
import { makeGetUserFlag } from 'uf/user/selectors/flags';

/**
 * Lookup a curated symbology by match on metadata `symbology_key` display hint.
 */
export function makeGetCuratedSymbologyFromKeySymbologiesFile() {
  return createSelector(
    makeGetCuratedSymbologyLoadingState(),
    makeGetFromPropsSelector<LayerId, 'layerId'>('layerId'),
    makeGetFromPropsSelector<ColumnKey, 'columnKey'>('columnKey'),
    makeGetLayerMetadataState(),
    makeGetSymbologyStatsState(),
    makeGetUserFlag('symbology-symbols'),
    (
      loadingState,
      layerId,
      columnKey,
      layerMetadataState,
      layerStatsState,
      hasSymbolSupportFlag,
    ) => {
      return getCuratedSymbologyFromKeySymbologiesFile(
        layerId,
        columnKey,
        loadingState,
        layerMetadataState,
        layerStatsState,
        hasSymbolSupportFlag,
      );
    },
  );
}
export function makeGetCuratedSymbologyFromKeySymbologiesFileGetter() {
  return createSelector(
    makeGetCuratedSymbologyLoadingStateGetter(),
    makeGetLayerMetadataStateGetter(),
    makeGetSymbologyStatsStateGetter(),
    makeGetUserFlag('symbology-symbols'),
    (
        getLoadingState,
        getLayerMetadataState,
        getLayerStatsState,
        hasSymbolSupportFlag,
      ) =>
      (projectId: ProjectId, layerId: LayerId, columnKey: ColumnKey) => {
        const loadingState = getLoadingState(projectId, layerId, columnKey);
        const layerMetadataState = getLayerMetadataState(layerId);
        const layerStatsState = getLayerStatsState(
          projectId,
          layerId,
          columnKey,
        );
        return getCuratedSymbologyFromKeySymbologiesFile(
          layerId,
          columnKey,
          loadingState,
          layerMetadataState,
          layerStatsState,
          hasSymbolSupportFlag,
        );
      },
  );
}

function getCuratedSymbologyFromKeySymbologiesFile(
  layerId: LayerId,
  columnKey: ColumnKey,
  loadingState: DataState<null>,
  layerMetadataState: DataState<LayerMetadata>,
  layerStatsState: DataState<LayerStats>,
  hasSymbolSupportFlag: boolean,
): DataState<LayerColumnSymbology[]> {
  if (!isLoaded(loadingState)) {
    return loadingState;
  }

  const layerMetadata = getData(layerMetadataState);
  const layerStats = getData(layerStatsState);

  // If we have an exact match for this layer we'll use it
  const hardcodedSymbologies = findKeySymbologies(layerMetadata, columnKey);

  if (hardcodedSymbologies) {
    return getCleanedSymbologyDataState(
      layerId,
      columnKey,
      layerMetadata,
      layerStats,
      hardcodedSymbologies,
      loadingState,
      hasSymbolSupportFlag,
    );
  }

  return loadingState;
}

function findKeySymbologies(
  layerMetadata: LayerMetadata,
  columnKey: ColumnKey,
) {
  const symbologyKey = getSymbologyKey(layerMetadata, columnKey);
  const keySymbologies = KeySymbologies[symbologyKey];
  return keySymbologies;
}
