import { combineEpics } from 'uf/base/epics';

import editorEpics from './editor';
import routingEpics from './routing';

export default combineEpics(
  {
    editorEpics,
    routingEpics,
  },
  'builtFormsEpics',
);
