import { Dispatch, Store } from 'redux';
import { RouteThunk } from 'redux-first-router';
import watch from 'redux-watch';

import { parseFullPath } from 'uf/base/dataset';
import { setUIProperty } from 'uf/explore/actions';
import { setExploreNewRoute } from 'uf/explore/actions/routing';
import { makeGetExploreUIProperty } from 'uf/explore/selectors/explore';
import {
  ExploreContext,
  makeGetExploreContext,
} from 'uf/explore/selectors/routing';
import { SyncStateWithUrl } from 'uf/routing/state';

export const syncExploreStateWithUrl: SyncStateWithUrl = (store: Store) => {
  const getExploreContext = makeGetExploreContext();

  const watchExploreState = watch(() => getExploreContext(store.getState()));

  const unsubscribe = store.subscribe(
    watchExploreState((newState: ExploreContext) => {
      const {
        exploreViewVisible,
        projectId,
        exploreModeProjectUrlsFlagEnabled,
      } = newState;

      if (!exploreViewVisible || !projectId) {
        return;
      }

      if (exploreModeProjectUrlsFlagEnabled) {
        const { namespace, key } = parseFullPath(projectId, 'project');
        store.dispatch(setExploreNewRoute(namespace, key));
      }
    }),
  );
  return unsubscribe;
};
export function makeUpdateExploreStateFromUrl(): RouteThunk {
  const getExploreViewVisible = makeGetExploreUIProperty<boolean>(
    'exploreViewVisible',
    false,
  );
  const setExploreViewVisible = () => setUIProperty('exploreViewVisible', true);

  return (dispatch: Dispatch, getState) => {
    const state = getState();
    const exploreViewVisible = getExploreViewVisible(state);
    if (exploreViewVisible) {
      return;
    }

    return dispatch(setExploreViewVisible());
  };
}
