/* eslint-disable no-console */
import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { t } from 'ttag';
import { OrganizationFilesListItem } from 'uf-api';
import { addAppMessage } from 'uf/appservices/actions';
import { getOrgFiles, getOrgFileUrl } from 'uf/organizations/actions';

const CONTENT_TYPE = 'application/octet-stream';

export function uploadFileToSignedUrl(
  file: File,
  destination: string,
  progress?: (number) => void,
): Promise<any> {
  return axios({
    url: destination,
    method: 'PUT',
    data: file,
    headers: {
      'Content-Type': CONTENT_TYPE,
    },
    onUploadProgress: e => {
      if (e.progress && progress) {
        progress((e.progress * 100).toFixed(2));
      }
    },
  });
}

enum UploadProgress {
  CLEAR = null,
  DONE = 100,
}

type UploadProgressType = UploadProgress | number;

interface SignedUploadProps {
  ready: boolean;
  upload: () => Promise<OrganizationFilesListItem[]>;
  progress: UploadProgressType;
  uploadError: Error[];
  isLoading: boolean;
}

export function useSignedUpload(
  orgKey: string,
  files: File[],
): SignedUploadProps {
  // null = not started, 100 = done
  const [progress, setProgress] = useState<UploadProgressType>(
    UploadProgress.CLEAR,
  );
  const [uploadError, setUploadError] = useState<Error[]>([]);
  const dispatch = useDispatch();
  const hasError = uploadError.length > 0;
  // upload is ready when we have multiple files and progress is "CLEAR"
  const uploadReady =
    files.length > 0 && progress === UploadProgress.CLEAR && !hasError;
  const isLoading =
    progress !== UploadProgress.CLEAR &&
    progress !== UploadProgress.DONE &&
    !hasError;

  const doUpload = useCallback(() => {
    if (uploadReady) {
      return Promise.all(
        // run uploads in parallel
        files.map(async (file: File) => {
          // no need to select from redux, we want immediate values only
          const { url } = await dispatch(
            getOrgFileUrl(orgKey, file.name, 'PUT'),
          );
          await uploadFileToSignedUrl(file, url, setProgress);
          return {
            name: file.name,
            // TODO return correct data or not at all
            created: new Date(),
          };
        }),
      )
        .then(values => {
          setProgress(UploadProgress.DONE);
          dispatch(getOrgFiles(orgKey));
          dispatch(
            addAppMessage(
              values.length > 1 ? t`Files uploaded` : t`File uploaded`,
              {
                level: 'info',
                status: 'success',
              },
            ),
          );
          return values;
        })
        .catch((error: Error | Error[]) => {
          // why is this ever not an array?
          setUploadError(Array.isArray(error) ? error : [error]);
          throw error;
        });
    }
    return Promise.reject(
      new Error(
        files.length
          ? 'Upload is already in progress'
          : 'Please select files to upload',
      ),
    );
  }, [uploadReady, dispatch, orgKey, files, setUploadError]);

  // on files change -> clear our status
  useEffect(() => {
    setProgress(UploadProgress.CLEAR);
    setUploadError([]);
  }, [setProgress, setUploadError, files]);
  return {
    ready: uploadReady,
    upload: doUpload,
    progress,
    uploadError,
    isLoading,
  };
}
