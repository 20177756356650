import { ThunkAction } from 'redux-thunk';

import { ColumnKey, LayerId } from 'uf/layers';
import { ProjectId } from 'uf/projects';
import { ensureCuratedSymbology } from 'uf/symbology/actions/curated';
import {
  ensureDefaultSymbology,
  loadDefaultSymbology,
} from 'uf/symbology/actions/defaultSymbology';
import {
  ensureUserSymbology,
  loadUserSymbology,
} from 'uf/symbology/actions/user';
import { ViewId } from 'uf/views';

// type SymbologyResources = [
//   /*
//    * Symbology the user has saved to their account.
//    */
//   UserSymbologyResources,
//   /*
//    * Hardcoded symbology the data team has curated.
//    */
//   CuratedSymbologyResources,
//   /*
//    * Default styles we generate from our apis.
//    */
//   DefaultSymbologyResources,
// ];

export function ensureSymbology(
  projectId: ProjectId,
  viewId: ViewId,
  layerId: LayerId,
  columnKey: ColumnKey,
  // TODO: use appropriate signature (#15846):
  // ThunkAction<Promise<SymbologyResources>, any, any, any> {
): ThunkAction<any, any, any, any> {
  return dispatch => {
    return Promise.all([
      dispatch(ensureUserSymbology(projectId, viewId, layerId, columnKey)),
      dispatch(ensureCuratedSymbology(layerId, columnKey)),
      dispatch(ensureDefaultSymbology(projectId, viewId, layerId, columnKey)),
    ]);
  };
}

export function loadSymbology(
  projectId: ProjectId,
  viewId: ViewId,
  layerId: LayerId,
  columnKey: ColumnKey,
  // TODO: use appropriate signature (#15846):
  // ThunkAction<Promise<SymbologyResources>, any, any, any> {
): ThunkAction<any, any, any, any> {
  return dispatch => {
    return Promise.all([
      dispatch(loadUserSymbology(projectId, viewId, layerId, columnKey)),

      // There is nothing to "hard load" in curated symbology, so ensure is fine
      dispatch(ensureCuratedSymbology(layerId, columnKey)),

      dispatch(loadDefaultSymbology(projectId, viewId, layerId, columnKey)),
    ]);
  };
}
