/**
 * These are all APIs used to make remote calls to the server,
 * generally through swagger.
 *
 * All of these functions should be entirely stateless except for the
 * promise(s) they are managing.
 */
import { GeometryType, LayerBounds, LayerMetadata, LayerStats } from 'uf-api';
import { LayerServiceInterface } from 'uf-api/api/layer.serviceInterface';
import { getCanonicalParameters } from 'uf/base/api';
import { parseFullPath } from 'uf/base/dataset';
import { Writeable } from 'uf/base/types';
import { makeAsyncApiCaller } from 'uf/base/xhr';
import { ClientOperation } from 'uf/data/loader';
import { ColumnKey, LayerId } from 'uf/layers';
import { ProjectId } from 'uf/projects';
import { Expression, ExpressionSearchParams, getFilterClause } from './filters';

export const LAYER_DATA_LIMIT = 1000;
const getLayerStats = makeAsyncApiCaller(apis => apis.layer.get_layer_stats);
/**
 * Raw request to the get_layer_stats API. Returns a promise with results.
 *
 * `query` must be shaped for a server, i.e. an array of filters.
 */
export function fetchLayerStats(
  layerId: LayerId,
  params: ExpressionSearchParams = {},
): ClientOperation<LayerStats> {
  const { filters, ...otherParams } = params;
  const { namespace, type: layerType, key } = parseFullPath(layerId);
  // filters is actually a string of json
  const filtersClause = getFilterClause(filters);
  // keep URLs consistently hashable for caching.
  const searchParams =
    getCanonicalParameters<LayerServiceInterface.getLayerStatsParams>({
      namespace,
      layer_type: layerType,
      key,
      ...otherParams,
      ...filtersClause,
    });
  return getLayerStats(searchParams);
}

const getLayerBounds = makeAsyncApiCaller(apis => apis.layer.get_layer_bounds);
export function fetchLayerBounds(
  layerId: LayerId,
  version?: string,
  filters?: Expression[],
): ClientOperation<LayerBounds> {
  const { namespace, type: layerType, key } = parseFullPath(layerId);
  const filtersClause = getFilterClause(filters);
  const boundsParams: LayerServiceInterface.getLayerBoundsParams = {
    key,
    layer_type: layerType,
    namespace,
    version,
    ...filtersClause,
  };

  return getLayerBounds(boundsParams);
}

const getLayerGeometryType = makeAsyncApiCaller(
  apis => apis.layer.get_layer_geometry_type,
);

export function fetchLayerGeometryType(
  layerId: LayerId,
): ClientOperation<GeometryType> {
  const { namespace, type, key } = parseFullPath(layerId);

  const params: LayerServiceInterface.getLayerGeometryTypeParams = {
    namespace,
    layer_type: type,
    key,
  };

  return getLayerGeometryType(params);
}

const getLayer = makeAsyncApiCaller(apis => apis.layer.get_layer);
/**
 * Raw request to get_layer API. Returns a promise.
 */
export function fetchLayerMetadata(
  layerId: LayerId,
): ClientOperation<LayerMetadata> {
  const { namespace, type: layerType, key } = parseFullPath(layerId);
  return getLayer({ namespace, layer_type: layerType, key });
}

const enqueueLayerExportTask = makeAsyncApiCaller(
  apis => apis.layer.enqueue_layer_export_task,
);

export function startExport(
  projectId: ProjectId,
  layerId: LayerId,
  version: string,
  fileFormat: LayerServiceInterface.enqueueLayerExportTaskParams['file_format'],
  includeGeometries: boolean,
  filters: Expression[],
  columnKey: ColumnKey,
  columns?: string[],
) {
  const { namespace: projectNamespace, key: projectKey } =
    parseFullPath(projectId);

  const filtersClause = getFilterClause(filters);
  const params: Writeable<LayerServiceInterface.enqueueLayerExportTaskParams> =
    {
      project_namespace: projectNamespace,
      project_key: projectKey,
      layer_path: layerId,
      file_format: fileFormat,
      columns: columns || [],
      ...filtersClause,
    };

  if (version) {
    params.version = version;
  }
  if (includeGeometries !== undefined) {
    params.include_geometries = includeGeometries;
  }
  if (columnKey) {
    params.column = columnKey;
  }
  return enqueueLayerExportTask(params);
}

const getLayerBreaks = makeAsyncApiCaller(apis => apis.layer.get_layer_breaks);
export function fetchLayerBreaks(
  layerId: LayerId,
  params: Omit<
    LayerServiceInterface.getLayerBreaksParams,
    'filters' | 'namespace' | 'layer_type' | 'key'
  >,
  filters?: Expression[],
) {
  const { columns, ...otherParams } = params;
  const { namespace, type: layerType, key } = parseFullPath(layerId);
  // filters is actually a string of json
  const filtersClause = getFilterClause(filters);
  // keep URLs consistently hashable for caching.
  const searchParams: LayerServiceInterface.getLayerBreaksParams =
    getCanonicalParameters({
      namespace,
      layer_type: layerType,
      key,
      columns,
      ...filtersClause,
      ...otherParams,
    });
  return getLayerBreaks(searchParams);
}
