import {
  DirectSelectOptions,
  DrawLineStringOptions,
  SimpleSelectOptions,
} from '@mapbox/mapbox-gl-draw';
import _ from 'lodash';

import { EMPTY_OBJECT } from 'uf/base';
import {
  DELETE_SELECTED_VERTICES,
  DeleteVerticesAction,
  SELECT_FEATURE,
  SelectFeaturesAction,
  SET_DRAWING_MODE,
  SetDrawingModeAction,
} from 'uf/explore/ActionTypes';
import { CoordPathsByFeature } from 'uf/map/state';

export default function selectedCoordPaths(
  state: CoordPathsByFeature = EMPTY_OBJECT,
  action: SelectFeaturesAction | DeleteVerticesAction | SetDrawingModeAction,
): CoordPathsByFeature {
  switch (action.type) {
    case DELETE_SELECTED_VERTICES: {
      const { featureId, coordPaths } = action;

      // Remove the vertices
      const prevCoordPaths = state[featureId] || [];
      const filteredCoordPaths = prevCoordPaths.filter(
        prevCoordPath => !coordPaths.includes(prevCoordPath),
      );

      // If we removed all the vertices, just delete the entire entry from state
      if (!filteredCoordPaths.length) {
        return _.omit(state, featureId);
      }

      return {
        ...state,
        [featureId]: filteredCoordPaths,
      };
    }

    case SELECT_FEATURE:
      return action.coordPaths;

    case SET_DRAWING_MODE: {
      // Direct select can set coordPaths, but if it doesn't we should clear
      // them out
      if (action.mode === 'direct_select') {
        const { featureId, coordPath }: DirectSelectOptions =
          action.modeOptions || {};
        if (coordPath?.length) {
          return {
            ...state,
            [featureId]: coordPath,
          };
        }
        return _.omit(state, featureId);
      }

      // Simple select will clear out selected vertices in gl-draw, so we should
      // do the same in redux
      if (action.mode === 'simple_select') {
        const simpleSelectOptions: SimpleSelectOptions =
          action.modeOptions || {};
        if (simpleSelectOptions.featureIds) {
          return _.omit(state, simpleSelectOptions.featureIds);
        }
      }

      // Selected coordPaths makes no sense when drawing, always clear these out
      if (action.mode === 'draw_line_string') {
        const drawLineStringOptions: DrawLineStringOptions =
          action.modeOptions || {};
        if (drawLineStringOptions?.featureId) {
          return _.omit(state, drawLineStringOptions?.featureId);
        }
      }

      return EMPTY_OBJECT;
    }

    default:
      return state;
  }
}
