import assert from 'assert';
import _ from 'lodash';
import { Layer } from 'mapbox-gl';
import { createSelector } from 'reselect';
import { EMPTY_ARRAY, EMPTY_OBJECT } from 'uf/base';
import { cacheableConcat } from 'uf/base/array';
import { makeGetFromPropsSelector } from 'uf/base/selector';
import { getData } from 'uf/data/dataState';
import {
  makeEditsSelectionStyleLayers,
  makeEditsStyleLayers,
  makeEditsTileSourceId,
} from 'uf/explore/map/edits';
import { makeHoverStyleLayers } from 'uf/explore/map/hover';
import { makeSelectionStyleLayers } from 'uf/explore/map/selection';
import { getShowPaintedOnly } from 'uf/explore/selectors/explore';
import {
  getActiveLayerId,
  makeGetProjectLayersMapStyles,
  makeGetVisibleLayerReferences,
} from 'uf/explore/selectors/layers';
import { makeGetLayerSelectedKeysGetter } from 'uf/explore/selectors/selection';
import { makePaintedOnlyStyleLayers } from 'uf/explore/styleLayers';
import { ColumnKey, LayerId } from 'uf/layers';
import { filtersAreEmpty } from 'uf/layers/filters';
import { getUfGeometryType, isPaintedLayer } from 'uf/layers/helpers';
import { getColumnMetatype } from 'uf/layers/metadata';
import {
  makeGetLayerMetadataState,
  makeGetLayerMetadataStateGetter,
} from 'uf/layers/selectors/metadata';
import {
  makeGetLayerVersion,
  makeGetLayerVersionGetter,
} from 'uf/layers/selectors/versions';
import { StyleLayerInfo, UFMapLayer } from 'uf/map';
import {
  maxMapBoundsLayerStyle,
  VIRTUAL_MAX_BOUNDS_LAYER_ID,
} from 'uf/map/mapStyleAdapters';
import { makeGetStyleLayerInfoGetter } from 'uf/map/selectors/stylelayers';
import { getStyleLayerUFMetadata } from 'uf/map/stylelayers/stylelayers';
import { makeSourceId } from 'uf/map/tileSources';
import {
  makeGetEditFeaturesFilteredGetter,
  makeGetLayerEditFeaturesGetter,
} from 'uf/mapedit/selectors/features';
import { ProjectId } from 'uf/projects';
import { getMaxMapBounds } from 'uf/projects/bounds';
import { makeGetProjectState } from 'uf/projects/selectors';
import { makeGetProjectLayerIdMap } from 'uf/projects/selectors/virtualLayers';
import { LegacyVirtualLayerId } from 'uf/projects/virtualLayers';
import { ScenarioId } from 'uf/scenarios';
import {
  makeGetDivideByColumn,
  makeGetDivideByColumnGetter,
} from 'uf/symbology/selectors/divideByColumn';
import { ViewId } from 'uf/views';
import warning from 'warning';
import { makeGetLayerFiltersGetter } from './filters';
import { getUserSetFlags } from 'uf/user/selectors/flags';

export function makeGetVisibleStyleLayers() {
  return createSelector(
    makeGetVisibleLayerReferences(),
    makeGetProjectLayersMapStyles(),
    makeGetMaxBoundsStyleLayerInfo(),
    makeGetExploreStyleLayersWithSelectionGetter(),
    makeGetProjectLayerIdMap(),
    makeGetFromPropsSelector<ProjectId, 'projectId'>('projectId'),
    makeGetFromPropsSelector<ViewId, 'viewId'>('viewId'),
    makeGetFromPropsSelector<ScenarioId, 'scenarioId'>('scenarioId'),
    (
      layers,
      columnKeysByLayerId,
      maxBoundsStyleLayerInfo,
      getExploreStyleLayersWithSelection,
      layerMap,
      projectId,
      viewId,
    ): Layer[] => {
      let projectMaskStyleLayers: Layer[] = [];
      if (maxBoundsStyleLayerInfo) {
        projectMaskStyleLayers = maxBoundsStyleLayerInfo.styleLayers;
      }

      const styleLayers = layers
        // don't request styles for layers without an active column
        .filter(({ full_path: layerId }) => {
          const virtualLayerId = layerMap[layerId];
          warning(
            !!virtualLayerId,
            `Cannot find virtual layer from ${layerId}`,
          );
          return !!columnKeysByLayerId[virtualLayerId];
        })
        .map(({ full_path: layerId }) => {
          const virtualLayerId = layerMap[layerId];
          return getExploreStyleLayersWithSelection(
            projectId,
            viewId,
            layerId,
            virtualLayerId,
            columnKeysByLayerId[virtualLayerId].activeColumnKey,
            null,
          );
        })
        .reverse()
        .flat(); // need to flatten *after* the reverse so layers stay in the right order
      return cacheableConcat(projectMaskStyleLayers, styleLayers);
    },
  );
}

/**
 * Gets the explore style layers for a uf layer, whether it's visible or not.
 * This may include "selection" outlines or "painted only" filters.
 */
export function makeGetExploreStyleLayersWithSelectionGetter() {
  return createSelector(
    getActiveLayerId,
    makeGetExploreStyleLayerInfoGetter(),
    makeGetSelectionStyleLayersGetter(),
    makeGetEditsStyleLayersGetter(),
    makeGetEditsSelectionStyleLayersGetter(),
    makeGetFromPropsSelector<ScenarioId, 'scenarioId'>('scenarioId'),
    (
        activeLayerId,
        getExploreStyleLayerInfo,
        getSelectionStyleLayers,
        getEditsStyleLayers,
        getEditsSelectionStyleLayers,
      ) =>
      (
        projectId: ProjectId,
        viewId: ViewId,
        layerId: LayerId,
        virtualLayerId: LegacyVirtualLayerId,
        columnKey: ColumnKey,
        parentLayerId: string,
      ): Layer[] => {
        const exploreStyleLayerInfo = getExploreStyleLayerInfo(
          projectId,
          viewId,
          layerId,
          virtualLayerId,
          columnKey,
        );

        const editsStyleLayers = getEditsStyleLayers(
          projectId,
          viewId,
          layerId,
          virtualLayerId,
          columnKey,
        );

        const selectionStyleLayers = getSelectionStyleLayers(
          projectId,
          layerId,
          columnKey,
          parentLayerId,
        );

        const editsSelectionStyleLayers = getEditsSelectionStyleLayers(
          projectId,
          layerId,
          parentLayerId,
        );

        return getExploreStyleLayersWithSelectionInner(
          activeLayerId,
          layerId,
          exploreStyleLayerInfo,
          selectionStyleLayers,
          editsStyleLayers,
          editsSelectionStyleLayers,
        );
      },
  );
}

/**
 * A function to determine which selection layers to use.  Normal selection layers are generally
 * used, but we don't use them for transit.  Instead we want to show the transitSelectionLayers, but
 * only if a route has not yet been selected for editing.  once the layer is selected for editing,
 * we use the editsSelectionStyleLayers
 */
function getExploreStyleLayersWithSelectionInner(
  activeLayerId: string,
  layerId: LayerId,
  exploreStyleLayerInfo: StyleLayerInfo,
  selectionStyleLayers: Layer[] = EMPTY_ARRAY,
  editsStyleLayers: Layer[] = EMPTY_ARRAY,
  editsSelectionStyleLayers: Layer[] = EMPTY_ARRAY,
): Layer[] {
  const exploreStyleLayers = exploreStyleLayerInfo?.styleLayers;
  if (!exploreStyleLayers && !editsSelectionStyleLayers) {
    return EMPTY_ARRAY;
  }

  const nonPreviewEditLayers = editsStyleLayers.filter(
    layer => !isPreviewStyleLayer(layer),
  );
  let styleLayers: Layer[] = cacheableConcat(exploreStyleLayers);

  // only use selectionStyleLayers for 'real' layers, not 'fake' layers
  if (layerId === activeLayerId) {
    styleLayers = cacheableConcat(styleLayers, selectionStyleLayers);
  }
  styleLayers = cacheableConcat(
    styleLayers,
    editsSelectionStyleLayers,
    nonPreviewEditLayers,
  );

  // Preview style layers need to be on top of selection layers, so we add them last
  const previewStyleLayers = editsStyleLayers.filter(isPreviewStyleLayer);
  return cacheableConcat(styleLayers, previewStyleLayers);
}

function isPreviewStyleLayer(layer: Layer): boolean {
  return layer?.metadata?.uf?.previewEditsLayer;
}

function makeGetExploreStyleLayerInfoGetter() {
  return createSelector(
    makeGetStyleLayerInfoGetter(),
    makeGetPaintedOnlyStyleLayerInfoGetter(),
    makeGetLayerVersionGetter(),
    makeGetLayerMetadataStateGetter(),
    (
        getStyleLayerInfo,
        getPaintedOnlyStyleLayerInfo,
        getLayerVersion,
        getMetadataState,
      ) =>
      (
        projectId: ProjectId,
        viewId: ViewId,
        layerId: LayerId,
        virtualLayerId: LegacyVirtualLayerId,
        columnKey: ColumnKey,
      ): StyleLayerInfo => {
        const layerVersion = getLayerVersion(layerId);

        const styleLayerInfo = getStyleLayerInfo(
          projectId,
          viewId,
          layerId,
          virtualLayerId,
          columnKey,
          layerVersion,
        );
        if (!styleLayerInfo || !styleLayerInfo.styleLayers) {
          return null;
        }

        const paintedOnlyStyleLayerInfo = getPaintedOnlyStyleLayerInfo(
          projectId,
          viewId,
          layerId,
          virtualLayerId,
          columnKey,
        );
        let styleLayers: UFMapLayer[] = [];
        if (paintedOnlyStyleLayerInfo) {
          styleLayers = cacheableConcat(
            styleLayers,
            paintedOnlyStyleLayerInfo.styleLayers,
          );
        } else {
          styleLayers = cacheableConcat(
            styleLayers,
            styleLayerInfo.styleLayers,
          );
        }

        const metadataState = getMetadataState(layerId);
        const metatype = getColumnMetatype(getData(metadataState), columnKey);

        return {
          columnKey,
          layerId,
          metatype,
          version: layerVersion,
          styleLayers,
        };
      },
  );
}

export const getStyleLayersFromLayerIds = createSelector(
  makeGetMaxBoundsStyleLayerInfo(),
  makeGetExploreStyleLayerInfoGetter(),
  makeGetProjectLayerIdMap(),
  makeGetFromPropsSelector<ProjectId, 'projectId'>('projectId'),
  makeGetFromPropsSelector<ViewId, 'viewId'>('viewId'),
  makeGetFromPropsSelector<LayerId[], 'layerIds'>('layerIds'),
  makeGetFromPropsSelector<string[], 'columnKeys'>('columnKeys'),
  (
    mapMaxBoundsStyleLayerInfo,
    getExploreStyleLayerInfo,
    virtualLayerIdMap,
    projectId,
    viewId,
    layerIds,
    columnKeys,
  ): StyleLayerInfo[] => {
    const styleLayerInfos = _.zip(layerIds, columnKeys).map(
      ([layerId, columnKey]) =>
        getExploreStyleLayerInfo(
          projectId,
          viewId,
          layerId,
          virtualLayerIdMap[layerId],
          columnKey,
        ),
    );

    // Strip out nulls because some style layers may not be ready yet due to
    // pending network requests.
    return _.compact([...styleLayerInfos, mapMaxBoundsStyleLayerInfo]);
  },
);
/**
 * Given a layerId, return all the style layers associated with that layer.
 *
 * This is generally useful for queryRenderedFeatures
 */
export function makeGetLayerStyleLayers() {
  return createSelector(
    makeGetFromPropsSelector<ProjectId, 'projectId'>('projectId'),
    makeGetFromPropsSelector<LayerId, 'layerId'>('layerId'),
    makeGetFromPropsSelector<ColumnKey, 'columnKey'>('columnKey'),
    makeGetVisibleStyleLayers(),
    (projectId, layerId, columnKey, styleLayers) => {
      // TODO: refactor selector so it only gets activeStyleLayers for the layer passed in
      const activeStyleLayers = styleLayers.filter(
        layer => getStyleLayerUFMetadata(layer).layerId === layerId,
      );

      return activeStyleLayers;
    },
  );
}

function makeGetPaintedOnlyStyleLayerInfoGetter() {
  return createSelector(
    makeGetStyleLayerInfoGetter(),
    makeGetLayerMetadataStateGetter(),
    makeGetLayerVersionGetter(),
    getShowPaintedOnly,
    (getStyleLayerInfo, getMetadataState, getLayerVersion, showPaintedOnly) =>
      (
        projectId: ProjectId,
        viewId: ViewId,
        layerId: LayerId,
        virtualLayerId: LegacyVirtualLayerId,
        columnKey: ColumnKey,
      ): StyleLayerInfo => {
        const metadataState = getMetadataState(layerId);
        const metadata = getData(metadataState, null);
        const layerVersion = getLayerVersion(layerId);
        const styleLayerInfo = getStyleLayerInfo(
          projectId,
          viewId,
          layerId,
          virtualLayerId,
          columnKey,
          layerVersion,
        );
        if (
          !showPaintedOnly[layerId] ||
          !styleLayerInfo ||
          !metadata ||
          !isPaintedLayer(metadata)
        ) {
          return null;
        }

        const {
          styleLayers: [primaryPaintedStyleLayer, secondaryLayer],
        } = styleLayerInfo;

        const paintedOnlyPrimaryStyleLayers = makePaintedOnlyStyleLayers(
          primaryPaintedStyleLayer,
        );
        const paintedOnlySecondaryStyleLayers =
          makePaintedOnlyStyleLayers(secondaryLayer);

        const styleLayers = _.zip(
          paintedOnlyPrimaryStyleLayers,
          paintedOnlySecondaryStyleLayers,
        );

        return {
          ...styleLayerInfo,
          styleLayers: _.flatten(styleLayers),
        };
      },
  );
}

export function makeGetMaxBoundsStyleLayerInfo() {
  return createSelector(
    makeGetFromPropsSelector<ProjectId, 'projectId'>('projectId'),
    makeGetProjectState(),
    getUserSetFlags,
    (activeProjectId, projectState, flags): StyleLayerInfo => {
      const project = getData(projectState, null);
      if (!project) {
        return null;
      }
      const maxMapBounds = getMaxMapBounds(project);
      if (!maxMapBounds || flags.includes('dev-hide-max-bounds')) {
        return null;
      }
      return {
        layerId: VIRTUAL_MAX_BOUNDS_LAYER_ID,
        columnKey: null,
        metatype: 'categorical',
        // This is not a real uf layer, so there is no version
        version: null,
        styleLayers: [maxMapBoundsLayerStyle(activeProjectId, maxMapBounds)],
      };
    },
  );
}

function makeGetSelectionStyleLayersGetter() {
  return createSelector(
    makeGetLayerVersionGetter(),
    makeGetLayerMetadataStateGetter(),
    makeGetLayerSelectedKeysGetter(),
    makeGetDivideByColumnGetter(),
    (
        getLayerVersion,
        getLayerMetadataState,
        getSelectedKeys,
        getDivideByColumn,
      ) =>
      (
        projectId: ProjectId,
        layerId: LayerId,
        columnKey: ColumnKey,
        parentLayerId: string,
      ): Layer[] => {
        assert(layerId, 'layerId is required');
        const layerMetadataState = getLayerMetadataState(layerId);
        const layerVersion = getLayerVersion(layerId);
        const selectedKeys = getSelectedKeys({
          layerId,
          parentLayerId,
          projectId,
        });
        const divideByColumn = getDivideByColumn(projectId, layerId, columnKey);

        const metadata = getData(layerMetadataState, null);
        if (!layerVersion || !metadata) {
          return null;
        }

        const selectionLayers = makeSelectionStyleLayers(
          layerId,
          layerVersion,
          columnKey,
          divideByColumn,
          getUfGeometryType(metadata),
          selectedKeys,
        );

        return selectionLayers;
      },
  );
}

function makeGetEditsStyleLayersGetter() {
  return createSelector(
    makeGetLayerEditFeaturesGetter(),
    makeGetLayerVersionGetter(),
    makeGetStyleLayerInfoGetter(),
    (getLayerEditsFeatures, getLayerVersion, getStyleLayerInfo) =>
      (
        projectId: ProjectId,
        viewId: ViewId,
        layerId: LayerId,
        virtualLayerId: LegacyVirtualLayerId,
        columnKey: ColumnKey,
      ): Layer[] => {
        const layerEditsFeatures = getLayerEditsFeatures(projectId, layerId);
        const layerVersion = getLayerVersion(layerId);
        const styleLayerInfo = getStyleLayerInfo(
          projectId,
          viewId,
          layerId,
          virtualLayerId,
          columnKey,
          layerVersion,
        );

        let editsStyleLayers: Layer[] = [];
        if (styleLayerInfo) {
          editsStyleLayers = makeEditsStyleLayers(
            layerId,
            styleLayerInfo,
            layerEditsFeatures,
          );
        }

        return editsStyleLayers;
      },
  );
}
function makeGetEditsSelectionStyleLayersGetter() {
  return createSelector(
    makeGetLayerMetadataStateGetter(),
    makeGetLayerFiltersGetter(),
    makeGetEditFeaturesFilteredGetter(),
    (getLayerMetadataState, getLayerFilters, getLayerEditFeatures) =>
      (
        projectId: ProjectId,
        layerId: LayerId,
        parentLayerId: LayerId,
      ): Layer[] => {
        const layerMetadataState = getLayerMetadataState(layerId);
        const metadata = getData(layerMetadataState, EMPTY_OBJECT);

        const filters = getLayerFilters(layerId, parentLayerId);
        const features = filtersAreEmpty(filters)
          ? EMPTY_ARRAY
          : getLayerEditFeatures(projectId, layerId, filters);

        const editsSelectionStyleLayers = features?.length
          ? makeEditsSelectionStyleLayers(
              layerId,
              makeEditsTileSourceId(layerId),
              getUfGeometryType(metadata),
              features,
            )
          : EMPTY_ARRAY;

        return editsSelectionStyleLayers;
      },
  );
}

export function makeGetSelectionStyleLayers() {
  return createSelector(
    makeGetSelectionStyleLayersGetter(),
    makeGetFromPropsSelector<ProjectId, 'projectId'>('projectId'),
    makeGetFromPropsSelector<LayerId, 'layerId'>('layerId'),
    makeGetFromPropsSelector<ColumnKey, 'columnKey'>('columnKey'),
    makeGetFromPropsSelector<LayerId, 'parentLayerId'>('parentLayerId'),
    (
      getSelectionStyleLayers,
      projectId,
      layerId,
      columnKey,
      parentLayerId,
    ): Layer[] =>
      getSelectionStyleLayers(projectId, layerId, columnKey, parentLayerId) ??
      EMPTY_ARRAY,
  );
}
export function makeGetHoverStyleLayers() {
  return createSelector(
    makeGetFromPropsSelector<LayerId, 'layerId'>('layerId'),
    makeGetFromPropsSelector<ColumnKey, 'columnKey'>('columnKey'),
    makeGetLayerMetadataState(),
    makeGetLayerVersion(),
    makeGetDivideByColumn(),
    (
      layerId,
      columnKey,
      layerMetadataState,
      layerVersion,
      divideByColumn,
    ): Layer[] => {
      const metadata = getData(layerMetadataState, null);
      if (!layerVersion || !metadata || !columnKey) {
        return EMPTY_ARRAY;
      }
      const sourceId = makeSourceId(
        layerId,
        layerVersion,
        columnKey,
        divideByColumn,
      );
      return makeHoverStyleLayers(
        layerId,
        columnKey,
        metadata,
        layerVersion,
        sourceId,
      );
    },
  );
}
