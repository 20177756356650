export interface PathRef {
  namespace: string;
  type: string;
  key: string;
}

export interface LayerRef {
  namespace: string;
  layer_type: string;
  key: string;
}

export interface FullLayerRef extends LayerRef {
  full_path: string;
}

/**
 * Parse dataset id in the form /namespace/layer_type/key into component parts.
 * @param entityId The path to an entity (project, layer, scenario, etc)
 * @param requiredType Pass in a string to match the middle part of the path
 *     (i.e. 'project', 'datset', etc) to consider it an error if the parsed
 *     type does not match
 */
export function parseFullPath(
  entityId: string,
  requiredType: string | null | undefined = null,
): PathRef {
  if (!entityId) {
    throw new Error(
      `Parsing invalid path, string not defined, value is ${entityId}`,
    );
  }

  if (typeof entityId !== 'string') {
    throw new Error(
      `Parsing invalid path, expected a string but got ${entityId}`,
    );
  }

  if (!entityId.startsWith('/')) {
    throw new Error(
      `Parsing invalid path, expected the form '/namespace/type/key' but got ${entityId}`,
    );
  }

  const datasetParts = entityId.split('/');
  if (
    datasetParts[0] || // should be empty
    datasetParts.length !== 4
  ) {
    throw new Error(`malformed dataset id: "${entityId}"`);
  }

  if (requiredType && datasetParts[2] !== requiredType) {
    throw new Error(
      `Parsing invalid path. Expected '${requiredType}' but found ${datasetParts[2]}`,
    );
  }

  return {
    namespace: datasetParts[1],
    type: datasetParts[2],
    key: datasetParts[3],
  };
}

export function makeLayerRefProps(
  fullPath: string,
  requiredType: string | null | undefined = null,
): LayerRef {
  const { namespace, type, key } = parseFullPath(fullPath, requiredType);
  return {
    namespace,
    key,
    layer_type: type,
  };
}

export function makeLayerPathProps(
  fullPath: string,
  requiredType: string | null | undefined = null,
): FullLayerRef {
  return {
    full_path: fullPath,
    ...makeLayerRefProps(fullPath, requiredType),
  };
}
