import colors from 'material-colors';

import {
  CuratedColumnSymbology,
  CUSTOM_THEME,
  DEFAULT_OPACITY,
  DistributionTypes,
  InterpolationTypes,
  PaintColorTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/public/dataset/colorado_evapotranspiration_canvas': {
    annual_evapotranspiration: [
      {
        type: SymbologyTypes.FILL,
        paint: {
          'fill-color': {
            property: 'annual_evapotranspiration',
            type: PaintColorTypes.INTERVAL,
            stops: [
              { value: 0, color: colors.red[300] },
              { value: 5, color: colors.deepOrange[300] },
              { value: 10, color: colors.orange[300] },
              { value: 15, color: colors.amber[200] },
              { value: 20, color: colors.yellow[200] },
              { value: 25, color: colors.lightGreen[200] },
              { value: 30, color: colors.green[200] },
              { value: 35, color: colors.green[300] },
              { value: 40, color: colors.teal[400] },
              { value: 45, color: colors.lightBlue[200] },
              { value: 50, color: colors.indigo[200] },
              { value: 55, color: colors.deepPurple[200] },
              { value: 60, color: colors.purple[200] },
            ],
          },
          'fill-opacity': DEFAULT_OPACITY,
        },
        display_hints: {
          scale: SymbologyScales.NUMERIC,
          distribution: DistributionTypes.DEFAULT,
          interpolation: InterpolationTypes.INTERVAL,
          theme: CUSTOM_THEME,
        },
      },
    ],
  },
};

export default symbologies;
