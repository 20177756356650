/**
 * UrbanFootprint App API
 * API for serving UrbanFootprint Application
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Restrict to data within the specified tile.
 */
export interface ExpressionWithinTile<> {
  /**
   * Expression function, set to \"within_tile\".
   */
  fn: ExpressionWithinTile.FnEnum;
  /**
   * tile x value
   */
  x?: number;
  /**
   * tile y value
   */
  y?: number;
  /**
   * tile z value
   */
  z?: number;
}
export namespace ExpressionWithinTile {
  export type FnEnum = 'within_tile';
  export const FnEnum = {
    WithinTile: 'within_tile',
  } as const;
}
