import colors from 'material-colors';

import {
  CuratedColumnSymbology,
  CUSTOM_THEME,
  DEFAULT_OPACITY,
  InterpolationTypes,
  PaintColorTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/public/dataset/california_flood_plain_layer': {
    fld_zone: [
      {
        type: SymbologyTypes.FILL,
        paint: {
          'fill-color': {
            property: 'fld_zone',
            type: PaintColorTypes.CATEGORICAL,
            stops: [
              {
                value: 'AH',
                label: 'AH - Areas With A 1% Annual Chance Of Shallow Flooding',
                color: colors.orange[400],
              },
              {
                value: 'AO',
                label: 'AO - River Or Stream Flood Hazard Areas',
                color: colors.orange[500],
              },
              {
                value: 'A',
                label: 'A - Areas With A 1% Annual Chance Of Flooding',
                color: colors.deepOrange[500],
              },
              {
                value: 'AE',
                label: 'AE - Base Floodplain Elevation',
                color: colors.indigo[500],
              },
              {
                value: 'A99',
                label:
                  'A99 - Areas With A 1% Annual Chance - Protected By Federal Flood Control System',
                color: colors.purple[500],
              },
              {
                value: 'V',
                label:
                  'V - Coastal Areas With >= 1% Chance Of Flooding And Additional Storm Wave Hazard',
                color: colors.red[500],
              },
              {
                value: 'VE',
                label:
                  'VE - Coastal Areas With >= 1% Chance Of Flooding And Additional Storm Wave Hazard',
                color: colors.red[500],
              },
            ],
          },
          'fill-opacity': DEFAULT_OPACITY,
        },
        display_hints: {
          scale: SymbologyScales.CATEGORICAL,
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          theme: CUSTOM_THEME,
        },
      },
    ],
  },
};

export default symbologies;
