import { parseFullPath } from 'uf/base/dataset';
import { makeAsyncApiCaller } from 'uf/base/xhr';
import { ProjectId } from 'uf/projects';
import { ScenarioId } from 'uf/scenarios';

const runAnalysisModuleForProject = makeAsyncApiCaller(
  apis => apis.project.run_analysis_module_for_project,
);
export function fetchRunAnalysis(
  moduleKey: string,
  projectId: ProjectId,
  scenarioId: ScenarioId,
) {
  const { namespace, key } = parseFullPath(projectId, 'project');
  const postData = {
    namespace,
    key,
    module: moduleKey,
    scenario: scenarioId,
  };

  return runAnalysisModuleForProject(postData);
}
