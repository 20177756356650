import _ from 'lodash';
import colors from 'material-colors';
import warning from 'warning';

import { FillLayer } from 'mapbox-gl';
import { LayerMetadata, LayerReference } from 'uf-api/model/models';
import { EMPTY_ARRAY } from 'uf/base';
import { DataState, getData } from 'uf/data/dataState';
import { ProjectStyleInfo } from 'uf/explore/state';
import { ColumnKey, LayerId, LayerVersion } from 'uf/layers';
import { getColumnKey } from 'uf/layers/geometryKey';
import { isPaintedLayer } from 'uf/layers/helpers';
import { LayerSource } from 'uf/map';
import { makeSourceId } from 'uf/map/tileSources';
import { ProjectId } from 'uf/projects';
import { LegacyVirtualLayerId } from 'uf/projects/virtualLayers';
import { DivideByColumnKey } from 'uf/symbology/divideByColumn';

export const VIRTUAL_MAX_BOUNDS_LAYER_ID = 'project-max-map-bounds-mask';

// Define the corners of the globe.
const GLOBE_WEST = -180;
const GLOBE_SOUTH = -85;
const GLOBE_EAST = 180;
const GLOBE_NORTH = 85;

const GLOBE_SW = [GLOBE_WEST, GLOBE_SOUTH];
const GLOBE_NW = [GLOBE_WEST, GLOBE_NORTH];
const GLOBE_NE = [GLOBE_EAST, GLOBE_NORTH];
const GLOBE_SE = [GLOBE_EAST, GLOBE_SOUTH];

// Configures the max zoom level for which overzooming of vector layers kicks in
const MAX_ZOOM_FOR_OVERZOOM = 16;

// Prop name to promote to feature id.
// Should be the same as the name specified in uf_api/tile/vec_tiles.py
const TILE_FEATURE_ID = '_feature_id';
/**
 *
 * @param layers List of layers to make tile sources for
 * @param layerVersions Map of layerId to layer versions
 * @param layerMetadataStates Map of layerId to loading state for metadata
 */
export function mapLayerReferencesToTileSources(
  layers: LayerReference[],
  layerVersions: Record<string, string>,
  layerMetadataStates: Record<string, DataState<LayerMetadata>>,
  mapStyles: ProjectStyleInfo,
  divideByColumns: Record<LayerId, DivideByColumnKey>,
  mapMaxBounds: [[number, number], [number, number]],
  layerMap: Record<LayerId, LegacyVirtualLayerId>,
): LayerSource[] {
  if (!layers) {
    return EMPTY_ARRAY;
  }
  return layers
    .map(layerReference => {
      const layerId: LayerId = layerReference?.full_path;
      const virtualLayerId = layerMap[layerId];
      warning(
        !!virtualLayerId,
        `Missing layer ${layerId} when making tile sources`,
      );
      const { details } = layerReference;
      const tileUrl = details?.tile_url;
      const columnKey = mapStyles?.[virtualLayerId]?.activeColumnKey;

      if (details?.tile_url) {
        const layerVersion = layerVersions?.[layerId];
        const layerMetadata = getData(layerMetadataStates[layerId]);
        const divideByColumn = divideByColumns[layerId];

        return makeVectorTileSource(
          layerId,
          columnKey,
          layerVersion,
          layerMetadata,
          divideByColumn,
          mapMaxBounds,
          tileUrl,
        );
      }
      // if we got this far, this is just not a mappable layer
      return null;
    })
    .filter(source => !!source);
}

function makeVectorTileSource(
  layerId: LayerId,
  columnKey: ColumnKey,
  layerVersion: LayerVersion,
  layerMetadata: LayerMetadata,
  divideByColumn: DivideByColumnKey,
  mapMaxBounds: [[number, number], [number, number]],
  tileUrl: string,
) {
  if (!columnKey) {
    warning(!!columnKey, `Missing mappable column key for ${layerId}`);
    // Not mappable without a primary column key
    return null;
  }
  const columnKeys = getRelevantColumnKeys(
    layerMetadata,
    columnKey,
    divideByColumn,
  );

  const columnKeyParam = columnKeys.join(',');

  const tileUrlWithParams = `${tileUrl}?version=${layerVersion}&columns=${columnKeyParam}`;

  const source: mapboxgl.VectorSource = {
    type: 'vector',
    tiles: [tileUrlWithParams],
    maxzoom: MAX_ZOOM_FOR_OVERZOOM,
    promoteId: TILE_FEATURE_ID,
  };

  if (mapMaxBounds) {
    source.bounds = mapMaxBounds.flat();
  }

  const id = makeSourceId(layerId, layerVersion, columnKey, divideByColumn);
  return { id, source };
}

function getRelevantColumnKeys(
  layerMetadata: LayerMetadata,
  columnKey: ColumnKey,
  divideByColumn: DivideByColumnKey,
) {
  const extraColumnKeys = getExtraColumnKeys(layerMetadata);
  const columnKeys = _.uniq([getColumnKey(columnKey), ...extraColumnKeys]);
  if (divideByColumn) {
    columnKeys.push(divideByColumn);
  }
  if (isPaintedLayer(layerMetadata)) {
    columnKeys.push('uf_is_painted');
  }
  return columnKeys;
}

export function makeMapMaxBoundsLayerSource(
  projectId: ProjectId,
  mapMaxBounds: [[number, number], [number, number]],
): LayerSource[] {
  if (!mapMaxBounds) {
    return EMPTY_ARRAY;
  }

  // Define the corners of the bounding box.
  const [[west, south], [east, north]] = mapMaxBounds;

  const sw = [west, south];
  const nw = [west, north];
  const ne = [east, north];
  const se = [east, south];

  // Use a source id that varys with the project. This ensures that a layer
  // source will not be re-used by a different project.
  const sourceId = `${VIRTUAL_MAX_BOUNDS_LAYER_ID}-${projectId}`;

  const source: mapboxgl.GeoJSONSourceRaw = {
    type: 'geojson',
    data: {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [GLOBE_SW, GLOBE_NW, GLOBE_NE, GLOBE_SE, GLOBE_SW],
          [sw, nw, ne, se, sw],
        ],
      },
      properties: {},
    },
  };

  return [{ id: sourceId, source }];
}

// Style inaccessible areas as a faded grey box outlined by a dark line.
export function maxMapBoundsLayerStyle(
  projectId: ProjectId,
  maxMapBounds: [[number, number], [number, number]],
): FillLayer {
  if (!maxMapBounds) {
    return null;
  }

  // Use a source id that varys with the project. This ensures that a layer
  // source will not be re-used by a different project.
  const sourceId = `${VIRTUAL_MAX_BOUNDS_LAYER_ID}-${projectId}`;

  return {
    id: `${sourceId}-fill`,
    source: sourceId,
    type: 'fill',
    paint: {
      'fill-color': colors.black,
      'fill-opacity': 0.2,
    },
  };
}

/** Get any supplemental column keys that need to be in every tile */
function getExtraColumnKeys(layerMetadata: LayerMetadata): string[] {
  if (!layerMetadata) {
    return EMPTY_ARRAY;
  }
  // we always include the uniqueKeys, because they can be used for individual selection
  const uniqueKeys = layerMetadata?.unique_keys ?? [];

  // Exclusive filter column keys need to be in the tiles so we can filter them
  // dynamically even when showing other values.
  const exclusiveFilterColumnKeys =
    layerMetadata?.columns
      ?.filter(
        ({ display_hints: displayHints }) => displayHints?.exclusive_filter,
      )
      .map(({ key }) => key) ?? EMPTY_ARRAY;
  const keys = [...uniqueKeys, ...exclusiveFilterColumnKeys].sort();
  return keys;
}
