import { combineEpics } from 'uf/base/epics';

import editorEpics from './editor';
import exportEpics from './export';
import routingEpics from './routing';

export default combineEpics(
  {
    editorEpics,
    exportEpics,
    routingEpics,
  },
  'analysiseEditorEpics',
);
