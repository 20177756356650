import _ from 'lodash';
import { Store } from 'redux';

import { SwaggerClient } from 'uf/base/xhr';
import { UFState } from 'uf/state';
import { getFlagHeaders } from 'uf/user/selectors/flags';

export function addFlagHeaderToRequests(
  client: SwaggerClient,
  store: Store<UFState>,
) {
  // Pass along backend feature flags to the server via headers.
  client.addRequestInterceptor(apiReq => {
    const headers = getFlagHeaders(store.getState());

    if (!_.isEmpty(headers)) {
      Object.keys(headers).forEach(header => {
        apiReq.headers[header] = headers[header];
      });
    }

    return apiReq;
  });
}
