import deepFreeze from 'deep-freeze';
import _ from 'lodash';

// eslint-disable-next-line no-restricted-imports
import readonlySpecData from 'uf_testing/swagger/swagger-data.json';
// eslint-disable-next-line no-restricted-imports
import readonlySpecManagement from 'uf_testing/swagger/swagger-management.json';
// eslint-disable-next-line no-restricted-imports
import readonlySpecApi from 'uf_testing/swagger/swagger.json';

// SwaggerClient mutates the objects given to it, so make sure to freeze each of
// them and then pass a working version of the object
deepFreeze(readonlySpecData);
deepFreeze(readonlySpecManagement);
deepFreeze(readonlySpecApi);

/** map of all swagger specs. Note that each access causes a clone */
export default {
  get specApi() {
    return _.cloneDeep(readonlySpecApi);
  },
  get specManagement() {
    return _.cloneDeep(readonlySpecManagement);
  },
  get specData() {
    return _.cloneDeep(readonlySpecData);
  },
};
