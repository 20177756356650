import {
  CuratedColumnSymbology,
  InterpolationTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/public/databank/zoning_standard_uf_v3': {
    uf_high_level_category: [
      {
        display_hints: {
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          scale: SymbologyScales.CATEGORICAL,
          theme: 'custom',
        },
        paint: {
          'fill-color': {
            default: '#17becf',
            property: 'uf_high_level_category',
            stops: [
              {
                color: '#d33115',
                value: 'commercial',
              },
              {
                color: '#e036b8',
                value: 'mixed_use',
              },
              {
                color: '#daf0fc',
                value: 'industrial',
              },
              {
                color: '#69b4fe',
                value: 'civic',
              },
              {
                color: '#fbfac0',
                value: 'detsf',
              },
              {
                color: '#f9a900',
                value: 'attsf',
              },
              {
                color: '#d86f00',
                value: 'mf',
              },
              {
                color: '#dbdf00',
                value: 'specific_plan',
              },
              {
                color: '#cccccc',
                value: 'general_use',
              },
            ],
            type: 'categorical',
          },
          'fill-opacity': 0.9,
        },
        type: SymbologyTypes.FILL,
      },
      {
        display_hints: {
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          scale: SymbologyScales.CATEGORICAL,
          theme: 'custom',
        },
        paint: {
          'line-color': '#b3b3b3',
          'line-opacity': 0.9,
          'line-width': 0.5,
        },
        type: SymbologyTypes.LINE,
      },
    ],
  },
};

export default symbologies;
