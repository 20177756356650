import { Feature, Position } from 'geojson';
import _ from 'lodash';
import { Map, Point } from 'mapbox-gl';
import colors from 'material-colors';

import { PolygonGeometry } from 'uf/base/geometry';
import { defaultLineLayout } from 'uf/map/lines';

/**
 * Polygon class for MapBox
 * @param map: a mapbox instance
 * @param [id]: string used to identify the layers/sources for a given polygon
 * @param [points]: array of points used to create a polygon on the map (min 3 points needed)
 */
export default class Polygon {
  map: Map;
  id: string;
  coordinates: PolygonGeometry;
  lastCoordinates: Position;

  points: Feature[];
  mousePosition: Position = null;
  constructor(map: Map, id: string = null, coordinates: PolygonGeometry = []) {
    this.map = map;
    this.id = id || 'polygon';
    this.coordinates = coordinates || []; // LatLngs
    this.lastCoordinates = [];
    this.points = []; // MapBox Point Features

    addSources(map, this.id);
    addLayers(map, this.id);

    // TODO: add ability to initialize a polygon if array of coordinates are supplied,
    // this could be used to show the outlines of all polygons if i.e. if someone were to hover
    // over a filter pill
  }

  // in case we ever need to switch map references
  setMap(map: Map) {
    this.map = map;
  }

  draw() {
    const allCoordinates = this.mousePosition
      ? [...this.coordinates, this.mousePosition]
      : this.coordinates;

    drawPoints(this.map, this.points, this.id);
    drawLines(this.map, allCoordinates, this.id);
    drawPolygon(this.map, allCoordinates, this.id);
  }

  // 'closing' the polygon means to add the first point as the last point.  This lets postGIS know
  // that the polygon is complete.
  closePolygon() {
    // only close the polygon if there are enough points to make one
    if (this.coordinates.length < 3) {
      return false;
    }

    this.coordinates.push(this.coordinates[0]);
    return true;
  }

  // clears the polygon from the screen
  reset() {
    this.coordinates = [];
    this.points = [];
    this.mousePosition = null;
    removeLayers(this.map, this.id);
    removeSources(this.map, this.id);
    addSources(this.map, this.id);
    addLayers(this.map, this.id);
  }

  // cleanup sources and layers
  remove() {
    removeLayers(this.map, this.id);
    removeSources(this.map, this.id);
  }

  isCursorOverStartingPoint(point: Point) {
    const renderedPoints = this.map.queryRenderedFeatures(point, {
      layers: [`${this.id}-points-layer`],
    });
    // return true if point with index 0 found
    return renderedPoints.some(p => p.properties.index === 0);
  }

  setMousePosition(coordinates: Position) {
    this.mousePosition = _.clone(coordinates);
  }

  addCoordinates(coordinates: [number, number]) {
    const coords = _.clone(coordinates);
    // don't add new point if same as last point
    if (_.isEqual(coords, this.lastCoordinates)) {
      return false;
    }
    this.lastCoordinates = coords;
    this.coordinates.push(coords);
    this.points.push({
      type: 'Feature',
      properties: {
        index: this.points.length,
      },
      geometry: {
        type: 'Point',
        coordinates: coords,
      },
    });

    return true;
  }

  getCoordinates() {
    return _.clone(this.coordinates);
  }

  getNumCoordinates() {
    return this.coordinates.length;
  }
}

/**
 * Helper functions, in the future these may be generalized to be used with box selection etc
 */
function drawPoints(map: Map, points: Feature[], id: string) {
  const source = map.getSource(`${id}-points-source`);
  if (source?.type === 'geojson') {
    source.setData({
      type: 'FeatureCollection',
      features: points,
    });
  }
}

function drawLines(map: Map, coordinates: Position[], id) {
  if (coordinates.length < 2) {
    return;
  }

  const data: Feature = {
    type: 'Feature',
    geometry: {
      type: 'LineString',
      coordinates,
    },
    properties: {},
  };
  const source = map.getSource(`${id}-lines-source`);
  if (source?.type === 'geojson') {
    source.setData(data);
  }
}

function drawPolygon(map: Map, coordinates: Position[], id: string) {
  if (coordinates.length < 3) {
    return;
  }

  const data: Feature = {
    type: 'Feature',
    geometry: {
      type: 'Polygon',
      coordinates: [[...coordinates, coordinates[0]]], // MapBox expects an array in an array
    },
    properties: {},
  };

  const source = map.getSource(`${id}-polygon-source`);
  if (source?.type === 'geojson') {
    source.setData(data);
  }
}

function addSources(map: Map, id: string) {
  if (!map.getSource(`${id}-polygon-source`)) {
    map.addSource(`${id}-polygon-source`, {
      type: 'geojson',
      data: {
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [0, 0],
              [0, 0],
              [0, 0],
            ],
          ], // must seed this to avoid MapBox errors
        },
        properties: {},
      },
    });
  }
  if (!map.getSource(`${id}-points-source`)) {
    map.addSource(`${id}-points-source`, {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [],
      },
    });
  }
  if (!map.getSource(`${id}-lines-source`)) {
    map.addSource(`${id}-lines-source`, {
      type: 'geojson',
      data: {
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [0, 0],
            [0, 0],
          ], // must seed this to avoid MapBox errors
        },
        properties: {},
      },
    });
  }
}

function addLayers(map: Map, id: string) {
  if (!map.getLayer(`${id}-polygon-layer`)) {
    map.addLayer({
      id: `${id}-polygon-layer`,
      type: 'fill',
      source: `${id}-polygon-source`,
      layout: {},
      paint: {
        'fill-color': colors.orange[500],
        'fill-opacity': 0.6,
      },
    });
  }

  if (!map.getLayer(`${id}-lines-layer`)) {
    map.addLayer({
      id: `${id}-lines-layer`,
      type: 'line',
      source: `${id}-lines-source`,
      layout: defaultLineLayout,
      paint: {
        'line-color': colors.orange[500],
        'line-width': 2,
        'line-dasharray': [4, 3],
      },
    });
  }

  if (!map.getLayer(`${id}-points-layer`)) {
    map.addLayer({
      id: `${id}-points-layer`,
      type: 'circle',
      source: `${id}-points-source`,
      paint: {
        'circle-radius': 4,
        'circle-color': colors.white,
        'circle-stroke-width': 2,
        'circle-stroke-color': colors.orange[500],
      },
    });
  }
}

function removeSources(map: Map, id: string) {
  if (!map) {
    return;
  }

  if (map.getSource(`${id}-polygon-source`)) {
    map.removeSource(`${id}-polygon-source`);
  }
  if (map.getSource(`${id}-lines-source`)) {
    map.removeSource(`${id}-lines-source`);
  }
  if (map.getSource(`${id}-points-source`)) {
    map.removeSource(`${id}-points-source`);
  }
}

function removeLayers(map: Map, id: string) {
  if (!map) {
    return;
  }
  if (map.getLayer(`${id}-polygon-layer`)) {
    map.removeLayer(`${id}-polygon-layer`);
  }
  if (map.getLayer(`${id}-lines-layer`)) {
    map.removeLayer(`${id}-lines-layer`);
  }
  if (map.getLayer(`${id}-points-layer`)) {
    map.removeLayer(`${id}-points-layer`);
  }
}
