import classNames from 'classnames';
import { parse } from 'query-string';
import React, { FunctionComponent } from 'react';
import { Box } from 'react-layout-components';
import { jt, t } from 'ttag';
import UAParser from 'ua-parser-js';

import { getSignInUrlBase } from 'uf/base/api';
import panelStyles from 'uf/styles/panels.module.css';
import popupStyles from 'uf/styles/popups.module.css';
import rootStyles from 'uf/styles/root.module.css';
import Logo from 'uf/ui/base/Logo';

interface ErrorViewProps {
  error?: Error;
  stack?: string;
  location?: { query: any; search?: string };

  userAgent?: string;
}

const ErrorView: FunctionComponent<ErrorViewProps> = props => {
  const {
    location = {
      query: {},
      search: '',
    },
    stack = '',
    error,
    userAgent,
  } = props;
  const query = location.query || parse(location.search || '');
  const { error: errorText, error_description: errorDescription } = query;
  let errorComponent = null;

  switch (errorText) {
    case 'unauthorized':
      errorComponent = (
        <UnauthorizedError errorDescription={errorDescription} />
      );
      break;

    case 'browsersupport':
      errorComponent = <BrowserSupportError userAgent={userAgent} />;
      break;

    case 'invalid_state':
      errorComponent = <InvalidStateError />;
      break;

    default:
      errorComponent = (
        <GenericError
          error={error ? error.message : errorText}
          errorDescription={errorDescription}
        />
      );
      break;
  }

  const header = <Logo height={75} className={rootStyles.mediumPadding} />;
  return (
    <Box
      fit
      center
      style={{
        backgroundColor: '#88abd7',
      }}>
      <div className={classNames(panelStyles.card2, popupStyles.dialog)}>
        {header && <div className={rootStyles.lightBackground}>{header}</div>}
        <div>
          <div className={rootStyles.mediumPadding}>{errorComponent}</div>
        </div>
        {__DEVELOPMENT__ && stack && <pre>{stack}</pre>}
      </div>
    </Box>
  );
};

interface UnauthorizedErrorProps {
  errorDescription: string;
}

const UnauthorizedError: FunctionComponent<UnauthorizedErrorProps> = ({
  errorDescription,
}) => {
  if (errorDescription === 'email_unverified') {
    return (
      <Box fit column center>
        <h1>{t`Thank you for joining UrbanFootprint!`}</h1>
        <h3>{t`Please check your email to verify your account.`}</h3>
      </Box>
    );
  }

  return (
    <Box fit column center>
      <h1>{t`You cannot login at this time.`}</h1>
      <h3>{t`Please contact support if the problem persists.`}</h3>
    </Box>
  );
};

interface BrowserSupportErrorProps {
  userAgent?: string;
}

let defaultUserAgent: string;
if (__CLIENT__) {
  defaultUserAgent = window.navigator.userAgent;
}

const BrowserSupportError: FunctionComponent<BrowserSupportErrorProps> = ({
  userAgent = defaultUserAgent,
}) => {
  const parsedUserAgent = new UAParser(userAgent);
  let { name, version } = parsedUserAgent.getBrowser();
  [version] = version ? /[0-9]+\.[0-9]+/.exec(version) : [null];
  name = name || t`an unknown browser`;
  const browserNameVersion = (
    <strong>
      {name} {version}
    </strong>
  );
  const browserLink = (
    <strong>
      <a href="https://chrome.google.com">{t`Google Chrome`}</a>
    </strong>
  );
  return (
    <div>
      <h2>{t`UrbanFootprint works best with the Google Chrome browser.`}</h2>
      <p>
        {jt`You are using ${browserNameVersion}. This browser is not currently supported. Please download and use ${browserLink}.`}
      </p>
      <p>{t`Additional browser support is coming soon!`}</p>
    </div>
  );
};

const InvalidStateError: FunctionComponent = () => {
  const login = <a href={getSignInUrlBase()}>{t`Login`}</a>;
  return (
    <div>
      <h1>{t`Login page expired`}</h1>
      <h3>{jt`Try to ${login} again.`}</h3>
    </div>
  );
};

interface GenericErrorProps {
  error: string;
  errorDescription: string;
}

const GenericError: FunctionComponent<GenericErrorProps> = ({
  error,
  errorDescription,
}) => {
  return (
    <div>
      <h1>{t`Oops, there was an error. Sorry about that!`}</h1>
      <div>
        {error && <strong>{`${error}`}</strong>}
        {errorDescription && <p>{errorDescription}</p>}
      </div>
    </div>
  );
};
export default ErrorView;
