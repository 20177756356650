import {
  CuratedColumnSymbology,
  InterpolationTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const symbologies: Record<string, Record<string, CuratedColumnSymbology[]>> = {
  '/public/dataset/city_of_appleton_city_of_appleton_zoning': {
    dxf_text: [
      {
        display_hints: {
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          scale: SymbologyScales.CATEGORICAL,
          theme: 'custom',
        },
        paint: {
          'fill-color': {
            default: '#98df8a',
            property: 'dxf_text',
            stops: [
              {
                color: '#dbdf00',
                value: 'AG',
              },
              {
                color: '#fda1ff',
                value: 'C-1',
              },
              {
                color: '#fda1ff',
                value: 'C-2',
              },
              {
                color: '#f44e3b',
                value: 'CBD',
              },
              {
                color: '#fa28ff',
                value: 'C-O',
              },
              {
                color: '#aea1ff',
                value: 'M-1',
              },
              {
                color: '#aea1ff',
                value: 'M-2',
              },
              {
                color: '#68bc00',
                value: 'NC',
              },
              {
                color: '#808080',
                value: 'P',
              },
              {
                color: '#cccccc',
                value: 'PD/C-2',
              },
              {
                color: '#cccccc',
                value: 'PD/CBD',
              },
              {
                color: '#cccccc',
                value: 'PD/C-O',
              },
              {
                color: '#cccccc',
                value: 'PD/M-2',
              },
              {
                color: '#cccccc',
                value: 'PD/P-I',
              },
              {
                color: '#cccccc',
                value: 'PD/R-1A',
              },
              {
                color: '#c7c7c7',
                value: 'PD/R-1B',
              },
              {
                color: '#cccccc',
                value: 'PD/R-2',
              },
              {
                color: '#cccccc',
                value: 'PD/R-3',
              },
              {
                color: '#009ce0',
                value: 'P-I',
              },
              {
                color: '#fcdc00',
                value: 'R-1A',
              },
              {
                color: '#fcdc00',
                value: 'R1-A',
              },
              {
                color: '#fcc400',
                value: 'R-1B',
              },
              {
                color: '#fcc400',
                value: 'R-1C',
              },
              {
                color: '#fb9e00',
                value: 'R-2',
              },
              {
                color: '#fb9e00',
                value: 'R-3',
              },
            ],
            type: 'categorical',
          },
          'fill-opacity': 0.6,
        },
        type: SymbologyTypes.FILL,
      },
      {
        display_hints: {
          distribution: null,
          interpolation: InterpolationTypes.CATEGORICAL,
          scale: SymbologyScales.CATEGORICAL,
          theme: 'custom',
        },
        paint: {
          'line-color': '#999999',
          'line-opacity': 0.6,
          'line-width': 1,
        },
        type: SymbologyTypes.LINE,
      },
    ],
  },
};

export default symbologies;
