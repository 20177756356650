import {
  GDB_EXTENSIONS,
  GDB_MIMETYPES,
  GEOJSON_EXTENSIONS,
  GEOJSON_MIMETYPES,
  GEOPACKAGE_EXTENSIONS,
  GEOPACKAGE_MIMETYPES,
  SHAPEFILE_EXTENSIONS,
  SHAPEFILE_MIMETYPES,
  CSV_EXTENSIONS,
  CSV_MIMETYPES,
} from 'uf/base/mimetypes';

export const IMPORT_EXTENSIONS: string[] = [
  ...new Set([
    ...GDB_EXTENSIONS,
    ...GEOJSON_EXTENSIONS,
    ...GEOPACKAGE_EXTENSIONS,
    ...SHAPEFILE_EXTENSIONS,
  ]),
];

export const IMPORT_MIMETYPES: string[] = [
  ...new Set([
    ...GDB_MIMETYPES,
    ...GEOJSON_MIMETYPES,
    ...GEOPACKAGE_MIMETYPES,
    ...SHAPEFILE_MIMETYPES,
  ]),
];

// Accepted values for attr-accept by way of react-dropzone
export const IMPORT_EXTENSIONS_AND_MIMETYPES: string[] = [
  ...IMPORT_MIMETYPES,
  ...IMPORT_EXTENSIONS,
];

export const IMPORT_EXTENSIONS_AND_MIMETYPES_PLUS_CSV: string[] = [
  ...IMPORT_EXTENSIONS_AND_MIMETYPES,
  ...CSV_EXTENSIONS,
  ...CSV_MIMETYPES,
];
