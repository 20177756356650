import {
  CuratedColumnSymbology,
  DistributionTypes,
  InterpolationTypes,
  SymbologyScales,
  SymbologyTypes,
} from 'uf/symbology';

const censusPlanningdatabaseEducationLayers = [
  {
    layerId:
      '/public/dataset/us_census_census_planning_database_2018_education',
    columnKey: 'pct_college_acs_12_16',
  },
  {
    layerId:
      '/public/dataset/us_census_census_planning_database_2019_education',
    columnKey: 'pct_college_acs_13_17',
  },
  {
    layerId: '/public/databank/cpd_2020_education',
    columnKey: 'pct_college_acs_14_18',
  },
  {
    layerId: '/public/databank/cpd_2021_education',
    columnKey: 'pct_college_acs_15_19',
  },
];

const symbologies: Record<
  string,
  Record<string, CuratedColumnSymbology[]>
> = {};
censusPlanningdatabaseEducationLayers.forEach(({ layerId, columnKey }) => {
  symbologies[layerId] = {
    [columnKey]: [
      {
        display_hints: {
          distribution: DistributionTypes.DEFAULT,
          interpolation: InterpolationTypes.INTERVAL,
          scale: SymbologyScales.NUMERIC,
          showZero: true,
          theme: 'd3:interpolateGnBu',
        },
        paint: {
          'fill-color': {
            property: columnKey,
            stops: [
              {
                color: 'rgb(247, 252, 240)',
                value: 0,
              },
              {
                color: 'rgb(227, 244, 221)',
                value: 10,
              },
              {
                color: 'rgb(207, 236, 202)',
                value: 20,
              },
              {
                color: 'rgb(179, 225, 188)',
                value: 30,
              },
              {
                color: 'rgb(143, 211, 190)',
                value: 40,
              },
              {
                color: 'rgb(103, 192, 202)',
                value: 50,
              },
              {
                color: 'rgb(67, 165, 202)',
                value: 60,
              },
              {
                color: 'rgb(35, 132, 186)',
                value: 70,
              },
              {
                color: 'rgb(12, 99, 164)',
                value: 80,
              },
              {
                color: 'rgb(8, 64, 129)',
                value: 90,
              },
            ],
            type: 'interval',
          },
          'fill-opacity': 0.5,
        },
        type: SymbologyTypes.FILL,
      },
      {
        display_hints: {
          distribution: DistributionTypes.DEFAULT,
          interpolation: InterpolationTypes.INTERVAL,
          scale: SymbologyScales.NUMERIC,
          showZero: true,
          theme: 'custom',
        },
        paint: {
          'line-color': '#333333',
          'line-opacity': 0.5,
          'line-width': 0.5,
        },
        type: SymbologyTypes.LINE,
      },
    ],
  };
});

export default symbologies;
